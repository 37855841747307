import { Match, pipe } from 'effect'

const homeTypes = [
  'town-house',
  'multi-family',
  'lot-or-land',
  'commercial',
  'single-family',
  'condo',
  'mobile',
  'farm',
  'duplex-to-fourplex',
] as const

export type HomeType = typeof homeTypes[number]

export const HomeType = {
  toString: (homeType: HomeType | null): string =>
    pipe(
      Match.value(homeType),
      Match.when('town-house', () => 'Town House'),
      Match.when('multi-family', () => 'Multi Family'),
      Match.when('lot-or-land', () => 'Lot/Land'),
      Match.when('single-family', () => 'Single Family'),
      Match.when('commercial', () => 'Commercial'),
      Match.when('condo', () => 'Condo'),
      Match.when('mobile', () => 'Mobile Home'),
      Match.when('farm', () => 'Farm'),
      Match.when('duplex-to-fourplex', () => 'Duplex - Fourplex'),
      Match.when(null, () => 'Any Type'),

      /** Unfortunately, we don't know all the possible values **/
      Match.when(Match.string, () => homeType || '--'),
      Match.exhaustive,
    ),
  toStringUnstandardized: (homeType: string | null): string =>
    pipe(
      Match.value(homeType),
      Match.when('town-house', () => 'Town House'),
      Match.when('multi-family', () => 'Multi Family'),
      Match.when('lot-or-land', () => 'Lot/Land'),
      Match.when('single-family', () => 'Single Family'),
      Match.when('commercial', () => 'Commercial'),
      Match.when('condo', () => 'Condo'),
      Match.when('mobile', () => 'Mobile Home'),
      Match.when('farm', () => 'Farm'),
      Match.when('duplex-to-fourplex', () => 'Duplex/Fourplex'),
      Match.when(null, () => 'Any Type'),

      /** Unfortunately, we don't know all the possible values **/
      Match.when(Match.string, () => homeType || '--'),
      Match.exhaustive,
    ),
  toArray: () => homeTypes,
}
