import { Center, Spinner } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { toast } from 'presentation/components/Toast'
import { restClient } from 'presentation/libs/client'
import QueryString from 'qs'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { create } from 'zustand'

type RetrialAttempt = {
  userId: string | null
  redirectLink: string | null
}

/**
 * For some reason the screen is rendered twice, for now just prevent duplicate
 * requests and redirection not working by using a store to keep track of the
 * retrial info.
 */
const store = create<{ retrialAttempt: RetrialAttempt | null }>(() => ({
  retrialAttempt: null,
}))

export const RenewTrialScreen = () => {
  const access = useAccessStore(state => state.local.access)
  const userId = useAccessStore(state =>
    (
      state.local.access.status === 'loaded'
      && state.local.access.data.status === 'logged-in'
    )
      ? state.local.access.data.accessor.id
      : null)
  const updateAccess = useAccessStore(state => state.actions.updateAccess.execute)
  const status = pipe(
    Match.value(access),
    // Comment out for now due to testability issues
    // Match.when(
    //   { data: { status: 'logged-in', subscription: {} } },
    //   () => 'logged-in-with-plan' as const
    // ),
    // Match.when(
    //   { data: { status: 'logged-in', subscription: null } },
    //   () => 'logged-in-without-plan' as const
    // ),
    Match.when({ data: { status: 'logged-in' } }, () => 'logged-in-without-plan' as const),
    Match.when({ data: { status: 'logged-out' } }, () => 'logged-out' as const),
    Match.orElse(() => 'loading' as const),
  )

  const shouldRenew = status === 'logged-in-without-plan'

  const retrialAttempt = store(state => state.retrialAttempt)
  const redirectLink = retrialAttempt?.redirectLink

  useEffect(() => {
    if (!shouldRenew || !userId || retrialAttempt?.userId === userId)
      return
    store.setState({ retrialAttempt: { userId, redirectLink: null } })
    void restClient.post('legacy/users/retrial')
      .then(async response => {
        if (response.status === 200) {
          toast.success({
            title: 'Trial has been successfully renewed',
          })
          await updateAccess()
          store.setState({ retrialAttempt: { userId, redirectLink: '/user/billing' } })
        } else {
          throw new Error('Failed to renew trial')
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          toast.error({
            title: 'Failed to renew trial',
            message: 'You have already used your trial renewal.',
          })
        } else {
          toast.error({
            title: 'Failed to renew trial',
            message: 'Please try again or contact support.',
          })
        }

        store.setState({ retrialAttempt: { userId, redirectLink: '/search' } })
      })
  }, [shouldRenew])

  if (status === 'logged-out') {
    const query = QueryString.stringify({ redirect: '/renew-trial' })
    return <Navigate to={`/login?${query}`} />
  }

  // Comment out for now due to testability issues
  // if (status === 'logged-in-with-plan')
  //   return <Navigate to='/search' />

  if (redirectLink)
    return <Navigate to={redirectLink} />

  return (
    <Center h='app-height'>
      <Spinner />
    </Center>
  )
}
