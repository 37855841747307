import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import HappyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Radio } from 'presentation/components/Radio/Radio'
import { toast } from 'presentation/components/Toast'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { useSelectFormatModal } from 'presentation/screens/SkiptraceScreen/components/SelectFormatModal/SelectFormatModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'

export type SelectFormatProps = {
  onCancel: () => void
}
export const SelectFormatCard = (props: SelectFormatProps) => {
  const [format, setFormat] = useState<'horizontal' | 'vertical'>('vertical')
  const state = useSelectFormatCardState()

  if (state.status !== 'ready') return null

  return (
    <>
      <Breakpoint
        tabSm={(
          <Image
            position='absolute'
            w='180px'
            h='180px'
            top='-105px'
            right='-15px'
            src={HappyDogPng}
          />
        )}
      />
      <Text
        color='neutral.500'
        textStyle={mbp({ mobSm: 'bodyXLHeavy', tabSm: 'h3Heavy' })}
        textAlign='center'
      >
        Select Format
      </Text>
      <Text
        color='graystrong.400'
        textStyle='bodyLFat'
        textAlign='center'
        mt={mbp({ mobSm: 1, tabSm: 2 })}
      >
        We have two formats to choose from:
      </Text>
      <Box mt={mbp({ mobSm: 3, tabSm: 5 })}>
        <Flex flexDirection='column' gap={3}>
          <FormatOption
            label='Vertical'
            description='One phone number per row. Each additional phone number takes
          up more rows so the information is not limited in any way.'
            isSelected={format === 'vertical'}
            onClick={() => setFormat('vertical')}
          />
          <FormatOption
            label='Horizontal'
            description='One row per skiptrace. Emails, phones, rtc, are
          separated into columns and are limited to 10 columns each'
            isSelected={format === 'horizontal'}
            onClick={() => setFormat('horizontal')}
          />
        </Flex>
        <Flex
          justifyContent='center'
          flexDirection={mbp({ mobSm: 'column', tabSm: 'row' })}
          mt={mbp({ mobSm: 3, tabSm: 5 })}
          gap={mbp({ mobSm: 2 })}
        >
          <Button
            colorScheme='neutral'
            variant='outline'
            order={mbp({ mobSm: 2, tabSm: 1 })}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button
            colorScheme='neutral'
            variant='solid'
            onClick={() => state.downloadResult(format)}
            order={mbp({ mobSm: 1, tabSm: 2 })}
          >
            Download
          </Button>
        </Flex>
      </Box>
    </>
  )
}

type FormatOptionProps = {
  label: string
  description: string
  isSelected?: boolean
  onClick?: () => void
}

const FormatOption = (props: FormatOptionProps) => (
  <Box
    borderColor={props.isSelected ? 'neutral.500' : 'highlight.100'}
    borderWidth={0.25}
    borderRadius={mbp({ mobSm: 3 })}
    padding={mbp({ mobSm: 2, tabSm: 3 })}
    bgColor='highlight.100'
    onClick={props.onClick}
    cursor='pointer'
    shadow='primary.m'
  >
    <Text
      color='graystrong.200'
      textStyle={mbp({ mobSm: 'bodyM', tabSm: 'bodyXL' })}
    >
      {props.description}
    </Text>
    <Radio
      mt={mbp({ mobSm: 1, tabSm: 2 })}
      isChecked={props.isSelected}
      onClick={e => e.preventDefault()}
      textStyle={mbp({ mobSm: 'bodyL', tabSm: 'bodyXL' })}
    >
      {props.label}
    </Radio>
  </Box>
)

const useSelectFormatCardState = () => {
  const modalApi = useSelectFormatModal()

  const routeLeadId = useLeadId()
  const propDetailsState = usePropertyDetailsStore(propertyDetailsApi => {
    const propertyState = propertyDetailsApi.actions.getProperty.state
    const doesPropertyMatch = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    const ownerAddress = propertyState.status === 'success'
      && propertyState.data.status === 'with-details'
      && propertyState.data.ownership?.address

    if (!doesPropertyMatch || !ownerAddress) return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      leadId: propertyState.data.leadId,
      ownerAddress,
    }
  })

  const skiptraceStore = useSkiptraceStore(skipApi => {
    if (propDetailsState.status !== 'ready') return { status: 'unready' as const }

    const toastId = 'download-toast-id'
    return {
      status: 'ready' as const,
      downloadResult: (format: 'horizontal' | 'vertical') => {
        void skipApi.actions.downloadResult.execute({
          format,
          address: propDetailsState.ownerAddress,
        })
          .then(() => {
            modalApi.actions.close()
            toast.info({ id: toastId, title: 'Your download will begin shortly' })
          })
          .finally(() => {
            toast.dismiss(toastId)
            toast.success({ title: 'Download completed' })
          })
      },
    }
  })

  return skiptraceStore
}
