import { Flex, Text } from '@chakra-ui/react'
import { PeekingDrawerButton, PeekingDrawerIcon } from 'presentation/components/PeekingDrawer/PeekingDrawer'
import { useSearchHistoryMetaProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/useSearchHistoryMetaProps'

export const DrawerPanelMetaButton = () => {
  const {
    bgColor,
    color,
    metaInfo,
    isDisabled,
  } = useSearchHistoryMetaProps()

  return (
    <PeekingDrawerButton
      borderWidth={0}
      isDisabled={isDisabled}
      bgColor={bgColor}
      color={color}
      borderTopRadius={3}
      px={1}
      py={0.5}
    >
      <Flex
        justifyContent='flex-start'
        alignItems='center'
        gap={1}
      >
        <PeekingDrawerIcon
          ml='auto'
          boxSize={5}
          color='inherit'
        />
        <Text
          flexGrow={1}
          textAlign='left'
          color='inherit'
          textStyle='bodyM'
        >
          {metaInfo}
        </Text>
      </Flex>
    </PeekingDrawerButton>
  )
}
