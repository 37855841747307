import { StringLib } from 'libs/String'
import { UndefinedOrNullProps } from 'libs/utils.types'
import { isEmpty } from 'remeda'

export type Address = {
  line1: string
  city: string
  state: string
  postalCode: string
}

const toNullIfEmpty = (address: Address): Address | null => {
  if (!address.line1 && !address.city && !address.state && !address.postalCode)
    return null
  return address
}

const isValid = (address: Partial<Address> | null | undefined): boolean =>
  !!address
  && !!StringLib.ensureOrNull(address.line1)
  && !!StringLib.ensureOrNull(address.city)
  && !!StringLib.ensureOrNull(address.state)
  && !!StringLib.ensureOrNull(address.postalCode)

const ensureOr = <T>(defaultValue: T) => (value: Partial<Address> | null | undefined): Address | T =>
  isValid(value)
    ? value as Address
    : defaultValue

const ensureOrNull = ensureOr(null)

const ifValid = <TOutput>(fn: (value: Address) => TOutput) =>
  (maybeValue: Address | null | undefined): TOutput | null =>
    isValid(maybeValue) ? fn(maybeValue as Address) : null

const toString = (address: Address): string => {
  const { line1, city, state, postalCode } = address
  return [line1, city, `${state} ${postalCode}`]
    .map(v => v.trim())
    .filter(v => !isEmpty(v))
    .join(', ')
}

const isEqual = (a: Address, b: Address): boolean =>
  a.line1 === b.line1
  && a.city === b.city
  && a.state === b.state
  && a.postalCode === b.postalCode

/** Simply removes extra properties and trims all string values */
const clean = <T extends Address>(address: T): Address => ({
  line1: address.line1.trim(),
  city: address.city.trim(),
  state: address.state.trim(),
  postalCode: address.postalCode.trim(),
})

const serialize = (address: Address): string => [
  address.line1,
  address.city,
  address.state,
  address.postalCode,
].join('|')

export const Address = {
  // equality
  isEqual,

  // type validation
  ensureOr,
  ensureOrNull,
  toNullIfEmpty,
  ifValid,
  isValid,

  // formatters
  toString,
  formatCityStateZip: (address: UndefinedOrNullProps<Address>): string => {
    const cityState = [address.city, address.state].filter(Boolean).join(', ')
    const cityStateZip = [cityState, address.postalCode].filter(Boolean).join(' ')
    return cityStateZip
  },
  formatCityStateZipSub: (address: UndefinedOrNullProps<Address & { subdivision: string }>): string =>
    [
      Address.formatCityStateZip(address),
      address.subdivision,
    ]
      .filter(Boolean)
      .join(', '),

  // misc
  clean,
  serialize,
  fillMissing: (address: UndefinedOrNullProps<Address>): Address => ({
    line1: address.line1 ?? '',
    city: address.city ?? '',
    state: address.state ?? '',
    postalCode: address.postalCode ?? '',
  }),
}
