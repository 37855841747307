import { createIcon } from '@chakra-ui/react'

export const StreetViewIcon = createIcon({
  displayName: 'SteetViewIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath='url(#clip0_1325_121352)'>
        <path
          d='M17.2453 15.4575C17.0287 15.7059 16.7869 15.9309 16.5 16.0973V17.1727C19.6181 17.6105 21.75 18.4875 21.75 19.5C21.75 20.9498 17.385 22.125 12 22.125C6.615 22.125 2.25 20.9498 2.25 19.5C2.25 18.4875 4.38188 17.6109 7.5 17.1727V16.0973C7.21313 15.9309 6.97125 15.7059 6.75469 15.4575C2.75953 16.1878 0 17.7211 0 19.5C0 21.9853 5.37281 24 12 24C18.6272 24 24 21.9853 24 19.5C24 17.7211 21.2405 16.1878 17.2453 15.4575ZM12 6C13.657 6 15 4.65703 15 3C15 1.34297 13.657 0 12 0C10.343 0 9 1.34297 9 3C9 4.65703 10.343 6 12 6ZM9 15V19.5C9 20.3283 9.67172 21 10.5 21H13.5C14.3283 21 15 20.3283 15 19.5V15C15.8283 15 16.5 14.3283 16.5 13.5V9C16.5 7.75734 15.4927 6.75 14.25 6.75H13.6969C13.178 6.98578 12.6066 7.125 12 7.125C11.3934 7.125 10.822 6.98578 10.3031 6.75H9.75C8.50734 6.75 7.5 7.75734 7.5 9V13.5C7.5 14.3283 8.17172 15 9 15Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </>

  ),
})
