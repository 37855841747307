import { Schema as S } from '@effect/schema'
import { pipe } from 'effect'
import { dedupe, map, reduce } from 'effect/Array'
import { BatchSkiptrace } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { SkiptraceProvider } from 'features/valueObjects/SkiptraceProvider'
import { EffectLib } from 'libs/Effect'
import { StringLib } from 'libs/String'
import { isNonNullable } from 'utils/isNonNullable'

export namespace GETBatchSkiptraceByListIdSchema {
  // #region Schemas
  export const SkiptraceSchema = S.Struct({
    provider: S.Union(
      S.Literal('reiGroup'),
      S.Literal('skipGenie'),
      S.Literal('peopleFindersPro'),
    ),
    timestamp: S.NullishOr(S.Date),
    results: S.NullishOr(S.Struct({
      matched: S.Number,
      total: S.Number,
    })),
  })

  export const PossibleRelativeSchema = S.Struct({
    name: S.NullishOr(S.String),
    age: S.NullishOr(S.Union(S.String, S.Number)),
    deceased: S.NullishOr(S.Union(S.String, S.Boolean)),
  })

  export const PhoneSchema = S.Struct({
    number: S.NullishOr(S.String),
    type: S.NullishOr(S.String),
  })

  export const ContactAddressSchema = S.Struct({
    line1: S.NullishOr(S.String),
    city: S.NullishOr(S.String),
    state: S.NullishOr(S.String),
    zip: S.NullishOr(S.String),
  })

  export const ContactSchema = S.Struct({
    firstName: S.NullishOr(S.String),
    lastName: S.NullishOr(S.String),
    address: S.NullishOr(ContactAddressSchema),
    dateOfBirth: S.NullishOr(S.String),
    emails: S.NullishOr(S.Array(S.String)),
    phones: S.NullishOr(S.Array(PhoneSchema)),
    possibleRelatives: S.NullishOr(S.Array(PossibleRelativeSchema)),
    skiptraces: S.NullishOr(S.Array(SkiptraceSchema)),
  })

  export const MemberSchema = S.Struct({
    id: S.String,
    contact: S.NullishOr(ContactSchema),
  })

  export const Self = S.Struct({
    count: S.Number,
    items: S.Array(MemberSchema),
  })

  export type Self = S.Schema.Type<typeof Self>

  // #endregion

  // #region decode
  export const decode = EffectLib.makeLoggedDecoder({
    schema: Self,
    name: 'GETBatchSkiptraceByListIdSchema',
  })
  // #endregion

  // #region toDomain
  export const toDomain = (origParams: {
    page: number
    listId: MarketingListId
  }) =>
    (resp: Self): BatchSkiptrace => ({
      currentPage: origParams.page,
      listId: origParams.listId,
      progress: {},
      resultByMemberId: resp.items
        .reduce<BatchSkiptrace['resultByMemberId']>(
        (record, item) => {
          const hasSkiptraceAttempt = (item.contact?.skiptraces?.length ?? 0) > 0

          if (!hasSkiptraceAttempt)
            return record

          const latestTimestamp = (item.contact?.skiptraces ?? [])
            .map(skiptrace => skiptrace.timestamp)
            .filter(isNonNullable)
            .reduce<Date | null>(
            (latest, current) =>
              latest === null || current > latest
                ? current
                : latest,
            null,
          )

          const ownerName = [
            item.contact?.firstName ?? '',
            item.contact?.lastName ?? '',
          ].join(' ')

          const result: Skiptrace.Result = {
            owners: [],
            providers: pipe(
              item.contact?.skiptraces ?? [],
              reduce(
                [],
                (acc: SkiptraceProvider[], skiptrace) => {
                  const provider: SkiptraceProvider = skiptrace.provider === 'reiGroup'
                    ? 'REIGROUP'
                    : skiptrace.provider === 'skipGenie'
                      ? 'SKIPGENIE'
                      : 'UNKNOWN'
                  return dedupe(
                    [...acc, provider],
                  )
                },
              ),
            ),
            skiptracedAt: latestTimestamp,
            subject: {
              leadId: '',
              address: {
                line1: item.contact?.address?.line1 ?? '',
                city: item.contact?.address?.city ?? '',
                state: item.contact?.address?.state ?? '',
                postalCode: item.contact?.address?.zip ?? '',
              },
              owner: ownerName,
            },
            tracers: [],
          }

          record[MarketingListMemberId.make(item.id)] = result

          const hasSkiptraceMatch = ((item.contact?.possibleRelatives?.length ?? 0) > 0)
            || ((item.contact?.emails?.length ?? 0) > 0)
            || ((item.contact?.phones?.length ?? 0) > 0)

          if (!hasSkiptraceMatch) return record

          result.owners = [
            {
              name: ownerName,
              addressHistory: [],
              age: null,
              bankruptciesCount: null,
              isNew: false,
              judgementsCount: null,
              liensCount: null,
              possibleAssociates: pipe(
                item.contact?.possibleRelatives ?? [],
                map(
                  relative => ({
                    isNew: true,
                    updatedAt: latestTimestamp,
                    name: relative.name ?? '',
                    unformattedAge: StringLib.toStringIfNumberUnstrict(relative.age) ?? '',
                  }),
                ),
              ),
              possibleEmails: pipe(
                item.contact?.emails ?? [],
                map(
                  email => ({
                    email,
                    isNew: true,
                    updatedAt: latestTimestamp,
                  }),
                ),
              ),
              possiblePhoneNumbers: pipe(
                item.contact?.phones ?? [],
                map(
                  phone => ({
                    isNew: true,
                    updatedAt: latestTimestamp,
                    phoneNumber: phone.number ?? '',
                    type: phone.type ?? '',
                  }),
                ),
              ),
              updatedAt: latestTimestamp,
            },
          ]

          return record
        },
        {},
      ),
    })
  // #endregion
}

export type GETBatchSkiptraceByListIdSchema = GETBatchSkiptraceByListIdSchema.Self
