import { Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import GetOwnersPropertiesPreview from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview'
import GET_OWNERS_PROPERTIES_PREVIEW from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview.graphql'
import GetOwnersPropertiesPreviewSchema from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview.schema'
import { apolloClient } from 'presentation/libs/client'

const GetOwnersPropertiesPreviewLive: GetOwnersPropertiesPreview = ({
  parcelId,
}) =>
  Effect.tryPromise({
    try: () =>
      apolloClient.query({
        errorPolicy: 'all',
        query: GET_OWNERS_PROPERTIES_PREVIEW,
        variables: {
          parcelId,
        },
      })
        .then(response => GetOwnersPropertiesPreviewSchema.resultFromGraphQL(response.data)),
    catch: error => new UnknownException(error),
  })

export default GetOwnersPropertiesPreviewLive
