import { CMA } from 'features/CMA/CMA.domain'
import { mapFiltersToLegacy } from 'features/CMA/infra/remote/filterComps/filterComps.mapper'
import { getPropertyCmaInfo } from 'features/CMA/infra/remote/loadCma/getPropertyCmaInfo'
import { mapCMA } from 'features/CMA/infra/remote/loadCma/loadCMA.mapper'
import { getProperty } from 'features/PropertyDetails/infra/remote/getProperty/getProperty'
import { restClient } from 'presentation/libs/client'

/** @TODO Do not use getProperty, properly extend the LOAD_CMA_QUERY to get all you need */
export const loadCMA = async (payload: CMA.LoadReportPayload): Promise<CMA.LoadReportResult> => {
  const leadId = payload.id
  // Get property details
  const property = await getProperty({ leadId })

  // If we get InsufficientConsumables, throw an error
  if (property.status === 'insufficient-consumables')
    throw new Error('Insufficient consumables to get property details')


  if (!property.location) {
    return {
      status: 'no-subj-location',
      address: {
        ...property.address,
        subdivision: (property.status === 'with-details'
        && property.land?.subdivision) || null,
      },
    }
  }

  // Create a new report directly without checking for existing data
  const restResponse = [await restClient.post(`legacy/cma/add/${leadId}`, {
    json: {
      ...mapFiltersToLegacy({
        soldWithinMonths: {
          max: CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX,
          range: [null, CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX],
        },
        distanceMiles: {
          max: CMA.DEFAULT_DISTANCE_MILES_MAX,
          subdivision: null,
        },
        boundary: null,
        homeType: null,
        yearBuilt: null,
        bedroomsCount: null,
        bathroomsCount: null,
        livingAreaSqft: null,
        lotAreaSize: null,
        garageSpacesCount: null,
        fireplacesCount: null,
        storiesCount: null,
        basementType: null,
        poolsCount: null,
        construction: null,
        foundation: null,
      }),
      confirmationKey: payload.confirmationKey,
    },
  }).json()]

  const gqlResponse = property.status === 'with-details'
    ? await getPropertyCmaInfo(property.parcelId)
    : null

  const report = mapCMA({
    rest: restResponse,
    gql: gqlResponse,
    property,
  })

  if (!report) throw new Error('No CMA data found')

  const result = {
    ...{
      _original: {
        rest: restResponse,
        gql: gqlResponse,
      },
    // Only for snapshot purposes
    } as any,
    status: 'success',
    report,
  }

  return result
}
