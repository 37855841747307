import { Context } from 'effect'
import { PropertyModalPayload } from 'presentation/components/PropertyModal/PropertyModal.hooks'

class PropertyModalService extends Context.Tag('PropertyModalService')<
  PropertyModalService,
  { open: (payload: PropertyModalService.OpenParams) => void }
>() {}

namespace PropertyModalService {
  export type Shape = Context.Tag.Service<PropertyModalService>
  export type OpenParams = PropertyModalPayload
}

export default PropertyModalService
