import { CMA } from 'features/CMA/CMA.domain'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { PartialRange } from 'features/valueObjects/Range'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { useCompsFiltersPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { filterByShowValue as byShowValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/CMAEntriesToolbar.utils'
import { useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { CompsFilterPanelDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/CompsFilterPanel.dumb'
import { BathsFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/BathsFilterCard'
import { BedsFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/BedsFilterCard'
import { DistanceFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/DistanceFilterCard'
import { GarageFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/GarageFilterCard'
import { HomeTypeFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/HomeTypeFilterCard'
import { HouseSizeFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/HouseSizeFilterCard'
import { LotSizeFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/LotSizeFilterCard'
import { PoolFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/PoolFilterCard'
import { ShowFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/ShowFilterCard'
import { SoldWithinFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/SoldWithinFilterCard'
import { YearBuiltFilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/YearBuiltFilterCard'
import { SoldWithinRange } from 'presentation/screens/CompsScreen/components/common/SoldWithinRange'
import { useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { shallow } from 'zustand/shallow'

export const CompsFilterPanel = () => {
  const { selectedListType: activeTab } = useActiveEntriesTab()
  const { showValues } = useEntriesToolbarMenu()
  const filterPanel = useCompsFiltersPanelStore()

  const report = useCMAStore(api => {
    const filterComps = api.actions.filterComps.execute
    if (checkStateHasReportData(api.local.report)) {
      return {
        salesCompsTotal: api.local.report.data.salesListInfo.comps.filter(byShowValue(showValues)).length,
        rentalsCompsTotal: api.local.report.data.rentalsListInfo.comps.filter(byShowValue(showValues)).length,
        filterComps,
      }
    }

    return {
      salesCompsTotal: 0,
      rentalsCompsTotal: 0,
      filterComps,
    }
  }, shallow)

  const resultCount = activeTab === 'SALES'
    ? report.salesCompsTotal
    : report.rentalsCompsTotal

  const handleClearAllFilters = () => {
    /**
     * Set all to default values
     */
    void report.filterComps({
      soldWithinMonths: {
        max: SoldWithinRange.default[1],
        range: SoldWithinRange.default,
      },
      distanceMiles: {
        subdivision: null,
        max: CMA.DEFAULT_DISTANCE_MILES_MAX,
      },
      boundary: null,
      yearBuilt: {
        range: PartialRange.EMPTY,
      },
      bedroomsCount: {
        range: PartialRange.EMPTY,
        shouldMatchSubjectProperty: false,
      },
      bathroomsCount: {
        range: PartialRange.EMPTY,
        shouldMatchSubjectProperty: false,
      },
      livingAreaSqft: {
        range: PartialRange.EMPTY,
      },
      lotAreaSize: {
        sqftRange: PartialRange.EMPTY,
        unit: 'sqft',
      },
      garageSpacesCount: {
        range: PartialRange.EMPTY,
        shouldExcludeAll: false,
        shouldMatchSubjectProperty: false,
        type: null,
      },
      poolsCount: {
        availablility: 'any',

        // @GENESIS
        shouldExcludeAll: false,
        shouldMatchSubjectProperty: false,
        type: null,
      },
    })
  }

  return (
    <CompsFilterPanelDumb
      isPanelOpen={filterPanel.isPanelOpen}
      clearAllFilters={handleClearAllFilters}
      closePanel={filterPanel.closePanel}
      resultCount={resultCount}
    >
      <HomeTypeFilterCard />

      <Breakpoint
        mobSm={<ShowFilterCard />}
        tabSm={<></>}
      />

      <SoldWithinFilterCard />
      <DistanceFilterCard />
      <YearBuiltFilterCard />
      <BedsFilterCard />
      <BathsFilterCard />
      <HouseSizeFilterCard />
      <LotSizeFilterCard />
      <GarageFilterCard />

      {/* @GENESIS */}
      {/* <FireplaceFilterCard /> */}
      {/* <StoriesFilterCard /> */}
      {/* <BasementFilterCard /> */}

      <PoolFilterCard />

      {/* @GENESIS */}
      {/* <ConstructionFilterCard /> */}
      {/* <FoundationFilterCard /> */}
    </CompsFilterPanelDumb>
  )
}
