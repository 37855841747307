import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { PartialRange } from 'features/valueObjects/Range'
import { BedsAndBathsFilterDropdownDumb } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/BedsAndBathsFilterDropdown/BedsAndBathsFilterDropdown.dumb'
import { BathsFilterProps, BedsFilterProps } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { shallow } from 'zustand/shallow'

export const BedsAndBathsFilterDropdown = () => {
  const bedsFilter = useBedsData()
  const bathsFilter = useBathsData()

  if (!bedsFilter || !bathsFilter) return null

  return (
    <BedsAndBathsFilterDropdownDumb
      bedsFilter={bedsFilter}
      bathsFilter={bathsFilter}
    />
  )
}

const useBedsData = (): BedsFilterProps | null => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.bedroomsCount ?? null,
      filterValue: state.local.report.data.filters.bedroomsCount,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: BedsFilterProps['onChange'] = filter => {
    void filterComps({
      bedroomsCount: filter,
    })
  }

  if (!isReady) return null

  return {
    value: filterValue || {
      range: PartialRange.EMPTY,
      shouldMatchSubjectProperty: false,
    },
    onChange: handleOnValueChange,
    subjectValue,
  }
}

const useBathsData = (): BathsFilterProps | null => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.bathroomsCountInfo?.full ?? null,
      filterValue: state.local.report.data.filters.bathroomsCount,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: BathsFilterProps['onChange'] = filter => {
    void filterComps({
      bathroomsCount: filter,
    })
  }

  if (!isReady) return null

  return {
    value: filterValue || {
      range: PartialRange.EMPTY,
      shouldMatchSubjectProperty: false,
    },
    onChange: handleOnValueChange,
    subjectValue,
  }
}
