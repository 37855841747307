import { useMediaQuery } from '@chakra-ui/react'
import { Array, HashSet, pipe } from 'effect'
import { isNull } from 'effect/Predicate'
import { CMA } from 'features/CMA/CMA.domain'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { useSwitchBreakpoint, useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { FilterMenu, FilterMenuButton, FilterMenuList } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterMenu'
import { HomeTypeFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/HomeTypeFilterDropdown/HomeTypeFilterDropdown.dumb.props'
import { FilterContentGroup } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/FilterContentGroup'
import { HomeTypeFilterOption } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption'
import { HomeTypeIconSize } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption.props'
import { HomeTypeFilterOptionGrid } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOptionGrid'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError'

export const HomeTypeFilterDropdownDumb = (
  props: HomeTypeFilterDropdownDumbProps,
) => {
  const value = HashSet.fromIterable(props.value.types)
  const [isWide1920px] = useMediaQuery('(min-width: 1920px)')
  const [is2k] = useMediaQuery('(min-width: 2048px)')
  const [is4k] = useMediaQuery('(min-width: 2560px)')
  const { sbp } = useSwitchBreakpointFn()

  const buttonMaxW = sbp({
    tabSm: '136px',
    dtLg: is4k
      ? 'full'
      : is2k
        ? '392px'
        : isWide1920px
          ? '248px'
          : '136px',
  })

  const optionSize: HomeTypeIconSize = useSwitchBreakpoint({
    mobSm: 'sm',
    tabSm: 'lg',
  }) ?? 'sm'

  const valueString = HashSet.size(value) === 0
    ? 'Any Type'
    : HashSet.size(value) > 1
      ? pipe(
        Array.fromIterable(value),
        Array.map(v => HomeType.toStringUnstandardized(v)),
        Array.join(', '),
      )
      : HomeType.toStringUnstandardized(props.value.types[0]) ?? 'Any Type'

  const handleSelectionChange = (
    isSelected: boolean,
    type: HomeType | null,
  ) => {
    const newValue: CMA.Filters.HomeType = {
      ...props.value,
      types: isNull(type)
        ? []
        : Array.fromIterable(isSelected
          ? HashSet.add(value, type)
          : HashSet.remove(value, type)),
    }

    props.onChange(newValue)
  }

  const isSelected = (type: HomeType | null) =>
    HashSet.has(value, type)

  const homeTypesOptions: HomeType[] = [
    'single-family',
    'mobile',
    'condo',
    'town-house',
    'duplex-to-fourplex',
    'multi-family',
    'commercial',
    'farm',
    'lot-or-land',
  ]

  return (
    <FilterMenu>
      <FilterMenuButton
        label='Prop Type'
        minW='136px'
        maxW={buttonMaxW}
      >
        {valueString}
      </FilterMenuButton>
      <FilterMenuList
        menuListProps={{
          w: 'max-content',
        }}
      >
        <FilterContentGroup
          title='Property Type'
          pl={2}
        >
          <HomeTypeFilterOptionGrid
            gridTemplateColumns='repeat(6, 1fr)'
            sx={{
              '& > div': {
                minW: '112px',
              },
              '& > div:nth-of-type(1), & > div:nth-of-type(2), & > div:nth-of-type(3)': {
                gridColumn: 'span 2',
              },
              '& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)': {
                gridColumn: 'span 2',
              },
              '& > div:nth-of-type(7), & > div:nth-of-type(9)': {
                gridColumn: '2 / span 2',
              },
              '& > div:nth-of-type(8), & > div:nth-of-type(10)': {
                gridColumn: '4 / span 2',
              },
            }}
          >
            <HomeTypeFilterOption
              key='any'
              size={optionSize}
              variation={null}
              isSelected={HashSet.size(value) === 0}
              isSubjectProperty={false}
              onSelectionChange={isSelected => handleSelectionChange(isSelected, null)}
            />

            {homeTypesOptions.map(type => (
              <HomeTypeFilterOption
                key={type}
                size={optionSize}
                variation={type}
                isSelected={isSelected(type)}
                isSubjectProperty={props.subjectValue === type}
                onSelectionChange={isSelected => handleSelectionChange(isSelected, type)}
              />
            ))}
          </HomeTypeFilterOptionGrid>
        </FilterContentGroup>

        {props.filterErrorMsg && (
          <CompsFilterCardError
            pl={2}
            error={props.filterErrorMsg}
          />
        )}
      </FilterMenuList>
    </FilterMenu>
  )
}
