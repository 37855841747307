import { Context } from 'effect'
import GetOwnerDeals from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals'
import GetOwnersPropertiesPreview from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview'

class OwnersPropertiesRepo extends Context.Tag('OwnersPropertiesRepo')<
  OwnersPropertiesRepo,
  {
    readonly getOwnersPropertiesPreview: GetOwnersPropertiesPreview
    readonly getOwnerDeals: GetOwnerDeals
  }
>() {}

namespace OwnersPropertiesRepo {
  export type Shape = Context.Tag.Service<OwnersPropertiesRepo>
}

export default OwnersPropertiesRepo
