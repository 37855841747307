import { fromPennies } from 'libs/Number/formatNumber'
import { UpgradeOption } from 'presentation/screens/Billing/components/ChoosePlanFlow/UpgradeOption'
import { FC } from 'react'
import { TargetPlan } from './ChoosePlanModal.types'

export type UpgradeToggleProps = {
  targetPlans: TargetPlan[]
  selectedPlanOption: TargetPlan
  onSelectedTargetChanged: (targetPlan: TargetPlan) => void
}

export const UpgradeToggle: FC<UpgradeToggleProps> = ({
  targetPlans,
  selectedPlanOption,
  onSelectedTargetChanged,
}) => {
  const monthly = targetPlans.find(plan => plan.intervalUnit === 'MONTH')
  const yearly = targetPlans.find(plan => plan.intervalUnit === 'YEAR')
  const discountAmount = monthly && yearly
    ? (monthly.price * 12) - yearly.price
    : 0

  return (
    <>
      {targetPlans.map(plan => (
        <UpgradeOption
          flex='1'
          key={plan.planId}
          intervalUnit={plan.intervalUnit}
          price={plan.price}
          discountAmount={plan.intervalUnit === 'YEAR' ? fromPennies(discountAmount) : 0}
          isSelected={selectedPlanOption.planId === plan.planId}
          onClick={() => onSelectedTargetChanged(plan)}
        />
      ))}
    </>
  )
}
