import { Box, Center, Flex, FlexProps, Link, Spinner, Text } from '@chakra-ui/react'
import { SolidCircleInfoIcon } from 'presentation/components/Icons/solid'
import { CardButton } from 'presentation/components/molecules/Card'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { ErrorModal } from 'presentation/main/globalModals/ErrorModal'
import { useSupportModal } from 'presentation/main/globalModals/SupportModal/SupportModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, PropsWithChildren, useEffect, useState } from 'react'

export const SupportModal: FC = () => {
  /** @TODO Not sure if we will reimplement "loading" in future */
  const [status, setStatus] = useState(SupportWidget.getStatus())

  useEffect(() => {
    const isMounted = true

    void SupportWidget.wait()
      .then(() => {
        if (isMounted)
          setStatus('success')
      })
      .catch(() => {
        if (isMounted)
          setStatus('error')
      })
  }, [])

  const title = status === 'error'
    ? 'Oops, Support can\'t be loaded'
    : 'Please wait while we try to load Support'

  const { isOpen, setIsOpen } = useSupportModal()

  useEffect(() => {
    if (status === 'success')
      setIsOpen(false)
  }, [status])

  return (
    <ErrorModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={title}
      message={(
        <Flex
          flexDirection='column'
          gap={mbp({ mobSm: '3', tabSm: '5' })}
        >
          {status === 'error'
            ? (
              <Text color='graystrong.200'>
                It appears that certain browser settings or extensions may be preventing the chat from loading.
              </Text>
            )
            : (
              <Center mt='4'>
                <Spinner size='md' />
              </Center>
            )}

          <Text color='neutral.500'>
            {status === 'error'
              ? (
                <> To access our chat support, please consider the following options: </>
              )
              : (
                <> In the meanwhile, please consider the following options that might block livechat: </>
              )}
          </Text>

          <Box
            shadow='primary.w'
            borderRadius='2'
            overflow='hidden'
          >
            <InfoRow bg='grayweak.200'>
              Disable any ad blockers or privacy extensions temporarily for this website.
            </InfoRow>
            <InfoRow bg='grayweak.100'>
              Try using a different browser for an uninterrupted chat experience.
            </InfoRow>
            <InfoRow bg='grayweak.200'>
              Alternatively, you can contact our support team at
              {' '}
              <Link href='mailto:support@propelio.com'>support@propelio.com</Link>
              {' '}
              for assistance.
            </InfoRow>
          </Box>
        </Flex>
      )}
      actionButtons={() => (
        <CardButton
          variant='solid'
          colorScheme='positive'
          autoFocus
          onClick={() => setIsOpen(false)}
        >
          OKAY
        </CardButton>
      )}
    />
  )
}

export const InfoRow: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => (
  <Flex
    p='2'
    alignItems='center'
    gap='2'
    justifyContent='flex-start'
    {...props}
  >
    <SolidCircleInfoIcon
      boxSize='3'
      color='neutral.500'
    />
    <Text
      textAlign='left'
      color='graystrong.200'
    >
      {children}
    </Text>
  </Flex>
)
