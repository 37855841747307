import { CMA } from 'features/CMA/CMA.domain'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { toast } from 'presentation/components/Toast'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

export const useRateErrorHandler = () => {
  const rateError = useCMAStore(state => {
    const lastError = state.actions.rateComp.state.lastError
    if (!lastError) return null
    const reportState = state.local.report
    if (!checkStateHasReportData(reportState)) return null
    const saleComps = reportState.data.salesListInfo.comps
    const rentalComps = reportState.data.rentalsListInfo.comps
    const erringComp = saleComps.find(comp => comp.id === lastError.compId)
      || rentalComps.find(comp => comp.id === lastError.compId)
    if (!erringComp) return null
    return {
      ...lastError,
      compAddressLine1: erringComp.address.line1,
    }
  }, shallow)

  useEffect(() => {
    if (!rateError) return

    const compsAddressPart = rateError.compAddressLine1 ?? 'a property'
    const originalRatingPart = ratingToText(rateError.originalRating)
    toast.error({
      title: 'Failed to rate a comparable',
      message: (
        <>
          “
          {compsAddressPart}
          ” was reverted to “
          {originalRatingPart}
          ”.
          <br />
          Please refresh to make sure your changes are saved.
        </>
      ),
    })
  }, [rateError])
}

const ratingToText = (rating: CMA.Comp.UserRating): string =>
  rating === 'included'
    ? 'thumbs up'
    : rating === 'excluded'
      ? 'thumbs down'
      : 'no rating'
