import { createSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { downloadResult } from 'features/Skiptrace/infra/remote/downloadResult/downloadResult'
import { getSkiptraceFundsInfo } from 'features/Skiptrace/infra/remote/getSkiptraceFundsInfo/getSkiptraceFundsInfo'
import { getSkiptraceResult } from 'features/Skiptrace/infra/remote/getSkiptraceResult/getSkiptraceResult'
import { performSkiptrace } from 'features/Skiptrace/infra/remote/performSkiptrace/performSkiptrace'
import { ErrorLib } from 'libs/errors'

export const skiptraceStore = createSkiptraceStore({
  getSkiptraceResult: ErrorLib.wrapReportOnReject(getSkiptraceResult),
  performSkiptrace: ErrorLib.wrapReportOnReject(performSkiptrace),
  downloadResult: ErrorLib.wrapReportOnReject(downloadResult),
  getSkiptraceFundsInfo: ErrorLib.wrapReportOnReject(getSkiptraceFundsInfo),
})
