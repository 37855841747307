import { SearchHistory as Domain } from 'features/SearchHistory/domain/SearchHistory.domain'
import { NormalizedHistory } from 'features/SearchHistory/infra/HistoryQuery/getHistory.types'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { EquityType } from 'features/valueObjects/EquityType'
import { Location } from 'features/valueObjects/Location'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import { PersonName } from 'features/valueObjects/PersonName'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { isNumber } from 'remeda'

export const mapHistory = ({
  rawData,
  limit,
}: {
  rawData: any
  limit: number
}): NormalizedHistory => {
  const total = NumberLib.fallback(0)(rawData?.count)

  return {
    total,
    totalPages: Math.ceil(total / limit),
    entries: Array.isArray(rawData?.models)
      ? rawData.models.map((model: any): Domain.Entry => {
        const details = model?.property || null
        const basicInfo = model?.values || null
        const bathFull = NumberLib.ensureNumberOrNull(details?.summary?.baths?.full)
        const bathHalf = NumberLib.ensureNumberOrNull(details?.summary?.baths?.half)

        return {
          id: model?.id || '',
          searchedAt: DateLib.fromIso(model?.modified),
          seeker: model?.creator || '',
          property: {
            id: model?.id || '',
            address: {
              line1: basicInfo?.address?.line1 || '',
              city: basicInfo?.address?.city || '',
              state: basicInfo?.address?.state || '',
              postalCode: basicInfo?.address?.zip || '',
            },
            location: Location.ensureOrNull({
              latitude: basicInfo?.address?.lat,
              longitude: basicInfo?.address?.lon,
            }),
            building: !details
              ? null
              : {
                bathroomsCount: {
                  full: bathFull,
                  half: bathHalf,
                  total: BathroomsCountInfo.getTotal({
                    full: bathFull,
                    half: bathHalf,
                  }),
                },
                bedroomsCount: NumberLib.ensureNumberOrNull(details?.summary?.beds),
                buildingSqft: NumberLib.ensureNumberOrNull(details?.summary?.sqft),
                garageCount: NumberLib.ensureNumberOrNull(details?.buildingDetail.garageSpaces),
                yearBuilt: NumberLib.ensureNumberOrNull(details?.summary?.yearBuilt),
              },
            land: !details
              ? null
              : {
                lotAreaSqft: NumberLib.ensureNumberOrNull(details?.landDetail?.lotArea),
                lotAreaAcres: NumberLib.ensureNumberOrNull(details?.landDetail?.lotAreaAcres),
              },
            valuation: !details
              ? null
              : {
                equity: NumberLib.ensureNumberOrNull(details?.summary?.equity),
                equityType: isNumber(details?.summary?.loanToValue)
                  ? EquityType.fromLtv(details?.summary?.loanToValue)
                  : null,
                lastSalePrice: NumberLib.ensureNumberOrNull(details?.summary?.lastSaleAmount),
                value: NumberLib.ensureNumberOrNull(details?.summary?.value),
              },
            ownership: !details
              ? null
              : {
                classification: mapClassification(details?.summary?.distressedCriteria?.ownerSituation),
                isForeclosure: !!details?.summary?.distressedCriteria?.isForeclosure,
                isVacant: !!details?.summary?.distressedCriteria?.isVacant,
                isSenior: !!details?.summary?.distressedCriteria?.isSenior,
                owners: details?.summary?.owners?.map((owner: any): Domain.Owner => ({
                  name: {
                    first: owner?.firstName || '',
                    last: owner?.lastName || '',
                    full: PersonName.combine(owner?.firstName, owner?.lastName) || '',
                  },
                })) || [],
              },
          },
        }
      })
      : [],
  }
}

const mapClassification = (classification: any): OwnerClassification | null => {
  switch (classification) {
  case 'trustowned':
    return 'TRUST'
  case 'bankowned':
    return 'BANK'
  case 'companyowned':
    return 'CORPORATE'
  case 'absentee':
    return 'IN_STATE'
  case 'owneroccupied':
    return 'OWNER_OCCUPIED'
  case 'outofstate':
    return 'OUT_OF_STATE'
  case 'outofcountry':
    return 'OUT_OF_COUNTRY'
  case 'military':
    return 'MILITARY'
  default:
    return null
  }
}
