import { BoxProps, Flex, useMultiStyleConfig } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { PaginationSideButton, PaginationStylesProvider } from 'presentation/components/Pagination/Pagination'
import { PaginationColorSchemes, PaginationSize } from 'presentation/components/Pagination/Pagination.theme'

export type PrevNextPaginationProps = BoxProps & {
  size: PaginationSize
  colorScheme?: PaginationColorSchemes
  onNext: () => void
  onPrev: () => void
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
  shouldUseChevron?: boolean
}

export const PrevNextPagination = ({
  size,
  colorScheme,
  onNext,
  onPrev,
  isPrevDisabled = false,
  isNextDisabled = false,
  shouldUseChevron,
  ...boxProps
}: PrevNextPaginationProps) => {
  const styles = useMultiStyleConfig('Pagination', { size, colorScheme })

  return (
    <PaginationStylesProvider value={styles}>
      <Flex sx={styles.container} justifyContent='end' {...boxProps}>
        <PaginationSideButton
          type='prev'
          onClick={onPrev}
          isDisabled={isPrevDisabled}
        >
          {shouldUseChevron
            ? <ChevronLeftIcon boxSize={2} />
            : 'Prev'}
        </PaginationSideButton>
        <PaginationSideButton
          type='next'
          onClick={onNext}
          isDisabled={isNextDisabled}
        >
          {shouldUseChevron
            ? <ChevronRightIcon boxSize={2} />
            : 'Next'}
        </PaginationSideButton>
      </Flex>
    </PaginationStylesProvider>
  )
}
