import { Box, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { LockIcon } from 'presentation/components/Icons'
import { Card } from 'presentation/components/molecules/Card'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { ClearBoundaryButton, DrawBoundaryButton } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundaryButtons'
import { useCompsBoundaryLinesFromFilters } from 'presentation/screens/CompsScreen/components/CompsBoundary/useCompsBoundaryLinesFromFilter'
import { DistanceFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/DistanceFilterDropdown/DistanceFilterDropdown.dumb.props'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError'
import { Miles } from 'presentation/screens/CompsScreen/components/common/Miles'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const DistanceFilterDropdownDumb = (
  props: DistanceFilterDropdownDumbProps,
) => {
  const linesFromFilters = useCompsBoundaryLinesFromFilters()
  const hasLinesFromFilters = linesFromFilters.length > 0
  const selectedLabel = hasLinesFromFilters
    ? 'Boundary Drawn'
    : Miles.roundOffThenToLabel(props.value.max)
  const status = CompsBoundary.useStore(state => state.status)
  const isDrawing = status === 'drawing'
  const shouldShowDrawButton = isDrawing || !hasLinesFromFilters
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  /** @GENESIS */
  // const handleOnMatchedToSubjectPropertyChange = () => {
  //   const value: CMA.Filters.DistanceMiles = {
  //     subdivision: null,
  //     max: CMA.DEFAULT_DISTANCE_MILES_MAX,
  //   }
  //   props.onChange(value)
  // }

  const handleMaxChange = (max: number) => {
    props.onChange({
      ...props.value,
      max,
    })
  }

  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <FilterPopover
      isOpen={isOpen}
      onOpen={onToggle}
      onClose={onClose}
    >
      <FilterPopoverButton
        label='Distance'
        minW={mbp({ mobSm: '120px', dtLg: '140px' })}
        onClick={onToggle}
        title={selectedLabel}
      >
        {selectedLabel}
      </FilterPopoverButton>
      <FilterPopoverContent
        popoverBodyProps={{
          py: 2,
        }}
      >
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
          h='5'
          lineHeight='5'
        >
          Distance
        </Text>
        {shouldShowDrawButton
          ? (
            <VStack
              mt={1}
              spacing={0.5}
              align='stretch'
            >
              {Miles
                .getOptions()
                .map(preset => (
                  <Box
                    key={preset}
                    as='button'
                    ml={-2}
                    pl={2}
                    textStyle='bodyMFat'
                    borderRightRadius='full'
                    color='graystrong.400'
                    height='40px'
                    textAlign='left'
                    {...(props.value.max === preset && {
                      bg: 'neutral.500',
                      color: 'ondark.1',
                    })}
                    onClick={() => {
                      handleMaxChange(preset)
                    }}
                  >
                    {Miles.roundOffThenToLabel(preset)}
                  </Box>
                ))}
            </VStack>
          )
          : (
            <Card
              bg='card.bg.1'
              p={3}
              borderRadius={3}
              boxShadow='primary.w'
            >
              <VStack
                spacing={1}
              >
                <LockIcon
                  fontSize={4}
                  color='neutral.500'
                  sx={{
                    path: {
                      strokeWidth: '1',
                    },
                  }}
                />

                <Text
                  textStyle='bodyMFat'
                  color='graystrong.200'
                  textAlign='center'
                >
                  You&rsquo;ve drawn a boundary on the map.
                  {' '}
                  To enable the dropdown, please clear your boundary.
                </Text>
              </VStack>
            </Card>
          )}

        {shouldShowDrawButton
          ? (
            <DrawBoundaryButton
              mt={2}
              w='full'
              onClick={() => {
                onClose()
                CompsBoundary.setStatus('drawing')
                CompsBoundary.setLines(linesFromFilters)
              }}
            />
          )
          : (
            <ClearBoundaryButton
              mt={2}
              onClick={() => {
                onClose()
                CompsBoundary.clearLines()
                void filterComps({ boundary: null })
              }}
            />
          )}

        {props.filterErrorMsg && (
          <CompsFilterCardError
            error={props.filterErrorMsg}
            ml={2}
          />
        )}
      </FilterPopoverContent>
    </FilterPopover>
  )
}
