import { createIcon } from '@chakra-ui/react'

export const OutlineHammerIcon = createIcon({
  displayName: 'OutlineHammerIcon',
  viewBox: '0 0 14 13',
  path: (
    <g fill='none' stroke='currentColor'>
      <path d='M7.80372 6.16986L7.15097 5.51686C7.00146 5.36731 6.79881 5.28304 6.58734 5.28247C6.37587 5.28191 6.17278 5.3651 6.02247 5.51386L1.48422 9.70211C1.31897 9.85786 1.21047 10.0564 1.20047 10.2786C1.19566 10.3888 1.21362 10.4989 1.25324 10.6018C1.29285 10.7048 1.35327 10.7985 1.43072 10.8771L2.40572 11.8629C2.40624 11.8635 2.40683 11.8641 2.40747 11.8646C2.55814 12.0148 2.76199 12.0994 2.97472 12.1001H3.00422C3.22647 12.0919 3.42547 11.9876 3.58347 11.8209L7.80097 7.30336C7.87576 7.22921 7.93517 7.14103 7.9758 7.04387C8.01643 6.94671 8.03747 6.84249 8.03773 6.73718C8.03798 6.63187 8.01744 6.52754 7.97729 6.43019C7.93713 6.33283 7.87815 6.24436 7.80372 6.16986V6.16986Z' />
      <path d='M7.80372 6.16986L7.15097 5.51686C7.00146 5.36731 6.79881 5.28304 6.58734 5.28247C6.37587 5.28191 6.17278 5.3651 6.02247 5.51386L1.48422 9.70211C1.31897 9.85786 1.21047 10.0564 1.20047 10.2786C1.19566 10.3888 1.21362 10.4989 1.25324 10.6018C1.29285 10.7048 1.35327 10.7985 1.43072 10.8771L2.40572 11.8629C2.40624 11.8635 2.40683 11.8641 2.40747 11.8646C2.55814 12.0148 2.76199 12.0994 2.97472 12.1001H3.00422C3.22647 12.0919 3.42547 11.9876 3.58347 11.8209L7.80097 7.30336C7.87576 7.22921 7.93517 7.14103 7.9758 7.04387C8.01643 6.94671 8.03747 6.84249 8.03773 6.73718C8.03798 6.63187 8.01744 6.52754 7.97729 6.43019C7.93713 6.33283 7.87815 6.24436 7.80372 6.16986V6.16986Z' />
      <path d='M13.0498 4.85015L13.042 4.8424L12.1853 3.9944C12.1351 3.94438 12.0755 3.9048 12.0099 3.87794C11.9444 3.85107 11.8741 3.83747 11.8033 3.8379C11.6885 3.83801 11.5766 3.87421 11.4835 3.9414C11.4835 3.93065 11.485 3.92015 11.4858 3.9109C11.497 3.7484 11.5145 3.5029 11.3558 3.2804C11.167 3.026 10.9598 2.78585 10.7358 2.5619C10.7352 2.56117 10.7345 2.5605 10.7338 2.5599C10.4008 2.2319 9.67603 1.61415 8.56578 1.1614C8.14472 0.988588 7.69392 0.899837 7.23878 0.900147C6.44978 0.900147 5.84978 1.25615 5.62003 1.46715C5.48994 1.5914 5.37129 1.72712 5.26553 1.87265C5.215 1.94239 5.18833 2.02658 5.18949 2.11269C5.19064 2.19881 5.21956 2.28225 5.27194 2.35061C5.32432 2.41897 5.39737 2.4686 5.48022 2.49212C5.56307 2.51564 5.65129 2.51179 5.73178 2.48115C5.80189 2.45494 5.87352 2.43298 5.94628 2.4154C6.0978 2.38335 6.2532 2.37367 6.40753 2.38665C6.73728 2.4139 7.12728 2.57765 7.29978 2.7139C7.59228 2.94915 7.73303 3.26615 7.75628 3.74115C7.76078 3.83665 7.56328 4.19465 7.25628 4.60315C7.1978 4.68024 7.16925 4.77592 7.17591 4.87245C7.18258 4.96898 7.22401 5.05983 7.29253 5.12815L8.15278 5.9884C8.22507 6.06086 8.32238 6.10285 8.42469 6.10573C8.527 6.10862 8.62653 6.07217 8.70278 6.0039C8.94603 5.78665 9.31653 5.45915 9.44603 5.3789C9.63828 5.26065 9.77578 5.2379 9.81353 5.2339C9.91149 5.22429 10.01 5.24506 10.0958 5.2934C10.0962 5.29768 10.0957 5.302 10.0944 5.30609C10.0931 5.31018 10.0909 5.31394 10.088 5.31715L10.0425 5.3604L10.035 5.3674C9.98475 5.41749 9.94489 5.47703 9.91772 5.54259C9.89056 5.60815 9.87663 5.67844 9.87675 5.74941C9.87686 5.82038 9.89102 5.89062 9.9184 5.95609C9.94578 6.02156 9.98584 6.08097 10.0363 6.1309L10.8928 6.97865C10.943 7.02863 11.0026 7.06818 11.0681 7.09499C11.1337 7.12181 11.2039 7.13538 11.2748 7.1349C11.417 7.13512 11.5537 7.07945 11.6553 6.9799L13.0428 5.6094C13.0475 5.60465 13.0523 5.59965 13.0568 5.59465C13.1499 5.49261 13.2009 5.35913 13.1996 5.22102C13.1983 5.08291 13.1448 4.95041 13.0498 4.85015V4.85015Z' />
      <path d='M13.0498 4.85015L13.042 4.8424L12.1853 3.9944C12.1351 3.94438 12.0755 3.9048 12.0099 3.87794C11.9444 3.85107 11.8741 3.83747 11.8033 3.8379C11.6885 3.83801 11.5766 3.87421 11.4835 3.9414C11.4835 3.93065 11.485 3.92015 11.4858 3.9109C11.497 3.7484 11.5145 3.5029 11.3558 3.2804C11.167 3.026 10.9598 2.78585 10.7358 2.5619C10.7352 2.56117 10.7345 2.5605 10.7338 2.5599C10.4008 2.2319 9.67603 1.61415 8.56578 1.1614C8.14472 0.988588 7.69392 0.899837 7.23878 0.900147C6.44978 0.900147 5.84978 1.25615 5.62003 1.46715C5.48994 1.5914 5.37129 1.72712 5.26553 1.87265C5.215 1.94239 5.18833 2.02658 5.18949 2.11269C5.19064 2.19881 5.21956 2.28225 5.27194 2.35061C5.32432 2.41897 5.39737 2.4686 5.48022 2.49212C5.56307 2.51564 5.65129 2.51179 5.73178 2.48115C5.80189 2.45494 5.87352 2.43298 5.94628 2.4154C6.0978 2.38335 6.2532 2.37367 6.40753 2.38665C6.73728 2.4139 7.12728 2.57765 7.29978 2.7139C7.59228 2.94915 7.73303 3.26615 7.75628 3.74115C7.76078 3.83665 7.56328 4.19465 7.25628 4.60315C7.1978 4.68024 7.16925 4.77592 7.17591 4.87245C7.18258 4.96898 7.22401 5.05983 7.29253 5.12815L8.15278 5.9884C8.22507 6.06086 8.32238 6.10285 8.42469 6.10573C8.527 6.10862 8.62653 6.07217 8.70278 6.0039C8.94603 5.78665 9.31653 5.45915 9.44603 5.3789C9.63828 5.26065 9.77578 5.2379 9.81353 5.2339C9.91149 5.22429 10.01 5.24506 10.0958 5.2934C10.0962 5.29768 10.0957 5.302 10.0944 5.30609C10.0931 5.31018 10.0909 5.31394 10.088 5.31715L10.0425 5.3604L10.035 5.3674C9.98475 5.41749 9.94489 5.47703 9.91772 5.54259C9.89056 5.60815 9.87663 5.67844 9.87675 5.74941C9.87686 5.82038 9.89102 5.89062 9.9184 5.95609C9.94578 6.02156 9.98584 6.08097 10.0363 6.1309L10.8928 6.97865C10.943 7.02863 11.0026 7.06818 11.0681 7.09499C11.1337 7.12181 11.2039 7.13538 11.2748 7.1349C11.417 7.13512 11.5537 7.07945 11.6553 6.9799L13.0428 5.6094C13.0475 5.60465 13.0523 5.59965 13.0568 5.59465C13.1499 5.49261 13.2009 5.35913 13.1996 5.22102C13.1983 5.08291 13.1448 4.95041 13.0498 4.85015V4.85015Z' />
    </g>
  )
  ,
})
