import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SearchEntryMarker } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/SearchEntryMarker'
import { SearchEntryMarkerProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/SearchEntryMarker/SearchEntryMarker.props'
import { PointLike, Popup } from 'react-map-gl'

export const SearchEntryMapMarker = (props: SearchEntryMarkerProps) => {
  const { entry } = props

  const { sbp } = useSwitchBreakpointFn()

  const maxWidth = sbp({ mobSm: '272px', tabSm: '336px' })

  /**
   * This offset is relative to the marker
   */
  const offset: Record<string, PointLike> = {
    'bottom': [0, -60],
    'right': [-20, -20],
    'left': [20, -20],
    'bottom-right': [0, -60],
    'bottom-left': [0, -60],
  }

  if (!entry.property.location) return null

  const longitude = entry.property.location.longitude
  const latitude = entry.property.location.latitude

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeOnClick={false}
      maxWidth={maxWidth}
      offset={offset}
      style={{ zIndex: MAP_ELEMENT__Z_INDICES.POPUP }}
    >
      <SearchEntryMarker entry={entry} />
    </Popup>
  )
}
