import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { Option } from 'effect'
import MayOwnMorePropsButton from 'features/OwnersProperties/view/components/MayOwnMorePropsButton'
import NameWithSkip from 'features/OwnersProperties/view/components/NameWithSkip'
import TruncatedOwnerList from 'features/OwnersProperties/view/components/TruncatedOwnerList'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SkiptraceSuccessIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { Tooltip } from 'presentation/components/Tooltip'
import { Card, CardBody, CardHeader } from 'presentation/components/molecules/Card'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { usePropertyDetailsActions } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsActionsContext'
import { useOwnershipViewModel } from 'presentation/screens/PropertyDetailsScreen/components/OwnershipSection/useViewOwnershipData'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const OwnershipSection = () => {
  const viewModel = useOwnershipViewModel()

  if (viewModel === null) return null

  const {
    formattedAddressOneLine,
    formattedYear,
    formattedStatus,
    owners,
    equityType,
    status,
    isSenior,
    isForeclosure,
    isVacant,
  } = viewModel

  return (
    <Card
      size='xs-locked'
      shadow='primary.s'
      position='relative'
      w='full'
    >
      <CardHeader>
        <HStack justifyContent='space-between' alignItems='center'>
          <Text textStyle='bodyXLFat' color='accent.blue-text'>
            <Breakpoint
              mobSm='Owners'
              mob='Property Owners'
            />
          </Text>
          <HStack spacing='1'>
            {viewModel.potentialPropertiesCount.pipe(Option.match({
              onNone: () => null,
              onSome: () => (
                <Breakpoint
                  dtLg={(
                    <MayOwnMorePropsButton
                      count={viewModel.potentialPropertiesCount}
                    />
                  )}
                />
              ),
            }))}
            <SkiptraceButton />
          </HStack>
        </HStack>
        {viewModel.potentialPropertiesCount.pipe(Option.match({
          onNone: () => null,
          onSome: () => (
            <Breakpoint
              mobSm={(
                <MayOwnMorePropsButton
                  count={viewModel.potentialPropertiesCount}
                  ml={-1}
                />
              )}
              dtLg={<></>}
            />
          ),
        }))}
      </CardHeader>
      <CardBody shouldResetSpacing mt={mbp({ mobSm: 2, mob: 4 })}>
        <Grid
          gridTemplateColumns='repeat(2, minmax(0, 1fr))'
          {...mbpg({
            mobSm: {
              rowGap: 2,
              columnGap: 3,
            },
            mob: {
              rowGap: 3,
            },
            dtSm: {
              columnGap: 2,
            },
          })}
        >
          <Pair size='md'>
            <PairKey>
              Name
            </PairKey>
            <PairValue
              as={TruncatedOwnerList}
              sx={{
                /**
                 * Move "and N more" below owners name on smaller
                 * viewports
                 */
                '& > div.truncated-owner-list__item__has-more': {
                  'flexDir': {
                    base: 'column',
                    dtLg: 'row',
                  },
                  '& .truncated-owner-list__and-more-button': {
                    ml: {
                      base: 3.5,
                      dtLg: 0,
                    },
                  },
                },
              }}
            >
              {owners.length > 0
                ? owners.map((owner, i) => (
                  <NameWithSkip key={i}>
                    {/** TODO: wire isSkipped */}
                    {owner.name.full}
                  </NameWithSkip>
                ))
                : '--'}
            </PairValue>
          </Pair>
          <Pair size='md'>
            <PairKey>
              Owner&apos;s Address
            </PairKey>
            <PairValue as={Box}>
              <Tooltip label={formattedAddressOneLine}>
                <Text color='inherit' noOfLines={2}>
                  {formattedAddressOneLine}
                </Text>
              </Tooltip>
            </PairValue>
          </Pair>
          <Pair size='md'>
            <PairKey>
              Years of Ownership
            </PairKey>
            <PairValue>{formattedYear}</PairValue>
          </Pair>
          <Pair size='md'>
            <PairKey as={Flex} gap={0.5}>
              <Text color='inherit'>Status</Text>
              <PropertyMarker
                markerType='tag'
                classification={status}
                equityType={equityType}
                isSenior={isSenior}
                isVacant={isVacant}
                isForeclosure={isForeclosure}
                size='xxs'
              />
            </PairKey>
            <PairValue as={Box}>
              {formattedStatus
                ? (
                  <Tooltip label={formattedStatus}>
                    <Text color='inherit' noOfLines={2}>
                      {formattedStatus}
                    </Text>
                  </Tooltip>
                )
                : '--'}
            </PairValue>
          </Pair>
        </Grid>
      </CardBody>
    </Card>
  )
}

const SkiptraceButton = () => {
  const leadId = useLeadId()
  const { goToSkiptrace } = usePropertyDetailsActions()

  if (leadId === null) return null

  const icon = (
    <Breakpoint
      mob={<SkiptraceSuccessIcon boxSize={3} />}
    />
  )
  return (
    <Button
      onClick={goToSkiptrace}
      variant='solid'
      colorScheme='positive'
      size='md'
      leftIcon={icon}
    >
      Skiptrace
    </Button>
  )
}
