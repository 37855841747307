import { Data, Option } from 'effect'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import OwnersPropertiesMachine from 'features/OwnersProperties/view/machine/OwnersPropertiesMachine'
import { Address } from 'features/valueObjects/Address'
import { createContext, useContext } from 'react'
import { MapRef } from 'react-map-gl'

type OwnersPropertiesHooks = OwnersPropertiesHooks.OwnerPropertiesHooks

namespace OwnersPropertiesHooks {
  // #region Requirements
  export type AsyncInputViewModel = {
    onEmpty: () => void
    onError: () => void
    onSuccess: (requirements: OwnersPropertiesMachine.GetRequirementsSuccessPayload) => void
  }
  export type UseAsyncInput = () => AsyncInputViewModel
  export const useAsyncInput: UseAsyncInput = () => useContext(Context).useAsyncInput()
  // #endregion

  // #region Screen
  export type ScreenViewModel = Data.TaggedEnum<{
    Loading: {}
    Success: {
      potentialPropertiesCount: Option.Option<number>
    }
    Error: {}
  }>
  export const ScreenViewModel = Data.taggedEnum<ScreenViewModel>()
  export type UseScreen = () => ScreenViewModel
  export const useScreen: UseScreen = () => useContext(Context).useScreen()
  // #endregion

  // #region OwnerList
  export type OwnersListViewModel = {
    ownerIds: string[]
  }
  export type UseOwnersList = () => OwnersListViewModel
  export const useOwnersList: UseOwnersList = () => useContext(Context).useOwnersList()
  // #endregion

  // #region OwnerRow
  export type OwnerViewModel = {
    owner: OwnersProperties.Owner
    onAddressClick: () => void
  }
  export type UseOwner = (ownerId: string) => Option.Option<OwnerViewModel>
  export const useOwner: UseOwner = (ownerId: string) => useContext(Context).useOwner(ownerId)
  // #endregion

  // #region OwnerTabSm
  export type OwnerTabSmViewModel = {
    isLastRow: boolean
    isExpanded: boolean
    onToggleExpand: () => void
  }
  export type UseOwnerTabSm = (ownerId: string) => Option.Option<OwnerTabSmViewModel>
  export const useOwnerTabSm: UseOwnerTabSm = ownerId => useContext(Context).useOwnerTabSm(ownerId)
  // #endregion

  // #region OwnerMobSm
  export type OwnerItemMobileViewModel = {
    onOwnerDealsOpenMobile: () => void
  }
  export type UseOwnerMobSm = (ownerId: string) => OwnerItemMobileViewModel
  export const useOwnerMobSm: UseOwnerMobSm = ownerId => useContext(Context).useOwnerMobSm(ownerId)
  // #endregion

  // #region OwnerDeals
  export type OwnerDealsViewModel = Data.TaggedEnum<{
    Loading: {}
    Success: {
      dealIds: string[]
      displayedPropertiesCount: number
    }
    Error: {}
  }>
  export const OwnerDealsViewModel = Data.taggedEnum<OwnerDealsViewModel>()
  export type UseOwnerDeals = (ownerId: string) => Option.Option<OwnerDealsViewModel>
  export const useOwnerDeals: UseOwnerDeals = ownerId => useContext(Context).useOwnerDeals(ownerId)
  // #endregion

  // #region OwnerDealsMobSm
  export type OwnerDealsMobSmViewModel = Data.TaggedEnum<{
    Loading: {}
    Success: {
      ownerId: string
      dealIds: string[]
      displayedPropertiesCount: number
      onBackToOwnerProperties: () => void
    }
    Error: {}
  }>
  export const OwnerDealsMobSmViewModel = Data.taggedEnum<OwnerDealsMobSmViewModel>()
  export type UseOwnerDealsMobSm = () => Option.Option<OwnerDealsMobSmViewModel>
  export const useOwnerDealsMobSm: UseOwnerDealsMobSm = () => useContext(Context).useOwnerDealsMobSm()
  // #endregion

  // #region OwnerDeal
  export type OwnerDealViewModel = {
    relation: OwnersProperties.DealRelation
    onAddressClick: () => void
  }
  export type UseOwnerDeal = (ownerId: string, dealId: string) => Option.Option<OwnerDealViewModel>
  export const useOwnerDeal: UseOwnerDeal = (ownerId, dealId) => useContext(Context).useOwnerDeal(ownerId, dealId)
  // #endregion

  // #region Map
  export type MapViewModel = {
    onMount: (ref: MapRef) => void
    onUnmount: () => void
  }
  export type UseMap = (ownerId: string) => Option.Option<MapViewModel>
  export const useMapView: UseMap = ownerId =>
    useContext(Context).useMap(ownerId)
  // #endregion

  // #region BackButton
  export type BackButtonViewModel = {
    onBack: () => void
  }
  export type UseBackButton = () => BackButtonViewModel
  export const useBackButton: UseBackButton = () => useContext(Context).useBackButton()
  // #endregion

  // #region SkiptraceButton
  export type SkiptraceButtonViewModel = {
    onSkiptrace: () => void
  }
  export type UseSkiptraceButton = () => SkiptraceButtonViewModel
  export const useSkiptraceButton = () => useContext(Context).useSkiptraceButton()
  // #endregion

  // #region OwnersInfo
  export type OwnersInfoViewModel = {
    onOpenAddress: () => void
    ownership: OwnersProperties.OwnershipInfo
    owners: OwnersProperties.Owner[]
  }
  export type UseOwnersInfo = () => Option.Option<OwnersInfoViewModel>
  export const useOwnersInfo: UseOwnersInfo = () => useContext(Context).useOwnersInfo()
  // #endregion

  // #region AddressButton
  export type AddressButtonViewModel = {
    onClick: (payload: { address: Address } | { parcelId: string }) => void
  }
  export type UseAddressButton = () => AddressButtonViewModel
  export const useAddressButton: UseAddressButton = () => useContext(Context).useAddressButton()
  // #endregion

  // #region Redirect
  export type RedirectViewModel = {
    ownersPropertiesPath: Option.Option<string>
  }
  export type UseRedirect = () => RedirectViewModel
  export const useRedirect: UseRedirect = () => useContext(Context).useRedirect()
  // #endregion

  // #region ToggleLimitToCurrentlyOwned
  export type ToggleLimitToCurrentlyOwnedViewModel = {
    shouldLimitToCurrentlyOwned: boolean
    onToggle: () => void
  }
  export type UseToggleLimitToCurrentlyOwned = (ownerId: string) => Option.Option<ToggleLimitToCurrentlyOwnedViewModel>
  export const useToggleLimitToCurrentlyOwned: UseToggleLimitToCurrentlyOwned = ownerId =>
    useContext(Context).useToggleLimitToCurrentlyOwned(ownerId)

  // #region type
  export type OwnerPropertiesHooks = {
    useAsyncInput: UseAsyncInput
    useScreen: UseScreen
    useOwnersList: UseOwnersList
    useOwner: UseOwner
    useOwnerTabSm: UseOwnerTabSm
    useOwnerMobSm: UseOwnerMobSm
    useOwnerDeals: UseOwnerDeals
    useOwnerDealsMobSm: UseOwnerDealsMobSm
    useOwnerDeal: UseOwnerDeal
    useMap: UseMap
    useBackButton: UseBackButton
    useSkiptraceButton: UseSkiptraceButton
    useOwnersInfo: UseOwnersInfo
    useAddressButton: UseAddressButton
    useRedirect: UseRedirect
    useToggleLimitToCurrentlyOwned: UseToggleLimitToCurrentlyOwned
  }
  // #endregion

  // #region Context
  const Context = createContext<OwnerPropertiesHooks>({
    useAsyncInput: () => { throw new Error('Missing hook implementation') },
    useScreen: () => { throw new Error('Missing hook implementation') },
    useOwnersList: () => { throw new Error('Missing hook implementation') },
    useOwner: () => { throw new Error('Missing hook implementation') },
    useOwnerTabSm: () => { throw new Error('Missing hook implementation') },
    useOwnerMobSm: () => { throw new Error('Missing hook implementation') },
    useOwnerDealsMobSm: () => { throw new Error('Missing hook implementation') },
    useOwnerDeals: () => { throw new Error('Missing hook implementation') },
    useOwnerDeal: () => { throw new Error('Missing hook implementation') },
    useMap: () => { throw new Error('Missing hook implementation') },
    useBackButton: () => { throw new Error('Missing hook implementation') },
    useSkiptraceButton: () => { throw new Error('Missing hook implementation') },
    useOwnersInfo: () => { throw new Error('Missing hook implementation') },
    useAddressButton: () => { throw new Error('Missing hook implementation') },
    useRedirect: () => { throw new Error('Missing hook implementation') },
    useToggleLimitToCurrentlyOwned: () => { throw new Error('Missing hook implementation') },
  })

  export const Provider = Context.Provider
  // #endregion
}

export default OwnersPropertiesHooks
