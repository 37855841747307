import { Button } from '@chakra-ui/react'
import { TrashIcon } from 'presentation/components/Icons'
import { DeleteModal } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertyDeleteAction/PropertyDeleteAction'
import { useState } from 'react'

const RemoveFromHistoryAction = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button
        variant='outline'
        colorScheme='negative'
        bgColor='card.bg.1'
        leftIcon={<TrashIcon />}
        onClick={() => setIsOpen(true)}
      >
        Remove from history
      </Button>
      <DeleteModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default RemoveFromHistoryAction
