import { Box, Center, ChakraProps, Flex, Spinner, Text, TextProps } from '@chakra-ui/react'
import { useRankTheme } from 'features/Billing/Plans/useRankTheme'
import { isNumber } from 'lodash/fp'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { HashLink } from 'presentation/components/HashLink'
import { toast } from 'presentation/components/Toast'
import { UserAvatar } from 'presentation/components/UserAvatarV2/UserAvatarV2'
import { Card, CardButton, CardFooter, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useRestorePlan } from 'presentation/main/globalModals/RestorePlanModal/useRestorePlan'
import { useCurrentPlanQuery } from 'presentation/screens/Billing/CurrentPlanSection/useCurrentPlanQuery'
import { PriceTag } from 'presentation/screens/Billing/components/PriceTag'
import { fadeIn } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

export type CurrentPlanSectionProps = ChakraProps

export const CurrentPlanSection: FC<CurrentPlanSectionProps> = ({ ...props }) => {
  const { data, error, loading } = useCurrentPlanQuery()

  const {
    restoreWithoutPayment,
    restoreWithPayment,
    loading: isRestorePlanLoading,
  } = useRestorePlan()

  const failedInvoiceSummary = data?.failedInvoiceSummary

  const handlePrimaryButtonClicked = () => {
    if (!failedInvoiceSummary)
      restoreWithoutPayment()
    else
      restoreWithPayment(failedInvoiceSummary)
  }

  useEffect(() => {
    if (!error) return

    toast({
      id: 'CURRENT_PLAN_ERROR',
      status: 'error',
      title: 'We couldn’t load the current plan.',
      message: error.message,
    })
  }, [error])

  const { mainColor } = useRankTheme(data?.rank ?? null)

  const content = loading || data === undefined
    ? (
      <Center w='full' py='4'><Spinner /></Center>
    )
    : (
      <Fragment>
        <PriceTag
          price={data.price}
          planInterval={data.planInterval}
          pos='absolute'
          top='-7.5px'
          right={mbp({ mobSm: '2', tabSm: '3', dtLg: '4' })}
          minW='70.5px'
          variant={data.rank || 'fallback'}
        />

        <CardPrimaryTitle>
          Current Plan
        </CardPrimaryTitle>

        <Flex
          mt={mbp({ mobSm: 1, tabSm: 2 })}
          gap={mbp({ mobSm: '2', dtLg: '4' })}
          alignItems={mbp({ mobSm: 'center', tabSm: 'center' })}
          justifyContent={mbp({ mobSm: 'center' })}
          px={mbp({ dtLg: '3' })}
          className={fadeIn}
          {...props}
        >
          <UserAvatar
            size={mbp({
              mobSm: 'lg',
              mob: 'xl',
            })}
            isMe
          />

          <Box>
            <SecondaryText color='graystrong.200'>
              {data.userName}
            </SecondaryText>

            <Text
              color={mainColor}
              textStyle={mbp({ mobSm: 'body-h-b', mob: 'h4-h-b', dtLg: 'h3-h-b' })}
              mt={mbp({ mobSm: '0.25', dtLg: '0.5' })}
            >
              {data.planName}
            </Text>

            {data.isCancelled
              ? (
                <SecondaryText color='negative.500' mt={mbp({ mobSm: '0', dtLg: '0.25' })}>
                  {isNumber(data.daysRemaining)
                    ? (
                      <>
                        PLAN CANCELS in
                        {' '}
                        {isNumber(data.daysRemaining) ? data.daysRemaining : 'UNKNOWN'}
                        {' '}
                        Day
                        {data.daysRemaining === 1 ? '' : 's'}
                        !
                      </>
                    )
                    : 'PLAN CANCELLED'}
                </SecondaryText>
              )
              : data.isTrialing
                ? (
                  <SecondaryText color='negative.500' mt={mbp({ mobSm: '0', dtLg: '0.25' })}>
                    TRIAL ENDS in
                    {' '}
                    {isNumber(data.daysRemaining) ? data.daysRemaining : 'UNKNOWN'}
                    {' '}
                    Day
                    {data.daysRemaining === 1 ? '' : 's'}
                    !
                  </SecondaryText>
                )
                : (
                  <SecondaryText color='graystrong.200' mt={mbp({ mobSm: '0', dtLg: '0.25' })}>
                    {isNumber(data.daysRemaining) ? data.daysRemaining : 'UNKNOWN'}
                    {' '}
                    day
                    {data.daysRemaining === 1 ? '' : 's'}
                    {' '}
                    until RENEWAL
                  </SecondaryText>
                )}
          </Box>
        </Flex>

        <CardActions
          isCancelled={data.isCancelled}
          isTrialing={data.isTrialing}
          onPrimaryButtonClicked={handlePrimaryButtonClicked}
          isPrimaryButtonLoading={isRestorePlanLoading}
        />
      </Fragment>
    )

  return (
    <Card
      variant='default'
      colorScheme='card.bg.1'
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', dtLg: 'md-locked' })}
      minH='152px'
      pos='relative'
      w='full'
      justifyContent='center'
      borderWidth={data?.isCancelled ? 0.25 : 0}
      borderColor='negative.500'
      className={fadeIn}
      {...props}
    >
      {content}
    </Card>
  )
}

const SecondaryText: FC<TextProps> = props => (
  <Text
    textStyle={mbp({ mobSm: 'body2', mob: 'body', dtLg: 'old.h4' })}
    mt={mbp({ mobSm: '0', dtLg: '0.25' })}
    {...props}
  />
)

type CardActionsProps = {
  isCancelled: boolean
  isTrialing: boolean
  isPrimaryButtonLoading?: boolean
  onPrimaryButtonClicked: () => void
} & ChakraProps

const CardActions: FC<CardActionsProps> = ({
  isCancelled,
  isPrimaryButtonLoading,
  onPrimaryButtonClicked,
  isTrialing,
}) => (
  <CardFooter horizontallyStackAt='mobSm'>
    {isCancelled
      ? (
        <CardButton
          w={mbp({ mobSm: 'full', tabSm: 'initial' })}
          variant='solid'
          colorScheme='positive'
          size={mbp({ mobSm: 'sm', dtLg: 'md' })}
          isLoading={isPrimaryButtonLoading}
          onClick={onPrimaryButtonClicked}
          spinnerPlacement='end'
          loadingText='Reactivating'
        >
          Reactivate Plan
        </CardButton>
      )
      : isTrialing
        ? (
          <>
            <CardButton
              variant='outline'
              colorScheme='negative'
              size={mbp({ mobSm: 'sm', dtLg: 'md' })}
              as={Link}
              to='/user/billing/cancel'
            >
              <Breakpoint
                mobSm={() => <>Cancel</>}
                mob={() => <>Cancel Plan</>}
                tabSm={() => <>Cancel</>}
                dtLg={() => <>Cancel Plan</>}
              />
            </CardButton>
            <CardButton
              w={mbp({ mobSm: 'full', tabSm: 'initial' })}
              variant='solid'
              colorScheme='positive'
              size={mbp({ mobSm: 'sm', dtLg: 'md' })}
              as={Link}
              to='/pricing#first-choose-plan'
            >
              Activate Your Plan
            </CardButton>
          </>
        )
        : (
          <>
            <CardButton
              variant='outline'
              colorScheme='negative'
              size={mbp({ mobSm: 'sm', dtLg: 'md' })}
              as={Link}
              to='/user/billing/cancel'
            >
              <Breakpoint
                mobSm={() => <>Cancel</>}
                mob={() => <>Cancel Plan</>}
                tabSm={() => <>Cancel</>}
                dtLg={() => <>Cancel Plan</>}
              />
            </CardButton>

            <CardButton
              variant='solid'
              colorScheme='neutral'
              size={mbp({ mobSm: 'sm', dtLg: 'md' })}
              as={HashLink}
              to='/pricing#first-choose-plan'
            >
              Change Plan
            </CardButton>
          </>
        )}
  </CardFooter>
)
