import { Breakpoint } from 'presentation/components/Breakpoint'
import { ListBuilderDummy } from 'presentation/screens/ListBuilderScreen/components/ListBuilderDummy'
import { ListBuilderDummyMobSm } from 'presentation/screens/ListBuilderScreen/components/ListBuilderDummyMobSm'

export const ListBuilderComingSoonScreen = () => (
  <Breakpoint
    mobSm={<ListBuilderDummyMobSm />}
    tabSm={<ListBuilderDummy />}
  />
)
