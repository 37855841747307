import { useCallback, useState } from 'react'

type Result = {
  isHovered: boolean

  // This MUST be called on target elements 'onmouseenter'
  onMouseEnter: () => void

  // This MUST be called on target elements 'onmouseleave'
  onMouseLeave: () => void
}

/**
 * Tracks isHovered state.
 *
 * Almost identical to React Use' `useHover` and `useHoverDirty` except it
 * detects hover state on mouseenter and mouseleave event, it's more stable if the
 * hoverable parents children structure changes, in which case you might find
 * that the hover state turns on and off erratically if you use `useHover`
 * or `userHoverDirty`
 *
 * This has been working better with Mapbox markers compared to `useHover`
 * and `useHoverDirty`
 *
 */
export const useMouseEnterLeaveHover = (): Result => {
  const [isHovered, setIsHovered] = useState(false)
  const onMouseEnter = useCallback((): void => {
    setIsHovered(true)
  }, [])

  const onMouseLeave = useCallback((): void => {
    setIsHovered(false)
  }, [])

  return { isHovered, onMouseEnter, onMouseLeave }
}
