import { Box } from '@chakra-ui/react'
import AppRuntime from 'main/AppRuntime'
import { ScrollAnimationProvider } from 'presentation/components/animation/ScrollAnimation'
import { useBillingDefaultNotice } from 'presentation/screens/Billing/BillingDefaultNotice'
import { DealSection } from 'presentation/screens/Plans/DealSection'
import { FAQ } from 'presentation/screens/Plans/FAQ/FAQ'
import { HelpSection } from 'presentation/screens/Plans/HelpSection/HelpSection'
import { HeroHeader } from 'presentation/screens/Plans/HeroHeader/HeroHeader'
import { useLegacyPlanNotice } from 'presentation/screens/Plans/LegacyPlanNotice'
import ChoosePlanSection from 'presentation/screens/Plans/PlanComparisonV2/ChoosePlanSection/ChoosePlanSection'
import PlanComparisonDetailed from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import UnlockBusinessSection from 'presentation/screens/Plans/PlanComparisonV2/UnlockBusinessSection/UnlockBusinessSection'
import { WaveFive, WaveFour, WaveOne, WaveSix, WaveThree, WaveTwo } from 'presentation/screens/Plans/Wave'
import './Plans.modals.register'

/**
 * @NOTE: These extra hooks should be able to add to story in the future.
 */
export const PricingScreenWithNotice = () => {
  useLegacyPlanNotice()
  useBillingDefaultNotice({
    breadcrumbs: [
      { label: 'Pricing' },
    ],
  })
  return (
    <PricingScreen />
  )
}

const PricingScreen = () => {
  const runtime = AppRuntime.useRuntime()
  return (
    <PlanComparisonMachine.Provider runtime={runtime}>
      <Box
        /**
         * Fancy, eh? This doesn't break position sticky's inside,
         * as opposed to hidden
         */
        overflowX='clip'
      >
        <ScrollAnimationProvider>
          <HeroHeader />
          <WaveOne />
          <ChoosePlanSection />
          <WaveTwo />
          <PlanComparisonDetailed />
          <WaveThree />
          <DealSection />
          <WaveFour />
          <UnlockBusinessSection />
          <WaveFive />
          <FAQ />
          {/** @HACK This container fixes extra scroll height during animation */}
          <Box overflow='hidden'>
            <WaveSix />
            <HelpSection />
          </Box>
        </ScrollAnimationProvider>
      </Box>
    </PlanComparisonMachine.Provider>
  )
}

export default PricingScreen
