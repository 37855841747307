import { Avatar, Box, BoxProps, FlexProps, HStack, Text, TextProps, VStack } from '@chakra-ui/react'
import classNames from 'classnames'
import { PersonName } from 'features/valueObjects/PersonName'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { MarkerSize } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { Tooltip } from 'presentation/components/Tooltip'
import { Card } from 'presentation/components/molecules/Card'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { animationClasses } from 'presentation/utils/animationClasses'
import { FC, useRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { useHoverDirty } from 'react-use'
import { isNonNullable } from 'utils/isNonNullable'
import { SearchEntryCardProps } from './SearchEntryCard.props'

export const SearchEntryCard = (props: SearchEntryCardProps) => {
  const {
    isHighlighted,
    onMouseEnter,
    onMouseLeave,
    onClick,
    entry,
  } = props

  /**
   * PropertyMarkerInfo
   */
  const classification = entry.property.ownership?.classification ?? null
  const equityType = entry.property.valuation?.equityType ?? null
  const isVacant = entry.property.ownership?.isVacant ?? false
  const isForeclosure = entry.property.ownership?.isForeclosure ?? false
  const isSenior = entry.property.ownership?.isSenior ?? false
  const owners = entry.property.ownership?.owners ?? []
  const seekerName = entry.seeker.name
  const seekerAvatarUrl = entry.seeker.avatarUrl

  /**
   * Property Address
   */
  const line1 = entry.property.address.line1
  const city = entry.property.address.city
  const state = entry.property.address.state
  const postalCode = entry.property.address.postalCode

  const markerSize = useSwitchBreakpoint<MarkerSize>({
    mobSm: 'xs',
    tabSm: 'sm',
  }) ?? 'xs'

  const borderColor = 'neutral.500'

  return (
    <Container
      id={`search-entry-${props.entry.id}`}
      isHovered={isHighlighted}
      borderColor={borderColor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={classNames(animationClasses.fadeIn)}
      transition={isHighlighted
        ? 'all 0.5s ease-in-out'
        : undefined}
      cursor='pointer'
      as={Link}
      to={`/search/${entry.id}`}
    >
      <HStack spacing={2} justifyContent='stretch'>
        <Box flexGrow={0}>
          <PropertyMarker
            classification={classification}
            equityType={equityType}
            isVacant={isVacant}
            isForeclosure={isForeclosure}
            isSenior={isSenior}
            size={markerSize}
            markerType='pin'
          />
        </Box>

        <VStack
          spacing={0}
          alignItems='start'
          minW={0}
          flexGrow={1}
        >
          <StreetAddressText>
            {' '}
            {line1}
            {' '}
          </StreetAddressText>
          <CityStateZipText>
            {' '}
            {city}
            ,
            {' '}
            {state}
            {' '}
            {postalCode}
            {' '}
          </CityStateZipText>
        </VStack>
      </HStack>

      <HStack
        mt={3}
        spacing={2}
        justifyContent='space-between'
        alignItems='start'
      >
        <OwnerSection isTruncated>
          {owners.map((ownerName, i) => (
            <TruncatedText color='inherit' key={i}>
              {PersonName.combine(ownerName.name)}
            </TruncatedText>
          ))}
        </OwnerSection>
        <SearchedBySection flex='1 1 50%' alignItems='end'>
          <Avatar
            name={PersonName.combine(seekerName) || undefined}
            size='md'
            src={seekerAvatarUrl ?? undefined}
          />
        </SearchedBySection>
      </HStack>
    </Container>
  )
}

const StreetAddressText: FC<TextProps> = ({ children, ...props }) => (
  <Tooltip label={children}>
    <Text
      textStyle='bodyMFat'
      color='accent.blue-text'
      textTransform='uppercase'
      noOfLines={1}
      {...props}
    >
      {children}
    </Text>
  </Tooltip>
)

const CityStateZipText: FC<TextProps> = ({ children, ...props }) => (
  <Tooltip label={children}>
    <Text
      textStyle='bodyM'
      color='graystrong.400'
      noOfLines={1}
      {...props}
    >
      {children}
    </Text>
  </Tooltip>
)

const OwnerSection: FC<FlexProps> = ({ children, ...props }) => (
  <Pair size='md' {...props}>
    <PairKey>
      Owner
    </PairKey>
    {/** @NOTE PairValue is a Text, set as Box to avoid invalid nesting of <p> */}
    <PairValue as={Box}>
      {children}
    </PairValue>
  </Pair>
)

const SearchedBySection: FC<FlexProps> = ({ children, ...props }) => (
  <Pair size='md' {...props}>
    <PairKey>
      Searched by
    </PairKey>
    {/** @NOTE PairValue is a Text, set as Box to avoid invalid nesting of <p> */}
    <PairValue as={Box}>
      {children}
    </PairValue>
  </Pair>
)

const TruncatedText: FC<TextProps> = ({ children, ...props }) => (
  <Tooltip label={children}>
    <Text {...props} isTruncated>
      {children}
    </Text>
  </Tooltip>
)

type ContainerProps = BoxProps & LinkProps & {
  isHovered?: boolean
}

const Container: FC<ContainerProps> = ({
  children,
  borderColor,
  isHovered: isHoveredDefault,
  ...props
}) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const isHoveredDirty = useHoverDirty(cardRef)
  const isHovered = isNonNullable(isHoveredDefault) ? isHoveredDefault : isHoveredDirty

  return (
    <Card
      ref={cardRef}
      variant='bordered'
      size='xxs-locked'
      shadow='primary.w'
      {...isHovered && { borderColor }}
      {...props}
    >
      {children}
    </Card>
  )
}
