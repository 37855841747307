import { Flex } from '@chakra-ui/react'
import { PartialRange } from 'features/valueObjects/Range'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { FilterContentGroup } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/FilterContentGroup'
import { PartialRangeFormErrors, PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

export type MinPropertiesFilter = {
  value: PartialRange
  onChange: (range: PartialRange) => void
}

export const MinPropertiesFilter = (props: MinPropertiesFilter) => {
  const [hasError, setHasError] = useState(false)

  const rangeString = PartialRange.checkHasValue(props.value)
    ? PartialRange.formatHumanReadable(props.value)
    : 'Any Props'

  const handleOnError = (errors: PartialRangeFormErrors) =>
    isNonNullable(errors.min || errors.max) ? setHasError(true) : setHasError(false)
  return (
    <FilterPopover>
      <FilterPopoverButton
        label='Minimum Prop'
        minW={mbp({ mobSm: '120px', dtLg: '140px' })}
        size={mbp({ mobSm: 'md', dtSm: 'lg' })}
        hasError={hasError}
      >
        {rangeString}
      </FilterPopoverButton>
      <FilterPopoverContent>
        <FilterContentGroup title='Minimum No. of Properties'>
          <Flex gap={3} flexDir='column' maxW='350px'>
            <PartialRangeInputV2
              initialValue={props.value}
              onDebouncedValidChange={newRange => {
                props.onChange(newRange)
              }}
              onError={handleOnError}
              shouldShowSubjectMarker={false}
            />
          </Flex>
        </FilterContentGroup>
      </FilterPopoverContent>
    </FilterPopover>
  )
}
