import { createIcon } from '@chakra-ui/react'

export const CardVisaDarkIcon = createIcon({
  displayName: 'CardVisaDarkIcon',
  viewBox: '0 0 47 32',
  path: (
    <>
      <rect width='46.6721' height='32' rx='4' fill='#E3C07F' />
      <path d='M19.9678 21.4437H17.3289L18.9794 11.2389H21.6182L19.9678 21.4437Z' fill='#9B793A' />
      <path d='M29.5333 11.4885C29.0128 11.282 28.1872 11.054 27.1664 11.054C24.5603 11.054 22.7252 12.4436 22.7139 14.4302C22.6923 15.8959 24.0278 16.7099 25.0267 17.1987C26.0477 17.698 26.3948 18.024 26.3948 18.4691C26.3844 19.1528 25.5698 19.4679 24.81 19.4679C23.7564 19.4679 23.1919 19.3054 22.334 18.9251L21.9865 18.762L21.6172 21.0528C22.2361 21.3347 23.3764 21.5849 24.5603 21.5959C27.3293 21.5959 29.132 20.2279 29.1533 18.1109C29.1639 16.9492 28.4586 16.0591 26.9382 15.3317C26.0152 14.8648 25.45 14.5499 25.45 14.0722C25.4608 13.6379 25.9281 13.193 26.97 13.193C27.8279 13.1712 28.4582 13.3774 28.9357 13.5837L29.1744 13.6921L29.5333 11.4885Z' fill='#9B793A' />
      <path d='M33.0419 17.8285C33.2592 17.2423 34.0955 14.9733 34.0955 14.9733C34.0845 14.9951 34.3123 14.3762 34.4427 13.9963L34.6271 14.8756C34.6271 14.8756 35.1268 17.3183 35.2353 17.8285C34.8229 17.8285 33.5631 17.8285 33.0419 17.8285ZM36.2993 11.2389H34.2581C33.6286 11.2389 33.1504 11.4233 32.8788 12.0856L28.959 21.4436H31.7279C31.7279 21.4436 32.1838 20.1841 32.2818 19.9128C32.5856 19.9128 35.2792 19.9128 35.67 19.9128C35.7457 20.2711 35.9849 21.4436 35.9849 21.4436H38.4283L36.2993 11.2389Z' fill='#9B793A' />
      <path d='M15.1245 11.2389L12.5401 18.1976L12.2576 16.7863C11.7798 15.1579 10.2814 13.3886 8.60913 12.5088L10.9763 21.4329H13.767L17.9149 11.2389H15.1245Z' fill='#9B793A' />
      <path d='M10.1401 11.2389H5.89427L5.85083 11.4451C9.16282 12.2921 11.3563 14.3336 12.2575 16.7874L11.3345 12.0968C11.1825 11.445 10.7156 11.2604 10.1401 11.2389Z' fill='#9B793A' />
    </>
  ),
})
