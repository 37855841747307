import { Box, BoxProps, Flex, Grid, GridItem, HStack, Text, useToken } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useTimedBoolean } from 'presentation/libs/useTimedBoolean'
import { CMAEntryTileCardProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/CMAEntryTileCard.props'
import { AddressSection } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/AddressSection'
import { CompPriceTag } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/CompsPriceTag'
import { DataSection } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/DataSection'
import { DistanceTag } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/DistanceTag'
import { PHOTOS_SLIDER_DEFAULT_HEIGHT, PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { PriceSection } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PriceSection'
import { StatusSection } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/StatusSection'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { createCompsEntryElementId } from 'presentation/screens/CompsScreen/utils/createEntryElementId'
import { SliderMinimizedSpinner } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMinimized'
import { IS_APPLE_TOUCH_DEVICE } from 'presentation/utils/isAppleTouchDevice'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { isNonNull } from 'remeda'

export const CMAEntryTileCard = (props: CMAEntryTileCardProps) => {
  const shouldAlwaysDisplayStats = IS_TOUCH_DEVICE

  const isHovering = props.isHighlighted ?? false

  const price = props.salePrice ?? props.listPrice

  const shouldShowPriceTag = price !== null || props.entryType === 'subject'

  const shouldShowMLSStatusTag = props.entryType === 'regular' && price === null

  const shouldShowSubjectStats = (shouldAlwaysDisplayStats || isHovering)
    && props.entryType !== 'subject'

  const topRightTag = pipe(
    Match.value(props),
    Match.when({ entryType: 'regular' }, entry => (
      <DistanceTag value={entry.distance} />
    )),
    Match.when({ entryType: 'subject' }, () => <SubjectPropertyTag />),
    Match.exhaustive,
  )

  const mlsIdAndBrokerData = [
    props.listingId,
    props.broker.name,
  ]
    .filter(isNonNull)
    .join(', ') || null
  const mlsIdAndBrokerDisplay = mlsIdAndBrokerData
    && `MLS ID: #${mlsIdAndBrokerData}`

  const INITIAL_DELAY = 500
  const isInitialDelayDone = useTimedBoolean(INITIAL_DELAY)

  const { sbp } = useSwitchBreakpointFn()

  return (
    <Container
      pointerEvents={isInitialDelayDone ? 'auto' : 'none'}
      isHovered={isHovering}
      id={createCompsEntryElementId(props.id)}
      status={props.status}
      isMuted={props.userRating === 'excluded'}
      cursor={isInitialDelayDone ? 'pointer' : 'default'}

      /**
       * @HACK using onMouseDown vs onClick for some reason fixes issue with
       *   layout breaking when clicking bottom part of tile when accessed from
       *   PropertyModal
       */
      onMouseDown={ev => {
        const isInsideButton = (ev.target as Element)?.closest('button') !== null

        // we want buttons to work as expected
        if (isInsideButton) return

        const isInsideSwiperButNotButton = (ev.target as Element)?.closest('.swiper') !== null
          && !isInsideButton

        // when it comes swiper, onMouseDown doesn't work (probably they did a preventDefault
        // or stopPropagation. In which case we don't fire the modal/onClick and allow the
        // onClick fire from actual onClick event (which happen to not break the layout)
        if (isInsideSwiperButNotButton) return

        props.onClick?.()
      }}
      {...!IS_APPLE_TOUCH_DEVICE && {
        onMouseEnter: () => props.onHoverEntry?.(props.id),
        onMouseLeave: () => props.onHoverOutEntry?.(props.id),
      }}
      onClick={ev => {
        const isInsideButton = (ev.target as Element)?.closest('button') !== null

        if (isInsideButton) return

        const isInsideSwiperButNotButton = (ev.target as Element)?.closest('.swiper') !== null
          && !isInsideButton

        if (isInsideSwiperButNotButton)
          props.onClick?.()
      }}
    >
      <>
        {/**
           * Comps Rating
           */}
        <CompsThumbButtons
          position='absolute'
          top={1}
          right={1}
          zIndex={4}
          comp={props}
          onCompsRatingChange={props.onCompsRatingChange}
          size={sbp({
            mobSm: 'sm',
            mob: 'md',
          })}
        />
        {/**
           * Top Section
           */}
        <Grid gridTemplateAreas='"main"' gridTemplateRows='minmax(0, min-content)'>
          <GridItem
            gridArea='main'
            minW={0}
            zIndex={1}
          >
            {isInitialDelayDone
              ? (
                <PhotosSlider
                  photos={props.photos}
                  shouldOpenFullscreenOnSlideClick={false}
                />
              )
              : (
                <SliderMinimizedSpinner height={PHOTOS_SLIDER_DEFAULT_HEIGHT} />
              )}
          </GridItem>

          {/**
             * Distance Tag
             */}
          <GridItem
            gridArea='main'
            m={1}
            justifySelf='start'
            alignSelf='start'
            zIndex={2}
          >
            {topRightTag}
          </GridItem>

          {/**
             * MLS Status
             */}
          {shouldShowMLSStatusTag && (
            <GridItem
              gridArea='main'
              justifySelf='end'
              alignSelf='end'
              zIndex={2}
              pr={1}
              mb='-12px'
            >
              <MLSStatusTag status={props.status} />
            </GridItem>
          )}

          {shouldShowPriceTag && (
            <CompPriceTag
              gridArea='main'
              justifySelf='end'
              alignSelf='end'
              zIndex={2}
              mr={1}
              mb='-12px'
              value={props.salePrice ?? props.listPrice}
              status={props.status}
            />
          )}
        </Grid>

        {/**
           * Bottom Section
            */ }
        <Flex
          flexDirection='column'
          gap={1}
          py={1.5}
          px={1}
        >
          <HStack spacing={1}>
            <AddressSection
              flex={1}
              {...props.address}
              classification={props.classification}
              equityType={props.equityType}
              isForeclosure={props.isForeclosure}
              isSenior={props.isSenior}
              isVacant={props.isVacant}
            />
            <StatusSection
              status={props.status}
              saleDate={props.saleDate}
            />
          </HStack>

          <DataSection
            shouldShowSubjectStats={shouldShowSubjectStats}
            {...props}
          />

          {/**
             * MLS ID Section
             */}
          {mlsIdAndBrokerDisplay
            ? (
              <Tooltip label={mlsIdAndBrokerDisplay}>
                <Text
                  textStyle='tagL'
                  color='grayweak.700'
                  textAlign='center'
                  isTruncated
                  mt={shouldAlwaysDisplayStats ? 2.5 : 0}
                >
                  {mlsIdAndBrokerDisplay}
                </Text>
              </Tooltip>
            )
            : (
              <Text
                textStyle='tagL'
                color='grayweak.700'
                textAlign='center'
                isTruncated
              >
                MLS ID: # --
              </Text>
            )}

          <PriceSection {...props} />
        </Flex>
      </>
    </Container>
  )
}

export const COMMON_CMA_ENTRY_TILE_CARD_STYLE = {
  h: 'full',
  w: 'full',
  borderRadius: 3,
  bgColor: 'card.bg.1',
}

const Container = (props: {
  status: MLSStatus
  isMuted?: boolean
  isHovered?: boolean
} & BoxProps) => {
  const { children, status, isMuted, isHovered, ...boxProps } = props

  const cardBorderColor = useToken('colors', getCardBorderColor(status))
  /**
   * @NOTE
   * We are simulating a border using shadow to prevent a change of size.
   * We also need to show a shadow that is why we have two layers of shadow:
   * one simulating the border and the other is the actual shadow.
   */
  const [hoveredShadow, defaultShadow] = useToken('shadows', ['button-hovered', 'primary.w'])
  const outerBorder = `0 0 0 4px ${cardBorderColor}`
  const boxShadow = isHovered
    ? [outerBorder, hoveredShadow].join(',')
    : defaultShadow

  return (
    <Box
      {...COMMON_CMA_ENTRY_TILE_CARD_STYLE}

      position='relative'
      boxShadow={boxShadow}
      overflow='hidden'
      /** Animate border changes on hover */
      transition='all 0.2s ease-in-out'
      /**
       * Muted Overlay
       */
      _after={{
        borderRadius: 3,
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 3,
        background: isMuted ? 'gw.100.am' : 'gw.100.az',
        transition: 'background 0.2s ease',
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export const getCardBorderColor = (
  status: MLSStatus,
): string => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => 'positive.500'),
  Match.when('FOR_LEASE', () => 'positive.500'),
  Match.when('SALE_OR_LEASE', () => 'positive.500'),
  Match.when('PENDING', () => 'neutral.500'),
  Match.when('SOLD', () => 'negative.500'),
  Match.when('LEASED', () => 'negative.500'),
  Match.when('EXPIRED', () => 'marker.gray'),
  Match.when('CANCELED', () => 'marker.gray'),
  Match.when('WITHDRAWN', () => 'marker.gray'),
  Match.when('OFF_MARKET', () => 'marker.darkgray'),
  Match.exhaustive,
)
