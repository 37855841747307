import { chakra, createIcon } from '@chakra-ui/react'

export const CheckCircleIcon = createIcon({
  displayName: 'CheckCircleIcon',
  viewBox: '0 0 17 16',
  path: (
    <chakra.g fill='none'>
      <circle
        cx='8.5'
        cy='8'
        r='8'
        fill='currentColor'
      />
      <chakra.path
        d='M4.5 9.5L7.02632 11.5L12.5 5'
        stroke='ondark.1'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </chakra.g>
  ),
})
