import { Button, ButtonProps, Flex, Td, Text, Tr } from '@chakra-ui/react'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TwoPersonsIcon } from 'presentation/components/Icons'
import { SkiptraceStatus } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceStatus/SkiptraceStatus'
import { SkiptraceSubAccordionItem } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceSubAccordionItem'
import { DateValue, NewValue, SkiptraceTable } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceTable'

export type SkiptraceAssociatesAccordionProps = {
  items: Skiptrace.PossibleAssociateInfo[]
}

export const SkiptraceAssociatesAccordion = (props: SkiptraceAssociatesAccordionProps) => {
  const { items } = props
  /** @TODO How to know if skipped or not to get min max? */
  const count = items.length
  const hasNew = items.some(v => v.isNew)
  return (
    <SkiptraceSubAccordionItem
      hasNew={hasNew}
      headerIcon={TwoPersonsIcon}
      headerLabel={`Possible Relatives / Associates (${count})`}
      isEmpty={count === 0}
    >
      <SkiptraceTable>
        {items.map((item, i) => (
          <Tr key={i}>
            <Td w='80px' textAlign='center'>
              {item.isNew
                ? <NewValue />
                : item.updatedAt
                  ? <DateValue date={item.updatedAt} />
                  : null}
            </Td>

            <Breakpoint
              mobSm={(
                <Td>
                  <Flex alignItems='center' gap={1}>
                    {/** @TODO Where to get this? */}
                    <SkiptraceStatus isSkipped={true} />
                    <Text textStyle='bodyL' color='link.500'>
                      {item.name}
                    </Text>
                  </Flex>
                </Td>
              )}
              tabSm={(
                <>
                  <Td w='112px'>
                    <Text textStyle='bodyL' color='graystrong.200'>
                      Relative
                      {' '}
                      {i + 1}
                    </Text>
                  </Td>
                  <Td>
                    <Flex alignItems='center' gap={1}>
                      {/** @TODO Where to get this? */}
                      <SkiptraceStatus isSkipped={true} />
                      <Text textStyle='bodyL' color='link.500'>
                        {item.name}
                      </Text>
                    </Flex>
                  </Td>

                  {/** @TODO Where to get this? */}
                  {/* <Td w='129px'>
                    {item.hasResults
                    ? <ViewResultsButton />
                    : <RunSkipButton />
                    }
                  </Td> */}
                </>
              )}
            />
          </Tr>
        ))}
      </SkiptraceTable>
    </SkiptraceSubAccordionItem>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RunSkipButton = (props: ButtonProps) => (
  <Button
    colorScheme='positive'
    variant='outline'
    size='xs'
    {...props}
  >
    Run Skip
  </Button>
)

// const ViewResultsButton = (props: ButtonProps) => (
//   <Button
//     colorScheme='neutral'
//     variant='ghost'
//     size='xs'
//     {...props}
//   >
//     View Results
//   </Button>
// )
