import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_TOWNHOUSE_ICON_W = 37

export const PropTypeTownhouseIcon = createIcon({
  displayName: 'PropTypeTownhouseIcon',
  viewBox: `0 0 ${PROP_TYPE_TOWNHOUSE_ICON_W} 33`,
  path: (
    <>
      <path d='M3.625 32.375V11.2589L11 3.88388L18.375 11.2589V32.375H3.625Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M18.625 32.375V11.2589L26 3.88388L33.375 11.2589V32.375H18.625Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='6.625' y='14.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='21.625' y='14.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='6.625' y='20.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='21.625' y='20.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='12.625' y='14.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='27.625' y='14.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='12.625' y='20.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='27.625' y='20.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='9.625' y='26.625' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='24.625' y='26.625' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M1 11L11 1L18.5 8.5L26 1L33 8M36 11L33 8M33 8V4' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
    </>
  ),
})
