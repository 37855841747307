import { Data } from 'effect'
import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { useMemo } from 'react'

export type UsePropertyResult = Data.TaggedEnum<{
  Loading: {}
  Success: { value: PropertyDetails.Property }
  Error: { error: Error }
}>

export const UsePropertyResult = Data.taggedEnum<UsePropertyResult>()

export const useProperty = () => {
  const contextLeadId = useLeadId()
  const propertyState = usePropertyDetailsStore(state => state.actions.getProperty.state)

  const propertyResult = useMemo(() => {
    if (propertyState.status === 'success' && propertyState.data.leadId === contextLeadId)
      return UsePropertyResult.Success({ value: propertyState.data })
    else if (propertyState.status === 'error')
      return UsePropertyResult.Error({ error: propertyState.error })
    else
      return UsePropertyResult.Loading()
  }, [propertyState])

  return propertyResult
}
