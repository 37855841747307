import { VStack } from '@chakra-ui/react'
import { ListTypeCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/ListTypeCard'
import { LocationCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/LocationCard'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const LocationFiltersContent = () => (
  <VStack
    p={2}
    spacing={2}
    align='stretch'
    flexDir={mbp({
      mobSm: 'column-reverse',
      tabSm: 'column',
    })}
  >
    <ListTypeCard />
    <LocationCard />
  </VStack>
)
