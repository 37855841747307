import { useInitializeLoadReportByRouteId, useResetHighlightedEntryOnUnmount, useResetTabOnUnmount } from 'features/CMA/infra/react/CMAState.helpers'
import { useGetPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { ComposeComponents } from 'presentation/components/ComposeComponents/ComposeComponents'
import { CompsScreenPanelsProvider } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { resetShowValues } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { CompsScreenBase } from 'presentation/screens/CompsScreen/components/CompScreenBase/CompsScreenBase'
import { useCompsExceededMaxResultsNotice } from 'presentation/screens/CompsScreen/hooks/useCompsExceededMaxResultsNotice'
import { CompsScreenViewModeProvider } from 'presentation/screens/CompsScreen/hooks/useCompsScreenViewMode'
import { useCompsToasts } from 'presentation/screens/CompsScreen/hooks/useCompsToasts'
import { useRateErrorHandler } from 'presentation/screens/CompsScreen/hooks/useRateErrorHandler'
import { useSyncUISettings } from 'presentation/screens/CompsScreen/hooks/useSyncUISettings'
import { useUpdateEstimateInfoErrorHandler } from 'presentation/screens/CompsScreen/hooks/useUpdateEstimateInfoErrorHandler'
import { useMLSStatusTrainingNotice } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingNotice/useMLSStatusTrainingNotice'
import { useEffect } from 'react'

export const CompsScreen = () => (
  <ComposeComponents
    providers={[
      CompsScreenPanelsProvider,
      CompsScreenViewModeProvider,
    ]}
  >
    <CompsScreenPreparer />
    <CompsScreenBase />
  </ComposeComponents>
)

export const CompsScreenPreparer = () => {
  useGetPropertyByRouteLeadId()
  useInitializeLoadReportByRouteId()
  useCompsExceededMaxResultsNotice()
  useResetTabOnUnmount()
  useResetHighlightedEntryOnUnmount()
  useSyncUISettings()
  useMLSStatusTrainingNotice()
  // ========================================
  // Initial Resets
  // ========================================
  useEffect(() => {
    /**
     * Reset local filters because it can potentially result to zero match and
     * that may give user impression that there are no results when in fact its
     * just hidden by the filters
     */
    resetShowValues()
  }, [])

  useRateErrorHandler()
  useUpdateEstimateInfoErrorHandler()
  useCompsToasts()
  return null
}
