import { Stream } from 'effect'
import { TaggedEnum, taggedEnum } from 'effect/Data'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { MarketingList } from 'features/ListBuilder/domain/MarketingList'
import MarketingListId from 'features/ListBuilder/domain/MarketingList/MarketingListId'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'

export type BatchSkiptraceByMemberId = Record<MarketingListMemberId, Skiptrace.Result>

// #region domain types
export type BatchSkiptrace = {
  listId: MarketingListId
  resultByMemberId: BatchSkiptraceByMemberId
  currentPage: number
  progress: {}
}

export type BatchSkiptraceStats = {
  skiptracedCount: number
  skiptracingCount: number
  membersCount: number
}

export type ReskipConfig = TaggedEnum<{
  DoNotReskip: {}
  Reskip: {}
  ReskipIfOlderThanXDays: {
    daysCount: number
  }
}>

export type ReskipConfigTag = ReskipConfig['_tag']

export type ToSkiptraceCount = {
  count: number
}

export type SkiptraceFormat = 'narrow' | 'wide'

// #endregion

// #region GetBatchSkiptraceByListId
export type GetBatchSkiptraceByListId = (listId: MarketingListId, page: number) =>
Promise<BatchSkiptrace>
// #endregion

// #region GetBatchSkiptraceStatsById
export type GetBatchSkiptraceStatsByListId = (listId: MarketingListId) =>
Promise<BatchSkiptraceStats>
// #endregion

// #region GetSelectionToSkiptraceCount
export type GetSelectionToSkiptraceCountPayload = {
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  reskipConfig: ReskipConfig
}

export type GetSelectionToSkiptraceCount = (payload: GetSelectionToSkiptraceCountPayload) => Promise<ToSkiptraceCount>
// #endregion

// #region AttemptBatchSkiptrace

export const ReskipConfig = taggedEnum<ReskipConfig>()

export type AttemptBatchSkiptracePayload = {
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  reskipConfig: ReskipConfig | null
  format: SkiptraceFormat
  toSkiptraceCount: number
  useFreeSkipsOnly: boolean
  remainingConsumables: number
}

export type AttemptBatchSkiptraceResult = {
  attempt: ConsumableAttempt.ConsumableAttempt
}

export type AttemptBatchSkiptrace = (payload: AttemptBatchSkiptracePayload) =>
Promise<AttemptBatchSkiptraceResult>
// #endregion

// #region ConfirmBatchSkiptrace
export type ConfirmBatchSkiptracePayload = {
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  reskipConfig: ReskipConfig | null
  format: SkiptraceFormat
  confirmationKey: string
  useFreeSkipsOnly: boolean
  remainingConsumables: number
}

export type ConfirmBatchSkiptrace = (payload: ConfirmBatchSkiptracePayload) =>
Promise<void>
// #endregion

// #region DownloadBatchSkiptraceList
export type DownloadBatchSkiptraceFormat =
  | 'vertical'
  | 'horizontal'

export type DownloadBatchSkiptraceList = (
  list: MarketingList,
  format: DownloadBatchSkiptraceFormat
) => Promise<void>
// #endregion

// #region WatchSkiptraceProgress
export type BatchSkiptraceProgress = TaggedEnum<{
  Idle: {}
  InProgress: { percentage: number }
}>

export const BatchSkiptraceProgress = taggedEnum<BatchSkiptraceProgress>()

export type WatchBatchSkiptraceProgress = (listId: MarketingListId) => Stream.Stream<BatchSkiptraceProgress>

// #endregion

export type BatchSkiptraceRepo = {
  getByListId: GetBatchSkiptraceByListId
  getStatsByListId: GetBatchSkiptraceStatsByListId
  getSelectionToSkiptraceCount: GetSelectionToSkiptraceCount
  attemptBatchSkiptrace: AttemptBatchSkiptrace
  confirmBatchSkiptrace: ConfirmBatchSkiptrace
  download: DownloadBatchSkiptraceList
  watchProgress: WatchBatchSkiptraceProgress
}
