import { Box, BoxProps, Text } from '@chakra-ui/react'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { Dollar } from 'libs/dollar'
import { getMLSStatusTagColor } from 'presentation/components/MLSStatusTag'

type CompPriceTagProps = {
  value: number | null
  status: MLSStatus
} & BoxProps

export const CompPriceTag = (props: CompPriceTagProps) => {
  const { value, ...boxProps } = props
  const { color, bgColor } = getMLSStatusTagColor(props.status)
  return (
    <Box
      borderRadius={0.5}
      borderWidth={0.125}
      borderColor={color}
      bgColor={bgColor}
      px={1}
      py={0.5}
      {...boxProps}
    >
      <Text
        textStyle='tagL'
        color={color}
      >
        {Dollar.formatMax3Digits(value)}
      </Text>
    </Box>
  )
}
