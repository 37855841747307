import { Context, Effect } from 'effect'
import { LocationCity, LocationCounty, LocationZip } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'

const Tag = Context.Tag('LocationService')

class LocationService extends Tag<LocationService, {
  searchCities: LocationService.SearchCities
  searchCounties: LocationService.SearchCounties
  searchZip: LocationService.SearchZip
}>() {}

declare namespace LocationService {

  export type SearchLocation<T> = (keyword: string) => Effect.Effect<T[]>

  export type SearchCities = SearchLocation<LocationCity>

  export type SearchCounties = SearchLocation<LocationCounty>

  export type SearchZip = SearchLocation<LocationZip>

}

export default LocationService
