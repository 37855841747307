import { createIcon } from '@chakra-ui/react'

export const ThumbsDownInactiveIcon = createIcon({
  displayName: 'ThumbsDownInactiveIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='white'>
      <path
        d='M10 15.5004V19.5004C10 20.296 10.3161 21.0591 10.8787 21.6217C11.4413 22.1843 12.2044 22.5004 13 22.5004L17 13.5004V2.50036H5.72003C5.2377 2.49491 4.76965 2.66396 4.40212 2.97636C4.0346 3.28875 3.79235 3.72346 3.72003 4.20036L2.34003 13.2004C2.29652 13.487 2.31586 13.7797 2.39669 14.0581C2.47753 14.3365 2.61793 14.5941 2.80817 14.8128C2.99842 15.0316 3.23395 15.2064 3.49846 15.3251C3.76297 15.4439 4.05012 15.5036 4.34003 15.5004H10ZM17 2.50036H19.67C20.236 2.49035 20.7859 2.6885 21.2154 3.05718C21.645 3.42586 21.9242 3.93942 22 4.50036V11.5004C21.9242 12.0613 21.645 12.5749 21.2154 12.9435C20.7859 13.3122 20.236 13.5104 19.67 13.5004H17'
      />
      <path
        d='M17 2.50036H19.67C20.236 2.49035 20.7859 2.6885 21.2154 3.05718C21.645 3.42586 21.9242 3.93942 22 4.50036V11.5004C21.9242 12.0613 21.645 12.5749 21.2154 12.9435C20.7859 13.3122 20.236 13.5104 19.67 13.5004H17M10 15.5004V19.5004C10 20.296 10.3161 21.0591 10.8787 21.6217C11.4413 22.1843 12.2044 22.5004 13 22.5004L17 13.5004V2.50036H5.72003C5.2377 2.49491 4.76965 2.66396 4.40212 2.97636C4.0346 3.28875 3.79235 3.72346 3.72003 4.20036L2.34003 13.2004C2.29652 13.487 2.31586 13.7797 2.39669 14.0581C2.47753 14.3365 2.61793 14.5941 2.80817 14.8128C2.99842 15.0316 3.23395 15.2064 3.49846 15.3251C3.76297 15.4439 4.05012 15.5036 4.34003 15.5004H10Z'
        stroke='#4C4C4C'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
})
