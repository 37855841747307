import { Box, Center, Checkbox, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match, Option } from 'effect'
import pluralize from 'pluralize'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import OwnersPropertiesHooks from '../viewModel/OwnersPropertiesHooks'
import OwnedPropertiesMap from './OwnedPropertiesMap'
import OwnedPropertiesTable from './OwnedPropertiesTable'

export type OwnedPropertiesTabbedProps = {
  ownerId: string
}

const OwnedPropertiesTabbed = ({
  ownerId,
}: OwnedPropertiesTabbedProps) => {
  const viewModel = OwnersPropertiesHooks.useOwnerDeals(ownerId)
  const toggleViewModel = OwnersPropertiesHooks.useToggleLimitToCurrentlyOwned(ownerId)

  const [activeTab, setActiveTab] = useState(0)

  if (Option.isNone(viewModel) || Option.isNone(toggleViewModel)) return null

  return Match.value(viewModel.value).pipe(
    Match.tag('Success', viewModel => (
      <Box>
        {}
        <HStack
          alignSelf='stretch'
          justifyContent='space-between'
          alignItems='center'
          h='5'
        >
          <Text
            textStyle='bodyXLFat'
            color='graystrong.500'
          >
            {pluralize('Property', viewModel.displayedPropertiesCount, false)}
            {' '}
            (
            {viewModel.displayedPropertiesCount}
            )
          </Text>

          <HStack>
            <Text
              textStyle='bodyXL'
              color='graystrong.400'
            >
              Show properties previously owned
            </Text>
            <Checkbox
              isChecked={toggleViewModel.value.shouldLimitToCurrentlyOwned}
              onChange={toggleViewModel.value.onToggle}
            />
          </HStack>
        </HStack>
        <BaseTabsContainer
          mt='2'
          h='482px'
          display='flex'
          flexDirection='column'
        >
          <BaseTabs>
            <BaseTab
              isActive={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              List
            </BaseTab>
            <BaseTab
              isActive={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              Map
            </BaseTab>
          </BaseTabs>
          <BaseTabContent
            p={mbp({ mobSm: 1, mob: 2 })}
            flex='1'
            display='flex'
            flexDirection='column'
            alignItems='stretch'
            justifyContent='stretch'
          >
            {Match.value(activeTab).pipe(
              Match.when(0, () => (
                <VStack
                  flex='1 1 auto'
                  alignContent='stretch'
                  justifyContent='stretch'

                  // These styles hides bg
                  // peeking thru border radius when scrolling
                  borderRadius={3}
                  overflow='hidden'
                >
                  <OwnedPropertiesTable ownerId={ownerId} />
                </VStack>
              )),
              Match.when(1, () => (
                <OwnedPropertiesMap ownerId={ownerId} />
              )),
              Match.orElse(() => null),
            )}
          </BaseTabContent>
        </BaseTabsContainer>
      </Box>
    )),
    Match.orElse(() => (
      <Center>
        <Spinner />
      </Center>
    )),
  )
}

export default OwnedPropertiesTabbed
