import { getSearchHistorySingletonStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { AppDomainEvents } from 'features/events/AppDomainEvents'
import { ServerCache } from 'libs/ServerCache'
import { useEffect } from 'react'

let isListening = false

export const useSearchHistoryEvents = () => {
  useEffect(() => {
    if (isListening) return

    AppDomainEvents.on('PropertyDetails/getProperty/success', async ({ payload }) => {
      /**
       * This is not obvious and is based on the legacy PHP app. For now just
       * know that if we get property via parcelId or addressString, it's
       * probably done via search input and is likely to have changed the
       * search history.
       *
       * Otherwise, if we get property via leadId, it's likely a direct request
       * from the lead details page which should not change the search history.
       */
      const didSearchHistoryChange = !!('parcelId' in payload && payload.parcelId)
        || !!('addressString' in payload && payload.addressString)

      if (!didSearchHistoryChange) return

      const historyState = (await getSearchHistorySingletonStore()).getState()
      historyState.actions.clearHistory()
      ServerCache.clearType('getHistory')
    })

    isListening = true
  }, [])
}
