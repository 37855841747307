import { Button, CardProps, Flex, Link, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { checkLocalAccessForData, useAccessStore } from 'features/Access/infra/react/AccessState'
import { RefreshIcon } from 'presentation/components/Icons'
import { toast } from 'presentation/components/Toast'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { useOneToastAtATime } from 'presentation/libs/useOneToastAtATime'
import { FormCard } from 'presentation/screens/AuthScreens/components/FormCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { pipe } from 'remeda'

export const ConfirmEmailCard = (props: CardProps) => {
  const domain = useAccessStore(store => {
    const resendConfirmationEmail = store.actions.resendConfirmationEmail
    const logout = store.actions.logout
    const clearErrors = store.actions.clearErrors
    const access = store.local.access

    return {
      data: checkLocalAccessForData(access) && access.data.status === 'logged-in'
        ? {
          status: 'ready',
          email: access.data.accessor.email,
          isEmailConfirmed: access.data.accessor.isEmailConfirmed,
        }
        : {
          status: 'unready',
        },
      updateAccess: {
        execute: store.actions.updateAccess.execute,
      },
      resendConfirmationEmail: {
        execute: resendConfirmationEmail.execute,
        isSubmitting: resendConfirmationEmail.state.status === 'loading',
        didSucceed: resendConfirmationEmail.state.status === 'success',
        requestId: 'requestId' in resendConfirmationEmail.state
          ? resendConfirmationEmail.state.requestId
          : null,
        errorMsg: pipe(
          Match.value(resendConfirmationEmail.state),
          Match.when({ error: Match.instanceOf(Error) }, ({ error }) => error.message),
          Match.orElse(() => null),
        ),
      },
      logout: {
        execute: logout.execute,
        isSubmitting: logout.state.status === 'loading',
      },
      clearErrors: clearErrors.execute,
    }
  })

  // Poll for email confirmation status
  const INTERVAL = 5000
  useEffect(() => {
    if (domain.data.isEmailConfirmed) return

    const intervalId = setInterval(() => {
      void domain.updateAccess.execute()
    }, INTERVAL)

    return () => clearInterval(intervalId)
  }, [domain.data.isEmailConfirmed])

  // Toast resendConfirmationEmail error

  useOneToastAtATime(() => {
    if (!domain.resendConfirmationEmail.errorMsg) return null

    domain.clearErrors()

    return {
      status: 'error',
      title: 'Resend confirmation email failed',
      message: domain.resendConfirmationEmail.errorMsg,
    }
  }, [domain.resendConfirmationEmail.errorMsg])

  // Toast resendConfirmationEmail success
  useEffect(() => {
    if (domain.resendConfirmationEmail.didSucceed) {
      toast.success({
        title: 'That was a success!',
        message: 'Confirmation email has been successfully resent.',
      })
    }
  }, [
    domain.resendConfirmationEmail.didSucceed,
    domain.resendConfirmationEmail.requestId,
  ])

  // Handle email confirmed
  const navigate = useNavigate()
  useEffect(() => {
    if (domain.data.status !== 'ready' || !domain.data.isEmailConfirmed) return

    toast.success({
      title: 'That was a success!',
      message: 'Email has been successfully confirmed.',
    })
    navigate('/')
  }, [domain.data.status, domain.data.isEmailConfirmed])

  if (domain.data.status === 'unready') return null

  return (
    <FormCard {...props}>
      <CardPrimaryTitle>
        Confirm your email address
      </CardPrimaryTitle>

      <Card
        mt={3}
        size={mbp({ mobSm: 'xxs-locked', mob: 'sm-locked', tabSm: 'lg-locked' })}
        colorScheme='card.bg.1'
        textAlign='center'
        zIndex={1}
        color='graystrong.200'
      >
        <Text
          mb={3}
          textStyle={mbp({
            mobSm: 'bodyL',
            mob: 'bodyXLFat',
          })}
          color='inherit'
        >
          We have sent an email to
          <br />
          <Text as='span' color='link'>
            {domain.data.email}
          </Text>
        </Text>

        <Text
          color='inherit'
          textStyle={mbp({
            mobSm: 'bodyL',
            mob: 'bodyXLFat',
          })}
        >
          Please check your email and click the confirmation link to complete the process.
        </Text>
      </Card>

      <Flex
        mt={mbp({ mobSm: 'auto', mob: '4' })}
        gap={3}
        justifyContent='center'
        flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
      >
        <Button
          size='lg'
          variant='outline'
          colorScheme='neutral'
          onClick={domain.logout.execute}
          isLoading={domain.logout.isSubmitting}
        >
          Sign Out
        </Button>
        <Button
          size='lg'
          leftIcon={<RefreshIcon boxSize={3} />}
          variant='solid'
          colorScheme='positive'
          order={mbp({ mobSm: -1, mob: 0 })}
          onClick={() => {
            void domain.resendConfirmationEmail.execute()
          }}
          isLoading={domain.resendConfirmationEmail.isSubmitting}
        >
          Resend Email
        </Button>
      </Flex>

      <Link
        color='link'
        mt={3}
        textAlign='center'
        onClick={() => {
          void SupportWidget.openWidget({
            topic: 'email-confirmation',
          })
        }}
      >
        Contact Support
      </Link>
    </FormCard>
  )
}
