import { gql } from '__generated__'

export const GET_CMA_FUNDS_INFO_GQL = gql(/** GraphQL */`
  query GetCMAFundsInfo {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          plan {
            name
            product {
              features {
                ... on SubscriptionFeatureComps {
                  __typename
                  prices {
                    included {
                      limit
                      nextRolloverDate
                      used
                    }
                    price
                    pricingUnit
                    pricingUnitDescription
                  }
                }
              }
            }
          }
          currentPeriod {
            end
          }
        }
      }
      wallet {
        balance {
          available
        }
      }
    }
  }
`)
