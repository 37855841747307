import { Schema as S } from '@effect/schema'
import { Array, Effect, Option, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { DownloadEntryId, ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { GETDownloadStatsSchema } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetDownloadStatsResolver/GETDownloadStatsSchema'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { DownloadEntrySchema } from 'features/ListBuilder/infra/repo/schema/DownloadEntrySchema'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

const getDownloadEntry = (listId: MarketingListId) =>
  Effect.tryPromise({
    try: async () => await restClient
      .get(`lists/v3/${listId}/downloads`)
      .json(),
    catch: error => {
      if (error instanceof HTTPError && error.response.status === 404)
        return new ListNotFoundError({ marketingListId: listId })

      return new UnknownException(error)
    },
  }).pipe(
    Effect.map(raw => S.decodeUnknownSync(GETDownloadStatsSchema)(raw)),
    Effect.map(decoded => decoded.downloads),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  )

/**
 * @NOTE: This endpoint creates a download entry that would * produce an id to be used for
 * downloading the "file" using another endpoint. The reason why it needs to create a download
 * entry is because it expects a range--using offset and limit--to specify partial download.
 * Not providing any is equivalent to download everything.
 */
const createDownloadEntry = (listId: MarketingListId) =>
  Effect.tryPromise({
    try: async () => await restClient
      .post(`lists/v3/${listId}/downloads`)
      .json(),
    catch: error => {
      if (error instanceof HTTPError && error.response.status === 404)
        return new ListNotFoundError({ marketingListId: listId })

      return new UnknownException(error)
    },
  }).pipe(
    Effect.map(raw => S.decodeUnknownSync(DownloadEntrySchema)(raw)),
    Effect.map(decoded => toDomain(decoded)),
    Effect.map(domain => domain as DownloadEntryId),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  )

const GetDownloadEntryResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetDownloadEntry) => {
    const x = pipe(
      getDownloadEntry(request.listId),
      /**
     * @NOTE: There should always be one entry. That entry was created the first time this request
     * was made. Incase there are multiple entries, get the first entry.
     */
      Effect.map(downloads => Array.head(downloads)),
      Effect.flatMap(downloads => Option.match(downloads, {
        onNone: () => createDownloadEntry(request.listId),
        onSome: downloadEntry => Effect.succeed(downloadEntry.id as DownloadEntryId),
      })),
    )
    return x
  },
)

export default GetDownloadEntryResolver

const toDomain = (schema: DownloadEntrySchema): DownloadEntryId => schema.id as DownloadEntryId
