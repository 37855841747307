import { createIcon } from '@chakra-ui/react'

export const LongArrowDownIcon = createIcon({
  displayName: 'LongArrowDownIcon',
  viewBox: '0 0 9 15',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M8 10.4545L4.5 14M4.5 14L1 10.4545M4.5 14L4.5 1' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
