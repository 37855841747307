import { CompsSort, DEFAULT_COMPS_SORT } from 'features/Settings/valueObjects/CompsSort'
import { CompsView } from 'features/Settings/valueObjects/CompsView'
import { ProgramException } from 'libs/errors'
import { DeepPartial } from 'libs/utils.types'

export namespace Settings {
  export type Theme = 'light' | 'dark'
  export type CMA = {
    filters: {
      lotAreaSqft: {
        unit: 'sqft' | 'acres' | null
      }
    }
    show: {
      shouldShowThumbsDown: boolean
    }
    sort: {
      shouldGroupByStatus: boolean
      sortBy: CompsSort
    }
    /** @IMPORTANT Must be nullable in order to determine if a view is not yet selected */
    view: CompsView | null
    isPinTrainingDone: boolean
  }

  export type SearchHistory = {
    isPinTrainingDone: boolean
  }

  export type General = {
    lastShownWelcomeModalVersion: string | null
  }

  export type Sites = {
    survey: {
      isWebsitesEssential: boolean | null
      isThirdPartyIntegrationEnough: boolean | null
    }
  }

  export type Features = {
    cma: CMA
    searchHistory: SearchHistory
    general: General
    sites: Sites
  }

  export type Settings = {
    theme: Settings.Theme
    features: Settings.Features
  }

  /**
   * This is a subset of setting that is expected to be immediately available.
   *
   * Current practical use case is to make theme available immediately upon app
   * load, instead of waiting for the settings to be fetched from the server
   * and potentially suddenly changing the theme on load which is jarring.
   *
   * This is the simplest solution that is thought of at the moment, and but a
   * more common and perhaps better solution is to do stale-while-revalidate
   * logic for the settings, but we're not sure yet how to approach that.
   */
  export type LocallyStoredSettings = Pick<Settings, 'theme'>

  export type GetSettingsPayload = never
  export type GetSettingsError = ProgramException
  export type GetSettingsResult = Settings

  export type UpdateSettingsPayload = { settings: DeepPartial<Settings> }
  export type UpdateSettingsError = ProgramException
  export type UpdateSettingsResult = void

  export type GetLocallyStoredSettingsPayload = never
  export type GetLocallyStoredSettingsError = ProgramException
  export type GetLocallyStoredSettingsResult = LocallyStoredSettings

  export type UpdateLocallyStoredSettingsPayload = { settings: DeepPartial<LocallyStoredSettings> }
  export type UpdateLocallyStoredSettingsError = ProgramException
  export type UpdateLocallyStoredSettingsResult = void

  export const DEFAULT_THEME: Theme = 'light'

  export const FALLBACK_SETTINGS: Settings = {
    theme: DEFAULT_THEME,
    features: {
      cma: {
        filters: {
          lotAreaSqft: {
            unit: null,
          },
        },
        view: null,
        show: {
          shouldShowThumbsDown: true,
        },
        sort: {
          shouldGroupByStatus: false,
          sortBy: DEFAULT_COMPS_SORT,
        },
        isPinTrainingDone: false,
      },
      searchHistory: {
        isPinTrainingDone: false,
      },
      general: {
        lastShownWelcomeModalVersion: null,
      },
      sites: {
        survey: {
          isWebsitesEssential: null,
          isThirdPartyIntegrationEnough: null,
        },
      },
    },
  }
}

export type Settings = Settings.Settings
