import { useQuery } from '@apollo/client'
import { Button, Center, Grid, GridItem, HStack, Spinner, Text } from '@chakra-ui/react'
import { isNumber } from 'lodash/fp'
import { HashLink } from 'presentation/components/HashLink'
import { PlusIcon } from 'presentation/components/Icons/PlusIcon'
import { Card, CardButton, CardFooter, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { useToastError } from 'presentation/hooks/useToastError'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { openPaymentFailedModal } from 'presentation/main/globalModals/PaymentFailedModal'
import { openWalletBalanceEntryModal } from 'presentation/screens/Billing/Billing.modals'
import { emitTabChangeEvent } from 'presentation/screens/Billing/components/BillingHistorySection/billingHistorySectionTabEvents'
import { fadeIn } from 'presentation/utils/animationClasses'
import { get } from 'presentation/utils/graphql'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'
import { ACCOUNT_BALANCE__GET_BALANCE } from './AccountBalance.queries'

export const AccountBalance: FC = () => {
  const { mfv } = useMobFirstBreakpointValue()

  const addFundsIcon = mfv({
    mobSm: <PlusIcon boxSize='3' />,
    mob: undefined,
  })

  const { data, error } = useQuery(ACCOUNT_BALANCE__GET_BALANCE)

  const activeSub = get('BillingSubscriptionActive')(
    data?.myEnterprise?.subscription,
  ) ?? undefined

  const failedInvoiceSummary = unmaskFailedInvoiceFragment(activeSub)

  const value = data?.myEnterprise?.wallet?.balance?.available

  useToastError({
    error,
    title: 'We couldn’t get your marketing funds',
  })

  const handleViewWallet = () => {
    emitTabChangeEvent('account-balance')
  }

  const handleAddFunds = () => {
    if (failedInvoiceSummary)
      openPaymentFailedModal({ failedInvoiceSummary })
    else
      openWalletBalanceEntryModal()
  }

  return (
    <>
      <Card
        variant='default'
        colorScheme='card.bg.1'
        size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', dtLg: 'md-locked' })}
        w='full'
      >
        {!isNumber(value)
          ? (
            <Center h='full' py='5'>
              <Spinner />
            </Center>
          )
          : (
            <Grid
              className={fadeIn}
              gridTemplate={mbp({
                mobSm: `
                  "funds funds" min-content
                  "info info" min-content
                  "actions actions" min-content
                `,
                mob: `
                  "funds info" min-content
                  "actions actions" min-content
                `,
                tabSm: `
                  "funds info" min-content
                  "funds actions" min-content
                `,
              })}
            >
              <GridItem gridArea='funds'>
                <HStack justifyContent='space-between'>
                  <CardPrimaryTitle>Account Balance</CardPrimaryTitle>
                  <Button
                    display={{ base: 'inline-flex', mob: 'none' }}
                    variant='outline'
                    colorScheme='neutral'
                    onClick={handleViewWallet}
                    to='/user/billing#history'
                    as={HashLink}
                  >
                    VIEW
                  </Button>
                </HStack>

                <Text
                  color='l.gs400-d.gs700'
                  fontFamily='Inter'
                  fontWeight='700'
                  fontSize={mbp({ mobSm: '30px', mob: '36px', tabSm: '42px', dtLg: '50px' })}
                  lineHeight='110%'
                >
                  {formatPenniesOptionalDecUsd(value)}
                </Text>
              </GridItem>

              <GridItem gridArea='info'>
                <Text
                  pt={mbp({ mobSm: '1', mob: '1' })}
                  pl={mbp({ mobSm: '0', mob: '3' })}
                  textStyle={mbp({ mobSm: 'body2', mob: 'body', dtLg: 'old.h4' })}
                  color='graystrong.200'
                  textAlign={mbp({ mobSm: 'left', mob: 'right' })}
                >
                  Your account balance is used for mailers, skiptraces and other services.
                </Text>
              </GridItem>

              <GridItem
                gridArea='actions'
                justifyContent={mbp({ mobSm: 'stretch', mob: 'flex-end' })}
                display='flex'
              >
                <CardFooter
                  w={mbp({ mobSm: 'full', mob: 'initial' })}
                  horizontallyStackAt='mobSm'
                >
                  <CardButton
                    display={{ base: 'none', mob: 'inline-flex' }}
                    variant='outline'
                    colorScheme='neutral'
                    borderColor='l.n500-brightblue'
                    bg='transparent'
                    onClick={handleViewWallet}
                    to='/user/billing#history'
                    as={HashLink}
                    size={{ base: 'lg', mob: 'sm' }}
                  >
                    VIEW BALANCE
                  </CardButton>

                  <CardButton
                    w={{ base: 'full', mob: 'auto' }}
                    variant='solid'
                    colorScheme='positive'
                    size={{ base: 'lg', mob: 'sm' }}
                    // @NOTE: This avoids the button adding a left padding when the icon is not present
                    {...addFundsIcon ? { leftIcon: addFundsIcon } : {}}
                    onClick={handleAddFunds}
                  >
                    ADD BALANCE
                  </CardButton>
                </CardFooter>
              </GridItem>
            </Grid>
          )}
      </Card>
    </>
  )
}
