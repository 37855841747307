import { createIcon } from '@chakra-ui/react'

export const InfinityIcon = createIcon({
  displayName: 'InfinityIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill='none' stroke='currentColor'>
      <path
        d='M13.75 9.375L14 9C14.6296 8.16047 15.5075 7.54033 16.5092 7.22743C17.5108 6.91453 18.5856 6.92473 19.5811 7.25658C20.5767 7.58844 21.4426 8.22512 22.0562 9.07645C22.6698 9.92778 23 10.9506 23 12C23 13.0494 22.6698 14.0722 22.0562 14.9236C21.4426 15.7749 20.5767 16.4116 19.5811 16.7434C18.5856 17.0753 17.5108 17.0855 16.5092 16.7726C15.5075 16.4597 14.6296 15.8395 14 15L10 9C9.37035 8.16047 8.49252 7.54033 7.49084 7.22743C6.48916 6.91453 5.41442 6.92473 4.41886 7.25658C3.4233 7.58844 2.55739 8.22512 1.94379 9.07645C1.33019 9.92778 1 10.9506 1 12C1 13.0494 1.33019 14.0722 1.94379 14.9236C2.55739 15.7749 3.4233 16.4116 4.41886 16.7434C5.41442 17.0753 6.48916 17.0855 7.49084 16.7726C8.49252 16.4597 9.37035 15.8395 10 15L10.334 14.5'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
