import { Box, CardProps, Grid, HStack, Text } from '@chakra-ui/react'
import { Option } from 'effect'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import pluralize from 'pluralize'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { Card } from 'presentation/components/molecules/Card'
import { noop } from 'utils/noop'
import NameWithSkip from './NameWithSkip'
import TruncatedOwnerList from './TruncatedOwnerList'

const OwnerInfoCard = (props: CardProps) => {
  const viewModel = OwnersPropertiesHooks.useOwnersInfo()

  if (Option.isNone(viewModel)) return null

  const { ownership, owners } = viewModel.value

  const address = ownership.address || null

  return (
    <Card
      size={{
        base: 'xs-locked',
        tabSm: 'sm-locked',
      }}
      shadow='primary.m'
      {...props}
    >
      <Grid
        gridTemplateColumns={{
          base: 'repeat(2, minmax(0, 1fr))',
          tabSm: 'repeat(4, minmax(0, 1fr))',
        }}
        columnGap={{
          base: 2,
          tabSm: 1,
        }}
        rowGap='3'
      >
        <Pair size='md'>
          <PairKey>
            Name
          </PairKey>
          <PairValue
            as={TruncatedOwnerList}
            // sx={{
            // /**
            //  * Move "and N more" below owners name on smaller
            //  * viewports
            //  */
            //   '& > div:last-child': {
            //     'flexDir': {
            //       base: 'column',
            //       dtLg: 'row',
            //     },
            //     '& .truncated-owner-list__and-more-button': {
            //       ml: {
            //         base: 3.5,
            //         dtLg: 0,
            //       },
            //     },
            //   },
            // }}
          >
            {owners.length > 0
              ? owners.map((owner, i) =>
                owner.name.pipe(Option.match({
                  onSome: name => (
                    <NameWithSkip key={i}>
                      {/** TODO: wire isSkipped */}
                      {name}
                    </NameWithSkip>
                  ),
                  onNone: () => null,
                })),
              )
              : '--'}
          </PairValue>
        </Pair>
        <Pair size='md'>
          <PairKey>
            Owner&rsquo;s Address
          </PairKey>
          <PairValue
            as={Box}
          >
            <Text color='link'>
              {address
                ? (
                  <>
                    <Text
                      color='inherit'
                      textAlign='left'
                      isTruncated
                      title={`${address.line1}, ${address.city}`}
                      onClick={viewModel.pipe(
                        Option.map(viewModel => viewModel.onOpenAddress),
                        Option.getOrElse(() => noop),
                      )}
                    >
                      {`${address.line1}, ${address.city}`}
                    </Text>
                    <Text
                      color='inherit'
                      textAlign='left'
                      isTruncated
                      title={`${address.state} ${address.postalCode}`}
                      onClick={viewModel.pipe(
                        Option.map(viewModel => viewModel.onOpenAddress),
                        Option.getOrElse(() => noop),
                      )}
                    >
                      {`${address.state} ${address.postalCode}`}
                    </Text>
                  </>
                )
                : (
                  <Text color='inherit'>
                    --
                  </Text>
                )}
            </Text>
          </PairValue>

        </Pair>
        <Pair size='md'>
          <PairKey as={HStack} gap='0.5'>
            <Text color='inherit'>
              Status
            </Text>
            <PropertyMarker
              markerType='tag'
              classification={ownership.classification}
              equityType={ownership.equityType.pipe(Option.getOrNull)}
              isSenior={ownership.isSenior || false}
              isVacant={ownership.isVacant || false}
              isForeclosure={ownership.isForeclosure || false}
              size='tiny'
            />
          </PairKey>
          <PairValue>
            {Option.fromNullable(ownership.classification).pipe(
              Option.map(
                OwnerClassification.toStringWithVacancy(
                  ownership.isVacant || false,
                ),
              ),
              Option.getOrElse(() => null),
            )}
          </PairValue>
        </Pair>
        <Pair size='md'>
          <PairKey>
            Years of Ownership
          </PairKey>
          <PairValue>
            {Option.fromNullable(ownership.months)
              .pipe(
                Option.map(months => Math.floor(months / 12)),
                Option.map(years => years > 0
                  ? `${years} ${pluralize('Year', years)}`
                  : 'Less than a year',
                ),
                Option.getOrElse(() => '--'),
              )}
          </PairValue>
        </Pair>
      </Grid>
    </Card>
  )
}

export default OwnerInfoCard
