import { toast } from 'presentation/components/Toast'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { FailedInvoiceSummary } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { openFailedInvoiceModal } from 'presentation/main/globalModals/FailedInvoiceModal/openFailedInvoiceModal'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'

export const handleDefaultOpenFailedInvoiceModal = (failedInvoiceSummary?: FailedInvoiceSummary) => {
  if (!failedInvoiceSummary) {
    toast({
      status: 'error',
      title: 'Unexpected Error',
      message: 'Failed to load invoice details. Please try again later.',
    })
  } else {
    openFailedInvoiceModal({
      failedInvoiceSummary,
      onSuccess: message => {
        toast({
          ...TOAST_PRESET.GENERIC_SUCCESS,
          message,
        })
        closeModal()
      },
      onError: error => openErrorModal({
        message: error.message,
      }),
    })
  }
}
