import { useMergeRefs } from '@chakra-ui/react-use-merge-refs'
import { cx, dataAttr } from '@chakra-ui/shared-utils'
import {
  HTMLChakraProps,
  SystemStyleObject,
  ThemingProps,
  chakra,
  forwardRef,
  omitThemingProps,
  useStyleConfig,
} from '@chakra-ui/system'

import {
  ButtonOptions,
  ButtonSpinner,
  useButtonGroup,
} from '@chakra-ui/react'
import { cloneElement, isValidElement, useCallback, useMemo, useState } from 'react'

import { merge } from 'lodash/fp'

export type ButtonProps = {} & HTMLChakraProps<'button'> & ButtonOptions & ThemingProps<'Button'>

/**
 * Button component is used to trigger an action or event, such as submitting a form, opening a Dialog, canceling an action, or performing a delete operation.
 *
 * @see Docs https://chakra-ui.com/docs/components/button
 * @see WAI-ARIA https://www.w3.org/WAI/ARIA/apg/patterns/button/
 */
export const ConfigurableChakraButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const group = useButtonGroup()
  const styles = useStyleConfig('Button', { ...group, ...props })

  const {
    isDisabled = group?.isDisabled,
    isLoading,
    isActive,
    children,
    leftIcon,
    rightIcon,
    loadingText,
    iconSpacing = '0.5rem',
    type,
    spinner,
    spinnerPlacement = 'start',
    className,
    as,
    __css,
    ...rest
  } = omitThemingProps(props)

  /**
   * When button is used within ButtonGroup (i.e. flushed with sibling buttons),
   * it is important to add a `zIndex` on focus.
   *
   * So let's read the component styles and then add `zIndex` to it.
   */
  const buttonStyles: SystemStyleObject = useMemo(() => {
    // @ts-expect-error TODO explain what's the issue
    const _focus = { ...styles?._focus, zIndex: 1 }
    return merge({
      display: 'inline-flex',
      appearance: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      position: 'relative',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      outline: 'none',
      ...styles,
      ...!!group && { _focus },
    }, __css)
  }, [styles, group, __css])

  const { ref: _ref, type: defaultType } = useButtonType(as)

  const contentProps = { rightIcon, leftIcon, iconSpacing, children }

  return (
    <chakra.button
      ref={useMergeRefs(ref, _ref)}
      as={as}
      type={type ?? defaultType}
      data-active={dataAttr(isActive)}
      data-loading={dataAttr(isLoading)}
      __css={buttonStyles}
      className={cx('chakra-button', className)}
      {...rest}
      disabled={isDisabled || isLoading}
    >
      {isLoading && spinnerPlacement === 'start' && (
        <ButtonSpinner
          className='chakra-button__spinner--start'
          label={loadingText}
          placement='start'
          spacing={iconSpacing}
        >
          {spinner}
        </ButtonSpinner>
      )}

      {isLoading
        ? (
          loadingText || (
            <chakra.span opacity={0}>
              <ButtonContent {...contentProps} />
            </chakra.span>
          )
        )
        : (
          <ButtonContent {...contentProps} />
        )}

      {isLoading && spinnerPlacement === 'end' && (
        <ButtonSpinner
          className='chakra-button__spinner--end'
          label={loadingText}
          placement='end'
          spacing={iconSpacing}
        >
          {spinner}
        </ButtonSpinner>
      )}
    </chakra.button>
  )
})

ConfigurableChakraButton.displayName = 'Button'

type ButtonContentProps = Pick<
  ButtonProps,
'leftIcon' | 'rightIcon' | 'children' | 'iconSpacing'
>

function ButtonContent(props: ButtonContentProps) {
  const { leftIcon, rightIcon, children, iconSpacing } = props
  return (
    <>
      {leftIcon && <ButtonIcon marginEnd={iconSpacing}>{leftIcon}</ButtonIcon>}
      {children}
      {rightIcon && (
        <ButtonIcon marginStart={iconSpacing}>{rightIcon}</ButtonIcon>
      )}
    </>
  )
}

export function ButtonIcon(props: HTMLChakraProps<'span'>) {
  const { children, className, ...rest } = props

  const _children = isValidElement(children)
    ? cloneElement<any>(children, {
      'aria-hidden': true,
      'focusable': false,
    })
    : children

  const _className = cx('chakra-button__icon', className)

  return (
    <chakra.span
      display='inline-flex'
      alignSelf='center'
      flexShrink={0}
      {...rest}
      className={_className}
    >
      {_children}
    </chakra.span>
  )
}

ButtonIcon.displayName = 'ButtonIcon'

export function useButtonType(value?: React.ElementType) {
  const [isButton, setIsButton] = useState(!value)
  const refCallback = useCallback((node: HTMLElement | null) => {
    if (!node) return
    setIsButton(node.tagName === 'BUTTON')
  }, [])
  const type = isButton ? 'button' : undefined
  return { ref: refCallback, type } as const
}
