import { IconButton, IconButtonProps } from '@chakra-ui/react'
import { PrinterIcon } from 'presentation/components/Icons'
import { ComponentPrint } from 'presentation/screens/CompsScreen/ComponentPrint'

export const CMAPrintButton = (props: Partial<IconButtonProps>) => {
  const { print } = ComponentPrint.usePrint()

  return (
    <IconButton
      aria-label='print'
      variant='icon-ghost'
      colorScheme='neutral'
      size='md'
      icon={<PrinterIcon />}
      onClick={() => {
        print()
      }}
      {...props}
    >
      Print
    </IconButton>
  )
}
