export const HEADER_H = 72

export const GRID_OF_8 = {
  // Jumps of 8px per unit
  0.125: '1px',
  0.25: '2px',
  0.375: '3px',
  0.5: '4px',
  0.75: '6px',
  1: '8px',
  1.5: '12px',
  2: '16px',
  2.5: '20px',
  3: '24px',
  3.5: '28px',
  4: '32px',
  4.5: '36px',
  5: '40px',
  5.5: '44px',

  // Jump of 16px per unit
  6: '48px',
  6.5: '56px',
  7: '64px',

  // Jump of 32px per unit
  8: '96px',
}
