import { Context, Effect, Option } from 'effect'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'

namespace SubscriptionProductRepo {

  export class GetProducts extends Context.Tag('SubscriptionProductRepo.GetProducts')<
    GetProducts,
    () => Effect.Effect<SubscriptionProduct[]>
  >() { }

  export class GetActive extends Context.Tag('SubscriptionProductRepo.GetActive')<
    GetActive,
    () => Effect.Effect<Option.Option<SubscriptionProductActive>>
  >() { }

}

export default SubscriptionProductRepo
