import { Option, Request } from 'effect'
import { DownloadEntryId, DownloadNotAvailableError, ListNotFoundError, ListTypeNotSupportedError, ParcelNotFoundError } from 'features/ListBuilder/domain/Errors'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import CursorId from 'features/valueObjects/CursorId'

namespace MarketingListRequest {

  export class SaveList extends Request.TaggedClass('SaveList')<
    MarketingList,
    never,
    {
      listName: string
      criteria: ListCriteria
    }
  > {}

  export class SubscribeImportProgress
    extends Request.TaggedClass('SubscribeImportProgress')<
      MarketingListRepo.ImportProgress,
      never,
      {
        readonly listId: MarketingListId
      }
    > {}

  export class UpdateListName extends Request.TaggedClass('UpdateListName')<
    MarketingList,
    ListNotFoundError,
    {
      listId: MarketingListId
      name: string
    }
  > {}

  export class FindById extends Request.TaggedClass('MarketingList/FindById')<
    MarketingList,
    ListNotFoundError,
    {
      listId: MarketingListId
    }
  > {}

  export class GetByPage extends Request.TaggedClass('MarketingList/GetByPage')<
    MarketingListRepo.GetByPageResult,
    never,
    {
      page: number
    }
  > {}

  export class DeleteById extends Request.TaggedClass('MarketingList/DeleteById')<
    void,
    ListNotFoundError,
    {
      listId: MarketingListId
    }
  > {}

  export class DownloadList extends Request.TaggedClass('DownloadList')<
    void,
    DownloadNotAvailableError,
    {
      listId: MarketingListId
      downloadId: DownloadEntryId
      fileName: string
    }
  > {}

  export class GetDownloadStats extends Request.TaggedClass('GetDownloadStats')<
    MarketingListRepo.DownloadStats,
    ListNotFoundError,
    {
      listId: MarketingListId
    }
  > {}

  export class GetDownloadEntry extends Request.TaggedClass('GetDownloadEntry')<
    DownloadEntryId,
    ListNotFoundError,
    {
      listId: MarketingListId
    }
  > {}

  export class GetNextByCursor extends Request.TaggedClass('GetNextByCursor')<
    MarketingListRepo.GetByCursorResult,
    never,
    {
      cursor: Option.Option<CursorId>
    }
  > {}

  export class GetPrevByCursor extends Request.TaggedClass('GetPrevByCursor')<
    MarketingListRepo.GetByCursorResult,
    never,
    {
      cursor: Option.Option<CursorId>
    }
  > {}

  export class GetNextByParcel extends Request.TaggedClass('GetNextByParcel')<
    MarketingListRepo.GetByCursorResult,
    never,
    {
      parcelId: ParcelId
      cursor: Option.Option<CursorId>
    }
  > {}

  export class GetPrevByParcel extends Request.TaggedClass('GetPrevByParcel')<
    MarketingListRepo.GetByCursorResult,
    never,
    {
      parcelId: ParcelId
      cursor: Option.Option<CursorId>
    }
  > {}

  export class AddParcel extends Request.TaggedClass('AddParcel')<
    void,
    ListNotFoundError | ParcelNotFoundError | ListTypeNotSupportedError,
    {
      parcelId: ParcelId
      listId: MarketingListId
    }
  > {}

  export class CreateList extends Request.TaggedClass('CreateList')<
    MarketingListId,
    never,
    {
      name: string
    }
  > {}

  export class RemoveParcel extends Request.TaggedClass('RemoveParcel')<
    void,
    ListNotFoundError | ParcelNotFoundError | ListTypeNotSupportedError,
    {
      parcelId: ParcelId
      listId: MarketingListId
    }
  > {}
}

export default MarketingListRequest
