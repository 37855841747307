import { SkiptraceEvents } from 'features/Skiptrace/infra/events/Skiptrace.events'
import { skiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state.store'
import { LegacyBillingEvents } from 'presentation/screens/Billing/Billing.events'

// When skiptrace is performed, we need to refresh the funds info
// Since getSkiptraceFundsInfo requires an address, we'll use the result data
SkiptraceEvents.performSkiptraceDone.subscribe(() => {
  const state = skiptraceStore.getState()
  const resultState = state.entities.resultState

  // Only refresh if we have a successful result with subject info containing an address
  if (resultState.status === 'success' && resultState.result?.subject?.address) {
    void state.actions.getSkiptraceFundsInfo.execute({
      leadId: resultState.params.leadId,
      address: resultState.result.subject.address,
    })
  }
})

// When funds are added, we also need to refresh the funds info
// We'll use the same approach as above
LegacyBillingEvents.addFundsDone.subscribe(() => {
  const state = skiptraceStore.getState()
  const resultState = state.entities.resultState

  // Only refresh if we have a successful result with subject info containing an address
  if (resultState.status === 'success' && resultState.result?.subject?.address) {
    void state.actions.getSkiptraceFundsInfo.execute({
      leadId: resultState.params.leadId,
      address: resultState.result.subject.address,
    })
  }
})
