import { Array, Data, Option, pipe } from 'effect'
import ProductFeatureSpec from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'
import ProductName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductName'
import SubscriptionPlan from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'

type SubscriptionProduct = {
  readonly id: ProductId
  readonly name: ProductName
  readonly plans: SubscriptionPlan[]
  readonly description: string
  readonly features: ProductFeatureSpec[]
}

const SubscriptionProduct = {
  make: Data.case<SubscriptionProduct>(),
  isCurrentProduct: (product: SubscriptionProduct) => pipe(
    Array.findFirst(product.plans, plan => plan.isCurrentPlan),
    Option.match({
      onNone: () => false,
      onSome: () => true,
    }),
  ),
  calculateYearlySavings: (products: SubscriptionProduct[]) => {
    const yearlySavings = Array.reduce(products, 0, (acc, product) => {
      const maybeYearly = pipe(
        product.plans,
        Array.findFirst(plan => plan._tag === 'YearlyPlan'),
        Option.map(plan => plan.price),
      )

      const maybeMonthly = pipe(
        product.plans,
        Array.findFirst(plan => plan._tag === 'MonthlyPlan'),
        Option.map(plan => plan.price),
      )

      return pipe(
        Option.zipWith(maybeMonthly, maybeYearly, (
          monthlyPrice,
          yearlyPrice,
        ) => Math.max(acc, (monthlyPrice * 12) - yearlyPrice)),
        Option.getOrElse(() => acc),
      )
    })

    return yearlySavings
  },
}

export default SubscriptionProduct
