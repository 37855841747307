import type { Router } from '@remix-run/router'
import { createContext, useContext } from 'react'

namespace AppRouterContext {
  const context = createContext<Router | null>(null)

  export const Provider = context.Provider

  export const useRouter = () => {
    const router = useContext(context)
    if (!router)
      throw new Error('useRouter must be used within a RouterProvider')
    return router
  }
}

export default AppRouterContext
