import EventEmitter from 'eventemitter3'

const emitter = new EventEmitter()

export type TabChangeEventPayload = 'account-balance' | 'payments'

export const emitTabChangeEvent = (payload: TabChangeEventPayload) => {
  emitter.emit('CHANGE_TAB_REQUEST', payload)
}

export const listenTabChangeEvent = (callback: (payload: TabChangeEventPayload) => void) => {
  emitter.on('CHANGE_TAB_REQUEST', callback)
}

export const unlistenTabChangeEvent = (callback: (payload: TabChangeEventPayload) => void) => {
  emitter.off('CHANGE_TAB_REQUEST', callback)
}
