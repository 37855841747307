import { Location } from 'features/valueObjects/Location'
import { ID } from 'libs/id'
import mapboxgl from 'mapbox-gl'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { IdWithOrigin } from 'presentation/libs/MapAndListSync/MapAndListSync'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { LngLatLike } from 'react-map-gl'
import { isNonNullable } from 'utils/isNonNullable'
import { shallow } from 'zustand/shallow'

export type Marker = {
  id: ID
  location: Location
}

/**
 * Collection of default behaviors for a map with markers.
 * Includes:
 * - Centering the map on the highlighted entry
 * - Fitting the map to the markers
 */
export type UseCommonMarkerBehaviorsParams =
  & UseRecenterOnHighlightMarkerParams
  & UseRecenterOnMarkersUpdateParams

export const useCommonMarkerBehaviorsEffect = (
  params: UseCommonMarkerBehaviorsParams,
) => {
  useRecenterOnHighlightMarker(params)
  useRecenterOnMarkersUpdate(params)
}

type UseRecenterOnHighlightMarkerParams = {
  highlightedEntry: IdWithOrigin | null
  markers: Marker[]
}

export const useRecenterOnHighlightMarker = (
  params: UseRecenterOnHighlightMarkerParams,
) => {
  const { mapRef, recenter } = useMapStore(state => ({
    mapRef: state.computed.getMapRef(),
    recenter: state.recenter,
  }), shallow)

  const { highlightedEntry, markers } = params

  const selectedMarker = markers.find(entry =>
    entry.id === params.highlightedEntry?.id,
  )

  /** Handle when mapRef available */
  useEffect(() => {
    if (!isNonNullable(highlightedEntry)) return
    if (highlightedEntry.origin === 'map') return

    const selectedLocation = Location.ensureOrNull(selectedMarker?.location)

    if (selectedLocation === null) return

    const bounds: LngLatLike = [
      selectedLocation.longitude,
      selectedLocation.latitude,
    ]

    if (!mapRef) return

    recenter({
      targetBounds: bounds,
      isSingleMarker: markers.length === 1,
    })
  }, [
    highlightedEntry,
    mapRef,
  ])
}

type UseRecenterOnMarkersUpdateParams = {
  markers: Marker[]
  recenterDeps?: unknown[]
  padding?: number | mapboxgl.PaddingOptions
  getShouldSkipRecenter?: (nth: number) => boolean
}

export const useRecenterOnMarkersUpdate = (
  params: UseRecenterOnMarkersUpdateParams,
) => {
  const { mapRef, fitMarkers } = useMapStore(state => ({
    mapRef: state.computed.getMapRef(),
    fitMarkers: state.fitMarkers,
  }), shallow)
  const skippedRecenterCountRef = useRef(0)

  const { markers } = params

  const isFirstLoadRef = useRef(true)

  useLayoutEffect(() => {
    if (!mapRef) return

    /**
     * On non first load, where markers are empty, it's probably an inbetween
     * loading state, in which we'd rather not move the map around.
     */
    if (!isFirstLoadRef.current && markers.length === 0)
      return

    skippedRecenterCountRef.current++

    if (params.getShouldSkipRecenter?.(skippedRecenterCountRef.current))
      return

    fitMarkers({
      isInitialLoad: isFirstLoadRef.current,
      markers: markers.map(v => v.location),
      padding: params.padding,
    })

    isFirstLoadRef.current = false
  }, [
    mapRef,
    markers.length,
    ...params.recenterDeps || [],
  ])
}
