import GetOwnerDealsLive from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals.live'
import GetOwnersPropertiesPreviewLive from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview.live'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'

const OwnersPropertiesRepoLive: OwnersPropertiesRepo.Shape = {
  getOwnersPropertiesPreview: GetOwnersPropertiesPreviewLive,
  getOwnerDeals: GetOwnerDealsLive,
}

export default OwnersPropertiesRepoLive
