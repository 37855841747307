export const NOTICE_ORDER = [
  'COMPS_EXCEEDED_MAX_RESULTS',
  'PAYMENT_FAILED',
  'SUBSCRIPTION',
  'EXPIRING_CARD',
  'LIST_IN_DEV',
  'LEGACY_PLAN',
  'PROPERTY_MARKER_TRAINING',
  'MLS_STATUS_TRAINING',
  'DEFAULT',
] as const

export type NoticeKey = (typeof NOTICE_ORDER)[number]

export const NOTICE_KEYS: Record<NoticeKey, NoticeKey> = NOTICE_ORDER.reduce<Record<NoticeKey, NoticeKey>>(
  (acc, key) => ({ ...acc, [key]: key }),

  {} as Record<NoticeKey, NoticeKey>,
)
