import { BoxProps, Checkbox, HStack, Menu, MenuButton, MenuItemOption, MenuOptionGroup, MenuProps, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { isArray } from 'effect/Array'
import { useExcludeProperties } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SWITCH_TAB_BUTTON_CLASS_NAME, SwitchTabButton, SwitchTabButtons } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useSwitchTabsContext } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { LIST_FILTERS_TAB_LIST, LIST_FILTERS_TAB_RECORD } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFiltersTabs.const'
import { useState } from 'react'

export const ListFilterTabButtons = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { sbp } = useSwitchBreakpointFn()
  const { activeTabKey } = useSwitchTabsContext()

  const visibleTabsCount = sbp({
    mobSm: 1,
    mob: 2,
    tabSm: 4,
    dtSm: 5,
    dtLg: 7,
  })
  const initialVisibleTabs = LIST_FILTERS_TAB_LIST.slice(0, visibleTabsCount)

  const visibleTabs = activeTabKey && !initialVisibleTabs.includes(activeTabKey)
    ? [...initialVisibleTabs.slice(0, -1), activeTabKey]
    : initialVisibleTabs

  return (
    <SwitchTabButtons
      pos='relative'
      sx={{
        [`.${SWITCH_TAB_BUTTON_CLASS_NAME}`]: {
          textTransform: 'uppercase',
        },
      }}
    >
      <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.LOCATION}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.LOCATION)}
      >
        Location
      </SwitchTabButton>

      {/* <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.LAND_FEATURES}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.LAND_FEATURES)}
      >
        Land Features
      </SwitchTabButton> */}
      <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.PROPERTY_TYPE}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.PROPERTY_TYPE)}
      >
        Property Type
      </SwitchTabButton>
      <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.OWNER}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.OWNER)}
      >
        Owner
      </SwitchTabButton>
      <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.STRESS_INDICATOR}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.STRESS_INDICATOR)}
      >
        Stress Indicator
      </SwitchTabButton>
      <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.EQUITY}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.EQUITY)}
      >
        Equity
      </SwitchTabButton>
      {/* <SwitchTabButton
        tabKey={LIST_FILTERS_TAB_RECORD.MLS}
        isHidden={!visibleTabs.includes(LIST_FILTERS_TAB_RECORD.MLS)}
      >
        MLS
      </SwitchTabButton> */}

      <Breakpoint
        mobSm={() => null}
        dtLg={(
          <ExcludePropertiesCheckbox pos='absolute' top={-0.5} right='0' />
        )}
      />

      <Breakpoint
        mobSm={(
          <MobileMenu
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}
        dtLg={() => null}
      />
    </SwitchTabButtons>
  )
}

const MobileMenu = (props: Partial<MenuProps>) => {
  const {
    activeTabKey,
    setActiveTabKey,
  } = useSwitchTabsContext()

  return (
    <Menu {...props}>
      <MenuButton
        as={SwitchTabButton}
        hasContent={false}
      >
        More +
      </MenuButton>

      <Portal>
        <ResponsiveMenuList>
          <MenuOptionGroup
            value={activeTabKey}
            onChange={valueStrOrArr => {
              const value = isArray(valueStrOrArr) ? valueStrOrArr[0] : valueStrOrArr

              if (value === activeTabKey)
                return

              setActiveTabKey(value)
              props.onClose?.()
            }}
          >
            <MenuItemOption value={LIST_FILTERS_TAB_RECORD.LOCATION}>
              Location
            </MenuItemOption>
            {/* <MenuItemOption value={LIST_FILTERS_TAB_RECORD.LAND_FEATURES}>
              Land Features
            </MenuItemOption> */}
            <MenuItemOption value={LIST_FILTERS_TAB_RECORD.PROPERTY_TYPE}>
              Property Type
            </MenuItemOption>
            <MenuItemOption value={LIST_FILTERS_TAB_RECORD.OWNER}>
              Owner
            </MenuItemOption>
            <MenuItemOption value={LIST_FILTERS_TAB_RECORD.STRESS_INDICATOR}>
              Stress Indicator
            </MenuItemOption>
            <MenuItemOption value={LIST_FILTERS_TAB_RECORD.EQUITY}>
              Equity
            </MenuItemOption>
            {/* <MenuItemOption value={LIST_FILTERS_TAB_RECORD.MLS}>
              MLS
            </MenuItemOption> */}
          </MenuOptionGroup>
        </ResponsiveMenuList>
      </Portal>
    </Menu>
  )
}

const ExcludePropertiesCheckbox = (props: BoxProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const api = useExcludeProperties()

  return (
    <HStack
      spacing={1}
      onClick={() => setIsChecked(!isChecked)}
      h='4'
      {...props}
    >
      <Text textStyle='bodyXL' color='graystrong.400'>
        Exclude properties on the other lists
      </Text>
      <Checkbox
        colorScheme='positive'
        isChecked={api.value}
        onChange={() => {
          api.apply(!api.value)
        }}
      />
    </HStack>
  )
}
