import { SquareWithArrowIcon } from 'presentation/components/Icons'
import { SolidCircleInfoIcon } from 'presentation/components/Icons/solid'
import { NoticeButton, NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { OLD_PROPELIO_URL } from 'presentation/const/env.const'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const ListsLayout = () => {
  const updateNotice = useNoticeStore(store => store.updateNotice)

  useEffect(() => {
    updateNotice(
      NOTICE_KEYS.LIST_IN_DEV,
      (
        <ResponsiveNotice
          colorScheme='info'
          icon={SolidCircleInfoIcon}
          textContent={(
            <>
              <NoticeEmphasis>Lists feature is in development!</NoticeEmphasis>
              {' '}
              Thanks for helping to test Propelio. Please give feedback through this
              {' '}
              <NoticeEmphasis
                as='a'
                href='#'
                textDecor='underline'
                onClick={() => {
                  (window as any).Acute('openModal')
                }}
              >
                link
              </NoticeEmphasis>
              {' '}
              or
              {' '}
              <NoticeEmphasis
                as='a'
                href='#'
                textDecor='underline'
                onClick={() => {
                  void SupportWidget.openWidget({ topic: 'beta-feedback' })
                }}
              >
                contact support.
              </NoticeEmphasis>
            </>
          )}
          actionsContent={(
            <>
              <NoticeButton
                variant='solid'
                colorScheme='highlight'
                as='a'
                target='_blank'
                leftIcon={<SquareWithArrowIcon />}
                href={OLD_PROPELIO_URL}
              >
                Visit Old Propelio
              </NoticeButton>
            </>
          )}
        />
      ),
    )

    return () => updateNotice(NOTICE_KEYS.LIST_IN_DEV, null)
  }, [])

  const setHeaderShadow = useLayoutStore(store => store.headerShadow.set)

  useEffect(() => {
    setHeaderShadow(true)

    return () => setHeaderShadow(false)
  }, [])

  return (
    <>
      <Outlet />
    </>
  )
}
