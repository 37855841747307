import { IconProps } from '@chakra-ui/react'
import { BillingCardsPaymentMethodBrands as Brands } from '__generated__/graphql'
import {
  CardAmexDarkIcon,
  CardAmexLightIcon,
  CardDinersDarkIcon,
  CardDinersLightIcon,
  CardDiscoverDarkIcon,
  CardDiscoverLightIcon,
  CardJcbDarkIcon,
  CardJcbLightIcon,
  CardMastercardDarkIcon,
  CardMastercardLightIcon,
  CardUnionDarkIcon,
  CardUnionLightIcon,
  CardVisaDarkIcon,
  CardVisaLightIcon,
} from 'presentation/components/Icons'
import { useThemeSpecificValueFn } from 'presentation/main/themes/hooks/useThemeSpecificValue'
import { ComponentType, FC } from 'react'

type IconType = ComponentType<IconProps>
const CARD_ICONS_RECORD: Record<Brands, readonly [IconType, IconType]> = {
  [Brands.Amex]: [CardAmexLightIcon, CardAmexDarkIcon],
  [Brands.Diners]: [CardDinersLightIcon, CardDinersDarkIcon],
  [Brands.Discover]: [CardDiscoverLightIcon, CardDiscoverDarkIcon],
  [Brands.Jcb]: [CardJcbLightIcon, CardJcbDarkIcon],
  [Brands.Mastercard]: [CardMastercardLightIcon, CardMastercardDarkIcon],
  [Brands.Unionpay]: [CardUnionLightIcon, CardUnionDarkIcon],
  [Brands.Visa]: [CardVisaLightIcon, CardVisaDarkIcon],
} as const

type Props = IconProps & {
  brand?: Brands
}

export const CardLogo: FC<Props> = ({ brand, ...props }) => {
  const { tsv } = useThemeSpecificValueFn()
  if (!brand) return null
  const Icon = tsv(...CARD_ICONS_RECORD[brand])
  if (!Icon) return null
  return <Icon {...props} />
}
