import { Box } from '@chakra-ui/react'
import { CardSelectionSlideProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CardSelectionSlide/CardSelectionSlide.props'
import { ComparableEntryCard, ComparableEntryCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparableEntryCard'

export const CardSelectionSlide = (props: CardSelectionSlideProps) => {
  const {
    selectedEntry,
    entry,
    onCompsRatingChange,
    onSelectedCompChange,
  } = props

  const isSelected = selectedEntry?.comp.id === entry.comp.id
  const shouldBeMuted = !isSelected

  const entryProps: ComparableEntryCardProps = {
    isSubject: entry.comp.type === 'subject-comp',
    comp: entry.comp,
    onCompsRatingChange,
  }
  return (
    <Box
      onClick={() => onSelectedCompChange(entry)}
      minW={0}
      mx={isSelected ? -1.5 : 0}
      w={isSelected ? 'calc(100% + 24px)' : 'full'}
    >
      <ComparableEntryCard
        isSelected={isSelected}
        isMuted={shouldBeMuted}
        {...entryProps}
      />
    </Box>
  )
}
