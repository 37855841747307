import { Button, Center } from '@chakra-ui/react'
import { useState } from 'react'
import { CompsInitializeModal } from '../CompsInitializeModal/CompsInitializeModal'

export const CompsInitializeStep = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <CompsInitializeModal
        isOpen={isModalOpen}
        onCancel={handleCloseModal}
      />

      {!isModalOpen && (
        <Center height='100%' flex='1 1 0'>
          <Button
            colorScheme='positive'
            size='lg'
            variant='solid'
            onClick={handleOpenModal}
          >
            Run Comps
          </Button>
        </Center>
      )}
    </>
  )
}
