import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import { Number, Option } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { SelectMenu, SelectMenuButton, SelectMenuList } from 'presentation/components/SelectMenu/SelectMenu'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'

const SoldWithinFilterCard = () => {
  const soldWithinMonths = NearbyBuyersHooks.useSoldWithinMonths()
  const setSoldWithinMonths = NearbyBuyersHooks.useSetSoldWithinMonths()
  const selectedLabel = NearbyBuyersSoldWithinPresets.toLabel(soldWithinMonths)

  return (
    <FilterCard
      title='Active Within'
    >
      <SelectMenu>
        <SelectMenuButton>
          {selectedLabel}
        </SelectMenuButton>
        <SelectMenuList>
          <MenuOptionGroup
            value={selectedLabel}
            onChange={rawLabelValue => {
              const labelValue = Array.isArray(rawLabelValue) ? rawLabelValue[0] : rawLabelValue
              const value = NearbyBuyersSoldWithinPresets.fromLabel(labelValue)
              setSoldWithinMonths(value)
            }}
          >
            {NearbyBuyersSoldWithinPresets.PRESETS.map(({ label }) => (
              <MenuItemOption key={label} value={label}>
                {label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </SelectMenuList>
      </SelectMenu>
    </FilterCard>
  )
}

export default SoldWithinFilterCard

export namespace NearbyBuyersSoldWithinPresets {
  export type Preset = {
    label: string
    value: Option.Option<number>
  }

  export const PRESETS: Preset[] = [
    { label: 'Any Time', value: Option.none() },
    { label: '1 month', value: Option.some(1) },
    { label: '2 months', value: Option.some(2) },
    { label: '3 months', value: Option.some(3) },
    { label: '6 months', value: Option.some(6) },
    { label: '9 months', value: Option.some(9) },
    { label: '1 year', value: Option.some(12) },
    { label: '1.5 years', value: Option.some(18) },
    { label: '2 years', value: Option.some(24) },
    { label: '5 years', value: Option.some(60) },
  ]

  const DEFAULT = PRESETS[0] // 6 months

  export const toLabel = (value: Option.Option<number>): string => {
    const isEqual = Option.getEquivalence(Number.Equivalence)
    const preset = PRESETS.find(preset =>
      isEqual(preset.value, value),
    ) ?? DEFAULT

    return preset.label
  }

  export const fromLabel = (label: string): Option.Option<number> => {
    const preset = PRESETS.find(preset => preset.label === label)
    return preset?.value ?? Option.none()
  }

}
