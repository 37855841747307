import OwnershipDetailsScreen from 'features/OwnersProperties/view/OwnershipDetailsScreen'
import OwnedPropertiesMobileScreen from 'features/OwnersProperties/view/components/OwnedPropertiesMobileScreen/OwnedPropertiesMobileScreen'
import OwnersPropertiesProviderLive from 'features/OwnersProperties/view/routes/OwnersPropertiesProviderLive'
import OwnersPropertiesRoutes from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes'

namespace OwnersPropertiesRoutesLive {
  export const routes = OwnersPropertiesRoutes.make({
    Container: OwnersPropertiesProviderLive,
    OwnersPropertiesScreen: OwnershipDetailsScreen,
    OwnerPropertiesMobileScreen: OwnedPropertiesMobileScreen,
  })
}

export default OwnersPropertiesRoutesLive
