import { Option } from 'effect'
import NearbyBuyersFilters from 'features/NearbyBuyers/domain/NearbyBuyersFilters'
import NearbyBuyersSorting from 'features/NearbyBuyers/domain/NearbyBuyersSorting'
import NearbyBuyerMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerMachine'
import NearbyBuyerPropertyMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerPropertyMachine'
import NearbyBuyersContext from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersContext'
import NearbyBuyersMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersMachine'
import { PartialRange } from 'features/valueObjects/Range'
import pluralize from 'pluralize'
import PropertyModalService from 'presentation/components/PropertyModal/PropertyModalService'
import { useCallback, useEffect } from 'react'
import { MapRef } from 'react-map-gl'

namespace NearbyBuyersHooks {
  // #region Core
  const { useActorRef, useSelector } = NearbyBuyersContext

  export const useSnapshot = () => useSelector(snap => snap)
  export const useProperty = () => useSelector(snap => snap.context.subjectPropertyDetails)
  export const useAreBuyersLoading = () => useSelector(snap => snap.matches('BuyersLoading'))
  export const useIsBuyerStateInitial = () => useSelector(snap => snap.matches('Initial'))

  export const useSubscribe = <TEvent extends NearbyBuyersMachine.Emitted>(
    type: TEvent['type'],
    handleEvent: (
      event: NearbyBuyersMachine.Emitted & (
        TEvent['type'] extends '*'
          ? {}
          : { type: TEvent['type'] }
      )) => void,
  ) => {
    const actor = useActorRef()

    useEffect(() => {
      actor.on(type, handleEvent)
    }, [])
  }

  export const usePanelState = () => {
    const snap = useSnapshot()

    if (snap.matches('NearbyBuyersLoadedEmpty'))
      return { _tag: 'Empty' } as const

    if (snap.matches('NearbyBuyersLoadedPopulated'))
      return { _tag: 'Populated', data: Option.getOrThrow(snap.context.data) } as const

    if (snap.matches('NoBuyerTypesSelected'))
      return { _tag: 'NoBuyerTypesSelected' } as const

    return { _tag: 'Unready' } as const
  }

  // #endregion

  // #region Buyers
  export const useBuyersData = () => useSelector(snap => snap.context.data)
  export const useBuyersActorRefs = () => {
    const data = useBuyersData()
    return data.pipe(
      Option.map(data => data.nearbyBuyerActorRefs),
    )
  }
  export const useBuyerActorRefsArray = () => {
    const buyers = useBuyersActorRefs()
    return buyers.pipe(Option.getOrElse((): NearbyBuyerMachine.ActorRef[] => []))
  }
  export const useIsDataEmpty = () => {
    const data = useBuyersData()
    return data.pipe(
      Option.isSome,
    )
  }
  // #endregion

  // #region Properties
  export const usePropertyActorRefs = () => {
    const data = useBuyersData()
    return data.pipe(
      Option.map(data => data.nearbyBuyerPropertyActorRefs),
    )
  }

  export const usePropertyActorRefsArray = () => {
    const properties = usePropertyActorRefs()
    return properties.pipe(Option.getOrElse((): NearbyBuyerPropertyMachine.ActorRef[] => []))
  }
  // #endregion

  // #region Filtering/Sorting
  export const useCanFilterOrSort = () => {
    const snap = useSnapshot()
    const canFilter = snap.can({
      type: 'set-partial-filters',
      filters: NearbyBuyersFilters.DEFAULT,
    })

    const canSort = snap.can({
      type: 'set-sorting',
      sorting: NearbyBuyersSorting.DEFAULT,
    })

    return canFilter || canSort
  }

  export const useHasNoBuyerTypeSelected = () =>
    useSelector(snap => snap.matches('NoBuyerTypesSelected'))

  export const useSorting = () => useSelector(snap => snap.context.sorting)
  export const useSetSorting = () => {
    const actor = useActorRef()
    return useCallback((sorting: NearbyBuyersSorting) => {
      actor.send({
        type: 'set-sorting',
        sorting,
      })
    }, [actor.send])
  }

  export const useFilters = () => useSelector(snap => snap.context.filters)
  export const useSetPartialFilters = () => {
    const actor = useActorRef()
    return useCallback((filters: Partial<NearbyBuyersFilters>) => {
      actor.send({
        type: 'set-partial-filters',
        filters,
      })
    }, [actor.send])
  }

  export const useResetFilters = () => {
    const setPartialFilters = useSetPartialFilters()
    return useCallback(() => {
      setPartialFilters(NearbyBuyersFilters.DEFAULT)
    }, [setPartialFilters])
  }

  export const useDistanceMiles = () => useSelector(snap => snap.context.filters.distanceMiles)
  export const useSetDistanceMiles = () => {
    const setPartialFilters = useSetPartialFilters()
    return useCallback((distanceMiles: number) => {
      setPartialFilters({
        distanceMiles,
      })
    }, [setPartialFilters])
  }

  export const useShouldIncludeLandLords = () => useSelector(snap => snap.context.filters.shouldIncludeLandLords)
  export const useToggleShouldIncludeLandLords = () => {
    const filters = useFilters()
    const setPartialFilters = useSetPartialFilters()
    return useCallback((input?: NearbyBuyersFilters['shouldIncludeLandLords']) => {
      const shouldIncludeLandLords = input ?? !filters.shouldIncludeLandLords
      setPartialFilters({
        shouldIncludeLandLords,
      })
    }, [filters, setPartialFilters])
  }

  export const useShouldIncludeFlippers = () => useSelector(snap => snap.context.filters.shouldIncludeFlippers)
  export const useToggleFlippersFilters = () => {
    const filters = useFilters()
    const setPartialFilters = useSetPartialFilters()
    return useCallback((input?: NearbyBuyersFilters['shouldIncludeFlippers']) => {
      const shouldIncludeFlippers = input ?? !filters.shouldIncludeFlippers
      setPartialFilters({
        shouldIncludeFlippers,
      })
    }, [filters, setPartialFilters])
  }

  export const useSoldWithinMonths = () => useSelector(snap => snap.context.filters.soldWithinMonths)
  export const useSetSoldWithinMonths = () => {
    const filters = useFilters()
    const setPartialFilters = useSetPartialFilters()
    return useCallback((soldWithinMonths: NearbyBuyersFilters['soldWithinMonths']) => {
      setPartialFilters({
        soldWithinMonths,
      })
    }, [filters, setPartialFilters])
  }

  export const useDealsRange = () => useSelector(snap => snap.context.filters.dealsRange)
  export const useSetDealsRange = () => {
    const filters = useFilters()
    const setPartialFilters = useSetPartialFilters()
    return useCallback((dealsRange: PartialRange) => {
      setPartialFilters({
        dealsRange,
      })
    }, [filters, setPartialFilters])
  }
  // #endregion

  // #region Save To List
  export const useOpenSaveToListModal = () => {
    const actor = useActorRef()
    return useCallback(() => {
      actor.send({ type: 'open-save-to-list-modal' })
    }, [actor.send])
  }

  export const useCloseSaveToListModal = () => {
    const actor = useActorRef()
    return useCallback(() => {
      actor.send({ type: 'close-save-to-list-modal' })
    }, [actor.send])
  }

  export const useCanSaveToList = () => {
    const snap = useSnapshot()
    return snap.hasTag('can-save-to-list')
  }

  export const useIsSaveToListModalOpen = () => useSelector(snap =>
    snap.matches({ NearbyBuyersLoadedPopulated: 'SaveToListModalOpen' }))
  // #endregion

  // #region Map
  export const useMapMountEvents = () => {
    const actor = useActorRef()

    const onMount = useCallback((mapRef: MapRef) => {
      actor.send({
        type: 'map-mounted',
        mapRef,
      })
    }, [])

    const onUnmount = useCallback(() => {
      actor.send({ type: 'map-unmounted' })
    }, [])

    return {
      onMount,
      onUnmount,
    }
  }
  // #endregion

  // #region List
  export const useListMountEvents = () => {
    const actor = useActorRef()

    const onMount = useCallback((listRef: HTMLDivElement) => {
      actor.send({ type: 'list-mounted', getListRef: () => listRef })
    }, [])

    const onUnmount = useCallback(() => {
      actor.send({ type: 'list-unmounted' })
    }, [])

    return {
      onMount,
      onUnmount,
    }
  }
  // #endregion

  // #region Pagination
  export const useTotalCount = () =>
    useSelector(snap =>
      snap.context.data.pipe(
        Option.flatMap(data => data.pageInfo.totalCount),
      ),
    )

  export const usePageRangeText = () =>
    useSelector((snap): Option.Option<string> => {
      const totalCount = snap.context.data.pipe(
        Option.flatMap(data => data.pageInfo.totalCount),
      )

      if (Option.isNone(totalCount)) {
        return snap.context.data.pipe(
          Option.map(data => data.nearbyBuyerActorRefs.length),
          Option.map(count => `Showing ${pluralize('result', count, true)}`),
        )
      }

      const pageRange = snap.context.pageRange
      const resultsCountText = pluralize('result', totalCount.value, true)

      if (Option.isNone(pageRange))
        return Option.some(resultsCountText)

      return Option.some(`${pageRange.value[0]} - ${pageRange.value[1]} of ${resultsCountText}`)
    })

  export const usePaginationProps = () => {
    const actorRef = useActorRef()

    return useSelector(snap => {
      const data = snap.context.data

      return data.pipe(
        Option.map(data => ({
          onNext: () => actorRef.send({ type: 'go-to-next-page' }),
          onPrev: () => actorRef.send({ type: 'go-to-prev-page' }),
          isPrevDisabled: !data.pageInfo.hasPreviousPage,
          isNextDisabled: !data.pageInfo.hasNextPage,
        })),
      )
    })
  }
  // #endregion

  // #region Property Modal
  export const useOpenPropertyModal = () => {
    const actor = useActorRef()
    return useCallback((params: PropertyModalService.OpenParams) => {
      actor.send({ type: 'open-property-modal', ...params })
    }, [actor.send])
  }
  // #endregion

  // #region Subject Property
  export const useSubjectProperty = () =>
    useSelector(snap => snap.context.subjectPropertyDetails)

  export const useSubjectMlsStatus = () =>
    useSelector(snap => {
      const subjectProperty = snap.context.subjectPropertyDetails

      if (Option.isNone(subjectProperty)) return 'OFF_MARKET'
      return subjectProperty.value.status === 'without-details'
        ? 'OFF_MARKET'
        : subjectProperty.value.mlsListings[0]?.status ?? 'OFF_MARKET'
    })
  // #endregion

  // #region Buyer
  export const useBuyerActorRef = (buyerId: string) =>
    useSelector(snap =>
      Option.fromNullable(
        snap.children[NearbyBuyersMachine.makeNearbyBuyerActorId(buyerId)],
      ),
    )
  // #endregion

  // #region Draggable Panel
  export const useDraggablePanelActorRef = () =>
    useSelector(snap => Option.fromNullable(snap.children.draggablePanel))
  // #endregion
}

export default NearbyBuyersHooks
