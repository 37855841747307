import { HStack, Tag, Text } from '@chakra-ui/react'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import { SelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { useEditListNameModal } from 'presentation/components/EditListNameModal'
import { EditIcon } from 'presentation/components/Icons'

export const ListNameSection = () => {
  const selectListState = useSelectListState()
  const updateNameApi = useEditListNameModal()

  if (!SelectListState.$is('Success')(selectListState)) return null

  const list = selectListState.list

  return (
    <HStack spacing={1} alignItems='center'>
      <Tag colorScheme='muted'>{ListType.toHumanReadable(list.type)}</Tag>
      <Text textStyle='h4' color='graystrong.600'>
        {list.name}
      </Text>
      <EditIcon
        role='button'
        fontSize={3}
        color='neutral.500'
        onClick={() => {
          updateNameApi.open({ listId: list.id, listName: list.name })
        }}
      />
    </HStack>
  )
}
