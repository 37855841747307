namespace RouteIdUtils {
  export const ROUTE_ID_PREFIXES = {
    LIST: '__LIST__',
    PROPERTY: '__PROPERTY__',
    PROPERTY_LOADER: '__PROPERTY_LOADER__',
  } as const

  export type RouteIdPrefix = typeof ROUTE_ID_PREFIXES[keyof typeof ROUTE_ID_PREFIXES]

  export const prefix = <TPrefix extends string>(prefix: TPrefix) =>
    <TID extends string>(id: TID): `${TPrefix}${TID}` =>
      `${prefix}${id}`

  export const prefixList = prefix(ROUTE_ID_PREFIXES.LIST)
  export const prefixProperty = prefix(ROUTE_ID_PREFIXES.PROPERTY)

  export const checkRouteIdPrefix = <TPrefix extends RouteIdPrefix>(prefix: TPrefix) =>
    (id: string): id is `${TPrefix}${string}` =>
      id.startsWith(prefix)

  export const checkPropertyRouteIdPrefix = checkRouteIdPrefix(ROUTE_ID_PREFIXES.PROPERTY)
}

export default RouteIdUtils
