import { createIcon } from '@chakra-ui/react'

export const GlobeIcon = createIcon({
  displayName: 'GlobeIcon',
  viewBox: '0 0 24 25',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19.424 4.98a10.501 10.501 0 1 0-14.85 14.848A10.499 10.499 0 1 0 19.425 4.98ZM2.999 12.403c0-.797.106-1.59.314-2.358.345.74.844 1.38 1.184 2.14.44.977 1.619.706 2.14 1.562.462.76-.032 1.72.314 2.515.251.577.844.704 1.253 1.125.417.426.408 1.01.472 1.564a14.314 14.314 0 0 0 .353 1.95c-3.508-1.233-6.03-4.576-6.03-8.498Zm9 9a9.04 9.04 0 0 1-1.5-.126c.006-.127.008-.245.02-.328.114-.745.488-1.474.991-2.032.497-.55 1.179-.922 1.599-1.547.412-.61.535-1.43.365-2.142-.25-1.051-1.679-1.403-2.45-1.973-.442-.328-.837-.835-1.418-.877-.269-.018-.493.04-.759-.03-.244-.062-.435-.194-.695-.16-.485.064-.79.583-1.312.512-.495-.066-1.004-.645-1.117-1.116-.144-.605.335-.802.848-.856.215-.022.455-.046.66.032.272.1.4.366.643.5.456.25.548-.15.479-.555-.105-.606-.227-.853.314-1.27.375-.289.696-.497.636-1.014-.036-.304-.202-.44-.047-.743.117-.23.44-.438.65-.575.544-.355 2.328-.329 1.6-1.32-.215-.292-.61-.812-.985-.883-.469-.089-.677.434-1.004.665-.337.239-.994.51-1.332.14-.455-.496.301-.658.468-1.005.078-.162 0-.386-.13-.598.17-.071.341-.137.516-.198.11.08.24.13.375.14.313.021.61-.149.883.065.304.235.523.53.926.604.39.07.803-.157.9-.556.058-.243 0-.5-.057-.75a8.945 8.945 0 0 1 4.922 1.51c-.094-.036-.206-.032-.344.033-.285.132-.688.468-.721.802-.038.379.52.432.786.432.398 0 .802-.178.674-.638-.056-.2-.132-.407-.255-.533.295.204.576.425.844.663l-.012.012c-.27.282-.584.504-.769.846-.13.241-.277.356-.54.418-.146.034-.312.047-.434.144-.34.268-.146.91.176 1.103.406.243 1.009.128 1.315-.219.24-.272.381-.744.812-.743.19 0 .372.074.507.206.178.185.143.358.18.588.068.41.43.188.65-.019.16.285.304.579.432.88-.242.348-.434.728-1.016.322-.348-.243-.562-.596-1-.706-.381-.094-.773.004-1.15.069-.429.074-.938.107-1.263.432-.314.313-.48.732-.816 1.047-.647.61-.92 1.274-.501 2.136.403.828 1.246 1.278 2.156 1.219.894-.06 1.823-.578 1.797.72-.01.46.087.779.228 1.206.13.393.122.775.151 1.181.03.476.104.948.223 1.41a8.988 8.988 0 0 1-7.099 3.475Z' fill='currentColor' />
    </svg>
  ),
})
