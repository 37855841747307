import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { PropertyMarkerTrainingCard } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard'
import { usePropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal/PropertyMarkerTrainingModal.api'

export const PropertyMarkerTrainingModal = () => {
  const api = usePropertyMarkerTrainingModal()
  return (
    <ResponsiveFullscreenModal
      isOpen={api.state.status === 'open'}
      onClose={api.actions.close}
      title='Prop Equity and Owners'
    >
      <PropertyMarkerTrainingCard onClose={api.actions.close} />
    </ResponsiveFullscreenModal>
  )
}
