import { chakra } from '@chakra-ui/react'
import { SuperscriptContainer } from 'presentation/components/PropertyMarker/components/containers/SuperscriptContainer'

export const PreforeclosureSup = () => (
  <SuperscriptContainer>
    <chakra.title>Superscript: Preforeclosure</chakra.title>
    <chakra.path
      d='M5,15.5 L5,3.86364 L10.0227,3.86364 C10.8864,3.86364 11.642,4.03409 12.2898,4.375 C12.9375,4.71591 13.4413,5.19508 13.8011,5.8125 C14.161,6.42992 14.3409,7.15151 14.3409,7.97727 C14.3409,8.81061 14.1553,9.5322 13.7841,10.142 C13.4167,10.7519 12.8996,11.2216 12.233,11.5511 C11.5701,11.8807 10.7955,12.0455 9.9091,12.0455 L6.9091,12.0455 L6.9091,9.5909 L9.2727,9.5909 C9.6439,9.5909 9.9602,9.5265 10.2216,9.39773 C10.4867,9.26515 10.6894,9.07765 10.8295,8.83523 C10.9735,8.5928 11.0455,8.30682 11.0455,7.97727 C11.0455,7.64394 10.9735,7.35985 10.8295,7.125 C10.6894,6.88636 10.4867,6.70455 10.2216,6.57955 C9.9602,6.45076 9.6439,6.38636 9.2727,6.38636 L8.1591,6.38636 L8.1591,15.5 L5,15.5 Z'
      fill='marker.strawberrypink'
      fillRule='nonzero'
    />
  </SuperscriptContainer>
)

export const MultiSup = () => (
  <SuperscriptContainer>
    <chakra.title>Superscript: Multi</chakra.title>
    <chakra.path
      d='M5.1009,15.5 L5.1009,13.2273 L9.4418,9.6364 C9.7296,9.39773 9.9759,9.17424 10.1804,8.96591 C10.3887,8.75379 10.5478,8.53598 10.6577,8.3125 C10.7713,8.08902 10.8281,7.84091 10.8281,7.56818 C10.8281,7.26894 10.7637,7.01326 10.6349,6.80114 C10.5099,6.58901 10.3357,6.42614 10.1122,6.3125 C9.8887,6.19508 9.6312,6.13636 9.3395,6.13636 C9.0478,6.13636 8.7902,6.19508 8.5668,6.3125 C8.3471,6.42992 8.1766,6.60227 8.0554,6.82955 C7.9342,7.05682 7.8736,7.33333 7.8736,7.65909 L4.8736,7.65909 C4.8736,6.84091 5.0573,6.13636 5.4247,5.54545 C5.7921,4.95455 6.3111,4.5 6.9815,4.18182 C7.652,3.86364 8.438,3.70455 9.3395,3.70455 C10.2713,3.70455 11.0781,3.85417 11.7599,4.15341 C12.4455,4.44886 12.974,4.86553 13.3452,5.40341 C13.7202,5.94129 13.9077,6.57197 13.9077,7.29545 C13.9077,7.74242 13.8149,8.1875 13.6293,8.63068 C13.4437,9.07008 13.1103,9.5568 12.6293,10.0909 C12.1482,10.625 11.4645,11.2614 10.5781,12 L9.4872,12.9091 L9.4872,12.9773 L14.0327,12.9773 L14.0327,15.5 L5.1009,15.5 Z'
      fill='marker.strawberrypink'
      fillRule='nonzero'
    />
  </SuperscriptContainer>
)

export const VacantSup = () => (
  <SuperscriptContainer>
    <chakra.title>Superscript: Vacant</chakra.title>
    <chakra.polygon
      fill='marker.strawberrypink'
      fillRule='nonzero'
      points='7.0646 4.1 9.451 12.30456 9.5419 12.30456 11.9283 4.1 15.4964 4.1 11.6555 15.73636 7.3374 15.73636 3.4964 4.1'
    />
  </SuperscriptContainer>
)
