import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert'
import { Center, HStack, NumberInput, NumberInputField, RadioGroup, Spinner, VStack } from '@chakra-ui/react'
import { ReskipConfig, ReskipConfigTag } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { useBatchSelectionToSkiptraceCount } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSelectionToSkiptraceCount'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { useBatchSkiptraceAttemptState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceAttemptState'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Radio } from 'presentation/components/Radio/Radio'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { BatchSkiptraceModalState, SkiptraceBreakdownStepPayload, useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { useEffect, useState } from 'react'

import { Match } from 'effect'
import { useBatchSelectionUnskiptracedCount } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSelectionUnskiptracedCount'
import { TriangleExclamationIcon } from 'presentation/components/Icons'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedStateTagged } from 'presentation/libs/hooks/useToastFailedState'

// Type for the internal attempt state
type InternalAttemptState =
  | { status: 'not-attempted' }
  | { status: 'attempted', payload: Omit<SkiptraceBreakdownStepPayload, 'attempt'> }

export const ReskipConfigStepCard = ({
  payload,
}: {
  payload: Extract<BatchSkiptraceModalState, { _tag: 'ReskipConfigStep' }>
}) => {
  const skiptraceActions = useBatchSkiptraceActions()
  const modalActions = useBatchSkiptraceModalActions()
  const [radioValue, setRadioValue] = useState<ReskipConfigTag | null>(null)
  const [daysCount, setDaysCount] = useState<number | null>(120)
  const unskiptracedCountState = useBatchSelectionUnskiptracedCount()
  const toSkiptraceCountState = useBatchSelectionToSkiptraceCount()
  const attemptState = useBatchSkiptraceAttemptState()

  // Track attempt processing state with a more explicit type
  const [internalAttemptState, setInternalAttemptState] = useState<InternalAttemptState>({
    status: 'not-attempted',
  })

  // Watch for changes in the attempt state
  useEffect(() => {
    // If we haven't attempted yet, ignore attempt state changes
    if (internalAttemptState.status === 'not-attempted') return

    if (attemptState._tag === 'Attempted') {
      // Proceed to breakdown step with attempt data
      modalActions.toSkiptraceBreakdownStep({
        ...internalAttemptState.payload,
        attempt: attemptState.attempt,
      })
      // Reset the internal state to prevent re-triggering
      setInternalAttemptState({ status: 'not-attempted' })
    } else if (attemptState._tag === 'Failed') {
      // Show error toast
      toast.error({
        title: 'Failed to attempt batch skiptrace',
        message: TOAST_PRESET.GENERIC_ERROR.message,
      })
      // Do NOT proceed to the next step when attempt fails
      // Just reset the internal state to allow the user to try again
      setInternalAttemptState({ status: 'not-attempted' })
    }
  }, [attemptState, internalAttemptState])

  useEffect(() => {
    skiptraceActions.getSelectionUnskiptracedCount(payload)
  }, [payload])

  useEffect(() => {
    if (!radioValue || daysCount === null) return

    skiptraceActions.getSelectionToSkiptraceCount({
      ...payload,
      reskipConfig: radioValue === 'ReskipIfOlderThanXDays'
        ? { _tag: radioValue, daysCount }
        : { _tag: radioValue },
    })
  }, [radioValue, daysCount])

  const toSkiptraceCount = toSkiptraceCountState._tag === 'Success'
    ? toSkiptraceCountState.count
    : null

  const alreadySkippedCount = unskiptracedCountState._tag === 'Success' && payload.memberIds.length > 0
    ? payload.memberIds.length - unskiptracedCountState.count
    : payload.stats.skiptracedCount

  useEffect(() => {
    if (alreadySkippedCount === 0) {
      // For records that haven't been skipped yet, attempt the batch skiptrace before proceeding
      const newPayload = {
        ...payload,
        reskipConfig: null,
        toSkiptraceCount: payload.memberIds.length > 0
          ? payload.memberIds.length
          : payload.stats.membersCount,
      }

      // Update internal state to indicate we've made an attempt
      setInternalAttemptState({
        status: 'attempted',
        payload: newPayload,
      })

      // Call the attempt action
      skiptraceActions.attemptBatchSkiptrace({
        listId: newPayload.listId,
        memberIds: newPayload.memberIds,
        reskipConfig: newPayload.reskipConfig,
        format: 'narrow', // Default format
        toSkiptraceCount: newPayload.toSkiptraceCount,
        remainingConsumables: 0,
        useFreeSkipsOnly: false,
      })
    }
  }, [alreadySkippedCount])

  const handleAttempt = (newPayload: Omit<SkiptraceBreakdownStepPayload, 'attempt'>) => {
    // Update internal state to indicate we've made an attempt
    setInternalAttemptState({
      status: 'attempted',
      payload: newPayload,
    })

    // Call the attempt action
    skiptraceActions.attemptBatchSkiptrace({
      listId: newPayload.listId,
      memberIds: newPayload.memberIds,
      reskipConfig: newPayload.reskipConfig,
      format: 'narrow', // Default format
      toSkiptraceCount: newPayload.toSkiptraceCount,
      remainingConsumables: 0,
      useFreeSkipsOnly: false,
    })
  }

  useToastFailedStateTagged(unskiptracedCountState, {
    title: 'Failed to get unskiptraced count',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useToastFailedStateTagged(toSkiptraceCountState, {
    title: 'Failed to get to skiptrace count',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  // Determine loading state
  const isAttempting = attemptState._tag === 'Attempting'

  return (
    <Card
      variant='modal.alert'
      size='modal.alert.xn'
      colorScheme='modal.alert.neutral'
    >
      <CardImage src={happyDogPng} />
      <ModalCloseButton
        onClose={modalActions.close}
      />
      {unskiptracedCountState._tag === 'Success' && attemptState._tag !== 'Attempting'
        ? (() => ((
          <>
            <CardPrimaryTitle>
              {alreadySkippedCount}
              {' '}
              of these
              {' '}
              {pluralize('records', alreadySkippedCount)}
              {' '}
              {pluralize('have', alreadySkippedCount)}
              {' '}
              already been skipped.
              {' '}
              What would you like to do?
            </CardPrimaryTitle>
            <CardBody mt={5}>
              {toSkiptraceCount === 0 && (
                <Alert
                  colorScheme='negative'
                  variant='bordered'
                  mb={3}
                >
                  <AlertIcon>
                    <TriangleExclamationIcon />
                  </AlertIcon>
                  <AlertDescription>
                    None of the records you selected are available to skip with the selected options:
                    {' '}
                    they have already been skipped or there is a pending order to skip them.
                  </AlertDescription>
                </Alert>
              )}
              <Card
                borderRadius={4}
                bg='highlight.100'
                p={3}
                boxShadow='primary.m'
              >
                <RadioGroup
                  value={radioValue ?? undefined}
                  onChange={value => {
                    setRadioValue(value as ReskipConfigTag)
                  }}
                >
                  <VStack spacing={2} align='stretch'>
                    <Radio value={'DoNotReskip' satisfies ReskipConfigTag}>Don&apos;t reskip anything</Radio>
                    <Radio value={'Reskip' satisfies ReskipConfigTag}>Reskip everything</Radio>
                    <VStack spacing={0} align='stretch'>
                      <Radio value={'ReskipIfOlderThanXDays' satisfies ReskipConfigTag} alignItems='flex-start'>
                        Reskip if previous skiptrace was:
                      </Radio>
                      <HStack spacing={1} pl={4}>
                        <span>older than</span>
                        <NumberInput
                          pr={2}
                          minW='64px'
                          w='64px'
                          keepWithinRange
                          precision={0}
                          min={0}
                          value={daysCount === null ? '' : daysCount}
                          onBlur={() => {
                            if (daysCount === null) setDaysCount(120)
                          }}
                          onChange={value => {
                            setDaysCount(value === '' ? null : NumberLib.fromStringSafe(value))
                          }}
                          display='inline-block'
                        >
                          <NumberInputField
                            minW='64px'
                            w='64px'
                            pr={2}
                          />
                        </NumberInput>
                        <span>days</span>
                      </HStack>
                    </VStack>
                  </VStack>
                </RadioGroup>
              </Card>
            </CardBody>
            <CardFooter>
              <CardButton
                variant='outline'
                colorScheme='neutral'
                size='md'
                onClick={modalActions.close}
                autoFocus
              >
                Cancel
              </CardButton>
              <CardButton
                variant='solid'
                colorScheme='neutral'
                size='md'
                onClick={() => {
                  if (toSkiptraceCount === null) return

                  const reskipConfig = Match.value(radioValue)
                    .pipe(
                      Match.when(null, () => null),
                      Match.when('Reskip', () => ReskipConfig.Reskip()),
                      Match.when('DoNotReskip', () => ReskipConfig.DoNotReskip()),
                      Match.when('ReskipIfOlderThanXDays', () =>
                        ReskipConfig.ReskipIfOlderThanXDays({ daysCount: daysCount ?? 120 })),
                      Match.exhaustive,
                    )

                  // Attempt batch skiptrace before proceeding to breakdown step
                  handleAttempt({
                    ...payload,
                    toSkiptraceCount,
                    reskipConfig,
                  })
                }}
                autoFocus
                isLoading={
                  (toSkiptraceCountState._tag !== 'Success' && toSkiptraceCountState._tag !== 'Initial')
                  || isAttempting
                }
                isDisabled={toSkiptraceCount === 0 || toSkiptraceCountState._tag === 'Initial'}
                loadingText='Checking...'
                type='submit'
              >
                Next
              </CardButton>
            </CardFooter>
          </>
        )))()
        : (
          <Center>
            <Spinner />
          </Center>
        )}

    </Card>
  )
}
