import { Box } from '@chakra-ui/react'
import { Address } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { loadGoogleMaps } from 'presentation/libs/loadGoogleMaps'
import { TabContainer } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/TabContainer'
import { useEffect, useRef } from 'react'

export type StreetViewProps = {
  location: Location
  address: Address
}
export const StreetView = ({ location }: StreetViewProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    void loadGoogleMaps()
      .then(googleMaps => {
        const propertyLocation = { lat: location.latitude, lng: location.longitude }
        const streetViewService = new google.maps.StreetViewService()
        const STREETVIEW_MAX_DISTANCE = 50

        // Given property coordinates, make streetview suggest panorama data
        void streetViewService.getPanorama({
          location: propertyLocation,
          radius: STREETVIEW_MAX_DISTANCE,
        }, (data, status) => {
          if (status === google.maps.StreetViewStatus.OK) {
            const panoramaLocation = data?.location?.latLng
            // Calculate heading from panorama location to property location
            const heading = panoramaLocation
              && google.maps.geometry.spherical.computeHeading(panoramaLocation, propertyLocation)

            // Open streetview with panorama location and calculated heading
            void new googleMaps.StreetViewPanorama(
              ref.current as HTMLDivElement,
              {
                position: panoramaLocation,
                visible: true,
                ...heading && {
                  pov: {
                    heading,
                    pitch: 0,
                  },
                },
              },
            )
          } else {
            // If no panorama data found, open streetview with just the property location
            void new googleMaps.StreetViewPanorama(
              ref.current as HTMLDivElement,
              {
                position: propertyLocation,
                visible: true,
              },
            )
          }
        })
      })
  }, [])

  return (
    <TabContainer borderRadius='3'>
      <Box h='full' ref={ref} />
    </TabContainer>
  )
}
