import { Grid, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo } from 'presentation/components/molecules/Card/CardCombo'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CardComboBottomSegment, CardComboTopSegment, MarkerLabelDescriptionPairs, MarkerLabelDescriptionPairsProps } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/common'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const PinCombinationStep = () => {
  const { sbp } = useSwitchBreakpointFn()
  const markers: MarkerLabelDescriptionPairsProps[] = [{
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType='HIGH'
        isVacant={false}
        isForeclosure={false}
        isSenior={false}
      />
    ),
    description: <>The property is owner occupied and has high equity</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType='UPSIDE_DOWN'
        isVacant={true}
        isForeclosure={true}
        isSenior={false}
        shouldHideSuperscript
      />
    ),
    description: <>The property is owner-occupied, vacant and has negative equity</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OUT_OF_COUNTRY'
        equityType='LOW'
        isVacant={false}
        isForeclosure={false}
        isSenior={false}
        shouldHideSuperscript
      />
    ),
    description: <>The property&rsquo;s owner is out of town/country and has low equity</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='BANK'
        equityType='UPSIDE_DOWN'
        isVacant={true}
        isForeclosure={false}
        isSenior={false}
      />
    ),
    description: <>The property is owned by a bank, has negative equity, and is vacant</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='MILITARY'
        equityType='FREE_AND_CLEAR'
        isVacant={true}
        isForeclosure={true}
        isSenior={false}
      />
    ),
    description: <>The property is military-based, has clear equity, and is in preforeclosure and vacant</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='TRUST'
        equityType='UPSIDE_DOWN'
        isVacant={true}
        isForeclosure={false}
        isSenior={false}
      />
    ),
    description: <>The property is owned by a trustee, has negative equity and is vacant</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='CORPORATE'
        equityType='HIGH'
        isVacant={false}
        isForeclosure={true}
        isSenior={false}
      />
    ),
    description: <>The property is owned by a corporation, has high equity, and is in preforeclosure</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='IN_STATE'
        equityType='HIGH'
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    description: <>The property is owned by an in-state absentee owner, has high equity</>,
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType='FREE_AND_CLEAR'
        isVacant={true}
        isForeclosure={true}
        isSenior={true}
      />
    ),
    description: <>The property is occupied by senior owner, has clear equity and is vacant</>,
  }]

  return (
    <>
      <Breakpoint
        mob={(
          <CardPrimaryTitle>Property Equity and Ownership </CardPrimaryTitle>
        )}
      />
      <CardPrimaryText>
        To enhance your property research, we&rsquo;ve assigned icons and symbols to
        indicate equity and ownership of your property. These markers and pin
        combinations will help you understand the property&rsquo;s state at a glance.
      </CardPrimaryText>

      <CardCombo
        size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
        mt={2}
      >
        <CardComboTopSegment flexDirection='column'>
          <Text
            textStyle='bodyLFat'
            color='graystrong.600'
            textAlign='center'
          >
            Here are some examples of pin combinations for you to check out:
          </Text>
        </CardComboTopSegment>
        <CardComboBottomSegment>
          <Grid
            gridTemplateColumns={mbp({
              mobSm: '1fr',
              mob: 'repeat(2, 1fr)',
              dtSm: 'repeat(3, 1fr)',
            })}
            gap={3}
          >
            {markers.map((value, index) => (
              <MarkerLabelDescriptionPairs
                key={index}
                marker={value.marker}
                label={value.label}
                description={value.description}
                {...sbp({
                  mob: {
                    ...index === markers.length - 1 && {
                      gridColumn: 'span 2',
                      w: '160px',
                      mx: 'auto',
                    },
                  },
                  tabSm: {
                    ...index === markers.length - 1 && {
                      gridColumn: 'span 2',
                      w: '260px',
                      mx: 'auto',
                    },
                  },
                  dtSm: {
                    ...index === markers.length - 1 && {
                      gridColumn: 'span 1',
                      w: 'auto',
                      mx: '0',
                    },
                  },
                })}
              />
            ))}
          </Grid>
        </CardComboBottomSegment>
      </CardCombo>

    </>
  )
}
