import { Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import GetBuyerDeals from 'features/NearbyBuyers/repository/GetBuyerDeals'
import { GET_BUYER_DEALS_QUERY } from 'features/NearbyBuyers/repository/GetBuyerDeals.graphql'
import GetBuyerDealsSchema from 'features/NearbyBuyers/repository/GetBuyerDeals.schema'
import { apolloClient } from 'presentation/libs/client'

const GetBuyerDealsLive: GetBuyerDeals = ({
  id,
}) =>
  Effect.gen(function * () {
    const response = yield * Effect.tryPromise({
      try: () =>
        apolloClient.query({
          errorPolicy: 'all',
          query: GET_BUYER_DEALS_QUERY,
          variables: {
            id,
          },
        }),
      catch: error => new UnknownException(error),
    })

    return yield * GetBuyerDealsSchema.resultFromGraphQLResult(response.data)
  })

export default GetBuyerDealsLive
