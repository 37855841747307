import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { AppLevelModals } from 'presentation/app/AppLevelModals'
import { PropertyModal } from 'presentation/components/PropertyModal/PropertyModal'
import { ToastContainer } from 'presentation/components/Toast'
import { AppLevelHooks } from 'presentation/main/AppLevelHooks'
import { useEffect } from 'react'

/**
 * Dependent on app providers so while it should be close to top-level,
 * it should also be rendered after all the top-level providers, including
 * the BrowserRouter
 */
export const AppLevelRenders = () => (
  <>
    <GetAccessCaller />
    <AppLevelHooks />
    <AppLevelModals />
    <ToastContainer />
    <PropertyModal />
  </>
)

const GetAccessCaller = () => {
  const getAccess = useAccessStore(state => state.actions.getAccess.execute)

  useEffect(() => {
    void getAccess()
  }, [])

  return null
}
