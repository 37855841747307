import { BoxProps, chakra } from '@chakra-ui/react'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, HTMLProps } from 'react'

// @TODO Add guide for sticky header

export const BIG_TABLE_CARD_BORDER_RADIUS = { mobSm: '4', tabSm: '6.5' } as const

/**
 * @NOTE DO NOT APPLY BORDER RADIUS AND OVERFLOW HIDDEN HERE. It should be
 *   applied from td/th instead. This is because overflow hidden breaks the
 *   sticky lib we're using
 */
export const BigTableCardContainer: FC<BoxProps> = props => (
  <chakra.div
    w='full'
    borderRadius={mbp(BIG_TABLE_CARD_BORDER_RADIUS)}
    boxShadow='primary.s'
    {...props}
  />
)

export type BigTableCardTableProps = BoxProps & HTMLProps<HTMLTableElement>
export const BigTableCardTable: FC<BigTableCardTableProps> = props => (
  <chakra.table
    w='full'
    {...props}
  />
)
export const BigTableCardThead = chakra.thead
export const BigTableCardTbody = chakra.tbody
export const BigTableCardTfoot = chakra.tfoot

/**
 * @TODO Do we need to deal with bg color when using this within thead?
 * @NOTE DO NOT APPLY BACKGROUND COLOR. Apply it to th or td instead,
 *   as we need to apply border radius to background color, but tr doesn't
 *   support border radius
 */
export const BigTableCardTr = chakra.tr

export type BigTableCardThProps = BoxProps & HTMLProps<HTMLTableCellElement>
export const BigTableCardTh: FC<BigTableCardThProps> = ({ sx, ...props }) => (
  <chakra.th
    p={0}
    overflow='hidden'
    sx={{
      'thead > tr:first-of-type > &:first-of-type': {
        borderTopLeftRadius: mbp(BIG_TABLE_CARD_BORDER_RADIUS),
      },
      'thead > tr:first-of-type > &:last-of-type': {
        borderTopRightRadius: mbp(BIG_TABLE_CARD_BORDER_RADIUS),
      },
      ...sx,
    }}
    {...props}
  />
)

export type BigTableCardTdProps = BoxProps & HTMLProps<HTMLTableCellElement>
export const BigTableCardTd: FC<BigTableCardTdProps> = props => (
  <chakra.td
    sx={{
      'tbody > tr:nth-of-type(odd) > &': {
        bg: 'grayweak.100',
      },
      'tbody > tr:nth-of-type(even) > &': {
        bg: 'grayweak.200',
      },
      'tbody > tr:last-of-type > &:first-of-type': {
        borderBottomLeftRadius: mbp(BIG_TABLE_CARD_BORDER_RADIUS),
      },
      'tbody > tr:last-of-type > &:last-of-type': {
        borderBottomRightRadius: mbp(BIG_TABLE_CARD_BORDER_RADIUS),
      },
    }}
    {...props}
  />
)
