import { useEffect, useState } from 'react'

export const useTimedBoolean = (duration: number) => {
  const [isTrue, setIsTrue] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTrue(true)
    }, duration)

    return () => clearTimeout(timeout)
  }, [duration])

  return isTrue
}
