import { Observer } from '@apollo/client'
import { createBrowserInspector } from '@statelyai/inspect'
import { useActorRef, useSelector as useSelectorUnbound } from '@xstate/react'
import { Effect, Runtime } from 'effect'
import { IS_DEV_ENV } from 'presentation/const/env.const'
import React, { useMemo } from 'react'
import { Actor, ActorOptions, AnyActorLogic, InspectionEvent, SnapshotFrom } from 'xstate'

let storedInspector: Observer<InspectionEvent>
const manageInspector = (shouldEnableInspector?: boolean) => {
  if (!shouldEnableInspector || !IS_DEV_ENV) return undefined
  storedInspector = storedInspector || createBrowserInspector().inspect
  return storedInspector
}

export namespace XStateLib {
  export const createRuntimeActorContext = <
    TLogic extends AnyActorLogic,
    TRequirements,
  >(
    makeActorLogic: Effect.Effect<TLogic, never, TRequirements>,
    actorOptions?: ActorOptions<TLogic>,
  ): {
    useSelector: <T>(
      selector: (snapshot: SnapshotFrom<TLogic>) => T,
      compare?: (a: T, b: T) => boolean
    ) => T
    useActorRef: () => Actor<TLogic>
    Provider: (props: {
      children: React.ReactNode
      runtime: Runtime.Runtime<TRequirements>
      shouldEnableInspector?: boolean
      options?: ActorOptions<TLogic>
    }) => React.ReactElement<any, any>
  } => {
    const ReactContext = React.createContext<Actor<TLogic> | null>(null)

    const OriginalProvider = ReactContext.Provider

    const Provider = ({
      children,
      runtime,
      shouldEnableInspector = false,
      options: providedOptions,
    }: {
      children: React.ReactNode
      runtime: Runtime.Runtime<TRequirements>
      options?: ActorOptions<TLogic>
      shouldEnableInspector?: boolean
    }) => {
      const actorLogic = useMemo(() =>
        makeActorLogic.pipe(Runtime.runSync(runtime)),
      [runtime])

      const actorRef = useActorRef(actorLogic, {
        inspect: manageInspector(shouldEnableInspector),
        ...actorOptions,
        ...providedOptions,
      })

      // eslint-disable-next-line react/no-children-prop
      return React.createElement(OriginalProvider, {
        value: actorRef,
        children,
      })
    }

    // TODO: add properties to actor ref to make more descriptive
    Provider.displayName = `ActorProvider`

    const useContext = (): Actor<TLogic> => {
      const actor = React.useContext(ReactContext)

      if (!actor) {
        throw new Error(
          `You used a hook from "${Provider.displayName}" but it's not inside a <${Provider.displayName}> component.`,
        )
      }

      return actor
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
    const useSelector = <T extends unknown>(
      selector: (snapshot: SnapshotFrom<TLogic>) => T,
      compare?: (a: T, b: T) => boolean,
    ): T => {
      const actor = useContext()
      return useSelectorUnbound(actor, selector, compare)
    }

    return {
      Provider,
      useActorRef: useContext,
      useSelector,
    }
  }
}
