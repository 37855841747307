import { FeatureCollection, Polygon } from '@turf/helpers'
import { CMA } from 'features/CMA/CMA.domain'

export const compsBoundaryToGeoJSON = (boundary: CMA.Filters.Boundary): FeatureCollection<Polygon> | null => {
  const coordinates = boundary.lines.map(line =>
    line.map(point =>
      [point.longitude, point.latitude]),
  )
  if (coordinates.length === 0 || coordinates[0].length === 0)
    return null

  return {
    type: 'FeatureCollection',
    features: coordinates.map(coordinate => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinate],
      },
      properties: {},
    })),
  }
}
