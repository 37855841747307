import { Box, BoxProps, Flex, Text, chakra } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { Dollar } from 'libs/dollar'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { ThumbsDownIcon, ThumbsUpIcon } from 'presentation/components/ThumbButtons'
import { SHADOWS } from 'presentation/main/themes/common/shadows.theme'
import { CompsMarkerProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarker/CompsMarker.props'

import { MLSStatus } from 'features/valueObjects/MLSStatus'

export const CompsMarker = (props: CompsMarkerProps) => {
  const mlsStatusProps = getMLSStatusMarkerColor(props.status)
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      position='relative'
    >
      {/**
       * @HACK This will prevent the other markers that happens
       * to be in between the gap of the marker and the popup from
       * being hovered.
       */}
      <Box
        position='absolute'
        h={1}
        top={-1}
        left={0}
        right={0}
      />

      <Box
        gap={0.5}
        w='fit-content'
        borderColor={mlsStatusProps.color}
        borderWidth={0.125}
        borderRadius={1.5}
        boxShadow='float'
      >
        {/**
         * @HACK
         * This portion without border with bg card is important, so that the
         * ArrowDown can go under it and fix the annoying line-on-top-of-triangle
         * issue
         */}
        <Flex
          gap={0.5}
          px={1}
          py={0.5}
          minW={6}
          minH={3.5}
          alignItems='center'
          justifyContent='center'
          pos='relative'
          zIndex='1'
          borderRadius={1.5}
          {...mlsStatusProps}
        >
          {pipe(
            Match.value(props.userRating),
            Match.when('included', () => <ThumbsUpIcon value='up' boxSize={2.5} />),
            Match.when('excluded', () => <ThumbsDownIcon value='down' boxSize={2.5} />),
            Match.orElse(() => null),
          )}
          <Text color='inherit' textStyle='tagL' textAlign='center'>
            {Dollar.formatMax3Digits(props.salePrice || props.listPrice)}
          </Text>
        </Flex>
      </Box>
      <ArrowDown {...mlsStatusProps} />
      {props.isSubjectProperty && (
        <SubjectPropertyMarker markerType='pin' size='sm' />
      )}
    </Flex>
  )
}

const ArrowDown = (props: MLSStatusMarkerColorProps & { isHovered?: boolean }) => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    width='20px'
    height='8px'
    viewBox='0 0 20 8'
    fill='none'
    pos='relative'
    top='-3px'
    alignSelf='center'
    filter={`drop-shadow(${SHADOWS.float})`}
  >
    <chakra.path
      d='M8.75061 7.52867C9.48105 8.15711 10.519 8.15711 11.2494 7.52867L20 1.43051e-06H0L8.75061 7.52867Z'
      fill={props.bgColor}
    />
    <chakra.path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.1492 1.07545L10.6247 6.68889C10.2595 7.00311 9.74052 7.00311 9.3753 6.68889L2.85078 1.07545H17.1492ZM11.2494 7.52867C10.519 8.15711 9.48105 8.15711 8.75061 7.52867L0 1.43051e-06H20L11.2494 7.52867Z'
      fill={props.color}
    />
    <chakra.path
      d='M2.85937 1.07545H17.1523L18.5 0H1.62717L2.85937 1.07545Z'
      fill={props.bgColor}
    />
  </chakra.svg>
)

export type MLSStatusMarkerColorProps = {
  color: string
  bgColor: string
} & Omit<BoxProps, 'color' | 'bgColor'>

export const getMLSStatusMarkerColor = (
  status: MLSStatus,
): MLSStatusMarkerColorProps => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('FOR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('SALE_OR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('PENDING', () => ({
    color: 'marker.oxfordblue',
    bgColor: 'marker.babyblue',
  })),
  Match.when('SOLD', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
  })),
  Match.when('LEASED', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
  })),
  Match.when('EXPIRED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('CANCELED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('WITHDRAWN', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('OFF_MARKET', () => ({
    color: 'marker.dirtywhite',
    bgColor: 'marker.darkgray',
  })),
  Match.exhaustive,
)
