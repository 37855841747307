import { Box, BoxProps } from '@chakra-ui/react'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { SliderMinimized, SliderMinimizedProps } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMinimized'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useRef } from 'react'
import { useHoverDirty } from 'react-use'

export type PhotosSliderProps = {
  height?: number
} & Omit<BoxProps, 'h' | 'height'>
& Omit<SliderMinimizedProps, 'shouldHideControls'>

export const PHOTOS_SLIDER_DEFAULT_HEIGHT = 136
export const PhotosSlider = (props: PhotosSliderProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const isHovering = useHoverDirty(ref)
  const shouldAutoHideControls = useSwitchBreakpoint({
    mobSm: false,
    dtSm: !isHovering,
  }) ?? false

  const { photos, h, height, shouldOpenFullscreenOnSlideClick, ...boxProps } = props
  return (
    <Box
      ref={ref}
      sx={{
        '& .slider-minimized': {
          '.slider-minimized__button-fullscreen': {
            display: 'none',
          },
          '.slider__button-prev, .slider__button-next': {
            h: mbp({
              mobSm: 3,
              mob: 4,
              tabSm: 3,
            }),
            w: mbp({
              mobSm: 3,
              mob: 4,
              tabSm: 3,
            }),
            svg: {
              h: mbp({
                mobSm: 1.5,
                mob: 2,
                tabSm: 1.5,
              }),
              w: mbp({
                mobSm: 1.5,
                mob: 2,
                tabSm: 1.5,
              }),
            },
          },
          '.slider__indicator': {
            textStyle: 'tagM',
          },
        },
      }}
      h='100%'
      minH={0}
      {...boxProps}
    >
      <SliderMinimized
        photos={props.photos}
        h={h || height || PHOTOS_SLIDER_DEFAULT_HEIGHT}
        emptyMessage='This property has no photos'
        shouldHideControls={shouldAutoHideControls}
        shouldOpenFullscreenOnSlideClick={shouldOpenFullscreenOnSlideClick}
      />
    </Box>
  )
}
