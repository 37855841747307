import { BasementType as BasementTypeValueObject } from 'features/CMA/valueObjects/BasementType'
import { ConstructionType } from 'features/CMA/valueObjects/ConstructionType'
import { FireplaceType as FireplaceTypeValueObject } from 'features/CMA/valueObjects/FireplaceType'
import { FoundationType } from 'features/CMA/valueObjects/FoundationType'
import { GarageType } from 'features/CMA/valueObjects/GarageType'
import { HomeType as HomeTypeValueObject } from 'features/CMA/valueObjects/HomeType'
import { PoolType } from 'features/CMA/valueObjects/PoolType'
import { StoryType } from 'features/CMA/valueObjects/StoryType'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { Address as ImportedAddress } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { MLSListing } from 'features/valueObjects/MLSListing'
import { MLSOrganization } from 'features/valueObjects/MLSOrganization'
import { PropelioEstimateStats } from 'features/valueObjects/PropelioEstimateStats'
import { NullableMinRange, PartialRange } from 'features/valueObjects/Range'
import { DomainError, ProgramException } from 'libs/errors'

export namespace CMA {
  // ========================================
  // Report
  // ========================================

  export type Report = {
    leadId: string
    reportId: string

    /**
     * Filter applied to salesList/rentalsList, usually you fine tune this so
     * that the comparables you have are very similar to the subject property.
     */
    filters: Filters

    /**
     * Contains the list of comparable of sold properties and relevant stats
     */
    salesListInfo: ListInfo

    /**
     * Contains the list of comparable of rental properties and relevant stats
     */
    rentalsListInfo: ListInfo

    organizations: MLSOrganization[]

    propelioEstimate: PropelioEstimate | null

    updatedAt: Date
  }

  export type PropelioEstimate = {
    stats: PropelioEstimateStats
  }

  export type EstimateInfo = {
    method: 'override'
    overrideValue: number
  } | {
    method: 'from-avg-per-sqft' | 'from-avg'
  }
  export type EstimateMethod = EstimateInfo['method']

  // ========================================
  // Subject Property
  // ========================================

  export type SubjectComp = Omit<MLSListing, 'mlsInfo'> & {
    type: 'subject-comp'
    /** @IMPORTANT null means comp can't/shoudn't be rated */
    userRating: Comp.UserRating | null
  }

  // ========================================
  // Filters
  // ========================================

  export const DEFAULT_SOLD_WITHIN_MONTHS_MAX = 6
  export const DEFAULT_DISTANCE_MILES_MAX = 0.5
  export type Filters = {
    soldWithinMonths: Filters.SoldWithinMonths
    distanceMiles: Filters.DistanceMiles

    homeType: Filters.HomeType | null
    yearBuilt: Filters.YearBuilt | null
    bedroomsCount: Filters.BedroomsCount | null
    bathroomsCount: Filters.BathroomsCount | null
    livingAreaSqft: Filters.LivingAreaSqft | null
    lotAreaSize: Filters.LotAreaSize | null
    garageSpacesCount: Filters.GarageSpacesCount | null
    fireplacesCount: Filters.FireplacesCount | null
    storiesCount: Filters.StoriesCount | null
    basementType: Filters.BasementType | null
    poolsCount: Filters.PoolsCount | null
    construction: Filters.Construction | null
    foundation: Filters.Foundation | null
    boundary: Filters.Boundary | null
  }

  export namespace Filters {
    export type HomeType = {
      shouldMatchSubjectProperty: boolean
      types: HomeTypeValueObject[]
    }

    export type SoldWithinMonths = {
      range: NullableMinRange
      /** @TODO Delete? See if backend in future decides to use range instead */
      max: number
    }

    export type DistanceMiles = {
      subdivision: string | null
      max: number
    }

    export type YearBuilt = {
      range: PartialRange
    }

    export type BedroomsCount = {
      range: PartialRange | null

      /** @NOTE Not functional. Planned in future */
      shouldMatchSubjectProperty: boolean
    }

    export type BathroomsCount = {
      range: PartialRange | null

      /** @NOTE Not functional. Planned in future */
      shouldMatchSubjectProperty: boolean
    }

    /** Aka House Size */
    export type LivingAreaSqft = {
      range: PartialRange | null
    }

    export type LotAreaSize = {
      sqftRange: PartialRange | null
      unit: 'sqft' | 'acres'
    }

    export type GarageSpacesCount = {
      type: GarageType
      shouldExcludeAll: boolean
      range: PartialRange | null

      /** @NOTE Not functional. Planned in future */
      shouldMatchSubjectProperty: boolean
    }

    export type FireplacesCount = {
      shouldMatchSubjectProperty: boolean
      type: FireplaceTypeValueObject
      shouldExcludeAll: boolean
      range: PartialRange | null
    }

    export type StoriesCount = {
      type: StoryType
      range: PartialRange | null

      /** @NOTE Not functional. Planned in future */
      shouldMatchSubjectProperty: boolean
    }

    export type BasementType = {
      shouldMatchSubjectProperty: boolean
      type: BasementTypeValueObject
      shouldExcludeAll: boolean
    }

    export type PoolsCount = {
      shouldMatchSubjectProperty: boolean
      availablility: 'yes' | 'any'

      /** @NOTE Not functional. Planned in future */
      shouldExcludeAll: boolean
      /** @NOTE Not functional. Planned in future */
      type: PoolType
    }

    export type Construction = {
      type: ConstructionType
    }

    export type Foundation = {
      type: FoundationType
    }

    export type Line = Location[]

    export type Boundary = {
      lines: Line[]
    }
  }

  // ========================================
  // Comp
  // ========================================
  export type Comp =
    | SubjectComp
    | SingleComp
    | SinglePointComps

  export type SingleComp = MLSListing & {
    type: 'single-comp'
    userRating: Comp.UserRating
    distance: number
  }

  export namespace Comp {
    export type Address = ImportedAddress & {
      subdivision: string | null
    }

    export type UserRating = 'included' | 'excluded' | 'undecided'
  }

  // ========================================
  // Singe Point Comps
  // ========================================
  /**
   * @NOTE This is derivative data, not stored in state.
   * A group of comps that share the same location/coordinates.
   * Particularly relevant for condos and rendering multi-comp markers.
   */
  export type SinglePointComps = {
    type: 'single-point-comps'
    location: Location
    comps: (SingleComp | SubjectComp)[]
  }

  // ========================================
  // Comp Types
  // ========================================
  export type CompType = SingleComp['type'] | SinglePointComps['type'] | SubjectComp['type']

  // ========================================
  // List (Sales/Rentals)
  // ========================================

  export type ListType = 'sales' | 'rentals'
  export type ListInfo = {
    subject: SubjectComp
    comps: SingleComp[]
    didExceedMaxResults: boolean
    estimateInfo: EstimateInfo
  }

  // ========================================
  // MLS Info
  // ========================================

  // ========================================
  // CompsStats
  // ========================================

  /**
   * @NOTE This is derivative data, not stored in state.
   */
  export type CompsStats = {
    listPrice: StatMetrics | null
    listPricePerSqft: StatMetrics | null
    salePrice: StatMetrics | null
    salePricePerSqft: StatMetrics | null

    /** Based on salePrice or listPrice in that order */
    pricePerSqft: StatMetrics | null
    /** Based on salePrice or listPrice in that order */
    price: StatMetrics | null

    /**
     * Propelio Estimate
     * @TODO This shouldn't be here, move this to report.propelioEstimate
     */
    // valuationDetails: MinMaxAvg | null

    /** distanceFromSubjectProperty */
    distanceMiles: StatMetrics | null
    /** AKA DOM */
    daysOnMarket: StatMetrics | null

    // ========================================
    // Physical Info
    // ========================================
    bedroomsCount: StatMetrics | null
    bathroomsFullCount: StatMetrics | null
    bathroomsHalfCount: StatMetrics | null
    garageSpacesCount: StatMetrics | null
    buildingAreaSqft: StatMetrics | null
    livingAreaSqft: StatMetrics | null
    lotAreaSqft: StatMetrics | null
    lotAreaAcres: StatMetrics | null
    yearBuilt: StatMetrics | null
  }

  export type StatMetrics = {
    min: number
    max: number
    avg: number
    count: number
    sum: number
  }

  // ========================================
  // Coverage
  // ========================================
  export type CoverageInfo = {
    coveredFipsMap: Partial<Record<string, boolean>>
    incomingStatesMap: Partial<Record<string, boolean>>
  }

  // ========================================
  // Commands/Queries
  // ========================================

  // InitializeLoadReport
  export type InitializeLoadReportPayload = string
  export type InitializeLoadReportInitialized = { status: 'initialized', consumableAttempt: ConsumableAttempt }
  export type InitializeLoadReportResult = InitializeLoadReportInitialized | LoadReportResultSuccess | LoadReportResultNoCoverage

  // LoadReport
  export type LoadReportPayload = {
    id: string
    confirmationKey: string
  }
  export type LoadReportResultSuccess = {
    status: 'success'
    report: Report
  }
  export type LoadReportResultNoCoverage = {
    status: 'no-coverage'
    address: Comp.Address
  }
  /** This means we can't run comps on the property (because we depend mosty on location) */
  export type LoadReportResultNoSubjLocation = {
    status: 'no-subj-location'
    address: Comp.Address
  }
  export type LoadReportResult = LoadReportResultSuccess
    | LoadReportResultNoCoverage
    | LoadReportResultNoSubjLocation

  // FilterComps
  export type FilterCompsPayload = {
    leadId: string
    reportId: string
    /** @IMPORTANT Due to legacy design, we have to pass all filters, not just the one that changed */
    filters: Filters
  }
  export type FilterCompsResult = Report

  // UpdateEstimateInfo
  export type UpdateEstimateInfoPayload = {
    leadId: string
    reportId: string
    type: ListType
    estimateInfo: EstimateInfo
  }
  export type UpdateEstimateInfoResult = void

  // RateCompCommand
  export type RateCompPayload = {
    leadId: string
    reportId: string
    compId: string
    rating: Comp.UserRating
  }

  export type RateCompError = ProgramException
  export type RateCompResult = void

  // GetCompsCoverageInfo
  export type GetCompsCoverageInfoError = ProgramException
  export type GetCompsCoverageInfoResult = CoverageInfo

  // ========================================
  // Errors
  // ========================================
  const domainErrorTag = Symbol('domainErrorTag')
  export class NoCMACoverageError extends DomainError {
    [domainErrorTag] = true
  }
}
