import { createIcon } from '@chakra-ui/react'

export const CardAmexDarkIcon = createIcon({
  displayName: 'CardAmexDarkIcon',
  viewBox: '0 0 47 32',
  path: (
    <>
      <mask
        id='mask0_5192_240524'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='47'
        height='32'
      >
        <rect width='46.6721' height='32' fill='#E3C07F' />
      </mask>
      <g mask='url(#mask0_5192_240524)'>
        <rect width='46.6721' height='32' rx='4' fill='#E3C07F' />
        <path d='M2.37243 8.42401L0.0078125 13.9219H1.5473L1.98361 12.8209H4.52003L4.95408 13.9219H6.52748L4.16511 8.42401H2.37243ZM3.2473 9.70353L4.02043 11.6273H2.4719L3.2473 9.70353V9.70353Z' fill='#9B793A' />
        <path d='M6.69019 13.9206V8.4227L8.87789 8.43083L10.1503 11.9755L11.3923 8.4227H13.5625V13.9206H12.188V9.8695L10.7311 13.9206H9.52567L8.06465 9.8695V13.9206H6.69019Z' fill='#9B793A' />
        <path d='M14.5042 13.9206V8.4227H18.9893V9.6525H15.8931V10.5929H18.9169V11.7504H15.8931V12.727H18.9893V13.9206H14.5042Z' fill='#9B793A' />
        <path d='M19.7839 8.42401V13.9219H21.1584V11.9687H21.7371L23.3852 13.9219H25.0648L23.2563 11.8964C23.9985 11.8337 24.7642 11.1967 24.7642 10.2077C24.7642 9.05072 23.8561 8.42401 22.8426 8.42401H19.7839V8.42401ZM21.1584 9.6538H22.7296C23.1065 9.6538 23.3806 9.94863 23.3806 10.2325C23.3806 10.5978 23.0254 10.8113 22.7499 10.8113H21.1584V9.6538V9.6538Z' fill='#9B793A' />
        <path d='M26.7306 13.9206H25.3271V8.4227H26.7306V13.9206Z' fill='#9B793A' />
        <path d='M30.0579 13.9206H29.755C28.2893 13.9206 27.3994 12.7659 27.3994 11.1943C27.3994 9.58382 28.2794 8.4227 30.1303 8.4227H31.6494V9.72483H30.0747C29.3234 9.72483 28.792 10.3112 28.792 11.2078C28.792 12.2726 29.3996 12.7198 30.275 12.7198H30.6367L30.0579 13.9206Z' fill='#9B793A' />
        <path d='M33.0497 8.42401L30.6851 13.9219H32.2245L32.6609 12.8209H35.1973L35.6313 13.9219H37.2047L34.8424 8.42401H33.0497ZM33.9245 9.70353L34.6977 11.6273H33.1491L33.9245 9.70353Z' fill='#9B793A' />
        <path d='M37.3638 13.9206V8.4227H39.1113L41.3425 11.877V8.4227H42.717V13.9206H41.026L38.7382 10.3759V13.9206H37.3638Z' fill='#9B793A' />
        <path d='M10.019 23.5413V18.0434H14.5042V19.2732H11.408V20.2136H14.4318V21.3711H11.408V22.3477H14.5042V23.5413H10.019Z' fill='#9B793A' />
        <path d='M31.9954 23.5413V18.0434H36.4805V19.2732H33.3843V20.2136H36.3937V21.3711H33.3843V22.3477H36.4805V23.5413H31.9954Z' fill='#9B793A' />
        <path d='M14.6787 23.5413L16.8625 20.8262L14.6267 18.0434H16.3584L17.6899 19.7637L19.0259 18.0434H20.6897L18.4833 20.7923L20.6711 23.5413H18.9398L17.6469 21.848L16.3855 23.5413H14.6787Z' fill='#9B793A' />
        <path d='M20.833 18.044V23.5419H22.2437V21.8058H23.6905C24.9147 21.8058 25.8426 21.1563 25.8426 19.8933C25.8426 18.847 25.1148 18.044 23.8691 18.044H20.833V18.044ZM22.2437 19.2874H23.7673C24.1628 19.2874 24.4455 19.5298 24.4455 19.9204C24.4455 20.2873 24.1643 20.5534 23.7628 20.5534H22.2437V19.2874Z' fill='#9B793A' />
        <path d='M26.4402 18.0434V23.5413H27.8147V21.5881H28.3934L30.0414 23.5413H31.7211L29.9125 21.5158C30.6548 21.4531 31.4204 20.8161 31.4204 19.8271C31.4204 18.6701 30.5123 18.0434 29.4988 18.0434H26.4402V18.0434ZM27.8147 19.2732H29.3858C29.7627 19.2732 30.0369 19.568 30.0369 19.8519C30.0369 20.2172 29.6817 20.4306 29.4062 20.4306H27.8147V19.2732V19.2732Z' fill='#9B793A' />
        <path d='M37.1181 23.5413V22.3477H39.8689C40.2759 22.3477 40.4521 22.1277 40.4521 21.8865C40.4521 21.6554 40.2765 21.4217 39.8689 21.4217H38.6259C37.5454 21.4217 36.9436 20.7634 36.9436 19.7751C36.9436 18.8935 37.4947 18.0434 39.1003 18.0434H41.7769L41.1982 19.2804H38.8832C38.4407 19.2804 38.3045 19.5126 38.3045 19.7344C38.3045 19.9623 38.4729 20.2136 38.8109 20.2136H40.113C41.3175 20.2136 41.8402 20.8968 41.8402 21.7916C41.8402 22.7535 41.2578 23.5413 40.0475 23.5413H37.1181Z' fill='#9B793A' />
        <path d='M42.1616 23.5413V22.3477H44.9123C45.3194 22.3477 45.4956 22.1277 45.4956 21.8865C45.4956 21.6554 45.3199 21.4217 44.9123 21.4217H43.6693C42.5888 21.4217 41.9871 20.7634 41.9871 19.7751C41.9871 18.8935 42.5381 18.0434 44.1437 18.0434H46.7657L46.2416 19.2804H43.9267C43.4842 19.2804 43.348 19.5126 43.348 19.7344C43.348 19.9623 43.5163 20.2136 43.8544 20.2136H45.1565C46.361 20.2136 46.8836 20.8968 46.8836 21.7916C46.8836 22.7535 46.3012 23.5413 45.0909 23.5413H42.1616Z' fill='#9B793A' />
      </g>
    </>
  ),
})
