import { Match, pipe } from 'effect'

/**
   * TRUST: The property is owned by a trust, a fiduciary relationship in which
   *   a trustor gives a trustee the right to hold title to property or assets
   *   for the benefit of a third party, the beneficiary.
   * BANK: The property is owned by a bank. This is often the case when the
   *   property has been seized after a foreclosure.
   * CORPORATE: The property is owned by a corporation.
   * OWNER_OCCUPIED: The owner of the property resides in the property.
   * IN_STATE: The owner of the property resides in the same state as property
   *   but not in the actual property
   * OUT_OF_STATE: The owner of the property resides in a different state than
   *   where the property is located.
   * OUT_OF_COUNTRY: The owner of the property resides in a different country
   *   than where the property is located.
   * MILITARY: The property is owned by a member of the military.
   */
const OWNER_CLASSIFICATION_ARRAY = [
  'TRUST',
  'BANK',
  'CORPORATE',
  'OWNER_OCCUPIED',
  'IN_STATE',
  'OUT_OF_STATE',
  'OUT_OF_COUNTRY',
  'MILITARY',
] as const

export type OwnerClassification = typeof OWNER_CLASSIFICATION_ARRAY[number]

const toStringWithVacancy = (
  isVacant: boolean,
) => (
  ownerClassification: OwnerClassification,
): string => pipe(
  Match.value(ownerClassification),
  Match.when('TRUST', () => 'Trust'),
  Match.when('BANK', () => 'Bank Owned'),
  Match.when('CORPORATE', () => 'Corporate'),
  Match.when('OWNER_OCCUPIED', () => isVacant ? 'Vacant' : 'Owner Occupied'),
  Match.when('IN_STATE', () => 'In State Absentee'),
  Match.when('OUT_OF_STATE', () => 'Out of State'),
  Match.when('OUT_OF_COUNTRY', () => 'Out of Country'),
  Match.when('MILITARY', () => 'Military'),
  Match.exhaustive,
)

const toString = (ownerClassification: OwnerClassification): string =>
  toStringWithVacancy(false)(ownerClassification)

export const OwnerClassification = {
  ARRAY: OWNER_CLASSIFICATION_ARRAY,
  toString,
  toStringWithVacancy,
  toArray: () => OWNER_CLASSIFICATION_ARRAY,
}
