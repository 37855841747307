import { Badge, Box, Button, ButtonProps, Flex, HStack, useMediaQuery } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { selectFiltersCount } from 'features/CMA/infra/react/CMAState.selectors'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { useCompsFiltersPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CMAEntriesViewMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar'
import { CMAPrintButton } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAPrintButton'
import { CompsFilterBarProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/CompsFilterBar.props'
import { BedsAndBathsFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/BedsAndBathsFilterDropdown'
import { DefaultFiltersDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/DefaultFiltersDropdown/DefaultFiltersDropdown'
import { DistanceFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/DistanceFilterDropdown'
import { HomeTypeFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/HomeTypeFilterDropdown'
import { SizeFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SizeFilterDropdown'
import { SoldWithinFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SoldWithinFilterDropdown'
import { YearBuiltFilterDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/YearBuiltFilterDropdown/YearBuiltFilterDropdown'
import { FiltersDisabledAlert } from 'presentation/screens/CompsScreen/components/FiltersDisabledAlert/FiltersDisabledAlert'
import { selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const CompsFilterBar = (props: CompsFilterBarProps) => {
  const {
    ...boxProps
  } = props

  const activeTab = useActiveEntriesTab(selectActiveTab)
  const isReportOpen = activeTab === 'REPORT'
  const filterPanel = useCompsFiltersPanelStore()
  const [isVeryWide] = useMediaQuery('(min-width: 1900px)')

  return (
    <Flex
      alignItems='center'
      justifyContent={mbp({ mobSm: 'flex-end', tabSm: 'flex-start' })}
      {...boxProps}
    >
      {isReportOpen
        ? (
          <HStack flex='1' spacing={2} justify='flex-end'>
            <FiltersDisabledAlert />
            <CMAPrintButton size='lg' />
          </HStack>
        )
        : (
          <>
            <HStack
              minW={mbp({
                mobSm: '0',
                dtSm: '608px',
                dtLg: '732px',
              })}
            >
              <Breakpoint
                tabSm={(
                  <>
                    <HomeTypeFilterDropdown />
                    <SoldWithinFilterDropdown />
                  </>
                )}
                dtSm={(
                  <>
                    <HomeTypeFilterDropdown />
                    <SoldWithinFilterDropdown />
                    <DistanceFilterDropdown />
                  </>
                )}
                dtLg={(
                  <>
                    <HomeTypeFilterDropdown />
                    <SoldWithinFilterDropdown />
                    <DistanceFilterDropdown />
                    <YearBuiltFilterDropdown />
                    {isVeryWide && (
                      <>
                        <BedsAndBathsFilterDropdown />
                        <SizeFilterDropdown />
                      </>
                    )}
                  </>
                )}
              />
              <Breakpoint
                tabSm={(
                  <AllFiltersButton
                    onClick={filterPanel.openPanel}
                    size='md'
                  >
                    All Filters
                  </AllFiltersButton>
                )}
                dtLg={(
                  <AllFiltersButton
                    onClick={filterPanel.openPanel}
                    size='xl'
                  >
                    All Filters
                  </AllFiltersButton>
                )}
              />
            </HStack>

            <HStack
              flex='1 1 0'
              minW={mbp({
                mobSm: '0',
                dtLg: '348px',
              })}
              spacing={0}
            >

              <Breakpoint
                mobSm={(
                  <DefaultFiltersDropdown
                    minW='118px'
                  />
                )}
                mob={(
                  <DefaultFiltersDropdown
                    minW='118px'
                    size='md'
                  />
                )}
                tabSm={(
                  <>
                    <DefaultFiltersDropdown
                      ml='auto'
                      size='md'
                    />
                    <CMAEntriesViewMenu size='md' />
                    <Box pl={1}>
                      <CMAPrintButton size='lg' />
                    </Box>
                  </>
                )}
                dtLg={(
                  <>
                    <DefaultFiltersDropdown
                      ml='auto'
                      size='xl'
                      w='184px'
                    />
                    <CMAEntriesViewMenu size='xl' />
                    <Box pl={1}>
                      <CMAPrintButton size='lg' />
                    </Box>
                  </>
                )}
              />
            </HStack>
          </>
        )}
    </Flex>
  )
}

const AllFiltersButton = ({ children, ...props }: ButtonProps) => {
  const filtersCount = useCMAStore(selectFiltersCount)

  return (
    <Button
      bgColor='card.bg.1'
      variant='outline'
      colorScheme='neutral'
      size='sm'
      sx={{
        '&.chakra-menu__menu-button': {
          px: 2,
        },
      }}
      pos='relative'
      {...props}
    >
      {children}
      {filtersCount !== 0 && filtersCount !== null && (
        <Badge
          colorScheme='positivesat'
          pos='absolute'
          top={-1}
          right={-1}
        >
          {filtersCount}
        </Badge>
      )}
    </Button>
  )
}
