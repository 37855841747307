import { Box, BoxProps } from '@chakra-ui/react'
import { isNumber } from 'lodash/fp'
import { setupContext } from 'presentation/utils/context'
import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useId, useRef, useState } from 'react'

type StaggerOpts = {
  /** In seconds, amount of time to wait after the preceding animation starts */
  stagger?: number

  /**
   * Multiplier for stagger, to adjust stagger relative to default stagger
   */
  staggerFactor?: number | keyof typeof STAGGER_FACTOR_PRESETS
}

const STAGGER_FACTOR_PRESETS = {
  longer: 2,
  long: 1.5,
  shorter: 0.5,
  short: 0.25,
}

type AnimationEntry = {
  id: string

  /**
   * When set to true, it means that the entry is ready to animate.
   *
   * This also needs to be true for all preceding entries that are in view,
   * before a specific entry can animate.
   */
  hasAnimationInitiated: boolean

  /**
   * Used to check when gathering all preceding entries that should be waited on
   * before current entry can animate.
   *
   * In this logic we only need to check if the entry is in view, not if it has
   */
  isInView: boolean | null

  /**
   * Used to trigger animation, only need for the element to enter view once
   */
  didEnterViewOnce: boolean | null
}

type ScrollAnimationState = {
  entries: AnimationEntry[]
  setEntries: Dispatch<SetStateAction<AnimationEntry[]>>
}

const [Provider, useContext] = setupContext<ScrollAnimationState>('ScrollAnimation', {
  entries: [],
  setEntries: () => [],
})

export const ScrollAnimationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [entries, setEntries] = useState<AnimationEntry[]>([])

  return (
    <Provider
      entries={entries}
      setEntries={setEntries}
    >
      {children}
    </Provider>
  )
}

const DEFAULT_STAGGER = 0.3

/**
 * @TOIMPROVE Initiate animation for elements that are already scrolled past
 * @TOIMPROVE Order the entries by their position in the DOM vs the time
 *   they were registered
 *
 * @param param0
 * @returns
 */
export const useScrollAnimation = <E extends Element>({
  stagger: rawStagger = DEFAULT_STAGGER,
  staggerFactor: rawStaggerFactor,
}: StaggerOpts) => {
  // =============================================================================
  // Calculate stagger
  // =============================================================================
  const staggerFactor = isNumber(rawStaggerFactor)
    ? rawStaggerFactor
    : typeof rawStaggerFactor === 'string'
      ? STAGGER_FACTOR_PRESETS[rawStaggerFactor]
      : 1

  const stagger = staggerFactor * rawStagger

  // =============================================================================
  // Prepare variables
  // =============================================================================
  const { entries, setEntries } = useContext()
  const id = useId()
  const ref = useRef<E | null>(null)

  const updateCurrentEntry = (update: (entry: AnimationEntry) => AnimationEntry) =>
    setEntries(entries =>
      entries.map(entry =>
        entry.id === id
          ? { ...entry, ...update(entry) }
          : entry,
      ),
    )

  // =============================================================================
  // Register animation entry
  // =============================================================================
  useEffect(() => {
    const newStaggerEntry: AnimationEntry = {
      id,
      hasAnimationInitiated: false,
      isInView: null,
      didEnterViewOnce: null,
    }

    setEntries(entries => [...entries, newStaggerEntry])

    return () => {
      setEntries(entries => entries.filter(entry => entry.id !== id))
    }
  }, [])

  // =============================================================================
  // Observer element entering to view
  // =============================================================================
  useEffect(() => {
    const el = ref.current

    if (!el) return

    const observer = new IntersectionObserver(([observerEntry]) => {
      const isInView = observerEntry.isIntersecting

      updateCurrentEntry(entry => ({
        ...entry,
        isInView,
        didEnterViewOnce: entry.didEnterViewOnce ? true : isInView,
      }))
    }, {
      threshold: 0,
    })

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [])

  // =============================================================================
  // Begin animation when requirements are met
  // =============================================================================

  /**
   * Entries that are required to have begun animation first, before current
   * entry can animate.
   *
   * Consists of all entries before current entry that are ALSO in the view.
   */
  const currentEntryIndex = entries.findIndex(entry => entry.id === id)
  const currentEntry = currentEntryIndex === -1 ? null : entries[currentEntryIndex]
  const requiredEntries = entries.slice(0, currentEntryIndex)
    .filter(entry => entry.isInView)
  const areRequiredEntriesDone = requiredEntries.every(entry => entry.hasAnimationInitiated)
  const areRequirementsMet = !!currentEntry && areRequiredEntriesDone && !!currentEntry?.didEnterViewOnce

  useEffect(() => {
    if (!areRequirementsMet) return

    const timeoutId = window.setTimeout(() => {
      updateCurrentEntry(entry => ({
        ...entry,
        isReady: true,
        hasAnimationInitiated: true,
      }))
    }, stagger * 1000)

    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [areRequirementsMet])

  const isReady = !!currentEntry?.hasAnimationInitiated

  return {
    /** Signals consumer to initiate animation */
    isReady,

    /** Ref to attach that is needed to be in view for animation */
    ref,
  }
}

export const ScrollAnimation: FC<PropsWithChildren<StaggerOpts & {
  className?: string
  sx?: BoxProps['sx']
}>> = ({
  children,
  className,
  sx,
  stagger,
  staggerFactor,
}) => {
  const { ref, isReady } = useScrollAnimation<HTMLDivElement>({ stagger, staggerFactor })

  return (
    <Box ref={ref}>
      <Box
        className={className}
        sx={{
          animationPlayState: isReady ? 'running' : 'paused',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
