import { Box, Flex } from '@chakra-ui/react'
import { CollapsibleSegment, CollapsibleSegments, FixedSegment } from 'presentation/components/CollapsibleSegments/CollapsibleSegments'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { useSearchHistoryListPanelStore } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { SidePanelMetaButton } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistorySidePanel/components/SidePanelMetaButton'
import { SearchHistoryList } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryList'
import { SearchHistoryPagination } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryPagination'
import { SearchHistoryToolbar } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryToolbar'

export const SidePanelDefaultMode = () => {
  const { isPanelOpen } = useSearchHistoryListPanelStore()
  return (
    <CollapsibleSegments pointerEvents='none'>
      <Box boxShadow='primary.s' pointerEvents='auto' borderBottomRightRadius={4}>
        <FixedSegment>
          <SearchHistoryToolbar
            colorScheme='sidepanel'
            p={3}
          />
        </FixedSegment>

        <CollapsibleSegment isOpen={isPanelOpen}>
          <Flex h='full' flexDirection='column' align='stretch' justify='stretch' bgColor='accent.hover'>
            <ScrollWithFade
              containerProps={{
                h: 'full',
              }}
              px={3}
              pb={3}
              fadeColor='rgb(0, 0, 0, 0.15)'
              fadeSize={3}
            >
              <SearchHistoryList />
            </ScrollWithFade>

            <SearchHistoryPagination />
          </Flex>
        </CollapsibleSegment>

        <FixedSegment>
          <SidePanelMetaButton />
        </FixedSegment>
      </Box>
    </CollapsibleSegments>
  )
}
