import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const InStateAbsenteeIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M10.41047,4.52513 C11.05747,3.86875 11.93497,3.5 12.84997,3.5 L15.14997,3.5 C16.06497,3.5 16.94247,3.86875 17.58947,4.52513 C18.23647,5.1815 18.59997,6.07174 18.59997,7 L18.59997,8.16667 L22.04997,8.16667 C22.96497,8.16667 23.84247,8.53542 24.48957,9.19179 C25.13647,9.84817 25.49997,10.7384 25.49997,11.6667 L25.49997,21 C25.49997,21.9283 25.13647,22.8185 24.48957,23.4749 C23.84247,24.1313 22.96497,24.5 22.04997,24.5 L5.95,24.5 C5.035,24.5 4.15748,24.1313 3.51048,23.4749 C2.86348,22.8185 2.5,21.9283 2.5,21 L2.5,11.6667 C2.5,10.7384 2.86348,9.84817 3.51048,9.19179 C4.15748,8.53542 5.035,8.16667 5.95,8.16667 L9.4,8.16667 L9.4,7 C9.4,6.07174 9.76348,5.1815 10.41047,4.52513 Z M7.1,10.5 L5.95,10.5 C5.645,10.5 5.3525,10.6229 5.13683,10.8417 C4.92116,11.0605 4.8,11.3572 4.8,11.6667 L4.8,21 C4.8,21.3094 4.92116,21.6062 5.13683,21.825 C5.3525,22.0437 5.645,22.1667 5.95,22.1667 L7.1,22.1667 L7.1,10.5 Z M9.4,22.1667 L18.59997,22.1667 L18.59997,10.5 L9.4,10.5 L9.4,22.1667 Z M20.89997,10.5 L20.89997,22.1667 L22.04997,22.1667 C22.35497,22.1667 22.64747,22.0437 22.86317,21.825 C23.07887,21.6062 23.19997,21.3094 23.19997,21 L23.19997,11.6667 C23.19997,11.3572 23.07887,11.0605 22.86317,10.8417 C22.64747,10.6229 22.35497,10.5 22.04997,10.5 L20.89997,10.5 Z M11.69997,8.16667 L11.69997,7 C11.69997,6.69058 11.82117,6.39383 12.03687,6.17504 C12.25247,5.95625 12.54497,5.83333 12.84997,5.83333 L15.14997,5.83333 C15.45497,5.83333 15.74747,5.95625 15.96317,6.17504 C16.17887,6.39383 16.29997,6.69058 16.29997,7 L16.29997,8.16667 L11.69997,8.16667 Z'
      fill='marker.gold'
    />
  </MarkerIconContainer>
)
