type NearbyBuyersSorting = NearbyBuyersSorting.SortTypeWithOrder<
  'yearsOfOwnership'
  | 'purchaseAmount'
  | 'soldDate'
  | 'estimatedValue'
>

namespace NearbyBuyersSorting {
  export const DEFAULT: SortTypeWithOrder<'yearsOfOwnership'> = {
    type: 'yearsOfOwnership',
    order: 'desc',
  }

  export type SortOrder = 'asc' | 'desc'

  export type SortTypeWithOrder<T extends string> = {
    type: T
    order: SortOrder
  }
}

export default NearbyBuyersSorting
