import { Box, BoxProps, Flex, MenuButton, MenuItemOption, MenuOptionGroup, Text, TextProps } from '@chakra-ui/react'
import { Match } from 'effect'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { SwitchTabButton, SwitchTabButtonProps, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useSwitchTabsContext } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { useReportDataSwitcherStore } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { useReportTypeSwitcherStore } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportTypeSwitcherStore'
import { CompsReportTable } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CompsReportTableBase'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { PropsWithChildren } from 'react'
import { pipe } from 'remeda'

const REPORT_TAB_KEYS = {
  SOLD_LEASED_KEY: 'SOLD',
  PENDING_KEY: 'PENDING',
  ACTIVE_KEY: 'ACTIVE',
  OFF_MARKET_KEY: 'OFF_MARKET',
}

export const CompsReportTables = (props: BoxProps) => (
  <Breakpoint
    mobSm={<CompsReportTableTabbed {...props} />}
    tabSm={<CompsReportTableGroup {...props} />}
  />
)
export const CompsReportTableGroup = (props: BoxProps) => {
  const report = useReportDataSwitcherStore()

  if (report.status !== 'loaded') return null

  const shouldShowSoldReport = report.soldReportData.items.length > 0 || report.leasedReportData.items.length > 0

  const shouldShowPendingReport = report.pendingReportData.items.length > 0

  const shouldShowActiveReport = report.activeReportData.items.length > 0

  const shouldShowOffmarketReport = report.offMarketReportData.items.length > 0

  const shouldShowReports = shouldShowActiveReport
    || shouldShowSoldReport
    || shouldShowPendingReport
    || shouldShowOffmarketReport

  return shouldShowReports
    ? (
      <Box
        {...props}
        sx={{
          /**
           * @HACK flex gap causes overlap issues on Firefox Print, so we resorted to this
           */
          '& > div + div': {
            mt: 3,
          },
        }}
      >
        {shouldShowSoldReport && (
          <Box className='print__break_before'>
            <CompsSoldReportHeader />
            <CompsSoldReportTable mt={3} />
          </Box>
        )}

        {shouldShowPendingReport && (
          <Box className='print__break_before'>
            <ReportHeader>
              Pending Properties
            </ReportHeader>
            <CompsPendingReportTable mt={3} />
          </Box>
        )}
        {shouldShowActiveReport && (
          <Box className='print__break_before'>
            <ReportHeader>
              Active Properties
            </ReportHeader>
            <CompsActiveReportTable mt={3} />
          </Box>
        )}
        {shouldShowOffmarketReport && (
          <Box className='print__break_before'>
            <ReportHeader>
              Off Market Properties
            </ReportHeader>
            <CompsOffMarketReportTable mt={3} />
          </Box>
        )}
      </Box>
    )
    : null
}

const CompsSoldReportHeader = () => {
  const reportType = useReportTypeSwitcherStore(store => store.reportType)
  return (
    <ReportHeader>
      {reportType === 'RENTALS' ? 'Leased' : 'Sold'}
      {' '}
      Properties
    </ReportHeader>
  )
}

const CompsSoldReportTable = (props: BoxProps) => {
  const reportType = useReportTypeSwitcherStore(store => store.reportType)
  const report = useReportDataSwitcherStore()
  if (report.status !== 'loaded') return null

  const result = reportType === 'RENTALS'
    ? report.leasedReportData
    : report.soldReportData
  return <CompsReportTable report={result} {...props} />
}

const CompsPendingReportTable = (props: BoxProps) => {
  const report = useReportDataSwitcherStore()
  if (report.status !== 'loaded') return null

  const result = report.pendingReportData
  return <CompsReportTable report={result} {...props} />
}

const CompsActiveReportTable = (props: BoxProps) => {
  const report = useReportDataSwitcherStore()
  if (report.status !== 'loaded') return null

  const result = report.activeReportData
  return <CompsReportTable report={result} {...props} />
}

const CompsOffMarketReportTable = (props: BoxProps) => {
  const report = useReportDataSwitcherStore()
  if (report.status !== 'loaded') return null

  const result = report.offMarketReportData
  return <CompsReportTable report={result} {...props} />
}

const ReportHeader = (props: TextProps) => (
  <Flex
    {...mbpg({
      mobSm: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 2,
      },
      tabSm: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
      },
    })}
  >
    <Text
      textStyle='h4'
      color='graystrong.500'
      {...props}
    />

    <Flex alignItems='center' gap={1}>
      <RegularCircleExclamationIcon boxSize={3} color='neutral.600' />
      <Text
        textStyle={mbp({ mobSm: 'bodyM', tabSm: 'bodyL' })}
        color='graystrong.200'
      >
        Only properties marked with a thumbs-up are included in the report.
      </Text>
    </Flex>
  </Flex>
)

const CompsReportTableTabbed = (props: BoxProps) => {
  const report = useReportDataSwitcherStore()

  if (report.status !== 'loaded') return null

  const shouldShowSoldReport = report.soldReportData.items.length > 0
    || report.leasedReportData.items.length > 0

  const shouldShowPendingReport = report.pendingReportData.items.length > 0

  const shouldShowActiveReport = report.activeReportData.items.length > 0

  const shouldShowOffmarketReport = report.offMarketReportData.items.length > 0

  const shouldShowReports = shouldShowActiveReport
    || shouldShowSoldReport
    || shouldShowPendingReport
    || shouldShowOffmarketReport

  const moreButton = (
    <MoreTabButton>
      {shouldShowSoldReport && (
        <MenuItemOption value={REPORT_TAB_KEYS.SOLD_LEASED_KEY}><SoldLeasedText /></MenuItemOption>
      )}
      {shouldShowPendingReport && (
        <MenuItemOption value={REPORT_TAB_KEYS.PENDING_KEY}>Pending</MenuItemOption>
      )}
      {shouldShowActiveReport && (
        <MenuItemOption value={REPORT_TAB_KEYS.ACTIVE_KEY}>Active</MenuItemOption>
      )}
      {shouldShowOffmarketReport && (
        <MenuItemOption value={REPORT_TAB_KEYS.OFF_MARKET_KEY}>Off Market</MenuItemOption>
      )}
    </MoreTabButton>
  )

  return shouldShowReports
    ? (
      <SwitchTabsWrapper
        initialActiveTabKey={(() => {
          if (shouldShowSoldReport) return REPORT_TAB_KEYS.SOLD_LEASED_KEY
          if (shouldShowPendingReport) return REPORT_TAB_KEYS.PENDING_KEY
          if (shouldShowActiveReport) return REPORT_TAB_KEYS.ACTIVE_KEY
          if (shouldShowOffmarketReport) return REPORT_TAB_KEYS.OFF_MARKET_KEY
          return ''
        })()}
        {...props}
      >
        <SwitchTabButtons
          sx={{
            '& .TabButton .chakra-text': {
              textStyle: 'bodyMFat',
            },

          }}
        >
          <Breakpoint
            mobSm={(
              <>
                {(shouldShowSoldReport || shouldShowActiveReport || shouldShowOffmarketReport) && <FirstTabButton />}
                {(shouldShowSoldReport || shouldShowActiveReport || shouldShowOffmarketReport) && moreButton}
              </>
            )}
            mob={(
              <>
                {shouldShowSoldReport && <SoldLeasedTabButton />}
                {shouldShowPendingReport && <PendingTabButton />}
                {shouldShowActiveReport && <ActiveTabButton />}
                {shouldShowOffmarketReport && <OffMarketTabButton />}
              </>
            )}
          />
        </SwitchTabButtons>
        <SwitchTabContents
          borderBottomRadius={3}
          overflow='hidden'
          sx={{
            '& table > thead > tr:first-of-type > th:first-of-type': {
              borderTopLeftRadius: 0,
            },
          }}
        >
          <SwitchTabContent>
            <CompsSoldReportTable />
          </SwitchTabContent>
          <SwitchTabContent>
            <CompsPendingReportTable />
          </SwitchTabContent>
          <SwitchTabContent>
            <CompsActiveReportTable />
          </SwitchTabContent>
          <SwitchTabContent>
            <CompsOffMarketReportTable />
          </SwitchTabContent>
        </SwitchTabContents>
      </SwitchTabsWrapper>
    )
    : null
}

const SoldLeasedTabButton = (props: SwitchTabButtonProps) => (
  <SwitchTabButton tabKey={REPORT_TAB_KEYS.SOLD_LEASED_KEY} {...props}>
    <SoldLeasedText />
  </SwitchTabButton>
)

const PendingTabButton = (props: SwitchTabButtonProps) => (
  <SwitchTabButton tabKey={REPORT_TAB_KEYS.PENDING_KEY} {...props}>
    Pending
  </SwitchTabButton>
)

const ActiveTabButton = (props: SwitchTabButtonProps) => (
  <SwitchTabButton tabKey={REPORT_TAB_KEYS.ACTIVE_KEY} {...props}>
    Active
  </SwitchTabButton>
)

const OffMarketTabButton = (props: SwitchTabButtonProps) => (
  <SwitchTabButton tabKey={REPORT_TAB_KEYS.OFF_MARKET_KEY} {...props}>
    Off Market
  </SwitchTabButton>
)

const FirstTabButton = () => {
  const { activeTabKey } = useSwitchTabsContext()
  return pipe(
    Match.value(activeTabKey),
    Match.when(REPORT_TAB_KEYS.ACTIVE_KEY, () => <ActiveTabButton />),
    Match.when(REPORT_TAB_KEYS.OFF_MARKET_KEY, () => <OffMarketTabButton />),
    Match.when(REPORT_TAB_KEYS.PENDING_KEY, () => <PendingTabButton />),
    Match.orElse(() => <SoldLeasedTabButton />),
  )
}

export const MoreTabButton = ({ children }: PropsWithChildren) => {
  const { activeTabKey, setActiveTabKey } = useSwitchTabsContext()

  const handleOnChange = (value: string | string[]) => {
    if (Array.isArray(value)) return
    setActiveTabKey(value)
  }

  return (
    <Menu>
      <SwitchTabButton as={MenuButton} hasContent={false}>More+</SwitchTabButton>

      <ResponsiveMenuList>
        <MenuOptionGroup defaultValue={activeTabKey || ''} type='radio' onChange={handleOnChange}>
          {children}
        </MenuOptionGroup>
      </ResponsiveMenuList>
    </Menu>
  )
}

const SoldLeasedText = () => {
  const reportType = useReportTypeSwitcherStore(store => store.reportType)
  return <>{reportType === 'RENTALS' ? 'Leased' : 'Sold'}</>
}
