import { useQuery } from '@apollo/client'
import { Box, Flex, Image } from '@chakra-ui/react'
import MisterSecurePng from 'presentation/assets/img/3d/mister-secure.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ResponsiveFullscreenModalV1 } from 'presentation/components/ResponsiveFullscreenModal'
import { toast } from 'presentation/components/Toast'
import { CardBody, CardButton, CardFooter, CardFullWidthSection, CardHeader, CardPrimaryTitle, CardSecondarySupportingText, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { openAddPaymentModal } from 'presentation/screens/Billing/Billing.modals'
import { PaymentCardList } from 'presentation/screens/Billing/components/PaymentCardList'
import { SecurePaymentIconsSection } from 'presentation/screens/Billing/components/SecurePaymentIconsSection'
import { CARDS_VIEW__GET_PAYMENT_METHODS } from 'presentation/screens/Billing/queries'
import { is } from 'presentation/utils/graphql'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, useEffect } from 'react'
import { AddPaymentFlowButton } from '../../../screens/Billing/components/AddPaymentFlowButton'
import { UpdatePaymentModalProps } from './UpdatePaymentModal.types'

export const UpdatePaymentModal: FC<UpdatePaymentModalProps> = ({ isOpen = false, onClose }) => {
  const { data, error, loading } = useQuery(CARDS_VIEW__GET_PAYMENT_METHODS)

  const { mfv } = useMobFirstBreakpointValue()

  const paymentMethods = data?.myEnterprise?.paymentMethods?.edges
    .map(e => e?.node)
    .filter(is('BillingCardsPaymentMethod'))

  const handleOnAddPaymentButtonClicked = () =>
    openAddPaymentModalWired()

  // React to errors
  useEffect(() => {
    if (!error) return

    toast({
      status: 'error',
      title: 'We couldn’t load your payment methods.',
      message: error.message,
    })
  }, [error])

  return (
    <ResponsiveFullscreenModalV1
      isOpen={!!isOpen}
      onClose={onClose}
      variant='default'
      colorScheme='modal.highlight'
      title='Update Primary Card'
      size='modal.default.n'
      footer={(
        <CardFooter>
          <CardButton
            variant='outline'
            colorScheme='neutral'
            onClick={onClose}
          >
            Cancel
          </CardButton>

          <CardButton
            variant='solid'
            colorScheme='positive'
            onClick={onClose}
            isDisabled={paymentMethods?.length === 0}
            autoFocus
          >
            {paymentMethods?.length === 0 ? 'Add Card First' : 'Change Primary'}
          </CardButton>
        </CardFooter>
      )}
    >

      <Image
        src={MisterSecurePng}
        boxSize={mbp({ tabSm: '200px', tab: '230px' })}
        pos='absolute'
        top={mbp({ tabSm: '-54px', tab: '-80px' })}
        right={mbp({ tabSm: '-20px', tab: '-50px' })}
        display={mbp({ mobSm: 'none', tabSm: 'block' })}
      />

      <CardHeader>
        <Breakpoint
          mob={(
            <CardPrimaryTitle>
              Update Your Primary Card
            </CardPrimaryTitle>
          )}
        />
        <CardSecondarySupportingText
          mt={mbp({ mob: '1', tabSm: '2' })}
          maxW={mbp({ mob: '100%', tabSm: '83%' })}
        >
          We protect your card information via Stripe, an industry-
          leading payment processor that’s PCI DSS Level 1
          certified — the highest security standard.
        </CardSecondarySupportingText>
      </CardHeader>

      <CardBody overflow='visible'>
        <Flex align='center'>
          <CardSecondaryTitle flex='1'>
            <Breakpoint
              mobSm='Payment Info'
              mob='Secure Payment Info'
            />
          </CardSecondaryTitle>

          <Box flex='0 0 auto'>
            <AddPaymentFlowButton onClick={handleOnAddPaymentButtonClicked} />
          </Box>
        </Flex>

        <CardFullWidthSection
          mt='-1'
        >
          <PaymentCardList
            isLoading={loading || !data}
            paymentMethods={paymentMethods}
            slidesOffsetBefore={mfv({
              mobSm: 16,
              mob: 24,
              tabSm: 40,
            }) || 16}
            slidesOffsetAfter={mfv({
              mobSm: 16,
              mob: 24,
              tabSm: 40,
            }) || 16}
          />
        </CardFullWidthSection>

        <SecurePaymentIconsSection />
      </CardBody>
    </ResponsiveFullscreenModalV1>
  )
}

/**
 * Opens AddPaymentModal, but when it closes, it opens back up the UpdatePaymentModal
 */
const openAddPaymentModalWired = (): void => {
  openAddPaymentModal()
}
