import { Settings } from 'features/Settings/domain/Settings.domain'
import { getSystemPrefTheme } from 'features/Settings/infra/remote/getSystemPrefTheme'
import { LOCALLY_STORED_SETTINGS_KEY } from 'features/Settings/infra/remote/locallyStoredSettings.const'

export const getLocallyStoredSettings = () => {
  const raw = localStorage.getItem(LOCALLY_STORED_SETTINGS_KEY)
  let settings: Settings.LocallyStoredSettings

  try {
    if (!raw) throw new Error('No locally stored settings found')
    settings = JSON.parse(raw)
  } catch {
    settings = {
      theme: getSystemPrefTheme(),
    }
  }

  return settings
}
