import { Box, BoxProps } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { FC } from 'react'

export const Underline: FC<BoxProps> = props => (
  <Box color='plans.advance' as='span' display='block' {...props}>
    <Breakpoint
      mobSm={(
        <svg viewBox='0 0 103 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M103 2.65772C102.254 2.75263 101.544 2.92785 100.829 2.92055C93.5244 2.84024 86.2199 2.52629 78.9245 2.68326C70.2625 2.86944 61.6095 3.36591 52.9655 3.89887C46.2364 4.31503 39.5163 4.90275 32.8142 5.56714C24.795 6.36294 16.8028 7.34856 8.78808 8.19912C6.66641 8.42545 4.51327 8.53131 2.37812 8.53861C0.413781 8.54591 -0.593112 7.23175 0.36883 6.0636C0.598078 5.78251 1.124 5.52698 1.53305 5.49778C6.76979 5.15099 12.02 4.9356 17.2433 4.51215C22.8666 4.05584 28.4629 3.38416 34.0773 2.85119C39.8534 2.30362 45.6206 1.6721 51.4147 1.3253C60.27 0.795984 69.1387 0.306814 78.012 0.131592C85.249 -0.0107764 92.4995 0.332372 99.7411 0.467439C101.377 0.496643 102.618 0.9712 102.987 2.65406L103 2.65772Z' fill='currentColor' />
        </svg>

      )}
      tabSm={(
        <svg fill='currentColor' viewBox='0 0 385 17' xmlns='http://www.w3.org/2000/svg'>
          <path d='M384.906 4.89173C382.12 5.07308 379.469 5.4079 376.801 5.39395C349.531 5.24049 322.261 4.6406 295.025 4.94054C262.687 5.29628 230.383 6.24492 198.112 7.26331C172.99 8.05849 147.902 9.18151 122.88 10.451C92.9424 11.9716 63.105 13.8549 33.1836 15.4802C25.2628 15.9126 17.2245 16.1149 9.25327 16.1288C1.91977 16.1428 -1.83927 13.6317 1.75196 11.3996C2.60781 10.8625 4.57124 10.3743 6.09835 10.3185C25.6487 9.65582 45.2495 9.24428 64.7495 8.43515C85.7431 7.56324 106.636 6.2798 127.596 5.26142C149.16 4.21513 170.691 3.00841 192.322 2.34576C225.382 1.33435 258.491 0.399656 291.618 0.0648435C318.636 -0.207191 345.705 0.448492 372.74 0.706576C378.848 0.762378 383.48 1.66915 384.856 4.88475L384.906 4.89173Z' fill='currentColor' />
        </svg>
      )}
    />
  </Box>
)
