import { CriteriaSchema as Schema } from './CriteriaSchema'
import { encode } from './encode'
import { toDomain } from './toDomain'
export {
  ConditionTypeSchema, DateRangeFilterSchema, EquityInfoSchema, FiltersSchema,
  LocationSchema, OwnerTypeSchema, PreforeclosureSchema,
  PropertyDetailsSchema, RangeFilterSchema,
} from './CriteriaSchema'

type CriteriaSchema = Schema

const CriteriaSchema = {
  toDomain,
  encode,
  schema: Schema,
}

export default CriteriaSchema
