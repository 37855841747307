import { createIcon } from '@chakra-ui/react'

export const RefreshIcon = createIcon({
  displayName: 'RefreshIcon',
  viewBox: '0 0 16 16',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M15.3334 2.66663V6.66663H11.3334' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.666626 13.3334V9.33337H4.66663' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M2.33996 6.00001C2.67807 5.04453 3.25271 4.19028 4.01027 3.51696C4.76783 2.84363 5.6836 2.37319 6.67215 2.14952C7.6607 1.92584 8.6898 1.95624 9.66342 2.23786C10.637 2.51948 11.5235 3.04315 12.24 3.76001L15.3333 6.66668M0.666626 9.33334L3.75996 12.24C4.47646 12.9569 5.36288 13.4805 6.3365 13.7622C7.31012 14.0438 8.33922 14.0742 9.32777 13.8505C10.3163 13.6268 11.2321 13.1564 11.9896 12.4831C12.7472 11.8097 13.3218 10.9555 13.66 10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
