import { RefObject, useLayoutEffect, useRef, useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

export type UseNoOfLinesResult<T> = {
  ref: RefObject<T>
  noOfLines: number
  lineHeight: number
}
/**
 * @NOTE:
 * Counts the number of lines in a given element. Also returns the line height of
 * the current typography in that element
 */
export const useNoOfLines = <T extends Element>(): UseNoOfLinesResult<T> => {
  const [noOfLines, setNoOfLines] = useState<number>(0)
  const [lineHeight, setLineHeight] = useState<number>(0)

  const ref = useRef<T>(null)

  useLayoutEffect(() => {
    if (!isNonNullable(ref.current)) return

    const element = ref.current

    const handleResize = () => {
      const clientH = element.clientHeight

      const localLineHeight = parseFloat(
        window.getComputedStyle(element).lineHeight,
      )
      // Calculate the number of lines
      const numberOfLines = Math.ceil(clientH / localLineHeight)

      setLineHeight(localLineHeight)
      setNoOfLines(numberOfLines)
    }

    if (!ref.current) return

    const obs = new ResizeObserver(handleResize)

    obs.observe(ref.current)

    return () => {
      obs.disconnect()
    }
  }, [])

  return { ref, noOfLines, lineHeight }
}
