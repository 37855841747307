import { Button, ButtonProps, HStack, Menu, MenuButton, Portal } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useBatchSkiptraceProgress } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceProgress'
import { useBatchSkiptraceStats } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceStats'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { SelectListState, useSelectedList } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { ChevronDownIcon, CloseIcon, DownloadBoxIcon, KebabIcon, SkiptraceSuccessIcon, TrashIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useBatchSkiptraceModal, useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { DeleteListModal, useDeleteListModalActions, useDeleteListModalState } from 'presentation/screens/ListScreen/components/DeleteListModal'
import { DeleteMemberModal, useDeleteMemberModalActions, useDeleteMemberModalState } from 'presentation/screens/ListScreen/components/DeleteMembersModal'
import { ListDataDownloadModal } from 'presentation/screens/ListScreen/components/ListFooter/components/ListDataDownloadModal'
import { ListSkipDataDownloadModal } from 'presentation/screens/ListScreen/components/ListFooter/components/ListSkipDataDownloadModal'
import { useListDataDownloadModal, useListDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListDataDownloadModal'
import { useListSkipDataDownloadModal, useListSkipDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListSkipDataDownloadModal'
import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'

export type ListScreenActionSheetProps = {
  selections: MarketingListMemberId[]
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
}

const ListScreenActionSheet = ({ selections, setSelections }: ListScreenActionSheetProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const listDataModalState = useListDataDownloadModal()
  const skipDataModalState = useListSkipDataDownloadModal()
  const deleteListModalState = useDeleteListModalState()
  const deleteMembersModalState = useDeleteMemberModalState()
  const skiptraceModalState = useBatchSkiptraceModal()
  const shouldHideMenuButton = isOpen
    || skipDataModalState.status === 'open'
    || listDataModalState.status === 'open'
    || deleteListModalState.status === 'open'
    || deleteMembersModalState.status === 'open'
    || skiptraceModalState._tag !== 'Closed'

  const close = () => setIsOpen(false)

  return (
    <>
      <Portal>
        <Menu
          placement='bottom-end'
          variant='actionSheet'
          closeOnSelect={false}
          closeOnBlur={false}
          isOpen={isOpen}
          onClose={() => { setIsOpen(false) }}
          onOpen={() => { setIsOpen(true) }}
        >
          <ActionsButton
            aria-label='Actions'
            visibility={shouldHideMenuButton ? 'hidden' : 'visible'}
            colorScheme='positive'
            textStyle='bodyLFat'
            position='fixed'
            right={1}
            bottom={1.5}
            zIndex='docked'
            leftIcon={<KebabIcon boxSize={3} />}
            shadow='float'
          >
            Actions
          </ActionsButton>
          <ResponsiveMenuList
            modalContentProps={{
              sx: {
                '.chakra-modal__close-button': {
                  display: 'none',
                },
              },
            }}
          >
            <SkipListButton onClick={close} selections={selections} />

            {selections.length === 0 && (
              <DownloadSkipDataButton onClick={close}>
                Download Skip Data
              </DownloadSkipDataButton>
            )}
            {selections.length === 0 && (
              <DownloadListDataButton onClick={close}>
                Download List Data
              </DownloadListDataButton>
            )}
            {selections.length > 0
              ? (
                <RemoveProperties
                  onClick={close}
                  selections={selections}
                  setSelections={setSelections}
                />
              )
              : (
                <DeleteListButton onClick={close} />
              )}
            <HStack justifyContent='flex-end' pb={1.5}>
              <Button
                aria-label='Actions'
                size='lg'
                variant='icon-solid'
                colorScheme='negative'
                h={6.5}
                w={6.5}
                border={0}
                shadow='float'
                onClick={() => { setIsOpen(false) }}
              >
                <ChevronDownIcon boxSize={3} />
              </Button>
            </HStack>
          </ResponsiveMenuList>
        </Menu>
      </Portal>

      <ListDataDownloadModal />
      <ListSkipDataDownloadModal />
      <DeleteListModal />
      <DeleteMemberModal />
    </>
  )
}

export default ListScreenActionSheet

const ActionsButton = ({ children, leftIcon, ...props }: ButtonProps) => {
  ;
  return (
    <MenuButton
      as={Button}
      leftIcon={leftIcon}
      variant='solid'
      size='lg'
      h={6.5}
      {...props}
      mb={7}
    >
      {children}
    </MenuButton>
  )

  // /**
  //  * @NOTE: Hide button label when page is scrolling
  //  */
  // const [isScrolling, setIsScrolling] = useState(false)

  // // let scrollTimeout: NodeJS.Timeout
  // const scrollTimeoutRef = useRef<NodeJS.Timeout>()

  // const handleScroll = () => {
  //   if (!isScrolling)
  //     setIsScrolling(true)

  //   clearTimeout(scrollTimeoutRef.current)

  //   scrollTimeoutRef.current = setTimeout(() => {
  //     setIsScrolling(false)
  //   }, 1000)
  // }

  // useEffect(() => {
  //   const element = document.getElementById(MOBILE_SCROLLABLE_LAYOUT_ID)

  //   if (!element) return

  //   if (element.scrollTop > 0)
  //     setIsScrolling(true)

  //   return () => clearTimeout(scrollTimeoutRef.current)
  // }, [])

  // useEventListener('scroll', handleScroll)

  // return (
  //   <>
  //     {isScrolling
  //       ? (
  //         <MenuButton
  //           as={IconButton}
  //           icon={leftIcon}
  //           variant='icon-solid'
  //           size='lg'
  //           h={6.5}
  //           w={6.5}
  //           border={0}
  //           {...props}
  //         />
  //       )
  //       : (
  //         <MenuButton
  //           as={Button}
  //           leftIcon={leftIcon}
  //           variant='solid'
  //           size='lg'
  //           h={6.5}
  //           {...props}
  //           mb={7}
  //         >
  //           {children}
  //         </MenuButton>
  //       )}
  //   </>
  // )
}

const DeleteListButton = ({ onClick }: { onClick: () => void }) => {
  const listState = useSelectListState()
  const deleteListModalActions = useDeleteListModalActions()

  if (!SelectListState.$is('Success')(listState))
    return null
  return (
    <>
      <Button
        colorScheme='negative'
        size='md'
        variant='ghost'
        leftIcon={<TrashIcon fontSize={3} />}
        bgColor='card.bg.1'
        onClick={() => {
          onClick()
          deleteListModalActions.open({
            listId: listState.list.id,
          })
        }}
      >
        Delete List
      </Button>
    </>
  )
}

type RemovePropertiesProps = {
  selections: MarketingListMemberId[]
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
  onClick: () => void
}

const RemoveProperties = ({ selections, setSelections, onClick }: RemovePropertiesProps) => {
  const listState = useSelectListState()
  const deleteMembersModalActions = useDeleteMemberModalActions()
  if (!SelectListState.$is('Success')(listState))
    return null
  return (
    <>
      <Button
        colorScheme='negative'
        size='md'
        variant='ghost'
        bgColor='card.bg.1'
        leftIcon={<CloseIcon fontSize={3} />}
        onClick={() => {
          onClick()
          deleteMembersModalActions.open({
            listId: listState.list.id,
            memberIds: selections,
            onSuccess: () => setSelections([]),
          })
        }}
      >
        Remove Properties
      </Button>
    </>
  )
}

const DownloadListDataButton = (props: PropsWithChildren<{ onClick: () => void }>) => {
  const listState = useSelectedList()
  const list = listState._tag === 'Success'
    ? listState.list
    : null

  const listDataModalActions = useListDataDownloadModalActions()

  if (list === null) return null
  return (
    <>
      <Button
        colorScheme='neutral'
        size='md'
        variant='outline'
        bgColor='card.bg.1'
        leftIcon={<DownloadBoxIcon fontSize={3} />}
        onClick={() => {
          props.onClick()
          listDataModalActions.open({ listId: list.id })
        }}
      >
        {props.children}
      </Button>
    </>
  )
}

const DownloadSkipDataButton = (props: PropsWithChildren<{ onClick: () => void }>) => {
  const listState = useSelectedList()
  const list = listState._tag === 'Success'
    ? listState.list
    : null

  const listSkipDataModalActoins = useListSkipDataDownloadModalActions()

  if (list === null) return null
  return (
    <>
      <Button
        colorScheme='neutral'
        size='md'
        variant='outline'
        bgColor='card.bg.1'
        leftIcon={<DownloadBoxIcon fontSize={3} />}
        onClick={() => {
          props.onClick()
          listSkipDataModalActoins.open({ list })
        }}
      >
        {props.children}
      </Button>
    </>
  )
}

export type SkipListButtonProps = {
  selections: MarketingListMemberId[]
  onClick: () => void
}

const SkipListButton = ({ selections, onClick }: SkipListButtonProps) => {
  const listState = useSelectedList()

  const skiptraceStats = useBatchSkiptraceStats()
  const skipraceModalActions = useBatchSkiptraceModalActions()
  const progress = useBatchSkiptraceProgress()

  if (!SelectListState.$is('Success')(listState))
    return null
  return (
    <>
      <Button
        colorScheme='positive'
        size='md'
        variant='solid'
        leftIcon={<SkiptraceSuccessIcon fontSize={3} />}
        onClick={() => {
          if (skiptraceStats._tag !== 'Success')
            return

          onClick()

          skipraceModalActions.open({
            stats: skiptraceStats.stats,
            listId: listState.list.id,
            memberIds: selections,
          })
        }}
        isLoading={progress._tag === 'Checking' || progress._tag === 'InProgress'}
        disabled={progress._tag === 'Checking' || progress._tag === 'InProgress'}
        loadingText={
          pipe(
            Match.value(progress),
            Match.tag('Checking', () => 'Checking Skiptrace'),
            Match.tag('InProgress', () => 'Skiptracing'),
            Match.orElse(() => undefined),
          )
        }
      >
        Skiptrace
        {' '}
        {selections.length > 0 ? 'Properties' : 'List'}
      </Button>
    </>
  )
}
