// eslint-disable-next-line no-restricted-imports
import { BoxProps, Menu as ChakraMenu, MenuList as ChakraMenuList, MenuListProps, MenuProps, ModalContentProps, ModalProps, useMenuContext, useMenuStyles } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MenuArrow } from 'presentation/components/Menu'
import { Sheet } from 'presentation/components/Sheet'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { PropsWithChildren, ReactNode } from 'react'

export const Menu = (props: MenuProps) => {
  const {
    children,
    closeOnBlur: closeOnBlurFromProps,
    ...rest
  } = props

  const closeOnBlur = useSwitchBreakpoint({
    mobSm: false,
    tabSm: closeOnBlurFromProps,
  })

  return (
    <ChakraMenu
    /**
     * @NOTE: Disable closeOnBlur on mobile to prevent the modal from closing.
     * This is because there is no MenuList on mobile, the Menu detects the
     * click outside and closes itself.
     */
      closeOnBlur={closeOnBlur}
      {...rest}
    >
      {children}
    </ChakraMenu>
  )
}
/**
 * Replaces Chakra's dropover menu with bottom sheet menu on mobile.
 *
 * Replace Chakra's `MenuList` with `ResponsiveMenuList` to use.
 */
export type ResponsiveMenuListProps = {
  children?: ReactNode
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>
  modalContentProps?: ModalContentProps
  modalContainerProps?: BoxProps
  menuListProps?: MenuListProps
}

export const ResponsiveMenuList = ({
  children,
  menuListProps,
  modalProps,
  modalContentProps,
  modalContainerProps,
}: ResponsiveMenuListProps) => (
  <Breakpoint
    mobSm={(
      <MenuListModal
        modalProps={modalProps}
        modalContainerProps={modalContainerProps}
        modalContentProps={modalContentProps}
      >
        {children}
      </MenuListModal>
    )}
    tabSm={(
      <ChakraMenuList
        zIndex='popover'
        position='relative'
        {...menuListProps}
      >
        <MenuArrow />
        {children}
      </ChakraMenuList>
    )}
  />
)

type MenuListModalProps = PropsWithChildren<{
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>
  modalContentProps?: ModalContentProps
  modalContainerProps?: BoxProps
}>

const MenuListModal = ({
  children,
  modalProps,
  modalContentProps,
  modalContainerProps,
}: MenuListModalProps) => {
  const styles = useMenuStyles()
  const { isOpen, onClose } = useMenuContext()

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      modalProps={modalProps}
      modalContainerProps={modalContainerProps}
      modalContentProps={{
        ...modalContentProps,
        sx: {
          ...styles.list,
          'pb': '6',
          '& > .chakra-menu__menutitle': {
            pb: '1.5',
          },

          '& .chakra-menu__menuitem, & .chakra-menu__menuitem-option, & .chakra-menu__menuitem-button': {
            h: '6',
          },

          '& > [data-popper-arrow]': {
            display: mbp({
              mobSm: 'none',
              tabSm: 'flex',
            }),
          },
          ...modalContentProps?.sx,
        },
      }}
    >
      {children}
    </Sheet>
  )
}
