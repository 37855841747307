import { Center, Spinner } from '@chakra-ui/react'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { toast } from 'presentation/components/Toast'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const PropertyDetailsFromAddressScreen = () => (
  <PropertyDetailsFromAddressScreenDumb />
)

export const PropertyDetailsFromAddressScreenDumb = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const getProperty = usePropertyDetailsStore(state => state.actions.getProperty)

  const addressString = [
    searchParams.get('line1'),
    searchParams.get('city'),
    // Note: state is observed to by often missing (comes from /legacy/cma/:leadId service)
    searchParams.get('state'),
    searchParams.get('postalCode'),
  ].filter(Boolean).join(', ')

  // Request property or redirect, depending on address completeness
  useEffect(() => {
    if (!addressString)
      navigate('/search')
    else
      void getProperty.execute({ addressString, origin: 'other' })
  }, [])

  // Redirect in reaction to property request state
  const state = getProperty.state
  useEffect(() => {
    if (!('params' in state)) return
    if (!('addressString' in state.params)) return
    if (state.params.addressString !== addressString) return

    if (state.status === 'success') {
      navigate(`/search/${state.data.leadId}`, { replace: true })
    } else if (state.status === 'error') {
      navigate('/search')
      toast.error({
        title: 'Failed to Load Property Data',
        message: 'Please try again or contact support.',
      })
    }
  }, [state])

  return <Loading />
}

const Loading = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)

  return (
    <Center h={height}>
      <Spinner />
    </Center>
  )
}
