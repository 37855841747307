import { Button, Menu, MenuButton, MenuItemOption, MenuOptionGroup, Portal } from '@chakra-ui/react'
import { useSelectedList } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { ListDataDownloadModal } from 'presentation/screens/ListScreen/components/ListFooter/components/ListDataDownloadModal'
import { ListSkipDataDownloadModal } from 'presentation/screens/ListScreen/components/ListFooter/components/ListSkipDataDownloadModal'
import { useListDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListDataDownloadModal'
import { useListSkipDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListSkipDataDownloadModal'

export const ListDownloadButton = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const listState = useSelectedList()
  const list = listState._tag === 'Success'
    ? listState.list
    : null

  const listDataModalActions = useListDataDownloadModalActions()
  const listSkipDataModalActoins = useListSkipDataDownloadModalActions()

  if (!list) return null

  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme='neutral'
        size='md'
        variant='outline'
        rightIcon={<ChevronDownIcon fontSize={3} />}
      >
        {children}
      </MenuButton>

      <Portal>
        <ResponsiveMenuList>
          <MenuOptionGroup
          /** @NOTE hard coding value prevents selected state after clicking options */
            value=''
          >
            <MenuItemOption
              onClick={() => {
                listDataModalActions.open({ listId: list.id })
              }}
            >
              Download list data
            </MenuItemOption>
            <MenuItemOption
              onClick={() => {
                listSkipDataModalActoins.open({ list })
              }}
            >
              Download skiptrace data
            </MenuItemOption>
          </MenuOptionGroup>
        </ResponsiveMenuList>
      </Portal>

      <ListDataDownloadModal />
      <ListSkipDataDownloadModal />
    </Menu>
  )
}
