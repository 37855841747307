import { Checkbox } from '@chakra-ui/react'

export const CheckboxIcon = (props: {
  isChecked?: boolean
  isIndeterminate?: boolean
}) => props.isIndeterminate
  ? <Indeterminate />
  : props.isChecked
    ? <Checked />
    : null

Checkbox.defaultProps = { icon: <CheckboxIcon /> }

const Checked = () => (
  <svg viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M19.391 0.647448C20.203 1.51071 20.203 2.91034 19.391 3.7736L8.49992 15.3526C8.11 15.7671 7.58114 16 7.0297 16C6.47826 16 5.94941 15.7671 5.55948 15.3526L0.608986 10.0894C-0.202995 9.22613 -0.202995 7.8265 0.608986 6.96324C1.42097 6.09997 2.73745 6.09997 3.54943 6.96324L7.0297 10.6633L16.4506 0.647448C17.2626 -0.215816 18.579 -0.215816 19.391 0.647448Z' fill='currentColor' />
  </svg>
)

const Indeterminate = () => (
  <svg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g>
      <path fillRule='evenodd' clipRule='evenodd' d='M2.7998 14C2.7998 12.4536 3.91412 11.2 5.28869 11.2L22.7109 11.2C24.0855 11.2 25.1998 12.4536 25.1998 14C25.1998 15.5464 24.0855 16.8 22.7109 16.8L5.28869 16.8C3.91412 16.8 2.7998 15.5464 2.7998 14Z' fill='currentColor' />
    </g>
  </svg>

)
