import { Match, pipe } from 'effect'

/**
 * Equity means the difference between the value of the property and the
 *
 * HIGH: The owner has a lot of equity in the property.
 * LOW: The owner has little equity in the property.
 * UPSIDE_DOWN: The owner owes more than the property is worth.
 * FREE_AND_CLEAR: The owner has no mortgage on the property.
 */
const EQUITY_TYPE_ARRAY = [
  'HIGH',
  'LOW',
  'UPSIDE_DOWN',
  'FREE_AND_CLEAR',
] as const

export type EquityType = typeof EQUITY_TYPE_ARRAY[number]

const toString = (equityType: EquityType): string => pipe(
  Match.value(equityType),
  Match.when('HIGH', () => 'High Equity'),
  Match.when('LOW', () => 'Low Equity'),
  Match.when('UPSIDE_DOWN', () => 'Upside Down'),
  Match.when('FREE_AND_CLEAR', () => 'Free and Clear'),
  Match.exhaustive,
)

export const UPSIDE_DOWN_MIN_LTV = 100
export const LOW_MIN_LTV = 81
export const HIGH_MIN_LTV = 1

const fromLtv = (ltv: number): EquityType => {
  switch (true) {
  case ltv >= UPSIDE_DOWN_MIN_LTV:
    return 'UPSIDE_DOWN'
  case ltv >= LOW_MIN_LTV:
    return 'LOW'
  case ltv >= HIGH_MIN_LTV:
    return 'HIGH'
  default:
    return 'FREE_AND_CLEAR'
  }
}

export const EquityType = {
  ARRAY: EQUITY_TYPE_ARRAY,
  toString,
  fromLtv,
  toArray: () => EQUITY_TYPE_ARRAY,
}
