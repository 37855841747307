import { Lead } from 'features/PropertyDetails/infra/remote/getProperty/Lead.type'
import { Address } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { NumberLib } from 'libs/Number'
import { ReportableException } from 'libs/errors'

export const parseLeadResponse = (data: any): Lead => {
  // If the response is wrapped in a result property, extract that first
  const result = data?.result ? data.result : data

  const parcelId = typeof result?.property?.id === 'string' ? result.property.id : null
  const leadId = typeof result?.id === 'string' ? result.id : null

  const rawAddress = result?.values?.address
  const address = Address.toNullIfEmpty({
    line1: typeof rawAddress?.line1 === 'string' ? rawAddress?.line1 : null,
    city: typeof rawAddress?.city === 'string' ? rawAddress?.city : null,
    state: typeof rawAddress?.state === 'string' ? rawAddress?.state : null,
    postalCode: typeof rawAddress?.zip === 'string' ? rawAddress?.zip : null,
  })

  // Safely check if lat and lon exist before trying to create location
  const location = rawAddress && typeof rawAddress.lat !== 'undefined' && typeof rawAddress.lon !== 'undefined'
    ? Location.ensureOrNull({
      latitude: rawAddress.lat,
      longitude: rawAddress.lon,
    })
    : null

  if (!leadId || !address) {
    throw new ReportableException('Failed to get lead information', {
      extraInfo: {
        data,
        result,
      },
    })
  }

  return {
    id: leadId,
    parcelId: parcelId || null,
    property: {
      id: leadId,
      leadId,
      status: 'without-details',
      address,
      location,
      originalData: {
        building: {
          livingAreaSqft: null,
        },
      },
      userInputData: {
        building: {
          livingAreaSqft: NumberLib.ensureOrNull(result?.values?.sqft),
        },
      },
    },
  } satisfies Lead
}
