import { Box } from '@chakra-ui/react'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, PropsWithChildren } from 'react'

/**
 * Context:
 * Cell to the right of amount could be a button with negative margin
 * (to align with non-button cells). It invades the space of the amount cell
 * and on mobile it blocks a portion of the amount cell hence this fix.
 */
export const AmountCellSpacer: FC<PropsWithChildren> = ({ children }) => (
  <Box
    pr={mbp({
      mobSm: 2,
      tabSm: 0,
    })}
  >
    {children}
  </Box>
)
