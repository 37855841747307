import { createPanelProvider, usePanelStore } from 'presentation/screens/hooks/usePanelStore'

const SEARCH_HISTORY_LIST_KEY = 'search-history-list'

export const SearchHistoryPanelsProvider = createPanelProvider({
  configs: [
    {
      panelKey: SEARCH_HISTORY_LIST_KEY,
      localMinBreakpoint: 'tabSm',
      localInitialIsOpen: false,
    },
  ],
})

export const useSearchHistoryListPanelStore = usePanelStore(SEARCH_HISTORY_LIST_KEY)
