import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'
import { debounce } from 'remeda'
import { create } from 'zustand'

type HeaderElementKey = 'headerMainDt' | 'headerMainMob' | 'headerNotice' | 'propertyLayoutTabLinks' | 'propertyLayoutSpacer'

type MobileHeaderContents = [
  ReactNode | null,
  ReactNode | null,
  ReactNode | null,
]

type LayoutStore = {
  // title stuff
  title: string | ReactElement
  setTitle: (title: string | ReactElement) => void

  headerMobRightToolbar: ReactNode
  setHeaderMobRightToolbar: (element: ReactNode) => void

  // header shadow sutff
  headerShadow: {
    value: boolean
    set: (value: boolean) => void
  }

  // mobile search property input
  isMobileSearchPropertyInputVisible: boolean
  setIsMobileSearchPropertyInputVisible: (value: boolean) => void

  // header height stuff
  headerElements: Partial<Record<HeaderElementKey, { height: number }>>
  totalHeaderHeight: number
  setHeaderElementHeight: (elementKey: string, height: number) => void

  // body height stuff
  totalBodyHeight: number

  mobileHeaderContents: MobileHeaderContents | null
  setMobileHeaderContents: (contents: MobileHeaderContents | null) => void
}

/**
 * @TODO Track inner height as property not as method
 * @TODO Update inner tracker on window resize
 * @TODO Debounce body height update IF empty
 */
export const useLayoutStore = create<LayoutStore>((set, get) => ({
  // title stuff
  title: '',
  setTitle: (title: string | ReactElement) => set({ title }),

  headerMobRightToolbar: null,
  setHeaderMobRightToolbar: element => set({ headerMobRightToolbar: element }),

  // header shadow sutff
  headerShadow: {
    value: false,
    set: (value: boolean) => set({ headerShadow: { ...get().headerShadow, value } }),
  },

  // mobile search property input
  isMobileSearchPropertyInputVisible: false,
  setIsMobileSearchPropertyInputVisible: (value: boolean) =>
    set({ isMobileSearchPropertyInputVisible: value }),

  // header height stuff
  headerElements: {},
  totalHeaderHeight: 0,

  setHeaderElementHeight: (elementKey: string, elementHeight: number) =>
    set({
      headerElements: {
        ...get().headerElements,
        [elementKey]: { height: elementHeight },
      },
    }),

  // body height stuff
  totalBodyHeight: window.innerHeight,

  mobileHeaderContents: null,
  setMobileHeaderContents: (contents: MobileHeaderContents | null) =>
    set({ mobileHeaderContents: contents }),
}))

export const useConfigureLayout = (config: Pick<LayoutStore, 'title'>, deps: unknown[] = []) => {
  const store = useLayoutStore()

  useEffect(() => {
    if (config.title) store.setTitle(config.title)
  }, deps)
}

export const useHeaderElementHeightTracker = (elementKey: HeaderElementKey) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>()
  const setHeaderElementHeight = useLayoutStore(store => store.setHeaderElementHeight)

  useEffect(() => {
    setHeaderElementHeight(elementKey, height)

    // make sure to reset the height when the component is unmounted
    return () => {
      setHeaderElementHeight(elementKey, 0)
    }
  }, [height])

  return { ref, height }
}

const DEBOUNCE_RATE = 100
export const useOptimizedHeightTracker = () => {
  const [localInnerHeight, setLocalInnerHeight] = useState<number>(window.innerHeight)

  // subscribe to window resizes
  useEffect(() => {
    const onResize = () => {
      setLocalInnerHeight(window.innerHeight)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const correctTotalHeaderHeight = useLayoutStore(store =>
    Object.values(store.headerElements)
      .map(el => el.height)
      .reduce((total, element) => total + element, 0))
  const correctTotalBodyHeight = localInnerHeight - correctTotalHeaderHeight

  const currentTotalHeaderHeight = useLayoutStore(store => store.totalHeaderHeight)
  const currentTotalBodyHeight = useLayoutStore(store => store.totalBodyHeight)

  const unoptimizedUpdateStore = ({
    correctTotalHeaderHeight,
    correctTotalBodyHeight,

    currentTotalHeaderHeight,
    currentTotalBodyHeight,
  }: {
    correctTotalHeaderHeight: number
    correctTotalBodyHeight: number
    currentTotalHeaderHeight: number
    currentTotalBodyHeight: number
  }) => {
    const isStoreUpToDate = currentTotalHeaderHeight === correctTotalHeaderHeight
      && currentTotalBodyHeight === correctTotalBodyHeight

    if (isStoreUpToDate) return

    useLayoutStore.setState({
      totalHeaderHeight: correctTotalHeaderHeight,
      totalBodyHeight: correctTotalBodyHeight,
    })
  }

  const optimizedUpdateStore = useMemo(() => debounce(unoptimizedUpdateStore, {
    waitMs: DEBOUNCE_RATE,
  }), [])

  useEffect(() => {
    optimizedUpdateStore.call({
      correctTotalHeaderHeight,
      correctTotalBodyHeight,
      currentTotalHeaderHeight,
      currentTotalBodyHeight,
    })
  }, [correctTotalHeaderHeight, correctTotalBodyHeight])
}

export const layoutStore = useLayoutStore

export const useMobileHeaderContents = () => useLayoutStore(store => store.mobileHeaderContents)

export const useSetMobileHeaderContents = () => useLayoutStore(store => store.setMobileHeaderContents)
