import { ArrayFormatter } from '@effect/schema'
import * as S from '@effect/schema/Schema'
import { Array, Effect, Either, Match, RequestResolver, pipe } from 'effect'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import { GETListsByPageSchema } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetListsByPageResolver/GETListsByPageSchema'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'
import { IS_DEV_ENV } from 'presentation/const/env.const'
import { restClient } from 'presentation/libs/client'

const GetListsByPageResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetByPage) => {
    const LIMIT = 50
    const currentPage = Math.max(request.page, 1)
    const offset = (currentPage - 1) * LIMIT
    return pipe(
      Effect.promise(async () =>
        await restClient.get('lists/v3', {
          searchParams: { offset, limit: LIMIT },
        }).json()),
      Effect.map(raw => decode(raw)),
      Effect.map(decoded => toDomain({ countPerPage: LIMIT, currentPage }, decoded)),
    )
  },
)

export default GetListsByPageResolver

const decode = (data: any) => pipe(
  data,
  S.decodeUnknownEither(GETListsByPageSchema),
  Either.getOrThrowWith(error => {
    if (IS_DEV_ENV) {
      pipe(
        error,
        ArrayFormatter.formatError,
        // eslint-disable-next-line no-console
        Effect.tap(array => console.log('GETFetchListByPage ParseError:', array)),
        Effect.runSync,
      )
    }

    throw error as Error
  }),
)

type PageMeta = {
  countPerPage: number
  currentPage: number
}

const toDomain = (
  pageMeta: PageMeta,
  response: GETListsByPageSchema,
): MarketingListRepo.GetByPageResult => ({
  countPerPage: pageMeta.countPerPage,
  currentPage: pageMeta.currentPage,
  lists: Array.map(response.items, item => MarketingList.make({
    id: MarketingListId.make(item.id),
    type: ListTypeSchema.toDomain(item.type),
    name: item.name,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt ?? item.createdAt,
    memberCount: item.memberStats?.total ?? 0, // @TODO: throw if no member count
    skiptracedCount: item.memberStats?.skiptraced ?? 0,
    criteria: CriteriaSchema.toDomain(
      Match.value(item.type).pipe(
        Match.when('national-leads-sellers', (): ListType => 'property-lists'),
        Match.when('national-leads-cashbuyers', (): ListType => 'cash-buyers'),
        Match.when('national-leads-privatelenders', (): ListType => 'private-lenders'),
        Match.when('drive', (): ListType => 'drive'),
        Match.exhaustive,
      ),
      item.source?.payload || null,
    ),
  })),
  totalCount: response.count,
})
