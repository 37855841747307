import { Schema as S } from '@effect/schema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'

export const DownloadEntrySchema = S.Struct({
  id: S.String,
  listId: S.String,
  type: ListTypeSchema.schema,
  cursor: S.Struct({
    offset: S.Number,
    limit: S.Number,
  }),
  createdAt: S.Date,
})

export type DownloadEntrySchema = S.Schema.Type<typeof DownloadEntrySchema>
