import { Effect, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import toDomain from 'features/ListBuilder/infra/repo/MarketingListRepoLive/AddParcelResolver/toDomain'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { MarketingListRemoveParcel } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/RemoveParcelResolver/schema'
import { apolloClient } from 'presentation/libs/client'

const RemoveParcelResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.RemoveParcel) => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.mutate({
          mutation: MarketingListRemoveParcel as any, // TODO: fix type issues
          variables: {
            parcelId: request.parcelId,
            listId: request.listId,
          },
        }),
      catch: error => new UnknownException({ message: String(error) }),
    }),
    Effect.andThen(result => toDomain({
      listId: request.listId,
      parcelId: request.parcelId,
      data: result.data.marketingListRemoveParcel,
    })),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default RemoveParcelResolver
