import { BoxProps } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SwitchTabButton, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ReactNode } from 'react'

export type TabbedComparativeContentProps = BoxProps & {
  infoCard: ReactNode
  analysisCard: ReactNode
  entriesAreSinglePoint?: boolean
  selectedIsSubjectComp?: boolean
}

export const TabbedComparativeContent = ({
  infoCard,
  analysisCard,
  entriesAreSinglePoint,
  selectedIsSubjectComp,
  ...props
}: TabbedComparativeContentProps) => (
  <SwitchTabsWrapper colorScheme='weak' {...props}>
    <SwitchTabButtons>
      <SwitchTabButton
        px={mbp({ mobSm: '2', tabSm: '3' })}
      >
        <Breakpoint
          mobSm='Description'
          tabSm='Agent&rsquo;s Description'
        />
      </SwitchTabButton>
      <SwitchTabButton
        px={mbp({ mobSm: '2', tabSm: '3' })}
      >
        {selectedIsSubjectComp
          ? (
            <Breakpoint
              mobSm='Features'
              mob='Facts &amp; Features'
              dtSm='Facts And Features'
            />
          )
          : 'Comparison'}
      </SwitchTabButton>
    </SwitchTabButtons>
    <SwitchTabContents>
      <SwitchTabContent
        p={mbp({ mobSm: 0.5, mob: 1, tabSm: 2 })}
        bgColor='neutral.200'
        borderTopRightRadius={3}
        borderBottomRadius={3}
      >
        {infoCard}
      </SwitchTabContent>
      <SwitchTabContent
        p={mbp({ mobSm: 0.5, mob: 1, tabSm: 2 })}
        bgColor='neutral.200'
        borderTopRightRadius={3}
        borderBottomRadius={3}
      >
        {analysisCard}
      </SwitchTabContent>
    </SwitchTabContents>
  </SwitchTabsWrapper>
)
