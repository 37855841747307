import { Box, Popover, PopoverBody, PopoverProps, PopoverTrigger, Portal, Text, VStack } from '@chakra-ui/react'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { PropertiesFilterChipProps, useCriteriaToChipsData } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useCriteriaToChipsData'
import { ResponsivePopoverContent } from 'presentation/components/ResponsivePopoverContent/ResponsivePopoverContent'
import { PropertiesFilterChip, PropertyFilterChipType } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFilterChips'
import { ReactNode } from 'react'
import { groupBy } from 'remeda'

export const ListCriteriaPopover = ({
  children,
  criteria,
  ...props
}: Omit<PopoverProps, 'children'> & {
  criteria: PropertyListCriteria | null
  children: React.ReactNode
}) => {
  const chipsData = useCriteriaToChipsData(criteria)
  const groups = groupBy(
    chipsData,
    chipData => chipData.type,
  ) as Record<PropertiesFilterChipProps['type'], PropertiesFilterChipProps[]>

  if (chipsData.length === 0) return null

  return (
    <Popover placement='top-start' colorScheme='highlight' {...props}>
      <PopoverTrigger>
        {children}
      </PopoverTrigger>
      <Portal>
        {/**
         * @TODO See if we still need these props for ResponsivePopoverContent
         * w='fit-content' borderRadius={3} maxWidth='608px'
         */}
        <ResponsivePopoverContent>
          <PopoverBody
            sx={{ p: 0 }}
          >
            <VStack spacing={3} align='stretch'>
              {([
                ['geographic', 'Location'],
                ['property', 'Property'],
                ['ownership', 'Ownership'],
                ['stressIndicator', 'Stress Indicator'],
                ['equity', 'Equity'],
                ['mls', 'MLS'],
              ] as const).map(([type, title]) =>
                groups[type]?.length
                  ? (
                    <FilterGroup
                      key={type}
                      title={title}
                      type={type}
                      values={groups[type].map(chipData => chipData.children)}
                    />
                  )
                  : null,
              )}
            </VStack>
          </PopoverBody>
        </ResponsivePopoverContent>
      </Portal>
    </Popover>
  )
}

const FilterGroup = ({
  title,
  type,
  values,
}: {
  title: string
  type: PropertyFilterChipType
  values: ReactNode[]
}) => (
  <VStack spacing={1} align='stretch'>
    <Text textStyle='bodyLFat' color='grayweak.900'>
      {title}
    </Text>
    <Box mx={-0.5} lineHeight='36px'>
      {values.map((value, i) => (
        <PropertiesFilterChip
          key={i}
          mx={0.5}
          type={type}
        >
          {value}
        </PropertiesFilterChip>
      ))}
    </Box>
  </VStack>
)
