import { Grid, VStack, Text } from '@chakra-ui/react'
import { Option, Array } from 'effect'
import DirectMailFeatureRowViewModel from './DirectMailFeatureRowViewModel'
import { Fragment } from 'react'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'

const DirectMailFeatureRow = () => {
  const vm = DirectMailFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Direct Mail
        </PrimaryText>
        <SecondaryText>
          Send one or thousands at a time seemlessly with
          delivery tracking and property images
        </SecondaryText>
        {vm.unitDescriptionText.pipe(
          Option.map(text => (
            <Text
              key='hint'
              textStyle='body2'
              color='positivesat.500'
            >
              {text}
            </Text>
          )),
          Option.getOrNull,
        )}
      </VStack>
      {Array.map(vm.columnValues, (column, i) => (
        <VStack justify='center' spacing='1' key={i}>
          {column.pipe(
            Option.match({
              onSome: content => (
                <Fragment>
                  <PrimaryText key='primary'>
                    {content.primaryText}
                  </PrimaryText>
                  <SecondaryText key='secondary'>
                    {content.secondaryText}
                  </SecondaryText>
                </Fragment>
              ),
              onNone: () => (
                <FeatureAvailability isAvailable={false} />
              ),
            }),
          )}
        </VStack>
      ))}
    </Grid>
  )
}

export default DirectMailFeatureRow
