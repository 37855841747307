import { Box, Image, Text, VStack } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Card } from 'presentation/components/molecules/Card'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { NoSearchHistoryCardProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/NoSearchHistoryCard/NoSearchHistoryCard.props'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import NoDataIMG120x120 from './img/no-data-120x120.png'
import NoDataIMG216x216 from './img/no-data-216x216.png'

export const NoSearchHistoryCard = (props: NoSearchHistoryCardProps) => {
  const {
    hasTextFilter,
    hasSeekerFilter,
    ...boxProps
  } = props

  const image = useSwitchBreakpoint({
    mobSm: NoDataIMG120x120,
    tabSm: NoDataIMG216x216,
  })

  const boxSize = useSwitchBreakpoint({
    mobSm: '120px',
    tabSm: '216px',
  })

  const title = !hasTextFilter && hasSeekerFilter
    ? NO_MATCH_FOUND_TITLE
    : NO_SEARCH_HISTORY_FOUND_TITLE

  const message = !hasTextFilter && hasSeekerFilter
    ? NO_MATCH_FOUND_MESSAGE
    : NO_SEARCH_HISTORY_FOUND_MESSAGE

  return (
    <Box
      {...boxProps}
      h='100%'
    >
      <Card size='sm-locked' h='100%'>
        <VStack
          spacing={1}
          h='100%'
          justify='center'
        >
          <Image src={image} boxSize={boxSize} />
          <Text
            {...mbpg({
              mobSm: {
                textStyle: 'bodyXLFat',
                color: 'graystrong.500',
              },
              tabSm: {
                textStyle: 'h2',
              },
            })}
            textAlign='center'
          >
            {title}
          </Text>
          <Text
            {...mbpg({
              mobSm: {
                textStyle: 'bodyMFat',
                color: 'graystrong.200',
              },
              tabSm: {
                textStyle: 'bodyXLFat',
              },
            })}
            textAlign='center'
          >
            {message}
          </Text>
        </VStack>
      </Card>
    </Box>
  )
}

const NO_MATCH_FOUND_TITLE = (
  <Breakpoint
    mobSm={<>Oops! No Match Found</>}
    tabSm={(
      <>
        Oops! No Match
        <br />
        Found
      </>
    )}
  />
)

const NO_SEARCH_HISTORY_FOUND_TITLE = (
  <Breakpoint
    mobSm={<>Oops! No Search History Found</>}
    tabSm={(
      <>
        Oops! No Search
        <br />
        History Found
      </>
    )}
  />
)

const NO_MATCH_FOUND_MESSAGE = `
  It seems that there are no matching results for your current filter.
  You can try again by adjusting your filters.
`

const NO_SEARCH_HISTORY_FOUND_MESSAGE = `
  It seems that there are no matching results for your search history.
  You can try again with a different keyword or adjusting your filters.
`
