import { Box } from '@chakra-ui/react'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'

const MinimumDealsFilterCard = () => {
  const dealRange = NearbyBuyersHooks.useDealsRange()
  const setDealsRange = NearbyBuyersHooks.useSetDealsRange()

  return (
    <FilterCard
      title='Minimum No. of Properties'
    >
      <Box
        sx={{
          '#partial_range_input__subject_property': {
            display: 'none',
          },
        }}
      >
        <PartialRangeInputV2
          precision={0}
          initialValue={dealRange}
          subjectValue=''
          onDebouncedValidChange={setDealsRange}
        />
      </Box>
    </FilterCard>
  )
}

export default MinimumDealsFilterCard
