import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { TypeToConfirmModalProps } from 'presentation/main/globalModals/TypeToConfirmModal/TypeToConfirmModal.types'
import { modals } from 'presentation/main/modals/modals'

type Props = Omit<TypeToConfirmModalProps, 'isOpen' | 'onClose'>

export const openTypeToConfirmModal = (props: Props): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'TYPE_TO_CONFIRM_MODAL',
      props: {
        ...props,
        onClose: goBackModal,
      },
    },
  })
}

export const updateTypeToConfirmModal = (props: Partial<Props>): void => {
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'TYPE_TO_CONFIRM_MODAL',
      props,
    },
  })
}

export const typeToConfirmModalAPI = {
  open: openTypeToConfirmModal,
  update: updateTypeToConfirmModal,
}
