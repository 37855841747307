import { Box, Grid } from '@chakra-ui/react'
import { WhiteSpace } from 'presentation/components/WhiteSpace/WhiteSpace'
import { px } from 'presentation/utils/px'
import { PropsWithChildren, useState } from 'react'
import { useNoOfLines } from './useNoOfLines'

export type CollapsibleContentProps = PropsWithChildren<{
  maxLineLimit?: number

  /** Initial state if the component is functioning as uncontrolled component (default) */
  initialIsExpanded?: boolean

  /** Necessary for controlled component mode */
  isExpanded?: boolean

  /** Necessary for controlled component mode */
  onExpandChanged?: (expanded: boolean) => void
}>

/**
 * Allows a content to have a limit on the number of lines it should show.
 * When a limit is specified, it will default to collapsed state which
 * truncates the content to the specified no of lines to limit.
 * The truncation is entirely dependent on the parent's width constraints.
 */
export const CollapsibleContent = ({
  children,
  maxLineLimit = 0,
  initialIsExpanded = false,
  isExpanded: isExpandedFromProps,
  onExpandChanged: onExpandChangedFromProps,
}: CollapsibleContentProps) => {
  const [internalIsExpanded, setInternalIsExpanded] = useState(initialIsExpanded)
  const { ref, noOfLines, lineHeight } = useNoOfLines<HTMLDivElement>()

  const isExpanded = isExpandedFromProps ?? internalIsExpanded

  const shouldShowMoreLessButton = maxLineLimit === 0
    ? false
    : noOfLines > maxLineLimit

  const height = maxLineLimit === 0 || noOfLines <= maxLineLimit || isExpanded
    ? 'auto'
    : px(lineHeight * maxLineLimit)

  const handleMoreLessClicked = () => {
    if (onExpandChangedFromProps && isExpanded !== undefined)
      onExpandChangedFromProps(!isExpanded)
    else
      setInternalIsExpanded(!internalIsExpanded)
  }

  return (
    <Box>
      <Grid
      /**
        * Simulate the truncation of the content by limiting the height
        * and hiding the overflow in synced with the truncated content.
        * Hiding the overflow is important because the real content that
        * is being measured has a hidden visibility which means that it
        * would take up space.
        */
        h={height}
        overflow='hidden'
        gridTemplateAreas='"content"'
      >
        {/**
         * @HACK: Make sure we are able to count the number of lines of
         * the whole content but hide it and only show the copy that
         * gets truncated
         */}
        <Box
          ref={ref}
          visibility='hidden'
          gridArea='content'
          /**
           * @NOTE: Make sure the height is not affected by the parent's height
           * This will make sure that noOfLines will not include empty lines added
           * by the height of the parent at first render.
           **/
          h='min-content'
        >
          {children}
        </Box>
        {/**
          * This is what get shown that gets truncated.
          */}
        <Box
          noOfLines={isExpanded ? 0 : maxLineLimit}
          gridArea='content'
        >
          <Box as='span'>
            {children}
          </Box>
          {shouldShowMoreLessButton && (
            <>
              {/* Couldn't get the whitespace render when expanded unless I use this */}
              <WhiteSpace />
              <LessButton onClick={handleMoreLessClicked} />
            </>
          )}
        </Box>
      </Grid>
      {shouldShowMoreLessButton && !isExpanded && (
        <MoreButton onClick={handleMoreLessClicked} />
      )}
    </Box>
  )
}

const MoreButton = (props: {
  onClick?: () => void
}) => (
  <LinkButton onClick={props.onClick}>
    more
  </LinkButton>
)

const LessButton = (props: {
  onClick?: () => void
}) => (
  <LinkButton onClick={props.onClick}>
    less
  </LinkButton>
)

const LinkButton = (props: PropsWithChildren<{
  onClick?: () => void
}>) => (
  <Box
    as='button'
    onClick={props.onClick}
    color='link.500'
    textDecoration='underline'
  >
    {props.children}
  </Box>
)
