import { StackProps } from '@chakra-ui/react'

import { isNotNull } from 'effect/Predicate'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { LocationCounty } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { useBathroomsCount, useBedroomsCount, useGarageSpacesCount, useHighEquityValue, useHomeType, useIsAffidavitsOfHeirshipsFlag, useIsBankFlag, useIsCorporateOwnedFlag, useIsDiscountedListingsFlag, useIsExcludeActiveListingsFlag, useIsFailedListingFlag, useIsFreeAndClearFlag, useIsHOALiensFlag, useIsHandymanSpecialsFlag, useIsInStateFlag, useIsMilitaryFlag, useIsOutOfCountryFlag, useIsOutOfStateFlag, useIsPersonalResidenceFlag, useIsPreforeclosureFlag, useIsProbatesFlag, useIsSeniorFlag, useIsSubstituteTrusteesFlag, useIsTaxDelinquent, useIsTrustFlag, useIsUnknownEquityFlag, useIsUnknownOwnershipFlag, useIsVacantFlag, useLivingAreaSqft, useLocationCriteria, useLotAreaSize, useLowEquityValue, usePropertyValue, useTaxValue, useUpsideDownValue, useYearBuilt, useYearsOfOwnership } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { ComponentType, ReactNode } from 'react'
import { isNumber } from 'remeda'

export const useCriteriaToChipsData = (criteria: PropertyListCriteria | null): PropertiesFilterChipProps[] => {
  const locationApi = useLocationCriteria()
  const baseLotApi = useLotAreaSize()
  const homeTypeApi = useHomeType()
  const yearsOfOwnershipApi = useYearsOfOwnership()
  const upsideDownValueApi = useUpsideDownValue()
  const highEquityValueApi = useHighEquityValue()
  const lowEquityValueApi = useLowEquityValue()

  if (!criteria) return []

  return [
    // #region Location Chips Data
    ...criteria.location
      .map((location): PropertiesFilterChipProps => ({
        type: 'geographic',
        children: location._tag === 'LocationCounty'
          ? LocationCounty.formatCounty(location.value)
          : location.value,
        onIconClick: () => locationApi.remove([location]),
      })),
    // #endregion

    // #region Property Home Type Chips Data
    ...([
      { specificValue: 'town-house', label: 'Town House' },
      { specificValue: 'multi-family', label: 'Multi Family' },
      { specificValue: 'lot-or-land', label: 'Lot/Land' },
      { specificValue: 'commercial', label: 'Commercial' },
      { specificValue: 'single-family', label: 'Single Family' },
      { specificValue: 'condo', label: 'Condo' },
      { specificValue: 'duplex-to-fourplex', label: 'Duplex - Fourplex' },
      { specificValue: 'mobile', label: 'Mobile Home' },
      { specificValue: 'farm', label: 'Farm' },
    ] satisfies { specificValue: HomeType, label: string }[])
      .map(({ specificValue, label }) => createUnionArrayChipData({
        type: 'property',
        api: homeTypeApi,
        specificValue,
        label,
        value: criteria.propertyDetails.homeType,
      })),
    // #endregion

    // #region Property Chips Data
    createRangeChipData({
      type: 'property',
      api: useBedroomsCount(),
      label: 'Beds',
      value: criteria.propertyDetails.bedroomsCount,
    }),
    createRangeChipData({
      type: 'property',
      api: useBathroomsCount(),
      label: 'Baths',
      value: criteria.propertyDetails.bathroomsCount,
    }),
    createRangeChipData({
      type: 'property',
      api: useGarageSpacesCount(),
      label: 'Garage',
      value: criteria.propertyDetails.garageSpacesCount,
    }),
    createRangeChipData({
      type: 'property',
      api: useYearBuilt(),
      label: 'Year Built',
      value: criteria.propertyDetails.yearBuilt,
    }),
    createRangeChipData({
      type: 'property',
      api: useLivingAreaSqft(),
      label: 'House Size',
      value: criteria.propertyDetails.livingAreaSqft,
    }),
    createRangeChipData({
      type: 'property',
      api: {
        value: baseLotApi.value.range,
        apply: (value: PartialRange) => {
          baseLotApi.apply({
            range: value,
            unit: baseLotApi.value.unit,
          })
        },
      },
      value: criteria.propertyDetails.lotAreaSize.range,
      label: 'Lot Size',
    }),
    // #endregion

    // #region Ownership Chips Data
    createRangeChipData({
      type: 'ownership',
      api: yearsOfOwnershipApi,
      label: 'Years Owned',
      formatValue: NumberLib.formatComma,
      value: criteria.yearsOfOwnership,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsPersonalResidenceFlag(),
      label: 'Owner Occ',
      value: criteria.ownershipFlags.isPersonalResidence,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsInStateFlag(),
      label: 'Absentee',
      value: criteria.ownershipFlags.isInState,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsOutOfStateFlag(),
      label: 'Absentee / OOS',
      value: criteria.ownershipFlags.isOutOfState,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsOutOfCountryFlag(),
      label: 'Absentee / OOC',
      value: criteria.ownershipFlags.isOutOfCountry,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsMilitaryFlag(),
      label: 'Military',
      value: criteria.ownershipFlags.isMilitary,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsSeniorFlag(),
      label: 'Senior',
      value: criteria.ownershipFlags.isSenior,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsCorporateOwnedFlag(),
      label: 'Corporate Owned',
      value: criteria.ownershipFlags.isCorporateOwned,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsTrustFlag(),
      label: 'Trust',
      value: criteria.ownershipFlags.isTrust,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsBankFlag(),
      label: 'REO/Bank',
      value: criteria.ownershipFlags.isBank,
    }),
    createNullableBooleanChipData({
      type: 'ownership',
      api: useIsUnknownOwnershipFlag(),
      label: 'Unknown Ownership',
      value: criteria.ownershipFlags.isUnknown,
    }),
    // #endregion

    // #region Stress Indicator Chips Data
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsPreforeclosureFlag(),
      label: 'Preforeclosure',
      value: criteria.stressIndicatorFlags.isPreforeclosure,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsSubstituteTrusteesFlag(),
      label: 'Substitute Trustees',
      value: criteria.stressIndicatorFlags.isSubstituteTrustees,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsProbatesFlag(),
      label: 'Probates',
      value: criteria.stressIndicatorFlags.isProbates,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsAffidavitsOfHeirshipsFlag(),
      label: 'Affidavits of Heirships',
      value: criteria.stressIndicatorFlags.isAffidavitsOfHeirships,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsHOALiensFlag(),
      label: 'HOA Liens',
      value: criteria.stressIndicatorFlags.isHOALiens,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsTaxDelinquent(),
      label: 'Tax Delinquent',
      value: criteria.stressIndicatorFlags.isTaxDelinquent,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsVacantFlag(),
      label: 'Vacant',
      value: criteria.stressIndicatorFlags.isVacant,
    }),
    createNullableBooleanChipData({
      type: 'stressIndicator',
      api: useIsFailedListingFlag(),
      label: 'Failed Listing',
      value: criteria.stressIndicatorFlags.isFailedListing,
    }),
    // #endregion

    // #region Equity Chips Data
    createRangeChipData({
      type: 'equity',
      api: usePropertyValue(),
      label: 'Est. Value',
      formatValue: Dollar.formatNumberWithCommas,
      value: criteria.equityValue.propertyValue,
    }),
    createRangeChipData({
      type: 'equity',
      api: useTaxValue(),
      label: 'Tax Value',
      formatValue: Dollar.formatNumberWithCommas,
      value: criteria.equityValue.taxValue,
    }),
    createNullableBooleanChipData({
      type: 'equity',
      api: useIsFreeAndClearFlag(),
      label: 'Free & Clear',
      value: criteria.equityValue.equityValueFlags.isFreeAndClear,
    }),
    createNullableBooleanChipData({
      type: 'equity',
      label: 'Unknown Equity',
      api: useIsUnknownEquityFlag(),
      value: criteria.equityValue.equityValueFlags.isUnknown,
    }),
    criteria.equityValue.upsideDownValue.minAmount !== null
      ? {
        type: 'equity' as const,
        children: `Upside Down at least: ${Dollar.formatNumberWithCommas(criteria.equityValue.upsideDownValue.minAmount)}`,
        onIconClick: () => upsideDownValueApi.apply({ minAmount: null }),
      }
      : null,
    (
      isNumber(criteria.equityValue.highEquityValue.minPercentage)
      || isNumber(criteria.equityValue.highEquityValue.minAmount)
    )
      ? {
        type: 'equity' as const,
        children: `Min Eq.: ${[
          isNumber(criteria.equityValue.highEquityValue.minPercentage)
            ? `${NumberLib.formatComma(criteria.equityValue.highEquityValue.minPercentage)}%`
            : null,
          isNumber(criteria.equityValue.highEquityValue.minAmount)
            ? Dollar.formatNumberWithCommas(criteria.equityValue.highEquityValue.minAmount)
            : null,
        ]
          .filter(isNotNull)
          .join(` ${criteria.equityValue.highEquityValue.condition} `)}`,
        onIconClick: () =>
          highEquityValueApi.apply({ minAmount: null, condition: 'and', minPercentage: null }),
      }
      : null,
    (
      isNumber(criteria.equityValue.lowEquityValue.maxPercentage)
      || isNumber(criteria.equityValue.lowEquityValue.maxAmount)
    )
      ? {
        type: 'equity' as const,
        children: `Max Eq.: ${[
          isNumber(criteria.equityValue.lowEquityValue.maxPercentage)
            ? `${NumberLib.formatComma(criteria.equityValue.lowEquityValue.maxPercentage)}%`
            : null,
          isNumber(criteria.equityValue.lowEquityValue.maxAmount)
            ? Dollar.formatNumberWithCommas(criteria.equityValue.lowEquityValue.maxAmount)
            : null,
        ]
          .filter(isNotNull)
          .join(` ${criteria.equityValue.lowEquityValue.condition} `)}`,
        onIconClick: () =>
          lowEquityValueApi.apply({ maxAmount: null, condition: 'and', maxPercentage: null }),
      }
      : null,
    // #endregion

    // #region MLS Chips Data
    createNullableBooleanChipData({
      type: 'mls',
      api: useIsDiscountedListingsFlag(),
      label: 'Discounted Listings',
      value: criteria.mlsFlags.isDiscountedListings,
    }),
    createNullableBooleanChipData({
      type: 'mls',
      api: useIsHandymanSpecialsFlag(),
      label: 'Handyman Specials',
      value: criteria.mlsFlags.isHandymanSpecials,
    }),
    createNullableBooleanChipData({
      type: 'mls',
      api: useIsExcludeActiveListingsFlag(),
      label: 'Exclude Active',
      value: criteria.mlsFlags.isExcludeActiveListings,
    }),
    // #endregion

  ].filter(isNotNull)
}

const createRangeChipData = ({
  type,
  api,
  label,
  valuePrefix = '',
  formatValue = String,
  value,
}: {
  type: PropertyFilterChipType
  api: UseCriteriaHookApi<PartialRange>
  label: string
  valuePrefix?: string
  formatValue?: (value: number) => string
  value: PartialRange
}): PropertiesFilterChipProps | null => {
  const humanReadable = PartialRange.formatHumanReadable(value, {
    prefix: valuePrefix,
    formatValue,
  })

  if (!humanReadable) return null
  return {
    type,
    children: `${label}: ${humanReadable}`,
    onIconClick: () => api.apply(PartialRange.EMPTY),
  }
}

const createNullableBooleanChipData = ({
  type,
  api,
  label,
  value,
}: {
  type: PropertyFilterChipType
  api: UseCriteriaHookApi<boolean | null>
  label: string
  value: boolean | null
}): PropertiesFilterChipProps | null => {
  if (!value) return null
  return {
    type,
    children: label,
    onIconClick: () => api.apply(null),
  }
}

const createUnionArrayChipData = <T extends string>({
  type,
  api,
  specificValue,
  value,
  label,
}: {
  type: PropertyFilterChipType
  api: UseCriteriaHookApi<T[]>
  value: T[]
  specificValue: T
  label: string
}): PropertiesFilterChipProps | null => {
  if (!value.includes(specificValue)) return null
  return {
    type,
    children: label,
    onIconClick: () => api.apply(
      value.filter(value => value !== specificValue),
    ),
  }
}

const TYPES_TO_COLOR_SCHEME = {
  geographic: 'highlight',
  property: 'neutral',
  ownership: 'warm',
  stressIndicator: 'positive',
  equity: 'error',
  mls: 'positivesat',
}

export type PropertyFilterChipType = keyof typeof TYPES_TO_COLOR_SCHEME

export type PropertiesFilterChipProps = StackProps & {
  iconComponent?: ComponentType | null
  onIconClick?: () => void
  type: PropertyFilterChipType
  children: ReactNode
}
