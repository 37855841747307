import { Box, BoxProps, Flex, FlexProps, Text } from '@chakra-ui/react'
import { PlanRank } from 'features/Billing/Plans/getPlanRank'
import { useRankTheme } from 'features/Billing/Plans/useRankTheme'
import { FC } from 'react'
import { formatPenniesOptionalDec } from 'utils/dataAdapter'

export type PriceTagProps = {
  price: number
  planInterval: string
  variant?: PlanRank
} & FlexProps

export const PriceTag: FC<PriceTagProps> = ({
  price,
  planInterval,
  variant,
  ...props
}) => {
  const { foldColor, mainColor, textColor } = useRankTheme(variant ?? null)
  const dollarSign = <Text as='span' textStyle='small-b' color={textColor}>$</Text>
  return (
    <Flex pos='relative' {...props}>
      <Box left='-7px' pos='absolute'>
        {' '}
        <Fold borderRightColor={foldColor} />
        {' '}
      </Box>
      <Box flexBasis='min-content'>
        <Flex
          flexDirection='column'
          backgroundColor={mainColor}
          pt='1'
          px='0.75'
          alignItems='stretch'
        >
          <Flex
            justifyContent='space-between'
            alignItems='center'
          >
            <Text alignSelf='flex-start' textStyle='small-b'>
              {' '}
              {dollarSign}
              {' '}
            </Text>
            <Text
              textStyle='inter-28-900'
              color={textColor}
            >
              {formatPenniesOptionalDec(price)}
            </Text>
            {/* Add same natural space whatever the size of the dollar */}
            <Text visibility='hidden' textStyle='small-b'>
              {' '}
              {dollarSign}
              {' '}
            </Text>
          </Flex>
          <Text
            mt='-0.75'
            textStyle='lato-12-700'
            color={textColor}
            whiteSpace='nowrap'
            textTransform='capitalize'
            mb='1px'
            textAlign='center'
          >
            {planInterval}
          </Text>
        </Flex>
        <BottomTriangle color={mainColor} mt='-1px' />
      </Box>
    </Flex>
  )
}

const Fold: FC<BoxProps> = props => (
  <Box
    height='0'
    width='0'
    borderRightWidth='7px'
    borderRightStyle='solid'
    borderTop='7.5px solid transparent'
    {...props}
  />
)

const BottomTriangle: FC<BoxProps> = props => (
  <Box {...props}>
    <svg
      stroke='none'
      strokeWidth='1'
      fill='currentColor'
      fillRule='evenodd'
      viewBox='0 0 70 16'
    >
      <polygon
        id='Path'
        fillRule='nonzero'
        points='0 -54 70 -54 70 1.082 35.0000397 16 0 1.082'
      />
    </svg>
  </Box>
)
