import type { Router } from '@remix-run/router/dist/router.d.ts'

let resolveRouter: (Router: Router) => void
const RouterPromise = new Promise<Router>(resolve => {
  resolveRouter = resolve
})

/** Access app router using this, in order to avoid circular dependency */
export const RouterExposer = {
  expose: (Router: Router) => {
    resolveRouter(Router)
  },

  get: async () => await RouterPromise,
}
