export const RESTLib = {
  makeRequestReparseable: (
    request: Request,
  ): Request => {
    const originalJson = request.json

    ;(request as any).__MODIFIED__ = true
    request.json = async () => {
      if (request.bodyUsed) return (request as any).parsedData

      const data = await originalJson.call(request)

      ;(request as any).parsedData = data

      return data
    }

    return request
  },

  makeResponseReparseable: (
    response: Response,
  ): Response => {
    const originalJson = response.json

    ;(response as any).__MODIFIED__ = true
    response.json = async () => {
      if (response.bodyUsed) return (response as any).parsedData

      const data = await originalJson.call(response)

      ;(response as any).parsedData = data

      return data
    }

    return response
  },
}
