import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { CMAEntriesSortMenuValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/components/CMAEntriesSortMenu/CMAEntriesSortMenuValue'
import { CMAEntriesShowValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'

export const filterByShowValue = (showValues: CMAEntriesShowValue[]) =>
  (comp: (CMA.SingleComp | CMA.SubjectComp)) => {
    // so far we don't ever what to hide subject comps
    if (comp.type === 'subject-comp')
      return true

    // if status mismatches, don't show even if it matches "show thumbs down" filter
    if (!showValues.includes(comp.status))
      return false

    // if thumbs down is excluded, and comp is thumbs down, don't show
    if (!showValues.includes('THUMBS_DOWN') && comp.userRating === 'excluded')
      return false

    return true
  }

export const sortBySortValue = (sortValue: CMAEntriesSortMenuValue) =>
  (a: (CMA.SingleComp | CMA.SubjectComp), b: (CMA.SingleComp | CMA.SubjectComp)) =>
    pipe(
      Match.value(sortValue),
      Match.when('PRICE_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aPrice = a.salePrice || a.listPrice || 0
        const bPrice = b.salePrice || b.listPrice || 0
        return aPrice - bPrice
      })),
      Match.when('PRICE_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aPrice = a.salePrice || a.listPrice || 0
        const bPrice = b.salePrice || b.listPrice || 0
        return bPrice - aPrice
      })),
      Match.when('YEAR_BUILT_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aYearBuilt = a.yearBuilt ?? 0
        const bYearBuilt = b.yearBuilt ?? 0
        return aYearBuilt - bYearBuilt
      })),
      Match.when('YEAR_BUILT_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aYearBuilt = a.yearBuilt ?? 0
        const bYearBuilt = b.yearBuilt ?? 0
        return bYearBuilt - aYearBuilt
      })),
      Match.when('SOLD_DATE_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aSoldDate = a.saleDate ?? a.listDate
        const bSoldDate = b.saleDate ?? b.listDate
        if (!aSoldDate && bSoldDate)
          return 1
        if (aSoldDate && !bSoldDate)
          return -1
        if (!aSoldDate || !bSoldDate)
          return 0
        return aSoldDate.getTime() - bSoldDate.getTime()
      })),
      Match.when('SOLD_DATE_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aSoldDate = a.saleDate ?? a.listDate
        const bSoldDate = b.saleDate ?? b.listDate
        if (!aSoldDate && bSoldDate)
          return 1
        if (aSoldDate && !bSoldDate)
          return -1
        if (!aSoldDate || !bSoldDate)
          return 0
        return bSoldDate.getTime() - aSoldDate.getTime()
      })),
      Match.when('SQUARE_FEET_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aSquareFeet = a.livingAreaSqft ?? 0
        const bSquareFeet = b.livingAreaSqft ?? 0
        return aSquareFeet - bSquareFeet
      })),
      Match.when('SQUARE_FEET_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aSquareFeet = a.livingAreaSqft ?? 0
        const bSquareFeet = b.livingAreaSqft ?? 0
        return bSquareFeet - aSquareFeet
      })),
      Match.when('PPSQFT_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aPricePerSqft = a.salePricePerSqft || a.listPricePerSqft || 0
        const bPricePerSqft = b.salePricePerSqft || b.listPricePerSqft || 0
        return aPricePerSqft - bPricePerSqft
      })),
      Match.when('PPSQFT_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aPricePerSqft = a.salePricePerSqft || a.listPricePerSqft || 0
        const bPricePerSqft = b.salePricePerSqft || b.listPricePerSqft || 0
        return bPricePerSqft - aPricePerSqft
      })),
      Match.when('DOM_ASC', sortUnlessSubject(a, b, (a, b) => {
        const aDom = a.daysOnMarket ?? 0
        const bDom = b.daysOnMarket ?? 0
        return aDom - bDom
      })),
      Match.when('DOM_DESC', sortUnlessSubject(a, b, (a, b) => {
        const aDom = a.daysOnMarket ?? 0
        const bDom = b.daysOnMarket ?? 0
        return bDom - aDom
      })),
      Match.when('DISTANCE_NEAR_TO_FAR', sortUnlessSubject(a, b, (a, b) => {
        const aDistance = a.distance ?? 0
        const bDistance = b.distance ?? 0
        return aDistance - bDistance
      })),
      Match.when('DISTANCE_FAR_TO_NEAR', sortUnlessSubject(a, b, (a, b) => {
        const aDistance = a.distance ?? 0
        const bDistance = b.distance ?? 0
        return bDistance - aDistance
      })),
      Match.exhaustive,
    )

const sortUnlessSubject = (
  a: (CMA.SingleComp | CMA.SubjectComp),
  b: (CMA.SingleComp | CMA.SubjectComp),
  fn: (a: CMA.SingleComp, b: CMA.SingleComp) => number,
) => () => {
  // subject comp is always first
  if (a.type === 'subject-comp')
    return -1
  if (b.type === 'subject-comp')
    return 1

  return fn(a, b)
}
