import { menuAnatomy } from '@chakra-ui/anatomy'
import { StyleFunctionProps, createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { merge } from 'remeda'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers([
    ...menuAnatomy.keys,
    'title',
    'itemButton',
    'scrollArea',
  ])

const itemBaseStyle = defineStyle({
  ...textStyles.bodyMFat,

  'display': 'flex',
  'alignItems': 'center',
  'gap': '1',
  'flexShrink': '0',

  'bg': 'none',
  'color': 'graystrong.400',
  'borderRightRadius': 'full',
  'h': '5',
  'p': 0,
  'pl': '2',

  '& > span': {
    margin: 0,
  },

  '_checked': {
    bg: 'neutral.500',
    color: 'ondark.1',
  },
  '_hover': {
    bg: 'card.bg.xlightblue',
    color: 'accent.blue-to-gray',
  },

  '_focus': {
    bg: 'card.bg.xlightblue',
    color: 'accent.blue-to-gray',
  },

})

const baseStyle = definePartsStyle({
  button: {},
  list: {
    'zIndex': 'popover',
    'bg': 'accent.hover',
    'borderColor': 'grayweak.500',
    'borderRadius': '2',
    'py': '2',
    'pr': '2',
    'pl': '0',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'stretch',
    'gap': '0.5',

    '& .chakra-menu__group': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '0.5',
    },
  },
  groupTitle: {
    ...textStyles.bodyLFat,
    m: 0,
    px: 2,
    py: 1,
  },
  command: {},
  divider: {},

  /** Custom Parts */
  title: {
    ...textStyles.bodyLFat,
    display: 'flex',
    alignItems: 'center',
    color: 'grayweak.900',
    pl: '2',
    pb: '1',
  },
  itemButton: {
    ...itemBaseStyle,
    w: 'full',
    textAlign: 'left',
    color: 'accent.blue-to-gray',
    textTransform: 'uppercase',
  },
  scrollArea: {
    maxH: mbp({ mobSm: null, tabSm: '200px' }),
    overflowY: mbp({ mobSm: null, tabSm: 'auto' }),
    mr: '-2',
    pr: '2',
  },
})

const selectVariant = definePartsStyle({
  list: {
    w: 'full',
    borderRadius: 1,
  },
  item: itemBaseStyle,
  button: {
    'borderColor': 'grayweak.600',
    'boxShadow': 'none',
    'borderWidth': '1px',
    'borderRadius': 1,
    'px': 2,
    'py': 1,
    'bgColor': 'card.bg.1',
    'textAlign': 'left',
    '&[aria-expanded="true"]': {
      borderColor: 'neutral.500',
      boxShadow: 'depth',
      borderWidth: '2px',
      py: px(8 - 1), // padding - border
      px: px(16 - 1), // padding - border
    },
    '&.disabled': {
      boxShadow: 'none',
      cursor: 'not-allowed',
      bgColor: 'grayweak.400',
    },
    '& .select-menu-button__value': {
      ...textStyles.bodyL,
      color: 'graystrong.200',
    },
    '&.disabled .select-menu-button__value': {
      color: 'grayweak.800',
    },
    '& .select-menu-button__icon': {
      color: 'graystrong.200',
      ml: 'auto',
      boxSize: 3,
    },
    '&.disabled .select-menu-button__icon': {
      color: 'grayweak.800',
    },
    '_hover': {
      boxShadow: 'depth',
      borderWidth: '2px',
      py: px(8 - 1), // padding - border
      px: px(16 - 1), // padding - border
    },
    '_focus': {
      borderColor: 'neutral.500',
      boxShadow: 'depth',
      borderWidth: '2px',
      py: px(8 - 1), // padding - border
      px: px(16 - 1), // padding - border
    },
  },
})

const defaultVariant = definePartsStyle({
  list: {
    w: '300px',
  },
  item: itemBaseStyle,
})

const actionSheetVariant = definePartsStyle({
  list: {
    p: 0,
    px: 1,
    gap: 2,
    bg: 'none',
    border: 0,
  },
})

export const cardColorSchemes = {
  accent: definePartsStyle({
    list: {
      bg: 'accent.hover',
      borderColor: 'grayweak.500',
    },
    groupTitle: {
      color: 'grayweak.900',
    },
  }),
  highlight: definePartsStyle({
    list: {
      bg: 'highlight.100',
      borderColor: 'highlight.100',
    },
  }),
}

export const variants = {
  default: (props: StyleFunctionProps) => merge(
    getRecKey(props.colorScheme)(cardColorSchemes) || {},
    defaultVariant,
  ),
  select: (props: StyleFunctionProps) => merge(
    getRecKey(props.colorScheme)(cardColorSchemes) || {},
    selectVariant,
  ),
  actionSheet: (props: StyleFunctionProps) => merge(
    getRecKey(props.colorScheme)(cardColorSchemes) || {},
    actionSheetVariant,
  ),
}

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'default',
    colorScheme: 'accent',
  },
})
