import { DomainError, PartialError } from 'libs/errors'
import { ToErrorFields } from 'libs/errors/FieldsError'
import { MembersScreenText } from 'presentation/screens/MembersScreen/MembersScreen.text'

const partialErrorTag = Symbol('partialInviteMembersErrorTag')

/**
 * @IMPORTANT PartialError is like FieldsError but specifically indicates that
 *   operation succeeded for subset of fields.
 *
 *   In contrast to FieldsError, which is more general error for forms.
 */
export class PartialInviteMembersError extends PartialError<{ emails: string[] }> {
  [partialErrorTag] = true

  name = 'PartialInviteMembersError'

  constructor(
    public message: string,
    public fields: ToErrorFields<{ emails: string[] }>,
    public remainingEmails: string[],
  ) {
    super(message, fields)
  }
}

const memberAlreadyInvitedErrorTag = Symbol('memberAlreadyInvitedErrorTag')
export class MemberAlreadyInvitedError extends DomainError {
  [memberAlreadyInvitedErrorTag] = true

  constructor() {
    super(MembersScreenText.errors.memberAlreadyInvited)
  }
}

const memberBelongsToDifferentTeamErrorTag = Symbol('memberBelongsToDifferentTeamErrorTag')
export class MemberBelongsToDifferentTeamError extends DomainError {
  [memberBelongsToDifferentTeamErrorTag] = true

  constructor() {
    super(MembersScreenText.errors.memberBelongsToDifferentTeam)
  }
}
