import { Box, Button, Drawer, DrawerContent, DrawerOverlay, Flex } from '@chakra-ui/react'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { ResetIcon } from 'presentation/components/Icons'
import { ModalCloseIcon } from 'presentation/components/Modal/ModalCloseIcon'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CompsFilterPanelDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/CompsFilterPanel.props'

export const CompsFilterPanelDrawer = (props: CompsFilterPanelDumbProps) => {
  const isFiltering = useCMAStore(state =>
    !checkStateHasReportData(state.local.report)
    || state.local.report.status === 'filtering',
  )

  const handleOnClose = () => {
    props.closePanel()
  }

  const handleOnClearAllFilters = () => {
    props.clearAllFilters()
  }

  return (
    <Drawer onClose={handleOnClose} isOpen={props.isPanelOpen} size='md'>
      <DrawerOverlay />
      <DrawerContent
        bg='transparent'
        maxW='576px'
        borderLeftRadius={4}
      >
        <Card
          display='flex'
          flexDirection='column'
          colorScheme='modal.highlight'
          borderRightRadius={0}
          px={0}
          pb={0}
          h='100%'
        >
          <CardPrimaryTitle
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            px={3}
          >
            All Filters
            <CloseButton onClick={handleOnClose} />
          </CardPrimaryTitle>

          {/** Filters */}
          <ScrollWithFade
            containerProps={{
              h: 'full',
              mt: 4,
              flex: 1,
            }}
            fadeColor='rgb(0, 0, 0, 0.15)'
            fadeSize={3}
            py={2}
            px={3}
          >
            <Box
              display='flex'
              flexDirection='column'
              gap={4}
              pb={2}
            >
              {props.children}
            </Box>
          </ScrollWithFade>

          {/**  Footer Actions  */}
          <Flex
            mt='auto'
            boxShadow='0px -4px 10px 0px rgba(0, 0, 0, 0.25)'
            py={2}
            px={3}
            justifyContent='space-between'
            borderBottomLeftRadius={4}
          >
            <Button
              colorScheme='neutral'
              variant='ghost'
              size='md'
              textTransform='uppercase'
              onClick={handleOnClearAllFilters}
              leftIcon={<ResetIcon boxSize={3} />}
            >
              Clear All Filters
            </Button>
            <Button
              colorScheme='positive'
              variant='solid'
              size='md'
              textTransform='uppercase'
              onClick={props.closePanel}
              isLoading={isFiltering}
              loadingText='Filtering'
            >
              See
              {' '}
              {props.resultCount}
              {' '}
              Results
            </Button>
          </Flex>
        </Card>
      </DrawerContent>
    </Drawer>
  )
}

const CloseButton = (props: {
  onClick?: () => void
}) => (
  <Box
    as='button'
    onClick={props.onClick}
    display='flex'
    alignItems='center'
    justifyContent='center'
    w={4}
    h={4}
    bgColor='onlight.1'
    borderRadius='50%'
    color='ondark.5'
  >
    <ModalCloseIcon
      boxSize='1.5'
      color='inherit'
    />
  </Box>
)
