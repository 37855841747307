import { Flex, Grid, Text } from '@chakra-ui/react'
import { Dollar } from 'libs/dollar'
import { Tooltip } from 'presentation/components/Tooltip'
import { CMAEntryTileCardProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/CMAEntryTileCard.props'

export const PriceSection = (props: CMAEntryTileCardProps) => {
  const prices = [
    {
      label: 'LP',
      value: Dollar.formatKODoDD(props.listPrice),
      tooltip: 'List Price',
    },
    {
      label: 'SP',
      value: Dollar.formatKODoDD(props.salePrice),
      tooltip: 'Sales Price',
    },
    {
      label: 'DOM',
      value: props.daysOnMarket ?? '--',
      tooltip: 'Days on Market',
    },
    {
      label: 'LP SQFT',
      value: Dollar.formatKODoDD(props.listPricePerSqft),
      tooltip: 'List Price Sqft',
    },
    {
      label: 'SP SQFT',
      value: Dollar.formatKODoDD(props.salePricePerSqft),
      tooltip: 'Sales Price Sqft',
    },
  ] as const
  return (
    <Grid
      templateColumns='repeat(3, auto)'
    >
      {prices.map((price, i) => (
        <Tooltip
          key={i}
          label={price.tooltip}
        >
          <Flex
            // if third column, align to the right to push it to
            // the right end of the card
            {...((i + 1) % 3 === 0) && { justifySelf: 'flex-end' }}
            alignItems='center'
            gap={0.5}
          >
            <Text
              textStyle='tagL'
              color='graystrong.200'
            >
              {price.label}
            </Text>
            <Text
              textStyle='bodyMFat'
              color='accent.blue-text'
            >
              {price.value}
            </Text>
          </Flex>
        </Tooltip>
      ))}
    </Grid>
  )
}
