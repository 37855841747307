import { Box, Button, Flex, Menu, MenuButton, MenuItem, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { FilterMenuButtonProps, FilterMenuListProps, FilterMenuProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterMenu/FilterMenu.props'
import { NoDataWarningIcon } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/NoDataWarningIcon'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const FilterMenu = (props: FilterMenuProps) => (
  <Menu
    offset={[0, 16]}
    closeOnBlur={true}
    isOpen={props.isOpen}
  >
    {props.children}
  </Menu>
)

export const FilterMenuButton = (props: FilterMenuButtonProps) => {
  const {
    label,
    children,
    shouldShowWarning,
    hasFilter,
    hasError,
    ...menuButtonProps
  } = props
  return (
    <MenuButton
      as={Button}
      bgColor='card.bg.1'
      variant='outline'
      colorScheme={hasError ? 'negative' : 'neutral'}
      size={mbp({ mobSm: 'md', dtLg: 'xl' })}
      position='relative'
      className={classNames({ 'has-filter': hasFilter })}
      rightIcon={<ChevronDownIcon boxSize={3} />}
      sx={{
        '&.chakra-menu__menu-button': {
          px: 2,
        },
      }}
      {...menuButtonProps}
    >
      {shouldShowWarning && (
        <NoDataWarningIcon
          color='negative.500'
          boxSize={3}
          position='absolute'
          top='-8px'
          right='4px'
        />
      )}
      <Flex
        flexDirection='column'
        alignItems='stretch'
        minW={0}
        gap={0.125}
      >
        <Text
          textStyle='tagM'
          textAlign='left'
          color={hasError ? 'negative.500' : 'accent.blue-to-gray'}
        >
          {label}
        </Text>
        <Box
          textStyle={mbp({ mobSm: 'bodySFat', dtLg: 'bodyMFat' })}
          textAlign='left'
          color='graystrong.400'
          lineHeight='normal'
          isTruncated
        >
          {children}
        </Box>
      </Flex>
    </MenuButton>
  )
}

export const FilterMenuList = (props: FilterMenuListProps) => (
  <ResponsiveMenuList {...props}>
    <Flex gap={3} flexDirection='column' w='full'>
      {props.children}
    </Flex>
  </ResponsiveMenuList>
)

export const FilterMenuItem = MenuItem
