import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { CMAState, checkStateHasReportData } from 'features/CMA/infra/react/CMAState'
import { PartialRange } from 'features/valueObjects/Range'
import { isNumber } from 'remeda'

export const selectReportStateByLeadId = (leadId: string) =>
  (state: CMAState): CMAState['local']['report'] | null => {
    const reportState = state.local.report
    if (!('leadId' in reportState))
      return null

    if (reportState.leadId !== leadId)
      return null

    return reportState
  }

export const selectReportByLeadId = (leadId: string) =>
  (state: CMAState): CMA.Report | null => {
    const reportState = selectReportStateByLeadId(leadId)(state)
    if (reportState === null)
      return null

    if (!checkStateHasReportData(reportState))
      return null

    return reportState.data
  }

export const selectSalesComps = (state: CMAState) => ({
  subject: state.local.report.status === 'loaded'
    ? state.local.report.data.salesListInfo.subject
    : null,
  comps: state.local.report.status === 'loaded'
    ? state.local.report.data.salesListInfo.comps
    : [],
  isLoading: ['loading', 'filtering', 'regenerating']
    .includes(state.local.report.status),
  rate: state.actions.rateComp,
})

export const selectRentalsComps = (state: CMAState) => ({
  subject: state.local.report.status === 'loaded'
    ? state.local.report.data.rentalsListInfo.subject
    : null,
  comps: state.local.report.status === 'loaded'
    ? state.local.report.data.rentalsListInfo.comps
    : [],
  isLoading: ['loading', 'filtering', 'regenerating']
    .includes(state.local.report.status),
})

export type SelectCompsByOption = 'SALES' | 'RENTALS'

export const selectCompsBy = (comps: SelectCompsByOption) => (state: CMAState) => {
  const report = state.local.report
  return {
    subject: checkStateHasReportData(report)
      ? pipe(
        Match.value(comps),
        Match.when('SALES', () => report.data.salesListInfo.subject),
        Match.when('RENTALS', () => report.data.rentalsListInfo.subject),
        Match.exhaustive,
      )
      : null,
    comps: checkStateHasReportData(report)
      ? pipe(
        Match.value(comps),
        Match.when('SALES', () => report.data.salesListInfo.comps),
        Match.when('RENTALS', () => report.data.rentalsListInfo.comps),
        Match.exhaustive,
      )
      : [],
    isLoading: ['loading', 'filtering', 'regenerating']
      .includes(state.local.report.status),
    rate: state.actions.rateComp,
  }
}

export const selectDidExceedMaxResults = (comps: SelectCompsByOption) => (state: CMAState) => {
  const reportState = state.local.report
  if (!checkStateHasReportData(reportState)) return false

  return comps === 'SALES'
    ? reportState.data.salesListInfo.didExceedMaxResults
    : reportState.data.rentalsListInfo.didExceedMaxResults
}

export const selectFiltersCount = (state: CMAState) => {
  const reportState = state.local.report
  if (!checkStateHasReportData(reportState)) return null

  const filters = reportState.data.filters
  const filtersCount = [
    isNumber(filters.soldWithinMonths.max),
    isNumber(filters.distanceMiles.max),
    PartialRange.checkHasValueOptional(filters.yearBuilt?.range),
    PartialRange.checkHasValueOptional(filters.bedroomsCount?.range),
    PartialRange.checkHasValueOptional(filters.bathroomsCount?.range),
    PartialRange.checkHasValueOptional(filters.livingAreaSqft?.range),
    PartialRange.checkHasValueOptional(filters.lotAreaSize?.sqftRange),
    PartialRange.checkHasValueOptional(filters.garageSpacesCount?.range),
    filters.poolsCount?.availablility
    && filters.poolsCount?.availablility !== 'any',
  ]
    .filter(Boolean)
    .length

  return filtersCount
}
