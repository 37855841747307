import { Box, BoxProps, Divider, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { Link } from 'react-router-dom'
import { ConsumableCircularStat, ConsumableType, consumableDisplayText } from './ConsumableCircularStat'

export type ConsumableInfoCardProps = {
  /**
   * Total number of free consumables on the plan
   */
  totalFree: number
  /**
   * Remaining number of free consumables
   */
  remainingFree: number
  /**
   * Price per unit in pennies, optional for property-lookup type that requires plan upgrade
   */
  itemPrice?: string
  /**
   * Plan name (Basic, Free, etc.)
   */
  currentPlan: string
  /**
   * Next renewal date formatted as string
   */
  nextRenewalDate: string
  /**
   * Type of consumable (affects display text)
   */
  consumableType: ConsumableType
  /**
   * URL for plan upgrade
   */
  upgradeUrl: string
  /**
   * Custom label for left pair key (defaults to "Monthly Free {plural}")
   */
  labelLeft?: string
  /**
   * Custom description (replaces default plan description)
   */
  customDescription?: string
  /**
   * Custom circular stat label
   */
  customCircularLabel?: string
} & BoxProps

export const ConsumableInfoCard = (props: ConsumableInfoCardProps) => {
  const {
    totalFree,
    remainingFree,
    itemPrice,
    currentPlan,
    nextRenewalDate,
    consumableType,
    upgradeUrl,
    labelLeft,
    customDescription,
    customCircularLabel,
    ...boxProps
  } = props

  const { sbp } = useSwitchBreakpointFn()

  const displayText = consumableDisplayText[consumableType]

  const singularItem = sbp({
    mobSm: displayText.shortName,
    tabSm: displayText.singular,
  }) ?? displayText.singular

  const term = displayText.term
  const isPropertyLookup = consumableType === 'property-lookup'

  // Prepare the plan display text based on whether currentPlan is provided
  const planDisplayText = currentPlan
    ? (
      <>
        You&rsquo;re on the
        {' '}
        <strong>
          {currentPlan}
        </strong>
      </>
    )
    : <>Your current plan</>

  return (
    <Box
      backgroundColor='neutral.100'
      p='3'
      borderRadius={4}
      {...boxProps}
    >
      <Stack
        justifyContent='space-between'
        gap='3'
        direction={{
          base: 'column',
          tabSm: 'row',
        }}
      >
        <ConsumableCircularStat
          remainingFree={remainingFree}
          totalFree={totalFree}
          consumableType={consumableType}
          customLabel={customCircularLabel}
        />
        <VStack alignItems='flex-start'>
          <Stack
            alignSelf='stretch'
            gap={{
              base: '0.5',
              tabSm: '2',
            }}
            direction={{
              base: 'column',
              tabSm: 'row',
            }}
            align='center'
          >
            <Pair
              minW='160'
              size='md'
              sx={{
                flexDirection: {
                  base: 'row',
                  tabSm: 'column',
                },
                gap: '0.5',
              }}
            >
              <PairKey>
                {labelLeft || `Monthly Free ${pluralize(singularItem)}:`}
              </PairKey>
              <PairValue>
                {`${NumberLib.formatComma(totalFree)} free`}
              </PairValue>
            </Pair>
            <Pair
              minW='160'
              size='md'
              sx={{
                flexDirection: {
                  base: 'row',
                  tabSm: 'column',
                },
                gap: '0.5',
              }}
            >
              <PairKey>
                Next Renewal Date:
              </PairKey>
              <PairValue>
                {nextRenewalDate}
              </PairValue>
            </Pair>
          </Stack>
          <Divider borderColor='grayweak.500' />
          <HStack>
            <RegularCircleExclamationIcon color='neutral.600' boxSize='3' />
            <Text
              textStyle='bodyM'
              color='graystrong.200'
            >
              {customDescription || (
                <>
                  {planDisplayText}
                  {isPropertyLookup
                    ? ` includes ${totalFree} free property lookups. Upgrade your plan to get more credits.`
                    : ` includes ${pluralize(term, totalFree, true)} free monthly ${pluralize(term, 2)}. Additional ${pluralize(term, 2)} cost ${itemPrice} each.`}
                </>
              )}
              {' '}
              <Text as={Link} color='link' to={upgradeUrl}>
                Upgrade Plan
              </Text>
            </Text>
          </HStack>
        </VStack>
      </Stack>
    </Box>
  )
}
