import { SeekersQuery } from '__generated__/graphql'
import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { PersonName } from 'features/valueObjects/PersonName'

export const mapSeekers = (input: SeekersQuery): SearchHistory.Seeker[] =>
  input.myEnterprise?.users?.edges.map(edge => ({
    id: edge.node.id,
    avatarUrl: edge.node.avatar?.avatarUrl && !edge.node.avatar.avatarUrl.includes('d=blank')
      ? edge.node.avatar.avatarUrl
      : null,
    name: {
      first: edge.node.firstname || '',
      last: edge.node.lastname || '',
      full: PersonName.combine(edge.node.firstname, edge.node.lastname) || '',
    },
  })) || []
