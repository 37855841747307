import { Access } from 'features/Access/domain/Access.domain'
import { AccessState } from 'features/Access/infra/react/AccessState.types'
import { AppDomainEvents } from 'features/events/AppDomainEvents'
import { nanoid } from 'nanoid'
import loadGoogleTagManager from 'presentation/libs/loadGoogleTagManager'
import { createContext, useContext, useMemo, useRef } from 'react'
import { setPath } from 'remeda'
import { create, useStore } from 'zustand'

// ========================================
// AccessDeps
// ========================================

export type AccessDeps = {
  getAccess: (payload?: { shouldIgnoreCache?: boolean }) =>
  Promise<Access.GetAccessResult>

  login: (payload: Access.LoginPayload) =>
  Promise<Access.LoginResult>

  register: (payload: Access.RegisterPayload) =>
  Promise<Access.RegisterResult>

  resendConfirmationEmail: () =>
  Promise<Access.ResendConfirmationEmailResult>

  sendResetPasswordEmail: (payload: Access.SendResetPasswordEmailPayload) =>
  Promise<Access.SendResetPasswordEmailResult>

  changePassword: (payload: Access.ChangePasswordPayload) =>
  Promise<Access.ChangePasswordResult>

  logout: () =>
  Promise<Access.LogoutResult>
}

// ========================================
// createAccessStore
// ========================================

export const createAccessStore = (deps: AccessDeps) => create<AccessState>((set, get) => {
  const getAccess: AccessState['actions']['getAccess'] = {
    execute: async () => {
      const initialAccess = get().local.access
      const shouldPreventReRequest
        = initialAccess.status === 'loading'

      if (shouldPreventReRequest) return

      set(setPath(
        ['local', 'access'],
        { status: 'loading' },
      ))

      await deps.getAccess()
        .then(data => ({ status: 'loaded', data }))
        .catch(() => ({
          status: 'loaded',
          data: {
            status: 'logged-out',
          },
        }))
        .then(nextState => {
          set(setPath(
            ['local', 'access'],
            nextState,
          ))
        })

      AppDomainEvents.emit('access/getAccess/success', get())
    },
  }

  const updateAccess: AccessState['actions']['updateAccess'] = {
    execute: async () => {
      const initialAccess = get().local.access
      const shouldPreventReRequest
        = initialAccess.status === 'loading'
        || initialAccess.status === 'updating'

      if (shouldPreventReRequest) return

      const isDataAvailable = checkLocalAccessForData(initialAccess)
      const isUpdatingLoggedInUser = isDataAvailable
        && initialAccess.data.status === 'logged-in'

      if (isUpdatingLoggedInUser) {
        set(setPath(
          ['local', 'access', 'status'],
          'updating',
        ))
      } else {
        set(setPath(
          ['local', 'access'],
          { status: 'loading' },
        ))
      }

      await deps.getAccess({
        shouldIgnoreCache: true,
      })
        .then(data => ({ status: 'loaded', data }))
        .catch(() => ({
          status: 'loaded',
          data: {
            status: 'logged-out',
          },
        }))
        .then(nextState => {
          set(setPath(
            ['local', 'access'],
            nextState,
          ))
        })

      AppDomainEvents.emit('access/updateAccess/success', get())
    },
  }

  const login: AccessState['actions']['login'] = {
    state: { status: 'initial' },
    execute: async params => {
      if (get().actions.login.state.status === 'loading') return

      set(setPath(
        ['actions', 'login', 'state'],
        { status: 'loading' },
      ))

      await deps.login(params)
        .then(result => {
          if (result.status === 'credentials-error') {
            set(setPath(
              ['actions', 'login', 'state'],
              { status: 'error', error: result },
            ))

            return
          }

          set(setPath(
            ['actions', 'login', 'state'],
            { status: 'success' },
          ))

          void getAccess.execute()
            .then(() => {
              AppDomainEvents.emit('access/login/success', get())
            })
        })
        .catch(error => {
          set(setPath(
            ['actions', 'login', 'state'],
            { status: 'error', error },
          ))
        })
    },
  }

  const register: AccessState['actions']['register'] = {
    state: { status: 'initial' },
    execute: async params => {
      if (get().actions.register.state.status === 'loading') return

      set(setPath(
        ['actions', 'register', 'state'],
        { status: 'loading' },
      ))

      await deps.register(params)
        .then(result => {
          if (result.status === 'email-taken-error') {
            set(setPath(
              ['actions', 'register', 'state'],
              { status: 'error', error: result },
            ))

            return
          }

          set(setPath(
            ['actions', 'register', 'state'],
            { status: 'success' },
          ))

          void getAccess.execute().then(() => {
            void loadGoogleTagManager().sendTrialEvent()
          })
        })
        .catch(error => {
          set(setPath(
            ['actions', 'register', 'state'],
            { status: 'error', error },
          ))
        })
    },
  }

  const resendConfirmationEmail: AccessState['actions']['resendConfirmationEmail'] = {
    state: { status: 'initial' },
    execute: async () => {
      const requestId = nanoid()
      set(setPath(
        ['actions', 'resendConfirmationEmail', 'state'],
        { status: 'loading', requestId },
      ))

      await deps.resendConfirmationEmail()
        .then(() => {
          set(setPath(
            ['actions', 'resendConfirmationEmail', 'state'],
            { status: 'success', requestId },
          ))
        })
        .catch(error => {
          set(setPath(
            ['actions', 'resendConfirmationEmail', 'state'],
            { status: 'error', requestId, error },
          ))
        })
    },
  }

  const sendResetPasswordEmail: AccessState['actions']['sendResetPasswordEmail'] = {
    state: { status: 'initial' },
    execute: async params => {
      set(setPath(
        ['actions', 'sendResetPasswordEmail', 'state'],
        { status: 'loading' },
      ))

      await deps.sendResetPasswordEmail(params)
        .then(() => {
          set(setPath(
            ['actions', 'sendResetPasswordEmail', 'state'],
            { status: 'success' },
          ))
        })
        .catch(error => {
          set(setPath(
            ['actions', 'sendResetPasswordEmail', 'state'],
            { status: 'error', error },
          ))
        })
    },
  }

  const changePassword: AccessState['actions']['changePassword'] = {
    state: { status: 'initial' },
    execute: async params => {
      set(setPath(
        ['actions', 'changePassword', 'state'],
        { status: 'loading' },
      ))

      await deps.changePassword(params)
        .then(() => {
          set(setPath(
            ['actions', 'changePassword', 'state'],
            { status: 'success' },
          ))
        })
        .catch(error => {
          set(setPath(
            ['actions', 'changePassword', 'state'],
            { status: 'error', error },
          ))
        })
    },
  }

  const logout: AccessState['actions']['logout'] = {
    state: { status: 'initial' },
    execute: async () => {
      set(setPath(
        ['actions', 'logout', 'state'],
        { status: 'loading' },
      ))

      AppDomainEvents.emit('access/logout/loading', get())

      await deps.logout()
        // Let's we assume session is comprimised, and when we're wrong,
        // the app would just reload.
        //
        // Better than if we assume the session is still valid,
        // and we're wrong, app will not be functional and user might continue
        // to use the app in non-functional state.
        .catch(() => {})

      AppDomainEvents.emit('access/logout/success', get())
    },
  }

  const clearErrors: AccessState['actions']['clearErrors'] = {
    execute: () => {
      set(setPath(
        ['actions', 'login', 'state'],
        { status: 'initial' },
      ))

      set(setPath(
        ['actions', 'register', 'state'],
        { status: 'initial' },
      ))

      set(setPath(
        ['actions', 'resendConfirmationEmail', 'state'],
        { status: 'initial' },
      ))

      set(setPath(
        ['actions', 'sendResetPasswordEmail', 'state'],
        { status: 'initial' },
      ))

      set(setPath(
        ['actions', 'changePassword', 'state'],
        { status: 'initial' },
      ))
    },
  }

  return {
    local: {
      access: { status: 'initial' },
    },
    actions: {
      getAccess,
      updateAccess,
      login,
      register,
      resendConfirmationEmail,
      sendResetPasswordEmail,
      logout,
      changePassword,
      clearErrors,
    },
  }
})

export type AccessStore = ReturnType<typeof createAccessStore>

let singletonInstance: AccessStore | null
let resolveSingletonInstance: (store: AccessStore) => void
const singletonInstancePromise = new Promise<AccessStore>(resolve => {
  resolveSingletonInstance = resolve
})

export const createAccessStoreSingleton = (deps: AccessDeps) => {
  if (singletonInstance) return singletonInstance

  singletonInstance = createAccessStore(deps)
  resolveSingletonInstance(singletonInstance)

  return singletonInstance
}

export const getAccessStoreSingleton = async () =>
  await singletonInstancePromise

// ========================================
// React helpers
// ========================================

const AccessContext = createContext<AccessStore | null>(null)

export type AccessProviderProps = AccessDeps & {
  useCase?: 'app' | 'test' | 'storybook'
  children: React.ReactNode
}

export const AccessProvider = ({
  children,

  /**
   * When useCase is app, we use singleton for store (so we can access it
   * outside of React,) otherwise we create new instance everytime which is
   * desirable for storybook/testing
   */
  useCase = 'app',
  ...deps
}: AccessProviderProps) => {
  const initStore = useMemo(() =>
    useCase === 'app'
      ? createAccessStoreSingleton(deps)
      : createAccessStore(deps),
  [])

  const store = useRef(initStore).current

  return (
    <AccessContext.Provider value={store}>
      {children}
    </AccessContext.Provider>
  )
}

// eslint-disable-next-line @stylistic/comma-dangle
export const useAccessStore = <T,>(
  selector: (store: AccessState) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(AccessContext)
  if (!store) throw new Error('useAccessStore must be used within AccessProvider')
  return useStore(store, selector, equalityFn)
}

// ========================================
// Internal Helpers
// ========================================

type LocalAccess = AccessState['local']['access']
type LocalAccessWithData = Extract<LocalAccess, { status: 'loaded' | 'updating' }>

export const checkLocalAccessForData = (
  access: AccessState['local']['access'],
): access is LocalAccessWithData => access.status === 'loaded' || access.status === 'updating'
