import { createIcon } from '@chakra-ui/react'

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
      stroke='currentColor'
    >
      <path d='M12 5V19' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 12H19' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
