import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeDuplexFourplex = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M22.5 21L14.5 29V51H45.5V29L37.5 21L30 13L22.5 21ZM29.375 15.4943L23.3981 21.8696L15.75 29.5178V49.75H29.375V15.4943ZM30.625 49.75H44.25V29.5178L36.6019 21.8696L30.625 15.4943V49.75Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M20.25 33.25H18.75V34.75H20.25V33.25ZM17.5 32V36H21.5V32H17.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M35.25 33.25H33.75V34.75H35.25V33.25ZM32.5 32V36H36.5V32H32.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M20.25 39.25H18.75V40.75H20.25V39.25ZM17.5 38V42H21.5V38H17.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M35.25 39.25H33.75V40.75H35.25V39.25ZM32.5 38V42H36.5V38H32.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M26.25 33.25H24.75V34.75H26.25V33.25ZM23.5 32V36H27.5V32H23.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M41.25 33.25H39.75V34.75H41.25V33.25ZM38.5 32V36H42.5V32H38.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M26.25 39.25H24.75V40.75H26.25V39.25ZM23.5 38V42H27.5V38H23.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M41.25 39.25H39.75V40.75H41.25V39.25ZM38.5 38V42H42.5V38H38.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M23.25 45.25H21.75V49.75H23.25V45.25ZM20.5 44V51H24.5V44H20.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M38.25 45.25H36.75V49.75H38.25V45.25ZM35.5 44V51H39.5V44H35.5Z' fill={props.color} />
    <chakra.path fillRule='evenodd' clipRule='evenodd' d='M30 10.375C30.1728 10.375 30.3378 10.4465 30.456 10.5725L37.9491 18.5652L47.9419 28.5581C48.186 28.8021 48.186 29.1979 47.9419 29.4419C47.6979 29.686 47.3021 29.686 47.0581 29.4419L37.0581 19.4419C37.0533 19.4372 37.0486 19.4324 37.044 19.4275L30 11.9138L22.956 19.4275C22.9529 19.4308 22.9497 19.4341 22.9465 19.4373C22.945 19.4389 22.9435 19.4404 22.9419 19.4419L12.9419 29.4419C12.6979 29.686 12.3021 29.686 12.0581 29.4419C11.814 29.1979 11.814 28.8021 12.0581 28.5581L22.0509 18.5652L29.544 10.5725C29.6622 10.4465 29.8272 10.375 30 10.375Z' fill={props.color} />
  </chakra.g>
)
