import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { createPropertyDetailsUrlFromComp } from 'features/CMA/infra/react/CMA.helpers'
import { EnterIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Tooltip } from 'presentation/components/Tooltip'
import { PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { getMarkerPopupColors } from 'presentation/screens/CompsScreen/components/CompsMap/components/utils/getMarkerPopupColors'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { ComparableEntryCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparableEntryCard/ComparableEntryCard.props'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useHoverDirty } from 'react-use'
import { isEmpty, isNonNull } from 'remeda'

export const ComparableEntryCard = (props: ComparableEntryCardProps) => {
  const {
    comp,
    isMuted,
    isSelected,
    onCompsRatingChange,
  } = props
  const ref = useRef<HTMLDivElement>(null)

  const isHovering = useHoverDirty(ref)

  const markerPopupColors = getMarkerPopupColors(comp.status)

  const shouldMute = isMuted && !isHovering

  const isSubdivisionAvailable = isNonNull(comp.address.subdivision)
    && !isEmpty(comp.address.subdivision)

  const subdivision = isSubdivisionAvailable
    ? comp.address.subdivision
    : '--'

  const subdivisionDisplay = (
    <Text
      textStyle='bodyM'
      color='inherit'
      isTruncated
    >
      {subdivision}
    </Text>
  )

  return (
    <Box
      ref={ref}
      borderColor={markerPopupColors.cardBorderColor}
      bgColor={markerPopupColors.cardBgColor}
      borderWidth={0.125}
      borderRadius={3}
      p={isSelected ? 1.5 : 0}
      overflow={isSelected ? 'visible' : 'hidden'}
      w='full'
      h='min-content'
      position='relative'
      shadow='float'
      cursor='pointer'
      /**
       * Muted Overlay
       */
      _after={{
        ...shouldMute && {
          borderRadius: 3,
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          zIndex: 3,
          background: isMuted ? 'gw.100.am' : 'gw.100.az',
          transition: 'all 0.2s ease',
        },
      }}
    >

      {/** HEADER */}
      <Flex
        borderTopRadius={isSelected ? 2 : 0}
        py={1}
        px={2}
        bgColor={markerPopupColors.headerBgColor}
        gap={1}
        alignItems='center'
      >
        {/* @GENESIS Current comps service does not support this */}
        {/* <PropertyMarker
          markerType='pin'
          classification={comp.classification}
          equityType={comp.equityType}
          isVacant={comp.isVacant}
          isForeclosure={comp.isForeclosure}
          isSenior={comp.isSenior}
          size='xs'
        /> */}
        <Flex
          minW={0}
          flex='1'
          flexDirection='column'
          color={markerPopupColors.textColor}
        >
          <Flex minW={0} alignItems='center'>
            <Tooltip label={comp.address.line1}>
              <Text
                textStyle='bodyMFat'
                isTruncated
                color='inherit'
                mr={1}
              >
                {comp.address.line1}
              </Text>
            </Tooltip>
            <Box as={Link} target='_blank' to={createPropertyDetailsUrlFromComp(comp)}>
              <EnterIcon
                boxSize={2}
                color='inherit'
              />
            </Box>
          </Flex>
          {comp.address.subdivision !== null
            ? (
              <Tooltip label={subdivision}>
                {subdivisionDisplay}
              </Tooltip>
            )
            : subdivisionDisplay}
        </Flex>
        <CompsThumbButtons
          comp={props.comp}
          onCompsRatingChange={onCompsRatingChange}
          ml='auto'
        />
      </Flex>

      <Grid
        gridTemplateAreas={'"layer"'}
        borderBottomRadius={isSelected ? 2 : 0}
        overflow='hidden'
      >

        <PhotosSlider
          gridArea='layer'
          minW={0}
          zIndex='1'
          photos={comp.photos}
          shouldOpenFullscreenOnSlideClick={false}
        />

        <GridItem
          gridArea='layer'
          alignSelf='end'
          justifySelf='end'
          zIndex='2'
          mr={1}
          mb={1}
        >
          <MLSStatusTag
            status={comp.status}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}
