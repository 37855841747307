import { Button, IconButton } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { selectCompsBy } from 'features/CMA/infra/react/CMAState.selectors'
import { ID } from 'libs/id'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon, HelpCircleIcon } from 'presentation/components/Icons'
import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { TitleBar } from 'presentation/components/TitleBar/TitleBar'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { CMARatingChangeEventHandler } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { useComparativePropertyModal } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal.api'
import { ComparativePropertyCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCard/ComparativePropertyCard'
import { ComparativePropertyCardMobile } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCardMobile'
import { ComparativePropertyCardMobileSmall } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCardMobileSmall'
import { useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useCallback } from 'react'

export const ComparativePropertyModal = () => {
  const {
    state: modalState,
    actions,
  } = useComparativePropertyModal()

  const { selectedListType: activeTab } = useActiveEntriesTab()

  const rate = useCMAStore(state => state.actions.rateComp)

  const data = useCMAStore(storeState => {
    if (modalState.status === 'closed') return null

    const {
      subject,
      comps: rawComps,
    } = selectCompsBy(activeTab)(storeState)

    if (!subject) return null

    const {
      modalType,
      comps: modalComps,
    } = getModalComps({
      subject,
      comps: rawComps,
      targetCompId: modalState.targetCompId,
    })

    return {
      modalType,
      subject,
      comps: modalComps,
    }
  })

  const handleRatingChange: CMARatingChangeEventHandler = useCallback((compId, rating) => {
    void rate.execute({ compId, rating })
  }, [])

  const handleOnClose = () => {
    actions.close()
  }

  if (!data) return null

  if (modalState.status === 'closed') return null

  const {
    modalType,
    subject,
    comps,
  } = data

  return (
    <ResponsiveFullscreenModal
      isOpen
      onClose={handleOnClose}
      title='Comparable Sales'
      size='modal.default'
      titleBarJsx={(
        <TitleBar
          leftJsx={(
            <Button
              leftIcon={<ArrowLeftIcon />}
              variant='solid'
              colorScheme='ondark'
              size='sm'
              alignSelf='center'
              onClick={handleOnClose}
              textTransform='none'
              px={1}
              _active={{
                px: 1,
              }}
              sx={
                modalType === 'single-comp'
                  ? { color: 'ondark.2' }
                  : { color: 'graystrong.600' }
              }
            >
              Comparable Sales
            </Button>
          )}
          rightJsx={(
            <IconButton
              aria-label='help'
              variant='icon-ghost'
              sx={
                modalType === 'single-comp'
                  ? {}
                  : { color: 'graystrong.600' }
              }
              size='md'
              icon={<HelpCircleIcon />}
              onClick={() => {
                void SupportWidget.openWidget({ topic: 'support' })
              }}
            />
          )}
          bg={modalType === 'single-comp'
            ? 'special.500'
            : 'card.bg.yellow'}
        />
      )}
    >
      <Breakpoint
        mobSm={(
          <ComparativePropertyCardMobileSmall
            modalType={modalType}
            subject={subject}
            comps={comps}
            targetCompId={modalState.targetCompId}
            onCompsRatingChange={handleRatingChange}
            onClose={actions.close}
          />
        )}
        mob={(
          <ComparativePropertyCardMobile
            modalType={modalType}
            subject={subject}
            comps={comps}
            targetCompId={modalState.targetCompId}
            onCompsRatingChange={handleRatingChange}
            onClose={actions.close}
          />
        )}
        tabSm={(
          <ComparativePropertyCard
            modalType={modalType}
            subject={subject}
            comps={comps}
            targetCompId={modalState.targetCompId}
            onCompsRatingChange={handleRatingChange}
            onClose={actions.close}
          />
        )}
      />
    </ResponsiveFullscreenModal>
  )
}

type GetModalComps = (params: {
  subject: CMA.SubjectComp | null
  comps: CMA.SingleComp[]
  targetCompId: ID | null
}) => {
  modalType: 'single-point-comps' | 'single-comp'
  comps: (CMA.SingleComp | CMA.SubjectComp)[]
}

/**
 * Determine the type of modal to show and the right comps for
 * that modal. This enables to show single point comps. Clicking
 * on the marker popup of single point comps, we get easy access
 * to single point comps because it is already grouped. In the
 * side panel, we have to do the grouping ourselves.
 */
const getModalComps: GetModalComps = params => {
  if (params.subject === null) {
    return {
      modalType: 'single-comp',
      comps: [],
    }
  }

  const withSubject = [params.subject, ...params.comps]

  const targetComp = withSubject.find(comp => comp.id === params.targetCompId)

  const grouped = withSubject.filter(comp =>
    comp.location
    && targetComp?.location
    && comp.location.longitude === targetComp?.location.longitude
    && comp.location.latitude === targetComp?.location.latitude,
  )

  const isSinglePoint = grouped.length > 1

  if (isSinglePoint) {
    return {
      modalType: 'single-point-comps',
      comps: grouped,
    }
  }

  return {
    modalType: 'single-comp',
    comps: params.comps,
  }
}
