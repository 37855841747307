import { Text, TextProps } from '@chakra-ui/react'
import { useHashScrollStore } from 'presentation/main/AppLevelHooks/useHashScroll'
import { MouseEvent as ReactMouseEvent, forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

type Event = ReactMouseEvent<HTMLAnchorElement, MouseEvent>
type HashLinkProps = Omit<TextProps & LinkProps, 'onClick'> & {
  onClick?: (ev: Event) => void
}

/**
 * React router link that also triggers a scroll to the element with the id of
 * the hash.
 */
export const HashLink = forwardRef<HTMLAnchorElement, HashLinkProps>(({ onClick, ...props }, ref) => {
  const setLastRequestedHash = useHashScrollStore(state => state.setLastRequest)

  const _onClick = (ev: Event) => {
    onClick?.(ev)
    setLastRequestedHash(ev.currentTarget.hash)
  }

  return (
    <Text
      as={Link}
      ref={ref}
      // @ts-expect-error TODO explain what's the issue
      onClick={_onClick}
      {...props}
    />
  )
})

HashLink.displayName = 'HashLink'
