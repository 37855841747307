import { HTTPError } from 'ky'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib } from 'libs/errors'
import { restClient } from 'presentation/libs/client'
import { MemberAlreadyInvitedError, MemberBelongsToDifferentTeamError } from 'presentation/screens/MembersScreen/MembersScreen.errors'
import { useCallback } from 'react'

export type InviteMemberPayload = { email: string }
/** @TODOREQUIRED When backend is fixed, handle MemberAlreadyHasPropelioAccount | ProgramException */
export type InviteMemberError = Error
export type InviteMemberCommand = Command.PromiseVersion<InviteMemberError, InviteMemberPayload>

/**
 * @TODO This returns success no matter what, because the API doesn't return
 *   proper responses. It should be fixed on the API side.
 * @NOTE Doesn't update the query state, meant to be combined into list form of
 *   command (useInviteMembersCommand,) which is where the query is refetched.
 */
export const useInviteMemberCommand = (): InviteMemberCommand => {
  const { transition } = CQReact.useStateManager<InviteMemberError, InviteMemberPayload>(
    CQ.createIdleState,
  )

  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<InviteMemberCommand['execute']>(async payload => {
    const commandId = regenerateId()

    return await restClient.post('invite/send', {
      json: {
        email: payload.email,
        url: `${window.location.origin}/reset-password`,
      },
    })
      /** @TODOREQUIRED Check response when backend is fixed */
      .then(() => transition.success({ id: commandId, payload }))
      .catch(async rawErr => {
        if (rawErr instanceof HTTPError) {
          const parsedData = (await ErrorLib.safeParseError(rawErr))?.serverResponse

          if (rawErr.response.status === 409 || parsedData?.error?.type === 'email_already_exists')
            return transition.error({ id: commandId, error: new MemberAlreadyInvitedError(), payload })
          else if (rawErr.response.status === 403)
            return transition.error({ id: commandId, error: new MemberBelongsToDifferentTeamError(), payload })
        }

        void ErrorLib.report(rawErr)

        const error = new Error('An error occurred while inviting the email')
        return transition.error({ id: commandId, error, payload })
      })
  }, [])

  return {
    execute,
  }
}
