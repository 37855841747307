import { Effect, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

/**
 * @NOTE: batched instead?
 */
const DeleteByIdResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.DeleteById) => pipe(
    Effect.tryPromise({
      try: async () =>
        await restClient.delete(`lists/v3/${request.listId}`)
          .json(),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404)
          return new ListNotFoundError({ marketingListId: request.listId })

        return new UnknownException(error)
      },
    }),
    Effect.andThen(Effect.void),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default DeleteByIdResolver
