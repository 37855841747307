import NearbyBuyersContext from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersContext'
import { UsePropertyResult, useProperty } from 'features/PropertyDetails/infra/react/hooks/useProperty'
import { useGetPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useEffect } from 'react'

const usePropertyDataNearbyBuyersMachineInterface = () => {
  useGetPropertyByRouteLeadId()

  const result = useProperty()
  const actor = NearbyBuyersContext.useActorRef()

  useEffect(() => {
    if (UsePropertyResult.$is('Success')(result)) {
      actor.send({
        type: 'load-property-succeeded',
        property: result.value,
      })
    } else if (UsePropertyResult.$is('Error')(result)) {
      actor.send({ type: 'load-property-failed', error: result.error })
    }
  }, [result, actor])
}

export default usePropertyDataNearbyBuyersMachineInterface
