import { BoxProps } from '@chakra-ui/react'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { MLSDisclaimer } from 'presentation/components/MLSDisclaimerSection/MLSDisclaimer'

export const CompsMLSDisclaimer = (props: BoxProps) => {
  const data = useCMAStore(state => {
    const reportState = state.local.report
    if (!checkStateHasReportData(reportState))
      return null
    return {
      organizations: reportState.data.organizations,
      updatedDate: reportState.data.updatedAt,
      maxMonthsFilter: reportState.data.filters.soldWithinMonths.range[1],
    }
  })

  if (!data)
    return null

  return (
    <MLSDisclaimer
      organizations={data.organizations}
      updatedDate={data.updatedDate}
      maxMonthsFilter={data.maxMonthsFilter}
      {...props}
    />
  )
}
