import { CardProps } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { ConsumableAttemptBillingSummaryCard } from 'features/common/ConsumableAttempt/views/ConsumableAttemptBillingSummaryCard'
import { DateLib } from 'libs/Date'
import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { LegacyBillingEvents } from 'presentation/screens/Billing/Billing.events'
import { useEffect } from 'react'

export type CompsInitializeModalProps = {
  isColumnCard?: boolean
  onCancel?: () => void
  isOpen?: boolean
} & CardProps

export const CompsInitializeModal = ({ onCancel, isOpen = true }: CompsInitializeModalProps) => {
  const state = useCompsInitializeModalState()
  const leadId = useLeadId()
  const initializeReport = useCMAStore(state => state.actions.initializeLoadReport.execute)

  // Use the onCancel prop if provided, otherwise use empty function
  const onClose = onCancel || (() => {})

  // Set up event listener for when funds are added
  useEffect(() => {
    if (!leadId) return

    const handleAddFundsDone = () => {
      // Only call initializeReport if we have a valid leadId and state is ready
      if (leadId)
        void initializeReport(leadId)
    }

    // Subscribe to the event and store the emitter
    const emitter = LegacyBillingEvents.addFundsDone.subscribe(handleAddFundsDone)

    // Clean up the subscription when component unmounts
    return () => {
      emitter.off('addFundsDone', handleAddFundsDone)
    }
  }, [leadId, initializeReport])

  if (state.status === 'unready') return null

  return (
    <ResponsiveFullscreenModal
      isOpen={isOpen}
      onClose={onClose}
      title='Comp Report'
    >
      <CompsInitializeCard
        maxW='736px'
        onCancel={onClose}
        state={state}
      />
    </ResponsiveFullscreenModal>
  )
}

type CompsInitializeCardProps = {
  state: ReturnType<typeof useCompsInitializeModalState> & { status: 'ready' }
  isColumnCard?: boolean
  onCancel?: () => void
} & CardProps

const CompsInitializeCard = (props: CompsInitializeCardProps) => {
  const {
    state,
    onCancel,
    isColumnCard,
    ...cardProps
  } = props

  const availableFunds = state.consumableInfo.funds.available
  const pricePenniesPerUnit = state.consumableInfo.price
  const nextRenewalDateRaw = state.consumableInfo.consumablesRefreshDate
  const nextRenewalDate = DateLib.formatMMDDYYDots(nextRenewalDateRaw)

  return (
    <ConsumableAttemptBillingSummaryCard
      title='Comp Report'
      subtitle=''
      consumableType='comps'
      paymentPrice={15} // This may need to be dynamically provided
      pricePenniesPerUnit={pricePenniesPerUnit}
      refreshingConsumables={state.consumableInfo.refreshingConsumables}
      remainingConsumables={state.consumableInfo.remainingConsumables}
      consumablesRefreshDate={nextRenewalDate}
      currentPlan={state.consumableInfo.planName}
      freeItemExceedCount={undefined}
      availableFunds={availableFunds}
      fundsStatus={state.consumableInfo.status}
      onAttempt={state.confirmLoadReport}
      isProcessing={false}
      onCancel={onCancel}
      isColumnCard={isColumnCard}
      upgradeUrl='/pricing#comps'
      quantity={1}
      {...cardProps}
    />
  )
}

const useCompsInitializeModalState = (): {
  status: 'ready'
  consumableInfo: ConsumableAttempt
  confirmLoadReport: () => void
} | {
  status: 'unready'
} => {
  const leadId = useLeadId()

  return useCMAStore(state => {
    const reportState = state.local.report
    const loadReport = state.actions.loadReport.execute

    if (!leadId || reportState.status !== 'initiated') return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      consumableInfo: reportState.consumablesInfo,
      confirmLoadReport: () => void loadReport({
        id: leadId,
        confirmationKey: reportState.consumablesInfo.confirmationKey,
      }),
    }
  })
}
