import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

export type FilterContentGroupProps = {
  title: ReactNode
  toolbar?: ReactNode
} & BoxProps

export const FilterContentGroup = (props: FilterContentGroupProps) => {
  const {
    title,
    toolbar,
    children,
    ...boxProps
  } = props
  return (
    <Box {...boxProps}>
      <Flex
        justifyContent='space-between'
        h={5}
      >
        <Text color='grayweak.900'>
          {title}
        </Text>
        {toolbar && (
          <Box ml='auto'>
            {toolbar}
          </Box>
        )}
      </Flex>
      {children}
    </Box>
  )
}
