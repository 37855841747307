import { BoxProps } from '@chakra-ui/react'
import { Option } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { PrevNextPagination } from 'presentation/components/PrevNextPagination/PrevNextPagination'

export const NearbyBuyersPagination = (props: BoxProps) => {
  const paginationProps = NearbyBuyersHooks.usePaginationProps()

  return paginationProps.pipe(
    Option.map(paginationProps => (
      <PrevNextPagination
        key='nearby-buyers-pagination'
        colorScheme='highlight'
        size='lg'
        {...paginationProps}
        {...props}
      />
    )),
    Option.getOrElse(() => null),
  )
}
