import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { BillingSubscriptionStatus } from '__generated__/graphql'
import { isPast } from 'date-fns'
import dayjs from 'dayjs'
import { PowerIcon, SquareWithArrowIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { NoticeButton, NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { useRestorePlan } from 'presentation/main/globalModals/RestorePlanModal/useRestorePlan'
import { get } from 'presentation/utils/graphql'
import { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

const SUBSCRIPTION_NOTICE__GET_SUBSCRIPTION = gql(`
  query SubscriptionNotice_GetSubscription {
    myEnterprise {
      id

      name
      subscription {
        ... on BillingSubscriptionActive {
          __typename
          id
          cancelAt
          cancelAtPeriodEnd
          ...FailedInvoiceFlow_FailedInvoiceSummary
        }
        ... on BillingSubscriptionInactive {
          __typename
          id
          status
        }
      }
    }
  }`,
)

export const useSubscriptionNotice = () => {
  const { data, loading, error } = useQuery(SUBSCRIPTION_NOTICE__GET_SUBSCRIPTION)
  const sub = data?.myEnterprise?.subscription
  const activeSub = get('BillingSubscriptionActive')(sub) ?? undefined
  const inactiveSub = get('BillingSubscriptionInactive')(sub) ?? undefined

  const failedInvoiceSummary = useMemo(() => unmaskFailedInvoiceFragment(activeSub), [activeSub])
  const {
    restoreWithPayment,
    restoreWithoutPayment,
    loading: isRestorePlanLoading,
  } = useRestorePlan()

  const handleReactivatePlan = useCallback(() => {
    if (failedInvoiceSummary)
      restoreWithPayment(failedInvoiceSummary)
    else
      restoreWithoutPayment()
  }, [failedInvoiceSummary])

  const updateNotice = useNoticeStore(store => store.updateNotice)

  useEffect(() => {
    if (loading || error) return
    const wasSetToCancelInPastDate = activeSub?.cancelAt && isPast(activeSub?.cancelAt)

    const isTrulyInactive = inactiveSub?.status
      && ![
        BillingSubscriptionStatus.Active,
        BillingSubscriptionStatus.Trialing,
      ].includes(inactiveSub.status)
    const hasInactiveSubWithoutStatus = !!inactiveSub && !inactiveSub.status
    const hasNoSub = !activeSub && !inactiveSub

    /** CASE: subscription was set to cancel in past date */
    if (wasSetToCancelInPastDate) {
      updateNotice(
        NOTICE_KEYS.SUBSCRIPTION,
        (
          <ResponsiveNotice
            colorScheme='error'
            icon={TriangleExclamationIcon}
            textContent={(
              <>
                <NoticeEmphasis>
                  Your subscription for
                  {' '}
                  {data?.myEnterprise?.name}
                  {' '}
                  has been cancelled.
                </NoticeEmphasis>
                {' '}
                {activeSub.cancelAt && (
                  <>
                    {' '}
                    Your team will lose access to Propelio on
                    {' '}
                    {dayjs(activeSub.cancelAt).format('MMM D, YYYY')}
                    {' '}
                    unless you reactivate your plan.
                  </>
                )}
              </>
            )}
            actionsContent={(
              <>
                <NoticeButton
                  variant='solid'
                  colorScheme='highlight'
                  leftIcon={<PowerIcon boxSize='2' />}
                  onClick={handleReactivatePlan}
                  isLoading={isRestorePlanLoading}
                >
                  REACTIVATE PLAN
                </NoticeButton>
              </>
            )}
          />
        ),
      )

    /** CASE: no active subscription */
    } else if (isTrulyInactive || hasInactiveSubWithoutStatus || hasNoSub) {
      updateNotice(
        NOTICE_KEYS.SUBSCRIPTION,
        (
          <ResponsiveNotice
            colorScheme='error'
            icon={TriangleExclamationIcon}
            textContent={(
              <>
                <NoticeEmphasis>
                  Your subscription for
                  {' '}
                  {data?.myEnterprise?.name}
                  {' '}
                  has been cancelled.
                </NoticeEmphasis>
                {' '}
                Please subscribe to a plan to continue using Propelio.
              </>
            )}
            actionsContent={(
              <>
                <NoticeButton
                  variant='solid'
                  colorScheme='highlight'
                  leftIcon={<SquareWithArrowIcon fontSize='2' />}
                  as={Link}
                  to='/pricing#first-choose-plan'
                >
                  Choose Plan
                </NoticeButton>
              </>
            )}
          />
        ),
      )
    }

    return () => updateNotice(NOTICE_KEYS.SUBSCRIPTION, null)
  }, [
    loading,
    error,
    activeSub?.cancelAtPeriodEnd,
    data?.myEnterprise?.name,
    activeSub?.cancelAt,
    handleReactivatePlan,
    isRestorePlanLoading,
  ])

  return null
}
