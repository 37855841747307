import { createIcon } from '@chakra-ui/react'

export const DownRightIcon = createIcon({
  displayName: 'DownRightIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d='M10 6.66675L13.3333 10.0001L10 13.3334' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M2.66602 2.66675V7.33341C2.66602 8.04066 2.94697 8.71894 3.44706 9.21903C3.94716 9.71913 4.62544 10.0001 5.33268 10.0001H13.3327' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' fill='none' />
    </>
  ),
})
