import { Box, BoxProps, ResponsiveValue, chakra, createStylesContext, useMultiStyleConfig } from '@chakra-ui/react'
import classNames from 'classnames'
import { BASE_TABS_CLASS_NAMES } from 'presentation/components/BaseTabs/BaseTabs.const'
import { colorScheme, size } from 'presentation/components/BaseTabs/BaseTabs.theme'
import { ComponentProps, createContext, forwardRef, useCallback, useContext, useEffect, useId, useMemo, useState } from 'react'

const [StylesProvider, useStyles] = createStylesContext('BaseTabs')

type ContextValue = {
  firstTabId: string | null
  setFirstTabId: (tabId: string) => void

  firstTabStatus: 'active' | 'inactive' | 'muted' | null
  setFirstTabStatus: (status: 'active' | 'inactive' | 'muted') => void
}

const INITIAL_CONTEXT_VALUE: ContextValue = {
  firstTabId: null,
  setFirstTabId: () => {},

  firstTabStatus: null,
  setFirstTabStatus: () => {},
}

const baseTabsContext = createContext<ContextValue>(INITIAL_CONTEXT_VALUE)

export type BaseTabsProps = BoxProps & {
  size?: size | ResponsiveValue<size>
  colorScheme?: colorScheme
}

export const BaseTabsContainer = forwardRef<HTMLDivElement, BaseTabsProps>((props, ref) => {
  const { size, colorScheme, ...componentProps } = props
  const styles = useMultiStyleConfig('BaseTabs', { size, colorScheme })

  const [firstTabId, setFirstTabId] = useState<string | null>(null)
  const [firstTabStatus, setFirstTabStatus] = useState<'active' | 'inactive' | 'muted' | null>(null)
  const contextValue = useMemo(() => ({
    firstTabId,
    setFirstTabId,
    firstTabStatus,
    setFirstTabStatus,
  }), [firstTabId, firstTabStatus])

  return (
    <baseTabsContext.Provider value={contextValue}>
      <StylesProvider
        value={styles}
      >
        <Box
          ref={ref}
          {...componentProps}
          sx={{ ...styles.container, ...componentProps.sx }}
        >
          {props.children}
        </Box>
      </StylesProvider>
    </baseTabsContext.Provider>
  )
})

BaseTabsContainer.displayName = 'BaseTabsContainer'

export const BaseTabs = forwardRef<HTMLDivElement, BaseTabsProps>((props, passedRef) => {
  const { size, colorScheme, ...componentProps } = props
  const styles = useMultiStyleConfig('BaseTabs', { size, colorScheme })

  const { firstTabId, setFirstTabId } = useContext(baseTabsContext)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!ref) return

    // get first child
    const firstTab = ref.querySelector(`.${BASE_TABS_CLASS_NAMES.tab}`)
    if (!firstTab) return
    const latestFirstTabId = firstTab.getAttribute('data-tabid')
    if (!latestFirstTabId || latestFirstTabId === firstTabId) return
    setFirstTabId(latestFirstTabId)
  }) // do this every render

  const handleRef = useCallback((ref: HTMLDivElement | null) => {
    setRef(ref)
    if (passedRef) {
      if (typeof passedRef === 'function')
        passedRef(ref)
      else
        passedRef.current = ref
    }
  }, [])

  return (
    <Box
      {...componentProps}
      sx={{ ...styles.tabs, ...componentProps.sx }}
      ref={handleRef}
    >
      {props.children}
    </Box>
  )
})

BaseTabs.displayName = 'BaseTabs'

export type BaseTabProps = BoxProps & {
  isActive?: boolean
  isDisabled?: boolean
  isMuted?: boolean
  /** Sometimes, you want to render it (e.g. for SwitchTabs logic) but hide it */
  isHidden?: boolean
}

export const BaseTab = forwardRef<HTMLDivElement, BaseTabProps>((props, ref) => {
  const { isActive, isDisabled, isMuted, isHidden, ...componentProps } = props
  const styles = useStyles()
  const id = useId()
  const { firstTabId, setFirstTabStatus } = useContext(baseTabsContext)

  useEffect(() => {
    if (!firstTabId || firstTabId !== id) return

    if (isActive)
      setFirstTabStatus('active')
    else if (isMuted || isDisabled)
      setFirstTabStatus('muted')
    else
      setFirstTabStatus('inactive')
  }, [firstTabId, isMuted, isDisabled, isActive])

  return (
    <Box
      as='button'
      data-isactive={isActive ? 'true' : 'false'}
      data-isdisabled={isDisabled ? 'true' : 'false'}
      data-ismuted={isMuted ? 'true' : 'false'}
      data-ishidden={isHidden ? 'true' : 'false'}
      data-tabid={id}
      {...componentProps}
      sx={{ ...styles.tab, ...componentProps.sx }}
      className={classNames(
        componentProps.className,
        BASE_TABS_CLASS_NAMES.tab,
      )}
      onClick={isDisabled ? undefined : componentProps.onClick}
      ref={ref}
    >
      <LeftCurve />

      <Box
        sx={{ ...styles.tabText }}
      >
        {props.children}
      </Box>
      <RightCurve />
    </Box>
  )
})

BaseTab.displayName = 'BaseTab'

type SvgProps = ComponentProps<typeof chakra.svg>

type CurveProps = SvgProps & {
  isActive?: boolean
}

const LeftCurve = (props: CurveProps) => {
  const { isActive, ...componentProps } = props
  const styles = useStyles()

  return (
    <chakra.svg
      viewBox='0 0 36 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-isactive={isActive ? 'true' : 'false'}
      data-curveside='left'
      {...componentProps}
      sx={{ ...styles.tabCurve, ...componentProps.sx }}
    >
      <chakra.path d='M0 35C13.5 30.7759 13.5 1.81034 36 0V35H0Z' fill='currentColor' />
    </chakra.svg>
  )
}

const RightCurve = (props: CurveProps) => {
  const { isActive, ...componentProps } = props
  const styles = useStyles()

  return (
    <chakra.svg
      viewBox='0 0 36 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      data-isactive={isActive ? 'true' : 'false'}
      data-curveside='right'
      {...componentProps}
      sx={{ ...styles.tabCurve, ...componentProps.sx }}
    >
      <chakra.path d='M36 35C22.5 30.7759 22.5 1.81034 0 0V35H36Z' fill='currentColor' />
    </chakra.svg>
  )
}

export const BaseTabContent = (props: BoxProps) => {
  const { ...componentProps } = props
  const styles = useStyles()

  return (
    <Box
      {...componentProps}
      sx={{ ...styles.content, ...componentProps.sx }}
    >
      <BaseTabsTopLeftCurveNegative />
      {props.children}
    </Box>
  )
}

export const BaseTabsTopLeftCurveNegative = () => {
  const styles = useStyles()
  const firstTabStatus = useContext(baseTabsContext).firstTabStatus

  return (
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      width='2'
      height='2'
      viewBox='0 0 16 16'
      data-firsttabstatus={firstTabStatus || 'null'}
      sx={{ ...styles.contentTopLeftCurveNegative }}
      className={BaseTabsTopLeftCurveNegative.CLASS_NAME}
    >
      <mask id='mask-quarter-circle'>
        <rect x='0' y='0' width='32' height='32' fill='white' />
        <circle cx='16' cy='16' r='16' fill='black' />
      </mask>
      <rect x='0' y='0' width='32' height='32' fill='currentColor' mask='url(#mask-quarter-circle)' />
    </chakra.svg>
  )
}

BaseTabsTopLeftCurveNegative.CLASS_NAME = 'chakra-base-tabs__content-top-left-curve-negative'
