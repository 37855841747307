import LRUCacheLive from 'services/LRUCacheLive'

export type CachedRequestType =
  // Search History
  | 'getHistory'
  // Property Details
  | 'getProperty'
  // CMA
  | 'loadCMA'
  /** Clearing loadCMA should also clear loadCMA:getPropertyCmaInfo */
  | 'loadCMA:getPropertyCmaInfo'
  | 'getCompsCoverageInfo'
  | 'CMA:loadUiConfig'
  | 'PropertyDetails:getPropertySuggestions'

const get = (type: CachedRequestType) => (key: string): unknown => LRUCacheLive.get(`${type}:${key}`)

const set = (type: CachedRequestType) => (key: string) => (value: any): void => {
  LRUCacheLive.set(`${type}:${key}`, {
    ...value,
    // omit debug info prior to caching
    _original: null,
  })
}

const ONLY_KEY = 'ONLY'
const getOnly = (type: CachedRequestType) => get(type)(ONLY_KEY)
const setOnly = (type: CachedRequestType) => set(type)(ONLY_KEY)

export const ServerCache = {
  get,
  set,

  getOnly,
  setOnly,

  delete: (type: CachedRequestType) => (key: string) => LRUCacheLive.delete(`${type}:${key}`),
  clearType: (type: CachedRequestType) => {
    for (const key of LRUCacheLive.keys())
      if (key.startsWith(`${type}:`)) LRUCacheLive.delete(key)
  },
}
