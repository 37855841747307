import { Box, Button, Center, Checkbox, Flex, IconButton, Text } from '@chakra-ui/react'
import { Option } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import DistanceFilterCard from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/DistanceFilterCard/DistanceFilterCard'
import MinimumDealsFilterCard from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/MinimumDealsFilterCard/MinimumDealsFilterCard'
import NearbyBuyersFilterPanelAPI from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/NearbyBuyersFilterPanel.api'
import SoldWithinFilterCard from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/SoldWithinFilterCard/SoldWithinFilterCard'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon, ResetIcon } from 'presentation/components/Icons'
import { mbp } from 'presentation/utils/mapBreakpoint'

const NearbyBuyersFilterPanelCard = () => {
  const shouldIncludeLandLords = NearbyBuyersHooks.useShouldIncludeLandLords()
  const toggleShouldIncludeLandLords = NearbyBuyersHooks.useToggleShouldIncludeLandLords()
  const shouldIncludeFlippers = NearbyBuyersHooks.useShouldIncludeFlippers()
  const toggleFlippersFilters = NearbyBuyersHooks.useToggleFlippersFilters()

  return (
    <Flex
      flexDir='column'
      bgColor='highlight.100'
      h='app-height'
    >
      <Breakpoint
        mobSm={<MobileHeader />}
        tabSm={<></>}
      />
      <Flex
        flex='1'
        flexDir='column'
      >
        <Flex
          flexShrink={0}
          gap={2}
          alignItems='center'
          py={2}
          px={mbp({
            mobSm: 1,
            mob: 2,
            tabSm: 3,
          })}
        >
          <Center h={5}>
            <Text
              textStyle='bodyXLFat'
              color='accent.blue-text'
              alignItems='center'
            >
              <Breakpoint
                mobSm='Filters'
                tabSm='All Filters'
              />
            </Text>
          </Center>
          <Checkbox
            ml={mbp({
              mobSm: 'auto',
              tabSm: '0px',
            })}
            isChecked={shouldIncludeLandLords}
            onChange={() => toggleShouldIncludeLandLords()}
          >
            <Text textStyle='bodyMFat' color='graystrong.400'>
              Landlords
            </Text>
          </Checkbox>
          <Checkbox
            isChecked={shouldIncludeFlippers}
            onChange={() => toggleFlippersFilters()}
          >
            <Text textStyle='bodyMFat' color='graystrong.400'>
              Flippers
            </Text>
          </Checkbox>
        </Flex>

        <Flex
          flex='1'
          flexBasis={0}
          minH={0}
          flexDir='column'
          px={mbp({
            mobSm: 1,
            mob: 2,
            tabSm: 3,
          })}
          gap={2}
          overflow='auto'
          pb={3}
        >
          <DistanceFilterCard />
          <SoldWithinFilterCard />
          <MinimumDealsFilterCard />
        </Flex>
      </Flex>
      <FilterActions />
    </Flex>
  )
}

export default NearbyBuyersFilterPanelCard

const FilterActions = () => {
  const resetFilters = NearbyBuyersHooks.useResetFilters()
  const areBuyersLoading = NearbyBuyersHooks.useAreBuyersLoading()
  const count = NearbyBuyersHooks.useTotalCount()
  return (
    <Flex
      boxShadow='vscroll-fade-bottom'
      justifyContent='space-between'
      p={mbp({
        mobSm: 1,
        mob: 2,
      })}
      bgColor='highlight.100'
    >
      <Button
        variant='ghost'
        colorScheme='neutral'
        leftIcon={<ResetIcon boxSize={3} />}
        onClick={resetFilters}
      >
        Clear all
      </Button>
      <Button
        variant='solid'
        colorScheme='positive'
        onClick={() => NearbyBuyersFilterPanelAPI.close()}
        spinnerPlacement='end'
        loadingText='Loading'
        isLoading={areBuyersLoading}
      >
        {count.pipe(
          Option.map(count => `See ${count} Results`),
          Option.getOrElse(() => 'See Results'),
        )}
      </Button>
    </Flex>
  )
}

const MobileHeader = () => (
  <Flex
    bgColor='special.500'
    p={1}
    minH={6.5}
    alignItems='center'
  >
    <BackButton />
  </Flex>
)

const BackButton = () => {
  const close = NearbyBuyersFilterPanelAPI.close
  return (
    <Flex
      alignItems='center'
      gap={1}
    >
      <IconButton
        aria-label='Save to List'
        size='md'
        key='right-toolbar-item'
        variant='icon-ghost'
        colorScheme='ondark'
        onClick={close}
      >
        <ArrowLeftIcon />
      </IconButton>

      <Box
        color='ondark.2'
        textStyle={mbp({
          mobSm: 'bodyMFat',
          mob: 'bodyXLFat',
        })}
      >
        Cash Buyers
      </Box>
    </Flex>
  )
}
