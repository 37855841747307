import { Box, BoxProps, Center, Flex, Grid, GridItem, GridProps, HStack, Spinner, Text, useToken, VStack } from '@chakra-ui/react'
import { thumbsUpOnly } from 'features/CMA/CMA.helpers'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Location } from 'features/valueObjects/Location'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ComposeComponents } from 'presentation/components/ComposeComponents/ComposeComponents'
import { Map, MapStoreProvider } from 'presentation/components/Map'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { MapLib } from 'presentation/components/Map/createMapStore'
import { Marker as MarkerType, useCommonMarkerBehaviorsEffect } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { getScale } from 'presentation/components/PropertyMarker/PropertyMarker.utils'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { CMADataUpdateDate } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { useListInfoState } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useListInfoState'
import { CMAEstimateInfoCard } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAEstimateInfoCard'
import { CMANoPropertiesSelected } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMANoPropertiesSelected'
import { CMAReportSwitcher } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher'
import { ReportSwitcherProvider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { useReportTypeSwitcherStore } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportTypeSwitcherStore'
import { CMAReportTotalComps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportTotalComps'
import { CMASalesEstimate } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMASalesEstimate'
import { CompsReportTables } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CompsReportTables'
import { WithScrollableCompsMapBehavior } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/WithScrollableCompsMapBehavior'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { CompsMapMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarker'
import { SubjectAPNPill } from 'presentation/screens/CompsScreen/components/SubjectAPNPill'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FullscreenControl, MapProps, Marker } from 'react-map-gl'
import { isNonNull, noop } from 'remeda'
import { shallow } from 'zustand/shallow'

const GRID_AREA_ID = {
  USER_ESTIMATE: 'user-estimate',
  PROPELIO_ESTIMATE: 'propelio-estimate',
  MAP: 'map',
}

export const CMAReport = (props: BoxProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const report = useCMAStore(api => ({
    totalComps: checkStateHasReportData(api.local.report)
      ? api.local.report.data.salesListInfo.comps.filter(thumbsUpOnly).length
      + api.local.report.data.rentalsListInfo.comps.filter(thumbsUpOnly).length
      : 0,
    hasNoData: !checkStateHasReportData(api.local.report),

    // When filtering, regenerating, loading, initial, etc, we want to show the loading spinner
    isLoading: api.local.report.status !== 'loaded',
  }), shallow)

  if (report.isLoading)
    return <Center p={3} flex='1' w='full' h='full'><Spinner /></Center>

  if (report.hasNoData)
    return <Box p={3} w='full' h='full'><CMANoPropertiesSelected /></Box>

  return (
    <ComposeComponents
      providers={[
        ReportSwitcherProvider,
        MapStoreProvider,
      ]}
    >
      <Box
        w='full'
        h='full'
        {...props}
      >
        <Breakpoint
          mobSm={(
            <HStack alignItems='center' justifyContent='space-between'>
              <VStack spacing={1} align='flex-start'>
                <Text
                  textStyle='bodyLFat'
                  color='graystrong.500'
                >
                  User&rsquo;s Estimated Value
                </Text>
                <CMAReportTotalComps />
              </VStack>
              <VStack align='flex-end' spacing={1}>
                <SubjectAPNPill
                  transformText={sbp({
                    mobSm: (text: string) => `APN ${text}`, // remove pound sign
                    mob: (text: string) => `APN # ${text}`, // add back pound sign
                  })}
                  sx={sbp({
                    mobSm: {
                      fontSize: '9px !important',
                    },
                    mob: {},
                  })}
                />
                <CMADataUpdateDate isShorterText={true} ml='auto' />
              </VStack>
            </HStack>
          )}
          tabSm={<></>}
        />

        <EstimatesWithMapGrid>
          <EstimatesGridItem
            shouldHideTitle={sbp({
              mobSm: true,
              tabSm: false,
            })}
          />
          <PropeliEstimateGridItem />
          <MapGridItem />
        </EstimatesWithMapGrid>

        <CMAReportSwitcher mt={3} />

        <CompsReportTables mt={3} />

        <Box
          mt={3}
          textStyle='bodyL'
          color='graystrong.400'
        >
          <CompsMLSDisclaimer />
        </Box>
      </Box>
    </ComposeComponents>
  )
}

const EstimatesGridItem = ({
  shouldHideTitle = false,
}: {
  shouldHideTitle?: boolean
}) => (
  <GridItem gridArea={GRID_AREA_ID.USER_ESTIMATE}>
    {!shouldHideTitle && (
      <Flex
        {...mbpg({
          mobSm: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          dtSm: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        })}
        h={5}
      >
        <Text
          textStyle='bodyLFat'
          color='graystrong.500'
        >
          User&rsquo;s Estimated Value
        </Text>
        <Breakpoint tabSm={<CMAReportTotalComps />} />
      </Flex>
    )}
    <Flex
      flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
      mt={2}
      gap={mbp({ mobSm: 3, mob: 2, tabSm: 3 })}
    >
      <CMAEstimateInfoCard cardType='SALES' />
      <CMAEstimateInfoCard cardType='RENTALS' />
    </Flex>
  </GridItem>
)

export const PropeliEstimateGridItem = () => (
  <GridItem
    gridArea={GRID_AREA_ID.PROPELIO_ESTIMATE}
    display='flex'
    flexDirection='column'
    gap={2}
  >
    <Flex
      {...mbpg({
        mobSm: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        },
        dtSm: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      })}
      h={5}
    >
      <Text
        textStyle='bodyLFat'
        color='graystrong.500'
      >
        Propelio’s Estimated Value
      </Text>
      {/* @GENESIS */}
      {/* <Text
        textStyle='bodyM'
        color='ondark.6'
      >
      Last {dayjs(lastUpdated.toLocaleDateString()).diff(Date.now(), 'd')} Day(s)
    </Text> */}
      {/** Just a spacer until the date is implemented */}
      <Breakpoint tabSm={<Box h={textStyles.bodyM.lineHeight} />} />
    </Flex>
    <CMASalesEstimate
      flex='1 1 0'
      sx={{
        /**
                 * Increase the size of marker when in fullscreen
                 */
        '& svg.marker-svg-container': {
          width: getScale('md', 'pin').width,
          height: getScale('md', 'pin').height,
        },
      }}
    />
  </GridItem>

)

const MapGridItem = () => (
  <GridItem
    gridArea={GRID_AREA_ID.MAP}
    bgColor='neutral.500'
    borderWidth={1}
    borderRadius={3}
    borderColor='neutral.500'
    position='relative'
  >
    <WithScrollableCompsMapBehavior>
      <ReportMap />
    </WithScrollableCompsMapBehavior>
  </GridItem>
)

const EstimatesWithMapGrid = (props: GridProps) => {
  const gridLayoutProps: GridProps = mbpg({
    mobSm: {
      gridTemplate: `
        "${GRID_AREA_ID.USER_ESTIMATE}"
        "${GRID_AREA_ID.PROPELIO_ESTIMATE}"
        "${GRID_AREA_ID.MAP}"
      `,
      gridTemplateRows: 'auto auto 216px',
      rowGap: 2,
    },
    mob: {
      gridTemplateRows: 'auto auto 265px',
    },
    tabSm: {
      gridTemplate: `
          "${GRID_AREA_ID.USER_ESTIMATE} ${GRID_AREA_ID.USER_ESTIMATE} ${GRID_AREA_ID.PROPELIO_ESTIMATE}"
          "${GRID_AREA_ID.MAP} ${GRID_AREA_ID.MAP} ${GRID_AREA_ID.MAP}"
        `,
      gridTemplateRows: 'auto 320px',
      columnGap: 3,
      rowGap: 3,
    },
    dtSm: {
      gridTemplate: `
          "${GRID_AREA_ID.USER_ESTIMATE} ${GRID_AREA_ID.MAP}"
          "${GRID_AREA_ID.PROPELIO_ESTIMATE} ${GRID_AREA_ID.MAP}"
        `,
      gridTemplateColumns: '1fr 360px',
      gridTemplateRows: 'auto auto',
    },
    dtLg: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto auto',
    },
  })
  return <Grid {...gridLayoutProps} {...props} />
}

export const ReportMap = (props: MapProps) => {
  const reportType = useReportTypeSwitcherStore(store => store.reportType)
  const infoState = useListInfoState({ type: reportType })

  const borderRadius = useToken('radii', 3)

  const data = infoState.status === 'loaded'
    ? infoState.data
    : null

  const subjectMarker = data ? data.subject : null
  const compsMarkers = data ? data.comps.filter(v => v.userRating === 'included') : []

  const markers: MarkerType[] = [subjectMarker, ...compsMarkers]
    .filter(isNonNull)
    .map(v => ({
      location: v.location,
      id: v.id,
    }))
    .filter(Location.hasLocation)

  useCommonMarkerBehaviorsEffect({
    markers,
    highlightedEntry: null,
    padding: 50,
  })

  return (
    <Map
      style={{
        borderRadius,
        ...props.style,
      }}
      initialViewState={
        subjectMarker?.location
          ? {
            latitude: subjectMarker.location.latitude,
            longitude: subjectMarker.location.longitude,
            zoom: 8,
          }
          : {
            latitude: MapLib.DEFAULT_CENTER[1],
            longitude: MapLib.DEFAULT_CENTER[0],
            zoom: 1,
          }
      }
    >
      <FullscreenControl />
      {subjectMarker?.location && (
        <Marker
          {...subjectMarker.location}
          style={{ zIndex: MAP_ELEMENT__Z_INDICES.SUBJECT_MARKER }}
        >
          <SubjectPropertyMarker
            markerType='pin'
            size='sm'
          />
        </Marker>
      )}

      {compsMarkers.map(v => v.location
        ? (
          <CompsMapMarker
            key={v.id}
            {...v.location}
            isSubjectProperty={false}
            userRating={v.userRating}
            id={v.id}
            status={v.status}
            listPrice={v.listPrice}
            salePrice={v.salePrice}
            onHover={noop}
            onHoverOut={noop}
            onMarkerClick={noop}
          />
        )
        : null)}
    </Map>
  )
}
