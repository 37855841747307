import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { Card, CardBody, CardFooter, CardHeader } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { isNonNullable } from 'utils/isNonNullable'
import { TypeToConfirmModalProps } from './TypeToConfirmModal.types'

type Form = {
  value: string
}

export const TypeToConfirmModal: FC<TypeToConfirmModalProps> = ({
  isOpen = true,
  onClose,
  title,
  wordToType,
  appendToLabel,
  isLoading,
  isDisabled,
  header,
  body,
  footer,
  ...props
}) => {
  const { register, formState: { isValid } } = useForm<Form>()
  return (
    <ResponsiveFullscreenModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      {...props}
    >
      <Card
        variant='default'
        colorScheme='modal.alert.negative'
        size='modal.default.w'
      >
        <CardHeader>
          {header}
        </CardHeader>

        <CardBody shouldResetSpacing>
          {body}
          <FormControl mt={mbp({ mobSm: 3, mob: 4, tabSm: 5 })}>
            <FormLabel colorScheme='negative'>
              Type the word “
              {wordToType}
              ”
              {' '}
              {isNonNullable(appendToLabel) ? appendToLabel : <>to confirm</>}
            </FormLabel>
            <Input
              {...register('value', {
                required: true,
                validate: value => value.toLowerCase() === wordToType.toLowerCase(),
              })}
              isDisabled={isDisabled || isLoading}
            />
          </FormControl>

          <button type='submit' style={{ display: 'none' }} />
        </CardBody>
        <CardFooter>
          {footer({ isValid, onClose, isLoading, isDisabled })}
        </CardFooter>
      </Card>
    </ResponsiveFullscreenModal>
  )
}
