import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

export const formErrorMessageTheme = {
  baseStyle: {
    text: {
      mx: '0',
      mb: '0',
      mt: '-1',
      pt: '1.5',
      px: '1',
      pb: '0.5',
      ...textStyles.body3,
      color: 'error.500',
      bg: 'error.100',
      borderBottomRadius: 1,
    },
  },
}
