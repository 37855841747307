import { Box, BoxProps, Grid, GridItem, HStack, Text, VStack, useToken } from '@chakra-ui/react'
import { pipe } from 'effect'
import { PropertyListData } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Dollar } from 'libs/dollar'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { Card } from 'presentation/components/molecules/Card'
import { getCardBorderColor } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard'
import { PropertiesListItemChip } from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/components/PropertiesListItemChip'
import { PropertyPreviewCard } from 'presentation/screens/ListBuilderScreen/components/PropertyPreviewCard/PropertyPreviewCard'
import { useRef } from 'react'
import { useHoverDirty } from 'react-use'

const IS_TNT = true

export type PropertiesListItemProps = {
  item: PropertyListData
  shouldShowBorder?: boolean
} & BoxProps

const PropertiesListItem = (props: PropertiesListItemProps) => {
  const { item, ...componentProps } = props
  const colorScheme = COLOR_SCHEME[item.mlsStatus]

  const cardBorderColor = useToken('colors', getCardBorderColor(item.mlsStatus))
  /**
   * @NOTE
   * We are simulating a border using shadow to prevent a change of size.
   * We also need to show a shadow that is why we have two layers of shadow:
   * one simulating the border and the other is the actual shadow.
   */
  const cardRef = useRef(null)
  const isHovered = useHoverDirty(cardRef)
  const [hoveredShadow, defaultShadow] = useToken('shadows', ['button-hovered', 'primary.w'])
  const outerBorder = `0 0 0 4px ${cardBorderColor}`
  const boxShadow = isHovered || props.shouldShowBorder
    ? [outerBorder, hoveredShadow].join(',')
    : defaultShadow

  return (
    <Card
      ref={cardRef}
      p={2}
      borderRadius={3}
      minW={0}
      minH={0}
      flex='0 0 auto'
      role='button'
      boxShadow={boxShadow}
      {...componentProps}
    >
      <HStack spacing={0} w='full' minW={0} minH={0}>
        <Box flex='0 0 auto'>
          <PropertyMarker
            markerType='pin'
            classification={item.classification}
            equityType={item.equityType}
            isVacant={!!item.isVacant}
            isForeclosure={!!item.isForeclosure}
            isSenior={!!item.isSenior}
            size='sm'
          />
        </Box>

        <Box
          pl={1}
          flex='1 1 auto'
          minW={0}
          minH={0}
        >
          <Text
            textStyle='bodyMFat'
            color='graystrong.400'
            noOfLines={1}
            title={item.address.line1 || ''}
            minW={0}
            minH={0}
          >
            {item.address.line1}
          </Text>
          <Text
            textStyle='tagL'
            color='grayweak.900'
            noOfLines={1}
            title={item.address.subdivision || ''}
            minW={0}
            minH={0}
          >
            {Address.formatCityStateZipSub(item.address)}
          </Text>
        </Box>

        {/* Two Columns */}
        <Grid flex='0 0 auto' pl={2} gridTemplateColumns='repeat(2, 124px)' columnGap={1} rowGap={0}>
          <GridItem>
            <HStack spacing={0.5}>
              <Text textStyle='tagL' color='graystrong.200'>EST&nbsp;VALUE</Text>
              <Text textStyle='bodyMFat' color='accent.blue-text'>
                {Dollar.formatNumberWithKOrDoubleDash(item.estimatedValue)}
              </Text>
            </HStack>
          </GridItem>
          <GridItem>
            <HStack spacing={0.5}>
              <Text textStyle='tagL' color='graystrong.200'>EST&nbsp;EQUITY</Text>
              <Text textStyle='bodyMFat' color='accent.blue-text'>
                {Dollar.formatNumberWithKOrDoubleDash(item.estimatedEquity)}
              </Text>
            </HStack>
          </GridItem>
          <GridItem gridColumn='2/3'>
            <HStack spacing={0.5}>
              <Text textStyle='tagL' color='graystrong.200'>LAST&nbsp;SOLD</Text>
              <Text textStyle='bodyMFat' color='accent.blue-text'>
                {DateLib.formatMMDDYYDotsOrDoubleDash(item.lastSoldDate)}
              </Text>
            </HStack>
          </GridItem>
        </Grid>
      </HStack>

      <HStack spacing={1.5} minW={0} minH={0} mt={2} align='flex-start'>
        <PropertyPreviewCard
          address={item.address}
          location={item.location}
          flexBasis='218px'
          flexShrink={0}
          flexGrow={0}
        />

        <VStack flex='1' align='stretch' spacing={1.5}>
          <HStack
            p={0.5}
            borderRadius={1}
            bg={colorScheme.features.bg}
            borderColor={colorScheme.features.borderColor}
            borderWidth={0.125}
            justify='space-around'
          >
            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Beds</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {NumberLib.orDoubleDash(item.bedroomsCount)}
              </Text>
            </VStack>

            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Bath</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {pipe(
                  item.bathroomsCountInfo,
                  BathroomsCountInfo.getTotal,
                  StringLib.orDoubleDash,
                )}
              </Text>
            </VStack>

            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Garage</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {NumberLib.orDoubleDash(item.garageSpacesCount)}
              </Text>
            </VStack>

            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Sqft</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {NumberLib.formatCommasOrDoubleDash(item.livingAreaSqft)}
              </Text>
            </VStack>

            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Built</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {NumberLib.orDoubleDash(item.yearBuilt)}
              </Text>
            </VStack>

            <VStack spacing={0.5}>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>Acre</Text>
              <Text textStyle='bodyMFat' color={colorScheme.features.color}>
                {NumberLib.formatCommasDecimalsOrDoubleDash(item.lotAreaAcres)}
              </Text>
            </VStack>
          </HStack>

          <MLSStatusTag status={item.mlsStatus} h={3} w='full' />

          {item.mlsStatus !== 'OFF_MARKET' && (
            <Box p={0.5} borderRadius={0.5} bg={colorScheme.price.bg}>
              <Text textStyle='tagL' color={colorScheme.price.color} textAlign='center'>
                $XXX
              </Text>
            </Box>
          )}
        </VStack>
      </HStack>

      {!IS_TNT && (
        <Box mt={1.5} mb={-0.5} mx={0.5} lineHeight='4'>
          {item.isVacant && (
            <ChipContainer><PropertiesListItemChip type='VACANT' /></ChipContainer>
          )}
          {item.isForeclosure && (
            <ChipContainer><PropertiesListItemChip type='PREFORECLOSURE' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='SUB_TRUSTEES' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='HOA_LIENS' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='AFFIDAVIT_OF_HEIRSHIP' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='TAX_DELINQUENT' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='PROBATE' /></ChipContainer>
          )}
          {!IS_TNT && (
            <ChipContainer><PropertiesListItemChip type='FAILED_LISTING' /></ChipContainer>
          )}
        </Box>
      )}
    </Card>
  )
}

export default PropertiesListItem

const ACTIVE_COLOR_SCHEME = {
  price: {
    bg: 'marker.mintgreen',
    color: 'marker.darkgreen',
  },
  features: {
    bg: 'marker.mintgreen',
    borderColor: 'marker.mintgreen',
    color: 'marker.darkgreen',
  },
} as const

const INACTIVE_COLOR_SCHEME = {
  price: {
    bg: 'marker.gray',
    color: 'marker.dirtywhite',
  },
  features: {
    bg: 'marker.gray',
    borderColor: 'marker.gray',
    color: 'marker.dirtywhite',
  },
} as const

const SOLD_LEASED_COLOR_SCHEME = {
  price: {
    bg: 'marker.pink',
    color: 'marker.maroon',
  },
  features: {
    bg: 'marker.pink',
    borderColor: 'marker.pink',
    color: 'marker.maroon',
  },
}

const COLOR_SCHEME = {
  FOR_SALE: ACTIVE_COLOR_SCHEME,
  FOR_LEASE: ACTIVE_COLOR_SCHEME,
  SALE_OR_LEASE: ACTIVE_COLOR_SCHEME,
  PENDING: {
    price: {
      bg: 'marker.babyblue',
      color: 'marker.oxfordblue',
    },
    features: {
      bg: 'marker.babyblue',
      borderColor: 'marker.babyblue',
      color: 'marker.oxfordblue',
    },
  },
  SOLD: SOLD_LEASED_COLOR_SCHEME,
  LEASED: SOLD_LEASED_COLOR_SCHEME,
  EXPIRED: INACTIVE_COLOR_SCHEME,
  CANCELED: INACTIVE_COLOR_SCHEME,
  WITHDRAWN: INACTIVE_COLOR_SCHEME,
  OFF_MARKET: {
    price: {
      bg: undefined,
      color: undefined,
    },
    features: {
      bg: 'transparent',
      borderColor: 'marker.darkgray',
      color: 'marker.darkgray',
    },
  },
} as const

const ChipContainer = (props: BoxProps) => (
  <Box display='inline-block' mx={0.5} {...props} />
)
