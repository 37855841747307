import { Match, pipe } from 'effect'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { IncorrectCriteriaError, UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { useStore } from 'features/ListBuilder/infra/react/ListBuilderStore/useStore'
import { PartialRange } from 'features/valueObjects/Range'
import { DeepPartial } from 'libs/utils.types'

export const useBaseHook = <Selection>(params: {
  value: (criteria: PropertyListCriteria) => Selection
  apply: (source: Selection) => DeepPartial<PropertyListCriteria>
}) =>
  useStore(store => pipe(
    Match.value(store.criteria),
    Match.tag('PropertyListCriteria', (oldCriteria): UseCriteriaHookApi<Selection> => ({
      value: params.value(oldCriteria),
      apply: value => {
        const newCriteria = PropertyListCriteria.with(oldCriteria, params.apply(value))
        store.buildList.execute(newCriteria)
      },
    })),
    Match.orElse(() => { throw new IncorrectCriteriaError() }),
  ))

export const useHomeType = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.homeType,
    apply: source => ({
      propertyDetails: { homeType: source },
    }),
  })

export const useBathroomsCount = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.bathroomsCount,
    apply: source => ({
      propertyDetails: { bathroomsCount: source },
    }),
  })

export const useBedroomsCount = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.bedroomsCount,
    apply: source => ({
      propertyDetails: { bedroomsCount: source },
    }),
  })

export const useLivingAreaSqft = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.livingAreaSqft,
    apply: source => ({
      propertyDetails: { livingAreaSqft: source },
    }),
  })

export const useLotAreaSize = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.lotAreaSize,
    apply: source => ({
      propertyDetails: { lotAreaSize: source },
    }),
  })

export const useYearBuilt = (): UseCriteriaHookApi<PartialRange> =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.yearBuilt,
    apply: source => ({
      propertyDetails: { yearBuilt: source },
    }),
  })

export const useGarageSpacesCount = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.propertyDetails.garageSpacesCount,
    apply: source => ({
      propertyDetails: { garageSpacesCount: source },
    }),
  })

export const useYearsOfOwnership = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.yearsOfOwnership,
    apply: source => ({
      yearsOfOwnership: source,
    }),
  })

export const useIsPersonalResidenceFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isPersonalResidence,
    apply: source => ({
      ownershipFlags: {
        isPersonalResidence: source,
      },
    }),
  })

export const useIsInStateFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isInState,
    apply: source => ({
      ownershipFlags: {
        isInState: source,
      },
    }),
  })

export const useIsOutOfStateFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isOutOfState,
    apply: source => ({
      ownershipFlags: {
        isOutOfState: source,
      },
    }),
  })

export const useIsOutOfCountryFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isOutOfCountry,
    apply: source => ({
      ownershipFlags: {
        isOutOfCountry: source,
      },
    }),
  })

export const useIsMilitaryFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isMilitary,
    apply: source => ({
      ownershipFlags: {
        isMilitary: source,
      },
    }),
  })

export const useIsSeniorFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isSenior,
    apply: source => ({
      ownershipFlags: {
        isSenior: source,
      },
    }),
  })

export const useIsCorporateOwnedFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isCorporateOwned,
    apply: source => ({
      ownershipFlags: {
        isCorporateOwned: source,
      },
    }),
  })

export const useIsTrustFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isTrust,
    apply: source => ({
      ownershipFlags: {
        isTrust: source,
      },
    }),
  })

export const useIsBankFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isBank,
    apply: source => ({
      ownershipFlags: {
        isBank: source,
      },
    }),
  })

export const useIsUnknownOwnershipFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.ownershipFlags.isUnknown,
    apply: source => ({
      ownershipFlags: {
        isUnknown: source,
      },
    }),
  })

export const useIsPreforeclosureFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isPreforeclosure,
    apply: source => ({
      stressIndicatorFlags: {
        isPreforeclosure: source,
      },
    }),
  })

export const useIsSubstituteTrusteesFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isSubstituteTrustees,
    apply: source => ({
      stressIndicatorFlags: {
        isSubstituteTrustees: source,
      },
    }),
  })

export const useIsProbatesFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isProbates,
    apply: source => ({
      stressIndicatorFlags: {
        isProbates: source,
      },
    }),
  })

export const useIsAffidavitsOfHeirshipsFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isAffidavitsOfHeirships,
    apply: source => ({
      stressIndicatorFlags: {
        isAffidavitsOfHeirships: source,
      },
    }),
  })

export const useIsHOALiensFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isHOALiens,
    apply: source => ({
      stressIndicatorFlags: {
        isHOALiens: source,
      },
    }),
  })

export const useIsTaxDelinquent = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isTaxDelinquent,
    apply: source => ({
      stressIndicatorFlags: {
        isTaxDelinquent: source,
      },
    }),
  })

export const useIsVacantFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isVacant,
    apply: source => ({
      stressIndicatorFlags: {
        isVacant: source,
      },
    }),
  })

export const useIsFailedListingFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.stressIndicatorFlags.isFailedListing,
    apply: source => ({
      stressIndicatorFlags: {
        isFailedListing: source,
      },
    }),
  })

export const usePropertyValue = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.propertyValue,
    apply: source => ({
      equityValue: {
        propertyValue: source,
      },
    }),
  })

export const useTaxValue = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.taxValue,
    apply: source => ({
      equityValue: {
        taxValue: source,
      },
    }),
  })

export const useHighEquityValue = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.highEquityValue,
    apply: source => ({
      equityValue: {
        highEquityValue: source,
      },
    }),
  })

export const useLowEquityValue = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.lowEquityValue,
    apply: source => ({
      equityValue: {
        lowEquityValue: source,
      },
    }),
  })

export const useIsFreeAndClearFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.equityValueFlags.isFreeAndClear,
    apply: source => ({
      equityValue: {
        equityValueFlags: {
          isFreeAndClear: source,
        },
      },
    }),
  })

export const useUpsideDownValue = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.upsideDownValue,
    apply: source => ({
      equityValue: {
        upsideDownValue: source,
      },
    }),
  })

export const useIsUnknownEquityFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.equityValue.equityValueFlags.isUnknown,
    apply: source => ({
      equityValue: {
        equityValueFlags: {
          isUnknown: source,
        },
      },
    }),
  })

export const useIsExcludeActiveListingsFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.mlsFlags.isExcludeActiveListings,
    apply: source => ({
      mlsFlags: {
        isExcludeActiveListings: source,
      },
    }),
  })

export const useIsDiscountedListingsFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.mlsFlags.isDiscountedListings,
    apply: source => ({
      mlsFlags: {
        isDiscountedListings: source,
      },
    }),
  })

export const useIsHandymanSpecialsFlag = () =>
  useBaseHook({
    value: oldCriteria => oldCriteria.mlsFlags.isHandymanSpecials,
    apply: source => ({
      mlsFlags: {
        isHandymanSpecials: source,
      },
    }),
  })
