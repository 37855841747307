import { gql } from '__generated__'

export const GET_SKIPTRACE_FUNDS_INFO_GQL = gql(/** GraphQL */`
  query GetSkiptraceFundsInfo {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          plan {
            name
            product {
              features {
                ... on SubscriptionFeatureSkiptrace {
                  __typename
                  prices {
                    price
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)
