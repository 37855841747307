import { Flex, IconButton } from '@chakra-ui/react'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { PeekingDrawer, PeekingDrawerContent } from 'presentation/components/PeekingDrawer/PeekingDrawer'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { useSearchHistoryListPanelStore } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { DrawerPanelMetaBar } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistoryDrawerPanel/components/DrawerPanelMetaBar/DrawerPanelMetaBar'
import { DrawerPanelMetaButton } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistoryDrawerPanel/components/DrawerPanelMetaButton'
import { SearchHistoryList } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryList'
import { SearchHistoryPagination } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryPagination'
import { SearchHistoryToolbar } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryToolbar'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const SearchHistoryDrawerPanel = () => {
  const { isPanelOpen, closePanel, setPanelOpen } = useSearchHistoryListPanelStore()

  const handleClosePanel = () => {
    closePanel()
  }

  return (
    <PeekingDrawer
      placement='bottom'
      isOpen={isPanelOpen}
      h='100%'
      onChange={setPanelOpen}
    >
      {!isPanelOpen
        ? <DrawerPanelMetaButton />
        : (
          <Flex
            bgColor='neutral.500'
            gap={1}
            py={1}
            px={1}
            pointerEvents='auto'
          >
            <IconButton
              aria-label='Back'
              icon={<ArrowLeftIcon />}
              variant='icon-ghost'
              colorScheme='ondark'
              size='md'
              alignSelf='center'
              onClick={handleClosePanel}
            />

            <SearchHistoryToolbar colorScheme='drawer' />
          </Flex>
        )}

      {isPanelOpen && <DrawerPanelMetaBar />}

      <PeekingDrawerContent>
        <ScrollWithFade
          containerProps={{
            h: 'full',
          }}
          px={mbp({ mobSm: 1, mob: 2 })}
          pb={3}
          pt={2}
          fadeColor='rgb(0, 0, 0, 0.15)'
          fadeTopSize={-0.5}
          fadeBottomSize={3}
          bgColor='accent.hover'
        >
          <SearchHistoryList />
        </ScrollWithFade>
      </PeekingDrawerContent>

      {isPanelOpen && <SearchHistoryPagination pointerEvents='auto' bgColor='accent.hover' />}
    </PeekingDrawer>
  )
}
