import { Match } from 'effect'
import { pipe } from 'effect/Function'
import { MarkerSize, MarkerType } from 'presentation/components/PropertyMarker/PropertyMarker.type'

type Scale = {
  width: string
  height: string
  viewBox: string
}

export const getScale = (size: MarkerSize, markerType: MarkerType): Scale => {
  const pinSize = pipe(
    Match.value(size),
    Match.when('lg', () => ({ width: '50px', height: '66px' })),
    Match.when('md', () => ({ width: '38px', height: '50px' })),
    Match.when('sm', () => ({ width: '30px', height: '40px' })),
    Match.when('xs', () => ({ width: '24px', height: '32px' })),
    Match.when('xxs', () => ({ width: '20px', height: '26px' })),
    Match.when('tiny', () => ({ width: '16px', height: '20px' })),
    Match.exhaustive,
  )

  const tagSize = pipe(
    Match.value(size),
    Match.when('lg', () => ({ width: '50px', height: '50px' })),
    Match.when('md', () => ({ width: '38px', height: '38px' })),
    Match.when('sm', () => ({ width: '30px', height: '30px' })),
    Match.when('xs', () => ({ width: '24px', height: '24px' })),
    Match.when('xxs', () => ({ width: '20px', height: '20px' })),
    Match.when('tiny', () => ({ width: '16px', height: '16px' })),
    Match.exhaustive,
  )

  const markerSize = markerType === 'pin'
    ? pinSize
    : tagSize

  /**
   * ==================
   * These are original sizes of the svg's and it is scaled down by the markerSize
   * ==================
   */
  const viewBox = markerType === 'pin' ? '0 0 50 66' : '0 0 50 50'

  return {
    ...markerSize,
    viewBox,
  } satisfies Scale
}
