import { BoxProps, Image, Text, VStack } from '@chakra-ui/react'
import image from 'presentation/assets/img/3d/2-peeps-1-magnifying-glass.png'
import { Card } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const CMAEntriesNoMatch = (props: BoxProps) => (
  <Card size='sm-locked' h='100%' {...props}>
    <VStack
      spacing={1}
      h='100%'
      justify='center'
    >
      <Image
        src={image}
        {...mbpg({
          mobSm: {
            w: '120px',
            h: '120px',
          },
          tabSm: {
            w: '180px',
            h: '180px',
          },
        })}
      />
      <Text
        textStyle={mbp({ mobSm: 'bodyXLFat', tabSm: 'h2' })}
        textAlign='center'
        color='graystrong.500'
      >
        Oops! No Properties Matches your Filters
      </Text>
      <Text
        textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyXLFat' })}
        textAlign='center'
        color='graystrong.200'
      >
        It seems like there are no matching results for your comparable properties.
        Please adjust your filters and try again.
      </Text>
    </VStack>
  </Card>
)
