import { Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react'
import { Option } from 'effect'
import Buyer from 'features/NearbyBuyers/domain/Buyer'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import NearbyBuyerStaticImage from 'features/NearbyBuyers/views/NearbyBuyersMaps/NearbyBuyersStaticImage'
import { useNearbyBuyersMarkerPopupWidth } from 'features/NearbyBuyers/views/NearbyBuyersMaps/useNearbyBuyersMarkerPopupWidth'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { getMarkerPopupColors } from 'presentation/screens/CompsScreen/components/CompsMap/components/utils/getMarkerPopupColors'
import { px } from 'presentation/utils/px'

const NearbyBuyerPropertyMarkerPopup = ({
  property,
}: {
  property: Buyer.Property
}) => {
  const subjectMlsStatus = NearbyBuyersHooks.useSubjectMlsStatus()
  const colors = getMarkerPopupColors(subjectMlsStatus)
  const popupWidth = useNearbyBuyersMarkerPopupWidth()
  return (
    <VStack
      borderWidth='0.125'
      borderColor={colors.cardBorderColor}
      bg={colors.cardBgColor}
      p={2}
      borderRadius={3}
      align='stretch'
      spacing={0}
      w={px(popupWidth)}
    >
      <Grid
        p={1}
        borderTopRadius={2}
        bg={colors.headerBgColor}
        gridTemplate={`
          "marker address" min-content
          "marker info" 1fr / min-content 1fr
        `}
      >
        <GridItem gridArea='marker'>
          <PropertyMarker
            classification={property.ownershipInfo.classification.pipe(Option.getOrNull)}
            equityType={property.valueInfo.equityType.pipe(Option.getOrNull)}
            isForeclosure={property.valueInfo.isForeclosure}
            isSenior={property.ownershipInfo.isSenior}
            isVacant={property.ownershipInfo.isVacant}
            markerType='pin'
            size='xs'
          />
        </GridItem>

        <GridItem gridArea='address' pl={1}>
          <HStack align='center'>
            <Text
              color={colors.textColor}
              textStyle='bodyMFat'
            >
              {property.address.line1}
            </Text>
          </HStack>
        </GridItem>

        <GridItem
          gridArea='info'
          pl={1}
          textStyle='bodyM'
          color={colors.textColor}
        >
          {[
            property.physicalInfo.bedroomsCount.pipe(
              Option.map(count => count > 1 ? `${count} bds` : `${count} bd`),
              Option.getOrElse(() => '-- bds'),
            ),

            property.physicalInfo.bathroomsCountInfo.pipe(
              Option.map(countInfo => countInfo.total === '1.0'
                ? `${countInfo.total} ba`
                : `${countInfo.total} bas`,
              ),
              Option.getOrElse(() => '-- bas'),
            ),

            property.physicalInfo.buildingAreaSqft.pipe(
              Option.map(area => `${area} sqft`),
              Option.getOrElse(() => '-- sqft'),
            ),
          ].join(' / ')}
        </GridItem>
      </Grid>

      <Grid
        gridTemplateAreas='"layer"'
      >
        <NearbyBuyerStaticImage
          gridArea='layer'
          address={property.address}
          location={property.location}
          imageWidth={302}
          imageHeight={128}
          borderBottomRadius={2}
        />

        <GridItem
          gridArea='layer'
          alignSelf='end'
          justifySelf='end'
          pr={1}
          pb={1}
        >
          <MLSStatusTag
            status={subjectMlsStatus}
          />
        </GridItem>
      </Grid>
    </VStack>
  )
}

export default NearbyBuyerPropertyMarkerPopup
