import { PointLike } from 'mapbox-gl'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { CompsMapMarkerPopupProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarkerPopup/CompsMapMarkerPopup.props'
import { CompsMarkerPopup } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarkerPopup'
import { Ref, forwardRef } from 'react'
import { Popup } from 'react-map-gl'

export const COMPS_MAP_POPUP_WIDTH = '336px'

const CompsMapMarkerPopupBase = (
  props: CompsMapMarkerPopupProps,
  ref?: Ref<mapboxgl.Popup>,
) => {
  const {
    longitude,
    latitude,
    ...markerPopupProps
  } = props

  const hasSubjectMarker = props.entryType === 'subject'

  /**
   * This offset is relative to the marker
   */
  const offset: Record<string, PointLike> = hasSubjectMarker
    ? {
      'bottom': [0, -85],
      'right': [-35, -65],
      'left': [35, -65],
      'bottom-right': [0, -80],
      'bottom-left': [0, -80],
      'top-left': [20, -40],
      'top-right': [-30, -40],
    }
    : {
      'bottom': [0, -45],
      'right': [-35, -20],
      'left': [35, -20],
      'bottom-right': [0, -60],
      'bottom-left': [0, -40],
      'top-left': [0, 0],
      'top-right': [-30, 0],
    }
  return (
    <Popup
      offset={offset}
      closeOnClick={false}
      /**
       * @NOTE: focusAfterOpen=false will prevent any scrollable
       * ancestor to scroll to make popup visible in the viewport.
       * This prevents the expansion of table rows to scroll to top
       * to show the map when view is table view.
       */
      focusAfterOpen={false}
      longitude={longitude}
      latitude={latitude}
      style={{ zIndex: MAP_ELEMENT__Z_INDICES.POPUP }}
      maxWidth={COMPS_MAP_POPUP_WIDTH}
      ref={ref}
    >
      <CompsMarkerPopup {...markerPopupProps} />
    </Popup>
  )
}

export const CompsMapMarkerPopup = forwardRef(CompsMapMarkerPopupBase)
