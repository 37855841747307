import { Option } from 'effect'
import { isNullable, isNumber } from 'effect/Predicate'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import pluralize from 'pluralize'
import { shallow } from 'zustand/shallow'

export const useOwnershipViewModel = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  return usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') {
      return {
        address: null,
        owners: [],
        status: null,
        years: null,
        isVacant: false,
        equityType: null,
        isSenior: false,
        isForeclosure: false,
        potentialPropertiesCount: Option.none<number>(),
      }
    }

    const address = property.ownership?.address || null
    const owners = property.ownership?.owners || []
    const status = property.ownership?.classification || null
    const years = property.ownership && isNumber(property.ownership?.months)
      ? Math.floor(property.ownership.months / 12)
      : null
    const isVacant = property.ownership?.isVacant || false
    const equityType = property.valuation?.equityType || null
    const isSenior = property.ownership?.isSenior || false
    const isForeclosure = property.ownership?.isForeclosure || false
    const potentialPropertiesCount = property.ownership?.potentialPropertiesCount ?? Option.none()

    const formattedAddressOneLine = address
      ? `${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`
      : '--'
    const formattedAddressTwoLines = address
      ? [
        `${address.line1}, ${address.city}`,
        `${address.state} ${address.postalCode}`,
      ] as const
      : null

    const formattedYear = years === null
      ? '--'
      : years > 0
        ? `${years} ${pluralize('Year', years)}`
        : 'Less than a year'

    const formattedStatus = isNullable(status)
      ? null
      : status === 'OWNER_OCCUPIED' && isVacant
        ? 'Vacant'
        : OwnerClassification.toString(status)

    return {
      formattedAddressOneLine,
      formattedAddressTwoLines,
      formattedYear,
      formattedStatus,
      owners,
      status,
      equityType,
      isVacant,
      isSenior,
      isForeclosure,
      potentialPropertiesCount,
    }
  }, shallow)
}
