import { gql } from '__generated__'
import { ProductFeaturesFragment, SubscriptionFeatureIntervals } from '__generated__/graphql'
import { Match, pipe } from 'effect'
import ProductFeatureInterval from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureInterval'

const PRODUCT_FEATURES_FRAGMENT = gql(/* GraphQL */`
   fragment ProductFeatures on BillingSubscriptionProductFeatures {
              
      ... on SubscriptionFeatureLeads {
        limits {
          interval
          value
        }
        usages {
          interval
          value
          nextRolloverDate
        }
      }

      ... on SubscriptionFeatureSkiptrace {
        limits {
          interval
          value
        }
        usages {
          interval
          value
          nextRolloverDate
        }
        prices {
          price
          pricingUnit
          pricingUnitDescription
          included {
            limit
            used
            nextRolloverDate
          }
        }
      }

      ... on SubscriptionFeatureComps {
        limits {
          interval
          value
        }
        usages {
          interval
          value
          nextRolloverDate
        }
        prices {
          price
          pricingUnit
          pricingUnitDescription
          included {
            limit
            used
            nextRolloverDate
          }
        }
      }

      ... on SubscriptionFeatureDirectMail {
        limits {
          interval
          value
        }
        usages {
          interval
          value
          nextRolloverDate
        }
        prices {
          price
          pricingUnit
          pricingUnitDescription
          included {
            limit
            used
            nextRolloverDate
          }
        }
      }

      ... on SubscriptionFeatureTeam {
        limits {
          value
          interval
        }
      }

    ... on SubscriptionFeaturePropertyLookup {
      limits {
        interval
        value
      }
      usages {
        interval
        value
        nextRolloverDate
      }
      productId
    }
   
   }   
`)

const isSubscriptionFeatureLeads = (
  fragment: ProductFeaturesFragment,
): fragment is Extract<ProductFeaturesFragment, { __typename?: 'SubscriptionFeatureLeads' }> =>
  fragment.__typename === 'SubscriptionFeatureLeads'

const isSubscriptionFeatureSkiptrace = (
  fragment: ProductFeaturesFragment,
): fragment is Extract<ProductFeaturesFragment, { __typename?: 'SubscriptionFeatureSkiptrace' }> =>
  fragment.__typename === 'SubscriptionFeatureSkiptrace'

const isSubscriptionFeatureComps = (
  fragment: ProductFeaturesFragment,
): fragment is Extract<ProductFeaturesFragment, { __typename?: 'SubscriptionFeatureComps' }> =>
  fragment.__typename === 'SubscriptionFeatureComps'

const isSubscriptionFeatureDirectMail = (
  fragment: ProductFeaturesFragment,
): fragment is Extract<ProductFeaturesFragment, { __typename?: 'SubscriptionFeatureDirectMail' }> =>
  fragment.__typename === 'SubscriptionFeatureDirectMail'

const isSubscriptionFeatureTeam = (
  fragment: ProductFeaturesFragment,
): fragment is Extract<ProductFeaturesFragment, { __typename?: 'SubscriptionFeatureTeam' }> =>
  fragment.__typename === 'SubscriptionFeatureTeam'

const toProductFeatureInterval = (interval: SubscriptionFeatureIntervals): ProductFeatureInterval => pipe(
  Match.value(interval),
  Match.withReturnType<ProductFeatureInterval>(),
  Match.when(SubscriptionFeatureIntervals.Monthly, () => 'monthly'),
  Match.when(SubscriptionFeatureIntervals.Weekly, () => 'weekly'),
  Match.when(SubscriptionFeatureIntervals.Daily, () => 'daily'),
  Match.when(SubscriptionFeatureIntervals.Each, () => 'each'),
  Match.exhaustive,
)

const GQLProductFeatures = {
  schema: PRODUCT_FEATURES_FRAGMENT,
  isSubscriptionFeatureLeads,
  isSubscriptionFeatureSkiptrace,
  isSubscriptionFeatureComps,
  isSubscriptionFeatureDirectMail,
  isSubscriptionFeatureTeam,
  toProductFeatureInterval,
}

export default GQLProductFeatures
