import { TableColumnHeaderProps, TableHeadProps, Th, Thead, Tr } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { toggleSortMenu, useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { CMAEntriesTableWidthKey, CMAEntriesTableWidthSync } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTableWidthSync'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { useThrottle } from 'react-use'

export const CompTableHeaderRow = (props: TableHeadProps) => {
  const isSortOpen = useEntriesToolbarMenu(state => state.isSortOpen)

  const throttledIsSortOpen = useThrottle(isSortOpen, 100)

  return (
    <Thead
      overflow='initial'
      {...props}
    >
      <Tr
        id='comps-screen_table-header'
        onClick={() => {
          /**
           * @WORKAROUND - When we sort menu is open, and we clicked here again,
           * we want it to close the sort menu. However, the sort menu's onBlur
           * handling is faster than this click event, so it will close the sort
           * first. By the time this click event is handled, the sort menu is
           * already closed and there were issues surrounding that.
           *
           * The workaround is to add throttle so that this handle wouldn't be
           * be informed when the sort menu was closed the same moment as click
           */
          if (throttledIsSortOpen) return
          toggleSortMenu()
        }}
        cursor='pointer'
        sx={{
          '& th': {
            borderBottomWidth: 0,
          },
        }}
      >
        <SyncedTh
          columnKey='address'
          {...mbpg({
            dtLg: {
              w: '240px',
            },
          })}
          textAlign='left'
        >
          Address
        </SyncedTh>

        <SyncedTh
          columnKey='prop-type'
          textAlign='left'
          {...mbpg({
            mobSm: {
              w: '72px',
            },
            dtLg: {
              w: 'auto',
            },
          })}
        >
          <Breakpoint
            dtLg={(
              <>
                Property
                <br />
                Type
              </>
            )}
            mobSm={(
              <>
                Prop
                <br />
                Type
              </>
            )}
          />
        </SyncedTh>

        <SyncedTh w='96px' columnKey='prop-status'>
          Property
          <br />
          Status
        </SyncedTh>

        <SyncedTh
          columnKey='beds-baths-garage'
          textAlign='right'
          w={mbp({
            mobSm: '72px',
            dtLg: 'auto',
          })}
        >
          <Breakpoint
            mobSm={(
              <>
                Bd / Ba
                <br />
                {' '}
                / Garage
              </>
            )}
            dtLg={(
              <>
                Beds / Baths
                <br />
                {' '}
                / Garage
              </>
            )}
          />
        </SyncedTh>

        <SyncedTh
          columnKey='year-built'
          textAlign='right'
          w={mbp({
            mobSm: '52px',
            dtLg: 'auto',
          })}
          overflowWrap='break-word'
          wordBreak='break-word'
          whiteSpace='normal'
        >
          <Breakpoint
            mobSm={(
              <>
                Year
                <br />
                Built
              </>
            )}
            dtLg='Year Built'
          />
        </SyncedTh>

        <SyncedTh
          columnKey='sqft'
          textAlign='right'
          w={mbp({
            mobSm: '56px',
            dtSm: '64px',
            dtLg: 'auto',
          })}
          {...mbpg({
            mobSm: {
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
            },
            dtLg: {
              whitespace: 'nowrap',
              overflowWrap: 'break-word',
              wordBreak: 'normal',
            },
          })}
        >
          <Breakpoint
            mobSm='Sqft/ Acre'
            dtLg='Sqft'
          />
        </SyncedTh>

        <Breakpoint
          dtLg={(
            <SyncedTh
              columnKey='acre'
              textAlign='right'
              w={mbp({
                mobSm: '56px',
                dtSm: '64px',
                dtLg: 'auto',
              })}
              overflowWrap='break-word'
              wordBreak='break-word'
            >
              Acre
            </SyncedTh>
          )}
        />

        <SyncedTh
          columnKey='list-price'
          textAlign='right'
          w={mbp({
            mobSm: '96px',
            dtLg: 'auto',
          })}
        >
          <Breakpoint
            mobSm={(
              <>
                List Price
                <br />
                /LP Sqft
              </>
            )}
            dtLg={<>List Price</>}
          />
        </SyncedTh>

        <Breakpoint
          dtLg={(
            <SyncedTh textAlign='right' columnKey='list-price-sqft'>
              LP Sqft
            </SyncedTh>
          )}
        />

        <SyncedTh
          columnKey='sale-price'
          textAlign='right'
          w={mbp({
            mobSm: '96px',
            dtLg: 'auto',
          })}
        >
          <Breakpoint
            mobSm={(
              <>
                Sale Price
                <br />
                /LP Sqft
              </>
            )}
            dtLg={<>Sale Price</>}
          />
        </SyncedTh>

        <Breakpoint
          dtLg={(
            <SyncedTh textAlign='right' columnKey='sale-price-sqft'>
              SP Sqft
            </SyncedTh>
          )}
        />

        <SyncedTh
          columnKey='distance'
          textAlign='right'
          w={mbp({
            mobSm: '64px',
            dtLg: '72px',
          })}
        >
          <Breakpoint
            mobSm='Dist'
            dtLg='Distance'
          />
        </SyncedTh>

        <SyncedTh
          columnKey='dom'
          textAlign='right'
          w={mbp({
            mobSm: '52px',
            dtLg: '54px',
          })}
        >
          DOM
        </SyncedTh>

        <SyncedTh
          columnKey='rate'
          w={mbp({
            mobSm: '80px',
            dtLg: '108px',
          })}
        >
          <Breakpoint
            mobSm={(
              <>
                Comps
                <br />
                Rate
              </>
            )}
            dtLg='Comps Rate'
          />
        </SyncedTh>
      </Tr>
    </Thead>
  )
}

const SyncedTh = ({
  columnKey, shouldBaseWidthOffSync,
  ...props
}: TableColumnHeaderProps & {
  columnKey: CMAEntriesTableWidthKey
  shouldBaseWidthOffSync?: boolean
}) => {
  const ref = CMAEntriesTableWidthSync.useWidthTracker(columnKey, {
    isDisabled: !shouldBaseWidthOffSync,
  })

  const width = CMAEntriesTableWidthSync.useWidth(columnKey)

  return (
    <Th
      ref={ref}
      {...props}
      {...shouldBaseWidthOffSync && width !== null
        ? { w: width }
        : {}}
    />
  )
}
