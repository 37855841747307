import { FundHistoryRow__TransactionFragmentFragment } from '__generated__/graphql'
import dayjs from 'dayjs'
import { isNumber } from 'lodash/fp'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { UserAvatar } from 'presentation/components/UserAvatarV2'
import { Td, Tr } from 'presentation/components/molecules/Table'
import { FC } from 'react'
import { formatPenniesOptionalDecUsd, formatPenniesOptionalDecUsdSafe } from 'utils/dataAdapter'

export const AccountBalanceRow: FC<{
  transaction: FundHistoryRow__TransactionFragmentFragment
}> = props => {
  const data = props.transaction
  const preBalance = data.preSettlementBalance
  const balance = isNumber(preBalance) ? data.amount + preBalance : null
  const date = dayjs(data.createdAt)

  return (
    <Tr
      smallScreenExpandedContent={(
        <>
          {data.description || '[no description]'}
        </>
      )}
    >
      <Td>
        <Breakpoint
          mobSm={() => <>{date.format('M/D')}</>}
          mob={() => <>{date.format('M/D/YY')}</>}
          tabSm={() => <>{date.format('M/D/YYYY')}</>}
        />
      </Td>

      <Td>
        {data.createdBy?.id
          ? <UserAvatar size='sm' id={data.createdBy?.id} />
          : <UserAvatar size='sm' isUnknown />}
      </Td>

      <Td isBigScreenOnly>
        {data.description || '[no description]'}
      </Td>

      <Td columnType='numeric'>
        {formatPenniesOptionalDecUsd(data.amount)}
      </Td>

      <Td columnType='numeric'>
        {formatPenniesOptionalDecUsdSafe(balance)}
      </Td>
    </Tr>
  )
}
