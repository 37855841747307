import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { restClient } from 'presentation/libs/client'

export const resendConfirmationEmail: AccessDeps['resendConfirmationEmail'] = async () =>
  await restClient.post('core/v3/users/me/sendConfirmationEmail', {
    json: {
      url: window.location.origin,
    },
  })
    .json()
    .then(() => ({
      status: 'success' as const,
    }))
    .catch(error => {
      // if (error instanceof HTTPError && error.response.status === 401)
      //   return { status: 'credentials-error' as const }
      throw error
    })
