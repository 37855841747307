import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { DomainError, ErrorLib, FieldsError, ProgramException, ReportableException } from 'libs/errors'
import { WithId, WithoutId } from 'libs/id'
import { useCallback } from 'react'

const MemberScreen_UpdateMemberPhoneCommand = gql(/* GraphQL */ `
  mutation MemberScreen_UpdateMemberPhoneCommand($id: ID!, $phone: String!) {
    userUpdatePhoneNumber(id: $id, phone: $phone) {
      __typename

      ... on User {
        id

        enterprise {
          ...EnterpriseMembers
        }
      }
      ... on UserInvalidInputError {
        message
        field
      }
    }
  }
`)

type __UpdateMemberPhonePayload = WithId<{ phone: string }>
type __UpdateMemberPhoneError = FieldsError<WithoutId<__UpdateMemberPhonePayload>>
type __UpdateMemberPhoneCommand = Command.PromiseVersion<__UpdateMemberPhoneError, __UpdateMemberPhonePayload>

/**
 * @NOTE Error management is weird because use case is weird -- we're looking to
 *   facade multiple commands including this under a single command
 */
export const useUpdateMemberPhoneCommand = (): __UpdateMemberPhoneCommand => {
  const [updateMemberPhone] = useMutation(MemberScreen_UpdateMemberPhoneCommand)
  const { transition } = CQReact.useStateManager<__UpdateMemberPhoneError, __UpdateMemberPhonePayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<__UpdateMemberPhoneCommand['execute']>(async payload => {
    const commandId = regenerateId()

    return await updateMemberPhone({ variables: payload })
      .then(result => {
        const { data } = result

        if (!data) {
          throw new ReportableException('Unexpected response', {
            extraInfo: { result },
          })
        }

        if (
          data.userUpdatePhoneNumber.__typename === 'UserInvalidInputError'
        ) {
          const serverErr = data.userUpdatePhoneNumber

          throw new FieldsError<WithoutId<__UpdateMemberPhonePayload>>(
            '',
            {
              phone: new DomainError(serverErr.message),
            },
          )
        }

        return transition.success({ id: commandId, payload })
      })
      .catch(rawErr => {
        void ErrorLib.report(rawErr)

        const error = rawErr instanceof FieldsError
          ? rawErr
          : new FieldsError<WithoutId<__UpdateMemberPhonePayload>>(
            '',
            {
              phone: new ProgramException('An error occurred while updating the member phone'),
            },
          )

        return transition.error({ id: commandId, error, payload })
      })
  }, [])

  return {
    execute,
  }
}
