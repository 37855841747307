import { Text } from '@chakra-ui/react'
import { FC } from 'react'
import { BillingInvoiceStatus } from '__generated__/graphql'

/** @TODO Failed subscription payment should be retry prompt */
export const TransactionStatus: FC<{ status: BillingInvoiceStatus }> = props => {
  if (props.status === BillingInvoiceStatus.Authorized) {
    return (
      <Text color='v2.positive.500' textStyle='caption-h-b'>
        Authorized
      </Text>
    )
  }

  if (props.status === BillingInvoiceStatus.Failed) {
    return (
      <Text color='l.neg500-d.neg800' textStyle='caption-h-b'>
        Failed
      </Text>
    )
  }

  if (props.status === BillingInvoiceStatus.PastDue) {
    return (
      <Text color='l.neg500-d.neg800' textStyle='caption-h-b'>
        Past Due
      </Text>
    )
  }
  if (props.status === BillingInvoiceStatus.Pending) {
    return (
      <Text color='l.neg500-d.neg800' textStyle='caption-h-b'>
        Failed
      </Text>
    )
  }
  if (props.status === BillingInvoiceStatus.Refunded) {
    return (
      <Text color='v2.neutral.500' textStyle='caption-h-b'>
        Refunded
      </Text>
    )
  }

  if (props.status === BillingInvoiceStatus.Settled) {
    return (
      <Text color='v2.positive.500' textStyle='caption-h-b'>
        Paid
      </Text>
    )
  }

  return null
}
