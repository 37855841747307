import { Box } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TabLinks } from 'presentation/components/TabLinks'
import { SearchPropertyInput } from 'presentation/layouts/Layout/components/HeaderDesktop/SearchPropertyInput'
import { useHeaderElementHeightTracker, useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLeadIdContext } from 'presentation/libs/LeadIdContext'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { MLSStatusTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingModal'
import { PropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal'
import { usePropertyMarkerTrainingNotice } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingNotice/usePropertyMarkerTrainingNotice'
import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { Link, useMatch, useParams } from 'react-router-dom'

export const PropertyLayout = ({ children }: { children: ReactNode }) => {
  usePropertyMarkerTrainingNotice()
  const leadId = useParams().leadId || null
  const isCompSalesActive = !!useMatch('/search/:leadId/comps')
  const isSkiptraceActive = !!useMatch('/search/:leadId/skiptrace')
  const isCashBuyersActive = !!useMatch('/search/:leadId/cash-buyers')
  const isPropDetailsActive = !!useMatch('/search/:leadId')

  const propertyLayoutTabLinksTracker = useHeaderElementHeightTracker('propertyLayoutTabLinks')
  const propertyLayoutSpacerTracker = useHeaderElementHeightTracker('propertyLayoutSpacer')
  const { layoutWidth } = useLayoutWidth()

  const { setLeadId } = useLeadIdContext()

  useEffect(() => {
    setLeadId(leadId)

    /**
     * @FIX Unset leadId to prevent glitch where a property details page keeps
     *   switching between two ids
     */
    return () => {
      setLeadId(null)
    }
  }, [leadId])

  if (!leadId) return null

  return (
    <>
      <Breakpoint
        dtSm={(
          <TabLinks.Wrapper
            position='relative'
          >
            {/**
             * @Note We have to position absolute this input under the wrapper so we can
             * put it in the same area as the tabs. This would've been better if it is under
             * TabLinks.Buttons and positioned naturally with flex items but because of zIndex
             * hiding the shadows of the tab in relation to the main content, it will hide the
             * SearchPropertyInput's popover.
            */}
            <Box position='absolute' w='376px' top={0} right={3} py={1} pointerEvents='auto' zIndex={2}>
              <SearchPropertyInput inputPlaceholder='Search' />
            </Box>
            {/* /** @HACK temporary fix for shadows of tabs clipped by header */}
            <Box h={2.5} ref={propertyLayoutSpacerTracker.ref} />
            <TabLinks.Buttons
              ref={propertyLayoutTabLinksTracker.ref}
              alignItems='end'
            >
              <TabLinks.Button as={Link} to={`/search/${leadId}`} isActive={isPropDetailsActive}>
                <Breakpoint
                  mobSm='Prop Details'
                  mob='Property Details'
                  dtSm='Details'
                  dtLg='Property Details'
                />
              </TabLinks.Button>
              <TabLinks.Button as={Link} to={`/search/${leadId}/comps`} isActive={isCompSalesActive}>
                <Breakpoint
                  mobSm='Comp Sales'
                  mob='Comparable Sales'
                  dtSm='Comp Sales'
                  dtLg='Comparable Sales'
                />
              </TabLinks.Button>
              <TabLinks.Button as={Link} to={`/search/${leadId}/skiptrace`} isActive={isSkiptraceActive}>
                Skiptrace
              </TabLinks.Button>
              <TabLinks.Button as={Link} to={`/search/${leadId}/cash-buyers`} isActive={isCashBuyersActive}>
                <Breakpoint
                  mobSm='Cash Buyers'
                  dtSm='Buyers'
                  dtLg='Cash Buyers'
                />
              </TabLinks.Button>
            </TabLinks.Buttons>

            <TabLinks.Content minH={0} w={layoutWidth}>
              {children}
            </TabLinks.Content>
          </TabLinks.Wrapper>
        )}
        mobSm={(
          <LayoutMobileWrapper>
            {children}
          </LayoutMobileWrapper>
        )}
      />

      <PropertyMarkerTrainingModal />
      <MLSStatusTrainingModal />
    </>
  )
}

const LayoutMobileWrapper = ({ children }: PropsWithChildren) => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  const { layoutWidth } = useLayoutWidth()
  return (
    <Box
      display='flex'
      flexDirection='column'

      flex='1'

      h={height}
      minH={0}
      bg='neutral.200'
      w={layoutWidth}

      pos='relative'
    >
      {children}
    </Box>
  )
}
