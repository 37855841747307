import { Box } from '@chakra-ui/react'
import { ErrorBoundary } from 'presentation/components/ErrorBoundary'
import { Card, CardFullWidthSection, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { SwitchTabButton, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { SwitchTabsAPI } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { PaymentsTable } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable'
import { listenTabChangeEvent } from 'presentation/screens/Billing/components/BillingHistorySection/billingHistorySectionTabEvents'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ComponentType, FC, useEffect, useRef } from 'react'
import { useMeasure } from 'react-use'
import { AccountBalanceTable } from './AccountBalanceTable'

export const BillingHistorySection: FC = () => (
  <Card
    id='history'
    as='section'
    variant='default'
    colorScheme='card.bg.1'
    size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', dtLg: 'md-locked' })}
    w='full'
  >
    <CardPrimaryTitle>
      Billing History
    </CardPrimaryTitle>

    <ErrorBoundary>
      <BillingHistorySectionContents />
    </ErrorBoundary>
  </Card>
)

const ADDITIONAL_OFFSET = 24

/** @TODO Commented out sticky code for now, it's broken, lets try to fix later */
export const BillingHistorySectionContents: FC = () => {
  const { sbp } = useSwitchBreakpointFn()
  const ref = useRef<SwitchTabsAPI>(null)
  // const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))
  const [tabsRef, { height: tabsHeight }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    listenTabChangeEvent(tab => ref.current?.setActiveTabKey(tab))
  }, [])

  // const tabsProps: FlexProps = sbp({
  //   mobSm: {
  //     top: px(headerHeight + ADDITIONAL_OFFSET),
  //     position: 'sticky',
  //   },
  //   tabSm: {},
  // }) ?? {}

  const tableHeaderStickyOffset = tabsHeight + ADDITIONAL_OFFSET

  // const stickyConcealer = useStickyConcealer()

  return (
    <Box
      mt={mbp({ mobSm: 1, mob: 2 })}
      as={sbp<ComponentType>({ mobSm: CardFullWidthSection, tabSm: Box })}
    >
      {/* {stickyConcealer?.aboveTabsWrapper} */}

      <SwitchTabsWrapper ref={ref}>
        <SwitchTabButtons
          ref={tabsRef}
          // {...stickyConcealer?.tabsProps}
          // {...tabsProps}
        >
          {/* {stickyConcealer?.insideTabs} */}

          <SwitchTabButton tabKey='payments'>Payments</SwitchTabButton>
          <SwitchTabButton tabKey='account-balance'>Account Balance</SwitchTabButton>
        </SwitchTabButtons>

        <SwitchTabContents>
          <SwitchTabContent>
            <PaymentsTable stickyOffset={tableHeaderStickyOffset} />
          </SwitchTabContent>

          <SwitchTabContent>
            <AccountBalanceTable stickyOffset={tableHeaderStickyOffset} />
          </SwitchTabContent>
        </SwitchTabContents>
      </SwitchTabsWrapper>
    </Box>
  )
}

// @TODO Currently broken, try to fix it later
// const useStickyConcealer = () => {
//   const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))
//   const stickyCoverHeight = ADDITIONAL_OFFSET

//   const isEnabled = useSwitchBreakpoint({
//     mobSm: true,
//     tabSm: false,
//   })

//   if (!isEnabled) return null

//   const aboveTabsWrapper = (
//     <Box
//       bg='card.bg.1'
//       pos='sticky'
//       top={px(headerHeight)}
//       left={mbp({ mobSm: 1, mob: 3 })}
//       right={mbp({ mobSm: 1, mob: 3 })}
//       w='full'
//       zIndex='3'
//       h={px(stickyCoverHeight)}
//     />
//   )

//   const tabsProps = {
//     mt: px(-stickyCoverHeight),
//     bg: 'card.bg.1',
//   }

//   const insideTabs = (
//     <BorderTopRightRadiusNegativeIcon
//       color='card.bg.1'
//       pos='absolute'
//       top='100%'
//       right='0'
//     />
//   )

//   return {
//     aboveTabsWrapper,
//     tabsProps,
//     insideTabs,
//   }
// }
