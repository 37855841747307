import { PropsWithChildren, createContext, useContext } from 'react'
import { isNonNullable } from 'utils/isNonNullable'
import { StoreApi, createStore, useStore } from 'zustand'

export type PeekingDrawerPlacement = 'top' | 'bottom'

export type PeekingDrawerState = {
  buttonHeight: number
  isOpen: boolean
  placement?: PeekingDrawerPlacement
}

export type PeekingDrawerActions = {
  setButtonHeight: (value: number) => void
  onToggle: () => void
}

export type PeekingDrawerStore = PeekingDrawerState & PeekingDrawerActions

export type PeekingDrawerStoreOptions = PropsWithChildren<Partial<PeekingDrawerState> & {
  onChange?: (isOpen: boolean) => void
}>

const createPeekingDrawerStore = (option: PeekingDrawerStoreOptions) => {
  const DEFAULT_STATE: PeekingDrawerState = {
    buttonHeight: 0,
    isOpen: false,
    placement: 'top',
  }

  return createStore<PeekingDrawerStore>()(set => ({
    ...DEFAULT_STATE,
    ...option,
    setButtonHeight: value => set(state => ({ ...state, buttonHeight: value })),
    onToggle: () => {
      if (option.onChange)
        option.onChange(!option.isOpen)
      else
        set(state => ({ ...state, isOpen: !state.isOpen }))
    },
  }))
}

const PeekingDrawerContext = createContext<StoreApi<PeekingDrawerStore> | null>(null)

// eslint-disable-next-line @stylistic/comma-dangle
export const usePeekingDrawerStore = <T,>(selector: (store: PeekingDrawerStore) => T) => {
  const context = useContext(PeekingDrawerContext)
  if (isNonNullable(context))
    return useStore(context, selector)
  throw new Error('No context provided for PeekingDrawer')
}

export type PeekingDrawerProviderProps = PropsWithChildren<{
  onChange?: (isOpen: boolean) => void
} & Partial<Omit<PeekingDrawerState, 'buttonHeight'>>
>

export const PeekingDrawerProvider = ({
  children,
  ...props
}: PeekingDrawerProviderProps) => {
  const store = createPeekingDrawerStore(props)

  return (
    <PeekingDrawerContext.Provider value={store}>
      {children}
    </PeekingDrawerContext.Provider>
  )
}
