import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ChevronUpIcon } from 'presentation/components/Icons'
import { useSearchHistoryListPanelStore } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { SidePanelMetaProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistorySidePanel/components/SidePanelMetaButton/SidePanelMetaButton.props'
import { useSearchHistoryMetaProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/useSearchHistoryMetaProps'

export const SidePanelMetaButton = (props: SidePanelMetaProps) => {
  const {
    isDisabled,
    icon: MetaIcon,
    metaInfo,
    color,
    bgColor,
  } = useSearchHistoryMetaProps()

  const { isPanelOpen, setPanelOpen } = useSearchHistoryListPanelStore()

  const handleOnClick = () => {
    if (isDisabled) return
    setPanelOpen(!isPanelOpen)
  }

  return (
    <Box
      onClick={handleOnClick}
      as='button'
      borderWidth={0}
      bgColor={bgColor}
      color={color}
      w='full'
      py={1}
      px={3}
      borderBottomRightRadius={4}
      pointerEvents='auto'
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      {...props}
    >
      <Flex
        justifyContent='flex-start'
        alignItems='center'
        gap={1}
      >
        <MetaIcon
          boxSize={3}
          color='inherit'
        />
        <Text
          flexGrow={1}
          textAlign='left'
          color='inherit'
          textStyle='bodyM'
        >
          {metaInfo}
        </Text>

        <motion.div
          animate={{ rotate: isPanelOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <IconButton
            as='div'
            variant='icon-ghost'
            size='md'
            icon={<ChevronUpIcon />}
            aria-label='Peeking Drawer Icon'
            ml='auto'
            boxSize={5}
            color='inherit'
          />
        </motion.div>
      </Flex>
    </Box>
  )
}
