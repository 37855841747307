import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_COMMERCIAL_ICON_W = 33

export const PropTypeCommercialIcon = createIcon({
  displayName: 'PropTypeCommercialIcon',
  viewBox: `0 0 ${PROP_TYPE_COMMERCIAL_ICON_W} 35`,
  path: (
    <>
      <path d='M1.625 6.625H16.375V34.375H1.625V6.625Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M31.375 6.43313V34.375H16.625V0.901875L31.375 6.43313Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='4.625' y='16.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='4.625' y='10.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='19.625' y='16.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='19.625' y='10.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='4.625' y='22.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='19.625' y='22.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='10.625' y='16.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='10.625' y='10.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='25.625' y='16.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='25.625' y='10.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='10.625' y='22.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='25.625' y='22.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='7.625' y='28.625' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='22.625' y='28.625' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
    </>
  ),
})
