import { PayInvoice, usePayInvoiceMutation, UsePayInvoiceMutationResult } from 'presentation/main/globalModals/FailedInvoiceModal'
import { useRestorePlanMutation } from 'presentation/main/globalModals/RestorePlanModal/useRestorePlanMutation'
import { useCallback } from 'react'

export type UseRestorePlanWithPayMutation = () => UsePayInvoiceMutationResult

export const useRestorePlanWithPayMutation: UseRestorePlanWithPayMutation = () => {
  const { payInvoice, loading: payInvoiceMutationIsLoading } = usePayInvoiceMutation()
  const { restore, loading: restoreMutationIsLoading } = useRestorePlanMutation()

  const payWithRestore: PayInvoice = useCallback(async (invoiceId: string) =>
    await payInvoice(invoiceId)
      .then(async message =>
        await restore()
          .then(() => `${message} Your plan has been restored successfully!`),
      )
  , [payInvoice, restore])

  return {
    payInvoice: payWithRestore,
    loading: payInvoiceMutationIsLoading || restoreMutationIsLoading,
  }
}
