import { Effect, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListMemberNotFoundError } from 'features/ListBuilder/domain/Errors'
import MarketingListMemberRequest from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/MarketingListMemberRequest'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

/**
 * @NOTE: batched instead?
 */
const DeleteByIdResolver = RequestResolver.fromEffect(
  (request: MarketingListMemberRequest.DeleteById) => pipe(
    Effect.tryPromise({
      try: async () =>
        await restClient.delete(`lists/v3/${request.listId}/members/${request.memberId}`)
          .json(),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404) {
          return new ListMemberNotFoundError({
            marketingListId: request.listId,
            marketingListMemberId: request.memberId,
          })
        }

        return new UnknownException(error)
      },
    }),
    Effect.andThen(Effect.void),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default DeleteByIdResolver
