import { anatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

const tagAnatomy = anatomy('tag').parts(
  'container',
  'label',
  'closeButton',
  'leftIcon', // we added this, didn't exist on chakra
  'rightIcon', // we added this, didn't exist on chakra
)

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
)

const baseStyle = {
  container: {
    textAlign: 'center',
  },
}

const variants = {
  bordered: definePartsStyle(props => ({
    container: {
      boxShadow: 'button-hovered',
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.container,
    },
    label: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.label,
    },
    leftIcon: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.leftIcon,
    },
    rightIcon: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.rightIcon,
    },
    closeButton: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.closeButton,
    },
  })),
  solid: definePartsStyle(props => ({
    container: {
      boxShadow: 'button-hovered',
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.container,
    },
    label: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.label,
    },
    leftIcon: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.leftIcon,
    },
    rightIcon: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.rightIcon,
    },
    closeButton: {
      ...colorScheme[props.colorScheme as TagColorScheme]?.(props)?.closeButton,
    },
  })),
}
export type TagVariant = keyof typeof variants
export const tagVariants = Object.keys(variants) as TagVariant[]

const colorScheme = {
  muted: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'grayweak.300',
          color: 'graystrong.600',
          borderColor: 'grayweak.700',
          _hover: {
            bg: 'grayweak.500',
          },
        },
        label: {},
        leftIcon: {
          color: 'grayweak.600',
        },
        rightIcon: {
          color: 'grayweak.600',
        },
        closeButton: {
          color: 'grayweak.600',
          opacity: 1,
        },
      }
      : {},
  ),
  neutral: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'neutral.100',
          color: 'graystrong.600',
          borderColor: 'neutral.700',
          _hover: {
            bg: 'neutral.200',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  positive: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'positive.50',
          color: 'graystrong.600',
          borderColor: 'positive.700',
          _hover: {
            bg: 'positive.200',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  error: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'negative.50',
          color: 'graystrong.600',
          borderColor: 'negative.700',
          _hover: {
            bg: 'negative.200',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  highlight: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'highlight.100',
          color: 'graystrong.600',
          borderColor: 'highlight.700',
          _hover: {
            bg: 'highlight.300',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {
        container: {
          bg: 'highlight.500',
          color: 'graystrong.600',
          _hover: {
            bg: 'accent.gold.100',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      },
  ),
  special: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'specialsat.50',
          color: 'graystrong.600',
          borderColor: 'specialsat.700',
          _hover: {
            bg: 'specialsat.100',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  teal: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'teal.50',
          color: 'graystrong.600',
          borderColor: 'teal.700',
          _hover: {
            bg: 'teal.200',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  positivesat: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'positivesat.100',
          color: 'graystrong.600',
          borderColor: 'positivesat.700',
          _hover: {
            bg: 'positivesat.300',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
  warm: definePartsStyle(props =>
    props.variant === 'bordered'
      ? {
        container: {
          bg: 'warm.100',
          color: 'graystrong.600',
          borderColor: 'warm.700',
          _hover: {
            bg: 'warm.300',
          },
        },
        label: {},
        leftIcon: {
          color: 'graystrong.600',
        },
        rightIcon: {
          color: 'graystrong.600',
        },
        closeButton: {
          color: 'graystrong.600',
          opacity: 1,
        },
      }
      : {},
  ),
} as const

export type TagColorScheme = keyof typeof colorScheme
export const tagColorSchemes = Object.keys(colorScheme) as TagColorScheme[]

const sizes = {
  default: definePartsStyle(props => ({
    container: {
      borderRadius: 'full',
      borderWidth: props.variant === 'bordered' ? 0.125 : 0,
      minH: 3.5,
      py: 0,
      px: 1,
      ...textStyles.bodyM,
      lineHeight: props.variant === 'bordered' ? '26px' : '28px',
    },
    closeButton: {
      boxSize: 3,
      p: 0,
      fontSize: 3,
      ml: 0.5,
      mr: 0,
    },
    leftIcon: {
      mr: 0.5,
      fontSize: 3,
    },
    rightIcon: {
      ml: 0.5,
      fontSize: 3,
    },
  })),
} as const
export type TagSize = keyof typeof sizes
export const tagSizes = Object.keys(sizes) as TagSize[]

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'default',
    colorScheme: 'muted',
    variant: 'bordered',
  },
})
