import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Address } from 'features/valueObjects/Address'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { shallow } from 'zustand/shallow'

export const SkiptraceHeader = (props: FlexProps) => {
  const state = useSkiptraceHeaderState()

  if (state.status === 'unready') return null

  return (
    <Flex
      gap={2}
      py={3}
      {...props}
    >
      <SubjectPropertyMarker
        markerType='pin'
        size='md'
      />
      <Box>
        <Flex gap={1} alignItems='center'>
          <Text
            textStyle='h4'
            color='accent.blue-to-gray'
            textTransform='uppercase'
          >
            {state.address?.line1}
          </Text>
          {state.mlsStatus && <MLSStatusTag status={state.mlsStatus} />}
        </Flex>
        <Text
          textStyle='bodyMFat'
          color='graystrong.200'
        >
          {Address.formatCityStateZip(state.address)}
        </Text>
      </Box>
      {/*
        @MAVERICK
        <Flex gap={2} ml='auto' alignItems='center'>
          <Button
            variant='outline'
            colorScheme='neutral'
            rightIcon={<ChevronDownIcon boxSize={3} />}
          >
            Set Status
          </Button>
          <Button
            variant='solid'
            colorScheme='positive'
          >
            Save to List
          </Button>
          <IconButton
            variant='icon-ghost'
            colorScheme='graystrong'
            aria-label='More options'
            size='md'
            icon={<KebabIcon boxSize={3} />}
          />
        </Flex>
       */}
    </Flex>
  )
}

export const useSkiptraceHeaderState = () => {
  const routeLeadId = useLeadId()
  const propDetailsState = usePropertyDetailsStore(propertyDetailsApi => {
    const propertyState = propertyDetailsApi.actions.getProperty.state

    const isReady = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    if (!isReady) return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      address: propertyState.data.address,
      mlsStatus: propertyState.data.status === 'with-details'
        ? propertyState.data.mlsListings[0]?.status
        : null,
    }
  }, shallow)

  return propDetailsState
}
