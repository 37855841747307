import { PartsStyleFunction, StyleFunctionProps } from '@chakra-ui/theme-tools'
import { px } from 'presentation/utils/px'
import { g8Unit } from 'presentation/utils/unit'
import { textStyles } from '../../main/themes/common/textStyles.common.theme'

export const containedInputBaseStyles = ({ value, isInvalid, isSuccess, isFocused }: {
  value?: string
  isInvalid?: boolean
  isSuccess?: boolean
  isFocused?: boolean
} = {}) => ({
  field: {
    borderColor: isFocused || isSuccess ? 'v2.neutral.500' : isInvalid ? 'v2.error.500' : 'v2.grayWeak.500',
    borderWidth: isInvalid || isFocused || isSuccess ? 0.25 : '1px',
    borderRadius: 1,
    boxShadow: isInvalid || isFocused || isSuccess || value
      ? 'none'
      : 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    bg: 'l.gw100-graylight',
    color: 'l.gs500-d.gs700',
    _placeholder: { color: 'l.gs100-d.gs300' },
    _focus: {
      borderColor: 'info',
      borderWidth: 0.25,
      boxShadow: 'none',
      bg: 'l.gw100-graylight',
    },
    _disabled: {
      boxShadow: 'none',
      bg: 'v2.grayWeak.500',
      color: 'v2.grayWeak.700',
      borderWidth: 0,
    },
  },
}) as const

const containedBaseStylesTyped: PartsStyleFunction = ({ value, isInvalid, isSuccess }) =>
  containedInputBaseStyles({ value, isInvalid, isSuccess })

const modalStyles: PartsStyleFunction = ({ value, isInvalid, isSuccess, isDisabled }) => ({
  field: {
    borderColor: isSuccess ? 'success' : isInvalid ? 'error' : value ? 'fg-800' : 'fg-500',
    borderWidth: isInvalid || isSuccess ? 0.25 : '1px',
    borderRadius: 1,
    boxShadow: isDisabled
      ? 'none'
      : 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    bg: 'bg-500',
    _placeholder: { color: 'font-4' },
    _focus: {
      borderColor: 'info',
      borderWidth: 0.25,
    },
    _disabled: {
      bg: 'fg-500',
      color: 'font-4',
      borderWidth: 0,
    },
  },
})

const modalStylesForcedLight: PartsStyleFunction = ({ value, isInvalid, isSuccess, isDisabled }) => ({
  field: {
    borderColor: isSuccess
      ? 'rgba(79, 185, 89, 1)'
      : isInvalid
        ? '#d70300'
        : value
          ? 'rgba(0, 0, 0, 0.50)'
          : 'rgba(0, 0, 0, 0.20)',
    borderWidth: isInvalid || isSuccess ? 0.25 : '1px',
    borderRadius: 1,
    boxShadow: isDisabled
      ? 'none'
      : 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    bg: 'rgba(255, 255, 255, 0.40)',
    _placeholder: { color: 'rgba(0, 0, 0, 0.30)' },
    _focus: {
      borderColor: 'rgba(7, 112, 186, 1)',
      borderWidth: 0.25,
    },
    _disabled: {
      bg: 'rgba(0, 0, 0, 0.20)',
      color: 'rgba(0, 0, 0, 0.30)',
      borderWidth: 0,
    },
  },
})

const searchStyles: PartsStyleFunction = ({ value, isInvalid, isSuccess }) => ({
  field: {
    borderColor: isSuccess ? 'positive.500' : isInvalid ? 'error.500' : 'grayweak.500',
    borderWidth: isInvalid || isSuccess ? 0.25 : '1px',
    borderRadius: 1,
    boxShadow: isInvalid || isSuccess || value
      ? 'none'
      : 'depth',
    bg: 'card.bg.1',
    color: 'graystrong.200',
    _placeholder: { color: 'graystrong.100' },
    _focus: {
      borderColor: 'neutral.500',
      borderWidth: 0.25,
      boxShadow: 'none',
      bg: 'card.bg.1',
    },
    _disabled: {
      boxShadow: 'none',
      bg: 'grayweak.400',
      color: 'graystrong.200',
    },
  },
  element: {
    color: 'graystrong.100',
  },
})

export const inputLegacyTheme = {
  baseStyle: {
    color: 'font-2',
  },
  sizes: {},
  variants: {
    'contained-md': (props: StyleFunctionProps) => ({
      ...containedBaseStylesTyped(props),
      field: {
        ...containedBaseStylesTyped(props).field,
        ...textStyles.body,
        h: 5,
        px: 2,
      },
    }),

    'contained-sm': (props: StyleFunctionProps) => ({
      ...containedBaseStylesTyped(props),
      field: {
        ...containedBaseStylesTyped(props).field,
        ...textStyles.body3,
        h: 3.5,
        px: 1,
      },
    }),

    'search-md': (props: StyleFunctionProps) => ({
      ...searchStyles(props),
      field: {
        ...searchStyles(props).field,
        ...textStyles.body,
        h: 5,
        borderRadius: 3,
        px: props.isInvalid || props.isSuccess ? '15px' : 2,
        _focus: {
          ...(searchStyles(props).field as any)._focus,
          px: '15px',
        },
      },
    }),

    'search-sm': (props: StyleFunctionProps) => ({
      ...searchStyles(props),
      field: {
        ...searchStyles(props).field,
        ...textStyles.body2,
        h: 3.5,
        borderRadius: px(g8Unit(3.5 / 2)),
        px: 2,
      },
    }),

    'modal': (props: StyleFunctionProps) => ({
      ...modalStyles(props),
      field: {
        ...modalStyles(props).field,
        ...textStyles.body2,
        h: 5,
        px: 1.5,
      },
    }),

    'modal-search': (props: StyleFunctionProps) => ({
      ...modalStyles(props),
      field: {
        ...modalStyles(props).field,
        ...textStyles.body,
        h: 4.5,
        borderRadius: px(g8Unit(4.5 / 2)),
        px: 2,
      },
    }),

    'modal-search-forced-light': (props: StyleFunctionProps) => ({
      ...modalStylesForcedLight(props),
      field: {
        ...modalStylesForcedLight(props).field,
        ...textStyles.body,
        h: 4.5,
        borderRadius: px(g8Unit(4.5 / 2)),
        px: 2,
      },
    }),
  },
  defaultProps: {},
}
