import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { shallow } from 'zustand/shallow'

export const useThemeSettings = () => {
  const settingsApi = useSettingsStore(state => ({
    theme: state.settingsState.settings.theme,
    updateSettings: state.actions.updateSettings.execute,
  }), shallow)

  const toggleTheme = () => {
    void settingsApi.updateSettings({
      settings: {
        theme: settingsApi.theme === 'light' ? 'dark' : 'light',
      },
    })
  }

  return {
    theme: settingsApi.theme,
    toggleTheme,
  }
}
