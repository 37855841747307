import { isToday, max } from 'date-fns'
import { Match } from 'effect'
import { Skiptrace as Domain } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Address } from 'features/valueObjects/Address'
import { SkiptraceProvider } from 'features/valueObjects/SkiptraceProvider'
import { ArrayLib } from 'libs/Array'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { ErrorLib, ReportableException } from 'libs/errors'
import { isString, map, pipe, sort } from 'remeda'

export const mapGetSkiptraceResultResponse = (
  rawResponse: any,
  tracers: Domain.Tracer[],
): Domain.GetSkiptraceResultResult => {
  const rawProperty = rawResponse?.property
  const skiptraceRequests = rawResponse?.property?.skiptraces || []

  if (
    !rawProperty
    || !skiptraceRequests.length
  )
    return { result: null }

  let latestSkiptracedAt = null as Date | null
  const providersSet = new Set<SkiptraceProvider>()
  const tracersIdSet = new Set<string>()
  const ownersMap: Record<string, any> = {}

  // ========================================
  // Loop thru raw.property.skiptraces
  // ========================================
  skiptraceRequests.forEach(tryWithReporter(
    'skiptraceRequests',
    (rawSkipReq: any) => {
      if (!rawSkipReq?.providers) return

      // ========================================
      // skiptracedAt
      // ========================================
      const thisSkiptracedAt = DateLib.fromIso(rawSkipReq.updatedAt)
      latestSkiptracedAt = mergeLatestDate(thisSkiptracedAt, latestSkiptracedAt)

      // ========================================
      // tracers
      // ========================================
      const tracerId = isString(rawSkipReq.userId) && mapRawId(rawSkipReq.userId)
      if (tracerId)
        tracersIdSet.add(tracerId)

      // ========================================
      // Loop thru raw.property.skiptraces.providers entries
      // ========================================
      Object.entries(rawSkipReq?.providers)
        .forEach(tryWithReporter(
          'Object.entries(rawSkipReq?.providers)',
          ([rawProvider, rawProviderDataList]: [string, any]) => {
            // ========================================
            // providers
            // ========================================
            const provider = mapRawProvider(StringLib.ensureOr('')(rawProvider))
            providersSet.add(provider)

            if (!Array.isArray(rawProviderDataList)) return

            // ========================================
            // Loop thru raw.property.skiptraces.providers value array
            // ========================================
            rawProviderDataList.forEach(tryWithReporter(
              'Object.entries(rawSkipReq?.providers)',
              (rawSkipData: any) => {
                if (!rawSkipData) return

                // ========================================
                // owners
                // ========================================
                const ownerName = rawSkipData.subjectName?.trim() || 'Unknown'

                const owner = ownersMap[ownerName] || {}

                owner.name = ownerName
                owner.updatedAt = mergeLatestDate(thisSkiptracedAt, owner.updatedAt)

                const mergeWithCurrent = <T>(
                  incomingValue: T,
                  existingValueWithDate?: { value: T, date: Date },
                ) =>
                  mergeInfoWithDate(
                    createInfoWithDate(incomingValue, thisSkiptracedAt),
                    existingValueWithDate,
                  )

                owner.age = mergeWithCurrent(
                  tryNumberFromString(rawSkipData.age),
                  owner.age,
                )

                owner.bankruptciesCount = mergeWithCurrent(
                  tryNumberFromString(rawSkipData.bankruptcies),
                  owner.bankruptciesCount,
                )

                owner.judgementsCount = mergeWithCurrent(
                  tryNumberFromString(rawSkipData.judgements),
                  owner.judgementsCount,
                )

                owner.liensCount = mergeWithCurrent(
                  tryNumberFromString(rawSkipData.liens),
                  owner.liensCount,
                )

                // ========================================
                // possiblePhoneNumbers
                // ========================================
                const rawPhones = ArrayLib.ensure(rawSkipData?.phones)

                owner.possiblePhoneNumbers = rawPhones.reduce<Record<string, any>>((acc, rawPhone: any) => {
                  const phoneNumber = StringLib.ensureOrNull(rawPhone?.number?.trim())
                  const type = StringLib.ensureOrNull(rawPhone?.type?.trim())

                  if (!phoneNumber) return acc

                  const existing = acc[phoneNumber]

                  acc[phoneNumber] = {
                    updatedAt: mergeLatestDate(thisSkiptracedAt, existing?.updatedAt),
                    isNew: false,
                    type,
                    phoneNumber,
                  }

                  return acc
                }, owner.possiblePhoneNumbers || {})

                // ========================================
                // possibleEmails
                // ========================================
                const rawEmails = ArrayLib.ensure(rawSkipData?.emails)

                owner.possibleEmails = rawEmails.reduce<Record<string, any>>((acc, rawEmail: any) => {
                  const email = StringLib.ensureOrNull(rawEmail?.trim())

                  if (!email) return acc

                  const existing = acc[email]

                  acc[email] = {
                    updatedAt: mergeLatestDate(thisSkiptracedAt, existing?.updatedAt),
                    isNew: false,
                    email,
                  }

                  return acc
                }, owner.possibleEmails || {})

                // ========================================
                // possibleAssociates
                // ========================================
                const rawAssociates = ArrayLib.ensure(rawSkipData?.possibleRelatives)

                owner.possibleAssociates = rawAssociates.reduce<Record<string, any>>((acc, rawAssociate: any) => {
                  const name = StringLib.ensureOrNull(rawAssociate?.name?.trim())
                  const unformattedAge = StringLib.ensureOrNull(rawAssociate?.age?.trim())

                  if (!name) return acc

                  const existing = acc[name]

                  acc[name] = {
                    updatedAt: mergeLatestDate(thisSkiptracedAt, existing?.updatedAt),
                    isNew: false,
                    name,
                    unformattedAge,
                  }

                  return acc
                }, owner.possibleAssociates || {})

                // ========================================
                // addressHistory
                // ========================================
                const rawAddressHistory = ArrayLib.ensure(rawSkipData?.addressSearch)

                owner.addressHistory = rawAddressHistory.reduce<Record<string, any>>((acc, rawAddress: any) => {
                  if (!rawAddress) return acc

                  const address = Address.ifValid(Address.clean)({
                    ...rawAddress,
                    line1: rawAddress.address,
                    postalCode: rawAddress.zip,
                  })

                  if (!address) return acc

                  const addressId = Address.serialize(address)

                  const existing = acc[addressId]

                  acc[addressId] = {
                    updatedAt: mergeLatestDate(thisSkiptracedAt, existing?.updatedAt),
                    isNew: false,
                    address,
                  }

                  return acc
                }, owner.addressHistory || {})

                // ========================================
                // update ownersMap
                // ========================================

                ownersMap[ownerName] = owner
              }))
          }))
    }))

  // ========================================
  // make sure we have all the expected data
  // ========================================

  const leadId = StringLib.ensureOrNull(rawResponse.id)
  const address = Address.ifValid(Address.clean)(rawProperty.address)

  if (!latestSkiptracedAt || !leadId || !address) {
    throw new ReportableException('Failed to map skiptrace', {
      extraInfo: rawResponse,
    })
  }

  // no idea why I have type error without this
  const ensuredLatestSkiptracedAt = latestSkiptracedAt

  // ========================================
  // owners post processing
  // ========================================
  const flatLatestElements = flagNewElementsBasedOnDate(
    ensuredLatestSkiptracedAt,
  )

  const owners = pipe(
    ownersMap,
    Object.values,
    map(rawOwner => {
      const withDateUnwrapped = unwrapPropertiesWithDate(rawOwner)

      // phones post processing
      withDateUnwrapped.possiblePhoneNumbers = pipe(
        withDateUnwrapped.possiblePhoneNumbers as any,
        Object.values,
        sort(compareByDateThenAlpha(x => x.phoneNumber)),
        flatLatestElements,
      )

      // emails post processing
      withDateUnwrapped.possibleEmails = pipe(
        withDateUnwrapped.possibleEmails as any,
        Object.values,
        sort(compareByDateThenAlpha(x => x.email)),
        flatLatestElements,
      )

      // associates post processing
      withDateUnwrapped.possibleAssociates = pipe(
        withDateUnwrapped.possibleAssociates as any,
        Object.values,
        sort(compareByDateThenAlpha(x => x.name)),
        flatLatestElements,
      )

      // addressHistory post processing
      withDateUnwrapped.addressHistory = pipe(
        withDateUnwrapped.addressHistory as any,
        Object.values,
        sort(compareByDateThenAlpha(x => Address.serialize(x.address))),
        flatLatestElements,
      )

      return withDateUnwrapped
    }),
    sort(compareByDateThenAlpha((owner: any) => owner.name)),
    flatLatestElements,
  )

  const tracersMap = tracers.reduce<Record<string, Domain.Tracer>>((acc, tracer) => {
    acc[tracer.id] = tracer
    return acc
  }, {})

  const result = {
    result: {
      skiptracedAt: ensuredLatestSkiptracedAt,
      providers: Array.from(providersSet),
      tracers: Array.from(tracersIdSet)
        .map(tracerId => tracersMap[tracerId])
        .filter(Boolean),
      subject: {
        leadId,
        address,
        owner: null,
      },
      owners: owners as any,
    },
  }

  return result
}

const mapRawProvider = (rawProvider: string) =>
  pipe(
    Match.value(rawProvider),
    Match.when('skipGenie', () => 'SKIPGENIE' as const),
    Match.when('reiGroup', () => 'REIGROUP' as const),
    Match.orElse(() => 'UNKNOWN' as const),
  )

// Fixes "001" to "1"
const mapRawId = (rawId: string) =>
  rawId.replace(/^0+/, '')

const mergeInfoWithDate = <T>(
  incoming: { value: T, date: Date },
  existing?: { value: T, date: Date },
) => {
  if (!existing)
    return incoming

  const doesOnlyExistingHaveValue = !!existing.value && !incoming.value
  const isExistingNewer = existing.date.getTime() > incoming.date.getTime()

  if (doesOnlyExistingHaveValue || isExistingNewer)
    return existing

  return incoming
}

const tryNumberFromString = (raw: any) =>
  StringLib.ifTruthy(NumberLib.fromStringSafe)(raw?.trim())

const createInfoWithDate = <T>(
  value: T,
  date: Date,
) => ({
  __type: 'InfoWithDate' as const,
  value,
  date,
})

const unwrapInfoWithDate = <T>(
  infoWithDate: { value: T, date: Date },
) => infoWithDate.value

const isInfoWithDate = (value: any): value is { value: any, date: Date } =>
  value?.__type === 'InfoWithDate'

const unwrapPropertiesWithDate = <T>(
  propertiesWithDate: Record<string, any>,
) =>
  Object
    .entries(propertiesWithDate)
    .reduce<Record<string, T>>((acc, [key, value]) => {
    if (isInfoWithDate(value))
      acc[key] = unwrapInfoWithDate(value)
    else
      acc[key] = value

    return acc
  }, {})

const compareByDateThenAlpha = <TElement>(
  getAlphaValue: (x: TElement) => string,
) => (a: TElement, b: TElement) => {
  const aAlpha = getAlphaValue(a)
  const bAlpha = getAlphaValue(b)

  if (aAlpha !== bAlpha)
    return aAlpha.localeCompare(bAlpha)

  return 0
}

const mergeLatestDate = (a: Date, b?: Date | null) =>
  b ? max([a, b]) : a

const flagNewElementsBasedOnDate = (basisDate: Date) =>
  <T extends { updatedAt: Date }>(
    elements: T[],
  ) => {
    const isLatestDateToday = isToday(basisDate)

    if (!isLatestDateToday) return elements

    return elements.map(element => ({
      ...element,
      isNew: element.updatedAt.getTime() >= basisDate.getTime(),
    }))
  }

const tryWithReporter = <T>(
  description: string,
  fn: (arg: T) => void,
) => (arg: T) => {
  try {
    fn(arg)
  } catch (error) {
    void ErrorLib.report(new ReportableException(`Failed to process ${description}`, {
      extraInfo: {
        arg,
        description,
      },
      originalError: error as any,
    }))
  }
}
