import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { mapHistory } from 'features/SearchHistory/infra/HistoryQuery/getHistory.mapper'
import { NormalizedHistory } from 'features/SearchHistory/infra/HistoryQuery/getHistory.types'
import stableStringify from 'json-stable-stringify'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'
import qs from 'qs'

export type GetHistoryParams = {
  textFilter?: string
  seekersFilter?: string[]
  sorting?: SearchHistory.Sorting
  offset?: number
  limit: number
}

export const getHistory = async ({
  textFilter,
  seekersFilter,
  sorting = 'search-date-new-first',
  offset,
  limit,
}: GetHistoryParams): Promise<{
  history: NormalizedHistory
}> => {
  const cacheKey = stableStringify({
    textFilter: textFilter ?? null,
    seekersFilter: seekersFilter ?? null,
    sorting: sorting ?? null,
    offset: offset ?? null,
    limit,
  })

  const fromCache = ServerCache.get('getHistory')(cacheKey)

  if (fromCache) return fromCache as { history: NormalizedHistory }

  const response = await restClient.get('legacy/leads', {
    searchParams: qs.stringify({
      show: 'active',
      ...textFilter && { address: textFilter },
      creator: seekersFilter,
      sort: mapSortingToLegacy(sorting),
      offset,
      limit,
    }),
  })
    .json()
    .then(rawData => ({
      /** Just for snapshotting purposes */
      __original: rawData,
      history: mapHistory({ rawData, limit }),
    }))

  ServerCache.set('getHistory')(cacheKey)(response)

  return response
}

const mapSortingToLegacy = (sorting: SearchHistory.Sorting): string => {
  switch (sorting) {
  case 'search-date-new-first':
    return 'modified-desc'
  case 'search-date-old-first':
    return 'modified-asc'
  case 'street-name-a-to-z':
    return 'address_street-asc'
  case 'street-name-z-to-a':
    return 'address_street-desc'
  case 'street-number-low-first':
    return 'address_number-asc'
  case 'street-number-high-first':
    return 'address_number-desc'
  }
}
