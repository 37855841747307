import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { CMARatingChangeEventHandler } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { ComparativePropertyCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCard/ComparativePropertyCard.props'
import { useEntriesTransformation } from 'presentation/screens/CompsScreen/hooks/useEntriesTransformation'
import { useEffect, useState } from 'react'
import { findIndex } from 'remeda'

export type CompEntry = {
  entryIndex: number
  comp: CMA.SingleComp | CMA.SubjectComp
}

export const useComparativePropertyCardLogic = (props: ComparativePropertyCardProps) => {
  const withoutSubj = props.comps.filter(v => v.type === 'single-comp') as CMA.SingleComp[]
  const hasSubject = !!props.comps.find(v => v.type === 'subject-comp')
  const transformedComps = useEntriesTransformation({ comps: withoutSubj })
  const comps = pipe(
    Match.value(props.modalType),
    Match.when('single-point-comps', () => hasSubject ? [props.subject, ...transformedComps] : transformedComps),
    Match.when('single-comp', () => [props.subject, ...transformedComps]),
    Match.exhaustive,
  )

  const compEntries: CompEntry[] = comps.map((comp, i) => ({
    entryIndex: i,
    comp,
  } satisfies CompEntry))

  const [unsafeSelectedCompIndex, setSelectedCompIndex] = useState(() => {
    const compId = findIndex(compEntries, entry => entry.comp.id === props.targetCompId)
    return compId === -1 ? 0 : compId
  })

  const maxIndex = compEntries.length - 1

  /**
   * There's specific case that nesseciates has the folowing 3 conditions:
   * 1. Thumbs-down button is set to NOT SHOW; and
   * 2. The comp was thumbed-down (resulting to it getting omitted to the viewed list)
   * 3. The comp that was thumbed-down is the last comp in the list.
   *
   * In such case the same index will no longer correspond to the same comp.
   * That's fine (it will just show the next comp), however if that comp is the
   * LAST comp, then the index will be out of bounds.
   **/
  const safeSelectedIndex = Math.min(unsafeSelectedCompIndex, maxIndex)

  const isSinglePointComps = props.modalType === 'single-point-comps'

  const modalBgColor = isSinglePointComps
    ? 'bg-modal-highlight'
    : 'special.500'

  const totalItems = compEntries.length

  const currentComp = compEntries[safeSelectedIndex]
    ?.comp as CMA.SingleComp | CMA.SubjectComp | undefined

  useEffect(() => {
    /**
     * Make sure that whenever the current comp changes,
     * the highlighted entry is also updated. This will
     * scroll the list in the side panel to the current
     * comp and the map marker is highlighted to show
     * the popover
     */
    CompsMapAndListSync.setHighlightedEntry(currentComp?.id ?? null)
  }, [currentComp])

  // Handle when no comps, which happens when the LAST COMP IS THUMBED-DOWN
  // and the thumbs-down button is set to NOT SHOW
  useEffect(() => {
    if (totalItems === 0)
      props.onClose()
  }, [totalItems])

  if (!currentComp) {
    return {
      status: 'aborting' as const,
    }
  }

  const selectedCompIsSubject = currentComp.type === 'subject-comp'

  const onSelectedIndexChange = (index: number) => {
    setSelectedCompIndex(index)
  }

  const handleRatingChange: CMARatingChangeEventHandler = (compId, rating) => {
    props.onCompsRatingChange?.(compId, rating)
  }

  return {
    status: 'ok' as const,
    isSinglePointComps,
    totalItems,
    compEntries,
    currentComp,
    selectedCompIsSubject,
    safeSelectedIndex,
    modalBgColor,
    handleRatingChange,
    onSelectedIndexChange,
  }
}
