import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { merge } from 'lodash/fp'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = () => definePartsStyle({
  control: {
    '& > svg': {
      color: 'ondark.1',
    },
    '&[data-disabled]': {
      borderColor: 'grayweak.500',
      backgroundColor: 'grayweak.600',
    },
    '&[data-checked][data-disabled]': {
      '& > svg': {
        color: 'ondark.5',
      },
      'borderColor': 'grayweak.900',
      'backgroundColor': 'grayweak.900',
    },
    '&[data-indeterminate][data-disabled]': {
      '& > svg': {
        color: 'ondark.5',
      },
      'borderColor': 'grayweak.900',
      'backgroundColor': 'grayweak.900',
    },
  },
})

const sizes = {
  sm: definePartsStyle({
    control: {
      '& > svg': {
        w: '16px',
        h: '12px',
      },
      'w': '24px',
      'h': '24px',
    },
    label: {
      ...textStyles.bodySFat,
    },
  }),
  md: definePartsStyle({
    control: {
      '& > svg': {
        w: '16px',
        h: '12px',
      },
      'w': '24px',
      'h': '24px',
    },
    label: {
      ...textStyles.bodyL,
    },
  }),
  lg: definePartsStyle({
    control: {
      '& > svg': {
        w: '20px',
        h: '16px',
      },
      'w': '32px',
      'h': '32px',
    },
    label: {
      ...textStyles.bodyL,
    },
  }),
}

const colorSchemes = {
  neutral: () => definePartsStyle({
    control: {
      _checked: {
        boxShadow: 'none',
        borderColor: 'neutral.500',
        backgroundColor: 'neutral.500',
        color: 'neutral.500',

        _hover: {
          boxShadow: 'none',
          borderColor: 'neutral.500',
          backgroundColor: 'neutral.500',
        },
      },
    },
  }),
  positive: () => definePartsStyle({
    control: {
      _checked: {
        boxShadow: 'none',
        borderColor: 'positive.500',
        backgroundColor: 'positive.500',
        color: 'positive.500',

        _hover: {
          boxShadow: 'none',
          borderColor: 'positive.500',
          backgroundColor: 'positive.500',
        },
      },
    },
  }),
}

const variants = {
  default: (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(colorSchemes)?.() || {},
      {
        control: {
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset',
          backgroundColor: 'card.bg.2',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '50%',
          borderColor: 'grayweak.500',
          _checked: {
            '&::before': {
              display: 'none',
            },
          },
        },
      },
    ),
}

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'default',
    colorScheme: 'neutral',
  },
})
