import { gql } from '__generated__'

export const GET_TRACERS_QUERY = gql(/* Graphql */`
  query Tracers {
    myEnterprise {
      users {
        edges {
          node {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`)
