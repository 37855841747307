import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { GETDebouncedListMembers } from 'features/common/infra/GETDebouncedListMembers'

const request = async (
  listId: MarketingListId,
  limit: number,
  offset: number,
) => await GETDebouncedListMembers.request(
  listId,
  offset,
  limit,
)

export const GETBatchSkiptraceByListId = {
  request,
}
