import { Box, BoxProps, Button, ButtonProps, Flex, Grid, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, useDisclosure } from '@chakra-ui/react'
import { isNumber } from 'effect/Number'
import { CMA } from 'features/CMA/CMA.domain'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useCompsFiltersPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { FilterPopover, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { PropsWithChildren, useState } from 'react'
import { shallow } from 'zustand/shallow'

export type DefaultFiltersDropdownProps = ButtonProps

export const DefaultFiltersDropdown = (props: DefaultFiltersDropdownProps) => {
  const onOpenEditFilterPanel = useCompsFiltersPanelStore().openPanel

  const [isOpen, setIsOpen] = useState(false)
  const data = useDefaultsFilterData()

  const isDisabled = data === null

  const filters = data?.values ?? []

  const handleOnOpenEditFilterPanel = () => {
    onOpenEditFilterPanel()
    setIsOpen(false)
  }

  const handleSetDefault = () => {
    data?.setDefault()
    setIsOpen(false)
  }

  return (
    <FilterPopover
      isOpen={isOpen}
      closeOnBlur={true}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<ChevronDownIcon />}
          isDisabled={isDisabled}
          variant={mbp({ mobSm: 'outline', tabSm: 'ghost' })}
          colorScheme='neutral'
          size='sm'
          onClick={() => setIsOpen(!isOpen)}
          px={2}
          sx={{
            '&.chakra-button': {
              bgColor: mbp({ mobSm: 'card.bg.1', tabSm: 'transparent' }),
            },
          }}
          {...props}
        >
          <Breakpoint
            mobSm='Filter'
            tabSm='Set Default'
          />
        </Button>
      </PopoverTrigger>
      <Portal>
        <FilterPopoverContent
          popoverContentProps={{
            w: 'min-content',
          }}
          modalContentProps={{
            sx: {
              px: 0,
            },
          }}
        >
          <Grid
            h='full'
            minH={0}
            gridTemplateRows='min-content minmax(0, 1fr) min-content'
          >
            <Text
              textStyle='bodyLFat'
              color='graystrong.400'
              h={5}
              px={mbp({ mobSm: 2, tabSm: 0 })}
            >
              Recommended Default Filters
            </Text>

            <ScrollWithFade
              containerProps={{
                h: 'full',
                minH: 0,
              }}
              pb={3}
              px={mbp({ mobSm: 2, tabSm: 0 })}
              fadeColor='rgb(0, 0, 0, 0.15)'
              fadeSize={3}
            >
              <Flex
                flexDirection='column'
                gap={2}
              >
                {filters.map((v, i) => (
                  <MinMaxInput
                    key={i}
                    label={v.label}
                    min={v.minLabel}
                    max={v.maxLabel}
                    subjectValue={v.subjectValue}
                  />
                ))}

              </Flex>
            </ScrollWithFade>
            <Flex
              gap={2}
              justifyContent='center'
              py={mbp({ mobSm: 1, tabSm: 0 })}
              px={mbp({ mobSm: 1, mob: 2, tabSm: 0 })}
            >
              <Breakpoint
                mobSm={(
                  <EditFiltersButton flex='1' onClick={handleOnOpenEditFilterPanel} />
                )}
                tabSm={<></>}
              />
              <ApplyDefaultsButtons flex='1' size={mbp({ mobSm: 'md', tabSm: 'sm' })} onClick={handleSetDefault} />
            </Flex>
          </Grid>
        </FilterPopoverContent>
      </Portal>
    </FilterPopover>
  )
}

type MinMaxInputProps = {
  min: string
  max: string
  subjectValue: string | null
  label: string
} & BoxProps

const MinMaxInput = (props: MinMaxInputProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const inputMinW = sbp({ mobSm: '108px', tabSm: '120px' })
  const { min, max, subjectValue, label, ...boxProps } = props
  return (
    <Box {...boxProps}>
      <Text
        textStyle='bodyLFat'
        color='grayweak.900'
      >
        {label}
      </Text>
      <Flex gap={2} mt={2}>
        <MinMaxTooltip>
          <Input type='text' value={min} isReadOnly minW={inputMinW} />
        </MinMaxTooltip>
        <Flex
          flexDir='column'
          gap={0.25}
          alignItems='center'
        >
          <SubjectPropertyMarker
            markerType='pin'
            size='xxs'
          />
          <Text
            textStyle='tagL'
            color='graystrong.200'
          >
            {subjectValue}
          </Text>
        </Flex>
        <MinMaxTooltip>
          <Input type='text' value={max} isReadOnly minW={inputMinW} />
        </MinMaxTooltip>
      </Flex>
    </Box>
  )
}

const MinMaxTooltip = ({ children }: PropsWithChildren) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box
          flex='1 1 0'
          onClick={() => {
            onToggle()
          }}
        >
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent w={mbp({ mobSm: '168px', tabSm: '160px' })} borderRadius={0.5}>
        <PopoverArrow />
        <PopoverBody textStyle='tagL' color='graystrong.400' px={2} py={1}>
          <Breakpoint
            mobSm='To edit filters, tap the edit filter button below.'
            tabSm='
              To edit filters, you can tap on individual filter buttons
              in the header or select &lsquo;All Filter&rsquo;.
            '
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const EditFiltersButton = (props: ButtonProps) => (
  <Button
    variant='solid'
    colorScheme='special'
    size='md'
    {...props}
  >
    Edit Filters
  </Button>
)

const ApplyDefaultsButtons = (props: ButtonProps) => (
  <Button
    variant='solid'
    colorScheme='neutral'
    size='md'
    {...props}
  >
    Apply Defaults
  </Button>
)

const useDefaultsFilterData = () => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    bathsSubjectValue,
    bedsSubjectValue,
    houseSizeSubjectValue,
    lotSizeSubjectValue,
    yearBuiltSubjectValue,
    lotSizeUnit,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        bathsSubjectValue: null,
        bedsSubjectValue: null,
        houseSizeSubjectValue: null,
        lotSizeSubjectValue: null,
        yearBuiltSubjectValue: null,
        lotSizeUnit: 'sqft' as CMA.Filters.LotAreaSize['unit'],
      }
    }

    return {
      bathsSubjectValue: state.local.report.data.salesListInfo.subject?.bathroomsCountInfo?.full ?? null,
      bedsSubjectValue: state.local.report.data.salesListInfo.subject?.bedroomsCount ?? null,
      houseSizeSubjectValue: state.local.report.data.salesListInfo.subject?.livingAreaSqft ?? null,
      lotSizeSubjectValue: state.local.report.data.salesListInfo.subject?.lotAreaSqft ?? null,
      yearBuiltSubjectValue: state.local.report.data.salesListInfo.subject?.yearBuilt ?? null,
      lotSizeUnit: state.local.report.data.filters.lotAreaSize?.unit ?? 'sqft' as CMA.Filters.LotAreaSize['unit'],
    }
  }, shallow)

  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const yearBuiltValue = {
    label: 'Year Built',
    maxLabel: '+ 5 Years',
    maxValue: isNumber(yearBuiltSubjectValue) ? yearBuiltSubjectValue + 5 : null,
    minLabel: '- 5 Years',
    minValue: isNumber(yearBuiltSubjectValue) ? yearBuiltSubjectValue - 5 : null,
    subjectValue: StringLib.orDoubleDash(yearBuiltSubjectValue?.toString()),
  }

  const bedsValue = {
    label: 'Beds',
    maxLabel: '+ 1 Bed',
    maxValue: isNumber(bedsSubjectValue) ? bedsSubjectValue + 1 : null,
    minLabel: '- 1 Bed',
    minValue: isNumber(bedsSubjectValue) ? bedsSubjectValue - 1 : null,
    subjectValue: StringLib.orDoubleDash(bedsSubjectValue?.toString()),
  }

  const bathsValue = {
    label: 'Baths',
    maxLabel: '+ 1 Bath',
    maxValue: isNumber(bathsSubjectValue) ? bathsSubjectValue + 1 : null,
    minLabel: '- 1 Bath',
    minValue: isNumber(bathsSubjectValue) ? bathsSubjectValue - 1 : null,
    subjectValue: StringLib.orDoubleDash(bathsSubjectValue?.toString()),
  }

  const houseSizeValue = {
    label: 'House Size',
    maxLabel: '+ 20% SqFt',
    maxValue: isNumber(houseSizeSubjectValue)
      ? Math.floor(houseSizeSubjectValue * 1.2)
      : houseSizeSubjectValue,
    minLabel: '- 20% SqFt',
    minValue: isNumber(houseSizeSubjectValue)
      ? Math.floor(houseSizeSubjectValue * 0.8)
      : houseSizeSubjectValue,
    subjectValue: NumberLib.formatCODoDD(houseSizeSubjectValue),
  }

  const lotSizeValue = {
    label: 'Lot Size Acres',
    maxLabel: '+ 20% SqFt',
    maxValue: isNumber(lotSizeSubjectValue)
      ? Math.floor(lotSizeSubjectValue * 1.2)
      : lotSizeSubjectValue,
    minLabel: '- 20% SqFt',
    minValue: isNumber(lotSizeSubjectValue)
      ? Math.floor(lotSizeSubjectValue * 0.8)
      : lotSizeSubjectValue,
    subjectValue: NumberLib.formatCODoDD(lotSizeSubjectValue),
  }

  const setDefault = () => {
    void filterComps({
      ...yearBuiltValue
        ? { yearBuilt: { range: [yearBuiltValue.minValue, yearBuiltValue.maxValue] } }
        : {},
      ...bedsValue
        ? {
          bedroomsCount: {
            range: [bedsValue.minValue, bedsValue.maxValue],
            shouldMatchSubjectProperty: false,
          },
        }
        : {},
      ...bathsValue
        ? {
          bathroomsCount: {
            range: [bathsValue.minValue, bathsValue.maxValue],
            shouldMatchSubjectProperty: false,
          },
        }
        : {},
      ...houseSizeValue
        ? {
          livingAreaSqft: {
            range: [houseSizeValue.minValue, houseSizeValue.maxValue],
          },
        }
        : {},
      ...lotSizeValue
        ? {
          lotAreaSize: {
            sqftRange: [lotSizeValue.minValue, lotSizeValue.maxValue],
            unit: lotSizeUnit,
          },
        }
        : {},
    })
  }

  if (!isReady) return null

  return {
    values: [
      ...yearBuiltValue ? [yearBuiltValue] : [],
      ...bedsValue ? [bedsValue] : [],
      ...bathsValue ? [bathsValue] : [],
      ...houseSizeValue ? [houseSizeValue] : [],
      ...lotSizeValue ? [lotSizeValue] : [],
    ],
    setDefault,
  }
}
