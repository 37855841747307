import { Box, Flex, Text } from '@chakra-ui/react'
import { cancelable } from 'cancelable-promise'
import dayjs from 'dayjs'
import { stripParenthesis } from 'libs/string/stripParenthesis'
import _ from 'lodash/fp'
import { toast } from 'presentation/components/Toast'
import { CardButton, CardFooter, CardHeader } from 'presentation/components/molecules/Card'
import { ErrorForModal } from 'presentation/main/globalModals/ErrorModal/ErrorModal.errors'
import { PaymentDetailsModal } from 'presentation/main/globalModals/PaymentDetailsModal'
import { BillingSummaryItem } from 'presentation/main/globalModals/PaymentDetailsModal/BillingSummarySegment'
import { closeModal, goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'
import { PlanSwitcherDescription, PlanSwitcherProps } from 'presentation/screens/Billing/components/ChoosePlanFlow/PlanSwitcherDescription'
import { UpgradeToggle } from 'presentation/screens/Billing/components/ChoosePlanFlow/UpgradeToggle'
import { unmaskCurrentPlanFragment } from 'presentation/screens/Billing/components/ChoosePlanFlow/unmaskCurrentPlanFragment'
import { unmaskTargetProductFragment } from 'presentation/screens/Billing/components/ChoosePlanFlow/unmaskTargetProductFragment'
import { useApplyQuoteMutation } from 'presentation/screens/Billing/components/ChoosePlanFlow/useApplyQuoteMutation'
import { useChangeQuoteMutation } from 'presentation/screens/Billing/components/ChoosePlanFlow/useChangeQuoteMutation'
import { MEMBERS_REDIRECT_VALUE } from 'presentation/screens/MembersScreen/components/PlanCard/PlanCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, Fragment, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'
import { isNonNullable } from 'utils/isNonNullable'
import { ChoosePlanBillingSummary, ChoosePlanModalProps, CurrentPlan, TargetPlan } from './ChoosePlanModal.types'

export const ChoosePlanModal: FC<ChoosePlanModalProps> = ({
  isOpen = true,
  selectedPlanId,
  onClose,
  targetProduct: targetProductFragment,
  currentPlan: currentPlanFragment,
}) => {
  const navigate = useNavigate()
  /**
   * Mutations
   */
  const { applyQuote, loading: isMutationLoading } = useApplyQuoteMutation()
  const { changeQuote, loading: isSummaryLoading, wasCalled: wasSummaryCalled } = useChangeQuoteMutation()

  /**
   * Prepare fragments
   */
  const targetPlans = unmaskTargetProductFragment(targetProductFragment)
  const currentPlan = currentPlanFragment ? unmaskCurrentPlanFragment(currentPlanFragment) : undefined

  /**
   * Manage billing summary state
   */
  const [billingSummary, setBillingSummary] = useState<ChoosePlanBillingSummary | undefined>()

  /**
   * Manage options state
   */
  const defaultSelectedOption = targetPlans.find(t => t.planId === selectedPlanId)
  const [selectedPlanOption, setSelectedPlanOption] = useState<TargetPlan>(defaultSelectedOption ?? targetPlans[0])

  /**
   * onSuccess
   */

  // redirect to members if flow is triggered from members screen
  const [params] = useSearchParams()
  const redirect = params.get('redirect')
  const shouldRedirectToMembers = isNonNullable(redirect) && redirect === MEMBERS_REDIRECT_VALUE

  const onSuccess = (message: ReactNode) => {
    closeModal()

    // Give the modal time to close before navigating to prevent navigation issues
    setTimeout(() => {
      if (shouldRedirectToMembers)
        navigate('/user/team')
      else
        navigate('/user/billing')

      toast({
        status: 'success',
        title: 'That was a success!',
        message,
      })
    }, 100)
  }

  /**
   * handlePayNow
   */
  const onError = (error: Error) => openErrorModal({
    message: error.message,
    title: error instanceof ErrorForModal ? error.title : undefined,
    actionButtons: error instanceof ErrorForModal ? error.actionButtons : undefined,
  })

  /**
   * handlePayNow
   */
  const handlePayNow = () => {
    if (!billingSummary) throw new Error('No billing summary to pay')
    const quoteId = billingSummary.quote.id
    const amount = billingSummary.quote.totalAmount
    applyQuote(quoteId, amount)
      .then(onSuccess)
      .catch(onError)
  }

  /**
   * handleOnSelectedTargetChanged
   */
  const handleOnSelectedTargetChanged = (targetPlan: TargetPlan): void => {
    setSelectedPlanOption(targetPlan)
  }

  /**
   * Request quote to populate the modal
   */
  useEffect(() => {
    const promise = cancelable(changeQuote(selectedPlanOption.planId))
      .then(billingSummary => setBillingSummary(billingSummary))
      .catch(onError)

    return () => promise.cancel()
  }, [selectedPlanOption.planId])

  /**
   * Prepare Top Contents
   */
  const transition: PlanSwitcherProps['transition'] = !currentPlan
    ? 'subscribing'
    : isUpgrading(currentPlan, targetPlans, selectedPlanOption)
      ? 'upgrading'
      : 'downgrading'

  const planName = stripParenthesis(selectedPlanOption.planName)

  const modalHeader = (
    <CardHeader>
      <PlanSwitcherDescription
        planName={planName}
        transition={transition}
      />
      {shouldShowToggle(targetPlans, currentPlan) && (
        <Box
          mt={mbp({ mobSm: '2', tabSm: '3' })}
        >
          <Flex
            flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
            gap={mbp({ mobSm: '2', mob: '1', tabSm: '3' })}
          >
            <UpgradeToggle
              selectedPlanOption={selectedPlanOption}
              targetPlans={targetPlans}
              onSelectedTargetChanged={handleOnSelectedTargetChanged}
            />
          </Flex>
        </Box>
      )}
    </CardHeader>
  )

  /**
   * Prepare Terms and Policy Additional Info
   */
  const nextChargeDate = billingSummary?.nextChargeInfo.date
    ? dayjs(billingSummary.nextChargeInfo.date).format('MMM D, YYYY')
    : 'your next billing date'
  const nextChargeAmount = formatPenniesOptionalDecUsd(Math.max(
    selectedPlanOption?.price ?? 0,
    0,
  ))

  const termsAndPolicyAddionalInfo = (
    <Fragment>
      The next billing will be up to &nbsp;
      <Text
        as='span'
        textStyle={mbp({ mobSm: 'body2-h-b', mob: 'body-h-b' })}
        color='modal.text'
      >
        {nextChargeAmount}
      </Text>
      &nbsp; minus applicable discounts and credits, on &nbsp;
      <Text
        as='span'
        textStyle={mbp({ mobSm: 'body2-h-b', mob: 'body-h-b' })}
        display='inline'
        color='modal.text'
      >
        {nextChargeDate}
      </Text>
      .&nbsp;
    </Fragment>
  )

  /**
   * Prepare PaymentDetailsModal info
   */
  const billingSummaryItems: BillingSummaryItem[] = billingSummary
    ? billingSummary.quote.items.map(item => ({
      label: item.description,
      price: item.amount,
    }))
    : []

  const title = transition === 'downgrading'
    ? 'Downgrade Plan'
    : transition === 'upgrading'
      ? 'Upgrade Plan'
      : 'Choose Plan'

  return (
    <PaymentDetailsModal
      title={title}
      shouldShowChangePlanButton={false}
      isCardImageAlt={transition === 'downgrading'}
      modalHeader={modalHeader}
      isOpen={isOpen}
      onClose={onClose}
      billingSummaryItems={billingSummaryItems}
      billingSummaryTotal={billingSummary?.quote.totalAmount}
      termsAndPolicyAdditionalInfo={termsAndPolicyAddionalInfo}
      isBillingSummaryLoading={!wasSummaryCalled || isSummaryLoading}
      modalFooter={params => (
        <PrimaryActions
          isLoading={isMutationLoading || isSummaryLoading}
          hasPaymentMethod={params?.hasPaymentMethod}
          onClick={handlePayNow}
          isDowngrade={transition === 'downgrading'}
          totalAmount={billingSummary?.quote.totalAmount}
        />
      )}
    />
  )
}

type PrimaryActionsProps = {
  onClick?: () => void
  isLoading?: boolean
  hasPaymentMethod?: boolean
  isDowngrade?: boolean
  totalAmount?: number
}

const PrimaryActions: FC<PrimaryActionsProps> = ({
  onClick,
  isLoading,
  hasPaymentMethod,
  isDowngrade,
  totalAmount,
}) => {
  // If totalAmount is 0, it's a free subscription
  const isFreeSubscription = totalAmount === 0

  return (
    <CardFooter>
      <CardButton
        variant='outline'
        colorScheme='neutral'
        onClick={goBackModal}
      >
        Cancel
      </CardButton>
      <CardButton
        variant='solid'
        colorScheme={isDowngrade ? 'negative' : 'positive'}
        onClick={onClick}
        isDisabled={!hasPaymentMethod && !isFreeSubscription}
        isLoading={isLoading}
        loadingText='Processing'
        spinnerPlacement='end'
        autoFocus
      >
        {isDowngrade
          ? 'Confirm Downgrade'
          : isFreeSubscription
            ? 'Subscribe'
            : 'Pay Now'}
      </CardButton>
    </CardFooter>
  )
}

const isUpgrading = (
  currentPlan: CurrentPlan,
  targetPlans: TargetPlan[],
  selectedPlan: TargetPlan,
): boolean => {
  const planToCompare = targetPlans.find(plan =>
    plan.planId !== currentPlan.planId
    && plan.intervalUnit === currentPlan.intervalUnit)

  // If there is no plan to compare, it is the same plan. Compare it to what is
  // currently selected instead. There is no chance that the same plan with
  // the same interval is selected because that option is disabled.
  if (!planToCompare) return selectedPlan.price > currentPlan.price
  return planToCompare.price > currentPlan.price
}

const shouldShowToggle = (
  targetPlans: TargetPlan[],
  currentPlan?: CurrentPlan,
): boolean => {
  // if there is no current plan, then we allow them to subscribe to any plan
  if (!currentPlan) return true

  return _.every((p: TargetPlan) => p.planId !== currentPlan?.planId)(targetPlans)
}
