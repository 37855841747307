import { isNumber, isString } from 'remeda'

export const formatCurrency = (cur: string) => (value: string | number) => {
  const isNegative = (isString(value) && value.startsWith('-'))
    || (isNumber(value) && value < 0)
  const absoluteNumber = isString(value) ? value.replace('-', '') : Math.abs(value)

  return [isNegative ? '-' : '', cur, absoluteNumber].join('')
}

export const formatUsd = formatCurrency('$')
