import { Button, ButtonProps, IconButton, Text, VStack } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { EraserIcon, PenIcon } from 'presentation/components/Icons'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { useCompsBoundaryLinesFromFilters } from 'presentation/screens/CompsScreen/components/CompsBoundary/useCompsBoundaryLinesFromFilter'

export const CompsBoundaryButtons = ({
  wrapButton,
}: {
  wrapButton: (button: JSX.Element) => JSX.Element
}) => {
  const status = CompsBoundary.useStore(state => state.status)
  const hasLinesFromDrawer = CompsBoundary.useStore(state => state.lines.length > 0)
  const linesFromFilters = useCompsBoundaryLinesFromFilters()
  const hasLinesFromFilters = linesFromFilters.length > 0
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  if (status === 'idle' && !hasLinesFromFilters) {
    return wrapButton(
      <DrawBoundaryButton
        onClick={() => {
          CompsBoundary.setStatus('drawing')
          CompsBoundary.setLines(linesFromFilters)
        }}
      />,
    )
  }

  if (status === 'idle' && hasLinesFromFilters) {
    return (
      <>
        {wrapButton(
          <DrawBoundaryButton
            hasExistingLines
            onClick={() => {
              CompsBoundary.setStatus('drawing')
              CompsBoundary.setLines(linesFromFilters)
            }}
          />,
        )}

        {wrapButton(
          <ClearBoundaryButton
            onClick={() => {
              CompsBoundary.clearLines()
              void filterComps({ boundary: null })
            }}
          />,
        )}

      </>
    )
  }

  if (status === 'drawing' && hasLinesFromDrawer) {
    return wrapButton(
      <ClearBoundaryButton
        onClick={() => {
          CompsBoundary.clearLines()
        }}
      />,
    )
  }

  if (status === 'drawing' && !hasLinesFromDrawer)
    return null

  return null
}

export const DrawBoundaryButton = ({
  hasExistingLines,
  ...props
}: ButtonProps & {
  hasExistingLines?: boolean
}) => (
  <Breakpoint
    mobSm={(
      <IconButton
        boxSize={6}
        bgColor='special.500'
        borderRadius='full'
        shadow='float'
        aria-label='Draw Boundary'
        fontSize={3}
        color='ondark.1'
        {...props}
      >
        <VStack align='center' justify='center' spacing={0}>
          <PenIcon boxSize={3} color='ondark.1' display='block' />
          <Text
            textStyle='tagS'
            color='ondark.1'
            textTransform='none'
          >
            {hasExistingLines ? 'Edit' : 'Draw'}
          </Text>
        </VStack>
      </IconButton>
    )}
    tabSm={(
      <Button
        w='full'
        size='md'
        colorScheme='special'
        variant='solid'
        leftIcon={<PenIcon fontSize={3} />}
        {...props}
      >
        {hasExistingLines ? 'Edit Boundary' : 'Draw Boundary'}
      </Button>
    )}
  />
)

export const ClearBoundaryButton = (props: ButtonProps) => (
  <Breakpoint
    mobSm={(
      <IconButton
        bg='card.bg.1'
        color='negative.500'
        borderColor='negative.500'
        borderWidth={0.25}
        boxSize={6}
        borderRadius='full'
        shadow='float'
        aria-label='Draw Boundary'
        fontSize={3}
        {...props}
      >
        <VStack align='center' justify='center' spacing={0}>
          <EraserIcon boxSize={3} display='block' />
          <Text
            textStyle='tagS'
            textTransform='none'
            color='inherit'
          >
            Clear
          </Text>
        </VStack>
      </IconButton>
    )}
    tabSm={(
      <Button
        w='full'
        size='md'
        colorScheme='negative'
        variant='outline'
        leftIcon={<EraserIcon fontSize={3} />}
        bg='card.bg.1'
        {...props}
      >
        Clear Boundary
      </Button>
    )}
  />
)
