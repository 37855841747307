import { BoxProps, Grid, Image } from '@chakra-ui/react'
import HomeVestorsLogo from 'presentation/assets/img/logo/affiliates/homevestors-logo.png'
import KWLogo from 'presentation/assets/img/logo/affiliates/keller-williams-logo.png'
import KeyGleeLogo from 'presentation/assets/img/logo/affiliates/keyglee-logo.png'
import TitaniumLogo from 'presentation/assets/img/logo/affiliates/titanium-investments-logo.png'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'

export const AffiliationLogos = (props: BoxProps) => {
  const logoHeight = useSwitchBreakpoint({
    dtSm: 2.5,
    dtLg: 3,
  }) ?? 3
  return (
    <Grid
      gap={5}
      gridTemplateColumns='repeat(4, 1fr)'
      {...props}
    >
      <Image src={HomeVestorsLogo} h={logoHeight} />
      <Image src={TitaniumLogo} h={logoHeight} />
      <Image src={KeyGleeLogo} h={logoHeight} />
      <Image src={KWLogo} h={logoHeight} />
    </Grid>
  )
}
