import { GetBuyerDealsQuery } from '__generated__/graphql'
import { Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import GetBuyerDeals from 'features/NearbyBuyers/repository/GetBuyerDeals'
import { dealsToCountyGeographicInfo, partialDealFromRawDeal, propertyFromRawParcel } from 'features/NearbyBuyers/repository/GetNearbyBuyers.schema'

namespace GetBuyerDealsSchema {
  // @TODO get parcel from node.parcel or node.buyer.buyerDetails.deals.parcel
  export const resultFromGraphQLResult = (raw: GetBuyerDealsQuery): GetBuyerDeals.ResultEffect => {
    if (raw.parcelContact.__typename === 'ParcelContactNotFoundError')
      return Effect.fail(new UnknownException(raw.parcelContact.message))

    if (raw.parcelContact.__typename !== 'ParcelContact')
      return Effect.fail(new UnknownException('Unexpected response from server'))

    const deals = raw.parcelContact.buyerDetails?.historicalDeals.edges.map(edge => {
      const rawDeal = edge.node
      const rawParcel = rawDeal.parcel
      const deal = partialDealFromRawDeal(rawDeal)
      const property = propertyFromRawParcel(rawParcel)
      return {
        ...deal,
        property,
      }
    }) || []

    const dealsGeographicInfo = dealsToCountyGeographicInfo(deals)

    return Effect.succeed(GetBuyerDeals.Result.Success({
      deals,
      dealsGeographicInfo,
    }))
  }
}

export default GetBuyerDealsSchema
