import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { PropertyMarkerInfo } from 'features/valueObjects/PropertyMarkerInfo'

export type MarkerInfo = PropertyMarkerInfo
export const EMPTY_MARKER_INFO: MarkerInfo = {
  classification: null,
  equityType: null,
  isForeclosure: false,
  isSenior: false,
  isVacant: false,
}

export const getMarkerInfoFromProperty = (
  domain: Domain.Property,
): MarkerInfo =>
  domain.status === 'without-details'
    ? EMPTY_MARKER_INFO
    : {
      classification: domain.ownership?.classification || null,
      equityType: domain.valuation?.equityType || null,
      isForeclosure: domain.ownership?.isForeclosure || false,
      isSenior: domain.ownership?.isSenior || false,
      isVacant: domain.ownership?.isVacant || false,
    }
