import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { DomainError, ErrorLib, FieldsError, ProgramException, ReportableException } from 'libs/errors'
import { WithId, WithoutId } from 'libs/id'
import { useCallback } from 'react'

const MemberScreen_UpdateMemberNameCommand = gql(/* GraphQL */ `
  mutation MemberScreen_UpdateMemberNameCommand($id: ID!, $firstname: String, $lastname: String) {
    userUpdateName(id: $id, firstname: $firstname, lastname: $lastname) {
      __typename

      ... on User {
        id

        enterprise {
          ...EnterpriseMembers
        }
      }
      ... on UserInvalidInputError {
        message
        field
      }
    }
  }
`)

type __UpdateMemberNamePayload = WithId<{ firstname?: string, lastname?: string }>
type __UpdateMemberNameError = FieldsError<WithoutId<__UpdateMemberNamePayload>>
type __UpdateMemberNameCommand = Command.PromiseVersion<__UpdateMemberNameError, __UpdateMemberNamePayload>

/**
 * @NOTE Error management is weird because use case is weird -- we're looking to
 *   facade multiple commands including this under a single command
 */
export const useUpdateMemberNameCommand = (): __UpdateMemberNameCommand => {
  const [updateMemberName] = useMutation(MemberScreen_UpdateMemberNameCommand)
  const { transition } = CQReact.useStateManager<__UpdateMemberNameError, __UpdateMemberNamePayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<__UpdateMemberNameCommand['execute']>(async payload => {
    const commandId = regenerateId()

    return await updateMemberName({ variables: payload })
      .then(result => {
        const { data } = result

        if (!data) {
          throw new ReportableException('Unexpected response', {
            extraInfo: { result },
          })
        }

        if (data.userUpdateName.__typename === 'UserInvalidInputError') {
          const serverErr = data.userUpdateName

          const firstNameError = new DomainError(
            serverErr.field === 'firstname'
              ? serverErr.message
              : 'An error occurred while updating the member first name',
          )

          const lastNameError = new DomainError(
            serverErr.field === 'lastname'
              ? serverErr.message
              : 'An error occurred while updating the member last name',
          )

          throw new FieldsError<WithoutId<__UpdateMemberNamePayload>>(
            '',
            {
              firstname: firstNameError,
              lastname: lastNameError,
            },
          )
        }

        return transition.success({ id: commandId, payload })
      })
      .catch(rawErr => {
        void ErrorLib.report(rawErr)

        const error = rawErr instanceof FieldsError
          ? rawErr
          : new FieldsError<WithoutId<__UpdateMemberNamePayload>>(
            '',
            {
              firstname: new ProgramException('An error occurred while updating the member first name'),
              lastname: new ProgramException('An error occurred while updating the member last name'),
            },
          )

        return transition.error({ id: commandId, error, payload })
      })
  }, [])

  return {
    execute,
  }
}
