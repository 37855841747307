import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { NumberLib } from 'libs/Number'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { EmptyCard } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/EmpyCard'
import { TableSectionCommon } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.common'

/** @TODO Simplify mapping, do all mapping in one place */
export const PropertyInfoTabContent = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const building = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    const building = property.building

    return building
  })

  const pairs: [string, string][] | null = !building
    ? null
    : [
      ['Bedrooms', TableSectionCommon.formatNumber(building?.bedroomsCount)],
      ['Full Baths', TableSectionCommon.formatNumber(building?.bathroomsCount?.full)],
      ['Half Baths', TableSectionCommon.formatNumber(building?.bathroomsCount?.half)],
      ['Garage Spaces', TableSectionCommon.formatNumber(building?.garageSpacesCount)],
      ['Total Rooms', TableSectionCommon.formatNumber(building?.roomsCount)],
      ['Living Sqft', NumberLib.formatCODoDD(building?.livingAreaSqft)],
      ['Total Sqft', NumberLib.formatCODoDD(building?.buildingAreaSqft)],
      ['Garage Sqft', NumberLib.formatCODoDD(building?.garageAreaSqft)],
      ['No. of Stories', TableSectionCommon.formatNumber(building?.storiesCount)],
      ['Basement Type', building?.basementType
        ? TableSectionCommon.formatString(building?.basementType)
        : building?.basementAreaSqft && building?.basementAreaSqft > 0
          ? '--'
          : 'None'],
      ['Basement Sqft', building?.basementAreaSqft
        ? NumberLib.formatCODoDD(building?.basementAreaSqft)
        : 'N/A'],
      ['Fireplaces', TableSectionCommon.formatNumber(building?.fireplacesCount)],
      ['Pools', TableSectionCommon.formatBoolean(building?.hasPool)],
      ['Heating Type', TableSectionCommon.formatString(building?.heatingType)],
      ['Fuel Type', TableSectionCommon.formatString(building?.heatingFuelType)],
      ['Year Built', TableSectionCommon.formatString(building?.yearBuilt)],
    ]

  return (
    <TabbedCards.TabContent>
      {pairs === null
        ? (
          <EmptyCard />
        )
        : (
          <TabbedCards.CardSection
            label={null}
            upperSegmentText='Public record'
            pairs={pairs}
          />
        )}
    </TabbedCards.TabContent>
  )
}
