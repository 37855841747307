import { chakra } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export const FreeAndClearMarker = () => (
  <EquityMarkerContainer>
    <chakra.title>Equity Marker: Free and Clear</chakra.title>
    <chakra.path
      d='M49.8392964,22.1699197 C50.0619262,24.1239162 50.0529285,26.050922 49.8341281,27.9234999 L40.8949753,26.8787284 C41.0365683,25.666736 41.0388341,24.4322969 40.8971497,23.1887486 C39.8968232,14.4089952 31.9685019,8.10252377 23.1887486,9.10285031 C14.4089952,10.1031768 8.10252377,18.0314981 9.10285031,26.8112514 L0.160703608,27.8300803 C-1.40230661,14.1117158 8.45155506,1.72371382 22.1699197,0.160703608 C35.8882842,-1.40230661 48.2762862,8.45155506 49.8392964,22.1699197 Z'
      fill='positivesat.600'
      fillRule='nonzero'
    />
  </EquityMarkerContainer>
)

export const HighEquityMarker = () => (
  <EquityMarkerContainer>
    <chakra.title>Equity Marker: High Equity</chakra.title>
    <chakra.path
      d='M44.8274432,9.76616607 L37.6959906,15.256374 C34.2608403,10.7939997 28.7368224,8.42101869 23.0500905,9.11926157 C14.2794009,10.1961669 8.0423563,18.1792198 9.11926157,26.9499095 L0.186346209,28.0467336 C-1.49631829,14.342531 8.24906384,1.8690107 21.9532664,0.186346209 C31.0540213,-0.931085988 39.6120345,2.99135144 44.8274432,9.76616607 Z'
      fill='teal.700'
      fillRule='nonzero'
    />
  </EquityMarkerContainer>
)

export const LowEquityMarker = () => (
  <EquityMarkerContainer>
    <chakra.title>Equity Marker: Low Equity</chakra.title>
    <chakra.path
      d='M24.8983134,8.99798839 C24.285181,9.00307472 23.6686096,9.04331698 23.0500905,9.11926157 C14.2794009,10.1961669 8.0423563,18.1792198 9.11926157,26.9499095 L0.186346209,28.0467336 C-1.49631829,14.342531 8.24906384,1.8690107 21.9532664,0.186346209 C22.9169704,0.0680182428 23.8745884,0.00620371626 24.822796,-0.00169480886 L24.8983134,8.99798839 Z'
      fill='trueorange.500'
      fillRule='nonzero'
    />
  </EquityMarkerContainer>
)

export const UpsideDownMarker = () => (
  <EquityMarkerContainer>
    <chakra.title>Equity Marker: Upside Down</chakra.title>
    <chakra.path
      d='M4.98325062,10.022302 L12.1884399,15.4153761 C9.72664687,18.704611 8.60827009,22.7882178 9.11926157,26.9499095 L0.186346209,28.0467336 C-0.632149878,21.3806179 1.25337563,15.0056973 4.98325062,10.022302 Z'
      fill='negative.500'
      fillRule='nonzero'
    />
  </EquityMarkerContainer>
)

const EquityMarkerContainer = ({
  children,
}: PropsWithChildren) => (
  <chakra.svg
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    stroke='none'
    strokeWidth='1'
    fill='none'
    fillRule='evenodd'
    filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
  >
    {children}
  </chakra.svg>
)
