import { Box, Center, Circle, HStack, Spinner, Stack, Text, VStack } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { toast } from 'presentation/components/Toast'
import { Card } from 'presentation/components/molecules/Card'
import { CompsCoverageSvgBox } from 'presentation/screens/CompsScreen/components/CompsCoverage/CompsCoverageSvgBox'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'

export const CompsCoverage = () => {
  const state = useCMAStore(state => state.local.coverageInfo)
  const getCoverageInfo = useCMAStore(state => state.actions.getCoverageInfo)

  useEffect(() => {
    void getCoverageInfo.execute()
  }, [])

  // Toast when error
  useEffect(() => {
    if (state.status !== 'error') return

    toast.error({
      title: 'Failed to load coverage map',
      message: 'Please try again or contact support.',
    })
  }, [state.status])

  return (
    <VStack flex='1' minH='full' align='stretch' spacing={0}>
      <Text
        textStyle={mbp({
          tabSm: 'h3',
          mob: 'h4',
          mobSm: 'bodyXLFat',
        })}
        color='graystrong.500'
        textAlign='center'
        p={4}
      >
        Looks like we do not have coverage in this area. Please see the map below for our current coverage.
      </Text>

      <Box
        flex={1}
        flexGrow={1}
        flexShrink={0}

        display='flex'
        flexDirection='column'

        minH='0'
        p={mbp({
          dtSm: 4,
          mob: 3,
          mobSm: 1,
        })}
        pt={0}
        flexBasis={mbp({
          dtLg: '764px',
          dtSm: '496px',
          tabSm: '688px',
          mob: '533px',
          mobSm: '351px',
        })}
      >

        <Card
          flex='1'

          display='flex'
          flexDirection='column'

          borderRadius={3}
          p={0}

          overflow='hidden'
          pos='relative'
        >
          {state.status !== 'loaded'
            ? (
              <Center flex='1'>
                <Spinner color='grayweak.900' />
              </Center>
            )
            : (() => (
              <>
                <Box
                  flex='1'
                  pos='relative'
                >
                  <Box
                    pos='absolute'
                    top={mbp({ tabSm: 6, mobSm: 1 })}
                    left={mbp({ tabSm: 6, mobSm: 1 })}
                    bottom={mbp({ tabSm: 6, mobSm: 1 })}
                    right={mbp({ tabSm: 6, mobSm: 1 })}
                  >
                    <CompsCoverageSvgBox data={state.data} />
                  </Box>
                </Box>

                <Stack
                  pos='absolute'
                  left={mbp({
                    tabSm: 3.5,
                    mobSm: 2,
                  })}
                  right={mbp({
                    tabSm: 'initial',
                    mobSm: '2',
                  })}
                  bottom={mbp({
                    tabSm: 3.5,
                    mobSm: 2,
                  })}
                  borderRadius={3}
                  bg='highlight.100'
                  boxShadow='float'
                  spacing={mbp({
                    tabSm: 2,
                    mobSm: 1,
                  })}
                  px={mbp({
                    mob: 2,
                    mobSm: 1,
                  })}
                  py={mbp({
                    tabSm: 3,
                    mobSm: 2,
                  })}
                  direction={mbp({
                    tabSm: 'column',
                    mobSm: 'row',
                  })}
                  align={mbp({
                    tabSm: 'flex-start',
                    mobSm: 'center',
                  })}
                  justify='space-between'
                >
                  {([
                    ['neutral.500', (
                      <Breakpoint
                        key='covered-areas'
                        mob='Property Data & Mobile D4$'
                        mobSm={(
                          <>
                            Property Data & Mobile D4$
                          </>
                        )}
                      />
                    )],
                    ['highlight.500', (
                      <Breakpoint
                        key='working-for-expansion'
                        mob='MLS Comps + Property Data & Mobile D4$'
                        mobSm={(
                          <>
                            MLS Comps + Property Data & Mobile D4$
                          </>
                        )}
                      />
                    )],
                  ] satisfies [string, JSX.Element][]).map(([color, label], i) => (
                    <HStack
                      key={i}
                      spacing={mbp({
                        tabSm: 1,
                        mobSm: 0.5,
                      })}
                    >
                      <Circle
                        size={mbp({
                          tabSm: 3,
                          mobSm: 2,
                        })}
                        boxShadow='float'
                        bg={color}
                      />
                      <Text
                        textStyle={mbp({
                          tabSm: 'bodySFat',
                          mobSm: 'tagM',
                        })}
                        color='graystrong.400'
                      >
                        {label}
                      </Text>
                    </HStack>
                  ))}
                </Stack>
              </>
            ))()}
        </Card>
      </Box>
    </VStack>
  )
}
