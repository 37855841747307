import { BoxProps, Center, Flex, Icon, IconProps, Text } from '@chakra-ui/react'
import { HashLink } from 'presentation/components/HashLink'
import { useIsScrollSpySectionActive } from 'presentation/components/ScrollSpy'
import { BILLING_SECTION_IDS } from 'presentation/screens/Billing/Billing.const'
import { emitTabChangeEvent } from 'presentation/screens/Billing/components/BillingHistorySection/billingHistorySectionTabEvents'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { ComponentType, FC } from 'react'
import { NavLink } from 'react-router-dom'
import { CreditCardIcon, DownRightIcon, TwoPersonsIcon } from '../Icons'

export const UserSidebarNav: FC<{
  onSelect?: () => void
}> = props => {
  const handleViewTransactions = () => {
    emitTabChangeEvent('payments')
    props.onSelect?.()
  }
  return (
    <Flex pr='2' py='2.5' flexDir='column'>
      <SidebarItem onClick={props.onSelect} icon={TwoPersonsIcon} title='Team Members' to='/user/team' />
      <SidebarItem onClick={props.onSelect} icon={CreditCardIcon} title='Plans & Billing' to='/user/billing' />

      <SidebarSubItem
        onClick={props.onSelect}
        isHash
        title='Plans'
        to={`/user/billing#${BILLING_SECTION_IDS.plans}`}
      />

      <SidebarSubItem
        onClick={props.onSelect}
        isHash
        title='Marketing Funds'
        to={`/user/billing#${BILLING_SECTION_IDS.funds}`}
      />

      <SidebarSubItem
        onClick={props.onSelect}
        isHash
        title='Secure Payment Info'
        to={`/user/billing#${BILLING_SECTION_IDS.payment}`}
      />

      <SidebarSubItem
        onClick={handleViewTransactions}
        isHash
        title='Billing History'
        to={`/user/billing#${BILLING_SECTION_IDS.history}`}
      />
    </Flex>
  )
}

const SidebarItem: FC<{
  icon: ComponentType<IconProps>
  title: string
  iconSize?: BoxProps['fontSize']
  to: string
  onClick?: () => void
}> = props => (
  <NavLink to={props.to} onClick={props.onClick}>
    {({ isActive }) => (
      <Flex
        alignItems='center'
        pl='4'
        my='0.5'
        py='1'
        borderRightRadius='full'
        bg={isActive ? 'accent.lightpurple-to-gray' : 'transparent'}
        _hover={{ bg: 'accent.lightpurple-to-gray' }}
        transitionProperty='background'
        transitionDuration='0.2s'
        role='group'
      >
        <Center boxSize='3'>
          <Icon
            as={props.icon}
            fontSize={props.iconSize || 3}
            color={isActive ? 'accent.purple-to-gray' : 'graystrong.200'}
            _groupHover={{ color: 'accent.purple-to-gray' }}
          />
        </Center>
        <Text
          textStyle='panel-title'
          color={isActive ? 'accent.purple-to-gray' : 'graystrong.200'}
          _groupHover={{ color: 'accent.purple-to-gray' }}
          ml='1'
        >
          {props.title}
        </Text>
      </Flex>
    )}
  </NavLink>
)

const SidebarSubItem: FC<{
  title: string
  iconSize?: BoxProps['fontSize']
  to: string
  onClick?: () => void
  isHash?: boolean
}> = props => {
  const Link = props.isHash ? HashLink : NavLink
  const hash = props.to.split('#')[1]
  const isScrolledTo = useIsScrollSpySectionActive(hash)

  return (
    <Link to={props.to} onClick={props.onClick}>
      <Flex
        alignItems='center'
        pl='4'
        my='0.5'
        py='1'
        borderRightRadius='full'
        transitionProperty='background'
        transitionDuration='0.2s'
        role='group'
        _hover={
          IS_TOUCH_DEVICE
            ? {}
            : {
              bg: 'accent.lightpurple-to-gray',
            }
        }
        bg={isScrolledTo ? 'accent.lightpurple-to-gray' : 'transparent'}
      >
        <Center boxSize='3'>
          <Icon
            as={DownRightIcon}
            fontSize='2'
            color={isScrolledTo ? 'accent.purple-to-gray' : 'graystrong.200'}
            _groupHover={
              IS_TOUCH_DEVICE
                ? {}
                : {
                  color: 'accent.purple-to-gray',
                }
            }
          />
        </Center>
        <Text
          textStyle='panel-title'
          ml='1'
          color={isScrolledTo ? 'accent.purple-to-gray' : 'graystrong.200'}
          _groupHover={
            IS_TOUCH_DEVICE
              ? {}
              : {
                color: 'accent.purple-to-gray',
              }
          }
        >
          {props.title}
        </Text>
      </Flex>
    </Link>
  )
}
