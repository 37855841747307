import { useSelector } from '@xstate/react'
import { Equal } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'

type PlanIntervalSwitcherViewModel = {
  isYearly: boolean
  toggleInterval: () => void
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const isYearly = useSelector(
    actor,
    snapshot => snapshot.context.isYearly,
    Equal.equals,
  )
  const vm: PlanIntervalSwitcherViewModel = {
    isYearly,
    toggleInterval: () => {
      actor.send({ type: 'toggle-interval' })
    },
  }

  return vm
}

const PlanIntervalSwitcherViewModel = {
  usePresenter,
}

export default PlanIntervalSwitcherViewModel
