import { MapStoreContext } from 'presentation/components/Map/MapStoreProvider'
import { MapStore } from 'presentation/components/Map/createMapStore'
import { useContext } from 'react'
import { useStore } from 'zustand'

export type UseMapStore = <T>(
  selector: (state: MapStore) => T,
  equalifyFn?: (a: T, b: T) => boolean,
) => T

export const useMapStore: UseMapStore = (selector, equalifyFn) => {
  const store = useContext(MapStoreContext)
  if (!store)
    throw new Error('Missing MapStoreProvider')

  return useStore(store, selector, equalifyFn)
}
