import { PartialRange } from 'features/valueObjects/Range'
import { GarageFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/GarageFilterCard/GarageFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { MatchSubjectCheckbox } from 'presentation/screens/CompsScreen/components/common/MatchSubjectCheckbox/MatchSubjectCheckbox'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'

export const GarageFilterCardDumb = (
  props: GarageFilterCardDumbProps,
) => {
  const subjectGarageSpacesCount = props.subjectValue.garageSpacesCount
  const isMatchingSubject = props.subjectValue !== null
    && props.value.range?.[0] === props.subjectValue.garageSpacesCount
    && props.value.range?.[1] === props.subjectValue.garageSpacesCount

  return (
    <FilterCard
      title='Garage'
      hasBadge={PartialRange.checkHasValueOptional(props.value.range)}
      toolbar={(
        <MatchSubjectCheckbox
          isChecked={isMatchingSubject}
          onChange={() => {
            props.onChange({
              range: isMatchingSubject
                ? [null, null]
                : [subjectGarageSpacesCount, subjectGarageSpacesCount],

              /** @GENESIS */
              shouldMatchSubjectProperty: false,
              shouldExcludeAll: false,
              type: null,
            })
          }}
        />
      )}
    >
      {/* @GENESIS */}
      {/* <ExcludeAllCheckbox
        label='garages'
        isChecked={props.value.shouldExcludeAll}
        onChange={handleOnExcludeAllChange}
        isDisabled={props.value.shouldMatchSubjectProperty}
        isSameAsSubject={props.value.shouldExcludeAll === props.subjectValue.shouldExcludeAll}
      />
      <FormControl>
        <FormLabel>Type</FormLabel>
        <SelectMenu isOpen={props.isGarageMenuOpen}>
          <SelectMenuButton
            w='full'
            isDisabled={props.value.shouldMatchSubjectProperty}
            onClick={handleOnOpenChange}
          >
            <ValueWithSubjectPropertyMarker
              shouldShowMarker={garageType === subjectGarageType}
            >
              {selectedValue}
            </ValueWithSubjectPropertyMarker>
          </SelectMenuButton>
          <SelectMenuList>
            {GarageType.toArray().map((value, index) => (
              <SelectMenuItem
                key={index}
                onClick={() => handleOnTypeChange(value)}
              >
                <ValueWithSubjectPropertyMarker
                  shouldShowMarker={value === subjectGarageType}
                >
                  {GarageType.toString(value)}
                </ValueWithSubjectPropertyMarker>
              </SelectMenuItem>
            ))}
          </SelectMenuList>
        </SelectMenu>
      </FormControl> */}

      <PartialRangeInputV2
        initialValue={props.value.range}
        onDebouncedValidChange={newRange => {
          props.onChange({
            range: newRange,

            /** @GENESIS */
            shouldMatchSubjectProperty: false,
            shouldExcludeAll: false,
            type: null,
          })
        }}
        subjectValue={subjectGarageSpacesCount?.toString()}
      />

      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )
}
