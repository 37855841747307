import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { thumbsUpOnly } from 'features/CMA/CMA.helpers'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { CMADataUpdateMeta } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { CMAReport } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport'
import { CMANoPropertiesSelected } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMANoPropertiesSelected'
import { SubjectAPNPill } from 'presentation/screens/CompsScreen/components/SubjectAPNPill'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { shallow } from 'zustand/shallow'

export const CMAFullscreenReport = () => {
  const report = useCMAStore(api => ({
    totalComps: checkStateHasReportData(api.local.report)
      ? api.local.report.data.salesListInfo.comps.filter(thumbsUpOnly).length
      + api.local.report.data.rentalsListInfo.comps.filter(thumbsUpOnly).length
      : 0,
    hasNoData: !checkStateHasReportData(api.local.report),
  }), shallow)

  if (report.hasNoData)
    return <Box p={3} h='full'><CMANoPropertiesSelected /></Box>

  return (
    <Flex
      flexDirection='column'
      h='full'
      minH={0}
      maxW='1376px'
      mx='auto'
    >
      <Flex flexDirection='column' px={3} pt={3}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex h={5} alignItems='center' gap={1}>
            <Text
              textStyle='h4'
              color='accent.blue-text'
            >
              Comparative Market Analysis
            </Text>
          </Flex>

          <HStack align='center' spacing={1}>
            <CMADataUpdateMeta />
            <SubjectAPNPill />
          </HStack>
        </Flex>
      </Flex>

      <CMAReport
        pt={2}
        px={mbp({ mobSm: 1, mob: 2, tabSm: 3 })}
        pb={mbp({ mobSm: 7, tabSm: 2 })}
      />
    </Flex>
  )
}
