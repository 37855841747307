import { useColorMode, useTheme } from '@chakra-ui/react'
import { useEffect } from 'react'

export const useColorSchemeHandler = () => {
  const themeVariant = useTheme()
  const colorMode = useColorMode()

  useEffect(() => {
    colorMode.setColorMode(themeVariant.id)
    document.querySelector('meta[name=\'theme-color\']')
      ?.setAttribute('content', themeVariant.colors['special.500'])
  }, [themeVariant])
}
