import { createIcon } from '@chakra-ui/react'

export const MultiStarIcon = createIcon({
  displayName: 'MultiStarIcon',
  viewBox: '0 0 56 57',
  path: (
    <g stroke='none' fill='none'>
      <path d='M54.25 20.6582H34.2125L28 2.2832L21.7875 20.6582H1.75L17.9375 32.0332L11.725 50.4082L28 39.0332L44.1875 50.4082L37.975 32.0332L54.25 20.6582Z' fill='#FFCE31' />
      <path d='M40.4254 18.2958L43.9254 8.3208L34.7379 14.6208L36.0504 18.2958H40.4254ZM24.4129 44.2833L28.0004 54.7833L31.5879 44.2833L28.0004 41.8333L24.4129 44.2833ZM44.3629 30.5458L41.0379 32.8208L42.4379 37.0208H53.7254L44.3629 30.5458ZM21.2629 14.6208L12.0754 8.3208L15.5754 18.2958H19.9504L21.2629 14.6208ZM11.6379 30.5458L2.27539 37.0208H13.5629L14.9629 32.8208L11.6379 30.5458Z' fill='#FFDF85' />
    </g>
  ),
})
