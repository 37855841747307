import { Effect, Layer, Match } from 'effect'
import { PropertyList } from 'features/ListBuilder/domain/ListSource'
import ListSourceRepository from 'features/ListBuilder/domain/repository/ListSourceRepo'
import BuildPropertyListResolver from 'features/ListBuilder/infra/repo/ListSourceRepoLive/BuildPropertyListResolver/BuildPropertyListResolver'
import GetBuildTotalResolver from 'features/ListBuilder/infra/repo/ListSourceRepoLive/GetBuildTotalResolver/GetBuildTotalResolver'
import ListSourceRequest from 'features/ListBuilder/infra/repo/ListSourceRepoLive/ListSourceRequest'

const ListSourceRepoLive = Layer.succeed(
  ListSourceRepository,
  {
    buildList: criteria => Match.value(criteria).pipe(
      Match.tag('PropertyListCriteria', c => buildPropertyList(c)),
      Match.exhaustive,
    ),
    getBuildTotal: criteria => Effect.request(
      new ListSourceRequest.GetBuildTotal({ criteria }),
      GetBuildTotalResolver,
    ),
  },
)

export default ListSourceRepoLive

const buildPropertyList: ListSourceRepository.BuildList<PropertyList> = criteria => Effect.request(
  new ListSourceRequest.BuildList<PropertyList>({ criteria }),
  BuildPropertyListResolver,
)
