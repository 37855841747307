import { modals } from 'presentation/main/modals'

/**
 * @IMPORTANT Almost always, goBackModal is better. You rarely need to close
 * modal and clear the history.
 */
export const closeModal = (): void => modals.emitter.emit({ type: 'CLOSE', payload: null })

/**
 * @TODO Rename to something more descriptive like moveOnToNextQueuedModal or moveOn
 */
export const goBackModal = (): void => modals.emitter.emit({ type: 'BACK', payload: null })
