import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, BoxProps, ComponentWithAs, Flex, IconProps, Text } from '@chakra-ui/react'

export type SkiptraceSubAccordionItemProps = {
  hasNew: boolean
  isEmpty: boolean
  headerLabel: string
  headerIcon: ComponentWithAs<'svg', IconProps>
} & BoxProps

export const SkiptraceSubAccordionItem = (props: SkiptraceSubAccordionItemProps) => {
  const {
    hasNew,
    headerLabel,
    headerIcon: HeaderIcon,
    children,
    isEmpty,
    ...boxProps
  } = props
  return (
    <AccordionItem
      sx={{
        '&:last-of-type': {
          borderBottomWidth: 0,
          borderTopWidth: 0,
        },
      }}
      shadow='primary.w'
      borderRadius={2}
      isDisabled={isEmpty}
      {...boxProps}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            bgColor='card.bg.blue'
            borderTopRadius={2}
            borderBottomRadius={isExpanded ? 0 : 2}
            p={0}
            _hover={{
              bgColor: 'neutral.400',
            }}
          >
            <Flex alignItems='center' p={1} gap={1}>
              <Box p={0.5}>
                <AccordionIcon boxSize={3} color='ondark.1' />
              </Box>
              {hasNew && <Indicator />}
              <HeaderIcon boxSize={3} color='ondark.1' />
              <Text
                textStyle='bodyMFat'
                color='ondark.1'
                textAlign='left'
              >
                {headerLabel}
              </Text>
            </Flex>
          </AccordionButton>
          <AccordionPanel
            bgColor='grayweak.100'
            borderBottomRadius={2}
            p={0}
            overflow='hidden'
          >
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

const Indicator = (props: BoxProps) => (
  <Box
    borderRadius='100px'
    bgColor='positive.500'
    minW={1.5}
    w={1.5}
    h={1.5}
    {...props}
  />
)
