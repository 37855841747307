import { createIcon } from '@chakra-ui/react'

export const LongArrowUpIcon = createIcon({
  displayName: 'LongArrowUpIcon',
  viewBox: '0 0 9 15',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 4.54545L4.49998 1M4.49998 1L8.00004 4.54545M4.49998 1V14' stroke='currentColor' strokeWidth='1.3' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
