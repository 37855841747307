import { Grid, VStack } from '@chakra-ui/react'
import { Array } from 'effect'
import UserAccessFeatureRowViewModel from './UserAccessFeatureRowViewModel'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'

const UserAccessFeatureRow = () => {
  const vm = UserAccessFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Multi-User Access Control
        </PrimaryText>
        <SecondaryText>
          Restrict user access to only the tools that are needed to complete
          their job while keeping your contacts, marketing and leads safe.
        </SecondaryText>
      </VStack>
      {Array.map(vm, (value, i) => (
        <FeatureAvailability key={i} isAvailable={value} />
      ))}
    </Grid>
  )
}

export default UserAccessFeatureRow
