import { uniq } from 'lodash/fp'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { setupContext } from 'presentation/utils/context'
import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

type ScrollSpyState = {
  activeSection: string | null
  setActiveSection: (sectionId: string) => void

  sectionIds: string[]
  setSectionIds: (sectionIds: string[]) => void
}

const [Provider, useContext] = setupContext<ScrollSpyState>('ScrollSpyState', {
  activeSection: null,
  setActiveSection: () => {},
  sectionIds: [],
  setSectionIds: () => {},
})

export const ScrollSpyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sectionIds, setSectionIdsBase] = useState<string[]>([])
  const [activeSection, setActiveSectionBase] = useState<string | null>(sectionIds[0] || null)
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))
  const visibleSectionsRef = useRef<string[]>([])

  const setActiveSectionMemoed = useCallback((sectionId: string) => {
    setActiveSectionBase(sectionId)
  }, [])

  const setSectionIdsMemoed = useCallback((sectionIds: string[]) => {
    setSectionIdsBase(sectionIds)
  }, [])

  // React to sectionIds change
  useEffect(() => {
    setActiveSectionBase(sectionIds[0] || null)
  }, [sectionIds])

  // Tracks the active section
  useEffect(() => {
    // Prepare all sections
    const sections = sectionIds
      .map(sectionId => document.getElementById(sectionId))
      .filter(isNonNullable)

    // Setup observer
    const observer = new IntersectionObserver(entries => {
      // Capture all previously visible sections
      const visibleSectionsBefore = [...visibleSectionsRef.current]

      // Get all visible sections
      entries.forEach(e => {
        if (e.isIntersecting) {
          visibleSectionsRef.current.push(e.target.id)
        } else {
          visibleSectionsRef.current = visibleSectionsRef.current
            .filter(id => id !== e.target.id)
        }
      })

      // Dedupe and sort new set of visible sections
      visibleSectionsRef.current = uniq(visibleSectionsRef.current).sort()

      // If visible sections didn't change, we don't need to update the active section
      const didVisibleSectionsChange = visibleSectionsBefore.join('') !== visibleSectionsRef.current.join('')
      if (!didVisibleSectionsChange)
        return

      // Find the top most section
      type TopMostSection = { id: string, pos: number } | null
      const topMostSection = visibleSectionsRef.current.reduce<TopMostSection>((
        topMostSection,
        sectionId,
      ) => {
        const section = document.getElementById(sectionId)

        if (!section)
          return topMostSection

        const pos = section.getBoundingClientRect().top - headerHeight

        if (topMostSection && pos > topMostSection.pos)
          return topMostSection

        return { id: sectionId, pos }
      }, null)

      // Update active section
      setActiveSectionBase(topMostSection ? topMostSection.id : null)
    }, {
      threshold: 0,

      /**
       * We want to take headerHeight into account. +1 is needed to trigger
       * the observer when the section is exactly at the top of the viewport.
       */
      rootMargin: `-${headerHeight + 1}px 0px 0px 0px`,
    })

    // Observe all sections
    sections.forEach(section => {
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [headerHeight, sectionIds])

  return (
    <Provider
      activeSection={activeSection}
      setActiveSection={setActiveSectionMemoed}
      sectionIds={sectionIds}
      setSectionIds={setSectionIdsMemoed}
    >
      {children}
    </Provider>
  )
}

/**
 * Registers the sectionIds with the ScrollSpyProvider.
 */
export const useRegisterScrollSpy = (
  sectionIds: string[],
) => {
  const { setSectionIds } = useContext()

  useEffect(() => {
    setSectionIds(sectionIds)
  }, [sectionIds])
}

/**
 * Returns true if the section is the active section.
 */
export const useIsScrollSpySectionActive = (
  sectionId: string,
) => {
  const { activeSection } = useContext()

  return activeSection === sectionId
}
