import { Box, BoxProps, Center, Image, Spinner } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { Address } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { NullProps } from 'libs/utils.types'
import { GOOGLE_KEY } from 'presentation/const/libs.const'
import { loadGoogleMaps } from 'presentation/libs/loadGoogleMaps'
import QueryString from 'qs'
import { useEffect, useState } from 'react'

type NearbyBuyerStaticImageProps = BoxProps & {
  address: NullProps<Address>
  location: Location | null
  imageWidth?: number
  imageHeight?: number
}

const NearbyBuyerStaticImage = (props: NearbyBuyerStaticImageProps) => (
  <NearbyBuyerStaticImageBase
    key={`${props.imageWidth}x${props.imageHeight}`}
    {...props}
  />
)

export default NearbyBuyerStaticImage

const NearbyBuyerStaticImageBase = ({
  address,
  location,
  imageWidth = 218,
  imageHeight = 126,
  ...props
}: NearbyBuyerStaticImageProps) => {
  const { state } = useStaticStreetview({
    location,
    imageWidth,
    imageHeight,
  })

  return (
    <Box
      overflow='hidden'
      boxSize='full'
      {...props}
    >
      {pipe(
        Match.value(state),
        Match.when({ status: 'success' }, ({ url }) => (
          <>
            <Image
              src={url}
              boxSize='full'
              objectFit='fill'
            />
          </>
        )),
        Match.orElse(() => (
          <Center boxSize='full'>
            <Spinner />
          </Center>
        )),
      )}
    </Box>
  )
}

const createStaticUrl = ({
  heading,
  location,
  imageWidth,
  imageHeight,
}: {
  heading: number
  location: Location
  imageWidth?: number
  imageHeight?: number
}) => {
  const BASE_URL = `https://maps.googleapis.com/maps/api/streetview`
  const params = QueryString.stringify({
    size: `${imageWidth}x${imageHeight}`,
    location: `${location.latitude},${location.longitude}`,
    heading,
    fov: 90,
    pitch: 0,
    key: GOOGLE_KEY,
  })

  return `${BASE_URL}?${params}`
}

type PanoramaData = {
  location: Location
  heading: number
}

const getPanoramaData = ({
  location,
}: {
  location: Location
}) => new Promise<PanoramaData>((resolve, reject) => {
  void loadGoogleMaps()
    .then(() => {
      const propertyLocation = { lat: location.latitude, lng: location.longitude }
      const streetViewService = new google.maps.StreetViewService()
      const STREETVIEW_MAX_DISTANCE = 50

      void streetViewService.getPanorama({
        location: propertyLocation,
        radius: STREETVIEW_MAX_DISTANCE,
      }, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          const panoramaLocation = data?.location?.latLng

          if (!panoramaLocation)
            return reject(new Error('Failed to load panorama location'))

          const heading = google.maps.geometry.spherical.computeHeading(panoramaLocation, propertyLocation)

          if (!heading)
            return reject(new Error('Failed to calculate heading'))

          return resolve({
            location: {
              latitude: panoramaLocation.lat(),
              longitude: panoramaLocation.lng(),
            },
            heading,
          })
        } else {
          return reject(new Error('Failed to load heading'))
        }
      })
    })
})

type PreviewState =
  | {
    status: 'loading'
    location: Location
    panoramaTransitionData?: {
      panoramaData: PanoramaData | null
    }
  }
  | {
    status: 'failed'
  }
  | {
    status: 'success'
    url: string
    location: Location
    panoramaData: PanoramaData
  }

type PanoramaDataCacheData = {
  params: {
    location: Location
  }
  panoramaData: PanoramaData
}

const useCachedPanoramaData = () => {
  const [cacheData, setCacheData] = useState<PanoramaDataCacheData | null>(null)

  const getHeadingCached = ({
    location,
  }: {
    location: Location
  }) => {
    const cachedData = cacheData
    if (cachedData && Location.isEqual(cachedData.params.location, location))
      return Promise.resolve(cachedData.panoramaData)

    return getPanoramaData({ location })
      .then(panoramaData => {
        setCacheData({ params: { location }, panoramaData })
        return panoramaData
      })
  }

  return getHeadingCached
}

const useStaticStreetview = ({
  location: locationFromParams,
  imageWidth,
  imageHeight,
}: {
  location: Location | null
  imageWidth: number
  imageHeight: number
}) => {
  const getPanoramaData = useCachedPanoramaData()

  const initialState = locationFromParams
    ? { status: 'loading', location: locationFromParams } as const
    : { status: 'failed' } as const
  const [state, setState] = useState<PreviewState>(initialState)

  // Handle initial state and transition to streetview
  useEffect(() => {
    if (state.status !== 'loading') return

    if (state.panoramaTransitionData) return

    const location = state.location
    void getPanoramaData({ location })
      .then(panoramaData => {
        if (panoramaData) {
          setState({
            status: 'success',
            url: createStaticUrl({
              heading: panoramaData.heading,
              location: panoramaData.location,
              imageWidth,
              imageHeight,
            }),
            location,
            panoramaData,
          })
        } else {
          setState({
            status: 'loading',
            location,
            panoramaTransitionData: {
              panoramaData: null,
            },
          })
        }
      })
      .catch(() => {
        setState({ status: 'failed' })
      })
  }, [state])

  return {
    state,
  }
}
