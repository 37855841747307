import { Box, BoxProps, Flex, Grid, GridItem, HStack, IconButton, Image, Td, Text, Tr, useToken } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { formatBedsBathsGarageFromComp } from 'features/CMA/CMA.helpers'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { MLSOrganization } from 'features/valueObjects/MLSOrganization'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Dollar } from 'libs/dollar'
import { ID } from 'libs/id'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon, ChevronUpIcon, CircleInfoIcon2 } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { MapStoreProvider } from 'presentation/components/Map'
import { Pager } from 'presentation/components/Pager'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { MarkerSize } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { COLS_COUNT, COLS_HIDDEN_TAB_SM, COLS_VISIBLE_DT_LG_ONLY } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable.const'
import { AgentDescriptionPopoverButton } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/AgentDescriptionPopoverButton'
import { COMPS_STICKY_ROWS_CONTAINER_ID, COMPS_STICKY_ROWS_VISIBLE_CLASS } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompsStickyRows const'
import { MapSection } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/MapSection'
import { ExpandedRow, RowData, useEntriesTableStore } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { useRowSx } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useRowSx'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { createCompsEntryElementId } from 'presentation/screens/CompsScreen/utils/createEntryElementId'
import { CollapsibleContent } from 'presentation/screens/PropertyDetailsScreen/components/ConditionSection/CollapsibleContent'
import { SliderMinimized } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMinimized'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { useMemo, useRef } from 'react'
import { identity } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'
import { shallow } from 'zustand/shallow'

export type CompRowProps = {
  rowData: RowData
}

export const CompRow = (props: CompRowProps) => {
  const { rowData } = props

  const { isOpen, expandRow } = useEntriesTableStore(store => ({
    isOpen: store.expandedRow?.data.comp.status !== 'OFF_MARKET'
      ? store.expandedRow?.data.comp?.id === rowData.comp?.id
      : false,
    expandRow: store.expandRow,
  }), shallow)

  const handleOnExpandRow = (id: ID) => {
    if (isOpen) {
      expandRow()
      return
    }

    expandRow({
      rowId: id,
      shouldScroll: false,
    })
  }

  const userRating = rowData.comp.userRating

  return useMemo(() => (
    <>
      <CompRowMainCells
        rowData={rowData}
        isOpen={isOpen}
        onExpandRow={handleOnExpandRow}
      />
      {isOpen && (
        <CompRowDetails rowData={rowData} />
      )}
    </>
  ), [isOpen, userRating])
}

export type CompRowMainCellsProps = {
  rowData: RowData
  isOpen: boolean
  onExpandRow: (id: ID) => void
}

export const CompRowMainCells = ({ rowData, isOpen, onExpandRow }: CompRowMainCellsProps) => {
  const { sbp } = useSwitchBreakpointFn()

  const rateComp = useCMAStore(store => store.actions.rateComp)

  const {
    expandedRow,
  } = useEntriesTableStore(store => ({
    nextRow: store.nextRow,
    expandedRow: store.expandedRow,
    expandRow: store.expandRow,
  }), shallow)

  const isSubjectRow = rowData.comp.type === 'subject-comp'

  const comp = rowData.comp

  const shouldShowExpandIcon = comp.status !== 'OFF_MARKET'

  const rowSx = useRowSx({ comp, isOpen })

  const listPriceFormatted = pipe(
    comp.listPrice,
    NumberLib.ifValid(Dollar.formatNumberWithCommas),
    StringLib.orDoubleDash,
  )
  const listPricePerSqftFormatted = pipe(
    comp.listPricePerSqft,
    NumberLib.ifValid(Dollar.formatNumberWithCommasDecimals),
    StringLib.orDoubleDash,
  )
  const salePriceFormatted = pipe(
    comp.salePrice,
    NumberLib.ifValid(Dollar.formatNumberWithCommas),
    StringLib.orDoubleDash,
  )
  const salePricePerSqftFormatted = pipe(
    comp.salePricePerSqft,
    NumberLib.ifValid(Dollar.formatNumberWithCommasDecimals),
    StringLib.orDoubleDash,
  )

  // @NOTE Tracking expandedRow on ref prevents us from having to rerender
  //   all rows when expandedRow changes
  const expandedRowRef = useRef<ExpandedRow | null>(expandedRow)
  expandedRowRef.current = expandedRow

  const handleOnCompsRatingChange = (id: ID, rating: CMA.Comp.UserRating) => {
    void rateComp.execute({
      compId: id,
      rating,
    })
  }

  /**
   * @TODO This is a temp fix. This could be improved.
   */
  const homeTypeString = HomeType.toStringUnstandardized(StringLib.orDoubleDash(comp.propertyType) as HomeType)
  const homeTypeDisplay = pipe(
    Match.value(homeTypeString),
    Match.when('Residential', () => sbp({
      mobSm: 'Resi-dential',
      dtLg: 'Residential',
    })),
    Match.when('Commercial', () => sbp({
      mobSm: 'Com-mercial',
      dtLg: 'Commercial',
    })),
    Match.orElse(() => homeTypeString),
  )

  const userRating = comp.userRating

  const markerSize: MarkerSize = sbp({ mobSm: 'xs', dtSm: 'sm' }) ?? 'sm' as MarkerSize

  return useMemo(() => (
    <Tr
      id={createCompsEntryElementId(comp.id)}
      key={comp.id}
      pos='relative'
      sx={{
        ...rowSx,
        '& > td': {
          position: 'relative',
          zIndex: 2,
        },
        '& > td::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: comp.userRating === 'excluded' ? 'gw.100.am' : 'gw.100.az',
          pointerEvents: 'none',
          zIndex: 3,
          transition: 'background 0.2s',
        },

        '&:last-of-type::after': {
          borderBottomRadius: 2,
        },
      }}
      onClick={() => {
        onExpandRow(comp.id)
      }}
      cursor='pointer'
      borderBottomWidth={mbp({
        mobSm: isOpen ? 0.125 : 0,
        dtLg: 0,
      })}
      borderBottomColor='grayweak.500'
    >
      {/* Address Cell */}
      <Td
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        textAlign='left'
      >
        <Flex align='center'>
          <Box minW='0'>
            <IconButton
              ml={-2.5}
              aria-label='Expand'
              size='md'
              variant='icon-ghost'
              colorScheme='onlight'
              visibility={shouldShowExpandIcon ? 'visible' : 'hidden'}
              icon={(
                isOpen
                  ? <ChevronUpIcon color='graystrong.500' />
                  : <ChevronDownIcon color='graystrong.500' />
              )}
              sx={{
                visibility: shouldShowExpandIcon ? 'visible' : 'hidden',

                [`#${COMPS_STICKY_ROWS_CONTAINER_ID}:not(.${COMPS_STICKY_ROWS_VISIBLE_CLASS}) &`]: {
                  visibility: 'hidden',
                },
              }}
            />
          </Box>

          <Flex
            pl={mbp({
              dtLg: 2,
              mobSm: 1,
            })}
            alignItems='center'
            gap={1}
            minW='0'
          >
            {isSubjectRow && <SubjectPropertyMarker size={markerSize} markerType='pin' />}
            <Box
              flex='1 1 0'
              minW='0'
            >
              <Tooltip label={comp.address.line1}>
                <Text
                  display='inline-block'
                  maxW='full'
                  isTruncated
                  textStyle='bodyLFat'
                  color='graystrong.400'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {comp.address.line1}
                </Text>
              </Tooltip>
              <br />
              {comp.address.subdivision
                ? (
                  <Tooltip label={comp.address.subdivision}>
                    <Text
                      display='inline-block'
                      maxW='full'
                      isTruncated
                      textStyle='bodyM'
                      color='grayweak.900'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                    >
                      {comp.address.subdivision}
                    </Text>
                  </Tooltip>
                )
                : (
                  <Text
                    display='inline-block'
                    maxW='full'
                    isTruncated
                    textStyle='bodyM'
                    color='grayweak.900'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                  >
                    --
                  </Text>
                )}
            </Box>
          </Flex>
        </Flex>
      </Td>

      {/* Property Type Cell */}
      <Td
        textAlign='left'
        overflowWrap='break-word'
        wordBreak='break-word'
        whiteSpace='normal'
      >
        {homeTypeDisplay}
      </Td>

      {/* Property Status Cell */}
      <Td
        pos='relative'
        textAlign='center'
        px='0 !important'
      >
        {comp.status === 'SOLD' || comp.status === 'LEASED'
          ? (
            <MLSStatusTag
              status={comp.status}
              date={comp.saleDate ?? undefined}
              labelLayout='vertical'
            />
          )
          : <MLSStatusTag status={comp.status} />}
      </Td>

      {/* Beds/Baths/Garage Cell */}
      <Td pos='relative' zIndex='auto' textAlign='right'>
        {formatBedsBathsGarageFromComp(comp)}
      </Td>

      {/* Year Built Cell */}
      <Td textAlign='right'>
        {NumberLib.orDoubleDash(comp.yearBuilt)}
      </Td>

      {/* Sqft Cell */}
      <Td
        textAlign='right'
        {...mbpg({
          mobSm: {
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          },
          dtLg: {
            whitespace: 'nowrap',
            overflowWrap: 'break-word',
            wordBreak: 'normal',
          },
        })}
      >

        <Breakpoint
          mobSm={
            `${
              NumberLib.formatCODoDD(comp.livingAreaSqft)
            } / ${
              NumberLib.ifElse(NumberLib.omitAfterNPlaces(2))(() => '--')(comp.lotAreaAcres)
            }`
          }
          dtLg={NumberLib.formatCODoDD(comp.livingAreaSqft)}
        />
      </Td>

      <Breakpoint
        dtLg={(
          <Td
            textAlign='right'
            overflowWrap='break-word'
            wordBreak='break-word'
          >
            {NumberLib.ifElse(NumberLib.omitAfterNPlaces(2))(() => '--')(comp.lotAreaAcres)}
          </Td>
        )}
      />

      {/* Listing Price Cell */}
      <Td textAlign='right'>
        <Breakpoint
          mobSm={(
            <>
              {listPriceFormatted}
              <br />
              /
              {listPricePerSqftFormatted}
            </>
          )}
          dtLg={listPriceFormatted}
        />
      </Td>

      {/* Listing Price Per Sqft Cell */}
      <Breakpoint
        dtLg={(
          <Td textAlign='right'>
            {listPricePerSqftFormatted}
          </Td>
        )}
      />

      {/* Selling Price Cell */}
      <Td textAlign='right'>
        <Breakpoint
          mobSm={(
            <>
              {salePriceFormatted}
              <br />
              /
              {salePricePerSqftFormatted}
            </>
          )}
          dtLg={salePriceFormatted}
        />
      </Td>

      {/* Selling Price Per Sqft Cell */}
      <Breakpoint
        dtLg={(
          <Td textAlign='right'>
            {salePricePerSqftFormatted}
          </Td>
        )}
      />

      {/* Distance Cell */}
      <Td textAlign='right'>
        {comp.type === 'subject-comp'
          ? 'N/A'
          : (
            <>
              <Breakpoint
                mobSm={(
                  `${NumberLib.omitAfterNPlaces(1)(comp.distance)} mi`
                )}
                dtLg={(
                  `${NumberLib.omitAfterNPlaces(1)(comp.distance)} ${pluralize('mile', comp.distance)}`
                )}
              />
            </>
          )}
      </Td>

      {/* Days On Market Cell */}
      <Td textAlign='right'>
        {NumberLib.orDoubleDash(comp.daysOnMarket)}
      </Td>

      {/* Comps Rating Cell */}
      <Td>
        <CompsThumbButtons
          size='sm'
          pos='relative'
          /** Sit on top of overlay when comp has thumbs down rating */
          zIndex='10'
          comp={comp}
          onCompsRatingChange={handleOnCompsRatingChange}
          gap={mbp({ dtLg: 1 })}
        />
      </Td>
    </Tr>
  ), [
    isOpen,
    userRating,
    onExpandRow,
    isSubjectRow,
  ])
}

export type CompRowDetailsProps = {
  rowData: RowData
}

export const CompRowDetails = (props: CompRowDetailsProps) => {
  const { rowData } = props

  const rowSx = useRowSx({ comp: rowData?.comp, isOpen: true })

  const pyToken = useToken('space', '2.5')

  const { sbp } = useSwitchBreakpointFn()

  return (
    <Tr
      sx={{
        ...rowSx,

        '& > td': {
          position: 'relative',
          zIndex: 2,
        },

        '& > td::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: rowData?.comp.userRating === 'excluded' ? 'gw.100.am' : 'gw.100.az',
          pointerEvents: 'none',
          zIndex: 3,
          transition: 'background 0.2s',
        },
      }}
    >
      <Td
        colSpan={sbp({
          tabSm: COLS_COUNT - COLS_HIDDEN_TAB_SM,
          dtLg: COLS_COUNT + COLS_VISIBLE_DT_LG_ONLY,
        })}
        whiteSpace='normal'
        sx={{
          pb: `${pyToken} !important`,
          pt: 0,
        }}
      >
        <Grid
          gap={2}
          gridTemplate={mbp({
            mobSm: `
              "Header Header" min-content
              "SliderSection MapSection" auto
              "AgentSection AgentSection" auto / 1fr 1fr
            `,
            dtLg: `
              "Header Header Header" min-content
              "Description Description Description" auto
              "AgentSection SliderSection MapSection" min-content / 1fr 328px 328px
            `,
          })}
          w='100%'
        >
          <Breakpoint
            mobSm={(
              <GridItem
                gridArea='Header'
                justifySelf='stretch'
              >
                <Flex alignItems='center' gap={2}>
                  <Flex color='graystrong.400' gap={0.5} alignItems='center'>
                    <Text textStyle='bodyMFat' color='inherit' flexShrink='0'>
                      MLS #
                    </Text>
                    {isNonNullable(rowData.comp.listingId)
                      ? (
                        <Tooltip label={rowData.comp.listingId}>
                          <Text textStyle='bodyL' color='inherit' isTruncated>
                            {rowData.comp.listingId || '--'}
                          </Text>
                        </Tooltip>
                      )
                      : (
                        <Text textStyle='bodyL' color='inherit' isTruncated>
                          {rowData.comp.listingId || '--'}
                        </Text>
                      )}
                    <Breakpoint
                      mobSm={(
                        <AgentDescriptionPopoverButton>
                          {StringLib.orDoubleDash(rowData.comp.description)}
                        </AgentDescriptionPopoverButton>
                      )}
                      dtLg={null}
                    />
                  </Flex>
                  <Box ml='auto'>
                    <RowPager currentRowNumber={rowData.rowNumber} />
                  </Box>
                </Flex>
              </GridItem>
            )}
            dtLg={(
              <GridItem
                gridArea='Header'
                justifySelf='end'
              >
                <RowPager currentRowNumber={rowData.rowNumber} />
              </GridItem>
            )}
          />

          <Breakpoint
            dtLg={(
              <GridItem
                gridArea='Description'
                minW={0}
                minH={0}
              >
                <Flex justifyContent='space-between'>
                  <Text
                    textStyle='h4'
                    color='accent.blue-text'
                    maxW='100%'
                    textAlign='left'
                  >
                    Agent&rsquo;s Description
                  </Text>
                </Flex>
                <Text
                  as='div'
                  textStyle='bodyL'
                  color='graystrong.400'
                  mt='1.5'
                  maxW='100%'
                >
                  <CollapsibleContent maxLineLimit={4}>
                    {StringLib.orDoubleDash(rowData.comp.description)}
                  </CollapsibleContent>
                </Text>
              </GridItem>
            )}
          />

          <GridItem
            gridArea='AgentSection'
            minW={0}
            minH={0}
            pt={mbp({ mobSm: 1, dtLg: 0 })}
          >
            <AgentSection rowData={rowData} />
          </GridItem>
          <MapStoreProvider>
            <GridItem gridArea='MapSection'>
              <MapSection h='306px' rowData={rowData} />
            </GridItem>
          </MapStoreProvider>
          <GridItem gridArea='SliderSection' minW={0} minH={0}>
            <SliderSection
              h='306px'
              comp={rowData.comp}
            />
          </GridItem>
        </Grid>
      </Td>
    </Tr>
  )
}

const RowPager = (props: { currentRowNumber: number }) => {
  const {
    nextRow,
    prevRow,
    totalRows,
  } = useEntriesTableStore(identity)

  const totalPages = totalRows

  const handleOnNext = () => {
    nextRow()
  }
  const handleOnPrev = () => {
    prevRow()
  }

  return (
    <Pager
      currentPage={props.currentRowNumber}
      totalPages={totalPages}
      onPrev={handleOnPrev}
      onNext={handleOnNext}
    />
  )
}

type AgentSectionProps = {
  rowData: RowData
} & BoxProps

const AgentSection = ({
  rowData,
  ...props
}: AgentSectionProps) => {
  const agent = rowData.comp.agent
  const broker = rowData.comp.broker
  const organization = rowData.comp.organization
  const buyerAgent = rowData.comp.buyerAgent
  const buyerBroker = rowData.comp.buyerBroker
  const isClosedListing = rowData.comp.status === 'SOLD' || rowData.comp.status === 'LEASED'
  const hasBuyerAgentInfo = isClosedListing && buyerAgent

  return (
    <Flex
      flexDirection='column'
      alignItems='space-between'
      h='full'
      {...props}
    >
      {/* Listing Agent/Office Information */}
      <Box>
        {organization && (
          <Flex justifyContent='space-between' alignItems='center' mb={2}>
            <Text
              textStyle='bodyLFat'
              color='graystrong.400'
            >
              Listing Office
              <Tooltip label="Seller's Agent" placement='bottom' hasArrow>
                <Box as='span' display='inline-block' ml={0.5}>
                  <CircleInfoIcon2 boxSize={2.5} color='accent.pink' />
                </Box>
              </Tooltip>
            </Text>
            <HStack spacing={1}>
              <Text
                textStyle='bodyMFat'
                color='neutral.500'
              >
                Source:
              </Text>
              <Text
                textStyle='bodyM'
                textTransform='uppercase'
                color='graystrong.200'
                flex='1'
              >
                {organization.name ? organization.name : '--'}
              </Text>
              <SourceLogo organization={organization} />
            </HStack>
          </Flex>
        )}

        <Grid
          gridTemplateColumns='repeat(3, 1fr)'
          gridTemplateRows='repeat(2, auto)'
          rowGap={1}
          columnGap={2}
        >
          <GridItem>
            <Pair size='md'>
              <PairKey>Broker Name</PairKey>
              <PairValue>
                {broker?.name || '--'}
              </PairValue>
            </Pair>
          </GridItem>
          <GridItem>
            <Pair size='md'>
              <PairKey>Broker Phone No.</PairKey>
              <PairValue>
                {broker?.phone || '--'}
              </PairValue>
            </Pair>
          </GridItem>
          <GridItem>
            <Pair size='md'>
              <PairKey>MLS #</PairKey>
              <PairValue>
                {rowData.comp.listingId}
              </PairValue>
            </Pair>
          </GridItem>
          <GridItem>
            <Pair size='md'>
              <PairKey>Agent Name</PairKey>
              <PairValue>
                {agent?.name || '--'}
              </PairValue>
            </Pair>
          </GridItem>
          <GridItem>
            <Pair size='md'>
              <PairKey>Agent Email</PairKey>
              <PairValue>
                {agent?.email || '--'}
              </PairValue>
            </Pair>
          </GridItem>
          <GridItem>
            <Pair size='md'>
              <PairKey>Agent Phone No.</PairKey>
              <PairValue>
                {agent?.phone || '--'}
              </PairValue>
            </Pair>
          </GridItem>
        </Grid>
      </Box>

      {/* Divider between seller and buyer agent info */}
      {hasBuyerAgentInfo && (
        <Box
          borderTopWidth='1px'
          borderTopColor='grayweak.500'
          my={4}
        />
      )}

      {/* Buyer Agent/Office Information - Only show for SOLD or LEASED listings */}
      {hasBuyerAgentInfo && (
        <Box>
          <Text
            textStyle='bodyLFat'
            color='graystrong.400'
            mb={2}
          >
            Buyer&apos;s Agent
          </Text>

          <Grid
            gridTemplateColumns='repeat(3, 1fr)'
            gridTemplateRows='repeat(2, auto)'
            rowGap={1}
            columnGap={2}
          >
            <GridItem>
              <Pair size='md'>
                <PairKey>Agent Name</PairKey>
                <PairValue>
                  {buyerAgent?.name || '--'}
                </PairValue>
              </Pair>
            </GridItem>
            <GridItem>
              <Pair size='md'>
                <PairKey>Broker Name</PairKey>
                <PairValue>
                  {buyerBroker?.name || '--'}
                </PairValue>
              </Pair>
            </GridItem>
            <GridItem>
              <Pair size='md'>
                <PairKey>Agent Phone No.</PairKey>
                <PairValue>
                  {buyerAgent?.phone || '--'}
                </PairValue>
              </Pair>
            </GridItem>
            <GridItem>
              <Pair size='md'>
                <PairKey>Agent Email</PairKey>
                <PairValue>
                  {buyerAgent?.email || '--'}
                </PairValue>
              </Pair>
            </GridItem>
            <GridItem>
              <Pair size='md'>
                <PairKey>Broker Phone No.</PairKey>
                <PairValue>
                  {buyerBroker?.phone || '--'}
                </PairValue>
              </Pair>
            </GridItem>
          </Grid>
        </Box>
      )}
    </Flex>
  )
}

type SliderSectionProps = BoxProps & {
  comp: CMA.SingleComp | CMA.SubjectComp
}

const SliderSection = ({ comp, ...props }: SliderSectionProps) => {
  const photos = comp.photos

  return (
    <Box
      boxShadow='float'
      overflow='hidden'
      borderRadius={3}
      {...props}
    >
      <SliderMinimized
        h={306}
        photos={photos}
        emptyMessage='This listing has no photos'
      />
    </Box>
  )
}

type SourceLogoProps = { organization: MLSOrganization } & BoxProps

const SourceLogo = ({
  organization,
}: SourceLogoProps) => (
  <Image
    h='4'
    src={organization.logo}
    alt={organization.name}
  />
)
