import { flow } from 'effect'
import { fromEntries, toEntries } from 'effect/Record'
import { mapValues } from 'lodash/fp'
import { reduce } from 'remeda'

type ShadeKey = string
type ShadeValue = string
type ShadeEntry = [ShadeKey, ShadeValue]
type HueDef = Record<ShadeKey, ShadeValue>
type ColorsDef = Record<string, HueDef>

/** Alpha definitions AKA Opacity */
const ALPHA_DEF = [
  ['axs', 0.9], // Alpha Xtra Strong
  ['as', 0.7], // Alpha Strong
  ['a', 0.5], // Alpha Medium
  ['aw', 0.3], // Alpha Weak
  ['axw', 0.1], // Alpha Xtra Weak
] as const

const addAlphaVariantEntries = (shades: ShadeEntry[], [shadeKey, shadeVal]: ShadeEntry): ShadeEntry[] => [
  ...shades,

  [shadeKey, shadeVal],

  ...ALPHA_DEF.map(([suffix, alpha]): ShadeEntry =>
    [shadeKey + suffix, addColorAlpha(alpha, shadeVal)]),
]

export const addColorAlphaVariants: (colorsDef: ColorsDef) => ColorsDef
  = mapValues(flow(
    toEntries,
    reduce<ShadeEntry, ShadeEntry[]>(addAlphaVariantEntries, []),
    fromEntries,
  ))

/** @see https://stackoverflow.com/a/68398236/7823311 */
const addColorAlpha = (alpha: number, colorHex: string): string => {
  const _alpha = Math.round(Math.min(Math.max(alpha || 1, 0), 1) * 255)
  return colorHex + _alpha.toString(16).toUpperCase()
}
