import { useStore } from 'features/ListBuilder/infra/react/ListBuilderStore/useStore'

/** @deprecated */
export const useStoreActionsDeprecated = () =>
  useStore(store => ({
    saveList: store.saveList,
    loadLists: store.loadLists,
    selectList: store.selectList,
    deleteLists: store.deleteLists,
    deleteMembers: store.deleteListMembers,
    updateListName: store.updateListName.execute,
  }))

export const useListStoreActions = () =>
  useStore(store => ({
    saveList: store.saveList.execute,
    loadLists: store.loadLists.execute,
    selectList: store.selectList.execute,
    deleteLists: store.deleteLists.execute,
    deleteMembers: store.deleteListMembers.execute,
    updateListName: store.updateListName.execute,
    getDownloadStats: store.getDownloadStats.execute,
    downloadList: store.downloadList.execute,
  }))
