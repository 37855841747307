import { Option } from 'effect'
import { PartialRange } from 'features/valueObjects/Range'

type NearbyBuyersFilters = {
  shouldIncludeLandLords: boolean
  shouldIncludeFlippers: boolean
  soldWithinMonths: Option.Option<number>
  dealsRange: PartialRange
  distanceMiles: number
}

namespace NearbyBuyersFilters {
  export const DEFAULT: NearbyBuyersFilters = {
    shouldIncludeLandLords: true,
    shouldIncludeFlippers: true,
    distanceMiles: 0.25,
    soldWithinMonths: Option.none(),
    dealsRange: PartialRange.EMPTY,
  }
}

export default NearbyBuyersFilters
