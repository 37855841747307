import { createIcon } from '@chakra-ui/react'

const SolidFeatherIcon = createIcon({
  displayName: 'SolidFeatherIcon',
  viewBox: '0 0 40 41',
  path: (
    <g fill='none'>
      <g clipPath='url(#clip0_232_66492)'>
        <path
          d='M21.7578 16.8838L1.79688 36.8369C1.0625 37.5713 1.0625 38.7588 1.79688 39.4854C2.53125 40.2119 3.71875 40.2197 4.44531 39.4854L8.89844 35.0322H14.2109C18.0938 35.0322 21.8594 33.9072 25.0703 31.8291C25.9375 31.2666 25.5 30.0322 24.4609 30.0322C24.0625 30.0322 23.7422 29.7119 23.7422 29.3135C23.7422 28.9932 23.9531 28.7197 24.25 28.626L30.5781 26.7275C30.7734 26.665 30.9531 26.5635 31.1016 26.415L32.8516 24.665C33.6406 23.876 33.0781 22.5322 31.9688 22.5322H29.4531C29.0547 22.5322 28.7344 22.2119 28.7344 21.8135C28.7344 21.4932 28.9453 21.2197 29.2422 21.126L37.9922 18.501C38.3047 18.4072 38.5703 18.1963 38.7188 17.8994C39.5625 16.2588 40 14.4229 40 12.54C40 9.33691 38.7266 6.2666 36.4609 4.00098L36.0312 3.57129C33.7734 1.31348 30.7031 0.0400391 27.5 0.0400391C24.2969 0.0400391 21.2266 1.31348 18.9609 3.5791L10.8594 11.6807C7.10938 15.4307 5 20.5166 5 25.8213V30.1416L19.8125 15.3369C20.2969 14.8525 21.0938 14.8525 21.5781 15.3369C22 15.7588 22.0547 16.3994 21.75 16.8838H21.7578Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_232_66492'>
          <rect width='40' height='40' fill='white' transform='translate(0 0.0400391)' />
        </clipPath>
      </defs>
    </g>
  ),
})

export default SolidFeatherIcon
