import { numberInputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { px } from 'presentation/utils/px'

/**
 * @TODO: Use theme values from Input.theme instead of duplicating them here
 */

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers(numberInputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    color: 'graystrong.700',
  },
})

const variants = {
  default: definePartsStyle({
    field: {
      ...textStyles.body, // let's put this here so we can access it from Stripe inputs
      'boxShadow': 'none',
      'bg': 'card.bg.1',
      'borderWidth': '1px',
      'borderColor': 'grayweak.500',
      'color': 'graystrong.700',
      'borderRadius': 1,

      '_placeholder': {
        color: 'graystrong.200',
      },

      '_focus': {
        boxShadow: 'depth',
        borderColor: 'neutral.500',
        borderWidth: '2px',
      },

      '_invalid': {
        borderColor: 'error.500',
        borderWidth: '2px',
      },

      '_disabled': {
        opacity: 1,
        boxShadow: 'none',
        borderColor: 'grayweak.600',
        color: 'grayweak.800',
        bgColor: 'grayweak.400',
      },
      '&:disabled::placeholder': {
        color: 'grayweak.800',
      },
      '_hover': {
        boxShadow: 'depth',
        borderWidth: '2px',
      },
    },
  }),
}

export const sizes = {
  md: definePartsStyle({
    field: {
      ...textStyles.bodyL,
      px: px(16 - 1), // padding - border
      py: px(8 - 1), // padding - border

      /** Needed to overline base chakra theme */
      paddingInlineEnd: px(16 - 1), // padding - border

      _focus: {
        px: px(16 - 2), // padding - border
        py: px(8 - 2), // padding - border
      },

      _invalid: {
        px: px(16 - 2), // padding - border
        py: px(8 - 2), // padding - border
      },

      _hover: {
        px: px(16 - 2), // padding - border
        py: px(8 - 2), // padding - border
      },

    },
  }),
}

export const numberInputTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
})
