import { Button, ButtonProps, Center, Flex, IconButton, Portal, Spinner, Text } from '@chakra-ui/react'
import { Option } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import usePropertyDataNearbyBuyersMachineInterface from 'features/NearbyBuyers/machine/NearbyBuyersMachine/usePropertyDataNearbyBuyersMachineInterface'
import NearbyBuyersFilterPanel from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/NearbyBuyersFilterPanel'
import NearbyBuyersMap from 'features/NearbyBuyers/views/NearbyBuyersMaps/NearbyBuyersMap'
import NearbyBuyersPanel from 'features/NearbyBuyers/views/NearbyBuyersPanel/NearbyBuyersPanel'
import NearbyBuyersTestIds from 'features/NearbyBuyers/views/NearbyBuyersTestIds'
import { Address } from 'features/valueObjects/Address'
import { Breakpoint } from 'presentation/components/Breakpoint'
import DraggablePanelContext from 'presentation/components/DraggablePanel/DraggablePanelContext'
import FloatingActionButton from 'presentation/components/FloatingActionButton'
import { ChevronDownIcon, SaveIcon } from 'presentation/components/Icons'
import { ColoredStarIcon } from 'presentation/components/Icons/ColoredStarIcon'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { HeaderMobileTitle } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile'
import { HeaderMobileDrawer } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/HeaderMobileDrawer'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useConfigScreenTitle } from 'presentation/libs/useConfigScreenTitle'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { DistanceFilter } from '../DistanceFilter/DistanceFilter'
import { InvestorTypeFilter } from '../InvestorTypeFilter'
import { MinPropertiesFilter } from '../MinPropertiesFilter/MinPropertiesFilter'
import NearbyBuyersFilterPanelAPI from '../NearbyBuyersFilterPanel/NearbyBuyersFilterPanel.api'
import NearbyBuyersLayout from '../NearbyBuyersLayout/NearbyBuyersLayout'
import { SoldWithinFilter } from '../SoldWithinFilter'
import InvestorTypeFilterCheckboxGroup from '../InvestorTypeFilter/InvestorTypeFilterCheckboxGroup'

const Loading = () => (
  <Center flex='1' h='full'>
    <Spinner color='graystrong.500' />
  </Center>
)

const NearbyBuyersScreen = () => {
  useConfigScreenTitle({
    htmlTitle: 'Cash Buyers',
    layoutTitle: 'Cash Buyers',
  })

  useSetupNearbyBuyersScreen()

  const isInitial = NearbyBuyersHooks.useIsBuyerStateInitial()

  return (
    <>
      {isInitial
        ? (
          <>
            <Loading />
          </>
        )
        : (
          <NearbyBuyersLayout>
            <NearbyBuyersLayout.Address>
              <AddressSection />
            </NearbyBuyersLayout.Address>
            <NearbyBuyersLayout.Toolbar>
              <ResponsiveToolbar />
            </NearbyBuyersLayout.Toolbar>
            <NearbyBuyersLayout.Map>
              <NearbyBuyersMap />
            </NearbyBuyersLayout.Map>
            <NearbyBuyersLayout.Panel label='View Cash Buyer&rsquo;s Lists'>
              <NearbyBuyersPanel />
            </NearbyBuyersLayout.Panel>
            <NearbyBuyersFilterPanel />
            <Portal>
              {/**
                <SaveToAListFloatingButton />
              */}
              <Breakpoint
                mobSm={(
                  <MobileHeader />
                )}
                tabSm={<></>}
              />
              <Outlet />
            </Portal>
          </NearbyBuyersLayout>
        )}
    </>
  )
}

export default NearbyBuyersScreen

const ResponsiveToolbar = () => {
  const state = DraggablePanelContext.useActorState()
  return (
    <Breakpoint
      mobSm={(
        <Flex
          justifyContent={state === 'Open' ? 'right' : 'center'}
          w={state === 'Open' ? 'auto' : 'full'}
          alignItems='center'
          gap={mbp({ mobSm: 1, mob: 2 })}
          ml='auto'
        >
          <InvestorTypeFilter
            {...state !== 'Open' && {
              colorScheme: 'neutral',
            }}
            {...state === 'Open' && {
              size: 'sm',
            }}
            px={2}
          >
            {state === 'Open' ? 'Type' : 'Investor Type'}
          </InvestorTypeFilter>
          <FilterButton
            {...state === 'Open' && {
              size: 'sm',
            }}
            sx={{
              px: '16px !important',
            }}
          />
        </Flex>
      )}
      tabSm={(
        <Flex alignItems='center' width='full'>
          <FilterButton
            {...state === 'Open' && {
              size: 'sm',
            }}
            sx={{
              px: '16px !important',
            }}
          />
          <InvestorTypeFilterCheckboxGroup ml='auto' />
          {/**
            <SaveToListToolbarButton ml={1} />
          */}
        </Flex>
      )}
      dtSm={(
        <Flex alignItems='center' width='full'>
          <FiltersSection />
          <InvestorTypeFilterCheckboxGroup ml='auto' />
          {/**
            <SaveToListToolbarButton ml={1} />
          */}
        </Flex>
      )}
    />
  )
}

export const SaveToListToolbarButton = (props: ButtonProps) => {
  const openSaveToListModal = NearbyBuyersHooks.useOpenSaveToListModal()
  const canSaveToList = NearbyBuyersHooks.useCanSaveToList()
  return (
    <Button
      variant='solid'
      colorScheme='positive'
      onClick={openSaveToListModal}
      data-testid={NearbyBuyersTestIds.SAVE_TO_LIST_MODAL_BUTTON}
      isDisabled={!canSaveToList}
      {...props}
    >
      Save to a List
    </Button>
  )
}

const AddressSection = () => {
  const property = NearbyBuyersHooks.useProperty()
  return property.pipe(
    Option.match({
      onSome: property => {
        const address = property.address

        return (
          <Flex gap={1}>
            <SubjectPropertyMarker markerType='pin' size='sm' />
            <Flex flexDir='column' minW={0} justify='center' w='260px'>
              <Text
                textStyle='bodyXLFat'
                color='accent.blue-to-gray'
                isTruncated
              >
                {address.line1}
              </Text>
              <Text
                textStyle='bodyMFat'
                color='graystrong.200'
                isTruncated
              >
                {Address.formatCityStateZip(address)}
              </Text>
            </Flex>
          </Flex>
        )
      },
      onNone: () => null,
    }),
  )
}

const FiltersSection = () => {
  const canFilterOrSort = NearbyBuyersHooks.useCanFilterOrSort()
  const distanceMiles = NearbyBuyersHooks.useDistanceMiles()
  const setDistanceMiles = NearbyBuyersHooks.useSetDistanceMiles()
  const soldWithin = NearbyBuyersHooks.useSoldWithinMonths()
  const setSoldWithin = NearbyBuyersHooks.useSetSoldWithinMonths()
  const dealsRange = NearbyBuyersHooks.useDealsRange()
  const setDealsRange = NearbyBuyersHooks.useSetDealsRange()

  return canFilterOrSort
    ? (
      <Flex
        alignItems='center'
        gap={1}
      >
        <DistanceFilter
          value={distanceMiles}
          onChange={setDistanceMiles}
        />

        <SoldWithinFilter
          value={soldWithin}
          onChange={setSoldWithin}
        />

        <MinPropertiesFilter
          value={dealsRange}
          onChange={setDealsRange}
        />
      </Flex>
    )
    : null
}

export const SaveToAListFloatingButton = () => {
  const state = DraggablePanelContext.useActorState()
  const openSaveToListModal = NearbyBuyersHooks.useOpenSaveToListModal()
  const { sbp } = useSwitchBreakpointFn()
  const shouldShowButton = sbp({
    mobSm: state === 'Open',
    tabSm: false,
  }) ?? false
  return shouldShowButton
    ? (
      <Portal>
        <FloatingActionButton.Button
          leftIcon={<SaveIcon boxSize={4} />}
          onClick={openSaveToListModal}
          bottom='58px'
        >
          Save to a list
        </FloatingActionButton.Button>
      </Portal>
    )
    : null
}

const FilterButton = (props: ButtonProps) => {
  const { sbp } = useSwitchBreakpointFn()
  return (
    <Button
      variant='outline'
      colorScheme='neutral'
      size={mbp({
        mobSm: 'sm',
        mob: 'md',
      })}
      bgColor='card.bg.1'
      {...sbp({
        mobSm: {
          rightIcon: <ChevronDownIcon boxSize={3} />,
        },
        tabSm: {},
      })}
      onClick={NearbyBuyersFilterPanelAPI.open}
      {...props}
    >
      {sbp({
        mobSm: 'Filters',
        tabSm: 'All Filters',
      })}
    </Button>
  )
}

export const useSetupNearbyBuyersScreen = () => {
  usePropertyDataNearbyBuyersMachineInterface()
}

const MobileHeader = () => {
  const setHeaderContents = useSetMobileHeaderContents()
  const property = NearbyBuyersHooks.useProperty()
  // @NOTE: not available yet
  // const openSaveToListModal = NearbyBuyersHooks.useOpenSaveToListModal()
  useEffect(() => {
    setHeaderContents([
      <HeaderMobileDrawer key='left-toolbar-item' ml={1} />,
      <HeaderMobileTitle
        key='header'
        flex='1'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDir='column'
      >
        <Text
          textStyle='bodyXLFat'
          color='ondark.1'
        >
          Cash Buyers
        </Text>

        {property.pipe(
          Option.match({
            onNone: () => null,
            onSome: property => (
              <Flex
                gap={1}
                alignItems='center'
              >
                <ColoredStarIcon boxSize={2} />
                <Text
                  textStyle='bodyLFat'
                  color='highlight.500'
                  isTruncated
                >
                  {property.address.line1}
                </Text>
              </Flex>
            ),
          }),
        )}

      </HeaderMobileTitle>,
      <IconButton
        /** NOTE: not available yet. visibilit is used to balance
         * the header spacing.
         */
        visibility='hidden'
        aria-label='Save to List'
        size='md'
        key='right-toolbar-item'
        variant='icon-ghost'
        colorScheme='ondark'
        mr={1}
        // onClick={openSaveToListModal}
      >
        <SaveIcon />
      </IconButton>,
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [Option.getOrNull(property)])

  return null
}
