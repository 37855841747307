import { isNullable } from 'effect/Predicate'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { HomeTypeFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/HomeTypeFilterDropdown'
import { HomeTypeFilterCardDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/HomeTypeFilterCard/HomeTypeFilterCard.dumb'
import { shallow } from 'zustand/shallow'

export const HomeTypeFilterCard = () => {
  const filterValue = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report))
      return null

    return state.local.report.data.filters.homeType
  }, shallow)

  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: HomeTypeFilterDropdownDumbProps['onChange'] = value => {
    void filterComps({ homeType: value })
  }

  if (isNullable(filterValue)) return null

  return (
    <HomeTypeFilterCardDumb
      value={filterValue}
      onChange={handleOnValueChange}
      subjectValue={null}
      filterErrorMsg=''
    />
  )
}
