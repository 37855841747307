import { Match, Option } from 'effect'
import { noop } from 'utils/noop'
import { fromCallback } from 'xstate'
import DraggablePanelEvent from './DraggablePanelEvent'
import DragListenerEvent from './DragListenerEvent'

namespace DragListenerActor {
  export const DefaultActorId = 'draggable-panel-listener' as const

  /**
   * setup for listening to drag events
   */
  export const make = () => fromCallback<DragListenerEvent.Incoming>(({
    sendBack,
    receive,
  }) => {
    let unsubscribe: Option.Option<() => void> = Option.none()

    const onMouseDown = (e: MouseEvent) => {
      sendBack(
        DraggablePanelEvent.WillDrag(
          e.clientY,
        ),
      )
    }

    const onMouseUp = (e: MouseEvent) => {
      sendBack(
        DraggablePanelEvent.DidDrag(
          e.clientY,
        ),
      )
    }

    const onMouseLeave = (e: MouseEvent) => {
      sendBack(
        DraggablePanelEvent.DidDrag(
          e.clientY,
        ),
      )
    }

    const onMouseMove = (e: MouseEvent) => {
      sendBack(
        DraggablePanelEvent.Dragging(
          e.clientY,
        ),
      )
    }

    const onTouchStart = (e: TouchEvent) => {
      sendBack(
        DraggablePanelEvent.WillDrag(
          e.touches[0].clientY,
        ),
      )

      const el = e.currentTarget as HTMLElement
      if (el.dataset['status'] === 'close')
        e.preventDefault()
    }

    const onTouchMove = (e: TouchEvent) => {
      sendBack(
        DraggablePanelEvent.Dragging(
          e.touches[0].clientY,
        ),
      )

      /**
       * Prevents the browser native drag/spring effect.
       * When dragging the panel, there is an additional drag that
       * ruins the drag motion of the panel. That additional
       * effect is the spring effect of the browser when dragging reached
       * the end where there is no more to scroll.
       */
      const el = e.currentTarget as HTMLElement
      if (el.dataset['status'] === 'close')
        e.preventDefault()
    }

    const onTouchEnd = (e: TouchEvent) => {
      sendBack(
        DraggablePanelEvent.DidDrag(
          e.changedTouches[0].clientY,
        ),
      )

      const el = e.currentTarget as HTMLElement
      if (el.dataset['status'] === 'close')
        e.preventDefault()
    }

    const onResize = () => {
      sendBack(DraggablePanelEvent.Resize())
    }

    const subscribe = (element: HTMLDivElement) => {
      window.addEventListener('resize', onResize)
      element.addEventListener('mousedown', onMouseDown)
      element.addEventListener('mouseup', onMouseUp)
      element.addEventListener('mouseleave', onMouseLeave)
      element.addEventListener('mousemove', onMouseMove)
      element.addEventListener('touchstart', onTouchStart, { passive: false })
      element.addEventListener('touchend', onTouchEnd)
      element.addEventListener('touchmove', onTouchMove, { passive: false })
      return () => {
        window.removeEventListener('resize', onResize)
        element.removeEventListener('mousedown', onMouseDown)
        element.removeEventListener('mouseup', onMouseUp)
        element.removeEventListener('mouseleave', onMouseLeave)
        element.removeEventListener('mousemove', onMouseMove)
        element.removeEventListener('touchstart', onTouchStart)
        element.removeEventListener('touchend', onTouchEnd)
        element.removeEventListener('touchmove', onTouchMove)
      }
    }

    receive(event => {
      Match.value(event).pipe(
        Match.when({ type: DragListenerEvent.OnAddListeners }, e => {
          if (Option.isSome(unsubscribe)) return

          unsubscribe = Option.some(subscribe(e.getDraggablePanelRef()))

          sendBack(DragListenerEvent.DidAddListener())
        }),
        Match.when({ type: DragListenerEvent.OnRemoveListeners }, () => {
          unsubscribe.pipe(
            Option.match({
              onNone: noop,
              onSome: exec => {
                exec()
              },
            }),
          )

          unsubscribe = Option.none()
          sendBack(DragListenerEvent.DidRemoveListener())
        }),
        Match.orElse(() => {}),
      )
    })

    return () => {
      unsubscribe.pipe(
        Option.match({
          onNone: noop,
          onSome: exec => {
            exec()
          },
        }),
      )
    }
  })

}

export default DragListenerActor
