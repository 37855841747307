import { createIcon } from '@chakra-ui/react'

export const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 16 16',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_929_102847)'>
        <path d='M5.33203 11.333L7.9987 13.9997L10.6654 11.333' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 8L8 14' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.9195 12.0601C14.4991 11.6525 14.9337 11.0708 15.1603 10.3995C15.3869 9.72817 15.3937 9.00209 15.1797 8.32663C14.9657 7.65117 14.542 7.06147 13.9702 6.64312C13.3983 6.22477 12.708 5.99952 11.9995 6.00008H11.1595C10.959 5.21866 10.5838 4.49291 10.0622 3.87746C9.54063 3.26202 8.88623 2.77292 8.14827 2.44698C7.41031 2.12104 6.60803 1.96676 5.80182 1.99576C4.99561 2.02475 4.20648 2.23626 3.49384 2.61436C2.7812 2.99247 2.16362 3.52731 1.68759 4.17863C1.21157 4.82995 0.889502 5.58077 0.745641 6.37458C0.60178 7.16838 0.639874 7.98447 0.857054 8.76142C1.07423 9.53837 1.46484 10.2559 1.99947 10.8601' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_929_102847'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>

  ),
})
