import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { toast } from 'presentation/components/Toast'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

export const useUpdateEstimateInfoErrorHandler = () => {
  const updateEstimateInfoError = useCMAStore(state => {
    const lastError = state.actions.updateEstimateInfo.state.lastError
    return lastError ?? null
  }, shallow)

  useEffect(() => {
    if (!updateEstimateInfoError) return

    const { originalEstimateInfo } = updateEstimateInfoError
    const originalEstimatePart = originalEstimateInfo.method === 'override'
      ? 'previous override'
      : originalEstimateInfo.method === 'from-avg'
        ? 'calculate from average price'
        : 'calculate from average price per sqft'

    toast.error({
      title: 'Failed to update estimate info',
      message: (
        <>
          Estimate info was reverted to “
          {originalEstimatePart}
          ”.
          <br />
          Please refresh to make sure your changes are saved.
        </>
      ),
    })
  }, [updateEstimateInfoError])
}
