import { setupErrorReporting } from 'presentation/main/setupErrorReporting'
import { createRoot } from 'react-dom/client'
import { App } from './presentation/main/App'
import { reportWebVitals } from './reportWebVitals'

setupErrorReporting()

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
