import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { PoolFilterCardDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/PoolFilterCard/PoolFilterCard.dumb'
import { PoolFilterCardDumbProps as Props } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/PoolFilterCard/PoolFilterCard.dumb.props'
import { shallow } from 'zustand/shallow'

export const PoolFilterCard = () => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      filterValue: state.local.report.data.filters.poolsCount,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: Props['onChange'] = filter => {
    void filterComps({
      poolsCount: filter,
    })
  }

  if (!isReady) return null

  return (
    <PoolFilterCardDumb
      value={filterValue || {
        // default
        availablility: 'any',

        // @GENESIS
        shouldMatchSubjectProperty: false,
        shouldExcludeAll: false,
        type: null,
      }}
      onChange={handleOnValueChange}
    />
  )
}
