import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { HTTPError } from 'ky'
import { DateLib } from 'libs/Date'
import { restClient } from 'presentation/libs/client'

export const register: AccessDeps['register'] = async payload =>
  await restClient.post('register', {
    json: {
      email: payload.email,
      password: payload.password,
      firstname: payload.name.first,
      lastname: payload.name.last,
      timezone: DateLib.getTimezone(),
      confirmationUrl: window.location.origin,
      planId: payload.planId,
      ...payload.paymentMethodId ? { card: payload.paymentMethodId } : {},
    },
  })
    .json()
    .then(() => ({
      status: 'success' as const,
    }))
    .catch(async error => {
      const messageFromResponse
        = error instanceof HTTPError
        && await error.response.json()
          .catch(() => null)
          .then(data => data?.error?.type)

      if (messageFromResponse === 'email_already_exists')
        return { status: 'email-taken-error' as const }

      throw error
    })
