import { Button, ButtonProps, Checkbox, Popover, PopoverTrigger, Text, VStack } from '@chakra-ui/react'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsivePopoverContent } from 'presentation/components/ResponsivePopoverContent/ResponsivePopoverContent'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'

export type InvestorTypeFilterProps = {
  label?: string
} & ButtonProps

export const InvestorTypeFilter = ({
  children,
  ...props
}: InvestorTypeFilterProps) => {
  const shouldIncludeLandLords = NearbyBuyersHooks.useShouldIncludeLandLords()
  const toggleShouldIncludeLandLords = NearbyBuyersHooks.useToggleShouldIncludeLandLords()
  const shouldIncludeFlippers = NearbyBuyersHooks.useShouldIncludeFlippers()
  const toggleFlippersFilters = NearbyBuyersHooks.useToggleFlippersFilters()
  const { sbp } = useSwitchBreakpointFn()
  return (
    <Popover offset={[0, 12]}>
      <PopoverTrigger>
        <Button
          variant='solid'
          colorScheme={sbp({
            mobSm: 'highlight',
            tabSm: 'neutral',
          })}
          size={sbp({
            mobSm: 'sm',
            mob: 'md',
          })}
          rightIcon={<ChevronDownIcon boxSize={3} />}
          {...props}
        >
          {children ?? (
            <Breakpoint
              mobSm='Type'
              tabSm='Investor Type'
            />
          )}
        </Button>
      </PopoverTrigger>
      <ResponsivePopoverContent
        modalProps={{
          portalProps: {
            appendToParentPortal: false,
          },
        }}
      >
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
          h='5'
          lineHeight='5'
        >
          Investor Type
        </Text>

        <VStack
          mt={1}
          spacing={2}
          align='stretch'
        >
          <Checkbox
            h={5}
            isChecked={shouldIncludeLandLords}
            onChange={() => toggleShouldIncludeLandLords()}
          >
            Landlords
          </Checkbox>
          <Checkbox
            h={5}
            isChecked={shouldIncludeFlippers}
            onChange={() => toggleFlippersFilters()}
          >
            Flippers
          </Checkbox>
        </VStack>
      </ResponsivePopoverContent>
    </Popover>
  )
}
