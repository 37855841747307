import { Box, BoxProps, Flex, FlexProps, IconButton } from '@chakra-ui/react'
import { Option } from 'effect'
import ReactLib from 'libs/ReactLib'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ForwardedRef, PropsWithChildren, forwardRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { ArrowLeftIcon } from '../Icons'
import { DraggablePanelParts } from './DraggablePanel.const'
import DraggablePanelContext from './DraggablePanelContext'
import DraggablePanelEvent from './DraggablePanelEvent'
import DraggablePanelMachine from './DraggablePanelMachine'

/**
 * Tries to immitate mobile draggable modal from bottom.
 * When dragged, it goes to fullscreen once it passes a drag threshold.
 * In this implementation, dragging beyond 50% of the screen height
 * will go to fullscreen. Otherwise, will revert back to the starting
 * height.
 */
const DraggablePanel = ({ children, ...props }: FlexProps) => {
  const actorRef = DraggablePanelContext.useActorRef()
  const state = DraggablePanelContext.useActorState()

  const [draggablePanelRef, setDraggablePanelRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (draggablePanelRef) {
      actorRef.send(
        DraggablePanelEvent.DidMount(
          draggablePanelRef,
        ),
      )
    }
  }, [draggablePanelRef])

  return (
    <Flex
      className={DraggablePanelParts.Panel}
      ref={setDraggablePanelRef}
      flexDir='column'
      bgColor='accent.hover'
      borderTopRadius={state === 'Open' ? 0 : 3}
      shadow='vscroll-fade-bottom'
      position='absolute'
      bottom={0}
      left={0}
      right={0}
      overflow='hidden'
      {...props}
    >
      <DraggablePanelHandle />

      <Box
        className={DraggablePanelParts.Header}
      >
        {/** header placeholder */}
      </Box>

      {/**
        * @NOTE: If child is a flex with items and overflow of auto
        * child items of that flex should have flex-shrink=0 in order
        * for the overflow to work. Otherwise, the flex container
        * will try to shrink it because of the constraint of this parent
        */}
      <Box
        className={DraggablePanelParts.Body}
        flex='1'
        minH={0}
      >
        {/** body placeholder */}
      </Box>

      {children}
    </Flex>
  )
}

export type DraggablePanelHeaderProps =
  & Omit<FlexProps, 'children'>
  & {
    backLabel?: string
  }
  & ReactLib.ChildrenOrFunc<{
    state: DraggablePanelMachine.ActorState
  }>

export const DraggablePanelHeader = forwardRef(({
  children,
  backLabel,
  ...props
}: DraggablePanelHeaderProps,
ref: ForwardedRef<HTMLDivElement>,
) => {
  const context = DraggablePanelContext.useActorContext()
  const state = DraggablePanelContext.useActorState()

  return context.getDraggablePanelRef().pipe(
    Option.flatMap(el =>
      Option.fromNullable(el.querySelector(`.${DraggablePanelParts.Header}`)),
    ),
    Option.map(headerEl => createPortal(
      <Flex
        ref={ref}
        bgColor='special.500'
        p={1}
        minH={6.5}
        {...props}
      >
        <ClosePanelButton>
          {backLabel}
        </ClosePanelButton>

        {typeof children === 'function'
          ? children({ state })
          : children}
      </Flex>,
      headerEl,
    )),
    Option.getOrNull,
  )
})

DraggablePanelHeader.displayName = 'DraggablePanelHeader'

export type DraggablePanelBodyProps =
  & Omit<BoxProps, 'children'>
  & ReactLib.ChildrenOrFunc<{
    state: DraggablePanelMachine.ActorState
  }>

export const DraggablePanelBody = forwardRef(({
  children,
}: DraggablePanelBodyProps,
ref: ForwardedRef<HTMLDivElement>,
) => {
  const context = DraggablePanelContext.useActorContext()
  const state = DraggablePanelContext.useActorState()

  return context.getDraggablePanelRef().pipe(
    Option.flatMap(el =>
      Option.fromNullable(el.querySelector(`.${DraggablePanelParts.Body}`)),
    ),
    Option.map(bodyEl => createPortal(
      <Box ref={ref}>
        {typeof children === 'function'
          ? children({ state })
          : children}
      </Box>,
      bodyEl,
    )),
    Option.getOrNull,
  )
})

DraggablePanelBody.displayName = 'DraggablePanelBody'

const DraggablePanelHandle = () => (
  <Box
    className={DraggablePanelParts.Handle}
    w={6}
    h={0.5}
    bgColor='ondark.6'
    borderRadius={1}
    position='absolute'
    top={1}
    left='50%'
    transformOrigin='center'
    transform='translateX(-50%)'
    zIndex='1'
  />
)

export default DraggablePanel

const ClosePanelButton = (props: PropsWithChildren) => {
  const actorRef = DraggablePanelContext.useActorRef()
  const onClose = () => {
    actorRef.send(DraggablePanelEvent.Close())
  }
  const onTouchClose = () => {
    actorRef.send(DraggablePanelEvent.Close())
  }
  return (
    <>
      <Flex
        alignItems='center'
        gap={1}
      >
        <IconButton
          aria-label='Save to List'
          size='md'
          key='right-toolbar-item'
          variant='icon-ghost'
          colorScheme='ondark'
          onClick={onClose}
          onTouchEnd={onTouchClose}
        >
          <ArrowLeftIcon />
        </IconButton>

        <Box
          color='ondark.2'
          textStyle={mbp({
            mobSm: 'bodyMFat',
            mob: 'bodyXLFat',
          })}
        >
          {props.children}
        </Box>
      </Flex>
    </>
  )
}
