import { useTheme } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'presentation/const/env.const'
import { memo, PropsWithChildren } from 'react'

if (!STRIPE_KEY) throw new Error('PAYMENT_KEY missing')

const stripePromise = loadStripe(STRIPE_KEY)

const STRIPE_OPTS: StripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700',
    },
  ],
}

export const StripeProvider = memo<PropsWithChildren>(props => {
  const theme = useTheme()

  return (
    <Elements
      key={theme.id}
      stripe={stripePromise}
      options={STRIPE_OPTS}
      {...props}
    />
  )
})

StripeProvider.displayName = 'StripeProvder'
