import { Button, ButtonProps, HStack, IconButton, Menu, MenuButton, Portal, useEventListener } from '@chakra-ui/react'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import { ParcelListManagerProvider } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { ChevronDownIcon, KebabIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { MOBILE_SCROLLABLE_LAYOUT_ID } from 'presentation/layouts/Layout'
import PropertySaveToListAction from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/PropertySaveToListAction'
import { usePropertySaveToListModal, usePropertySaveToListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/usePropertySaveToListModal'
import RemoveFromAllListAction from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/RemoveFromAllListAction'
import RemoveFromHistoryAction from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/RemoveFromHistoryAction'
import { useEffect, useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

const MobileActionSheet = (props: { parcelId: ParcelId | null }) => {
  const [isOpen, setIsOpen] = useState(false)
  const modalState = usePropertySaveToListModal()
  const { close } = usePropertySaveToListModalActions()

  const shouldHideMenuButton
    = modalState.status === 'open'
    || isOpen

  useEffect(() => {
    if (isOpen)
      close()
  }, [isOpen])
  return (
    <Portal>
      <Menu
        placement='bottom-end'
        variant='actionSheet'
        closeOnSelect={false}
        closeOnBlur={false}
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
        onOpen={() => { setIsOpen(true) }}
      >
        <ActionsButton
          aria-label='Actions'
          visibility={shouldHideMenuButton ? 'hidden' : 'visible'}
          colorScheme='positive'
          textStyle='bodyLFat'
          position='fixed'
          right={1}
          bottom={1.5}
          zIndex='popover'
          leftIcon={<KebabIcon boxSize={3} />}
          shadow='float'
        >
          Actions
        </ActionsButton>
        <ResponsiveMenuList
          modalContentProps={{
            sx: {
              '.chakra-modal__close-button': {
                display: 'none',
              },
            },
          }}
        >
          {isNonNullable(props.parcelId) && (
            <ParcelListManagerProvider parcelId={props.parcelId}>
              <>
                <PropertySaveToListAction />
                <RemoveFromAllListAction />
              </>
            </ParcelListManagerProvider>
          )}

          <RemoveFromHistoryAction />

          <HStack justifyContent='flex-end' pb={1.5}>
            <Button
              aria-label='Actions'
              size='lg'
              variant='icon-solid'
              colorScheme='negative'
              h={6.5}
              w={6.5}
              border={0}
              shadow='float'
              onClick={() => { setIsOpen(false) }}
            >
              <ChevronDownIcon boxSize={3} />
            </Button>
          </HStack>
        </ResponsiveMenuList>
      </Menu>
    </Portal>
  )
}

export default MobileActionSheet

const ActionsButton = ({ children, leftIcon, ...props }: ButtonProps) => {
  /**
   * @NOTE: Hide button label when page is scrolling
   */
  const [isScrolling, setIsScrolling] = useState(false)

  let scrollTimeout: NodeJS.Timeout

  const handleScroll = () => {
    if (!isScrolling)
      setIsScrolling(true)

    clearTimeout(scrollTimeout)

    scrollTimeout = setTimeout(() => {
      setIsScrolling(false)
    }, 1000)
  }

  useEffect(() => {
    const element = document.getElementById(MOBILE_SCROLLABLE_LAYOUT_ID)

    if (!element) return

    if (element.scrollTop > 0)
      setIsScrolling(true)

    return () => clearTimeout(scrollTimeout)
  }, [])

  useEventListener('scroll', handleScroll)

  return (
    <>
      {isScrolling
        ? (
          <MenuButton
            as={IconButton}
            icon={leftIcon}
            variant='icon-solid'
            size='lg'
            h={6.5}
            w={6.5}
            border={0}
            {...props}
          />
        )
        : (
          <MenuButton
            as={Button}
            leftIcon={leftIcon}
            variant='solid'
            size='lg'
            h={6.5}
            {...props}
          >
            {children}
          </MenuButton>
        )}
    </>
  )
}
