import { createIcon } from '@chakra-ui/react'

export const CircleInfoIcon2 = createIcon({
  displayName: 'CircleInfoIcon2',
  viewBox: '0 0 24 24',
  path: (
    <>
      <circle cx='12' cy='12' r='7.65' stroke='currentColor' fill='none' strokeWidth='1.65' />
      <path d='M12 16V12' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
      <circle cx='12' cy='8.5' r='1' fill='currentColor' />
    </>
  ),
})
