import { Box, BoxProps, useModalContext, useModalStyles } from '@chakra-ui/react'
import { ModalCloseIcon } from 'presentation/components/Modal/ModalCloseIcon'
import { FC, MouseEventHandler, useCallback } from 'react'

/**
 * Like Chakra's CloseButton, but with a custom icon
 * @TODOSOON Fix cusror pointer not working on hover
 */
export const ModalCloseButton: FC<Omit<BoxProps, 'onClick'> & {
  onClose?: () => void
  onClick?: MouseEventHandler<HTMLButtonElement>
}> = ({ onClose: onCloseFromProps, onClick, ...props }) => {
  const styles = useModalStyles()
  const ctx = useModalContext()
  const onClose = onCloseFromProps || ctx.onClose
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(ev => {
    ev.stopPropagation()
    onClose()
    onClick?.(ev)
  }, [onCloseFromProps, onClick])

  return (
    <Box
      pointerEvents='auto'
      className='chakra-modal__close-button'
      as='button'
      type='button'
      __css={styles.closeButton}
      {...props}
      onClick={handleClick}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <ModalCloseIcon
        boxSize='1.5'
        color='inherit'
      />
    </Box>
  )
}
