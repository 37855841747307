import { Box, BoxProps, Image, VStack } from '@chakra-ui/react'
import { subMonths } from 'date-fns'
import { MLSOrganization } from 'features/valueObjects/MLSOrganization'
import { DateLib } from 'libs/Date'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Fragment } from 'react'

export type MLSDisclaimerProps = BoxProps & {
  organizations: MLSOrganization[]
  updatedDate?: Date
  maxMonthsFilter?: number
}

export const MLSDisclaimer = ({ organizations, maxMonthsFilter, updatedDate, ...props }: MLSDisclaimerProps) => {
  if (!organizations.length) return null

  const organizationsListText = organizations.map(org => org.name).join(', ')

  let forThePeriodPart = ''
  if (maxMonthsFilter && updatedDate) {
    const startDate = subMonths(updatedDate, maxMonthsFilter)
    const endDate = updatedDate

    forThePeriodPart = `for the period ${
      DateLib.formatMMDDYYYYSlashed(startDate)
    } through ${
      DateLib.formatMMDDYYYYSlashed(endDate)
    }`
  }

  const asOfDatePart = DateLib.formatDateTimeTZ(updatedDate || new Date())

  return (
    <VStack
      spacing={1}
      alignItems='flex-start'
      textStyle={mbp({ mobSm: 'bodyM', tabSm: 'bodyL' })}
      color='graystrong.400'
      textAlign='left'
      {...props}
    >
      <Box>
        Disclosure: An AVM or CMA is an estimated sale price for a property. It is not the same
        as the opinion of value in an appraisal developed by a licensed appraiser under
        the Uniform Standards of Professional Appraisal Practice.
      </Box>

      <Box>
        This analysis has not been performed in accordance with the Uniform Standards of Professional Appraisal Practice which requires valuers to act as unbiased, disinterested third parties with impartiality, objectivity and independence and without accommodation of personal interest. It is not to be construed as an appraisal and may not be used as such for any purpose.
      </Box>

      <Box>
        Based on MLS listing data from the
        {' '}
        {organizationsToNamesPhrase(organizations)}
        {forThePeriodPart && ` ${forThePeriodPart}`}
        {' '}
        as of
        {' '}
        {asOfDatePart}
        .
      </Box>

      <Box>
        This data is only for consumers/clients of Propelio Realty, LLC. If you have an exclusive
        {' '}
        representation agreement with another broker, please let us know so we may discontinue
        {' '}
        this service.
      </Box>

      <Box>
        We do not independently verify the currency, completeness, accuracy, or authenticity of the
        {' '}
        data contained herein. The data may be subject to transcription and transmission
        {' '}
        errors. Accordingly, the data is provided on an ‘as is, as available’ basis only,
        {' '}
        including all faults, with all properties subject to prior sale or withdrawal and may
        {' '}
        not reflect all real estate activity in the market. Neither Propelio Realty,
        {' '}
        the Board of REALTORS®, nor
        {organizationsListText}
        {' '}
        guarantees or is in any way
        {' '}
        responsible for its accuracy.
      </Box>

      <Box>
        Data provided is exclusively for personal, non-commercial use and may not be redistributed
        {' '}
        or reproduced.
      </Box>

      <Box>
        {organizations.map(org => (
          <Fragment key={org.id}>
            The compilation of MLS listings and each individual MLS listing marked with
            <Image
              mx='0.5'
              verticalAlign='middle'
              display='inline-block'
              src={org.logo}
              maxH='4'
            />
            are ©
            {new Date().getFullYear()}
            {' '}
            by
            {' '}
            {org.name}
            . All Rights Reserved.
            {' '}
          </Fragment>
        ))}
      </Box>
    </VStack>
  )
}

const organizationsToNamesPhrase = (organizations: MLSOrganization[]) =>
  organizations.reduce((phrase, org, index) => {
    if (!phrase) return org.name
    const conjunction = index === organizations.length - 1 ? 'and' : ','
    return `${phrase} ${conjunction} ${org.name}`
  }, '')
