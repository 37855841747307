import { NullableMinRange } from 'features/valueObjects/Range'

type Preset = {
  label: string
  value: NullableMinRange
}

const SOLD_WITHIN_PRESETS: Preset[] = [
  { label: '1 month', value: [0, 1] },
  { label: '2 months', value: [0, 2] },
  { label: '3 months', value: [0, 3] },
  { label: '6 months', value: [0, 6] },
  { label: '9 months', value: [0, 9] }, // @NOTE added for legacy support
  { label: '1 year', value: [0, 12] },
  { label: '1.5 years', value: [0, 18] }, // @NOTE added for legacy support
  { label: '2 years', value: [0, 24] },
  { label: '5 years', value: [0, 60] },
]

const DEFAULT = SOLD_WITHIN_PRESETS[3] // 6 months

const ANY_TIME = 'Any Time'

export const SoldWithinRange = {
  findByIndex: (index: number): NullableMinRange =>
    SOLD_WITHIN_PRESETS[index].value ?? DEFAULT.value,

  toLabel: (range: NullableMinRange): string => {
    const preset = SOLD_WITHIN_PRESETS.find(preset => {
      const doesMinMatch = range[0] === null || range[0] === preset.value[0]
      const doesMaxMatch = range[1] === null || range[1] === preset.value[1]
      return doesMinMatch && doesMaxMatch
    })
    return preset?.label ?? ANY_TIME
  },

  fromLabel: (rangeString: string): NullableMinRange => {
    const preset = SOLD_WITHIN_PRESETS.find(preset => preset.label === rangeString)
    return preset?.value ?? DEFAULT.value
  },

  any: ANY_TIME,

  PRESETS: SOLD_WITHIN_PRESETS,

  default: DEFAULT.value,
}
