import { ListTypes } from '__generated__/graphql'
import { Effect, Option, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { GET_MARKETING_LISTS } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/schema'
import toDomain, { MARKETINGLIST_PAGINATION_LIMIT } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/toDomain'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { apolloClient } from 'presentation/libs/client'

const GetPrevByCursorResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetPrevByCursor) => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.query({
          query: GET_MARKETING_LISTS,
          variables: {
            last: MARKETINGLIST_PAGINATION_LIMIT,
            before: Option.getOrNull(request.cursor),
            types: [ListTypes.LegacySellers] },
        }),
      catch: error => new UnknownException({ message: String(error) }),
    }),
    Effect.andThen(result => toDomain(result.data)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default GetPrevByCursorResolver
