import { LIST_PREVIEW_SUBSCREEN_KEYS } from 'presentation/screens/ListPreviewScreen/ListPreviewScreen.const'
import { ListPreviewMobileListSubscreen } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMobileListSubscreen'
import { ListPreviewMobileMapSubscreen } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMobileMapSubscreen'
import { useLocation } from 'react-router-dom'

const usePreviewSubscreen = () => {
  const { state } = useLocation()
  const defaultSubscreen = LIST_PREVIEW_SUBSCREEN_KEYS.MAP
  const subscreen = state?.subscreen || defaultSubscreen
  return subscreen
}

export const ListPreviewMobile = () => {
  const subscreen = usePreviewSubscreen()

  return subscreen === LIST_PREVIEW_SUBSCREEN_KEYS.MAP
    ? <ListPreviewMobileMapSubscreen />
    : <ListPreviewMobileListSubscreen />
}
