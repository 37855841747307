import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { NullableMinRange } from 'features/valueObjects/Range'
import { SoldWithinFilterCardDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/SoldWithinFilterCard/SoldWithinFilterCard.dumb'

export const SoldWithinFilterCard = () => {
  const reportState = useCMAStore(state => state.local.report)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange = (value: NullableMinRange) => {
    void filterComps({
      soldWithinMonths: {
        max: value[1],
        range: value,
      },
    })
  }

  if (!checkStateHasReportData(reportState)) return null

  const value = reportState.data.filters.soldWithinMonths.range

  return (
    <SoldWithinFilterCardDumb
      value={value}
      onChange={handleOnValueChange}
    />
  )
}
