import { Flex } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { PartialRange } from 'features/valueObjects/Range'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { YearBuiltFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/YearBuiltFilterDropdown/YearBuiltFilterDropdown.dumb.props'
import { FilterContentGroup } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/FilterContentGroup'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError'
import { PartialRangeFormErrors, PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

export const YearBuiltFilterDropdownDumb = (
  props: YearBuiltFilterDropdownDumbProps,
) => {
  const [hasError, setHasError] = useState(false)
  const rangeString = formatDateRange(props.value)

  const handleOnError = (errors: PartialRangeFormErrors) =>
    isNonNullable(errors.min || errors.max) ? setHasError(true) : setHasError(false)
  return (
    <FilterPopover>
      <FilterPopoverButton
        label='Year Built'
        minW={mbp({ mobSm: '120px', dtLg: '140px' })}
        hasError={hasError}
      >
        {rangeString}
      </FilterPopoverButton>
      <FilterPopoverContent>
        <FilterContentGroup title='Year Built'>
          <Flex gap={3} flexDir='column' maxW='350px'>
            <PartialRangeInputV2
              initialValue={props.value}
              onDebouncedValidChange={newRange => {
                props.onChange(newRange)
              }}
              subjectValue={props.subjectValue?.toString()}
              validateEither={isNonOr4Digit}
              onError={handleOnError}
            />

            {/* @GENESIS */}
            {/* <FilterActionButtonGroup>
              <FilterResetButton onClick={handleOnReset} />
              <FilterDoneButton
                onClick={handleOnDone}
                isDisabled={hasError.min || hasError.max}
              />
            </FilterActionButtonGroup> */}

            {props.filterErrorMsg && (
              <CompsFilterCardError error={props.filterErrorMsg} />
            )}
          </Flex>
        </FilterContentGroup>
      </FilterPopoverContent>
    </FilterPopover>
  )
}

const formatDateRange = (range: PartialRange) =>
  pipe(
    Match.value(range),
    Match.when([Match.number, Match.null], ([min]) => `${formatDate(min)} & later`),
    Match.when([Match.null, Match.number], ([,max]) => `up to ${formatDate(max)}`),
    Match.when([Match.number, Match.number], ([min, max]) =>
      `${formatDate(min)}-${formatDate(max)}`),
    Match.orElse(() => 'Any Year'),
  )

const formatDate = (date: number) => {
  const dateStr = date.toString()
  return `‘${dateStr.substring(2, 4)}`
}

const isNonOr4Digit = (year: number | null) => {
  if (year === null) return true
  return /^\d{0}$|^\d{4}$/.test(year.toString())
}
