import GetBuyerDealsLive from 'features/NearbyBuyers/repository/GetBuyerDealsLive'
import GetNearbyBuyersLive from 'features/NearbyBuyers/repository/GetNearbyBuyersLive'
import NearbyBuyersRepo from 'features/NearbyBuyers/repository/NearbyBuyersRepo'

const NearbyBuyersRepoLive: NearbyBuyersRepo.Shape = {
  getLists: (() => {}) as any,
  saveNearbyBuyersToList: (() => {}) as any,
  getNearbyBuyers: GetNearbyBuyersLive,
  getBuyerDeals: GetBuyerDealsLive,
}

export default NearbyBuyersRepoLive
