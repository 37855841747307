import { ModalOverlay } from '@chakra-ui/react'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import SaveBuyersToListCard from 'features/NearbyBuyers/views/SaveBuyersToListModal/SaveBuyersToListCard'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'

const SaveBuyersToListModal = () => {
  const close = NearbyBuyersHooks.useCloseSaveToListModal()

  return (
    <Modal
      isOpen={true}
      onClose={close}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <SaveBuyersToListCard />
      </ModalContent>
    </Modal>
  )
}

export default SaveBuyersToListModal
