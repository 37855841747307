import { useBreakpointValue } from '@chakra-ui/react'
import { SIDEBAR_DESKTOP_W } from 'presentation/layouts/Layout/components/SidebarDesktop/SidebarDesktop'

export const useLayoutWidth = () => {
  const offsetWidth = useBreakpointValue({
    tabSm: 0,
    dtSm: SIDEBAR_DESKTOP_W,
  }) || 0

  const layoutWidth = `calc(var(--app-width) - ${offsetWidth}px)`

  return {
    layoutWidth,
    offsetWidth,
  }
}
