import { Center, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, IconButton, Image, Text } from '@chakra-ui/react'
import { isNotNull } from 'effect/Predicate'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import image from 'presentation/assets/img/3d/2-peeps-1-magnifying-glass.png'
import { CloseIcon } from 'presentation/components/Icons'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { CustomAccordion } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard'
import { SkiptraceAccordionItem } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceAccordionItem'
import React from 'react'
import { isNumber } from 'remeda'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const BatchSkiptraceDrawer = () => {
  const drawerApi = useBatchSkiptraceDrawer()
  const drawerActions = useBatchSkiptraceDrawerActions()

  const [index, setIndex] = React.useState(0)

  const outerPadding = useSwitchBreakpoint({
    mobSm: 2,
    tabSm: 3,
  })

  return (
    <Drawer
      isOpen={drawerApi.status === 'open'}
      placement='right'
      onClose={drawerActions.close}
    >
      <DrawerOverlay />
      {drawerApi.status === 'open' && (() => {
        const skiptrace = drawerApi.payload.skiptrace
        const ownerNameAndLine1 = [
          skiptrace.subject.owner,
          skiptrace.subject.address.line1,
        ]
          .filter(isNotNull)
          .join(' - ')

        return (
          <DrawerContent maxW={{ dtSm: '720px', dtLg: '984px', tabSm: '520px' }} borderBottomLeftRadius={{ base: 0, dtSm: 4 }}>
            <DrawerHeader pt={3} px={outerPadding} pb={0}>
              <HStack mr={-1}>
                <Text
                  textStyle='h4'
                  color='accent.blue-text'
                  flex='1'
                >
                  Skiptrace results for
                  {' '}
                  &rdquo;
                  {ownerNameAndLine1}
                  &ldquo;
                </Text>

                <IconButton
                  aria-label='Close'
                  size='lg'
                  variant='icon-ghost'
                  colorScheme='onlight'
                  color='grayweak.900'
                  icon={<CloseIcon />}
                  onClick={drawerActions.close}
                />
              </HStack>
            </DrawerHeader>

            <DrawerBody px={outerPadding} pt={4}>
              {skiptrace.owners.length > 0
                ? (
                  <CustomAccordion
                    allowToggle
                    index={index}
                    onChange={index => setIndex(isNumber(index) ? index : index[0])}
                    mt={0}
                  >
                    {skiptrace.owners.map(owner => (
                      <SkiptraceAccordionItem
                        key={owner.name}
                        owner={owner}
                      />
                    ))}
                  </CustomAccordion>
                )
                : (
                  <Center flex='1' h='full' flexDir='column' gap={2}>
                    <Image
                      src={image}
                      boxSize='216px'
                    />
                    <Text
                      textStyle='h1'
                      color='graystrong.500'
                      textAlign='center'
                    >
                      Oops! No Skiptrace Results for
                      {' '}
                      <br />
                      {' '}
                      &rdquo;
                      {ownerNameAndLine1}
                      &ldquo;
                    </Text>
                    <Text
                      textStyle='h4Heavy'
                      color='positive.500'
                      textAlign='center'
                    >
                      You were not charged for this skiptrace.
                    </Text>
                  </Center>
                )}
            </DrawerBody>
          </DrawerContent>
        )
      })()}
    </Drawer>
  )
}

type Payload = {
  skiptrace: Skiptrace.Result
}

type BatchSkiptraceDrawerState = {
  state: {
    status: 'open'
    payload: Payload
  } | {
    status: 'closed'
  }
  open: (payload: Payload) => void
  close: () => void
}

const useBatchSkiptraceDrawerStore = create<BatchSkiptraceDrawerState>()(immer(set => ({
  state: {
    status: 'closed',
  },
  open: payload => set(state => {
    state.state = {
      status: 'open',
      payload,
    }
  }),
  close: () => set(state => {
    state.state = {
      status: 'closed',
    }
  }),
})))

export const useBatchSkiptraceDrawer = () =>
  useBatchSkiptraceDrawerStore(store => store.state)

export const useBatchSkiptraceDrawerActions = () =>
  useBatchSkiptraceDrawerStore(store => ({
    open: store.open,
    close: store.close,
  }))
