import { BillingIntervals } from '__generated__/graphql'

/**
 * @Note: Not sure where to put this yet because it depends on the graphql generated types
 */
export const formatToFrequency = (intervalUnit: BillingIntervals) => {
  switch (intervalUnit) {
  case 'MONTH': return 'Monthly'
  case 'YEAR': return 'Yearly'
  case 'WEEK': return 'Weekly'
  case 'DAY': return 'Daily'
  default:
    throw new Error(`Unknown interval unit: ${intervalUnit}`)
  }
}
