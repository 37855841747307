import { Box, BoxProps, Fade, Flex, FlexProps, useToken } from '@chakra-ui/react'
import { EventListener } from 'overlayscrollbars'
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { Color, Space } from 'presentation/libs/chakra/chakra.types'
import { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'

type Props = Omit<
  BoxProps,
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomLeftRadius'
  | 'borderBottomRightRadius'
  | 'borderTopRadius'
  | 'borderBottomRadius'
  | 'borderLeftRadius'
  | 'borderRightRadius'
  | 'borderRadius'
> & {
  fadeTopSize?: number
  fadeBottomSize?: number
  fadeSize?: number
  fadeColor: Exclude<Color, []>

  borderTopLeftRadius?: Exclude<Space | number, []>
  borderTopRightRadius?: Exclude<Space | number, []>
  borderBottomLeftRadius?: Exclude<Space | number, []>
  borderBottomRightRadius?: Exclude<Space | number, []>
  borderTopRadius?: Exclude<Space | number, []>
  borderBottomRadius?: Exclude<Space | number, []>
  borderLeftRadius?: Exclude<Space | number, []>
  borderRightRadius?: Exclude<Space | number, []>
  borderRadius?: Exclude<Space | number, []>

  containerProps?: FlexProps
}

const DEFAULT_FADE_SIZE = 6.5

/**
 * You can use all BoxProps except for margin props.
 *
 * For margin, it's better to add it to `containerProps`.
 *
 * This component is made of 2 important parts, first is the container.
 *
 * Container was necessary to serve as anchor to fade elements (with position absolute).
 *
 * Second is the scroll component, which is the actual scrollable component.
 *
 * For some reason, it looks broken under Storybook's Docs tab. But it should work fine in the app.
 *
 * @param props
 * @returns
 *
 * @TODO Shadow should be progressive, not instant depending on distance to the edge
 */
export const ScrollWithFade: FC<Props> = props => {
  const [ref, setRef] = useState<OverlayScrollbarsComponentRef | null>(null)
  const { shouldShowTopFade, shouldShowBottomFade } = useScrollFadeState(ref)
  const {
    fadeTopSize,
    fadeBottomSize,
    fadeColor,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    containerProps,
    scrollComponentProps,
  } = useExtractStyleProps(props)

  const value: ScrollWithFadeContextType = useMemo(() => ({ ref }), [ref])

  return (
    <Flex
      pos='relative'
      flexDir='column'
      minH='0'
      {...containerProps}
    >
      <ScrollWithFadeContext.Provider value={value}>
        <Box
          ref={setRef as any}
          as={OverlayScrollbarsComponent}
          h='full'
          {...scrollComponentProps}
        />
      </ScrollWithFadeContext.Provider>

      <Fade in={shouldShowTopFade} unmountOnExit>
        <Box
          h={fadeTopSize}
          pos='absolute'
          top='0'
          right='0'
          left='0'
          bg={`linear-gradient(to bottom, ${fadeColor}, transparent)`}
          borderTopLeftRadius={borderTopLeftRadius}
          borderTopRightRadius={borderTopRightRadius}
        />
      </Fade>

      <Fade in={shouldShowBottomFade} unmountOnExit>
        <Box
          h={fadeBottomSize}
          pos='absolute'
          bottom='0'
          right='0'
          left='0'
          bg={`linear-gradient(to top, ${fadeColor}, transparent)`}
          borderBottomLeftRadius={borderBottomLeftRadius}
          borderBottomRightRadius={borderBottomRightRadius}
        />
      </Fade>
    </Flex>
  )
}

const useExtractStyleProps = ({
  fadeColor: fadeColorFromProps,
  fadeTopSize: fadeTopSizeFromProps,
  fadeBottomSize: fadeBottomSizeFromProps,
  fadeSize: fadeSizeFromProps,
  containerProps,
  ...scrollComponentProps
}: Props) => {
  const fadeTopSize = useToken('space', fadeTopSizeFromProps || fadeSizeFromProps || DEFAULT_FADE_SIZE)
  const fadeBottomSize = useToken('space', fadeBottomSizeFromProps || fadeSizeFromProps || DEFAULT_FADE_SIZE)
  const fadeColor = useToken('colors', fadeColorFromProps)

  const borderTopLeftRadius = useToken(
    'space',
    scrollComponentProps.borderTopLeftRadius
    || scrollComponentProps.borderTopRadius
    || scrollComponentProps.borderLeftRadius
    || scrollComponentProps.borderRadius
    || '0',
  )

  const borderTopRightRadius = useToken(
    'space',
    scrollComponentProps.borderTopRightRadius
    || scrollComponentProps.borderTopRadius
    || scrollComponentProps.borderRightRadius
    || scrollComponentProps.borderRadius
    || '0',
  )

  const borderBottomLeftRadius = useToken(
    'space',
    scrollComponentProps.borderBottomLeftRadius
    || scrollComponentProps.borderBottomRadius
    || scrollComponentProps.borderLeftRadius
    || scrollComponentProps.borderRadius
    || '0',
  )

  const borderBottomRightRadius = useToken(
    'space',
    scrollComponentProps.borderBottomRightRadius
    || scrollComponentProps.borderBottomRadius
    || scrollComponentProps.borderRightRadius
    || scrollComponentProps.borderRadius
    || '0',
  )

  return {
    fadeTopSize,
    fadeBottomSize,
    fadeColor,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    containerProps,
    scrollComponentProps,
  }
}

const useScrollFadeState = (
  scrollFadeRef: OverlayScrollbarsComponentRef<'div'> | null,
): {
  shouldShowTopFade: boolean
  shouldShowBottomFade: boolean
} => {
  const [shouldShowTopFade, setShouldShowTopFade] = useState(false)
  const [shouldShowBottomFade, setShouldShowBottomFade] = useState(false)

  const updateFade = (element: HTMLElement): void => {
    const isScrolledToTop = element.scrollTop === 0
    const isScrolledToBot = Math.ceil(element.scrollHeight - element.scrollTop) === element.clientHeight

    setShouldShowTopFade(!isScrolledToTop)
    setShouldShowBottomFade(!isScrolledToBot)
  }

  useEffect(() => {
    if (!scrollFadeRef) return

    const instance = scrollFadeRef.osInstance()

    if (!instance) return

    if (instance.state().overflowAmount.y > 0)
      setShouldShowBottomFade(true)

    const updateFadeOnScroll: EventListener<'scroll'> = (_, ev): void => {
      updateFade(ev.target as HTMLElement)
    }

    instance.on('scroll', updateFadeOnScroll)

    return () => {
      instance.off('scroll', updateFadeOnScroll)
    }
  }, [scrollFadeRef])

  return {
    shouldShowTopFade,
    shouldShowBottomFade,
  }
}

export type ScrollWithFadeContextType = {
  ref: OverlayScrollbarsComponentRef | null
}

const ScrollWithFadeContext = createContext<ScrollWithFadeContextType | null>(null)

export const useScrollWithFade = (): ScrollWithFadeContextType => {
  const context = useContext(ScrollWithFadeContext)
  if (!context) throw new Error('ScrollWithFadeContext is not provided')
  return context
}
