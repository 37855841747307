import { BillingIntervals, GetProductsQuery, ProductFeaturesFragment, SubscriptionFeatureIntervalValue } from '__generated__/graphql'
import { Array, Effect, Either, Layer, Match, Option, pipe, String } from 'effect'
import { NoSuchElementException, UnknownException } from 'effect/Cause'
import { apolloClient } from 'presentation/libs/client'
import { GqlPath } from 'presentation/libs/graphql'
import ApplicationError from 'presentation/screens/Plans/PlanComparisonV2/domain/ApplicationError'
import { FeatureAvailableSpec, FeatureLimitedSpec, FeatureQuotaSpec, FeatureUnlimitedSpec, FeatureUsagePricingSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'
import ProductName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductName'
import SubscriptionPlan, { MonthlyPlan, PlanId, YearlyPlan } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import GQLProductFeaturesFragment from '../GQLProductFeaturesFragment'
import SubscriptionProductRepo from '../SubscriptionProductRepo'
import GET_PRODUCTS from './getProducts.schema'

const getProductsLive = Layer.succeed(
  SubscriptionProductRepo.GetProducts,
  () => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.query({
          query: GET_PRODUCTS,
        }),
      // @TODO: error from graphql should be properly parsed for
      catch: error => new UnknownException({ message: error }),
    }),
    Effect.andThen(result => toDomain(result.data)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default getProductsLive

const toDomain = (
  data: GetProductsQuery,
) => {
  const activePlanId = pipe(
    data.myEnterprise?.subscription,
    Option.fromNullable,
    Option.flatMap(sub =>
      sub.__typename === 'BillingSubscriptionActive'
        ? Option.some(sub)
        : Option.none(),
    ),
    Option.map(sub => PlanId.fromString(sub.plan.id)),
  )

  const products = pipe(
    data.billingSubscriptionProducts,
    Array.map(product => toProductDomain({
      activePlanId,
      product,
    })),
    Effect.all,
  )

  return products
}

type GQLSubscriptionProduct = GqlPath<
  GetProductsQuery,
  [
    ['billingSubscriptionProducts', 'BillingSubscriptionProduct'],
  ]
>

type ToProductDomainParams = {
  activePlanId: Option.Option<PlanId>
  product: GQLSubscriptionProduct
}

const toProductDomain = ({
  activePlanId,
  product,
}: ToProductDomainParams) => Effect.gen(function *() {
  const productName = yield * pipe(
    ProductName.fromString(product.name),
    Option.match({
      onNone: () => Effect.die(
        new ApplicationError({
          message: 'Product name was not included in mapping',
        }),
      ),
      onSome: Effect.succeed,
    }),
  )

  const productId = ProductId.fromString(product.id)

  // @NOTE: If the product is free, we need to add a yearly plan with a price of 0
  // This is to show the free plan on the yearly interval. Currently the backend doesn't
  // return a yearly plan for the free product. If this is removed, the free plan will
  // not be shown on the yearly interval.
  const adhocPlans = pipe(
    Match.value(productName),
    Match.when('free', () => {
      const hasYearlyPlan = pipe(
        product.plans,
        Array.some(plan => plan.interval.unit === BillingIntervals.Year),
      )

      return hasYearlyPlan
        ? product.plans
        : Array.append(product.plans, {
          __typename: 'BillingSubscriptionPlan',
          id: 'free-yearly-id',
          name: 'free',
          price: 0,
          interval: {
            __typename: 'BillingSubscriptionPlanInterval',
            unit: BillingIntervals.Year,
          },
        } satisfies GQLSubscriptionProduct['plans'][number])
    }),
    Match.orElse(() => product.plans),
  )

  const plans = yield * findPlans({
    activePlanId,
    productId,
    plans: adhocPlans,
  })

  const features = yield * findFeaturesSpec({
    productId,
    features: product.features,
  })

  /**
   * Some important features information are client side only. It
   * is collocated here together with features provided by the backend
   */
  const syntheticFeatures = yield * makeSyntheticFeaturesSpec(
    productId,
    productName,
  )

  const allFeatures = Array.appendAll(
    syntheticFeatures,
    features,
  )

  return SubscriptionProduct.make({
    id: productId,
    name: productName,
    description: pipe(
      Option.fromNullable(product.description),
      // @Note: Temp fix because backend value does not reflect design
      Option.map(productDesc => productName === 'free'
        ? 'Getting Started'
        : productDesc,
      ),
      Option.getOrElse(() => String.empty),
    ),
    plans,
    features: allFeatures,
  })
})

type FindPlansParams = {
  activePlanId: Option.Option<PlanId>
  productId: ProductId
  plans: GQLSubscriptionProduct['plans']
}

const findPlans = ({
  activePlanId,
  productId,
  plans,
}: FindPlansParams) => pipe(
  plans,
  Array.map(plan => {
    const planId = PlanId.fromString(plan.id)
    return pipe(
      Option.fromNullable(plan.interval.unit),
      Option.flatMap<BillingIntervals, SubscriptionPlan>(unit => pipe(
        Match.value(unit),
        Match.when(BillingIntervals.Year, () => pipe(
          YearlyPlan.make({
            id: PlanId.fromString(plan.id),
            productId,
            price: plan.price,
            isCurrentPlan: pipe(
              activePlanId,
              Option.map(activeId => activeId === planId),
              Option.getOrElse(() => false),
            ),
          }),
          Option.some,
        )),
        Match.when(BillingIntervals.Month, () => pipe(
          MonthlyPlan.make({
            id: PlanId.fromString(plan.id),
            productId,
            price: plan.price,
            isCurrentPlan: pipe(
              activePlanId,
              Option.map(activeId => activeId === planId),
              Option.getOrElse(() => false),
            ),
          }),
          Option.some,
        )),
        Match.orElse(() => Option.none()),
      )),
      Option.match({
        onNone: () => Effect.die(new ApplicationError({
          message: 'Unable to construct plan',
        })),
        onSome: plan => Effect.succeed(plan),
      }),
    )
  }),
  Effect.all,
)

export const makeSyntheticFeaturesSpec = (
  productId: ProductId,
  productName: ProductName,
) => {
  const propertyFindersSpec = pipe(
    findPropertyFindersFeatureSpec(productId, productName),
    Effect.option,
  )

  const d4dSpec = pipe(
    findDrivingForDollarsFeatureSpec(productId, productName),
    Effect.option,
  )

  const userAccessSpec = pipe(
    findUserAccessFeatureSpec(productId, productName),
    Effect.option,
  )

  const liveSupportSpec = pipe(
    findLiveSupportFeatureSpec(productId, productName),
    Effect.option,
  )

  const foundSyntheticFeaturesSpec = pipe(
    Array.make(
      propertyFindersSpec,
      d4dSpec,
      userAccessSpec,
      liveSupportSpec,
    ),
    Effect.all,
    Effect.andThen(v => Array.getSomes(v)),
  )

  return foundSyntheticFeaturesSpec
}

type FindFeaturesParams = {
  productId: ProductId
  features: ProductFeaturesFragment[]
}

export const findFeaturesSpec = ({
  productId,
  features,
}: FindFeaturesParams) => {
  const leadFeatureSpec = pipe(
    findLeadFeatureSpec(productId, features),
    Effect.option,
  )
  const skiptraceFeatureSpec = pipe(
    findSkiptraceFeatureSpec(productId, features),
    Effect.option,
  )
  const compsFeatureSpec = pipe(
    findCompsFeatureSpec(productId, features),
    Effect.option,
  )

  const directMailFeatureSpec = pipe(
    findDirectMailFeatureSpec(productId, features),
    Effect.option,
  )

  const teamFeatureSpec = pipe(
    findTeamFeatureSpec(productId, features),
    Effect.option,
  )

  const foundFeaturesSpec = pipe(
    Array.make(
      leadFeatureSpec,
      skiptraceFeatureSpec,
      compsFeatureSpec,
      directMailFeatureSpec,
      teamFeatureSpec,
    ),
    Effect.all,
    Effect.andThen(v => Array.getSomes(v)),
  )

  return foundFeaturesSpec
}


const findLeadFeatureSpec = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * pipe(
    features,
    Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureLeads),
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedSpec> = pipe(
    feature.limits,
    /**
     * @NOTE: Get any limit as there are no requirements to which
     * specific interval to retrieve.
     */
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedSpec.make({
          productId,
          featureName: 'LeadListExports',
        }),
      ),
    }),
  )

  return pipe(
    limit,
    Either.match({
      onLeft: unlimited => unlimited,
      onRight: l => pipe(
        FeatureLimitedSpec.make({
          productId,
          featureName: 'LeadListExports',
          interval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
          limit: l.value,
        }),
      ),
    }),
  )
})

const findSkiptraceFeatureSpec = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * Array.findFirst(
    features,
    GQLProductFeaturesFragment.isSubscriptionFeatureSkiptrace,
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedSpec> = pipe(
    feature.limits,
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedSpec.make({
          productId,
          featureName: 'Skiptrace',
        }),
      ),
    }),
  )

  const pricing = Array.get(feature.prices, 0)

  const price = pipe(
    pricing,
    Option.map(p => p.price),
    Option.getOrElse(() => 0),
  )

  return yield * pipe(
    limit,
    Either.match({
      onLeft: v => Effect.succeed(v),
      onRight: v => pipe(
        FeatureQuotaSpec.make({
          productId,
          featureName: 'Skiptrace',
          quotaLimit: v.value,
          replenishInterval: GQLProductFeaturesFragment.toProductFeatureInterval(v.interval),
          overageCost: price,
        }),
        Effect.succeed,
      ),
    }),
  )
})

const findCompsFeatureSpec = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * Array.findFirst(
    features,
    GQLProductFeaturesFragment.isSubscriptionFeatureComps,
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedSpec> = pipe(
    feature.limits,
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedSpec.make({
          productId,
          featureName: 'Comps',
        }),
      ),
    }),
  )

  const pricing = Array.get(feature.prices, 0)

  const price = pipe(
    pricing,
    Option.map(p => p.price),
    Option.getOrElse(() => 0),
  )

  return yield * pipe(
    limit,
    Either.match({
      onLeft: v => Effect.succeed(v),
      onRight: v => pipe(
        FeatureQuotaSpec.make({
          productId,
          featureName: 'Comps',
          quotaLimit: v.value,
          replenishInterval: GQLProductFeaturesFragment.toProductFeatureInterval(v.interval),
          overageCost: price,
        }),
        Effect.succeed,
      ),
    }),
  )
})

const findDirectMailFeatureSpec = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * pipe(
    features,
    Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureDirectMail),
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedSpec> = pipe(
    feature.limits,
    /**
     * @NOTE: Get any limit as there are no requirements to which
     * specific interval to retrieve.
     */
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedSpec.make({
          productId,
          featureName: 'DirectMailFeature',
        }),
      ),
    }),
  )

  return yield * pipe(
    limit,
    Either.match({
      onLeft: unlimited => Effect.succeed(unlimited),
      onRight: l => pipe(
        feature.prices,
        /**
           * @NOTE: Get any price as there are no requirements to which
           * specific price to retrieve.
           */
        Array.get(0),
        Option.map(p => FeatureUsagePricingSpec.make({
          productId,
          featureName: 'DirectMailFeature',
          price: p.price,
          unit: p.pricingUnit,
          interval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
          unitDescription: pipe(
            Option.fromNullable(p.pricingUnitDescription),
            Option.getOrElse(() => String.empty),
          ),
        })),
        Option.map(feat => Effect.succeed(feat)),
        Option.getOrElse(() => Effect.die(new ApplicationError({
          message: 'Direct mail feature has no price data',
        }))),
      ),
    }),
  )
})

const findTeamFeatureSpec = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => pipe(
  features,
  Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureTeam),
  Option.map(feature => pipe(
    feature.limits,
    Array.get(0),
    Option.map(f => FeatureLimitedSpec.make({
      productId,
      featureName: 'TeamMembers',
      limit: f.value,
      interval: GQLProductFeaturesFragment.toProductFeatureInterval(f.interval), // @TODO get interval
    })),
    Option.getOrElse(() => FeatureUnlimitedSpec.make({
      productId,
      featureName: 'TeamMembers',
    })),
  )),
)

const findPropertyFindersFeatureSpec = (
  productId: ProductId,
  productName: ProductName,
) => pipe(
  Match.value(productName),
  Match.when('free', () => pipe(
    FeatureLimitedSpec.make({
      productId,
      featureName: 'PropertyFinders',
      limit: 0,
      interval: 'monthly',
    }),
    Effect.succeed,
  )),
  Match.when('beginner', () => pipe(
    FeatureLimitedSpec.make({
      productId,
      featureName: 'PropertyFinders',
      limit: 2,
      interval: 'monthly',
    }),
    Effect.succeed,
  )),
  Match.when('intermediate', () => pipe(
    FeatureLimitedSpec.make({
      productId,
      featureName: 'PropertyFinders',
      limit: 2,
      interval: 'monthly',
    }),
    Effect.succeed,
  )),
  Match.when('advanced', () => pipe(
    FeatureLimitedSpec.make({
      productId,
      featureName: 'PropertyFinders',
      limit: 10,
      interval: 'monthly',
    }),
    Effect.succeed,
  )),
  Match.orElse(() => Effect.die(new ApplicationError({
    message: `
      Failed to create a Property Finders value for ${productName}
    `,
  }))),
)

const findUserAccessFeatureSpec = (
  productId: ProductId,
  productName: ProductName,
) => pipe(
  Match.value(productName),
  Match.when('free', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'UserAccess',
    }),
    Effect.succeed,
  )),
  Match.when('beginner', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'UserAccess',
    }),
    Effect.succeed,
  )),
  Match.when('intermediate', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'UserAccess',
    }),
    Effect.succeed,
  )),
  Match.when('advanced', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'UserAccess',
    }),
    Effect.succeed,
  )),
  Match.orElse(() => Effect.die(new ApplicationError({
    message: `
      Failed to create a User Access Feature value for ${productName}
    `,
  }))),
)

const findLiveSupportFeatureSpec = (
  productId: ProductId,
  productName: ProductName,
) => pipe(
  Match.value(productName),
  Match.when('free', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'LiveSupport',
    }),
    Effect.succeed,
  )),
  Match.when('beginner', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'LiveSupport',
    }),
    Effect.succeed,
  )),
  Match.when('intermediate', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'LiveSupport',
    }),
    Effect.succeed,
  )),
  Match.when('advanced', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'LiveSupport',
    }),
    Effect.succeed,
  )),
  Match.orElse(() => Effect.die(new ApplicationError({
    message: `
      Failed to create a Live Support Feature value for ${productName}
    `,
  }))),
)

const findDrivingForDollarsFeatureSpec = (
  productId: ProductId,
  productName: ProductName,
) => pipe(
  Match.value(productName),
  Match.when('free', () => Effect.fail(new NoSuchElementException())),
  Match.when('beginner', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'DrivingForDollars',
    }),
    Effect.succeed,
  )),
  Match.when('intermediate', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'DrivingForDollars',
    }),
    Effect.succeed,
  )),
  Match.when('advanced', () => pipe(
    FeatureAvailableSpec.make({
      productId,
      featureName: 'DrivingForDollars',
    }),
    Effect.succeed,
  )),
  Match.orElse(() => Effect.die(new ApplicationError({
    message: `
      Failed to create a Driving for Dollar Feature value for ${productName}
    `,
  }))),
)
