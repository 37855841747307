import { Grid, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo } from 'presentation/components/molecules/Card/CardCombo'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CardComboBottomSegment, CardComboTopSegment, MarkerLabelDescriptionPairs, MarkerLabelDescriptionPairsProps } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/common'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const OwnershipStep = () => {
  const { sbp } = useSwitchBreakpointFn()
  const markers: MarkerLabelDescriptionPairsProps[] = [{
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification={null}
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={false}
      />
    ),
    label: <>UNKNOWN</>,
    description: 'The ownership status was not included in our data file delivery.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={false}
      />
    ),
    label: <>OWNER OCCUPIED</>,
    description: 'The mailing address of the property matches the property address.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType={null}
        isVacant={true}
        isForeclosure={false}
        isSenior={false}
        shouldHideSuperscript
      />
    ),
    label: <>OWNER OCCUPIED BUT VACANT</>,
    description: 'The mailing address matches, but the USPS says it’s vacant.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OWNER_OCCUPIED'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>SENIOR OWNER OCCUPIED</>,
    description: 'The mailing addresses match and there is a senior tax exemption.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='IN_STATE'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>IN STATE ABSENTEE</>,
    description: 'Mailing address is in the same state and does not match property address.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='OUT_OF_STATE'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>OUT OF STATE</>,
    description: 'Mailing address is not in the same state and does not match property address.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='BANK'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>BANK OWNED</>,
    description: 'Property owner is listed as a Bank, also known as an REO.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='CORPORATE'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>CORPORATE</>,
    description: 'Property owner is an LLC or other form of corporation.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='TRUST'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>TRUSTEE</>,
    description: 'Property is owned by a trust.',
  }, {
    marker: (
      <PropertyMarker
        markerType='pin'
        size='md'
        classification='MILITARY'
        equityType={null}
        isVacant={false}
        isForeclosure={false}
        isSenior={true}
      />
    ),
    label: <>MILITARY</>,
    description: 'Property has a military tax exemption filed.',
  }]

  return (
    <>
      <Breakpoint
        mob={(
          <CardPrimaryTitle>Property Equity and Ownership </CardPrimaryTitle>
        )}
      />
      <CardPrimaryText>
        These pins reflect ownership status and are combined with the equity markers and
        stress indicators to give you a quick understanding of the property at a glance.
      </CardPrimaryText>

      <CardCombo
        mt={mbp({ mobSm: 2, mob: 3 })}
        size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
      >
        <CardComboTopSegment flexDirection='column'>
          <Text
            textStyle='bodyLFat'
            color='graystrong.600'
            textAlign='center'
          >
            These pins reflect ownership status and are combined with the equity markers
            and stress indicators to give you a quick understanding of the property at a glance.
          </Text>
        </CardComboTopSegment>
        <CardComboBottomSegment>
          <Grid
            gridTemplateColumns={mbp({
              mobSm: '1fr',
              mob: 'repeat(2, 1fr)',
              tabSm: 'repeat(3, 1fr)',
              dtSm: 'repeat(4, 1fr)',
            })}
            gap={3}
          >
            {markers.map((value, index) => (
              <MarkerLabelDescriptionPairs
                key={index}
                marker={value.marker}
                label={value.label}
                description={value.description}
                {...sbp({
                  tabSm: {
                    ...index === markers.length - 1 && {
                      justifySelf: 'center',
                      gridColumn: '2 / 3',
                    },
                  },
                  dtSm: {
                    ...index === markers.length - 2 && {
                      justifySelf: 'center',
                      gridColumn: '2 / 3',
                    },
                  },
                })}
              />
            ))}
          </Grid>
        </CardComboBottomSegment>
      </CardCombo>
    </>
  )
}
