import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals'
import { IdleInviteMembersModalTransition, IdleInviteMembersModalViewModel, LoadingInviteMembersModalTransition, PartialErrorInviteMembersModalTransition, ProgramExceptionInviteMembersModalTransition } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModal.viewModel'

const transitionToIdle = (
  props: Omit<IdleInviteMembersModalTransition & IdleInviteMembersModalViewModel, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'INVITE_MEMBERS_MODAL',
      props: {
        ...props,
        status: 'idle',
        onClose: goBackModal,
      },
    },
  })

const transitionToLoading = (
  props: Omit<LoadingInviteMembersModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'INVITE_MEMBERS_MODAL',
      props: {
        ...props,
        status: 'loading',
      },
    },
  })

/** @TODO Just merge ToPartialError with ToProgramException */
const transitionToPartialError = (
  props: Omit<PartialErrorInviteMembersModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'INVITE_MEMBERS_MODAL',
      props: {
        ...props,
        status: 'error',
      },
    },
  })

const transitionToProgramException = (
  props: Omit<ProgramExceptionInviteMembersModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'INVITE_MEMBERS_MODAL',
      props: {
        ...props,
        status: 'error',
      },
    },
  })

export const InviteMembersModalAPI = {
  transitionToIdle,
  transitionToLoading,
  transitionToPartialError,
  transitionToProgramException,
}
