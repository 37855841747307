import { createIcon } from '@chakra-ui/react'

export const ResetIcon = createIcon({
  displayName: 'ResetIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill='none'>
      <path
        d='M23 4V10H17'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.49 15C19.84 16.8399 18.6095 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67215 20.1286 6.18375 18.8667C4.69535 17.6047 3.65742 15.8932 3.22636 13.9901C2.7953 12.0869 2.99447 10.0952 3.79386 8.31508C4.59324 6.53496 5.94953 5.06288 7.65835 4.12065C9.36716 3.17843 11.3359 2.81711 13.268 3.09116C15.2 3.3652 16.9906 4.25975 18.37 5.64001L23 10'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
