import { Box, Grid, GridItem, HStack, Spacer, Spinner, VStack, useToken } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { groupSinglePointComps } from 'features/CMA/infra/react/CMAState.helpers'
import { selectCompsBy } from 'features/CMA/infra/react/CMAState.selectors'
import { Location } from 'features/valueObjects/Location'
import { ID } from 'libs/id'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Map } from 'presentation/components/Map'
import { MapStatusCard } from 'presentation/components/Map/MapStatusCard'
import { MapLib } from 'presentation/components/Map/createMapStore'
import { useClickOnMapCanvas } from 'presentation/components/Map/useClosePopupOnMapClick'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { useCommonMarkerBehaviorsEffect } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { MLSStatusMapLegendItems } from 'presentation/components/MapLegend/MLSStatusMapLegendItems'
import { MapLegend } from 'presentation/components/MapLegend/MapLegend'
import { MapModeToggle } from 'presentation/components/MapModeToggle'
import { MapModeIconToggle } from 'presentation/components/MapModeToggle/MapModeIconToggle'
import { IdWithOrigin } from 'presentation/libs/MapAndListSync/MapAndListSync'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { useCompsListPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CMARatingChangeEventHandler } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { filterByShowValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/CMAEntriesToolbar.utils'
import { useEntriesToolbarMenu, useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { useListInfoState } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useListInfoState'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { CompsBoundaryButtons } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundaryButtons'
import { CompsBoundaryDrawing } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundaryDrawing'
import { useIntegrateCompsBoundaryToMap } from 'presentation/screens/CompsScreen/components/CompsBoundary/useIntegrateCompsBoundaryToMap'
import { COMPS_MAP_MAPBOX_ID } from 'presentation/screens/CompsScreen/components/CompsMap/CompsMap.const'
import { CompsMapMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarker'
import { COMPS_MAP_POPUP_WIDTH, CompsMapMarkerPopup } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarkerPopup'
import { CompsMarkerProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarker'
import { CompsMarkerPopupProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarkerPopup'
import { CompsSinglePointMapMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMapMarker/CompsSinglePointMapMarker'
import { CompsSinglePointMapMarkerPopup } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMapMarkerPopup'
import { useComparativePropertyModal } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal.api'
import { CompsTableScroller } from 'presentation/screens/CompsScreen/hooks/CompsTableViewScroller'
import { useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useCompsScreenViewMode } from 'presentation/screens/CompsScreen/hooks/useCompsScreenViewMode'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FullscreenControl, NavigationControl } from 'react-map-gl'
import { map } from 'remeda'
import { shallow } from 'zustand/shallow'

type PopupPrimaryActionEventHandler = (
  params:
    | { modalType: 'single-point-comps', targetCompId: ID, compIds: ID[] }
    | { modalType: 'single-comp', targetCompId: ID }
) => void

export const CompsMap = () => {
  const compsBoundaryStatus = CompsBoundary.useStore(state => state.status)
  const { selectedListType: activeTab } = useActiveEntriesTab()
  const mode = useCompsScreenViewMode(store => store.mode)

  const {
    subject: subjectProperty,
    comps,
    rate,
  } = useCMAStore(selectCompsBy(activeTab), shallow)

  const currentMap = useMapStore(mapStore => mapStore.computed.getMapRef())

  const { showValues } = useEntriesToolbarMenu()
  const viewValue = useResponsiveViewValue()

  const comparativeModalApi = useComparativePropertyModal()

  const highlightedEntry = CompsMapAndListSync.useStore(store => store.highlightedMapMarker)

  useClickOnMapCanvas({
    mapRef: currentMap,
    onMapCanvasClick: () => {
      CompsMapAndListSync.setHighlightedEntry(null)
    },
  })

  const entriesWithSubject = useMemo(() => {
    const filteredEntries = comps.filter(filterByShowValue(showValues))

    const entriesWithSubject = [
      ...subjectProperty ? [subjectProperty] : [],
      ...filteredEntries,
    ]
      .filter(Location.hasLocation)

    return entriesWithSubject
  }, [comps, showValues, subjectProperty])

  useCommonMarkerBehaviorsEffect({
    highlightedEntry,
    markers: entriesWithSubject,
  })

  const groupedEntries = pipe(
    entriesWithSubject,
    groupSinglePointComps,
  )

  const highlightedEntryData: CMA.Comp | undefined
    = groupedEntries.find(
      entry => pipe(
        Match.value(entry),
        Match.when({ type: 'single-point-comps' },
          e => e.comps.find(v => v.id === highlightedEntry?.id)),
        Match.orElse(e => e.id === highlightedEntry?.id),
      ),
    )

  useIntegrateCompsBoundaryToMap()

  /**
   * ====================
   * Event Handlers
   * ====================
   */
  const handleOnMarkerHover = useCallback((id: ID) => {
    CompsMapAndListSync.handleMapMarkerHover(id)
  }, [])

  const handleOnMarkerClick = useCallback((id: ID) => {
    if (mode === 'with-table') {
      const isSubject = id === subjectProperty?.id
      CompsTableScroller.scrollToRow(id, isSubject)
      CompsMapAndListSync.handleMapMarkerClick(id, {
        shouldClearIfHighlighted: false,
      })
    } else {
      CompsMapAndListSync.handleMapMarkerClick(id, {
        shouldClearIfHighlighted: true,
      })
    }
  }, [mode])

  /** @TODO Ask @chrisregner to explain or refactor this sorcery */
  const handleOnMarkerHoverOut = useCallback(() => {
    CompsMapAndListSync.handleMapMarkerUnhover()
  }, [])

  const handleOnCompsRatingChange: CMARatingChangeEventHandler = useCallback((
    compId,
    rating,
  ) => {
    void rate.execute({ compId, rating })
  }, [])

  const handlePopupPrimaryActionClick: PopupPrimaryActionEventHandler = useCallback(params => {
    if (viewValue === 'TABLE_VIEW') {
      const isSubject = params.targetCompId === subjectProperty?.id
      CompsTableScroller.scrollToRow(params.targetCompId, isSubject)
    } else { comparativeModalApi.actions.open(params.targetCompId) }
  }, [viewValue])

  /**
   * ====================
   * Component creators
   * ====================
   */
  const entries = pipe(
    groupedEntries,
    map(toMarkers({
      highlightedEntry,
      onMarkerHover: handleOnMarkerHover,
      onMarkerHoverOut: handleOnMarkerHoverOut,
      onMarkerClick: handleOnMarkerClick,
    })),
  )

  const markerPopup = toMarkerPopup({
    highlightedEntryData,
    highlightedEntry,
    onMultiMarkerPageChange: handleOnMarkerHover,
    onCompsRatingChange: handleOnCompsRatingChange,
    onPopupPrimaryAction: handlePopupPrimaryActionClick,
  })

  const mapFallbackBgHex = useToken('colors', 'grayweak.300')

  const isShowingTable = viewValue === 'TABLE_VIEW'
  const isDrawingBoundary = compsBoundaryStatus === 'drawing'
  const { isPanelOpen } = useCompsListPanelStore()
  const shouldEnableDragPan = !isDrawingBoundary
  const isCtrlPressed = useCtrlPressed()

  if (!subjectProperty?.location) return null

  return (
    <Grid
      minH={0}
      h='full'
      gridTemplateAreas='"layer"'
    >
      <GridItem
        gridArea='layer'
        zIndex='1'
        sx={{
          '& .mapboxgl-popup': {
            width: COMPS_MAP_POPUP_WIDTH,
          },
          '& .mapboxgl-popup-content': {
            padding: 0,
            background: 'none',
            width: COMPS_MAP_POPUP_WIDTH,
          },
          '& .mapboxgl-popup-tip, & .mapboxgl-popup-close-button': {
            display: 'none',
          },
        }}
      >
        <Map
          id={COMPS_MAP_MAPBOX_ID}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            background: mapFallbackBgHex,
            touchAction: 'none',
          }}
          dragPan={shouldEnableDragPan}
          initialViewState={
            subjectProperty
              ? {
                latitude: subjectProperty.location.latitude,
                longitude: subjectProperty.location.longitude,
                zoom: 8,
              }
              : {
                latitude: MapLib.DEFAULT_CENTER[1],
                longitude: MapLib.DEFAULT_CENTER[0],
                zoom: 1,
              }
          }
        >
          {!isShowingTable && (
            <>
              <FullscreenControl />
              <NavigationControl />
            </>
          )}
          {isDrawingBoundary && entries[0]}
          {/**
            * We need to hide popup when ctrl is pressed,
            * so that the page won't zoom when scrolling and the
            * mouse is positioned on the popup.
            */}
          {!isDrawingBoundary && !isCtrlPressed && markerPopup}
          {!isDrawingBoundary && entries}
          <CompsBoundaryDrawing />
        </Map>
      </GridItem>

      <GridItem
        gridArea='layer'
        zIndex='1'
        justifySelf={mbp({ mobSm: 'flex-end', tabSm: 'flex-start' })}
        alignSelf='flex-end'
        p={4}
        {...mbpg({
          mobSm: {
            pr: 3,
            pb: compsBoundaryStatus === 'idle' ? '88px' : 4,
            gap: 3,
          },
          tabSm: {
            p: 4,
            pb: isShowingTable ? 7 : 4,
            gap: 1,
          },
        })}
        display='flex'
        flexDirection='column'
        pointerEvents='none'
        w='full'
      >
        <Breakpoint
          mobSm={(
            <HStack justify='flex-end'>
              <VStack gap={1} align='flex-end'>
                <Box pointerEvents='auto'>
                  <MapModeIconToggle />
                </Box>
                <CompsBoundaryButtons
                  wrapButton={button => (
                    <Box pointerEvents='auto'>
                      {button}
                    </Box>
                  )}
                />
              </VStack>
            </HStack>
          )}
          tabSm={(
            <HStack align='flex-end'>
              <VStack gap={1}>
                {compsBoundaryStatus === 'idle' && (
                  <Box pointerEvents='auto'>
                    <MapLegend>
                      <MLSStatusMapLegendItems />
                    </MapLegend>
                  </Box>
                )}
                <Box pointerEvents='auto'>
                  <MapModeToggle />
                </Box>
              </VStack>
              <Spacer />
              {/**
                * @NOTE: We have to hide the map elements on right side when the panel is opened in
                * tabSm. When the panel is opened, the map will be pushed to create space for the
                * panel. Unfortunately, the combined width of the legend and the draw button will
                * prevent this and will force the side panel to overflow. This will create a
                * horizontal scroll for the whole screen.
                */}
              {!isPanelOpen && (
                <VStack gap={1} alignItems='stretch'>
                  <Box pointerEvents='auto'>
                    <StatusIndicator />
                  </Box>
                  <CompsBoundaryButtons
                    wrapButton={button => (
                      <Box pointerEvents='auto'>
                        {button}
                      </Box>
                    )}
                  />
                </VStack>
              )}
            </HStack>
          )}
        />
      </GridItem>
    </Grid>
  )
}

const useCtrlPressed = () => {
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey)
        setIsCtrlPressed(true)
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.ctrlKey)
        setIsCtrlPressed(false)
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return isCtrlPressed
}

const StatusIndicator = () => {
  const { selectedListType } = useActiveEntriesTab()
  const listInfoState = useListInfoState({ type: selectedListType })
  const data = useCMAStore(state => {
    const reportState = state.local.report

    if (reportState.status === 'loaded' && listInfoState.status === 'loaded') {
      return {
        status: 'loaded' as const,
        count: listInfoState.data.comps.length,
      }
    }

    if (reportState.status === 'regenerating') {
      return {
        status: 'regenerating' as const,
      }
    }

    if (reportState.status === 'filtering') {
      return {
        status: 'filtering' as const,
      }
    }

    return {
      status: 'loading' as const,
    }
  })

  return (
    <MapStatusCard>
      {pipe(
        Match.value(data),
        Match.when({ status: 'loading' }, () => (
          <TextWithSpinner>
            Loading
          </TextWithSpinner>
        )),
        Match.when({ status: 'filtering' }, () => (
          <TextWithSpinner>
            Filtering
          </TextWithSpinner>
        )),
        Match.when({ status: 'regenerating' }, () => (
          <TextWithSpinner>
            Updating
          </TextWithSpinner>
        )),
        Match.when({ status: 'loaded' }, ({ count }) => `${count} results`),
        Match.exhaustive,
      )}
    </MapStatusCard>
  )
}

const TextWithSpinner = ({ children }: { children: string }) => (
  <>
    {children}
    <Spinner ml={1} size='xs' boxSize={2} />
  </>
)

/**
 * Creates a marker popup for the selected marker.
 * This displays the details of the comp.
 */
const toMarkerPopup = (params: {
  highlightedEntryData?: CMA.Comp
  highlightedEntry: IdWithOrigin | null
  onMultiMarkerPageChange: (id: ID) => void
  onCompsRatingChange: CMARatingChangeEventHandler
  onPopupPrimaryAction: PopupPrimaryActionEventHandler
}) => params.highlightedEntryData
  ? pipe(
    Match.value(params.highlightedEntryData),
    Match.when({ type: 'single-point-comps' }, e => {
      const entries: CompsMarkerPopupProps[] = e.comps.map((v): CompsMarkerPopupProps => {
        const common = {
          userRating: v.userRating ?? 'undecided',
          onCompsRatingChange: params.onCompsRatingChange,
        }

        return v.type === 'subject-comp'
          ? { ...v, ...common, entryType: 'subject' }
          : { ...v, ...common, entryType: 'regular' }
      })

      const handlePrimaryActionClick = (targetCompId: ID) => {
        params.onPopupPrimaryAction({
          modalType: 'single-point-comps',
          targetCompId,
          compIds: e.comps.map(v => v.id),
        })
      }

      return (
        <CompsSinglePointMapMarkerPopup
          /**
           * Rerender for different popup, to retrigger animation
           */
          key={`${e.location.latitude}|${e.location.longitude}`}
          latitude={e.location.latitude}
          longitude={e.location.longitude}
          entries={entries}
          highlightedEntry={params.highlightedEntry}
          onPageChange={params.onMultiMarkerPageChange}
          onOpenComparativePropertyModal={(id: ID) => handlePrimaryActionClick(id)}
        />
      )
    }),
    Match.orElse(e => {
      const handlePopupPrimaryActionClick = (targetCompId: ID) => {
        params.onPopupPrimaryAction({
          modalType: 'single-comp',
          targetCompId,
        })
      }

      const commonEntryProps = {
        userRating: e.userRating ?? 'undecided',
        onCompsRatingChange: params.onCompsRatingChange,
        onPopupPrimaryActionClick: (id: ID) => handlePopupPrimaryActionClick(id),
      }
      const entryProps: CompsMarkerPopupProps = e.type === 'subject-comp'
        ? { ...e, ...commonEntryProps, entryType: 'subject' }
        : { ...e, ...commonEntryProps, entryType: 'regular' }

      if (!e.location) return null

      return (
        <CompsMapMarkerPopup
          /**
           * Rerender for different popup, to retrigger animation
           */
          key={e.id}
          latitude={e.location.latitude}
          longitude={e.location.longitude}
          {...entryProps}
        />
      )
    }),
  )
  : null

/**
 * Creates Marker pin for each entry
 */
const toMarkers = (params: {
  highlightedEntry: IdWithOrigin | null
  onMarkerHover: (id: ID) => void
  onMarkerHoverOut: (id: ID) => void
  onMarkerClick: (id: ID) => void
}) => {
  const map = (entry: CMA.Comp) => pipe(
    Match.value(entry),
    Match.when({ type: 'subject-comp' }, e => {
      const entryProps: CompsMarkerProps = {
        id: e.id,
        isSubjectProperty: true,
        status: e.status,
        userRating: e.userRating ?? 'undecided',
        salePrice: e.salePrice,
        listPrice: e.listPrice,
      }

      if (!e.location) return null

      return (
        <CompsMapMarkerMemo
          key={e.id}
          isHighlighted={params.highlightedEntry?.id === e.id}
          latitude={e.location.latitude}
          longitude={e.location.longitude}
          onHover={params.onMarkerHover}
          onHoverOut={params.onMarkerHoverOut}
          onMarkerClick={params.onMarkerClick}
          {...entryProps}
        />
      )
    }),
    Match.when({ type: 'single-comp' }, e => {
      if (!e.location) return null

      const entryProps: CompsMarkerProps = {
        id: e.id,
        isSubjectProperty: false,
        status: e.status,
        userRating: e.userRating ?? 'undecided',
        salePrice: e.salePrice,
        listPrice: e.listPrice,
      }
      return (
        <CompsMapMarkerMemo
          key={e.id}
          isHighlighted={params.highlightedEntry?.id === e.id}
          latitude={e.location.latitude}
          longitude={e.location.longitude}
          onHover={params.onMarkerHover}
          onHoverOut={params.onMarkerHoverOut}
          onMarkerClick={params.onMarkerClick}
          {...entryProps}
        />
      )
    }),
    /**
     * Creates a marker pin for a group of comps
     */
    Match.when({ type: 'single-point-comps' }, e => (
      <CompsSinglePointMapMarker
        key={`${e.location.latitude}-${e.location.longitude}`}
        isHighlighted={e.comps.some(v => v.id === params.highlightedEntry?.id)}
        currentHighlightedMarkerId={params.highlightedEntry?.id || undefined}
        latitude={e.location.latitude}
        longitude={e.location.longitude}
        onHover={params.onMarkerHover}
        onHoverOut={params.onMarkerHoverOut}
        onMarkerClick={params.onMarkerClick}
        markers={e.comps.map(v => ({
          id: v.id,
          isSubjectProperty: v.type === 'subject-comp',
          status: v.status,
          userRating: v.userRating ?? 'undecided',
          salePrice: v.salePrice,
          listPrice: v.listPrice,
        }))}
      />
    )),
    Match.exhaustive,
  )
  return map
}

const CompsMapMarkerMemo = memo(CompsMapMarker)
