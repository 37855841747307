import { Box, Button, HStack, IconButton, IconButtonProps, NumberInput, NumberInputField, Popover, PopoverTrigger, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { getUserInputOrOriginalData } from 'features/PropertyDetails/domain/PropertyDetails.utils'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { NumberLib } from 'libs/Number'
import { EditIcon } from 'presentation/components/Icons'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { ResponsivePopoverContent } from 'presentation/components/ResponsivePopoverContent/ResponsivePopoverContent'
import { toast } from 'presentation/components/Toast'
import { forwardRef, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isNumber } from 'remeda'
import { shallow } from 'zustand/shallow'

type Form = {
  sqft: string
}

export const EditSqftMenuButton = (props: Partial<IconButtonProps>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <PopoverTrigger>
        <EditSqftButton {...props} />
      </PopoverTrigger>
      <ResponsivePopoverContent>
        <EditSqftForm
          isOpen={isOpen}
          onClose={onClose}
        />
      </ResponsivePopoverContent>
    </Popover>
  )
}

const EditSqftButton = forwardRef<HTMLButtonElement, Partial<IconButtonProps>>((props, ref) => (
  <IconButton
    aria-label='Edit Sqft'
    icon={<EditIcon />}
    size='md'
    variant='icon-ghost'
    colorScheme='neutral'
    ref={ref}
    {...props}
  />
))

EditSqftButton.displayName = 'EditSqftButton'

const EditSqftForm = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const state = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    return {
      leadId: property.leadId,
      currentValue: NumberLib.orNull(
        getUserInputOrOriginalData(data => data.building?.livingAreaSqft)(property),
      ),
      originalValue: property.originalData?.building?.livingAreaSqft ?? null,
      editProperty: state.actions.editProperty.execute,
    }
  }, shallow)

  const form = useForm<Form>({
    ...state?.currentValue && {
      defaultValues: {
        sqft: state.currentValue.toString(),
      },
    },
  })

  const handleSubmit = form.handleSubmit(formData => {
    const fallbackValue = state?.originalValue ?? null
    const newValue = NumberLib.fromStringSafe(formData.sqft)
    const finalValue = newValue && newValue !== 0
      ? newValue
      : fallbackValue

    if (finalValue !== null)
      form.setValue('sqft', finalValue.toString())

    void state?.editProperty({
      leadId: state?.leadId,
      building: {
        livingAreaSqft: finalValue,
      },
    })

    onClose()
  })

  const handleReset = () => {
    const originalValue = state?.originalValue
    form.setValue('sqft', originalValue?.toString() ?? '')
  }

  useEffect(() => {
    if (!isOpen) return
    form.setValue('sqft', state?.currentValue?.toString() ?? '')
  }, [isOpen])

  if (!state) return null

  return (
    <VStack align='stretch' h='full'>
      <Text
        textStyle='bodyLFat'
        color='grayweak.900'
        align='left'
      >
        Edit SqFt:
      </Text>

      <Box
        as='form'
        m={0}
        onSubmit={handleSubmit}
        // @HACK fixes input not getting repopulated when saving empty input and opening menu again
        key={isOpen ? 'open' : 'closed'}
      >
        {/* @TODO commas */}
        <Controller
          name='sqft'
          control={form.control}
          render={({ field: { ref, ...restField } }) => (
            <NumberInput
              mt={2}
              precision={0}
              {...restField}
            >
              <NumberInputField
                ref={ref}
                name={restField.name}
              />
            </NumberInput>
          )}
        />

        {isNumber(state.originalValue) && (
          <HStack mt={1}>
            <Text textStyle='bodyMFat' color='mixkey.blue'>
              Original Value
            </Text>
            <Text textStyle='bodyL'>
              {NumberLib.formatCommaOptionalDecimals(state.originalValue)}
            </Text>
          </HStack>
        )}

        <HStack spacing={1} mt={4}>
          <Button
            variant='outline'
            colorScheme='neutral'
            size='md'
            flex='1'
            onClick={handleReset}
          >
            RESET
          </Button>
          <Button
            variant='solid'
            colorScheme='neutral'
            size='md'
            flex='1'
            type='submit'
          >
            SAVE
          </Button>
        </HStack>

        <HStack mt={3} spacing={1} align='start'>
          <RegularCircleExclamationIcon fontSize={3} color='neutral.600' />
          <Text textStyle='bodyM' color='graystrong.200' align='left'>
            If the SqFt value is left blank or set to zero, it will default back to its original value.
          </Text>
        </HStack>
      </Box>
    </VStack>
  )
}

export const useEditSqftErrorHandler = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const state = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    return {
      error: state.actions.editProperty.error,
    }
  }, shallow)

  useEffect(() => {
    if (!state?.error) return

    toast.error({
      title: 'Failed to edit sqft value',
      message: 'Please try again or contact support.',
    })
  }, [state?.error])
}
