import { RequireSpecificFields } from 'libs/type'

/**
 * @example
 * ```ts
 * type Obj = { a: 1, b: 2 }
 * type Result = IsEmptyObject<Obj> // false
 *
 * type EmptyObj = {}
 * type Result = IsEmptyObject<EmptyObj> // true
 */
export type IsEmptyObject<Obj extends Record<PropertyKey, unknown>> =
  [keyof Obj] extends [never] ? true : false

export type NullableProps<T extends object> = {
  [K in keyof T]: T[K] | null
}

export type NullablePartialProps<T extends object> = {
  [K in keyof T]?: T[K] | null | undefined
}

const undefinedPropsToNull = <

  T extends Record<string, any>,
>(input: T): {
  [key in keyof T]: Exclude<T[key], undefined>
} => {
  const copy = { ...input }
  const keys = Object.keys(copy) as (keyof T)[]
  keys.forEach(key => {
    if (copy[key] === undefined)

      copy[key] = null as any
  })
  return copy
}

const shallowEqual = <T extends object>(a: T, b: T): boolean => {
  const aKeys = Object.keys(a) as (keyof T)[]
  const bKeys = Object.keys(b) as (keyof T)[]
  if (aKeys.length !== bKeys.length) return false
  return aKeys.every(key => a[key] === b[key])
}

const getAnyValue = <T>(obj: Record<string, T>): T =>
  Object.values(obj)[0]

export const object = {
  undefinedPropsToNull,
  shallowEqual,
  getAnyValue,
  has: <T, K extends keyof T>(key: K) => (obj: T): obj is RequireSpecificFields<T, K> =>
    obj[key] !== undefined && obj[key] !== null,
  isEmpty: <T extends object>(obj: T) =>
    Object.values(obj).every(v => v === null || v === undefined),
}
