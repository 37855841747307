import { FundsProvider } from 'features/Funds/infra/react/Funds.state'
import { fundsStore } from 'features/Funds/infra/react/Funds.state.store'
import { ReactNode } from 'react'

export const FundsProviderLive = ({
  children,
}: {
  children?: ReactNode
}) => (
  <FundsProvider store={fundsStore}>
    {children}
  </FundsProvider>
)
