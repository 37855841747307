import { GetNearbyBuyersQuery, NearbyBuyersAddressDetailsFragment, NearbyBuyersDealDetailsFragment, NearbyBuyersParcelDetailsFragment, ParcelBuyerType } from '__generated__/graphql'
import { Array, Option, pipe, Record } from 'effect'
import { isNotNull } from 'effect/Predicate'
import Buyer from 'features/NearbyBuyers/domain/Buyer'
import NearbyBuyer from 'features/NearbyBuyers/domain/NearbyBuyer'
import NearbyBuyerProperty from 'features/NearbyBuyers/NearbyBuyerProperty'
import GetNearbyBuyers from 'features/NearbyBuyers/repository/GetNearbyBuyers'
import { ownerClassificationFromGql } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getPropertyDetails.mapper'
import { Address } from 'features/valueObjects/Address'
import { EquityType } from 'features/valueObjects/EquityType'
import Connection from 'libs/Connection'

namespace GetNearbyBuyersSchema {
  // @TODO get parcel from node.parcel or node.buyer.buyerDetails.deals.parcel
  export const resultFromGraphQLResult = (raw: GetNearbyBuyersQuery): GetNearbyBuyers.Result => {
    const rawEdges = raw.buyersByRadius.edges

    if (rawEdges.length === 0)
      return GetNearbyBuyers.Result.Empty()

    const nearbyBuyerPropertiesRecord: Record<string, NearbyBuyerProperty> = {}

    return GetNearbyBuyers.Result.Populated({
      nearbyBuyersConnection: {
        edges: rawEdges.map((edge): Connection.Edge<NearbyBuyer> => {
          const rawBuyerDetails = Option.fromNullable(edge.node.buyer.buyerDetails)
          const rawDeals = edge.node.deals ?? []
          const rawParcels = edge.node.parcels
          const allPropertiesRecord = pipe(
            rawParcels,
            Array.map(propertyFromRawParcel),
            Record.fromIterableWith(property => [
              Option.getOrElse(property.parcelId, () => ''),
              property,
            ]),
          )

          const allDeals = pipe(
            rawDeals,
            Array.map((deal): Buyer.Deal => ({
              ...partialDealFromRawDeal(deal),
              property: Record.get(allPropertiesRecord, deal.parcel.id).pipe(
                Option.getOrThrowWith(() => new Error('deal.property not found in propertiesRecord')),
              ),
            })),
          )

          const allDealsRecord = Record.fromIterableBy(allDeals, serializeDeal)

          const nearbyPropertyIDs = edge.node.parcels.map(parcel => parcel.id)

          nearbyPropertyIDs.forEach(nearbyPropertyId => {
            const existing = nearbyBuyerPropertiesRecord[nearbyPropertyId] || {
              property: Record.get(allPropertiesRecord, nearbyPropertyId).pipe(
                Option.getOrThrowWith(() => new Error('property not found in propertiesRecord')),
              ),
              nearbyBuyerIDs: [],
              nearbyDeals: [],
            }

            existing.nearbyBuyerIDs = [...new Set([...existing.nearbyBuyerIDs, edge.node.buyer.id])]

            const nearbyDeals = pipe(
              edge.node.deals,
              Array.map(rawDeal => {
                const dealId = serializeRawDealDetails(rawDeal)
                const deal = Record.get(allDealsRecord, dealId)
                return deal
              }),
              Array.filter(Option.isSome),
              Array.map(option => option.value),
            )

            const nearbyDealsRecord = Record.fromIterableBy(nearbyDeals, serializeDeal)
            const existingNearbyDealsRecord = Record.fromIterableBy(existing.nearbyDeals, serializeDeal)

            existing.nearbyDeals = Record.values({ ...existingNearbyDealsRecord, ...nearbyDealsRecord })

            nearbyBuyerPropertiesRecord[nearbyPropertyId] = existing
          })

          return {
            cursor: edge.cursor || edge.node.buyer.id,
            node: {
              buyer: {
                id: edge.node.buyer.id,
                buyerType: rawBuyerDetails.pipe(
                  Option.flatMap(buyerDetails => Option.fromNullable(buyerDetails.buyerTypes)),
                  Option.map(types =>
                    types.map((type): Buyer.Type => type === ParcelBuyerType.Flipper
                      ? 'flipper'
                      : 'landlord',
                    ),
                  ),
                ),
                name: Option.fromNullable(edge.node.buyer.name).pipe(Option.flatMap(name =>
                  Option.fromNullable([
                    name.first,
                    name.last,
                    name.middle,
                  ].filter(isNotNull).join(' ') || name.full || null),
                )),
                address: Option.fromNullable(edge.node.buyer.address)
                  .pipe(Option.map(address => ({
                    line1: address.line1 ?? '',
                    city: address.city ?? '',
                    postalCode: address.postalCode ?? '',
                    state: address.state ?? '',
                  }))),
                buyerHistory: {
                  averageDealAmount: rawBuyerDetails.pipe(
                    Option.flatMap(history => Option.fromNullable(history.stats.average)),
                  ),
                  totalDealAmount: rawBuyerDetails.pipe(
                    Option.flatMap(history => Option.fromNullable(history.stats.total)),
                  ),
                  totalDealsCount: rawBuyerDetails.pipe(
                    Option.flatMap(history => Option.fromNullable(history.stats.count)),
                  ),
                  dealAmountRange: rawBuyerDetails.pipe(
                    Option.flatMap(history => {
                      const minDealAmount = Option.fromNullable(history.stats.min)
                      const maxDealAmount = Option.fromNullable(history.stats.max)

                      if (Option.isSome(minDealAmount) && Option.isSome(maxDealAmount))
                        return Option.some([minDealAmount.value, maxDealAmount.value])

                      if (Option.isSome(minDealAmount))
                        return Option.some([minDealAmount.value, minDealAmount.value])

                      if (Option.isSome(maxDealAmount))
                        return Option.some([maxDealAmount.value, maxDealAmount.value])

                      return Option.none()
                    }),
                  ),
                  dealsGeographicInfo: dealsToCountyGeographicInfo(allDeals),
                  deals: allDeals,
                  lastDeal: Option.fromNullable(
                    edge.node.buyer.buyerDetails?.historicalDeals.edges[0]?.node,
                  ).pipe(
                    Option.map(partialDealFromRawDeal),
                  ),
                  propertiesCount: rawBuyerDetails.pipe(
                    Option.flatMap(history =>
                      Option.fromNullable(history.historicalDeals.pageInfo.totalCount),
                    ),
                  ),
                },
              },
              nearbyPropertyIDs,
            },
          }
        }),
        pageInfo: {
          hasNextPage: raw.buyersByRadius.pageInfo.hasNextPage,
          hasPreviousPage: raw.buyersByRadius.pageInfo.hasPreviousPage,
          totalCount: Option.fromNullable(raw.buyersByRadius.pageInfo.totalCount),
        },
      },
      nearbyBuyerProperties: Record.values(nearbyBuyerPropertiesRecord),
    })
  }
}

export default GetNearbyBuyersSchema

export const dealsToCountyGeographicInfo = (deals: Buyer.Deal[]): Buyer.DealsGeographicInfo => {
  type CountyState = { county: string, state: string }
  const countiesByState = pipe(
    deals,
    Array.map(deal =>
      deal.property.address.county.pipe(
        Option.map(county => ({
          county,
          state: deal.property.address.state,
        })),
      )),
    Array.filter(Option.isSome),
    Array.map(option => option.value),
    Array.reduce<Buyer.DealsGeographicInfo['countiesByState'], CountyState>(
      {},
      (acc, { county, state }) => ({
        ...acc,
        [state]: [...acc[state] || [], county],
      })),
    Record.map(Array.dedupe),
  )
  const counties = pipe(
    countiesByState,
    Record.toEntries,
    Array.flatMap(([state, counties]) => counties.map(county => ({ state, county }))),
  )
  const states = pipe(
    countiesByState,
    Record.keys,
  )

  return {
    counties,
    states,
    countiesByState,
  }
}

const addressFromRawParcelAddress = (address: NearbyBuyersAddressDetailsFragment): Address => ({
  line1: address.line1 ?? '',
  city: address.city ?? '',
  postalCode: address.postalCode ?? '',
  state: address.state ?? '',
})

export const partialDealFromRawDeal = (
  rawDeal: NearbyBuyersDealDetailsFragment,
): Omit<Buyer.Deal, 'property'> => {
  const dealInfo = {
    purchaseDate: Option.fromNullable(rawDeal.purchaseDate),
    purchaseAmount: Option.fromNullable(rawDeal.purchaseAmount),
    soldDate: Option.fromNullable(rawDeal.saleDate),
    soldAmount: Option.fromNullable(rawDeal.saleAmount),
    rentDate: Option.fromNullable(rawDeal.rentDate),
    rentAmount: Option.fromNullable(rawDeal.rentAmount),
  }

  return {
    id: serializeDealInfo(dealInfo),
    ...dealInfo,
  }
}

export const propertyFromRawParcel = (parcel: NearbyBuyersParcelDetailsFragment): Buyer.Property => ({
  id: parcel.id,
  parcelId: Option.some(parcel.id),
  address: Option.fromNullable(parcel.address).pipe(
    Option.map(addressFromRawParcelAddress),
    Option.map(address => ({
      ...address,
      county: Option.fromNullable(parcel.landDetails.county?.name),
    })),
    Option.getOrElse(() => ({
      line1: '',
      city: '',
      postalCode: '',
      state: '',
      county: Option.none(),
    })),
  ),
  // @TODO Filter out zero latitude and longitude
  location: {
    latitude: parcel.address?.location?.latitude ?? 0,
    longitude: parcel.address?.location?.longitude ?? 0,
  },
  ownershipInfo: {
    classification: Option.fromNullable(
      ownerClassificationFromGql(
        parcel.ownerDetails.ownerClassification ?? null,
        parcel.ownerDetails.ownerStatus ?? null,
      ),
    ),
    isSenior: parcel.ownerDetails.isSenior ?? false,
    isVacant: parcel.ownerDetails.isVacant ?? false,
    ownedYears: Option.fromNullable(parcel.ownerDetails.ownershipMonths).pipe(
      Option.map(months => Math.floor(months / 12)),
    ),
  },
  physicalInfo: {
    bathroomsCountInfo: Option.fromNullable(parcel.buildingDetails.bathroomsCount).pipe(
      Option.map(count => ({
        full: count.full ?? null,
        half: count.half ?? null,
        total: count.total ?? null,
      })),
    ),
    bedroomsCount: Option.fromNullable(parcel.buildingDetails.bedroomsCount),
    buildingAreaSqft: Option.fromNullable(parcel.buildingDetails.buildingAreaSqft),
  },
  valueInfo: {
    equityType: Option.fromNullable(parcel.valuationDetails.loanToValue).pipe(
      Option.map(EquityType.fromLtv),
    ),
    isForeclosure: parcel.ownerDetails.isForeclosure ?? false,
  },
})

const serializeRawDealDetails = (
  rawDeal: NearbyBuyersDealDetailsFragment,
) =>
  serializeDealInfo({
    purchaseDate: Option.fromNullable(rawDeal.purchaseDate),
    purchaseAmount: Option.fromNullable(rawDeal.purchaseAmount),
    soldDate: Option.fromNullable(rawDeal.saleDate),
    soldAmount: Option.fromNullable(rawDeal.saleAmount),
    rentDate: Option.fromNullable(rawDeal.rentDate),
    rentAmount: Option.fromNullable(rawDeal.rentAmount),
  })

const serializeDeal = (deal: Buyer.Deal) =>
  serializeDealInfo({
    purchaseDate: deal.purchaseDate,
    purchaseAmount: deal.purchaseAmount,
    soldDate: deal.soldDate,
    soldAmount: deal.soldAmount,
    rentDate: deal.rentDate,
    rentAmount: deal.rentAmount,
  })

export const serializeDealInfo = ({
  purchaseDate,
  purchaseAmount,
  soldDate,
  soldAmount,
  rentDate,
  rentAmount,
}: {
  purchaseDate: Option.Option<Date>
  purchaseAmount: Option.Option<number>
  soldDate: Option.Option<Date>
  soldAmount: Option.Option<number>
  rentDate: Option.Option<Date>
  rentAmount: Option.Option<number>
}) =>
  [
    purchaseDate.pipe(Option.map(date => date)).pipe(Option.getOrElse(() => null)),
    purchaseAmount.pipe(Option.map(amount => amount)).pipe(Option.getOrElse(() => null)),
    soldDate.pipe(Option.map(date => date)).pipe(Option.getOrElse(() => null)),
    soldAmount.pipe(Option.map(amount => amount)).pipe(Option.getOrElse(() => null)),
    rentDate.pipe(Option.map(date => date)).pipe(Option.getOrElse(() => null)),
    rentAmount.pipe(Option.map(amount => amount)).pipe(Option.getOrElse(() => null)),
  ]
    .map(value => value?.toString())
    .join('|')
