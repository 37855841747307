type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> }

/**
 * Makes a type {@link DeepWriteable}
 *
 * Useful as workaround for Effect TS's `match` weirdly casting some Error
 * fields to readonly
 */
const toWritable = <T>(t: T): DeepWriteable<T> => t as DeepWriteable<T>

export const TypeLib = {
  toWritable,
}

export type RequireFields<T> = {
  [K in keyof T]: Exclude<T[K], null | undefined>
}

export type RequireSpecificFields<T, K extends keyof T> = T & RequireFields<Pick<T, K>>
