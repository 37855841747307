import { createIcon } from '@chakra-ui/react'

export const ThumbsUpActiveMultiColorIcon = createIcon({
  displayName: 'ThumbsUpActiveMultiColorIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='#055A08'>
      <path
        d='M13 7.5V3.5C13 2.70435 12.6839 1.94129 12.1213 1.37868C11.5587 0.816071 10.7956 0.5 10 0.5L6 9.5V20.5H17.28C17.7623 20.5055 18.2304 20.3364 18.5979 20.024C18.9654 19.7116 19.2077 19.2769 19.28 18.8L20.66 9.8C20.7035 9.51336 20.6842 9.22068 20.6033 8.94225C20.5225 8.66382 20.3821 8.40629 20.1919 8.18751C20.0016 7.96873 19.7661 7.79393 19.5016 7.67522C19.2371 7.5565 18.9499 7.49672 18.66 7.5H13ZM6 20.5H3C2.46957 20.5 1.96086 20.2893 1.58579 19.9142C1.21071 19.5391 1 19.0304 1 18.5V11.5C1 10.9696 1.21071 10.4609 1.58579 10.0858C1.96086 9.71071 2.46957 9.5 3 9.5H6'
      />
      <path d='M6 20.5H3C2.46957 20.5 1.96086 20.2893 1.58579 19.9142C1.21071 19.5391 1 19.0304 1 18.5V11.5C1 10.9696 1.21071 10.4609 1.58579 10.0858C1.96086 9.71071 2.46957 9.5 3 9.5H6M13 7.5V3.5C13 2.70435 12.6839 1.94129 12.1213 1.37868C11.5587 0.816071 10.7956 0.5 10 0.5L6 9.5V20.5H17.28C17.7623 20.5055 18.2304 20.3364 18.5979 20.024C18.9654 19.7116 19.2077 19.2769 19.28 18.8L20.66 9.8C20.7035 9.51336 20.6842 9.22068 20.6033 8.94225C20.5225 8.66382 20.3821 8.40629 20.1919 8.18751C20.0016 7.96873 19.7661 7.79393 19.5016 7.67522C19.2371 7.5565 18.9499 7.49672 18.66 7.5H13Z' stroke='#ffffff' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
