import { createIcon } from '@chakra-ui/react'

export const CardJcbLightIcon = createIcon({
  displayName: 'CardJcbLightIcon',
  viewBox: '0 0 47 33',
  path: (
    <>
      <rect y='1' width='46.6721' height='32' rx='4' fill='#FFCF77' />
      <path d='M29.761 17.1863C30.446 17.2012 31.1348 17.156 31.8171 17.2097C32.508 17.3386 32.6745 18.3844 32.0607 18.727C31.6419 18.9526 31.1443 18.811 30.6903 18.8508H29.761V17.1863ZM32.2131 15.3022C32.3653 15.8394 31.8475 16.3216 31.3301 16.2476H29.761C29.772 15.7411 29.7395 15.1914 29.7769 14.7115C30.4055 14.7292 31.0399 14.6754 31.6649 14.7397C31.9334 14.8071 32.1581 15.0278 32.2131 15.3022ZM35.9895 7.33713C36.0187 8.36287 35.9937 9.44282 36.0021 10.4894C36.0002 14.7442 36.0062 18.999 35.9988 23.2541C35.9712 24.8487 34.5579 26.2343 32.9741 26.2661C31.3888 26.2725 29.8033 26.2671 28.2178 26.2688V19.8364C29.9453 19.8275 31.6736 19.8545 33.4004 19.8229C34.2015 19.7726 35.0789 19.2441 35.1162 18.3627C35.2105 17.4774 34.3758 16.8651 33.5833 16.7684C33.2787 16.7605 33.2876 16.6796 33.5833 16.6444C34.3392 16.481 34.9327 15.6988 34.7103 14.9154C34.5208 14.0915 33.61 13.7726 32.8525 13.7742C31.3078 13.7637 29.7628 13.7727 28.2181 13.7697C28.2281 12.5688 28.1973 11.3666 28.2347 10.1667C28.3571 8.60083 29.806 7.30953 31.3675 7.33754C32.9084 7.33731 34.449 7.33731 35.9895 7.33725V7.33713Z' fill='#916C27' />
      <path d='M10.762 10.329C10.8015 8.73691 12.2209 7.36268 13.8026 7.33941C15.382 7.33455 16.9617 7.33871 18.5411 7.3373C18.5368 12.6641 18.5497 17.9915 18.5346 23.3179C18.4738 24.8907 17.0699 26.2387 15.5054 26.2666C13.9229 26.2723 12.3404 26.2674 10.7579 26.269V19.6196C12.295 19.9827 13.9069 20.1373 15.475 19.8965C16.4123 19.7457 17.4379 19.2854 17.7553 18.313C17.9888 17.4813 17.8573 16.606 17.8921 15.7523V13.7698H15.1783C15.1661 15.081 15.2033 16.3944 15.1587 17.704C15.0855 18.509 14.2882 19.0204 13.529 18.9931C12.5874 19.0031 10.7214 18.3108 10.7214 18.3108C10.7167 15.8542 10.7487 12.7777 10.762 10.3292V10.329Z' fill='#916C27' />
      <path d='M19.5532 14.5693C19.4104 14.5996 19.5245 14.0828 19.488 13.8868C19.4977 12.6472 19.4676 11.4062 19.5046 10.1675C19.6266 8.59503 21.0867 7.30051 22.6545 7.33731H27.2716C27.2673 12.6641 27.2801 17.9915 27.2651 23.3179C27.2042 24.8907 25.8001 26.2387 24.2356 26.2666C22.6531 26.2725 21.0705 26.2675 19.4879 26.269V18.9838C20.5687 19.8705 22.0377 20.0085 23.3843 20.0109C24.3994 20.0106 25.4086 19.8541 26.3944 19.62V18.2853C25.2834 18.839 23.9775 19.1906 22.7459 18.8725C21.8869 18.6587 21.2632 17.8286 21.2771 16.9421C21.1776 16.0202 21.718 15.0469 22.6241 14.7729C23.749 14.4208 24.975 14.6901 26.0295 15.1479C26.2554 15.2661 26.4847 15.4128 26.3943 15.0353V13.9862C24.6308 13.5667 22.754 13.4123 20.9823 13.8687C20.4694 14.0134 19.9698 14.2327 19.5532 14.5694V14.5693Z' fill='#916C27' />
    </>
  ),
})
