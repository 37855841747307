import { format2Decimals, formatCommas, formatNumber, formatOptionalDecimals, fromPennies } from 'libs/Number/formatNumber'
import { isFiniteNumber } from 'libs/Number/isFiniteNumber'
import { castToFiniteNumber } from 'libs/string/castToFiniteNumber'
import { isNonEmptyString } from 'libs/string/isNonEmptyString'
import { Nullable } from 'libs/utils.types'
import { flow, identity } from 'lodash/fp'
import { formatUsd } from 'utils/formatCurrency'
import { isNonNullable } from 'utils/isNonNullable'

/**
 * When rendering, this happens a lot:
 * 1) We `validate` (first param) if the data at hand is of expected type
 * 2) We `format` (second param) the data to achieve desired output
 * 3) We provide `fallback` (third param) in case that the data is unformattable OR empty
 *
 * The forth param would be the actual input to go through rules above
 *
 * @NOTE The catch is this can't handle Generic types for the Input/Wanted,
 *   to do so just override the type like ensureArray
 */
/** @deprecated Opt for lib/[datatype] instead */
export const makeDataAdapter = <
  Input,
  WantedType,
>(validate: (input: Input | WantedType) => input is WantedType) =>
  <Output>(format: (value: WantedType) => Output) =>
    <Fallback>(fallback: Fallback) =>
      (input: Input | WantedType): Output | Fallback =>
        validate(input) ? format(input) : fallback

// =============================================================================
// Number Adapters
// =============================================================================

/** Make Finite Number Adapter */
/** @deprecated Opt for lib/[datatype] instead */
export const ifNumber = makeDataAdapter<Nullable<number>, number>(isFiniteNumber)

/** Make Finite Number Checker (no formatting format) */
/** @deprecated Opt for lib/[datatype] instead */
export const ensureNumberOr = ifNumber<number>(identity)

/** Make Finite Number Checker (no formatting format, 0 as fallback) */
/** @deprecated Opt for lib/[datatype] instead */
export const ensureNumber = ensureNumberOr(0)

type FormatNumberOrReturn = <Fallback>(fallback: Fallback) => (input: Nullable<number>) => string | Fallback
/** @deprecated Opt for lib/[datatype] instead */
export const formatNumberOr = (format: string): FormatNumberOrReturn => ifNumber(formatNumber(format))

type FormatNumberSafeReturn = (input: Nullable<number>) => string | null
/** @deprecated Opt for lib/[datatype] instead */
export const formatNumberSafe = (format: string): FormatNumberSafeReturn => formatNumberOr(format)(null)

/** @deprecated Opt for lib/[datatype] instead */
export const formatCommasOr = ifNumber(formatCommas)
/** @deprecated Opt for lib/[datatype] instead */
export const formatCommasSafe = formatCommasOr(null)

/** @deprecated Opt for lib/[datatype] instead */
export const format2DecUsd = flow(format2Decimals, formatUsd)
/** @deprecated Opt for lib/[datatype] instead */
export const format2DecUsdOr = ifNumber(format2DecUsd)
/** @deprecated Opt for lib/[datatype] instead */
export const format2DecUsdSafe = format2DecUsdOr(null)

/** @deprecated Opt for lib/[datatype] instead */
export const formatOptionalDecUsd = flow(formatOptionalDecimals, formatUsd)
/** @deprecated Opt for lib/[datatype] instead */
export const formatOptionalDecUsdOr = ifNumber(formatOptionalDecimals)
/** @deprecated Opt for lib/[datatype] instead */
export const formatOptionalDecUsdSafe = format2DecUsdOr(null)

/** @deprecated Opt for lib/[datatype] instead */
export const formatNoDecUsd = flow(formatCommas, formatUsd)
/** @deprecated Opt for lib/[datatype] instead */
export const formatNoDecUsdOr = ifNumber(flow(formatCommas, formatUsd))
/** @deprecated Opt for lib/[datatype] instead */
export const formatNoDecUsdSafe = formatNoDecUsdOr(null)

/**
 * @example
 * formatPenniesOptionalDec(123400) // 1,234
 * formatPenniesOptinalDec(123450) // 1,234.50
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDec = flow(fromPennies, formatOptionalDecimals)

/**
 * @example
 * formatPenniesOptionalDecOr('Bamboozle')(123400) // 1,234
 * formatPenniesOptionalDecOr('Bamboozle')(123450) // 1,234.50
 * formatPenniesOptionalDecOr('Bamboozle')(undefined) // Bamboozle
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDecOr = ifNumber(formatPenniesOptionalDec)

/**
 * @example
 * formatPenniesOptionalDecSafe(123400) // 1,234
 * formatPenniesOptionalDecSafe(123450) // 1,234.50
 * formatPenniesOptionalDecSafe(undefined) // null
 * formatPenniesOptionalDecSafe(null) // null
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDecSafe = formatPenniesOptionalDecOr(null)

/**
 * @example
 * formatPenniesOptionalDecUsd(123400) // $1,234
 * formatPenniesOptionalDecUsd(123450) // $1,234.50
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDecUsd = flow(formatPenniesOptionalDec, formatUsd)

/**
 * @example
 * formatPenniesOptionalDecUsdOr('Bamboozle')(123400) // $1,234
 * formatPenniesOptionalDecUsdOr('Bamboozle')(123450) // $1,234.50
 * formatPenniesOptionalDecUsdOr('Bamboozle')(undefined) // Bamboozle
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDecUsdOr = ifNumber(formatPenniesOptionalDecUsd)

/**
 * @example
 * formatPenniesOptionalDecUsdSafe(123400) // $1,234
 * formatPenniesOptionalDecUsdSafe(123450) // $1,234.50
 * formatPenniesOptionalDecUsdSafe(undefined) // null
 * formatPenniesOptionalDecUsdSafe(null) // null
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesOptionalDecUsdSafe = formatPenniesOptionalDecUsdOr(null)

/** @deprecated Opt for lib/[datatype] instead */
export const scalePenniesOptionalDec = (scaleOn: number) => (value: number) => {
  /**
   * Only format if value is greater than or equal to scaleOn
   */
  if (isNonNullable(value) && value >= scaleOn) {
    return flow(
      fromPennies,
      formatNumber('0[.]00a'),
    )(value).toUpperCase()
  } else {
    return flow(
      fromPennies,
      formatOptionalDecimals,
    )(value)
  }
}

/** @deprecated Opt for lib/[datatype] instead */
export const scalePenniesToK = scalePenniesOptionalDec(1_000_00)

/**
 * @example
 * formatPenniesKOptionalDecUsdOr('--')(12300) // $123
 * formatPenniesKOptionalDecUsdOr('--')(12345) // $123.45
 * formatPenniesKOptionalDecUsdOr('--')(123400) // $1.23k
 * formatPenniesKOptionalDecUsdOr('--')(123450) // $1.23k
 * formatPenniesKOptionalDecUsdOr('--')(undefined) // --
 */
/** @deprecated Opt for lib/[datatype] instead */
export const formatPenniesKOptionalDecUsdOr = ifNumber(flow(scalePenniesToK, formatUsd))

// =============================================================================
// String Adapters
// =============================================================================

/** Make Non-Empty String Adapter */
/** @deprecated Opt for lib/[datatype] instead */
export const ifString = makeDataAdapter<unknown, string>(isNonEmptyString)

/** Make Non-Empty String Adapter (no formatting format) */
/** @deprecated Opt for lib/[datatype] instead */
export const ensureStringOr = ifString<string>(identity)

/** Make Non-Empty String Adapter (no formatting format, '' as fallback) */
/** @deprecated Opt for lib/[datatype] instead */
export const ensureString = ensureStringOr('')

/** @deprecated Opt for lib/[datatype] instead */
export const format2DecUsdStrOr = ifString(flow(castToFiniteNumber, format2DecUsdOr(null)))
/** @deprecated Opt for lib/[datatype] instead */
export const format2DecUsdStrSafe = format2DecUsdStrOr(null)
/** @deprecated Opt for lib/[datatype] instead */
export const formatNoDecUsdStrOr = ifString(flow(castToFiniteNumber, formatNoDecUsdOr(null)))
/** @deprecated Opt for lib/[datatype] instead */
export const formatNoDecUsdStrSafe = formatNoDecUsdStrOr(null)

/**
 * Date adapters
 */

// =============================================================================
// Array Adapters
// =============================================================================

/** Make Array Adapter */
const ifArray = makeDataAdapter<Nullable<any[]>, any[]>(Array.isArray)

/** Make Array Adapter (no formatting format) */
type EnsureArrayOr = <T, Fallback>(fallback: Fallback) => (input: Nullable<T[]>) => T[] | Fallback
/** @deprecated Opt for lib/[datatype] instead */
export const ensureArrayOr: EnsureArrayOr = ifArray<any[]>(identity)

/** Make Array Adapter (no formatting format, '' as fallback) */
type EnsureArray = <T>(input: Nullable<T[]>) => T[]
/** @deprecated Opt for lib/[datatype] instead */
export const ensureArray: EnsureArray = ensureArrayOr([])
