import { CMA } from 'features/CMA/CMA.domain'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'

export const getCompsCoverageInfo = async (): Promise<CMA.CoverageInfo> => {
  const fromCache = ServerCache.getOnly('getCompsCoverageInfo')
  if (fromCache) return fromCache as CMA.CoverageInfo

  const resp = await restClient.get('legacy/cma/coverage').json<any>()
  const coverageInfo: CMA.CoverageInfo = {
    coveredFipsMap: ((resp?.coverage || []) as string[])
      .reduce<CMA.CoverageInfo['coveredFipsMap']>((acc, fips) =>
      ({ ...acc, [fips]: true }), {}),
    incomingStatesMap: FIXED_INCOMING_STATES,
  }

  ServerCache.setOnly('getCompsCoverageInfo')(coverageInfo)

  return coverageInfo
}

const FIXED_INCOMING_STATES = {
  '01': true, // Alabama
  '08': true, // Colorado
  '09': true, // Connecticut
  '10': true, // Delaware
  '13': true, // Georgia
  '12': true, // Florida
  '29': true, // Missouri
  '31': true, // Nebraska
  '37': true, // North Carolina
  '40': true, // Oklahoma
  '47': true, // Tennessee
  '48': true, // Texas
}
