import { HasOnlyId } from 'libs/id'

// ========================================
// Common
// ========================================

type VanillaError = Error

export namespace CQ {
  export type Meta<TPayload> = {
    /**
     * Identifier of the command/query. If this is a non executable query, this
     * will be set to a constant value (because we don't have `execute` phase
     * where we can generate ID)
     */
    id: string

    /**
     * Payload of the command/query.
     */
    payload: [TPayload] extends [never] ? null : TPayload

    /**
     * Timestamp when the command/query was executed.
     */
    initialTimestamp: number

    /**
     * Timestamp of this particular state. If this is the first state, this will
     * be same as `initialTimestamp`.
     */
    currentTimestamp: number
  }

  export type State<TData, TError extends VanillaError, TPayload> =
    | State.Idle
    | State.Loading<TData, TPayload>
    | State.Success<TData, TPayload>
    | State.Error<TData, TError, TPayload>

  export namespace State {
    export type Idle = { status: 'idle' }
    export type Loading<TData, TPayload> = {
      status: 'loading'
      meta: Meta<TPayload>
      /** Data from previous activity is still accessible while loading */
      data: ([TData] extends [never] ? null : TData) | null
    }

    export type Success<TData, TPayload> = {
      status: 'success'
      data: [TData] extends [never] ? null : TData
      meta: Meta<TPayload>
    }

    export type Error<TData, TError extends VanillaError, TPayload> = {
      status: 'error'
      error: TError
      /** Data from previous activity is still accessible while loading */
      data: ([TData] extends [never] ? null : TData) | null
      meta: Meta<TPayload>
    }

    export type NarrowIdle<TState extends State<any, any, any>> =
      TState extends Idle ? TState : never
    export type NarrowLoading<TState extends State<any, any, any>> =
      TState extends Loading<any, any> ? TState : never
    export type NarrowSuccess<TState extends State<any, any, any>> =
      TState extends Success<any, any> ? TState : never
    export type NarrowError<TState extends State<any, any, any>> =
      TState extends Error<any, any, any> ? TState : never

    export type ExtractData<TState extends State<any, any, any>> =
      TState extends State<infer TData, any, any> ? TData : never
    export type ExtractError<TState extends State<any, any, any>> =
      TState extends State<any, infer TError, any> ? TError : never
    export type ExtractPayload<TState extends State<any, any, any>> =
      TState extends State<any, any, infer TPayload> ? TPayload : never
  }

}

// ========================================
// Query
// ========================================

export type Query<
  TData,
  TError extends VanillaError,
  TPayload,
> = Query.Query<
  TData,
  TError,
  TPayload
>

export namespace Query {
  /**
   * Query represents domain data that is being requested
   */
  export type Query<TData, TError extends VanillaError, TPayload> = {
    state: State<TData, TError, TPayload>
  }

  /**
   * Lazy is a version of Query that doesn't execute immediately, starts in Idle
   * state and can be executed later.
   */
  export type Lazy<TData, TError extends VanillaError, TPayload> = {
    state: LazyState<TData, TError, TPayload>
    execute: (payload: TPayload) => void
  }

  /**
   * Executable is a version of Query that can be re-executed.
   */
  export type Executable<TData, TError extends VanillaError, TPayload> = {
    state: State<TData, TError, TPayload>
    execute: (payload: TPayload) => void
  }

  export type State<TData, TError extends VanillaError, TPayload> =
    | CQ.State.Loading<TData, TPayload>
    | CQ.State.Success<TData, TPayload>
    | CQ.State.Error<TData, TError, TPayload>

  export type LazyState<TData, TError extends VanillaError, TPayload> =
    | CQ.State.Idle
    | CQ.State.Loading<TData, TPayload>
    | CQ.State.Success<TData, TPayload>
    | CQ.State.Error<TData, TError, TPayload>
}

// ========================================
// Command
// ========================================

export type Command<
  TError extends VanillaError,
  TPayload,
> = Command.Command<
  TError,
  TPayload
>

export namespace Command {
  /**
   * Command represents domain action that can be executed. Command never
   * exposes data aside from execution meta data, as exposing data is a
   * responsibility of Query.
   */
  export type Command<TError extends VanillaError, TPayload> = {
    state: State<TError, TPayload>
    execute: [TPayload] extends [never] ? () => void : (payload: TPayload) => void
  }

  type State<TError extends VanillaError, TPayload> =
    | CQ.State.Idle
    | CQ.State.Loading<never, TPayload>
    | CQ.State.Success<never, TPayload>
    | CQ.State.Error<never, TError, TPayload>

  // ========================================
  // Special Command Types
  // ========================================

  /**
   * Non-declartive, promise version of command. Only used as building block for Keyed
   */
  export type PromiseVersion<TError extends Error, TPayload> = {
    execute: (payload: TPayload) => Promise<
      | CQ.State.NarrowSuccess<State<TError, TPayload>>
      | CQ.State.NarrowError<State<TError, TPayload>>
    >
  }

  /**
   * Due to no-promise, plain object, reactive nature, of the contract, we can't
   * track the state of multiple simultaneous execution of same command, unlike
   * with promises where we can track the state of each promise individually.
   *
   * This works around that by allowing us to track the state of each execution
   * individually, by providing a key to each execution.
   */
  export type Keyed<
    TError extends Error,
    TPayload,
    TKey extends string | number = string,
  > = {
    state: Partial<Record<TKey, State<TError, TPayload>>>

    /**
     * If there is no payload, we only require key.
     * If there is payload, but it only has id, we only require key.
     * If there is payload, we require both key and payload.
     */
    execute: [TPayload] extends [never]
      ? (key: TKey) => void
      : HasOnlyId<TPayload> extends true
        ? (key: TKey) => void
        : (key: TKey, payload: TPayload) => void
  }
}

// ========================================
// Helpers
// ========================================

export namespace CQ {
  export const isError = <S extends { status: string }>(
    state: S,
  ): state is Extract<S, { status: 'error' }> => state.status === 'error'

  export const isSuccess = <S extends { status: string }>(
    state: S,
  ): state is Extract<S, { status: 'success' }> => state.status === 'success'

  export const isLoading = <S extends { status: string }>(
    state: S,
  ): state is Extract<S, { status: 'loading' }> => state.status === 'loading'

  export const isIdle = <S extends { status: string }>(
    state: S,
  ): state is Extract<S, { status: 'idle' }> => state.status === 'idle'

  type CreateIdleState = () => CQ.State.Idle
  export const IDLE_STATE = { status: 'idle' } as const

  /**
   * @NOTE Returns the same reference to IDLE_STATE every time it's called,
   *   which is important because when creating idle we're not requiring ID,
   *   and because there's no ID, we can't reaccess the same state within
   *   hooks.
   */
  export const createIdleState: CreateIdleState = () => IDLE_STATE

  type CreateLoadingState = {
    <TData, TPayload>(params: {
      id: string
      payload: TPayload
      data?: TData
      initialTimestamp?: number
    }): CQ.State.Loading<TData, TPayload>

    <TPayload>(params: {
      id: string
      payload: TPayload
      initialTimestamp?: number
    }): CQ.State.Loading<never, TPayload>

    <TData>(params: {
      id: string
      data?: TData
      initialTimestamp?: number
    }): CQ.State.Loading<TData, never>

    (params: {
      id: string
      initialTimestamp?: number
    }): CQ.State.Loading<never, never>
  }

  export const createLoadingState: CreateLoadingState = <TData, TPayload>({
    id,
    payload,
    data,
    initialTimestamp,
  }: {
    id: string
    payload?: TPayload
    data?: TData
    initialTimestamp?: number
  }) => ({
    status: 'loading' as const,
    meta: {
      id,
      payload: payload ?? null,
      initialTimestamp: initialTimestamp ?? Date.now(),
      currentTimestamp: Date.now(),
    },
    data: data ?? null,
  })

  type CreateSuccessState = {
    <TData, TPayload>(params: {
      id: string
      data: TData
      payload: TPayload
      initialTimestamp?: number
    }): CQ.State.Success<TData, TPayload>

    <TPayload>(params: {
      id: string
      payload: TPayload
      initialTimestamp?: number
    }): CQ.State.Success<never, TPayload>

    <TData>(params: {
      id: string
      data: TData
      initialTimestamp?: number
    }): CQ.State.Success<TData, never>

    (params: {
      id: string
      initialTimestamp?: number
    }): CQ.State.Success<never, never>
  }

  export const createSuccessState: CreateSuccessState = <TData, TPayload>({
    id,
    data,
    payload,
    initialTimestamp,
  }: {
    id: string
    data?: TData
    payload?: TPayload
    initialTimestamp?: number
  }) => ({
    status: 'success' as const,
    meta: {
      id,
      payload: payload ?? null,
      initialTimestamp: initialTimestamp ?? Date.now(),
      currentTimestamp: Date.now(),
    },
    data: data ?? null,
  })

  type CreateErrorState = {
    <TData, TError extends Error, TPayload>(params: {
      id: string
      error: TError
      payload: TPayload
      data?: TData
      initialTimestamp?: number
    }): CQ.State.Error<TData, TError, TPayload>

    <TError extends Error, TPayload>(params: {
      id: string
      error: TError
      payload: TPayload
      initialTimestamp?: number
    }): CQ.State.Error<never, TError, TPayload>

    <TData, TError extends Error>(params: {
      id: string
      error: TError
      data?: TData
      initialTimestamp?: number
    }): CQ.State.Error<TData, TError, never>

    <TError extends Error>(params: {
      id: string
      error: TError
      initialTimestamp?: number
    }): CQ.State.Error<never, TError, never>
  }

  export const createErrorState: CreateErrorState = <TData, TError extends Error, TPayload>({
    id,
    error,
    payload,
    data,
    initialTimestamp,
  }: {
    id: string
    error: TError
    payload?: TPayload
    data?: TData
    initialTimestamp?: number
  }) => ({
    status: 'error' as const,
    meta: {
      id,
      payload: payload ?? null,
      initialTimestamp: initialTimestamp ?? Date.now(),
      currentTimestamp: Date.now(),
    },
    data: data ?? null,
    error,
  })
}
