// eslint-disable-next-line no-restricted-imports
import { BoxProps, ModalContentProps, ModalProps, PopoverBody, PopoverBodyProps, PopoverContent, PopoverContentProps, chakra, usePopoverContext, usePopoverStyles } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Sheet } from 'presentation/components/Sheet/Sheet'
import { FC, PropsWithChildren } from 'react'

export type ResponsivePopoverContentProps = PropsWithChildren<{
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>
  modalContentProps?: ModalContentProps
  popoverContentProps?: PopoverContentProps
  popoverBodyProps?: PopoverBodyProps
}>

/**
 * Replaces Chakra's popover with bottom sheet menu on mobile.
 */
export const ResponsivePopoverContent = (props: ResponsivePopoverContentProps) => {
  const { children, modalProps, modalContentProps, popoverContentProps, popoverBodyProps } = props
  return (
    <Breakpoint
      mobSm={(
        <PopoverContentModal
          modalProps={modalProps}
          modalContentProps={modalContentProps}
        >
          {children}
        </PopoverContentModal>
      )}
      tabSm={(
        <PopoverContent
          zIndex='popover'
          position='relative'
          bg='accent.hover'
          {...popoverContentProps}
        >
          {/* <PopoverArrow /> */}
          <PopoverBody {...popoverBodyProps}>{children}</PopoverBody>
        </PopoverContent>
      )}
    />
  )
}

type PopoverContentModalProps = PropsWithChildren<{
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>
  modalContentProps?: ModalContentProps
}>

const PopoverContentModal = ({
  children,
  modalProps,
  modalContentProps,
}: PopoverContentModalProps) => {
  const styles = usePopoverStyles()
  const { isOpen, onClose } = usePopoverContext()

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      modalProps={modalProps}
      modalContentProps={{
        ...modalContentProps,
        sx: {
          ...styles.content,
          ...styles.body,
          ...modalContentProps?.sx,
        },
      }}
    >
      {children}
    </Sheet>
  )
}

export const PopoverArrow: FC<BoxProps> = () => {
  const popper = usePopoverContext()
  const { getArrowProps, getArrowInnerProps } = popper
  const styles = usePopoverStyles()

  return (
    <chakra.div
      {...getArrowProps({
        size: '32px',
        pointerEvents: 'none',
      })}
    >
      <chakra.div
        {...getArrowInnerProps()}
        bg='none !important'
        transform='rotate(0deg) !important'
        boxShadow='none !important'
        display='flex'
        justifyContent='center'
        sx={{
          'pt': '4px',
          'pb': '0',

          '*[data-popper-placement^="top"] &': {
            pt: '0',
            pb: '16px',
          },
        }}
      >
        <svg width='32' height='12' viewBox='0 0 32 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <chakra.path d='M14.2 0.64C15.2667 -0.213333 16.7333 -0.213334 17.8 0.64L32 12H0L14.2 0.64Z' fill={styles.content.bgColor as string} />
          <chakra.path fillRule='evenodd' clipRule='evenodd' d='M29 10.9333L17.2 1.49333C16.4889 0.924444 15.5111 0.924445 14.8 1.49333L3 10.9333H29ZM17.8 0.64C16.7333 -0.213334 15.2667 -0.213333 14.2 0.64L0 12H32L17.8 0.64Z' fill={styles.content.borderColor as string} />
          <chakra.path d='M29 10.9333L30.4081 12H1.69189L3 10.9333H29Z' fill={styles.content.bgColor as string} />
        </svg>
      </chakra.div>
    </chakra.div>
  )
}
