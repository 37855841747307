import { Effect, Exit, Sink, Stream } from 'effect'
import { AttemptBatchSkiptracePayload, ConfirmBatchSkiptracePayload, ReskipConfig } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { AttemptBatchSkiptraceState, BatchSkiptraceProgressState, BatchSkiptraceState, ConfirmBatchSkiptraceState, DownloadBatchSkiptraceState, GetBatchSkiptraceByListIdState, GetSelectionToSkiptraceCountState, GetSelectionUnskiptracedCountState, GetStatsByListIdState, SelectionToSkiptraceCountState, SelectionUnskiptracedCountState, StatsState, BatchSkiptraceStore as Store, WatchBatchSkiptraceProgressState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/BatchSkiptraceStore'
import { BatchSkiptraceStoreDeps } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/BatchSkiptraceStoreDeps'
import { ErrorLib } from 'libs/errors'
import { IS_DEV_ENV } from 'presentation/const/env.const'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const make = (deps: BatchSkiptraceStoreDeps) => create<Store>()(immer(set => ({
  // #region data
  batchSkiptrace: { state: BatchSkiptraceState.Initial() },
  stats: { state: StatsState.Initial() },
  selectionUnskiptracedCount: { state: SelectionUnskiptracedCountState.Initial() },
  selectionToSkiptraceCount: { state: SelectionToSkiptraceCountState.Initial() },
  progress: { state: BatchSkiptraceProgressState.Initial() },
  // #endregion

  // #region getBatchResultByListId
  getByListId: {
    state: GetBatchSkiptraceByListIdState.Initial(),
    execute: (listId, page) => {
      set(state => {
        state.getByListId.state = GetBatchSkiptraceByListIdState.Loading()
        state.batchSkiptrace.state = BatchSkiptraceState.Loading()
      })

      void deps.batchSkiptraceRepo.getByListId(listId, page)
        .then(result => {
          set(state => {
            state.getByListId.state = GetBatchSkiptraceByListIdState.Success()
            state.batchSkiptrace.state = BatchSkiptraceState.Success({ batchSkiptrace: result })
          })
        })
        .catch(error => {
          ErrorLib.report(error)
          set(state => {
            state.getByListId.state = GetBatchSkiptraceByListIdState.Failed(error)
            state.batchSkiptrace.state = BatchSkiptraceState.Failed()
          })
        })
    },
  },
  // #endregion

  // #region getStatsByListId
  getStatsByListId: {
    state: GetBatchSkiptraceByListIdState.Initial(),
    execute: listId => {
      set(state => {
        state.getStatsByListId.state = GetStatsByListIdState.Loading()
        state.stats.state = StatsState.Loading()
      })

      void deps.batchSkiptraceRepo.getStatsByListId(listId)
        .then(result => {
          set(state => {
            state.getStatsByListId.state = GetStatsByListIdState.Success()
            state.stats.state = StatsState.Success({ stats: result })
          })
        })
        .catch(error => {
          ErrorLib.report(error)
          set(state => {
            state.getStatsByListId.state = GetStatsByListIdState.Failed(error)
            state.stats.state = StatsState.Failed()
          })
        })
    },
  },
  // #endregion

  // #region getSelectionToSkiptraceCount
  getSelectionToSkiptraceCount: {
    state: GetSelectionToSkiptraceCountState.Initial(),
    execute: payload => {
      set(state => {
        state.selectionToSkiptraceCount.state = SelectionToSkiptraceCountState.Loading()
        state.getSelectionToSkiptraceCount.state = GetSelectionToSkiptraceCountState.Loading()
      })

      void deps.batchSkiptraceRepo.getSelectionToSkiptraceCount(payload)
        .then(result => {
          set(state => {
            state.selectionToSkiptraceCount.state = SelectionToSkiptraceCountState.Success(result)
            state.getSelectionToSkiptraceCount.state = GetSelectionToSkiptraceCountState.Success()
          })
        })
        .catch(error => {
          ErrorLib.report(error)
          set(state => {
            state.selectionToSkiptraceCount.state = SelectionToSkiptraceCountState.Failed()
            state.getSelectionToSkiptraceCount.state = GetSelectionToSkiptraceCountState.Failed({ error })
          })
        })
    },
    reset: () => {
      set(state => {
        state.selectionToSkiptraceCount.state = SelectionToSkiptraceCountState.Initial()
        state.getSelectionToSkiptraceCount.state = GetSelectionToSkiptraceCountState.Initial()
      })
    },
  },

  // #region getSelectionUnskiptracedCount
  getSelectionUnskiptracedCount: {
    state: GetSelectionUnskiptracedCountState.Initial(),
    execute: payload => {
      set(state => {
        state.selectionUnskiptracedCount.state = SelectionUnskiptracedCountState.Loading()
        state.getSelectionUnskiptracedCount.state = GetSelectionUnskiptracedCountState.Loading()
      })

      void deps.batchSkiptraceRepo.getSelectionToSkiptraceCount({
        ...payload,
        reskipConfig: ReskipConfig.DoNotReskip(),
      })
        .then(result => {
          set(state => {
            state.selectionUnskiptracedCount.state = SelectionUnskiptracedCountState.Success(result)
            state.getSelectionUnskiptracedCount.state = GetSelectionUnskiptracedCountState.Success()
          })
        })
        .catch(error => {
          ErrorLib.report(error)
          set(state => {
            state.selectionUnskiptracedCount.state = SelectionUnskiptracedCountState.Failed()
            state.getSelectionUnskiptracedCount.state = GetSelectionUnskiptracedCountState.Failed({ error })
          })
        })
    },
    reset: () => {
      set(state => {
        state.selectionUnskiptracedCount.state = SelectionUnskiptracedCountState.Initial()
        state.getSelectionUnskiptracedCount.state = GetSelectionUnskiptracedCountState.Initial()
      })
    },
  },

  // #region doBatchSkiptrace
  attemptBatchSkiptrace: {
    state: AttemptBatchSkiptraceState.Initial(),
    execute: (payload: AttemptBatchSkiptracePayload) => {
      set(state => {
        state.attemptBatchSkiptrace.state = AttemptBatchSkiptraceState.Attempting()
      })

      void deps.batchSkiptraceRepo.attemptBatchSkiptrace(payload)
        .then(result => {
          set(state => {
            state.attemptBatchSkiptrace.state = AttemptBatchSkiptraceState.Attempted({
              attempt: result.attempt,
            })
          })
        })
        .catch((error: Error) => {
          ErrorLib.report(error)
          set(state => {
            state.attemptBatchSkiptrace.state = AttemptBatchSkiptraceState.Failed({ error })
          })
        })
    },
  },
  // #endregion

  // #region confirmBatchSkiptrace
  confirmBatchSkiptrace: {
    state: ConfirmBatchSkiptraceState.Initial(),
    execute: (payload: ConfirmBatchSkiptracePayload) => {
      set(state => {
        state.confirmBatchSkiptrace.state = ConfirmBatchSkiptraceState.Loading()
      })

      void deps.batchSkiptraceRepo.confirmBatchSkiptrace(payload)
        .then(() => {
          set(state => {
            state.confirmBatchSkiptrace.state = ConfirmBatchSkiptraceState.Success()
          })
        })
        .catch((error: Error) => {
          ErrorLib.report(error)
          set(state => {
            state.confirmBatchSkiptrace.state = ConfirmBatchSkiptraceState.Failed({ error })
          })
        })
    },
  },
  // #endregion

  // #region download
  download: {
    state: DownloadBatchSkiptraceState.Initial(),
    execute: (list, format) => {
      set(state => {
        state.download.state = DownloadBatchSkiptraceState.Loading()
      })

      void deps.batchSkiptraceRepo.download(list, format)
        .then(() => {
          set(state => {
            state.download.state = DownloadBatchSkiptraceState.Success()
          })
        })
        .catch(error => {
          ErrorLib.report(error)
          set(state => {
            state.download.state = DownloadBatchSkiptraceState.Failed(error)
          })
        })
    },
  },
  // #endregion

  // #region watchProgress
  watchProgress: {
    state: WatchBatchSkiptraceProgressState.Initial(),
    execute: listId => {
      set(state => {
        state.progress.state = BatchSkiptraceProgressState.Checking()
        state.watchProgress.state = WatchBatchSkiptraceProgressState.Checking()
      })

      void deps.batchSkiptraceRepo.watchProgress(listId).pipe(
        Stream.map(progress => {
          set(state => {
            if (progress._tag === 'Idle') {
              state.progress.state = BatchSkiptraceProgressState.Initial()
              state.watchProgress.state = WatchBatchSkiptraceProgressState.Initial()
            } else if (progress._tag === 'InProgress') {
              state.progress.state = BatchSkiptraceProgressState.InProgress(progress)
              state.watchProgress.state = WatchBatchSkiptraceProgressState.InProgress()
            }
          })

          return progress
        }),
        Stream.run(Sink.last()),
        Effect.runPromiseExit,
      )
        .then(Exit.match({
          onFailure: cause => {
            if (IS_DEV_ENV)
              // eslint-disable-next-line no-console
              console.log('watchProgress error', cause)
          },
          onSuccess: () => {},
        }))
    },
  },
  // #endregion
})))
