import { Text } from '@chakra-ui/react'
import { Exclamation } from 'presentation/screens/Plans/HeroHeader/Exclamation'
import { Underline } from 'presentation/screens/Plans/HeroHeader/Underline'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const EverythingYouNeedHeroText = () => (
  <Text
    {...mbpg({
      mob: {
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '38px',
        textAlign: 'center',
      },
      tabSm: {
        fontWeight: '700',
        fontSize: '38px',
        lineHeight: '42px',
        textAlign: 'center',
      },
      dtSm: {
        fontWeight: '700',
        fontSize: '40px',
        lineHeight: '44px',
        textAlign: 'left',
      },
      dtLg: {
        fontWeight: '700',
        fontSize: '54px',
        lineHeight: '60px',
      },
    })}
    fontFamily='Inter'
    fontStyle='normal'
    letterSpacing='-0.03em'
    color='graystrong.600'
  >
    Everything you need to
    <br />
    {' '}
    buy&nbsp;
    <Text
      as='span'
      {...mbpg({
        tabSm: {
          fontWeight: '700',
          fontSize: '46px',
          lineHeight: '80px',
        },
        dtSm: {
          fontWeight: '700',
          fontSize: '52px',
          lineHeight: '57px',
        },
        dtLg: {
          fontWeight: '700',
          fontSize: '66px',
          lineHeight: '72px',
        },
      })}
      fontFamily='Kalam'
      fontStyle='normal'
      letterSpacing='-0.03em'
      color='specialsat.500'
      position='relative'
    >
      more houses
      <Underline
        position='absolute'
        left={0}
        bottom={mbp({ mob: '0px', tabSm: '10px', dtSm: '15px' })}
        width='100%'
      />
    </Text>
      &nbsp;
    <Exclamation
      display='inline-block'
      w={mbp({ mob: '1.5', tabSm: '2', dtSm: '2.5', dtLg: '3' })}
    />
  </Text>
)
