import { Flex, NumberInput, NumberInputField, Text } from '@chakra-ui/react'
import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { px } from 'presentation/utils/px'
import { useEffect, useRef } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

type PartialRangeInputV2Props = {
  initialValue: PartialRange | null
  onDebouncedValidChange: (range: PartialRange) => void
  subjectValue?: string
  validateEither?: (value: number | null) => boolean
  onError?: (errors: FieldErrors<PartialRangeForm>) => void
  formatInputDisplay?: (value: string | number) => string | number
  precision?: number
  shouldShowSubjectMarker?: boolean
}

type Props = PartialRangeInputV2Props

export type PartialRangeForm = {
  min: string
  max: string
}

export type PartialRangeFormErrors = FieldErrors<PartialRangeForm>

const DEBOUNCE_MS = 1000

export const PartialRangeInputV2 = ({
  validateEither = () => true,
  onError,
  formatInputDisplay,
  precision = 0,
  shouldShowSubjectMarker,
  ...props
}: Props) => {
  const {
    formState: { errors },
    watch,
    trigger,
    setValue,
    control,
  } = useForm<PartialRangeForm>({
    defaultValues: {
      min: props.initialValue?.[0]?.toString() ?? '',
      max: props.initialValue?.[1]?.toString() ?? '',
    },
    mode: 'onChange',
  })

  const minNumFromForm = NumberLib.fromStringSafe(watch('min'))
  const maxNumFromForm = NumberLib.fromStringSafe(watch('max'))

  // validate max when min changes
  useEffect(() => {
    void trigger('max')
  }, [minNumFromForm])

  // validate min when max changes
  useEffect(() => {
    void trigger('min')
  }, [maxNumFromForm])

  const validateRange = ([min, max]: [number | null, number | null]) => {
    if (min === null || max === null) return true
    return min <= max
  }

  // call onDebouncedValidChange when min or max changes
  // IF valid, value has changed, debounce period has passed
  const queuedRangeRef = useRef<PartialRange | null>(null)
  useEffect(() => {
    const initialMin = props.initialValue?.[0] ?? null
    const initialMax = props.initialValue?.[1] ?? null
    const didChange = minNumFromForm !== initialMin || maxNumFromForm !== initialMax

    if (!didChange) return

    const isValid = validateEither(minNumFromForm)
      && validateEither(maxNumFromForm)
      && validateRange([minNumFromForm, maxNumFromForm])

    let timeoutId: ReturnType<typeof setTimeout> | null = null

    if (isValid) {
      queuedRangeRef.current = [minNumFromForm, maxNumFromForm]
      timeoutId = setTimeout(() => {
        queuedRangeRef.current = null
        props.onDebouncedValidChange([minNumFromForm, maxNumFromForm])
      }, DEBOUNCE_MS)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [minNumFromForm, maxNumFromForm])

  /**
   * When unmounted, call any queued debounced
   * change that hasn't been called yet
   */
  useEffect(() => () => {
    if (!queuedRangeRef.current) return
    props.onDebouncedValidChange(queuedRangeRef.current)
  }, [])

  // update form values when initialValue changes
  useEffect(() => {
    setValue('min', props.initialValue?.[0]?.toString() ?? '')
    setValue('max', props.initialValue?.[1]?.toString() ?? '')
  }, [props.initialValue?.[0], props.initialValue?.[1]])

  useEffect(() => {
    onError?.(errors)
  }, [errors.max, errors.min])

  return (
    <Flex w='full' gap={2}>
      <Controller
        name='min'
        control={control}
        rules={{
          validate: (value, formValues) =>
            validateEither(NumberLib.fromStringSafe(value))
            && validateRange([
              NumberLib.fromStringSafe(value),
              NumberLib.fromStringSafe(formValues.max),
            ]),
        }}
        render={({ field: { ref, ...restField } }) => (
          <NumberInput
            flex='1 1 0'
            isInvalid={!!errors.min}
            format={formatInputDisplay}
            precision={precision}
            {...restField}
          >
            <NumberInputField
              placeholder='Min'
              px={px(16 - 1)}
              py={px(8 - 1)}
              ref={ref}
              name={restField.name}
            />
          </NumberInput>
        )}
      />

      {shouldShowSubjectMarker && (
        <Flex
          id='partial_range_input__subject_property'
          flexDir='column'
          gap={0.25}
          alignItems='center'
        >
          <SubjectPropertyMarker
            markerType='pin'
            size='xxs'
          />
          <Text
            textStyle='tagL'
            color='graystrong.200'
          >
            {props.subjectValue ?? '--'}
          </Text>
        </Flex>
      )}

      <Controller
        name='max'
        control={control}
        rules={{
          validate: (value, formValues) =>
            validateEither(NumberLib.fromStringSafe(value))
            && validateRange([
              NumberLib.fromStringSafe(formValues.min),
              NumberLib.fromStringSafe(value),
            ]),
        }}
        render={({ field: { ref, ...restField } }) => (
          <NumberInput
            flex='1 1 0'
            isInvalid={!!errors.max}
            format={formatInputDisplay}
            precision={precision}
            {...restField}
          >
            <NumberInputField
              placeholder='Max'
              px={px(16 - 1)}
              py={px(8 - 1)}
              ref={ref}
              name={restField.name}
            />
          </NumberInput>
        )}
      />
    </Flex>
  )
}
