export const LIST_FILTERS_TAB_RECORD = {
  LOCATION: 'LOCATION',
  LAND_FEATURES: 'LAND_FEATURES',
  PROPERTY_TYPE: 'PROPERTY_TYPE',
  OWNER: 'OWNER',
  STRESS_INDICATOR: 'STRESS_INDICATOR',
  EQUITY: 'EQUITY',
  MLS: 'MLS',
}

export const LIST_FILTERS_TAB_LIST = [
  LIST_FILTERS_TAB_RECORD.LOCATION,
  LIST_FILTERS_TAB_RECORD.LAND_FEATURES,
  LIST_FILTERS_TAB_RECORD.PROPERTY_TYPE,
  LIST_FILTERS_TAB_RECORD.OWNER,
  LIST_FILTERS_TAB_RECORD.STRESS_INDICATOR,
  LIST_FILTERS_TAB_RECORD.EQUITY,
  LIST_FILTERS_TAB_RECORD.MLS,
]
