import { Grid, HStack, VStack } from '@chakra-ui/react'
import { Array, Option } from 'effect'
import CompsFeatureRowViewModel from './CompsFeatureRowViewModel'
import { Fragment } from 'react'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'
import CompsDetailDisclosure from 'presentation/screens/Plans/components/CompsDetailDisclosure'

const CompsFeatureRow = () => {
  const vm = CompsFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Nationwide MLS Comps
        </PrimaryText>
        <SecondaryText>
          Quickly reveiw MLS sales to calculate a value for your investment properties.
        </SecondaryText>
      </VStack>
      {Array.map(vm, (content, i) => (
        <VStack justify='center' spacing='1' key={i}>
          {content.pipe(
            Option.match({
              onSome: value => (
                <Fragment key={i}>
                  <HStack>
                    <PrimaryText>
                      {value.primaryText}
                    </PrimaryText>
                    <CompsDetailDisclosure
                      propertyCount={value.popoverValue}
                      iconProps={{ color: 'neutral.500' }}
                    />
                  </HStack>
                  {value.secondaryText1.pipe(
                    Option.map(text => (
                      <SecondaryText key='secondary-1'>
                        {text}
                      </SecondaryText>
                    )),
                    Option.getOrNull,
                  )}
                  <SecondaryText>
                    {value.secondaryText2}
                  </SecondaryText>
                </Fragment>
              ),
              onNone: () => (
                <FeatureAvailability isAvailable={false} />
              ),
            }),
          )}
        </VStack>
      ))}
    </Grid>
  )
}

export default CompsFeatureRow
