import { Data, Effect, Exit, FiberHandle, Layer, Match, Option, Request, Runtime, Scope, Sink, Stream, pipe } from 'effect'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import ListSourceRepo from 'features/ListBuilder/domain/repository/ListSourceRepo'
import MarketingListMemberRepo from 'features/ListBuilder/domain/repository/MarketingListMemberRepo'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import { BuildList, BuildListState, BuildTotalState, DeleteListMembersState, DeleteListState, DownloadListState, GetDownloadStatsState, ListBuilderStore, ListMembersPaginationState, ListMembersState, ListsPaginationState, ListsState, SaveListState, SelectListBranch, SelectListState, UpdatingListNameState } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStore'
import { ListBuilderStoreLayer } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStoreDeps'
import { EffectLib } from 'libs/Effect'
import { StoreApi, createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const makeStore = (deps: ListBuilderStoreLayer): StoreApi<ListBuilderStore> => {
  const StoreScope = Effect.runSync(Scope.make())

  const StoreCache = Request.makeCache({
    capacity: 100,
    timeToLive: '60 minutes',
  }).pipe(Effect.runSync)

  const StoreRuntime = pipe(
    Layer.merge(
      deps,
      Layer.setRequestCache(StoreCache),
    ),
    Layer.toRuntime,
    Scope.extend(StoreScope),
    Effect.runSync,
  )

  return createStore<ListBuilderStore>()(immer((set, get) => ({
    closeStore: () => {
      Scope.close(StoreScope, Exit.void).pipe(Runtime.runSync(StoreRuntime))
    },
    criteria: PropertyListCriteria.make(),
    switchCriteria: type => {
      const criteria = Match.value(type).pipe(
        Match.when('property-lists', () => Effect.sync(() => PropertyListCriteria.make())),
        Match.when('cash-buyers', () => Effect.sync(() => null)),
        Match.when('private-lenders', () => Effect.sync(() => null)),
        Match.when('drive', () => Effect.sync(() => null)),
        Match.exhaustive,
        Effect.runSync,
      )

      set({ criteria })
    },
    buildTotal: BuildTotalState.Initial(),

    // #region buildList
    buildList: {
      state: BuildListState.Initial(),
      execute: Effect.gen(function* (_) {
        const runFork = yield * _(FiberHandle.makeRuntime<never>())
        const listSourceRepo = yield * _(ListSourceRepo)

        const execute: BuildList['execute'] = criteria => {
          const hasLocation = criteria.location.length > 0

          set(store => {
            store.buildList.state = hasLocation ? BuildListState.Loading() : BuildListState.Initial()
            store.buildTotal = hasLocation ? BuildTotalState.Loading() : BuildTotalState.Initial()
            store.criteria = criteria
          })

          if (!hasLocation) return

          const buildListTask = listSourceRepo.buildList(criteria).pipe(
            Effect.tap(list => {
              set(store => {
                store.buildList.state = BuildListState.Success({ preview: list })
              })
            }),
            Effect.tapErrorCause(cause => Effect.sync(() => {
              set(store => {
                store.buildList.state = BuildListState.Failed({ error: EffectLib.causeToError(cause) })
              })
            })),
          )

          const buildTotalTask = listSourceRepo.getBuildTotal(criteria).pipe(
            Effect.tap(count => {
              set(store => {
                store.buildTotal = BuildTotalState.Success({ total: count })
              })
            }),
            Effect.tapErrorCause(cause => Effect.sync(() => {
              set(store => {
                store.buildTotal = BuildTotalState.Failed({ error: EffectLib.causeToError(cause) })
              })
            })),
          )

          Effect.logInfo('start building list').pipe(
            Effect.andThen(Effect.all([buildListTask, buildTotalTask], { concurrency: 'unbounded' })),
            Effect.tap(Effect.logInfo('build complete')),
            Effect.tapDefect(Effect.logFatal),
            runFork,
          )
        }

        return execute
      }).pipe(Scope.extend(StoreScope), Runtime.runSync(StoreRuntime)),
    },
    // #endregion buildList

    // #region saveList
    saveList: {
      state: SaveListState.Initial(),
      execute: listName => {
        set(store => {
          store.saveList.state = SaveListState.Loading()
        })

        const criteria = get().criteria

        if (criteria === null) {
          set(store => {
            store.saveList.state = SaveListState.Initial()
          })
          return
        }

        void MarketingListRepo.pipe(
          Effect.andThen(repo => {
            set(store => {
              /**
               * Reset previous build related data
               */
              store.criteria = PropertyListCriteria.make()
              store.buildTotal = BuildTotalState.Initial()
              store.buildList.state = BuildListState.Initial()
            })
            return repo.saveList({ listName, criteria })
          }),
          Effect.tap(marketingList => {
            set(store => {
              store.saveList.state = SaveListState.Success({ list: marketingList })

              get().loadLists.execute()
            })
          }),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.saveList.state = SaveListState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion saveList

    // #region selectList
    listMembers: ListMembersState.Initial(),
    selectList: {
      state: SelectListState.Initial(),
      execute: Effect.gen(function * (_) {
        const runForkCancellable = yield * _(FiberHandle.makeRuntime<MarketingListMemberRepo | MarketingListRepo>())
        const runtime = yield * _(Effect.runtime())
        const runFork = Runtime.runFork(runtime)
        const repo = yield * _(MarketingListRepo)
        const memberRepo = yield * _(MarketingListMemberRepo)

        const execute: SelectListBranch['execute'] = listId => {
          set(store => {
            store.selectList.state = SelectListState.Loading()
            store.listMembers = ListMembersState.Loading()
          })

          /**
           * Get the marketing list
           */
          const fetchListTask = repo.findById(listId).pipe(
            Effect.tap(list => {
              set(store => {
                store.selectList.state = SelectListState.Success({ list })
              })
            }),
            Effect.tapErrorCause(cause => Effect.sync(() => {
              set(store => {
                store.selectList.state = SelectListState.Failed({
                  error: EffectLib.causeToError(cause),
                })
              })
            })),
          )

          const page = 1
          const fetchListMembersTask = memberRepo.getByPage(listId, page).pipe(
            Effect.tap(pageResult => {
              set(store => {
                store.listMembers = ListMembersState.Success({ listId, members: pageResult.members })

                store.listMembersPagination.state = ListMembersPaginationState.Success({
                  countPerPage: pageResult.countPerPage,
                  currentPage: pageResult.currentPage,
                  listId,
                  totalCount: pageResult.totalCount,
                })
              })
            }),
          )

          void Effect.all([fetchListTask, fetchListMembersTask], { concurrency: 'unbounded' })
            .pipe(
              Effect.catchTag('ListNotFoundError', error =>
                Effect.sync(() => {
                  set(store => {
                    store.selectList.state = SelectListState.Failed({ error })
                  })
                }),
              ),
              Effect.tapDefect(Effect.logFatal),
              runFork,
            )

          /**
           * Subscribe to import in progress if it is importing
           */
          void repo.subscribeImportProgress(listId).pipe(
            Stream.flatMap(progress => MarketingListMemberRepo.pipe(
              /**
               * For every emit of import in progress, update the members list
               */
              Effect.flatMap(repo => repo.getByPage(listId, page)),
              Stream.fromEffect,
              Stream.tap(pageResult =>
                Effect.sync(() => {
                  set(store => {
                    if (progress.percentage !== 100) {
                      store.listMembers = ListMembersState.Importing({
                        members: pageResult.members,
                        progress,
                      })
                    }
                  })
                }),
              ),
              Stream.tapError(error =>
                Effect.sync(() => {
                  set(store => {
                    store.listMembers = ListMembersState.Failed({ error })
                  })
                }),
              ),
            )),
            Stream.run(Sink.last()),
            Effect.tap(pageResult => {
              set(store => {
                Option.match(pageResult, {
                  onNone: () => {
                    store.listMembersPagination.state = ListMembersPaginationState.Initial()
                  },
                  onSome: result => {
                    store.listMembers = ListMembersState.Success({ listId, members: result.members })

                    store.listMembersPagination.state = ListMembersPaginationState.Success({
                      countPerPage: result.countPerPage,
                      currentPage: result.currentPage,
                      listId,
                      totalCount: result.totalCount,
                    })
                  },
                })
              })
            }),
            runForkCancellable,
          )
        }

        return execute
      }).pipe(Scope.extend(StoreScope), Runtime.runSync(StoreRuntime)),

    },
    // #endregion selectedList

    // #region Lists
    loadLists: {
      state: ListsState.Initial(),
      execute: () => {
        set(store => {
          store.loadLists.state = ListsState.Loading()
        })

        void MarketingListRepo.pipe(
          Effect.flatMap(repo => repo.getByPage(1)),
          Effect.tap(Effect.logInfo('Loading list')),
          Effect.tap(results => {
            set(store => {
              store.loadLists.state = ListsState.Success({ lists: results.lists })

              store.listsPagination.state = ListsPaginationState.Success({
                countPerPage: results.countPerPage,
                currentPage: results.currentPage,
                totalCount: results.totalCount,
              })
            })
          }),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.loadLists.state = ListsState.Failed({ error: EffectLib.causeToError(cause) },
              )
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion

    // #region listMemberPagination
    listMembersPagination: {
      state: ListMembersPaginationState.Initial(),
      execute: (page: number) => {
        const initialState = get().listMembersPagination.state

        if (ListMembersPaginationState.$is('Initial')(initialState)) return

        set(store => {
          store.listMembers = ListMembersState.Loading()

          if (ListMembersPaginationState.$is('Success')(initialState)) {
            store.listMembersPagination.state = ListMembersPaginationState.Loading({
              ...initialState,
              currentPage: page,
            })
          }
        })

        const state = get().selectList.state

        void pipe(
          state._tag === 'Success'
            ? Option.some(state.list.id)
            : Option.none(),
          Effect.flatMap(id => MarketingListMemberRepo.pipe(
            Effect.andThen(repo => repo.getByPage(id, page)),
            Effect.map(result => Data.struct({
              listId: id,
              result,
            })),
          )),
          Effect.tap(({ listId, result }) => {
            set(store => {
              store.listMembersPagination.state = ListMembersPaginationState.Success({
                listId,
                countPerPage: result.countPerPage,
                currentPage: result.currentPage,
                totalCount: result.totalCount,
              })

              store.listMembers = ListMembersState.Success({ listId, members: result.members })
            })
          }),
          Effect.catchTag('ListNotFoundError', error =>
            Effect.sync(() => {
              set(store => {
                store.listMembersPagination.state = ListMembersPaginationState.Failed({
                  error,
                })
                store.listMembers = ListMembersState.Failed({ error })
              })
            }),
          ),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.listMembersPagination.state = ListMembersPaginationState.Failed({
                error: EffectLib.causeToError(cause),
              })
              store.listMembers = ListMembersState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion listMemberPagination

    // #region listsPagination
    listsPagination: {
      state: ListsPaginationState.Initial(),
      execute: (page: number) => {
        const initialState = get().listsPagination.state

        if (
          ListsPaginationState.$is('Initial')(initialState)
          || ListsPaginationState.$is('Failed')(initialState)
        ) return

        set(store => {
          store.loadLists.state = ListsState.Loading()
          store.listsPagination.state = ListsPaginationState.Loading({
            ...initialState,
            currentPage: page,
          })
        })

        void MarketingListRepo.pipe(
          Effect.andThen(repo => repo.getByPage(page)),
          Effect.tap(result => {
            set(store => {
              store.listsPagination.state = ListsPaginationState.Success({
                countPerPage: result.countPerPage,
                currentPage: result.currentPage,
                totalCount: result.totalCount,
              })

              store.loadLists.state = ListsState.Success({ lists: result.lists })
            })
          }),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.listsPagination.state = ListsPaginationState.Failed({ error: EffectLib.causeToError(cause) })
              store.loadLists.state = ListsState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion listsPagination

    // #region deleteList
    deleteLists: {
      state: DeleteListState.Initial(),
      execute: listIdsToDelete => {
        set(store => {
          store.deleteLists.state = DeleteListState.Loading()
        })

        void MarketingListRepo.pipe(
          Effect.andThen(repo => repo.deleteByIds(listIdsToDelete)),
          Effect.tap(result => {
            set(store => {
              store.deleteLists.state = DeleteListState.Success(result)

              const loadList = store.loadLists.state

              const remainingLists = ListsState.$is('Success')(loadList)
                ? loadList.lists.filter(list => !result.successfulIds.includes(list.id))
                : []

              store.loadLists.state = ListsState.Success({ lists: remainingLists })
            })
          }),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.deleteLists.state = DeleteListState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion deleteList

    // #region deleteListMembers
    deleteListMembers: {
      state: DeleteListMembersState.Initial(),
      execute: (listId, memberIds) => {
        set(store => {
          store.deleteListMembers.state = DeleteListMembersState.Loading()
        })

        void MarketingListMemberRepo.pipe(
          Effect.andThen(repo => repo.deleteByIds(listId, memberIds)),
          Effect.tap(result => {
            const listMembers = get().listMembers
            set(store => {
              store.deleteListMembers.state = DeleteListMembersState.Success(result)

              const remainingMembers = ListMembersState.$is('Success')(listMembers)
                ? listMembers.members.filter(member => !result.successfulIds.includes(member.id))
                : []

              store.listMembers = ListMembersState.Success({ listId, members: remainingMembers })
            })
          }),
          Effect.catchAll(e =>
            Effect.sync(() => {
              set(store => {
                store.deleteListMembers.state = DeleteListMembersState.Failed({ error: e })
              })
            }),
          ),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.deleteListMembers.state = DeleteListMembersState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion deleteListMembers

    // #region updateName
    updateListName: {
      state: UpdatingListNameState.Initial(),
      execute: (id, name) => {
        set(store => {
          store.updateListName.state = UpdatingListNameState.Loading()
        })

        void MarketingListRepo.pipe(
          Effect.andThen(repo => repo.updateListName(id, name)),
          Effect.tap(marketingList => {
            set(store => {
              store.updateListName.state = UpdatingListNameState.Success({ list: marketingList })
              store.selectList.state = SelectListState.Success({ list: marketingList })
              get().loadLists.execute()
            })
          }),
          Effect.catchAll(e =>
            Effect.sync(() => {
              set(store => {
                store.updateListName.state = UpdatingListNameState.Failed({ error: e })
              })
            }),
          ),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.updateListName.state = UpdatingListNameState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion updateName

    // #region getDownloadStats
    getDownloadStats: {
      state: GetDownloadStatsState.Initial(),
      execute: listId => {
        set(store => {
          store.getDownloadStats.state = GetDownloadStatsState.Loading()
        })

        void MarketingListRepo.pipe(
          Effect.andThen(repo => repo.getDownloadStats(listId)),
          Effect.tap(downloadStats => {
            set(store => {
              store.getDownloadStats.state = GetDownloadStatsState.Success({
                downloadStats,
              })
            })
          }),
          Effect.catchAll(e =>
            Effect.sync(() => {
              set(store => {
                store.getDownloadStats.state = GetDownloadStatsState.Failed({ error: e })
              })
            }),
          ),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.getDownloadStats.state = GetDownloadStatsState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion getDownloadStats

    // #region downloadList
    downloadList: {
      state: DownloadListState.Initial(),
      execute: listId => {
        set(store => {
          store.downloadList.state = DownloadListState.Loading()
        })

        void MarketingListRepo.pipe(
          Effect.andThen(repo => repo.downloadList(listId)),
          Effect.tap(() => {
            set(store => {
              store.downloadList.state = DownloadListState.Success()
            })
          }),
          Effect.catchAll(e =>
            Effect.sync(() => {
              set(store => {
                store.downloadList.state = DownloadListState.Failed({ error: e })
              })
            }),
          ),
          Effect.tapDefect(Effect.logFatal),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            set(store => {
              store.downloadList.state = DownloadListState.Failed({ error: EffectLib.causeToError(cause) })
            })
          })),
          Runtime.runPromise(StoreRuntime),
        )
      },
    },
    // #endregion downloadList
  }),
  ))
}
