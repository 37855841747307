import { SelectCompsByOption } from 'features/CMA/infra/react/CMAState.selectors'
import { create } from 'zustand'

export type ReportTypeSwitcherStore = {
  reportType: SelectCompsByOption
  toggleReportType: () => void
  setReportType: (type: SelectCompsByOption) => void
}
export const useReportTypeSwitcherStore = create<ReportTypeSwitcherStore>(set => ({
  reportType: 'SALES',
  setReportType: type => set({ reportType: type }),
  toggleReportType: () => set(state => ({
    ...state,
    reportType: state.reportType === 'SALES' ? 'RENTALS' : 'SALES',
  })),
}))
