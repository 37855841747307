import { createIcon } from '@chakra-ui/react'

export const LayersIcon = createIcon({
  displayName: 'LayersIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='none'>
      <path
        d='M12 2L2 7L12 12L22 7L12 2Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 17L12 22L22 17'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 12L12 17L22 12'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
})
