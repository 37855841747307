import { FailedInvoiceSummary } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { restoreWithoutPayment } from 'presentation/main/globalModals/RestorePlanModal/restoreWithoutPayment'
import { restoreWithPayment } from 'presentation/main/globalModals/RestorePlanModal/restoreWithPayment'
import { useRestorePlanMutation } from 'presentation/main/globalModals/RestorePlanModal/useRestorePlanMutation'

export type UseRestorePlanResult = {
  restoreWithoutPayment: () => void
  restoreWithPayment: (failedInvoiceSummary: FailedInvoiceSummary) => void
  loading: boolean
}

export const useRestorePlan = (): UseRestorePlanResult => {
  const { restore, loading } = useRestorePlanMutation()

  return {
    restoreWithoutPayment: () => restoreWithoutPayment(restore),
    restoreWithPayment,
    loading,
  }
}
