import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'

type DeleteEntries = (params: SearchHistory.DeleteEntriesPayload) => Promise<SearchHistory.DeleteEntriesResult>

export const deleteEntries: DeleteEntries = async ({ ids }) => {
  // @TODO move this to events?
  ServerCache.clearType('getHistory')

  await Promise.all(ids.map(async id => await deleteEntry({ id })))
}

const deleteEntry = async (payload: { id: string }) => {
  // @TODO move this to events?
  ServerCache.clearType('getHistory')

  await restClient.put(`legacy/leads/${payload.id}`, {
    json: {
      active: false,
      deleted: true,
    },
  })
}
