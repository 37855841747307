import { Box, Flex, Grid, GridItem, Image, useTheme } from '@chakra-ui/react'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { ListBuilderComingSoonOverlay } from 'presentation/screens/ListBuilderScreen/components/ListBuilderComingSoonOverlay'
import MapDrawButtonDark from './img/draw-button-dark.png'
import MapDrawButtonLight from './img/draw-button-light.png'
import HeaderDtLgDark from './img/header-dtLg-dark.png'
import HeaderDtLgLight from './img/header-dtLg-light.png'
import HeaderDtSmDark from './img/header-dtSm-dark.png'
import HeaderDtSmLight from './img/header-dtSm-light.png'
import HeaderTabSmDark from './img/header-tabSm-dark.png'
import HeaderTabSmLight from './img/header-tabSm-light.png'
import MapControlDark from './img/map-control-dark.png'
import MapControlLight from './img/map-control-light.png'
import MapDark from './img/map-dark.png'
import MapLegendDark from './img/map-legend-dark.png'
import MapLegendLight from './img/map-legend-light.png'
import MapLight from './img/map-light.png'
import MapMarkersDark from './img/map-markers-dark.png'
import MapMarkersLight from './img/map-markers-light.png'
import MapTooltipDark from './img/map-tooltip-dark.png'
import MapTooltipLight from './img/map-tooltip-light.png'
import ModalDark from './img/modal-dark.png'
import ModalLight from './img/modal-light.png'
import PanelButtonDark from './img/panel-button-dark.png'
import PanelButtonLight from './img/panel-button-light.png'
import PanelContentDark from './img/panel-content-dark.png'
import PanelContentLight from './img/panel-content-light.png'
import PanelInputDark from './img/panel-input-dark.png'
import PanelInputLight from './img/panel-input-light.png'

export const ListBuilderDummy = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  return (
    <Grid
      gridTemplateAreas={`
        "header header"
        "map map"
      `}
      gridTemplateColumns='1fr 1fr'
      gridTemplateRows='min-content minmax(0, 1fr)'
      h={height}
      minH={0}
    >
      <GridItem gridArea='header' zIndex={1}>
        <DummyHeader />
      </GridItem>
      <GridItem gridArea='map' zIndex={1}>
        <DummyMap />
      </GridItem>
      <GridItem gridArea='map' justifySelf='start' alignSelf='stretch' zIndex={1}>
        <DummyPanel />
      </GridItem>

      <ListBuilderComingSoonOverlay gridColumn='1 / -1' gridRow='1 / -1' zIndex={2} overflow='auto' />
    </Grid>
  )
}

const DummyHeader = () => {
  const theme = useTheme()
  const { sbp } = useSwitchBreakpointFn()
  const headerLight = sbp({
    tabSm: HeaderTabSmLight,
    dtSm: HeaderDtSmLight,
    dtLg: HeaderDtLgLight,
  }) ?? HeaderTabSmLight

  const headerDark = sbp({
    tabSm: HeaderTabSmDark,
    dtSm: HeaderDtSmDark,
    dtLg: HeaderDtLgDark,
  }) ?? HeaderTabSmDark

  const isDarkMode = theme.id === 'dark'

  const header = isDarkMode ? headerDark : headerLight

  const height = sbp({ mobSm: '56px', dtLg: '80px' })
  return (
    <Box bgColor='neutral.200'>
      <Image src={header} ml='auto' h={height} />
    </Box>
  )
}

const DummyMap = () => {
  const theme = useTheme()
  const isDarkMode = theme.id === 'dark'
  const map = isDarkMode ? MapDark : MapLight
  const mapTooltip = isDarkMode ? MapTooltipDark : MapTooltipLight
  const mapControl = isDarkMode ? MapControlDark : MapControlLight
  const mapLegend = isDarkMode ? MapLegendDark : MapLegendLight
  const mapMarkers = isDarkMode ? MapMarkersDark : MapMarkersLight
  const drawButton = isDarkMode ? MapDrawButtonDark : MapDrawButtonLight
  return (
    <Grid
      gridTemplateAreas='"layer"'
      w='full'
      h='full'
      minH={0}
      gridTemplateRows='minmax(0, 1fr)'
    >
      <Image
        gridArea='layer'
        src={map}
        objectFit='cover'
        w='full'
        h='full'
        minH={0}
      />

      <Image
        gridArea='layer'
        justifySelf='center'
        mt={3}
        src={mapTooltip}
      />

      <Image
        gridArea='layer'
        justifySelf='end'
        alignSelf='start'
        mt={2}
        mr={2}
        src={mapControl}
      />

      <Image
        gridArea='layer'
        justifySelf='start'
        alignSelf='end'
        ml={2}
        mb={2}
        src={mapLegend}
      />

      <Image
        gridArea='layer'
        justifySelf='center'
        alignSelf='center'
        objectFit='cover'
        maxH='full'
        src={mapMarkers}
      />

      <Image
        gridArea='layer'
        justifySelf='end'
        alignSelf='end'
        mr={2}
        mb={2}
        src={drawButton}
      />
    </Grid>
  )
}

const DummyPanel = () => {
  const theme = useTheme()
  const isDarkMode = theme.id === 'dark'
  const panelInput = isDarkMode ? PanelInputDark : PanelInputLight
  const panelButton = isDarkMode ? PanelButtonDark : PanelButtonLight
  const panelContent = isDarkMode ? PanelContentDark : PanelContentLight
  const modal = isDarkMode ? ModalDark : ModalLight
  return (
    <Flex h='full' minH={0}>
      <Flex
        w='392px'
        h='full'
        minH={0}
        flexDirection='column'
        bgColor='grayweak.200'
        borderBottomRightRadius={4}
      >
        <Box py={2}>
          <Image src={panelInput} mx='auto' />
        </Box>
        <Box
          flex='1'
          overflow='auto'
        >
          <Image src={panelContent} mx='auto' />
        </Box>
        <Box py={1}>
          <Image src={panelButton} w='392px' />
        </Box>
      </Flex>

      <Image
        alignSelf='center'
        justifySelf='center'
        src={modal}
      />
    </Flex>
  )
}
