import { ModalOverlay } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { FormatStepCard } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/FormatStepCard'
import { ReskipConfigStepCard } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/ReskipConfigStepCard'
import { SkiptraceBreakdownStepCard } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/SkiptraceBreakdownCard'
import { useBatchSkiptraceModal, useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { useEffect } from 'react'

export const BatchSkiptraceModal = () => {
  const modalState = useBatchSkiptraceModal()
  const modalActions = useBatchSkiptraceModalActions()
  const skiptraceActions = useBatchSkiptraceActions()

  useEffect(() => {
    if (modalState._tag !== 'Closed') return
    skiptraceActions.resetGetSelectionToSkiptraceCount()
    skiptraceActions.resetGetSelectionUnskiptracedCount()
  }, [modalState])

  return (
    <Modal
      isOpen={modalState._tag !== 'Closed'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {pipe(
          Match.value(modalState),
          Match.tag('ReskipConfigStep', state => (
            <ReskipConfigStepCard payload={state} />
          )),
          Match.tag('SkiptraceBreakdownStep', state => (
            <SkiptraceBreakdownStepCard
              payload={state}
            />
          )),
          Match.tag('FormatStep', state => (
            <FormatStepCard
              payload={state}
            />
          )),
          Match.tag('Closed', () => null),
          Match.exhaustive,
        )}
      </ModalContent>
    </Modal>
  )
}
