// eslint-disable-next-line no-restricted-imports
import { Box, BoxProps, Card, ModalContent as ChakraModalContent, Flex, IconButton, ModalOverlay, VStack } from '@chakra-ui/react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { ModalContentV1, ModalPropsV1, ModalV1 } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { TitleBar } from 'presentation/components/TitleBar/TitleBar'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { FC, ReactNode, useMemo } from 'react'

export type ResponsiveModalPropsV1 = ModalPropsV1 & Pick<BoxProps, 'maxW'> & {
  title: string
  footer?: ReactNode
}

/** @deprecated Use v2 instead */
export const ResponsiveFullscreenModalV1: FC<ResponsiveModalPropsV1> = ({
  children,
  onClose,
  maxW,
  title,
  footer,
  size,
  variant,
  colorScheme,
  ...props
}) => {
  const { sbp } = useSwitchBreakpointFn()

  return (
    <Breakpoint
      mobSm={(
        <ModalV1
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          onClose={onClose}
          scrollBehavior={sbp({ mobSm: 'inside', tabSm: 'outside' })}
          {...props}
        >
          <ChakraModalContent
            containerProps={{
              sx: useMemo(() => ({
                p: 0,
                flex: '1 0 0',
                minH: '0',
              }), []),
            }}
            sx={{
              w: '100vw',
              maxW: '100vw',
              m: 0,
              p: '0 !important',
              borderRadius: 0,
              align: 'stretch',
              flex: '1 0 0',
              minH: '0',
              h: 'full',
              maxH: 'full',
            }}
          >
            <VStack
              flex='1 0 0'
              minH='0'
              minW='0'
              h='full'
              spacing='0'
              align='stretch'
            >
              <TitleBar
                leftJsx={(
                  <IconButton
                    aria-label='Back'
                    icon={<ArrowLeftIcon />}
                    variant='icon-ghost'
                    colorScheme='ondark'
                    size='md'
                    onClick={onClose}
                  />
                )}
                title={title}
              />

              <Flex
                direction='column'
                flex='1 0 0'
                minH='0'
                minW='0'
                as={OverlayScrollbarsComponent}
                align='stretch'
                sx={{
                  '& > .os-viewport, & > [data-overlayscrollbars-contents]': {
                    minH: '0',
                    minW: '0',
                    display: 'grid',
                    gridTemplateRows: 'minmax(min-content, 1fr) min-content',
                    gritTemplateColumns: '100vw',
                  },
                }}
              >
                <Card
                  size='xxs-locked'
                  variant={variant}
                  colorScheme={colorScheme}
                  borderRadius='0'
                >

                  <Box
                    minH='0'
                    minW='0'
                    w='100%'
                    flex='1'
                  >
                    {children}
                  </Box>

                  <Box
                    minH='0'
                    minW='0'
                    w='100%'
                  >
                    {footer}
                  </Box>
                </Card>
              </Flex>
            </VStack>
          </ChakraModalContent>
        </ModalV1>
      )}
      mob={(
        <ModalV1
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          onClose={onClose}
          {...props}
        >
          <ModalOverlay />
          <ModalContentV1 maxW={maxW} overflow='visible'>
            <ModalCloseButton onClose={onClose} />

            {children}

            {footer}
          </ModalContentV1>
        </ModalV1>
      )}
    />
  )
}
