import { Data } from 'effect'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListId from 'features/ListBuilder/domain/MarketingList/MarketingListId'
import { merge } from 'remeda'
import { ListCriteria } from '../ListCriteria'

// TODO: add hasSkiptraceData for checking if the list has a downloadable skiptrace list
export type MarketingList = {
  id: MarketingListId
  name: string
  type: ListType
  createdAt: Date
  updatedAt: Date
  memberCount: number
  skiptracedCount: number
  criteria: ListCriteria | null
}

type MarketingListParams =
  & Omit<MarketingList, 'updatedAt' | 'memberCount' | 'skiptracedCount'>
  & Partial<Pick<MarketingList, 'updatedAt' | 'memberCount' | 'skiptracedCount'>>
  & { criteria: ListCriteria | null }

const constructor = Data.case<MarketingList>()

export const MarketingList = {
  make: (params: MarketingListParams): MarketingList => {
    const DEFAULTS: MarketingList = constructor({
      id: params.id,
      name: params.name,
      type: params.type,
      createdAt: new Date(),
      updatedAt: new Date(),
      memberCount: 0,
      skiptracedCount: 0,
      criteria: params.criteria,
    })

    return merge(DEFAULTS, params)
  },
}
