import { Accordion } from '@chakra-ui/react'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { useListMemberWithSkiptrace } from 'features/common/ListMemberWithSkiptrace/infra/useListMemberWithSkiptrace'
import ListAccordionItem from 'presentation/screens/ListScreen/components/ListAccordion/components/ListAccordionItem/ListAccordionItem'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Dispatch, SetStateAction } from 'react'

export type ListAccordionProps = {
  canSelect: boolean
  selections: MarketingListMemberId[]
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
}

const ListAccordion = ({
  canSelect,
  selections,
  setSelections,
}: ListAccordionProps) => {
  const membersApi = useListMemberWithSkiptrace()

  if (membersApi._tag === 'Loading') return null

  const members = membersApi.members
  return (
    <Accordion
      allowToggle
      display='flex'
      flexDirection='column'
      gap={mbp({ mobSm: 1, mob: 2 })}
      p={mbp({ mobSm: 1, mob: 2 })}
      reduceMotion
      bgColor='card.bg.blue'
      borderRadius={2}
      hidden={members.length === 0}
    >
      {members.map(member => (
        <ListAccordionItem
          id={member.id}
          isSelected={selections.includes(member.id)}
          setSelections={setSelections}
          canSelect={canSelect}
          key={member.id}
          member={member}
        />
      ))}
    </Accordion>
  )
}

export default ListAccordion
