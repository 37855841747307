import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import { Dollar } from 'libs/dollar'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { ProductFeatureSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'

type ColumnValue = {
  readonly primaryText: string
  readonly secondaryText: string
}

type DirectMailFeatureRowViewModel = {
  readonly unitDescriptionText: Option.Option<string>
  readonly columnValues: readonly Option.Option<ColumnValue>[]
}

const isDirectMailFeature = (feature: ProductFeatureSpec): boolean =>
  feature.featureName === 'DirectMailFeature'

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const { context } = snapshot
  const vm: DirectMailFeatureRowViewModel = {
    unitDescriptionText: Option.some('Postage included'),
    columnValues: pipe(
      context.products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.map(product => pipe(
        product.features,
        Array.findFirst(isDirectMailFeature),
        Option.flatMap(feature => pipe(
          Match.value(feature),
          Match.tag('FeatureUsagePricingSpec', f => pipe(
            Data.struct<ColumnValue>({
              primaryText: Dollar.formatPennies(f.price),
              secondaryText: `per ${f.unit}`,
            }),
            Option.some,
          )),
          Match.tag('FeatureUnlimitedSpec', () => pipe(
            Data.struct<ColumnValue>({
              primaryText: 'Unlimited',
              secondaryText: `per month`,
            }),
            Option.some,
          )),
          Match.orElse(() => Option.none<ColumnValue>()),
        )),
      )),
      Data.array,
    ),
  }

  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const DirectMailFeatureRowViewModel = {
  usePresenter,
}

export default DirectMailFeatureRowViewModel
