import { CMA } from 'features/CMA/CMA.domain'
import { mapFiltersToLegacy } from 'features/CMA/infra/remote/filterComps/filterComps.mapper'
import { getPropertyCmaInfo } from 'features/CMA/infra/remote/loadCma/getPropertyCmaInfo'
import { mapCMA } from 'features/CMA/infra/remote/loadCma/loadCMA.mapper'
import { getProperty } from 'features/PropertyDetails/infra/remote/getProperty/getProperty'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'

export const filterComps = async (payload: CMA.FilterCompsPayload): Promise<CMA.FilterCompsResult> => {
  // clear loadCMA cache
  ServerCache.clearType('loadCMA')

  // map filter
  const legacyPayload = mapFiltersToLegacy(payload.filters)

  const leadId = payload.leadId
  const reportId = payload.reportId

  // prepare REST call for filtering
  const filterRestPromise = restClient.post(
    `legacy/cma/search/${leadId}/${reportId}`,
    { json: legacyPayload },
  ).json()

  // prepare call for property
  const propertyPromise = getProperty({ leadId })

  const [restResponse, property] = await Promise.all([
    filterRestPromise,
    propertyPromise,
  ])

  if ((restResponse as any)?.error?.type === 'mls_coverage_error')
    throw new Error('CMA being filtered has no MLS coverage')

  // If we get InsufficientConsumables, throw an error
  if (property.status === 'insufficient-consumables')
    throw new Error('Insufficient consumables to get property details')


  const gqlResponse = property.status === 'with-details'
    ? await getPropertyCmaInfo(property.parcelId)
    : null

  const report = mapCMA({
    rest: restResponse,
    gql: gqlResponse,
    property,
  })

  if (!report) throw new Error('Could not map CMA')

  // update loadCMA cache
  const loadCmaCacheReplacement = {
    status: 'success',
    report,
  };

  (loadCmaCacheReplacement as any)._original = {
    rest: restResponse,
    gql: gqlResponse,
    property,
  }

  ServerCache.set('loadCMA')(leadId)(loadCmaCacheReplacement)

  return report
}
