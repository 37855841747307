import { Box, Flex, Text } from '@chakra-ui/react'
import { DrawerPanelMetaBarProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistoryDrawerPanel/components/DrawerPanelMetaBar/DrawerPanelMetaBar.props'
import { useSearchHistoryMetaProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/useSearchHistoryMetaProps'

const DEFAULT_BG_COLOR = 'neutral.500'
const DEFAULT_COLOR = 'ondark.1'

export const DrawerPanelMetaBar = (props: DrawerPanelMetaBarProps) => {
  const {
    bgColor,
    color,
    metaInfo,
    icon: MetaIcon,
    isDisabled,
  } = useSearchHistoryMetaProps()

  const areUsingDefaultColors = bgColor === DEFAULT_BG_COLOR && color === DEFAULT_COLOR

  const showIcon = !areUsingDefaultColors && !isDisabled

  return (
    <Box
      display={isDisabled ? 'none' : 'block'}
      bgColor={areUsingDefaultColors ? 'accent.hover' : bgColor}
      color={areUsingDefaultColors ? 'grayweak.900' : color}
      px={2}
      py={1}
      {...props}
    >
      <Flex
        justifyContent='flex-start'
        alignItems='center'
        gap={1}
        h={5}
      >
        {showIcon && (
          <MetaIcon
            boxSize={3}
            color='inherit'
          />
        )}
        <Text
          flexGrow={1}
          textAlign='left'
          color='inherit'
          textStyle='bodyM'
        >
          {metaInfo}
        </Text>
      </Flex>
    </Box>
  )
}
