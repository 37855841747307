import { Box, IconButton, IconButtonProps } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { SliderLogic } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/Slider.logic'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { MouseEventHandler } from 'react'
import { Swiper as SwiperComponent, SwiperProps } from 'swiper/react'

export const Slider = ({ children, ...props }: SwiperProps) => {
  const { setCurrentSlide, setSwiper } = SliderLogic.useContext()

  return (
    <SwiperComponent
      className='slider'
      onSlideChange={({ realIndex }) => setCurrentSlide(realIndex)}
      onSwiper={swiper => setSwiper(swiper)}
      {...props}
    >
      {children}
    </SwiperComponent>
  )
}

export const SliderButton = (props: IconButtonProps) => (
  <IconButton
    variant='icon-solid'
    colorScheme='ondark'
    size={mbp({ mobSm: 'md', mob: 'lg' })}
    {...props}
  />
)

export const SliderNav = () => {
  const { swiper, photos } = SliderLogic.useContext()

  if (photos.length <= 1) return null

  const handlePrev: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    swiper?.slidePrev()
  }

  const handleNext: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    swiper?.slideNext()
  }

  return (
    <>
      <SliderButton
        className='slider__button-prev'
        aria-label='Previous'
        icon={<ChevronLeftIcon />}
        pos='absolute'
        top='50%'
        left={mbp({ mobSm: '1.5', mob: '2.5' })}
        zIndex='1'
        transform='translateY(-50%)'
        onClick={handlePrev}
      />

      <SliderButton
        className='slider__button-next'
        aria-label='Next'
        icon={<ChevronRightIcon />}
        pos='absolute'
        top='50%'
        right={mbp({ mobSm: '1.5', mob: '2.5' })}
        zIndex='1'
        transform='translateY(-50%)'
        onClick={handleNext}
      />
    </>
  )
}

export const SliderIndicator = () => {
  const { currentSlide, photos } = SliderLogic.useContext()

  return (
    <Box
      className='slider__indicator'
      borderWidth='0.125'
      textStyle={mbp({ mobSm: 'bodyMFat', mob: 'tagL' })}
      pos='absolute'
      bottom={1}
      left='50%'
      transform='translateX(-50%)'
      px={mbp({ mobSm: 1, mob: 2 })}
      py={0.5}
      zIndex='1'
      borderRadius='2'
      borderColor='grayweak.500'
      bg='grayweak.200'
      color='graystrong.500'
    >
      {currentSlide + 1}
      /
      {photos.length}
    </Box>
  )
}
