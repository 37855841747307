import { createIcon } from '@chakra-ui/react'

export const PhoneOffIcon = createIcon({
  displayName: 'PhoneOffIcon',
  viewBox: '0 0 16 17',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_536_4791)'>
        <path
          d='M4.00756 9.03998C2.93694 7.30865 2.2685 5.33809 2.05594 3.28665C2.04006 3.10229 2.06095 2.91649 2.11729 2.74107C2.17363 2.56564 2.26418 2.40444 2.38318 2.26773C2.50218 2.13102 2.64702 2.02179 2.80849 1.947C2.96995 1.87221 3.14449 1.83349 3.321 1.83332H5.22812C5.53663 1.83013 5.83572 1.9447 6.06964 2.15567C6.30356 2.36664 6.45635 2.65961 6.49953 2.97998C6.58003 3.62003 6.72931 4.24847 6.94453 4.85332C7.03006 5.09193 7.04857 5.35126 6.99787 5.60057C6.94717 5.84988 6.82938 6.07872 6.65846 6.25998L5.85111 7.10665M7.49759 9.37332C8.14274 10.0505 8.87188 10.6335 9.66535 11.1066L10.4727 10.26C10.6455 10.0807 10.8638 9.95722 11.1015 9.90405C11.3392 9.85087 11.5865 9.87029 11.814 9.95998C12.3908 10.1857 12.9901 10.3422 13.6004 10.4266C13.9059 10.4719 14.1852 10.6322 14.3864 10.8775C14.5876 11.1228 14.6968 11.4364 14.6938 11.76V13.76C14.6945 13.9456 14.6582 14.1294 14.5873 14.2995C14.5164 14.4697 14.4124 14.6224 14.2819 14.7479C14.1514 14.8734 13.9974 14.969 13.8297 15.0285C13.662 15.0879 13.4843 15.11 13.308 15.0933C11.3518 14.8704 9.47273 14.1694 7.8218 13.0466C7.05595 12.5367 6.34603 11.9397 5.7049 11.2666L7.49759 9.37332Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M15.3293 1.16669L1.34375 15.8334' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_536_4791'>
          <rect width='15.257' height='16' fill='white' transform='translate(0.708008 0.5)' />
        </clipPath>
      </defs>
    </svg>
  ),
})
