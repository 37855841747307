import { Grid, VStack } from '@chakra-ui/react'
import { Array } from 'effect'
import LiveSupportFeatureRowViewModel from './LiveSupportFeatureRowViewModel'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'

const LiveSupportFeatureRow = () => {
  const vm = LiveSupportFeatureRowViewModel.usePresenter()

  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
      borderBottomRadius='6.5'
    >
      <VStack
        py='3'
        px='4'
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Outstanding Live Chat Support
        </PrimaryText>
        <SecondaryText>
          Our support experts have hundreds of rave reviews. Experience the Propelio Support difference.
        </SecondaryText>
      </VStack>
      {Array.map(vm, (value, i) => (
        <FeatureAvailability key={i} isAvailable={value} />
      ))}
    </Grid>
  )
}

export default LiveSupportFeatureRow
