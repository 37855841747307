import { Schema as S } from '@effect/schema'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'

const MarketingListSchema = S.Struct({
  id: S.String,
  name: S.String,
  createdAt: S.Date,
  updatedAt: S.NullishOr(S.Date),
  type: ListTypeSchema.schema,
  memberStats: S.Struct({
    total: S.Number,
    skiptraced: S.NullishOr(S.Number),
  }),
  source: S.NullishOr(S.Struct({
    payload: CriteriaSchema.schema,
  })),
})

type MarketingListSchema = S.Schema.Type<typeof MarketingListSchema>

export default MarketingListSchema
