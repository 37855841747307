import { createIcon } from '@chakra-ui/react'

export const TagIcon = createIcon({
  displayName: 'TagIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
    >
      <path
        d='M19.1596 13.1743L13.1846 19.1493C13.0298 19.3043 12.846 19.4272 12.6437 19.5111C12.4414 19.595 12.2245 19.6382 12.0055 19.6382C11.7864 19.6382 11.5696 19.595 11.3672 19.5111C11.1649 19.4272 10.9811 19.3043 10.8263 19.1493L3.66797 11.9993V3.66602H12.0013L19.1596 10.8243C19.4701 11.1366 19.6443 11.559 19.6443 11.9993C19.6443 12.4397 19.4701 12.8621 19.1596 13.1743V13.1743Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.83203 7.83398H7.84036'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
