import { createStylesContext, Flex, FlexProps, ResponsiveValue, Text, TextProps, useMultiStyleConfig } from '@chakra-ui/react'
import { PairColorScheme, PairSize, PairVariant } from 'presentation/components/Pair/pair.theme'
import { FC } from 'react'

const [StylesProvider, useStyles] = createStylesContext('Pair')

type PairProps = FlexProps & {
  size?: ResponsiveValue<PairSize>
  variant?: PairVariant
  colorScheme?: PairColorScheme
}

export const Pair: FC<PairProps> = ({
  children,
  size,
  variant,
  colorScheme,
  ...stackProps
}) => {
  const styles = useMultiStyleConfig('Pair', { size, variant, colorScheme })
  return (
    <Flex sx={styles.pairContainer} {...stackProps}>
      <StylesProvider value={styles}>
        {children}
      </StylesProvider>
    </Flex>
  )
}

export const PairKey: FC<TextProps> = ({
  children,
  ...textProps
}) => {
  const styles = useStyles()
  return (
    <Text sx={styles.pairKey} {...textProps}>{children}</Text>
  )
}

export const PairValue: FC<TextProps> = ({
  children,
  ...textProps
}) => {
  const styles = useStyles()
  return (
    <Text sx={styles.pairValue} {...textProps}>
      {children}
    </Text>
  )
}
