import { Schema as S } from '@effect/schema'

export const POSTFetchBuildTotalSchema = S.Struct({
  stats: S.Struct({
    count: S.NullishOr(S.NumberFromString),
    estimate: S.OptionFromNullishOr(S.Number, undefined),
  }),
})

export type POSTFetchBuildTotalSchema = S.Schema.Type<typeof POSTFetchBuildTotalSchema>
