import { Request } from 'effect'
import { ListMemberNotFoundError, ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import MarketingListMemberRepo from 'features/ListBuilder/domain/repository/MarketingListMemberRepo'

namespace MarketingListMemberRequest {

  export class GetByPage extends Request.TaggedClass('MarketingListMember/GetByPage')<
    MarketingListMemberRepo.GetByPageResult,
    ListNotFoundError,
    {
      listId: MarketingListId
      page: number
    }
  > {}

  export class DeleteById extends Request.TaggedClass('MarketingListMember/GetByPage')<
    void,
    ListMemberNotFoundError,
    {
      listId: MarketingListId
      memberId: MarketingListMemberId
    }
  > {}

}

export default MarketingListMemberRequest
