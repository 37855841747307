import { createIcon } from '@chakra-ui/react'

export const ExclamationCircleIcon = createIcon({
  displayName: 'ExclamationCircleIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d='M7.9987 14.6663C11.6806 14.6663 14.6654 11.6816 14.6654 7.99967C14.6654 4.31778 11.6806 1.33301 7.9987 1.33301C4.3168 1.33301 1.33203 4.31778 1.33203 7.99967C1.33203 11.6816 4.3168 14.6663 7.9987 14.6663Z' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 5.33301V7.99967' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 10.667H8.00667' stroke='currentColor' fill='none' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
