import { gql } from '__generated__'
import { PlanRank_ProductFragment } from '__generated__/graphql'
import { isNumber } from 'lodash/fp'

export const PLAN_RANK__PRODUCT_FRAGMENT = gql(/* GraphQL */ `
  fragment PlanRank_Product on BillingSubscriptionProduct {
    id
    plans {
      id
      price
      interval {
        unit
      }
    }
  }
`)

export type PlanRank = 'free' | 'beginner' | 'intermediate' | 'advanced' | 'fallback'

export const getPlanRank = (
  fragment: PlanRank_ProductFragment[],
  planId: string,
): PlanRank => {
  const products = fragment

  const ranked = products
    // Simplify to id and price
    .map(product => {
      const price = product.plans.find(plan => plan.interval.unit === 'MONTH')?.price
      const planIds = product.plans.map(plan => plan.id)

      if (!isNumber(price)) throw new Error(`No monthly price found for product ${product.id}`)

      return { planIds, price }
    })
    // Sort by price
    .sort((a, b) => a.price - b.price)

  const rank = ranked.findIndex(product => product.planIds.includes(planId))

  if (rank === 0) return 'free'
  if (rank === 1) return 'beginner'
  if (rank === 2) return 'intermediate'
  if (rank === 3) return 'advanced'

  return 'fallback'
}
