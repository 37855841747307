import { NeverUndefinedProps } from 'libs/utils.types'
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react'

/**
 * Creates context provider and hook with less code. Please see example for usage.
 *
 * The returned provider accepts the properties of context type as props. Context
 * value is memoed and only updates when one of the properties change (strict equality)
 *
 * @NOTE It requires that the context value is an object that with NO undefined property.
 *   Nulls are allowed
 *
 * @example
 * type ContextType = { foo: string }
 * const [Provider, useContext] = setupContext<ContextType>('FooDisplayName', {
 *   foo: 'bar',
 * })
 * ...
 * <Provider foo='baz'>
 * ...
 * const { foo } = useTdContainerContext()
 */
export const setupContext = <ContextType extends Record<string, unknown>>(
  displayName: string,
  initData: NeverUndefinedProps<ContextType>,
) => {
  const ctx = createContext<NeverUndefinedProps<ContextType>>(initData)

  const Provider: FC<PropsWithChildren<NeverUndefinedProps<ContextType>>> = props => {
    const { children, ...data } = props

    /**
     * This memoization makes it so that the context value is only updated when
     * one of the properties change (strict equality)
     */
    const value = useMemo(
      () => data as NeverUndefinedProps<ContextType>,

      /**
       * @IMPORTANT This can break if you allow undefined in any of context value properties
       *   that's why discouraged that via typescript
       */
      Object.keys(data).sort().map(key => data[key]),
    )

    return (
      <ctx.Provider value={value}>
        {children}
      </ctx.Provider>
    )
  }

  Provider.displayName = `${displayName}Provider`

  return [
    Provider,
    () => useContext(ctx),
  ] as const
}
