import { Box, Flex, Menu, MenuButton, MenuItem } from '@chakra-ui/react'
import classNames from 'classnames'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { SelectMenuButtonProps, SelectMenuProps } from 'presentation/components/SelectMenu/SelectMenu.props'

export const SelectMenu = (props: SelectMenuProps) => (
  <Menu
    variant='select'
    matchWidth
    offset={[0, 0]}
  >
    {props.children}
  </Menu>
)

export const SelectMenuButton = (props: SelectMenuButtonProps) => {
  const { children, isDisabled, ...menuButtonProps } = props
  return (
    <MenuButton
      className={classNames({ disabled: isDisabled })}
      {...menuButtonProps}
    >
      <Flex>
        <Box className='select-menu-button__value'>
          {children}
        </Box>
        <ChevronDownIcon className='select-menu-button__icon' />
      </Flex>
    </MenuButton>
  )
}

export const SelectMenuList = ResponsiveMenuList

export const SelectMenuItem = MenuItem
