import { chakra } from '@chakra-ui/react'
import { isValidMotionProp, motion } from 'framer-motion'

/**
 * @see https://chakra-ui.com/guides/integrations/with-framer
 * @see https://www.framer.com/docs/
 * @TODO Why the heck is the comment docs here not appearing on Storybook??
 */
export const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children',
})
