import { Settings } from 'features/Settings/domain/Settings.domain'
import { getLocallyStoredSettings } from 'features/Settings/infra/remote/getLocallyStoredSettings'
import { DEFAULT_COMPS_SORT } from 'features/Settings/valueObjects/CompsSort'
import { DeepPartialN } from 'libs/utils.types'
import { restClient } from 'presentation/libs/client'

export const getSettings = async () => await restClient.get('users/v2/me/settings')
  .json<any>()
  .then(rawSettingsUntyped => {
    const rawSettings = rawSettingsUntyped as undefined | null | DeepPartialN<Settings>
    const rawTheme = rawSettings?.theme
    const rawLotAreaSqftUnit = rawSettings?.features?.cma?.filters?.lotAreaSqft?.unit
    const locallySavedSettings = getLocallyStoredSettings()

    return {
      theme: rawTheme === 'light'
        ? 'light'
        : rawTheme === 'dark'
          ? 'dark'
          : locallySavedSettings.theme,
      features: {
        cma: {
          filters: {
            lotAreaSqft: {
              unit: rawLotAreaSqftUnit === 'sqft'
                ? 'sqft'
                : rawLotAreaSqftUnit === 'acres'
                  ? 'acres'
                  : null,
            },
          },
          show: {
            shouldShowThumbsDown: !!rawSettings?.features?.cma?.show?.shouldShowThumbsDown,
          },
          sort: {
            shouldGroupByStatus: !!rawSettings?.features?.cma?.sort?.shouldGroupByStatus,
            sortBy: rawSettings?.features?.cma?.sort?.sortBy || DEFAULT_COMPS_SORT,
          },
          view: rawSettings?.features?.cma?.view || null,
          isPinTrainingDone: !!rawSettings?.features?.cma?.isPinTrainingDone,
        },
        searchHistory: {
          isPinTrainingDone: !!rawSettings?.features?.searchHistory?.isPinTrainingDone,
        },
        general: {
          lastShownWelcomeModalVersion: rawSettings?.features?.general?.lastShownWelcomeModalVersion || null,
        },
        sites: {
          survey: {
            isWebsitesEssential: rawSettings?.features?.sites?.survey?.isWebsitesEssential ?? null,
            isThirdPartyIntegrationEnough: rawSettings?.features?.sites?.survey?.isThirdPartyIntegrationEnough ?? null,
          },
        },
      },
    } satisfies Settings
  })
