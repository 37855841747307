import { Schema as S } from '@effect/schema'
import { Effect, Option, RequestResolver, pipe } from 'effect'
import { POSTFetchBuildTotalSchema } from 'features/ListBuilder/infra/repo/ListSourceRepoLive/GetBuildTotalResolver/POSTFetchBuildTotalSchema'
import ListSourceRequest from 'features/ListBuilder/infra/repo/ListSourceRepoLive/ListSourceRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { restClient } from 'presentation/libs/client'

const GetBuildTotalResolver = RequestResolver.fromEffect(
  (request: ListSourceRequest.GetBuildTotal) => pipe(
    Effect.sync(() => CriteriaSchema.encode(request.criteria)),
    Effect.flatMap(payload =>
      Effect.promise(async () => await restClient
        .post('parcels/v1/listbuilder/seller/count', { json: payload })
        .json(),
      ),
    ),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => decoded.stats.count
    ?? Option.getOrElse(() => 0)(decoded.stats.estimate),
    ),

  ),
)

export default GetBuildTotalResolver

const decode = (response: unknown) =>
  S.decodeUnknownSync(POSTFetchBuildTotalSchema)(response)
