import { Button, Flex, FlexProps, Link, Text } from '@chakra-ui/react'
import { OLD_PROPELIO_URL } from 'presentation/const/env.const'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const ListBuilderComingSoonOverlay = (props: FlexProps) => {
  const legayLink = `${OLD_PROPELIO_URL}/leadlists`
  return (
    <Flex
      h='full'
      minH={0}
      bgColor='modal.blur'
      justifyContent='center'
      alignItems='center'
      p={1}
      {...props}
    >
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        gap={mbp({ mobSm: 2, tabSm: 3 })}
        w={mbp({ mob: '400px', tabSm: '568px', dtSm: '800px', dtLg: '912px' })}
      >

        <Text
          textStyle={mbp({ mobSm: 'h4', mob: 'h2', tabSm: 'display4', dtLg: 'display3' })}
          color='ondark.1'
          textAlign='center'
        >
          List Builder Is Coming Soon To Mobile and Tablet Devices!
        </Text>
        <Text
          textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat', tabSm: 'h4', dtLg: 'h3' })}
          color='ondark.1'
          textAlign='center'
        >
          Prepare for the Launch!
          We&rsquo;re still polishing things and ensuring everything is ready for the
          release of mobile and tablet version of List Builder coming your way soon.
        </Text>

        <Text
          textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat', tabSm: 'h4', dtLg: 'h3' })}
          color='ondark.1'
          textAlign='center'
        >
          While awaiting the new version, you can still access the legacy version for continued use.
        </Text>
        <Flex gap={2}>
          <Button
            as={Link}
            variant='solid'
            colorScheme='highlight'
            size={mbp({ mobSm: 'md', mob: 'lg' })}
            href={legayLink}
            isExternal
          >
            Visit Legacy
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
