import { NetworkStatus, useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { PaymentsTable_GetInvoicesQuery } from '__generated__/graphql'
import { last, noop } from 'lodash/fp'
import { toast } from 'presentation/components/Toast'
import { GqlPath } from 'presentation/libs/graphql'
import { useEffect } from 'react'

export const usePaymentsTable = (): {
  isReady: boolean
  invoices: Invoice[]
  activeSubscription: ActiveSubscription | null
  canFetchMore: boolean
  fetchMore: () => void
  isFetchingMore: boolean
} => {
  const { data, error, networkStatus, ...query } = useQuery(PAYMENTS_TABLE__GET_INVOICES, {
    notifyOnNetworkStatusChange: true,
  })

  const invoiceEdges = data?.myEnterprise?.invoices?.edges
  const invoicesRaw: Invoice[] = invoiceEdges?.map(edge => edge?.node)
    .filter((n): n is Invoice => n !== undefined) || []

  const subscription = data?.myEnterprise?.subscription
  const activeSubscription = subscription?.__typename === 'BillingSubscriptionActive' ? subscription : null

  const invoices: Invoice[] = [
    ...invoicesRaw,
  ]

  const dataHasNextPage = data?.myEnterprise?.invoices?.pageInfo.hasNextPage
  const lastCursor = (invoiceEdges && last(invoiceEdges)?.cursor) || null
  const canFetchMore = !!dataHasNextPage && !!lastCursor

  const fetchMore = (): void => {
    query.fetchMore({
      variables: {
        cursor: lastCursor,
      },
    })
      .catch(noop) // error is handled from hook
  }

  // React to errors
  useEffect(() => {
    if (!error) return

    toast({
      id: `useCardTransactons${error.message}`,
      status: 'error',
      title: 'We couldn’t load your payment history.',
      message: error.message,
    })
  }, [error])

  return {
    isReady: !!data,
    invoices,
    activeSubscription,
    canFetchMore,
    fetchMore,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
  }
}

export const PAYMENTS_TABLE__GET_INVOICES = gql(/* GraphQL */ `
  query PaymentsTable_GetInvoices($cursor: String) {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionInactive {
          id
        }

        ...on BillingSubscriptionActive {
          id
          pastDueInvoice {
            __typename
            ...PaymentRow__InvoiceFragment
          }

          ...FailedInvoiceFlow_FailedInvoiceSummary

          upcomingInvoice {
            __typename
          }

          ...UpcomingPaymentRow__UpcomingPayment
        }
      }

      invoices(first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            ...PaymentRow__InvoiceFragment
          }
        }
      }
    }
  }
`)

export type ActiveSubscription = GqlPath<
  PaymentsTable_GetInvoicesQuery,
  [
    ['myEnterprise', 'Enterprise'],
    ['subscription', 'BillingSubscriptionActive'],
  ]
>

type Invoice = GqlPath<
  PaymentsTable_GetInvoicesQuery,
  [
    ['myEnterprise', 'Enterprise'],
    ['invoices', 'BillingInvoiceConnection'],
    ['edges', 'BillingInvoiceEdge'],
    ['node', 'BillingInvoice'],
  ]
>
