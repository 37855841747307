import { Box, BoxProps, Image, Text, VStack } from '@chakra-ui/react'
import { Photo } from 'features/valueObjects/Photo'
import runningDogPng from 'presentation/assets/img/3d/running-dog.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SliderMinimized } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMinimized'
import { TabContainer } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/TabContainer'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const MLSPhotos = ({
  photos,
}: {
  photos: Photo[]
}) => {
  const { sbp } = useSwitchBreakpointFn()

  return (
    <TabContainer
      display='flex'
      flexDirection='column'
      alignItems='stretch'
      justifyContent='center'
      bg={photos.length === 0 ? 'neutral.100' : '#1B1C1E'}
      borderRadius='3'
    >
      <Box w='full' h={sbp(TabContainer.RESPONSIVE_HEIGHT)}>
        {photos.length === 0
          ? <NoPhoto />
          : <SliderMinimized photos={photos} h={sbp(TabContainer.RESPONSIVE_HEIGHT)} />}
      </Box>
    </TabContainer>
  )
}

export const NoPhoto = (props: BoxProps) => (
  <VStack
    bg='neutral.100'
    h='full'
    justifyContent='center'
    alignItems='center'
    px={3}
    spacing='1'
    {...props}
  >
    <Box>
      <Image
        src={runningDogPng}
        boxSize={mbp({ mob: '104px', tabSm: '120px', dtLg: '160px' })}
        display={mbp({ mobSm: 'none', mob: 'block' })}
        mr='auto'
        ml='auto'
      />

      <Text
        textStyle={mbp({ mobSm: 'bodyXLFat', mob: 'h4', tabSm: 'h3', dtLg: 'h2' })}
        color='graystrong.500'
        textAlign='center'
      >
        <Breakpoint
          mobSm='No photos from MLS'
          mob={<>No photos available from&nbsp;MLS</>}
        />
      </Text>

      <Text
        textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })}
        color='graystrong.200'
        textAlign='center'
      >
        Apologies, no photos found for this property in MLS database
      </Text>
    </Box>
  </VStack>
)
