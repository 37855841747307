import { Context, Effect } from 'effect'
import { ListMemberNotFoundError, ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMember } from 'features/ListBuilder/domain/MarketingListMember'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'

const Tag = Context.Tag('MarketingListMemberRepo')

class MarketingListMemberRepo extends Tag<MarketingListMemberRepo, {
  getByPage: MarketingListMemberRepo.GetByPage
  deleteByIds: MarketingListMemberRepo.DeleteByIds
}>() {}

declare namespace MarketingListMemberRepo {

  export type GetByPage = (
    listId: MarketingListId,
    page: number
  ) => Effect.Effect<GetByPageResult, ListNotFoundError>

  export type GetByPageResult = {
    members: MarketingListMember[]
    currentPage: number
    countPerPage: number
    totalCount: number
  }

  export type AddToListsResult = {
    savedToIds: MarketingListId[]
  }

  export type RemoveFromListsResult = {
    removeFromIds: MarketingListId[]
  }

  export type DeleteByIds = (
    listId: MarketingListId,
    memberIds: MarketingListMemberId[]
  ) => Effect.Effect<DeleteByIdsResult, ListMemberNotFoundError>

  export type DeleteByIdsResult = {
    listId: MarketingListId
    successfulIds: MarketingListMemberId[]
    failedIds: MarketingListMemberId[]
  }

}

export default MarketingListMemberRepo
