import { Box, Flex, Text, chakra } from '@chakra-ui/react'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { SHADOWS } from 'presentation/main/themes/common/shadows.theme'
import { CompsSinglePointMarkerProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMarker/CompsSinglePointMarker.props'

export const CompsSinglePointMarker = (props: CompsSinglePointMarkerProps) => (
  <Flex
    flexDirection='column'
    alignItems='center'
    position='relative'
  >
    {/**
       * @HACK This will prevent the other markers that happens
       * to be in between the gap of the marker and the popup from
       * being hovered.
       */}
    <Box
      position='absolute'
      h='15px'
      top='-15px'
      left={0}
      right={0}
    />
    {/**
       * @HACK
       * This portion without border with bg card is important, so that the
       * ArrowDown can go under it and fix the annoying line-on-top-of-triangle
       * issue
       */}
    <Box
      gap={0.5}
      w='fit-content'
      borderColor='marker.brown'
      borderWidth={0.125}
      borderRadius={1.5}
      boxShadow='float'
    >
      <Flex
        gap={0.5}
        px={1}
        py={0.5}
        minW={6}
        minH={3.5}
        alignItems='center'
        justifyContent='center'
        pos='relative'
        zIndex='1'
        borderRadius={1.5}
        color='marker.brown'
        bgColor='marker.cream'
      >
        <Text color='inherit' textStyle='tagL' textAlign='center'>
          {props.markers.length}
          {' '}
          Listings
        </Text>
      </Flex>
    </Box>
    <ArrowDown />
    {props.hasSubjectProperty && (
      <SubjectPropertyMarker markerType='pin' size='sm' />
    )}
  </Flex>
)

const ArrowDown = () => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    width='20px'
    height='8px'
    viewBox='0 0 20 8'
    fill='none'
    pos='relative'
    top='-3px'
    alignSelf='center'
    filter={`drop-shadow(${SHADOWS.float})`}
  >
    <chakra.path
      d='M8.75061 7.52867C9.48105 8.15711 10.519 8.15711 11.2494 7.52867L20 1.43051e-06H0L8.75061 7.52867Z'
      fill='marker.cream'
    />
    <chakra.path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.1492 1.07545L10.6247 6.68889C10.2595 7.00311 9.74052 7.00311 9.3753 6.68889L2.85078 1.07545H17.1492ZM11.2494 7.52867C10.519 8.15711 9.48105 8.15711 8.75061 7.52867L0 1.43051e-06H20L11.2494 7.52867Z'
      fill='marker.brown'
    />
    <chakra.path
      d='M2.85937 1.07545H17.1523L18.5 0H1.62717L2.85937 1.07545Z'
      fill='marker.cream'
    />
  </chakra.svg>
)
