import { Match, pipe } from 'effect'

type ProductFeatureInterval =
  | 'yearly'
  | 'monthly'
  | 'weekly'
  | 'daily'
  | 'each'

const ProductFeatureInterval = {
  toSingularForm: (interval: ProductFeatureInterval) => pipe(
    Match.value(interval),
    Match.when('yearly', () => 'year'),
    Match.when('monthly', () => 'month'),
    Match.when('weekly', () => 'week'),
    Match.when('daily', () => 'day'),
    Match.when('each', () => 'each'),
    Match.exhaustive,
  ),
}

export default ProductFeatureInterval
