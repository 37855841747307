import dayjs from 'dayjs'

const formatDate = (value: Date) => {
  const date = dayjs(value)
  const time = date.format('h:mm A')
  /**
   * @TODO currently this is an offset ie: -06:00
   * but we need to convert it to a timezone abbreviation ie: CST
   * */
  const timeZoneOffset = date.format('Z')
  const dateMDYYYY = date.format('M/D/YYYY')
  return `${time} ${timeZoneOffset} | ${dateMDYYYY}`
}

export const membersScreenUtils = {
  formatDate,
}
