import { createIcon } from '@chakra-ui/react'

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 16 16',
  path: (
    <g
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 5.9987L8 1.33203L14 5.9987V13.332C14 13.6857 13.8595 14.0248 13.6095 14.2748C13.3594 14.5249 13.0203 14.6654 12.6667 14.6654H3.33333C2.97971 14.6654 2.64057 14.5249 2.39052 14.2748C2.14048 14.0248 2 13.6857 2 13.332V5.9987Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 14.6667V8H10V14.6667'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
