import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'

type UserAccessFeatureRowViewModel = readonly boolean[]

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const vm: UserAccessFeatureRowViewModel = pipe(
    snapshot.context.products,
    /**
     * Make sure we only show the product if the plan is available
     */
    Array.filter(product => pipe(
      product.plans,
      Array.some(plan =>
        plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
    )),
    Array.map(product => pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'UserAccess'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureAvailableSpec', () => Option.some(true)),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => false),
    )),
    Data.array,
  )
  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const UserAccessFeatureRowViewModel = {
  usePresenter,
}

export default UserAccessFeatureRowViewModel
