import { Outlet, RouteObject } from 'react-router-dom'

namespace OwnersPropertiesRoutes {
  export const make = ({
    Container,
    OwnersPropertiesScreen,
    OwnerPropertiesMobileScreen,
  }: {
    Container: React.ComponentType<{ children: React.ReactNode }>
    OwnersPropertiesScreen: React.ComponentType
    OwnerPropertiesMobileScreen: React.ComponentType
  }): RouteObject[] => [{
    path: 'owners-properties',
    element: (
      <Container>
        <Outlet />
      </Container>
    ),
    children: [
      {
        index: true,
        element: <OwnersPropertiesScreen />,
      },
      {
        path: 'owner/:ownerId/deals',
        element: <OwnerPropertiesMobileScreen />,
      },
    ],
  }]

  export const makeOwnersPropertiesPath = (leadId: string) =>
    `/search/${leadId}/owners-properties`
  export const makeOwnerPropertiesPath = (leadId: string, ownerId: string) =>
    `/search/${leadId}/owners-properties/owner/${ownerId}/deals`
}

export default OwnersPropertiesRoutes
