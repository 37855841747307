import { Array, Brand, Data } from 'effect'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'

export type PlanId = string & Brand.Brand<'PlanId'>

export const PlanId = {
  fromString: Brand.nominal<PlanId>(),
}

export type YearlyPlan = {
  readonly _tag: 'YearlyPlan'
  readonly id: PlanId
  readonly productId: ProductId
  readonly price: number
  readonly pricePerMonth: number
  readonly isCurrentPlan: boolean
}

export const YearlyPlan = {
  make: (plan: Omit<YearlyPlan, '_tag' | 'pricePerMonth'>) =>
    Data.tagged<YearlyPlan>('YearlyPlan')({
      id: plan.id,
      productId: plan.productId,
      price: plan.price,
      pricePerMonth: plan.price / 12,
      isCurrentPlan: plan.isCurrentPlan,
    }),
  is: (sp: SubscriptionPlan): sp is YearlyPlan =>
    sp._tag === 'YearlyPlan',
}

export type MonthlyPlan = {
  readonly _tag: 'MonthlyPlan'
  readonly id: PlanId
  readonly productId: ProductId
  readonly price: number
  readonly isCurrentPlan: boolean
}

export const MonthlyPlan = {
  make: Data.tagged<MonthlyPlan>('MonthlyPlan'),
  is: (sp: SubscriptionPlan): sp is MonthlyPlan =>
    sp._tag === 'MonthlyPlan',
}

type SubscriptionPlan =
  | YearlyPlan
  | MonthlyPlan

const SubscriptionPlan = {
  getCurrent: (plans: SubscriptionPlan[]) =>
    Array.findFirst(plans, plan => plan.isCurrentPlan),
  getYearly: (plans: SubscriptionPlan[]) =>
    Array.findFirst(plans, YearlyPlan.is),
  getMonthly: (plans: SubscriptionPlan[]) =>
    Array.findFirst(plans, MonthlyPlan.is),
}

export default SubscriptionPlan
