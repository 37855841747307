import { GetOwnersPropertiesPreviewQuery, ParcelBuyerType } from '__generated__/graphql'
import { Array, Option, Record, pipe } from 'effect'
import { isNotNull } from 'effect/Predicate'
import Buyer from 'features/NearbyBuyers/domain/Buyer'
import { partialDealFromRawDeal } from 'features/NearbyBuyers/repository/GetNearbyBuyers.schema'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import GetOwnersPropertiesPreview from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview'
import { ownerClassificationFromGql } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getPropertyDetails.mapper'
import { Address } from 'features/valueObjects/Address'
import { EquityType } from 'features/valueObjects/EquityType'

namespace GetOwnersPropertiesPreviewSchema {
  export const resultFromGraphQL = (
    raw: GetOwnersPropertiesPreviewQuery,
  ): GetOwnersPropertiesPreview.Result => {
    const rawValuationDetails = raw.parcel?.valuationDetails
    const rawOwnerDetails = raw.parcel?.ownerDetails
    const rawOwnerAddress = rawOwnerDetails?.owners.find(o => o.address)?.address || null

    return GetOwnersPropertiesPreview.Result.Success({
      ownerPreviews: rawOwnerDetails?.owners?.map((rawOwner): OwnersProperties.Owner => {
        const rawBuyerDetails = Option.fromNullable(rawOwner.buyerDetails)

        return {
          id: rawOwner.id,
          name: Option.fromNullable(rawOwner.name).pipe(Option.flatMap(name =>
            Option.fromNullable([
              name.first,
              name.last,
              name.middle,
            ].filter(isNotNull).join(' ') || name.full || null),
          )),
          buyerType: rawBuyerDetails.pipe(
            Option.flatMap(buyerDetails => Option.fromNullable(buyerDetails.buyerTypes)),
            Option.map(types =>
              types.map((type): Buyer.Type => type === ParcelBuyerType.Flipper
                ? 'flipper'
                : 'landlord',
              ),
            ),
          ),
          address: Option.fromNullable(rawOwner.address)
            .pipe(Option.map(address => ({
              line1: address.line1 ?? '',
              city: address.city ?? '',
              postalCode: address.postalCode ?? '',
              state: address.state ?? '',
            }))),

          averageDealAmount: rawBuyerDetails.pipe(
            Option.flatMap(history => Option.fromNullable(history.stats.average)),
          ),
          totalDealAmount: rawBuyerDetails.pipe(
            Option.flatMap(history => Option.fromNullable(history.stats.total)),
          ),
          totalDealsCount: rawBuyerDetails.pipe(
            Option.flatMap(history => Option.fromNullable(history.stats.count)),
          ),
          dealAmountRange: rawBuyerDetails.pipe(
            Option.flatMap(history => {
              const minDealAmount = Option.fromNullable(history.stats.min)
              const maxDealAmount = Option.fromNullable(history.stats.max)

              if (Option.isSome(minDealAmount) && Option.isSome(maxDealAmount))
                return Option.some([minDealAmount.value, maxDealAmount.value])

              if (Option.isSome(minDealAmount))
                return Option.some([minDealAmount.value, minDealAmount.value])

              if (Option.isSome(maxDealAmount))
                return Option.some([maxDealAmount.value, maxDealAmount.value])

              return Option.none()
            }),
          ),
          // @TODO
          dealsGeographicInfo: {
            counties: rawBuyerDetails.pipe(
              Option.map(details => details.stats.counties),
              Option.map(Array.map((county): Buyer.CountyInfo => ({
                county: county.name,
                state: county.state,
              }))),
              Option.getOrElse(() => Array.empty()),
            ),
            countiesByState: rawBuyerDetails.pipe(
              Option.map(details => details.stats.counties),
              Option.map(Array.groupBy(county => county.state)),
              Option.map(grouped => pipe(
                grouped,
                Record.map(Array.map(county => county.name)),
              )),
              Option.getOrElse(() => Record.empty()),
            ),
            states: rawBuyerDetails.pipe(
              Option.map(details => details.stats.counties),
              Option.map(Array.groupBy(county => county.state)),
              Option.map(Record.keys),
              Option.getOrElse(() => Array.empty()),
            ),
          },
          lastDeal: Option.fromNullable(
            rawOwner.buyerDetails?.historicalDeals.edges[0]?.node,
          ).pipe(
            Option.map(partialDealFromRawDeal),
          ),
          propertiesCount: rawBuyerDetails.pipe(
            Option.flatMap(history =>
              Option.fromNullable(history.historicalDeals.pageInfo.totalCount),
            ),
          ),
        }
      }) || [],
      ownershipInfo: {
        address: Address.toNullIfEmpty({
          line1: rawOwnerAddress?.line1 || '',
          city: rawOwnerAddress?.city || '',
          state: rawOwnerAddress?.state || '',
          postalCode: rawOwnerAddress?.postalCode || '',
        }),
        classification: ownerClassificationFromGql(
          rawOwnerDetails?.ownerClassification || null,
          rawOwnerDetails?.ownerStatus || null,
        ),
        months: rawOwnerDetails?.ownershipMonths || null,
        isVacant: rawOwnerDetails?.isVacant || false,
        isForeclosure: rawOwnerDetails?.isForeclosure || false,
        isSenior: rawOwnerDetails?.isSenior || false,
        potentialPropertiesCount: pipe(
          rawOwnerDetails?.owners ?? [],
          Array.map(owner =>
            Option.fromNullable(owner.buyerDetails?.historicalDeals.pageInfo.totalCount),
          ),
          Option.all,
          Option.map(counts => Array.reduce(counts, counts[0], Math.max)),
        ),
        equityType: Option.fromNullable(rawValuationDetails?.loanToValue).pipe(
          Option.map(EquityType.fromLtv),
        ),
      },
    })
  }
}

export default GetOwnersPropertiesPreviewSchema
