export const SHADOWS = {
  'primary.s': '30px 30px 30px rgba(0, 0, 0, 0.1)',
  'primary.m': '0px 10px 10px rgba(0, 0, 0, 0.1)',

  'primary.w': '0px 10px 20px rgba(0, 0, 0, 0.1)',

  'tabs.first': '4px 4px 10px rgba(0, 0, 0, 0.3)',
  'tabs.notfirst': '0px 4px 10px rgba(0, 0, 0, 0.3)',
  'bottom-shadow': '0px 4px 10px rgba(0, 0, 0, 0.3)',

  'depth': 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
  /** Same as button-hovered */
  'float': '0px 4px 4px rgba(0, 0, 0, 0.25)',
  'high-float': '30px 30px 30px rgba(0, 0, 0, 0.25)',

  'hscroll-sticky': '4px 0px 10px rgba(0, 0, 0, 0.25)',
  'vscroll-sticky': '0px 4px 10px rgba(0, 0, 0, 0.25)',
  'vscroll-fade-bottom': '0px -4px 10px 0px rgba(0, 0, 0, 0.1)',

  'top-sticky': '0px 4px 10px rgba(0, 0, 0, 0.25)',
  'right-sticky': '-4px 0px 10px rgba(0, 0, 0, 0.25)',

  'button-hovered': '0px 4px 4px rgba(0, 0, 0, 0.25)',
  'button-selected': 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
}
