import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { StyleFunctionProps, createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react'
import { mergeDeep } from 'remeda'
import { getRecKey } from 'utils/getRecKey'
const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  popper: {
    zIndex: 'popover',
  },
})

const $popperBg = cssVar('popper-bg')
const $arrowShadowColor = cssVar('popper-arrow-shadow-color')

const defaultVariant = definePartsStyle({
  content: {
    borderWidth: 0.125,
    borderRadius: 2,
    shadow: 'primary.s',
  },
  body: {
    px: 2,
    py: 3,
  },
})

export const popoverColorSchemes = {
  neutral: (props: StyleFunctionProps) => definePartsStyle({
    content: {
      borderColor: 'grayweak.500',
      bgColor: 'accent.hover',
      color: 'graystrong.400',
      [$popperBg.variable]: props.theme.colors['accent.hover'] || $popperBg.reference,
      [$arrowShadowColor.variable]: props.theme.colors['grayweak.500'] || $arrowShadowColor.reference,
    },
  }),
  highlight: (props: StyleFunctionProps) => definePartsStyle({
    content: {
      borderColor: 'grayweak.500',
      bgColor: 'highlight.100',
      color: 'graystrong.400',
      [$popperBg.variable]: props.theme.colors['highlight.100'] || $popperBg.reference,
      [$arrowShadowColor.variable]: props.theme.colors['grayweak.500'] || $arrowShadowColor.reference,
    },
  }),
}

export const variants = {
  default: (props: StyleFunctionProps) => {
    const result = getRecKey(props.colorScheme)(popoverColorSchemes)
    const style = (typeof result === 'function')
      ? result(props)
      : result
    return mergeDeep(
      style || {},
      defaultVariant,
    )
  },
}

export const popoverTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'default',
    colorScheme: 'neutral',
  },
})
