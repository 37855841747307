import { ReactElement } from 'react'

export class ErrorForModal extends Error {
  title?: string
  actionButtons?: () => ReactElement

  constructor({ title, message, actionButtons }: {
    title?: string
    message: string
    actionButtons?: () => ReactElement
  }) {
    super(message)

    this.title = title
    this.actionButtons = actionButtons

    Object.setPrototypeOf(this, ErrorForModal.prototype)
  }
}
