import { createIcon } from '@chakra-ui/react'

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 25 24',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M19.6072 12H5.60718' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.6072 19L5.60718 12L12.6072 5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
