import { Match as M, pipe } from 'effect'
import { CQ } from 'libs/commandQuery/commandQuery'
import { ErrorLib, PartialError, ProgramException, ReportableException } from 'libs/errors'
import { GENERIC_ERROR } from 'libs/errors/errors.const'
import { WithoutId } from 'libs/id'
import { TypeLib } from 'libs/type'
import pluralize from 'pluralize'
import { toast } from 'presentation/components/Toast'
import { useRunOnceCallback } from 'presentation/hooks/useRunOnceCallback'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { InviteMembersPayload, MembersScreenUseCases, UpdateMemberPayload } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { PartialInviteMembersError } from 'presentation/screens/MembersScreen/MembersScreen.errors'
import { CanDisableMemberCapabilityViewModel, CanEnableMemberCapabilityViewModel, CanResendInviteMemberCapabilityViewModel, CanUpdateMemberCapabilityViewModel, MemberViewModel, MembersScreenSuccessState, MembersScreenViewModel } from 'presentation/screens/MembersScreen/MembersScreen.viewModel'
import { InviteMembersModalAPI } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModal.api'
import { MemberDeactivateModalAPI } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberDeactivateModal.api'
import { MemberReactivateModalAPI } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberReactivateModal.api'
import { UserInfoModalAPI } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoModal.api'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

export const useMembersScreenController = (useCases: MembersScreenUseCases): MembersScreenViewModel => {
  const runOnce = useRunOnceCallback()

  // =============================================================================
  // State
  // =============================================================================
  const membersInfoQuery = useCases.query.teamInfo

  // Local state
  const [isDeactivatedSectionExpanded, setIsDeactivatedSectionExpanded] = useState(false)

  /**
   * Show error toast if membersInfoQuery has error
   */
  useEffect(() => {
    if (!CQ.isError(membersInfoQuery.state)) return

    toast.error({
      title: 'Sorry there was an error!',
      message: membersInfoQuery.state.error.message,
    })
  }, [membersInfoQuery.state])

  // =============================================================================
  // Action - inviteMembers
  // =============================================================================
  const inviteMembersCommand = useCases.command.inviteMembers

  const plan = membersInfoQuery.state.status === 'success' && membersInfoQuery.state.data.plan

  const onInviteMembers = useMemo(() => {
    if (!plan) return null

    return () => {
      InviteMembersModalAPI.transitionToIdle({
        plan,
        onInvite: (payload: InviteMembersPayload) => {
          InviteMembersModalAPI.transitionToLoading({ plan })
          inviteMembersCommand.execute(payload)
        },
      })
    }
  }, [plan])

  // React to inviteMembersCommand result
  useEffect(() => {
    pipe(
      M.value(inviteMembersCommand.state),

      M.when({ status: 'success' }, state => {
        const emails = state.meta.payload.emails
        toast.success({
          title: 'That was a success!',
          message: `${emails.length} team ${pluralize('member', emails.length)} has been successfully added.`,
        })
        goBackModal()
      }),

      M.when({ error: M.instanceOf(PartialInviteMembersError) }, state => {
        const error = TypeLib.toWritable(state.error)

        toast.error({
          title: 'We couldn’t invite some members',
          message: 'Please see the remaining emails and their errors. Other emails that were valid have been invited.',
        })

        InviteMembersModalAPI.transitionToPartialError({
          error,
        })
      }),

      M.when({ error: M.instanceOf(ProgramException) }, state => {
        const error = state.error

        void ErrorLib.report(error)

        toast.error({
          title: 'Couldn’t invite members',
          message: error.message,
        })

        InviteMembersModalAPI.transitionToProgramException({
          error,
        })
      }),
    )
  }, [inviteMembersCommand.state])

  // =============================================================================
  // Action - updateMember
  // =============================================================================
  const updateMemberCommand = useCases.command.updateMember

  const me = membersInfoQuery.state.status === 'success' && membersInfoQuery.state.data.me

  const onUpdateMember = useMemo(() => {
    if (!me) return null

    const onInviteInit = () => {
      UserInfoModalAPI.transitionToInitial({
        member: me,
        onCancel: () => {
          UserInfoModalAPI.transitionToClosed()
        },
        onSave: payload => {
          UserInfoModalAPI.transitionToLoading({})
          updateMemberCommand.execute(payload)
        },
      })
    }

    return onInviteInit
  }, [me])

  // React to updateMemberCommand result
  useEffect(() => {
    pipe(
      M.value(updateMemberCommand.state),

      M.when({ status: 'success' }, () => {
        toast.success({
          title: 'That was a success!',
          message: 'Member info has been successfully updated.',
        })
        goBackModal()
      }),

      M.when({ error: M.instanceOf(PartialError<WithoutId<UpdateMemberPayload>>) }, state => {
        const error = state.error

        UserInfoModalAPI.transitionToPartialError({
          error,
        })
      }),

      M.when({ error: M.instanceOf(ProgramException) }, state => {
        const error = state.error

        void ErrorLib.report(error)

        toast.error({
          title: 'Couldn’t update member',
          message: error.message,
        })

        UserInfoModalAPI.transitionToProgramException({
          error,
        })
      }),
    )
  }, [updateMemberCommand.state])

  // =============================================================================
  // Action - enableMember
  // =============================================================================
  const enableMemberCommand = useCases.command.enableMember
  const members = membersInfoQuery.state.status === 'success' && membersInfoQuery.state.data.members
  const owner = membersInfoQuery.state.status === 'success' && membersInfoQuery.state.data.owner

  const onEnableMember = useMemo(() => {
    if (!owner || !members) return null

    const onEnableInit = (id: string) => {
      const member = members?.find(member => member.id === id)

      if (!member) {
        void ErrorLib.report(
          new ReportableException('Missing member', {
            extraInfo: {
              members,
              useCases,
            },
          }),
        )

        return
      }

      MemberReactivateModalAPI.open({
        member,
        isOwner: owner.id === id,
        onEnable: () => {
          MemberReactivateModalAPI.transitionToLoading({})
          enableMemberCommand.execute({ id })
        },
      })
    }

    return onEnableInit
  }, [owner, members])

  // React to enableMemberCommand result
  useEffect(() => {
    pipe(
      M.value(enableMemberCommand.state),

      M.when({ status: 'success' }, () => {
        toast.success({
          title: 'That was a success!',
          message: 'Team Member has been reactivated.',
        })
        goBackModal()
      }),

      M.when({ error: M.instanceOf(ProgramException) }, state => {
        void ErrorLib.report(state.error)

        toast.error({
          title: 'Something went wrong',
          message: 'We couldn’t activate the member. Please try again or let us know via live support',
        })

        MemberReactivateModalAPI.transitionToInitial({})
      }),
    )
  }, [enableMemberCommand.state])

  // =============================================================================
  // Action - disableMember
  // =============================================================================
  const disableMemberCommand = useCases.command.disableMember

  const onDisableMember = useMemo(() => {
    if (!owner || !members) return null

    const onDisableInit = (id: string) => {
      const member = members?.find(member => member.id === id)

      if (!member) {
        void ErrorLib.report(
          new ReportableException('Missing member', {
            extraInfo: {
              members,
              useCases,
            },
          }),
        )

        return
      }

      MemberDeactivateModalAPI.open({
        member,
        isOwner: owner.id === id,
        onDisable: () => {
          MemberDeactivateModalAPI.transitionToLoading()
          disableMemberCommand.execute({ id })
        },
      })
    }

    return onDisableInit
  }, [owner, members])

  // React to disableMemberCommand result
  useEffect(() => {
    pipe(
      M.value(disableMemberCommand.state),

      M.when({ status: 'success' }, () => {
        toast.info({
          title: 'Deactivation processed',
          message: 'Team Member has been deactivated.',
        })
        goBackModal()
      }),

      M.when({ error: M.instanceOf(ProgramException) }, state => {
        void ErrorLib.report(state.error)

        toast.error({
          title: 'Something went wrong',
          message: 'We couldn’t deactivate the member. Please try again or let us know via live support',
        })

        MemberDeactivateModalAPI.transitionToInitial()
      }),
    )
  }, [disableMemberCommand.state])

  // =============================================================================
  // Action - resendMemberInvite
  // =============================================================================
  const resendMemberInviteCommand = useCases.command.resendMemberInvite

  const onResendMemberInvite = useCallback((payload: {
    id: string
    email: string
  }) => {
    resendMemberInviteCommand.execute(payload.id, payload)
  }, [])

  const lastResendMemberInviteCommandState = useRef(resendMemberInviteCommand.state)

  // React to resendMemberInviteCommand result
  useEffect(() => {
    Object.keys(resendMemberInviteCommand.state).forEach(key => {
      const last = lastResendMemberInviteCommandState.current[key]
      const current = resendMemberInviteCommand.state[key]

      if (last !== current) {
        pipe(
          M.value(current),

          M.when({ status: 'success' }, () => {
            toast.success({
              title: 'That was a success!',
              message: 'Invite has been resent',
            })
            goBackModal()
          }),

          M.when({ error: M.instanceOf(ProgramException) }, state => {
            void ErrorLib.report(state.error)

            toast.error({
              title: 'Something went wrong',
              message: 'We couldn’t resend the invite. Please try again or let us know via live support',
            })

            MemberDeactivateModalAPI.transitionToInitial()
          }),
        )
      }
    })

    lastResendMemberInviteCommandState.current = resendMemberInviteCommand.state
  }, [resendMemberInviteCommand.state])

  // =============================================================================
  // Action - Toggle Deactivated Section
  // =============================================================================
  const onDeactivatedSectionToggle = useCallback(() => {
    setIsDeactivatedSectionExpanded(state => !state)
  }, [])

  // =============================================================================
  // Map data to view model
  // =============================================================================

  const memberStatusRecordRef = useRef<Record<string, MemberViewModel['status']>>({})
  const didLoadRef = useRef(false)

  /**
   * Capture newly invited members status. Only runs if query has loaded at
   * least once, because first load members are 'initially-loaded' members
   * rather than 'newly-added' members
   */
  useMemo(() => {
    if (!didLoadRef.current || membersInfoQuery.state.status !== 'success') return

    membersInfoQuery.state.data.members.forEach(member => {
      if (!memberStatusRecordRef.current[member.id])
        memberStatusRecordRef.current[member.id] = 'newly-added'
    })
  }, [membersInfoQuery.state])

  /** Capture initial loaded member status. Only runs in the first load */
  useMemo(() => {
    if (didLoadRef.current || membersInfoQuery.state.status !== 'success') return

    didLoadRef.current = true
    membersInfoQuery.state.data.members.forEach(member => {
      memberStatusRecordRef.current[member.id] = 'initially-loaded'
    })
  }, [membersInfoQuery.state])

  /** Capture enabled member status */
  useMemo(() => {
    if (enableMemberCommand.state.status !== 'success') return
    const { id } = enableMemberCommand.state.meta.payload
    memberStatusRecordRef.current[id] = 'newly-enabled'
  }, [enableMemberCommand.state])

  /** Capture disabled member status */
  useMemo(() => {
    if (disableMemberCommand.state.status !== 'success') return
    const { id } = disableMemberCommand.state.meta.payload
    memberStatusRecordRef.current[id] = 'newly-disabled'
  }, [disableMemberCommand.state])

  /** Capture resending member status */
  useMemo(() => {
    Object.entries(resendMemberInviteCommand.state)
      .forEach(([id, state]) => {
        const isResending = state?.status === 'loading'
        const isInResendingState = memberStatusRecordRef.current[id] === 'resending-invite'

        if (isResending)
          memberStatusRecordRef.current[id] = 'resending-invite'
        else if (!isResending && isInResendingState)
          memberStatusRecordRef.current[id] = 'idle'
      })
  }, [resendMemberInviteCommand.state])

  const memberViewModels: MemberViewModel[] | null = useMemo(() => {
    const queryState = membersInfoQuery.state
    const data = queryState.status === 'success' && queryState.data

    const areRequirementsMet = !!data
      && !!onUpdateMember
      && !!onEnableMember
      && !!onDisableMember
      && !!onResendMemberInvite

    if (!areRequirementsMet) return null

    return data.members
      .map((member): MemberViewModel | null => {
        const isMemberOwner = member.id === data.owner.id
        const isMemberMe = member.id === data.me.id
        const amIOwner = data.me.id === data.owner.id

        const canUpdate = isMemberMe
        const canEnable = amIOwner && !member.isEnabled
        const canDisable = amIOwner && !isMemberMe && member.isEnabled
        const canResendInvite = amIOwner && !member.isEmailConfirmed

        const shouldHidePendingMemberFromNonOwner = !amIOwner && !member.isEmailConfirmed

        if (shouldHidePendingMemberFromNonOwner)
          return null

        return {
          ...member,
          status: memberStatusRecordRef.current[member.id] || 'initially-loaded',
          isOwner: isMemberOwner,

          ...canUpdate && {
            canUpdate: true,
            onUpdate: () => onUpdateMember(),
          } satisfies CanUpdateMemberCapabilityViewModel,

          ...canEnable && {
            canEnable: true,
            isEnabled: false,
            onEnable: () => onEnableMember(member.id),
          } satisfies CanEnableMemberCapabilityViewModel,

          ...canDisable && {
            canDisable: true,
            isEnabled: true,
            onDisable: () => onDisableMember(member.id),
          } satisfies CanDisableMemberCapabilityViewModel,

          ...canResendInvite && {
            canResendInvite: true,
            onResendInvite: () => {
              if (!member.email) return

              return onResendMemberInvite({
                id: member.id,
                email: member.email,
              })
            },
            isEmailConfirmed: false,
          } satisfies CanResendInviteMemberCapabilityViewModel,
        }
      })
      .filter(isNonNullable)
  }, [
    // Member data would have been changed when this updates
    membersInfoQuery.state,

    // Member status could have been changed when these commands update
    inviteMembersCommand.state,
    resendMemberInviteCommand.state,
    enableMemberCommand.state,
    disableMemberCommand.state,
  ])

  const ownerViewModel: MemberViewModel | null = useMemo(() => {
    const areRequirementsDefined = !!memberViewModels && owner

    if (!areRequirementsDefined) return null

    return memberViewModels.find(member => member.id === owner.id) || null
  }, [memberViewModels, owner])

  const meViewModel: MemberViewModel | null = useMemo(() => {
    const areRequirementsDefined = !!memberViewModels && me

    if (!areRequirementsDefined) return null

    return memberViewModels.find(member => member.id === me.id) || null
  }, [memberViewModels, me])

  // =============================================================================
  // Result
  // =============================================================================

  return pipe(
    M.value(membersInfoQuery.state),

    M.when({ status: 'loading' }, () => ({
      status: 'loading' as const,
    })),

    M.when({ status: 'error' }, () => ({
      status: 'error' as const,
    })),

    M.when({ status: 'success' }, state => {
      const areRequirementsDefined = !!onInviteMembers
        && !!onUpdateMember
        && !!onEnableMember
        && !!onDisableMember
        && memberViewModels
        && ownerViewModel
        && meViewModel

      if (!areRequirementsDefined) {
        runOnce(() => {
          toast.error({
            title: 'Unexpected error',
            message: GENERIC_ERROR.message,
          })

          void ErrorLib.report(new ReportableException('Unexpected state', {
            extraInfo: {
              useCases,
              actions: {
                onInviteMembers,
                onUpdateMember,
              },
            },
          }))
        })

        return { status: 'error' as const }
      }

      return {
        status: 'success' as const,
        ...state.data,
        members: memberViewModels,
        owner: ownerViewModel,
        me: meViewModel,
        isDeactivatedSectionExpanded,
        resendMemberInviteState: useCases.command.resendMemberInvite.state,
        onInviteMembers,
        onDeactivatedSectionToggle,
      } satisfies MembersScreenSuccessState
    }),

    M.exhaustive,
  )
}
