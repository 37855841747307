import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_MOBILE_ICON_W = 33

export const PropTypeMobileIcon = createIcon({
  displayName: 'PropTypeMobileIcon',
  viewBox: '3.13 3.13 35.75 23.13',
  path: (
    <>
      <path d='M17.25 13.2501L12.25 6.75006L6.75 13.2501V22.2501H17.25M17.25 13.2501H35.75V22.2501H17.25M17.25 13.2501V22.2501M3.75 13.2501L12.25 3.75003M37.25 11.2501H18.25L12.25 3.75003M37.25 11.2501L32.75 3.75L12.25 3.75003M37.25 11.2501L38.25 12.75' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <circle cx='21.75' cy='23.25' r='2.5' fill='none' stroke='currentColor' />
      <mask id='mask0_3451_22510' style={{ maskType: 'alpha' }} maskUnits='userSpaceOnUse' x='7' y='22' width='7' height='5'>
        <rect x='7.75' y='22.8679' width='6' height='3.3821' fill='currentColor' />
      </mask>
      <g mask='url(#mask0_3451_22510)'>
        <circle cx='10.75' cy='23.2501' r='2.5' fill='none' stroke='currentColor' />
      </g>
      <circle cx='32.2402' cy='23.25' r='2.5' fill='none' stroke='currentColor' />
      <path d='M10 21.5V15H14V22' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
    </>
  ),
})
