import { createIcon } from '@chakra-ui/react'

export const CropIcon = createIcon({
  displayName: 'CropIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath='url(#clip0_1325_120500)'>
        <path
          d='M6.13 1L6 16C6 16.5304 6.21071 17.0391 6.58579 17.4142C6.96086 17.7893 7.46957 18 8 18H23'
          stroke='currentColor'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
        <path
          d='M1 6.13L16 6C16.5304 6 17.0391 6.21071 17.4142 6.58579C17.7893 6.96086 18 7.46957 18 8V23'
          stroke='currentColor'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
          fill='none'
        />
      </g>
      <defs>
        <clipPath id='clip0_1325_120500'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </>
  ),
})
