import { extendTheme } from '@chakra-ui/react'
import { set } from 'lodash/fp'
import { cardTheme } from 'presentation/components/molecules/Card/Card.theme'
import { breakpointTheme } from 'presentation/main/themes/common/breakpoints.theme'
import { commonTheme } from 'presentation/main/themes/common/common.theme'

type TextStyles = typeof commonTheme.textStyles

type Theme<Colors, ID extends string> = Record<string, any> // extendTheme's output type
  & Omit<typeof commonTheme, 'colors'>
  & {
    id: ID
    colors: Colors
    textStyles: TextStyles
  }

export const prepareTheme = <Colors, ID extends string>(id: ID, colors: Colors): Theme<Colors, ID> => {
  let theme: Theme<Colors, ID> = {
    id,

    // Not using `extendTheme` breaks few things for some reason
    ...extendTheme({
      ...commonTheme,
      colors: {
        ...colors,
      },
    }) as typeof commonTheme & { colors: Colors },

    // Get rid of default breakpoints from extendTheme
    breakpoints: breakpointTheme,

  }

  // Purge problematic default component themes
  theme = set('components.Card', cardTheme, theme)

  return theme
}
