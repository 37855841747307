import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { MLSDisclaimer } from 'presentation/components/MLSDisclaimerSection/MLSDisclaimer'
import { shallow } from 'zustand/shallow'

export const MLSDisclaimerSection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    return {
      mlsOrganizations: property.mlsListings.map(mlsListing => mlsListing.organization),
    }
  }, shallow)

  if (!data) return null

  return <MLSDisclaimer organizations={data.mlsOrganizations} />
}
