import { BoxProps, HTMLChakraProps, chakra, useMenuContext, useMenuStyles } from '@chakra-ui/react'
import cn from 'classnames'
import { OverlayScrollbars } from 'overlayscrollbars'
import { FC, ReactNode, useEffect, useRef } from 'react'

export const MenuTitle: FC<HTMLChakraProps<'h1'>> = props => {
  const styles = useMenuStyles()

  return (
    <chakra.h1
      __css={styles.title}
      {...props}
      className={cn('chakra-menu__menutitle', props.className)}
    />
  )
}

export const MenuItemButton: FC<HTMLChakraProps<'button'> & {
  icon?: ReactNode
}> = ({ icon, children, ...props }) => {
  const styles = useMenuStyles()

  return (
    <chakra.button
      __css={styles.itemButton}
      {...props}
      className={cn('chakra-menu__menuitem-button', props.className)}
    >
      {icon && (
        <chakra.span fontSize='3' w='4' textAlign='center' display='flex' justifyContent='center'>
          {icon}
        </chakra.span>
      )}
      {children}
    </chakra.button>
  )
}

export const MenuArrow: FC<BoxProps> = props => {
  const { popper } = useMenuContext()
  const { getArrowProps, getArrowInnerProps } = popper
  const styles = useMenuStyles()

  return (
    <chakra.div
      {...getArrowProps({
        size: '32px',
        pointerEvents: 'none',
      })}
      className={cn('arrow chakra-menu__menuarrow', props.className)}
      sx={{
        zIndex: '0 !important',
      }}
    >
      <chakra.div
        {...getArrowInnerProps()}
        display='flex'
        justifyContent='center'
        sx={{
          'pt': '4px',
          'pb': '0',

          '*[data-popper-placement^="top"] &': {
            pt: '0',
            pb: '16px',
          },
        }}
      >
        <svg width='32' height='12' viewBox='0 0 32 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <chakra.path d='M14.2 0.64C15.2667 -0.213333 16.7333 -0.213334 17.8 0.64L32 12H0L14.2 0.64Z' fill={styles.list.bg as string} />
          <chakra.path fillRule='evenodd' clipRule='evenodd' d='M29 10.9333L17.2 1.49333C16.4889 0.924444 15.5111 0.924445 14.8 1.49333L3 10.9333H29ZM17.8 0.64C16.7333 -0.213334 15.2667 -0.213333 14.2 0.64L0 12H32L17.8 0.64Z' fill={styles.list.borderColor as string} />
          <chakra.path d='M29 10.9333L30.4081 12H1.69189L3 10.9333H29Z' fill={styles.list.bg as string} />
        </svg>
      </chakra.div>
    </chakra.div>
  )
}

export const MenuScrollArea: FC<HTMLChakraProps<'div'>> = props => {
  const styles = useMenuStyles()
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!ref.current) return
    OverlayScrollbars(ref.current, {})
  }, [])

  return (
    <chakra.div
      ref={ref}
      __css={styles.scrollArea}
      {...props}
      className={cn('chakra-menu__menuscrollarea', props.className)}
    />
  )
}
