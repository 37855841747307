import { ParcelBuyerType } from '__generated__/graphql'
import { Effect, Option } from 'effect'
import { UnknownException } from 'effect/Cause'
import { isNumber } from 'effect/Predicate'
import GetNearbyBuyers from 'features/NearbyBuyers/repository/GetNearbyBuyers'
import { GET_NEARBY_BUYERS_QUERY } from 'features/NearbyBuyers/repository/GetNearbyBuyers.graphql'
import GetNearbyBuyersSchema from 'features/NearbyBuyers/repository/GetNearbyBuyers.schema'
import { apolloClient } from 'presentation/libs/client'

const GetNearbyBuyersLive: GetNearbyBuyers = ({
  location,
  filters,
  before,
  after,
  first,
  last,
}) =>
  Effect.tryPromise({
    try: () =>
      apolloClient.query({
        errorPolicy: 'all',
        query: GET_NEARBY_BUYERS_QUERY,
        variables: {
          before,
          after,
          first,
          last,
          input: {
            locationRadius: {
              latitude: location.latitude,
              longitude: location.longitude,
              radiusMiles: filters.distanceMiles,
            },
            buyerTypes: [
              ...filters.shouldIncludeLandLords ? [ParcelBuyerType.Landlord] : [],
              ...filters.shouldIncludeFlippers ? [ParcelBuyerType.Flipper] : [],
            ],
            soldWithinMonths: filters.soldWithinMonths.pipe(Option.getOrNull),
            numberProperties: filters.dealsRange.some(isNumber)
              ? {
                min: filters.dealsRange[0] ?? null,
                max: filters.dealsRange[1] ?? null,
              }
              : null,
          },
        },
      })
        .then(response => GetNearbyBuyersSchema.resultFromGraphQLResult(response.data)),
    catch: error => new UnknownException(error),
  })

export default GetNearbyBuyersLive
