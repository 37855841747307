import { createIcon } from '@chakra-ui/react'

export const FullscreenArrowIcon = createIcon({
  displayName: 'FullscreenArrowIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
    >
      <path
        d='M14.5 4.5H19.5V9.5'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 19.5H4.5V14.5'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5013 4.5L13.668 10.3333'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 19.4993L10.3333 13.666'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
