import { Box, BoxProps, Flex, FlexProps, Spacer, Text } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { Tooltip } from 'presentation/components/Tooltip'
import { CMAEntriesViewMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar'
import { useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { CMAPrintButton } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAPrintButton'
import { CompsFilterBar } from 'presentation/screens/CompsScreen/components/CompsFilterBar'
import { DefaultFiltersDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/DefaultFiltersDropdown/DefaultFiltersDropdown'
import { CompsHeaderProps } from 'presentation/screens/CompsScreen/components/CompsHeader/CompsHeader.props'
import { FiltersDisabledAlert } from 'presentation/screens/CompsScreen/components/FiltersDisabledAlert/FiltersDisabledAlert'
import { selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { animationClasses } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { shallow } from 'zustand/shallow'

export const CompsHeader = ({ children, ...props }: CompsHeaderProps) => (
  <Breakpoint
    mobSm={(
      <CompsHeaderExtraSmall {...props} />
    )}
    mob={(
      <CompsHeaderSmall {...props} />
    )}
    tabSm={(
      <Flex
        bgColor='neutral.200'
        gap={1}
        py={1}
        px={3}
        alignItems='center'
        className={animationClasses.fadeIn}
        {...props}
      >
        <CompsFilterBar
          ml='0'
          minW='0'
          flex='1 1 0'
        />
      </Flex>
    )}
    dtLg={(
      <CompsHeaderLarge {...props} />
    )}
  />
)

export const CompsHeaderExtraSmall = ({ children, ...props }: BoxProps) => {
  const activeTab = useActiveEntriesTab(selectActiveTab)
  const isReportOpen = activeTab === 'REPORT'

  return (
    <Flex
      bgColor='neutral.200'
      gap={1}
      alignItems='center'
      className={animationClasses.fadeIn}
      p={1}
      {...props}
    >
      {isReportOpen
        ? (

          <FiltersDisabledAlert />
        )
        : (
          <>
            <Spacer />
            <CMAEntriesViewMenu
              size='sm'
              variant='solid'
              px={2}
            />
            <DefaultFiltersDropdown
              px={2}
            />
          </>
        )}

      <CMAPrintButton
        colorScheme='neutral'
      />
    </Flex>
  )
}

export const CompsHeaderSmall = ({ children, ...props }: BoxProps) => {
  const activeTab = useActiveEntriesTab(selectActiveTab)
  const isReportOpen = activeTab === 'REPORT'

  return (
    <Flex
      bgColor='neutral.200'
      gap={1}
      alignItems='center'
      className={animationClasses.fadeIn}
      px={2}
      py={1}
      {...props}
    >
      <SubjectPropertyMarker markerType='pin' size='xs' />
      <CompsAddressSection minW='132px' flex='1' />
      {isReportOpen
        ? (
          <FiltersDisabledAlert />
        )
        : (
          <>
            <CMAEntriesViewMenu
              size='sm'
              variant='solid'
              px={2}
            />
            <DefaultFiltersDropdown
              px={2}
            />
          </>
        )}
      <CMAPrintButton
        colorScheme='neutral'
      />
    </Flex>
  )
}
export const CompsHeaderLarge = ({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <Flex
      bgColor='neutral.200'
      alignItems='center'
      py={2}
      px={3}
      className={animationClasses.fadeIn}
    >
      <Flex gap={1} mr={2} minW='0' flex='0 1 auto'>
        <SubjectPropertyMarker markerType='pin' size='sm' />
        <CompsAddressSection />
      </Flex>
      <CompsFilterBar
        ml='0'
        minW='1048px'
        flex='1 1 0'
      />
    </Flex>
  </Box>
)

export const CompsHeaderAddress = (props: FlexProps) => {
  const viewValue = useResponsiveViewValue()
  const isShowingTable = viewValue === 'TABLE_VIEW'
  return isShowingTable
    ? null
    : (
      <Flex
        bgColor='neutral.200'
        gap={1}
        py={1}
        px={3}
        alignItems='center'
        position='absolute'
        top={0}
        left={0}
        w='240px'
        h='64px'
        borderBottomRightRadius={4}
        {...props}
      >
        <SubjectPropertyMarker markerType='pin' size='xs' />
        <CompsAddressSection />
      </Flex>
    )
}

export const CompsAddressSection = (props: BoxProps) => {
  const address = useCMAStore<CMA.Comp.Address>(api => {
    if (api.local.report.status === 'no-coverage' || api.local.report.status === 'no-subj-location')
      return api.local.report.data

    if (!checkStateHasReportData(api.local.report)) {
      const address: CMA.Comp.Address = {
        city: '',
        line1: '',
        postalCode: '',
        state: '',
        subdivision: null,
      }
      return address
    }

    /**
     * No need to consult tab, just get the sales subject comp always
     * because sales is populated and more battle-tested more than rentals,
     * and address isn't expected to change between tabs.
     */
    return api.local.report.data.salesListInfo.subject.address
  }, shallow)
  return (
    <Flex flexDir='column' minW={0} flex='1' justify='center' {...props}>
      <Tooltip label={address.line1}>
        <Text
          textStyle={mbp({
            mobSm: 'bodyLFat',
            mob: 'bodyXLFat',
          })}
          color='accent.blue-to-gray'
          isTruncated
        >
          {address.line1}
        </Text>
      </Tooltip>
      {address.subdivision && (
        <Tooltip label={address.subdivision}>
          <Text
            textStyle={mbp({
              mobSm: 'bodySFat',
              mob: 'bodyMFat',
            })}
            color='graystrong.200'
            isTruncated
          >
            {address.subdivision}
          </Text>
        </Tooltip>
      )}
    </Flex>
  )
}
