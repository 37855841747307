import { Box, Link } from '@chakra-ui/react'
import { Address } from 'features/valueObjects/Address'
import { DateLib } from 'libs/Date'
import { Dollar } from 'libs/dollar'
import { AddressTwoLines } from 'presentation/components/AddressTwoLines/AddressTwoLines'
import { useState } from 'react'
import { isNumber, isString } from 'remeda'

export namespace TableSectionCommon {
  export const formatDate = DateLib.format.fallback('--')('MM/dd/yyyy')
  export const formatDollar = Dollar.formatNumber.withCommasDecimals.fallback('--')
  export const formatString = (value: string | null | undefined) => isString(value) ? value : '--'
  export const formatNumber = (value: number | null | undefined) => isNumber(value) ? value.toString() : '--'
  export const formatAddress = (address: Address | null | undefined) =>
    address ? <AddressTwoLines address={address} /> : '--'
  export const formatBoolean = (value: boolean | null | undefined) =>
    value === true
      ? 'Yes'
      : value === false
        ? 'No'
        : '--'

  export const MultipleStrings = ({ strings, initialIsExpanded }: {
    strings: string[] | undefined
    initialIsExpanded?: boolean
  }) => strings?.length
    ? (
      <CollapsibleItems
        items={strings || []}
        limit={2}
        initialIsExpanded={initialIsExpanded}
      />
    )
    : (
      <>--</>
    )

  export const MultipleAddresses = ({ addresses, initialIsExpanded }: {
    addresses: Address[] | undefined
    initialIsExpanded?: boolean
  }) => addresses?.length
    ? (
      <CollapsibleItems
        items={addresses.map((address, i) => (
          <AddressTwoLines key={i} address={address} />
        )) || []}
        limit={2}
        initialIsExpanded={initialIsExpanded}
      />
    )
    : (
      <>--</>
    )

  export const CollapsibleItems = ({
    items: allItems,
    limit,
    initialIsExpanded = false,
  }: {
    items: (JSX.Element | string)[]
    limit: number
    initialIsExpanded?: boolean
  }) => {
    const [isExpanded, setIsExpanded] = useState(initialIsExpanded)

    const itemsToRender = isExpanded
      ? allItems
      : allItems.slice(0, limit)

    return (
      <>
        {itemsToRender.map((item, i) => {
          const shouldShowButton = i === itemsToRender.length - 1 && allItems.length > limit
          const shouldShowMore = shouldShowButton && !isExpanded
          const shouldShowLess = shouldShowButton && isExpanded

          return (
            <Box pt={i === 0 ? 0 : 1} key={i}>
              {item}
              {shouldShowLess && (
                <>
                  {' '}
                  <Link
                    onClick={ev => {
                      ev.preventDefault()
                      setIsExpanded(false)
                    }}
                  >
                    Less
                  </Link>
                </>
              )}

              {shouldShowMore && (
                <>
                  ...
                  {' '}
                  <Link
                    onClick={ev => {
                      ev.preventDefault()
                      setIsExpanded(true)
                    }}
                  >
                    More
                  </Link>
                </>
              )}
            </Box>
          )
        })}
      </>
    )
  }

}
