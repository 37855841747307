import { Center, Spinner } from '@chakra-ui/react'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import { ListsState, useListStoreActions, useLists } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { ListsTabsContent } from 'presentation/screens/ListsScreen/component/ListsTabsContent/ListsTabsContent'
import React, { useEffect } from 'react'

export const ListsTabs = () => {
  const [activeTab, setActiveTab] = React.useState<ListType | 'all'>('all')
  const actions = useListStoreActions()
  const listsState = useLists()

  useEffect(() => {
    actions.loadLists()
  }, [])

  return (
    <BaseTabsContainer>
      <BaseTabs alignItems='center'>
        {([
          ['All', 'all'],
          // ['Properties', 'property-lists'],
          // ['Cash Buyers', 'cash-buyers'],
          // ['Private Lenders', 'private-lenders'],
        ] as const).map(([label, type]) => (
          <BaseTab
            key={type}
            isActive={activeTab === type}
            textTransform='uppercase'
            onClick={() => setActiveTab(type)}
          >
            {label}
          </BaseTab>
        ))}
        {/* <Spacer />
        <Text textStyle='bodyM' color='ondark.6' pr={3.5}>
          3 Lists
        </Text> */}
      </BaseTabs>
      <BaseTabContent
        p={2}
        sx={{
          // These styles are put here in order to override
          borderRadius: 4,
          borderTopLeftRadius: 0,
        }}
      >
        {!ListsState.$is('Success')(listsState)
          ? (
            <Center>
              <Spinner color='grayweak.300' />
            </Center>
          )
          : (
            <ListsTabsContent />
          )}
      </BaseTabContent>
    </BaseTabsContainer>
  )
}
