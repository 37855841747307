import { createIcon } from '@chakra-ui/react'

export const TriangleLeftIcon = createIcon({
  displayName: 'TriangleLeftIcon',
  viewBox: '0 0 9 8',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M-8.74228e-07 4L9 -2.81077e-06L9 8L-8.74228e-07 4Z' fill='currentColor' />
    </svg>

  ),
})
