import { Spinner } from '@chakra-ui/react'
import { alertTheme } from 'presentation/components/Alert/Alert.theme'
import { badgeTheme } from 'presentation/components/Badge/Badge.theme'
import { baseTabsTheme } from 'presentation/components/BaseTabs/BaseTabs.theme'
import { breadcrumbTheme } from 'presentation/components/Breadcrumbs/Breadcrumb.theme'
import { checkboxTheme } from 'presentation/components/Checkbox/Checkbox.theme'
import { chipTheme } from 'presentation/components/Chip/Chip.theme'
import { formErrorMessageTheme } from 'presentation/components/FormErrorMessage/FormErrorMessage.theme'
import { formLabelTheme } from 'presentation/components/FormLabel/FormLabel.theme'
import { inputTheme } from 'presentation/components/Input/Input.theme'
import { menuTheme } from 'presentation/components/Menu/Menu.theme'
import { modalTheme } from 'presentation/components/Modal/Modal.theme'
import { noticeTheme } from 'presentation/components/Notice/Notice.theme'
import { numberInputTheme } from 'presentation/components/NumberInput/NumberInput.theme'
import { paginationTheme } from 'presentation/components/Pagination/Pagination.theme'
import { pairTheme } from 'presentation/components/Pair/pair.theme'
import { radioTheme } from 'presentation/components/Radio/Radio.theme'
import { selectTheme } from 'presentation/components/Select/Select.theme'
import { stepperTheme } from 'presentation/components/Stepper/Stepper.theme'
import { switchTheme } from 'presentation/components/Switch/Switch.theme'
import { tableTheme } from 'presentation/components/Table/Table.theme'
import { tagTheme } from 'presentation/components/Tag/Tag.theme'
import { toastTheme } from 'presentation/components/Toast/Toast.theme'
import { tooltipTheme } from 'presentation/components/Tooltip/Tooltip.theme'
import { cardTheme } from 'presentation/components/molecules/Card/Card.theme'
import { avatarTheme } from 'presentation/main/themes/components/avatar.theme'
import { buttonTheme } from 'presentation/main/themes/components/button.theme'
import { popoverTheme } from 'presentation/main/themes/components/popover.theme'
import { Text } from 'presentation/main/themes/components/text.theme'
import { formTheme } from '../../../components/Form/Form.theme'
import { CloseButton } from './closeButton.theme'
import { Link } from './link.theme'
import { Textarea } from './textarea.theme'

export const components = {
  // Chakra
  Alert: alertTheme,
  Avatar: avatarTheme,
  Badge: badgeTheme,
  Button: buttonTheme,
  Breadcrumb: breadcrumbTheme,
  Card: cardTheme,
  Checkbox: checkboxTheme,
  Chip: chipTheme,
  CloseButton,
  Form: formTheme,
  FormControl: formTheme,
  FormError: formErrorMessageTheme,
  FormLabel: formLabelTheme,
  Input: inputTheme,
  Link,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Radio: radioTheme,
  Select: selectTheme,
  Spinner,
  Stepper: stepperTheme,
  Switch: switchTheme,
  BaseTabs: baseTabsTheme,
  Table: tableTheme,
  Tag: tagTheme,
  Text,
  Textarea,
  Tooltip: tooltipTheme,

  // Custom
  Toast: toastTheme,
  Notice: noticeTheme,
  NumberInput: numberInputTheme,
  Pair: pairTheme,
  Pagination: paginationTheme,
}
