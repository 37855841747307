const ensureOr = <TFallback>(fallback: TFallback) =>
  <TElement>(value: TElement[] | null | undefined): TElement[] | TFallback =>
    Array.isArray(value) ? value : fallback

const ensure = ensureOr([]) as <TElement>(value: TElement[] | null | undefined) =>
TElement[]

export const ArrayLib = {
  ensureOr,
  ensure,
}
