import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type PropertySaveToListModalState = {
  state: {
    status: 'open'
  } | {
    status: 'closed'
  }
  open: () => void
  close: () => void
}

const usePropertySaveToListModalStore = create<PropertySaveToListModalState>()(immer(set => ({
  state: { status: 'closed' },
  open: () => set(draft => {
    draft.state = { status: 'open' }
  }),
  close: () => set(draft => {
    draft.state = { status: 'closed' }
  }),
})))

export const usePropertySaveToListModal = () =>
  usePropertySaveToListModalStore(store => store.state)

export const usePropertySaveToListModalActions = () =>
  usePropertySaveToListModalStore(store => ({
    open: store.open,
    close: store.close,
  }))
