import { Match, pipe } from 'effect'
import { ReskipConfig } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { restClient } from 'presentation/libs/client'

const request = async (payload: {
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  reskipConfig: ReskipConfig
}) =>
  await restClient.post(`lists/v3/${payload.listId}/skiptrace/count`, {
    json: {
      provider: 'skipGenie',
      members: payload.memberIds,
      reskip: pipe(
        Match.value(payload.reskipConfig),
        Match.tag('DoNotReskip', () => false),
        Match.tag('Reskip', () => true),
        Match.tag('ReskipIfOlderThanXDays', ({ daysCount }) =>
          ({ olderThanXDays: daysCount })),
        Match.exhaustive,
      ),
    },
  })
    .json()

export const POSTGetToBatchSkiptraceCount = {
  request,
}
