import { chakra } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'

export const SuperscriptContainer = ({
  children,
}: PropsWithChildren) => (
  <chakra.svg
    stroke='none'
    fill='none'
    fillRule='evenodd'
    x='31'
    y='0'
  >
    {
      /**
       * ===========================
       * Background Circle
       * ===========================
       */
    }
    <chakra.circle
      stroke='marker.strawberrypink'
      fill='marker.white-to-red'
      fillRule='nonzero'
      cx='9.5'
      cy='9.5'
      r='9'
      strokeWidth='1'
    />
    {children}
  </chakra.svg>
)
