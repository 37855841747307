import { Grid, VStack } from '@chakra-ui/react'
import { Array } from 'effect'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'
import D4DFeatureRowViewModel from './D4DFeatureRowViewModel'

const D4DFeatureRow = () => {
  const vm = D4DFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Propelio Mobile / Driving for Dollars
        </PrimaryText>
        <SecondaryText>
          Track your Drive : Motivated Seller Displays : Send Mail : Skip Trace : Property Research
        </SecondaryText>
      </VStack>
      {Array.map(vm, (value, i) => (
        <FeatureAvailability key={i} isAvailable={value} />
      ))}
    </Grid>
  )
}

export default D4DFeatureRow
