import { useActorRef, useSelector } from '@xstate/react'
import { Equal, Option, Runtime, pipe } from 'effect'
import { ErrorLib } from 'libs/errors'
import React, { PropsWithChildren, createContext, useRef } from 'react'
import DraggablePanelMachine from './DraggablePanelMachine'

const DEFAULT_PANEL_HEIGHT = 72 // in px

const Context = createContext<Option.Option<DraggablePanelMachine.ActorRef>>(Option.none())

/**
 * Can be used with a standalone actor setup or combined parent
 * actor provider where the reference can be obtained from actor.children
 *
 * @sample
 *
 * const Provider = ({ children, actorRef }: PropsWithChildren<{
 *   actorRef: Option.Option<ParentMachine.ActorRef>
 * }>) => {
 *   const childActorRef = useActorRef(
 *     actorRef,
 *     snapshot => Option.fromNullable(
 *       snapshot.children[DraggablePanelMachine.DefaultActorId] as DraggablePanelMachine.ActorRef
 *     ),
 *   )
 *   return (
 *     <Context.Provider value={actorRef}>
 *       <DraggablePanelContext.Provider={childActorRef}>
 *         {children}
 *       </DraggablePanelContext.Provider>
 *     </Context.Provider>
 *   )
 * }
 */
const Provider = ({ children, actorRef }: PropsWithChildren<{
  actorRef: Option.Option<DraggablePanelMachine.ActorRef>
}>) => (
  <Context.Provider value={actorRef}>
    {children}
  </Context.Provider>
)

/**
 * Used for using actor/machine as a standalone
 *
 * @sample
 * const runtime = ...
 * const actorRef = useActorRefWithRuntime({ runtime })
 * <DraggablePanelContext.Provider actorRef={Option.some(actorRef)} />
 */
const useActorRefWithRuntime = (params: {
  runtime: Runtime.Runtime<never>
  panelHeight?: number
}) => {
  const machineRef = useRef<DraggablePanelMachine.ActorLogic>()

  if (!machineRef.current) {
    machineRef.current = DraggablePanelMachine.make().pipe(
      ErrorLib.tapCauseReporter,
      Runtime.runSync(params.runtime),
    )
  }

  return useActorRef(machineRef.current, {
    input: {
      parentRef: Option.none(),
      panelHeight: pipe(
        Option.fromNullable(params.panelHeight),
        Option.getOrElse(() => DEFAULT_PANEL_HEIGHT),
      ),
    },
  })
}

const useActorRefFromContext = () => {
  const context = React.useContext(Context)
  return Option.getOrElse(context, () => {
    throw new Error('No DraggablePanelMachine Provider')
  })
}

const useActorState = () => {
  const actorRef = useActorRefFromContext()
  return useSelector(
    actorRef,
    snapshot => snapshot.value,
    Equal.equals,
  )
}

const useActorSnapshot = () => {
  const actorRef = useActorRefFromContext()
  return actorRef.getSnapshot()
}

const useActorContext = () => {
  const actorRef = useActorRefFromContext()
  return useSelector(
    actorRef,
    snapshot => snapshot.context,
    Equal.equals,
  )
}

const DraggablePanelContext = {
  Provider,
  useActorState,
  useActorContext,
  useActorSnapshot,
  useActorRefWithRuntime,
  useActorRef: useActorRefFromContext,
  defaultPanelHeight: DEFAULT_PANEL_HEIGHT,
}

export default DraggablePanelContext
