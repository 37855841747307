export type PersonName = {
  first: string
  last: string
  full: string
}

export namespace PersonName {
  type Combine = {
    (first?: string | null, last?: string | null): string | null
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    (first?: string | null, middle?: string | null, last?: string | null): string | null
    (name: PersonName): string | null
  }

  const combineArrayOfStrings = (strings: (string | null | undefined)[]) =>
    strings.filter(Boolean).join(' ') || null

  export const combine: Combine = (firstOrPersonName?: string | null | PersonName, last?: string | null) =>
    firstOrPersonName instanceof Object
      ? (
        combineArrayOfStrings([firstOrPersonName.first, firstOrPersonName.last])
        || firstOrPersonName.full
      )
      : combineArrayOfStrings([firstOrPersonName, last])

  export const combineOrFull = (name: PersonName) =>
    combine(name) || name.full || null
}
