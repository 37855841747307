import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'
import { TriangleExclamationIcon } from 'presentation/components/Icons'
import { CardButton, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { openTypeToConfirmModal } from 'presentation/main/globalModals/TypeToConfirmModal/TypeToConfirmModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'

export type OpenCancelSubscriptionModalProps = {
  alertMessage?: string
  onCancel?: () => void
}

export const openCancelSubscriptionModal = ({
  alertMessage,
  onCancel,
}: OpenCancelSubscriptionModalProps): void => {
  openTypeToConfirmModal({
    title: 'Cancel Plan',
    wordToType: 'DELETE',
    header: (
      <>
        <CardPrimaryTitle>
          Are you sure you want to delete your account?
        </CardPrimaryTitle>
        <CardPrimaryText>
          By cancelling your plan in this account, all of the access will be revoked,
          and all of your account information, including skip data, websites,
          saved properties and more will be permanently deleted.
        </CardPrimaryText>
      </>
    ),
    body: (
      <>
        {alertMessage && (
          <Alert colorScheme='negative' mt={mbp({ mobSm: 2, tabSm: 3 })}>
            <AlertIcon>
              <TriangleExclamationIcon />
            </AlertIcon>
            <AlertDescription>
              {alertMessage}
            </AlertDescription>
          </Alert>
        )}
      </>
    ),
    footer: ({ isValid, onClose, isLoading, isDisabled }) => (
      <>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          onClick={onClose}
          isDisabled={isDisabled || isLoading}
        >
          Keep My Account
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='negative'
          onClick={onCancel}
          isDisabled={!isValid || isDisabled}
          isLoading={isLoading}
          loadingText='Cancelling'
        >
          Cancel Plan
        </CardButton>
      </>
    ),
  })
}
