import { Box, ButtonProps, Center, Checkbox, Flex, HStack, IconButton, Spinner, Text } from '@chakra-ui/react'
import assert from 'assert'
import { Match, Option } from 'effect'
import NearbyBuyerHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerHooks'
import NearbyBuyerMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerMachine'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { BuyerHistoryMap, BuyerHistoryTable, FlipperPill, LandlordPill, PropCountPill, PurchasesPopover } from 'features/NearbyBuyers/views/NearbyBuyersItem/NearbyBuyersItem'
import { Address } from 'features/valueObjects/Address'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { Card } from 'presentation/components/molecules/Card'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import AppRouterContext from 'presentation/main/Router/AppRouterContext'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'

const NearbyBuyersHistoryMobileScreen = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  const [activeTab, setActiveTab] = useState(0)
  const buyerId = AppRouterContext.useRouter().state.location.state.payload.buyerId

  assert(typeof buyerId === 'string', 'buyerId must be a string')

  const actorRef = NearbyBuyersHooks.useBuyerActorRef(buyerId)

  assert(Option.isSome(actorRef), 'actorRef must be Some')

  const closeModal = NearbyBuyerHooks.useCloseMobileBuyerHistoryModal(actorRef.value)
  const shouldDisplayPropertiesOutsideLocation = NearbyBuyerHooks.useShouldDisplayPropertiesOutsideLocation(actorRef.value)
  const toggleDisplayPropertiesOutsideLocation = NearbyBuyerHooks.useToggleDisplayPropertiesOutsideLocation(actorRef.value)
  const isBuyerHistoryLoading = NearbyBuyerHooks.useIsBuyerHistoryLoading(actorRef.value)

  return (
    <Box bgColor='special.500' h={height} minH={0}>
      <HStack>
        <Flex
          key='back-button'
          p={1}
          minH={6.5}
        >
          <BackButton onClick={closeModal} />
        </Flex>

        <Flex
          key='include-checkbox'
          gap={1}
          ml='auto'
          h={5}
          alignItems='center'
          p={1}
        >
          <Text
            textStyle='bodySFat'
            color='ondark.2'
          >
            <Breakpoint
              mobSm={(
                <>
                  Include props
                  <br />
                  outside location
                </>
              )}
              mob='Include props outside location'
            />
          </Text>
          <Checkbox
            isChecked={shouldDisplayPropertiesOutsideLocation}
            onChange={toggleDisplayPropertiesOutsideLocation}
          />
        </Flex>

      </HStack>
      <BaseTabsContainer
        colorScheme='xweak'
        size='sm'
        h='full'
        display='flex'
        flexDirection='column'
      >
        <BaseTabs>
          <BaseTab
            isActive={activeTab === 0}
            onClick={() => setActiveTab(0)}
          >
            List
          </BaseTab>
          <BaseTab
            isActive={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            Map
          </BaseTab>
        </BaseTabs>
        <BaseTabContent
          p={mbp({ mobSm: 1, mob: 2 })}
          flex='1'
          borderBottomRadius='0 !important'
        >
          {isBuyerHistoryLoading
            ? (
              <Center h='full'><Spinner /></Center>
            )
            : Match.value(activeTab).pipe(
              Match.when(0, () => (
                <ListContent actorRef={actorRef.value} />
              )),
              Match.when(1, () => (
                <BuyerHistoryMap actorRef={actorRef.value} />
              )),
              Match.orElse(() => null),
            )}
        </BaseTabContent>
      </BaseTabsContainer>
    </Box>
  )
}

export default NearbyBuyersHistoryMobileScreen

const ListContent = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => (
  <Flex
    h='full'
    flexDir='column'
    gap='2'
  >
    <BuyerInfo actorRef={actorRef} />
    <Breakpoint
      mobSm={(
        <BuyerHistoryTable
          actorRef={actorRef}
          flex='1'
          overflow='auto'
          shadow='primary.w'
        />
      )}
      mob={(
        <BuyerHistoryTable
          actorRef={actorRef}
          flex='1'
          overflow='auto'
          shadow='primary.w'
        />
      )}
    />
  </Flex>
)

const BuyerInfo = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const buyer = NearbyBuyerHooks.useBuyer(actorRef)
  const openPropertyModal = NearbyBuyersHooks.useOpenPropertyModal()

  return (
    <Card
      size='xxs-locked'
      w='full'
      minW={0}
      borderWidth={0.25}
      pr={1}
    >
      <Flex
        gap={0.5}
        alignItems='center'
      >
        {/* <SkiptraceFailedIcon boxSize={3} color='negative.500' /> */}

        <Text
          textStyle='bodyLFat'
          color='graystrong.400'
          minW={0}
          isTruncated
        >
          {buyer.name.pipe(
            Option.getOrElse(() => 'Unknown Owner'),
          )}
        </Text>

        {buyer.buyerHistory.propertiesCount.pipe(
          Option.flatMap(Option.liftPredicate(count => count > 1)),
          Option.map(count => (
            <PropCountPill
              key='prop-count-pill'
              flexShrink={0}
            >
              {count}
            </PropCountPill>
          )),
          Option.getOrElse(() => null),
        )}
      </Flex>

      <Box
        key='buyer-address'
        textStyle='bodyM'
        color='link.500'
        mt={0.5}
      >
        {buyer.address.pipe(
          Option.map(address => {
            const line1 = address.line1
            const cityStateZip = Address.formatCityStateZip(address)

            return (
              <>
                <Text color='inherit' title={line1} isTruncated>
                  <span
                    role='button'
                    onClick={() => openPropertyModal({ address })}
                  >
                    {line1}
                  </span>
                </Text>
                <Text color='inherit' title={cityStateZip} isTruncated>
                  <span
                    role='button'
                    onClick={() => openPropertyModal({ address })}
                  >
                    {cityStateZip}
                  </span>
                </Text>
              </>
            )
          }),
          Option.getOrElse(() => (
            <>
              <Text color='inherit'>--</Text>
              <Text color='inherit'>--</Text>
            </>
          )),
        )}
      </Box>

      {buyer.buyerType.pipe(
        Option.map(types => (
          <HStack mt={1} key='pills' spacing={0.5}>
            {/**
              * @NOTE: Bonus feature
              * {types.includes('neighbor') && (
              *   <NeighborPill />
              * )}
              */}
            {types.includes('landlord') && (
              <LandlordPill />
            )}
            {types.includes('flipper') && (
              <FlipperPill />
            )}
          </HStack>
        )),
        Option.getOrNull,
      )}

      <Flex gap={1} alignItems='baseline' mt={2}>
        <Text textStyle='tagL' color='graystrong.200'>
          PURCHASES:
        </Text>
        <Text textStyle='bodyM' color='link.500'>
          <PurchasesPopover actorRef={actorRef} />
        </Text>
      </Flex>
    </Card>
  )
}

const BackButton = (props: ButtonProps) => (
  <Flex
    alignItems='center'
    gap={1}
  >
    <IconButton
      aria-label='Save to List'
      size='md'
      key='right-toolbar-item'
      variant='icon-ghost'
      colorScheme='ondark'
      {...props}
    >
      <ArrowLeftIcon />
    </IconButton>

    <Box
      color='ondark.2'
      textStyle={mbp({
        mobSm: 'bodyMFat',
        mob: 'bodyXLFat',
      })}
    >
      Preview List
    </Box>
  </Flex>
)
