import { Text } from '@chakra-ui/react'
import { SelectListState, useStoreActionsDeprecated } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'

export const ListItemsCount = () => {
  const actionsApi = useStoreActionsDeprecated()
  const selectListState = actionsApi.selectList.state

  if (!SelectListState.$is('Success')(selectListState)) return null

  return (
    <Text textStyle='bodyM' color='ondark.6'>
      {NumberLib.formatComma(selectListState.list.memberCount)}
      {' '}
      {pluralize('property', selectListState.list.memberCount)}
    </Text>
  )
}
