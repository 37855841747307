import { Box, Tbody } from '@chakra-ui/react'
import { usePropertyModalContext } from 'presentation/components/PropertyModal/PropertyModalContext'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { CompsTable, CompsTableContainer } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable.components'
import { CompRow } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompRow'
import { CompTableHeaderRow } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompTableHeaderRow'
import { COMPS_STICKY_ROWS_CONTAINER_ID, COMPS_STICKY_ROWS_VISIBLE_CLASS } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompsStickyRows const'
import { useEntriesTableStore } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { CompsTableStickyOverreachFix } from 'presentation/screens/CompsScreen/hooks/CompsTableStickyOverreachFix'
import { useTrackStickyHeight } from 'presentation/screens/CompsScreen/hooks/CompsTableViewScroller'
import { px } from 'presentation/utils/px'
import { useRef } from 'react'
import { useIntersection } from 'react-use'

/**
 * @IMPORTANT Used for both sticky and non sticky version, don't add styles here
 * that would break either
 */
export const CompsStickyRows = () => {
  const { isPropertyModal } = usePropertyModalContext()
  const offset = useLayoutStore(store => {
    if (isPropertyModal) return 0
    const tabLinksHeight = store.headerElements.propertyLayoutTabLinks?.height ?? 0
    const spacerHeight = store.headerElements.propertyLayoutSpacer?.height ?? 0

    return store.totalHeaderHeight - (tabLinksHeight + spacerHeight + 1) // +1 as workaround for gap issue
  })
  const intersectionRef = useRef<HTMLDivElement>(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `${offset * -1}px 0px 0px 0px`,
    threshold: 1,
  })

  const isOverreaching = CompsTableStickyOverreachFix.useStore(store => store.isOverreaching)
  const shouldDisplaySticky = !!intersection
    && intersection.boundingClientRect.y - offset <= 0
    && !isOverreaching
  const { layoutWidth } = useLayoutWidth()
  const rows = useEntriesTableStore(store => store.rows)
  const subjectRow = rows[0]
  const stickyHeightTrackerRef = useTrackStickyHeight<HTMLDivElement>()

  CompsTableStickyOverreachFix.useSetupScrollListener()

  return (
    <>
      <Box ref={intersectionRef} w='full' />
      <Box
        /** Needed to target styles on sub-elements for sticky hacks */
        id={COMPS_STICKY_ROWS_CONTAINER_ID}
        className={shouldDisplaySticky ? COMPS_STICKY_ROWS_VISIBLE_CLASS : ''}
        ref={ref => {
          if (!ref) return
          stickyHeightTrackerRef(ref)
          CompsTableStickyOverreachFix.setStickyElement(ref)
        }}
        position='fixed'
        zIndex='sticky-1'
        top={px(offset)}
        w={layoutWidth}
        /** We want to render it no matter what, in order to get height measurement */
        {...!shouldDisplaySticky && {
          visibility: 'hidden',
          pointerEvents: 'none',
        }}
      >
        <CompsTableContainer
          sx={{
            '&': {
              msOverflowStyle: 'none', /* Microsoft Edge */
              scrollbarWidth: 'none', /* Firefox */
            },
            '&::-webkit-scrollbar': {
              display: 'none', /* Safari and Chrome */
            },
          }}
        >
          <CompsTable>
            <CompTableHeaderRow
              sx={{
                '& th:first-of-type': {
                  borderTopLeftRadius: 0,
                },
                '& th:last-of-type': {
                  borderTopRightRadius: 0,
                },
              }}
            />
            <Tbody>
              <CompRow
                key={subjectRow.comp.id}
                rowData={subjectRow}
              />
            </Tbody>
          </CompsTable>
        </CompsTableContainer>
      </Box>
    </>
  )
}
