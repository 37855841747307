import { BoxProps, Button } from '@chakra-ui/react'
import { BaseTabsTopLeftCurveNegative } from 'presentation/components/BaseTabs/BaseTabs'
import { SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { EquityFiltersContent } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/EquityFiltersContent'
import { ListFilterTabButtons } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/ListFilterTabButtons'
import { LocationFiltersContent } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/LocationFiltersContent'
import { OwnershipFiltersContent } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/OwnershipFiltersContent'
import { PropertyFilterContent } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertyFiltersContent'
import { StressIndicatorFiltersContent } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/StressIndicatorFiltersContent'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const PropertiesFiltersTabs = (props: BoxProps) => (
  <SwitchTabsWrapper
    display='flex'
    flexDirection='column'
    sx={{
      /** Hide for now until we have to handle how this behaves on responsive */
      [`.${BaseTabsTopLeftCurveNegative.CLASS_NAME}`]: {
        display: mbp({
          mobSm: 'none',
          dtSm: 'initial',
        }),
      },
    }}
    size='sm'
    {...props}
  >
    <ListFilterTabButtons />

    <SwitchTabContents
      flex='1'
      display='flex'
      flexDirection='column'
    >
      <FilterTabContent>
        <LocationFiltersContent />
      </FilterTabContent>

      {/* <FilterTabContent>
        <LandFilterContent />
      </FilterTabContent> */}

      <FilterTabContent>
        <PropertyFilterContent />
      </FilterTabContent>

      <FilterTabContent>
        <OwnershipFiltersContent />
      </FilterTabContent>

      <FilterTabContent>
        <StressIndicatorFiltersContent />
      </FilterTabContent>

      <FilterTabContent>
        <EquityFiltersContent />
      </FilterTabContent>

      {/* <FilterTabContent>
        <MLSFiltersContent />
      </FilterTabContent> */}
    </SwitchTabContents>
  </SwitchTabsWrapper>
)

const FilterTabContent = ({ children, ...props }: BoxProps) => (
  <SwitchTabContent
    flex='1'
    as='form'
    onSubmit={ev => {
      ev.preventDefault()
      if (
        document.activeElement
        && 'blur' in document.activeElement
        && typeof document.activeElement.blur === 'function'
      )
        document.activeElement.blur()
    }}
    {...props}
  >
    {children}
    <Button
      type='submit'
      display='none'
    />
  </SwitchTabContent>
)
