import { BoxProps, Link } from '@chakra-ui/react'
import { CardFootnote } from 'presentation/components/molecules/Card'
import { LEGAL_PRIVACY_URL } from 'presentation/const/env.const'
import { FC, PropsWithChildren } from 'react'

export type TermsAndPolicyProps = PropsWithChildren<BoxProps>

export const TermsAndPolicy: FC<TermsAndPolicyProps> = ({ children, ...props }) => (
  <CardFootnote {...props}>
    You authorize Propelio to charge you for the amount above.&nbsp;
    {children}
    By clicking the button below you understand and agree to these terms and Propelio&rsquo;s
    &nbsp;
    <Link color='link' href={LEGAL_PRIVACY_URL} isExternal>PRIVACY POLICY</Link>
    {' '}
    and
    &nbsp;
    <Link color='link' href='/termsofuse' isExternal>TERMS OF SERVICE</Link>
    .
  </CardFootnote>
)
