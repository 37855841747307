import { CMA } from 'features/CMA/CMA.domain'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { ErrorLib, ReportableException } from 'libs/errors'
import { nanoid } from 'nanoid'
import { Miles } from 'presentation/screens/CompsScreen/components/common/Miles'
import { createContext, useContext, useRef } from 'react'
import { pathOr, setPath } from 'remeda'
import { create, useStore } from 'zustand'

export type CMADeps = {
  initializeLoadReport: (payload: CMA.InitializeLoadReportPayload) => Promise<CMA.InitializeLoadReportResult>
  loadCMA: (leadId: CMA.LoadReportPayload) => Promise<CMA.LoadReportResult>
  rateComp: (params: CMA.RateCompPayload) => Promise<CMA.RateCompResult>
  filterComps: (params: CMA.FilterCompsPayload) => Promise<CMA.FilterCompsResult>
  updateEstimateInfo: (params: CMA.UpdateEstimateInfoPayload) => Promise<CMA.UpdateEstimateInfoResult>
  getCoverageInfo: () => Promise<CMA.CoverageInfo>
}

export type CMAState = {
  local: {
    report: { status: 'initial' }
    | { status: 'initiating', leadId: string }
    | { status: 'initiated', consumablesInfo: ConsumableAttempt }
    | { status: 'loading', leadId: string }
    | {
      status: 'loaded' | 'filtering' | 'regenerating'
      hasData: true
      leadId: string
      data: CMA.Report
    }
    | { status: 'no-coverage', leadId: string, data: CMA.Comp.Address }
    | { status: 'no-subj-location', leadId: string, data: CMA.Comp.Address }
    | { status: 'error', leadId: string, error: unknown }

    coverageInfo: { status: 'initial' }
    | { status: 'loading' }
    | { status: 'loaded', data: CMA.CoverageInfo }
    | { status: 'error' }
  }
  actions: {
    initializeLoadReport: {
      state: { lastRequestId: string | null }
      execute: (leadId: string) => Promise<void>
    }
    loadReport: {
      state: { lastRequestId: string | null }
      execute: (params: CMA.LoadReportPayload) => Promise<void>
    }
    /** @NOTE Updates optimistically */
    rateComp: {
      state: {
        lastError: {
          compId: string
          requestedRating: CMA.Comp.UserRating
          originalRating: CMA.Comp.UserRating
        } | null
      }
      execute: (params: Omit<CMA.RateCompPayload, 'reportId' | 'leadId'>) => Promise<void>
    }
    filterComps: {
      state: { lastRequestId: string | null }
      execute: (filters: Partial<CMA.Filters>) => Promise<void>
    }
    /** @NOTE Updates optimistically */
    updateEstimateInfo: {
      state: {
        lastError: {
          type: CMA.ListType
          requestedEstimateInfo: CMA.EstimateInfo
          originalEstimateInfo: CMA.EstimateInfo
        } | null
      }
      execute: (params: {
        type: CMA.ListType
        estimateInfo: CMA.EstimateInfo
      }) => Promise<void>
    }

    /** This simply calls the filterComps dependency */
    regenerateReport: {
      execute: () => Promise<void>
    }

    getCoverageInfo: {
      execute: () => Promise<void>
    }

    resetReport: {
      execute: () => void
    }
  }
}

export const createCMAStore = (deps: CMADeps) => create<CMAState>((set, get) => {
  const setReportState = (reportState: CMAState['local']['report']) =>
    set(state => setPath(
      state,
      ['local', 'report'],
      reportState,
    ))

  const initializeLoadReportAction: CMAState['actions']['initializeLoadReport'] = {
    state: { lastRequestId: null },
    execute: async leadId => {
      const requestId = nanoid()

      // Set initial state to initiating
      setReportState({ status: 'initiating', leadId })

      set(state => setPath(
        state,
        ['actions', 'initializeLoadReport', 'state', 'lastRequestId'],
        requestId,
      ))

      return await deps.initializeLoadReport(leadId)
        .then(result => {
          // Check if we got a LoadReportResultSuccess and transition to 'loaded'
          if (result.status === 'success') {
            return {
              status: 'loaded' as const,
              hasData: true as const,
              leadId,
              data: result.report,
            }
          }

          if (result.status === 'no-coverage') {
            return {
              status: 'no-coverage' as const,
              leadId,
              data: result.address,
            }
          }

          // Otherwise, we have an InitializeLoadReportInitialized
          return {
            status: 'initiated' as const,
            consumablesInfo: result.consumableAttempt,
          }
        })
        .catch(error => ({ status: 'error' as const, leadId, error }))
        .then(nextState => {
          const currentState = get().actions.initializeLoadReport.state
          const isOutdated = currentState.lastRequestId !== requestId
          if (isOutdated) return

          setReportState(nextState)
        })
    },
  }

  const loadReportAction: CMAState['actions']['loadReport'] = {
    state: { lastRequestId: null },
    execute: async params => {
      const requestId = nanoid()
      const loadingState = { status: 'loading', leadId: params.id } as const

      setReportState(loadingState)
      set(state => setPath(
        state,
        ['actions', 'loadReport', 'state', 'lastRequestId'],
        requestId,
      ))

      const expandAndLoadUntilResult = async ({
        leadId,
        reportId,
        filters,
      }: CMA.FilterCompsPayload): Promise<CMA.Report> => {
        const result = await deps.filterComps({
          filters,
          leadId,
          reportId,
        })

        const resultsCount = result.salesListInfo.comps.length

        if (resultsCount > 0)
          return result

        const nextRadius = Miles.doubleWithinPreset(filters.distanceMiles.max)

        if (nextRadius === filters.distanceMiles.max)
          return result

        const expandedFilters: CMA.Filters = {
          ...filters,
          distanceMiles: {
            max: Miles.doubleWithinPreset(filters.distanceMiles.max),
            subdivision: filters.distanceMiles.subdivision || null,
          },
        }

        return await expandAndLoadUntilResult({
          leadId,
          reportId,
          filters: expandedFilters,
        })
      }

      return await deps.loadCMA(params)
        .then(async (initialData): Promise<CMAState['local']['report']> => {
          if (initialData.status === 'no-subj-location')
            return { status: 'no-subj-location' as const, data: initialData.address, leadId: params.id }

          if (initialData.status === 'no-coverage')
            return { status: 'no-coverage' as const, data: initialData.address, leadId: params.id }

          const resultsCount = initialData.report.salesListInfo.comps.length

          const data = resultsCount > 0
            ? initialData.report
            : await expandAndLoadUntilResult({
              leadId: params.id,
              reportId: initialData.report.reportId,
              filters: {
                ...initialData.report.filters,
                distanceMiles: {
                  max: Miles.doubleWithinPreset(initialData.report.filters.distanceMiles.max),
                  subdivision: initialData.report.filters.distanceMiles.subdivision || null,
                },
              },
            })

          return {
            hasData: true,
            status: 'loaded' as const,
            leadId: params.id,
            data,
          }
        })
        .catch(error => ({ status: 'error' as const, leadId: params.id, error }))
        .then(nextState => {
          const currentState = get().actions.loadReport.state
          const isOutdated = currentState.lastRequestId !== requestId
          if (isOutdated) return
          setReportState(nextState)
        })
    },
  }

  const rateCompAction: CMAState['actions']['rateComp'] = {
    state: { lastError: null },
    execute: async params => {
      let originalRating: CMA.Comp.UserRating | null = null
      let compPath: (string | number)[] | null = null

      try {
        const reportState = get().local.report

        if (!checkStateHasReportData(reportState)) return

        // =============================================================================
        // Figure out where the comp is in the report
        // =============================================================================

        const salesListInfo = reportState.data.salesListInfo
        const rentalsListInfo = reportState.data.rentalsListInfo

        type CompMidPath =
          | ['salesListInfo', 'subject']
          | ['rentalsListInfo', 'subject']
          | ['salesListInfo', 'comps', number]
          | ['rentalsListInfo', 'comps', number]
        let compMidPath: CompMidPath | null = null

        if (salesListInfo.subject.id === params.compId)
          compMidPath = ['salesListInfo', 'subject']

        if (rentalsListInfo.subject.id === params.compId)
          compMidPath = ['rentalsListInfo', 'subject']

        const salesIndex = compMidPath ? -1 : salesListInfo.comps.findIndex(sale => sale.id === params.compId)

        if (salesIndex !== -1)
          compMidPath = ['salesListInfo', 'comps', salesIndex]

        const rentalsIndex = compMidPath ? -1 : rentalsListInfo.comps.findIndex(sale => sale.id === params.compId)

        if (rentalsIndex !== -1)
          compMidPath = ['rentalsListInfo', 'comps', rentalsIndex]

        // =============================================================================
        // Throw if we couldn't find the comp in the report
        // =============================================================================

        if (!compMidPath) {
          const error = new ReportableException('Could not find comp in report', {
            extraInfo: {
              report: reportState.data,
              compId: params.compId,
            },
          })
          void ErrorLib.report(error)
          throw error
        }

        // =============================================================================
        // Update optimistically
        // =============================================================================

        compPath = [
          'local',
          'report',
          'data',
          ...compMidPath,
          'userRating',
        ]

        originalRating = pathOr(get() as any, compPath as any, null as any)

        if (!originalRating) {
          const error = new ReportableException('Could not find comp in report', {
            extraInfo: {
              report: reportState.data,
              compId: params.compId,
            },
          })
          void ErrorLib.report(error)
          throw error
        }

        set(state => {
          const report = state.local.report

          if (!checkStateHasReportData(report)) return state

          return setPath(
            state,
            compPath as any,
            params.rating as any,
          )
        })

        // =============================================================================
        // Call server
        // =============================================================================

        await deps.rateComp({
          ...params,
          leadId: reportState.data.leadId,
          reportId: reportState.data.reportId,
        })

      // =============================================================================
      // Handle errors
      // =============================================================================
      } catch (error) {
        if (originalRating === null) return

        // revert rating
        set(state => {
          const report = state.local.report

          if (report.status !== 'loaded') return state

          return setPath(
            state,
            compPath as any,
            originalRating as any,
          )
        })

        // record error
        set(state => setPath(
          state,
          ['actions', 'rateComp', 'state', 'lastError'],
          {
            compId: params.compId,
            requestedRating: params.rating,
            originalRating: originalRating as any,
          },
        ))
      }
    },
  }

  const filterCompsAction: CMAState['actions']['filterComps'] = {
    state: { lastRequestId: null },
    execute: async filtersParam => {
      const reportState = get().local.report

      if (!checkStateHasReportData(reportState)) return

      const requestId = nanoid()
      const currentFilters = reportState.data.filters
      const updatedFilters = { ...currentFilters, ...filtersParam }
      const updatedData = setPath(
        reportState.data,
        ['filters'],
        updatedFilters,
      )
      const filteringState = {
        hasData: true,
        status: 'filtering',
        leadId: reportState.leadId,
        data: updatedData,
      } as const

      setReportState(filteringState)
      set(state => setPath(
        state,
        ['actions', 'filterComps', 'state', 'lastRequestId'],
        requestId,
      ))

      await deps.filterComps({
        filters: updatedFilters,
        leadId: reportState.data.leadId,
        reportId: reportState.data.reportId,
      })
        .then(data => ({
          hasData: true,
          status: 'loaded',
          leadId: reportState.leadId,
          data,
        } as const))
        .catch(error => ({ status: 'error' as const, leadId: reportState.leadId, error }))
        .then(nextState => {
          const currentState = get().actions.filterComps.state
          const isOutdated = currentState.lastRequestId !== requestId
          if (isOutdated) return
          setReportState(nextState)
        })
    },
  }

  const updateEstimateInfoAction: CMAState['actions']['updateEstimateInfo'] = {
    state: { lastError: null },
    execute: async ({
      type,
      estimateInfo: estimateInfoParam,
    }) => {
      const reportState = get().local.report

      if (!checkStateHasReportData(reportState)) return

      const typePath = type === 'sales' ? 'salesListInfo' : 'rentalsListInfo'
      const oldEstimateInfo = reportState.data[typePath].estimateInfo

      setReportState({
        hasData: true,
        status: 'loaded',
        leadId: reportState.leadId,
        data: type === 'sales'
          ? setPath(
            reportState.data,
            ['salesListInfo', 'estimateInfo'],
            estimateInfoParam,
          )
          : setPath(
            reportState.data,
            ['rentalsListInfo', 'estimateInfo'],
            estimateInfoParam,
          ),
      })

      await deps.updateEstimateInfo({
        estimateInfo: estimateInfoParam,
        type,
        leadId: reportState.data.leadId,
        reportId: reportState.data.reportId,
      })
        .catch(() => {
          // revert estimate info
          setReportState({
            hasData: true,
            status: 'loaded',
            leadId: reportState.leadId,
            data: type === 'sales'
              ? setPath(
                reportState.data,
                ['salesListInfo', 'estimateInfo'],
                oldEstimateInfo,
              )
              : setPath(
                reportState.data,
                ['rentalsListInfo', 'estimateInfo'],
                oldEstimateInfo,
              ),
          })

          // record error
          set(state => setPath(
            state,
            ['actions', 'updateEstimateInfo', 'state', 'lastError'],
            { type, requestedEstimateInfo: estimateInfoParam, originalEstimateInfo: oldEstimateInfo },
          ))
        })
    },
  }

  const regenerateReportAction: CMAState['actions']['regenerateReport'] = {
    execute: async () => {
      const reportState = get().local.report

      if (!checkStateHasReportData(reportState)) return

      const regeneratingState = {
        hasData: true,
        status: 'regenerating',
        leadId: reportState.leadId,
        data: reportState.data,
      } as const

      setReportState(regeneratingState)

      await deps.filterComps({
        filters: reportState.data.filters,
        leadId: reportState.data.leadId,
        reportId: reportState.data.reportId,
      })
        .then(data => ({
          hasData: true,
          status: 'loaded',
          leadId: reportState.leadId,
          data,
        } as const))
        .catch(error => ({ status: 'error' as const, leadId: reportState.leadId, error }))
        .then(nextState => {
          const currentLoadedReport = get().local.report
          /** @TODO This code path (outdated checking) is not covered */
          if (!checkStateHasReportData(currentLoadedReport)) return

          const latestLeadId = currentLoadedReport.leadId
          /** @TODO This code path (outdated checking) is not covered */
          if (latestLeadId !== reportState.leadId) return

          setReportState(nextState)
        })
    },
  }

  const getCoverageInfoAction: CMAState['actions']['getCoverageInfo'] = {
    execute: async () => {
      // DO NOT RE-REQUEST IF LOADING OR LOADED
      const coverageInfoState = get().local.coverageInfo
      if (coverageInfoState.status === 'loading' || coverageInfoState.status === 'loaded')
        return

      set(state => setPath(
        state,
        ['local', 'coverageInfo'],
        { status: 'loading' },
      ))

      await deps.getCoverageInfo()
        .then(data => ({ status: 'loaded', data } as const))
        .catch(() => ({ status: 'error' as const }))
        .then(nextState => {
          set(state => setPath(
            state,
            ['local', 'coverageInfo'],
            nextState,
          ))
        })
    },
  }

  const resetReportAction = {
    execute: () => {
      setReportState({ status: 'initial' })
    },
  }

  return {
    local: {
      report: { status: 'initial' },
      coverageInfo: { status: 'initial' },
      uiConfig: { status: 'initial' },
    },
    actions: {
      initializeLoadReport: initializeLoadReportAction,
      loadReport: loadReportAction,
      rateComp: rateCompAction,
      filterComps: filterCompsAction,
      updateEstimateInfo: updateEstimateInfoAction,
      regenerateReport: regenerateReportAction,
      getCoverageInfo: getCoverageInfoAction,
      resetReport: resetReportAction,
    },
  }
})

export type CMAStore = ReturnType<typeof createCMAStore>

const CMAContext = createContext<CMAStore | null>(null)

export type CMAProviderProps = CMADeps & {
  children: React.ReactNode
}

export const CMAProvider = ({
  children,
  ...deps
}: CMAProviderProps) => {
  const store = useRef(createCMAStore(deps)).current
  return (
    <CMAContext.Provider value={store}>
      {children}
    </CMAContext.Provider>
  )
}

// eslint-disable-next-line @stylistic/comma-dangle
export const useCMAStore = <T,>(
  selector: (store: CMAState) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(CMAContext)
  if (!store) throw new Error('useCMAStore must be used within a CMAProvider')
  return useStore(store, selector, equalityFn)
}

export const checkStateHasReportData = (
  report: CMAState['local']['report'],
): report is Extract<CMAState['local']['report'], { hasData: true }> =>
  report.status === 'loaded'
  || report.status === 'filtering'
  || report.status === 'regenerating'
