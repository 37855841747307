import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { create } from 'zustand'

type HashScrollStore = {
  lastRequest: {
    hash: string
    id: string
  } | null
  setLastRequest: (hash: string | null) => void
}

export const useHashScrollStore = create<HashScrollStore>(set => ({
  lastRequest: null,
  setLastRequest: (hash: string | null) => set(() => ({
    lastRequest: hash ? { hash, id: Date.now().toString() } : null,
  })),
}))

export const useHashScroll = () => {
  const location = useLocation()
  const lastRequest = useHashScrollStore(state => state.lastRequest)
  const currentHash = location.hash
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  useEffect(() => {
    if (lastRequest && lastRequest.hash !== currentHash) return

    const elem = currentHash
      ? document.querySelector(currentHash)
      : undefined

    if (elem) {
      window.scrollTo({
        top: elem.getBoundingClientRect().top + window.scrollY - headerHeight,
        behavior: 'smooth',
      })
    }

    useHashScrollStore.setState({ lastRequest: null })
  }, [currentHash, lastRequest])
}
