import { BoxProps, Table, TableContainer, Tbody, Td, Text, Th, Tr } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SummaryStatistics } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export type CompsReportTableProps = {
  report: SummaryStatistics
} & BoxProps

export const CMASummaryStatistics = ({ report, ...props }: CompsReportTableProps) => (
  <TableContainer
    overflowX='auto'
    {...props}
  >
    <Table
      layout={mbp({
        mobSm: 'fixed',
        dtLg: 'auto',
      })}
      minW='912px'
    >
      <Tbody
        sx={{
          /** Make last rows corner cells round */
          'tr:last-of-type td:first-of-type': {
            borderBottomLeftRadius: 2,
          },
          'tr:last-of-type td:last-of-type': {
            borderBottomRightRadius: 2,
          },
        }}
      >
        <TableSummaryHeaderRow />

        <TableSummaryRows
          rowLabel='Minimum'
          statisticKeys='min'
          {...report}
        />
        <TableSummaryRows
          rowLabel='Maximum'
          statisticKeys='max'
          {...report}
        />
        <TableSummaryRows
          rowLabel='Average'
          statisticKeys='avg'
          {...report}
        />
      </Tbody>
    </Table>
  </TableContainer>
)

export const BedsBathsGarageCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '72px',
      dtLg: 'auto',
    })}
    {...props}
  >
    <Breakpoint
      mobSm={(
        <>
          Bd / Ba
          <br />
          {' '}
          / Garage
        </>
      )}
      dtLg={(
        <>
          Beds / Baths
          <br />
          {' '}
          / Garage
        </>
      )}
    />
  </Th>
)

export const YearBuiltCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '52px',
      dtSm: '64px',
      dtLg: 'auto',
    })}
    overflowWrap='break-word'
    wordBreak='break-word'
    whiteSpace='normal'
    {...props}
  >
    Yr Built
  </Th>
)

export const SqftAcreCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '56px',
        dtSm: '64px',
        dtLg: 'auto',
      })}
      {...mbpg({
        mobSm: {
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        },
        dtLg: {
          whitespace: 'nowrap',
          overflowWrap: 'break-word',
          wordBreak: 'normal',
        },
      })}
      {...props}
    >
      <Breakpoint
        mobSm='Sqft/ Acre'
        dtLg='Sqft'
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th
          textAlign='right'
          w={mbp({
            mobSm: '56px',
            dtSm: '64px',
            dtLg: 'auto',
          })}
          overflowWrap='break-word'
          wordBreak='break-word'
          {...props}
        >
          Acre
        </Th>
      )}
    />
  </>
)

export const ListingPriceCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '96px',
        dtLg: 'auto',
      })}
      {...props}
    >
      <Breakpoint
        mobSm={(
          <>
            List Price
            <br />
            /LP Sqft
          </>
        )}
        dtLg={<>List Price</>}
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th textAlign='right' {...props}>
          LP Sqft
        </Th>
      )}
    />
  </>
)

export const SalePriceCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '96px',
        dtLg: 'auto',
      })}
      {...props}
    >
      <Breakpoint
        mobSm={(
          <>
            Sale Price
            <br />
            /LP Sqft
          </>
        )}
        dtLg={<>Sale Price</>}
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th textAlign='right' {...props}>
          SP Sqft
        </Th>
      )}
    />
  </>
)

export const DistanceCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '64px',
      dtLg: '72px',
    })}
    {...props}
  >
    <Breakpoint
      mobSm='Dist'
      dtLg='Distance'
    />
  </Th>
)

export const DomCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '52px',
      dtLg: '54px',
    })}
    {...props}
  >
    DOM
  </Th>
)

const TableSummaryHeaderRow = () => (
  <Tr
    sx={{
      'tr&:nth-of-type(even) > td, tr&:nth-of-type(odd) > td': {
        bg: 'neutral.400',
        borderBottomWidth: 0,
        borderRadius: 0,
      },
    }}
  >
    <Td w='96px'></Td>
    <BedsBathsGarageCell as={Td} />
    <YearBuiltCell as={Td} />
    <SqftAcreCell as={Td} />
    <ListingPriceCell as={Td} />
    <SalePriceCell as={Td} />
    <DistanceCell as={Td} />
    <DomCell as={Td} />
  </Tr>
)

type TableSummaryRowProps = SummaryStatistics & {
  rowLabel: string
  statisticKeys: 'min' | 'max' | 'avg'
}

const TableSummaryRows = (props: TableSummaryRowProps) => (
  <Tr
    h='56px'
    sx={{
      '& td': {
        color: 'graystrong.600',
      },
    }}
  >
    <Td>
      <Text textStyle='bodyLFat' color='graystrong.400'>
        {props.rowLabel}
      </Text>
    </Td>
    {/**
       * =================
       * BEDS / BATHS / GARAGE
       * =================
       */}
    <Td textAlign='right'>{props.bedsBathsGarage[props.statisticKeys]}</Td>
    {/**
       * =================
       * YEAR BUILT
       * =================
       */}
    <Td textAlign='right'>{props.yearBuilt[props.statisticKeys]}</Td>
    {/**
       * =================
       * LIVING AREA SQFT
       * =================
       */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.livingAreaSqft[props.statisticKeys]}
            <br />
            /
            {props.lotAreaAcres[props.statisticKeys]}
          </>
        )}
        dtLg={props.livingAreaSqft[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.lotAreaAcres[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
       * =================
       * LISTING PRICE
       * =================
       */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.listPrice[props.statisticKeys]}
            <br />
            /
            {props.listPricePerSqft[props.statisticKeys]}
          </>
        )}
        dtLg={props.listPrice[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.listPricePerSqft[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
     * =================
     * SELLING PRICE
     * =================
     */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.salePrice[props.statisticKeys]}
            <br />
            /
            {props.salePricePerSqft[props.statisticKeys]}
          </>
        )}
        dtLg={props.salePrice[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.salePricePerSqft[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
       * =================
       * DISTANCE
       * =================
       */}
    <Td textAlign='right'>{props.distance[props.statisticKeys]}</Td>

    {/**
       * =================
       * DAYS ON MARKET
       * =================
       */}
    <Td textAlign='right'>
      {props.daysOnMarket[props.statisticKeys]}
    </Td>
  </Tr>

)
