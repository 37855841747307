import { Box, Flex, FlexProps, Grid, GridItem, GridItemProps, GridProps, Portal, Text, chakra, useToken } from '@chakra-ui/react'
import { useSelector } from '@xstate/react'
import { createStore } from '@xstate/store'
import { Match, Option, Record, pipe } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { Breakpoint } from 'presentation/components/Breakpoint'
import DraggablePanel, { DraggablePanelBody, DraggablePanelHeader } from 'presentation/components/DraggablePanel/DraggablePanel'
import { DraggablePanelParts } from 'presentation/components/DraggablePanel/DraggablePanel.const'
import DraggablePanelContext from 'presentation/components/DraggablePanel/DraggablePanelContext'
import { ChevronsLeftIcon } from 'presentation/components/Icons'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const NearbyBuyersLayoutParts = {
  Toolbar: 'nearby-buyers-layout__toolbar' as const,
  Map: 'nearby-buyers-layout__map' as const,
  Panel: 'nearby-buyers-layout__panel' as const,
  DraggablePanel: 'nearby-buyers-layout__draggable-panel' as const,
  DraggablePanelHeader: 'nearby-buyers-layout__draggable-panel-header' as const,
  Address: 'nearby-buyers-layout__address' as const,
  FloatingAddress: 'nearby-buyers-layout__floating-address' as const,
}

const SIDE_PANEL_DRAWER_WIDTH = 572
const SIDE_PANEL_WIDTH = 604
const SIDEPANEL_TRANSITION_DURATION = 100

/**
 * @NOTE: might want to reuse on screens with same layout
 */
const NearbyBuyersLayout = ({ children }: PropsWithChildren) => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  // @TODO: Move to machine state. Might want to combine with draggable panel context
  const [isPanelExpanded, setPanelExpanded] = useState(false)
  const { sbp } = useSwitchBreakpointFn()
  const mode = useBodySwitchLayout()

  /**
   * @NOTE: This is temp. It is recommended to control DraggablePanel in
   * NearbyBuyersMachine
   */
  const draggablePanelActorRef = NearbyBuyersHooks.useDraggablePanelActorRef()

  const gridProps: GridProps = Match.value(mode).pipe(
    Match.when('with-sidepanel', (): GridProps => ({
      gridTemplateAreas: `
        "toolbar toolbar"
        "map panel"
      `,
      gridTemplateRows: 'minmax(0, 80px) minmax(0, 1fr)',
      gridTemplateColumns: `1fr auto`,
    })),
    Match.when('with-sidepanel-drawer', (): GridProps => ({
      gridTemplateAreas: `
        "toolbar toolbar"
        "map panel"
      `,
      gridTemplateRows: `minmax(0, ${sbp({ tabSm: px(56), dtSm: px(80) })}) minmax(0, 1fr)`,
      gridTemplateColumns: `1fr auto`,
    })),
    Match.when('with-draggable-bottom-panel', (): GridProps => ({
      gridTemplateAreas: `
        "toolbar"
        "map"
      `,
      gridTemplateRows: `minmax(0, ${sbp({ mobSm: px(48), mob: px(56) })}) 1fr`,
      gridTemplateColumns: `1fr`,
    })),
    Match.orElse(() => Record.empty()),
  )

  const toolbarProps: GridItemProps = Match.value(mode).pipe(
    Match.when('with-sidepanel', (): GridItemProps => ({
      py: 2,
      px: 3,
    })),
    Match.orElse(() => Record.empty()),
  )

  const mapGridItemProps: GridItemProps = Match.value(mode).pipe(
    Match.when('with-sidepanel-drawer', (): GridItemProps => ({
      position: 'relative',
    })),
    Match.orElse(() => Record.empty()),
  )

  const panelGridItemProps: GridItemProps = Match.value(mode).pipe(
    Match.when('with-sidepanel', (): GridItemProps => ({
      w: px(SIDE_PANEL_WIDTH),
      ml: -3,
      boxShadow: 'vscroll-sticky',
      zIndex: 3,
      position: 'relative',
    })),
    Match.when('with-sidepanel-drawer', (): GridItemProps => ({
      w: px(SIDE_PANEL_DRAWER_WIDTH),
      ml: -3,
      mr: isPanelExpanded ? '0px' : `-${px(SIDE_PANEL_DRAWER_WIDTH - 40)}`,
      zIndex: 3,
      position: 'relative',
      boxShadow: 'vscroll-sticky',
      transition: `margin-right ${SIDEPANEL_TRANSITION_DURATION}ms ease`,
    })),
    Match.when('with-draggable-bottom-panel', (): GridItemProps => ({
      position: 'relative',
    })),
    Match.orElse(() => Record.empty()),
  )
  return (
    <DraggablePanelContext.Provider
      actorRef={draggablePanelActorRef}
    >
      <Grid
        ref={setGridRef}
        h={height}
        minH={0}
        overflow='hidden'
        {...gridProps}
      >

        <GridItem
          gridArea='toolbar'
          bgColor='neutral.200'
          shadow='vscroll-sticky'
          display='flex'
          alignItems='center'
          gap={1}
          zIndex={4}
          {...toolbarProps}
        >
          <Box
            className={NearbyBuyersLayoutParts.Address}
            display={mode === 'with-sidepanel' ? 'block' : 'none'}
          />
          <Box
            className={NearbyBuyersLayoutParts.Toolbar}
            display='flex'
            alignItems='center'
            gap={1}
            flex='1'
            px={mbp({ mobSm: 1, tabSm: 3, dtLg: 0 })}
            py={1}
          />
        </GridItem>

        <GridItem
          gridArea='map'
          {...mapGridItemProps}
        >
          <Box h='full' className={NearbyBuyersLayoutParts.Map} />

          <Box
            className={NearbyBuyersLayoutParts.FloatingAddress}
            position='absolute'
            top={0}
            left={0}
            zIndex={2}
            px={3}
            py={1}
            bgColor='neutral.200'
            w='240px'
            borderBottomRightRadius={4}
            shadow='vscroll-sticky'
            display={mode === 'with-sidepanel-drawer' ? 'block' : 'none'}
          />

        </GridItem>

        <GridItem
          gridArea='panel'
          {...panelGridItemProps}
        >
          {mode === 'with-sidepanel-drawer' && (
            <SidePanelButton
              isPanelExpanded={isPanelExpanded}
              onClick={() => setPanelExpanded(state => !state)}
            />
          )}

          <Box
            className={NearbyBuyersLayoutParts.Panel}
            position='absolute'
            top='0'
            left='0'
            bottom='0'
            right='0'
            zIndex={2}
            bgColor='accent.hover'
            borderBottomLeftRadius={3}
          />
        </GridItem>

        <Portal>
          <DraggablePanelArea
            zIndex='modal'
            display={mode === 'with-draggable-bottom-panel' ? 'flex' : 'none'}
          />
        </Portal>

        {children}
      </Grid>
    </DraggablePanelContext.Provider>
  )
}

export default NearbyBuyersLayout

const DraggablePanelArea = (props: FlexProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const backLabel = sbp({
    mobSm: 'Map',
    mob: 'Back to Map',
  })
  return (
    <DraggablePanel {...props}>
      <DraggablePanelHeader
        ref={setDraggableHeaderRef}
        backLabel={backLabel}
      />
      <DraggablePanelBody />
    </DraggablePanel>
  )
}

NearbyBuyersLayout.Toolbar = ({ children }: PropsWithChildren) => {
  const gridRef = useSelector(layoutStore, store => store.context.gridRef)
  const panelRef = useSelector(layoutStore, store => store.context.draggableHeaderRef)

  const toolbarRef = gridRef.pipe(
    Option.flatMap(ref => Option.fromNullable(
      ref.querySelector(`.${NearbyBuyersLayoutParts.Toolbar}`),
    )),
  )

  return pipe(
    Option.zipWith(
      toolbarRef,
      panelRef,
      (toolbar, panelToolbar) => (
        <>
          {createPortal(
            children,
            toolbar,
          )}

          {createPortal(
            children,
            panelToolbar,
          )}
        </>
      ),
    ),
    Option.getOrNull,
  )
}

NearbyBuyersLayout.Map = ({ children }: PropsWithChildren) => {
  const gridRef = useSelector(layoutStore, store => store.context.gridRef)
  return pipe(
    gridRef,
    Option.flatMap(ref => Option.fromNullable(
      ref.querySelector(`.${NearbyBuyersLayoutParts.Map}`),
    )),
    Option.map(el => createPortal(
      children,
      el,
    )),
    Option.getOrNull,
  )
}

export type NearbyBuyersLayoutPanelProps = PropsWithChildren<{
  label?: ReactNode
}>

NearbyBuyersLayout.Panel = ({ children, label }: NearbyBuyersLayoutPanelProps) => {
  const gridRef = useSelector(layoutStore, store => store.context.gridRef)
  const context = DraggablePanelContext.useActorContext()

  const desktopPanelRef = gridRef.pipe(
    Option.flatMap(ref => Option.fromNullable(
      ref.querySelector(`.${NearbyBuyersLayoutParts.Panel}`),
    )),
  )

  const mobilePanelRef = context.getDraggablePanelRef().pipe(
    Option.flatMap(ref => Option.fromNullable(
      ref.querySelector(`.${DraggablePanelParts.Body}`),
    )),
  )

  useEffect(() => {
    pipe(
      Option.fromNullable(label),
      Option.map(v => setSidePanelLabel(v)),
    )
  }, [])

  return pipe(
    Option.zipWith(
      desktopPanelRef,
      mobilePanelRef,
      (desktopPanelRef, mobilePanelRef) => (
        <Breakpoint
          mobSm={
            createPortal(
              children,
              mobilePanelRef,
            )
          }
          tabSm={
            createPortal(
              children,
              desktopPanelRef,
            )
          }
        />
      ),
    ),
    Option.getOrNull,
  )
}

NearbyBuyersLayout.Address = ({ children }: PropsWithChildren) => {
  const gridRef = useSelector(layoutStore, store => store.context.gridRef)
  return pipe(
    gridRef,
    Option.flatMap(ref => {
      const addressRef = Option.fromNullable(
        ref.querySelector(`.${NearbyBuyersLayoutParts.Address}`),
      )
      const floatingAddressRef = Option.fromNullable(
        ref.querySelector(`.${NearbyBuyersLayoutParts.FloatingAddress}`),
      )

      return Option.zipWith(
        addressRef,
        floatingAddressRef,
        (addressEl, floatingAddressEl) => (
          <>
            {createPortal(
              children,
              addressEl,
            )}

            {createPortal(
              children,
              floatingAddressEl,
            )}
          </>
        ))
    }),
    Option.getOrNull,
  )
}

type SidePanelButtonProps = {
  isPanelExpanded?: boolean
} & FlexProps

const SidePanelButton = ({ isPanelExpanded, ...props }: SidePanelButtonProps) => {
  const sidePanelLabel = useSelector(layoutStore, store => store.context.sidePanelLabel)
  return sidePanelLabel.pipe(
    Option.map(label => (
      <Box
        key='side-panel-button'
        position='absolute'
        top='50%'
        left='-20px'
        transform='translateX(-50%) rotate(-90deg)'
        transformOrigin='center'
        zIndex={1}
        role='button'
        {...props}
      >
        <Flex filter='drop-shadow(0px -4px 10px rgba(0, 0, 0, 0.25))'>
          <LeftCurve />
          <Flex
            ml='-2px'
            bgColor='accent.hover'
            p='2px'
            alignItems='center'
            gap={1}
          >
            <ChevronsLeftIcon
              color='graystrong.400'
              boxSize={3}
              transform={isPanelExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}
            />
            {isPanelExpanded
              ? (
                <Text
                  textStyle='bodyLFat'
                  color='graystrong.400'
                  textTransform='uppercase'
                >
                  Hide
                </Text>
              )
              : (
                <Text
                  textStyle='bodyLFat'
                  color='graystrong.400'
                  textTransform='uppercase'
                >
                  {label}
                </Text>
              )}
          </Flex>
          <RightCurve />
        </Flex>
      </Box>
    )),
    Option.getOrNull,
  )
}

const LeftCurve = () => {
  const bgColor = useToken('colors', 'accent.hover')
  return (
    <chakra.svg
      width='56px'
      height='40px'
      viewBox='0 0 56 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <chakra.g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.5120241,25.110505 C13.3042341,27.520705 7.65922507,33.408005 8.01617257,47.928705 L47.999994,48.0000033 L47.999994,-7.99999673 C47.4256941,20.035805 27.2928941,12.249305 15.5120241,25.110505 Z'
          id='left-curve'
          fill={bgColor}
          fillRule='nonzero'
          transform='translate(28, 20) scale(-1, 1) rotate(90) translate(-28, -20)'
        />
      </chakra.g>
    </chakra.svg>
  )
}

const RightCurve = () => {
  const bgColor = useToken('colors', 'accent.hover')
  return (
    <chakra.svg
      ml='-2px'
      width='56px'
      height='40px'
      viewBox='0 0 56 40'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <chakra.g
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M15.5120241,25.110505 C13.3042341,27.520705 7.65922507,33.408005 8.01617257,47.928705 L47.999994,48.0000033 L47.999994,-7.99999673 C47.4256941,20.035805 27.2928941,12.249305 15.5120241,25.110505 Z'
          id='right-curve'
          fill={bgColor}
          fillRule='nonzero'
          transform='translate(28, 20) rotate(90) translate(-28, -20)'
        />
      </chakra.g>
    </chakra.svg>
  )
}

type BodySwitcherMode =
  | 'with-sidepanel'
  | 'with-sidepanel-drawer'
  | 'with-draggable-bottom-panel'

const useBodySwitchLayout = (): BodySwitcherMode => {
  const { sbp } = useSwitchBreakpointFn()
  const mode = sbp<BodySwitcherMode>({
    mobSm: 'with-draggable-bottom-panel',
    tabSm: 'with-sidepanel-drawer',
    dtLg: 'with-sidepanel',
  })

  return pipe(
    Option.fromNullable(mode),
    Option.getOrElse(() => 'with-sidepanel' as BodySwitcherMode),
  )
}

/**
 * @NOTE: there is probably only one instance of layout in a screen so
 * need to isolate the store in a react context
 */
const layoutStore = createStore({
  context: {
    gridRef: Option.none<HTMLElement>(),
    draggableHeaderRef: Option.none<HTMLElement>(),
    sidePanelLabel: Option.none<ReactNode>(),
  },
  on: {
    setGridRef: (context, event: {
      gridRef: Option.Option<HTMLElement>
    }) => ({
      ...context,
      gridRef: event.gridRef,
    }),
    setDraggableHeaderRef: (context, event: {
      draggableHeaderRef: Option.Option<HTMLElement>
    }) => ({
      ...context,
      draggableHeaderRef: event.draggableHeaderRef,
    }),
    setSidePanelLabel: (context, event: {
      label: ReactNode
    }) => ({
      ...context,
      sidePanelLabel: Option.some(event.label),
    }),
  },
})

const setGridRef = (ref?: HTMLElement | null) =>
  layoutStore.send({ type: 'setGridRef', gridRef: Option.fromNullable(ref) })

const setDraggableHeaderRef = (ref?: HTMLElement | null) =>
  layoutStore.send({
    type: 'setDraggableHeaderRef',
    draggableHeaderRef: Option.fromNullable(ref),
  })

const setSidePanelLabel = (label: ReactNode) =>
  layoutStore.send({
    type: 'setSidePanelLabel',
    label,
  })
