import { useQuery } from '@apollo/client'
import { Avatar, AvatarProps } from '@chakra-ui/react'
import { gql } from '__generated__'
import { UserAvatar_GetAvatarInfoQuery } from '__generated__/graphql'
import { toast } from 'presentation/components/Toast'
import { FC, useEffect } from 'react'

const USER_AVATAR_GET_AVATAR_INFO = gql(/* GraphQL */ `
  query UserAvatar_GetAvatarInfo {
    me {
      id
    }
    myEnterprise {
      id
      users {
        edges {
          node {
            id
            firstname
            lastname
            avatar {
              avatarUrl
            }
          }
        }
      }
    }
  }
`)

type Props = AvatarProps & ({
  isMe: true
} | {
  id: string
} | {
  isUnknown: true
})

/**
 * @TODO Make initials size editable
 * @TODO Create storybook for this component
 * @deprecated Just call Avatar directly, in the context state design, all
 *   data should be supplied by the context
 */
export const UserAvatar: FC<Props> = props => {
  const avatarProps = getAvatarProps(props)
  const { data, error } = useQuery(USER_AVATAR_GET_AVATAR_INFO)
  const user = data ? getUser(props, data) : undefined
  const isUnknown = 'isUnknown' in props
  // React to errors
  useEffect(() => {
    if (!error) return

    toast({
      id: 'USER_AVATAR_ERROR',
      status: 'error',
      title: 'We couldn’t load the user avatar.',
      message: error.message,
    })
  }, [error])

  if (isUnknown) {
    return (
      <Avatar
        src={undefined}
        name='?'
        {...avatarProps}
      />
    )
  }

  if (!user) {
    return (
      <Avatar
        src={undefined}
        bg='special.500'
        {...avatarProps}
      />
    )
  }

  /** @HACK For some reason when there's no avatar, we get transparent image instead of null */
  const isAvatarJustBlank = !!user.avatar?.avatarUrl?.includes('d=blank')
  const avatar = isAvatarJustBlank ? undefined : user.avatar?.avatarUrl
  const name = [user.firstname, user.lastname].filter(Boolean).join(' ').trim()
  return (
    <Avatar
      src={avatar || undefined}
      name={name}
      {...avatarProps}
    />
  )
}

const getAvatarProps = (props: Props): AvatarProps => {
  if ('isMe' in props) {
    const { isMe, ...rest } = props
    return rest
  } else if ('id' in props) {
    const { id, ...rest } = props
    return rest
  } else if ('isUnknown' in props) {
    const { isUnknown, ...rest } = props
    return rest
  } else {
    return props
  }
}

const getUser = (props: Props, data: UserAvatar_GetAvatarInfoQuery) => {
  const users = data.myEnterprise?.users.edges.map(({ node }) => node)
  const id = 'id' in props ? props.id : data.me?.id
  return users?.find(user => user.id === id)
}
