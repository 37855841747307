import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { PartialRange } from 'features/valueObjects/Range'
import { BedsFilterCardDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/BedsFilterCard/BedsFilterCard.dumb'
import { BedsFilterCardDumbProps as Props } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/BedsFilterCard/BedsFilterCard.dumb.props'
import { shallow } from 'zustand/shallow'

export const BedsFilterCard = () => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.bedroomsCount ?? null,
      filterValue: state.local.report.data.filters.bedroomsCount,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: Props['onChange'] = filter => {
    void filterComps({
      bedroomsCount: filter,
    })
  }

  if (!isReady) return null

  return (
    <BedsFilterCardDumb
      value={filterValue || {
        range: PartialRange.EMPTY,
        shouldMatchSubjectProperty: false,
      }}
      onChange={handleOnValueChange}
      subjectValue={subjectValue}
    />
  )
}
