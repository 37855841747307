import { NumberLib } from 'libs/Number'
import { pipe } from 'lodash/fp'
import * as R from 'remeda'

const makeNumberFallbackFn = <TOutput>(fn: (...args: any[]) => TOutput) =>
  <TFallback>(fallback: TFallback) =>
    (number: number | null | undefined) =>
      number === null ? fallback : fn(number)

const fromCents = (cents: number) => cents / 100
const prefixNumberString = (string: string) =>
  string.startsWith('-') ? `-$${string.slice(1)}` : `$${string}`

const withCommas = (dollars: number) => R.pipe(dollars, NumberLib.formatComma, prefixNumberString)
withCommas.fallback = makeNumberFallbackFn(withCommas)

const withCommasDecimals = (dollars: number) => R.pipe(
  dollars,
  NumberLib.formatCommaDecimals,
  prefixNumberString,
)
withCommasDecimals.fallback = makeNumberFallbackFn(withCommasDecimals)

const withCommasOptionalDecimals = (dollars: number) => R.pipe(
  dollars,
  NumberLib.formatCommaOptionalDecimals,
  prefixNumberString,
)

const withK = (dollars: number) => R.pipe(dollars, NumberLib.formatK, prefixNumberString)
withK.fallback = makeNumberFallbackFn(withK)

withK.nullable = (dollars: number | null) => withK.fallback(null)(dollars)

const convertToNumber = (dollar: string) => {
  const cleanedStr = dollar.replace(/[$,]/g, '')
  const num = parseFloat(cleanedStr)
  return isNaN(num) ? null : num
}

/**
 * Formats pennies as cents (X¢) if below 100, otherwise as dollars ($X.XX)
 * Dollars will have optional decimals (showing only when necessary)
 */
const formatPennies = (pennies: number): string => {
  if (pennies < 100)
    return `${pennies}¢`

  // Convert pennies to dollars and format with optional decimals
  return withCommasOptionalDecimals(fromCents(pennies))
}

/** @TODO Move to feature/valueObjects */
export const Dollar = {
  fromCents,
  prefixNumberString,
  /** @deprecated useformatNumberWithX instead */
  formatNumber: {
    /** @deprecated use formatNumberWithCommas instead */
    withCommas,
    /** @deprecated use formatNumberWithCommasDecimals instead */
    withCommasDecimals,
    /** @deprecated use formatNumberWithK instead */
    withK,
  },
  formatNumberWithCommas: withCommas,
  formatNumberWithCommasDecimals: withCommasDecimals,
  formatNumberWithCommasOptionalDecimals: withCommasOptionalDecimals,
  formatNumberWithK: withK,
  formatPennies,

  formatNumberWithCommasOrDoubleDash: NumberLib.ifElse(withCommas)(() => '--'),
  formatNumberWithCommasDecimalsOrDoubleDash: NumberLib.ifElse(withCommasDecimals)(() => '--'),
  formatNumberWithCommasOptionalDecimalsOrDoubleDash: NumberLib.ifElse(withCommasOptionalDecimals)(() => '--'),
  formatNumberWithKOrDoubleDash: NumberLib.ifElse(withK)(() => '--'),
  /**
   * formatNumber with K (e.g.4k) and Optional Decimals, or Double Dash if non number
   */
  formatKODoDD: NumberLib.ifElse(
    R.createPipe(
      NumberLib.formatKOptionalDecimals,
      prefixNumberString,
    ),
  )(
    () => '--',
  ),
  formatMax3Digits: NumberLib.ifElse(
    R.createPipe(
      NumberLib.formatMax3Digits,
      prefixNumberString,
    ),
  )(
    () => '--',
  ),
  /** formatNumber Commas or Double Dash */
  formatNumberCoDD: NumberLib.ifElse(withCommas)(() => '--'),
  /** formatNumberRounded Commas or Double Dash */
  formatNumberCoDDRounded: NumberLib.ifElse(pipe(Math.round, withCommas))(() => '--'),
  /** formatNumber Commas Decimals or Double Dash */
  formatNumberCDoDD: NumberLib.ifElse(withCommasDecimals)(() => '--'),
  /** formatNumber Commas Optional Decimals or Double Dash */
  formatNumberCODoDD: NumberLib.ifElse(withCommasOptionalDecimals)(() => '--'),
  /** formatPennies or Double Dash */
  formatPenniesOrDoubleDash: NumberLib.ifElse(formatPennies)(() => '--'),
  toNumber: convertToNumber,
}
