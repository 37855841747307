import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpoint, useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CompColumnProps, CompStatCardProps, CompStatRowProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompStatCard/CompStatCard.props'
import { getCompCardColors } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/utils/getCompCardColors'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useRef } from 'react'
import { isEmpty, isNonNull } from 'remeda'

const BORDER_WIDTH = 0.25
const CARD_BORDER_RADIUS = 2
const SHADOW = 'primary.m'
const COMP_STAT_ROW__CLASSNAME = 'comp-stat-row'
const COMP_STAT_ROW__HEADER__CLASSNAME = 'comp-stat-row-header'
const BED_BATH_SIZE__CLASSNAME = 'bed-bath-size'
const SUBDIVISION__CLASSNAME = 'subdivision'
const ADDRESS__CLASSNAME = 'address'
const IS_SUBJECT_ROW__CLASSNAME = 'is-subject-row'
const IS_COMP_ROW__CLASSNAME = 'is-comp-row'
const MARKER__CLASSNAME = 'marker'
const ROW_LABEL__CLASSNAME = 'row-label'
const ROW_VALUE__CLASSNAME = 'row-value'
/**
 * If no comp is provided, the card will render the subject property stat
 * only showing only 1 column. If a comp is provided, the card will render
 * 2 columns, one for the subject property and one for the comp.
 */
export const CompStatCard = ({ comp, subject, ...boxProps }: CompStatCardProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const { sbp } = useSwitchBreakpointFn()

  const isCompact = sbp({ mobSm: true, tabSm: false }) ?? false
  const isMobSm = sbp({ mobSm: true, mob: false }) ?? false

  const columnWidth = sbp({ mobSm: '216px', tabSm: '248px', dtSm: '272px' }) ?? '272px'

  const shouldShowCompColumn = comp !== undefined

  const gridTemplateColumns = sbp({
    mobSm: shouldShowCompColumn ? `minmax(0, ${columnWidth}) minmax(101px, 1fr)` : 'minmax(0, 1fr)',
    tabSm: `repeat(${shouldShowCompColumn ? 2 : 1}, minmax(${columnWidth}, 1fr))`,
  })
  return (
    <Grid
      ref={ref}
      w='full'
      minW={0}
      columnGap={isCompact ? 0 : 2}
      gridAutoFlow='column'
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows='repeat(20, min-content)'
      sx={{
        '& > .comp-stat-row:nth-of-type(odd)': {
          bgColor: 'grayweak.200',
        },
        '& > .comp-stat-row:nth-of-type(even)': {
          bgColor: 'grayweak.100',
        },
        ...isCompact && shouldShowCompColumn && createCompStatRowCompactStyles(isMobSm),
      }}
      overflowX={mbp({ mobSm: 'auto', tabSm: 'initial' })}
      borderBottomRadius={CARD_BORDER_RADIUS}
      {...boxProps}
    >
      {shouldShowCompColumn && (
        <CompColumn
          comp={comp}
        />
      )}
      <CompColumn comp={subject} />
    </Grid>
  )
}

const CompColumn = (props: CompColumnProps) => {
  const { comp } = props

  const isMobSm = useSwitchBreakpoint({ mobSm: true, mob: false }) ?? false

  const formatDate = (date: Date) =>
    isMobSm ? DateLib.formatMMDDYYSlashed(date) : DateLib.formatMMDDYYYYSlashed(date)

  const listDate = comp.listDate
    ? formatDate(comp.listDate)
    : '--'
  const saleDate = comp.saleDate
    ? formatDate(comp.saleDate)
    : '--'

  const isSubjectRow = comp.type === 'subject-comp'
  return (
    <>
      <CompStatHeader
        comp={comp}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Status'
        value={<MLSStatusTag status={comp.status} />}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='List Price'
        value={Dollar.formatNumberCODoDD(comp.listPrice)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='List Price SqFt'
        value={Dollar.formatNumberCODoDD(comp.listPricePerSqft)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Sales Price'
        value={Dollar.formatNumberCODoDD(comp.salePrice)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Sales Price SqFt'
        value={Dollar.formatNumberCODoDD(comp.salePricePerSqft)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Contract Date'
        value={listDate}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Sold Date'
        value={saleDate}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Days on Market'
        value={comp.daysOnMarket ?? '--'}
      />

      <CompStatRowSpacer
        isSubjectRow={isSubjectRow}
        status={comp.status}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Subdivision'
        value={comp.address.subdivision ?? '--'}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Year Built'
        value={comp.yearBuilt ?? '--'}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Approx. Acres'
        value={NumberLib.formatCODoDD(comp.lotAreaAcres)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Lot SqFt'
        value={NumberLib.formatCODoDD(comp.lotAreaSqft)}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Type'
        value={comp.propertyType
          ? HomeType.toStringUnstandardized(comp.propertyType)
          : '--'}
      />

      {/* @GENESIS */}
      {/* <CompStatRow
      status={comp.status}
      label='Style'
      value={comp.style ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Exterior'
      value={comp.exterior ?? '--'}
    /> */}

      <CompStatRowSpacer
        isSubjectRow={isSubjectRow}
        status={comp.status}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Bedrooms'
        value={comp.bedroomsCount ?? '--'}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Full Baths'
        value={comp.bathroomsCountInfo?.full ?? '--'}
      />

      <CompStatRow
        isSubjectRow={isSubjectRow}
        status={comp.status}
        label='Half Baths'
        value={comp.bathroomsCountInfo?.half ?? '--'}
        isLastRow
      />

      {/**
     * @HACK Make the num of rows even, otherwise the alternating bg color goes wacky
     */}
      <div />

      {/* @GENESIS */}
      {/* <CompStatRow
      status={comp.status}
      label='Heat/Cool'
      value={comp.heatingOrCooling ?? '--'}
    />

    <CompStatRowSpacer status={comp.status} />

    <CompStatRow
      status={comp.status}
      label='Fireplace'
      value={comp.fireplace ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Parking'
      value={comp.parking ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Driveway'
      value={comp.driveway ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Foundation'
      value='--'
    />

    <CompStatRowSpacer status={comp.status} />
    <CompStatRow
      status={comp.status}
      label='Flooring'
      value={comp.flooring ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Flooring'
      value='--'
    />

    <CompStatRow
      status={comp.status}
      label='Flooring'
      value='--'
    />

    <CompStatRow
      status={comp.status}
      label='Laundry'
      value={comp.laundry ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Int. Feature'
      value={comp.interiorFeature ?? '--'}
    />

    <CompStatRow
      status={comp.status}
      label='Ext. Feature'
      value={comp.exteriorFeature ?? '--'}
      isLastRow
    /> */}
    </>
  )
}

const CompStatHeader = (props: CompColumnProps) => {
  const { comp, ...rest } = props

  const cardColors = getCompCardColors(comp.status)

  const isSubject = comp.type === 'subject-comp'

  const isSubdivisionAvailable = isNonNull(comp.address.subdivision)
    && !isEmpty(comp.address.subdivision)

  const subdivision = isSubdivisionAvailable
    ? comp.address.subdivision
    : '--'

  const subdivisionDisplay = (
    <Text
      className={SUBDIVISION__CLASSNAME}
      textStyle='bodyM'
      color='inherit'
    >
      {subdivision}
    </Text>
  )

  const subdivisionDisplayWithTooltip = isSubdivisionAvailable
    ? <Tooltip label={subdivision}>{subdivisionDisplay}</Tooltip>
    : subdivisionDisplay

  const subheading = subdivisionDisplayWithTooltip

  const marker = isSubject
    ? (
      <Box className={MARKER__CLASSNAME}>
        <SubjectPropertyIcon boxSize={3} />
      </Box>
    )
    : null

  return (
    <GridItem
      className={classNames(COMP_STAT_ROW__HEADER__CLASSNAME, {
        [IS_SUBJECT_ROW__CLASSNAME]: isSubject,
        [IS_COMP_ROW__CLASSNAME]: !isSubject,
      })}
      borderTopRadius={CARD_BORDER_RADIUS}
      borderColor={cardColors.cardBorderColor}
      bgColor={isSubject ? 'accent.darkpurple' : cardColors.cardHeaderBgColor}
      borderTopWidth={BORDER_WIDTH}
      borderLeftWidth={BORDER_WIDTH}
      borderRightWidth={BORDER_WIDTH}
      shadow={SHADOW}
      {...rest}
    >
      <Flex
        h='56px'
        py={1}
        // @HACK Temporary hack to fix the spacing when a marker is not present
        // @TODO Just set this to 1 when PropertyMarker is applied
        px={2}
        gap={1}
        alignItems='center'
      >
        {marker}

        <Flex
          className={ADDRESS__CLASSNAME}
          minW={0}
          flex='1'
          flexDirection='column'
          color={isSubject ? 'ondark.1' : cardColors.cardHeaderTextColor}
        >
          <Tooltip label={comp.address.line1}>
            <Text
              textStyle='bodyMFat'
              isTruncated
              color='inherit'
              mr={1}
            >
              {comp.address.line1}
            </Text>
          </Tooltip>

          {subheading}
        </Flex>
      </Flex>
    </GridItem>
  )
}

const CompStatRow = (comp: CompStatRowProps) => {
  const {
    status,
    label,
    value,
    isLastRow,
    align: alignFromProps = 'right',
    isSubjectRow,
    ...boxProps
  } = comp
  const borderColor = getCompCardColors(status).cardBorderColor
  const align = value === '--' ? 'right' : alignFromProps
  return (
    <Flex
      className={classNames(COMP_STAT_ROW__CLASSNAME, {
        [IS_SUBJECT_ROW__CLASSNAME]: isSubjectRow,
        [IS_COMP_ROW__CLASSNAME]: !isSubjectRow,
      })}
      alignItems='top'
      justifyContent='space-between'
      minH={5}
      py={1}
      pr={3}
      pl={2}
      borderColor={borderColor}
      borderLeftWidth={BORDER_WIDTH}
      borderRightWidth={BORDER_WIDTH}
      {...isLastRow && {
        borderBottomWidth: BORDER_WIDTH,
        borderBottomRadius: CARD_BORDER_RADIUS,
        overflow: 'hidden',
      }}
      shadow={SHADOW}
      gap={1}
      {...boxProps}
    >
      <Box
        className={ROW_LABEL__CLASSNAME}
        textStyle='bodyLFat'
        color='graystrong.600'
        textAlign='left'
      >
        {label}
      </Box>
      <Box
        className={ROW_VALUE__CLASSNAME}
        textStyle='bodyL'
        color='graystrong.600'
        textAlign={align}
        wordBreak='break-word'
      >
        {value}
      </Box>
    </Flex>
  )
}

const CompStatRowSpacer = (comp: { status: MLSStatus, isSubjectRow?: boolean }) => {
  const borderColor = getCompCardColors(comp.status).cardBorderColor
  return (
    <Box
      className={classNames(COMP_STAT_ROW__CLASSNAME, {
        [IS_SUBJECT_ROW__CLASSNAME]: comp.isSubjectRow,
        [IS_COMP_ROW__CLASSNAME]: !comp.isSubjectRow,
      })}

      h={5}
      borderColor={borderColor}
      borderLeftWidth={BORDER_WIDTH}
      borderRightWidth={BORDER_WIDTH}
      shadow={SHADOW}
    />
  )
}

const createCompStatRowCompactStyles = (isMobSm?: boolean) => ({
  [`& .${COMP_STAT_ROW__HEADER__CLASSNAME}`]: {
    'borderColor': 'neutral.500',
    '& > div': {
      px: 1,
    },
    [`& .${SUBDIVISION__CLASSNAME}`]: {
      display: 'none',
    },
    [`& .${MARKER__CLASSNAME}`]: {
      display: isMobSm ? 'block' : 'none',
    },
    [`& .${BED_BATH_SIZE__CLASSNAME}`]: {
      display: 'none',
    },
    [`&.${IS_COMP_ROW__CLASSNAME}`]: {
      borderTopRightRadius: 0,
      borderRightWidth: '0.5px',
      borderRightColor: 'grayweak.500',
    },
    [`&.${IS_SUBJECT_ROW__CLASSNAME}`]: {
      'borderTopLeftRadius': 0,
      'borderLeftWidth': '0.5px',
      'borderLeftColor': 'grayweak.500',
      [`& .${ADDRESS__CLASSNAME}`]: {
        display: isMobSm ? 'none' : 'flex',
      },
      '& > div': {
        justifyContent: 'center',
      },
    },
  },
  [`& .${COMP_STAT_ROW__CLASSNAME}`]: {
    px: 0.5,
    borderColor: 'neutral.500',
    [`&.${IS_COMP_ROW__CLASSNAME}`]: {
      borderBottomRightRadius: 0,
      borderRightWidth: '0.5px',
      borderRightColor: 'grayweak.500',
    },
    [`&.${IS_SUBJECT_ROW__CLASSNAME}`]: {
      borderBottomLeftRadius: 0,
      justifyContent: 'end',
      borderLeftWidth: '0.5px',
      borderLeftColor: 'grayweak.500',
      [`.${ROW_LABEL__CLASSNAME}`]: {
        display: 'none',
      },
    },
  },
}
)
