import { createIcon } from '@chakra-ui/react'

export const PlanAdvancedIcon = createIcon({
  displayName: 'PlanAdvancedIcon',
  viewBox: '0 0 56 57',
  path: (
    <>
      <path d='M54.25 20.6533H34.2125L28 2.27832L21.7875 20.6533H1.75L17.9375 32.0283L11.725 50.4033L28 39.0283L44.1875 50.4033L37.975 32.0283L54.25 20.6533Z' fill='#FFCE31' />
      <path d='M40.4254 18.2908L43.9254 8.31583L34.7379 14.6158L36.0504 18.2908H40.4254ZM24.4129 44.2783L28.0004 54.7783L31.5879 44.2783L28.0004 41.8283L24.4129 44.2783ZM44.3629 30.5408L41.0379 32.8158L42.4379 37.0158H53.7254L44.3629 30.5408ZM21.2629 14.6158L12.0754 8.31583L15.5754 18.2908H19.9504L21.2629 14.6158ZM11.6379 30.5408L2.27539 37.0158H13.5629L14.9629 32.8158L11.6379 30.5408Z' fill='#FFDF85' />
    </>
  ),
})
