import { Box, Flex, Image, Spacer, Text } from '@chakra-ui/react'
import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { MLSListing } from 'features/valueObjects/MLSListing'
import { DateLib } from 'libs/Date'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { EmptyCard } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/EmpyCard'
import { TableSectionCommon } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.common'
import { Fragment, useMemo } from 'react'
import { groupBy, isNonNull, mapValues, sortBy, toPairs } from 'remeda'
import { pipe, Option } from 'effect'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { Breakpoint } from 'presentation/components/Breakpoint'

const UNKNOWN_DATE_STRING = 'UNKNOWN'

type DatedData<TKey, TData> = {
  type: TKey
  date: Date | null
  data: TData
}

type DatedTransfer = DatedData<'transfer', PropertyDetails.Transfer>
type DatedMLSListing = DatedData<'mls-listing', MLSListing>

type LiensAndOwnersDateData = DatedTransfer | DatedMLSListing

export const LiensAndOwnersTabContent = () => {
  const { sbp } = useSwitchBreakpointFn()
  const selectProperty = useSelectPropertyByRouteLeadId()
  const property = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details' || !property.land) return null

    return property
  })

  const dataByDate = useMemo(() => {
    if (!property) return []

    const datedTransfers: DatedTransfer[] = property.transfers
      .map(transfer => ({
        type: 'transfer' as const,
        date: transfer.recordingDate || transfer.saleDate || null,
        data: transfer,
      }))

    const datedMLSListings: DatedMLSListing[] = property.mlsListings
      .map(listing => ({
        type: 'mls-listing' as const,
        date: listing.listDate || listing.saleDate || null,
        data: listing,
      }))

    const sortListingFirstBeforeTransfer = sortBy<LiensAndOwnersDateData>(
      data => data.type === 'mls-listing' ? 0 : 1,
    )

    const dataByDate: [string, LiensAndOwnersDateData[]][] = pipe(
      [
        ...datedTransfers,
        ...datedMLSListings,
      ],
      groupBy(datedData =>
        datedData.date === null
          ? UNKNOWN_DATE_STRING
          : DateLib.formatMMDDYYSlashed(datedData.date)),
      mapValues(sortListingFirstBeforeTransfer),
      toPairs,
      sortBy([([dateString]) => {
        const date = dateString === UNKNOWN_DATE_STRING
          ? null
          : DateLib.fromMMDDYYSlashed(dateString)

        return date?.getTime() || -Infinity
      }, 'desc']),
    )

    return dataByDate
  }, [property])

  return (
    <TabbedCards.TabContent>
      {dataByDate.length === 0 && (
        <EmptyCard />
      )}

      {dataByDate.length > 0 && dataByDate.map(([dateString, listForDate]) => {
        const date = dateString === UNKNOWN_DATE_STRING
          ? null
          : DateLib.fromMMDDYYSlashed(dateString)

        return (
          <Fragment key={dateString}>
            <TabbedCards.CardLabel>
              {createDateHeading(date, listForDate)}
            </TabbedCards.CardLabel>

            {listForDate.map(({ type, data }, i) => {
              /** @TODO Derive and use proper key/id */
              const key = i

              switch (type) {
              case 'transfer':
                return (
                  <TabbedCards.MultiSegmentedCardSection
                    key={key}
                    label={null}
                    sections={[
                      [
                        <>
                          <TabbedCards.CardUpperSegmentTextEmphasized>
                            Transfer
                          </TabbedCards.CardUpperSegmentTextEmphasized>
                          {' '}
                          -
                          {' '}
                          <TabbedCards.CardUpperSegmentText>
                            Public Record
                          </TabbedCards.CardUpperSegmentText>
                        </>,
                        [
                          ['Recording Date', TableSectionCommon.formatDate(data.recordingDate)],
                          ['Transfer Date', TableSectionCommon.formatDate(data.saleDate)],
                          ['Previous Owner Name(s)', (
                            <TableSectionCommon.MultipleStrings
                              key='prev-owner-names'
                              strings={data.sellerInfo?.sellers.map(seller => seller.name) || []}
                            />
                          )],
                          ['New Owner Name(s)', (
                            <TableSectionCommon.MultipleStrings
                              key='new-owner-names'
                              strings={data.buyerInfo?.buyers.map(buyer => buyer.name) || []}
                            />
                          )],
                          ['Document Type', data.documentType || '--'],
                          ['Transaction Type', data.transactionType || '--'],
                          ['Previous Owner Address', (
                            TableSectionCommon.formatAddress(data.sellerInfo?.address)
                          )],
                          ['New Owner Address', (
                            TableSectionCommon.formatAddress(data.buyerInfo?.address)
                          )],
                          ['Document Number', data.documentNumber || '--'],
                          ['Purchase Method', data.purchaseMethod || '--'],
                        ],
                      ],

                      ...data.liens.map((lien, i): TabbedCards.MultiSegmentedCardSectionProps['sections'][number] => [
                        (
                          <Flex key={`mortgage-${i}`}>
                            <Box>
                              <TabbedCards.CardUpperSegmentTextEmphasized>
                                Loan
                                {' '}
                                {i + 1}
                                {' '}
                                of
                                {data.liens.length}
                              </TabbedCards.CardUpperSegmentTextEmphasized>
                              {' '}
                              -
                              {' '}
                              <TabbedCards.CardUpperSegmentText>
                                Public Record
                              </TabbedCards.CardUpperSegmentText>
                            </Box>
                            <Spacer />
                            <Breakpoint
                              mob='Status:'
                              tabSm='Loan Status:'
                            />
                            <Text
                              ml='1'
                              h='3'
                              px='2'
                              bg={lien.isActive ? 'negative.500' : 'ondark.6'}
                              borderRadius='full'
                              color='ondark.1'
                              textStyle='bodyMFat'
                            >
                              {lien.isActive ? 'Active' : 'Inactive'}
                            </Text>
                          </Flex>
                        ),
                        [
                          ['Recording Date', TableSectionCommon.formatDate(lien.recordingDate)],
                          ['Loan Date', TableSectionCommon.formatDate(data.saleDate)],
                          ['Lender Name(s)', lien.holder?.name || '--'],
                          ['Owner Name(s)', (
                            <TableSectionCommon.MultipleStrings
                              key='new-owner-names'
                              strings={data.buyerInfo?.buyers.map(buyer => buyer.name) || []}
                            />
                          )],
                          ['Document Type', '--'],
                          ['Original Loan Amount', TableSectionCommon.formatDollar(lien.amount)],
                          ['Lender Address', (
                            <TableSectionCommon.MultipleAddresses
                              key='lender-address'
                              addresses={[lien.holder?.address || null].filter(isNonNull)}
                            />
                          )],
                          ['Owner Address', (
                            TableSectionCommon.formatAddress(data.buyerInfo?.address)
                          )],
                          [
                            'Document Number',
                            (
                              <Box
                                key='doc-no'
                                mb={mbp({
                                  mobSm: '2',
                                  tabSm: '4',
                                })}
                              >
                                {lien.documentNumber || '--'}
                              </Box>
                            ),
                          ],
                          // @NOTE: this is a spacing hack
                          ...sbp<TabbedCards.CardSectionProps['pairs']>({
                            mobSm: [
                              ['', ''],
                            ],
                            tabSm: [
                              ['', ''],
                              ['', ''],
                              ['', ''],
                            ],
                          }) ?? [],
                          ['Loan Type', lien.mortgageType || '--'],
                          // ['Purchase Method', data.purchaseMethod || '--'],
                          [
                            <Breakpoint
                              key='loan-pay'
                              mobSm='Est. Loan Payment'
                              mob='Estimated Loan Payment'
                            />,
                            lien.estimatedPayment || '--',
                          ],
                          [
                            'Interest Rate',
                            pipe(
                              Option.fromNullable(lien.interestRatePercent),
                              Option.map(v => `${v}%`),
                              Option.getOrElse(() => '--'),
                            ),
                          ],
                          ['', ''],
                          ['Loan Term', lien.termMonths || '--'],
                          ['Loan Age', TableSectionCommon.formatDate(lien.maturityDate) || '--'],
                          [
                            <Breakpoint
                              key='loan-balance'
                              mobSm='Est. Loan Balance'
                              mob='Estimated Loan Balance'
                            />,
                            lien.estimatedBalance || '--',
                          ],
                        ],
                      ]),
                    ]}
                  />
                )
              case 'mls-listing':
                return (
                  <TabbedCards.MultiSegmentedCardSection
                    key={key}
                    label={null}
                    sections={[
                      [
                        <>
                          <Flex key={`listing-${data.id || ''}-header`} align='center'>
                            <Box>
                              <TabbedCards.CardUpperSegmentTextEmphasized>
                                Listing
                              </TabbedCards.CardUpperSegmentTextEmphasized>
                              {' '}
                              -
                              {' '}
                              <TabbedCards.CardUpperSegmentText>
                                MLS#
                                {' '}
                                {data.id}
                              </TabbedCards.CardUpperSegmentText>
                            </Box>
                            <Spacer />
                            Source:
                            <Image
                              ml={1}
                              my={-0.5}
                              h='4'
                              src={data.organization.logo}
                              alt={data.organization.name}
                            />
                          </Flex>
                        </>,
                        [
                          /**
                           * Listing Date
                           * Sale Date
                           * Agent Name
                           * Broker Name
                           * Days on Market
                           * Sale Amount
                           * Agent Contact
                           * Broker Contact
                           */
                          ['Listing Date', TableSectionCommon.formatDate(data.listDate)],
                          [
                            data.isRental ? 'Lease Date' : 'Sale Date',
                            TableSectionCommon.formatDate(data.saleDate)],
                          ['Agent Name', data.agent.name || '--'],
                          ['Broker Name', data.broker.name || '--'],
                          ['Days on Market', data.daysOnMarket || '--'],
                          [
                            data.isRental ? 'Lease Amount' : 'Sale Amount',
                            TableSectionCommon.formatDollar(data.salePrice)],
                          ['Agent Contact', (
                            <TableSectionCommon.MultipleStrings
                              key={`listing-${data.id || ''}-agent-contact`}
                              strings={[
                                data.agent.phone || data.agent.phone || null,
                                data.agent.email || null,
                              ].filter(isNonNull)}
                            />
                          )],
                          ['Broker Contact', (
                            <TableSectionCommon.MultipleStrings
                              key={`listing-${data.id || ''}-broker-contact`}
                              strings={[
                                data.broker.phone || null,
                                data.broker.email || null,
                              ].filter(isNonNull)}
                            />
                          )],
                        ]],
                    ]}
                  />
                )
              default:
                return null
              }
            })}
          </Fragment>
        )
      })}
    </TabbedCards.TabContent>
  )
}

const createDateHeading = (date: Date | null, data: LiensAndOwnersDateData[]) => {
  const datePart = date === null ? 'Unknown Date' : DateLib.formatNaturalShortMonth(date)
  let unknownTransferOwnerCount = 0
  let listingCount = 0
  const ownerNamesPerTransfer: string[][] = []

  data.forEach(datedData => {
    if (datedData.type === 'transfer') {
      const ownerNamesCount = (datedData.data.buyerInfo?.buyers || [])
        .filter(b => b.name)
        .length

      if (!ownerNamesCount) {
        unknownTransferOwnerCount++
        return
      }

      const ownerNames = datedData.data.buyerInfo?.buyers
        .map(b => b.name)
        .filter(Boolean) || []

      ownerNamesPerTransfer.push(ownerNames)
    } else {
      listingCount++
    }
  })

  const phrases = []

  if (listingCount === 1)
    phrases.push('MLS Listing')
  else if (listingCount > 1)
    phrases.push(`${listingCount} MLS Listing${listingCount > 1 ? 's' : ''}`)

  const areAllTransferRefinance = data.every(d => d.type === 'transfer' && d.data.transactionType === 'REFINANCE')
  const verbForTransfer = areAllTransferRefinance ? 'Refinanced' : 'Owned'

  if (ownerNamesPerTransfer.length) {
    ownerNamesPerTransfer
      .forEach(names => {
        if (names.length === 1)
          phrases.push(`${verbForTransfer} by ${names[0]}`)
        else
          phrases.push(`${verbForTransfer} by ${names[0]} and ${names.length - 1} other${names.length > 2 ? 's' : ''}`)
      })
  }

  if (unknownTransferOwnerCount === 1)
    phrases.push(`${verbForTransfer} by unknown owner`)
  else if (unknownTransferOwnerCount > 1)
    phrases.push(`${verbForTransfer} by unknown owner (${unknownTransferOwnerCount} record${unknownTransferOwnerCount > 1 ? 's' : ''})`)

  return `${datePart} - ${phrases.join(', ')}`
}
