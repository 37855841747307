import { Effect, Number, Option, pipe } from 'effect'
import { noop } from 'utils/noop'
import { ActorLogicFrom, ActorRefFrom, AnyActorRef, SnapshotFrom, assign, enqueueActions, sendTo, setup } from 'xstate'
import { DraggablePanelParts } from './DraggablePanel.const'
import DraggablePanelEvent from './DraggablePanelEvent'
import DragListenerActor from './DragListenerActor'
import DragListenerEvent from './DragListenerEvent'

namespace DraggablePanelMachine {
  // #region machine
  export const make = () => Effect.sync(() => setup({
    types: {
      events: {} as DraggablePanelEvent.Incoming,
      context: {} as {
        parentRef: Option.Option<AnyActorRef>
        panelHeight: number // px
        initialYPosition: number // px
        getDraggablePanelRef: () => Option.Option<HTMLDivElement>
      },
      input: {} as {
        parentRef: Option.Option<AnyActorRef>
        panelHeight: number
      },
    },
    actors: {
      draggableListener: DragListenerActor.make(),
    },
  }).createMachine({
    id: 'DraggablePanelMachine',
    context: ({ input }) => ({
      parentRef: input.parentRef,
      initialYPosition: 0,
      panelHeight: input.panelHeight,
      getDraggablePanelRef: () => Option.none(),
    }),
    invoke: [
      {
        id: DragListenerActor.DefaultActorId,
        src: 'draggableListener',
      },
    ],
    initial: 'Mounting',
    on: {
      [DraggablePanelEvent.OnOpen]: '.Open',
      [DraggablePanelEvent.OnClose]: '.Closed',
      [DraggablePanelEvent.OnResize]: {
        actions: enqueueActions(({ enqueue, context }) => {
          context.getDraggablePanelRef().pipe(
            Option.match({
              onNone: noop,
              onSome: el => {
                if (el.offsetTop <= 0) {
                  enqueue.raise(
                    DraggablePanelEvent.Open(),
                  )
                } else {
                  enqueue.raise(
                    DraggablePanelEvent.Close(),
                  )
                }
              },
            }),
          )
        }),
      },
    },
    states: {
      Mounting: {
        on: {
          [DraggablePanelEvent.OnDidMount]: {
            actions: [
              assign({
                getDraggablePanelRef: ({ event }) =>
                  () => Option.some(event.getDraggablePanelRef()),
              }),
              sendTo(
                DragListenerActor.DefaultActorId,
                ({ context }) => context.getDraggablePanelRef().pipe(
                  Option.map(
                    DragListenerEvent.AddListeners,
                  ),
                  Option.getOrThrow,
                ),
              ),
            ],
          },
          [DragListenerEvent.OnDidAddListeners]: 'Closed',
        },
      },
      Open: {
        entry: [
          /**
           * after opening, remove the listeners so that no more events
           * being fired. This is just a additional guarantee that the content
           *inside the panel does not clash with any drag/click/scroll.
           */
          sendTo(
            DragListenerActor.DefaultActorId,
            DragListenerEvent.RemoveListeners,
          ),
          ({ context }) => context.getDraggablePanelRef().pipe(
            Option.match({
              onNone: noop,
              onSome: el => {
                // show header once open
                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Header}`) as HTMLElement,
                  ),
                  Option.map(headerEl => {
                    headerEl.style.display = 'block'
                  }),
                )

                // hide handle once open
                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Handle}`) as HTMLElement,
                  ),
                  Option.map(handleEl => {
                    handleEl.style.display = 'none'
                  }),
                )

                // animate to open
                el.style.bottom = '0px'

                animateTop({
                  element: el,
                  start: el.offsetTop,
                  end: 0,
                  duration: 50,
                })

                el.dataset['status'] = 'open'
              },
            }),
          ),
          // emit to parent
          enqueueActions(({ context, enqueue }) => {
            context.parentRef.pipe(
              Option.match({
                onNone: noop,
                onSome: parentRef => {
                  enqueue.sendTo(parentRef, DraggablePanelEvent.DidOpen())
                },
              }),
            )
          }),
        ],
        exit: [
          /**
           * add the events again because it got removed when the
           * panel was in the opened state.
           */
          sendTo(
            DragListenerActor.DefaultActorId,
            ({ context }) => context.getDraggablePanelRef().pipe(
              Option.map(
                DragListenerEvent.AddListeners,
              ),
              Option.getOrThrow,
            ),
          ),
        ],
        on: {
          [DraggablePanelEvent.OnClose]: 'Closed',
        },
      },
      Closed: {
        entry: [
          ({ context }) => context.getDraggablePanelRef().pipe(
            Option.match({
              onNone: noop,
              onSome: el => {
                // hide header once closed
                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Header}`) as HTMLElement,
                  ),
                  Option.map(headerEl => {
                    headerEl.style.display = 'none'
                  }),
                )

                // show handle once closed
                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Panel}`) as HTMLElement,
                  ),
                  Option.map(handleEl => {
                    handleEl.style.display = 'block'
                  }),
                )

                // hide handle once open
                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Handle}`) as HTMLElement,
                  ),
                  Option.map(handleEl => {
                    handleEl.style.display = 'block'
                  }),
                )

                pipe(
                  Option.fromNullable(
                    el.querySelector(`.${DraggablePanelParts.Body}`) as HTMLElement,
                  ),
                  Option.map(bodyEl => {
                    /**
                     * Make sure that when closed, the scroll resets to top. This is
                     * to not leave the scroll position to wherever it is when it was
                     * opened.
                     */
                    bodyEl.scrollTop = 0
                  }),
                )

                el.style.bottom = '0px'
                const start = el.offsetTop
                const end = window.innerHeight - context.panelHeight
                animateTop({
                  element: el,
                  start,
                  end,
                  duration: 50,
                })

                el.dataset['status'] = 'close'
              },
            }),
          ),
          // emit to parent
          enqueueActions(({ context, enqueue }) => {
            context.parentRef.pipe(
              Option.match({
                onNone: noop,
                onSome: parentRef => {
                  enqueue.sendTo(parentRef, DraggablePanelEvent.DidClose())
                },
              }),
            )
          }),
        ],
        on: {
          [DraggablePanelEvent.OnWillDrag]: {
            target: 'Dragging',
            actions: [
              assign({
                initialYPosition: ({ event, context }) =>
                  context.getDraggablePanelRef().pipe(
                    Option.map(el => event.dragPointY - el.offsetTop),
                    Option.getOrThrow,
                  ),
              }),
            ],
          },
          [DraggablePanelEvent.OnOpen]: 'Open',
        },
      },
      Dragging: {
        on: {
          [DraggablePanelEvent.OnOpen]: 'Open',
          [DraggablePanelEvent.OnClose]: 'Closed',
          [DraggablePanelEvent.OnDidDrag]: {
            actions: [
              enqueueActions(({ enqueue, event }) => {
                /**
                 * when dragging ends, if the treshold for opening a page has
                 * been reached where the dragging stopped, then open the page
                 * already and skip the dragging.
                 */
                const passingLine = window.innerHeight / 2
                const draggedSoFar = event.dragPointY
                const shouldOpen = draggedSoFar < passingLine
                if (shouldOpen) {
                  enqueue.raise(
                    DraggablePanelEvent.Open(),
                  )
                } else {
                  enqueue.raise(
                    DraggablePanelEvent.Close(),
                  )
                }
              }),
            ],
          },
          [DraggablePanelEvent.OnDragging]: {
            target: 'Dragging',
            reenter: true,
            actions: enqueueActions(({ enqueue, context, event }) =>
              context.getDraggablePanelRef().pipe(
                Option.match({
                  onNone: noop,
                  onSome: el => {
                    const topPosition = Number.clamp(
                      event.dragPointY - context.initialYPosition,
                      {
                        minimum: 0,
                        maximum: window.innerHeight - context.panelHeight,
                      },
                    )

                    /**
                     * while dragging, if the treshold for opening a page has
                     * been reached, then open the page already and skip the dragging.
                     */
                    const passingLine = window.innerHeight / 2
                    const shouldOpen = topPosition < passingLine

                    if (shouldOpen) {
                      enqueue.raise({ type: 'open' })
                    } else {
                      el.style.height = `auto`
                      el.style.top = `${topPosition}px`
                      el.style.bottom = '0px'
                    }
                  },
                }),
              ),
            ),
          },
        },
      },

    },
  }))

  export type Self = Effect.Effect.Success<ReturnType<typeof make>>

  export type ActorRef = ActorRefFrom<Self>

  export type ActorLogic = ActorLogicFrom<Self>

  export type ActorState = SnapshotFrom<Self>['value']

  export type ActorSnapshot = SnapshotFrom<Self>
}

export default DraggablePanelMachine

const animateTop = (params: {
  element: HTMLElement
  start: number
  end: number
  duration: number
}) => {
  const startTime = performance.now()

  const animate = (time: number) => {
    const elapsed = time - startTime
    const progress = Math.min(elapsed / params.duration, 1) // Cap progress at 1

    params.element.style.top = `${params.start + (params.end - params.start) * progress}px`

    if (progress < 1)
      requestAnimationFrame(animate)
  }

  requestAnimationFrame(animate)
}
