import { CardButton } from 'presentation/components/molecules/Card'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'

export type OpenInviteDicardErrorModalOptions = {
  onDiscard?: () => void
  onKeepProgress?: () => void
}

export const openInviteDiscardErrorModal = (options?: OpenInviteDicardErrorModalOptions) => {
  openErrorModal({
    title: 'Wait, you have unsaved changes!',
    message: 'You will lose your progress if you proceed. Are you sure you want to proceed with this action?',
    shouldHideCloseButton: true,
    onClose: () => {},
    actionButtons: () => (
      <>
        <CardButton
          variant='outline'
          colorScheme='negative'
          onClick={() => options?.onDiscard?.()}
        >
          Discard Changes
        </CardButton>

        <CardButton
          variant='solid'
          colorScheme='negative'
          autoFocus
          onClick={() => options?.onKeepProgress?.()}
        >
          Stay On Page
        </CardButton>
      </>
    ),
  })
}
