import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { PartialRange } from 'features/valueObjects/Range'
import { YearBuiltFilterDropdownDumb } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/YearBuiltFilterDropdown/YearBuiltFilterDropdown.dumb'
import { YearBuiltFilterDropdownDumbProps as Props } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/YearBuiltFilterDropdown/YearBuiltFilterDropdown.dumb.props'
import { shallow } from 'zustand/shallow'

export const YearBuiltFilterDropdown = () => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.yearBuilt ?? null,
      filterValue: state.local.report.data.filters.yearBuilt?.range ?? null,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: Props['onChange'] = range => {
    void filterComps({
      yearBuilt: {
        range,
      },
    })
  }

  if (!isReady) return null

  return (
    <YearBuiltFilterDropdownDumb
      value={filterValue || PartialRange.EMPTY}
      onChange={handleOnValueChange}
      subjectValue={subjectValue}
    />
  )
}
