import { Text, TextProps } from '@chakra-ui/react'
import { isNumber } from 'effect/Predicate'
import { useBuildTotalCount } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'

export const PreviewResultsCountText = (props: TextProps) => {
  const totalCountState = useBuildTotalCount()
  const totalCount = 'total' in totalCountState ? totalCountState.total : null

  return (
    <Text {...props}>
      {isNumber(totalCount)
        ? `${NumberLib.formatComma(totalCount)} ${pluralize('result', totalCount)}`
        : 'Loading...'}
    </Text>
  )
}
