import { ApolloError, useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { Function, Match } from 'effect'
import { CQ } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib, ProgramException, ReportableException } from 'libs/errors'
import { EnableMemberCommand, EnableMemberError, EnableMemberPayload } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { useCallback, useEffect, useRef } from 'react'

const MemberScreen_EnableMemberCommand = gql(/* GraphQL */ `
  mutation MemberScreen_EnableMemberCommand($id: ID!) {
    userEnable(id: $id) {
      user {
        id

        enterprise {
          ...EnterpriseMembers
        }
      }
      userErrors {
        message
      }
    }
  }
`)

/**
 * @NOTE Error management is weird because use case is weird -- we're looking to
 *   facade multiple commands including this under a single command
 */
export const useEnableMemberCommand = (): EnableMemberCommand => {
  const [enableMember, mutation] = useMutation(MemberScreen_EnableMemberCommand)
  const payloadRef = useRef<EnableMemberPayload | null>(null)
  const { state, transition } = CQReact.useStateManager<EnableMemberError, EnableMemberPayload>(CQ.createIdleState)
  const { id, regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback((payload: EnableMemberPayload) => {
    payloadRef.current = payload
    regenerateId()
    void enableMember({ variables: payload })
  }, [])

  useEffect(() => {
    Function.pipe(
      Match.value([payloadRef.current, mutation]),

      // Idle
      Match.when(
        [null, {}],
        () => transition.idle(),
      ),

      // Loading
      Match.when(
        [{}, { loading: true }],
        ([payload]) => transition.loading({ id, payload }),
      ),

      // Error
      Match.when(
        [{}, { error: Match.instanceOf(ApolloError) }],
        ([payload, mutation]) => {
          void ErrorLib.report(mutation.error)

          const error = new ProgramException('An error occurred while enabling a member')

          return transition.error({ id, error, payload })
        },
      ),

      // Success
      Match.when(
        [{}, { data: { userEnable: { user: {} } } }],
        ([payload]) => transition.success({ id, payload }),
      ),

      // Unexpected state
      Match.orElse(([payload, mutation]) => {
        void ErrorLib.report(new ReportableException('Unexpected state', {
          extraInfo: { payload, mutation },
        }))

        const error = new ProgramException('An error occurred while enabling a member')

        return transition.error({ id, error, payload: payload || FALLBACK_PAYLOAD })
      }),
    )
  }, [
    mutation.data,
    mutation.loading,
    mutation.error,
  ])

  return {
    execute,
    state,
  }
}

const FALLBACK_PAYLOAD = { id: '' }
