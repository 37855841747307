import { fitBounds } from '@math.gl/web-mercator'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { Location } from 'features/valueObjects/Location'
import { LngLatBounds } from 'mapbox-gl'
import { DEFAULT_FIT_BOUNDS_PADDING } from 'presentation/components/Map/Map.const'
import { MapLib } from 'presentation/components/Map/createMapStore'
import { useMemo } from 'react'
import { useMeasure } from 'react-use'
import { pipe } from 'remeda'

export const useInitialGlobeViewState = () => {
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  const history = useSearchHistoryStore(api => api.remote.history)

  const entries = useMemo(() =>
    history.status === 'success'
      ? history.data.entries
      : null, [history])

  const markers = useMemo(() =>
    entries && entries.map(entry => ({ id: entry.id, location: entry.property.location })),
  [entries])

  const markersWithLocation = useMemo(() =>
    markers && markers.filter(Location.hasLocation),
  [markers])

  const initialViewState = useMemo(() => {
    if (width === 0 || height === 0) return null
    if (!markersWithLocation) return null

    if (markersWithLocation.length > 0) {
      const inputBounds = new LngLatBounds()

      markersWithLocation.forEach(marker => {
        inputBounds.extend([marker.location.longitude, marker.location.latitude])
      })

      const { longitude, latitude, zoom } = fitBounds({
        width,
        height,
        bounds: [
          [inputBounds.getWest(), inputBounds.getSouth()],
          [inputBounds.getEast(), inputBounds.getNorth()],
        ],
        padding: DEFAULT_FIT_BOUNDS_PADDING,
      })

      return { longitude, latitude, zoom }
    } else {
      const WORLD_DIM = { width: 200, height: 200 } // Dimensions of the world at zoom level 0
      const ZOOM_MAX = 22 // renders globe at 1024^22x1024^22 (pixels)
      const ZOOM_MIN = 0 // renders globe at 200x200 (pixels)

      const zoomX = Math.log2(width / WORLD_DIM.width)
      const zoomY = Math.log2(height / WORLD_DIM.height)

      const zoom = pipe(
        // use the smaller zoom value to fit the entire globe
        Math.min(zoomX, zoomY),

        // make sure the zoom is within the valid range
        zoom => Math.max(ZOOM_MIN, Math.min(ZOOM_MAX, zoom)),

        // @HACK Map is not centering correctly with zoom less than 1, make sure it's at least 1
        zoom => Math.max(1, zoom),
      )

      return {
        zoom,
        latitude: MapLib.DEFAULT_CENTER[1],
        longitude: MapLib.DEFAULT_CENTER[0],
      }
    }
  },
  [width, height])

  return {
    containerRef, initialViewState,
  }
}
