import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import 'features/Skiptrace/infra/events/Skiptrace.subscribers'
import { SkiptraceProvider, useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { skiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state.store'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { toast } from 'presentation/components/Toast'
import { Tooltip } from 'presentation/components/Tooltip'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { MarketingFundsModal } from 'presentation/screens/SkiptraceScreen/components/MarketingFundsModal'
import { SelectFormatModal } from 'presentation/screens/SkiptraceScreen/components/SelectFormatModal/SelectFormatModal'
import { SkiptraceHeader, useSkiptraceHeaderState } from 'presentation/screens/SkiptraceScreen/components/SkiptraceHeader'
import { SkiptraceNoPreviousResultCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceNoPreviousResultCard'
import { SkiptraceNotEnoughInfoCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceNotEnoughInfoCard/SkiptraceNotEnoughInfoCard'
import { SkiptraceResultsScreen } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsScreen'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

export const SkiptraceScreen = () => (
  <SkiptraceProvider store={skiptraceStore}>
    <SkiptraceScreenDumb />
  </SkiptraceProvider>
)

/**
 * @TODO
 * error handling
 */
export const SkiptraceScreenDumb = () => {
  useSetupSkiptraceScreenState()
  useHandlePerformSkiptraceFailure()
  const state = useSkiptraceScreenState()
  const height = useLayoutStore(store => store.totalBodyHeight)

  useFundsToolbarEffect()

  useSkiptraceMobHeaderEffect()

  if (!state.propDetailsState.isReady) {
    return (
      <Center h='full' minH={height}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex
      flexDirection='column'
      minH={height}
      h='full'
      position='relative'
      overflow={mbp({ mobSm: 'auto', dtLg: 'visible' })}
      bgColor='neutral.200'
      maxW='1360px'
      w='full'
      mx={mbp({ mobSm: '0', dtSm: 'auto' })}
    >
      <Breakpoint
        tabSm={(
          state.propDetailsState.isReady && (
            <Flex
              {...mbpg({
                tabSm: {
                  justifyContent: 'stretch',
                  px: 3,
                },
                dtLg: {
                  justifyContent: 'center',
                },
              })}
              position='sticky'
              top={0}
              zIndex={2}
              bgColor='neutral.200'
            >
              <SkiptraceHeader w='full' />
            </Flex>
          )
        )}
      />

      {pipe(
        Match.value(state.status),
        Match.when('loading', () => (
          <Center flex='1'>
            <Spinner />
          </Center>
        )),
        Match.when('not-enough-info', () => (
          <Flex
            flexDirection='column'
            flex='1 1 0'
            px={mbp({ mobSm: 1, mob: 3 })}
            py={mbp({ mobSm: 2, mob: 3 })}
          >
            <SkiptraceNotEnoughInfoCard flex='1 1 0' />
          </Flex>
        )),
        Match.when('has-previous-result', () => (
          <SkiptraceResultsScreen />
        )),
        Match.when('no-previous-result', () => (
          <SkiptraceNoPreviousResultCard
            alignSelf='center'
            w={mbp({ mobSm: 'full', tabSm: '688px', dtSm: '736px' })}
          />
        )),
        Match.exhaustive,
      )}

      <SelectFormatModal />
    </Flex>
  )
}

const useSkiptraceScreenState = () => {
  const routeLeadId = useLeadId()
  const propDetailsState = usePropertyDetailsStore(propertyDetailsApi => {
    const propertyState = propertyDetailsApi.actions.getProperty.state
    const doesPropertyMatch = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    return {
      isReady: doesPropertyMatch,
      error: propertyState.status === 'error' ? propertyState.error : undefined,
      hasEnoughInfo: doesPropertyMatch
      && propertyState.data.status === 'with-details'
      && !!propertyState.data.ownership?.address,
    }
  }, shallow)

  const fundsState = useSkiptraceStore(skiptraceApi => {
    const skiptraceFundsState = skiptraceApi.entities.skiptraceFunds

    return {
      isReady: skiptraceFundsState.status === 'success',
      error: skiptraceFundsState.status === 'error' ? skiptraceFundsState.error : null,
    }
  })

  const skiptraceState = useSkiptraceStore(skiptraceApi => {
    const skiptraceState = skiptraceApi.entities.resultState
    const doesSkiptraceMatch = skiptraceState.status === 'success'
      && skiptraceState.params.leadId === routeLeadId

    return {
      isReady: doesSkiptraceMatch,
      error: skiptraceState.status === 'error' ? skiptraceState.error : undefined,
      existingResult: doesSkiptraceMatch && skiptraceState.result,
    }
  })

  const allState = {
    isReady: propDetailsState.isReady
    && fundsState.isReady
    && skiptraceState.isReady,
    error: skiptraceState.error
    || fundsState.error
    || propDetailsState.error,
    existingResult: skiptraceState.existingResult,
  }

  return {
    allState,

    status: !propDetailsState.hasEnoughInfo
      ? 'not-enough-info' as const
      : !allState.isReady
        ? 'loading' as const
        : skiptraceState.existingResult
          ? 'has-previous-result' as const
          : 'no-previous-result' as const,

    propDetailsState,
    fundsState,
    skiptraceState,
  }
}

const useSetupSkiptraceScreenState = () => {
  useSetupPropertyDetailsState()
  useSetupSkiptraceFundsState()
  useSetupSkiptraceState()
}

const useSetupPropertyDetailsState = () => {
  const routeId = useLeadId()

  const propDetailsState = usePropertyDetailsStore(propertyDetailsApi => {
    const getPropertyAction = propertyDetailsApi.actions.getProperty

    return {
      getProperty: getPropertyAction.execute,
    }
  }, shallow)

  useEffect(() => {
    if (!routeId) return

    void propDetailsState.getProperty({ leadId: routeId, origin: 'other' })
  }, [routeId])
}

const useSetupSkiptraceFundsState = () => {
  const routeId = useLeadId()

  const skiptraceState = useSkiptraceStore(skiptraceApi => ({
    getSkiptraceFundsInfo: skiptraceApi.actions.getSkiptraceFundsInfo.execute,
    error: skiptraceApi.entities.skiptraceFunds.status === 'error' ? skiptraceApi.entities.skiptraceFunds.error : null,
  }), shallow)

  // Get the property address directly
  const propertyAddress = usePropertyDetailsStore(store => {
    const propertyState = store.actions.getProperty.state
    if (
      propertyState.status === 'success'
      && propertyState.data.status === 'with-details'
      && propertyState.data.ownership?.address
    )
      return propertyState.data.ownership.address

    return null
  })

  useEffect(() => {
    if (!routeId || !propertyAddress) return

    void skiptraceState.getSkiptraceFundsInfo({
      leadId: routeId,
      address: propertyAddress,
    })
  }, [routeId, propertyAddress])

  // Handle getSkiptraceFundsInfo error
  useEffect(() => {
    if (!skiptraceState.error) return

    toast.error({
      title: 'Skiptrace funds info error',
      message: TOAST_PRESET.GENERIC_ERROR.message,
    })
  }, [skiptraceState.error])
}

const useSetupSkiptraceState = () => {
  const routeId = useLeadId()

  const skiptraceState = useSkiptraceStore(skiptraceApi => {
    const getResultAction = skiptraceApi.actions.getSkiptraceResult

    return {
      getSkiptraceResult: getResultAction.execute,
    }
  }, shallow)

  useEffect(() => {
    if (!routeId) return

    void skiptraceState.getSkiptraceResult({ leadId: routeId })
  }, [routeId])
}

const useHandlePerformSkiptraceFailure = () => {
  const routeLeadId = useLeadId()
  const skiptraceState = useSkiptraceStore(skiptraceApi => ({
    lastResult: skiptraceApi.actions.performSkiptrace.lastResult,
  }), shallow)

  useEffect(() => {
    if (!routeLeadId || !skiptraceState.lastResult) return

    const doesLeadIdMatch = skiptraceState.lastResult.payload.leadId === routeLeadId

    if (!doesLeadIdMatch) return

    const isNoResult = skiptraceState.lastResult.status === 'no-result'
    const isError = skiptraceState.lastResult.status === 'error'

    if (isNoResult) {
      toast.info({
        title: 'No skiptrace result found. You were not charged.',
      })
    } else if (isError) {
      toast.error({
        title: 'Skiptrace failed',
        message: TOAST_PRESET.GENERIC_ERROR.message,
      })
    }
  }, [skiptraceState.lastResult?.requestId, routeLeadId])
}

/**
 * Overrides the default HeaderMobile button on the right side with the
 * funds button when on mobile small
 */
const useFundsToolbarEffect = () => {
  const isMobileSmall = useSwitchBreakpoint({
    mobSm: true,
    mob: false,
  })
  const setHeaderMobRightToolbar = useLayoutStore(store => store.setHeaderMobRightToolbar)

  useEffect(() => {
    if (isMobileSmall)
      setHeaderMobRightToolbar(<MarketingFundsModal />)
    else
      setHeaderMobRightToolbar(null)

    return () => {
      setHeaderMobRightToolbar(null)
    }
  }, [isMobileSmall])
}

const useSkiptraceMobHeaderEffect = () => {
  const state = useSkiptraceHeaderState()

  const isMobile = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  })
  const setTitle = useLayoutStore(store => store.setTitle)

  useEffect(() => {
    const setSkiptraceHeaderTitle = () => {
      if (isMobile) {
        setTitle(
          <Box h='full' p={2} maxW='224px'>
            <Text
              textStyle='bodyXLFat'
              lineHeight='normal'
              color='ondark.1'
              textAlign='center'
            >
              Skiptrace
            </Text>
            {state.address && (
              <Tooltip label={state.address.line1}>
                <Text
                  minW={0}
                  w='full'
                  textStyle='bodyLFat'
                  color='highlight.500'
                  lineHeight='normal'
                  textAlign='center'
                  isTruncated
                >
                  {state.address.line1}
                </Text>
              </Tooltip>
            )}
          </Box>,
        )
      }
    }

    // Delay a bit to override the Route setting the Header Title
    const timer = setTimeout(() => {
      setSkiptraceHeaderTitle()
    }, 300)

    return () => clearTimeout(timer)
  }, [isMobile])
}
