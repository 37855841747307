import { MarkerSizeProp, MarkerType } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { MarkerContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerContainer'
import { BankOwnedIcon } from 'presentation/components/PropertyMarker/components/icons/BankOwnedIcon'
import { CorporateIcon } from 'presentation/components/PropertyMarker/components/icons/CorporateIcon'
import { InStateAbsenteeIcon } from 'presentation/components/PropertyMarker/components/icons/InStateAbsenteeIcon'
import { MilitaryIcon } from 'presentation/components/PropertyMarker/components/icons/MilitaryIcon'
import { OutOfStateIcon } from 'presentation/components/PropertyMarker/components/icons/OutOfStateIcon'
import { OwnerOccVacantIcon } from 'presentation/components/PropertyMarker/components/icons/OwnerOccVacantIcon'
import { OwnerOccupiedIcon } from 'presentation/components/PropertyMarker/components/icons/OwnerOccupiedIcon'
import { PreforeclosureIcon } from 'presentation/components/PropertyMarker/components/icons/PreforeclosureIcon'
import { SeniorIcon } from 'presentation/components/PropertyMarker/components/icons/SeniorIcon'
import { TrustIcon } from 'presentation/components/PropertyMarker/components/icons/TrustIcon'
import { UnknownIcon } from 'presentation/components/PropertyMarker/components/icons/UnknownIcon'
import { VacantIcon } from 'presentation/components/PropertyMarker/components/icons/VacantIcon'

export type MarkerProps = {
  markerType: MarkerType
} & MarkerSizeProp

export const OwnerOccupiedMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.sky'
      borderColor='marker.teal'
      {...props}
    />
    <OwnerOccupiedIcon markerType={props.markerType} />
  </>
)

export const OwnerOccVacantMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.sky'
      borderColor='marker.teal'
      {...props}
    />
    <OwnerOccVacantIcon markerType={props.markerType} />
  </>
)

export const OwnerOccSeniorMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.sky'
      borderColor='marker.teal'
      {...props}
    />
    <SeniorIcon markerType={props.markerType} />
  </>
)

export const InStateAbsenteeMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.yellow'
      borderColor='marker.gold'
      {...props}
    />
    <InStateAbsenteeIcon markerType={props.markerType} />
  </>
)

export const OutOfStateMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.yellow'
      borderColor='marker.gold'
      {...props}
    />
    <OutOfStateIcon markerType={props.markerType} />
  </>
)

export const BankOwnedMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.darkpink'
      borderColor='marker.scarlet'
      {...props}
    />
    <BankOwnedIcon markerType={props.markerType} />
  </>
)

export const CorporateMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.lightgreen'
      borderColor='marker.darkgreen'
      {...props}
    />
    <CorporateIcon markerType={props.markerType} />
  </>
)

export const TrustMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.lightblue'
      borderColor='marker.carolinablue'
      {...props}
    />
    <TrustIcon markerType={props.markerType} />
  </>
)

export const MilitaryMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.yellowgreen'
      borderColor='marker.olivegreen'
      {...props}
    />
    <MilitaryIcon markerType={props.markerType} />
  </>
)

export const UnknownMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.white-to-red'
      borderColor='marker.strawberrypink'
      {...props}
    />
    <UnknownIcon markerType={props.markerType} />
  </>
)

export const PreforeclosureMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.white-to-red'
      borderColor='marker.strawberrypink'
      {...props}
    />
    <PreforeclosureIcon markerType={props.markerType} />
  </>
)

export const VacantMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='marker.white-to-blue'
      borderColor='marker.cobaltblue'
      {...props}
    />
    <VacantIcon markerType={props.markerType} />
  </>
)

export const EmptyMarker = (props: MarkerProps) => (
  <>
    <MarkerContainer
      bgColor='gw.100.am'
      borderColor='grayweak.900'
      {...props}
    />
  </>
)
