import { createIcon } from '@chakra-ui/react'

export const PhoneIcon = createIcon({
  displayName: 'PhoneIcon',
  viewBox: '0 0 17 17',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.4658 11.78V13.78C15.4666 13.9656 15.4283 14.1494 15.3535 14.3195C15.2786 14.4896 15.1689 14.6423 15.0312 14.7679C14.8936 14.8934 14.731 14.9889 14.5541 15.0484C14.3771 15.1079 14.1896 15.13 14.0035 15.1133C11.9394 14.8904 9.9566 14.1894 8.21455 13.0666C6.5938 12.0431 5.21968 10.6774 4.18978 9.06662C3.05613 7.32742 2.35063 5.34728 2.13044 3.28662C2.11368 3.10226 2.13573 2.91646 2.19518 2.74104C2.25463 2.56561 2.35018 2.40441 2.47575 2.2677C2.60132 2.13099 2.75415 2.02176 2.92453 1.94697C3.0949 1.87218 3.27907 1.83346 3.46533 1.83329H5.47771C5.80325 1.8301 6.11885 1.94467 6.36568 2.15564C6.61251 2.36661 6.77374 2.65958 6.8193 2.97995C6.90424 3.62 7.06176 4.24844 7.28886 4.85329C7.37911 5.0919 7.39864 5.35123 7.34514 5.60054C7.29164 5.84985 7.16735 6.07869 6.987 6.25995L6.13509 7.10662C7.09 8.77565 8.48049 10.1576 10.1599 11.1066L11.0118 10.26C11.1941 10.0807 11.4244 9.95719 11.6753 9.90401C11.9261 9.85084 12.187 9.87026 12.4271 9.95995C13.0357 10.1857 13.6681 10.3422 14.3121 10.4266C14.6379 10.4723 14.9355 10.6354 15.1482 10.8849C15.361 11.1345 15.474 11.453 15.4658 11.78Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  ),
})
