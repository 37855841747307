import { useMapStore } from 'presentation/components/Map/useMapStore'
import { useEffect } from 'react'
import { MapRef } from 'react-map-gl'

export namespace MapHooks {
  export const useMap = () => useMapStore(state => state.computed.getMapRef())
  export const useOnMapReady = (onMapReady: (mapRef: MapRef) => void | (() => void)) => {
    const mapRef = useMap()
    useEffect(() => {
      if (!mapRef) return
      const cleanup = onMapReady(mapRef)

      return cleanup
    }, [mapRef])
  }
}
