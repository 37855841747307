import { createIcon } from '@chakra-ui/react'

export const SortDownIcon = createIcon({
  displayName: 'SortDownIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.25 5.25a.75.75 0 0 0-1.499 0v13.19l-1.719-1.721a.752.752 0 0 0-1.282.531.751.751 0 0 0 .22.531l3 2.998.01.011a.745.745 0 0 0 1.05-.009l3-3a.75.75 0 1 0-1.06-1.062l-1.72 1.72V5.25Zm6 .75a.75.75 0 0 1 0-1.5h1.5a.75.75 0 1 1 0 1.5h-1.5Zm0 4.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 1 1 0 1.5h-4.5Zm0 4.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 1 1 0 1.5h-7.5Zm-.75 3.75a.75.75 0 0 0 .75.75h10.5a.75.75 0 1 0 0-1.5h-10.5a.75.75 0 0 0-.75.75Z'
        fill='currentColor'
      />
    </svg>
  ),
})
