import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { NumberLib } from 'libs/Number'

export type DistanceTagProps = { value: number } & FlexProps

export const DistanceTag = (props: DistanceTagProps) => {
  const { value, ...flexProps } = props
  return (
    <Flex
      bgColor='onlight.1'
      borderRadius={3}
      py={0.5}
      px={1}
      gap={0.5}
      boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      alignItems='center'
      {...flexProps}
    >
      <Text
        textStyle='tagM'
        color='ondark.4'
      >
        DIST
      </Text>
      <Text
        textStyle='bodySHeavy'
        color='ondark.1'
      >
        {NumberLib.roundNPlaces(2)(value)}
        {' '}
        mile
      </Text>
    </Flex>
  )
}
