import { Box, Button, Flex, Image } from '@chakra-ui/react'
import cn from 'classnames'
import { HashLink } from 'presentation/components/HashLink'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { fadeIn, fadeInDown, fast, rollIn } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import SupportDeskPNG from './images/support-desk.png'

export const HelpSection: FC = () => (
  <Box
    bgColor='highlight.500'
  >
    <Box
      pt={mbp({ mobSm: '3' })}
      px={mbp({ mobSm: '2', tabSm: '0' })}
      pb={mbp({ mobSm: '26px', dtSm: '43px', dtLg: '4' })}
      display='flex'
      flexDirection={mbp({ mobSm: 'column', tabSm: 'row-reverse' })}
      justifyContent='space-between'
      w={mbp({ mobSm: '100%', mob: '352px', tabSm: '640px', dtSm: '720px', dtLg: '960px' })}
      mx='auto'
    >
      <ScrollAnimation
        className={cn(rollIn)}
      >
        {/* <SlowPlayfulRotate> */}
        <Image
          position='relative'
          display={mbp({ mobSm: 'none', tabSm: 'block' })}
          src={SupportDeskPNG}
          mt={mbp({ mobSm: '-93px', dtLg: '-120px' })}
          w={mbp({ mobSm: '268px', dtSm: '281px', dtLg: '402px' })}
        />
        {/* </SlowPlayfulRotate> */}
      </ScrollAnimation>

      <Box>
        <ScrollAnimation
          className={cn(fadeInDown, fast)}
        >
          <Box
            color='accent.xdarkpurple'
            textStyle={mbp({
              mobSm: 'inter-24-700',
              mob: 'inter-29-700',
              tabSm: 'inter-34-700',
              dtSm: 'inter-40-700',
              dtLg: 'inter-60-700',
            })}
            textAlign={mbp({ mobSm: 'center', tabSm: 'left' })}
            mb={mbp({ mobSm: '3', tabSm: '4', dtLg: '5' })}
          >
            Need some help
            {' '}
            <br />
            deciding?
          </Box>
        </ScrollAnimation>

        <ScrollAnimation
          className={cn(fadeIn)}
          staggerFactor='longer'
        >
          <Flex
            flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
            gap={mbp({ mobSm: '2', mob: '3' })}
            justifyContent={mbp({ mobSm: 'center', tabSm: 'flex-start' })}
          >
            <Button
              variant='solid'
              colorScheme='special'
              size={mbp({ mobSm: 'sm', tabSm: 'md', dt: 'lg' })}
              as={HashLink}
              to='#second-choose-plan'
            >
              CHOOSE PLAN
            </Button>
            <Button
              variant='outline'
              colorScheme='special'
              size={mbp({ mobSm: 'sm', tabSm: 'md', dt: 'lg' })}
              onClick={() => {
                void SupportWidget.openWidget({ topic: 'pricing' })
              }}
            >
              TALK TO SALES
            </Button>
          </Flex>
        </ScrollAnimation>
      </Box>
    </Box>
  </Box>
)
