import { ApolloError, useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { CurrentPlanSection_UserPlanQuery } from '__generated__/graphql'
import dayjs from 'dayjs'
import { PlanRank, getPlanRank } from 'features/Billing/Plans/getPlanRank'
import { FailedInvoiceSummary } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'

export type CurrentPlanModel = {
  userName: string
  planName: string
  daysRemaining: number | null
  price: number
  planInterval: string
  isCancelled: boolean
  failedInvoiceSummary?: FailedInvoiceSummary
  rank?: PlanRank
  isTrialing: boolean
}

export const CURRENT_PLAN_SECTION_USER_PLAN = gql(/* GraphQL */ `
  query CurrentPlanSection_UserPlan {
    billingSubscriptionProducts {
      ...PlanRank_Product
    }

    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
          plan {
            id
            price
            interval {
              unit
              count
            }
            name
          }
          currentPeriod {
            end
          }
          trialPeriod {
            end
          }
          cancelAt
          cancelAtPeriodEnd
          ...FailedInvoiceFlow_FailedInvoiceSummary
        }

        ... on BillingSubscriptionInactive {
          id
          id
          plan {
            id
            price
            interval {
              unit
              count
            }
            name
          }
        }
      }
    }
    me {
      id
      firstname
      lastname
    }
  }
`)

type UseCurrentPlanQueryResult = {
  data?: CurrentPlanModel
  loading: boolean
  error?: ApolloError
}

export const useCurrentPlanQuery = (): UseCurrentPlanQueryResult => {
  const { data, error, loading } = useQuery(CURRENT_PLAN_SECTION_USER_PLAN)
  const model: CurrentPlanModel | undefined = data ? mapQueryToModel(data) : undefined
  return {
    data: model,
    error,
    loading,
  }
}

const mapQueryToModel = (data: CurrentPlanSection_UserPlanQuery): CurrentPlanModel => {
  const billingSubscription = data.myEnterprise?.subscription
  const billingSubscriptionActive
    = data?.myEnterprise?.subscription?.__typename === 'BillingSubscriptionActive'
      ? data?.myEnterprise?.subscription
      : undefined

  const failedInvoiceSummary = unmaskFailedInvoiceFragment(billingSubscriptionActive)
  // @NOTE: There are too many nullables. Not sure if we expect everything to be available
  // or what to show if something is missing or if it is okay to be missing.

  const price = billingSubscription?.plan?.price ?? 0

  const intervalUnit: string
    = billingSubscription?.plan?.interval?.unit?.toLowerCase() ?? ''

  // @NOTE: What are the possible values for a plan interval? Is there a use for the intervalCount?
  // I am assuming that it might not always have the same prefix 'Per'. Are there any other possible prefixes?
  const planInterval = `Per ${intervalUnit}`

  const firstName = data?.me?.firstname ?? null
  const lastName = data?.me?.lastname ?? null
  const name = firstName !== null || lastName !== null
    ? [firstName, lastName].join(' ')
    : 'Unknown User'

  // @NOTE: If plan name has an interval included in it, we remove it. ie: 'Pro (yearly)'
  const planNameRaw = billingSubscription?.plan?.name?.replace(/\([^()]*\)/g, '')
  const planName: string = planNameRaw || 'Unknown'

  const endPeriod = billingSubscriptionActive?.currentPeriod?.end
    ? new Date(billingSubscriptionActive?.currentPeriod?.end)
    : null

  const daysRemaining = endPeriod
    ? dayjs(endPeriod.toLocaleDateString()).diff(Date.now(), 'd')
    : null

  const isCancelled = !!billingSubscriptionActive?.cancelAtPeriodEnd
    || billingSubscription?.__typename === 'BillingSubscriptionInactive'
    || false
  const trialEnd = billingSubscriptionActive?.trialPeriod?.end
  const isTrialing = !!trialEnd && dayjs(trialEnd).isAfter(Date.now())
  const planId = billingSubscription?.plan?.id || null

  return {
    userName: name,
    planName: `${planName} Plan`,
    daysRemaining,
    price,
    planInterval,
    isCancelled,
    isTrialing,
    failedInvoiceSummary,
    rank: planId
      ? getPlanRank(data.billingSubscriptionProducts, planId)
      : undefined,
  }
}
