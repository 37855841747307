import { Effect, Match } from 'effect'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'
import OwnersPropertiesRepoLive from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepoLive'
import OwnersPropertiesMachine from 'features/OwnersProperties/view/machine/OwnersPropertiesMachine'
import OwnersPropertiesMachineContext from 'features/OwnersProperties/view/machine/OwnersPropertiesMachineContext'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import OwnerPropertiesHooksLive from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks.live'
import { useProperty } from 'features/PropertyDetails/infra/react/hooks/useProperty'
import { useGetPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { ErrorLib } from 'libs/errors'
import { useResponsivePropertyModal } from 'presentation/components/PropertyModal/PropertyModal.hooks'
import PropertyModalService from 'presentation/components/PropertyModal/PropertyModalService'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import AppRouterContext from 'presentation/main/Router/AppRouterContext'
import { useEffect, useMemo } from 'react'
import AppCache from 'services/AppCache'
import AppCacheLive from 'services/AppCacheLive'
import AppRouter from 'services/AppRouter'

const OwnersPropertiesProviderLive = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const router = AppRouterContext.useRouter()
  const propertyModalApi = useResponsivePropertyModal({
    backLabel: 'Back to PropertyDetails',
  })

  const runtime = useMemo(() =>
    Effect.runtime<OwnersPropertiesMachine.Requirements>().pipe(
      Effect.provideService(OwnersPropertiesRepo, OwnersPropertiesRepoLive),
      Effect.provideService(AppRouter, { router }),
      Effect.provideService(AppCache, AppCacheLive),
      Effect.provideService(PropertyModalService, {
        open: propertyModalApi.actions.open,
      }),
      ErrorLib.tapCauseReporter,
      Effect.runSync,
    ), [])

  return (
    <OwnersPropertiesMachineContext.Provider
      runtime={runtime}
    >
      <OwnerPropertiesHooksLive.Provider>
        <OwnersPropertiesManager />
        {children}
      </OwnerPropertiesHooksLive.Provider>
    </OwnersPropertiesMachineContext.Provider>
  )
}

export default OwnersPropertiesProviderLive

const OwnersPropertiesManager = () => {
  useGetPropertyByRouteLeadId()

  const asyncInputViewModel = OwnersPropertiesHooks.useAsyncInput()
  const property = useProperty()
  const leadId = useLeadId()

  useEffect(() => {
    if (!leadId) return

    Match.value(property).pipe(
      Match.tag('Loading', () => {}),
      Match.tag('Success', property =>
        Match.value(property.value).pipe(
          Match.when({ status: 'without-details' }, () => {
            asyncInputViewModel.onEmpty()
          }),
          Match.when({ status: 'with-details' }, property => {
            asyncInputViewModel.onSuccess({
              leadId,
              parcelId: property.parcelId,
            })
          }),
          Match.exhaustive,
        ),
      ),
      Match.tag('Error', () => {
        asyncInputViewModel.onError()
      }),
      Match.exhaustive,
    )
  }, [property])

  return null
}
