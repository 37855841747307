import { HStack, VStack } from '@chakra-ui/react'
import { PropertiesListPreviewPanel } from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/PropertiesListPreviewPanel'
import { ListPreviewMap } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMap'

const ListPreviewDesktopScreen = () => (
  <HStack align='stretch' h='layout-height' spacing={0}>
    <ListPreviewMap />
    <VStack align='stretch' flexShrink={0}>
      <PropertiesListPreviewPanel />
    </VStack>
  </HStack>
)

export default ListPreviewDesktopScreen
