import { toast as baseToast } from 'presentation/components/Toast'
import { ToastParams } from 'presentation/components/Toast/Toast.types'
import { useEffect, useRef } from 'react'

export const useOneToastAtATime = (
  getToastParams: () => ToastParams | null,
  deps: unknown[],
) => {
  const oneAtATime = useOneToastAtATimeFn()
  useEffect(() => {
    const toastParams = getToastParams()
    if (!toastParams) return
    oneAtATime.toast(toastParams)
  }, deps)
}

export const useOneToastAtATimeFn = () => {
  const lastToastIdRef = useRef<string | number | null>(null)

  const toast = (params: ToastParams) => {
    if (lastToastIdRef.current) baseToast.dismiss(lastToastIdRef.current)
    lastToastIdRef.current = baseToast(params)
    return lastToastIdRef.current
  }

  const createToastFn = (
    status: 'info' | 'success' | 'error',
  ) =>
    (params: Omit<ToastParams, 'status'>) => {
      if (lastToastIdRef.current) baseToast.dismiss(lastToastIdRef.current)
      lastToastIdRef.current = baseToast[status](params)
      return lastToastIdRef.current
    }

  toast.error = createToastFn('error')
  toast.success = createToastFn('success')
  toast.info = createToastFn('info')
  toast.dismiss = (id: string | number) => {
    baseToast.dismiss(id)
    if (lastToastIdRef.current === id) lastToastIdRef.current = null
  }

  return {
    toast,
  }
}
