import { createIcon } from '@chakra-ui/react'

export const AscDescIcon = createIcon({
  displayName: 'AscDescIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 14.5V21.5'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.5 18L18 21.5L14.5 18'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 9.5V2.5'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 6L6 2.5L9.5 6'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
