import { gql } from '__generated__'

export const PROPERTY_DETAILS_QUERY = gql(/* GraphQL */`
query PropertyDetails($parcelId: String!) {
  parcel(id: $parcelId) {
    id
    address {
      ...VerifiedAddressBasicFields
      ...VerifiedAddressLocationFields
      ...UnverifiedAddressBasicFields
      ...UnverifiedAddressLocationFields
    }
    ownerDetails {

      ownerClassification
      ownershipMonths
      owners {
        name {
          ...ParcelContactNameFields
        }
        address {
          ...VerifiedAddressBasicFields
          ...UnverifiedAddressBasicFields
        }
        buyerDetails {
          historicalDeals(first: 1) {
            pageInfo {
              totalCount
            }
          }
        }
      }
      ownerStatus
      isForeclosure
      isHomestead
      isSenior
      isTaxDelinquent
      isVacant
    }
    buildingDetails {
      bathroomsCount {
        total
        full
        half
      }
      bedroomsCount
      garageSpacesCount
      roomsCount
      fireplacesCount
      storiesCount
      buildingAreaSqft
      livingAreaSqft
      basementAreaSqft
      garageAreaSqft
      yearBuilt
      basementType
      heatingType
      heatingFuelType
      poolAreaSqft
    }
    landDetails {
      legalDescription
      assessorParcelNumber
      standardizedUseClass
      standardizedUseType
      censusTract
      lotAreaSqft
      lotAreaAcres
      lot
      block
      subdivision
      county {
        ... on UsCounty {
          name
        }
      }
    }
    lastSale {
      ...ParcelTransferFields
    }
    valuationDetails {
      condition
      equity
      loanToValue
      high
      low
      value
    }
    currentLiens {
      ...ParcelLienFields
    }
    preforeclosures {
      edges {
        node {
          id
          document {
            recordingDate
            type
            defaultAmount

            documentNumber
            caseNumber

            trustee {
              contacts {
                name {
                  full
                }
              }
              phone
              address {
                ...VerifiedAddressBasicFields
                ...UnverifiedAddressBasicFields
              }
            }
          }
          lien {
            recordingDate
            documentNumber
            originalAmount
            servicer {
              name
              address {
                ...VerifiedAddressBasicFields
                ...UnverifiedAddressBasicFields
              }
            }
            lienHolder {
              name
            }
          }
          auction {
            date
            time
            openingBid
            location
          }
        }
      }
    }
    taxHistory {
      edges {
        node {
          year
          billed
          assessment {
            total
            land
            improvements
          }
        }
      }
    }
    transferHistory {
      edges {
        node {
          ...ParcelTransferFields
        }
      }
    }
    mlsPropertyListings(first: 100) {
      edges {
        node {
          id
          organization {
            id
            disclosureText
            logoUrl
            name
          }
          resoPropertyListing {
            bathroomsFull
            bathroomsHalf
            bedroomsTotal
            buildingAreaTotal
            city
            closeDate
            closePrice
            countyOrParish
            daysOnMarket
            garageSpaces
            latitude
            listAgentDirectPhone
            listAgentEmail
            listAgentFullName
            listAgentPreferredPhone
            listAgentPreferredPhone
            listingId
            listingKey
            listOfficeEmail
            listOfficeName
            listOfficePhone
            listPrice
            livingArea
            livingAreaUnits
            longitude
            lotSizeAcres
            lotSizeSquareFeet
            onMarketTimestamp
            originalEntryTimestamp
            poolPrivateYN
            postalCode
            propertyType
            publicRemarks
            standardStatus
            stateOrProvince
            stories
            streetDirPrefix
            streetDirSuffix
            streetName
            streetNumber
            streetSuffix
            subdivisionName
            unitNumber
            yearBuilt
            buyerAgentEmail
            buyerAgentFullName
            buyerAgentDirectPhone
            buyerAgentPreferredPhone
            buyerOfficeEmail
            buyerOfficeName
            buyerOfficePhone
          }
          media (types: [Jpeg, Png]) {
            edges {
              node {
                mediaURL
                shortDescription
              }
            }
          }
        }
      }
    }
  }
}

fragment VerifiedAddressBasicFields on AddressVerified {
line1
city
state
postalCode
}

fragment UnverifiedAddressBasicFields on AddressUnverified {
line1
city
state
postalCode
}

fragment VerifiedAddressLocationFields on AddressVerified {
location {
  latitude
  longitude
}
}

fragment UnverifiedAddressLocationFields on AddressUnverified {
location {
  latitude
  longitude
}
}

fragment ParcelContactNameFields on ParcelContactName {
first
middle
last
}

fragment ParcelContactFields on ParcelContact {
name {
  first
  last
  middle
  full
  suffix
}
address {
  ...VerifiedAddressBasicFields
  ...UnverifiedAddressBasicFields
}
}


fragment ParcelRawContactFields on ParcelRawContact {
nameRaw: name {
  first
  last
  middle
  full
  suffix
}
address {
  ...VerifiedAddressBasicFields
  ...UnverifiedAddressBasicFields
}
}

fragment ParcelTransferFields on ParcelTransfer {
documentNumber
documentType
recordingDate
saleDate
saleAmount
purchaseMethod
transactionType
buyers {
  ...ParcelContactFields
  ...ParcelRawContactFields
}
sellers {
  ...ParcelContactFields
  ...ParcelRawContactFields
}
liens {
  ...ParcelLienFields
}
}

fragment ParcelLienFields on ParcelLien {
recordingDate
type
documentNumber
maturityDate
originalAmount
estimatedBalance
estimatedPayment
termMonths
interestRatePercent
lienHolder {
  ...ParcelContactFields
  ...ParcelRawContactFields
}
}
`)
