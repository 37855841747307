import { ChoosePlan_TargetProductFragment } from '__generated__/graphql'
import { TargetPlan } from 'presentation/screens/Billing/components/ChoosePlanFlow/ChoosePlanModal.types'

export const unmaskTargetProductFragment = (
  data: ChoosePlan_TargetProductFragment,
): TargetPlan[] => {
  const product = data

  const targetPlans = product.plans.map((p): TargetPlan => {
    if (!p.interval.unit) throw new Error('Plan interval unit missing')
    return {
      planId: p.id,
      planName: product.name,
      price: p.price,
      intervalUnit: p.interval.unit,
    }
  })

  return targetPlans
}
