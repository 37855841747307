import { differenceInDays, differenceInMonths } from 'date-fns'
import { Option } from 'effect'
import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { MLSListing } from 'features/valueObjects/MLSListing'
import { DateLib } from 'libs/Date'
import { isNumber } from 'remeda'

export const patchLastSale = (
  propertyDetails: Domain.PropertyWithDetails,
): Domain.PropertyWithDetails => {
  const latestMlsListing = propertyDetails.mlsListings.reduce<MLSListing | null>(
    (latestSaleListing, thisListing) => {
      if (thisListing.status !== 'SOLD') return latestSaleListing

      // @FIX Fix MLSListing.saleDate being returned as string
      thisListing.saleDate = DateLib.fixDateStringOrNull(thisListing?.saleDate)

      if (!thisListing?.saleDate || thisListing.isRental) return latestSaleListing

      if (!latestSaleListing?.saleDate) return thisListing

      return thisListing.saleDate.getTime() > latestSaleListing.saleDate.getTime()
        ? thisListing
        : latestSaleListing
    },
    null,
  )

  const latestMlsSaleDate = latestMlsListing?.saleDate
  const latestTransfer = propertyDetails.transfers.reduce<Domain.Transfer | null>(
    (latestTransfer, thisTransfer) => {
      const thisDate = thisTransfer.saleDate || thisTransfer.recordingDate
      const latestDate = latestTransfer?.saleDate || latestTransfer?.recordingDate
      const isThisRefinance = thisTransfer.transactionType === 'REFINANCE'

      if (isThisRefinance) return latestTransfer
      if (!thisDate) return latestTransfer
      if (!latestDate) return thisTransfer

      return thisDate.getTime() > latestDate.getTime() ? thisTransfer : latestTransfer
    },
    null,
  )
  const latestTransferDate = latestTransfer?.saleDate || latestTransfer?.recordingDate

  const lastSale = propertyDetails.lastSale
  const lastSaleDate = lastSale?.saleDate

  const isLastSaleOutdatedByMls = isALaterThanBByThreshold(latestMlsSaleDate, lastSaleDate)
  const isLatestTransferOutdatedByMls = isALaterThanBByThreshold(latestMlsSaleDate, latestTransferDate)

  // ====================================
  // Invalidate/patch lastSale via MLS WHEN outdated by MLS
  // ====================================

  if (latestMlsListing && isLastSaleOutdatedByMls) {
    propertyDetails.lastSale = {
      buyerInfo: null,
      documentNumber: null,
      documentType: null,
      liens: [],
      purchaseMethod: null,
      recordingDate: null,
      sellerInfo: null,
      transactionType: null,
      salePrice: latestMlsListing.salePrice,
      saleDate: latestMlsListing.saleDate,
    }

    propertyDetails.valuation = {
      // Fallbacks on data points we don't need to invalidate
      estimateRange: null,

      // Spread any existing data points we don't want to invalidate
      ...propertyDetails.valuation,

      // Invalidate the following data points
      equity: null,
      equityType: null,
      loanToValue: null,
    }

    propertyDetails.ownership = {
      // Fallbacks on data points we don't need to invalidate
      isForeclosure: false,
      isVacant: false,

      // Spread any existing data points we don't want to invalidate
      ...propertyDetails.ownership,

      // Recalculate the following data points
      months: latestMlsListing.saleDate
        ? differenceInMonths(new Date(), latestMlsListing.saleDate)
        : latestMlsListing.saleDate,

      // Invalidate the following data points
      owners: [],
      address: null,
      classification: null,
      isSenior: null,
      potentialPropertiesCount: Option.none(),
    }
  }

  // ====================================
  // Patch lastSalePrice via MLS if MLS is not outdated by latest transfer
  // ====================================
  const isMLSOutdatedByTransfer = isALaterThanBByThreshold(latestTransferDate, latestMlsSaleDate)
  const shouldPatchLastSalePrice = latestMlsListing?.salePrice
    && !isMLSOutdatedByTransfer

  if (shouldPatchLastSalePrice) {
    propertyDetails.lastSale = {
      ...EMPTY_TRANSFER,
      ...propertyDetails.lastSale,
      salePrice: latestMlsListing?.salePrice,
    }
  }

  const isLatestTransferCloseEnoughWithLastSale = isCloseEnough(latestTransferDate, lastSaleDate)
  const latestTransferPurchaseMethod = latestTransfer?.purchaseMethod
  const lastSalePurchaseMethod = propertyDetails.lastSale?.purchaseMethod
  const shouldPatchLastSalePurchaseMethod = !lastSalePurchaseMethod
    && latestTransferPurchaseMethod
    && isLatestTransferCloseEnoughWithLastSale

  // ====================================
  // Patch purchaseMethod via latest transfer WHEN missing and date is close enough
  // ====================================

  if (shouldPatchLastSalePurchaseMethod) {
    propertyDetails.lastSale = {
      ...EMPTY_TRANSFER,
      ...propertyDetails.lastSale,
      purchaseMethod: latestTransferPurchaseMethod,
    }
  }

  // ====================================
  // Patch months of ownership WHEN missing and lastSaleDate is available
  // ====================================

  const monthsOfOwnership = propertyDetails.ownership?.months
  const shouldPatchMonthsOfOwnership = !isNumber(monthsOfOwnership)
    && lastSaleDate

  if (shouldPatchMonthsOfOwnership) {
    propertyDetails.ownership = {
      ...EMPTY_OWNERSHIP,
      ...propertyDetails.ownership,
      months: differenceInMonths(new Date(), lastSaleDate),
    }
  }

  // ====================================
  // Patch owner names via latest transfer WHEN latest transfer has more owners
  //   and date is close enough and is not outdated by MLS or there's no lastSaleDate
  // ====================================

  const shouldConsiderLatestTransferForOwnerInfo = !isLatestTransferOutdatedByMls
    && (
      isLatestTransferCloseEnoughWithLastSale
      || !lastSaleDate
    )

  const ownersFromProps = propertyDetails.ownership?.owners || []
  const ownersFromTransfer = latestTransfer?.buyerInfo?.buyers || []
  const shouldPatchOwnerNames = ownersFromTransfer.length > ownersFromProps.length
    && shouldConsiderLatestTransferForOwnerInfo

  if (shouldPatchOwnerNames) {
    propertyDetails.ownership = {
      ...EMPTY_OWNERSHIP,
      ...propertyDetails.ownership,
      owners: ownersFromTransfer.map(owner => ({
        name: {
          full: owner.name,
          first: owner.name,
          last: '',
        },
      })),
    }
  }

  // ====================================
  // Patch owner address via latest transfer WHEN missing and lastest transfer
  //   is not outdated by MLS or is close enough or there's no last sale
  // ====================================
  const ownerAddressFromProp = propertyDetails.ownership?.address
  const ownerAddressFromTransfer = latestTransfer?.buyerInfo?.address
  const shouldPatchOwnerAddress = !ownerAddressFromProp
    && ownerAddressFromTransfer
    && shouldConsiderLatestTransferForOwnerInfo

  if (shouldPatchOwnerAddress) {
    propertyDetails.ownership = {
      ...EMPTY_OWNERSHIP,
      ...propertyDetails.ownership,
      address: ownerAddressFromTransfer,
    }
  }

  return propertyDetails
}

const EMPTY_OWNERSHIP: Domain.Ownership = {
  address: null,
  classification: null,
  isForeclosure: null,
  isSenior: null,
  isVacant: null,
  months: null,
  owners: [],
  potentialPropertiesCount: Option.none(),
}

const EMPTY_TRANSFER: Domain.Transfer = {
  recordingDate: null,
  saleDate: null,
  salePrice: null,
  documentNumber: null,
  documentType: null,
  purchaseMethod: null,
  transactionType: null,
  buyerInfo: null,
  sellerInfo: null,
  liens: [],
}

const THRESHOLD_DAYS = 14
const isCloseEnough = (
  dateA: Date | null | undefined,
  dateB: Date | null | undefined,
) => !!dateA
&& !!dateB
&& Math.abs(differenceInDays(dateA, dateB)) <= THRESHOLD_DAYS

const isALaterThanBByThreshold = (
  dateA: Date | null | undefined,
  dateB: Date | null | undefined,
) => !!dateA
&& !!dateB
&& dateA.getTime() > dateB.getTime()
&& !isCloseEnough(dateA, dateB)
