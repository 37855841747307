import { CircularProgress, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import ZStack from 'presentation/components/ZStack'

export type ConsumableType = 'skiptrace' | 'comps' | 'property-lookup'

// Map of consumer type to display text
export const consumableDisplayText = {
  'skiptrace': {
    singular: 'Skiptrace',
    term: 'skip',
    shortName: 'Skip',
  },
  'comps': {
    singular: 'Comp Report',
    term: 'comp report',
    shortName: 'Comp',
  },
  'property-lookup': {
    singular: 'Property Lookup',
    term: 'property lookup',
    shortName: 'Lookup',
  },
}

export type ConsumableCircularStatProps = {
  remainingFree: number
  totalFree: number
  consumableType: ConsumableType
  customLabel?: string
}

export const ConsumableCircularStat = ({
  remainingFree,
  totalFree,
  consumableType,
  customLabel,
}: ConsumableCircularStatProps) => {
  const hasNoFree = remainingFree <= 0
  const progress = hasNoFree
    ? 100
    : (remainingFree / totalFree) * 100

  const terminology = consumableDisplayText[consumableType]

  return (
    <ZStack>
      <CircularProgress
        capIsRound
        value={progress}
        color={hasNoFree ? 'negative.500' : 'positive.500'}
        trackColor='grayweak.500'
        size='136px'
        thickness={5}
      />
      <Text
        textStyle='bodyXLFat'
        color={hasNoFree ? 'negative.500' : 'positive.500'}
        textAlign='center'
        px={2}
      >
        {customLabel || `${pluralize(`free ${terminology.shortName}`, remainingFree, true)}\nleft`}
      </Text>
    </ZStack>
  )
}
