import { ComponentProps, ComponentType, ReactNode, createElement } from 'react'

type ProviderTuple<T extends ComponentType> = [T, ComponentProps<T>]

export const ComposeComponents = <T extends ComponentType>({ providers, children }: {
  providers: (ProviderTuple<any> | T)[]
  children: ReactNode
}) => (
  <>
    {providers.reduceRight(
      (acc, providerConfig) => {
        if (Array.isArray(providerConfig)) {
          const [Provider, props] = providerConfig
          return createElement(Provider, props, acc)
        }
        return createElement(providerConfig, {}, acc)
      },
      children,
    )}
  </>
)
