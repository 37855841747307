import { Box } from '@chakra-ui/react'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { MAPBOX_TOKEN } from 'presentation/const/env.const'
import { ComponentProps, useEffect, useMemo } from 'react'
import MapFromMapbox from 'react-map-gl'
import { useMeasure } from 'react-use'
import { debounce } from 'remeda'
import { shallow } from 'zustand/shallow'

export const Map = (props: Partial<ComponentProps<typeof MapFromMapbox>>) => {
  const { setMapRef, getMapStyle, onMapLoad } = useMapStore(mapStore => ({
    setMapRef: mapStore.setMapRef,
    getMapStyle: mapStore.getMapStyle,
    onMapLoad: mapStore.onMapLoad,
  }), shallow)

  const { containerRef } = useDebouncedRepaintOnResize()

  return (
    <Box ref={containerRef} boxSize='100%'>
      <MapFromMapbox
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={getMapStyle()}
        onLoad={() => onMapLoad()}
        preserveDrawingBuffer
        ref={setMapRef}
        {...props}
      />
    </Box>
  )
}

const useDebouncedRepaintOnResize = () => {
  const { mapRef } = useMapStore(state => ({
    mapRef: state.computed.getMapRef(),
  }), shallow)
  const [containerRef, { height, width }] = useMeasure<HTMLDivElement>()

  const unoptimizedRepaint = () => {
    mapRef?.getMap().resize()
  }

  const DEBOUNCE_RATE = 100
  const debouncedRepaint = useMemo(() =>
    debounce(unoptimizedRepaint, { waitMs: DEBOUNCE_RATE }),
  [mapRef])

  useEffect(() => {
    if (!mapRef) return
    debouncedRepaint.call()
  }, [height, width, mapRef])

  return { containerRef }
}
