import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeSingleFamIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30 17L38.6842 23.3746V21.1994C38.6842 20.7562 39.0376 20.397 39.4736 20.397C39.9097 20.397 40.2631 20.7562 40.2631 21.1994V24.5337L44.6727 27.7705C45.0262 28.03 45.1058 28.5316 44.8505 28.8908C44.5952 29.2501 44.1017 29.331 43.7482 29.0715L30 18.9796L16.2518 29.0715C15.8983 29.331 15.4048 29.2501 15.1495 28.8908C14.8942 28.5316 14.9738 28.03 15.3273 27.7705L30 17ZM30 19.4109L41.8421 28.0081V43.6667H18.1579V28.0081L30 19.4109ZM19.7369 28.834V42.0619H40.2631V28.834L30 21.3831L19.7369 28.834Z'
      fill={props.color}
    />
    <chakra.rect
      x='26.625'
      y='35.625'
      width='6.75px'
      height='6.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='26.625'
      y='29.625'
      width='6.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
  </chakra.g>
)
