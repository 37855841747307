import { nanoid } from 'nanoid'
import { BaseTabsProps } from 'presentation/components/BaseTabs/BaseTabs'
import { Dispatch, ForwardRefExoticComponent, MutableRefObject, PropsWithChildren, PropsWithoutRef, RefAttributes, SetStateAction, createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

export type SwitchTabsAPI = {
  setActiveTabKey: (tabKey: string) => void
}

type SwitchTabsInfo = {
  activeTabKey: string
  tabKeys: string[]
  contentKeys: string[]
}

/**
 * Context is convoluted because we're trying to implement Chakra API which
 * depends on legacy Children API, without using legacy Children API.
 *
 * With Children API we can immediately represent the children as array, however
 * without Children API we try to achieve the array by registering them on mount.
 *
 * Issue with that is on first array where things are yet to be registered, we
 * can't access the array representation on first render unless we use ref
 * instead of state.
 *
 * But we still need state for reactivity...
 *
 * ...so we have both.
 *
 * ---
 *
 * This would be simplified by following React's alternative to Children API
 * which deviates from Chakra's design, but let's keep this like this for now to
 * avoid having to refactor consumers of Tab components (no time).
 */
type SwitchTabsContextValue = SwitchTabsInfo & {
  setActiveTabKey: (tabKey: string) => void
  setTabKeys: Dispatch<SetStateAction<string[]>>
  setTabContentKeys: Dispatch<SetStateAction<string[]>>

  /**
   * We are repeating info in ref in order to be able to get data within the
   * first render, otherwise (with useState + useEffect) we would have to wait
   * for the second render to get the data.
   *
   * Information that are crucial for first render include the active tab,
   * active content, and whether or not the tab is the first tab (for styling.)
   */
  ref: MutableRefObject<SwitchTabsInfo>
  colorScheme?: BaseTabsProps['colorScheme']
  size?: BaseTabsProps['size']
}

const INITIAL_SWITCH_TABS_INFO: SwitchTabsInfo = {
  activeTabKey: '',
  tabKeys: [],
  contentKeys: [],
}

const INITIAL_CONTEXT_VALUE: SwitchTabsContextValue = {
  ...INITIAL_SWITCH_TABS_INFO,
  setActiveTabKey: () => {},
  setTabKeys: () => {},
  setTabContentKeys: () => {},
  ref: {
    current: INITIAL_SWITCH_TABS_INFO,
  },
}

const SwitchTabContext = createContext(INITIAL_CONTEXT_VALUE)

// ========================================
// TabProvider
// ========================================

type TabProviderProps = PropsWithChildren<Pick<BaseTabsProps, 'colorScheme' | 'size'> & {
  initialActiveTabKey?: string
  onActiveTabKeyChange?: (tabKey: string) => void
}>

type TabProvider = ForwardRefExoticComponent<
  PropsWithoutRef<TabProviderProps>
  & RefAttributes<SwitchTabsAPI>
>

export const SwitchTabsProvider: TabProvider = forwardRef(({
  initialActiveTabKey,
  onActiveTabKeyChange,
  size,
  colorScheme,
  children,
}, forwardedRef) => {
  const [activeTabKeyState, setActiveTabKeyState] = useState(initialActiveTabKey || '')
  const [tabKeys, setTabKeys] = useState<string[]>([])
  const [contentKeys, setTabContentKeys] = useState<string[]>([])
  /**
   * @IMPORTANT Create new reference by spreading to prevent multiple instances
   * having one reference
   */
  const ref = useRef<SwitchTabsInfo>({
    ...INITIAL_SWITCH_TABS_INFO,
    activeTabKey: initialActiveTabKey || INITIAL_SWITCH_TABS_INFO.activeTabKey,
  })

  const setActiveTabKey = useCallback((tabKey: string) => {
    setActiveTabKeyState(tabKey)
    ref.current.activeTabKey = tabKey
  }, [setActiveTabKeyState])

  // ========================================
  // Prepare API to be passed to ref
  // ========================================
  useImperativeHandle(forwardedRef, () => ({
    setActiveTabKey,
  }), [setActiveTabKeyState])

  // ========================================
  // Prepare context value
  // ========================================

  const contextValue = useMemo<SwitchTabsContextValue>(() => ({
    activeTabKey: activeTabKeyState,
    tabKeys,
    contentKeys,
    setActiveTabKey,
    setTabKeys,
    setTabContentKeys,
    ref,
    size,
    colorScheme,
  }), [
    activeTabKeyState,
    tabKeys,
    contentKeys,
    setActiveTabKey,
    setTabKeys,
    setTabContentKeys,
    ref,
    size,
    colorScheme,
  ])

  // ========================================
  // Reset refs and state on unmount lest HMR will go cray cray
  // ========================================
  useEffect(() => () => {
    setActiveTabKey(initialActiveTabKey || '')
    setTabKeys([])
    setTabContentKeys([])
  }, [])

  useEffect(() => {
    onActiveTabKeyChange?.(activeTabKeyState)
  }, [activeTabKeyState])

  return (
    <SwitchTabContext.Provider value={contextValue}>
      {children}
    </SwitchTabContext.Provider>
  )
})

SwitchTabsProvider.displayName = 'TabsProvider'

// ========================================
// useTabsContext
// ========================================

export const useSwitchTabsContext = () => useContext(SwitchTabContext)

// ========================================
// useTabButton
// ========================================

type UseSwitchTabButton = (params: {
  tabKey?: string
  hasContent: boolean
  onClick?: () => void
}) => {
  tabKey: string
  contentKey: string
  index: number
  isActive: boolean
  onClick: () => void
}

export const useSwitchTabButton: UseSwitchTabButton = params => {
  const ctx = useSwitchTabsContext()
  const tabKey = useMemo(() => params.tabKey || nanoid(), [])

  // ========================================
  // Ref modifications for first render
  // ========================================
  const addTabKeyIfMissing = addIfMissing(tabKey)

  ctx.ref.current.tabKeys = addTabKeyIfMissing(ctx.ref.current.tabKeys)

  const shouldSetActiveTabKey = !ctx.ref.current.activeTabKey
  if (shouldSetActiveTabKey)
    ctx.ref.current.activeTabKey = tabKey

  // ========================================
  // State modifications for reactivity
  // ========================================
  useEffect(() => {
    ctx.setTabKeys(addTabKeyIfMissing)
    if (shouldSetActiveTabKey)
      ctx.setActiveTabKey(tabKey)
  }, [])

  // ========================================
  // Derived state
  // ========================================
  const index = ctx.ref.current.tabKeys.indexOf(tabKey)
  const contentKey = ctx.ref.current.contentKeys[index]
  const isActive = ctx.ref.current.activeTabKey === tabKey

  const onClick = useCallback(() => {
    if (params.onClick) {
      // For now assume this is trying to override the default onClick
      params.onClick()
      return
    }

    if (!params.hasContent) return

    ctx.ref.current.activeTabKey = tabKey
    ctx.setActiveTabKey(tabKey)
  }, [ctx.setActiveTabKey, tabKey, params.hasContent])

  return {
    index,
    isActive,
    onClick,
    tabKey,
    contentKey,
  }
}

// ========================================
// UseTabContent
// ========================================

type UseTabContent = () => {
  tabKey: string
  contentKey: string
  isActive: boolean
}

export const useSwitchTabContent: UseTabContent = () => {
  const ctx = useSwitchTabsContext()
  const contentKey = useMemo(() => nanoid(), [])

  // ========================================
  // Ref modifications for first render
  // ========================================
  const addContentKeyIfMissing = addIfMissing(contentKey)

  ctx.ref.current.contentKeys = addContentKeyIfMissing(ctx.ref.current.contentKeys)

  // ========================================
  // State modifications for reactivity
  // ========================================
  useEffect(() => {
    ctx.setTabContentKeys(addContentKeyIfMissing)
  }, [])

  // ========================================
  // Derived state
  // ========================================
  const contentIndex = ctx.ref.current.contentKeys.indexOf(contentKey)
  const tabKey = ctx.ref.current.tabKeys[contentIndex]
  const isActive = ctx.ref.current.activeTabKey === tabKey

  return {
    tabKey,
    contentKey,
    isActive,
  }
}

// eslint-disable-next-line @stylistic/comma-dangle
const addIfMissing = <T,>(item: T) => (items: T[]) => {
  if (items.includes(item)) return items
  return [...items, item]
}
