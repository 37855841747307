import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals'
import { CommonUserInfoViewModel, InitialUserInfoModalTransition, LoadingUserInfoModalTransition, PartialErrorUserInfoModalTransition, ProgramExceptionUserInfoModalTransition } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoModal.viewModel'

const transitionToInitial = (
  props: Omit<InitialUserInfoModalTransition & CommonUserInfoViewModel, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'USER_INFO_MODAL',
      props: {
        ...props,
        onClose: goBackModal,
        status: 'initial',
      },
    },
  })

const transitionToLoading = (
  props: Omit<LoadingUserInfoModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'USER_INFO_MODAL',
      props: {
        ...props,
        status: 'loading',
      },
    },
  })

const transitionToPartialError = (
  props: Omit<PartialErrorUserInfoModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'USER_INFO_MODAL',
      props: {
        ...props,
        status: 'partial-error',
      },
    },
  })

const transitionToProgramException = (
  props: Omit<ProgramExceptionUserInfoModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'USER_INFO_MODAL',
      props: {
        ...props,
        status: 'program-error',
      },
    },
  })

const transitionToClosed = (): void => goBackModal()

export const UserInfoModalAPI = {
  transitionToInitial,
  transitionToLoading,
  transitionToPartialError,
  transitionToProgramException,
  transitionToClosed,
}
