import { useSelector } from '@xstate/react'
import { Option } from 'effect'
import NearbyBuyerListEdgeMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerListEdgeMachine'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import SaveNearbyBuyersToListMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/SaveNearbyBuyersToListMachine'
import { useCallback } from 'react'

namespace SaveNearbyBuyersToListHooks {
  export const useActorRef = () => {
    const snapshot = NearbyBuyersHooks.useSnapshot()
    const actorRef = snapshot.children.saveToList
    return actorRef
  }

  export const useFromNearbyBuyersMachineSelector = <T>(
    selector: (snapshot: Option.Option<SaveNearbyBuyersToListMachine.Snapshot>) => T,
  ) => {
    const actorRef = useActorRef()
    return useSelector(actorRef, snap => selector(Option.fromNullable(snap)))
  }

  export const useListActorsRefs = () => useFromNearbyBuyersMachineSelector(snap => snap.pipe(
    Option.flatMap(s => s.context.listsResult),
    Option.map(r => r.edgeActorRefs),
  ))

  export const useList = (ref: NearbyBuyerListEdgeMachine.ActorRef) =>
    useSelector(ref, snap => snap.context.edge.node)
  export const useListIsChecked = (ref: NearbyBuyerListEdgeMachine.ActorRef) =>
    useSelector(ref, snap => snap.context.isChecked)
  export const useToggleList = (ref: NearbyBuyerListEdgeMachine.ActorRef) =>
    useCallback(() => ref.send({ type: 'toggle' }), [ref])

  export const useSave = () => {
    const actorRef = useActorRef()
    return useCallback(() => {
      actorRef?.send({ type: 'save-to-list' })
    }, [actorRef])
  }

  export const useIsSaving = () => useFromNearbyBuyersMachineSelector(snap => snap.pipe(
    Option.map(s => s.matches({
      running: {
        SaveToList: 'SaveToListPending',
      },
    }),
    )))
}

export default SaveNearbyBuyersToListHooks
