import { createIcon } from '@chakra-ui/react'

export const SkiptraceIcon = createIcon({
  displayName: 'SkiptraceIcon',
  viewBox: '0 0 48 49',
  path: (
    <g fill='none'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17.0949 3.54679C17.0949 3.54679 17.6983 2.11007 18.2346 1.19625C18.7709 0.282426 20.6681 0.342459 21.6536 1.19625L24.2681 2.69798C25.236 3.16154 25.7142 3.19323 26.4134 2.69798C28.5113 1.31453 29.2403 1.86404 30.3016 3.54679L31.7095 7.33377H35.1285C38.3674 7.83612 38.7354 8.77018 37.6089 11.2513C36.138 13.1558 35.1606 14.0509 33.0502 15.2342C33.3396 17.7578 33.0561 18.5284 31.7095 18.6947C31.7308 21.1655 31.3707 22.0212 30.3016 22.9387L33.0502 26.2687C34.2933 27.47 34.1489 27.7953 33.0502 28.0316L32.1117 28.358C32.0534 28.3988 32.0001 28.4323 31.953 28.4619C31.6605 28.6458 31.6094 28.6779 32.1117 29.3374L35.7988 32.6021L28.8268 48.3376H18.5698L11.5307 32.6021L14.9497 29.3374C15.6283 28.7185 15.536 28.5369 14.9497 28.358L14.0111 28.0316C12.961 27.7417 13.004 27.343 14.0111 26.2687L17.0949 22.9387C15.8202 22.0506 15.7185 20.9678 15.553 19.0212C14.0131 18.6569 13.9935 17.4496 14.0111 15.2342C11.5436 14.0214 10.7215 13.1002 9.72063 11.2513C8.60663 9.21853 8.92696 8.42513 11.1955 7.72553C13.0565 7.49338 14.0654 7.41181 15.553 7.72553L17.0949 3.54679ZM11.1955 9.61717H15.2178C19.9775 15.9506 31.2401 12.7526 32.1116 9.48803L36.201 9.48808C34.0557 13.4067 28.8491 15.9486 23.3295 15.9506C17.81 15.9525 12.8897 13.2527 11.1955 9.61717ZM29.0274 24.1135C25.0438 27.6319 22.689 27.9322 18.1671 24.1135L22.1224 40.5019L22.1429 40.3961C22.5303 38.394 22.913 36.4165 22.1224 34.2991C21.2509 31.4915 21.7202 30.8386 22.9269 29.2716H24.3347C25.4617 30.5089 25.8766 31.4263 25.2733 34.1685C24.5358 36.2579 24.5358 37.8902 25.2733 40.5019L29.0274 24.1135ZM4.15642 37.6293L9.92179 34.43L16.2235 48.4679H0L2.21229 40.6328C2.56057 38.9077 3.05411 38.3414 4.15642 37.6293ZM37.5419 34.0382L30.9721 48.5332H48L45.6536 40.0451C45.3981 38.9061 44.9103 38.3858 43.7095 37.564L37.5419 34.0382Z' fill='currentColor' />
    </g>
  ),
})
