import { Brand, String } from 'effect'
import { nanoid } from 'nanoid'

type CursorId = string & Brand.Brand<'CursorId'>

const fromString = Brand.refined<CursorId>(
  n => !String.isEmpty(n),
  () => Brand.error(`Expected CursorId to be a non empty string`),
)

const CursorId = {
  fromString,
  make: () => fromString(nanoid()),
}

export default CursorId
