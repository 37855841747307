import { Box, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { Miles } from 'presentation/screens/CompsScreen/components/common/Miles'
import { mbp } from 'presentation/utils/mapBreakpoint'

export type DistanceFilterProps = {
  value: number
  onChange: (value: number) => void
}

export const DistanceFilter = (props: DistanceFilterProps) => {
  const selectedLabel = Miles.toLabel(props.value)
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <FilterPopover
      isOpen={isOpen}
      onOpen={onToggle}
      onClose={onClose}
    >
      <FilterPopoverButton
        label='Distance'
        minW={mbp({ mobSm: '120px', dtLg: '140px' })}
        onClick={onToggle}
        title={selectedLabel}
        size={mbp({ mobSm: 'md', dtSm: 'lg' })}
      >
        {selectedLabel}
      </FilterPopoverButton>
      <FilterPopoverContent
        popoverBodyProps={{
          py: 2,
        }}
      >
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
          h='5'
          lineHeight='5'
        >
          Distance
        </Text>
        <VStack
          mt={1}
          spacing={0.5}
          align='stretch'
        >
          {Miles
            .getOptions()
            .map(preset => (
              <Box
                key={preset}
                as='button'
                ml={-2}
                pl={2}
                textStyle='bodyMFat'
                borderRightRadius='full'
                color='graystrong.400'
                height='40px'
                textAlign='left'
                {...(props.value === preset && {
                  bg: 'neutral.500',
                  color: 'ondark.1',
                })}
                onClick={() => {
                  props.onChange(preset)
                }}
              >
                {Miles.roundOffThenToLabel(preset)}
              </Box>
            ))}
        </VStack>

      </FilterPopoverContent>
    </FilterPopover>
  )
}
