import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_LOTS_LAND_ICON_W = 32

export const PropTypeLotsLandIcon = createIcon({
  displayName: 'PropTypeLotsLandIcon',
  viewBox: `0 0 ${PROP_TYPE_LOTS_LAND_ICON_W} 28`,
  path: (
    <>
      <path d='M1 29V26H33V29H1Z' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M25 12V14.5M25 26V21.5M25 21.5L21 18M25 21.5L29 18M25 21.5V18M25 18L22.5 16M25 18L27.5 16M25 18V14.5M25 14.5L24 13.5M25 14.5L26 13.5' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 26V12' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.1111 9.35758C14.0321 9.35758 15.5 10.7525 15.5 12.3684H16.5C16.5 10.1064 14.4857 8.35758 12.1111 8.35758V9.35758ZM15.5 12.3684C15.5 13.9844 14.0321 15.3793 12.1111 15.3793V16.3793C14.4857 16.3793 16.5 14.6304 16.5 12.3684H15.5ZM12.0068 15.7973C11.8233 16.9016 11.4678 17.5506 11.0088 17.9333C10.5493 18.3165 9.90572 18.5 9 18.5V19.5C10.0273 19.5 10.9393 19.2934 11.6493 18.7013C12.3598 18.1089 12.7878 17.1975 12.9932 15.9612L12.0068 15.7973ZM9 18.5C8.29285 18.5 7.55011 17.9626 6.9524 16.9792C6.36671 16.0154 6 14.74 6 13.5387H5C5 14.9227 5.4168 16.3779 6.09785 17.4985C6.76689 18.5994 7.77415 19.5 9 19.5V18.5ZM2.5 9.63776C2.5 7.87141 3.84156 6.42098 5.55493 6.2531L5.45742 5.25787C3.23451 5.47567 1.5 7.35435 1.5 9.63776H2.5ZM5.55493 6.2531C5.66466 6.24235 5.77606 6.23683 5.88889 6.23683V5.23683C5.74344 5.23683 5.5995 5.24394 5.45742 5.25787L5.55493 6.2531ZM5.88889 13.0387C4.01871 13.0387 2.5 11.5175 2.5 9.63776H1.5C1.5 12.0669 3.46352 14.0387 5.88889 14.0387V13.0387ZM6 6.12693C6 6.00621 6.00198 5.88803 6.00589 5.77238L5.00646 5.73859C5.00216 5.86583 5 5.99528 5 6.12693H6ZM6.00589 5.77238C6.05758 4.24336 6.44458 3.17924 7.02345 2.50473C7.59159 1.84273 8.39225 1.5 9.40788 1.5V0.5C8.14744 0.5 7.05127 0.936831 6.26459 1.85347C5.48864 2.75762 5.06283 4.07124 5.00646 5.73859L6.00589 5.77238ZM9.40788 1.5C9.82928 1.5 10.3063 1.71366 10.7889 2.15707C11.2673 2.59667 11.7122 3.22862 12.0576 3.96974C12.7552 5.46632 12.9915 7.27975 12.4258 8.66903L13.352 9.04613C14.057 7.31466 13.734 5.1993 12.964 3.54728C12.5757 2.71409 12.0598 1.9668 11.4655 1.42072C10.8753 0.878443 10.1678 0.5 9.40788 0.5V1.5Z' fill='currentColor' />
    </>
  ),
})
