import { Box, Image, Text, VStack } from '@chakra-ui/react'
import noDataImg from 'presentation/assets/img/3d/hurt-dog.png'
import { Card } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const CompsError = () => (
  <VStack flex='1' minH='full' align='stretch' spacing={0}>

    <Box
      flex={1}
      flexGrow={1}
      flexShrink={0}

      display='flex'
      flexDirection='column'

      minH='0'
      p={mbp({
        dtSm: 4,
        mob: 3,
        mobSm: 1,
      })}
      flexBasis={mbp({
        dtLg: '764px',
        dtSm: '496px',
        tabSm: '688px',
        mob: '533px',
        mobSm: '351px',
      })}
    >

      <Card
        flex='1'

        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'

        borderRadius={3}
        p={0}

        overflow='hidden'
        pos='relative'
      >
        <VStack
          spacing={mbp({
            tabSm: 2,
            mob: 1,
          })}
        >
          <Image
            src={noDataImg}
            alt='no-data'
            boxSize={mbp({
              tabSm: '180px',
              mob: '128px',
              mobSm: '120px',
            })}
          />
          <Text
            mt={-2}
            textStyle={mbp({
              tabSm: 'h1',
              mob: 'h4',
              mobSm: 'bodyXLFat',
            })}
            color='graystrong.500'
            textAlign='center'
          >
            Something went wrong with Comps
          </Text>
          <Text
            textStyle={mbp({
              tabSm: 'bodyXLFat',
              mobSm: 'bodyMFat',
            })}
            color='graystrong.200'
            textAlign='center'
          >
            Please refresh or contact support.
          </Text>
        </VStack>
      </Card>
    </Box>
  </VStack>
)
