import { split } from '@chakra-ui/object-utils'
import { UseRadioProps, useRadio, useRadioGroupContext } from '@chakra-ui/react'
import { callAll } from '@chakra-ui/shared-utils'
import {
  HTMLChakraProps,
  SystemProps,
  SystemStyleObject,
  ThemingProps,
  chakra,
  forwardRef,
  layoutPropNames,
  omitThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system'

type Omitted = 'onChange' | 'defaultChecked' | 'checked'
type BaseControlProps = {} & Omit<HTMLChakraProps<'div'>, Omitted>

export type RadioProps = {
  spacing?: SystemProps['marginLeft']
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
} & UseRadioProps & ThemingProps<'Radio'> & BaseControlProps

/**
 * Copied from
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/radio/src/radio.tsx
 */
export const Radio = forwardRef<RadioProps, 'input'>((props, ref) => {
  const group = useRadioGroupContext()
  const { onChange: onChangeProp, value: valueProp } = props

  const styles = useMultiStyleConfig('Radio', { ...group, ...props })

  const ownProps = omitThemingProps(props)

  const {
    spacing = '0.5rem',
    children,
    isDisabled = group?.isDisabled,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
    ...rest
  } = ownProps

  let isChecked = props.isChecked
  if (group?.value != null && valueProp != null)
    isChecked = group.value === valueProp

  let onChange = onChangeProp
  if (group?.onChange && valueProp != null)
    onChange = callAll(group.onChange, onChangeProp)

  const name = props?.name ?? group?.name

  const {
    state,
    getInputProps,
    getRadioProps,
    getLabelProps,
    getRootProps,
    htmlProps,
  } = useRadio({
    ...rest,
    isChecked,
    isFocusable,
    isDisabled,
    onChange,
    name,
  })

  const [layoutProps, otherProps] = split(htmlProps, layoutPropNames as any)

  const checkboxProps = getRadioProps(otherProps)
  const inputProps = getInputProps(htmlInputProps, ref)
  const labelProps = getLabelProps()
  const rootProps = Object.assign({}, layoutProps, getRootProps())

  const rootStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
    ...styles.container,
  }

  const checkboxStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    ...styles.control,
  }

  const labelStyles: SystemStyleObject = {
    userSelect: 'none',
    marginStart: spacing,
    ...styles.label,
  }

  return (
    <chakra.label className='chakra-radio' {...rootProps} __css={rootStyles}>
      <input className='chakra-radio__input' {...inputProps} />
      <chakra.span
        className='chakra-radio__control'
        {...checkboxProps}
        __css={checkboxStyles}
      >
        {state.isChecked && <Checked />}
      </chakra.span>
      {children && (
        <chakra.span
          className='chakra-radio__label'
          {...labelProps}
          __css={labelStyles}
        >
          {children}
        </chakra.span>
      )}
    </chakra.label>
  )
})

Radio.displayName = 'Radio'

const Checked = () => (
  <svg viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M19.391 0.647448C20.203 1.51071 20.203 2.91034 19.391 3.7736L8.49992 15.3526C8.11 15.7671 7.58114 16 7.0297 16C6.47826 16 5.94941 15.7671 5.55948 15.3526L0.608986 10.0894C-0.202995 9.22613 -0.202995 7.8265 0.608986 6.96324C1.42097 6.09997 2.73745 6.09997 3.54943 6.96324L7.0297 10.6633L16.4506 0.647448C17.2626 -0.215816 18.579 -0.215816 19.391 0.647448Z' fill='currentColor' />
  </svg>
)
