import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

const baseStyle = defineStyle({
  bg: 'accent.hover',
  borderColor: 'grayweak.500',
  borderWidth: 0.125,
  px: 2,
  py: 1,
  ...textStyles.tagL,
  color: 'graystrong.400',
  maxW: '160px',
  textAlign: 'center',
})

export const tooltipTheme = defineStyleConfig({
  baseStyle,
})
