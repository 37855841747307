import { Box } from '@chakra-ui/react'

export const HasFilterIndicator = () => (
  <Box
    bgColor='negative.500'
    w={1.5}
    h={1.5}
    borderRadius='full'
    position='absolute'
    right='0'
    top='0'
  />
)
