import { Array, Data, Match, Option, pipe } from 'effect'
import { isNotNullable } from 'effect/Predicate'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { HighEquityValue, LowEquityValue, PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import HomeTypeCode from 'features/ListBuilder/infra/HomeTypeCode'
import { CriteriaSchema } from 'features/ListBuilder/infra/repo/schema/CriteriaSchema/CriteriaSchema'
import { PartialRange } from 'features/valueObjects/Range'

// #region toDomain
export const toDomain = (
  listType: ListType,
  criteriaSchema: CriteriaSchema | null,
): ListCriteria | null =>
  pipe(
    Match.value(listType),
    Match.when('property-lists', () => mapPropertyCriteriaToSchema(criteriaSchema)),
    Match.when('private-lenders', () => null),
    Match.when('cash-buyers', () => null),
    Match.when('drive', () => null),
    Match.exhaustive,
  )

// #region PropertyListCriteria
const mapPropertyCriteriaToSchema = (
  criteriaSchema: CriteriaSchema | null,
): PropertyListCriteria =>
  PropertyListCriteria.of({
    excludePropertiesFromOtherList: Option.getOrElse(
      criteriaSchema?.newOnly ?? Option.none(),
      () => false,
    ),

    // #region boundary
    boundary: { lines: [] },

    location: pipe(
      Array.flatten([
        pipe(
          Option.fromNullable(criteriaSchema),
          Option.flatMap(schema => schema.location.city),
          Option.map(Array.map(LocationCriteria.City)),
          Option.getOrElse(() => []),
        ),
        pipe(
          Option.fromNullable(criteriaSchema),
          Option.flatMap(schema => Option.zipWith(
            schema.location.county,
            schema.location.countyDesc,
            (county, countyDesc) => Data.tuple(county, countyDesc),
          )),
          Option.map(([county, countyDesc]) =>
            pipe(
              county,
              Array.map((fips, i) =>
                /**
                 *  @NOTE: The countyDesc becomes undefined because of a bug where the data being saved for county
                 *  is the description (Dallas, TX) instead of the fips number. This crashes list screen because
                 *  countyDesc is undefined. This temporarily allows empty string until the issue is fixed.
                 *  @TODO: provide a better prevention of this kind of issue.
                 * */
                countyDesc[i]
                  ? LocationCriteria.County(
                    countyDesc[i],
                    fips,
                  )
                  : null,
              ),
              Array.filter(isNotNullable),
            ),
          ),
          Option.getOrElse(() => []),
        ),
        pipe(
          Option.fromNullable(criteriaSchema),
          Option.flatMap(schema => schema.location.zip),
          Option.map(Array.map(LocationCriteria.Zip)),
          Option.getOrElse(() => []),
        ),
      ]),
    ),
    // #endregion GeographicCriteria

    // #region propertyDetails
    propertyDetails: {
      homeType: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(schema => schema.filters.propertyTypes),
        Option.map(codes => HomeTypeCode.fromIterable(codes)),
        Option.match({
          onNone: () => Array.empty<HomeType>(),
          onSome: codes => HomeTypeCode.getTypes(codes),
        }),
      ),
      bathroomsCount: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.propertyDetails),
        Option.flatMap(p => p.baths),
        Option.flatMap(baths =>
          Option.some(
            PartialRange.make(
              Option.getOrNull(baths.min),
              Option.getOrNull(baths.max),
            ),
          ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),

      bedroomsCount: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.propertyDetails),
        Option.flatMap(p => p.beds),
        Option.flatMap(baths =>
          Option.some(
            PartialRange.make(
              Option.getOrNull(baths.min),
              Option.getOrNull(baths.max),
            ),
          ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),

      livingAreaSqft: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.propertyDetails),
        Option.flatMap(p => p.buildingSqft),
        Option.flatMap(baths =>
          Option.some(
            PartialRange.make(
              Option.getOrNull(baths.min),
              Option.getOrNull(baths.max),
            ),
          ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),

      lotAreaSize: {
        range: pipe(
          Option.fromNullable(criteriaSchema),
          Option.flatMap(criteria => criteria.filters.propertyDetails),
          Option.flatMap(p => p.lotSqft),
          Option.flatMap(baths =>
            Option.some(
              PartialRange.make(
                Option.getOrNull(baths.min),
                Option.getOrNull(baths.max),
              ),
            ),
          ),
          Option.getOrElse(() => PartialRange.EMPTY),
        ),
        unit: 'sqft',
      },

      yearBuilt: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.propertyDetails),
        Option.flatMap(p => p.yearBuilt),
        Option.flatMap(baths =>
          Option.some(
            PartialRange.make(
              Option.getOrNull(baths.min),
              Option.getOrNull(baths.max),
            ),
          ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),

      garageSpacesCount: PartialRange.EMPTY, // @TODO:
    },
    // #endregion propertyDetails
    yearsOfOwnership: pipe(
      Option.fromNullable(criteriaSchema),
      Option.flatMap(criteria => criteria.filters.equityInfo),
      Option.flatMap(equityInfo => equityInfo.ownershipLength),
      Option.flatMap(ownershipLength =>
        Option.some(
          PartialRange.make(
            Option.getOrNull(ownershipLength.min),
            Option.getOrNull(ownershipLength.max),
          ),
        ),
      ),
      Option.getOrElse(() => PartialRange.EMPTY),
    ),
    ownershipFlags: {
      isPersonalResidence: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.individual),
        Option.flatMap(individual => individual.ownerOccupied),
        Option.getOrNull,
      ),
      isInState: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.individual),
        Option.flatMap(individual => individual.absentee),
        Option.flatMap(absentee => absentee.inState),
        Option.getOrNull,
      ),
      isOutOfState: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.individual),
        Option.flatMap(individual => individual.absentee),
        Option.flatMap(absentee => absentee.outOfState),
        Option.getOrNull,
      ),
      isOutOfCountry: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.individual),
        Option.flatMap(individual => individual.absentee),
        Option.flatMap(absentee => absentee.outOfCountry),
        Option.getOrNull,
      ),
      isMilitary: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.individual),
        Option.flatMap(individual => individual.military),
        Option.getOrNull,
      ),
      isSenior: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.taxExemptions),
        Option.flatMap(taxExemptions => taxExemptions.isSenior),
        Option.getOrNull,
      ),
      isCorporateOwned: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.isCompany),
        Option.getOrNull,
      ),
      isTrust: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.isTrust),
        Option.getOrNull,
      ),
      isBank: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.isBank),
        Option.getOrNull,
      ),
      // @TODO: Where does this map? There is also Unknown in Equity
      isUnknown: null,
    },
    stressIndicatorFlags: {
      // @NOTE: Not part of TNT Release
      isPreforeclosure: null,
      // @NOTE: Not part of TNT Release
      isSubstituteTrustees: null,
      // @NOTE: Not part of TNT Release
      isProbates: null,
      // @NOTE: Not part of TNT Release
      isAffidavitsOfHeirships: null,
      // @NOTE: Not part of TNT Release
      isHOALiens: null,
      // @NOTE: Not part of TNT Release
      isTaxDelinquent: null,
      isVacant: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.ownerType),
        Option.flatMap(ownerType => ownerType.vacancy),
        Option.flatMap(vacancy => vacancy.isVacant),
        Option.getOrNull,
      ),
      isFailedListing: null,
    },
    equityValue: {
      propertyValue: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.equityInfo),
        Option.flatMap(equityInfo => equityInfo.estimatedValue),
        Option.flatMap(estimatedValue => Option.some(
          PartialRange.make(
            Option.getOrNull(estimatedValue.min),
            Option.getOrNull(estimatedValue.max),
          ),
        ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),
      highEquityValue: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.equityType),
        Option.flatMap(equityType => equityType.highEquity),
        Option.flatMap(highEquity => Option.some<HighEquityValue>({
          condition: highEquity.conditionType.pipe(
            Option.map(conditionType => pipe(
              Match.value(conditionType),
              Match.when('AND', () => 'and' as const),
              Match.when('OR', () => 'or' as const),
              Match.exhaustive,
            )),
            Option.getOrThrow,
          ),
          minAmount: highEquity.dollarsAtLeast.pipe(Option.getOrNull),
          minPercentage: highEquity.percentAtLeast.pipe(Option.getOrNull),
        })),
        Option.getOrElse((): HighEquityValue => ({
          condition: 'and',
          minAmount: null,
          minPercentage: null,
        })),
      ),
      lowEquityValue: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.equityType),
        Option.flatMap(equityType => equityType.lowEquity),
        Option.flatMap(lowEquity => Option.some({
          condition: lowEquity.conditionType.pipe(
            Option.map(conditionType => pipe(
              Match.value(conditionType),
              Match.when('AND', () => 'and' as const),
              Match.when('OR', () => 'or' as const),
              Match.exhaustive,
            )),
            Option.getOrThrow,
          ),
          maxAmount: lowEquity.dollarsAtMost.pipe(Option.getOrNull),
          maxPercentage: lowEquity.percentAtMost.pipe(Option.getOrNull),
        })),
        Option.getOrElse((): LowEquityValue => ({
          condition: 'and',
          maxAmount: null,
          maxPercentage: null,
        })),
      ),
      equityValueFlags: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.equityType),
        Option.flatMap(equityType => Option.some({
          isFreeAndClear: equityType.freeAndClear.pipe(Option.getOrNull),
          isUnknown: equityType.unknown.pipe(Option.getOrNull),
        })),
        Option.getOrElse(() => ({
          isFreeAndClear: null,
          isUnknown: null,
        })),
      ),
      taxValue: pipe(
        Option.fromNullable(criteriaSchema),
        Option.flatMap(criteria => criteria.filters.equityInfo),
        Option.flatMap(p => p.taxValue),
        Option.flatMap(taxValue =>
          Option.some(
            PartialRange.make(
              Option.getOrNull(taxValue.min),
              Option.getOrNull(taxValue.max),
            ),
          ),
        ),
        Option.getOrElse(() => PartialRange.EMPTY),
      ),
      upsideDownValue: {
        minAmount: pipe(
          Option.fromNullable(criteriaSchema),
          Option.flatMap(criteria => criteria.filters.equityType),
          Option.flatMap(equityType => equityType.upsideDown),
          Option.flatMap(upsideDown => upsideDown.upsideDownAtLeast),
          Option.getOrNull,
        ),
      },
    },
    mlsFlags: { // @NOTE: Not part of TNT release
      isExcludeActiveListings: null,
      isDiscountedListings: null,
      isHandymanSpecials: null,
    },
  })

// #endregion decode
