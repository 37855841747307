import { Avatar, AvatarProps, Box, forwardRef, IconButton, Menu, MenuButton, MenuItem, Text } from '@chakra-ui/react'
import { useThemeSettings } from 'features/Settings/infra/react/Settings.state.reactUtils'
import { DarkModeIcon, LightModeIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { FC, ForwardedRef } from 'react'
import { NavLink } from 'react-router-dom'

type UserMenuProps = AvatarProps & {
  /** Require size */
  size: AvatarProps['size']
}

export const UserMenu: FC<UserMenuProps> = props => {
  const viewModel = LayoutViewModel.useReadySelector(state => ({
    isSubscribed: !!state.subscription,
    logout: state.accessor.logout,
  }))

  const themeApi = useThemeSettings()

  /** @TODO Report illegal state */
  if (!viewModel) return null

  return (
    <Menu>
      <MenuButton
        as={AvatarButton}
        aria-label='contacts'
        {...props}
      />
      <ResponsiveMenuList
        /**
         * Menu button is small that it messes up arrow alignment.
         * This is work around for that
         */
        menuListProps={{ mr: -0.5 }}
      >
        {viewModel.isSubscribed && (
          <>
            <NavLink to='/user/team'>
              {({ isActive }) => (
                <MenuItem {...isActive && { 'data-checked': true }}>
                  Team Members
                </MenuItem>
              )}
            </NavLink>
            <NavLink to='/user/billing'>
              {({ isActive }) => (
                <MenuItem {...isActive && { 'data-checked': true }}>
                  Plans & Billing
                </MenuItem>
              )}
            </NavLink>
          </>
        )}
        <MenuItem onClick={themeApi.toggleTheme}>
          <Box as='span' flex='1'>
            Switch to
            {themeApi.theme === 'light' ? 'dark' : 'light'}
            {' '}
            theme
          </Box>

          <Box mr='1'>
            {themeApi.theme === 'light' ? <LightModeIcon w='6' h='3' /> : <DarkModeIcon w='6' h='3' />}
          </Box>
        </MenuItem>
        <MenuItem onClick={viewModel.logout}>
          <Text as='span' color='negative.500'>Log Out</Text>
        </MenuItem>
      </ResponsiveMenuList>
    </Menu>
  )
}

const AvatarButton = forwardRef<UserMenuProps, 'button'>((
  { size, ...props },
  ref: ForwardedRef<HTMLButtonElement>,
): ReturnType<FC> => {
  const viewModel = LayoutViewModel.useReadySelector(state => ({
    name: state.accessor.name,
    avatarUrl: state.accessor.avatarUrl,
  }))

  if (!viewModel) return null

  return (
    <IconButton ref={ref} {...props} aria-label='User Menu'>
      <Avatar
        name={viewModel.name}
        src={viewModel.avatarUrl}
        size={size}
      />
    </IconButton>
  )
})

AvatarButton.displayName = 'AvatarButton'
