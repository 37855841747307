import { Box, Flex, IconButton, Image, Text } from '@chakra-ui/react'
import logoForLight from 'presentation/assets/img/red-logo-black-text.svg'
import logoForDark from 'presentation/assets/img/red-logo-white-text.svg'
import { HelpCircleIcon } from 'presentation/components/Icons'
import { HEADER_H } from 'presentation/const/layout.const'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { UserMenu } from 'presentation/layouts/Layout/components/UserMenu'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { useThemeSpecificValue } from 'presentation/main/themes/hooks/useThemeSpecificValue'
import { px } from 'presentation/utils/px'
import { FC } from 'react'
import { SearchPropertyInput } from './SearchPropertyInput'

type Props = {
  pageTitle: string | JSX.Element
}

export const HeaderDesktop: FC<Props> = ({
  pageTitle,
}) => {
  const isSubscribed = LayoutViewModel.useReadySelector(state =>
    !!state.subscription)
  const hasShadow = useLayoutStore(store => store.headerShadow.value)

  const logo = useThemeSpecificValue({
    light: logoForLight,
    dark: logoForDark,
  })

  return (
    <Flex
      as='header'
      direction='row'
      align='center'
      h={px(HEADER_H)}
      w='full'
      px={4}
      bg='graycool.100'
      gap={3}
      boxShadow={hasShadow ? 'bottom-shadow' : 'none'}
    >

      {isSubscribed
        ? (
          <>
            <Box>
              {pageTitle && typeof pageTitle !== 'string'
                ? pageTitle
                : (<Text textStyle='h3' color='graystrong.500'>{pageTitle || 'Propelio'}</Text>)}
            </Box>

            <Flex flex={1} justify='center'>
              <Box w='full' maxW='592px'>
                <SearchPropertyInput />
              </Box>
            </Flex>

            <Flex gap='0.5'>
              <IconButton
                aria-label='help'
                variant='icon-ghost'
                colorScheme='graystrong'
                size='md'
                icon={<HelpCircleIcon />}
                onClick={() => {
                  void SupportWidget.openWidget({ topic: 'pricing' })
                }}
              />
            </Flex>
          </>
        )
        : (
          <Box flex='1'>
            <Image
              src={logo}
              alt='Propelio'
              w='auto'
              h='4'
            />
          </Box>
        )}

      <UserMenu size='md' />
    </Flex>
  )
}
