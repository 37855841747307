import { gql } from '__generated__'

export const GET_SEEKERS_QUERY = gql(/* Graphql */`
  query Seekers {
    myEnterprise {
      users {
        edges {
          node {
            avatar {
              avatarUrl
            }
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`)
