import { BoxProps, ButtonProps, Center, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { CloseIcon } from 'presentation/components/Icons'
import { shallow } from 'zustand/shallow'

export const SearchHistoryInput = (props: BoxProps & {
  onClose: () => void
}) => {
  const { onClose, ...componentProps } = props
  const { textFilter, filterByText } = useSearchHistoryStore(api => ({
    textFilter: api.local.textFilter,
    filterByText: api.actions.filterByText,
    isUpdating: api.remote.history.status === 'updating',
  }), shallow)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void filterByText(e.target.value)
  }

  const handleOnClear = () => {
    void filterByText('')
    onClose()
  }

  return (
    <InputGroup
      {...componentProps}
    >
      <Input
        size='md'
        variant='rounded'
        placeholder='Search History'
        value={textFilter}
        onChange={handleOnChange}
      />

      <InputRightElement>
        <ClearButton onClick={handleOnClear} />
      </InputRightElement>
    </InputGroup>
  )
}

export const ClearButton = (props: ButtonProps) => (
  <Center as='button' {...props} p={0}>
    <CloseIcon boxSize={3} color='graystrong.200' />
  </Center>
)
