import { Text } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Card, CardBody, CardHeader } from 'presentation/components/molecules/Card'
import { MLSSubSection } from 'presentation/screens/PropertyDetailsScreen/components/ListingSection/MLSSubSection/MLSSubSection'
import { ValuationSectionProps } from 'presentation/screens/PropertyDetailsScreen/components/ValuationSection/ValuationSection.props'
import { shallow } from 'zustand/shallow'

export const ListingSection = (props: ValuationSectionProps) => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const hasData = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    return !!property
  }, shallow)

  if (!hasData) return null

  return (
    <Card size='xs-locked' colorScheme='card.bg.1' {...props}>
      <CardHeader>
        <Text textStyle='bodyXLFat' color='accent.blue-text'>
          Most Recent MLS Listing
        </Text>
      </CardHeader>
      <CardBody mt='3' shouldResetSpacing flex='1'>
        <MLSSubSection />
      </CardBody>
    </Card>
  )
}
