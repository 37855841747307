import { createIcon } from '@chakra-ui/react'

const RegularCircleExclamationIcon = createIcon({
  displayName: 'RegularCircleExclamationIcon ',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' fill='none' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 8V12' fill='none' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 16H12.01' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})

export default RegularCircleExclamationIcon
