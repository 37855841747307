import { Box, Center, Spinner } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MARKETING_PAGE_PRICING_URL } from 'presentation/const/const'
import { EverythingYouNeedGrid } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid'
import { SignUpCard } from 'presentation/screens/AuthScreens/components/SignUpCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const SignUpScreen = () => {
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('planId')

  useEffect(() => {
    if (!planId)
      window.location.href = MARKETING_PAGE_PRICING_URL
  }, [planId])

  if (!planId) {
    return (
      <Center h='var(--app-height)'>
        <Spinner />
      </Center>
    )
  }

  return (
    <Box h='var(--app-height)'>
      <Breakpoint
        mobSm={<SignUpCard overflow='auto' h='100%' />}
        mob={(
          <EverythingYouNeedGrid>
            <SignUpCard
              mt={mbp({
                mobSm: '0',
                // @HACK add more spacing on the top to not cut the rocketman image in the form
                dtSm: '30px',
                dtLg: '0',
              })}
            />
          </EverythingYouNeedGrid>
        )}
      />
    </Box>
  )
}
