import {
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/styled-system'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

const baseStyle = defineStyle(({ colorScheme }) => ({
  bg: getRecKey(colorScheme)({
    positivesat: 'positivesat.500',
    negative: 'negative.500',
  }),
  color: 'ondark.1',
  boxSize: '3',
  borderRadius: 'full',
  p: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...textStyles.tagM,
}))

const variants = {
  round: {},
}

export const badgeTheme = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'round',
    colorScheme: 'positivesat',
  },
})
