import { Tag } from '@chakra-ui/react'

/**
  Pills
  @TODO: standardize across screens including cash buyers
*/
export const FlippedPill = () => (
  <Tag
    colorScheme='warm'
    variant='bordered'
  >
    Flipped
  </Tag>
)

export const FlipperPill = () => (
  <Tag
    colorScheme='warm'
    variant='bordered'
  >
    Flipper
  </Tag>
)

export const RentalPill = () => (
  <Tag
    colorScheme='neutral'
    variant='bordered'
  >
    Rental
  </Tag>
)

export const LandlordPill = () => (
  <Tag
    colorScheme='neutral'
    variant='bordered'
  >
    Landlord
  </Tag>
)
