import { Center, Checkbox, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match, Option } from 'effect'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import pluralize from 'pluralize'
import OwnedPropertiesMap from './OwnedPropertiesMap'
import OwnedPropertiesTable from './OwnedPropertiesTable'

const OwnedPropertiesColumned = ({ ownerId }: { ownerId: string }) => {
  const viewModel = OwnersPropertiesHooks.useOwnerDeals(ownerId)
  const toggleViewModel = OwnersPropertiesHooks.useToggleLimitToCurrentlyOwned(ownerId)

  if (Option.isNone(viewModel) || Option.isNone(toggleViewModel)) return null

  return Match.value(viewModel.value).pipe(
    Match.tag('Success', viewModel => (
      <HStack
        alignItems='flex-start'
        spacing='2'
        h='560px'
      >
        <VStack
          flex='1'
          h='full'
          w='full'
          alignItems='stretch'
          justifyContent='flex-start'
          spacing='2'
        >
          <HStack
            alignSelf='stretch'
            justifyContent='space-between'
            alignItems='center'
            h='5'
          >
            <Text
              textStyle='bodyXLFat'
              color='graystrong.500'
            >
              {pluralize('Property', viewModel.displayedPropertiesCount, false)}
              {' '}
              (
              {viewModel.displayedPropertiesCount}
              )
            </Text>
            <HStack>
              <Text
                textStyle='bodyXL'
                color='graystrong.400'
              >
                Show properties previously owned
              </Text>

              <Checkbox
                isChecked={toggleViewModel.value.shouldLimitToCurrentlyOwned}
                onChange={toggleViewModel.value.onToggle}
              />
            </HStack>
          </HStack>
          <VStack
            flex='1'
            align='stretch'
            justify='stretch'
          >
            <OwnedPropertiesTable ownerId={ownerId} />
          </VStack>
        </VStack>
        <OwnedPropertiesMap ownerId={ownerId} w='560px' />
      </HStack>
    )),
    Match.orElse(() => (
      <Center>
        <Spinner />
      </Center>
    )),
  )
}

export default OwnedPropertiesColumned
