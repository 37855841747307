import { createIcon } from '@chakra-ui/react'

export const TileIcon = createIcon({
  displayName: 'TileIcon',
  viewBox: '0 0 17 16',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M7.02983 2H2.36316V6.66667H7.02983V2Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.3632 2H9.69653V6.66667H14.3632V2Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.3632 9.33301H9.69653V13.9997H14.3632V9.33301Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.02983 9.33301H2.36316V13.9997H7.02983V9.33301Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
