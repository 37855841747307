import { createWidthSync } from 'presentation/libs/WidthSync/WidthSync'

export type CMAEntriesTableWidthKey = 'address'
  | 'prop-type'
  | 'prop-status'
  | 'beds-baths-garage'
  | 'year-built'
  | 'sqft'
  | 'acre'
  | 'list-price'
  | 'list-price-sqft'
  | 'sale-price'
  | 'sale-price-sqft'
  | 'distance'
  | 'dom'
  | 'rate'

export const CMAEntriesTableWidthSync = createWidthSync<CMAEntriesTableWidthKey>()
