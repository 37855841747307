import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const TrustIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M5.89501,3.45465 C6.4726,2.84735 7.26285,2.5 8.09375,2.5 L15.96873,2.5 C16.27263,2.5 16.56353,2.62565 16.77533,2.84836 L22.68163,9.05836 C22.88573,9.27302 23.00003,9.56061 23.00003,9.86 L23.00003,22.27997 C23.00003,23.12367 22.68153,23.93917 22.10503,24.54537 C21.52743,25.15267 20.73713,25.49997 19.90623,25.49997 L8.09375,25.49997 C7.26284,25.49997 6.4726,25.15267 5.89501,24.54537 C5.31847,23.93917 5,23.12367 5,22.27997 L5,5.72 C5,4.87629 5.31847,4.06084 5.89501,3.45465 Z M19.86843,9.4 L16.25003,5.59546 L16.25003,9.4 L19.86843,9.4 Z M14.00003,4.8 L14.00003,10.54997 C14.00003,11.18517 14.50363,11.69997 15.12503,11.69997 L20.75003,11.69997 L20.75003,22.27997 C20.75003,22.53427 20.65363,22.77187 20.49173,22.94207 C20.33093,23.11117 20.11963,23.19997 19.90623,23.19997 L8.09375,23.19997 C7.88036,23.19997 7.66909,23.11117 7.50825,22.94207 C7.34637,22.77187 7.25,22.53427 7.25,22.27997 L7.25,5.72 C7.25,5.46571 7.34637,5.22814 7.50825,5.05793 C7.66908,4.88883 7.88036,4.8 8.09375,4.8 L14.00003,4.8 Z M9.5,11.69997 C9.5,11.06487 10.00368,10.54997 10.62503,10.54997 L11.75003,10.54997 C12.37133,10.54997 12.87503,11.06487 12.87503,11.69997 C12.87503,12.33517 12.37133,12.84997 11.75003,12.84997 L10.62503,12.84997 C10.00368,12.84997 9.5,12.33517 9.5,11.69997 Z M9.5,15.14997 C9.5,14.51487 10.00368,13.99997 10.62503,13.99997 L17.37503,13.99997 C17.99633,13.99997 18.50003,14.51487 18.50003,15.14997 C18.50003,15.78517 17.99633,16.29997 17.37503,16.29997 L10.62503,16.29997 C10.00368,16.29997 9.5,15.78517 9.5,15.14997 Z M9.5,18.59997 C9.5,17.96487 10.00368,17.44997 10.62503,17.44997 L17.37503,17.44997 C17.99633,17.44997 18.50003,17.96487 18.50003,18.59997 C18.50003,19.23517 17.99633,19.74997 17.37503,19.74997 L10.62503,19.74997 C10.00368,19.74997 9.5,19.23517 9.5,18.59997 Z'
      fill='marker.carolinablue'
    />
  </MarkerIconContainer>
)
