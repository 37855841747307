import { Avatar, Box, Flex } from '@chakra-ui/react'
import classNames from 'classnames'
import { CardSecondaryText, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Tooltip } from 'presentation/components/Tooltip'
import { ActivateButton, Container, ContainerProps, DeactivateMemberOption, EditMemberInfoOption, OptionButton } from 'presentation/screens/MembersScreen/components/MemberCard/common'
import { memberStatusToAnimationClass } from 'presentation/screens/MembersScreen/components/MemberCard/MemberCard.utils'
import { MemberCardViewModel } from 'presentation/screens/MembersScreen/components/MemberCard/MemberCard.viewModel'
import { formatPhoneNumber } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoCard'
import { membersScreenUtils } from 'presentation/screens/MembersScreen/utils/MembersScreen.utils'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export type MemberCardProps = Omit<ContainerProps, 'me'> & MemberCardViewModel

export const MemberCard: FC<MemberCardProps> = props => {
  const { viewModel, rest } = separateViewModel(props)

  const memberStatus = viewModel.member.status
  const cardAnimation = memberStatusToAnimationClass(memberStatus)

  const name = [
    viewModel.member.firstName,
    viewModel.member.lastName,
  ].filter(Boolean).join(' ')

  return (
    <Container
      isMuted={!viewModel.member.isEnabled}
      className={classNames(cardAnimation)}
      {...rest}
    >
      {({
        isHovered: isCardHovered,
        isMenuOpen,
        onMenuClose,
        onMenuOpen,
      }) => (
        <>
          <Flex justifyContent='right' h='3'>
            {'canEnable' in viewModel.member && !viewModel.member.isEnabled
              ? <ActivateButton onClick={viewModel.member.onEnable} />
              : 'canDisable' in viewModel.member || 'canUpdate' in viewModel.member
                ? (
                  <OptionButton
                    color={isCardHovered ? 'accent.blue-to-gray' : undefined}
                    isOpen={isMenuOpen}
                    onOpen={onMenuOpen}
                    onClose={onMenuClose}
                  >
                    {'canUpdate' in viewModel.member && <EditMemberInfoOption onClick={viewModel.member.onUpdate} />}
                    {'canDisable' in viewModel.member && <DeactivateMemberOption onClick={viewModel.member.onDisable} />}
                  </OptionButton>
                )
                : null}
          </Flex>

          <Flex
            mt='1'
            gap={mbp({ mobSm: 2, mob: 3 })}
            alignItems='center'
          >
            <Avatar
              colorScheme='specialsat.700'
              size='lg'
              name={name}
              src={viewModel.member.avatarUrl ?? undefined}
            />

            <Box color='graystrong.400' minW={0}>
              <Tooltip label={name}>
                <CardSecondaryTitle
                  display='block'
                  shouldResetSpacing
                  isTruncated
                >
                  {name}
                </CardSecondaryTitle>
              </Tooltip>
              <Tooltip label={viewModel.member.email}>
                <CardSecondaryText
                  shouldResetSpacing
                  isTruncated
                >
                  {viewModel.member.email}
                </CardSecondaryText>
              </Tooltip>
              <Tooltip label={formatPhoneNumber(viewModel.member.phone)}>
                <CardSecondaryText
                  shouldResetSpacing
                  isTruncated
                >
                  {formatPhoneNumber(viewModel.member.phone)}
                </CardSecondaryText>
              </Tooltip>
            </Box>
          </Flex>

          <Flex
            mt='3'
            gap='2'
            flexDirection={mbp({ mobSm: 'row', tabSm: 'column' })}
          >
            <Pair
              size={mbp({ mobSm: 'sm', mob: 'md' })}
              flexBasis='50%'
            >
              <PairKey>Position</PairKey>
              <PairValue>{viewModel.member.isOwner ? 'Owner' : 'Team Member'}</PairValue>
            </Pair>

            {viewModel.member.memberSince && (
              <Pair
                size={mbp({ mobSm: 'sm', mob: 'md' })}
                flexBasis='50%'
              >
                <PairKey>Invited On</PairKey>
                <PairValue>{membersScreenUtils.formatDate(viewModel.member.memberSince)}</PairValue>
              </Pair>
            )}
          </Flex>
        </>
      )}
    </Container>
  )
}

const separateViewModel = ({
  member,
  ...rest
}: MemberCardProps) => ({
  viewModel: {
    member,
  } satisfies MemberCardViewModel,
  rest,
})
