import EventEmitter from 'eventemitter3'

const emitter = new EventEmitter()

export const SkiptraceEvents = {
  performSkiptraceDone: {
    emit: () => emitter.emit('performSkiptraceDone'),
    subscribe: (listener: () => void) => emitter.on('performSkiptraceDone', listener),
  },
}
