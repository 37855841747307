import { CMA } from 'features/CMA/CMA.domain'
import { ThumbButtons, ThumbButtonsProps, ThumbSize, ThumbValue } from 'presentation/components/ThumbButtons'
import { ratingFromThumbValue, thumbValueFromRatingNullable } from 'presentation/screens/CompsScreen/utils/CMAThumbRatingConverter'

export type CompsThumbButtonsProps = {
  size?: ThumbSize
  comp: Pick<CMA.SingleComp | CMA.SubjectComp, 'id' | 'type' | 'userRating' | 'status'>
  onCompsRatingChange: (id: string, rating: CMA.Comp.UserRating) => void
} & Omit<ThumbButtonsProps, 'value' | 'onChange'>

export const CompsThumbButtons = ({
  onCompsRatingChange,
  size,
  comp,
  ...props
}: CompsThumbButtonsProps) => {
  const handleOnThumbChange = (thumbState: ThumbValue) => {
    const rating = ratingFromThumbValue(thumbState)
    onCompsRatingChange(comp.id, rating)
  }

  /**
   * Show thumb buttons if:
   * 1. Entry is a regular comp
   * 2. Entry is a subject property with userRating NOT set to NULL.
   *   - if it's null, it means that the listing is off market, or didn't
   *   come from cma service (sourced from prop details as fallback)
   */
  const shouldShowThumbButtons = comp.type === 'single-comp'
    || (comp.type === 'subject-comp' && comp.userRating !== null)

  if (!shouldShowThumbButtons)
    return null

  return (
    <ThumbButtons
      /** Rerender if the actual comp changes */
      key={comp.id}
      value={thumbValueFromRatingNullable(comp.userRating)}
      onChange={handleOnThumbChange}
      size={size}
      {...props}
    />
  )
}
