import { Box } from '@chakra-ui/react'
import { NOTICE_ORDER, NoticeKey } from 'presentation/main/NoticeCarousel/Notice.const'
import { FC, Fragment, ReactNode, useEffect } from 'react'
import Swiper from 'swiper'
import 'swiper/css/autoplay'
import { Autoplay } from 'swiper/modules'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react'
import { create } from 'zustand'

const AUTOPLAY_DELAY = 30000

export const NoticeCarousel: FC = () => {
  const {
    notices,
    updateActiveIndex,
    setSwiper,
    defaultNotice,
  } = useNoticeStore(state => ({
    notices: state.notices,
    updateActiveIndex: state.updateActiveIndex,
    setSwiper: state.setSwiper,
    defaultNotice: state.defaultNotice,
  }))

  const carouselNotices = NOTICE_ORDER
    .map(key => [key, notices[key]])
    .filter((entry): entry is [string, ReactNode] => !!entry[1])

  /**
   * Rerender when set of notices change to prevent issues that were observed
   * when slides were asynchronously added/removed.
   */
  const key = carouselNotices.map(([key]) => key).join(',')

  /**
   * Reset index as well when set of notices change
   */
  useEffect(() => {
    updateActiveIndex(0)
  }, [key])

  return (
    <Box w='full' bg='graycool.100'>
      <SwiperComponent
        modules={[Autoplay]}
        onSwiper={setSwiper}
        spaceBetween={0}
        slidesPerView={1}
        loop={!!carouselNotices.length}
        onActiveIndexChange={({ realIndex }) => {
          updateActiveIndex(realIndex)
        }}
        autoplay={{ delay: AUTOPLAY_DELAY }}
        key={key}
      >
        {carouselNotices.length
          ? carouselNotices.map(([key, notice]) => (
            <Fragment key={key}>
              <SwiperSlide key={key} style={{ height: 'auto' }}>
                {notice}
              </SwiperSlide>
            </Fragment>
          ))
          : defaultNotice}
      </SwiperComponent>
    </Box>
  )
}

type NoticeState = {
  notices: Record<string, ReactNode>
  /** Displayed when there's no other notices */
  defaultNotice: ReactNode | null
  activeIndex: number
  updateNotice: (key: NoticeKey, value: ReactNode | null) => void
  updateActiveIndex: (index: number) => void
  updateDefaultNotice: (value: ReactNode | null) => void

  /** Mobile only */
  isExpanded: boolean
  toggleIsExpanded: () => void

  swiper?: Swiper
  setSwiper: (swiper: Swiper) => void
}

export const selectNoticeCount = (state: NoticeState) =>
  Object
    .entries(state.notices)
    .filter(([, value]) => value)
    .length

export const useNoticeStore = create<NoticeState>(set => ({
  notices: {},
  defaultNotice: null,
  activeIndex: 0,

  isExpanded: false,
  toggleIsExpanded: () => set(state => ({ isExpanded: !state.isExpanded })),

  updateNotice: (key, value) => set(state => ({
    ...state,
    notices: {
      ...state.notices,
      [key]: value,
    },
  })),

  updateDefaultNotice: value => set({ defaultNotice: value }),

  updateActiveIndex: index => set({ activeIndex: index }),

  swiper: undefined,
  setSwiper: swiper => set({ swiper }),
}))
