import { Table, TableContainer, TableContainerProps, TableProps } from '@chakra-ui/react'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { CMAEntriesTableScrollXSync } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTableScrollSync'
import { useEntriesTableStore } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { ReactNode, forwardRef } from 'react'

/**
 * @IMPORTANT Used for both sticky and non sticky version, don't add styles here
 * that would break either
 */
export const CompsTableContainer = (props: TableContainerProps) => {
  const { layoutWidth } = useLayoutWidth()
  const rows = useEntriesTableStore(store => store.rows)
  const scrollSync = CMAEntriesTableScrollXSync.useSync()

  if (rows === null) return null

  return (
    <TableContainer
      w='full'
      maxW={layoutWidth}
      px={3}
      ref={scrollSync.setRef}
      {...props}
    />
  )
}

/**
 * @IMPORTANT Used for both sticky and non sticky version, don't add styles here
 * that would break either
 */
export const CompsTable = forwardRef<
  HTMLTableElement,
  TableProps & {
    children: ReactNode
  }
>(({ children, ...props }, ref) => {
  const rows = useEntriesTableStore(store => store.rows)

  if (rows === null) return null

  return (
    <Table
      // Fixed is important for set table width to work
      layout='fixed'
      minW='912px'
      w='full'
      ref={ref}
      {...props}
    >
      {children}
    </Table>
  )
})

CompsTable.displayName = 'CompsTable'
