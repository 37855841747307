import { Box } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CardSelectionCarouselProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CardSelectionCarousel/CardSelectionCarousel.props'
import { CardSelectionSlide } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CardSelectionSlide'
import { useCardSelectionCarousel } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useCardSelectionCarousel'
import { CompEntry } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useComparativePropertyCardLogic'
import { isNonNull } from 'remeda'
import { Navigation, Virtual } from 'swiper/modules'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react'

export const CardSelectionCarousel = (props: CardSelectionCarouselProps) => {
  const {
    onCompsRatingChange,
    selectedIndex,
    entries: slideEntries,
    onSelectedIndexChange,
  } = props

  const cardSelection = useCardSelectionCarousel({
    entries: slideEntries,
    selectedIndex,
    onSelectedIndexChange,
  })

  const { sbp } = useSwitchBreakpointFn()

  if (cardSelection.status === 'aborting') return null

  const {
    slidesOffsetAfter,
    slidesOffsetBefore,
    isFirstSlide,
    isLastSlide,
    next,
    prev,
    setSwiper,
  } = cardSelection

  const handleSlideClick = (entry: CompEntry) => {
    onSelectedIndexChange(entry.entryIndex)
  }

  const selectedEntry = isNonNull(selectedIndex)
    ? slideEntries[selectedIndex] ?? null
    : null

  const slides = slideEntries
    .map(entry => (
      <SwiperSlide
        className={selectedEntry?.comp.id === entry.comp.id ? 'swiper-slide__selected' : ''}
        key={entry.comp.id}
        virtualIndex={entry.entryIndex}
      >
        <CardSelectionSlide
          selectedEntry={selectedEntry}
          entry={entry}
          onSelectedCompChange={handleSlideClick}
          onCompsRatingChange={onCompsRatingChange}
        />
      </SwiperSlide>
    ))

  const slidesPerView = sbp({
    tabSm: 2,
    dtSm: 3,
    dtLg: 4,
  })

  return (
    <Box
      sx={{
        '& > .swiper': {
          pb: 3,
        },
        '& > .swiper > .swiper-wrapper': {
          alignItems: 'center',
        },
        '& > .swiper > .swiper-wrapper > .swiper-slide': {
          h: 'min-content',
        },
        '& > .swiper > .swiper-wrapper > .swiper-slide.swiper-slide__selected': {
          h: 'min-content',
        },
      }}
    >

      <SwiperComponent
        modules={[Navigation, Virtual]}
        // @NOTE Important to specify a specific number instead of 'auto' in slidesPerView
        //  to make virtual slides work or else it will crash for some reason. Maybe because
        // it is auto it doesn't know how many it needs to render initially.
        slidesPerView={slidesPerView}
        spaceBetween={16}
        slidesOffsetBefore={slidesOffsetBefore}
        slidesOffsetAfter={slidesOffsetAfter}
        onSwiper={setSwiper}
        navigation={{ prevEl: null, nextEl: null }}
        virtual
      >
        {slides}
        {!isFirstSlide && (
          <NavButton
            direction='prev'
            onClick={prev}
          />
        )}
        {!isLastSlide && (
          <NavButton
            direction='next'
            onClick={next}
          />
        )}
      </SwiperComponent>
    </Box>
  )
}

const NavButton = (props: {
  direction: 'next' | 'prev'
  onClick?: () => void
}) => (
  <Box
    position='absolute'
    top='50%'
    {...(props.direction === 'next' ? { right: 2 } : { left: 2 })}
    transform='translateY(-50%)'
    borderRadius='50%'
    p={1}
    bgColor='special.500'
    zIndex='1'
    cursor='pointer'
    shadow='button-hovered'
    onClick={props.onClick}
  >
    {props.direction === 'next'
      ? <ChevronRightIcon boxSize={2.5} color='ondark.1' display='block' />
      : <ChevronLeftIcon boxSize={2.5} color='ondark.1' display='block' />}
  </Box>
)

NavButton.displayName = 'NavButton'
