import { VStack } from '@chakra-ui/react'
import { PropertyFeaturesCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertyFeatureCard'
import { PropertyTypeCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertyTypeCard'

export const PropertyFilterContent = () => (
  <VStack p={2} spacing={2} align='stretch'>
    <PropertyTypeCard />

    <PropertyFeaturesCard />
  </VStack>
)
