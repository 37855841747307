import { Badge, Flex, HStack } from '@chakra-ui/react'
import { Card, CardHeader, CardPrimaryText } from 'presentation/components/molecules/Card'
import { FilterCardProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard/FilterCard.props'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const FilterCard = (props: FilterCardProps) => (
  <Card
    size={mbp({
      mobSm: 'xxs-locked',
      tabSm: 'sm-locked',
    })}
    shadow='primary.m'
  >
    <CardHeader display='flex' alignItems='center' h={5}>
      <HStack spacing={1}>
        <CardPrimaryText color='graystrong.500' shouldResetSpacing>
          {props.title}
        </CardPrimaryText>

        {props.hasBadge && (
          <Badge
            display='inline-block'
            colorScheme='positivesat'
            boxSize='1.5'
          />
        )}
      </HStack>
      {/** Match Subject Property Placeholder */}
      {props.toolbar}
    </CardHeader>

    <Flex flexDir='column' gap={3} mt={2}>
      {props.children}
    </Flex>
  </Card>
)
