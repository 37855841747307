import { Box, Center, Flex, IconButtonProps, Image, Spinner, Text } from '@chakra-ui/react'
import { MaximizeIcon } from 'presentation/components/Icons'
import { Slider as CommonSlider, SliderButton, SliderIndicator, SliderNav } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/Slider.common'
import { SliderLogic } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/Slider.logic'
import { SliderMaximizedModal } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMaximizedModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SwiperProps, SwiperSlide } from 'swiper/react'
import noPhotoPng from './no-photo.png'

/**
 * @TODO Rename to photo slider
 * @TODO Move to common components
 */

export type SliderMinimizedProps = SliderProps & {
  photos: SliderLogic.Photo[]
}

export const SliderMinimized = ({
  photos,
  ...props
}: SliderMinimizedProps) => (
  <SliderLogic.Provider photos={photos}>
    <Slider {...props} />
  </SliderLogic.Provider>
)

export type SliderProps = SwiperProps & {
  h?: number
  emptyMessage?: string
  shouldHideControls?: boolean
  shouldOpenFullscreenOnSlideClick?: boolean
  onSlideClick?: () => void
}

const Slider = ({
  h,
  emptyMessage = 'Oops. No photos.',
  shouldHideControls,
  shouldOpenFullscreenOnSlideClick = true,
  onSlideClick,
  ...props
}: SliderProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { photos, currentSlide, setCurrentSlide } = SliderLogic.useContext()

  const handleSlideClick = (
    event: React.MouseEvent,
    i: number,
  ) => {
    setCurrentSlide(i)
    onSlideClick?.()
    if (!shouldOpenFullscreenOnSlideClick) return

    event.stopPropagation()
    setIsOpen(true)
  }

  return (
    !photos.length
      ? (
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          h={h && px(h)}
          minH={0}
          bg='card.bg.2'
          py={2}
        >
          <Image
            width='auto'
            h='auto'
            minH={0}
            flexShrink={1}
            src={noPhotoPng}
          />

          <Text color='grayweak.900' textStyle='bodyMFat'>
            {emptyMessage}
          </Text>
        </Flex>
      )
      : (
        <CommonSlider
          className='slider-minimized'
          slidesPerView={1}
          loop={!!photos.length}
          {...props}
        >
          {photos.map((photo, i) => (
            <SwiperSlide
              key={i}
              onClick={e => handleSlideClick(e, i)}
            >
              <Box
                h={h && px(h)}
                overflow='hidden'
              >
                <LazyLoadImage
                  className='slider-minimized__image'
                  src={photo.url}
                  alt={photo.caption || ''}
                  height={h}
                  width='100%'
                  useIntersectionObserver
                  placeholder={<SliderMinimizedSpinner height={h} />}
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: h,
                  }}
                />
              </Box>
              {/* </RenderInViewContainer> */}
            </SwiperSlide>
          ))}

          {!shouldHideControls && (
            <>
              <SliderNav />
              <SliderIndicator />
              <SliderFullscreenButton
                onClick={e => {
                  e.stopPropagation()
                  setIsOpen(true)
                }}
              />
            </>
          )}

          <SliderMaximizedModal
            photos={photos}
            isOpen={isOpen}
            initialSlide={currentSlide}
            onClose={() => {
              setIsOpen(false)
            }}
          />
        </CommonSlider>
      )
  )
}

const SliderFullscreenButton = (props: Omit<IconButtonProps, 'aria-label'>) => (
  <SliderButton
    className='slider-minimized__button-fullscreen'
    icon={<MaximizeIcon />}
    pos='absolute'
    top={mbp({ mobSm: '1.5', mob: '2.5' })}
    right={mbp({ mobSm: '1.5', mob: '2.5' })}
    zIndex='1'
    {...props}
    aria-label='Maximize'
  />
)

export const SliderMinimizedSpinner = ({
  height,
}: {
  height?: number
}) => (
  <Center bg='card.bg.2' h={height && px(height)}>
    <Spinner size='sm' color='grayweak.900' />
  </Center>
)
