import { Box, Button, CardProps, Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { usePropertyDetailsOwners } from 'features/PropertyDetails/infra/react/hooks/usePropertyDetailsOwners'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SkiptraceSuccessIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Tooltip } from 'presentation/components/Tooltip'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { isEmpty, pipe } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

export type OwnerSkiptraceCardProps = {
  onSkiptraceAgain: () => void
} & CardProps

export const OwnerSkiptraceCard = (props: OwnerSkiptraceCardProps) => {
  const { onSkiptraceAgain, ...cardProps } = props
  const store = useOwnerSkiptraceCardData()

  const { sbp } = useSwitchBreakpointFn()

  if (!store) return null

  return (
    <Card
      size={mbp({ mobSm: 'xxs-locked', tabSm: 'sm-locked' })}
      bgColor='highlight.100'
      {...cardProps}
    >
      {pipe(
        Match.value(store),
        Match.when({ status: 'success' }, result => (
          <>
            <Flex justifyContent='space-between'>
              <CardPrimaryTitle>
                Owners
              </CardPrimaryTitle>
              <Button
                colorScheme={sbp({ mobSm: 'positive', tabSm: 'neutral' })}
                variant='outline'
                size='md'
                onClick={onSkiptraceAgain}
              >
                <Breakpoint
                  mobSm='Skip Again'
                  tabSm='Skiptrace Again'
                />
              </Button>
            </Flex>
            <Flex
              flexDirection={mbp({ mobSm: 'row', tabSm: 'column' })}
              mt={2}
              justifyContent='space-between'
              gap={3}
            >
              <Breakpoint
                mobSm={(
                  <Pair size='sm' minW={0} flex='1 1 50%'>
                    <PairKey>
                      Name
                    </PairKey>
                    <PairValue as='div' display='flex' flexDirection='column' gap={1}>
                      {result.owners.map((owner, index) => (
                        <Tooltip label={owner.name.full} key={index}>
                          <Text color='inherit' isTruncated>
                            {owner.name.full}
                          </Text>
                        </Tooltip>
                      ))}
                    </PairValue>
                  </Pair>
                )}
                tabSm={(
                  <Flex flexDirection='column' gap={1}>
                    {result.owners.map((owner, index) => (
                      <Flex
                        key={index}
                        alignItems='center'
                        gap={1}
                        textStyle='bodyL'
                        minW={0}
                      >
                        <SkiptraceSuccessIcon boxSize={4} color='positive.500' />
                        <Text color='inherit' isTruncated>
                          {owner.name.full}
                        </Text>
                        {/** @NOTE no age in propery details owners */}
                        {/* <Text color='inherit' ml='auto'>{owner.age}</Text> */}
                      </Flex>
                    ))}
                  </Flex>
                )}
              />

              <Pair size={mbp({ mobSm: 'sm', tabSm: 'md' })} minW={0} flex='1 1 50%'>
                <PairKey>
                  Owner&rsquo;s Address
                </PairKey>
                <PairValue as='div'>
                  <Breakpoint
                    mobSm={(
                      <Tooltip label={result.address}>
                        <Box noOfLines={2}>
                          {result.address}
                        </Box>
                      </Tooltip>
                    )}
                    tabSm={result.address}
                  />
                </PairValue>
              </Pair>
            </Flex>
          </>
        )),
        Match.orElse(() => (
          <Center h='full'>
            <Spinner />
          </Center>
        )),
      )}
    </Card>
  )
}

const useOwnerSkiptraceCardData = () => {
  const owners = usePropertyDetailsOwners()

  const skiptraceResult = useSkiptraceStore(store => {
    const resultState = store.entities.resultState

    if (resultState.status !== 'success')
      return null

    const result = resultState.result

    if (!result)
      return null

    const getAddress = () => {
      const address = result.subject.address

      const joinedAddressParts = [
        address?.line1 ? `${address.line1},` : null,
        address?.city ? `${address.city},` : null,
        address?.state,
        address?.postalCode,
      ].filter(isNonNullable).join(' ')

      return isEmpty(joinedAddressParts) ? '--' : joinedAddressParts
    }

    return {
      status: resultState.status,
      address: getAddress(),
      skiptraceAgain: () => {
        if (resultState.status === 'success') {
          void store.actions.performSkiptrace.execute({
            leadId: resultState.params.leadId,
            address: result.subject.address,
          })
        }
      },
    }
  })

  return {
    ...skiptraceResult,
    owners,
  }
}
