import { BoxProps } from '@chakra-ui/react'
import { PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import PropertiesListItem from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/components/PropertyListItem'
import { usePropModalFromPreviewScreen } from 'presentation/screens/ListPreviewScreen/usePropModalFromPreviewScreen'

export const PropertiesListItemCard = (props: { item: PropertyListData } & BoxProps) => {
  const { item, ...componentProps } = props
  const propertyModalApi = usePropModalFromPreviewScreen()

  return (
    <PropertiesListItem
      id={createPropertyListItemCardId(item.id)}
      item={item}
      onClick={() => {
        propertyModalApi.actions.open(
          item.parcelId
            ? { parcelId: item.parcelId }
            : {
              address: item.address,
            },
        )
      }}
      {...componentProps}
    />
  )
}

export const createPropertyListItemCardId = (id: PropertyListDataId) =>
  `property-list-card-${id}`
