import { CMA } from 'features/CMA/CMA.domain'
import { filterByShowValue as byShowValue, sortBySortValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/CMAEntriesToolbar.utils'
import { useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { useMemo } from 'react'
import { createPipe, flatten, groupBy, map } from 'remeda'

export type UseEntriesTransformationParams = {
  comps: CMA.SingleComp[]
}

export type UseEntriesTransformation = (
  params: UseEntriesTransformationParams
) => CMA.SingleComp[]

export const useEntriesTransformation: UseEntriesTransformation = params => {
  const { showValues, sortValue, isSortGroupedByStatus } = useEntriesToolbarMenu()

  const filtered = params.comps.filter(byShowValue(showValues))
  const transformedValues = isSortGroupedByStatus
    ? createPipe(
      groupBy<CMA.SingleComp>(v => v.status),
      Object.entries,
      map(([, value]) => value.sort(sortBySortValue(sortValue))),
      flatten(),
    )(filtered)
    : filtered.sort(sortBySortValue(sortValue))

  return useMemo(() => transformedValues,
    [params.comps, showValues, sortValue, isSortGroupedByStatus])
}
