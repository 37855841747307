import { useMutation } from '@apollo/client'
import { Text } from '@chakra-ui/react'
import { gql } from '__generated__'
import { ChoosePlanFlow_ApplyQuoteMutation } from '__generated__/graphql'
import { GraphQLFormattedError } from 'graphql'
import { stripParenthesis } from 'libs/string/stripParenthesis'
import { capitalize } from 'lodash/fp'
import { CardButton } from 'presentation/components/molecules/Card'
import { GqlPath } from 'presentation/libs/graphql'
import loadGoogleTagManager from 'presentation/libs/loadGoogleTagManager'
import { ErrorForModal } from 'presentation/main/globalModals/ErrorModal/ErrorModal.errors'
import { handleDefaultOpenFailedInvoiceModal } from 'presentation/main/globalModals/FailedInvoiceModal/handleDefaultOpenFailedInvoiceModal'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { formatToFrequency } from 'presentation/screens/Billing/formatToFrequency'
import { get } from 'presentation/utils/graphql'
import { FC, PropsWithChildren, ReactNode, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPenniesOptionalDec, formatPenniesOptionalDecUsd } from 'utils/dataAdapter'
import { isNonNullable } from 'utils/isNonNullable'

export const CHOOSE_PLAN_FLOW__APPLY_QUOTE = gql(/* GraphQL */ `
 mutation ChoosePlanFlow_ApplyQuote($quoteId: ID!) {
  billingSubscriptionChangeQuoteApply(quoteId: $quoteId) {
    ... on BillingSubscriptionChangeQuoteNotFoundError {
      message
    }
    ... on BillingSubscriptionChangeQuoteExpiredError {
      message
    }
    ... on BillingPaymentDeclinedError {
      message
    }
    ... on Enterprise {
      id
      paymentMethods {
        edges {
          node {
            ... on BillingCardsPaymentMethod {
              isDefault
              last4
            }
          }
        }
      }

      subscription {
        ... on BillingSubscriptionActive {
          plan {
            id
            name
            interval {
              unit
            }
          }
        }
        ...FailedInvoiceFlow_FailedInvoiceSummary
      }

      ...SubscriptionUpdate
    }
  }
}
`)

export type ApplyQuote = (quoteId: string, amount: number) => Promise<ReactNode>

type UseApplyQuoteMutationResult = {
  applyQuote: ApplyQuote
  loading: boolean
}

export const useApplyQuoteMutation = (): UseApplyQuoteMutationResult => {
  const [applyQuoteMutation, { loading }] = useMutation(CHOOSE_PLAN_FLOW__APPLY_QUOTE)
  const navigate = useNavigate()

  const applyQuote: ApplyQuote = useCallback(async (quoteId, amount) =>
    await applyQuoteMutation({ variables: { quoteId } })
      .then(({ errors, data }) => {
        const errMsg = data ? getErrors(data, errors) : null

        if (errMsg) throw new Error(errMsg)

        const enterprise = data ? getEnterprise(data.billingSubscriptionChangeQuoteApply) : null

        if (!enterprise) throw new Error('Enterprise missing in applyQuote')

        const activeSub = get('BillingSubscriptionActive')(enterprise.subscription)

        if (!activeSub) throw new Error('Active subscription missing in applyQuote')

        const failedInvoiceSummary = unmaskFailedInvoiceFragment(activeSub)

        if (failedInvoiceSummary) {
          loadGoogleTagManager().sendFailedInvoice(failedInvoiceSummary)

          const handleTryPaymentAgain = () => {
            handleDefaultOpenFailedInvoiceModal(failedInvoiceSummary)
          }

          navigate('/user/billing')

          throw new ErrorForModal({
            title: 'Plan was updated but we weren’t able to charge your card',
            message: 'Please update your payment and pay the latest invoice immediately. Otherwise, your subscription will be cancelled soon.',
            actionButtons: () => (
              <CardButton
                variant='solid'
                colorScheme='positive'
                onClick={handleTryPaymentAgain}
                autoFocus
              >
                Pay Invoice
              </CardButton>
            ),
          })
        }

        /**
         * Build success message
         */
        const last4 = getLast4Digit(enterprise)
        const pennies = ` ${formatPenniesOptionalDecUsd(amount)}`
        const planName: string = capitalize(stripParenthesis(activeSub.plan.name))
        const frequency = isNonNullable(activeSub.plan.interval.unit)
          ? formatToFrequency(activeSub.plan.interval.unit)
          : null

        const value = formatPenniesOptionalDec(amount)

        void loadGoogleTagManager().sendPurchaseEvent({
          ecommerce: {
            currency: 'USD',
            value,
            transaction_id: quoteId,
            items: [{
              item_id: activeSub.plan.id,
              item_name: planName,
              price: value,
              item_category: frequency ?? 'N/A',
              quantity: '1',
            }],
          },
        })

        const planMessage = frequency
          ? (
            <>
              You are now on the&nbsp;
              <Emphasize>
                (
                {frequency}
                )
                {' '}
                {planName}
                {' '}
                Plan
              </Emphasize>
              .
            </>
          )
          : (
            <>
              <Emphasize>
                $
                {planName}
                {' '}
                Plan
              </Emphasize>
              .
            </>
          )

        const successMessage = amount > 0
          ? (
            <>
              Your card ending in
              {' '}
              {last4}
              {' '}
              was successfully charged
              {pennies}
              .
              {planMessage}
            </>
          )
          : planMessage

        return successMessage
      }), [applyQuoteMutation])

  return { applyQuote, loading }
}

type EnterpriseFromResp = GqlPath<ChoosePlanFlow_ApplyQuoteMutation, [
  ['billingSubscriptionChangeQuoteApply', 'Enterprise'],
]>

const getLast4Digit = (enterprise: EnterpriseFromResp): string => {
  const defaultPaymentMethod = enterprise?.paymentMethods?.edges
    ?.find(v => get('BillingCardsPaymentMethod')(v?.node)?.isDefault)
    ?.node

  return get('BillingCardsPaymentMethod')(defaultPaymentMethod)?.last4 ?? '####'
}

const getEnterprise = get('Enterprise')

const getErrorFromData = (data: ChoosePlanFlow_ApplyQuoteMutation): string | null => {
  if ('message' in data.billingSubscriptionChangeQuoteApply)
    return data?.billingSubscriptionChangeQuoteApply?.message ?? null

  return null
}

const getErrors = (
  data: ChoosePlanFlow_ApplyQuoteMutation,
  graphqlError: readonly GraphQLFormattedError[] | undefined,
): string | null => {
  const errorFromData = getErrorFromData(data)

  if (errorFromData) return errorFromData

  if (graphqlError?.[0]?.message)
    return 'Unexpected error occurred. Please try again or contact support.'

  return null
}

const Emphasize: FC<PropsWithChildren> = ({ children }) => (
  <Text as='span' textStyle='bodyLHeavy' color='inherit'>{children}</Text>
)
