import { Box, Center, Grid, Spinner, StackProps, VStack } from '@chakra-ui/react'
import { useSelector } from '@xstate/react'
import cn from 'classnames'
import { Array, pipe } from 'effect'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import { SHADOWS } from 'presentation/main/themes/common/shadows.theme'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { fadeInUp, fast } from 'presentation/utils/animationClasses'
import { PropsWithChildren } from 'react'
import {
  CompsFeatureRow,
  D4DFeatureRow,
  DirectMailFeatureRow,
  HeaderRow,
  LeadsFeatureRow,
  LiveSupportFeatureRow,
  PriceFeatureRow,
  SectionBanner,
  SkiptraceFeatureRow,
  TeamFeatureRow,
  UserAccessFeatureRow,
} from './components'

const PlanComparisonDetailed = () => {
  // @NOTE: should move to view model instead?
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const isLoading = useSelector(actor, snap => snap.hasTag('products-unready'))

  return isLoading
    ? (
      <SectionContainer minH='2680px' h='1px'>
        <Center py='4' h='full'>
          <Spinner color='graystrong.500' />
        </Center>
      </SectionContainer>
    )
    : (
      <SectionContainer>
        <SectionBanner />
        <SectionBody>
          <HeaderRow />
          <PriceFeatureRow />
          <LeadsFeatureRow />
          <SkiptraceFeatureRow />
          <DirectMailFeatureRow />
          <CompsFeatureRow />
          <D4DFeatureRow />
          {/* <PropertyFindersFeatureRow /> */}
          <TeamFeatureRow />
          <UserAccessFeatureRow />
          <LiveSupportFeatureRow />
        </SectionBody>
      </SectionContainer>

    )
}

export default PlanComparisonDetailed

const SectionBody = ({ children }: PropsWithChildren) => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const columns = useSelector(
    actor,
    snapshot => pipe(
      snapshot.context.products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.length,
    ),
  )
  return (
    <Box
      maxW='full'
      minW={0}
      overflowX={{
        base: 'auto',
        dtLg: 'initial',
      }}
      pb={{
        dtSm: '80px',
        tabSm: '5',
        mob: '4',
        base: '4',
      }}
    >
      <ScrollAnimation
        className={cn(fadeInUp, fast)}
      >
        <Grid
          mt={{ base: '4', dtSm: '80px' }}
          px={{
            base: '1',
            mob: '3',
            tabSm: '6',
          }}
          gridTemplateColumns={{
            base: `194px repeat(${columns}, 164px)`,
            dtSm: `436px repeat(${columns}, 224px)`,
          }}
          filter={`drop-shadow(${SHADOWS['primary.s']})`}
          sx={{
            '& > div:not(:first-of-type):nth-of-type(odd)': {
              bgColor: 'grayweak.200',
            },
            '& > div:not(:first-of-type):nth-of-type(even)': {
              bgColor: 'grayweak.100',
            },
          }}
        >
          {children}
        </Grid>
      </ScrollAnimation>
    </Box>
  )
}

const SectionContainer = ({ children, ...props }: StackProps) => (
  <VStack
    justify='center'
    bg='graycool.100'
    id='plan-comparison-detailed'
    w='full'
    pt={{
      dtSm: '80px',
      tabSm: '5',
      mob: '4',
      base: '4',
    }}
    {...props}
  >
    {children}
  </VStack>
)
