import { createIcon } from '@chakra-ui/react'

export const SquareWithArrowIcon = createIcon({
  displayName: 'SquareWithArrowIcon',
  viewBox: '0 0 25 25',
  path: (
    <g fill='none' stroke='currentColor'>
      <path
        d='M18.5 13.6191V19.6191C18.5 20.1496 18.2893 20.6583 17.9142 21.0334C17.5391 21.4084 17.0304 21.6191 16.5 21.6191H5.5C4.96957 21.6191 4.46086 21.4084 4.08579 21.0334C3.71071 20.6583 3.5 20.1496 3.5 19.6191V8.61914C3.5 8.08871 3.71071 7.58 4.08579 7.20493C4.46086 6.82985 4.96957 6.61914 5.5 6.61914H11.5'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 3.61914H21.5V9.61914'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 14.6191L21.5 3.61914'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
