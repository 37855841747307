import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { HouseSizeFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/HouseSizeFilterCard/HouseSizeFilterCard.dumb.props'

import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { isNumber } from 'remeda'

export const HouseSizeFilterCardDumb = (
  props: HouseSizeFilterCardDumbProps,
) => (
  <FilterCard
    title='House Size'
    hasBadge={
      PartialRange.checkHasValueOptional(props.value)
    }
  >
    <PartialRangeInputV2
      initialValue={props.value}
      onDebouncedValidChange={newRange => {
        props.onChange(newRange)
      }}
      subjectValue={NumberLib.formatCODoDD(props.subjectValue)}
      formatInputDisplay={v => {
        const parsed = isNumber(v) ? v : NumberLib.fromStringSafe(v)

        if (parsed === null) return ''

        return NumberLib.formatCommaOptionalDecimals(parsed)
      }}
    />
    {props.filterErrorMsg && (
      <CompsFilterCardError error={props.filterErrorMsg} />
    )}
  </FilterCard>
)
