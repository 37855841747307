import { Match, Option, pipe } from 'effect'

type ProductName =
  | 'free'
  | 'beginner'
  | 'intermediate'
  | 'advanced'

const ProductName = {
  fromString: (name: string) => pipe(
    Match.value(name),
    Match.withReturnType<Option.Option<ProductName>>(),
    Match.when('Free', () => Option.some('free')),
    Match.when('Beginner', () => Option.some('beginner')),
    Match.when('Intermediate', () => Option.some('intermediate')),
    Match.when('Advanced', () => Option.some('advanced')),
    Match.orElse(() => Option.none()),
  ),
}

export default ProductName
