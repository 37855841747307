import { gql } from '__generated__'

const GET_OWNERS_PROPERTIES_PREVIEW = gql(/* GraphQL */`
query GetOwnersPropertiesPreview($parcelId: String!) {
  parcel(id: $parcelId) {
    id
    ownerDetails {
      ownerClassification
      ownershipMonths
      owners {
        id
        name {
          ...OwnerPropertiesPreviewParcelContactNameFields
        }
        address {
          ...OwnerPropertiesPreviewVerifiedAddressBasicFields
          ...OwnerPropertiesPreviewUnverifiedAddressBasicFields
        }
        buyerDetails {
          buyerTypes
          stats {
            min
            max
            total
            average
            count
            counties {
              ...on UsCounty {
                fips
                name
                state
              }
            }
          }
          historicalDeals(first: 1, filters: { limitToCurrentlyOwned: true }) {
            edges {
              node {
                ...NearbyBuyersDealDetails
              }
            }
            pageInfo {
              totalCount
            }
          }
        }
      }
      ownerStatus
      isForeclosure
      isHomestead
      isSenior
      isTaxDelinquent
      isVacant
    }
    valuationDetails {
      condition
      equity
      loanToValue
      high
      low
      value
    }
  }
}

fragment OwnerPropertiesPreviewVerifiedAddressBasicFields on AddressVerified {
line1
city
state
postalCode
}

fragment OwnerPropertiesPreviewUnverifiedAddressBasicFields on AddressUnverified {
line1
city
state
postalCode
}

fragment OwnerPropertiesPreviewParcelContactNameFields on ParcelContactName {
first
middle
last
full
}

`)

export default GET_OWNERS_PROPERTIES_PREVIEW
