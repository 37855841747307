import * as inputTheme from 'presentation/components/Input/Input.theme'
import { selectLegacyTheme } from 'presentation/components/Select/SelectLegacy.theme'

export const selectTheme = {
  baseStyle: {
    ...inputTheme.baseStyle,
  },
  variants: {
    ...selectLegacyTheme.variants,

    default: {
      field: {
        ...inputTheme.variants.default.field,
        pr: 0,
      },
      icon: {
        insetEnd: 0,
        pl: 1,
        pr: 2,
        fontSize: 3,
        color: inputTheme.variants.default.field._placeholder.color,
      },
    },
  },

  sizes: {
    md: {
      ...inputTheme.sizes.md,
    },
  },

  defaultProps: {
    variant: 'default',
    size: 'md',
  },
}
