import { gql } from '__generated__'

export const MarketingListRemoveParcel = gql(/* GraphQL */`
  mutation MarketingListRemoveParcel($parcelId: ID!, $listId: ID!) {
    marketingListRemoveParcel(parcelId: $parcelId, listId: $listId) {
      ... on MarketingListNotFoundError {
        message
        field
      }
      ... on MarketingListParcelNotFoundError {
        message
        field
      }
      ... on MarketingListUnsupportedTypeError {
        message
        field
      }
      ... on MarketingList {
        id
        name
        createdAt
        updatedAt
        type
        itemStats {
          skiptraced
          skiptracing
          total
        }
      }
    }
  }
`)
