import { createIcon } from '@chakra-ui/react'

export const BookIcon = createIcon({
  displayName: 'BookIcon',
  viewBox: '0 0 25 25',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M4.5 20.2842C4.5 19.6211 4.76339 18.9853 5.23223 18.5164C5.70107 18.0476 6.33696 17.7842 7 17.7842H20.5' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 2.78418H20.5V22.7842H7C6.33696 22.7842 5.70107 22.5208 5.23223 22.0519C4.76339 21.5831 4.5 20.9472 4.5 20.2842V5.28418C4.5 4.62114 4.76339 3.98525 5.23223 3.51641C5.70107 3.04757 6.33696 2.78418 7 2.78418V2.78418Z' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
