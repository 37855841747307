import { Button, ButtonProps } from '@chakra-ui/react'
import { Option } from 'effect'
import OwnersPropertiesRoutes from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes'
import { HomeIcon } from 'presentation/components/Icons'
import { usePropertyModalContext } from 'presentation/components/PropertyModal/PropertyModalContext'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { Link } from 'react-router-dom'

export type MayOwnMorePropsButtonProps = ButtonProps & {
  count: Option.Option<number>
}

/** Button that would open PropertyOwnershipDetails */
const MayOwnMorePropsButton = ({
  count,
  ...props
}: MayOwnMorePropsButtonProps) => {
  const leadId = useLeadId()
  const { isPropertyModal } = usePropertyModalContext()

  if (!leadId) return null

  return (
    <Button
      leftIcon={(
        <HomeIcon boxSize='2' />
      )}
      variant='ghost'
      colorScheme='neutral'
      size='xs'
      as={Link}
      to={OwnersPropertiesRoutes.makeOwnersPropertiesPath(leadId)}
      target={isPropertyModal ? '_blank' : undefined}
      {...props}
    >
      MAY OWN
      {count.pipe(Option.match({
        onNone: () => <>{' '}</>,
        onSome: c => (
          <>
            {' '}
            {c}
            {' '}
          </>
        ),
      }))}
      MORE PROPS
    </Button>
  )
}

export default MayOwnMorePropsButton
