import { useConfigureLayout } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useEffect } from 'react'

export const useConfigScreenTitle = ({
  htmlTitle,
  layoutTitle,
}: {
  htmlTitle: string
  layoutTitle: string
}) => {
  useEffect(() => {
    document.title = `${htmlTitle} | Propelio`
  }, [])

  useConfigureLayout({ title: layoutTitle })
}
