import { pipe } from 'effect'

namespace LocationPath {
  export const getParentPath = (path: string) => path.substring(0, path.lastIndexOf('/'))
  export const getNthParentPath = (path: string, n: number) => {
    let parentPath = path
    for (let i = 0; i < n; i++)
      parentPath = LocationPath.getParentPath(parentPath)

    return parentPath
  }

  export const checkEndsWithSubpath = (subpath: string, path: string) => {
    const cleanedPath = removeTrailSlash(path)
    const cleanedSubpath = cleanSubpath(subpath)

    return cleanedPath.endsWith(cleanedSubpath)
  }

  export const removeTrailSlash = (path: string) =>
    path.endsWith('/') ? path.slice(0, -1) : path

  export const removeLeadSlash = (path: string) =>
    path.startsWith('/') ? path.slice(1) : path

  export const cleanSubpath = (subpath: string) =>
    pipe(
      subpath,
      removeLeadSlash,
      removeTrailSlash,
    )

  export const removeTrailSubpath = (subpath: string, path: string) => {
    const cleanedPath = removeTrailSlash(path)
    const cleanedSubpath = cleanSubpath(subpath)

    const hasSubpath = cleanedPath.endsWith(cleanedSubpath)

    if (!hasSubpath) return cleanedPath

    const sliced = cleanedPath.slice(0, -cleanedSubpath.length)
    const cleaned = removeTrailSlash(sliced)

    return cleaned
  }

  export const appendSubpath = (subpath: string, path: string) => {
    const cleanedPath = removeTrailSlash(path)
    const cleanedSubpath = cleanSubpath(subpath)
    return `${cleanedPath}/${cleanedSubpath}`
  }
}

export default LocationPath
