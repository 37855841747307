import { Box, Image, Spacer, Text } from '@chakra-ui/react'
import cn from 'classnames'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import { fadeIn, fadeInDown, fadeInLeft, fast, faster } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, PropsWithChildren } from 'react'
import { useCommonQuestions } from './hooks/useCommonQuestions'
import FAQPng from './images/FAQ.png'

export const FAQ: FC = () => {
  const questions = useCommonQuestions()

  return (
    <Box
      bg='bg-landingpage-special'
      color='ondark.1'
      pt='5'
      px={mbp({ mobSm: '2', mob: '7', dtSm: '0' })}
      pb='4'
    >
      <Box
        textAlign='left'
        w={mbp({ mobSm: '288px', mob: '352px', dtSm: '720px', tabSm: '640px', dtLg: '960px' })}
        mx='auto'
      >
        <HeadingBlock>Common questions</HeadingBlock>

        {questions.map((qa, index) => (
          <Box
            key={index}
            mt={mbp({ mobSm: '4', tabSm: '6', dtLg: '7' })}
            mx='auto'
          >
            <ScrollAnimation
              className={cn(fadeInLeft, faster)}
              staggerFactor='long'
            >
              <QuestionBlock>{qa.question}</QuestionBlock>
            </ScrollAnimation>
            <Spacer h={mbp({ mobSm: '1', tabSm: '2' })} />

            <ScrollAnimation
              className={cn(fadeIn)}
              staggerFactor='long'
            >
              <AnswerBlock>
                {
                  qa.answer.split('\n').map((item, idx) => (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  ))
                }
              </AnswerBlock>
            </ScrollAnimation>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const HeadingBlock: FC<PropsWithChildren> = ({ children }) => (
  <Text
    textStyle={mbp({
      mobSm: 'inter-18-700',
      mob: 'inter-24-700',
      tabSm: 'inter-29-700',
      dtSm: 'inter-36-700',
      dtLg: 'inter-48-700',
    })}
    color='inherit'
    as='h1'
    position='relative'
  >
    <ScrollAnimation
      className={cn(fadeInDown, fast)}
    >
      {children}
    </ScrollAnimation>

    <ScrollAnimation
      className={cn(fadeIn, faster)}
    >
      <Image
        src={FAQPng}
        position='absolute'
        h={mbp({ mobSm: '46px', mob: '61px', tabSm: '114px', dtSm: '146px', dtLg: '165px' })}
        right={mbp({ mobSm: '20px', mob: '0', tabSm: '80px' })}
        top={mbp({ mobSm: '-15px', tabSm: '-25px' })}
      />
    </ScrollAnimation>

  </Text>
)

const QuestionBlock: FC<PropsWithChildren> = ({ children }) => (
  <Text
    textStyle={mbp({
      mobSm: 'inter-16-700',
      mob: 'inter-18-700',
      tabSm: 'inter-20-700',
      dtSm: 'inter-24-700',
      dtLg: 'inter-29-700',
    })}
    color='inherit'
    as='h3'
  >
    {children}
  </Text>
)

const AnswerBlock: FC<PropsWithChildren> = ({ children }) => (
  <Text
    textStyle={mbp({
      mobSm: 'body2-b',
      tabSm: 'lato-18-700',
      dtSm: 'h3-b',
    })}
    color='inherit'
    as='p'
  >
    {children}
  </Text>
)
