/**
 * ProgramException an error that...
 * - is NOT a domain error
 * - is unexpected (E.g. network failure, backend failure, program failure, etc)
 * - is created when catching an error OR encountering an unexpected situation
 * - has user friendly error message and is meant to be represented to the user
 * - should be handled gracefully
 * - should not crash the app for
 * @TODO Require message
 */
const programExceptionTag = Symbol('programExceptionTag')
export class ProgramException extends Error {
  [programExceptionTag] = true
  name = 'ProgramException'
}
