import { createIcon } from '@chakra-ui/react'

export const PaperPlaneIcon = createIcon({
  displayName: 'PaperPlaneIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill='none'>
      <path
        d='M22 2L11 13'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 2L15 22L11 13L2 9L22 2Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
