import { Effect } from 'effect'
import GetOwnerDeals from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'
import { EffectLib } from 'libs/Effect'
import { ErrorLib } from 'libs/errors'
import AppCache from 'services/AppCache'
import { fromPromise } from 'xstate'

namespace GetOwnerDealsActor {
  export const make = () => Effect.gen(function * (_) {
    const repo = yield * OwnersPropertiesRepo
    const runPromise = yield * EffectLib.getRunPromiseFromRuntime<AppCache>()

    return fromPromise((params: { input: GetOwnerDeals.Params }) =>
      repo.getOwnerDeals(params.input).pipe(
        ErrorLib.tapCauseReporter,
        runPromise,
      ))
  })
}

export default GetOwnerDealsActor
