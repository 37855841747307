import { Breakpoint } from 'presentation/components/Breakpoint'
import { CompsFilterPanelDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/CompsFilterPanel.props'
import { CompsFilterPanelDrawer } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/CompsFilterPanelDrawer/CompsFilterPanelDrawer'
import { CompsFilterFullscreenPanel } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/CompsFilterPanelModal/CompsFilterPanelModal'

export const CompsFilterPanelDumb = ({
  children,
  ...props
}: CompsFilterPanelDumbProps) => (
  <Breakpoint
    mobSm={(
      <CompsFilterFullscreenPanel {...props}>
        {children}
      </CompsFilterFullscreenPanel>
    )}
    tabSm={(
      <CompsFilterPanelDrawer {...props}>
        {children}
      </CompsFilterPanelDrawer>
    )}
  />
)
