import { ComponentWithAs, IconProps } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { LayersIcon, SliderHorizontalIcon } from 'presentation/components/Icons'
import { isEmpty } from 'remeda'

export type SearchHistoryMetaGetIcon = (params: {
  textFilter: string
  hasSeekerFilter: boolean
}) => ComponentWithAs<'svg', IconProps>

export type SearchHistoryMetaGetColor = (params: {
  textFilter: string
  hasSeekerFilter: boolean
}) => string

export type SearchHistoryMetaTextFormatter = (params: {
  totalResult: number
  hasSeekerFilter: boolean
  textFilter: string
  currentPage: number
  pageLimit: number
}) => string

export const formatText: SearchHistoryMetaTextFormatter = params => {
  const {
    totalResult,
    hasSeekerFilter,
    textFilter,
    currentPage,
    pageLimit,
  } = params

  const paginationInfo = createPaginationInfo({
    currentPage,
    pageLimit,
    totalResult,
  })

  return pipe(
    Match.value([hasTextFilter(textFilter), hasSeekerFilter]),
    Match.when([true, false], () => `${paginationInfo} result for "${textFilter}"`),
    Match.when([false, true], () => `${paginationInfo} matches in search history`),
    Match.when([true, true], () => `${paginationInfo} matches for "${textFilter}"`),
    Match.orElse(() => `${paginationInfo} item(s) in search history`),
  )
}

export const getIcon: SearchHistoryMetaGetIcon = params => {
  const { textFilter, hasSeekerFilter } = params
  return pipe(
    Match.value([hasTextFilter(textFilter), hasSeekerFilter]),
    Match.when([true, false], () => LayersIcon),
    Match.when([false, true], () => SliderHorizontalIcon),
    Match.when([true, true], () => SliderHorizontalIcon),
    Match.orElse(() => LayersIcon),
  )
}

export const getBgColor: SearchHistoryMetaGetColor = params => {
  const { textFilter, hasSeekerFilter } = params
  return hasTextFilter(textFilter) || hasSeekerFilter
    ? 'card.bg.yellow'
    : 'neutral.500'
}

export const getColor: SearchHistoryMetaGetColor = params => {
  const { textFilter, hasSeekerFilter } = params
  return hasTextFilter(textFilter) || hasSeekerFilter
    ? 'graystrong.500'
    : 'ondark.1'
}

const hasTextFilter = (value: string | null) => {
  if (value === null) return false
  return !isEmpty(value.trim())
}

type CreatePaginationInfo = (params: {
  currentPage: number
  pageLimit: number
  totalResult: number
}) => string

const createPaginationInfo: CreatePaginationInfo = ({
  currentPage,
  pageLimit,
  totalResult,
}) => {
  const minBound = (currentPage - 1) * pageLimit + 1
  const maxBound = currentPage * pageLimit

  if (totalResult === 0 || totalResult <= pageLimit)
    return totalResult.toString()

  if (minBound === totalResult)
    return `${minBound} of ${totalResult}`

  if (maxBound > totalResult)
    return `${minBound}-${totalResult} of ${totalResult}`

  return `${minBound}-${maxBound} of ${totalResult}`
}

export const SearchHistoryMeta = {
  hasTextFilter,
  formatText,
  getIcon,
  getColor,
  getBgColor,
}
