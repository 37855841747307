import { Avatar, ButtonProps, CardProps, Flex, Text, TextProps } from '@chakra-ui/react'
import { useRankTheme } from 'features/Billing/Plans/useRankTheme'
import { PersonName } from 'features/valueObjects/PersonName'
import pluralize from 'pluralize'
import { PlusIcon } from 'presentation/components/Icons'
import { Card, CardButton, CardFooter, CardSecondaryText } from 'presentation/components/molecules/Card'
import { PlanCardViewModel } from 'presentation/screens/MembersScreen/components/PlanCard/PlanCard.viewModel'
import { animationClasses } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const MEMBERS_REDIRECT_VALUE = 'user/team'

export type PlanCardProps = PlanCardViewModel & CardProps

export const PlanCard: FC<PlanCardProps> = ({
  plan: {
    rank,
    limit,
    name,
  },
  owner: {
    firstName,
    lastName,
    avatarUrl,
  },
  onInviteMembers,
  ...props
}) => {
  const planColor = useRankTheme(rank).mainColor

  return (
    <Card
      className={animationClasses.fadeIn}
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked' })}
      {...props}
    >
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        h='100%'
      >
        <Flex
          gap='2'
          flexGrow={1}
          alignItems='center'
          {...mbpg({
            mobSm: rank !== 'advanced'
              ? {
                flexDirection: 'row',
              }
              : {
                flexDirection: 'column',
                justifyContent: 'center',
              },
            tabSm: {
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          <Avatar
            name={PersonName.combine(firstName, lastName) || undefined}
            size='lg'
            {...avatarUrl && { src: avatarUrl }}
          />

          <Flex
            {...mbpg({
              mobSm: rank !== 'advanced'
                ? {
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                }
                : {
                  flexDirection: 'column',
                  textAlign: 'center',
                },
              tabSm: {
                alignItems: 'center',
                textAlign: 'center',
              },
            })}
          >
            <PlanName color={planColor}>
              {name}
              {' '}
              Plan
            </PlanName>
            <CardSecondaryText shouldResetSpacing>
              {limit === Infinity ? 'Unlimited' : limit}
              {' '}
              team
              {' '}
              {pluralize('member', limit)}
              {' '}
              included!
            </CardSecondaryText>
          </Flex>
        </Flex>

        <CardFooter
          flexDir='column'
          horizontallyStackAt={null}
        >
          <InviteButton onClick={onInviteMembers} />
          {rank !== 'advanced' && <UpgradeButton />}
        </CardFooter>
      </Flex>
    </Card>
  )
}

const PlanName: FC<TextProps> = ({ children, ...props }) => (
  <Text
    textStyle={mbp({ mobSm: 'bodyLHeavy', mob: 'h4Heavy' })}
    {...props}
  >
    {children}
  </Text>
)

const InviteButton: FC<ButtonProps> = props => (
  <CardButton
    variant='solid'
    colorScheme='special'
    leftIcon={<PlusIcon boxSize='3' />}
    {...props}
  >
    Invite a Team Member
  </CardButton>
)

const UpgradeButton: FC<ButtonProps> = props => (
  <CardButton
    as={Link}
    variant='outline'
    colorScheme='neutral'
    to={`/pricing?redirect=${MEMBERS_REDIRECT_VALUE}#first-choose-plan`}
    {...props}
  >
    Upgrade Plan
  </CardButton>
)
