import { Location } from 'features/valueObjects/Location'
import { PropertyMarkerInfo } from 'features/valueObjects/PropertyMarkerInfo'
import { useMapStore } from 'presentation/components/Map'
import { Map } from 'presentation/components/Map/Map'
import { MapMode } from 'presentation/components/Map/createMapStore'
import { TabContainer } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/TabContainer'
import { PropertyMapMarker } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/MapMarkers/PropertyMapMarker'
import { useEffect } from 'react'
import { FullscreenControl } from 'react-map-gl'
import { shallow } from 'zustand/shallow'

export const MapView = ({ location, markerInfo, mapMode }: {
  location: Location
  markerInfo: PropertyMarkerInfo
  mapMode: MapMode
}) => {
  const { mapRef, defaultZoom, setMapMode } = useMapStore(store => ({
    defaultZoom: store.defaultZoom,
    mapRef: store.computed.getMapRef(),
    setMapMode: store.setMode,
  }), shallow)

  useEffect(() => {
    mapRef?.resize()
  }, [mapRef])

  useEffect(() => {
    setMapMode(mapMode)
  }, [mapMode])

  return (
    <TabContainer borderRadius='3'>
      <Map
        initialViewState={{
          ...location,
          zoom: defaultZoom,
        }}
      >
        <FullscreenControl />

        <PropertyMapMarker
          size='md'
          {...location}
          {...markerInfo}
        />
      </Map>
    </TabContainer>
  )
}
