import { PROPERTY_MODAL_CONTAINER_CLASS } from 'presentation/components/PropertyModal/PropertyModal.const'
import { layoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { DOMLib } from 'presentation/libs/DOM'
import { COMPS_TABLE_ID } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable.const'
import { createCompsEntryElementId } from 'presentation/screens/CompsScreen/utils/createEntryElementId'
import { useEffect } from 'react'
import { useMeasure } from 'react-use'
import { create } from 'zustand'

export type CompsTableViewScrollerStore = {
  stickyHeight: number
}

const store = create<CompsTableViewScrollerStore>(() => ({
  stickyHeight: 0,
}))

const api = {
  store,
  useStore: store,
  setStickyHeight: (height: number) => store.setState({ stickyHeight: height }),
  scrollToRow: (rowId: string, isSubject: boolean) => {
    /**
     * Delay is workaround for two issues:
     * 1. We need to wait for the new row to be expanded, which will affect the scroll position
     * 2. The smooth scroll is being interrupted (at least with `scrollToMap`)
     *    presumably because of some other animation firing around the same time
     */
    setTimeout(() => {
      const elementId = createCompsEntryElementId(rowId)
      /**
       * @IMPORTANT Search from this element which is the main table in order
       *   to avoid getting row from sticky table
       */
      const mainTable = document.getElementById(COMPS_TABLE_ID)
      const element = mainTable?.querySelector(`#${elementId}`)

      if (!element) return

      const isWithinPropertyModal = !!mainTable && DOMLib.isAncestorOfClass(mainTable, PROPERTY_MODAL_CONTAINER_CLASS)

      if (isWithinPropertyModal) {
        const stickyOffset = isSubject ? 0 : store.getState().stickyHeight
        const scrollElement = document.getElementsByClassName(PROPERTY_MODAL_CONTAINER_CLASS)[0].parentElement
        if (!scrollElement) return
        const y = element.getBoundingClientRect().top + scrollElement.scrollTop - stickyOffset
        smoothScrollTo(scrollElement, y, 800)
      } else {
        const layout = layoutStore.getState()
        const tabLinksHeight = layout.headerElements.propertyLayoutTabLinks?.height ?? 0
        const spacerHeight = layout.headerElements.propertyLayoutSpacer?.height ?? 0
        const nonStickyHeight = tabLinksHeight + spacerHeight + 1 // sticky subj has extra 1px for gap
        const layoutOffset = layout.totalHeaderHeight - nonStickyHeight
        const stickyOffset = isSubject ? 0 : store.getState().stickyHeight
        const scrollTop = window.scrollY || window.pageYOffset
        const elementTopFromViewport = element.getBoundingClientRect().top
        const y = (scrollTop + elementTopFromViewport) - (layoutOffset + stickyOffset)

        smoothScrollTo(window, y, 800)
      }
    }, 100)
  },
  scrollToMap: () => {
    setTimeout(() => {
      smoothScrollTo(window, 0, 800)
    }, 100)
  },
}

export const CompsTableScroller = api

export const useTrackStickyHeight = <T extends Element>() => {
  const [ref, { height }] = useMeasure<T>()

  useEffect(() => {
    api.setStickyHeight(height)

    return () => {
      api.setStickyHeight(0)
    }
  }, [height])

  return ref
}

const smoothScrollTo = (
  element: HTMLElement | Window,
  targetY: number,
  duration: number,
): void => {
  const startY = 'scrollTop' in element ? element.scrollTop : element.scrollY
  const distance = targetY - startY
  const startTime = performance.now()

  const scroll = (timestamp: number) => {
    const currentTime = timestamp - startTime
    const progress = Math.min(currentTime / duration, 1)
    const easing = easeInOutQuad(progress)
    const newY = startY + distance * easing

    if ('scrollTop' in element)
      element.scrollTo(0, newY)
    else
      element.scrollTo(0, newY)

    if (currentTime < duration)
      requestAnimationFrame(scroll)
  }

  const easeInOutQuad = (t: number): number => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2

  requestAnimationFrame(scroll)
}
