import { toast } from 'presentation/components/Toast'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'

export const restoreWithoutPayment = (restore: () => Promise<string>) => {
  void restore()
    .then(message => {
      toast({
        ...TOAST_PRESET.GENERIC_SUCCESS,
        message,
      })
      closeModal()
    })
    .catch(error => openErrorModal({
      message: error.message,
    }))
}
