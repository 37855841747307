import { Center, Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import { BillingIntervals } from '__generated__/graphql'
import { Card } from 'presentation/components/molecules/Card'
import { formatToFrequency } from 'presentation/screens/Billing/formatToFrequency'
import { sizeToPx } from 'presentation/utils/chakraSizeToNumber'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { px } from 'presentation/utils/px'
import { FC } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'
import HandDrawnCircleSvg from './img/hand-drawn-circle.svg'
import RadioOffSvg from './img/radio-off.svg'
import RadioOnBlueSvg from './img/radio-on-blue.svg'
import RadioOnGreenSvg from './img/radio-on-green.svg'

type UpgradeOptionProps = {
  intervalUnit: BillingIntervals
  price: number
  discountAmount: number
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: () => void
} & FlexProps

export const UpgradeOption: FC<UpgradeOptionProps> = ({
  intervalUnit,
  price,
  discountAmount,
  isSelected,
  isDisabled,
  onClick,
  ...props
}) => {
  const monthlyPrice = intervalUnit === BillingIntervals.Year
    ? price / 12
    : price

  const paymentIntervalDisplay = `Pay ${formatToFrequency(intervalUnit)}`
  const priceDisplay = `${formatPenniesOptionalDecUsd(monthlyPrice)}/month`
  const discountAmountDisplay = `$${discountAmount}`
  const BORDER_W = '0.25'
  const reduceBorder = (size: string) => px((sizeToPx(size) || 0) - (sizeToPx(BORDER_W) || 0))
  const RadioOnSvg = intervalUnit === BillingIntervals.Year ? RadioOnGreenSvg : RadioOnBlueSvg
  return (
    <Card
      p={mbp({ mobSm: reduceBorder('1'), tabSm: reduceBorder('2') })}
      pr={mbp({ mobSm: reduceBorder('4'), mob: reduceBorder('2'), tabSm: reduceBorder('4') })}
      pl={mbp({ mobSm: reduceBorder('2'), mob: reduceBorder('1'), tabSm: reduceBorder('2') })}
      gap={mbp({ mobSm: '1', tabSm: '2' })}
      borderWidth={BORDER_W}
      colorScheme='card.bg.1'
      boxShadow='primary.m'
      borderRadius={mbp({ mobSm: '2', tabSm: '3' })}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      onClick={onClick}
      flexDir='row'
      borderColor={isSelected ? 'special.500' : 'transparent'}
      cursor='pointer'
      align='center'
      {...props}
    >
      {isSelected
        ? (
          <Image
            boxSize='3'
            flexGrow='0'
            src={RadioOnSvg}
            filter='drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))'
          />
        )
        : <Image boxSize='3' flexGrow='0' src={RadioOffSvg} />}
      <Flex
        flexDirection='column'
        flexGrow='1'
      >
        <Text textStyle={mbp({ mobSm: 'body2-h-b', tabSm: 'h4-h-b' })} color='graystrong.500'>{paymentIntervalDisplay}</Text>
        <Text textStyle={mbp({ mobSm: 'body2', tabSm: 'body' })} color='graystrong.200'>{priceDisplay}</Text>
      </Flex>
      {!!discountAmount && (
        <Center flexDir='column'>
          <Text textStyle={mbp({ mobSm: 'body3-h-b', tabSm: 'body2-h-b' })} color='plans.advance'>SAVE</Text>
          <Text textStyle={mbp({ mobSm: 'body-h-b', tabSm: 'h3-h-b' })} color='plans.advance'>{discountAmountDisplay}</Text>

          <Image
            {...mbpg({
              mobSm: {
                w: '63px',
                h: '57px',
              },
              tabSm: {
                w: '83px',
                h: '67px',
              },
            })}
            src={HandDrawnCircleSvg}
            pos='absolute'
          />
        </Center>
      )}
    </Card>
  )
}
