import numeral from 'numeral'

export const formatNumber = (format: string) => (value: number): string =>
  numeral(value).format(format)

export const formatCommas = (x: number): string =>
  formatNumber('0,0')(x)

export const formatK1OptionalDecimal = (x: number): string =>
  formatNumber('0[.]0a')(x)

export const format2Decimals = (x: number): string =>
  formatNumber('0,0.00')(x)

export const formatOptionalDecimals = (x: number): string =>
  formatNumber('0,0[.]00')(x)

export const format1Decimal = (x: number): string =>
  formatNumber('0,0.0')(x)

export const fromPennies = (x: number): number =>
  x / 100

export const toPennies = (x: number): number =>
  Math.round(x * 100)
