import { Box, BoxProps, StackProps, Tag } from '@chakra-ui/react'

import { useListCriteria } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useCriteriaToChipsData } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useCriteriaToChipsData'
import { CloseIcon } from 'presentation/components/Icons'
import { TagRightIcon } from 'presentation/components/Tag/Tag'
import { ComponentType, ReactNode, forwardRef } from 'react'

export const PropertiesFilterChips = (props: BoxProps) => {
  const criteria = useListCriteria()
  const chipsData = useCriteriaToChipsData(criteria)

  return (
    <Box lineHeight={4.5} m={-0.5} {...props}>
      {chipsData.map((chipData, index) => (
        <PropertiesFilterChip
          key={index}
          mx={0.5}
          iconComponent={CloseIcon}
          {...chipData}
        />
      ))}
    </Box>
  )
}

const TYPES_TO_COLOR_SCHEME = {
  geographic: 'highlight',
  property: 'neutral',
  ownership: 'warm',
  stressIndicator: 'positive',
  equity: 'error',
  mls: 'positivesat',
}

export type PropertyFilterChipType = keyof typeof TYPES_TO_COLOR_SCHEME

export type PropertiesFilterChipProps = StackProps & {
  iconComponent?: ComponentType | null
  onIconClick?: () => void
  type: PropertyFilterChipType
  children: ReactNode
}

export const PropertiesFilterChip = forwardRef(({
  iconComponent: icon,
  onIconClick,
  type,
  children,
  ...props
}: PropertiesFilterChipProps, ref) => (
  <Tag ref={ref} colorScheme={TYPES_TO_COLOR_SCHEME[type]} {...props}>
    {children}
    {icon && <TagRightIcon as={icon} onClick={onIconClick} role='button' />}
  </Tag>
))

PropertiesFilterChip.displayName = 'PropertiesFilterChip'
