import { Box, ChakraProvider, Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { thumbsUpOnly } from 'features/CMA/CMA.helpers'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { ComposeComponents } from 'presentation/components/ComposeComponents/ComposeComponents'
import { MapStoreProvider } from 'presentation/components/Map'
import { getScale } from 'presentation/components/PropertyMarker/PropertyMarker.utils'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import lightTheme from 'presentation/main/themes/light/light.theme'
import { ComponentPrint } from 'presentation/screens/CompsScreen/ComponentPrint'
import { ReportMap } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/CMAReport'
import { CMAEstimateInfoCard } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAEstimateInfoCard'
import { CMANoPropertiesSelected } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMANoPropertiesSelected'
import { CMAReportSwitcher } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher'
import { ReportSwitcherProvider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { CMAReportTotalComps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportTotalComps'
import { CMASalesEstimate } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMASalesEstimate'
import { CompsReportTableGroup } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CompsReportTables'
import { CompsAddressSection } from 'presentation/screens/CompsScreen/components/CompsHeader'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { SubjectAPNPill } from 'presentation/screens/CompsScreen/components/SubjectAPNPill'
import { shallow } from 'zustand/shallow'

const CONTAINER_ID = 'cma-report-print-container'
export const CMAReportPrint = () => (
  /** We need to render it for the map to be rendered and ready upon print */
  <Box h='1px' w='1px' overflow='hidden'>
    <Box
      p={2}
      ref={element => {
        if (element) ComponentPrint.setComponent(element)
      }}
      id={CONTAINER_ID}
    >
      {/* Restrict to light theme */}
      <ChakraProvider
        theme={lightTheme}
        /**
         * Needed to make nested ChakraProvider with different theme work
         * @see https://github.com/chakra-ui/chakra-ui/discussions/5180
         */
        cssVarsRoot={`#${CONTAINER_ID}`}
      >
        <ComposeComponents
          providers={[
            ReportSwitcherProvider,
            MapStoreProvider,
            ComponentPrint.Provider,
          ]}
        >
          <CMAReportPrintBase />
        </ComposeComponents>
      </ChakraProvider>
    </Box>
  </Box>
)

const CMAReportPrintBase = () => {
  const report = useCMAStore(api => ({
    totalComps: checkStateHasReportData(api.local.report)
      ? api.local.report.data.salesListInfo.comps.filter(thumbsUpOnly).length
      + api.local.report.data.rentalsListInfo.comps.filter(thumbsUpOnly).length
      : 0,
    hasNoData: !checkStateHasReportData(api.local.report),
  }), shallow)

  return report.hasNoData
    ? (
      <Box p={3} h='full'><CMANoPropertiesSelected /></Box>
    )
    : (
      <Box>
        {/* First page */}
        <Box className='print__no-break'>
          <Flex gap={1}>
            <SubjectPropertyMarker markerType='pin' size='sm' />
            <CompsAddressSection />
            <Spacer />
            <CMAReportSwitcher />
          </Flex>

          <HStack spacing={2} mt={1}>
            <VStack spacing={2} flex='1' align='stretch'>
              <Box>
                <HStack align='center' justify='space-between'>
                  <Box>
                    <Text
                      textStyle='bodyLFat'
                      color='graystrong.500'
                    >
                      User&rsquo;s Estimated Value
                    </Text>
                    <CMAReportTotalComps />
                  </Box>
                  <SubjectAPNPill />
                </HStack>
                <HStack
                  mt={1}
                  spacing={2}
                >
                  <CMAEstimateInfoCard cardType='SALES' />
                  <CMAEstimateInfoCard cardType='RENTALS' />
                </HStack>
              </Box>
              <Box>
                <Text
                  textStyle='bodyLFat'
                  color='graystrong.500'
                >
                  Propelio’s Estimated Value
                </Text>

                <CMASalesEstimate
                  mt='1'
                  flex='1 1 0'
                  sx={{
                    /**
                   * Increase the size of marker when in fullscreen
                   */
                    '& svg.marker-svg-container': {
                      width: getScale('md', 'pin').width,
                      height: getScale('md', 'pin').height,
                    },
                  }}
                />
              </Box>
            </VStack>
            <Box flex='0 0 536px'>
              <Box
                bgColor='neutral.500'
                borderWidth={1}
                borderRadius={3}
                borderColor='neutral.500'
                position='relative'
                boxSize='536px'
                sx={{
                  width: '536px',
                  height: '536px',
                }}
              >
                <ReportMap
                  style={{
                    width: '520px',
                    height: '520px',
                  }}
                />
              </Box>
            </Box>
          </HStack>
        </Box>

        <CompsReportTableGroup mt={3} />

        <Box
          pt={3}
          textStyle='bodyL'
          color='graystrong.400'
          className='print__no-break'
        >
          <CompsMLSDisclaimer />
        </Box>
      </Box>
    )
}
