import { Box } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const WaveOne = () => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    w='full'
    preserveAspectRatio='none'
    bg='graycool.100'

    // @HACK We reduced height from 188px to 187px to fix the gap/line at the bottom of SVG
    viewBox='0 0 1376 187'
    maxH={mbp({ mobSm: '32px', mob: '48px', tabSm: '72px', dtSm: '100px', dtLg: '188px' })}
  >
    <chakra.path
      fill='highlight.500'
      d='m0 95.714 28.743 3c28.896 3 86.382 9 143.716 15.2 57.333 6.1 114.513 12.5 171.847 0 57.333-12.5 114.819-43.9 172.153-44.4 57.333-.5 114.513 29.9 171.847 16.7 57.333-13.2 114.819-69.8 172.153-83 57.333-13.2 114.513 17.2 171.851 37.8 57.33 20.7 114.82 31.7 172.15 29.7 57.33-2 114.51-17 142.95-24.5l28.59-7.5v149H0v-92Z'
    />
    <chakra.path
      fill='accent.xdarkpurple'
      d='m0 68.715 28.743-.7c28.896-.6 86.382-2 143.716 7.9 57.333 9.8 114.513 30.8 171.847 45.5 57.333 14.6 114.819 23 172.153 26.5 57.333 3.5 114.513 2.1 171.847-8.7 57.333-10.8 114.819-31.2 172.153-27.8 57.333 3.3 114.513 30.3 171.851 34.5 57.33 4.1 114.82-14.5 172.15-30.5 57.33-16 114.51-29.4 142.95-36l28.59-6.7v115H0v-119Z'
    />
  </chakra.svg>
)

export const WaveTwo = () => (
  <Box pos='relative'>
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1376 188'
      fill='none'
      maxH={mbp({ mobSm: '32px', mob: '48px', tabSm: '72px', dtSm: '100px', dtLg: '188px' })}
      w='full'
      preserveAspectRatio='none'
      bg='graycool.100'
      display='block'
    >
      <chakra.path
        fill='highlight.500'
        d='M1376 134.147L1347.26 127.347C1318.36 120.447 1260.87 106.847 1203.54 115.347C1146.21 123.847 1089.03 154.447 1031.69 152.947C974.361 151.447 916.875 117.847 859.541 101.447C802.208 85.1471 745.028 86.1471 687.694 110.347C630.361 134.447 572.875 181.847 515.541 187.847C458.208 193.847 401.028 158.447 343.694 150.847C286.361 143.147 228.875 163.147 171.541 155.947C114.208 148.847 57.0276 114.447 28.5902 97.3472L-9.47499e-06 80.1472L-1.64688e-05 0.1472L28.5902 0.147197C57.0276 0.147195 114.208 0.14719 171.541 0.147185C228.875 0.14718 286.361 0.147175 343.694 0.14717C401.028 0.147165 458.208 0.14716 515.541 0.147155C572.875 0.14715 630.361 0.147145 687.694 0.14714C745.028 0.147135 802.208 0.14713 859.541 0.147125C916.875 0.14712 974.361 0.147115 1031.69 0.14711C1089.03 0.147105 1146.21 0.1471 1203.54 0.147095C1260.87 0.14709 1318.36 0.147085 1347.26 0.147082L1376 0.147079L1376 134.147Z'
      />
      <chakra.path
        fill='accent.xdarkpurple'
        d='M1376 105.147L1347.26 106.847C1318.36 108.447 1260.87 111.847 1203.54 102.447C1146.21 93.1471 1089.03 71.1471 1031.69 64.3471C974.361 57.4471 916.875 65.8471 859.541 59.3471C802.208 52.8471 745.028 31.4471 687.694 28.4471C630.361 25.4471 572.875 40.8471 515.541 63.9472C458.208 87.1472 401.028 118.147 343.694 129.147C286.361 140.147 228.875 131.147 171.541 124.647C114.208 118.147 57.0276 114.147 28.5902 112.147L-2.11286e-06 110.147L-1.17294e-05 0.147215L28.5902 0.147213C57.0276 0.14721 114.208 0.147205 171.541 0.1472C228.875 0.147195 286.361 0.14719 343.694 0.147185C401.028 0.14718 458.208 0.147175 515.541 0.14717C572.875 0.147165 630.361 0.14716 687.694 0.147155C745.028 0.14715 802.208 0.147145 859.541 0.14714C916.875 0.147135 974.361 0.14713 1031.69 0.147125C1089.03 0.14712 1146.21 0.147115 1203.54 0.14711C1260.87 0.147105 1318.36 0.1471 1347.26 0.147097L1376 0.147095L1376 105.147Z'
      />
    </chakra.svg>

    {/* @HACK Covers the line/gap at the top of SVG */}
    <Box h='1px' pos='absolute' top='0' w='full' bg='accent.xdarkpurple' />
  </Box>
)

/** @TODO Should be responsive / custom for smaller viewports */
export const WaveThree = () => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    w='full'
    preserveAspectRatio='none'
    bg='graycool.100'

    // @HACK We reduced height from 49px to 48px to fix the gap/line at the bottom of SVG
    viewBox='0 0 320 48'
    maxH={mbp({ mobSm: '33px', mob: '48px', tabSm: '64px', dtSm: '74px', dtLg: '98px' })}
  >
    <chakra.path d='M0 9.39987L6.68444 11.9468C13.4044 14.4448 26.7733 19.5387 40.1067 21.9876C53.44 24.4366 66.7378 24.2407 80.0711 21.8897C93.4044 19.5386 106.773 14.9346 120.107 14.2978C133.44 13.6611 146.738 16.8937 160.071 16.0121C173.404 15.1305 186.773 10.0366 200.107 12.4856C213.44 14.9346 226.738 24.9264 240.071 28.1101C253.404 31.2938 266.773 27.6693 280.107 21.8897C293.44 16.1101 306.738 8.07743 313.351 4.11008L320 0.09375V48.0938H313.351C306.738 48.0938 293.44 48.0938 280.107 48.0938C266.773 48.0938 253.404 48.0938 240.071 48.0938C226.738 48.0938 213.44 48.0938 200.107 48.0938C186.773 48.0938 173.404 48.0938 160.071 48.0938C146.738 48.0938 133.44 48.0938 120.107 48.0938C106.773 48.0938 93.4044 48.0938 80.0711 48.0938C66.7378 48.0938 53.44 48.0938 40.1067 48.0938C26.7733 48.0938 13.4044 48.0938 6.68444 48.0938H0V9.39987Z' fill='plans.advance' />
    <chakra.path d='M0 19.2327L6.68444 21.8701C13.4044 24.4612 26.7733 29.736 40.1067 33.2063C53.44 36.6765 66.7378 38.3422 80.0711 35.1959C93.4044 32.0495 106.773 23.9985 120.107 20.5283C133.44 17.058 146.738 18.1685 160.071 21.2223C173.404 24.3224 186.773 29.4121 200.107 33.4839C213.44 37.6019 226.738 40.6557 240.071 37.8333C253.404 34.9645 266.773 26.1732 280.107 25.2478C293.44 24.3224 306.738 31.2629 313.351 34.7332L320 38.2034V48.3828H313.351C306.738 48.3828 293.44 48.3828 280.107 48.3828C266.773 48.3828 253.404 48.3828 240.071 48.3828C226.738 48.3828 213.44 48.3828 200.107 48.3828C186.773 48.3828 173.404 48.3828 160.071 48.3828C146.738 48.3828 133.44 48.3828 120.107 48.3828C106.773 48.3828 93.4044 48.3828 80.0711 48.3828C66.7378 48.3828 53.44 48.3828 40.1067 48.3828C26.7733 48.3828 13.4044 48.3828 6.68444 48.3828H0V19.2327Z' fill='accent.xdarkpurple' />
  </chakra.svg>
)

/** @TODO Should be responsive / custom for smaller viewports */
export const WaveFour = () => (
  <Box pos='relative'>
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      w='full'
      preserveAspectRatio='none'
      bg='graycool.100'
      viewBox='0 0 320 49'
      maxH={mbp({ mobSm: '33px', mob: '48px', tabSm: '64px', dtSm: '74px', dtLg: '98px' })}
    >
      <chakra.path d='M320 39.0767L313.316 36.5297C306.596 34.0318 293.227 28.9379 279.893 26.4889C266.56 24.04 253.262 24.2359 239.929 26.5869C226.596 28.9379 213.227 33.542 199.893 34.1787C186.56 34.8155 173.262 31.5828 159.929 32.4645C146.596 33.3461 133.227 38.44 119.893 35.991C106.56 33.542 93.2622 23.5502 79.9289 20.3665C66.5955 17.1828 53.2267 20.8073 39.8933 26.5869C26.56 32.3665 13.2622 40.3992 6.64889 44.3665L0 48.3828L-4.19629e-06 0.38284L6.64889 0.38284C13.2622 0.382839 26.56 0.382838 39.8933 0.382837C53.2267 0.382836 66.5955 0.382835 79.9289 0.382833C93.2622 0.382832 106.56 0.382831 119.893 0.38283C133.227 0.382829 146.596 0.382828 159.929 0.382826C173.262 0.382825 186.56 0.382824 199.893 0.382823C213.227 0.382822 226.596 0.382821 239.929 0.38282C253.262 0.382818 266.56 0.382817 279.893 0.382816C293.227 0.382815 306.596 0.382814 313.316 0.382813L320 0.382812L320 39.0767Z' fill='plans.advance' />
      <chakra.path d='M320 31.241L313.316 28.4491C306.596 25.7063 293.227 20.1226 279.893 16.4491C266.56 12.7756 253.262 11.0124 239.929 14.343C226.596 17.6736 213.227 26.1961 199.893 29.8695C186.56 33.543 173.262 32.3675 159.929 29.1348C146.596 25.8532 133.227 20.4655 119.893 16.1553C106.56 11.7961 93.2622 8.56341 79.9289 11.5512C66.5955 14.5879 53.2267 23.894 39.8933 24.8736C26.56 25.8532 13.2622 18.5063 6.64889 14.8328L-1.83424e-06 11.1593L-2.77627e-06 0.38384L6.64889 0.383839C13.2622 0.383839 26.56 0.383838 39.8933 0.383836C53.2267 0.383835 66.5955 0.383834 79.9289 0.383833C93.2622 0.383832 106.56 0.383831 119.893 0.383829C133.227 0.383828 146.596 0.383827 159.929 0.383826C173.262 0.383825 186.56 0.383824 199.893 0.383822C213.227 0.383821 226.596 0.38382 239.929 0.383819C253.262 0.383818 266.56 0.383817 279.893 0.383815C293.227 0.383814 306.596 0.383813 313.316 0.383813L320 0.383812L320 31.241Z' fill='accent.xdarkpurple' />
    </chakra.svg>

    {/* @HACK Covers the line/gap at the top of SVG */}
    <Box h='2px' pos='absolute' top='0' w='full' bg='accent.xdarkpurple' />
  </Box>
)

export const WaveFive = () => (
  <chakra.svg
    mt={mbp({
      // @Note: Undo the additional padding of the carousel
      mobSm: '-60px',
      // @Note: revert back because tablet and beyond doesn't use carousel
      tabSm: '0',
    })}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    w='full'
    preserveAspectRatio='none'
    bg='graycool.100'

    // @HACK We reduced height from 231px to 230px to fix the gap/line at the bottom of SVG
    viewBox='0 0 1440 231'
    maxH={mbp({ mobSm: '68px', tabSm: '101px', dtSm: '148px', dtLg: '231px' })}
  >
    <chakra.path d='M0 66.7267L30.08 75.029C60.32 83.4534 120.48 100.058 180.48 89.6801C240.48 79.3022 300.32 41.9418 360.32 43.7732C420.32 45.6046 480.48 86.6278 540.48 106.651C600.48 126.552 660.32 125.331 720.32 95.7847C780.32 66.3604 840.48 8.48836 900.48 1.16279C960.48 -6.16278 1020.32 37.0581 1080.32 46.3371C1140.32 55.7383 1200.48 31.3198 1260.48 40.1104C1320.48 48.779 1380.32 90.7789 1410.08 111.657L1440 132.657V230.331H1410.08C1380.32 230.331 1320.48 230.331 1260.48 230.331C1200.48 230.331 1140.32 230.331 1080.32 230.331C1020.32 230.331 960.48 230.331 900.48 230.331C840.48 230.331 780.32 230.331 720.32 230.331C660.32 230.331 600.48 230.331 540.48 230.331C480.48 230.331 420.32 230.331 360.32 230.331C300.32 230.331 240.48 230.331 180.48 230.331C120.48 230.331 60.32 230.331 30.08 230.331H0L0 66.7267Z' fill='highlight.500' />
    <chakra.path d='M-4.54242e-06 121.449L30.08 128.56C60.32 135.533 120.48 149.753 180.48 156.59C240.48 163.427 300.32 162.88 360.32 156.317C420.32 149.753 480.48 136.9 540.48 135.123C600.48 133.345 660.32 142.37 720.32 139.909C780.32 137.447 840.48 123.227 900.48 130.064C960.48 136.9 1020.32 164.794 1080.32 173.682C1140.32 182.57 1200.48 172.452 1260.48 156.317C1320.48 140.182 1380.32 117.758 1410.08 106.682L1440 95.47L1440 229.47L1410.08 229.47C1380.32 229.47 1320.48 229.47 1260.48 229.47C1200.48 229.47 1140.32 229.47 1080.32 229.47C1020.32 229.47 960.48 229.47 900.48 229.47C840.48 229.47 780.32 229.47 720.32 229.47C660.32 229.47 600.48 229.47 540.48 229.47C480.48 229.47 420.32 229.47 360.32 229.47C300.32 229.47 240.48 229.47 180.48 229.47C120.48 229.47 60.32 229.47 30.08 229.47L-2.34293e-05 229.47L-4.54242e-06 121.449Z' fill='plans.advance' />
    <chakra.path d='M0 126.601L30.08 124.904C60.32 123.306 120.48 119.91 180.48 129.298C240.48 138.586 300.32 160.559 360.32 167.35C420.32 174.242 480.48 165.852 540.48 172.344C600.48 178.836 660.32 200.209 720.32 203.205C780.32 206.201 840.48 190.821 900.48 167.75C960.48 144.579 1020.32 113.618 1080.32 102.632C1140.32 91.6454 1200.48 100.634 1260.48 107.126C1320.48 113.618 1380.32 117.613 1410.08 119.61L1440 121.608V231.47H1410.08C1380.32 231.47 1320.48 231.47 1260.48 231.47C1200.48 231.47 1140.32 231.47 1080.32 231.47C1020.32 231.47 960.48 231.47 900.48 231.47C840.48 231.47 780.32 231.47 720.32 231.47C660.32 231.47 600.48 231.47 540.48 231.47C480.48 231.47 420.32 231.47 360.32 231.47C300.32 231.47 240.48 231.47 180.48 231.47C120.48 231.47 60.32 231.47 30.08 231.47H0L0 126.601Z' fill='accent.xdarkpurple' />
  </chakra.svg>
)

export const WaveSix = () => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    w='full'
    preserveAspectRatio='none'
    bg='accent.xdarkpurple'
    // @HACK We reduced height from 189px to 188px to fix the gap/line at the bottom of SVG
    viewBox='0 0 1376 188'
    maxH={mbp({ mobSm: '48px', tabSm: '80px', dtSm: '100px', dtLg: '188px' })}
  >
    <chakra.path d='M0 54.9096L28.7431 61.7096C57.6391 68.6096 115.125 82.2096 172.459 73.7096C229.792 65.2096 286.972 34.6096 344.306 36.1096C401.639 37.6096 459.125 71.2096 516.459 87.6096C573.792 103.91 630.972 102.91 688.306 78.7096C745.639 54.6096 803.125 7.20956 860.459 1.20956C917.792 -4.79044 974.972 30.6096 1032.31 38.2096C1089.64 45.9096 1147.13 25.9096 1204.46 33.1096C1261.79 40.2096 1318.97 74.6096 1347.41 91.7096L1376 108.91V188.91H1347.41C1318.97 188.91 1261.79 188.91 1204.46 188.91C1147.13 188.91 1089.64 188.91 1032.31 188.91C974.972 188.91 917.792 188.91 860.459 188.91C803.125 188.91 745.639 188.91 688.306 188.91C630.972 188.91 573.792 188.91 516.459 188.91C459.125 188.91 401.639 188.91 344.306 188.91C286.972 188.91 229.792 188.91 172.459 188.91C115.125 188.91 57.6391 188.91 28.7431 188.91H0L0 54.9096Z' fill='plans.advance' />
    <chakra.path d='M0 83.9115L28.7431 82.2115C57.6391 80.6115 115.125 77.2115 172.459 86.6115C229.792 95.9115 286.972 117.911 344.306 124.711C401.639 131.611 459.125 123.211 516.459 129.711C573.792 136.211 630.972 157.612 688.306 160.612C745.639 163.612 803.125 148.212 860.459 125.112C917.792 101.912 974.972 70.9115 1032.31 59.9115C1089.64 48.9115 1147.13 57.9115 1204.46 64.4115C1261.79 70.9115 1318.97 74.9115 1347.41 76.9115L1376 78.9115V188.911H1347.41C1318.97 188.911 1261.79 188.911 1204.46 188.911C1147.13 188.911 1089.64 188.911 1032.31 188.911C974.972 188.911 917.792 188.911 860.459 188.911C803.125 188.911 745.639 188.911 688.306 188.911C630.972 188.911 573.792 188.911 516.459 188.911C459.125 188.911 401.639 188.911 344.306 188.911C286.972 188.911 229.792 188.911 172.459 188.911C115.125 188.911 57.6391 188.911 28.7431 188.911H0L0 83.9115Z' fill='highlight.500' />
  </chakra.svg>
)
