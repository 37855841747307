import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const OwnerOccVacantIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.polygon
      fill='marker.teal'
      fillRule='nonzero'
      points='11.4602 7 14.4431 17.2557 14.5568 17.2557 17.5397 7 22 7 17.1988 21.5455 11.8011 21.5455 7 7'
    />
  </MarkerIconContainer>
)
