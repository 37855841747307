import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals/modals'

export const openUpdatePaymentModal = (): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'UPDATE_PAYMENT_MODAL',
      props: {
        onClose: goBackModal,
      },
    },
  })
}
