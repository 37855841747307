import { ArrayFormatter, Schema } from '@effect/schema'
import { Cause, Effect, Either, Match, pipe, Runtime } from 'effect'
import { IS_DEV_ENV } from 'presentation/const/env.const'
import { fromPromise } from 'xstate'

/** effect-ts helpers */
export namespace EffectLib {
  export const makeLoggedDecoder = <A, I>({
    schema,
    name,
  }: {
    schema: Schema.Schema<A, I, never>
    name: string
  }) => (data: any) =>
    pipe(
      data,
      Schema.decodeUnknownEither(schema),
      Either.getOrThrowWith(error => {
        if (IS_DEV_ENV) {
          pipe(
            error,
            ArrayFormatter.formatError,
            // eslint-disable-next-line no-console
            Effect.tap(array => console.log(`${name} ParseError:`, array)),
            Effect.runSync,
          )
        }

        throw error as Error
      }),
    )

  export const causeToError = (cause: Cause.Cause<any>): Error => {
    const fallbackError = new Error('Unexpected Error')
    const error = pipe(
      Match.value(cause),
      Match.tag('Die', c => c.defect || fallbackError),
      Match.tag('Empty', () => fallbackError),
      Match.tag('Fail', c => c.error || fallbackError),
      Match.tag('Interrupt', () => fallbackError),
      Match.tag('Parallel', () => fallbackError),
      Match.tag('Sequential', () => fallbackError),
      Match.exhaustive,
    ) as Error

    return error
  }

  export const getRunPromiseFromRuntime = <TRequirements = never>() =>
    Effect.runtime<TRequirements>().pipe(
      Effect.andThen(Runtime.runPromise),
    )

  export const getRunSyncFromRuntime = <TRequirements = never>() =>
    Effect.runtime<TRequirements>().pipe(
      Effect.andThen(Runtime.runSync),
    )

  /**
     * @NOTE: Converts a callback --that returns an effect-- to
     * a promise actor that can be invoked by an xstate machine
     *
     * example:
     *  const SampleMachine = () => Effect.gen(function *() {
     *    const deps = yield * SampeEffectContext
     *    const findById = yield * pipe(
     *      deps.findListById,
     *      EffectLib.toPromiseActor,
     *    )
     *    const updateListName = yield * pipe(
     *      deps.updateListName,
     *      EffectLib.toPromiseActor,
     *    )
     *
     *    return setup({
     *      actors: {
     *        findById,
     *        updateListName,
     *      },
     *    }).createMachine()
     *  })
     */
  export const toPromiseActor = <In, Out, Err, Req>(
    cb: (params: In) => Effect.Effect<Out, Err, Req>,
  ) => Effect.gen(function *() {
    const runFork = yield * pipe(
      Effect.runtime<Req>(),
      Effect.andThen(Runtime.runPromise),
    )

    return fromPromise(async ({ input }: { input: In }) =>
      cb(input).pipe(runFork),
    )
  })
}
