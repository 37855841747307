import { flow } from 'lodash/fp'
import { mapToArrayBreakpointDeep } from 'presentation/utils/mapToArrayBreakpointDeep'
import { groupLeavesByTopLevelKeys } from 'utils/groupLeavesByTopLevelKeys'

export const mapBreakpointByGroup = flow(
  groupLeavesByTopLevelKeys,
  mapToArrayBreakpointDeep,
)

export const mbpg = mapBreakpointByGroup
