import { Data } from 'effect'
import { PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import MarketingListId from 'features/ListBuilder/domain/MarketingList/MarketingListId'
import { MarketingListMemberBase } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberBase'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'

export type PropertyListMember = {
  readonly _tag: 'PropertyListMember'
} & MarketingListMemberBase<Omit<PropertyListData, 'id'> & { propertyId: PropertyListDataId }>

type PropertyListMemberParams = {
  id: MarketingListMemberId
  listId: MarketingListId
  propertyData: PropertyListData
}

const make = Data.tagged<PropertyListMember>('PropertyListMember')

export const PropertyListMember = {
  make: (params: PropertyListMemberParams) => {
    const { id, ...propertyData } = params.propertyData
    const member = make({
      id: params.id,
      listId: params.listId,
      propertyId: id,
      ...propertyData,
    })

    return member
  },
}
