import { BillingIntervals, GetActiveProductQuery, ProductFeaturesFragment, SubscriptionFeatureIntervalValue } from '__generated__/graphql'
import { Array, Effect, Either, Layer, Match, Option, pipe, String } from 'effect'
import { UnknownException } from 'effect/Cause'
import { apolloClient } from 'presentation/libs/client'
import { GqlPath } from 'presentation/libs/graphql/graphql'
import ApplicationError from 'presentation/screens/Plans/PlanComparisonV2/domain/ApplicationError'
import { FeatureLimitedState, FeatureQuotaState, FeatureUnlimitedState, FeatureUsagePricingState } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureState'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'
import ProductName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductName'
import { MonthlyPlan, PlanId, YearlyPlan } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'
import GQLProductFeaturesFragment from '../GQLProductFeaturesFragment'
import SubscriptionProductRepo from '../SubscriptionProductRepo'
import GET_ACTIVE_PRODUCT from './getActiveProductsLive.schema'

type GQLSubscriptionProductActive = GqlPath<
  GetActiveProductQuery,
  [
    ['myEnterprise', 'Enterprise'],
    ['subscription', 'BillingSubscriptionActive'],
  ]
>

const getActiveProductLive = Layer.succeed(
  SubscriptionProductRepo.GetActive,
  () => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.query({
          query: GET_ACTIVE_PRODUCT,
          variables: {},
        }),
      catch: error => new UnknownException({ message: error }),
    }),
    Effect.andThen(result => toDomain(result.data)),
    Effect.map(v => Option.some(v)),
    Effect.catchTag('NoSuchElementException', () => Effect.succeed(
      Option.none<SubscriptionProductActive>(),
    )),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default getActiveProductLive

const toDomain = (
  data: GetActiveProductQuery,
) =>
  Effect.gen(function *() {
    const subscription = yield * pipe(
      Option.fromNullable(data.myEnterprise?.subscription),
      Option.flatMap(subs => subs.__typename === 'BillingSubscriptionActive'
        ? Option.some(subs)
        : Option.none()),
    )

    const productName = yield * pipe(
      Option.fromNullable(subscription.plan),
      Option.flatMap(sub =>
        ProductName.fromString(sub.product.name),
      ),
    )

    const productId = ProductId.fromString(
      subscription.plan.product.id,
    )

    const plan = yield * pipe(
      Option.fromNullable(subscription.plan.interval.unit),
      Option.flatMap(unit => pipe(
        Match.value(unit),
        Match.when(BillingIntervals.Year, () => pipe(
          YearlyPlan.make({
            id: PlanId.fromString(subscription.plan.id),
            productId,
            price: subscription.plan.price,
            isCurrentPlan: true,
          }),
          Option.some,
        )),
        Match.when(BillingIntervals.Month, () => pipe(
          MonthlyPlan.make({
            id: PlanId.fromString(subscription.plan.id),
            productId,
            price: subscription.plan.price,
            isCurrentPlan: true,
          }),
          Option.some,
        )),
        Match.orElse(() => Option.none()),
      )),
      Option.match({
        onSome: plan => Effect.succeed(plan),
        onNone: () => Effect.die(new ApplicationError({
          message: 'Active subscription does not have specific plan selected',
        })),
      }),
    )

    const features = yield * findFeaturesState({
      gqlActiveProduct: subscription,
      features: subscription.plan.product.features,
    })

    return SubscriptionProductActive.make({
      id: productId,
      name: productName,
      plans: plan,
      description: pipe(
        Option.fromNullable(subscription.plan.product.description),
        Option.getOrElse(() => String.empty),
      ),
      features,
      currentPeriod: {
        end: Option.fromNullable(subscription.currentPeriod?.end),
      },
    })
  })


  type FindFeaturesParams = {
    gqlActiveProduct: GQLSubscriptionProductActive
    features: ProductFeaturesFragment[]
  }

const findFeaturesState = ({
  gqlActiveProduct,
  features,
}: FindFeaturesParams) => {
  const productId = ProductId.fromString(gqlActiveProduct.plan.product.id)

  const leadFeatureState = pipe(
    findLeadFeatureState(productId, features),
    Effect.option,
  )
  const skiptraceFeatureState = pipe(
    findSkiptraceFeatureState(productId, features),
    Effect.option,
  )
  const compsFeatureState = pipe(
    findCompsFeatureState(productId, features),
    Effect.option,
  )

  const directMailFeatureState = pipe(
    findDirectMailFeatureState(gqlActiveProduct, features),
    Effect.option,
  )

  const teamFeatureState = pipe(
    findTeamFeatureState(productId, features),
    Effect.option,
  )

  const foundFeaturesState = pipe(
    Array.make(
      leadFeatureState,
      skiptraceFeatureState,
      compsFeatureState,
      directMailFeatureState,
      teamFeatureState,
    ),
    Effect.all,
    Effect.andThen(v => Array.getSomes(v)),
  )

  return foundFeaturesState
}

const findLeadFeatureState = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * pipe(
    features,
    Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureLeads),
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedState> = pipe(
    feature.limits,
    /**
     * @NOTE: Get any limit as there are no requirements to which
     * specific interval to retrieve.
     */
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedState.make({
          productId,
          featureName: 'LeadListExports',
        }),
      ),
    }),
  )

  return pipe(
    limit,
    Either.match({
      onLeft: unlimited => unlimited,
      onRight: l => pipe(
        FeatureLimitedState.make({
          productId,
          featureName: 'LeadListExports',
          interval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
          limit: l.value,
          used: pipe(
            feature.usages,
            Array.findFirst(usage => usage.interval === l.interval),
            Option.flatMap(usage => Option.fromNullable(usage.value)),
            Option.getOrElse(() => 0),
          ),
        }),
      ),
    }),
  )
})

const findSkiptraceFeatureState = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * Array.findFirst(
    features,
    GQLProductFeaturesFragment.isSubscriptionFeatureSkiptrace,
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedState> = pipe(
    feature.limits,
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedState.make({
          productId,
          featureName: 'Skiptrace',
        }),
      ),
    }),
  )

  const pricing = Array.get(feature.prices, 0)

  const price = pipe(
    pricing,
    Option.map(p => p.price),
    Option.getOrElse(() => 0),
  )


  return yield * pipe(
    limit,
    Either.match({
      onLeft: v => Effect.succeed(v),
      onRight: l => {
        const usages = pipe(
          feature.usages,
          Array.findFirst(usage => usage.interval === l.interval),
        )

        const used = pipe(
          usages,
          Option.flatMap(usage => Option.fromNullable(usage.value)),
          Option.getOrElse(() => 0),
        )

        const nextRolloverDate = pipe(
          usages,
          Option.flatMap(usage => Option.fromNullable(usage.nextRolloverDate)),
          Option.map(date => new Date(date)),
        )

        return pipe(
          nextRolloverDate,
          Option.map(date => FeatureQuotaState.make({
            productId,
            featureName: 'Skiptrace',
            quotaLimit: l.value,
            replenishInterval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
            overageCost: price,
            used,
            nextRolloverDate: date,
          })),
          Option.map(feat => Effect.succeed(feat)),
          Option.getOrElse(() => Effect.die(new ApplicationError({
            message: 'Skiptrace feature of the active product does not have a next roll over date',
          }))),
        )
      },
    }),
  )
})

const findCompsFeatureState = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const feature = yield * Array.findFirst(
    features,
    GQLProductFeaturesFragment.isSubscriptionFeatureComps,
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedState> = pipe(
    feature.limits,
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedState.make({
          productId,
          featureName: 'Comps',
        }),
      ),
    }),
  )

  const pricing = Array.get(feature.prices, 0)

  const price = pipe(
    pricing,
    Option.map(p => p.price),
    Option.getOrElse(() => 0),
  )


  return yield * pipe(
    limit,
    Either.match({
      onLeft: v => Effect.succeed(v),
      onRight: l => {
        const usages = pipe(
          feature.usages,
          Array.findFirst(usage => usage.interval === l.interval),
        )

        const used = pipe(
          usages,
          Option.flatMap(usage => Option.fromNullable(usage.value)),
          Option.getOrElse(() => 0),
        )

        const nextRolloverDate = pipe(
          usages,
          Option.flatMap(usage => Option.fromNullable(usage.nextRolloverDate)),
          Option.map(date => new Date(date)),
        )

        return pipe(
          nextRolloverDate,
          Option.map(date => FeatureQuotaState.make({
            productId,
            featureName: 'Comps',
            quotaLimit: l.value,
            replenishInterval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
            overageCost: price,
            used,
            nextRolloverDate: date,
          })),
          Option.map(feat => Effect.succeed(feat)),
          Option.getOrElse(() => Effect.die(new ApplicationError({
            message: 'Comps feature of the active product does not have a next roll over date',
          }))),
        )
      },
    }),
  )
})

const findDirectMailFeatureState = (
  gqlActiveProduct: GQLSubscriptionProductActive,
  features: ProductFeaturesFragment[],
) => Effect.gen(function *() {
  const productId = ProductId.fromString(gqlActiveProduct.plan.product.id)

  const feature = yield * pipe(
    features,
    Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureDirectMail),
  )

  const limit: Either.Either<SubscriptionFeatureIntervalValue, FeatureUnlimitedState> = pipe(
    feature.limits,
    /**
     * @NOTE: Get any limit as there are no requirements to which
     * specific interval to retrieve.
     */
    Array.get(0),
    Option.match({
      onSome: v => Either.right(v),
      onNone: () => Either.left(
        FeatureUnlimitedState.make({
          productId,
          featureName: 'DirectMailFeature',
        }),
      ),
    }),
  )

  const currentPeriodEnd = yield * pipe(
    Option.fromNullable(gqlActiveProduct.currentPeriod?.end),
    Option.map(date => new Date(date)),
    Option.map(date => Effect.succeed(date)),
    Option.getOrElse(() => Effect.die(new ApplicationError({
      message: 'Direct mail feature has no current period end date',
    }))),
  )

  return yield * pipe(
    limit,
    Either.match({
      onLeft: unlimited => Effect.succeed(unlimited),
      onRight: l => {
        const usages = pipe(
          feature.usages,
          Array.findFirst(usage => usage.interval === l.interval),
        )

        const currentPeriodUsage = pipe(
          usages,
          Option.flatMap(usage => Option.fromNullable(usage.value)),
          Option.getOrElse(() => 0),
        )

        return pipe(
          feature.prices,
          /**
             * @NOTE: Get any price as there are no requirements to which
             * specific price to retrieve.
             */
          Array.get(0),
          Option.map(p => FeatureUsagePricingState.make({
            productId,
            featureName: 'DirectMailFeature',
            price: p.price,
            unit: p.pricingUnit,
            interval: GQLProductFeaturesFragment.toProductFeatureInterval(l.interval),
            unitDescription: pipe(
              Option.fromNullable(p.pricingUnitDescription),
              Option.getOrElse(() => String.empty),
            ),
            currentPeriodUsage,
            currentPeriodEnd,
          })),
          Option.map(feat => Effect.succeed(feat)),
          Option.getOrElse(() => Effect.die(new ApplicationError({
            message: 'Direct mail feature has no price data',
          })),
          ),
        )
      },
    }),
  )
})

const findTeamFeatureState = (
  productId: ProductId,
  features: ProductFeaturesFragment[],
) => pipe(
  features,
  Array.findFirst(GQLProductFeaturesFragment.isSubscriptionFeatureTeam),
  Option.map(feature => pipe(
    feature.limits,
    Array.get(0),
    Option.map(f => FeatureLimitedState.make({
      productId,
      featureName: 'TeamMembers',
      limit: f.value,
      interval: GQLProductFeaturesFragment.toProductFeatureInterval(f.interval),
      /**
       * @NOTE: Not available in the subscription schema but can be
       * retrieved from some other source.
       * @TODO: Figure out how to get this data. For now it is not required
       */
      used: 0,
    })),
    Option.getOrElse(() => FeatureUnlimitedState.make({
      productId,
      featureName: 'TeamMembers',
    })),
  )),
)
