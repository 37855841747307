import { Array, Effect, identity, Match, Option, pipe, Runtime } from 'effect'
import { Dollar } from 'libs/dollar'
import { formatK1OptionalDecimal } from 'libs/Number/formatNumber'
import AppRuntime from 'main/AppRuntime'
import { Color } from 'presentation/libs/chakra/chakra.types'
import ProductFeatureState from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureState'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'
import SubscriptionProductRepo from 'presentation/screens/Plans/PlanComparisonV2/domain/SubscriptionProductRepo'
import { useEffect, useState } from 'react'

type CurrentFeaturesSectionViewModel = {
  featureNameText: string
  featureValueText: string
  featureValueTextColor: Color
  disclosure: Option.Option<string>
}[]

const makePresenter = (activeProduct: SubscriptionProductActive): CurrentFeaturesSectionViewModel => {
  const vm: CurrentFeaturesSectionViewModel = [
    pipe(
      activeProduct.features,
      Array.findFirst((feature: ProductFeatureState) => feature.featureName === 'TeamMembers'),
      Option.map(feat => {
        const limit = pipe(
          Match.value(feat),
          Match.tag('FeatureUnlimitedState', () => 'Unlimited'),
          Match.tag('FeatureLimitedState', f => formatK1OptionalDecimal(f.limit)),
          Match.orElse(() => 'Zero'),
        )
        return {
          featureNameText: 'Team Members',
          featureValueText: limit,
          featureValueTextColor: 'accent.blue-to-gray',
          disclosure: Option.none(),
        } satisfies CurrentFeaturesSectionViewModel[number]
      }),
      Option.getOrElse(() => ({
        featureNameText: 'Team Members',
        featureValueText: 'Zero',
        featureValueTextColor: 'negative.500',
        disclosure: Option.none(),
      } satisfies CurrentFeaturesSectionViewModel[number])),
    ),
    // Lead List Exports
    pipe(
      activeProduct.features,
      Array.findFirst((feature: ProductFeatureState) => feature.featureName === 'LeadListExports'),
      Option.map(feat => {
        const limit = pipe(
          Match.value(feat),
          Match.tag('FeatureUnlimitedState', () => 'Unlimited'),
          Match.tag('FeatureLimitedState', f => `${formatK1OptionalDecimal(f.used)} / ${formatK1OptionalDecimal(f.limit)}`),
          Match.orElse(() => 'Zero'),
        )
        return {
          featureNameText: 'Lead List Exports',
          featureValueText: limit,
          featureValueTextColor: 'accent.blue-to-gray',
          disclosure: Option.none(),
        } satisfies CurrentFeaturesSectionViewModel[number]
      }),
      Option.getOrElse(() => ({
        featureNameText: 'Lead List Exports',
        featureValueText: 'Zero',
        featureValueTextColor: 'negative.500',
        disclosure: Option.none(),
      } satisfies CurrentFeaturesSectionViewModel[number])),
    ),
    // Comps
    pipe(
      activeProduct.features,
      Array.findFirst((feature: ProductFeatureState) => feature.featureName === 'Comps'),
      Option.flatMap(feat => pipe(
        Match.value(feat),
        Match.tag('FeatureQuotaState', f => {
          const min = formatK1OptionalDecimal(f.used)
          const max = formatK1OptionalDecimal(f.quotaLimit)
          const priceEach = `${Dollar.formatPennies(f.overageCost)}/ea`
          const value = `${min} / ${max} free | ${priceEach}`
          return Option.some({
            featureNameText: 'Comps',
            featureValueText: value,
            featureValueTextColor: 'accent.blue-to-gray',
            disclosure: Option.some(String(f.quotaLimit)),
          } satisfies CurrentFeaturesSectionViewModel[number])
        }),
        Match.tag('FeatureUnlimitedState', () => Option.some({
          featureNameText: 'Comps',
          featureValueText: 'Unlimited',
          featureValueTextColor: 'accent.blue-to-gray',
          disclosure: Option.none(),
        } satisfies CurrentFeaturesSectionViewModel[number])),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => ({
        featureNameText: 'Comps',
        featureValueText: 'Zero',
        featureValueTextColor: 'negative.500',
        disclosure: Option.none(),
      } satisfies CurrentFeaturesSectionViewModel[number])),
    ),
    // Skiptrace
    pipe(
      activeProduct.features,
      Array.findFirst((feature: ProductFeatureState) => feature.featureName === 'Skiptrace'),
      Option.flatMap(feat => pipe(
        Match.value(feat),
        Match.tag('FeatureQuotaState', f => {
          const min = formatK1OptionalDecimal(f.used)
          const max = formatK1OptionalDecimal(f.quotaLimit)
          const priceEach = `${Dollar.formatPennies(f.overageCost)}/ea`
          const value = `${min} / ${max} free | ${priceEach}`
          return Option.some({
            featureNameText: 'Skiptrace',
            featureValueText: value,
            featureValueTextColor: 'accent.blue-to-gray',
            disclosure: Option.some(String(f.quotaLimit)),
          } satisfies CurrentFeaturesSectionViewModel[number])
        }),
        Match.tag('FeatureUnlimitedState', () => Option.some({
          featureNameText: 'Skiptrace',
          featureValueText: 'Unlimited',
          featureValueTextColor: 'accent.blue-to-gray',
          disclosure: Option.none(),
        } satisfies CurrentFeaturesSectionViewModel[number])),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => ({
        featureNameText: 'Skiptrace',
        featureValueText: 'Zero',
        featureValueTextColor: 'negative.500',
        disclosure: Option.none(),
      } satisfies CurrentFeaturesSectionViewModel[number])),
    ),
  ]
  return vm
}

const usePresenter = () => {
  const runtime = AppRuntime.useRuntime()

  const program = Effect.gen(function *() {
    const activeProduct = yield * pipe(
      SubscriptionProductRepo.GetActive,
      Effect.andThen(getActive => getActive()),
      Effect.andThen(identity),
    )

    return CurrentFeaturesSectionViewModel.makePresenter(activeProduct)
  })

  const [vm, setVm] = useState<Option.Option<CurrentFeaturesSectionViewModel>>(Option.none())

  useEffect(() => {
    void program.pipe(
      Runtime.runPromise(runtime),
    ).then(model => setVm(Option.some(model)))
  }, [])

  return vm
}

const CurrentFeaturesSectionViewModel = {
  makePresenter,
  usePresenter,
}

export default CurrentFeaturesSectionViewModel
