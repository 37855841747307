import { chakra, createIcon } from '@chakra-ui/react'

export const SubjectPropertyIcon = createIcon({
  displayName: 'SubjectPropertyIcon',
  viewBox: '0 0 16 16',
  path: (
    <chakra.g fill='none' xmlns='http://www.w3.org/2000/svg'>
      <chakra.path d='M8.50629 0.90375L10.1888 4.74125C10.2521 4.88567 10.353 5.01046 10.4809 5.10259C10.6089 5.19471 10.7593 5.2508 10.9163 5.265L15.015 5.6325C15.4788 5.7 15.6638 6.26875 15.3275 6.59625L12.24 9.19C11.99 9.4 11.8763 9.73 11.945 10.0487L12.8425 14.25C12.9213 14.7113 12.4375 15.0638 12.0225 14.845L8.44504 12.75C8.31011 12.6708 8.1565 12.6291 8.00004 12.6291C7.84359 12.6291 7.68997 12.6708 7.55504 12.75L3.97754 14.8438C3.56379 15.0612 3.07879 14.71 3.15754 14.2487L4.05504 10.0475C4.12254 9.72875 4.01004 9.39875 3.76004 9.18875L0.671292 6.5975C0.336292 6.27125 0.521292 5.70125 0.983792 5.63375L5.08254 5.26625C5.23958 5.25205 5.38993 5.19596 5.5179 5.10384C5.64586 5.01171 5.74676 4.88692 5.81004 4.7425L7.49254 0.905C7.70129 0.485 8.29879 0.485 8.50629 0.90375Z' fill='trueorange.500' />
      <chakra.path d='M8.3838 4.97131L8.0988 2.14381C8.08755 1.98631 8.05505 1.71631 8.30755 1.71631C8.50755 1.71631 8.6163 2.13256 8.6163 2.13256L9.4713 4.40256C9.7938 5.26631 9.6613 5.56256 9.35005 5.73756C8.99255 5.93756 8.46505 5.78131 8.3838 4.97131Z' fill='highlight.500' />
      <chakra.path d='M11.91 8.93859L14.3625 7.02484C14.4838 6.92359 14.7025 6.76234 14.5275 6.57859C14.3888 6.43359 14.0138 6.64234 14.0138 6.64234L11.8675 7.48109C11.2275 7.70234 10.8025 8.02984 10.765 8.44234C10.7163 8.99234 11.21 9.41609 11.91 8.93859Z' fill='trueorange.100' />
    </chakra.g>

  ),
})
