import { Effect, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { MarketingListAddParcel } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/AddParcelResolver/schema'
import toDomain from 'features/ListBuilder/infra/repo/MarketingListRepoLive/AddParcelResolver/toDomain'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { apolloClient } from 'presentation/libs/client'

const AddParcelResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.AddParcel) => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.mutate({
          mutation: MarketingListAddParcel as any, // TODO: fix type issues
          variables: {
            parcelId: request.parcelId,
            listId: request.listId,
          },
        }),
      catch: error => new UnknownException({ message: String(error) }),
    }),
    Effect.andThen(result => toDomain({
      listId: request.listId,
      parcelId: request.parcelId,
      data: result.data.marketingListAddParcel,
    })),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default AddParcelResolver
