import { Box, BoxProps, Image, Progress, Text } from '@chakra-ui/react'
import { animate, useAnimation, useMotionValue, useTransform } from 'framer-motion'
import { MotionBox } from 'presentation/components/animation/MotionBox'
import { useEffect, useRef } from 'react'
import rocketPng from './img/rocket.png'

export const ListProgress = ({
  progress,
  title,
  ...props
}: BoxProps & {
  /** 0 = 0%, 100 = 100% */
  progress: number
  title: string
}) => {
  const TEMP_FIX = true

  if (TEMP_FIX) {
    return (
      <Box {...props}>
        <Text textStyle='bodyLFat' color='graystrong.400'>
          {title}
        </Text>
        <Progress
          mt={2}
          isIndeterminate
          hasStripe
          colorScheme='yellow'
          size='sm'
          borderRadius='full'
        />
      </Box>
    )
  }

  const ref = useRef<HTMLSpanElement>(null)
  const progressMotionValue = useMotionValue(0)
  const rocketMotionControl = useAnimation()

  useEffect(() => {
    const progressTransition = {
      type: 'tween' as const,
      duration: 2,
      ease: [0.44, 0.16, 0.37, 1.04] as [number, number, number, number],
    }
    void animate(progressMotionValue, progress, {
      ...progressTransition,
    })

    void rocketMotionControl.start('start')
      .then(async () => await rocketMotionControl.start('middle'))
      .then(async () => await rocketMotionControl.start('end'))
    void rocketMotionControl.start({
      left: `${progress}%`,
      transition: {
        ...progressTransition,
      },
    })

    return () => {
      progressMotionValue.stop()
      rocketMotionControl.stop()
    }
  }, [progress])

  useEffect(
    () => {
      if (ref.current)
        ref.current.textContent = progressMotionValue.get().toFixed(0)

      progressMotionValue.on('change', latest => {
        if (ref.current)
          ref.current.textContent = latest.toFixed(0)
      })
    },
    [progressMotionValue],
  )

  const motionPct = useTransform(progressMotionValue, [0, 100], ['0%', '100%'])

  return (
    <Box {...props}>
      <Text textStyle='bodyLFat' color='graystrong.400'>
        {title}
        {' '}
        <span ref={ref} />
        %
      </Text>

      <Box
        h={1.5}
        pos='relative'
        borderRadius='full'
        bg='grayweak.300'
        mt={2}
      >
        <MotionBox
          h={1.5}
          pos='absolute'
          left='0'
          borderRadius='full'
          bg='highlight.500'
          style={{ width: motionPct }}
        />

        <MotionBox
          pos='absolute'
          top='50%'
          boxSize='6.5'
          style={{
            left: 0,
            originX: 0,
            originY: 0,
          }}
          variants={{
            start: { rotate: 0, transition: { duration: 0 } },
            middle: {
              rotate: 45,
              scale: 1.25,
              transition: { type: 'spring', damping: 20, mass: 2 },
            },
            end: {
              rotate: 0,
              scale: 1,
              transition: { type: 'spring', damping: 15, stiffness: 50, mass: 1.5 },
            },
          }}
          animate={rocketMotionControl}
          transformOrigin='center'
        >
          <Image
            as='img'
            src={rocketPng}
            role='presentation'
            boxSize='6.5'
            transform='translate(-50%, -50%)'
          />
        </MotionBox>
      </Box>
    </Box>
  )
}
