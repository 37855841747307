import { Context, Effect, Option } from 'effect'
import { PlanId } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'

namespace PlanCheckoutService {
  export type AddToCartParams = {
    targetProduct: SubscriptionProduct
    activeProduct: Option.Option<SubscriptionProductActive>
    selectedPlanId: Option.Option<PlanId>
  }

  export class AddToCart extends Context.Tag('PlanCheckoutService.AddToCart')<
    AddToCart,
    (params: AddToCartParams) => Effect.Effect<void>
  >() { }
}

export default PlanCheckoutService
