import { FailedInvoiceSummary } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer?: any[]
  }
}

export type EcommerceTransaction = {
  ecommerce: {
    currency: string
    value: string
    transaction_id: string // quote id
    items: {
      item_id: string // plan id
      item_name: string // plan name
      price: string
      item_category: string // frequency or interval
      quantity: string
    }[]
  }
}

export type FailedInvoicePayload = FailedInvoiceSummary

const loadGoogleTagManager = () => {
  if (window.dataLayer === undefined)
    window.dataLayer = []

  return {
    sendTest: () =>
      new Promise<void>(resolve => {
        if (!window.dataLayer) return resolve()
        window.dataLayer?.push({
          event: 'TEST_EVENT',
          eventCallback: () => {
            resolve()
          },
        })
      }),
    sendPurchaseEvent: (payload: EcommerceTransaction) =>
      new Promise<void>(resolve => {
        if (!window.dataLayer) return resolve()
        window.dataLayer?.push({
          event: 'purchase',
          ...payload,
          eventCallback: () => {
            resolve()
          },
        })
      }),
    sendTrialEvent: () =>
      new Promise<void>(resolve => {
        if (!window.dataLayer) return resolve()
        window.dataLayer?.push({
          event: 'trial',
          eventCallback: () => {
            resolve()
          },
        })
      }),
    sendWalletEvent: (payload: EcommerceTransaction) =>
      new Promise<void>(resolve => {
        if (!window.dataLayer) return resolve()
        window.dataLayer?.push({
          ...payload,
          event: 'walletFundsPurchase',
          eventCallback: () => {
            resolve()
          },
        })
      }),
    sendFailedInvoice: (payload: FailedInvoicePayload) =>
      new Promise<void>(resolve => {
        if (!window.dataLayer) return resolve()
        window.dataLayer?.push({
          event: 'failedInvoice',
          ...payload,
          eventCallback: () => {
            resolve()
          },
        })
      }),
  }
}

export default loadGoogleTagManager
