import { IconButton, MenuButton } from '@chakra-ui/react'
import { WalletIcon } from 'presentation/components/Icons'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { Tooltip } from 'presentation/components/Tooltip'
import { SkiptraceAccountBalanceCard } from '../SkiptraceAccountBalanceCard'

export const MarketingFundsModal = () => (
  <Menu>
    <Tooltip
      label='Click here to view/add marketing funds'
      defaultIsOpen={true}
      autoCloseAfter={5000}
      placement='bottom-start'
    >
      <MenuButton
        as={IconButton}
        aria-label='Marketing Funds'
        icon={<WalletIcon boxSize={3} />}
        color='ondark.1'
      />
    </Tooltip>
    <ResponsiveMenuList>
      <SkiptraceAccountBalanceCard bg='none' shadow='none' p={0} pl={2} />
    </ResponsiveMenuList>
  </Menu>
)
