import { create } from 'zustand'

export type IdWithOrigin = {
  id: string
  origin: 'map' | 'list' | 'other'
}

export type MapAndListSync = {
  highlightedMapMarker: IdWithOrigin | null
  highlightedListItem: IdWithOrigin | null
}

const DELAY = 500

export const creatMapAndListSync = () => {
  const useStore = create<MapAndListSync>(() => ({
    highlightedMapMarker: null,
    highlightedListItem: null,
  }))

  const store = useStore

  // ========================================
  // Map handlers -- click
  // ========================================

  const handleMapMarkerClick = (id: string, { shouldClearIfHighlighted = false } = {}) => {
    const currentMapHighlight = store.getState().highlightedMapMarker

    // Abort marker timeout handler
    if (mapMarkerHoverTimeoutId)
      clearTimeout(mapMarkerHoverTimeoutId)

    // Update immediately
    if (shouldClearIfHighlighted && currentMapHighlight?.id === id) {
      store.setState({
        highlightedMapMarker: null,
        highlightedListItem: null,
      })
    } else {
      store.setState({
        highlightedMapMarker: {
          id,
          origin: 'map',
        },
        highlightedListItem: {
          id,
          origin: 'map',
        },
      })
    }
  }

  // ========================================
  // Map handlers -- hover
  // ========================================

  let mapMarkerHoverTimeoutId: ReturnType<typeof setTimeout> | null = null
  const handleMapMarkerHover = (id: string) => {
    // Abort marker timeout handler
    if (mapMarkerHoverTimeoutId)
      clearTimeout(mapMarkerHoverTimeoutId)

    // Only update after a delay
    mapMarkerHoverTimeoutId = setTimeout(() => {
      store.setState({
        highlightedMapMarker: {
          id,
          origin: 'map',
        },
        highlightedListItem: {
          id,
          origin: 'map',
        },
      })
    }, DELAY)
  }

  // ========================================
  // Map handlers -- unhover
  // ========================================

  const handleMapMarkerUnhover = () => {
    // Abort marker timeout handler
    if (mapMarkerHoverTimeoutId)
      clearTimeout(mapMarkerHoverTimeoutId)
  }

  // ========================================
  // List handlers -- click
  // ========================================

  const handleListItemClick = (id: string) => {
    // Abort list item timeout handler
    if (listItemHoverTimeoutId)
      clearTimeout(listItemHoverTimeoutId)

    // Update immediately
    store.setState({
      highlightedMapMarker: {
        id,
        origin: 'list',
      },
      highlightedListItem: {
        id,
        origin: 'list',
      },
    })
  }

  // ========================================
  // List handlers -- hover
  // ========================================

  let listItemHoverTimeoutId: ReturnType<typeof setTimeout> | null = null
  const handleListItemHover = (id: string) => {
    // Update immediately list item immediately
    store.setState({
      highlightedListItem: {
        id,
        origin: 'list',
      },
    })

    // abort marker timeout handler
    if (listItemHoverTimeoutId)
      clearTimeout(listItemHoverTimeoutId)

    // Only update map marker after a delay
    listItemHoverTimeoutId = setTimeout(() => {
      store.setState({
        highlightedMapMarker: {
          id,
          origin: 'list',
        },
      })
    }, DELAY)
  }

  // ========================================
  // List handlers -- unhover
  // ========================================

  const handleListItemUnhover = () => {
    // Abort list item timeout handler
    if (listItemHoverTimeoutId)
      clearTimeout(listItemHoverTimeoutId)

    // If there's map highlight
    const highlightedMapMarker = store.getState().highlightedMapMarker

    // restore list highlight to it
    if (highlightedMapMarker) {
      store.setState({
        highlightedListItem: highlightedMapMarker,
      })
    }
  }

  const setHighlightedEntry = (id: string | null) => {
    if (id) {
      store.setState({
        highlightedListItem: {
          id,
          origin: 'other',
        },
        highlightedMapMarker: {
          id,
          origin: 'other',
        },
      })
    } else {
      store.setState({
        highlightedListItem: null,
        highlightedMapMarker: null,
      })
    }
  }

  const mapAndListSync = {
    handleMapMarkerClick,
    handleMapMarkerHover,
    handleMapMarkerUnhover,
    handleListItemClick,
    handleListItemHover,
    handleListItemUnhover,
    setHighlightedEntry,
    useStore,
  }

  return mapAndListSync
}
