import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react'
import { chipColorScheme, chipSize, chipVariant } from 'presentation/components/Chip/Chip.theme'
import { forwardRef } from 'react'

export type ChipProps = BoxProps & {
  size?: chipSize
  colorScheme?: chipColorScheme
  variant?: chipVariant
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(({
  size,
  colorScheme,
  variant,
  children,
  ...props
}, ref) => {
  const styles = useMultiStyleConfig('Chip', { size, colorScheme, variant })

  return (
    <Box
      {...props}
      sx={{
        ...styles.chip,
        ...props.sx,
      }}
      ref={ref}
    >
      {children}
    </Box>
  )
})

Chip.displayName = 'Chip'
