import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import { isNumber } from 'effect/Predicate'
import { SelectMenu, SelectMenuButton, SelectMenuList } from 'presentation/components/SelectMenu/SelectMenu'
import { SoldWithinFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/SoldWithinFilterCard/SoldWithinFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { SoldWithinRange } from 'presentation/screens/CompsScreen/components/common/SoldWithinRange'

export const SoldWithinFilterCardDumb = (
  props: SoldWithinFilterCardDumbProps,
) => {
  const selectedLabel = SoldWithinRange.toLabel(props.value)

  const handleOnOpenChange = () => {
    props.onOpenChange?.(!props.isOpen)
  }

  return (
    <FilterCard
      title='Sold Within'
      hasBadge={isNumber(props.value[1])}
    >
      <SelectMenu isOpen={props.isOpen}>
        <SelectMenuButton onClick={handleOnOpenChange}>
          {selectedLabel}
        </SelectMenuButton>
        <SelectMenuList>
          <MenuOptionGroup
            value={selectedLabel}
            onChange={rawLabelValue => {
              const labelValue = Array.isArray(rawLabelValue) ? rawLabelValue[0] : rawLabelValue
              const rangeValue = SoldWithinRange.fromLabel(labelValue)
              props.onChange(rangeValue)
            }}
          >
            {SoldWithinRange.PRESETS.map(({ label }) => (
              <MenuItemOption key={label} value={label}>
                {label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </SelectMenuList>
      </SelectMenu>
      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )
}
