import { MarketingListMutationResult } from '__generated__/graphql'
import { Effect, Match } from 'effect'
import { ListNotFoundError, ListTypeNotSupportedError, ParcelNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import ParcelId from 'features/ListBuilder/domain/ParcelId'

const toDomain = (params: {
  listId: MarketingListId
  parcelId: ParcelId
  data: MarketingListMutationResult
}) => Match.value(params.data).pipe(
  Match.when({ __typename: 'MarketingList' }, () => Effect.void), // @TODO return marketing list instead
  Match.when({ __typename: 'MarketingListNotFoundError' }, () =>
    Effect.fail(new ListNotFoundError({ marketingListId: params.listId })),
  ),
  Match.when({ __typename: 'MarketingListParcelNotFoundError' }, () =>
    Effect.fail(new ParcelNotFoundError({ parcelId: params.parcelId })),
  ),
  Match.when({ __typename: 'MarketingListUnsupportedTypeError' }, () =>
    Effect.fail(new ListTypeNotSupportedError({ marketingListId: params.listId })),
  ),
  Match.orElse(() => Effect.dieMessage('Adding parcel failed')),
)

export default toDomain
