import { ColorProps, IconProps } from '@chakra-ui/react'
import { SkiptraceFailedIcon, SkiptraceSuccessIcon } from 'presentation/components/Icons'

export type SkiptraceStatusProps = {
  isSkipped?: boolean
  successColor?: ColorProps['color']
  failedColor?: ColorProps['color']
} & Pick<IconProps, 'boxSize'>

export const SkiptraceStatus = ({
  isSkipped,
  boxSize = 3,
  successColor = 'positive.500',
  failedColor = 'negative.500',
}: SkiptraceStatusProps) => (
  <>
    {isSkipped
      ? <SkiptraceSuccessIcon boxSize={boxSize} color={successColor} />
      : <SkiptraceFailedIcon boxSize={boxSize} color={failedColor} />}
  </>
)
