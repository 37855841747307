import EventEmitter from 'eventemitter3'

const eventEmitter = new EventEmitter()

export const LegacyBillingEvents = {
  addFundsDone: {
    emit: () => eventEmitter.emit('addFundsDone'),
    subscribe: (listener: () => void) => eventEmitter.on('addFundsDone', listener),
  },
}
