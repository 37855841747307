import { mbp } from 'presentation/utils/mapBreakpoint'

export const MODAL_MX = { mobSm: 1, mob: 2, tabSm: 5 }

export const modalTheme = {
  parts: [
    'overlay',
    'dialogContainer',
    'dialog',
    'header',
    'closeButton',
    'body',
    'footer',
  ],
  baseStyle: {
    overlay: {
      bg: 'modal.blur',
    },
    dialog: {
      w: 'auto',
      bg: 'none',
      boxShadow: 'none',
    },
    dialogContainer: {
      px: mbp(MODAL_MX),
      py: '4',
    },
    closeButton: {
      pos: 'absolute',
      top: '0',
      right: '0',
      transform: mbp({
        mobSm: 'translate(-16px, 16px)',
        tabSm: 'translate(100%, -100%)',
      }),
      boxSize: '4',
      bg: mbp({ mobSm: 'onlight.1', tabSm: 'ondark.5' }),
      color: mbp({ mobSm: 'ondark.5', tabSm: 'onlight.1' }),
      borderRadius: 'full',
      fontSize: '2.5',
      lineHeight: '0',

      // Dirty hack to make the close button clickable
      // when there's some image on the upper right part
      zIndex: 'modal',
    },
  },
  size: {},
  defaultProps: {
    isCentered: true,
    scrollBehavior: 'inside',
  },
}
