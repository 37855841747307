import { createIcon } from '@chakra-ui/react'

export const LockIcon = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d='M12.6667 7.33337H3.33333C2.59695 7.33337 2 7.93033 2 8.66671V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V8.66671C14 7.93033 13.403 7.33337 12.6667 7.33337Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4.66602 7.33337V4.66671C4.66602 3.78265 5.01721 2.93481 5.64233 2.30968C6.26745 1.68456 7.11529 1.33337 7.99935 1.33337C8.8834 1.33337 9.73125 1.68456 10.3564 2.30968C10.9815 2.93481 11.3327 3.78265 11.3327 4.66671V7.33337' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
