import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { SkiptraceBillingSummaryCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceBillingSummaryCard'

export type SkiptraceAgainBillingSummaryModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const SkiptraceAgainBillingSummaryModal = (props: SkiptraceAgainBillingSummaryModalProps) => (
  <ResponsiveFullscreenModal
    isOpen={props.isOpen}
    onClose={props.onClose}
    title='Skiptrace'
  >
    <SkiptraceBillingSummaryCard
      maxW='736px'
      onCancel={props.onClose}
    />
  </ResponsiveFullscreenModal>
)
