import { Array, HashSet } from 'effect'
import { isNull } from 'effect/Predicate'
import { CMA } from 'features/CMA/CMA.domain'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { HomeTypeFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/HomeTypeFilterCard/HomeTypeFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { HomeTypeFilterOption } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption'
import { HomeTypeIconSize } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption.props'
import { HomeTypeFilterOptionGrid } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOptionGrid'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'

export const HomeTypeFilterCardDumb = (
  props: HomeTypeFilterCardDumbProps,
) => {
  const value = HashSet.fromIterable(props.value.types)

  const optionSize: HomeTypeIconSize = useSwitchBreakpoint({
    mobSm: 'sm',
    tabSm: 'lg',
  }) ?? 'sm'

  const handleSelectionChange = (
    isSelected: boolean,
    type: HomeType | null,
  ) => {
    const newValue: CMA.Filters.HomeType = {
      ...props.value,
      types: isNull(type)
        ? []
        : Array.fromIterable(isSelected
          ? HashSet.add(value, type)
          : HashSet.remove(value, type)),
    }

    props.onChange(newValue)
  }

  const homeTypesOptions: HomeType[] = [
    'single-family',
    'mobile',
    'condo',
    'town-house',
    'duplex-to-fourplex',
    'multi-family',
    'commercial',
    'farm',
    'lot-or-land',
  ]

  return (
    <FilterCard title='Property Type'>
      <HomeTypeFilterOptionGrid
        gridTemplateColumns='repeat(6, 1fr)'
        sx={{
          '& > div:nth-of-type(1), & > div:nth-of-type(2), & > div:nth-of-type(3)': {
            gridColumn: 'span 2',
          },
          '& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)': {
            gridColumn: 'span 2',
          },
          '& > div:nth-of-type(7), & > div:nth-of-type(9)': {
            gridColumn: '2 / span 2',
          },
          '& > div:nth-of-type(8), & > div:nth-of-type(10)': {
            gridColumn: '4 / span 2',
          },
        }}
      >
        <HomeTypeFilterOption
          key='any'
          size={optionSize}
          variation={null}
          isSelected={HashSet.size(value) === 0}
          isSubjectProperty={false}
          onSelectionChange={isSelected => handleSelectionChange(isSelected, null)}
        />

        {homeTypesOptions.map(type => (
          <HomeTypeFilterOption
            key={type}
            size={optionSize}
            variation={type}
            isSelected={HashSet.has(value, type)}
            isSubjectProperty={false}
            onSelectionChange={isSelected => handleSelectionChange(isSelected, type)}
          />
        ))}
      </HomeTypeFilterOptionGrid>

      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )
}
