import { Context, Effect, Option, Stream } from 'effect'
import { DownloadNotAvailableError, ListNotFoundError, ListTypeNotSupportedError, ParcelNotFoundError } from 'features/ListBuilder/domain/Errors'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import CursorId from 'features/valueObjects/CursorId'

const Tag = Context.Tag('MarketingListRepo')

class MarketingListRepo extends Tag<MarketingListRepo, {
  readonly saveList: MarketingListRepo.SaveList
  readonly subscribeImportProgress: MarketingListRepo.SubscribeImportProgress
  readonly findById: MarketingListRepo.FindById
  readonly getByPage: MarketingListRepo.GetByPage
  readonly deleteByIds: MarketingListRepo.DeleteByIds
  readonly updateListName: MarketingListRepo.UpdateListName
  readonly getDownloadStats: MarketingListRepo.GetDownloadStats
  readonly downloadList: MarketingListRepo.DownloadList
  readonly getNextByCursor: MarketingListRepo.GetNextByCursor
  readonly getPrevByCursor: MarketingListRepo.GetPreviousByCursor
  readonly getNextByParcel: MarketingListRepo.GetNextByParcel
  readonly getPrevByParcel: MarketingListRepo.GetPreviousByParcel
  readonly addParcel: MarketingListRepo.AddParcel
  readonly addParcelToLists: MarketingListRepo.AddParcelToLists
  readonly removeParcelFromLists: MarketingListRepo.RemoveParcelFromLists
  readonly createList: MarketingListRepo.CreateList
}>() {}

declare namespace MarketingListRepo {

  export type SaveList<T extends ListCriteria = ListCriteria> = (
    params: SaveListParams<T>
  ) => Effect.Effect<MarketingList>

  export type SaveListParams<T extends ListCriteria = ListCriteria> = {
    listName: string
    criteria: T
  }

  export type SubscribeImportProgress = (
    id: MarketingListId
  ) => Stream.Stream<ImportProgress>

  export type ImportProgress = {
    marketingListId: MarketingListId
    memberCount: number
    percentage: number
    lastUpdated: Date | null
  }

  export type FindById = (
    id: MarketingListId
  ) => Effect.Effect<MarketingList, ListNotFoundError>

  export type GetByPage = (
    page: number
  ) => Effect.Effect<GetByPageResult>

  export type GetByPageResult = {
    lists: MarketingList[]
    currentPage: number
    countPerPage: number
    totalCount: number
  }

  export type DeleteByIds = (
    listIds: MarketingListId[]
  ) => Effect.Effect<DeleteListsResult, ListNotFoundError>

  export type DeleteListsResult = {
    successfulIds: MarketingListId[]
    failedIds: MarketingListId[]
  }

  export type UpdateListName = (
    listId: MarketingListId,
    name: string
  ) => Effect.Effect<MarketingList, ListNotFoundError>

  export type GetDownloadStats = (
    listId: MarketingListId
  ) => Effect.Effect<DownloadStats, ListNotFoundError>

  export type DownloadStats = {
    listId: MarketingListId
    downloadedMemberCount: number
    limitInfoByInterval: LimitInfoByInterval
  }

  export type LimitInterval = 'monthly'

  export type LimitInfoByInterval = Record<LimitInterval, LimitInfo>

  export type LimitInfo = {
    interval: LimitInterval
    limit: number
    consumed: number
    remaining: number
  }

  export type DownloadList = (
    listId: MarketingListId
  ) => Effect.Effect<void, ListNotFoundError | DownloadNotAvailableError, never>

  export type GetNextByCursor = (
    cursor: Option.Option<CursorId>
  ) => Effect.Effect<GetByCursorResult, never>

  export type GetPreviousByCursor = (
    cursor: Option.Option<CursorId>
  ) => Effect.Effect<GetByCursorResult, never>

  export type GetNextByParcel = (params: {
    parcelId: ParcelId
    cursor: Option.Option<CursorId>
  }) => Effect.Effect<GetByCursorResult, never>

  export type GetPreviousByParcel = (params: {
    parcelId: ParcelId
    cursor: Option.Option<CursorId>
  }) => Effect.Effect<GetByCursorResult, never>

  export type GetByCursorResult = {
    lists: MarketingList[]
    firstCursor: Option.Option<CursorId>
    lastCursor: Option.Option<CursorId>
    countPerPage: number
    totalCount: number
  }

  export type AddParcel = (params: {
    parcelId: ParcelId
    listId: MarketingListId
  }) => Effect.Effect<void, ListNotFoundError | ParcelNotFoundError | ListTypeNotSupportedError>

  export type AddParcelToLists = (params: {
    parcelId: ParcelId
    listIds: MarketingListId[]
  }) => Effect.Effect<AddParcelToListsResult, ListNotFoundError | ParcelNotFoundError | ListTypeNotSupportedError>

  export type AddParcelToListsResult = {
    successfulIds: MarketingListId[]
    failedIds: MarketingListId[]
  }

  export type RemoveParcelFromLists = (params: {
    parcelId: ParcelId
    listIds: MarketingListId[]
  }) => Effect.Effect<RemoveParcelFromListsResult, ListNotFoundError | ParcelNotFoundError | ListTypeNotSupportedError>

  export type RemoveParcelFromListsResult = {
    successfulIds: MarketingListId[]
    failedIds: MarketingListId[]
  }

  export type CreateList = (params: {
    name: string
  }) => Effect.Effect<MarketingListId>
}

export default MarketingListRepo
