import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { NumberLib } from 'libs/Number'
import { getMLSStatusTagColor } from 'presentation/components/MLSStatusTag'
import { SubjectPropertyStat } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { DataSectionDumb } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/DataSection'

export const ComparativeModalDataSection = ({
  currentComp,
  subjectComp,
}: {
  currentComp: CMA.SingleComp | CMA.SubjectComp
  subjectComp: CMA.SubjectComp
}) => {
  const {
    bgColor,
    color,
  } = pipe(
    Match.value(currentComp),
    Match.when({ type: 'single-comp' }, entry =>
      getMLSStatusTagColor(entry.status)),
    Match.when({ type: 'subject-comp' }, () => ({
      color: 'ondark.1',
      bgColor: 'accent.darkpurple',
    })),
    Match.exhaustive,
  )

  const shouldShowSubjectIcon = currentComp.type === 'subject-comp'

  const mapCompToStats = (comp: CMA.SingleComp | SubjectPropertyStat) => [
    { label: 'beds', value: comp.bedroomsCount ?? '--' },
    {
      label: 'baths',
      value: (comp.bathroomsCountInfo
      && BathroomsCountInfo.getTotal(comp.bathroomsCountInfo))
      ?? '--',
    },
    { label: 'garage', value: comp.garageSpacesCount ?? '--' },
    { label: 'sqft', value: NumberLib.formatCODoDD(comp.livingAreaSqft) },
    { label: 'built', value: comp.yearBuilt ?? '--' },
    {
      label: 'acre',
      value: NumberLib.ifElse(NumberLib.omitAfterNPlaces(2))(() => '--')(comp.lotAreaAcres),
    },
  ]

  const stats = mapCompToStats(currentComp)

  const subjectStats = currentComp.type === 'single-comp'
    ? mapCompToStats(subjectComp)
    : []

  return (
    <DataSectionDumb
      shouldShowSubjectStats={currentComp.type === 'single-comp'}
      bgColor={bgColor}
      color={color}
      stats={stats}
      subjectStats={subjectStats}
      shouldShowSubjectIcon={shouldShowSubjectIcon}
    />
  )
}
