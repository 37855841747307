import { createSettingsStore } from 'features/Settings/infra/react/Settings.state.zustand'
import { getLocallyStoredSettings } from 'features/Settings/infra/remote/getLocallyStoredSettings'
import { getSettings } from 'features/Settings/infra/remote/getSettings'
import { updateLocallyStoredSettings } from 'features/Settings/infra/remote/updateLocallyStoredSettings'
import { updateSettings } from 'features/Settings/infra/remote/updateSettings'
import { ErrorLib } from 'libs/errors'

const reportedGetSettings = ErrorLib.wrapReportOnReject(getSettings)
const reportedUpdateSettings = ErrorLib.wrapReportOnReject(updateSettings)

export const settingsStore = createSettingsStore({
  getSettings: reportedGetSettings,
  updateSettings: reportedUpdateSettings,
  getLocallyStoredSettings,
  updateLocallyStoredSettings,
})
