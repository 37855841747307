import DetailDisclosurePopover, { DetailDisclosurePopoverProps } from 'presentation/components/DetailDisclosurePopover/DetailDisclosurePopover'

type SkiptraceDetailDisclosureViewModel = {
  propertyCount: string
}

type SkiptraceDetailDisclosureProps =
  & DetailDisclosurePopoverProps
  & SkiptraceDetailDisclosureViewModel

const SkiptraceDetailDisclosure = ({
  propertyCount,
  popoverContentProps,
  ...props
}: SkiptraceDetailDisclosureProps) => (
  <DetailDisclosurePopover
    popoverContentProps={{
      w: '160px',
      textAlign: 'center',
      ...popoverContentProps,
    }}
    {...props}
  >
    Free Skiptrace for up to
    {' '}
    {propertyCount}
    {' '}
    properties.
    <br />
    Failed skips won’t be deducted.
  </DetailDisclosurePopover>
)

export default SkiptraceDetailDisclosure
