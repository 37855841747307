import { useQuery } from '@apollo/client'
import { Box, Flex } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { toast } from 'presentation/components/Toast'
import { Card, CardFullWidthSection, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { PaymentCardList } from 'presentation/screens/Billing/components/PaymentCardList'
import { SecurePaymentIconsSection } from 'presentation/screens/Billing/components/SecurePaymentIconsSection'
import { CARDS_VIEW__GET_PAYMENT_METHODS } from 'presentation/screens/Billing/queries'
import { is } from 'presentation/utils/graphql'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, useEffect } from 'react'
import { AddPaymentFlowButton } from '../AddPaymentFlowButton'

export const PaymentSection: FC = () => {
  const { data, error, loading } = useQuery(CARDS_VIEW__GET_PAYMENT_METHODS)
  const { mfv } = useMobFirstBreakpointValue()

  const paymentMethods = data?.myEnterprise?.paymentMethods?.edges
    .map(e => e?.node)
    .filter(is('BillingCardsPaymentMethod'))

  // React to errors
  useEffect(() => {
    if (!error) return

    toast({
      status: 'error',
      title: 'We couldn’t load your payment methods.',
      message: error.message,
    })
  }, [error])

  return (
    <Card
      variant='default'
      colorScheme='card.bg.1'
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', dtLg: 'md-locked' })}
      w='full'
    >
      <Flex
        w='full'
        alignItems='center'
      >
        <CardPrimaryTitle flex='1' as='h1'>
          <Breakpoint
            mobSm='Payment Info'
            mob='Secure Payment Info'
          />
        </CardPrimaryTitle>

        <Box flex='0 0 auto'>
          <AddPaymentFlowButton />
        </Box>
      </Flex>

      <CardFullWidthSection>
        <PaymentCardList
          isLoading={loading || !data}
          paymentMethods={paymentMethods}
          slidesOffsetBefore={mfv({
            mobSm: 16,
            mob: 24,
            dtLg: 32,
          }) || 16}
          slidesOffsetAfter={mfv({
            mobSm: 16,
            mob: 24,
            dtLg: 32,
          }) || 16}
        />
      </CardFullWidthSection>

      <SecurePaymentIconsSection />
    </Card>
  )
}
