import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

export const sendResetPasswordEmail: AccessDeps['sendResetPasswordEmail'] = async payload => {
  const isForRetrial = payload.redirectUrl?.includes('renew-trial')
  const url = isForRetrial
    ? `${window.location.origin}/reset-password-for-retrial`
    : `${window.location.origin}/reset-password`

  return await restClient.post('password-reset/request', {
    json: {
      email: payload.email,
      url,
    },
  })
    .json()
    .then(() => ({
      status: 'success' as const,
    }))
    .catch(async error => {
      // We don't let the user know if the email doesn't exist for security purposes
      if (error instanceof HTTPError && error.response.status === 404)
        return { status: 'success' as const }

      throw error
    })
}
