import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Option, pipe } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { FeatureAvailableSpec, ProductFeatureSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'

type LiveSupportFeatureRowViewModel = readonly boolean[]

const isLiveSupportFeature = (feature: ProductFeatureSpec): feature is FeatureAvailableSpec =>
  feature.featureName === 'LiveSupport' && feature._tag === 'FeatureAvailableSpec'

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const vm: LiveSupportFeatureRowViewModel = pipe(
    snapshot.context.products,
    /**
     * Make sure we only show the product if the plan is available
     */
    Array.filter(product => pipe(
      product.plans,
      Array.some(plan =>
        plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
    )),
    Array.map(product => pipe(
      product.features,
      Array.findFirst((feature: ProductFeatureSpec): feature is FeatureAvailableSpec => isLiveSupportFeature(feature)),
      Option.match({
        onNone: () => false,
        onSome: () => true,
      }),
    )),
    Data.array,
  )

  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const LiveSupportFeatureRowViewModel = {
  usePresenter,
}

export default LiveSupportFeatureRowViewModel
