export const COMPS_SORT_VALUES = [
  'PRICE_ASC',
  'PRICE_DESC',
  'SQUARE_FEET_ASC',
  'SQUARE_FEET_DESC',

  // @IMPORTANT Abbreviate PPSQFT because some logic uses string.includes and
  // "PRICE_PER_SQUARE_FEET" would cause issues with "PRICE" and "SQUARE_FEET"
  // would conflict
  'PPSQFT_ASC',
  'PPSQFT_DESC',
  'YEAR_BUILT_ASC',
  'YEAR_BUILT_DESC',
  'SOLD_DATE_DESC',
  'SOLD_DATE_ASC',
  'DOM_ASC',
  'DOM_DESC',
  'DISTANCE_FAR_TO_NEAR',
  'DISTANCE_NEAR_TO_FAR',
] as const

export type CompsSort = typeof COMPS_SORT_VALUES[number]

export const DEFAULT_COMPS_SORT = 'PRICE_ASC'
