import { Effect, Option, RequestResolver, pipe } from 'effect'
import { isNullable } from 'effect/Predicate'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { GETImportProgressSchema } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SubscribeImportProgressResolver/GETImportProgressSchema'
import { EffectLib } from 'libs/Effect'
import { restClient } from 'presentation/libs/client'

const SubscribeImportProgressResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.SubscribeImportProgress) => pipe(
    Effect.promise(async () =>
      await restClient
        .get(`lists/v3/${request.listId}`)
        .json()),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => toDomain(decoded)),
  ),
)

export default SubscribeImportProgressResolver

const decode = EffectLib.makeLoggedDecoder({
  schema: GETImportProgressSchema,
  name: 'GETImportProgressResponse',
})

/**
 * Percentage takes some time to progress from 0. It is ideal to
 * show some progress instead of 0 to indicate there there is an activity.
 */
const MIN_PROGRESS = 10

const toDomain = (response: GETImportProgressSchema): MarketingListRepo.ImportProgress =>
  Option.match(response.importProgress, {
    onNone: (): MarketingListRepo.ImportProgress => ({
      marketingListId: MarketingListId.make(response.id),
      lastUpdated: response.updatedAt ?? response.createdAt,
      memberCount: response.memberStats.total ?? 0,
      percentage: 100,
    }),
    onSome: (v): MarketingListRepo.ImportProgress => ({
      marketingListId: MarketingListId.make(response.id),
      lastUpdated: v.updatedAt ?? v.createdAt ?? null,
      memberCount: v.total ?? 0,
      percentage: v.total === 0 || isNullable(v.total)
        ? MIN_PROGRESS
        : Math.max(Math.ceil(v.count) / v.total * 100, MIN_PROGRESS),
    }),
  })
