import { createIcon } from '@chakra-ui/react'

export const PenIcon = createIcon({
  displayName: 'PenIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='m12 19 7-7 3 3-7 7-3-3Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='m18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5ZM2 2l7.586 7.586' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M11 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
    </>
  ),
})
