import { ChoosePlan_CurrentPlanFragment } from '__generated__/graphql'
import { isNumber } from 'lodash/fp'
import { CurrentPlan } from './ChoosePlanModal.types'

export const unmaskCurrentPlanFragment = (
  data: ChoosePlan_CurrentPlanFragment,
): CurrentPlan => {
  const billingSubscriptionActive = data

  const planId = billingSubscriptionActive.plan.id

  if (!planId) throw new Error('No plan id found')

  const planName: string
      = billingSubscriptionActive.plan.name.replace(/\([^()]*\)/g, '') ?? ''

  const billingEndPeriod = billingSubscriptionActive.currentPeriod.end
    ? new Date(billingSubscriptionActive.currentPeriod.end)
    : undefined

  const price = billingSubscriptionActive.plan.price

  if (!isNumber(price)) throw new Error('Missing price')

  const intervalUnit = billingSubscriptionActive.plan.interval.unit

  if (!intervalUnit) throw new Error('No interval unit found')

  return {
    planId,
    planName,
    price,
    intervalUnit,
    billingEndPeriod,
  }
}
