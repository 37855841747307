import { Funds } from 'features/Funds/domain/Funds.domain'
import { GET_FUNDS_INFO_GQL } from 'features/Funds/infra/remote/getFundsInfo.graphql'
import { apolloClient } from 'presentation/libs/client'
import { isDate } from 'remeda'

export const getFundsInfo = async (): Promise<Funds.GetFundsInfoResult> =>
  await apolloClient.query({
    query: GET_FUNDS_INFO_GQL,
  })
    .then(({ data }) => {
      const enterprise = data.myEnterprise

      if (!enterprise)
        throw new Error('undefined `myEnterprise` getFundsInfo data')

      const subscription = enterprise.subscription

      if (subscription?.__typename !== 'BillingSubscriptionActive')
        throw new Error('undefined `myEnterprise.subscription` getFundsInfo data')

      const skiptrace = subscription.plan.product.features
        .find((
          feat,
        ): feat is Extract<typeof feat, { __typename: 'SubscriptionFeatureSkiptrace' }> =>
          feat.__typename === 'SubscriptionFeatureSkiptrace')

      const skiptracePrices = skiptrace?.prices[0]
      const skiptraceConsumables = skiptracePrices?.included

      if (!skiptracePrices || !skiptraceConsumables)
        throw new Error('undefined `skiptrace` getFundsInfo data')

      const remainingConsumables = skiptraceConsumables.limit - skiptraceConsumables.used

      const status = remainingConsumables > 0
        ? 'sufficient-consumables'
        : enterprise.wallet.balance.available >= skiptracePrices.price
          ? 'sufficient-funds'
          : 'insufficient-funds'

      return {
        fundsInfo: {
          funds: {
            available: enterprise.wallet.balance.available,
          },
          planName: subscription.plan.name,
          price: skiptracePrices.price,
          refreshingConsumables: skiptraceConsumables.limit,
          remainingConsumables,
          consumablesRefreshDate: isDate(subscription.currentPeriod.end)
            ? subscription.currentPeriod.end
            : new Date(subscription.currentPeriod.end),
          confirmationKey: '',
          status,
        },
      }
    })
