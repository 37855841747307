import { Array, Data, Effect, Layer, Match, Schedule, Stream, pipe } from 'effect'
import { DownloadEntryId } from 'features/ListBuilder/domain/Errors'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import AddParcelResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/AddParcelResolver/AddParcelResolver'
import CreateListResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/CreateListResolver/CreateListResolver'
import DownloadListResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/DownloadListResolver/DownloadListResolver'
import EditNameResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/EditNameResolver/EditNameResolver'
import FindByListIdResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/FindByListIdResolver/FindByListIdResolver'
import GetNextByCursorResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/GetNextByCursorResolver'
import GetPrevByCursorResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/GetPrevByCursorResolver'
import GetNextByParcelIdResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/GetNextByParcelIdResolver'
import GetPrevByParcelIdResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/GetPrevByParcelIdResolver'
import GetDownloadEntryResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetDownloadEntryResolver/GetDownloadEntryResolver'
import GetDownloadStatsResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetDownloadStatsResolver/GetDownloadStatsResolver'
import GetListsByPageResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetListsByPageResolver/GetListsByPageResolver'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import RemoveParcelResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/RemoveParcelResolver/RemoveParcelResolver'
import SaveListResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SaveListResolver/SaveListResolver'
import SubscribeImportProgressResolver from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SubscribeImportProgressResolver/SubscribeImportProgressResolver'
import DeleteByIdResolver from './DeleteByIdResolver/DeleteByIdResolver'

const findById: MarketingListRepo.FindById = listId => pipe(
  Effect.request(
    new MarketingListRequest.FindById({ listId }),
    FindByListIdResolver,
  ),
)

const deleteById = (listId: MarketingListId) => Effect.request(
  new MarketingListRequest.DeleteById({ listId }),
  DeleteByIdResolver,
)

const getDownloadList = (params: {
  listId: MarketingListId
  downloadId: DownloadEntryId
  fileName: string
}) => Effect.request(
  new MarketingListRequest.DownloadList(params),
  DownloadListResolver,
)

const MarketingListRepoLive = Layer.succeed(MarketingListRepo, {
  saveList: ({ criteria, listName }) => pipe(
    Match.value(criteria),
    Match.tag('PropertyListCriteria', v => savePropertyList({ listName, criteria: v })),
    Match.exhaustive,
  ),
  subscribeImportProgress: listId => pipe(
    Effect.request(
      new MarketingListRequest.SubscribeImportProgress({ listId }),
      SubscribeImportProgressResolver,
    ),
    Stream.repeatEffect,
    Stream.schedule(Schedule.spaced('1 second')),
    Stream.changes,
    Stream.takeUntil(v => v.percentage === 100),
  ),
  findById,
  getByPage: page => pipe(
    Effect.request(
      new MarketingListRequest.GetByPage({ page }),
      GetListsByPageResolver,
    ),
  ),
  deleteByIds: (listIds: MarketingListId[]) => pipe(
    Effect.succeed(listIds),
    Effect.map(ids => Array.map(ids, id => pipe(
      deleteById(id),
      Effect.andThen(() => Data.struct({ _tag: 'success', id })),
      Effect.catchTag('ListNotFoundError', () =>
        Effect.succeed(Data.struct({ _tag: 'failed', id }))),
    ))),
    Effect.flatMap(requests => Effect.all(requests, { concurrency: 'unbounded' })),
    Effect.flatMap(Effect.partition(response =>
      response._tag === 'success'
        ? Effect.succeed(response.id)
        : Effect.fail(response.id))),
    Effect.flatMap(([failedIds, successfulIds]) =>
      Effect.succeed({ successfulIds, failedIds } satisfies MarketingListRepo.DeleteListsResult)),
  ),
  updateListName: (listId, name) => pipe(
    Effect.request(
      new MarketingListRequest.UpdateListName({ listId, name }),
      EditNameResolver,
    ),
  ),
  getDownloadStats: listId => Effect.request(
    new MarketingListRequest.GetDownloadStats({ listId }),
    GetDownloadStatsResolver,
  ),
  downloadList: listId => pipe(
    Effect.request(
      new MarketingListRequest.GetDownloadEntry({ listId }),
      GetDownloadEntryResolver,
    ),
    Effect.flatMap(downloadId => pipe(
      findById(listId),
      Effect.map(marketingList => marketingList.name),
      Effect.flatMap(fileName => getDownloadList({ listId, downloadId, fileName })),
    )),
  ),
  getNextByCursor: cursor => pipe(
    Effect.request(
      new MarketingListRequest.GetNextByCursor({ cursor }),
      GetNextByCursorResolver,
    ).pipe(Effect.withRequestCaching(false)),
  ),
  getPrevByCursor: cursor => pipe(
    Effect.request(
      new MarketingListRequest.GetPrevByCursor({ cursor }),
      GetPrevByCursorResolver,
    ),
  ),
  getNextByParcel: ({ parcelId, cursor }) => pipe(
    Effect.request(
      new MarketingListRequest.GetNextByParcel({ parcelId, cursor }),
      GetNextByParcelIdResolver,
    ),
  ),
  getPrevByParcel: ({ parcelId, cursor }) => pipe(
    Effect.request(
      new MarketingListRequest.GetPrevByParcel({ parcelId, cursor }),
      GetPrevByParcelIdResolver,
    ),
  ),
  addParcel: ({ parcelId, listId }) => pipe(
    Effect.request(
      new MarketingListRequest.AddParcel({ parcelId, listId }),
      AddParcelResolver,
    ).pipe(Effect.withRequestCaching(false)),
  ),
  addParcelToLists: ({ parcelId, listIds }) => pipe(
    Effect.succeed(listIds),
    Effect.map(ids => Array.map(ids, id => pipe(
      Effect.request(
        new MarketingListRequest.AddParcel({ parcelId, listId: id }),
        AddParcelResolver,
      ).pipe(Effect.withRequestCaching(false)),
      Effect.andThen(() => Data.struct({ _tag: 'success', id })),
      Effect.catchTag('ListNotFoundError', () =>
        Effect.succeed(Data.struct({ _tag: 'failed', id }))),
    ))),
    Effect.flatMap(requests => Effect.all(requests, { concurrency: 'unbounded' })),
    Effect.flatMap(Effect.partition(response =>
      response._tag === 'success'
        ? Effect.succeed(response.id)
        : Effect.fail(response.id))),
    Effect.flatMap(([failedIds, successfulIds]) =>
      Effect.succeed({ successfulIds, failedIds } satisfies MarketingListRepo.AddParcelToListsResult)),
  ),
  removeParcelFromLists: ({ parcelId, listIds }) => pipe(
    Effect.succeed(listIds),
    Effect.map(ids => Array.map(ids, id => pipe(
      Effect.request(
        new MarketingListRequest.RemoveParcel({ parcelId, listId: id }),
        RemoveParcelResolver,
      ).pipe(Effect.withRequestCaching(false)),
      Effect.andThen(() => Data.struct({ _tag: 'success', id })),
      Effect.catchTag('ListNotFoundError', () =>
        Effect.succeed(Data.struct({ _tag: 'failed', id }))),
    ))),
    Effect.flatMap(requests => Effect.all(requests, { concurrency: 'unbounded' })),
    Effect.flatMap(Effect.partition(response =>
      response._tag === 'success'
        ? Effect.succeed(response.id)
        : Effect.fail(response.id))),
    Effect.flatMap(([failedIds, successfulIds]) =>
      Effect.succeed({ successfulIds, failedIds } satisfies MarketingListRepo.RemoveParcelFromListsResult)),
  ),
  createList: ({ name }) => pipe(
    Effect.request(
      new MarketingListRequest.CreateList({ name }),
      CreateListResolver,
    ).pipe(Effect.withRequestCaching(false)),
  ),
})

export default MarketingListRepoLive
/**
 * @TODO:
 * - add savePrivateLendersList
 * - add saveCashBuyersList
 */

// #region creation variants
const savePropertyList: MarketingListRepo.SaveList<PropertyListCriteria> = params => Effect.request(
  new MarketingListRequest.SaveList({
    listName: params.listName,
    criteria: params.criteria,
  }),
  SaveListResolver,
).pipe(Effect.withRequestCaching(false))
// #endregion
