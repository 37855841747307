import { Text, TextProps } from '@chakra-ui/react'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const HeadingText = (props: TextProps) => (
  <Text
    as='h1'
    textStyle={mbp({
      mobSm: 'inter-18-700',
      mob: 'inter-20-700',
      tabSm: 'inter-29-700',
      dtSm: 'inter-36-700',
      dt: 'inter-44-700',
    })}
    textAlign='center'
    {...props}
  />
)

export const SubheadingText = (props: TextProps) => (
  <Text
    as='h2'
    textStyle={mbp({
      mobSm: 'body2-b',
      tabSm: 'h4-b',
      dtSm: 'h3-b',
      dtLg: 'h2-b',
    })}
    mt='1'
    textAlign='center'
    {...props}
  />
)

export const SubheadingEmphasis = (props: TextProps) => (
  <Text
    as='strong'
    textStyle={mbp({
      mobSm: 'body2-h-b',
      tabSm: 'h4-h-b',
      dtSm: 'h3-h-b',
      dtLg: 'h2-h-b',
    })}
    {...props}
  />
)
