import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { EmailListForm, InviteMembersModalProps } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModal.types'
import { InviteMembersModalCard } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModalCard'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { openInviteDiscardErrorModal } from '../InviteDiscardErrorModal/InviteDiscardErrorModal.api'
import { InviteMembersModalAPI } from './InviteMembersModal.api'

export const InviteMembersModal: FC<InviteMembersModalProps> = ({
  isOpen,
  onClose,
  ...viewModel
}) => {
  // =============================================================================
  // Get defaults emails. Defaults emails might come from a previous session
  // =============================================================================
  const defaultEmails = viewModel.emails ?? []
  // =============================================================================
  // Modal form (list of emails)
  // =============================================================================
  const emailListForm = useForm<EmailListForm>({ defaultValues: { emails: defaultEmails } })

  const emails = emailListForm.watch('emails') || []

  const handleOnClose = () => {
    const hasProgress = emails.length > 0
    if (hasProgress) {
      openInviteDiscardErrorModal({
        onDiscard: () => {
          closeModal()
        },
        onKeepProgress: () => {
          InviteMembersModalAPI.transitionToIdle({
            ...viewModel,
            emails,
          })
        },
      })
    } else { onClose() }
  }

  return (
    <ResponsiveFullscreenModal
      title='Invite Team Members'
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <InviteMembersModalCard
        onClose={handleOnClose}
        {...viewModel}
        emailListForm={emailListForm}
        emails={emails}
      />
    </ResponsiveFullscreenModal>
  )
}
