// eslint-disable-next-line no-restricted-imports
import { BoxProps, ModalContent as ChakraModalContent, Flex, IconButton, ModalOverlay, VStack } from '@chakra-ui/react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { Modal, ModalContent, ModalProps } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { TitleBar } from 'presentation/components/TitleBar/TitleBar'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { FC, ReactNode, useMemo } from 'react'

export type ResponsiveModalProps = ModalProps & Pick<BoxProps, 'maxW'> & {
  title: string
  titleBarJsx?: ReactNode
}

export const ResponsiveFullscreenModal: FC<ResponsiveModalProps> = ({
  children,
  onClose,
  title,
  size,
  variant,
  colorScheme,
  titleBarJsx,
  ...props
}) => {
  const { sbp } = useSwitchBreakpointFn()

  return (
    <Breakpoint
      mobSm={(
        <Modal
          onClose={onClose}
          scrollBehavior={sbp({ mobSm: 'inside', tabSm: 'outside' })}
          {...props}
        >
          <ChakraModalContent
            containerProps={{
              sx: useMemo(() => ({
                p: 0,
                flex: '1 0 0',
                minH: '0',
              }), []),
            }}
            sx={{
              w: '100vw',
              maxW: '100vw',
              m: 0,
              p: '0 !important',
              borderRadius: 0,
              align: 'stretch',
              flex: '1 0 0',
              minH: '0',
              h: 'full',
              maxH: 'full',
            }}
          >
            <VStack
              flex='1 0 0'
              minH='0'
              minW='0'
              h='full'
              spacing='0'
              align='stretch'
            >
              {titleBarJsx || (
                <TitleBar
                  leftJsx={(
                    <IconButton
                      aria-label='Back'
                      icon={<ArrowLeftIcon />}
                      variant='icon-ghost'
                      colorScheme='ondark'
                      size='md'
                      onClick={onClose}
                    />
                  )}
                  title={title}
                />
              )}

              <Flex
                direction='column'
                flex='1 0 0'
                minH='0'
                minW='0'
                as={OverlayScrollbarsComponent}
                align='stretch'
                sx={{
                  '& > .os-viewport, & > [data-overlayscrollbars-contents]': {
                    'minH': '0',
                    'minW': '0',
                    'display': 'grid',
                    'gridTemplateRows': 'minmax(min-content, 1fr) min-content',
                    'gritTemplateColumns': '100vw',

                    '& > .chakra-card': {
                      'borderRadius': 0,
                      'w': 'full',
                      'maxW': 'full',

                      '& > .chakra-card__body': {
                        flex: 1,
                      },
                    },
                  },
                }}
              >
                {children}
              </Flex>
            </VStack>
          </ChakraModalContent>
        </Modal>
      )}
      mob={(
        <Modal onClose={onClose} {...props}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton onClose={onClose} />

            {children}
          </ModalContent>
        </Modal>
      )}
    />
  )
}
