import { Avatar, Box, Checkbox, HStack, IconButton, Menu, MenuButton, MenuItemOption, MenuOptionGroup, Portal, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { PersonName } from 'features/valueObjects/PersonName'
import { SliderHorizontalIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { HasFilterIndicator } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/HasFilterIndicator'
import { SeekersFilterMenuProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SeekersFilterMenu/SeekersFilterMenu.props'
import { shallow } from 'zustand/shallow'

export const SeekersFilterMenu = ({ colorScheme, ...props }: SeekersFilterMenuProps) => {
  const { seekers, toggleSeekerFilter, selectedSeekers, isUpdating } = useSearchHistoryStore(api => ({
    seekers: api.remote.seekers,
    toggleSeekerFilter: api.actions.toggleSeekerFilter,
    selectedSeekers: api.local.seekersFilter,
    isUpdating: api.remote.history.status === 'updating',
  }), shallow)

  const seekersData = seekers.status === 'success'
    ? seekers.data
    : []

  const shouldShowIndicator = selectedSeekers.length > 0

  const iconColor = pipe(
    Match.value(colorScheme),
    Match.when('sidepanel', () => shouldShowIndicator ? 'accent.darkblue' : 'graystrong.600'),
    Match.when('drawer', () => shouldShowIndicator ? 'highlight.500' : 'ondark.1'),
    Match.exhaustive,
  )

  return (
    <Box position='relative' w='min-content' {...props}>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={IconButton}
          position='relative'
          variant='icon-ghost'
          colorScheme='onlight'
          size='md'
          isDisabled={isUpdating}
          icon={(
            <SliderHorizontalIcon
              boxSize={3}
              color={iconColor}
            />
          )}
        />
        {shouldShowIndicator && <HasFilterIndicator />}
        <Portal>
          <ResponsiveMenuList>
            <MenuOptionGroup
              title='Searched by:'
              value={selectedSeekers}
              type='checkbox'
            >
              <Box maxH='388px' overflow='auto'>
                {seekersData.map(seeker => (
                  <MenuItemOption
                    key={seeker.id}
                    type='checkbox'
                    value={seeker.id}
                    onClick={ev => {
                      /**
                   * @HACK We're not supposed to use checkbox with MenuItemOption
                   * but because we because of speed somehow the cause the click
                   * to fire twice. Ignore the click if the target is an input
                   * to fix the double fire.
                   */
                      if (ev.target instanceof HTMLInputElement) return
                      void toggleSeekerFilter(seeker.id)
                    }}
                  >
                    <Checkbox
                      isChecked={selectedSeekers.includes(seeker.id)}
                      tabIndex={-1}
                    >
                      <HStack>
                        <Avatar
                          size='sm'
                          src={seeker.avatarUrl || ''}
                          name={PersonName.combine(seeker.name) || undefined}
                        />
                        <Text
                          textStyle='bodyMFat'
                          color='graystrong.400'
                        >
                          {PersonName.combine(seeker.name)}
                        </Text>
                      </HStack>
                    </Checkbox>
                  </MenuItemOption>
                ))}
              </Box>
            </MenuOptionGroup>
          </ResponsiveMenuList>
        </Portal>
      </Menu>
    </Box>
  )
}
