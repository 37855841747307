import { Logger, Match } from 'effect'
import { ErrorLib } from 'libs/errors'

const sentryLogger = Logger.make(({ logLevel, message }) => {
  void Match.value(logLevel).pipe(
    Match.tag('Fatal', async cause => {
      await ErrorLib.report(cause)
    }),
    Match.orElse(() => {
      // eslint-disable-next-line no-console
      console.log(`[${logLevel.label}] ${message}`)
    }),
  )
})

const SentryLoggerLive = Logger.replace(
  Logger.defaultLogger,
  sentryLogger,
)

export default SentryLoggerLive
