import { Box, BoxProps, Flex, createStylesContext, useMultiStyleConfig } from '@chakra-ui/react'
import classNames from 'classnames'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { PaginationColorSchemes, PaginationSize } from 'presentation/components/Pagination/Pagination.theme'
import { calcPageButtonsToRender } from 'presentation/components/Pagination/calcPaginationButtonsToRender'
import { ReactNode } from 'react'

export type PaginationProps = BoxProps & {
  size: PaginationSize
  colorScheme?: PaginationColorSchemes
  totalPages: number
  /** First page is with 1, NOT ZERO */
  currentPage: number
  maxPageButtons: number
  onPageChange: (
    /** First page is with 1, NOT ZERO */
    page: number
  ) => void
  isDisabled?: boolean
  renderPageButton?: (page: number | null) => ReactNode
  shouldHideFillers?: boolean
  shouldUseChevron?: boolean
}

export const [PaginationStylesProvider, usePaginationStyles] = createStylesContext('Pagination')

export const Pagination = ({
  size,
  colorScheme,
  totalPages,
  currentPage,
  maxPageButtons,
  onPageChange,
  renderPageButton,
  isDisabled,
  shouldHideFillers,
  shouldUseChevron,
  ...boxProps
}: PaginationProps) => {
  const styles = useMultiStyleConfig('Pagination', { size, colorScheme })
  const pageButtons = calcPageButtonsToRender({
    currentPage,
    totalPages,
    maxButtons: maxPageButtons,
  })

  const fillersToRender = maxPageButtons - pageButtons.length

  return (
    <PaginationStylesProvider value={styles}>
      <Flex sx={styles.container} justifyContent='end' {...boxProps}>
        <PaginationSideButton
          type='prev'
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={isDisabled || currentPage === 1}
        >
          {shouldUseChevron
            ? <ChevronLeftIcon boxSize={2} />
            : 'Prev'}
        </PaginationSideButton>
        {pageButtons.map((page, i) => (
          <InnerButton
            key={i}
            isSelected={page === currentPage}
            onClick={() => {
              if (page === null) return
              onPageChange(page)
            }}
            className={classNames(
              page === null && 'ellipsis',
              isDisabled && 'disabled-via-override',
            )}
            isDisabled={isDisabled || page === null}
          >
            {renderPageButton ? renderPageButton(page) : page === null ? '...' : page}
          </InnerButton>
        ),
        )}
        {!shouldHideFillers && Array.from({ length: fillersToRender }, (_, i) => (
          <Filler key={i} />
        ))}
        <PaginationSideButton
          type='next'
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={isDisabled || currentPage === totalPages}
        >
          {shouldUseChevron
            ? <ChevronRightIcon boxSize={2} />
            : 'Next'}
        </PaginationSideButton>
      </Flex>
    </PaginationStylesProvider>
  )
}

export const PaginationSideButton = ({ children, type, onClick, isDisabled }: {
  children: ReactNode
  type: 'prev' | 'next'
  onClick: () => void
  isDisabled: boolean
}) => {
  const styles = usePaginationStyles()
  return (
    <Box
      as='button'
      className={type}
      sx={styles.sideButton}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Flex alignItems='center' justifyContent='center'>
        {children}
      </Flex>
    </Box>
  )
}

const InnerButton = ({ isSelected, children, onClick, isDisabled, className }: {
  isSelected: boolean
  isDisabled: boolean
  children: ReactNode
  onClick?: () => void
  className: string
}) => {
  const styles = usePaginationStyles()
  return (
    <Box
      as='button'
      sx={styles.innerButton}
      className={classNames(isSelected ? 'selected' : '', className)}
      onClick={onClick}
      disabled={isDisabled}
      position='relative'
    >
      {children}
    </Box>
  )
}

const Filler = () => {
  const styles = usePaginationStyles()
  return (
    <Box sx={styles.filler} />
  )
}
