import { Effect, Option, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { MARKETINGLIST_PAGINATION_LIMIT } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/toDomain'
import { GET_PARCEL_MARKETING_LISTS } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/schema'
import toDomain from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/toDomain'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { apolloClient } from 'presentation/libs/client'

const GetPrevByParcelIdResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetPrevByParcel) => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.query({
          query: GET_PARCEL_MARKETING_LISTS,
          variables: {
            first: MARKETINGLIST_PAGINATION_LIMIT,
            after: Option.getOrNull(request.cursor),
            parcelId: request.parcelId,
          },
        }),
      catch: error => new UnknownException({ message: String(error) }),
    }),
    Effect.andThen(result => toDomain(result.data)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default GetPrevByParcelIdResolver
