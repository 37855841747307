import { Button, ButtonProps } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PlusIcon } from 'presentation/components/Icons'
import { openAddPaymentModal } from 'presentation/screens/Billing/Billing.modals'
import { FC } from 'react'

export const AddPaymentFlowButton: FC<ButtonProps> = ({ onClick = () => openAddPaymentModal(), ...props }) => (
  <Button
    variant='ghost'
    colorScheme='neutral'
    size='xs'
    leftIcon={<PlusIcon boxSize={2} />}
    onClick={onClick}
    {...props}
  >
    <Breakpoint
      mobSm='Add Payment'
      tabSm='Add Payment Method'
    />
  </Button>
)
