import { Breakpoint } from 'presentation/main/themes/common/breakpoints.theme'
import { isNonNullable } from 'utils/isNonNullable'

/** @IMPORTANT Exactly seven elements, because we have seven breakpoints */

export type MappedBreakpoint<T> = [T | null, T | null, T | null, T | null, T | null, T | null, T | null]
export type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

export const mapBreakpoint = <T>(dict: BreakpointMap<T>): MappedBreakpoint<T> =>
  [
    isNonNullable(dict.mobSm) ? dict.mobSm : null,
    isNonNullable(dict.mob) ? dict.mob : null,
    isNonNullable(dict.tabSm) ? dict.tabSm : null,
    isNonNullable(dict.tab) ? dict.tab : null,
    isNonNullable(dict.dtSm) ? dict.dtSm : null,
    isNonNullable(dict.dt) ? dict.dt : null,
    isNonNullable(dict.dtLg) ? dict.dtLg : null,
  ]

export const mbp = mapBreakpoint
