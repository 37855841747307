import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'
import { PropertyMarkerInfo } from 'features/valueObjects/PropertyMarkerInfo'
import { StringLib } from 'libs/String'
import { Tooltip } from 'presentation/components/Tooltip'

export const AddressSection = (
  {
    subdivision,
    classification,
    equityType,
    isVacant,
    isForeclosure,
    isSenior,
    line1,
    ...props
  }: BoxProps & PropertyMarkerInfo & CMA.Comp.Address,
) => (
  <Flex
    gap={1}
    alignItems='center'
    justifyContent='flex-start'
    {...props}
  >
    {/* @GENESIS */}
    {/* <PropertyMarker
      markerType='pin'
      classification={classification}
      equityType={equityType}
      isVacant={isVacant}
      isForeclosure={isForeclosure}
      isSenior={isSenior}
      size='xs'
    /> */}

    <Box minW={0}>
      <Tooltip label={line1}>
        <Text
          textStyle='bodyMFat'
          color='graystrong.400'
          isTruncated
          textAlign='left'
        >
          {line1}
        </Text>
      </Tooltip>
      <Tooltip label={StringLib.orDoubleDash(subdivision)}>
        <Text
          textStyle='bodyM'
          color='grayweak.900'
          isTruncated
          textAlign='left'
        >
          {StringLib.orDoubleDash(subdivision)}
        </Text>
      </Tooltip>
    </Box>
  </Flex>
)
