import { ButtonProps, Grid, IconButton } from '@chakra-ui/react'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { ModalCloseIcon } from 'presentation/components/Modal/ModalCloseIcon'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { SkiptraceBillingSummaryCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceBillingSummaryCard'
import { shallow } from 'zustand/shallow'

export type SkiptraceAgainBillingSummaryColumnProps = {
  onCancel: () => void
}

export const SkiptraceAgainBillingSummaryColumn = (props: SkiptraceAgainBillingSummaryColumnProps) => {
  const { onCancel } = props
  const store = useSkiptraceAgainState()

  return (
    <Grid gridTemplateAreas='"layer"' zIndex={1}>
      <SkiptraceBillingSummaryCard
        gridArea='layer'
        onCancel={onCancel}
        isColumnCard
      />
      <CloseButton
        gridArea='layer'
        zIndex={2}
        justifySelf='end'
        m={2}
        isDisabled={store.isSkiptracing}
        onClick={onCancel}
      />
    </Grid>
  )
}

/** TODO: Make this button reusable */
const CloseButton = (props: ButtonProps) => (
  <IconButton
    aria-label='cancel button'
    display='flex'
    alignItems='center'
    justifyContent='center'
    w={4}
    h={4}
    bgColor='onlight.1'
    borderRadius='50%'
    color='ondark.5'
    {...props}
  >
    <ModalCloseIcon
      boxSize='1.5'
      color='inherit'
    />
  </IconButton>
)

export const useSkiptraceAgainState = () => {
  const routeLeadId = useLeadId()
  const isSkiptracing = useSkiptraceStore(store => store.entities.resultState.status === 'skiptracing')
  const address = usePropertyDetailsStore(propertyDetailsApi => {
    const propertyState = propertyDetailsApi.actions.getProperty.state

    const isReady = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    if (!isReady) return null

    return propertyState.data.address.line1
  }, shallow)

  return {
    isSkiptracing,
    address,
  }
}
