import { ApolloError } from '@apollo/client'
import { captureException } from '@sentry/browser'
import { Cause, Effect } from 'effect'
import { HTTPError as KyHTTPError } from 'ky'
import { ReportableException } from 'libs/errors/ReportableException'
import { useRunOnceCallback } from 'presentation/hooks/useRunOnceCallback'
import { useEffect } from 'react'
import { logError } from 'utils/log'

export * from './DomainError'
export * from './FieldsError'
export * from './PartialError'
export * from './ProgramException'
export * from './ReportableException'

/**
 * @NOTE This is named ErrorLib to avoid name collision and confusion with the
 * built-in Error class
 */
export namespace ErrorLib {
  /** @TODO ban directly importing sentry to report error */

  /**
   * @example
   * const errorWithMoreInfo = new ReportableException('message', {
   *   extraInfo: {
   *     extra: 'info',
   *   }
   * })
   *
   * ErrorLib.report(errorWithMoreInfo)
   *
   * or ErrorLib.report(error, {
   *   extraInfo: {
   *     extra: 'info',
   *   }
   * })
   */
  export const report = async (
    rawError: any,
    params?: {
      extraInfo?: object
    },
  ) => {
    const originalError = rawError instanceof ReportableException
      && rawError.options?.originalError

    const errorToCapture = originalError || rawError

    const serverExtraInfo = await safeParseError(errorToCapture)

    const extraInfo = {
      ...rawError instanceof ReportableException
      && rawError.options?.extraInfo,
      ...params?.extraInfo,
      ...serverExtraInfo,
    }

    if (process.env.NODE_ENV === 'development') {
      logError('ErrorReport', 'error:', rawError)
      logError('ErrorReport', 'extraInfo:', extraInfo)
      logError('ErrorReport', 'originalError:', originalError)
    }

    captureException(
      errorToCapture,
      {
        /**
         * Extra prefers Record<string, any> but let's observe if it does fine
         * with any object
         */
        extra: extraInfo,
      },
    )
  }

  export const reportOnReject = <TPromise extends Promise<any>>(
    promise: TPromise,
  ): TPromise =>
    promise
      .catch(error => {
        void report(error)
        throw error
      }) as TPromise

  export const wrapReportOnReject = <TPromiseFn extends (...args: any[]) => Promise<any>>(
    promiseFn: TPromiseFn,
  ): TPromiseFn =>
    (async (...args: Parameters<TPromiseFn>) =>
      await reportOnReject(promiseFn(...args))) as TPromiseFn

  export const useReportOnceCallback = () => {
    const runOnce = useRunOnceCallback()

    const reportOnce = (error: Error) => {
      runOnce(() => {
        void report(error)
      })
    }

    return reportOnce
  }

  /** Reports error if any. Rereports when reference of error changes */

  export const useOptionalReport = (optionalError: any) => {
    useEffect(() => {
      if (!optionalError) return
      void report(optionalError)
    }, [optionalError])
  }

  /**
   * Parses error safely, in that if the error is already parsed, it will not
   * attempt to parse it again and get it instead from where it was stored when
   * it was first parsed.
   * @param error
   * @returns
   */
  export const safeParseError = async (error: any) => {
    // For apollo let's go ahead and parse, as far as we know, no other places
    // are parsing the response
    if (
      error instanceof ApolloError
      && error.networkError
    ) {
      let serverResponse: any

      if ('response' in error.networkError) {
        if (!error.networkError.response.bodyUsed) {
          serverResponse = await error.networkError.response
            .json()
            .catch(parseError => {
              void ErrorLib.report(parseError)
              return null
            })

        // ServerError
        } else if ('result' in error.networkError) {
          serverResponse = error.networkError.result

        // ServerParseError
        } else if ('bodyText' in error.networkError) {
          serverResponse = error.networkError.bodyText
        }
      }

      return {
        clientErrors: error.clientErrors,
        graphQLErrors: error.graphQLErrors,
        protocolErrors: error.protocolErrors,
        serverResponse,
      }
    }

    // For ky, we don't want to parse the response if it's already been parsed.
    // We try to capture the parsed data in the client.ts
    if (error instanceof KyHTTPError && error.response) {
      return {
        serverResponse: error.response.bodyUsed

          ? (error.response as any).parsedData ?? null // set in client.ts
          : await error.response
            .json()
            .catch(parseError => {
              void ErrorLib.report(parseError)
              return null
            }),
      }
    }

    return null
  }

  export const onThrow = (onThrowFn: (error: any) => any) =>

    <TFn extends (...args: any[]) => any>(fn: TFn) =>
      ((...args) => {
        try {
          return fn(...args)
        } catch (error) {
          return onThrowFn(error)
        }
      }) as TFn

  export const consoleLogOnThrow = onThrow((error: any) => {
    // eslint-disable-next-line no-console
    console.error(error)
  })

  export const tapCauseReporter = <E, A, R>(
    program: Effect.Effect<E, A, R>,
  ): Effect.Effect<E, A, R> =>
    program.pipe(
      Effect.tapErrorCause(cause => Effect.sync(() => {
        const extractedInfo = Cause.match<unknown, unknown>(cause, {
          onEmpty: () => ({}),
          onFail: failure =>
            failure && typeof failure === 'object' && 'cause' in failure
              ? { innerCause: failure.cause }
              : { failure },
          onDie: defect =>
            defect && typeof defect === 'object' && 'cause' in defect
              ? { innerCause: defect.cause }
              : { defect },
          onInterrupt: fiberId => ({
            fiberId,
          }),
          onSequential: (left, right) =>
            ({
              sequentialLeft: left,
              sequentialRight: right,
            }),
          onParallel: (left, right) =>
            ({
              parallelLeft: left,
              parallelRight: right,
            }),
        })
        const pretty = Cause.pretty(cause)

        void ErrorLib.report(
          new Error(pretty),
          {
            extraInfo: {
              ...extractedInfo as object,
              cause,
              pretty,
            },
          })
      })),
    )
}
