import { createIcon } from '@chakra-ui/react'

export const CloseCircleFilledIcon = createIcon({
  displayName: 'CloseCircleFilledIcon',
  viewBox: '0 0 40 40',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M20 37C29.3888 37 37 29.3888 37 20C37 10.6112 29.3888 3 20 3C10.6112 3 3 10.6112 3 20C3 29.3888 10.6112 37 20 37ZM16.5529 13.2471C15.64 12.3343 14.16 12.3343 13.2471 13.2471C12.3343 14.16 12.3343 15.64 13.2471 16.5529L16.6943 20L13.2471 23.4471C12.3343 24.36 12.3343 25.84 13.2471 26.7529C14.16 27.6657 15.64 27.6657 16.5529 26.7529L20 23.3057L23.4471 26.7529C24.36 27.6657 25.84 27.6657 26.7529 26.7529C27.6657 25.84 27.6657 24.36 26.7529 23.4471L23.3057 20L26.7529 16.5529C27.6657 15.64 27.6657 14.16 26.7529 13.2471C25.84 12.3343 24.36 12.3343 23.4471 13.2471L20 16.6943L16.5529 13.2471Z' fill='currentColor' />
    </svg>
  ),
})
