import { Layer } from 'effect'
import ListSourceRepoLive from 'features/ListBuilder/infra/repo/ListSourceRepoLive/ListSourceRepoLive'
import MarketingListMemberRepoLive from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/MarketingListMemberRepoLive'
import MarketingListRepoLive from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRepoLive'
import LocationServiceLive from 'features/ListBuilder/infra/services/LocationServiceLive/LocationServiceLive'
import SentryLoggerLive from 'features/ListBuilder/infra/services/SentryLoggerLive'

export const makeLiveLayers = () => {
  const layer = Layer.mergeAll(
    ListSourceRepoLive,
    MarketingListRepoLive,
    MarketingListMemberRepoLive,
    LocationServiceLive,
    SentryLoggerLive,
  )

  return layer
}
