import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useLeadId } from 'presentation/libs/LeadIdContext'

export const usePropertyDetailsOwners = () => {
  const routeLeadId = useLeadId()

  return usePropertyDetailsStore(api => {
    const propertyState = api.actions.getProperty.state

    const isReady = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    if (!isReady) return []

    const data = propertyState.data

    if (data.status === 'without-details') return []

    return data.ownership?.owners ?? []
  })
}
