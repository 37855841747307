import { Settings } from 'features/Settings/domain/Settings.domain'
import { getLocallyStoredSettings } from 'features/Settings/infra/remote/getLocallyStoredSettings'
import { LOCALLY_STORED_SETTINGS_KEY } from 'features/Settings/infra/remote/locallyStoredSettings.const'

export const updateLocallyStoredSettings = (payloadSettings: Settings.UpdateLocallyStoredSettingsPayload) => {
  const currentSettings = getLocallyStoredSettings()

  localStorage.setItem(LOCALLY_STORED_SETTINGS_KEY, JSON.stringify({
    ...currentSettings,
    ...payloadSettings,
  }))
}
