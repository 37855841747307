import { createIcon } from '@chakra-ui/react'

export const MapMarkerIcon = createIcon({
  displayName: 'MapMarkerIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
    >
      <path
        d='M19.5 10.334C19.5 16.1673 12 21.1673 12 21.1673C12 21.1673 4.5 16.1673 4.5 10.334C4.5 8.34486 5.29018 6.43721 6.6967 5.03068C8.10322 3.62416 10.0109 2.83398 12 2.83398C13.9891 2.83398 15.8968 3.62416 17.3033 5.03068C18.7098 6.43721 19.5 8.34486 19.5 10.334Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 12.834C13.3807 12.834 14.5 11.7147 14.5 10.334C14.5 8.95327 13.3807 7.83398 12 7.83398C10.6193 7.83398 9.5 8.95327 9.5 10.334C9.5 11.7147 10.6193 12.834 12 12.834Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
