import { gql } from '__generated__'

const GET_OWNERS_DEALS = gql(/* GraphQL */`

query GetOwnerDeals($parcelContactId: ID!) {
  currentlyOwnedDeals: parcelContact(id: $parcelContactId) {
    __typename

    ... on ParcelContact {
      id
      buyerDetails {
        historicalDeals(first: 50, filters: { limitToCurrentlyOwned: true }) {
          edges {
            node {
              ...NearbyBuyersDealDetails
              parcel {
                id
                ...NearbyBuyersParcelDetails
              }
            }
          }
        }
      }
    }
  }

  historicalDeals: parcelContact(id: $parcelContactId) {
    __typename

    ... on ParcelContact {
      id
      buyerDetails {
        historicalDeals(first: 50, filters: { limitToCurrentlyOwned: false }) {
          edges {
            node {
              ...NearbyBuyersDealDetails
              parcel {
                id
                ...NearbyBuyersParcelDetails
              }
            }
          }
        }
      }
    }
  }
}

`)

export default GET_OWNERS_DEALS
