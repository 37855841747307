import { FieldsError, MergedFieldsErrorsArray, MergedFieldsErrorsFields, ToErrorFields } from 'libs/errors/FieldsError'

const partialErrorTag = Symbol('partialErrorTag')

/**
 * @IMPORTANT PartialError is like FieldsError but specifically indicates that
 *   operation succeeded for subset of fields.
 *
 *   In contrast to FieldsError, which is more general error for forms.
 */

export class PartialError<Fields extends object | any[]> extends FieldsError<Fields> {
  [partialErrorTag] = true

  constructor(
    public message: string,
    public fields: ToErrorFields<Fields>,
  ) {
    super(message, fields)
  }
}

/** PartialError but param is unconstrained */
type PartialErrorConditionally<T> =

  T extends object | any[]
    ? PartialError<T>
    : never

export type MergedFieldsErrorsToParital<FE extends FieldsError<any>> =
  PartialErrorConditionally<MergedFieldsErrorsFields<FE>>

// Merges field errors to Partial
export function mergeFieldsErrorsToPartial<Arr extends FieldsError<any>[]>(errors: Arr): MergedFieldsErrorsArray<Arr> {
  let mergedFields: any = {}

  for (const error of errors)
    mergedFields = { ...mergedFields, ...error.fields }

  // Assumes that all error messages are identical. If not, you'll need a strategy for merging them
  return new FieldsError(errors[0]?.message || '', mergedFields) as any
}
