import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_CONDO_ICON_W = 32

export const PropTypeCondoIcon = createIcon({
  displayName: 'PropTypeCondoIcon',
  viewBox: `0 0 ${PROP_TYPE_CONDO_ICON_W} 30`,
  path: (
    <>
      <rect x='8.625' y='1.625' width='14.75' height='27.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='23.625' y='14.625' width='6.75' height='14.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='1.625' y='14.625' width='6.75' height='14.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='11.625' y='11.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='11.625' y='5.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='11.625' y='17.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='3.625' y='17.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='25.625' y='17.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='17.625' y='11.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='17.625' y='5.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='17.625' y='17.625' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='14.625' y='23.625' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
    </>
  ),
})
