import { Schema as S } from '@effect/schema'

export const GETImportProgressSchema = S.Struct({
  id: S.String,
  name: S.String,
  type: S.Literal(
    'national-leads-cashbuyers',
    'national-leads-privatelenders',
    'national-leads-sellers',
    'drive',
  ),
  createdAt: S.Date,
  updatedAt: S.NullishOr(S.Date),
  importProgress: S.OptionFromNullishOr(S.Struct({
    count: S.Number,
    createdAt: S.NullishOr(S.Date),
    updatedAt: S.NullishOr(S.Date),
    total: S.NullishOr(S.Number),
  }), undefined),
  memberStats: S.Struct({
    total: S.NullishOr(S.Number),
  }),
})

export type GETImportProgressSchema = S.Schema.Type<typeof GETImportProgressSchema>
