import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { GET_SEEKERS_QUERY } from 'features/SearchHistory/infra/SeekersQuery/getSeekers.graphql'
import { mapSeekers } from 'features/SearchHistory/infra/SeekersQuery/mapSeekers'
import { apolloClient } from 'presentation/libs/client'

export const getSeekers = async (): Promise<{ seekers: SearchHistory.Seeker[] }> =>
  await apolloClient.query({
    query: GET_SEEKERS_QUERY,
  })
    .then(({ data }) => ({
      /** Just for snapshotting purposes */
      __original: data,
      seekers: mapSeekers(data),
    }))
