import { chakra } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { HomeTypeFilterOptionProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption.props'
import { HomeTypeAnyIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeAnyIcon'
import { HomeTypeCommercialIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeCommercialIcon'
import { HomeTypeCondoIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeCondoIcon'
import { HomeTypeDuplexFourplex } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeDuplexFourplex'
import { HomeTypeFarmIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeFarmIcon'
import { HomeTypeLotLandIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeLotLandIcon'
import { HomeTypeMobileIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeMobileIcon'
import { HomeTypeMultiFamIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeMultiFamIcon'
import { HomeTypeSingleFamIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeSingleFam'
import { HomeTypeTownHouseIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeTownHouseIcon'

const HOME_TYPE_ICON_STROKE_DASH_ARRAY = '4 4'

const HOME_TYPE_LG_ICON_SIZE = '60px'

const HOME_TYPE_SM_ICON_SIZE = '40px'

export const HomeTypeIcon = (props: HomeTypeFilterOptionProps) => {
  const color = props.isSelected
    ? 'neutral.500'
    : 'grayweak.900'

  const strokeDashArray = props.isSelected
    ? 0
    : HOME_TYPE_ICON_STROKE_DASH_ARRAY

  const boxSize = pipe(
    Match.value(props.size),
    Match.when('lg', () => HOME_TYPE_LG_ICON_SIZE),
    Match.when('sm', () => HOME_TYPE_SM_ICON_SIZE),
    Match.exhaustive,
  )

  return (
    <chakra.svg
      width={boxSize}
      height={boxSize}
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/**
       * ========================
       * Border
       * ========================
       */}
      <chakra.circle
        cx='30'
        cy='30'
        r='29'
        stroke={color}
        strokeWidth='2'
        strokeLinejoin='round'
        strokeDasharray={strokeDashArray}
      />

      {/**
       * ========================
       * Icon
       * ========================
       */}
      {pipe(
        Match.value(props.variation),
        Match.when('town-house', () => <HomeTypeTownHouseIcon color={color} />),
        Match.when('multi-family', () => <HomeTypeMultiFamIcon color={color} />),
        Match.when('lot-or-land', () => <HomeTypeLotLandIcon color={color} />),
        Match.when('commercial', () => <HomeTypeCommercialIcon color={color} />),
        Match.when('single-family', () => <HomeTypeSingleFamIcon color={color} />),
        Match.when('condo', () => <HomeTypeCondoIcon color={color} />),
        Match.when('mobile', () => <HomeTypeMobileIcon color={color} />),
        Match.when('farm', () => <HomeTypeFarmIcon color={color} />),
        Match.when('duplex-to-fourplex', () => <HomeTypeDuplexFourplex color={color} />),
        Match.when(null, () => <HomeTypeAnyIcon color={color} />),
        Match.orElse(() => null),
      )}
    </chakra.svg>
  )
}
