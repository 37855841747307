import * as S from '@effect/schema/Schema'
import { Match } from 'effect'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'

const schema = S.Literal(
  'national-leads-cashbuyers',
  'national-leads-privatelenders',
  'national-leads-sellers',
  'drive',
)

export const ListTypeSchema = {
  schema,
  toDomain: (encoded: ListTypeSchema) =>
    Match.value(encoded).pipe(
      Match.when('national-leads-sellers', (): ListType => 'property-lists'),
      Match.when('national-leads-cashbuyers', (): ListType => 'cash-buyers'),
      Match.when('national-leads-privatelenders', (): ListType => 'private-lenders'),
      Match.when('drive', (): ListType => 'drive'),
      Match.exhaustive,
    ),

}

export type ListTypeSchema = S.Schema.Type<typeof schema>
