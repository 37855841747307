import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { FundsProviderLive } from 'features/Funds/infra/react/FundsProviderLive'
import { makeLiveLayers } from 'features/ListBuilder/infra/makeLiveLayers'
import * as ListBuilderStore from 'features/ListBuilder/infra/react/ListBuilderStore'
import { ListBuilderStoreLayer } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStoreDeps'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { SettingsProvider } from 'features/Settings/infra/react/Settings.state.react.production'
import AppRuntime from 'main/AppRuntime'
import AppRuntimeLayer from 'main/AppRuntimeLayer'
import { ComposeComponents } from 'presentation/components/ComposeComponents/ComposeComponents'
import { ScrollSpyProvider } from 'presentation/components/ScrollSpy'
import { setupDomainEventSubscribers } from 'presentation/events/subscribers'
import { LeadIdProvider } from 'presentation/libs/LeadIdContext'
import { apolloClient } from 'presentation/libs/client'
import { StripeProvider } from 'presentation/libs/stripe'
import { DevHelpers } from 'presentation/main/DevHelpers'
import { BoundAccessProvider } from 'presentation/main/Router/BoundAccessProvider'
import 'presentation/main/globalModals/globalModals.register'
import 'presentation/main/imports'
import { darkTheme } from 'presentation/main/themes/dark/dark.theme'
import lightTheme from 'presentation/main/themes/light/light.theme'
import { ComponentType, FC, ReactNode, useEffect } from 'react'
import { Router } from './Router'
import './setups'

const listBuilderStoreDeps: ListBuilderStoreLayer = makeLiveLayers()

setupDomainEventSubscribers()

export const App: FC = () => (
  <DevHelpers>
    <ComposeComponents
      providers={[
        [ApolloProvider, { client: apolloClient }],
        SettingsProvider,
        BoundAccessProvider,
        FundsProviderLive,

        /**
         * @TODO Consider just making list builder able to work without context,
         *   so that it's not included all the time
         */
        [ListBuilderStore.Provider, listBuilderStoreDeps],
        BoundChakraProvider as ComponentType,
        StripeProvider,
        ScrollSpyProvider,
        LeadIdProvider,
        [AppRuntime.Provider, { layers: AppRuntimeLayer.makeLive() }],
      ]}
    >
      <Router />
    </ComposeComponents>
  </DevHelpers>
)

const BoundChakraProvider = ({ children }: { children: ReactNode }) => {
  const theme = useSettingsStore(state => state.settingsState.settings.theme)
  const getSettings = useSettingsStore(state => state.actions.getSettings.execute)
  useEffect(() => {
    void getSettings()
  }, [])
  return (
    <ChakraProvider
      theme={theme === 'light' ? lightTheme : darkTheme}
      /**
       * Setting this allows you to create nested ChakraProvider with different
       * theme with different cssVarsRoot with less issues.
       * @see https://github.com/chakra-ui/chakra-ui/discussions/5180
       */
      cssVarsRoot='body'
    >
      {children}
    </ChakraProvider>
  )
}
