import { isNullable } from 'effect/Predicate'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { HomeTypeFilterDropdownDumb } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/HomeTypeFilterDropdown/HomeTypeFilterDropdown.dumb'
import { HomeTypeFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/HomeTypeFilterDropdown/HomeTypeFilterDropdown.dumb.props'
import { shallow } from 'zustand/shallow'

export const HomeTypeFilterDropdown = () => {
  const value = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report))
      return null

    return state.local.report.data.filters.homeType
  }, shallow)

  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: HomeTypeFilterDropdownDumbProps['onChange'] = value => {
    void filterComps({ homeType: value })
  }

  if (isNullable(value)) return null

  return (
    <HomeTypeFilterDropdownDumb
      value={value}
      onChange={handleOnValueChange}
      subjectValue={null}
      filterErrorMsg=''
    />
  )
}
