import { Box, HStack, Text } from '@chakra-ui/react'
import { Option, pipe } from 'effect'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { shallow } from 'zustand/shallow'

const ensureNumberOrNull = NumberLib.ensureNumberOrNull

export const FinancialsSubsection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return null

    return property.status === 'with-details'
      ? {
        equity: ensureNumberOrNull(property.valuation?.equity),
        lastSaleAmount: ensureNumberOrNull(property.lastSale?.salePrice),
        lastsaleDate: property.lastSale?.saleDate || null,
        estimatedPayment: ensureNumberOrNull(
          property.currentLiens?.reduce<null | number>((acc, lien) => {
            if (lien.estimatedPayment !== null)
              return NumberLib.ensureOr(0)(acc) + lien.estimatedPayment
            return acc
          }, null),
        ),
        estimatedBalance: ensureNumberOrNull(
          property.currentLiens?.reduce<null | number>((acc, lien) => {
            if (lien.estimatedBalance !== null)
              return NumberLib.ensureOr(0)(acc) + lien.estimatedBalance
            return acc
          }, null),
        ),
        interestRate: ensureNumberOrNull(
          property.currentLiens?.reduce<null | number>((acc, lien) => {
            if (lien.interestRatePercent !== null)
              return NumberLib.ensureOr(0)(acc) + lien.interestRatePercent

            return acc
          }, null),
        ),
        originalLoanAmount: ensureNumberOrNull(
          property.currentLiens?.reduce<null | number>((acc, lien) => {
            if (lien.amount !== null)
              return NumberLib.ensureOr(0)(acc) + lien.amount

            return acc
          }, null),
        ),
        loansCount: property.currentLiens?.length ?? 0,
      }
      : {
        equity: null,
        lastSaleAmount: null,
        lastsaleDate: null,
        estimatedPayment: null,
        estimatedBalance: null,
        originalLoanAmount: null,
        interestRate: null,
        loansCount: null,
      }
  }, shallow)

  if (!data) return null

  return (
    <Box>
      <Text textStyle='bodyLFat' color='graystrong.500'>
        Estimated Financials
      </Text>

      <Box
        mt={3}
        borderRadius={2}
        boxShadow='primary.w'
        overflow='hidden'
      >
        <Row
          value={Dollar.formatNumberWithCommasOrDoubleDash(data.lastSaleAmount)}
          label='Last Sale Price'
        />
        <Row
          value={DateLib.format.fallback('--')('MM/dd/yyyy')(data.lastsaleDate)}
          label='Last Sale Date'
        />
        <Row
          value={Dollar.formatNumberWithCommasOrDoubleDash(data.estimatedPayment)}
          label='Estimated Payment'
        />
        <Row
          value={Dollar.formatNumberWithCommasOrDoubleDash(data.estimatedBalance)}
          label='Estimated Balance'
        />
        <Row
          value={Dollar.formatNumberWithCommasOrDoubleDash(data.equity)}
          label='Estimated Equity'
        />
        <Row
          value={Dollar.formatNumberWithCommasOrDoubleDash(data.originalLoanAmount)}
          label='Original Loan Amount'
        />
        <Row
          value={pipe(
            Option.fromNullable(data.interestRate),
            Option.map(v => `${v.toFixed(2)}%`),
            Option.getOrElse(() => '--'),
          )}
          label='Interest Rate'
        />
        <Row
          value={data.loansCount?.toString() ?? '--'}
          label='No. of Active Loans'
        />
      </Box>
    </Box>
  )
}

const Row = (props: { label: string, value: string | null }) => (
  <HStack
    minH='5'
    justify='space-between'
    _odd={{
      bg: 'highlight.100',
    }}
    _even={{
      bg: 'highlight.200',
    }}
    px='2'
    spacing='2'
  >
    <Text textStyle='bodyMFat' color='modal.text'>
      {props.label}
    </Text>
    <Text textStyle='bodyL' color='graystrong.600'>
      {props.value}
    </Text>
  </HStack>
)
