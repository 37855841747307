import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { DateLib } from 'libs/Date'

export const patchDates = (
  propertyDetails: Domain.PropertyWithDetails,
): Domain.PropertyWithDetails => {
  if (propertyDetails.lastSale)
    propertyDetails.lastSale.saleDate = DateLib.fixDateStringOrNull(propertyDetails.lastSale?.saleDate)

  propertyDetails.transfers.forEach(transfer => {
    transfer.saleDate = DateLib.fixDateStringOrNull(transfer?.saleDate)
    transfer.recordingDate = DateLib.fixDateStringOrNull(transfer?.recordingDate)
  })

  return propertyDetails
}
