import { createIcon } from '@chakra-ui/react'

export const CloseCircleIcon = createIcon({
  displayName: 'CloseCircleIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10ZM15 9l-6 6M9 9l6 6' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
