import { useBreakpointValue } from '@chakra-ui/react'
import { BREAKPOINT_ENTRIES_ORDERED, BREAKPOINTS, Breakpoint as BreakpointType } from 'presentation/main/themes/common/breakpoints.theme'
import { fromPairs } from 'remeda'

export namespace Breakpoint {
  /** mobSm is 0, mob is 1, ... dtLg is 6 */
  export const useCurrent = () => {
    const current = useBreakpointValue({
      base: BREAKPOINTS[0],
      ...fromPairs(BREAKPOINTS.map(key => [key, key])),
    }, { ssr: false }) || BREAKPOINTS[0]

    return current
  }

  /** mobSm is 0, mob is 1, ... dtLg is 6 */
  export const toIndex = (bp: BreakpointType) =>
    BREAKPOINTS.findIndex(key => key === bp)

  export const widthToBreakpoint = (width: number) =>
    BREAKPOINT_ENTRIES_ORDERED.toReversed().find(([_, w]) => width >= w)?.[0] || BREAKPOINTS[0]

  export const isGreaterThan = (a: BreakpointType, b: BreakpointType) =>
    toIndex(a) > toIndex(b)
  export const isGreaterThanOrEqual = (a: BreakpointType, b: BreakpointType) =>
    toIndex(a) >= toIndex(b)
  export const isLessThan = (a: BreakpointType, b: BreakpointType) =>
    toIndex(a) < toIndex(b)
  export const isLessThanOrEqual = (a: BreakpointType, b: BreakpointType) =>
    toIndex(a) <= toIndex(b)

  export const getCurrentBreakpoint = () => widthToBreakpoint(window.innerWidth)

  export const isCurrentAtLeast = (bp: BreakpointType) =>
    isGreaterThanOrEqual(getCurrentBreakpoint(), bp)

  export const isCurrentLessThan = (bp: BreakpointType) =>
    isLessThan(getCurrentBreakpoint(), bp)

  export const isCurrentAtMost = (bp: BreakpointType) =>
    isLessThanOrEqual(getCurrentBreakpoint(), bp)

  export const isCurrentGreaterThan = (bp: BreakpointType) =>
    isGreaterThan(getCurrentBreakpoint(), bp)

}

export type Breakpoint = BreakpointType
