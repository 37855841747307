import { FeatureCollection, Polygon } from '@turf/helpers'
import { CMA } from 'features/CMA/CMA.domain'

export const geoJSONToCompsBoundary = (
  geoJSON: FeatureCollection<Polygon>,
): CMA.Filters.Boundary | null => {
  if (geoJSON.type !== 'FeatureCollection')
    return null

  const features = geoJSON.features
  if (features.length === 0)
    return null

  const lines: CMA.Filters.Line[] = []

  features.forEach(feature => {
    if (feature.geometry.type !== 'Polygon')
      return

    const coordinates = feature.geometry.coordinates
    if (!Array.isArray(coordinates) || coordinates.length === 0)
      return

    coordinates.forEach((polygon: number[][]) => {
      lines.push(
        polygon.map(([longitude, latitude]) => ({ longitude, latitude })),
      )
    })
  })

  return { lines }
}
