import { CQ } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib, mergeFieldsErrorsToPartial, PartialError, ProgramException } from 'libs/errors'
import { UpdateMemberCommand, UpdateMemberError, UpdateMemberPayload } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { useUpdateMemberAvatarCommand } from 'presentation/screens/MembersScreen/useCases/command/UpdateMemberCommand/private/UpdateMemberAvatarCommand'
import { useUpdateMemberEmailCommand } from 'presentation/screens/MembersScreen/useCases/command/UpdateMemberCommand/private/UpdateMemberEmailCommand'
import { useUpdateMemberNameCommand } from 'presentation/screens/MembersScreen/useCases/command/UpdateMemberCommand/private/UpdateMemberNameCommand'
import { useUpdateMemberPhoneCommand } from 'presentation/screens/MembersScreen/useCases/command/UpdateMemberCommand/private/UpdateMemberPhoneCommand'
import { useCallback } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

/**
 * Facade of the following commands:
 * - useUpdateMemberEmailCommand
 * - useUpdateMemberNameCommand
 * - useUpdateMemberPhoneCommand
 * - useUpdateMemberAvatarCommand
 */
export const useUpdateMemberCommand = (): UpdateMemberCommand => {
  const nameCommand = useUpdateMemberNameCommand()
  const emailCommand = useUpdateMemberEmailCommand()
  const phoneCommand = useUpdateMemberPhoneCommand()
  const avatarCommand = useUpdateMemberAvatarCommand()

  const { state, transition } = CQReact.useStateManager<UpdateMemberError, UpdateMemberPayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<UpdateMemberCommand['execute']>(async payload => {
    const commandId = regenerateId()

    try {
      transition.loading({
        id: commandId,
        payload,
      })

      const {
        id,
        firstName: firstname,
        lastName: lastname,
        email,
        phone,
        avatarFile,
      } = payload

      const promises = [
        (firstname || lastname) ? nameCommand.execute({ id, firstname, lastname }) : null,
        email ? emailCommand.execute({ id, email }) : null,
        phone ? phoneCommand.execute({ id, phone }) : null,
        avatarFile ? avatarCommand.execute({ id, avatarFile }) : null,
      ]
        .filter(isNonNullable)

      const results = await Promise.all(promises)

      const errorResults = results.filter(CQ.isError)

      if (errorResults.length) {
        const errors = errorResults.map(r => r.error)
        const mergedErrors = mergeFieldsErrorsToPartial(errors)
        throw mergedErrors
      }

      return transition.success({ id: commandId, payload })
    } catch (rawErr) {
      void ErrorLib.report(rawErr)

      const error = rawErr instanceof PartialError
        ? rawErr
        : new ProgramException('An error occurred while updating the member')

      return transition.error({ id: commandId, error, payload })
    }
  }, [])

  return {
    execute,
    state,
  }
}
