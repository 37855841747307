// eslint-disable-next-line no-restricted-imports
import { Box, Image, Modal, ModalContent, ModalOverlay, Stack } from '@chakra-ui/react'
import { debounce } from 'lodash/fp'
import { ArrowLeftIcon, CloseIcon } from 'presentation/components/Icons'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { Slider, SliderButton, SliderIndicator, SliderNav } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/Slider.common'
import { SliderLogic } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/Slider.logic'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import Swiper from 'swiper'
import { Mousewheel, Thumbs } from 'swiper/modules'
import { Swiper as SwiperComponent, SwiperProps, SwiperSlide } from 'swiper/react'

type SliderMaximizedModalProps = {
  onClose: () => void
  isOpen: boolean
  photos: SliderLogic.Photo[]
  initialSlide?: number
}

export const SliderMaximizedModal = (props: SliderMaximizedModalProps) => {
  const [renderKey, setRenderKey] = useState(0)

  // @HACK Prevents swiper error when reopening the modal via rerendering
  useEffect(() => {
    if (!props.isOpen)
      setRenderKey(prev => prev + 1)
  }, [props.isOpen])

  // @HACK Prevents screwed up swiper design on resize via rerendering
  useEffect(() => {
    const updateRenderKey = debounce(100, () => {
      setRenderKey(prev => prev + 1)
    })

    window.addEventListener('resize', updateRenderKey)

    return () => {
      window.removeEventListener('resize', updateRenderKey)
    }
  }, [])

  return (
    <SliderMaximizedModalBase
      key={renderKey}
      {...props}
    />
  )
}

const SliderMaximizedModalBase = ({
  photos,
  initialSlide,
  ...props
}: SliderMaximizedModalProps) => {
  const [thumbSwiper, setThumbsSwiper] = useState<Swiper | null>(null)

  return (
    <SliderMaximizedModalContainer {...props}>
      <SliderLogic.Provider photos={photos}>
        <Stack
          spacing='0.5'
          w='100vw'
          h='app-height'
          alignItems='stretch'
          justifyContent='stretch'
          direction={mbp({ mobSm: 'column', dtSm: 'row' })}
        >
          <MainSlider
            initialSlide={initialSlide}
            onClose={props.onClose}
            thumbs={{ swiper: thumbSwiper }}
          />
          <Thumbnails
            setThumbsSwiper={setThumbsSwiper}
          />
        </Stack>
      </SliderLogic.Provider>
    </SliderMaximizedModalContainer>
  )
}

export const MainSlider = ({
  onClose,
  ...props
}: SwiperProps & {
  onClose: () => void
}) => {
  const { photos } = SliderLogic.useContext()
  const { sbp } = useSwitchBreakpointFn()
  const dimensions = sbp(THUMB_DIMEN_BY_BP) || THUMB_DIMEN_BY_BP.mobSm

  return (
    <Slider
      slidesPerView={1}
      loop={!!photos.length}
      modules={[Thumbs]}
      style={{
        position: 'relative',
        flex: 1,
        ...sbp({
          mobSm: {
            width: '100vw',
          },
          dtSm: {},
        }),
      }}
      {...props}
    >
      {photos.map((photo, i) => (
        <SwiperSlide
          key={i}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src={photo.url}
            alt={photo.caption || ''}
            objectFit='contain'
            {...mbpg({
              mobSm: {
                maxH: `calc(var(--app-height) - ${dimensions.height} + 4px))`,
              },
              dtSm: {
                maxH: 'var(--app-height)',
              },
            })}
          />
        </SwiperSlide>
      ))}

      <SliderNav />
      <SliderIndicator />
      <SliderCloseButton onClose={onClose} />
      <SliderBackButton onClose={onClose} />
    </Slider>
  )
}

export const Thumbnails = ({
  setThumbsSwiper,
}: {
  setThumbsSwiper: (swiper: Swiper) => void
}) => {
  const { photos } = SliderLogic.useContext()
  const { sbp } = useSwitchBreakpointFn()
  const dimensions = sbp(THUMB_DIMEN_BY_BP) || THUMB_DIMEN_BY_BP.mobSm

  return (
    <Box
      sx={{
        '.swiper-slide': {
          opacity: 0.3,
        },
        '.swiper-slide-thumb-active': {
          opacity: 1,
        },
      }}
    >
      <SwiperComponent
        onSwiper={setThumbsSwiper}
        spaceBetween={4}
        watchSlidesProgress={true}
        slidesPerView='auto'
        modules={[Thumbs, Mousewheel]}
        direction={sbp({ mobSm: 'horizontal', dtSm: 'vertical' })}
        style={sbp({
          mobSm: {
            height: dimensions.height,
            width: '100vw',
          },
          dtSm: {
            height: 'var(--app-height)',
          },
        })}
        freeMode={true}
        mousewheel
      >
        {photos.map((photo, i) => (
          <SwiperSlide
            key={i}
            style={{
              ...dimensions,
              cursor: 'pointer',
            }}
          >
            <Image
              src={photo.url}
              alt={photo.caption || ''}
              objectFit='cover'
              {...dimensions}
            />
          </SwiperSlide>
        ))}
      </SwiperComponent>
    </Box>
  )
}

export const SliderMaximizedModalContainer = ({ children, ...props }: PropsWithChildren<{
  onClose: () => void
  isOpen: boolean
}>) => (
  <Modal {...props}>
    {/* Chris invented the design so no theme based color for now */}
    <ModalOverlay bg='#111111' opacity='1' />
    <ModalContent
      containerProps={{
        sx: useMemo(() => ({
          p: '0 !important',
          flex: '1 0 0',
          minH: '0',
        }), []),
      }}
      sx={{
        w: '100vw',
        maxW: '100vw',
        m: 0,
        p: '0 !important',
        borderRadius: 0,
        align: 'stretch',
        flex: '1 0 0',
        minH: '0',
        h: 'full',
        maxH: 'full',
      }}
    >
      {children}
    </ModalContent>
  </Modal>
)

const SliderCloseButton = ({
  onClose,
}: {
  onClose: () => void
}) => (
  <SliderButton
    aria-label='Close'
    icon={<CloseIcon />}
    pos='absolute'
    top={mbp({ mobSm: '1.5', mob: '2.5' })}
    right={mbp({ mobSm: '1.5', mob: '2.5' })}
    zIndex='1'
    onClick={onClose}
  />
)

const SliderBackButton = (props: { onClose: () => void }) => (
  <SliderButton
    aria-label='Back'
    variant='icon-ghost'
    icon={<ArrowLeftIcon />}
    pos='absolute'
    top={mbp({ mobSm: '1.5', mob: '2.5' })}
    left={mbp({ mobSm: '1.5', mob: '2.5' })}
    zIndex='1'
    onClick={props.onClose}
  />
)

const THUMB_DIMEN_BY_BP = {
  mobSm: {
    width: '72px',
    height: '40px',
  },
  tabSm: {
    width: '240px',
    height: '136px',
  },
}
