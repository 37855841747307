import { BuildListState, useListPreview } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useBuildListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useBuildListState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import LocationPath from 'presentation/libs/LocationPath'
import { useToastFailedState } from 'presentation/libs/hooks/useToastFailedState'
import ListPreviewDesktopScreen from 'presentation/screens/ListPreviewScreen/components/ListPreviewDesktopScreen'
import { ListPreviewMobile } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMobile'
import { ListPreviewTablet } from 'presentation/screens/ListPreviewScreen/components/ListPreviewTablet'
import { SaveListModal } from 'presentation/screens/ListPreviewScreen/components/SaveListModal'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-use'

export const ListPreviewScreen = () => {
  const location = useLocation()
  const parentPath = location.pathname && LocationPath.getParentPath(location.pathname)
  const listPreview = useListPreview()
  const navigate = useNavigate()

  useToastFailedState(
    useBuildListState(),
    {
      title: 'Failed to apply filters',
      message: TOAST_PRESET.GENERIC_ERROR.message,
    },
  )

  useEffect(() => {
    if (BuildListState.$is('Initial')(listPreview))
      navigate({ pathname: parentPath })
  }, [listPreview._tag])

  return (
    <>
      <Breakpoint
        mobSm={<ListPreviewMobile />}
        tabSm={<ListPreviewTablet />}
        dtLg={<ListPreviewDesktopScreen />}
      />
      <SaveListModal />
    </>
  )
}
