import { createIcon } from '@chakra-ui/react'

export const DownloadBoxIcon = createIcon({
  displayName: 'DownloadBoxIcon',
  viewBox: '0 0 25 24',
  path: (
    <g fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21.5 15V19C21.5 19.5304 21.2893 20.0391 20.9142 20.4142C20.5391 20.7893 20.0304 21 19.5 21H5.5C4.96957 21 4.46086 20.7893 4.08579 20.4142C3.71071 20.0391 3.5 19.5304 3.5 19V15' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 10L12.5 15L17.5 10' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 15V3' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
