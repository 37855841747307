import {
  chakra,
  forwardRef,
  SystemProps,
  HTMLChakraProps,
} from '@chakra-ui/system'
import { assignAfter } from '@chakra-ui/object-utils'

export type FlexOptions = {
  /**
   * Shorthand for `alignItems` style prop
   * @type SystemProps["alignItems"]
   */
  align?: SystemProps['alignItems']

  /**
   * Shorthand for `justifyContent` style prop
   * @type SystemProps["justifyContent"]
   */
  justify?: SystemProps['justifyContent']

  /**
   * Shorthand for `flexWrap` style prop
   * @type SystemProps["flexWrap"]
   */
  wrap?: SystemProps['flexWrap']

  /**
   * Shorthand for `flexDirection` style prop
   * @type SystemProps["flexDirection"]
   * @default "row"
   */
  direction?: SystemProps['flexDirection']

  /**
   * Shorthand for `flexBasis` style prop
   * @type SystemProps["flexBasis"]
   */
  basis?: SystemProps['flexBasis']

  /**
   * Shorthand for `flexGrow` style prop
   * @type SystemProps["flexGrow"]
   */
  grow?: SystemProps['flexGrow']

  /**
   * Shorthand for `flexShrink` style prop
   * @type SystemProps["flexShrink"]
   */
  shrink?: SystemProps['flexShrink']
}

export type FlexProps = {} & HTMLChakraProps<'div'> & FlexOptions

/**
 * All this code is copied from Chakra UI, except for commented part below
 */
export const ConfigurableChakraFlex = forwardRef<FlexProps, 'div'>((props, ref) => {
  const { direction, align, justify, wrap, basis, grow, shrink, ...rest }
    = props

  const styles = {
    display: 'flex',
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
  }
  /** This the only deviation from Chakra's Text */
  const configurableStyles = assignAfter(
    {},
    styles,
    props.__css,
  )

  return <chakra.div ref={ref} __css={configurableStyles} {...rest} />
})

ConfigurableChakraFlex.displayName = 'Flex'
