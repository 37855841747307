import { Grid, GridItem, GridProps } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { AffiliationLogos } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/AffiliationLogos'
import { EverytingYouNeedColumn } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/EverythingYouNeedColumn'
import { EverythingYouNeedHeroText } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/EverytingYouNeedHeroText'
import { PropelioLogo } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/PropelioLogo'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const EverythingYouNeedGrid = ({ children, ...props }: GridProps) => (
  <Grid
    bgColor='graycool.100'
    {...mbpg({
      mob: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gap: 4,
        py: 4,
        px: 3,
        alignItems: 'center',
        alignContent: 'center',
      },
      tabSm: {
        gridTemplateColumns: 'minmax(440px, 608px)',
        gap: 7,
        py: 7,
      },
      dtSm: {
        gridTemplateColumns: 'repeat(2, minmax(0, 440px))',
        alignItems: 'center',
        gap: 6,
      },
      dtLg: {
        gridTemplateColumns: '608px 593px',
        gap: '80px',
      },
    })}
    h='100%'
    minH={0}
    justifyContent='center'
    overflow='auto'
    {...props}
  >
    <Breakpoint
      mob={(
        <GridItem>
          <PropelioLogo mb={3} mx='auto' />
          <EverythingYouNeedHeroText />
        </GridItem>
      )}
      dtSm={<></>}
    />

    <GridItem>
      {children}
    </GridItem>

    <Breakpoint
      mob={<></>}
      dtSm={(
        <GridItem
          alignSelf='center'
          display={mbp({ mobSm: 'none', dtSm: 'block' })}
        >
          <EverytingYouNeedColumn />
        </GridItem>
      )}
    />

    <GridItem
      display={mbp({ mob: 'block', dtSm: 'none' })}
      justifySelf='center'
    >
      <AffiliationLogos
        gridTemplateColumns={mbp({
          mob: 'repeat(2, 1fr)',
          tabSm: 'repeat(4, 1fr)',
        })}
        gap={
          mbp({
            mob: '3',
            tabSm: '5',
          })
        }
      />
    </GridItem>
  </Grid>
)
