import { PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList/PropertyListDataId'
import { Address, Address as ImportedAddress } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { EquityType } from 'features/valueObjects/EquityType'
import { Location } from 'features/valueObjects/Location'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import ParcelId from 'features/valueObjects/ParcelId'
import { Photo } from 'features/valueObjects/Photo'
import { NullableProps } from 'libs/object'
import { NullProps } from 'libs/utils.types'
import { merge } from 'remeda'

export type PropertyListData = {
  // #region App Info
  id: PropertyListDataId
  parcelId: ParcelId | null
  // #endregion

  // #region Address Info
  address: NullProps<ImportedAddress & { subdivision: string }>
  location: Location | null
  // #endregion

  // #region Value Info
  lastSoldDate: Date | null
  estimatedValue: number | null
  estimatedEquity: number | null
  listPrice: number | null
  salePrice: number | null
  // #endregion

  // #region Property Info
  useType: string | null

  // #region Stats
  bedroomsCount: number | null
  bathroomsCountInfo: BathroomsCountInfo
  garageSpacesCount: number | null
  buildingAreaSqft: number | null
  livingAreaSqft: number | null
  lotAreaSqft: number | null
  lotAreaAcres: number | null
  yearBuilt: number | null
  // #endregion

  // #region Owner/Value Info
  ownerName: string | null
  ownerAddress: NullProps<Address>

  photos: Photo[]
  // #region Owner/Value Info
  classification: OwnerClassification | null
  equityType: EquityType | null
  isVacant: boolean | null
  isForeclosure: boolean | null
  isSenior: boolean | null
  isHomestead: boolean | null
  isTaxDelinquent: boolean | null

  // #region MLS Info
  mlsStatus: MLSStatus
  // #endregion
}

export type Self = PropertyListData

export type PropertyDataCreateParams =
  NullableProps<Omit<PropertyListData, 'id'>> & Pick<PropertyListData, 'id'>

export const PropertyListData = {
  make: (params: PropertyDataCreateParams): PropertyListData => {
    const DEFAULTS: PropertyListData = {
      // #region App Info
      id: params.id,
      parcelId: params.parcelId ? ParcelId.fromString(params.parcelId) : null,
      // #endregion

      // #region Address Info
      address: {
        city: '',
        line1: '',
        postalCode: '',
        state: '',
        subdivision: null,
      },
      location: { latitude: 0, longitude: 0 },
      // #endregion

      // #region Value Info
      lastSoldDate: new Date(),
      estimatedValue: 0,
      estimatedEquity: 0,
      listPrice: null,
      salePrice: null,
      // #endregion

      // #region Property Info
      useType: null,
      // #endregion

      // #region Stats
      bedroomsCount: null,
      bathroomsCountInfo: {
        total: null,
        full: null,
        half: null,
      },
      garageSpacesCount: null,
      buildingAreaSqft: null,
      livingAreaSqft: null,
      lotAreaSqft: null,
      lotAreaAcres: null,
      yearBuilt: null,
      // #endregion

      // #region Owner/Value Info
      ownerName: null,
      ownerAddress: { city: '', line1: '', postalCode: '', state: '' },
      photos: [],
      // #endregion

      // #region Owner/Value Info
      classification: null,
      equityType: null,
      isVacant: false,
      isForeclosure: false,
      isSenior: false,
      isHomestead: false,
      isTaxDelinquent: false,
      // #endregion

      // #region MLS Info
      mlsStatus: 'OFF_MARKET',
      // #endregion
    }

    return merge(DEFAULTS, params)
  },
}
