import { Box, Grid, Image, Text, VStack } from '@chakra-ui/react'
import { Option, Array, pipe, Match } from 'effect'
import handdrawnX1 from 'presentation/assets/img/handdrawn/x-1.png'
import handdrawnX2 from 'presentation/assets/img/handdrawn/x-2.png'
import handdrawnX3 from 'presentation/assets/img/handdrawn/x-3.png'
import cn from 'classnames'
import { bounceIn, fadeIn, flash } from 'presentation/utils/animationClasses'
import PriceFeatureRowViewModel from './PriceFeatureRowViewModel'

const PriceFeatureRow = () => {
  const vm = PriceFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
      h='224px'
    >
      <VStack justify='center' spacing='0'>
        <Text
          color='accent.darkgreen'
          textStyle={{
            base: 'body2-h-b',
            mob: 'h4-h-b',
            tabSm: 'h4-h-b',
            dtSm: 'h3-h-b',
            dtLg: 'h2-h-b',
          }}
        >
          Save up to
          {' '}
          {vm.saveUpToPriceText}
        </Text>
        <Text
          textStyle={{
            base: 'body2-b',
            mob: 'body2-b',
            dtSm: 'body-b',
            dtLg: 'h3-b',
          }}
          color='accent.darkgreen'
        >
          when you pay yearly!
        </Text>
      </VStack>

      {Array.map(vm.columnValues, (info, priceIndex) => (
        <VStack justify='center' spacing='0.5' key={priceIndex}>
          <Text
            fontSize={{
              base: '20px',
              mob: '24px',
              dtSm: '29px',
            }}
            lineHeight='110%'
            fontFamily='Inter'
            fontWeight='900'
            color={info.textColor}
            className={cn(vm.isYearly ? bounceIn : fadeIn)}
          >
            {info.primaryText}

            <Text
              as='span'
              textStyle={{
                base: 'old.h4',
                mob: 'h3-h',
              }}
              color='inherit'
            >
              {info.primaryTextPrefix}
            </Text>
          </Text>

          {info.secondaryText.pipe(
            Option.map(text => (
              <Text
                key='secondary-text'
                textStyle={{
                  base: 'body2-b',
                  dtSm: 'body-b',
                }}
                color='graystrong.200'
                className={cn(vm.isYearly && fadeIn)}
                sx={{ animationDelay: '0.6s' }}
              >
                {text}
              </Text>
            )),
            Option.getOrNull,
          )}

          {info.crossedOutText.pipe(
            Option.map(price => (
              <Text
                key='crossed-out-text'
                as={Box}
                textStyle={{
                  base: 'body-b',
                  mob: 'h3-b',
                  dtSm: 'h2-b',
                }}
                color='graystrong.200'
                pos='relative'
                textAlign='center'
                className={cn(vm.isYearly && fadeIn)}
                sx={{ animationDelay: '1s' }}
              >
                {price}
                {pipe(
                  Match.value(priceIndex),
                  Match.when(1, () => <CrossOut w='91px' src={handdrawnX1} topOffset='2px' />),
                  Match.when(2, () => <CrossOut w='56px' src={handdrawnX2} topOffset='2px' />),
                  Match.when(3, () => <CrossOut w='86px' src={handdrawnX3} />),
                  Match.orElse(() => null),
                )}
              </Text>
            )),
            Option.getOrNull,
          )}

          <Text
            // Force re-render on interval change, to retrigger animation
            key={vm.isYearly ? 'yearly' : 'monthly'}
            textStyle={{
              mob: info.isPopular ? 'body2-h-b' : 'body2-b',
              dtSm: info.isPopular ? 'body-h-b' : 'body-b',
            }}
            textTransform={info.isPopular ? 'uppercase' : 'none'}
            color={info.textColor}
            textAlign='center'
            display={{ base: 'none', mob: 'block' }}
            className={fadeIn}
            sx={{ animationDelay: vm.isYearly ? '1.4s' : '0.8s' }}
          >
            {info.sloganText}
          </Text>
        </VStack>
      ))}
    </Grid>
  )
}

export default PriceFeatureRow

type CrossOutProps = {
  w: string
  src: string
  topOffset?: string
}

const CrossOut = ({
  w,
  src,
  topOffset = '0px',
}: CrossOutProps) => (
  <Box
    w={w}
    pos='absolute'
    top={`calc(50% + ${topOffset})`}
    left='50%'
    transform={{
      base: 'translate(-50%, -50%) scale(0.8)',
      tabSm: 'translate(-50%, -50%)',
    }}
  >
    <Image
      w='full'
      src={src}
      alt='crossed out'
      display='block'
      className={flash}
      opacity='0'
      sx={{ animationDelay: '1.8s' }}
    />
  </Box>
)
