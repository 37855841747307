import { useMeasure } from 'react-use'

export const usePeekaboo = <E extends Element>({
  idealSize,
  spaceAround,
  spaceBetween,
  possibleTweaks = [-8, 8, 16, 24, 32],
  minPeekaboo = 24,
  minHiddenSize = 16,
}: {
  idealSize: number
  spaceAround: number
  spaceBetween: number
  possibleTweaks?: number[]
  minPeekaboo?: number
  minHiddenSize?: number
}) => {
  const [ref, { width: containerWidth }] = useMeasure<E>()

  type Scenario = {
    itemSize: number
    peekaboo: number
    hiddenSize: number
    isIdeal: boolean
    tweak: number
  }

  const getBetterScenario = (a: Scenario, b: Scenario): Scenario => {
    const isAHiddenSizeGood = a.hiddenSize >= minHiddenSize
    const isBHiddenSizeGood = b.hiddenSize >= minHiddenSize

    const isAPeekabooGood = a.peekaboo >= minPeekaboo
    const isBPeekabooGood = b.peekaboo >= minPeekaboo

    const isAGood = isAHiddenSizeGood && isAPeekabooGood
    const isBGood = isBHiddenSizeGood && isBPeekabooGood

    if (isAGood && a.isIdeal)
      return a

    if (isBGood && b.isIdeal)
      return b

    if (isAGood && !isBGood)
      return a

    const doesAHaveLessTweak = Math.abs(a.tweak) < Math.abs(b.tweak)
    if (isAGood && isBGood && doesAHaveLessTweak)
      return a

    return b
  }

  const allConfigs = [0, ...possibleTweaks]
    .map((tweak): Scenario => {
      const itemSize = idealSize + tweak
      const remainingSpace = containerWidth - spaceAround
      const spacePerItem = itemSize + spaceBetween
      const spacePerItemRemainder = remainingSpace % spacePerItem
      const hiddenSize = itemSize - spacePerItemRemainder
      const peekaboo = itemSize - hiddenSize
      const isIdeal = tweak === 0

      return {
        itemSize,
        peekaboo,
        hiddenSize,
        isIdeal,
        tweak,
      }
    })

  const bestConfig = allConfigs.reduce(getBetterScenario, allConfigs[0])

  return {
    containerRef: ref,
    containerWidth,
    ...bestConfig,
  }
}
