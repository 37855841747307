import { IconProps, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverContentProps, PopoverProps, PopoverTrigger, Portal } from '@chakra-ui/react'
import { RegularCircleInfoIcon } from 'presentation/components/Icons/regular'
import { PropsWithChildren } from 'react'

export type DetailDisclosurePopoverProps = PropsWithChildren<{
  iconProps?: IconProps
  popoverProps?: PopoverProps
  popoverContentProps?: PopoverContentProps
}>

const DetailDisclosurePopover = ({
  children,
  iconProps,
  popoverProps,
  popoverContentProps,
}: DetailDisclosurePopoverProps) => (
  <Popover {...popoverProps}>
    <PopoverTrigger>
      <RegularCircleInfoIcon
        color='inherit'
        {...iconProps}
      />
    </PopoverTrigger>
    <Portal>
      <PopoverContent
        _focusVisible={{
          outline: 'none',
        }}
        {...popoverContentProps}
        maxW='200px'
      >
        <PopoverArrow />
        <PopoverBody
          p='1'
          color='graystrong.400'
          textStyle='tagL'
        >
          {children}
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
)

export default DetailDisclosurePopover
