import { Match, pipe } from 'effect'

export type MLSStatus = typeof MLSStatus.ARRAY[number]

export namespace MLSStatus {
  export const ARRAY = [
    'FOR_SALE',
    'FOR_LEASE',
    'SALE_OR_LEASE',
    'PENDING',
    'SOLD',
    'LEASED',
    'EXPIRED',
    'CANCELED',
    'WITHDRAWN',
    'OFF_MARKET',
  ] as const

  export const toString = (status: MLSStatus) => pipe(
    Match.value(status),
    Match.when('FOR_SALE', () => 'For Sale'),
    Match.when('FOR_LEASE', () => 'For Lease'),
    Match.when('SALE_OR_LEASE', () => 'Sale/Lease'),
    Match.when('PENDING', () => 'Pending'),
    Match.when('SOLD', () => 'Sold'),
    Match.when('LEASED', () => 'Leased'),
    Match.when('EXPIRED', () => 'Expired'),
    Match.when('CANCELED', () => 'Canceled'),
    Match.when('WITHDRAWN', () => 'Withdrawn'),
    Match.when('OFF_MARKET', () => 'Off Market'),
    Match.exhaustive,
  )

  export const toArray = () => ARRAY
}
