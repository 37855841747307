import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const VacantIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.polygon
      fill='marker.cobaltblue'
      fillRule='nonzero'
      points='10.4602 7 13.4431 17.2557 13.5568 17.2557 16.5397 7 21 7 16.1988 21.5454 10.8011 21.5454 6 7'
    />
  </MarkerIconContainer>
)
