import { useMutation } from '@apollo/client'
import { Box, BoxProps, Button, Circle, Fade, Flex, Text } from '@chakra-ui/react'
import { gql } from '__generated__'
import { PaymentMethodCard_PaymentMethodFragmentFragment } from '__generated__/graphql'
import cn from 'classnames'
import dayjs from 'dayjs'
import { ChevronDownIcon, TrashIcon } from 'presentation/components/Icons'
import { CheckCircleIcon } from 'presentation/components/Icons/CheckCircleIcon'
import { toast } from 'presentation/components/Toast'
import { ToastParams } from 'presentation/components/Toast/Toast.types'
import { bounceIn } from 'presentation/utils/animationClasses'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, MouseEventHandler, useCallback, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { CardLogo } from './CardLogo/CardLogo'

export const PAYMENT_METHOD_CARD__PAYMENT_METHOD_FRAGMENT = gql(/* GraphQL */ `
  fragment PaymentMethodCard_PaymentMethodFragment on BillingCardsPaymentMethod {
    __typename
    id,
    cardHolderName,
    last4,
    brand,
    expiration,
    isDefault
  }
`)

type Props = BoxProps & {
  paymentMethod: PaymentMethodCard_PaymentMethodFragmentFragment
}

const Z_INDEX = {
  OVERLAY: 1,
  FLOATING_ICON: 2,
}

export const PaymentCard: FC<Props> = ({
  paymentMethod: fragment,
  boxShadow = 'primary.w',
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const [isClicked, setIsClicked] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  useClickAway(ref, () => {
    setIsHovered(false)
    setIsClicked(false)
  })

  const paymentMethod = fragment
  const { deleteMethod, ...deleteMethodApi } = useDeleteMethod(paymentMethod.id)
  const { makeMethodPrimary, ...makeMethodPrimaryApi } = useMakeMethodPrimary(paymentMethod.id)

  const wasInteracted = IS_TOUCH_DEVICE ? isClicked : isHovered
  const shouldShowOverlay = wasInteracted || makeMethodPrimaryApi.loading || deleteMethodApi.loading

  const handleMakeMethodPrimary: MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation()
    void makeMethodPrimary()
      .then(() => {
        setIsHovered(false)
        setIsClicked(false)
      })
  }

  const handleDismissOverlay: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    setIsHovered(false)
    setIsClicked(false)
  }

  return (
    <Flex
      ref={ref}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsClicked(true)}
      p={mbp({ mobSm: '2', mob: '3' })}
      boxShadow={boxShadow}
      borderRadius='2'
      bg={paymentMethod.isDefault ? 'special.300' : 'card.bg.2'}
      pos='relative'
      h={mbp({ mobSm: '147px', mob: '171px' })}
      flexDirection='column'
      alignItems='stretch'
      justifyContent='stretch'
      className={cn(paymentMethod.isDefault && bounceIn)}
      {...props}
    >
      {paymentMethod.isDefault && (
        <Circle
          size='3'
          bg='ondark.1'
          pos='absolute'
          top='-1'
          right='-1'
          zIndex={Z_INDEX.FLOATING_ICON}
        >
          <CheckCircleIcon
            color={shouldShowOverlay ? 'grayweak.900' : 'neutral.500'}
            boxSize='4'
          />
        </Circle>
      )}

      <Flex alignItems='center'>
        <Box mr='auto'>
          <CardLogo brand={paymentMethod.brand || undefined} w='47px' h='32px' />
        </Box>

        <Fade in={!shouldShowOverlay}>
          <Text
            textStyle='button-b'
            color={paymentMethod.isDefault ? 'ondark.1' : 'neutral.500'}
          >
            Options
            <ChevronDownIcon fontSize='2' ml='1' />
          </Text>
        </Fade>
      </Flex>

      <Text mt='2' textStyle={mbp({ mobSm: 'body-b', mob: 'h3-b' })} color={paymentMethod.isDefault ? 'ondark.1' : 'graystrong.400'} flex='1'>
        #### ####  ####
        {' '}
        {paymentMethod.last4}
      </Text>

      <Flex mt='2'>
        <Box flex='1'>
          <Text
            color={paymentMethod.isDefault ? 'ondark.1' : 'graystrong.200'}
            textStyle='body3'
          >
            Card Holder Name
          </Text>
          <Text
            color={paymentMethod.isDefault ? 'highlight.500' : 'graystrong.400'}
            textStyle='body2-xb'
          >
            {paymentMethod.cardHolderName}
          </Text>
        </Box>
        <Box>
          <Text
            color={paymentMethod.isDefault ? 'ondark.1' : 'graystrong.200'}
            textStyle='body3'
          >
            Expires
          </Text>
          <Text
            color={paymentMethod.isDefault ? 'highlight.500' : 'graystrong.400'}
            textStyle='body2-xb'
          >
            {dayjs(paymentMethod.expiration, 'M/YYYY').format('MM/YY')}
          </Text>
        </Box>
      </Flex>

      <Fade in={shouldShowOverlay} unmountOnExit>
        <Box
          bg='overlay.dark-am'
          pos='absolute'
          top='0'
          right='0'
          left='0'
          bottom='0'
          borderRadius='2'
          zIndex={Z_INDEX.OVERLAY}
          display='flex'
          alignItems='stretch'
          justifyContent='center'
          flexDir='column'
          px='3'
          gap='2'
          onClick={handleDismissOverlay}
        >
          <Text
            color='ondark.1'
            textStyle={mbp({ mobSm: 'body3-b', tabSm: 'body-b' })}
            textAlign='center'
          >
            Here are some options:
          </Text>
          {!deleteMethodApi.loading && (
            <Button
              mt={mbp({ mobSm: '-1', mob: '0' })}
              variant='solid'
              colorScheme='positive'
              size='sm'
              onClick={handleMakeMethodPrimary}
              isLoading={makeMethodPrimaryApi.loading}
              isDisabled={deleteMethodApi.loading || paymentMethod.isDefault}
              loadingText='Making primary'
              spinnerPlacement='end'
            >
              Make Primary
            </Button>
          )}

          {!makeMethodPrimaryApi.loading && (
            <Button
              variant='solid'
              colorScheme='negative'
              size='sm'
              leftIcon={<TrashIcon boxSize='3' />}
              onClick={deleteMethod}
              isLoading={deleteMethodApi.loading}
              isDisabled={makeMethodPrimaryApi.loading}
              loadingText='Deleting'
              spinnerPlacement='end'
            >
              Delete
            </Button>
          )}
        </Box>
      </Fade>
    </Flex>
  )
}

// =============================================================================
// Delete hook
// =============================================================================

export const PAYMENT_CARD__DELETE_PAYMENT_METHOD = gql(/* GraphQL */ `
  mutation PaymentCard__DeletePaymentMethod($paymentMethodId: String!) {
    billingRemovePaymentMethod(paymentMethodId: $paymentMethodId) {
      ...on Enterprise {
        id
        paymentMethods {
          edges {
            node {
              ... on BillingCardsPaymentMethod { id }
              ...PaymentMethodCard_PaymentMethodFragment
            }
          }
        }
      }
    }
  }
`)

const useDeleteMethod = (paymentMethodId: string) => {
  const [deleteMethodApi, { loading }] = useMutation(PAYMENT_CARD__DELETE_PAYMENT_METHOD)

  const deleteMethod = useCallback(async () => {
    await deleteMethodApi({ variables: { paymentMethodId } })
      .then(({ errors, data }) => {
        if (errors?.[0])
          toast(DELETE_METHOD_ERROR_TOAST)

        if (data)
          toast(DELETE_METHOD_SUCCESS_TOAST)
      })
  }, [paymentMethodId])

  return { deleteMethod, loading }
}

const DELETE_METHOD_ERROR_TOAST: ToastParams = {
  status: 'error',
  title: 'We couldn’t delete your payment method',
}

const DELETE_METHOD_SUCCESS_TOAST: ToastParams = {
  status: 'success',
  title: 'Successfully deleted payment method',
}

// =============================================================================
// Make method primary hook
// =============================================================================

export const PAYMENT_CARD__MAKE_METHOD_PRIMARY_METHOD = gql(/* GraphQL */ `
  mutation PaymentCard_MakePaymentMethodPrimary($paymentMethodId: String!) {
    billingSetDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      ...on Enterprise {
        id
        paymentMethods {
          edges {
            node {
              ... on BillingCardsPaymentMethod { id }
              ...PaymentMethodCard_PaymentMethodFragment
            }
          }
        }
      }
    }
  }
`)

const useMakeMethodPrimary = (paymentMethodId: string) => {
  const [makeMethodPrimaryApi, { loading }] = useMutation(PAYMENT_CARD__MAKE_METHOD_PRIMARY_METHOD)

  const makeMethodPrimary = useCallback(async () => {
    await makeMethodPrimaryApi({ variables: { paymentMethodId } })
      .then(({ errors, data }) => {
        if (errors?.[0])
          toast(MAKE_METHOD_PRIMARY_ERROR_TOAST)

        if (data)
          toast(MAKE_METHOD_PRIMARY_SUCCESS_TOAST)
      })
  }, [paymentMethodId])

  return { makeMethodPrimary, loading }
}

const MAKE_METHOD_PRIMARY_ERROR_TOAST: ToastParams = {
  status: 'error',
  title: 'We couldn’t make your payment method primary',
}

const MAKE_METHOD_PRIMARY_SUCCESS_TOAST: ToastParams = {
  status: 'success',
  title: 'Successfully updated your primary card',
}
