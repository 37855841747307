import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const BankOwnedIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M12.2524544,3.2702187 C13.5107544,2.2098287 15.3347544,2.2483587 16.5494544,3.3609887 L24.5754544,10.7127487 C26.3383544,12.3276487 25.4114544,15.2320487 23.2170544,15.6024487 L23.2170544,23.2414487 L24.3180544,23.2414487 C24.9261544,23.2414487 25.4190544,23.7470487 25.4190544,24.3707487 C25.4190544,24.9944487 24.9261544,25.4999487 24.3180544,25.4999487 L4.49993439,25.4999487 C3.89187439,25.4999487 3.39893439,24.9944487 3.39893439,24.3707487 C3.39893439,23.7470487 3.89187439,23.2414487 4.49993439,23.2414487 L5.60094439,23.2414487 L5.60094439,15.6416487 L5.25798439,15.6416487 C2.67403439,15.6416487 1.51412039,12.3196487 3.51061439,10.6371487 L12.2524544,3.2702187 Z M22.7419544,13.3831487 C23.2475544,13.3831487 23.4857544,12.7427487 23.1086544,12.3973487 L15.0826544,5.0455587 C14.6777544,4.6746787 14.0697544,4.6618387 13.6503544,5.0152987 L4.90851439,12.3822487 C4.50920439,12.7187487 4.74119439,13.3831487 5.25798439,13.3831487 L22.7419544,13.3831487 Z M13.3079544,15.6416487 L11.6564544,15.6416487 L11.6564544,23.2414487 L13.3079544,23.2414487 L13.3079544,15.6416487 Z M15.5099544,23.2414487 L17.1615544,23.2414487 L17.1615544,15.6416487 L15.5099544,15.6416487 L15.5099544,23.2414487 Z M19.3635544,15.6416487 L19.3635544,23.2414487 L21.0150544,23.2414487 L21.0150544,15.6416487 L19.3635544,15.6416487 Z M9.45446439,23.2414487 L9.45446439,15.6416487 L7.80295439,15.6416487 L7.80295439,23.2414487 L9.45446439,23.2414487 Z M12.2069544,9.8732087 C12.2069544,9.2495287 12.6999544,8.7439287 13.3079544,8.7439287 L14.9594544,8.7439287 C15.5675544,8.7439287 16.0604544,9.2495287 16.0604544,9.8732087 C16.0604544,10.4968887 15.5675544,11.0024487 14.9594544,11.0024487 L13.3079544,11.0024487 C12.6999544,11.0024487 12.2069544,10.4968887 12.2069544,9.8732087 Z'
      fill='marker.scarlet'
    />
  </MarkerIconContainer>
)
