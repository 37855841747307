import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { toast } from 'presentation/components/Toast'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLeadIdContext } from 'presentation/libs/LeadIdContext'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

/**
 * Facilitates redirects and toasts relating to PropertyDetails state changes.
 * This logic is exported to separate component as it's rendered in whole app
 */
export const PropertyDetailsStateHandler = () => {
  // ========================================
  // Domain Selector
  // ========================================

  const {
    searchState,
    suggestionsError,
  } = usePropertyDetailsStore(state => {
    const getSuggestions = state.actions.getPropertySuggestions
    const getProperty = state.actions.getProperty

    return {
      searchState: getProperty.state,
      suggestionsError: 'error' in getSuggestions.state
        ? getSuggestions.state.error
        : undefined,
    }
  })

  const resetReport = useCMAStore(store => store.actions.resetReport.execute)

  const { setLeadId } = useLeadIdContext()

  const setIsMobileSearchInputVisible = useLayoutStore(layout =>
    layout.setIsMobileSearchPropertyInputVisible)

  // Navigate on search success or toast on error
  const navigate = useNavigate()
  const leadIdFromSearchState = searchState.status === 'success' && searchState.data.leadId
  const leadIdFromRoute = useParams().leadId
  const didGetPropertyViaSearch = 'params' in searchState
    && searchState.params.origin === 'search'
    && (
      'parcelId' in searchState.params
      || 'addressString' in searchState.params
    )

  useEffect(() => {
    const isAlreadyOnPropertyPage = leadIdFromRoute && leadIdFromSearchState && leadIdFromRoute === leadIdFromSearchState

    if (searchState.status === 'error') {
      toast.error({
        title: 'Failed to search property',
        message: 'Please try again or contact support.',
      })
    } else if (
      searchState.status === 'success'
      && !isAlreadyOnPropertyPage
      && didGetPropertyViaSearch
    ) {
      setLeadId(searchState.data.leadId)
      resetReport()
      navigate(`/search/${searchState.data.leadId}`)

      // Dismisses search input if on mobile
      setIsMobileSearchInputVisible(false)
    }
  }, [searchState])

  // Toast on suggestions error
  useEffect(() => {
    if (suggestionsError) {
      toast.error({
        title: 'Failed to fetch suggestions',
        message: 'Please try again or contact support.',
      })
    }
  }, [suggestionsError])

  return null
}
