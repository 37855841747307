import { Box, ButtonProps, Table, TableProps, Tbody, Text, TextProps } from '@chakra-ui/react'
import { DateLib } from 'libs/Date'
import { HiddenIcon, VisibleIcon } from 'presentation/components/Icons'

export const SkiptraceTable = ({ children, ...tableProps }: TableProps) => (
  <Table
    variant='striped'
    layout='fixed'
    sx={{
      '& > tr > td': {
        padding: 1,
      },
      '& > tr > td:first-of-type': {
        paddingLeft: 2,
      },
    }}
    {...tableProps}
  >
    <Tbody>
      {children}
    </Tbody>
  </Table>
)

export const NewValue = (props: TextProps) => (
  <Text
    color='positive.500'
    textStyle='bodyMFat'
    {...props}
  >
    New
  </Text>
)

export type DateValueProps = {
  date: Date
} & TextProps

export const DateValue = ({ date, ...textProps }: DateValueProps) => (
  <Text
    textStyle='bodyMFat'
    color='teal.500'
    overflowWrap='normal'
    {...textProps}
  >
    {DateLib.formatMDDYYDots(date)}
  </Text>
)

export type VisibilityButtonProps = {
  isVisible?: boolean
} & ButtonProps

export const VisibilityButton = (props: VisibilityButtonProps) => (
  <Box
    as='button'
    {...props}
  >
    {props.isVisible
      ? <VisibleIcon boxSize={3} color='graystrong.200' />
      : <HiddenIcon boxSize={3} color='graystrong.200' />}
  </Box>
)
