import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const ListIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.rect
        x='4'
        y='4'
        width='3'
        height='3'
        rx='4'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M14.667 10.666H21.3337'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.path
        d='M14.667 21.334H21.3337'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.path
        d='M14.667 16H21.3337'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.circle
        cx='10.6663'
        cy='10.6673'
        r='1.33333'
        fill={stroke}
      />

      <chakra.circle
        cx='10.6663'
        cy='15.9993'
        r='1.33333'
        fill={stroke}
      />

      <chakra.circle
        cx='10.6663'
        cy='21.3333'
        r='1.33333'
        fill={stroke}
      />
    </chakra.svg>

  )
}
