import { Flex, Grid, Text } from '@chakra-ui/react'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { StatPair } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard.props'
import { getCompCardColors } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/utils/getCompCardColors'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ReactNode } from 'react'

/**
 * Beds, Baths, Garage, Sqft, Built, Lot, Acres
 */

export type CompStatsGridProps = {
  subjectStat: StatPair[]
  mlsStatus: MLSStatus
  compStat: StatPair[]
  compMarker: ReactNode
}

export const CompStatsGrid = (props: CompStatsGridProps) => {
  const compColors = getCompCardColors(props.mlsStatus)
  return (
    <Grid
      borderRadius={3}
      overflow='hidden'
      gridTemplateColumns={`min-content repeat(${props.subjectStat.length}, 1fr)`}
      gridTemplateRows='minmax(0, 1fr)'
    >
      <Flex
        bgColor={compColors.statTableBgColor}
        alignItems='center'
        justifyContent='center'
        pl={mbp({ mobSm: 1, tabSm: 2 })}
      >
        {/*
          @Genesis
          {props.compMarker}
        */}
      </Flex>
      {props.compStat.map((comp, i) => (
        <Flex
          key={i}
          flexDir='column'
          gap={1}
          py={1}
          pr={mbp({ mobSm: i === props.subjectStat.length - 1 ? 1 : 0, tabSm: 0 })}
          bgColor={compColors.statTableBgColor}
        >
          <Text
            textStyle='bodySFat'
            textAlign='center'
            color={compColors.statTableLabelColor}
          >
            {comp.label}
          </Text>
          <Text
            textStyle='bodyM'
            color={compColors.statTableValueColor}
            textAlign='center'
          >
            {comp.value}
          </Text>
        </Flex>
      ))}
      <Flex
        alignSelf='center'
        bgColor='accent.darkpurple'
        pl={mbp({ mobSm: 1, tabSm: 2 })}
        h='full'
        justifyContent='center'
        alignItems='center'
      >
        <SubjectPropertyIcon boxSize={2} />
      </Flex>
      {props.subjectStat.map((comp, i) => (
        <Text
          key={i}
          py={1}
          textStyle='bodyM'
          color='ondark.1'
          bgColor='accent.darkpurple'
          textAlign='center'
          alignSelf='center'
          pr={mbp({ mobSm: i === props.subjectStat.length - 1 ? 1 : 0, tabSm: 0 })}
        >
          {comp.value}
        </Text>
      ))}
    </Grid>
  )
}
