import 'features/PropertyDetails/infra/react/PropertyDetails.modals.register'
import { EditListNameModal } from 'presentation/components/EditListNameModal'
import { SupportModal } from 'presentation/main/globalModals/SupportModal/SupportModal'
import { modals } from 'presentation/main/modals'

export const AppLevelModals = () => (
  <>
    <modals.ActiveModal />
    <SupportModal />
    <EditListNameModal />
  </>
)
