import { ArrayFormatter } from '@effect/schema'
import * as S from '@effect/schema/Schema'
import { Effect, Either, pipe } from 'effect'
import { BatchSkiptraceStats } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { IS_DEV_ENV } from 'presentation/const/env.const'

export namespace GETBatchSkiptraceStatsByListIdSchema {
  // #region Schemas
  export const MemberStatsSchema = S.Struct({
    skiptraced: S.optional(S.Number),
    skiptracing: S.optional(S.Number),
    total: S.optional(S.Number),
  })

  export const PendingSkiptraceSchema = S.Struct({
    batchId: S.String,
  })

  export const Self = S.Struct({
    memberStats: S.optional(MemberStatsSchema),
    pendingSkiptraces: S.optional(S.Array(PendingSkiptraceSchema)),
  })

  export type Self = S.Schema.Type<typeof Self>

  // #endregion

  // #region decode
  export const decode = (data: any) => pipe(
    data,
    S.decodeUnknownEither(Self),
    Either.getOrThrowWith(error => {
      if (IS_DEV_ENV) {
        pipe(
          error,
          ArrayFormatter.formatError,
          // eslint-disable-next-line no-console
          Effect.tap(console.log),
          Effect.runSync,
        )
      }

      throw error as Error
    }),
  )
  // #endregion

  // #region toDomain
  export const toDomain = (resp: Self): BatchSkiptraceStats => ({
    skiptracedCount: resp.memberStats?.skiptraced ?? 0,
    skiptracingCount: resp.memberStats?.skiptracing ?? 0,
    membersCount: resp.memberStats?.total ?? 0,
  })
  // #endregion
}

export type GETBatchSkiptraceStatsByListIdSchema = GETBatchSkiptraceStatsByListIdSchema.Self
