import { Avatar, Box, Center, Flex, FlexProps, Icon, IconButton, Spacer } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'presentation/assets/img/logo.svg'
import { CarIcon, ListBuilderIcon, ListIcon, PowerIcon, SearchIcon } from 'presentation/components/Icons'
import { px } from 'presentation/utils/px'
import { FC, memo } from 'react'
import { SidebarDtItem } from './SidebarDesktopItem'

import { useThemeSettings } from 'features/Settings/infra/react/Settings.state.reactUtils'
import { ReactComponent as DarkIcon } from 'presentation/assets/icons/dark-mode.svg'
import { ReactComponent as LightIcon } from 'presentation/assets/icons/light-mode.svg'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'

export const SIDEBAR_DESKTOP_W = 64

export const SidebarDesktop = memo<FlexProps>(props => {
  const viewModel = LayoutViewModel.useReadySelector(state => ({
    logout: state.accessor.logout,
    name: state.accessor.name,
    avatarUrl: state.accessor.avatarUrl,
  }))

  if (!viewModel) return null

  return (
    <Flex
      as='aside'
      h='app-height'
      flex={`0 0 ${SIDEBAR_DESKTOP_W}px`}
      direction='column'
      bg='special.500'
      flexShrink={0}
      w={px(SIDEBAR_DESKTOP_W)}
      pt='5'
      pb='3'
      gap='3'
      {...props}
    >
      <Flex h='5'>
        <Box as={Logo} w='5' mx='auto' />
      </Flex>

      <Spacer flexBasis='56px' flexGrow='0' flexShrink='1' />

      <SidebarDtItem to='/search' Icon={SearchIcon} name='Property Data' />

      <SidebarDtItem to='/lists/builder' Icon={ListIcon} name='List Builder' />
      <SidebarDtItem to='/lists' Icon={ListBuilderIcon} name='List Manager' />

      <SidebarDtItem to='/driving-for-dollars' Icon={CarIcon} name='Driving for Dollars' />
      <Spacer />

      <Center><ThemeSwitcher /></Center>

      <Spacer flexBasis='1' flexGrow='0' flexShrink='1' />

      <SidebarDtItem
        to='/user/team'
        iconReplacement={(
          <Avatar
            name={viewModel.name}
            src={viewModel.avatarUrl}
            size='sm'
            ml='-0.5'
          />
        )}
        name='Settings'
      />
      <SidebarDtItem
        onClick={viewModel.logout}
        Icon={() => <Icon as={PowerIcon} w={3} h={3} color='negative.500' />}
        color='negative.500'
        name='logout'
      />
    </Flex>
  )
})

SidebarDesktop.displayName = 'SidebarDesktop'

export const ThemeSwitcher: FC = () => {
  const themeApi = useThemeSettings()

  return (
    <IconButton
      onClick={themeApi.toggleTheme}
      aria-label={`Switch to ${themeApi.theme === 'light' ? 'dark' : 'light'} theme`}
      border={0}
      padding={0}
    >
      {themeApi.theme === 'light' ? <LightIcon /> : <DarkIcon />}
    </IconButton>
  )
}
