import { Table, TableActionButton, TableActions, TableEmptyIndicator, TableGroup, TableLoadingIndicator, TableScroller, Tbody, Th, Thead, Tr } from 'presentation/components/molecules/Table'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { AccountBalanceRow } from './AccountBalanceRow'
import { useAccountBalanceTable } from './useAccountBalanceTable'

/** @TODO Commented out sticky code for now, it's broken, lets try to fix later */
export const AccountBalanceTable: FC<{
  stickyOffset: number
}> = () => {
  const {
    isReady,
    transactions,
    canFetchMore,
    fetchMore,
    isFetchingMore,
  } = useAccountBalanceTable()
  // const { mfv } = useMobFirstBreakpointValue()
  // const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  // const theadProps: TableHeadProps = mfv({
  //   mobSm: {
  //     top: px(headerHeight + stickyOffset),
  //     position: 'sticky',
  //   },
  //   tabSm: {},
  // }) ?? {}

  return (
    <TableGroup>
      <TableScroller>
        <Table>
          {/* <Thead {...theadProps}> */}
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>User</Th>
              <Th w='full' isBigScreenOnly>Description</Th>
              <Th w={mbp({ mobSm: 'full', tabSm: 'auto' })} columnType='numeric'>Amount</Th>
              <Th columnType='numeric'>Balance</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map(edge => edge
              ? (
                <AccountBalanceRow transaction={edge.node} key={edge.cursor} />
              )
              : null)}
          </Tbody>
        </Table>
      </TableScroller>

      {!isReady && (
        <TableLoadingIndicator />
      )}

      {isReady && !transactions?.length && (
        <TableEmptyIndicator
          message='You don’t have transactions to review. All your future transactions will be displayed on this page.'
        />
      )}

      {canFetchMore && (
        <TableActions>
          <TableActionButton
            onClick={fetchMore}
            isLoading={isFetchingMore}
            loadingText='Loading More'
            spinnerPlacement='end'
          >
            Load More
          </TableActionButton>
        </TableActions>
      )}
    </TableGroup>
  )
}
