import { chakra } from '@chakra-ui/react'
import { MarkerSizeProp, MarkerType } from 'presentation/components/PropertyMarker/PropertyMarker.type'

export type MarkerContainerProps = {
  markerType: MarkerType
  borderColor: string
  bgColor: string
} & MarkerSizeProp

export const MarkerContainer = (props: MarkerContainerProps) =>
  props.markerType === 'pin'
    ? <PinContainer {...props} />
    : props.markerType === 'tag'
      ? <TagContainer {...props} />
      : null

export const PinContainer = (props: MarkerContainerProps) => {
  const strokeWidth = ['sm', 'xs'].includes(props.size) ? 2.5 : 2
  return (
    <chakra.svg
      x='5'
      y='5'
      filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
    >
      <chakra.path
        d='M20,1 C25.2191573,1 29.9743779,3.28090674 33.4194665,6.97643673 C36.8635595,10.6708986 39,15.7778943 39,21.4539035 C39,26.6796291 37.1072159,32.5674041 34.0073604,38.9060672 C30.8551038,45.3516113 26.4931333,52.1681396 21.6704282,59.1069366 C21.2590891,59.6987794 20.6115957,59.9953852 19.9638925,59.9999463 C19.316843,60.0045028 18.666564,59.7175888 18.2494518,59.1326541 C13.0551008,51.8485035 8.71622208,45.0504633 5.68051303,38.7046563 C2.69591246,32.4656971 1,26.7330076 1,21.4539035 C1,15.7778943 3.13644053,10.6708986 6.58053346,6.97643673 C10.0256221,3.28090674 14.7808427,1 20,1 Z'
        stroke={props.borderColor}
        strokeWidth={strokeWidth}
        fill={props.bgColor}
      />
    </chakra.svg>
  )
}

export const TagContainer = (props: MarkerContainerProps) => {
  const strokeWidth = ['sm', 'xs'].includes(props.size) ? 2.5 : 2
  return (
    <chakra.svg
      x='5'
      y='5'
      fill={props.bgColor}
      fillRule='nonzero'
      stroke={props.borderColor}
      strokeWidth={strokeWidth}
      filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
    >
      <chakra.circle cx='20' cy='20' r='19' />
    </chakra.svg>
  )
}
