import { Box, Text } from '@chakra-ui/react'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useSupportModal } from 'presentation/main/globalModals/SupportModal/SupportModal.api'

export type PlanDowngradeAlertCardProps = {
  isLoading: boolean
  skipAmount: string
  billingInterval: string
  planName: string
  onCancel: () => void
  onProceed: () => void
}

const PlanDowngradeAlertCard = (props: PlanDowngradeAlertCardProps) => {
  const { setIsOpen: setIsSupportModalOpen } = useSupportModal()

  const handleCancel = () => {
    props.onCancel()
  }

  const handleProceed = () => {
    props.onProceed()
  }

  const handleContactSupport = () => {
    setIsSupportModalOpen(true)
  }

  return (
    <Card
      variant='modal.alert'
      size='modal.alert.xn'
      colorScheme='modal.alert.negative'
    >
      <CardImage src={hurtDogPng} />
      <CardPrimaryTitle>
        Downgrade to
        {' '}
        {props.planName}
      </CardPrimaryTitle>
      <CardBody>
        <CardPrimaryText>
          Your plan will downgrade at the end of your billing cycle with
          {' '}
          {props.skipAmount}
          {' '}
          free skips
          {' '}
          {props.billingInterval}
          . Your current skiptrace credits won&apos;t change. Proceed with the downgrade?
        </CardPrimaryText>
      </CardBody>
      <CardFooter>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          size='md'
          onClick={handleCancel}
          autoFocus
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='negative'
          size='md'
          onClick={handleProceed}
          isLoading={props.isLoading}
          loadingText='Processing'
          type='submit'
        >
          Yes, Proceed
        </CardButton>
      </CardFooter>
      <Box textAlign='center' mt={2} mb={4}>
        <Text
          color='blue.500'
          fontSize='sm'
          cursor='pointer'
          _hover={{ textDecoration: 'underline' }}
          onClick={handleContactSupport}
        >
          Contact Support
        </Text>
      </Box>
    </Card>
  )
}

export default PlanDowngradeAlertCard
