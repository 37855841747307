import { Box, Button, ButtonProps, Flex, Popover, PopoverProps, PopoverTrigger, Text } from '@chakra-ui/react'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsivePopoverContent } from 'presentation/components/ResponsivePopoverContent/ResponsivePopoverContent'
import { NoDataWarningIcon } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/NoDataWarningIcon'
import { mbp } from 'presentation/utils/mapBreakpoint'

export type FilterPopoverProps = PopoverProps

export const FilterPopover = (props: FilterPopoverProps) => {
  const { children, ...popoverProps } = props
  return (
    <Popover offset={[0, 12]}{...popoverProps}>
      {children}
    </Popover>
  )
}

export type FilterPopoverButtonProps = {
  label: string
  shouldShowWarning?: boolean
  hasFilter?: boolean
  hasError?: boolean
} & ButtonProps

export const FilterPopoverButton = (props: FilterPopoverButtonProps) => {
  const { label, hasError, hasFilter, shouldShowWarning, children, ...buttonProps } = props
  return (
    <PopoverTrigger>
      <Button
        bgColor='card.bg.1'
        variant='outline'
        colorScheme={hasError ? 'negative' : 'neutral'}
        size={mbp({ mobSm: 'md', dtLg: 'xl' })}
        rightIcon={<ChevronDownIcon boxSize={3} />}
        position='relative'
        sx={{
          '&': {
            px: 2,
          },
        }}
        {...buttonProps}
      >
        {shouldShowWarning && (
          <NoDataWarningIcon
            color='negative.500'
            boxSize={3}
            position='absolute'
            top='-8px'
            right='4px'
          />
        )}
        <Flex
          flexDirection='column'
          alignItems='stretch'
          minW={0}
          gap={0.125}
        >
          <Text
            textStyle='tagM'
            textAlign='left'
            color={hasError ? 'negative.500' : 'accent.blue-to-gray'}
            lineHeight='12px'
          >
            {label}
          </Text>
          <Box
            textStyle={mbp({ mobSm: 'bodySFat', dtLg: 'bodyMFat' })}
            textAlign='left'
            color='graystrong.400'
            lineHeight='normal'
            isTruncated
          >
            {children}
          </Box>
        </Flex>
      </Button>
    </PopoverTrigger>
  )
}

export const FilterPopoverContent = ResponsivePopoverContent
