import { CMAProvider } from 'features/CMA/infra/react/CMAState'
import { filterComps } from 'features/CMA/infra/remote/filterComps/filterComps'
import { getCompsCoverageInfo } from 'features/CMA/infra/remote/getCompsCoverageInfo/getCompsCoverageInfo'
import { initializeLoadReport } from 'features/CMA/infra/remote/initializeLoadReport/initializeLoadReport'
import { loadCMA } from 'features/CMA/infra/remote/loadCma/loadCMA'
import { rateComp } from 'features/CMA/infra/remote/rateComp/rateComp'
import { updateEstimateInfo } from 'features/CMA/infra/remote/updateEstimateInfo/updateEstimateInfo'
import { ErrorLib } from 'libs/errors'
import { ReactNode } from 'react'

const errorReportedLoadCMA = ErrorLib.wrapReportOnReject(loadCMA)
const errorReportedFilterComps = ErrorLib.wrapReportOnReject(filterComps)
const errorReportedRateComp = ErrorLib.wrapReportOnReject(rateComp)
const errorReportedUpdateEstimateInfo = ErrorLib.wrapReportOnReject(updateEstimateInfo)
const errorReportedGetCompsCoverageInfo = ErrorLib.wrapReportOnReject(getCompsCoverageInfo)

export const LiveCMAProvider = ({ children }: {
  children?: ReactNode
}) => (
  <CMAProvider
    loadCMA={errorReportedLoadCMA}
    filterComps={errorReportedFilterComps}
    rateComp={errorReportedRateComp}
    updateEstimateInfo={errorReportedUpdateEstimateInfo}
    getCoverageInfo={errorReportedGetCompsCoverageInfo}
    initializeLoadReport={initializeLoadReport}
  >
    {children}
  </CMAProvider>
)
