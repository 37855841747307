import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const PreforeclosureIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M8,21.5455 L8,7 L14.2784,7 C15.358,7 16.3026,7.2131 17.1122,7.6393 C17.9219,8.0654 18.5516,8.6643 19.0014,9.4361 C19.4512,10.2079 19.6761,11.1099 19.6761,12.1421 C19.6761,13.1838 19.4441,14.0857 18.9801,14.8481 C18.5208,15.6104 17.8745,16.1975 17.0412,16.6094 C16.2126,17.0214 15.2443,17.2273 14.1364,17.2273 L10.3864,17.2273 L10.3864,14.1591 L13.3409,14.1591 C13.8049,14.1591 14.2003,14.0786 14.527,13.9177 C14.8584,13.7519 15.1117,13.5176 15.2869,13.2145 C15.4669,12.9115 15.5568,12.554 15.5568,12.1421 C15.5568,11.7254 15.4669,11.3703 15.2869,11.0767 C15.1117,10.7785 14.8584,10.5512 14.527,10.3949 C14.2003,10.2339 13.8049,10.1535 13.3409,10.1535 L11.9489,10.1535 L11.9489,21.5455 L8,21.5455 Z'
      fill='marker.strawberrypink'
      fillRule='nonzero'
    />
  </MarkerIconContainer>
)
