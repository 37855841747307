import { PropertyDetailsProvider } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { editProperty } from 'features/PropertyDetails/infra/remote/getProperty/editProperty'
import { getProperty } from 'features/PropertyDetails/infra/remote/getProperty/getProperty'
import { getPropertySuggestions } from 'features/PropertyDetails/infra/remote/getPropertySuggestions.ts/getPropertySuggestions'
import { ErrorLib } from 'libs/errors'
import { ReactNode } from 'react'

const reportedGetProperty = ErrorLib.wrapReportOnReject(getProperty)
const reportedGetPropertySuggestions = ErrorLib.wrapReportOnReject(getPropertySuggestions)
const reportedEditProperty = ErrorLib.wrapReportOnReject(editProperty)

export const LivePropertyDetailsProvider = ({ children }: {
  children: ReactNode
}) => (
  <PropertyDetailsProvider
    getProperty={reportedGetProperty}
    getPropertySuggestions={reportedGetPropertySuggestions}
    editProperty={reportedEditProperty}
  >
    {children}
  </PropertyDetailsProvider>
)
