import { DEFAULT_COMPS_SORT } from 'features/Settings/valueObjects/CompsSort'
import { CompsView, DEFAULT_COMPS_VIEW } from 'features/Settings/valueObjects/CompsView'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { CMAEntriesSortMenuValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/components/CMAEntriesSortMenu/CMAEntriesSortMenuValue'
import { create } from 'zustand'

export type CMAEntriesViewValue = CompsView

const showValues = [...MLSStatus.toArray(), 'THUMBS_DOWN'] as const

export type CMAEntriesShowValue = typeof showValues[number]

export type UseEntriesToolbarMenuStore = {
  isSortOpen: boolean
  isSortGroupedByStatus: boolean
  isShowOpen: boolean
  isViewOpen: boolean
  showValues: CMAEntriesShowValue[]
  sortValue: CMAEntriesSortMenuValue
  /** You probably need `viewValue` from `useResponsiveViewValue` instead */
  __DANGER__viewValue: CMAEntriesViewValue
}

export const useEntriesToolbarMenu = create<UseEntriesToolbarMenuStore>(() => ({
  isSortOpen: false,
  isSortGroupedByStatus: false,
  isShowOpen: false,
  isViewOpen: false,
  showValues: [...showValues],
  sortValue: DEFAULT_COMPS_SORT,
  __DANGER__viewValue: DEFAULT_COMPS_VIEW,
}))

export const useResponsiveViewValue = () => {
  const isMobile = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  })

  const { __DANGER__viewValue: viewValue } = useEntriesToolbarMenu()

  return isMobile && viewValue === 'TABLE_VIEW' ? 'TILE_VIEW' : viewValue
}

export const setShowValues = (values: CMAEntriesShowValue[]) => {
  useEntriesToolbarMenu.setState({ showValues: values })
}

export const setSortValue = (value: CMAEntriesSortMenuValue) => {
  useEntriesToolbarMenu.setState({ sortValue: value })
}

export const setViewValue = (value: CMAEntriesViewValue) => {
  useEntriesToolbarMenu.setState({ __DANGER__viewValue: value })
}

export const resetShowValues = () => {
  useEntriesToolbarMenu.setState(state => {
    const wasPreviouslyShowingThumbsDown = state.showValues.includes('THUMBS_DOWN')
    /** Let's try to retain the thumbs down settings */
    const nextShowValues = wasPreviouslyShowingThumbsDown
      ? state.showValues
      : state.showValues.filter(value => value !== 'THUMBS_DOWN')

    return { showValues: [...nextShowValues] }
  })
}

export const toggleShowMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isShowOpen: !state.isShowOpen,
    isSortOpen: false,
    isViewOpen: false,
  }))
}

export const toggleSortMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isSortOpen: !state.isSortOpen,
    isShowOpen: false,
    isViewOpen: false,
  }))
}

export const toggleViewMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isViewOpen: !state.isViewOpen,
    isShowOpen: false,
    isSortOpen: false,
  }))
}

export const openShowMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isShowOpen: true,
    isSortOpen: false,
    isViewOpen: false,
  }))
}

export const openSortMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isSortOpen: true,
    isShowOpen: false,
    isViewOpen: false,
  }))
}

export const openViewMenu = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isViewOpen: true,
    isShowOpen: false,
    isSortOpen: false,
  }))
}

export const closeShowMenu = () => {
  useEntriesToolbarMenu.setState(state => ({ ...state, isShowOpen: false }))
}

export const closeSortMenu = () => {
  useEntriesToolbarMenu.setState(state => ({ ...state, isSortOpen: false }))
}

export const closeViewMenu = () => {
  useEntriesToolbarMenu.setState(state => ({ ...state, isViewOpen: false }))
}

export const toggleSortMenuGroupByStatus = () => {
  useEntriesToolbarMenu.setState(state => ({
    ...state,
    isSortGroupedByStatus: !state.isSortGroupedByStatus,
  }))
}
