import { Match, pipe } from 'effect'

export const SKIPTRACE_PROVIDERS = {
  SKIPGENIE: 'SKIPGENIE',
  UNKNOWN: 'UNKNOWN',
  REIGROUP: 'REIGROUP',
} as const

export type SkiptraceProvider = keyof typeof SKIPTRACE_PROVIDERS

export const SkiptraceProvider = {
  toHumanReadable: (provider: SkiptraceProvider) =>
    pipe(
      Match.value(provider),
      Match.when('SKIPGENIE', () => 'Skip Genie'),
      Match.when('REIGROUP', () => 'REI Group'),
      Match.when('UNKNOWN', () => 'Other Provider'),
      Match.exhaustive,
    ),
}
