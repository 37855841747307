import { Button, ButtonProps } from '@chakra-ui/react'
import { PowerIcon } from 'presentation/components/Icons'
import { FC } from 'react'

export type ActivateButtonProps = ButtonProps & {
  isVisible?: boolean
}

export const ActivateButton: FC<ActivateButtonProps> = ({ isVisible = true, ...props }) => (
  <Button
    variant='muted'
    size='xs'
    colorScheme='neutral'
    leftIcon={<PowerIcon boxSize='2' />}
    visibility={isVisible ? 'visible' : 'hidden'}
    {...props}
  >
    Activate
  </Button>
)
