import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { merge } from 'lodash/fp'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

/**
 * ====================
 * Base Style
 * ====================
 */
const baseStyle = definePartsStyle({
  icon: {
    display: 'none',
  },
  control: {
    'boxShadow': '0px 4px 4px 0px rgba(0, 0, 0, 0.10) inset',
    'borderWidth': 0.125,
    'borderColor': 'grayweak.500',
    'backgroundColor': 'card.bg.2',
    '_checked': {
      '& > svg': {
        color: 'ondark.1',
      },
    },
    '_indeterminate': {
      '& > svg': {
        color: 'ondark.1',
      },
    },
    '&[data-disabled]': {
      borderColor: 'grayweak.500',
      backgroundColor: 'grayweak.600',
    },
    '&[data-checked][data-disabled]': {
      '& > svg': {
        color: 'ondark.5',
      },
      'borderColor': 'grayweak.900',
      'backgroundColor': 'grayweak.900',
    },
    '&[data-indeterminate][data-disabled]': {
      '& > svg': {
        color: 'ondark.5',
      },
      'borderColor': 'grayweak.900',
      'backgroundColor': 'grayweak.900',
    },
  },
})

/**
 * ====================
 * Sizes
 * ====================
 */
const sizes = {
  md: (props: StyleFunctionProps) => definePartsStyle({
    control: {
      borderRadius: props.variant === 'circle' ? '50%' : 0.75,
      boxSize: 3,
      _checked: {
        '& > svg': {
          w: '16px',
          h: '12px',
        },
      },
      _indeterminate: {
        '& > svg': {
          w: '20px',
          h: '20px',
        },
      },
    },
  }),
  lg: (props: StyleFunctionProps) => definePartsStyle({
    control: {
      borderRadius: props.variant === 'circle' ? '50%' : 0.75,
      boxSize: 4,
      _checked: {
        '& > svg': {
          w: '20px',
          h: '16px',
        },
      },
      _indeterminate: {
        '& > svg': {
          w: '28px',
          h: '28px',
        },
      },
    },
  }),
}

/**
 * ====================
 * Color Schemes
 * ====================
 */
const colorSchemes = {
  neutral: () => definePartsStyle({
    control: {
      _checked: {
        boxShadow: 'none',
        borderColor: 'neutral.500',
        backgroundColor: 'neutral.500',

        _hover: {
          boxShadow: 'none',
          borderColor: 'neutral.500',
          backgroundColor: 'neutral.500',
        },
      },
      _indeterminate: {
        boxShadow: 'none',
        backgroundColor: 'neutral.500',
      },
    },
  }),
  positive: () => definePartsStyle({
    control: {
      _checked: {
        boxShadow: 'none',
        borderColor: 'positive.500',
        backgroundColor: 'positive.500',
        color: 'positive.500',

        _hover: {
          boxShadow: 'none',
          borderColor: 'positive.500',
          backgroundColor: 'positive.500',
        },
      },
      _indeterminate: {
        boxShadow: 'none',
      },
    },
  }),
}

/**
 * ====================
 * Variants
 * ====================
 */
const variants = {
  square: (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(colorSchemes)?.() || {},
      {
        /**
         * Put square variant styles here
         */
      },
    ),
  circle: (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(colorSchemes)?.() || {},
      {
        /**
         * Put circle variant styles here
         */
      },
    ),
}

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'square',
    colorScheme: 'neutral',
  },
})
