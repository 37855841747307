import { switchAnatomy } from '@chakra-ui/anatomy'
import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers([...switchAnatomy.keys, 'label'])

const baseStyle = definePartsStyle({
  container: {
    boxSizing: 'border-box',
    p: '0',
    m: '0',
    display: 'grid',
    gridTemplateAreas: '"layer"',
  },
  track: {
    gridArea: 'layer',
    boxSizing: 'border-box',
    lineHeight: '0',
    p: '0',
    m: '0',
    boxShadow: 'depth',
    borderWidth: '1px',
    borderColor: 'grayweak.500',
  },
  thumb: {
    boxSizing: 'border-box',
    p: '0',
    m: '1px',
    color: 'card.bg.1',
  },
  label: {
    gridArea: 'layer',
    alignSelf: 'center',
    p: '0',
    m: '4px',
    color: 'white',
  },
})

const defaultVariantColorSchemes = {
  default: () => definePartsStyle({
    track: {
      bg: 'ondark.3',
      _checked: {
        bg: 'neutral.500',
      },
    },
  }),
  special: () => definePartsStyle({
    track: {
      bg: 'neutral.500',
      _checked: {
        bg: 'positive.500',
      },
    },
  }),
  neutral: () => definePartsStyle({
    track: {
      bg: 'neutral.500',
      _checked: {
        bg: 'neutral.500',
      },
    },
  }),
}

const variants = {
  default: (props: StyleFunctionProps) =>
    getRecKey(props.colorScheme)(defaultVariantColorSchemes)?.() || {},
}

const sizes = {
  lg: definePartsStyle({
    container: {
      w: '6.5',
      h: '3.5',
    },
    track: {
      w: '6.5',
      h: '3.5',
    },
    thumb: {
      w: '3',
      h: '3',
      fontSize: '3',
      _checked: {
        transform: 'translateX(28px)',
      },
    },
    label: {
      ...textStyles.tagM,
    },
  }),
  md: definePartsStyle({
    container: {
      w: '6',
      h: '3',
    },
    track: {
      w: '6',
      h: '3',
    },
    thumb: {
      w: '2.5',
      h: '2.5',
      fontSize: '2.5',
      _checked: {
        transform: 'translateX(24px)',
      },
    },
    label: {
      ...textStyles.tagS,
    },
  }),
}

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'default',
    colorScheme: 'default',
  },
})
