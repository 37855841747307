import { avatarAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys)

const baseStyle = ({ colorScheme, name }: StyleFunctionProps) => definePartsStyle({
  label: {
    borderRadius: '50%',
    height: '100%',
    borderColor: 'ondark.1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 0,
    color: 'onlight.3',
    /**
     * @Note
     * If name is a question mark, use specialsat.500 as bg. Otherwise randomize it.
     * The randomization is based on the name, so it--the question mark--will always
     * be the same color. In our theme it is color brown, which we don't want.
     */
    background: name === '?' ? 'specialsat.500' : 'var(--avatar-background)',
  },
  container: {
    'display': 'block',
    'borderColor': colorScheme,
    'boxShadow': '0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset',
    'bg': 'ondark.1',
    '& .chakra-avatar__svg': {
      /*  @Note A fallback doesn't have a dynamic background color
      *   like the initials bg. The initial will hash the name and
      *   based on that, it will pick a color from the theme. Because
      *   we don't have a basis for a consistent bg for a fallback, we
      *   will use the specialsat.500 like in the design.
      */
      background: 'specialsat.500',
      borderRadius: '50%',
    },
    'p': '0.25',
  },
})

const sizes = {
  sm: definePartsStyle({
    label: {
      ...textStyles.bodySHeavy,
    },
    container: {
      w: '4',
      h: '4',
      borderWidth: '0.25',
      p: '0.125',
    },
  }),
  md: definePartsStyle({
    label: {
      ...textStyles.bodyMHeavy,
    },
    container: {
      w: '5',
      h: '5',
      borderWidth: '0.25',
      p: '0.25',
    },
  }),
  lg: definePartsStyle({
    label: {
      ...textStyles.h4Heavy,
    },
    container: {
      w: '7',
      h: '7',
      borderWidth: '0.375',
    },
  }),
  xl: definePartsStyle({
    label: {
      ...textStyles.h3Heavy,
    },
    container: {
      w: '72px',
      h: '72px',
      borderWidth: '0.375',
    },
  }),
}

export const avatarTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'specialsat.500',
  },
})
