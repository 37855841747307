import { isNumber } from 'remeda'

type Range = [number, number]

export type PartialRange = [number | null, number | null]

export type NullableMinRange = [number | null, number]
export type NullableMaxRange = [number, number | null]

export const PartialRange = {
  EMPTY: [null, null] satisfies PartialRange,
  checkHasValue: (range: PartialRange): boolean => range[0] !== null || range[1] !== null,
  checkHasValueOptional: (range: PartialRange | null | undefined): boolean =>
    range ? PartialRange.checkHasValue(range) : false,
  min: (range: PartialRange): number | null => range[0],
  max: (range: PartialRange): number | null => range[1],
  checkMin: (range: PartialRange): boolean => range[0] !== null,
  checkMax: (range: PartialRange): boolean => range[1] !== null,
  formatHumanReadable: (range: PartialRange, options?: {
    prefix?: string
    formatValue?: (value: number) => string
  }): string | null => {
    if (!PartialRange.checkHasValue(range)) return null

    const min = PartialRange.min(range)
    const max = PartialRange.max(range)
    const prefix = options?.prefix || ''
    const formatValue = options?.formatValue || String

    if (isNumber(min) && isNumber(max)) return `${prefix}${formatValue(min)} - ${prefix}${formatValue(max)}`
    if (isNumber(min)) return `${prefix}${formatValue(min)} or more`
    if (isNumber(max)) return `${prefix}${formatValue(max)} or less`

    return null
  },
  make: (min: number | null, max: number | null) => [min, max] as PartialRange,
}

namespace Range {
  export const isRange = (value: any): value is Range =>
    Array.isArray(value) && value.length === 2 && isNumber(value[0]) && isNumber(value[1])
}

export default Range
