import { CMA } from 'features/CMA/CMA.domain'
import { ID } from 'libs/id'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { useListInfoState } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useListInfoState'
import { CompsTableScroller } from 'presentation/screens/CompsScreen/hooks/CompsTableViewScroller'
import { ActiveCMAEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useEntriesTransformation } from 'presentation/screens/CompsScreen/hooks/useEntriesTransformation'
import { PropsWithChildren, createContext, useContext } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'

export type RowData = {
  rowNumber: number
  comp: CMA.SingleComp | CMA.SubjectComp
}

export type ExpandedRow = {
  data: RowData
  shouldScroll: boolean
}

export type ExpandRow = (params?: {
  rowId: ID
  shouldScroll?: boolean
}) => void

export type NextRow = (shouldSkipExcluded?: boolean, fromRow?: RowData) => void

export type EntriesTableStoreStatus = 'loading' | 'loaded' | 'empty'

export type EntriesTableStore = {
  compStatus: EntriesTableStoreStatus
  compCount: number
  rows: RowData[]
  totalRows: number
  expandedRow: ExpandedRow | null
  expandRow: ExpandRow
  nextRow: NextRow
  prevRow: () => void
}

const Context = createContext<StoreApi<EntriesTableStore> | null>(null)

export type CreateEntriesTableStoreParams = {
  rows: RowData[]
  expandedRow: ExpandedRow | null
  compStatus: EntriesTableStoreStatus
}

const createEntriesTableStore = (params: CreateEntriesTableStoreParams) => {
  const store = createStore(
    subscribeWithSelector<EntriesTableStore>((set, get) => ({
      ...params,
      compCount: params.rows.length > 0 ? params.rows.length - 1 : 0, // exclude the count for subject
      totalRows: params.rows.length,
      expandRow: params => set(state => {
        if (params === undefined) {
          return {
            ...state,
            expandedRow: null,
          }
        }

        if (params.rowId === state.expandedRow?.data.comp.id) {
          return {
            ...state,
            expandedRow: {
              data: state.expandedRow.data,
              shouldScroll: params.shouldScroll ?? false,
            },
          }
        }

        const rowIndex = state.rows.findIndex(row => row.comp.id === params.rowId)

        if (rowIndex === -1) return state

        const data = state.rows[rowIndex]

        // Prevent expansion of comp row if offmarket
        if (data.comp.status === 'OFF_MARKET') return state

        return {
          ...state,
          expandedRow: {
            data,
            shouldScroll: params.shouldScroll ?? false,
          },
        }
      }),
      nextRow: (shouldSkipExcluded, fromRow) => set(state => {
        if (state.expandedRow === null) return state

        /**
         * If fromRow is provided, use it as the current row index
         */
        const currentRowIndex = fromRow
          ? fromRow.rowNumber - 1
          : state.expandedRow.data.rowNumber - 1

        if (shouldSkipExcluded) {
          /**
          * Find the next row that is not excluded
          */
          let nextRowIndex = currentRowIndex + 1
          let currentNextRow = state.rows[nextRowIndex]
          while (currentNextRow && currentNextRow.comp.userRating === 'excluded')
            currentNextRow = state.rows[nextRowIndex++]

          /**
           * If we reached the end row, return the first row
           */
          if (currentNextRow === undefined) {
            return {
              ...state,
              expandedRow: get().rows.length > 0
                ? {
                  shouldScroll: true,
                  data: get().rows[0],
                }
                : null,
            }
          }

          return {
            ...state,
            expandedRow: {
              shouldScroll: true,
              data: currentNextRow,
            },
          }
        } else {
          /**
           * Move to the next row normally
           */
          if (currentRowIndex === state.rows.length - 1) return state

          const nextRowIndex = currentRowIndex + 1

          return {
            ...state,
            expandedRow: {
              shouldScroll: true,
              data: state.rows[nextRowIndex],
            },
          }
        }
      }),
      prevRow: () => set(state => {
        if (state.expandedRow === null) return state
        const currentRowIndex = state.expandedRow.data.rowNumber - 1
        if (currentRowIndex <= 0) return state
        const prevRowIndex = currentRowIndex - 1
        const data = state.rows[prevRowIndex]

        // Prevent expansion of comp row if offmarket
        if (data.comp.status === 'OFF_MARKET') return state

        return {
          ...state,
          expandedRow: {
            shouldScroll: true,
            data: state.rows[prevRowIndex],
          },
        }
      }),
    })))

  return store
}

export type EntriesTableProviderProps = PropsWithChildren<{
  type: ActiveCMAEntriesTab
}>

export const EntriesTableProvider = (props: EntriesTableProviderProps) => {
  const highlightedEntry = CompsMapAndListSync.useStore(store => store.highlightedListItem)

  const listInfoState = useListInfoState({ type: props.type })

  const rawComps = listInfoState.status === 'loaded' ? listInfoState.data.comps : []

  const comps = useEntriesTransformation({ comps: rawComps })

  const withSubject = listInfoState.status === 'loaded'
    ? [listInfoState.data.subject, ...comps]
    : []

  const rowRecord = withSubject.map((comp, index) => ({
    rowNumber: index + 1,
    comp,
  } satisfies RowData))

  const initialHighlightedComp = rowRecord
    .find(row => row.comp.id === highlightedEntry?.id)

  const defaultExpandedRow: ExpandedRow | null = initialHighlightedComp
    ? {
      data: initialHighlightedComp,
      shouldScroll: false,
    }
    : null

  const store = createEntriesTableStore({
    rows: rowRecord,
    expandedRow: defaultExpandedRow,
    compStatus: listInfoState.status,
  })

  store.subscribe(
    state => state.expandedRow,
    expandedRow => {
      if (expandedRow) {
        CompsMapAndListSync.setHighlightedEntry(expandedRow.data.comp.id)
        if (expandedRow.shouldScroll) {
          const isSubject = expandedRow.data.comp.type === 'subject-comp'
          CompsTableScroller.scrollToRow(expandedRow.data.comp.id, isSubject)
        }
      } else {
        CompsMapAndListSync.setHighlightedEntry(null)
      }
    },
    { equalityFn: shallow },
  )

  return (
    <Context.Provider value={store}>
      {props.children}
    </Context.Provider>
  )
}

export type UseEntriesTableStore = <T>(
  selector: (state: EntriesTableStore) => T,
  equalifyFn?: (a: T, b: T) => boolean,
) => T

export const useEntriesTableStore: UseEntriesTableStore = (selector, equalifyFn) => {
  const store = useContext(Context)
  if (!store)
    throw new Error('Missing EntriesTablePaginationProvider')

  return useStore(store, selector, equalifyFn)
}
