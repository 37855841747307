import { useToken } from '@chakra-ui/react'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { useCompsBoundaryLinesFromFilters } from 'presentation/screens/CompsScreen/components/CompsBoundary/useCompsBoundaryLinesFromFilter'
import { Layer, Source } from 'react-map-gl'

export const CompsBoundaryDrawing = () => {
  const status = CompsBoundary.useStore(state => state.status)
  const linesFromDrawer = CompsBoundary.useStore(state => state.lines)

  const linesFromFilters = useCompsBoundaryLinesFromFilters()

  const lines = status === 'drawing' ? linesFromDrawer : linesFromFilters

  const lineColor = useToken('colors', 'neutral.500')
  const fillColor = useToken('colors', 'neutral.300')

  return (
    <>
      {lines.map((line, index) => line.coordinates.length > 0 && (
        <Source
          key={index}
          type='geojson'
          data={{
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: line.coordinates,
            },
          }}
        >
          {line.isClosed && (
            <Layer
              paint={{
                'fill-color': fillColor,
                'fill-opacity': 0.2,
              }}
              type='fill'
            />
          )}

          <Layer
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': lineColor,
              'line-width': 5,
            }}
            type='line'
          />
        </Source>
      ))}
    </>
  )
}
