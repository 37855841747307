import { createIcon } from '@chakra-ui/react'

export const ScribbleLineIcons = createIcon({
  displayName: 'ScribbleLineIcons',
  viewBox: '0 0 468 18',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M382.617 8.69878C295.912 5.75093 208.855 11.8231 122.198 13.0552C105.137 13.2977 88.2938 14.4295 71.2959 15.1287C57.3478 15.7025 43.471 15.6424 29.5111 15.9106C23.5583 16.0249 17.6418 16.2702 11.6816 16.2539C10.1723 16.2498 20.6783 16.2286 20.9944 16.2234C91.6743 15.058 161.917 9.10035 232.571 7.61483C263.002 6.97502 293.574 6.64598 324.005 7.52946C338.028 7.9366 352.441 7.53853 366.382 8.54219C366.762 8.56957 367.894 8.66576 367.527 8.60136C364.823 8.12565 362.091 7.72492 359.314 7.40219C327.58 3.71478 294.362 3.20566 262.117 2.65316C219.56 1.92397 176.95 2.08337 134.381 1.9666C99.5858 1.87115 64.7418 1.4799 29.9504 1.7922C20.9155 1.8733 11.8958 2.13489 2.85807 2.1101C-1.19899 2.09897 10.9537 2.4462 15.0081 2.53563C35.2948 2.98314 55.6678 2.75923 75.963 2.8149C124.523 2.9481 173.069 3.42927 221.627 3.69072C254.507 3.86775 287.357 3.59244 320.232 3.51295C335.557 3.4759 351.324 3.23193 366.594 4.22844C371.215 4.53003 369.524 4.6299 365.399 4.28119C334.63 1.68037 303.296 2.49411 272.374 3.46572C199.699 5.74929 127.12 8.54942 54.2825 8.77867C42.2652 8.81649 30.2451 8.74075 18.2273 8.70778C6.30453 8.67508 19.0923 8.6553 23.6561 8.49855C116.218 5.31959 209.216 5.39272 301.949 5.47989C348.828 5.52396 395.853 5.30941 442.681 6.81844C450.144 7.0589 458.632 6.86427 465.985 7.91892' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
    </g>
  ),
})
