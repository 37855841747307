import { Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import { LockIcon } from 'presentation/components/Icons'
import { useThemeSpecificValueFn } from 'presentation/main/themes/hooks/useThemeSpecificValue'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import AmexLogoDark from './img/dark/amex-logo.svg'
import DiscoverLogoDark from './img/dark/discover-logo.svg'
import JcbLogoDark from './img/dark/jcb-logo.svg'
import MastercardLogoDark from './img/dark/mastercard-logo.svg'
import PciCertDssLogoDark from './img/dark/pci-dss-cert-logo.svg'
import SslSecureLogoDark from './img/dark/ssl-secure-logo.svg'
import StripeLogoDark from './img/dark/stripe-logo.svg'
import UnionPayLogoDark from './img/dark/unionpay-logo.svg'
import VisaLogoDark from './img/dark/visa-logo.svg'
import AmexLogoLight from './img/light/amex-logo.svg'
import DiscoverLogoLight from './img/light/discover-logo.svg'
import JcbLogoLight from './img/light/jcb-logo.svg'
import MastercardLogoLight from './img/light/mastercard-logo.svg'
import PciCertDssLogoLight from './img/light/pci-dss-cert-logo.svg'
import SslSecureLogoLight from './img/light/ssl-secure-logo.svg'
import StripeLogoLight from './img/light/stripe-logo.svg'
import UnionPayLogoLight from './img/light/unionpay-logo.svg'
import VisaLogoLight from './img/light/visa-logo.svg'

export const SecurePaymentIconsSection: FC<FlexProps> = props => {
  const { tsv } = useThemeSpecificValueFn()

  return (
    <Flex alignItems='center' {...props}>
      <Flex flex='1' gap={mbp({ mobSm: '1', dtLg: '2' })}>
        <Flex flex={mbp({ mobSm: '1', tabSm: '0 0 auto' })} gap={mbp({ mobSm: '1', dtLg: '2' })}>
          <Image
            src={tsv(SslSecureLogoLight, SslSecureLogoDark)}
            alt='SSL Secure'
            w={mbp({ mobSm: '63px', tabSm: '76px' })}
            h={mbp({ mobSm: '21px', tabSm: '26px' })}
          />
          <Image
            src={tsv(PciCertDssLogoLight, PciCertDssLogoDark)}
            alt='PCI DSS Certified'
            w={mbp({ mobSm: '56px', tabSm: '67px' })}
            h={mbp({ mobSm: '26px', tabSm: '32px' })}
          />
        </Flex>

        <Flex
          alignItems='center'
        >
          <LockIcon color='graystrong.200' fontSize='2' />
          <Text ml='0.5' color='graystrong.200' textStyle='body2-b'>Powered by</Text>
          <Image ml='0.25' mb='-0.5' src={tsv(StripeLogoLight, StripeLogoDark)} alt='Stripe' />
        </Flex>
      </Flex>

      <Flex alignItems='center' gap='1' display={mbp({ mobSm: 'none', tabSm: 'flex' })}>
        <Image src={tsv(VisaLogoLight, VisaLogoDark)} alt='Visa' />
        <Image src={tsv(MastercardLogoLight, MastercardLogoDark)} alt='Mastercard' />
        <Image src={tsv(AmexLogoLight, AmexLogoDark)} alt='American Express' />
        <Image src={tsv(JcbLogoLight, JcbLogoDark)} alt='JCB' />
        <Image src={tsv(DiscoverLogoLight, DiscoverLogoDark)} alt='Discover' />
        <Image src={tsv(UnionPayLogoLight, UnionPayLogoDark)} alt='UnionPay' />
      </Flex>
    </Flex>
  )
}
