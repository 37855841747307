import { Schema as S } from '@effect/schema'
import { Array, Effect, Match, Option, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import { GETDownloadStatsSchema } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetDownloadStatsResolver/GETDownloadStatsSchema'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

const GetDownloadStatsResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetDownloadStats) => pipe(
    Effect.tryPromise({
      try: async () => await restClient
        .get(`lists/v3/${request.listId}/downloads`)
        .json(),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404)
          return new ListNotFoundError({ marketingListId: request.listId })

        return new UnknownException(error)
      },
    }),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => toDomain(request.listId, decoded)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default GetDownloadStatsResolver

const decode = (u: unknown): GETDownloadStatsSchema =>
  S.decodeUnknownSync(GETDownloadStatsSchema)(u)

/**
 * @TODO: Should update schema to exactly reflect all optional fields.
 * Return error instead for unusable data
 */
const toDomain = (
  listId: MarketingListId,
  schema: GETDownloadStatsSchema,
): MarketingListRepo.DownloadStats => ({
  listId,
  downloadedMemberCount: pipe(
    Array.last(schema.downloads),
    Option.match({
      onNone: () => 0,
      onSome: download => download.cursor.limit,
    }),
  ),
  limitInfoByInterval: {
    monthly: {
      interval: 'monthly',
      consumed: pipe(
        Match.value([schema.limits.limit, schema.limits.available]),
        Match.when(
          [{ _tag: 'Some' }, { _tag: 'Some' }],
          ([limit, available]) => limit.value - available.value,
        ),
        Match.orElse(() => 0),
      ),
      limit: Option.match(schema.limits.limit, {
        onSome: limit => limit,
        onNone: () => Infinity,
      }),
      remaining: Option.match(schema.limits.available, {
        onSome: available => available,
        onNone: () => Infinity,
      }),
    },
  },
})
