import { Brand, String } from 'effect'
import { nanoid } from 'nanoid'

type ParcelId = string & Brand.Brand<'ParcelId'>

const fromString = Brand.refined<ParcelId>(
  n => !String.isEmpty(n),
  () => Brand.error(`Expected ParcelId to be a non empty string`),
)

const ParcelId = {
  fromString,
  make: () => fromString(nanoid()),
}

export default ParcelId
