import { Menu, MenuButton, StackProps, Tag, VStack, Text, Stack, Box, MenuButtonProps } from '@chakra-ui/react'
import { Fragment, ReactNode } from 'react'
import { Array } from 'effect'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import classNames from 'classnames'
import { zIndices } from 'presentation/main/themes/common/zIndex.theme'

const TruncatedOwnerList = ({ children, ...props }: StackProps) => {
  const visibleOwners = Array.isArray(children)
    ? Array.take(children as Iterable<ReactNode>, 2)
    : [children]

  const hiddenOwners = Array.isArray(children)
    ? Array.drop(children as Iterable<ReactNode>, 2)
    : [children]

  const countHiddenOwners = Array.length(hiddenOwners)

  return (
    <Box
      className='truncated-owner-list'
      {...props}
    >
      {Array.map(visibleOwners, (visible, i) => (
        <Fragment key={i}>
          {i === 0
            ? visible
            : (
              <Stack
                className={classNames(
                  'truncated-owner-list__item',
                  { 'truncated-owner-list__item__has-more': countHiddenOwners > 0 },
                )}
                direction='row'
                spacing='1'
              >
                {visible}
                {countHiddenOwners > 0 && (
                  <MoreOwnersPopover
                    flex='1'
                    count={countHiddenOwners}
                  >
                    {Array.map(hiddenOwners, (hidden, i) => (
                      <Tag
                        key={i}
                        variant='bordered'
                        colorScheme='muted'
                      >
                        {hidden}
                      </Tag>
                    ))}
                  </MoreOwnersPopover>
                )}
              </Stack>
            )}
        </Fragment>
      ))}
    </Box>
  )
}

export default TruncatedOwnerList

type OwnersPopoverProps = MenuButtonProps & {
  count: number
}

const MoreOwnersPopover = ({ children, count, ...props }: OwnersPopoverProps) => (
  <Menu>
    <MenuButton
      className='truncated-owner-list__and-more-button'
      {...props}
    >
      <Text
        textStyle='bodyL'
        color='link.500'
        w='max-content'
      >
        and
        {' '}
        {count}
        {' '}
        more
      </Text>
    </MenuButton>
    <ResponsiveMenuList
      modalContainerProps={{
        zIndex: zIndices['popover-1'],
      }}
    >
      <VStack
        flexDirection='column'
        alignItems='flex-start'
        gap={1}
        pl={2}
      >
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
          p='1'
        >
          Owners:
        </Text>
        {children}
      </VStack>
    </ResponsiveMenuList>
  </Menu>
)
