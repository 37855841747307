import { Box, Flex, Text } from '@chakra-ui/react'
import { FC, ReactElement, ReactNode, useRef } from 'react'
import { Link as RRLink, useMatch, useResolvedPath } from 'react-router-dom'
import { useHoverDirty } from 'react-use'

type Props = {
  icon?: ReactNode
  to?: string
  title: string
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  color?: string
  iconColor?: string
  textColor?: string
  iconReplacement?: ReactElement
  rightJsx?: ReactNode
}

export const HeaderMobileDrawerItem: FC<Props> = ({
  icon,
  to = '',
  title,
  onClick,
  color = 'ondark.1',
  iconColor,
  textColor,
  iconReplacement,
  rightJsx,
}) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const isActive = to && match !== null
  const hoverRef = useRef<HTMLDivElement | null>(null)
  const isHovered = useHoverDirty(hoverRef)
  const shouldHighlight = isActive || isHovered

  return (
    <Flex
      h={5}
      align='center'
      justify='flex-start'
      pl={2.5}
      bg={shouldHighlight ? 'specialsat.500' : 'transparent'}
      onClick={onClick}
      as={to ? RRLink : 'button'}
      textTransform='none'
      w={224}
      borderWidth={0}
      ref={hoverRef}
      borderStartRadius={0}
      borderRightRadius='full'
      gap='2'
      pos='relative'

      {...(to ? { to } : {}) as any}
    >
      {iconReplacement || (
        <Text
          fontSize='3'
          color={shouldHighlight ? 'highlight.500' : iconColor || color}
          lineHeight='0'
        >
          {icon}
        </Text>
      )}

      <Box
        as='span'
        textStyle='bodySFat'
        color={shouldHighlight ? 'highlight.500' : textColor || color}
      >
        {title}
      </Box>

      {rightJsx}
    </Flex>
  )
}
