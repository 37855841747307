import { Box, BoxProps } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { UserSidebarNav } from 'presentation/components/UserSidebarNav'
import { LayoutBody, LayoutBodyHeight } from 'presentation/layouts/Layout/components/LayoutBody'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

const BILLING_SIDEBAR_W = 224

export const UserLayout: FC = () => {
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  return (
    <LayoutBody
      display='flex'
      alignItems='stretch'
      bg='graycool.200'
    >
      <Breakpoint
        mobSm={() => null}
        dtSm={() => (
          <LayoutBodyHeight
            heightMode='fixed'
            w={px(BILLING_SIDEBAR_W)}
            pos='sticky'
            top={px(headerHeight)}
          >
            <UserSidebarNav />
          </LayoutBodyHeight>
        )}
      />

      <Box
        flex='1 1 0'
        minW='0'
      >
        <ScrollInsideCardIllusion />

        <Outlet />
      </Box>
    </LayoutBody>
  )
}

const ScrollInsideCardIllusion = () => (
  <>
    <StickBelowHeader>
      <LayoutBodyHeight
        bg='graycool.100'
        borderLeftRadius={mbp({ mobSm: 0, dtSm: 4 })}
        boxShadow={mbp({ mobSm: 'none', dtSm: 'depth' })}
      />
    </StickBelowHeader>

    <StickBelowHeader>
      <ShadowHackConcealer />
    </StickBelowHeader>

    <StickBelowHeader zIndex='1'>
      <ShadowHackCastFromAbove />
    </StickBelowHeader>
  </>
)

/**
 * If you want to make sense of this hack, you can just remove/add these back,
 * as you observe the inset shadow of the layout when you scroll on desktop
 *
 * ---
 *
 * Explanation:
 *
 * Hides the inset shadow.
 *
 * Issue with that inset shadow in this layout is that, the inset shadow creates
 * an illusion of the content being in lower z-index than the sidebar and header.
 *
 * That becomes weird when you scroll within the layout and then the inset
 * shadow actually acts like a background, rather than shadow casting on the
 * layout content
 */
const ShadowHackConcealer: FC = () => (
  <Breakpoint
    dtSm={() => (
      <Box
        w='calc(100% - 40px)'
        ml='5'
        h='1'
        zIndex='0'
        bg='graycool.100'
      />
    )}
  />
)

/**
 * Recreates the inset shadow, but because you can render it after the content,
 * it now casts above the contentf
 */
const ShadowHackCastFromAbove: FC = () => (
  <Breakpoint
    dtSm={() => (
      <Box
        pos='relative'
        top='-8px'

        w='calc(100% - 40px)'
        ml='40px'
        h='2'

        // Hides the left edge of fake shadow
        overflow='hidden'
      >
        <Box
          h='1'
          boxShadow='-4px 4px 4px rgba(0,0,0,0.15)'
        />
      </Box>
    )}
  />
)

/**
 * Sticks to the top of the layout, below the header, without occupying
 * any space in the layout
 */
const StickBelowHeader: FC<BoxProps> = ({ children, ...props }) => {
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  return (
    <Box
      h='0'
      overflow='visible'
      pos='sticky'
      top={px(headerHeight)}
      {...props}
    >
      {children}
    </Box>
  )
}
