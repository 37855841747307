import { chakra } from '@chakra-ui/react'
import { MarkerType } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { PropsWithChildren } from 'react'

export type MarkerIconProps = PropsWithChildren<{
  markerType: MarkerType
}>

export const MarkerIconContainer = ({
  children,
  markerType,
}: MarkerIconProps) => {
  const position = markerType === 'pin'
    ? { x: 11, y: 14 }
    : markerType === 'tag'
      ? { x: 11, y: 11 }
      : {}
  return (
    <chakra.svg
      stroke='none'
      strokeWidth={1}
      fill='none'
      fillRule='evenodd'
      {...position}
    >
      {children}
    </chakra.svg>
  )
}
