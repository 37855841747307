import { Box, BoxProps, Button, ButtonProps, Divider, Flex, HStack, Text, TextProps, VStack } from '@chakra-ui/react'
import cn from 'classnames'
import { Array, Match, Option, pipe } from 'effect'
import { HashLink } from 'presentation/components/HashLink'
import { BinocularIcon, BookIcon, CartIcon, ListIcon, MailIcon, SkiptraceSuccessIcon, SquareWithArrowIcon, TwoPersonsIcon } from 'presentation/components/Icons'
import { SolidFeatherIcon, SolidPaperPlaneIcon, SolidPlaneIcon, SolidRocketIcon } from 'presentation/components/Icons/solid'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import CompsDetailDisclosure from 'presentation/screens/Plans/components/CompsDetailDisclosure/CompsDetailDisclosure'
import SkiptraceDetailDisclosure from 'presentation/screens/Plans/components/SkiptraceDetailDisclosure/SkiptraceDetailDisclosure'
import { fadeInUp, faster } from 'presentation/utils/animationClasses'
import { PropsWithChildren } from 'react'
import PlanCompactViewModel from './PlanCompactViewModel'

export type PlanCompactProps = {
  viewModel: PlanCompactViewModel
} & BoxProps

const PlanCompact = ({ viewModel, ...props }: PlanCompactProps) => (
  <PlanCompactViewModel.Provider viewModel={viewModel}>
    <PackageContainer {...props}>
      <PackageHeader />
      <Divider mt='3' mb='5' borderColor='grayweak.500' opacity={1} />
      <FeatureList />
      <SeePlanDetailsButton />
    </PackageContainer>
  </PlanCompactViewModel.Provider>
)

export default PlanCompact

const PackageHeader = () => {
  const vm = PlanCompactViewModel.usePresenter()
  const iconProps = {
    boxSize: 5,
    color: '#FFB636',
  }
  return (
    <VStack align='flex-start'>
      <HStack
        align='center'
        justifyContent='space-between'
        w='full'
      >
        <Text
          flex='1'
          textStyle={{
            base: 'h4',
            tabSm: 'h2',
            dtSm: 'h2',
          }}
          color='graystrong.500'
          casing='capitalize'
        >
          {vm.productNameText}
        </Text>
        {pipe(
          Match.value(vm.productIconType),
          Match.when('feather', _ => <SolidFeatherIcon {...iconProps} />),
          Match.when('paper-plane', _ => <SolidPaperPlaneIcon {...iconProps} />),
          Match.when('plane', _ => <SolidPlaneIcon {...iconProps} />),
          Match.when('rocket', _ => <SolidRocketIcon {...iconProps} />),
          Match.exhaustive,
        )}
      </HStack>
      <PackagePrice />
      <Box mt={2}>
        {vm.isCurrentPlan
          ? <CurrentPlanButton />
          : <AddToCartButton />}
      </Box>
      <PackageDescription mt={2} />
    </VStack>
  )
}

const PackageContainer = ({ children, ...props }: PropsWithChildren<BoxProps>) => (
  <Box
    className='package'
    w={{
      base: '250px',
      tabSm: '292px',
    }}
    {...props}
  >
    <ScrollAnimation
      staggerFactor='shorter'
      className={cn(fadeInUp, faster)}
    >
      <Flex
        flexDirection='column'
        bgColor='card.bg.1'
        borderRadius={{
          base: 3,
          tabSm: 4,
        }}
        shadow='primary.s'
        p={{ base: 3, tabSm: 4, dtLg: 4 }}
        pb={{ base: 2, tabSm: 2, dtSm: 2, dtLg: 2 }}
        px={{ dtSm: 3 }}
        pt={{ dtSm: 4 }}
      >
        {children}
      </Flex>
    </ScrollAnimation>
  </Box>
)

const PackagePrice = () => {
  const vm = PlanCompactViewModel.usePresenter()
  return (
    <HStack
      textAlign='center'
      align='center'
      spacing={1}
    >
      <Text
        as='span'
        textStyle={{
          base: 'display4Heavy',
          tabSm: 'display2Heavy',
          dtSm: 'display2Heavy',
        }}
        color={vm.planPriceTextColor}
      >
        {vm.planPriceText}
      </Text>

      <Text
        color='graystrong.200'
        textStyle={{
          base: 'bodyM',
          tabSm: 'bodyXL',
          dtSm: 'bodyXL',
        }}
        textAlign='left'
        whiteSpace='pre-wrap'
      >
        {vm.planPriceSideText}
      </Text>
    </HStack>
  )
}

/**
 * Buttons
 */
const PackageButton = ({
  children,
  ...props
}: ButtonProps) => (
  <Button
    size={{
      base: 'sm',
      tabSm: 'md',
    }}
    variant='solid'
    colorScheme='positive'
    {...props}
  >
    {children}
  </Button>
)

const CurrentPlanButton = (props: ButtonProps) => (
  <PackageButton isDisabled {...props}>
    Current Plan
  </PackageButton>
)

const AddToCartButton = (props: ButtonProps) => {
  const vm = PlanCompactViewModel.usePresenter()
  return (
    <PackageButton
      leftIcon={<CartIcon boxSize='3' />}
      onClick={vm.addToCart}
      isDisabled={vm.isDisabled}
      {...props}
    >
      Add to cart
    </PackageButton>
  )
}

const SeePlanDetailsButton = (props: ButtonProps) => {
  const vm = PlanCompactViewModel.usePresenter()
  return (
    <Button
      mt={3}
      as={HashLink}
      size='xl'
      variant='ghost'
      colorScheme='neutral'
      to={vm.seePlanDetailsLink}
      leftIcon={<SquareWithArrowIcon />}
      {...props}
    >
      See Plan Details
    </Button>
  )
}

const PackageDescription = (props: TextProps) => {
  const vm = PlanCompactViewModel.usePresenter()
  return (
    <Text
      textStyle={{
        base: 'bodyLFat',
        tabSm: 'bodyXLFat',
        dtSm: 'bodyXLFat',
        dtLg: 'h4-b',
      }}
      textAlign='left'
      color={vm.productDescTextColor}
      {...props}
    >
      {vm.productDescText}
    </Text>
  )
}

const FeatureList = ({ children, ...props }: BoxProps) => {
  const vm = PlanCompactViewModel.usePresenter()
  const iconProps = {
    boxSize: {
      base: 2,
      tabSm: 3,
      dtSm: 3,
    },
    color: 'accent.blue-to-gray',
    display: 'block',
  }
  return (
    <VStack
      alignItems='stretch'
      color='graystrong.500'
      gap={{
        base: 3,
        tabSm: 2,
      }}
      {...props}
    >
      {Array.map(vm.featureLineItems, (item, i) => (
        <HStack
          spacing={{ base: 1, tabSm: 1.5 }}
          key={i}
        >
          <Box>
            {pipe(
              Match.value(item.iconType),
              Match.when('two-persons', () => <TwoPersonsIcon {...iconProps} />),
              Match.when('binocular', () => <BinocularIcon {...iconProps} />),
              Match.when('car', () => <CartIcon {...iconProps} />),
              Match.when('book', () => <BookIcon {...iconProps} />),
              Match.when('list', () => <ListIcon {...iconProps} />),
              Match.when('skip', () => <SkiptraceSuccessIcon {...iconProps} />),
              Match.when('mail', () => <MailIcon {...iconProps} />),
              Match.orElse(() => null),
            )}
          </Box>
          <Box
            textStyle={{
              base: 'bodyMFat',
              tabSm: 'bodyXLFat',
            }}
          >
            {item.featureNameText}
          </Box>
          <HStack
            ml='auto'
            gap='0.5'
          >
            <Text
              textAlign='right'
              textTransform='capitalize'
              textStyle={{ base: 'bodyMFat', tabSm: 'bodyLFat' }}
              color={item.featureValueTextColor}
            >
              {item.featureValueText}
            </Text>
            {item.disclosure.pipe(
              Option.match({
                onSome: v => pipe(
                  Match.value(v),
                  Match.when({ type: 'skiptrace' }, disclosure => (
                    <SkiptraceDetailDisclosure
                      propertyCount={disclosure.value}
                      iconProps={{
                        color: item.featureValueTextColor,
                      }}
                    />
                  )),
                  Match.when({ type: 'comps' }, disclosure => (
                    <CompsDetailDisclosure
                      propertyCount={disclosure.value}
                      iconProps={{
                        color: item.featureValueTextColor,
                      }}
                    />
                  )),
                  Match.orElse(() => null),
                ),
                onNone: () => null,
              }),
            )}
          </HStack>
        </HStack>
      ))}
    </VStack>
  )
}
