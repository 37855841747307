import { GetOwnerDealsQuery } from '__generated__/graphql'
import { Array, pipe } from 'effect'
import { partialDealFromRawDeal, propertyFromRawParcel } from 'features/NearbyBuyers/repository/GetNearbyBuyers.schema'
import GetOwnerDeals from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals'

namespace GetOwnerDealsSchema {
  export const resultFromGraphQL = (
    raw: GetOwnerDealsQuery,
  ): GetOwnerDeals.Result => {
    if (
      raw.historicalDeals.__typename === 'ParcelContactNotFoundError'
      || raw.currentlyOwnedDeals.__typename === 'ParcelContactNotFoundError'
    ) throw new Error('Owner not found')

    const currentlyOwnedDeals = pipe(
      raw.currentlyOwnedDeals.buyerDetails?.historicalDeals.edges || [],
      Array.map(edge => ({
        isCurrentlyOwned: true,
        deal: partialDealFromRawDeal(edge.node),
        property: propertyFromRawParcel(edge.node.parcel),
      })),
    )

    const currentlyOwnedIds = new Set(
      currentlyOwnedDeals.map(dealRelation => dealRelation.property.id),
    )

    const historicalDeals = pipe(
      raw.historicalDeals.buyerDetails?.historicalDeals.edges || [],
      Array.map(edge => ({
        isCurrentlyOwned: false,
        deal: partialDealFromRawDeal(edge.node),
        property: propertyFromRawParcel(edge.node.parcel),
      })),
      Array.filter(dealRelation => !currentlyOwnedIds.has(dealRelation.property.id)),
    )

    const dealRelations = [...currentlyOwnedDeals, ...historicalDeals]

    return GetOwnerDeals.Result.Success({
      dealRelations,
    })
  }
}

export default GetOwnerDealsSchema
