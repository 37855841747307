import { Box, Center, Flex, Grid, GridItem, Spinner, Tbody, Text, VStack } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SwitchTabButton, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { CMADataUpdateButton, CMADataUpdateDate } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { CMAEntriesNoMatch } from 'presentation/screens/CompsScreen/components/CMAEntriesNoMatch'
import { CMAEntriesSortMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar'
import { CompsTable, CompsTableContainer } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable.components'
import { COMPS_TABLE_ID } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable.const'
import { CMASummaryStatistics } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CMASummaryStatistics'
import { CompRow } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompRow'
import { CompTableHeaderRow } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompTableHeaderRow'
import { CompsStickyRows } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/components/CompsStickyRows'
import { RowData, useEntriesTableStore } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { createSummaryStatistics, getFilterValuesForStatus } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { CompsTableStickyOverreachFix } from 'presentation/screens/CompsScreen/hooks/CompsTableStickyOverreachFix'
import { CompsTableScroller } from 'presentation/screens/CompsScreen/hooks/CompsTableViewScroller'
import { selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

export const CMAEntriesTable = () => {
  const { totalResult, subjectRow, ...entriesTable } = useEntriesTableStore(store => ({
    totalResult: store.compCount,
    status: store.compStatus,
    subjectRow: (store.rows[0] || null) as RowData | null,
  }), shallow)

  // Consider comps unready when filtering or regenerating
  const isCompsReady = useCMAStore(state => state.local.report.status === 'loaded')
  const status = isCompsReady ? entriesTable.status : 'loading'

  const highlightedEntry = CompsMapAndListSync.useStore(state => state.highlightedListItem)

  // On render, set fallback highlighted entry
  useEffect(() => {
    if (!highlightedEntry && subjectRow)
      CompsMapAndListSync.setHighlightedEntry(subjectRow.comp.id)
  }, [])

  return (
    <Flex
      flexDirection='column'
      h='100%'
    >
      {/**
       * ========================================
       * Header Section
       * ========================================
       */}
      <Flex flexDirection='column' p={3}>
        <Grid
          alignItems='center'
          justifyContent='space-between'
          gap={2}
          gridTemplate='"title result meta actions" auto / auto 1fr auto auto'
        >
          <GridItem
            gridArea='title'
          >
            <Text
              textStyle='h4'
              color='accent.blue-text'
            >
              <Breakpoint
                mobSm='Comparable Market Analysis'
                dtSm='Comp Market Analysis'
                dtLg='Comparative Market Analysis'
              />
            </Text>
          </GridItem>
          <GridItem
            gridArea='result'
          >
            <Text
              textStyle='bodyL'
              color='ondark.6'
            >
              (
              {totalResult}
              {' '}
              {pluralize('result', totalResult)}
              )
            </Text>
          </GridItem>

          <GridItem
            gridArea='meta'
            justifySelf='end'
          >
            <VStack spacing={0} alignItems='flex-end'>
              <CMADataUpdateButton mr={-1} />
              <CMADataUpdateDate />
            </VStack>
          </GridItem>

          <GridItem
            gridArea='actions'
          >
            <CMAEntriesSortMenu />
          </GridItem>
        </Grid>
      </Flex>

      {/**
       * ========================================
       * Table Section
       * ========================================
       */}
      {pipe(
        Match.value(status),
        Match.when('loading', () => (
          <Center flex={1} minH='240px'><Spinner /></Center>
        )),
        Match.when('empty', () => (
          <CMAEntriesNoMatch flex={1} m={3} mt={0} />
        )),
        Match.when('loaded', () => (
          <>
            <CompsTableWithContent />

            <TableSummary />

            <CompsMLSDisclaimer
              px={3}
              my={4}
              w='100%'
            />
          </>
        )),
        Match.exhaustive,
      )}
    </Flex>
  )
}

const CompsTableWithContent = () => {
  const rows = useEntriesTableStore(store => store.rows)
  const tableMinHeight = CompsTableScroller.useStore(state => state.stickyHeight)

  if (rows === null) return null

  const subjectRow = rows[0]
  const compRows = rows.slice(1)
  return (
    <>
      <CompsStickyRows />
      <CompsTableContainer>
        <CompsTable
          id={COMPS_TABLE_ID}
          minH={tableMinHeight}
          ref={CompsTableStickyOverreachFix.setTableElement}
        >
          <CompTableHeaderRow />
          <Tbody
            sx={{
            /** Make last rows corner cells round */
              'tr:last-of-type td:first-of-type': {
                borderBottomLeftRadius: 2,
                overflow: 'hidden',
              },
              'tr:last-of-type td:last-of-type': {
                borderBottomRightRadius: 2,
                overflow: 'hidden',
              },
            }}
          >
            <CompRow
              key={subjectRow.comp.id}
              rowData={subjectRow}
            />
            {compRows.map(row => (
              <CompRow
                key={row.comp.id}
                rowData={row}
              />
            ))}
          </Tbody>
        </CompsTable>
      </CompsTableContainer>
    </>
  )
}

export const TableSummary = () => {
  const comps: CMA.SingleComp[] = useEntriesTableStore(store =>
    store.rows.filter(v => v.comp.type === 'single-comp').map(v => v.comp) as CMA.SingleComp[])
  const { layoutWidth } = useLayoutWidth()
  const activeTab = useActiveEntriesTab(selectActiveTab)

  return (
    <Box w={layoutWidth} overflow='auto'>
      <SwitchTabsWrapper
        mt={3}
        px={3}
        my={4}
      >
        <SwitchTabButtons>
          <SwitchTabButton>
            All
          </SwitchTabButton>
          <SwitchTabButton>
            {activeTab === 'RENTALS' ? 'Leased' : 'Sold'}
          </SwitchTabButton>
          <SwitchTabButton>
            Pending
          </SwitchTabButton>
          <SwitchTabButton>
            Active
          </SwitchTabButton>
          <SwitchTabButton>
            Off Market
          </SwitchTabButton>
        </SwitchTabButtons>
        <SwitchTabContents borderBottomRadius={2} overflow='hidden'>
          <SwitchTabContent>
            <TabTitle />
            <AllSummary comps={comps} />
          </SwitchTabContent>
          <SwitchTabContent>
            <TabTitle />
            <SoldSummary comps={comps} />
          </SwitchTabContent>
          <SwitchTabContent>
            <TabTitle />
            <PendingSummary comps={comps} />
          </SwitchTabContent>
          <SwitchTabContent>
            <TabTitle />
            <ActiveSummary comps={comps} />
          </SwitchTabContent>
          <SwitchTabContent>
            <TabTitle />
            <OffMarketSummary comps={comps} />
          </SwitchTabContent>
        </SwitchTabContents>
      </SwitchTabsWrapper>
    </Box>
  )
}

const AllSummary = (props: { comps: CMA.SingleComp[] }) => {
  const all = createSummaryStatistics(
    props.comps,
    { shouldBaseOnIncludedOnly: false },
  )
  return (
    <CMASummaryStatistics report={all} />
  )
}

const SoldSummary = (props: { comps: CMA.SingleComp[] }) => {
  const soldOrLeased = createSummaryStatistics(
    props.comps.filter(v => v.status === 'SOLD' || v.status === 'LEASED'),
    { shouldBaseOnIncludedOnly: false },
  )
  return (
    <CMASummaryStatistics report={soldOrLeased} />
  )
}

const PendingSummary = (props: { comps: CMA.SingleComp[] }) => {
  const pending = createSummaryStatistics(
    props.comps.filter(v => v.status === 'PENDING'),
    { shouldBaseOnIncludedOnly: false },
  )
  return (
    <CMASummaryStatistics report={pending} />
  )
}

const ActiveSummary = (props: { comps: CMA.SingleComp[] }) => {
  const active = createSummaryStatistics(
    props.comps.filter(v => getFilterValuesForStatus('ACTIVE').includes(v.status)),
    { shouldBaseOnIncludedOnly: false },
  )
  return (
    <CMASummaryStatistics report={active} />
  )
}

const OffMarketSummary = (props: { comps: CMA.SingleComp[] }) => {
  const offMarket = createSummaryStatistics(
    props.comps.filter(v => getFilterValuesForStatus('OFF_MARKET').includes(v.status)),
    { shouldBaseOnIncludedOnly: false },
  )
  return (
    <CMASummaryStatistics report={offMarket} />
  )
}

const TabTitle = () => (
  <Text
    bgColor='neutral.500'
    textStyle='bodyMFat'
    color='ondark.1'
    py={1}
    px={3}
    borderTopRightRadius={2}
  >
    Summary Statistics
  </Text>
)
