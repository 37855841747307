import { Box, Button, CardProps, Link as ChakraLink, Checkbox, Flex, FlexProps, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import { Match, Option, pipe } from 'effect'
import { Funds } from 'features/Funds/domain/Funds.domain'
import { Dollar } from 'libs/dollar'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PlusIcon, TriangleExclamationOutlineIcon } from 'presentation/components/Icons'
import { Card, CardPrimaryTitle, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo, CardSegment } from 'presentation/components/molecules/Card/CardCombo'
import { LEGAL_PRIVACY_URL } from 'presentation/const/env.const'
import { BillingSummarySegment } from 'presentation/main/globalModals/PaymentDetailsModal/BillingSummarySegment'
import { openWalletBalanceEntryModal } from 'presentation/screens/Billing/Billing.modals'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { ConsumableType, consumableDisplayText } from './ConsumableCircularStat'
import { ConsumableInfoCard } from './ConsumableInfoCard'

export type ConsumableAttemptBillingSummaryCardProps = {
  isColumnCard?: boolean
  onCancel?: () => void
  title: string
  subtitle: string
  consumableType: ConsumableType
  paymentPrice: number
  freeItemExceedCount?: number
  refreshingConsumables: number
  remainingConsumables: number
  pricePenniesPerUnit: number
  consumablesRefreshDate: string
  currentPlan: string
  fundsStatus: Funds.Status
  availableFunds: number
  onAttempt: () => void
  isProcessing?: boolean
  upgradeUrl?: string
  quantity: number
  onUseFreeSkips?: () => void
} & CardProps

export const ConsumableAttemptBillingSummaryCard = (props: ConsumableAttemptBillingSummaryCardProps) => {
  const {
    onCancel,
    isColumnCard,
    title,
    subtitle,
    consumableType,
    paymentPrice,
    freeItemExceedCount,
    refreshingConsumables,
    remainingConsumables,
    pricePenniesPerUnit,
    consumablesRefreshDate,
    currentPlan,
    fundsStatus,
    availableFunds,
    onAttempt,
    isProcessing = false,
    upgradeUrl = '/pricing#first-choose-plan',
    quantity,
    onUseFreeSkips,
    ...cardProps
  } = props

  const [isAuthorized, setAuthorized] = useState(false)

  const isInsufficientFunds = fundsStatus === 'insufficient-funds'

  const isPayNowDisabled = fundsStatus === 'sufficient-consumables'
    ? false
    : (isInsufficientFunds || !isAuthorized)

  const priceFormatted = pipe(
    paymentPrice,
    Dollar.fromCents,
    Dollar.formatNumberWithCommasDecimals,
  )

  const freeItemExceedCountOption = Option.fromNullable(freeItemExceedCount).pipe(
    Option.flatMap(Option.liftPredicate(n => n > 0)),
  )

  const displayText = consumableDisplayText[consumableType]
  const singularTerm = displayText.singular
  const term = displayText.term
  const shortName = displayText.shortName

  return (
    <Card
      variant='default'
      colorScheme='modal.highlight'
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', tabSm: 'lg-locked', dtLg: isColumnCard ? 'sm-locked' : 'lg-locked' })}
      {...cardProps}
    >
      <CardPrimaryTitle>
        {pipe(
          Match.value(fundsStatus),
          Match.when('sufficient-consumables', () => `Use Your Free ${pluralize(singularTerm)}`),
          Match.when('sufficient-funds', () => `Pay for this ${singularTerm}`),
          Match.when('insufficient-funds', () => `Pay for this ${singularTerm}`),
          Match.exhaustive,
        )}
      </CardPrimaryTitle>

      {pipe(
        freeItemExceedCountOption,
        Option.match({
          onSome: propertyCount => (
            <Text
              mt={mbp({ mobSm: 2, mob: 3, tabSm: 2, dtLg: isColumnCard ? 3 : 2 })}
              textStyle='bodyXLFat'
              color='graystrong.200'
            >
              You have
              {' '}
              <Text
                as='span'
                color='negative.500'
              >
                {NumberLib.formatComma(propertyCount)}
              </Text>
              {' '}
              remaining properties exceeding your free limit.
              {' '}
              Do you want to pay for additional fee to proceed?
            </Text>
          ),
          onNone: () => (
            <>
              <Text
                mt={mbp({ mobSm: 2, mob: 3, tabSm: 2, dtLg: isColumnCard ? 3 : 2 })}
                textStyle='bodyXLFat'
                color='graystrong.200'
              >
                {subtitle || (
                  fundsStatus === 'sufficient-consumables'
                    ? `Do you want to use your free ${pluralize(term, 2)}?`
                    : `You've run out of ${pluralize(term, 0)} for this month. Do you want to pay for an additional ${pluralize(term, 1)}?`
                )}
              </Text>
            </>
          ),
        }),
      )}

      <ConsumableInfoCard
        mt='2'
        totalFree={refreshingConsumables}
        remainingFree={remainingConsumables}
        itemPrice={Dollar.formatPennies(pricePenniesPerUnit)}
        currentPlan={currentPlan}
        nextRenewalDate={consumablesRefreshDate}
        consumableType={consumableType}
        upgradeUrl={upgradeUrl}
      />

      {fundsStatus !== 'sufficient-consumables' && (
        <>
          <Box mt={mbp({ mobSm: 3, mob: 4, tabSm: 5, dtLg: isColumnCard ? 4 : 5 })}>
            <CardSecondaryTitle alignSelf='start'>
              Billing Summary
            </CardSecondaryTitle>

            <Grid
              gridTemplateAreas='"layer"'
              mt={mbp({ mobSm: 2, mob: 3 })}
            >
              <CardCombo gridArea='layer' zIndex={1}>
                <BillingAddFundsSegment
                  isColumnCard={isColumnCard}
                  availableFunds={availableFunds}
                  isInsufficientFunds={isInsufficientFunds}
                />
                <BillingSummarySegment
                  {...isColumnCard && {
                    px: 2,
                    pt: 2,
                    pb: 2,
                    borderBottomRadius: 3,
                  }}
                  items={[{
                    label: singularTerm.toUpperCase(),
                    price: pricePenniesPerUnit * quantity,
                    quantity,
                  }]}
                  {...isInsufficientFunds
                    ? { pb: '80px' }
                    : {}}
                />
              </CardCombo>

              {isInsufficientFunds && (
                <GridItem
                  gridArea='layer'
                  zIndex={2}
                  display='flex'
                  gap={1}
                  justifyContent='flex-start'
                  alignSelf='end'
                  px={mbp({ mobSm: '2', tabSm: '4', dtLg: isColumnCard ? '2' : '4' })}
                  pb={4}
                >
                  <TriangleExclamationOutlineIcon boxSize={3} color='negative.500' />
                  <Text textStyle='bodyL' color='graystrong.200'>
                    Insufficient marketing funds
                  </Text>
                </GridItem>
              )}
            </Grid>
          </Box>

          <TermsAndPolicy
            mt={mbp({ mobSm: 2, mob: 3 })}
            isAuthorized={isAuthorized}
            onAuthorizationChange={setAuthorized}
            price={priceFormatted}
            consumableType={singularTerm}
          />
        </>
      )}

      <Stack
        justifyContent='center'
        gap={3}
        mt={mbp({ mobSm: 3, mob: 4, tabSm: 5 })}
        direction={{
          base: 'column',
          tabSm: 'row',
        }}
      >
        {onUseFreeSkips
          ? (
            <Button
              variant='outline'
              colorScheme='neutral'
              size='lg'
              onClick={onUseFreeSkips}
              order={{
                base: '2',
                tabSm: '1',
              }}
            >
              Use Free Skips Only
            </Button>
          )
          : onCancel
            ? (
              <Button
                variant='outline'
                colorScheme='neutral'
                size='lg'
                onClick={onCancel}
                order={{
                  base: '2',
                  tabSm: '1',
                }}
              >
                Cancel
              </Button>
            )
            : null}
        <Button
          w={mbp({ mobSm: onCancel ? 'auto' : 'full', mob: 'auto' })}
          variant='solid'
          colorScheme='positive'
          size='lg'
          isDisabled={isPayNowDisabled}
          onClick={onAttempt}
          isLoading={isProcessing}
          order={{
            base: '1',
            tabSm: '2',
          }}
        >
          {fundsStatus !== 'sufficient-consumables'
            ? 'Pay Now'
            : `Use Free ${pluralize(shortName, 2)}`}
        </Button>
      </Stack>
    </Card>
  )
}

type BillingAddFundsSegmentProps = {
  isColumnCard?: boolean
  availableFunds: number
  isInsufficientFunds: boolean
}

const BillingAddFundsSegment = (props: BillingAddFundsSegmentProps) => {
  const { availableFunds, isColumnCard } = props
  const isInsufficientFunds = availableFunds === 0

  const noFundsBgColor = isInsufficientFunds
    ? 'negative.50'
    : 'card.bg.2'

  return (
    <CardSegment
      px={mbp({ mobSm: '2', tabSm: '4', dtLg: isColumnCard ? '2' : '4' })}
      py={mbp({ mobSm: '2', tabSm: '3', dtLg: isColumnCard ? '2' : '3' })}
      bg={noFundsBgColor}
      borderTopRadius={mbp({ mobSm: '2', tabSm: '4', dtLg: isColumnCard ? '3' : '4' })}
      flexDirection='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box>
        <Text
          textStyle='bodyMFat'
          color='graystrong.400'
        >
          Account Balance
        </Text>
        <Text
          textStyle={mbp({ mobSm: 'h4', tabSm: 'h2' })}
          color='graystrong.400'
        >
          {pipe(
            availableFunds,
            Dollar.fromCents,
            Dollar.formatNumberWithCommasDecimals,
          )}
        </Text>
      </Box>
      <Button
        variant={isInsufficientFunds ? 'solid' : 'ghost'}
        h={isInsufficientFunds ? '5' : 'auto'}
        colorScheme='neutral'
        size='xs'
        leftIcon={<Breakpoint tabSm={<PlusIcon boxSize={2} />} />}
        onClick={openWalletBalanceEntryModal}
      >
        Add Funds
      </Button>
    </CardSegment>
  )
}

type TermsAndPolicyProps = {
  isAuthorized?: boolean
  onAuthorizationChange?: (isAuthorized: boolean) => void
  price: string
  consumableType: string
} & FlexProps

const TermsAndPolicy = (props: TermsAndPolicyProps) => {
  const { isAuthorized, onAuthorizationChange, price, consumableType, ...flexProps } = props
  return (
    <Flex alignItems='start' gap={1} {...flexProps}>
      <Checkbox
        isChecked={isAuthorized}
        onChange={e => onAuthorizationChange?.(e.target.checked)}
      />
      <Box>
        <Text
          textStyle={{ mobSm: 'bodyM', mob: 'bodyL' }}
          color='modal.text'
        >
          I understand and authorize Propelio to deduct
          {' '}
          {price}
          {' '}
          from my marketing funds.
          By checking this box and clicking the button below, I agree to these terms,
          as well as Propelio&rsquo;s
          &nbsp;
          <ChakraLink color='link' href={LEGAL_PRIVACY_URL} isExternal>
            PRIVACY POLICY
          </ChakraLink>
          {' '}
          and
          &nbsp;
          <ChakraLink color='link' href='/termsofuse' isExternal>
            TERMS OF SERVICE
          </ChakraLink>
          .
        </Text>
        <Text
          textStyle={{ mobSm: 'bodyMFat', mob: 'bodyLFat' }}
          color='negative.500'
          mt={3}
        >
          The
          {' '}
          {consumableType.toLowerCase()}
          {' '}
          service is non-refundable.
        </Text>
      </Box>
    </Flex>
  )
}
