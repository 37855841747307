import { Router } from '@remix-run/router'
import { Context } from 'effect'

/** @TODO Rename to AppRouterService */
class AppRouter extends Context.Tag('AppRouter')<
  AppRouter,
  { router: Router }
>() {}

namespace AppRouter {
  export type Shape = Context.Tag.Service<AppRouter>
}

export default AppRouter
