import { useTheme } from '@chakra-ui/react'
import { Settings } from 'features/Settings/domain/Settings.domain'
import { useEffect } from 'react'

/**
 * Adds className to body to indicate the current theme for certain overrides
 */
export const useHTMLThemeAttributes = (): void => {
  const theme = useTheme()

  useEffect(() => {
    const themeVariants: Settings.Theme[] = ['light', 'dark']
    themeVariants.forEach(t => {
      document.body.classList.remove(t)
    })

    document.body.classList.add(theme.id)
    document.documentElement.setAttribute('data-theme', theme.id)
  }, [theme.id])
}
