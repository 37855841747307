export const createSingleton = <TParams, Type>(
  makeInstance: (params: TParams) => Type,
) => {
  let instance: Type
  const createSingleton = (params: TParams): Type => {
    if (instance) return instance
    instance = makeInstance(params)
    return instance
  }

  return createSingleton
}

export const createAwaitableSingleton = <TParams, Type>(
  makeInstance: (params: TParams) => Type,
) => {
  let instance: Type
  let resolve: (instance: Type) => void

  const promise = new Promise<Type>(_resolve => {
    resolve = _resolve
  })

  const createSingleton = (params: TParams): Type => {
    if (instance) return instance
    instance = makeInstance(params)
    resolve(instance)
    return instance
  }

  const getInstance = async (): Promise<Type> => await promise

  return {
    createSingleton,
    getInstance,
  }
}
