import { createIcon } from '@chakra-ui/react'

export const LeafIcon = createIcon({
  displayName: 'LeafIcon',
  viewBox: '0 0 44 41',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M22 15.8667C22 12.1537 20.525 8.59271 17.8995 5.96721C15.274 3.3417 11.713 1.8667 8 1.8667H1V6.53337C1 10.2464 2.475 13.8074 5.10051 16.4329C7.72601 19.0584 11.287 20.5334 15 20.5334H22M22 25.2C22 21.487 23.475 17.926 26.1005 15.3005C28.726 12.675 32.287 11.2 36 11.2H43V13.5334C43 17.2464 41.525 20.8074 38.8995 23.4329C36.274 26.0584 32.713 27.5334 29 27.5334H22M22 39.2V15.8667' stroke='#FFB636' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
