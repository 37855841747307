import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import { HEADER_H_MOBILE } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile.const'
import { px } from 'presentation/utils/px'
import { ReactNode } from 'react'

export const TitleBar = ({ title, leftJsx, rightJsx, ...props }: FlexProps & {
  title?: string
  leftJsx: ReactNode
  rightJsx?: ReactNode
}) => (
  <Flex
    bg='special.500'
    h={px(HEADER_H_MOBILE)}
    alignItems='center'
    position='sticky'
    top='0'
    zIndex='1'
    px='1.5'
    {...props}
  >
    {leftJsx}

    <Box flex={1}>
      <Text color='ondark.1' textStyle='h4-b' textAlign='center'>
        {title}
      </Text>
    </Box>

    {rightJsx || <Box boxSize={4} />}
  </Flex>
)
