import { get, isPlainObject, set } from 'lodash/fp'

export type NestedObject = {
  [key: string]: string | number | NestedObject
}

export type InputObject = Record<string, NestedObject>

/**
 * Please see tests for usage examples.
 * @TODO Stronger typing
 * @TODO Refactor; Apply functional programming
 */
export const groupLeavesByTopLevelKeys = (
  input: InputObject,
): NestedObject => {
  let output: any = {}

  const iterate = (
    topLevelKey: string,
    subPath: string[] | null,
    value: NestedObject,
  ) => {
    Object.entries(value).forEach(([key, value]) => {
      const path = subPath ? [...subPath, key] : [key]

      if (isPlainObject(value)) {
        iterate(topLevelKey, path, value as any)
      } else {
        const existing = get(path, output)
        output = set(
          path,
          {
            ...existing,
            [topLevelKey]: value,
          },
          output,
        )
      }
    })
  }

  Object.entries(input).forEach(([key, value]) => iterate(key, null, value))

  return output
}
