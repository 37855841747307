import { createIcon } from '@chakra-ui/react'

export const SunIcon = createIcon({
  displayName: 'SunIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath='url(#clip0_2741_179480)'>
        <path d='M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 1V3' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M12 21V23' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.21997 4.21997L5.63997 5.63997' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M18.36 18.36L19.78 19.78' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1 12H3' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M21 12H23' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.21997 19.78L5.63997 18.36' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M18.36 5.63997L19.78 4.21997' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2741_179480'>
          <rect width='24' height='24' fill='currentColor' />
        </clipPath>
      </defs>
    </>
  ),
})
