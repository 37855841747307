import { Match, pipe } from 'effect'
import { PartialRange } from 'features/valueObjects/Range'
import { BedsAndBathsFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/BedsAndBathsFilterDropdown/BedsAndBathsFilterDropdown.dumb.props'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { FilterContentGroup } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/FilterContentGroup'
import { MatchSubjectCheckbox } from 'presentation/screens/CompsScreen/components/common/MatchSubjectCheckbox'
import { PartialRangeFormErrors, PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { isNonNullable } from 'utils/isNonNullable'

export const BedsAndBathsFilterDropdownDumb = (
  props: BedsAndBathsFilterDropdownDumbProps,
) => {
  const [hasError, setHasError] = useState(false)

  const selectedValue = formatSelectedValue({
    beds: props.bedsFilter.value.range,
    baths: props.bathsFilter.value.range,
  })

  const isBedsMatchingSubject = props.bedsFilter.subjectValue !== null
    && props.bedsFilter.value.range?.[0] === props.bedsFilter.subjectValue
    && props.bedsFilter.value.range?.[1] === props.bedsFilter.subjectValue
  const isBathsMatchingSubject = props.bathsFilter.subjectValue !== null
    && props.bathsFilter.value.range?.[0] === props.bathsFilter.subjectValue
    && props.bathsFilter.value.range?.[1] === props.bathsFilter.subjectValue

  const handleOnError = (errors: PartialRangeFormErrors) =>
    isNonNullable(errors.min || errors.max) ? setHasError(true) : setHasError(false)

  return (
    <FilterPopover>
      <FilterPopoverButton
        label='Beds & Baths'
        display={mbp({ mobSm: 'none', dtSm: 'flex' })}
        minW={mbp({ mobSm: '130px', dtLg: '150px' })}
        hasError={hasError}
      >
        {selectedValue}
      </FilterPopoverButton>
      <FilterPopoverContent>
        <FilterContentGroup
          title='Beds'
          toolbar={(
            props.bedsFilter.subjectValue !== null && (
              <MatchSubjectCheckbox
                label='Match Subject Property'
                isChecked={isBedsMatchingSubject}
                onChange={() => {
                  props.bedsFilter.onChange({
                    range: isBedsMatchingSubject
                      ? [null, null]
                      : [props.bedsFilter.subjectValue, props.bedsFilter.subjectValue],

                    /** @GENESIS */
                    shouldMatchSubjectProperty: false,
                  })
                }}
              />
            )
          )}
        >
          <PartialRangeInputV2
            /**
               * Rerender when matching/unmatching subject property to reapply initialValue
               */
            key={isBedsMatchingSubject.toString()}
            initialValue={props.bedsFilter.value.range}
            onDebouncedValidChange={newRange => {
              props.bedsFilter.onChange({
                range: newRange,

                /** @GENESIS */
                shouldMatchSubjectProperty: false,
              })
            }}
            subjectValue={props.bedsFilter.subjectValue?.toString()}
            onError={handleOnError}
          />
        </FilterContentGroup>
        <FilterContentGroup
          mt={3}
          title='Baths'
          toolbar={props.bathsFilter.subjectValue !== null && (
            <MatchSubjectCheckbox
              label='Match Subject Property'
              isChecked={isBathsMatchingSubject}
              onChange={() => {
                props.bathsFilter.onChange({
                  range: isBathsMatchingSubject
                    ? [null, null]
                    : [props.bathsFilter.subjectValue, props.bathsFilter.subjectValue],

                  /** @GENESIS */
                  shouldMatchSubjectProperty: false,
                })
              }}
            />
          )}
        >
          <PartialRangeInputV2
            /**
               * Rerender when matching/unmatching subject property to reapply initialValue
               */
            key={isBathsMatchingSubject.toString()}
            initialValue={props.bathsFilter.value.range}
            onDebouncedValidChange={newRange => {
              props.bathsFilter.onChange({
                range: newRange,

                /** @GENESIS */
                shouldMatchSubjectProperty: false,
              })
            }}
            subjectValue={props.bathsFilter.subjectValue?.toString()}
            onError={handleOnError}
          />
        </FilterContentGroup>

        {/* @GENESIS */}
        {/* <FilterActionButtonGroup>
          <FilterResetButton onClick={handleOnReset} />
          <FilterDoneButton
            onClick={handleOnDone}
            isDisabled={hasError.beds || hasError.baths}
          />
        </FilterActionButtonGroup> */}

        {/* @GENESIS */}
        {/* <Flex
          ml={2}
          flexDirection='column'
          gap={3}
        >
          {props.bathsFilter.filterErrorMsg && (
            <CompsFilterCardError error={props.bathsFilter.filterErrorMsg} />
          )}

          {props.bedsFilter.filterErrorMsg && (
            <CompsFilterCardError error={props.bedsFilter.filterErrorMsg} />
          )}
        </Flex> */}
      </FilterPopoverContent>
    </FilterPopover>
  )
}

const formatRange = (range: PartialRange | null) => pipe(
  Match.value({
    min: range?.[0] ?? null,
    max: range?.[1] ?? null,
  }),
  Match.when({ min: Match.number, max: Match.null },
    ({ min }) => `${min}+`),
  Match.when({ min: Match.null, max: Match.number },
    ({ max }) => `Up to ${max}`),
  Match.when({ min: Match.number, max: Match.number },
    ({ min, max }) => `${min} - ${max}`),
  Match.orElse(() => 'Any'),
)

const formatSelectedValue = (params: {
  beds: PartialRange | null
  baths: PartialRange | null
}) =>
  `${formatRange(params.beds)} Bd/${formatRange(params.baths)} Ba`
