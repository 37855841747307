import { StackProps, VStack } from '@chakra-ui/react'
import { CheckCircleIcon, CloseCircleFilledIcon } from 'presentation/components/Icons'
import { Text, TextProps } from '@chakra-ui/react'

export const PrimaryText = ({ children, ...props }: TextProps) => (
  <Text
    textStyle={{
      base: 'body-b',
      mob: 'h4-b',
      dtSm: 'h3-b',
    }}
    color='graystrong.500'
    {...props}
  >
    {children}
  </Text>
)

export const SecondaryText = ({ children, ...props }: TextProps) => (
  <Text
    textStyle={{
      base: 'body2',
      dtSm: 'body',
    }}
    color='graystrong.200'
    {...props}
  >
    {children}
  </Text>
)

export const EmphasisText = ({ children, ...props }: TextProps) => (
  <Text
    textStyle={{
      base: 'body-h-b',
      dtSm: 'h3-h-b',
    }}
    color='positive.500'
    textAlign='center'
    {...props}
  >
    {children}
  </Text>
)

export type FeatureAvailabilityProps = {
  isAvailable?: boolean
} & StackProps

export const FeatureAvailability = ({
  isAvailable,
}: FeatureAvailabilityProps) => (
  <VStack justify='center' spacing='1'>
    {isAvailable
      ? (
        <CheckCircleIcon
          color='positive.500'
          fontSize='4.5'
        />
      )
      : (
        <CloseCircleFilledIcon
          color='graystrong.200'
          fontSize='4.5'
        />
      )}
  </VStack>
)
