// eslint-disable-next-line no-restricted-imports
import { Card, Modal as ChakraModal, ModalContent as ChakraModalContent, ModalProps as ChakraModalProps, ModalContentProps, ResponsiveValue } from '@chakra-ui/react'
import { CardColorScheme, CardSize, CardVariant } from 'presentation/components/molecules/Card/Card.types'
import { setupContext } from 'presentation/utils/context'
import { FC, useEffect, useState } from 'react'

type ModalContext = {
  variant: ResponsiveValue<CardVariant> | CardVariant
  colorScheme: CardColorScheme
  size: ResponsiveValue<CardSize> | CardSize
}

const [Provider, useContext] = setupContext<ModalContext>('Modal', {
  variant: 'default',
  colorScheme: 'card.bg.1',
  size: 'modal.default.n',
})

export type ModalPropsV1 = ChakraModalProps & ModalContext

/** @deprecated */
export const ModalV1: FC<ModalPropsV1> = ({
  variant,
  colorScheme,
  size,
  ...props
}) => {
  const [cardProps, setCardProps] = useState<ModalContext>({
    variant: variant || 'default',
    colorScheme: colorScheme || 'card.bg.1',
    size: size || 'modal.default.n',
  })

  const variantKey = Array.isArray(variant) ? variant.map(v => !v ? 'null' : v).join('.') : variant
  const colorSchemeKey = colorScheme
  const sizeKey = Array.isArray(size) ? size.map(v => !v ? 'null' : v).join('.') : size

  useEffect(() => {
    setCardProps({
      variant,
      colorScheme,
      size,
    })
  }, [
    variantKey,
    colorSchemeKey,
    sizeKey,
  ])

  return (
    <Provider {...cardProps}>
      <ChakraModal {...cardProps} {...props} />
    </Provider>
  )
}

/** @deprecated */
export const ModalContentV1: FC<ModalContentProps> = ({ children, ...props }) => {
  const { variant, colorScheme, size } = useContext()

  return (
    <ChakraModalContent w='full' pointerEvents='none' {...props}>
      <Card
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        mx='auto'
        pointerEvents='auto'
      >
        {children}
      </Card>
    </ChakraModalContent>
  )
}
