import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { merge } from 'lodash/fp'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { getRecKey } from 'utils/getRecKey'

export const noticeParts = [
  'container',
  'icon',
  'text',
  'secondaryText',
  'emphasis',
  'actions',
  'navContainer',
  'navButton',
  'navText',
] as const

export const noticeKeys = typeof noticeParts

const { definePartsStyle, defineMultiStyleConfig }
= createMultiStyleConfigHelpers(noticeParts)

const baseStyle = definePartsStyle({
  container: {
    py: mbp({ mobSm: 2, tabSm: 1 }),
    pl: mbp({ mobSm: 2, tabSm: 3 }),
    pr: mbp({ mobSm: 1, tabSm: 2 }),
    display: 'flex',
    flexDirection: mbp({ mobSm: 'column', tabSm: 'row' }),
    alignItems: 'center',
    flexWrap: 'wrap',
    h: 'full',
  },
  icon: {
    mr: '1',
    fontSize: '3',
    display: mbp({ mobSm: 'none', tabSm: 'block' }),
  },
  text: {
    ...textStyles['body-b'],
    flex: '1',
  },
  secondaryText: {
    ...textStyles['body-b'],
  },
  emphasis: {
    ...textStyles['body-h-b'],
  },
  actions: {
    minH: 3,
    display: 'flex',
    flexDir: mbp({ mobSm: 'row-reverse', tabSm: 'row' }),
    alignItems: 'center',
    justifyContent: 'space-between',
    w: mbp({ mobSm: '100%', tabSm: 'auto' }),
    mt: mbp({ mobSm: 2, tabSm: 0 }),
    ml: mbp({ mobSm: 0, tabSm: 2 }),
  },
  navContainer: {
    display: 'flex',
    flexDir: 'row',
    alignItems: 'center',
    my: '-1',
  },
  navText: {
    ...textStyles['button-b'],
  },
  navButton: {
    fontSize: '2',
    lineHeight: '0',
    boxSize: '4',
  },
})

/**
 * ====================
 * Color Schemes
 * ====================
 */

export type NoticeColorScheme = 'error' | 'warning' | 'neutral' | 'default' | 'info' | 'special'

const colorSchemes = {
  error: definePartsStyle({
    container: {
      bg: 'negative.500',
    },
    icon: {
      color: 'highlight.500',
    },
    text: {
      color: 'ondark.1',
    },
    emphasis: {
      color: 'ondark.1',
    },
    navText: {
      color: 'ondark.1',
    },
    navButton: {
      color: 'ondark.1',
    },
  }),
  warning: definePartsStyle({
    container: {
      bg: 'grayweak.400',
    },
    icon: {
      color: 'negative.500',
    },
    text: {
      color: 'graystrong.500',
    },
    emphasis: {
      color: 'graystrong.500',
    },
    navText: {
      color: 'graystrong.500',
    },
    navButton: {
      color: 'graystrong.500',
    },
  }),
  neutral: definePartsStyle({
    emphasis: {
      color: 'graystrong.500',
    },
    navText: {
      color: 'graystrong.500',
    },
    navButton: {
      color: 'graystrong.500',
    },
  }),
  default: definePartsStyle({
    container: {
      bg: 'highlight.500',
    },
    text: {
      color: 'onlight.1',
    },
    secondaryText: {
      color: 'overlay.dark-am',
    },
  }),
  info: definePartsStyle({
    container: {
      bg: 'neutral.500',
    },
    icon: {
      color: 'highlight.500',
    },
    text: {
      color: 'ondark.1',
    },
    emphasis: {
      color: 'ondark.1',
    },
    navText: {
      color: 'ondark.1',
    },
    navButton: {
      color: 'ondark.1',
    },
  }),
  special: definePartsStyle({
    container: {
      bg: 'specialsat.500',
    },
    text: {
      color: 'ondark.1',
    },
    navText: {
      color: 'ondark.1',
    },
    navButton: {
      color: 'ondark.1',
    },
  }),
}

/**
 * ====================
 * Variants
 * ====================
 */
const variants = {
  default: (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(colorSchemes) || {},
      {
        /**
         * Put default variant styles here
         */
      },
    ),
}

export const noticeTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'default',
    colorScheme: 'info',
  },
})
