import { DownloadBatchSkiptraceFormat } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { MarketingList } from 'features/ListBuilder/domain/MarketingList'
import { downloadTextAsFile } from 'features/Skiptrace/infra/remote/downloadResult/downloadResult'
import { restClient } from 'presentation/libs/client'

const request = async (list: MarketingList, format: DownloadBatchSkiptraceFormat) => {
  await restClient.get(`lists/v3/${list.id}/skiptrace/download`, {
    searchParams: { format },
  }).text().then(text => downloadTextAsFile({ text, fileName: `${list.name}-skiptraced.csv` }))
}

export const GETDownloadBatchSkiptrace = {
  request,
}
