import { createIcon } from '@chakra-ui/react'

export const MapIcon = createIcon({
  displayName: 'MapIcon',
  viewBox: '0 0 24 25',
  path: (
    <svg width='24' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 6.398v16l7-4 8 4 7-4v-16l-7 4-8-4-7 4ZM8 2.398v16M16 6.398v16' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
