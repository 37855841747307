declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    uipe?: any
  }
}

const SCRIPT_ID = 'ablecdp-script'

let trackerPromise: Promise<Tracker> | null = null

export const loadTracker = () => {
  trackerPromise = trackerPromise || new Promise<Tracker>(resolve => {
    const el = document.createElement('script')
    el.id = SCRIPT_ID
    el.async = true
    el.src = 'https://app.ablecdp.com/ue.js'
    el.addEventListener('load', () => {
      const tracker = createTracker()
      tracker.init()
      resolve(tracker)
    })
    document.head.appendChild(el)
  })

  return trackerPromise
}

export type TrackerLeadInfo = {
  userId: string
  email: string
  firstName: string
  lastName: string
}

export type Tracker = {
  init: () => void
  trackLead: (info: TrackerLeadInfo) => void
  trackPageView: () => void
}

const createTracker = (): Tracker => ({
  init: () => {
    // @TODO: should come from enviroment
    window.uipe('init', '86de1f46-810b-4345-96cd-1076f7a08fed')
  },
  trackLead: info => {
    window.uipe('track', 'Lead', {
      keys: {
        email: info.email,
        client_id: info.userId,
      },
      lead: {
        firstName: info.firstName,
        lastName: info.lastName,
      },
    })
  },
  trackPageView: () => {
    window.uipe('track', 'PageView')
  },
})
