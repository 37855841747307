import { Box, Grid, GridItem, GridItemProps, GridProps, HStack, Link, Text } from '@chakra-ui/react'
import { Array, Option } from 'effect'
import Hooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { DateLib } from 'libs/Date'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon, ChevronUpIcon } from 'presentation/components/Icons'
import NameWithSkip from './NameWithSkip'
import OwnedPropertiesColumned from './OwnedPropertiesColumned'
import OwnedPropertiesTabbed from './OwnedPropertiesTabbed'
import { FlippedPill, LandlordPill } from './common'

/**
 * A summary of each owner's real estate activity
 */
const PropertyOwnershipActivityTable = () => {
  const viewModel = Hooks.useOwnersList()

  return (
    <HStack
      align='stretch'
      justifyContent='stretch'
      mx={{ tabSm: -3, dtSm: 0 }}
      overflowX='auto'
      w='auto'
    >
      <Box
        flexGrow={0}
        flexShrink={0}
        w={{ tabSm: 3, dtSm: 0 }}
      />

      <DataGrid flex='1'>
        {/** Header */}
        <HeaderRow minW='912px'>
          <HeaderCell>{/** header for chevron */}</HeaderCell>
          <HeaderCell>
            Owner&rsquo;s&nbsp;Name&nbsp;&amp;&nbsp;Type
          </HeaderCell>
          <HeaderCell>
            Mailing&nbsp;Address
          </HeaderCell>
          <HeaderCell textAlign='right'>
            Total&nbsp;Deals
          </HeaderCell>
          <HeaderCell textAlign='right'>
            Average&nbsp;Deal
          </HeaderCell>
          <HeaderCell textAlign='right'>
            Deal&nbsp;Range
          </HeaderCell>
          <HeaderCell textAlign='right'>
            Last Deal
          </HeaderCell>
          <HeaderCell textAlign='right'>
            No.&nbsp;of&nbsp;Deals
          </HeaderCell>
          <HeaderCell textAlign='left'>
            Location
          </HeaderCell>
        </HeaderRow>

        {/** Rows */}
        {viewModel.ownerIds.map(ownerId => (
          <Row ownerId={ownerId} key={ownerId} />
        ))}
      </DataGrid>
      <Box
        flexGrow={0}
        flexShrink={0}
        w={{ tabSm: '3', dtSm: '0' }}
      />
    </HStack>
  )
}

export default PropertyOwnershipActivityTable

const Row = ({ ownerId }: { ownerId: string }) => {
  const viewModelOption = Hooks.useOwner(ownerId)

  const dtLgModelOption = Hooks.useOwnerTabSm(ownerId)

  const models = Option.all([viewModelOption, dtLgModelOption])

  if (Option.isNone(models)) return null

  const [viewModel, dtLgModel] = models.value

  const { owner } = viewModel

  const toggleRowProps = {
    onClick: () => {
      dtLgModel.onToggleExpand()
    },
    role: 'button',
  }

  return (
    <DataRow
      minW='912px'
      key={owner.id}
      isLastRow={dtLgModel.isLastRow}
      bgColor={dtLgModel.isExpanded ? 'highlight.100' : 'grayweak.100'}
      _hover={{
        bgColor: dtLgModel.isExpanded ? 'highlight.100' : 'positive.50',
      }}
    >
      <DataCell alignItems='center' {...toggleRowProps}>
        <Box
          as={dtLgModel.isExpanded ? ChevronUpIcon : ChevronDownIcon}
          role='button'
          boxSize='3'
          color='graystrong.500'
        />
      </DataCell>
      <DataCell maxW='200px' {...toggleRowProps}>
        <Box minW='0'>
          <NameWithSkip>
            {Option.match(owner.name, {
              onSome: name => name,
              onNone: () => '--',
            })}
          </NameWithSkip>
          <HStack spacing={1} mt='1' flexWrap='wrap'>
            {owner.buyerType.pipe(
              Option.match({
                onSome: buyerType => (
                  <>
                    {buyerType.includes('flipper') && <FlippedPill />}
                    {buyerType.includes('landlord') && <LandlordPill />}
                  </>
                ),
                onNone: () => null,
              }),
            )}
          </HStack>
        </Box>
      </DataCell>
      <DataCell
        minW={{ tabSm: 'auto', dtSm: '0' }}
        maxW={{ tabSm: '300px', dtSm: 'auto' }}
        {...toggleRowProps}
      >
        {owner.address.pipe(
          Option.match({
            onSome: address => (
              <Link
                minW='0'
                onClick={ev => {
                  viewModel.onAddressClick()
                  ev.stopPropagation()
                }}
              >
                <Text
                  color='inherit'
                  isTruncated
                  title='17 Kingfisher Rd., Tewbury, a long and winding road'
                >
                  {address.line1}
                  ,
                  {' '}
                  {address.city}
                </Text>
                <Text
                  color='inherit'
                  isTruncated
                  title={`${address.state} ${address.postalCode}`}
                >
                  {address.state}
                  {' '}
                  {address.postalCode}
                </Text>
              </Link>
            ),
            onNone: () => (
              <>--</>
            ),
          }),
        )}
      </DataCell>
      <DataCell
        justifyContent='right'
        textAlign='right'
        {...toggleRowProps}
      >
        {owner.totalDealsCount.pipe(
          Option.flatMap(Option.liftPredicate(count => count > 0)),
          Option.map(totalDealsCount => (
            <>
              {owner.totalDealAmount.pipe(
                Option.map(Dollar.formatNumberWithCommas),
                Option.getOrElse(() => '--'),
              )}
              <br />
              in&nbsp;
              {totalDealsCount}
              &nbsp;
              {pluralize('deal', totalDealsCount, false)}
            </>
          )),
          Option.getOrElse(() => '--'),
        )}
      </DataCell>
      <DataCell
        justifyContent='right'
        textAlign='right'
        {...toggleRowProps}
      >
        {owner.averageDealAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
      </DataCell>
      <DataCell
        justifyContent='right'
        textAlign='right'
        {...toggleRowProps}
      >
        {(() => {
          const minDealFormatted = owner.dealAmountRange.pipe(
            Option.map(range => range[0]),
            Option.map(Dollar.formatNumberWithCommas),
            Option.getOrElse(() => '--'),
          )

          const maxDealFormatted = owner.dealAmountRange.pipe(
            Option.map(range => range[1]),
            Option.map(Dollar.formatNumberWithCommas),
            Option.getOrElse(() => '--'),
          )

          return (
            <>
              {minDealFormatted}
              <Breakpoint
                dtSm={<>&nbsp;</>}
                mobSm={<>{' '}</>}
              />
              &#8209;&nbsp;
              {maxDealFormatted}
            </>
          )
        })()}
      </DataCell>
      <DataCell
        justifyContent='right'
        textAlign='right'
        {...toggleRowProps}
      >
        {owner.lastDeal.pipe(
          Option.map(deal => {
            if (
              Option.isNone(deal.purchaseDate)
              && Option.isNone(deal.purchaseAmount)
            ) return '--'

            const purchaseDateFormatted = deal.purchaseDate.pipe(
              Option.map(DateLib.formatMMDDYYDots),
              Option.getOrElse(() => '--'),
            )

            return purchaseDateFormatted
          }),
          Option.getOrElse(() => '--'),
        )}
      </DataCell>
      <DataCell
        justifyContent='right'
        {...toggleRowProps}
      >
        {owner.propertiesCount.pipe(
          Option.match({
            onSome: count => (
              <Breakpoint
                dtSm={(
                  <>
                    {count}
                    &nbsp;
                    {pluralize('Properties', count, false)}
                  </>
                )}
                tabSm={(
                  <>
                    {count}
                    &nbsp;
                    {pluralize('Prop', count, false)}
                  </>
                )}
              />
            ),
            onNone: () => '--',
          }),
        )}
      </DataCell>
      <DataCell {...toggleRowProps}>
        {pluralize('State', Array.length(owner.dealsGeographicInfo.states), true)}
        {' & '}
        <br />
        {pluralize('County', Array.length(owner.dealsGeographicInfo.counties), true)}
      </DataCell>

      {/** Expanded Content */}
      {dtLgModel.isExpanded && (
        <DataRowExpandedContent>
          <Breakpoint
            tabSm={(
              <OwnedPropertiesTabbed ownerId={ownerId} />
            )}
            dtLg={(
              <OwnedPropertiesColumned ownerId={ownerId} />
            )}
          />
        </DataRowExpandedContent>
      )}
    </DataRow>
  )
}

const DataRowExpandedContent = ({ children, ...props }: GridItemProps) => (
  <GridItem
    colSpan={9}
    my='1'
    py='2'
    px='2'
    {...props}
  >
    {children}
  </GridItem>
)

const DataGrid = ({ children, ...props }: GridProps) => (
  <Grid
    gridTemplateColumns='min-content repeat(8, 1fr)'
    gridAutoRows='auto'
    {...props}
  >
    {children}
  </Grid>
)

const HeaderRow = ({ children, ...props }: GridProps) => (
  <Grid
    role='row'
    gridColumn='span 9'
    gridTemplateColumns='subgrid'
    borderTopRadius='2'
    p='1'
    bgColor='card.bg.blue'
    textStyle='bodyMFat'
    color='ondark.1'
    {...props}
  >
    {children}
  </Grid>
)

const HeaderCell = ({ children, ...props }: GridItemProps) => (
  <GridItem
    role='columnheader'
    alignContent='center'
    px='1'
    {...props}
  >
    {children}
  </GridItem>
)

type DataRowProps = GridProps & {
  isLastRow?: boolean
}

const DataRow = ({
  children,
  isLastRow,
  ...props
}: DataRowProps) => (
  <Grid
    role='row'
    p='1'
    borderBottomRadius={isLastRow ? 2 : 0}
    gridColumn='span 9'
    gridTemplateColumns='subgrid'
    textStyle='bodyM'
    color='graystrong.600'
    {...props}
  >
    {children}
  </Grid>
)

const DataCell = ({ children, ...props }: GridItemProps) => (
  <GridItem
    role='gridcell'
    display='flex'
    alignItems='center'
    justifyContent='left'
    px='1'
    {...props}
  >
    {children}
  </GridItem>
)
