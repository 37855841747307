import { usePrevious } from '@chakra-ui/react'
import { toast } from 'presentation/components/Toast'
import { useEffect } from 'react'

export const useToastFailedState = <T extends { _tag: string, error?: unknown }>(
  state: T,
  options: {
    title: string
    message?: string
    /**
     * If true, it will not toast when state was failed initially. It must
     * transition from non-failed state instead. Default: true
     */
    shouldToastOnTransitionOnly?: boolean
  } = {
    title: 'Something went wrong.',
    shouldToastOnTransitionOnly: true,
  },
) => {
  const prev = usePrevious(state)

  useEffect(() => {
    if (state._tag !== 'Failed') return
    if (options.shouldToastOnTransitionOnly && prev?._tag === 'Failed') return

    toast.error({
      title: options.title,
      message: options.message
        ? options.message
        : !state.error
          ? 'Unexpected error'
          : state.error instanceof Error
            ? state.error.message
            : String(state.error),
    })
  }, [state])
}

export const useToastFailedStateTagged = <T extends { _tag: string, error?: unknown }>(
  state: T,
  options: {
    title: string
    message?: string
    /**
     * If true, it will not toast when state was failed initially. It must
     * transition from non-failed state instead. Default: false
     */
    shouldToastOnTransitionOnly?: boolean
  } = {
    title: 'Something went wrong.',
    shouldToastOnTransitionOnly: false,
  },
) => {
  const prev = usePrevious(state)

  useEffect(() => {
    if (state._tag !== 'Failed') return
    if (options.shouldToastOnTransitionOnly && prev?._tag === 'Failed') return

    toast.error({
      title: options.title,
      message: options.message
        ? options.message
        : !state.error
          ? 'Unexpected error'
          : state.error instanceof Error
            ? state.error.message
            : String(state.error),
    })
  }, [state])
}
