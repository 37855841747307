import { VStack } from '@chakra-ui/react'
import { Match } from 'effect'
import { useLists } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { ListCard } from 'presentation/screens/ListsScreen/component/ListCard/ListCard'

export const ListsTabsContent = () => {
  const listsState = useLists()

  return (
    Match.value(listsState).pipe(
      Match.tag('Success', state => (
        <VStack spacing={2} align='stretch' justify='flex-start'>
          {state.lists.map(list => (
            <ListCard
              key={list.id}
              list={list}
            />
          ))}
        </VStack>
      )),
      Match.orElse(() => null),
    )
  )
}
