import { gql } from '__generated__'
import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { PersonName } from 'features/valueObjects/PersonName'
import { ReportableException } from 'libs/errors'
import { apolloClient } from 'presentation/libs/client'

export const GetAccessGqlQuery = gql(/* GraphQL */ `
  query GetAccess {
    me {
      id
      email
      isBeta
      isEmailConfirmed
      avatar {
        avatarUrl
      }
      enterprise {
        subscription {
          ... on BillingSubscriptionActive {
            id
          }
          ... on BillingSubscriptionInactive {
            id
          }
        }
      }
      firstname
      lastname
    }
  }
`)

export const getAccess: AccessDeps['getAccess'] = async (payload?: {
  shouldIgnoreCache?: boolean
}) =>
  await apolloClient.query({
    query: GetAccessGqlQuery,
    fetchPolicy: payload?.shouldIgnoreCache ? 'network-only' : 'cache-first',
  })
    .then(({ data }) => {
      const me = data.me

      if (!me) {
        throw new ReportableException('undefined `me` getAccess data', {
          extraInfo: {
            data,
          },
        })
      }

      return {
        status: 'logged-in',
        accessor: {
          id: me.id,
          isEmailConfirmed: me.isEmailConfirmed,
          avatarUrl: me.avatar?.avatarUrl ?? '',
          email: me.email || '',
          name: {
            first: me.firstname || '',
            last: me.lastname || '',
            full: PersonName.combine(me.firstname, me.lastname) || '',
          },
        },
        subscription: me.enterprise?.subscription?.id ? {} : null,
      }
    })
