import { Data } from 'effect'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { GeographyCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { ListCriteriaBase } from 'features/ListBuilder/domain/ListCriteria/ListCriteriaBase'
import { PartialRange } from 'features/valueObjects/Range'
import { DeepPartial } from 'libs/utils.types'
import { mergeDeep } from 'remeda'

// #region Type namespace
export type PropertyListCriteria = ListCriteriaBase<{
  readonly _tag: 'PropertyListCriteria'
  propertyDetails: PropertyDetails
  yearsOfOwnership: PartialRange
  ownershipFlags: OwnershipFlags
  stressIndicatorFlags: StressIndicatorFlags
  equityValue: EquityValue
  mlsFlags: MLSFlags
}>

// #region Property Info
export type PropertyDetails = {
  homeType: HomeType[]
  bathroomsCount: PartialRange
  bedroomsCount: PartialRange
  livingAreaSqft: PartialRange
  lotAreaSize: LotAreaSizeRange
  yearBuilt: PartialRange
  garageSpacesCount: PartialRange
}

export type LotAreaSizeRange = {
  range: PartialRange
  unit: 'sqft' | 'acres'
}
// #endregion

// #region Ownership
export type OwnershipFlags = {
  isPersonalResidence: boolean | null
  isInState: boolean | null
  isOutOfState: boolean | null
  isOutOfCountry: boolean | null
  isMilitary: boolean | null
  isSenior: boolean | null
  isCorporateOwned: boolean | null
  isTrust: boolean | null
  isBank: boolean | null
  isUnknown: boolean | null
}
// #endregion

// #region Stress Indicators
export type StressIndicatorFlags = {
  isPreforeclosure: boolean | null
  isSubstituteTrustees: boolean | null
  isProbates: boolean | null
  isAffidavitsOfHeirships: boolean | null
  isHOALiens: boolean | null
  isTaxDelinquent: boolean | null
  isVacant: boolean | null
  isFailedListing: boolean | null
}
// #endregion

// #region Equity/Value
export type EquityValue = {
  propertyValue: PartialRange
  taxValue: PartialRange
  highEquityValue: HighEquityValue
  lowEquityValue: LowEquityValue
  upsideDownValue: UpsideDownValue
  equityValueFlags: EquityValueFlags
}

export type UpsideDownValue = {
  minAmount: number | null
}

export type HighEquityValue = {
  condition: 'and' | 'or'
  minPercentage: number | null
  minAmount: number | null
}

export type LowEquityValue = {
  condition: 'and' | 'or'
  maxPercentage: number | null
  maxAmount: number | null
}

export type EquityValueFlags = {
  isFreeAndClear: boolean | null
  isUnknown: boolean | null
}
// #endregion

// #region MLS
export type MLSFlags = {
  isExcludeActiveListings: boolean | null
  isDiscountedListings: boolean | null
  isHandymanSpecials: boolean | null
}
// #endregion
// #endregion Types

const constructor = Data.tagged<PropertyListCriteria>('PropertyListCriteria')

// #region Value namespace
export const PropertyListCriteria = {
  of: constructor,
  make: (params?: DeepPartial<PropertyListCriteria>): PropertyListCriteria => {
    const DEFAULTS: PropertyListCriteria = constructor({
      ...GeographyCriteria.make(),
      excludePropertiesFromOtherList: false,
      propertyDetails: {
        homeType: [],
        bathroomsCount: PartialRange.EMPTY,
        bedroomsCount: PartialRange.EMPTY,
        livingAreaSqft: PartialRange.EMPTY,
        lotAreaSize: {
          range: PartialRange.EMPTY,
          unit: 'sqft',
        },
        yearBuilt: PartialRange.EMPTY,
        garageSpacesCount: PartialRange.EMPTY,
      },
      yearsOfOwnership: PartialRange.EMPTY,
      ownershipFlags: {
        isPersonalResidence: null,
        isInState: null,
        isOutOfState: null,
        isOutOfCountry: null,
        isMilitary: null,
        isSenior: null,
        isCorporateOwned: null,
        isTrust: null,
        isBank: null,
        isUnknown: null,
      },
      stressIndicatorFlags: {
        isPreforeclosure: null,
        isSubstituteTrustees: null,
        isProbates: null,
        isAffidavitsOfHeirships: null,
        isHOALiens: null,
        isTaxDelinquent: null,
        isVacant: null,
        isFailedListing: null,
      },
      equityValue: {
        propertyValue: PartialRange.EMPTY,
        taxValue: PartialRange.EMPTY,
        highEquityValue: {
          condition: 'and',
          minPercentage: null,
          minAmount: null,
        },
        lowEquityValue: {
          condition: 'and',
          maxPercentage: null,
          maxAmount: null,
        },
        upsideDownValue: {
          minAmount: null,
        },
        equityValueFlags: {
          isFreeAndClear: null,
          isUnknown: null,
        },
      },
      mlsFlags: {
        isExcludeActiveListings: null,
        isDiscountedListings: null,
        isHandymanSpecials: null,
      },
    })

    return mergeDeep(DEFAULTS, params as Record<string, unknown>)
  },
  with: (
    destination: PropertyListCriteria,
    source: DeepPartial<PropertyListCriteria>,
  ): PropertyListCriteria => mergeDeep(destination, source as Record<string, unknown>),
}
// #endregion Value namespace
