import { Card, CardBody, CardButton, CardFooter, CardHeader, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { MemberReactivateModalViewModel } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberReactivateModal.viewModel'
import { MemberTable } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberTable'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export type MemberReactivateModalProps = { isOpen: boolean, onClose: () => void } & MemberReactivateModalViewModel

export const MemberReactivateModal: FC<MemberReactivateModalProps> = ({
  status,
  member,
  isOwner,
  onEnable,
  ...modalProps
}) => (
  <ResponsiveFullscreenModal
    title='Team Members'
    {...modalProps}
  >
    <Card
      size={mbp({ mobSm: 'sm-locked', tabSm: 'lg-locked' })}
      variant='default'
      colorScheme='modal.alert.neutral'
    >
      <CardHeader>
        <CardPrimaryTitle>
          Reactivate Team Member
        </CardPrimaryTitle>
        <CardPrimaryText>
          By reactivating this team member, all of their access will be restored,
          allowing them to log in and access your team&rsquo;s resources.
        </CardPrimaryText>
      </CardHeader>
      <CardBody shouldResetSpacing mt={mbp({ mobSm: 2, mob: 3 })}>
        <MemberTable
          w='full'
          member={member}
          isOwner={isOwner}
        />
      </CardBody>
      <CardFooter horizontallyStackAt='mob'>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          onClick={modalProps.onClose}
          isDisabled={status === 'loading'}
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='positive'
          onClick={onEnable}
          isLoading={status === 'loading'}
          loadingText='Reactivating'
        >
          Reactivate
        </CardButton>
      </CardFooter>
    </Card>
  </ResponsiveFullscreenModal>
)
