import { Button, ButtonProps } from '@chakra-ui/react'
import { ChoosePlan_CurrentPlanFragment, ChoosePlan_TargetProductFragment } from '__generated__/graphql'
import { CartIcon } from 'presentation/components/Icons/CartIcon'
import { OpenChoosePlanModalParams, openChoosePlanModal } from 'presentation/screens/Billing/components/ChoosePlanFlow/openChoosePlanModal'
import { usePricingStore } from 'presentation/screens/Plans/hooks/usePricingStore'
import { getButtonColorSchemeByInterval } from 'presentation/screens/Plans/utils/getButtonColorSchemeByInterval'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

type AddToCartBtnProps = ButtonProps & {
  targetProduct: ChoosePlan_TargetProductFragment
  currentPlan?: ChoosePlan_CurrentPlanFragment
  selectedPlanId: string
}

export const AddToCartBtn: FC<AddToCartBtnProps> = ({
  targetProduct,
  currentPlan,
  selectedPlanId,
  ...props
}) => {
  const handlePlanChange = () => {
    handleOpenChoosePlanModal({
      targetProduct,
      currentPlan,
      selectedPlanId,
    })
  }

  const { interval } = usePricingStore()

  return (
    <Button
      colorScheme={getButtonColorSchemeByInterval(interval)}
      variant='solid'
      size={mbp({ mobSm: 'sm', dtSm: 'md' })}
      rightIcon={<CartIcon boxSize={mbp({ mobSm: '16px', dtSm: '24px' })} />}
      border='0'
      onClick={handlePlanChange}
      {...props}
    >
      ADD TO CART
    </Button>
  )
}

export const handleOpenChoosePlanModal = ({
  targetProduct,
  currentPlan,
  selectedPlanId,
  shouldReplace,
}: OpenChoosePlanModalParams) => {
  openChoosePlanModal({
    targetProduct,
    currentPlan,
    selectedPlanId,
    shouldReplace,
  })
}
