import cn from 'classnames'
import { CARD_PARTS } from 'presentation/components/molecules/Card/Card.theme'

type CardParts = typeof CARD_PARTS
type CardPart = CardParts[keyof CardParts]

export const createChakraClass = (part: CardPart) => `chakra-card__${part}`

export const mergeChakraClass = (part: CardPart, ...classNames: (string | undefined | boolean)[]) =>
  cn(createChakraClass(part), ...classNames)
