import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  'pairContainer',
  'pairKey',
  'pairValue',
])

const baseStyle = definePartsStyle({
  pairContainer: {
    flexDirection: 'column',
    gap: 0.5,
  },
})

const sizes = {
  sm: definePartsStyle({
    pairKey: {
      ...textStyles.bodySFat,
    },
    pairValue: {
      ...textStyles.bodyM,
    },
  }),
  md: definePartsStyle({
    pairKey: {
      ...textStyles.bodyMFat,
    },
    pairValue: {
      ...textStyles.bodyL,
    },
  }),
  lg: definePartsStyle({
    pairKey: {
      ...textStyles.bodyXLFat,
    },
    pairValue: {
      ...textStyles.bodyLFat,
    },
  }),
}

export const colorSchemes = {
  default: () => definePartsStyle({
    pairKey: {
      color: 'mixkey.blue',
    },
    pairValue: {
      color: 'graystrong.400',
    },
  }),
  white: () => definePartsStyle({
    pairKey: {
      color: 'marker.dirtywhite',
    },
    pairValue: {
      color: 'marker.dirtywhite',
    },
  }),
}

export const variants = {
  default: (props: StyleFunctionProps) =>
    getRecKey(props.colorScheme)(colorSchemes)?.() || {},
}

export type PairSize = keyof typeof sizes

export type PairVariant = keyof typeof variants

export type PairColorScheme = keyof typeof colorSchemes

export const pairTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'default',
    colorScheme: 'default',
    size: 'sm',
  },
})
