import { Box, Flex } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

// Import Swiper styles
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

export const PlanComparisonCarousel: FC<PropsWithChildren> = ({ children }) => {
  const slideContents = Array.isArray(children)
    ? children.map((element, i) => (
      <SwiperSlide key={element.key}>
        <SlideContainer index={i}>{element}</SlideContainer>
      </SwiperSlide>
    ))
    : (
      <SwiperSlide>
        <SlideContainer index={0}>{children}</SlideContainer>
      </SwiperSlide>
    )

  return (
    <Flex
      w='full'
      alignItems='center'
      justifyContent='center'
      className='package-carousel'
      sx={{
        '.swiper': {
          overflow: 'visible',
        },
        '.swiper, .swiper-container': {
          pb: '60px',
        },
        '.swiper-slide': mbpg({
          mobSm: {
            w: '250px',
          },
          tabSm: {
            w: '292px',
          },
        }),
      }}
    >
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        initialSlide={1}
        slidesPerView='auto'
        centeredSlides={true}
        roundLengths={true}
        loop={false}
        navigation={false}
        pagination={false}
        spaceBetween='16px'
      >
        {slideContents}
      </Swiper>
    </Flex>
  )
}

const SlideContainer: FC<PropsWithChildren<{ index: number }>> = ({ children, index }) => {
  const swiper = useSwiper()

  return (
    <Box onClick={() => swiper.slideTo(index)}>
      {children}
    </Box>
  )
}
