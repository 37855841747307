import { createIcon } from '@chakra-ui/react'

export const LockRedBlackIcon = createIcon({
  displayName: 'LockRedBlackIcon',
  viewBox: '0 0 40 46',
  path: (
    <>
      <path d='M31.667 18.333H8.333A3.333 3.333 0 0 0 5 21.666v11.667a3.333 3.333 0 0 0 3.333 3.333h23.334A3.333 3.333 0 0 0 35 33.333V21.666a3.333 3.333 0 0 0-3.333-3.333ZM11.667 18.333v-6.667a8.333 8.333 0 0 1 16.667 0v6.667' stroke='#F04745' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path fillRule='evenodd' clipRule='evenodd' d='M21.444 29.477A3.335 3.335 0 0 0 20.334 23a3.333 3.333 0 0 0-1.112 6.477v2.412a1.111 1.111 0 0 0 2.222 0v-2.412Z' fill='#000' />
    </>
  ),
})
