import { Address } from 'features/valueObjects/Address'

export const AddressTwoLines = ({ address }: { address: Address }) => (
  <>
    {address.line1}
    <br />
    {address.city}
    ,
    {address.state}
    {' '}
    {address.postalCode}
  </>
)
