import { Button, Flex, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useMLSStatusTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingModal/MLSStatusTrainingModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { MouseEventHandler } from 'react'

export type MLSStatusTrainingNoticeProps = {
  onClose: () => void
}

export const MLSStatusTrainingNotice = (props: MLSStatusTrainingNoticeProps) => {
  const api = useMLSStatusTrainingModal()
  const handleOpenModal: MouseEventHandler = () => {
    api.actions.open()
    props.onClose()
  }
  return (
    <ResponsiveNotice
      colorScheme='special'
      textContent={(
        <>
          <Flex gap={1} alignItems='center'>
            <Breakpoint
              tabSm={(
                <SubjectPropertyIcon boxSize={3} />
              )}
            />
            <Text color='inherit'>
              The subject property will always be marked with the lavender/purple color and star icon/pin.
            </Text>
          </Flex>
        </>
      )}
      actionsContent={(
        <Button
          variant='solid'
          colorScheme='highlight'
          size={mbp({ mobSm: 'sm', mob: 'xs' })}
          onClick={handleOpenModal}
        >
          Learn More
        </Button>
      )}
    />
  )
}
