import { createIcon } from '@chakra-ui/react'

export const PowerIcon = createIcon({
  displayName: 'PowerIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='M18.3591 6.64001C19.6175 7.8988 20.4744 9.50246 20.8214 11.2482C21.1685 12.994 20.9901 14.8034 20.3088 16.4478C19.6275 18.0921 18.474 19.4976 16.994 20.4864C15.514 21.4752 13.7741 22.0029 11.9941 22.0029C10.2142 22.0029 8.4743 21.4752 6.99432 20.4864C5.51434 19.4976 4.36079 18.0921 3.67951 16.4478C2.99823 14.8034 2.81983 12.994 3.16686 11.2482C3.51389 9.50246 4.37077 7.8988 5.62914 6.64001' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 2V12' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
