import { BoxProps, Text, VStack } from '@chakra-ui/react'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { DateLib } from 'libs/Date'
import { getMLSStatusTagColor } from 'presentation/components/MLSStatusTag'

export const StatusSection = ({
  saleDate,
  status,
  ...props
}: BoxProps & {
  saleDate: Date | null
  status: MLSStatus
}) => (
  <VStack
    align='flex-end'
    spacing={0}
    {...props}
  >
    <Text
      textStyle='bodySHeavy'
      color={getMLSStatusTagColor(status).borderColor}
    >
      {MLSStatus.toString(status)}
      {status === 'SOLD' && saleDate && (
        <>
          {' '}
          -
        </>
      )}
    </Text>
    <Text
      textStyle='tagL'
      color={getMLSStatusTagColor(status).borderColor}
    >
      {status === 'SOLD' && saleDate && DateLib.formatMMDDYYDots(saleDate)}
    </Text>
  </VStack>
)
