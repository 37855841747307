import { ID } from 'libs/id'
import { StringLib } from 'libs/String'
import { useCallback, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const COMPARATIVE_MODAL_KEY = 'comparative-property'

/** @TODO Refactor when pattern emerge on 3rd or 4th instance of similar logic */
export const useComparativePropertyModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationRef = useRef(location)
  locationRef.current = location

  const open = useCallback((targetCompId: ID) => {
    const location = locationRef.current
    const isAlreadyOpen = location.state?.modal === COMPARATIVE_MODAL_KEY
      && location.state?.targetCompId === targetCompId

    if (isAlreadyOpen) return

    navigate(location.pathname, {
      state: {
        ...location.state,
        modal: COMPARATIVE_MODAL_KEY,
        targetCompId,
      },
    })
  }, [])

  const close = useCallback(() => {
    const isAlreadyClosed = locationRef.current.state?.modal !== COMPARATIVE_MODAL_KEY
    if (isAlreadyClosed) return

    navigate(-1)
  }, [])

  const targetCompId = StringLib.ensureOrNull(location.state?.targetCompId)
  const isOpen = location.state?.modal === COMPARATIVE_MODAL_KEY
    && targetCompId

  return useMemo(() => ({
    actions: {
      open,
      close,
    },
    state: isOpen && targetCompId
      ? {
        status: 'open' as const,
        targetCompId,
      }
      : {
        status: 'closed' as const,
      },
  }), [
    isOpen,
    open,
    close,
    targetCompId,
  ])
}
