import { Box, BoxProps, Flex, Text, useColorMode } from '@chakra-ui/react'
import { SubjectPropertyIcon } from 'presentation/components/Icons'

export type SubjectPropertyTagProps = {
  isOnDark?: boolean
} & BoxProps

export const SubjectPropertyTag = ({ isOnDark, ...props }: SubjectPropertyTagProps) => {
  /**
   * @TODO: revert this once <DarkMode /> & <LightMode /> is working
   */
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const borderColor = isDarkMode
    ? 'marker.darkpurple'
    : isOnDark
      ? 'marker.lightpurple'
      : 'marker.darkpurple'

  return (
    <Flex
      bgColor='special.500'
      gap={0.5}
      borderColor={borderColor}
      borderRadius='100px'
      borderWidth={0.125}
      py='3px'
      px={1.5}
      boxShadow='0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      w='fit-content'
      alignItems='center'
      {...props}
    >
      <SubjectPropertyIcon boxSize={2} />

      <Text
        as={Box}
        color='ondark.1'
        textStyle='tagL'
        flexShrink={0}
        flexGrow={1}
        textAlign='center'
      >
        Subject Property
      </Text>
    </Flex>
  )
}
