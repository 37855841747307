import { Flex, useMultiStyleConfig, useTheme } from '@chakra-ui/react'
import { ConfigurableChakraFlex } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraFlex'
import { ConfigurableChakraText } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraText'
import { CheckCircleIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { ToastFn, ToastParams } from 'presentation/components/Toast/Toast.types'
// eslint-disable-next-line no-restricted-imports
import { ToastContainer as ToastContainerBase, cssTransition, toast as toastBase } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './ToastAnimation.css'
import { SolidCircleInfoIcon } from 'presentation/components/Icons/solid'

export const toast: ToastFn = ({ id, status, title, message, ...params }) => {
  const toastForStatus = toastBase[status]
    || toastBase

  return toastForStatus((
    <Toast
      id={id}
      status={status}
      title={title}
      message={message}
    />
  ), {
    toastId: id,
    ...params,
  })
}

toast.success = params => toast({ status: 'success', ...params })
toast.error = params => toast({ status: 'error', ...params })
toast.info = params => toast({ status: 'info', ...params })
toast.dismiss = toastBase.dismiss

const Toast = ({ status, title, message, ...props }: ToastParams) => {
  const styles = useMultiStyleConfig('Toast', { ...props, variant: status })
  const Icon = status === 'info'
    ? SolidCircleInfoIcon
    : status === 'success'
      ? CheckCircleIcon
      : status === 'error'
        ? TriangleExclamationIcon
        : null

  return (
    <ConfigurableChakraFlex __css={styles.container}>
      {Icon ? <Icon __css={styles.icon} /> : null}

      <Flex flexDir='column'>
        <ConfigurableChakraText __css={styles.title}>
          {title}
        </ConfigurableChakraText>

        {message && (
          <ConfigurableChakraText __css={styles.message}>
            {message}
          </ConfigurableChakraText>
        )}
      </Flex>
    </ConfigurableChakraFlex>
  )
}

const TOAST_DURATION = 5000
export const ToastContainer = () => {
  const theme = useTheme()

  return (
    <ToastContainerBase
      autoClose={TOAST_DURATION}
      closeButton={true}
      closeOnClick
      draggable='touch'
      icon={false}
      pauseOnFocusLoss
      pauseOnHover
      position='bottom-center'
      theme={theme.id}
      transition={transition}
    />
  )
}

const transition = cssTransition({
  enter: 'Toastify--animate toast-enter',
  exit: 'Toastify--animate toast-exit',
  collapseDuration: 300,
})
