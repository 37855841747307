import { ArrayFormatter } from '@effect/schema'
import * as S from '@effect/schema/Schema'
import { Effect, Either, pipe } from 'effect'
import { IS_DEV_ENV } from 'presentation/const/env.const'

export namespace GETListOrdersByListIdSchema {
  // #region Schemas
  export const SkiptraceItemSchema = S.Struct({
    service: S.Literal('skiptrace'),
    status: S.Union(S.Literal('pending'), S.Literal('completed')),
    requestedServiceQty: S.Number,
    id: S.String,
  })

  type SkiptraceItemSchema = S.Schema.Type<typeof SkiptraceItemSchema>

  export const ResultSchema = S.Struct({
    id: S.String,
  })

  export const ItemSchema = S.Union(SkiptraceItemSchema, S.Struct({}))

  export const Self = S.Struct({
    items: S.optional(S.Array(ItemSchema)),
  })

  export type Self = S.Schema.Type<typeof Self>

  // #endregion

  // #region decode
  export const decode = (data: any) => pipe(
    data,
    S.decodeUnknownEither(Self),
    Either.getOrThrowWith(error => {
      if (IS_DEV_ENV) {
        pipe(
          error,
          ArrayFormatter.formatError,
          // eslint-disable-next-line no-console
          Effect.tap(console.log),
          Effect.runSync,
        )
      }

      throw error as Error
    }),
  )
  // #endregion

  // #region toDomain
  export const toDomain = (resp: Self) => ({
    orders: (resp.items ?? [])
      .filter((item): item is SkiptraceItemSchema =>
        'service' in item && item.service === 'skiptrace')
      .map(item => ({
        id: item.id,
        toSkiptraceCount: item.requestedServiceQty,
        status: item.status,
      })),

  })
  // #endregion
}

export type GETListOrdersByListIdSchema = GETListOrdersByListIdSchema.Self
