import { ApolloQueryResult } from '@apollo/client'
import { LoadCmaQuery } from '__generated__/graphql'
import { LOAD_CMA_QUERY } from 'features/CMA/infra/remote/loadCma/loadCma.graphql'
import { ServerCache } from 'libs/ServerCache'
import { apolloClient } from 'presentation/libs/client'

/** @TODO Do not use getProperty, properly extend the LOAD_CMA_QUERY to get all you need */
export const getPropertyCmaInfo = async (parcelId: string) => {
  const fromCache = ServerCache.get('loadCMA:getPropertyCmaInfo')(parcelId)

  if (fromCache) return fromCache as ApolloQueryResult<LoadCmaQuery>

  const response = await apolloClient.query({
    query: LOAD_CMA_QUERY,
    variables: { parcelId },
  })

  ServerCache.set('loadCMA:getPropertyCmaInfo')(parcelId)(response)

  return response
}
