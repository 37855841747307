import { Box, BoxProps, Flex, Stack, SystemStyleObject, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { NumberLib } from 'libs/Number'
import { EnterIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { Tooltip } from 'presentation/components/Tooltip'
import { animationClasses } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useNavigate } from 'react-router-dom'
import { formatCommasOr } from 'utils/dataAdapter'
import { SearchEntryMarkerProps } from './SearchEntryMarker.props'

export const SearchEntryMarker = (props: SearchEntryMarkerProps) => {
  const { entry } = props

  const navigate = useNavigate()
  /**
   * PropertyMarkerInfo
   */
  const classification = entry.property.ownership?.classification ?? null
  const equityType = entry.property.valuation?.equityType ?? null
  const isVacant = entry.property.ownership?.isVacant ?? false
  const isForeclosure = entry.property.ownership?.isForeclosure ?? false
  const isSenior = entry.property.ownership?.isSenior ?? false

  /**
  * Property Address
  */
  const line1 = entry.property.address.line1

  /**
   * Property Details
   */
  const bedroomsCount = entry.property.building?.bedroomsCount ?? '--'
  const bathroomsCountTotal = entry.property.building?.bathroomsCount?.total ?? '--'
  const buildingSqft = entry.property.building?.buildingSqft ?? null
  const lotAreaAcres = entry.property.land?.lotAreaAcres ?? null
  const yearBuilt = entry.property.building?.yearBuilt ?? '--'
  const equity = entry.property.valuation?.equity ?? null
  const value = entry.property.valuation?.value ?? null
  const lastSalePrice = entry.property.valuation?.lastSalePrice ?? null
  const garageCount = entry.property.building?.garageCount ?? '--'

  const onOpenPropertyDetails = () => {
    navigate(`/search/${entry.id}`)
  }

  return (
    <Container onClick={onOpenPropertyDetails} cursor='pointer'>
      <Stack
        direction='row'
        gap={1}
        p={1}
        borderTopRadius={2}
        backgroundColor='gw.100.am'
        alignItems='center'
      >
        <PropertyMarker
          classification={classification}
          equityType={equityType}
          isSenior={isSenior}
          isVacant={isVacant}
          isForeclosure={isForeclosure}
          size='xs'
          markerType='pin'
        />

        <Tooltip label={line1}>
          <Text
            color='graystrong.600'
            flex='1'
            textStyle='bodyLFat'
            isTruncated
          >
            {line1}
          </Text>
        </Tooltip>

        <Box p={0.5}>
          <EnterIcon boxSize={3} color='graystrong.600' />
        </Box>
      </Stack>

      <Stack
        direction='column'
        gap={2}
        p={2}
        borderBottomRadius={2}
        backgroundColor='graycool.100'
      >
        <Flex
          flexFlow='row wrap'
          justifyContent='space-between'
          rowGap={2}
        >
          <Pair size='sm' alignItems='center' flexBasis={mbp({ mobSm: '33.33%', tabSm: '19.5%' })}>
            <PairKey>Bed</PairKey>
            <PairValue>{bedroomsCount}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flexBasis={mbp({ mobSm: '33.33%', tabSm: '19.5%' })}>
            <PairKey>Bath</PairKey>
            <PairValue>{bathroomsCountTotal}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flexBasis={mbp({ mobSm: '33.33%', tabSm: '19.5%' })}>
            <PairKey>SqFt</PairKey>
            <PairValue>{formatCommasOr('--')(buildingSqft)}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flexBasis={mbp({ mobSm: '33.33%', tabSm: '22%' })}>
            {/** @TODO: */}
            <PairKey>Lot Acres</PairKey>
            <PairValue>{NumberLib.formatCommasDecimalsOrDoubleDash(lotAreaAcres)}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flexBasis={mbp({ mobSm: '33.33%', tabSm: '19.5%' })}>
            <PairKey>Build</PairKey>
            <PairValue>{yearBuilt}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flex={mbp({ mobSm: '33.33%', tabSm: '1' })}>
            <PairKey>Est. Equity</PairKey>
            <PairValue>{NumberLib.formatKODoDD(equity)}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flex={mbp({ mobSm: '33.33%', tabSm: '1' })}>
            <PairKey>Est. Value</PairKey>
            <PairValue>{NumberLib.formatKODoDD(value)}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flex={mbp({ mobSm: '33.33%', tabSm: '1' })}>
            <PairKey>Last Sold</PairKey>
            <PairValue>{NumberLib.formatKODoDD(lastSalePrice)}</PairValue>
          </Pair>
          <Pair size='sm' alignItems='center' flex={mbp({ mobSm: '33.33%', tabSm: '1' })}>
            <PairKey>Garage</PairKey>
            <PairValue>{garageCount}</PairValue>
          </Pair>
        </Flex>
      </Stack>
    </Container>
  )
}

export const Container = ({
  children,
  ...stackProps
}: BoxProps) => (
  <Box
    p={2}
    backgroundColor='teal.500'
    w='full'
    className={classNames(animationClasses.fadeIn, animationClasses.faster)}
    {...stackProps}
  >
    {children}
  </Box>
)

export const getSearchEntryMarkerMapPopupOverrides = (): SystemStyleObject => ({
  '& .mapboxgl-popup-content': {
    padding: 0,
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: 3,
    overflow: 'hidden',
  },
  [`& .mapboxgl-popup-anchor-top,
        & .mapboxgl-popup-anchor-top-right,
        & .mapboxgl-popup-anchor-top-left
      `]: {
    '.mapboxgl-popup-tip': {
      borderBottomColor: 'teal.500',
    },
  },
  [`& .mapboxgl-popup-anchor-bottom,
        & .mapboxgl-popup-anchor-bottom-right,
        & .mapboxgl-popup-anchor-bottom-left
      `]: {
    '.mapboxgl-popup-tip': {
      borderTopColor: 'teal.500',
    },
  },
  '& .mapboxgl-popup-anchor-top-right .mapboxgl-popup-content': {
    borderTopRightRadius: 0,
  },
  '& .mapboxgl-popup-anchor-top-left .mapboxgl-popup-content': {
    borderTopLeftRadius: 0,
  },
  '& .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content': {
    borderBottomRightRadius: 0,
  },
  '& .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content': {
    borderBottomLeftRadius: 0,
  },
  '& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
    borderRightColor: 'teal.500',
  },
  '& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
    borderLeftColor: 'teal.500',
  },
  '& .mapboxgl-popup-close-button': {
    display: 'none',
  },
})
