import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

export const login: AccessDeps['login'] = async payload =>
  await loginUnparsed(payload)
    .then(async response => await response.json())
    .then(() => ({
      status: 'success' as const,
    }))
    .catch(error => {
      if (error instanceof HTTPError && error.response.status === 401)
        return { status: 'credentials-error' as const }
      throw error
    })

export const loginUnparsed = async (payload: Parameters<AccessDeps['login']>[0]) =>
  await restClient.post('login', { json: payload })
