import { Box, Button, ButtonProps, Center, Checkbox, Flex, HStack, MenuButton, MenuItemOption, MenuOptionGroup, Portal, Switch, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { MenuTitle } from 'presentation/components/Menu'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { ThumbsDownIcon } from 'presentation/components/ThumbButtons'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { CMAEntriesShowValue, CMAEntriesViewValue, closeViewMenu, setShowValues, setViewValue, toggleViewMenu, useEntriesToolbarMenu, useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { pipe, uniq } from 'remeda'

/**
 * Storybook Test Ids
 */
export const VIEW_MENU__BUTTON_ID = 'cma-entries-view-menu__button'
export const VIEW_MENU__TABLE_VIEW_BUTTON_ID = 'cma-entries-view-menu__table-view__button'

export const CMAEntriesViewMenu = (props: ButtonProps) => {
  const { showValues } = useEntriesToolbarMenu()

  const selectedItems = showValues

  const handleOnSelectionChange = (value: string[] | string) => {
    if (!Array.isArray(value)) return

    const initial = value as CMAEntriesShowValue[]

    const filtered = pipe(
      initial,
      values =>
        values.includes('SOLD')
          ? [...values, 'LEASED'] satisfies CMAEntriesShowValue[]
          : values.filter(v => v !== 'LEASED'),
      values =>
        values.includes('FOR_SALE')
          ? [...values, 'SALE_OR_LEASE', 'FOR_SALE', 'FOR_LEASE'] satisfies CMAEntriesShowValue[]
          : values.filter(v => v !== 'SALE_OR_LEASE' && v !== 'FOR_SALE' && v !== 'FOR_LEASE'),
      values =>
        values.includes('OFF_MARKET')
          ? [...values, 'CANCELED', 'EXPIRED', 'WITHDRAWN'] satisfies CMAEntriesShowValue[]
          : values.filter(v => v !== 'CANCELED' && v !== 'EXPIRED' && v !== 'WITHDRAWN'),
    )

    setShowValues(uniq(filtered))
  }

  const { isViewOpen } = useEntriesToolbarMenu()
  const viewValue = useResponsiveViewValue()

  const isMobile = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  })

  const handleOnViewChange = (value: CMAEntriesViewValue) => {
    if (isMobile && value === 'TABLE_VIEW')
      return

    setViewValue(value)
  }

  const switchJsx = (
    <Switch
      colorScheme='special'
      size='lg'
      isChecked={viewValue === 'TABLE_VIEW'}
      onChange={() => {
        if (isMobile) return
        handleOnViewChange(viewValue === 'TABLE_VIEW' ? 'TILE_VIEW' : 'TABLE_VIEW')
      }}
    />
  )

  return (
    <Menu
      closeOnSelect={false}
      closeOnBlur={true}
      isOpen={isViewOpen}
      onClose={() => closeViewMenu()}
    >
      <MenuButton
        data-testid={VIEW_MENU__BUTTON_ID}
        as={Button}
        colorScheme='neutral'
        variant='solid'
        rightIcon={<ChevronDownIcon />}
        size={mbp({ mobSm: 'xs-narrow', mob: 'xs' })}
        onClick={() => toggleViewMenu()}
        {...props}
      >
        View
      </MenuButton>
      <Portal>
        <ResponsiveMenuList>
          <Box
          // Remove default background color of menu item when checked
            sx={{
              '.chakra-menu__menuitem-option[aria-checked="true"]': {
                background: 'none',
                color: 'graystrong.400',
              },
              '.chakra-menu__menuitem-option[aria-checked="true"]:hover': {
                bg: 'card.bg.xlightblue',
                color: 'accent.blue-to-gray',
              },
            }}
          >
            <MenuTitle>Show:</MenuTitle>
            <MenuOptionGroup
              type='checkbox'
              value={selectedItems}
              onChange={handleOnSelectionChange}
            >
              <MenuItemOption value='SOLD'>
                <Flex gap={1}>
                  <Checkbox
                    isChecked={selectedItems.includes('SOLD') || selectedItems.includes('LEASED')}
                    pointerEvents='none'
                  />
                  <MLSStatusTag status='SOLD'>
                    Sold / Leased
                  </MLSStatusTag>
                </Flex>
              </MenuItemOption>

              <MenuItemOption value='PENDING'>
                <Flex gap={1}>
                  <Checkbox
                    isChecked={selectedItems.includes('PENDING')}
                    pointerEvents='none'
                  />
                  <MLSStatusTag status='PENDING' />
                </Flex>
              </MenuItemOption>

              <MenuItemOption value='FOR_SALE'>
                <Flex gap={1}>
                  <Checkbox
                    isChecked={
                      selectedItems.includes('FOR_SALE')
                      || selectedItems.includes('FOR_LEASE')
                      || selectedItems.includes('SALE_OR_LEASE')
                    }
                    pointerEvents='none'
                  />
                  <MLSStatusTag status='FOR_SALE'>
                    For Sale / Lease
                  </MLSStatusTag>
                </Flex>
              </MenuItemOption>

              <MenuItemOption value='OFF_MARKET'>
                <Flex gap={1}>
                  <Checkbox
                    isChecked={selectedItems.includes('OFF_MARKET')
                    || selectedItems.includes('CANCELED')
                    || selectedItems.includes('EXPIRED')
                    || selectedItems.includes('WITHDRAWN')}
                    pointerEvents='none'
                  />
                  <MLSStatusTag status='OFF_MARKET' />
                </Flex>
              </MenuItemOption>

              <MenuItemOption value='THUMBS_DOWN'>
                <Flex gap={1}>
                  <Checkbox
                    isChecked={selectedItems.includes('THUMBS_DOWN')}
                    pointerEvents='none'
                  />
                  <Text textStyle='bodyMFat' color='inherit'>
                    Thumbs Down
                  </Text>
                  <ThumbsDownIcon value='down' boxSize={3} />
                </Flex>
              </MenuItemOption>
            </MenuOptionGroup>
          </Box>

          <Center
            bg='highlight.500'
            p={2}
            {...mbpg({
              mobSm: {
                mr: -2,
                mb: -6,
              },
              tabSm: {
                mr: -2,
                mb: -2,
                borderBottomRadius: 2,
              },
            })}
          >
            <HStack spacing={1}>
              <Text
                flex='1'
                textStyle='bodyMFat'
                color={viewValue === 'TILE_VIEW' ? 'neutral.500' : 'grayweak.900'}
              >
                Tile&nbsp;View
              </Text>
              <Breakpoint
                mobSm={(
                  <Tooltip
                    label='Table view is available only on desktop and tablet'
                    isEnabledOnTouchDevice
                  >
                    <Box>
                      {switchJsx}
                    </Box>
                  </Tooltip>
                )}
                tabSm={switchJsx}
              />
              <Text
                flex='1'
                textStyle='bodyMFat'
                color={viewValue === 'TABLE_VIEW' ? 'neutral.500' : 'grayweak.900'}
              >
                Table&nbsp;View
              </Text>
            </HStack>
          </Center>
        </ResponsiveMenuList>
      </Portal>
    </Menu>
  )
}
