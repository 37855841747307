import { createIcon } from '@chakra-ui/react'

export const CardMastercardLightIcon = createIcon({
  displayName: 'CardMastercardLightIcon',
  viewBox: '0 0 47 32',
  path: (
    <>
      <rect width='46.6721' height='32' rx='4' fill='#FFCF77' />
      <path fillRule='evenodd' clipRule='evenodd' d='M23.3361 23.0164C25.339 21.3378 26.6125 18.8176 26.6125 16C26.6125 13.1824 25.339 10.6623 23.3361 8.98361C24.9259 7.65121 26.9751 6.84897 29.2118 6.84897C34.2664 6.84897 38.3639 10.946 38.3639 16C38.3639 21.054 34.2664 25.1511 29.2118 25.1511C26.9751 25.1511 24.9259 24.3488 23.3361 23.0164ZM23.3361 23.0164C21.7464 24.3488 19.6971 25.1511 17.4604 25.1511C12.4059 25.1511 8.30835 21.054 8.30835 16C8.30835 10.946 12.4059 6.84897 17.4604 6.84897C19.6971 6.84897 21.7464 7.65121 23.3361 8.98361C21.3332 10.6623 20.0597 13.1824 20.0597 16C20.0597 18.8176 21.3332 21.3378 23.3361 23.0164Z' fill='#916C27' />
    </>
  ),
})
