import { Box, BoxProps, Button, Center, Image, Spinner } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { Address } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { NullProps } from 'libs/utils.types'
import { StreetViewIcon } from 'presentation/components/Icons/StreetViewIcon'
import { GOOGLE_KEY } from 'presentation/const/libs.const'
import { loadGoogleMaps } from 'presentation/libs/loadGoogleMaps'
import QueryString from 'qs'
import { useEffect, useState } from 'react'

const IS_TOGGLE_DISABLED = true

type PropertyPreviewCardProps = BoxProps & {
  address: NullProps<Address>
  location: Location | null
  imageWidth?: number
  imageHeight?: number
}

export const PropertyPreviewCard = (props: PropertyPreviewCardProps) => (
  <PropertyPreviewCardBase
    key={`${props.imageWidth}x${props.imageHeight}`}
    {...props}
  />
)

const PropertyPreviewCardBase = ({
  address,
  location,
  imageWidth = 218,
  imageHeight = 126,
  ...props
}: PropertyPreviewCardProps) => {
  const [panoramaRef, setPanoramicRef] = useState<HTMLDivElement | null>(null)
  const { canToggle, state, toggle } = usePreview({
    location,
    panoramaRef,
    imageWidth,
    imageHeight,
  })

  return (
    <Box
      id='property-preview-card'
      borderRadius={2}
      overflow='hidden'
      boxSize='full'
      pos='relative'
      {...props}
    >
      {pipe(
        Match.value(state),
        Match.when({ status: 'static-streetview' }, ({ url }) => (
          <>
            <Image
              src={url}
              boxSize='full'
              objectFit='fill'
            />
          </>
        )),
        Match.whenOr(
          { status: 'panorama-streetview' },
          { status: 'loading', panoramaTransitionData: {} },
          () => (
            <Box
              ref={setPanoramicRef}
              boxSize='full'
              border='1px solid red'
              onClick={ev => ev.stopPropagation()}
            />
          )),
        Match.orElse(() => (
          <Center boxSize='full'>
            <Spinner />
          </Center>
        )),
      )}

      {!IS_TOGGLE_DISABLED && canToggle && (
        <Button
          pos='absolute'
          top={1}
          left={1}
          boxSize={3.5}
          borderRadius='full'
          bg='grayweak.200'
          color='graystrong.600'
          borderColor='grayweak.500'
          borderWidth={0.125}
          onClick={ev => {
            ev.stopPropagation()
            toggle()
          }}
          p={0}
        >
          <StreetViewIcon />
        </Button>
      )}
    </Box>
  )
}

const createStaticUrl = ({
  heading,
  location,
  imageWidth,
  imageHeight,
}: {
  heading: number
  location: Location
  imageWidth?: number
  imageHeight?: number
}) => {
  const BASE_URL = `https://maps.googleapis.com/maps/api/streetview`
  const params = QueryString.stringify({
    size: `${imageWidth}x${imageHeight}`,
    location: `${location.latitude},${location.longitude}`,
    heading,
    fov: 90,
    pitch: 0,
    key: GOOGLE_KEY,
  })

  return `${BASE_URL}?${params}`
}

type PanoramaData = {
  location: Location
  heading: number
}

const getPanoramaData = ({
  location,
}: {
  location: Location
}) => new Promise<PanoramaData>((resolve, reject) => {
  void loadGoogleMaps()
    .then(() => {
      const propertyLocation = { lat: location.latitude, lng: location.longitude }
      const streetViewService = new google.maps.StreetViewService()
      const STREETVIEW_MAX_DISTANCE = 50

      void streetViewService.getPanorama({
        location: propertyLocation,
        radius: STREETVIEW_MAX_DISTANCE,
      }, (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          const panoramaLocation = data?.location?.latLng

          if (!panoramaLocation)
            return reject(new Error('Failed to load panorama location'))

          const heading = google.maps.geometry.spherical.computeHeading(panoramaLocation, propertyLocation)

          if (!heading)
            return reject(new Error('Failed to calculate heading'))

          return resolve({
            location: {
              latitude: panoramaLocation.lat(),
              longitude: panoramaLocation.lng(),
            },
            heading,
          })
        } else {
          return reject(new Error('Failed to load heading'))
        }
      })
    })
})

type PreviewState =
  | {
    status: 'loading'
    location: Location
    panoramaTransitionData?: {
      panoramaData: PanoramaData | null
    }
  }
  | {
    status: 'failed'
  }
  | {
    status: 'static-streetview'
    url: string
    location: Location
    panoramaData: PanoramaData
  }
  | {
    status: 'panorama-streetview'
    panoramaRef: HTMLDivElement
    location: Location
    panoramaData: PanoramaData | null
  }

type PanoramaDataCacheData = {
  params: {
    location: Location
  }
  panoramaData: PanoramaData
}

const useCachedPanoramaData = () => {
  const [cacheData, setCacheData] = useState<PanoramaDataCacheData | null>(null)

  const getHeadingCached = ({
    location,
  }: {
    location: Location
  }) => {
    const cachedData = cacheData
    if (cachedData && Location.isEqual(cachedData.params.location, location))
      return Promise.resolve(cachedData.panoramaData)

    return getPanoramaData({ location })
      .then(panoramaData => {
        setCacheData({ params: { location }, panoramaData })
        return panoramaData
      })
  }

  return getHeadingCached
}

const usePreview = ({
  location: locationFromParams,
  panoramaRef,
  imageWidth,
  imageHeight,
}: {
  location: Location | null
  panoramaRef: HTMLDivElement | null
  imageWidth: number
  imageHeight: number
}) => {
  const getPanoramaData = useCachedPanoramaData()

  const initialState = locationFromParams
    ? { status: 'loading', location: locationFromParams } as const
    : { status: 'failed' } as const
  const [state, setState] = useState<PreviewState>(initialState)

  // Handle initial state and transition to streetview
  useEffect(() => {
    if (state.status !== 'loading') return

    if (state.panoramaTransitionData && !panoramaRef) return

    if (state.panoramaTransitionData && panoramaRef) {
      return setState({
        status: 'panorama-streetview',
        panoramaRef,
        location: state.location,
        panoramaData: state.panoramaTransitionData.panoramaData,
      })
    }

    const location = state.location
    void getPanoramaData({ location })
      .then(panoramaData => {
        if (panoramaData) {
          setState({
            status: 'static-streetview',
            url: createStaticUrl({
              heading: panoramaData.heading,
              location: panoramaData.location,
              imageWidth,
              imageHeight,
            }),
            location,
            panoramaData,
          })
        } else {
          setState({
            status: 'loading',
            location,
            panoramaTransitionData: {
              panoramaData: null,
            },
          })
        }
      })
      .catch(() => {
        setState({ status: 'failed' })
      })
  }, [state, panoramaRef])

  // Handle setting up panorama streetview
  useEffect(() => {
    (async () => {
      try {
        if (state.status !== 'panorama-streetview') return

        const panoramaData = await getPanoramaData({ location: state.location })
          .catch(() => null)
        const googleMaps = await loadGoogleMaps()

        const streetViewParams = panoramaData
          ? {
            position: {
              lat: panoramaData.location.latitude,
              lng: panoramaData.location.longitude,
            },
            visible: true,
            pov: {
              heading: panoramaData.heading,
              pitch: 0,
            },
          }
          : {
            position: {
              lat: state.location.latitude,
              lng: state.location.longitude,
            },
            visible: true,
          }

        void new googleMaps.StreetViewPanorama(state.panoramaRef, streetViewParams)
      } catch {
        setState({ status: 'failed' })
      }
    })()
  }, [state])

  const toggle = () => {
    if (state.status === 'static-streetview') {
      setState({
        status: 'loading',
        location: state.location,
        panoramaTransitionData: {
          panoramaData: state.panoramaData,
        },
      })
    } else if (state.status === 'panorama-streetview' && state.panoramaData) {
      setState({
        status: 'static-streetview',
        url: createStaticUrl({
          heading: 0,
          location: state.location,
        }),
        location: state.location,
        panoramaData: state.panoramaData,
      })
    }
  }

  // Basically just checks the presence of panoramaData
  const canToggle = state.status === 'static-streetview'
    || (
      state.status === 'panorama-streetview'
      && !!state.panoramaData
    )

  return {
    canToggle,
    state,
    toggle,
  }
}
