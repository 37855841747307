import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { MapLegend } from 'presentation/components/MapLegend/MapLegend'
import { PropertyMarkerLegendItems } from 'presentation/components/MapLegend/PropertyMarkerLegendItems'
import { MapModeToggle } from 'presentation/components/MapModeToggle'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { useSearchHistoryListPanelStore } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { SearchHistoryMap } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap'
import { SearchHistoryDrawerPanel } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistoryDrawerPanel'
import { SearchHistorySidePanel } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/SearchHistorySidePanel'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { createPortal } from 'react-dom'

export const CONTROL_LAYER_MAX_WIDTH = '392px'

export const SIDE_PANEL_BOTTOM_RADIUS = '32px'

export const SIDE_PANEL_WIDTH = '384px'

export const ReturningUser = () => {
  const { isPanelOpen } = useSearchHistoryListPanelStore()
  const isDrawerMode = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  })

  const widgetBaseOffset = useSwitchBreakpoint({
    mobSm: '8px',
    tabSm: '32px',
  }) ?? '0'

  const mapLeftOffset = isPanelOpen && !isDrawerMode
    ? `calc(${SIDE_PANEL_WIDTH as string} - ${SIDE_PANEL_BOTTOM_RADIUS})`
    : '0'

  const mapWidgetLeftOffset = isPanelOpen && !isDrawerMode
    ? `calc(${SIDE_PANEL_WIDTH as string} + ${widgetBaseOffset})`
    : widgetBaseOffset

  return (
    <>
      <Grid gridTemplateAreas='"main"' h='100%' minH={0}>
        <GridItem
          gridArea='main'
          h='100%'
          minH={0}
          __css={{
            ml: mapLeftOffset,
          }}
        >
          <SearchHistoryMap />
        </GridItem>

        {!isDrawerMode && (
          <GridItem
            gridArea='main'
            alignSelf='start'
            w={SIDE_PANEL_WIDTH}
            zIndex='sticky'
            pointerEvents='none'
            justifyContent='stretch'
            h='100%'
            minH='0'
          >
            <SearchHistorySidePanel />
          </GridItem>
        )}

        <GridItem
          gridArea='main'
          alignSelf='end'
          justifySelf='stretch'
          zIndex='sticky'
          mb={mbp({ mobSm: 7, tabSm: 4 })}
          ml={mapWidgetLeftOffset}
          pointerEvents='none'
        >
          <Flex
            flexDirection='column'
            gap={1}
            w={mbp({ mobSm: '100%', dtSm: '408px' })}
            zIndex='popover'
            align='start'
            sx={{
            /**
               * ======================
               * Tablet with side panel open and legend open forces to
               * create a horizontal scroll because it doesn't fit the
               * view port. This is a hack to pull the legend to the left.
               * ======================
               */
              '& .chakra-collapse': {
                marginLeft: mbp({ mobSm: '0', tabSm: '-100px', dtSm: '0' }),
              },
            }}
          >
            <Box pointerEvents='auto'>
              <MapLegend>
                <PropertyMarkerLegendItems />
              </MapLegend>
            </Box>
            <Box pointerEvents='auto'>
              <MapModeToggle />
            </Box>
          </Flex>
        </GridItem>
      </Grid>

      {isDrawerMode
      && createPortal(
        <Box
          position='absolute'
          top='0'
          left='0'
          right='0'
          bottom='0'
          pointerEvents='none'
          zIndex='overlay'
        >
          <SearchHistoryDrawerPanel />
        </Box>,
        document.body,
      )}
    </>
  )
}
