import { Alert, AlertDescription, AlertIcon, Avatar, Box, Flex, FlexProps, FormControl, FormErrorMessage, IconButton, Input, Text } from '@chakra-ui/react'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PlusIcon, TrashIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { Card, CardBody, CardButton, CardFooter, CardHeader, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { Table, Tbody, Td, TdProps, Th, Thead, Tr } from 'presentation/components/molecules/Table'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { Plan } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { PartialInviteMembersError } from 'presentation/screens/MembersScreen/MembersScreen.errors'
import { InviteMembersModalViewModel } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModal.viewModel'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, useEffect } from 'react'
import { UseFormReturn, useForm } from 'react-hook-form'
import { EmailListForm } from './InviteMembersModal.types'

const MAX_EMAILS_AT_A_TIME = 5

type SingleEmailForm = { email?: string }

export const InviteMembersModalCard: FC<InviteMembersModalViewModel & {
  emailListForm: UseFormReturn<EmailListForm, object>
  onClose: () => void
}> = ({ onClose, emailListForm, emails = [], ...viewModel }) => {
  const { sbp } = useSwitchBreakpointFn()

  const plan = viewModel.plan

  // =============================================================================
  // Input (individual email)
  // =============================================================================
  const singleEmailForm = useForm<SingleEmailForm>()

  emailListForm.register('emails', {
    validate: emails => !emails?.length ? NO_EMAIL_ERR_MSG : true,
  })

  const submitForm = emailListForm.handleSubmit(({ emails }) => {
    if (viewModel.status === 'loading') return
    viewModel.onInvite({ emails })
  })

  const addEmail = singleEmailForm.handleSubmit(async ({ email }) => {
    const emails = emailListForm.getValues('emails') || []
    if (!email) return
    emailListForm.setValue('emails', [...emails, email])
    void emailListForm.trigger('emails')
  })

  const handleEmailListFormSubmit = async () => {
    await addEmail()
    if (singleEmailForm.formState.isValid)
      void submitForm()
  }

  const handleSingleEmailFormSubmit = addEmail

  const handleRemoveEmail = (email: string) => {
    const emails = emailListForm.getValues('emails') || []
    emailListForm.setValue('emails', emails.filter(e => e !== email))
  }

  useEffect(() => {
    singleEmailForm.reset()
  }, [singleEmailForm.formState.isSubmitSuccessful])

  // =============================================================================
  // Local error management
  // =============================================================================
  const singleEmailFormError = singleEmailForm.formState.errors.email?.message
  const highlightedError = singleEmailFormError || emailListForm.formState.errors.emails?.message

  // Clear emails list error when individual email error is cleared
  useEffect(() => {
    if (!singleEmailFormError) emailListForm.clearErrors('emails')
  }, [singleEmailFormError])

  // =============================================================================
  // Handle partial error
  // =============================================================================
  useEffect(() => {
    if (
      viewModel.status !== 'error'
      || !(viewModel.error instanceof PartialInviteMembersError)
    ) return // Only handle partial errors

    emailListForm.setValue('emails', viewModel.error.remainingEmails)

    viewModel.error.fields.emails?.forEach((error, i) => {
      if (!error) return
      emailListForm.setError(`emails.${i}`, { message: error.message })
    })
  }, [viewModel.status])

  // =============================================================================
  // Formatting
  // =============================================================================
  const formatted = {
    limit: plan.limit === Infinity ? 'Unlimited' : plan.limit,
    pluralizedMember: pluralize('Members', plan.limit),
    planNameWithInterval: formatPlanNameWithInterval(plan),
  }

  // =============================================================================
  // Render
  // =============================================================================
  return (
    <Card
      colorScheme='card.bg.1'
      size='modal.default.w'
    >
      <CardHeader>
        <CardPrimaryTitle flex='1'>Invite Team Members</CardPrimaryTitle>
        <CardPrimaryText>
          {formatted.limit}
          {' '}
          Team
          {formatted.pluralizedMember}
          {' '}
          are included in the
          {' '}
          {formatted.planNameWithInterval}
          {' '}
          Plan ($
          {Dollar.fromCents(plan.price)}
          )
        </CardPrimaryText>
      </CardHeader>
      <CardBody>
        <Table minH='0' mt={-1} mb={4} shouldHideExpandButton w='auto'>
          <Thead>
            <Tr>
              <Th w='full'>Email Address</Th>
              <Th textAlign='right'>{emails.length ? 'Amount' : ''}</Th>
              <Th columnType='icon-button' />
            </Tr>
          </Thead>
          <Tbody>
            {emails.length === 0
              ? (
                <Tr
                  smallScreenExpandedContent={null}
                >
                  <Td
                    colSpan={3}
                    color='graystrong.100'
                    textStyle={mbp({
                      mobSm: 'bodyM',
                      tabSm: 'bodyL',
                    })}
                  >
                    No email addresses added yet
                  </Td>
                </Tr>
              )
              : emails.map((email, i) => {
                const error = emailListForm.formState.errors.emails?.[i]?.message

                return (
                  <Tr
                    key={email}
                    smallScreenExpandedContent={null}
                  >
                    <Td wordBreak='break-all'>
                      <Box>
                        <Flex alignItems='center' py={1}>
                          <Avatar
                            size='sm'
                            name={email}
                            colorScheme='specialsat.500'
                          />

                          <Text as='span' ml={1}>
                            {email}
                          </Text>
                        </Flex>

                        {!!error && (
                          <Alert variant='ghost-triggered' colorScheme='negative' mt={-0.5} mb={0.5}>
                            <AlertIcon
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                              {...sbp({ mobSm: { boxSize: 4 }, tabSm: {} })}
                            >
                              <TriangleExclamationIcon />
                            </AlertIcon>
                            <AlertDescription>{error}</AlertDescription>
                          </Alert>
                        )}
                      </Box>
                    </Td>
                    <StickToTopTd whiteSpace='nowrap' textAlign='right'>
                      <StickToTopCellContainer>
                        <Breakpoint
                          mobSm='no cost'
                          tabSm='no additional cost'
                        />
                      </StickToTopCellContainer>
                    </StickToTopTd>
                    <StickToTopTd whiteSpace='nowrap' columnType='icon-button'>
                      <StickToTopCellContainer>
                        <IconButton
                          isDisabled={viewModel.status === 'loading'}
                          onClick={() => handleRemoveEmail(email)}
                          aria-label='Remove email'
                          icon={<TrashIcon />}
                          size='md'
                          variant='icon-ghost'
                          colorScheme='negative'
                        />
                      </StickToTopCellContainer>
                    </StickToTopTd>
                  </Tr>
                )
              })}

            <Tr
              smallScreenExpandedContent={null}
            >
              <Td colSpan={2}>
                <Box as='form' py={1} w='full' onSubmit={handleSingleEmailFormSubmit}>
                  <FormControl isInvalid={!!highlightedError}>
                    <Input
                      isDisabled={viewModel.status === 'loading'}
                      placeholder='Enter email address'
                      type='email'
                      w='full'
                      {...singleEmailForm.register('email', {
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: INVALID_EMAIL_ERR_MSG,
                        },
                        validate: email => {
                          const emails = emailListForm.getValues('emails') || []
                          if (email && emails.includes(email))
                            return EMAIL_ALREADY_ADDED_ERR_MSG
                          if (emails.length >= MAX_EMAILS_AT_A_TIME)
                            return MAX_EMAIL_EXCEEDED_MSG
                          return true
                        },
                      })}
                    />
                    {highlightedError && (
                      <FormErrorMessage>
                        {highlightedError}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <button type='submit' hidden />
                </Box>
              </Td>
              <StickToTopTd whiteSpace='nowrap' columnType='icon-button'>
                <StickToTopCellContainer
                  /** Increase height because input cell is a bit taller */
                  h={6.5}
                >
                  <IconButton
                    isDisabled={viewModel.status === 'loading'}
                    onClick={handleSingleEmailFormSubmit}
                    aria-label='Add email'
                    icon={<PlusIcon />}
                    size='md'
                    variant='icon-ghost'
                    colorScheme='neutral'
                  />
                </StickToTopCellContainer>
              </StickToTopTd>
            </Tr>
          </Tbody>
        </Table>
      </CardBody>
      <CardFooter>
        <CardButton
          variant='outline'
          colorScheme='negative'
          isDisabled={viewModel.status === 'loading'}
          onClick={onClose}
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='neutral'
          onClick={handleEmailListFormSubmit}
          isLoading={viewModel.status === 'loading'}
          loadingText='Inviting'
        >
          Invite
        </CardButton>
      </CardFooter>
    </Card>
  )
}

const NO_EMAIL_ERR_MSG = 'Please add at least one email. Press the plus (+) button on the right or the invite button.'
const INVALID_EMAIL_ERR_MSG = 'Please fill in valid email address'
const EMAIL_ALREADY_ADDED_ERR_MSG = 'Email already added'
const MAX_EMAIL_EXCEEDED_MSG = `You can only invite ${MAX_EMAILS_AT_A_TIME} emails at a time`

const formatPlanNameWithInterval = (plan: Plan) => {
  const interval = plan.interval === 'MONTH'
    ? 'Monthly'
    : plan.interval === 'YEAR'
      ? 'Yearly'
      : plan.interval === 'DAY'
        ? 'Daily'
        : 'Weekly'

  return `${plan.name} (${interval})`
}

/**
 * A trick to make the cell stick to the top of the table when there are errors
 */
const StickToTopCellContainer: FC<FlexProps> = props => (
  <Flex h={6} align='center' {...props} />
)

const StickToTopTd: FC<TdProps> = props => (
  <Td alignItems='flex-start' {...props} />
)
