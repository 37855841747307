import { createIcon } from '@chakra-ui/react'

export const PrinterIcon = createIcon({
  displayName: 'PrinterIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='M6 9V2H18V9' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 14H6V22H18V14Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
