import { Settings } from 'features/Settings/domain/Settings.domain'
import { mergeDeep } from 'remeda'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type SettingsState = {
  settingsState: { status: 'init', settings: Settings.LocallyStoredSettings }
  | { status: 'loading', settings: Settings.LocallyStoredSettings }
  /**
     * We merged success and error state, because we probably don't have to
     * halt the app just because the settings failed to load.
     */
  | { status: 'loaded', settings: Settings, error: Error | null }
  actions: {
    getSettings: {
      execute: () => Promise<void>
    }
    updateSettings: {
      error: Error | null
      execute: (payload: Settings.UpdateSettingsPayload) => Promise<void>
    }
  }
}

export type SettingsDeps = {
  getSettings: () => Promise<Settings.GetSettingsResult>
  updateSettings: (payload: Settings.UpdateSettingsPayload) => Promise<Settings.UpdateSettingsResult>
  getLocallyStoredSettings: () => Settings.GetLocallyStoredSettingsResult
  updateLocallyStoredSettings: (payload: Settings.UpdateLocallyStoredSettingsPayload) =>
  Settings.UpdateLocallyStoredSettingsResult
}

export const createSettingsStore = (deps: SettingsDeps) => create<SettingsState>()(immer((
  set,
  get,
) => ({
  settingsState: {
    status: 'init',
    settings: deps.getLocallyStoredSettings(),
  },
  actions: {
    getSettings: {
      execute: async () => {
        const initialState = get().settingsState
        const initialStatus = initialState.status
        const isLoading = initialStatus === 'loading'
        const hasLoadedWithoutError = initialStatus === 'loaded' && !initialState.error
        if (isLoading || hasLoadedWithoutError)
          return

        if (initialStatus === 'init') {
          set(state => {
            state.settingsState = {
              status: 'loading',
              settings: state.settingsState.settings,
            }
          })
        }

        await deps.getSettings()
          .then(settings => {
            set(state => {
              state.settingsState = {
                status: 'loaded',
                settings,
                error: null,
              }
            })
            deps.updateLocallyStoredSettings({ settings })
          })
          .catch(error => {
            set(state => {
              state.settingsState = {
                status: 'loaded',
                settings: Settings.FALLBACK_SETTINGS,
                error,
              }
            })
          })
      },
    },
    updateSettings: {
      error: null,
      execute: async payload => {
        const initialState = get().settingsState
        if (initialState.status !== 'loaded')
          return

        const destination = initialState.settings
        const source = payload.settings
        const merged = mergeDeep(destination, source) as Settings

        set(state => {
          state.settingsState.settings = merged
        })
        deps.updateLocallyStoredSettings({ settings: merged })

        await deps.updateSettings(payload)
          .catch(error => {
            set(state => {
              state.actions.updateSettings.error = error
            })
          })
      },
    },
  },
})))

export type SettingsStore = ReturnType<typeof createSettingsStore>
