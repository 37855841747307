import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { useOneToastAtATimeFn } from 'presentation/libs/useOneToastAtATime'
import { useEffect, useRef } from 'react'

export const useCompsToasts = () => {
  const status = useCMAStore(state => state.local.report.status)
  const lastStatusRef = useRef(status)
  const oneAtATime = useOneToastAtATimeFn()

  useEffect(() => {
    if (status === 'error') {
      oneAtATime.toast.error({
        title: 'Something went wrong with comparable sales',
        message: 'Please try again or contact support.',
      })
    }

    lastStatusRef.current = status
  }, [status])
}
