import type { SystemStyleObject } from '@chakra-ui/theme-tools'

const baseStyle: SystemStyleObject = {
  bg: 'v2.gray.700',
  color: 'v2.gray.300',
  borderRadius: 'full',
}

const sizes: Record<string, SystemStyleObject> = {
  md: {
    boxSize: 4,
  },
}

const defaultProps = {
  size: 'md',
}

export const CloseButton = {
  baseStyle,
  sizes,
  defaultProps,
}
