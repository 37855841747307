import { MembersScreen } from 'presentation/screens/MembersScreen/MembersScreen'
import { useMembersScreenController } from 'presentation/screens/MembersScreen/MembersScreen.controller'
import { MembersScreenUseCases } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { FC } from 'react'

export const ControllableMembersScreen: FC<MembersScreenUseCases> = useCases => {
  const viewModel = useMembersScreenController(useCases)

  return (
    <MembersScreen {...viewModel} />
  )
}
