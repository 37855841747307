import { GetParcelMarketingListQuery, ListTypes, MarketingListsEdge } from '__generated__/graphql'
import { Array, Effect, Match, Option, pipe } from 'effect'
import { isNotNull } from 'effect/Predicate'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import CursorId from 'features/valueObjects/CursorId'

export const MARKETINGLIST_PAGINATION_LIMIT = 50

const toDomain = (data: GetParcelMarketingListQuery): Effect.Effect<MarketingListRepo.GetByCursorResult> => {
  const list = data.parcel?.marketingLists

  if (!list)
    return Effect.dieMessage('Parcel is not defined')

  return Match.value(list).pipe(
    Match.when({ __typename: 'MarketingListsConnection' }, l => {
      const edges = l.edges as (MarketingListsEdge | null)[]
      const totalCount = l.pageInfo.totalCount
      const hasPrev = l.pageInfo.hasPreviousPage
      const hasNext = l.pageInfo.hasNextPage

      const marketingListEdge = pipe(
        edges,
        Array.filter(e => isNotNull(e)),
        Array.map(e => e as MarketingListsEdge),
      )

      const marketingList = Array.map(marketingListEdge, list => {
        const type = Match.value(list.node.type).pipe(
          Match.when(ListTypes.LegacySellers, (): ListType => 'property-lists'),
          Match.when(ListTypes.LegacyPrivateLenders, (): ListType => 'private-lenders'),
          Match.when(ListTypes.LegacyCashBuyers, (): ListType => 'cash-buyers'),
          Match.when(ListTypes.LegacyDrive, (): ListType => 'drive'),
          Match.orElse(() => { throw new Error('ListType does not exist') }),
        )

        return MarketingList.make({
          id: MarketingListId.make(list.node.id),
          memberCount: 0,
          name: list.node.name ?? '', // @How to handle?
          type,
          createdAt: list.node.createdAt ? new Date(list.node.createdAt) : new Date(), // @TODO: make created at nullable
          updatedAt: list.node.updatedAt ? new Date(list.node.updatedAt) : new Date(), // @TODO: make created at nullable
          criteria: null, // @TODO: get criteria
        })
      })

      const cursors = Array.map(marketingListEdge, l => CursorId.fromString(l.cursor))

      return Effect.succeed<MarketingListRepo.GetByCursorResult>({
        lists: marketingList,
        totalCount,
        countPerPage: MARKETINGLIST_PAGINATION_LIMIT,
        firstCursor: hasPrev ? Array.head(cursors) : Option.none(),
        lastCursor: hasNext ? Array.last(cursors) : Option.none(),
      })
    }),
    Match.when({ __typename: 'MarketingListBadRequestError' }, e =>
      Effect.dieMessage(e.message ?? 'MarketingListBadRequestError'),
    ),
    Match.when({ __typename: Match.undefined }, () => Effect.dieMessage('query GetMarketingLists failed')),
    Match.exhaustive,
  )
}

export default toDomain
