import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import { formatCommas, formatK1OptionalDecimal } from 'libs/Number/formatNumber'
import { Dollar } from 'libs/dollar'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import ProductFeatureInterval from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureInterval'
import { ProductFeatureSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'

type ColumnValue = {
  readonly popoverValue: string
  readonly primaryText: string
  readonly secondaryText1: Option.Option<string>
  readonly secondaryText2: string
}

type CompsFeatureRowViewModel = readonly Option.Option<ColumnValue>[]

const isCompsFeature = (feature: ProductFeatureSpec): boolean =>
  feature.featureName === 'Comps'

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const { context } = snapshot
  const vm: CompsFeatureRowViewModel = pipe(
    context.products,
    /**
     * Make sure we only show the product if the plan is available
     */
    Array.filter(product => pipe(
      product.plans,
      Array.some(plan =>
        plan._tag === (context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
    )),
    Array.map(product => pipe(
      product.features,
      Array.findFirst(isCompsFeature),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => pipe(
          Data.struct<ColumnValue>({
            popoverValue: 'Unlimited',
            primaryText: 'Unlimited',
            secondaryText1: Option.none(),
            secondaryText2: '',
          }),
          Option.some,
        )),
        Match.tag('FeatureQuotaSpec', f => pipe(
          Data.struct<ColumnValue>({
            popoverValue: formatCommas(f.quotaLimit),
            primaryText: `${formatK1OptionalDecimal(f.quotaLimit)} Comps`,
            secondaryText1: pipe(
              Match.value(f.replenishInterval),
              Match.when('each', () => Option.some('per hit')),
              Match.orElse(() =>
                Option.some(`per ${ProductFeatureInterval.toSingularForm(f.replenishInterval)}`),
              ),
            ),
            secondaryText2: `${Dollar.formatPennies(f.overageCost)}/ea additional`,
          }),
          Option.some,
        )),
        Match.orElse(() => Option.none()),
      )),
    )),
    Data.array,
  )

  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const CompsFeatureRowViewModel = {
  usePresenter,
}

export default CompsFeatureRowViewModel
