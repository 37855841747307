import { ListBuilderStore } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStore'
import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { Context } from './Provider'

export const useStore = <T>(
  selector: (store: ListBuilderStore) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => {
  const store = useContext(Context)
  if (!store) throw new Error('ListBuilderStore.useStore must be used within a ListBuilderStore.Provider')
  return useStoreWithEqualityFn(store, selector, equalityFn)
}
