import { HStack, Text } from '@chakra-ui/react'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Tooltip } from 'presentation/components/Tooltip'
import * as CardComboModule from 'presentation/components/molecules/Card/CardCombo'
import * as TabsModule from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Fragment, PropsWithChildren, ReactNode } from 'react'

export namespace TabbedCards {
  export const TabsWrapper = TabsModule.SwitchTabsWrapper
  export const TabButtons = TabsModule.SwitchTabButtons
  export const TabButton = TabsModule.SwitchTabButton
  export const TabContents = TabsModule.SwitchTabContents
  export const TabContent = ({ children }: PropsWithChildren) => (
    <TabsModule.SwitchTabContent
      position='relative'
      bg='card.bg.blue'
      px='2'
      pt='1'
      pb='2'
      borderBottomRadius='2'
      borderTopRightRadius='2'
    >
      {children}
    </TabsModule.SwitchTabContent>
  )

  export const CardLabel = ({ children }: PropsWithChildren) => (
    <Text
      color='ondark.1'
      textStyle='bodyMFat'
      px='1'
      sx={{
        'pt': 1,
        '&:first-of-type': {
          pt: 0,
        },
        [`.${CardCombo.className} + &`]: {
          pt: 3,
        },
      }}
    >
      {children}
    </Text>
  )

  export const CardCombo = ({ children }: PropsWithChildren) => (
    <CardComboModule.CardCombo
      mt='1'
      className={CardCombo.className}
    >
      {children}
    </CardComboModule.CardCombo>
  )

  CardCombo.className = 'card-combo'

  export const CardUpperSegmentText = ({ children }: PropsWithChildren) => (
    <Text
      color='graystrong.400'
      textStyle='bodyM'
      as='span'
    >
      {children}
    </Text>
  )

  export const CardUpperSegmentTextEmphasized = ({ children }: PropsWithChildren) => (
    <Text
      color='graystrong.400'
      textStyle='bodyMFat'
      as='span'
    >
      {children}
    </Text>
  )

  export const CardUpperSegment = ({ children }: PropsWithChildren) => (
    <CardComboModule.CardSegment
      bg='neutral.200'
      px='2'
      py='1.5'
      borderTopRadius='2'
    >
      <CardUpperSegmentText>{children}</CardUpperSegmentText>
    </CardComboModule.CardSegment>
  )

  export const CardLowerSegment = ({ children }: PropsWithChildren) => (
    <CardComboModule.CardSegment
      bg='neutral.100'
      borderBottomRadius='2'
      p='2'
      display='grid'
      gridTemplateColumns={mbp({
        mobSm: 'repeat(2, 1fr)',
        tabSm: 'repeat(4, 1fr)',
      })}
      gap='2'
    >
      {children}
    </CardComboModule.CardSegment>
  )

  type PairKeyType = ReactNode
  type PairValueType = string | number | JSX.Element | null | undefined
  type PairConfig = { tooltip?: string }
  type PairType = [PairKeyType, PairValueType] | [PairKeyType, PairValueType, PairConfig]
  type Pairs = (JSX.Element | PairType)[]
  export type CardSectionProps = {
    label: string | null
    upperSegmentText: string | JSX.Element
    pairs: Pairs
  }

  export const CardSection = ({
    label,
    upperSegmentText,
    pairs,
  }: CardSectionProps) => (
    <>
      {label && <TabbedCards.CardLabel>{label}</TabbedCards.CardLabel>}
      <TabbedCards.CardCombo>
        <TabbedCards.CardUpperSegment>{upperSegmentText}</TabbedCards.CardUpperSegment>
        <TabbedCards.CardLowerSegment>
          {pairs.map((pair, i) => {
            if (!Array.isArray(pair)) return pair

            const keyText = pair[0]
            const value = pair[1]
            const tooltip = pair[2]?.tooltip

            if (!tooltip) {
              return (
                <Pair size='md' key={i}>
                  <PairKey>
                    {keyText}
                  </PairKey>
                  <PairValue>
                    {value === null || value === undefined
                      ? '--'
                      : value}
                  </PairValue>
                </Pair>
              )
            }

            return (
              <Pair size='md' key={i}>
                <PairKey>
                  <Tooltip label={tooltip}>
                    <HStack display='inline-flex' justify='center' spacing='0.5'>
                      <span>
                        {keyText}
                        {' '}
                        <RegularCircleExclamationIcon fontSize='2' mt='-0.25' />
                      </span>
                    </HStack>
                  </Tooltip>
                </PairKey>
                <PairValue>
                  {value === null || value === undefined
                    ? '--'
                    : value}
                </PairValue>
              </Pair>
            )
          })}
        </TabbedCards.CardLowerSegment>
      </TabbedCards.CardCombo>
    </>
  )
  type Sections = [CardSectionProps['upperSegmentText'], CardSectionProps['pairs']]

  export type MultiSegmentedCardSectionProps = {
    label: string | null
    sections: Sections[]
  }

  export const MultiSegmentedCardSection = ({
    label,
    sections,
  }: MultiSegmentedCardSectionProps) => (
    <>
      {label && <TabbedCards.CardLabel>{label}</TabbedCards.CardLabel>}
      <TabbedCards.CardCombo>
        {sections.map(([upperSegmentText, pairs], i) => (
          <Fragment key={i}>
            <TabbedCards.CardUpperSegment>{upperSegmentText}</TabbedCards.CardUpperSegment>
            <TabbedCards.CardLowerSegment>
              {pairs.map((pair, i) => {
                if (!Array.isArray(pair)) return pair

                const keyText = pair[0]
                const value = pair[1]
                const tooltip = pair[2]?.tooltip

                if (!tooltip) {
                  return (
                    <Pair size='md' key={i}>
                      <PairKey>
                        {keyText}
                      </PairKey>
                      <PairValue>
                        {value === null || value === undefined
                          ? '--'
                          : value}
                      </PairValue>
                    </Pair>
                  )
                }

                return (
                  <Pair size='md' key={i}>
                    <PairKey>
                      <Tooltip label={tooltip}>
                        <HStack display='inline-flex' justify='center' spacing='0.5'>
                          <span>
                            {keyText}
                            {' '}
                            <RegularCircleExclamationIcon fontSize='2' mt='-0.25' />
                          </span>
                        </HStack>
                      </Tooltip>
                    </PairKey>
                    <PairValue>
                      {value === null || value === undefined
                        ? '--'
                        : value}
                    </PairValue>
                  </Pair>
                )
              })}
            </TabbedCards.CardLowerSegment>
          </Fragment>
        ))}
      </TabbedCards.CardCombo>
    </>
  )
}
