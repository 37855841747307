import { FormControl, FormErrorMessage, FormLabel, Input, ModalOverlay } from '@chakra-ui/react'
import { castToFiniteNumber } from 'libs/string/castToFiniteNumber'
import shoppingLadyWithDogPng from 'presentation/assets/img/3d/shopping-lady-with-dog.png'
import { ModalContentV1, ModalV1 } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { openWalletPaymentDetailsModal } from 'presentation/screens/Billing/Billing.modals'
import { validateAmount } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/formValidators'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { WalletBalanceEntryModalProps } from './WalletBalanceEntryModal.types'

export const WalletBalanceEntryModal: FC<WalletBalanceEntryModalProps> = ({
  isOpen = true,
  onClose,
}) => (
  <ModalV1
    isOpen={isOpen}
    onClose={onClose}
    variant='modal.alert'
    colorScheme='modal.alert.neutral'
    size='modal.alert.xn'
    isCentered
  >
    <ModalOverlay />
    <ModalContentV1>
      <ModalCloseButton onClose={onClose} />
      <WalletBalanceEntryModalContent />
    </ModalContentV1>
  </ModalV1>
)

type Form = { amount: string }

const WalletBalanceEntryModalContent: FC = () => {
  const { register, handleSubmit, formState, watch, setValue } = useForm<Form>()

  const handleNext = handleSubmit(({ amount }) => {
    const casted = castToFiniteNumber(amount)
    if (!casted) throw new Error('Invalid amount')
    openWalletPaymentDetailsModal(casted)
  })

  const value = `$${watch('amount') || ''}`
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const stripped = stripDollar(value)
    setValue('amount', stripped, { shouldValidate: true })
  }

  useEffect(() => {
    register('amount', {
      validate: validateAmount,
    })
  }, [])

  return (
    <form onSubmit={handleNext}>
      <CardImage src={shoppingLadyWithDogPng} />

      <CardPrimaryTitle>
        Add Marketing Funds
      </CardPrimaryTitle>

      <CardPrimaryText>
        How much would you like to add?
      </CardPrimaryText>

      <FormControl mt={mbp({ mobSm: '3', tabSm: '5' })} isInvalid={!!formState.errors.amount}>
        <FormLabel>Amount to Add</FormLabel>
        <Input
          value={value}
          onChange={onChange}
          autoFocus
        />
        {formState.errors.amount?.message
        && <FormErrorMessage>{formState.errors.amount?.message}</FormErrorMessage>}
      </FormControl>

      <CardFooter>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          onClick={goBackModal}
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='neutral'
          isDisabled={!!formState.errors.amount}
          type='submit'
        >
          Checkout
        </CardButton>
      </CardFooter>
    </form>
  )
}

// TODO min of 2 decimals
const stripDollar = (value: string) => value.replace(/\$/g, '')
