import { createIcon } from '@chakra-ui/react'

const SolidCircleInfoIcon = createIcon({
  displayName: 'SolidCircleInfoIcon',
  viewBox: '0 0 32 32',
  path: (
    <path fillRule='evenodd' clipRule='evenodd' d='M16 29C23.1797 29 29 23.1797 29 16C29 8.82032 23.1797 3 16 3C8.82028 3 3 8.82032 3 16C3 23.1797 8.82028 29 16 29ZM15.6717 9.5C14.7761 9.5 14.05 10.2275 14.05 11.125C14.05 12.0225 14.7761 12.75 15.6717 12.75H15.6783C16.5739 12.75 17.3 12.0225 17.3 11.125C17.3 10.2275 16.5739 9.5 15.6783 9.5H15.6717ZM17.3 16.4727C17.3 15.4937 16.5725 14.7 15.675 14.7C14.7775 14.7 14.05 15.4937 14.05 16.4727V20.7273C14.05 21.7063 14.7775 22.5 15.675 22.5C16.5725 22.5 17.3 21.7063 17.3 20.7273V16.4727Z' fill='currentColor' />
  ),
})

export default SolidCircleInfoIcon
