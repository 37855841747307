import { ListBuilderStore } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStore'
import { ListBuilderStoreLayer } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStoreDeps'
import { makeStore } from 'features/ListBuilder/infra/react/ListBuilderStore/makeStore'
import { PropsWithChildren, createContext, useEffect, useRef } from 'react'
import { StoreApi } from 'zustand'

export const Context = createContext<StoreApi<ListBuilderStore> | null>(null)

export const Provider = ({ children, ...deps }: PropsWithChildren<ListBuilderStoreLayer>) => {
  const storeRef = useRef<StoreApi<ListBuilderStore>>()
  if (!storeRef.current) {
    const store = makeStore(deps)
    storeRef.current = store
  }

  useEffect(() => () => {
    storeRef.current?.getState().closeStore()
  }, [])

  return (
    <Context.Provider value={storeRef.current}>
      {children}
    </Context.Provider>
  )
}
