import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type CreateListActionModalState = {
  state: {
    status: 'open'
  } | {
    status: 'closed'
  }
  open: () => void
  close: () => void
}

const useCreateListActionModalStore = create<CreateListActionModalState>()(immer(set => ({
  state: { status: 'closed' },
  open: () => set(draft => {
    draft.state = { status: 'open' }
  }),
  close: () => set(draft => {
    draft.state = { status: 'closed' }
  }),
})))

export const useCreateListActionModal = () =>
  useCreateListActionModalStore(store => store.state)

export const useCreateListModalActions = () =>
  useCreateListActionModalStore(store => ({
    open: store.open,
    close: store.close,
  }))
