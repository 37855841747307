import * as S from '@effect/schema/Schema'
import { POSTSavePropertyListType } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SaveListResolver/POSTSavePropertyListType'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'

export const POSTSavePropertyListSchema = S.Struct({
  id: S.String,
  marketingListId: S.String,
  listType: POSTSavePropertyListType.schema,
  createdAt: S.Date,
  updatedAt: S.Date,
  params: S.Struct({
    builderParams: CriteriaSchema.schema,
  }),
})

export type POSTSavePropertyListSchema = S.Schema.Type<typeof POSTSavePropertyListSchema>
