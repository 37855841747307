import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeFarmIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      d='M12 44V41H50V44H12Z'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M44.5 40.5H29V30H25L29 21L37 17L44.5 21L48.5 30H44.5V40.5Z'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M33 40V30.6667C33.4444 29.7778 34.8667 28 37 28C39.1333 28 40.7778 29.7778 41 30.6667V40'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M33 34H41M33 34V38M33 34L41 38M41 34V38M41 34L33 38M41 38H33'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M19 41V38.0959M19 38.0959C18.3333 38.3725 16.68 38.8427 15.4 38.5108C14.12 38.1789 13.2667 36.9896 13 36.4365C13.4 35.745 14.6 34.5281 16.2 35.1919C17.8 35.8557 18.7333 37.4045 19 38.0959Z'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M19 40V37.0959M19 37.0959C19.6667 37.3725 21.32 37.8427 22.6 37.5108C23.88 37.1789 24.7333 35.9896 25 35.4365C24.6 34.745 23.4 33.5281 21.8 34.1919C20.2 34.8557 19.2667 36.4045 19 37.0959Z'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </chakra.g>
)
