import { Schema as S } from '@effect/schema'
import { DownloadEntrySchema } from 'features/ListBuilder/infra/repo/schema/DownloadEntrySchema'

/**
 * @TODO: Update fields that are optional on the backend
 */
export const GETDownloadStatsSchema = S.Struct({
  downloads: S.Array(DownloadEntrySchema),
  limits: S.Struct({
    limit: S.OptionFromNullishOr(S.Number, null),
    limits: S.OptionFromNullishOr(S.Struct({
      monthly: S.OptionFromNullishOr(S.Number, null),
      weekly: S.OptionFromNullishOr(S.Number, null),
      daily: S.OptionFromNullishOr(S.Number, null),
      each: S.OptionFromNullishOr(S.Number, null),
    }), null),
    downloads: S.OptionFromNullishOr(S.Struct({
      monthly: S.OptionFromNullishOr(S.Number, null),
      weekly: S.OptionFromNullishOr(S.Number, null),
      daily: S.OptionFromNullishOr(S.Number, null),
      each: S.OptionFromNullishOr(S.Number, null),
    }), null),
    available: S.OptionFromNullishOr(S.Number, null),
  }),
})

export type GETDownloadStatsSchema = S.Schema.Type<typeof GETDownloadStatsSchema>
