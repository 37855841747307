import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import CharlesStewartPng from 'presentation/assets/img/testimonials/charles-stewart.png'
import JamilDamjiPng from 'presentation/assets/img/testimonials/jamil-damji.png'
import { Card } from 'presentation/components/molecules/Card'
import { AffiliationLogos } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/AffiliationLogos'
import { EverythingYouNeedHeroText } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/EverytingYouNeedHeroText'
import { PropelioLogo } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/PropelioLogo'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const EverytingYouNeedColumn = () => (
  <Box>
    <PropelioLogo />
    <Box mt={6}>
      <EverythingYouNeedHeroText />
    </Box>
    <Text
      mt={4}
      textStyle={mbp({ dtSm: 'bodyXLFat', dtLg: 'h4' })}
      color='graystrong.200'
    >
      Whether you&rsquo;re just starting your journey or an established business, we&rsquo;re here to help you scale.
    </Text>
    <AffiliationLogos mt={5} />
    <Flex
      flexDirection='column'
      {...mbpg({
        dtSm: {
          mt: 4,
          gap: 3,
        },
        dtLg: {
          mt: 5,
          gap: 5,
        },
      })}
    >
      <TestimonialCard
        name='Jamil Damji'
        message='Great platform for Real Estate Investors'
        avatar={JamilDamjiPng}
      />
      <TestimonialCard
        name='Charles Stewart'
        message='Propelio is everything I need as a real estate investor.
        I evaluate quite a few properties per month and Propelio is spot on.
        It is one of the most valuable tools that I use DAILY!! I would recommend to any real estate investor.'
        avatar={CharlesStewartPng}
      />
    </Flex>
  </Box>
)

type TestimonialCardProps = {
  message: string
  name: string
  avatar?: string
}

const TestimonialCard = (props: TestimonialCardProps) => (
  <Card
    colorScheme='card.bg.1'
    {...mbpg({
      dtSm: {
        size: 'sm-locked',
      },
      dtLg: {
        size: 'md-locked',
        py: 3,
      },
    })}
  >
    <Flex gap={2}>
      <Avatar name={props.name} colorScheme='ondark.2' size='lg' src={props.avatar} />
      <Text
        textStyle={mbp({
          dtSm: 'bodyMFat',
          dtLg: 'bodyXLFat',
        })}
        color='graystrong.600'
      >
        “
        {props.message}
        ”
        <br />
        <Text
          as='span'
          textStyle={mbp({
            dtSm: 'bodyM',
            dtLg: 'bodyXL',
          })}
        >
          —
          {' '}
          {props.name}
        </Text>
      </Text>
    </Flex>
  </Card>
)
