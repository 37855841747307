import { Checkbox, FormControl, FormErrorMessage, FormLabel, Input, ModalOverlay } from '@chakra-ui/react'
import { BuildListState, SaveListState, useExcludeProperties, useListPreview, useStoreActionsDeprecated } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useBuildListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useBuildListState'
import { useSaveListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSaveListState'
import LadyGift from 'presentation/assets/img/3d/lady-gift.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedState } from 'presentation/libs/hooks/useToastFailedState'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type SaveListForm = {
  listName: string
}

export const SaveListModal = () => {
  const { isOpen, close } = useSaveListModal()
  const formApi = useForm<SaveListForm>()
  const excludeApi = useExcludeProperties()
  const previewApi = useListPreview()
  const actionsApi = useStoreActionsDeprecated()
  const navigate = useNavigate()
  const saveState = useSaveListState()
  const buildState = useBuildListState()

  useToastFailedState(saveState, {
    title: 'Failed to save list',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useEffect(() => {
    if (SaveListState.$is('Success')(saveState)) {
      close()
      navigate({ pathname: `/lists/${saveState.list.id}` })
    }
  }, [saveState._tag])

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
    >
      <ModalOverlay />
      <ModalContent>
        <Card
          as='form'
          variant='modal.alert'
          size='modal.alert.xn'
          colorScheme='modal.alert.neutral'
          onSubmit={formApi.handleSubmit(formValues => {
            actionsApi.saveList.execute(formValues.listName)
          })}
        >
          <CardImage src={LadyGift} />
          <ModalCloseButton onClose={close} />
          <CardPrimaryTitle>
            Save this List
          </CardPrimaryTitle>
          <CardBody>
            <FormControl isInvalid={!!formApi.formState.errors.listName}>
              <FormLabel>List Name</FormLabel>
              <Input
                type='text'
                {...formApi.register('listName', {
                  required: 'List name is required',
                })}
              />
              {formApi.formState.errors.listName && (
                <FormErrorMessage>
                  {formApi.formState.errors.listName.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <Checkbox
              mt={5}
              isChecked={excludeApi.value}
              onChange={() => excludeApi.apply(!excludeApi.value)}
            >
              Exclude properties on other lists
            </Checkbox>
          </CardBody>
          <CardFooter>
            <CardButton
              variant='outline'
              colorScheme='neutral'
              size='md'
              onClick={close}
              autoFocus
            >
              Cancel
            </CardButton>
            <CardButton
              variant='solid'
              colorScheme='positive'
              size='md'
              autoFocus
              isLoading={
                BuildListState.$is('Loading')(previewApi)
                || SaveListState.$is('Loading')(saveState)
                || BuildListState.$is('Failed')(buildState)
              }
              loadingText={
                BuildListState.$is('Loading')(previewApi)
                || BuildListState.$is('Failed')(buildState)
                  ? 'Filtering'
                  : 'Saving'
              }
              type='submit'
            >
              Save
            </CardButton>
          </CardFooter>

        </Card>
      </ModalContent>
    </Modal>
  )
}

type SaveListModalState = {
  isOpen: boolean
  close: () => void
  open: () => void
}

export const useSaveListModal = create<SaveListModalState>()(immer(set => ({
  isOpen: false,
  close: () => set(state => { state.isOpen = false }),
  open: () => set(state => { state.isOpen = true }),
})))
