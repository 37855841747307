import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CMASalesEstimateProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMASalesEstimate/CMASalesEstimate.props'
import { shallow } from 'zustand/shallow'

export const CMASalesEstimate = (props: CMASalesEstimateProps) => {
  const estimateStats = useCMAStore(api => (
    api.local.report.status === 'loaded'
    && api.local.report.data.propelioEstimate?.stats
  ) || null
  , shallow)
  const hasNoData = estimateStats === null
  const format = NumberLib.ifElse(Dollar.formatNumberWithCommas)(() => '--')
  const avg = format(estimateStats?.price.avg)
  const min = format(estimateStats?.price.min)
  const max = format(estimateStats?.price.max)
  return (
    <Card
      size='xxs-locked'
      w='full'
      shadow='float'
      {...props}
    >
      <Flex
        justifyContent='space-between'
        alignItems='center'
      >
        <CardPrimaryTitle shouldResetSpacing>
          Sales Estimate
        </CardPrimaryTitle>
        {/* @GENESIS */}
        {/* {!hasNoData && (
          <PricePill trend='up'>
            {???}
          </PricePill>
        )} */}
      </Flex>

      <VStack
        spacing={0}
        align='stretch'
        justifyContent='center'
        h='full'
        pt={3}
      >
        <HStack
          justify='space-between'
          align='end'
          pb={1}
          spacing={0}
        >
          <Text textStyle='bodySHeavy' color='grayweak.900'>
            Low
          </Text>
          <Box
            visibility={hasNoData ? 'hidden' : 'visible'}
          >
            <SubjectPropertyMarker
              size='xs'
              markerType='pin'
            />
          </Box>
          <Text textStyle='bodySHeavy' color='grayweak.900'>
            High
          </Text>
        </HStack>

        <Box
          borderRadius='1'
          h='1'
          bg={hasNoData ? 'grayweak.900' : 'specialsat.500'}
        />

        <HStack justify='space-between' pt='1' align='center'>
          <VStack spacing={0} align='flex-start'>
            <Text textStyle='tagL' color='graystrong.400'>
              {min}
            </Text>
            <EstimateText />
          </VStack>
          <Text textStyle='bodySHeavy' color='graystrong.400'>
            {avg}
          </Text>
          <VStack spacing={0} align='flex-end'>
            <Text textStyle='tagL' color='graystrong.400'>
              {max}
            </Text>
            <EstimateText />
          </VStack>
        </HStack>
      </VStack>
    </Card>
  )
}

const EstimateText = () => (
  <Text
    textStyle='tagM'
    color='grayweak.900'
  >
    Estimate
  </Text>
)

/** @GENESIS */
// const PricePill = (props: PropsWithChildren<{
//   trend: 'up' | 'down'
// }>) => (
//   <Flex
//     alignItems='center'
//     borderRadius='100px'
//     bgColor={pipe(
//       Match.value(props.trend),
//       Match.when('up', () => 'positive.500'),
//       Match.when('down', () => 'negative.500'),
//       Match.exhaustive,
//     )}
//     gap={0.25}
//     px={1}
//     h='min-content'
//   >
//     {pipe(
//       Match.value(props.trend),
//       Match.when('up', () => <ArrowUpIcon boxSize={1} color='ondark.1'/>),
//       Match.when('down', () => <ArrowDownIcon boxSize={1} color='ondark.1'/>),
//       Match.exhaustive,
//     )}

//     <Text textStyle='bodySFat' color='ondark.1'>
//       {props.children}
//     </Text>
//   </Flex>
// )
