import { NumberLib } from 'libs/Number'
import { PanicError } from 'libs/errors/PanicError'

export const calcPageButtonsToRender = ({
  totalPages,
  currentPage,
  maxButtons,
}: {
  totalPages: number
  currentPage: number
  maxButtons: number
}): (number | null)[] => {
  if (maxButtons < 5) throw new PanicError('maxButtons less than 5 is not supported.')

  if (totalPages <= maxButtons)
    return Array.from({ length: totalPages }, (_, i) => i + 1)

  // Example 1, if we are to show [1, 2, 3, 4, 10], the current page can only be 1-3.
  // Example 2, if we are to show [1, 2, 3, 4, 5, 10], the current page can only be 1-4.

  // For example 1, we are reserving for 4, and 10.
  // For example 2, we are reserving for 5, and 10.
  const RESERVED_BUTTONS = 2

  const maxDigitOfFirstButtons = maxButtons - RESERVED_BUTTONS
  if (currentPage <= maxDigitOfFirstButtons) {
    return Array.from({ length: maxDigitOfFirstButtons }, (_, i): number => i + 1)
      .concat([maxDigitOfFirstButtons + 1, totalPages])
  }

  // Example 1, if we are to show [1, 7, 8, 9, 10], the current page can only be 8-10.
  // Example 2, if we are to show [1, 6, 7, 8, 9, 10], the current page can only be 7-10.

  // For example 1, we reserve for 1, and 7, and that leaves us lastButtonsCount of 3 for 8, 9, 10
  // For example 2, we reserve for 1, and 6, and that leaves us lastButtonsCount of 4 for 7, 8, 9, 10
  const lastButtonsCount = maxButtons - RESERVED_BUTTONS

  // For example 1, with 10 buttons, 3 buttons is reserved (1, 7), and 3 buttons are left (8, 9, 10)
  // the minDigitOfLastButtons is 8.
  // For example 2, with 10 buttons, 4 buttons is reserved (1, 6), and 4 buttons are left (7, 8, 9, 10)
  // the minDigitOfLastButtons is 7.
  const minDigitOfLastButtons = (totalPages - lastButtonsCount) + 1

  if (currentPage >= minDigitOfLastButtons) {
    return [1, minDigitOfLastButtons - 1]
      .concat(Array.from({ length: lastButtonsCount }, (_, i): number => minDigitOfLastButtons + i))
  }

  // At this point we have [1, ???, totalPages].
  // So 2 spots is already taken. The first page, and the last page.
  const middleButtonsCount = maxButtons - 2

  // Let's pad the right and left of the current page until we meet middleButtonsCount
  const middleButtons = [currentPage]

  while (middleButtons.length < middleButtonsCount) {
    const shouldPadRight = NumberLib.isOdd(middleButtons.length)

    if (shouldPadRight) {
      const lastPage = middleButtons[middleButtons.length - 1]
      middleButtons.push(lastPage + 1)
    } else {
      const firstPage = middleButtons[0]
      middleButtons.unshift(firstPage - 1)
    }
  }

  return [1, ...middleButtons, totalPages]
}
