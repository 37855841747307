import { createIcon } from '@chakra-ui/react'

export const BellIcon = createIcon({
  displayName: 'BellIcon',
  viewBox: '0 0 25 25',
  path: (
    <>
      <path d='M18.5 8.78418C18.5 7.19288 17.8679 5.66676 16.7426 4.54154C15.6174 3.41632 14.0913 2.78418 12.5 2.78418C10.9087 2.78418 9.38258 3.41632 8.25736 4.54154C7.13214 5.66676 6.5 7.19288 6.5 8.78418C6.5 15.7842 3.5 17.7842 3.5 17.7842H21.5C21.5 17.7842 18.5 15.7842 18.5 8.78418Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.2295 21.7842C14.0537 22.0873 13.8014 22.3388 13.4978 22.5137C13.1941 22.6886 12.8499 22.7806 12.4995 22.7806C12.1492 22.7806 11.8049 22.6886 11.5013 22.5137C11.1977 22.3388 10.9453 22.0873 10.7695 21.7842' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
