import { Box, Image, Text, VStack } from '@chakra-ui/react'
import runningDogPng from 'presentation/assets/img/3d/running-dog.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TabContainer } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/TabContainer'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const NoLocationView = () => (
  <TabContainer borderRadius='3'>
    <VStack
      bg='neutral.100'
      h='full'
      justifyContent='center'
      alignItems='center'
      px={3}
      spacing='1'
    >
      <Box>
        <Image
          src={runningDogPng}
          boxSize={mbp({ mob: '104px', tabSm: '120px', dtLg: '160px' })}
          display={mbp({ mobSm: 'none', mob: 'block' })}
          mr='auto'
          ml='auto'
        />

        <Text
          textStyle={mbp({ mobSm: 'bodyXLFat', mob: 'h4', tabSm: 'h3', dtLg: 'h2' })}
          color='graystrong.500'
          textAlign='center'
        >
          <Breakpoint
            mobSm='No photos from MLS'
            mob={<>Location unavailable</>}
          />
        </Text>

        <Text
          textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })}
          color='graystrong.200'
          textAlign='center'
        >
          Apologies, we can&rsquo;t find the coordinates for this property
        </Text>
      </Box>
    </VStack>
  </TabContainer>
)
