import { Array, Data, Effect, HashSet, Option, Order, Runtime, pipe } from 'effect'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import { ListBuilderStoreDeps } from 'features/ListBuilder/infra/react/ListBuilderStore/ListBuilderStoreDeps'
import SelectableListMachine from 'features/ListBuilder/views/machines/SelectableListMachine'
import { toast } from 'presentation/components/Toast'
import { ActorRefFrom, assertEvent, assign, fromPromise, raise, setup } from 'xstate'

type ParcelListsManagerEvents =
  | { type: 'lists.save' }
  | { type: 'lists.create', listName: string }
  | { type: 'lists.load' }
  | { type: 'lists.toggle', listId: MarketingListId }
  | { type: 'lists.removeFromAll' }

type ParcelListsManagerContext = {
  parcelId: ParcelId
  selectableLists: SelectableListMachine[] //
  parcelLists: MarketingList[]
  hasLists: boolean
}

const make = (storeRuntime: Runtime.Runtime<ListBuilderStoreDeps>) => setup({
  types: {
    events: {} as ParcelListsManagerEvents,
    context: {} as ParcelListsManagerContext,
    input: {} as { parcelId: ParcelId },
  },
  actors: {
    loadLists: fromPromise(
      async ({ input }: { input: { parcelId: ParcelId } }) => {
        const listsTask = MarketingListRepo.pipe(
          Effect.flatMap(repo => repo.getNextByCursor(Option.none())),
        )

        const parcelListTask = MarketingListRepo.pipe(
          Effect.flatMap(repo => repo.getNextByParcel({
            parcelId: input.parcelId,
            cursor: Option.none(),
          })),
        )

        return await Effect.all([
          listsTask,
          parcelListTask,
        ], { concurrency: 'unbounded' }).pipe(
          Effect.map(([lists, parcelLists]) => ({ lists, parcelLists })),
          Runtime.runPromise(storeRuntime),
        )
      }),
    removeFromAllLists: fromPromise(async ({ input }: {
      input: {
        parcelId: ParcelId
        parcelLists: MarketingList[]
      }
    }) => {
      const toRemoveListIds = Array.map(input.parcelLists, v => v.id)

      return await MarketingListRepo.pipe(
        Effect.flatMap(repo => repo.removeParcelFromLists({
          parcelId: input.parcelId,
          listIds: toRemoveListIds,
        })),
        Runtime.runPromise(storeRuntime),
      )
    }),
    createList: fromPromise(
      async ({ input }: { input: { name: string, parcelId: ParcelId } }) => MarketingListRepo.pipe(
        Effect.flatMap(repo => repo.createList({ name: input.name }).pipe(
          Effect.andThen(marketingListId => repo.addParcel({
            parcelId: input.parcelId,
            listId: marketingListId,
          }).pipe(
            Effect.andThen({
              parcelId: input.parcelId,
              listId: marketingListId,
            }),
          )),
        )),
        Runtime.runPromise(storeRuntime),
      ),
    ),
    saveParcelToLists: fromPromise(
      async ({ input }: { input: {
        parcelId: ParcelId
        selectedLists: MarketingList[]
        parcelLists: MarketingList[]
      } }) => {
        const parcelLists = pipe(
          HashSet.fromIterable(input.parcelLists),
          HashSet.map(v => v.id),
        )

        const selectedList = pipe(
          HashSet.fromIterable(input.selectedLists),
          HashSet.map(v => v.id),
        )

        const toRemoveListIds = parcelLists.pipe(
          HashSet.filter(v => !selectedList.pipe(HashSet.has(v))),
          Array.fromIterable,
        )

        const toAddListIds = selectedList.pipe(
          HashSet.difference(parcelLists),
          Array.fromIterable,
        )

        return await MarketingListRepo.pipe(
          Effect.flatMap(repo => {
            const addTask = repo.addParcelToLists({
              parcelId: input.parcelId,
              listIds: toAddListIds,
            })
            const removeTask = repo.removeParcelFromLists({
              parcelId: input.parcelId,
              listIds: toRemoveListIds,
            })

            return Effect.all([addTask, removeTask], { concurrency: 'unbounded' })
          }),
          Runtime.runPromise(storeRuntime),
        )
      }),
  },
  actions: {
    updateLists: assign(({ spawn }, params: {
      lists: MarketingListRepo.GetByCursorResult
      parcelLists: MarketingListRepo.GetByCursorResult
    }) => {
      const lists = params.lists.lists
      const parcelLists = params.parcelLists.lists

      const newLists = pipe(
        lists,
        Array.map(list => {
          const isSelected = pipe(
            parcelLists,
            Array.findFirst(l => l.id === list.id),
            Option.match({
              onNone: () => false,
              onSome: _ => true,
            }),
          )
          return Data.struct({ isSelected, list })
        }),
        /** start: Move all selected lists at the start of the list */
        Array.sortWith(v => v.isSelected, Order.boolean),
        Array.reverse,
        /** end: Move all selected lists at the start of the list */
        Array.map(v => spawn(
          SelectableListMachine.make(v.isSelected), {
            input: { list: v?.list },
          },
        )),
      )

      return {
        hasLists: lists.length > 0,
        parcelLists: params.parcelLists.lists,
        selectableLists: newLists,
      }
    }),
  },
}).createMachine({
  id: 'ParcelListsManagerMachine',
  initial: 'Idle',
  context: ({ input }) => ({
    parcelId: input.parcelId,
    selectableLists: [],
    parcelLists: [],
    hasLists: false,
  }),
  states: {
    Idle: {
      on: {
        'lists.load': {
          target: 'Loading',
        },
      },
    },
    Loading: {
      tags: ['loading'],
      invoke: {
        id: 'LoadListsActor',
        src: 'loadLists',
        input: ({ context }) => ({ parcelId: context.parcelId }),
        onDone: {
          target: 'Loaded',
          actions: {
            type: 'updateLists',
            params: result => ({
              lists: result.event.output.lists,
              parcelLists: result.event.output.parcelLists,
            }),
          },
        },
      },
    },
    Loaded: {
      initial: 'Idle',
      on: {
        'lists.load': {
          target: 'Loading',
        },
      },
      states: {
        Idle: {
          on: {
            'lists.save': 'Saving',
            'lists.create': 'Creating',
            'lists.removeFromAll': 'Removing',
          },
        },
        Removing: {
          tags: ['loading'],
          id: 'DeleteFromAllListsActor',
          invoke: {
            src: 'removeFromAllLists',
            input: ({ context }) => ({
              parcelId: context.parcelId,
              parcelLists: context.parcelLists,
            }),
            onDone: {
              actions: [
                () => {
                  toast.success({
                    title: 'That was a success!',
                    message: 'This property has been successfully removed from all lists',
                  })
                },
                raise({ type: 'lists.load' }),
              ],
            },
            onError: {
              actions: [
                () => {
                  toast.error({
                    title: 'Failed to Save Property to a List',
                    message: 'Please try again or contact support.',
                  })
                },
                raise({ type: 'lists.load' }),
              ],
            },
          },
        },
        Saving: {
          tags: ['loading'],
          id: 'SaveListActor',
          invoke: {
            src: 'saveParcelToLists',
            input: ({ context }) => ({
              parcelId: context.parcelId,
              parcelLists: context.parcelLists,
              selectedLists: pipe(
                context.selectableLists,
                Array.filter(actorRef => actorRef.getSnapshot().value !== 'Unchecked'),
                Array.map(actorRef => actorRef.getSnapshot().context.list),
              ),
            }
            ),
            onDone: {
              actions: [
                () => {
                  toast.success({
                    title: 'That was a success!',
                    message: 'This property has been successfully saved.',
                  })
                },
                raise({ type: 'lists.load' }),
              ],
            },
            onError: {
              actions: [
                () => {
                  toast.error({
                    title: 'Failed to Save Property to a List',
                    message: 'Please try again or contact support.',
                  })
                },
              ],
            },
          },
        },
        Saved: {

        },
        Creating: {
          tags: ['loading'],
          invoke: {
            id: 'CreateListActor',
            src: 'createList',
            input: ({ event, context }) => {
              assertEvent(event, 'lists.create')
              return {
                name: event.listName,
                parcelId: context.parcelId,
              }
            },
            onDone: {
              actions: [
                () => {
                  toast.success({
                    title: 'That was a success!',
                    message: 'This property has been successfully saved.',
                  })
                },
                raise({ type: 'lists.load' }),
              ],
            },
            onError: {
              actions: [
                () => {
                  toast.error({
                    title: 'Failed to Save Property to a List',
                    message: 'Please try again or contact support',
                  })
                },
                raise({ type: 'lists.load' }),
              ],
            },
          },
        },
      },
    },
  },
})

type ParcelListsManagerMachine = ActorRefFrom<ReturnType<typeof make>>

const ParcelListsManagerMachine = {
  make,
}

export default ParcelListsManagerMachine
