import { Table, TableActionButton, TableActions, TableGroup, TableLoadingIndicator, TableScroller, Tbody, Th, Thead, Tr } from 'presentation/components/molecules/Table'
import { AmountCellSpacer } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/AmountCellSpacer'
import { FailedNextPaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/FailedNextPaymentRow'
import { PaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/PaymentRow/PaymentRow'
import { UpcomingPaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/UpcomingPaymentRow'
import { usePaymentsTable } from 'presentation/screens/Billing/components/BillingHistorySection/usePaymentsTable'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

/** @TODO Commented out sticky code for now, it's broken, lets try to fix later */
export const PaymentsTable: FC<{
  stickyOffset: number
}> = () => {
  const {
    isReady,
    invoices,
    activeSubscription,
    canFetchMore,
    fetchMore,
    isFetchingMore,
  } = usePaymentsTable()
  // const { mfv } = useMobFirstBreakpointValue()
  // const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  // const theadProps: TableHeadProps = mfv({
  //   mobSm: {
  //     top: px(headerHeight + stickyOffset),
  //     position: 'sticky',
  //   },
  //   tabSm: {},
  // }) ?? {}

  return (
    <TableGroup>
      <TableScroller>
        <Table minW={mbp({ mobSm: 'auto', tabSm: '800px' })}>
          {/* <Thead {...theadProps}> */}
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>User</Th>

              <Th w={mbp({ mobSm: 'full', tabSm: 'auto' })} columnType='numeric'>
                <AmountCellSpacer>
                  Amount
                </AmountCellSpacer>
              </Th>

              <Th w='full' isBigScreenOnly>Description</Th>
              <Th>Status</Th>
              <Th isBigScreenOnly>PDF</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isReady && activeSubscription && (
              <FailedNextPaymentRow subscription={activeSubscription} />
            )}

            {isReady && activeSubscription && (
              <UpcomingPaymentRow subscription={activeSubscription} />
            )}

            {invoices.map(transaction => transaction
              ? (
                <PaymentRow transaction={transaction} key={transaction.id} />
              )
              : null)}
          </Tbody>
        </Table>
      </TableScroller>

      {!isReady && (
        <TableLoadingIndicator />
      )}

      {canFetchMore && (
        <TableActions>
          <TableActionButton
            onClick={fetchMore}
            isLoading={isFetchingMore}
            loadingText='Loading More'
            spinnerPlacement='end'
          >
            Load More
          </TableActionButton>
        </TableActions>
      )}
    </TableGroup>
  )
}
