import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeLotLandIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      d='M14 44V41H46V44H14Z'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M38 27V29.5M38 41V36.5M38 36.5L34 33M38 36.5L42 33M38 36.5V33M38 33L35.5 31M38 33L40.5 31M38 33V29.5M38 29.5L37 28.5M38 29.5L39 28.5'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M22 41V27'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M25.1111 23.8576C27.2589 23.8576 29 25.4294 29 27.3684C29 29.3074 27.2589 30.8793 25.1111 30.8793M25.5 30.8793C25.1111 33.2198 23.933 34 22 34C20.067 34 18.5 31.124 18.5 28.5387M18.8889 20.7368C16.7411 20.7368 15 22.4833 15 24.6378C15 26.7922 16.7411 28.5387 18.8889 28.5387M18.5 21.1269C18.5 17.6798 20.0453 16 22.4079 16C24.7704 16 27.1595 20.7368 25.8889 23.8576'
      stroke={props.color}
    />
  </chakra.g>
)
