import { Box, Grid, HStack, Text, TextProps, VStack } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { EquityType } from 'features/valueObjects/EquityType'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { EquityOnlyPropertyMarker } from 'presentation/components/PropertyMarker/components/EquityOnlyPropertyMarker/EquityOnlyPropertyMarker'
import { Card } from 'presentation/components/molecules/Card'
import { EMPTY_MARKER_INFO, getMarkerInfoFromProperty } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsScreen.helpers'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { isNonNull } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

export const MarkerInfoSection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return EMPTY_MARKER_INFO

    return getMarkerInfoFromProperty(property)
  })

  const {
    classification,
    equityType,
    isVacant,
    isForeclosure,
    isSenior,
  } = data

  const classificationString = classification === null
    ? 'UNKNOWN'
    : OwnerClassification.toString(classification)

  const equityTypeString = !isNonNullable(equityType)
    ? ''
    : EquityType.toString(equityType)

  const isVacantButOwnerOccupiedAndNotSenior = classification === 'OWNER_OCCUPIED'
    && isVacant
    && !isSenior

  return (
    <Card
      size='xs-locked'
      borderRadius={2}
      boxShadow='primary.s'
      {...mbpg({
        mobSm: { px: 2, py: 3 },
        mob: { p: 3 },
        tabSm: { p: 1 },
        dtSm: {
          py: 1,
          px: 2,
        },
      })}
    >
      <HStack
        spacing={mbp({ mobSm: 0, mob: 1, tabSm: 0, dtSm: 2 })}
        sx={{ '& > *': { w: '7' } }}
        justify='center'
        align='flex-start'
      >
        {equityType && (
          <VStack spacing={0.25}>
            <EquityOnlyPropertyMarker
              size='xs'
              markerType='tag'
              equityType={equityType}
            />
            <Label
              wordBreak='break-word'
            >
              {equityTypeString}
            </Label>
          </VStack>
        )}

        {!isVacantButOwnerOccupiedAndNotSenior && (
          <VStack spacing={0.25}>
            <PropertyMarker
              size='xs'
              // only show classification
              classification={classification}
              // include senior still, we'll mention that
              isSenior={isSenior}

              isVacant={false}
              isForeclosure={false}
              equityType={null}
              markerType='tag'
              shouldHideSuperscript
            />
            <Label
              wordBreak='break-word'
            >
              {[
                isVacant && classification === 'OWNER_OCCUPIED'
                  ? null
                  : classificationString,
                classification === 'OWNER_OCCUPIED' && isSenior
                  ? 'Senior'
                  : null,
              ].filter(isNonNull).join(', ')}
            </Label>
          </VStack>
        )}

        {isForeclosure && (
          <VStack spacing={0.25}>
            <PropertyMarker
              size='xs'
              // only show foreclosure
              isForeclosure={isForeclosure}

              classification={null}
              equityType={null}
              isSenior={false}
              isVacant={false}
              markerType='tag'
            />
            <Label>
              Prefore-
              <br />
              closure
            </Label>
          </VStack>
        )}

        {isVacant && (
          <Grid
            gridTemplateColumns='1fr'
            gridTemplateRows='min-content 1fr'
            alignItems='center'
            justifyContent='center'
            rowGap={0.25}
          >
            <Box mx='auto'>
              <PropertyMarker
                size='xs'
                // only show vacant
                isVacant={isVacant}

                classification={null}
                equityType={null}
                isSenior={false}
                isForeclosure={false}
                markerType='tag'
              />
            </Box>
            <Label>Vacant</Label>
          </Grid>
        )}
      </HStack>
    </Card>
  )
}

const Label = ({ children, ...props }: TextProps) => (
  <Text
    textStyle='tagM'
    color='graystrong.400'
    align='center'
    {...props}
  >
    {children}
  </Text>
)
