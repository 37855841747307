import { Box, Flex, Grid } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { HeaderDesktop } from 'presentation/layouts/Layout/components/HeaderDesktop/HeaderDesktop'
import { HeaderMobile } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile'
import { SIDEBAR_DESKTOP_W, SidebarDesktop } from 'presentation/layouts/Layout/components/SidebarDesktop/SidebarDesktop'
import { useHeaderElementHeightTracker, useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { NoticeCarousel } from 'presentation/main/NoticeCarousel'
import { px } from 'presentation/utils/px'
import { FC, PropsWithChildren } from 'react'
import { Outlet, useMatch, useSearchParams } from 'react-router-dom'

const MAX_W = 1440
export const HEADER_AREA_ID = 'header-area'
export const LAYOUT_CONTENT_MAX_W = MAX_W - SIDEBAR_DESKTOP_W
export const MOBILE_SCROLLABLE_LAYOUT_ID = 'mobile-scrollable-layout'
const NOTICE_HEIGHT_FALLBACK = 0

export const Layout: FC<PropsWithChildren> = ({ children }) => (
  <Breakpoint
    mobSm={<LayoutMob>{children}</LayoutMob>}
    dtSm={<LayoutDt>{children}</LayoutDt>}
  />
)

const LayoutMob: FC<PropsWithChildren> = ({ children }) => {
  const { shouldShowHeaderMob } = useShouldShowHeaderMob()
  const headerMainMobTracker = useHeaderElementHeightTracker('headerMainMob')
  const headerNoticeTracker = useHeaderElementHeightTracker('headerNotice')
  return (
    <Flex bg='graycool.100' direction='column' minH='app-height' minW='320px'>
      <Box
        gridArea='headerMain'
        alignSelf='stretch'
        position='sticky'
        top='0'
        /** Must overpower sticky zIndices of the content */
        zIndex='sticky-5'
      >
        <Box ref={headerMainMobTracker.ref}>
          {shouldShowHeaderMob ? <HeaderMobile /> : <HeaderMobileSpacer />}
        </Box>
        <Box ref={headerNoticeTracker.ref}>
          <NoticeCarousel />
        </Box>
      </Box>

      <Flex flex='1 1 auto' direction='column' minH={0} id={MOBILE_SCROLLABLE_LAYOUT_ID}>
        {children || <Outlet />}
      </Flex>
    </Flex>

  )
}

const LayoutDt: FC<PropsWithChildren> = ({ children }) => {
  const title = useLayoutStore(state => state.title)
  const isSubscribed = LayoutViewModel.useReadySelector(state => !!state.subscription)
  const isPropDetailsScreen = !!useMatch('/search/:leadId/*')
  const headerMainDtTracker = useHeaderElementHeightTracker('headerMainDt')
  const headerNoticeTracker = useHeaderElementHeightTracker('headerNotice')

  return (
    <Grid
      minH='app-height'
      gridTemplate={`
            "headerNotice headerNotice" min-content
            "sidebar      headerMain"   min-content
            "sidebar      content"      1fr / min-content 1fr
          `}
    >
      <Box
        ref={headerNoticeTracker.ref}
        gridArea='headerNotice'
        alignSelf='start'
        position='sticky'
        top='0'
        /** Must overpower sticky zIndices of the content */
        zIndex='sticky-5'
      >
        <NoticeCarousel />
      </Box>

      {isSubscribed && (
        <Box
          gridArea='sidebar'
          alignSelf='start'
          position='sticky'
          height={`calc(var(--app-height) - ${px(headerNoticeTracker.height)})`}
          top={px(headerNoticeTracker.height || NOTICE_HEIGHT_FALLBACK)}
          bottom='0'
          left='0'
          /** Must overpower sticky zIndices of the content */
          zIndex='sticky-5'
        >
          <SidebarDesktop h='full' />
        </Box>
      )}

      <Box
        ref={headerMainDtTracker.ref}
        gridArea='headerMain'
        alignSelf='start'
        position='sticky'
        top={px(headerNoticeTracker.height)}
        /** Must overpower sticky zIndices of the content */
        zIndex='sticky-5'
        bg='graycool.100'
      >
        {/** Make comp screen compact. */}
        {isPropDetailsScreen
          ? null
          : <HeaderDesktop pageTitle={title} />}
      </Box>

      <Flex
        flex='1 1 0'
        direction='column'
        minH={0}
        gridArea='content'
      >
        {children || <Outlet />}
      </Flex>
    </Grid>
  )
}

type UseShouldShowHeaderMobResult = {
  shouldShowHeaderMob: boolean
}

const useShouldShowHeaderMob = (): UseShouldShowHeaderMobResult => {
  const [searchParams] = useSearchParams()
  const shouldShowHeaderMob = searchParams.get('layoutMode') !== 'iframe'
  return { shouldShowHeaderMob }
}

const HeaderMobileSpacer: FC = () => <Box h={1} />
