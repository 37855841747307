import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { PropertyMarkerProps } from 'presentation/components/PropertyMarker/PropertyMarker.props'
import { Marker } from 'react-map-gl'

export type PropertyMapMarkerProps = {
  latitude: number
  longitude: number
} & Omit<PropertyMarkerProps, 'markerType'>

export const PropertyMapMarker = ({
  classification,
  size,
  equityType,
  isSenior,
  isVacant,
  isForeclosure,
  shouldHideSuperscript,
  isHighlighted,
  ...props
}: PropertyMapMarkerProps) => (
  <Marker
    latitude={props.latitude}
    longitude={props.longitude}
    style={{
      zIndex: isHighlighted
        ? MAP_ELEMENT__Z_INDICES.SELECTED_MARKER
        : MAP_ELEMENT__Z_INDICES.MARKER,
    }}
    anchor='bottom'
  >
    <PropertyMarker
      classification={classification}
      size={size}
      equityType={equityType}
      isSenior={isSenior}
      isVacant={isVacant}
      isForeclosure={isForeclosure}
      shouldHideSuperscript={shouldHideSuperscript}
      markerType='pin'
    />
  </Marker>
)
