import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'

/**
 * @NOTE: This currently does not support vertical orientation
 */

const STEPPER_PARTS = {
  stepper: 'stepper',
  step: 'step',
  title: 'title',
  description: 'description',
  indicator: 'indicator',
  separator: 'separator',
  icon: 'icon',
  number: 'number',
}

const {
  definePartsStyle,
  defineMultiStyleConfig,
} = createMultiStyleConfigHelpers(Object.values(STEPPER_PARTS))

const baseStyle = ({ colorScheme }: StyleFunctionProps) => definePartsStyle({
  stepper: {
    gap: 0,
    justifyContent: 'normal',
  },
  step: {
    gap: 0,
    flex: 0,
  },
  indicator: {
    '&[data-status="incomplete"]': {
      borderColor: 'grayweak.400',
    },
    /**
     * @NOTE If the <StepStatus /> has a null active prop, add a custom child.
     *
     * For observation: Encountered a weird issue where this custom child is
     * not centered to its parent depending on the width of the separator.
     */
    '&:empty[data-status="active"]::after': {
      content: '""',
      w: '100%',
      h: '100%',
      backgroundColor: `${colorScheme}.500`,
      borderRadius: '50%',
    },
  },
  separator: {
    '&[data-orientation=horizontal]': {
      margin: 0,
    },
    '&[data-status="active"]': {
      backgroundColor: 'grayweak.400',
    },
  },
})

const sizes = {
  xs: definePartsStyle({
    indicator: {
      'w': 2,
      'h': 2,
      '&[data-status="active"]': {
        borderWidth: 0.125,
      },
      '&:empty[data-status="active"]': {
        p: 0.25,
      },
    },
    icon: {
      boxSize: 2,
    },
  }),
}

const variants = {
  compact: definePartsStyle({
    separator: {
      '&[data-orientation=horizontal]': {
        w: 6,
      },
    },
  }),
  comfortable: definePartsStyle({
    separator: {
      '&[data-orientation=horizontal]': {
        w: '72px',
      },
    },
  }),
  full: definePartsStyle({
    stepper: {
      justifyContent: 'space-between',
    },
    step: {
      flex: 1,
    },
    separator: {
      '&[data-orientation=horizontal]': {
        w: '100%',
      },
    },
  }),
}

export const stepperTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'xs',
    variant: 'comfortable',
    colorScheme: 'neutral',
  },
})
