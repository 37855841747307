import { Checkbox, CheckboxGroup, Flex, Grid, Text } from '@chakra-ui/react'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { ThumbsDownIcon } from 'presentation/components/ThumbButtons'
import { CMAEntriesShowValue, setShowValues, useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { uniq } from 'remeda'

export const ShowFilterCard = () => {
  const { showValues } = useEntriesToolbarMenu()

  const handleOnSelectionChange = (value: (string | number)[]) => {
    if (typeof value === 'number') return

    const initial = value as CMAEntriesShowValue[]

    const filteredSoldValues: CMAEntriesShowValue[] = initial.includes('SOLD')
      ? [...initial, 'LEASED']
      : initial.filter(v => v !== 'LEASED')

    const filteredForSaleValues: CMAEntriesShowValue[] = filteredSoldValues.includes('FOR_SALE')
      ? [...filteredSoldValues, 'SALE_OR_LEASE', 'FOR_SALE', 'FOR_LEASE']
      : filteredSoldValues.filter(v => v !== 'SALE_OR_LEASE' && v !== 'FOR_SALE' && v !== 'FOR_LEASE')

    const filteredOffMarketValues: CMAEntriesShowValue[] = filteredForSaleValues.includes('OFF_MARKET')
      ? [...filteredForSaleValues, 'CANCELED', 'EXPIRED', 'WITHDRAWN']
      : filteredForSaleValues.filter(v => v !== 'CANCELED' && v !== 'EXPIRED' && v !== 'WITHDRAWN')

    setShowValues(uniq(filteredOffMarketValues))
  }

  return (
    <FilterCard title='Show'>
      <CheckboxGroup defaultValue={showValues} onChange={handleOnSelectionChange}>
        <Grid
          gridTemplateColumns={mbp({
            mobSm: 'repeat(1, 1fr)',
            mob: 'repeat(2, 1fr)',
          })}
          rowGap={2}
          columnGap={2}
          pb={1}
          px={1.5}
        >
          <Flex gap={1}>
            <Checkbox
              value='SOLD'
            />
            <MLSStatusTag status='SOLD'>
              Sold / Leased
            </MLSStatusTag>
          </Flex>
          <Flex gap={1}>
            <Checkbox value='PENDING' />
            <MLSStatusTag status='PENDING' />
          </Flex>
          <Flex gap={1}>
            <Checkbox value='FOR_SALE' />
            <MLSStatusTag status='FOR_SALE'>
              For Sale / Lease
            </MLSStatusTag>
          </Flex>
          <Flex gap={1}>
            <Checkbox value='OFF_MARKET' />
            <MLSStatusTag status='OFF_MARKET' />
          </Flex>
          <Flex gap={1}>
            <Checkbox value='THUMBS_DOWN' />
            <Text textStyle='bodyMFat' color='inherit'>
              Thumbs Down
            </Text>
            <ThumbsDownIcon value='down' boxSize={3} />
          </Flex>
        </Grid>
      </CheckboxGroup>
    </FilterCard>
  )
}
