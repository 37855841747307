import { PartialRange } from 'features/valueObjects/Range'
import { Year } from 'features/valueObjects/Year'
import { YearBuiltFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/YearBuiltFilterCard/YearBuiltFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'

export const YearBuiltFilterCardDumb = (
  props: YearBuiltFilterCardDumbProps,
) => (
  <FilterCard
    title='Year built'
    hasBadge={
      PartialRange.checkHasValueOptional(props.value)
    }
  >
    <PartialRangeInputV2
      initialValue={props.value}
      onDebouncedValidChange={newRange => {
        props.onChange(newRange)
      }}
      subjectValue={props.subjectValue?.toString()}
      validateEither={validateYear}
    />
    {props.filterErrorMsg && (
      <CompsFilterCardError error={props.filterErrorMsg} />
    )}
  </FilterCard>
)

const validateYear = (year: number | null) => {
  if (year === null) return true
  return Year.isValidNumber(year)
}
