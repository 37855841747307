import { Data } from 'effect'
import ProductFeatureInterval from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureInterval'
import ProductFeatureName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureName'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'

/**
 * A feature spec is a specification for a feature that is used to describe
 * a feature in a product compared by a subscription tier.
 */

/** Indicates that a feature is unlimited in a specific subscription tier */
export type FeatureUnlimitedSpec = {
  readonly _tag: 'FeatureUnlimitedSpec'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
}

export const FeatureUnlimitedSpec = {
  make: Data.tagged<FeatureUnlimitedSpec>('FeatureUnlimitedSpec'),
}

/**
 * Simply indicates that the feature exist and if there
 * is nothing in the collection of features indicates that it
 * is not available. Other tags indicates availability with
 * additional information
 */
export type FeatureAvailableSpec = {
  readonly _tag: 'FeatureAvailableSpec'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
}

export const FeatureAvailableSpec = {
  make: Data.tagged<FeatureAvailableSpec>('FeatureAvailableSpec'),
}

/**
 * Specification for a feature with a hard usage limit, tied to a subscription tier.
 * Defines the maximum allowed usage without overage charges.
 */
export type FeatureLimitedSpec = {
  readonly _tag: 'FeatureLimitedSpec'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  // Maximum allowed usage without overage charges
  readonly limit: number
  // Period over which the limit applies
  readonly interval: ProductFeatureInterval
}

export const FeatureLimitedSpec = {
  make: Data.tagged<FeatureLimitedSpec>('FeatureLimitedSpec'),
}

/**
 * Specification for usage-based pricing of a feature, where the price per unit varies by subscription tier.
 * Defines the cost per unit of usage and the billing interval.
 * Used on pricing pages to inform users about the cost structure.
 */
export type FeatureUsagePricingSpec = {
  readonly _tag: 'FeatureUsagePricingSpec'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  readonly price: number
  readonly unit: string
  readonly unitDescription: string
  readonly interval: ProductFeatureInterval
}

export const FeatureUsagePricingSpec = {
  make: Data.tagged<FeatureUsagePricingSpec>('FeatureUsagePricingSpec'),
}

/**
 * Specification for a feature with a free quota and overage charges.
 * Used to define the terms of the feature, such as the free usage limit and cost for exceeding it.
 * Typically displayed on pricing pages to inform users about the plan.
 */
export type FeatureQuotaSpec = {
  readonly _tag: 'FeatureQuotaSpec'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  readonly quotaLimit: number
  readonly replenishInterval: ProductFeatureInterval
  readonly overageCost: number
}

export const FeatureQuotaSpec = {
  make: Data.tagged<FeatureQuotaSpec>('FeatureQuotaSpec'),
}

/** Product feature static term */
export type ProductFeatureSpec =
  | FeatureAvailableSpec
  | FeatureUnlimitedSpec
  | FeatureLimitedSpec
  | FeatureUsagePricingSpec
  | FeatureQuotaSpec

export default ProductFeatureSpec
