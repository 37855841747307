import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { SizeFilterDropdownDumb } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SizeFilterDropdown/SizeFilterDropdown.dumb'
import { HouseSizeFilterProps, LotSizeFilterProps } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { shallow } from 'zustand/shallow'

export const SizeFilterDropdown = () => {
  const houseSizeFilter = useHouseSizeData()
  const lotSizeFilter = useLotSizeData()

  if (!houseSizeFilter || !lotSizeFilter) return null

  return (
    <SizeFilterDropdownDumb
      houseSizeFilter={houseSizeFilter}
      lotSizeFilter={lotSizeFilter}
    />
  )
}

const useHouseSizeData = (): HouseSizeFilterProps | null => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.livingAreaSqft ?? null,
      filterValue: state.local.report.data.filters.livingAreaSqft?.range ?? null,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: HouseSizeFilterProps['onChange'] = range => {
    void filterComps({
      livingAreaSqft: {
        range,
      },
    })
  }

  if (!isReady) return null

  return {
    value: filterValue,
    onChange: handleOnValueChange,
    subjectValue,
  }
}

const useLotSizeData = (): LotSizeFilterProps | null => {
  const isReady = useCMAStore(state =>
    checkStateHasReportData(state.local.report),
  )

  const {
    subjectValue,
    filterValue,
  } = useCMAStore(state => {
    if (!checkStateHasReportData(state.local.report)) {
      return {
        subjectValue: null,
        filterValue: null,
      }
    }

    return {
      subjectValue: state.local.report.data.salesListInfo.subject?.lotAreaSqft ?? null,
      filterValue: state.local.report.data.filters.lotAreaSize,
    }
  }, shallow)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: LotSizeFilterProps['onChange'] = filter => {
    void filterComps({
      lotAreaSize: filter,
    })
  }

  if (!isReady) return null

  return {
    value: filterValue || {
      sqftRange: null,
      unit: 'sqft',
    },
    onChange: handleOnValueChange,
    subjectValue,
  }
}
