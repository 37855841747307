import { Data, Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import AppCache from 'services/AppCache'

type GetOwnersPropertiesPreview = (params: GetOwnersPropertiesPreview.Params) => GetOwnersPropertiesPreview.ResultEffect

namespace GetOwnersPropertiesPreview {
  export type Params = {
    parcelId: string
  }

  export type Result = Data.TaggedEnum<{
    Success: {
      ownershipInfo: OwnersProperties.OwnershipInfo
      ownerPreviews: OwnersProperties.Owner[]
    }
  }>

  export type ResultSuccess = Extract<Result, { _tag: 'Success' }>

  export type Error = UnknownException

  export type ResultEffect = Effect.Effect<Result, Error, AppCache>

  export const Result = Data.taggedEnum<Result>()
}

export default GetOwnersPropertiesPreview
