import { Td, Text, Tr } from '@chakra-ui/react'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PhoneIcon } from 'presentation/components/Icons'
import { SkiptraceSubAccordionItem } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceSubAccordionItem'
import { DateValue, NewValue, SkiptraceTable } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceTable'

export type SkiptracePhonesAccordionProps = {
  items: Skiptrace.PossiblePhoneNumberInfo[]
}

export const SkiptracePhonesAccordion = (props: SkiptracePhonesAccordionProps) => {
  const { items } = props
  const count = items.length
  const hasNew = items.some(v => v.isNew)
  return (
    <SkiptraceSubAccordionItem
      hasNew={hasNew}
      headerIcon={PhoneIcon}
      headerLabel={`Possible Phones (${count})`}
      isEmpty={count === 0}
    >
      <SkiptraceTable>
        {items.map((item, i) => (
          <Tr key={i}>
            <Td w='80px' textAlign='center'>
              {item.isNew
                ? <NewValue />
                : item.updatedAt
                  ? <DateValue date={item.updatedAt} />
                  : null}
            </Td>

            <Breakpoint
              mobSm={(
                <Td>
                  <Text textStyle='bodyM' color='graystrong.200'>
                    {item.type}
                  </Text>
                  <Text textStyle='bodyL' color='graystrong.600'>
                    {item.phoneNumber}
                  </Text>
                </Td>
              )}
              tabSm={(
                <>
                  <Td w='112px'>
                    <Text textStyle='bodyL' color='graystrong.200'>
                      {item.type}
                    </Text>
                  </Td>
                  <Td>
                    <Text textStyle='bodyL' color='graystrong.600'>
                      {item.phoneNumber}
                    </Text>
                  </Td>
                </>
              )}
            />
          </Tr>
        ))}
      </SkiptraceTable>
    </SkiptraceSubAccordionItem>
  )
}
