import { Spacer } from '@chakra-ui/react'
import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Dollar } from 'libs/dollar'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { EmptyCard } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/EmpyCard'
import { TableSectionCommon } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.common'

const Exemption = PropertyDetails.Taxation.Exemption

/**
 * @TODOIMPORTANT We use assessmentTotal here twice
 */
export const TaxationTabContent = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const pairs = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    const taxation = property.taxation
    const pairs = [
      ['Tax Assessed Year', TableSectionCommon.formatString(taxation.year)],
      ['Improvement Value', Dollar.formatNumberWithCommasOrDoubleDash(taxation.assessment?.improvements)],
      ['Land Value', Dollar.formatNumberWithCommasOrDoubleDash(taxation.assessment?.land)],
      ['Total Assessed Value', Dollar.formatNumberWithCommasOrDoubleDash(taxation.assessment?.total), {
        tooltip: 'Improvement Value + Land Value = Total Assessed Value',
      }],
      ['Annual Taxes', Dollar.formatNumberWithCommasOrDoubleDash(taxation.billed)],
      <Breakpoint
        key='spacer'
        tabSm={<Spacer />}
      />,
      ['Exemptions', taxation.exemptions.map(Exemption.format).join(', ') || 'None'],
      ['Actual Tax Value', Dollar.formatNumberWithCommasOrDoubleDash(taxation.assessment?.total), {
        tooltip: 'Total Assessed Value - Applicable Exemptions = Actual Tax Value',
      }],
    ] satisfies TabbedCards.CardSectionProps['pairs']

    return pairs
  })

  return (
    <TabbedCards.TabContent>
      {pairs === null
        ? (
          <EmptyCard />
        )
        : (
          <TabbedCards.CardSection
            label={null}
            upperSegmentText='Public record'
            pairs={pairs}
          />
        )}
    </TabbedCards.TabContent>
  )
}
