import { createIcon } from '@chakra-ui/react'

export const TriangleRightIcon = createIcon({
  displayName: 'TriangleRightIcon',
  viewBox: '0 0 9 8',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9 4L8.12599e-07 8L1.16229e-06 -3.93402e-07L9 4Z' fill='currentColor' />
    </svg>
  ),
})
