import { Box, Image, Text, keyframes } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Exclamation } from 'presentation/screens/Plans/HeroHeader/Exclamation'
import { Underline } from 'presentation/screens/Plans/HeroHeader/Underline'
import { heartBeat, jackInTheBox } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import WomanInWindowPNG from './images/women-in-window.png'

export const HeroHeader: FC = () => (
  <Box
    pt={mbp({ mobSm: '1', mob: '4', tabSm: '6', dtSm: '80px' })}
    px={mbp({ mobSm: '3', mob: '5', tabSm: '7', dtSm: '0' })}
    bgColor='graycool.100'
  >
    <Box
      maxW={mbp({ mobSm: '272px', mob: '400px', tabSm: '640px', dtSm: '800px', dtLg: '1200px' })}
      mx='auto'
      className={jackInTheBox}
    >
      {/* <SlowPlayfulRotate
        float='right'
      > */}
      <Box float='right'>
        <Image
          src={WomanInWindowPNG}
          alt=''
          boxSize={mbp({ mobSm: '119px', mob: '140px', tabSm: '180px', dtSm: '225px', dtLg: '400px' })}
        />
      </Box>
      {/* </SlowPlayfulRotate> */}
      <Text
        color='graystrong.500'
        textStyle={mbp({
          mobSm: 'h3-h',
          mob: 'inter-22-700',
          tabSm: 'inter-36-700',
          dtSm: 'inter-50-700',
          dtLg: 'inter-65-700',
        })}
        pt={mbp({ mobSm: '2', mob: '0', dtSm: '1.5', dtLg: '68px' })}
        mb={mbp({ mobSm: '2', mob: '1.5', tabSm: '3', dtSm: '4' })}
      >
        Everything you need
        {' '}
        <Breakpoint mob={<br />} tabSm='' />
        {' '}
        to
        <Breakpoint dtLg={<br />} />
        {' '}
        buy&nbsp;

        <Text
          as='span'
          display='inline-block'
          className={heartBeat}
          sx={{
            // Fix blurry font due to animation on Chrome
            backfaceVisibility: 'hidden',
            animationName: String(keyframes`
              0% { transform: scale(1); }
              4% { transform: scale(1.1); }
              8% { transform: scale(0.9); }
              12% { transform: scale(1.1); }
              20% { transform: scale(1); }
            `),
            animationIterationCount: 'infinite',
            animationDuration: '4.5s',
            animationDelay: '1.6s',
          }}
        >
          <Text
            as='span'
            display='inline-block'
            textStyle={mbp({
              mobSm: 'kalam-20-700',
              mob: 'kalam-24-700',
              tabSm: 'kalam-44-700',
              dtSm: 'kalam-56-700',
              dtLg: 'kalam-80-700',
            })}
            color='plans.advance'
            position='relative'
          >
            more houses
            <Underline
              position='absolute'
              bottom={mbp({ mobSm: '-5px', tabSm: '0' })}
              width='100%'
            />
          </Text>
          &nbsp;
          <Exclamation
            display='inline-block'
            transform='translateY(5px)'
            w={mbp({ mobSm: '1', tabSm: '2', dtSm: '3' })}
          />
        </Text>
      </Text>
      <Text
        textStyle={mbp({ mobSm: 'body2-b', tabSm: 'body-b', dtSm: 'h3-b', dtLg: 'h2-b' })}
        color='graystrong.200'
      >
        Whether you&apos;re just starting your journey or an established
        {' '}
        <Breakpoint dtSm={<br />} dtLg='' />
        {' '}
        business,
        we&apos;re here to help you scale.
      </Text>
    </Box>
    <Box sx={{ clear: 'both' }}></Box>
  </Box>
)
