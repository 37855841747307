import { BoxProps } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { PropertyMarkerInfo } from 'features/valueObjects/PropertyMarkerInfo'
import { PropertyMarkerProps } from 'presentation/components/PropertyMarker/PropertyMarker.props'
import { FreeAndClearMarker, HighEquityMarker, LowEquityMarker, UpsideDownMarker } from 'presentation/components/PropertyMarker/components/EquityMarker'
import { BankOwnedMarker, CorporateMarker, InStateAbsenteeMarker, MilitaryMarker, OutOfStateMarker, OwnerOccSeniorMarker, OwnerOccVacantMarker, OwnerOccupiedMarker, PreforeclosureMarker, TrustMarker, UnknownMarker, VacantMarker } from 'presentation/components/PropertyMarker/components/Marker'
import { MultiSup, PreforeclosureSup, VacantSup } from 'presentation/components/PropertyMarker/components/Superscript'
import { MarkerSvgContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerSvgContainer'

export const PropertyMarker = ({
  size,
  isForeclosure,
  isVacant,
  isSenior,
  equityType,
  classification,
  markerType,
  shouldHideSuperscript,
  isHighlighted,
  ...props
}: PropertyMarkerProps & BoxProps) => (
  <MarkerSvgContainer size={size} markerType={markerType} {...props}>
    {
      /**
         * ==================
         * Layer 1: Base Pins
         * ==================
         */
    }
    {pipe(
      Match.value({
        classification,
        equityType,
        isVacant,
        isForeclosure,
        isSenior,
        markerType,
      }),

      /** Vacant, no classification, and is tag */
      Match.when({
        markerType: 'tag',
        classification: Match.null,
        isVacant: true,
      }, () => <VacantMarker size={size} markerType={markerType} />),

      /** Preforeclosure, no clasification, and is tag */
      Match.when({
        markerType: 'tag',
        classification: Match.null,
        isForeclosure: true,
      }, () => <PreforeclosureMarker size={size} markerType={markerType} />),

      /** Owner occ, vacant */
      Match.when({
        classification: 'OWNER_OCCUPIED',
        isVacant: true,
        isSenior: false,
      }, () => <OwnerOccVacantMarker size={size} markerType={markerType} />),

      /** Owner occ, senior */
      Match.when({
        classification: 'OWNER_OCCUPIED',
        isSenior: true,
      }, () => <OwnerOccSeniorMarker size={size} markerType={markerType} />),

      /** Rest of owner occ */
      Match.when({
        classification: 'OWNER_OCCUPIED',
      }, () => <OwnerOccupiedMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'IN_STATE',
      }, () => <InStateAbsenteeMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'OUT_OF_STATE',
      }, () => <OutOfStateMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'OUT_OF_COUNTRY',
      }, () => <OutOfStateMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'BANK',
      }, () => <BankOwnedMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'CORPORATE',
      }, () => <CorporateMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'TRUST',
      }, () => <TrustMarker size={size} markerType={markerType} />),

      Match.when({
        classification: 'MILITARY',
      }, () => <MilitaryMarker size={size} markerType={markerType} />),

      Match.orElse(() => <UnknownMarker size={size} markerType={markerType} />),
    )}

    {
      /**
        * ==================
        * Layer 2: Equity Markers
        * ==================
        */
    }
    {pipe(
      Match.value(equityType),
      Match.when('FREE_AND_CLEAR', () => <FreeAndClearMarker />),
      Match.when('HIGH', () => <HighEquityMarker />),
      Match.when('LOW', () => <LowEquityMarker />),
      Match.when('UPSIDE_DOWN', () => <UpsideDownMarker />),
      Match.orElse(() => null),
    )}

    {
      /**
        * ==================
        * Layer 3: Superscripts
        * ==================
        */
    }
    {shouldHideSuperscript
      ? null
      : pipe(
        Match.value<PropertyMarkerInfo>({
          classification,
          equityType,
          isVacant,
          isForeclosure,
          isSenior,
        }),

        Match.when({
          classification: Match.null,
          equityType: Match.null,
          isVacant: Match.any,
          isForeclosure: Match.any,
          isSenior: Match.any,
        }, () => null),

        Match.when({
          classification: 'OWNER_OCCUPIED',
          equityType: Match.any,
          isVacant: true,
          isForeclosure: true,
          isSenior: false,
        }, () => <PreforeclosureSup />),

        Match.when({
          classification: 'OWNER_OCCUPIED',
          equityType: Match.any,
          isVacant: true,
          isForeclosure: true,
          isSenior: true,
        }, () => <VacantSup />),

        Match.when({
          classification: Match.any,
          equityType: Match.any,
          isVacant: true,
          isForeclosure: false,
          isSenior: Match.any,
        }, () => <VacantSup />),

        Match.when({
          classification: Match.any,
          equityType: Match.any,
          isVacant: false,
          isForeclosure: true,
          isSenior: Match.any,
        }, () => <PreforeclosureSup />),

        Match.when({
          classification: Match.any,
          equityType: Match.any,
          isVacant: true,
          isForeclosure: true,
          isSenior: Match.any,
        }, () => <MultiSup />),

        Match.orElse(() => null),
      )}

  </MarkerSvgContainer>
)
