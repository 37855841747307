import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_FARM_ICON_W = 43

export const PropTypeFarmIcon = createIcon({
  displayName: 'PropTypeFarmIcon',
  viewBox: `0 0 ${PROP_TYPE_FARM_ICON_W} 30`,
  path: (
    <>
      <path d='M3 29V26H41V29H3Z' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M35.5 25.5H20V15H16L20 6L28 2L35.5 6L39.5 15H35.5V25.5Z' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M23.375 25C23.375 25.3452 23.6548 25.625 24 25.625C24.3452 25.625 24.625 25.3452 24.625 25H23.375ZM24 15.6667L23.441 15.3872C23.3976 15.4739 23.375 15.5696 23.375 15.6667H24ZM32 15.6667H32.625C32.625 15.6156 32.6187 15.5647 32.6063 15.5151L32 15.6667ZM31.375 25C31.375 25.3452 31.6548 25.625 32 25.625C32.3452 25.625 32.625 25.3452 32.625 25H31.375ZM24.625 25V15.6667H23.375V25H24.625ZM24.559 15.9462C24.745 15.5742 25.1582 14.979 25.7629 14.4834C26.3623 13.992 27.1158 13.625 28 13.625V12.375C26.7509 12.375 25.7266 12.8969 24.9705 13.5166C24.2195 14.1321 23.6994 14.8702 23.441 15.3872L24.559 15.9462ZM28 13.625C28.8914 13.625 29.7036 13.9978 30.33 14.491C30.9794 15.0023 31.3294 15.5612 31.3937 15.8183L32.6063 15.5151C32.4484 14.8832 31.8651 14.1088 31.1033 13.509C30.3186 12.8911 29.2419 12.375 28 12.375V13.625ZM31.375 15.6667V25H32.625V15.6667H31.375Z' fill='currentColor' />
      <path d='M24 19H32M24 19V23M24 19L32 23M32 19V23M32 19L24 23M32 23H24' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9.375 26C9.375 26.3452 9.65482 26.625 10 26.625C10.3452 26.625 10.625 26.3452 10.625 26H9.375ZM10 23.0959H10.625C10.625 23.019 10.6108 22.9428 10.5831 22.871L10 23.0959ZM4 21.4365L3.459 21.1235C3.35537 21.3026 3.34714 21.5215 3.43701 21.7079L4 21.4365ZM10.625 26V23.0959H9.375V26H10.625ZM9.7605 22.5186C9.46948 22.6394 8.93492 22.8127 8.32617 22.9152C7.71096 23.0189 7.07635 23.0405 6.55687 22.9058L6.24313 24.1158C7.00365 24.313 7.8357 24.2655 8.53383 24.1478C9.23841 24.0291 9.86386 23.8291 10.2395 23.6732L9.7605 22.5186ZM6.55687 22.9058C6.05809 22.7765 5.61861 22.4724 5.26264 22.1154C4.90594 21.7575 4.66638 21.3795 4.56299 21.1651L3.43701 21.7079C3.60029 22.0466 3.92073 22.5398 4.37736 22.9978C4.83473 23.4567 5.46191 23.9132 6.24313 24.1158L6.55687 22.9058ZM4.541 21.7494C4.69623 21.4811 5.02153 21.094 5.45514 20.8514C5.86573 20.6217 6.3647 20.522 6.9605 20.7692L7.4395 19.6146C6.4353 19.198 5.53427 19.3748 4.84486 19.7605C4.17847 20.1333 3.70377 20.7004 3.459 21.1235L4.541 21.7494ZM6.9605 20.7692C8.34527 21.3436 9.1851 22.7199 9.41686 23.3208L10.5831 22.871C10.2816 22.0891 9.25473 20.3677 7.4395 19.6146L6.9605 20.7692Z' fill='currentColor' />
      <path d='M10.625 25C10.625 25.3452 10.3452 25.625 10 25.625C9.65482 25.625 9.375 25.3452 9.375 25H10.625ZM10 22.0959H9.375C9.375 22.019 9.38919 21.9428 9.41686 21.871L10 22.0959ZM16 20.4365L16.541 20.1235C16.6446 20.3026 16.6529 20.5215 16.563 20.7079L16 20.4365ZM9.375 25V22.0959H10.625V25H9.375ZM10.2395 21.5186C10.5305 21.6394 11.0651 21.8127 11.6738 21.9152C12.289 22.0189 12.9237 22.0405 13.4431 21.9058L13.7569 23.1158C12.9963 23.313 12.1643 23.2655 11.4662 23.1478C10.7616 23.0291 10.1361 22.8291 9.7605 22.6732L10.2395 21.5186ZM13.4431 21.9058C13.9419 21.7765 14.3814 21.4724 14.7374 21.1154C15.0941 20.7575 15.3336 20.3795 15.437 20.1651L16.563 20.7079C16.3997 21.0466 16.0793 21.5398 15.6226 21.9978C15.1653 22.4567 14.5381 22.9132 13.7569 23.1158L13.4431 21.9058ZM15.459 20.7494C15.3038 20.4811 14.9785 20.094 14.5449 19.8514C14.1343 19.6217 13.6353 19.522 13.0395 19.7692L12.5605 18.6146C13.5647 18.198 14.4657 18.3748 15.1551 18.7605C15.8215 19.1333 16.2962 19.7004 16.541 20.1235L15.459 20.7494ZM13.0395 19.7692C11.6547 20.3436 10.8149 21.7199 10.5831 22.3208L9.41686 21.871C9.71844 21.0891 10.7453 19.3677 12.5605 18.6146L13.0395 19.7692Z' fill='currentColor' />
    </>
  ),
})
