import { CMA } from 'features/CMA/CMA.domain'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'
import { clone } from 'remeda'

/** @TODO Just replace loadCMA cache instead of clearing it */
export const rateComp = async (payload: CMA.RateCompPayload): Promise<CMA.RateCompResult> => {
  const loadReportCacheValue = ServerCache.get('loadCMA')(payload.leadId)

  if (loadReportCacheValue) {
    const cloneCopy = clone(loadReportCacheValue) as CMA.LoadReportResult
    if (cloneCopy.status === 'success') {
      cloneCopy.report.salesListInfo.comps = cloneCopy.report.salesListInfo.comps.map(comp => {
        if (comp.id === payload.compId) {
          return {
            ...comp,
            userRating: payload.rating,
          }
        }

        return comp
      })

      cloneCopy.report.rentalsListInfo.comps = cloneCopy.report.rentalsListInfo.comps.map(comp => {
        if (comp.id === payload.compId) {
          return {
            ...comp,
            userRating: payload.rating,
          }
        }

        return comp
      })

      ServerCache.set('loadCMA')(payload.leadId)(cloneCopy)
    }
  }

  return await restClient.post(
    `legacy/cma/toggle/${payload.leadId}/${payload.reportId}`,
    {
      json: {
        [payload.compId]: payload.rating === 'undecided' ? 'unknown' : payload.rating,
      },
    },
  )
    .json()
    .catch(error => {
      if (loadReportCacheValue)
        ServerCache.set('loadCMA')(payload.leadId)(loadReportCacheValue)

      throw error
    }) as any
}
