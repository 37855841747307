import { createIcon } from '@chakra-ui/react'

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 22 20',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21 1H1l8 9.46V17l4 2v-8.54L21 1Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
