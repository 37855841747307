import { AspectRatio, HStack, Image, ModalOverlay, VStack } from '@chakra-ui/react'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { noop } from 'lodash/fp'
import { Modal, ModalContent } from 'presentation/components/Modal/Modal'
import { Radio } from 'presentation/components/Radio/Radio'
import { Card, CardBody, CardButton, CardFooter, CardFootnote, CardHeader, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { setViewValue as setSharedViewValue, useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { useCompsViewSettingsModal } from 'presentation/screens/CompsScreen/components/CompsViewSettingsModal/CompsViewSettingsModal.api'
import { useEffect, useState } from 'react'
import tableViewPng from './img/table-view.png'
import tileViewPng from './img/tile-view.png'

/**
 * @TODO onClose (commit to settings state)
 *   onSelect (integrate to settings state and view hook)
 */

export const CompsViewSettingsModal = () => {
  const { isValidStateToOpen } = useOpenIfNecessaryLogic()
  const { state, actions } = useCompsViewSettingsModal()
  const sharedViewValue = useResponsiveViewValue()
  const updateSettings = useSettingsStore(state => state.actions.updateSettings.execute)

  const [localViewValue, setLocalViewValue] = useState(sharedViewValue)

  // @jeromegamo maybe look into how you can use startTransition/useTransition
  //   here. Although i did try and there might be issue with zustand or i might
  //   have missed something

  if (state.status !== 'open' || !isValidStateToOpen) return null

  return (
    <Modal
      isOpen
      // Uncloseable except from the main button
      onClose={noop}
    >
      <ModalOverlay />
      <ModalContent>
        <Card
          variant='default'
          colorScheme='modal.alert.neutral'
          size='modal.default.w'
        >
          <CardHeader>
            <CardPrimaryTitle flex='1'>Choose Your Default View</CardPrimaryTitle>
            <CardPrimaryText>Select the view that best suits your preference:</CardPrimaryText>
          </CardHeader>

          <CardBody>
            <HStack spacing={3}>
              <ChoiceBox
                title='Tile View'
                image={tileViewPng}
                isSelected={localViewValue === 'TILE_VIEW'}
                onSelect={() => {
                  // Temporary workaround for the fact that transition takes too long
                  setLocalViewValue('TILE_VIEW')
                  setTimeout(() => {
                    setSharedViewValue('TILE_VIEW')
                  }, 300)
                }}
              />

              <ChoiceBox
                title='Table View'
                image={tableViewPng}
                isSelected={localViewValue === 'TABLE_VIEW'}
                onSelect={() => {
                  // Temporary workaround for the fact that transition takes too long
                  setLocalViewValue('TABLE_VIEW')
                  setTimeout(() => {
                    setSharedViewValue('TABLE_VIEW')
                  }, 300)
                }}
              />
            </HStack>

            <CardFootnote>
              You can change this view in the future by accessing it through
              {' '}
              the Comparative Market Analysis (CMA) “VIEW” button.
            </CardFootnote>
          </CardBody>

          <CardFooter>
            <CardButton
              variant='solid'
              colorScheme='positive'
              isLoading={localViewValue !== sharedViewValue}
              isDisabled={localViewValue !== sharedViewValue}
              loadingText='Loading view...'
              onClick={() => {
                void updateSettings({ settings: { features: { cma: { view: localViewValue } } } })
                actions.closeReplace()
              }}
            >
              START COMPS NOW
            </CardButton>
          </CardFooter>
        </Card>
      </ModalContent>
    </Modal>
  )
}

export const ChoiceBox = ({
  title,
  image,
  isSelected,
  onSelect,
}: {
  title: string
  image: string
  isSelected: boolean
  onSelect: () => void
}) => (
  <Card
    variant={isSelected ? 'bordered' : 'default'}
    borderColor='neutral.500'
    p={3}
    borderRadius={4}
    bg='highlight.100'
    onClick={onSelect}
    as='button'
    flex='1'
  >
    <VStack spacing={2} align='stretch' w='full'>
      <AspectRatio ratio={394 / 293} w='full' alignSelf='stretch'>
        <Image src={image} w='full' />
      </AspectRatio>

      <Radio
        isChecked={isSelected}
        role='presentation'
        size='md'
        variant='default'
        colorScheme='neutral'
        tabIndex={0}
      >
        {title}
      </Radio>
    </VStack>
  </Card>
)

const useOpenIfNecessaryLogic = () => {
  const settingsState = useSettingsStore(state => state.settingsState)
  const { actions } = useCompsViewSettingsModal()

  // For edge case when history still thinks it should open this modal somehow,
  // like when you set view in another tab
  const [isValidStateToOpen, setIsValidStateToOpen] = useState(false)

  useEffect(() => {
    if (settingsState.status === 'loaded') {
      if (!settingsState.settings.features.cma.view) {
        actions.open()
        setIsValidStateToOpen(true)
      } else {
        actions.closeReplace()
        setIsValidStateToOpen(false)
      }
    }
  }, [settingsState.status])

  return { isValidStateToOpen }
}
