import { Button, ButtonProps } from '@chakra-ui/react'
import { shallowEqual, useSelector } from '@xstate/react'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { TrashIcon } from 'presentation/components/Icons'

const RemoveFromAllListAction = (props: ButtonProps) => {
  const actor = useParcelListManager()
  const isLoading = useSelector(actor, snapshot => snapshot.matches('Loading'), shallowEqual)
  const handleRemoveFromAllList = () => {
    actor.send({ type: 'lists.removeFromAll' })
  }
  return (
    <Button
      variant='outline'
      colorScheme='negative'
      bgColor='card.bg.1'
      leftIcon={<TrashIcon />}
      isLoading={isLoading}
      onClick={handleRemoveFromAllList}
      {...props}
    >
      Remove from all list
    </Button>
  )
}

export default RemoveFromAllListAction
