import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Center, Flex, HStack, IconButton, Portal, Progress, Spacer, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match } from 'effect'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { useBatchSkiptraceProgress } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceProgress'
import { useBatchSkiptraceStats } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceStats'
import { useGetBatchSkiptraceByListIdState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useGetBatchSkiptraceByListIdState'
import { useGetBatchSkiptraceStatsByListIdState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useGetBatchSkiptraceStatsByListIdState'
import { useFundsStore } from 'features/Funds/infra/react/Funds.state'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { ListMembersPaginationState, ListMembersState, SelectListState, useListMemberPagination, useListStoreActions, useMembers } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { useListMemberWithSkiptrace } from 'features/common/ListMemberWithSkiptrace/infra/useListMemberWithSkiptrace'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon, ChevronLeftIcon, EyeOutlineIcon } from 'presentation/components/Icons'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { toast } from 'presentation/components/Toast'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { HeaderMobileTitle } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import LocationPath from 'presentation/libs/LocationPath'
import { useToastFailedStateTagged } from 'presentation/libs/hooks/useToastFailedState'
import { BatchSkiptraceDrawer } from 'presentation/screens/ListScreen/components/BatchSkiptraceDrawer/BatchSkiptraceDrawer'
import { BatchSkiptraceModal } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/BatchSkiptraceModal'
import ListAccordion from 'presentation/screens/ListScreen/components/ListAccordion/ListAccordion'
import { ListCriteriaPopover } from 'presentation/screens/ListScreen/components/ListCriteriaPopover/ListCriteriaPopover'
import { ListFooter } from 'presentation/screens/ListScreen/components/ListFooter/ListFooter'
import { ListProgress } from 'presentation/screens/ListScreen/components/ListImportProgress/ListProgress'
import { ListItemsCount } from 'presentation/screens/ListScreen/components/ListItemsCount/ListItemsCount'
import { ListNameSection } from 'presentation/screens/ListScreen/components/ListNameSection/ListNameSection'
import ListScreenActionSheet from 'presentation/screens/ListScreen/components/ListScreenActionSheet/ListScreenActionSheet'
import { ListTable } from 'presentation/screens/ListScreen/components/ListTable/ListTable'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { usePrevious } from 'react-use'

export const ListScreen = () => {
  const [selections, setSelections] = useState<MarketingListMemberId[]>([])

  const rawListId = useParams().listId
  const listId = rawListId ? MarketingListId.make(rawListId) : null

  const actionsApi = useListStoreActions()
  const membersState = useMembers()
  const membersWithSkiptraceApi = useListMemberWithSkiptrace()
  const selectListState = useSelectListState()
  const pageApi = useListMemberPagination()
  const skipActions = useBatchSkiptraceActions()
  const skiptraceProgress = useBatchSkiptraceProgress()

  const location = useLocation()
  const navigate = useNavigate()

  const listsRoute = LocationPath.getParentPath(location.pathname)
  const list = SelectListState.$is('Success')(selectListState)
    ? selectListState.list
    : null
  const listName = list?.name
  const pageInfo = ListMembersPaginationState.$is('Success')(pageApi.pageInfo)
    ? pageApi.pageInfo
    : null

  const isUnsupportedListType = list?.type === 'private-lenders' || list?.type === 'cash-buyers'

  useEffect(() => {
    if (!listId) {
      navigate(listsRoute)
      return
    }

    actionsApi.selectList(MarketingListId.make(listId))
  }, [])

  const page = pageApi.pageInfo._tag === 'Success'
    ? pageApi.pageInfo.currentPage
    : 1
  useEffect(() => {
    if (!listId) return
    skipActions.getByListId(listId, page)
  }, [listId, page])

  const fundsState = useFundsStore(fundsApi => ({
    getFundsInfo: fundsApi.actions.getFundsInfo.execute,
  }))

  useEffect(() => {
    void fundsState.getFundsInfo()
  }, [])

  useEffect(() => {
    if (!listId) return

    skipActions.getStatsByListId(listId)
  }, [listId])

  useEffect(() => {
    if (!listId) return

    skipActions.watchProgress(listId)
  }, [listId])

  const skiptraceStats = useBatchSkiptraceStats()

  const prevProgress = usePrevious(skiptraceProgress)
  useEffect(() => {
    if (!listId || !pageInfo) return

    const didFinish = prevProgress?._tag === 'InProgress' && skiptraceProgress._tag === 'Initial'
    if (didFinish)
      skipActions.getByListId(listId, pageInfo.currentPage)
  }, [skiptraceProgress, prevProgress, listId, pageInfo])

  const listError = selectListState._tag === 'Failed'
    ? selectListState.error
    : ListMembersState.$is('Failed')(membersState)
      ? membersState.error
      : null

  useEffect(() => {
    if (!listError) return
    toast.error({
      title: 'Failed to load list.',
      message: TOAST_PRESET.GENERIC_ERROR.message,
    })
  }, [listError])

  useToastFailedStateTagged(useGetBatchSkiptraceByListIdState(), {
    title: 'Failed to load skiptrace data.',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useToastFailedStateTagged(useGetBatchSkiptraceStatsByListIdState(), {
    title: 'Failed to load skiptrace stats.',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  const set = useSetMobileHeaderContents()

  const headerMobile = (
    <Flex
      justifyContent='flex-start'
      px={1}
      w='full'
      alignItems='center'
      gap={1}
    >
      <IconButton
        aria-label='Back'
        icon={<ArrowLeftIcon />}
        variant='icon-ghost'
        colorScheme='ondark'
        size='md'
        onClick={() => {
          navigate(listsRoute)
          set(null)
        }}
      />
      <HeaderMobileTitle>
        List Manager
      </HeaderMobileTitle>
      {list?.criteria && (
        <ListCriteriaPopover criteria={list.criteria}>
          <IconButton
            aria-label='Back'
            icon={<EyeOutlineIcon />}
            variant='icon-ghost'
            colorScheme='ondark'
            size='md'
            ml='auto'
          />
        </ListCriteriaPopover>
      )}
    </Flex>
  )

  useEffect(() => {
    set([headerMobile, null, null])

    return () => set(null)
  }, [])

  const { sbp } = useSwitchBreakpointFn()

  const bottomPadding = sbp({
    tabSm: 24,
    dtLg: 40,
  }) || 24

  return (
    <>
      <Breakpoint
        mobSm={(
          <Flex
            bg='neutral.100'
            minH='layout-height'
            p={mbp({ mobSm: 1, mob: 3 })}
            flexDir='column'
          >
            {SelectListState.$is('Success')(selectListState)
              ? (
                <Flex
                  flex='1'
                  flexDir='column'
                  gap={2}
                  h='full'
                  minH={0}
                  pt={1}
                  pb={6}
                >
                  <ListNameSection />

                  {(ListMembersState.$is('Importing')(membersState) || skiptraceProgress._tag === 'InProgress') && (
                    <Box>
                      <Text textStyle='bodyLFat' color='graystrong.400'>
                        {ListMembersState.$is('Importing')(membersState) ? 'Importing...' : 'Skiptracing...'}
                      </Text>
                      <Progress
                        mt={0.5}
                        isIndeterminate
                        hasStripe
                        colorScheme='yellow'
                        size='xs'
                        borderRadius='full'
                      />
                    </Box>
                  )}

                  {isUnsupportedListType
                    ? (
                      <Center flex='1' pb={px(ListFooter.MIN_HEIGHT)}>
                        <Box>
                          <Box fontSize='md' color='neutral.600'>
                            {list?.type === 'private-lenders' ? 'Private Lenders' : 'Cash Buyers'}
                            {' '}
                            list support is coming soon!
                          </Box>
                        </Box>
                      </Center>
                    )
                    : membersWithSkiptraceApi._tag === 'Success'
                      ? (
                        <ListAccordion
                          selections={selections}
                          setSelections={setSelections}
                          canSelect={ListMembersState.$is('Success')(membersState)}
                        />
                      )
                      : (
                        <Center flex='1' pb={px(ListFooter.MIN_HEIGHT)}>
                          <Spinner />
                        </Center>
                      )}

                  {!ListMembersState.$is('Importing')(membersState)
                  && !isUnsupportedListType
                  && (
                    <ListScreenActionSheet
                      selections={selections}
                      setSelections={setSelections}
                    />
                  )}

                  {(() => {
                    if (isUnsupportedListType)
                      return null

                    if (ListMembersState.$is('Importing')(membersState))
                      return null

                    if (skiptraceProgress._tag === 'InProgress')
                      return null

                    if (!ListMembersPaginationState.$is('Initial')(pageApi.pageInfo)) {
                      return (
                        <Portal>
                          <MobilePagination />
                        </Portal>
                      )
                    }
                  })()}
                </Flex>
              )
              : (
                <Center flex='1' pb={px(ListFooter.MIN_HEIGHT)}>
                  <Spinner />
                </Center>
              )}
          </Flex>
        )}
        tabSm={(
          <VStack
            bg='neutral.100'
            minH='app-height'
            display='flex'
            alignItems='center'
            justifyContent='stretch'
            spacing={0}
          >
            <VStack
              px={{ dtLg: 5, tabSm: 3 }}
              pt={3}
              maxWidth='1376px'
              w='full'
              align='stretch'
              flex='1'
            >
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to={listsRoute}>
                    <ChevronLeftIcon fontSize={3} mr={1} />
                    List Manager
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink href='#'>{listName ?? 'List'}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              {SelectListState.$is('Success')(selectListState)
                ? (
                  <>
                    <HStack pt={3} spacing={0}>
                      <ListNameSection />
                      <Spacer />

                      {(() => {
                        if (isUnsupportedListType)
                          return null

                        if (ListMembersState.$is('Importing')(membersState)) {
                          return (
                            <ListProgress title='Importing...' progress={membersState.progress.percentage} w='428px' />
                          )
                        }

                        if (skiptraceProgress._tag === 'InProgress') {
                          return (
                            <>
                              <ListItemsCount />
                              <Box w={2} />
                              <ListProgress title='Skiptracing...' progress={skiptraceProgress.percentage} w='428px' />
                            </>
                          )
                        }

                        if (!ListMembersPaginationState.$is('Initial')(pageApi.pageInfo)) {
                          return (
                            <>
                              <ListItemsCount />
                              <Box w={2} />
                              <Box maxW='436px'>
                                <ListPagination />
                              </Box>
                            </>
                          )
                        }
                      })()}
                    </HStack>

                    {isUnsupportedListType
                      ? (
                        <Center flex='1' pb={px(ListFooter.MIN_HEIGHT)}>
                          <Box>
                            <Box fontSize='md' color='neutral.600'>
                              {list?.type === 'private-lenders' ? 'Private Lenders' : 'Cash Buyers'}
                              {' '}
                              list support is coming soon!
                            </Box>
                          </Box>
                        </Center>
                      )
                      : membersWithSkiptraceApi._tag === 'Success' && (!ListMembersState.$is('Importing')(membersState) || membersState.members.length > 0)
                        ? (
                          <Box pt={2} flex='1'>
                            <ListTable
                              selections={selections}
                              setSelections={setSelections}
                              canSelect={ListMembersState.$is('Success')(membersState)}
                              {...sbp({
                                tabSm: {
                                  w: 'calc(100% + 48px)',
                                  maxW: 'calc(100% + 48px)',
                                  minW: 'calc(100% + 48px)',
                                  mx: -3,
                                  px: 3,
                                  pb: px(bottomPadding),
                                },
                                dtSm: {
                                  pb: px(bottomPadding),
                                },
                              })}
                            />
                          </Box>
                        )
                        : (
                          <Center flex='1' pb={px(ListFooter.MIN_HEIGHT + bottomPadding)}>
                            <Spinner />
                          </Center>
                        )}
                  </>
                )
                : (
                  <Center flex='1' pb={px(ListFooter.MIN_HEIGHT + bottomPadding)}>
                    <Spinner />
                  </Center>
                )}
            </VStack>

            {selectListState._tag === 'Success'
            && !ListMembersState.$is('Loading')(membersState)
            && skiptraceStats._tag === 'Success'
            && !listError
            && (
              <ListFooter
                selections={selections}
                setSelections={setSelections}
                status={ListMembersState.$is('Importing')(membersState) ? 'importing' : 'ready'}
                position='sticky'
                bottom='0'
              />
            )}
          </VStack>
        )}
      />

      <BatchSkiptraceDrawer />
      <BatchSkiptraceModal />
    </>
  )
}

const ListPagination = () => {
  const api = useListMemberPagination()
  const { sbp } = useSwitchBreakpointFn()
  return Match.value(api.pageInfo).pipe(
    Match.when({ _tag: 'Loading' }, page => (
      <Pagination
        colorScheme='highlight'
        currentPage={page.currentPage}
        totalPages={Math.ceil(page.totalCount / page.countPerPage)}
        maxPageButtons={8}
        size='lg'
        onPageChange={api.setPage}
        {...sbp({
          mobSm: { shouldUseChevron: true },
          mob: { shouldUseChevron: false },
          tabSm: { shouldHideFillers: true },
        })}
      />
    )),
    Match.when({ _tag: 'Success' }, page => (
      <Pagination
        colorScheme='highlight'
        currentPage={page.currentPage}
        totalPages={Math.ceil(page.totalCount / page.countPerPage)}
        maxPageButtons={8}
        size='lg'
        onPageChange={api.setPage}
        {...sbp({
          mobSm: { shouldUseChevron: true },
          mob: { shouldUseChevron: false },
          tabSm: { shouldHideFillers: true },
        })}
      />
    )),
    Match.orElse(() => null),
  )
}

const MobilePagination = () => {
  ;
  return (
    <Box
      position='fixed'
      bottom={0}
      left={0}
      right={0}
      p={1}
      bgColor='neutral.100'
      shadow='vscroll-fade-bottom'
    >
      <ListPagination />
    </Box>
  )
  // const isMobile = useSwitchBreakpoint({ mobSm: true, tabSm: false })

  // /**
  //  * @NOTE: Hide button label when page is scrolling
  //  */
  // const [isScrolling, setIsScrolling] = useState(false)

  // // let scrollTimeout: NodeJS.Timeout
  // const scrollTimeoutRef = useRef<NodeJS.Timeout>()

  // const handleScroll = () => {
  //   if (!isScrolling)
  //     setIsScrolling(true)

  //   clearTimeout(scrollTimeoutRef.current)

  //   scrollTimeoutRef.current = setTimeout(() => {
  //     setIsScrolling(false)
  //   }, 1000)
  // }

  // useEffect(() => {
  //   const element = document.getElementById(MOBILE_SCROLLABLE_LAYOUT_ID)

  //   if (!element) return

  //   if (element.scrollTop > 0)
  //     setIsScrolling(true)

  //   return () => clearTimeout(scrollTimeoutRef.current)
  // }, [])

  // useEventListener('scroll', handleScroll)

  // return isScrolling && isMobile
  //   ? null
  //   : (
  //     <Box
  //       position='fixed'
  //       bottom={0}
  //       left={0}
  //       right={0}
  //       p={1}
  //       bgColor='neutral.100'
  //       shadow='vscroll-fade-bottom'
  //     >
  //       <ListPagination />
  //     </Box>
  //   )
}
