import { prepareTheme } from 'presentation/main/themes/prepareTheme'
import { lightColors } from './colors.light.theme'

type Colors = typeof lightColors

export const lightTheme = prepareTheme<Colors, 'light'>('light' as const, lightColors)

/**
 * @NOTE We need exemption to `import/no-default-export` rule for Chakra theme type generation
 */
export default lightTheme
