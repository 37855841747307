import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeAnyIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.4297 31.3271L37.4321 35.0737V33.7953C37.4321 33.5348 37.6357 33.3237 37.8869 33.3237C38.1381 33.3237 38.3417 33.5348 38.3417 33.7953V35.7549L40.8818 37.6573C41.0854 37.8098 41.1312 38.1046 40.9842 38.3157C40.8371 38.5269 40.5529 38.5744 40.3492 38.4219L32.4297 32.4906L24.5101 38.4219C24.3065 38.5744 24.0222 38.5269 23.8752 38.3157C23.7281 38.1046 23.774 37.8098 23.9776 37.6573L32.4297 31.3271ZM32.4297 32.7441L39.2512 37.7969V46.9999H25.6082V37.7969L32.4297 32.7441ZM26.5177 38.2823V46.0567H38.3417V38.2823L32.4297 33.9032L26.5177 38.2823Z'
      fill={props.color}
    />
    <chakra.path
      d='M25.0265 43.5H13.4766V23.25L20.9766 16.5L28.4766 23.25V33.3059'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='16.9141'
      y='25.7583'
      width='2.5px'
      height='2.61667px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='16.9141'
      y='31.5583'
      width='2.5px'
      height='2.61667px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='22.5391'
      y='25.7583'
      width='2.5px'
      height='2.61667px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='22.5391'
      y='31.5583'
      width='2.5px'
      height='2.61667px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='19.7266'
      y='37.9778'
      width='2.5px'
      height='5.51667px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M11.4766 23L20.9766 14.5L27.6824 20.5M30.4766 23L27.6824 20.5M27.6824 20.5V18'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
    />
    <chakra.path
      d='M42.8594 41.5451V27.7272'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.path
      d='M45.2934 24.0399C47.6301 24.0399 49.5244 25.5483 49.5244 27.4089C49.5244 29.2695 47.6301 30.7778 45.2934 30.7778M45.7165 30.7778C45.2934 33.0237 44.0117 33.7724 41.9087 33.7724C39.8057 33.7724 38.1008 31.0126 38.1008 28.5318M38.5239 21.0454C36.1872 21.0454 34.293 22.7213 34.293 24.7886C34.293 26.8559 36.1872 28.5318 38.5239 28.5318M38.1008 21.4197C38.1008 18.1119 39.7821 16.5 42.3524 16.5C44.9228 16.5 48.2351 20.2787 46.1396 24.0399'
      stroke={props.color}
    />
  </chakra.g>
)
