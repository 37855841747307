import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { get } from 'presentation/utils/graphql'
import { useEffect } from 'react'
import { unmaskFailedInvoiceFragment } from '../FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { openPaymentFailedModal } from './PaymentFailedModal'

const USE_PAYMENT_FAILED_MODAL__GET_PAST_DUE_INVOICE = gql(/* GraphQL */ `
  query UsePaymentFailedModal_GetPastDueInvoice {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
        }
        ... on BillingSubscriptionInactive {
          id
        }

        ...FailedInvoiceFlow_FailedInvoiceSummary
      }
    }
  }
`)

export const usePaymentFailedModal = () => {
  const { data } = useQuery(USE_PAYMENT_FAILED_MODAL__GET_PAST_DUE_INVOICE)

  const sub = data?.myEnterprise?.subscription
  const activeSub = get('BillingSubscriptionActive')(sub)

  const failedInvoiceSummary = activeSub ? unmaskFailedInvoiceFragment(activeSub) : null

  const isPastDue = !!failedInvoiceSummary

  useEffect(() => {
    if (!isPastDue) return
    openPaymentFailedModal({ failedInvoiceSummary })
  }, [isPastDue])
}
