import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeTownHouseIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      d='M15.625 44.375V23.2589L23 15.8839L30.375 23.2589V44.375H15.625Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M30.625 44.375V23.2589L38 15.8839L45.375 23.2589V44.375H30.625Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='18.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='33.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='18.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='33.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='24.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='24.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='21.625'
      y='38.625'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='36.625'
      y='38.625'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M13 23L23 13L30.5 20.5L38 13L45 20M48 23L45 20M45 20V16'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </chakra.g>
)
