import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const GlobeIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.circle
        cx='16.0003'
        cy='15.9993'
        r='12.3333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M25.9997 8L23.8663 9.6C23.5201 9.85964 23.0991 10 22.6663 10H17.9024C17.1449 10 16.4523 10.428 16.1136 11.1056L16.048 11.2367C15.6299 12.0729 15.8468 13.0871 16.5704 13.6791L20.1423 16.6016C21.7163 17.8893 22.5308 19.8867 22.3062 21.9079L22.0933 23.8243C22.031 24.3843 21.9098 24.9362 21.7317 25.4707L21.333 26.6667'
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M3.33301 13.9993L8.5711 13.1263C9.92475 12.9007 11.0983 14.0743 10.8727 15.4279L10.4854 17.7519C10.1967 19.484 11.0714 21.2019 12.6421 21.9872L13.1806 22.2565C14.4382 22.8853 15.0904 24.3032 14.7493 25.6673L13.9997 28.666'
        stroke={stroke}
        strokeWidth='2'
      />
    </chakra.svg>

  )
}
