import { Box, Center, Flex, IconButton, IconButtonProps, Image, ImageProps, Text, TextProps } from '@chakra-ui/react'
import logo from 'presentation/assets/img/red-logo-white-text.svg'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { HelpCircleIcon, SearchIcon } from 'presentation/components/Icons'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { SearchPropertyInput } from 'presentation/layouts/Layout/components/HeaderDesktop/SearchPropertyInput'
import { HEADER_H_MOBILE, HEADER_H_TABLET } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile.const'
import { HeaderMobileDrawer } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/HeaderMobileDrawer'
import { UserMenu } from 'presentation/layouts/Layout/components/UserMenu'
import { useLayoutStore, useMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { useMatch } from 'react-router-dom'

export const HeaderMobile: FC = () => {
  const title = useLayoutStore(state => state.title)
  const rightToolbar = useLayoutStore(state => state.headerMobRightToolbar)
  const isSubscribed = LayoutViewModel.useReadySelector(state =>
    !!state.subscription)

  const isMobileSearchInputVisible = useLayoutStore(layout =>
    layout.isMobileSearchPropertyInputVisible)
  const setIsMobileSearchInputVisible = useLayoutStore(layout =>
    layout.setIsMobileSearchPropertyInputVisible)

  const customMobileContent = useMobileHeaderContents()
  const isMobile = useSwitchBreakpoint({ mobSm: true, tabSm: false })

  if (isMobileSearchInputVisible) {
    return (
      <Flex
        as='header'
        pr={mbp({ mobSm: 1, tabSm: 3 })}
        pl={mbp({ mobSm: 1, tabSm: 2 })}
        align='center'
        flexGrow='0'
        flexShrink='0'
        h={mbp({
          mobSm: `${HEADER_H_MOBILE}px`,
          tabSm: `${HEADER_H_TABLET}px`,
        })}
        bg='special.500'
        position='relative'
        zIndex='10'
      >
        <SearchPropertyInput
          shouldShowCloseButton
          onClose={() => setIsMobileSearchInputVisible(false)}
        />
      </Flex>
    )
  }

  return (
    <Flex
      as='header'
      pr={isMobile && customMobileContent ? 0 : mbp({ mobSm: 1, tabSm: 3 })}
      pl={isMobile && customMobileContent ? 0 : mbp({ mobSm: 1, tabSm: 2 })}
      align='center'
      flexGrow='0'
      flexShrink='0'
      h={mbp({
        mobSm: `${HEADER_H_MOBILE}px`,
        tabSm: `${HEADER_H_TABLET}px`,
      })}
      bg='special.500'
      position='relative'
      zIndex='10'
    >
      <Breakpoint
        mobSm={
          customMobileContent
            ? customMobileContent
            : (
              <>
                <HeaderMobileDrawer />

                {isSubscribed
                  ? (
                    <HeaderMobileTitle
                      display='flex'
                      flex={mbp<number | string>({
                        mobSm: 1,
                        tabSm: 'inherit',
                      })}
                      alignItems='center'
                      justifyContent='center'
                      px={2}
                    >
                      {title || 'Propelio'}
                    </HeaderMobileTitle>
                  )
                  : (<Center flex='1'><Logo h='3' /></Center>)}

                {rightToolbar || (
                  <ContextDependentIconButton
                    onSearchClick={() => setIsMobileSearchInputVisible(true)}
                    isSearchInputAlwaysVisible={false}
                  />
                )}
              </>
            )
        }
        tabSm={(
          <>
            {isSubscribed
              ? (
                <>
                  <HeaderMobileDrawer />
                  <HeaderMobileTitle
                    display='flex'
                    flex={mbp<number | string>({
                      mobSm: 1,
                      tabSm: 'inherit',
                    })}
                    alignItems='center'
                    justifyContent='center'
                    px={2}
                  >
                    {title || 'Propelio'}
                  </HeaderMobileTitle>
                </>
              )
              : (
                <Box flex='1'>
                  <Logo h='4' />
                </Box>
              )}

            <Breakpoint
              tabSm={(
                <>
                  {isSubscribed && (
                    <Flex flex={1} justify='center' mx={3}>
                      <SearchPropertyInput />
                    </Flex>
                  )}

                  <Flex gap='0.5' align='center'>
                    <ContextDependentIconButton
                      onSearchClick={() => setIsMobileSearchInputVisible(true)}
                      isSearchInputAlwaysVisible
                    />
                  </Flex>

                  <UserMenu size='sm' ml='2' />
                </>
              )}
              dt={() => null}
            />
          </>
        )}
      />
    </Flex>
  )
}

export const HeaderMobileTitle = ({ children, ...textProps }: TextProps) => (
  <Text
    as='h1'
    mt='-2px'
    textStyle='bodyXLFat'
    color='ondark.2'
    {...textProps}
  >
    {children}
  </Text>
)

const Logo: FC<ImageProps> = props => (
  <Image
    src={logo}
    alt='Propelio'
    w='auto'
    {...props}
  />
)

const HeaderIconButton: FC<IconButtonProps> = props => (
  <IconButton
    variant='icon-ghost'
    colorScheme='ondark'
    size='md'
    {...props}
  />
)

const ContextDependentIconButton = ({
  onSearchClick,
  isSearchInputAlwaysVisible,
}: {
  onSearchClick: () => void
  isSearchInputAlwaysVisible: boolean
}) => {
  const isOnSearchFeature = useMatch('/search/*')

  if (!isOnSearchFeature || isSearchInputAlwaysVisible) {
    return (
      <HeaderIconButton
        aria-label='help'
        onClick={() => {
          void SupportWidget.openWidget({ topic: 'support' })
        }}
        icon={<HelpCircleIcon />}
      />
    )
  }

  return (
    <HeaderIconButton
      aria-label='search'
      onClick={() => onSearchClick()}
      icon={<SearchIcon />}
    />
  )
}
