import { Box, Button, Flex, Input, RadioGroup, RadioProps, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { thumbsUpOnly } from 'features/CMA/CMA.helpers'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { FullEstimateInfo, calculateFullEstimateInfo } from 'features/CMA/infra/react/CMAState.helpers'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Dollar } from 'libs/dollar'
import { EditIcon } from 'presentation/components/Icons'
import { Radio } from 'presentation/components/Radio/Radio'
import { ThumbsDownIcon, ThumbsUpIcon } from 'presentation/components/ThumbButtons'
import { Card } from 'presentation/components/molecules/Card'
import { CMAEstimateInfoCardMode, CMAEstimateInfoCardProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAEstimateInfoCard/CMAEstimateInfoCard.props'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'

type CMAReportEstimateInfo = {
  updateEstimateInfo: (params: CMA.EstimateInfo) => Promise<void>
  estimateInfo: FullEstimateInfo | null
  totalComps: number
}

/**
 * @TODO: call openCMADiscardProgressDialog when user hasn't saved changes
 */
export const CMAEstimateInfoCard = (props: CMAEstimateInfoCardProps) => {
  const userInputSqft = usePropertyDetailsStore(state => {
    const propState = state.actions.getProperty.state

    if (propState.status !== 'success') return null
    const sqft = propState.data.userInputData.building?.livingAreaSqft
    return sqft ?? null
  })

  const report: CMAReportEstimateInfo = useCMAStore(api => {
    const report = api.local.report
    const status = report.status

    if (status !== 'loaded') {
      return {
        updateEstimateInfo: async () => {},
        estimateInfo: null,
        totalComps: 0,
      }
    }

    const { data } = report

    const totalComps = pipe(
      Match.value(props.cardType),
      Match.when('SALES', () => data.salesListInfo.comps.filter(thumbsUpOnly).length),
      Match.when('RENTALS', () => data.rentalsListInfo.comps.filter(thumbsUpOnly).length),
      Match.exhaustive,
    )

    const estimateInfo = pipe(
      Match.value(props.cardType),
      Match.when('SALES', () => calculateFullEstimateInfo(
        data.salesListInfo,
        userInputSqft,
      )),
      Match.when('RENTALS', () => calculateFullEstimateInfo(
        data.rentalsListInfo,
        userInputSqft,
      )),
      Match.exhaustive,
    )

    return {
      updateEstimateInfo: async estimateInfo =>
        await api.actions.updateEstimateInfo.execute({
          type: props.cardType === 'SALES' ? 'sales' : 'rentals',
          estimateInfo,
        }),
      estimateInfo,
      totalComps,
    }
  }, shallow)

  const [mode, setMode] = useState<CMAEstimateInfoCardMode>(
    props.__DANGER_STORYBOOK_ONLY__?.mode ?? 'default',
  )

  const handleOnDone = (estimateInfo: CMA.EstimateInfo) => {
    void report.updateEstimateInfo(estimateInfo)
    setMode('default')
  }

  const handleOnCancel = () => {
    setMode('default')
  }

  const handleOnModify = () => {
    setMode('custom')
  }

  return (
    <Card
      size='xxs-locked'
      w='full'
      boxShadow='button-hovered'
      minH='240px'
    >
      {pipe(
        Match.value(mode),
        Match.when('default', () => (
          <DefaultMode
            cardType={props.cardType}
            onModify={handleOnModify}
            {...report}
          />
        )),
        Match.when('custom', () => (
          <CustomMode
            onCancel={handleOnCancel}
            onDone={handleOnDone}
            {...report}
          />
        )),
        Match.exhaustive,
      )}
    </Card>
  )
}

const DefaultMode = (props: {
  cardType: CMAEstimateInfoCardProps['cardType']
  onModify?: () => void
} & CMAReportEstimateInfo) => {
  const hasNoData = props.estimateInfo === null

  const preferredValue = props.estimateInfo?.preferred.value
  const preferredMethod = props.estimateInfo?.preferred.method
  const valueDescription = pipe(
    Match.value(preferredMethod),
    Match.when('from-avg', () => 'Average Price'),
    Match.when('from-avg-per-sqft', () => 'Average $/Sqft'),
    Match.when('override', () => 'Custom Value'),
    Match.orElse(() => null),
  )

  const label = pipe(
    Match.value(props.cardType),
    Match.when('RENTALS', () => 'Rental'),
    Match.when('SALES', () => 'Sales'),
    Match.exhaustive,
  )

  const content = (
    <>
      <Box
        mt={3}
        p={1.5}
        borderRadius={2}
        borderWidth={0.125}
        borderColor='specialsat.500'
      >
        <Text
          textStyle='bodyLFat'
          color='neutral.500'
          textAlign='center'
          textTransform='capitalize'
        >
          {label}
          {' '}
          Estimate
        </Text>
        <Text
          mt={1}
          textStyle='h2'
          color='graystrong.400'
          textAlign='center'
        >
          {Dollar.formatNumberWithCommasOrDoubleDash(preferredValue)}
        </Text>
        <Text
          textStyle='bodySFat'
          color='grayweak.900'
          textAlign='center'
        >
          {valueDescription
            ? `Based on ${valueDescription}`
            : '--'}

        </Text>
      </Box>

      <Button
        mt={3}
        size='xs'
        variant='solid'
        colorScheme='neutral'
        leftIcon={<EditIcon boxSize={2} />}
        w='min-content'
        mx='auto'
        onClick={props.onModify}
      >
        Modify
      </Button>
    </>
  )

  return (
    <>
      <Flex
        justifyContent='space-between'
        alignItems='center'
      >
        <Text
          textStyle='bodyXLFat'
          color='accent.blue-text'
          textTransform='capitalize'
        >
          {label}
          {' '}
          Comps
        </Text>
        <Text
          textStyle='bodyM'
          color='grayweak.900'
        >
          {props.totalComps}
          {' '}
          Included
        </Text>
      </Flex>

      {hasNoData
        ? <NoData />
        : content}
    </>
  )
}

type SelectedRadioOption = CMA.EstimateMethod

const CustomMode = (props: {
  onCancel?: () => void
  onDone?: (params: CMA.EstimateInfo) => void
} & CMAReportEstimateInfo) => {
  const defaultMethod = props.estimateInfo?.preferred.method ?? 'from-avg'

  const [selectedOption, setSelectedOption] = useState<SelectedRadioOption>(defaultMethod)

  const [overrideValue, setOverrideValue] = useState<number | null>(
    props.estimateInfo?.preferred.value ?? null,
  )

  const formatedOverrideValue = overrideValue !== null
    ? Dollar.formatNumberWithCommas(overrideValue)
    : ''

  const shouldDisableDoneButton = selectedOption === 'override' && overrideValue === undefined

  const handleOptionChange = (value: SelectedRadioOption) => {
    setSelectedOption(value)
  }

  const handleOnOverrideChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Dollar.toNumber(e.target.value)
    setOverrideValue(value)
  }

  const handleOnDone = () => {
    if (selectedOption === 'override') {
      props.onDone?.({
        method: 'override',
        overrideValue: overrideValue ?? 0,
      })
      return
    }

    props.onDone?.({ method: selectedOption })
  }

  const handleOnCancel = () => {
    props.onCancel?.()
  }

  return (
    <>
      <Flex
        h={5}
        alignItems='center'
      >
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
        >
          Calculated from:
        </Text>
      </Flex>

      <RadioGroup
        value={selectedOption}
        onChange={handleOptionChange}
        sx={{
          '& .chakra-radio__label': {
            w: 'full',
          },
        }}
      >
        <Flex flexDirection='column'>
          <RadioItemOption
            label='Avg $/Sqft'
            value='from-avg-per-sqft'
          >
            {Dollar.formatNumberWithCommasOrDoubleDash(
              props.estimateInfo?.fromAvgPricePerSqft,
            )}
          </RadioItemOption>
          <RadioItemOption
            label='Avg Price'
            value='from-avg'
          >
            {Dollar.formatNumberWithCommasOrDoubleDash(
              props.estimateInfo?.fromAvgPrice,
            )}
          </RadioItemOption>
          <Flex justifyContent='space-between'>
            <RadioItemOption
              label='Custom'
              value='override'
            />
            <Input
              type='text'
              w='100px'
              textAlign='right'
              value={formatedOverrideValue}
              onChange={handleOnOverrideChange}
              isDisabled={selectedOption !== 'override'}
            />
          </Flex>
        </Flex>
      </RadioGroup>

      <Flex
        mt={3}
        gap={2}
        justifyContent='stretch'
      >
        <Button
          colorScheme='negative'
          variant='outline'
          size='xs'
          onClick={handleOnCancel}
        >
          Cancel
        </Button>
        <Button
          colorScheme='neutral'
          variant='solid'
          size='xs'
          onClick={handleOnDone}
          isDisabled={shouldDisableDoneButton}
        >
          Done
        </Button>
      </Flex>
    </>
  )
}

const NoData = () => (
  <Box>
    <Flex gap={2} mt={4} justifyContent='center'>
      <ThumbsUpIcon boxSize={4} value='up' />
      <ThumbsDownIcon boxSize={4} value='down' />
    </Flex>
    <Text
      mt={1}
      textStyle='bodyL'
      color='graystrong.200'
      textAlign='center'
    >
      Use the thumbs up or thumbs down when viewing properties to include or
      exclude them from your suggested value.
    </Text>
  </Box>
)

const RadioItemOption = (props: RadioProps & {
  label: string
}) => {
  const { children, label, ...rest } = props
  return (
    <Radio
      h={5}
      {...rest}
    >
      <Flex
        justifyContent='space-between'
        textStyle='bodyMFat'
        color='graystrong.400'
        alignItems='center'
        h='full'
        w='full'
      >
        <Text color='inherit'>{label}</Text>
        <Box>
          {children}
        </Box>
      </Flex>
    </Radio>
  )
}
