import { BillingIntervals, ChoosePlan_CurrentPlanFragment, ChoosePlan_TargetProductFragment } from '__generated__/graphql'
import { Array, Effect, Layer, Match, Option, pipe, String } from 'effect'
import { handleOpenChoosePlanModal } from 'presentation/screens/Plans/components/AddToCartBtn'
import ApplicationError from 'presentation/screens/Plans/PlanComparisonV2/domain/ApplicationError'
import PlanCheckoutService from 'presentation/screens/Plans/PlanComparisonV2/domain/PlanCheckoutService'
import { PlanId } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'
import { handleOpenPlanDowngradeAlert } from 'presentation/screens/Plans/PlanComparisonV2/PlanDowngradeAlert/handleOpenPlanDowngradeAlert'

const addToCartLive = Layer.succeed(
  PlanCheckoutService.AddToCart,
  ({ activeProduct, targetProduct, selectedPlanId }) => {
    // Check if user is downgrading
    const downgrading = isDowngrading(activeProduct, targetProduct, selectedPlanId)

    const GQLTargetProduct: ChoosePlan_TargetProductFragment = {
      __typename: 'BillingSubscriptionProduct',
      id: targetProduct.id,
      name: targetProduct.name,
      plans: Array.map(targetProduct.plans, plan => ({
        __typename: 'BillingSubscriptionPlan',
        id: plan.id,
        name: targetProduct.name,
        price: plan.price,
        interval: {
          __typename: 'BillingSubscriptionPlanInterval',
          unit: plan._tag === 'YearlyPlan' ? BillingIntervals.Year : BillingIntervals.Month,
        },
      })),
    }

    const GQLActiveProduct: Option.Option<ChoosePlan_CurrentPlanFragment> = pipe(
      activeProduct,
      Option.map(activeProduct => ({
        __typename: 'BillingSubscriptionActive',
        id: activeProduct.id,
        plan: {
          __typename: 'BillingSubscriptionPlan',
          id: activeProduct.plans.id,
          name: String.capitalize(activeProduct.name),
          price: activeProduct.plans.price,
          interval: {
            __typename: 'BillingSubscriptionPlanInterval',
            unit: activeProduct.plans._tag === 'YearlyPlan' ? BillingIntervals.Year : BillingIntervals.Month,
          },
        },
        currentPeriod: {
          __typename: 'BillingPeriod',
          end: activeProduct.currentPeriod.end.pipe(Option.getOrUndefined),
        },
      } satisfies ChoosePlan_CurrentPlanFragment)),
    )

    if (downgrading) {
      // Extract the Skiptrace Feature limit and interval from the active product
      const skiptraceInfo = pipe(
        activeProduct,
        Option.flatMap(product =>
          pipe(
            product.features,
            Array.findFirst(feature =>
              feature.featureName === 'Skiptrace',
            ),
          ),
        ),
        Option.flatMap(feature =>
          pipe(
            Match.value(feature),
            Match.when({ _tag: 'FeatureUnlimitedState' }, () =>
              Option.some({
                skipAmount: 'Unlimited',
                billingInterval: 'monthly',
              }),
            ),
            Match.when({ _tag: 'FeatureQuotaState' }, f =>
              Option.some({
                skipAmount: f.quotaLimit.toString(),
                billingInterval: f.replenishInterval,
              }),
            ),
            Match.orElse(() => Option.none()),
          ),
        ),
        Option.getOrElse(() => ({
          skipAmount: '0',
          billingInterval: 'monthly',
        })),
      )

      // Format the plan name
      const targetPlanName = `${String.capitalize(targetProduct.name)}'s Plan`

      // Open PlanDowngradeAlert with skip amount and billing interval from active product
      handleOpenPlanDowngradeAlert({
        skipAmount: skiptraceInfo.skipAmount,
        billingInterval: skiptraceInfo.billingInterval,
        planName: targetPlanName,
        onProceed: () => {
          // When the user proceeds, open ChoosePlanModal
          handleOpenChoosePlanModal({
            targetProduct: GQLTargetProduct,
            currentPlan: GQLActiveProduct.pipe(
              Option.getOrUndefined,
            ),
            selectedPlanId: selectedPlanId.pipe(
              Option.getOrUndefined,
            ),
            shouldReplace: true,
          })
        },
      })
    } else {
      // If not downgrading, directly open ChoosePlanModal
      handleOpenChoosePlanModal({
        targetProduct: GQLTargetProduct,
        currentPlan: GQLActiveProduct.pipe(
          Option.getOrUndefined,
        ),
        selectedPlanId: selectedPlanId.pipe(
          Option.getOrUndefined,
        ),
      })
    }

    return Effect.void
  },
)

export default addToCartLive

// Helper function to determine tier level from subscription product name
const getTierLevel = (name: string): number => {
  switch (name.toLowerCase()) {
  case 'free': return 0
  case 'beginner': return 1
  case 'intermediate': return 2
  case 'advanced': return 3
  default: return -1
  }
}

// Helper function to determine if the user is downgrading
const isDowngrading = (
  activeProduct: Option.Option<SubscriptionProductActive>,
  targetProduct: SubscriptionProduct,
  selectedPlanId: Option.Option<PlanId>,
): boolean => pipe(
  activeProduct,
  Option.match({
    onNone: () => false, // No active product means they can't downgrade
    onSome: active => {
      // Find the selected plan from the target product
      const selectedPlan = pipe(
        selectedPlanId,
        Option.flatMap(id =>
          Array.findFirst(targetProduct.plans, plan => plan.id === id),
        ),
      )

      // If no selected plan is found, use the first plan from target product
      const targetPlan = pipe(
        selectedPlan,
        Option.match({
          onNone: () => pipe(
            Array.get(targetProduct.plans, 0),
            Option.getOrThrowWith(() => new ApplicationError({ message: 'No plans available for this product' })),
          ),
          onSome: plan => plan,
        }),
      )

      // Compare based on product name tier first
      const activeTier = getTierLevel(active.name)
      const targetTier = getTierLevel(targetProduct.name)

      if (activeTier > targetTier)
        return true // Downgrading to a lower tier product
      else if (activeTier < targetTier)
        return false // Upgrading to a higher tier product

      // If same product tier, compare based on plan type and price
      if (active.plans._tag === 'YearlyPlan' && targetPlan._tag === 'MonthlyPlan')
        return true // Downgrading from yearly to monthly
      else if (active.plans._tag === 'MonthlyPlan' && targetPlan._tag === 'YearlyPlan')
        return false // Upgrading from monthly to yearly

      // If both same plan type, compare by price
      return active.plans.price > targetPlan.price
    },
  }),
)
