import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { TriangleExclamationIcon } from 'presentation/components/Icons'
import { NoticeButton, NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { openLegacyPlanModal } from 'presentation/screens/Billing/Billing.modals'
import { get } from 'presentation/utils/graphql'
import { useEffect } from 'react'

const GET_IS_PLAN_LEGACY = gql(/* GraphQL */ `
  query GetIsPlanLegacy {
    myEnterprise {
      id
      subscription {
        ...LegacyPlanModal__CurrentSubscription
        ... on BillingSubscriptionActive {
          id
          plan {
            id
            product {
              isLegacy
            }
          }
        }
        ... on BillingSubscriptionInactive {
          id
        }
      }
    }
  }
`)

export const useLegacyPlanNotice = () => {
  const { data, loading, error } = useQuery(GET_IS_PLAN_LEGACY)

  const sub = data?.myEnterprise?.subscription
  const isLegacy = sub?.__typename === 'BillingSubscriptionActive'
    ? sub.plan.product.isLegacy
    : null
  const activeSub = get('BillingSubscriptionActive')(sub)

  const updateNotice = useNoticeStore(store => store.updateNotice)

  useEffect(() => {
    if (loading || error) return

    if (isLegacy && activeSub) {
      updateNotice(NOTICE_KEYS.LEGACY_PLAN, (
        <ResponsiveNotice
          colorScheme='warning'
          icon={TriangleExclamationIcon}
          textContent={(
            <>
              <NoticeEmphasis>
                Your current plan is no longer available for selection.
                You may still stay on your current plan or switch to a new one below.
              </NoticeEmphasis>
            </>
          )}
          actionsContent={(
            <>
              <NoticeButton
                variant='solid'
                colorScheme='negative'
                onClick={() => {
                  openLegacyPlanModal({ subscription: activeSub })
                }}
              >
                View Details
              </NoticeButton>
            </>
          )}
        />
      ))
    }

    return () => updateNotice(NOTICE_KEYS.LEGACY_PLAN, null)
  }, [isLegacy, activeSub])
}
