import { useEffect, useRef } from 'react'

export const useAppDimensionsCssVar = () => {
  useHeightCssVar()
  useWidthCssVar()
}

/** Better version of 100vh as it take accounts status bar and alike on mobile */
const useHeightCssVar = () => {
  const updateCssVar = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    updateCssVar()
    window.addEventListener('resize', updateCssVar)

    return () => {
      window.removeEventListener('resize', updateCssVar)
    }
  }, [])
}

/** Better version of 100vw as it takes account of scrollbar width */
const useWidthCssVar = () => {
  const lastAppWidthRef = useRef(0)
  const resizeObserverRef = useRef(null)

  useEffect(() => {
    const handleResize: ResizeObserverCallback = entries => {
      for (const entry of entries) {
        const { width } = entry.contentRect
        if (lastAppWidthRef.current !== width) {
          lastAppWidthRef.current = width
          document.documentElement.style.setProperty('--app-width', `${width}px`)
        }
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(document.documentElement)

    return () => {
      if (resizeObserverRef.current)
        resizeObserver.disconnect()
    }
  }, [])

  // Return an empty fragment or null since this hook doesn't need to render anything
  return null
}
