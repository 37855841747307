import { Box, Button, Flex, Icon, IconButton } from '@chakra-ui/react'
import { BillingInvoiceStatus } from '__generated__/graphql'
import dayjs from 'dayjs'
import { isString } from 'lodash/fp'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PdfIcon, SearchIcon } from 'presentation/components/Icons'
import { UserAvatar } from 'presentation/components/UserAvatarV2'
import { Td, Tr } from 'presentation/components/molecules/Table'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { AmountCellSpacer } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/AmountCellSpacer'
import { FC, ReactNode, useCallback } from 'react'
import { formatPenniesOptionalDecUsdSafe } from 'utils/dataAdapter'
import { TransactionStatus } from '../TransactionStatus'

export const BasePaymentRow: FC<{
  dateString: string
  createdById?: string
  linkToPdf?: string
  amount?: number
  description?: string | ReactNode
  status?: BillingInvoiceStatus | ReactNode
}> = ({
  dateString,
  createdById,
  linkToPdf,
  amount,
  description,
  status,
}) => {
  const { mfv } = useMobFirstBreakpointValue()
  const isBigScreen = mfv({ mobSm: false, tabSm: true })

  const handleRowClick = useCallback(() => {
    if (!isBigScreen || !linkToPdf) return

    window.open(linkToPdf, '_blank')
  }, [linkToPdf, isBigScreen])
  const date = dayjs(dateString)

  const handleDownloadPdfClick = useCallback(() => {
    window.open(linkToPdf, '_blank')
  }, [linkToPdf])

  return (
    <Tr
      onClick={handleRowClick}
      cursor={linkToPdf ? 'pointer' : 'default'}
      smallScreenExpandedContent={(
        <>
          {description}
          {linkToPdf && (
            <Button
              variant='outline'
              colorScheme='neutral'
              onClick={handleDownloadPdfClick}
              size='sm'
              w='full'
              mt='2'
            >
              Download Pdf
            </Button>
          )}
        </>
      )}
    >
      <Td>
        <Breakpoint
          mobSm={() => <>{date.format('M/D')}</>}
          mob={() => <>{date.format('M/D/YY')}</>}
          tabSm={() => <>{date.format('M/D/YYYY')}</>}
        />
      </Td>

      <Td>
        {createdById
          ? <UserAvatar size='sm' id={createdById} />
          : <UserAvatar size='sm' isUnknown />}
      </Td>

      <Td columnType='numeric'>
        <AmountCellSpacer>
          {formatPenniesOptionalDecUsdSafe(amount)}
        </AmountCellSpacer>
      </Td>

      <Td isBigScreenOnly>
        <Flex alignItems='center' w='full'>
          <Box flex='1 0 0'>
            {description}
          </Box>

          {linkToPdf && (
            <Box display='none' _groupHover={{ display: 'block' }}>
              <Button variant='ghost' colorScheme='neutral' size='sm' rightIcon={<SearchIcon fontSize='2' />}>
                View
              </Button>
            </Box>
          )}
        </Flex>
      </Td>

      <Td>
        {isBillingInvoiceStatus(status) ? <TransactionStatus status={status} /> : status}
      </Td>

      <Td isBigScreenOnly>
        {linkToPdf && (
          <IconButton
            ml={-0.5}
            aria-label='Open PDF Modal'
            boxSize={4}
            icon={(
              <Icon
                as={PdfIcon}
                color='negative.500'
                fontSize={3}
              />
            )}
          />
        )}
      </Td>
    </Tr>
  )
}

const isBillingInvoiceStatus = (value: unknown): value is BillingInvoiceStatus =>
  isString(value) && Object.values(BillingInvoiceStatus).includes(value as BillingInvoiceStatus)
