import { Data, Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import AppCache from 'services/AppCache'

type GetOwnerDeals = (params: GetOwnerDeals.Params) => GetOwnerDeals.ResultEffect

namespace GetOwnerDeals {
  export type Params = {
    parcelContactId: string
  }

  export type Result = Data.TaggedEnum<{
    Success: {
      dealRelations: OwnersProperties.DealRelation[]
    }
  }>

  export type ResultSuccess = Extract<Result, { _tag: 'Success' }>

  export type Error = UnknownException

  export type ResultEffect = Effect.Effect<Result, Error, AppCache>

  export const Result = Data.taggedEnum<Result>()
}

export default GetOwnerDeals
