import { Box, Button, Menu, Popover, PopoverContent, PopoverTrigger, Portal, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'

export const MAP_LEGEND_MENU__BUTTON_ID = 'map-legend-menu__button'

export type MapLegendProps = PropsWithChildren<{
  isExpanded?: boolean
}>

export const MapLegend = (props: MapLegendProps) => {
  const isMobile = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  })

  return isMobile
    ? <MapLegendMenu {...props} />
    : <MapLegendPopover {...props} />
}

export const MapLegendMenu = ({
  isExpanded,
  children,
}: MapLegendProps) => {
  const [isOpen, setIsOpen] = useState(isExpanded)
  return (
    <Menu
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      colorScheme='highlight'
    >
      <Button
        data-testid={MAP_LEGEND_MENU__BUTTON_ID}
        colorScheme='highlight'
        variant='solid'
        size='md'
        w={mbp({ mobSm: '96px', tabSm: '120px' })}
        onClick={() => setIsOpen(!isOpen)}
        boxShadow='button-hovered'
      >
        Legend
      </Button>
      <ResponsiveMenuList>
        <Text color='grayweak.900' textStyle='bodyLFat' px={2}>LEGEND</Text>
        <Box px='2' py='1'>
          {children}
        </Box>
      </ResponsiveMenuList>
    </Menu>
  )
}

export const MapLegendPopover = ({
  isExpanded,
  children,
}: MapLegendProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(isExpanded)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <Popover
      placement='top-start'
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        <Button
          data-testid={MAP_LEGEND_MENU__BUTTON_ID}
          colorScheme='highlight'
          variant='solid'
          size='md'
          w={mbp({ mobSm: '96px', tabSm: '120px' })}
          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          boxShadow='button-hovered'
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            // Flip the direction of rotation
            '& .chakra-button__icon': {
              transform: 'rotate(180deg)',
            },
            'bgColor': 'card.bg.yellow',
            'color': 'graystrong.600',
          }}
        >
          Legend
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          p={0}
          bg='none'
          borderWidth={0}
        >
          <Box
            bgColor='highlight.100'
            borderWidth={0.25}
            borderStyle='solid'
            borderRadius={2}
            p={2}
            boxShadow='button-hovered'
          >
            {children}
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
