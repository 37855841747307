import { ProgramException } from 'libs/errors/ProgramException'

/**
 * Error that containers extra info for error reporting.
 *
 * Useful if you want to capture extra info for error but you want to throw
 * instead of reporting immediately so that you can handle the error only in
 * one place.
 */
const reportableExceptionTag = Symbol('reportableExceptionTag')
export class ReportableException extends ProgramException {
  [reportableExceptionTag] = true
  name = 'ReportableException'

  constructor(
    public message: string,
    public options?: {
      extraInfo?: object
      originalError?: Error
    },
  ) {
    super(message)
    this.options = options
  }

  static fromOriginalError = (error: unknown, message = '') =>
    new ReportableException(message, {
      extraInfo: { originalError: error },
    })
}
