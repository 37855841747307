import { Box } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { EmptyCard } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/EmpyCard'
import { TableSectionCommon } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.common'
import { Fragment } from 'react'
import { isNonNull } from 'remeda'

export const PreforeclosureTabContent = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const preforeclosures = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return []

    const preforeclosures = property.preforeclosures

    return preforeclosures.map(preforeclosure =>
      ({
        summaryPairs: [
          ['Recording Date', TableSectionCommon.formatDate(preforeclosure.document.recordingDate)],
          ['Default Amount', TableSectionCommon.formatDollar(preforeclosure.document.defaultAmount)],
          ['Document Type', TableSectionCommon.formatString(preforeclosure.document.type)],
          ['Auction Date', TableSectionCommon.formatDate(preforeclosure.auction?.date)],
          ['Opening Bid', TableSectionCommon.formatDollar(preforeclosure.auction?.openingBid)],
        ] satisfies TabbedCards.CardSectionProps['pairs'],
        originalLoanPairs: [
          ['Recording Date', TableSectionCommon.formatDate(preforeclosure.document.recordingDate)],
          ['Lender Name', TableSectionCommon.formatString(preforeclosure.lien?.holder?.name)],
          ['Loan Amount', TableSectionCommon.formatDollar(preforeclosure.lien?.originalAmount)],
          ['Servicer Name', TableSectionCommon.formatString(preforeclosure.lien?.servicer?.name)],
          ['Instrument No.', TableSectionCommon.formatString(preforeclosure.lien?.documentNumber)],
          // Spacer on tablet and up
          (
            <Breakpoint
              key='spacer'
              tabSm={(
                <>
                  <Box />
                  <Box />
                </>
              )}
            />
          ),
          ['Servicer Address', TableSectionCommon.formatAddress(preforeclosure.lien?.servicer?.address)],
        ] satisfies TabbedCards.CardSectionProps['pairs'],
        auctionPairs: [
          ['Auction Date', TableSectionCommon.formatDate(preforeclosure.auction?.date)],
          ['Location', TableSectionCommon.formatString(preforeclosure.auction?.location)],
          ['Time', TableSectionCommon.formatString(preforeclosure.auction?.time)],
          ['Opening Bid', TableSectionCommon.formatDollar(preforeclosure.auction?.openingBid)],
        ] satisfies TabbedCards.CardSectionProps['pairs'],
        documentType: preforeclosure.document.type,
        documentPairs: [
          ['Recording Date', TableSectionCommon.formatDate(preforeclosure.document.recordingDate)],
          ['Default Amount', TableSectionCommon.formatDollar(preforeclosure.document.defaultAmount)],
          ['Trustee Names', (
            <TableSectionCommon.MultipleStrings
              key='trustee-names'
              strings={preforeclosure.document.trusteeInfo?.names || []}
            />
          )],
          ['Instrument No.', TableSectionCommon.formatString(preforeclosure.document.documentNumber)],
          ['Case No.', TableSectionCommon.formatString(preforeclosure.document.caseNumber)],
          ['Trustee Address', (
            <TableSectionCommon.MultipleAddresses
              key='trustee-address'
              addresses={[preforeclosure.document.trusteeInfo?.address || null].filter(isNonNull)}
            />
          )],
          // Spacer on tablet and up
          (
            <Breakpoint
              key='spacer'
              tabSm={(
                <>
                  <Box />
                  <Box />
                  <Box />
                </>
              )}
            />
          ),
          ['Trustee Phone', (
            <TableSectionCommon.MultipleStrings
              key='trustee-phone'
              strings={[preforeclosure.document.trusteeInfo?.phone || null].filter(isNonNull)}
            />
          )],
        ] satisfies TabbedCards.CardSectionProps['pairs'],
      }),
    )
  })

  return (
    <TabbedCards.TabContent>
      {preforeclosures.length === 0
        ? (
          <EmptyCard />
        )
        : preforeclosures.map((preforeclosure, index) => (
          <Fragment key={index}>
            {preforeclosures.length > 1 && (
              <TabbedCards.CardLabel>
                Foreclosure Filing
                {' '}
                {index + 1}
                {' '}
                of
                {' '}
                {preforeclosures.length}
              </TabbedCards.CardLabel>
            )}

            <TabbedCards.CardSection
              label='Summary'
              upperSegmentText='Public record'
              pairs={preforeclosure.summaryPairs}
            />

            <TabbedCards.CardSection
              label='Original Loan'
              upperSegmentText='Public record'
              pairs={preforeclosure.originalLoanPairs}
            />

            <TabbedCards.CardSection
              label={preforeclosure.documentType || 'Document'}
              upperSegmentText='Public record'
              pairs={preforeclosure.documentPairs}
            />

            <TabbedCards.CardSection
              label='Auction'
              upperSegmentText='Public record'
              pairs={preforeclosure.auctionPairs}
            />
          </Fragment>
        ))}
    </TabbedCards.TabContent>
  )
}
