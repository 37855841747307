import * as S from '@effect/schema/Schema'

const ListItemSchema = S.Struct({
  id: S.String,
  name: S.optional(S.String),
  propertyUseType: S.optional(S.String),
  value: S.optional(S.Number),
  taxValue: S.optional(S.Number),
  equity: S.optional(S.Number),
  loanToValue: S.optional(S.Number),
  lastSaleDate: S.optional(S.Date),
  lastSalePrice: S.optional(S.Number),
  fips: S.optional(S.String),
  addressLine1: S.optional(S.String),
  addressCity: S.optional(S.String),
  addressCounty: S.optional(S.String),
  addressState: S.optional(S.String),
  addressZip: S.optional(S.String),
  addressLat: S.optional(S.Number),
  addressLon: S.optional(S.Number),
  isProtectedAddress: S.optional(S.Boolean),
  propertyAddressLine1: S.optional(S.String),
  propertyAddressCity: S.optional(S.String),
  propertyAddressCounty: S.optional(S.String),
  propertyAddressState: S.optional(S.String),
  propertyAddressZip: S.optional(S.String),
  propertyAddressLat: S.optional(S.Number),
  propertyAddressLon: S.optional(S.Number),
  bathsFull: S.optional(S.Number),
  bathsHalf: S.optional(S.Number),
  beds: S.optional(S.Number),
  sqft: S.optional(S.Number.pipe(S.nonNegative())),
  lotSqft: S.optional(S.Number.pipe(S.nonNegative())),
  garageSpaces: S.optional(S.Number),
  yearBuilt: S.optional(S.Number),
  stories: S.optional(S.Number),
  ownerType: S.optional(S.String),
  isIndividual: S.optional(S.Boolean),
  isHomestead: S.optional(S.Boolean),
  isSenior: S.optional(S.Boolean),
  isVacant: S.optional(S.Boolean),
  lastVacancyCheck: S.optional(S.String),
  propertyUseTypeCode: S.optional(S.Number),
  unitsCount: S.optional(S.Number),
  lastVacancyChange: S.optional(S.String),
  isForeclosure: S.optional(S.Boolean),
  isTaxDelinquent: S.optional(S.Boolean),
})

export const POSTBuildPropertyListSchema = S.Struct({
  items: S.Array(ListItemSchema),
})

export type POSTBuildPropertyListSchema = S.Schema.Type<typeof POSTBuildPropertyListSchema>
