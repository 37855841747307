import { Function, Match } from 'effect'
import { Access as AccessDomain } from 'features/Access/domain/Access.domain'
import { PanicError } from 'libs/errors/PanicError'
import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react'
import * as Zustand from 'zustand'
import { shallow } from 'zustand/shallow'

export namespace LayoutViewModel {
  // ========================================
  // Types
  // ========================================

  /**
   * Technically this should never happen because access info should've been queried
   * before the layout is rendered.
   */
  export type Unready = { status: 'unready' }
  export type Ready = {
    status: 'ready'
    accessor: Accessor
    subscription: AccessDomain.Subscription | null
  }

  export type ViewModel = Unready | Ready

  export type Subscription = AccessDomain.Subscription

  export type Accessor = {
    name: string
    avatarUrl: AccessDomain.Accessor['avatarUrl']
    logout: () => void
  }

  // ========================================
  // Constants
  // ========================================

  export const UNREADY: Unready = { status: 'unready' }

  // ========================================
  // Utilities
  // ========================================

  const createStore = (viewModel: ViewModel) =>
    Zustand.createStore<ViewModel>()(() => viewModel)

  type Store = ReturnType<typeof createStore>

  const Context = createContext<Store | null>(null)

  type ProviderProps = PropsWithChildren<{ viewModel: ViewModel }>

  export const Provider: FC<ProviderProps> = ({ children, viewModel }) => {
    const storeRef = useRef<Store>()

    const store = storeRef.current || createStore(viewModel)

    if (!storeRef.current)
      storeRef.current = store

    useEffect(() => {
      store.setState(viewModel)
    }, [viewModel])

    return (
      <Context.Provider value={storeRef.current}>
        {children}
      </Context.Provider>
    )
  }

  const useStore = () => {
    const store = useContext(Context)
    if (!store) throw new PanicError('Missing Provider in the tree')
    return store
  }

  export const useTrack = (viewModel: ViewModel) => {
    const store = useStore()

    useEffect(() => {
      store.setState(viewModel)
    }, [viewModel])
  }

  /** @IMPORTANT The values selected are shallowly memoed */
  // eslint-disable-next-line @stylistic/comma-dangle
  export const useReadySelector = <T,>(
    selector: (readyState: Ready) => T,
  ) => {
    const store = useStore()

    return Zustand.useStore(
      store,
      Function.pipe(
        Match.type<ViewModel>(),
        Match.when({ status: 'ready' }, (viewModel): T => selector(viewModel)),
        Match.orElse(() => null),
      ),
      shallow,
    )
  }
}

export type LayoutViewModel = LayoutViewModel.ViewModel
