import { addColorAlphaVariants } from '../addColorAlphaVariants'

export const v3 = {
  'accent.blue-text': '#1b7dc2',
  'accent.blue-to-gray': '#cdcdcd',
  'accent.darkblue': '#055d9c',
  'accent.darkgreen': '#28732f',
  'accent.darkpurple': '#5a3972',
  'accent.darkred': '#a91f1c',
  'accent.gold.100': '#ddb05d',
  'accent.gold.200': '#9b793a',
  'accent.gold.300': '#785920',
  'accent.green-to-gray': '#cdcdcd',
  'accent.hover': '#0c3c5e',
  'accent.lightpurple-to-gray': '#555557',
  'accent.peach-to-gray': '#cdcdcd',
  'accent.purple-to-gray': '#cdcdcd',
  'accent.red-to-gray': '#cdcdcd',
  'accent.xdarkblue': '#023960',
  'accent.xdarkgreen': '#34943d',
  'accent.xdarkpurple': '#33124f',
  'accent.xdarkred': '#620604',
  'accent.xxdarkgreen': '#025406',
  'accent.xxdarkpurple': '#1f0338',
  'accent.yellow-to-gray': '#cdcdcd',
  'card.bg.1': '#282829',
  'card.bg.2': '#3e3e3f',
  'card.bg.3': '#0c3c5e',
  'card.bg.4': '#626873',
  'card.bg.blue': '#032d4b',
  'card.bg.darkgreen': '#055a08',
  'card.bg.green': '#1c431e',
  'card.bg.lightblue': '#334c5e',
  'card.bg.lightgreen': '#3b5a3c',
  'card.bg.lightred': '#9F3F34',
  'card.bg.xlightblue': '#082e4a',
  'card.bg.xlightgreen': '#6fbf77',
  'card.bg.yellow': '#8c703b',
  'error.100': '#590100',
  'error.200': '#790100',
  'error.300': '#980200',
  'error.400': '#b80200',
  'error.500': '#cc2826',
  'error.600': '#e12b29',
  'error.700': '#eb5756',
  'error.800': '#f58786',
  'error.900': '#ffbaba',
  'graycool.100': '#121212',
  'graycool.200': '#1f1f2b',
  'graycool.300': '#303043',
  'graystrong.100': '#888888',
  'graystrong.200': '#999999',
  'graystrong.300': '#abaaaa',
  'graystrong.400': '#bcbcbc',
  'graystrong.500': '#cdcdcd',
  'graystrong.600': '#d7d7d7',
  'graystrong.700': '#e1e0e0',
  'graystrong.800': '#eaeaea',
  'graystrong.900': '#f4f4f4',
  'grayweak.100': '#141414',
  'grayweak.200': '#1e1e1e',
  'grayweak.300': '#282829',
  'grayweak.400': '#3e3e3f',
  'grayweak.500': '#4c4c4c',
  'grayweak.600': '#4b4b4b',
  'grayweak.700': '#5a5a5a',
  'grayweak.800': '#696969',
  'grayweak.900': '#787878',
  'gs.900.axs': 'rgba(244,244,244,0.1)',
  'gs.900.as': 'rgba(244, 244, 244, 0.3)',
  'gs.900.am': 'rgba(244,244,244,0.5)',
  'gs.900.aw': 'rgba(244, 244, 244, 0.7)',
  'gs.900.axw': 'rgba(244,244,244,0.9)',
  'gw.100.az': 'rgba(20, 20, 20, 0)',
  'gw.100.axs': 'rgba(20, 20, 20, 0.1)',
  'gw.100.as': 'rgba(20, 20, 20, 0.3)',
  'gw.100.am': 'rgba(20, 20, 20, 0.5)',
  'gw.100.aw': 'rgba(20, 20, 20, 0.7)',
  'gw.100.axw': 'rgba(20, 20, 20, 0.9)',
  'highlight.100': '#50390e',
  'highlight.200': '#76571d',
  'highlight.300': '#9c7733',
  'highlight.400': '#c39a4e',
  'highlight.500': '#e3c07f',
  'highlight.600': '#edc57a',
  'highlight.700': '#f0ca83',
  'highlight.800': '#f6d79e',
  'highlight.900': '#f7dfb2',
  'link.500': '#3F64E6',
  'modal.bg': '#121212',
  'modal.blur': 'rgba(57,57,57,0.7)',
  'modal.text': '#999999',
  'negative.100': '#7c0f0f',
  'negative.200': '#941918',
  'negative.300': '#ab2424',
  'negative.400': '#c33332',
  'negative.50': '#6d0d0d',
  'negative.500': '#de514e',
  'negative.600': '#e45d5c',
  'negative.700': '#ed7979',
  'negative.800': '#f69797',
  'negative.900': '#ffb7b7',
  'neutral.100': '#002036',
  'neutral.200': '#0c3c5e',
  'neutral.300': '#064673',
  'neutral.400': '#08578f',
  'neutral.500': '#1b7dc2',
  'neutral.600': '#2d7eb8',
  'neutral.700': '#5496c4',
  'neutral.800': '#81afd1',
  'neutral.900': '#94b5cd',
  'ondark.1': '#f0f0f0',
  'ondark.2': '#bababa',
  'ondark.3': '#e4e4e4',
  'ondark.4': '#cdcdcd',
  'ondark.5': '#bebebe',
  'ondark.6': '#888888',
  'onlight.1': '#4b4b4b',
  'onlight.2': '#3c3c3c',
  'onlight.3': '#353535',
  'overlay.dark-am': 'rgba(0, 0, 0, 0.5)',
  'plans.advance': '#9473b1',
  'plans.intermediate': '#54a15b',
  'positive.100': '#055a08',
  'positive.200': '#117216',
  'positive.300': '#218a28',
  'positive.400': '#36a13e',
  'positive.50': '#022502',
  'positive.500': '#519958',
  'positive.600': '#7bbb81',
  'positive.700': '#8fda96',
  'positive.800': '#b4ebba',
  'positive.900': '#ddfbe1',
  'positivesat.100': '#003a03',
  'positivesat.200': '#025406',
  'positivesat.300': '#025406',
  'positivesat.400': '#09870e',
  'positivesat.500': '#119417',
  'positivesat.600': '#30b036',
  'positivesat.700': '#57c05b',
  'positivesat.800': '#83cf86',
  'positivesat.900': '#b4deb6',
  'special.100': '#3b224e',
  'special.200': '#3b224e',
  'special.300': '#3b224e',
  'special.400': '#3b224e',
  'special.50': '#3b224e',
  'special.500': '#3b224e',
  'special.600': '#6e4a8c',
  'special.700': '#9675b3',
  'special.800': '#c3a9d9',
  'special.900': '#f4e7ff',
  'specialsat.100': '#552f75',
  'specialsat.200': '#5f3583',
  'specialsat.300': '#6a3b92',
  'specialsat.400': '#7541a0',
  'specialsat.50': '#4a2966',
  'specialsat.500': '#7f47ae',
  'specialsat.600': '#9766c2',
  'specialsat.700': '#b289d6',
  'specialsat.800': '#cfb1eb',
  'specialsat.900': '#efdeff',
  'teal.100': '#002023',
  'teal.200': '#032f34',
  'teal.300': '#0f464c',
  'teal.400': '#205d63',
  'teal.50': '#001d20',
  'teal.500': '#4f9097',
  'teal.600': '#6e9ea2',
  'teal.700': '#6ca9af',
  'teal.800': '#8bc5ca',
  'teal.900': '#bcdde1',
  'trueorange.100': '#9b6501',
  'trueorange.200': '#ae7204',
  'trueorange.300': '#c98303',
  'trueorange.400': '#e99701',
  'trueorange.500': '#ffa500',
  'warm.100': '#562006',
  'warm.200': '#76300c',
  'warm.300': '#974215',
  'warm.400': '#b7551f',
  'warm.500': '#d86b2c',
  'warm.600': '#e07f47',
  'warm.700': '#e89464',
  'warm.800': '#f0aa82',
  'warm.900': '#f9caaf',
  'mixkey.blue': '#81AFD1',
  /**
   * ===========================
   * Marker Colors
   * ===========================
   */
  'marker.teal': '#21666e',
  'marker.sky': '#9ad1d6',
  'marker.olivegreen': '#b5c365',
  'marker.yellowgreen': '#4c522e',
  'marker.carolinablue': '#a0c5da',
  'marker.lightblue': '#3f6c8d',
  'marker.darkgreen': '#DEFBE1',
  'marker.lightgreen': '#255630',
  'marker.darkpink': '#7b211e',
  'marker.scarlet': '#d5a6a5',
  'marker-mlstag.scarlet': '#F69797',
  'marker.gold': '#d5ba72',
  'marker.yellow': '#6f592f',
  'marker.cobaltblue': '#F0F0F0',
  'marker.strawberrypink': '#F0F0F0',
  'marker.white': '#f0f0f0',
  'marker.mintgreen': '#519958',
  'marker.babyblue': '#1B7DC2',
  'marker.oxfordblue': '#E0F2FF',
  'marker.pink': '#8D2622',
  'marker.gray': '#4B4B4B',
  'marker.darkgray': '#ABAAAA',
  'marker.dirtywhite': '#353535',
  'marker.lightpurple': '#6E4A8C',
  'marker.darkpurple': '#F4E7FF',
  'marker.cream': '#785920',
  'marker.brown': '#FFDC9B',
  'marker.maroon': '#F0C9C6',
  'marker.white-to-blue': '#3F64E6',
  'marker.white-to-red': '#DE514E',
  'marker.dirty-white': '#CDCDCD',
}

const v2 = {
  'link': {
    500: '#2f80ed',
  },

  'grayWeak': {
    100: '#141414',
    200: '#1E1E1E',
    300: '#2A2D32',
    400: '#323232',
    500: '#4C4C4C',
    600: '#4B4B4B',
    700: '#5A5A5A',
    800: '#696969',
    900: '#787878',
  },

  'grayStrong': {
    100: '#888888',
    200: '#999999',
    300: '#ABAAAA',
    400: '#BCBCBC',
    500: '#CDCDCD',
    600: '#D7D7D7',
    700: '#E1E0E0',
    800: '#EAEAEA',
    900: '#F4F4F4',
  },

  /** @deprecated Use grayWeak or grayStrong instead */
  'gray': {
    100: '#111111',
    200: '#1D1D1D',
    300: '#3A3A3A',
    400: '#505050',
    500: '#616161',
    600: '#7D7D7D',
    700: '#D1D1D1',
    800: '#E5E5E5',
    900: '#EEEEEE',
  },

  'neutral': {
    100: '#03243B',
    200: '#053557',
    300: '#064673',
    400: '#08578F',
    500: '#0770BA',
    600: '#2D7EB8',
    700: '#5496C4',
    800: '#81AFD1',
    900: '#94B5CD',
  },

  'positive': {
    50: '#022502',
    100: '#055A08',
    200: '#117216',
    300: '#218A28',
    400: '#36A13E',
    500: '#4FB959',
    600: '#6DC976',
    700: '#8FDA96',
    800: '#B4EBBA',
    900: '#DDFBE1',
  },

  'negative': {
    50: '#FFE0DF',
    100: '#7C0F0F',
    200: '#941918',
    300: '#AB2424;',
    400: '#C33332',
    500: '#FF4341',
    600: '#E45D5C',
    700: '#ED7979',
    800: '#F69797',
    900: '#FFB7B7',
  },

  'highlight': {
    100: '#50390E',
    200: '#76571D',
    300: '#9C7733',
    400: '#C39A4E',
    500: '#FFCF77',
    600: '#EDC57A',
    700: '#F0CA83',
    800: '#F6D79E',
    900: '#F7DFB2',
  },

  'special': {
    100: '#380D5C',
    200: '#471B6F',
    300: '#53257C',
    400: '#5C2F85',
    500: '#4A2966',
    600: '#6E4A8C',
    700: '#9675B3',
    800: '#C3A9D9',
    900: '#F4E7FF',
  },

  'special-sat': {
    50: '#4A2966',
    100: '#552F75',
    200: '#5F3583',
    300: '#6A3B92',
    400: '#7541A0',
    500: '#7F47AE',
    600: '#9766C2',
    700: '#B289D6',
    800: '#CFB1EB',
    900: '#EFDEFF',
  },

  'error': {
    100: '#590100',
    200: '#790100',
    300: '#980200',
    400: '#B80200',
    500: '#CC2826',
    600: '#E12B29',
    700: '#EB5756',
    800: '#F58786',
    900: '#FFBABA',
  },

  'positivesat': {
    100: '#003A03',
    200: '#025406',
    300: '#056E0A',
    400: '#09870E',
    500: '#119417',
    600: '#30B036',
    700: '#57C05B',
    800: '#83CF86',
    900: '#B4DEB6',
  },

  'warm': {
    100: '#562006',
    200: '#76300C',
    300: '#974215',
    400: '#B7551F',
    500: '#D86B2C',
    600: '#E07F47',
    700: '#E89464',
    800: '#F0AA82',
    900: '#F9CAAF',
  },

  'teal': {
    50: '#001D20',
    100: '#002023',
    200: '#032F34',
    300: '#0F464C',
    400: '#205D63',
    500: '#36747B',
    600: '#559499',
    700: '#6CA9AF',
    800: '#8BC5CA',
    900: '#background: #BCDDE1;',
  },
}

const darkColorsLegacy = {
  /**
   * Foundation colors
   */
  // Primary
  'pri': '#DB4342',
  'pri-1': '#C24444',
  'pri-2': '#F36C6A',
  'pri-3': '#FFB7B7',
  'pri-4': '#FFDFDF',
  'pri-5': '#371B1B',
  'pri-6': '#7C0F0F',

  // Secondary
  'sec': '#0A68AB',
  'sec-1': '#2E5571',
  'sec-2': '#398DC8',
  'sec-3': '#A2D3F5',
  'sec-4': '#E5F1F9',
  'sec-5': '#1D2A35',
  'sec-6': '#19252E',
  'sec-7': '#03253D',

  // Tertiary
  'ter': 'rgb(233, 190, 111, 1)',
  'ter-1': '#BA9244',
  'ter-2': '#2E2516',
  'ter-3': '#EBC784',
  'ter-70': 'rgb(255, 207, 119, 0.70)',
  'ter-50': 'rgb(255, 207, 119, 0.50)',
  'ter-40': 'rgb(255, 207, 119, 0.40)',
  'ter-30': 'rgb(255, 207, 119, 0.30)',
  'ter-20': 'rgb(255, 207, 119, 0.20)',
  'ter-10': 'rgb(255, 207, 119, 0.10)',
  'ter-0': 'rgb(233, 190, 111, 0)',

  // Accent
  'acc': '#462860',
  'acc-1': '#6D2FA3',
  'acc-2': '#645DBA',
  'acc-3': '#EDDFF9',
  'acc-4': '#B2BCC4',
  'acc-5': '#CCE1F0',
  'acc-6': '#E1ECF3',

  // Quaternary
  'quat': '#119417',
  'quat-70': 'rgba(15, 161, 21, 0.70)',
  'quat-50': 'rgba(15, 161, 21, 0.50)',
  'quat-40': 'rgba(15, 161, 21, 0.40)',
  'quat-30': 'rgba(15, 161, 21, 0.30)',
  'quat-20': 'rgba(15, 161, 21, 0.20)',
  'quat-10': 'rgba(15, 161, 21, 0.10)',
  'quat-0': 'rgb(17, 148, 23, 0)',

  /**
   * Status colors
   */
  'error': '#d70300',
  'error-4': '#FFDFDF',

  'success': '#119417',
  'success-1': '#1D9C29',
  'success-2': '#72C77A',
  'success-3': '#B0DBB4',
  'success-4': '#DEFBE1',
  'success-5': '#4EA526',
  'success-6': '#A3AB3E',
  'success-7': '#055A08',

  'info': 'rgba(7, 112, 186, 1)',
  'info-70': 'rgba(7, 112, 186, 0.70)',
  'info-50': 'rgba(7, 112, 186, 0.50)',
  'info-40': 'rgba(7, 112, 186, 0.40)',
  'info-30': 'rgba(7, 112, 186, 0.30)',
  'info-20': 'rgba(7, 112, 186, 0.20)',
  'info-10': 'rgba(7, 112, 186, 0.10)',

  'link': '#2f80ed',

  /**
   * Misc
   */
  // Font
  'font-1': '#FFFFFF',
  'font-2': '#D2D3D4',
  'font-3': '#8F9093',
  'font-4': '#636467',
  'font-5': '#36383C',
  'font-6': '#C3C3C4',
  'font-7': '#8D8E90',

  // Gray Shades
  'fg': 'rgba(0, 0, 0, 1)',
  'fg-900': 'rgba(0, 0, 0, 0.70)',
  'fg-800': 'rgba(0, 0, 0, 0.50)',
  'fg-700': 'rgba(0, 0, 0, 0.40)',
  'fg-600': 'rgba(0, 0, 0, 0.30)',
  'fg-500': 'rgba(0, 0, 0, 0.20)',
  'fg-400': 'rgba(0, 0, 0, 0.10)',
  'fg-300': 'rgba(0, 0, 0, 0.05)',

  // `-s` means solid
  'fg-900-s': '#4C4C4C',
  'fg-800-s': '#7F7F7F',
  'fg-600-s': '#B1B1B1',
  'fg-500-s': '#CACACA',
  'fg-400-s': '#E3E3E3',
  'fg-300-s': '#EFEFEF',
  'fg-200-s': '#F5F6F4',

  'bg': '#202226',
  'bg-900': '#36383C',
  'bg-800': '#4D4E51',
  'bg-700': '#636467',
  'bg-600': '#797A7D',
  'bg-500': '#8F9093',
  'bg-400': '#A6A7A8',
  'bg-300': '#BCBDBE',
  'bg-200': '#D2D3D4',
  'bg-100': '#E9E9E9',

  'main-bg': '#202226',

  // Extra background colors
  'bg-screen': '#171724',
  'bg-bubble': '#171724',
  'bg-modal-highlight': '#2A2D32',
  'bg-card/divider': '#ABAAAA',
  'bg-sbmenu': '#200E30',
  'bg-icon-outline-light': '#f7f7f7',
  'bg-landingpage-special': '#33124F',
  'bg-accent': '#0770BA',

  // Extra text colors
  'text-primary-xstrong': '#F7F7F7',
  'text-primary-strong': '#CDCDCD',
  'text-onbutton/banner-xstrong': '#F7F7F7',
  'text-primary-neutral': '#ABAAAA',
  'text-highlight-neutral': '#FFDB99',
  'onhighlight-text-xstrong': 'rgba(15, 15, 15, 0.8)',

  // Extra colors
  'l.gw100-graymid': '#34373e',
  'darkblue-brightblue': '#2060FE',
  'xlightblue-d.gw300': '#2A2D32',
  'xlightblue-grayxdark': '#171724',
  'lightblue-newpurple': '#361851',
  'l.n500-brightblue': '#2060FE',
  'l.gs200-d.gs300': '#ABAAAA',
  'l.gs100-d.gs300': '#ABAAAA',
  'l.s300-d.s500': '#4A2966',
  'l.gw200-d.gs300': '#ABAAAA',
  'gs900-alphamid': 'rgba(0, 0, 0, 0.5)',
  'bg-button-go-active': '#4FB959',
  'l.h500-d.gw300': '#2A2D32',
  'l.gw200-l.gw200': '#F7F7F7',
  'l.gs300-graymid': '#34373E',
  'l.gw600-graydark': '#21252F',
  'l.gw300-l.gw300': '#E3E3E3',
  'l.gs200-d.gw500': '#4C4C4C',
  'l.neg500-d.neg800': '#FF4341',
  'l.gs500-d.gs700': '#E1E0E0',
  'l.p50-lightgreen': '#87C574',
  'l.gs200-d.gs700': '#e1e0e0',
  'l.gw300-graymid': '#34373E',
  'text-header-neutral-xstrong': '#013281',
  'l.gs400-d.gs700': '#E1E0E0',
  'l.s500-lightpurple': '#D9CAE8',
  'lightpurple-lightpurple': '#D9CAE8',
  'l.gs500-l.gw100Axw': '#FFFFFF',
  'l.n500-d.gs500': '#CDCDCD',
  'l.gw100-graydark': '#2A2D32',
  'l.gs500-d.gs300': '#ABAAAA',
  'l.gs400-d.gs300': '#ABAAAA',
  'l.gw200-l.gw100': '#FFFFFF',
  'l.gs200-d.gw900': '#787878',
  'l.n500-brightpurple': '#B554FE',
  'l.s700-l.s700': '#33124F',
  'l.n200-d.s500': '#4D296D',
  'l.p600-d.p500': '#4FB959',
  'l.gw500-d.gw800': '#696969',
  'l.n500-d.h500': '#FFCF77',
  'l.h500-mudyellow': '#E0AD2D',
  'l.gw100-l.gw100': '#FFFFFF',

  // modals
  'modal-backdrop': 'rgba(74, 41, 102, 0.95)',
  'modal-success-bg': '#87C574',
  'modal-success-border': '#87C574',
  'l.gw100-graylight': '#484D56',
  'l.gw500-d.gs500': '#CDCDCD',
  'l.s500-brightpurple': '#B554FE',
  'l.gs200-d.gs500': '#CDCDCD',
  'l.h500-graymid': '#FFCF77',
  'l.h800-l.h800': '#997531',
  'l.gs500-d.p300': '#218A28',
  'l.gs400-d.gw600': '#4B4B4B',
}

export const darkColors = {
  v2: addColorAlphaVariants(v2),
  ...darkColorsLegacy,
  ...v3,
}
