/**
 * 1 unit = 8 pixels, this fn just multiply numbers by 8
 * Grid of 8 Unit.
 *
 * @GOTCHA
 *   GRID_OF_8 used in theme from layout.const.ts increase increments in later units (common
 *   practice with theme values scaling), while this function has consistent increment of 8 (pixels)
 *
 *   e.g.
 *   GRID_OF_8[8] // 96px
 *   g8Unit(8) // 64px
 */
export const g8Unit = (x: number): number => x * 8
