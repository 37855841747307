import type { RouterState } from '@remix-run/router/dist/router.d.ts'
import { RouterExposer } from 'presentation/main/Router/RouterExposer'
import { create } from 'zustand'

type FromSearchOrDirectScenario = {
  type: 'from-search-or-direct'
}

type FromOtherModulesScenario = {
  type: 'from-other-modules'
  goBackToOriginButton: {
    label: string
    onClick: () => void
  }
  checkShouldResetOnNavigate: (routerState: RouterState) => boolean
}

type Scenario = FromSearchOrDirectScenario | FromOtherModulesScenario

type PropertyDrawerContentState = {
  scenario: Scenario
  setScenario: (scenario: Scenario) => void
}

export const usePropertyDrawerContentState = create<PropertyDrawerContentState>(set => ({
  scenario: { type: 'from-search-or-direct' },
  setScenario: (scenario: Scenario) => {
    set({ scenario })

    if (scenario.type === 'from-other-modules') {
      RouterExposer
        .get()
        .then(router => {
          const unsubscribe = router.subscribe(state => {
            const shouldReset = scenario.checkShouldResetOnNavigate(state)
            if (shouldReset) {
              set({ scenario: { type: 'from-search-or-direct' } })
              unsubscribe()
            }
          })
        })
    }
  },
}))
