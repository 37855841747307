import { Box, Flex, Grid, GridItem, Text, chakra } from '@chakra-ui/react'
import classNames from 'classnames'
import { TableIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Tooltip } from 'presentation/components/Tooltip'
import { useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { CompsMarkerPopupProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarkerPopup/CompsMarkerPopup.props'
import { getMarkerPopupColors } from 'presentation/screens/CompsScreen/components/CompsMap/components/utils/getMarkerPopupColors'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { toBedBathsLivingSqft } from 'presentation/screens/CompsScreen/formatters/toBedBathsBldngSqft'
import { animationClasses } from 'presentation/utils/animationClasses'

export const CompsMarkerPopup = (props: CompsMarkerPopupProps) => {
  const viewValue = useResponsiveViewValue()
  const isTableView = viewValue === 'TABLE_VIEW'
  const markerPopupColors = getMarkerPopupColors(props.status)

  const bedsBathsSizeFormat = toBedBathsLivingSqft(props)

  const handleOnFullscreen = () => {
    props.onPopupPrimaryActionClick?.(props.id)
  }

  return (
    <Box
      onClick={handleOnFullscreen}
      borderColor={markerPopupColors.cardBorderColor}
      bgColor={markerPopupColors.cardBgColor}
      borderWidth={0.125}
      borderRadius={3}
      p={2}
      w='full'
      position='relative'
      shadow='float'
      className={classNames(animationClasses.fadeIn, animationClasses.faster)}
      cursor='pointer'
    >
      <Box
        position='absolute'
        top='-14px'
        right='-14px'
        zIndex='4'
        borderRadius='100px'
        bgColor='ondark.1'
        borderColor={markerPopupColors.fullScreenBorderColor}
        borderWidth={0.125}
        p={1}
        onClick={ev => {
          ev.stopPropagation()
          handleOnFullscreen()
        }}
        cursor='pointer'
      >
        {isTableView
          ? (
            <TableIcon
              color={markerPopupColors.fullScreenIconColor}
              boxSize={2.5}
              display='block'
            />
          )
          : (
            <FullscreenIcon
              color={markerPopupColors.fullScreenIconColor}
            />
          )}
      </Box>

      {/** HEADER */}
      <Flex
        borderTopRadius={2}
        p={1}
        pl={2}
        bgColor={markerPopupColors.headerBgColor}
        gap={1}
        alignItems='center'
      >
        {/* @GENESIS Current comps service does not support this */}
        {/* <PropertyMarker
          markerType='pin'
          classification={props.classification}
          equityType={props.equityType}
          isVacant={props.isVacant}
          isForeclosure={props.isForeclosure}
          isSenior={props.isSenior}
          size='xs'
        /> */}
        <Flex
          minW={0}
          flex='1'
          flexDirection='column'
          color={markerPopupColors.textColor}
        >
          <Tooltip label={props.address.line1}>
            <Text
              textStyle='bodyMFat'
              isTruncated
              color='inherit'
              mr={1}
            >
              {props.address.line1}
            </Text>
          </Tooltip>
          <Text
            textStyle='bodyM'
            color='inherit'
          >
            {bedsBathsSizeFormat}
          </Text>
        </Flex>
        <CompsThumbButtons
          comp={props}
          onCompsRatingChange={props.onCompsRatingChange}
          ml='auto'
        />
      </Flex>

      <Grid
        gridTemplateAreas={'"layer"'}
        borderBottomRadius={2}
        overflow='hidden'
      >

        <PhotosSlider
          gridArea='layer'
          minW={0}
          zIndex='1'
          photos={props.photos}
          shouldOpenFullscreenOnSlideClick={false}
        />

        <GridItem
          gridArea='layer'
          alignSelf='end'
          justifySelf='end'
          zIndex='2'
          mr={1}
          mb={1}
        >
          <MLSStatusTag
            status={props.status}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

const FullscreenIcon = (props: {
  color: string
}) => (
  <chakra.svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    display='block'
  >
    <g id='ICON OUTLINE'>
      <chakra.path
        id='Vector'
        d='M6.66667 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V6.66667M17.5 6.66667V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5H13.3333M13.3333 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V13.3333M2.5 13.3333V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H6.66667'
        stroke={props.color}
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </chakra.svg>
)
