import { FlexProps } from '@chakra-ui/react'
import { toPennies } from 'libs/Number/formatNumber'
import { CardButton, CardFooter, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { PaymentDetailsModal } from 'presentation/main/globalModals/PaymentDetailsModal'
import { BillingSummaryItem } from 'presentation/main/globalModals/PaymentDetailsModal/BillingSummarySegment'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { useAddBalanceMutation } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/useWalletAddBalanceMutation'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { WalletPaymentDetailsModalProps } from './WalletPaymentDetailsModal.types'

export const WalletPaymentDetailsModal: FC<WalletPaymentDetailsModalProps> = ({
  isOpen = true,
  onClose,
  amount,
}) => {
  const { addBalance, loading } = useAddBalanceMutation()

  const billingSummaryItems: BillingSummaryItem[] = [{
    label: 'Add to my wallet',
    price: toPennies(amount),
  }]

  const handlePayNow = () => {
    addBalance(amount)
  }

  const modalHeader = (
    <>
      <CardPrimaryTitle>
        Add Marketing Funds
      </CardPrimaryTitle>
      <CardPrimaryText
        mt={mbp({ mobSm: '1', tabSm: '2' })}
        w={mbp({
          mobSm: 'full',
          tabSm: '84%',
          tab: '68%',
          dtSm: '80%',
        })}
      >
        You are adding marketing funds to your account.
        These funds can be used for mailers, skiptraces, and other services.
      </CardPrimaryText>
    </>
  )

  return (
    <PaymentDetailsModal
      title='Add Funds'
      modalHeader={modalHeader}
      shouldShowChangePlanButton={false}
      isOpen={isOpen}
      billingSummaryItems={billingSummaryItems}
      onClose={onClose}
      modalFooter={params => (
        <PrimaryActions
          isLoading={loading}
          isPrimaryButtonDisabled={!params?.hasPaymentMethod}
          onConfirm={handlePayNow}
        />
      )}
    />
  )
}

type PrimaryActionsProps = FlexProps & {
  isLoading?: boolean
  onConfirm?: () => void
  isPrimaryButtonDisabled?: boolean
}

const PrimaryActions: FC<PrimaryActionsProps> = ({
  onConfirm,
  isLoading,
  isPrimaryButtonDisabled,
}) => (
  <CardFooter>
    <CardButton
      variant='outline'
      colorScheme='neutral'
      onClick={() => goBackModal()}
    >
      Cancel
    </CardButton>
    <CardButton
      variant='solid'
      colorScheme='positive'
      isDisabled={isPrimaryButtonDisabled}
      isLoading={isLoading}
      onClick={onConfirm}
      loadingText='Processing'
      spinnerPlacement='end'
      autoFocus
    >
      Pay Now
    </CardButton>
  </CardFooter>
)
