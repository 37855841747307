import { createIcon } from '@chakra-ui/react'

const SolidRocketIcon = createIcon({
  displayName: 'SolidRocketIcon',
  viewBox: '0 0 56 57',
  path: (
    <g fill='none'>
      <path
        d='M51.2475 4.99875C51.2279 4.90202 51.1818 4.81298 51.1144 4.74208C51.047 4.67118 50.9612 4.62135 50.8671 4.59844C44.5917 3.03219 30.0918 8.6136 22.236 16.6297C20.8348 18.0485 19.5575 19.5892 18.4184 21.2344C15.9959 21.0156 13.5734 21.1983 11.5087 22.117C5.68324 24.7344 3.98714 31.5638 3.51463 34.5016C3.48783 34.6624 3.49794 34.8273 3.54417 34.9834C3.59039 35.1396 3.67147 35.2826 3.78103 35.4014C3.89059 35.5201 4.02564 35.6113 4.17555 35.6677C4.32547 35.7241 4.48617 35.7443 4.64501 35.7266L13.9998 34.6733C14.0065 35.3933 14.049 36.1124 14.1273 36.828C14.1744 37.3249 14.3905 37.7893 14.738 38.1405L18.3606 41.8297C18.7049 42.184 19.1597 42.4045 19.6463 42.4531C20.3433 42.5327 21.0439 42.5762 21.7453 42.5833L20.7188 52.1208C20.7017 52.2829 20.7216 52.4468 20.7769 52.5997C20.8323 52.7527 20.9216 52.8904 21.0379 53.0022C21.1541 53.114 21.2942 53.1968 21.447 53.2441C21.5999 53.2913 21.7614 53.3018 21.9188 53.2747C24.7914 52.8044 31.4933 51.073 34.0422 45.1263C34.9422 43.0186 35.1265 40.5577 34.9187 38.0967C36.5342 36.9339 38.0475 35.6296 39.4412 34.1986C47.3217 26.1945 52.7582 11.7231 51.2475 4.99875ZM31.579 24.6502C30.8594 23.9161 30.3692 22.9806 30.1705 21.9621C29.9718 20.9436 30.0735 19.8878 30.4626 18.9283C30.8518 17.9688 31.511 17.1487 32.3568 16.5717C33.2026 15.9946 34.1971 15.6866 35.2144 15.6866C36.2317 15.6866 37.2262 15.9946 38.072 16.5717C38.9178 17.1487 39.577 17.9688 39.9662 18.9283C40.3553 19.8878 40.457 20.9436 40.2583 21.9621C40.0595 22.9806 39.5694 23.9161 38.8498 24.6502C38.3726 25.138 37.806 25.5249 37.1822 25.789C36.5584 26.053 35.8897 26.1889 35.2144 26.1889C34.5391 26.1889 33.8704 26.053 33.2466 25.789C32.6228 25.5249 32.0561 25.138 31.579 24.6502Z'
        fill='currentColor'
      />
      <path
        d='M18.4187 44.5006C17.8194 45.1011 16.858 45.3352 15.7008 45.5353C13.1009 45.9783 10.8052 43.7317 11.2722 41.1035C11.4505 40.1071 11.9777 38.7103 12.3058 38.3822C12.3775 38.3119 12.4252 38.2207 12.4422 38.1217C12.4592 38.0227 12.4445 37.9209 12.4003 37.8307C12.3561 37.7405 12.2847 37.6665 12.196 37.6192C12.1074 37.5719 12.0061 37.5538 11.9066 37.5674C10.4527 37.7453 9.10015 38.4044 8.06422 39.4399C5.49281 42.0135 5.25 51.563 5.25 51.563C5.25 51.563 14.805 51.3202 17.3764 48.7466C18.4151 47.7115 19.0751 46.3569 19.25 44.901C19.2905 44.4438 18.7337 44.1714 18.4187 44.5006Z'
        fill='currentColor'
      />
    </g>
  ),
})

export default SolidRocketIcon
