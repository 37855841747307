import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { Function, Match } from 'effect'
import { getPlanRank } from 'features/Billing/Plans/getPlanRank'
import { DateLib } from 'libs/Date'
import { CQ } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ReportableException } from 'libs/errors/ReportableException'
import { ErrorLib, ProgramException } from 'libs/errors/errors'
import { GENERIC_ERROR } from 'libs/errors/errors.const'
import { object } from 'libs/object'
import { Member, Plan, TeamInfo, TeamInfoQuery } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { useMemo } from 'react'

export const MembersScreenQuery = gql(/* GraphQL */ `
  query MembersScreenQuery {
    billingSubscriptionProducts {
      __typename
      ...PlanRank_Product
    }
    me {
      id
    }
    myEnterprise {
      ...EnterpriseMembers

      owner {
        id
      }

      subscription {
        ... on BillingSubscriptionActive {
          __typename
          plan {
            id
            price
            interval {
              unit
            }
            product {
              name
              features {
                ... on SubscriptionFeatureTeam {
                  limits {
                    value
                  }
                }
              }
            }
          }
        }

        ... on BillingSubscriptionInactive {
          __typename
          plan {
            id
            price
            interval {
              unit
            }
            product {
              name
              features {
                ... on SubscriptionFeatureTeam {
                  limits {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)

/**
 * @TODO Rename to useMembersInfoQuery?
 * @TODO Use QueryHook type
 * @TODO Consider missing `me` or enterprise `owner` to be unexpected result as well
 * @TODO Error report if memberSince date is missing
 */
export const useMembersInfoQuery = (): TeamInfoQuery => {
  const gqlQuery = useQuery(MembersScreenQuery)

  const queryId = CQReact.useResultWatchId({
    data: gqlQuery.data || null,
    error: gqlQuery.error || null,
  })
  const cq = CQReact.useStateManager<TeamInfo, ProgramException>(() =>
    CQ.createLoadingState({ id: queryId }))

  const state: TeamInfoQuery['state'] = useMemo(() =>
    Function.pipe(
      Match.value([
        gqlQuery.data,
        gqlQuery.loading,
        gqlQuery.error,
      ]),

      Match.when([Match.any, true, Match.any], () => cq.transition.loading({ id: queryId })),

      Match.when([
        {
          myEnterprise: {
            owner: {},
            users: {
              edges: [{}],
            },
            subscription: {
              plan: {
                id: Match.string,
              },
            },
          },
        },
        Match.any,
        Match.any,
      ], ([data]) => {
        const members: Member[] = data.myEnterprise.users.edges.map(edge => {
          const member = object.undefinedPropsToNull({
            id: edge.node.id,
            firstName: edge.node.firstname,
            lastName: edge.node.lastname,
            email: edge.node.email,
            phone: edge.node.phone,
            memberSince: DateLib.maybeFromUnknown(edge.node.createdAt),
            isEnabled: edge.node.isEnabled,
            isEmailConfirmed: edge.node.isEmailConfirmed,
            avatarUrl: edge.node.avatar?.profileImageUrl,
          })

          return member
        }) || null

        const owner = members
          .find(member => member.id === data?.myEnterprise?.owner?.id) || null
        const me = members
          .find(member => member.id === data?.me?.id) || null

        const activeSub = data.myEnterprise.subscription
        const subProduct = activeSub.plan.product
        const allProducts = data?.billingSubscriptionProducts
        const plan: Plan | null = subProduct
          ? object.undefinedPropsToNull({
            name: subProduct.name,
            limit: data.myEnterprise.subscription.plan.product.features
              .find((feature): feature is ({
                __typename?: 'SubscriptionFeatureTeam' | undefined
                limits: {
                  __typename?: 'SubscriptionFeatureIntervalValue' | undefined
                  value: number
                }[]
              }) =>
                feature.__typename === 'SubscriptionFeatureTeam',
              )
              ?.limits[0]?.value ?? Infinity,
            rank: allProducts ? getPlanRank(allProducts, activeSub.plan.id) : null,
            price: activeSub.plan.price,
            interval: activeSub.plan.interval.unit,
          })
          : null

        const arePartsComplete = !!members && !!plan && !!owner && !!me

        if (!arePartsComplete) {
          void ErrorLib.report(new ReportableException('Unexpected response', {
            extraInfo: {
              data,
              loading: gqlQuery.loading,
              error: gqlQuery.error,
            },
          }))

          return cq.transition.error({ id: queryId, error: GENERIC_ERROR })
        }

        return cq.transition.success({
          id: queryId,
          data: { members, plan, owner, me },
        })
      }),

      Match.orElse(() => {
        void ErrorLib.report(new ReportableException('Unexpected response', {
          extraInfo: {
            data: gqlQuery.data,
            loading: gqlQuery.loading,
            error: gqlQuery.error,
          },
        }))

        return cq.transition.error({ id: queryId, error: GENERIC_ERROR })
      }),
    ), [
    queryId,
    gqlQuery.data,
    gqlQuery.loading,
    gqlQuery.error,
  ])

  return { state }
}
