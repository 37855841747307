const PRESETS: number[] = [
  0.25,
  0.5,
  0.75,
  1,
  1.5,
  2,
  3,
  4,
  5,
  7.5,
  10,
  15,
]

const ANY_MILE = 'Any Mile'

const toLabel = (miles: number | null): string => {
  if (miles === null)
    return ANY_MILE

  return (miles > 1) ? `${miles} miles` : `${miles} mile`
}

const findClosest = (numbers: number[]) => (target: number): number | null => {
  const closest = numbers.reduce((prev, curr) => {
    const prevDiff = Math.abs(prev - target)
    const currDiff = Math.abs(curr - target)
    return (currDiff < prevDiff) ? curr : prev
  })
  return closest
}

const roundOffThenToLabel = (max: number): string => {
  const closest = findClosest(PRESETS)(max)
  return toLabel(closest)
}

const findByIndex = (index: number): number | null =>
  PRESETS[index] ?? null

const doubleWithinPreset = (miles: number): number =>
  findClosest(PRESETS)(miles * 2) || 15

export const Miles = {
  getOptions: () => PRESETS,
  roundOffThenToLabel,
  toLabel,
  findByIndex,
  any: ANY_MILE,
  doubleWithinPreset,
}
