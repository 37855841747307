import { createIcon } from '@chakra-ui/react'

export const ArrowUpIcon = createIcon({
  displayName: 'ArrowUpIcon',
  viewBox: '0 0 24 24',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M12 19V5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5 12L12 5L19 12' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
