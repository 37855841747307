import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import cn from 'classnames'
import { fadeIn, fadeInDown, fast, faster } from 'presentation/utils/animationClasses'
import { PlansHeading, PlansSubheading, PlansSubheadingEmphasis } from 'presentation/screens/Plans/PlansTypography'
import { VStack } from '@chakra-ui/react'
import SectionBannerViewModel from './SectionBannerViewModel'

const SectionBanner = () => {
  const vm = SectionBannerViewModel.usePresenter()
  return (
    <VStack>
      <ScrollAnimation
        className={cn(fadeInDown, fast)}
      >
        <PlansHeading color='graystrong.500' px={{ mob: '5', tabSm: '0' }}>
          Match your team&rsquo;s needs to the right plan
        </PlansHeading>
      </ScrollAnimation>

      <ScrollAnimation
        className={cn(fadeIn, faster)}
        sx={{
          animationDelay: '0.3s',
        }}
      >
        <PlansSubheading color='graystrong.500'>
          Pay annually and
          {' '}
          <PlansSubheadingEmphasis color='accent.blue-text'>
            save up to
            {' '}
            {vm.priceText}
            !
          </PlansSubheadingEmphasis>
        </PlansSubheading>
      </ScrollAnimation>
    </VStack>
  )
}

export default SectionBanner
