import { Funds as Domain } from 'features/Funds/domain/Funds.domain'
import { nanoid } from 'nanoid'
import { ReactNode, createContext, useContext } from 'react'
import { setPath } from 'remeda'
import { create } from 'zustand'

export type FundsDeps = {
  getFundsInfo: () =>
  Promise<Domain.GetFundsInfoResult>
}

export type FundsState = {
  entities: {
    fundsInfoState:
      | {
        status: 'initial'
      }
      | {
        status: 'loading'
        requestId: string
      }
      | {
        status: 'success'
        requestId: string
        fundsInfo: Domain.FundsInfo
      }
      | {
        status: 'refreshing'
        requestId: string
        fundsInfo: Domain.FundsInfo
      }
      | {
        status: 'error'
        requestId: string
        error: Domain.GetFundsInfoError
      }
  }
  actions: {
    /**
     * Fetches the funds info, and will not allow consecutive requests until
     * the previous one is finished.
     *
     * Design is for different separate modules to be able to request the funds
     * without having to worry about duplicate requests.
     */
    getFundsInfo: {
      execute: () => Promise<void>
    }

    /**
     * Refreshes the funds info, key differences with `getFundsInfo` is that it
     * allows consecutive requests even when the previous one is still loading,
     * and will always only keep the latest request.
     *
     * Design is intended for force update on key events, such as when the user
     * just purchased more funds, or when the user just spent some funds.
     */
    refreshFundsInfo: {
      state: {
        requestId: string
        timestamp: number
        error: Domain.GetFundsInfoError
      } | null
      execute: () => Promise<void>
    }
  }
}

export const createFundsStore = (deps: FundsDeps) =>
  create<FundsState>((set, get) => {
    const setFundsInfoState = (
      fundsInfo: FundsState['entities']['fundsInfoState'],
    ) => {
      set(state => ({
        ...state,
        entities: {
          ...state.entities,
          fundsInfoState: fundsInfo,
        },
      }))
    }

    const getLatestRequestId = () => {
      const fundsInfo = get().entities.fundsInfoState
      if (fundsInfo.status === 'initial') return null
      return fundsInfo.requestId
    }

    return {
      entities: {
        fundsInfoState: {
          status: 'initial',
        },
      },
      actions: {
        getFundsInfo: {
          state: null,
          execute: async () => {
            const requestId = nanoid()

            const initialResultState = get().entities.fundsInfoState

            const isSameRequestStillLoading
              = initialResultState.status === 'loading'

            if (isSameRequestStillLoading) return

            setFundsInfoState({
              status: 'loading',
              requestId,
            })

            await deps.getFundsInfo()
              .then(({ fundsInfo }) => ({
                status: 'success' as const,
                requestId,
                fundsInfo,
              }))
              .catch(error => ({
                status: 'error' as const,
                requestId,
                error,
              }))
              .then(nextState => {
                if (getLatestRequestId() !== requestId) return
                setFundsInfoState(nextState)
              })
          },
        },
        refreshFundsInfo: {
          state: null,
          execute: async () => {
            const requestId = nanoid()

            const fundsInfoState = get().entities.fundsInfoState

            const priorFundsInfo = 'fundsInfo' in fundsInfoState
              ? fundsInfoState.fundsInfo
              : null

            const loadingOrRefreshingState = priorFundsInfo
              ? {
                status: 'refreshing' as const,
                requestId,
                fundsInfo: priorFundsInfo,
              }
              : {
                status: 'loading' as const,
                requestId,
              }

            setFundsInfoState(loadingOrRefreshingState)

            await deps.getFundsInfo()
              .then(({ fundsInfo }) => ({
                status: 'success' as const,
                requestId,
                fundsInfo,
              }))
              .catch(error => {
                set(setPath(
                  ['actions', 'refreshFundsInfo', 'state'],
                  {
                    error,
                    requestId,
                    timestamp: Date.now(),
                  },
                ))

                return priorFundsInfo
                  ? {
                    status: 'success' as const,
                    requestId,
                    fundsInfo: priorFundsInfo,
                  }
                  : {
                    status: 'error' as const,
                    requestId,
                    error,
                  }
              })
              .then(nextState => {
                if (getLatestRequestId() !== requestId) return
                setFundsInfoState(nextState)
              })
          },
        },
      },
    }
  })

export type FundsStore = ReturnType<typeof createFundsStore>

const FundsContext = createContext<FundsStore | null>(null)

export const FundsProvider = ({
  children,
  store,
}: {
  children?: ReactNode
  store: FundsStore
}) => (
  <FundsContext.Provider value={store}>
    {children}
  </FundsContext.Provider>
)

// eslint-disable-next-line @stylistic/comma-dangle
export const useFundsStore = <T,>(
  selector: (store: FundsState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(FundsContext)

  if (!store)
    throw new Error('useFundsStore must be used within a FundsProvider')

  return equalityFn ? store(selector, equalityFn) : store(selector)
}
