import { intersection } from 'lodash/fp'
import { BREAKPOINTS } from 'presentation/main/themes/common/breakpoints.theme'
import { mapBreakpoint } from 'presentation/utils/mapBreakpoint'

/**
 * Please see tests for usage examples.
 * @TODO Stronger typing
 * @TODO Refactor; Apply functional programming
 */
export const mapToArrayBreakpointDeep = (input: object): object =>
  Object.entries(input).reduce<any>((acc, [key, value]) => {
    if (intersection(Object.keys(value), BREAKPOINTS).length)
      acc[key] = mapBreakpoint(value)
    else
      acc[key] = mapToArrayBreakpointDeep(value)

    return acc
  }, {})
