import { Flex, Step, StepIndicator, StepSeparator, StepStatus, Stepper } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { Card } from 'presentation/components/molecules/Card'
import { IntroStep } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/IntroStep'
import { OwnershipStep } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/OwnershipStep'
import { PinCombinationStep } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/PinCombinationStep'
import { CancelButton, NextButton } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/common'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'

const MAX_STEP = 2
const MIN_STEP = 0

const steps = [MIN_STEP, 1, MAX_STEP] as const

type Steps = typeof steps[number]

const clamp = (value: number): number => Math.min(Math.max(value, MIN_STEP), MAX_STEP)

export type PropertyStatusesTrainingCardProps = {
  onClose: () => void
}

export const PropertyMarkerTrainingCard = (props: PropertyStatusesTrainingCardProps) => {
  const { onClose } = props

  const [activeStep, setActiveStep] = useState<Steps>(0)

  const signalTrainingDone = useSettingsStore(state => () => {
    const updateSettings = state.actions.updateSettings.execute
    void updateSettings({
      settings: {
        features: {
          searchHistory: {
            isPinTrainingDone: true,
          },
        },
      },
    })
  })

  const handleNextStep = () => {
    if (activeStep === MAX_STEP) {
      onClose()
      void signalTrainingDone()
      return
    }

    setActiveStep(prev => clamp(prev + 1) as Steps)
  }

  const handleCancel = () => {
    onClose()
  }

  const currentStepComponent = pipe(
    Match.value(activeStep),
    Match.when(0, () => <IntroStep />),
    Match.when(1, () => <OwnershipStep />),
    Match.when(2, () => <PinCombinationStep />),
    Match.exhaustive,
  )

  const currentNextLabel = pipe(
    Match.value(activeStep),
    Match.when(0, () => 'NEXT: OWNERSHIP'),
    Match.when(1, () => 'NEXT: PIN COMBINATIONS'),
    Match.when(2, () => 'DONE'),
    Match.exhaustive,
  )

  return (
    <Card
      size='modal.default.w'
    >
      {currentStepComponent}
      <Flex
        mt={mbp({ mobSm: 3, mob: 4, dtSm: 5 })}
        gap={mbp({ mobSm: 2, tabSm: 3 })}
        flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
        justifyContent='center'
      >
        <NextButton
          onClick={handleNextStep}
          order={mbp({ mobSm: 1, mob: 2 })}
        >
          {currentNextLabel}
        </NextButton>

        <CancelButton
          onClick={handleCancel}
          order={mbp({ mobSm: 2, mob: 1 })}
        />
      </Flex>

      <Flex
        justifyContent='center'
        mt={mbp({ mobSm: 3, mob: 4, dtSm: 5 })}
      >
        <Stepper
          index={activeStep}
          colorScheme='neutral'
          size='xs'
          variant='comfortable'
        >
          {steps.map(step => (
            <Step key={step} onClick={() => setActiveStep(step)}>
              <StepIndicator>
                <StepStatus />
              </StepIndicator>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Flex>
    </Card>
  )
}
