import EventEmitter from 'eventemitter3'
import { AccessStateEvents } from 'features/Access/infra/react/AccessState.events'
import { PropertyDetailsEvents } from 'features/PropertyDetails/domain/PropertyDetails.events'

/**
 * @TODO Reset subscribers on hot reload to prevent duplicate subscribers
 */
export namespace AppDomainEvents {
  type AppDomainEventUndistributed =
    | AccessStateEvents
    | PropertyDetailsEvents
  type Distributed<Type> = Type extends any ? Type : never
  type AppDomainEvent = Distributed<AppDomainEventUndistributed>

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export declare interface DomainEventEmitter {
    on: <TKey extends AppDomainEvent['key']>(
      key: TKey,
      handler: (data: Extract<AppDomainEvent, { key: TKey }>['data']) => void
    ) => this

    off: <TKey extends AppDomainEvent['key']>(
      key: TKey,
      handler?: (data: Extract<AppDomainEvent, { key: TKey }>['data']) => void
    ) => this

    emit: <TKey extends AppDomainEvent['key']>(
      key: TKey,
      data: Extract<AppDomainEvent, { key: TKey }>['data'],
    ) => boolean
  }

  export class DomainEventEmitter extends EventEmitter<AppDomainEvent['key']> {}

  const emitter = new DomainEventEmitter()

  export const on = emitter.on.bind(emitter)
  export const off = emitter.off.bind(emitter)
  export const emit = emitter.emit.bind(emitter)
}
