import { NumberLib } from 'libs/Number'

const ACRE_TO_SQFT = 43_560

const convertSqftToAcre = (sqft: number) =>
  NumberLib.omitAfterNPlaces(2)(sqft / ACRE_TO_SQFT)

const convertAcreToSqft = (acre: number) =>
  NumberLib.omitAfterNPlaces(2)(acre * ACRE_TO_SQFT)

const UnitConverter = {
  sqftToAcre: convertSqftToAcre,
  acreToSqft: convertAcreToSqft,
}

export default UnitConverter
