import { Box, HStack, StackProps, Text } from '@chakra-ui/react'
import { String } from 'effect'
import { isBoolean } from 'effect/Boolean'
import { SkiptraceFailedIcon, SkiptraceSuccessIcon } from 'presentation/components/Icons'

export type NameWithSkipProps = StackProps & {
  isSkipped?: boolean
}

/** Owner name with skiptrace indicator */
const NameWithSkip = ({
  children,
  isSkipped,
  ...props
}: NameWithSkipProps) => {
  const title = String.isString(children)
    ? children
    : undefined

  return (
    <HStack
      spacing='0.5'
      minW='0'
      textStyle='bodyL'
      color='graystrong.600'
      {...props}
    >
      {isBoolean(isSkipped) && (
        <Box w='min-content'>
          {isSkipped
            ? (
              <SkiptraceSuccessIcon
                boxSize='3'
                color='positive.500'
              />
            )
            : (
              <SkiptraceFailedIcon
                boxSize='3'
                color='negative.500'
              />
            )}
        </Box>
      )}
      <Text
        as={Box}
        flex='1'
        isTruncated
        minW='0'
        title={title}
      >
        {children}
      </Text>
    </HStack>
  )
}

export default NameWithSkip
