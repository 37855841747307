import { createIcon } from '@chakra-ui/react'

const SolidPlaneIcon = createIcon({
  displayName: 'SolidPlaneIcon',
  viewBox: '0 0 56 57',
  path: (
    <path
      d='M31.9297 34.1478L23.9031 41.2878L25.1351 46.9415L19.3297 52.7468L13.4801 43.5978L4.33105 37.7482L10.1364 31.9428L16.0981 33.4828L22.9277 25.1458L8.52872 15.5465L15.1274 8.94551L33.1314 14.9492L41.9817 6.09884C44.7141 3.36417 48.9467 3.16351 51.4317 5.64851C53.9167 8.13351 53.7137 12.3638 50.9814 15.0985L42.1311 23.9488L48.1301 41.9458L41.5314 48.5468L31.9297 34.1478Z'
      fill='currentColor'
    />
  ),
})

export default SolidPlaneIcon
