import { createIcon } from '@chakra-ui/react'

export const OutlineBedIcon = createIcon({
  displayName: 'OutlineBedIcon',
  viewBox: '0 0 20 17',
  path: (
    <path
      fill='currentColor'
      d='M4.5 11C5.87844 11 7 9.87844 7 8.5C7 7.12156 5.87844 6 4.5 6C3.12156 6 2 7.12156 2 8.5C2 9.87844 3.12156 11 4.5 11ZM4.5 7C5.32719 7 6 7.67281 6 8.5C6 9.32719 5.32719 10 4.5 10C3.67281 10 3 9.32719 3 8.5C3 7.67281 3.67281 7 4.5 7ZM16.5 6H8.5C8.22375 6 8 6.22375 8 6.5V12H1V3.25C1 3.11187 0.888125 3 0.75 3H0.25C0.111875 3 0 3.11187 0 3.25V14.75C0 14.8881 0.111875 15 0.25 15H0.75C0.888125 15 1 14.8881 1 14.75V13H19V14.75C19 14.8881 19.1119 15 19.25 15H19.75C19.8881 15 20 14.8881 20 14.75V9.5C20 7.56687 18.4331 6 16.5 6ZM19 12H9V7H16.5C17.8784 7 19 8.12156 19 9.5V12Z'
    />
  ),
})
