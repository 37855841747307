import { Grid, GridItem, VStack } from '@chakra-ui/react'
import { useIsVacantFlag } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { makeWiredSwitchCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/SwitchCard'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const StressIndicatorFiltersContent = () => (
  <VStack p={2} spacing={2} align='stretch'>
    <Grid
      gridTemplate={mbp({
        mobSm: 'auto / repeat(1, auto)',
        tabSm: 'auto / repeat(2, 1fr)',
      })}
      gap={2}
    >
      {/* <GridItem>
        <PreforeclosureCard h='full' />
      </GridItem>
      <GridItem>
        <SubstituteTrusteesCard h='full' />
      </GridItem>
      <GridItem>
        <ProbatesCard h='full' />
      </GridItem>
      <GridItem>
        <AffidavitsOfHeirshipsCard h='full' />
      </GridItem>
      <GridItem>
        <HOALiensCard h='full' />
      </GridItem>
      <GridItem>
        <TaxDelinquentCard h='full' />
      </GridItem> */}
      <GridItem>
        <VacantCard h='full' />
      </GridItem>
      {/* <GridItem>
        <FailedListingCard h='full' />
      </GridItem> */}
    </Grid>
  </VStack>
)

// const PreforeclosureCard = makeWiredSwitchCard({
//   title: 'Preforeclosure',
//   displayNamePrefix: 'Preforeclosure',
//   useApi: useIsPreforeclosureFlag,
//   isInDev: true,
// })

// const SubstituteTrusteesCard = makeWiredSwitchCard({
//   title: 'Substitute Trustees',
//   displayNamePrefix: 'SubstituteTrustees',
//   useApi: useIsSubstituteTrusteesFlag,
//   isInDev: true,
// })

// const ProbatesCard = makeWiredSwitchCard({
//   title: 'Probates',
//   displayNamePrefix: 'Probates',
//   useApi: useIsProbatesFlag,
//   isInDev: true,
// })

// const AffidavitsOfHeirshipsCard = makeWiredSwitchCard({
//   title: 'Affidavits of Heirships',
//   displayNamePrefix: 'AffidavitsOfHeirships',
//   useApi: useIsAffidavitsOfHeirshipsFlag,
//   isInDev: true,
// })

// const HOALiensCard = makeWiredSwitchCard({
//   title: 'HOA Liens',
//   displayNamePrefix: 'HOALiens',
//   useApi: useIsHOALiensFlag,
//   isInDev: true,
// })

// const TaxDelinquentCard = makeWiredSwitchCard({
//   title: 'Tax Delinquent',
//   displayNamePrefix: 'TaxDelinquent',
//   useApi: useIsTaxDelinquent,
//   isInDev: true,
// })

const VacantCard = makeWiredSwitchCard({
  title: 'Vacant',
  displayNamePrefix: 'Vacant',
  useApi: useIsVacantFlag,
})

// const FailedListingCard = makeWiredSwitchCard({
//   title: 'Failed Listing',
//   displayNamePrefix: 'FailedListing',
//   useApi: useIsFailedListingFlag,
//   isInDev: true,
// })
