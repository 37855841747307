import { Text, Image, Button, VStack, HStack, Stack, ButtonProps, Link } from '@chakra-ui/react'
import GoogleStoreBtnImg from 'presentation/assets/img/button/google-play-store-btn.png'
import AppStoreBtnImg from 'presentation/assets/img/button/app-store-btn.png'
import QRImg from './img/qr-code.png'
import DevicesImg from './img/devices.png'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { px } from 'presentation/utils/px'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { Breakpoint } from 'presentation/components/Breakpoint'

const D4DDownloadScreen = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  return (
    <Stack
      bgColor='positive.50'
      h={px(height)}
      {...mbpg({
        mobSm: {
          direction: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
        dtSm: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          spacing: '4',
        },
      })}
      py='3'
      px={mbp({
        mobSm: 2,
        mob: 3,
      })}
    >
      <DevicesImage />
      <Stack
        {...mbpg({
          mobSm: {
            direction: 'column',
          },
          dtSm: {
            direction: 'column',
            alignItems: 'flex-start',
          },
        })}
        mt={mbp({
          mobSm: '3',
          tabSm: '5',
          dtSm: '0',
        })}
      >
        <VStack
          spacing='1'
          alignItems={mbp({
            mobSm: 'center',
            dtSm: 'start',
          })}
        >
          <Headline />
          <Subheadline />
        </VStack>

        <VStack
          gap={mbp({
            mobSm: '2',
            tabSm: '3',
          })}
          mt={mbp({
            mobSm: '3',
            mob: '4',
            tabSm: '3',
            dtSm: '5',
          })}
        >
          <HStack
            spacing={mbp({
              mobSm: '3',
              dtSm: '2',
            })}
          >
            <Breakpoint
              tabSm={(
                <QRImage />
              )}
            />

            <VStack
              spacing='2'
              alignItems='start'
            >
              <Stack
                direction={mbp({
                  mobSm: 'row',
                  tabSm: 'column',
                })}
                spacing={mbp({
                  mobSm: '2',
                })}
              >
                <AppleStoreButton />
                <GoogleStoreButton />
              </Stack>
              {/**
                * @NOTE: deferred
                * <Breakpoint
                *   dtSm={(
                *     <AlreadyDownloadedButton />
                *   )}
                * />
                */}
            </VStack>
          </HStack>
          {/**
            * @NOTE: deferred
            * <Breakpoint
            *   mobSm={(
            *     <AlreadyDownloadedButton />
            *   )}
            *   dtSm={<></>}
            * />
            */}
        </VStack>
      </Stack>
    </Stack>
  )
}

export default D4DDownloadScreen

const AppleStoreButton = () => (
  <Link
    href='https://apps.apple.com/us/app/propelio-mobile/id1462188127'
  >
    <Image
      src={AppStoreBtnImg}
      {...mbpg({
        mobSm: {
          w: 'auto',
          h: '5',
        },
        tabSm: {
          w: '184px',
          h: 'auto',
        },
        dtSm: {
          w: '162px',
          h: 'auto',
        },
      })}
    />
  </Link>
)

const GoogleStoreButton = () => (
  <Link
    href='https://play.google.com/store/apps/details?id=com.propeliomobile'
  >
    <Image
      src={GoogleStoreBtnImg}
      {...mbpg({
        mobSm: {
          w: 'auto',
          h: '5',
        },
        tabSm: {
          w: '184px',
          h: 'auto',
        },
        dtSm: {
          w: '162px',
          h: 'auto',
        },
      })}
    />
  </Link>
)

export const AlreadyDownloadedButton = (props: ButtonProps) => (
  <Button
    colorScheme='neutral'
    variant='outline'
    {...props}
  >
    I&rsquo;ve Already Downloaded
  </Button>
)

const Headline = () => (
  <Text
    textStyle='customAccent'
    {...mbpg({
      mobSm: {
        fontSize: 3,
        lineHeight: '38px',
      },
      mob: {
        fontSize: 4,
        lineHeight: '51px',
      },
      tabSm: {
        fontSize: 5,
        lineHeight: '63px',
      },
      dtSm: {
        fontSize: 4,
        lineHeight: '51px',
      },
      dtLg: {
        fontSize: 6,
        lineHeight: '76px',
      },
    })}
    color='graystrong.500'
    w='max-content'
  >
    Get the Propelio App
  </Text>
)

const Subheadline = () => (
  <Text
    textStyle={mbp({
      mobSm: 'bodyXLFat',
      tabSm: 'h4',
      dtLg: 'h3',
    })}
    color='graystrong.200'
    w={mbp({
      mobSm: 'full',
      dtSm: 'max-content',
    })}
    textAlign={mbp({
      mobSm: 'center',
      dtSm: 'left',
    })}
  >
    Download the Propelio Mobile App and log in
    <Breakpoint tabSm={<br />} />
    using your registered email and password.
  </Text>
)

const DevicesImage = () => (
  <Image
    src={DevicesImg}
    w={mbp({
      mobSm: 'full',
      tabSm: '616px',
      dtSm: '453px',
      dtLg: '546px',
    })}
  />
)

const QRImage = () => (
  <Image
    src={QRImg}
    w='114px'
    h='114px'
  />
)
