import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { FailedInvoice_PayInvoiceMutation } from '__generated__/graphql'
import { GraphQLFormattedError } from 'graphql'
import { isNumber } from 'lodash/fp'
import { GqlPath } from 'presentation/libs/graphql'
import { useCallback } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'

type RespBillingInvoice = GqlPath<FailedInvoice_PayInvoiceMutation, [['billingPayInvoice', 'BillingInvoice']]>

export const FAILED_INVOICE_FLOW__PAY_INVOICE = gql(/* GraphQL */ `
  mutation FailedInvoice_PayInvoice($invoiceId: String!) {
    billingPayInvoice(invoiceId: $invoiceId) {
      __typename
      ... on BillingMutationError {
        message
      }
      ... on BillingPaymentDeclinedError {
        message
      }
      ... on BillingInvoiceNotFoundError {
        message
      }
      ... on BillingInvoice {
        capturedAmount
        paymentMethod {
          ... on BillingCardsPaymentMethod {
            last4
          }
        }
      }
    }
  }
`)

export type PayInvoice = (invoiceId: string) => Promise<string>

export type UsePayInvoiceMutationResult = {
  payInvoice: PayInvoice
  loading: boolean
}

export type UsePayInvoiceMutation = () => UsePayInvoiceMutationResult

export const usePayInvoiceMutation: UsePayInvoiceMutation = () => {
  const [payInvoiceMutation, { loading }] = useMutation(FAILED_INVOICE_FLOW__PAY_INVOICE)

  const payInvoice: PayInvoice = useCallback(async (invoiceId: string) =>
    await payInvoiceMutation({ variables: { invoiceId } })
      .then(({ errors, data }) => {
        const errMsg = data ? getErrors(data, errors) : null

        if (errMsg) throw new Error(errMsg)

        const billingInvoice = data ? getBillingInvoice(data) : null

        if (billingInvoice) {
          const last4 = getLast4Digit(billingInvoice)
          const amountRaw = billingInvoice.capturedAmount
          const amount = isNumber(amountRaw) ? ` ${formatPenniesOptionalDecUsd(amountRaw)}` : ''
          const successMessage = `Your card ending in ${last4} was successfully charged${amount}`
          return successMessage
        }

        throw new Error('Unexpected error occurred. Please try again or contact support.')
      }), [payInvoiceMutation])

  return { payInvoice, loading }
}

const getLast4Digit = (invoice: RespBillingInvoice): string => {
  const method = invoice?.paymentMethod
  return method?.__typename === 'BillingCardsPaymentMethod'
    ? method.last4
    : '####'
}

const getBillingInvoice = (data: FailedInvoice_PayInvoiceMutation): RespBillingInvoice | null =>
  data?.billingPayInvoice?.__typename === 'BillingInvoice'
    ? data.billingPayInvoice
    : null

const getErrorFromData = (data: FailedInvoice_PayInvoiceMutation): string | null => {
  if ('message' in data.billingPayInvoice)
    return data?.billingPayInvoice?.message ?? null

  return null
}

const getErrors = (
  data: FailedInvoice_PayInvoiceMutation,
  graphqlError: readonly GraphQLFormattedError[] | undefined,
): string | null => {
  const errorFromData = getErrorFromData(data)

  if (errorFromData) return errorFromData

  if (graphqlError?.[0]?.message)
    return 'Unexpected error occurred. Please try again or contact support.'

  return null
}
