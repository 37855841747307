import { Box, Grid, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { Location } from 'features/valueObjects/Location'
import { NumberLib } from 'libs/Number'
import { ID } from 'libs/id'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MapStoreProvider, useMapStore } from 'presentation/components/Map'
import { Marker, useCommonMarkerBehaviorsEffect } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { TabbedMap } from 'presentation/components/TabbedMap'
import { Card } from 'presentation/components/molecules/Card'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { DistanceTag } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/DistanceTag'
import { CompsMapMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarker'
import { useComparativePropertyModal } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal.api'
import { CompsAnalysisCardProps, StatPair } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard.props'
import { CompStatsGrid } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/components/CompStatsGrid'
import { SubjectStatsFlexWrap } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/components/SubjectStatsFlexWrap'
import { SubjectStatsGrid } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/components/SubjectStatsGrid'
import { CompStatCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompStatCard/CompStatCard'
import { ComparativeModalDataSection } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativeModalDataSection/ComparativeModalDataSection'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { Marker as MapMarker } from 'react-map-gl'
import { noop, pipe } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

export const CompAnalysisCard = (props: CompsAnalysisCardProps) => {
  const { comp, subject } = props

  const shouldShowComp = comp !== undefined

  const subjectStats = getStats(subject)
  const compStats = comp ? getStats(comp) : []

  const compMarkers = [comp, subject].filter(isNonNullable)

  return (
    <Card
      w='full'
      size={mbp({ mobSm: 'xs-locked', tabSm: 'sm-locked' })}
      display='flex'
      flexDirection='column'
      bg='grayweak.100'
      gap={3}
      minW='374px'
    >
      <Text
        textStyle='h4'
        color='accent.blue-text'
      >
        {shouldShowComp
          ? 'Comparative Analysis'
          : 'Facts and Features'}
      </Text>

      <Grid
        gridTemplateAreas={'"layer"'}
      >
        <Box
          pos='relative'
          h='280px'
          gridArea='layer'
          zIndex={1}
        >
          <MapStoreProvider>
            {/** @TODO Investigate why zoom got affected. */}
            <TabbedMap>
              {/* <FullscreenControl /> */}
              <MapMarkers comps={compMarkers} />
            </TabbedMap>
          </MapStoreProvider>
        </Box>
        {comp && (
          <DistanceTag
            gridArea='layer'
            justifySelf='start'
            alignSelf='start'
            mt={6}
            ml={2}
            value={comp.distance}
            zIndex={2}
          />
        )}
      </Grid>

      {shouldShowComp
        ? (
          <Breakpoint
            mobSm={null}
            mob={(
              <ComparativeModalDataSection
                subjectComp={props.subject}
                currentComp={comp}
              />
            )}
            tabSm={(
              <CompStatsGrid
                compStat={compStats}
                subjectStat={subjectStats}
                mlsStatus={comp.status}
                compMarker={(
                  <PropertyMarker
                    markerType='pin'
                    classification={comp.classification}
                    equityType={comp.equityType}
                    isVacant={comp.isVacant}
                    isForeclosure={comp.isForeclosure}
                    isSenior={comp.isSenior}
                    size='xs'
                  />
                )}
              />
            )}
          />
        )
        : (
          <Breakpoint
            mob={(
              <ComparativeModalDataSection
                subjectComp={props.subject}
                currentComp={props.subject}
              />
            )}
            tabSm={(
              <SubjectStatsGrid subjectStat={subjectStats} />
            )}
            dtLg={(
              <SubjectStatsFlexWrap subjectStat={subjectStats} />
            )}
          />
        )}

      <CompStatCard
        mt={props.comp ? 2.5 : 0}
        subject={subject}
        comp={comp}
      />
    </Card>
  )
}

type MapMarkersProps = {
  comps: (CMA.SubjectComp | CMA.SingleComp)[]
}

export const MapMarkers = (props: MapMarkersProps) => {
  const { comps } = props
  const map = useMapStore(store => store.computed.getMapRef())

  const {
    actions,
  } = useComparativePropertyModal()

  const { sbp } = useSwitchBreakpointFn()

  const horizontalPadding = sbp({
    mobSm: 50,
    tabSm: 100,
  }) ?? 50

  const markers: Marker[] = comps
    .map(comp => ({
      id: comp.id,
      location: comp.location,
    }))
    .filter(Location.hasLocation)

  useEffect(() => {
    map?.resize()
  }, [comps.length])

  const compsIdSerial = comps.map(c => c.id).join('-')

  useCommonMarkerBehaviorsEffect({
    markers,
    highlightedEntry: null,
    recenterDeps: [compsIdSerial],
    padding: {
      top: 50,
      bottom: 50,
      left: horizontalPadding,
      right: horizontalPadding,
    },
  })

  const handleMarkerClick = (id: ID) => {
    CompsMapAndListSync.setHighlightedEntry(id)

    actions.close()
  }

  return (
    <>
      {comps.map(marker => marker.location && (
        <MapMarker
          key={marker.id}
          longitude={marker.location.longitude}
          latitude={marker.location.latitude}
        >
          {pipe(
            Match.value(marker),
            Match.when({ type: 'subject-comp' }, v => (
              <Box onClick={() => handleMarkerClick(v.id)}>
                <SubjectPropertyMarker
                  markerType='pin'
                  size='sm'
                />
              </Box>
            )),
            Match.when({ type: 'single-comp', location: {} }, v => (
              <Box
                onClick={() => handleMarkerClick(v.id)}
              >
                <CompsMapMarker
                  {...v.location}
                  isSubjectProperty={false}
                  userRating={v.userRating}
                  id={v.id}
                  status={v.status}
                  listPrice={v.listPrice}
                  salePrice={v.salePrice}
                  onHover={noop}
                  onHoverOut={noop}
                  onMarkerClick={noop}
                />
              </Box>
            )),
            Match.orElse(() => null),
          )}
        </MapMarker>
      ))}
    </>
  )
}

export const getStats = (
  comp: CMA.SubjectComp | CMA.SingleComp,
): StatPair[] => {
  const values: StatPair[] = [
    { label: 'Beds', value: comp.bedroomsCount ?? '--' },
    {
      label: 'Baths',
      value: (comp.bathroomsCountInfo
      && BathroomsCountInfo.getTotal(comp.bathroomsCountInfo))
      ?? '--',
    },
    { label: 'Garage', value: comp.garageSpacesCount ?? '--' },
    { label: 'SqFt', value: NumberLib.formatCODoDD(comp.livingAreaSqft) },
    { label: 'Built', value: comp.yearBuilt ?? '--' },
    {
      label: 'Lot',
      value: NumberLib.formatCODoDD(comp.lotAreaSqft),
    },
    {
      label: 'Acres',
      value: NumberLib.ifElse(
        NumberLib.omitAfterNPlaces(2),
      )(() => '--')(comp.lotAreaAcres),
    },
    // @TODO Genesis (we don't have these data ppulated yet)
    // { label: 'Pool', value: '--' },
    // { label: 'Frplc', value: comp.fireplacesCount ?? '--' },
    // { label: 'Bsmt', value: '--' },
    // { label: 'Stories', value: '--' },
  ]
  return values
}
