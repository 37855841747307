import { SelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { useResponsivePropertyModal } from 'presentation/components/PropertyModal/PropertyModal.hooks'

export const usePropModalFromListScreen = () => {
  const selectListState = useSelectListState()
  const listName = SelectListState.$is('Success')(selectListState)
    ? selectListState.list.name
    : null
  const propertyModalApi = useResponsivePropertyModal({
    backLabel: listName
      ? `Back to “${listName}” List`
      : 'Back to List',
  })

  return propertyModalApi
}
