import { createIcon } from '@chakra-ui/react'

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  viewBox: '0 0 36 36',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M18 7.5V28.5' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.5 18L18 28.5L7.5 18' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
