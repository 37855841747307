import { useBuildTotalCount } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useBuildListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useBuildListState'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedState } from 'presentation/libs/hooks/useToastFailedState'
import { ListBuilderScreenBase } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/ListBuilderScreenBase'

export const ListBuilderScreen = () => {
  useToastFailedState(
    useBuildListState(),
    {
      title: 'Failed to apply filters',
      message: TOAST_PRESET.GENERIC_ERROR.message,
    },
  )

  useToastFailedState(
    useBuildTotalCount(),
    {
      title: 'Failed to load list total count',
      message: TOAST_PRESET.GENERIC_ERROR.message,
    },
  )

  return (
    <ListBuilderScreenBase />
  )
}
