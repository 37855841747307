import { createIcon } from '@chakra-ui/react'

export const BankIcon = createIcon({
  displayName: 'BankIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <path d='M8.67725 3.93653C8.89831 3.93653 9.11031 3.84872 9.26662 3.6924C9.42294 3.53609 9.51075 3.32409 9.51075 3.10303C9.51075 2.88197 9.42294 2.66997 9.26662 2.51366C9.11031 2.35735 8.89831 2.26953 8.67725 2.26953C8.45619 2.26953 8.24419 2.35735 8.08788 2.51366C7.93156 2.66997 7.84375 2.88197 7.84375 3.10303C7.84375 3.32409 7.93156 3.53609 8.08788 3.6924C8.24419 3.84872 8.45619 3.93653 8.67725 3.93653Z' fill='currentColor' />
      <path d='M14.6783 11.402V6.06195H14.7713C15.6643 6.06195 16.0313 4.91495 15.3013 4.39795L9.45034 0.24595C9.2249 0.0859504 8.95529 0 8.67884 0C8.40239 0 8.13278 0.0859504 7.90734 0.24595L2.05534 4.39795C1.32834 4.91495 1.69234 6.06195 2.58534 6.06195H2.67834V11.402C2.07734 11.788 1.67834 12.462 1.67834 13.229V14.562C1.67834 14.6946 1.73102 14.8217 1.82479 14.9155C1.91856 15.0093 2.04573 15.062 2.17834 15.062H15.1783C15.311 15.062 15.4381 15.0093 15.5319 14.9155C15.6257 14.8217 15.6783 14.6946 15.6783 14.562V13.229C15.6783 12.462 15.2803 11.788 14.6783 11.403V11.402ZM8.48634 1.06195C8.54261 1.02208 8.60988 1.00067 8.67884 1.00067C8.74781 1.00067 8.81507 1.02208 8.87134 1.06195L14.5103 5.06195H2.84734L8.48634 1.06195ZM13.6783 6.06195V11.068C13.6231 11.0638 13.5677 11.0618 13.5123 11.062H11.6783V6.06195H13.6783ZM3.67834 11.068V6.06195H5.67834V11.062H3.84534C3.78962 11.0618 3.73391 11.0638 3.67834 11.068ZM3.84534 12.062H13.5123C14.1563 12.062 14.6783 12.584 14.6783 13.229V14.062H2.67834V13.229C2.67834 12.584 3.20134 12.062 3.84534 12.062ZM8.17834 11.062H6.67834V6.06195H8.17834V11.062ZM9.17834 11.062V6.06195H10.6783V11.062H9.17834Z' fill='currentColor' />
    </>
  ),
})
