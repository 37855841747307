import { StyleFunctionProps } from '@chakra-ui/theme-tools'
import * as inputTheme from 'presentation/components/Input/Input.theme'

export const Textarea = {
  baseStyle: {
    color: 'font-2',
    minH: '88px',
  },
  sizes: {},
  variants: {
    'contained-md': (props: StyleFunctionProps) => inputTheme.variants['contained-md'](props).field,
    'contained-sm': (props: StyleFunctionProps) => inputTheme.variants['contained-sm'](props).field,
    'modal': (props: StyleFunctionProps) => inputTheme.variants.modal(props).field,
    'modal-search': (props: StyleFunctionProps) => inputTheme.variants['modal-search'](props).field,
  },
  defaultProps: {},
}
