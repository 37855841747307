import { isNumber } from 'effect/Number'
import { isString } from 'remeda'

const ensureOr = <TFallback>(fallback: TFallback) =>
  (value: string | null | undefined): string | TFallback =>
    isString(value) ? value : fallback

export const StringLib = {
  fromNumber: (x: number) => x.toString(),

  ensureOr,
  or: ensureOr,

  ensureOrNull: ensureOr(null),
  orNull: ensureOr(null),

  ensureOrUndefined: ensureOr(undefined),
  orUndefined: ensureOr(undefined),

  ensureOrDoubleDash: ensureOr('--'),
  orDoubleDash: ensureOr('--'),

  ifElse: <TOutput>(ifFn: (str: string) => TOutput) =>
    <TInput, TElseOutput>(elseFn: (input: TInput) => TElseOutput) =>
      (input: TInput) =>
        isString(input) ? ifFn(input) : elseFn(input),

  ifValid: <TOutput>(fn: (str: string) => TOutput) =>
    (maybeStr: string | undefined | null): TOutput | null =>
      isString(maybeStr) ? fn(maybeStr) : null,

  ifTruthy: <TOutput>(fn: (str: string) => TOutput) =>
    (maybeStr: string | undefined | null): TOutput | null =>
      isString(maybeStr) && maybeStr ? fn(maybeStr) : null,

  /**
   * @example
   * stringsToPhrase(['a', 'b', 'c', 'd']) // 'a, b, c and d'
   * stringsToPhrase(['a', 'b', 'c']) // 'a, b and c'
   * stringsToPhrase(['a', 'b']) // 'a and b'
   */
  stringsToPhrase: (origStrings: string[]): string => {
    if (origStrings.length === 0) return ''

    if (origStrings.length === 1) return origStrings[0]

    const stringsCopy = [...origStrings]
    const last = stringsCopy.pop()
    const phrase = stringsCopy.join(', ')
    return last ? `${phrase} and ${last}` : phrase
  },

  isAllCaps: (str: string) => str.length === 0
    ? false
    : str === str.toUpperCase(),

  toStringIfNumber: (x: string | number) => isNumber(x) ? x.toString() : x,
  toStringIfNumberUnstrict: (x: string | number | null | undefined) => isNumber(x) ? x.toString() : x ?? null,
}
