import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, ButtonProps, Checkbox, Flex, Grid, Link, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { SelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { ListMemberWithSkiptrace } from 'features/common/ListMemberWithSkiptrace/infra/useListMemberWithSkiptrace'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Dollar } from 'libs/dollar'
import { SkiptraceFailedIcon, SkiptraceSuccessIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { useBatchSkiptraceDrawerActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceDrawer/BatchSkiptraceDrawer'
import { usePropModalFromListScreen } from 'presentation/screens/ListScreen/usePropModalFromListScreen'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Dispatch, SetStateAction } from 'react'

export type ListAccordionItemProps = {
  id: MarketingListMemberId
  canSelect: boolean
  isSelected: boolean
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
  member: ListMemberWithSkiptrace
}

const ListAccordionItem = ({
  id,
  canSelect,
  isSelected,
  setSelections,
  member: memberWithSkiptrace,
}: ListAccordionItemProps) => {
  const member = memberWithSkiptrace.member.member
  const skiptrace = memberWithSkiptrace.skiptrace
  const propertyModalApi = usePropModalFromListScreen()
  const ownerCityStateZip = Address.formatCityStateZip(member.ownerAddress)
  const drawerApi = useBatchSkiptraceDrawerActions()

  const isSkipped = skiptrace._tag === 'Success' || skiptrace._tag === 'SuccessEmpty'

  return (
    <AccordionItem
      borderTopWidth={0}
      sx={{
        '&:last-of-type': {
          borderBottomWidth: 0,
          borderTopWidth: 0,
        },
      }}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            py={1}
            px={mbp({ mobSm: 1, mob: 2 })}
            bgColor={isExpanded
              ? 'neutral.200'
              : isSkipped
                ? 'positive.50'
                : 'card.bg.1'}
            _hover={{ bgColor: isExpanded
              ? 'neutral.200'
              : isSkipped
                ? 'positive.50'
                : 'card.bg.1' }}
            borderTopRadius={2}
            borderBottomRadius={isExpanded ? 0 : 2}
          >
            <AccordionIcon boxSize={3} color='graystrong.200' mr={1} />
            <Flex
              as='span'
              flex='1'
              textAlign='left'
            >
              <Box>
                <Link
                  textStyle='bodyLFat'
                  w='full'
                  noOfLines={1}
                  display='inline-block'
                  href='#'
                  onClick={e => {
                    e.preventDefault()
                    propertyModalApi.actions.open(
                      member.parcelId
                        ? { parcelId: member.parcelId }
                        : { address: member.address },
                    )
                  }}
                >
                  {member.address.line1}
                </Link>
                {isExpanded
                  ? (
                    <Text color='grayweak.900' textStyle='bodyL'>
                      {member.address.subdivision ?? '--'}
                    </Text>
                  )
                  : (
                    <Flex gap={0.5} alignItems='center'>
                      {pipe(
                        Match.value(skiptrace),
                        Match.tag('Loading', () => null),
                        Match.when(
                          skip =>
                            skip._tag === 'Success'
                            || skip._tag === 'SuccessEmpty',
                          () => (
                            <SkiptraceSuccessIcon fontSize='2.5' color='positive.500' />
                          )),
                        Match.orElse(() => <SkiptraceFailedIcon fontSize='2.5' color='negative.500' />),
                      )}
                      <Text
                        {...isSkipped && {
                          color: 'link.500',
                        }}
                        // title={member.ownerName}
                        noOfLines={1}
                        display='inline-block'
                      >
                        {member.ownerName}
                      </Text>
                    </Flex>
                  )}
              </Box>
            </Flex>
            {canSelect && (
              <Checkbox
                isChecked={isSelected}
                onChange={() => {
                  if (isSelected)
                    setSelections(prev => prev.filter(prevId => prevId !== id))
                  else
                    setSelections(prev => [...prev, id])
                }}
              />
            )}
          </AccordionButton>
          <AccordionPanel
            as={Grid}
            bgColor='neutral.100'
            p={mbp({ mobSm: 1, mob: 2 })}
            rowGap={2}
            columnGap={2}
            gridTemplateColumns={mbp({ mobSm: 'repeat(2, 1fr)' })}
            borderBottomRadius={2}
          >
            {/**
           // region: Owner's Name
           **/}
            <Pair>
              <PairKey>
                Owner&rsquo;s Name
              </PairKey>
              <PairValue>
                <Flex
                  gap={0.5}
                  alignItems='center'
                  {...isSkipped && {
                    role: 'button',
                    onClick: () => {
                      drawerApi.open({
                        skiptrace: skiptrace.result,
                      })
                    },
                  }}
                >
                  {pipe(
                    Match.value(skiptrace),
                    Match.tag('Loading', () => null),
                    Match.when(
                      skip =>
                        skip._tag === 'Success'
                        || skip._tag === 'SuccessEmpty',
                      () => (
                        <SkiptraceSuccessIcon fontSize='2.5' color='positive.500' />
                      )),
                    Match.orElse(() => <SkiptraceFailedIcon fontSize='2.5' color='negative.500' />),
                  )}
                  <Text
                    color={isSkipped ? 'link.500' : 'graystrong.400'}
                    noOfLines={1}
                  >
                    {member.ownerName}
                  </Text>
                </Flex>
              </PairValue>
            </Pair>

            {/**
           // region: Owner's Address
           **/}
            <Pair>
              <PairKey>
                Owner&rsquo;s Address
              </PairKey>
              <PairValue as={Box}>
                <Text
                  textStyle='bodyL'
                  color='graystrong.400'
                  noOfLines={1}
                >
                  {member.ownerAddress.line1}
                </Text>
                <Text
                  textStyle='bodyM'
                  color='grayweak.900'
                  noOfLines={1}
                >
                  {ownerCityStateZip}
                </Text>
              </PairValue>
            </Pair>

            {/**
           // region: Last Sale Date
           */}
            <Pair>
              <PairKey>
                Last Sale Date
              </PairKey>
              <PairValue>
                {DateLib.formatMMDDYYDotsOrDoubleDash(member.lastSoldDate)}
              </PairValue>
            </Pair>

            {/**
           // region: Property Type
           */}
            <Pair>
              <PairKey>
                Property Type
              </PairKey>
              <PairValue>
                {member.useType || '--'}
              </PairValue>
            </Pair>

            {/**
           // region: Beds / Baths / Garage
           */}
            <Pair>
              <PairKey>
                Beds/Baths/Garage
              </PairKey>
              <PairValue>
                {[
                  NumberLib.orDoubleDash(member.bedroomsCount),
                  pipe(
                    member.bathroomsCountInfo,
                    BathroomsCountInfo.getTotal,
                    StringLib.orDoubleDash,
                  ),
                  NumberLib.orDoubleDash(member.garageSpacesCount),
                ].join(' / ')}
              </PairValue>
            </Pair>

            {/**
           // region: Yr. Build
           */}
            <Pair>
              <PairKey>
                Yr Built
              </PairKey>
              <PairValue>
                {NumberLib.orDoubleDash(member.yearBuilt)}
              </PairValue>
            </Pair>

            {/**
           // region: Lot Acre
           */}
            <Pair>
              <PairKey>
                Lot Acre
              </PairKey>
              <PairValue>
                {NumberLib.formatCommasDecimalsOrDoubleDash(member.lotAreaAcres)}
              </PairValue>
            </Pair>

            {/**
           // region: House Sqft
           */}
            <Pair>
              <PairKey>
                House Sqft
              </PairKey>
              <PairValue>
                {NumberLib.formatCommasOrDoubleDash(member.livingAreaSqft)}
              </PairValue>
            </Pair>

            {/**
           // region: Est Equity
           */}
            <Pair>
              <PairKey>
                Est Equity
              </PairKey>
              <PairValue>
                {Dollar.formatNumberWithKOrDoubleDash(member.estimatedEquity)}
              </PairValue>
            </Pair>

            {/**
           // region: Est Value
           */}
            <Pair>
              <PairKey>
                Est Value
              </PairKey>
              <PairValue>
                {Dollar.formatNumberWithKOrDoubleDash(member.estimatedValue)}
              </PairValue>
            </Pair>
            {skiptrace._tag === 'Success' && (
              <SkipDataButton
                gridColumn='1 / -1'
                memberId={member.id}
                skiptraceResult={skiptrace.result}
              />
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default ListAccordionItem

const SkipDataButton = ({ memberId, skiptraceResult, ...props }: ButtonProps & {
  memberId: MarketingListMemberId
  skiptraceResult: Skiptrace.Result
}) => {
  const listState = useSelectListState()
  const drawerApi = useBatchSkiptraceDrawerActions()

  if (!SelectListState.$is('Success')(listState))
    return null

  return (
    <Button
      variant='outline'
      colorScheme='positive'
      onClick={() => {
        drawerApi.open({
          skiptrace: skiptraceResult,
        })
      }}
      {...props}
    >
      <Flex gap={1}>
        <SkiptraceSuccessIcon boxSize={3} />
        <Text color='inherit'>
          Skip Data
        </Text>
      </Flex>
    </Button>
  )
}
