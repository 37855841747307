namespace DragListenerEvent {

  export const OnAddListeners = 'add-listeners' as const
  export const OnRemoveListeners = 'remove-listeners' as const
  export const OnDidAddListeners = 'did-add-listeners' as const
  export const OnDidRemoveListeners = 'did-remove-listeners' as const

  export type Incoming =
    | { type: typeof OnAddListeners, getDraggablePanelRef: () => HTMLDivElement }
    | { type: typeof OnRemoveListeners }

  export type Outgoing =
    | { type: typeof OnDidAddListeners }
    | { type: typeof OnDidRemoveListeners }

  export const AddListeners = (
    draggablePanelRef: HTMLDivElement,
  ): Incoming => ({
    type: OnAddListeners,
    getDraggablePanelRef: () => draggablePanelRef,
  })

  export const RemoveListeners = (): Incoming => ({
    type: OnRemoveListeners,
  })

  export const DidAddListener = (): Outgoing => ({
    type: OnDidAddListeners,
  })

  export const DidRemoveListener = (): Outgoing => ({
    type: OnDidRemoveListeners,
  })

}

export default DragListenerEvent
