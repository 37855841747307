import { Box, Center, Flex, Grid, GridItem, Spinner } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { selectCompsBy } from 'features/CMA/infra/react/CMAState.selectors'
import { ID } from 'libs/id'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { IdWithOrigin } from 'presentation/libs/MapAndListSync/MapAndListSync'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { CMARatingChangeEventHandler } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { CMADataUpdateButton, CMADataUpdateDate } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { CMAEntriesNoMatch } from 'presentation/screens/CompsScreen/components/CMAEntriesNoMatch'
import { CMAEntriesSortMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar'
import { CMAEntryTileCard, CMAEntryTileCardProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard'
import { CMATileEntriesProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMATileEntries/CMATileEntries.props'
import { CompsCount } from 'presentation/screens/CompsScreen/components/CompsCount/CompsCount'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { useComparativePropertyModal } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal.api'
import { useEntriesTransformation } from 'presentation/screens/CompsScreen/hooks/useEntriesTransformation'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { GridComponents, VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso'
import { shallow } from 'zustand/shallow'

export const CMATileEntries = (props: CMATileEntriesProps) => {
  /**
   * ================
   * Hooks
   * ================
   */

  const {
    subject,
    comps,
    isLoading,
    rate,
  } = useCMAStore(selectCompsBy(props.entriesType), shallow)

  /**
   * ================
   * Handlers
   * ================
   */
  const comparativeModalApi = useComparativePropertyModal()

  const transformedComps = useEntriesTransformation({ comps })

  const handleOnCardClick = (targetCompId: ID) => {
    comparativeModalApi.actions.open(targetCompId)
  }

  const handleRatingChange: CMARatingChangeEventHandler = useCallback((compId, rating) => {
    void rate.execute({ compId, rating })
  }, [])

  const highlightedEntry = CompsMapAndListSync.useStore(store => store.highlightedListItem)

  const handleHoverEntry = useCallback((id: ID) => {
    CompsMapAndListSync.handleListItemHover(id)
  }, [])

  /** @TODO Ask @chrisregner to explain or refactor this sorcery */
  const handleHoverOutEntry = useCallback(() => {
    CompsMapAndListSync.handleListItemUnhover()
  }, [])

  /**
   * ================
   * Derived Data
   * ================
   */
  const hasNoData = comps.length === 0

  const allEntries = useMemo(() => [
    ...subject ? [subject] : [],
    ...transformedComps,
  ], [subject, transformedComps])

  const { virtuosoRef } = useScrollToHighlightedEntry(highlightedEntry, allEntries)

  return (
    <Flex
      w='full'
      flexDirection='column'
      h='100%'
    >
      <Breakpoint
        mobSm={(
          <Grid
            templateRows='repeat(2, 1fr)'
            templateColumns='repeat(2, 1fr)'
            px={mbp({ mobSm: 1, mob: 2 })}
            pt={2}
            pb={mbp({ mobSm: 1, mob: 2 })}
          >
            <GridItem justifySelf='start'>
              <CMADataUpdateButton colorScheme='neutral' />
            </GridItem>
            <GridItem justifySelf='end'>
              <CMAEntriesSortMenu />
            </GridItem>
            <GridItem justifySelf='start' pt={0.5}>
              <CMADataUpdateDate isCompact color='ondark.6' />
            </GridItem>
            <GridItem justifySelf='end'pt={0.5}>
              <CompsCount color='ondark.6' />
            </GridItem>
          </Grid>
        )}
        tabSm={(
          <Grid
            templateColumns='repeat(2, 1fr)'
            p={3}
            pb={3}
          >
            <GridItem justifySelf='start'>
              <CompsCount color='ondark.6' />
            </GridItem>
            <GridItem justifySelf='end'>
              <CMAEntriesSortMenu />
            </GridItem>
          </Grid>
        )}
      />

      {pipe(
        Match.value([hasNoData, isLoading]),
        // Show loading spinner
        Match.when([Match.any, true], () => (
          <Center h='100%'>
            <Spinner color='neutral.500' />
          </Center>
        )),
        // Show entries
        Match.when([false, false], () => (
          <VirtuosoGrid
            ref={virtuosoRef}
            data={allEntries}
            totalCount={allEntries.length}
            itemContent={(index, entry) =>
              renderTileCard({
                entry,
                subject,
                highlightEntryId: highlightedEntry?.id ?? undefined,
                onHoverEntry: handleHoverEntry,
                onHoverOutEntry: handleHoverOutEntry,
                onCompsRatingChange: handleRatingChange,
                onOpenComparativeModal: handleOnCardClick,
              })}
            components={virtualGridComponents}
          >
          </VirtuosoGrid>
        )),
        // Show no result
        Match.orElse(() => (
          <CMAEntriesNoMatch
            mx={mbp({ mobSm: 1, mob: 2, tabSm: 3 })}
            mb={3}
          />
        )),
      )}
    </Flex>
  )
}

const useScrollToHighlightedEntry = (
  entry: IdWithOrigin | null,
  allEntries: (CMA.SingleComp | CMA.SubjectComp)[],
) => {
  const virtuosoRef = useRef<VirtuosoGridHandle | null>(null)

  useEffect(() => {
    /**
     * ================
     * Scroll to highlighted entry when hovering markers
     * on the map
     * ================
     */
    if (entry === null) return

    if (entry.origin === 'list') return

    const index = allEntries.findIndex(e => e.id === entry.id)

    virtuosoRef.current?.scrollToIndex({
      index,
      behavior: 'smooth',
    })
  }, [entry])

  return {
    virtuosoRef,
  }
}

const renderTileCard = (props: {
  subject: CMA.SubjectComp | null
  entry: CMA.SingleComp | CMA.SubjectComp
  highlightEntryId?: ID
  onHoverEntry: (id: ID) => void
  onHoverOutEntry: (id: ID) => void
  onCompsRatingChange: CMARatingChangeEventHandler
  onOpenComparativeModal: (id: ID) => void
}) => {
  const handleOnClick = () => {
    props.onOpenComparativeModal(props.entry.id)
  }

  const entryProps: CMAEntryTileCardProps = pipe(
    Match.value(props.entry),
    Match.when({ type: 'single-comp' }, (e: CMA.SingleComp) => ({
      ...e,
      entryType: 'regular' as const,
      subjectProperty: props.subject,
      onCompsRatingChange: props.onCompsRatingChange,
      onClick: handleOnClick,
    })),
    Match.when({ type: 'subject-comp' }, (e: CMA.SubjectComp) => ({
      ...e,
      entryType: 'subject' as const,
      onCompsRatingChange: props.onCompsRatingChange,
      onClick: handleOnClick,
    })),
    Match.exhaustive,
  )

  return (
    <CMAEntryTileCardMemo
      key={props.entry.id}
      {...entryProps}
      isHighlighted={props.highlightEntryId === props.entry.id}
      onHoverEntry={props.onHoverEntry}
      onHoverOutEntry={props.onHoverOutEntry}
    />
  )
}

export const CMAEntryTileCardMemo = memo(CMAEntryTileCard)

const VirtualGridList: GridComponents['List'] = forwardRef(({ children, ...props }, ref) => (
  <Grid
    ref={ref}
    {...props}
    {...mbpg({
      mobSm: {
        gridTemplateColumns: '100%',
        px: 1,
        mt: -0.5,
      },
      tabSm: {
        gridTemplateColumns: '50% 50%',
        px: 1,
        mt: -0.5,
        mb: -1.5,
      },
    })}
    gridTemplateRows='min-content'
  >
    {children}
  </Grid>
))

VirtualGridList.displayName = 'CMAEntriesGridList'

const VirtualGridFooter: GridComponents['Footer'] = forwardRef((props, ref) => (
  <Box
    ref={(ref as any)}
    {...mbpg({
      mobSm: {
        p: 1,
        pb: 7,
      },
      tabSm: {
        p: 3,
        pb: 7,
      },
    })
    }
    {...props}
  >
    <CompsMLSDisclaimer />
  </Box>
))

VirtualGridFooter.displayName = 'CMAEntriesGridFooter'

const VirtualGridItem: GridComponents['Item'] = forwardRef((props, ref) => (
  <Box

    ref={(ref as any)}
    // @NOTE expressing spacing via padding vs row/column was what's working with react-virtuoso
    {...mbpg({
      mobSm: {
        py: 1,
      },
      mob: {
        px: 1,
        py: 1,
      },
      tabSm: {
        px: 1,
        py: 1,
      },
    })}
  >

    {

      (props as any).children
    }
  </Box>
))

VirtualGridItem.displayName = 'CMAEntriesGridItem'

const virtualGridComponents: GridComponents = {
  List: VirtualGridList,
  Footer: VirtualGridFooter,
  Item: VirtualGridItem,
}
