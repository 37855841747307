import DragListenerEvent from './DragListenerEvent'

namespace DraggablePanelEvent {

  // #region event types
  export const OnDidMount = 'did-mount' as const
  export const OnWillDrag = 'will-drag' as const
  export const OnDragging = 'dragging' as const
  export const OnDidDrag = 'did-drag' as const
  export const OnResize = 'resize' as const
  export const OnOpen = 'open' as const
  export const OnClose = 'close' as const
  export const OnDidOpen = 'did-open' as const
  export const OnDidClose = 'did-close' as const

  export type Incoming =
    | {
      type: typeof OnDidMount
      getDraggablePanelRef: () => HTMLDivElement
    }
    | { type: typeof OnWillDrag, dragPointY: number }
    | { type: typeof OnDragging, dragPointY: number }
    | { type: typeof OnDidDrag, dragPointY: number }
    | { type: typeof OnResize }
    | { type: typeof OnOpen }
    | { type: typeof OnClose }
    | DragListenerEvent.Outgoing

  export type Outgoing =
    | { type: typeof OnDidOpen }
    | { type: typeof OnDidClose }

  // #region event
  export const DidMount = (ref: HTMLDivElement): Incoming => ({
    type: OnDidMount,
    getDraggablePanelRef: () => ref,
  })

  export const WillDrag = (dragPointY: number): Incoming => ({
    type: OnWillDrag,
    dragPointY,
  })

  export const Dragging = (dragPointY: number): Incoming => ({
    type: OnDragging,
    dragPointY,
  })

  export const DidDrag = (dragPointY: number): Incoming => ({
    type: OnDidDrag,
    dragPointY,
  })

  export const Resize = (): Incoming => ({
    type: OnResize,
  })

  export const Open = (): Incoming => ({
    type: OnOpen,
  })

  export const Close = (): Incoming => ({
    type: OnClose,
  })

  // #region emitted
  export const DidOpen = (): Outgoing => ({
    type: OnDidOpen,
  })

  export const DidClose = (): Outgoing => ({
    type: OnDidClose,
  })

}

export default DraggablePanelEvent
