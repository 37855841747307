import { sizeToPx } from 'presentation/utils/chakraSizeToNumber'
import { px } from 'presentation/utils/px'

/**
 * Performs subtraction of pixel from size and returns a string pixel value
 * @example
 * subtractPixelFromSize({
 *  size: 1, <-- is equivalent to 8px based on chakra theme
 *  pixel: 2 <-- is equivalent to 2px
 * })
 * returns 6px
 */
type SubtractPixelFromSizeParams = {
  size: string | number
  pixel: number
}

export const subtractPixelFromSize = (params: SubtractPixelFromSizeParams): string =>
  px((sizeToPx(params.size) || 0) - params.pixel)
