import { createIcon } from '@chakra-ui/react'

export const GavelIcon = createIcon({
  displayName: 'GavelIcon',
  viewBox: '0 0 24 24',
  path: (
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='GavelIcon' transform='translate(0.375, 0.375)'>
        <path d='M10.5899,9.6609 L11.1136,9.13585 C11.5266,8.72117 11.774,8.16997 11.8092,7.58579 C11.83,7.57705 11.8502,7.56697 11.8696,7.5556 L13.9814,6.23523 C14.1194,6.06711 14.1907,5.8542 14.182,5.63693 C14.1733,5.41965 14.0851,5.21314 13.9342,5.0566 L10.2605,1.37373 C10.1045,1.22272 9.8984,1.13443 9.6815,1.12571 C9.4646,1.117 9.25207,1.18847 9.0845,1.32648 L7.76675,3.44485 L7.73787,3.50523 C7.1735,3.54067 6.61962,3.77298 6.19175,4.20217 L4.19412,6.20635 C3.76494,6.63554 3.53394,7.19073 3.4985,7.75642 C3.47774,7.76516 3.45757,7.77524 3.43812,7.7866 L1.32631,9.10698 C1.18839,9.27509 1.117,9.488 1.12571,9.7053 C1.13442,9.9226 1.22262,10.1291 1.37356,10.2856 L5.04725,13.9685 C5.20321,14.1195 5.40932,14.2078 5.62623,14.2165 C5.84315,14.2252 6.05568,14.1537 6.22325,14.0157 L7.541,11.8974 L7.57119,11.837 C8.13556,11.8015 8.68944,11.5692 9.11731,11.14 L9.6974,10.5586' id='Path' stroke='currentColor' strokeWidth='1.75'></path>
        <path d='M9.78676874,7.43098013 L9.964095,7.4436612 C10.8115661,7.53872802 11.7141311,8.50263422 12.2646946,8.97595739 C13.6560458,10.1721116 22.5482651,17.8591721 23.0372872,18.69405 C23.3336545,19.2000201 23.5885506,20.1712049 23.2254627,21.0888 C23.0353761,21.5691866 22.4520761,22.2361876 22.04,22.6125 C20.656,23.8763841 19.1363251,23.3774666 18.4432763,22.8923371 C18.1508609,22.6876483 17.0290487,21.4327897 15.8791601,20.1170615 L15.473456,19.6517703 C14.5301822,18.5677111 13.6318029,17.5213046 13.2258701,17.065093 C10.9240384,14.4781572 8.78864966,12.3327302 7.85164245,10.9436591 C7.22697097,10.0176117 7.31241016,9.07237871 8.10796,8.10796 C8.69203859,7.59180384 9.31075026,7.37037091 9.964095,7.4436612 Z M10.0559,9.32452 C9.8509,9.14229 9.53935,9.15144 9.34539,9.34539 C9.15144,9.53935 9.14229,9.8509 9.32452,10.0559 L19.348,21.3323 C19.7303,21.7624 20.3957,21.7819 20.8026,21.3751 L21.3751,20.8026 C21.7819,20.3957 21.7624,19.7303 21.3323,19.348 Z' id='Combined-Shape' fill='currentColor'></path>
      </g>
    </g>
  ),
})
