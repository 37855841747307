import { createIcon } from '@chakra-ui/react'

export const RetryChargeIcon = createIcon({
  displayName: 'RetryChargeIcon',
  viewBox: '0 0 29 29',
  path: (
    <g fill='none'>
      <path d='M25.41 23.5L29 19.91L27.59 18.5L24 22.09L20.41 18.5L19 19.91L22.59 23.5L19 27.09L20.41 28.5L24 24.91L27.59 28.5L29 27.09L25.41 23.5ZM17 0.5C13.8202 0.509234 10.7734 1.77648 8.52492 4.02492C6.27648 6.27336 5.00923 9.32024 5 12.5V18.7L1.4 15.1L0 16.5L6 22.5L12 16.5L10.6 15.1L7 18.7V12.5C7 9.84784 8.05357 7.3043 9.92893 5.42893C11.8043 3.55357 14.3478 2.5 17 2.5C19.6522 2.5 22.1957 3.55357 24.0711 5.42893C25.9464 7.3043 27 9.84784 27 12.5V15.5H29V12.5C28.9908 9.32024 27.7235 6.27336 25.4751 4.02492C23.2266 1.77648 20.1798 0.509234 17 0.5Z' fill='currentColor' />
    </g>
  ),
})
