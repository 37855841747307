import { ButtonProps, IconButton, Menu, MenuButton, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { shallowEqual, useSelector } from '@xstate/react'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { getPropertyFromState, usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { KebabIcon, TrashIcon } from 'presentation/components/Icons'
import { MenuItemButton } from 'presentation/components/Menu/Menu'
import { Modal, ModalContent } from 'presentation/components/Modal/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { toast } from 'presentation/components/Toast'
import { Card, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card/Card'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const PropertyDeleteAction = (props: { shouldShowFromAllListAction: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu
      placement='bottom-end'
    >
      <MenuButton
        as={IconButton}
        aria-label='Actions'
        icon={<KebabIcon />}
        size='md'
        variant='icon-ghost'
        colorScheme='onlight'
      />

      <ResponsiveMenuList>
        {props.shouldShowFromAllListAction && (
          <RemoveFromAllListItemButton />
        )}
        <MenuItemButton
          icon={<TrashIcon />}
          color='accent.red-to-gray'
          sx={{
            '&:hover': {
              color: 'accent.red-to-gray',
            },
          }}
          onClick={onOpen}
        >
          REMOVE FROM SEARCH HISTORY
        </MenuItemButton>
      </ResponsiveMenuList>

      <DeleteModal isOpen={isOpen} onClose={onClose} />
    </Menu>
  )
}

// Create this here to avoid recreating it on every render and confusing useEffect
const FALLBACK_IDLE_STATE = { status: 'idle' as const }

export const DeleteModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const propertyId = usePropertyDetailsStore(state => {
    const property = getPropertyFromState(state)
    if (!property) return null
    return property.leadId
  })
  const deleteFromHistory = useSearchHistoryStore(state =>
    state.actions.deleteEntries.execute)

  const deleteState = useSearchHistoryStore(state => {
    const deleteState = state.actions.deleteEntries.state
    if (
      propertyId
      && 'payload' in deleteState
      && !deleteState.payload.ids.includes(propertyId)
    )
      return FALLBACK_IDLE_STATE

    return deleteState
  })

  // handle error
  const error = 'error' in deleteState ? deleteState.error : null
  useEffect(() => {
    if (!error) return

    toast.error({
      title: 'Failed to remove property',
      message: 'Please try again or contact support.',
    })
  }, [error])

  // handle success
  const navigate = useNavigate()
  useEffect(() => {
    if (deleteState.status !== 'success') return

    toast.info({
      title: 'Item Removed',
      message: 'An item has been removed from the search history.',
    })
    onClose()
    navigate('/search')
  }, [deleteState])

  if (!propertyId) return null

  const handleDelete = () => {
    void deleteFromHistory({ ids: [propertyId] })
      .then()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <Card
          size='modal.alert.xn'
          colorScheme='modal.alert.negative'
          variant='modal.alert'
        >
          <CardImage src={hurtDogPng} alt='' />
          <CardPrimaryTitle flex='1'>Remove this property from Search History</CardPrimaryTitle>
          <CardPrimaryText>
            Removing this property will remove it from your search history.
            {' '}
            However, you can still access it by searching for its address again.
            {' '}
            Are you sure you want to proceed with this action?
          </CardPrimaryText>
          <CardFooter>
            <CardButton
              variant='outline'
              colorScheme='neutral'
              onClick={onClose}
            >
              Cancel
            </CardButton>
            <CardButton
              variant='solid'
              colorScheme='negative'
              onClick={handleDelete}
              isLoading={deleteState.status === 'loading'}
            >
              Yes, Remove
            </CardButton>
          </CardFooter>
        </Card>
      </ModalContent>
    </Modal>
  )
}

const RemoveFromAllListItemButton = (props: ButtonProps) => {
  const actor = useParcelListManager()
  const isLoading = useSelector(actor, snapshot => snapshot.matches('Loading'), shallowEqual)
  const handleRemoveFromAllList = () => {
    actor.send({ type: 'lists.removeFromAll' })
  }
  return (
    <MenuItemButton
      icon={<TrashIcon />}
      color='accent.red-to-gray'
      sx={{
        '&:hover': {
          color: 'accent.red-to-gray',
        },
      }}
      isLoading={isLoading}
      onClick={handleRemoveFromAllList}
      {...props}
    >
      Remove from all list
    </MenuItemButton>
  )
}
