import { Flex, Text } from '@chakra-ui/react'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { SubjectStatsGridProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/components/SubjectStatsGrid'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const SubjectStatsFlexWrap = (props: SubjectStatsGridProps) => (
  <Flex
    bgColor='accent.darkpurple'
    flexWrap='wrap'
    borderRadius={3}
    overflow='hidden'
  >
    <Flex
      alignSelf='center'
      pl={mbp({ mobSm: 1, tabSm: 2 })}
      justifyContent='center'
      alignItems='center'
    >
      <SubjectPropertyIcon boxSize={2} />
    </Flex>
    {props.subjectStat.map((comp, i) => (
      <Flex
        flex='1 0 23%'
        key={i}
        flexDir='column'
        gap={1}
        py={1}
        pr={mbp({ mobSm: i === props.subjectStat.length - 1 ? 1 : 0, tabSm: 0 })}
      >
        <Text
          textStyle='bodySFat'
          textAlign='center'
          color='ondark.1'
        >
          {comp.label}
        </Text>
        <Text
          textStyle='bodyM'
          color='ondark.1'
          textAlign='center'
        >
          {comp.value}
        </Text>
      </Flex>
    ))}
  </Flex>
)
