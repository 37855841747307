import { Center, Grid, GridItem, HStack, Text } from '@chakra-ui/react'
import { Radio } from 'presentation/components/Radio/Radio'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { noop } from 'remeda'

export const ListTypeCard = () => (
  <Card
    variant='default'
    colorScheme='card.bg.1'
    size={mbp({
      mobSm: 'xs-locked',
      mob: 'sm-locked',
    })}
  >
    <CardHeader>
      <CardSecondaryTitle justifySelf='flex-start'>
        What type of list would you like to build?
      </CardSecondaryTitle>

      <Grid
        mt={mbp({
          mobSm: 2,
          mob: 3,
        })}
        gridTemplate={mbp({
          mobSm: 'repeat(3, min-content) / 1fr',
          tabSm: 'repeat(1, 1fr) / repeat(2, 1fr)',
          dtSm: '1fr / repeat(3, 1fr)',
        })}
        gap={mbp({
          mobSm: 2,
          dtSm: 3,
        })}
      >
        <GridItem>
          <ListTypeOptionCard
            title='Properties'
            description='Explore a variety of property types to find your ideal investment opportunity or dream home.'
            isSelected={true}
            onSelect={noop}
          />
        </GridItem>
        {/* <GridItem>
          <ListTypeOptionCard
            title='Cash Buyers'
            description='Connect with motivated investors who are ready to purchase properties with cash or hard money.'
            isSelected={false}
            onSelect={noop}
            isInDev
          />
        </GridItem>
        <GridItem>
          <ListTypeOptionCard
            title='Private Lender'
            description='Access a pool of private lenders who offer alternative financing options for real estate investments.'
            isSelected={false}
            onSelect={noop}
            isInDev
          />
        </GridItem> */}
      </Grid>
    </CardHeader>
  </Card>
)

const ListTypeOptionCard = ({
  title,
  description,
  isSelected,
  isInDev,
}: {
  title: string
  description: string
  isSelected: boolean
  onSelect: () => void
  isInDev?: boolean
}) => (
  <Card
    p={mbp({
      mobSm: 2,
      dtSm: 3,
    })}
    bg='highlight.100'
    borderColor={isSelected ? 'neutral.500' : 'highlight.100'}
    borderWidth={0.25}
    borderRadius={mbp({
      mobSm: 3,
      mob: 4,
    })}
    h='full'
    display='flex'
    flexDirection='column'
    cursor={isInDev ? 'not-allowed' : 'pointer'}
    pos='relative'
  >
    <Text
      color='graystrong.500'
      textStyle={mbp({
        mobSm: 'bodyMFat',
        mob: 'bodyLFat',
      })}
      flex='1'
    >
      {description}
    </Text>

    <HStack
      spacing={1}
      mt={mbp({
        mobSm: 2,
        dtSm: 5,
      })}
    >
      <Radio
        isChecked={isSelected}
        role='presentation'
        size={mbp({
          mobSm: 'sm',
          mob: 'md',
        })}
        variant='default'
        colorScheme='neutral'
        tabIndex={0}
        isDisabled={isInDev}
      >
        {title}
      </Radio>
    </HStack>

    {isInDev && (
      <Center
        pos='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
        bg='modal.blur'
        borderRadius={3}
        cursor='not-allowed'
      >
        <Text
          color='ondark.1'
          textStyle={mbp({
            mobSm: 'bodyLFat',
            mob: 'bodyXLFat',
          })}
        >
          Currently in development!
        </Text>
      </Center>
    )}
  </Card>
)
