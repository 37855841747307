import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { is } from 'presentation/utils/graphql'

export const PAYMENT_DETAILS_MODAL__DEFAULT_PAYMENT_METHOD = gql(/* GraphQL */ `
  query PaymentDetailsModal_DefaultPaymentMethod {
    myEnterprise {
      id
      paymentMethods {
        edges {
          node {
            __typename
            ...on BillingCardsPaymentMethod {
              id
              isDefault
            }
            ...DefaultPaymentMethodSegment__PaymentMethodFragment
          }
        }
      }
    }
  }
`)

export const useDefaultPaymentMethodQuery = () => {
  const { data, error, loading } = useQuery(PAYMENT_DETAILS_MODAL__DEFAULT_PAYMENT_METHOD)
  const defaultPaymentMethod = data?.myEnterprise?.paymentMethods?.edges
    ?.map(edge => edge?.node)
    ?.filter(is('BillingCardsPaymentMethod'))
    ?.find(paymentMethod => paymentMethod?.isDefault)

  return {
    data: defaultPaymentMethod,
    loading,
    error,
  }
}
