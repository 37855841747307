import { gql } from '__generated__'

export const ACCOUNT_BALANCE__GET_BALANCE = gql(/* GraphQL */ `
  query AccountBalance_GetBalance {
    myEnterprise {
      id
      wallet {
        balance {
          available
        }
      }
      subscription {
        ...FailedInvoiceFlow_FailedInvoiceSummary
      }
    }
  }
`)
