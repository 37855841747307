import { Avatar, Button, Input as ChakraInput, Flex, FormControl, FormErrorMessage, FormLabel, Grid, InputProps, forwardRef } from '@chakra-ui/react'
import { PersonName } from 'features/valueObjects/PersonName'
import { Blob, blob } from 'libs/blob'
import { UploadIcon } from 'presentation/components/Icons'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardHeader, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { MemberViewModalUpdatePayload, UserInfoViewModel } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoModal.viewModel'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { isNonNullable } from 'utils/isNonNullable'

/**
 * @NOTE Should have a clear photo in case user change their mind to not want to upload a photo but already have a photo preview
 */

type FormValues = Omit<MemberViewModalUpdatePayload, 'id' | 'avatarFile' >

/**
 * @TODO:
 * - Reset fields when submission succeeds
 */
export const UserInfoCard: FC<UserInfoViewModel> = viewModel => {
  const member = viewModel.member

  const savedMemberName = PersonName.combine(
    viewModel.member.firstName,
    viewModel.member.lastName,
  )

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: localErrors },
    setValue,
    setError,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      email: member.email || '',
      firstName: member.firstName || '',
      lastName: member.lastName || '',
      phone: member.phone || '',
    },
  })

  /**
   * ========================
   * Avatar
   * ========================
   */
  const [avatarFile, setAvatarFile] = useState<File | null>(null)
  const [avatarBlob, setAvatarBlob] = useState<Blob | null>(null)
  useEffect(() => {
    if (!avatarFile) {
      setAvatarBlob(null)
      return
    }

    /** @TODO Handle blob convertion error */
    void blob.fromFile(avatarFile)
      .then(setAvatarBlob)
  }, [avatarFile])
  const avatarUrlOrBlob = avatarBlob ?? member.avatarUrl

  /**
   * ========================
   * Form Watch
   * ========================
   */
  const formattedPhoneNumber = formatPhoneNumber(watch('phone') ?? null) ?? ''

  /**
   * ========================
   * useEffects
   * ========================
   */

  useEffect(() => {
    if (viewModel.status !== 'partial-error') return

    const fields = viewModel.error.fields

    if (isNonNullable(fields.email))
      setError('email', { type: 'custom', message: fields.email.message })
    if (isNonNullable(fields.firstName))
      setError('firstName', { type: 'custom', message: fields.firstName.message })
    if (isNonNullable(fields.lastName))
      setError('lastName', { type: 'custom', message: fields.lastName.message })
    if (isNonNullable(fields.phone))
      setError('phone', { type: 'custom', message: fields.phone.message })
  }, [viewModel])

  useEffect(() => {
    register('phone')
  }, [])

  /**
   * ========================
   * Special error handling
   * ========================
   */
  const avatarErrMsg = viewModel.status === 'partial-error'
    && viewModel.error.fields.avatarFile?.message

  /**
   * Because avatar element has no spot to display error message,
   * just toast it
   */
  useEffect(() => {
    if (!avatarErrMsg) return
    toast.error({ title: avatarErrMsg })
  }, [avatarErrMsg])

  /**
   * ========================
   * Handlers
   * ========================
   */

  const handleFormSubmission = handleSubmit(data => {
    viewModel.onSave(
      {
        id: viewModel.member.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        ...avatarFile && { avatarFile },
      } satisfies MemberViewModalUpdatePayload)
  })

  const handlePhoneNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const stripped = stripPhoneNoFormatting(value)
    setValue('phone', stripped)
  }

  const handleOnCancel = () => {
    reset()
    viewModel.onCancel()
  }

  /**
   * ========================
   * Form Registers
   * ========================
   */
  const emailRegister = register('email', {
    required: 'Email is required',
    // @TODO replace with yup or joi
    pattern: {
      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
      message: 'Invalid email address',
    },
  })

  const firstNameRegister = register('firstName', {
    required: 'First name is required',
  })

  const lastNameRegister = register('lastName', {
    required: 'Last name is required',
  })

  return (
    <Card
      size='modal.default.n'
      colorScheme='modal.highlight'
    >
      <CardHeader>
        <CardPrimaryTitle display={mbp({ mobSm: 'none', mob: 'block' })}>
          Edit Team Member&apos;s Info
        </CardPrimaryTitle>
      </CardHeader>
      <CardBody
        shouldResetSpacing
        mt={mbp({ mobSm: 2, mob: 3 })}
      >
        <AvatarUploader
          avatar={avatarUrlOrBlob}
          onAvatarChange={setAvatarFile}
          isDisabled={viewModel.status === 'loading'}

          /**
           * @NOTE Use the saved member name vs the name from the form values.
           *   Because without avatar, Chakra UI calculates the color based on
           *   the name, and every keystroke as the user types the name the
           *   avatar color changes, creating a weird UX.
           */
          name={savedMemberName}
        />

        <form
          id='edit-info-form'
          onSubmit={handleFormSubmission}
        >
          <Grid
            mt={mbp({ mobSm: 3, mob: 4, tabSm: 5 })}
            columnGap={2}
            rowGap={3}
            gridTemplateColumns={mbp({ mobSm: '1fr', tabSm: 'repeat(2, 1fr)' })}
          >
            <FormControl isInvalid={!!localErrors.firstName}>
              <FormLabel>First Name</FormLabel>
              <Input
                {...firstNameRegister}
                isInvalid={!!localErrors.firstName}
                isDisabled={viewModel.status === 'loading'}
              />
              <FormErrorMessage>{localErrors.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!localErrors.lastName}>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...lastNameRegister}
                isInvalid={!!localErrors.lastName}
                isDisabled={viewModel.status === 'loading'}
              />
              <FormErrorMessage>{localErrors.lastName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!localErrors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                {...emailRegister}
                isInvalid={!!localErrors.email}
                isDisabled={viewModel.status === 'loading'}
              />
              <FormErrorMessage>{localErrors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!localErrors.phone}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                type='text'
                value={formattedPhoneNumber}
                onChange={handlePhoneNumberChanged}
                isDisabled={viewModel.status === 'loading'}
              />
              <FormErrorMessage>{localErrors.phone?.message}</FormErrorMessage>
            </FormControl>
          </Grid>
        </form>
      </CardBody>
      <CardFooter>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          onClick={handleOnCancel}
          isDisabled={viewModel.status === 'loading'}
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='positive'
          form='edit-info-form'
          isLoading={viewModel.status === 'loading'}
          type='submit'
          loadingText='Saving'
        >
          Save
        </CardButton>
      </CardFooter>
    </Card>
  )
}

type AvatarUploaderViewModel = {
  avatar: string | null
  name: string | null
  onAvatarChange: (avatar: File | null) => void
  isDisabled: boolean
}

const AvatarUploader: FC<AvatarUploaderViewModel> = ({
  avatar,
  name,
  onAvatarChange,
  isDisabled,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleOpenFileDialog = () => {
    fileInputRef.current?.click()
  }

  const handleOnAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null
    onAvatarChange(file)
  }

  return (
    <Flex
      flexDir='column'
      alignItems='center'
      gap={1}
    >
      <Avatar
        name={name ?? undefined}
        src={avatar ?? undefined}
        size='lg'
      />
      <Button
        variant='ghost'
        colorScheme='neutral'
        leftIcon={<UploadIcon boxSize={3} />}
        onClick={handleOpenFileDialog}
        isDisabled={isDisabled}
      >
        Upload Photo
      </Button>
      <Input
        type='file'
        ref={fileInputRef}
        onChange={handleOnAvatarChange}
        display='none'
      />
    </Flex>
  )
}

const Input = forwardRef<InputProps, 'input'>((props, ref) => (
  <ChakraInput ref={ref} variant='contained-md' {...props} />
))

Input.displayName = 'Input'

// @NOTE what if there are different formats? what if there is country code like prefix +1
export const formatPhoneNumber = (value: string | null) => {
  if (!value) return value
  const phone = value.replace(/[^\d]/g, '')
  const phoneLength = phone.length

  if (phoneLength < 4)
    return phone

  if (phoneLength < 7)
    return `(${phone.slice(0, 3)}) ${phone.slice(3)}`

  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
}

export const stripPhoneNoFormatting = (formattedPhoneNumber: string) => formattedPhoneNumber.replace(/\D/g, '')
