import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'
import { merge } from 'lodash/fp'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(({ colorScheme, variant }) => merge(
  {
    container: {
      alignItems: 'center',
      display: 'flex',
      w: 'auto',
      gap: '1',
    },
    title: {},
    description: {},
    icon: {
      m: '0',
      ...mbpg({
        mobSm: { fontSize: '3', boxSize: '3' },
        tabSm: { fontSize: '4', boxSize: '4' },
      }),
    },
    spinner: {
    },
  },
  getRecKey(variant)({
    'bordered': merge(
      {
        container: {
          px: '2',
          py: '1',
          borderWidth: '0.125',
          borderRadius: '2',
        },
        description: {
          ...mbpg({
            mobSm: textStyles.bodyL,
            tabSm: textStyles.bodyXL,
          }),
        },
      },
      getRecKey(colorScheme)({
        negative: {
          container: {
            bg: 'negative.50',
            borderColor: 'negative.500',
          },
          icon: {
            color: 'negative.500',
          },
          description: {
            color: 'negative.800',
          },
        },
        neutral: {
          container: {
            bg: 'neutral.50',
            borderColor: 'neutral.500',
          },
          icon: {
            color: 'neutral.600',
          },
          description: {
            color: 'neutral.800',
          },
        },
        positive: {
          container: {
            bg: 'positive.50',
            borderColor: 'positive.500',
          },
          icon: {
            color: 'positive.600',
          },
          description: {
            color: 'positive.800',
          },
        },
      }),
    ),
    'ghost': merge(
      {
        container: {
          px: '0',
          py: '0',
          borderWidth: '0',
          borderRadius: '0',
        },
        description: {
          ...mbpg({
            mobSm: textStyles.bodyM,
            tabSm: textStyles.bodyL,
          }),
        },
      },
      getRecKey(colorScheme)({
        negative: {
          container: {},
          icon: {
            color: 'negative.500',
          },
          description: {
            color: 'graystrong.200',
          },
        },
        neutral: {
          container: {},
          icon: {
            color: 'neutral.600',
          },
          description: {
            color: 'graystrong.200',
          },
        },
        positive: {
          container: {},
          icon: {
            color: 'positive.600',
          },
          description: {
            color: 'graystrong.200',
          },
        },
      }),
    ),
    'ghost-triggered': merge(
      {
        container: {
          px: '0',
          py: '0',
          borderWidth: '0',
          borderRadius: '0',
        },
        description: {
          ...mbpg({
            mobSm: textStyles.bodyM,
            tabSm: textStyles.bodyL,
          }),
        },
      },
      getRecKey(colorScheme)({
        negative: {
          container: {},
          icon: {
            color: 'negative.500',
          },
          description: {
            color: 'negative.500',
          },
        },
        neutral: {
          container: {},
          icon: {
            color: 'neutral.600',
          },
          description: {
            color: 'negative.500',
          },
        },
        positive: {
          container: {},
          icon: {
            color: 'positive.600',
          },
          description: {
            color: 'negative.500',
          },
        },
      }),
    ),
  })))

const bordered = definePartsStyle(() => ({}))

const variants = {
  bordered,
}

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'bordered',
    colorScheme: 'negative',
  },
})
