import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { NumberLib } from 'libs/Number'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { EmptyCard } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/EmpyCard'
import { TableSectionCommon } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.common'

export const LandTabContent = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const pairs = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details' || !property.land) return null

    const land = property.land
    const pairs = [
      ['APN', TableSectionCommon.formatString(land?.assessorParcelNumber)],
      ['Property Class', TableSectionCommon.formatString(land?.standardizedUseClass)],
      ['Property Type', TableSectionCommon.formatString(land?.standardizedUseType)],
      ['Census Tract', TableSectionCommon.formatString(land?.censusTract)],
      ['Lot Size Acres', NumberLib.formatCommasDecimalsOrDoubleDash(land?.lotAreaAcres)],
      ['Lot', TableSectionCommon.formatString(land?.lot)],
      ['Block', TableSectionCommon.formatString(land?.block)],
      ['Subdivision', TableSectionCommon.formatString(land?.subdivision)],
      ['Lot Size Sqft', TableSectionCommon.formatNumber(land?.lotAreaSqft)],
      ['County', TableSectionCommon.formatString(land?.county)],
      ['Legal Description', TableSectionCommon.formatString(land?.legalDescription)],
    ] satisfies TabbedCards.CardSectionProps['pairs']

    return pairs
  })

  return (
    <TabbedCards.TabContent>
      {pairs === null
        ? (
          <EmptyCard />
        )
        : (
          <TabbedCards.CardSection
            label={null}
            upperSegmentText='Public record'
            pairs={pairs}
          />
        )}
    </TabbedCards.TabContent>
  )
}
