import { Match } from 'effect'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { useStore } from 'features/ListBuilder/infra/react/ListBuilderStore/useStore'

export const useExcludeProperties = (): UseCriteriaHookApi<boolean> =>
  useStore(store => ({
    value: store.criteria?.excludePropertiesFromOtherList ?? false,
    apply: value => Match.value(store.criteria).pipe(
      Match.tag('PropertyListCriteria', oldCriteria => {
        const newCriteria = PropertyListCriteria.with(oldCriteria, {
          excludePropertiesFromOtherList: value,
        })
        store.buildList.execute(newCriteria)
      }),
      Match.when(Match.null, () => {}),
      Match.exhaustive,
    ),
  }))
