import { Checkbox, Flex, FlexProps } from '@chakra-ui/react'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'

const InvestorTypeFilterCheckboxGroup = (props: FlexProps) => {
  const shouldIncludeLandLords = NearbyBuyersHooks.useShouldIncludeLandLords()
  const toggleShouldIncludeLandLords = NearbyBuyersHooks.useToggleShouldIncludeLandLords()
  const shouldIncludeFlippers = NearbyBuyersHooks.useShouldIncludeFlippers()
  const toggleFlippersFilters = NearbyBuyersHooks.useToggleFlippersFilters()

  return (
    <Flex
      gap={2}
      alignItems='center'
      {...props}
    >
      <Checkbox
        ml='auto'
        color='graystrong.400'
        isChecked={shouldIncludeLandLords}
        onChange={() => toggleShouldIncludeLandLords()}
      >
        Landlords
      </Checkbox>

      <Checkbox
        color='graystrong.400'
        isChecked={shouldIncludeFlippers}
        onChange={() => toggleFlippersFilters()}
      >
        Flippers
      </Checkbox>
    </Flex>
  )
}

export default InvestorTypeFilterCheckboxGroup
