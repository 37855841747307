import { Box, Button, Checkbox, Flex, FlexProps } from '@chakra-ui/react'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useCancelSubscriptionFooter } from './hooks/useCancelSubscriptionFooter'

export const CancelSubscriptionFooter: FC<FlexProps & {
  periodEndFormatted: string | null
}> = ({ periodEndFormatted, ...props }) => {
  const {
    handleKeepMyAccount,
    loading,
    control,
    handleSubmit,
    isValid,
    isSubmitted,
  } = useCancelSubscriptionFooter({ periodEndFormatted })

  return (
    <Box
      as='form'
      onSubmit={handleSubmit}
      {...props}
    >
      <Flex
        bgColor={!isValid && isSubmitted ? 'negative.100' : 'accent.hover'}
        justifyContent='center'
      >
        <Box
          px={mbp({
            mobSm: '2',
            mob: '3',
            tabSm: '5',
            dtSm: '7',
          })}
          py={mbp({
            mobSm: '2',
            mob: '2',
            dtSm: '3',
          })}
          maxW='1080px'
        >
          <Controller
            rules={{ required: true }}
            name='hasAcknowledged'
            control={control}
            render={({ field: { value, ...field } }) => (
              <Checkbox
                textStyle={mbp({ mobSm: 'body2', tabSm: 'old.h4', dtSm: 'old.h3' })}
                color='graystrong.500'
                isChecked={value}
                spacing='2'
                {...field}
              >
                I acknowledge all of my account information, including skip data,
                websites, saved properties and more will be
                {' '}
                <em>permanently</em>
                {' '}
                deleted.
              </Checkbox>
            )}
          />
        </Box>
      </Flex>
      <Flex
        bgColor='highlight.500'
        justifyContent='center'
        px={mbp({
          mobSm: '2',
          mob: '5',
          tabSm: '5',
          dtSm: '7',
        })}
        pt={mbp({
          mobSm: '3',
          tabSm: '4',
          dtSm: '5',
          dtLg: '6',
        })}
        pb={mbp({
          mobSm: '4',
          dtSm: '7',
          dtLg: '80px',
        })}
      >
        <Flex
          flex='1'
          justifyContent={mbp({
            mobSm: 'center',
            mob: 'space-between',
          })}
          alignItems='center'
          maxW={mbp({ dtSm: '1136px', dtLg: '896px' })}
          flexDir={mbp({
            mobSm: 'column',
            mob: 'row',
          })}
          gap={mbp({
            mobSm: '2',
            dtSm: '4',
          })}
        >
          <Button
            size={mbp({ mobSm: 'sm', tabSm: 'md', dtSm: 'lg' })}
            variant='solid'
            colorScheme='neutral'
            onClick={handleKeepMyAccount}
            isDisabled={loading}
            w={mbp({ mobSm: 'full', mob: 'auto' })}
            textTransform='uppercase'
          >
            Keep My Account
          </Button>

          <Button
            size={mbp({ mobSm: 'sm', tabSm: 'md', dtSm: 'lg' })}
            variant='solid'
            colorScheme='negative'
            isLoading={loading}
            type='submit'
            w={mbp({ mobSm: 'full', mob: 'auto' })}
            textTransform='uppercase'
            spinnerPlacement='end'
            loadingText='Canceling'
          >
            Continue To Cancel
          </Button>
        </Flex>
      </Flex>
    </Box>
  )
}
