import { useActorRef as useXStateActorRef } from '@xstate/react'
import { Array, Effect, Option, Runtime, pipe } from 'effect'
import { EffectLib } from 'libs/Effect'
import PlanCheckoutService from 'presentation/screens/Plans/PlanComparisonV2/domain/PlanCheckoutService/PlanCheckoutService'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import SubscriptionProductActive from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProductActive'
import SubscriptionProductRepo from 'presentation/screens/Plans/PlanComparisonV2/domain/SubscriptionProductRepo'
import React, { PropsWithChildren, createContext, useRef } from 'react'
import { ActorLogicFrom, ActorRefFrom, SnapshotFrom, assertEvent, assign, fromPromise, setup } from 'xstate'

namespace PlanComparisonMachine {

  export type MachineModel = {
    isYearly: boolean
    yearlySavings: number
    products: SubscriptionProduct[]
    activeProduct: Option.Option<SubscriptionProductActive>
    disableFreePlan: boolean
  }

  export type Event =
    | { type: 'load-products' }
    | { type: 'toggle-interval' }
    | { type: 'add-to-cart', targetProduct: SubscriptionProduct }

  export type ActorRef =
    ActorRefFrom<Effect.Effect.Success<ReturnType<typeof make>>>

  export type ActorLogic =
    ActorLogicFrom<Effect.Effect.Success<ReturnType<typeof make>>>

  export type Snapshot = SnapshotFrom<ActorRef>

  export type Requirements =
    Effect.Effect.Context<ReturnType<typeof make>>

  export const make = () => Effect.gen(function*() {
    const getProducts = yield * SubscriptionProductRepo.GetProducts
    const getActiveProducts = yield * SubscriptionProductRepo.GetActive

    const runFork = yield * pipe(
      Effect.runtime(),
      Effect.andThen(Runtime.runPromise),
    )

    const addToCart = yield * pipe(
      PlanCheckoutService.AddToCart,
      Effect.andThen(f => EffectLib.toPromiseActor(f)),
    )

    return setup({
      types: {} as {
        context: MachineModel
        events: Event
      },
      actors: {
        addToCart,
        loadProducts: fromPromise(async () => {
          const products = await getProducts().pipe(runFork)
          const activeProducts = await getActiveProducts().pipe(runFork)
          return {
            products,
            activeProducts,
          }
        }),
      },
    }).createMachine({
      context: {
        isYearly: false,
        yearlySavings: 0,
        products: [],
        activeProduct: Option.none(),
        disableFreePlan: false,
      },
      on: {
        'toggle-interval': {
          actions: assign({
            isYearly: ({ context }) => !context.isYearly,
          }),
        },
      },
      initial: 'Loading',
      states: {
        Idle: {
          on: {
            'load-products': 'Loading',
            'add-to-cart': 'AddingToCart',
          },
        },
        AddingToCart: {
          tags: ['loading'],
          invoke: {
            src: 'addToCart',
            input: ({ event, context }) => {
              assertEvent(event, 'add-to-cart')
              return {
                targetProduct: event.targetProduct,
                activeProduct: context.activeProduct,
                selectedPlanId: pipe(
                  event.targetProduct.plans,
                  Array.findFirst(p => context.isYearly ? p._tag === 'YearlyPlan' : p._tag === 'MonthlyPlan'),
                  Option.map(p => p.id),
                ),
              }
            },
            onDone: {
              target: 'Idle',
            },
          },
        },
        Loading: {
          tags: ['loading', 'products-unready'],
          invoke: {
            src: 'loadProducts',
            onDone: {
              target: 'Idle',
              actions: assign({
                products: ({ event }) => event.output.products,
                activeProduct: ({ event }) => event.output.activeProducts,
                yearlySavings: ({ event }) =>
                  SubscriptionProduct.calculateYearlySavings(event.output.products),
                disableFreePlan: ({ event }) => pipe(
                  event.output.activeProducts,
                  Option.match({
                    onNone: () => false,
                    onSome: activeProduct => activeProduct.name !== 'free',
                  }),
                ),
              }),
            },
          },
        },
      },
    })
  })

  export const useActorRef = (runtime: Runtime.Runtime<Requirements>) => {
    const machineRef = useRef<ActorLogic>()

    if (!machineRef.current)
      machineRef.current = make().pipe(Runtime.runSync(runtime))

    return useXStateActorRef(machineRef.current)
  }

  const Context = createContext<Option.Option<ActorRef>>(Option.none())

  export const useActorRefFromContext = () => {
    const context = React.useContext(Context)
    return Option.getOrThrowWith(context,
      () => new Error('No PlanComparisonMachine Provider'))
  }

  export const Provider = (props: PropsWithChildren<{
    runtime: Runtime.Runtime<Requirements>
  }>) => {
    const actorRef = useActorRef(props.runtime)
    return (
      <Context.Provider value={Option.some(actorRef)}>
        {props.children}
      </Context.Provider>
    )
  }
}

export default PlanComparisonMachine
