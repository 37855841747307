import { globalStyles } from 'presentation/components/Toast/Toast.styles'
import { GRID_OF_8 } from 'presentation/const/layout.const'
import { SHADOWS } from 'presentation/main/themes/common/shadows.theme'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { zIndices } from 'presentation/main/themes/common/zIndex.theme'
import { components } from '../components/components.theme'
import { breakpointTheme } from './breakpoints.theme'
import { sizes } from './sizes.common.theme'

export const commonTheme = {
  styles: {
    global: {
      ...globalStyles,

      // Default chakra global styles
      'body': {
        fontFamily: 'body',
        color: 'chakra-body-text',
        bg: 'graycool.100',
        transitionProperty: 'background-color',
        transitionDuration: 'normal',
        lineHeight: 'base',
      },
      '*::placeholder': {
        color: 'chakra-placeholder-color',
      },
      '*, *::before, &::after': {
        borderColor: 'chakra-border-color',
      },
    },
  },

  config: { initialColorMode: 'light', useSystemColorMode: true },

  borderWidths: GRID_OF_8,

  textStyles,

  lineHeights: sizes,

  fonts: { heading: 'Inter', body: 'Lato' },

  fontSizes: GRID_OF_8,

  space: sizes,

  sizes: { ...sizes, max: 'max-content', min: 'min-content', full: '100%' },

  radii: { ...sizes, full: '9999px' },

  components,

  zIndices,

  shadows: SHADOWS,

  breakpoints: breakpointTheme,
}
