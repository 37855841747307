import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { formatPenniesOptionalDecUsd, formatPenniesOptionalDecUsdOr } from 'utils/dataAdapter'

type PriceFeatureRowViewModel = {
  saveUpToPriceText: string
  isYearly: boolean
  columnValues: {
    textColor: string
    primaryText: string
    primaryTextPrefix: string
    secondaryText: Option.Option<string>
    crossedOutText: Option.Option<string>
    sloganText: string
    isPopular: boolean
  }[]
}

type ColumnValues = PriceFeatureRowViewModel['columnValues'][number]

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const { context } = snapshot

  const saveUpToPrice = context.yearlySavings

  const vm = Data.struct<PriceFeatureRowViewModel >({
    saveUpToPriceText: formatPenniesOptionalDecUsdOr('UNKNOWN')(saveUpToPrice),

    isYearly: context.isYearly,

    columnValues: pipe(
      context.products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.map(product => Data.struct<ColumnValues>({

        textColor: pipe(
          Match.value(product.name),
          Match.when('free', () => 'graystrong.200'),
          Match.when('beginner', () => 'accent.gold.200'),
          Match.when('intermediate', () => 'positivesat.500'),
          Match.when('advanced', () => 'special.400'),
          Match.orElse(() => { throw Error('Product not found') }),
        ),

        primaryText: pipe(
          product.plans,
          Array.findFirst(plan =>
            plan._tag === (context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
          Option.map(plan => pipe(
            Match.value(plan),
            Match.tag('YearlyPlan', p => p.pricePerMonth),
            Match.tag('MonthlyPlan', p => p.price),
            Match.exhaustive,
          )),
          Option.map(price => formatPenniesOptionalDecUsd(price)),
          Option.getOrThrowWith(() => new Error('Plan not found')),
        ),

        primaryTextPrefix: '/mo',

        secondaryText: pipe(
          Match.value(product.name),
          Match.when('free', () => Option.some('free forever')),
          Match.orElse(() => pipe(
            product.plans,
            Array.findFirst(plan =>
              plan._tag === (context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
            Option.flatMap(plan => pipe(
              Match.value(plan),
              Match.tag('YearlyPlan', p => Option.some(p.price)),
              Match.tag('MonthlyPlan', () => Option.none()),
              Match.exhaustive,
            )),
            Option.map(price => `${formatPenniesOptionalDecUsd(price)} billed yearly`),
          )),
        ),

        crossedOutText: pipe(
          Match.value(product.name),
          Match.when('free', () => Option.none()),
          Match.orElse(() => pipe(
            product.plans,
            Array.findFirst(plan =>
              plan._tag === (context.isYearly ? 'MonthlyPlan' : 'YearlyPlan')),
            Option.flatMap(plan => pipe(
              Match.value(plan),
              Match.tag('YearlyPlan', () => Option.none()),
              Match.tag('MonthlyPlan', () => Option.some(plan.price)),
              Match.exhaustive,
            )),
            Option.map(price => `${formatPenniesOptionalDecUsd(price)}/mo`),
          )),
        ),

        sloganText: product.description,

        isPopular: product.name === 'intermediate',
      })),
    ),
  })

  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const PriceFeatureRowViewModel = {
  usePresenter,
}

export default PriceFeatureRowViewModel
