import { Box } from '@chakra-ui/react'
import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { Location } from 'features/valueObjects/Location'
import { Map } from 'presentation/components/Map/Map'
import { useClickOnMapCanvas } from 'presentation/components/Map/useClosePopupOnMapClick'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { useRecenterOnHighlightMarker, useRecenterOnMarkersUpdate } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { SearchHistoryMapAndListSync } from 'presentation/screens/SearchHistoryScreen/SearchHistoryMapAndListSync'
import { useSearchHistoryListPanelStore } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { SearchEntryMapMarker } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/MapMarkers/SearchEntryMapMarker'
import { getSearchEntryMarkerMapPopupOverrides } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/SearchEntryMarker'
import { useInitialGlobeViewState } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryMap/useInitialGlobeViewState'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { useLayoutEffect, useMemo } from 'react'
import { NavigationControl } from 'react-map-gl'
import { identity } from 'remeda'
import { PropertyMapMarker } from './MapMarkers/PropertyMapMarker'

export const SearchHistoryMap = () => {
  const { containerRef, initialViewState } = useInitialGlobeViewState()

  return (
    <Box
      h='100%'
      sx={getSearchEntryMarkerMapPopupOverrides()}
      ref={containerRef}
    >
      {initialViewState && (
        <Map
          initialViewState={initialViewState}
        >
          <MapMarkers />
          <NavigationControl />
        </Map>
      )}
    </Box>
  )
}

const MapMarkers = () => {
  const { isPanelOpen } = useSearchHistoryListPanelStore()

  const history = useSearchHistoryStore(api => api.remote.history)

  const entries = useMemo(() =>
    history.status === 'success'
      ? history.data.entries
      : [], [history])

  const markers = useMemo(() =>
    entries.map(entry => ({ id: entry.id, location: entry.property.location })),
  [entries])

  const markersWithLocation = useMemo(() =>
    markers.filter(Location.hasLocation),
  [markers])

  const highlightedEntry = SearchHistoryMapAndListSync.useStore(
    state => state.highlightedMapMarker,
  )
  const highlightedEntryData = entries.find(entry => entry.id === highlightedEntry?.id)

  const map = useMapStore(identity)

  const currentMap = map.computed.getMapRef()

  useRecenterOnHighlightMarker({
    highlightedEntry,
    markers: markersWithLocation,
  })

  useRecenterOnMarkersUpdate({
    markers: markersWithLocation,
    // Skip the first recenter because it's handled by useInitialGlobeViewState
    getShouldSkipRecenter: nth => nth === 1,
  })

  useLayoutEffect(() => {
    currentMap?.resize()
  }, [isPanelOpen])

  useClickOnMapCanvas({
    mapRef: currentMap,
    onMapCanvasClick: () => {
      SearchHistoryMapAndListSync.setHighlightedEntry(null)
    },
  })

  const handleOnMarkerHover = (entry: SearchHistory.Entry) => {
    if (IS_TOUCH_DEVICE) return

    SearchHistoryMapAndListSync.handleMapMarkerHover(entry.id)
  }

  const handleOnMarkerUnhover = () => {
    if (IS_TOUCH_DEVICE) return

    SearchHistoryMapAndListSync.handleMapMarkerUnhover()
  }

  const handleOnMarkerClick = (entry: SearchHistory.Entry) => {
    SearchHistoryMapAndListSync.handleMapMarkerClick(entry.id, {
      shouldClearIfHighlighted: true,
    })
  }

  const markersJsx = entries.map(entry =>
    !entry.property.location
      ? null
      : (
        <Box
          key={entry.id}
          onMouseEnter={() => handleOnMarkerHover(entry)}
          onMouseLeave={() => handleOnMarkerUnhover()}
          onClick={() => handleOnMarkerClick(entry)}
        >
          <PropertyMapMarker
            isHighlighted={highlightedEntryData?.id === entry.id}
            size='md'
            classification={entry.property.ownership?.classification ?? null}
            equityType={entry.property.valuation?.equityType ?? null}
            isForeclosure={entry.property.ownership?.isForeclosure ?? false}
            isSenior={entry.property.ownership?.isSenior ?? false}
            isVacant={entry.property.ownership?.isVacant ?? false}
            latitude={entry.property.location.latitude}
            longitude={entry.property.location.longitude}
          />
        </Box>
      ),
  )

  return (
    <>
      {highlightedEntryData && (
        <SearchEntryMapMarker
          /**
           * Rerender when entry changes to retrigger the animation
           */
          key={highlightedEntryData.id}
          entry={highlightedEntryData}
        />
      )}

      {markersJsx}
    </>
  )
}
