import { useSelector } from '@xstate/react'
import { Array, Equal, pipe } from 'effect'
import PlanCompactViewModel from 'presentation/screens/Plans/PlanComparisonV2/PlanCompact/PlanCompactViewModel'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import { formatPenniesOptionalDecUsdOr } from 'utils/dataAdapter'

type ChoosePlanSectionViewModel = {
  saveUpToText: string
  planCards: PlanCompactViewModel[]
}

const makePresenter = (
  actor: PlanComparisonMachine.ActorRef,
  snapshot: PlanComparisonMachine.Snapshot,
): ChoosePlanSectionViewModel => {
  const { products, isYearly, disableFreePlan } = snapshot.context
  const vm: ChoosePlanSectionViewModel = {
    saveUpToText: pipe(
      SubscriptionProduct.calculateYearlySavings(products),
      formatPenniesOptionalDecUsdOr('UNKNOWN'),
    ),
    planCards: pipe(
      products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.map(product => PlanCompactViewModel.makePresenter({
        isYearly,
        product,
        addToCart: product => actor.send({ type: 'add-to-cart', targetProduct: product }),
        disableFreePlan,
      })),
    ),
  }
  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    snapshot => makePresenter(actor, snapshot),
    Equal.equals,
  )
}

const ChoosePlanSectionViewModel = {
  usePresenter,
}

export default ChoosePlanSectionViewModel
