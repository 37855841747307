import { Flex, Grid, Text } from '@chakra-ui/react'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { StatPair } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard.props'

/**
 * Beds, Baths, Garage, Sqft, Built, Lot, Acres
 */

export type SubjectStatsGridProps = {
  subjectStat: StatPair[]
}

export const SubjectStatsGrid = (props: SubjectStatsGridProps) => (
  <Grid
    borderRadius={3}
    overflow='hidden'
    gridTemplateColumns={`min-content repeat(${props.subjectStat.length}, 1fr)`}
    gridTemplateRows='minmax(0, 1fr)'
    bgColor='accent.darkpurple'
    p={1}
  >
    <Flex
      alignSelf='center'
      h='full'
      alignItems='center'
      justifyContent='center'
    >
      <SubjectPropertyIcon boxSize={3} />
    </Flex>
    {props.subjectStat.map((comp, i) => (
      <Flex
        key={i}
        flexDir='column'
        gap={1}
        color='ondark.1'
      >
        <Text
          textStyle='bodySFat'
          color='inherit'
          textAlign='center'
        >
          {comp.label}
        </Text>
        <Text
          textStyle='bodyM'
          color='inherit'
          textAlign='center'
        >
          {comp.value}
        </Text>
      </Flex>
    ))}
  </Grid>
)
