import { createIcon } from '@chakra-ui/react'

export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 16 16',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M8 13.3334H14' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11 2.33328C11.2652 2.06806 11.6249 1.91907 12 1.91907C12.1857 1.91907 12.3696 1.95565 12.5412 2.02672C12.7128 2.09779 12.8687 2.20196 13 2.33328C13.1313 2.4646 13.2355 2.6205 13.3066 2.79208C13.3776 2.96367 13.4142 3.14756 13.4142 3.33328C13.4142 3.519 13.3776 3.7029 13.3066 3.87448C13.2355 4.04606 13.1313 4.20196 13 4.33328L4.66667 12.6666L2 13.3333L2.66667 10.6666L11 2.33328Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
