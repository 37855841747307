import { Box, BoxProps, Image, Text } from '@chakra-ui/react'
import { formatCommas } from 'libs/Number/formatNumber'
import { isNumber } from 'lodash/fp'
import { BigTableCardContainer, BigTableCardTable, BigTableCardTbody, BigTableCardTd, BigTableCardTh, BigTableCardThead, BigTableCardTr } from 'presentation/components/BigTableCard'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { BinocularIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/BinocularIcon'
import { CarIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/CarIcon'
import { GlobeIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/GlobeIcon'
import { IncognitoIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/IncognitoIcon'
import { ListIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/ListIcon'
import { MessageIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/MessageIcon'
import { SearchIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/SearchIcon'
import { UsersIcon } from 'presentation/screens/CancelSubscription/CancelTableCard/icons/UsersIcon'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import bannerMobJpg from './img/banner-mob.jpg'
import bannerMobSmJpg from './img/banner-mobsm.jpg'
import bannerTabSmJpg from './img/banner-tabsm.jpg'

export const CancelTableCard: FC<{ leadsLimit: number | null }> = ({ leadsLimit }) => {
  const { mfv } = useMobFirstBreakpointValue()

  return (
    <BigTableCardContainer maxW='1136px' mx='auto'>
      <BigTableCardTable>
        <BigTableCardThead>
          <BigTableCardTr>
            <BigTableCardTh colSpan={2} pos='relative'>
              <Image
                src={mfv({
                  mobSm: bannerMobSmJpg,
                  mob: bannerMobJpg,
                  tabSm: bannerTabSmJpg,
                })}
                alt=''
              />

              <Box
                pos='absolute'
                right={mbp({ mobSm: '33.25%', tabSm: '40.5%' })}
                top='50%'
                transform='translateY(-50%)'
              >
                <Text
                  textAlign='right'
                  textStyle={mbp({
                    mobSm: 'body2-h-b',
                    mob: 'body-h-b',
                    tabSm: 'h3-h-b',
                    dtSm: 'h1-h-xb',
                    dtLg: 'display-h-b',
                  })}
                  color='ondark.1'
                >
                  You’re Just One Deal Away
                </Text>
                <Text
                  textAlign='right'
                  textStyle={mbp({
                    mobSm: 'body2-0',
                    mob: 'body',
                    tabSm: 'old.h3',
                    dtSm: 'old.h1',
                    dtLg: 'display-b',
                  })}
                  color='ondark.1'
                >
                  Don’t give up now
                </Text>
              </Box>
            </BigTableCardTh>
          </BigTableCardTr>
        </BigTableCardThead>
        <BigTableCardTbody>
          <BigTableCardTr>
            <BigTableCardTd
              colSpan={2}
              px={mbp({
                mobSm: '2',
                mob: '4',
                tabSm: '5',
                dtSm: '6',
                dtLg: '72px',
              })}
              py={mbp({
                mobSm: '3',
                mob: '4',
                tabSm: '5',
                dtSm: '5',
                dtLg: '72px',
              })}
            >
              <Text
                textStyle={mbp({
                  mobSm: 'h4-h-b',
                  mob: 'h3-h-b',
                  tabSm: 'h2-h-b',
                  dtLg: 'inter-44-900',
                })}
                textAlign='left'
                color='graystrong.500'
              >
                You will
                {' '}
                <Text as='span' color='negative.500'>lose access</Text>
                {' '}
                to these premium tools for your business
              </Text>
            </BigTableCardTd>
          </BigTableCardTr>
          {([
            ...isNumber(leadsLimit)
              ? [[
                ListIcon,
                `Lose access to ${leadsLimit === Infinity
                  ? 'unlimited'
                  : formatCommas(leadsLimit)} new monthly lead exports`,
                'You and your team will no longer have the ability to search, filter and export lead lists.',
              ]]
              : [],
            [GlobeIcon, 'Your websites will be permanently deleted', 'All of your websites, design changes, blog articles, and customized colors will be permanently deleted.'],
            [SearchIcon, 'No more access to MLS Comps for property valuation', 'You will lose all of your previous Comp evaluations and your team will no longer have access to nationwide MLS comps.'],
            [CarIcon, 'D4$ routes & saved properties will be permanently deleted', 'You and your team will lose all access to the Propelio Mobile app, and all previosly driven routes & saved properties will be permanently deleted.'],
            [IncognitoIcon, 'Lose access to all Skip Trace Information', 'Your skip traced information such as phone numbers, emails, relatives, and property data will be lost and not recoverable.'],
            [MessageIcon, 'All mail campaigns stop & delivery history erased', 'Your scheduled marketing mailers will be deleted and all of your marketing history will be erased.'],
            [BinocularIcon, 'All Team Members & Prospectors will lose access', 'Team member and Prospector information, account history, and uploaded property data will be permanently lost.'],
            [UsersIcon, 'Multi-user access permissions deleted', 'All customized user access profiles, site restrictions, and user allocations will be erased.'],
          ] as ([FC<BoxProps>, string, string][])).map(([Icon, heading, description], i) => (
            <BigTableCardTr key={i}>
              <BigTableCardTd
                pl={mbp({
                  mobSm: '2',
                  mob: '4',
                  tabSm: '5',
                  dtSm: '6',
                  dtLg: '72px',
                })}
                w={mbp({
                  mobSm: '6',
                  mob: '7',
                  tabSm: '72px',
                  dtSm: '88px',
                  dtLg: '128px',
                })}
              >
                <Icon
                  boxSize={mbp({ mobSm: '4', dtSm: '5', dtLg: '6.5' })}
                  color='highlight.500'
                  stroke='onlight.1'
                />
              </BigTableCardTd>

              <BigTableCardTd
                pl={mbp({ mobSm: '2', mob: '3', tabSm: '4', dtLg: '6.5' })}
                py={mbp({ mobSm: '2', mob: '4', dtSm: '5', dtLg: '5' })}
                pr={mbp({ mobSm: '2', mob: '4', tabSm: '5', dtSm: '6', dtLg: '72px' })}
              >
                <Text
                  textStyle={mbp({
                    mobSm: 'body2-h-b',
                    mob: 'body-h-b',
                    tabSm: 'h4-h-b',
                    dtSm: 'h2-h-b',
                    dtLg: 'h1-h-xb',
                  })}
                  color='graystrong.400'
                >
                  {heading}
                </Text>
                <Text
                  textStyle={mbp({
                    mobSm: 'body2',
                    mob: 'body2',
                    tabSm: 'body',
                    dtSm: 'old.h4',
                    dtLg: 'old.h2',
                  })}
                  color='graystrong.200'
                  pt={mbp({ mobSm: '0.5', mob: '1' })}
                >
                  {description}
                </Text>
              </BigTableCardTd>
            </BigTableCardTr>
          ))}
        </BigTableCardTbody>
      </BigTableCardTable>
    </BigTableCardContainer>
  )
}
