import { FailedInvoiceFlow_FailedInvoiceSummaryFragment } from '__generated__/graphql'
import { GqlPath } from 'presentation/libs/graphql'
import { isNumber } from 'remeda'
import { CurrentChargeInfo, FailedInvoiceSummary, NextChargeInfo } from './FailedInvoiceModal.types'

export const unmaskFailedInvoiceFragment = (fragment?:
FailedInvoiceFlow_FailedInvoiceSummaryFragment,
): FailedInvoiceSummary | undefined => {
  if (!fragment) return undefined
  const data = fragment
  return getBillingSummary(data)
}

export const getBillingSummary = (
  data: FailedInvoiceFlow_FailedInvoiceSummaryFragment,
): FailedInvoiceSummary | undefined => {
  const { pastDueInvoice, upcomingInvoice } = data

  if (!pastDueInvoice) return undefined

  const planName = data?.plan?.name

  if (!planName) throw new Error('No plan name found')

  const invoiceId = data?.pastDueInvoice?.id

  if (!invoiceId) throw new Error('No invoice id found')

  const currentChargeInfo = getCurrentChargeInfo(pastDueInvoice)

  const nextChargeInfo = upcomingInvoice
    ? getNextChargeInfo(upcomingInvoice, data.plan)
    : undefined

  return {
    invoiceId,
    planName,
    currentChargeInfo,
    nextChargeInfo,
  }
}

type PastDueInvoice = GqlPath<FailedInvoiceFlow_FailedInvoiceSummaryFragment, [
  ['pastDueInvoice', 'BillingInvoice'],
]>

const getCurrentChargeInfo = (invoice: PastDueInvoice): CurrentChargeInfo => {
  const itemsRaw = invoice.items ?? []

  if (itemsRaw.length === 0) throw new Error('No items found')

  const items = itemsRaw.map(item => ({
    ...item,
    description: item.description ?? null,
  }))

  const dueDate = new Date(invoice.createdAt)

  const amountDue = invoice.authorizedAmount ?? undefined

  if (!isNumber(amountDue)) throw new Error('No amount due found')

  const currentChangeInfo: CurrentChargeInfo = {
    dueDate,
    amountDue,
    items,
  }

  return currentChangeInfo
}

type UpcomingInvoice = GqlPath<FailedInvoiceFlow_FailedInvoiceSummaryFragment, [
  ['upcomingInvoice', 'BillingProposedInvoice'],
]>

type SubscriptionPlan = GqlPath<FailedInvoiceFlow_FailedInvoiceSummaryFragment, [
  ['plan', 'BillingSubscriptionPlan'],
]>

const getNextChargeInfo = (
  invoice: UpcomingInvoice,
  plan: SubscriptionPlan,
): NextChargeInfo => {
  const nextChargeAmount = plan.price

  if (!nextChargeAmount) throw new Error('No amount found')

  const dueDate = invoice.paymentDate
    ? new Date(invoice.paymentDate)
    : null

  return { amount: nextChargeAmount, dueDate }
}
