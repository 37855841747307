import { Grid, GridProps } from '@chakra-ui/react'

const ZStack = ({ children, ...props }: GridProps) => (
  <Grid
    gridTemplateAreas='"stack"'
    gridTemplateColumns='1fr'
    gridTemplateRows='1fr'
    position='relative'
    sx={{
      '& > *': {
        gridArea: 'stack',
        position: 'relative',
        alignSelf: 'center',
        justifySelf: 'center',
      },
    }}
    {...props}
  >
    {children}
  </Grid>
)

export default ZStack
