import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib, ProgramException } from 'libs/errors'
import { restClient } from 'presentation/libs/client'
import { useCallback } from 'react'

export type __ResendMemberInvitePayload = { id: string, email: string }
export type __ResendMemberInviteError = ProgramException
export type __ResendMemberInviteCommand = Command.PromiseVersion<__ResendMemberInviteError, __ResendMemberInvitePayload>

/**
 * @TODO This returns success no matter what, because the API doesn't return
 *   proper responses. It should be fixed on the API side.
 */
export const __useResendMemberInviteCommand = (): __ResendMemberInviteCommand => {
  const { transition } = CQReact.useStateManager<__ResendMemberInviteError, __ResendMemberInvitePayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<__ResendMemberInviteCommand['execute']>(async payload => {
    const commandId = regenerateId()

    return await restClient.post('invite/resend', {
      json: {
        email: payload.email,
        url: `${window.location.origin}/reset-password`,
      },
    })
      .then(() => transition.success({ id: commandId, payload }))
      .catch(rawErr => {
        void ErrorLib.report(rawErr)
        const error = new ProgramException('An error occurred while inviting the email')
        return transition.error({ id: commandId, error, payload })
      })
  },
  [])

  return {
    execute,
  }
}
