import { Box, BoxProps } from '@chakra-ui/react'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SkiptraceBillingSummaryCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceBillingSummaryCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const SkiptraceNoPreviousResultCard = (props: BoxProps) => {
  const { sbp } = useSwitchBreakpointFn()
  return (
    <Box
      alignSelf='center'
      {...mbpg({
        mobSm: {
          px: 0,
        },
        mob: {
          py: 3,
          px: 2,
        },
        tabSm: {
          px: 0,
        },
      })}
      flex={mbp({ mobSm: '1 1 0', mob: 'initial' })}
      {...props}
    >
      <SkiptraceBillingSummaryCard
        {...sbp({
          mobSm: {
            borderRadius: '0 !important',
          },
          mob: {
            borderRadius: undefined,
          },
        })}
        h='full'
        minH={0}
      />
    </Box>
  )
}
