import { createIcon } from '@chakra-ui/react'

export const SkiptraceFailedIcon = createIcon({
  displayName: 'SkiptraceFailedIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill='currentColor'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.125 19.2635 15.078 18.0319 16.6177L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16.6177 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11ZM7.79289 7.79289C8.18342 7.40237 8.81658 7.40237 9.20711 7.79289L11 9.58579L12.7929 7.79289C13.1834 7.40237 13.8166 7.40237 14.2071 7.79289C14.5976 8.18342 14.5976 8.81658 14.2071 9.20711L12.4142 11L14.2071 12.7929C14.5976 13.1834 14.5976 13.8166 14.2071 14.2071C13.8166 14.5976 13.1834 14.5976 12.7929 14.2071L11 12.4142L9.20711 14.2071C8.81658 14.5976 8.18342 14.5976 7.79289 14.2071C7.40237 13.8166 7.40237 13.1834 7.79289 12.7929L9.58579 11L7.79289 9.20711C7.40237 8.81658 7.40237 8.18342 7.79289 7.79289Z'
      />
    </g>

  ),
})
