import { FormControl, FormErrorMessage, FormLabel, InputGroup, InputRightElement, NumberInput, NumberInputField, Spacer, Stack, StackProps } from '@chakra-ui/react'
import { CloseIcon } from 'presentation/components/Icons'
import { px } from 'presentation/utils/px'

import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { FormValidator } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/formValidators'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect, useRef } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { pipe } from 'remeda'

export type ListBuilderRangeFieldProps = {
  label?: string
  minLabel?: string
  maxLabel?: string
  minPlaceholder?: string
  maxPlaceholder?: string
  initialValue?: PartialRange | null
  onDebouncedValidChange?: (range: PartialRange) => void
  onValidBlur?: (range: PartialRange) => void
  validate?: FormValidator
  onError?: (errors: FieldErrors<PartialRangeForm>) => void
  formatInputDisplay?: (value: string | number) => string | number
  precision?: number
  upperRightElement?: React.ReactNode
  spacing?: StackProps['spacing']
  shouldStack?: boolean
  isInDev?: boolean
}

type Props = ListBuilderRangeFieldProps

export type PartialRangeForm = {
  min: string
  max: string
}

export type PartialRangeFormErrors = FieldErrors<PartialRangeForm>

const DEBOUNCE_MS = 1000

export const ListBuilderRangeField = ({
  label,
  minLabel,
  maxLabel,
  minPlaceholder = 'No Min',
  maxPlaceholder = 'No Max',
  validate = () => true,
  onError,
  formatInputDisplay,
  precision = 0,
  upperRightElement,
  spacing = mbp({
    mobSm: 1,
    mob: 2,
  }),
  shouldStack = false,
  isInDev,
  ...props
}: Props) => {
  // #region useForm
  const {
    formState: { errors },
    watch,
    trigger,
    setValue,
    control,
  } = useForm<PartialRangeForm>({
    defaultValues: {
      min: props.initialValue?.[0]?.toString() ?? '',
      max: props.initialValue?.[1]?.toString() ?? '',
    },
    mode: 'onChange',
  })
  // #endregion

  // #region Track form values
  const minFromForm = NumberLib.fromStringSafe(watch('min'))
  const maxFromForm = NumberLib.fromStringSafe(watch('max'))

  // validate max when min changes
  useEffect(() => {
    void trigger('max')
  }, [minFromForm])

  // validate min when max changes
  useEffect(() => {
    void trigger('min')
  }, [maxFromForm])

  // update form values when initialValue changes
  useEffect(() => {
    setValue('min', props.initialValue?.[0]?.toString() ?? '')
    setValue('max', props.initialValue?.[1]?.toString() ?? '')
  }, [props.initialValue?.[0], props.initialValue?.[1]])
  // #endregion

  // #region Validation and Errors
  const anyError = errors.min || errors.max

  useEffect(() => {
    onError?.(errors)
  }, [errors.max, errors.min])
  // #endregion

  // #region Facilitate onDebouncedValidChange
  // call onDebouncedValidChange when min or max changes
  // IF valid, value has changed, debounce period has passed
  const queuedRangeRef = useRef<PartialRange | null>(null)
  useEffect(() => {
    const initialMin = props.initialValue?.[0] ?? null
    const initialMax = props.initialValue?.[1] ?? null
    const didChange = minFromForm !== initialMin || maxFromForm !== initialMax

    if (!didChange) return

    let timeoutId: ReturnType<typeof setTimeout> | null = null

    if (!anyError) {
      queuedRangeRef.current = [minFromForm, maxFromForm]
      timeoutId = setTimeout(() => {
        queuedRangeRef.current = null
        props.onDebouncedValidChange?.([minFromForm, maxFromForm])
      }, DEBOUNCE_MS)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [minFromForm, maxFromForm])

  /**
   * When unmounted, call any queued debounced
   * change that hasn't been called yet
   */
  useEffect(() => () => {
    if (!queuedRangeRef.current) return
    props.onDebouncedValidChange?.(queuedRangeRef.current)
  }, [])
  // #endregion

  // #region Facilitate onValidBlur
  const minFromFormRef = useRef(minFromForm)
  const maxFromFormRef = useRef(maxFromForm)
  const anyErrorRef = useRef(anyError)
  minFromFormRef.current = minFromForm
  maxFromFormRef.current = maxFromForm
  anyErrorRef.current = anyError

  /**
   * @NOTE We need to use refs, because keepWithinRange tends to update input after blur,
   *   and if we don't use refs, we wont get the updated values post-blur
   */
  const handleDelayedBlur = () => {
    if (anyErrorRef.current) return
    props.onValidBlur?.([minFromFormRef.current, maxFromFormRef.current])
  }
  // #endregion

  // #region Render
  return (
    <Stack
      direction={shouldStack ? 'column' : 'row'}
      spacing={spacing}
      align='flex-end'
    >
      <Controller
        name='min'
        control={control}
        rules={{ validate }}
        render={({ field: { ref, ...restField } }) => (
          <FormControl
            isInvalid={!!errors.min}
          >
            <FormLabel
              display='flex'
              alignItems='center'
              minHeight={px(LABEL_HEIGHT)}
              color={isInDev ? 'grayweak.900' : undefined}
            >
              {minLabel || label}
              {isInDev && (
                <>
                  {' '}
                  (In Development)
                </>
              )}
            </FormLabel>
            <NumberInput
              isDisabled={isInDev}
              keepWithinRange
              isInvalid={!!errors.min}
              format={formatInputDisplay}
              precision={precision}
              max={pipe(
                watch('max'),
                NumberLib.fromStringSafe,
                NumberLib.ensureOrUndefined,
              )}
              {...restField}
              onBlur={() => {
                /** @NOTE We call onBlur post delay, because keepWithinRange updates value post blur */
                setTimeout(() => {
                  handleDelayedBlur?.()
                  restField.onBlur()
                }, 0)
              }}
              onChange={ev => {
                restField.onChange(ev)
              }}
            >
              <InputGroup>
                <NumberInputField
                  placeholder={minPlaceholder}
                  ref={ref}
                  name={restField.name}
                />
                {restField.value && (
                  <InputRightElement
                    role='button'
                    px={1}
                    onClick={() => {
                      props.onValidBlur?.([null, maxFromForm])
                    }}
                  >
                    <CloseIcon />
                  </InputRightElement>
                )}
              </InputGroup>
            </NumberInput>
            {errors.min && (
              <FormErrorMessage>{errors.min.message}</FormErrorMessage>
            )}
          </FormControl>
        )}
      />

      <Controller
        name='max'
        control={control}
        rules={{ validate }}
        render={({ field: { ref, ...restField } }) => (
          <FormControl isInvalid={!!errors.max}>
            <FormLabel
              display='flex'
              alignItems='center'
              minHeight={px(LABEL_HEIGHT)}
              color={isInDev ? 'grayweak.900' : undefined}
            >
              {maxLabel || <span>&nbsp;</span>}
              {maxLabel && isInDev && (
                <>
                  {' '}
                  (In Development)
                </>
              )}
              <Spacer />
              {upperRightElement}
            </FormLabel>
            <NumberInput
              isDisabled={isInDev}
              keepWithinRange
              isInvalid={!!errors.max}
              format={formatInputDisplay}
              precision={precision}
              min={pipe(
                watch('min'),
                NumberLib.fromStringSafe,
                NumberLib.ensureOrUndefined,
              )}
              {...restField}
              onBlur={() => {
                /** @NOTE We call onBlur post delay, because keepWithinRange updates value post blur */
                setTimeout(() => {
                  handleDelayedBlur?.()
                  restField.onBlur()
                }, 0)
              }}
              onChange={ev => {
                restField.onChange(ev)
              }}
            >
              <InputGroup>
                <NumberInputField
                  placeholder={maxPlaceholder}
                  ref={ref}
                  name={restField.name}
                />
                {restField.value && (
                  <InputRightElement
                    role='button'
                    px={1}
                    onClick={() => {
                      props.onValidBlur?.([minFromForm, null])
                    }}
                  >
                    <CloseIcon />
                  </InputRightElement>
                )}
              </InputGroup>
            </NumberInput>
            {errors.max && (
              <FormErrorMessage>{errors.max.message}</FormErrorMessage>
            )}
          </FormControl>
        )}
      />
    </Stack>
  )
  // #endregion
}

/** Label height is adjusted because in we put switch on label sections sometimes */
const LABEL_HEIGHT = 28
