import { PropertyListMember } from 'features/ListBuilder/domain/MarketingListMember/PropertyListMember'

/**
 * @TODO:
 * - add PrivateLendersMember
 * - add CashBuyersMember
 */
export type MarketingListMember =
  | PropertyListMember

export const MarketingListMember = {
  PropertyList: PropertyListMember.make,
}
