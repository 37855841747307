import pDebounce from 'p-debounce'
import { restClient } from 'presentation/libs/client'

export const GETDebouncedListMembers = {
  /**
   * @NOTE Current use case for pDebounce is so that when on list screen,
   *   we call it both from list domain and skiptrace domain, we would be able
   *   to avoid calling it multiple times in a short period of time.
   */
  request: pDebounce(
    async (listId: string, offset: number, limit: number) =>
      await restClient.get(`lists/v3/${listId}/members`, {
        searchParams: { offset, limit },
      })
        .json(),
    100,
  ),
}
