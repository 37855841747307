import { PaymentRow__InvoiceFragmentFragment } from '__generated__/graphql'
import { BasePaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/BasePaymentRow/BasePaymentRow'
import { FC } from 'react'

export const PaymentRow: FC<{
  transaction: PaymentRow__InvoiceFragmentFragment
}> = props => {
  const data = props.transaction

  return (
    <BasePaymentRow
      dateString={data.paidAt || data.createdAt}
      createdById={data.createdBy?.id}
      linkToPdf={data.linkToPdf || undefined}
      amount={data.refundedAmount
      || data.capturedAmount
      || data.authorizedAmount
      || undefined}
      description={data.description}
      status={data.status}
    />
  )
}
