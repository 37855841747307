import { Box, BoxProps, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { BuildTotalState, useBuildTotalCount } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PropertiesFilterChips } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFilterChips'
import { PropertiesFiltersTabs } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFiltersTabs'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Link } from 'react-router-dom'

export const ListBuilderScreenBase = (props: BoxProps) => (
  <VStack
    pt={mbp({
      mobSm: 1,
      mob: 3,
      dtSm: 4,
    })}
    pos='relative'
    spacing={0}
    align='stretch'
    h='full'
    flex='1'
    bg='neutral.100'
    {...props}
  >
    <VStack
      px={mbp({
        mobSm: 1,
        mob: 3,
        dtSm: 3,
      })}
      flex='1'
      overflow='auto'
      align='stretch'
      justify='stretch'
      pb={1}
    >
      <PropertiesFiltersTabs flex='1' />
    </VStack>

    <ListBuilderFooter
      position='sticky'
      bottom={0}
      zIndex='sticky-2'
    />
  </VStack>
)

const ListBuilderFooter = (props: BoxProps) => {
  const buildTotalCount = useBuildTotalCount()

  return (
    <HStack
      px={mbp({
        mobSm: 1,
        mob: 3,
        dtSm: 3,
      })}
      py={mbp({
        mobSm: 1,
        mob: 2,
        dtSm: 3,
      })}
      spacing={3}
      boxShadow='vscroll-fade-bottom'
      bg='neutral.100'
      {...props}
    >
      <Breakpoint
        mobSm={null}
        dtSm={(
          <Box flex='1'>
            <PropertiesFilterChips />
          </Box>
        )}
      />

      <Box
        flexGrow={mbp({
          mobSm: 1,
          dtSm: 0,
        })}
      >
        {BuildTotalState.$is('Initial')(buildTotalCount)
          ? (
            <Breakpoint
              mobSm={(
                <Text
                  color='graystrong.200'
                  textStyle='bodyLFat'
                >
                  Add a location to start building a list.
                </Text>
              )}
              dtSm={(
                <FooterButton
                  size='md'
                  colorScheme='positive'
                  variant='solid'
                  isDisabled
                >
                  No Location Selected
                </FooterButton>
              )}
            />
          )
          : BuildTotalState.$is('Loading')(buildTotalCount) || BuildTotalState.$is('Failed')(buildTotalCount)
            ? (
              <FooterButton
                size='md'
                colorScheme='positive'
                variant='solid'
                isLoading={true}
                loadingText='Loading Results'
                spinnerPlacement='start'
              >
                Loading Results
              </FooterButton>
            )
            : buildTotalCount.total === 0
              ? (
                <FooterButton
                  size='md'
                  colorScheme='positive'
                  variant='solid'
                  isDisabled
                >
                  No Result
                </FooterButton>
              )
              : (
                <FooterButton
                  size='md'
                  colorScheme='positive'
                  variant='solid'
                  as={Link}
                  to='preview'
                >
                  See
                  {' '}
                  {NumberLib.formatComma(buildTotalCount.total)}
                  {' '}
                  Results
                </FooterButton>
              )}
      </Box>
    </HStack>
  )
}

const FooterButton: typeof Button = props => (
  <Button
    w={mbp({
      mobSm: 'full',
      dtSm: 'auto',
    })}
    {...props}
  >
    {props.children}
  </Button>
)
