import { chakra, createIcon } from '@chakra-ui/react'

export const ColoredStarIcon = createIcon({
  displayName: 'ColoredStarIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <chakra.path id='Vector' d='M8.50629 0.90375L10.1888 4.74125C10.2521 4.88567 10.353 5.01046 10.4809 5.10259C10.6089 5.19471 10.7593 5.2508 10.9163 5.265L15.015 5.6325C15.4788 5.7 15.6638 6.26875 15.3275 6.59625L12.24 9.19C11.99 9.4 11.8763 9.73 11.945 10.0487L12.8425 14.25C12.9213 14.7113 12.4375 15.0638 12.0225 14.845L8.44504 12.75C8.31011 12.6708 8.1565 12.6291 8.00004 12.6291C7.84359 12.6291 7.68997 12.6708 7.55504 12.75L3.97754 14.8438C3.56379 15.0612 3.07879 14.71 3.15754 14.2487L4.05504 10.0475C4.12254 9.72875 4.01004 9.39875 3.76004 9.18875L0.671292 6.5975C0.336292 6.27125 0.521292 5.70125 0.983792 5.63375L5.08254 5.26625C5.23958 5.25205 5.38993 5.19596 5.5179 5.10384C5.64586 5.01171 5.74676 4.88692 5.81004 4.7425L7.49254 0.905C7.70129 0.485 8.29879 0.485 8.50629 0.90375Z' fill='trueorange.500' />
      <chakra.path id='Vector_2' d='M8.3838 4.97125L8.0988 2.14375C8.08755 1.98625 8.05505 1.71625 8.30755 1.71625C8.50755 1.71625 8.6163 2.1325 8.6163 2.1325L9.4713 4.4025C9.7938 5.26625 9.6613 5.5625 9.35005 5.7375C8.99255 5.9375 8.46505 5.78125 8.3838 4.97125Z' fill='highlight.500' />
      <chakra.path id='Vector_3' d='M11.91 8.93875L14.3625 7.025C14.4838 6.92375 14.7025 6.7625 14.5275 6.57875C14.3888 6.43375 14.0138 6.6425 14.0138 6.6425L11.8675 7.48125C11.2275 7.7025 10.8025 8.03 10.765 8.4425C10.7163 8.9925 11.21 9.41625 11.91 8.93875Z' fill='trueorange.100' />
    </>
  ),
})
