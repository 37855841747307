import { CardProps } from '@chakra-ui/react'
import { ConsumableAttemptBillingSummaryCard } from 'features/common/ConsumableAttempt/views/ConsumableAttemptBillingSummaryCard'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { DateLib } from 'libs/Date'
import { useLeadId } from 'presentation/libs/LeadIdContext'

export type SkiptraceBillingSummaryCardProps = {
  isColumnCard?: boolean
  onCancel?: () => void
} & CardProps

export const SkiptraceBillingSummaryCard = (props: SkiptraceBillingSummaryCardProps) => {
  const {
    onCancel,
    isColumnCard,
    ...cardProps
  } = props

  const state = useSkiptraceBillingSummaryCardState()

  if (state.status === 'unready') return null

  const availableFunds = state.fundsInfo.funds.available
  const pricePenniesPerUnit = state.fundsInfo.price
  const nextRenewalDateRaw = state.fundsInfo.consumablesRefreshDate
  const nextRenewalDate = DateLib.formatMMDDYYDots(nextRenewalDateRaw)

  return (
    <ConsumableAttemptBillingSummaryCard
      title='Skiptrace'
      subtitle=''
      consumableType='skiptrace'
      paymentPrice={15}
      pricePenniesPerUnit={pricePenniesPerUnit}
      refreshingConsumables={state.fundsInfo.refreshingConsumables}
      remainingConsumables={state.fundsInfo.remainingConsumables}
      consumablesRefreshDate={nextRenewalDate}
      currentPlan={state.fundsInfo.planName}
      freeItemExceedCount={undefined}
      availableFunds={availableFunds}
      fundsStatus={state.fundsInfo.status}
      onAttempt={state.performSkiptrace}
      isProcessing={state.isSkiptracing}
      onCancel={onCancel}
      isColumnCard={isColumnCard}
      quantity={1}
      {...cardProps}
    />
  )
}

const useSkiptraceBillingSummaryCardState = () => {
  const routeLeadId = useLeadId()
  const propDetailsState = usePropertyDetailsStore(propertyDetailsApi => {
    const propertyState = propertyDetailsApi.actions.getProperty.state
    const doesPropertyMatch = propertyState.status === 'success'
      && propertyState.data.leadId === routeLeadId

    const ownerAddress = propertyState.status === 'success'
      && propertyState.data.status === 'with-details'
      && propertyState.data.ownership?.address

    if (!doesPropertyMatch || !ownerAddress) return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      leadId: propertyState.data.leadId,
      ownerAddress,
    }
  })

  const fundsState = useSkiptraceStore(skiptraceApi => {
    const skiptraceFundsState = skiptraceApi.entities.skiptraceFunds

    if (skiptraceFundsState.status !== 'success') return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      fundsInfo: {
        funds: skiptraceFundsState.attempt.funds,
        price: skiptraceFundsState.attempt.price,
        consumablesRefreshDate: skiptraceFundsState.attempt.consumablesRefreshDate,
        refreshingConsumables: skiptraceFundsState.attempt.refreshingConsumables,
        remainingConsumables: skiptraceFundsState.attempt.remainingConsumables,
        planName: skiptraceFundsState.attempt.planName,
        status: skiptraceFundsState.attempt.status,
      },
    }
  })

  const skiptraceStore = useSkiptraceStore(skipApi => {
    if (propDetailsState.status !== 'ready') return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      isSkiptracing: skipApi.entities.resultState.status === 'skiptracing',
      performSkiptrace: () => {
        void skipApi.actions.performSkiptrace.execute({
          leadId: propDetailsState.leadId,
          address: propDetailsState.ownerAddress,
        })
      },
    }
  })

  const isAnyUnready = propDetailsState.status === 'unready'
    || fundsState.status === 'unready'
    || skiptraceStore.status === 'unready'

  if (isAnyUnready) return { status: 'unready' as const }

  return {
    ...fundsState,
    status: 'ready' as const,
    isSkiptracing: skiptraceStore.isSkiptracing,
    performSkiptrace: skiptraceStore.performSkiptrace,
  }
}
