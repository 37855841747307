import { Effect } from 'effect'
import LocationPath from 'presentation/libs/LocationPath'
import RouteIdUtils from 'presentation/main/Router/RouteIdUtils'
import { ComponentType, ReactElement } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import AppRouter from 'services/AppRouter'

namespace NearbyBuyersRoutes {
  export const IDS = {
    NEARBY_BUYERS: RouteIdUtils.prefixProperty('NEARBY_BUYERS'),
  }

  /**
   * @NOTE The Provider component is configurable for dependency injection,
   *   while the others is supplied via params just to work around circular
   *   dependencies.
   */
  export const make = ({
    Provider,
    NearbyBuyersScreen,
    SaveToListModal,
    MobileBuyerHistory,
  }: {
    Provider: ComponentType<{ children: ReactElement }>
    NearbyBuyersScreen: ComponentType
    SaveToListModal: ComponentType
    MobileBuyerHistory: ComponentType
  }): RouteObject[] =>
    [{
      element: <Provider><Outlet /></Provider>,
      children: [
        {
          id: IDS.NEARBY_BUYERS,
          path: 'cash-buyers',
          element: <NearbyBuyersScreen />,
          children: [
            {
              path: MODAL_TYPES_TO_SUBPATH.SAVE_TO_LIST,
              element: <SaveToListModal />,
            },
            {
              path: MODAL_TYPES_TO_SUBPATH.MOBILE_BUYER_HISTORY,
              element: <MobileBuyerHistory />,
            },
          ],
        },
      ],
    }]

  export const MODAL_TYPES_TO_SUBPATH = {
    SAVE_TO_LIST: 'modal/save-to-list',
    MOBILE_BUYER_HISTORY: 'modal/mobile-buyers-history',
  }

  export type ModalType = keyof typeof MODAL_TYPES_TO_SUBPATH

  export type ModalTypeToPayload = {
    SAVE_TO_LIST: void
    MOBILE_BUYER_HISTORY: { buyerId: string }
  }

  export const navigateToOpenModal = <TModalType extends ModalType>(
    modalType: TModalType,
    ...args: ModalTypeToPayload[TModalType] extends void ? [] : [ModalTypeToPayload[TModalType]]
  ) => Effect.gen(function * () {
    const { router } = yield * AppRouter

    const subpath = MODAL_TYPES_TO_SUBPATH[modalType]

    const currentPath = router.state.location.pathname
    const isOnModalPath = LocationPath.checkEndsWithSubpath(
      subpath,
      currentPath,
    )

    if (!isOnModalPath) {
      const nextPath = LocationPath.appendSubpath(
        subpath,
        currentPath,
      )

      router.navigate(nextPath, {
        state: {
          payload: args[0],
        },
      })
    }
  })

  export const navigateToCloseModal = <TModalType extends ModalType>(
    modalType: TModalType,
  ) => Effect.gen(function * () {
    const { router } = yield * AppRouter

    const subpath = MODAL_TYPES_TO_SUBPATH[modalType]
    const currentPath = router.state.location.pathname
    const isOnModalPath = LocationPath.checkEndsWithSubpath(
      subpath,
      currentPath,
    )

    if (isOnModalPath) {
      const parentPath = LocationPath.removeTrailSubpath(
        subpath,
        currentPath,
      )
      router.navigate(parentPath, { replace: true })
    }
  })
}

export default NearbyBuyersRoutes
