import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, GridItem, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { Array, Option } from 'effect'
import Hooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Fragment } from 'react'
import NameWithSkip from './NameWithSkip'
import { FlippedPill, LandlordPill } from './common'
import { DateLib } from 'libs/Date'

const PropertyOwnershipActivityAccordions = () => {
  const viewModel = Hooks.useOwnersList()
  return (
    <Accordion
      display='grid'
      gridTemplateColumns='repeat(6, 1fr)'
      allowMultiple={false}
      allowToggle
      rowGap='2'
    >
      {viewModel.ownerIds.map(ownerId => (
        <AccordionGridItem
          key={ownerId}
          ownerId={ownerId}
        />
      ))}
    </Accordion>
  )
}

export default PropertyOwnershipActivityAccordions

type AccordionGridItemProps = {
  ownerId: string
}

const AccordionGridItem = ({
  ownerId,
}: AccordionGridItemProps) => {
  const viewModelOption = Hooks.useOwner(ownerId)
  const mobSmModelOption = Hooks.useOwnerMobSm(ownerId)

  if (Option.isNone(viewModelOption)) return null

  const viewModel = viewModelOption.value
  const { owner, onAddressClick } = viewModel

  return (
    <>
      <AccordionItem
        display='grid'
        gridTemplateColumns='subgrid'
        gridColumn='1 / -1'
        color='graystrong.400'
        borderTopWidth={0}
        sx={{
          '& .chakra-collapse[style*="display: block"]': {
            display: 'grid !important',
          },
          '& .chakra-collapse': {
            display: 'grid',
            gridTemplateColumns: 'subgrid',
            gridColumn: '1 / -1',
          },
        }}
      >
        {({ isExpanded }) => (
          <Fragment>
            <AccordionButton
              display='grid'
              gridTemplateColumns='subgrid'
              gridColumn='1 / -1'
              bgColor={isExpanded ? 'highlight.200' : 'card.bg.1'}
              _hover={{
                bgColor: isExpanded ? 'highlight.200' : 'positive.50',
              }}
              py='10px'
              px='1'
              borderTopRadius='2'
              borderBottomRadius={isExpanded ? 0 : 2}
            >
              <GridItem
                gridColumn={{
                  base: '1 / -1',
                  mob: 'span 3',
                }}
                display='flex'
                gap='1'
                alignItems='center'
              >
                <AccordionIcon boxSize='3' />
                <Box flex='1' minW='0'>
                  <NameWithSkip
                    textStyle='bodyLFat'
                    textAlign='left'
                  >
                    {Option.match(owner.name, {
                      onSome: name => name,
                      onNone: () => '--',
                    })}
                  </NameWithSkip>
                  <HStack
                    display={{
                      base: 'flex',
                      mob: 'none',
                    }}
                    spacing='1'
                    mt='1'
                  >
                    {owner.buyerType.pipe(
                      Option.match({
                        onSome: buyerType => (
                          <>
                            {buyerType.includes('flipper') && <FlippedPill />}
                            {buyerType.includes('landlord') && <LandlordPill />}
                          </>
                        ),
                        onNone: () => null,
                      }),
                    )}
                  </HStack>
                </Box>
              </GridItem>
              <GridItem
                display={{
                  base: 'none',
                  mob: 'flex',
                }}
                gridColumn='span 3'
                gap='1'
                alignItems='center'
                justifyContent='flex-end'
              >
                {owner.buyerType.pipe(
                  Option.match({
                    onSome: buyerType => (
                      <>
                        {buyerType.includes('flipper') && <FlippedPill />}
                        {buyerType.includes('landlord') && <LandlordPill />}
                      </>
                    ),
                    onNone: () => null,
                  }),
                )}
              </GridItem>
            </AccordionButton>

            <AccordionPanel
              display='grid'
              gridTemplateColumns='subgrid'
              gridColumn='1 / -1'
              bgColor='highlight.100'
              p='1'
              borderBottomRadius='2'
            >
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Total Deals
                </PairKey>
                <PairValue>
                  {owner.totalDealsCount.pipe(
                    Option.flatMap(Option.liftPredicate(count => count > 0)),
                    Option.map(totalDealsCount => (
                      <>
                        {owner.totalDealAmount.pipe(
                          Option.map(Dollar.formatNumberWithCommas),
                          Option.getOrElse(() => '--'),
                        )}
                        {' '}
                        in
                        <br />
                        {pluralize('deal', totalDealsCount, true)}
                      </>
                    )),
                    Option.getOrElse(() => '--'),
                  )}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Average Deal
                </PairKey>
                <PairValue>
                  {owner.averageDealAmount.pipe(
                    Option.map(Dollar.formatNumberWithCommas),
                    Option.getOrElse(() => '--'),
                  )}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Last Deal
                </PairKey>
                <PairValue>
                  {owner.lastDeal.pipe(
                    Option.map(deal => {
                      if (
                        Option.isNone(deal.purchaseDate)
                        && Option.isNone(deal.purchaseAmount)
                      ) return '--'

                      const purchaseDateFormatted = deal.purchaseDate.pipe(
                        Option.map(DateLib.formatMMDDYYDots),
                        Option.getOrElse(() => '--'),
                      )

                      return purchaseDateFormatted
                    }),
                    Option.getOrElse(() => '--'),
                  )}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Price Range
                </PairKey>
                <PairValue>
                  {(() => {
                    const minDealFormatted = owner.dealAmountRange.pipe(
                      Option.map(range => range[0]),
                      Option.map(Dollar.formatNumberWithCommas),
                      Option.getOrElse(() => '--'),
                    )

                    const maxDealFormatted = owner.dealAmountRange.pipe(
                      Option.map(range => range[1]),
                      Option.map(Dollar.formatNumberWithCommas),
                      Option.getOrElse(() => '--'),
                    )

                    return (
                      <>
                        {minDealFormatted}
                        {' '}
                        -
                        {' '}
                        {maxDealFormatted}
                      </>
                    )
                  })()}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Location
                </PairKey>
                <PairValue>
                  {pluralize('State', Array.length(owner.dealsGeographicInfo.states), true)}
                  {' '}
                  &&nbsp;
                  {pluralize('County', Array.length(owner.dealsGeographicInfo.counties), true)}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  No. of Deals
                </PairKey>
                <PairValue>
                  {owner.propertiesCount.pipe(
                    Option.match({
                      onSome: count => pluralize('Properties', count, true),
                      onNone: () => '--',
                    }),
                  )}
                </PairValue>
              </Pair>
              <Pair gridColumn='span 3' p='1' size='md'>
                <PairKey>
                  Mailing Address
                </PairKey>
                {owner.address.pipe(
                  Option.match({
                    onSome: address => (
                      <Link minW='0' as={VStack} alignItems='stretch' spacing={0}>
                        <Text
                          flex='1'
                          color='inherit'
                          isTruncated
                          title={`${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`}
                          as='button'
                          onClick={onAddressClick}
                          textAlign='left'
                        >
                          {address.line1}
                          ,
                          {' '}
                          {address.city}
                        </Text>
                        <Text
                          flex='1'
                          color='inherit'
                          isTruncated
                          title={`${address.state} ${address.postalCode}`}
                          as='button'
                          onClick={onAddressClick}
                          textAlign='left'
                        >
                          {address.state}
                          {' '}
                          {address.postalCode}
                        </Text>
                      </Link>
                    ),
                    onNone: () => (
                      <PairValue>
                        <>--</>
                      </PairValue>
                    ),
                  }),
                )}
              </Pair>

              <GridItem
                display='flex'
                justifyContent={{
                  base: 'stretch',
                  mob: 'flex-end',
                }}
                gridColumn='1 / -1'
              >
                <Button
                  mt='1'
                  variant='outline'
                  colorScheme='neutral'
                  w={{
                    base: 'full',
                    mob: 'auto',
                  }}
                  onClick={mobSmModelOption.onOwnerDealsOpenMobile}
                >
                  View Deals
                </Button>
              </GridItem>
            </AccordionPanel>
          </Fragment>
        )}
      </AccordionItem>
    </>
  )
}
