import { createIcon } from '@chakra-ui/react'

export const EyeOffIcon = createIcon({
  displayName: 'EyeOffIcon',
  viewBox: '0 0 16 12',
  path: (
    <>
      <path fillRule='evenodd' clipRule='evenodd' d='M7.99852 3.08943C7.58563 3.08869 7.17463 3.12489 6.77416 3.19699L6.54227 3.24935L5.05548 2.10705C5.05548 2.10705 5.90424 1.7143 6.48395 1.58521C7.06437 1.45597 7.49086 1.45086 8.00074 1.45173C10.5363 1.45195 12.5093 2.56776 13.8237 3.64622C14.4829 4.18709 14.9876 4.72719 15.3288 5.13368C15.4999 5.33739 15.631 5.50887 15.721 5.63195C15.766 5.69353 15.8009 5.74312 15.8253 5.77871C15.8376 5.7965 15.8472 5.81081 15.8543 5.82138L15.863 5.83441L15.8658 5.83876L15.8669 5.84039C15.8669 5.84039 15.8677 5.84165 15.1811 6.28792L15.8677 5.84165C16.0491 6.12073 16.0435 6.48186 15.8536 6.7552C15.4207 7.3782 14.9072 7.96064 14.3235 8.49421L13.6603 9.04954L12.3733 8.01333L13.2185 7.28546C13.5647 6.96902 13.8786 6.63555 14.1585 6.28811C14.132 6.25566 14.1041 6.22183 14.0746 6.18674C13.7835 5.83999 13.3497 5.37575 12.7849 4.91229C11.6513 3.98218 10.0339 3.08943 8 3.08943L7.99852 3.08943ZM5.72768 3.27494L4.5369 4.01111C3.45327 4.64761 2.54353 5.4219 1.84232 6.28868C1.86852 6.32084 1.89622 6.35435 1.92539 6.3891C2.21653 6.73586 2.65028 7.20009 3.21514 7.66355C4.34779 8.59288 5.96335 9.4849 7.99485 9.48641C9.26628 9.46957 10.4819 9.14007 11.4636 8.56441L12.0042 8.27547L13.2756 9.30466L12.292 9.97718C11.0432 10.7094 9.54302 11.1048 8.0103 11.124L8 11.1242C5.4641 11.1242 3.49086 10.0082 2.17634 8.92963C1.51714 8.38876 1.01245 7.84866 0.671153 7.44216C0.500116 7.23845 0.369009 7.06697 0.279005 6.94389C0.233974 6.88231 0.199143 6.83273 0.174662 6.79714C0.162418 6.77934 0.152754 6.76503 0.145689 6.75446L0.13704 6.74144L0.134178 6.73708L0.133114 6.73545C0.133114 6.73545 0.132295 6.73419 0.81885 6.28792L0.132295 6.73419C-0.0494066 6.45466 -0.0434867 6.09287 0.147263 5.81943C1.03424 4.54795 2.25254 3.45358 3.70745 2.59899L4.41351 2.18427L5.72768 3.27494Z' fill='currentColor' />
      <path fillRule='evenodd' clipRule='evenodd' d='M1.82608 0.103516L14.9839 10.6171L13.9616 11.8965L0.803768 1.38295L1.82608 0.103516Z' fill='currentColor' />
      <path d='M5.35385 3.45087C5.07749 3.79823 4.87225 4.19661 4.74985 4.62329C4.62745 5.04996 4.59029 5.49656 4.64049 5.9376C4.69069 6.37863 4.82727 6.80546 5.04242 7.19371C5.25758 7.58197 5.5471 7.92404 5.89445 8.2004C6.24181 8.47676 6.64019 8.682 7.06687 8.8044C7.49354 8.9268 7.94014 8.96396 8.38118 8.91376C8.82221 8.86356 9.24904 8.72699 9.63729 8.51183C10.0255 8.29668 10.3676 8.00716 10.644 7.6598L5.35385 3.45087Z' fill='currentColor' />
      <path d='M11.0358 6.90902L11.1217 6.74775C11.2069 6.5742 11.2806 6.39424 11.3177 6.20452C11.3842 5.86526 11.3983 5.51723 11.3591 5.17261C11.3089 4.73157 11.1723 4.30474 10.9571 3.91649C10.742 3.52824 10.4525 3.18617 10.1051 2.9098C9.75775 2.63344 9.35936 2.4282 8.93269 2.3058C8.50602 2.1834 8.05941 2.14624 7.61838 2.19644C7.17734 2.24664 6.36141 2.60643 5.97316 2.82158L11.0358 6.90902Z' fill='currentColor' />
    </>
  ),
})
