import { AddPaymentModal } from 'presentation/main/globalModals/AddPaymentModal'
import { useRestorePlanWithPayMutation } from 'presentation/main/globalModals/RestorePlanModal/useRestorePlanWithPayMutation'
import { TypeToConfirmModal } from 'presentation/main/globalModals/TypeToConfirmModal'
import { modals } from 'presentation/main/modals/modals'
import { ErrorModal } from './ErrorModal'
import { createFailedInvoiceModal, usePayInvoiceMutation } from './FailedInvoiceModal'
import { PaymentFailedModal } from './PaymentFailedModal'
import { SuccessModal } from './SuccessModal'
import { UpdatePaymentModal } from './UpdatePaymentModal'

modals.register('PAYMENT_FAILED_MODAL', PaymentFailedModal)
modals.register('ADD_PAYMENT_MODAL', AddPaymentModal)
modals.register('ERROR_MODAL', ErrorModal)
modals.register('SUCCESS_MODAL', SuccessModal)
modals.register('UPDATE_PAYMENT_MODAL', UpdatePaymentModal)
modals.register('FAILED_INVOICE_MODAL', createFailedInvoiceModal(usePayInvoiceMutation))
modals.register('RESTORE_FAILED_INVOICE_MODAL', createFailedInvoiceModal(useRestorePlanWithPayMutation))
modals.register('TYPE_TO_CONFIRM_MODAL', TypeToConfirmModal)
