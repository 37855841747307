import * as S from '@effect/schema/Schema'

export const RangeFilterSchema = S.Struct({
  min: S.OptionFromNullishOr(S.Number, undefined),
  max: S.OptionFromNullishOr(S.Number, undefined),
})

export type RangeFilterSchema = S.Schema.Type<typeof RangeFilterSchema>

export const DateRangeFilterSchema = S.Struct({
  after: S.OptionFromNullishOr(S.Date, undefined),
  before: S.OptionFromNullishOr(S.Date, undefined),
})

export type DateRangeFilterSchema = S.Schema.Type<typeof DateRangeFilterSchema>

export const ConditionTypeSchema = S.Literal('AND', 'OR')

export type ConditionTypeSchema = S.Schema.Type<typeof ConditionTypeSchema>

export const LocationSchema = S.Struct({
  zip: S.OptionFromNullishOr(S.Array(S.String), undefined),
  city: S.OptionFromNullishOr(S.Array(S.String), undefined),
  county: S.OptionFromNullishOr(S.Array(S.String), undefined),
  countyDesc: S.OptionFromNullishOr(S.Array(S.String), undefined),
  neightborhood: S.OptionFromNullishOr(S.Array(S.Number), undefined),
})

export type LocationSchema = S.Schema.Type<typeof LocationSchema>

export const PropertyDetailsSchema = S.Struct({
  beds: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  baths: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  yearBuilt: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  buildingSqft: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  lotSqft: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  stories: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  units: S.OptionFromNullishOr(RangeFilterSchema, undefined),
})

export type PropertyDetailsSchema = S.Schema.Type<typeof PropertyDetailsSchema>

export const OwnerTypeSchema = S.Struct({
  individual: S.OptionFromNullishOr(
    S.Struct({
      ownerOccupied: S.OptionFromNullishOr(S.Boolean, undefined),
      military: S.OptionFromNullishOr(S.Boolean, undefined),
      vacant: S.OptionFromNullishOr(S.Boolean, undefined),
      absentee: S.OptionFromNullishOr(
        S.Struct({
          inState: S.OptionFromNullishOr(S.Boolean, undefined),
          outOfState: S.OptionFromNullishOr(S.Boolean, undefined),
          outOfCountry: S.OptionFromNullishOr(S.Boolean, undefined),
        }),
        undefined,
      ),
    }),
    undefined,
  ),
  isTrust: S.OptionFromNullishOr(S.Boolean, undefined),
  isBank: S.OptionFromNullishOr(S.Boolean, undefined),
  isCompany: S.OptionFromNullishOr(S.Boolean, undefined),
  taxExemptions: S.OptionFromNullishOr(
    S.Struct({
      isSenior: S.OptionFromNullishOr(S.Boolean, undefined),
      isHomestead: S.OptionFromNullishOr(S.Boolean, undefined),
    }),
    undefined,
  ),
  vacancy: S.OptionFromNullishOr(
    S.Struct({
      isVacant: S.OptionFromNullishOr(S.Boolean, undefined),
    }),
    undefined,
  ),
})

export type OwnerTypeSchema = S.Schema.Type<typeof OwnerTypeSchema>

export const EquityInfoSchema = S.Struct({
  estimatedValue: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  taxValue: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  ownershipLength: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  lastSaleDate: S.OptionFromNullishOr(DateRangeFilterSchema, undefined),
})

export type EquityInfoSchema = S.Schema.Type<typeof EquityInfoSchema>

export const EquityTypeSchema = S.Struct({
  highEquity: S.OptionFromNullishOr(
    S.Struct({
      percentAtLeast: S.OptionFromNullishOr(S.Number, undefined),
      dollarsAtLeast: S.OptionFromNullishOr(S.Number, undefined),
      yearsOwnershipAtLeast: S.OptionFromNullishOr(S.Number, undefined),
      conditionType: S.OptionFromNullishOr(
        ConditionTypeSchema,
        undefined,
      ),
    }),
    undefined,
  ),
  lowEquity: S.OptionFromNullishOr(
    S.Struct({
      percentAtMost: S.OptionFromNullishOr(S.Number, undefined),
      dollarsAtMost: S.OptionFromNullishOr(S.Number, undefined),
      yearsOwnershipAtMost: S.OptionFromNullishOr(S.Number, undefined),
      conditionType: S.OptionFromNullishOr(
        ConditionTypeSchema,
        undefined,
      ),
    }),
    undefined,
  ),
  upsideDown: S.OptionFromNullishOr(
    S.Struct({
      upsideDownAtLeast: S.OptionFromNullishOr(S.Number, undefined),
    }),
    undefined,
  ),
  freeAndClear: S.OptionFromNullishOr(S.Boolean, undefined),
  unknown: S.OptionFromNullishOr(S.Boolean, undefined),
})

export type EquityTypeSchema = S.Schema.Type<typeof EquityTypeSchema>

export const PreforeclosureSchema = S.Struct({
  recordingDate: S.OptionFromNullishOr(DateRangeFilterSchema, undefined),
  auctionDate: S.OptionFromNullishOr(DateRangeFilterSchema, undefined),
  defaultAmount: S.OptionFromNullishOr(RangeFilterSchema, undefined),
  openingBid: S.OptionFromNullishOr(RangeFilterSchema, undefined),
})

export type PreforeclosureSchema = S.Schema.Type<typeof PreforeclosureSchema>

export const FiltersSchema = S.Struct({
  propertyDetails: S.OptionFromNullishOr(PropertyDetailsSchema, undefined),
  propertyTypes: S.OptionFromNullishOr(S.Array(S.Number), undefined),
  ownerType: S.OptionFromNullishOr(OwnerTypeSchema, undefined),
  equityInfo: S.OptionFromNullishOr(EquityInfoSchema, undefined),
  equityType: S.OptionFromNullishOr(EquityTypeSchema, undefined),
  preforeclosure: S.OptionFromNullishOr(PreforeclosureSchema, undefined),
})

export type FiltersSchema = S.Schema.Type<typeof FiltersSchema>

export const CriteriaSchema = S.Struct({
  location: LocationSchema,
  filters: FiltersSchema,
  limit: S.OptionFromNullishOr(S.Number, undefined),
  offset: S.OptionFromNullishOr(S.Number, undefined),
  newOnly: S.OptionFromNullishOr(S.Boolean, undefined),
})

export type CriteriaSchema = S.Schema.Type<typeof CriteriaSchema>

export type CriteriaEncodedSchema = S.Schema.Encoded<typeof CriteriaSchema>

export type Self = CriteriaSchema
// #endregion Schema
