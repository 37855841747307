import { Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import NearbyBuyersFilterPanelAPI from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/NearbyBuyersFilterPanel.api'
import NearbyBuyersFilterPanelCard from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/NearbyBuyersFilterPanelCard'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'

const NearbyBuyersFilterPanel = () => {
  const isOpen = NearbyBuyersFilterPanelAPI.useIsOpen()
  const close = NearbyBuyersFilterPanelAPI.close

  return (
    <Drawer
      onClose={close}
      isOpen={isOpen}
      size='full'
    >
      <DrawerOverlay />
      <DrawerContent
        // borderBottomLeftRadius={3}
        maxWidth='608px'
        borderLeftRadius={mbp({
          mobSm: 0,
          tabSm: 4,
        })}
        overflow='hidden'
      >
        <Breakpoint
          tabSm={(
            <DrawerCloseButton />
          )}
        />
        <NearbyBuyersFilterPanelCard />
      </DrawerContent>
    </Drawer>
  )
}

export default NearbyBuyersFilterPanel
