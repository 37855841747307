import { create } from 'zustand'
import { BillingIntervals } from '__generated__/graphql'

type PricingStore = {
  interval: BillingIntervals
  toggleInterval: (interval?: BillingIntervals) => void
}

export const usePricingStore = create<PricingStore>(set => ({
  interval: BillingIntervals.Month,
  toggleInterval: (interval?: BillingIntervals) => {
    set(state => ({
      interval: interval
      || (state.interval === BillingIntervals.Month
        ? BillingIntervals.Year
        : BillingIntervals.Month),
    }))
  },
}))
