import { Effect, Schedule, Stream } from 'effect'
import { BatchSkiptraceProgress, BatchSkiptraceRepo } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { GETBatchSkiptraceByListId } from 'features/BatchSkiptrace/infra/repo/GETBatchSkiptraceByListId/GETBatchSkiptraceByListId'
import { GETBatchSkiptraceByListIdSchema } from 'features/BatchSkiptrace/infra/repo/GETBatchSkiptraceByListId/GETBatchSkiptraceByListIdSchema'
import { GETBatchSkiptraceStatsByListId } from 'features/BatchSkiptrace/infra/repo/GETBatchSkiptraceStatsByListId/GETBatchSkiptraceStatsByListId'
import { GETBatchSkiptraceStatsByListIdSchema } from 'features/BatchSkiptrace/infra/repo/GETBatchSkiptraceStatsByListId/GETBatchSkiptraceStatsByListIdSchema'
import { GETListOrdersByListId } from 'features/BatchSkiptrace/infra/repo/GETListOrdersByListId/GETListOrdersByListId'
import { GETListOrdersByListIdSchema } from 'features/BatchSkiptrace/infra/repo/GETListOrdersByListId/GETListOrdersByListIdSchema'
import { POSTAttemptBatchSkiptrace } from 'features/BatchSkiptrace/infra/repo/POSTAttemptBatchSkiptrace/POSTAttemptBatchSkiptrace'
import { POSTConfirmBatchSkiptrace } from 'features/BatchSkiptrace/infra/repo/POSTConfirmBatchSkiptrace/POSTConfirmBatchSkiptrace'
import { POSTGetToBatchSkiptraceCount } from 'features/BatchSkiptrace/infra/repo/POSTGetToBatchSkiptraceCount/POSTGetToBatchSkiptraceCount'
import { POSTGetToBatchSkiptraceCountSchema } from 'features/BatchSkiptrace/infra/repo/POSTGetToBatchSkiptraceCount/POSTGetToBatchSkiptraceCountSchema'
import { pipe } from 'remeda'
import { GETDownloadBatchSkiptrace } from './GETDownloadBatchSkiptrace/GETDownloadBatchSkiptrace'

const PAGINATION_LIMIT = 50

export const BatchSkiptraceRepoLive: BatchSkiptraceRepo = {
  getByListId: async (listId, page) => {
    const offset = (page - 1) * PAGINATION_LIMIT

    return await GETBatchSkiptraceByListId.request(
      listId,
      PAGINATION_LIMIT,
      offset,
    )
      .then(GETBatchSkiptraceByListIdSchema.decode)
      .then(GETBatchSkiptraceByListIdSchema.toDomain({
        page,
        listId,
      }))
  },

  getStatsByListId: async listId =>
    await GETBatchSkiptraceStatsByListId.request(listId)
      .then(GETBatchSkiptraceStatsByListIdSchema.decode)
      .then(GETBatchSkiptraceStatsByListIdSchema.toDomain),

  getSelectionToSkiptraceCount: async payload =>
    await POSTGetToBatchSkiptraceCount.request(payload)
      .then(POSTGetToBatchSkiptraceCountSchema.decode)
      .then(POSTGetToBatchSkiptraceCountSchema.toDomain),

  download: async (list, format) =>
    await GETDownloadBatchSkiptrace.request(list, format),

  attemptBatchSkiptrace: async payload =>
    await POSTAttemptBatchSkiptrace.request(payload),

  confirmBatchSkiptrace: async payload =>
    await POSTConfirmBatchSkiptrace.request(payload),

  watchProgress: listId => {
    const ordersPromise = GETListOrdersByListId.request(listId)
    const ordersOnceEffect = pipe(
      Effect.promise(async () => await ordersPromise),
      Effect.map(GETListOrdersByListIdSchema.decode),
      Effect.map(GETListOrdersByListIdSchema.toDomain),
    )

    const statsEffect = pipe(
      Effect.promise(async () => await GETBatchSkiptraceStatsByListId.request(listId)),
      Effect.map(GETBatchSkiptraceStatsByListIdSchema.decode),
      Effect.map(GETBatchSkiptraceStatsByListIdSchema.toDomain),
    )

    const stream = pipe(
      Effect.all([
        ordersOnceEffect,
        statsEffect,
      ]),
      Stream.repeatEffect,
      Stream.schedule(Schedule.spaced('1 second')),
      Stream.map(([ordersResp, statsResp]) => {
        const pendingOrder = ordersResp.orders.find(order => order.status === 'pending')

        if (!pendingOrder) return BatchSkiptraceProgress.Idle()

        const skiptracedCount = pendingOrder.toSkiptraceCount - statsResp.skiptracingCount

        const percentage = skiptracedCount === 0
          ? 0
          : Math.floor(
            skiptracedCount / pendingOrder.toSkiptraceCount * 100,
          )

        if (percentage === 100) return BatchSkiptraceProgress.Idle()

        const MIN_PROGRESS = 10

        return BatchSkiptraceProgress.InProgress({
          percentage: Math.max(MIN_PROGRESS, percentage),
        })
      }),
      Stream.map(progress => progress),
      Stream.changes,
      Stream.takeUntil(progress => progress._tag === 'Idle'),
    )

    return stream
  },
}
