import { SettingsState, SettingsStore } from 'features/Settings/infra/react/Settings.state.zustand'
import { ReactNode, createContext, useContext } from 'react'

const SettingsContext = createContext<SettingsStore | null>(null)

export const SettingsProviderDumb = ({
  children,
  store,
}: {
  children: ReactNode
  store: SettingsStore
}) => (
  <SettingsContext.Provider value={store}>
    {children}
  </SettingsContext.Provider>
)

// eslint-disable-next-line @stylistic/comma-dangle
export const useSettingsStore = <T,>(
  selector: (store: SettingsState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(SettingsContext)

  if (!store)
    throw new Error('useSettingsStore must be used within a SettingsProvider')

  return equalityFn ? store(selector, equalityFn) : store(selector)
}
