import { Text, TextProps } from '@chakra-ui/react'
import { thumbsUpOnly } from 'features/CMA/CMA.helpers'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { shallow } from 'zustand/shallow'

export const CMAReportTotalComps = (props: TextProps) => {
  const report = useCMAStore(api => ({
    totalComps: checkStateHasReportData(api.local.report)
      ? api.local.report.data.salesListInfo.comps.filter(thumbsUpOnly).length
      + api.local.report.data.rentalsListInfo.comps.filter(thumbsUpOnly).length
      : 0,
  }), shallow)

  return (
    <Text
      textStyle='bodyM'
      color='ondark.6'
      {...props}
    >
      {report.totalComps}
      {' '}
      <Breakpoint tabSm='Comps' />
      {' '}
      Included
    </Text>
  )
}
