import { Match } from 'effect'
import { pipe } from 'effect/Function'
import { CMA } from 'features/CMA/CMA.domain'
import { ThumbValue } from 'presentation/components/ThumbButtons'

export const thumbValueFromRating = (rating: CMA.Comp.UserRating): ThumbValue => pipe(
  Match.value(rating),
  Match.when('included', () => 'up' as const),
  Match.when('excluded', () => 'down' as const),
  Match.when('undecided', () => 'undecided' as const),
  Match.exhaustive,
)

export const thumbValueFromRatingNullable = (rating: CMA.Comp.UserRating | null): ThumbValue => pipe(
  Match.value(rating),
  Match.when('included', () => 'up' as const),
  Match.when('excluded', () => 'down' as const),
  Match.when('undecided', () => 'undecided' as const),
  Match.when(null, () => 'undecided' as const),
  Match.exhaustive,
)

export const ratingFromThumbValue = (state: ThumbValue): CMA.Comp.UserRating => pipe(
  Match.value(state),
  Match.when('up', () => 'included' as const),
  Match.when('down', () => 'excluded' as const),
  Match.when('undecided', () => 'undecided' as const),
  Match.exhaustive,
)
