import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Lead } from 'features/PropertyDetails/infra/remote/getProperty/Lead.type'
import { PROPERTY_DETAILS_QUERY } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getPropertyDetails.graphql'
import { mapPropertyDetails } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getPropertyDetails.mapper'
import { RequireSpecificFields } from 'libs/type'
import { apolloClient } from 'presentation/libs/client'

export const getPropertyDetails = async (
  lead: RequireSpecificFields<Lead, 'parcelId'>,
): Promise<PropertyDetails.PropertyWithDetails> =>
  await apolloClient.query({
    query: PROPERTY_DETAILS_QUERY,
    variables: { parcelId: lead.parcelId },
    errorPolicy: 'all',
  })
    .then(({ data }) => {
      const propertyDetails = {
        /** Just for snapshotting purposes */
        __original: data,
        ...mapPropertyDetails(data, lead, lead.id),
      }

      return propertyDetails
    })
