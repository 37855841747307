export const DEFAULT_TOAST_ERROR_MESSAGE = 'Please try again or contact support.'

export const TOAST_PRESET = {
  // General
  GENERIC_SUCCESS: {
    status: 'success',
    title: 'That was a success!',
  },

  GENERIC_ERROR: {
    status: 'error',
    title: 'Something went wrong',
    message: DEFAULT_TOAST_ERROR_MESSAGE,
  },

  // Auth
  LOGOUT: {
    status: 'info',
    title: 'Logging out...',
  },
} as const
