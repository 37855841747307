import { Data } from 'effect'
import { StringLib } from 'libs/String'
import { CompsBoundaryLine } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.types'
import { merge } from 'remeda'

export type GeographyCriteria = {
  location: LocationCriteria[]
  boundary: BoundaryCriteria
}

export type BoundaryCriteria = {
  /**
   * @IMPORTANT Refactor boundary line as general type, and it's comps applications,
   * before implementing it for list builder
   */
  lines: CompsBoundaryLine[]
}

export type LocationCriteria =
  | LocationCity
  | LocationCounty
  | LocationZip

export type LocationCity = { readonly _tag: 'LocationCity', value: string }
export type LocationCounty = { readonly _tag: 'LocationCounty', value: string, fips: string }
export type LocationZip = { readonly _tag: 'LocationZip', value: string }

export const LocationCriteria = {
  County: (value: string, fips: string) => Data.tagged<LocationCounty>('LocationCounty')({ value, fips }),
  City: (value: string) => Data.tagged<LocationCity>('LocationCity')({ value }),
  Zip: (value: string) => Data.tagged<LocationZip>('LocationZip')({ value }),
}

export const LocationCounty = {
  /**
   * @example
   * LocationCounty.formatCounty('Dallas, TX') // 'Dallas County, TX'
   */
  formatCounty: (county: string) => {
    const parts = county.split(', ')
    const firstParts = parts.slice(0, parts.length - 1)
    const lastPart = parts[parts.length - 1]
    const withCounty = `${firstParts.join(', ')} County, ${lastPart}`
    const withCorrectCasing = StringLib.isAllCaps(county)
      ? withCounty.toUpperCase()
      : withCounty
    return withCorrectCasing
  },
}

export const GeographyCriteria = {
  make: (params?: Partial<GeographyCriteria>): GeographyCriteria => {
    const DEFAULTS: GeographyCriteria = {
      boundary: { lines: [] },
      location: [],
    }

    return merge(DEFAULTS, params)
  },
}
