import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const CarIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.path
        d='M3.77407 24.4993H28.6663C29.7709 24.4993 30.6663 23.6039 30.6663 22.4993V19.4982C30.6663 18.8498 30.4563 18.2188 30.0676 17.6998L28.7699 15.9671C28.3922 15.4629 27.799 15.166 27.169 15.166H4.84398C3.89106 15.166 3.07048 15.8383 2.88305 16.7726L1.81314 22.106C1.5648 23.3439 2.5115 24.4993 3.77407 24.4993Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M5.33301 15.166L6.24148 9.67622C6.48113 8.2281 7.73294 7.16602 9.20076 7.16602H13.2542H18.5724C19.7692 7.16602 20.8503 7.87739 21.325 8.97606L23.9997 15.166H13.2542H5.33301Z'
        fill={fillFromProps}
      />

      <chakra.path
        d='M13.2542 7.16602C12.0336 7.16602 10.4887 7.16602 9.20076 7.16602C7.73294 7.16602 6.48113 8.2281 6.24148 9.67622L5.33301 15.166H13.2542M13.2542 7.16602C14.7535 7.16602 16.976 7.16602 18.5724 7.16602C19.7692 7.16602 20.8503 7.87739 21.325 8.97606L23.9997 15.166H13.2542M13.2542 7.16602V15.166'
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.circle
        cx='22.6667'
        cy='24.5007'
        r='2.66667'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.circle
        cx='9.33366'
        cy='24.5007'
        r='2.66667'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />
    </chakra.svg>

  )
}
