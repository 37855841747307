import { Year } from 'features/valueObjects/Year'
import _ from 'lodash/fp'
import { ValidateResult } from 'react-hook-form'
import { isString } from 'remeda'

export const MINIMUM_AMOUNT = 10
const IS_VALID = true
const IS_INVALID = false

export type FormValidator = (value: string, formValues: object) => ValidateResult

export const composeValidators = (...validators: FormValidator[]): FormValidator =>
  value => {
    const resultWithNull = validators
      .reduce((
        validationValue: ValidateResult | null,
        validator: FormValidator,
      ): ValidateResult | null => {
        if (validationValue === IS_INVALID)
          return IS_INVALID
        if (isString(validationValue))
          return validationValue

        return validator(value, {})
      }, null)

    const result = resultWithNull === null ? IS_VALID : resultWithNull
    return result
  }

const isBelowMinimum = (minimum: number, message: string): FormValidator => {
  const validator: FormValidator = (value?: string) => {
    if (!value || value.trim() === '') return true
    const num = Number(value)
    return !_.isNaN(num) && num < minimum ? message : true
  }
  return validator
}

const isNumber = (message: string): FormValidator => {
  const validator: FormValidator = (value?: string) =>
    !isNaN(Number(value)) ? true : message
  return validator
}

const isEmpty = (message: string): FormValidator => {
  const validator: FormValidator = (value?: string) =>
    !value || value.trim() === '' ? message : true
  return validator
}

const isOnlyUpToTwoDecPlaces = (message: string): FormValidator => {
  const validator: FormValidator = (value?: string) => {
    if (!value || value.trim() === '') return true
    const parts = value.split('.')
    return parts.length > 2 || parts[1]?.length > 2 ? message : true
  }
  return validator
}

export const validateAmount: FormValidator = composeValidators(
  isEmpty('Please fill in an amount'),
  isNumber('Please fill in valid amount'),
  isOnlyUpToTwoDecPlaces('Please fill in up to two decimal places'),
  isBelowMinimum(MINIMUM_AMOUNT, `Please fill in a minimum amount of $${MINIMUM_AMOUNT}`),
)

export const validateYear = (message = 'Please enter a valid year'): FormValidator =>
  (year: string) => !year || Year.isValidString(year)
    ? IS_VALID
    : message

export const FormValidators = {
  validateAmount,
  validateYear,
  composeValidators,
  isBelowMinimum,
  isNumber,
  isEmpty,
  isOnlyUpToTwoDecPlaces,
}
