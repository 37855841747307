import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeCondoIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.rect
      x='22.625'
      y='16.625'
      width='14.75px'
      height='27.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='37.625'
      y='29.625'
      width='6.75px'
      height='14.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='15.625'
      y='29.625'
      width='6.75px'
      height='14.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='25.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='25.625'
      y='20.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='25.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='17.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='31.625'
      y='26.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='31.625'
      y='20.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='31.625'
      y='32.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='28.625'
      y='38.625'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
  </chakra.g>
)
