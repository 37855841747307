import { gql } from '__generated__'

export const GET_BUYER_DEALS_QUERY = gql(/* GraphQL */`
query getBuyerDeals(
  $id: ID!
) {
  parcelContact(id: $id) {
    ... on ParcelContactNotFoundError {
      message
      field
    }
    ... on ParcelContact {
      id
      buyerDetails {
        buyerTypes
        stats {
          min
          max
          total
          average
          count
        }
        historicalDeals(first: 50) {
          edges {
            node {
              ...NearbyBuyersDealDetails

              parcel {
                ...NearbyBuyersParcelDetails
              }
            }
          }
          pageInfo {
            totalCount
          }
        }
      }
    }
  }
}

`)
