import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import { formatCommas } from 'libs/Number/formatNumber'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import ProductFeatureInterval from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureInterval'
import { ProductFeatureSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'

type ColumnValue = {
  readonly primaryText: string
  readonly secondaryText: Option.Option<string>
  readonly emphasisText: Option.Option<string>
}

type LeadsFeatureRowViewModel = readonly Option.Option<ColumnValue>[]

const isLeadFeature = (feature: ProductFeatureSpec): boolean =>
  feature.featureName === 'LeadListExports'

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const vm: LeadsFeatureRowViewModel = pipe(
    snapshot.context.products,
    /**
     * Make sure we only show the product if the plan is available
     */
    Array.filter(product => pipe(
      product.plans,
      Array.some(plan =>
        plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
    )),
    Array.map(product => pipe(
      product.features,
      Array.findFirst(isLeadFeature),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => pipe(
          Data.struct<ColumnValue>({
            primaryText: 'Unlimited',
            secondaryText: Option.none(),
            emphasisText: Option.none(),
          }),
          Option.some,
        )),
        Match.tag('FeatureLimitedSpec', f => pipe(
          Data.struct<ColumnValue>({
            primaryText: `${formatCommas(f.limit)} Leads`,
            secondaryText: Option.some(`per ${ProductFeatureInterval.toSingularForm(f.interval)}`),
            emphasisText: Option.none(),
          }),
          Option.some,
        )),
        Match.orElse(() => Option.none()),
      )),
    )),
    Data.array,
  )
  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const LeadsFeatureRowViewModel = {
  usePresenter,
}

export default LeadsFeatureRowViewModel
