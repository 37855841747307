import { Badge, HStack, Menu, MenuButton, MenuItemOption, MenuOptionGroup, useToken } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SwitchTabBadge, SwitchTabButton, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { VisualsSectionProps } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/VisualsSection.props'
import { MenuTitle } from 'presentation/components/Menu/Menu'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { MapStoreProvider } from 'presentation/components/Map'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useSwitchTabsContext } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { getMarkerInfoFromProperty } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsScreen.helpers'
import { MLSPhotos } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/MLSPhotos'
import { MapView } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MapView'
import { NoLocationView } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/NoLocationView'
import { StreetView } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/StreetView'
import { shallow } from 'zustand/shallow'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const VisualsSection = ({
  __DANGER_STORYBOOK_ONLY__,
}: VisualsSectionProps) => {
  const size3token = useToken('sizes', '3')
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details')
      return null

    return {
      location: property.location,
      address: property.address,
      mlsPhotos: property.status === 'with-details'
        ? property.mlsListings[0]?.photos || []
        : [],
      markerInfo: getMarkerInfoFromProperty(property),
    }
  }, shallow)

  if (!data) return null

  const {
    mlsPhotos,
    location,
    markerInfo,
    address,
  } = data

  return (
    <SwitchTabsWrapper
      colorScheme='strong'
      initialActiveTabKey={
        __DANGER_STORYBOOK_ONLY__?.initialActiveTabKey
          ? __DANGER_STORYBOOK_ONLY__?.initialActiveTabKey
          : mlsPhotos.length === 0
            ? 'map'
            : 'photos'
      }
      display='flex'
      flexDirection='column'
      flex='1'
    >
      <SwitchTabButtons>
        <Breakpoint
          mobSm={(
            <>
              <PhotosTabButton count={mlsPhotos.length} isMuted={!mlsPhotos.length} />
              <MapTabButton isMuted={!location} isHidden />
              <SatelliteTabButton isMuted={!location} isHidden />
              <StreetTabButton isMuted={!location} isHidden />
              <MoreTabButton
                photoCount={mlsPhotos.length}
              />
            </>
          )}
          mob={(
            <>
              <PhotosTabButton count={mlsPhotos.length} isMuted={!mlsPhotos.length} />
              <MapTabButton isMuted={!location} />
              <SatelliteTabButton isMuted={!location} isHidden />
              <StreetTabButton isMuted={!location} isHidden />
              <MoreTabButton
                photoCount={mlsPhotos.length}
              />
            </>
          )}
          tabSm={(
            <>
              <PhotosTabButton count={mlsPhotos.length} isMuted={!mlsPhotos.length} />
              <MapTabButton isMuted={!location} isHidden />
              <SatelliteTabButton isMuted={!location} isHidden />
              <StreetTabButton isMuted={!location} isHidden />
              <MoreTabButton
                photoCount={mlsPhotos.length}
              />
            </>
          )}
          dtSm={(
            <>
              <PhotosTabButton count={mlsPhotos.length} isMuted={!mlsPhotos.length} />
              <MapTabButton isMuted={!location} />
              <SatelliteTabButton isMuted={!location} />
              <StreetTabButton isMuted={!location} />
            </>
          )}
        />
      </SwitchTabButtons>
      <SwitchTabContents
        boxShadow='primary.s'
        display='flex'
        flexDirection='column'
        flex='1'
        borderRadius='3'
      >
        <SwitchTabContent
          display='flex'
          flexDirection='column'
          flex='1'
          p={mbp({ mobSm: '1', mob: '2' })}
          sx={{
            borderRadius: `${size3token} !important`,
            borderTopLeftRadius: '0 !important',
          }}
        >
          <MLSPhotos photos={mlsPhotos} />
        </SwitchTabContent>
        <SwitchTabContent
          display='flex'
          flexDirection='column'
          flex='1'
          p={mbp({ mobSm: '1', mob: '2' })}
          sx={{
            borderRadius: `${size3token} !important`,
            borderTopLeftRadius: '0 !important',
          }}
        >
          <MapStoreProvider>
            {location
              ? <MapView location={location} markerInfo={markerInfo} mapMode='map' />
              : <NoLocationView />}
          </MapStoreProvider>
        </SwitchTabContent>
        <SwitchTabContent
          display='flex'
          flexDirection='column'
          flex='1'
          p={mbp({ mobSm: '1', mob: '2' })}
          sx={{
            borderRadius: `${size3token} !important`,
            borderTopLeftRadius: '0 !important',
          }}
        >
          <MapStoreProvider>
            {location
              ? <MapView location={location} markerInfo={markerInfo} mapMode='satellite' />
              : <NoLocationView />}
          </MapStoreProvider>
        </SwitchTabContent>
        <SwitchTabContent
          display='flex'
          flexDirection='column'
          flex='1'
          p={mbp({ mobSm: '1', mob: '2' })}
          sx={{
            borderRadius: `${size3token} !important`,
            borderTopLeftRadius: '0 !important',
          }}
        >
          {location
            ? <StreetView location={location} address={address} />
            : <NoLocationView />}
        </SwitchTabContent>
      </SwitchTabContents>
    </SwitchTabsWrapper>
  )
}

export const PhotosTabButton = ({ count, isMuted }: { count: number, isMuted: boolean }) => (
  <SwitchTabButton
    isMuted={isMuted}
    tabKey={'photos' satisfies VisualsSectionProps.TabKey}
  >
    <SwitchTabBadge colorScheme={count > 0 ? 'positivesat' : 'negative'}>
      {count}
    </SwitchTabBadge>
    MLS Photos
  </SwitchTabButton>
)

export const StreetTabButton = ({ isHidden, isMuted }: { isHidden?: boolean, isMuted: boolean }) => (
  <SwitchTabButton
    isMuted={isMuted}
    tabKey={'street' satisfies VisualsSectionProps.TabKey}
    isHidden={isHidden}
  >
    <Breakpoint
      mobSm='Street View'
      dtSm='Street'
      dtLg='Street View'
    />
  </SwitchTabButton>
)

export const MapTabButton = ({ isHidden, isMuted }: { isHidden?: boolean, isMuted: boolean }) => (
  <SwitchTabButton
    isMuted={isMuted}
    tabKey={'map' satisfies VisualsSectionProps.TabKey}
    isHidden={isHidden}
  >
    Map
  </SwitchTabButton>
)

export const SatelliteTabButton = ({ isHidden, isMuted }: { isHidden?: boolean, isMuted: boolean }) => (
  <SwitchTabButton
    isMuted={isMuted}
    tabKey={'satellite' satisfies VisualsSectionProps.TabKey}
    isHidden={isHidden}
  >
    Satellite
  </SwitchTabButton>
)

export const MoreTabButton = ({ photoCount }: { photoCount: number }) => {
  const { activeTabKey, setActiveTabKey } = useSwitchTabsContext()

  const handleOnChange = (value: string | string[]) => {
    if (Array.isArray(value)) return
    setActiveTabKey(value)
  }

  return (
    <Menu>
      <SwitchTabButton as={MenuButton} hasContent={false}>More+</SwitchTabButton>

      <ResponsiveMenuList>
        <MenuTitle>Map & Photos</MenuTitle>

        <MenuOptionGroup defaultValue={activeTabKey || ''} type='radio' onChange={handleOnChange}>
          <MenuItemOption value={'photos' satisfies VisualsSectionProps.TabKey}>
            <HStack>
              <span>MLS Photos</span>
              <Badge colorScheme={photoCount > 0 ? 'positivesat' : 'negative'}>
                {photoCount}
              </Badge>
            </HStack>
          </MenuItemOption>
          <MenuItemOption value={'map' satisfies VisualsSectionProps.TabKey}>Map View</MenuItemOption>
          <MenuItemOption value={'satellite' satisfies VisualsSectionProps.TabKey}>Satellite View</MenuItemOption>
          <MenuItemOption value={'street' satisfies VisualsSectionProps.TabKey}>Street View</MenuItemOption>
        </MenuOptionGroup>
      </ResponsiveMenuList>
    </Menu>
  )
}
