import { CMA } from 'features/CMA/CMA.domain'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'

export const formatBedsBathsGarageFromComp = (comp: CMA.SingleComp | CMA.SubjectComp) => {
  const bedsStr = NumberLib.ensureOr('--')(comp.bedroomsCount)
  const bathsTotal = comp.bathroomsCountInfo
    ? BathroomsCountInfo.getTotal(comp.bathroomsCountInfo)
    : null
  const bathsStr = StringLib.ensureOr('--')(bathsTotal)
  const garageStr = NumberLib.ensureOr('--')(comp.garageSpacesCount)
  return `${bedsStr} / ${bathsStr} / ${garageStr}`
}

export const thumbsUpOnly = (comp: CMA.SingleComp) => comp.userRating === 'included'
