import { Box, Button, Spacer, VStack } from '@chakra-ui/react'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { ListPreviewMap } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMap'
import { PreviewBar } from 'presentation/screens/ListPreviewScreen/components/PreviewBar'
import { useSaveListModal } from 'presentation/screens/ListPreviewScreen/components/SaveListModal'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export const ListPreviewMobileMapSubscreen = () => {
  usePreviewMobileHeaderContents()

  return (
    <>
      <VStack align='stretch' h='layout-height'>
        <ListPreviewMap />
        <Box h='52px' pos='relative' zIndex={2}>
          <Box pos='absolute' left='0' bottom='0' right='0' zIndex='1'>
            <PreviewBar />
          </Box>
        </Box>
      </VStack>
    </>
  )
}

const usePreviewMobileHeaderContents = () => {
  const setHeaderContents = useSetMobileHeaderContents()
  const saveListModalApi = useSaveListModal()

  useEffect(() => {
    setHeaderContents([
      <Button
        key='map-left'
        color='ondark.2'
        textTransform='none'
        textStyle='bodyMFat'
        leftIcon={(
          <ArrowLeftIcon fontSize={3} />
        )}
        as={Link}
        to='/lists/builder'
      >
        Edit Filters
      </Button>,
      <Spacer key='map-center' />,
      <Button
        key='map-right'
        size='md'
        variant='solid'
        colorScheme='positive'
        mr={1}
        onClick={saveListModalApi.open}
      >
        Save List
      </Button>,
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [])
}
