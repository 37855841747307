import { Match, pipe } from 'effect'
import { PartialRange } from 'features/valueObjects/Range'
import { Acres } from 'libs/Acres'
import { NumberLib } from 'libs/Number'
import { formatCommas } from 'libs/Number/formatNumber'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { SizeFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SizeFilterDropdown/SizeFilterDropdown.dumb.props'
import { FilterContentGroup } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/common/FilterContentGroup'
import { PartialRangeFormErrors, PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { isNumber } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

const SQFT_PER_ACRE = 43560

export const SizeFilterDropdownDumb = (
  props: SizeFilterDropdownDumbProps,
) => {
  const [hasError, setHasError] = useState(false)
  const lotSizeSubjectValueSqft = props.lotSizeFilter.subjectValue
  const lotSizeSubjectValueAcres = isNumber(lotSizeSubjectValueSqft)
    ? NumberLib.omitAfterNPlaces(2)(lotSizeSubjectValueSqft / SQFT_PER_ACRE)
    : null

  /**
   * Other
   */
  const lotSizeSqftRange = props.lotSizeFilter.value.sqftRange
  const lotSizeAcresRange = lotSizeSqftRange
    ? lotSizeSqftRange?.map(numOrNull => {
      if (numOrNull === null) return null
      return NumberLib.omitAfterNPlaces(2)(numOrNull / SQFT_PER_ACRE)
    }) as PartialRange
    : PartialRange.EMPTY
  const selectedValue = formatSelectedValue({
    houseSizeRange: props.houseSizeFilter.value,
    lotSizeRange: lotSizeAcresRange,
    lotSizeUnit: 'acres',
  })

  const handleOnError = (errors: PartialRangeFormErrors) =>
    isNonNullable(errors.min || errors.max) ? setHasError(true) : setHasError(false)

  return (
    <FilterPopover>
      <FilterPopoverButton
        label='Size'
        display={mbp({ mobSm: 'none', dtLg: 'flex' })}
        minW='130px'
        hasError={hasError}
      >
        {selectedValue}
      </FilterPopoverButton>
      <FilterPopoverContent>
        <FilterContentGroup title='House Size Sqft'>
          <PartialRangeInputV2
            initialValue={props.houseSizeFilter?.value}
            onDebouncedValidChange={newRange => {
              props.houseSizeFilter?.onChange(newRange)
            }}
            subjectValue={NumberLib.formatCODoDD(props.houseSizeFilter?.subjectValue)}
            onError={handleOnError}
            formatInputDisplay={v => {
              const parsed = isNumber(v) ? v : NumberLib.fromStringSafe(v)

              if (parsed === null) return ''

              return NumberLib.formatCommaOptionalDecimals(parsed)
            }}
          />
        </FilterContentGroup>
        <FilterContentGroup
          mt={3}
          title='Lot Size Acres'
          // @GENESIS
          // toolbar={(
          //   <SizeUnitSwitch
          //     isSqft={isSqft}
          //     handleOnUnitChange={handleOnUnitChange}
          //   />
          // )}
        >
          <PartialRangeInputV2
            precision={3}
            initialValue={lotSizeAcresRange}
            onDebouncedValidChange={newRange => {
              props.lotSizeFilter.onChange({
                sqftRange: newRange.map(numOrNull => {
                  if (numOrNull === null) return null
                  return numOrNull * SQFT_PER_ACRE
                }) as PartialRange,

                /** @NOTE This doesn't matter right now because backend doesn't support unit */
                unit: 'acres',
              })
            }}
            subjectValue={lotSizeSubjectValueAcres?.toString()}
            onError={handleOnError}
          />
        </FilterContentGroup>

        {/* @GENESIS */}
        {/* <FilterActionButtonGroup>
          <FilterResetButton onClick={handleOnReset} />
          <FilterDoneButton
            onClick={handleOnDone}
            isDisabled={hasError.houseSize || hasError.lotSize}
          />
        </FilterActionButtonGroup>

        <Box pl={2}>
          {props.houseSizeFilter.filterErrorMsg && (
            <CompsFilterCardError error={props.houseSizeFilter.filterErrorMsg} />
          )}

          {props.lotSizeFilter.filterErrorMsg && (
            <CompsFilterCardError error={props.lotSizeFilter.filterErrorMsg} />
          )}
        </Box> */}
      </FilterPopoverContent>
    </FilterPopover>
  )
}

// @GENESIS
// const SizeUnitSwitch = (props: {
//   isSqft: boolean
//   handleOnUnitChange: () => void
// }) => (
//   <Switch
//     colorScheme='neutral'
//     isChecked={props.isSqft}
//     onChange={props.handleOnUnitChange}
//     ml='auto'
//     size='lg'
//   >
//     <Flex
//       justifyContent='space-around'
//     >
//       <Text color='inherit'>sqft</Text>
//       <Text color='inherit'>ac</Text>
//     </Flex>
//   </Switch>
// )

const formatRange = (range: PartialRange | null) => pipe(
  Match.value({
    min: range?.[0] ?? null,
    max: range?.[1] ?? null,
  }),
  Match.when({ min: Match.number, max: Match.null },
    ({ min }) => `${formatCommas(min)}+`),
  Match.when({ min: Match.null, max: Match.number },
    ({ max }) => `Up to ${formatCommas(max)}`),
  Match.when({ min: Match.number, max: Match.number },
    ({ min, max }) => `${formatCommas(min)} - ${formatCommas(max)}`),
  Match.orElse(() => 'Any'),
)

const formatAcresRange = (range: PartialRange | null) => pipe(
  Match.value({
    min: range?.[0] ?? null,
    max: range?.[1] ?? null,
  }),
  Match.when({ min: Match.number, max: Match.null },
    ({ min }) => `${Acres.formatWithVariableDecimals(min)}+`),
  Match.when({ min: Match.null, max: Match.number },
    ({ max }) => `Up to ${Acres.formatWithVariableDecimals(max)}`),
  Match.when({ min: Match.number, max: Match.number },
    ({ min, max }) => `${Acres.formatWithVariableDecimals(min)} - ${Acres.formatWithVariableDecimals(max)}`),
  Match.orElse(() => 'Any'),
)

const formatSelectedValue = (params: {
  houseSizeRange: PartialRange | null
  lotSizeRange: PartialRange | null
  lotSizeUnit: 'sqft' | 'acres'
}) => {
  const houseSize = formatRange(params.houseSizeRange)
  const lotSize = formatAcresRange(params.lotSizeRange)
  return `${houseSize}/${lotSize} ${params.lotSizeUnit}`
}
