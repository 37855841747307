import { Box, Flex, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { NumberLib } from 'libs/Number'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { getMLSStatusTagColor } from 'presentation/components/MLSStatusTag'
import { SubjectPropertyStat } from 'presentation/screens/CompsScreen/CompsScreen.types'
import { CMAEntryTileCardProps } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/CMAEntryTileCard.props'
import { animationClasses } from 'presentation/utils/animationClasses'

export type DataSectionProps = {
  shouldShowSubjectStats: boolean
} & CMAEntryTileCardProps

export const DataSection = (props: DataSectionProps) => {
  const {
    bgColor,
    color,
  } = pipe(
    Match.value(props),
    Match.when({ entryType: 'regular' }, entry =>
      getMLSStatusTagColor(entry.status)),
    Match.when({ entryType: 'subject' }, () => ({
      color: 'ondark.1',
      bgColor: 'accent.darkpurple',
    })),
    Match.exhaustive,
  )

  const shouldShowSubjectIcon = props.entryType === 'subject'

  const mapCompToStats = (comp: CMA.SingleComp | SubjectPropertyStat) => [
    { label: 'beds', value: comp.bedroomsCount ?? '--' },
    {
      label: 'baths',
      value: (comp.bathroomsCountInfo
      && BathroomsCountInfo.getTotal(comp.bathroomsCountInfo))
      ?? '--',
    },
    { label: 'garage', value: comp.garageSpacesCount ?? '--' },
    { label: 'sqft', value: NumberLib.formatCODoDD(comp.livingAreaSqft) },
    { label: 'built', value: comp.yearBuilt ?? '--' },
    {
      label: 'acre',
      value: NumberLib.ifElse(NumberLib.omitAfterNPlaces(2))(() => '--')(comp.lotAreaAcres),
    },
  ]

  const stats = mapCompToStats(props)

  const subjectStats = pipe(
    Match.value(props),
    Match.when({
      entryType: 'regular',
      subjectProperty: Match.defined,
    }, entry => mapCompToStats(entry.subjectProperty)),
    Match.when({ entryType: 'subject' }, () => []),
    Match.orElse(() => []),
  )

  return (
    <DataSectionDumb
      shouldShowSubjectStats={props.shouldShowSubjectStats}
      bgColor={bgColor}
      color={color}
      stats={stats}
      subjectStats={subjectStats}
      shouldShowSubjectIcon={shouldShowSubjectIcon}
    />
  )
}

export const DataSectionDumb = (props: {
  shouldShowSubjectStats: boolean
  bgColor: string
  color: string
  stats: { label: string, value: string | number }[]
  subjectStats: { value: string | number }[]
  shouldShowSubjectIcon: boolean
}) => {
  const {
    bgColor,
    color,
    stats,
    subjectStats,
    shouldShowSubjectIcon,
  } = props

  return (
    <Box position='relative'>
      <Flex
        justifyContent='space-around'
        borderTopRadius={1}
        borderBottomRadius={props.shouldShowSubjectStats ? 0 : 1}
        p={0.5}
        color={color}
        bgColor={bgColor}
        boxShadow='float'
      >
        {shouldShowSubjectIcon && <SubjectPropertyIcon boxSize={2} alignSelf='center' />}
        {stats.map((stat, i) => (
          <Flex
            flexDirection='column'
            key={i}
            flex='1'
            gap={0.5}
          >
            <Text
              textStyle='bodyM'
              color='inherit'
              textAlign='center'
            >
              {stat.label}
            </Text>
            <Text
              textStyle='bodyMFat'
              color='inherit'
              textAlign='center'
            >
              {stat.value}
            </Text>
          </Flex>
        ))}
      </Flex>
      {props.shouldShowSubjectStats && (
        <Flex
          position='absolute'
          top='100%'
          left='0'
          right='0'
          bgColor='accent.darkpurple'
          borderBottomRadius={1}
          px={0.5}
          py={0}
          justifyContent='space-around'
          boxShadow='0px 4px 4px 0px #00000040'
          h={2.5}
          className={classNames(animationClasses.fadeIn, animationClasses.faster)}
        >

          <SubjectPropertyIcon
            position='absolute'
            left={0.25}
            top={0.25}
            boxSize={2}
          />

          {subjectStats.map((stat, i) => (
            <Text
              key={i}
              flex='1'
              textStyle='bodyMFat'
              textAlign='center'
              color='ondark.1'
              mt='-1px'
            >
              {stat.value}
            </Text>
          ))}
        </Flex>
      )}
    </Box>
  )
}
