import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { RestorePlanFlow_RestoreMutation } from '__generated__/graphql'
import { GraphQLFormattedError } from 'graphql'
import { useCallback } from 'react'

export const RESTORE_PLAN_FLOW__RESTORE = gql(/* GraphQL */ `
  mutation RestorePlanFlow_Restore {
    billingResubscribe {
      ... on BillingNoSubscriptionError {
        message
      }
      ... on Enterprise {
        __typename

        ...SubscriptionUpdate
      }
    }
  }
`)

export type UseRestorePlanMutationResult = {
  restore: () => Promise<string>
  loading: boolean
}

export const useRestorePlanMutation = (): UseRestorePlanMutationResult => {
  const [restoreMutation, { loading }] = useMutation(RESTORE_PLAN_FLOW__RESTORE)

  const restore = useCallback(async () =>
    await restoreMutation()
      .then(({ errors, data }) => {
        const errMsg = data ? getErrors(data, errors) : null

        if (errMsg) throw new Error(errMsg)

        if (data?.billingResubscribe.__typename === 'Enterprise')
          return 'Your plan has been restored successfully!'

        throw new Error('Unable to restore your plan. Please try again or contact support.')
      })
  , [restoreMutation])

  return { restore, loading }
}

const getErrorFromData = (data: RestorePlanFlow_RestoreMutation): string | null => {
  if ('message' in data.billingResubscribe)
    return data?.billingResubscribe?.message ?? null

  return null
}

const getErrors = (
  data: RestorePlanFlow_RestoreMutation,
  graphqlError: readonly GraphQLFormattedError[] | undefined,
): string | null => {
  const errorFromData = getErrorFromData(data)

  if (errorFromData) return errorFromData

  if (graphqlError?.[0]?.message)
    return 'Unexpected error occurred. Please try again or contact support.'

  return null
}
