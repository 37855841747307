import { Schema as S } from '@effect/schema'

const PropertyAddressSchema = S.Struct({
  line1: S.OptionFromNullishOr(S.String, undefined),
  city: S.OptionFromNullishOr(S.String, undefined),
  state: S.OptionFromNullishOr(S.String, undefined),
  zip: S.OptionFromNullishOr(S.String, undefined),
  lat: S.OptionFromNullishOr(S.Union(S.Number, S.String), undefined),
  lon: S.OptionFromNullishOr(S.Union(S.Number, S.String), undefined),
})

const ContactAddressSchema = S.Struct({
  line1: S.OptionFromNullishOr(S.String, undefined),
  city: S.OptionFromNullishOr(S.String, undefined),
  state: S.OptionFromNullishOr(S.String, undefined),
  zip: S.OptionFromNullishOr(S.String, undefined),
})

const PropertySchema = S.Struct({
  id: S.OptionFromNullishOr(S.Union(S.String, S.Number), undefined),
  address: S.OptionFromNullishOr(PropertyAddressSchema, undefined),
  baths_full: S.OptionFromNullishOr(S.Number, undefined),
  baths_half: S.OptionFromNullishOr(S.Number, undefined),
  beds: S.OptionFromNullishOr(S.Number, undefined),
  garageSpaces: S.OptionFromNullishOr(S.Number, undefined),
  sqft: S.OptionFromNullishOr(S.Union(S.Number, S.String), undefined),
  year_built: S.OptionFromNullishOr(S.Union(S.NumberFromString, S.Number), undefined),
})

const MemberMetaSchema = S.Struct({
  property: S.OptionFromNullishOr(PropertySchema, undefined),
  propertyUseType: S.OptionFromNullishOr(S.String, undefined),
  lastSaleDate: S.OptionFromNullishOr(S.Date, undefined),
  value: S.OptionFromNullishOr(S.Union(S.Number, S.NumberFromString), undefined),
  equity: S.OptionFromNullishOr(S.Number, undefined),
  lotSqft: S.OptionFromNullishOr(S.Number, undefined),
  lastSalePrice: S.OptionFromNullishOr(S.Union(S.Number, S.NumberFromString), undefined),
  isForeclosure: S.OptionFromNullishOr(S.Boolean, undefined),
  isHomestead: S.OptionFromNullishOr(S.Boolean, undefined),
  isSenior: S.OptionFromNullishOr(S.Boolean, undefined),
  isVacant: S.OptionFromNullishOr(S.Boolean, undefined),
  isTaxDelinquent: S.OptionFromNullishOr(S.Boolean, undefined),
})

const ContactSchema = S.Struct({
  address: S.OptionFromNullishOr(ContactAddressSchema, undefined),
  firstName: S.OptionFromNullishOr(S.String, undefined),
  lastName: S.OptionFromNullishOr(S.String, undefined),
})

const MarketingListMemberSchema = S.Struct({
  id: S.Union(S.String, S.Number),
  listId: S.String,
  contact: S.OptionFromNullishOr(ContactSchema, undefined),
  meta: MemberMetaSchema,
})

type MarketingListMemberSchema = S.Schema.Type<typeof MarketingListMemberSchema>

export default MarketingListMemberSchema
