import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const SearchIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.circle
        cx='13.3333'
        cy='13.8333'
        r='9.33333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />
      <chakra.path
        d='M28.0003 28.4993L22.667 23.166'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <chakra.path
        d='M13.3333 8.5C12.633 8.5 11.9394 8.63795 11.2924 8.90598C10.6453 9.174 10.0573 9.56685 9.5621 10.0621C9.06685 10.5573 8.674 11.1453 8.40598 11.7924C8.13795 12.4394 8 13.133 8 13.8333'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </chakra.svg>

  )
}
