/**
 * @example
 * const isBillingInvoice = is('BillingInvoice' as const)
 * const status = isBillingInvoice(nextInvoiceRaw) ? nextInvoiceRaw.status : null
 */
export const isGqlType = <TypeName extends string>(
  typeName: TypeName,
) => <Type extends { __typename?: string } | undefined>(
  value?: Type | null,
): value is Extract<Type, { __typename?: TypeName }> =>
  value?.__typename === typeName

/** Alias for isGqlType */
export const is = isGqlType

/**
 * @TODO Rename to narrowGqlType
 * @example
 * const getBillingInvoice = get('BillingInvoice' as const)
 * const billingInvoiceOrNull = getBillingInvoice(nextInvoiceRaw)
 */
export const getGqlType = <TypeName extends string>(
  typeName: TypeName,

) => <Type extends { __typename?: string } | undefined>(
  value?: Type | null,
): Extract<Type, { __typename?: TypeName }> | null => {
  const fn = is(typeName)
  return fn(value) ? value : null
}

/**
 * @TODO Rename to narrow
 * Alias for isGqlType
 */
export const get = getGqlType
