import { Effect, Match, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'
import MarketingListSchema from 'features/ListBuilder/infra/repo/schema/MarketingListSchema'
import { EffectLib } from 'libs/Effect'
import { restClient } from 'presentation/libs/client'

const CreateListResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.CreateList) => pipe(
    Effect.tryPromise({
      try: async () =>
        await restClient.post(`lists/v3`, {
          json: {
            name: request.name,
            type: 'national-leads-sellers', // @TODO: replace with dynamic
          },
        })
          .json(),
      catch: error => new UnknownException(error),
    }),
    Effect.andThen(raw => decode(raw)),
    Effect.andThen(decoded => toDomain(decoded)),
    Effect.andThen(domain => domain.id),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default CreateListResolver

const decode = EffectLib.makeLoggedDecoder({
  schema: MarketingListSchema,
  name: 'GETFindByListIdResponse',
})

const toDomain = (response: MarketingListSchema): MarketingList => MarketingList.make({
  id: MarketingListId.make(response.id),
  type: ListTypeSchema.toDomain(response.type),
  name: response.name,
  createdAt: response.createdAt,
  updatedAt: response.updatedAt ?? response.createdAt,
  memberCount: response.memberStats?.total ?? 0,
  skiptracedCount: response.memberStats?.skiptraced ?? 0,
  criteria: CriteriaSchema.toDomain(
    Match.value(response.type).pipe(
      Match.when('national-leads-sellers', (): ListType => 'property-lists'),
      Match.when('national-leads-cashbuyers', (): ListType => 'cash-buyers'),
      Match.when('national-leads-privatelenders', (): ListType => 'private-lenders'),
      Match.when('drive', (): ListType => 'drive'),
      Match.exhaustive,
    ),
    response.source?.payload || null,
  ),
})
