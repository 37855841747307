import { Option } from 'effect'
import { isNullable } from 'effect/Predicate'
import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { EquityType } from 'features/valueObjects/EquityType'
import { Location } from 'features/valueObjects/Location'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import Range from 'features/valueObjects/Range'

type Buyer = Buyer.Buyer

namespace Buyer {
  export type Type = 'neighbor' | 'landlord' | 'flipper'

  /** @TODO GROUP ENTITY AND INFO */
  export type Buyer = {
    id: string
    name: Option.Option<string>
    address: Option.Option<Address>
    buyerType: Option.Option<Type[]>
    buyerHistory: BuyerHistory
  }

  export type BuyerHistory = {
    deals: Deal[]
    dealsGeographicInfo: DealsGeographicInfo
    averageDealAmount: Option.Option<number>
    totalDealAmount: Option.Option<number>
    totalDealsCount: Option.Option<number>
    lastDeal: Option.Option<DealWithoutProperty>
    dealAmountRange: Option.Option<Range>
    propertiesCount: Option.Option<number>
  }

  export type Deal = {
    id: string
    property: Property
    purchaseAmount: Option.Option<number>
    purchaseDate: Option.Option<Date>
    soldAmount: Option.Option<number>
    soldDate: Option.Option<Date>
    rentAmount: Option.Option<number>
    rentDate: Option.Option<Date>
  }

  export type DealWithoutProperty = Omit<Deal, 'property'>

  export const getLatestDealDate = (deal: Deal): Option.Option<Date> => {
    if (Option.isSome(deal.soldDate) && Option.isSome(deal.rentDate)) {
      return Option.some(
        deal.soldDate.value > deal.rentDate.value
          ? deal.soldDate.value
          : deal.rentDate.value,
      )
    }

    if (Option.isSome(deal.soldDate))
      return Option.some(deal.soldDate.value)

    if (Option.isSome(deal.rentDate))
      return Option.some(deal.rentDate.value)

    return Option.none()
  }

  export type DealsGeographicInfo = {
    states: string[]
    counties: CountyInfo[]
    countiesByState: CountyByState
  }

  export type CountyInfo = { state: string, county: string }
  export type CountyByState = Record<string, string[]>

  // #region Property

  export type Property = {
    id: string
    parcelId: Option.Option<string>
    address: AddressWithCounty
    location: Location
    ownershipInfo: OwnershipInfo
    physicalInfo: PhysicalInfo
    valueInfo: ValueInfo
  }

  export namespace Property {
    export const fromPropertyDetails = (
      input: PropertyDetails.Property,
      options: {
        fallback: {
          location: Location
        }
      },
    ): Property => {
      const detailedProperty = input.status === 'with-details'
        ? Option.some(input)
        : Option.none()

      return {
        id: input.id,
        parcelId: detailedProperty.pipe(
          Option.map(details => details.parcelId),
        ),
        address: {
          ...input.address,
          county: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.land?.county)),
          ),
        },
        location: input.location ?? options.fallback.location,
        ownershipInfo: {
          ownedYears: detailedProperty.pipe(
            Option.flatMap(details => {
              const months = details.ownership?.months
              if (isNullable(months)) return Option.none()
              return Option.some(Math.floor(months / 12))
            }),
          ),
          classification: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.ownership?.classification)),
          ),
          isVacant: detailedProperty.pipe(
            Option.map(details => details.ownership?.isVacant ?? false),
            Option.getOrElse(() => false),
          ),
          isSenior: detailedProperty.pipe(
            Option.map(details => details.ownership?.isSenior ?? false),
            Option.getOrElse(() => false),
          ),
        },
        physicalInfo: {
          bedroomsCount: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.building?.bedroomsCount)),
          ),
          bathroomsCountInfo: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.building?.bathroomsCount)),
          ),
          buildingAreaSqft: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.building?.buildingAreaSqft)),
          ),
        },
        valueInfo: {
          equityType: detailedProperty.pipe(
            Option.flatMap(details => Option.fromNullable(details.valuation?.equityType)),
          ),
          isForeclosure: detailedProperty.pipe(
            Option.map(details => details.ownership?.isForeclosure ?? false),
            Option.getOrElse(() => false),
          ),
        },
      }
    }
  }

  export type AddressWithCounty = Address & { county: Option.Option<string> }

  export type OwnershipInfo = {
    ownedYears: Option.Option<number>
    classification: Option.Option<OwnerClassification>
    isVacant: boolean
    isSenior: boolean
  }

  export type PhysicalInfo = {
    bedroomsCount: Option.Option<number>
    bathroomsCountInfo: Option.Option<BathroomsCountInfo>
    buildingAreaSqft: Option.Option<number>
  }

  export type ValueInfo = {
    equityType: Option.Option<EquityType>
    isForeclosure: boolean
  }

  export type MLSInfo = {
    status: MLSStatus
    physicalInfo: PhysicalInfo
  }

  // #endregion Property
}

export default Buyer
