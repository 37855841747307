import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { DomainError, ErrorLib, FieldsError, ProgramException, ReportableException } from 'libs/errors'
import { WithId, WithoutId } from 'libs/id'
import { useCallback } from 'react'

const MemberScreen_UpdateMemberEmailCommand = gql(/* GraphQL */ `
  mutation MemberScreen_UpdateMemberEmailCommand($id: ID!, $email: String!) {
    userUpdateEmailAddress(id: $id, email: $email) {
      __typename

      ... on User {
        id

        enterprise {
          ...EnterpriseMembers
        }
      }
      ... on UserInvalidInputError {
        message
        field
      }
      ... on UserEmailTakenError {
        field
        message
      }
    }
  }
`)

type __UpdateMemberEmailPayload = WithId<{ email: string }>
type __UpdateMemberEmailError = FieldsError<WithoutId<__UpdateMemberEmailPayload>>
type __UpdateMemberEmailCommand = Command.PromiseVersion<__UpdateMemberEmailError, __UpdateMemberEmailPayload>

/**
 * @NOTE Error management is weird because use case is weird -- we're looking to
 *   facade multiple commands including this under a single command
 */
export const useUpdateMemberEmailCommand = (): __UpdateMemberEmailCommand => {
  const [updateMemberEmail] = useMutation(MemberScreen_UpdateMemberEmailCommand)
  const { transition } = CQReact.useStateManager<__UpdateMemberEmailError, __UpdateMemberEmailPayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<__UpdateMemberEmailCommand['execute']>(async payload => {
    const commandId = regenerateId()
    return await updateMemberEmail({ variables: payload })
      .then(result => {
        const { data } = result

        if (!data) {
          throw new ReportableException('Unexpected response', {
            extraInfo: { result },
          })
        }

        if (
          data.userUpdateEmailAddress.__typename === 'UserInvalidInputError'
          || data.userUpdateEmailAddress.__typename === 'UserEmailTakenError'
        ) {
          const serverErr = data.userUpdateEmailAddress

          throw new FieldsError<WithoutId<__UpdateMemberEmailPayload>>(
            '',
            {
              email: new DomainError(serverErr.message),
            },
          )
        }

        return transition.success({ id: commandId, payload })
      })
      .catch(rawErr => {
        void ErrorLib.report(rawErr)

        const error = rawErr instanceof FieldsError
          ? rawErr
          : new FieldsError<WithoutId<__UpdateMemberEmailPayload>>(
            '',
            {
              email: new ProgramException('An error occurred while updating the member email'),
            },
          )

        return transition.error({ id: commandId, error, payload })
      })
  }, [])

  return {
    execute,
  }
}
