import { Effect, Option } from 'effect'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'
import GetOwnerDealsActor from 'features/OwnersProperties/view/machine/GetOwnerDealsActor'
import OwnersPropertiesRoutes from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes'
import { EffectLib } from 'libs/Effect'
import { ErrorLib } from 'libs/errors'
import { MapLib } from 'presentation/components/Map/createMapStore'
import { toast } from 'presentation/components/Toast'
import { ToastParams } from 'presentation/components/Toast/Toast.types'
import { DEFAULT_TOAST_ERROR_MESSAGE } from 'presentation/const/toast.const'
import { MapRef } from 'react-map-gl'
import AppCache from 'services/AppCache'
import AppRouter from 'services/AppRouter'
import { ActorLogicFrom, ActorRefFrom, SnapshotFrom, assign, setup } from 'xstate'

namespace OwnerRelationMachine {
  export type Snapshot = SnapshotFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type ActorRef = ActorRefFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type ActorLogic = ActorLogicFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type Requirements = OwnersPropertiesRepo | AppCache

  export type Input = {
    leadId: string
    owner: OwnersProperties.Owner
  }

  export type Context = {
    leadId: string
    owner: OwnersProperties.Owner
    dealRelations: Option.Option<OwnersProperties.DealRelation[]>
    mapRef: Option.Option<MapRef>
    displayed: {
      filters: {
        shouldLimitToCurrentlyOwned: boolean
      }
      dealRelations: Option.Option<OwnersProperties.DealRelation[]>
    }
  }

  export type Event =
    | { type: 'expand-owner-row' }
    | { type: 'collapse-owner-row' }
    | { type: 'map-mounted', mapRef: MapRef }
    | { type: 'map-unmounted' }
    | { type: 'go-back-to-owners-properties' }
    | { type: 'get-deals' }
    | { type: 'toggle-limit-to-currently-owned' }

  export const make = () => Effect.gen(function * () {
    const runSync = yield * EffectLib.getRunSyncFromRuntime<AppRouter>()

    return setup({
      types: {
        input: {} as Input,
        context: {} as Context,
        events: {} as Event,
      },
      actors: {
        getOwnerDeals: yield * GetOwnerDealsActor.make(),
      },
      actions: {
        notify: (_, params: ToastParams) => {
          toast(params)
        },
        fitMarkersInMap: ({ context }) => {
          const mapRef = context.mapRef

          if (Option.isNone(mapRef) || Option.isNone(context.displayed.dealRelations)) return

          const coordinates = context.displayed.dealRelations.value.map(relation =>
            relation.property.location)

          MapLib.fitMarkers(mapRef.value, {
            markers: coordinates,
            padding: MAP_PADDING,
          })
        },
        goBackToOwnersProperties: ({ context }) =>
          Effect.gen(function * () {
            const service = yield * AppRouter
            service.router.navigate(
              OwnersPropertiesRoutes.makeOwnersPropertiesPath(context.leadId),
              { replace: true })
          }).pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          ),
        subscribeToSelf: ({ self }) => {
          const subscription = self.subscribe({
            error: error => {
              ErrorLib.report(error)
            },
            complete: () => {
              subscription.unsubscribe()
            },
          })
        },
      },
      guards: {
        hasDeals: ({ context }) => context.dealRelations.pipe(Option.isSome),
      },
    }).createMachine({
      id: 'ownerRelationsMachine',
      context: ({ input }) => ({
        leadId: input.leadId,
        owner: input.owner,
        dealRelations: Option.none(),
        mapRef: Option.none(),
        displayed: {
          filters: {
            shouldLimitToCurrentlyOwned: true,
          },
          dealRelations: Option.none(),
        },
      }),
      entry: 'subscribeToSelf',
      type: 'parallel',
      states: {
        GetDeals: {
          id: 'GetDeals',
          initial: 'Idle',
          states: {
            Idle: {
              on: {
                'expand-owner-row': 'Loading',
                'get-deals': 'Loading',
              },
            },
            Loading: {
              invoke: {
                id: 'getOwnerDeals',
                src: 'getOwnerDeals',
                input: ({ context }) => ({ parcelContactId: context.owner.id }),
                onDone: {
                  target: 'Success',
                  actions: [
                    assign({
                      dealRelations: ({ event }) => Option.some(event.output.dealRelations),
                      displayed: ({ event, context }) => ({
                        ...context.displayed,
                        dealRelations: Option.some(
                          event.output.dealRelations.filter(
                            relation =>
                              !context.displayed.filters.shouldLimitToCurrentlyOwned
                              || relation.isCurrentlyOwned,
                          ),
                        ),
                      }),
                    }),
                  ],
                },
                onError: {
                  target: 'Error',
                  actions: [
                    {
                      type: 'notify',
                      params: {
                        status: 'error',
                        title: 'Failed to load the properties',
                        message: DEFAULT_TOAST_ERROR_MESSAGE,
                      },
                    },
                  ],
                },
              },
            },
            Success: {},
            Error: {
              on: {
                'expand-owner-row': 'Loading',
                'get-deals': 'Loading',
              },
            },
          },
        },
        OwnerRow: {
          initial: 'Collapsed',
          states: {
            Collapsed: {
              on: {
                'expand-owner-row': 'Expanded',
              },
            },
            Expanded: {
              on: {
                'collapse-owner-row': 'Collapsed',
              },
            },
          },
        },
      },
      on: {
        'map-mounted': {
          actions: [
            assign({
              mapRef: ({ event }) => Option.some(event.mapRef),
            }),
            'fitMarkersInMap',
          ],
        },
        'map-unmounted': {
          actions: assign({
            mapRef: Option.none(),
          }),
        },
        'go-back-to-owners-properties': {
          actions: 'goBackToOwnersProperties',
        },
        'toggle-limit-to-currently-owned': {
          actions: [
            assign({
              displayed: ({ context }) => {
                const shouldLimitToCurrentlyOwned = !context.displayed.filters.shouldLimitToCurrentlyOwned

                return {
                  ...context.displayed,
                  filters: {
                    shouldLimitToCurrentlyOwned,
                  },
                  dealRelations: context.dealRelations.pipe(Option.map(dealRelations =>
                    dealRelations.filter(
                      relation => !shouldLimitToCurrentlyOwned || relation.isCurrentlyOwned,
                    ),
                  )),
                }
              },
            }),
            'fitMarkersInMap',
          ],
        },
      },
    })
  })

  const MAP_PADDING = {
    top: 48,
    left: 32,
    right: 32,
    bottom: 24,
  }
}

export default OwnerRelationMachine
