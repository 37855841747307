import { Box, Text, VStack } from '@chakra-ui/react'
import { Option } from 'effect'
import { NearbyBuyersSoldWithinPresets as Presets } from 'features/NearbyBuyers/views/NearbyBuyersFilterPanel/SoldWithinFilterCard/SoldWithinFilterCard'
import { FilterPopover, FilterPopoverButton, FilterPopoverContent } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterPopover'
import { mbp } from 'presentation/utils/mapBreakpoint'

export type SoldWithinFilterProps = {
  value: Option.Option<number>
  onChange: (range: Option.Option<number>) => void
}

export const SoldWithinFilter = (props: SoldWithinFilterProps) => {
  // const selectedLabel = SoldWithinRange.toLabel(props.value)

  const onChange = (value: Option.Option<number>) => {
    props.onChange(value)
  }

  const selectedLabel = Presets.toLabel(props.value)

  return (
    <FilterPopover>
      <FilterPopoverButton
        label='Active Within'
        minW={mbp({ mobSm: '130px', dtLg: '150px' })}
        title='Active Within'
        size={mbp({ mobSm: 'md', dtSm: 'lg' })}
      >
        {selectedLabel}
      </FilterPopoverButton>
      <FilterPopoverContent>
        <Text
          textStyle='bodyLFat'
          color='grayweak.900'
          h='5'
          lineHeight='5'
        >
          Active Within
        </Text>

        <VStack
          mt={1}
          spacing={0.5}
          align='stretch'
        >
          {Presets.PRESETS.map(({ label, value }) => (
            <Box
              key={label}
              as='button'
              ml={-2}
              pl={2}
              textStyle='bodyMFat'
              borderRightRadius='full'
              color='graystrong.400'
              height='40px'
              textAlign='left'
              {...(selectedLabel === label && {
                bg: 'neutral.500',
                color: 'ondark.1',
              })}
              onClick={() => {
                onChange(value)
              }}
            >
              {label}
            </Box>
          ))}
        </VStack>
        {/**
        <MenuOptionGroup
          title='Active Within'
          value={selectedLabel}
          onChange={rawLabelValue => {
            const labelValue = Array.isArray(rawLabelValue) ? rawLabelValue[0] : rawLabelValue
            const rangeValue = SoldWithinRange.fromLabel(labelValue)
            props.onChange(rangeValue)
          }}
        >
          {SoldWithinRange.PRESETS.map(({ label }) => (
            <MenuItemOption
              key={label}
              type='checkbox'
              value={label}
            >
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        */}
      </FilterPopoverContent>
    </FilterPopover>
  )
}
