import { Box, HStack, Spinner, SystemStyleObject, useToken } from '@chakra-ui/react'
import { Array, Match, Option, pipe } from 'effect'
import { PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { BuildListState, BuildTotalState, useBuildTotalCount, useListPreview } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Location } from 'features/valueObjects/Location'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Map, MapStoreProvider, useMapStore } from 'presentation/components/Map'
import { MapStatusCard } from 'presentation/components/Map/MapStatusCard'
import { useClickOnMapCanvas } from 'presentation/components/Map/useClosePopupOnMapClick'
import { useCommonMarkerBehaviorsEffect } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { MapModeToggle } from 'presentation/components/MapModeToggle'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { getCardBorderColor } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard'
import ListPreviewMapAndListSync from 'presentation/screens/ListPreviewScreen/components/ListPreviewMapAndListSync'
import { sizeToPx } from 'presentation/utils/chakraSizeToNumber'
import { px } from 'presentation/utils/px'
import { useCallback } from 'react'
import { FullscreenControl, Marker, NavigationControl } from 'react-map-gl'
import ListPreviewMapMarkerPopup from './ListPreviewMapMarkerPopup'

type ListPreviewMapProps = {
  shouldHideStatusCard?: boolean
}

export const ListPreviewMap = (props: ListPreviewMapProps) => (
  <MapStoreProvider>
    <ListPreviewMapBase {...props} />
  </MapStoreProvider>
)

const ListPreviewMapBase = ({ shouldHideStatusCard }: ListPreviewMapProps) => {
  const currentMap = useMapStore(mapStore => mapStore.computed.getMapRef())
  const totalCount = useBuildTotalCount()
  const listPreview = useListPreview()
  const highlightedEntry = ListPreviewMapAndListSync.useStore(store => store.highlightedMapMarker)
  const itemsWithLocation = pipe(
    Match.value(listPreview),
    Match.tag('Success', value =>
      value.preview.items.filter(Location.hasLocation)),
    Match.orElse(() => []),
  )

  /**
   * ====================
   * Map Behaviors
   * ====================
   */
  useCommonMarkerBehaviorsEffect({
    highlightedEntry,
    markers: itemsWithLocation,
  })

  useClickOnMapCanvas({
    mapRef: currentMap,
    onMapCanvasClick: () => {
      ListPreviewMapAndListSync.setHighlightedEntry(null)
    },
  })

  /**
   * ====================
   * Event Handlers
   * ====================
   */
  const handleMarkerClick = useCallback((id: PropertyListDataId) => {
    ListPreviewMapAndListSync.setHighlightedEntry(id)
  }, [])

  const handleOnMarkerHover = useCallback((id: PropertyListDataId) => {
    ListPreviewMapAndListSync.handleMapMarkerHover(id)
  }, [])

  const handleOnMarkerHoverOut = useCallback(() => {
    ListPreviewMapAndListSync.handleMapMarkerUnhover()
  }, [])

  const highlightedEntryData: Option.Option<PropertyListData> = pipe(
    itemsWithLocation,
    Array.fromIterable,
    Array.findFirst(v => v.id === highlightedEntry?.id),
  )

  const popoverOverrides = useMapPopoverOverrides(highlightedEntryData)
  return (
    <Box sx={{
      width: 'full',
      height: 'full',
      position: 'relative',
      ...popoverOverrides,
    }}
    >
      <Map>
        <FullscreenControl />
        <NavigationControl />

        <Breakpoint
          mobSm={null}
          tabSm={(
            <>
              <MapModeToggle
                pos='absolute'
                bottom={3}
                left={3}
              />

              {!shouldHideStatusCard && (
                <MapStatusCard
                  pos='absolute'
                  bottom={3}
                  right={3}
                  width={224}
                >
                  {pipe(
                    Match.value({ totalCount, listPreview }),
                    Match.when({
                      totalCount: BuildTotalState.$is('Success'),
                      listPreview: BuildListState.$is('Success'),
                    }, value =>
                      `${
                        value.listPreview.preview.items.length
                      } of ${
                        value.totalCount.total
                      } ${
                        pluralize('result', value.totalCount.total)
                      }`,
                    ),
                    Match.orElse(() => (
                      <HStack align='center' gap={0.5}>
                        <Box>Loading</Box>
                        {' '}
                        <Spinner size='xs' />
                      </HStack>
                    )),
                  )}
                </MapStatusCard>
              )}
            </>
          )}
        />

        {highlightedEntryData.pipe(
          Option.map(data => (
          // eslint-disable-next-line react/jsx-key
            <ListPreviewMapMarkerPopup item={data} />
          )),
          Option.getOrNull,
        )}

        {itemsWithLocation
          .map(item => (
            <Box
              key={item.id}
              onClick={() => {
                handleMarkerClick(item.id)
              }}
              onMouseEnter={() => {
                handleOnMarkerHover(item.id)
              }}
              onMouseLeave={() => {
                handleOnMarkerHoverOut()
              }}
            >
              <Marker
                latitude={item.location.latitude}
                longitude={item.location.longitude}
              >
                <PropertyMarker
                  markerType='pin'
                  classification={item.classification}
                  equityType={item.equityType}
                  isVacant={!!item.isVacant}
                  isForeclosure={!!item.isForeclosure}
                  isSenior={!!item.isSenior}
                  size='sm'
                />
              </Marker>
            </Box>
          ))}
      </Map>
    </Box>
  )
}

export const useMapPopoverOverrides = (data: Option.Option<PropertyListData>): SystemStyleObject => {
  const { sbp } = useSwitchBreakpointFn()

  const contentBorderWidth = sbp({ mobSm: 0.25, tabSm: 0.5 }) ?? 0
  const tipForegroundColor = 'card.bg.1'
  const contentBorderColor = useToken('colors',
    data.pipe(
      Option.map(v => getCardBorderColor(v.mlsStatus)),
      Option.getOrElse(() => 'transparent'),
    ),
  )

  const tipForegroundWidth = `calc(${px(sizeToPx(2) ?? 0)})`
  const tipBackgroundWidth = `calc(${px(sizeToPx(2) ?? 0)} + ${px(sizeToPx(contentBorderWidth) ?? 0)})`
  const tipAdditionalOffset = sbp({ mobSm: 0.125, tabSm: 0.25 }) ?? 0
  const shouldShowForeground = sbp({ mobSm: false, tabSm: true })

  return {
    '& .mapboxgl-popup-content': {
      padding: 0,
      borderWidth: contentBorderWidth,
      borderColor: contentBorderColor,
      backgroundColor: 'transparent',
      borderRadius: 3,
      overflow: 'hidden',
    },
    [`& .mapboxgl-popup-anchor-top,
      & .mapboxgl-popup-anchor-left,
      & .mapboxgl-popup-anchor-right,
      & .mapboxgl-popup-anchor-top-right,
      & .mapboxgl-popup-anchor-top-left,
      & .mapboxgl-popup-anchor-bottom,
      & .mapboxgl-popup-anchor-bottom-right,
      & .mapboxgl-popup-anchor-bottom-left
    `]: {
      '.mapboxgl-popup-tip::before': {
        borderWidth: tipBackgroundWidth,
        position: 'absolute',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        content: '""',
        zIndex: 0,
        width: 0,
        height: 0,
      },
      '.mapboxgl-popup-tip::after': {
        borderWidth: tipForegroundWidth,
        position: 'absolute',
        content: '""',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        zIndex: 1,
        width: 0,
        height: 0,
      },
      '.mapboxgl-popup-tip': {
        position: 'relative',
        borderColor: 'transparent',
        borderWidth: tipBackgroundWidth,
      },
    },
    [`& .mapboxgl-popup-anchor-top`]: {
      '.mapboxgl-popup-tip::before': {
        borderBottomColor: contentBorderColor,
        bottom: 0,
        left: 0,
        transform: 'translate(-50%, 50%)',
      },
      '.mapboxgl-popup-tip::after': {
        borderBottomColor: shouldShowForeground ? tipForegroundColor : 'transparent',
        bottom: -contentBorderWidth,
        left: 0,
        transform: 'translate(-50%, 50%)',
      },
      '.mapboxgl-popup-tip': {
        bottom: -contentBorderWidth,
      },
    },
    [`& .mapboxgl-popup-anchor-top-left`]: {
      '.mapboxgl-popup-tip::before': {
        borderLeftColor: contentBorderColor,
        top: 0,
        left: 0,
      },
      '.mapboxgl-popup-tip::after': {
        borderLeftColor: shouldShowForeground ? tipForegroundColor : 'transparent',
        top: `calc((${px(sizeToPx(contentBorderWidth) ?? 0)} * 2) + ${px(sizeToPx(tipAdditionalOffset) ?? 0)})`,
        left: contentBorderWidth,
      },
      '.mapboxgl-popup-tip': {
        zIndex: sbp({ mobSm: -1, tabSm: 1 }),
      },
    },
    [`& .mapboxgl-popup-anchor-top-right`]: {
      '.mapboxgl-popup-tip::before': {
        borderRightColor: contentBorderColor,
        right: 0,
      },
      '.mapboxgl-popup-tip::after': {
        borderRightColor: shouldShowForeground ? tipForegroundColor : 'transparent',
        top: `calc((${px(sizeToPx(contentBorderWidth) ?? 0)} * 2) + ${px(sizeToPx(tipAdditionalOffset) ?? 0)})`,
        right: contentBorderWidth,
      },
      '.mapboxgl-popup-tip': {
        zIndex: sbp({ mobSm: -1, tabSm: 1 }),
      },
    },
    [`& .mapboxgl-popup-anchor-bottom`]: {
      '.mapboxgl-popup-tip::before': {
        borderTopColor: contentBorderColor,
        top: 0,
        left: 0,
        transform: 'translate(-50%, -50%)',
      },
      '.mapboxgl-popup-tip::after': {
        borderTopColor: tipForegroundColor,
        top: -contentBorderWidth,
        left: 0,
        transform: 'translate(-50%, -50%)',
      },
      '.mapboxgl-popup-tip': {
        top: -contentBorderWidth,
      },
    },
    [`& .mapboxgl-popup-anchor-bottom-right`]: {
      '.mapboxgl-popup-tip::before': {
        borderRightColor: contentBorderColor,
        bottom: 0,
        right: 0,
      },
      '.mapboxgl-popup-tip::after': {
        borderRightColor: tipForegroundColor,
        bottom: `calc((${px(sizeToPx(contentBorderWidth) ?? 0)} * 2) + ${px(sizeToPx(tipAdditionalOffset) ?? 0)})`,
        right: contentBorderWidth,
      },
    },
    [`& .mapboxgl-popup-anchor-bottom-left`]: {
      '.mapboxgl-popup-tip::before': {
        borderLeftColor: contentBorderColor,
        bottom: 0,
        left: 0,
      },
      '.mapboxgl-popup-tip::after': {
        borderLeftColor: tipForegroundColor,
        bottom: `calc((${px(sizeToPx(contentBorderWidth) ?? 0)} * 2) + ${px(sizeToPx(tipAdditionalOffset) ?? 0)})`,
        left: contentBorderWidth,
      },
    },
    '& .mapboxgl-popup-anchor-left': {
      '.mapboxgl-popup-tip::before': {
        borderRightColor: contentBorderColor,
        right: 0,
        top: 0,
        transform: 'translate(50%, -50%)',
      },
      '.mapboxgl-popup-tip::after': {
        borderRightColor: tipForegroundColor,
        right: -contentBorderWidth,
        top: 0,
        transform: 'translate(50%, -50%)',
      },
      '.mapboxgl-popup-tip': {
        right: -contentBorderWidth,
      },
    },
    '& .mapboxgl-popup-anchor-right': {
      '.mapboxgl-popup-tip::before': {
        borderLeftColor: contentBorderColor,
        right: 0,
        top: 0,
        transform: 'translate(50%, -50%)',
      },
      '.mapboxgl-popup-tip::after': {
        borderLeftColor: tipForegroundColor,
        right: contentBorderWidth,
        top: 0,
        transform: 'translate(50%, -50%)',
      },
      '.mapboxgl-popup-tip': {
        right: contentBorderWidth,
      },
    },
    '& .mapboxgl-popup-anchor-top-right .mapboxgl-popup-content': {
      borderTopRightRadius: 0,
    },
    '& .mapboxgl-popup-anchor-top-left .mapboxgl-popup-content': {
      borderTopLeftRadius: 0,
    },
    '& .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-content': {
      borderBottomRightRadius: 0,
    },
    '& .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-content': {
      borderBottomLeftRadius: 0,
    },
    '& .mapboxgl-popup-close-button': {
      display: 'none',
    },
  }
}
