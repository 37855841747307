import { Box, BoxProps } from '@chakra-ui/react'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { px } from 'presentation/utils/px'

export const TabContainer = ({
  children,
  ...boxProps
}: BoxProps) => {
  const { sbp } = useSwitchBreakpointFn()

  return (
    <Box
      overflow='hidden'
      flex='1'
      minH={px(sbp(TabContainer.RESPONSIVE_HEIGHT) || 0)}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

TabContainer.RESPONSIVE_HEIGHT = {
  mobSm: 172,
  mob: 220,
  tabSm: 226,
  dtSm: 255,
  dtLg: 300,
}
