import { Text, TextProps, VStack } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'

export const AgentInfoText = ({ comp, ...props }: TextProps & {
  comp: CMA.SingleComp | CMA.SubjectComp
}) => {
  const infosText = [
    comp.agent.name,
    comp.broker.phone,
    comp.broker.name,
    comp.broker.email,
  ]
    .filter(Boolean)
    .join(' | ')

  const isClosed = comp.status === 'SOLD' || comp.status === 'LEASED'
  const hasBuyerInfo = isClosed && comp.buyerAgent && (
    comp.buyerAgent.name
    || comp.buyerBroker?.name
    || comp.buyerBroker?.phone
    || comp.buyerAgent.phone
    || comp.buyerBroker?.email
  )

  const buyerInfosText = hasBuyerInfo
    ? [
      comp.buyerAgent?.name,
      comp.buyerBroker?.phone,
      comp.buyerBroker?.name,
      comp.buyerBroker?.email,
    ]
      .filter(Boolean)
      .join(' | ')
    : null

  if (!infosText && !buyerInfosText) return null

  if (!buyerInfosText) {
    return (
      <Text
        textStyle='bodySFat'
        fontWeight='normal'
        color='ondark.2'
        {...props}
      >
        Seller&apos;s Agent:
        {' '}
        {infosText}
      </Text>
    )
  }

  return (
    <VStack spacing={0.5} align='stretch' {...props}>
      <Text
        textStyle='bodySFat'
        fontWeight='normal'
        color='ondark.2'
        {...props}
        marginBottom='0'
      >
        Seller&apos;s Agent:
        {' '}
        {infosText}
      </Text>
      <Text
        textStyle='bodySFat'
        fontWeight='normal'
        color='ondark.2'
        {...props}
      >
        Buyer&apos;s Agent:
        {' '}
        {buyerInfosText}
      </Text>
    </VStack>
  )
}
