import { Center, Text } from '@chakra-ui/react'
import { ErrorLib } from 'libs/errors'
import { IS_DEV_ENV, IS_STORYBOOK } from 'presentation/const/env.const'
import React, { FC } from 'react'

type Props = {
  children: React.ReactNode
  fallback?: React.ReactNode
}

type State = {
  hasError: boolean
}

export const GenericFallback: FC = () => (
  <Center flex='1' flexDir='column' p='3'>
    <Text textStyle='body' color='v2.grayStrong.500' textAlign='center'>
      Oops! We couldn’t load this section
    </Text>
    <Text mt='0.5' textStyle='body2' color='v2.grayStrong.200' textAlign='center'>
      Please try again or let us know via live support.
    </Text>
  </Center>
)

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    if (IS_DEV_ENV || IS_STORYBOOK)
      // eslint-disable-next-line no-console
      console.log(error)
    else
      void ErrorLib.report(error)

    return { hasError: true }
  }

  static defaultProps = {
    fallback: <GenericFallback />,
  }

  render(): React.ReactNode {
    const { children, fallback } = this.props

    if (this.state.hasError)
      return fallback

    return children
  }
}
