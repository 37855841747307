import { Effect, Layer, pipe } from 'effect'
import { LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import LocationService from 'features/ListBuilder/domain/LocationService'
import { GETSearchCities } from 'features/ListBuilder/infra/services/LocationServiceLive/GETSearchCities/GETSearchCities'
import { GETSearchCounties } from 'features/ListBuilder/infra/services/LocationServiceLive/GETSearchCounties/GETSearchCounties'
import { EffectLib } from 'libs/Effect'
import React, { useMemo } from 'react'
import { debounce } from 'throttle-debounce'

const searchCities: LocationService.SearchCities = keyword => pipe(
  GETSearchCities.request(keyword),
  Effect.map(raw => GETSearchCities.decode(raw)),
  Effect.map(decoded => GETSearchCities.toDomain(decoded)),
)

const searchCounties: LocationService.SearchCounties = keyword => pipe(
  GETSearchCounties.request(keyword),
  Effect.map(raw => GETSearchCounties.decode(raw)),
  Effect.map(decoded => GETSearchCounties.toDomain(decoded)),
)

const searchZip: LocationService.SearchZip = keyword => {
  const isFiveDigitNumber = /^\d{5}$/.test(keyword.trim())

  if (isFiveDigitNumber)
    return Effect.succeed([LocationCriteria.Zip(keyword)])
  else
    return Effect.succeed([])
}

const LocationServiceLive = Layer.succeed(LocationService, {
  searchCities,
  searchCounties,
  searchZip,
})

export default LocationServiceLive

const makeUseSearchLocation = <T extends LocationCriteria>(search: LocationService.SearchLocation<T>) =>
  () => {
    const [results, setResults] = React.useState<T[]>([])
    const [keyword, setKeyword] = React.useState('')
    const [error, setError] = React.useState<Error | null>(null)
    const keywordRef = React.useRef(keyword)
    keywordRef.current = keyword
    const [status, setStatus] = React.useState<'idle' | 'loading'>('idle')

    const searchDebounced = useMemo(() =>
      debounce(500, (searchKeyword: string) => {
        if (searchKeyword !== keywordRef.current)
          return

        if (searchKeyword === '') {
          setResults([])
          setStatus('idle')
          return
        }

        void pipe(
          search(searchKeyword),
          Effect.tap((results: T[]) => {
            if (searchKeyword !== keywordRef.current) return
            setResults(results)
            setStatus('idle')
          }),
          Effect.tapErrorCause(cause => Effect.sync(() => {
            setError(EffectLib.causeToError(cause))
          })),
          Effect.runPromise,
        )
      }),
    [])

    const onKeywordChange = (keyword: string) => {
      setKeyword(keyword)
      setStatus('loading')
      setError(null)
      searchDebounced(keyword)
    }

    return {
      status,
      results,
      keyword,
      error,
      onKeywordChange,
    }
  }

export type UseSearchLocation = ReturnType<typeof makeUseSearchLocation>

export const useSearchCities = makeUseSearchLocation(searchCities)
export const useSearchCounties = makeUseSearchLocation(searchCounties)
export const useSearchZip = makeUseSearchLocation(searchZip)
