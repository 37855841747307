import { Box, Spinner } from '@chakra-ui/react'
import { SearchHistoryProvider, useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { ComposeComponents } from 'presentation/components/ComposeComponents/ComposeComponents'
import { MapStoreProvider } from 'presentation/components/Map'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { MLSStatusTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingModal'
import { PropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal'
import { usePropertyMarkerTrainingNotice } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingNotice/usePropertyMarkerTrainingNotice'
import { useSearchHistoryEvents } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.events'
import { SearchHistoryPanelsProvider } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen.panels'
import { ReturningUser } from 'presentation/screens/SearchHistoryScreen/components/ReturningUser'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

export const SearchHistoryScreenDumb = () => {
  usePropertyMarkerTrainingNotice()
  const store = useLayoutStore()
  const initialize = useSearchHistoryStore(store => store.actions.initialize)
  const clearHistory = useSearchHistoryStore(store => store.actions.clearHistory)
  const status = useSearchHistoryStore(store => {
    const historyStatus = store.remote.history.status
    const seekerStatus = store.remote.seekers.status

    if (historyStatus === 'loading' || seekerStatus === 'loading')
      return 'loading' as const
    else if (historyStatus === 'error' || seekerStatus === 'error')
      return 'error' as const
    else
      return 'success' as const
  })

  useEffect(() => {
    void initialize()

    // Clear history because we want this screen to refetch when it goes back,
    // in case some history items were deleted from elsewhere
    return () => clearHistory()
  }, [])

  useEffect(() => {
    /**
     * Make sure we don't have extra height for the tab links when
     * navigating from the property details or comps screen
     */
    store.setHeaderElementHeight('propertyLayoutTabLinks', 0)
  }, [])

  const layout = useLayoutStore(store => ({
    setTitle: store.setTitle,
    setHeaderShadow: store.headerShadow.set,
    bodyHeight: store.totalBodyHeight,
  }), shallow)

  useEffect(() => {
    layout.setTitle('Property Data')

    if (status === 'success')
      layout.setHeaderShadow(true)

    return () => {
      layout.setHeaderShadow(false)
    }
  }, [status])

  useSearchHistoryEvents()

  return (
    <ComposeComponents
      providers={[
        MapStoreProvider,
        SearchHistoryPanelsProvider,
      ]}
    >
      <Box h={layout.bodyHeight}>
        {(status !== 'success')
          ? (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              h='100%'
              minH={0}
            >
              <Spinner />
            </Box>
          )
          : <ReturningUser />}
      </Box>
    </ComposeComponents>
  )
}

export const SearchHistoryScreen = () => (
  <SearchHistoryProvider>
    <SearchHistoryScreenDumb />
    <PropertyMarkerTrainingModal />
    <MLSStatusTrainingModal />
  </SearchHistoryProvider>
)
