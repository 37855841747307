import { createIcon } from '@chakra-ui/react'

export const ChevronsLeftIcon = createIcon({
  displayName: 'ChevronsLeftIcon',
  viewBox: '0 0 16 16',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.33333 11.3333L4 7.99996L7.33333 4.66663' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 11.3333L8.66669 7.99996L12 4.66663' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
})
