import { Button, ButtonProps, CardProps, Flex, FlexProps, Icon, IconProps, Text, TextProps } from '@chakra-ui/react'
import { CardSegment } from 'presentation/components/molecules/Card/CardCombo'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { ReactNode } from 'react'

export const Heading = ({ children, ...props }: TextProps) => (
  <Text
    textStyle='h4'
    color='neutral.500'
    {...props}
  >
    {children}
  </Text>
)

export const IconLabel = ({ children, ...props }: TextProps) => (
  <Text
    textStyle={mbp({ mobSm: 'bodyLFat', mob: 'bodyXLFat', tabSm: 'h4' })}
    color='graystrong.600'
    {...props}
  >
    {children}
  </Text>
)

export const IconDescription = ({ children, ...props }: TextProps) => (
  <Text
    textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })}
    color='graystrong.600'
    {...props}
  >
    {children}
  </Text>
)

export type MarkerLabelDescriptionPairsProps = {
  marker: ReactNode
  label?: ReactNode
  description: ReactNode
}

export const MarkerLabelDescriptionPairs = (props: MarkerLabelDescriptionPairsProps & FlexProps) => {
  const { marker, label, description, ...flexProps } = props
  return (
    <Flex
      gap={2}
      {...mbpg({
        mobSm: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        mob: {
          flexDirection: 'column',
        },
      })}
      {...flexProps}
    >
      {marker}
      <Flex flexDirection='column' gap={0.5}>
        {label && (
          <IconLabel textAlign={mbp({ mobSm: 'left', mob: 'center' })}>
            {label}
          </IconLabel>
        )}
        <IconDescription textAlign={mbp({ mobSm: 'left', mob: 'center' })}>
          {description}
        </IconDescription>
      </Flex>
    </Flex>
  )
}

export const CardComboTopSegment = ({ children, ...props }: CardProps) => (
  <CardSegment
    bg='card.bg.2'
    shadow='primary.m'
    gap={1}
    flexDirection='row'
    {...props}
  >
    {children}
  </CardSegment>
)

export const CardComboBottomSegment = ({ children, ...props }: CardProps) => (
  <CardSegment
    shadow='primary.m'
    bgColor='highlight.100'
    flexDirection='column'
    p={2}
    {...props}
  >
    {children}
  </CardSegment>
)

export const NextButton = ({ children, ...props }: ButtonProps) => (
  <Button
    variant='solid'
    colorScheme='positive'
    size='md'
    {...props}
  >
    {children}
  </Button>
)

export const CancelButton = (props: ButtonProps) => (
  <Button
    variant='outline'
    colorScheme='neutral'
    size='md'
    {...props}
  >
    CANCEL
  </Button>
)

export const PreforeclosureIcon = () => (
  <Icon boxSize='48px' viewBox='0 0 48 49'>
    <circle cx='24' cy='24.5' r='22.8' fill='#F7F7F7' stroke='#EB4C4A' strokeWidth='2.4' />
    <path d='M13.5437 38.2656V10.3384H25.5983C27.671 10.3384 29.4847 10.7474 31.0392 11.5656C32.5938 12.3838 33.8028 13.5338 34.6665 15.0156C35.5301 16.4974 35.9619 18.2293 35.9619 20.2111C35.9619 22.2111 35.5165 23.9429 34.6256 25.4065C33.7438 26.8702 32.5028 27.9974 30.9028 28.7884C29.3119 29.5793 27.4528 29.9747 25.3256 29.9747H18.1256V24.0838H23.7983C24.6892 24.0838 25.4483 23.9293 26.0756 23.6202C26.7119 23.302 27.1983 22.852 27.5347 22.2702C27.8801 21.6884 28.0528 21.002 28.0528 20.2111C28.0528 19.4111 27.8801 18.7293 27.5347 18.1656C27.1983 17.5929 26.7119 17.1565 26.0756 16.8565C25.4483 16.5474 24.6892 16.3929 23.7983 16.3929H21.1256V38.2656H13.5437Z' fill='#EB4C4A' />
  </Icon>
)

export const VacantIcon = () => (
  <Icon boxSize='48px' viewBox='0 0 48 49'>
    <circle cx='24' cy='24.5' r='22.8' fill='#F7F7F7' stroke='#EB4C4A' strokeWidth='2.4' />
    <path d='M18.9989 10.3384L24.7261 30.0293H24.9443L30.6716 10.3384H39.2352L30.017 38.2656H19.6534L10.4352 10.3384H18.9989Z' fill='#EB4C4A' />
  </Icon>
)

export const MultipleIcon = () => (
  <Icon boxSize='48px' viewBox='0 0 48 49'>
    <circle cx='24' cy='24.5' r='22.8' fill='#F7F7F7' stroke='#EB4C4A' strokeWidth='2.4' />
    <path d='M14.2858 38.2656V32.8111L24.704 24.1929C25.3949 23.6202 25.9858 23.0838 26.4767 22.5838C26.9767 22.0747 27.3585 21.552 27.6222 21.0156C27.8949 20.4793 28.0313 19.8838 28.0313 19.2293C28.0313 18.5111 27.8767 17.8974 27.5676 17.3884C27.2676 16.8793 26.8494 16.4884 26.3131 16.2156C25.7767 15.9338 25.1585 15.7929 24.4585 15.7929C23.7585 15.7929 23.1403 15.9338 22.604 16.2156C22.0767 16.4974 21.6676 16.9111 21.3767 17.4565C21.0858 18.002 20.9403 18.6656 20.9403 19.4474H13.7403C13.7403 17.4838 14.1812 15.7929 15.0631 14.3747C15.9449 12.9565 17.1903 11.8656 18.7994 11.102C20.4085 10.3384 22.2949 9.95653 24.4585 9.95653C26.6949 9.95653 28.6313 10.3156 30.2676 11.0338C31.9131 11.7429 33.1813 12.7429 34.0722 14.0338C34.9722 15.3247 35.4222 16.8384 35.4222 18.5747C35.4222 19.6474 35.1994 20.7156 34.754 21.7793C34.3085 22.8338 33.5085 24.002 32.354 25.2838C31.1994 26.5656 29.5585 28.0929 27.4313 29.8656L24.8131 32.0474V32.2111H35.7222V38.2656H14.2858Z' fill='#EB4C4A' />
  </Icon>
)

export const SavedIcon = (props: IconProps) => (
  <Icon boxSize='48px' viewBox='0 0 48 48' {...props}>
    <path d='M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z' fill='#F7F7F7' />
    <path fillRule='evenodd' clipRule='evenodd' d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM35.7213 20.1213C36.8929 18.9498 36.8929 17.0503 35.7213 15.8787C34.5498 14.7071 32.6503 14.7071 31.4787 15.8787L20.4 26.9574L16.5213 23.0787C15.3498 21.9071 13.4503 21.9071 12.2787 23.0787C11.1071 24.2503 11.1071 26.1498 12.2787 27.3213L18.2787 33.3213C19.4503 34.4929 21.3498 34.4929 22.5213 33.3213L35.7213 20.1213Z' fill='#4FB959' />
  </Icon>
)
