import { useBreakpointValue } from '@chakra-ui/react'
import { Breakpoint, BREAKPOINTS } from 'presentation/main/themes/common/breakpoints.theme'
import { isNonNullable } from 'utils/isNonNullable'

/**
 * @returns an object with `switchBreakpoint` and `sbp`.
 *
 *   `switchBreakpoint` behaves kinda like [Chakra UI array syntax](https://chakra-ui.com/docs/styled-system/features/responsive-styles#the-array-syntax)
 *
 *   `switchBreakpoint` accepts `Record<Breakpoint, T>` then
 *   according to current browser breakpoint, it will select the correct value
 *   by accessing the value at the EXACT breakpoint OR the NEXT LOWER BREAKPOINT
 *   with NON `null`/`undefind` value.
 *
 *   Example:
 *   ```
 *   const { sbp } = useSwitchBreakpoint()
 *   const x = sbp({ mobSm: 'Chips', Dt: 'Ahoy })
 *  // For `mobSm` up to `tab` breakpoint, `x` will result to 'Chips'
 *  // For `Dt` and up, `x` will result to 'Ahoy'
 *   ```
 */
export const useSwitchBreakpointFn = () => {
  const currBreakpoint = useBreakpointValue({ base: 'mobSm', ...BP_TO_BP }, { ssr: false })

  const switchBreakpoint = <T>(dict: Partial<Record<Breakpoint, T>>): T | undefined => {
    const matchedIndex = BREAKPOINTS.findIndex(key => key === currBreakpoint) || 0
    const matchedBpWithVal = BREAKPOINTS
      /**
       * We only adopt value from breakpoints that are equal or lower than
       * current breakpoint
       */
      .slice(0, matchedIndex + 1)
      /**
       * We want it so that the equivalent breakpoint is at the top of list, and
       * the next closest (lower) breakpoint to be right after that, and so on.
       */
      .reverse()
      /**
       * Look for first value equal or closest to current breakpoint that has valid value
       */
      .find(bp => isNonNullable(dict[bp]))

    const matchedVal = matchedBpWithVal && dict[matchedBpWithVal]

    return matchedVal
  }

  return {
    switchBreakpoint,
    sbp: switchBreakpoint,
  }
}

const BP_TO_BP = {
  mobSm: 'mobSm',
  mob: 'mob',
  tabSm: 'tabSm',
  tab: 'tab',
  dtSm: 'dtSm',
  dt: 'dt',
  dtLg: 'dtLg',
} as const

/**
 * Shortcut for `useSwitchBreakpoint().sbp()`
 *
 * @returns a value according to current browser breakpoint.
 * @example
 * ```
 * const x = useSwitchBreakpoint({ mobSm: 'Chips', Dt: 'Ahoy })
 * // For `mobSm` up to `tab` breakpoint, `x` will result to 'Chips'
 * // For `Dt` and up, `x` will result to 'Ahoy'
 */
export const useSwitchBreakpoint = <T>(dict: Partial<Record<Breakpoint, T>>): T | undefined => {
  const { sbp } = useSwitchBreakpointFn()
  return sbp(dict)
}
