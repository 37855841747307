import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mergeDeep, values } from 'remeda'
import { getRecKey } from 'utils/getRecKey'

const PARTS = {
  chip: 'chip',
}

const PART_KEYS = values(PARTS) as (keyof typeof PARTS)[]

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  PART_KEYS,
)

const baseStyle = definePartsStyle(props => (
  {
    [PARTS.chip]: mergeDeep(
      props.colorScheme ? colorScheme[props.colorScheme as chipColorScheme](props).chip : {},
      {
        display: 'inline-flex',
        alignItems: 'center',
        transition: 'all 0.2s',
        _hover: {
          boxShadow: 'button-hovered',
        },
      },
    ),
  }
))

const EMPTY_PART_STYLE = {
  [PARTS.chip]: {},
}
export const colorScheme = {
  muted: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'grayweak.700',
        bg: 'grayweak.300',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'grayweak.700',
          bg: 'grayweak.500',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  neutral: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'neutral.700',
        bg: 'neutral.100',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'neutral.700',
          bg: 'neutral.200',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  positive: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'positive.700',
        bg: 'positive.50',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'positive.700',
          bg: 'positive.200',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  negative: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'negative.700',
        bg: 'negative.50',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'negative.700',
          bg: 'negative.200',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  highlight: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'highlight.700',
        bg: 'highlight.100',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'highlight.700',
          bg: 'highlight.300',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {
        borderColor: 'highlight.500',
        bg: 'highlight.500',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'accent.gold.100',
          bg: 'accent.gold.100',
        },
      },
    },
  }) || EMPTY_PART_STYLE,
  specialsat: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'specialsat.700',
        bg: 'specialsat.50',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'specialsat.700',
          bg: 'specialsat.100',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  teal: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'teal.700',
        bg: 'teal.50',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'teal.700',
          bg: 'teal.200',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  positivesat: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'positivesat.700',
        bg: 'positivesat.100',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'positivesat.700',
          bg: 'positivesat.300',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
  warm: ({ variant }: StyleFunctionProps) => getRecKey(variant)({
    outline: {
      [PARTS.chip]: {
        borderColor: 'warm.700',
        bg: 'warm.100',
        color: 'graystrong.600',
        _hover: {
          borderColor: 'warm.700',
          bg: 'warm.300',
          color: 'graystrong.600',
        },
      },
    },
    solid: {
      [PARTS.chip]: {},
    },
  }) || EMPTY_PART_STYLE,
} as const
export type chipColorScheme = keyof typeof colorScheme
export const CHIP_COLOR_SCHEMES = Object.keys(colorScheme) as chipColorScheme[]

const sizes = {
  default: {
    [PARTS.chip]: {
      h: 3.5,
      px: 1,
      borderWidth: 0.125,
      borderRadius: 2.5,
      ...textStyles.bodyM,
    },
  },
} as const
export type chipSize = keyof typeof sizes
export const CHIP_SIZES = Object.keys(sizes) as chipSize[]

const variants = {
  outline: {},
  solid: {},
} as const
export type chipVariant = keyof typeof variants
export const CHIP_VARIANTS = Object.keys(variants) as chipVariant[]

export const chipTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'default',
    colorScheme: 'muted',
    variant: 'outline',
  },
})
