import { Box, BoxProps, Collapse } from '@chakra-ui/react'
import { PanicError } from 'libs/errors/PanicError'
import { px } from 'presentation/utils/px'
import { createContext, useContext, useEffect, useId, useMemo, useRef } from 'react'
import { useMeasure } from 'react-use'
import { setPath } from 'remeda'
import { create, useStore } from 'zustand'

export const CollapsibleSegments = (props: BoxProps) => {
  const [ref, { height }] = useMeasure()

  return (
    <CollapsibleSegmentsProvider initialContainerHeight={height}>
      <Box ref={ref} h='full' {...props} />
    </CollapsibleSegmentsProvider>
  )
}
export const FixedSegment = (props: BoxProps) => {
  const { updateFixedSegment } = useCollapsibleSegmentsStore(state => state.actions)
  const id = useId()

  const element = useRef<HTMLDivElement | null>(null)

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        const height = entries[0].borderBoxSize[0].blockSize
        updateFixedSegment(id, height)
      }),
    [],
  )

  return (
    <Box
      {...props}
      ref={(node: HTMLDivElement | null) => {
        element.current = node
        if (node)
          observer.observe(node)
      }}
    />
  )
}

export const CollapsibleSegment = ({ isOpen, ...props }: BoxProps & { isOpen?: boolean }) => {
  const remainingHeight = useCollapsibleSegmentsStore(state => state.getters.getRemainingHeight())

  return (
    <Collapse
      in={isOpen}
      animateOpacity={false}
    >
      <Box
        {...props}
        h={px(remainingHeight)}
      />
    </Collapse>
  )
}

type FixedSegmentId = string
type FixedSegmentHeight = number
type CollapsibleSegmentsState = {
  values: {
    containerHeight: number
    fixedSegments: Record<FixedSegmentId, FixedSegmentHeight | null>
  }
  actions: {
    updateContainerHeight: (height: number) => void
    updateFixedSegment: (id: FixedSegmentId, height: FixedSegmentHeight) => void
    removeFixedSegment: (id: FixedSegmentId) => void
  }
  getters: {
    getRemainingHeight: () => number
  }
}

const createCollapsibleSegmentsStore = ({
  containerHeight,
}: {
  containerHeight: number
}) => create<CollapsibleSegmentsState>((set, get) => ({
  values: {
    containerHeight,
    fixedSegments: {},
  },
  actions: {
    updateContainerHeight: height =>
      set(state => setPath(
        state,
        ['values', 'containerHeight'],
        height,
      )),
    updateFixedSegment: (id, height) =>
      set(state => setPath(
        state,
        ['values', 'fixedSegments', id],
        height,
      )),
    removeFixedSegment: id =>
      set(state => setPath(
        state,
        ['values', 'fixedSegments', id],
        null,
      )),
  },
  getters: {
    getRemainingHeight: () => {
      const { containerHeight, fixedSegments } = get().values

      const fixedHeight = Object
        .values(fixedSegments)
        .reduce(
          (acc: number, height: number | null): number => acc + (height || 0),
          0,
        )
      return containerHeight - fixedHeight
    },
  },
}))

type CollapsibleSegmentsStore = ReturnType<typeof createCollapsibleSegmentsStore>

const CollapsibleSegmentsContext = createContext<CollapsibleSegmentsStore | null>(null)

type CollapsibleSegmentsProviderProps = {
  children: React.ReactNode
  initialContainerHeight: number
}

const CollapsibleSegmentsProvider = ({
  children,
  initialContainerHeight,
}: CollapsibleSegmentsProviderProps) => {
  const store = useRef(createCollapsibleSegmentsStore({
    containerHeight: initialContainerHeight,
  })).current

  useEffect(() => {
    store.getState().actions.updateContainerHeight(initialContainerHeight)
  }, [initialContainerHeight])

  return (
    <CollapsibleSegmentsContext.Provider value={store}>
      {children}
    </CollapsibleSegmentsContext.Provider>
  )
}

// eslint-disable-next-line @stylistic/comma-dangle
export const useCollapsibleSegmentsStore = <T,>(
  selector: (state: CollapsibleSegmentsState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(CollapsibleSegmentsContext)
  if (!store) throw new PanicError('useCollapsibleSegmentsStore must be used within CollapsibleSegmentsProvider')
  return useStore(store, selector, equalityFn)
}
