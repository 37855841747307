import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeCommercialIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      d='M15.625 18.625H30.375V46.375H15.625V18.625Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M45.375 18.4331V46.375H30.625V12.9019L45.375 18.4331Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='18.625'
      y='28.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='18.625'
      y='22.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='33.625'
      y='28.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='33.625'
      y='22.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='18.625'
      y='34.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='33.625'
      y='34.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='24.625'
      y='28.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='24.625'
      y='22.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='28.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='22.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='24.625'
      y='34.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='39.625'
      y='34.625'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='21.625'
      y='40.625'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='36.625'
      y='40.625'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
  </chakra.g>
)
