import { Effect, Option } from 'effect'
import OwnersProperties from 'features/OwnersProperties/domain/entities/OwnersProperties'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'
import GetOwnersPropertiesPreviewActor from 'features/OwnersProperties/view/machine/GetOwnersPropertiesPreviewActor'
import OwnerRelationMachine from 'features/OwnersProperties/view/machine/OwnerRelationMachine'
import OwnersPropertiesRoutes from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes'
import { Address } from 'features/valueObjects/Address'
import { EffectLib } from 'libs/Effect'
import { ErrorLib } from 'libs/errors'
import PropertyModalService from 'presentation/components/PropertyModal/PropertyModalService'
import { toast } from 'presentation/components/Toast'
import { ToastParams } from 'presentation/components/Toast/Toast.types'
import { DEFAULT_TOAST_ERROR_MESSAGE } from 'presentation/const/toast.const'
import AppCache from 'services/AppCache'
import AppRouter from 'services/AppRouter'
import { SnapshotFrom, assign, setup } from 'xstate'

namespace OwnersPropertiesMachine {
  export type Snapshot = SnapshotFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type Requirements = OwnersPropertiesRepo | AppCache | AppRouter | PropertyModalService

  export type Context = {
    leadId: Option.Option<string>
    parcelId: Option.Option<string>
    data: Option.Option<{
      ownershipInfo: OwnersProperties.OwnershipInfo
      owners: OwnerRelationMachine.ActorRef[]
    }>
    error: Option.Option<unknown>
  }

  export type Children = {
    getOwnersPropertiesPreview: 'getOwnersPropertiesPreview'
    ownerRelations: OwnerRelationsId
  }

  export type GetRequirementsSuccessPayload = {
    leadId: string
    parcelId: string
  }

  export type OpenAddressPayload = { address: Address } | { parcelId: string }

  export type OpenOwnerDealsMobilePayload = { ownerId: string }

  type Event =
    | { type: 'get-async-input-success', payload: GetRequirementsSuccessPayload }
    | { type: 'get-async-input-failed' }
    | { type: 'get-async-input-empty' }
    | { type: 'go-back' }
    | { type: 'skiptrace' }
    | { type: 'open-address', payload: OpenAddressPayload }
    | { type: 'open-owner-deals-mobile', payload: OpenOwnerDealsMobilePayload }

  export const make = () => Effect.gen(function * () {
    const runSync = yield * EffectLib.getRunSyncFromRuntime<AppRouter | PropertyModalService>()

    return setup({
      types: {
        context: {} as Context,
        children: {} as Children,
        events: {} as Event,
      },
      actors: {
        getOwnersPropertiesPreview: yield * GetOwnersPropertiesPreviewActor.make(),
        ownerRelations: yield * OwnerRelationMachine.make(),
      },
      actions: {
        notify: (_, params: ToastParams) => {
          toast(params)
        },
        goBack: ({ context }) =>
          Effect.gen(function * () {
            if (Option.isNone(context.leadId)) return

            const service = yield * AppRouter
            service.router.navigate(`/search/${context.leadId.value}`, {
              replace: true,
            })
          }).pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          ),
        skiptrace: ({ context }) =>
          Effect.gen(function * () {
            if (Option.isNone(context.leadId)) return

            const service = yield * AppRouter
            service.router.navigate(`/search/${context.leadId.value}/skiptrace`)
          }).pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          ),
        subscribeToSelf: ({ self }) => {
          const subscription = self.subscribe({
            error: error => {
              ErrorLib.report(error)
            },
            complete: () => {
              subscription.unsubscribe()
            },
          })
        },
        openAddress: (_, payload: OpenAddressPayload) =>
          Effect.gen(function * () {
            const propertyModal = yield * PropertyModalService
            propertyModal.open(payload)
          }).pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          ),
        openOwnerDealsMobile: ({ context }, payload: OpenOwnerDealsMobilePayload) =>
          Effect.gen(function * () {
            if (Option.isNone(context.leadId)) return

            const service = yield * AppRouter
            service.router.navigate(
              OwnersPropertiesRoutes.makeOwnerPropertiesPath(
                context.leadId.value,
                payload.ownerId,
              ),
            )
          }).pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          ),
      },
    }).createMachine({
      id: 'ownersPropertiesMachine',
      context: {
        leadId: Option.none(),
        parcelId: Option.none(),
        data: Option.none(),
        error: Option.none(),
      },
      entry: 'subscribeToSelf',
      initial: 'GetAsyncInputsLoading',
      states: {
        GetAsyncInputsLoading: {
          on: {
            'get-async-input-success': {
              target: 'GetAsyncInputsSuccess',
              actions: assign({
                leadId: ({ event }) => Option.some(event.payload.leadId),
                parcelId: ({ event }) => Option.some(event.payload.parcelId),
              }),
            },
            'get-async-input-failed': 'GetAsyncInputsFailed',
            'get-async-input-empty': 'GetAsyncInputsEmpty',
          },
        },
        GetAsyncInputsEmpty: {
          entry: [
            {
              type: 'notify',
              params: {
                status: 'error',
                title: 'We don’t have information about this property',
                message: 'Owners properties information not found found',
              },
            },
          ],
        },
        GetAsyncInputsSuccess: {
          initial: 'GetPreviewLoading',
          states: {
            GetPreviewLoading: {
              invoke: {
                id: 'getOwnersPropertiesPreview',
                src: 'getOwnersPropertiesPreview',
                input: ({ context }) => ({ parcelId: context.parcelId.pipe(Option.getOrThrow) }),
                onDone: {
                  target: 'GetPreviewSucceeded',
                  actions: [
                    assign({
                      data: ({ spawn, event, context }) => context.leadId.pipe(
                        Option.map(leadId => ({
                          ownershipInfo: event.output.ownershipInfo,
                          owners: event.output.ownerPreviews.map(owner =>
                            spawn('ownerRelations', {
                              id: OwnerRelationsId.make(owner.id),
                              input: { owner, leadId },
                            })),
                        })),
                      ),
                    }),
                  ],
                },
                onError: {
                  target: 'GetPreviewFailed',
                  actions: [
                    {
                      type: 'notify',
                      params: {
                        status: 'error',
                        title: 'Failed to load the owners',
                        message: DEFAULT_TOAST_ERROR_MESSAGE,
                      },
                    },
                  ],
                },
              },
            },
            GetPreviewSucceeded: {},
            GetPreviewFailed: {},
          },
        },
        GetAsyncInputsFailed: {
          entry: [
            {
              type: 'notify',
              params: {
                status: 'error',
                title: 'Failed to load owner properties',
                message: DEFAULT_TOAST_ERROR_MESSAGE,
              },
            },
          ],
        },
      },
      on: {
        'go-back': { actions: ['goBack'] },
        'skiptrace': { actions: ['skiptrace'] },
        'open-address': { actions: [{
          type: 'openAddress',
          params: ({ event }) => event.payload,
        }] },
        'open-owner-deals-mobile': { actions: [
          {
            type: 'openOwnerDealsMobile',
            params: ({ event }) => event.payload,
          },
        ] },
      },
    })
  })

  namespace OwnerRelationsId {
    export const make = (ownerId: string): OwnerRelationsId => `ownerRelations-${ownerId}`
  }

  type OwnerRelationsId = `ownerRelations-${string}`
}

export default OwnersPropertiesMachine
