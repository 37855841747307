import { Box } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ConfirmEmailCard } from 'presentation/screens/AuthScreens/components/ConfirmEmailCard'
import { EverythingYouNeedGrid } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/EverythingYouNeedGrid'

export const ConfirmEmailScreen = () => (
  <Box h='var(--app-height)'>
    <Breakpoint
      mobSm={<ConfirmEmailCard overflow='auto' h='100%' />}
      mob={(
        <EverythingYouNeedGrid>
          <ConfirmEmailCard />
        </EverythingYouNeedGrid>
      )}
    />
  </Box>
)
