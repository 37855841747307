import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { Breakpoint as BreakpointType } from 'presentation/main/themes/common/breakpoints.theme'
import { FC, ReactNode } from 'react'

type Props = {
  [key in BreakpointType]?: ReactNode | (() => ReactNode)
}

/**
 * @GOTCHA_ Why is ref hook within a breakpoint not working?
 *   Some hooks like `useHoverDirty` access the ref on the first `useEffect` trigger, but in the
 *   first `useEffect` trigger, the implementation of this component (specifically because of
 *   Chakra's `useBreakpoint`,) cause for the breakpoint value during the first `useEffect` trigger
 *   to be always the base value (smallest breakpoint,) even if its wrong.
 *
 * @SOLUTION_ If you're using `useRef` and `useEffect`, try using `useState` and `useEffect` instead
 *   for managing and using refs.
 *
 * @TODO_ Expand on GOTCHA
 */
export const Breakpoint: FC<Props> = props => {
  const { mfv } = useMobFirstBreakpointValue()
  const match = mfv(props)
  return <>{typeof match === 'function' ? match?.() : match}</>
}
