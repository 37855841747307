import { Alert, AlertDescription, AlertIcon, Box, BoxProps, Button, HStack, Spacer, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useBatchSkiptraceProgress } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceProgress'
import { useBatchSkiptraceStats } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceStats'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { SelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSelectListState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useSelectListState'
import { CloseIcon, EyeIcon, SkiptraceSuccessIcon, TrashIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { DeleteListModal, useDeleteListModalActions } from 'presentation/screens/ListScreen/components/DeleteListModal'
import { DeleteMemberModal, useDeleteMemberModalActions } from 'presentation/screens/ListScreen/components/DeleteMembersModal'
import { ListCriteriaPopover } from 'presentation/screens/ListScreen/components/ListCriteriaPopover/ListCriteriaPopover'
import { ListDownloadButton } from 'presentation/screens/ListScreen/components/ListFooter/components/ListDownloadButton'
import { px } from 'presentation/utils/px'

export const ListFooter = ({
  status,
  selections,
  setSelections,
  ...props
}: BoxProps & {
  status: 'importing' | 'ready'
  selections: MarketingListMemberId[]
  setSelections: React.Dispatch<React.SetStateAction<MarketingListMemberId[]>>
}) => {
  const listState = useSelectListState()
  const deleteMembersModalActions = useDeleteMemberModalActions()
  const deleteListModalActions = useDeleteListModalActions()
  const progress = useBatchSkiptraceProgress()

  const skiptraceStats = useBatchSkiptraceStats()
  const skipraceModalActions = useBatchSkiptraceModalActions()

  if (!SelectListState.$is('Success')(listState))
    return null

  return (
    <HStack
      shadow='vscroll-fade-bottom'
      w='full'
      justifyContent='center'
      bg='neutral.100'
      minH={px(ListFooter.MIN_HEIGHT)}
      {...props}
    >
      <Box
        py={3}
        px={5}
        maxWidth='1376px'
        w='full'
      >
        {status === 'importing'
          ? (
            <HStack>
              <Alert
                colorScheme='negative'
                variant='ghost'
              >
                <AlertIcon>
                  <TriangleExclamationIcon />
                </AlertIcon>
                <AlertDescription>
                  Data is still importing. Feel free to continue working and come back when its done.
                </AlertDescription>
              </Alert>
            </HStack>
          )
          : (
            <HStack spacing={1}>
              {selections.length > 0
                ? (
                  <Text textStyle='bodyXLFat' color='graystrong.200'>
                    {selections.length}
                    {' '}
                    Selected
                  </Text>
                )
                : listState.list.criteria !== null
                  ? (
                    <ListCriteriaPopover criteria={listState.list.criteria}>
                      <Button
                        colorScheme='neutral'
                        size='md'
                        variant='ghost'
                        leftIcon={<EyeIcon fontSize={3} />}
                      >
                        Show List Criteria
                      </Button>
                    </ListCriteriaPopover>
                  )
                  : null}

              <Spacer />
              {selections.length > 0
                ? (
                  <Button
                    colorScheme='negative'
                    size='md'
                    variant='ghost'
                    leftIcon={<CloseIcon fontSize={3} />}
                    onClick={() => {
                      deleteMembersModalActions.open({
                        listId: listState.list.id,
                        memberIds: selections,
                        onSuccess: () => setSelections([]),
                      })
                    }}
                  >
                    Remove Properties
                  </Button>
                )
                : (
                  <Button
                    colorScheme='negative'
                    size='md'
                    variant='ghost'
                    leftIcon={<TrashIcon fontSize={3} />}
                    onClick={() => {
                      deleteListModalActions.open({
                        listId: listState.list.id,
                      })
                    }}
                  >
                    Delete List
                  </Button>
                )}
              {selections.length === 0 && (
                <ListDownloadButton>
                  Download
                  {' '}
                  {selections.length > 0 ? 'Properties' : 'List'}
                </ListDownloadButton>
              )}
              <Button
                colorScheme='positive'
                size='md'
                variant='solid'
                leftIcon={<SkiptraceSuccessIcon fontSize={3} />}
                onClick={() => {
                  if (skiptraceStats._tag !== 'Success')
                    return

                  skipraceModalActions.open({
                    stats: skiptraceStats.stats,
                    listId: listState.list.id,
                    memberIds: selections,
                  })
                }}
                isLoading={progress._tag === 'Checking' || progress._tag === 'InProgress'}
                disabled={progress._tag === 'Checking' || progress._tag === 'InProgress'}
                loadingText={
                  pipe(
                    Match.value(progress),
                    Match.tag('Checking', () => 'Checking Skiptrace'),
                    Match.tag('InProgress', () => 'Skiptracing'),
                    Match.orElse(() => undefined),
                  )
                }
              >
                Skiptrace
                {' '}
                {selections.length > 0 ? 'Properties' : 'List'}
              </Button>
            </HStack>
          )}
      </Box>

      <DeleteMemberModal />
      <DeleteListModal />
    </HStack>
  )
}

ListFooter.MIN_HEIGHT = 88
