import { gql } from '__generated__'

export const GET_NEARBY_BUYERS_QUERY = gql(/* GraphQL */`

query GetNearbyBuyers(
  $first: Int
  $last: Int
  $before: ID
  $after: ID
  $input: ParcelBuyersFiltersInput!
) {
  buyersByRadius(
    first: $first
    last: $last
    before: $before
    after: $after
    input: $input
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    totalCount
    }
    edges {
      cursor
      node {
        buyer {
          id
          name {
            first
            last
            middle
            suffix
            full
          }
          address {
            ...NearbyBuyersAddressUnverifiedDetailsWithoutLocation
          }
          buyerDetails {
            buyerTypes
            stats {
              min
              max
              total
              average
              count
            }
            historicalDeals(first: 1) {
              edges {
                node {
                  ...NearbyBuyersDealDetails
                }
              }
              pageInfo {
                totalCount
              }
            }
          }
        }
        deals {
          ...NearbyBuyersDealDetails
          parcel {
            id
          }
        }
        parcels {
          id
          ...NearbyBuyersParcelDetails
        }
      }
    }
  }
}

# fragment NearbyBuyersDealDetails on ParcelBuyerDeal {
#   purchaseDate
#   purchaseAmount
#   rentDate
#   rentAmount
#   saleDate
#   saleAmount
# }

# fragment NearbyBuyersAddressVerifiedDetails on AddressVerified {
#   __typename
#   id
#   location {
#     latitude
#     longitude
#   }
#   line1
#   city
#   state
#   postalCode
# }

# fragment NearbyBuyersAddressUnverifiedDetails on AddressUnverified {
#   __typename
#   location {
#     latitude
#     longitude
#   }
#   line1
#   city
#   state
#   postalCode
# }

# fragment NearbyBuyersAddressUnverifiedDetails on ParcelAddress {
#   ... on AddressVerified {
#     ...NearbyBuyersAddressVerifiedDetails
#   }
#   ... on AddressUnverified {
#     ...NearbyBuyersAddressUnverifiedDetails
#   }
# }

# # @HACK Address accessed via buyers seems to have no location (despite same address accessed from
# # parcels having location.) This causes apollo client to set location to null sometimes. Using
# # this fragment avoids the issue.
# fragment NearbyBuyersAddressUnverifiedDetailsWithoutLocation on ParcelAddress {
#   ... on AddressVerified {
#     line1
#     city
#     state
#     postalCode
#   }
#   ... on AddressUnverified {
#     line1
#     city
#     state
#     postalCode
#   }
# }

# fragment NearbyBuyersParcelDetails on Parcel {
#   id
#   address {
#     ...NearbyBuyersAddressUnverifiedDetails
#   }
#   landDetails {
#     county {
#       ... on UsCounty {
#         name
#         state
#       }
#     }
#   }
#   ownerDetails {
#     ownershipMonths
#     ownerClassification
#     ownerStatus
#     isForeclosure
#     isSenior
#     isVacant
#   }
#   buildingDetails {
#     bedroomsCount
#     bathroomsCount {
#       full
#       half
#       total
#     }
#     buildingAreaSqft
#   }
#   valuationDetails {
#     equity
#     loanToValue
#     value
#   }
# }

`)
