import { Box } from '@chakra-ui/react'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { CompsSinglePointMapMarkerProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMapMarker/CompsSinglePointMapMarker.props'
import { CompsSinglePointMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMarker'
import { Marker } from 'react-map-gl'

export const CompsSinglePointMapMarker = (props: CompsSinglePointMapMarkerProps) => {
  const {
    latitude,
    longitude,
    isHighlighted,
    onHover,
    onHoverOut,
    onMarkerClick,
    ...markerProps
  } = props

  const hasSubjectProperty = markerProps.markers.some(marker => marker.isSubjectProperty)
  /**
   * Make sure that we maintain the current page of the popup
   */
  const getCurrentMarkerId = () => {
    if (markerProps.markers.length === 0) return null

    return markerProps.markers.find(
      marker => marker.id === markerProps.currentHighlightedMarkerId,
    )?.id ?? markerProps.markers[0].id
  }
  return (
    <Box
      onMouseEnter={e => {
        e.stopPropagation()
        const currentMarkerId = getCurrentMarkerId()
        if (currentMarkerId) onHover(currentMarkerId)
      }}
      onMouseLeave={e => {
        e.stopPropagation()
        const currentMarkerId = getCurrentMarkerId()
        if (currentMarkerId) onHoverOut(currentMarkerId)
      }}
      onClick={e => {
        e.stopPropagation()
        const currentMarkerId = getCurrentMarkerId()
        if (currentMarkerId) onMarkerClick(currentMarkerId)
      }}

    >
      <Marker
        longitude={longitude}
        latitude={latitude}
        style={{
          zIndex: isHighlighted
            ? MAP_ELEMENT__Z_INDICES.SELECTED_MARKER
            : hasSubjectProperty
              ? MAP_ELEMENT__Z_INDICES.SUBJECT_MARKER
              : MAP_ELEMENT__Z_INDICES.MARKER,
        }}
        anchor='bottom'
      >
        <CompsSinglePointMarker
          hasSubjectProperty={hasSubjectProperty}
          {...markerProps}
        />
      </Marker>
    </Box>
  )
}
