import { useSelector } from '@xstate/react'
import { Array, Equal, pipe } from 'effect'
import PlanCompactViewModel from 'presentation/screens/Plans/PlanComparisonV2/PlanCompact/PlanCompactViewModel'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'

type UnlockBusinessSectionViewModel = {
  planCards: PlanCompactViewModel[]
}

const makePresenter = (
  actor: PlanComparisonMachine.ActorRef,
  snapshot: PlanComparisonMachine.Snapshot,
) => {
  const { products, isYearly, disableFreePlan } = snapshot.context
  return pipe(
    products,
    /**
     * Make sure we only show the product if the plan is available
     */
    Array.filter(product => pipe(
      product.plans,
      Array.some(plan =>
        plan._tag === (isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
    )),
    Array.map(product => PlanCompactViewModel.makePresenter({
      isYearly,
      product,
      addToCart: product => actor.send({ type: 'add-to-cart', targetProduct: product }),
      disableFreePlan,
    })),
  )
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    snapshot => makePresenter(actor, snapshot),
    Equal.equals,
  )
}

const UnlockBusinessSectionViewModel = {
  usePresenter,
}

export default UnlockBusinessSectionViewModel
