import { gql } from '__generated__'

export const LOAD_CMA_QUERY = gql(/* GraphQL */`
query LoadCMA($parcelId: String!) {
  parcel(id: $parcelId) {
    id
    address {
      ...VerifiedAddressBasicFields
      ...VerifiedAddressLocationFields
      ...UnverifiedAddressBasicFields
      ...UnverifiedAddressLocationFields
    }
    mlsPropertyListings(first: 10) {
      edges {
        node {
          id
          resoPropertyListing {
            listingId
          }
        }
      }
    }
  }
}

fragment VerifiedAddressBasicFields on AddressVerified {
line1
city
state
postalCode
}

fragment UnverifiedAddressBasicFields on AddressUnverified {
line1
city
state
postalCode
}

fragment VerifiedAddressLocationFields on AddressVerified {
location {
  latitude
  longitude
}
}

fragment UnverifiedAddressLocationFields on AddressUnverified {
location {
  latitude
  longitude
}
}
`)
