import { useMutation } from '@apollo/client'
import { Box, Flex, FlexProps, FormControl, FormControlProps, FormErrorMessage, FormLabel, FormLabelProps, Image, Input, Select, Text, useToken } from '@chakra-ui/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeCardNumberElement, StripeElementChangeEvent, StripeElementStyle } from '@stripe/stripe-js'
import { gql } from '__generated__'
import CountryRegionData from 'country-region-data/data.json'
import misterSecurePng from 'presentation/assets/img/3d/mister-secure.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import * as inputTheme from 'presentation/components/Input/Input.theme'
import { ResponsiveFullscreenModalV1 } from 'presentation/components/ResponsiveFullscreenModal'
import { toast } from 'presentation/components/Toast'
import { CardBody, CardButton, CardFooter, CardHeader, CardPrimaryTitle, CardSecondarySupportingText } from 'presentation/components/molecules/Card'
import { SecurePaymentIconsSection } from 'presentation/screens/Billing/components/SecurePaymentIconsSection'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ComponentProps, FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, UseFormReturn, useForm, useFormContext } from 'react-hook-form'
import { create } from 'zustand'
import { AddPaymentModalProps } from './AddPaymentModal.props'

// =============================================================================
// AddPaymentModal
// =============================================================================
/**
 * @TODO Use Stripe Address form instead for real international address?
 * @TODO Adjust Stripe input logic so that it matches react hook form behavior more
 *   For example, unless dirty, do not show error if input is blurred with invalid value
 * @TODO Intercept invalid input on inputs like zip
 * @param param0
 * @returns
 */
export const AddPaymentModal: FC<AddPaymentModalProps> = ({ isOpen = false, onClose, context }) => {
  const {
    isLoading,
    addPaymentMethod,
    formMethods,
  } = useAddPaymentModal({ onClose, context })

  return (
    <ResponsiveFullscreenModalV1
      isOpen={isOpen}
      onClose={onClose}
      variant='default'
      colorScheme='modal.highlight'
      size='modal.default.n'
      blockScrollOnMount
      title='Add New Card'
      footer={(
        <CardFooter>
          <CardButton
            variant='outline'
            colorScheme='neutral'
            onClick={onClose}
          >
            Cancel
          </CardButton>

          <CardButton
            variant='solid'
            colorScheme='positive'
            isLoading={isLoading}
            onClick={addPaymentMethod}
            loadingText='Adding Card'
            spinnerPlacement='end'
          >
            Add Card
          </CardButton>
        </CardFooter>
      )}
    >
      <FormProvider {...formMethods}>
        <Image
          src={misterSecurePng}
          boxSize={mbp({ tabSm: '200px', tab: '230px' })}
          pos='absolute'
          top={mbp({ tabSm: '-54px', tab: '-80px' })}
          right={mbp({ tabSm: '-33px', tab: '-50px' })}
          display={mbp({ mobSm: 'none', tabSm: 'block' })}
        />
        <CardHeader>
          <Breakpoint
            mob={(
              <CardPrimaryTitle>
                Add New Card
              </CardPrimaryTitle>
            )}
          />
          <CardSecondarySupportingText
            mt={mbp({ mob: '1', tabSm: '2' })}
            maxW={mbp({ mob: '100%', tabSm: '83%' })}
          >
            We protect your card information via Stripe, an industry-
            leading payment processor that&apos;s PCI DSS Level 1 certified —
            the highest security standard.
          </CardSecondarySupportingText>
        </CardHeader>

        <CardBody overflow='visible'>
          <AddPaymentForm onSubmit={addPaymentMethod} />
          <SecurePaymentIconsSection
            mt={mbp({ mobSm: '2', tabSm: '5' })}
          />
        </CardBody>
      </FormProvider>
    </ResponsiveFullscreenModalV1>
  )
}

type FormValues = {
  cardNumber: boolean // managed by stripe, but used for managing form errors
  cardExpiry: boolean // managed by stripe, but used for managing form errors
  cardCvc: boolean // managed by stripe, but used for managing form errors
  name: string
  line1: string
  city: string
  state: string
  country: string
  zip: string
}

const US_STATES = CountryRegionData
  .find(c => c.countryName === 'United States')
  ?.regions

if (!US_STATES) throw new Error('States data missing')

// =============================================================================
// Add Payment Method Mutation
// =============================================================================
export const ADD_PAYMENT_MODAL__ADD_PAYMENT_METHOD = gql(/* GraphQL */ `
  mutation AddPaymentModal_AddPaymentMethod($cardTokenId: String!) {
    billingAddPaymentMethod(cardTokenId: $cardTokenId) {
      ...on Enterprise {
        id
        paymentMethods {
          edges {
            node {
              ... on BillingCardsPaymentMethod { id }
              ...PaymentMethodCard_PaymentMethodFragment
            }
          }
        }
      }
    }
  }
`)

// =============================================================================
// useAddPaymentModal
// =============================================================================
const useAddPaymentModal = ({ onClose, context = 'other' }: {
  onClose: () => void
  context?: 'registration' | 'other'
}): {
    isLoading: boolean
    addPaymentMethod: () => void
    formMethods: UseFormReturn<FormValues>
  } => {
  const stripe = useStripe()
  const elements = useElements()
  const formMethods = useForm<FormValues>({
    shouldFocusError: false,
    defaultValues: { country: 'United States' },
  })

  const [isLoading, setIsLoading] = useState(false)

  const [addPaymentMethodApi] = useMutation(ADD_PAYMENT_MODAL__ADD_PAYMENT_METHOD)

  /**
   * Create token with stripe, then call add payment api
   */
  const addPaymentMethod = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      // Check if nullable values are retrieved
      if (elements === null || stripe === null) {
        setIsLoading(false)
        throw new Error('Payment service not loaded properly')
      }

      const cardNumEl = elements.getElement(CardNumberElement)

      if (cardNumEl === null)
        throw new Error('Payment service not loaded properly')

      // Get values from local form
      const localFormResult = await new Promise<{ values: FormValues } | { error: true }>(resolve => {
        void formMethods.handleSubmit(
          values => {
            resolve({ values })
          },
          () => {
            resolve({ error: true })
          },
        )()
      })

      // Short-circuit if there's an error on local form or stripe form
      if ('error' in localFormResult)
        throw new FormError()

      // Create stripe token
      const extraData = createTokenReqExtraData(localFormResult.values)
      const { error, token } = await stripe.createToken(cardNumEl, extraData)

      if (error)
        throw new Error(error.message)

      if (!token)
        throw GENERIC_ADD_PAYMENT_ERROR

      // If in registration context, skip the GraphQL call and just dispatch the event
      if (context === 'registration') {
        // Dispatch an event with the payment information
        // This will be picked up by components that need payment info (like registration)
        const paymentAddedEvent = new CustomEvent('paymentMethodAdded', {
          detail: {
            type: 'PAYMENT_METHOD_ADDED',
            paymentMethodId: token.id,
          },
        })
        window.dispatchEvent(paymentAddedEvent)
        onClose()
        setIsLoading(false)
        return
      }

      // For non-registration contexts, feed stripe token to our backend
      await addPaymentMethodApi({ variables: { cardTokenId: token.id } })
        .then(({ data, errors }) => {
          const errMsg = errors?.[0].message

          if (errMsg || !data)
            throw new Error(errMsg || GENERIC_ADD_PAYMENT_ERROR.message)

          onClose()
        })
    } catch (error) {
      // FormError would be displayed already if any
      if (!(error instanceof FormError)) {
        toast({
          status: 'error',
          title: (error as Error)?.message || GENERIC_ADD_PAYMENT_ERROR.message,
        })
      }
    }

    setIsLoading(false)
  }, [elements, stripe])

  return {
    isLoading,
    addPaymentMethod,
    formMethods,
  }
}

class FormError extends Error {
  constructor() {
    super()

    Object.setPrototypeOf(this, FormError.prototype)
  }
}

// =============================================================================
// Toast and Error presets
// =============================================================================
const GENERIC_ADD_PAYMENT_ERROR = new Error('Add payment method failed')

// =============================================================================
// Token Creation Extra Data Helper
// =============================================================================
type TokenReqExtraData = {
  name: string
  address_line1: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
}

const createTokenReqExtraData = (raw: FormValues): TokenReqExtraData => ({
  name: raw.name,
  address_line1: raw.line1,
  address_city: raw.city,
  address_state: raw.state,
  address_zip: raw.zip,
  address_country: raw.country,
})

// =============================================================================
// AddPaymentForm
// =============================================================================
const AddPaymentForm: FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const { register, formState: { errors } } = useFormContext<FormValues>()
  const stripeErrors = useStripeErrorStore(state => state.errors)

  const cardNumberError = stripeErrors.cardNumber || errors.cardNumber?.message
  const cardExpiryError = stripeErrors.cardExpiry || errors.cardExpiry?.message
  const cardCvcError = stripeErrors.cardCvc || errors.cardCvc?.message

  return (
    <Box
      as='form'
      mx={mbp({ mobSm: '-0.5', tabSm: '-1' })}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <SectionTitle>
        Card Info
      </SectionTitle>

      {/* Card Number Input */}
      <InputContainer isInvalid={!!cardNumberError}>
        <StripeElementStyler
          fieldKey='cardNumber'
          autoFocus
          isInvalid={!!cardNumberError}
          options={{ ...useStripeOpts(), placeholder: 'Card Number' }}
        />

        {cardNumberError && (
          <FormErrorMessage>
            {cardNumberError}
          </FormErrorMessage>
        )}
      </InputContainer>

      {/* Card Name Input */}
      <InputContainer
        isInvalid={!!errors.name}
      >
        <Input
          variant='default'
          placeholder='Name on Card'
          {...register('name', { required: 'Please fill in name on card' })}
        />

        {errors.name?.message && (
          <FormErrorMessage>
            {errors.name.message}
          </FormErrorMessage>
        )}
      </InputContainer>

      <FlexContainer>
        {/* Card Expiry Input */}
        <InputContainer
          isInvalid={!!cardExpiryError}
          flex={mbp({ mobSm: '0 0 50%', tabSm: '0 0 66.66%' })}
        >
          <StripeElementStyler
            isInvalid={!!cardExpiryError}
            fieldKey='cardExpiry'
          />

          {cardExpiryError && (
            <FormErrorMessage>
              {cardExpiryError}
            </FormErrorMessage>
          )}
        </InputContainer>

        {/* Card CVC Input */}
        <InputContainer
          isInvalid={!!cardCvcError}
          flex={mbp({ mobSm: '0 0 50%', tabSm: '0 0 33.33%' })}
        >
          <StripeElementStyler
            isInvalid={!!cardCvcError}
            fieldKey='cardCvc'
          />

          {cardCvcError && (
            <FormErrorMessage>
              {cardCvcError}
            </FormErrorMessage>
          )}
        </InputContainer>
      </FlexContainer>

      <SectionTitle mt='2'>
        Billing Address
      </SectionTitle>

      {/* Card Address (Line 1) Input */}
      <InputContainer
        isInvalid={!!errors.line1}
      >
        <Input
          variant='default'
          placeholder='Address'
          {...register('line1', { required: 'Please fill in address' })}
        />

        {errors.line1?.message && (
          <FormErrorMessage>
            {errors.line1.message}
          </FormErrorMessage>
        )}
      </InputContainer>

      <FlexContainer>
        {/* City Input */}
        <InputContainer
          isInvalid={!!errors.city}
          flex={mbp({ mobSm: '0 0 100%', tabSm: '0 0 33.33%' })}
        >
          <Input
            variant='default'
            placeholder='City'
            {...register('city', { required: 'Please fill in city' })}
          />

          {errors.city?.message && (
            <FormErrorMessage>
              {errors.city.message}
            </FormErrorMessage>
          )}
        </InputContainer>

        {/* State Input */}
        <InputContainer
          isInvalid={!!errors.state}
          flex={mbp({ mobSm: '0 0 100%', tabSm: '0 0 33.33%' })}
        >
          <Select
            variant='default'
            {...register('state', { required: 'Please fill in state' })}
          >
            <Text as='option' value='' color='l.gs100-d.gs300'>State</Text>
            {US_STATES
              .map(r => (
                <Text as='option' key={r.shortCode || r.name} value={r.name}>
                  {r.name}
                </Text>
              )) || null}
          </Select>

          {errors.state?.message && (
            <FormErrorMessage>
              {errors.state.message}
            </FormErrorMessage>
          )}
        </InputContainer>

        {/* Zip Input */}
        <InputContainer
          isInvalid={!!errors.zip}
          flex={mbp({ mobSm: '0 0 100%', tabSm: '0 0 33.33%' })}
        >
          <Input
            variant='default'
            placeholder='Zip'
            {...register(
              'zip',
              {
                required: 'Please fill in zip',
                pattern: {
                  value: /(^\d{5}$)/,
                  message: 'Please fill in valid zip',
                },
              },
            )}
          />

          {errors.zip?.message && (
            <FormErrorMessage>
              {errors.zip.message}
            </FormErrorMessage>
          )}
        </InputContainer>
      </FlexContainer>

      {/* Hidden button to trigger submit on enter */}
      <Input
        type='submit'
        display='none'
        tabIndex={-1}
      />
    </Box>
  )
}

const FlexContainer: FC<FlexProps> = props => (
  <Flex flexWrap='wrap' {...props} />
)

const InputContainer: FC<FormControlProps> = props => (
  <FormControl px={mbp({ mobSm: '0.5', tabSm: '1' })} py='1' {...props} />
)

const SectionTitle: FC<FormLabelProps> = props => (
  <FormLabel px={mbp({ mobSm: '0.5', tabSm: '1.5' })} {...props} />
)

// =============================================================================
// Stripe Fields Errors and Types
// =============================================================================

const STRIPE_FIELD_KEY_TO_COMPONENT = {
  cardNumber: CardNumberElement,
  cardExpiry: CardExpiryElement,
  cardCvc: CardCvcElement,
} as const

type StripeFieldKey = keyof typeof STRIPE_FIELD_KEY_TO_COMPONENT
type RelevantStripeComponents = typeof STRIPE_FIELD_KEY_TO_COMPONENT[StripeFieldKey]

const INIT_STRIPE_FIELD_ERRORS = {
  cardNumber: { error: 'Please fill in valid card number' },
  cardExpiry: { error: 'Please fill in valid card expiry' },
  cardCvc: { error: 'Please fill in valid card cvc' },
} as const

// =============================================================================
// StripeElementStyler
// =============================================================================
const inputStyles = inputTheme.variants.default.field
const inputSizeStyles = inputTheme.sizes.md.field

const StripeElementStyler = ({
  children,
  fieldKey,
  options,
  isInvalid,
  ...props
}: FlexProps & {
  fieldKey: StripeFieldKey
  options?: ComponentProps<RelevantStripeComponents>['options']
  autoFocus?: boolean
  isInvalid?: boolean
}): ReactElement => {
  const { isFocused, ...elementFocus } = useStripeElFocus({ fieldKey })
  const errorTracker = useTrackStripeElError({ fieldKey })
  const Element = STRIPE_FIELD_KEY_TO_COMPONENT[fieldKey]
  const stripeOpts = useStripeOpts()

  return (
    <Box
      transitionProperty='border-color, box-shadow'
      transitionDuration='0.2s'

      /** Needed to put input above the error message */
      pos='relative'
      zIndex='1'

      {...inputStyles}
      {...inputSizeStyles}
      {...isFocused && inputStyles._focus}
      {...isFocused && inputSizeStyles._focus}
      {...isInvalid && inputStyles._invalid}
      {...isInvalid && inputSizeStyles._invalid}
      {...props}
    >
      <Element
        options={{ ...stripeOpts, ...options }}
        onReady={element => {
          elementFocus.handleReady()
          errorTracker.handleReady()
          if (props.autoFocus)
            element.focus()
        }}
        className='stripe-element'
      />
    </Box>
  )
}

const useStripeOpts = (): { style: StripeElementStyle } => {
  const color = useToken('colors', inputStyles.color)
  const placeholderColor = useToken('colors', inputStyles._placeholder.color)
  const fontSize = useToken('fontSizes', inputStyles.fontSize)
  const fontFamily = useToken('fontSizes', inputStyles.fontFamily)
  const lineHeight = useToken('fontSizes', inputStyles.lineHeight)

  return {
    style: {
      base: {
        fontSize,
        fontFamily,
        lineHeight,
        color,
        '::placeholder': {
          color: placeholderColor,
        },
      },
    },
  }
}

/**
 * A way to detect focus on stripe element
 */
const useStripeElFocus = ({ fieldKey }: {
  fieldKey: StripeFieldKey
}): {
    isFocused: boolean
    handleReady: () => void
  } => {
  const elements = useElements()
  const [isFocused, setIsFocused] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const handleReady = useCallback(() => {
    setIsReady(true)
  }, [])

  useEffect(() => {
    if (elements === null) return

    const component = STRIPE_FIELD_KEY_TO_COMPONENT[fieldKey]

    const stripeEl = elements.getElement(component as any) as StripeCardNumberElement | null

    if (stripeEl === null)
      throw new Error('Payment service not loaded properly')

    const handleFocus = (): void => setIsFocused(true)
    const handleBlur = (): void => setIsFocused(false)

    stripeEl.on('focus', handleFocus)
    stripeEl.on('blur', handleBlur)

    return () => {
      stripeEl.off('focus', handleFocus)
      stripeEl.off('blur', handleBlur)
    }
  }, [isReady, elements])

  return {
    isFocused,
    handleReady,
  }
}

/**
 * A way to track error on stripe element
 */
const useTrackStripeElError = ({ fieldKey }: {
  fieldKey: StripeFieldKey
}): {
    handleReady: () => void
  } => {
  const elements = useElements()
  const formMethods = useFormContext<FormValues>()

  const [isReady, setIsReady] = useState(false)

  const handleReady = useCallback(() => {
    setIsReady(true)
  }, [])

  const setStripeError = useStripeErrorStore(state => state.set)

  const isCompleteRef = useRef<boolean>(false)

  useEffect(() => {
    if (!isReady || elements === null) return

    formMethods.setValue(fieldKey, false)
    formMethods.register(fieldKey, { required: INIT_STRIPE_FIELD_ERRORS[fieldKey].error })

    const component = STRIPE_FIELD_KEY_TO_COMPONENT[fieldKey]

    const element = elements.getElement(component as any) as StripeCardNumberElement | null

    if (element === null)
      throw new Error('Payment service not loaded properly')

    // Track error on change
    const handleChange = (ev: StripeElementChangeEvent): void => {
      /** If we got incomplete error, prefer our verbage over Stripe for consistency */
      const isErrorCodeIncomplete = ev.error?.type && checkIsStripeErrorCodeIncomplete(ev.error.code)

      const stripeError = ev.error && !isErrorCodeIncomplete
        ? ev.error.message
        : null

      /** Store isComplete in ref to be used on blur */
      isCompleteRef.current = ev.complete

      /** On change, stripe errors are shown immediately */
      setStripeError(fieldKey, stripeError)

      /**
       * React hook form errors on the otherhand are only updated immediately
       * if it clears the error. Otherwise, it's updated on blur.
       */
      if (ev.complete) {
        formMethods.setValue(fieldKey, ev.complete)
        void formMethods.trigger(fieldKey)
      }
    }

    /** Trigger React Hook Form validation on blur */
    const handleBlur = (): void => {
      formMethods.setValue(fieldKey, isCompleteRef.current)
      void formMethods.trigger(fieldKey)
    }

    element.on('change', handleChange)
    element.on('blur', handleBlur)

    return () => {
      element.off('change', handleChange)
      element.off('blur', handleBlur)
    }
  }, [isReady, elements])

  return { handleReady }
}

const INCOMPLETE_STRIPE_ERROR_TYPES = [
  'incomplete_number',
  'incomplete_expiry',
  'incomplete_cvc',
]

const checkIsStripeErrorCodeIncomplete = (type: string) =>
  INCOMPLETE_STRIPE_ERROR_TYPES.includes(type)

/**
 * Store that retains Stripe error. Created because we want Stripe error to take
 * precedence but react hook form clears custom errors whenever it's validation
 * is triggered.
 */
type StripeErrorStore = {
  errors: { [key in StripeFieldKey]?: string }
  set: (key: StripeFieldKey, value: string | null) => void
}

const useStripeErrorStore = create<StripeErrorStore>((set, get) => ({
  set: (key, value) => {
    set({ errors: { ...get().errors, [key]: value } })
  },
  errors: {},
}))
