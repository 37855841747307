import { useBatchSkiptraceStore } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/BatchSkiptraceStoreLive'

export const useBatchSkiptraceActions = () =>
  useBatchSkiptraceStore(store => ({
    getByListId: store.getByListId.execute,
    getStatsByListId: store.getStatsByListId.execute,
    getSelectionToSkiptraceCount: store.getSelectionToSkiptraceCount.execute,
    getSelectionUnskiptracedCount: store.getSelectionUnskiptracedCount.execute,
    resetGetSelectionToSkiptraceCount: store.getSelectionToSkiptraceCount.reset,
    resetGetSelectionUnskiptracedCount: store.getSelectionUnskiptracedCount.reset,
    download: store.download.execute,
    attemptBatchSkiptrace: store.attemptBatchSkiptrace.execute,
    confirmBatchSkiptrace: store.confirmBatchSkiptrace.execute,
    watchProgress: store.watchProgress.execute,
  }))
