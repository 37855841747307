import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { MLSStatusTrainingCard } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingCard'
import { useMLSStatusTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingModal/MLSStatusTrainingModal.api'

export const MLSStatusTrainingModal = () => {
  const api = useMLSStatusTrainingModal()
  return (
    <ResponsiveFullscreenModal
      isOpen={api.state.status === 'open'}
      onClose={api.actions.close}
      title='Property Status'
    >
      <MLSStatusTrainingCard onClose={api.actions.close} />
    </ResponsiveFullscreenModal>
  )
}
