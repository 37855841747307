import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeMobileIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.path
      d='M20.25 23.7501L25.25 30.2501H43.75V39.2501H25.25H14.75V30.2501L20.25 23.7501Z'
      fill='grayweak.300'
    />
    <chakra.path
      d='M45.25 28.2501H26.25L20.25 20.75L40.75 20.75L45.25 28.2501Z'
      fill='grayweak.300'
    />
    <chakra.path
      d='M25.25 30.2501L20.25 23.7501L14.75 30.2501V39.2501H25.25M25.25 30.2501H43.75V39.2501H25.25M25.25 30.2501V39.2501M11.75 30.2501L20.25 20.75M45.25 28.2501H26.25L20.25 20.75M45.25 28.2501L40.75 20.75L20.25 20.75M45.25 28.2501L46.25 29.75'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <chakra.circle
      cx='29.75'
      cy='40.25'
      r='2.5'
      fill='grayweak.300'
      stroke={props.color}
    />
    <chakra.mask
      id='mask0_619_102275'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='15'
      y='39'
      width='7px'
      height='5px'
    >
      <chakra.rect
        x='15.75'
        y='39.8679'
        width='6px'
        height='3.3821px'
        fill='grayweak.300'
      />
    </chakra.mask>
    <chakra.g mask='url(#mask0_619_102275)'>
      <chakra.circle
        cx='18.75'
        cy='40.25'
        r='2.5'
        fill='grayweak.300'
        stroke={props.color}
      />
    </chakra.g>
    <chakra.circle
      cx='40.2422'
      cy='40.25'
      r='2.5'
      fill='grayweak.300'
      stroke={props.color}
    />
    <chakra.path
      d='M18 38.5V32H22V39'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </chakra.g>
)
