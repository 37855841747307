import { Match, pipe as effectPipe } from 'effect'
import { ProgramException } from 'libs/errors'
import numeral from 'numeral'
import { createPipe, identity, isNumber, isString } from 'remeda'

export namespace NumberLib {
  export const ensureOr = <TFallback>(fallback: TFallback) =>
    (value: number | undefined | null): number | TFallback =>
      isNumber(value) ? value : fallback

  export const ensureOrNull = ensureOr(null)
  export const orNull = ensureOrNull

  export const ensureOrUndefined = ensureOr(undefined)
  export const orUndefined = ensureOrUndefined

  export const ensureOrDoubleDash = ensureOr('--')
  export const orDoubleDash = ensureOrDoubleDash

  export const ifValid = <TOutput>(fn: (num: number) => TOutput) =>
    (maybeNum: number | undefined | null): TOutput | null =>
      isNumber(maybeNum) ? fn(maybeNum) : null

  export const ifElse = <TOutput>(ifFn: (num: number) => TOutput) =>
    <TElseOutput>(elseFn: () => TElseOutput) =>
      (maybeNum: number | undefined | null) =>
        isNumber(maybeNum) ? ifFn(maybeNum) : elseFn()

  const makeNumberFallbackFn = <TOutput>(fn: (x: number) => TOutput) =>
    <TFallback>(fallback: TFallback) =>
      (x?: number | null) =>
        isNumber(x) ? fn(x) : fallback

  const removeCommas = (x: string) => x.replace(/,/g, '')

  const fromStringUnsafe = createPipe(removeCommas, parseFloat)

  const fromStringVariants = {
    strict: (stringInput: string): number => {
      if (!stringInput) throw new ProgramException('String is empty')
      const converted = fromStringUnsafe(stringInput)
      if (isNaN(converted)) throw new ProgramException('String is not a number')
      return converted
    },
    safe: (stringInput: string): number | null => {
      if (!stringInput) return null
      const converted = fromStringUnsafe(stringInput)
      if (isNaN(converted)) return null
      return converted
    },
  }

  type FromString = {
    (stringInput: string): number
    strict: (stringInput: string) => number
    safe: (stringInput: string) => number | null
  }

  export const fromString: FromString = value => fromStringVariants.strict(value)
  fromString.strict = fromStringVariants.strict
  fromString.safe = fromStringVariants.safe

  export const fromStringSafe = fromString.safe

  export const fromUnknown = {
    safe: (unknownInput: unknown): number | null => {
      if (Number.isNaN(unknownInput)) return null
      if (isNumber(unknownInput)) return unknownInput
      if (!unknownInput) return null
      const converted = Number(unknownInput)
      if (isNaN(converted)) return null
      return converted
    },
  }

  export const formatK = (number: number) => numeral(number).format('0.[0]a').toUpperCase()

  export const formatMax3Digits = (number: number) => effectPipe(
    Match.value(number),
    /** if only first digit is non zero to not allow 1000 formatting */
    Match.when(n => /^[1-9]0*$/.test(n.toString()), n => numeral(n).format('0a')),
    Match.when(n => n > 9999 && n < 1_000_000, n => numeral(n).format('0a')),
    Match.when(n => n <= 9999, n => numeral(n).format('0,0')),
    Match.orElse(n => numeral(n).format('0.00a')),
  )

  /** @TODO rename to formatCommas */
  export const formatComma = (number: number) => numeral(number).format('0,0')
  formatComma.fallback = makeNumberFallbackFn(formatComma)
  export const formatCommasOrDoubleDash = ifElse(formatComma)(() => '--')
  /** formatCommasOrDoubleDash */
  export const formatCoDD = formatCommasOrDoubleDash

  /** @TODO rename to formatCommasDecimals */
  export const formatCommaDecimals = (number: number) => numeral(number).format('0,0.00')
  formatCommaDecimals.fallback = makeNumberFallbackFn(formatCommaDecimals)
  export const formatCommasDecimalsOrDoubleDash = ifElse(formatCommaDecimals)(() => '--')
  /** formatCommasDecimalsOrDoubleDash */
  export const formatCDoDD = formatCommasDecimalsOrDoubleDash

  export const formatCommaOptionalDecimals = (number: number) => numeral(number).format('0,0[.]00')
  export const formatCommasOptionalDecimalsOrDoubleDash = ifElse(formatCommaOptionalDecimals)(() => '--')
  /** formatCommasOptionalDecimalsOrDoubleDash */
  export const formatCODoDD = formatCommasOptionalDecimalsOrDoubleDash

  export const formatCommaOptionalDecimal = (number: number) => numeral(number).format('0,0[.]0')
  export const formatCommasOptionalDecimalOrDoubleDash = ifElse(formatCommaOptionalDecimal)(() => '--')
  /** formatCommasOptionalDecimalsOrDoubleDash */
  export const formatCODo1DD = formatCommasOptionalDecimalOrDoubleDash

  export const formatKOptionalDecimals = (number: number) => numeral(number).format('0[.]00a')
  export const formatKOptionalDecimalsOrDoubleDash = ifElse(formatKOptionalDecimals)(() => '--')
  /** formatKOptionalDecimalsOrDoubleDash */
  export const formatKODoDD = formatKOptionalDecimalsOrDoubleDash

  export const formatPercent = (number: number) => numeral(number).format('0.00%')
  export const formatPercentOrDoubleDash = ifElse(formatPercent)(() => '--')
  /** formatPercentOrDoubleDash */
  export const formatPoDD = formatPercentOrDoubleDash

  export const formatNumberCoDDRounded = ifElse(createPipe(Math.round, formatComma))(() => '--')

  export const roundOrDoubleDash = ifElse(Math.round)(() => '--')

  export const fallback = makeNumberFallbackFn(identity)

  /**
   * @deprecated use NumberLib.ensureOrNull
   * @TODO rename to toNullIfUndefined? */
  export const ensureNumberOrNull = fallback(null)

  export const roundNPlaces = (noOfPlaces: number) => (x: number) => {
    const multiplier = Math.pow(10, noOfPlaces)
    return Math.ceil(x * multiplier) / multiplier
  }

  export const omitAfterNPlaces = (noOfPlaces: number) => (x: number): number => {
    const multiplier = Math.pow(10, noOfPlaces)
    return Math.trunc(x * multiplier) / multiplier
  }

  export const isOdd = (x: number) => x % 2 === 1

  export const preferNullOverZero = (x: number) => x === 0 ? null : x

  export const toNumberIfString = (x: string | number) => isString(x) ? fromStringSafe(x) : x
  export const toNumberIfStringUnstrict = (x: string | number | null | undefined) =>
    (isString(x) ? fromString(x) : x) ?? null
}
