import { createIcon } from '@chakra-ui/react'

export const ThumbsDownActiveMultiColorIcon = createIcon({
  displayName: 'ThumbsDownActiveMultiColorIcon',
  viewBox: '0 0 24 24',
  path: (
    <g fill='#7C0F0F'>
      <path
        d='M10 15.0004V19.0004C10 19.796 10.3161 20.5591 10.8787 21.1217C11.4413 21.6843 12.2044 22.0004 13 22.0004L17 13.0004V2.00036H5.72003C5.2377 1.99491 4.76965 2.16396 4.40212 2.47636C4.0346 2.78875 3.79235 3.22346 3.72003 3.70036L2.34003 12.7004C2.29652 12.987 2.31586 13.2797 2.39669 13.5581C2.47753 13.8365 2.61793 14.0941 2.80817 14.3128C2.99842 14.5316 3.23395 14.7064 3.49846 14.8251C3.76297 14.9439 4.05012 15.0036 4.34003 15.0004H10ZM17 2.00036H19.67C20.236 1.99035 20.7859 2.1885 21.2154 2.55718C21.645 2.92586 21.9242 3.43942 22 4.00036V11.0004C21.9242 11.5613 21.645 12.0749 21.2154 12.4435C20.7859 12.8122 20.236 13.0104 19.67 13.0004H17'
      />
      <path
        d='M17 2.00036H19.67C20.236 1.99035 20.7859 2.1885 21.2154 2.55718C21.645 2.92586 21.9242 3.43942 22 4.00036V11.0004C21.9242 11.5613 21.645 12.0749 21.2154 12.4435C20.7859 12.8122 20.236 13.0104 19.67 13.0004H17M10 15.0004V19.0004C10 19.796 10.3161 20.5591 10.8787 21.1217C11.4413 21.6843 12.2044 22.0004 13 22.0004L17 13.0004V2.00036H5.72003C5.2377 1.99491 4.76965 2.16396 4.40212 2.47636C4.0346 2.78875 3.79235 3.22346 3.72003 3.70036L2.34003 12.7004C2.29652 12.987 2.31586 13.2797 2.39669 13.5581C2.47753 13.8365 2.61793 14.0941 2.80817 14.3128C2.99842 14.5316 3.23395 14.7064 3.49846 14.8251C3.76297 14.9439 4.05012 15.0036 4.34003 15.0004H10Z'
        stroke='#ffffff'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
