import { useCallback, useRef } from 'react'

/**
 * Returns a `runOnce` function that will run the given function only once.
 *
 * Useful if you want to for example report an error only once somewhere
 * within a component, outside useEffect.
 */
export const useRunOnceCallback = () => {
  const hasRun = useRef(false)
  const runOnce = useCallback((fn: (...args: any[]) => void) => {
    if (hasRun.current) return

    hasRun.current = true

    fn()
  }, [])

  return runOnce
}
