import { Box, ButtonProps, Collapse, Flex, FlexProps, IconButton, IconButtonProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ChevronDownIcon, ChevronUpIcon } from 'presentation/components/Icons'
import { PeekingDrawerProvider, usePeekingDrawerStore } from 'presentation/components/PeekingDrawer/PeekingDrawer.store'
import { px } from 'presentation/utils/px'
import { PropsWithChildren, useLayoutEffect } from 'react'
import { useMeasure } from 'react-use'

export type PeekingDrawerProps = {
  placement?: 'top' | 'bottom'
  isOpen?: boolean
  onChange?: (isOpen: boolean) => void
} & Omit<FlexProps, 'onChange'>

export const PeekingDrawer = ({
  children,
  placement = 'top',
  isOpen = false,
  onChange,
  ...props
}: PeekingDrawerProps) => {
  const justifyContent = placement === 'top' ? 'start' : 'end'
  return (
    <PeekingDrawerProvider
      placement={placement}
      isOpen={isOpen}
      onChange={onChange}
    >
      <Flex
        flex={1}
        minH={0}
        flexDirection='column'
        justifyContent={justifyContent}
        h='100%'
        /**
         * @NOTE: This component takes up any available space of the parent whether
         * it is collapsed or expanded. If it is collapsed, and there are interactable
         * elements behind it, it should not block it.
         */
        pointerEvents='none'
        {...props}
      >
        {children}
      </Flex>
    </PeekingDrawerProvider>
  )
}

export type PeekingDrawerButtonProps = PropsWithChildren<ButtonProps>

export const PeekingDrawerButton = ({
  children,
  isDisabled,
  ...props
}: PeekingDrawerButtonProps) => {
  const setButtonHeight = usePeekingDrawerStore(api => api.setButtonHeight)
  const onToggle = usePeekingDrawerStore(api => api.onToggle)

  const [ref, { height }] = useMeasure<HTMLButtonElement>()

  useLayoutEffect(() => {
    setButtonHeight(height)
  }, [height])

  return (
    <Box
      as='button'
      w='full'
      ref={ref}
      onClick={onToggle}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      {...props}
    >
      {children}
    </Box>
  )
}

export type PeekingDrawerContentProps = PropsWithChildren

export const PeekingDrawerContent = ({
  children,
}: PeekingDrawerContentProps) => {
  const { isOpen, buttonHeight } = usePeekingDrawerStore(store => store)
  return (
    <Collapse
      in={isOpen}
      endingHeight={`calc(100% - ${px(buttonHeight)})`}
      style={{ pointerEvents: 'auto' }}
    >
      { children }
    </Collapse>
  )
}

export type PeekingDrawerIconProps = Omit<IconButtonProps, 'aria-label'>

export const PeekingDrawerIcon = (props: PeekingDrawerIconProps) => {
  const { isOpen, peekingPosition } = usePeekingDrawerStore(store => ({
    isOpen: store.isOpen,
    peekingPosition: store.placement,
  }))
  const icon = peekingPosition === 'top' ? <ChevronDownIcon /> : <ChevronUpIcon />
  return (
    <motion.div
      animate={{ rotate: isOpen ? 180 : 0 }}
      transition={{ duration: 0.3 }}
      style={{ pointerEvents: 'auto' }}
    >
      <IconButton
        as='div'
        variant='icon-ghost'
        size='md'
        icon={icon}
        aria-label='Peeking Drawer Icon'
        {...props}
      />
    </motion.div>
  )
}
