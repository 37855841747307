import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const NewListButton = () => (
  <Button
    variant='solid'
    colorScheme='positive'
    size='md'
    as={Link}
    to='/lists/builder'
  >
    New List
  </Button>
)
