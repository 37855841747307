const panicErrorTag = Symbol('panicErrorTag')

/**
 * PanicError is a special type of error that isn't expected to be handled
 * gracefully.
 *
 * @IMPORTANT Please be sure when throwing this within production / user facing
 *   code. Almost all user facing errors should be handled gracefully.
 */
export class PanicError extends Error {
  [panicErrorTag] = true
  name = 'PanicError'
}
