import { isNumber } from 'remeda'

export type Location = {
  latitude: number
  longitude: number
}

const isEqual = (a: Location, b: Location): boolean =>
  a.latitude === b.latitude && a.longitude === b.longitude

const makeSafe = (
  latitude: number | null | undefined,
  longitude: number | null | undefined,
): Location | null =>
  isNumber(latitude) && isNumber(longitude) ? { latitude, longitude } : null

export const Location = {
  ensureOrNull: (value: any): Location | null =>
    isNumber(value?.latitude) && isNumber(value?.longitude)
      ? value
      : null,
  isEqual,
  makeSafe,
  hasLocation: <T extends { location?: Location | null | undefined }>(
    value: T,
  ): value is T & { location: Location } => Boolean(value.location),
}
