import { textStyles } from '../../main/themes/common/textStyles.common.theme'

export const formLegacyTheme = {
  variants: {
    error: {
      container: {
        bg: 'negative.100',
        borderRadius: 1,
      },
      helperText: {
        ...textStyles.body2,
        textAlign: 'left',
        m: 0,
        px: 2,
        py: 0.5,
        color: 'negative.500',
      },
    },
    success: {
      container: {
        bg: 'positive.100',
        borderRadius: 1,
      },
      helperText: {
        ...textStyles.body2,
        textAlign: 'left',
        m: 0,
        px: 2,
        py: 0.5,
        color: 'positive.500',
      },
    },
  },
}
