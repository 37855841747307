import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import pluralize from 'pluralize'
import { SelectMenu, SelectMenuButton, SelectMenuList } from 'presentation/components/SelectMenu'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'

const OPTIONS = [
  0.25,
  0.5,
  0.75,
  1,
  1.5,
  3,
  4,
  5,
]

const DistanceFilterCard = () => {
  const distanceMiles = NearbyBuyersHooks.useDistanceMiles()
  const setDistanceMiles = NearbyBuyersHooks.useSetDistanceMiles()

  return (
    <FilterCard
      title='Distance'
    >
      <SelectMenu>
        <SelectMenuButton>
          {formatDistance(distanceMiles)}
        </SelectMenuButton>
        <SelectMenuList>
          <MenuOptionGroup value={distanceMiles.toString()}>
            {OPTIONS.map(option => (
              <MenuItemOption
                key={option}
                value={option.toString()}
                onClick={() => {
                  setDistanceMiles(option)
                }}
              >
                {formatDistance(option)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </SelectMenuList>
      </SelectMenu>
    </FilterCard>
  )
}

export default DistanceFilterCard

const formatDistance = (distance: number) => `${distance} ${pluralize('Mile', distance)}`
