import { Button, ButtonProps } from '@chakra-ui/react'
import { shallowEqual, useSelector } from '@xstate/react'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { CreateListActionModal } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/CreateListAction/CreateListActionModal'
import { useCreateListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/CreateListAction/useCreateListActionModal'
import { PropertySaveToListActionModal } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/PropertySaveToListActionModal'
import { usePropertySaveToListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/usePropertySaveToListModal'
import { useEffect } from 'react'

const PropertySaveToListAction = (props: ButtonProps) => {
  const saveListActions = usePropertySaveToListModalActions()
  const createListActions = useCreateListModalActions()
  const actor = useParcelListManager()
  const { parcelIsInAList, isLoading, hasList } = useSelector(actor, snapshot => ({
    isLoading: snapshot.matches('Loading'),
    parcelIsInAList: snapshot.context.parcelLists.length > 0,
    hasList: snapshot.context.hasLists,
  }), shallowEqual)

  useEffect(() => {
    actor.send({ type: 'lists.load' })
  }, [])

  const handle = () => {
    if (hasList)
      saveListActions.open()
    else
      createListActions.open()
  }

  return (
    <>
      <Button
        size='md'
        onClick={handle}
        isLoading={isLoading}
        colorScheme={parcelIsInAList ? 'neutral' : 'positive'}
        variant='solid'
        {...props}
      >
        {parcelIsInAList ? 'Change list' : 'Save to list'}
      </Button>
      <PropertySaveToListActionModal />
      <CreateListActionModal />
    </>
  )
}

export default PropertySaveToListAction
