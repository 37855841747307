import { Button, ButtonProps, Center, Flex, HStack, Spacer, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match, Option } from 'effect'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon, SkiptraceSuccessIcon } from 'presentation/components/Icons'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useEffect } from 'react'
import OwnerInfoCard from './components/OwnerInfoCard'
import PropertyOwnershipActivityAccordions from './components/PropertyOwnershipActivityAccordions'
import PropertyOwnershipActivityTable from './components/PropertyOwnershipActivityTable'

const OwnershipDetailsScreen = () => {
  const viewModel = OwnersPropertiesHooks.useScreen()
  const backButtonViewModel = OwnersPropertiesHooks.useBackButton()
  const skiptraceViewModel = OwnersPropertiesHooks.useSkiptraceButton()

  return (
    <>
      <Breakpoint
        mobSm={(
          <MobileHeader />
        )}
        tabSm={<></>}
      />
      <Flex
        flexDirection='column'
        gap='2'
        pt={{
          base: 2,
          mob: 3,
          tabSm: 0,
        }}
        pb={{
          base: 2,
          mob: 3,
        }}
        px={{
          base: 1,
          mob: 3,
        }}
        minH='full'
      >
        <HStack
          display={{
            base: 'none',
            tabSm: 'flex',
          }}
          justify='space-between'
          alignItems='center'
          py='2'
        >
          <BackToDetailsButton ml={-2} p={1} onClick={backButtonViewModel.onBack} />
          <SkiptraceButton onClick={skiptraceViewModel.onSkiptrace} />
        </HStack>

        {Match.value(viewModel).pipe(
          Match.tag('Success', viewModel => (
            <>
              <VStack
                spacing='2'
                alignItems='left'
              >
                <Text
                  textStyle={{
                    base: 'bodyXLFat',
                    tabSm: 'h4',
                  }}
                  color='neutral.500'
                  py='1'
                >
                  Owner&rsquo;s Info
                </Text>

                <OwnerInfoCard />
              </VStack>
              <VStack
                spacing='2'
                alignItems='left'
              >
                <Text
                  textStyle={{
                    base: 'bodyXLFat',
                    tabSm: 'h4',
                  }}
                  color='neutral.500'
                  py='1'
                >
                  Potential Properties Owned
                  {viewModel.potentialPropertiesCount.pipe(
                    Option.match({
                      onSome: count => ` (${count})`,
                      onNone: () => '',
                    }),
                  )}
                </Text>
                <Breakpoint
                  mobSm={(
                    <PropertyOwnershipActivityAccordions />
                  )}
                  tabSm={(
                    <PropertyOwnershipActivityTable />
                  )}
                />
              </VStack>
            </>
          )),
          Match.orElse(() => (
            <Center mt={7} flex='1'>
              <Spinner />
            </Center>
          )),
        )}
      </Flex>
    </>
  )
}

export default OwnershipDetailsScreen

const BackToDetailsButton = (props: ButtonProps) => (
  <Button
    size='lg'
    colorScheme='neutral'
    variant='ghost'
    leftIcon={(
      <ArrowLeftIcon boxSize='3' />
    )}
    {...props}
  >
    <Breakpoint
      mobSm='Back'
      tabSm='Back to details'
    />
  </Button>
)

const SkiptraceButton = (props: ButtonProps) => (
  <Button
    size='lg'
    colorScheme='positive'
    variant='solid'
    leftIcon={(
      <SkiptraceSuccessIcon boxSize='3' />
    )}
    {...props}
  >
    Skiptrace
  </Button>
)

const MobileHeader = () => {
  const setHeaderContents = useSetMobileHeaderContents()
  const backButtonViewModel = OwnersPropertiesHooks.useBackButton()
  const skiptraceViewModel = OwnersPropertiesHooks.useSkiptraceButton()

  useEffect(() => {
    setHeaderContents([
      <BackToDetailsButton
        colorScheme=''
        key='back'
        size='md'
        color='card.bg.1'
        ml='1'
        px={1}
        onClick={backButtonViewModel.onBack}
      />,
      <Spacer key='spacer' />,
      <SkiptraceButton
        key='skiptrace'
        size='md'
        mr='1'
        leftIcon={undefined}
        onClick={skiptraceViewModel.onSkiptrace}
      />,
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [])

  return null
}
