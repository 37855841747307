import { Context, Effect } from 'effect'
import stringify from 'json-stable-stringify'
import { LRUCache } from 'lru-cache'

class AppCache extends Context.Tag('AppCache')<
  AppCache,
  { cache: LRUCache<any, any> }
>() {}

namespace AppCache {
  export type Shape = Context.Tag.Service<AppCache>

  export type Request<TParams, A, E, R> =
    TParams extends void
      ? () => Effect.Effect<A, E, R>
      : (params: TParams) => Effect.Effect<A, E, R>

  export const cacheRequest = <TParams, A, E, R>(
    request: Request<TParams, A, E, R>,
    serialize: (params: TParams) => string = stringify,
  ): Request<TParams, A, E, R | AppCache> =>
    (params => Effect.gen(function * () {
      const { cache } = yield * AppCache
      const cacheKey = serialize(params)
      const cached = cache.get(cacheKey)
      if (cached) return cached as A
      const result = yield * request(params)
      cache.set(cacheKey, result)
      return result
    })) as Request<TParams, A, E, R>
}

export default AppCache
