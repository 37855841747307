import { Box, BoxProps, Flex, Grid, GridItem, Text, VStack, chakra, useToken } from '@chakra-ui/react'
import { Match, Option, pipe } from 'effect'
import NearbyBuyerPropertyHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerPropertyHooks'
import NearbyBuyerPropertyMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerPropertyMachine'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import NearbyBuyerPropertyMarkerPopup from 'features/NearbyBuyers/views/NearbyBuyersMaps/NearbyBuyerPropertyMarkerPopup'
import { useNearbyBuyersMarkerPopupWidth } from 'features/NearbyBuyers/views/NearbyBuyersMaps/useNearbyBuyersMarkerPopupWidth'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { Dollar } from 'libs/dollar'
import { Breakpoint } from 'presentation/components/Breakpoint'
import DraggablePanelContext from 'presentation/components/DraggablePanel/DraggablePanelContext'
import { Map, MapStoreProvider } from 'presentation/components/Map'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { MapHooks } from 'presentation/components/Map/MapHooks'
import { MLSStatusMapLegendItems } from 'presentation/components/MapLegend/MLSStatusMapLegendItems'
import { MapLegend } from 'presentation/components/MapLegend/MapLegend'
import { MapModeToggle } from 'presentation/components/MapModeToggle'
import { MapModeIconToggle } from 'presentation/components/MapModeToggle/MapModeIconToggle'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { SHADOWS } from 'presentation/main/themes/common/shadows.theme'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { px } from 'presentation/utils/px'
import { PropsWithChildren } from 'react'
import { Marker, NavigationControl as NavControl } from 'react-map-gl'

const NavigationControl = chakra(NavControl)

const NearbyBuyersMap = () => {
  const [size] = useToken('sizes', [2])
  const popupWidth = useNearbyBuyersMarkerPopupWidth()
  return (
    <MapStoreProvider>
      <Grid
        gridTemplateAreas='"layer"'
        h='full'
        sx={{
          '.mapboxgl-ctrl-top-right': {
            pt: 2,
            pr: 5,
          },
        }}
      >
        <GridItem
          gridArea='layer'
          zIndex='0'
          sx={{
            '& .mapboxgl-popup': {
              width: px(popupWidth),
            },
            '& .mapboxgl-popup-content': {
              padding: 0,
              background: 'none',
              width: px(popupWidth),
            },
            '& .mapboxgl-popup-tip, & .mapboxgl-popup-close-button': {
              display: 'none',
            },
          }}
        >
          <NearbyBuyersBase />
        </GridItem>
        <GridItem
          gridArea='layer'
          zIndex='1'
          {...mbpg({
            mobSm: {
              justifySelf: 'flex-end',
              alignSelf: 'flex-end',
              // @NOTE: might want to get panel height from context
              mb: `calc(${size} + ${px(DraggablePanelContext.defaultPanelHeight)})`,
              mr: 2,
            },
            tabSm: {
              justifySelf: 'flex-start',
              alignSelf: 'flex-end',
              ml: 3,
              mb: 3,
            },
          })}
        >
          <Breakpoint
            mobSm={<MapModeIconToggle />}
            tabSm={(
              <VStack gap={1}>
                <MapLegend>
                  <MLSStatusMapLegendItems />
                </MapLegend>
                <MapModeToggle />
              </VStack>
            )}
          />
        </GridItem>
      </Grid>
    </MapStoreProvider>
  )
}

export default NearbyBuyersMap

const NearbyBuyersBase = () => {
  const mapMountEvents = NearbyBuyersHooks.useMapMountEvents()

  MapHooks.useOnMapReady(ref => {
    mapMountEvents.onMount(ref)

    return mapMountEvents.onUnmount
  })

  return (
    <Box
      boxSize='full'
      sx={{
        // prevent marker from blocking hover events of overlapping markers
        // pointer event is to be restored in actual area of marker and popup
        '.mapboxgl-marker': {
          pointerEvents: 'none !important',
        },
      }}
    >
      <Map
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Breakpoint
          tabSm={<NavigationControl pr={3} pt={3} />}
        />

        <NearbyBuyerMarkers />
      </Map>
    </Box>
  )
}

const NearbyBuyerMarkers = () => {
  const actorRefs = NearbyBuyersHooks.usePropertyActorRefsArray()

  return (
    <>
      {actorRefs.map(actorRef => (
        <NearbyBuyerMarker key={actorRef.id} actorRef={actorRef} />
      ))}
    </>
  )
}

const NearbyBuyerMarker = ({ actorRef }: { actorRef: NearbyBuyerPropertyMachine.ActorRef }) => {
  const setup = NearbyBuyerPropertyHooks.useSetup(actorRef)
  const property = NearbyBuyerPropertyHooks.useProperty(actorRef)
  const isHighlighted = NearbyBuyerPropertyHooks.useIsHighlighted(actorRef)
  const isSubject = NearbyBuyerPropertyHooks.useIsSubject(actorRef)
  const price = NearbyBuyerPropertyHooks.useLatestDealPrice(actorRef)
  const subjectMlsStatus = NearbyBuyersHooks.useSubjectMlsStatus()
  const highlightedFilter = isHighlighted
    ? 'drop-shadow(30px 30px 30px rgba(0, 0, 0, 0.125))'
    : 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
  const handleClick = NearbyBuyerPropertyHooks.useOnClick(actorRef)

  return (
    <Marker
      latitude={property.location.latitude}
      longitude={property.location.longitude}
      ref={element => {
        if (element) setup(element.getElement())
      }}
      anchor='bottom'
      style={{
        zIndex: isHighlighted && isSubject
          ? MAP_ELEMENT__Z_INDICES.SELECTED_SUBJECT_MARKER
          : isHighlighted
            ? MAP_ELEMENT__Z_INDICES.SELECTED_MARKER
            : isSubject
              ? MAP_ELEMENT__Z_INDICES.SUBJECT_MARKER
              : MAP_ELEMENT__Z_INDICES.MARKER,
      }}
    >
      <VStack
        spacing={0.5}
        align='center'
        pos='relative'
      >
        {isHighlighted && (
          <Box
            // restore pointer event for this interactable part
            pointerEvents='all'
          >
            {isSubject
              ? (
                <NearbyBuyerPropertyMarkerPopup property={property} />
              )
              : (
                <MarkerPrice status={subjectMlsStatus}>
                  {price.pipe(
                    Option.map(Dollar.formatMax3Digits),
                    Option.getOrElse(() => '--'),
                  )}
                </MarkerPrice>
              )}
          </Box>
        )}

        <Box
          // restore pointer event for this interactable part
          pointerEvents='all'
          filter={highlightedFilter}
        >
          {isSubject
            ? (
              <SubjectPropertyMarker
                markerType='pin'
                size={isHighlighted ? 'lg' : 'md'}
                onClick={handleClick}
              />
            )
            : (
              <PropertyMarker
                classification={property.ownershipInfo.classification.pipe(Option.getOrNull)}
                equityType={property.valueInfo.equityType.pipe(Option.getOrNull)}
                isForeclosure={property.valueInfo.isForeclosure}
                isSenior={property.ownershipInfo.isSenior}
                isVacant={property.ownershipInfo.isVacant}
                markerType='pin'
                size={isHighlighted ? 'lg' : 'md'}
                onClick={handleClick}
              />
            )}
        </Box>
      </VStack>
    </Marker>
  )
}

const MarkerPrice = (props: PropsWithChildren<{
  status: MLSStatus
}>) => {
  const mlsStatusProps = getMLSStatusMarkerColor(props.status)

  return (
    <VStack
      gap={0}
      w='fit-content'
      align='center'
    >
      <Flex
        gap={0.5}
        px={1}
        py={0.5}
        minW={6}
        minH={3.5}
        alignItems='center'
        justifyContent='center'
        pos='relative'
        zIndex='1'
        borderRadius={1.5}
        boxShadow='float'
        borderColor={mlsStatusProps.color}
        borderWidth={0.125}
        {...mlsStatusProps}
      >
        <Text color='inherit' textStyle='tagL' textAlign='center'>
          {props.children}
        </Text>
      </Flex>

      <ArrowDown
        width='20px'
        height='8px'
        pos='relative'
        top='-1px'
        zIndex={1}
        {...mlsStatusProps}
      />
    </VStack>
  )
}

const ArrowDown = ({
  isHovered,
  color,
  bgColor,
  ...props
}: MLSStatusMarkerColorProps & { isHovered?: boolean }) => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 8'
    fill='none'
    filter={`drop-shadow(${SHADOWS.float})`}
    {...props}
  >
    <chakra.path
      d='M8.75061 7.52867C9.48105 8.15711 10.519 8.15711 11.2494 7.52867L20 1.43051e-06H0L8.75061 7.52867Z'
      fill={bgColor}
    />
    <chakra.path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.1492 1.07545L10.6247 6.68889C10.2595 7.00311 9.74052 7.00311 9.3753 6.68889L2.85078 1.07545H17.1492ZM11.2494 7.52867C10.519 8.15711 9.48105 8.15711 8.75061 7.52867L0 1.43051e-06H20L11.2494 7.52867Z'
      fill={color}
    />
    <chakra.path
      d='M2.85937 1.07545H17.1523L18.5 0H1.62717L2.85937 1.07545Z'
      fill={bgColor}
    />
  </chakra.svg>
)

/**
 * @NOTE: this is a duplicate from CompsMarker. Should make this an official
 * utility
 */
export type MLSStatusMarkerColorProps = {
  color: string
  bgColor: string
} & Omit<BoxProps, 'color' | 'bgColor'>

export const getMLSStatusMarkerColor = (
  status: MLSStatus,
): MLSStatusMarkerColorProps => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('FOR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('SALE_OR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
  })),
  Match.when('PENDING', () => ({
    color: 'marker.oxfordblue',
    bgColor: 'marker.babyblue',
  })),
  Match.when('SOLD', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
  })),
  Match.when('LEASED', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
  })),
  Match.when('EXPIRED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('CANCELED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('WITHDRAWN', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
  })),
  Match.when('OFF_MARKET', () => ({
    color: 'marker.dirtywhite',
    bgColor: 'marker.darkgray',
  })),
  Match.exhaustive,
)
