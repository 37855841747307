import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const OwnerOccupiedIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M14.0000104,14 C18.7776104,14 21.4162104,16.4693 22.6034104,18.7231 C23.4248104,20.2824 22.9696104,22.2976 21.2447104,23.1499 C19.7585104,23.8843 17.4179104,24.5 14.0000104,24.5 C10.5821104,24.5 8.24151044,23.8843 6.75541044,23.1499 C5.03041044,22.2976 4.57521044,20.2824 5.39661044,18.7231 C6.58391044,16.4693 9.22241044,14 14.0000104,14 Z M14.0000104,16.3333 C10.1854104,16.3333 8.29591044,18.2353 7.46721044,19.8085 C7.18161044,20.3507 7.38601044,20.8577 7.79351044,21.059 C8.88351044,21.5976 10.8502104,22.1667 14.0000104,22.1667 C17.1498104,22.1667 19.1165104,21.5976 20.2065104,21.059 C20.6140104,20.8577 20.8185104,20.3507 20.5329104,19.8085 C19.7042104,18.2353 17.8147104,16.3333 14.0000104,16.3333 Z M14.0000104,3.5 C14.7719104,3.5 15.9037104,3.7214 16.8762104,4.4199 C17.9083104,5.1612 18.6781104,6.3864 18.6781104,8.1667 C18.6781104,9.9469 17.9083104,11.1722 16.8762104,11.9135 C15.9037104,12.6119 14.7719104,12.8333 14.0000104,12.8333 C13.2281104,12.8333 12.0963104,12.6119 11.1238104,11.9135 C10.0917104,11.1722 9.32201044,9.9469 9.32201044,8.1667 C9.32201044,6.3864 10.0917104,5.1612 11.1238104,4.4199 C12.0963104,3.7214 13.2281104,3.5 14.0000104,3.5 Z M14.0000104,5.8333 C13.6024104,5.8333 12.9800104,5.9619 12.4905104,6.3135 C12.0607104,6.6222 11.6610104,7.1469 11.6610104,8.1667 C11.6610104,9.1864 12.0607104,9.7112 12.4905104,10.0199 C12.9800104,10.3714 13.6024104,10.5 14.0000104,10.5 C14.3976104,10.5 15.0201104,10.3714 15.5095104,10.0199 C15.9393104,9.7112 16.3390104,9.1864 16.3390104,8.1667 C16.3390104,7.1469 15.9393104,6.6222 15.5095104,6.3135 C15.0201104,5.9619 14.3976104,5.8333 14.0000104,5.8333 Z'
      fill='marker.teal'
    />
  </MarkerIconContainer>
)
