import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { gql } from '__generated__'
import { DefaultPaymentMethodSegment__PaymentMethodFragmentFragment } from '__generated__/graphql'
import dayjs from 'dayjs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { CreditCardIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { CardSegment } from 'presentation/components/molecules/Card/CardCombo'
import { openUpdatePaymentModal } from 'presentation/main/globalModals/UpdatePaymentModal/openUpdatePaymentModal'
import { openAddPaymentModal } from 'presentation/screens/Billing/Billing.modals'
import { CardLogo } from 'presentation/screens/Billing/components/PaymentCard/CardLogo/CardLogo'
import { formatCardsPaymentMethodBrands } from 'presentation/screens/Billing/formatCardsPaymentMethodBrands'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export const DEFAULT_CARD_SEGMENT__PAYMENT_METHOD_FRAGMENT = gql(/* GraphQL */ `
  fragment DefaultPaymentMethodSegment__PaymentMethodFragment on BillingCardsPaymentMethod {
    id
    brand
    last4
    expiration
  }
`)

export const DefaultPaymentMethodSegment: FC<{
  paymentMethod?: DefaultPaymentMethodSegment__PaymentMethodFragmentFragment
}> = props => {
  const paymentMethod = props.paymentMethod

  if (!paymentMethod) {
    return (
      <CardSegment
        px={mbp({ mobSm: '2', tabSm: '4' })}
        py={mbp({ mobSm: '2', tabSm: '3' })}
        bg='negative.50'
        borderTopRadius={mbp({ mobSm: '2', tabSm: '4' })}
      >
        <Stack direction={mbp({ mobSm: 'column', tabSm: 'row' })} align='center' spacing='2'>
          <Text flex='1' textStyle='body2-b' color='graystrong.400'>
            <TriangleExclamationIcon fontSize='20px' color='negative.500' mr='1' />
            {' '}
            No payment method on file
          </Text>

          <Button
            variant='solid'
            colorScheme='neutral'
            leftIcon={<CreditCardIcon fontSize='2' />}
            onClick={() => openAddPaymentModal()}
            size='sm'
          >
            Add Payment Method
          </Button>
        </Stack>
      </CardSegment>
    )
  }

  return (
    <CardSegment
      bg='card.bg.2'
      px={mbp({ mobSm: '2', tabSm: '4' })}
      py={mbp({ mobSm: '2', tabSm: '3' })}
      borderTopRadius={mbp({ mobSm: '2', tabSm: '4' })}
    >
      <Flex align='center'>
        {paymentMethod.brand && (
          <CardLogo
            brand={paymentMethod.brand}
            w={mbp({ mobSm: '35px', mob: '47px', tabSm: '58px' })}
            h={mbp({ mobSm: '24px', mob: '32px', tabSm: '40px' })}
          />
        )}

        <Box ml={mbp({ mobSm: '1', mob: '2', tabSm: '3' })} flex='1'>
          <Text textStyle='body2-b' color='graystrong.400'>
            <Breakpoint
              mobSm={(
                <>
                  ****
                  {' '}
                  {paymentMethod.last4}
                </>
              )}
              tabSm={(
                <>
                  {paymentMethod.brand && (
                    <>
                      {formatCardsPaymentMethodBrands(paymentMethod.brand)}
                      {' '}
                    </>
                  )}
                  {' '}
                  ending in
                  {' '}
                  {paymentMethod.last4}
                </>
              )}
            />
          </Text>
          <Text
            textStyle={mbp({ mobSm: 'body3', mob: 'body2' })}
            color='graystrong.400'
          >
            Expires
            {' '}
            {dayjs(paymentMethod.expiration, 'M/YYYY').format('MM/YY')}
          </Text>
        </Box>

        <Button
          variant='ghost'
          colorScheme='neutral'
          leftIcon={<CreditCardIcon fontSize='2' />}
          onClick={openUpdatePaymentModal}
          size='xs'
        >
          <Breakpoint
            mobSm='Change card'
            mob='Change credit card'
          />
        </Button>
      </Flex>
    </CardSegment>
  )
}
