import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import fragmentMatcher from '__generated__/fragment-matcher'
import { getAccessStoreSingleton } from 'features/Access/infra/react/AccessState'
import ky from 'ky'
import { TestSession } from 'libs/TestSession'
import { API_URL, IS_DEV_ENV } from 'presentation/const/env.const'
import { RESTLib } from 'presentation/libs/REST'

if (typeof API_URL === 'undefined') throw new Error('API_URL missing')

const TIMEOUT = 60000

// ========================================
// REST client
// ========================================

/**
 * Authenticated version of API, not to be used with login/register calls
 */
export const restClient = ky.extend({
  prefixUrl: API_URL,
  credentials: 'include',
  timeout: TIMEOUT,
  headers: {
    accept: 'application/json',
  },
  hooks: {
    beforeRequest: [
      request => {
        if (TestSession.getCookie())
          request.headers.set('cookie', TestSession.getCookie() || '')
      },
    ],
    afterResponse: [
      // capture parsed data for error reporting
      async (request, _, response) => {
        RESTLib.makeRequestReparseable(request)
        RESTLib.makeResponseReparseable(response)
        return response
      },

      // handle logged out state
      (_request, _options, response) => {
        void getAccessStoreSingleton()
          .then(async store => {
            const jsonResponse = await response.json()

            const isLoggedOutBasedOnResponse = response.status === 401
              && jsonResponse?.error?.type === 'authentication_error'

            const access = store.getState().local.access
            const isLoggedInBasedOnState = access.status === 'loaded'
              && access.data.status === 'logged-in'

            if (isLoggedOutBasedOnResponse && isLoggedInBasedOnState) {
              void getAccessStoreSingleton()
                .then(async store => await store.getState().actions.logout.execute())
            }
          })
      },
    ],
  },
})

// ========================================
// Apollo client
// ========================================

const httpLink = new HttpLink({ uri: `${API_URL}/graphql`, credentials: 'include' })

const testSessionMiddleware = new ApolloLink((operation, forward) => {
  if (TestSession.getCookie()) {
    operation.setContext({
      headers: {
        cookie: TestSession.getCookie() || '',
      },
    })
  }

  return forward(operation)
})

const errorLink = onError(({ networkError }) => {
  void getAccessStoreSingleton()
    .then(store => {
      const isLoggedOutBasedOnResponse = (networkError as any)?.statusCode === 404

        && (networkError as any)?.result?.error?.type === 'invalid_request_error'

      const access = store.getState().local.access
      const isLoggedInBasedOnState = access.status === 'loaded'
        && access.data.status === 'logged-in'

      if (isLoggedOutBasedOnResponse && isLoggedInBasedOnState) {
        void getAccessStoreSingleton()
          .then(async store => await store.getState().actions.logout.execute())
      }
    })
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, testSessionMiddleware, httpLink]),
  connectToDevTools: IS_DEV_ENV,
  cache: new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies: {
      Enterprise: {
        fields: {
          invoices: relayStylePagination(),
        },
      },
      Wallet: {
        fields: {
          transactions: relayStylePagination(),
        },
      },
      MlsPropertyListing: {
        keyFields: ['id'],
        fields: {
          resoPropertyListing: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
})
