import { Box, Center, IconButton, Spacer, VStack } from '@chakra-ui/react'
import { useLists } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PlusIcon } from 'presentation/components/Icons'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { HeaderMobileTitle } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobile'
import { HeaderMobileDrawer } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/HeaderMobileDrawer'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useToastFailedState } from 'presentation/libs/hooks/useToastFailedState'
import { ListsFooter, useIsListsFooterReady } from 'presentation/screens/ListsScreen/component/ListsFooter/ListsFooter'
import { ListsHeader } from 'presentation/screens/ListsScreen/component/ListsHeader/ListsHeader'
import { ListsTabs } from 'presentation/screens/ListsScreen/component/ListsTabs/ListsTabs'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

const MAX_WIDTH = 1012

export const ListsScreen = () => {
  const isFooterReady = useIsListsFooterReady()

  useToastFailedState(useLists(), {
    title: 'Failed to Load List Manager.',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useListsMobileHeaderContents()

  const outerPadding = mbp({
    mobSm: 1,
    mob: 2,
    tabSm: 3,
    dtSm: 5,
  })

  return (
    <VStack
      bg='neutral.100'
      minH='layout-height'
      align='center'
      justify='flex-start'
      flex='1'
      w='full'
      p={0}
      pt={outerPadding}
    >
      <Breakpoint
        dtLg={(
          <Center
            w='full'
            px={outerPadding}
          >
            <Box w='full' maxW={px(MAX_WIDTH)}>
              <ListsHeader />
            </Box>
          </Center>
        )}
      />

      <Center
        w='full'
        px={outerPadding}
      >
        <Box w='full' maxW={px(MAX_WIDTH)}>
          <ListsTabs />
        </Box>
      </Center>

      <Spacer minH={2} />

      {isFooterReady && (
        <Center
          w='full'
          position='sticky'
          bottom={0}
          zIndex='sticky-2'
          bg='neutral.100'
          shadow='vscroll-fade-bottom'
        >
          <Box w='full' maxW={px(MAX_WIDTH)}>
            <ListsFooter />
          </Box>
        </Center>
      )}
    </VStack>
  )
}

const useListsMobileHeaderContents = () => {
  const setHeaderContents = useSetMobileHeaderContents()

  useEffect(() => {
    setHeaderContents([
      <HeaderMobileDrawer key='lists-left' ml={1} />,
      <HeaderMobileTitle
        key='lists-center'
        flex='1'
        display='flex'
        justifyContent='center'
      >
        List Manager
      </HeaderMobileTitle>,
      <IconButton
        aria-label='Create New List'
        size='md'
        key='lists-right'
        variant='icon-ghost'
        colorScheme='ondark'
        as={Link}
        to='/lists/builder'
        mr={1}
      >
        <PlusIcon />
      </IconButton>,
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [])
}
