import { Box, IconButton, Menu, MenuButton, MenuItemOption, MenuOptionGroup, Portal } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { SortDownIcon } from 'presentation/components/Icons'
import { Radio } from 'presentation/components/Radio/Radio'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { HasFilterIndicator } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/HasFilterIndicator'
import { SearchHistorySortMenuProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistorySortMenu/SearchHistorySortMenu.props'
import { shallow } from 'zustand/shallow'

export const SearchHistorySortMenu = ({ colorScheme, ...props }: SearchHistorySortMenuProps) => {
  const store = useSearchHistoryStore(api => ({
    sorting: api.local.sorting,
    sort: api.actions.sort,
    isUpdating: api.remote.history.status === 'updating',
  }), shallow)

  const DEFAULT_SORTING: SearchHistory.Sorting = 'search-date-new-first'
  const showIndicator = store.sorting !== DEFAULT_SORTING

  const iconColor = pipe(
    Match.value(colorScheme),
    Match.when('sidepanel', () => showIndicator ? 'accent.darkblue' : 'graystrong.600'),
    Match.when('drawer', () => showIndicator ? 'highlight.500' : 'ondark.1'),
    Match.exhaustive,
  )

  return (
    <Box position='relative' w='min-content' {...props}>
      <Menu>
        <MenuButton
          as={IconButton}
          position='relative'
          variant='icon-ghost'
          size='md'
          colorScheme='onlight'
          isDisabled={store.isUpdating}
          icon={(
            <SortDownIcon
              boxSize={3}
              color={iconColor}
            />
          )}
        />
        {showIndicator && <HasFilterIndicator />}

        <Portal>
          <ResponsiveMenuList>
            <MenuOptionGroup
              title='Sort by:'
              value={store.sorting}
            >
              {OPTIONS.map(([sorting, label]) => (
                <MenuItemOption
                  key={sorting}
                  type='checkbox'
                  value={sorting}
                  onClick={ev => {
                    /**
                   * @HACK We're not supposed to use checkbox with MenuItemOption
                   * but because we because of speed somehow the cause the click
                   * to fire twice. Ignore the click if the target is an input
                   * to fix the double fire.
                   */
                    if (ev.target instanceof HTMLInputElement) return
                    void store.sort(sorting)
                  }}
                >
                  <Radio
                    tabIndex={-1}
                    isChecked={store.sorting === sorting}
                  >
                    {label}
                  </Radio>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </ResponsiveMenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

const OPTIONS: [SearchHistory.Sorting, string][] = [
  ['search-date-new-first', 'Search Date, newest first'],
  ['search-date-old-first', 'Search Date, oldest first'],
  ['street-name-a-to-z', 'Street Name, A to Z'],
  ['street-name-z-to-a', 'Street Name, Z to A'],
  ['street-number-low-first', 'Street Number, lowest first'],
  ['street-number-high-first', 'Street Number, highest first'],
]
