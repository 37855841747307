import { FormControl, FormErrorMessage, FormLabel, Input, ModalOverlay } from '@chakra-ui/react'
import { useSelector } from '@xstate/react'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useCreateListActionModal, useCreateListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/CreateListAction/useCreateListActionModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { noop } from 'remeda'

type SaveListForm = {
  listName: string
}

export const CreateListActionModal = () => {
  const modalActions = useCreateListModalActions()
  const modalState = useCreateListActionModal()
  const formApi = useForm<SaveListForm>({ defaultValues: { listName: '' } })
  const actor = useParcelListManager()
  const { isCreating } = useSelector(actor, snapshot => ({
    isCreating: snapshot.matches({ Loaded: 'Creating' }),
  }))

  useEffect(() => {
    if (modalState.status === 'open')
      formApi.resetField('listName')
  }, [modalState.status])

  useEffect(() => {
    if (!isCreating)
      modalActions.close()
  }, [isCreating])

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={isCreating ? noop : modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        <Card
          as='form'
          variant='modal.alert'
          size='modal.alert.xn'
          colorScheme='modal.alert.neutral'
          onSubmit={formApi.handleSubmit(formValues => {
            actor.send({
              type: 'lists.create',
              listName: formValues.listName,
            })
          })}
        >
          <CardImage
            src={happyDogPng}
            display={mbp({ mobSm: 'none', tabSm: 'block' })}
          />
          <ModalCloseButton onClose={close} />
          <CardPrimaryTitle color='neutral.500'>
            Create a List
          </CardPrimaryTitle>
          <CardPrimaryText>
            Create one now to start saving your properties!
          </CardPrimaryText>
          <CardBody mt={mbp({ mobSm: 2, tabSm: 5 })}>
            <FormControl isInvalid={!!formApi.formState.errors.listName}>
              <FormLabel>List Name</FormLabel>
              <Input
                type='text'
                {...formApi.register('listName', {
                  required: 'List name is required',
                })}
              />
              {formApi.formState.errors.listName && (
                <FormErrorMessage>
                  {formApi.formState.errors.listName.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </CardBody>
          <CardFooter
            gap={mbp({ mobSm: 2, tabSm: 3 })}
          >
            <CardButton
              variant='outline'
              colorScheme='neutral'
              size='md'
              onClick={modalActions.close}
              autoFocus
              isDisabled={isCreating}
            >
              Cancel
            </CardButton>
            <CardButton
              variant='solid'
              colorScheme='positive'
              size='md'
              autoFocus
              type='submit'
              isLoading={isCreating}
            >
              Create
            </CardButton>
          </CardFooter>

        </Card>
      </ModalContent>
    </Modal>
  )
}
