import { useCallback, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { create } from 'zustand'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type ModalState<TPayload extends unknown = void> =
  | { status: 'closed' }
  | { status: 'open', payload: TPayload extends void ? null : TPayload }

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type ModalActions<TPayload extends unknown = void> = {
  open: (payload: TPayload extends void ? void : TPayload) => void
  close: () => void
  closeReplace: () => void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type ModalAPI<TPayload extends unknown = void> = {
  actions: ModalActions<TPayload>
  state: ModalState<TPayload>
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const createHistoryUseModal = <TPayload extends unknown = void>(key: string) => () => {
  const navigate = useNavigate()
  const location = useLocation()
  const locationRef = useRef(location)
  locationRef.current = location

  const open = useCallback((payload: TPayload extends void ? void : TPayload) => {
    const location = locationRef.current
    const isAlreadyOpen = location.state?.modal === key

    if (isAlreadyOpen) return

    navigate(location.pathname, {
      state: {
        ...location.state,
        modal: key,
        payload,
      },
    })
  }, [])

  const close = useCallback(() => {
    const isAlreadyClosed = locationRef.current.state?.modal !== key

    if (isAlreadyClosed) return

    navigate(-1)
  }, [])

  const closeReplace = useCallback(() => {
    const isAlreadyClosed = locationRef.current.state?.modal !== key

    if (isAlreadyClosed) return

    navigate(location.pathname, {
      state: {
        ...location.state,
        modal: null,
      },
      replace: true,
    })
  }, [])

  const isOpen = location.state?.modal === key

  return useMemo(() => ({
    actions: {
      open,
      close,
      closeReplace,
    },
    state: isOpen
      ? {
        status: 'open' as const,
        payload: location.state?.payload ?? null,
      }
      : { status: 'closed' as const },
  }) as ModalAPI<TPayload>, [
    isOpen,
    open,
    close,
    closeReplace,
  ])
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const createStoreUseModal = <TPayload extends unknown = void>() =>
  create<ModalAPI<TPayload>>(set => ({
    actions: {
      open: (payload: TPayload extends void ? void : TPayload) => set(() => ({
        state: {
          status: 'open',
          payload: payload ?? null as any,
        },
      })),
      close: () => set({ state: { status: 'closed' } }),
      closeReplace: () => set(() => ({ state: { status: 'closed' } })),
    },
    state: { status: 'closed' },
  }))

export const Modals = {
  createHistoryUseModal,
  createStoreUseModal,
}
