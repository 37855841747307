import { Function, Match } from 'effect'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { nanoid } from 'nanoid'
import { useCallback, useMemo, useRef, useState } from 'react'

/**
 * The road to hell is paved with good intentions and typescript generics
 * - Proverb
 */
export namespace CQReact {
  type UseStateManager = {
    <TData, TError extends Error, TPayload>(
      createInitialState: () => CQ.State<TData, TError, TPayload>,
    ): {
      state: CQ.State<TData, TError, TPayload>
      transition: {
        idle: () => CQ.State.Idle

        loading: (params: {
          id: string
          payload: TPayload
          data?: TData
        }) => CQ.State.Loading<TData, TPayload>

        success: (params: {
          id: string
          data: TData
          payload: TPayload
        }) => CQ.State.Success<TData, TPayload>

        error: (params: {
          id: string
          error: TError
          payload: TPayload
          data?: TData
        }) => CQ.State.Error<TData, TError, TPayload>
      }
    }

    <TError extends Error, TPayload>(
      createInitialState: () => CQ.State<never, TError, TPayload>,
    ): {
      state: CQ.State<never, TError, TPayload>
      transition: {
        idle: () => CQ.State.Idle

        loading: (params: {
          id: string
          payload: TPayload
        }) => CQ.State.Loading<never, TPayload>

        success: (params: {
          id: string
          payload: TPayload
        }) => CQ.State.Success<never, TPayload>

        error: (params: {
          id: string
          error: TError
          payload: TPayload
        }) => CQ.State.Error<never, TError, TPayload>
      }
    }

    <TData, TError extends Error>(
      createInitialState: () => CQ.State<TData, TError, never>,
    ): {
      state: CQ.State<TData, TError, never>
      transition: {
        idle: () => CQ.State.Idle

        loading: (params: {
          id: string
          data?: TData
        }) => CQ.State.Loading<TData, never>

        success: (params: {
          id: string
          data: TData
        }) => CQ.State.Success<TData, never>

        error: (params: {
          id: string
          error: TError
          data?: TData
        }) => CQ.State.Error<TData, TError, never>
      }
    }

    <TError extends Error>(
      createInitialState: () => CQ.State<never, TError, never>,
    ): {
      state: CQ.State<never, TError, never>
      transition: {
        idle: () => CQ.State.Idle

        loading: (params: {
          id: string
        }) => CQ.State.Loading<never, never>

        success: (params: {
          id: string
        }) => CQ.State.Success<never, never>

        error: (params: {
          id: string
          error: TError
        }) => CQ.State.Error<never, TError, never>
      }
    }
  }

  /**
   * @TODO
   *   #1. Observe if we can get away with not returning state from transitions
   *   so that everybody just uniformly relies on the state returned from the
   *   hook.
   *
   *   #2. We might have to rely on return values from transitions because we some
   *   command/query may or may not have idle/loading states.
   *
   *   #3. Given #2, we can just create further hooks like useQueryState or
   *   useOptimisticCommandState that relies on useCQState.
   */
  export const useStateManager: UseStateManager = <TData, TError extends Error, TPayload>(
    createInitialState: () => CQ.State<TData, TError, TPayload>,
  ) => {
    type StateRecord = Partial<Record<string, CQ.State<TData, TError, TPayload>>>

    const stateRecordRef = useRef<StateRecord>({})
    const initialState = useMemo(() => createInitialState(), [])
    const [state, setState] = useState<CQ.State<TData, TError, TPayload>>(initialState)

    const getInitialTimestamp = (id: string) => {
      const existing = stateRecordRef.current[id]
      return existing && 'meta' in existing
        ? existing.meta.initialTimestamp
        : 'meta' in initialState
          ? initialState.meta.initialTimestamp
          : Date.now()
    }

    return {
      state,
      transition: {
        idle: (): CQ.State.Idle => {
          const state: CQ.State.Idle = CQ.createIdleState()
          setState(state)
          return state
        },

        loading: (params: {
          id: string
          data?: TData
          payload?: TPayload
        }): CQ.State.Loading<TData, TPayload> => {
          const existing = stateRecordRef.current[params.id]

          if (existing && CQ.isLoading(existing)) return existing

          const state = {
            status: 'loading' as const,
            data: params.data
              ? params.data
              : existing && 'data' in existing
                ? existing.data
                : null,
            meta: {
              id: params.id,
              initialTimestamp: getInitialTimestamp(params.id),
              currentTimestamp: Date.now(),
              payload: 'payload' in params ? params.payload : null,
            },
          } as CQ.State.Loading<TData, TPayload>

          stateRecordRef.current[params.id] = state
          setState(state as CQ.State<TData, TError, TPayload>)

          return state
        },

        success: (params: {
          id: string
          data?: TData
          payload?: TPayload
        }): CQ.State.Success<TData, TPayload> => {
          const existing = stateRecordRef.current[params.id]

          if (existing && CQ.isSuccess(existing)) return existing

          const state = {
            status: 'success' as const,
            data: params.data ? params.data : null,
            meta: {
              id: params.id,
              initialTimestamp: getInitialTimestamp(params.id),
              currentTimestamp: Date.now(),
              payload: 'payload' in params ? params.payload : null,
            },
          } as CQ.State.Success<TData, TPayload>

          stateRecordRef.current[params.id] = state
          setState(state as CQ.State<TData, TError, TPayload>)

          return state
        },

        error: (params: {
          id: string
          error: TError
          data?: TData
          payload?: TPayload
        }): CQ.State.Error<TData, TError, TPayload> => {
          const existing = stateRecordRef.current[params.id]

          if (existing && CQ.isError(existing)) return existing

          const state = {
            status: 'error' as const,
            data: params.data ? params.data : null,
            error: params.error,
            meta: {
              id: params.id,
              initialTimestamp: getInitialTimestamp(params.id),
              currentTimestamp: Date.now(),
              payload: 'payload' in params ? params.payload : null,
            },
          } as CQ.State.Error<TData, TError, TPayload>

          stateRecordRef.current[params.id] = state
          setState(state as CQ.State<TData, TError, TPayload>)

          return state
        },
      },

    /** Due to limitation of generics, we have to cast to any in the end */
    } as any
  }

  /**
   * Creates ID which updates when reference to data or error changes. Does not
   * create ID when result just changed from undefined to defined (usually when
   * loading transitions to success/error).
   *
   * Useful for React Query and Apollo's query hooks. SHOULD NOT USE for
   * mutation hooks, create ID upon execution of mutation instead.
   *
   * @TODO Allow manually regenerating ID for executable queries, where you
   *   want to generate ID BOTH when data changes and when query is executed.
   */
  export const useResultWatchId = ({ data, error }: {
    // Let's use object because pattern matcher doesn't work with unknown
    data: object | null
    error: object | null
  }): string => {
    const result = data || error
    const lastResultRef = useRef(result)
    const initialId = useMemo(() => nanoid(), [])
    const idRef = useRef<string>(initialId)

    /**
     * It's important that we use useMemo not useEffect + useState so that the
     * updated ID is available in the same tick/render as the data update
     */
    const id = useMemo(() => Function.pipe(
      Match.value({
        last: lastResultRef.current,
        current: result,
      }),

      /**
       * From loading to loading, keep the same ID, because it seems like the
       * same request is still being loaded.
       *
       * We can make this smarter later to distinguish new request by checking
       * the payload to determine if this is really the same request, but for
       * now this is good enough.
       */
      Match.when({
        last: Match.null,
        current: Match.null,
      }, () => idRef.current),

      /**
       * From resultful to loading, CREATE NEW ID, because it seems like the
       * existing result is invalidated and new command/query is being loaded.
       */
      Match.when({
        last: Match.null,
        current: {},
      }, ({ current }) => {
        lastResultRef.current = current
        idRef.current = nanoid()
        return idRef.current
      }),

      /**
       * From loading to resultful, KEEP THE SAME ID, because it seems like
       * the the same command/query is just finished.
       */
      Match.when({
        last: {},
        current: Match.null,
      }, ({ current }) => {
        lastResultRef.current = current
        idRef.current = nanoid()
        return idRef.current
      }),

      /**
       * From resultful to resultful, a change in result must have been detected
       * via useMemo deps so usually the cached result is updated and we should
       * GENERATE NEW ID for it to distinguish/invalidate the previous result.
       */
      Match.when({
        last: {},
        current: {},
      }, ({ current }) => {
        lastResultRef.current = current
        idRef.current = nanoid()
        return idRef.current
      }),

      Match.exhaustive,
    ), [result])

    return id
  }

  /**
   * Used to generate ID whenever executing a query or command.
   */
  export const useRegeneratableId = (): {
    id: string
    regenerateId: () => string
  } => {
    const [id, setId] = useState(nanoid())

    const regenerateId = useCallback(() => {
      setId(nanoid())
      return id
    }, [id])

    return {
      id,
      regenerateId,
    }
  }

  export const createKeyedHook = <
    TPayload,
    TError extends Error,
    TKey extends string | number = string,
  >(
    promiseCommandHook: () => Command.PromiseVersion<TError, TPayload>,
  ) =>
    (): Command.Keyed<TError, TPayload, TKey> => {
      type KeyedCommand = Command.Keyed<TError, TPayload, TKey>
      const [state, setState] = useState<KeyedCommand['state']>({})
      const command = promiseCommandHook()

      const execute = useCallback(async (
        key: TKey,
        payload?: TPayload,
      ) => {
        setState(prevState => ({
          ...prevState,
          [key]: {
            status: 'loading',
          },
        }))

        void command

          .execute(payload || { id: key } as TPayload)
          .then(
            result => {
              setState(prevState =>
                ({ ...prevState, [key]: result }))
              return result
            },
          )
      }, [])

      return {
        state,
        execute,
      }
    }
}
