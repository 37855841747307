import { Match, pipe } from 'effect'
import { ConfirmBatchSkiptracePayload } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { ErrorLib } from 'libs/errors'
import { restClient } from 'presentation/libs/client'
import { POSTAttemptBatchSkiptrace } from '../POSTAttemptBatchSkiptrace/POSTAttemptBatchSkiptrace'

const request = async (payload: ConfirmBatchSkiptracePayload): Promise<void> => {
  try {
    let confirmationKey = payload.confirmationKey

    // Calculate the successfulItemLimit value
    const successfulItemLimit = payload.useFreeSkipsOnly
      ? payload.remainingConsumables
      : undefined

    // If we're using a limit (free skips only), we need to get a fresh confirmation key
    if (successfulItemLimit !== undefined) {
      // Call the attempt endpoint to get a fresh confirmation key
      const attemptResult = await POSTAttemptBatchSkiptrace.request({
        ...payload,
        toSkiptraceCount: 0,
      })

      // Use the new confirmation key from the attempt
      confirmationKey = attemptResult.attempt.confirmationKey
    }

    // Make the confirmation request with the confirmation key
    await restClient.post(`lists/v3/${payload.listId}/skiptrace`, {
      json: {
        members: payload.memberIds,
        provider: 'skipGenie',
        reskip: pipe(
          Match.value(payload.reskipConfig),
          /** We'd get null if there's nothing to reskip, so we can put true without consequence */
          Match.when(Match.null, () => true),
          Match.when({ _tag: 'Reskip' }, () => true),
          Match.when({ _tag: 'DoNotReskip' }, () => false),
          Match.when({ _tag: 'ReskipIfOlderThanXDays' }, ({ daysCount }) =>
            ({ olderThanXDays: daysCount })),
          Match.exhaustive,
        ),
        providerOptions: {
          skipGenie: {
            searchtype: payload.format,
          },
        },
        useIncludedConsumables: true,
        confirmationKey, // Use the potentially updated confirmation key
        successfulItemLimit, // Include the successfulItemLimit in the confirmation request
      },
    }).json()

    // Successful confirmation, return void as per the API contract
    return
  } catch (error) {
    // Log and report the error
    const extraInfo = await ErrorLib.safeParseError(error)
    ErrorLib.report(error, {
      extraInfo: { ...extraInfo, context: 'Batch skiptrace confirmation failed' },
    })

    // Rethrow the error
    throw error
  }
}

export const POSTConfirmBatchSkiptrace = {
  request,
}
