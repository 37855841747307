import { checkLocalAccessForData } from 'features/Access/infra/react/AccessState'
import { settingsStore } from 'features/Settings/infra/react/Settings.state.zustand.production'
import { AppDomainEvents } from 'features/events/AppDomainEvents'
import { noop } from 'lodash/fp'
import { toast } from 'presentation/components/Toast'
import { EventsText } from 'presentation/events/Events.text'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { loadTracker } from 'presentation/libs/Tracker'

export const setupDomainEventSubscribers = () => {
  // toast on logout start
  AppDomainEvents.on('access/logout/loading', () => {
    toast.info({
      title: EventsText.logout.loading,
    })
  })

  // clear some data then reload on logout success
  AppDomainEvents.on('access/logout/success', async () => {
    await resetUserData()
    redirectAndRefreshToLogin()
  })

  AppDomainEvents.on('access/login/success', state => {
    const accessState = state.local.access

    if (
      !checkLocalAccessForData(accessState)
      || accessState.data.status !== 'logged-in'
    )
      return

    // fetch settings
    void settingsStore.getState().actions.getSettings.execute()
  })

  AppDomainEvents.on('access/updateAccess/success', async state => {
    const access = state.local.access

    if (!checkLocalAccessForData(access)) return

    if (access.data.status === 'logged-in') {
      const accessor = access.data.accessor
      // SupportWidget record user info
      if (accessor.isEmailConfirmed) {
        void SupportWidget.recordLoggedInUser({
          email: accessor.email,
          name: accessor.name.full,
          userId: accessor.id,
        })
      }
    }
  })

  AppDomainEvents.on('access/getAccess/success', async state => {
    const access = state.local.access

    if (!checkLocalAccessForData(access)) return

    // clear some data if logged out
    if (access.data.status === 'logged-out')

      delete (window as any).actSettings.user

    if (access.data.status === 'logged-in') {
      const accessor = access.data.accessor

      /** Acute feedback platform extra info */

      ;(window as any).actSettings.user = {
        id: accessor.id,
        email: accessor.email,
        name: accessor.name,
        avatarURL: accessor.avatarUrl,
      }

      // SupportWidget record user info
      if (accessor.isEmailConfirmed) {
        void SupportWidget.recordLoggedInUser({
          email: accessor.email,
          name: accessor.name.full,
          userId: accessor.id,
        })
      }

      void loadTracker().then(tracker => {
        tracker.trackPageView()

        tracker.trackLead({
          userId: accessor.id,
          email: accessor.email,
          firstName: accessor.name.first,
          lastName: accessor.name.last,
        })
      })
    }
  })
}

const resetUserData = async () => {
  delete (window as any).actSettings.user
  await SupportWidget.clearLoggedInUser()
    // we must proceed with logout even if intercom fails
    .catch(noop)
}

const redirectAndRefreshToLogin = () => {
  location.pathname = '/login'
}
