import { Data, Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import NearbyBuyer from 'features/NearbyBuyers/domain/NearbyBuyer'
import NearbyBuyersFilters from 'features/NearbyBuyers/domain/NearbyBuyersFilters'
import NearbyBuyersSorting from 'features/NearbyBuyers/domain/NearbyBuyersSorting'
import NearbyBuyerProperty from 'features/NearbyBuyers/NearbyBuyerProperty'
import { Location } from 'features/valueObjects/Location'
import OptionalTotalConnection from 'libs/OptionalTotalConnection'
import AppCache from 'services/AppCache'

type GetNearbyBuyers = (params: GetNearbyBuyers.Params) => GetNearbyBuyers.ResultEffect

namespace GetNearbyBuyers {
  export type Params = {
    location: Location
    filters: NearbyBuyersFilters
    sorting: NearbyBuyersSorting
    before?: string
    after?: string
    first?: number
    last?: number
  }

  export type Result = Data.TaggedEnum<{
    Populated: {
      nearbyBuyersConnection: OptionalTotalConnection<NearbyBuyer>
      nearbyBuyerProperties: NearbyBuyerProperty[]
    }
    Empty: {}
  }>

  export type ResultPopulated = Extract<Result, { _tag: 'Populated' }>
  export type ResultEmpty = Extract<Result, { _tag: 'Empty' }>

  export type Error = UnknownException

  export type ResultEffect = Effect.Effect<Result, Error, AppCache>

  export const Result = Data.taggedEnum<Result>()

}

export default GetNearbyBuyers
