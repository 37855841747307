import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { LotSizeFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/LotSizeFilterCard/LotSizeFilterCard.dumb.props'

import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'
import { isNumber } from 'remeda'

const SQFT_PER_ACRE = 43560

export const LotSizeFilterCardDumb = (
  props: LotSizeFilterCardDumbProps,
) => {
  const lotSizeSubjectValueSqft = props.subjectValue
  const lotSizeSubjectValueAcres = isNumber(lotSizeSubjectValueSqft)
    ? NumberLib.omitAfterNPlaces(2)(lotSizeSubjectValueSqft / SQFT_PER_ACRE)
    : null

  /**
   * Other
   */
  const lotSizeSqftRange = props.value.sqftRange
  const lotSizeAcresRange = lotSizeSqftRange
    ? lotSizeSqftRange?.map(numOrNull => {
      if (numOrNull === null) return null
      return NumberLib.omitAfterNPlaces(2)(numOrNull / SQFT_PER_ACRE)
    }) as PartialRange
    : PartialRange.EMPTY

  return (
    <FilterCard
      title='Lot Size Acres'
      hasBadge={PartialRange.checkHasValueOptional(lotSizeSqftRange)}
    >
      <PartialRangeInputV2
        initialValue={lotSizeAcresRange}
        precision={3}
        onDebouncedValidChange={newRange => {
          props.onChange({
            sqftRange: newRange.map(numOrNull => {
              if (numOrNull === null) return null
              return numOrNull * SQFT_PER_ACRE
            }) as PartialRange,

            /** @NOTE This doesn't matter right now because backend doesn't support unit */
            unit: 'acres',
          })
        }}
        subjectValue={lotSizeSubjectValueAcres?.toString()}
      />
      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )
}
