import { isNumber } from 'effect/Number'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { Skiptrace as Domain } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Pennies } from 'features/valueObjects/Pennies'
import { ErrorLib } from 'libs/errors'
import { apolloClient, restClient } from 'presentation/libs/client'
import { GET_SKIPTRACE_FUNDS_INFO_GQL } from './getSkiptraceFundsInfo.graphql'

/**
 * Determine the status based on remaining consumables, available funds, and how many records need to be skiptrace'd
 */
const determineStatus = ({
  remainingConsumables,
  availableFunds,
  toSkiptraceCount,
  pricePerUnit,
}: {
  remainingConsumables: number
  availableFunds: number
  toSkiptraceCount: number
  pricePerUnit: number
}): ConsumableAttempt.Status => {
  if (remainingConsumables >= toSkiptraceCount)
    return 'sufficient-consumables'
  else if (availableFunds >= pricePerUnit * (toSkiptraceCount - remainingConsumables))
    return 'sufficient-funds'
  else
    return 'insufficient-funds'
}

export const getSkiptraceFundsInfo = async (
  params: Domain.GetSkiptraceFundsInfoPayload,
): Promise<Domain.GetSkiptraceFundsInfoResult> => {
  const commonPayload = {
    /** @TODO Dynamic providers */
    provider: 'skipGenie',
    request: {
      line1: params.address.line1,
      city: params.address.city,
      state: params.address.state,
      zip: params.address.postalCode,
    },
    useIncludedConsumables: true,
  }

  // Make the request with useIncludedConsumables: true
  // This is expected to return a 402 with consumable attempt data
  return restClient.post('marketing/v2/skiptrace/v3', {
    json: commonPayload,
  })
    .json()
    .then(() => {
      // This should not happen - we expect the request to throw a 402 error
      throw new Error('Unexpected response from skiptrace funds info attempt')
    })
    .catch(async error => {
      // Check if it's an error with a response
      if (error?.response) {
        try {
          const errorResponse = await error.response.json()

          // Check if it's the payment confirmation required error (402)
          if (errorResponse?.error?.type === 'payment_confirmation_required') {
            // Extract data from the error response first
            const confirmationKey = errorResponse.error.confirmationKey

            // Check if we have consumable data in the error response
            const restRemainingConsumables = errorResponse.error?.details?.included?.available
            const restRefreshingConsumables = errorResponse.error?.details?.included?.limit
            const restRefreshDate = errorResponse.error?.details?.included?.rolloverDate
            const restWalletBalance = errorResponse.error?.details?.balance?.available

            // Get missing subscription and funds info from GraphQL - only for planName and price
            const { data } = await apolloClient.query({
              query: GET_SKIPTRACE_FUNDS_INFO_GQL,
            }).catch(gqlError => {
              ErrorLib.report(gqlError, {
                extraInfo: { context: 'Error fetching skiptrace funds info subscription data' },
              })
              throw gqlError
            })

            const enterprise = data.myEnterprise

            if (!enterprise)
              throw new Error('undefined `myEnterprise` in GraphQL response')

            const subscription = enterprise.subscription

            if (subscription?.__typename !== 'BillingSubscriptionActive')
              throw new Error('undefined or inactive subscription')

            // Find the Skiptrace feature in subscription - only for price
            type SkiptraceFeature = { __typename: 'SubscriptionFeatureSkiptrace', prices: { price: number }[] }
            const skiptraceFeature = subscription.plan.product.features
              .find((feat: any): feat is SkiptraceFeature =>
                feat.__typename === 'SubscriptionFeatureSkiptrace')

            const price = skiptraceFeature?.prices[0]?.price

            if (!isNumber(price))
              throw new Error('missing Skiptrace price data')

            // Use REST data for everything except planName and price when not available in REST
            const remainingConsumables = restRemainingConsumables ?? 0
            const refreshingConsumables = restRefreshingConsumables ?? 0
            const consumablesRefreshDate = restRefreshDate ? new Date(restRefreshDate) : new Date()
            const walletBalance = restWalletBalance ?? 0

            // Create the response object
            return {
              attempt: {
                planName: subscription.plan.name,
                price,
                refreshingConsumables,
                remainingConsumables,
                consumablesRefreshDate,
                funds: {
                  available: walletBalance as Pennies,
                },
                confirmationKey,
                status: determineStatus({
                  remainingConsumables,
                  availableFunds: walletBalance,
                  toSkiptraceCount: 1, // For single skiptrace, we're only processing one record
                  pricePerUnit: price,
                }),
              },
            }
          }
        } catch (parseError) {
          // Error parsing the response JSON
          ErrorLib.report(parseError, {
            extraInfo: { originalError: error, context: 'Error parsing skiptrace funds info response' },
          })

          throw parseError
        }
      }

      // If we reached here, it's not the expected error format or failed to parse
      const extraInfo = await ErrorLib.safeParseError(error)
      ErrorLib.report(error, {
        extraInfo: { ...extraInfo, context: 'Skiptrace funds info attempt failed' },
      })

      throw error
    })
}
