import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { SearchIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { NoticeButton, NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { handleDefaultOpenFailedInvoiceModal } from 'presentation/main/globalModals/FailedInvoiceModal/handleDefaultOpenFailedInvoiceModal'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { get } from 'presentation/utils/graphql'
import { useCallback, useEffect, useMemo } from 'react'

const PAYMENT_FAILED_NOTICE__GET_PAST_DUE_INVOICE = gql(/* GraphQL */ `
  query PaymentFailedNotice_GetPastDueInvoice {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
        }
        ... on BillingSubscriptionInactive {
          id
        }

        ...FailedInvoiceFlow_FailedInvoiceSummary
      }
    }
  }
`)

export const usePaymentFailedNotice = () => {
  const { data, loading, error } = useQuery(PAYMENT_FAILED_NOTICE__GET_PAST_DUE_INVOICE)

  const sub = data?.myEnterprise?.subscription
  const activeSub = get('BillingSubscriptionActive')(sub) ?? undefined

  const failedInvoiceSummary = useMemo(() =>
    unmaskFailedInvoiceFragment(activeSub), [activeSub])
  const hasPastDueInvoice = !!failedInvoiceSummary

  const handleTryPaymentAgain = useCallback(() => {
    handleDefaultOpenFailedInvoiceModal(failedInvoiceSummary)
  }, [failedInvoiceSummary])

  useEffect(() => {
  }, [failedInvoiceSummary])

  const updateNotice = useNoticeStore(store => store.updateNotice)

  useEffect(() => {
    if (loading || error) return

    if (hasPastDueInvoice) {
      updateNotice(NOTICE_KEYS.PAYMENT_FAILED, (
        <ResponsiveNotice
          colorScheme='error'
          icon={TriangleExclamationIcon}
          textContent={(
            <>
              <NoticeEmphasis>
                Uh-Oh! We were unable to process your payment.
              </NoticeEmphasis>
              {' '}
              Your account is
              {' '}
              <NoticeEmphasis>past due</NoticeEmphasis>
              {' '}
              and
              scheduled for deletion this month.
            </>
          )}
          actionsContent={(
            <>
              <NoticeButton
                variant='solid'
                colorScheme='highlight'
                onClick={handleTryPaymentAgain}
                leftIcon={<SearchIcon boxSize='2' />}
              >
                review payment
              </NoticeButton>
            </>
          )}
        />
      ))
    }

    return () => updateNotice(NOTICE_KEYS.PAYMENT_FAILED, null)
  }, [
    loading,
    error,
    hasPastDueInvoice,
    handleTryPaymentAgain,
  ])
}
