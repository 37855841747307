import { Button, ButtonProps } from '@chakra-ui/react'
import { shallowEqual, useSelector } from '@xstate/react'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { useCreateListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/CreateListAction/useCreateListActionModal'

const CreateListAction = (props: ButtonProps) => {
  const modalActions = useCreateListModalActions()
  const actor = useParcelListManager()
  const isLoading = useSelector(actor, snapshot => snapshot.hasTag('loading'), shallowEqual)
  const handle = () => {
    modalActions.open()
  }

  return (
    <>
      <Button
        variant='solid'
        colorScheme='neutral'
        onClick={handle}
        isLoading={isLoading}
        {...props}
      >
        Create New List
      </Button>
    </>
  )
}

export default CreateListAction
