import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { FilterMenu, FilterMenuButton } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/FilterMenu'
import { SoldWithinFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SoldWithinFilterDropdown/SoldWithinFilterDropdown.dumb.props'
import { SoldWithinRange } from 'presentation/screens/CompsScreen/components/common/SoldWithinRange'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const SoldWithinFilterDropdownDumb = (
  props: SoldWithinFilterDropdownDumbProps,
) => {
  const selectedLabel = SoldWithinRange.toLabel(props.value)

  return (
    <FilterMenu>
      <FilterMenuButton
        label='Sold Within'
        minW={mbp({ mobSm: '130px', dtLg: '150px' })}
      >
        {selectedLabel}
      </FilterMenuButton>
      <ResponsiveMenuList>
        <MenuOptionGroup
          title='Sold Within'
          value={selectedLabel}
          onChange={rawLabelValue => {
            const labelValue = Array.isArray(rawLabelValue) ? rawLabelValue[0] : rawLabelValue
            const rangeValue = SoldWithinRange.fromLabel(labelValue)
            props.onChange(rangeValue)
          }}
        >
          {SoldWithinRange.PRESETS.map(({ label }) => (
            <MenuItemOption
              key={label}
              type='checkbox'
              value={label}
            >
              {label}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </ResponsiveMenuList>
    </FilterMenu>
  )
}
