import { Box, Text, VStack } from '@chakra-ui/react'
import { SkiptraceFormat } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { useConfirmBatchSkiptraceState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useConfirmBatchSkiptraceState'
import { useFundsActions } from 'features/Funds/infra/react/hooks/useFundsActions'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Radio } from 'presentation/components/Radio/Radio'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedStateTagged } from 'presentation/libs/hooks/useToastFailedState'
import { BatchSkiptraceModalState, useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

export const FormatStepCard = ({
  payload,
}: {
  payload: Extract<BatchSkiptraceModalState, { _tag: 'FormatStep' }>
}) => {
  const modalActions = useBatchSkiptraceModalActions()
  const skiptraceActions = useBatchSkiptraceActions()
  const confirmState = useConfirmBatchSkiptraceState()
  const fundsActions = useFundsActions()
  const [radioValue, setRadioValue] = useState<SkiptraceFormat>('narrow')

  const prevConfirmState = usePrevious(confirmState)

  useToastFailedStateTagged(confirmState, {
    title: 'Failed to run skiptrace',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useEffect(() => {
    if (prevConfirmState?._tag === 'Loading' && confirmState?._tag === 'Success') {
      skiptraceActions.watchProgress(payload.listId)
      void fundsActions.getFundsInfo()
      modalActions.close()
    }
  }, [confirmState, prevConfirmState])

  return (
    <Card
      variant='modal.alert'
      size='modal.alert.xn'
      colorScheme='modal.alert.neutral'
    >
      <CardImage src={happyDogPng} />
      <ModalCloseButton
        onClose={modalActions.close}
      />
      <CardPrimaryTitle>
        Select Format
      </CardPrimaryTitle>
      <CardBody>
        <CardPrimaryText>
          We have two formats to choose from:
        </CardPrimaryText>
        <VStack spacing={3} mt={5}>
          <FormatOption
            label='Narrow'
            description={`
              Runs the skiptraces specifically on the owner and their contact address.
              You'll get fewer hits, but they'll be more accurate.
            `}
            isSelected={radioValue === 'narrow'}
            onClick={() => setRadioValue('narrow')}
          />

          <FormatOption
            label='Wide'
            description={`
              Runs the skiptraces only on the owner's contact address.
              This will get you more hits such as other people also associated with that address
              but they could be previous residents and not in any way associated with the subject property.
            `}
            isSelected={radioValue === 'wide'}
            onClick={() => setRadioValue('wide')}
          />
        </VStack>
      </CardBody>
      <CardFooter>
        <CardButton
          variant='solid'
          colorScheme='neutral'
          size='md'
          onClick={() => {
            skiptraceActions.confirmBatchSkiptrace({
              format: radioValue,
              listId: payload.listId,
              memberIds: payload.memberIds,
              reskipConfig: payload.reskipConfig,
              confirmationKey: payload.attempt.confirmationKey,
              useFreeSkipsOnly: payload.useFreeSkipsOnly,
              remainingConsumables: payload.remainingConsumables,
            })
          }}
          type='submit'
          isLoading={confirmState?._tag === 'Loading'}
          loadingText='Processing'
        >
          Pay Now
        </CardButton>
      </CardFooter>
    </Card>
  )
}

type FormatOptionProps = {
  label: string
  description: string
  isSelected?: boolean
  onClick?: () => void
}

const FormatOption = (props: FormatOptionProps) => (
  <Box
    borderColor={props.isSelected ? 'neutral.500' : 'highlight.100'}
    borderWidth={0.25}
    borderRadius={mbp({ mobSm: 3 })}
    padding={mbp({ mobSm: 2, tabSm: 3 })}
    bgColor='highlight.100'
    onClick={props.onClick}
    cursor='pointer'
    shadow='primary.m'
    role='button'
  >
    <Text
      color='graystrong.200'
      textStyle={mbp({ mobSm: 'bodyM', tabSm: 'bodyXL' })}
    >
      {props.description}
    </Text>
    <Radio
      mt={mbp({ mobSm: 1, tabSm: 2 })}
      isChecked={props.isSelected}
      onClick={e => e.preventDefault()}
      textStyle={mbp({ mobSm: 'bodyL', tabSm: 'bodyXL' })}
    >
      {props.label}
    </Radio>
  </Box>
)
