import { ModalOverlay, Text } from '@chakra-ui/react'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { ModalContentV1, ModalV1 } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { toast } from 'presentation/components/Toast'
import { CardButton, CardFooter, CardHeader, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { closeModal, goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals/modals'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FC } from 'react'
import { FailedInvoiceSummary } from '../FailedInvoiceModal/FailedInvoiceModal.types'
import { openFailedInvoiceModal } from '../FailedInvoiceModal/openFailedInvoiceModal'
import { openUpdatePaymentModal } from '../UpdatePaymentModal/openUpdatePaymentModal'
import { PaymentFailedModalProps } from './PaymentFailedModal.types'

export const PaymentFailedModal: FC<PaymentFailedModalProps> = ({
  isOpen = false,
  onClose,
  onRetryCharge,
  onUpdateCard,
}) => (
  <ModalV1
    isOpen={isOpen}
    onClose={onClose}
    variant='modal.alert'
    size='modal.alert.xn'
    colorScheme='modal.alert.negative'
    isCentered
  >
    <ModalOverlay />
    <ModalContentV1>
      <ModalCloseButton />
      <CardImage src={hurtDogPng} />

      <CardHeader>
        <CardPrimaryTitle>
          Uh-oh! We were unable to process your payment.
        </CardPrimaryTitle>
      </CardHeader>

      <CardPrimaryText>
        Here are some options:
      </CardPrimaryText>

      <CardFooter
        {...mbpg({
          mobSm: {
            flexDirection: 'column',
            alignItems: 'stretch',
          },
          tabSm: {
            flexDirection: 'row',
            alignItems: 'initial',
          },
        })}
      >
        <CardButton
          variant='outline'
          colorScheme='positive'
          onClick={onRetryCharge}
        >
          Retry Payment
        </CardButton>

        <CardButton
          variant='solid'
          colorScheme='positive'
          type='submit'
          onClick={onUpdateCard}
          autoFocus
        >
          Update Card
        </CardButton>
      </CardFooter>

      <Text
        mt={mbp({ mobSm: '2', tabSm: '5' })}
        textStyle='bodyXL'
        color='graystrong.200'
        textAlign='center'
      >
        Your account is
        {' '}
        <Text as='span' textStyle='bodyXLHeavy' color='graystrong.200'>past due</Text>
        {' '}
        and scheduled for cancelation soon.
      </Text>
    </ModalContentV1>
  </ModalV1>
)

export const openPaymentFailedModal = ({ failedInvoiceSummary }: {
  failedInvoiceSummary: FailedInvoiceSummary
}): void => {
  const handleRetryCharge = () => {
    openFailedInvoiceModal({
      failedInvoiceSummary,
      onSuccess: message => {
        toast({
          ...TOAST_PRESET.GENERIC_SUCCESS,
          message,
        })
        closeModal()
      },
      onError: error => openErrorModal({
        message: error.message,
      }),
    })
  }

  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'PAYMENT_FAILED_MODAL',
      props: {
        onClose: goBackModal,
        onRetryCharge: handleRetryCharge,
        onUpdateCard: () =>
          openUpdatePaymentModal(),
      },
    },
  })
}
