import { Box, Flex, HStack, IconButton, Spacer, Text, VStack } from '@chakra-ui/react'
import { Match } from 'effect'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { CloseIcon } from 'presentation/components/Icons'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { useCompsListPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CMADataUpdateButton, CMADataUpdateDate } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { CMAReport } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport'
import { CMAPrintButton } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAPrintButton'
import { CMATileEntries } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMATileEntries/CMATileEntries'
import { SubjectAPNPill } from 'presentation/screens/CompsScreen/components/SubjectAPNPill'
import { openCMARentalsTab, openCMASalesTab, openCMASummaryTab, selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useCompsScreenViewMode } from 'presentation/screens/CompsScreen/hooks/useCompsScreenViewMode'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { pipe } from 'remeda'

export const CMASidePanel = () => {
  const activeTab = useActiveEntriesTab(selectActiveTab)
  const setMode = useCompsScreenViewMode(store => store.setMode)

  const { closePanel, openPanel } = useCompsListPanelStore()

  const { sbp } = useSwitchBreakpointFn()

  const isNotMobSm = sbp({
    mobSm: false,
    mob: true,
  })

  const isMobile = sbp({
    mobSm: true,
    tabSm: false,
  })

  const shouldShowCloseButton = activeTab === 'REPORT'

  useEffect(() => {
    if (isMobile) return

    if (activeTab === 'REPORT')
      setMode('with-panel-overlay')
    else
      setMode('with-side-panel')
  }, [activeTab])

  const handleClosePanel = () => {
    // set back to default tab before closing from fullscreen version of side panel
    openCMASalesTab()
    closePanel()
  }

  return (
    <Flex
      minW={0}
      w='full'
      minH={0}
      h='full'
      bgColor={mbp({ mobSm: 'neutral.500', tabSm: 'card.bg.1' })}
      flexDirection='column'
      boxShadow='right-sticky'
    >
      <Flex
        alignItems='center'
        justifyContent='space-between'
        py={2}
        pb={1}
        px={3}
        display={mbp({ mobSm: 'none', tabSm: 'flex' })}
      >
        <Text
          textStyle='h4'
          color='accent.blue-text'
        >
          Comparative Market Analysis
        </Text>

        <Spacer />

        {shouldShowCloseButton && (
          <IconButton
            aria-label='close panel'
            icon={<CloseIcon boxSize={3} color='graystrong.500' />}
            variant='icon-ghost'
            size='md'
            onClick={handleClosePanel}
          />
        )}
      </Flex>

      <BaseTabsContainer
        minH={0}
        flex='1 1 0'
        colorScheme='xweak'
        size={mbp({
          mobSm: 'sm',
          mob: 'md',
        })}
      >
        <BaseTabs alignItems='flex-end'>
          <BaseTab
            isActive={activeTab === 'SALES'}
            onClick={() => {
              openCMASalesTab()
              openPanel()
            }}
          >
            SALES
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'RENTALS'}
            onClick={() => {
              openCMARentalsTab()
              openPanel()
            }}
          >
            RENTALS
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'REPORT'}
            onClick={openCMASummaryTab}
          >
            REPORT
          </BaseTab>
          <Flex ml='auto' mr={!isNotMobSm && activeTab === 'REPORT' ? 1 : 3} gap={1}>
            <Breakpoint
              mobSm={(
                <CMAPrintButton
                  colorScheme='ondark'
                />
              )}
              mob={(
                <></>
              )}
              tabSm={(
                <VStack spacing={0} alignItems='flex-end'>
                  {activeTab !== 'REPORT' && <CMADataUpdateButton colorScheme='neutral' />}
                  <HStack spacing={1} mb={0.5}>
                    <CMADataUpdateDate />
                    {activeTab === 'REPORT' && <SubjectAPNPill />}
                  </HStack>
                </VStack>
              )}
            />
          </Flex>
        </BaseTabs>
        <BaseTabContent
          minH={0}
          overflow='initial'
          position='relative'
          h='full'
          sx={{
            borderTopRightRadius: '0 !important',
          }}
        >
          {pipe(
            Match.value(activeTab),
            Match.when('SALES', () => <CMATileEntries entriesType='SALES' />),
            Match.when('RENTALS', () => <CMATileEntries entriesType='RENTALS' />),
            Match.when('REPORT', () => <CMASidePanelReport />),
            Match.exhaustive,
          )}
        </BaseTabContent>
      </BaseTabsContainer>
    </Flex>
  )
}

const CMASidePanelReport = () => {
  const { layoutWidth } = useLayoutWidth()

  return (
    <ScrollWithFade
      containerProps={{
        h: 'full',
      }}
      fadeColor='rgb(0, 0, 0, 0.15)'
      fadeSize={3}
    >
      <Box
        w={layoutWidth}
        minH='full'
        display='flex'
        alignItems='center'
        justifyContent='stretch'
        pt={2}
        px={mbp({ mobSm: 1, mob: 2, tabSm: 3 })}
        pb={mbp({ mobSm: 7, tabSm: 2 })}
      >
        <CMAReport />
      </Box>
    </ScrollWithFade>
  )
}
