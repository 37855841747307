import { PartialRange } from 'features/valueObjects/Range'
import { BathsFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/BathsFilterCard/BathsFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { MatchSubjectCheckbox } from 'presentation/screens/CompsScreen/components/common/MatchSubjectCheckbox/MatchSubjectCheckbox'
import { PartialRangeInputV2 } from 'presentation/screens/CompsScreen/components/common/PartialRangeInputV2/PartialRangeInputV2'

export const BathsFilterCardDumb = (
  props: BathsFilterCardDumbProps,
) => {
  const isBathsMatchingSubject = props.subjectValue !== null
    && props.value.range?.[0] === props.subjectValue
    && props.value.range?.[1] === props.subjectValue

  return (
    <FilterCard
      title='Baths'
      hasBadge={
        PartialRange.checkHasValueOptional(props.value.range)
      }
      toolbar={(
        props.subjectValue !== null && (
          <MatchSubjectCheckbox
            isChecked={isBathsMatchingSubject}
            onChange={() => {
              props.onChange({
                range: isBathsMatchingSubject
                  ? [null, null]
                  : [props.subjectValue, props.subjectValue],

                /** @GENESIS */
                shouldMatchSubjectProperty: false,
              })
            }}
          />
        )
      )}
    >
      <PartialRangeInputV2
        initialValue={props.value.range}
        onDebouncedValidChange={newRange => {
          props.onChange({
            range: newRange,

            /** @GENESIS */
            shouldMatchSubjectProperty: false,
          })
        }}
        subjectValue={props.subjectValue?.toString()}
      />

      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}

    </FilterCard>
  )
}
