import { gql } from '__generated__'

const GET_ACTIVE_PRODUCT = gql(/* GraphQL */`
  query GetActiveProduct {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
          plan {
            id
            name
            price
            interval {
              unit
              count
            }
            product {
              id
              name
              description
              features {
                ...ProductFeatures 
              }
            }
          }
          currentPeriod {
            end
          }
          trialPeriod {
            end
            __typename
          }
          cancelAt
          cancelAtPeriodEnd
        }
      }
    }
  }
`)

export default GET_ACTIVE_PRODUCT
