import { createIcon } from '@chakra-ui/react'

export const ListBuilderIcon = createIcon({
  displayName: 'ListBuilderIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 3V21' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
