import { ModalOverlay } from '@chakra-ui/react'
import { ModalContentV1, ModalV1 } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { SelectFormatCard } from 'presentation/screens/SkiptraceScreen/components/SelectFormatCard'
import { useSelectFormatModal } from 'presentation/screens/SkiptraceScreen/components/SelectFormatModal/SelectFormatModal.api'

export const SelectFormatModal = () => {
  const api = useSelectFormatModal()

  return (
    <ModalV1
      isOpen={api.state.status === 'open'}
      onClose={api.actions.close}
      variant='modal.alert'
      colorScheme='modal.alert.neutral'
      size='modal.alert.xn'
      isCentered
    >
      <ModalOverlay />
      <ModalContentV1>
        <ModalCloseButton onClose={api.actions.close} />
        <SelectFormatCard onCancel={api.actions.close} />
      </ModalContentV1>
    </ModalV1>
  )
}
