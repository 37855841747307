import { Brand, Data } from 'effect'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import ParcelId from 'features/ListBuilder/domain/ParcelId'

export class ListNotFoundError extends
  Data.TaggedError('ListNotFoundError')<{
    marketingListId: MarketingListId
    message: string
  }> {
  constructor({
    marketingListId,
  }: {
    marketingListId: MarketingListId
  }) {
    super({
      marketingListId,
      message: `MarketingListId ${marketingListId} not found.`,
    })
  }
}

/**
 * @NOTE: This represents both incorrect list id and member id because both
 * results in a generic 404 error
 */
export class ListMemberNotFoundError extends
  Data.TaggedError('ListMemberNotFoundError')<{
    marketingListId: MarketingListId
    marketingListMemberId: MarketingListMemberId
    message: string
  }> {
  constructor({
    marketingListId,
    marketingListMemberId,
  }: {
    marketingListId: MarketingListId
    marketingListMemberId: MarketingListMemberId
  }) {
    super({
      marketingListId,
      marketingListMemberId,
      message: `MarketingListId ${marketingListId} with MarketingListMemberId ${marketingListMemberId} not found.`,
    })
  }
}

export type DownloadEntryId = string & string & Brand.Brand<'DownloadId'>

/**
 * @NOTE: This represents both incorrect list id and download id because both
 * results in a generic 404 error
 */
export class DownloadNotAvailableError extends
  Data.TaggedError('DownloadNotAvailableError')<{
    marketingListId: MarketingListId
    downloadId: DownloadEntryId
    message: string
  }> {
  constructor({
    marketingListId,
    downloadId,
  }: {
    marketingListId: MarketingListId
    downloadId: DownloadEntryId
  }) {
    super({
      marketingListId,
      downloadId,
      message: `MarketingListId ${marketingListId} with DownloadEntryId ${downloadId} was not found`,
    })
  }
}

export class ParcelNotFoundError extends
  Data.TaggedError('ParcelNotFoundError')<{
    parcelId: ParcelId
    message: string
  }> {
  constructor({ parcelId }: { parcelId: ParcelId }) {
    super({
      parcelId,
      message: `Parcel with id ${parcelId} not found.`,
    })
  }
}
export class ListTypeNotSupportedError extends
  Data.TaggedError('ListTypeNotSupportedError')<{
    marketingListId: MarketingListId
    message: string
  }> {
  constructor({
    marketingListId,
  }: {
    marketingListId: MarketingListId
  }) {
    super({
      marketingListId,
      message: `Marketing list type is not supported for MarketingListId ${marketingListId}`,
    })
  }
}
