import { ChoosePlan_CurrentPlanFragment, ChoosePlan_TargetProductFragment } from '__generated__/graphql'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals/modals'

export type OpenChoosePlanModalParams = {
  targetProduct: ChoosePlan_TargetProductFragment
  currentPlan?: ChoosePlan_CurrentPlanFragment
  selectedPlanId?: string
  shouldReplace?: boolean
}

export const openChoosePlanModal = ({
  targetProduct,
  currentPlan,
  selectedPlanId,
  shouldReplace = false,
}: OpenChoosePlanModalParams) => {
  modals.emitter.emit({
    type: shouldReplace ? 'REPLACE' : 'OPEN',
    payload: {
      key: 'CHOOSE_PLAN_MODAL',
      props: {
        targetProduct,
        currentPlan,
        selectedPlanId,
        onClose: goBackModal,
      },
    },
  })
}
