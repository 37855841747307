import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_SINGLE_FAMILY_ICON_W = 32

export const PropTypeSingleFamilyIcon = createIcon({
  displayName: 'PropTypeSingleFamilyIcon',
  viewBox: `0 0 ${PROP_TYPE_SINGLE_FAMILY_ICON_W} 30`,
  path: (
    <>
      <path fillRule='evenodd' clipRule='evenodd' d='M16 2L24.6842 8.37464V6.1994C24.6842 5.75625 25.0376 5.397 25.4736 5.397C25.9097 5.397 26.2631 5.75625 26.2631 6.1994V9.53367L30.6727 12.7705C31.0262 13.03 31.1058 13.5316 30.8505 13.8908C30.5952 14.2501 30.1017 14.331 29.7482 14.0715L16 3.97958L2.25176 14.0715C1.89829 14.331 1.4048 14.2501 1.14952 13.8908C0.89424 13.5316 0.973835 13.03 1.3273 12.7705L16 2ZM16 4.41092L27.8421 13.0081V28.6667H4.15794V13.0081L16 4.41092ZM5.73688 13.834V27.0619H26.2631V13.834L16 6.38307L5.73688 13.834Z' fill='currentColor' />
      <rect x='12.625' y='20.625' width='6.75' height='6.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='12.625' y='14.625' width='6.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
    </>
  ),
})
