import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { createPropertyDetailsUrlFromComp } from 'features/CMA/infra/react/CMA.helpers'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { EnterIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { ThumbButtonsToggle } from 'presentation/components/ThumbButtons'
import { Tooltip } from 'presentation/components/Tooltip'
import { Card } from 'presentation/components/molecules/Card'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { AgentInfoText } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/AgentInfoText/AgentInfoText'
import { CardSelectionCarousel } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CardSelectionCarousel/CardSelectionCarousel'
import { CompAnalysisCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard'
import { ComparativePropertyCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCard/ComparativePropertyCard.props'
import { CompsMLSInfoCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompsMLSInfoCard/CompsMLSInfoCard'
import { TabbedComparativeContent } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/TabbedComparativeContent'
import { useComparativePropertyCardLogic } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useComparativePropertyCardLogic'
import { toBedBathsLivingSqft } from 'presentation/screens/CompsScreen/formatters/toBedBathsBldngSqft'
import { thumbValueFromRatingNullable } from 'presentation/screens/CompsScreen/utils/CMAThumbRatingConverter'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Link } from 'react-router-dom'
import { isEmpty, isNonNull } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

export const ComparativePropertyCard = (props: ComparativePropertyCardProps) => {
  const comparativeLogic = useComparativePropertyCardLogic(props)

  if (comparativeLogic.status === 'aborting')
    return null

  const {
    compEntries,
    onSelectedIndexChange,
    handleRatingChange,
    modalBgColor,
    selectedCompIsSubject,
    isSinglePointComps,
    safeSelectedIndex,
    currentComp: selectedComp,
    totalItems,
  } = comparativeLogic

  const currentPage = safeSelectedIndex + 1

  const bedBathsSqft = toBedBathsLivingSqft(selectedComp, false)

  const onPageChange = (page: number) => {
    onSelectedIndexChange(page - 1)
  }

  const infoCard = (
    <CompsMLSInfoCard
      entry={selectedComp}
    />
  )

  const analysisCard = pipe(
    Match.value(selectedComp),
    Match.when({ type: 'subject-comp' }, v => (
      <CompAnalysisCard
        subject={v}
        onRatingChange={handleRatingChange}
      />
    )),
    Match.when({ type: 'single-comp' }, v => (
      <CompAnalysisCard
        subject={props.subject}
        comp={v as CMA.SingleComp}
        onRatingChange={handleRatingChange}
      />
    )),
    Match.orElse(() => null),
  )

  const renderPageButton = (page: number | null) => {
    if (page === null) return '...'
    const entry = compEntries[page - 1]
    const thumbValue = thumbValueFromRatingNullable(entry?.comp.userRating)
    return (
      <>
        {thumbValue !== 'undecided' && (
          <ThumbButtonsToggle
            value={thumbValue}
            position='absolute'
            top='-8px'
            right='-5px'
            boxSize={2}
          />
        )}
        {page}
      </>
    )
  }

  const isSubdivisionAvailable = isNonNull(selectedComp.address.subdivision)
    && !isEmpty(selectedComp.address.subdivision)

  const subdivision = isSubdivisionAvailable
    ? selectedComp.address.subdivision
    : '--'

  const subdivisionDisplay = (
    <Text
      textStyle={mbp({
        dtSm: 'bodyXLFat',
        dtLg: 'bodyXL',
      })}
      color={isSinglePointComps ? 'modal.text' : 'ondark.3'}
      isTruncated
    >
      {subdivision}
    </Text>
  )

  return (
    <Box
      minW={0}
      maxW='1168px'
      w='full'
      pointerEvents='auto'
    >
      {/** Navigation */}
      <Card
        size='lg-locked'
        bgColor={isSinglePointComps ? 'grayweak.100' : 'highlight.100'}
        borderBottomRadius={0}
        borderTopRadius={5}
        p={0}
      >
        <Flex
          justifyContent='space-between'
          p={3}
        >
          <Text
            textStyle='h4'
            color='accent.blue-text'
          >
            Navigate Listings
          </Text>
          <Pagination
            currentPage={currentPage}
            totalPages={totalItems}
            maxPageButtons={8}
            onPageChange={onPageChange}
            renderPageButton={renderPageButton}
            size='lg'
            colorScheme='highlight'
            shouldHideFillers
          />
        </Flex>
        <CardSelectionCarousel
          selectedIndex={safeSelectedIndex}
          entries={compEntries}
          onSelectedIndexChange={onSelectedIndexChange}
          onCompsRatingChange={handleRatingChange}
        />

        {isSinglePointComps && (
          <Text
            textStyle='bodyM'
            color='graystrong.400'
            mx={3}
            mb={3}
          >
            This is a group of multiple listings at a single address.
            You can navigate through the pagination to explore other listings available.
          </Text>
        )}
      </Card>

      <Card
        borderTopRadius={0}
        size='lg-locked'
        bgColor={modalBgColor}
      >
        {/** Header */}
        <Grid
          gridTemplateColumns='minmax(0, 1fr) fit-content(30%)'
          columnGap={3}
          mb={5}
        >
          <Box>
            {/** Address */}
            <Flex
              alignItems='center'
              gap={1}
            >
              <Tooltip label={selectedComp.address.line1}>
                <Text
                  textStyle={mbp({
                    tabSm: 'h3',
                    dtLg: 'h1',
                  })}
                  color={isSinglePointComps ? 'graystrong.500' : 'ondark.1'}
                  isTruncated
                >
                  {selectedComp.address.line1}
                </Text>
              </Tooltip>
              <Box
                as={Link}
                p={0.5}
                to={createPropertyDetailsUrlFromComp(selectedComp)}
                target='_blank'
              >
                <EnterIcon
                  boxSize={3}
                  color={isSinglePointComps ? 'graystrong.500' : 'ondark.1'}
                />
              </Box>
              {selectedComp.type === 'subject-comp'
                ? <SubjectPropertyTag isOnDark flexShrink={0} />
                : <MLSStatusTag status={selectedComp.status} flexShrink={0} />}
            </Flex>

            {isSubdivisionAvailable
              ? <Tooltip label={subdivision}>{subdivisionDisplay}</Tooltip>
              : subdivisionDisplay}

            <Text
              top='0.5'
              textStyle='bodyM'
              color={isSinglePointComps ? 'modal.text' : 'ondark.1'}
            >
              {bedBathsSqft}
            </Text>
          </Box>

          <Box>
            <Flex
              alignItems='center'
              justifyContent='end'
              gap={1}
            >
              <Text
                textStyle={mbp({
                  tabSm: 'h3',
                  dtLg: 'h1',
                })}
                color={isSinglePointComps ? 'graystrong.500' : 'ondark.1'}
              >
                {Dollar.formatNumberCODoDD(selectedComp.salePrice || selectedComp.listPrice)}
              </Text>

              <CompsThumbButtons
                comp={selectedComp}
                onCompsRatingChange={handleRatingChange}
                size='lg'
              />
            </Flex>
            <Text
              mt={1}
              textStyle='bodyXL'
              color={isSinglePointComps ? 'modal.text' : 'ondark.3'}
              textAlign='right'
            >
              {isNonNullable(selectedComp?.daysOnMarket)
                ? [selectedComp.daysOnMarket, pluralize('day', selectedComp.daysOnMarket), 'on market'].join(' ')
                : '-- days on market'}
            </Text>
          </Box>
        </Grid>

        <AgentInfoText
          comp={selectedComp}
          mb={2}
          color={
            isSinglePointComps
              ? 'graystrong.400'
              : 'ondark.4'
          }
        />

        <Breakpoint
          tabSm={(
            <TabbedComparativeContent
              infoCard={infoCard}
              analysisCard={analysisCard}
              entriesAreSinglePoint={isSinglePointComps}
              selectedIsSubjectComp={selectedCompIsSubject}
            />
          )}
          dtLg={(
            <Grid
              gridTemplateColumns='minmax(0, 1fr) min-content'
              columnGap={3}
            >
              {infoCard}
              {analysisCard}
            </Grid>
          )}
        />

        <CompsMLSDisclaimer
          mt={5}
          color={isSinglePointComps ? 'modal.text' : 'ondark.3'}
        />
      </Card>
    </Box>
  )
}

type PaginationPillProps = {
  total: number
  current: number
}

export const PaginationPill = (props: PaginationPillProps) => {
  const { total, current } = props
  return (
    <Flex
      alignItems='center'
      justifyContent='center'
      h='min-content'
      py={0.5}
      px={1}
      bgColor='marker.cream'
      color='marker.brown'
      borderColor='marker.brown'
      borderWidth={0.125}
      borderRadius='full'
      textStyle='tagL'
    >
      {current}
      {' '}
      /
      {total}
      {' '}
      Listings
    </Flex>
  )
}
