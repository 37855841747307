import { createIcon } from '@chakra-ui/react'

export const LightModeIcon = createIcon({
  displayName: 'LightModeIcon',
  viewBox: '0 0 48 25',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_i_1926_161607)'>
        <rect y='0.5' width='48' height='24' rx='12' fill='#A2D3F5' />
      </g>
      <path fillRule='evenodd' clipRule='evenodd' d='M21 12.2016C21 16.6751 17.3736 20.3016 12.9 20.3016C8.42654 20.3016 4.80005 16.6751 4.80005 12.2016C4.80005 7.72806 8.42654 4.10156 12.9 4.10156C17.3736 4.10156 21 7.72806 21 12.2016ZM27.6 11.0016C27.6 11.4986 27.1971 11.9016 26.7 11.9016C26.203 11.9016 25.8 11.4986 25.8 11.0016C25.8 10.5045 26.203 10.1016 26.7 10.1016C27.1971 10.1016 27.6 10.5045 27.6 11.0016ZM33.9001 10.7016C35.7227 10.7016 37.2001 9.2241 37.2001 7.40156C37.2001 5.57902 35.7227 4.10156 33.9001 4.10156C32.0776 4.10156 30.6001 5.57902 30.6001 7.40156C30.6001 9.2241 32.0776 10.7016 33.9001 10.7016Z' fill='white' />
      <defs>
        <filter id='filter0_i_1926_161607' x='-3' y='0.5' width='51' height='27' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx='-3' dy='3' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_1926_161607' />
        </filter>
      </defs>
    </svg>
  ),
})
