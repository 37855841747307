import { Context, Effect } from 'effect'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { ListSource } from 'features/ListBuilder/domain/ListSource'
import { ListPreview } from 'features/ListBuilder/domain/ListSource/ListPreview'

const Tag = Context.Tag('ListSourceRepo')

class ListSourceRepo extends Tag<ListSourceRepo, {
  buildList: ListSourceRepo.BuildList
  getBuildTotal: ListSourceRepo.GetBuildTotal
}>() {}

declare namespace ListSourceRepo {

  export type BuildList<T extends ListSource = ListSource> = (
    criteria: ListCriteria
  ) => Effect.Effect<ListPreview<T>>

  export type GetBuildTotal = (
    criteria: ListCriteria
  ) => Effect.Effect<number>

}

export default ListSourceRepo
