import { BoxProps, Button, Center, HStack, Spacer, Spinner, Text, VStack } from '@chakra-ui/react'
import { PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { BuildListState, BuildTotalState, useBuildTotalCount, useListPreview } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import LocationPath from 'presentation/libs/LocationPath'
import { IdWithOrigin } from 'presentation/libs/MapAndListSync/MapAndListSync'
import { useThemeSpecificValueFn } from 'presentation/main/themes/hooks/useThemeSpecificValue'
import { createPropertyListItemCardId } from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/components/PropertiesListItemCard'
import ListPreviewMapAndListSync from 'presentation/screens/ListPreviewScreen/components/ListPreviewMapAndListSync'
import { useSaveListModal } from 'presentation/screens/ListPreviewScreen/components/SaveListModal'
import { ComponentType, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'

export const createListPreviewPanel = ({
  title,
  componentPrefix,
  ItemComponent,
}: {
  title: string
  componentPrefix: string
  ItemComponent: ComponentType<{ item: PropertyListData } & BoxProps>
}) => {
  const ListPreviewPanel = () => {
    const location = useLocation()
    const parentPath = location.pathname && LocationPath.getParentPath(location.pathname)
    const totalCount = useBuildTotalCount()
    const listPreview = useListPreview()
    const saveListModalApi = useSaveListModal()
    const { tsv } = useThemeSpecificValueFn()
    const highlightedEntry = ListPreviewMapAndListSync.useStore(store => store.highlightedListItem)

    useScrollToHighlightedEntry(highlightedEntry)

    const handleOnMarkerHover = useCallback((id: PropertyListDataId) => {
      ListPreviewMapAndListSync.handleListItemHover(id)
    }, [])

    const handleOnMarkerHoverOut = useCallback(() => {
      ListPreviewMapAndListSync.handleListItemUnhover()
    }, [])

    return (
      <VStack
        bg='card.bg.1'
        w='572px'
        align='stretch'

        minW={0}
        h='full'
      >
        <HStack
          px={3}
          pt={2}
          spacing={2}

          flex='0 0 auto'
          minW={0}
          minH={0}
        >
          <Text textStyle='h4' color='accent.blue-text' flex={1}>
            Preview List
          </Text>
          <Button
            size='md'
            colorScheme={tsv(
              'special',
              'neutral',
            )}
            variant='outline'
            as={Link}
            to={parentPath}
          >
            Edit Filters
          </Button>
          <Button
            size='md'
            colorScheme='positive'
            variant='solid'
            onClick={saveListModalApi.open}
          >
            Save List
          </Button>
        </HStack>

        <BaseTabsContainer
          pt={2}
          colorScheme='xweak'
          size='md'
          display='flex'
          flexDir='column'
          alignItems='stretch'

          flex='1 1 auto'
          minW={0}
          minH='0'
        >
          <BaseTabs alignItems='stretch'>
            <BaseTab isActive textTransform='uppercase'>
              {title}
            </BaseTab>
            <Spacer />
            <Center pr={3}>
              {BuildTotalState.$is('Success')(totalCount) && (
                <Text textStyle='bodyM' color='ondark.6'>
                  {NumberLib.formatComma(totalCount.total)}
                  {' '}
                  results
                </Text>
              )}
            </Center>
          </BaseTabs>
          <BaseTabContent
            display='flex'
            flexDirection='column'
            alignItems='stretch'

            flex='1 1 auto'
            minW={0}
            minH={0}
            pt={3}

            sx={{
              boxShadow: 'none',
            }}
          >
            {BuildListState.$is('Success')(listPreview)
              ? (
                <>
                  <VStack
                    spacing={3}
                    p={3}
                    pt={0}
                    align='stretch'
                    overflow='auto'

                    minW={0}
                    minH={0}
                    flex='1 1 auto'
                  >
                    {listPreview.preview.items.map(item => (
                      <ItemComponent
                        key={item.id}
                        item={item}
                        onMouseEnter={() => handleOnMarkerHover(item.id)}
                        onMouseLeave={() => handleOnMarkerHoverOut()}
                      />
                    ))}
                  </VStack>

                  {/* <Box p={2}>
                    <Pagination
                      colorScheme='highlight'
                      currentPage={1}
                      totalPages={100}
                      maxPageButtons={10}
                      size='lg'
                      onPageChange={noop}
                    />
                  </Box> */}
                </>
              )
              : (
                <Center
                  flex='1 1 auto'
                  minW={0}
                  minH={0}
                >
                  <Spinner />
                </Center>
              )}
          </BaseTabContent>
        </BaseTabsContainer>
      </VStack>
    )
  }

  ListPreviewPanel.displayName = `${componentPrefix}.ListPreviewPanel`

  return ListPreviewPanel
}

export const useScrollToHighlightedEntry = (
  entry: IdWithOrigin | null,
) => {
  useEffect(() => {
    /**
     * ================
     * Scroll to highlighted entry when hovering markers
     * on the map
     * ================
     */
    if (entry === null) return

    if (entry.origin === 'list') return

    const element = document.getElementById(
      createPropertyListItemCardId(
        PropertyListDataId.from(entry.id),
      ),
    )

    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [entry])
}
