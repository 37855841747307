import { StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers([
    'container',
    'sideButton',
    'innerButton',
    'filler',
  ])

const baseStyle = definePartsStyle({
  container: {
    justifyContent: 'space-between',
  },
  sideButton: {
    'transition': 'all 0.2s ease-in-out',
    'textTransform': 'uppercase',
    'borderWidth': 0.125,
    'flex': 1,

    ':hover:not(:disabled)': {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    ':active:not(:disabled), &.selected:not(:disabled)': {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
  },
  innerButton: {
    'transition': 'all 0.2s ease-in-out',
    'textTransform': 'uppercase',
    'borderWidth': 0.125,
    '.ellipsis': {
      cursor: 'default',
    },
    ':hover:not(:disabled)': {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    ':active:not(:disabled), &.selected:not(:disabled)': {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
  },
  filler: {},
})

const sizes = {
  sm: definePartsStyle({
    container: {
      gap: 0.25,
    },
    innerButton: {
      ...textStyles.tagM,
      boxSize: 3,
      borderRadius: 0.5,
    },
    sideButton: {
      ...textStyles.tagM,
      'h': 3,
      'px': 1,
      '&.prev': {
        borderRightRadius: 0.5,
        borderLeftRadius: 1.5,
      },
      '&.next': {
        borderLeftRadius: 0.5,
        borderRightRadius: 1.5,
      },
    },
    filler: {
      boxSize: 3,
    },
  }),
  lg: definePartsStyle({
    container: {
      gap: 0.5,
    },
    innerButton: {
      ...textStyles.bodyMFat,
      boxSize: 4,
      borderRadius: 1,
    },
    sideButton: {
      ...textStyles.bodyMFat,
      'h': 4,
      'px': 1,
      '&.prev': {
        borderRightRadius: 1,
        borderLeftRadius: 2,
      },
      '&.next': {
        borderLeftRadius: 1,
        borderRightRadius: 2,
      },
    },
    filler: {
      boxSize: 4,
    },
  }),
}

// =============================================================================
// Color Scheme
// =============================================================================

export const colorSchemes = {
  default: () => definePartsStyle({
    sideButton: {
      'borderColor': 'grayweak.500',
      'bg': 'card.bg.4',
      'color': 'graystrong.600',
      ':hover:not(:disabled)': {
        bg: 'card.bg.2',
      },
      ':active:not(:disabled), &.selected:not(:disabled)': {
        bg: 'grayweak.400',
      },
      ':disabled': {
        borderColor: 'grayweak.500',
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
    innerButton: {
      'borderColor': 'grayweak.500',
      'bg': 'card.bg.4',
      'color': 'graystrong.600',
      ':hover:not(:disabled)': {
        bg: 'card.bg.2',
      },
      ':active:not(:disabled), &.selected:not(:disabled)': {
        bg: 'grayweak.400',
        borderColor: 'neutral.500',
      },
      ':disabled:not(.ellipsis), &.disabled-via-override': {
        borderColor: 'grayweak.500',
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
  }),
  highlight: () => definePartsStyle({
    sideButton: {
      'borderColor': 'grayweak.500',
      'bg': 'highlight.500',
      'color': 'onlight.2',
      ':hover:not(:disabled)': {
        bg: 'accent.gold.100',
      },
      ':active:not(:disabled), &.selected:not(:disabled)': {
        color: 'ondark.1',
        bg: 'accent.gold.200',
        borderColor: 'grayweak.500',
      },
      ':disabled': {
        borderColor: 'grayweak.500',
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
    innerButton: {
      'borderColor': 'grayweak.500',
      'bg': 'card.bg.4',
      'color': 'onlight.2',
      ':hover:not(:disabled)': {
        bg: 'card.bg.2',
      },
      ':active:not(:disabled), &.selected:not(:disabled)': {
        bg: 'bg.2',
        borderColor: 'neutral.500',
      },
      ':disabled:not(.ellipsis), &.disabled-via-override': {
        borderColor: 'grayweak.500',
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
  }),
}

// =============================================================================
// Variants
// =============================================================================

export const variants = {
  default: (props: StyleFunctionProps) =>
    getRecKey(props.colorScheme)(colorSchemes)?.() || {},
}

export type PaginationSize = keyof typeof sizes

export type PaginationColorSchemes = keyof typeof colorSchemes

export const paginationTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'sm',
    variant: 'default',
    colorScheme: 'default',
  },
})
