import { Box, HStack, IconButton, IconButtonProps, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { SearchIcon } from 'presentation/components/Icons'
import { SearchHistoryMeta } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/SearchHistoryMeta'
import { SearchHistoryInput } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryInput/SearchHistoryInput'
import { SearchHistorySortMenu } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistorySortMenu'
import { SearchHistoryToolbarProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryToolbar/SearchHistoryToolbar.props'
import { SeekersFilterMenu } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SeekersFilterMenu'
import { animationClasses } from 'presentation/utils/animationClasses'
import { useState } from 'react'
import { pipe } from 'remeda'

export const SearchHistoryToolbar = (props: SearchHistoryToolbarProps) => {
  const textFilter = useSearchHistoryStore(api => api.local.textFilter)

  const {
    colorScheme,
    ...boxProps
  } = props

  const hasNoTextFilter = !SearchHistoryMeta.hasTextFilter(textFilter)

  const bgColor = pipe(
    Match.value(colorScheme),
    Match.when('sidepanel', () => 'accent.hover'),
    Match.when('drawer', () => 'neutral.500'),
    Match.exhaustive,
  )

  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false)

  return (
    <Box
      {...boxProps}
      bgColor={bgColor}
      w='full'
      pointerEvents='auto'
      spacing={0}
    >
      <HStack
        h='40px' // matches the size of search history input
        spacing={0.5}
        align='center'
      >
        {isSearchInputVisible
          ? (
            <Box flexGrow={1} pr={0.5} className={animationClasses.fadeIn}>
              <SearchHistoryInput onClose={() => setIsSearchInputVisible(false)} />
            </Box>
          )
          : (
            <>
              <Text
                flexGrow={1}
                pr={0.5}
                textStyle='h4'
                color={colorScheme === 'sidepanel' ? 'graystrong.600' : 'ondark.1'}
                textAlign='left'
                className={animationClasses.fadeIn}
              >
                History
              </Text>
              <Box className={animationClasses.fadeIn}>
                <SearchHistoryButton
                  colorScheme={colorScheme}
                  onClick={() => setIsSearchInputVisible(true)}
                />
              </Box>
            </>
          )}
        {hasNoTextFilter && (
          <>
            <Box className={animationClasses.fadeIn}>
              <SeekersFilterMenu colorScheme={colorScheme} />
            </Box>
            <Box className={animationClasses.fadeIn}>
              <SearchHistorySortMenu colorScheme={colorScheme} />
            </Box>
          </>
        )}
      </HStack>
    </Box>
  )
}

const SearchHistoryButton = (props: Partial<IconButtonProps> & {
  colorScheme: SearchHistoryToolbarProps['colorScheme']
  onClick: () => void
}) => {
  const { colorScheme, ...componentProps } = props

  const iconColor = colorScheme === 'sidepanel' ? 'graystrong.600' : 'ondark.1'

  return (
    <IconButton
      aria-label='Search History'
      variant='icon-ghost'
      colorScheme='onlight'
      size='md'
      icon={(
        <SearchIcon
          boxSize={3}
          color={iconColor}
        />
      )}
      {...componentProps}
    />
  )
}
