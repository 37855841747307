import { Center, Flex, FlexProps, HStack, Spinner } from '@chakra-ui/react'
import { useSelector } from '@xstate/react'
import cn from 'classnames'
import { Array } from 'effect'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import PlanCompact from 'presentation/screens/Plans/PlanComparisonV2/PlanCompact/PlanCompact'
import PlanIntervalSwitcher from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/PlanIntervalSwitcher'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { PlanComparisonCarousel } from 'presentation/screens/Plans/PlanComparisonV2/components/PlanComparisonCarousel'
import { HeadingText, SubheadingText } from 'presentation/screens/Plans/PlanComparisonV2/components/common'
import { fadeIn, fadeInDown, fast, faster } from 'presentation/utils/animationClasses'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { Fragment, PropsWithChildren } from 'react'
import UnlockBusinessSectionViewModel from './UnlockBusinessSectionViewModel'

const UnlockBusinessSection = () => {
  // @NOTE: should move to view model instead?
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const isLoading = useSelector(actor, snap => snap.hasTag('loading'))

  const vm = UnlockBusinessSectionViewModel.usePresenter()

  const plans = Array.map(vm, (planCompactViewModel, i) => (
    <PlanCompact
      key={i}
      viewModel={planCompactViewModel}
    />
  ))

  return isLoading
    ? (
      <SectionContainer minH='1280px' h='1px'>
        <Center py='4' h='full'>
          <Spinner color='graystrong.500' />
        </Center>
      </SectionContainer>
    )
    : (
      <SectionContainer>
        <ScrollAnimation
          className={cn(fadeInDown, fast)}
        >
          <HeadingText color='graystrong.500'>
            <Breakpoint
              mobSm={(
                <Fragment>
                  Unlock your business&rsquo;s full
                  <br />
                  potential with our feature-packed offers
                </Fragment>
              )}
              mob={(
                <Fragment>
                  Unlock your business&rsquo;s full potential with
                  <br />
                  {' '}
                  our feature-packed offers
                </Fragment>
              )}
            />
          </HeadingText>
          <ScrollAnimation
            className={cn(fadeIn, faster)}
          >
            <SubheadingText color='graystrong.200'>
              <Breakpoint
                mobSm={(
                  <Fragment>
                    Designed to help you grow your business
                    <br />
                    with ease
                  </Fragment>
                )}
                mob={(
                  <Fragment>
                    Designed to help you grow your business with ease
                  </Fragment>
                )}
              />
            </SubheadingText>
          </ScrollAnimation>
        </ScrollAnimation>
        <ScrollAnimation
          className={cn(fadeIn, faster)}
          staggerFactor='longer'
        >
          <PlanIntervalSwitcher
            monthlyTextColor='graystrong.400'
          />
        </ScrollAnimation>
        <Breakpoint
          mobSm={(
            <PlanComparisonCarousel>
              {plans}
            </PlanComparisonCarousel>
          )}
          dtLg={(
            <StaticStack>
              {plans}
            </StaticStack>
          )}
        />
      </SectionContainer>
    )
}

export default UnlockBusinessSection

const StaticStack = ({ children }: PropsWithChildren) => (
  <HStack
    spacing='2'
    justifyContent='center'
    overflow='visible'
  >
    {children}
  </HStack>
)

const SectionContainer = ({ children, ...props }: FlexProps) => (
  <Flex
    id='second-choose-plan'
    backgroundColor='graycool.100'
    flexDirection='column'
    {...mbpg({
      mobSm: {
        pt: '5',
        pb: '4',
        gap: '4',
      },
      mob: {
        pt: '5',
        pb: '4',
      },
      tabSm: {
        pt: '7',
        pb: '6',
      },
      dtSm: {
        gap: '80px',
      },
      dtLg: {
        pt: '112px',
        pb: '120px',
      },
    })}
    overflow='hidden'
    {...props}
  >
    {children}
  </Flex>
)
