import { Layer } from 'effect'
import addToCartLive from 'presentation/screens/Plans/PlanComparisonV2/domain/PlanCheckoutService/addToCartLive'
import getActiveProductLive from 'presentation/screens/Plans/PlanComparisonV2/domain/SubscriptionProductRepo/getActiveProduct/getActiveProductLive'
import getProductsLive from 'presentation/screens/Plans/PlanComparisonV2/domain/SubscriptionProductRepo/getProducts/getProductsLive'

namespace AppRuntimeLayer {
  export const makeLive = () =>
    Layer.mergeAll(
      getActiveProductLive,
      getProductsLive,
      addToCartLive,
    )

  export type Self = ReturnType<typeof makeLive>

  export type Requirements = Layer.Layer.Success<Self>
}

export default AppRuntimeLayer
