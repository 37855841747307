import NearbyBuyersRoutes from 'features/NearbyBuyers/routes/NearbyBuyersRoutes'
import RouteIdUtils from 'presentation/main/Router/RouteIdUtils'

export const ROUTE_IDS = {
  LIST: RouteIdUtils.prefixList('LIST'),
  LIST_BUILDER: RouteIdUtils.prefixList('LIST_BUILDER'),
  LIST_BUILDER_PREVIEW: RouteIdUtils.prefixList('LIST_BUILDER_PREVIEW'),

  DETAILS: RouteIdUtils.prefixProperty('DETAILS'),
  COMPS: RouteIdUtils.prefixProperty('COMPS'),
  SKIPTRACE: RouteIdUtils.prefixProperty('SKIPTRACE'),
  ...NearbyBuyersRoutes.IDS,
} as const

export type RouteId = typeof ROUTE_IDS[keyof typeof ROUTE_IDS]
