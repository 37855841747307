import { BoxProps, Button, ButtonProps, Flex, Text, TextProps } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { RefreshIcon } from 'presentation/components/Icons'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { shallow } from 'zustand/shallow'

type CommonProps = { isCompact?: boolean }

export type CMADataUpdateMetaProps = BoxProps & CommonProps & {
  textProps?: CMADataUpdateMetaTextProps
  buttonProps?: CMADataUpdateMetaButtonProps
}
export type CMADataUpdateMetaTextProps = TextProps & CommonProps & {
  isShorterText?: boolean
}
export type CMADataUpdateMetaButtonProps = ButtonProps & CommonProps

export const CMADataUpdateMeta = ({
  isCompact,
  textProps,
  buttonProps,
  ...props
}: CMADataUpdateMetaProps) => (
  <Flex gap={1} alignItems='center' {...props}>
    <CMADataUpdateDate isCompact={isCompact} {...textProps} />
    <CMADataUpdateButton isCompact={isCompact} {...buttonProps} />
  </Flex>
)

export const CMADataUpdateDate = ({
  isCompact,
  isShorterText,
  ...props
}: CMADataUpdateMetaTextProps) => {
  const estimateInfo = useCMAStore(api => ({
    isUpdating: api.local.report.status === 'regenerating',
    lastUpdated: api.local.report.status === 'loaded'
      ? api.local.report.data.updatedAt
      : undefined,
  }), shallow)

  const formattedDate = dayjs(estimateInfo.lastUpdated).format('MM.DD.YY')

  return (
    <Text
      textStyle='bodyM'
      color={mbp({ mobSm: isCompact ? 'ondark.3' : 'ondark.6', tabSm: 'ondark.6' })}
      {...props}
    >
      {estimateInfo.isUpdating
        ? 'Updating...'
        : isShorterText
          ? `Created: ${formattedDate}`
          : `Created on: ${formattedDate}`}
    </Text>
  )
}

export const CMADataUpdateButton = ({
  isCompact,
  ...props
}: CMADataUpdateMetaButtonProps) => {
  const estimateInfo = useCMAStore(api => ({
    update: api.actions.regenerateReport.execute,
    isUpdating: api.local.report.status === 'regenerating',
  }), shallow)

  const handleOnUpdate = () => {
    void estimateInfo.update()
  }

  const buttonColorScheme = useSwitchBreakpoint({
    mobSm: isCompact ? 'ondark' : 'neutral',
    tabSm: 'neutral',
  })

  const activeTab = useActiveEntriesTab(selectActiveTab)

  if (activeTab === 'REPORT') return null

  return (
    <Button
      variant='ghost'
      colorScheme={buttonColorScheme}
      leftIcon={<RefreshIcon boxSize={2} />}
      onClick={handleOnUpdate}
      isDisabled={estimateInfo.isUpdating}
      size='xs'
      {...props}
    >
      Update
    </Button>
  )
}
