import { BillingCardsPaymentMethodBrands } from '__generated__/graphql'

export const formatCardsPaymentMethodBrands = (brand: BillingCardsPaymentMethodBrands): string => {
  switch (brand) {
  case BillingCardsPaymentMethodBrands.Amex:
    return 'American Express'
  case BillingCardsPaymentMethodBrands.Diners:
    return 'Diners Club'
  case BillingCardsPaymentMethodBrands.Discover:
    return 'Discover'
  case BillingCardsPaymentMethodBrands.Jcb:
    return 'JCB'
  case BillingCardsPaymentMethodBrands.Mastercard:
    return 'Mastercard'
  case BillingCardsPaymentMethodBrands.Unionpay:
    return 'UnionPay'
  case BillingCardsPaymentMethodBrands.Visa:
    return 'Visa'
  default:
    return ''
  }
}
