import { gql } from '__generated__'

export const GET_ATTEMPT_BATCH_SKIPTRACE_MISSING_DATA_GQL = gql(/* GraphQL */`
  query GetAttemptBatchSkiptraceMissingData {
    myEnterprise {
      subscription {
        ... on BillingSubscriptionActive {
          plan {
            name
            product {
              features {
                ... on SubscriptionFeatureSkiptrace {
                  __typename
                  prices {
                    price
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)
