import NearbyBuyersScreen from 'features/NearbyBuyers/views/NearbyBuyersScreen/NearbyBuyersScreen'
import { LayoutBody } from 'presentation/layouts/Layout/components/LayoutBody'
import { useConfigScreenTitle } from 'presentation/libs/useConfigScreenTitle'
import { ConfirmEmailScreen } from 'presentation/screens/AuthScreens/ConfirmEmailScreen'
import { ResetPasswordScreen } from 'presentation/screens/AuthScreens/ResetPasswordScreen'
import { SendResetPasswordEmailScreen } from 'presentation/screens/AuthScreens/SendPasswordResetEmailScreen/SendResetPasswordEmailScreen'
import { SignInScreen } from 'presentation/screens/AuthScreens/SignInScreen'
import { SignUpScreen } from 'presentation/screens/AuthScreens/SignUpScreen'
import { Billing } from 'presentation/screens/Billing'
import { CancelSubscription } from 'presentation/screens/CancelSubscription'
import { CompsScreen } from 'presentation/screens/CompsScreen/CompsScreen'
import D4DDownloadScreen from 'presentation/screens/D4DDownloadScreen/D4DDownloadScreen'
import { DeleteAccountScreen } from 'presentation/screens/DeleteAccountScreen/DeleteAccountScreen'
import { TermsOfUseScreen } from 'presentation/screens/LegalScreens/TermsOfUseScreen/TermsOfUseScreen'
import { ListBuilderComingSoonScreen } from 'presentation/screens/ListBuilderScreen/ListBuilderComingSoonScreen'
import { ListBuilderScreen } from 'presentation/screens/ListBuilderScreen/ListBuilderScreen'
import { ListPreviewScreen } from 'presentation/screens/ListPreviewScreen/ListPreviewScreen'
import { ListScreen } from 'presentation/screens/ListScreen/ListScreen'
import { ListsScreen } from 'presentation/screens/ListsScreen/ListsScreen'
import { ControlledMembersScreen } from 'presentation/screens/MembersScreen/MembersScreen.controlled'
import { PricingScreenWithNotice } from 'presentation/screens/Plans/PricingScreen'
import { Playground } from 'presentation/screens/Playground/Playground'
import { PropertyDetailsFromAddressScreen } from 'presentation/screens/PropertyDetailsFromAddressScreen/PropertyDetailsFromAddressScreen'
import { PropertyDetailsFromParcelIdScreen } from 'presentation/screens/PropertyDetailsFromParcelIdScreen/PropertyDetailsFromParcelIdScreen'
import { PropertyDetailsScreen } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsScreen'
import { RenewTrialScreen } from 'presentation/screens/RenewTrialScreen/RenewTrialScreen'
import { SearchHistoryScreen } from 'presentation/screens/SearchHistoryScreen/SearchHistoryScreen'
import { SkiptraceScreen } from 'presentation/screens/SkiptraceScreen'
import { ReactNode } from 'react'

export const LoginRouteElement = () => (
  <AddTitle
    layoutTitle='Sign In'
    htmlTitle='Sign In'
    element={<SignInScreen />}
  />
)

export const RegisterRouteElement = () => (
  <AddTitle
    layoutTitle='Sign Up'
    htmlTitle='Sign Up'
    element={<SignUpScreen />}
  />
)

export const ConfirmEmailRouteElement = () => (
  <AddTitle
    layoutTitle='Confirm Email'
    htmlTitle='Confirm Email'
    element={<ConfirmEmailScreen />}
  />
)

export const SendResetPasswordEmailRouteElement = () => (
  <AddTitle
    layoutTitle='Send Reset Password Email'
    htmlTitle='Send Reset Password Email'
    element={<SendResetPasswordEmailScreen />}
  />
)

export const ResetPasswordRouteElement = ({
  isForRetrial,
}: {
  isForRetrial?: boolean
}) => (
  <AddTitle
    layoutTitle='Reset Password'
    htmlTitle='Reset Password'
    element={<ResetPasswordScreen isForRetrial={isForRetrial} />}
  />
)

export const HomeRouteElement = () => (
  <AddTitle
    layoutTitle='Home'
    htmlTitle='Home'
    element={null}
  />
)

export const CancelSubscriptionRouteElement = () => (
  <AddTitle
    layoutTitle='Settings'
    htmlTitle='Cancel Plan'
    element={<CancelSubscription />}
  />
)

export const BillingRouteElement = () => (
  <AddTitle
    layoutTitle='Settings'
    htmlTitle='Plans & Billing'
    element={<Billing />}
  />
)

export const MembersRouteElement = () => (
  <AddTitle
    layoutTitle='Settings'
    htmlTitle='Team Members'
    element={<ControlledMembersScreen />}
  />
)

export const PricingRouteElement = () => (
  <AddTitle
    layoutTitle='Settings'
    htmlTitle='Pricing'
    element={<PricingScreenWithNotice />}
  />
)

export const NotFoundRouteElement = () => (
  <AddTitle
    layoutTitle='Not Found'
    htmlTitle='Not Found'
    element={(
      <LayoutBody p={4} textStyle='h1-h-xb'>
        404
      </LayoutBody>
    )}
  />
)

export const PlaygroundRouteElement = () => (
  <AddTitle
    layoutTitle='Dev Playground'
    htmlTitle='Dev Playground'
    element={<Playground />}
  />
)

export const SearchRouteElement = () => (
  <AddTitle
    layoutTitle='Property Data'
    htmlTitle='Search'
    element={<SearchHistoryScreen />}
  />
)

export const PropertyDetailsElement = () => (
  <AddTitle
    layoutTitle='Property Data'
    htmlTitle='Property Data'
    element={<PropertyDetailsScreen />}
  />
)

export const PropertyDetailsFromAddressElement = () => (
  <AddTitle
    layoutTitle='Property Data'
    htmlTitle='Property Data'
    element={<PropertyDetailsFromAddressScreen />}
  />
)

export const PropertyDetailsFromParcelIdElement = () => (
  <AddTitle
    layoutTitle='Property Data'
    htmlTitle='Property Data'
    element={<PropertyDetailsFromParcelIdScreen />}
  />
)

export const CompsElement = () => (
  <AddTitle
    layoutTitle='Property Data'
    htmlTitle='Comparable Sales'
    element={<CompsScreen />}
  />
)

export const SkiptraceElement = () => (
  <AddTitle
    layoutTitle='Skiptrace'
    htmlTitle='Skiptrace'
    element={<SkiptraceScreen />}
  />
)

export const ListBuilderComingSoonElement = () => (
  <AddTitle
    layoutTitle='List Builder'
    htmlTitle='List Builder'
    element={<ListBuilderComingSoonScreen />}
  />
)

export const ListBuilderElement = () => (
  <AddTitle
    layoutTitle='List Builder'
    htmlTitle='List Builder'
    element={<ListBuilderScreen />}
  />
)

export const ListsElement = () => (
  <AddTitle
    layoutTitle='List Manager'
    htmlTitle='List Manager'
    element={<ListsScreen />}
  />
)

export const ListPreviewElement = () => (
  <AddTitle
    layoutTitle='List Builder'
    htmlTitle='List Builder'
    element={<ListPreviewScreen />}
  />
)

export const ListElement = () => (
  <AddTitle
    layoutTitle='List Manager'
    htmlTitle='List Manager'
    element={<ListScreen />}
  />
)

export const NearbyBuyersElement = () => (
  <AddTitle
    layoutTitle='Cash Buyers'
    htmlTitle='Cash Buyers'
    element={<NearbyBuyersScreen />}
  />
)

export const DrivingForDollarsElement = () => (
  <AddTitle
    layoutTitle='Driving for Dollars'
    htmlTitle='Driving for Dollars'
    element={<D4DDownloadScreen />}
  />
)

export const RenewTrialRouteElement = () => (
  <AddTitle
    layoutTitle='Propelio'
    htmlTitle='Propelio'
    element={<RenewTrialScreen />}
  />
)

export const TermsOfUseElement = () => (
  <AddTitle
    layoutTitle='Terms of Use'
    htmlTitle='Terms of Use'
    element={<TermsOfUseScreen />}
  />
)

const AddTitle = ({
  element,
  htmlTitle,
  layoutTitle,
}: {
  element: ReactNode
  htmlTitle: string
  layoutTitle: string
}) => {
  useConfigScreenTitle({ htmlTitle, layoutTitle })

  return <>{element}</>
}

export const DeleteAccountElement = () => (
  <AddTitle
    layoutTitle='Delete Account'
    htmlTitle='Delete Account'
    element={<DeleteAccountScreen />}
  />
)
