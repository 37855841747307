import { Array, Data, Effect, Layer, pipe } from 'effect'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import MarketingListMemberRepo from 'features/ListBuilder/domain/repository/MarketingListMemberRepo'
import DeleteByIdResolver from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/DeleteByIdResolver/DeleteByIdResolver'
import GetMembersByPageResolver from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/GetMembersByPageResolver/GetMembersByPageResolver'
import MarketingListMemberRequest from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/MarketingListMemberRequest'

const deleteById = (listId: MarketingListId, memberId: MarketingListMemberId) => Effect.request(
  new MarketingListMemberRequest.DeleteById({ listId, memberId }),
  DeleteByIdResolver,
)

const MarketingListMemberRepoLive = Layer.succeed(MarketingListMemberRepo, {
  getByPage: (listId, page) => Effect.request(
    new MarketingListMemberRequest.GetByPage({ listId, page }),
    GetMembersByPageResolver,
  ),
  deleteByIds: (listId, memberIds) => pipe(
    Effect.sync(() => Array.map(memberIds, memberId => pipe(
      deleteById(listId, memberId),
      Effect.andThen(Data.struct({ _tag: 'success', memberId })),
      Effect.catchTag('ListMemberNotFoundError', () =>
        Effect.succeed(Data.struct({ _tag: 'failed', memberId }))),
    ))),
    Effect.flatMap(requests => Effect.all(requests, { concurrency: 'unbounded' })),
    Effect.flatMap(Effect.partition(response =>
      response._tag === 'success'
        ? Effect.succeed(response.memberId)
        : Effect.fail(response.memberId))),
    Effect.flatMap(([failedIds, successfulIds]) =>
      Effect.succeed({ listId, successfulIds, failedIds } satisfies MarketingListMemberRepo.DeleteByIdsResult)),
  ),
})

export default MarketingListMemberRepoLive
