import { Menu, MenuButton, MenuItemOption, MenuOptionGroup, Portal } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { SwitchTabBadge, SwitchTabBadgeProps, SwitchTabButton } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useSwitchTabsContext } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { TabbedCards } from 'presentation/screens/PropertyDetailsScreen/components/TabbedCards/TabbedCards'
import { LandTabContent } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/LandTabContent'
import { LiensAndOwnersTabContent } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/LiensAndOwnersTabContent'
import { PreforeclosureTabContent } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/PreforeclosureTabContent'
import { PropertyInfoTabContent } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/PropertyInfoTabContent'
import { LAND_KEY, LIENS_AND_OWNERS_KEY, MORE_TAB_KEY, PREFORECLOSURES_KEY, PROPERTY_INFO_KEY, TAXATION_KEY } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection.const'
import { TaxationTabContent } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TaxationTabContent'
import { shallow } from 'zustand/shallow'

export type TabKey =
  | typeof PREFORECLOSURES_KEY
  | typeof PROPERTY_INFO_KEY
  | typeof LAND_KEY
  | typeof LIENS_AND_OWNERS_KEY
  | typeof TAXATION_KEY

export type TabKeyWithMore = TabKey | typeof MORE_TAB_KEY

export type Props = {
  __DANGER_STORYBOOK_ONLY__?: {
    initialTabKey?: TabKey
  }
}

/**
 * @TODO Detect if tabs other than preforeclosures have data and show badge
 * @TODO Smarter initial tab selection, detect contents of all tabs
 */
export const TablesSection = (props: Props) => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const initialTabKey = usePropertyDetailsStore(state => {
    const property = selectProperty(state)
    const initialTabOverride = props.__DANGER_STORYBOOK_ONLY__?.initialTabKey

    if (initialTabOverride)
      return initialTabOverride

    const hasPreforeclosures = property?.status === 'with-details'
      && property.preforeclosures.length > 0

    if (hasPreforeclosures) return PREFORECLOSURES_KEY

    return PROPERTY_INFO_KEY
  }, shallow)

  if (!initialTabKey) return null

  return (
    <TabbedCards.TabsWrapper initialActiveTabKey={initialTabKey}>
      <TabbedCards.TabButtons>
        <ResponsiveTabButtons />
      </TabbedCards.TabButtons>
      <TabbedCards.TabContents>
        <PreforeclosureTabContent />
        <PropertyInfoTabContent />
        <LandTabContent />
        <LiensAndOwnersTabContent />
        <TaxationTabContent />
      </TabbedCards.TabContents>
    </TabbedCards.TabsWrapper>
  )
}

const ResponsiveTabButtons = () => {
  const tabs: [
    TabKeyWithMore,
    (props: { isHidden: boolean }) => JSX.Element | null,
  ][] = [
    [PREFORECLOSURES_KEY, ({ isHidden }) => (
      <PreforeclosuresTab isHidden={isHidden} key={0} />
    )],
    [PROPERTY_INFO_KEY, ({ isHidden }) => (
      <PropertyInfoTab isHidden={isHidden} key={1} />
    )],
    [LAND_KEY, ({ isHidden }) => (
      <LandTab isHidden={isHidden} key={2} />
    )],
    [LIENS_AND_OWNERS_KEY, ({ isHidden }) => (
      <LiensAndOwnersTab isHidden={isHidden} key={3} />
    )],
    [TAXATION_KEY, ({ isHidden }) => (
      <TaxationTab isHidden={isHidden} key={4} />
    )],
    [MORE_TAB_KEY, ({ isHidden }) => isHidden ? null : (<MoreTabButton key={5} />)],
  ]

  const visibleKeys: TabKeyWithMore[] = useSwitchBreakpoint({
    mobSm: [PREFORECLOSURES_KEY, MORE_TAB_KEY],
    mob: [PREFORECLOSURES_KEY, PROPERTY_INFO_KEY, MORE_TAB_KEY],
    tabSm: [PREFORECLOSURES_KEY, PROPERTY_INFO_KEY, LAND_KEY, MORE_TAB_KEY],
    dtSm: [PREFORECLOSURES_KEY, PROPERTY_INFO_KEY, LAND_KEY, LIENS_AND_OWNERS_KEY, TAXATION_KEY],
    dtLg: [PREFORECLOSURES_KEY, PROPERTY_INFO_KEY, LAND_KEY, LIENS_AND_OWNERS_KEY, TAXATION_KEY],
  }) || [PREFORECLOSURES_KEY, MORE_TAB_KEY]

  const { activeTabKey } = useSwitchTabsContext()

  const typedActiveTabKey = activeTabKey as TabKey

  let shiftedKeys = visibleKeys
  if (!visibleKeys.includes(typedActiveTabKey)) {
    /**
     * We will replace the second from last tab
     * (not the last because that's the more button)
     * so that the active tab is always visible
     */
    const secondFromLastIndex = visibleKeys.length - 2
    shiftedKeys = [
      ...visibleKeys.slice(0, secondFromLastIndex),
      typedActiveTabKey,
      ...visibleKeys.slice(secondFromLastIndex + 1),
    ]
  }

  const visibleTabs = tabs.map(([key, render]) =>
    render({
      isHidden: !shiftedKeys.includes(key),
    }))

  return <>{visibleTabs}</>
}

const CountBadge = ({ count, ...props }: { count: number } & SwitchTabBadgeProps) => (
  <SwitchTabBadge colorScheme={count > 0 ? 'negative' : 'positivesat'} {...props}>
    {count}
  </SwitchTabBadge>
)

// @TODO
// const BooleanBadge = ({ hasData, ...props }: { hasData: boolean } & TabBadgeProps) =>
//   hasData
//     ? null
//     : (<TabBadge colorScheme='negative' {...props}>0</TabBadge>)

const PreforeclosuresTab = ({ isHidden }: {
  isHidden?: boolean
}) => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const count = usePropertyDetailsStore(state => {
    const property = selectProperty(state)
    if (!property || property.status !== 'with-details') return 0
    return property.preforeclosures.length
  })

  return (
    <TabbedCards.TabButton tabKey={PREFORECLOSURES_KEY} isHidden={isHidden}>
      <CountBadge count={count} />
      Preforeclosures
    </TabbedCards.TabButton>
  )
}

const PropertyInfoTab = ({ isHidden }: {
  isHidden?: boolean
}) => (
  <TabbedCards.TabButton tabKey={PROPERTY_INFO_KEY} isHidden={isHidden}>
    {/* @TODO */}
    {/* <BooleanBadge hasData={hasData} /> */}
    Property Info
  </TabbedCards.TabButton>
)
const LandTab = ({ isHidden }: {
  isHidden?: boolean
}) => (
  <TabbedCards.TabButton tabKey={LAND_KEY} isHidden={isHidden}>
    {/* @TODO */}
    {/* <BooleanBadge hasData={hasData} /> */}
    Land Info
  </TabbedCards.TabButton>
)

const TaxationTab = ({ isHidden }: {
  isHidden?: boolean
}) => (
  <TabbedCards.TabButton tabKey={TAXATION_KEY} isHidden={isHidden}>
    {/* @TODO */}
    {/* <BooleanBadge hasData={hasData} /> */}
    Tax Info
  </TabbedCards.TabButton>
)

const LiensAndOwnersTab = ({ isHidden }: {
  isHidden?: boolean
}) => (
  <TabbedCards.TabButton tabKey={LIENS_AND_OWNERS_KEY} isHidden={isHidden}>
    {/* <BooleanBadge hasData={hasData} /> */}
    Liens & Owners
  </TabbedCards.TabButton>
)

const MoreTabButton = () => {
  const { activeTabKey, setActiveTabKey } = useSwitchTabsContext()
  const preforeclosuresCount = usePropertyDetailsStore(state => {
    const property = useSelectPropertyByRouteLeadId()(state)
    if (!property || property.status !== 'with-details') return 0
    return property.preforeclosures.length
  }, shallow)

  return (
    <Menu>
      <SwitchTabButton as={MenuButton} hasContent={false}>More+</SwitchTabButton>

      <Portal>
        <ResponsiveMenuList>
          <MenuOptionGroup
            defaultValue={activeTabKey || ''}
            type='radio'
            onChange={value => {
              if (typeof value !== 'string') return
              setActiveTabKey(value)
            }}
          >
            <MenuItemOption value={PREFORECLOSURES_KEY}>
              Preforeclosures
              <CountBadge
                count={preforeclosuresCount}
                pos='static'
                display='inline-flex'
                ml={1}
                mt={-0.5}
              />
            </MenuItemOption>
            <MenuItemOption value={PROPERTY_INFO_KEY}>
              Property Info
              {/* <BooleanBadge hasData={hasPropertyInfo} pos='static' /> */}
            </MenuItemOption>
            <MenuItemOption value={LAND_KEY}>
              Land Info
              {/* <BooleanBadge hasData={hasLand} pos='static' /> */}
            </MenuItemOption>
            <MenuItemOption value={LIENS_AND_OWNERS_KEY}>
              Liens & Owners
              {/* <BooleanBadge hasData={hasTransfers} pos='static' /> */}
            </MenuItemOption>
            <MenuItemOption value={TAXATION_KEY}>
              Tax Info
              {/* <BooleanBadge hasData={hasTaxation} pos='static' /> */}
            </MenuItemOption>
          </MenuOptionGroup>
        </ResponsiveMenuList>
      </Portal>
    </Menu>
  )
}
