import { createIcon } from '@chakra-ui/react'

export const UpwardTrendIcon = createIcon({
  displayName: 'UpwardTrendIcon',
  viewBox: '0 0 16 16',
  path: (
    <g fill='none'>
      <path
        d='M15.3346 4L9.0013 10.3333L5.66797 7L0.667969 12'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.332 4L15.332 4V8'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>

  ),
})
