import { Box, Button, Checkbox, Flex } from '@chakra-ui/react'
import { LayoutBody } from 'presentation/layouts/Layout/components/LayoutBody'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { CancelTableCard } from 'presentation/screens/CancelSubscription/CancelTableCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import React from 'react'
import { Link } from 'react-router-dom'

export const DeleteAccountScreen = () => {
  const [hasAcknowledged, setHasAcknowledged] = React.useState(false)

  return (
    <LayoutBody>
      <Box
        flex='1 0 0'
        mt={mbp({ mobSm: '3', tabSm: '5', dtSm: '7', dtLg: '80px' })}
        mx={mbp({ mobSm: '1', mob: '3', tabSm: '5', dtSm: '7', dtLg: '0' })}
      >
        <CancelTableCard leadsLimit={null} />
      </Box>

      <Box
        mt={mbp({ mobSm: '3', tabSm: '5', dtSm: '6', dtLg: '80px' })}
      >
        <Flex
          bgColor={hasAcknowledged ? 'accent.hover' : 'negative.100'}
          justifyContent='center'
        >
          <Box
            px={mbp({
              mobSm: '2',
              mob: '3',
              tabSm: '5',
              dtSm: '7',
            })}
            py={mbp({
              mobSm: '2',
              mob: '2',
              dtSm: '3',
            })}
            maxW='1080px'
          >
            <Checkbox
              textStyle={mbp({ mobSm: 'body2', tabSm: 'old.h4', dtSm: 'old.h3' })}
              color='graystrong.500'
              isChecked={hasAcknowledged}
              spacing='2'
              onChange={() => setHasAcknowledged(!hasAcknowledged)}
            >
              I acknowledge all of my account information, including skip data,
              websites, saved properties and more will be
              {' '}
              <em>permanently</em>
              {' '}
              deleted.
            </Checkbox>
          </Box>
        </Flex>
        <Flex
          bgColor='highlight.500'
          justifyContent='center'
          px={mbp({
            mobSm: '2',
            mob: '5',
            tabSm: '5',
            dtSm: '7',
          })}
          pt={mbp({
            mobSm: '3',
            tabSm: '4',
            dtSm: '5',
            dtLg: '6',
          })}
          pb={mbp({
            mobSm: '4',
            dtSm: '7',
            dtLg: '80px',
          })}
        >
          <Flex
            flex='1'
            justifyContent={mbp({
              mobSm: 'center',
              mob: 'space-between',
            })}
            alignItems='center'
            maxW={mbp({ dtSm: '1136px', dtLg: '896px' })}
            flexDir={mbp({
              mobSm: 'column',
              mob: 'row',
            })}
            gap={mbp({
              mobSm: '2',
              dtSm: '4',
            })}
          >
            <Button
              size={mbp({ mobSm: 'sm', tabSm: 'md', dtSm: 'lg' })}
              variant='solid'
              colorScheme='neutral'
              w={mbp({ mobSm: 'full', mob: 'auto' })}
              textTransform='uppercase'
              as={Link}
              to='/'
            >
              Keep My Account
            </Button>

            <Button
              size={mbp({ mobSm: 'sm', tabSm: 'md', dtSm: 'lg' })}
              variant='solid'
              colorScheme='negative'
              w={mbp({ mobSm: 'full', mob: 'auto' })}
              textTransform='uppercase'
              isDisabled={!hasAcknowledged}
              onClick={() => SupportWidget.openWidget({ topic: 'delete-account' })}
            >
              Ask Support For Deletion
            </Button>
          </Flex>
        </Flex>
      </Box>
    </LayoutBody>
  )
}
