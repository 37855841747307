import { INTERCOM_APP_ID } from 'presentation/const/env.const'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { suppotModalStore } from 'presentation/main/globalModals/SupportModal/SupportModal.api'
import { useEffect } from 'react'
import { noop } from 'remeda'

let shouldHideFloatingButton = false

export type SupportTopic = 'pricing' | 'support' | 'beta-feedback' | 'email-confirmation' | 'delete-account'

const setup = async () =>
  await loadIntercom().then(intercom =>
    intercom('boot', {
      app_id: INTERCOM_APP_ID,
      hide_default_launcher: shouldHideFloatingButton,
    }))
    .catch(noop)

export const SupportWidget = {
  setup,

  recordLoggedInUser: async ({
    email,
    name,
    userId,
  }: {
    email: string
    name: string
    userId: string
  }) =>
    await loadIntercom()
      .then(intercom =>
        intercom('update', {
          email,
          name,
          user_id: userId,
        }))
      .catch(noop),

  clearLoggedInUser: async () =>
    await loadIntercom()
      .then(intercom => intercom('shutdown')),

  openWidget: async ({
    topic,
  }: {
    topic: SupportTopic
  }) => {
    if (intercomStatus === 'error' || intercomStatus === 'loading') {
      suppotModalStore.setState({ isOpen: true })
      return
    }

    await loadIntercom()
      .then(intercom => {
        intercom('update', {
          [`topic__${topic}__timestamp`]: Date.now(),
        })

        intercom('show')
      })
      .catch(() => {
        suppotModalStore.setState({ isOpen: true })
      })
  },

  closeWidget: async () =>
    await loadIntercom()
      .then(intercom => {
        intercom('hide')
      })
      .catch(noop),

  /**
   * @TODO On login, registration and other auth screens,
   *   we might want to show intercom button regardless
   */
  useSetup: () => {
    const isTabSmOrSmaller = useSwitchBreakpoint({
      mobSm: true,
      dtSm: false,
    }) ?? true

    // Hacky, cheap way to track viewport for hiding/showing intercom button when restarting
    shouldHideFloatingButton = isTabSmOrSmaller

    useEffect(() => {
      void loadIntercom()
        .then(intercom => {
          intercom('update', {
            hide_default_launcher: shouldHideFloatingButton,
          })
        })
        .catch(noop)
    }, [shouldHideFloatingButton])

    // Setup Intercom
    useEffect(() => {
      void SupportWidget.setup()
    }, [])
  },

  getStatus: () => intercomStatus,

  wait: async () => await loadIntercom().then(noop),
}

/**
 * returns `() => Intercom` instead of just `Intercom` because window.Intercom
 * reference could still be unreliable/not working, even with `.booted` check.
 *
 * As workaround we always try to get the latest reference from window.Intercom.
 */
let intercomPromise = null as Promise<() => Intercom_.IntercomStatic> | null
let intercomStatus: 'loading' | 'success' | 'error' = 'loading'

const loadIntercom = async () => {
  intercomPromise = intercomPromise || new Promise((resolve, reject) => {
    if (window.Intercom?.booted)
      return resolve(() => window.Intercom)

    const maxRetries = 5000 / 500
    let retries = 0
    const INTERVAL = 500
    const intervalId = setInterval(() => {
      if (window.Intercom?.booted) {
        resolve(() => window.Intercom)
        intercomStatus = 'success'
        clearInterval(intervalId)
        return
      }

      if (++retries > maxRetries) {
        reject(new Error('Intercom failed'))
        intercomStatus = 'error'
        clearInterval(intervalId)
      }
    }, INTERVAL)
  })

  return await intercomPromise.then(getIntercom => getIntercom())
}
