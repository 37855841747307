import { Box, BoxProps, Flex, GridItem, Text, chakra } from '@chakra-ui/react'
import { useSwitchBreakpoint, useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useCompsListPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { px } from 'presentation/utils/px'
import { PropsWithChildren } from 'react'

const LOCAL_Z_INDEX = {
  HEADER: 4,
  SIDEPANEL: 2,
  FULLSCREEN: 3,
}

export const useSidePanelWidth = () =>
  useSwitchBreakpoint({
    mobSm: 572,
    dtLg: 604,
  }) ?? 572

export const SIDEPANEL_TRANSITION_DURATION = 100

export type CMASidePanelDrawerProps = PropsWithChildren

type SidePanelDrawerState = {
  isPanelOpen: boolean
  isPanelButtonVisible: boolean
}

export const CMASidePanelDrawer = ({ children }: CMASidePanelDrawerProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const { isPanelOpen, setPanelOpen } = useCompsListPanelStore()

  const sidePanelState = sbp<SidePanelDrawerState>({
    dtLg: {
      isPanelOpen: true,
      isPanelButtonVisible: false,
    },
  }) ?? {
    isPanelOpen,
    isPanelButtonVisible: true,
  }

  const sidePanelWidth = useSidePanelWidth()

  return (
    <GridItem
      minH={0}
      h='full'
      position='relative'
      w={px(sidePanelWidth)}
      transition={`margin-right ${SIDEPANEL_TRANSITION_DURATION}ms ease`}
      mr={sidePanelState.isPanelOpen ? '0' : `-${px(sidePanelWidth - 40)}`}
      justifySelf='end'
    >
      <Box
        minH={0}
        h='full'
        position='relative'
        zIndex={LOCAL_Z_INDEX.SIDEPANEL + 1}
      >
        {children}
        {sidePanelState.isPanelButtonVisible && (
          <PanelButton
            isOpen={sidePanelState.isPanelOpen}
            onClick={() => setPanelOpen(!sidePanelState.isPanelOpen)}
          />
        )}
      </Box>
    </GridItem>
  )
}

type PanelButtonProps = BoxProps & {
  isOpen?: boolean
}

/**
 * @NOTE Shadows in this button are manually adjusted to make it look like
 * they are part of the same shadow and blends with the side panel's shadow
 */
const PanelButton = ({
  children,
  isOpen,
  ...props
}: PanelButtonProps) => (
  <Flex
    position='absolute'
    top='50%'
    left='1px'
    alignSelf='center'
    transform='rotate(-90deg) translate(-50%)'
    transformOrigin='left bottom'
    zIndex='3'
    cursor='pointer'
    {...props}
  >
    <PanelButtonCurve
      zIndex='1'
      display='block'
      transform='scaleX(-1)'
      // @TODO: Improve shadow
      filter='drop-shadow(15px -10px 10px rgba(0, 0, 0, 0.25))'
    />
    <Flex
      backgroundColor='accent.hover'
      alignItems='center'
      gap={1}
      // @TODO: Improve shadow
      boxShadow='-4px -10px 10px rgba(0, 0, 0, 0.10)'
    >
      <PanelButtonIcon
        transform={`rotate(90deg) ${isOpen ? '' : 'scaleX(-1)'}`}
      />
      <Text
        textStyle='bodyLFat'
        color='graystrong.400'
        flex='1 0 0'
        zIndex='2'
      >
        {isOpen ? 'HIDE' : 'VIEW CMA REPORTS'}
      </Text>
    </Flex>
    <PanelButtonCurve
      display='block'
      zIndex='1'
      // @TODO: Improve shadow
      filter='drop-shadow(15px -15px 10px rgba(0, 0, 0, 0.10))'
    />
  </Flex>
)

const PanelButtonCurve = (props: BoxProps) => (
  <chakra.svg
    width='56px'
    height='40px'
    viewBox='0 0 56 40'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <chakra.g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(28, 20) rotate(90) translate(-28, -20)translate(8, -8)'
      >
        <chakra.path
          d='M7.51203007,33.1105017 C5.30424007,35.5207017 -0.34076893,41.4080017 0.0161785704,55.9287017 L40,56 L40,7.83325058e-16 C39.4257001,28.0358017 19.2929001,20.2493017 7.51203007,33.1105017 Z'
          id='Path'
          fill='accent.hover'
        >
        </chakra.path>
      </g>
    </chakra.g>
  </chakra.svg>
)

const PanelButtonIcon = (props: BoxProps) => (
  <chakra.svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    boxSize={3}
    {...props}
  >
    <path d='M13 17L18 12L13 7' stroke='#4B4B4B' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 17L11 12L6 7' stroke='#4B4B4B' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
  </chakra.svg>
)
