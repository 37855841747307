import { Match, pipe } from 'effect'
import { EmptyMarker } from 'presentation/components/PropertyMarker/components/Marker'
import { SupOnlyPropertyMarkerProps } from 'presentation/components/PropertyMarker/components/SupOnlyPropertyMarker/SupOnlyPropertyMarker.props'
import { MultiSup, PreforeclosureSup, VacantSup } from 'presentation/components/PropertyMarker/components/Superscript'
import { MarkerSvgContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerSvgContainer'

export const SupOnlyPropertyMarker = (props: SupOnlyPropertyMarkerProps) => (
  <MarkerSvgContainer size={props.size} markerType={props.markerType}>
    <EmptyMarker size={props.size} markerType={props.markerType} />
    {pipe(
      Match.value(props.superscript),
      Match.when('vacant', () => <VacantSup />),
      Match.when('preforeclosure', () => <PreforeclosureSup />),
      Match.when('multi', () => <MultiSup />),
      Match.exhaustive,
    )}
  </MarkerSvgContainer>
)
