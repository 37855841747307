import { gql } from '__generated__'
import { CURRENT_PLAN_SECTION_USER_PLAN } from 'presentation/screens/Billing/CurrentPlanSection/useCurrentPlanQuery'
import { ACCOUNT_BALANCE__GET_BALANCE } from 'presentation/screens/Billing/AccountBalance/AccountBalance.queries'
import { PAYMENTS_TABLE__GET_INVOICES } from './components/BillingHistorySection/usePaymentsTable'
import { ACCOUNT_BALANCE_TABLE__GET_WALLET_TRANSACTIONS } from './components/BillingHistorySection/AccountBalanceTable/useAccountBalanceTable'

export const CARDS_VIEW__GET_PAYMENT_METHODS = gql(/* GraphQL */ `
  query CardsView_GetPaymentMethods {
    myEnterprise {
      id
      paymentMethods {
        edges {
          node {
            __typename

            ...on BillingCardsPaymentMethod {
              id
              isDefault
            }

            ...PaymentMethodCardList_PaymentMethodFragment
          }
        }
      }
    }
  }
`)

export const WALLET_QUERIES = [
  { query: ACCOUNT_BALANCE_TABLE__GET_WALLET_TRANSACTIONS },
  { query: PAYMENTS_TABLE__GET_INVOICES },
  { query: ACCOUNT_BALANCE__GET_BALANCE },
]

export const PLAN_QUERIES = [
  { query: CURRENT_PLAN_SECTION_USER_PLAN },
]

export const SubscriptionUpdateFragment = gql(/* GraphQL */ `
  fragment SubscriptionUpdate on Enterprise {
    id
    subscription {
      ... on BillingSubscriptionActive {
        cancelAt
        cancelAtPeriodEnd
        createdAt
        createdBy {
          id
        }
        currentPeriod {
          start
          end
        }
        id
        pastDueInvoice {
          id
          createdBy {
            id
          }
          authorizedAmount
          capturedAmount
          refundedAmount
          createdAt
          paidAt
          description
          linkToPdf
          status
        }
        plan {
          id
          interval {
            unit
          }
          name
          price
          product {
            id
            name
            description
            isLegacy
            features {
              ... on SubscriptionFeatureComps {
                limits {
                  interval
                  value
                }
                productId
              }
              ... on SubscriptionFeatureWebsites {
                limits {
                  interval
                  value
                }
                productId
              }
              ... on SubscriptionFeatureLeads {
                limits {
                  interval
                  value
                }
                productId
              }
              ... on SubscriptionFeatureSkiptrace {
                limits {
                  interval
                  value
                }
                productId
                prices {
                  pricingUnit
                  pricingUnitDescription
                  price
                }
              }
              ... on SubscriptionFeatureDirectMail {
                limits {
                  interval
                  value
                }
                productId
                prices {
                  pricingUnit
                  pricingUnitDescription
                  price
                }
              }
              ... on SubscriptionFeatureTeam {
                limits {
                  interval
                  value
                }
                productId
              }
            }
          }
        }
        status
        trialPeriod {
          start
          end
        }
        upcomingInvoice {
          description
          totalAmount
          paymentDate
        }
      }
      ... on BillingSubscriptionInactive {
        id
      }
    }
  }
`)
