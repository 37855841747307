import { Function, Match } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { LiveCMAProvider } from 'features/CMA/infra/react/LiveCMAProvider'
import { LivePropertyDetailsProvider } from 'features/PropertyDetails/infra/react/LivePropertyDetailsProvider'
import { PropertyDetailsStateHandler } from 'features/PropertyDetails/infra/react/PropertyDetailsStateHandler'
import { PersonName } from 'features/valueObjects/PersonName'
import { Layout } from 'presentation/layouts/Layout/Layout'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

export const ControlledLayout = () => {
  const viewModel = useLayoutViewModel()

  return (
    <LivePropertyDetailsProvider>
      <LiveCMAProvider>
        <LayoutViewModel.Provider viewModel={viewModel}>
          <PropertyDetailsStateHandler />
          <Layout />
        </LayoutViewModel.Provider>
      </LiveCMAProvider>
    </LivePropertyDetailsProvider>
  )
}

/**
 * @TODO Goal is get rid of this, allow components to reach out to specific
 * domain states on their own
 */
const useLayoutViewModel = (): LayoutViewModel => {
  const accessDomain = useAccessStore(state => ({
    access: state.local.access,
    logout: state.actions.logout.execute,
  }), shallow)

  return useMemo(() => Function.pipe(
    Match.value(accessDomain.access),

    Match.when(
      { data: { status: 'logged-in' } },
      (access): LayoutViewModel => {
        const accessData = access.data

        const accessor = {
          ...accessData.accessor,
          name: PersonName.combine(accessData.accessor.name) || '',

          /**
           * @HACK Blank gravatar causes for avatar letter to not render.
           *   Replace it with something broken so that letters would appear
           */
          avatarUrl: accessData.accessor.avatarUrl.includes('d=blank')
            ? ''
            : accessData.accessor.avatarUrl,

          logout: accessDomain.logout,
        }

        const subscription = accessData.subscription

        return {
          status: 'ready',
          accessor,
          subscription,
        }
      }),

    Match.orElse(() => LayoutViewModel.UNREADY),
  ), [
    accessDomain.access,
    accessDomain.logout,
  ])
}
