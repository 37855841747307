import { Center, ModalOverlay, Spinner } from '@chakra-ui/react'
import { pipe } from 'effect'
import { LiveCMAProvider } from 'features/CMA/infra/react/LiveCMAProvider'
import { LivePropertyDetailsProvider } from 'features/PropertyDetails/infra/react/LivePropertyDetailsProvider'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Address } from 'features/valueObjects/Address'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { SwitchTabButton, SwitchTabButtons, SwitchTabContent, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useSwitchTabsContext } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { PROPERTY_MODAL_CONTAINER_CLASS } from 'presentation/components/PropertyModal/PropertyModal.const'
import { useInternalPropertyModal } from 'presentation/components/PropertyModal/PropertyModal.hooks'
import { PropertyModalProvider } from 'presentation/components/PropertyModal/PropertyModalContext'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SIDEBAR_DESKTOP_W } from 'presentation/layouts/Layout/components/SidebarDesktop/SidebarDesktop'
import { LeadIdProvider } from 'presentation/libs/LeadIdContext'
import { CompsScreen } from 'presentation/screens/CompsScreen/CompsScreen'
import { PropertyDetailsScreen } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsScreen'
import { SkiptraceScreen } from 'presentation/screens/SkiptraceScreen'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { useEffect, useState } from 'react'

export const PropertyModal = () => (
  <LivePropertyDetailsProvider>
    <LiveCMAProvider>
      <PropertyModalBase />
    </LiveCMAProvider>
  </LivePropertyDetailsProvider>
)

const PropertyModalBase = () => {
  const { sbp } = useSwitchBreakpointFn()
  const modalApi = useInternalPropertyModal()
  const [leadId, setLeadId] = useState<string | null>(null)
  const getPropertyApi = usePropertyDetailsStore(store => store.actions.getProperty)

  useEffect(() => {
    if (modalApi.state.status === 'closed') {
      setLeadId(null)
      return
    }

    getPropertyApi.execute({
      ...'address' in modalApi.state.payload
        ? {
          addressString: pipe(
            modalApi.state.payload.address,
            Address.fillMissing,
            Address.toString,
          ),
        }
        : { parcelId: modalApi.state.payload.parcelId },
      origin: 'other',
    })
  }, [modalApi.state])

  useEffect(() => {
    if (getPropertyApi.state.status === 'success')

      setLeadId(getPropertyApi.state.data.leadId)
  }, [getPropertyApi.state.status])

  if (modalApi.state.status !== 'open') return null

  return (
    <PropertyModalProvider value={{ isPropertyModal: true }}>
      <LeadIdProvider leadId={leadId}>
        <Modal
          isOpen
          onClose={modalApi.actions.closeReplace}
        >
          <ModalOverlay />
          <ModalContent
            containerProps={{
              sx: {
                px: '0 !important',
                py: '24px !important',
              },
            }}
            className={PROPERTY_MODAL_CONTAINER_CLASS}
          >
            <ModalCloseButton
              sx={{
                top: -1,
                // Undo default positioning of modal button
                transform: 'none',
              }}
            />
            <SwitchTabsWrapper
              {...mbpg({
                dtSm: {
                  w: `calc(var(--app-width) - ${SIDEBAR_DESKTOP_W}px)`,
                  minW: `calc(var(--app-width) - ${SIDEBAR_DESKTOP_W}px)`,
                  maxW: `1376px`,
                  minH: `calc(var(--app-height) - 80px)`,
                },
                mobSm: {
                  w: 'app-width',
                  minW: 'app-width',
                  maxW: 'app-width',
                  minH: `calc(var(--app-height) - 80px)`,
                },
              })}
              colorScheme='weak'
              size={sbp({ mobSm: 'sm', dtSm: 'lg' })}
              pointerEvents='auto'
              display='flex'
              flexDirection='column'
            >
              <SwitchTabButtons>
                <SwitchTabButton tabKey={TAB_KEY_RECORD.PROPERTY_DETAILS}>
                  <Breakpoint
                    tabSm='PROPERTY DETAILS'
                    mobSm='DETAILS'
                  />
                </SwitchTabButton>
                <SwitchTabButton tabKey={TAB_KEY_RECORD.COMPARABLE_SALES}>
                  <Breakpoint
                    tabSm='COMPARABLE SALES'
                    mobSm='COMPS'
                  />
                </SwitchTabButton>
                <SwitchTabButton tabKey={TAB_KEY_RECORD.SKIPTRACE}>
                  <Breakpoint
                    tabSm='SKIPTRACE'
                    mobSm='SKIPTRACE'
                  />
                </SwitchTabButton>
              </SwitchTabButtons>
              <SwitchTabContents
                display='flex'
                flexDirection='column'
                flex='1 1 auto'
              >
                <SwitchTabContent flex='1 1 auto' display='flex' flexDirection='column'>
                  {(leadId ? <PropertyDetailsScreenForModal /> : <LoadingScreen />)}
                </SwitchTabContent>
                <SwitchTabContent flex='1 1 auto' display='flex' flexDirection='column'>
                  {(leadId ? <CompsScreen /> : <LoadingScreen />)}
                </SwitchTabContent>
                <SwitchTabContent flex='1 1 auto' display='flex' flexDirection='column'>
                  {(leadId ? <SkiptraceScreen /> : <LoadingScreen />)}
                </SwitchTabContent>
              </SwitchTabContents>
            </SwitchTabsWrapper>
          </ModalContent>
        </Modal>
      </LeadIdProvider>
    </PropertyModalProvider>
  )
}

export const LoadingScreen = () => (
  <Center flex='1'>
    <Spinner />
  </Center>
)

const TAB_KEY_RECORD = {
  PROPERTY_DETAILS: 'PROPERTY_DETAILS',
  COMPARABLE_SALES: 'COMPARABLE_SALES',
  SKIPTRACE: 'SKIPTRACE',
}

const PropertyDetailsScreenForModal = () => {
  const tabsApi = useSwitchTabsContext()
  return (
    <PropertyDetailsScreen
      goToComps={() => {
        tabsApi.setActiveTabKey(TAB_KEY_RECORD.COMPARABLE_SALES)
      }}
      goToSkiptrace={() => {
        tabsApi.setActiveTabKey(TAB_KEY_RECORD.SKIPTRACE)
      }}
    />
  )
}
