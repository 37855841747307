import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { FeatureListItem_FeaturesFragment } from '__generated__/graphql'
import { formatK1OptionalDecimal } from 'libs/Number/formatNumber'
import { capitalize, isNumber } from 'lodash/fp'
import { ListIcon, TwoPersonsIcon, WebsiteIcon } from 'presentation/components/Icons'
import { ResponsiveFullscreenModalV1 } from 'presentation/components/ResponsiveFullscreenModal'
import { Card, CardButton, CardFooter, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { LegacyPlanModalProps } from 'presentation/screens/Billing/components/LegacyPlanModal/LegacyPlanModal.types'
import { PriceTag } from 'presentation/screens/Billing/components/PriceTag'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, PropsWithChildren } from 'react'

export const LegacyPlanModal: FC<LegacyPlanModalProps> = ({
  isOpen,
  onClose,
  subscription,
}) => {
  const sub = subscription

  type FeatureFragment = FeatureListItem_FeaturesFragment
  type FeatureMap = {
    SubscriptionFeatureLeads?: Extract<FeatureFragment, { __typename: 'SubscriptionFeatureLeads' }>
    SubscriptionFeatureComps?: Extract<FeatureFragment, { __typename: 'SubscriptionFeatureComps' }>
    SubscriptionFeatureWebsites?: Extract<FeatureFragment, { __typename: 'SubscriptionFeatureWebsites' }>
  }

  const featuresMap: FeatureMap = sub.plan.product.features.reduce<FeatureMap>((acc, feature) => ({
    ...acc,
    [feature.__typename]: feature,
  }), {})

  const leadListExportLimit = !featuresMap.SubscriptionFeatureLeads
    ? 'zero'
    : !featuresMap.SubscriptionFeatureLeads.limits?.length
      ? 'unli'
      : featuresMap.SubscriptionFeatureLeads.limits[0]?.value
  const compsLimit = !featuresMap.SubscriptionFeatureComps
    ? 'zero'
    : !featuresMap.SubscriptionFeatureComps.limits?.length
      ? 'unli'
      : featuresMap.SubscriptionFeatureComps.limits[0]?.value
  const websitesLimit = !featuresMap.SubscriptionFeatureWebsites
    ? 'zero'
    : !featuresMap.SubscriptionFeatureWebsites.limits?.length
      ? 'unli'
      : featuresMap.SubscriptionFeatureWebsites.limits[0]?.value

  return (
    <ResponsiveFullscreenModalV1
      isOpen={isOpen}
      onClose={onClose}
      variant='default'
      colorScheme='modal.highlight'
      title='Legacy Plan FAQ'
      size='modal.default.w'
      footer={(
        <CardFooter>
          <CardButton
            variant='solid'
            colorScheme='positive'
            onClick={closeModal}
          >
            OKAY
          </CardButton>
        </CardFooter>
      )}
    >
      <CardPrimaryTitle>
        Frequently Asked Questions
      </CardPrimaryTitle>

      <Stack
        mt={mbp({ mobSm: '3', mob: '2', tabSm: '5' })}
        direction={mbp({ mobSm: 'column-reverse', tabSm: 'row' })}
        spacing={mbp({ mobSm: '3', tabSm: '1.5', tab: '5' })}
        align={mbp({ mobSm: 'center', tabSm: 'flex-start' })}
      >
        <Flex
          flex='1'
          flexDirection='column'
          gap={mbp({ mobSm: '3', mob: '2', tabSm: '5' })}
        >
          <Box>
            <Question>
              May I Keep My Current Plan?
            </Question>
            <Answer>
              Yes! In fact, this is the most valuable plan we have ever offered.
              {' '}
              This is an unrestricted account that includes all future updates at no additional costs!
            </Answer>
          </Box>
          <Box>
            <Question>
              May I Leave & Return to This Plan?
            </Question>
            <Answer>
              Sort of, kind of, but not really. The long and short of it is this plan is no longer offered,
              {' '}
              but if your payments lapse you may re-activate this plan by paying all past due amounts.
            </Answer>
          </Box>
        </Flex>
        <Box alignSelf={mbp({ mobSm: 'center', mob: 'auto' })}>
          <Card
            colorScheme='card.bg.1'
            size='sm-locked'
            w={mbp({ mobSm: '264px', tabSm: '232px', dtLg: '264px' })}
            pt='72px'
            pb='5'
          >
            <PriceTag
              price={sub.plan.price}
              planInterval={sub.plan.interval.unit ? capitalize(sub.plan.interval.unit) : ''}
              pos='absolute'
              top='-7.5px'
              right='1.5'
            />
            <Text textStyle='h2-h-b' color='graystrong.500'>
              Legacy
              {' '}
              {sub.plan.product.name}
            </Text>

            <Stack spacing='2' mt='3' alignItems='stretch'>
              <Flex alignItems='center'>
                <TwoPersonsIcon color='accent.blue-to-gray' boxSize='2' />
                <Text ml='1' flex='1' textStyle='body2' color='graystrong.500'>Driving for Dollars</Text>
                <Text textStyle='body2-xb' color='neutral.500'>Yes</Text>
              </Flex>

              <Flex alignItems='center'>
                <TwoPersonsIcon color='accent.blue-to-gray' boxSize='2' />
                <Text ml='1' flex='1' textStyle='body2' color='graystrong.500'>Team Members</Text>
                <Text textStyle='body2-xb' color='neutral.500'>Unlimited</Text>
              </Flex>

              <Flex alignItems='center'>
                <TwoPersonsIcon color='accent.blue-to-gray' boxSize='2' />
                <Text ml='1' flex='1' textStyle='body2' color='graystrong.500'>Lead List Exports</Text>
                {isNumber(leadListExportLimit)
                  ? (<Text textStyle='body2-xb' color='neutral.500'>{formatK1OptionalDecimal(leadListExportLimit)}</Text>)
                  : leadListExportLimit === 'unli'
                    ? (<Text textStyle='body2-xb' color='neutral.500'>Unlimited</Text>)
                    : leadListExportLimit === 'zero'
                      ? (<Text textStyle='body2-xb' color='negative.500'>Zero</Text>)
                      : null}
              </Flex>

              <Flex alignItems='center'>
                <ListIcon color='accent.blue-to-gray' boxSize='2' />
                <Text ml='1' flex='1' textStyle='body2' color='graystrong.500'>Comps/CMA</Text>
                {isNumber(compsLimit)
                  ? (<Text textStyle='body2-xb' color='neutral.500'>{formatK1OptionalDecimal(compsLimit)}</Text>)
                  : compsLimit === 'unli'
                    ? (<Text textStyle='body2-xb' color='neutral.500'>Unlimited</Text>)
                    : compsLimit === 'zero'
                      ? (<Text textStyle='body2-xb' color='negative.500'>Zero</Text>)
                      : null}
              </Flex>

              <Flex alignItems='center'>
                <WebsiteIcon color='accent.blue-to-gray' boxSize='2' />
                <Text ml='1' flex='1' textStyle='body2' color='graystrong.500'>Websites</Text>
                {isNumber(websitesLimit)
                  ? (<Text textStyle='body2-xb' color='neutral.500'>{formatK1OptionalDecimal(websitesLimit)}</Text>)
                  : websitesLimit === 'unli'
                    ? (<Text textStyle='body2-xb' color='neutral.500'>Unlimited</Text>)
                    : websitesLimit === 'zero'
                      ? (<Text textStyle='body2-xb' color='negative.500'>Zero</Text>)
                      : null}
              </Flex>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </ResponsiveFullscreenModalV1>
  )
}

const Question: FC<PropsWithChildren> = ({ children }) => (
  <Text
    textStyle={mbp({
      mobSm: 'lato-18-700',
      mob: 'lato-20-700',
      tabSm: 'lato-24-700',
    })}
    color='graystrong.500'
  >
    {children}
  </Text>
)

const Answer: FC<PropsWithChildren> = ({ children }) => (
  <Text
    textStyle={mbp({
      mobSm: 'lato-16-700',
      mob: 'lato-18-700',
    })}
    color='modal.text'
    mt={mbp({ mobSm: '1', tabSm: '2' })}
  >
    {children}
  </Text>
)
