import { Box, BoxProps, Center, ComponentWithAs, Flex, FlexProps, Text, forwardRef } from '@chakra-ui/react'
import classNames from 'classnames'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { pipe, times } from 'remeda'
import { ReactComponent as CurveLeftSvg } from './img/curve-left.svg'
import { ReactComponent as CurveRightSvg } from './img/curve-right.svg'

/** @Deprecated Use BaseTabs instead */
export namespace TabLinks {

  /**
 * @IMPORTANT This doesn't have a11y props because intended usage is to use tabs
 *   basically as links. IF you're going to use it more like a tab rather than a
 *   link, then you should add a11y props.
 * @TODO a11y props for links
 */
  export const Wrapper = ({
    children,
    ...props
  }: FlexProps) => (
    <Flex direction='column' minH='full' {...props}>
      {children}
    </Flex>
  )

  export const Buttons = forwardRef((props: FlexProps, ref) => (
    <Flex
      ref={ref}
      pos='relative'

      /** Neccessary to allow BigTabContent to go above BigTab */
      zIndex={0}

      {...props}
    />
  ))

  type ButtonProps = BoxProps & { isActive?: boolean }

  /**
   * @TODO Tabs on mobile should have carousel-like navigation when it exceeds container width
   */
  export const Button: ComponentWithAs<FC<ButtonProps>> = ({
    children,
    isActive,
    ...props
  }) => (
    <ButtonInnerContainer isActive={isActive} {...props}>
      <CurveLeft isActive={isActive} />

      <Text
        textStyle='bodyLFat'
        color={isActive ? 'graystrong.600' : 'graystrong.200'}
        pos='relative'
        textTransform='uppercase'
        zIndex={1}
      >
        {children}
      </Text>

      <CurveRight isActive={isActive} />
    </ButtonInnerContainer>
  )

  const BUTTON_CLASSNAMES = {
    default: 'BigTab',
    active: 'BigTab--active',
  }

  type ButtonInnerContainerProps = BoxProps & { isActive?: boolean }

  const ButtonInnerContainer = ({ isActive, children, ...props }: ButtonInnerContainerProps) => (
    <Center
      // dimensions
      h={4.5}
      px={mbp({ mobSm: 2, tabSm: 3 })}

      // appearance
      borderTopRadius={3}
      bg={isActive ? 'neutral.200' : 'highlight.200'}

      // positioning
      pos='relative' // for zIndex in SX

      // misc
      cursor='pointer'

      className={classNames(
        BUTTON_CLASSNAMES.default,
        isActive && BUTTON_CLASSNAMES.active,
      )}

      // complex styles
      sx={{
        ...descendingTabsZIndexSx,

        [`&.${BUTTON_CLASSNAMES.active}`]: {
          // Active tab should be above every other tabs
          zIndex: MAX_TABS + 1,
        },

        // First tab has different shadow vs every other tabs
        boxShadow: 'tabs.notfirst',
        [`&.${BUTTON_CLASSNAMES.default}:first-of-type`]: {
          boxShadow: 'tabs.first',
        },
      }}

      {...props}
    >
      {children}
    </Center>
  )

  const CurveLeft = ({
    isActive,
  }: { isActive?: boolean }) => (
    <Box
      as={CurveLeftSvg}
      h='37px'
      pos='absolute'
      top={0}
      left='22px'
      transform='translateX(-100%)'
      color={isActive ? 'neutral.200' : 'highlight.200'}
      pointerEvents='none'
      sx={{
        [`.${BUTTON_CLASSNAMES.default}:first-of-type &`]: {
          display: 'none',
        },
      }}
    />
  )

  const CurveRight = ({
    isActive,
  }: { isActive?: boolean }) => (
    <Box
      as={CurveRightSvg}
      h='37px'
      pos='absolute'
      top={0}
      right='22px'
      transform='translateX(100%)'
      color={isActive ? 'neutral.200' : 'highlight.200'}
      pointerEvents='none'
    />
  )

  export const Content: FC<BoxProps> = props => (
    <Box
      bg='neutral.200'
      pos='relative'
      flex='1'
      overflow='hidden'

      /** Go above shadow of BigTabsList */
      zIndex={1}
      {...props}
    />
  )

  const MAX_TABS = 10

  /**
   * @returns
   *  {
   *   '&:nth-of-type(1)': { zIndex: 10 },
   *   '&:nth-of-type(2)': { zIndex: 9 },
   *   ...
   *   '&:nth-of-type(10)': { zIndex: 1 },
   * }
   */
  const descendingTabsZIndexSx = pipe(
    MAX_TABS,
    times(n => [`&:nth-of-type(${n + 1})`, { zIndex: MAX_TABS - n }]),
    Object.fromEntries,
  )
}
