import * as DateFns from 'date-fns/fp'
import dayjs from 'dayjs'
import { PanicError } from 'libs/errors/PanicError'
import { isDate, isString } from 'remeda'

const maybeFromUnknown = (value: unknown): Date | null => {
  if (typeof value === 'string') {
    const dayObj = dayjs(value)
    return dayObj.isValid() ? dayObj.toDate() : null
  }

  return null
}

const from = (format: string) => (value: string) =>
  DateFns.parse(new Date(), format, value)

from.safe = (format: string) => (value: string) => {
  if (!value) return null
  const date = DateFns.parse(new Date(), format, value)
  if (!DateFns.isValid(date)) return null
  return date
}

from.strict = (format: string) => (value: string) => {
  if (!value) throw new PanicError('Invalid data value')
  const date = DateFns.parse(new Date(), format, value)
  if (!DateFns.isValid(date)) throw new PanicError('Invalid data value')
  return date
}

from.nullable = (format: string) => (value: string | null) => {
  if (!value) return null
  const date = DateFns.parse(new Date(), format, value)
  if (!DateFns.isValid(date)) return null
  return date
}

const fromIso = (value: string) => DateFns.parseISO(value)

fromIso.safe = (value: string) => {
  if (!value) return null
  const date = DateFns.parseISO(value)
  if (!DateFns.isValid(date)) return null
  return date
}

fromIso.strict = (value: string) => {
  if (!value) throw new PanicError('Invalid data value')
  const date = DateFns.parseISO(value)
  if (!DateFns.isValid(date)) throw new PanicError('Invalid data value')
  return date
}

fromIso.nullable = (value: string | null) => {
  if (!value) return null
  const date = DateFns.parseISO(value)
  if (!DateFns.isValid(date)) return null
  return date
}

type Formatter = (value: Date) => string
const format = (format: string): Formatter => valueFromParams => {
  let value = valueFromParams
  /** @HACK GraphQL services return string for Date, just deal with that here for now */
  if (typeof value === 'string') value = DateLib.fixDateString(value)

  return DateFns.format(format, value)
}

const applyFormatFallback = <TFallback>(fallback: TFallback) =>
  (formatter: Formatter) =>
    (value: Date | null | undefined) =>
      value ? formatter(value) : fallback

format.fallback = <TFallback>(fallback: TFallback) =>
  (format: string) =>
    (rawValue: Date | null | undefined) => {
      /**
       * @BUGFIX A Very bad but very cheap solution. If we somehow received
       * string (GraphQL is screwing us and is sending string instead of Date,)
       * we will try to parse it as a date.
       * @TODOIMPORTANT Remove this and fix the issue at the source.
       */
      const value = typeof rawValue === 'string'
        ? DateFns.parseISO(rawValue)
        : rawValue

      if (!value) return fallback
      return DateFns.format(format, value)
    }

const MM_DD_YY_SLASHED = 'MM/dd/yy'
const MM_DD_YYYY_SLASHED = 'MM/dd/yyyy'
const M_DD_YY_DOTS = 'M.dd.yy'
const MM_DD_YY_DOTS = 'MM.dd.yy'
const NATURAL_SHORT_MONTH = 'MMM d, yyyy'
// 12/3/2023 5:53:24 PM PST
const DATE_TIME_TZ = 'MM/dd/yyyy h:mm:ss a zzz'

const ensureOrCastStringOrNull = (value: any): Date | null => {
  if (isDate(value)) return value
  if (isString(value)) {
    const parsed = DateFns.parseISO(value)
    if (DateFns.isValid(parsed)) return parsed
  }
  return null
}

const ensureOrNull = (value: any): Date | null => {
  if (isDate(value)) return value
  return null
}

const getTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const DateLib = {
  // ensure functions
  ensureOrCastStringOrNull,
  ensureOrNull,

  // create functions
  maybeFromUnknown,
  from,
  fromIso,
  format,

  // fix functions
  fixDateString: (date: Date) => typeof date === 'string' ? DateFns.parseISO(date) : date,
  fixDateStringOrNull: (date: Date | null | undefined) => typeof date === 'string'
    ? DateFns.parseISO(date)
    : date instanceof Date
      ? date
      : null,

  // formats
  MM_DD_YY_SLASHED,
  MM_DD_YYYY_SLASHED,
  NATURAL_SHORT_MONTH,
  DATE_TIME_TZ,

  // format functions
  formatMMDDYYSlashed: format(MM_DD_YY_SLASHED),
  formatMDDYYDots: format(M_DD_YY_DOTS),
  formatMMDDYYDots: format(MM_DD_YY_DOTS),
  formatMMDDYYDotsOrDoubleDash: applyFormatFallback('--')(format(MM_DD_YY_DOTS)),
  formatMMDDYYYYSlashed: format(MM_DD_YYYY_SLASHED),
  formatNaturalShortMonth: format(NATURAL_SHORT_MONTH),
  formatDateTimeTZ: format(DATE_TIME_TZ),

  // parse functions
  fromMMDDYYSlashed: from(MM_DD_YY_SLASHED),
  fromMMDDYYYYSlashed: from(MM_DD_YYYY_SLASHED),
  fromNaturalShortMonth: from(NATURAL_SHORT_MONTH),
  fromDateTimeTZ: from(DATE_TIME_TZ),

  // misc helpers
  getTimezone,
}
