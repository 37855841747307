import { Address } from 'features/valueObjects/Address'
import { NullProps } from 'libs/utils.types'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { usePropertyDrawerContentState } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/usePropertyDrawerContent'
import { Modals } from 'presentation/libs/modals'
import RouteIdUtils from 'presentation/main/Router/RouteIdUtils'
import { ROUTE_IDS, RouteId } from 'presentation/main/Router/routeId'
import QueryString from 'qs'
import { useMatches, useNavigate } from 'react-router-dom'

export type PropertyModalPayload = {
  address: NullProps<Address>
} | {
  parcelId: string
}

export const useInternalPropertyModal = Modals.createStoreUseModal<PropertyModalPayload>()

/**
 * @TODO Set navigation origin
 * @TODO Set handle opening modal from modal
 */
export const useResponsivePropertyModal = ({
  backLabel,
}: {
  backLabel: string
}) => {
  const isMobile = useSwitchBreakpoint({
    mobSm: false,
    dtSm: true,
  })

  const mobileAPI = useMobilePropertyModal()
  const desktopAPI = useDesktopPropertyModal({ backLabel })

  return isMobile ? mobileAPI : desktopAPI
}

const useDesktopPropertyModal = ({
  backLabel,
}: {
  backLabel: string
}) => {
  const setDrawerContentScenario = usePropertyDrawerContentState(state =>
    state.setScenario)
  const navigate = useNavigate()
  const routeMatches = useMatches()

  const getRouteMatch = (routeId: RouteId) =>
    routeMatches.find(match => match.id === routeId) ?? null

  const navigateToPropPage = (payload: PropertyModalPayload) => {
    const propertyLoaderPath = 'address' in payload
      ? `/search/from-address?${QueryString.stringify(payload.address)}`
      : `/search/from-parcel-id?parcelId=${encodeURIComponent(payload.parcelId)}`

    const listRouteMatch = getRouteMatch(ROUTE_IDS.LIST)
    if (listRouteMatch) {
      setDrawerContentScenario({
        type: 'from-other-modules',
        goBackToOriginButton: {
          label: backLabel,
          onClick: () => {
            navigate(listRouteMatch.pathname)
          },
        },
        checkShouldResetOnNavigate: state => {
          const currentFullPath = state.location.pathname + state.location.search
          const isPropertyRoute = state.matches.some(match => RouteIdUtils.checkPropertyRouteIdPrefix(match.route.id))
          const isPropertyLoaderPath = currentFullPath === propertyLoaderPath
          const shouldReset = !isPropertyRoute && !isPropertyLoaderPath
          return shouldReset
        },
      })
    }

    const listBuilderPreviewRouteMatch = getRouteMatch(ROUTE_IDS.LIST_BUILDER_PREVIEW)
    if (listBuilderPreviewRouteMatch) {
      setDrawerContentScenario({
        type: 'from-other-modules',
        goBackToOriginButton: {
          label: backLabel,
          onClick: () => {
            navigate(listBuilderPreviewRouteMatch.pathname)
          },
        },
        checkShouldResetOnNavigate: state => {
          const currentFullPath = state.location.pathname + state.location.search
          const isPropertyRoute = state.matches.some(match => RouteIdUtils.checkPropertyRouteIdPrefix(match.route.id))
          const isPropertyLoaderPath = currentFullPath === propertyLoaderPath
          const shouldReset = !isPropertyRoute && !isPropertyLoaderPath
          return shouldReset
        },
      })
    }

    navigate(propertyLoaderPath)
  }

  return {
    actions: {
      open: navigateToPropPage,
    },
  }
}

const useMobilePropertyModal = () => {
  const modalApi = useInternalPropertyModal()

  return {
    actions: {
      open: modalApi.actions.open,
    },
  }
}
