import { useEffect, useId, useState } from 'react'

export const createScrollXSync = () => {
  const refs: Record<string, HTMLElement | null> = {}

  const api = {
    useSync: () => {
      const id = useId()
      const [ref, setRef] = useState<HTMLDivElement | null>(null)

      // track refs
      useEffect(() => {
        if (!ref) return

        refs[id] = ref

        return () => {
          refs[id] = null
        }
      }, [ref])

      // track and sync scroll
      useEffect(() => {
        if (!ref) return

        const handleScroll = (e: Event) => {
          const target = e.target as HTMLElement

          Object.values(refs).forEach(r => {
            if (!r || r === target) return

            r.scrollLeft = target.scrollLeft
          })
        }

        ref.addEventListener('scroll', handleScroll)

        return () => {
          ref.removeEventListener('scroll', handleScroll)
        }
      }, [ref])

      // on ref set, sync to any other existing refs
      useEffect(() => {
        if (!ref) return

        const anyOtherExistingRef = Object.values(refs).find(r => r && r !== ref)

        if (!anyOtherExistingRef) return

        ref.scrollLeft = anyOtherExistingRef.scrollLeft
      }, [ref])

      return { setRef }
    },
  }

  return api
}
