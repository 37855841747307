import { useEffect } from 'react'
import { MapRef } from 'react-map-gl'

export const useClickOnMapCanvas = (params: {
  mapRef: MapRef | null
  onMapCanvasClick: () => void
}) => {
  useEffect(() => {
    const handleEvent = (e: MouseEvent) => {
      const isCanvas = e.target === params.mapRef?.getCanvas()
      if (!isCanvas) return
      params.onMapCanvasClick()
    }

    params.mapRef?.getContainer().addEventListener('click', handleEvent)
    return () => {
      params.mapRef?.getContainer().removeEventListener('click', handleEvent)
    }
  }, [params.mapRef])
}
