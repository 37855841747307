import { VStack, Text } from '@chakra-ui/react'
import DetailDisclosurePopover from 'presentation/components/DetailDisclosurePopover'
import { DetailDisclosurePopoverProps } from 'presentation/components/DetailDisclosurePopover/DetailDisclosurePopover'

type CompsDetailDisclosureViewModel = {
  propertyCount: string
}

type CompsDetailDisclosureProps =
  & DetailDisclosurePopoverProps
  & CompsDetailDisclosureViewModel

const CompsDetailDisclosure = ({
  propertyCount,
  popoverContentProps,
  ...props
}: CompsDetailDisclosureProps) => (
  <DetailDisclosurePopover
    popoverContentProps={{
      w: '188px',
      ...popoverContentProps,
    }}
    {...props}
  >
    <VStack>
      <Text color='inherit' textAlign='center'>
        Analyze up to
        {' '}
        {propertyCount}
        {' '}
        properties per month with unlimited comparable properties.
      </Text>
      <Text color='inherit' textAlign='center'>
        For example, when analyzing a 3-bed home in Phoenix, view as many comps as needed to create an accurate CMA.
      </Text>
    </VStack>
  </DetailDisclosurePopover>
)

export default CompsDetailDisclosure
