import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals'
import { BaseMemberReactivateModalViewModel, InitialMemberReactivateModalTransition, LoadingMemberReactivateModalTransition } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberReactivateModal.viewModel'

const open = (
  props: Omit<InitialMemberReactivateModalTransition & BaseMemberReactivateModalViewModel, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'MEMBER_REACTIVATE_MODAL',
      props: {
        ...props,
        onClose: goBackModal,
        status: 'initial',
      },
    },
  })

const transitionToInitial = (
  props: Omit<InitialMemberReactivateModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'MEMBER_REACTIVATE_MODAL',
      props: {
        ...props,
        status: 'initial',
      },
    },
  })

const transitionToLoading = (
  props: Omit<LoadingMemberReactivateModalTransition, 'status'>,
): void =>
  modals.emitter.emit({
    type: 'UPDATE',
    payload: {
      key: 'MEMBER_REACTIVATE_MODAL',
      props: {
        ...props,
        status: 'loading',
      },
    },
  })

const close = (): void => goBackModal()

export const MemberReactivateModalAPI = {
  open,
  transitionToLoading,
  transitionToInitial,
  close,
}
