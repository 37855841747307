import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { isObject, isString } from 'lodash/fp'
import { toast } from 'presentation/components/Toast'
import { openCancelSubscriptionModal } from 'presentation/main/globalModals/CancelSubscriptionModal/CancelSubscriptionModal.api'
import { updateTypeToConfirmModal } from 'presentation/main/globalModals/TypeToConfirmModal/TypeToConfirmModal.api'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const CANCEL_SUBSCRIPTION_FOOTER__UNSUBSCRIBE = gql(/* GraphQL */`
  mutation CancelSubscriptionFooter_Unsubscribe {
    billingUnsubscribe {
      ... on BillingNoSubscriptionError {
        message
        field
      }
      ... on Enterprise {
        id
        subscription {
          ... on BillingSubscriptionActive {
            id
            status
            cancelAtPeriodEnd
            cancelAt
          }
          ... on BillingSubscriptionInactive {
            status
            id
          }
        }

        ...SubscriptionUpdate
      }
    }
  }
`)

type FormData = {
  hasAcknowledged: boolean
}

export const useCancelSubscriptionFooter = ({ periodEndFormatted }: { periodEndFormatted: string | null }) => {
  const {
    control,
    formState: { isValid, isSubmitted },
    ...form
  } = useForm<FormData>({
    defaultValues: {
      hasAcknowledged: false,
    },
  })

  const navigate = useNavigate()
  const [unsubscribe, { reset, loading, error }] = useMutation(CANCEL_SUBSCRIPTION_FOOTER__UNSUBSCRIBE)

  const callCancelApi = async () => {
    try {
      updateTypeToConfirmModal({
        isLoading: true,
        isDisabled: true,
      })

      const result = await unsubscribe()

      const typeName = result?.data?.billingUnsubscribe?.__typename

      const errMsg = result.errors?.[0].message

      if (errMsg) throw new Error(errMsg)

      if (typeName !== 'Enterprise')
        throw new Error('Unexpected typename in useCancelSubscriptionFooter')

      updateTypeToConfirmModal({
        isLoading: false,
        isDisabled: false,
      })
      goBackModal()

      navigate('/user/billing')
    } catch (error) {
      updateTypeToConfirmModal({
        isLoading: false,
        isDisabled: false,
      })

      const message = isObject(error) && 'message' in error && isString(error.message)
        ? error.message
        : undefined
      toastError(message)
    }

    reset()
  }

  const handleSubmit = form.handleSubmit(async (data: FormData) => {
    if (!data.hasAcknowledged) throw Error('Please check the acknowledgemnt checkbox')

    const alertMessage = periodEndFormatted
      ? `Your subscription is paid until ${periodEndFormatted}. If you cancel, you’ll lose access to all of the features included in your account when your subscription term ends.`
      : undefined

    openCancelSubscriptionModal({
      alertMessage,
      onCancel: callCancelApi,
    })
  })

  const handleKeepMyAccount = useCallback(() => {
    navigate('/user/billing')
  }, [navigate])

  // React to errors
  useEffect(() => {
    if (!error) return

    toast({
      status: 'error',
      title: 'We couldn’t load the page.',
      message: error.message,
    })
  }, [error])

  return {
    handleKeepMyAccount,
    loading,
    control,
    handleSubmit,
    isValid,
    isSubmitted,
  }
}

const toastError = (message?: string) => {
  toast({
    id: 'CancelSubscriptionFooter',
    status: 'error',
    title: 'We couldn’t process your request.',
    message,
  })
}
