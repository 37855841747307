import { Box, Flex } from '@chakra-ui/react'
import { LEGAL_PRIVACY_URL } from 'presentation/const/env.const'
import { LegalDocument, LegalParagraph, LegalSection, UnderlinedLink } from 'presentation/screens/LegalScreens/components'

export const TermsOfUseScreen = () => (
  <Flex flexDirection='column'>
    <LegalDocument title='Propelio Terms of Use'>

      <LegalSection title='Acceptance of the Terms of Use'>
        <LegalParagraph>
          These website terms of use are entered into by and between you, as the user,
          and Propelio Realty, LLC (&quot;Propelio Realty&quot;, &quot;we&quot; or
          &quot;us&quot;). The following terms and conditions (&quot;Terms of Use&quot;)
          , govern your access to and use of
          {' '}
          <UnderlinedLink href='https://www.propelio.com'>www.propelio.com</UnderlinedLink>
          , including any content, functionality and services offered on or
          through
          {' '}
          <UnderlinedLink href='https://www.propelio.com'>www.propelio.com</UnderlinedLink>
          , and all mobile
          applications relating to propelio.com (&quot;Website&quot;).
        </LegalParagraph>

        <LegalParagraph>
          Please read the Terms of Use carefully before you start to use the Website. By
          using the Website, or by clicking to accept or agree to the Terms of Use when
          this option is made available to you, you accept and agree to be bound and abide
          by these Terms of Use, including the MLS Rules and
          our
          {' '}
          <UnderlinedLink href={LEGAL_PRIVACY_URL}>Privacy Policy</UnderlinedLink>
          , which is incorporated
          by reference (&quot;Privacy Policy&quot;). You further agree and acknowledge that you have read
          these Terms of Use and all applicable MLS Rules, and agree to all of their terms and conditions,
          including the provisions relating to the VOW.  If you do not want to agree to these Terms of Use
          or the Privacy Policy, you may not access or use the Website.
        </LegalParagraph>

        <LegalParagraph>
          This Website is offered and available to users who are 18 years of age or older,
          and by using this Website, you represent and warrant that you are 18 years of age
          or older. If you do not meet this requirement, you may not access or use the
          Website.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Changes to the Terms of Use'>
        <LegalParagraph>
          We may revise, supplement, replace, and update these Terms of Use from time to time
          in our sole discretion. All changes are effective immediately when we post them,
          and apply to all access to and use of the Website thereafter. Propelio Realty will
          make a reasonable effort to notify you of material changes by email, in a pop up
          window, or by a similar means.  You will be deemed to have accepted all such changes
          if you use the Website, or any part of the Website, after such change is made and
          posted on the Website.  If any such changes are unacceptable, do not access or use
          the Website.  The VOW and Paid Services may include additional terms and conditions
          that are posted on the Website in connection with the VOW and Paid Services.  All such
          terms and conditions are incorporated into these Terms and Conditions.
        </LegalParagraph>

        <LegalParagraph>
          Your continued use of the Website following the posting of revised Terms of Use means
          that you accept and agree to the changes. You are expected to check this page each time
          you access this Website so you are aware of any changes, as they are binding on you.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='License'>
        <LegalParagraph>
          Propelio Realty hereby grants you a revocable, limited, nonexclusive license for the
          duration of your current viewing session to access and have a single use of the Website,
          including any third-party applications provided by the Website, subject to the limitations
          set forth in these Terms of Use.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Virtual Office Website'>
        <LegalParagraph>
          A feature of the Website is a virtual office website (“VOW”), which grants registrants
          access to certain real estate listing information and content (“Listing Content”) which
          is provided by one or more multiple listing services (“MLS”).  The VOW includes pictures
          and prices of homes, and comments on active listings from real estate agents, brokers, or
          salespersons. The MLS providing Listing Content require and you acknowledge that you may
          not access the VOW and have access to Listing Content until you have become a registrant.
          The following are conditions for you to become a registrant under the VOW:
        </LegalParagraph>
        <LegalParagraph>
          Before accessing or viewing Listing Content on the VOW, you must accept and agree to
          these Terms of Use, and all MLS Rules, and by accessing the VOW and Website, you are
          agreeing to these Terms of Use, in addition to all MLS Rules.
        </LegalParagraph>
        <LegalParagraph>
          You must provide your identity to Propelio Realty.
        </LegalParagraph>
        <LegalParagraph>
          You must provide your name and a valid email address to Propelio Realty. Propelio Realty
          will send an email to you at the address provided by you confirming that you have agreed
          to the Terms of Use.  You must then reply to that email which will confirm that the email
          address is a valid email address, and that you received the Terms of Use confirmation.
          You will be permitted to access the VOW only after Propelio Realty has verified that
          the email address provided by you is valid, and that you received the Terms of Use confirmation.
        </LegalParagraph>
        <LegalParagraph>
          You must supply to Propelio Realty a username and a password, the combination of which must
          be different from those of all other registrants on the VOW, before you will be permitted
          to search and retrieve Listing Content on the VOW.  The username and password may be
          established by you or may be supplied by Propelio Realty, at the option of Propelio
          Realty.  The email address you provide to Propelio Realty may be associated with only
          one username and password.
        </LegalParagraph>
        <LegalParagraph>
          You agree and acknowledge as follows:
        </LegalParagraph>
        <LegalParagraph>
          Your password and access to the VOW will expire in 90 days after the date of registration,
          but may be renewed.
        </LegalParagraph>
        <LegalParagraph>
          You have entered into a lawful consumer-broker relationship with Propelio Realty. You have no
          obligation to work with Propelio Realty to assist you in valuing a property. You can choose
          to work with Propelio Realty, or another broker or agent. These Terms of Use, and the
          relationship created under these Terms of Use, do not create a financial obligation for you,
          and any such obligation would be pursuant to a separate written or electronic agreement
          expressly agreed by you.
        </LegalParagraph>
        <LegalParagraph>
          All Listing Content obtained from the VOW is intended only for your personal, non-commercial
          use, or if you are a commercial seller of real property, only in connection with your
          locating ready, willing, and able buyers.  You agree that you are authorized to use the
          Website only for residential real estate transactions, and not for commercial real estate
          transactions of any kind.
        </LegalParagraph>
        <LegalParagraph>
          You have a bona fide interest in the purchase, sale, or lease of real estate of the type
          being offered through the VOW.
        </LegalParagraph>
        <LegalParagraph>
          You will not copy, redistribute, or retransmit any of the Listing Content provided, except
          in connection with your consideration of the purchase or sale of an individual property.
        </LegalParagraph>
        <LegalParagraph>
          The MLS providing the Listing Content, and other participants of such MLS, or their duly
          authorized representatives, are authorized by you to access the VOW for the purposes of
          verifying compliance with MLS rules and monitoring display of Listings on the VOW.
        </LegalParagraph>
        <LegalParagraph>
          The VOW, and your use of the VOW and Listing Content, are governed by rules and regulations,
          policies, and terms of use of the National Association of REALTORS® and the MLS providing
          Listing Content (“MLS Rules”), and Propelio Realty is obligated to comply with all such
          MLS Rules.  You agree to fully comply with and acknowledge all such MLS Rules.
        </LegalParagraph>
        <LegalParagraph>
          You acknowledge PrimeMLS&apos;s ownership of, and the validity of PrimeMLS&apos;s copyright in the
          PrimeMLS database.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='User Content'>
        <LegalParagraph>
          The Website includes features that will allow you to upload, contribute or post information
          or content, including without limitation, photographs, images, graphics, video recordings,
          virtual tours, drawings, written descriptions, remarks, and narratives submitted by users of
          the Website (“User Content”).
        </LegalParagraph>
        <LegalParagraph>
          Propelio Realty may, but has no obligation to, store any user generated content on its
          servers, and will not be liable for any User Content submitted by you.  Further, Propelio
          Realty shall have no liability in the event any User Content is damaged, deleted,
          transferred, or modified.  Upon termination of these Terms of Use for you, Propelio
          Realty may remove, transfer, or delete all User Content.
        </LegalParagraph>
        <LegalParagraph>
          Any User Content you post to the Website will be considered non-confidential and
          non-proprietary. By providing any User Content on the Website, you grant Propelio
          Realty and its affiliates and service providers, and each of their and our respective
          licensees, successors and assigns the right to use, reproduce, modify, perform, display,
          distribute and otherwise disclose to third parties any User Content for any purpose.
        </LegalParagraph>
        <LegalParagraph>
          You represent, warrant, and agree that:
          <Box marginLeft={5}>
            <ul>
              <li>
                You own or control all rights in and to the User Content, and have the right to grant
                the license granted in these Terms of Use.
              </li>
              <li>
                All User Content does and will comply with these Terms of Use, and specifically, but
                without limitation, the Content Standards.
              </li>
              <li>
                You grant to Propelio Realty a non-exclusive, irrevocable, worldwide, royalty free
                license to use, copy, sublicense through multiple tiers, publish, reproduce,
                prepare derivative works, distribute, and display the User Content, or any
                derivative works thereof on the Website.  Such license shall be deemed granted as
                of the moment of creation without the necessity of any further action on the part
                of either party.  You represent and warrant to Propelio Realty with respect to the
                User Content that the User Content, and the license of rights in and to the User
                Content, do not infringe or violate any copyrights, trade secrets, or other intellectual
                or proprietary rights of any third party, and that posting any User Content will not
                violate any applicable laws, rules, or regulations.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          You understand and acknowledge that you are responsible for any User Content you submit
          or contribute, and you have fully responsibility for such content, including its legality,
          reliability, accuracy and appropriateness.  Propelio Realty is not responsible, or liable
          to you or any third party, for the content or accuracy of any User Content posted by you
          or any other user of the Website.
        </LegalParagraph>
        <LegalParagraph>
          Propelio has the right, but not the obligation, to do any of the following:
          <Box marginLeft={5}>
            <ul>
              <li>
                Remove or refuse to post any User Content for any or no reason in Propelio Realty&apos;s
                sole discretion.
              </li>
              <li>
                Take any action with respect to any User Content Propelio Realty deems necessary or
                appropriate in its sole discretion, including if it believes that such User Content
                violates these Terms of Use, infringes any intellectual property right or other right
                of any person or entity, threatens the personal safety of users of the Website or the
                public or could create liability for Propelio Realty.
              </li>
              <li>
                Disclose your identity or other information about you to any third party who claims that
                material posted by you violates their rights, including their intellectual property
                rights or their right to privacy.
              </li>
              <li>
                Take appropriate legal action, including without limitation, referral to law enforcement,
                for any illegal or unauthorized use of the Website.
              </li>
              <li>
                Fully cooperate with any law enforcement authorities or court order requesting or directing
                Propelio Realty to disclose the identity or other information of anyone posting any materials
                on or through the Website. YOU WAIVE AND HOLD HARMLESS PROPELIO REALTY, AND ITS AFFILIATES,
                LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY PROPELIO
                REALTY OR ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND
                FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER PROPELIO REALTY, SUCH
                PARTIES, OR LAW ENFORCEMENT AUTHORITIES.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          Notwithstanding the forgoing, Propelio Realty does not undertake to review User Content before
          it is posted on the Website, and cannot ensure prompt removal of objectionable material after
          it has been posted. Accordingly, Propelio Realty assumes no liability for any action or
          inaction regarding transmissions, communications or content provided by any user or third
          party. Propelio Realty has no liability or responsibility to anyone for performance or
          nonperformance of the activities described in this section.
        </LegalParagraph>
        <LegalParagraph>
          YOU ACKNOWLEDGE THAT YOU MUST OBTAIN ASSIGNMENTS OR LICENSES FROM THE AUTHORS OF ANY PORTIONS
          OF THE USER CONTENT, INCLUDING AFFILIATES, PHOTOGRAPHERS, AND OTHER THIRD PARTY CONTRIBUTORS,
          AS NECESSARY FOR YOU TO LICENSE THE USER CONTENT TO PROPELIO REALTY, AND TO OTHERWISE MAKE
          FULL USE OF THE USER CONTENT UNDER THESE TERMS OF USE.  IF YOU FAIL TO DO SO, YOU WILL ASSUME
          AND REIMBURSE PROPELIO REALTY FOR THE COST OF DEFENDING AGAINST INFRINGEMENT CLAIMS AND
          PAYING DAMAGES ON ANY SUCH CLAIMS.  PROPELIO REALTY SHALL HAVE THE RIGHT TO CONTENT ITS
          OWN DEFENSEN AND ENGAGE LEGAL COUNSEL ACCEPTABLE TO PROPELIO REALTY.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Content Standards for User Content'>
        <LegalParagraph>
          These following content standards (“Content Standards”) apply to any and all User Content, and
          use of the Website. User Content must in their entirety comply with all applicable federal,
          state, local and international laws and regulations. Without limiting the foregoing, User
          Content must not:
          <Box marginLeft={5}>
            <ul>
              <li>
                Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing,
                violent, hateful, inflammatory or otherwise objectionable.
              </li>
              <li>
                Contain any material which is false or misleading.
              </li>
              <li>
                Promote sexually explicit or pornographic material, violence, or discrimination based on
                race, sex, religion, nationality, disability, sexual orientation or age.
              </li>
              <li>
                Infringe any patent, trademark, trade secret, copyright or other intellectual property or
                other rights of any other person.
              </li>
              <li>
                Violate the legal rights, including the rights of publicity and privacy, of others or contain
                any material that could give rise to any civil or criminal liability under applicable laws
                or regulations or that otherwise may be in conflict with these Terms of Use and the Privacy
                Policy.
              </li>
              <li>
                Be likely to deceive any person.
              </li>
              <li>
                Promote any illegal activity, or advocate, promote or assist any unlawful act.
              </li>
              <li>
                Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm
                or annoy any other person.
              </li>
              <li>
                Impersonate any person, or misrepresent your identity or affiliation with any person or
                organization.
              </li>
              <li>
                Involve commercial activities or sales, such as contests, sweepstakes and other sales
                promotions, barter or advertising.
              </li>
              <li>
                Give the impression that they emanate from or are endorsed by Propelio Realty, or any other
                person or entity, if this is not the case.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Copyright Infringement'>
        <LegalParagraph>
          If you believe that any User Content violates your copyright, please send an email
          to
          {' '}
          <UnderlinedLink href='mailto:support@propelio.com'>support@propelio.com</UnderlinedLink>
          {' '}
          for any notice of copyright
          infringement. It is the policy of Propelio Realty to terminate the user accounts of repeat infringers.
        </LegalParagraph>

        <LegalSection title='Notification of Potential Infringement'>
          <LegalParagraph>
            <ul>
              <li>
                If any user believes its copyright, trademark or other property rights have been infringed
                by any content included or posted on this website, user should immediately send a notification
                to Propelio Realty&apos;s designated agent (“Designated Agent”), as identified below.  To be
                effective, the notification must include:

                <Box marginLeft={5}>
                  <ul>
                    <li>
                      A physical or electronic signature of a person authorized to act on behalf of the
                      owner of an exclusive right that is allegedly infringed;
                    </li>
                    <li>
                      Identification of the copyrighted work claimed to have been infringed;
                    </li>
                    <li>
                      Information reasonably sufficient to permit Propelio Realty to contact the complaining
                      party, such as address, telephone number and, if available, an electronic mail address
                      at which the complaining party may be contacted;
                    </li>
                    <li>
                      Identification of the material that is claimed to be infringing or to be subject to
                      infringing activity and that is to be removed and information reasonably sufficient
                      to permit Propelio Realty to locate the material;
                    </li>
                    <li>
                      A statement that the complaining party has a good faith belief that use of the material
                      in the manner complained of is not authorized by the copyright owner, agent, or the
                      law; and
                    </li>
                    <li>
                      A statement that the information in the notification is accurate and, under penalty
                      of perjury, that the complaining party is authorized to act on behalf of the owner
                      of an exclusive right that is allegedly infringed.
                    </li>
                  </ul>
                </Box>
              </li>
              <li>
                Pursuant to the Digital Millennium Copyright Act (“DMCA”), 17 U.S.C. § 512(c), Propelio
                Realty&apos;s Designated Agent for notice of claims of copyright infringement can be reached
                as indicated below. The Designated Agent shall be:
                <Box marginLeft={20}>
                  Propelio Realty, LLC
                  <br />
                  1145 Santa Fe Dr. #3075
                  <br />
                  Weatherford TX, 76086
                  <br />
                </Box>
              </li>
              <li>
                User acknowledges and agrees that upon receipt of a notice of a claim of copyright infringement,
                Propelio Realty may immediately remove the identified materials from the website without
                liability to user or any other party and that the claims of the complaining party and
                the party that originally posted the materials will be referred to the United States Copyright
                Office for adjudication as provided in the DMCA.
              </li>
              <li>
                Any copyright infringement by Users shall not be tolerated.  If any user is a repeat infringer
                of any copyright, trademark or other proprietary right, the license granted to the website
                may be immediately terminated.
              </li>
            </ul>
          </LegalParagraph>
        </LegalSection>
      </LegalSection>

      <LegalSection title='Paid Services'>
        <LegalParagraph>
          The Website offers services for a fee (“Paid Services”).  You agree to pay for all Paid Services
          in accordance with the terms provided on the Website.  The pricing and refund terms for Paid
          Services are included on the Website.  Propelio Realty may refund some or all the fees paid
          for Paid Services, in its sole discretion.  If you fail to pay for any Paid Services when due, Propelio
          Realty may immediately terminate your access to the Website, and any Paid Services you have
          been using.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Accessing the Website and Account Security'>
        <LegalParagraph>
          You acknowledge that access to the Website, including the VOW and Paid Services, may from time-to-time
          be unavailable to you, whether because of technical failures or interruptions, intentional downtime
          for service or changes to the Website, or otherwise.  You agree that any modification of the
          Website, and any interruption or unavailability of access to the Website shall not constitute
          a default of any obligations of Propelio Realty under these Terms of Use, and Propelio Realty
          shall have no liability of any nature to User for any such modifications, interruptions, unavailability, or
          failure of access.  Propelio Realty has the right at any time to change or discontinue any aspect
          or feature of the Website, including, without limitation, the content, hours of availability, and
          equipment needed for access or use of the Website.  From time to time, we may restrict access
          to some parts of the Website, or the entire Website, to users, including registered users.
        </LegalParagraph>
        <LegalParagraph>
          You are responsible for:
          <Box marginLeft={5}>
            <ul>
              <li>
                Making all arrangements necessary for you to have access to the Website, including any Internet
                connection.
              </li>
              <li>
                The payment of all Internet connection and telecommunications fees and charges that you
                incur when accessing the Website.
              </li>
              <li>
                Ensuring that all persons who access the Website through your internet connection are aware
                of these Terms of Use and comply with them.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          To access the Website or some of the resources it offers, you may be asked to provide certain
          registration details or other information. It is a condition of your use of the Website that
          all the information you provide on the Website is correct, current and complete. You agree that
          all information you provide to register with this Website or otherwise, is governed by our Privacy
          Policy, and you consent to all actions we take with respect to your information consistent with
          our Privacy Policy.
        </LegalParagraph>
        <LegalParagraph>
          If you choose, or are provided with, a username, password or any other piece of information
          as part of our security procedures, you must treat such information as confidential, and you
          must not disclose it to any other person or entity. You also acknowledge that your account is
          personal to you and agree not to provide any other person with access to this Website or portions
          of it using your username, password, or other security information. You agree to notify us immediately
          of any unauthorized access to or use of your username or password or any other breach of security. You
          also agree to ensure that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or shared computer so that
          others are not able to view or record your password or other personal information.
        </LegalParagraph>
        <LegalParagraph>
          We have the right to disable any username, password or other identifier, whether chosen by you
          or provided by us, at any time in our sole discretion for any or no reason, including if, in
          our opinion, you have violated any provision of these Terms of Use.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Intellectual Property Rights'>
        <LegalParagraph>
          The Website and its entire contents, including Listing Content, features and functionality, including
          but not limited to all information, software, text, displays, images, video and audio, and the
          design, selection and arrangement thereof, are owned by Propelio Realty, its licensors, including
          all applicable MLS, or other providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret and other intellectual property or
          proprietary rights laws.
        </LegalParagraph>
        <LegalParagraph>
          These Terms of Use permit you to use the Website during the current viewing session for your
          personal, non-commercial use only, except as otherwise expressly permitted by these Terms of
          Use. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly
          perform, republish, download, store or transmit any of the material on our Website, except as
          follows:
          <Box marginLeft={5}>
            <ul>
              <li>
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing
                and viewing those materials.
              </li>
              <li>
                You may store files that are automatically cached by your Web browser for display enhancement
                purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of pages of the Website for your
                own personal, non-commercial use and not for further reproduction, publication or distribution.
              </li>
              <li>
                If we provide desktop, mobile or other applications for download, you may download a single
                copy to your computer or mobile device solely for your own personal, non-commercial use, provided
                you agree to be bound by our end user license agreement for such applications.
              </li>
              <li>
                If we provide social media features with certain content, you may take such actions as are
                enabled by such features.
              </li>
              <li>
                You may not:
                <Box marginLeft={5}>
                  <ul>
                    <li>
                      Modify copies of any materials from this site.
                    </li>
                    <li>
                      Use any illustrations, photographs, video or audio sequences or any graphics separately
                      from the Propelio Realty text.
                    </li>
                    <li>
                      Delete or alter any copyright, trademark or other proprietary rights notices from copies
                      of materials from this site.
                    </li>
                  </ul>
                </Box>
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          Except as expressly permitted in these Terms of Use, you may not access or use for any commercial
          purposes any part of the Website or any services or materials available through the Website.
        </LegalParagraph>
        <LegalParagraph>
          If you print, copy, modify, download or otherwise use or provide any other person with access
          to any part of the Website in breach of the Terms of Use, your right to use the Website will
          cease immediately and you must, at our option, return or destroy any copies of the materials
          you have made. No right, title or interest in or to the Website or any content on the Website
          is transferred to you, and all rights not expressly granted are reserved by Propelio Realty. Any
          use of the Website not expressly permitted by these Terms of Use is a breach of these Terms
          of Use and may violate copyright, trademark and other laws.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Searches'>
        <LegalParagraph>
          When conducting a search for available properties for sale or rent provided on the Website, you
          agree to enter property search criteria, such as price range, location, and other features, that
          are a good faith representation of the types of properties that you are interested in, and financially
          capable of acquiring or leasing, or that represent properties similar to the property you have
          available for sale or lease.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Trademarks'>
        <LegalParagraph>
          The Propelio Realty name, Propelio Realty logo, and all related names, logos, product and service
          names, designs and slogans are trademarks of Propelio Realty or its affiliates or licensors. You
          may not use such marks without the prior written permission of Propelio Realty. All other names, logos,
          product and service names, designs and slogans on this Website are the trademarks of their respective
          owners.
        </LegalParagraph>
        <LegalParagraph>
          Apple, the Apple logo, iPad, iPhone, and iPod touch are trademarks of Apple Inc., registered
          in the U.S. and other countries. App Store is a service mark of Apple Inc. Android is a trademark
          of Google Inc. and use of this trademark is subject to Google Permissions. Other trademarks
          and logos used in connection with our Service may be the trademarks of their respective owners, and
          such use is not intended to grant us any rights in such trademarks.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Prohibited Uses'>
        <LegalParagraph>
          You may use the Website only for lawful purposes and in accordance with these Terms of Use. The
          Website is for use only in the United States, and no other country or territory.  You agree
          not to use the Website:
          <Box marginLeft={5}>
            <ul>
              <li>
                In any way that violates any applicable federal, state, local or international law or regulation,
                including, without limitation, any laws regarding the export of data or software to and
                from the United States or other countries.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or promotional material, without
                our prior written consent, including any &quot;junk mail&quot;, &quot;chain letter&quot; or
                &quot;spam&quot; or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Propelio Realty, a Propelio Realty employee, another
                user or any other person or entity, including, without limitation, by using email addresses
                or screen names associated with any of the foregoing.
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone&apos;s use or enjoyment
                of the Website, or which, as determined by us, may harm Propelio Realty or users of the
                Website or expose them to liability.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          Additionally, you agree not to:
          <Box marginLeft={5}>
            <ul>
              <li>
                Use the Website in any manner that could disable, overburden, damage, or impair the site
                or interfere with any other party&apos;s use of the Website, including their ability to
                engage in real time activities through the Website.
              </li>
              <li>
                Access the Website by any means other than the user interface provided by the Website, including, without
                limitation, through the use of scripts, bots, spiders, web crawlers, or other automatic
                device, process or means to access the Website for any purpose, including monitoring or
                copying any of the material on the Website.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on the Website or for any
                other unauthorized purpose without our prior written consent.
              </li>
              <li>
                Use any device, software or routine that interferes with the proper working of the Website.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious
                or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of
                the Website, the server on which the Website is stored, or any server, computer or database
                connected to the Website.
              </li>
              <li>
                Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.
              </li>
              <li>
                Use the Website to analyze, review or obtain information regarding the functionality, features,
                content, materials or individual elements of the Website, whether for commercial purposes
                or otherwise, except as expressly permitted in these Terms of Use.
              </li>
              <li>
                Modify, merge, decompile, disassemble, scrape, translate, decode or reverse engineer any
                portion of the Website, or technology used by Propelio Realty, to deliver the Website for
                any purpose, including, without limitation, to discover or directly access the source code, analytics,
                algorithms, methodologies or other know-how of the Website or any component or portion thereof, or
                use any data mining, gathering or extraction tool, or any robot, spider or other automatic
                device or manual process, to monitor or copy any portion of the Website, or other technology
                of Propelio Realty, or the data generated thereby.
              </li>
              <li>
                Derive, mine, extract, scrape and/or store any aspect of the Website for purposes of developing
                a database of information for any owned or third-party software, technology, product or
                service.
              </li>
              <li>
                Use, reproduce, compile, disclose, publish, display, distribute, transmit or make available
                any portion of the Website or technology of Propelio Realty, or the data generated thereby, for
                the purpose of selling or licensing the same on a stand-alone basis or otherwise.
              </li>
              <li>
                Make any portion of the Website publicly available.
              </li>
              <li>
                Create derivative works from the Website, or any aspect thereof.
              </li>
              <li>
                Store the Website outside of the United States.
              </li>
              <li>
                Access or attempt to access password protected, secure or non-public areas of the Website
                except as provided in these Terms of Use.
              </li>
              <li>
                Use, resell or sublicense any information on the Website for use: (A) as a factor in establishing
                an individual&apos;s eligibility for credit, insurance, or employment; (B) in connection
                with a determination of an individual&apos;s eligibility for a license or other benefit
                granted by a governmental authority; (C) in connection with underwriting individual insurance; or
                (D) in a way that would cause the information to constitute a “consumer report” under the
                Fair Credit Reporting Act, 15 U.S.C. § 1681 et seq. or similar statute, or by any other
                authority having jurisdiction over the applicable parties.
              </li>
              <li>
                Sell, assign, rent, lease, act as a service bureau, or grant rights in the Website, including, without
                limitation, through sublicense, to any other person or entity without our prior written
                consent.
              </li>
              <li>
                Make any false, misleading or deceptive statement or representation regarding Propelio Realty
                or the Website.
              </li>
              <li>
                Use the Website for any commercial purpose unless consistent with these Terms of Use, and
                the intended use of the same, or for the benefit of any third party, or charge any person
                or entity, or receive any compensation for, the use of the Website, unless you are specifically
                authorized to do so in a separate written agreement with Propelio Realty.
              </li>
              <li>
                Use the Website to perform any unsolicited commercial communication not permitted by applicable
                law.
              </li>
              <li>
                Use the Website to engage in any activity that (A) constitutes harassment or a violation
                of privacy or threatens other people or groups of people, (B) is harmful to children in
                any manner, (C) constitutes phishing, pharming or impersonates any other person or entity, or
                steals or assumes any person&apos;s identity, whether a real identity or online nickname
                or alias, or (D) violates any applicable law, ordinance, rule, regulation, or treaty.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the Website.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Monitoring and Enforcement; Termination'>
        <LegalParagraph>
          Propelio Realty has the right to:
          <Box marginLeft={5}>
            <ul>
              <li>
                Disclose your identity or other information about you to any third party who claims that
                material posted by you violates their rights, including their intellectual property rights
                or their right to privacy.
              </li>
              <li>
                Take appropriate legal action, including without limitation, referral to law enforcement, for
                any illegal or unauthorized use of the Website.
              </li>
              <li>
                Without notice, terminate all of your rights under these Terms of Use, and terminate or
                suspend your access to all or part of the Website at any time and for any or no reason, including
                without limitation, any violation of these Terms of Use.  If your access to the Website
                is disabled, you will not have access to the Website, including any User Content.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Your Termination of These Terms of Use'>
        <LegalParagraph>
          You may terminate your access to the Website and your rights under these Terms of Use at any
          time by completing the process for termination provided on the Website.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Reliance on Information Posted and Listing Content'>
        <LegalParagraph>
          The information presented on or through the Website is made available solely for general information
          purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any
          reliance you place on such information is strictly at your own risk. We disclaim all liability
          and responsibility arising from any reliance placed on such materials by you or any other visitor
          to the Website, or by anyone who may be informed of any of its contents.
        </LegalParagraph>
        <LegalParagraph>
          When conducting a search for available properties for sale or rent, you agree to enter property
          search criteria, such as price range, location, and other features, that are a good faith representation
          of the types of properties that you are interested in, and financially capable of acquiring
          or leasing, or that represent properties similar to the property you have available for sale
          or lease.
        </LegalParagraph>
        <LegalParagraph>
          You understand that in order to maintain maximum database efficiency, Propelio Realty will limit
          the number of properties displayed to you in response to your search requests based upon sole
          determination of Propelio Realty of the reasonable number of properties appropriate to be displayed
          in response to a single search request, given existing market conditions, total number of matching
          listings in the MLS database compilation, and other reasonable use restrictions deemed appropriate
          by Propelio Realty, which may vary from market to market and from time to time.
        </LegalParagraph>
        <LegalParagraph>
          Any and all property valuations originating from the Website are an opinion of value or comparative
          market analysis and should not be considered an appraisal. Propelio Realty makes no representation
          or warranty regarding the valuation of any real estate or other property.
        </LegalParagraph>
        <LegalParagraph>
          The Listing Content is intended to convey general information about available real estate listings, by
          and through Propelio Realty, as a licensed real estate broker. The Listing Content shall not
          be construed as professional or legal advice, but is intended for personal use, and general
          information purposes only. Your use of the Listing Content is limited to accessing and viewing
          the Listing Content, and making a reasonable number of single copies for the purpose of engaging
          in a bona fide real estate transaction.
        </LegalParagraph>
        <LegalParagraph>
          The Website links or references, or provides access to other third party websites, resources, content,
          services, products and/or materials (collectively, “Third Party Content”). Third Party Content
          includes Listing Content provided by one or more multiple listing services (“MLS”).  Propelio
          Realty makes no representation or warranty concerning any Third Party Content. The Third Party
          Content may be protected by various laws, rules and regulations, including, but not limited
          to, copyright as a collective work and/or compilation, and any reproduction, modification, creation
          of derivative works, copying, or redistribution, of any Third Party Content, or any portions
          thereof is expressly prohibited without first obtaining the prior written consent of the owner
          of the Third Party Content. You further acknowledge and agree that the Third Party Content may
          contain confidential information, and that you shall not disclose such confidential information
          without the prior written consent of the owner of the Third Party Content.  You acknowledge
          that Third Party Content may be subject to license agreements and other terms and conditions
          not included in these Terms of Use, and you agree to fully comply with all such license agreements
          and terms of use.  You acknowledge and agree that Propelio Realty is not responsible or liable
          for any Third Party Content, and you waive any and all possible claims against Propelio Realty
          in connection with the use and display of Third Party Content.
        </LegalParagraph>
        <LegalParagraph>
          In addition, the Website may include features provided by third parties (“Third Party Providers”) that
          allow you to create and maintain a website for your own business purposes (“Third Party Program”).  You
          agree to use any Third Party Program strictly in accordance with the terms and conditions of
          any applicable end user license agreement with the Third Party Provider, and specifically to
          avoid any potential liability for Propelio Realty.  You acknowledge and agree that any Third
          Party Program is provided by a Third Party Provider, and not Propelio Realty, and Propelio Realty
          shall have no liability of any nature to you in connection with your access to and use of a
          Third Party Program.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Changes to the Website'>
        <LegalParagraph>
          We may update the content on this Website from time to time, but its content is not necessarily
          complete or up-to-date. Any of the material on the Website may be out of date at any given time, and
          we are under no obligation to update such material. The Website, and the services, including
          Paid Services, features and functionality provided on the Website, may be modified, terminated, or
          otherwise be made unavailable in Propelio Realty&apos;s sole discretion.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Representations and Warranties'>
        <LegalParagraph>
          You represent and warrant that you have the legal capacity to enter these Terms of Use.  If
          you are using the Website on behalf of your employer or another entity, you represent and warrant
          that you have full legal authority to bind your employer or such other entity to these terms
          of Use.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Information About You and Your Visits to the Website'>
        <LegalParagraph>
          All information we collect on this Website is subject to our Privacy Policy. By using the Website, you
          consent to all actions taken by us with respect to your information in compliance with the Privacy
          Policy.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Linking to the Website and Social Media Features'>
        <LegalParagraph>
          You may link to our homepage, provided you do so in a way that is fair and legal and does not
          damage our reputation or take advantage of it, but you may not establish a link in such a way
          as to suggest any form of association, approval or endorsement on our part, without our express
          written consent.
        </LegalParagraph>
        <LegalParagraph>
          This Website may provide certain social media features that enable you to:
          <Box marginLeft={5}>
            <ul>
              <li>
                Link from your own or certain third-party websites to certain content on this Website.
              </li>
              <li>
                Send emails or other communications with certain content, or links to certain content, on
                this Website.
              </li>
              <li>
                Cause limited portions of content on this Website to be displayed or appear to be displayed
                on your own or certain third-party websites.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          You may use these features solely as they are provided by us, and solely with respect to the
          content they are displayed with, and otherwise in accordance with any additional terms and conditions
          we provide with respect to such features. Subject to the foregoing, you may not:
          <Box marginLeft={5}>
            <ul>
              <li>
                Establish a link from any website that is not owned by you.
              </li>
              <li>
                Cause the Website or portions of it to be displayed, or appear to be displayed by, for example,
                framing, deep linking or in-line linking, on any other site.
              </li>
              <li>
                Link to any part of the Website other than the homepage.
              </li>
              <li>
                Otherwise take any action with respect to the materials on this Website that is inconsistent
                with any other provision of these Terms of Use.
              </li>
            </ul>
          </Box>
        </LegalParagraph>
        <LegalParagraph>
          You agree to cooperate with us in causing any unauthorized framing or linking immediately to
          cease. We reserve the right to withdraw linking permission without notice.
        </LegalParagraph>
        <LegalParagraph>
          We may disable all or any social media features and any links at any time without notice in
          our discretion.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Links from the Website'>
        <LegalParagraph>
          If the Website contains links to other sites and resources provided by third parties, these
          links are provided for your convenience only. This includes links contained in advertisements, including
          banner advertisements and sponsored links. We have no control over the contents of those sites
          or resources, and accept no responsibility for them or for any loss or damage that may arise
          from your use of them. If you decide to access any of the third party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms and conditions of use
          for such websites.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Geographic Restrictions'>
        <LegalParagraph>
          The owner of the Website is based in the state of Texas in the United States. We provide this
          Website for use only by persons located in the United States. We make no claims that the Website
          or any of its content is accessible or appropriate outside of the United States. Access to the
          Website may not be legal by certain persons or in certain countries. If you access the Website
          from outside the United States, you do so on your own initiative and are responsible for compliance
          with local laws.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Disclaimer of Warranties'>
        <LegalParagraph>
          You understand that we cannot and do not guarantee or warrant that files and content available
          for downloading from the internet or the Website will be free of viruses or other destructive
          code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
          your particular requirements for anti-virus protection and accuracy of data input and output, and
          for maintaining a means external to our site for any reconstruction of any lost data. WE WILL
          NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES
          OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
          PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES
          OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR
          ON ANY WEBSITE LINKED TO IT.
        </LegalParagraph>
        <LegalParagraph>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE
          IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER PROPELIO REALTY NOR ANY PERSON ASSOCIATED
          WITH PROPELIO REALTY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE, OR ANY OF THE INFORMATION OR
          CONTENT PROVIDED THROUGH OR IN CONNECTION WITH THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER
          PROPELIO REALTY NOR ANYONE ASSOCIATED WITH PROPELIO REALTY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
          CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE
          OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
          AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES
          OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </LegalParagraph>
        <LegalParagraph>
          ROPELIO REALTY DOES NOT REPRESENT OR WARRANT THAT (A) YOU WILL BE ABLE TO ACCESS OR USE THE
          WEBSITE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; (B) YOUR USE OF THE WEBSITE WILL MEET YOUR
          REQUIREMENTS; (C) DEFECTS IN THE OPERATION OF THE WEBSITE WILL BE CORRECTED; OR (E) THE WEBSITE
          IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE WEBSITE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND
          OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. FURTHERMORE, PROPELIO
          REALTY DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE
          CONDITIONS OF THE WEBSITE DUE TO INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE
          PROVIDERS, TO THE SATURATION OF THE INTERNET NETWORK, AND FOR ANY OTHER REASON.
        </LegalParagraph>
        <LegalParagraph>
          YOU ACKNOWLEDGE AND AGREE THAT ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
          OF THE WEBSITE IS AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
          YOUR COMPUTER, MOBILE PHONE OR OTHER DEVICE OR ANY LOSS OF DATA RESULTING FROM DOWNLOADING OR
          OBTAINING SUCH MATERIAL
        </LegalParagraph>
        <LegalParagraph>
          PROPELIO REALTY IS NOT RESPONSIBLE AND MAKES NO REPRESENTATIONS OR WARRANTIES FOR THE DELIVERY
          OF ANY MESSAGES, SUCH AS POSTING OF ANSWERS OR TRANSMISSION OF ANY SUBMISSIONS OR OTHER THIRD-PARTY
          CONTENT, SENT THROUGH PROPELIO REALTY TO ANYONE. ANY MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED
          OR USED ON THE WEBSITE MAY BE SUBJECT TO INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES
          WHO HAVE LICENSED SUCH MATERIAL, SERVICE, OR TECHNOLOGY TO PROPELIO REALTY. PROPELIO REALTY
          DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS SUBSCRIBING TO THE WEBSITE, NOR
          DOES IT HAVE ANY OBLIGATION TO MONITOR THE USE OF THE WEBSITE BY OTHER USERS OF THE WEBSITE.  THEREFORE,
          PROPELIO REALTY DISCLAIMS ALL LIABILITY FOR IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY
          OR INFORMATION BY OTHERS.
        </LegalParagraph>
        <LegalParagraph>
          PROPELIO REALTY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY
          OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTY OF SATISFACTORY QUALITY, AND ANY WARRANTIES
          OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
        </LegalParagraph>
        <LegalParagraph>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
          LAW.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Additional Disclaimers'>
        <LegalParagraph>
          You acknowledge and agree that projected market and financial information, conclusions and other
          information contained on the Website are not definitive forecasts, appraisals or opinions of
          value. All such information and conclusions are stated, or are assumed to be, in terms of probability
          of likelihood based on market factors and information submitted to Propelio Realty, and such
          information and conclusions are not guaranteed by Propelio Realty and should not be construed
          as investment advice or relied upon for critical decision making. Propelio Realty uses or has
          used public and/or confidential data and assumptions provided to Propelio Realty by third parties, and
          Propelio Realty has not independently verified the data and assumptions used in these analyses
          or data sets. Changes in the underlying data or operating assumptions, or any loss of access
          to any one or more sources will clearly impact the analyses and conclusions.
        </LegalParagraph>
        <LegalParagraph>
          You further acknowledge and agree that, except as provided in connection with any particular
          product or service within its associated terms of use, Propelio Realty makes no representations
          or warranties, express or implied, regarding the Website, or the information contained therein, including
          the recommendations or the advice given therein, and Propelio Realty has no liability to you, or
          to any third parties, relating to the use or implementation of the information contained in
          the Website, or any action, inaction or decisions, including any lending, investment, purchase
          or disposition decision, that may be made based on the information provided therein. You agree
          that any decision, including any lending, investment, purchase or disposition decision, regarding
          or relating to or based on the use or implementation of any output, data, analysis, recommendation
          or advice contained in the Website is made solely by you, and your agents and employees, but
          specifically excluding real estate agents of Propelio Realty, at your or their sole and exclusive
          discretion. You further agree that you, and your agents and employees shall not hold Propelio
          Realty, or any of its affiliates or related party, liable for the use or implementation, by
          you or any other party, of the information contained in the Website, or for any action, inaction
          or decisions, including any lending, investment, purchase or disposition decision, made, by
          you or any other party, based on the information provided in the Website.
        </LegalParagraph>
        <LegalParagraph>
          The Website does not constitute legal, tax, accounting or other professional advice, and may
          not be used for or relied upon for these purposes. Although you are entering into a lawful broker-consumer, or
          similar relationship, no attorney-client, advisory, fiduciary or other relationship is created
          by your acceptance or use of the Website. You shall not use the Website to determine an individual&apos;s
          eligibility for credit, insurance, employment, or government license or benefit. The information
          on the Website is not an offer or solicitation by anyone in any jurisdiction in which an offer
          or solicitation cannot legally be made, or to any person to whom it is unlawful to make a solicitation.
        </LegalParagraph>
        <LegalParagraph>
          You acknowledge and agree that Propelio Realty&apos;s information providers shall not be liable
          for any claim or loss resulting from the content of, errors or omissions in, or your use of
          the information contained in or retrieved from the Website, including without limitation any
          User Content. Issues of data accuracy may be brought to Propelio Realty&apos;s attention by
          sending feedback, but it is likely that such information accuracy cannot be corrected by Propelio
          Realty, and the entity or person that generated the information must be appealed to.
        </LegalParagraph>
        <LegalParagraph>
          The disclaimers of warranties in these Terms of Use may not be enforceable in some states or
          jurisdictions, and in such event, the unenforceable disclaimers of warranties will be deemed
          to not be applicable to you.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Limitation on Liability'>
        <LegalParagraph>
          IN NO EVENT WILL PROPELIO REALTY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
          MANAGES, MEMBERS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
          THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
          WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR
          ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, IN AN AMOUNT TO EXCEED ONE HUNDRED
          DOLLARS ($100).  IN NO EVENT WILL PROPELIO REALTY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
          PROVIDERS, EMPLOYEES, AGENTS, MANAGES, MEMBERS, OFFICERS, OR DIRECTORS BE LIABLE FOR ANY DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
          INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT
          (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. DAMAGES OF ANY
          KIND, UNDER ANY LEGAL THEORY.
        </LegalParagraph>
        <LegalParagraph>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
          LAW.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Indemnification'>
        <LegalParagraph>
          YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS PROPELIO REALTY, ITS OFFICERS, DIRECTORS, MANAGERS
          , MEMBERS. EMPLOYEES, SHAREHOLDERS, AGENTS, AFFILIATES, LICENSORS, SUPPLIERS, SERVICE PROVIDERS, AND
          THEIR SUCCESSORS AND ASSIGNS , AND THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, MEMBERS, EMPLOYEES
          , CONTRACTORS, AGENTS, AFFILIATES, LICENSORS, SUPPLIERS, SERVICE PROVIDERS, AND THEIR SUCCESSORS
          AND ASSIGNS, FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, JUDGMENTS, AWARDS, LOSSES, COSTS, EXPENSES
          OR FEES, INCLUDING REASONABLE ATTORNEYS&apos; FEES, ARISING OUT OF OR RELATING TO (A) YOUR VIOLATION
          OF THESE TERMS OF USE OR YOUR USE OF THE WEBSITE, (B) ANY USE OF THE WEBSITE&apos;S CONTENT, SERVICES
          AND PRODUCTS OTHER THAN AS EXPRESSLY AUTHORIZED IN THESE TERMS OF USE OR YOUR USE OF ANY INFORMATION
          OBTAINED FROM THE WEBSITE, (C) ANY USER CONTENT, INCLUDING ANY CLAIM THAT THE UPLOADING OR SUBMISSION
          OF, ACCESS TO, DISPLAY OF, OR USE OF ANY USER CONTENT, OR ANY PORTION OF THE USER CONTENT, INFRINGES
          ON OR CONSTITUTES A MISAPPROPRIATION OF THE RIGHTS OF ANY THIRD PARTY, INCLUDING ANY PATENT, COPYRIGHT, OR
          TRADE SECRET, OR ANY OTHER INTELLECTUAL PROPERTY RIGHT OF ANY PERSON OR ENTITY ANYWHERE IN THE
          WORLD, AND (D) ANY NEGLIGENT ACTS, ERRORS OR OMISSIONS OF YOU OR YOUR AGENTS OR CONTRACTORS.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Governing Law and Jurisdiction'>
        <LegalParagraph>
          All matters relating to the Website and these Terms of Use, and any dispute or claim arising
          therefrom or related thereto, in each case, including non-contractual disputes or claims, shall
          be governed by and construed in accordance with the internal laws of the State of Texas without
          giving effect to any choice or conflict of law provision or rule, whether of the State of Texas, or
          any other jurisdiction).  The application of the United Nations Convention on Contracts for
          the International Sale of Goods is expressly excluded.
        </LegalParagraph>
        <LegalParagraph>
          Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the
          Website shall be instituted exclusively in the federal courts of the United States or the courts
          of the State of Texas, in each case located in the City of Dallas and County of Dallas, although
          Propelio Realty retains the right to bring any suit, action or proceeding against you for breach
          of these Terms of Use in any other jurisdiction, and you waive any and all objections to the
          exercise of jurisdiction over you by such courts and to venue in such courts.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Limitation on Time to File Claims'>
        <LegalParagraph>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR
          THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH
          CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title="Children&apos;s Online Privacy Protection Act">
        <LegalParagraph>
          You agree and acknowledge that the Website is operated for individual users who are at least
          thirteen (13) years old and Propelio Realty never requests personally identifiable information
          from anyone under the age of thirteen (13).  You represent and warrant that you are thirteen
          (13) years old or older, and you shall not provide any personal information in violation of
          the Children&apos;s Online Privacy Protection Act, 15 U.S.C. §§ 6501 through 6506.  If you know
          that a child has provided personally identifiable information and such child is under the age
          of thirteen (13), you agree to immediately notify Propelio Realty as provided in these Terms
          of Use.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Export Controls'>
        <LegalParagraph>
          You agree to comply with all export laws and regulations of the United States or any other country
          (“Export Controls”), and not export, direct or transfer any portion of the Website, or any direct
          product thereof, to any destination, person or entity restricted or prohibited by the Export
          Controls.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title="Attorney&apos;s Fees">
        <LegalParagraph>
          If any action is brought by you or Propelio Realty against the other party regarding the subject
          matter of these Terms of Use, the prevailing party shall be entitled to recover, in addition
          to any other relief granted, reasonable attorney fees, costs, and expenses of litigation.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Waiver and Severability'>
        <LegalParagraph>
          No waiver by Propelio Realty of any term or condition set forth in these Terms of Use shall
          be deemed a further or continuing waiver of such term or condition or a waiver of any other
          term or condition, and any failure of Propelio Realty to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </LegalParagraph>
        <LegalParagraph>
          If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction
          to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of the Terms of Use will continue
          in full force and effect.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='No Third Party Beneficiaries; Entire Agreement'>
        <LegalParagraph>
          These Terms of Use are for the sole and exclusive benefit of you and Propelio Realty, and are
          not intended to benefit any third party.  No third party may claim any right or benefit under
          or seek to enforce any of the terms and conditions of these Terms of Use.  The Terms of Use, and
          any additional terms and conditions for the VOW or Paid Services as provided in these Terms
          of Use, applicable MLS Rules, and the Privacy Policy, constitute the sole and entire agreement
          between you and Propelio Realty with respect to the Website and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both written and oral, with respect
          to the Website.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Equitable Relief.'>
        <LegalParagraph>
          You agree that a breach or violation of these Terms of Use may result in immediate and irreparable
          injury and harm to Propelio Realty.  In such event, Propelio Realty shall have, in addition
          to any and all remedies of law and other consequences under these Terms of Use, the right to
          an injunction, specific performance or other equitable relief to prevent the violation of the
          obligation under these Terms of Use without any obligation to post a bond or other security, and
          you expressly waive any obligation for the posting of any such bond or security; provided, however, that, this
          shall in no way limit any other remedies which Propelio may have, including, without limitation, the
          right to seek monetary damages.
        </LegalParagraph>
      </LegalSection>

      <LegalSection title='Notices and Other Communications'>
        <LegalParagraph>
          You agree to receive all communications, agreements, and notices that we provide in connection
          with the Website electronically, including by email, SMS text message, or by posting them on
          the Website. You agree that all communications provided by Propelio Realty to you electronically
          satisfy any legal requirement that such communications be in writing.  Any notice to Propelio
          Realty shall be in writing and delivered to Propelio Realty at the following address: 1145 Santa
          Fe Dr #3075 Weatherford, TX 76086
        </LegalParagraph>
        <LegalParagraph>
          For purposes of responding to you and providing you with information and notices about your
          account or the Website, such as information about homes you might be interested in, you agree
          that these Terms of Use constitute a written contract and an existing business relationship
          between you and Propelio Realty, and that we may communicate with you using the contact information
          associated with your account or the Website. Please review your account settings on the Website, or
          settings on your mobile device, to control what kind of messages you receive from Propelio Realty. Propelio
          Realty has no liability arising from your failure to maintain accurate contact or other information,
          including, but not limited to, your failure to receive critical information about the Website. Through
          the Website you may find, search and filter for homes and home types you are looking for, listing
          price and neighborhood information regarding schools, safety, proximity to grocery stores and
          neighborhood amenities. By using the Website, you authorize Propelio Realty to share your personal
          information including your contact information, home search history, favorites and saved searches. Propelio
          Realty may send you messages, including without limitation email, text messages/SMS, push notifications, and
          direct mail, relating to your account or your use of the Website. Propelio Reality will not
          send you messages for marketing purposes without first receiving your prior express consent.
        </LegalParagraph>
        <LegalParagraph>
          You may choose to, or Propelio Realty may invite you to, submit comments, bug reports, ideas
          or other feedback about the Website (“Feedback”). By submitting Feedback, you agree that Propelio
          Realty is free to use such Feedback at its discretion without any obligation to you. Propelio
          Realty may also choose to disclose Feedback to third parties. You hereby grant us a royalty-free,
          perpetual, irrevocable, transferable, sublicensable, worldwide, nonexclusive right and license
          to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative
          works of, and otherwise commercially or non-commercially exploit in any manner it deems appropriate, any
          and all Feedback, and to sublicense the foregoing rights.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Severability'>
        <LegalParagraph>
          If any provision of these Terms of Use are determined to be invalid or unenforceable, then such
          portion shall be severed, and the remainder of these Terms of Use shall be given full force
          and effect.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='Assignment'>
        <LegalParagraph>
          You agree that these Terms of Use are personal to you, and you may not assign or transfer any
          license or rights granted under these Terms of Use, or transfer any rights or delegate any duties
          under these Terms of Use, to any third party.  Any attempt to assign, transfer, or delegate
          any of User&apos;s rights, duties, or obligations under these Terms of Use shall be void.  Propelio
          Realty may assign these Terms of Use, and assign any of its rights and delegate any of its duties
          under these Terms of Use.
        </LegalParagraph>
      </LegalSection>
      <LegalSection title='No Partnership'>
        <LegalParagraph>
          Nothing in these terms of Use shall be construed to create a partnership, or joint venture, or
          any other form of association, for tax purposes or otherwise, between you and Propelio Realty, and
          you and Propelio Realty shall at all times be and remain an independent contractors of each
          other.
        </LegalParagraph>
      </LegalSection>
    </LegalDocument>
  </Flex>
)
