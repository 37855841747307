import { MenuItemOption, MenuOptionGroup, Text, VStack } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { LockIcon } from 'presentation/components/Icons'
import { SelectMenu, SelectMenuButton, SelectMenuList } from 'presentation/components/SelectMenu'
import { Card } from 'presentation/components/molecules/Card'
import { useCompsFiltersPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { ClearBoundaryButton, DrawBoundaryButton } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundaryButtons'
import { useCompsBoundaryLinesFromFilters } from 'presentation/screens/CompsScreen/components/CompsBoundary/useCompsBoundaryLinesFromFilter'
import { DistanceFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/DistanceFilterCard/DistanceFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'
import { Miles } from 'presentation/screens/CompsScreen/components/common/Miles'
import { isNumber } from 'remeda'

export const DistanceFilterCardDumb = (
  props: DistanceFilterCardDumbProps,
) => {
  const selectedLabel = Miles.roundOffThenToLabel(props.value.max)
  const linesFromFilters = useCompsBoundaryLinesFromFilters()
  const hasLinesFromFilters = linesFromFilters.length > 0
  const status = CompsBoundary.useStore(state => state.status)
  const isDrawing = status === 'drawing'
  const shouldShowDrawButton = isDrawing || !hasLinesFromFilters
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)
  const filterPanel = useCompsFiltersPanelStore()

  const handleMaxChange = (max: number) => {
    props.onChange({
      ...props.value,
      max,
    })
  }
  const handleOpenChange = () => {
    props.onOpenChange?.(!props.isOpen)
  }

  return (
    <FilterCard
      title='Distance'
      hasBadge={isNumber(props.value.max)}
    >
      {shouldShowDrawButton
        ? (
          <>
            <SelectMenu isOpen={props.isOpen}>
              <SelectMenuButton
                w='full'
                onClick={handleOpenChange}
              >
                {selectedLabel}
              </SelectMenuButton>
              <SelectMenuList>
                <MenuOptionGroup
                  value={props.value.max.toString()}
                  onChange={maxStringOrArr => {
                    const maxString = Array.isArray(maxStringOrArr) ? maxStringOrArr[0] : maxStringOrArr
                    const max = Number.parseFloat(maxString)
                    handleMaxChange(max)
                  }}
                >
                  {Miles.getOptions().map(preset => (
                    <MenuItemOption
                      key={preset}
                      type='radio'
                      value={preset.toString()}
                    >
                      {Miles.roundOffThenToLabel(preset)}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </SelectMenuList>
            </SelectMenu>
            <DrawBoundaryButton
              mt={-1}
              w='full'
              onClick={() => {
                props.onOpenChange?.(false)
                filterPanel.closePanel()
                CompsBoundary.setStatus('drawing')
                CompsBoundary.setLines(linesFromFilters)
              }}
            />
          </>
        )
        : (
          <Card
            bg='card.bg.2'
            p={3}
            borderRadius={3}
            boxShadow='primary.w'
          >
            <VStack
              spacing={1}
            >
              <LockIcon
                fontSize={4}
                color='neutral.500'
                sx={{
                  path: {
                    strokeWidth: '1',
                  },
                }}
              />

              <Text
                textStyle='bodyMFat'
                color='graystrong.200'
                textAlign='center'
              >
                You&rsquo;ve drawn a boundary on the map.
                {' '}
                To enable the dropdown, please clear your boundary.
              </Text>

              <ClearBoundaryButton
                mt={2}
                onClick={() => {
                  props.onOpenChange?.(false)
                  CompsBoundary.clearLines()
                  void filterComps({ boundary: null })
                }}
              />
            </VStack>
          </Card>
        )}
      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )
}
