// eslint-disable-next-line no-restricted-imports
import { Modal, ModalContent } from '@chakra-ui/react'
import NearbyBuyersHistoryMobileScreen from 'features/NearbyBuyers/views/NearbyBuyersHistoryMobileScreen/NearbyBuyersHistoryMobileScreen'
import { noop } from 'utils/noop'

export const NearbyBuyersHistoryMobileModal = () => (
  <Modal
    isOpen
    onClose={noop}
    scrollBehavior='inside'
    size='full'
  >
    <ModalContent
      containerProps={{
        paddingInlineStart: '0 !important',
        paddingInlineEnd: '0 !important',
      }}
      w='full'
    >
      <NearbyBuyersHistoryMobileScreen />
    </ModalContent>
  </Modal>
)
