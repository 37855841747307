import { Button, ButtonProps, Center, Grid, IconProps, Image, Text, VStack } from '@chakra-ui/react'
import { Array, Match, pipe } from 'effect'
import logo from 'presentation/assets/img/white-logo-white-text.svg'
import { CartIcon } from 'presentation/components/Icons'
import { SolidFeatherIcon, SolidPaperPlaneIcon, SolidPlaneIcon, SolidRocketIcon } from 'presentation/components/Icons/solid'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import PlanIntervalSwitcher from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/PlanIntervalSwitcher'
import HeaderRowViewModel from './HeaderRowViewModel'

const HeaderRow = () => {
  const vm = HeaderRowViewModel.usePresenter()
  const iconProps: IconProps = {
    boxSize: {
      base: 4,
      dtSm: 6.5,
    },
    color: '#FFB636',
  }

  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
      bgColor={vm.headerBgColor}
      borderTopRadius='6.5'
      pt='6'
      zIndex={1}
    >
      <VStack justifyContent='center'>
        <PropelioLogo />
      </VStack>
      {Array.map(vm.headerValues, (plan, i) => (
        <VStack spacing='2' key={i}>
          {pipe(
            Match.value(plan.iconType),
            Match.when('feather', _ => <SolidFeatherIcon {...iconProps} />),
            Match.when('paper-plane', _ => <SolidPaperPlaneIcon {...iconProps} />),
            Match.when('plane', _ => <SolidPlaneIcon {...iconProps} />),
            Match.when('rocket', _ => <SolidRocketIcon {...iconProps} />),
            Match.exhaustive,
          )}
          <Text
            textStyle={{
              base: 'h3-h-b',
              dtSm: 'h2Heavy',
            }}
            color='ondark.1'
            casing='capitalize'
          >
            {plan.planName}
          </Text>
          {plan.isCurrentPlan
            ? (
              <Button
                variant='solid'
                colorScheme='highlight'
                isDisabled
              >
                Current Plan
              </Button>
            )
            : (

              <AddToCartButton
                onClick={plan.addToCart}
                isDisabled={plan.isDisabled}
              />
            )}
        </VStack>
      ))}
      <Center
        mt='5'
        py='3'
        gridColumn='1 / -1'
        bgColor={vm.switchBgColor}
      >
        <PlanIntervalSwitcher />
      </Center>
    </Grid>
  )
}

export default HeaderRow

const PropelioLogo = () => (
  <Image
    src={logo}
    alt='Propelio'
    w={{
      base: '120px',
      dtSm: '220px',
    }}
  />
)

const AddToCartButton = (props: ButtonProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const showIcon = sbp({
    mobSm: false,
    dtSm: true,
  }) ?? false
  return (
    <Button
      variant='solid'
      colorScheme='highlight'
      {...showIcon && {
        leftIcon: <CartIcon boxSize='3' />,
      }}
      {...props}
    >
      Add to cart
    </Button>
  )
}
