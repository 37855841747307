import { NumberLib } from 'libs/Number'
import { isNumber } from 'remeda'

export type BathroomsCountInfo = {
  total: string | null
  full: number | null
  half: number | null
}

const getTotal = ({ full, half }: {
  full: number | null
  half: number | null
}): string | null => {
  if (isNumber(full) && isNumber(half)) return `${Math.round(full)}.${Math.round(half)}`
  if (isNumber(full) && half === null) return `${Math.round(full)}`
  if (full === null && isNumber(half)) return `0.${Math.round(half)}`
  return null
}

const fromMissableFullHalf = (
  missableFull: number | null | undefined,
  missableHalf: number | null | undefined,
): BathroomsCountInfo => {
  const full = NumberLib.orNull(missableFull)
  const half = NumberLib.orNull(missableHalf)

  return {
    total: getTotal({ full, half }),
    full,
    half,
  }
}

export const BathroomsCountInfo = {
  getTotal,
  fromMissableFullHalf,
}
