import { Box, Button, Flex, Text } from '@chakra-ui/react'
import cn from 'classnames'
import { HashLink } from 'presentation/components/HashLink'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { PlansSubheading } from 'presentation/screens/Plans/PlansTypography'
import { fadeIn, fadeInDown, fast, faster } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export const DealSection: FC = () => (
  <Box
    bgColor='accent.xdarkpurple'
    pt={mbp({ mobSm: '5', dtSm: '7' })}
    px={mbp({ mobSm: '2' })}
    pb={mbp({ mobSm: '3', dtSm: '5' })}
  >
    <ScrollAnimation
      className={cn(fadeInDown, fast)}
    >
      <Text
        as='h1'
        textStyle={mbp({
          mobSm: 'inter-24-700',
          tabSm: 'inter-29-700',
          dtSm: 'inter-36-700',
          dtLg: 'inter-44-700',
        })}
        textAlign='center'
        color='ondark.1'
      >
        Do more deals using Propelio tools
      </Text>
    </ScrollAnimation>
    <ScrollAnimation className={cn(fadeIn, faster)}>
      <PlansSubheading
        color='ondark.1'
        mb='3'
      >
        Maximize your potential with our all-in-one real estate solution!
      </PlansSubheading>
    </ScrollAnimation>

    <ScrollAnimation
      className={cn(fadeIn)}
      staggerFactor='longer'
    >
      <Flex
        flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
        gap={mbp({ mobSm: '2', mob: '3' })}
        justifyContent='center'
      >
        <Button
          variant='solid'
          colorScheme='highlight'
          size={mbp({ mobSm: 'sm', tabSm: 'md', dtLg: 'lg' })}
          as={HashLink}
          to='#second-choose-plan'
        >
          CHOOSE PLAN
        </Button>
        <Button
          variant='outline'
          colorScheme='highlight'
          size={mbp({ mobSm: 'sm', tabSm: 'md', dtLg: 'lg' })}
          onClick={() => {
            void SupportWidget.openWidget({ topic: 'pricing' })
          }}
        >
          TALK TO SALES
        </Button>
      </Flex>
    </ScrollAnimation>
  </Box>
)
