import { BoxProps } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { MLSStatus } from 'features/valueObjects/MLSStatus'

export type MLSStatusColorProps = {
  color: string
  bgColor: string
  borderColor: string
} & Omit<BoxProps, 'color' | 'bgColor' | 'borderColor'>

export const getMLSStatusTagColor = (
  status: MLSStatus,
): MLSStatusColorProps => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
    borderColor: 'marker.darkgreen',
  })),
  Match.when('FOR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
    borderColor: 'marker.darkgreen',
  })),
  Match.when('SALE_OR_LEASE', () => ({
    color: 'marker.darkgreen',
    bgColor: 'marker.mintgreen',
    borderColor: 'marker.darkgreen',
  })),
  Match.when('PENDING', () => ({
    color: 'marker.oxfordblue',
    bgColor: 'marker.babyblue',
    borderColor: 'marker.oxfordblue',
  })),
  Match.when('SOLD', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
    borderColor: 'marker.maroon',
  })),
  Match.when('LEASED', () => ({
    color: 'marker.maroon',
    bgColor: 'marker.pink',
    borderColor: 'marker.maroon',
  })),
  Match.when('EXPIRED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
    borderColor: 'marker.gray',
  })),
  Match.when('CANCELED', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
    borderColor: 'marker.gray',
  })),
  Match.when('WITHDRAWN', () => ({
    color: 'marker.white',
    bgColor: 'marker.gray',
    borderColor: 'marker.gray',
  })),
  Match.when('OFF_MARKET', () => ({
    color: 'marker.dirtywhite',
    bgColor: 'marker.darkgray',
    borderColor: 'marker.darkgray',
  })),
  Match.exhaustive,
)
