import { Button, Center, Checkbox, Flex, Spinner, Text } from '@chakra-ui/react'
import { Option } from 'effect'
import NearbyBuyerListEdgeMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerListEdgeMachine'
import SaveNearbyBuyersToListHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/SaveNearbyBuyersToListHooks'
import { NumberLib } from 'libs/Number'
import pluralize from 'pluralize'
import ladyGiftPng from 'presentation/assets/img/3d/lady-gift.png'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { Card, CardBody, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'

const SaveBuyersToListCard = () => {
  const actors = SaveNearbyBuyersToListHooks.useListActorsRefs()
  const save = SaveNearbyBuyersToListHooks.useSave()
  const isSaving = SaveNearbyBuyersToListHooks.useIsSaving().pipe(Option.getOrElse(() => false))

  return (
    <Card
      variant='modal.alert'
      size='modal.alert.xn'
      colorScheme='modal.alert.neutral'
    >
      <CardImage
        src={ladyGiftPng}
        display={mbp({ mobSm: 'none', tabSm: 'block' })}
      />

      <CardPrimaryTitle color='positive.500'>
        Save Buyers to List
      </CardPrimaryTitle>
      <CardPrimaryText>
        Select the desired list from the options below or create a new list.
      </CardPrimaryText>

      <CardBody mt={mbp({ mobSm: 2, tabSm: 3 })}>
        <ScrollWithFade
          containerProps={{
            minH: '280px',
            maxH: '460px',
            h: 'full',
          }}
          pb={3}
          px={2}
          mx={-2}
          fadeColor='rgb(255, 255, 255, 1.5)'
          fadeTopSize={-0.5}
          fadeBottomSize={4}
          display='flex'
          flex='1 1 0'
          flexDirection='column'
        >
          <Flex
            h='full'
            flexDirection='column'
            flex='1 1 0'
            gap={1}
          >
            {actors.pipe(
              Option.map(actorRefs =>
                actorRefs.map(actorRef => (
                  <ListSelectionItem
                    key={actorRef.id}
                    actorRef={actorRef}
                  />
                )),
              ),
              Option.getOrElse(() => (
                <Center flex='1 1 0' h='full'>
                  <Spinner color='graystrong.500' />
                </Center>
              )),
            )}
          </Flex>
        </ScrollWithFade>
      </CardBody>
      <CardFooter
        shouldResetSpacing
        gap={mbp({ mobSm: 2, tabSm: 3 })}
        mt={6}
      >
        <Button
          variant='solid'
          colorScheme='neutral'
        >
          Create New List
        </Button>
        <Button
          variant='solid'
          colorScheme='positive'
          onClick={save}
          isLoading={isSaving}
          loadingText='Saving'
        >
          Save
        </Button>
      </CardFooter>
    </Card>
  )
}

export default SaveBuyersToListCard

const ListSelectionItem = ({ actorRef }: {
  actorRef: NearbyBuyerListEdgeMachine.ActorRef
}) => {
  const list = SaveNearbyBuyersToListHooks.useList(actorRef)
  const isChecked = SaveNearbyBuyersToListHooks.useListIsChecked(actorRef)
  const toggle = SaveNearbyBuyersToListHooks.useToggleList(actorRef)

  return (
    <Checkbox
      isChecked={isChecked}
      onChange={toggle}
      py={1}
      sx={{
        '& .chakra-checkbox__label': {
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          w: 'full',
          minW: 0,
        },
      }}
    >
      <Text
        textStyle='bodyMFat'
        color='graystrong.400'
        isTruncated
      >
        {list.name}
      </Text>
      <Text
        textStyle='bodySFat'
        color='grayweak.900'
        ml='auto'
        whiteSpace='nowrap'
      >
        {NumberLib.formatComma(list.memberCount)}
        {' '}
        {pluralize('property', list.memberCount)}
      </Text>
    </Checkbox>
  )
}
