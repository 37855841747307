import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { mapGetSkiptraceResultResponse } from 'features/Skiptrace/infra/remote/getSkiptraceResult/getSkiptraceResult.mapper'
import { getTracers } from 'features/Skiptrace/infra/remote/getTracers/getTracers'
import { restClient } from 'presentation/libs/client'

/** @TODO Caching */
export const getSkiptraceResult = async ({ leadId }: {
  leadId: string
}): Promise<Skiptrace.GetSkiptraceResultResult> => {
  const [
    skiptraceResponse,
    tracersResponse,
  ] = await Promise.all([
    await restClient.get(`legacy/leads/${leadId}`).json(),
    getTracers(),
  ])

  const mapped = mapGetSkiptraceResultResponse(
    skiptraceResponse,
    tracersResponse.tracers,
  )

  return mapped
}
