import { Match, pipe } from 'effect'
import { FreeAndClearMarker, HighEquityMarker, LowEquityMarker, UpsideDownMarker } from 'presentation/components/PropertyMarker/components/EquityMarker'
import { EquityOnlyPropertyMarkerProps } from 'presentation/components/PropertyMarker/components/EquityOnlyPropertyMarker/EquityOnlyPropertyMarker.props'
import { MarkerSvgContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerSvgContainer'

export const EquityOnlyPropertyMarker = (props: EquityOnlyPropertyMarkerProps) => (
  <MarkerSvgContainer size={props.size} markerType={props.markerType}>
    {pipe(
      Match.value(props.equityType),
      Match.when('FREE_AND_CLEAR', () => <FreeAndClearMarker />),
      Match.when('HIGH', () => <HighEquityMarker />),
      Match.when('LOW', () => <LowEquityMarker />),
      Match.when('UPSIDE_DOWN', () => <UpsideDownMarker />),
      Match.orElse(() => null),
    )}
  </MarkerSvgContainer>
)
