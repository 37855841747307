import { Box, ChakraProps, Flex, FlexProps, chakra, keyframes } from '@chakra-ui/react'
import classNames from 'classnames'
import { Match, pipe } from 'effect'
import { animationClasses } from 'presentation/utils/animationClasses'
import { MouseEventHandler, useEffect, useRef } from 'react'

export type ThumbValue = 'up' | 'down' | 'undecided'

export type ThumbSize = 'sm' | 'md' | 'lg'

export type ThumbButtonsProps = {
  size?: ThumbSize
  value?: ThumbValue
  onChange?: (value: ThumbValue) => void
} & Omit<FlexProps, 'onChange'>

export const ThumbButtons = (props: ThumbButtonsProps) => {
  const { value, onChange, size = 'sm', ...flexProps } = props

  const boxSize = pipe(
    Match.value(size),
    Match.when('lg', () => 4),
    Match.when('md', () => 3),
    Match.when('sm', () => 2.5),
    Match.exhaustive,
  )

  const handleOnThumbsUp: MouseEventHandler = e => {
    e.stopPropagation()
    onChange?.(
      value === 'up' ? 'undecided' : 'up',
    )
  }

  const handleOnThumbsDown: MouseEventHandler = e => {
    e.stopPropagation()
    onChange?.(
      value === 'down' ? 'undecided' : 'down',
    )
  }

  return (
    <Flex {...flexProps}>
      <Box
        as='button'
        p='6px'
        onClick={handleOnThumbsUp}
      >
        <ThumbsUpIcon boxSize={boxSize} value={value} />
      </Box>
      <Box
        as='button'
        p='6px'
        onClick={handleOnThumbsDown}
      >
        <ThumbsDownIcon boxSize={boxSize} value={value} />
      </Box>
    </Flex>
  )
}

const thumbsUpKeyFrames = keyframes({
  '0%, 100%': { transform: 'scale(100%) translateY(0%)' },
  '12.5%, 87.5%': { transform: 'scale(140%) translateY(0)' },
  '25%, 75%': { transform: 'scale(140%) translateY(-20%)' },
  '50%': { transform: 'scale(140%) translateY(0)' },
})

export const ThumbsUpIcon = (props: ChakraProps & {
  value?: ThumbValue
}) => {
  const { value, ...chakraProps } = props
  const isActive = value === 'up'
  const fillColor = isActive ? 'positive.500' : 'overlay.dark-am'
  const fillOpacity = isActive ? '1' : '0.5'

  const lastvalue = useRef(value)
  const didUndo = lastvalue.current === 'up' && value === undefined
  useEffect(() => {
    lastvalue.current = value
  }, [value])

  return (
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='4 0 20 20'
      fill='none'
      filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
      {...chakraProps}
      className={classNames(
        didUndo && animationClasses.jackInTheBox,
      )}
      sx={{
        ...isActive && {
          animation: `${thumbsUpKeyFrames} 0.6s linear`,
        },
      }}
    >
      <chakra.path
        d='M15.667 7.49935V4.16602C15.667 3.50297 15.4036 2.86709 14.9348 2.39825C14.4659 1.92941 13.83 1.66602 13.167 1.66602L9.83366 9.16602V18.3327H19.2337C19.6356 18.3372 20.0256 18.1964 20.3319 17.936C20.6382 17.6757 20.8401 17.3134 20.9003 16.916L22.0503 9.41602C22.0866 9.17715 22.0705 8.93325 22.0031 8.70122C21.9357 8.4692 21.8187 8.25459 21.6602 8.07228C21.5017 7.88996 21.3054 7.74429 21.085 7.64536C20.8645 7.54644 20.6252 7.49661 20.3837 7.49935H15.667ZM9.83366 18.3327H7.33366C6.89163 18.3327 6.46771 18.1571 6.15515 17.8445C5.84259 17.532 5.66699 17.108 5.66699 16.666V10.8327C5.66699 10.3907 5.84259 9.96673 6.15515 9.65417C6.46771 9.34161 6.89163 9.16602 7.33366 9.16602H9.83366'
        fill={fillColor}
        fillOpacity={fillOpacity}
        transition='all 0.5s ease'
      />
      <chakra.path
        d='M9.83366 18.3327H7.33366C6.89163 18.3327 6.46771 18.1571 6.15515 17.8445C5.84259 17.532 5.66699 17.108 5.66699 16.666V10.8327C5.66699 10.3907 5.84259 9.96673 6.15515 9.65417C6.46771 9.34161 6.89163 9.16602 7.33366 9.16602H9.83366M15.667 7.49935V4.16602C15.667 3.50297 15.4036 2.86709 14.9348 2.39825C14.4659 1.92941 13.83 1.66602 13.167 1.66602L9.83366 9.16602V18.3327H19.2337C19.6356 18.3372 20.0256 18.1964 20.3319 17.936C20.6382 17.6757 20.8401 17.3134 20.9003 16.916L22.0503 9.41602C22.0866 9.17715 22.0705 8.93325 22.0031 8.70122C21.9357 8.4692 21.8187 8.25459 21.6602 8.07228C21.5017 7.88996 21.3054 7.74429 21.085 7.64536C20.8645 7.54644 20.6252 7.49661 20.3837 7.49935H15.667Z'
        stroke='ondark.1'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        transition='all 0.5s ease'
      />
    </chakra.svg>
  )
}

const thumbsDownKeyFrames = keyframes({
  '0%, 100%': { transform: 'translateY(0)' },
  '25%, 75%': { transform: 'translateY(20%)' },
  '50%': { transform: 'translateY(-10%)' },
})

export const ThumbsDownIcon = (props: ChakraProps & {
  value?: ThumbValue
}) => {
  const { value, ...chakraProps } = props
  const isActive = value === 'down'
  const fillColor = isActive ? 'negative.500' : 'overlay.dark-am'
  const fillOpacity = isActive ? '1' : '0.5'

  const lastvalue = useRef(value)
  const didUndo = lastvalue.current === 'down' && value === undefined

  useEffect(() => {
    lastvalue.current = value
  }, [value])

  return (
    <chakra.svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='4 0 20 20'
      fill='none'
      filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
      {...chakraProps}
      className={classNames(
        didUndo && animationClasses.jackInTheBox,
      )}
      sx={{
        ...isActive && {
          animation: `${thumbsDownKeyFrames}  0.5s ease`,
        },
      }}
    >
      <chakra.path
        d='M11.3329 12.4997V15.833C11.3329 16.496 11.5963 17.1319 12.0651 17.6008C12.5339 18.0696 13.1698 18.333 13.8329 18.333L17.1662 10.833V1.66632H7.7662C7.36426 1.66177 6.97422 1.80265 6.66795 2.06298C6.36168 2.32331 6.15981 2.68556 6.09954 3.08298L4.94954 10.583C4.91328 10.8219 4.92939 11.0658 4.99675 11.2978C5.06412 11.5298 5.18112 11.7444 5.33965 11.9267C5.49819 12.109 5.69447 12.2547 5.91489 12.3536C6.13532 12.4526 6.37461 12.5024 6.6162 12.4997H11.3329ZM17.1662 1.66632H19.3912C19.8628 1.65798 20.3211 1.8231 20.679 2.13033C21.037 2.43757 21.2696 2.86553 21.3329 3.33298V9.16632C21.2696 9.63377 21.037 10.0617 20.679 10.369C20.3211 10.6762 19.8628 10.8413 19.3912 10.833H17.1662'
        fill={fillColor}
        fillOpacity={fillOpacity}
        transition='all 0.5s ease'
      />
      <chakra.path
        d='M17.1662 1.66632H19.3912C19.8628 1.65798 20.3211 1.8231 20.679 2.13033C21.037 2.43757 21.2696 2.86553 21.3329 3.33298V9.16632C21.2696 9.63377 21.037 10.0617 20.679 10.369C20.3211 10.6762 19.8628 10.8413 19.3912 10.833H17.1662M11.3329 12.4997V15.833C11.3329 16.496 11.5963 17.1319 12.0651 17.6008C12.5339 18.0696 13.1698 18.333 13.8329 18.333L17.1662 10.833V1.66632H7.7662C7.36426 1.66177 6.97422 1.80265 6.66795 2.06298C6.36168 2.32331 6.15981 2.68556 6.09954 3.08298L4.94954 10.583C4.91328 10.8219 4.92939 11.0657 4.99675 11.2978C5.06412 11.5298 5.18112 11.7444 5.33965 11.9267C5.49819 12.109 5.69447 12.2547 5.91489 12.3536C6.13532 12.4526 6.37461 12.5024 6.6162 12.4997H11.3329Z'
        stroke='#F7F7F7'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        transition='all 0.5s ease'
      />
    </chakra.svg>
  )
}

export type ThumbButtonsToggleProps = {
  value?: ThumbValue
} & ChakraProps

export const ThumbButtonsToggle = ({ value, ...props }: ThumbButtonsToggleProps) => pipe(
  Match.value(value),
  Match.when('up', () => <ThumbsUpIcon value='up' {...props} />),
  Match.when('down', () => <ThumbsDownIcon value='down' {...props} />),
  Match.orElse(() => null),
)
