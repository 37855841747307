import { ModalOverlay } from '@chakra-ui/react'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { DeleteListMembersState, useListStoreActions } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useDeleteListMembersState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useDeleteListMembersState'
import pluralize from 'pluralize'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const DeleteMemberModal = () => {
  const modalActions = useDeleteMemberModalActions()
  const modalState = useDeleteMemberModalState()
  const actionsApi = useListStoreActions()
  const deleteState = useDeleteListMembersState()

  // #region Close Modal on Delete Success
  const previousDeleteState = usePrevious(deleteState)
  useEffect(() => {
    if (
      modalState.status === 'open'
      && DeleteListMembersState.$is('Loading')(previousDeleteState)
      && DeleteListMembersState.$is('Success')(deleteState)
    )
      modalActions.close()
  }, [deleteState])
  // #endregion

  // #region Handle Delete Outcome
  useEffect(() => {
    if (
      !DeleteListMembersState.$is('Loading')(previousDeleteState)
      || modalState.status !== 'open'
    ) return

    const payload = modalState.payload

    if (DeleteListMembersState.$is('Success')(deleteState)) {
      toast.info({
        title: 'Items Removed',
        message: `${
          payload.memberIds.length
        } ${
          pluralize('item', payload.memberIds.length)
        } has been removed from the list.`,
      })
      payload.onSuccess?.()
    } else if (DeleteListMembersState.$is('Failed')(deleteState)) {
      toast.error({
        title: 'Failed to Remove Items from the List',
        message: TOAST_PRESET.GENERIC_ERROR.message,
      })
    }
  }, [deleteState])
  // #endregion

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.negative'
          >
            <CardImage src={hurtDogPng} />
            <ModalCloseButton onClose={modalActions.close} />
            <CardPrimaryTitle>
              Remove
              {' '}
              {modalState.payload.memberIds.length}
              {' '}
              {pluralize('Property', modalState.payload.memberIds.length)}
              {' '}
              from This List
            </CardPrimaryTitle>
            <CardBody>
              <CardPrimaryText>
                These properties will be removed from this list.
                You can still view them from details page.
                Are you sure you want to proceed?
              </CardPrimaryText>
            </CardBody>
            <CardFooter>
              <CardButton
                variant='outline'
                colorScheme='neutral'
                size='md'
                onClick={modalActions.close}
                autoFocus
              >
                Cancel
              </CardButton>
              <CardButton
                variant='solid'
                colorScheme='negative'
                size='md'
                onClick={() => {
                  actionsApi.deleteMembers(
                    modalState.payload.listId,
                    modalState.payload.memberIds,
                  )
                }}
                autoFocus
                isLoading={DeleteListMembersState.$is('Loading')(deleteState)}
                loadingText='Removing'
                type='submit'
              >
                Yes, Remove
              </CardButton>
            </CardFooter>

          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}

type OpenPayload = {
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  onSuccess: () => void
}

type DeleteMemberModalState = {
  state: {
    status: 'open'
    payload: OpenPayload
  }
  | { status: 'close' }
  close: () => void
  open: (payload: OpenPayload) => void
}

const useDeleteMemberModal = create<DeleteMemberModalState>()(immer(set => ({
  state: { status: 'close' },
  close: () => set(state => { state.state = { status: 'close' } }),
  open: payload => set(state => { state.state = { status: 'open', payload } }),
})))

export const useDeleteMemberModalActions = () => useDeleteMemberModal(state => ({
  close: state.close,
  open: state.open,
}))

export const useDeleteMemberModalState = () => useDeleteMemberModal(state => state.state)
