import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { createPropertyDetailsUrlFromComp } from 'features/CMA/infra/react/CMA.helpers'
import { Dollar } from 'libs/dollar'
import { EnterIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { MapStoreProvider } from 'presentation/components/Map'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { TabbedMap } from 'presentation/components/TabbedMap'
import { ThumbButtonsToggle } from 'presentation/components/ThumbButtons'
import { Tooltip } from 'presentation/components/Tooltip'
import { DistanceTag } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/DistanceTag'
import { PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { AgentInfoText } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/AgentInfoText/AgentInfoText'
import { MapMarkers } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard'
import { CompStatCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompStatCard/CompStatCard'
import { ComparativeModalDataSection } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativeModalDataSection/ComparativeModalDataSection'
import { ComparativePropertyCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCard/ComparativePropertyCard.props'
import { CompsMLSInfoCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompsMLSInfoCard'
import { useComparativePropertyCardLogic } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useComparativePropertyCardLogic'
import { getCompCardColors } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/utils/getCompCardColors'
import { toBedBathsLivingSqft } from 'presentation/screens/CompsScreen/formatters/toBedBathsBldngSqft'
import { thumbValueFromRatingNullable } from 'presentation/screens/CompsScreen/utils/CMAThumbRatingConverter'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Link } from 'react-router-dom'
import { isNonNullable } from 'utils/isNonNullable'

export const ComparativePropertyCardMobileSmall = (props: ComparativePropertyCardProps) => {
  const logic = useComparativePropertyCardLogic(props)

  if (logic.status === 'aborting') return null

  const {
    compEntries,
    currentComp,
    handleRatingChange,
    modalBgColor,
    onSelectedIndexChange,
    selectedCompIsSubject,
    isSinglePointComps,
    totalItems,
    safeSelectedIndex,
  } = logic

  const currentPage = safeSelectedIndex + 1

  const price = Dollar.formatNumberCODoDD(currentComp.salePrice || currentComp.listPrice)

  const bedsBathsSizeFormat = toBedBathsLivingSqft(currentComp)

  const cardColors = getCompCardColors(currentComp.status)

  const compMarkers = selectedCompIsSubject
    ? [currentComp]
    : [currentComp, props.subject].filter(isNonNullable)

  const distance = currentComp.type === 'single-comp'
    ? currentComp.distance
    : null

  const onPageChange = (page: number) => {
    onSelectedIndexChange(page - 1)
  }

  const renderPageButton = (page: number | null) => {
    if (page === null) return '...'
    const entry = compEntries[page - 1]
    const thumbValue = thumbValueFromRatingNullable(entry?.comp.userRating)
    return (
      <>
        {thumbValue !== 'undecided' && (
          <ThumbButtonsToggle
            value={thumbValue}
            position='absolute'
            top='-8px'
            right='-5px'
            boxSize={2}
          />
        )}
        {page}
      </>
    )
  }

  return (
    <Flex
      minH={0}
      h='full'
      w='full'
      minW={0}
      flexDirection='column'
      bgColor={modalBgColor}
    >
      <ScrollWithFade
        containerProps={{
          h: 'full',
          minH: 0,
          flex: '1 0 0',
          bgColor: modalBgColor,
        }}
        fadeColor='rgb(0, 0, 0, 0.15)'
        fadeSize={3}
      >
        {/** Start of header region */}
        <Box
          px={2}
          py={1}
          bgColor={selectedCompIsSubject
            ? 'special.500'
            : cardColors.cardHeaderBgColor}
          color={selectedCompIsSubject
            ? 'ondark.1'
            : cardColors.cardHeaderTextColor}
          minW={0}
        >
          <Flex alignItems='center'>
            <Tooltip label={currentComp.address.line1}>
              <Text
                textStyle='bodyLFat'
                color='inherit'
                isTruncated
              >
                {currentComp.address.line1}
              </Text>
            </Tooltip>
            <Box
              as={Link}
              p={0.5}
              to={createPropertyDetailsUrlFromComp(currentComp)}
              target='_blank'
              mr={1}
            >
              <EnterIcon
                boxSize={2}
                color='inherit'
                display='block'
              />
            </Box>
            <Text
              textStyle='bodyXLFat'
              color='inherit'
              ml='auto'
            >
              {price}
            </Text>
          </Flex>

          <Flex
            alignItems='center'
            justifyContent='space-between'
          >
            <Text
              textStyle='bodyM'
              color='inherit'
            >
              {bedsBathsSizeFormat}
            </Text>
            <Text
              textStyle='bodyM'
              color='inherit'
              textAlign='right'
            >
              {isNonNullable(currentComp?.daysOnMarket)
                ? [currentComp.daysOnMarket, 'DOM'].join(' ')
                : '-- DOM'}
            </Text>
          </Flex>
        </Box>
        {/** End of header region */}
        {/** Start of Carousel Region */}
        <Grid
          gridTemplateAreas={'"layer"'}
          overflow='hidden'
        >
          <PhotosSlider
            key={currentComp.id}
            gridArea='layer'
            minW={0}
            zIndex='1'
            photos={currentComp.photos}
            h={165}
          />

          <GridItem
            gridArea='layer'
            alignSelf='start'
            justifySelf='start'
            zIndex='2'
            mt={1.5}
            ml={1}
          >
            {currentComp.type === 'subject-comp'
              ? (
                <SubjectPropertyTag
                  h='fit-content'
                />
              )
              : (
                <MLSStatusTag
                  status={currentComp.status}
                />
              )}
          </GridItem>
        </Grid>
        {/** End of Carousel Region */}

        <Flex
          flexDirection='column'
          gap={3}
          pt={1}
          pb={3}
          px={1}
          bg='neutral.100'
        >
          <AgentInfoText comp={currentComp} color='graystrong.200' />
          <CompsMLSInfoCard
            entry={currentComp}
            bg='transparent'
            p={0}
            px={0}
            borderWidth={0}
          />

          <Grid
            gridTemplateAreas={'"layer"'}
          >
            <Box
              pos='relative'
              h='280px'
              gridArea='layer'
              zIndex={1}
            >
              <MapStoreProvider>
                {/** @TODO Investigate why zoom got affected. */}
                <TabbedMap>
                  <MapMarkers comps={compMarkers} />
                </TabbedMap>
              </MapStoreProvider>
            </Box>
            {distance && (
              <DistanceTag
                gridArea='layer'
                justifySelf='start'
                alignSelf='start'
                mt={6}
                ml={2}
                value={distance}
                zIndex={2}
              />
            )}
          </Grid>

          <ComparativeModalDataSection
            subjectComp={props.subject}
            currentComp={currentComp}
          />

          <CompStatCard
            subject={props.subject}
            comp={currentComp.type === 'single-comp' ? currentComp : undefined}
            mt={currentComp.type === 'single-comp' ? 2.5 : 0}
          />

          <CompsMLSDisclaimer
            color={mbp({
              mobSm: 'graystrong.400',
              mob: isSinglePointComps ? 'modal.text' : 'ondark.3',
            })}
            pb={5}
          />
        </Flex>
      </ScrollWithFade>

      {/** Start of pagination and rating region */}
      <Box
        p={1.5}
        position='relative'
      >
        <Grid
          position='absolute'
          top='-60px'
          right={1}
          gridArea='"rating"'
        >
          <CompsThumbButtons
            gridArea='rating'
            comp={currentComp}
            onCompsRatingChange={handleRatingChange}
            size='lg'
            bgColor='ondark.2'
            py={0.25}
            px={1}
            borderRadius='100px'
            borderWidth={0.125}
            borderColor='grayweak.500'
            gap={2}
            shadow='button-hovered'
          />
          <Box
            gridArea='rating'
            justifySelf='center'
            alignSelf='center'
            w={0.125}
            h={3}
            bgColor='grayweak.500'
          />
        </Grid>
        <Pagination
          currentPage={currentPage}
          totalPages={totalItems}
          maxPageButtons={5}
          onPageChange={onPageChange}
          renderPageButton={renderPageButton}
          size='lg'
          colorScheme={isSinglePointComps ? 'default' : 'highlight'}
        />

        {isSinglePointComps && (
          <Text
            mt={1}
            textStyle='bodyM'
            color='onlight.2'
          >
            This is for multiple listings at a single address.
          </Text>
        )}
      </Box>
      {/** End of pagination and rating region */}
    </Flex>
  )
}
