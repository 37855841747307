import { Box, BoxProps, Flex, Text, VStack } from '@chakra-ui/react'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { Tooltip } from 'presentation/components/Tooltip'
import { CardSegment } from 'presentation/components/molecules/Card/CardCombo'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'

export type BillingSummaryItem = { label: string | null, price: number, quantity?: number }

export type BillingSummaryProps = {
  items: BillingSummaryItem[]
  totalAmount?: number
  bottomSlot?: React.ReactNode
} & BoxProps

export const BillingSummarySegment: FC<BillingSummaryProps> = ({
  items,
  totalAmount,
  bottomSlot,
  ...boxProps
}) => {
  const calculatedTotal = items.reduce((acc, item) => acc + item.price, 0)
  const total = totalAmount !== undefined
    ? totalAmount
    : items.reduce((acc, item) => acc + item.price, 0)

  const { positive, negative } = items.reduce<{
    positive: BillingSummaryItem[]
    negative: BillingSummaryItem[]
  }>((acc, item) => {
    if (item.price > 0)
      acc.positive.push(item)
    else
      acc.negative.push(item)

    return acc
  }, { positive: [], negative: [] })

  return (
    <CardSegment
      px={mbp({ mobSm: '2', tabSm: '4' })}
      pt={mbp({ mobSm: '2', tabSm: '4.5' })}
      pb={mbp({ mobSm: '2.5', tabSm: '4.5' })}
      borderBottomRadius={mbp({ mobSm: '2', tabSm: '4' })}
      {...boxProps}
    >
      <VStack align='stretch' spacing='2'>
        {positive.map((item, index) => (
          <PositiveItemRow
            key={index}
            label={item.label || ''}
            value={item.price}
            quantity={item.quantity}
          />
        ))}

        {negative.length > 0 && (
          <VStack align='stretch' spacing='0.5'>
            {negative.map((item, index) => (
              <NegativeItemRow
                key={index}
                tooltip={item.label?.startsWith('Unused time on')
                  ? `Your account has a credit from ${item.label.replace('Unused time on', '')}`
                  : 'Your remaining credit is deducted from your current purchase.'}
                label={item.label || ''}
                value={item.price}
              />
            ))}
          </VStack>
        )}

        <VStack align='stretch' spacing='0.5'>
          <TotalRow total={total} />

          {calculatedTotal < 0 && (
            <NegativeItemRow
              tooltip='Your remaining credit is deductible for your future purchases.'
              label='Remaining Credit'
              value={calculatedTotal}
            />
          )}
        </VStack>
      </VStack>

      {bottomSlot}
    </CardSegment>
  )
}

type ItemRowProps = { label: string, value: number, quantity?: number }

const PositiveItemRow: FC<ItemRowProps> = ({ label, value, quantity }) => (
  <Flex gap={1} align='flex-start'>
    <Box flex='1'>
      <Text textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat' })} color='graystrong.500'>
        {label}
      </Text>
      <Text textStyle='bodyM' color='graystrong.200'>
        Qty:
        {' '}
        {quantity}
      </Text>
    </Box>
    <Text textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat' })} color='graystrong.500'>
      {formatPenniesOptionalDecUsd(value)}
    </Text>
  </Flex>
)

const NegativeItemRow: FC<ItemRowProps & { tooltip: string }> = ({ label, value, tooltip }) => (
  <Flex gap={1} alignItems='center'>
    <Tooltip label={tooltip}>
      <RegularCircleExclamationIcon
        fontSize={2}
        color='neutral.500'
      />
    </Tooltip>
    <Text flex='1' textStyle='bodyM' color='graystrong.200'>
      {label}
    </Text>
    <Text textStyle='bodyMFat' color='graystrong.200'>
      {formatPenniesOptionalDecUsd(value)}
    </Text>
  </Flex>
)

const TotalRow: FC<{ total: number }> = ({ total = 0 }) => (
  <Flex gap={1}>
    <Text flex='1' textStyle={mbp({ mobSm: 'body2-h-b', tabSm: 'body-h-b' })} color='neutral.500'>
      Amount Due today
    </Text>
    <Text textStyle={mbp({ mobSm: 'body2-h-b', tabSm: 'body-h-b' })} color='neutral.500'>
      {formatPenniesOptionalDecUsd(total)}
    </Text>
  </Flex>
)
