import { useToken } from '@chakra-ui/react'
import { PlanRank } from 'features/Billing/Plans/getPlanRank'
import ColorLib from 'presentation/libs/color'

type RankTheme = {
  foldColor: string
  mainColor: string
  textColor: string
}

const BASE_RANK_THEME_RECORD = {
  free: {
    mainColor: 'graystrong.200',
    textColor: 'ondark.1',
  },
  beginner: {
    mainColor: 'accent.gold.200',
    textColor: 'ondark.1',
  },
  intermediate: {
    mainColor: 'neutral.500',
    textColor: 'ondark.1',
  },
  advanced: {
    mainColor: 'positivesat.500',
    textColor: 'ondark.1',
  },
  fallback: {
    mainColor: 'neutral.500',
    textColor: 'ondark.1',
  },
}

export const useRankTheme = (rank: PlanRank | null): RankTheme => {
  const baseTheme = rank
    ? BASE_RANK_THEME_RECORD[rank]
    : BASE_RANK_THEME_RECORD.fallback

  const mainColorHex = useToken('colors', baseTheme.mainColor)

  return {
    ...baseTheme,
    foldColor: ColorLib.darken(0.5)(mainColorHex),
  }
}
