import { PropertyListData } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import PropertiesListItem from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/components/PropertyListItem'
import { LIST_PREVIEW_SUBSCREEN_KEYS } from 'presentation/screens/ListPreviewScreen/ListPreviewScreen.const'
import { PointLike, Popup } from 'react-map-gl'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListPreviewMobileItem } from './ListPreviewMobileItem'

export type ListPreviewMobileMapMarkerPopupProps = {
  item: PropertyListData
}
const ListPreviewMapMarkerPopup = (props: ListPreviewMobileMapMarkerPopupProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const location = useLocation()
  const navigate = useNavigate()
  const popupWidth = sbp({ mobSm: '300px', tabSm: '556px' })
  const handleClick = () => {
    navigate(location.pathname, {
      state: {
        subscreen: LIST_PREVIEW_SUBSCREEN_KEYS.LIST,
      },
    })
  }
  return (
    <Popup
      closeOnClick={false}
      /**
     * @NOTE: focusAfterOpen=false will prevent any scrollable
     * ancestor to scroll to make popup visible in the viewport.
     * This prevents the expansion of table rows to scroll to top
     * to show the map when view is table view.
     */
      focusAfterOpen={false}
      longitude={props.item.location?.longitude ?? 0} // @TODO: should not default to 0
      latitude={props.item.location?.latitude ?? 0}
      style={{ zIndex: MAP_ELEMENT__Z_INDICES.POPUP }}
      offset={{
        'bottom': [0, -20],
        'top': [0, 20],
        'right': [-10, -0],
        'left': [10, 0],
        'bottom-right': [0, -15],
        'bottom-left': [0, -15],
        'top-left': [0, 20],
        'top-right': [0, 20],
      } as Record<string, PointLike>}
      maxWidth={popupWidth}
    >
      <Breakpoint
        mobSm={(
          <ListPreviewMobileItem
            item={props.item}
            borderRadius={0}
            sx={{
              '#property-preview-card': {
                h: '126px',
              },
            }}
            onClick={handleClick}
          />
        )}
        tabSm={(
          <PropertiesListItem item={props.item} borderRadius={0} />
        )}
      />
    </Popup>
  )
}

export default ListPreviewMapMarkerPopup
