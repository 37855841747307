import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'
import { SelectMenu, SelectMenuButton, SelectMenuList } from 'presentation/components/SelectMenu'
import { PoolFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/PoolFilterCard/PoolFilterCard.dumb.props'
import { FilterCard } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/common/FilterCard'
import { CompsFilterCardError } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError'

type Availablility = CMA.Filters.PoolsCount['availablility']

const poolToPretty = (pool: Availablility) =>
  pool === 'yes' ? 'Yes' : 'Any'

export const PoolFilterCardDumb = (
  props: PoolFilterCardDumbProps,
) => {
  const selectedAvailability = props.value.availablility
  const selectedLabel = poolToPretty(selectedAvailability)

  const handleAvailabilityChange = (availablility: Availablility) => {
    props.onChange({
      ...props.value,
      availablility,
    })
  }

  return (
    <FilterCard
      title='Pools'
      hasBadge={selectedAvailability !== 'any'}
    >
      <SelectMenu>
        <SelectMenuButton
          w='full'
        >
          {selectedLabel}
        </SelectMenuButton>
        <SelectMenuList>
          <MenuOptionGroup
            value={selectedAvailability}
            onChange={valueOrArr => {
              const availability = Array.isArray(valueOrArr) ? valueOrArr[0] : valueOrArr
              handleAvailabilityChange(availability as Availablility)
            }}
          >
            {(['any', 'yes'] as const).map(availability => (
              <MenuItemOption
                key={availability}
                type='radio'
                value={availability.toString()}
              >
                {poolToPretty(availability)}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </SelectMenuList>
      </SelectMenu>
      {props.filterErrorMsg && (
        <CompsFilterCardError error={props.filterErrorMsg} />
      )}
    </FilterCard>
  )

  //
  // return (
  //   <FilterCard
  //     title='Pool'
  //     toolbar={(
  //       <MatchSubjectCheckbox
  //         isChecked={props.value.shouldMatchSubjectProperty}
  //         onChange={handleOnMatchedToSubjectPropertyChange}
  //       />
  //     )}
  //   >
  //     <ExcludeAllCheckbox
  //       label='pools'
  //       isChecked={props.value.shouldExcludeAll}
  //       onChange={handleOnExcludeAllChange}
  //       isDisabled={props.value.shouldMatchSubjectProperty}
  //       isSameAsSubject={props.value.shouldExcludeAll === props.subjectValue.shouldExcludeAll}
  //     />
  //     <FormControl>
  //       <FormLabel>Type</FormLabel>
  //       <SelectMenu isOpen={props.isPoolMenuOpen}>
  //         <SelectMenuButton
  //           w='full'
  //           isDisabled={props.value.shouldMatchSubjectProperty}
  //           onClick={handleOnOpenChange}
  //         >
  //           <ValueWithSubjectPropertyMarker
  //             shouldShowMarker={poolType === subjectPoolType}
  //           >
  //             {selectedValue}
  //           </ValueWithSubjectPropertyMarker>
  //         </SelectMenuButton>
  //         <SelectMenuList>
  //           {PoolType.toArray().map((value, index) => (
  //             <SelectMenuItem
  //               key={index}
  //               onClick={() => handleOnTypeChange(value)}
  //             >
  //               <ValueWithSubjectPropertyMarker
  //                 shouldShowMarker={value === subjectPoolType}
  //               >
  //                 {PoolType.toString(value)}
  //               </ValueWithSubjectPropertyMarker>
  //             </SelectMenuItem>
  //           ))}
  //         </SelectMenuList>
  //       </SelectMenu>
  //     </FormControl>
  //     {props.filterErrorMsg && (
  //       <CompsFilterCardError error={props.filterErrorMsg} />
  //     )}
  //   </FilterCard>
  // )
}
