import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const IncognitoIcon: FC<BoxProps & {
  strokeWidth?: string | number | undefined
}> = ({ color, fill, stroke, boxSize, strokeWidth = 2, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.path
        d='M24.0003 25.8357C24.0003 27.3071 22.8064 28.5 21.3337 28.5C19.8609 28.5 18.667 27.3071 18.667 25.8357C18.667 24.3642 19.8609 24.5035 21.3337 24.5035C22.8064 24.5035 24.0003 24.3642 24.0003 25.8357Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <chakra.path
        d='M14.6663 25.8357C14.6663 27.3071 13.4724 28.5 11.9997 28.5C10.5269 28.5 9.33301 27.3071 9.33301 25.8357C9.33301 24.3642 10.5269 24.5035 11.9997 24.5035C13.4724 24.5035 14.6663 24.3642 14.6663 25.8357Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      <chakra.path
        d='M27.2265 25.3288C27.6842 25.0199 27.8048 24.3983 27.4958 23.9405C27.1869 23.4828 26.5653 23.3622 26.1075 23.6712L27.2265 25.3288ZM7.26699 23.7C6.82516 23.3686 6.19836 23.4582 5.86699 23.9C5.53562 24.3418 5.62516 24.9686 6.06699 25.3L7.26699 23.7ZM14.4201 26.8333H17.9756V24.8333H14.4201V26.8333ZM24.0003 26.5C24.6156 27.2883 24.6156 27.2883 24.6156 27.2883C24.6156 27.2883 24.6156 27.2883 24.6157 27.2883C24.6157 27.2882 24.6158 27.2882 24.6159 27.2881C24.6161 27.2879 24.6164 27.2877 24.6168 27.2874C24.6177 27.2867 24.619 27.2857 24.6207 27.2843C24.6242 27.2816 24.6295 27.2775 24.6364 27.2721C24.6502 27.2613 24.6707 27.2454 24.6971 27.2249C24.7499 27.1838 24.8263 27.1245 24.9205 27.0516C25.109 26.9058 25.3681 26.7061 25.6508 26.4904C26.2254 26.0522 26.8689 25.5702 27.2265 25.3288L26.1075 23.6712C25.6937 23.9505 25.0039 24.4685 24.4379 24.9001C24.1505 25.1194 23.8875 25.3221 23.6965 25.4699C23.601 25.5438 23.5233 25.6041 23.4694 25.646C23.4425 25.6669 23.4215 25.6833 23.4072 25.6944C23.4 25.7 23.3946 25.7043 23.3908 25.7072C23.389 25.7086 23.3876 25.7097 23.3866 25.7105C23.3861 25.7109 23.3857 25.7112 23.3855 25.7114C23.3853 25.7115 23.3852 25.7115 23.3852 25.7116C23.3851 25.7116 23.3851 25.7117 23.3851 25.7117C23.3851 25.7117 23.385 25.7117 24.0003 26.5ZM9.93366 25.7L7.26699 23.7L6.06699 25.3L8.73366 27.3L9.93366 25.7Z'
        fill={stroke}
      />
      <chakra.path
        d='M10.4194 6.8908C10.4194 6.09387 10.8264 4.5 12.454 4.5C14.4886 4.5 13.9799 5.6954 16.5232 5.6954C19.0664 5.6954 18.5577 4.5 20.5923 4.5C22.0909 4.5 22.8421 5.8511 23.0842 6.68737C23.1231 6.82174 23.1355 6.96133 23.1355 7.10121L23.1355 11.6724C24.4919 11.8716 27.9997 12.6894 27.9997 14.6609C27.9997 17.8299 21.3096 20.1449 17.2668 21.5698C16.7883 21.7385 16.2661 21.7213 15.7974 21.5271C12.2461 20.0551 5.33306 17.4444 5.33301 14.6609C5.33297 12.2701 8.21532 11.8716 10.4194 11.6724V6.8908Z'
        fill={fillFromProps}
      />
      <chakra.path
        d='M10.4194 11.6724V13.5163C10.4194 14.2254 10.7936 14.8833 11.4278 15.2006C13.064 16.0191 15.5406 17.0517 16.5232 17.0517C17.5114 17.0517 20.1218 16.0072 21.9799 15.1865C22.6889 14.8734 23.1355 14.1661 23.1355 13.391L23.1355 11.6724M10.4194 11.6724V6.8908C10.4194 6.09387 10.8264 4.5 12.454 4.5C14.4886 4.5 13.9799 5.6954 16.5232 5.6954C19.0664 5.6954 18.5577 4.5 20.5923 4.5C22.0909 4.5 22.8421 5.8511 23.0842 6.68737C23.1231 6.82174 23.1355 6.96133 23.1355 7.10121L23.1355 11.6724M10.4194 11.6724C8.21532 11.8716 5.33297 12.2701 5.33301 14.6609C5.33306 17.4444 12.2461 20.0551 15.7974 21.5271C16.2661 21.7213 16.7883 21.7385 17.2668 21.5698C21.3096 20.1449 27.9997 17.8299 27.9997 14.6609C27.9997 12.6894 24.4919 11.8716 23.1355 11.6724'
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </chakra.svg>

  )
}
