import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

const { defineMultiStyleConfig, definePartsStyle }
  = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  table: {
    // from chakra theme
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
  },
  th: {
    textTransform: 'none',
  },
  td: {
    // from chakra theme
    textAlign: 'start',
  },
  caption: {
    // from chakra theme
    textAlign: 'center',
  },
})

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
})

const variantStriped = definePartsStyle(props => {
  if (props.colorScheme === 'default') {
    return {
      th: {
      // from chakra theme
        ...numericStyles,

        bg: 'card.bg.blue',
        color: 'ondark.1',
      },
      td: {
      // from chakra theme
        ...numericStyles,
        'color': 'graystrong.600',

        'border': 0,

        /** @HACK `&&&` is a specificity hack to override Chakra's default styles */
        '&&&': {
          borderBottomWidth: 0,
        },

        'tr:nth-of-type(odd) &': {
          bg: 'grayweak.100',
        },

        'tr:nth-of-type(even) &': {
          bg: 'grayweak.200',
        },
      },
      caption: {
      },
    }
  }

  return {}
})

const variants = {
  striped: variantStriped,
  unstyled: defineStyle({}),
}

const sizes = {
  md: definePartsStyle({
    th: {
      'px': 1,
      'py': 1,
      'letterSpacing': '0',
      ...textStyles.bodyMFat,

      '&:first-of-type': {
        borderTopLeftRadius: 2,
        pl: 3,
      },

      '&:last-of-type': {
        borderTopRightRadius: 2,
        pr: 3,
      },
    },
    tbody: {
      td: {
        'px': 1,
        'py': 1,
        ...textStyles.bodyM,

        '&:first-of-type': {
          pl: 3,
        },

        '&:last-of-type': {
          pr: 3,
        },
      },
      tr: {
        '&:last-of-type': {
          td: {
            '&:first-of-type': {
              borderBottomLeftRadius: 2,
            },
            '&:last-of-type': {
              borderBottomRightRadius: 2,
            },
          },
        },
      },
    },
  }),
}

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'striped',
    size: 'md',
    colorScheme: 'default',
  },
})
