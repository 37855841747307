// eslint-disable-next-line no-restricted-imports
import { BoxProps, chakra, Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps, useStyleConfig } from '@chakra-ui/react'
import cn from 'classnames'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { Children, cloneElement, FC, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'
import { isNonNullable } from 'utils/isNonNullable'

export type TooltipProps = {
  /**
   * Auto close the tooltip after it was opened
   */
  autoCloseAfter?: number
  isEnabledOnTouchDevice?: boolean
} & ChakraTooltipProps

export const Tooltip: FC<TooltipProps> = ({
  hasArrow,
  label,
  children,
  shouldWrapChildren,
  isOpen: isOpenFromProps,
  autoCloseAfter,
  isEnabledOnTouchDevice,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isOpenFromProps)
  const [element, setElement] = useState<HTMLElement | null>(null)
  const DEBOUNCE_DELAY = 100
  const debouncedSetIsOpen = useMemo(() => debounce(DEBOUNCE_DELAY, setIsOpen), [])
  const visibilityCheckIntervalRef = useRef<number | null>(null)

  const shouldWrap = typeof children === 'string' || shouldWrapChildren

  /**
   * TLDR: We need to clone the children to inject event handlers.
   *
   * ---
   *
   * Here's the deal, we want the tooltip to open on mobile by touch.
   *
   * To do that, we need to manually add onClick event to the element.
   *
   * (Chakra intentionally didn't do this by default, see the link below)
   * https://github.com/chakra-ui/chakra-ui/issues/2691#issuecomment-799353335
   *
   * We have two options, (#1) add wrapper with event handlers to the children
   * or (#2) add event handlers directly to the element.
   *
   * Historically we did #1 first, but it lead to a lot of styling issues
   * because the wrapper will have to make assumptions in its styling, which
   * may conflict with how it's used (e.g. display inline vs block, lineheight,
   * etc).
   *
   * Now to simplify the consumers, we opted to do #2, and that involves cloning
   * the children so we can inject the event handlers.
   *
   * ---
   *
   * Side note, this solution is based on the the source code of Chakra's
   * Tooltip itself. They also clone the children to inject event handlers and
   * only wrap the children if it's a string.
   */

  let trigger: React.ReactElement

  if (shouldWrap) {
    trigger = (
      <chakra.span
        display='inline-block'
        tabIndex={0}
        ref={setElement}
      >
        {children}
      </chakra.span>
    )
  } else {
    /**
     * Ensure tooltip has only one child node
     */
    const child = Children.only(children) as React.ReactElement & {
      ref?: React.Ref<any>
    }
    trigger = cloneElement(child, {
      ...child.props,
      ref: setElement,
    })
  }

  useEffect(() => {
    if (!isNonNullable(autoCloseAfter) || !isOpen) return

    const timer = setTimeout(() => {
      setIsOpen(false)
    }, autoCloseAfter)

    return () => clearTimeout(timer)
  }, [autoCloseAfter, isOpen])

  useEffect(() => {
    /**
     * Disable on touch device at it interferes with parent cards that are supposed to be tappable
     *
     * @TODO Make it activate via long press on touch device instead
     */
    const shouldDisable = !isEnabledOnTouchDevice && IS_TOUCH_DEVICE

    const onMouseEnter = () => {
      if (shouldDisable) return
      if (IS_TOUCH_DEVICE) return // prevents double call of setIsOpen
      setIsOpen(true)
    }

    const onMouseLeave = () => {
      setIsOpen(false)
    }
    const onClick = () => {
      if (shouldDisable) return
      /**
       * Works around the issue of onClick getting called twice
       * when adding tooltip to elements like Switch
       */
      debouncedSetIsOpen(v => !v)
    }

    element?.addEventListener('mouseenter', onMouseEnter)
    element?.addEventListener('mouseleave', onMouseLeave)
    element?.addEventListener('click', onClick)

    return () => {
      element?.removeEventListener('mouseenter', onMouseEnter)
      element?.removeEventListener('mouseleave', onMouseLeave)
      element?.removeEventListener('click', onClick)
    }
  }, [element])

  // Check if the element is visible and close tooltip if not
  useEffect(() => {
    if (!element || !isOpen) {
      if (visibilityCheckIntervalRef.current) {
        window.clearInterval(visibilityCheckIntervalRef.current)
        visibilityCheckIntervalRef.current = null
      }
      return
    }

    // Function to check if element is visible (not covered by another element)
    const checkElementVisibility = () => {
      if (!element) return

      // Get element's position and dimensions
      const rect = element.getBoundingClientRect()

      // If element is out of viewport, consider it invisible
      if (
        rect.bottom < 0
        || rect.top > window.innerHeight
        || rect.right < 0
        || rect.left > window.innerWidth
      ) {
        setIsOpen(false)
        return
      }

      // Check if element is covered by another element (like a modal)
      const centerX = rect.left + rect.width / 2
      const centerY = rect.top + rect.height / 2

      // Get the element at the center point of our tooltip trigger
      const elementAtPoint = document.elementFromPoint(centerX, centerY)

      // If our element is not the top element at its position or a child of it, close the tooltip
      if (elementAtPoint && !element.contains(elementAtPoint) && !elementAtPoint.contains(element))
        setIsOpen(false)
    }

    // Check immediately and set up interval
    checkElementVisibility()
    visibilityCheckIntervalRef.current = window.setInterval(checkElementVisibility, 300)

    return () => {
      if (visibilityCheckIntervalRef.current) {
        window.clearInterval(visibilityCheckIntervalRef.current)
        visibilityCheckIntervalRef.current = null
      }
    }
  }, [element, isOpen])

  return (
    <ChakraTooltip
      isOpen={isOpen}
      label={(
        <>
          {hasArrow && <TooltipArrow />}
          {label}
        </>
      )}
      motionProps={{
        /**
         * @HACK Fixes the issue where the extra scrollbar briefly appears
         * because of delay of tooltip's unmount.
         *
         * Getting rid of issue has a lot more value vs the exit animation.
         */
        exit: {},
      }}
      {...props}
    >
      {trigger}
    </ChakraTooltip>
  )
}

const TooltipArrow: FC<BoxProps> = props => {
  const styles = useStyleConfig('Tooltip', props)

  return (
    <chakra.div
      id='test'
      pointerEvents='none'
      data-popper-arrow
      className={cn('chakra-tooltip__arrow-wrapper', props.className)}
      sx={{
        '--popper-arrow-size': '20px',

        'zIndex': '0 !important',
      }}
    >
      <chakra.div
        id='test'
        data-popper-arrow-inner
        display='flex'
        justifyContent='center'
        className={cn('chakra-tooltip__arrow', props.className)}
        sx={{
          'w': '20px',

          'pt': '1px',
          'pb': '0',

          '*[data-popper-placement^="top"] &': {
            pt: '0',
            pb: '11px',
          },
        }}
      >
        <svg width='20' height='8' viewBox='0 0 20 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <chakra.path d='M8.75061 0.471329C9.48105 -0.157109 10.519 -0.15711 11.2494 0.471329L20 8H0L8.75061 0.471329Z' fill={styles.bg as string} />
          <chakra.path fillRule='evenodd' clipRule='evenodd' d='M17.1492 6.92455L10.6247 1.31111C10.2595 0.996895 9.74052 0.996894 9.3753 1.31111L2.85078 6.92455H17.1492ZM11.2494 0.471329C10.519 -0.15711 9.48105 -0.157109 8.75061 0.471329L0 8H20L11.2494 0.471329Z' fill={styles.borderColor as string} />
          <chakra.path d='M2.85937 6.92455H17.1523L18.5 8H1.62717L2.85937 6.92455Z' fill={styles.bg as string} />
        </svg>
      </chakra.div>
    </chakra.div>
  )
}
