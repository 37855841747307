import { BoxProps, CardProps, Center, ChakraProps, Flex, Grid, GridItem, Image, Spinner } from '@chakra-ui/react'
import { Array, Match, Option, pipe } from 'effect'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import CurrentFeaturesSectionViewModel from 'presentation/screens/Billing/CurrentFeaturesSection/CurrentFeaturesSectionViewModel'
import { fadeIn } from 'presentation/utils/animationClasses'
import { Fragment } from 'react'
import image215 from './images/plan-details-right.215x215.png'
import image430 from './images/plan-details-right.430x430.png'
import image from './images/plan-details-right.png'
import CompsDetailDisclosure from 'presentation/screens/Plans/components/CompsDetailDisclosure'
import SkiptraceDetailDisclosure from 'presentation/screens/Plans/components/SkiptraceDetailDisclosure'

export type CurrentFeaturesSectionProps = ChakraProps

const CurrentFeaturesSection = (props: CurrentFeaturesSectionProps) => {
  const vm = CurrentFeaturesSectionViewModel.usePresenter()
  return (
    <SectionContainer {...props}>
      {vm.pipe(
        Option.map(v => (
          <Fragment key='current-features-section'>
            <DogImage />

            <Flex
              h='full'
              flexDir='column'
              className={fadeIn}
              alignContent='center'
              justifyContent='center'
            >
              <CardPrimaryTitle
                mb={{
                  base: '3',
                  tabSm: '3',
                  dtLg: '4',
                }}
              >
                Plan Details
              </CardPrimaryTitle>

              <Grid
                mt={{
                  base: '3',
                  tabSm: '3',
                }}
                gridTemplateColumns={{
                  base: 'minmax(min-content, 112px) 1fr',
                  tabSm: 'minmax(min-content, 128px) 1fr',
                  dtLg: 'minmax(min-content, 136px) 1fr',
                }}
                gridTemplateRows='repeat(3, 1fr)'
                columnGap={{
                  base: '2',
                  tabSm: '3',
                }}
                rowGap={{
                  base: '1',
                  mob: '2',
                  dtSm: '1',
                }}
                pb={{ base: '0', tabSm: '1' }}
              >
                {Array.map(v, feature => (
                  <Grid
                    key={feature.featureNameText}
                    gridTemplateColumns='subgrid'
                    gridColumn='1/-1'
                    display={{
                      base: 'flex',
                      mob: 'grid',
                      tabSm: 'flex',
                      dtSm: 'grid',
                    }}
                    gap={{
                      base: 3,
                      mob: 3,
                      tabSm: 1,
                    }}
                  >
                    <GridItem
                      textStyle={{
                        base: 'body2',
                        mob: 'body',
                        dtLg: 'old.h4',
                      }}
                      color='graystrong.200'
                      whiteSpace='nowrap'
                      display='flex'
                      h='full'
                      alignItems='center'
                    >
                      {feature.featureNameText}
                    </GridItem>
                    <GridItem
                      textStyle={{
                        mobSm: 'body2-b',
                        mob: 'body-b',
                      }}
                      color={feature.featureValueTextColor}
                      display='flex'
                      h='full'
                      alignItems='center'
                      gap='0.5'
                    >
                      {feature.featureValueText}
                      {feature.disclosure.pipe(
                        Option.flatMap(value => pipe(
                          Match.value(feature.featureNameText),
                          Match.when('Comps', () => Option.some(
                            <CompsDetailDisclosure
                              propertyCount={value}
                            />,
                          )),
                          Match.when('Skiptrace', () => Option.some(
                            <SkiptraceDetailDisclosure
                              propertyCount={value}
                            />,
                          )),
                          Match.orElse(() => Option.none()),
                        )),
                        Option.getOrNull,
                      )}
                    </GridItem>
                  </Grid>
                ))}
              </Grid>
            </Flex>
          </Fragment>
        )),
        // @NOTE: should it be a view model loading state instead of option?
        Option.getOrElse(() => (
          <Center
            h='full'
            flex='1'
          >
            <Spinner color='graystrong.500' />
          </Center>
        )),
      )}
    </SectionContainer>
  )
}

export default CurrentFeaturesSection

const SectionContainer = ({ children, ...props }: CardProps) => (
  <Card
    variant='default'
    colorScheme='card.bg.1'
    size={{
      base: 'xs-locked',
      mob: 'sm-locked',
      dtLg: 'md-locked',
    }}
    pos='relative'
    minH='253px'
    w='full'
    {...props}
  >
    {children}
  </Card>
)

const DogImage = () => {
  const { mfv } = useMobFirstBreakpointValue()
  return (
    <Image
      src={image215}
      srcSet={`${image215} 1x, ${image430} 2x, ${image} 3x`}
      pos='absolute'
      {...mfv<BoxProps>({
        dtLg: {
          w: '200px',
          top: '-6',
          right: '0',
        },
        tabSm: {
          w: '42%',
          top: '-4.5',
          right: '-3',
        },
        mob: {
          w: '35%',
          maxW: '200px',
          top: '-3',
          right: '0',
        },
        mobSm: {
          w: '40%',
          top: '-4',
          right: '0',
        },
      })}
    />
  )
}
