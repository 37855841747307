import { Brand } from 'effect'
import { nanoid } from 'nanoid'

export type PropertyListDataId = string & Brand.Brand<'PropertyListDataId'>

export const PropertyListDataId = {
  from: Brand.nominal<PropertyListDataId>(),
}

export type FallbackPropertyListDataId = string & Brand.Brand<'PropertyListDataId'>

export const FallbackPropertyListDataId = {
  make: () => Brand.nominal<PropertyListDataId>()(nanoid()),
}
