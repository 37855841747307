import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { CompsBoundaryLine } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.types'
import { useMemo } from 'react'

export const useCompsBoundaryLinesFromFilters = () => {
  const linesFromFiltersRaw = useCMAStore(state => {
    const reportState = state.local.report
    if (!checkStateHasReportData(reportState))
      return null

    const lines = reportState.data?.filters?.boundary?.lines || []

    return lines
  })

  const linesFromFilters = useMemo<CompsBoundaryLine[]>(() => {
    if (!linesFromFiltersRaw)
      return []

    return linesFromFiltersRaw.map(line => ({
      isClosed: true,
      coordinates: line.map(point => [point.longitude, point.latitude]),
    }))
  }, [linesFromFiltersRaw])

  return linesFromFilters
}
