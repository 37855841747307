import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { WalletAddBalanceFlow_AddBalanceMutation } from '__generated__/graphql'
import { GraphQLFormattedError } from 'graphql'
import { toPennies } from 'libs/Number/formatNumber'
import { isNumber } from 'lodash/fp'
import { toast } from 'presentation/components/Toast'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import loadGoogleTagManager from 'presentation/libs/loadGoogleTagManager'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { LegacyBillingEvents } from 'presentation/screens/Billing/Billing.events'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'
import { WALLET_QUERIES } from 'presentation/screens/Billing/queries'
import { useCallback } from 'react'
import { formatPenniesOptionalDec, formatPenniesOptionalDecUsd } from 'utils/dataAdapter'

export const ADD_BALANCE_FLOW__ADD_BALANCE = gql(/* GraphQL */ `
  mutation WalletAddBalanceFlow_AddBalance($amount: Int!) {
    walletAddBalance(amount: $amount) {
      ... on WalletTransaction {
        amount
        preSettlementBalance
        charge {
          paymentMethod {
            ... on BillingCardsPaymentMethod {
              last4
            }
          }
          capturedAmount
        }
        createdBy {
          id
        }
      }
      ... on WalletInvalidAmountError {
        minimumAmount
        message
      }
      ... on WalletPaymentMethodDeclinedError {
        message
      }
    }
  }
`)

export type UseAddBalanceMutationResult = {
  addBalance: (amount: number) => void
  loading: boolean
}

export const useAddBalanceMutation = (): UseAddBalanceMutationResult => {
  const [addBalanceFetchApi, { loading }] = useMutation(ADD_BALANCE_FLOW__ADD_BALANCE, {
    refetchQueries: WALLET_QUERIES,
  })

  const addBalance = useCallback(async (amount: number) => {
    const penny = toPennies(amount)
    // We need to await refetch queries to prevent race conditions where the balance hasn't
    // been updated in the GraphQL cache when other components (like CompsInitializeModal)
    // try to read it. Without this, the addFundsDone event might be emitted before the
    // new balance is available in the cache, causing components to see stale data.
    await addBalanceFetchApi({ variables: { amount: penny },
      refetchQueries: WALLET_QUERIES,
      awaitRefetchQueries: true, // Ensure refetch completes before continuing
    })
      .then(({ errors, data }) => {
        const errMsg = data ? getErrors(data, errors) : null

        if (errMsg) throw new Error(errMsg)

        if (!data) {
          toast({
            status: 'error',
            title: 'Unexpected error occurred',
            message: 'Please try again or contact support.',
          })
          return
        }

        const last4 = getLast4Digit(data)
        const capturedAmount = getCapturedAmount(data)
        const preSettlementBalance = getPreSettlementBalance(data)
        const newBalance = isNumber(preSettlementBalance) && isNumber(capturedAmount)
          ? preSettlementBalance + capturedAmount
          : null

        closeModal()

        if (capturedAmount !== null) {
          void loadGoogleTagManager().sendWalletEvent({
            ecommerce: {
              currency: 'USD',
              value: formatPenniesOptionalDec(amount),
              transaction_id: getUserId(data) ?? 'N/A',
              items: [],
            },
          })
        }

        if (newBalance === null) {
          const amount = capturedAmount ? ` ${formatPenniesOptionalDecUsd(capturedAmount)}` : ''

          toast({
            ...TOAST_PRESET.GENERIC_SUCCESS,
            message: `Your card ending in ${last4} was successfully charged${amount}.`,
          })
          return
        }

        toast({
          ...TOAST_PRESET.GENERIC_SUCCESS,
          message: `Your new balance is now ${formatPenniesOptionalDecUsd(newBalance)}.`,
        })
      })
      .catch(error => {
        openErrorModal({ message: error.message })
      })
      .then(() => {
        LegacyBillingEvents.addFundsDone.emit()
      })
  }, [addBalanceFetchApi])

  return { addBalance, loading }
}

const getErrors = (
  data: WalletAddBalanceFlow_AddBalanceMutation,
  graphqlError: readonly GraphQLFormattedError[] | undefined,
): string | null => {
  const errorFromData = getErrorFromData(data)

  if (errorFromData) return errorFromData

  if (graphqlError?.[0]?.message)
    return 'Unexpected error occurred. Please try again or contact support.'

  return null
}

const getErrorFromData = (data: WalletAddBalanceFlow_AddBalanceMutation): string | null => {
  const walletAddBalance = data?.walletAddBalance

  if (walletAddBalance && 'message' in walletAddBalance)
    return walletAddBalance?.message ?? null

  return null
}

const getLast4Digit = (data: WalletAddBalanceFlow_AddBalanceMutation): string => {
  if (data?.walletAddBalance?.__typename === 'WalletTransaction') {
    const paymentMethod = data?.walletAddBalance?.charge?.paymentMethod

    if (paymentMethod?.__typename === 'BillingCardsPaymentMethod')
      return paymentMethod?.last4 ?? '####'
  }

  return '####'
}

const getCapturedAmount = (data: WalletAddBalanceFlow_AddBalanceMutation): number | null => {
  if (data?.walletAddBalance?.__typename === 'WalletTransaction')
    return data?.walletAddBalance?.charge?.capturedAmount ?? null

  return 0
}

const getPreSettlementBalance = (data: WalletAddBalanceFlow_AddBalanceMutation): number | null => {
  if (data?.walletAddBalance?.__typename === 'WalletTransaction')
    return data?.walletAddBalance?.preSettlementBalance ?? null

  return 0
}

const getUserId = (data: WalletAddBalanceFlow_AddBalanceMutation): string | null => {
  if (data?.walletAddBalance?.__typename === 'WalletTransaction')
    return data?.walletAddBalance?.createdBy?.id ?? null

  return null
}
