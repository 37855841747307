// eslint-disable-next-line no-restricted-imports
import { Modal as ChakraModal, ModalContent as ChakraModalContent, ModalProps as ChakraModalProps, ModalContentProps } from '@chakra-ui/react'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { FC } from 'react'

export type ModalProps = ChakraModalProps

export const Modal: FC<ChakraModalProps> = ({
  variant,
  colorScheme,
  size,
  motionPreset: motionPresetFromProps,
  ...props
}) => {
  const isMobOrSmaller = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  }) ?? true

  /** Makes modal more performant in mobile setting which is a bit slow at the moment */
  const motionPreset = motionPresetFromProps ?? (isMobOrSmaller ? 'none' : 'scale')

  return (
    <ChakraModal
      motionPreset={motionPreset}
      {...props}
    />
  )
}

export const ModalContent: FC<ModalContentProps> = ({ children, sx, ...props }) => (
  <ChakraModalContent
    /**
     * ModelContent needs to only match the width of Card, so that the
     * close button is aligned with the right edge of the card.
     */
    w='auto'

    /**
     * Override theme maxW
     */
    maxW='full'
    pointerEvents='none'
    sx={{
      '& > .chakra-card': {
        mx: 'auto',
        pointerEvents: 'auto',
      },
      ...sx,
    }}
    {...props}
  >
    {children}
  </ChakraModalContent>
)
