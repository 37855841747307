import { Match, pipe } from 'effect'
import { MLSStatus } from 'features/valueObjects/MLSStatus'

export type MarkerPopupColors = {
  textColor: string
  cardBgColor: string
  cardBorderColor: string
  fullScreenBorderColor: string
  fullScreenIconColor: string
  headerBgColor: string
}

export const getMarkerPopupColors = (
  status: MLSStatus,
): MarkerPopupColors => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'marker.darkgreen',
    fullScreenBorderColor: 'accent.xxdarkgreen',
    fullScreenIconColor: 'accent.xxdarkgreen',
    headerBgColor: 'accent.xxdarkgreen',
  })),
  Match.when('FOR_LEASE', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'marker.darkgreen',
    fullScreenBorderColor: 'accent.xxdarkgreen',
    fullScreenIconColor: 'accent.xxdarkgreen',
    headerBgColor: 'accent.xxdarkgreen',
  })),
  Match.when('SALE_OR_LEASE', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'marker.darkgreen',
    fullScreenBorderColor: 'accent.xxdarkgreen',
    fullScreenIconColor: 'accent.xxdarkgreen',
    headerBgColor: 'accent.xxdarkgreen',
  })),
  Match.when('PENDING', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'marker.babyblue',
    cardBorderColor: 'marker.oxfordblue',
    fullScreenBorderColor: 'accent.xdarkblue',
    fullScreenIconColor: 'accent.xdarkblue',
    headerBgColor: 'accent.xdarkblue',
  })),
  Match.when('SOLD', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'card.bg.lightred',
    cardBorderColor: 'marker-mlstag.scarlet',
    fullScreenBorderColor: 'accent.xdarkred',
    fullScreenIconColor: 'accent.xdarkred',
    headerBgColor: 'accent.xdarkred',
  })),
  Match.when('LEASED', () => ({
    textColor: 'ondark.1',
    cardBgColor: 'card.bg.lightred',
    cardBorderColor: 'marker-mlstag.scarlet',
    fullScreenBorderColor: 'accent.xdarkred',
    fullScreenIconColor: 'accent.xdarkred',
    headerBgColor: 'accent.xdarkred',
  })),
  Match.when('EXPIRED', () => ({
    textColor: 'graystrong.600',
    cardBgColor: 'grayweak.600',
    cardBorderColor: 'marker.white',
    fullScreenBorderColor: 'onlight.1',
    fullScreenIconColor: 'onlight.1',
    headerBgColor: 'grayweak.200',
  })),
  Match.when('CANCELED', () => ({
    textColor: 'graystrong.600',
    cardBgColor: 'grayweak.600',
    cardBorderColor: 'marker.white',
    fullScreenBorderColor: 'onlight.1',
    fullScreenIconColor: 'onlight.1',
    headerBgColor: 'grayweak.200',
  })),
  Match.when('WITHDRAWN', () => ({
    textColor: 'graystrong.600',
    cardBgColor: 'marker.gray',
    cardBorderColor: 'marker.white',
    fullScreenBorderColor: 'onlight.1',
    fullScreenIconColor: 'onlight.1',
    headerBgColor: 'grayweak.200',
  })),
  Match.when('OFF_MARKET', () => ({
    textColor: 'graystrong.600',
    cardBgColor: 'marker.darkgray',
    cardBorderColor: 'marker.dirtywhite',
    fullScreenBorderColor: 'onlight.1',
    fullScreenIconColor: 'onlight.1',
    headerBgColor: 'grayweak.200',
  })),
  Match.exhaustive,
)
