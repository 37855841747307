import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const BinocularIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.path
        d='M19.2 10.5C19.2 8.9 16.8 8.5 16 8.5C14.08 8.5 12.8 9.83333 12.8 10.5L12.0302 22.0463C12.0103 22.345 12.0487 22.6509 12.2219 22.895C12.7136 23.5877 13.8824 24.5 16 24.5C18.0382 24.5 19.1974 23.6548 19.7199 22.9738C19.94 22.6869 19.9686 22.3137 19.9196 21.9554C19.4399 18.4519 19.2 12.0174 19.2 10.5Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M2.72211 22.8487L5.32565 9.45404C5.33098 9.42664 5.33665 9.39939 5.34351 9.37233C5.44362 8.97738 6.3346 5.83398 9.3336 5.83398C13.3333 5.83398 13.3337 9.41234 13.3337 9.41293V9.41296V23.1322C13.3336 24.9217 12.8002 28.5007 8.00021 28.5007C3.44562 28.5007 2.73266 25.2783 2.67167 23.42C2.66537 23.228 2.68545 23.0373 2.72211 22.8487Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M29.2779 22.8487L26.6743 9.45404C26.669 9.42664 26.6633 9.39939 26.6565 9.37233C26.5564 8.97738 25.6654 5.83398 22.6664 5.83398C18.6667 5.83398 18.6663 9.41234 18.6663 9.41293V9.41296V23.1322C18.6664 24.9217 19.1998 28.5007 23.9998 28.5007C28.5544 28.5007 29.2673 25.2783 29.3283 23.42C29.3346 23.228 29.3146 23.0373 29.2779 22.8487Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.circle
        cx='24.0003'
        cy='23.1673'
        r='5.33333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.circle
        cx='8.00033'
        cy='23.1673'
        r='5.33333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />

      <chakra.path
        d='M15.9997 24.4993C17.4724 24.4993 18.6663 23.3054 18.6663 21.8327C18.6663 20.3599 17.4724 19.166 15.9997 19.166C14.5269 19.166 13.333 20.3599 13.333 21.8327C13.333 23.3054 14.5269 24.4993 15.9997 24.4993Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
      />
    </chakra.svg>

  )
}
