import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { Breakpoint } from 'presentation/libs/breakpoint'
import { MutableRefObject, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { identity } from 'remeda'
import { create } from 'zustand'

export type PanelState = {
  isPanelOpen: boolean
  openPanel: () => void
  closePanel: () => void
  togglePanel: () => void
  setPanelOpen: (isPanelOpen: boolean) => void
}

const createPanelStore = (state: MutableRefObject<IsOpenState>) =>
  create<PanelState>(() => ({
    isPanelOpen: state.current.isOpen,
    openPanel: () => {
      state.current.open()
    },
    closePanel: () => {
      state.current.close()
    },
    togglePanel: () => {
      state.current.isOpen ? state.current.close() : state.current.open()
    },
    setPanelOpen: (nextIsPanelOpen: boolean) => {
      if (nextIsPanelOpen === state.current.isOpen) return
      if (nextIsPanelOpen)
        state.current.open()
      else
        state.current.close()
    },
  }))

type PanelStore = ReturnType<typeof createPanelStore>
type PanelContextValue = Record<string, PanelStore>

const PanelContext = createContext<PanelContextValue | null>(null)

type PanelConfig = {
  panelKey: string
  localMinBreakpoint?: Breakpoint
  localInitialIsOpen?: boolean
}

export const createPanelProvider = ({
  configs,
}: {
  configs: PanelConfig[]
}) => {
  const PanelProvider = ({
    children,
  }: PropsWithChildren) => {
    const initialStores = configs
      .map(config => useCreatePanelStore(config))
      .reduce<PanelContextValue>((acc, store, index) => ({
      ...acc,
      [configs[index].panelKey]: store,
    }), {})
    const stores = useRef(initialStores).current

    return (
      <PanelContext.Provider value={stores}>
        {children}
      </PanelContext.Provider>
    )
  }

  return PanelProvider
}

const useCreatePanelStore = ({
  panelKey,
  localMinBreakpoint,
  localInitialIsOpen = false,
}: PanelConfig) => {
  const routerState = useRouterIsOpenState(panelKey)
  const localState = useLocalIsOpenState(localInitialIsOpen)

  const mode = useSwitchBreakpoint({
    ...localMinBreakpoint && {
      [localMinBreakpoint]: localState,
    },
    mobSm: routerState,
  }) || routerState

  const modeRef = useRef(mode)
  modeRef.current = mode

  const initialStore = createPanelStore(modeRef)
  const store = useRef(initialStore).current

  useEffect(() => {
    store.setState({
      isPanelOpen: modeRef.current.isOpen,
    }, false)
  }, [modeRef.current.isOpen])

  return store
}

export const usePanelStore = (panelKey: string) =>
  <T extends PanelState>(
    selector: (store: PanelState) => T = (identity as (state: PanelState) => T),
    equalityFn?: (left: T, right: T) => boolean,
  ): T => {
    const storeRecord = useContext(PanelContext)
    if (!storeRecord) throw new Error('usePanelStore must be used within a PanelProvider')
    const store = storeRecord[panelKey]
    if (!store) throw new Error(`PanelProvider does not have a store for panelKey: ${panelKey}`)
    return equalityFn ? store(selector, equalityFn) : store(selector)
  }

type IsOpenState = {
  isOpen: boolean
  open: () => void
  close: () => void
}

const useRouterIsOpenState = (key: string): IsOpenState => {
  const location = useLocation()
  const isOpen = !!location.state?.[key]?.isOpen
  const navigate = useNavigate()

  const locationRef = useRef(location)
  locationRef.current = location

  const open = useCallback(() => {
    const location = locationRef.current
    const isOpen = !!location.state?.[key]?.isOpen
    if (isOpen) return
    navigate(location.pathname, { state: { [key]: { isOpen: true } } })
  }, [])

  const close = useCallback(() => {
    const location = locationRef.current
    const isOpen = !!location.state?.[key]?.isOpen
    if (!isOpen) return
    navigate(-1)
  }, [])

  const api = useMemo(() => ({
    isOpen,
    open,
    close,
  }), [isOpen])

  return api
}

const useLocalIsOpenState = (initialIsOpen: boolean): IsOpenState => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)

  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const api = useMemo(() => ({
    isOpen,
    open,
    close,
  }), [isOpen])

  return api
}
