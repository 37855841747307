import { Button } from '@chakra-ui/react'
import { capitalize } from 'effect/String'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { MapModeButton } from 'presentation/components/MapModeButton'
import { MapModeToggleDumbProps, MapModeToggleProps } from 'presentation/components/MapModeToggle/MapModeToggle.props'
import { identity } from 'remeda'

export const MapModeToggle = ({
  variant = 'image-button',
  ...props
}: MapModeToggleProps) => {
  const { mode, toggleMode } = useMapStore(identity)

  const handleMapMode = () => {
    toggleMode()
  }

  return (
    <MapModeToggleDumb
      variant={variant}
      currentMode={mode}
      onClick={handleMapMode}
      {...props}
    />
  )
}

export const MapModeToggleDumb = ({
  onClick,
  currentMode,
  variant = 'image-button',
  ...boxProps
}: MapModeToggleDumbProps) => {
  const nextMode = currentMode === 'map' ? 'satellite' : 'map'

  const alt = `Switch to ${capitalize(nextMode)} mode`

  if (variant === 'image-button') {
    return (
      <MapModeButton
        onClick={onClick}
        mode={nextMode}
        label={nextMode}
        alt={alt}
        {...boxProps}
      />
    )
  }

  return (
    <Button
      variant='outline'
      colorScheme='special'
      size='sm'
      onClick={onClick}
      bg='card.bg.1'
      {...boxProps as any}
    >
      {capitalize(nextMode)}
    </Button>
  )
}
