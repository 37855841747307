import { BoxProps, chakra } from '@chakra-ui/react'
import { MarkerProps } from 'presentation/components/PropertyMarker/components/Marker'
import { MarkerContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerContainer'
import { MarkerIconContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'
import { MarkerSvgContainer } from 'presentation/components/PropertyMarker/components/containers/MarkerSvgContainer'

export const SubjectPropertyMarker = ({
  markerType,
  size,
  ...props
}: MarkerProps & BoxProps) => (
  <MarkerSvgContainer size={size} markerType={markerType} {...props}>
    <>
      <MarkerContainer
        bgColor='special.500'
        borderColor='marker.darkpurple'
        markerType={markerType}
        size={size}
      />
      <MarkerIconContainer markerType={markerType}>
        <chakra.path d='M14.9454238,0.613710589 L18.0862782,8.11631073 C18.2044446,8.39866266 18.3928014,8.64263646 18.6315609,8.82275752 C18.8705071,9.00285902 19.1512689,9.11251918 19.4443513,9.14028124 L27.0956698,9.85877129 C27.9614765,9.99073885 28.3068284,11.1026877 27.6790346,11.7429748 L21.915391,16.8139504 C21.4486992,17.2245162 21.2364478,17.8696909 21.3646947,18.4927733 L23.0401182,26.7066297 C23.1872195,27.6085058 22.2840775,28.2976697 21.5093691,27.8698993 L14.8310843,23.7740173 C14.5792014,23.6191754 14.2924473,23.5376487 14.0003729,23.5376487 C13.7083172,23.5376487 13.4215444,23.6191754 13.1696615,23.7740173 L6.49130205,27.8675532 C5.71892714,28.2925865 4.81354507,27.6059642 4.96055298,26.7040881 L6.6359765,18.4904272 C6.76198329,17.8672471 6.55197198,17.2220723 6.08528019,16.8115066 L0.319306877,11.7454186 C-0.30606012,11.1075754 0.0392918035,9.99318269 0.902671613,9.86121513 L8.55407975,9.14272508 C8.84723687,9.11496302 9.12790531,9.00530287 9.3667955,8.82520136 C9.60566703,8.64508031 9.79402383,8.4011065 9.91215286,8.11875458 L13.0529886,0.616154433 C13.4426762,-0.204977048 14.5580696,-0.204977048 14.9454238,0.613710589 Z' id='Path' fill='trueorange.500'></chakra.path>
        <chakra.path d='M14.6037266,8.46446319 L14.0159502,3.05749862 C13.9927485,2.75631492 13.9257213,2.24 14.4464706,2.24 C14.8589453,2.24 15.0832284,3.0359855 15.0832284,3.0359855 L16.8465576,7.37685538 C17.511673,9.02858505 17.2384085,9.59509725 16.5964948,9.92974581 C15.8591963,10.3122013 14.7712944,10.013408 14.6037266,8.46446319 Z' id='Path' fill='highlight.500'></chakra.path>
        <chakra.path d='M21.6441278,15.3252026 L26.4698732,12.0511292 C26.7085533,11.8779091 27.1388859,11.6020401 26.7945411,11.2876777 C26.5216233,11.0396095 25.7837418,11.3967422 25.7837418,11.3967422 L21.5605012,12.8316888 C20.3011833,13.2102068 19.4649176,13.7704989 19.3911294,14.4762103 C19.2953032,15.4171589 20.2667489,16.142117 21.6441278,15.3252026 Z' id='Path' fill='trueorange.100'></chakra.path>
      </MarkerIconContainer>
    </>
  </MarkerSvgContainer>
)
