import { BoxProps, chakra } from '@chakra-ui/react'
import { FC } from 'react'

export const UsersIcon: FC<BoxProps> = ({ color, fill, stroke, boxSize, ...props }) => {
  const fillFromProps = color ?? fill
  return (
    <chakra.svg
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      boxSize={boxSize}
      {...props}
    >
      <chakra.circle
        cx='7.77572'
        cy='12.4993'
        r='4.33333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.circle
        cx='24.0003'
        cy='12.4993'
        r='4.33333'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7148 27.3679H29.3094C29.9109 27.3679 30.3643 26.8433 30.2024 26.264C29.6815 24.4011 28.1325 20.834 23.9082 20.834C22.3728 20.834 21.1908 21.3052 20.2832 21.9946C22.5436 23.4005 23.3945 25.7775 23.7148 27.3679Z'
        fill={fillFromProps}
      />

      <chakra.path
        d='M23.7148 27.3679L22.7345 27.5654L22.8962 28.3679H23.7148V27.3679ZM30.2024 26.264L31.1654 25.9947L31.1654 25.9947L30.2024 26.264ZM20.2832 21.9946L19.6783 21.1983L18.5218 22.0768L19.7551 22.8438L20.2832 21.9946ZM29.3094 26.3679H23.7148V28.3679H29.3094V26.3679ZM29.2393 26.5333C29.2358 26.5207 29.2327 26.4948 29.2397 26.4632C29.2464 26.4335 29.2592 26.4108 29.2715 26.396C29.2956 26.3668 29.3159 26.3679 29.3094 26.3679V28.3679C30.4793 28.3679 31.5301 27.2989 31.1654 25.9947L29.2393 26.5333ZM23.9082 21.834C25.6947 21.834 26.8743 22.5714 27.6903 23.5075C28.5332 24.4744 28.9986 25.6724 29.2393 26.5333L31.1654 25.9947C30.8853 24.9927 30.3157 23.4756 29.1979 22.1933C28.0531 20.8801 26.3461 19.834 23.9082 19.834V21.834ZM20.8881 22.791C21.6245 22.2316 22.5947 21.834 23.9082 21.834V19.834C22.1509 19.834 20.7571 20.3789 19.6783 21.1983L20.8881 22.791ZM19.7551 22.8438C21.6708 24.0353 22.4361 26.0836 22.7345 27.5654L24.6952 27.1705C24.353 25.4715 23.4164 22.7657 20.8113 21.1455L19.7551 22.8438Z'
        fill={stroke}
      />

      <chakra.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1983 21.9946C10.2907 21.3052 9.10876 20.834 7.57341 20.834C3.34911 20.834 1.80014 24.4011 1.27922 26.264C1.11724 26.8433 1.57071 27.3679 2.17221 27.3679H7.76654C8.08686 25.7775 8.93779 23.4004 11.1983 21.9946Z'
        fill={fillFromProps}
      />

      <chakra.path
        d='M11.1983 21.9946L11.7264 22.8437L12.9598 22.0767L11.8032 21.1982L11.1983 21.9946ZM1.27922 26.264L2.24228 26.5333L1.27922 26.264ZM7.76654 27.3679V28.3679H8.58522L8.74686 27.5654L7.76654 27.3679ZM7.57341 21.834C8.88684 21.834 9.85703 22.2316 10.5935 22.7909L11.8032 21.1982C10.7244 20.3789 9.33069 19.834 7.57341 19.834V21.834ZM2.24228 26.5333C2.48301 25.6724 2.94842 24.4744 3.79134 23.5075C4.60735 22.5714 5.78697 21.834 7.57341 21.834V19.834C5.13555 19.834 3.42854 20.8801 2.28376 22.1933C1.16589 23.4756 0.596351 24.9927 0.316162 25.9947L2.24228 26.5333ZM2.17221 26.3679C2.16569 26.3679 2.18598 26.3668 2.21012 26.396C2.22238 26.4108 2.23523 26.4335 2.24186 26.4632C2.2489 26.4948 2.24581 26.5207 2.24228 26.5333L0.316162 25.9947C-0.0485123 27.2989 1.00227 28.3679 2.17221 28.3679V26.3679ZM7.76654 26.3679H2.17221V28.3679H7.76654V26.3679ZM8.74686 27.5654C9.0453 26.0835 9.81057 24.0352 11.7264 22.8437L10.6702 21.1454C8.065 22.7656 7.12841 25.4715 6.78623 27.1705L8.74686 27.5654Z'
        fill={stroke}
      />

      <chakra.path
        d='M15.7754 20.5C22.0117 20.5 23.3866 25.3614 23.6897 27.505C23.767 28.0518 23.3277 28.5 22.7754 28.5H8.77539C8.22311 28.5 7.78378 28.0518 7.86111 27.505C8.16422 25.3614 9.53908 20.5 15.7754 20.5Z'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />

      <chakra.circle
        cx='15.776'
        cy='11.1667'
        r='5.66667'
        fill={fillFromProps}
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </chakra.svg>
  )
}
