import { ModalOverlay } from '@chakra-ui/react'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { DeleteListState, useListStoreActions } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useDeleteListsState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useDeleteListsState'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const DeleteListModal = () => {
  const modalActions = useDeleteListModalActions()
  const modalState = useDeleteListModalState()
  const actionsApi = useListStoreActions()
  const deleteState = useDeleteListsState()
  const navigate = useNavigate()

  // #region Close Modal on Delete Success
  const previousDeleteState = usePrevious(deleteState)
  useEffect(() => {
    if (
      modalState.status === 'open'
      && DeleteListState.$is('Loading')(previousDeleteState)
      && DeleteListState.$is('Success')(deleteState)
    ) {
      modalActions.close()
      navigate('/lists', { replace: true })
    }
  }, [deleteState])
  // #endregion

  // #region Handle Delete Outcome
  useEffect(() => {
    if (
      !DeleteListState.$is('Loading')(previousDeleteState)
      || modalState.status !== 'open'
    ) return

    if (DeleteListState.$is('Success')(deleteState)) {
      toast.info({
        title: 'List Deleted',
        message: 'A list has been deleted from the list manager.',
      })
    } else if (DeleteListState.$is('Failed')(deleteState)) {
      toast.error({
        title: 'Failed to Delete List from List Manager',
        message: TOAST_PRESET.GENERIC_ERROR.message,
      })
    }
  }, [deleteState])
  // #endregion

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.negative'
          >
            <CardImage src={hurtDogPng} />
            <ModalCloseButton onClose={modalActions.close} />
            <CardPrimaryTitle>
              Delete This List from List Manager
            </CardPrimaryTitle>
            <CardBody>
              <CardPrimaryText>
                This list will be deleted from your list manager. Are you sure you want to proceed?
              </CardPrimaryText>
            </CardBody>
            <CardFooter>
              <CardButton
                variant='outline'
                colorScheme='neutral'
                size='md'
                onClick={modalActions.close}
                autoFocus
              >
                Cancel
              </CardButton>
              <CardButton
                variant='solid'
                colorScheme='negative'
                size='md'
                /** @TODO */
                onClick={() => {
                  actionsApi.deleteLists(
                    [modalState.payload.listId],
                  )
                }}
                autoFocus
                isLoading={DeleteListState.$is('Loading')(deleteState)}
                loadingText='Deleting'
                type='submit'
              >
                Yes, Delete
              </CardButton>
            </CardFooter>

          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}

type OpenPayload = {
  listId: MarketingListId
}

type DeleteListModalState = {
  state: {
    status: 'open'
    payload: OpenPayload
  }
  | { status: 'close' }
  close: () => void
  open: (payload: OpenPayload) => void
}

const useDeleteListModal = create<DeleteListModalState>()(immer(set => ({
  state: { status: 'close' },
  close: () => set(state => { state.state = { status: 'close' } }),
  open: payload => set(state => { state.state = { status: 'open', payload } }),
})))

export const useDeleteListModalActions = () => useDeleteListModal(state => ({
  close: state.close,
  open: state.open,
}))

export const useDeleteListModalState = () => useDeleteListModal(state => state.state)
