import { createIcon } from '@chakra-ui/react'

export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.0004 20.9999L16.6504 16.6499' stroke='currentColor' fill='none' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
