import { useSelector } from '@xstate/react'
import { Array, Equal, Match, Option, pipe } from 'effect'
import { Color } from 'presentation/libs/chakra/chakra.types'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'

type HeaderRowViewModel = {
  headerBgColor: Color
  switchBgColor: Color
  headerValues: {
    planName: string
    iconType: 'feather' | 'paper-plane' | 'plane' | 'rocket'
    isCurrentPlan: boolean
    isDisabled: boolean
    addToCart: () => void
  }[]
}

type HeaderValues = HeaderRowViewModel['headerValues'][number]

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const products = useSelector(
    actor,
    snapshot => snapshot.context.products,
    Equal.equals,
  )
  const isYearly = useSelector(
    actor,
    snapshot => snapshot.context.isYearly,
    Equal.equals,
  )
  const disableFreePlan = useSelector(
    actor,
    snapshot => snapshot.context.disableFreePlan,
    Equal.equals,
  )

  const vm: HeaderRowViewModel = {
    headerBgColor: isYearly ? 'card.bg.green' : 'card.bg.blue',
    switchBgColor: isYearly ? 'card.bg.lightgreen' : 'card.bg.lightblue',
    headerValues: pipe(
      products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.map(product => ({
        planName: product.name,
        iconType: pipe(
          Match.value(product.name),
          Match.when('free', () => 'feather'),
          Match.when('beginner', () => 'paper-plane'),
          Match.when('intermediate', () => 'plane'),
          Match.when('advanced', () => 'rocket'),
          Match.orElse(() => { throw Error('product found') }),
        ),
        isCurrentPlan: pipe(
          product.plans,
          Array.findFirst(plan =>
            plan._tag === (isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
          Option.map(plan => plan.isCurrentPlan),
          Option.getOrElse(() => false),
        ),
        isDisabled: product.name === 'free' && disableFreePlan,
        addToCart: () => actor.send({ type: 'add-to-cart', targetProduct: product }),
      } as HeaderValues)),
    ),
  }

  return vm
}

const PlanComparisonHeaderRowViewModel = {
  usePresenter,
}

export default PlanComparisonHeaderRowViewModel
