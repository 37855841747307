import { Box, BoxProps, Checkbox, HStack, Link, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Portal, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { isString } from 'effect/Predicate'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { ListMemberWithSkiptrace, useListMemberWithSkiptrace } from 'features/common/ListMemberWithSkiptrace/infra/useListMemberWithSkiptrace'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Dollar } from 'libs/dollar'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Chip } from 'presentation/components/Chip/Chip'
import { SkiptraceFailedIcon, SkiptraceSuccessIcon } from 'presentation/components/Icons'
import { useBatchSkiptraceDrawerActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceDrawer/BatchSkiptraceDrawer'
import { usePropModalFromListScreen } from 'presentation/screens/ListScreen/usePropModalFromListScreen'
import { Dispatch, SetStateAction } from 'react'

export const ListTable = ({
  canSelect,
  selections,
  setSelections,
  ...boxProps
}: BoxProps & {
  canSelect: boolean
  selections: MarketingListMemberId[]
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
}) => {
  const membersApi = useListMemberWithSkiptrace()

  if (membersApi._tag === 'Loading') return null

  const members = membersApi.members

  return (
    <TableContainer
      borderBottomRadius={3}
      overflow='auto'
      {...boxProps}
    >
      <Table w='full' layout='fixed' minW='912px'>
        <Thead>
          <Tr>
            {canSelect && (
              <Th w='40px' sx={{ '&&': { px: 1 } }} fontSize={0}>
                <Checkbox
                  isChecked={selections.length === members.length}
                  isIndeterminate={selections.length > 0 && selections.length < members.length}
                  onChange={() => {
                    if (selections.length === members.length)
                      setSelections([])
                    else
                      setSelections(members.map(member => member.id))
                  }}
                  sx={{
                    '& .chakra-checkbox__control[data-indeterminate]': {
                      borderWidth: 0.125,
                      borderColor: 'neutral.300',
                    },
                  }}
                />
              </Th>
            )}
            <Th
              sx={canSelect ? { '&&': { pl: 0 } } : {}}
            >
              Address
            </Th>

            <Breakpoint
              dtLg={(
                <>
                  <Th w='160px'>Owner’s Name</Th>
                  <Th w='184px'>Owner’s Address</Th>
                </>
              )}
              tabSm={(
                <Th w='136px'>
                  Owner’s Name
                  <br />
                  &amp; Address
                </Th>
              )}
            />

            <Th w='80px'>
              Last Sale
              <br />
              Date
            </Th>
            <Th w='76px'>
              Property
              <br />
              Type
            </Th>
            <Th w='64px' isNumeric>Yr Built</Th>
            <Th w='64px' isNumeric>
              House
              <br />
              Sqft
            </Th>
            <Th
              w={{
                dtLg: '96px',
                tabSm: '72px',
              }}
            >
              <Breakpoint
                dtLg={(
                  <>
                    Beds/Baths
                    <br />
                    /Garage
                  </>
                )}
                tabSm={(
                  <>
                    Bds/Ba
                    <br />
                    /Garage
                  </>
                )}
              />
            </Th>
            <Th w='64px' isNumeric>
              Lot
              <br />
              Acre
            </Th>
            {/* <Th w='64px' isNumeric>Lot<br />Acre</Th> */}

            <Breakpoint
              dtLg={(
                <>
                  <Th w='96px' isNumeric>Est Value</Th>
                  <Th w='96px' isNumeric>Est Equity</Th>
                </>
              )}
              tabSm={(
                <>
                  <Th w='96px'>
                    Est Value/
                    <br />
                    Est Equity
                  </Th>
                </>
              )}
            />

            <Th w={{ dtLg: '96px', tabSm: '64px' }}>
              <Breakpoint
                dtLg={(
                  <>
                    Stress
                    <br />
                    Indicators
                  </>
                )}
                tabSm={(
                  <>
                    Stress
                    <br />
                    Indic.
                  </>
                )}
              />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map(member => (
            <Row
              id={member.id}
              isSelected={selections.includes(member.id)}
              setSelections={setSelections}
              canSelect={canSelect}
              key={member.id}
              member={member}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const Row = ({
  id,
  canSelect,
  isSelected,
  setSelections,
  member: memberWithSkiptrace,
}: {
  id: MarketingListMemberId
  canSelect: boolean
  isSelected: boolean
  setSelections: Dispatch<SetStateAction<MarketingListMemberId[]>>
  member: ListMemberWithSkiptrace
}) => {
  const member = memberWithSkiptrace.member.member
  const skiptrace = memberWithSkiptrace.skiptrace
  const drawerApi = useBatchSkiptraceDrawerActions()
  const propertyModalApi = usePropModalFromListScreen()

  const ownerCityStateZip = Address.formatCityStateZip(member.ownerAddress)
  const stressIndicators = [
    member.isForeclosure && 'Foreclosure',
    member.isTaxDelinquent && 'Tax Delinquent',
    member.isVacant && 'Vacant',
    member.isSenior && 'Senior',
  ].filter(isString)

  const ownerNameContent
    = member.ownerName
      ? (
        <HStack
          {...(skiptrace._tag === 'Success' || skiptrace._tag === 'SuccessEmpty')
          && {
            role: 'button',
            onClick: () => {
              drawerApi.open({
                skiptrace: skiptrace.result,
              })
            },
          }}
        >
          {pipe(
            Match.value(skiptrace),
            Match.tag('Loading', () => null),
            Match.when(
              skip =>
                skip._tag === 'Success'
                || skip._tag === 'SuccessEmpty',
              () => (
                <SkiptraceSuccessIcon fontSize='2.5' color='positive.500' />
              )),
            Match.orElse(() => <SkiptraceFailedIcon fontSize='2.5' color='negative.500' />),
          )}

          <Text
            {...(skiptrace._tag === 'Success' || skiptrace._tag === 'SuccessEmpty') && {
              color: 'link.500',
            }}
            title={member.ownerName}
            noOfLines={1}
            display='inline-block'
          >
            {member.ownerName}
          </Text>
        </HStack>
      )
      : '--'

  const ownerAddressContent
    = member.ownerAddress.line1 || ownerCityStateZip
      ? (
        <VStack align='flex-start' spacing={0}>
          <Text
            textStyle='bodyMFat'
            color='graystrong.400'
            noOfLines={1}
            w='full'
            maxW='full'
            display='inline-block'
          >
            {member.ownerAddress.line1}
          </Text>
          <Text
            textStyle='bodyM'
            color='grayweak.900'
            noOfLines={1}
            w='full'
            maxW='full'
            display='inline-block'
          >
            {ownerCityStateZip}
          </Text>
        </VStack>
      )
      : '--'

  return (
    <Tr>
      {/* Checkbox */}
      {canSelect && (
        <Td sx={{ '&&&': { px: 1 } }} fontSize={0}>
          <Checkbox
            isChecked={isSelected}
            onChange={() => {
              if (isSelected)
                setSelections(prev => prev.filter(prevId => prevId !== id))
              else
                setSelections(prev => [...prev, id])
            }}
          />
        </Td>
      )}

      {/* Address */}
      <Td
        sx={canSelect ? { '&&': { pl: 0 } } : {}}
      >
        <VStack
          align='flex-start'
          spacing={0}
          title={`${member.address.line1}, ${member.address.subdivision}`}
        >
          <Link
            textStyle='bodyLFat'
            w='full'
            noOfLines={1}
            display='inline-block'
            href='#'
            onClick={e => {
              e.preventDefault()
              propertyModalApi.actions.open(
                member.parcelId
                  ? { parcelId: member.parcelId }
                  : { address: member.address },
              )
            }}
          >
            {member.address.line1}
          </Link>
          <Text
            textStyle='bodyM'
            color='grayweak.900'
            w='full'
            noOfLines={1}
            display='inline-block'
          >
            {Address.formatCityStateZipSub(member.address)}
          </Text>
        </VStack>
      </Td>

      {/* Owner’s Name & Address */}
      <Breakpoint
        dtLg={(
          <>
            <Td>{ownerNameContent}</Td>
            <Td>{ownerAddressContent}</Td>
          </>
        )}
        tabSm={(
          <Td>
            {ownerNameContent}
            {ownerAddressContent}
          </Td>
        )}
      />

      {/* Last Sale Date */}
      <Td>{DateLib.formatMMDDYYDotsOrDoubleDash(member.lastSoldDate)}</Td>

      {/* Property Type */}
      <Td
        isTruncated
        title={member.useType ?? undefined}
      >
        {member.useType || '--'}
      </Td>

      {/* Year Built */}
      <Td isNumeric>{NumberLib.orDoubleDash(member.yearBuilt)}</Td>

      {/* House Sqft */}
      <Td isNumeric>{NumberLib.formatCommasOrDoubleDash(member.livingAreaSqft)}</Td>

      {/* Beds/Baths/Garage */}
      <Td>
        {[
          NumberLib.orDoubleDash(member.bedroomsCount),
          pipe(
            member.bathroomsCountInfo,
            BathroomsCountInfo.getTotal,
            StringLib.orDoubleDash,
          ),
          NumberLib.orDoubleDash(member.garageSpacesCount),
        ].join(' / ')}
      </Td>

      {/* Lot Acre */}
      <Td isNumeric>{NumberLib.formatCommasDecimalsOrDoubleDash(member.lotAreaAcres)}</Td>

      {/* Est Value & Est Equity */}
      {/* <Td isNumeric>{Dollar.formatNumberWithKOrDoubleDash(member.estimatedValue)}</Td>
      <Td isNumeric>{Dollar.formatNumberWithKOrDoubleDash(member.estimatedEquity)}</Td> */}
      <Breakpoint
        dtLg={(
          <>
            <Td>{Dollar.formatNumberWithKOrDoubleDash(member.estimatedValue)}</Td>
            <Td isNumeric>{Dollar.formatNumberWithKOrDoubleDash(member.estimatedEquity)}</Td>
          </>
        )}
        tabSm={(
          <>
            <Td>
              {Dollar.formatNumberWithCommasOrDoubleDash(member.estimatedValue)}
              <br />
              {Dollar.formatNumberWithCommasOrDoubleDash(member.estimatedEquity)}
            </Td>
          </>
        )}
      />

      {/* Stress Indicators */}
      <Td>
        <Popover
          isOpen={
            stressIndicators.length
              // undefined means controlled by Popover, it just works when there's any stress indicator
              ? undefined
              // false means it's always closed, effectively disabled when there's no stress indicator
              : false
          }
          offset={[0, 10]}
          colorScheme='highlight'
        >
          <PopoverTrigger>
            <Chip
              as='button'
              colorScheme='positive'
              w='full'
              justifyContent='center'
            >
              {stressIndicators.length || '--'}
            </Chip>
          </PopoverTrigger>
          <Portal>
            <PopoverContent p={2} maxW='304px'>
              <PopoverArrow padding={1} mt={-0.5} />
              <Text
                textStyle='bodyLFat'
                color='grayweak.900'
              >
                Stress Indicator:
              </Text>
              <Box lineHeight={4.5} mx={-0.5}>
                {stressIndicators.map(indicator => (
                  <Chip
                    mx={0.5}
                    key={indicator}
                    colorScheme='positive'
                  >
                    {indicator}
                  </Chip>
                ))}
              </Box>
            </PopoverContent>
          </Portal>
        </Popover>
      </Td>
    </Tr>
  )
}
