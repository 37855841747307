import { CMA } from 'features/CMA/CMA.domain'
import { mapFiltersToLegacy } from 'features/CMA/infra/remote/filterComps/filterComps.mapper'
import { GET_CMA_FUNDS_INFO_GQL } from 'features/CMA/infra/remote/initializeLoadReport/initializeLoadReport.graphql'
import { getPropertyCmaInfo } from 'features/CMA/infra/remote/loadCma/getPropertyCmaInfo'
import { mapCMA } from 'features/CMA/infra/remote/loadCma/loadCMA.mapper'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { getProperty } from 'features/PropertyDetails/infra/remote/getProperty/getProperty'
import { Pennies } from 'features/valueObjects/Pennies'
import { ErrorLib } from 'libs/errors'
import { apolloClient, restClient } from 'presentation/libs/client'

/**
 * Initializes a load report for a property, handling payment confirmation if required
 * First tries to get an existing CMA report, if found returns it
 * Otherwise, returns a confirmation key for payment confirmation flows
 */
export const initializeLoadReport = async (
  payload: CMA.InitializeLoadReportPayload,
): Promise<CMA.InitializeLoadReportResult> => {
  // InitializeLoadReportPayload is a string (the leadId)
  const leadId = payload

  try {
    // First, try to get property details - needed for both existing and new reports
    const property = await getProperty({ leadId })

    // If we get InsufficientConsumables, throw an error
    if (property.status === 'insufficient-consumables')
      throw new Error('Insufficient consumables to get property details')


    // Try to get an existing CMA report
    const getExistingReport = async (): Promise<CMA.InitializeLoadReportResult | null> => {
      try {
        const getRestResponseUnparsed = await restClient(`legacy/cma/${leadId}`)
          .json()
          .catch(async error => {
            const extraInfo = await ErrorLib.safeParseError(error)

            if (extraInfo?.serverResponse.error?.type === 'mls_coverage_error') {
              return {
                status: 'no-coverage' as const,
                address: {
                  ...property.address,
                  subdivision: (property.status === 'with-details'
                  && property.land?.subdivision) || null,
                },
              }
            }

            // If it's an error other than "not found", rethrow
            if (error?.response?.status !== 404) {
              ErrorLib.report(error, {
                extraInfo: { ...extraInfo },
              })
              throw error
            }

            // Return empty array to indicate no existing report
            return []
          })

        // Return if no coverage
        if ((getRestResponseUnparsed as any)?.status === 'no-coverage') {
          return {
            status: 'no-coverage' as const,
            address: {
              ...property.address,
              subdivision: (property.status === 'with-details'
              && property.land?.subdivision) || null,
            },
          }
        }

        // Check if we have a valid response with data
        const hasExistingReport = Array.isArray(getRestResponseUnparsed) && getRestResponseUnparsed.length > 0

        if (hasExistingReport && property.status === 'with-details') {
          // We have an existing report, map it and return success
          const gqlResponse = await getPropertyCmaInfo(property.parcelId)

          const report = mapCMA({
            rest: getRestResponseUnparsed,
            gql: gqlResponse,
            property,
          })

          if (report) {
            return {
              status: 'success' as const,
              report,
            }
          }
        }

        // No existing report found or couldn't be mapped
        return null
      } catch (getError) {
        // If there's an error getting the existing report, log it but continue with initialization
        ErrorLib.report(getError, {
          extraInfo: { leadId, context: 'Getting existing CMA failed, proceeding with initialization' },
        })
        // Return null to indicate we should proceed with initialization
        return null
      }
    }

    // Try to get existing report first
    const existingReportResult = await getExistingReport()

    // If we got a valid result, return it
    if (existingReportResult)
      return existingReportResult

    // If we reach here, there's no existing CMA or it couldn't be retrieved
    // Proceed with the original initialization logic
    // Make both requests in parallel
    const [restResponse, gqlResponse] = await Promise.all([
      // REST API request
      restClient.post(`legacy/cma/add/${leadId}`, {
        json: {
          useIncludedConsumables: true,
          ...mapFiltersToLegacy({
            soldWithinMonths: {
              max: CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX,
              range: [null, CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX],
            },
            distanceMiles: {
              max: CMA.DEFAULT_DISTANCE_MILES_MAX,
              subdivision: null,
            },
            boundary: null,
            homeType: null,
            yearBuilt: null,
            bedroomsCount: null,
            bathroomsCount: null,
            livingAreaSqft: null,
            lotAreaSize: null,
            garageSpacesCount: null,
            fireplacesCount: null,
            storiesCount: null,
            basementType: null,
            poolsCount: null,
            construction: null,
            foundation: null,
          }),
        },
      })
        .json<any>()
        .catch(async error => {
          // Try to parse the error response
          const errorResponse = await error?.response?.json()

          // Check if it's the payment confirmation required error
          if (errorResponse?.error?.type === 'payment_confirmation_required'
            && errorResponse?.error?.confirmationKey) {
            // Return the payment confirmation error info
            return errorResponse
          }

          // If not the expected error shape, report and rethrow
          const extraInfo = await ErrorLib.safeParseError(error)
          ErrorLib.report(error, {
            extraInfo: { ...extraInfo },
          })

          throw error
        }),

      // GraphQL request for subscription info
      apolloClient.query({
        query: GET_CMA_FUNDS_INFO_GQL,
      }),
    ])

    // Extract data from GraphQL response
    const { data } = gqlResponse
    const enterprise = data.myEnterprise

    if (!enterprise)
      throw new Error('undefined `myEnterprise` in GraphQL response')

    const subscription = enterprise.subscription

    if (subscription?.__typename !== 'BillingSubscriptionActive')
      throw new Error('undefined or inactive subscription')

    // Find the CMA feature in subscription
    const cmaFeature = subscription.plan.product.features
      .find((feat: any): feat is Extract<typeof feat, { __typename: 'SubscriptionFeatureComps' }> =>
        feat.__typename === 'SubscriptionFeatureComps')

    const cmaPrices = cmaFeature?.prices[0]

    if (!cmaPrices)
      throw new Error('missing CMA feature data in subscription')

    // Get wallet balance
    const walletBalance = enterprise.wallet.balance.available

    // Check if this is a successful response (not an error)
    // If the response doesn't have an error, it means comps are free and the operation succeeded directly
    if (!restResponse.error) {
      // Map the response and return success result directly - no need to track consumables for free comps
      const gqlInfoResponse = property.status === 'with-details'
        ? await getPropertyCmaInfo(property.parcelId)
        : null

      const report = mapCMA({
        rest: Array.isArray(restResponse) ? restResponse : [restResponse],
        gql: gqlInfoResponse,
        property,
      })

      if (report) {
        return {
          status: 'success' as const,
          report,
        }
      }
    }

    // If we get here, it means payment is required - proceed with normal payment flow
    // Calculate remaining consumables
    const remainingConsumables = restResponse?.error?.details?.included?.available || 0

    // Determine status
    const status = remainingConsumables > 0
      ? 'sufficient-consumables'
      : walletBalance >= cmaPrices.price
        ? 'sufficient-funds'
        : 'insufficient-funds'

    // Get confirmation key from REST response if it exists
    const confirmationKey = restResponse?.error?.confirmationKey || ''

    // Create the consumable attempt structure
    const consumableAttempt: ConsumableAttempt.ConsumableAttempt = {
      planName: subscription.plan.name,
      price: cmaPrices.price,
      refreshingConsumables: restResponse?.error?.details?.included?.limit,
      remainingConsumables,
      consumablesRefreshDate: new Date(subscription.currentPeriod.end),
      funds: {
        available: walletBalance as Pennies,
      },
      confirmationKey,
      status,
    }

    return {
      status: 'initialized' as const,
      consumableAttempt,
    }
  } catch (error) {
    ErrorLib.report(error, {
      extraInfo: { leadId },
    })

    throw error
  }
}
