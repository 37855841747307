import { create } from 'zustand'

/**
 * @TOREFACTOR Move to XState
 */
namespace NearbyBuyersFilterPanelAPI {
  const store = create(() => ({
    isOpen: false,
  }))

  export const useIsOpen = () => store(state => state.isOpen)
  export const open = () => store.setState(() => ({ isOpen: true }))
  export const close = () => store.setState(() => ({ isOpen: false }))
}

export default NearbyBuyersFilterPanelAPI
