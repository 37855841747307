import { Request } from 'effect'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { ListSource } from 'features/ListBuilder/domain/ListSource'
import { ListPreview } from 'features/ListBuilder/domain/ListSource/ListPreview'

namespace ListSourceRequest {
  export class BuildList<T extends ListSource = ListSource>
    extends Request.TaggedClass('BuildList')<
      ListPreview<T>,
      never,
      {
        criteria: ListCriteria
      }
    > {}

  export class GetBuildTotal extends Request.TaggedClass('GetBuildTotal')<
    number,
    never,
    {
      criteria: ListCriteria
    }
  > {}
}

export default ListSourceRequest
