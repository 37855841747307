import { Box } from '@chakra-ui/react'
import { MAP_ELEMENT__Z_INDICES } from 'presentation/components/Map/Map.const'
import { CompsMapMarkerProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarker/CompsMapMarker.props'
import { CompsMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarker'
import { Marker } from 'react-map-gl'

export const CompsMapMarker = (props: CompsMapMarkerProps) => {
  const {
    latitude,
    longitude,
    isHighlighted,
    onHover,
    onHoverOut,
    onMarkerClick,
    ...markerProps
  } = props
  return (
    <Box
      onMouseEnter={e => {
        e.stopPropagation()
        onHover(markerProps.id)
      }}
      onMouseLeave={e => {
        e.stopPropagation()
        onHoverOut(markerProps.id)
      }}
      onClick={e => {
        e.stopPropagation()
        onMarkerClick(markerProps.id)
      }}
    >
      <Marker
        longitude={longitude}
        latitude={latitude}
        style={{
          zIndex: isHighlighted
            ? MAP_ELEMENT__Z_INDICES.SELECTED_MARKER
            : markerProps.isSubjectProperty
              ? MAP_ELEMENT__Z_INDICES.SUBJECT_MARKER
              : MAP_ELEMENT__Z_INDICES.MARKER,
        }}
        anchor='bottom'
      >
        <CompsMarker {...markerProps} />
      </Marker>
    </Box>
  )
}
