import { Flex, MenuButton, MenuButtonProps, MenuGroup, Text, VStack } from '@chakra-ui/react'
import { MapIcon } from 'presentation/components/Icons'
import { MapMode } from 'presentation/components/Map/createMapStore'
import { useMapStore } from 'presentation/components/Map/useMapStore'
import { MLSStatusMapLegendItems } from 'presentation/components/MapLegend/MLSStatusMapLegendItems'
import { MapModeButton } from 'presentation/components/MapModeButton'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'

type MapModeIconToggleProps = MenuButtonProps

export const MapModeIconToggle = (props: MapModeIconToggleProps) => {
  const { mode, setMode } = useMapStore(store => ({
    mode: store.mode,
    setMode: store.setMode,
  }), shallow)
  const [isOpen, setIsOpen] = useState(false)
  const handleMapModeChange = (mode: MapMode) => {
    setMode(mode)
    setIsOpen(false)
  }
  return (
    <Menu
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <MenuButton
        boxSize={6}
        bgColor='special.500'
        borderRadius='full'
        shadow='float'
        onClick={() => setIsOpen(!isOpen)}
        {...props}
      >
        <VStack align='center' justify='center' spacing={0}>
          <MapIcon boxSize={3} color='ondark.1' display='block' />
          <Text
            textStyle='tagS'
            color='ondark.1'
          >
            Map
          </Text>
        </VStack>
      </MenuButton>
      <ResponsiveMenuList>
        <MenuGroup title='Map Type'>
          <Flex
            gap={2}
            pl={2}
          >
            <MapModeButton
              mode='map'
              label='Map'
              alt='Switch to Map mode'
              w='full'
              h='96px'
              onClick={() => handleMapModeChange('map')}
              {...(mode === 'map' ? { borderColor: 'neutral.500' } : {})}
            />

            <MapModeButton
              mode='satellite'
              label='Satellite'
              alt='Switch to Satellite mode'
              w='full'
              h='96px'
              onClick={() => handleMapModeChange('satellite')}
              {...(mode === 'satellite' ? { borderColor: 'neutral.500' } : {})}
            />
          </Flex>
        </MenuGroup>
        <MenuGroup title='Legend'>
          <MLSStatusMapLegendItems
            pl={2}
          />
        </MenuGroup>
      </ResponsiveMenuList>
    </Menu>
  )
}
