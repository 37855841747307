import { Button, CardProps, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { Match } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { EyeIcon, EyeOffIcon } from 'presentation/components/Icons'
import { toast } from 'presentation/components/Toast'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useOneToastAtATime } from 'presentation/libs/useOneToastAtATime'
import { FormCard, ValidationFeedback } from 'presentation/screens/AuthScreens/components/FormCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { pipe } from 'remeda'

type FormValues = {
  password: string
  confirmPassword: string
}

export const ResetPasswordCard = ({
  isForRetrial,
  ...props
}: CardProps & {
  isForRetrial?: boolean
}) => {
  const domain = useAccessStore(store => {
    const changePassword = store.actions.changePassword
    const clearErrors = store.actions.clearErrors

    return {
      changePassword: changePassword.execute,
      clearErrors: clearErrors.execute,
      isSubmitting: changePassword.state.status === 'loading',
      didSucceed: changePassword.state.status === 'success',
      errorMsg: pipe(
        Match.value(changePassword.state),
        Match.when({ error: Match.instanceOf(Error) }, ({ error }) => error.message),
        Match.orElse(() => null),
      ),
    }
  })

  const form = useForm<FormValues>()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    setIsPasswordVisible(!isPasswordVisible)
  }

  const isPasswordPristine = !form.formState.dirtyFields.password
  const password = form.watch('password')

  useOneToastAtATime(() => {
    if (!domain.errorMsg) return null

    domain.clearErrors()

    return {
      status: 'error',
      title: 'Failed to Reset Password',
      message: domain.errorMsg,
    }
  }, [domain.errorMsg])

  const navigate = useNavigate()
  useEffect(() => {
    if (!domain.didSucceed) return

    const query = isForRetrial ? `?redirect=${encodeURIComponent('/renew-trial')}` : ''
    navigate(`/login${query}`)
    toast.success({
      title: 'Reset Password Successful',
      message: 'You can now log in with your new password.',
    })
  }, [domain.didSucceed])

  const [searchParams] = useSearchParams()
  const codeFromEmail = searchParams.get('code') || ''

  return (
    <FormCard
      as='form'
      onSubmit={form.handleSubmit(formValues => {
        void domain.changePassword({
          code: codeFromEmail,
          password: formValues.password,
        })
      })}
      {...props}
    >
      <CardPrimaryTitle>
        Reset Password
      </CardPrimaryTitle>
      <Flex mt={3} flexDirection='column' gap={3}>
        <CardPrimaryText shouldResetSpacing>
          Create your new password
        </CardPrimaryText>
        <FormControl isInvalid={!!form.formState.errors.password}>
          <FormLabel>Create Password</FormLabel>
          <InputGroup>
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Enter password'
              {...form.register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  // Hide message, as it's already shown in ValidationFeedback
                  message: '',
                },
              })}
            />
            <InputRightElement
              onClick={togglePasswordVisibility}
              as='button'
              type='button'
            >
              {isPasswordVisible
                ? <EyeOffIcon color='graystrong.200' />
                : <EyeIcon color='graystrong.200' />}
            </InputRightElement>
          </InputGroup>
          {form.formState.errors.password?.message && (
            <FormErrorMessage>
              {form.formState.errors.password.message}
            </FormErrorMessage>
          )}
          <ValidationFeedback
            status={
              isPasswordPristine
                ? 'pristine'
                : password.length >= 8
                  ? 'valid'
                  : 'invalid'
            }
          >
            Minimum 8 characters
          </ValidationFeedback>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.confirmPassword}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Re-enter password'
              {...form.register('confirmPassword', {
                required: 'Password confirmation is required',
                validate: (value, { password }) =>
                  value === password || 'Passwords do not match',
              })}
            />
            <InputRightElement onClick={togglePasswordVisibility} as='button'>
              {isPasswordVisible
                ? <EyeOffIcon color='graystrong.200' />
                : <EyeIcon color='graystrong.200' />}
            </InputRightElement>
          </InputGroup>
          {form.formState.errors.confirmPassword?.message && (
            <FormErrorMessage>
              {form.formState.errors.confirmPassword.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>

      <Button
        mt={mbp({ mobSm: 'auto', mob: '5' })}
        variant='solid'
        colorScheme='positive'
        type='submit'
        isDisabled={form.formState.isSubmitted && !form.formState.isValid}
        isLoading={domain.isSubmitting}
      >
        Reset
      </Button>
    </FormCard>
  )
}
