import { Grid, GridItem } from '@chakra-ui/react'
import { useHomeType } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { HomeTypeFilterOption } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const PropertyTypeCard = () => {
  const api = useHomeType()
  const { sbp } = useSwitchBreakpointFn()
  const optionSize = sbp({
    mobSm: 'sm' as const,
    mob: 'lg' as const,
  }) || 'sm'

  return (
    <Card
      variant='default'
      colorScheme='card.bg.1'
      size={mbp({
        mobSm: 'xxs-locked',
        tabSm: 'sm-locked',
      })}
    >
      <CardHeader>
        <CardSecondaryTitle justifySelf='flex-start'>
          Property Type
        </CardSecondaryTitle>

        <Grid
          mt={2}
          gridTemplate={mbp({
            mobSm: 'auto / repeat(3, 1fr)',
            tabSm: 'auto / repeat(5, 1fr)',
          })}
          rowGap={mbp({
            mobSm: 2,
            tabSm: 4,
          })}
          columnGap={mbp({
            mobSm: 0,
          })}
        >
          {([
            null,
            'town-house',
            'multi-family',
            'lot-or-land',
            'commercial',
            'single-family',
            'condo',
            'duplex-to-fourplex',
            'mobile',
            'farm',
          ] as const).map(type => (
            <GridItem key={type} display='flex' justifyContent='center'>
              <HomeTypeFilterOption
                size={optionSize}
                variation={type}
                isSelected={type === null
                  ? api.value.length === 0
                  : api.value.includes(type)}
                onSelectionChange={() => {
                  if (type === null) {
                    api.apply([])
                    return
                  }

                  api.apply(
                    api.value.includes(type)
                      ? api.value.filter(v => v !== type)
                      : [...api.value, type],
                  )
                }}
                isSubjectProperty={false}
              />
            </GridItem>
          ))}
        </Grid>
      </CardHeader>
    </Card>
  )
}
