import { Button, CardProps, Stack, Text } from '@chakra-ui/react'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ConsumableInfoCard } from './ConsumableInfoCard'

export type PropertyDetailReportCardProps = {
  onCancel?: () => void
  onChoosePlan: () => void
  title?: string
  subtitle?: string
  refreshingConsumables: number
  remainingConsumables: number
  consumablesRefreshDate: string
  currentPlan: string
  upgradeUrl?: string
} & CardProps

export const PropertyDetailReportCard = (props: PropertyDetailReportCardProps) => {
  const {
    onCancel,
    onChoosePlan,
    title = 'Get a Property Lookup',
    subtitle,
    refreshingConsumables,
    remainingConsumables,
    consumablesRefreshDate,
    currentPlan,
    upgradeUrl = '/pricing#first-choose-plan',
    ...cardProps
  } = props

  return (
    <Card
      variant='default'
      colorScheme='modal.highlight'
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked', tabSm: 'lg-locked', dtLg: 'lg-locked' })}
      {...cardProps}
    >
      <CardPrimaryTitle>
        {title}
      </CardPrimaryTitle>

      <Text
        mt={mbp({ mobSm: 2, mob: 3, tabSm: 2 })}
        textStyle='bodyXLFat'
        color='graystrong.200'
      >
        {subtitle}
      </Text>

      <ConsumableInfoCard
        mt='4'
        totalFree={refreshingConsumables}
        remainingFree={remainingConsumables}
        currentPlan={currentPlan}
        nextRenewalDate={consumablesRefreshDate}
        consumableType='property-lookup'
        upgradeUrl={upgradeUrl}
      />

      <Stack
        justifyContent='center'
        gap={3}
        mt={mbp({ mobSm: 3, mob: 4, tabSm: 5 })}
        direction={{
          base: 'column',
          tabSm: 'row',
        }}
      >
        {onCancel && (
          <Button
            variant='outline'
            colorScheme='neutral'
            size='lg'
            onClick={onCancel}
            order={{
              base: '2',
              tabSm: '1',
            }}
          >
            CANCEL
          </Button>
        )}
        <Button
          w={mbp({ mobSm: onCancel ? 'auto' : 'full', mob: 'auto' })}
          variant='solid'
          colorScheme='positive'
          size='lg'
          onClick={onChoosePlan}
          order={{
            base: '1',
            tabSm: '2',
          }}
        >
          CHOOSE PLAN
        </Button>
      </Stack>
    </Card>
  )
}
