/**
 * Based on Charka's zIndices, but with more levels
 */
export const zIndices = {
  'hide': -1,
  'auto': 'auto',
  'base': 0,
  'docked': 10,
  'docked-1': 11,
  'docked-2': 12,
  'dropdown': 1000,
  'sticky': 1100,
  'sticky-1': 1115,
  'sticky-2': 1130,
  'sticky-3': 1145,
  'sticky-4': 1160,
  'sticky-5': 1175,
  'banner': 1200,
  'overlay': 1300,
  'modal': 1400,
  'popover': 1500,
  'popover-1': 1515,
  'popover-2': 1530,
  'popover-3': 1545,
  'popover-4': 1560,
  'popover-5': 1575,
  'skipLink': 1600,
  'toast': 1700,
  'tooltip': 1800,
}
