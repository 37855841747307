import { createContext, useContext } from 'react'
import { useReactToPrint } from 'react-to-print'
import { create } from 'zustand'

type ZustandState = {
  element: HTMLElement | null
}

const store = create<ZustandState>(() => ({
  element: null,
}))

type ContextState = {
  isForPrint: boolean
}

const context = createContext<ContextState>({
  isForPrint: false,
})

export const ComponentPrint = {
  setComponent: (element: HTMLElement) => store.setState({ element }),
  Provider: ({ children }: {
    children?: React.ReactNode
  }) => (
    <context.Provider value={{ isForPrint: true }}>
      {children}
    </context.Provider>
  ),
  usePrint: () => {
    const print = useReactToPrint({
      content: () => store.getState().element,
    })
    const isForPrint = useContext(context).isForPrint

    return {
      print,
      isForPrint,
    }
  },
}
