import { createIcon } from '@chakra-ui/react'

export const EmailIcon = createIcon({
  displayName: 'EmailIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path d='M3.33333 2.66699H14C14.7333 2.66699 15.3333 3.26699 15.3333 4.00033V12.0003C15.3333 12.7337 14.7333 13.3337 14 13.3337H3.33333C2.6 13.3337 2 12.7337 2 12.0003V4.00033C2 3.26699 2.6 2.66699 3.33333 2.66699Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M15.3333 4L8.66667 6L2 4' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' fill='none' />
      <path d='M8.31299 12.7656C8.69922 12.7656 9.07178 12.7144 9.35547 12.6255V12.1196C9.15723 12.2017 8.75049 12.2563 8.34717 12.2563C6.89111 12.2563 5.96484 11.354 5.96484 9.93896V9.93213C5.96484 8.54443 6.89795 7.56689 8.22412 7.56689C9.54688 7.56689 10.4321 8.39062 10.4321 9.62109V9.62793C10.4321 10.3799 10.1929 10.8652 9.81348 10.8652C9.59131 10.8652 9.46484 10.7217 9.46484 10.4824V8.58887H8.83936V8.99902H8.77783C8.64453 8.69824 8.34375 8.51709 7.98145 8.51709C7.29102 8.51709 6.81592 9.08789 6.81592 9.91504V9.92188C6.81592 10.7866 7.29102 11.3677 8.00195 11.3677C8.39844 11.3677 8.68896 11.1797 8.83252 10.8345H8.89404L8.89746 10.855C8.96582 11.1763 9.27344 11.3848 9.68359 11.3848C10.4971 11.3848 11.0063 10.6943 11.0063 9.60059V9.59375C11.0063 8.09668 9.88525 7.06445 8.25488 7.06445C6.55615 7.06445 5.39062 8.22656 5.39062 9.91162V9.91846C5.39062 11.6445 6.53906 12.7656 8.31299 12.7656ZM8.13525 10.79C7.74561 10.79 7.50635 10.4688 7.50635 9.94238V9.93555C7.50635 9.40234 7.74219 9.08447 8.13867 9.08447C8.54199 9.08447 8.79492 9.4126 8.79492 9.93555V9.94238C8.79492 10.4619 8.53857 10.79 8.13525 10.79Z' fill='currentColor' />
    </>
  ),
})
