import { Box, FormControl, FormLabel, Grid, GridItem, HStack, InputGroup, InputRightElement, NumberInput, NumberInputField, Switch, Text, VStack } from '@chakra-ui/react'
import { useHighEquityValue, useIsFreeAndClearFlag, useIsUnknownEquityFlag, useLowEquityValue, usePropertyValue, useTaxValue, useUpsideDownValue } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { CloseIcon } from 'presentation/components/Icons'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import ChakraLib from 'presentation/libs/Chakra'
import { makeOptionalRangeCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/OptionalRangeCard'
import { makeWiredSwitchCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/SwitchCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import React from 'react'
import useStateRef from 'react-usestateref'
import { isNumber, isString } from 'remeda'

export const EquityFiltersContent = () => {
  const shouldStackInputs = useSwitchBreakpoint({
    mobSm: true,
    tabSm: false,
  }) ?? true

  return (
    <VStack p={2} spacing={2} align='stretch'>
      <Grid
        gridTemplate={mbp({
          mobSm: 'auto / repeat(1, auto)',
          tabSm: 'auto / repeat(2, 1fr)',
        })}
        gap={2}
      >
        <GridItem>
          <EstimatedPropertyValueCard shouldStack={shouldStackInputs} />
        </GridItem>
        <GridItem>
          <TaxValueCard shouldStack={shouldStackInputs} />
        </GridItem>
        <GridItem>
          <FreeAndClearCard h='full' />
        </GridItem>
        <GridItem>
          <UpsideDownCard />
        </GridItem>
        <GridItem>
          <HighEquityCard />
        </GridItem>
        <GridItem>
          <LowEquityCard />
        </GridItem>
        <GridItem>
          <UnknownCard h='full' />
        </GridItem>
      </Grid>
    </VStack>
  )
}

// #region makeEquityCard
const makeEquityCard = ({
  title,
  displayNamePrefix,
  percentageLabel,
  amountLabel,
  useApi,
}: {
  title: string
  displayNamePrefix: string
  percentageLabel: string
  amountLabel: string
  useApi: () => ({
    value: {
      condition: 'and' | 'or'
      percentage: number | null
      amount: number | null
    }
    apply: (value: { condition?: 'and' | 'or', percentage?: number | null, amount?: number | null }) => void
  })
}) => {
  const EquityCard = () => {
    const api = useApi()
    const [isOpen, setIsOpen] = React.useState(isNumber(api.value.amount) || isNumber(api.value.percentage))
    const [percentage, setPercentage, percentageRef] = useStateRef(api.value.percentage ?? '')
    const [amount, setAmount] = useStateRef(api.value.amount ?? '')

    const isStackedMode = useSwitchBreakpoint({
      mobSm: true,
      tabSm: false,
    }) ?? true

    // update value when the api value changes
    React.useEffect(() => {
      setPercentage(api.value.percentage ?? '')
      setAmount(api.value.amount ?? '')
    }, [api.value.amount, api.value.percentage])

    const adjustPercentInputCursor = (input: HTMLInputElement) => {
      const isCursorAfterPercent = input.selectionStart === input.value.length

      if (!isCursorAfterPercent) return

      input.setSelectionRange(input.value.length - 1, input.value.length - 1)
    }

    const switchJsx = (
      <Switch
        colorScheme='special'
        size='lg'
        isChecked={api.value.condition === 'and'}
        onChange={() =>
          api.apply({
            condition: api.value.condition === 'and' ? 'or' : 'and',
          })}
      >
        <HStack justifyContent='space-around' gap={0}>
          <Text color='inherit'>and</Text>
          <Text color='inherit' pr={1}>or</Text>
        </HStack>
      </Switch>
    )

    return (
      <Card
        variant='default'
        colorScheme='card.bg.1'
        size={mbp({
          mobSm: 'xxs-locked',
          tabSm: 'sm-locked',
        })}
        align='stretch'
        justify='center'
        bg={isOpen ? 'neutral.200' : undefined}
        h='full'
        onClick={ev => {
          if (isOpen) return
          const isChakraSwitchClick = ChakraLib.isTargetChakraSwitch(ev.target)
          if (isChakraSwitchClick) return
          setIsOpen(true)
        }}
      >
        <CardHeader>
          <HStack>
            <CardSecondaryTitle justifySelf='flex-start' flex='1'>
              {title}
            </CardSecondaryTitle>
            <Switch
              colorScheme='default'
              isChecked={isOpen}
              onChange={() => {
                setIsOpen(!isOpen)
                const willClose = isOpen
                if (willClose) {
                  api.apply({
                    condition: 'and',
                    percentage: null,
                    amount: null,
                  })
                }
              }}
            />
          </HStack>
        </CardHeader>

        {isOpen && (
          <Box mt={2}>
            <Grid
              gridTemplate={mbp({
                mobSm: 'auto / 1fr',
                tabSm: 'auto / 1fr min-content 1fr',
              })}
              columnGap={1}
            >
              <GridItem>
                <FormControl>
                  <FormLabel>
                    {percentageLabel}
                  </FormLabel>
                  <NumberInput
                    keepWithinRange
                    max={100}
                    precision={0}
                    format={value => `${value}%`}
                    onBlur={() => {
                      /**
                       * @HACK delay by one tick, to allow NumberInput
                       * to apply max restriction first
                       */
                      setTimeout(() => {
                        /**
                         * @NOTE must use ref value here otherwise we won't
                         * get the value adjusted by NumberInput after one tick
                         */
                        api.apply({
                          percentage: isString(percentageRef.current)
                            ? NumberLib.fromStringSafe(percentageRef.current)
                            : percentageRef.current,
                        })
                      }, 0)
                    }}
                    value={percentage}
                    onChange={setPercentage}
                  >
                    <InputGroup>
                      <NumberInputField
                        onChange={ev => {
                          adjustPercentInputCursor(ev.target)
                        }}
                        onFocus={ev => {
                          adjustPercentInputCursor(ev.target)
                        }}
                        onSelect={ev => {
                          adjustPercentInputCursor(ev.target as HTMLInputElement)
                        }}
                      />

                      {percentage && (
                        <InputRightElement
                          role='button'
                          px={1}
                          onClick={() => {
                            setPercentage('')
                            api.apply({ percentage: null })
                          }}
                        >
                          <CloseIcon />
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </NumberInput>
                </FormControl>
              </GridItem>
              {!isStackedMode && (
                <GridItem>
                  <Box pt='35px'>
                    {switchJsx}
                  </Box>
                </GridItem>
              )}
              <GridItem>
                <FormControl>
                  {isStackedMode
                    ? (
                      <HStack align='flex-end'>
                        <FormLabel flex='1'>
                          {amountLabel}
                        </FormLabel>

                        <Box pb={1} pt={1.5}>
                          {switchJsx}
                        </Box>
                      </HStack>
                    )
                    : (
                      <FormLabel>
                        {amountLabel}
                      </FormLabel>
                    )}
                  <NumberInput
                    keepWithinRange
                    precision={0}
                    format={formatDollarWithCommas}
                    onBlur={() => api.apply({
                      amount: isString(amount)
                        ? NumberLib.fromStringSafe(amount)
                        : amount,
                    })}
                    value={amount}
                    onChange={setAmount}
                  >
                    <InputGroup>
                      <NumberInputField />
                      {amount && (
                        <InputRightElement
                          role='button'
                          px={1}
                          onClick={() => {
                            setAmount('')
                            api.apply({ amount: null })
                          }}
                        >
                          <CloseIcon />
                        </InputRightElement>
                      )}
                    </InputGroup>
                  </NumberInput>
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        )}
      </Card>
    )
  }

  EquityCard.displayName = `${displayNamePrefix}Card`

  return EquityCard
}
// #endregion

// #region Equity Cards
const HighEquityCard = makeEquityCard({
  title: 'High Equity',
  displayNamePrefix: 'HighEquity',
  percentageLabel: 'Minimum Percentage',
  amountLabel: 'Minimum Amount',
  useApi: () => {
    const baseApi = useHighEquityValue()

    return {
      value: {
        condition: baseApi.value.condition,
        percentage: baseApi.value.minPercentage,
        amount: baseApi.value.minAmount,
      },
      apply: value => baseApi.apply({
        ...baseApi.value,
        ...value.amount !== undefined && { minAmount: value.amount },
        ...value.percentage !== undefined && { minPercentage: value.percentage },
        ...value.condition !== undefined && { condition: value.condition },
      }),
    }
  },
})

const LowEquityCard = makeEquityCard({
  title: 'Low Equity',
  displayNamePrefix: 'LowEquity',
  percentageLabel: 'Maximum Percentage',
  amountLabel: 'Maximum Amount',
  useApi: () => {
    const baseApi = useLowEquityValue()

    return {
      value: {
        condition: baseApi.value.condition,
        percentage: baseApi.value.maxPercentage,
        amount: baseApi.value.maxAmount,
      },
      apply: value => baseApi.apply({
        ...baseApi.value,
        ...value.amount !== undefined && { maxAmount: value.amount },
        ...value.percentage !== undefined && { maxPercentage: value.percentage },
        ...value.condition !== undefined && { condition: value.condition },
      }),
    }
  },
})
// #endregion

// #region Optional Range Cards
const formatDollarWithCommas = (value: string | number) => {
  if (value === '-') return '-$'

  const maybeNumber = isNumber(value)
    ? value
    : NumberLib.fromStringSafe(value)

  if (maybeNumber === null) return '$'

  return Dollar.formatNumberWithCommas(maybeNumber)
}

const EstimatedPropertyValueCard = makeOptionalRangeCard({
  title: 'Estimated Property Value',
  displayNamePrefix: 'EstimatedPropertyValue',
  useApi: usePropertyValue,
  formatInputDisplay: formatDollarWithCommas,
  minLabel: 'Minimum Value',
  maxLabel: 'Maximum Value',
  minPlaceholder: '$',
  maxPlaceholder: '$',
})

const TaxValueCard = makeOptionalRangeCard({
  title: 'Tax Value',
  displayNamePrefix: 'TaxValue',
  useApi: useTaxValue,
  formatInputDisplay: formatDollarWithCommas,
  minLabel: 'Minimum Value',
  maxLabel: 'Maximum Value',
  minPlaceholder: '$',
  maxPlaceholder: '$',
})
// #endregion

// #region Switch Cards
const FreeAndClearCard = makeWiredSwitchCard({
  title: 'Free & Clear',
  displayNamePrefix: 'FreeAndClear',
  useApi: useIsFreeAndClearFlag,
})

const UnknownCard = makeWiredSwitchCard({
  title: 'Unknown',
  displayNamePrefix: 'Unknown',
  useApi: useIsUnknownEquityFlag,
})
// #endregion

// #region UpsideDownCard
const UpsideDownCard = () => {
  const api = useUpsideDownValue()
  const [isOpen, setIsOpen] = React.useState(isNumber(api.value.minAmount))
  const [minAmount, setMinAmount] = React.useState(api.value.minAmount ?? '')

  // update value when the api value changes
  React.useEffect(() => {
    setMinAmount(api.value.minAmount ?? '')
  }, [api.value.minAmount])

  return (
    <Card
      variant='default'
      colorScheme='card.bg.1'
      size={mbp({
        mobSm: 'xxs-locked',
        tabSm: 'sm-locked',
      })}
      align='stretch'
      justify='center'
      bg={isOpen ? 'neutral.200' : undefined}
      h='full'
      onClick={ev => {
        if (isOpen) return
        const isChakraSwitchClick = ChakraLib.isTargetChakraSwitch(ev.target)
        if (isChakraSwitchClick) return
        setIsOpen(true)
      }}
    >
      <CardHeader>
        <HStack>
          <CardSecondaryTitle justifySelf='flex-start' flex='1'>
            Upside Down
          </CardSecondaryTitle>
          <Switch
            colorScheme='default'
            isChecked={isOpen}
            onChange={() => {
              setIsOpen(!isOpen)
              const willOpen = !isOpen
              if (willOpen) {
                api.apply({ minAmount: 0 })
                setMinAmount(0)
              } else {
                api.apply({ minAmount: null })
              }
            }}
          />
        </HStack>
      </CardHeader>

      {isOpen && (
        <FormControl mt={2}>
          <FormLabel>
            Min Upside Down
          </FormLabel>
          <NumberInput
            keepWithinRange
            precision={0}
            format={formatDollarWithCommas}
            onBlur={() => api.apply({
              minAmount: isString(minAmount)
                ? NumberLib.fromStringSafe(minAmount)
                : minAmount,
            })}
            value={minAmount}
            onChange={setMinAmount}
          >
            <InputGroup>
              <NumberInputField />
              {minAmount && (
                <InputRightElement
                  role='button'
                  px={1}
                  onClick={() => {
                    setMinAmount('')
                    api.apply({ minAmount: null })
                  }}
                >
                  <CloseIcon />
                </InputRightElement>
              )}
            </InputGroup>
          </NumberInput>
        </FormControl>
      )}
    </Card>
  )
}
// #endregion
