import { createIcon } from '@chakra-ui/react'

const RegularCircleInfoIcon = createIcon({
  displayName: 'RegularCircleInfoIcon',
  viewBox: '0 0 17 17',
  path: (
    <g fill='none'>
      <g clipPath='url(#clip0_388_129584)'>
        <path
          d='M8.65007 15.5658C12.332 15.5658 15.3167 12.581 15.3167 8.89909C15.3167 5.21719 12.332 2.23242 8.65007 2.23242C4.96817 2.23242 1.9834 5.21719 1.9834 8.89909C1.9834 12.581 4.96817 15.5658 8.65007 15.5658Z'
          stroke='currentColor'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.65039 11.5661V8.89941'
          stroke='currentColor'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.65039 6.23242H8.65706'
          stroke='currentColor'
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_388_129584'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.650391 0.899414)'
          />
        </clipPath>
      </defs>
    </g>
  ),
})

export default RegularCircleInfoIcon
