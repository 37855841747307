import { BoxProps, Image, Text } from '@chakra-ui/react'
import runningDogPng from 'presentation/assets/img/3d/running-dog.png'
import { Card } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const EmptyCard = (props: BoxProps) => (
  <Card
    borderRadius='2'
    colorScheme='card.bg.1'
    px='0'
    p='2'
    pb='5'
    alignItems='center'
    mt='1'
    mb='0'
    {...props}
  >
    <Image src={runningDogPng} w='180px' h='162px' />
    <Text mt='1' textStyle={mbp({ mobSm: 'h4-b', tabSm: 'h3-b' })} color='graystrong.500' textAlign='center'>
      There’s nothing to show here
    </Text>
    <Text mt='1' textStyle={mbp({ mobSm: 'bodyL', mob: 'bodyXL' })} color='graystrong.200' textAlign='center'>
      No previous records are available at the moment.
    </Text>
  </Card>
)
