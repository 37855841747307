import { Flex, Text } from '@chakra-ui/react'
import { TriangleExclamationOutlineIcon } from 'presentation/components/Icons'
import { CompsFilterCardErrorProps } from 'presentation/screens/CompsScreen/components/common/CompsFilterCardError/CompsFilterCardError.props'

export const CompsFilterCardError = (props: CompsFilterCardErrorProps) => {
  const { error, ...flexProps } = props
  return (
    <Flex gap={1} {...flexProps}>
      <TriangleExclamationOutlineIcon boxSize={3} color='negative.500' />
      <Text>{error}</Text>
    </Flex>
  )
}
