import { OpenPlanDowngradeAlertParams, openPlanDowngradeAlert } from './openPlanDowngradeAlert'

export const handleOpenPlanDowngradeAlert = ({
  skipAmount,
  billingInterval,
  planName,
  onProceed,
}: OpenPlanDowngradeAlertParams) => {
  openPlanDowngradeAlert({
    skipAmount,
    billingInterval,
    planName,
    onProceed,
  })
}
