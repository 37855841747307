import { breadcrumbAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mergeDeep } from 'remeda'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  breadcrumbAnatomy.keys,
)

const baseStyle = {}

const variants = {
  default: definePartsStyle(props => ({
    link: mergeDeep(colorScheme[props.colorScheme as BreadcrumbColorScheme]?.()?.link, {
      'display': 'inline-flex',
      'alignItems': 'center',
      'textTransform': 'uppercase',

      '&[aria-current="page"]': {
        textTransform: 'none',
      },
    }),
    item: mergeDeep(colorScheme[props.colorScheme as BreadcrumbColorScheme]?.()?.item, {
    }),
    container: mergeDeep(colorScheme[props.colorScheme as BreadcrumbColorScheme]?.()?.container, {
    }),
    separator: mergeDeep(colorScheme[props.colorScheme as BreadcrumbColorScheme]?.()?.separator, {
    }),
  })),
}
export type BreadcrumbVariant = keyof typeof variants
export const breadcrumbVariants = Object.keys(variants) as BreadcrumbVariant[]

const colorScheme = {
  default: definePartsStyle(() => ({
    link: {
      'color': 'accent.blue-to-gray',

      '&[aria-current="page"]': {
        color: 'graystrong.200',
      },
    },
    item: {},
    container: {},
    separator: {
      color: 'graystrong.200',
    },
  })),
} as const

export type BreadcrumbColorScheme = keyof typeof colorScheme
export const breadcrumbColorSchemes = Object.keys(colorScheme) as BreadcrumbColorScheme[]

const sizes = {
  default: definePartsStyle(() => ({
    link: {
      ...textStyles.bodyMFat,
    },
    item: {},
    container: {},
    separator: {
      ...textStyles.bodyMFat,
    },
  })),
} as const
export type BreadcrumbSize = keyof typeof sizes
export const breadcrumbSizes = Object.keys(sizes) as BreadcrumbSize[]

export const breadcrumbTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'default',
    colorScheme: 'default',
    variant: 'default',
  },
})
