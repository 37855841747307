import { CMA } from 'features/CMA/CMA.domain'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { ActiveCMAEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'

export type ListInfoStateParams = {
  type: ActiveCMAEntriesTab
}

export type UseListInfoState = (params: ListInfoStateParams) =>
  | { status: 'loading' }
  | { status: 'empty' }
  | { status: 'loaded', data: CMA.ListInfo }

export const useListInfoState: UseListInfoState = ({ type }) =>
  useCMAStore(store => {
    const reportState = store.local.report

    if (!checkStateHasReportData(reportState))
      return { status: 'loading' as const }

    const listInfo = type === 'SALES'
      ? reportState.data.salesListInfo
      : reportState.data.rentalsListInfo

    if (listInfo.comps.length === 0)
      return { status: 'empty' as const }

    return {
      status: 'loaded' as const,
      data: listInfo,
    }
  })
