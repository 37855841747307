import { Box, Button, Drawer, DrawerContent, Flex, IconButton } from '@chakra-ui/react'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { ArrowLeftIcon, ResetIcon } from 'presentation/components/Icons'
import { TitleBar } from 'presentation/components/TitleBar/TitleBar'
import { Card } from 'presentation/components/molecules/Card'
import { CompsFilterPanelDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/CompsFilterPanel.props'

export const CompsFilterFullscreenPanel = ({ children, ...props }: CompsFilterPanelDumbProps) => {
  const isFiltering = useCMAStore(state =>
    !checkStateHasReportData(state.local.report)
    || state.local.report.status === 'filtering',
  )

  const handleOnClose = () => {
    props.closePanel()
  }

  const handleOnClearAllFilters = () => {
    props.clearAllFilters()
  }

  return (
    <Drawer
      onClose={handleOnClose}
      isOpen={props.isPanelOpen}
      size='full'
    >
      <DrawerContent>
        <Flex
          display='flex'
          flexDirection='column'
          minH={0}
          h='var(--app-height)'
        >
          <TitleBar
            h={6.5}
            leftJsx={(
              <IconButton
                aria-label='Back'
                icon={<ArrowLeftIcon />}
                variant='icon-ghost'
                colorScheme='ondark'
                size='md'
                onClick={handleOnClose}
              />
            )}
            title='All Filters'
          />
          <Card
            flex='1 1 100%'
            size='xs-locked'
            colorScheme='modal.highlight'
            borderRadius={0}
            px={0}
            pb={0}
            minH={0}
          >
            {/** Filters */}
            <Box
              flex='1 1 0'
              display='flex'
              flexDirection='column'
              gap={4}
              px={1}
              pb={3}
              overflow='auto'
              minH={0}
            >
              {children}
            </Box>

            {/**  Footer Actions  */}
            <Flex
              boxShadow='0px -4px 10px 0px rgba(0, 0, 0, 0.25)'
              p={1}
              justifyContent='space-between'
            >
              <Button
                colorScheme='neutral'
                variant='ghost'
                size='md'
                textTransform='uppercase'
                onClick={handleOnClearAllFilters}
                leftIcon={<ResetIcon boxSize={3} />}
              >
                Clear All
              </Button>
              <Button
                colorScheme='positive'
                variant='solid'
                size='md'
                textTransform='uppercase'
                onClick={props.closePanel}
                isLoading={isFiltering}
                loadingText='Filtering'
              >
                See
                {' '}
                {props.resultCount}
                {' '}
                Results
              </Button>
            </Flex>
          </Card>
        </Flex>
      </DrawerContent>
    </Drawer>
  )
}
