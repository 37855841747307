import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'

export const toastTheme = {
  parts: [
    'container',
    'icon',
    'title',
    'message',
  ],
  baseStyle: {
    container: {
      display: 'flex',
      maxW: 'min(912px, calc(100vw - 48px))',
      minW: 'min(472px, calc(100vw - 48px))',
      alignItems: 'center',
      p: '2',
      borderWidth: '0.25',
      borderRadius: '2',
      boxShadow: 'high-float',
      gap: 1,
    },
    icon: {
      fontSize: 3,
    },
    title: {
      ...textStyles['body-h-b'],
    },
    message: {
      ...textStyles['h4-b'],
      mt: 0.5,
      color: 'graystrong.400',
    },
  },
  variants: {

    info: {
      container: {
        bg: 'accent.hover',
        borderColor: 'neutral.500',
      },
      icon: {
        color: 'neutral.500',
      },
      title: {
        color: 'neutral.600',
      },
    },

    success: {
      container: {
        bg: 'card.bg.lightgreen',
        borderColor: 'positive.500',
      },
      icon: {
        color: 'positive.500',
      },
      title: {
        color: 'positive.500',
      },
    },

    error: {
      container: {
        bg: 'error.100',
        borderColor: 'negative.500',
      },
      icon: {
        color: 'negative.500',
      },
      title: {
        color: 'negative.600',
      },
    },

  },
}
