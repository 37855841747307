import { PropertyDetailReportModalProps } from 'features/PropertyDetails/infra/react/PropertyDetailReportModal'
import { AddPaymentModalProps } from 'presentation/main/globalModals/AddPaymentModal/AddPaymentModal.props'
import { ErrorModalProps } from 'presentation/main/globalModals/ErrorModal/ErrorModal.types'
import { FailedInvoiceModalProps } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { PaymentFailedModalProps } from 'presentation/main/globalModals/PaymentFailedModal/PaymentFailedModal.types'
import { SuccessModalProps } from 'presentation/main/globalModals/SuccessModal/SuccessModal.types'
import { TypeToConfirmModalProps } from 'presentation/main/globalModals/TypeToConfirmModal/TypeToConfirmModal.types'
import { UpdatePaymentModalProps } from 'presentation/main/globalModals/UpdatePaymentModal/UpdatePaymentModal.types'
import { ResetPasswordSuccessDialogProps } from 'presentation/screens/AuthScreens/ResetPasswordScreen/ResetPasswordSuccessfulDialog/ResetPasswordSuccessDialog'
import { ChoosePlanModalProps } from 'presentation/screens/Billing/components/ChoosePlanFlow/ChoosePlanModal.types'
import { LegacyPlanModalProps } from 'presentation/screens/Billing/components/LegacyPlanModal/LegacyPlanModal.types'
import { WalletBalanceEntryModalProps } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/WalletBalanceEntryModal.types'
import { WalletPaymentDetailsModalProps } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/WalletPaymentDetailsModal.types'
import { ComparativePropertyModalProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal.props'
import { InviteMembersModalProps } from 'presentation/screens/MembersScreen/components/InviteMembersModal/InviteMembersModal.types'
import { MemberReactivateModalProps } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberReactivateModal'
import { UserInfoModalProps } from 'presentation/screens/MembersScreen/components/UserInfoModal'
import { PlanDowngradeAlertProps } from 'presentation/screens/Plans/PlanComparisonV2/PlanDowngradeAlert/PlanDowngradeAlert'
import { FC } from 'react'
import { createModalsManager } from './createModalsManager'

type ModalsRecord = {
  PAYMENT_FAILED_MODAL: FC<PaymentFailedModalProps>
  ADD_PAYMENT_MODAL: FC<AddPaymentModalProps>
  ERROR_MODAL: FC<ErrorModalProps>
  SUCCESS_MODAL: FC<SuccessModalProps>
  UPDATE_PAYMENT_MODAL: FC<UpdatePaymentModalProps>
  WALLET_BALANCE_ENTRY_MODAL: FC<WalletBalanceEntryModalProps>
  WALLET_PAYMENT_DETAILS_MODAL: FC<WalletPaymentDetailsModalProps>
  LEGACY_PLAN_MODAL: FC<LegacyPlanModalProps>
  CHOOSE_PLAN_MODAL: FC<ChoosePlanModalProps>
  FAILED_INVOICE_MODAL: FC<FailedInvoiceModalProps>
  RESTORE_FAILED_INVOICE_MODAL: FC<FailedInvoiceModalProps>
  TYPE_TO_CONFIRM_MODAL: FC<TypeToConfirmModalProps>
  INVITE_MEMBERS_MODAL: FC<InviteMembersModalProps>
  USER_INFO_MODAL: FC<UserInfoModalProps>
  MEMBER_REACTIVATE_MODAL: FC<MemberReactivateModalProps>
  COMPARATIVE_PROPERTY_MODAL: FC<ComparativePropertyModalProps>
  PASSWORD_RESET_SUCCESS_DIALOG: FC<ResetPasswordSuccessDialogProps>
  PLAN_DOWNGRADE_ALERT_MODAL: FC<PlanDowngradeAlertProps>
  PROPERTY_DETAIL_REPORT_MODAL: FC<PropertyDetailReportModalProps>
}

export const modals = createModalsManager<ModalsRecord>()
