import { Context } from 'effect'
import { GetLists } from 'features/ListBuilderV2/domain/repository/GetLists'
import SaveNearbyBuyersToList from 'features/ListBuilderV2/domain/repository/SaveNearbyBuyersToList'
import GetBuyerDeals from 'features/NearbyBuyers/repository/GetBuyerDeals'
import GetNearbyBuyers from 'features/NearbyBuyers/repository/GetNearbyBuyers'

class NearbyBuyersRepo extends Context.Tag('NearbyBuyersRepo')<
  NearbyBuyersRepo,
  {
    readonly getNearbyBuyers: GetNearbyBuyers
    readonly saveNearbyBuyersToList: SaveNearbyBuyersToList
    readonly getLists: GetLists
    readonly getBuyerDeals: GetBuyerDeals
  }
>() {}

namespace NearbyBuyersRepo {
  export type Shape = Context.Tag.Service<NearbyBuyersRepo>
}

export default NearbyBuyersRepo
