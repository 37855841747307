import { useSelector } from '@xstate/react'
import NearbyBuyerMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerMachine'
import { useCallback } from 'react'
import { MapRef } from 'react-map-gl'

namespace NearbyBuyerHooks {
  // #region Core
  export const useSetup = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useCallback((element: HTMLElement) => {
      actorRef.send({ type: 'setup', getElement: () => element })
    }, [actorRef])
  // #endregion

  // #region State
  export const useIsHighlighted = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.matches({
      Initialized: {
        Hover: 'Highlighted',
      },
    }))
  // #endregion

  // #region Buyer Data
  export const useBuyer = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.buyer)

  export const useDisplayedBuyerDeals = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.displayedDeals)

  export const useDisplayedProperties = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.displayedProperties)

  export const useDealsGeographicInfo = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.buyer.buyerHistory.dealsGeographicInfo)
  // #endregion

  // #region Buyer History Actions
  export const useShouldDisplayPropertiesOutsideLocation = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.shouldDisplayPropertiesOutsideLocation)

  export const useToggleDisplayPropertiesOutsideLocation = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useCallback(() => {
      actorRef.send({ type: 'toggle-display-properties-outside-location' })
    }, [actorRef])

  export const useIsExpanded = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.matches({
      Initialized: {
        BuyerHistory: {
          Collapsible: 'Expanded',
        },
      },
    }))

  export const useToggleExpand = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useCallback(() => {
      actorRef.send({ type: 'responsive-toggle-buyer-history' })
    }, [actorRef])

  export const useIsBuyerHistoryLoading = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useSelector(actorRef, snap => !snap.matches({
      Initialized: {
        BuyerHistory: {
          DataLoad: 'Success',
        },
      },
    }))
  // #endregion

  // #region Map
  export const useMapMountEvents = (actorRef: NearbyBuyerMachine.ActorRef) => {
    const onMount = useCallback((mapRef: MapRef) => {
      actorRef.send({
        type: 'map-mounted',
        mapRef,
      })
    }, [])

    const onUnmount = useCallback(() => {
      actorRef.send({ type: 'map-unmounted' })
    }, [])

    return {
      onMount,
      onUnmount,
    }
  }
  // #endregion

  // #region Mobile Buyer History Modal
  export const useOpenMobileBuyerHistoryModal = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useCallback(() => {
      actorRef.send({ type: 'open-mobile-buyer-history-modal' })
    }, [actorRef])

  export const useCloseMobileBuyerHistoryModal = (actorRef: NearbyBuyerMachine.ActorRef) =>
    useCallback(() => {
      actorRef.send({ type: 'close-mobile-buyer-history-modal' })
    }, [actorRef])
  // #endregion
}

export default NearbyBuyerHooks
