import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'

export const getUserInputOrOriginalData = <TResult>(
  selector: (state: Domain.UserInputData) => TResult,
) => (
  property: Domain.Property,
) => {
  const userInputData = selector(property.userInputData)

  const originalData = property.status === 'with-details'
    ? selector(property)
    : null

  return userInputData ?? originalData
}

export const EMPTY_PROPERTY_BUILDING: Domain.Building = {
  bathroomsCount: null,
  bedroomsCount: null,
  garageSpacesCount: null,
  roomsCount: null,
  fireplacesCount: null,
  storiesCount: null,

  /** @TODOIMPORTANT change to boolean */
  hasPool: null,

  buildingAreaSqft: null,
  livingAreaSqft: null,
  basementAreaSqft: null,
  garageAreaSqft: null,

  yearBuilt: null,
  basementType: null,
  heatingType: null,
  heatingFuelType: null,
}
