import { createIcon } from '@chakra-ui/react'

export const StrikeIcon = createIcon({
  displayName: 'StrikeIcon',
  viewBox: '0 0 104 9',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M30.0715 3.87704C38.5463 3.87704 46.9625 4.01405 55.426 4.7967C64.2882 5.61622 73.1129 5.71635 81.9915 5.71635C84.9198 5.71635 91.7992 9.00934 90.771 5.30761C89.9817 2.46565 78.1644 3.04518 77.072 2.95739C60.1677 1.5989 43.2129 2.03774 26.2872 2.03774C17.76 2.03774 9.23282 2.03774 0.705621 2.03774C-0.228606 2.03774 14.8711 2.02012 17.5077 2.03774C37.7458 2.173 98.4456 3.87704 78.2073 3.87704C74.7762 3.87704 71.3452 3.87704 67.9141 3.87704C67.1124 3.87704 55.2121 3.87704 59.8915 3.87704C67.4348 3.87704 74.978 3.87704 82.5213 3.87704C88.9082 3.87704 107.086 7.52754 101.67 2.95739C97.2392 -0.781135 85.9967 2.11956 81.2347 2.44647C69.6149 3.24417 58.0378 4.89077 46.4195 5.71635' stroke='#FF4341' strokeWidth='2' strokeLinecap='round' />
    </g>
  ),
})
