import { Schema as S } from '@effect/schema'
import { Effect, Match, RequestResolver, pipe } from 'effect'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { POSTSavePropertyListSchema } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SaveListResolver/POSTSavePropertyListSchema'
import { POSTSavePropertyListType } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/SaveListResolver/POSTSavePropertyListType'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { restClient } from 'presentation/libs/client'

const toDomain = (
  listName: string,
  response: POSTSavePropertyListSchema,
): MarketingList =>
  MarketingList.make({
    id: MarketingListId.make(response.marketingListId),
    type: POSTSavePropertyListType.toDomain(response.listType),
    name: listName,
    createdAt: response.createdAt,
    updatedAt: response.updatedAt,
    criteria: CriteriaSchema.toDomain(
      Match.value(response.listType).pipe(
        Match.when('seller', (): ListType => 'property-lists'),
        Match.when('cashBuyer', () => { throw new Error('Not Implemented') }),
        Match.when('privateLender', () => { throw new Error('Not Implemented') }),
        Match.exhaustive,
      ),
      response.params.builderParams,
    ),
  })

const SaveListResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.SaveList) => pipe(
    Effect.sync(() => CriteriaSchema.encode(request.criteria)),
    Effect.flatMap(encoded =>
      Effect.promise(async () => await restClient
        .post('parcels/v1/listbuilder/seller/export', {
          json: encoded,
          searchParams: {
            name: request.listName,
          },
        }).json()),
    ),
    Effect.map(raw => S.decodeUnknownSync(POSTSavePropertyListSchema)(raw)),
    Effect.map(decoded => toDomain(request.listName, decoded)),
  ),
)

export default SaveListResolver
