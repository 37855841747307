import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { GETDebouncedListInfoById } from 'features/common/infra/GETDebouncedListInfo'

const request = async (
  listId: MarketingListId,
) =>
  await GETDebouncedListInfoById.request(listId)

export const GETBatchSkiptraceStatsByListId = {
  request,
}
