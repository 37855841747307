import { StyleFunctionProps } from '@chakra-ui/react'
import { merge } from 'lodash/fp'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { getRecKey } from 'utils/getRecKey'

export const formLabelTheme = {
  baseStyle: (props: StyleFunctionProps) => merge(
    {
      ...textStyles.bodyMFat,
      mx: '0',
      mt: '0',
      mb: '1',
    },
    getRecKey(props.colorScheme)({
      neutral: {
        color: 'neutral.500',
      },
      negative: {
        color: 'negative.500',
      },
    }),
  ),
  defaultProps: {
    colorScheme: 'neutral',
  },
}
