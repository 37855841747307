import { Text } from '@chakra-ui/react'
import { UpcomingPaymentRow__UpcomingPaymentFragment } from '__generated__/graphql'
import dayjs from 'dayjs'
import { BasePaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/BasePaymentRow/BasePaymentRow'
import { FC } from 'react'
import { formatPenniesOptionalDecUsdOr } from 'utils/dataAdapter'

export const UpcomingPaymentRow: FC<{
  subscription: UpcomingPaymentRow__UpcomingPaymentFragment
}> = props => {
  const data = props.subscription

  if (!data.upcomingInvoice) return null

  const amount = data.upcomingInvoice.totalAmount
  const date = data.upcomingInvoice?.paymentDate

  return (
    <BasePaymentRow
      dateString={date}
      createdById={data.createdBy?.id}
      amount={(
        amount
      )}
      description={(
        <>
          Your Propelio subscription of
          {' '}
          {
            formatPenniesOptionalDecUsdOr('unknown amount')(amount)
          }
          {' '}
          will renew on
          {' '}
          {dayjs(date).format('M/D/YYYY')}
          .
        </>
      )}
      status={(
        <Text color='teal.500' textStyle='caption-h-b'>Next Invoice</Text>
      )}
    />
  )
}
