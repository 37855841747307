import { Box, HStack, Spacer, Text } from '@chakra-ui/react'
import { LIST_PREVIEW_SUBSCREEN_KEYS } from 'presentation/screens/ListPreviewScreen/ListPreviewScreen.const'
import { PreviewResultsCountText } from 'presentation/screens/ListPreviewScreen/components/ResultsCountText'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Link, useLocation } from 'react-router-dom'

export const PreviewBar = () => {
  const location = useLocation()

  return (
    <Box
      h='68px'
      borderTopRadius={2}
      bg='accent.hover'
      p={mbp({ mobSm: 1, mob: 2 })}
      boxShadow='vscroll-fade-bottom'
      as={Link}
      to={location.pathname}
      state={{
        subscreen: LIST_PREVIEW_SUBSCREEN_KEYS.LIST,
      }}
      display='block'
    >
      <HStack h='full' align='center'>
        <Text
          textStyle='bodyXLFat'
          color='accent.blue-text'
        >
          Preview List
        </Text>
        <Spacer />
        <PreviewResultsCountText
          textStyle='bodyM'
          color='graystrong.200'
        />
      </HStack>
    </Box>
  )
}
