import { Array, Effect, Option } from 'effect'
import { GetLists } from 'features/ListBuilderV2/domain/repository/GetLists'
import SaveNearbyBuyersToList from 'features/ListBuilderV2/domain/repository/SaveNearbyBuyersToList'
import NearbyBuyerListEdgeMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerListEdgeMachine'
import NearbyBuyersRepo from 'features/NearbyBuyers/repository/NearbyBuyersRepo'
import NearbyBuyersRoutes from 'features/NearbyBuyers/routes/NearbyBuyersRoutes'
import Connection from 'libs/Connection'
import { EffectLib } from 'libs/Effect'
import { ErrorLib } from 'libs/errors'
import { toast } from 'presentation/components/Toast'
import { ToastParams } from 'presentation/components/Toast/Toast.types'
import { DEFAULT_TOAST_ERROR_MESSAGE } from 'presentation/const/toast.const'
import AppCache from 'services/AppCache'
import AppRouter from 'services/AppRouter'
import { ActorRefFrom, assign, fromPromise, setup, SnapshotFrom } from 'xstate'

namespace SaveNearbyBuyersToListMachine {
  export type Context = {
    listsResult: Option.Option<{
      pageInfo: Connection.PageInfo
      edgeActorRefs: NearbyBuyerListEdgeMachine.ActorRef[]
    }>
  }
  export type Events =
    | { type: 'load-more-lists' }
    | { type: 'save-to-list' }

  export const make = () => Effect.gen(function * () {
    const runSync = yield * EffectLib.getRunSyncFromRuntime<AppRouter>()

    const machine = setup({
      types: {} as {
        context: Context
        events: Events
      },
      actions: {
        notify: (_, params: ToastParams) => {
          toast(params)
        },
        prepareCleanup: ({ self }) => {
          const sub = self.subscribe({
            complete: () => {
              NearbyBuyersRoutes.navigateToCloseModal('SAVE_TO_LIST').pipe(
                ErrorLib.tapCauseReporter,
                runSync,
              )
              sub.unsubscribe()
            },
          })
        },
        openSaveToListModal: () => {
          NearbyBuyersRoutes.navigateToOpenModal('SAVE_TO_LIST').pipe(
            ErrorLib.tapCauseReporter,
            runSync,
          )
        },
      },
      actors: {
        saveNearbyBuyersToList: yield * createSaveNearbyBuyersToListActor,
        getLists: yield * createGetListsActor,
        listEdgeMachine: yield * NearbyBuyerListEdgeMachine.make(),
      },
      guards: {
        canLoadMoreLists: ({ context }) => context.listsResult.pipe(
          Option.map(result => result.pageInfo.hasNextPage),
          Option.getOrElse(() => true),
        ),
        canSaveToList: ({ context }) => context.listsResult.pipe(
          Option.map(result => result.edgeActorRefs),
          Option.map(Array.some(ref => ref.getSnapshot().context.isChecked)),
          Option.getOrElse(() => false),
        ),
        cannotSaveToList: ({ context }) => !context.listsResult.pipe(
          Option.map(result => result.edgeActorRefs),
          Option.map(Array.some(ref => ref.getSnapshot().context.isChecked)),
          Option.getOrElse(() => false),
        ),
      },
    }).createMachine({
      /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOgCcBXffAqEgGwHt0IBae3WAF1gebY7dYrAA5h8EWgGIIjQiQIA3RgGswJGFwAynHgG0ADAF1EoEY1i4uuOaZAAPRAGYnAdhIAOAIwAmACxOAQCcTl4eHgA0IACezl4ArCReBn4+rgYAbB4Z8R5uTgC+BVFoWHiEpJTUtHws7Lq8THWCPKLikvhQUmBkZIxkJCL06FwAZv2oGmDaDYYmSCDmlta2C44ILh4kfq5BXl47BiFeu65RsQh+GU4k8Qb3QR53Hj7hGUUlGDgExORUNJ1agIGkD6kJWLAKJhMHBYFImmxUP0wGD9MY7EsrDZ8HZ1k4fEFbnkfBkgvEMr4SZEYogfK9PC4-GEnEFdoEPB8QKVvhU-tVAbB0IoUVxGKiFBB6GApILhaxRai5hiLFjVqB1l4yUkPK4rhkDD47vE-C9zogPEcSCkTqkXk8dj5Odzyr8qgC6LKRWKWiRZbR5d7dDI5OolKp1J6AHJgXBQbAAI36sAAKowdNwlQtMSscWs4oTWXsso94j4DK5DWaEMl4jdjU8LV5i14nBzilyvi7Kv8ap6A+K-Z1+y1ur1+oNhmMJr6hWBo7GE0nU+muJmzCqc7jEAkDCR0k4DE8Mq47hX4lXSV4SGSPKkHQagn54kV2-hGBA4HZnT8iMrltitwQVhfBuQsmz8IIyz8Q8MgyKtgMNa8gmQukm38Cln3bb9eTdWg-1VXN1UQVgT2vXZwMglIYLgmkEHcPJAgOVlMmPbw20+Mofz5d1QRaeAsw3AC80uK17jEk8-CfVxAhoi4DivWDj1LZI3FZA4nU7LjcMBBFUUafg9LaCQ8IE-81QcRBS1EsTy2NKSZKrUIfAZQICQSeJdRCDTOJwnsdIMvjeIaCEoRhWB+PXMzCIshAPMSQJWycWtGIyHwnEcpwMiSWDXAyKTSyfE5vJ5V0-LoXTAt0wgF0TMhhB6PoyHwzdhPJRIPDJPZctcTzHirAItkU3VXB1Q5cswjiSu7fkPVnYddGaoSiKAtIyKLCCoOovwLxSPcutyVIMNJYqu243s5oVH1cElMBFvM9YyytMs8lPTqW0yqs8gUlJDSczZYJOrSypnOVLt0EH-TB7g7ui9ZoMJXKUggg9Uv2bbaN2LYesPE8RtJQ0-EB3luEYEQxAgGHAJcej4hCUldjLDykqrNGbnSL70niMJkMwoogA */
      context: {
        listsResult: Option.none(),
      },
      initial: 'running',
      entry: [
        { type: 'prepareCleanup' },
        { type: 'openSaveToListModal' },
      ],
      states: {
        running: {
          type: 'parallel',
          states: {
            LoadLists: {
              guard: { type: 'canLoadMoreLists' },
              initial: 'LoadListsPending',
              states: {
                LoadListsPending: {
                  invoke: {
                    id: 'getLists',
                    src: 'getLists',
                    input: ({ context }) => {
                      const DEFAULT_LIMIT = 50
                      const baseInput = { first: DEFAULT_LIMIT }

                      const lastCursor = context.listsResult.pipe(
                        Option.flatMap(result => Array.last(result.edgeActorRefs)),
                        Option.map(machine => machine.getSnapshot().context.edge.cursor),
                        Option.getOrNull,
                      )

                      const hasNextPage = context.listsResult.pipe(
                        Option.map(result => result.pageInfo.hasNextPage),
                        Option.getOrElse(() => false),
                      )

                      if (!hasNextPage || lastCursor === null)
                        return baseInput

                      return { ...baseInput, afterCursor: lastCursor }
                    },
                    onDone: {
                      target: 'LoadListsSuccess',
                      actions: [
                        assign({
                          listsResult: ({ context, spawn, event: { output: newResult } }) => {
                            const newActorRefs = newResult.edges.map(edge =>
                              spawn('listEdgeMachine', { id: edge.node.id, input: { edge } }))

                            return context.listsResult.pipe(
                              Option.map(oldResult => ({
                                edgeActorRefs: [...oldResult.edgeActorRefs, ...newActorRefs],
                                pageInfo: newResult.pageInfo,
                              })),
                              Option.orElse(() => Option.some({
                                edgeActorRefs: newActorRefs,
                                pageInfo: newResult.pageInfo,
                              })),
                            )
                          },
                        }),
                      ],
                    },
                    onError: {
                      target: 'LoadListsError',
                    },
                  },
                },
                LoadListsSuccess: {
                  on: {
                    'load-more-lists': {
                      target: 'LoadListsPending',
                    },
                  },
                },
                LoadListsError: {
                  entry: [
                    {
                      type: 'notify',
                      params: {
                        status: 'error',
                        title: 'Failed to load lists',
                        message: DEFAULT_TOAST_ERROR_MESSAGE,
                      },
                    },
                  ],
                },
              },
            },
            SaveToList: {
              initial: 'Idle',
              states: {
                Idle: {
                  on: {
                    'save-to-list': [{
                      guard: { type: 'canSaveToList' },
                      target: 'SaveToListPending',
                    }, {
                      guard: { type: 'cannotSaveToList' },
                      actions: {
                        type: 'notify',
                        params: {
                          status: 'error',
                          title: 'No list selected',
                          message: 'Please select at least one list to save',
                        },
                      },
                    }],
                  },
                },
                SaveToListPending: {
                  invoke: {
                    id: 'saveNearbyBuyersToList',
                    src: 'saveNearbyBuyersToList',
                    input: ({ context }) => {
                      const listIds = context.listsResult.pipe(
                        Option.map(result => result.edgeActorRefs),
                        Option.map(Array.filter(ref => ref.getSnapshot().context.isChecked)),
                        Option.map(Array.map(ref => ref.getSnapshot().context.edge.node.id)),
                        Option.getOrThrowWith(() => new Error('No list IDs to save to')),
                      )

                      return { listIds }
                    },
                    onDone: {
                      target: '#stopped',
                      actions: {
                        type: 'notify',
                        params: {
                          status: 'success',
                          title: 'That was a success!',
                          message: 'Buyers has been successfully saved.',
                        },
                      },
                    },
                    onError: {
                      target: 'Idle',
                      actions: [
                        {
                          type: 'notify',
                          params: {
                            status: 'error',
                            title: 'Failed to Save Buyers to a List',
                            message: DEFAULT_TOAST_ERROR_MESSAGE,
                          },
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
        Stopped: {
          id: 'stopped',
          type: 'final',
        },
      },
    })

    return machine
  })

  export type ActorRef = ActorRefFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type Snapshot = SnapshotFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  const createSaveNearbyBuyersToListActor = Effect.gen(function * (_) {
    const repo = yield * NearbyBuyersRepo
    const runPromise = yield * EffectLib.getRunPromiseFromRuntime()

    return fromPromise(async (params: { input: SaveNearbyBuyersToList.Params }) =>
      repo.saveNearbyBuyersToList(params.input).pipe(
        ErrorLib.tapCauseReporter,
        runPromise,
      ))
  })

  const createGetListsActor = Effect.gen(function * (_) {
    const repo = yield * NearbyBuyersRepo
    const runPromise = yield * EffectLib.getRunPromiseFromRuntime<AppCache>()

    return fromPromise(async (params: { input: GetLists.Params }) =>
      repo.getLists(params.input).pipe(
        ErrorLib.tapCauseReporter,
        runPromise,
      ))
  })
}

export default SaveNearbyBuyersToListMachine
