import { Avatar, Flex, Text } from '@chakra-ui/react'
import classNames from 'classnames'
import { CardButton, CardFooter, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Tooltip } from 'presentation/components/Tooltip'
import { Container, ContainerProps } from 'presentation/screens/MembersScreen/components/MemberCard/common'
import { memberStatusToAnimationClass } from 'presentation/screens/MembersScreen/components/MemberCard/MemberCard.utils'
import { PendingMemberCardViewModel } from 'presentation/screens/MembersScreen/components/MemberCard/PendingMemberCard.viewModel'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export type PendingMemberCardProps = ContainerProps & PendingMemberCardViewModel

export const PendingMemberCard: FC<PendingMemberCardProps> = props => {
  const { viewModel, rest } = separateViewModel(props)

  const memberStatus = viewModel.member.status
  const cardAnimation = memberStatusToAnimationClass(memberStatus)

  const name = [viewModel.member.firstName, viewModel.member.lastName].filter(Boolean).join(' ')

  return (
    <Container
      isMuted={false}
      justifyContent='space-between'
      className={classNames(cardAnimation)}
      {...rest}
    >
      <Flex
        flexDirection='column'
        gap='3'
      >
        <Flex justifyContent='space-between' alignItems='center' h='3'>
          <PendingTag />
        </Flex>
        <Flex
          gap='2'
          alignItems='center'
        >
          <Avatar
            colorScheme='specialsat.700'
            size='lg'
            name={name}
            src={viewModel.member.avatarUrl ?? undefined}
          />

          <Tooltip
            label={viewModel.member.email}
          >
            <CardSecondaryTitle
              display='block' // @NOTE default of this in theme is flex. Flex prevents truncation.
              color='graystrong.400'
              shouldResetSpacing
              isTruncated
            >
              {viewModel.member.email}
            </CardSecondaryTitle>
          </Tooltip>
        </Flex>

        <Pair
          size={mbp({ mobSm: 'sm', mob: 'md' })}
        >
          <PairKey>Position</PairKey>
          <PairValue>{viewModel.member.isOwner ? 'Owner' : 'Team Member'}</PairValue>
        </Pair>
      </Flex>
      <CardFooter>
        <CardButton
          w='full'
          variant='outline'
          colorScheme='positive'
          onClick={viewModel.member.onResendInvite}
          isLoading={viewModel.member.status === 'resending-invite'}
          loadingText='Resending'
        >
          Resend Invite
        </CardButton>
      </CardFooter>
    </Container>
  )
}

export const PendingTag: FC = () => (
  <Text textStyle='bodyMFat' color='teal.500'>Pending</Text>
)

const separateViewModel = ({
  member,
  ...rest
}: PendingMemberCardProps) => ({
  viewModel: {
    member,
  } satisfies PendingMemberCardViewModel,
  rest,
})
