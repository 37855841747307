import { castToFiniteNumber } from 'libs/string/castToFiniteNumber'
import { GRID_OF_8 } from 'presentation/const/layout.const'

/**
 * Converts chakra space value to simple number.
 *
 * It will try to use convert the number using GRID_OF_8 from layout.const.ts,
 * strip px suffix, cast to number or return number as is (if it's of number type),
 * in that order.
 *
 * @NOTE Does NOT support em/rem
 * @example
 *
 * chakraSizeToNumber(1) // 8, because it's in GRID_OF_8
 * chakraSizeToNumber('1') // 8, because it's in GRID_OF_8
 * chakraSizeToNumber('1px') // 1 because it's suffixed with px
 * chakraSizeToNumber(8) // 96, because it's in GRID_OF_8
 * chakraSizeToNumber('8') // 96, because it's in GRID_OF_8
 * chakraSizeToNumber(9) // 9, because it's NOT in GRID_OF_8
 * chakraSizeToNumber('9') // 9, because it's NOT in GRID_OF_8
 * chakraSizeToNumber('9px') // 9, 1 because it's suffixed with px
 */
export const chakraSizeToPx = (value: string | number): number | null => {
  type GridOf8Key = keyof typeof GRID_OF_8
  const stringified = value.toString()
  const fromGridOf8 = GRID_OF_8[stringified as unknown as GridOf8Key]
  const pxStripped = (fromGridOf8 || stringified).replace('px', '')

  return castToFiniteNumber(pxStripped)
}

export const sizeToPx = chakraSizeToPx
