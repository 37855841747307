import { Box, Button, HStack, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import { MarketingList } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import { PropertiesFilterChipProps, useCriteriaToChipsData } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useCriteriaToChipsData'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { useEditListNameModal } from 'presentation/components/EditListNameModal'
import { EditIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Card } from 'presentation/components/molecules/Card'
import { PropertiesFilterChip } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFilterChips'
import { mbp } from 'presentation/utils/mapBreakpoint'
import React from 'react'
import { Link } from 'react-router-dom'

export const ListCard = ({
  list,
}: {
  list: MarketingList
}) => {
  // const [isPopoverOpen, _setIsPopoverOpen] = useState(false)
  const updateNameModal = useEditListNameModal()

  /**
   * @HACK This throttle prevents weird race condition issue between onClick from pills
   *   and onClose. If you remove this and click on pill successively, the popover
   *   will at times will not open.
   */
  // const setIsPopoverOpen = useMemo(() =>
  //   throttle(100, (isOpen: boolean) => {
  //     _setIsPopoverOpen(isOpen)
  //   }, { noTrailing: true }), [])

  const chipsData = useCriteriaToChipsData(list.criteria)

  return (
    <Card
      shadow='primary.w'
      p={2}
      borderRadius={3}
      as={Link}
      to={list.id}
    >
      <HStack
        spacing={1}
        align={{
          base: 'flex-start',
          mob: 'center',
        }}
      >
        {/* <Avatar size='sm' colorScheme='specialsat.500' /> */}
        <Stack
          spacing={mbp({
            mobSm: 0.5,
            mob: 1,
          })}
          direction={{ base: 'column-reverse', mob: 'row' }}
          align={{
            base: 'flex-start',
            mob: 'center',
          }}
        >
          <Tag>{ListType.toHumanReadable(list.type)}</Tag>
          <HStack spacing={1} align='center'>
            <Text
              textStyle='bodyXLFat'
              color='accent.blue-text'
              noOfLines={1}
              title={list.name}
            >
              {list.name}
            </Text>
            <EditIcon
              fontSize={3}
              color='neutral.500'
              role='button'
              onClick={ev => {
                // make sure when edit is clicked the link behavior of parent card is not triggered
                ev.preventDefault()
                updateNameModal.open({ listId: list.id, listName: list.name })
              }}
            />
          </HStack>
        </Stack>
        <Spacer />
        <Button
          variant='ghost'
          colorScheme='neutral'
          size='md'
          as='div'
          mr={{
            base: -1,
            tabSm: 0,
          }}
        >
          View
        </Button>
      </HStack>

      <HStack mt={{ base: 1.5, mob: 1 }} spacing={3}>
        <Pair
          flex={1}
          gap={0.5}
          sx={{ flexDirection: { base: 'column', mob: 'row' } }}
        >
          <PairKey>Date Created</PairKey>
          <PairValue>{DateLib.formatMMDDYYDots(list.createdAt)}</PairValue>
        </Pair>

        <Pair
          flex={1}
          gap={0.5}
          sx={{ flexDirection: { base: 'column', mob: 'row' } }}
        >
          <PairKey>Properties</PairKey>
          <PairValue>{NumberLib.formatComma(list.memberCount)}</PairValue>
        </Pair>
      </HStack>

      {chipsData.length > 0 && (
        <Box lineHeight={4.5} m={-0.5} mt={3}>
          {/* {chipsData.map((chip, index) =>
            index === 0
            ? (
              <ListCriteriaPopover
                key={index}
                isOpen={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
                criteria={list.criteria}
              >
                <FilterChip
                  type={chip.type}
                  iconComponent={ChevronDownIcon}
                  onClick={() => {
                    setIsPopoverOpen(!isPopoverOpen)
                  }}
                >
                  {chip.children}
                </FilterChip>
              </ListCriteriaPopover>
            )
            : (
              <FilterChip
                key={index}
                type={chip.type}
                onClick={() => {
                  setIsPopoverOpen(!isPopoverOpen)
                }}
              >
                {chip.children}
              </FilterChip>
            ))} */}
          {chipsData.map((chip, index) =>
            (
              <FilterChip
                key={index}
                type={chip.type}
              >
                {chip.children}
              </FilterChip>
            ))}
        </Box>
      )}
    </Card>
  )
}

const FilterChip = React.forwardRef(({
  children,
  ...props
}: PropertiesFilterChipProps, ref) => (
  <PropertiesFilterChip
    data-islistcriteriapopovertrigger='true'
    role='button'
    mx={0.5}
    ref={ref}
    {...props}
  >
    {children}
  </PropertiesFilterChip>
))

FilterChip.displayName = 'FilterChip'
