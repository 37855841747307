import { Photo as CommonPhoto } from 'features/valueObjects/Photo'
import { PropsWithChildren, createContext, useContext as useContextFromReact, useMemo, useState } from 'react'
import Swiper from 'swiper'

export namespace SliderLogic {
  export type Photo = CommonPhoto

  type ContextValue = {
    photos: Photo[]

    currentSlide: number
    setCurrentSlide: (slide: number) => void

    swiper: Swiper | null
    setSwiper: (swiper: Swiper) => void
  }

  const INITIAL_VALUE: ContextValue = {
    photos: [],

    currentSlide: 0,
    setCurrentSlide: () => {},

    swiper: null,
    setSwiper: () => {},
  }

  export const Context = createContext(INITIAL_VALUE)

  export const Provider = ({
    photos,
    children,
  }: PropsWithChildren<{
    photos: Photo[]
  }>) => {
    const [swiper, setSwiper] = useState<Swiper | null>(null)
    const [currentSlide, setCurrentSlide] = useState(0)

    const value = useMemo(() => ({
      photos,
      setSwiper,
      swiper,
      currentSlide,
      setCurrentSlide,
    }), [
      photos,
      setSwiper,
      swiper,
      currentSlide,
      setCurrentSlide,
    ])

    return (
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    )
  }

  export const useContext = () => useContextFromReact(Context)
}
