import NearbyBuyersRoutesLive from 'features/NearbyBuyers/routes/NearbyBuyersRoutesLive'
import OwnersPropertiesRoutesLive from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes.live'
import { AppLevelRenders } from 'presentation/app/AppLevelRenders'
import { IS_DEV_ENV } from 'presentation/const/env.const'
import { ControlledLayout } from 'presentation/layouts/Layout/Layout.controlled'
import { ListsLayout } from 'presentation/layouts/ListsLayout/ListsLayout'
import { PropertyLayout } from 'presentation/layouts/PropertyLayout/PropertyLayout'
import { AuthenticationControl, EmailConfirmationControl, SubscribedOnly } from 'presentation/main/Router/Router.utils'
import { RouterExposer } from 'presentation/main/Router/RouterExposer'
import { ROUTE_IDS } from 'presentation/main/Router/routeId'
import { UserLayout } from 'presentation/screens/User/UserLayout'
import {
  Navigate,
  Outlet,
  createBrowserRouter,
} from 'react-router-dom'
import { BillingRouteElement, CancelSubscriptionRouteElement, CompsElement, ConfirmEmailRouteElement, DeleteAccountElement, DrivingForDollarsElement, ListBuilderComingSoonElement, ListBuilderElement, ListElement, ListPreviewElement, ListsElement, LoginRouteElement, MembersRouteElement, NotFoundRouteElement, PlaygroundRouteElement, PricingRouteElement, PropertyDetailsElement, PropertyDetailsFromAddressElement, PropertyDetailsFromParcelIdElement, RegisterRouteElement, RenewTrialRouteElement, ResetPasswordRouteElement, SearchRouteElement, SendResetPasswordEmailRouteElement, SkiptraceElement, TermsOfUseElement } from './RouteElements'

export const appRouter = createBrowserRouter([{
  path: '/',
  element: (
    <>
      <AppLevelRenders />
      <Outlet />
    </>
  ),
  children:
  [
    {
      path: '/renew-trial',
      element: <RenewTrialRouteElement />,
    },
    {
      path: '/termsofuse',
      element: <TermsOfUseElement />,
    },
    {
      element: <AuthenticationControl shouldBe={false} redirectTo='/' />,
      children: [
        {
          path: '/login',
          element: <LoginRouteElement />,
        },
        {
          path: '/register',
          element: <RegisterRouteElement />,
        },
        {
          path: '/send-reset-password-email',
          element: <SendResetPasswordEmailRouteElement />,
        },
        {
          path: '/reset-password',
          element: <ResetPasswordRouteElement />,
        },
        {
          path: '/reset-password-for-retrial',
          element: <ResetPasswordRouteElement isForRetrial />,
        },
      ],
    },
    {
      element: <AuthenticationControl shouldBe={true} redirectTo='/login' />,
      children: [
        {
          element: <EmailConfirmationControl shouldBe={false} redirectTo='/' />,
          children: [
            {
              path: '/confirm-email',
              element: <ConfirmEmailRouteElement />,
            },
          ],
        },
        {
          element: <EmailConfirmationControl shouldBe={true} redirectTo='/confirm-email' />,
          children: [
            {
              element: <ControlledLayout />,
              children: [
                {
                  element: <SubscribedOnly redirectTo='/pricing' />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to='search' />,
                    },
                    {
                      path: 'search',
                      element: <SearchRouteElement />,
                    },
                    {
                      path: 'search/from-address',
                      element: <PropertyDetailsFromAddressElement />,
                    },
                    {
                      path: 'search/from-parcel-id',
                      element: <PropertyDetailsFromParcelIdElement />,
                    },
                    {
                      path: 'search/:leadId',
                      element: (
                        <PropertyLayout>
                          <Outlet />
                        </PropertyLayout>
                      ),
                      children: [
                        {
                          id: ROUTE_IDS.DETAILS,
                          index: true,
                          element: <PropertyDetailsElement />,
                        },
                        {
                          id: ROUTE_IDS.COMPS,
                          path: 'comps',
                          element: <CompsElement />,
                        },
                        {
                          id: ROUTE_IDS.SKIPTRACE,
                          path: 'skiptrace',
                          element: <SkiptraceElement />,
                        },
                        ...NearbyBuyersRoutesLive.routes,
                        ...OwnersPropertiesRoutesLive.routes,
                      ],
                    },
                    {
                      path: 'user/delete-account',
                      element: <DeleteAccountElement />,
                    },
                    {
                      path: 'user/billing/cancel',
                      element: <CancelSubscriptionRouteElement />,
                    },
                    {
                      path: 'user/*',
                      element: <UserLayout />,
                      children: [
                        {
                          path: 'billing',
                          element: <BillingRouteElement />,
                        },
                        {
                          path: 'team',
                          element: <MembersRouteElement />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'pricing',
                  element: <PricingRouteElement />,
                },
                {
                  path: 'list-builder-coming-soon',
                  element: <ListBuilderComingSoonElement />,
                },
                {
                  path: 'lists',
                  element: <ListsLayout />,
                  children: [
                    {
                      index: true,
                      element: <ListsElement />,
                    },
                    {
                      path: 'builder',
                      children: [
                        {
                          id: ROUTE_IDS.LIST_BUILDER,
                          index: true,
                          element: <ListBuilderElement />,
                        },
                        {
                          id: ROUTE_IDS.LIST_BUILDER_PREVIEW,
                          path: 'preview',
                          element: <ListPreviewElement />,
                        },
                      ],
                    },
                    {
                      id: ROUTE_IDS.LIST,
                      path: ':listId',
                      element: <ListElement />,
                    },
                  ],
                },
                {
                  path: 'driving-for-dollars',
                  element: <DrivingForDollarsElement />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundRouteElement />,
    },
    ...IS_DEV_ENV
      ? [{
        path: '/playground',
        element: <PlaygroundRouteElement />,
      }]
      : [],
  ],
}])

RouterExposer.expose(appRouter)
