import { createIcon } from '@chakra-ui/react'

export const JudgeHammerIcon = createIcon({
  displayName: 'JudgeHammerIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <g clipPath='url(#clip0_2011_275427)'>
        <path d='M7.64199 6.69057L7.99111 6.34057C8.26643 6.06411 8.43134 5.69665 8.45486 5.30719C8.4687 5.30137 8.48215 5.29464 8.49511 5.28707L9.90299 4.40682C9.99494 4.29474 10.0425 4.1528 10.0367 4.00795C10.0309 3.8631 9.97212 3.72542 9.87149 3.62107L7.42236 1.16582C7.31839 1.06515 7.18098 1.00629 7.03637 1.00048C6.89176 0.994665 6.75008 1.04231 6.63836 1.13432L5.75986 2.54657L5.74061 2.58682C5.36436 2.61044 4.99511 2.76532 4.70986 3.05144L3.37811 4.38757C3.09199 4.67369 2.93799 5.04382 2.91436 5.42094C2.90052 5.42677 2.88708 5.43349 2.87411 5.44107L1.46624 6.32132C1.37429 6.43339 1.3267 6.57533 1.33251 6.72019C1.33831 6.86504 1.39711 7.00271 1.49774 7.10707L3.94686 9.56232C4.05084 9.66299 4.18824 9.72185 4.33285 9.72766C4.47746 9.73347 4.61915 9.68583 4.73086 9.59382L5.60936 8.18157L5.62949 8.14132C6.00574 8.11769 6.37499 7.96282 6.66024 7.67669L7.04699 7.28907' stroke='currentColor' fill='none' />
        <path d='M6.42467 7.2861L12.9277 14.602C13.471 15.2132 14.4166 15.241 14.9948 14.6628C15.573 14.0846 15.5452 13.1389 14.9341 12.5957L7.61813 6.09264C7.28359 5.79527 6.77524 5.81021 6.45874 6.12671C6.14224 6.44321 6.1273 6.95156 6.42467 7.2861Z' stroke='currentColor' fill='none' />
      </g>
      <defs>
        <clipPath id='clip0_2011_275427'>
          <rect width='16' height='16' fill='white' transform='translate(0.332031)' />
        </clipPath>
      </defs>
    </>
  ),
})
