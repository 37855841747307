/*
 * @TODO Add more components according to the design system when we actually need them
 * <CardDesciption />
 * <CardSecondaryTitle />
 */

import { Box, CardProps } from '@chakra-ui/react'
import { Card } from 'presentation/components/molecules/Card/Card'
import { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react'

type CardComboContextValue = Pick<CardProps, 'size' | 'boxShadow'>
const CardComboContext = createContext<CardComboContextValue>({})

export const CardCombo: FC<PropsWithChildren & CardProps> = ({ size, boxShadow, ...props }) => {
  const contextValue = useMemo(() => ({ size, boxShadow }), [size, boxShadow])

  return (
    <CardComboContext.Provider value={contextValue}>
      {/* Box is necessary for CardSegments nth-of-type css work */}
      <Box {...props} />
    </CardComboContext.Provider>
  )
}

export const CardSegment: FC<CardProps> = props => {
  const { size, boxShadow } = useContext(CardComboContext)

  return (
    <Card
      size={size}
      boxShadow={boxShadow}
      sx={{
        '&:not(:first-of-type)': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        '&:not(:last-of-type)': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      }}
      {...props}
    />
  )
}
