import { Button, Link, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { handleDefaultOpenFailedInvoiceModal } from 'presentation/main/globalModals/FailedInvoiceModal/handleDefaultOpenFailedInvoiceModal'
import { unmaskFailedInvoiceFragment } from 'presentation/main/globalModals/FailedInvoiceModal/unmaskFailedInvoiceFragment'
import { BasePaymentRow } from 'presentation/screens/Billing/components/BillingHistorySection/PaymentsTable/BasePaymentRow/BasePaymentRow'
import { ActiveSubscription } from 'presentation/screens/Billing/components/BillingHistorySection/usePaymentsTable'
import { FC } from 'react'
import { formatPenniesOptionalDecUsdOr } from 'utils/dataAdapter'

export const FailedNextPaymentRow: FC<{
  subscription: ActiveSubscription
}> = ({ subscription }) => {
  const failedInvoiceSummary = unmaskFailedInvoiceFragment(subscription)
  const invoice = subscription.pastDueInvoice

  if (!invoice) return null

  const price = invoice.refundedAmount
    || invoice.capturedAmount
    || invoice.authorizedAmount
    || undefined

  const handlePayNow = (e: React.MouseEvent<unknown>): void => {
    e.stopPropagation()
    handleDefaultOpenFailedInvoiceModal(failedInvoiceSummary)
  }

  return (
    <BasePaymentRow
      dateString={invoice.paidAt || invoice.createdAt}
      createdById={invoice.createdBy?.id}
      linkToPdf={invoice.linkToPdf || undefined}
      amount={price}
      description={(
        <>
          Your Propelio subscription of
          {' '}
          {formatPenniesOptionalDecUsdOr('unknown amount')(price)}
          {' '}
          is
          {WHITE_SPACE}
          <Text as='span' textStyle='body2-h-b' color='negative.500'>past due</Text>
          .
          {WHITE_SPACE}
          <Link href='#' textStyle='body2-h-b' color='link.500' onClick={handlePayNow}>Pay Now</Link>
          {WHITE_SPACE}
          to keep service.
        </>
      )}
      status={(
        <Button
          variant='solid'
          colorScheme='negative'
          size='xs-narrow'
          onClick={handlePayNow}
          ml='-2'
        >
          <Breakpoint
            mobSm={() => <>Pay</>}
            tabSm={() => <>Pay Now</>}
          />
        </Button>
      )}
    />
  )
}

const WHITE_SPACE = <Text as='span' whiteSpace='pre-wrap'> </Text> // fixes the pesky space that won't render
