import { Button, Center, Spacer, Spinner, VStack } from '@chakra-ui/react'
import { BuildListState, useListPreview } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useScrollToHighlightedEntry } from 'presentation/screens/ListBuilderScreen/components/ListPreviewPanel/ListPreviewPanel'
import { LIST_PREVIEW_SUBSCREEN_KEYS } from 'presentation/screens/ListPreviewScreen/ListPreviewScreen.const'
import ListPreviewMapAndListSync from 'presentation/screens/ListPreviewScreen/components/ListPreviewMapAndListSync'
import { PreviewResultsCountText } from 'presentation/screens/ListPreviewScreen/components/ResultsCountText'
import { useSaveListModal } from 'presentation/screens/ListPreviewScreen/components/SaveListModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ListPreviewMobileItemCard from './ListPreviewMobileItemCard'

export const ListPreviewMobileListSubscreen = () => {
  usePreviewMobileHeaderContents()
  const listPreview = useListPreview()

  const highlightedEntry = ListPreviewMapAndListSync.useStore(store => store.highlightedListItem)

  useScrollToHighlightedEntry(highlightedEntry)

  return (
    <BaseTabsContainer
      size='sm'
      colorScheme='weak'
      flex='1 0 0'
      display='flex'
      flexDirection='column'
      bg='special.500'
      pt={1}
      h='layout-height'
      maxH='layout-height'
      minH='0'
    >
      <BaseTabs flex='0 0 0'>
        <BaseTab isActive textTransform='uppercase'>
          Properties
        </BaseTab>
        <Spacer />
        <PreviewResultsCountText
          textStyle='bodyM'
          color='ondark.1'
          pr={1}
        />
      </BaseTabs>
      <BaseTabContent
        flex='1 0 0'
        sx={{ borderBottomRadius: '0', overflowY: 'auto' }}
        minH='0'
      >
        {BuildListState.$is('Success')(listPreview)
          ? (
            <VStack spacing={2} p={mbp({ mobSm: 1, mob: 2 })} align='stretch'>
              {listPreview.preview.items.map(item => (
                <ListPreviewMobileItemCard
                  key={item.id}
                  item={item}
                />
              ))}
            </VStack>
          )
          : (
            <Center
              flex='1 1 auto'
              minW={0}
              minH={0}
            >
              <Spinner />
            </Center>
          )}

      </BaseTabContent>
    </BaseTabsContainer>
  )
}

const usePreviewMobileHeaderContents = () => {
  const setHeaderContents = useSetMobileHeaderContents()
  const saveListModalApi = useSaveListModal()
  const location = useLocation()

  useEffect(() => {
    setHeaderContents([
      <Button
        key='list-left'
        color='ondark.2'
        textTransform='none'
        textStyle='bodyMFat'
        leftIcon={(
          <ArrowLeftIcon fontSize={3} />
        )}
        as={Link}
        to={location.pathname}
        state={{
          subscreen: LIST_PREVIEW_SUBSCREEN_KEYS.MAP,
        }}
      >
        Map
      </Button>,
      <Spacer key='list-center' />,
      <Button
        key='list-right'
        size='md'
        variant='solid'
        colorScheme='positive'
        mr={1}
        onClick={saveListModalApi.open}
      >
        Save List
      </Button>,
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [])
}
