import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { Skiptrace as Domain } from 'features/Skiptrace/domain/Skiptrace.domain'
import { SkiptraceEvents } from 'features/Skiptrace/infra/events/Skiptrace.events'
import { ProgramException } from 'libs/errors'
import { nanoid } from 'nanoid'
import { ReactNode, createContext, useContext } from 'react'
import { create } from 'zustand'

export type SkiptraceDeps = {
  getSkiptraceResult: (payload: Domain.GetSkiptraceResultPayload) =>
  Promise<Domain.GetSkiptraceResultResult>

  performSkiptrace: (payload: Domain.PerformSkiptracePayload) =>
  Promise<Domain.PerformSkiptraceResult>

  downloadResult: (payload: Domain.DownloadPayload) => Promise<void>

  getSkiptraceFundsInfo: (payload: Domain.GetSkiptraceFundsInfoPayload) =>
  Promise<Domain.GetSkiptraceFundsInfoResult>
}

export type SkiptraceState = {
  entities: {
    resultState:
      | {
        status: 'initial'
      }
      | {
        status: 'loading'
        requestId: string
        params: Domain.GetSkiptraceResultPayload
      }
    /**
     * @NOTE We go back to success state when subsequent skiptrace fails to
     *   avoid losing existing result.
     */
      | {
        status: 'success'
        requestId: string
        params: Domain.GetSkiptraceResultPayload
        result: Domain.Result | null
      }
      | {
        status: 'skiptracing'
        requestId: string
        params: Domain.GetSkiptraceResultPayload

        /** null on first skiptrace, has result if there's prior request with result */
        result: Domain.Result | null
      }
      | {
        status: 'error'
        params: Domain.GetSkiptraceResultPayload
        requestId: string
        error: Domain.GetSkiptraceResultError
      }

    skiptraceFunds:
      | {
        status: 'initial'
      }
      | {
        status: 'loading'
        requestId: string
        params: Domain.GetSkiptraceFundsInfoPayload
      }
      | {
        status: 'success'
        requestId: string
        params: Domain.GetSkiptraceFundsInfoPayload
        attempt: ConsumableAttempt.ConsumableAttempt
      }
      | {
        status: 'error'
        requestId: string
        params: Domain.GetSkiptraceFundsInfoPayload
        error: Domain.GetSkiptraceFundsInfoError
      }
  }
  actions: {
    getSkiptraceResult: {
      execute: (payload: Domain.GetSkiptraceResultPayload) =>
      Promise<void>
    }

    performSkiptrace: {
      /**
       * Let's track last error here.
       *
       * Because let's say you have existing result, you performed skiptrace again,
       * and it failed.
       *
       * If we transition to error state, we lose the existing result.
       *
       * So we just go back to success state, but track the error here.
       */
      lastResult:
        | {
          requestId: string
          status: 'error'
          error: Domain.PerformSkiptraceError | null
          payload: Omit<Domain.PerformSkiptracePayload, 'confirmationKey'>
        }
        | {
          requestId: string
          status: 'no-result'
          payload: Omit<Domain.PerformSkiptracePayload, 'confirmationKey'>
        }
        | {
          requestId: string
          status: 'success'
          payload: Omit<Domain.PerformSkiptracePayload, 'confirmationKey'>
        }
        | null

      execute: (payload: Omit<Domain.PerformSkiptracePayload, 'confirmationKey'>) =>
      Promise<void>
    }

    downloadResult: {
      execute: (payload: Domain.DownloadPayload) => Promise<void>
    }

    getSkiptraceFundsInfo: {
      execute: (payload: Domain.GetSkiptraceFundsInfoPayload) =>
      Promise<void>
    }
  }
}

export const createSkiptraceStore = (deps: SkiptraceDeps) =>
  create<SkiptraceState>((set, get) => {
    const setResultState = (
      resultState: SkiptraceState['entities']['resultState'],
    ) => {
      set(state => ({
        ...state,
        entities: {
          ...state.entities,
          resultState,
        },
      }))
    }

    const setSkiptraceFundsState = (
      skiptraceFunds: SkiptraceState['entities']['skiptraceFunds'],
    ) => {
      set(state => ({
        ...state,
        entities: {
          ...state.entities,
          skiptraceFunds,
        },
      }))
    }

    const getLatestRequestId = () => {
      const resultState = get().entities.resultState
      if (resultState.status === 'initial') return null
      return resultState.requestId
    }

    const getLatestFundsRequestId = () => {
      const fundsState = get().entities.skiptraceFunds
      if (fundsState.status === 'initial') return null
      return fundsState.requestId
    }

    const api: SkiptraceState = {
      entities: {
        resultState: {
          status: 'initial',
        },
        skiptraceFunds: {
          status: 'initial',
        },
      },
      actions: {
        getSkiptraceResult: {
          execute: async payload => {
            const requestId = nanoid()

            const initialResultState = get().entities.resultState

            const isSameRequestStillLoading
              = initialResultState.status === 'loading'
              && initialResultState.params.leadId === payload.leadId

            if (isSameRequestStillLoading) return

            setResultState({
              status: 'loading',
              requestId,
              params: payload,
            })

            await deps.getSkiptraceResult(payload)
              .then(({ result }) => ({
                status: 'success' as const,
                requestId,
                params: payload,
                result,
              }))
              .catch(error => ({
                status: 'error' as const,
                requestId,
                params: payload,
                error,
              }))
              .then(nextState => {
                const latestRequestId = getLatestRequestId()
                const isRequestOutdated = latestRequestId !== requestId

                if (isRequestOutdated) return

                setResultState(nextState)
              })
          },
        },
        performSkiptrace: {
          lastResult: null,
          execute: async payload => {
            const initialResultState = get().entities.resultState
            const fundsState = get().entities.skiptraceFunds

            const isSameRequestStillLoading = initialResultState.status === 'skiptracing'
              && initialResultState.params.leadId === payload.leadId
            if (isSameRequestStillLoading) return

            const isExistingResultLoaded = initialResultState.status === 'success'
              && initialResultState.params.leadId === payload.leadId
            if (!isExistingResultLoaded) return

            // Check if we have a valid confirmation key from the funds state
            if (fundsState.status !== 'success') {
              set(state => ({
                ...state,
                actions: {
                  ...state.actions,
                  performSkiptrace: {
                    ...state.actions.performSkiptrace,
                    lastResult: {
                      requestId: nanoid(),
                      status: 'error',
                      error: new ProgramException('Missing confirmation key. Please check funds information first.'),
                      payload,
                    },
                  },
                },
              }))
              return
            }

            const initialResult = initialResultState.result
            const requestId = nanoid()

            setResultState({
              status: 'skiptracing',
              requestId,
              params: payload,
              result: initialResult,
            })

            await deps.performSkiptrace({
              ...payload,
              confirmationKey: fundsState.attempt.confirmationKey,
            })
              .then(response => {
                set(state => ({
                  ...state,
                  actions: {
                    ...state.actions,
                    performSkiptrace: {
                      ...state.actions.performSkiptrace,
                      lastResult: response.isSuccessful
                        ? {
                          requestId,
                          status: 'success',
                          payload,
                        }
                        : {
                          requestId,
                          status: 'no-result',
                          payload,
                        },
                    },
                  },
                }))
              })
              .catch(error => {
                set(state => ({
                  ...state,
                  actions: {
                    ...state.actions,
                    performSkiptrace: {
                      ...state.actions.performSkiptrace,
                      lastResult: {
                        requestId,
                        status: 'error',
                        error,
                        payload,
                      },
                    },
                  },
                }))
              })
              .then(async () => {
                const latestRequestId = getLatestRequestId()
                const isRequestOutdated = latestRequestId !== requestId

                if (isRequestOutdated) return

                await api.actions.getSkiptraceResult.execute(payload)
                SkiptraceEvents.performSkiptraceDone.emit()
              })
          },
        },
        downloadResult: {
          execute: async payload => {
            await deps.downloadResult(payload)
          },
        },
        getSkiptraceFundsInfo: {
          execute: async payload => {
            const requestId = nanoid()

            const initialFundsState = get().entities.skiptraceFunds

            const isSameRequestStillLoading
              = initialFundsState.status === 'loading'
              && initialFundsState.params.leadId === payload.leadId

            if (isSameRequestStillLoading) return

            setSkiptraceFundsState({
              status: 'loading',
              requestId,
              params: payload,
            })

            await deps.getSkiptraceFundsInfo(payload)
              .then(({ attempt }) => ({
                status: 'success' as const,
                requestId,
                params: payload,
                attempt,
              }))
              .catch(error => ({
                status: 'error' as const,
                requestId,
                params: payload,
                error,
              }))
              .then(nextState => {
                const latestRequestId = getLatestFundsRequestId()
                const isRequestOutdated = latestRequestId !== requestId

                if (isRequestOutdated) return

                setSkiptraceFundsState(nextState)
              })
          },
        },
      },
    }

    return api
  })

export type SkiptraceStore = ReturnType<typeof createSkiptraceStore>

const SkiptraceContext = createContext<SkiptraceStore | null>(null)

export const SkiptraceProvider = ({
  children,
  store,
}: {
  children: ReactNode
  store: SkiptraceStore
}) => (
  <SkiptraceContext.Provider value={store}>
    {children}
  </SkiptraceContext.Provider>
)

// eslint-disable-next-line @stylistic/comma-dangle
export const useSkiptraceStore = <T,>(
  selector: (store: SkiptraceState) => T,
  equalityFn?: (a: T, b: T) => boolean,
) => {
  const store = useContext(SkiptraceContext)

  if (!store)
    throw new Error('useSkiptraceStore must be used within a SkiptraceProvider')

  return equalityFn ? store(selector, equalityFn) : store(selector)
}
