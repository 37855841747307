import {
  chakra,
  forwardRef,
  omitThemingProps,
  SystemProps,
  ThemingProps,
  useStyleConfig,
  HTMLChakraProps,
} from '@chakra-ui/system'
import { cx } from '@chakra-ui/shared-utils'
import { compact, assignAfter } from '@chakra-ui/object-utils'

export type TextProps = {
  /**
   * The CSS `text-align` property
   * @type SystemProps["textAlign"]
   */
  align?: SystemProps['textAlign']
  /**
   * The CSS `text-decoration` property
   * @type SystemProps["textDecoration"]
   */
  decoration?: SystemProps['textDecoration']
  /**
   * The CSS `text-transform` property
   * @type SystemProps["textTransform"]
   */
  casing?: SystemProps['textTransform']
} & HTMLChakraProps<'p'> & ThemingProps<'Text'>

/**
 * All this code is copied from Chakra UI, except for commented part below
 */
export const ConfigurableChakraText = forwardRef<TextProps, 'p'>((props, ref) => {
  const styles = useStyleConfig('Text', props)
  const { className, align, decoration, casing, ...rest }
    = omitThemingProps(props)

  const aliasedProps = compact({
    textAlign: props.align,
    textDecoration: props.decoration,
    textTransform: props.casing,
  })

  /** This the only deviation from Chakra's Text */
  const configurableStyles = assignAfter(
    {},
    styles,
    props.__css,
  )

  return (
    <chakra.p
      ref={ref}
      className={cx('chakra-text', props.className)}
      {...aliasedProps}
      {...rest}
      __css={configurableStyles}
    />
  )
})

ConfigurableChakraText.displayName = 'Text'
