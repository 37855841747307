import { Button, Menu, MenuButton, MenuItemOption } from '@chakra-ui/react'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { FC, PropsWithChildren } from 'react'

export type OptionButtonProps = PropsWithChildren<{
  isDisabled?: boolean
  isVisible?: boolean
  color?: string
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}>

export const OptionButton: FC<OptionButtonProps> = ({
  children,
  isDisabled,
  isVisible = true,
  isOpen,
  onOpen,
  onClose,
  ...props
}) => (
  <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
    <MenuButton
      as={Button}
      variant='muted'
      size='xs'
      colorScheme='neutral'
      rightIcon={<ChevronDownIcon boxSize='2' />}
      isDisabled={isDisabled}
      visibility={isVisible ? 'visible' : 'hidden'}
      {...props}
    >
      Options
    </MenuButton>
    <ResponsiveMenuList>
      {children}
    </ResponsiveMenuList>
  </Menu>
)

export const EditMemberInfoOption: typeof MenuItemOption = props => (
  <MenuItemOption {...props}>Edit Team Member&apos;s info</MenuItemOption>
)

export const DeactivateMemberOption: typeof MenuItemOption = props => (
  <MenuItemOption {...props}>Deactivate Team Member</MenuItemOption>
)

export const CancelInviteOption: typeof MenuItemOption = props => (
  <MenuItemOption {...props}>Cancel Invite</MenuItemOption>
)
