import { Text, VStack } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Card, CardBody, CardButton, CardFooter, CardHeader } from 'presentation/components/molecules/Card'
import { usePropertyDetailsActions } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsActionsContext'
import { EstimateSubsection } from 'presentation/screens/PropertyDetailsScreen/components/ValuationSection/EstimateSubsection'
import { FinancialsSubsection } from 'presentation/screens/PropertyDetailsScreen/components/ValuationSection/FinancialsSubsection'
import { ValuationSectionProps } from 'presentation/screens/PropertyDetailsScreen/components/ValuationSection/ValuationSection.props'
import { shallow } from 'zustand/shallow'

export const ValuationSection = (props: ValuationSectionProps) => {
  const { goToComps } = usePropertyDetailsActions()
  const selectProperty = useSelectPropertyByRouteLeadId()
  const hasData = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    return !!property
  }, shallow)

  if (!hasData) return null

  return (
    <Card size='xs-locked' colorScheme='card.bg.1' {...props}>
      <CardHeader>
        <Text textStyle='bodyXLFat' color='accent.blue-text'>
          Propelio’s Estimated Value
        </Text>
      </CardHeader>
      <CardBody mt='2' shouldResetSpacing flex='1'>
        <VStack spacing='4' align='stretch'>
          <EstimateSubsection />
          <FinancialsSubsection />
        </VStack>
      </CardBody>
      <CardFooter>
        <CardButton
          variant='solid'
          colorScheme='positive'
          size='md'
          w='full'
          onClick={goToComps}
        >
          Run Comps/CMA
        </CardButton>
      </CardFooter>
    </Card>
  )
}
