import { Box, Flex, Grid, GridItem, Text, useToken } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { createPropertyDetailsUrlFromComp } from 'features/CMA/infra/react/CMA.helpers'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { EnterIcon, SubjectPropertyIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { ThumbButtonsToggle } from 'presentation/components/ThumbButtons'
import { Tooltip } from 'presentation/components/Tooltip'
import { Card } from 'presentation/components/molecules/Card'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { CompsMLSDisclaimer } from 'presentation/screens/CompsScreen/components/CompsMLSDisclaimer/CompsMLSDisclaimer'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { AgentInfoText } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/AgentInfoText/AgentInfoText'
import { CompAnalysisCard } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompAnalysisCard/CompAnalysisCard'
import { ComparativePropertyCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/ComparativePropertyCard/ComparativePropertyCard.props'
import { CompsMLSInfoCard, FullscreenButton } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompsMLSInfoCard'
import { TabbedComparativeContent } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/TabbedComparativeContent'
import { useComparativePropertyCardLogic } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useComparativePropertyCardLogic'
import { getCompCardColors } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/utils/getCompCardColors'
import { toBedBathsLivingSqft } from 'presentation/screens/CompsScreen/formatters/toBedBathsBldngSqft'
import { thumbValueFromRatingNullable } from 'presentation/screens/CompsScreen/utils/CMAThumbRatingConverter'
import { SliderMaximizedModal } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMaximizedModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { isNonNullable } from 'utils/isNonNullable'

export const ComparativePropertyCardMobile = (props: ComparativePropertyCardProps) => {
  const logic = useComparativePropertyCardLogic(props)

  if (logic.status === 'aborting') return null

  const {
    compEntries,
    currentComp,
    handleRatingChange,
    modalBgColor,
    onSelectedIndexChange,
    selectedCompIsSubject,
    isSinglePointComps,
    totalItems,
    safeSelectedIndex,
  } = logic

  const currentPage = safeSelectedIndex + 1

  const onPageChange = (page: number) => {
    onSelectedIndexChange(page - 1)
  }

  const renderPageButton = (page: number | null) => {
    if (page === null) return '...'
    const entry = compEntries[page - 1]
    const thumbValue = thumbValueFromRatingNullable(entry?.comp.userRating)
    return (
      <>
        {thumbValue !== 'undecided' && (
          <ThumbButtonsToggle
            value={thumbValue}
            position='absolute'
            top='-8px'
            right='-5px'
            boxSize={2}
          />
        )}
        {page}
      </>
    )
  }

  const infoCard = (
    <CompsMLSInfoCard
      entry={currentComp}
    />
  )

  const analysisCard = pipe(
    Match.value(currentComp),
    Match.when({ type: 'subject-comp' }, v => (
      <CompAnalysisCard
        subject={v}
        onRatingChange={handleRatingChange}
      />
    )),
    Match.when({ type: 'single-comp' }, v => (
      <CompAnalysisCard
        subject={props.subject}
        comp={v as CMA.SingleComp}
        onRatingChange={handleRatingChange}
      />
    )),
    Match.orElse(() => null),
  )

  return (
    <Card
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked' })}
      bgColor={modalBgColor}
      w='full'
      pointerEvents='auto'
    >
      <Flex alignItems='center' gap={1}>
        {currentComp.type === 'subject-comp' && (
          <SubjectPropertyIcon boxSize={2} />
        )}
        <Tooltip label={currentComp.address.line1}>
          <Text
            textStyle='h4'
            color={isSinglePointComps ? 'graystrong.500' : 'ondark.1'}
            isTruncated
          >
            {currentComp.address.line1}
          </Text>
        </Tooltip>
        <Box
          as={Link}
          target='_blank'
          p={0.5}
          to={createPropertyDetailsUrlFromComp(currentComp)}
        >
          <EnterIcon
            boxSize={3}
            color={isSinglePointComps ? 'graystrong.500' : 'ondark.1'}
          />
        </Box>
        <CompsThumbButtons
          comp={currentComp}
          onCompsRatingChange={handleRatingChange}
          size='lg'
          ml='auto'
          display={mbp({ mobSm: 'flex', mob: 'none' })}
        />
      </Flex>

      <Flex gap={1} alignItems='center'>
        <Tooltip label={currentComp.address.subdivision}>
          <Text
            textStyle='bodyXL'
            color={isSinglePointComps ? 'modal.text' : 'ondark.3'}
            isTruncated
          >
            {currentComp.address.subdivision}
          </Text>
        </Tooltip>
      </Flex>

      <Flex
        alignItems='center'
        mt={2}
        {...isSinglePointComps && {
          sx: {
            '& button.next:not([disabled]), & button.prev:not(disabled)': {
              bgColor: 'card.bg.1',
            },
          },
        }}
      >
        <Pagination
          currentPage={currentPage}
          totalPages={totalItems}
          maxPageButtons={5}
          onPageChange={onPageChange}
          renderPageButton={renderPageButton}
          size='lg'
          colorScheme={isSinglePointComps ? 'default' : 'highlight'}
          shouldHideFillers
        />
        <CompsThumbButtons
          comp={currentComp}
          onCompsRatingChange={handleRatingChange}
          size='lg'
          ml='auto'
          display={mbp({ mobSm: 'none', mob: 'flex' })}
        />
      </Flex>

      {isSinglePointComps && (
        <Text
          mt={1}
          textStyle='bodyM'
          color='graystrong.400'
        >
          This is a group of multiple listings at a single address.
          Use pagination to explore more listings.
        </Text>
      )}

      <Box mt={2}>
        <ComparableEntryInfo
          entry={currentComp}
        />

        <AgentInfoText
          color={
            isSinglePointComps
              ? 'graystrong.400'
              : 'ondark.4'
          }
          comp={currentComp}
          mt={2}
        />
      </Box>

      <TabbedComparativeContent
        mt={3}
        infoCard={infoCard}
        analysisCard={analysisCard}
        entriesAreSinglePoint={isSinglePointComps}
        selectedIsSubjectComp={selectedCompIsSubject}
      />

      <CompsMLSDisclaimer
        mt={5}
        color={isSinglePointComps ? 'modal.text' : 'ondark.3'}
      />
    </Card>
  )
}

type ComparableEntryInfoProps = {
  entry: CMA.SingleComp | CMA.SubjectComp
}

const ComparableEntryInfo = (props: ComparableEntryInfoProps) => {
  const { entry } = props

  const { sbp } = useSwitchBreakpointFn()

  const marginSize = useToken('sizes', sbp({
    mobSm: 2,
    mob: 3,
  }) ?? 3)

  const [isFullscreen, setIsFullscreen] = useState(false)

  const cardColors = getCompCardColors(entry.status)

  const bedsBathsSizeFormat = toBedBathsLivingSqft(entry)

  const price = Dollar.formatNumberCODoDD(entry.salePrice || entry.listPrice)

  const daysOnMarket = isNonNullable(entry?.daysOnMarket)
    ? [entry.daysOnMarket, pluralize('day', entry.daysOnMarket), 'on market'].join(' ')
    : '--'

  const yearBuilt = entry.yearBuilt
    ? `year built ${entry.yearBuilt}`
    : '--'

  const shouldShowFullscreenButton = entry.photos.length > 0

  const handleFullscreen = () => {
    setIsFullscreen(true)
  }

  const handleClose = () => {
    setIsFullscreen(false)
  }

  return (
    <Box
      w={`calc(100% + (${marginSize} * 2))`}
      ml={`-${marginSize}`}
      mr={`-${marginSize}`}
    >
      <SliderMaximizedModal
        photos={entry.photos}
        isOpen={isFullscreen}
        onClose={handleClose}
      />

      <Box
        py={1}
        px={2}
        bgColor={cardColors.cardHeaderBgColor}
        color={cardColors.cardHeaderTextColor}
      >
        <Flex
          justifyContent='space-between'
        >
          <Text
            textStyle='bodyXLFat'
            color='inherit'
          >
            {price}
          </Text>
          <Text
            textStyle='bodyM'
            color='inherit'
          >
            {bedsBathsSizeFormat}
          </Text>
        </Flex>
        <Flex
          justifyContent='space-between'
        >
          <Text
            color='inherit'
            textStyle='bodySFat'
          >
            {daysOnMarket}
          </Text>
          <Text
            textStyle='bodyM'
            color='inherit'
          >
            {yearBuilt}
          </Text>
        </Flex>
      </Box>
      <Grid
        gridTemplateAreas={'"layer"'}
        overflow='hidden'
      >

        <PhotosSlider
          key={entry.id}
          gridArea='layer'
          minW={0}
          zIndex='1'
          photos={entry.photos}
          h={232}
        />

        <GridItem
          gridArea='layer'
          alignSelf='start'
          justifySelf='start'
          zIndex='2'
          mt={1.5}
          ml={1}
        >
          {entry.type === 'subject-comp'
            ? (
              <SubjectPropertyTag
                h='fit-content'
              />
            )
            : (
              <MLSStatusTag
                status={entry.status}
              />
            )}
        </GridItem>

        {shouldShowFullscreenButton && (
          <GridItem
            gridArea='layer'
            alignSelf='start'
            justifySelf='end'
            zIndex='2'
          >
            <FullscreenButton
              position='relative'
              onClick={handleFullscreen}
            />
          </GridItem>
        )}
      </Grid>
    </Box>
  )
}
