import { gql } from '@apollo/client'
import imageCompression from 'browser-image-compression'
import { CQ, Command } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib, FieldsError, ProgramException, ReportableException } from 'libs/errors'
import { WithId, WithoutId } from 'libs/id'
import { API_URL } from 'presentation/const/env.const'
import { apolloClient } from 'presentation/libs/client'
import { useCallback } from 'react'

export type __UpdateMemberAvatarPayload = WithId<{ avatarFile: File }>
export type __UpdateMemberAvatarError = FieldsError<WithoutId<__UpdateMemberAvatarPayload>>
export type __UpdateMemberAvatarCommand = Command.PromiseVersion<__UpdateMemberAvatarError, __UpdateMemberAvatarPayload>

export const useUpdateMemberAvatarCommand = (): __UpdateMemberAvatarCommand => {
  const { transition } = CQReact.useStateManager<__UpdateMemberAvatarError, __UpdateMemberAvatarPayload>(
    CQ.createIdleState,
  )
  const { regenerateId } = CQReact.useRegeneratableId()

  const execute = useCallback<__UpdateMemberAvatarCommand['execute']>(async payload =>

    // eslint-disable-next-line no-async-promise-executor
    await new Promise(async resolve => {
      // =============================================================================
      // Set initial state
      // =============================================================================
      const { id, avatarFile } = payload
      const commandId = regenerateId()

      try {
        // =============================================================================
        // Prepare payload
        // =============================================================================
        const compressed = await imageCompression(avatarFile, {
          maxSizeMB: 10,
          maxWidthOrHeight: 512,
          useWebWorker: true,
        })

        const formData = new FormData()
        formData.append('avatar', compressed)

        const request = new XMLHttpRequest()

        // =============================================================================
        // Prepare callback
        // =============================================================================
        request.onreadystatechange = () => {
          try {
            const isResponseReady = request.readyState === XMLHttpRequest.DONE

            if (!isResponseReady) return

            const isResponseOk = request.status >= 200 && request.status < 300

            if (!isResponseOk) {
              throw new ReportableException('Unexpected response', {
                extraInfo: {
                  status: request.status,
                  response: request.responseText,
                },
              })
            }

            // =============================================================================
            // Get Avatar URL
            // =============================================================================
            const json = JSON.parse(request.responseText)

            const avatarUrl = json.profileImage

            if (!avatarUrl) {
              throw new ReportableException('Unexpected response', {
                extraInfo: {
                  json,
                },
              })
            }

            // =============================================================================
            // Save Avatar URL to Apollo Cache
            // =============================================================================
            apolloClient.writeFragment({
              id: `User:${id}`,
              fragment: gql`
                fragment User on User {
                  avatar {
                    profileImageUrl
                  }
                }
              `,
              data: {
                avatar: {
                  profileImageUrl: avatarUrl,
                },
              },
            })

            resolve(transition.success({ id: commandId, payload }))
          } catch (error) {
            void ErrorLib.report(error)
            resolve(transition.error({ id: commandId, error: FALLBACK_ERROR, payload }))
          }
        }
        // =============================================================================
        // Send request
        // =============================================================================
        request.open('post', `${API_URL}/users/v2/me/avatar`)
        request.timeout = 60000
        request.withCredentials = true

        request.send(formData)
      } catch (rawError) {
        void ErrorLib.report(rawError, { extraInfo: { rawError } })
        resolve(transition.error({ id: commandId, error: FALLBACK_ERROR, payload }))
      }
    }), [])

  return {
    execute,
  }
}

const FALLBACK_ERROR = new FieldsError<WithoutId<__UpdateMemberAvatarPayload>>(
  '',
  {
    avatarFile: new ProgramException('An error occurred while updating the avatar'),
  },
)
