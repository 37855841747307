import { MembersScreenUseCases } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { useDisableMemberCommand } from 'presentation/screens/MembersScreen/useCases/command/DisableMemberCommand/DisableMemberCommand'
import { useEnableMemberCommand } from 'presentation/screens/MembersScreen/useCases/command/EnableMemberCommand/EnableMemberCommand'
import { useInviteMembersCommand } from 'presentation/screens/MembersScreen/useCases/command/InviteMembersCommand/InviteMembersCommand'
import { useResendMemberInviteCommand } from 'presentation/screens/MembersScreen/useCases/command/ResendMemberInviteKeyedComand/ResendMemberInviteKeyedComand'
import { useUpdateMemberCommand } from 'presentation/screens/MembersScreen/useCases/command/UpdateMemberCommand/UpdateMemberCommand'
import { useMembersInfoQuery } from 'presentation/screens/MembersScreen/useCases/query/MembersInfoQuery/MembersInfoQuery'

export const useMembersScreenUseCases = (): MembersScreenUseCases => {
  const teamInfo = useMembersInfoQuery()
  const inviteMembers = useInviteMembersCommand()
  const updateMember = useUpdateMemberCommand()
  const enableMember = useEnableMemberCommand()
  const disableMember = useDisableMemberCommand()
  const resendMemberInvite = useResendMemberInviteCommand()

  return {
    query: {
      teamInfo,
    },
    command: {
      inviteMembers,
      updateMember,
      enableMember,
      disableMember,
      resendMemberInvite,
    },
  }
}
