import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { PropertyDetailsState } from 'features/PropertyDetails/infra/react/usePropertyDetailsState.types'
import { useLeadId } from 'presentation/libs/LeadIdContext'

export const selectPropertyByLeadID = (leadId: string) =>

  (state: PropertyDetailsState): Domain.Property | null =>
    state.actions.getProperty.state.status === 'success' && state.actions.getProperty.state.data?.leadId === leadId
      ? state.actions.getProperty.state.data
      : null

type LeadIDBoundSelector = (state: PropertyDetailsState) => Domain.Property | null

/** @TOREFACTOR rename to useSelectPropertyByContextLeadId */
export const useSelectPropertyByRouteLeadId = (): LeadIDBoundSelector => {
  const leadId = useLeadId()

  const selector: LeadIDBoundSelector
    = leadId === null ? () => null : selectPropertyByLeadID(leadId)

  return selector
}
