const ColorLib = {
  darken: (value: number) => (color: string): string => {
    // Ensure value is between 0 and 1
    value = Math.min(1, Math.max(0, value))

    // Remove the hash at the start if it's there
    color = color.replace(/^#/, '')

    // Parse the r, g, b values
    let r = parseInt(color.substring(0, 2), 16)
    let g = parseInt(color.substring(2, 4), 16)
    let b = parseInt(color.substring(4, 6), 16)

    // Calculate the new values
    r = Math.round(r * (1 - value))
    g = Math.round(g * (1 - value))
    b = Math.round(b * (1 - value))

    // Convert to hex and pad with zeros if necessary
    const toHex = (n: number) => n.toString(16).padStart(2, '0')

    // Return the new color in hex format
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  },
}

export default ColorLib
