import { createIcon } from '@chakra-ui/react'

export const CarNewIcon = createIcon({
  displayName: 'CarNewIcon',
  viewBox: '0 0 48 49',
  path: (
    <g fill='none'>
      <g clipPath='url(#a)'>
        <path d='M16.2275 33.0825H31.4217M10.4072 19.3657L13.1067 13.8412C13.4422 13.1546 14.1395 12.7192 14.9036 12.7192H33.2617C34.0482 12.7192 34.7616 13.1801 35.0849 13.897L37.5512 19.3657H10.4072ZM7.10544 23.9083C5.344 25.6259 6.17973 28.1394 6.4979 28.8405C6.81608 29.5415 11.3475 29.7324 13.131 28.8405C14.9145 27.9485 8.86688 22.1908 7.10544 23.9083ZM40.543 23.9083C42.3044 25.6259 41.4687 28.1394 41.1505 28.8405C40.8324 29.5415 36.3009 29.7324 34.5174 28.8405C32.7339 27.9485 38.7816 22.1908 40.543 23.9083ZM4.91673 18.0181L9.02634 9.95714C9.70937 8.61738 11.0861 7.77392 12.59 7.77393L35.0373 7.774C36.5412 7.774 37.9179 8.61749 38.601 9.9573L42.7103 18.0181H47.2813L46.4242 21.821H44.2816L46.4242 24.8207V33.5445C46.4242 34.2471 46.0556 34.8981 45.4532 35.2595L45.2526 35.3799C44.6502 35.7413 44.2816 36.3924 44.2816 37.0949V40.5333C44.2816 41.6379 43.3861 42.5333 42.2816 42.5333H37.6958C36.5912 42.5333 35.6958 41.6379 35.6958 40.5333V39.8195C35.6958 38.7149 34.8004 37.8195 33.6958 37.8195H13.1419C12.0373 37.8195 11.1419 38.7149 11.1419 39.8195V40.5333C11.1419 41.6379 10.2465 42.5333 9.14189 42.5333H4.99981C3.89524 42.5333 2.99981 41.6379 2.99981 40.5333V37.0949C2.99981 36.3924 2.63121 35.7413 2.0288 35.3799L1.82816 35.2595C1.22575 34.8981 0.857148 34.2471 0.857148 33.5445V24.8207L2.99981 21.821H0.857148L0 18.0181L4.91673 18.0181Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='a'>
          <rect width='48' height='48' fill='white' transform='translate(0 0.533203)' />
        </clipPath>
      </defs>
    </g>
  ),
})
