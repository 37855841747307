import { Effect, Option } from 'effect'
import NearbyBuyerProperty from 'features/NearbyBuyers/NearbyBuyerProperty'
import { ActorLogicFrom, ActorRefFrom, assign, ActorRef as BaseActorRef, EventObject, fromCallback, sendTo, setup, Snapshot } from 'xstate'

namespace NearbyBuyerPropertyMachine {
  export type Input = NearbyBuyerProperty & {
    parentActorRef: ParentActorRef
    isSubject?: boolean
  }

  export type Context = Input & {
    getElement: Option.Option<() => HTMLElement>
    isSubject: boolean
  }

  export type Event =
    | { type: 'setup', getElement: () => HTMLElement }
    | { type: 'hover' }
    | { type: 'unhover' }
    | { type: 'highlight' }
    | { type: 'unhighlight' }
    | { type: 'set-data', data: NearbyBuyerProperty }
    | { type: 'click' }

  type ParentEvent = {
    type: 'highlight-from-buyer-property'
    id: string
  } | {
    type: 'property-marker-clicked'
  }

  type ParentActorRef = BaseActorRef<Snapshot<unknown>, ParentEvent>

  export const make = () => Effect.gen(function * () {
    return setup({
      types: {
        context: {} as Context,
        events: {} as Event,
        input: {} as Input,
      },
      delays: {
        highlightDelay: 500,
      },
      actors: {
        hoverActor: yield * makeHoverActor(),
      },
    }).createMachine({
      /** @xstate-layout N4IgpgJg5mDOIC5QDkwEMBOAjAngIQFccwMAFDAewAcSAXHAWTQGMALASwDswBiWMWgFoIaWmgDaABgC6iUFQqx2tdhU5yQAD0QBWHQDYAdAGYdATgBMZ48YAcAdgCMFyfYA0IHIkevDAFh1JW1s-YydJR2d9AF9oj1RMXEJiMkoaDHomNi5eZgAbdmYAaylZJBAFJRU1DW0EA2NDSWMLPyd9Y0lJPzNbDy8EZ1j49Gx8IhJyajpGFg5uQwBJTmV2NAKALy4oPgECKlKNStWa8rrHex0mgPtLc3tW-Xt9fsQnxot3iwv9bvt-4YgBJjZKTNIzLLzMBLFYqdbsDaQHgcKCsAqo2iHcrHarqM6IYyOIySPQdHrGNr2EKvBD6WyNHSOTq2CzGMyXLr2QHApITVLTDKzbILZareGIiA8AicFFo9gYrHyRQnPGgOqE4mkinWSnUzyIPySCyGb46TotGxs1rGbmjXkpKbpTJzHIwsWbSCGAAiYAAZmgCHlaMiKAA3EiKirK3G1XRmIzWePGfQWWyWMyhGkNJo2Q1BHoRWyOW2JcYO8GCyGu0Vwj0QQwACTDJCR0tYzYwkZxqlVWjjCbZHRTaasmf19SZTXshrClxcZpLIL5johLpFsLWdcbHaRmlgYlo0LQvsPGAAFLL0axaD68mgcABKHg8stggXO4XQmubhGepvhjBIC7aMe1jBAQkkE0zGaPxTW6SI+nHHozEMaCzXjPwLjMHQqUXe03ydIUoTdWtf3rBt5TlDFWxlSir0xGQjhA041UQBxjSeeMUzpbDHBpK1DHMUIDBcUx7GaWI4hATgKAgOANBfUF+UIqtuCYqpQPxBBBD48cLkcVCImeBxYKNCwdDw19lNXT8SJ-LZOCgdSVTAtoaX0eMTUCFkdGCDUzGLKTFOXCsP2I79xUgZyYy0z5bCaLVyV1PwaXM+KMM6PR2R0Q1ApGUslJXSs1y-DdIvrH1-UDWhos01iED8X5BOaJxLGcHL9B0GlDWNAJOmgh4LFZIILKCu0rKKsLqzKrd-xbCBapYvsECceLbBy2DAnsZN9EwrNvhMSw-HW9M9AsLkxoKkL3yI6b3TIxs6Mow8FuxZje3OIzDGcf5GvjB4qX2xoR2O8wrDOi7YiAA */
      id: 'NearbyBuyerPropertyMachine',
      context: ({ input }) => ({
        ...input,
        isSubject: input.isSubject ?? false,
        getElement: Option.none(),
      }),
      on: {
        'set-data': {
          actions: assign({
            property: ({ event }) => event.data.property,
            nearbyBuyerIDs: ({ event }) => event.data.nearbyBuyerIDs,
          }),
        },
        'click': {
          target: '.Initialized.Highlighted',
          actions: [
            sendTo(
              ({ context }) => context.parentActorRef,
              () => ({ type: 'property-marker-clicked' }),
            ),
            sendTo(
              ({ context }) => context.parentActorRef,
              ({ context }) => ({
                type: 'highlight-from-buyer-property',
                id: context.property.id,
              }),
            ),
          ],
        },
      },
      initial: 'Initializing',
      states: {
        Initializing: {
          on: {
            setup: {
              target: 'Initialized',
              actions: assign({
                getElement: ({ event }) => Option.some(event.getElement),
              }),
            },
          },
        },
        Initialized: {
          invoke: {
            src: 'hoverActor',
            input: ({ context }) => ({ getElement: context.getElement.pipe(Option.getOrThrow) }),
          },
          initial: 'Default',
          states: {
            Default: {
              on: {
                hover: 'Hovered',
              },
            },
            Hovered: {
              on: {
                unhover: 'Default',
              },
              after: {
                highlightDelay: {
                  target: 'Highlighted',
                  actions: sendTo(
                    ({ context }) => context.parentActorRef,
                    ({ context }) => ({
                      type: 'highlight-from-buyer-property',
                      id: context.property.id,
                    }),
                  ),
                },
              },
            },
            Highlighted: {
              on: {
                unhighlight: 'Default',
              },
            },
          },
          on: {
            highlight: '.Highlighted',
            unhighlight: '.Default',
          },
        },
      },
    })
  })

  export const makeHoverActor = () =>
    Effect.succeed(fromCallback<EventObject, { getElement: () => HTMLElement }>(({
      input,
      sendBack,
    }) => {
      const hoverListener = () => sendBack({ type: 'hover' })
      const unhoverListener = () => sendBack({ type: 'unhover' })

      input.getElement().addEventListener('mouseenter', hoverListener)
      input.getElement().addEventListener('mouseleave', unhoverListener)

      return () => {
        input.getElement().removeEventListener('mouseenter', hoverListener)
        input.getElement().removeEventListener('mouseleave', unhoverListener)
      }
    }))

  export type ActorRef = ActorRefFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >

  export type ActorLogic = ActorLogicFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >
}

export default NearbyBuyerPropertyMachine
