import { Box, Button, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Portal, Text } from '@chakra-ui/react'
import { EyeIcon } from 'presentation/components/Icons'
import { PropsWithChildren } from 'react'

export type AgentDescriptionPopoverButtonProps = PropsWithChildren

export const AgentDescriptionPopoverButton = (props: AgentDescriptionPopoverButtonProps) => {
  const { children } = props
  return (
    <Popover
      closeOnEsc
      placement='bottom-start'
      arrowSize={15}
      arrowPadding={50}
      offset={[0, 15]}
    >
      <PopoverTrigger>
        <Button
          variant='ghost'
          colorScheme='neutral'
          leftIcon={<EyeIcon />}
        >
          View Agent&rsquo;s Description
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          w='438px'
          minH='224px'
          maxH='328px'
        >
          <PopoverArrow />
          <Box
            overflow='auto'
            p={2}
            borderRadius={2}
          >
            <Text
              textStyle='h4'
              color='accent.blue-text'
              maxW='100%'
              textAlign='left'
            >
              Agent’s Description
            </Text>
            <Box mt={1}>
              {children}
            </Box>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
