import { createIcon } from '@chakra-ui/react'

export const ExitFullscreenIcon = createIcon({
  displayName: 'ExitFullscreenIcon',
  viewBox: '0 0 16 16',
  path: (
    <g fill='none'>
      <path
        id='Vector'
        d='M2.66797 9.33301H6.66797V13.333'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.332 6.66699H9.33203V2.66699'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.33203 6.66667L13.9987 2'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 13.9997L6.66667 9.33301'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
