import { BoxProps, Button, Flex, Text } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { useThemeType } from 'presentation/main/themes/hooks/useThemeType'

export type PagerProps = {
  currentPage: number
  totalPages: number
  onNext: () => void
  onPrev: () => void
} & BoxProps

export const Pager = (props: PagerProps) => {
  const {
    currentPage,
    totalPages,
    onNext,
    onPrev,
    ...boxProps
  } = props
  const page = `${currentPage}/${totalPages}`
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const themeType = useThemeType()
  return (
    <Flex
      justifyContent='center'
      gap={1}
      {...boxProps}
    >
      <Button
        size='xs'
        colorScheme='neutral'
        leftIcon={<ChevronLeftIcon />}
        isDisabled={isFirstPage}
        onClick={onPrev}
        sx={themeType === 'light'
          ? {}
          : { color: 'accent.blue-to-gray' }}
      >
        Prev
      </Button>
      <Text
        bgColor='grayweak.200'
        borderColor='grayweak.500'
        borderWidth={0.125}
        borderRadius='100px'
        textStyle='tagL'
        color='graystrong.500'
        py={0.5}
        px={2}
      >
        {page}
      </Text>
      <Button
        size='xs'
        colorScheme='neutral'
        rightIcon={<ChevronRightIcon />}
        isDisabled={isLastPage}
        onClick={onNext}
        sx={themeType === 'light'
          ? {}
          : { color: 'accent.blue-to-gray' }}
      >
        Next
      </Button>
    </Flex>
  )
}
