import EventEmitter from 'eventemitter3'
import { memo, useEffect, useState } from 'react'
import { ensureStringOr } from 'utils/dataAdapter'
import { ModalEmitter, ModalEvent, ModalsManager, ModalsRecord, UpdateEvent } from './createModalsManager.types'

/**
 * @TODO fix types
 * @TODO better transition
 */
export const createModalsManager = <
  MR extends ModalsRecord,
>(): ModalsManager<MR> => {
  const modals: Partial<MR> = {}

  const emitterBase = new EventEmitter()

  const emitter: ModalEmitter<MR> = {
    emit: ev => emitterBase.emit(ev.type, ev.payload),

    on: (event, callback): void => {
      emitterBase.on(event, callback)
    },

    off: (event, callback): void => {
      emitterBase.off(event, callback)
    },
  }

  type HistoryEntry = ModalEvent<MR, keyof MR>['payload']
  type History = HistoryEntry[]

  const ActiveModal = memo(() => {
    const [history, setHistory] = useState<History>([])
    const modalData = history[history.length - 1]

    // OPEN and PUSH event handler
    useEffect(() => {
      const handleEvent = (data: ModalEvent<MR, keyof MR>['payload']): void =>
        setHistory(history => [...history, data])

      emitter.on('OPEN', handleEvent)
      emitter.on('PUSH', handleEvent)

      return () => {
        emitter.off('OPEN', handleEvent)
        emitter.off('PUSH', handleEvent)
      }
    }, [])

    // REPLACE event handler
    useEffect(() => {
      const handleEvent = (data: UpdateEvent<keyof MR, MR>['payload']): void =>
        setHistory(history => {
          const historyExceptLast = history.slice(0, history.length - 1)
          return [...historyExceptLast, data]
        })

      emitter.on('REPLACE', handleEvent)

      return () => {
        emitter.off('REPLACE', handleEvent)
      }
    }, [])

    // UPDATE event handler
    useEffect(() => {
      const handleEvent = (data: UpdateEvent<keyof MR, MR>['payload']): void =>
        setHistory(history => {
          const lastEntry = history[history.length - 1]
          if (!lastEntry) return history

          const doesEntryMatch = lastEntry.key === data.key
          if (!doesEntryMatch) return history

          const historyExceptLast = history.slice(0, history.length - 1)
          return [...historyExceptLast, { ...data, props: { ...lastEntry.props, ...data.props } }]
        })

      emitter.on('UPDATE', handleEvent)

      return () => {
        emitter.off('UPDATE', handleEvent)
      }
    }, [])

    // CLOSE event handler
    useEffect(() => {
      const handleEvent = (): void => {
        setHistory([])
      }

      emitter.on('CLOSE', handleEvent)

      return () => {
        emitter.off('CLOSE', handleEvent)
      }
    }, [])

    // BACK event handler
    useEffect(() => {
      const handleEvent = (): void => {
        setHistory(history => {
          const historyExceptLast = history.slice(0, history.length - 1)
          return historyExceptLast
        })
      }

      emitter.on('BACK', handleEvent)

      return () => {
        emitter.off('BACK', handleEvent)
      }
    }, [])

    if (!modalData) return null

    const ModalComponent = modals[modalData.key]

    if (!ModalComponent) throw new Error(`Modal for key ${ensureStringOr('UNKNOWN')(modalData.key)} is not registered`)

    return (
      <>
        {/* @ts-expect-error not sure yet how to strongly type all possible modal types */}
        <ModalComponent isOpen {...modalData.props} />
      </>
    )
  })

  ActiveModal.displayName = 'ActiveModal'

  const register = <K extends keyof MR>(key: K, modal: MR[K]): void => {
    modals[key] = modal
  }

  return {
    register,
    emitter,
    ActiveModal,
  }
}
