import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { MLSStatusTrainingNotice } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingNotice/MLSStatusTrainingNotice'
import { useEffect } from 'react'

export const useMLSStatusTrainingNotice = () => {
  const shouldShow = useSettingsStore(state => {
    const settingsState = state.settingsState
    if (settingsState.status !== 'loaded') return false
    const isDone = settingsState.settings.features.cma.isPinTrainingDone
    return !isDone
  })

  const updateNotice = useNoticeStore(store => store.updateNotice)
  const handleOnClose = () => {
    updateNotice(NOTICE_KEYS.MLS_STATUS_TRAINING, null)
  }

  useEffect(() => {
    if (!shouldShow) return

    updateNotice(NOTICE_KEYS.MLS_STATUS_TRAINING,
      <MLSStatusTrainingNotice onClose={handleOnClose} />,
    )

    return () => {
      updateNotice(NOTICE_KEYS.MLS_STATUS_TRAINING, null)
    }
  }, [])
}
