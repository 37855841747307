import { Box, BoxProps, Flex, Grid, Image, useTheme } from '@chakra-ui/react'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { ListBuilderComingSoonOverlay } from 'presentation/screens/ListBuilderScreen/components/ListBuilderComingSoonOverlay'
import BodyDarkPNG from './body-dark.png'
import BodyPNG from './body.png'
import HeaderDarkPNG from './header-dark.png'
import HeaderPNG from './header.png'

export const ListBuilderDummyMobSm = (props: BoxProps) => {
  const theme = useTheme()
  const height = useLayoutStore(store => store.totalBodyHeight)
  const isDarkMode = theme.id === 'dark'
  return (
    <Grid
      gridTemplateAreas='"layer"'
      gridTemplateRows='minmax(0, 1fr)'
      minH={0}
      h={height}
    >
      <Flex
        gridArea='layer'
        flexDirection='column'
        h='full'
        minH={0}
        zIndex={1}
        {...props}
      >
        <Box bgColor='neutral.500'>
          <Image src={isDarkMode ? HeaderDarkPNG : HeaderPNG} mx='auto' w='320px' h='56px' />
        </Box>
        <Box overflow='auto' bgColor='accent.hover'>
          <Image src={isDarkMode ? BodyDarkPNG : BodyPNG} mx='auto' w='320px' />
        </Box>
      </Flex>
      <ListBuilderComingSoonOverlay gridArea='layer' zIndex={2} overflow='auto' />
    </Grid>
  )
}
