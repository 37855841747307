import * as Sentry from '@sentry/browser'
import { extraErrorDataIntegration } from '@sentry/integrations'
import { checkLocalAccessForData, getAccessStoreSingleton } from 'features/Access/infra/react/AccessState'
import { ENVIRONMENT_NAME, RELEASE_HASH, SENTRY_DSN } from 'presentation/const/env.const'

const eventLimiter: Record<string, boolean> = {}

/**
 * @TODOIMPORTANT Add userId/enterpriseId to Sentry
 */
export const setupErrorReporting = (): void => {
  Sentry.init({
    environment: ENVIRONMENT_NAME || 'MISSING',
    integrations: [extraErrorDataIntegration()],
    dsn: SENTRY_DSN,
    beforeSend: async (event, hint) => {
      // =============================================================================
      // Limit rate of events
      // =============================================================================
      const msg = typeof hint?.originalException === 'string'
        ? hint?.originalException

        : (hint?.originalException as any)?.message

      if (msg && msg in eventLimiter)
        return null

      if (msg) {
        eventLimiter[msg] = true
        const REREPORT_WAIT_TIME = 5 * 60 * 1000 // 5 minutes
        setTimeout(() => {
          eventLimiter[msg] = false
        }, REREPORT_WAIT_TIME)
      }

      // =============================================================================
      // Add more info
      // =============================================================================
      event.tags = event.tags || {}

      await getAccessStoreSingleton()
        .then(store => {
          const accessState = store.getState().local.access

          if (
            !checkLocalAccessForData(accessState)
            || accessState.data.status !== 'logged-in'
          )
            return

          event.user = { ...event.user, id: accessState.data.accessor.id }
        })

      return event
    },
  })

  Sentry.configureScope(scope => {
    const FRONTEND_VERSION = 2

    scope.setTag('environment', ENVIRONMENT_NAME || 'MISSING')
    scope.setTag('release_hash', RELEASE_HASH || 'MISSING')
    scope.setTag('frontend-version', FRONTEND_VERSION)
  })
}
