import { Effect } from 'effect'
import NearbyBuyersContext from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersContext'
import NearbyBuyersMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersMachine'
import NearbyBuyersRepo from 'features/NearbyBuyers/repository/NearbyBuyersRepo'
import NearbyBuyersRepoLive from 'features/NearbyBuyers/repository/NearbyBuyersRepoLive'
import NearbyBuyersRoutes from 'features/NearbyBuyers/routes/NearbyBuyersRoutes'
import { NearbyBuyersHistoryMobileModal } from 'features/NearbyBuyers/views/NearbyBuyersHistoryMobileScreen/NearbyBuyersHistoryMobileModal'
import NearbyBuyersScreen from 'features/NearbyBuyers/views/NearbyBuyersScreen/NearbyBuyersScreen'
import SaveBuyersToListModal from 'features/NearbyBuyers/views/SaveBuyersToListModal/SaveBuyersToListModal'
import { ErrorLib } from 'libs/errors'
import { useResponsivePropertyModal } from 'presentation/components/PropertyModal/PropertyModal.hooks'
import PropertyModalService from 'presentation/components/PropertyModal/PropertyModalService'
import AppRouterContext from 'presentation/main/Router/AppRouterContext'
import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import AppCache from 'services/AppCache'
import AppCacheLive from 'services/AppCacheLive'
import AppRouter from 'services/AppRouter'

const NearbyBuyersRoutesProviderLive = () => {
  // property details modal
  const router = AppRouterContext.useRouter()
  const propertyModalApi = useResponsivePropertyModal({
    backLabel: 'Back to Cash Buyers',
  })

  const runtime = useMemo(() =>
    Effect.runtime<NearbyBuyersMachine.Requirements>().pipe(
      Effect.provideService(NearbyBuyersRepo, NearbyBuyersRepoLive),
      Effect.provideService(AppRouter, { router }),
      Effect.provideService(AppCache, AppCacheLive),
      Effect.provideService(PropertyModalService, {
        open: propertyModalApi.actions.open,
      }),
      ErrorLib.tapCauseReporter,
      Effect.runSync,
    ), [])

  return (
    <NearbyBuyersContext.Provider
      runtime={runtime}
    >
      <Outlet />
    </NearbyBuyersContext.Provider>
  )
}

namespace NearbyBuyersRoutesLive {
  export const routes = NearbyBuyersRoutes.make({
    Provider: NearbyBuyersRoutesProviderLive,
    NearbyBuyersScreen,
    SaveToListModal: SaveBuyersToListModal,
    MobileBuyerHistory: NearbyBuyersHistoryMobileModal,
  })
}

export default NearbyBuyersRoutesLive
