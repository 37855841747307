import { SettingsLink, useBillingDefaultNotice } from 'presentation/screens/Billing/BillingDefaultNotice'
import { ControllableMembersScreen } from 'presentation/screens/MembersScreen/MembersScreen.controllable'
import { useMembersScreenUseCases } from 'presentation/screens/MembersScreen/useCases/MembersScreen.useCases'
import { FC } from 'react'

export const ControlledMembersScreen: FC = () => {
  useBillingDefaultNotice({
    breadcrumbs: [
      SettingsLink,
      { label: 'TEAM MEMBERS' },
    ],
  })
  const useCases = useMembersScreenUseCases()

  return <ControllableMembersScreen {...useCases} />
}
