import { AccessDeps } from 'features/Access/infra/react/AccessState'
import { restClient } from 'presentation/libs/client'

export const changePassword: AccessDeps['changePassword'] = async payload =>
  await restClient.post('password-reset/continue', {
    json: {
      code: payload.code,
      password: payload.password,
    },
  })
    .json()
    .then(() => ({
      status: 'success' as const,
    }))
    .catch(async error => {
      throw error
    })
