import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Lead } from 'features/PropertyDetails/infra/remote/getProperty/Lead.type'
import { parseLeadResponse } from 'features/PropertyDetails/infra/remote/getProperty/parseLeadResponse'
import { restClient } from 'presentation/libs/client'

// Define possible responses from the initial API call
type InitialResponse =
  | { confirmationKey: string }
  | { directResponse: any }
  | {
    status: 'insufficient-consumables'
    attempt: PropertyDetails.InsufficientConsumables['attempt']
  }

// Define return type that can include insufficient-consumables status
type GetLeadWithPropertyIdResult = Lead | PropertyDetails.InsufficientConsumables

export const getLeadWithPropertyId = async (parcelId: string): Promise<GetLeadWithPropertyIdResult> => {
  // First request to get confirmation key
  const initialResponse = await restClient.post(`legacy/leads/fromparcel/${parcelId}`, {
    json: {
      useIncludedConsumables: true,
    },
  })
    .then(async response => ({ directResponse: await response.json() }))
    .catch(async error => {
      // Handle 422 error with 'insufficient_usages_available' type
      if (error.response?.status === 422) {
        const errorData = await error.response.json()
        if (errorData.error?.type === 'insufficient_usages_available') {
          // Return insufficient-consumables status with proper data from the error response
          const included = errorData.error?.details?.included || {}
          return {
            status: 'insufficient-consumables',
            attempt: {
              // For 422 errors, we might not have plan name in the response
              planName: errorData.error?.details?.planName,
              refreshingConsumables: included.limit || 0,
              remainingConsumables: included.available || 0,
              consumablesRefreshDate: included.rolloverDate
                ? new Date(included.rolloverDate)
                : new Date(),
              funds: {
                available: errorData.error?.details?.funds?.available || 0,
              },
              confirmationKey: errorData.error?.confirmationKey || '',
              status: 'insufficient-funds', // Default to insufficient-funds to encourage upgrading
            },
          }
        }
      }

      // Check if it's a 409 error with confirmation key
      if (error.response?.status === 409) {
        const errorData = await error.response.json()

        // Check if we've run out of consumables
        const remainingConsumables = errorData.error?.details?.included?.available || 0

        // If we have no remaining consumables, return insufficient-consumables status
        if (remainingConsumables <= 0) {
          const details = errorData.error?.details
          return {
            status: 'insufficient-consumables',
            attempt: {
              planName: details?.planName,
              refreshingConsumables: details?.included?.refreshing || 0,
              remainingConsumables: details?.included?.available || 0,
              consumablesRefreshDate: details?.included?.nextRefreshDate ? new Date(details.included.nextRefreshDate) : new Date(),
              funds: {
                available: details?.funds?.available || 0,
              },
              confirmationKey: errorData.error?.confirmationKey || '',
              status: details?.funds?.available > 0 ? 'sufficient-funds' : 'insufficient-funds',
            },
          }
        }

        if (!errorData.error?.confirmationKey)
          throw new Error('Expected confirmation key in error response but none was found')

        return { confirmationKey: errorData.error.confirmationKey }
      }

      // If we get any other error, rethrow it
      throw error
    }) as InitialResponse

  // If we got insufficient-consumables status, return it
  if ('status' in initialResponse && initialResponse.status === 'insufficient-consumables')
    return initialResponse

  // If we got a direct response (no confirmation needed), parse and return it
  if ('directResponse' in initialResponse)
    return parseLeadResponse(initialResponse.directResponse)

  // Otherwise, we have a confirmation key and need to make a second request
  const { confirmationKey } = initialResponse as { confirmationKey: string }

  // Make the second request with the confirmation key
  return await restClient.post(`legacy/leads/fromparcel/${parcelId}`, {
    json: {
      useIncludedConsumables: true,
      confirmationKey,
    },
  })
    .json<any>()
    .then(data => parseLeadResponse(data))
    .catch(async error => {
      // Handle 422 error with 'insufficient_usages_available' type in the second request too
      if (error.response?.status === 422) {
        const errorData = await error.response.json()
        if (errorData.error?.type === 'insufficient_usages_available') {
          // Return insufficient-consumables status with proper data from the error response
          const included = errorData.error?.details?.included || {}
          return {
            status: 'insufficient-consumables',
            attempt: {
              // For 422 errors, we might not have plan name in the response
              planName: errorData.error?.details?.planName,
              refreshingConsumables: included.limit || 0,
              remainingConsumables: included.available || 0,
              consumablesRefreshDate: included.rolloverDate
                ? new Date(included.rolloverDate)
                : new Date(),
              funds: {
                available: errorData.error?.details?.funds?.available || 0,
              },
              confirmationKey: errorData.error?.confirmationKey || '',
              status: 'insufficient-funds', // Default to insufficient-funds to encourage upgrading
            },
          }
        }
      }
      throw error
    })
}
