import { GOOGLE_KEY } from 'presentation/const/libs.const'

const callbackName = 'loadGoogleMaps'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    [callbackName]?: () => void
  }
}

let promise: Promise<typeof google.maps>

export const loadGoogleMaps = async (): Promise<typeof google.maps> => {
  if (promise !== undefined) return await promise

  promise = new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject(new Error('google map cannot be loaded outside browser env'))
      return
    }

    if (window.google?.maps !== undefined) {
      resolve(window.google.maps)
      return
    }

    window[callbackName] = () => {
      resolve(window.google.maps)
    }

    const url = `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&sensor=false&key=${GOOGLE_KEY}&callback=${callbackName}`
    const scriptEl = document.createElement('script')

    scriptEl.src = url
    document.head.appendChild(scriptEl)
  })

  return await promise
}
