import { CMA } from 'features/CMA/CMA.domain'
import QueryString from 'qs'

export const createPropertyDetailsUrlFromComp = (comp: Pick<CMA.SingleComp | CMA.SubjectComp, 'address'>) => {
  const queryString = QueryString.stringify({
    line1: comp.address.line1,
    city: comp.address.city,
    state: comp.address.state,
    postalCode: comp.address.postalCode,
  })

  return `/search/from-address?${queryString}`
}
