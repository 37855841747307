import { FlexProps, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { stripParenthesis } from 'libs/string/stripParenthesis'
import { CardButton, CardFooter, CardHeader, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { BillingSummaryItem } from 'presentation/main/globalModals/PaymentDetailsModal/BillingSummarySegment'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { FailedInvoiceModalProps } from './FailedInvoiceModal.types'
import { UsePayInvoiceMutationResult } from './usePayInvoiceMutation'

export type FailedInvoiceModal = FC<FailedInvoiceModalProps>

export const createFailedInvoiceModal = (
  usePayInvoiceMutation: () => UsePayInvoiceMutationResult,
) => {
  const FailedInvoiceModal: FailedInvoiceModal = ({
    isOpen = true,
    onClose,
    onSuccess,
    onError,
    failedInvoiceSummary,
  }) => {
    const { nextChargeInfo, currentChargeInfo } = failedInvoiceSummary

    const { payInvoice, loading: isMutationLoading } = usePayInvoiceMutation()

    const billingSummaryItems: BillingSummaryItem[] = currentChargeInfo
      .items.map(item => ({
        label: item.description,
        price: item.amount,
      }))

    const handlePayNow = () => {
      payInvoice(failedInvoiceSummary.invoiceId)
        .then(onSuccess)
        .catch(onError)
    }

    const modalHeader = (
      <CardHeader>
        <CardPrimaryTitle>
          Retry Payment
        </CardPrimaryTitle>
        <CardPrimaryText
          maxW={mbp({ mobSm: 'full', tabSm: '87%', tab: '80%' })}
        >
          Your Propelio
          {' '}
          {stripParenthesis(failedInvoiceSummary.planName)}
          {' '}
          subscription
          of
          {' '}
          {formatPenniesOptionalDecUsd(currentChargeInfo.amountDue)}
          {' '}
          is
          {' '}
          <Text as='span' color='negative.500' textStyle='body-h-b'>past due</Text>
          .
          {nextChargeInfo?.dueDate && (
            <>
              {' '}
              Service will cancel on
              {dayjs(nextChargeInfo.dueDate).format('MMM D, YYYY')}
              .
            </>
          )}
          {' '}
          <Text as='span' color='link.500' textStyle='body-h-b' textTransform='uppercase'>Pay now</Text>
          {' '}
          to avoid cancellation.
        </CardPrimaryText>
      </CardHeader>
    )

    return (
      <PaymentDetailsModal
        title='Restore Plan'
        isOpen={isOpen}
        onClose={onClose}
        modalHeader={modalHeader}
        billingSummaryItems={billingSummaryItems}
        termsAndPolicyAdditionalInfo={nextChargeInfo
          ? (
            <AdditionalInfo
              amount={nextChargeInfo.amount}
              nextBillingPeriod={nextChargeInfo.dueDate}
            />
          )
          : null}
        modalFooter={params => (
          <PrimaryActions
            onClick={handlePayNow}
            isLoading={isMutationLoading}
            isPrimaryButtonDisabled={!params?.hasPaymentMethod}
          />
        )}
      />
    )
  }

  return FailedInvoiceModal
}

type PrimaryActionsProps = FlexProps & {
  onClick?: () => void
  isLoading?: boolean
  isPrimaryButtonDisabled?: boolean
}

const PrimaryActions: FC<PrimaryActionsProps> = ({
  onClick,
  isLoading,
  isPrimaryButtonDisabled,
}) => (
  <CardFooter>
    <CardButton
      variant='outline'
      colorScheme='neutral'
      onClick={goBackModal}
    >
      Cancel
    </CardButton>
    <CardButton
      variant='solid'
      colorScheme='positive'
      onClick={onClick}
      isDisabled={isPrimaryButtonDisabled}
      isLoading={isLoading}
      loadingText='Processing'
      spinnerPlacement='end'
      autoFocus
    >
      Pay Now
    </CardButton>
  </CardFooter>
)

const AdditionalInfo: FC<{ amount: number, nextBillingPeriod: Date | null }> = ({ amount, nextBillingPeriod }) => (
  <>
    The next billing will be up to &nbsp;
    <Text
      as='span'
      textStyle={mbp({ mobSm: 'body2-h-b', mob: 'body-h-b' })}
      color='modal.text'
    >
      {formatPenniesOptionalDecUsd(Math.max(amount, 0))}
    </Text>
    &nbsp; minus applicable discounts and credits
    {
      nextBillingPeriod
        ? (
          <>
            , on &nbsp;
            <Text
              as='span'
              textStyle={mbp({ mobSm: 'body2-h-b', mob: 'body-h-b' })}
              color='modal.text'
            >
              {dayjs(nextBillingPeriod).format('MMM D, YYYY')}
            </Text>
            .
          </>
        )
        : '.'
    }
&nbsp;
  </>
)
