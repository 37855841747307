import { Box, BoxProps, Grid, GridProps, useToken } from '@chakra-ui/react'
import { Location } from 'features/valueObjects/Location'
import { ID } from 'libs/id'
import { Map, useMapStore } from 'presentation/components/Map'
import { Marker as MarkerType, useCommonMarkerBehaviorsEffect } from 'presentation/components/Map/useMarkersBehaviorEffect'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { SwitchTabButton, SwitchTabButtons, SwitchTabContents, SwitchTabsWrapper } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { RowData, useEntriesTableStore } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { CompsMapMarker } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMapMarker'
import { CompsTableScroller } from 'presentation/screens/CompsScreen/hooks/CompsTableViewScroller'
import { useEffect, useRef } from 'react'
import { Marker } from 'react-map-gl'
import { isNonNull, noop } from 'remeda'
import { shallow } from 'zustand/shallow'

export type MapSectionProps = {
  rowData: RowData
} & BoxProps

export const MapSection = ({
  rowData,
  ...props
}: MapSectionProps) => {
  const { mapRef, defaultZoom, setMode } = useMapStore(store => ({
    mapRef: store.computed.getMapRef(),
    defaultZoom: store.defaultZoom,
    setMode: store.setMode,
  }), shallow)

  const subject = useEntriesTableStore(store => {
    const row = store.compStatus === 'loaded'
      ? store.rows[0]
      : null

    return row?.comp.type === 'subject-comp'
      ? row.comp
      : null
  }
  , shallow)

  useEffect(() => {
    mapRef?.resize()
  }, [mapRef])

  const borderRadius = useToken('radii', 2)

  const handlActiveTabKeyChange = (tabKey: string) => {
    if (tabKey === 'map')
      setMode('map')
    else if (tabKey === 'satellite')
      setMode('satellite')
  }

  const handleMarkerClick = (id: ID) => {
    /**
     * @NOTE only set the highlighted entry if the marker clicked is the subject.
     * If we are clicking on the map, it means the row has been expanded already.
     * Because the row has been expanded, that means the highlighted entry is already set.
     * If we are clicking the subject property marker and the row that is currently expanded
     * is not the subject property's row, then we have to set the highlighted entry.
     */
    if (id === subject?.id)
      CompsMapAndListSync.handleMapMarkerClick(id)

    CompsTableScroller.scrollToMap()
  }

  return (
    <SwitchTabsWrapper
      pos='relative'
      display='flex'
      flexDirection='column'
      onActiveTabKeyChange={handlActiveTabKeyChange}
      zIndex={2}
      {...props}
    >
      <SwitchTabButtons
        sx={{
          '& button.TabButton[aria-selected=true]': {
            bgColor: 'neutral.500',
            svg: {
              color: 'neutral.500',
            },
          },
          '& button.TabButton[aria-selected=false]': {
            bgColor: 'highlight.500',
            svg: {
              color: 'highlight.500',
            },
          },
        }}
      >
        <SwitchTabButton tabKey='map'>
          Map
        </SwitchTabButton>
        <SwitchTabButton tabKey='satellite'>
          Satellite
        </SwitchTabButton>
      </SwitchTabButtons>
      <SwitchTabContents
        flex='1 1 0'
      >
        <MapGrid
          onClick={() => handleMarkerClick(rowData.comp.id)}
        >
          <Map
            style={{
              borderRadius,
            }}
            initialViewState={{
              ...rowData?.comp.location,
              zoom: defaultZoom,
            }}
          >
            <MapMarkers rowData={rowData} />
          </Map>
        </MapGrid>
      </SwitchTabContents>
    </SwitchTabsWrapper>
  )
}

type MapMarkersProps = {
  rowData: RowData
}

const MapMarkers = (props: MapMarkersProps) => {
  const { rowData } = props

  const subject = useEntriesTableStore(store => {
    const row = store.compStatus === 'loaded'
      ? store.rows[0]
      : null

    return row?.comp.type === 'subject-comp'
      ? row.comp
      : null
  }
  , shallow)

  const currentRowData = rowData?.comp

  const compData = currentRowData.type === 'single-comp'
    ? currentRowData
    : null

  const markers: MarkerType[] = [subject, compData]
    .filter(isNonNull)
    .map(v => ({
      location: v.location,
      id: v.id,
    }))
    .filter(Location.hasLocation)

  useCommonMarkerBehaviorsEffect({
    markers,
    highlightedEntry: null,
    padding: 50,
  })

  return (
    <>
      {subject?.location && (
        <Marker {...subject.location}>
          <SubjectPropertyMarker
            markerType='pin'
            size='sm'
          />
        </Marker>
      )}

      {compData?.location && (
        <>
          <CompsMapMarker
            {...compData.location}
            isSubjectProperty={false}
            userRating={compData.userRating}
            id={compData.id}
            status={compData.status}
            listPrice={compData.listPrice}
            salePrice={compData.salePrice}
            onHover={noop}
            onHoverOut={noop}
            onMarkerClick={noop}
          />
          {/** @GENESIS */}
          {/* <PropertyMapMarker
            {...compData.location}
            classification={compData.classification}
            equityType={compData.equityType}
            isVacant={compData.isVacant}
            isForeclosure={compData.isForeclosure}
            isSenior={compData.isSenior}
            size='sm'
          /> */}
        </>
      )}
    </>
  )
}

const MapGrid = ({ children, ...props }: GridProps) => {
  const mapContainerRef = useRef(null)
  return (
    <Grid
      ref={mapContainerRef}
      h='full'
      p={1}
      backgroundColor='neutral.500'
      borderBottomRadius={3}
      borderTopRightRadius={3}
      overflow='hidden'
      boxShadow='float'
      gridTemplateAreas='"layer"'
      {...props}
    >
      <Box gridArea='layer' zIndex={1}>
        {children}
      </Box>
    </Grid>
  )
}
