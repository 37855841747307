import { Box } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { EverythingYouNeedGrid } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/EverythingYouNeedGrid'
import { ResetPasswordCard } from 'presentation/screens/AuthScreens/components/ResetPasswordCard'

export const ResetPasswordScreen = ({
  isForRetrial,
}: {
  isForRetrial?: boolean
}) => (
  <Box h='var(--app-height)'>
    <Breakpoint
      mobSm={(
        <ResetPasswordCard
          isForRetrial={isForRetrial}
          overflow='auto'
          h='100%'
        />
      )}
      mob={(
        <EverythingYouNeedGrid>
          <ResetPasswordCard isForRetrial={isForRetrial} />
        </EverythingYouNeedGrid>
      )}
    />
  </Box>
)
