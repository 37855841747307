import { usePropModalFromPreviewScreen } from '../usePropModalFromPreviewScreen'
import { ListPreviewMobileItem, ListPreviewMobileItemProps } from './ListPreviewMobileItem'

export type ListPreviewMobileItemCardProps = ListPreviewMobileItemProps

const ListPreviewMobileItemCard = ({ item, ...boxProps }: ListPreviewMobileItemCardProps) => {
  const propertyModalApi = usePropModalFromPreviewScreen()
  return (
    <ListPreviewMobileItem
      item={item}
      onClick={() => {
        propertyModalApi.actions.open(
          item.parcelId
            ? { parcelId: item.parcelId }
            : {
              address: item.address,
            },
        )
      }}
      borderWidth={0.25}
      borderRadius={3}
      {...boxProps}
    />
  )
}

export default ListPreviewMobileItemCard
