import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { PropertyDetailReportCard } from 'features/common/ConsumableAttempt/views/PropertyDetailReportCard'
import { DateLib } from 'libs/Date'
import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { toast } from 'presentation/components/Toast'
import { RouterExposer } from 'presentation/main/Router/RouterExposer'
import { FC } from 'react'

export type PropertyDetailReportModalProps = {
  isOpen?: boolean
  attempt: ConsumableAttempt.ConsumableAttemptWithoutPrice
  onClose: () => void
}

export const PropertyDetailReportModal: FC<PropertyDetailReportModalProps> = ({
  isOpen = true,
  attempt,
  onClose,
}) => {
  // Format the date for display
  const consumablesRefreshDate = DateLib.formatMMDDYYDots(attempt.consumablesRefreshDate)

  // Handle the upgrade plan action
  const handleChoosePlan = () => {
    onClose()
    // Redirect to pricing page using RouterExposer
    void RouterExposer.get().then(router => {
      router.navigate('/pricing#first-choose-plan')
    })
  }

  return (
    <ResponsiveFullscreenModal
      isOpen={isOpen}
      onClose={onClose}
      title='Property Lookup'
    >
      <PropertyDetailReportCard
        maxW='736px'
        title='Get a Property Lookup'
        subtitle='You have 0 property lookups left. To continue, upgrade your plan for more credits.'
        refreshingConsumables={attempt.refreshingConsumables}
        remainingConsumables={attempt.remainingConsumables}
        consumablesRefreshDate={consumablesRefreshDate}
        currentPlan={attempt.planName}
        onChoosePlan={handleChoosePlan}
        onCancel={() => {
          onClose()
          toast.error({
            title: 'Property lookup aborted',
          })
        }}
      />
    </ResponsiveFullscreenModal>
  )
}
