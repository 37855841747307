import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { restClient } from 'presentation/libs/client'
import qs from 'qs'

export const downloadResult = async ({
  address,
  format,
}: Skiptrace.DownloadPayload): Promise<void> =>
  await restClient.get(
    `marketing/v2/skiptrace/v3/download?${qs.stringify({
      line1: address.line1,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
      format,
    })}`,
  )
    .text()
    .then(text => downloadTextAsFile({ text, fileName: `skiptrace-${address.line1}.csv` }))

export const downloadTextAsFile = ({ text, fileName }: {
  text: string
  fileName: string
}): void => {
  const element = document.createElement('a')
  element.setAttribute('href', `data:text/plaincharset=utf-8,${encodeURIComponent(text)}`)
  element.setAttribute('download', fileName)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
