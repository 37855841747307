import { Icon, IconProps, forwardRef, useTagStyles } from '@chakra-ui/react'

export const TagLeftIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  const styles = useTagStyles()

  return (
    <Icon ref={ref} sx={styles.leftIcon} {...props} />
  )
})

export const TagRightIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  const styles = useTagStyles()

  return (
    <Icon ref={ref} sx={styles.rightIcon} {...props} />
  )
})
