import { Button, CardProps, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, Link, Text } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { PersonName } from 'features/valueObjects/PersonName'
import { EyeIcon, EyeOffIcon } from 'presentation/components/Icons'
import { CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { LEGAL_PRIVACY_URL } from 'presentation/const/env.const'
import { useOneToastAtATime } from 'presentation/libs/useOneToastAtATime'
import { FormCard, RequiredAsterisk, UnderlinedLink, ValidationFeedback } from 'presentation/screens/AuthScreens/components/FormCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { usePaymentRegistration } from './usePaymentRegistration'

type FormValues = {
  email: string
  password: string
  firstName: string
  lastName: string
}

const FREE_PLAN_IDS = ['free', 'price_1R4GIGLqWXedw9D5Y3416qV8']

export const SignUpCard = (props: CardProps) => {
  const domain = useAccessStore(store => {
    const register = store.actions.register
    const clearErrors = store.actions.clearErrors

    return {
      register: register.execute,
      isSubmitting: register.state.status === 'loading',
      clearErrors: clearErrors.execute,
      errorMsg: pipe(
        Match.value(register.state),
        Match.when({ error: { status: 'email-taken-error' } }, () =>
          'This email is already taken. Please try another one.'),
        Match.when({ error: Match.instanceOf(Error) }, ({ error }) => error.message),
        Match.orElse(() => null),
      ),
    }
  })

  useOneToastAtATime(() => {
    if (!domain.errorMsg) return null

    domain.clearErrors()

    return {
      status: 'error',
      title: 'Sign up failed',
      message: domain.errorMsg,
    }
  }, [domain.errorMsg])

  const form = useForm<FormValues>()

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    setIsPasswordVisible(!isPasswordVisible)
  }

  const isPasswordPristine = !form.formState.dirtyFields.password
  const password = form.watch('password')

  const [searchParams] = useSearchParams()
  const planId = searchParams.get('planId')
  const isPaidPlan = planId && !FREE_PLAN_IDS.includes(planId)

  // Submit registration with payment info if needed
  const submitRegistration = (formValues: FormValues, paymentMethodId?: string) => {
    void domain.register({
      email: formValues.email,
      password: formValues.password,
      name: {
        first: formValues.firstName,
        last: formValues.lastName,
        full: PersonName.combine(formValues.firstName, formValues.lastName) || '',
      },
      planId: planId || null,
      ...paymentMethodId && isPaidPlan ? { paymentMethodId } : {},
    })
  }

  // Use our payment registration hook
  const payment = usePaymentRegistration({
    isPaidPlan: !!isPaidPlan,
    onPaymentComplete: (values, paymentMethodId) => {
      submitRegistration(values, paymentMethodId)
    },
  })

  // Function to handle form submission
  const handleFormSubmit = (formValues: FormValues) => {
    // Store form values for later use
    payment.storeFormValues(formValues)

    // If payment flow is initiated, return early
    if (payment.initiatePaymentFlow())
      return


    // Otherwise submit registration directly
    submitRegistration(formValues, payment.paymentMethodId || undefined)
  }

  return (
    <FormCard
      as='form'
      onSubmit={form.handleSubmit(handleFormSubmit)}
      {...props}
    >
      <CardPrimaryTitle>
        Create your account
      </CardPrimaryTitle>

      <Flex mt={3} flexDirection='column' gap={3}>
        <FormControl isInvalid={!!form.formState.errors.email}>
          <Flex>
            <FormLabel>Email</FormLabel>
            <RequiredAsterisk />
          </Flex>
          <Input
            type='email'
            placeholder='Enter Email'
            {...form.register('email', {
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Please provide valid email address.',
              },
            })}
          />
          {form.formState.errors.email && (
            <FormErrorMessage>
              {form.formState.errors.email.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.password}>
          <Flex>
            <FormLabel>Create Password</FormLabel>
            <RequiredAsterisk />
          </Flex>
          <InputGroup>
            <Input
              placeholder='Enter Password'
              type={isPasswordVisible ? 'text' : 'password'}
              {...form.register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  // Hide message, as it's already shown in ValidationFeedback
                  message: '',
                },
              })}
            />
            <InputRightElement
              onClick={togglePasswordVisibility}
              as='button'
              type='button'
            >
              {isPasswordVisible
                ? <EyeOffIcon color='graystrong.200' />
                : <EyeIcon color='graystrong.200' />}
            </InputRightElement>
          </InputGroup>
          {form.formState.errors.password?.message && (
            <FormErrorMessage>
              {form.formState.errors.password.message}
            </FormErrorMessage>
          )}
          <ValidationFeedback
            status={
              isPasswordPristine
                ? 'pristine'
                : password.length >= 8
                  ? 'valid'
                  : 'invalid'
            }
          >
            Minimum 8 characters
          </ValidationFeedback>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.firstName}>
          <Flex>
            <FormLabel>First Name</FormLabel>
            <RequiredAsterisk />
          </Flex>
          <Input
            type='text'
            placeholder='Enter First Name'
            {...form.register('firstName', {
              required: 'First Name is required',
            })}
          />
          {form.formState.errors.firstName && (
            <FormErrorMessage>
              {form.formState.errors.firstName.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors.lastName}>
          <Flex>
            <FormLabel>Last Name</FormLabel>
            <RequiredAsterisk />
          </Flex>
          <Input
            type='text'
            placeholder='Enter Last Name'
            {...form.register('lastName', {
              required: 'Last Name is required',
            })}
          />
          {form.formState.errors.lastName && (
            <FormErrorMessage>
              {form.formState.errors.lastName.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <Text textStyle='bodyM' color='graystrong.200'>
          By continuing, you agree to our
          &nbsp;
          <UnderlinedLink href='/termsofuse'>Terms of Service</UnderlinedLink>
          {' '}
          and
          &nbsp;
          <UnderlinedLink href={LEGAL_PRIVACY_URL}>Privacy Policy</UnderlinedLink>
        </Text>
      </Flex>

      <Button
        mt={mbp({ mobSm: 'auto', mob: '5' })}
        variant='solid'
        colorScheme='special'
        type='submit'
        isLoading={domain.isSubmitting}
        isDisabled={!form.formState.isValid}
      >
        Continue
      </Button>

      <Text
        mt={3}
        color='graystrong.400'
        textAlign='center'
      >
        Already have an account?
        &nbsp;
        <Link
          as={RouterLink}
          mt={1}
          color='link'
          to='/login'
        >
          Sign in
        </Link>
      </Text>
    </FormCard>
  )
}
