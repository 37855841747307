import { MemberCardViewModel } from 'presentation/screens/MembersScreen/components/MemberCard/MemberCard.viewModel'

export type PendingMemberCardViewModel = MemberCardViewModel & {
  member: Extract<MemberCardViewModel['member'], { canResendInvite: true }>
}

const canResendInvite = (
  member: MemberCardViewModel['member'],
): member is Extract<MemberCardViewModel['member'], { canResendInvite: true }> =>
  'canResendInvite' in member && member.canResendInvite

export const pendingMemberCardViewModel = {
  canResendInvite,
}
