import { useEffect } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

let resolveNavigate: (navigate: NavigateFunction) => void
const navigatePromise = new Promise<NavigateFunction>(resolve => {
  resolveNavigate = resolve
})

export const NavigateExposer = {
  expose: (navigateFunction: NavigateFunction) => {
    resolveNavigate(navigateFunction)
  },

  useExpose: () => {
    const navigate = useNavigate()
    useEffect(() => {
      NavigateExposer.expose(navigate)
    }, [])
  },

  get: async () => await navigatePromise,
}
