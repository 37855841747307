import { CardProps, Image, Text, VStack } from '@chakra-ui/react'
import noDataImg from 'presentation/assets/img/3d/2-peeps-1-magnifying-glass.png'
import { Card } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const SkiptraceNotEnoughInfoCard = (props: CardProps) => (
  <Card
    boxShadow='primary.s'
    bg='card.bg.1'
    w='full'
    borderRadius={3}
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    {...props}
  >
    <VStack
      spacing={mbp({
        tabSm: 2,
        mob: 1,
      })}
    >
      <Image
        src={noDataImg}
        alt='no-data'
        boxSize={mbp({
          tabSm: '180px',
          mob: '128px',
          mobSm: '120px',
        })}
      />
      <Text
        textStyle={mbp({
          tabSm: 'h1',
          mob: 'h4',
          mobSm: 'bodyXLFat',
        })}
        color='graystrong.500'
        textAlign='center'
      >
        Skiptrace is not available for this address
      </Text>
      <Text
        textStyle={mbp({
          tabSm: 'bodyXLFat',
          mobSm: 'bodyMFat',
        })}
        color='graystrong.200'
        textAlign='center'
      >
        We don’t have enough info on this property to run a skiptrace.
      </Text>
    </VStack>
  </Card>
)
