import { BoxProps, Center, Flex, Grid, Image, Link, Spinner, Text, VStack } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { useGetPropertyByRouteLeadId, usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { SearchHistoryProvider } from 'features/SearchHistory/infra/react/SearchHistory.state'
import noDataImg from 'presentation/assets/img/3d/2-peeps-1-magnifying-glass.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { toast } from 'presentation/components/Toast'
import { Card } from 'presentation/components/molecules/Card'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { PropertyDetailsActions, PropertyDetailsActionsProvider } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsActionsContext'
import { useEditSqftErrorHandler } from 'presentation/screens/PropertyDetailsScreen/components/FeaturesSection/EditSqftMenuButton'
import { FeaturesSection } from 'presentation/screens/PropertyDetailsScreen/components/FeaturesSection/FeaturesSection'
import { HeaderInfoSection } from 'presentation/screens/PropertyDetailsScreen/components/HeaderInfoSection/HeaderInfoSection'
import { ListingSection } from 'presentation/screens/PropertyDetailsScreen/components/ListingSection/ListingSection'
import { MLSDisclaimerSection } from 'presentation/screens/PropertyDetailsScreen/components/MLSDisclaimerSection/MLSDisclaimerSection'
import { MarkerInfoSection } from 'presentation/screens/PropertyDetailsScreen/components/MarkerInfoSection/MarkerInfoSection'
import { OwnershipSection } from 'presentation/screens/PropertyDetailsScreen/components/OwnershipSection/OwnershipSection'
import { TablesSection } from 'presentation/screens/PropertyDetailsScreen/components/TablesSection/TablesSection'
import { ValuationSection } from 'presentation/screens/PropertyDetailsScreen/components/ValuationSection/ValuationSection'
import { VisualsSection } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/VisualsSection'
import { animationClasses } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { useCallback, useEffect } from 'react'
import { Link as RRLink, useNavigate } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

export const PropertyDetailsScreen = (props: Partial<PropertyDetailsActions>) => (
  <SearchHistoryProvider>
    <PropertyDetailsScreenDumb {...props} />
  </SearchHistoryProvider>
)

export const PropertyDetailsScreenDumb = (props: Partial<PropertyDetailsActions>) => {
  useGetPropertyByRouteLeadId()
  useResetSearchStateOnUnmount()
  useEditSqftErrorHandler()

  const selectProperty = useSelectPropertyByRouteLeadId()
  const leadId = useLeadId()

  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)
    const getPropertyState = state.actions.getProperty.state
    const error = leadId
      && getPropertyState.status === 'error'
      && 'leadId' in getPropertyState.params
      && getPropertyState.params.leadId === leadId
      && getPropertyState.error

    return {
      property,
      error,
    }
  }, shallow)

  useEffect(() => {
    if (data.error) {
      toast.error({
        title: 'Failed to Load Property Data',
        message: 'Please try again or contact support.',
      })
    }
  }, [data.error])

  const navigate = useNavigate()

  const defaultGoToSkiptrace = useCallback(() => {
    navigate(`/search/${leadId}/skiptrace`)
  }, [leadId])
  const goToSkiptrace = props.goToSkiptrace ?? defaultGoToSkiptrace

  const defaultGoToComps = useCallback(() => {
    navigate(`/search/${leadId}/comps`)
  }, [leadId])
  const goToComps = props.goToComps ?? defaultGoToComps

  return (
    <PropertyDetailsActionsProvider
      value={{
        goToSkiptrace,
        goToComps,
      }}
    >
      {!data.property
        ? <Loading />
        : data.property.status === 'with-details'
          ? (
            <Grid
              {...mbpg({
                dtLg: {
                  maxW: '1376px',
                  mx: 'auto',
                  px: 4,
                  py: 2,
                  gridGap: 2.5,
                  gridTemplate: `
                        "headerinfo headerinfo headerinfo" auto
                        "features   features   features"   auto
                        "visuals    visuals valuation"  auto
                        "ownership  listing    valuation"  264px
                        "tables     tables     tables"     auto
                        "disclaimer disclaimer disclaimer" auto / 1fr 298px 324px
                      `,
                },
                dtSm: {
                  px: 3,
                  py: 2,
                  gridGap: 3,
                  gridTemplate: `
                        "headerinfo headerinfo headerinfo" auto
                        "features   visuals    visuals"  410px
                        "features   ownership  valuation"  auto
                        "features   listing  valuation"    auto
                        "tables     tables     tables"     auto
                        "disclaimer disclaimer disclaimer" auto / 97px 48.46% 35.52%
                      `,
                },
                tabSm: {
                  px: 3,
                  py: 2,
                  gridGap: 3,
                  gridTemplate: `
                        "headerinfo headerinfo" auto
                        "features  features"    auto
                        "visuals   visuals"     410px
                        "ownership valuation"   auto
                        "listing valuation"   auto
                        "tables    tables"      auto
                        "disclaimer disclaimer" auto / 53.88% 42.77%
                      `,
                },
                mob: {
                  px: 3,
                  py: 2,
                  gridGap: 4,
                  gridTemplate: `
                    "headerinfo" auto
                    "visuals"    370px
                    "markerinfo" auto
                    "features"   auto
                    "valuation"  auto
                    "listing"    auto
                    "ownership"  auto
                    "tables"     auto
                    "disclaimer" auto / auto
                  `,
                },
                mobSm: {
                  px: 1,
                  py: 2,
                  gridGap: 3,
                  gridTemplate: `
                    "headerinfo" auto
                    "visuals"    314px
                    "markerinfo" auto
                    "features"   auto
                    "valuation"  auto
                    "listing"    auto
                    "ownership"  auto
                    "condition"  auto
                    "tables"     auto
                    "disclaimer" auto / auto
                  `,
                },
              })}
            >
              <GridItem
                gridArea='headerinfo'
                /**
                 * Must be higher than all other sections, for actions menu
                 * to not get clipped
                 */
                zIndex={2}
              >
                <HeaderInfoSection />
              </GridItem>
              <GridItem
                gridArea='features'
                /**
                 * Must be higher than all other sections EXCEPT headerinfo,
                 * for actions menu to not get clipped
                 */
                zIndex={1}
              >
                <FeaturesSection />
              </GridItem>
              <GridItem gridArea='visuals'>
                <VisualsSection />
              </GridItem>
              <GridItem gridArea='valuation' pt={mbp({ dtLg: 4 })}>
                <ValuationSection flex='1' />
              </GridItem>
              <GridItem gridArea='ownership'>
                <OwnershipSection />
              </GridItem>
              <GridItem gridArea='listing'>
                <ListingSection minW={{ base: 'auto', dtLg: '312px' }} />
              </GridItem>
              <GridItem gridArea='tables'>
                <TablesSection />
              </GridItem>
              <GridItem gridArea='disclaimer' pb={2}>
                <MLSDisclaimerSection />
              </GridItem>

              <Breakpoint
                tabSm={() => null}
                mobSm={(
                  <GridItem gridArea='markerinfo'>
                    <MarkerInfoSection />
                  </GridItem>
                )}
              />
            </Grid>
          )
          : <NoDataContent />}
    </PropertyDetailsActionsProvider>
  )
}

const GridItem = (props: BoxProps) => (
  <Flex
    alignItems='stretch'
    justifyContent='stretch'
    minW={0}
    minH={0}
    w='full'
    sx={{ '& > *': { w: 'full' } }}
    className={animationClasses.fadeIn}
    {...props}
  />
)

const NoDataCard = () => (
  <Card
    boxShadow='primary.s'
    bg='card.bg.1'
    w='full'
    borderRadius={3}
    alignItems='center'
    justifyContent='center'
    p={0}
  >
    <VStack
      spacing={mbp({
        tabSm: 2,
        mob: 1,
      })}
    >
      <Image
        src={noDataImg}
        alt='no-data'
        boxSize={mbp({
          tabSm: '180px',
          mob: '128px',
          mobSm: '120px',
        })}
      />
      <Text
        textStyle={mbp({
          tabSm: 'h1',
          mob: 'h4',
          mobSm: 'bodyXLFat',
        })}
        color='graystrong.500'
        textAlign='center'
      >
        No data available for this address.
      </Text>
      <Text
        textStyle={mbp({
          tabSm: 'bodyXLFat',
          mobSm: 'bodyMFat',
        })}
        color='graystrong.200'
        textAlign='center'
      >
        {' '}
        <Link as={RRLink} to='comps'>Comparable Sales</Link>
        {' '}
        {' '}
        can still work depending on coverage.
        <Breakpoint
          tabSm={<br />}
          mobSm=' '
        />
        <Link as={RRLink} to='/search'>Go back to Map</Link>
        {' '}
        and check other nearby properties.
      </Text>
    </VStack>
  </Card>
)

const NoDataContent = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)

  return (
    <Grid
      minH={0}
      h={height}
      gridTemplate={`
      "headerinfo" min-content
      "nodata"     1fr
    `}
      {...mbpg({
        dtLg: {
          px: 4,
          py: 2,
          gridGap: 4,
        },
        dtSm: {
          px: 3,
          py: 2,
          gridGap: 3,
        },
        tabSm: {
          px: 3,
          py: 2,
          gridGap: 3,
        },
        mob: {
          px: 3,
          py: 2,
          gridGap: 4,
        },
        mobSm: {
          px: 1,
          py: 2,
          gridGap: 3,
        },
      })}
    >
      <GridItem
        gridArea='headerinfo'
        /** Must be higher than all other sections, for actions menu to not get clipped */
        zIndex={1}
      >
        <HeaderInfoSection />
      </GridItem>

      <GridItem gridArea='nodata'>
        <NoDataCard />
      </GridItem>
    </Grid>
  )
}

const Loading = () => {
  const height = useLayoutStore(store => store.totalBodyHeight)

  return (
    <Center h={height}>
      <Spinner />
    </Center>
  )
}

const useResetSearchStateOnUnmount = () => {
  const clearProperty = usePropertyDetailsStore(store => store.actions.clearProperty.execute)

  useEffect(() => () => {
    void clearProperty()
  }, [])
}
