
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Address": [
      "AddressUnverified",
      "AddressVerified"
    ],
    "AddressLookupResult": [
      "AddressNotFoundError",
      "AddressVerified"
    ],
    "AddressSearchResult": [
      "AddressNotFoundError",
      "AddressVerified"
    ],
    "BillingEnterpriseMutationResult": [
      "BillingMutationError",
      "BillingNoSubscriptionError",
      "BillingPaymentDeclinedError",
      "BillingPaymentMethodNotFoundError",
      "BillingSubscriptionPlanNotFoundError",
      "Enterprise"
    ],
    "BillingInvoiceMutationResult": [
      "BillingInvoice",
      "BillingInvoiceNotFoundError",
      "BillingMutationError",
      "BillingPaymentDeclinedError"
    ],
    "BillingPaymentMethod": [
      "BillingACHPaymentMethod",
      "BillingCardsPaymentMethod"
    ],
    "BillingResubscribeResult": [
      "BillingNoSubscriptionError",
      "Enterprise"
    ],
    "BillingSubscription": [
      "BillingSubscriptionActive",
      "BillingSubscriptionInactive"
    ],
    "BillingSubscriptionApplyQuoteResult": [
      "BillingInvoicePastDueError",
      "BillingPaymentDeclinedError",
      "BillingSubscriptionCanceledError",
      "BillingSubscriptionChangeQuoteExpiredError",
      "BillingSubscriptionChangeQuoteNotFoundError",
      "BillingSubscriptionPlanNotFoundError",
      "Enterprise"
    ],
    "BillingSubscriptionChangeQuoteResult": [
      "BillingInvoicePastDueError",
      "BillingSubscriptionCanceledError",
      "BillingSubscriptionChangeQuote",
      "BillingSubscriptionPlanNotFoundError"
    ],
    "BillingSubscriptionInterface": [
      "BillingSubscriptionActive",
      "BillingSubscriptionInactive"
    ],
    "BillingSubscriptionPlanFeature": [
      "SubscriptionFeatureComps",
      "SubscriptionFeatureDirectMail",
      "SubscriptionFeatureLeads",
      "SubscriptionFeaturePropertyLookup",
      "SubscriptionFeatureSkiptrace",
      "SubscriptionFeatureTeam",
      "SubscriptionFeatureWebsites"
    ],
    "BillingSubscriptionProductFeatures": [
      "SubscriptionFeatureComps",
      "SubscriptionFeatureDirectMail",
      "SubscriptionFeatureLeads",
      "SubscriptionFeaturePropertyLookup",
      "SubscriptionFeatureSkiptrace",
      "SubscriptionFeatureTeam",
      "SubscriptionFeatureWebsites"
    ],
    "BillingUnsubscribeResult": [
      "BillingNoSubscriptionError",
      "Enterprise"
    ],
    "CountyEquivalent": [
      "UsCounty"
    ],
    "Error": [
      "WalletInvalidAmountError",
      "WalletPaymentMethodDeclinedError"
    ],
    "MarketingListLookupResult": [
      "MarketingList",
      "MarketingListNotFoundError"
    ],
    "MarketingListMutationResult": [
      "MarketingList",
      "MarketingListNotFoundError",
      "MarketingListParcelNotFoundError",
      "MarketingListUnsupportedTypeError"
    ],
    "MarketingListQueryResult": [
      "MarketingListBadRequestError",
      "MarketingListsConnection"
    ],
    "MlsConnectionCredentials": [
      "MlsConnectionOauthCredentials",
      "MlsConnectionSimpleCredentials"
    ],
    "MlsOrganizationLookupResult": [
      "MlsOrganization",
      "MlsOrganizationNotFoundError"
    ],
    "MlsPropertyListingLookupResult": [
      "MlsPropertyListing",
      "MlsPropertyListingNotFoundError"
    ],
    "Node": [
      "AddressVerified",
      "BillingInvoice",
      "BillingSubscriptionChangeQuote",
      "BillingSubscriptionPlan",
      "Enterprise",
      "EnterpriseUserRole",
      "FraudCheck",
      "FraudDomain",
      "FraudEmail",
      "FraudIpAddress",
      "FraudPasswordHash",
      "FraudTimezone",
      "MlsConnection",
      "MlsConnectionOauthCredentials",
      "MlsConnectionSimpleCredentials",
      "MlsOrganization",
      "Parcel",
      "ParcelPreforeclosure",
      "ParcelTaxDetails",
      "ParcelTransfer",
      "UsCounty",
      "User"
    ],
    "ParcelAddress": [
      "AddressUnverified",
      "AddressVerified"
    ],
    "ParcelContactLookupResult": [
      "ParcelContact",
      "ParcelContactNotFoundError"
    ],
    "ParcelContactUnion": [
      "ParcelContact",
      "ParcelRawContact"
    ],
    "ParcelCountyEquivalent": [
      "UsCounty"
    ],
    "ParcelLienInterface": [
      "ParcelLien",
      "ParcelPreforeclosureLien"
    ],
    "UpdateOrganizationResult": [
      "MlsOrganization",
      "MlsOrganizationNotFoundError"
    ],
    "UsCountyLookupResult": [
      "CountyNotFoundError",
      "UsCounty"
    ],
    "UserUpdateEmailAddressResult": [
      "User",
      "UserEmailTakenError",
      "UserInvalidInputError"
    ],
    "UserUpdateNameResult": [
      "User",
      "UserInvalidInputError"
    ],
    "UserUpdatePhoneNumberResult": [
      "User",
      "UserInvalidInputError"
    ],
    "WalletAddBalanceResult": [
      "WalletInvalidAmountError",
      "WalletPaymentMethodDeclinedError",
      "WalletTransaction"
    ],
    "WalletOwner": [
      "Enterprise",
      "User"
    ],
    "_TovoImportLookupResult": [
      "_TovoImport",
      "_TovoImportNotFoundError"
    ],
    "_TovoPropertyLookupResult": [
      "_TovoProperty",
      "_TovoPropertyNotFoundError"
    ]
  }
};
      export default result;
    