import { BoxProps, Flex, HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { MarkerSize } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { ReportData, ReportItem, SummaryStatistics } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportDataSwitcherStore'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export type CompsReportTableProps = {
  report: ReportData
} & BoxProps

export const CompsReportTable = ({ report, ...props }: CompsReportTableProps) => (
  <TableContainer
    overflowX='auto'
    {...props}
  >
    <Table
      layout={mbp({
        mobSm: 'fixed',
      })}
      minW='912px'
    >
      <Thead>
        <TableHeaderRow />
      </Thead>
      <Tbody
        sx={{
          /** Make last rows corner cells round */
          'tr:last-of-type td:first-of-type': {
            borderBottomLeftRadius: 2,
          },
          'tr:last-of-type td:last-of-type': {
            borderBottomRightRadius: 2,
          },
          '& td': {
            color: 'graystrong.600',
          },
        }}
      >
        <TableRow item={report.subject} />

        {report.items.map(item => <TableRow key={item.id} item={item} />)}
        {/**
           * =================
           * SUMMARY STATISTICS
           * =================
           */}
        <TableSummaryHeaderRow />

        <TableSummaryRows
          rowLabel='Minimum'
          statisticKeys='min'
          {...report.summaryStatistics}
        />
        <TableSummaryRows
          rowLabel='Maximum'
          statisticKeys='max'
          {...report.summaryStatistics}
        />
        <TableSummaryRows
          rowLabel='Average'
          statisticKeys='avg'
          {...report.summaryStatistics}
        />
      </Tbody>
    </Table>
  </TableContainer>
)

const TableRow = ({ item }: { item: ReportItem }) => {
  const markerSize: MarkerSize = useSwitchBreakpoint({ mobSm: 'xs', dtSm: 'sm' }) ?? 'sm' as MarkerSize

  return (
    <Tr key={item.id}>
      {/**
     * =================
     * ADDRESS
     * =================
     */}
      <Td
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        textAlign='left'
      >
        <HStack spacing={1}>
          {item.isSubject && <SubjectPropertyMarker size={markerSize} markerType='pin' />}
          <Flex minW={0} flexDirection='column'>
            <Tooltip label={item.address.line1}>
              <Text
                display='inline-block'
                maxW='full'
                isTruncated
                textStyle='bodyLFat'
                color='graystrong.400'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                {item.address.line1}
              </Text>
            </Tooltip>
            <Tooltip label={item.address.subdivision}>
              <Text
                display='inline-block'
                maxW='full'
                isTruncated
                textStyle='bodyM'
                color='grayweak.900'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                {item.address.subdivision}
              </Text>
            </Tooltip>
          </Flex>
        </HStack>
      </Td>

      {/**
     * =================
     * PROPERTY TYPE
     * =================
     */}
      <Td
        textAlign='left'
        overflowWrap='break-word'
        wordBreak='break-word'
        whiteSpace='normal'
        textStyle='bodyM'
      >
        {item.propertyType}
      </Td>

      {/**
     * =================
     * PROPERTY STATUS
     * =================
     */}
      <Td
        pos='relative'
        textAlign='center'
        px='0 !important'
      >
        {pipe(
          Match.value(item.propertyStatus),
          Match.when(
            status => status === 'SOLD' || status === 'LEASED',
            () => (
              <MLSStatusTag
                status={item.propertyStatus}
                date={item.saleDate ?? undefined}
                labelLayout='vertical'
              />
            ),
          ),
          Match.orElse(status => <MLSStatusTag status={status} />),
        )}
      </Td>

      {/**
     * =================
     * BEDS / BATHS / GARAGE
     * =================
     */}
      <Td pos='relative' zIndex='auto' textAlign='right'>
        {item.bedsBathsGarage}
      </Td>

      {/**
     * =================
     * YEAR BUILT
     * =================
     */}
      <Td textAlign='right'>
        {item.yearBuilt}
      </Td>

      {/**
     * =================
     * LIVING AREA SQFT
     * =================
     */}
      <Td
        textAlign='right'
        {...mbpg({
          mobSm: {
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
          },
          dtLg: {
            whitespace: 'nowrap',
            overflowWrap: 'break-word',
            wordBreak: 'normal',
          },
        })}
      >
        <Breakpoint
          mobSm={`${item.livingAreaSqft} / ${item.lotAreaAcres}`}
          dtLg={item.livingAreaSqft}
        />
      </Td>

      <Breakpoint
        dtLg={(
          <Td
            textAlign='right'
            overflowWrap='break-word'
            wordBreak='break-word'
          >
            {item.lotAreaAcres}
          </Td>
        )}
      />

      {/**
     * =================
     * LISTING PRICE
     * =================
     */}
      <Td textAlign='right'>
        <Breakpoint
          mobSm={(
            <>
              {item.listPrice}
              <br />
              /
              {item.listPricePerSqft}
            </>
          )}
          dtLg={item.listPrice}
        />
      </Td>

      <Breakpoint
        dtLg={(
          <Td textAlign='right'>
            {item.listPricePerSqft}
          </Td>
        )}
      />

      {/**
     * =================
     * SELLING PRICE
     * =================
     */}
      <Td textAlign='right'>
        <Breakpoint
          mobSm={(
            <>
              {item.salePrice}
              <br />
              /
              {item.salePricePerSqft}
            </>
          )}
          dtLg={item.salePrice}
        />
      </Td>

      <Breakpoint
        dtLg={(
          <Td textAlign='right'>
            {item.salePricePerSqft}
          </Td>
        )}
      />

      {/**
     * =================
     * DISTANCE
     * =================
     */}
      <Td textAlign='right'>{item.distance}</Td>

      {/**
     * =================
     * DAYS ON MARKET
     * =================
     */}
      <Td textAlign='right'>
        {item.daysOnMarket}
      </Td>
    </Tr>
  )
}

export const BedsBathsGarageCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '72px',
      dtLg: 'auto',
    })}
    {...props}
  >
    <Breakpoint
      mobSm={(
        <>
          Bd / Ba
          <br />
          {' '}
          / Garage
        </>
      )}
      dtLg={(
        <>
          Beds / Baths
          <br />
          {' '}
          / Garage
        </>
      )}
    />
  </Th>
)

export const YearBuiltCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '52px',
      dtSm: '64px',
      dtLg: 'auto',
    })}
    overflowWrap='break-word'
    wordBreak='break-word'
    whiteSpace='normal'
    {...props}
  >
    Yr Built
  </Th>
)

export const SqftAcreCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '56px',
        dtSm: '64px',
        dtLg: 'auto',
      })}
      {...mbpg({
        mobSm: {
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        },
        dtLg: {
          whitespace: 'nowrap',
          overflowWrap: 'break-word',
          wordBreak: 'normal',
        },
      })}
      {...props}
    >
      <Breakpoint
        mobSm='Sqft/ Acre'
        dtLg='Sqft'
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th
          textAlign='right'
          w={mbp({
            mobSm: '56px',
            dtSm: '64px',
            dtLg: 'auto',
          })}
          overflowWrap='break-word'
          wordBreak='break-word'
          {...props}
        >
          Acre
        </Th>
      )}
    />
  </>
)

export const ListingPriceCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '96px',
        dtLg: 'auto',
      })}
      {...props}
    >
      <Breakpoint
        mobSm={(
          <>
            List Price
            <br />
            /LP Sqft
          </>
        )}
        dtLg={<>List Price</>}
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th textAlign='right' {...props}>
          LP Sqft
        </Th>
      )}
    />
  </>
)

export const SalePriceCell = (props: BoxProps) => (
  <>
    <Th
      textAlign='right'
      w={mbp({
        mobSm: '96px',
        dtLg: 'auto',
      })}
      {...props}
    >
      <Breakpoint
        mobSm={(
          <>
            Sale Price
            <br />
            /LP Sqft
          </>
        )}
        dtLg={<>Sale Price</>}
      />
    </Th>

    <Breakpoint
      dtLg={(
        <Th textAlign='right' {...props}>
          SP Sqft
        </Th>
      )}
    />
  </>
)

export const DistanceCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w={mbp({
      mobSm: '64px',
      dtLg: '72px',
    })}
    {...props}
  >
    <Breakpoint
      mobSm='Dist'
      dtLg='Distance'
    />
  </Th>
)

export const DomCell = (props: BoxProps) => (
  <Th
    textAlign='right'
    w='72px'
    {...props}
  >
    DOM
  </Th>
)

const TableHeaderRow = () => (
  <Tr
    sx={{
      '& th': {
        borderBottomWidth: 0,
      },
    }}
  >
    <Th
      {...mbpg({
        dtLg: {
          w: '232px',
        },
      })}
      sx={{
        '@media print': {
          w: '220px',
        },
      }}
      textAlign='left'
    >
      Address
    </Th>

    <Th
      textAlign='left'
      {...mbpg({
        mobSm: {
          w: '72px',
        },
        dtLg: {
          w: 'auto',
        },
      })}
    >
      <Breakpoint
        dtLg={(
          <>
            Property
            <br />
            Type
          </>
        )}
        mobSm={(
          <>
            Prop
            <br />
            Type
          </>
        )}
      />
    </Th>

    <Th w='96px'>
      Property
      <br />
      Status
    </Th>

    <BedsBathsGarageCell />
    <YearBuiltCell />
    <SqftAcreCell />
    <ListingPriceCell />
    <SalePriceCell />
    <DistanceCell />
    <DomCell />
  </Tr>
)

const TableSummaryHeaderRow = () => (
  <Tr
    sx={{
      'tr&:nth-of-type(even) > td, tr&:nth-of-type(odd) > td': {
        bg: 'neutral.400',
        borderBottomWidth: 0,
      },
      /** @HACK `&&` is a specificity hack to override Chakra's default styles */
      '&& td': {
        color: 'ondark.1',
      },
      '& > td:last-of-type': {
        borderTopRightRadius: '0 !important',
      },
    }}
  >
    <Td colSpan={3}></Td>
    <BedsBathsGarageCell as={Td} />
    <YearBuiltCell as={Td} />
    <SqftAcreCell as={Td} />
    <ListingPriceCell as={Td} />
    <SalePriceCell as={Td} />
    <DistanceCell as={Td} />
    <DomCell as={Td} />
  </Tr>
)

type TableSummaryRowProps = SummaryStatistics & {
  rowLabel: string
  statisticKeys: 'min' | 'max' | 'avg'
}

const TableSummaryRows = (props: TableSummaryRowProps) => (
  <Tr h='56px'>
    <Td colSpan={3}><Text textStyle='bodyLFat' color='graystrong.400'>{props.rowLabel}</Text></Td>
    {/**
       * =================
       * BEDS / BATHS / GARAGE
       * =================
       */}
    <Td textAlign='right'>{props.bedsBathsGarage[props.statisticKeys]}</Td>
    {/**
       * =================
       * YEAR BUILT
       * =================
       */}
    <Td textAlign='right'>{props.yearBuilt[props.statisticKeys]}</Td>
    {/**
       * =================
       * LIVING AREA SQFT
       * =================
       */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.livingAreaSqft[props.statisticKeys]}
            <br />
            /
            {props.lotAreaAcres[props.statisticKeys]}
          </>
        )}
        dtLg={props.livingAreaSqft[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.lotAreaAcres[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
       * =================
       * LISTING PRICE
       * =================
       */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.listPrice[props.statisticKeys]}
            <br />
            /
            {props.listPricePerSqft[props.statisticKeys]}
          </>
        )}
        dtLg={props.listPrice[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.listPricePerSqft[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
     * =================
     * SELLING PRICE
     * =================
     */}
    <Td textAlign='right'>
      <Breakpoint
        mobSm={(
          <>
            {props.salePrice[props.statisticKeys]}
            <br />
            /
            {props.salePricePerSqft[props.statisticKeys]}
          </>
        )}
        dtLg={props.salePrice[props.statisticKeys]}
      />
    </Td>

    <Breakpoint
      dtLg={(
        <Td textAlign='right'>
          {props.salePricePerSqft[props.statisticKeys]}
        </Td>
      )}
    />

    {/**
       * =================
       * DISTANCE
       * =================
       */}
    <Td textAlign='right'>{props.distance[props.statisticKeys]}</Td>

    {/**
       * =================
       * DAYS ON MARKET
       * =================
       */}
    <Td textAlign='right'>
      {props.daysOnMarket[props.statisticKeys]}
    </Td>
  </Tr>

)
