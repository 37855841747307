import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const UnknownIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.rect
      fill='marker.strawberrypink'
      fillRule='nonzero'
      x='3'
      y='12'
      width='22px'
      height='4px'
    />
  </MarkerIconContainer>
)
