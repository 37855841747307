import { createIcon } from '@chakra-ui/react'

export const MailIcon = createIcon({
  displayName: 'MailIcon',
  viewBox: '0 0 48 49',
  path: (
    <g fill='none'>
      <path d='M8 8.5332H40C42.2 8.5332 44 10.3332 44 12.5332V36.5332C44 38.7332 42.2 40.5332 40 40.5332H8C5.8 40.5332 4 38.7332 4 36.5332V12.5332C4 10.3332 5.8 8.5332 8 8.5332Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M44 12.5332L24 26.5332L4 12.5332' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
