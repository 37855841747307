import { HStack, Spacer } from '@chakra-ui/react'
import { ListsPaginationState, useListsPagination } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { NewListButton } from 'presentation/screens/ListsScreen/component/NewListButton/NewListButton'

export const ListsFooter = () => {
  const api = useListsPagination()
  const { sbp } = useSwitchBreakpointFn()

  if (ListsPaginationState.$is('Initial')(api.pageInfo)
    || ListsPaginationState.$is('Failed')(api.pageInfo)) return null

  return (
    <HStack
      py={{
        base: 1,
        tabSm: 2,
      }}
      px={{
        base: 1,
        mob: 2,
        tabSm: 3,
        dtSm: 5,
      }}
    >
      <Breakpoint dtSm={<Spacer />} />
      <Pagination
        maxW={{ base: 'full', tabSm: '436px' }}
        colorScheme='highlight'
        currentPage={api.pageInfo.currentPage}
        totalPages={Math.ceil(api.pageInfo.totalCount / api.pageInfo.countPerPage)}
        maxPageButtons={sbp({ mobSm: 6, mob: 8 }) || 6}
        size='lg'
        onPageChange={api.setPage}
        shouldHideFillers={sbp({ mobSm: false, tabSm: true })}
        shouldUseChevron={sbp({ mobSm: true, mob: false })}
        flexGrow={{ base: 1, tabSm: 0 }}
      />
      <Breakpoint
        tabSm={(
          <>
            <Spacer />
            <NewListButton />
          </>
        )}
        dtSm={() => null}
      />
    </HStack>
  )
}

export const useIsListsFooterReady = () => {
  const api = useListsPagination()

  return !ListsPaginationState.$is('Initial')(api.pageInfo)
}
