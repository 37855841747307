import { CMA } from 'features/CMA/CMA.domain'
import { ServerCache } from 'libs/ServerCache'
import { ErrorLib } from 'libs/errors'
import { restClient } from 'presentation/libs/client'

export const updateEstimateInfo = async (
  payload: CMA.UpdateEstimateInfoPayload,
): Promise<CMA.UpdateEstimateInfoResult> => {
  ServerCache.clearType('loadCMA')

  return await restClient.post(
    `legacy/cma/update/${payload.leadId}/${payload.reportId}`,
    {
      json: payload.estimateInfo.method === 'override'
        ? { arvType: 'custom', arv: payload.estimateInfo.overrideValue }
        : payload.estimateInfo.method === 'from-avg'
          ? { arvType: 'avg_price', arv: 1 }
          : { arvType: 'avg_per_foot', arv: 1 },
    },
  )
    .json()
    // This endpoint always error. Just ignore and pray to the gods.
    .catch(error => {
      void ErrorLib.report(error)
    })
    .then(() => {})
}
