import { createIcon } from '@chakra-ui/react'

export const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  viewBox: '0 0 16 16',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 4h12M5.334 3.999V2.665a1.333 1.333 0 0 1 1.333-1.333h2.667a1.333 1.333 0 0 1 1.333 1.333V4m2 0v9.333a1.333 1.333 0 0 1-1.333 1.333H4.667a1.333 1.333 0 0 1-1.333-1.333V3.999h9.333ZM6.666 7.332v4M9.334 7.332v4'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
})
