// eslint-disable-next-line no-restricted-imports
import { BoxProps, Modal, ModalContent, ModalContentProps, ModalOverlay, ModalProps } from '@chakra-ui/react'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { PropsWithChildren } from 'react'

export type SheetProps = PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose' | 'children'>
  modalContentProps?: ModalContentProps
  modalContainerProps?: BoxProps
}>

export const Sheet = (props: SheetProps) => {
  const {
    isOpen,
    onClose,
    modalProps,
    modalContentProps,
    modalContainerProps,
    children,
  } = props

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          ...modalContainerProps,
          sx: {
            p: '0 !important',
            pt: 2,
            ...modalContainerProps?.sx,
          },
        }}

        /**
         * We have to spread this here before sx because setting sx prop in modalContentProps overrides the whole
         * sx in this ModalContent.
       */
        {...modalContentProps}

        sx={{
          ...modalContentProps?.sx,
          w: 'full',
          maxW: 'full',
          mb: '0',
          borderTopRadius: '3',
          borderBottomRadius: '0',
          pt: '7',
          border: '0',
          maxH: 'calc(var(--app-height) - 64px) !important',
          overflow: 'auto',
        }}
      >
        <ModalCloseButton
          sx={{
            bg: 'ondark.5',
            color: 'onlight.1',
          }}
          onClose={onClose}
        />
        {children}
      </ModalContent>
    </Modal>
  )
}
