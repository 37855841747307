import { createContext, useContext, useMemo, useState } from 'react'
/**
 * @WHY Most of property screens (details, comps, skiptrace) needs lead id.
 *
 *   Now we serve property screens in two ways, via router (actual main screens)
 *   and via property modal.
 *
 *   Each way has its own way to get lead id. In router way, we get lead id from
 *   router params. In modal, we request lead id right when opening the modal.
 *
 *   This context allows us to have single way to get lead id for all property
 *   screens.
 */
type LeadIdAPI = {
  leadId: string | null
  setLeadId: (leadId: string | null) => void
}

const context = createContext<LeadIdAPI>({
  leadId: null,
  setLeadId: () => {},
})

export const LeadIdProvider = ({
  leadId: leadIdFromProps,
  children,
}: {
  /** leadId from internal state will be ignored if this is provided */
  leadId?: string | null
  children?: React.ReactNode
}) => {
  const [leadId, setLeadId] = useState<string | null>(null)

  const contextValue = useMemo(() => ({
    leadId: leadIdFromProps !== undefined ? leadIdFromProps : leadId,
    setLeadId,
  }), [
    leadIdFromProps,
    leadId,
    setLeadId,
  ])

  return (
    <context.Provider value={contextValue}>
      {children}
    </context.Provider>
  )
}

export const useLeadIdContext = () =>
  useContext(context)

export const useLeadId = () =>
  useContext(context).leadId
