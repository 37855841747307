import { Center, Grid, Image, Text } from '@chakra-ui/react'
import { Settings } from 'features/Settings/domain/Settings.domain'
import { MapMode } from 'presentation/components/Map/createMapStore'
import { MapModeButtonProps } from 'presentation/components/MapModeButton/MapModeButton.props'
import { useThemeType } from 'presentation/main/themes/hooks/useThemeType'
import { mbp } from 'presentation/utils/mapBreakpoint'
import mapDarkPng from './img/map-dark.png'
import mapLightPng from './img/map-light.png'
import satellitePng from './img/satellite.png'

const MAP_IMG: Record<MapMode, Record<Settings.Theme, string>> = {
  map: {
    light: mapLightPng,
    dark: mapDarkPng,
  },
  satellite: {
    light: satellitePng,
    dark: satellitePng,
  },
}

export const MapModeButton = (props: MapModeButtonProps) => {
  const { onClick, mode, label, alt, ...boxProps } = props

  const themeType = useThemeType()

  const mapImg = getMapImg(mode, themeType)

  return (
    <Grid
      gridTemplateAreas='"main"'
      borderRadius={2}
      borderWidth={0.25}
      borderColor='graystrong.400'
      w={mbp({ mobSm: '96px', tabSm: '120px' })}
      h={mbp({ mobSm: '72px', tabSm: '96px' })}
      overflow='hidden'
      boxShadow='button-hovered'
      onClick={onClick}
      cursor='pointer'
      as='button'
      {...boxProps}
    >
      <Image
        gridArea='main'
        w='full'
        h='full'
        minH={0}
        objectFit='cover'
        src={mapImg}
        alt={alt}
        backgroundSize='120%'
      />

      <Center
        py={0.5}
        bg='overlay.dark-am'
        gridArea='main'
        alignSelf='end'
        justifySelf='stretch'
      >
        <Text
          textStyle='bodyMFat'
          color='ondark.1'
          textTransform='capitalize'
        >
          {label}
        </Text>
      </Center>
    </Grid>
  )
}

const getMapImg = (mode: MapMode, theme: Settings.Theme): string =>
  MAP_IMG[mode][theme]
