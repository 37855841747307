import { BoxProps, Grid, GridItem, VStack, chakra } from '@chakra-ui/react'
import { Option } from 'effect'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Map, MapStoreProvider } from 'presentation/components/Map'
import { MapHooks } from 'presentation/components/Map/MapHooks'
import { MLSStatusMapLegendItems } from 'presentation/components/MapLegend/MLSStatusMapLegendItems'
import { MapLegend } from 'presentation/components/MapLegend/MapLegend'
import { MapModeToggle } from 'presentation/components/MapModeToggle'
import { MapModeIconToggle } from 'presentation/components/MapModeToggle/MapModeIconToggle'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FullscreenControl as FullscreenCtrl, Marker } from 'react-map-gl'

const FullscreenControl = chakra(FullscreenCtrl)

const OwnedProperties = ({ ownerId, ...props }: {
  ownerId: string
} & BoxProps) => (
  <MapStoreProvider>
    <OwnedPropertiesMapContent ownerId={ownerId} {...props} />
  </MapStoreProvider>
)

const OwnedPropertiesMapContent = ({ ownerId, ...props }: {
  ownerId: string
} & BoxProps) => {
  const viewModel = OwnersPropertiesHooks.useOwnerDeals(ownerId)
  const mapModel = OwnersPropertiesHooks.useMapView(ownerId)

  MapHooks.useOnMapReady(ref => {
    if (Option.isNone(mapModel)) return
    mapModel.value.onMount(ref)

    return mapModel.value.onUnmount
  })

  if (
    Option.isNone(viewModel)
    || !OwnersPropertiesHooks.OwnerDealsViewModel.$is('Success')(viewModel.value)
  ) return null

  return (
    <Grid
      borderRadius='3'
      overflow='hidden'
      h='100%'
      gridTemplateAreas={'"layer"'}
      {...props}
    >
      <GridItem
        gridArea='layer'
      >
        <Map
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {viewModel.value.dealIds.map(dealId => (
            <DealPropertyMarker
              key={dealId}
              ownerId={ownerId}
              dealId={dealId}
            />
          ))}

          <FullscreenControl />
        </Map>
      </GridItem>
      <GridItem
        gridArea='layer'
        zIndex='1'
        {...mbpg({
          mobSm: {
            justifySelf: 'flex-end',
            alignSelf: 'flex-end',
            mr: 2,
            mb: 3,
          },
          tabSm: {
            justifySelf: 'flex-start',
            alignSelf: 'flex-end',
            ml: 3,
            mb: 3,
          },
        })}
      >
        <Breakpoint
          mobSm={<MapModeIconToggle />}
          tabSm={(
            <VStack gap={1}>
              <MapLegend>
                <MLSStatusMapLegendItems />
              </MapLegend>
              <MapModeToggle />
            </VStack>
          )}
        />
      </GridItem>
    </Grid>
  )
}

export default OwnedProperties

const DealPropertyMarker = ({
  ownerId,
  dealId,
}: {
  ownerId: string
  dealId: string
}) => {
  const deal = OwnersPropertiesHooks.useOwnerDeal(ownerId, dealId)

  if (Option.isNone(deal)) return null

  const property = deal.value.relation.property

  return (
    <Marker
      latitude={property.location.latitude}
      longitude={property.location.longitude}
      anchor='bottom'
    >
      <PropertyMarker
        classification={property.ownershipInfo.classification.pipe(Option.getOrNull)}
        equityType={property.valueInfo.equityType.pipe(Option.getOrNull)}
        isForeclosure={property.valueInfo.isForeclosure}
        isSenior={property.ownershipInfo.isSenior}
        isVacant={property.ownershipInfo.isVacant}
        markerType='pin'
        size='md'
      />
    </Marker>
  )
}
