import { Match, pipe } from 'effect'

export type ListType =
  | 'property-lists'
  | 'cash-buyers'
  | 'private-lenders'
  | 'drive'

export const ListType = {
  toHumanReadable: (type: ListType) =>
    pipe(
      Match.value(type),
      Match.when('property-lists', () => 'Properties'),
      Match.when('cash-buyers', () => 'Cash Buyers'),
      Match.when('private-lenders', () => 'Private Lenders'),
      Match.when('drive', () => 'Driving for Dollars'),
      Match.exhaustive,
    ),
}
