import { Box, BoxProps, Button, Grid } from '@chakra-ui/react'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { ExclamationCircleIcon } from 'presentation/components/Icons'
import { MLSStatusMultipleTag, MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { useMLSStatusTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/MLSStatusTraining/MLSStatusTrainingModal/MLSStatusTrainingModal.api'

export const MLSStatusMapLegendItems = (props: BoxProps) => {
  const api = useMLSStatusTrainingModal()
  const mlsItems = MLSStatus.toArray()
    .filter(status => status !== 'OFF_MARKET')
    .map((status, i) => (
      <MLSStatusTag status={status} key={i} w='full' />
    ))

  return (
    <Box {...props}>
      <Grid
        columnGap={2}
        rowGap={2}
        gridTemplateColumns='repeat(2, minmax(0, 1fr))'
        gridTemplateRows='repeat(6, 1fr)'
        gridAutoFlow='column'
      >
        <SubjectPropertyTag w='full' />
        {mlsItems.slice(0, 4)}
        <MLSStatusMultipleTag w='full' />
        {mlsItems.slice(4)}
      </Grid>
      <Button
        mt={3}
        variant='outline'
        colorScheme='special'
        leftIcon={<ExclamationCircleIcon boxSize={3} />}
        w='full'
        onClick={() => api.actions.open()}
      >
        Learn More
      </Button>
    </Box>
  )
}
