import { Match, pipe } from 'effect'
import { COMPS_SORT_VALUES as IMPORTED_COMPS_SORT_VALUES, CompsSort as ImportedCompsSort } from 'features/Settings/valueObjects/CompsSort'
import { ArrowDownIcon, ArrowUpIcon, CalenderIcon, CropIcon } from 'presentation/components/Icons'
import { ClockIcon } from 'presentation/components/Icons/ClockIcon'
import { FullscreenArrowIcon } from 'presentation/components/Icons/FullscreenArrowIcon'
import { MapMarkerIcon } from 'presentation/components/Icons/MapMarkerIcon'
import { TagIcon } from 'presentation/components/Icons/TagIcon'

export const COMPS_SORT_VALUES = IMPORTED_COMPS_SORT_VALUES

const CMA_ENTRIES_SORT_DEFAULT_VALUES = [
  'PRICE_ASC',
  'SQUARE_FEET_ASC',
  'PPSQFT_ASC',
  'YEAR_BUILT_ASC',
  'SOLD_DATE_DESC',
  'DOM_ASC',
  'DISTANCE_FAR_TO_NEAR',
] as const

export type CMAEntriesSortMenuValue = ImportedCompsSort

/**
 * @TODO Change sort value data type so that we can use better exhaustive
 * pattern matching instead of doing `value.includes()` which is weak in
 * terms of typing
 *
 * For example, the following shape can significantly trim down the amount of
 * logic in methods and would also make extending sort values much more straight
 * forward (currently each method is very fragile when extending sort values.):
 *
 * { key: 'SQUARE_FEET', label: 'Square Feet' }
 *
 * Also order (ASC/DESC) should probably be tracked by state as well instead of
 * being included with the sort type key
 */
export const CMAEntriesSortMenuValue = {
  toArray: () => COMPS_SORT_VALUES,
  toDefaultsArray: () => CMA_ENTRIES_SORT_DEFAULT_VALUES,
  getLabel: (value: ImportedCompsSort, isSelected: boolean) => {
    const label = pipe(
      Match.value(value),
      Match.when(value => value.includes('PRICE'), () => 'Price'),
      Match.when(value => value.includes('YEAR_BUILT'), () => 'Year Built'),
      Match.when(value => value.includes('SOLD_DATE'), () => 'Sold Date'),
      Match.when(value => value.includes('SQUARE_FEET'), () => 'Square Feet'),
      Match.when(value => value.includes('PPSQFT'), () => 'Sold Price per Square Foot'),
      Match.when(value => value.includes('DOM'), () => 'Days on Market'),
      Match.when(value => value.includes('DISTANCE'), () => 'Distance'),
      Match.orElse(() => null),
    )

    if (!isSelected || label === null) return label

    return pipe(
      Match.value(value),
      Match.when(v => v.includes('DESC') || v.includes('FAR_TO_NEAR'),
        v =>
          v.includes('YEAR_BUILT') || v.includes('SOLD_DATE')
            ? `${label} (new to old)`
            : `${label} (high to low)`),
      Match.when(v => v.includes('ASC') || v.includes('NEAR_TO_FAR'),
        v =>
          v.includes('YEAR_BUILT') || v.includes('SOLD_DATE')
            ? `${label} (old to new)`
            : `${label} (low to high)`),
      Match.orElse(() => label),
    )
  },
  getIcon: (value: CMAEntriesSortMenuValue, isSelected: boolean) => {
    if (isSelected) {
      return pipe(
        Match.value(value),
        Match.when(value => value.includes('DESC') || value.includes('FAR_TO_NEAR'),
          () => ArrowDownIcon),
        Match.when(value => value.includes('ASC') || value.includes('NEAR_TO_FAR'),
          () => ArrowUpIcon),
        Match.orElse(() => null),
      )
    }

    return pipe(
      Match.value(value),
      Match.when(value => value.includes('PRICE'), () => TagIcon),
      Match.when(value => value.includes('YEAR_BUILT'), () => CalenderIcon),
      Match.when(value => value.includes('SOLD_DATE'), () => CalenderIcon),
      Match.when(value => value.includes('SQUARE_FEET'), () => FullscreenArrowIcon),
      Match.when(value => value.includes('PPSQFT'), () => CropIcon),
      Match.when(value => value.includes('DOM'), () => ClockIcon),
      Match.when(value => value.includes('DISTANCE'), () => MapMarkerIcon),
      Match.when(value => value.includes('DESC') || value.includes('FAR_TO_NEAR'),
        () => ArrowUpIcon),
      Match.when(value => value.includes('ASC') || value.includes('NEAR_TO_FAR'),
        () => ArrowDownIcon),
      Match.orElse(() => null),
    )
  },
  getInverseValue: (value: CMAEntriesSortMenuValue): CMAEntriesSortMenuValue => pipe(
    Match.value(value),
    Match.when('PRICE_ASC', () => 'PRICE_DESC' as const),
    Match.when('PRICE_DESC', () => 'PRICE_ASC' as const),
    Match.when('YEAR_BUILT_ASC', () => 'YEAR_BUILT_DESC' as const),
    Match.when('YEAR_BUILT_DESC', () => 'YEAR_BUILT_ASC' as const),
    Match.when('SOLD_DATE_ASC', () => 'SOLD_DATE_DESC' as const),
    Match.when('SOLD_DATE_DESC', () => 'SOLD_DATE_ASC' as const),
    Match.when('SQUARE_FEET_ASC', () => 'SQUARE_FEET_DESC' as const),
    Match.when('SQUARE_FEET_DESC', () => 'SQUARE_FEET_ASC' as const),
    Match.when('PPSQFT_ASC', () => 'PPSQFT_DESC' as const),
    Match.when('PPSQFT_DESC', () => 'PPSQFT_ASC' as const),
    Match.when('DOM_ASC', () => 'DOM_DESC' as const),
    Match.when('DOM_DESC', () => 'DOM_ASC' as const),
    Match.when('DISTANCE_FAR_TO_NEAR', () => 'DISTANCE_NEAR_TO_FAR' as const),
    Match.when('DISTANCE_NEAR_TO_FAR', () => 'DISTANCE_FAR_TO_NEAR' as const),
    Match.exhaustive,
  ),
  isSelected: (value: CMAEntriesSortMenuValue, selectedValue: CMAEntriesSortMenuValue) =>
    pipe(
      Match.value(value),
      Match.when(value => value.includes('PRICE'), () => selectedValue.includes('PRICE')),
      Match.when(value => value.includes('YEAR_BUILT'), () => selectedValue.includes('YEAR_BUILT')),
      Match.when(value => value.includes('SOLD_DATE'), () => selectedValue.includes('SOLD_DATE')),
      Match.when(value => value.includes('SQUARE_FEET'), () => selectedValue.includes('SQUARE_FEET')),
      Match.when(value => value.includes('PPSQFT'), () => selectedValue.includes('PPSQFT')),
      Match.when(value => value.includes('DOM'), () => selectedValue.includes('DOM')),
      Match.when(value => value.includes('DISTANCE'), () => selectedValue.includes('DISTANCE')),
      Match.orElse(() => false),
    ),

}
