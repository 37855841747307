import { PartsStyleFunction, StyleFunctionProps } from '@chakra-ui/theme-tools'
import { containedInputBaseStyles } from 'presentation/components/Input/InputLegacy.theme'
import { textStyles } from '../../main/themes/common/textStyles.common.theme'

const containedBaseStyles: PartsStyleFunction = ({ value, isInvalid, isSuccess }) => {
  const base = containedInputBaseStyles({ value, isInvalid, isSuccess })

  return {
    field: {
      borderColor: base.field.borderColor,
      borderWidth: base.field.borderWidth,
      borderRadius: base.field.borderRadius,
      boxShadow: base.field.boxShadow,
      bg: base.field.bg,
      color: base.field.color,
      _placeholder: base.field._placeholder,
      _focus: base.field._focus,
      _disabled: base.field._disabled,
      pr: 4,
    },
    icon: {
      insetEnd: 0,
      w: 4,
      color: base.field._placeholder.color,
    },
  }
}

export const selectLegacyTheme = {
  baseStyle: {
    color: 'font-2',
  },
  sizes: {},
  variants: {
    'contained-md': (props: StyleFunctionProps) => ({
      ...containedBaseStyles(props),
      field: {
        ...containedBaseStyles(props).field,
        ...textStyles.body,
        h: 5,
        px: 2,
      },
    }),

    'contained-sm': (props: StyleFunctionProps) => ({
      ...containedBaseStyles(props),
      field: {
        ...containedBaseStyles(props).field,
        ...textStyles.body3,
        h: 3.5,
        px: 1,
      },
    }),
  },
  defaultProps: {},
}
