import { ApolloError } from '@apollo/client'
import { Data, Effect, Option, RequestResolver, String, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'
import { MARKETINGLIST_PAGINATION_LIMIT } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByCursorResolver/toDomain'
import { GET_PARCEL_MARKETING_LISTS } from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/schema'
import toDomain from 'features/ListBuilder/infra/repo/MarketingListRepoLive/GetByParcelResolver/toDomain'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { apolloClient } from 'presentation/libs/client'

class ApolloEmptyEdgeError extends Data.TaggedError('ApolloEmptyEdgeError')<{
  message: string
}> {}

const GetNextByParcelIdResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.GetNextByParcel) => pipe(
    Effect.tryPromise({
      try: async () =>
        await apolloClient.query({
          query: GET_PARCEL_MARKETING_LISTS,
          variables: {
            first: MARKETINGLIST_PAGINATION_LIMIT,
            after: Option.getOrNull(request.cursor),
            parcelId: request.parcelId,
          },
          fetchPolicy: 'no-cache',
        }),
      catch: error => {
        if (error instanceof ApolloError) {
          if (String.includes('count')(error.message))
            return new ApolloEmptyEdgeError({ message: error.message })

          return new UnknownException({ message: error.message })
        }

        return new UnknownException({ message: JSON.stringify(error) })
      },
    }),
    Effect.andThen(result => toDomain(result.data)),
    Effect.catchTag('ApolloEmptyEdgeError', () => Effect.succeed<MarketingListRepo.GetByCursorResult>({
      countPerPage: MARKETINGLIST_PAGINATION_LIMIT,
      firstCursor: Option.none(),
      lastCursor: Option.none(),
      lists: [],
      totalCount: 0,
    })),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default GetNextByParcelIdResolver
