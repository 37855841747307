import { isNotNull } from 'effect/Predicate'
import { CMA } from 'features/CMA/CMA.domain'
import { NumberLib } from 'libs/Number'

type Comp = Pick<CMA.SubjectComp | CMA.SingleComp,
  | 'bedroomsCount'
  | 'bathroomsCountInfo'
  | 'livingAreaSqft'
>

export const toBedBathsLivingSqft = (comp: Comp, isAbbreviated?: boolean): string => {
  const isAbbr = isAbbreviated ?? true
  const bedsLabel = isAbbr ? 'bd' : 'beds'
  const bathsLabel = isAbbr ? 'ba' : 'baths'
  const sqftLabel = 'sqft'

  const beds = isNotNull(comp.bedroomsCount)
    ? `${comp.bedroomsCount} ${bedsLabel}`
    : `-- ${bedsLabel}`

  const baths = isNotNull(comp.bathroomsCountInfo)
    && isNotNull(comp.bathroomsCountInfo.total)
    ? `${comp.bathroomsCountInfo.total} ${bathsLabel}`
    : `-- ${bathsLabel}`

  const size = NumberLib.formatCODoDD(comp.livingAreaSqft)

  const sqft = size === `-- ${sqftLabel}` ? size : `${size} ${sqftLabel}`

  return [beds, baths, sqft].join(' / ')
}
