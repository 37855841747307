import { gql } from '__generated__'

export const GET_PARCEL_MARKETING_LISTS = gql(/* GraphQL */`
query GetParcelMarketingList($parcelId: String!, $first: Int, $last: Int, $before: ID, $after: ID) {
  parcel(id: $parcelId) {
    marketingLists(first: $first, last: $last, before: $before, after: $after) {
      ... on MarketingListsConnection {
        edges {
          cursor
          node {
            id
            name
            createdAt
            updatedAt
            type
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          totalCount
        }
      }
      ... on MarketingListBadRequestError {
        message
        field
      }
    }
    id
  }
}
`)
