import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Grid, GridProps, Spinner, Text } from '@chakra-ui/react'
import { ErrorLib, ProgramException } from 'libs/errors'
import { LAYOUT_CONTENT_MAX_W } from 'presentation/layouts/Layout'
import { LayoutBodyHeight } from 'presentation/layouts/Layout/components/LayoutBody'
import { Member } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { MembersScreenViewModel } from 'presentation/screens/MembersScreen/MembersScreen.viewModel'
import { MemberCard } from 'presentation/screens/MembersScreen/components/MemberCard/MemberCard'
import { PendingMemberCard } from 'presentation/screens/MembersScreen/components/MemberCard/PendingMemberCard'
import { pendingMemberCardViewModel } from 'presentation/screens/MembersScreen/components/MemberCard/PendingMemberCard.viewModel'
import { PlanCard } from 'presentation/screens/MembersScreen/components/PlanCard/PlanCard'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { px } from 'presentation/utils/px'
import { FC, PropsWithChildren, useEffect } from 'react'
import { filter, pipe, sort } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'
import './MembersScreen.modals.register'

const SIDEBAR_W = 224

export const MembersScreen: FC<MembersScreenViewModel> = viewModel => {
  const isReady = viewModel.status !== 'loading'
    && viewModel.status !== 'error'

  const isUnexpectedState = isReady && viewModel.members === null

  useEffect(() => {
    if (isUnexpectedState)
      void ErrorLib.report(new ProgramException('Unexpected state: ready but members is null'))
  }, [isUnexpectedState])

  if (!isReady || viewModel.members === null) {
    return (
      <LayoutBodyHeight
        w='full'
        display='flex'
        alignItems='center'
        justifyContent='center'
        maxW={px(LAYOUT_CONTENT_MAX_W - SIDEBAR_W)}
      >
        <Spinner />
      </LayoutBodyHeight>
    )
  }

  const enabledMemberViewModels = pipe(
    viewModel.members,
    filter(member => member.isEnabled),
    sort((a, b) =>
      prioritizeOwner(viewModel.owner.id)(a, b)
      || prioritizeCurrentUser(viewModel.me.id)(a, b)
      || prioritizeActivated(a, b)),
  )

  const disabledMemberViewModels = viewModel.members.filter(member => !member.isEnabled)

  return (
    <Container>
      <CardsContainer>
        {viewModel.me.id === viewModel.owner.id && (
          <PlanCard
            owner={viewModel.owner}
            plan={viewModel.plan}
            onInviteMembers={viewModel.onInviteMembers}
          />
        )}
        {enabledMemberViewModels.map(
          member =>
            pendingMemberCardViewModel.canResendInvite(member)
              ? (
                <PendingMemberCard
                  key={member.id}
                  member={member}
                />
              )
              : (
                <MemberCard
                  key={member.id}
                  member={member}
                />
              )
          ,
        )}
      </CardsContainer>

      <DeactivatedSection
        count={disabledMemberViewModels.length}
        isExpanded={viewModel.isDeactivatedSectionExpanded}
        onClick={viewModel.onDeactivatedSectionToggle}
      >
        <CardsContainer>
          {disabledMemberViewModels.length > 0
            ? disabledMemberViewModels.map(member => (
              <MemberCard
                key={member.id}
                member={member}
              />
            ))
            : null /* return null to remove minimum height of CardsContainer */}
        </CardsContainer>
      </DeactivatedSection>
    </Container>
  )
}

const DeactivatedSection: FC<PropsWithChildren<{
  count: number
  isExpanded?: boolean
  onClick?: () => void
}>> = ({ count, isExpanded, onClick, children }) => (
  <Accordion
    allowToggle={!!count}
    // This is the only way to control the expand outside
    // please check the Accordion documentation in chakra-ui for more info.
    index={isExpanded ? [0] : []}
    onChange={onClick}
    {...mbpg({
      mobSm: { mt: 3 },
      tabSm: { mt: 5 },
      dtSm: { mt: 3 },
      dtLg: { mt: 4 },
    })}
    sx={{
      '& .chakra-collapse': {
        overflow: 'visible !important', // Prevents the shadows of cards from being cut.
      },
    }}
  >
    <AccordionItem sx={{ border: 0 }}>
      <AccordionButton
        bg='special.300'
        borderRadius='1'
        py='1'
        px='3'
        borderColor='none'
        display='flex'
        justifyContent='space-between'
        _hover={{
          bg: undefined, // Do not allow chakra to add a color on hover
        }}
      >
        <Text
          textStyle='bodyLFat'
          color='ondark.1'
        >
          Deactivated (
          {count}
          )
        </Text>
        <AccordionIcon color='ondark.1' />
      </AccordionButton>
      <AccordionPanel
        p={0}
        {...mbpg({
          mobSm: { pt: 3 },
          tabSm: { pt: 5 },
          dtSm: { pt: 3 },
          dtLg: { pt: 4 },
        })}
      >
        {children}
      </AccordionPanel>

    </AccordionItem>
  </Accordion>
)

const CardsContainer: FC<PropsWithChildren> = ({ children }) => {
  const hasChildren = isNonNullable(children)
  return (
    <Grid
      gridTemplateColumns='repeat(auto-fill, minmax(304px, 1fr))'
      gridTemplateRows={`repeat(auto-fill, minmax(${hasChildren ? 232 : 0}px, auto))`}
      {...mbpg({
        mobSm: { gap: 3 },
        tabSm: { columnGap: 4, rowGap: 5 },
        dtSm: { rowGap: 3 },
        dtLg: { rowGap: 4 },
      })}
    >
      {children}
    </Grid>
  )
}

const Container: FC<GridProps> = ({ children }) => (
  <Box
    {...mbpg({
      mobSm: {
        px: 1,
        py: 3,
      },
      mob: {
        px: 3,
      },
      tabSm: {
        p: 5,
      },
      dtLg: {
        p: 7,
      },
    })}
    /**
     * For some reason the sub Box container does not render and adding this
     * fixes it. This is taken from the Billing screen.
     */
    sx={{ WebkitTransform: 'translate3d(0, 0, 0)' }}
    maxW={px(LAYOUT_CONTENT_MAX_W - SIDEBAR_W)}
  >
    {children}
  </Box>
)

const prioritizeOwner = (ownerId: string) => (a: Member, b: Member) => {
  if (a.id === ownerId) return -1
  else if (b.id === ownerId) return 1
  else return 0
}

const prioritizeCurrentUser = (currentUserId: string) => (a: Member, b: Member) => {
  if (a.id === currentUserId) return -1
  else if (b.id === currentUserId) return 1
  else return 0
}

const prioritizeActivated = (a: Member, b: Member) => {
  if (a.isEmailConfirmed && !b.isEmailConfirmed) return -1
  if (!a.isEmailConfirmed && b.isEmailConfirmed) return 1
  return 0
}
