import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { DistanceFilterCardDumb } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/DistanceFilterCard/DistanceFilterCard.dumb'
import { DistanceFilterCardDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/components/DistanceFilterCard/DistanceFilterCard.dumb.props'
import { useState } from 'react'

type Props = DistanceFilterCardDumbProps

export const DistanceFilterCard = () => {
  const [isOpen, setIsOpen] = useState(false)

  const reportState = useCMAStore(state => state.local.report)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: Props['onChange'] = value => {
    void filterComps({
      distanceMiles: value,
    })
  }

  if (!checkStateHasReportData(reportState)) return null

  const value = reportState.data.filters.distanceMiles

  return (
    <DistanceFilterCardDumb
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      value={value}
      onChange={handleOnValueChange}
    />
  )
}
