import { Button, ButtonProps, CardProps, Flex, Grid, Text } from '@chakra-ui/react'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { SubjectPropertyIcon } from 'presentation/components/Icons'
import { MLSStatusMultipleTag, MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Card, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo, CardSegment } from 'presentation/components/molecules/Card/CardCombo'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SubjectPropertyTag } from 'presentation/screens/CompsScreen/components/SubjectPropertyTag/SubjectPropertyTag'
import { usePropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal/PropertyMarkerTrainingModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'

export type MLSStatusTrainingCardProps = {
  onClose: () => void
}

export const MLSStatusTrainingCard = (props: MLSStatusTrainingCardProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const api = usePropertyMarkerTrainingModal()
  const isDesktopSmall = sbp({ dtSm: true, dtLg: false }) ?? false

  const signalTrainingDone = useSettingsStore(state => () => {
    const updateSettings = state.actions.updateSettings.execute
    void updateSettings({
      settings: {
        features: {
          cma: {
            isPinTrainingDone: true,
          },
        },
      },
    })
  })

  const handleDone = () => {
    void signalTrainingDone()
    props.onClose()
  }

  return (
    <Card size='modal.default.w'>
      <Breakpoint mob={<CardPrimaryTitle>Property Status</CardPrimaryTitle>} />

      <CardPrimaryText>
        To enhance your property comparison experience, we&rsquo;ve assigned colors to indicate property statuses,
        along with additional information to make understanding the data even easier.
      </CardPrimaryText>

      <Grid
        mt={3}
        gridTemplateColumns={mbp({
          mobSm: 'minmax(0, 1fr)',
          tabSm: 'repeat(2, minmax(0, 1fr))',
          dtSm: 'repeat(3, minmax(0, 1fr))',
        })}
        gridTemplateRows='minmax(0, 1fr)'
        rowGap={3}
        columnGap={3}
        alignItems='stretch'
      >
        <CustomCardCombo gridColumn={mbp({ mobSm: 'span 1', tabSm: 'span 2', dtSm: 'span 1' })}>
          <CardComboTopSegment>
            <SubjectPropertyIcon boxSize={4} />
            <SubjectPropertyMarker markerType='pin' size='xs' />
            <SubjectPropertyTag />
          </CardComboTopSegment>
          <CardComboBottomSegment color='accent.purple-to-gray' bgColor='special.50'>
            We use a star icon and lavender/purple color for the subject property.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment {...isDesktopSmall && { py: '1.5 !important', flex: '0 1 0' }}>
            <MLSStatusTag status='FOR_SALE' />
            <MLSStatusTag status='FOR_LEASE' />
            <MLSStatusTag status='SALE_OR_LEASE' />
          </CardComboTopSegment>
          <CardComboBottomSegment color='marker.darkgreen' bgColor='marker.mintgreen'>
            For active properties like for sale, for rent, or sale/rent, we use the green color.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment {...isDesktopSmall && { py: '1.5 !important', h: '72px' }}>
            <MLSStatusTag status='CANCELED' />
            <MLSStatusTag status='EXPIRED' />
            <MLSStatusTag status='WITHDRAWN' />
          </CardComboTopSegment>
          <CardComboBottomSegment color='ondark.1' bgColor='marker.gray'>
            For inactive properties like for canceled, for expired, or withdrawn, we use the gray color.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment>
            <MLSStatusTag status='SOLD' date={new Date(2023, 10, 21)} />
          </CardComboTopSegment>
          <CardComboBottomSegment color='marker.maroon' bgColor='marker.pink'>
            For sold properties, we use the red color and include the sold date.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment>
            <MLSStatusTag status='PENDING' />
          </CardComboTopSegment>
          <CardComboBottomSegment color='marker.oxfordblue' bgColor='marker.babyblue'>
            For pending properties, we use the blue color.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment>
            <MLSStatusTag status='OFF_MARKET' />
          </CardComboTopSegment>
          <CardComboBottomSegment color='grayweak.200' bgColor='marker.darkgray'>
            For properties that are not listed in MLS, we use the dark gray color.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <CustomCardCombo>
          <CardComboTopSegment>
            <MLSStatusMultipleTag />
          </CardComboTopSegment>
          <CardComboBottomSegment color='graystrong.600' bgColor='marker.cream'>
            For multiple properties, we use the yellow/gold colors.
          </CardComboBottomSegment>
        </CustomCardCombo>

        <Card
          gridColumn={mbp({ mobSm: 'span 1', tabSm: 'span 2' })}
          colorScheme='card.bg.2'
          size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
          shadow='primary.m'
        >
          <Text
            textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })}
            color='graystrong.400'
          >
            <Text
              as='span'
              textStyle={mbp({ mobSm: 'bodyMHeavy', tabSm: 'bodyLHeavy' })}
              color='inherit'
            >
              Note:
            </Text>
            &nbsp;The colors used for property statuses do not apply to these owner indication pins and tags.
            Click the button below to learn more.
          </Text>

          <Flex
            mt={2}
            flexDirection={mbp({ mobSm: 'column', tabSm: 'row' })}
            alignItems='center'
            gap={2}
          >
            <Flex flexDirection='row' gap={0.5} alignItems='center'>
              <PropertyMarker
                markerType='tag'
                classification='TRUST'
                equityType='LOW'
                isVacant={false}
                isForeclosure={false}
                isSenior={false}
                size='sm'
              />

              <PropertyMarker
                markerType='tag'
                classification='BANK'
                equityType='FREE_AND_CLEAR'
                isVacant={false}
                isForeclosure={false}
                isSenior={false}
                size='sm'
              />

              <PropertyMarker
                markerType='pin'
                classification='IN_STATE'
                equityType='LOW'
                isVacant={true}
                isForeclosure={false}
                isSenior={false}
                size='xs'
              />

              <PropertyMarker
                markerType='pin'
                classification='OWNER_OCCUPIED'
                equityType='FREE_AND_CLEAR'
                isVacant={true}
                isForeclosure={true}
                isSenior={true}
                size='xs'
              />

              <PropertyMarker
                markerType='pin'
                classification='CORPORATE'
                equityType='UPSIDE_DOWN'
                isVacant={true}
                isForeclosure={true}
                isSenior={false}
                size='xs'
              />

              <PropertyMarker
                markerType='pin'
                classification='MILITARY'
                equityType='HIGH'
                isVacant={false}
                isForeclosure={true}
                isSenior={false}
                size='xs'
              />

              <PropertyMarker
                markerType='tag'
                classification='OUT_OF_COUNTRY'
                equityType='UPSIDE_DOWN'
                isVacant={false}
                isForeclosure={true}
                isSenior={false}
                size='sm'
              />

              <PropertyMarker
                markerType='tag'
                classification='OWNER_OCCUPIED'
                equityType='HIGH'
                isVacant={false}
                isForeclosure={false}
                isSenior={false}
                size='sm'
              />
            </Flex>

            <Button
              ml='auto'
              variant='ghost'
              colorScheme='neutral'
              size='xs'
              onClick={() => api.actions.open()}
            >
              Learn More
            </Button>
          </Flex>
        </Card>
      </Grid>
      <DoneButton mt={mbp({ mobSm: 3, tabSm: 5 })} onClick={handleDone} />
    </Card>
  )
}

const DoneButton = (props: ButtonProps) => (
  <Button
    variant='solid'
    colorScheme='positive'
    w={mbp({ mobSm: 'full', tabSm: 'min-content' })}
    mx='auto'
    {...props}
  >
    Done
  </Button>
)

const CustomCardCombo = (props: CardProps) => (
  <CardCombo
    size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
    display='flex'
    flexDirection='column'
    {...props}
  />
)

export const CardComboTopSegment = ({ children, ...props }: CardProps) => (
  <CardSegment
    flex='0 1 0'
    bg='card.bg.2'
    shadow='primary.m'
    gap={1}
    flexDirection='row'
    flexWrap='wrap'
    justifyContent='center'
    alignItems='center'
    px={mbp({ mobSm: 2, tabSm: 0, dtSm: 2, dtLg: 0 })}
    {...props}
  >
    {children}
  </CardSegment>
)

export const CardComboBottomSegment = ({ children, ...props }: CardProps) => (
  <CardSegment
    flex='1 1 0'
    shadow='primary.m'
    flexDirection='column'
    p='2 !important'
    textAlign='center'
    textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })}
    {...props}
  >
    {children}
  </CardSegment>
)
