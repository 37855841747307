import { Box, BoxProps, Grid, GridItem, HStack, Text, VStack, useToken } from '@chakra-ui/react'
import { pipe } from 'effect'
import { PropertyListData } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { Dollar } from 'libs/dollar'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { getCardBorderColor } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard'
import { CompPriceTag } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/CompsPriceTag'
import { createPropertyListItemCardId } from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/components/PropertiesListItemCard'
import { PropertyPreviewCard } from 'presentation/screens/ListBuilderScreen/components/PropertyPreviewCard/PropertyPreviewCard'

export type ListPreviewMobileItemProps = BoxProps & {
  item: PropertyListData
}

export const ListPreviewMobileItem = ({
  item,
  ...boxProps
}: ListPreviewMobileItemProps) => {
  const { sbp } = useSwitchBreakpointFn()
  const cardBorderColor = useToken('colors', getCardBorderColor(item.mlsStatus))
  const featureColumns = [
    ['beds', item.bedroomsCount],
    ['baths', BathroomsCountInfo.getTotal(item.bathroomsCountInfo)],
    ['garage', item.garageSpacesCount],
    ['sqft', NumberLib.formatCommasOrDoubleDash(item.livingAreaSqft)],
    ['built', item.yearBuilt],
    ['acre', item.lotAreaAcres],
  ]

  const valuationGridItems = [
    ['Est Equity', Dollar.formatNumberWithKOrDoubleDash(item.estimatedEquity)],
    ['Est Value', Dollar.formatNumberWithKOrDoubleDash(item.estimatedValue)],
    ['Last Sold', DateLib.formatMMDDYYDotsOrDoubleDash(item.lastSoldDate)],
  ]

  const line1Formatted = item.address?.line1 || '--'
  const cityStateZipFormatted = pipe(
    item.address,
    Address.fillMissing,
    Address.formatCityStateZip,
  ) || '--'

  return (
    <VStack
      id={createPropertyListItemCardId(item.id)}
      as='button'
      spacing={0}
      bg='card.bg.1'
      borderColor={cardBorderColor}
      align='stretch'
      overflow='hidden'
      {...boxProps}
    >
      <PropertyPreviewCard
        address={item.address}
        location={item.location}
        flexShrink={0}
        flexGrow={0}
        borderRadius={0}
        {...sbp({
          mobSm: {
            imagineWidth: 300,
            imageHeight: 126,
            h: '126px',
          },
          mob: {
            imageWidth: 448,
            imageHeight: 204,
            h: '204px',
          },
        })}
      />

      <Box pos='relative'>
        <CompPriceTag
          pos='absolute'
          top={0}
          right={1}
          transform='translateY(-50%)'
          zIndex={2}
          value={item.estimatedValue}
          status='OFF_MARKET'
        />
      </Box>

      <HStack spacing={1} px={1} pt={1.5} align='center'>
        <PropertyMarker
          classification={null}
          equityType={null}
          isForeclosure={false}
          isSenior={false}
          isVacant={false}
          markerType='pin'
          size='xs'
          isHighlighted={false}
        />
        <VStack spacing={0} flex='1' align='flex-start'>
          <Text textStyle='bodyMFat' color='link.500' noOfLines={1} title={line1Formatted}>
            {line1Formatted}
          </Text>
          <Text textStyle='bodyM' color='grayweak.900' noOfLines={1} title={cityStateZipFormatted}>
            {cityStateZipFormatted}
          </Text>
        </VStack>
        <Text textStyle='bodySHeavy' color='marker.darkgray'>
          Off Market
        </Text>
      </HStack>

      <Box px={1} pt={1}>
        <HStack
          justify='space-between'
          px={0.5}
          textStyle='bodyMFat'
          color='marker.darkgray'
          borderColor='marker.darkgray'
          borderWidth={0.125}
          borderRadius={1}
        >
          {featureColumns.map(([label, value]) => (
            <VStack
              key={label}
              spacing={0}
              align='center'
            >
              <span>{label}</span>
              <span>{value ?? '--'}</span>
            </VStack>
          ))}
        </HStack>
      </Box>

      <Grid gridTemplate='repeat(2, 1fr) / repeat(2, 1fr)' px={1} pt={1} pb={1}>
        {valuationGridItems.map(([label, value]) => (
          <GridItem key={label}>
            <HStack spacing={0.5} align='center'>
              <Text textStyle='tagL' color='graystrong.200' textTransform='uppercase'>{label}</Text>
              <Text textStyle='bodyMFat' color='accent.blue-text'>{value}</Text>
            </HStack>
          </GridItem>
        ))}
      </Grid>
    </VStack>
  )
}
