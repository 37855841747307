import { ProgramException } from 'libs/errors'
import { isNonNullable } from 'utils/isNonNullable'

const fileReadIsNotString = Symbol('fileReadIsNotString')
export class FileReadIsNotStringException extends ProgramException {
  [fileReadIsNotString] = true
}

export type Blob = string

export type FileToBlobConverter = (file: File) => Promise<Blob>

const fromFile: FileToBlobConverter = async file =>
  await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      if (typeof reader.result !== 'string')
        reject(new FileReadIsNotStringException('result is not string'))
      const dataURL = reader.result as string

      if (isNonNullable(dataURL) && dataURL.startsWith('data:'))
        resolve(dataURL)
      reject(dataURL)
    })
    reader.readAsDataURL(file)
  })

export const blob = {
  fromFile,
}
