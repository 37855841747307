import { toast } from 'presentation/components/Toast'
import { useEffect } from 'react'

export const useToastError = ({ error, title, id }: {
  error?: { message?: string } | null
  id?: string
  title: string
}) => {
  useEffect(() => {
    if (!error) return

    toast({
      id,
      status: 'error',
      title,
      message: error.message,
    })
  }, [error])
}
