import { Accordion, AccordionProps, Box, Button, ButtonProps, CardProps, Center, Flex, IconButton, Spinner, Text } from '@chakra-ui/react'
import { isToday } from 'date-fns'
import { Match, pipe } from 'effect'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { SkiptraceProvider } from 'features/valueObjects/SkiptraceProvider'
import { DateLib } from 'libs/Date'
import { StringLib } from 'libs/String'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { EnterIcon } from 'presentation/components/Icons'
import { CardPrimaryTitle, CardSecondarySupportingText, Card as ChakraCard } from 'presentation/components/molecules/Card'
import { useSelectFormatModal } from 'presentation/screens/SkiptraceScreen/components/SelectFormatModal/SelectFormatModal.api'
import { SkiptraceAccordionItem } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceAccordionItem'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const SkiptraceResultsCard = (props: CardProps) => {
  const store = useSkiptraceStore(store => {
    const resultState = store.entities.resultState
    if (resultState.status !== 'success' || !resultState.result)
      return null

    return {
      status: resultState.status,
      owners: resultState.result.owners,
      tracers: resultState.result.tracers,
      address: resultState.result.subject.address,
      providers: resultState.result.providers,
      updateDate: resultState.result.skiptracedAt,
    }
  })

  const selectFormatModalApi = useSelectFormatModal()
  const openDownloadModal = selectFormatModalApi.actions.open

  if (!store) return null

  const tracersPhrase = StringLib.stringsToPhrase(store.tracers.map(tracer => tracer.name))
  const providersPhrase = StringLib.stringsToPhrase(store.providers.map(SkiptraceProvider.toHumanReadable))

  return (
    <CustomCard {...props}>
      {pipe(
        Match.value(store),
        Match.when({ status: 'success' }, result => (
          <Flex flexDirection='column' h='full' minH={0}>
            <Box>
              <Flex justifyContent='space-between'>
                <CardPrimaryTitle>
                  Skiptrace results
                </CardPrimaryTitle>
                <Breakpoint
                  mobSm={(
                    <DownloadIconButton onClick={() => openDownloadModal()} />
                  )}
                  tabSm={(
                    <DownloadButton onClick={() => openDownloadModal()} />
                  )}
                />
              </Flex>
              <CardSecondarySupportingText>
                Skipped by
                {' '}
                {tracersPhrase}
                {' '}
                using
                {' '}
                {providersPhrase}
              </CardSecondarySupportingText>
            </Box>
            <CustomAccordion>
              {result.owners.map(owner => (
                <SkiptraceAccordionItem
                  key={owner.name}
                  owner={owner}
                />
              ))}
            </CustomAccordion>
            {result.owners.length === 0 && (
              <Text textStyle='bodyL' color='graystrong.400'>
                No Results Found. No Charge.
                {store.updateDate && `
                  Last skiptraced ${isToday(store.updateDate)
                ? 'today'
                : `on ${DateLib.formatNaturalShortMonth(store.updateDate)}`
              }`}
                .
              </Text>
            )}
          </Flex>
        )),
        Match.orElse(() => (
          <Center h='full'>
            <Spinner />
          </Center>
        )),
      )}
    </CustomCard>
  )
}

export const CustomAccordion = (props: AccordionProps) => ((
  <Accordion
    allowToggle
    display='flex'
    flexDirection='column'
    gap={3}
    mt={mbp({ mobSm: 2, tabSm: 3 })}
    flex='1 1 0'
    reduceMotion
    {...props}
  >
    {props.children}
  </Accordion>
))

const CustomCard = (props: CardProps) => (
  <Breakpoint
    mobSm={(
      <ChakraCard
        size='sm-locked'
        sx={{
          bg: 'none',
          shadow: 'none',
          p: 0,
        }}
        {...props}
      >
        {props.children}
      </ChakraCard>
    )}
    tabSm={(
      <ChakraCard
        size='sm-locked'
        {...props}
      >
        {props.children}
      </ChakraCard>
    )}
  />
)

const DownloadIconButton = (props: ButtonProps) => (
  <IconButton
    aria-label='Download'
    icon={(
      <EnterIcon
        boxSize={3}
        transform='rotate(90deg)'
      />
    )}
    variant='icon-ghost'
    colorScheme='neutral'
    size='md'
    {...props}
  />
)

const DownloadButton = (props: ButtonProps) => (
  <Button
    colorScheme='neutral'
    variant='ghost'
    leftIcon={(
      <EnterIcon
        boxSize={3}
        transform='rotate(90deg)'
      />
    )}
    {...props}
  >
    Download
  </Button>
)
