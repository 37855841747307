import { useQuery } from '@apollo/client'
import { Box, Center, Spinner } from '@chakra-ui/react'
import { gql } from '__generated__'
import { CancelSubscriptionSections__GetLeadsLimitQuery } from '__generated__/graphql'
import dayjs from 'dayjs'
import { ErrorBoundary, GenericFallback } from 'presentation/components/ErrorBoundary'
import { LayoutBody } from 'presentation/layouts/Layout/components/LayoutBody'
import { PlansBillingLink, SettingsLink, useBillingDefaultNotice } from 'presentation/screens/Billing/BillingDefaultNotice'
import { CancelTableCard } from 'presentation/screens/CancelSubscription/CancelTableCard'
import { get, getGqlType } from 'presentation/utils/graphql'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import { CancelSubscriptionFooter } from './CancelSubscriptionFooter'

export const CancelSubscription: FC = () => (
  <ErrorBoundary fallback={<GenericFallback />}>
    <CancelSubscriptionSections />
  </ErrorBoundary>
)

const CANCEL_SUBSCRIPTION_SECTIONS__GET_LEADS_LIMIT = gql(`
  query CancelSubscriptionSections__GetLeadsLimit {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id

          currentPeriod {
            end
          }

          plan {
            id
            product {
              features {
                __typename

                ... on SubscriptionFeatureLeads {
                  __typename
                  limits {
                    interval
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`)

export const CancelSubscriptionSections: FC = () => {
  useBillingDefaultNotice({
    breadcrumbs: [
      SettingsLink,
      PlansBillingLink,
      { label: 'Cancel Plan' },
    ],
  })

  const { data } = useQuery(CANCEL_SUBSCRIPTION_SECTIONS__GET_LEADS_LIMIT)

  if (!data) {
    return (
      <LayoutBody>
        <Center boxSize='full'>
          <Spinner />
        </Center>
      </LayoutBody>
    )
  }

  const leadsLimit = data ? getLeadsLimit(data) : null
  const periodEndRaw = getGqlType('BillingSubscriptionActive')(data.myEnterprise?.subscription)?.currentPeriod.end
  const periodEndFormatted = periodEndRaw && dayjs(periodEndRaw).isValid()
    ? dayjs(periodEndRaw).format('MMMM D, YYYY')
    : null

  return (
    <LayoutBody>
      <Box
        flex='1 0 0'
        mt={mbp({ mobSm: '3', tabSm: '5', dtSm: '7', dtLg: '80px' })}
        mx={mbp({ mobSm: '1', mob: '3', tabSm: '5', dtSm: '7', dtLg: '0' })}
      >
        <CancelTableCard leadsLimit={leadsLimit} />
      </Box>

      <CancelSubscriptionFooter
        periodEndFormatted={periodEndFormatted}
        mt={mbp({ mobSm: '3', tabSm: '5', dtSm: '6', dtLg: '80px' })}
      />
    </LayoutBody>
  )
}

const getLeadsLimit = (data: CancelSubscriptionSections__GetLeadsLimitQuery) => {
  const activeSub = get('BillingSubscriptionActive')(data?.myEnterprise?.subscription)

  const subscriptionFeatureLeads = activeSub?.plan?.product?.features?.find(
    feature => feature.__typename === 'SubscriptionFeatureLeads',
  )

  const limits = get('SubscriptionFeatureLeads')(subscriptionFeatureLeads)?.limits
  if (!limits) return null

  const firstLimit = limits[0]

  const value = !firstLimit ? Infinity : firstLimit.value

  return value
}
