import { Box, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import { DownloadBatchSkiptraceFormat } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { useDownloadBatchSkiptraceState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useDownloadBatchSkiptraceState'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Radio } from 'presentation/components/Radio/Radio'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedStateTagged } from 'presentation/libs/hooks/useToastFailedState'
import { useListSkipDataDownloadModal, useListSkipDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListSkipDataDownloadModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'

export const ListSkipDataDownloadModal = () => {
  const modalState = useListSkipDataDownloadModal()
  const modalActions = useListSkipDataDownloadModalActions()
  const skipActions = useBatchSkiptraceActions()
  const downloadSkipState = useDownloadBatchSkiptraceState()

  const [radioValue, setRadioValue] = useState<DownloadBatchSkiptraceFormat>('vertical')

  useToastFailedStateTagged(downloadSkipState, {
    title: 'Failed to download skiptrace data',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.neutral'
          >
            <CardImage src={happyDogPng} />
            <ModalCloseButton onClose={modalActions.close} />

            <CardPrimaryTitle>
              Select Format
            </CardPrimaryTitle>

            <CardBody>
              <CardPrimaryText>
                We have two formats to choose from:
              </CardPrimaryText>
              <VStack spacing={3} mt={5}>
                <FormatOption
                  label='Vertical'
                  description={`
                      One phone number per row.
                      Each additional phone number takes up more rows
                      so the information is not limited in any way.
                    `}
                  isSelected={radioValue === 'vertical'}
                  onClick={() => setRadioValue('vertical')}
                />

                <FormatOption
                  label='Horizontal'
                  description={`
                      One row per skiptrace.
                      Emails, phones, rtc, are separated into columns
                      and are limited to 10 columns each
                    `}
                  isSelected={radioValue === 'horizontal'}
                  onClick={() => setRadioValue('horizontal')}
                />
              </VStack>
            </CardBody>

            <CardFooter>
              <CardButton
                variant='outline'
                colorScheme='neutral'
                size='md'
                onClick={modalActions.close}
              >
                Cancel
              </CardButton>
              <CardButton
                variant='solid'
                colorScheme='neutral'
                size='md'
                onClick={() => {
                  skipActions.download(modalState.payload.list, radioValue)
                  modalActions.close()
                  toast.info({
                    title: 'Download Starting...',
                    message: 'Your list skip data will download shortly',
                  })
                }}
              >
                Download
              </CardButton>
            </CardFooter>
          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}

type FormatOptionProps = {
  label: string
  description: string
  isSelected?: boolean
  onClick?: () => void
}

const FormatOption = (props: FormatOptionProps) => (
  <Box
    borderColor={props.isSelected ? 'neutral.500' : 'highlight.100'}
    borderWidth={0.25}
    borderRadius={mbp({ mobSm: 3 })}
    padding={mbp({ mobSm: 2, tabSm: 3 })}
    bgColor='highlight.100'
    onClick={props.onClick}
    cursor='pointer'
    shadow='primary.m'
    role='button'
  >
    <Text
      color='graystrong.200'
      textStyle={mbp({ mobSm: 'bodyM', tabSm: 'bodyXL' })}
    >
      {props.description}
    </Text>
    <Radio
      mt={mbp({ mobSm: 1, tabSm: 2 })}
      isChecked={props.isSelected}
      onClick={e => e.preventDefault()}
      textStyle={mbp({ mobSm: 'bodyL', tabSm: 'bodyXL' })}
    >
      {props.label}
    </Radio>
  </Box>
)
