import { Match, pipe } from 'effect'
import { TaggedEnum, taggedEnum } from 'effect/Data'
import { useBatchSkiptrace } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptrace'
import { MarketingListMember } from 'features/ListBuilder/domain/MarketingListMember'
import { useMembers } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { useMemo } from 'react'

export type ListMemberWithSkiptrace = {
  id: MarketingListMember['id']
  member: ListMemberWithSkiptraceMemberState
  skiptrace: ListMemberWithSkiptraceSkiptraceState
}

export type ListMemberWithSkiptraceState = TaggedEnum<{
  Loading: {}
  Success: { members: ListMemberWithSkiptrace[] }
}>

export const ListMemberWithSkiptraceState = taggedEnum<ListMemberWithSkiptraceState>()

export type ListMemberWithSkiptraceMemberState = TaggedEnum<{
  Success: { member: MarketingListMember }
}>

export const ListMemberMemberState = taggedEnum<ListMemberWithSkiptraceMemberState>()

export type ListMemberWithSkiptraceSkiptraceState = TaggedEnum<{
  Loading: {}
  Success: { result: Skiptrace.Result }
  SuccessEmpty: { result: Skiptrace.Result }
  Unskipped: {}
}>

export const ListMemberSkiptraceState = taggedEnum<ListMemberWithSkiptraceSkiptraceState>()

export const useListMemberWithSkiptrace = () => {
  const skiptraceApi = useBatchSkiptrace()
  const membersApi = useMembers()

  const state = useMemo(() => pipe(
    Match.value({ membersApi, skiptraceApi }),
    Match.when(
      { membersApi: { _tag: 'Importing' } },
      apis =>
        ListMemberWithSkiptraceState.Success({
          members: apis.membersApi.members.map(member => ({
            id: member.id,
            member: ListMemberMemberState.Success({ member }),
            skiptrace: ListMemberSkiptraceState.Loading(),
          })),
        }),
    ),
    Match.when(
      { membersApi: { _tag: 'Success' }, skiptraceApi: { _tag: 'Success' } },
      apis => {
        const skiptraceByMemberId = apis.skiptraceApi.batchSkiptrace.resultByMemberId

        return ListMemberWithSkiptraceState.Success({
          members: apis.membersApi.members.map(member => {
            const result = skiptraceByMemberId[member.id]
            const ownersCount = result?.owners.length

            const skiptrace = pipe(
              Match.value(ownersCount),
              Match.when(0, () =>
                ListMemberSkiptraceState.SuccessEmpty({ result: skiptraceByMemberId[member.id] })),
              Match.when(count => count > 0, () =>
                ListMemberSkiptraceState.Success({ result: skiptraceByMemberId[member.id] })),
              Match.orElse(() => ListMemberSkiptraceState.Unskipped()),
            )

            return {
              id: member.id,
              member: ListMemberMemberState.Success({ member }),
              skiptrace,
            }
          }),
        })
      },
    ),
    Match.orElse(() => ListMemberWithSkiptraceState.Loading()),
  ), [membersApi, skiptraceApi])

  return state
}
