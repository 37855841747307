const textStylesV2 = {
  h4Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '20px', lineHeight: '30px' /* 150% */ },
  h3Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '24px', lineHeight: '36px' /* 150% */ },
  h2Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '28px', lineHeight: '34px' },
  h1Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '32px', lineHeight: '39px' },
  display4Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '36px', lineHeight: '44px' },
  display3Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '42px', lineHeight: '51px' },
  display1Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '56px', lineHeight: '68px' },
  display2Heavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '50px', lineHeight: '61px' },
  tagS: { fontFamily: 'Inter', fontWeight: 700, fontSize: '8px', lineHeight: '10px' },
  tagM: { fontFamily: 'Lato', fontWeight: 700, fontSize: '10px', lineHeight: '12px' },
  tagL: { fontFamily: 'Lato', fontWeight: 700, fontSize: '12px', lineHeight: '14px' },
  bodyLHeavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '16px', lineHeight: '24px' /* 150% */ },
  bodyXLHeavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '18px', lineHeight: '27px' /* 150% */ },
  bodyMHeavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '14px', lineHeight: '21px' /* 150% */ },
  bodySHeavy: { fontFamily: 'Inter', fontWeight: 900, fontSize: '12px', lineHeight: '18px' /* 150% */ },
  bodyMFat: { fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px' /* 150% */ },
  bodyM: { fontFamily: 'Lato', fontWeight: 400, fontSize: '14px', lineHeight: '21px' /* 150% */ },
  bodySFat: { fontFamily: 'Lato', fontWeight: 700, fontSize: '12px', lineHeight: '18px' /* 150% */ },
  bodyLFat: { fontFamily: 'Lato', fontWeight: 700, fontSize: '16px', lineHeight: '24px' /* 150% */ },
  bodyXLFat: { fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', lineHeight: '27px' /* 150% */ },
  h4: { fontFamily: 'Lato', fontWeight: 700, fontSize: '20px', lineHeight: '30px' /* 150% */ },
  h3: { fontFamily: 'Lato', fontWeight: 700, fontSize: '24px', lineHeight: '36px' /* 150% */ },
  h2: { fontFamily: 'Lato', fontWeight: 700, fontSize: '28px', lineHeight: '34px' },
  h1: { fontFamily: 'Lato', fontWeight: 700, fontSize: '32px', lineHeight: '38px' },
  display4: { fontFamily: 'Lato', fontWeight: 700, fontSize: '36px', lineHeight: '43px' },
  display3: { fontFamily: 'Lato', fontWeight: 700, fontSize: '42px', lineHeight: '50px' },
  display1: { fontFamily: 'Lato', fontWeight: 700, fontSize: '56px', lineHeight: '67px' },
  display2: { fontFamily: 'Lato', fontWeight: 700, fontSize: '50px', lineHeight: '60px' },
  bodyL: { fontFamily: 'Lato', fontWeight: 400, fontSize: '16px', lineHeight: '24px' /* 150% */ },
  bodyXL: { fontFamily: 'Lato', fontWeight: 400, fontSize: '18px', lineHeight: '27px' /* 150% */ },
  customAccent: { fontFamily: 'Kalam', fontStyle: 'normal', fontWeight: 700, fontSize: '22px', lineHeight: 'normal' },
}

const textStylesV1 = {
  /**
   * Display
   */
  'display': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '110%',
  },

  'display-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '110%',
  },

  'display-l': {
    fontFamily: 'Lato',
    fontWeight: 300,
    fontSize: '46px',
    lineHeight: '110%',
  },

  'display-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '36px',
    lineHeight: '110%',
  },

  /**
   * H1
   */

  'old.h1': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '29px',
    lineHeight: '110%',
  },

  'h1-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '29px',
    lineHeight: '110%',
  },

  'h1-h-b': {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '36px',
  },

  'h1-h-xb': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '29px',
    lineHeight: '110%',
  },

  /**
   * H2
   */

  'old.h2': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '29px',
  },

  'h2-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
  },

  'h2-h': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
  },

  'h2-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '29px',
  },

  /**
   * H3
   */

  'old.h3': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
  },

  'h3-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },

  'h3-h': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },

  'h3-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
  },

  /**
   * H4
   */

  'old.h4': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
  },

  'h4-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
  },

  'h4-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '27px',
  },

  /**
   * Body
   */

  'body': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },

  'body-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },

  'body-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '19px',
  },

  /**
   * Body 2
   */

  'body2': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.01em',
  },

  'body2-0': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.01em',
  },

  'body2-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.01em',
  },

  'body2-b-0': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '-0.01em',
  },

  'body2-xb': {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.01em',
  },

  'body2-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '14px',
    lineHeight: '150%',
  },

  /**
   * Body 3
   */

  'body3': {
    fontFamily: 'Lato',
    fontWeight: 400,

    fontSize: '12px',
    lineHeight: '14px',
  },

  'body3-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
  },

  'body3-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '15px',
  },

  /**
   * Panel
   */
  'panel-title': {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '120%',
  },

  'panel-title-h-b': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '120%',
  },

  /**
   * Caption
   */

  'caption': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '36px',
  },

  'caption-h-b': {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '15px',
  },

  /**
   * Small
   */

  'small': {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '200%',
  },

  'small-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
  },

  'small-h-b': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
  },

  'small-h-xb': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '12px',
  },

  /**
   * Button
   */

  'button-b': {
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
    textTransform: 'uppercase',
  },

  'button-h-b': {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
    textTransform: 'uppercase',
  },

  'button-m-b': {
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
    textTransform: 'uppercase',
  },

  /**
   * Tag
   */

  'tag-h-b': {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '8px',
    lineHeight: '17px',
  },

  'inter-44-900': {
    fontFamily: 'Inter',
    fontWeight: 900,
    fontSize: '44px',
    lineHeight: '119%',
    letterSpacing: '-0.03em',
  },

  /**
   * Web
   */
  'web-h-xb': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '48px',
    lineHeight: '110%',
    letterSpacing: '-0.03em',
  },

  /**
   * Uncategorized styles
   */
  'kalam-18-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '29px',
  },
  'kalam-20-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: '-0.03em',
  },
  'kalam-24-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '26px',
    letterSpacing: '-0.03em',
  },
  'kalam-28-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '45px',
  },
  'kalam-36-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '57px',
  },
  'kalam-44-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '44px',
    lineHeight: '70px',
  },
  'kalam-56-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '56px',
    lineHeight: '62px',
    letterSpacing: '-0.03em',
  },
  'kalam-80-700': {
    fontFamily: 'Kalam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '80px',
    lineHeight: '88px',
    letterSpacing: '-0.03em',
  },
  'inter-14-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-0.03em',
  },
  'inter-16-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.03em',
  },
  'inter-18-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '150%',
  },
  'inter-20-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
  },
  'inter-22-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.03em',
  },
  'inter-24-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px',
    letterSpacing: '-0.03em',
  },
  'inter-29-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '29px',
    lineHeight: '32px',
  },
  'inter-34-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '41px',
    letterSpacing: '-0.03em',
  },
  'inter-36-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '40px',
  },
  'inter-40-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '44px',
    letterSpacing: '-0.03em',
  },
  'inter-44-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '44px',
    lineHeight: '119%',
    letterSpacing: '-0.03em',
  },
  'inter-48-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '52px',
    letterSpacing: '-0.03em',
  },
  'inter-50-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '50px',
    lineHeight: '60px',
    letterSpacing: '-0.03em',
  },
  'inter-60-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '66px',
    letterSpacing: '-0.03em',
  },
  'inter-65-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '65px',
    lineHeight: '72px',
    letterSpacing: '-0.03em',
  },
  'inter-88-700': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '65px',
    lineHeight: '88px',
    letterSpacing: '-0.03em',
  },
  'inter-28-900': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '28px',
    lineHeight: '34px',
  },
  'inter-29-900': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '29px',
    lineHeight: '110%',
    letterSpacing: '-0.03em',
  },
  'inter-36-900': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '36px',
    lineHeight: '110%',
    letterSpacing: '-0.03em',
  },
  'inter-50-900': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '50px',
    lineHeight: '110%',
    letterSpacing: '-0.03em',
  },
  'lato-12-700': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '150%',
  },
  'lato-16-700': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  'lato-18-700': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '150%',
  },
  'lato-20-700': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '150%',
  },
  'lato-24-700': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '150%',
  },
  'lato-12-900': {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.005em',
  },
}

export const textStyles = {
  ...textStylesV1,
  ...textStylesV2,
}
