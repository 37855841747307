import { Box, Grid, GridItem, GridProps } from '@chakra-ui/react'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { OwnerSkiptraceCard } from 'presentation/screens/SkiptraceScreen/components/OwnerSkiptraceCard'
import { SkiptraceAgainBillingSummaryColumn } from 'presentation/screens/SkiptraceScreen/components/SkiptraceAgainBillingSummaryColumn'
import { SkiptraceAgainBillingSummaryModal } from 'presentation/screens/SkiptraceScreen/components/SkiptraceAgainBillingSummaryModal'
import { SkiptraceBillingSummaryCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceBillingSummaryCard'
import { SkiptraceResultsCard } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { ReactNode, useState } from 'react'
import { SkiptraceAccountBalanceCard } from '../SkiptraceAccountBalanceCard'

export const SkiptraceResultsScreen = (props: GridProps) => {
  const [shouldShowBillingSummary, setShouldShowBillingSummary] = useState(false)
  const { sbp } = useSwitchBreakpointFn()

  const openColumnVariant: boolean = (sbp({
    mobSm: false,
    dtLg: true,
  }) ?? false) && shouldShowBillingSummary

  const openModalVariant: boolean = (sbp({
    mobSm: true,
    dtLg: false,
  }) ?? false) && shouldShowBillingSummary

  const sideColumn = openColumnVariant
    ? <SkiptraceAgainBillingSummaryColumn onCancel={() => setShouldShowBillingSummary(false)} />
    : (
      <Grid
        gridTemplateColumns={mbp({ mobSm: '1fr', tabSm: '1fr 1fr', dtLg: '1fr' })}
        gridTemplateRows={mbp({ mobSm: 'min-content', tabSm: 'min-content', dtLg: 'repeat(2, min-content)' })}
        gap={3}
        gridColumn={mbp({ dtLg: '2' })}
      >
        <SkiptraceAccountBalanceCard display={mbp({ mobSm: 'none', mob: 'block' })} />
        <OwnerSkiptraceCard onSkiptraceAgain={() => setShouldShowBillingSummary(true)} />
      </Grid>
    )

  if (shouldShowBillingSummary) {
    return (
      <Box
        alignSelf='center'
        {...mbpg({
          mobSm: {
            px: 0,
          },
          mob: {
            py: 3,
            px: 2,
          },
          tabSm: {
            px: 0,
          },
        })}
        flex={mbp({ mobSm: '1 1 0', mob: 'initial' })}
        {...props}
      >
        <SkiptraceBillingSummaryCard
          maxW='736px'
          onCancel={() => setShouldShowBillingSummary(false)}
        />
      </Box>
    )
  }

  return (
    <>
      <SkiptraceResultsLayout
        sideColumn={sideColumn}
        {...props}
      />
      <SkiptraceAgainBillingSummaryModal
        isOpen={openModalVariant}
        onClose={() => setShouldShowBillingSummary(false)}
      />
    </>
  )
}

type SkiptraceResultsLayoutProps = {
  sideColumn: ReactNode
} & GridProps

const SkiptraceResultsLayout = ({ sideColumn, ...props }: SkiptraceResultsLayoutProps) => (
  <Grid
    flex='1'
    {...mbpg({
      mobSm: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content',
        pt: 3,
        pb: 3,
        px: 1,
      },
      mob: {
        px: 3,
      },
      tabSm: {
        pt: 3,
        pb: 3,
      },
      dtLg: {
        gridTemplateColumns: '880px 400px',
        gridTemplateRows: 'min-content minmax(0, 1fr)',
        pt: 2,
        px: 4,
      },
    })}
    rowGap={3}
    columnGap={3}
    justifyContent='center'
    h='100%'
    minH={0}
    {...props}
  >

    <GridItem gridColumn={mbp({ dtLg: '2' })}>
      {sideColumn}
    </GridItem>

    <GridItem
      gridColumn={mbp({ dtLg: '1' })}
      gridRow={mbp({ dtLg: '1 / -1' })}
    >
      <SkiptraceResultsCard />
    </GridItem>
  </Grid>
)
