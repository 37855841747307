import { Schema as S } from '@effect/schema'
import { Array, Match, Option, pipe, Record } from 'effect'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { LocationCounty } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import HomeTypeCode from 'features/ListBuilder/infra/HomeTypeCode'
import { ConditionTypeSchema, CriteriaEncodedSchema, CriteriaSchema } from 'features/ListBuilder/infra/repo/schema/CriteriaSchema/CriteriaSchema'

const RESULT_LIMIT = 50
const FIRST_PAGE_OFFSET_ONLY = 0
export const encode = (criteria: ListCriteria): CriteriaEncodedSchema =>
  pipe(
    Match.value(criteria),
    Match.tag('PropertyListCriteria', c => S.encodeSync(CriteriaSchema)(encodePropertyList(c))),
    Match.exhaustive,
  )
// #endregion Operators

const encodePropertyList = (criteria: PropertyListCriteria): CriteriaSchema => ({
  offset: Option.some(FIRST_PAGE_OFFSET_ONLY),
  limit: Option.some(RESULT_LIMIT),
  location: {
    city: pipe(
      criteria.location,
      Array.filter(i => i._tag === 'LocationCity'),
      Array.map(location => location.value),
      Option.some,
    ),
    county: pipe(
      criteria.location,
      Array.filter(i => i._tag === 'LocationCounty'),
      Array.map(i => (i as LocationCounty).fips),
      Option.some,
    ),
    countyDesc: Option.none(), // @NOTE: Only needed when decoding
    neightborhood: Option.none(), // @NOTE: not part of design
    zip: pipe(
      criteria.location,
      Array.filter(i => i._tag === 'LocationZip'),
      Array.map(location => location.value),
      Option.some,
    ),
  },
  newOnly: Option.some(criteria.excludePropertiesFromOtherList),
  filters: {
    propertyDetails: pipe({
      beds: pipe(
        Match.value(criteria.propertyDetails.bedroomsCount),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      baths: pipe(
        Match.value(criteria.propertyDetails.bathroomsCount),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      buildingSqft: pipe(
        Match.value(criteria.propertyDetails.livingAreaSqft),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      lotSqft: pipe(
        Match.value(criteria.propertyDetails.lotAreaSize.range),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      yearBuilt: pipe(
        Match.value(criteria.propertyDetails.yearBuilt),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      stories: Option.none(), // @NOTE: Not supported by design
      units: Option.none(), // @NOTE: Not supported by design
    }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
    propertyTypes: pipe(
      criteria.propertyDetails.homeType,
      Array.map(type => HomeTypeCode.getCodes(type)),
      Array.flatten,
      codes => codes.length > 0 ? Option.some(codes) : Option.none(),
    ),
    ownerType: pipe({
      individual: pipe({
        ownerOccupied: Option.fromNullable(criteria.ownershipFlags.isPersonalResidence),
        military: Option.fromNullable(criteria.ownershipFlags.isMilitary),
        vacant: Option.none(), // @NOTE: Not mapped by legacy. Probably deprecated.
        absentee: pipe({
          inState: Option.fromNullable(criteria.ownershipFlags.isInState),
          outOfState: Option.fromNullable(criteria.ownershipFlags.isOutOfState),
          outOfCountry: Option.fromNullable(criteria.ownershipFlags.isOutOfCountry),
        }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
      }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
      isBank: Option.fromNullable(criteria.ownershipFlags.isBank),
      isCompany: Option.fromNullable(criteria.ownershipFlags.isCorporateOwned),
      isTrust: Option.fromNullable(criteria.ownershipFlags.isTrust),
      taxExemptions: pipe({
        isSenior: Option.fromNullable(criteria.ownershipFlags.isSenior),
        isHomestead: Option.none(), // @NOTE: Not supported by design
      }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
      vacancy: pipe(
        Option.fromNullable(criteria.stressIndicatorFlags.isVacant),
        Option.map(isVacant => ({ isVacant: Option.some(isVacant) })),
      ),
    }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
    equityInfo: pipe({
      estimatedValue: pipe(
        Match.value(criteria.equityValue.propertyValue),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      lastSaleDate: Option.none(), // @NOTE: Not supported by design
      ownershipLength: pipe(
        Match.value(criteria.yearsOfOwnership),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
      taxValue: pipe(
        Match.value(criteria.equityValue.taxValue),
        Match.when([Match.null, Match.null], () => Option.none()),
        Match.orElse(([min, max]) => Option.some({
          min: Option.fromNullable(min),
          max: Option.fromNullable(max),
        })),
      ),
    }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
    equityType: pipe({
      highEquity: pipe({
        percentAtLeast: Option.fromNullable(criteria.equityValue.highEquityValue.minPercentage),
        dollarsAtLeast: Option.fromNullable(criteria.equityValue.highEquityValue.minAmount),
        yearsOwnershipAtLeast: Option.none(), // @NOTE: ask if deprecated already, see equityInfo.ownershipLength
        conditionType: pipe(
          Option.zipWith(
            Option.fromNullable(criteria.equityValue.highEquityValue.minPercentage),
            Option.fromNullable(criteria.equityValue.highEquityValue.minAmount),
            () => Match.value(criteria.equityValue.highEquityValue.condition)
              .pipe(
                Match.when('and', () => 'AND' as ConditionTypeSchema),
                Match.when('or', () => 'OR' as ConditionTypeSchema),
                Match.exhaustive,
              ),
          ),
        ),
      }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
      lowEquity: pipe({
        percentAtMost: Option.fromNullable(criteria.equityValue.lowEquityValue.maxPercentage),
        dollarsAtMost: Option.fromNullable(criteria.equityValue.lowEquityValue.maxAmount),
        yearsOwnershipAtMost: Option.none(), // @NOTE: ask if deprecated already, see equityInfo.ownershipLength
        conditionType: pipe(
          Option.zipWith(
            Option.fromNullable(criteria.equityValue.lowEquityValue.maxPercentage),
            Option.fromNullable(criteria.equityValue.lowEquityValue.maxAmount),
            () => Match.value(criteria.equityValue.lowEquityValue.condition)
              .pipe(
                Match.when('and', () => 'AND' as ConditionTypeSchema),
                Match.when('or', () => 'OR' as ConditionTypeSchema),
                Match.exhaustive,
              ),
          ),
        ),
      }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
      upsideDown: pipe(
        Option.fromNullable(criteria.equityValue.upsideDownValue.minAmount),
        Option.map(minAmount => ({ upsideDownAtLeast: Option.some(minAmount) })),
      ),
      freeAndClear: Option.fromNullable(criteria.equityValue.equityValueFlags.isFreeAndClear),
      unknown: Option.fromNullable(criteria.equityValue.equityValueFlags.isUnknown),
    }, Option.liftPredicate(Record.some(Option.isSome<unknown>))),
    preforeclosure: Option.none(), // @NOTE: Not supported by design
  },
})
