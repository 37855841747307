import dissolve from '@turf/dissolve'
import { featureCollection, polygon } from '@turf/helpers'
import simplify from '@turf/simplify'
import { Location } from 'features/valueObjects/Location'
import { CompsBoundaryLine } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.types'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type CompsBoundaryState = {
  status: 'idle' | 'drawing'
  lines: CompsBoundaryLine[]
}

const store = create<CompsBoundaryState>()(immer(_set => ({
  status: 'idle',
  lines: [],
})))

const useStore = store

const setStatus = (status: CompsBoundaryState['status']) => {
  store.setState({ status })
}

const startLine = () => {
  store.setState(state => {
    state.lines.push({ isClosed: false, coordinates: [] })
  })
}

const endLine = () => {
  store.setState(state => {
    const lastLine = state.lines[state.lines.length - 1]
    lastLine.isClosed = true
    // close the line by adding the first point to the end
    lastLine.coordinates.push(lastLine.coordinates[0])

    // eliminate last line had less than 4 points,
    // dissolve function throws an error otherwise
    if (lastLine.coordinates.length < 4)
      state.lines.pop()

    if (state.lines.length === 0)
      return

    const collection = featureCollection(state.lines.map(line => polygon([line.coordinates])))

    // dissolve combines all overlapping polygons into one
    const dissolved = dissolve(collection)

    // simplify reduces the number of points in the polygon
    const simplified = simplify(dissolved, { tolerance: 0.0001, highQuality: false })

    const newLines = simplified.features.map(feature => ({
      isClosed: true,
      coordinates: feature.geometry.coordinates[0],
    }))

    // make sure all coordinates are closed
    newLines.map(line => {
      const firstCoordinate = line.coordinates[0]
      const lastCoordinate = line.coordinates[line.coordinates.length - 1]

      if (firstCoordinate[0] !== lastCoordinate[0] || firstCoordinate[1] !== lastCoordinate[1])
        line.coordinates.push(firstCoordinate)

      return line
    })

    state.lines = newLines
  })
}

const extendLine = (location: Location) => {
  store.setState(state => {
    state.lines[state.lines.length - 1].coordinates.push([location.longitude, location.latitude])
  })
}

const clearLines = () => {
  store.setState(state => {
    state.lines = []
  })
}

const setLines = (lines: CompsBoundaryLine[]) => {
  store.setState(state => {
    state.lines = lines
  })
}

export const CompsBoundary = {
  store,
  useStore,
  setStatus,
  startLine,
  endLine,
  extendLine,
  clearLines,
  setLines,
}
