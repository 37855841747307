import { Data } from 'effect'
import ProductFeatureInterval from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureInterval'
import ProductFeatureName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureName'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'

/**
 * A feature state is a state of a feature in a specific subscription tier that is
 * currently active.
 */

/**
 * Indicates that a feature is unlimited in a specific subscription tier
 * @NOTE: This is a state, not a spec. It is just a way to differentiate
 * unlimited feature between an active subscription and a product feature.
 */
export type FeatureUnlimitedState = {
  readonly _tag: 'FeatureUnlimitedState'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
}

export const FeatureUnlimitedState = {
  make: Data.tagged<FeatureUnlimitedState>('FeatureUnlimitedState'),
}

/**
 * Current state of a feature with a hard usage limit for an active subscription.
 * Tracks the user's usage against the defined limit.
 * Note: If the limit is reached, further usage may be blocked or restricted.
 */
export type FeatureLimitedState = {
  readonly _tag: 'FeatureLimitedState'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  readonly limit: number
  readonly interval: ProductFeatureInterval
  readonly used: number
}

export const FeatureLimitedState = {
  make: Data.tagged<FeatureLimitedState>('FeatureLimitedState'),
}

/**
 * Current state of a feature with a free quota and overage charges for an active subscription.
 * Tracks the user's usage against the free quota and indicates when the quota resets.
 */
export type FeatureQuotaState = {
  readonly _tag: 'FeatureQuotaState'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  // Free usage limit
  readonly quotaLimit: number
  // Frequency of replenishment of the quota
  readonly replenishInterval: ProductFeatureInterval
  // Amount of the quota used so far
  readonly used: number
  // Charge when the quota is exceeded
  readonly overageCost: number
  // When the quota will next reset
  readonly nextRolloverDate: Date
}

export const FeatureQuotaState = {
  make: Data.tagged<FeatureQuotaState>('FeatureQuotaState'),
}

/**
 * Current state of usage-based pricing for a feature in an active subscription.
 * Tracks the user's usage within the current billing period and indicates when the period ends.
 */
export type FeatureUsagePricingState = {
  readonly _tag: 'FeatureUsagePricingState'
  readonly productId: ProductId
  readonly featureName: ProductFeatureName
  readonly price: number
  readonly unit: string
  readonly unitDescription: string
  readonly interval: ProductFeatureInterval
  // Usage within the current billing period
  readonly currentPeriodUsage: number
  // When the current billing period ends
  readonly currentPeriodEnd: Date
}

export const FeatureUsagePricingState = {
  make: Data.tagged<FeatureUsagePricingState>('FeatureUsagePricingState'),
}

type ProductFeatureState =
  | FeatureQuotaState
  | FeatureLimitedState
  | FeatureUsagePricingState
  | FeatureUnlimitedState

export default ProductFeatureState
