import { Center, Spinner } from '@chakra-ui/react'
import { Function, Match } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { useGlobalNotice } from 'presentation/main/GlobalNotice'
import { usePaymentFailedModal } from 'presentation/main/globalModals/PaymentFailedModal/usePaymentFailedModal'
import {
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom'

export const SubscribedOnly = ({ redirectTo }: {
  redirectTo: string
}) => {
  const access = useAccessStore(state => state.local.access)

  return Function.pipe(
    Match.value(access),
    Match.when({ data: { subscription: {} } }, () => (
      <Outlet />
    )),
    Match.when({ data: { subscription: Match.null } }, () => (
      <Navigate to={redirectTo} />
    )),
    Match.orElse(() => (
      <Center h='app-height'>
        <Spinner />
      </Center>
    )),
  )
}

export const AuthenticationControl = ({ shouldBe, redirectTo }: {
  shouldBe: boolean
  redirectTo: string
}) => {
  const access = useAccessStore(state => state.local.access)
  const [searchParams] = useSearchParams()
  const redirectUrlFromQuery = searchParams.get('redirect')

  if (shouldBe) {
    return Function.pipe(
      Match.value(access),
      Match.when({ data: { status: 'logged-in' } }, () => (
        <>
          <AuthenticatedOnlyHooks />
          <Outlet />
        </>
      )),
      Match.when({ data: { status: 'logged-out' } }, () => (
        <Navigate to={redirectUrlFromQuery || redirectTo} />
      )),
      Match.orElse(() => (
        <Center h='app-height'>
          <Spinner />
        </Center>
      )),
    )
  } else {
    return Function.pipe(
      Match.value(access),
      Match.when({ data: { status: 'logged-in' } }, () => (
        <Navigate to={redirectUrlFromQuery || redirectTo} />
      )),
      Match.when({ data: { status: 'logged-out' } }, () => (
        <Outlet />
      )),
      Match.orElse(() => (
        <Center h='app-height'>
          <Spinner />
        </Center>
      )),
    )
  }
}

export const EmailConfirmationControl = ({ shouldBe, redirectTo }: {
  shouldBe: boolean
  redirectTo: string
}) => {
  const access = useAccessStore(state => state.local.access)

  if (shouldBe) {
    return Function.pipe(
      Match.value(access),
      Match.when({ data: { accessor: { isEmailConfirmed: true } } }, () => (
        <Outlet />
      )),
      Match.when({ data: { accessor: { isEmailConfirmed: false } } }, () => (
        <Navigate to={redirectTo} />
      )),
      Match.orElse(() => (
        <Center h='app-height'>
          <Spinner />
        </Center>
      )),
    )
  } else {
    return Function.pipe(
      Match.value(access),
      Match.when({ data: { accessor: { isEmailConfirmed: true } } }, () => (
        <Navigate to={redirectTo} />
      )),
      Match.when({ data: { accessor: { isEmailConfirmed: false } } }, () => (
        <Outlet />
      )),
      Match.orElse(() => (
        <Center h='app-height'>
          <Spinner />
        </Center>
      )),
    )
  }
}

export const AuthenticatedOnlyHooks = () => {
  usePaymentFailedModal()
  useGlobalNotice()

  return null
}
