import { createIcon } from '@chakra-ui/react'

export const EraserIcon = createIcon({
  displayName: 'EraserIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='m11.607 6.535-7.78 7.779a2 2 0 0 0 0 2.828l1.537 1.536a2 2 0 0 0 1.414.585h6.243M11.607 6.535l2.12-2.12a2 2 0 0 1 2.83 0l4.242 4.242a2 2 0 0 1 0 2.828l-2.121 2.122m-7.071-7.072 7.07 7.072m-5.656 5.656 5.657-5.656m-5.657 5.656H20'
      fill='none'
    />
  ),
})
