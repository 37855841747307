import { HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { Dollar } from 'libs/dollar'
import { ArrowDownIcon, ArrowUpIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag/MLSStatusTag'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { isNumber } from 'remeda'
import { getRecKey } from 'utils/getRecKey'
import { shallow } from 'zustand/shallow'

export const MLSSubSection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return null
    return property.status === 'with-details'
      ? {
        mlsListingOffice: property.mlsListings[0]?.organization?.name || null,
        mlsNumber: property.mlsListings[0]?.listingId || null,
        mlsPrice: property.mlsListings[0]?.listPrice || null,
        mlsStatus: property.mlsListings[0]?.status || null,
        mlsSaleDate: property.mlsListings[0]?.saleDate || null,

        /** @TODO Future plan; not yet in system */
        mlsPriceChange: null,
      }
      : {
        mlsListingOffice: '',
        mlsNumber: null,
        mlsPrice: null,
        mlsPriceChange: null,
        mlsStatus: null,
        mlsSaleDate: null,
      }
  }, shallow)

  if (!data) return null

  const {
    mlsStatus,
    mlsPrice,
    mlsPriceChange,
    mlsListingOffice,
    mlsSaleDate,
    mlsNumber,
  } = data

  const statusTheme = mlsStatus ? getRecKey(mlsStatus)(THEME_BY_STATUS) : EMPTY_THEME

  return (
    <VStack
      borderRadius={2}
      borderWidth='0.25'
      borderColor={statusTheme?.borderColor}
      p={2}
      h={mbp({
        dtLg: '168px',
        dtSm: '192px',
        tabSm: '154px',
        mobSm: '168px',
      })}
      alignItems='stretch'
    >
      {!isNumber(mlsPrice)
        ? (
          <>
            <Spacer />
            <Text textStyle='bodyMFat' color='grayweak.900' textAlign='center'>
              No MLS listings found.
            </Text>
            <Spacer />
          </>
        )
        : (
          <>
            <HStack>
              {mlsStatus && (
                <MLSStatusTag
                  status={mlsStatus}
                  date={mlsStatus === 'SOLD' || mlsStatus === 'LEASED'
                    ? mlsSaleDate ?? undefined
                    : undefined}
                />
              )}
              <Spacer />
              {mlsNumber && (
                <Text textStyle='bodySHeavy' color='grayweak.900'>
                  MLS #
                  {mlsNumber}
                </Text>
              )}
            </HStack>

            <Spacer />

            <VStack spacing={0}>
              <HStack spacing='0.5' justify='center'>
                {mlsPriceChange === 0 || mlsPriceChange === null
                  ? null
                  : mlsPriceChange > 0
                    ? <ArrowUpIcon fontSize={2} color='positivesat.500' />
                    : <ArrowDownIcon fontSize={2} color='negative.500' />}

                <Text textStyle='h2' color='graystrong.400'>
                  {Dollar.formatNumber.withCommas(mlsPrice)}
                </Text>
              </HStack>

              {mlsPriceChange === 0 || mlsPriceChange === null
                ? null
                : mlsPriceChange > 0
                  ? (
                    <Text textAlign='center'>
                      <Text as='span' textStyle='bodyM' color='grayweak.900'>
                        Price hike
                      </Text>
                      {' '}
                      <Text as='span' textStyle='bodyM' color='positivesat.500'>
                        {Dollar.formatNumber.withCommas.fallback('--')(mlsPriceChange)}
                      </Text>
                    </Text>
                  )
                  : (
                    <Text textAlign='center'>
                      <Text as='span' textStyle='bodyM' color='grayweak.900'>
                        Price drop
                      </Text>
                      {' '}
                      <Text as='span' textStyle='bodyM' color='negative.500'>
                        {Dollar.formatNumber.withCommas.fallback('--')(mlsPriceChange)}
                      </Text>
                    </Text>
                  )}
            </VStack>

            <Spacer />

            <Text textAlign='center' textStyle='tagS' color='grayweak.900'>
              Listing provided by
              {' '}
              {mlsListingOffice}
            </Text>
          </>
        )}
    </VStack>
  )
}

type ThemeByStatus = Record<MLSStatus, { borderColor: string }>
const THEME_BY_STATUS: ThemeByStatus = {
  FOR_SALE: { borderColor: 'positive.500' },
  FOR_LEASE: { borderColor: 'positive.500' },
  SALE_OR_LEASE: { borderColor: 'positive.500' },
  PENDING: { borderColor: 'neutral.500' },
  SOLD: { borderColor: 'negative.500' },
  LEASED: { borderColor: 'negative.500' },
  EXPIRED: { borderColor: 'grayweak.500' },
  CANCELED: { borderColor: 'grayweak.500' },
  WITHDRAWN: { borderColor: 'grayweak.500' },
  OFF_MARKET: { borderColor: 'graystrong.500' },
}

const EMPTY_THEME = { borderColor: 'grayweak.500' }
