import { ComponentWithAs, HStack, IconButton, IconProps, VStack } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon, ChevronUpIcon } from 'presentation/components/Icons'
import { Notice, NoticeActions, NoticeIcon, NoticeNav, NoticeProps, NoticeText } from 'presentation/components/Notice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { ReactNode } from 'react'

export const ResponsiveNotice = ({
  colorScheme,
  icon,
  textContent,
  actionsContent,
}: {
  colorScheme: NoticeProps['colorScheme']
  icon?: ComponentWithAs<'svg', IconProps>
  textContent: ReactNode
  actionsContent: ReactNode
}) => {
  const isExpanded = useNoticeStore(store => store.isExpanded)
  const toggleIsExpanded = useNoticeStore(store => store.toggleIsExpanded)

  return (
    <Breakpoint
      mobSm={(
        <Notice colorScheme={colorScheme}>
          <VStack spacing={1} h='full'>
            <HStack
              spacing={1}
              flex={1}
              {...isExpanded
                ? {
                  align: 'flex-start',
                }
                : {
                  my: -1,
                  align: 'center',
                }}
            >
              <NoticeText
                {...isExpanded
                  ? {}
                  : {
                    noOfLines: 1,
                  }}
              >
                {textContent}
              </NoticeText>

              <IconButton
                {...isExpanded
                  ? {
                    mt: '-8px !important',
                  }
                  : {
                  }}
                ml={0.5}
                aria-label='Toggle notice'
                icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={toggleIsExpanded}
                variant='icon-ghost'
                colorScheme='ondark'
                size='lg'
              />
            </HStack>

            {isExpanded && (
              <NoticeActions>
                {actionsContent}

                <NoticeNav />
              </NoticeActions>
            )}
          </VStack>
        </Notice>
      )}
      tabSm={(
        <Notice colorScheme={colorScheme}>
          {icon && <NoticeIcon as={icon} />}

          <NoticeText>
            {textContent}
          </NoticeText>

          <NoticeActions>
            {actionsContent}

            <NoticeNav />
          </NoticeActions>
        </Notice>
      )}
    />
  )
}
