import { Grid, HStack, Text } from '@chakra-ui/react'
import { getUserInputOrOriginalData } from 'features/PropertyDetails/domain/PropertyDetails.utils'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { NumberLib } from 'libs/Number'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Card } from 'presentation/components/molecules/Card'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { EditSqftMenuButton } from 'presentation/screens/PropertyDetailsScreen/components/FeaturesSection/EditSqftMenuButton'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { isNumber } from 'remeda'
import { ensureNumberOr, ensureStringOr, formatCommasOr } from 'utils/dataAdapter'
import { shallow } from 'zustand/shallow'

export const FeaturesSection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const { sbp } = useSwitchBreakpointFn()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details') return null

    const building = property.building
    const land = property.land
    const userInputData = property.userInputData
    const originalData = property.originalData

    return {
      bedroomsCount: NumberLib.ensureNumberOrNull(building?.bedroomsCount),
      totalBathroomsCount: building?.bathroomsCount?.total ?? null,
      fullBathroomsCount: building?.bathroomsCount?.full ?? null,
      halfBathroomsCount: building?.bathroomsCount?.half ?? null,
      garageSpacesCount: NumberLib.ensureNumberOrNull(building?.garageSpacesCount),
      livingAreaSqft: NumberLib.orNull(
        getUserInputOrOriginalData(data => data.building?.livingAreaSqft)(property),
      ),
      yearBuilt: building?.yearBuilt || null,
      fireplacesCount: NumberLib.orNull(building?.fireplacesCount),
      hasBasement:
        !!building?.basementAreaSqft
        || (!!building?.basementType
        && building.basementType !== 'None'),
      storiesCount: NumberLib.ensureNumberOrNull(building?.storiesCount),
      lotAreaSqft: NumberLib.ensureNumberOrNull(land?.lotAreaSqft),
      lotAreaAcres: NumberLib.ensureNumberOrNull(land?.lotAreaAcres),
      hasPool: building?.hasPool ?? null,
      isLivingAreaSqftUserInput: isNumber(userInputData.building?.livingAreaSqft)
      && userInputData.building?.livingAreaSqft !== originalData.building?.livingAreaSqft,
    }
  }, shallow)

  if (!data) return null

  const keyValues = {
    'Beds': ensureNumberOr('--')(data.bedroomsCount),
    'Bath': ensureStringOr('--')(data.totalBathroomsCount),
    'Garage': ensureNumberOr('--')(data.garageSpacesCount),
    'SqFt': formatCommasOr('--')(data.livingAreaSqft),
    'Lot SqFt': formatCommasOr('--')(data.lotAreaSqft),
    'Built': ensureStringOr('--')(data.yearBuilt),
    'Acres': NumberLib.formatCommasDecimalsOrDoubleDash(data.lotAreaAcres),
    'Pool': data.hasPool === true ? 'Yes' : data.hasPool === false ? 'No' : '--',
    'Bsmt': data.hasBasement === true ? 'Yes' : data.hasBasement === false ? 'No' : '--',
    'Fireplace': ensureNumberOr('--')(data.fireplacesCount),
    'Stories': ensureNumberOr('--')(data.storiesCount),
  }

  return (
    <Card
      bg='highlight.100'
      size={mbp({
        mobSm: 'xxs-locked',
        mob: 'sm-locked',
        dtSm: 'xxs-locked',
        dtLg: 'sm-locked',
      })}
      sx={{
        ...sbp({
          dtSm: {
            px: '0 !important',
          },
          dtLg: {

          },
        }),
      }}
    >
      <Grid
        sx={{
          ...mbpg({
            mobSm: {
              'gridTemplateColumns': 'repeat(6, 1fr)',
              'rowGap': 2,
              'columnGap': 2,
              '& > div': {
                gridColumn: 'span 2',
              },
              '& > div:nth-last-of-type(-n+2)': {
                gridColumn: 'span 3',
              },
            },
            mob: {
              'gridTemplateColumns': 'repeat(12, 1fr)',
              '& > div': {
                gridColumn: 'span 3',
              },
              '& > div:nth-last-of-type(-n+3) ': {
                gridColumn: 'span 4',
              },
            },
            tabSm: {
              'gridTemplateColumns': 'repeat(6, 1fr)',
              '& > div:nth-last-of-type(n) ': {
                gridColumn: 'span 1',
              },
              '& > div:nth-of-type(6)': {
                gridRow: 'span 2',
                alignSelf: 'center',
              },
            },
            dtSm: {
              'rowGap': 2,
              'gridTemplateColumns': '1fr',
              'h': 'full',
              'gridTemplateRows': 'repeat(11, 1fr)',
              '& > div:nth-of-type(6)': {
                gridRow: 'span 1',
              },
            },
            dtLg: {
              gridTemplateColumns: 'repeat(11, 1fr)',
              gridTemplateRows: 'repeat(1, 1fr)',
            },
          }),
        }}
      >
        {Object.entries(keyValues).map(([key, value], i) => (
          <Pair size={mbp({ mobSm: 'md', dtSm: 'lg' })} key={i}>
            <PairKey textAlign='center'>
              <HStack align='center' justify='center' spacing={0.5} h={3}>
                <div>{key}</div>

                {(key === 'SqFt') && (
                  <EditSqftMenuButton my={-0.5} />
                )}
              </HStack>
            </PairKey>
            <PairValue
              textAlign='center'
            >
              {key === 'SqFt' && data.isLivingAreaSqftUserInput
                ? (
                  <Text
                    color={key === 'SqFt' && data.isLivingAreaSqftUserInput
                      ? 'positive.600'
                      : undefined}
                  >
                    {value}
                  </Text>
                )
                : value}
            </PairValue>
          </Pair>
        ))}
      </Grid>
    </Card>
  )
}
