import { useLayoutBodyHeightCSSVar } from 'presentation/layouts/Layout/components/LayoutBody'
import { useOptimizedHeightTracker } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { useAppDimensionsCssVar } from 'presentation/main/AppLevelHooks/useAppDimensionsCssVar'
import { useColorSchemeHandler } from 'presentation/main/AppLevelHooks/useColorSchemeHandler'
import { useHashScroll } from 'presentation/main/AppLevelHooks/useHashScroll'
import { useRouteScrollReset } from 'presentation/main/AppLevelHooks/useRouteScrollReset'
import { NavigateExposer } from 'presentation/main/Router/navigateExposer'
import { FC } from 'react'
import { useHTMLThemeAttributes } from './useHTMLThemeAttributes'

export const AppLevelHooks: FC = () => {
  useHTMLThemeAttributes()
  useAppDimensionsCssVar()
  useLayoutBodyHeightCSSVar()
  useRouteScrollReset()
  useHashScroll()
  useColorSchemeHandler()
  useOptimizedHeightTracker()
  SupportWidget.useSetup()
  NavigateExposer.useExpose()

  return null
}
