import { createContext, useContext } from 'react'

const context = createContext<{
  isPropertyModal: boolean
}>({
  isPropertyModal: false,
})

export const PropertyModalProvider = context.Provider

export const usePropertyModalContext = () =>
  useContext(context)
