import { BoxProps, chakra } from '@chakra-ui/react'
import { MarkerSize, MarkerType } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { getScale } from 'presentation/components/PropertyMarker/PropertyMarker.utils'

export const MarkerSvgContainer = ({
  size,
  markerType,
  ...props
}: BoxProps & {
  size: MarkerSize
  markerType: MarkerType
}) => (
  <chakra.svg
    className='marker-svg-container'
    {...getScale(size, markerType)}
    overflow='visible'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <chakra.title>PropertyMarker</chakra.title>

    {props.children}
  </chakra.svg>
)
