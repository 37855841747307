import { Flex, Switch, Text } from '@chakra-ui/react'
import { Color } from 'presentation/libs/chakra/chakra.types'
import PlanIntervalSwitcherViewModel from './PlanIntervalSwitcherViewModel'
import { PropsWithChildren } from 'react'

export type PlanIntervalSwitcherProps = {
  monthlyTextColor?: Color
  yearlyTextColor?: Color
}

const PlanIntervalSwitcher = (props: PlanIntervalSwitcherProps) => {
  const vm = PlanIntervalSwitcherViewModel.usePresenter()
  const monthlyTextColor = props.monthlyTextColor ?? 'graystrong.400'
  const yearlyTextColor = props.yearlyTextColor ?? 'positive.500'

  return (
    <Flex alignItems='center' justifyContent='center'>
      <SwitchLabel textColor={monthlyTextColor}>
        Monthly
      </SwitchLabel>

      <Switch
        variant='default'
        mx='2'
        aria-label='Bill yearly?'
        onChange={vm.toggleInterval}
        isChecked={vm.isYearly}
        colorScheme='special'
      />

      <SwitchLabel
        textColor={
          vm.isYearly
            ? (yearlyTextColor || monthlyTextColor)
            : monthlyTextColor
        }
      >
        Yearly
      </SwitchLabel>
    </Flex>
  )
}

export default PlanIntervalSwitcher

type SwitchLabelProps = PropsWithChildren<{
  textColor: Color
}>

const SwitchLabel = ({
  textColor,
  children,
}: SwitchLabelProps) => (
  <Text
    display='inline-block'
    as='span'
    color={textColor}
    cursor='pointer'
    textStyle={{
      base: 'body-h-b',
      mob: 'h4-h-b',
      dtSm: 'h3-h-b',
      dtLg: 'h2-h-b',
    }}
    transitionProperty='color'
    transitionDuration='300ms'
  >
    {children}
  </Text>
)
