import { createIcon } from '@chakra-ui/react'

export const CarIcon = createIcon({
  displayName: 'CarIcon',
  viewBox: '0 0 24 26',
  path: (
    <>
      <g clipPath='url(#clip0_3885_14995)'>
        <path d='M8.11377 17.7645H15.7109M5.20361 10.6524L6.55335 7.78795C6.72108 7.43197 7.06976 7.20622 7.45182 7.20622H16.6309C17.0241 7.20622 17.3808 7.44519 17.5425 7.8169L18.7756 10.6524H5.20361ZM3.55272 13.0077C2.672 13.8983 3.08986 15.2016 3.24895 15.5651C3.40804 15.9286 5.67375 16.0275 6.5655 15.5651C7.45724 15.1026 4.43344 12.1172 3.55272 13.0077ZM20.2715 13.0077C21.1522 13.8983 20.7344 15.2016 20.5753 15.5651C20.4162 15.9286 18.1505 16.0275 17.2587 15.5651C16.367 15.1026 19.3908 12.1172 20.2715 13.0077ZM2.45836 9.95365L4.51317 5.77408C4.85469 5.07942 5.54307 4.64209 6.29498 4.64209L17.5187 4.64213C18.2706 4.64213 18.959 5.07948 19.3005 5.77416L21.3551 9.9537H23.6406L23.2121 11.9255H22.1408L23.2121 13.4808V18.0041C23.2121 18.3684 23.0278 18.7059 22.7266 18.8933L22.6263 18.9557C22.3251 19.1431 22.1408 19.4807 22.1408 19.8449V21.6278C22.1408 22.2005 21.6931 22.6648 21.1408 22.6648H18.8479C18.2956 22.6648 17.8479 22.2005 17.8479 21.6278V21.2576C17.8479 20.6849 17.4002 20.2206 16.8479 20.2206H6.57094C6.01866 20.2206 5.57094 20.6849 5.57094 21.2576V21.6278C5.57094 22.2005 5.12323 22.6648 4.57094 22.6648H2.49991C1.94762 22.6648 1.49991 22.2005 1.49991 21.6278V19.8449C1.49991 19.4807 1.31561 19.1431 1.0144 18.9557L0.914081 18.8933C0.612874 18.7059 0.428574 18.3684 0.428574 18.0041V13.4808L1.49991 11.9255H0.428574L0 9.9537L2.45836 9.95365Z' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_3885_14995'>
          <rect width='24' height='24.8879' fill='currentColor' transform='translate(0 0.887939)' />
        </clipPath>
      </defs>
    </>
  ),
})
