import { Schema as S } from '@effect/schema'
import { Effect, Match, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'
import MarketingListSchema from 'features/ListBuilder/infra/repo/schema/MarketingListSchema'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

const EditNameResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.UpdateListName) => pipe(
    Effect.tryPromise({
      try: async () => await restClient
        .put(`lists/v3/${request.listId}`, {
          json: { name: request.name },
        })
        .json(),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404)
          return new ListNotFoundError({ marketingListId: request.listId })

        return new UnknownException(error)
      },
    }),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => toDomain(decoded)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default EditNameResolver

const decode = (u: unknown): MarketingListSchema =>
  S.decodeUnknownSync(MarketingListSchema)(u)

const toDomain = (response: MarketingListSchema): MarketingList => MarketingList.make({
  id: MarketingListId.make(response.id),
  type: ListTypeSchema.toDomain(response.type),
  name: response.name,
  updatedAt: response.updatedAt ?? response.createdAt,
  createdAt: response.createdAt,
  memberCount: response.memberStats?.total ?? 0, // @TODO: throw if no member count
  skiptracedCount: response.memberStats?.skiptraced ?? 0,
  criteria: CriteriaSchema.toDomain(
    Match.value(response.type).pipe(
      Match.when('national-leads-sellers', (): ListType => 'property-lists'),
      Match.when('national-leads-cashbuyers', (): ListType => 'cash-buyers'),
      Match.when('national-leads-privatelenders', (): ListType => 'private-lenders'),
      Match.when('drive', (): ListType => 'drive'),
      Match.exhaustive,
    ),
    response.source?.payload || null,
  ),
})
