import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_MULTI_FAMILY_ICON_W = 45

export const PropTypeMutiFamilyIcon = createIcon({
  displayName: 'PropTypeMutiFamilyIcon',
  viewBox: `0 0 ${PROP_TYPE_MULTI_FAMILY_ICON_W} 33`,
  path: (
    <>
      <rect x='11.625' y='24.625' width='6.75' height='6.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='11.625' y='18.625' width='6.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M26.625 31.875V10.7589L34 3.38388L41.375 10.7589V31.875H26.625Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M3.625 31.875V18.8024L15 9.79715L26.375 18.8024V31.875H3.625Z' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='29.625' y='14.125' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='29.625' y='20.125' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='35.625' y='14.125' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='35.625' y='20.125' width='2.75' height='2.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <rect x='32.625' y='26.125' width='2.75' height='5.75' stroke='currentColor' strokeWidth='1.25' fill='none' />
      <path d='M1 18.5L6 14.5M43.5 10.5L34 1L25 10V15.5L15 7.5L6 14.5M6 14.5V10' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' fill='none' />
    </>
  ),
})
