import { Center, Flex, Spinner } from '@chakra-ui/react'
import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SearchHistoryMapAndListSync } from 'presentation/screens/SearchHistoryScreen/SearchHistoryMapAndListSync'
import { SearchHistoryMeta } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/SearchHistoryMeta'
import { NoSearchHistoryCard } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/NoSearchHistoryCard'
import { SearchEntryCard } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchEntryCard'
import { SearchHistoryListProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryList/SearchHistoryList.props'
import { ReactNode, useEffect } from 'react'
import { map, noop } from 'remeda'
import { shallow } from 'zustand/shallow'

export const SearchHistoryList = (props: SearchHistoryListProps) => {
  const { history, textFilter, seekersFilter } = useSearchHistoryStore(api => ({
    history: api.remote.history,
    textFilter: api.local.textFilter,
    seekersFilter: api.local.seekersFilter,
  }), shallow)

  const { sbp } = useSwitchBreakpointFn()

  const historyData = history.status === 'success'
    ? history.data
    : null

  const isLoading = history.status === 'updating'

  const hasSearchText = SearchHistoryMeta.hasTextFilter(textFilter)

  const hasSeekerFilter = seekersFilter.length > 0

  const list = historyData?.entries ?? []

  const hasNoEntries = list.length === 0

  const highlightedEntry = SearchHistoryMapAndListSync.useStore(
    state => state.highlightedListItem,
  )

  useEffect(() => {
    /**
     * ================
     * Scroll to highlighted entry when hovering markers
     * on the map
     * ================
     */
    if (highlightedEntry === null) return
    if (highlightedEntry.origin === 'list') return
    const elementId = `search-entry-${highlightedEntry.id}`
    document.querySelector(`#${elementId}`)?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [highlightedEntry])

  /**
     * If it is drawer mode, then it is probably the mob and mobSm viewport.
     * We have no hover in mobile. For this we rely on the click event as a tap
     * to highlight the entry or go to the property details.
     */
  const handleOnHover = sbp({
    dtSm: (entry: SearchHistory.Entry) => {
      SearchHistoryMapAndListSync.handleListItemHover(entry.id)
    },
  }) || noop

  const handleOnUnhover = sbp({
    dtSm: () => {
      SearchHistoryMapAndListSync.handleListItemUnhover()
    },
  }) || noop

  /**
     * If it is not drawer mode but it still received a click/tap event, this means
     * this list is rendered in the side panel. If the user is clicking on desktop,
     * it should redirect to the property details. If user is tapping on tablet,
     * it should highlight the entry in the map because the map is visible in tablet
     * mode. If the user is tapping on mobile, it should redirect to the property details
     * because the map is not visible in mobile mode.
     */
  const handleOnClick = sbp({
    mobSm: () => () => {},
    tabSm: (entry: SearchHistory.Entry) => (ev: React.MouseEvent) => {
      ev.preventDefault()
      SearchHistoryMapAndListSync.handleListItemClick(entry.id)
    },
    dtSm: () => () => {},
  }) || (() => noop)

  if (isLoading) {
    return (
      <Center {...props} h='100%'>
        <Spinner color='neutral.500' />
      </Center>
    )
  }

  if (hasNoEntries && !isLoading) {
    return (
      <NoSearchHistoryCard
        hasTextFilter={hasSearchText}
        hasSeekerFilter={hasSeekerFilter}
      />
    )
  }

  const toSearchEntryCard = (entry: SearchHistory.Entry): ReactNode => (
    <SearchEntryCard
      key={entry.id}
      isHighlighted={highlightedEntry?.id === entry.id}
      onMouseEnter={() => handleOnHover(entry)}
      onMouseLeave={() => handleOnUnhover()}
      onClick={ev => handleOnClick(entry)(ev)}
      entry={entry}
    />
  )

  const entries = map(
    list,
    toSearchEntryCard,
  )

  return (
    <Flex
      flexDirection='column'
      gap={3}
      pointerEvents='auto'
      {...props}
    >
      {entries}
    </Flex>
  )
}
