import { toast } from 'presentation/components/Toast'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { FailedInvoiceSummary } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { openRestoreFailedInvoiceModal } from 'presentation/main/globalModals/RestorePlanModal/openRestoreFailedInvoiceModal'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { openErrorModal } from 'presentation/screens/Billing/Billing.modals'

export const restoreWithPayment = (failedInvoiceSummary: FailedInvoiceSummary) => {
  openRestoreFailedInvoiceModal({
    failedInvoiceSummary,
    onSuccess: message => {
      toast({
        ...TOAST_PRESET.GENERIC_SUCCESS,
        message,
      })
      closeModal()
    },
    onError: error => openErrorModal({
      message: error.message,
    }),
  })
}
