import { ModalOverlay } from '@chakra-ui/react'
import hurtDogPng from 'presentation/assets/img/3d/hurt-dog.png'
import { ModalContentV1, ModalV1 } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FC } from 'react'
import { ErrorModalProps } from './ErrorModal.types'

export const ErrorModal: FC<ErrorModalProps> = ({
  isOpen = true,
  onClose,
  title = 'We couldn’t process your request',
  message,
  shouldHideCloseButton = false,
  actionButtons,
  ...props
}) => (
  <ModalV1
    {...props}
    isOpen={isOpen}
    onClose={onClose}
    variant='modal.alert'
    colorScheme='modal.alert.negative'
    size='modal.alert.xn'
  >
    <ModalOverlay />
    <ModalContentV1>
      <CardImage src={hurtDogPng} />
      {!shouldHideCloseButton && <ModalCloseButton onClose={onClose} />}
      <CardPrimaryTitle>
        {title}
      </CardPrimaryTitle>
      <CardBody>
        <CardPrimaryText>
          {message ?? 'Please try again or let us know using our live support'}
        </CardPrimaryText>
      </CardBody>
      <CardFooter
        {...mbpg({
          mobSm: {
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '2',
          },
          tabSm: {
            flexDirection: 'row',
            alignItems: 'initial',
            gap: '3',
          },
        })}
      >
        {actionButtons
          ? actionButtons()
          : (
            <CardButton
              variant='solid'
              colorScheme='negative'
              onClick={goBackModal}
              autoFocus
            >
              TRY AGAIN!
            </CardButton>
          )}
      </CardFooter>
    </ModalContentV1>
  </ModalV1>
)
