import { SelectCompsByOption } from 'features/CMA/infra/react/CMAState.selectors'
import { create } from 'zustand'

export type ActiveCMAEntriesTab = SelectCompsByOption

export type UseActiveEntriesTabStore = {
  selectedListType: ActiveCMAEntriesTab
  isReportOpen: boolean
}

export const useActiveEntriesTab = create<UseActiveEntriesTabStore>(() => ({
  selectedListType: 'SALES',
  isReportOpen: false,
}))

export const openCMASalesTab = () =>
  useActiveEntriesTab.setState({ selectedListType: 'SALES', isReportOpen: false })

export const openCMARentalsTab = () =>
  useActiveEntriesTab.setState({ selectedListType: 'RENTALS', isReportOpen: false })

export const openCMASummaryTab = () =>
  useActiveEntriesTab.setState({ isReportOpen: true })

export const selectActiveTab = (state: UseActiveEntriesTabStore) =>
  state.isReportOpen ? 'REPORT' : state.selectedListType
