import { MarketingList } from 'features/ListBuilder/domain/MarketingList'
import { ActorRefFrom, setup } from 'xstate'

type SelectableListContext = {
  list: MarketingList
}

type SelectableListEvent =
  | { type: 'list.toggle' }

type SelectableListInput = {
  list: MarketingList
}

const make = (isChecked: boolean) => setup({
  types: {
    context: {} as SelectableListContext,
    events: {} as SelectableListEvent,
    input: {} as SelectableListInput,
  },
}).createMachine({
  id: 'SelectableListMachine',
  initial: isChecked ? 'Checked' : 'Unchecked',
  context: ({ input }) => ({
    list: input.list,
  }),
  states: {
    Checked: {
      on: {
        'list.toggle': 'Unchecked',
      },
    },
    Unchecked: {
      on: {
        'list.toggle': 'Checked',
      },
    },
  },
})

type SelectableListMachine = ActorRefFrom<ReturnType<typeof make>>

const SelectableListMachine = {
  make,
}

export default SelectableListMachine
