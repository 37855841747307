import { Box, HStack, Switch } from '@chakra-ui/react'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { PartialRange } from 'features/valueObjects/Range'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import ChakraLib from 'presentation/libs/Chakra'
import { ListBuilderRangeField } from 'presentation/screens/ListBuilderScreen/components/ListBuilderRangeField/ListBuilderRangeField'
import { mbp } from 'presentation/utils/mapBreakpoint'
import React from 'react'

export const makeOptionalRangeCard = ({
  title,
  displayNamePrefix,
  useApi,
  formatInputDisplay,
  minLabel,
  maxLabel,
  minPlaceholder,
  maxPlaceholder,
}: {
  title: string
  displayNamePrefix: string
  useApi: () => UseCriteriaHookApi<PartialRange>
  formatInputDisplay?: (value: string | number) => string
  minLabel: string
  maxLabel: string
  minPlaceholder?: string
  maxPlaceholder?: string
}) => {
  const OptinalRangeCard = ({
    shouldStack = false,
  }: {
    shouldStack?: boolean
  }) => {
    const api = useApi()
    const [isOpen, setIsOpen] = React.useState(PartialRange.checkHasValue(api.value))

    return (
      <Card
        variant='default'
        colorScheme='card.bg.1'
        size={mbp({
          mobSm: 'xxs-locked',
          tabSm: 'sm-locked',
        })}
        align='stretch'
        justify='center'
        bg={isOpen ? 'neutral.200' : undefined}
        h='full'
        onClick={ev => {
          if (isOpen) return
          const isChakraSwitchClick = ChakraLib.isTargetChakraSwitch(ev.target)
          if (isChakraSwitchClick) return
          setIsOpen(true)
        }}
      >
        <CardHeader>
          <HStack>
            <CardSecondaryTitle justifySelf='flex-start' flex='1'>
              {title}
            </CardSecondaryTitle>
            <Switch
              colorScheme='default'
              isChecked={isOpen}
              onChange={() => {
                setIsOpen(!isOpen)
                const willClose = isOpen
                if (willClose)
                  api.apply(PartialRange.EMPTY)
              }}
            />
          </HStack>
        </CardHeader>

        {isOpen && (
          <Box mt={2}>
            <ListBuilderRangeField
              minLabel={minLabel}
              maxLabel={maxLabel}
              minPlaceholder={minPlaceholder}
              maxPlaceholder={maxPlaceholder}
              initialValue={api.value}
              onValidBlur={api.apply}
              formatInputDisplay={formatInputDisplay}
              shouldStack={shouldStack}
            />
          </Box>
        )}
      </Card>
    )
  }

  OptinalRangeCard.displayName = `${displayNamePrefix}Card`

  return OptinalRangeCard
}
