import { BoxProps, Button } from '@chakra-ui/react'
import { PlusIcon } from 'presentation/components/Icons'
import { Card } from 'presentation/components/molecules/Card'
import { openAddPaymentModal } from 'presentation/screens/Billing/Billing.modals'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export const AddPaymentCard: FC<BoxProps & { onClick?: () => void }> = ({
  onClick = () => openAddPaymentModal(),
  ...props
}) => (
  <Card
    colorScheme='card.bg.1'
    size='xs'
    alignItems='center'
    justifyContent='center'
    h={mbp({ mobSm: '147px', mob: '171px' })}
    cursor='pointer'
    boxShadow='primary.w'
    onClick={onClick}
    borderRadius='2'
    {...props}
  >
    <Button
      size={mbp({ mobSm: 'sm', dtLg: 'md' })}
      variant='outline'
      colorScheme='neutral'
      leftIcon={<PlusIcon boxSize='3' />}
    >
      Add Credit Card
    </Button>
  </Card>
)
