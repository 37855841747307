import { gql } from '__generated__'

export const GET_MARKETING_LISTS = gql(/* GraphQL */`
  query GetMarketingLists($first: Int, $types: [ListTypes!], $after: ID, $last: Int, $before: ID) {
    marketingLists(first: $first, types: $types, after: $after, last: $last, before: $before) {
      ... on MarketingListsConnection {
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            createdAt
            updatedAt
            type
            itemStats {
              total
              skiptracing
              skiptraced
            }
          }
        }
      }
      ... on MarketingListBadRequestError {
        message
        field
      }
    }
  }
`)
