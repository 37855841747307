import { SearchHistory } from 'features/SearchHistory/domain/SearchHistory.domain'
import { GetHistoryParams, getHistory, getHistory as getHistoryRemote } from 'features/SearchHistory/infra/HistoryQuery/getHistory'
import { NormalizedHistory } from 'features/SearchHistory/infra/HistoryQuery/getHistory.types'
import { getSeekers, getSeekers as getSeekersRemote } from 'features/SearchHistory/infra/SeekersQuery/getSeekers'
import { deleteEntries } from 'features/SearchHistory/infra/remote/deleteEntries'
import { ErrorLib, ReportableException } from 'libs/errors'
import { PanicError } from 'libs/errors/PanicError'
import { ID } from 'libs/id'
import { nanoid } from 'nanoid'
import { createContext, useContext, useMemo, useRef } from 'react'
import { setPath } from 'remeda'
import { debounce } from 'throttle-debounce'
import { createAwaitableSingleton } from 'utils/createSingleton'
import { create, useStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type RemoteState<T> = T & {
  requestId: string
}

export type SearchHistoryDeps = {
  getHistory: typeof getHistoryRemote
  getSeekers: typeof getSeekersRemote
  deleteEntries: (payload: SearchHistory.DeleteEntriesPayload) =>
  Promise<SearchHistory.DeleteEntriesResult>
}

export type SearchHistoryConfig = {
  debounceMs?: number
}

/**
 * @TODO seeker mapping should've been internal implementation of getHistory.
 * And the issue of redundant requests seeker request should've been handled
 * there using debouncing and caching
 */
type SearchHistoryState = {
  __internal: {
    unprocessedHistoryState: RemoteState<
      { status: 'error' }
      | { status: 'awaiting-process', data: NormalizedHistory }
    > | null
  }

  local: {
    textFilter: string
    seekersFilter: ID[]
    sorting: SearchHistory.Sorting
    currentPage: number
    pageLimit: number
  }

  remote: {
    seekers: RemoteState<
      | { status: 'loading', hasInitiatedRequest: boolean }
      | { status: 'error' }
      | { status: 'success', data: SearchHistory.Seeker[] }
    >

    history: RemoteState<
      | { status: 'loading', hasInitiatedRequest: boolean }
      | { status: 'error' }
      | {
        status: 'updating'
        /**
       * data becomes null if for example filter is updated before
       * the first request is finished
       */
        previousData: SearchHistory.History | null
      }
      | { status: 'success', data: SearchHistory.History }
    >
  }

  actions: {
    initialize: () => Promise<void>
    filterByText: (text: string) => Promise<void>
    filterBySeekers: (seekers: ID[]) => Promise<void>
    toggleSeekerFilter: (seeker: ID) => Promise<void>
    sort: (sorting: SearchHistory.Sorting) => Promise<void>
    goToNextPage: () => Promise<void>
    goToPrevPage: () => Promise<void>
    getPage: (page: number) => Promise<void>
    clearHistory: () => void
    deleteEntries: {
      state: { status: 'idle' }
      | { status: 'loading', payload: SearchHistory.DeleteEntriesPayload }
      | { status: 'error', error: SearchHistory.DeleteEntriesError, payload: SearchHistory.DeleteEntriesPayload }
      | { status: 'success', payload: SearchHistory.DeleteEntriesPayload }
      execute: (payload: SearchHistory.DeleteEntriesPayload) => Promise<void>
    }
  }
}

const INITIAL_REQUEST_ID = 'INITIAL_REQUEST_ID'

export const HISTORY_ITEM_PER_PAGE = 20

export type CreateSearchHistoryStoreParams = SearchHistoryDeps & SearchHistoryConfig

export const createSearchHistoryStore = ({
  debounceMs = 500,
  ...deps
}: CreateSearchHistoryStoreParams) => create<SearchHistoryState>()(immer((set, get) => {
  // ========================================
  // Helpers
  // ========================================

  const getHistoryParams = (): GetHistoryParams => ({
    limit: HISTORY_ITEM_PER_PAGE,
    textFilter: get().local.textFilter,
    seekersFilter: get().local.seekersFilter,
    sorting: get().local.sorting,
    offset: (get().local.currentPage - 1) * HISTORY_ITEM_PER_PAGE,
  })

  const setHistoryState = (historyState: SearchHistoryState['remote']['history']) =>
    set(state => setPath(
      state,
      ['remote', 'history'],
      historyState,
    ))

  const setUnprocessedHistoryState = (historyState: SearchHistoryState['__internal']['unprocessedHistoryState']) =>
    set(state => setPath(
      state,
      ['__internal', 'unprocessedHistoryState'],
      historyState,
    ))

  const setSeekersState = (seekersState: SearchHistoryState['remote']['seekers']) =>
    set(state => setPath(
      state,
      ['remote', 'seekers'],
      seekersState,
    ))

  // ========================================
  // History processors and updaters
  // ========================================

  /**
   * Looks at unprocessedHistoryState and seekers state and updates history state accordingly
   *
   * History is dependent on seekers because seekers are just IDs in the raw history
   */
  const updateHistoryState = () => {
    const unprocessedHistoryState = get().__internal.unprocessedHistoryState
    const historyState = get().remote.history
    const seekersState = get().remote.seekers

    if (unprocessedHistoryState?.status === 'error' || seekersState.status === 'error')
      return setHistoryState({ status: 'error', requestId: historyState.requestId })

    if (unprocessedHistoryState?.status === 'awaiting-process' && seekersState.status === 'success') {
      return setHistoryState({
        status: 'success',
        requestId: unprocessedHistoryState.requestId,
        data: processHistory(unprocessedHistoryState.data, seekersState.data),
      })
    }
  }

  /**
   * Replaces seeker IDs in history with actual seeker objects
   */
  const processHistory = (
    unprocessedHistory: NormalizedHistory,
    seekers: SearchHistory.Seeker[],
  ): SearchHistory.History => {
    const seekersById = new Map(seekers.map(seeker => [seeker.id, seeker]))
    const FALLBACK_SEEKER: SearchHistory.Seeker = {
      avatarUrl: null,
      id: '',
      name: {
        first: '',
        last: '',
        full: '',
      },
    }

    let seekerNotFound = false
    const processedEntries = unprocessedHistory.entries.map((entry): SearchHistory.Entry => {
      const seeker = seekersById.get(entry.seeker)

      if (!seeker)
        seekerNotFound = true

      return {
        ...entry,
        seeker: seeker || FALLBACK_SEEKER,
      }
    })

    if (seekerNotFound) {
      void ErrorLib.report(new ReportableException('Seeker not found in history', {
        extraInfo: {
          normalizedHistory: unprocessedHistory,
          seekers,
        },
      }))
    }

    return {
      ...unprocessedHistory,
      entries: processedEntries,
    }
  }

  // ========================================
  // action.initialize
  // ========================================

  const initialize = async () => {
    await Promise.all([
      initializeHistory(),
      initializeSeekers(),
    ])
  }

  const initializeHistory = async () => {
    const requestId = nanoid()

    setUnprocessedHistoryState(null)
    setHistoryState({ status: 'loading', requestId, hasInitiatedRequest: true })

    const params = getHistoryParams()

    await deps.getHistory(params)
      .then(resp => ({ status: 'awaiting-process' as const, requestId, data: resp.history }))
      .catch(() => ({ status: 'error' as const, requestId }))
      .then(normalizedHistoryState => {
        const latestRequestId = get().remote.history.requestId

        if (requestId !== latestRequestId) return

        setUnprocessedHistoryState(normalizedHistoryState)
        updateHistoryState()
      })
  }

  const initializeSeekers = async () => {
    const requestId = nanoid()
    set(state => setPath(
      state,
      ['remote', 'seekers'],
      { status: 'loading', requestId, hasInitiatedRequest: true },
    ))

    await deps.getSeekers()
      .then(resp => ({ status: 'success' as const, requestId, data: resp.seekers }))
      .catch(() => ({ status: 'error' as const, requestId }))
      .then(result => {
        const latestRequestId = get().remote.seekers.requestId

        if (requestId !== latestRequestId) return

        setSeekersState(result)
        updateHistoryState()
      })
  }

  // ========================================
  // updateFilter and updateFilterDebounced
  // ========================================
  const refetchHistory = async () => {
    const historyState = get().remote.history

    const previousData = historyState.status === 'success'
      ? historyState.data
      : null

    const requestId = nanoid()

    setUnprocessedHistoryState(null)
    setHistoryState({ status: 'updating', requestId, previousData })

    const params = getHistoryParams()

    await deps.getHistory(params)
      .then(resp => ({ status: 'awaiting-process' as const, requestId, data: resp.history }))
      .catch(() => ({ status: 'error' as const, requestId }))
      .then(normalizedHistoryState => {
        const latestRequestId = get().remote.history.requestId

        if (requestId !== latestRequestId) return

        setUnprocessedHistoryState(normalizedHistoryState)
        updateHistoryState()
      })
  }

  const refetchHistoryDebounced = debounce(debounceMs, refetchHistory)

  // ========================================
  // other actions
  // ========================================

  const manageLocalFilterUpdate = (localStateUpdate: Partial<SearchHistoryState['local']>) => {
    set(state => setPath(
      state,
      ['local'],
      {
        ...state.local,
        ...localStateUpdate,

        /** Reset page when updating filters */
        currentPage: 1,
      },
    ))
  }

  const filterByText = async (text: string) => {
    manageLocalFilterUpdate({ textFilter: text })
    await refetchHistoryDebounced()
  }

  const filterBySeekers = async (seekers: ID[]) => {
    manageLocalFilterUpdate({ seekersFilter: seekers })
    await refetchHistoryDebounced()
  }

  const sort = async (sorting: SearchHistory.Sorting) => {
    manageLocalFilterUpdate({ sorting })
    await refetchHistoryDebounced()
  }

  const toggleSeekerFilter = async (seeker: ID) => {
    const isAlreadyIncluded = get().local.seekersFilter.includes(seeker)
    manageLocalFilterUpdate({
      seekersFilter: isAlreadyIncluded
        ? get().local.seekersFilter.filter(id => id !== seeker)
        : [...get().local.seekersFilter, seeker],
    })

    await refetchHistoryDebounced()
  }

  const getPage = async (
    /** First page is with 1, NOT ZERO */
    page: number,
  ) => {
    set(state => setPath(
      state,
      ['local', 'currentPage'],
      page,
    ))
    await refetchHistory()
  }

  const goToPrevPage = async () => {
    const currentPage = get().local.currentPage
    if (currentPage <= 1) return
    await getPage(currentPage - 1)
  }

  const goToNextPage = async () => {
    const historyState = get().remote.history
    const totalPages = historyState.status === 'success'
      && historyState.data.totalPages

    const currentPage = get().local.currentPage

    if (!totalPages || currentPage >= totalPages) return

    await getPage(currentPage + 1)
  }

  const clearHistory = () => {
    setHistoryState({ status: 'loading', requestId: INITIAL_REQUEST_ID, hasInitiatedRequest: false })
    setUnprocessedHistoryState(null)
  }

  // ========================================
  // deleteEntries
  // ========================================
  const deleteEntries: SearchHistoryState['actions']['deleteEntries'] = {
    state: { status: 'idle' },
    execute: async payload => {
      set(state => {
        state.actions.deleteEntries.state = {
          status: 'loading',
          payload,
        }
      })

      await deps.deleteEntries(payload)
        .then(() => {
          set(state => {
            state.actions.deleteEntries.state = {
              status: 'success',
              payload,
            }
          })
        })
        .catch(error => {
          set(state => {
            state.actions.deleteEntries.state = {
              status: 'error',
              error,
              payload,
            }
          })
        })
        .then(async () => {
          const isHistoryLoaded = get().remote.history.status === 'success'
            || get().remote.history.status === 'updating'

          if (!isHistoryLoaded) return

          await refetchHistory()
        })
    },
  }

  // ========================================
  // returned initial state
  // ========================================

  return {
    __internal: {
      unprocessedHistoryState: null,
    },

    local: {
      textFilter: '',
      seekersFilter: [],
      sorting: 'search-date-new-first',
      currentPage: 1,
      pageLimit: HISTORY_ITEM_PER_PAGE,
    },

    remote: {
      seekers: { status: 'loading', requestId: INITIAL_REQUEST_ID, hasInitiatedRequest: false },
      history: { status: 'loading', requestId: INITIAL_REQUEST_ID, hasInitiatedRequest: false },
    },

    actions: {
      initialize,
      filterByText,
      filterBySeekers,
      toggleSeekerFilter,
      sort,
      getPage,
      goToNextPage,
      goToPrevPage,
      clearHistory,
      deleteEntries,
    },
  }
}))

type SearchHistoryStore = ReturnType<typeof createSearchHistoryStore>

const SearchHistoryContext = createContext<SearchHistoryStore | null>(null)

export type SearchHistoryProviderProps = SearchHistoryDeps & SearchHistoryConfig & {
  environment?: 'app' | 'test' | 'storybook'
  children: React.ReactNode
}

export const {
  createSingleton: createSearchHistoryStoreSingleton,
  getInstance: getSearchHistorySingletonStore,
} = createAwaitableSingleton(createSearchHistoryStore)

export const SearchHistoryProviderDumb = ({
  children,
  environment = 'app',
  ...rest
}: SearchHistoryProviderProps) => {
  const initStore = useMemo(() =>
    environment === 'app'
      ? createSearchHistoryStoreSingleton(rest)
      : createSearchHistoryStore(rest),
  [])

  const store = useRef(initStore).current

  return (
    <SearchHistoryContext.Provider value={store}>
      {children}
    </SearchHistoryContext.Provider>
  )
}

const errorReportedGetHistory = ErrorLib.wrapReportOnReject(getHistory)
const errorReportedGetSeekers = ErrorLib.wrapReportOnReject(getSeekers)
const errorReportedDeleteEntries = ErrorLib.wrapReportOnReject(deleteEntries)

export const SearchHistoryProvider = (props: {
  children: React.ReactNode
}) => (
  <SearchHistoryProviderDumb
    environment='app'
    getHistory={errorReportedGetHistory}
    getSeekers={errorReportedGetSeekers}
    deleteEntries={errorReportedDeleteEntries}
    {...props}
  />
)

// eslint-disable-next-line @stylistic/comma-dangle
export const useSearchHistoryStore = <T,>(
  selector: (state: SearchHistoryState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(SearchHistoryContext)
  if (!store) throw new PanicError('useSearchHistoryStore must be used within SearchHistoryProvider')
  return useStore(store, selector, equalityFn)
}
