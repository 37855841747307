import { createIcon } from '@chakra-ui/react'

export const SubjectPropertyMarkerIcon = createIcon({
  displayName: 'SubjectPropertyMarkerIcon',
  viewBox: '0 0 15 22',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14.359 7.33224C14.359 9.23061 13.5658 11.4181 12.2609 13.802C11.0509 16.0127 9.42785 18.345 7.67322 20.71C5.74998 18.1948 4.12144 15.8548 2.95281 13.6768C1.70172 11.3451 1 9.23918 1 7.33224C1 3.54892 4.00038 0.5 7.67949 0.5C11.3586 0.5 14.359 3.54892 14.359 7.33224Z' fill='#F4E7FF' stroke='#4A2966' />
      <g clipPath='url(#clip0_2541_77106)'>
        <path d='M4.84497 6.99935L7.74152 4.66602L10.6381 6.99935V10.666C10.6381 10.8428 10.5703 11.0124 10.4495 11.1374C10.3288 11.2624 10.1651 11.3327 9.9944 11.3327H5.48865C5.31793 11.3327 5.15421 11.2624 5.0335 11.1374C4.91279 11.0124 4.84497 10.8428 4.84497 10.666V6.99935Z' stroke='#4A2966' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.77588 11.3333V8H8.70691V11.3333' stroke='#4A2966' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2541_77106'>
          <rect width='7.72414' height='8' fill='white' transform='translate(3.87939 4)' />
        </clipPath>
      </defs>
    </svg>
  ),
})
