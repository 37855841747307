import { BoxProps, Center } from '@chakra-ui/react'
import { Card } from 'presentation/components/molecules/Card'

export const MapStatusCard = ({ children, ...props }: BoxProps) => (
  <Card
    px={2}
    py={1}
    bg='highlight.100'
    borderColor='grayweak.500'
    borderWidth={0.125}
    borderRadius={1}
    {...props}
  >
    <Center textStyle='bodyMFat' color='graystrong.600'>
      {children}
    </Center>
  </Card>
)
