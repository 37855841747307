import { createIcon } from '@chakra-ui/react'

export const ClockIcon = createIcon({
  displayName: 'ClockIcon',
  viewBox: '0 0 24 24',
  path: (
    <g
      fill='none'
    >
      <path
        d='M12.0013 20.3327C16.6037 20.3327 20.3346 16.6017 20.3346 11.9993C20.3346 7.39698 16.6037 3.66602 12.0013 3.66602C7.39893 3.66602 3.66797 7.39698 3.66797 11.9993C3.66797 16.6017 7.39893 20.3327 12.0013 20.3327Z'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 7V12L15.3333 13.6667'
        stroke='currentColor'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  ),
})
