import { Flex, FlexProps, Switch, Text } from '@chakra-ui/react'
import { useReportTypeSwitcherStore } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportTypeSwitcherStore'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const CMAReportSwitcher = (props: FlexProps) => {
  const { reportType, toggleReportType } = useReportTypeSwitcherStore()

  const handleToggle = () => {
    toggleReportType()
  }
  return (
    <Flex
      alignItems='center'
      justifyContent='flex-start'
      gap={1}
      {...props}
    >
      <Text
        textStyle={mbp({ mobSm: 'bodyXLFat', tabSm: 'h3' })}
        color={reportType === 'RENTALS' ? 'neutral.500' : 'graystrong.600'}
      >
        Rentals
      </Text>
      <Switch
        colorScheme='special'
        size='lg'
        isChecked={reportType === 'SALES'}
        onChange={handleToggle}
      >
      </Switch>
      <Text
        textStyle={mbp({ mobSm: 'bodyXLFat', tabSm: 'h3' })}
        color={reportType === 'SALES' ? 'positivesat.500' : 'graystrong.600'}
      >
        Sales
      </Text>
    </Flex>
  )
}
