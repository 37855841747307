import { Effect } from 'effect'
import GetOwnersPropertiesPreview from 'features/OwnersProperties/domain/repo/GetOwnersProperties/GetOwnersPropertiesPreview'
import OwnersPropertiesRepo from 'features/OwnersProperties/domain/repo/OwnersPropertiesRepo'
import { EffectLib } from 'libs/Effect'
import { ErrorLib } from 'libs/errors'
import AppCache from 'services/AppCache'
import { fromPromise } from 'xstate'

namespace GetOwnersPropertiesPreviewActor {
  export const make = () => Effect.gen(function * (_) {
    const repo = yield * OwnersPropertiesRepo
    const runPromise = yield * EffectLib.getRunPromiseFromRuntime<AppCache>()

    return fromPromise((params: { input: GetOwnersPropertiesPreview.Params }) =>
      repo.getOwnersPropertiesPreview(params.input).pipe(
        ErrorLib.tapCauseReporter,
        runPromise,
      ))
  })
}

export default GetOwnersPropertiesPreviewActor
