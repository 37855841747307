export const DOMLib = {
  isAncestorOfClass: (element: HTMLElement, className: string): boolean => {
    let currentElement: HTMLElement | null = element

    while (currentElement !== null) {
      if (currentElement.classList.contains(className))
        return true

      currentElement = currentElement.parentElement
    }

    return false
  },
}
