import { Menu, MenuItemOption } from '@chakra-ui/react'
import { Modifier, State } from '@popperjs/core'

/** PopperJS Modifier in order to manipulate the popover arrow according to our needs  */
const arrowModifier: Modifier<'innerArrow', any> = {
  name: 'innerArrow',
  enabled: true,
  phase: 'main',
  requires: ['arrow'],
  fn: ({ state }) => {
    setArrowStyles(state)
  },
  effect:
    ({ state }) =>
      () => {
        setArrowStyles(state)
      },
}

/**
 * Rotates the 'arrow inner' depending on popper placement. Also undos the
 * Chakra's modifier that overrides the transform of the arrow.
 *
 * @NOTE You must manipulate the transform of this element here and not from the
 *   ArrowComponent as the Chakra's modifier will override it and this is the
 *   only way to override it again.
 */
const setArrowStyles = (state: State) => {
  if (!state.elements.arrow) return

  const inner: HTMLElement | null = state.elements.arrow.querySelector(
    '[data-popper-arrow-inner]',
  )

  if (!inner) return
  const isPlacedTop = state.elements.popper.getAttribute('data-popper-placement')?.startsWith('top')

  Object.assign(inner.style, {
    transform: isPlacedTop ? 'rotate(180deg)' : 'rotate(0deg)',
  })
}

Menu.defaultProps = {
  /** Change this offset to tweak the distance */
  offset: [0, 16],
  autoSelect: false,
  modifiers: [
    arrowModifier,
  ],
}

MenuItemOption.defaultProps = {
  icon: null,
}
