import { Checkbox, Flex, Text } from '@chakra-ui/react'
import { MatchSubjectCheckboxProps } from 'presentation/screens/CompsScreen/components/common/MatchSubjectCheckbox/MatchSubjectCheckbox.props'

export const MatchSubjectCheckbox = (props: MatchSubjectCheckboxProps) => (
  <Flex alignItems='center' gap={1} ml='auto'>
    <Text textStyle='bodySFat' color='graystrong.400'>
      {props.label ?? (
        <>Match to Subject Property</>
      )}
    </Text>
    <Checkbox
      size='md'
      {...props}
    />
  </Flex>
)
