import { Match } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { formatBedsBathsGarageFromComp } from 'features/CMA/CMA.helpers'
import { CalculateCompsStatsOptions, DEFAULT_CALCULATE_COMPS_STATS_OPTIONS, calculateCompsStats, createEmptyCompsStats } from 'features/CMA/infra/react/CMAState.helpers'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { NumberLib } from 'libs/Number'
import { StringLib } from 'libs/String'
import { Dollar } from 'libs/dollar'
import { ID } from 'libs/id'
import pluralize from 'pluralize'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useListInfoState } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useListInfoState'
import { useReportTypeSwitcherStore } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAReportSwitcher/useReportTypeSwitcherStore'
import { PropsWithChildren, createContext, useContext } from 'react'
import { createPipe, isNumber, pipe } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

export type ReportItem = {
  id: ID
  isSubject: boolean
  address: {
    line1: string
    subdivision: string
  }
  propertyType: string
  propertyStatus: MLSStatus
  saleDate: Date | null
  bedsBathsGarage: string
  yearBuilt: string
  livingAreaSqft: string
  lotAreaAcres: string
  listPrice: string
  listPricePerSqft: string
  salePrice: string
  salePricePerSqft: string
  distance: string | null
  daysOnMarket: string
  rating: CMA.Comp.UserRating | null
}

export type SummaryStatisticsItem = Omit<ReportItem,
  | 'id'
  | 'address'
  | 'propertyType'
  | 'propertyStatus'
  | 'saleDate'
  | 'rating'
  | 'location'
>

export type SummaryStatistics = {
  [K in keyof SummaryStatisticsItem]: SummaryStatisticsItem[K] extends boolean
    ? null
    : { min: string, max: string, avg: string }
}

export type ReportData = {
  subject: ReportItem
  items: ReportItem[]
  summaryStatistics: SummaryStatistics
}

export type ReportFilterValues = 'SOLD' | 'LEASED' | 'PENDING' | 'ACTIVE' | 'OFF_MARKET'

export type GetData = (filter?: ReportFilterValues) => ReportData

export type ReportResult =
  | { status: 'loading' }
  | { status: 'empty' }
  | { status: 'loaded'
    soldReportData: ReportData
    leasedReportData: ReportData
    pendingReportData: ReportData
    activeReportData: ReportData
    offMarketReportData: ReportData
  }

const Context = createContext<ReportResult | null>(null)

export const ReportSwitcherProvider = (props: PropsWithChildren) => {
  const { sbp } = useSwitchBreakpointFn()
  const reportType = useReportTypeSwitcherStore(store => store.reportType)
  const infoState = useListInfoState({ type: reportType })

  const convertCompsToReport = (
    comp: CMA.SubjectComp | CMA.SingleComp,
  ): ReportItem => {
    const homeTypeString = HomeType.toStringUnstandardized(StringLib.orDoubleDash(comp.propertyType) as HomeType)

    const homeTypeDisplay = pipe(
      Match.value(homeTypeString),
      Match.when('Residential', () => sbp({
        mobSm: 'Resi-dential',
        dtLg: 'Residential',
      }) ?? 'Residential'),
      Match.when('Commercial', () => sbp({
        mobSm: 'Com-mercial',
        dtLg: 'Commercial',
      }) ?? 'Commercial'),
      Match.orElse(() => homeTypeString),
    )

    const item: ReportItem = {
      id: comp.id,
      isSubject: comp.type === 'subject-comp',
      address: {
        line1: comp.address.line1,
        subdivision: StringLib.orDoubleDash(comp.address.subdivision),
      },
      propertyType: homeTypeDisplay,
      propertyStatus: comp.status,
      saleDate: comp.saleDate,
      bedsBathsGarage: formatBedsBathsGarageFromComp(comp),
      yearBuilt: NumberLib.orDoubleDash(comp.yearBuilt).toString(),
      livingAreaSqft: NumberLib.formatCODoDD(comp.livingAreaSqft),
      lotAreaAcres: NumberLib.ifElse(
        NumberLib.omitAfterNPlaces(2))(() => '--')(comp.lotAreaAcres).toString(),
      listPrice: pipe(
        comp.listPrice,
        NumberLib.ifValid(Dollar.formatNumberWithCommas),
        StringLib.orDoubleDash,
      ),
      listPricePerSqft: pipe(
        comp.listPricePerSqft,
        NumberLib.ifValid(Dollar.formatNumberWithCommasDecimals),
        StringLib.orDoubleDash,
      ),
      salePrice: pipe(
        comp.salePrice,
        NumberLib.ifValid(Dollar.formatNumberWithCommas),
        StringLib.orDoubleDash,
      ),
      salePricePerSqft: pipe(
        comp.salePricePerSqft,
        NumberLib.ifValid(Dollar.formatNumberWithCommasDecimals),
        StringLib.orDoubleDash,
      ),
      distance: comp.type !== 'subject-comp'
        ? `${NumberLib.omitAfterNPlaces(2)(comp.distance)} ${sbp({
          dtLg: pluralize('mile', comp.distance),
        }) ?? 'mi'}`
        : 'N/A',
      daysOnMarket: NumberLib.orDoubleDash(comp.daysOnMarket).toString(),
      rating: comp.userRating,
    }

    return item
  }

  // const value = match(infoState)
  //   .returnType<ReportResult>()
  //   .with({ status: 'loaded' }, info => {
  //     const getData: GetData = filter => {
  //       const filterValues = isNonNullable(filter)
  //         ? getFilterValuesForStatus(filter)
  //         : null

  //       const filteredComps = isNonNullable(filterValues)
  //         ? info.data.comps.filter(v => v.userRating === 'included' && filterValues.includes(v.status))
  //         : info.data.comps.filter(v => v.userRating === 'included')

  //       const all: ReportItem[] = filteredComps
  //         .map(convertCompsToReport)

  //       const subject: ReportItem = convertCompsToReport(info.data.subject)

  //       const summaryStatistics: SummaryStatistics = createSummaryStatistics(filteredComps)
  //       return {
  //         subject,
  //         items: all,
  //         summaryStatistics,
  //       }
  //     }

  //     return {
  //       status: info.status,
  //       soldReportData: getData('SOLD'),
  //       leasedReportData: getData('LEASED'),
  //       pendingReportData: getData('PENDING'),
  //       activeReportData: getData('ACTIVE'),
  //       offMarketReportData: getData('OFF_MARKET'),
  //     }
  //   })
  //   .with({ status: 'empty' }, { status: 'loading' }, v => v)
  //   .exhaustive()
  const value = pipe(
    Match.value(infoState),
    Match.when({ status: 'loaded' }, info => {
      const getData: GetData = filter => {
        const filterValues = isNonNullable(filter)
          ? getFilterValuesForStatus(filter)
          : null

        const filteredComps = isNonNullable(filterValues)
          ? info.data.comps.filter(v => v.userRating === 'included' && filterValues.includes(v.status))
          : info.data.comps.filter(v => v.userRating === 'included')

        const all: ReportItem[] = filteredComps
          .map(convertCompsToReport)

        const subject: ReportItem = convertCompsToReport(info.data.subject)

        const summaryStatistics: SummaryStatistics = createSummaryStatistics(filteredComps)
        return {
          subject,
          items: all,
          summaryStatistics,
        }
      }

      return {
        status: info.status,
        soldReportData: getData('SOLD'),
        leasedReportData: getData('LEASED'),
        pendingReportData: getData('PENDING'),
        activeReportData: getData('ACTIVE'),
        offMarketReportData: getData('OFF_MARKET'),
      }
    }),
    Match.when(
      (state): state is { status: 'empty' | 'loading' } =>
        state.status === 'empty'
        || state.status === 'loading',
      state => state,
    ),
    Match.exhaustive,
  )

  return (
    <Context.Provider value={value}>
      {props.children}
    </Context.Provider>
  )
}

export const useReportDataSwitcherStore = (): ReportResult => {
  const context = useContext(Context)
  if (!context)
    throw Error('useReportSwitcherStore must be inside a ReportSwitcherProvider')
  return context
}
const getBedsBathsGarageMinMaxAvg = (status: CMA.CompsStats) => {
  const {
    bedroomsCount,
    bathroomsFullCount,
    bathroomsHalfCount,
    garageSpacesCount,
  } = status

  const formatNumber = NumberLib.ifElse(
    createPipe(
      Math.round,
      NumberLib.formatCommasOptionalDecimalsOrDoubleDash,
    ))(
    () => '--',
  )

  const bedMinStr = formatNumber(bedroomsCount?.min)
  const bathMinStr = (!bathroomsFullCount || !isNumber(bathroomsFullCount.min))
    && (!bathroomsHalfCount || !isNumber(bathroomsHalfCount.min))
    ? '--'
    : BathroomsCountInfo.getTotal({
      full: NumberLib.ensureOrNull(bathroomsFullCount?.min),
      half: NumberLib.ensureOrNull(status.bathroomsHalfCount?.min),
    }) || '--'
  const garageMinStr = formatNumber(garageSpacesCount?.min)
  const min = `${bedMinStr} / ${bathMinStr} / ${garageMinStr}`

  const bedMaxStr = formatNumber(bedroomsCount?.max)
  const bathMaxStr = (!bathroomsFullCount || !isNumber(bathroomsFullCount.max))
    && (!bathroomsHalfCount || !isNumber(bathroomsHalfCount.max))
    ? '--'
    : BathroomsCountInfo.getTotal({
      full: NumberLib.ensureOrNull(bathroomsFullCount?.max),
      half: NumberLib.ensureOrNull(status.bathroomsHalfCount?.max),
    }) || '--'
  const garageMaxStr = formatNumber(garageSpacesCount?.max)
  const max = `${bedMaxStr} / ${bathMaxStr} / ${garageMaxStr}`

  const bedAvgStr = formatNumber(bedroomsCount?.avg)
  const bathAvgStr = (!bathroomsFullCount || !isNumber(bathroomsFullCount.avg))
    && (!bathroomsHalfCount || !isNumber(bathroomsHalfCount.avg))
    ? '--'
    : BathroomsCountInfo.getTotal({
      full: NumberLib.ensureOrNull(bathroomsFullCount?.avg),
      half: NumberLib.ensureOrNull(status.bathroomsHalfCount?.avg),
    }) || '--'
  const garageAvgStr = formatNumber(garageSpacesCount?.avg)
  const avg = `${bedAvgStr} / ${bathAvgStr} / ${garageAvgStr}`

  return { min, max, avg } as const
}

const getDistanceMilesMinMaxAvg = (stats: CMA.CompsStats) => {
  const min = stats.distanceMiles?.min
    ? [
      NumberLib.formatCommaOptionalDecimal(stats.distanceMiles.min),
      pluralize('mile', stats.distanceMiles.min),
    ].join(' ')
    : '--'
  const max = stats.distanceMiles?.max
    ? [
      NumberLib.formatCommaOptionalDecimal(stats.distanceMiles.max),
      pluralize('mile', stats.distanceMiles.max),
    ].join(' ')
    : '--'
  const avg = stats.distanceMiles?.avg
    ? [
      NumberLib.formatCommaOptionalDecimal(stats.distanceMiles.avg),
      pluralize('mile', stats.distanceMiles.avg),
    ].join(' ')
    : '--'
  return { min, max, avg } as const
}

export const getFilterValuesForStatus = (filter: ReportFilterValues) =>
  pipe(
    Match.value(filter),
    Match.when('SOLD', () => ['SOLD']),
    Match.when('LEASED', () => ['LEASED']),
    Match.when('PENDING', () => ['PENDING']),
    Match.when('ACTIVE', () => ['SALE_OR_LEASE', 'FOR_SALE', 'FOR_LEASE']),
    Match.when('OFF_MARKET', () => ['OFF_MARKET', 'CANCELED', 'EXPIRED', 'WITHDRAWN']),
    Match.exhaustive,
  )

export const createSummaryStatistics = (
  comps: CMA.SingleComp[],
  options: CalculateCompsStatsOptions = DEFAULT_CALCULATE_COMPS_STATS_OPTIONS,
) => {
  const stats = calculateCompsStats(comps, options) ?? createEmptyCompsStats()
  const summaryStatistics: SummaryStatistics = {
    isSubject: null,
    bedsBathsGarage: getBedsBathsGarageMinMaxAvg(stats),
    yearBuilt: {
      min: NumberLib.roundOrDoubleDash(stats.yearBuilt?.min).toString(),
      max: NumberLib.roundOrDoubleDash(stats.yearBuilt?.max).toString(),
      avg: NumberLib.roundOrDoubleDash(stats.yearBuilt?.avg).toString(),
    },
    livingAreaSqft: {
      min: NumberLib.formatNumberCoDDRounded(stats.livingAreaSqft?.min),
      max: NumberLib.formatNumberCoDDRounded(stats.livingAreaSqft?.max),
      avg: NumberLib.formatNumberCoDDRounded(stats.livingAreaSqft?.avg),
    },
    lotAreaAcres: {
      min: NumberLib.formatCODoDD(stats.lotAreaAcres?.min),
      max: NumberLib.formatCODoDD(stats.lotAreaAcres?.max),
      avg: NumberLib.formatCODoDD(stats.lotAreaAcres?.avg),
    },
    listPrice: {
      min: Dollar.formatNumberCoDDRounded(stats.listPrice?.min),
      max: Dollar.formatNumberCoDDRounded(stats.listPrice?.max),
      avg: Dollar.formatNumberCoDDRounded(stats.listPrice?.avg),
    },
    listPricePerSqft: {
      min: Dollar.formatNumberCDoDD(stats.listPricePerSqft?.min),
      max: Dollar.formatNumberCDoDD(stats.listPricePerSqft?.max),
      avg: Dollar.formatNumberCDoDD(stats.listPricePerSqft?.avg),
    },
    salePrice: {
      min: Dollar.formatNumberCoDDRounded(stats.salePrice?.min),
      max: Dollar.formatNumberCoDDRounded(stats.salePrice?.max),
      avg: Dollar.formatNumberCoDDRounded(stats.salePrice?.avg),
    },
    salePricePerSqft: {
      min: Dollar.formatNumberCDoDD(stats.salePricePerSqft?.min),
      max: Dollar.formatNumberCDoDD(stats.salePricePerSqft?.max),
      avg: Dollar.formatNumberCDoDD(stats.salePricePerSqft?.avg),
    },
    distance: getDistanceMilesMinMaxAvg(stats),
    daysOnMarket: {
      min: NumberLib.formatNumberCoDDRounded(stats.daysOnMarket?.min),
      max: NumberLib.formatNumberCoDDRounded(stats.daysOnMarket?.max),
      avg: NumberLib.formatNumberCoDDRounded(stats.daysOnMarket?.avg),
    },
  }
  return summaryStatistics
}
