import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl'
import { useMapStore } from 'presentation/components/Map'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { COMPS_MAP_MAPBOX_ID } from 'presentation/screens/CompsScreen/components/CompsMap/CompsMap.const'
import { HAS_MOUSE_SUPPORT } from 'presentation/utils/hasMouseSupport'
import { IS_TOUCH_DEVICE } from 'presentation/utils/isTouchDevice'
import { useEffect, useRef } from 'react'
import { throttle } from 'throttle-debounce'

export const useIntegrateCompsBoundaryToMap = () => {
  const mapRef = useMapStore(state => state.computed.getMapRef())
  const boundaryStatus = CompsBoundary.useStore(state => state.status)
  const isMouseDownRef = useRef(false)
  const boundaryStatusRef = useRef(boundaryStatus)

  boundaryStatusRef.current = boundaryStatus

  useDisableScrollOnTouchDeviceWhenDrawing()

  useEffect(() => {
    if (!mapRef) return

    const map = mapRef.getMap()

    const handleMouseDown = () => {
      if (boundaryStatusRef.current !== 'drawing') return
      isMouseDownRef.current = true
      CompsBoundary.startLine()
    }

    const handleMouseUp = () => {
      if (boundaryStatusRef.current !== 'drawing') return
      isMouseDownRef.current = false
      CompsBoundary.endLine()
    }

    const handleMouseMoveThrottled = throttle(
      100,
      (ev: MapTouchEvent | MapMouseEvent) => {
        if (boundaryStatusRef.current !== 'drawing') return
        if (!isMouseDownRef.current) return

        CompsBoundary.extendLine({
          latitude: ev.lngLat.lat,
          longitude: ev.lngLat.lng,
        })
      })

    if (IS_TOUCH_DEVICE) {
      map.on('touchstart', handleMouseDown)
      map.on('touchend', handleMouseUp)
      map.on('touchmove', handleMouseMoveThrottled)

      return () => {
        map.off('touchstart', handleMouseDown)
        map.off('touchend', handleMouseUp)
        map.off('touchmove', handleMouseMoveThrottled)
      }
    }

    if (HAS_MOUSE_SUPPORT) {
      map.on('mousedown', handleMouseDown)
      map.on('mouseup', handleMouseUp)
      map.on('mousemove', handleMouseMoveThrottled)

      return () => {
        map.off('mousedown', handleMouseDown)
        map.off('mouseup', handleMouseUp)
        map.off('mousemove', handleMouseMoveThrottled)
      }
    }
  }, [
    mapRef,
    boundaryStatus,
  ])
}

const applyDisableScrollStyle = () => {
  document.body.style.overflow = 'hidden'
}

const removeDisableScrollStyle = () => {
  document.body.style.overflow = ''
}
const useDisableScrollOnTouchDeviceWhenDrawing = () => {
  const boundaryStatus = CompsBoundary.useStore(state => state.status)

  useEffect(() => {
    if (boundaryStatus !== 'drawing' || !IS_TOUCH_DEVICE) return

    const handleTouchStart = (event: TouchEvent) => {
      if (event.target && !isDescendant(COMPS_MAP_MAPBOX_ID, event.target as HTMLElement))
        event.preventDefault()
    }

    const handleMouseDown = (event: MouseEvent) => {
      if (event.target && !isDescendant(COMPS_MAP_MAPBOX_ID, event.target as HTMLElement))
        event.preventDefault()
    }

    // Disable scroll
    applyDisableScrollStyle()
    document.addEventListener('touchstart', handleTouchStart, { passive: false })
    document.addEventListener('mousedown', handleMouseDown, { passive: false })

    return () => {
      // Enable scroll on cleanup
      removeDisableScrollStyle()
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])
}

const isDescendant = (parentId: string, childElement: HTMLElement): boolean => {
  const parentElement = document.getElementById(parentId)
  if (!parentElement)
    return false

  return parentElement.contains(childElement)
}
