import { CardButton, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { typeToConfirmModalAPI } from 'presentation/main/globalModals/TypeToConfirmModal/TypeToConfirmModal.api'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { InitialMemberDeactivateModalTransition, MemberDeactivateModalViewModel } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberDeactivateModal.viewModel'
import { MemberTable } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberTable'
import { mbp } from 'presentation/utils/mapBreakpoint'

const open = (
  viewModel: Omit<InitialMemberDeactivateModalTransition & MemberDeactivateModalViewModel, 'status'>,
): void =>
  typeToConfirmModalAPI.open({
    title: 'Team Members',
    wordToType: 'DEACTIVATE',
    appendToLabel: 'to complete user deactivation',
    header: (
      <>
        <CardPrimaryTitle shouldResetSpacing>
          Deactivate Team Member
        </CardPrimaryTitle>
        <CardPrimaryText>
          By deactivating this team member, all of their access will be revoked,
          and they will no longer be able to log in or access any of your team&rsquo;s resources.
        </CardPrimaryText>
      </>
    ),
    body: (
      <MemberTable
        mt={mbp({ mobSm: 2, mob: 3 })}
        w='full'
        member={viewModel.member}
        isOwner={viewModel.isOwner}
      />
    ),
    footer: ({ isValid, onClose, isLoading, isDisabled }) => (
      <>
        <CardButton
          variant='outline'
          colorScheme='neutral'
          onClick={onClose}
          isDisabled={isDisabled || isLoading}
          loadingText='Canceling'
        >
          Cancel
        </CardButton>
        <CardButton
          variant='solid'
          colorScheme='negative'
          onClick={viewModel.onDisable}
          isDisabled={!isValid || isDisabled}
          isLoading={isLoading}
        >
          Deactivate
        </CardButton>
      </>
    ),
  })

const transitionToInitial = (): void =>
  typeToConfirmModalAPI.update({
    isLoading: false,
  })

const transitionToLoading = (): void =>
  typeToConfirmModalAPI.update({
    isLoading: true,
  })

const close = (): void => goBackModal()

export const MemberDeactivateModalAPI = {
  open,
  transitionToLoading,
  transitionToInitial,
  close,
}
