import 'animate.css'

export const bounce = 'animate__animated animate__bounce'
export const flash = 'animate__animated animate__flash'
export const pulse = 'animate__animated animate__pulse'
export const rubberBand = 'animate__animated animate__rubberBand'
export const shakeX = 'animate__animated animate__shakeX'
export const shakeY = 'animate__animated animate__shakeY'
export const headShake = 'animate__animated animate__headShake'
export const swing = 'animate__animated animate__swing'
export const tada = 'animate__animated animate__tada'
export const wobble = 'animate__animated animate__wobble'
export const jello = 'animate__animated animate__jello'
export const heartBeat = 'animate__animated animate__heartBeat'

export const backInDown = 'animate__animated animate__backInDown'
export const backInLeft = 'animate__animated animate__backInLeft'
export const backInRight = 'animate__animated animate__backInRight'
export const backInUp = 'animate__animated animate__backInUp'
export const backOutDown = 'animate__animated animate__backOutDown'
export const backOutLeft = 'animate__animated animate__backOutLeft'
export const backOutRight = 'animate__animated animate__backOutRight'
export const backOutUp = 'animate__animated animate__backOutUp'

export const bounceIn = 'animate__animated animate__bounceIn'
export const bounceInDown = 'animate__animated animate__bounceInDown'
export const bounceInLeft = 'animate__animated animate__bounceInLeft'
export const bounceInRight = 'animate__animated animate__bounceInRight'
export const bounceInUp = 'animate__animated animate__bounceInUp'
export const bounceOut = 'animate__animated animate__bounceOut'
export const bounceOutDown = 'animate__animated animate__bounceOutDown'
export const bounceOutLeft = 'animate__animated animate__bounceOutLeft'
export const bounceOutRight = 'animate__animated animate__bounceOutRight'
export const bounceOutUp = 'animate__animated animate__bounceOutUp'

export const fadeIn = 'animate__animated animate__fadeIn'
export const fadeInDown = 'animate__animated animate__fadeInDown'
export const fadeInDownBig = 'animate__animated animate__fadeInDownBig'
export const fadeInLeft = 'animate__animated animate__fadeInLeft'
export const fadeInLeftBig = 'animate__animated animate__fadeInLeftBig'
export const fadeInRight = 'animate__animated animate__fadeInRight'
export const fadeInRightBig = 'animate__animated animate__fadeInRightBig'
export const fadeInUp = 'animate__animated animate__fadeInUp'
export const fadeInUpBig = 'animate__animated animate__fadeInUpBig'
export const fadeInTopLeft = 'animate__animated animate__fadeInTopLeft'
export const fadeInTopRight = 'animate__animated animate__fadeInTopRight'
export const fadeInBottomLeft = 'animate__animated animate__fadeInBottomLeft'
export const fadeInBottomRight = 'animate__animated animate__fadeInBottomRight'

export const fadeOut = 'animate__animated animate__fadeOut'
export const fadeOutDown = 'animate__animated animate__fadeOutDown'
export const fadeOutDownBig = 'animate__animated animate__fadeOutDownBig'
export const fadeOutLeft = 'animate__animated animate__fadeOutLeft'
export const fadeOutLeftBig = 'animate__animated animate__fadeOutLeftBig'
export const fadeOutRight = 'animate__animated animate__fadeOutRight'
export const fadeOutRightBig = 'animate__animated animate__fadeOutRightBig'
export const fadeOutUp = 'animate__animated animate__fadeOutUp'
export const fadeOutUpBig = 'animate__animated animate__fadeOutUpBig'
export const fadeOutTopLeft = 'animate__animated animate__fadeOutTopLeft'
export const fadeOutTopRight = 'animate__animated animate__fadeOutTopRight'
export const fadeOutBottomRight = 'animate__animated animate__fadeOutBottomRight'
export const fadeOutBottomLeft = 'animate__animated animate__fadeOutBottomLeft'

export const flip = 'animate__animated animate__flip'
export const flipInX = 'animate__animated animate__flipInX'
export const flipInY = 'animate__animated animate__flipInY'
export const flipOutX = 'animate__animated animate__flipOutX'
export const flipOutY = 'animate__animated animate__flipOutY'

export const lightSpeedInRight = 'animate__animated animate__lightSpeedInRight'
export const lightSpeedInLeft = 'animate__animated animate__lightSpeedInLeft'
export const lightSpeedOutRight = 'animate__animated animate__lightSpeedOutRight'
export const lightSpeedOutLeft = 'animate__animated animate__lightSpeedOutLeft'

export const rotateIn = 'animate__animated animate__rotateIn'
export const rotateInDownLeft = 'animate__animated animate__rotateInDownLeft'
export const rotateInDownRight = 'animate__animated animate__rotateInDownRight'
export const rotateInUpLeft = 'animate__animated animate__rotateInUpLeft'
export const rotateInUpRight = 'animate__animated animate__rotateInUpRight'
export const rotateOut = 'animate__animated animate__rotateOut'
export const rotateOutDownLeft = 'animate__animated animate__rotateOutDownLeft'
export const rotateOutDownRight = 'animate__animated animate__rotateOutDownRight'
export const rotateOutUpLeft = 'animate__animated animate__rotateOutUpLeft'
export const rotateOutUpRight = 'animate__animated animate__rotateOutUpRight'

export const hinge = 'animate__animated animate__hinge'
export const jackInTheBox = 'animate__animated animate__jackInTheBox'
export const rollIn = 'animate__animated animate__rollIn'
export const rollOut = 'animate__animated animate__rollOut'

export const zoomIn = 'animate__animated animate__zoomIn'
export const zoomInDown = 'animate__animated animate__zoomInDown'
export const zoomInLeft = 'animate__animated animate__zoomInLeft'
export const zoomInRight = 'animate__animated animate__zoomInRight'
export const zoomInUp = 'animate__animated animate__zoomInUp'
export const zoomOut = 'animate__animated animate__zoomOut'
export const zoomOutDown = 'animate__animated animate__zoomOutDown'
export const zoomOutLeft = 'animate__animated animate__zoomOutLeft'
export const zoomOutRight = 'animate__animated animate__zoomOutRight'
export const zoomOutUp = 'animate__animated animate__zoomOutUp'

export const slideInDown = 'animate__animated animate__slideInDown'
export const slideInLeft = 'animate__animated animate__slideInLeft'
export const slideInRight = 'animate__animated animate__slideInRight'
export const slideInUp = 'animate__animated animate__slideInUp'
export const slideOutDown = 'animate__animated animate__slideOutDown'
export const slideOutLeft = 'animate__animated animate__slideOutLeft'
export const slideOutRight = 'animate__animated animate__slideOutRight'
export const slideOutUp = 'animate__animated animate__slideOutUp'

export const delay1s = 'animate__delay-1s'
export const delay2s = 'animate__delay-2s'
export const delay3s = 'animate__delay-3s'
export const delay4s = 'animate__delay-4s'
export const delay5s = 'animate__delay-5s'

export const fast = 'animate__fast'
/** 500ms */
export const faster = 'animate__faster'
export const slow = 'animate__slow'
export const slower = 'animate__slower'

export const animationClasses = {
  bounce,
  flash,
  pulse,
  rubberBand,
  shakeX,
  shakeY,
  headShake,
  swing,
  tada,
  wobble,
  jello,
  heartBeat,
  backInDown,
  backInLeft,
  backInRight,
  backInUp,
  backOutDown,
  backOutLeft,
  backOutRight,
  backOutUp,
  bounceIn,
  bounceInDown,
  bounceInLeft,
  bounceInRight,
  bounceInUp,
  bounceOut,
  bounceOutDown,
  bounceOutLeft,
  bounceOutRight,
  bounceOutUp,
  fadeIn,
  fadeInDown,
  fadeInDownBig,
  fadeInLeft,
  fadeInLeftBig,
  fadeInRight,
  fadeInRightBig,
  fadeInUp,
  fadeInUpBig,
  fadeInTopLeft,
  fadeInTopRight,
  fadeInBottomLeft,
  fadeInBottomRight,
  fadeOut,
  fadeOutDown,
  fadeOutDownBig,
  fadeOutLeft,
  fadeOutLeftBig,
  fadeOutRight,
  fadeOutRightBig,
  fadeOutUp,
  fadeOutUpBig,
  fadeOutTopLeft,
  fadeOutTopRight,
  fadeOutBottomRight,
  fadeOutBottomLeft,
  flip,
  flipInX,
  flipInY,
  flipOutX,
  flipOutY,
  lightSpeedInRight,
  lightSpeedInLeft,
  lightSpeedOutRight,
  lightSpeedOutLeft,
  rotateIn,
  rotateInDownLeft,
  rotateInDownRight,
  rotateInUpLeft,
  rotateInUpRight,
  rotateOut,
  rotateOutDownLeft,
  rotateOutDownRight,
  rotateOutUpLeft,
  rotateOutUpRight,
  hinge,
  jackInTheBox,
  rollIn,
  rollOut,
  zoomIn,
  zoomInDown,
  zoomInLeft,
  zoomInRight,
  zoomInUp,
  zoomOut,
  zoomOutDown,
  zoomOutLeft,
  zoomOutRight,
  zoomOutUp,
  slideInDown,
  slideInLeft,
  slideInRight,
  slideInUp,
  slideOutDown,
  slideOutLeft,
  slideOutRight,
  slideOutUp,

  fast,
  faster,
  slow,
  slower,

  delay1s,
  delay2s,
  delay3s,
  delay4s,
  delay5s,
}

export type AnimationClass = keyof typeof animationClasses
