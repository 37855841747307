import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Chip, ChipProps } from 'presentation/components/Chip/Chip'

export const SubjectAPNPill = ({
  transformText = text => `APN # ${text}`,
  ...props
}: ChipProps & {
  transformText?: (text: string) => string
}) => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const apn = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property || property.status === 'without-details' || !property.land) return null

    return property.land.assessorParcelNumber || null
  })

  if (!apn) return null

  return (
    <Chip
      colorScheme='positive'
      {...props}
    >
      {transformText(apn)}
    </Chip>
  )
}
