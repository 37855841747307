import { pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { Location } from 'features/valueObjects/Location'
import { NumberLib } from 'libs/Number'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { CompsMapAndListSync } from 'presentation/screens/CompsScreen/CompsMapAndListSync'
import { openCMASalesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useEffect } from 'react'
import { filter, groupBy, isNumber, map, toPairs } from 'remeda'

export const useInitializeLoadReportByRouteId = () => {
  const id = useLeadId()

  const initializeLoadReport = useCMAStore(state => state.actions.initializeLoadReport.execute)

  useEffect(() => {
    if (!id) return
    void initializeLoadReport(id)
  }, [id])
}

export const useResetTabOnUnmount = () => {
  useEffect(() => () => openCMASalesTab(), [])
}

export const useResetHighlightedEntryOnUnmount = () => {
  useEffect(() => () => {
    CompsMapAndListSync.setHighlightedEntry(null)
  }, [])
}

export const groupSinglePointComps = (
  comps: (CMA.SubjectComp | CMA.SingleComp)[],
): CMA.Comp[] =>
  pipe(
    comps,
    filter(Location.hasLocation),
    groupBy(comp => `${comp.location.latitude}|${comp.location.longitude}`),
    toPairs,
    map(([, comps]) => {
      if (comps.length === 1) return comps[0]
      return {
        type: 'single-point-comps',
        location: comps[0].location,
        comps,
      } satisfies CMA.SinglePointComps
    }),
  )

type StatKey = keyof CMA.CompsStats

type AttributeHelper = {
  statKey: StatKey
  getFromComp: (comp: CMA.SingleComp) => number | null
}

const ATTRIBUTE_HELPERS: AttributeHelper[] = [
  {
    statKey: 'listPrice',
    getFromComp: comp => comp.listPrice,
  },
  {
    statKey: 'listPricePerSqft',
    getFromComp: comp => comp.listPricePerSqft,
  },
  {
    statKey: 'salePrice',
    getFromComp: comp => comp.salePrice,
  },
  {
    statKey: 'salePricePerSqft',
    getFromComp: comp => comp.salePricePerSqft,
  },
  {
    statKey: 'distanceMiles',
    getFromComp: comp => comp.distance,
  },
  {
    statKey: 'daysOnMarket',
    getFromComp: comp => comp.daysOnMarket,
  },
  {
    statKey: 'bedroomsCount',
    getFromComp: comp => comp.bedroomsCount,
  },
  {
    statKey: 'bathroomsFullCount',
    getFromComp: (comp): number | null => NumberLib.ensureOrNull(comp.bathroomsCountInfo?.full),
  },
  {
    statKey: 'bathroomsHalfCount',
    getFromComp: (comp): number | null => NumberLib.ensureOrNull(comp.bathroomsCountInfo?.half),
  },
  {
    statKey: 'garageSpacesCount',
    getFromComp: comp => comp.garageSpacesCount,
  },
  {
    statKey: 'livingAreaSqft',
    getFromComp: comp => comp.livingAreaSqft,
  },
  {
    statKey: 'lotAreaSqft',
    getFromComp: comp => comp.lotAreaSqft,
  },
  {
    statKey: 'lotAreaAcres',
    getFromComp: comp => comp.lotAreaAcres,
  },
  {
    statKey: 'yearBuilt',
    getFromComp: comp => comp.yearBuilt,
  },
  {
    statKey: 'price',
    getFromComp: comp => comp.salePrice ?? comp.listPrice,
  },
  {
    statKey: 'pricePerSqft',
    getFromComp: comp => comp.salePricePerSqft ?? comp.listPricePerSqft,
  },
]

export const calculateCompsStats = (
  comps: CMA.SingleComp[],
  options: CalculateCompsStatsOptions = DEFAULT_CALCULATE_COMPS_STATS_OPTIONS,
): CMA.CompsStats | null => {
  if (comps.length === 0) return null

  const isNoCompIncluded = options.shouldBaseOnIncludedOnly && comps.every(comp => comp.userRating !== 'included')

  if (isNoCompIncluded) return null

  const stats: CMA.CompsStats = createEmptyCompsStats()

  comps.forEach(comp => {
    ATTRIBUTE_HELPERS.forEach(helper => {
      if (options.shouldBaseOnIncludedOnly && comp.userRating !== 'included') return

      const value = helper.getFromComp(comp)

      if (value === null) return

      const metrics = stats[helper.statKey]

      if (!metrics) {
        stats[helper.statKey] = {
          min: value,
          max: value,
          sum: value,
          count: 1,
          avg: value,
        }
      } else {
        metrics.min = Math.min(metrics.min, value)
        metrics.max = Math.max(metrics.max, value)
        metrics.sum += value
        metrics.count++
        metrics.avg = metrics.sum / metrics.count
      }
    })
  })

  return stats
}

export type FullEstimateInfo = {
  fromAvgPrice: number | null
  fromAvgPricePerSqft: number | null
  preferred: {
    method: CMA.EstimateMethod
    value: number | null
  }
}

export type CalculateCompsStatsOptions = {
  shouldBaseOnIncludedOnly?: boolean
}

export const DEFAULT_CALCULATE_COMPS_STATS_OPTIONS = {
  shouldBaseOnIncludedOnly: true,
} satisfies CalculateCompsStatsOptions

/**
 * @TODO We should discuss on how to deal scenarios that ends up with null data
 *   e.g. when subject property has no sqft, etc.
 */
export const calculateFullEstimateInfo = (
  listInfo: CMA.ListInfo,
  overrideSqft: number | null,
  options: CalculateCompsStatsOptions = DEFAULT_CALCULATE_COMPS_STATS_OPTIONS,
): FullEstimateInfo | null => {
  const estimatedInfo = listInfo.estimateInfo
  const stats = calculateCompsStats(listInfo.comps, options)
  if (!stats) return null

  const sqft = overrideSqft ?? listInfo.subject.livingAreaSqft
  const avgPerSqft = stats.pricePerSqft?.avg ?? null

  const fromAvgPrice = stats.price?.avg ?? null
  const fromAvgPricePerSqft = isNumber(sqft) && isNumber(avgPerSqft)
    ? sqft * avgPerSqft
    : null

  return {
    fromAvgPrice,
    fromAvgPricePerSqft,
    preferred: estimatedInfo.method === 'override'
      ? {
        method: estimatedInfo.method,
        value: estimatedInfo.overrideValue,
      }
      : estimatedInfo.method === 'from-avg-per-sqft'
        ? {
          method: estimatedInfo.method,
          value: fromAvgPricePerSqft,
        }
        : {
          method: estimatedInfo.method,
          value: fromAvgPrice,
        },
  }
}

export const createEmptyCompsStats = (): CMA.CompsStats => ({
  listPrice: null,
  listPricePerSqft: null,
  salePrice: null,
  salePricePerSqft: null,
  pricePerSqft: null,
  price: null,
  distanceMiles: null,
  daysOnMarket: null,
  bedroomsCount: null,
  bathroomsFullCount: null,
  bathroomsHalfCount: null,
  garageSpacesCount: null,
  buildingAreaSqft: null,
  livingAreaSqft: null,
  lotAreaSqft: null,
  lotAreaAcres: null,
  yearBuilt: null,
})
