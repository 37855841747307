import { pipe, Record } from 'effect'
import { px } from 'presentation/utils/px'

/**
 * @NOTE Values are sorted from lowest to highest breakpoint
 * @NOTE This is in array form instead of object to preserve keys order
 */
export const BREAKPOINT_ENTRIES_ORDERED = [
  ['mobSm', 0], // 0th index in Chakra API array, previously 0th or 'mob'
  ['mob', 480], // 1st index in Chakra API array
  ['tabSm', 768], // 2nd index in Chakra API array, previously 1st or 'tab'
  ['tab', 900], // 3th index in Chakra API array
  ['dtSm', 1024], // 4th index in Chakra API array
  ['dt', 1280], // 5th index in Chakra API array, previously 2nd or 'dt'
  ['dtLg', 1440], // 6th index in Chakra API array
] as const

export type Breakpoint = typeof BREAKPOINT_ENTRIES_ORDERED[number][0]

/** @NOTE Values are sorted from lowest to highest breakpoint */
export const BREAKPOINTS: Breakpoint[] = BREAKPOINT_ENTRIES_ORDERED.map(([key]) => key)

export const BREAKPOINT_TO_WIDTH: Record<Breakpoint, number> = Record.fromEntries(BREAKPOINT_ENTRIES_ORDERED)

export const breakpointTheme = pipe(
  BREAKPOINT_ENTRIES_ORDERED,
  Record.fromEntries,
  Record.filter(width => width > 0), // Remove mobSm for theme to work (not sure why needed yet)
  Record.map(px),
)
