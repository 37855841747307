import { Button, ComponentWithAs, FlexProps, Icon, Text, TextProps, useMultiStyleConfig } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import { ConfigurableChakraFlex } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraFlex'
import { ConfigurableChakraText } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraText'
import { ChevronLeftIcon, ChevronRightIcon } from 'presentation/components/Icons'
import { NoticeColorScheme } from 'presentation/components/Notice/Notice.theme'
import { selectNoticeCount, useNoticeStore } from 'presentation/main/NoticeCarousel'
import { fadeIn } from 'presentation/utils/animationClasses'
import { FC, PropsWithChildren, createContext, useContext } from 'react'
import { useSwiper } from 'swiper/react'

type CardComboContextValue = ReturnType<typeof useMultiStyleConfig>
const CardComboContext = createContext<CardComboContextValue>({})

export type NoticeProps = FlexProps & { colorScheme: NoticeColorScheme }
export const Notice: FC<NoticeProps> = props => {
  const styles = useMultiStyleConfig('Notice', props)

  const { colorScheme, ...rest } = props

  return (
    <CardComboContext.Provider value={styles}>
      <ConfigurableChakraFlex __css={styles.container} className={fadeIn} {...rest} />
    </CardComboContext.Provider>
  )
}

export const NoticeIcon: ComponentWithAs<typeof Icon> = props => {
  const styles = useContext(CardComboContext)

  return <Icon __css={styles.icon} {...props} />
}

export const NoticeText: FC<TextProps> = props => {
  const styles = useContext(CardComboContext)

  return <ConfigurableChakraText as='span' __css={styles.text} {...props} />
}

export const NoticeSecondaryText: FC<TextProps> = props => {
  const styles = useContext(CardComboContext)

  return <ConfigurableChakraText as='p' __css={styles.secondaryText} {...props} />
}

export const NoticeActions: FC<PropsWithChildren> = props => {
  const styles = useContext(CardComboContext)

  return <ConfigurableChakraFlex __css={styles.actions} {...props} />
}

export const NoticeEmphasis: ComponentWithAs<typeof Text> = props => {
  const styles = useContext(CardComboContext)

  return <ConfigurableChakraText as='span' __css={styles.emphasis} {...props} />
}

export const NoticeButton: ComponentWithAs<typeof Button> = props => (
  <Button size='xs' {...props} />
)

export const NoticeNav: FC = () => {
  const styles = useContext(CardComboContext)
  const activeIndex = useNoticeStore(state => state.activeIndex)
  const noticeCount = useNoticeStore(selectNoticeCount)
  const swiper = useSwiper()

  if (noticeCount <= 1) return null

  return (
    <ConfigurableChakraFlex __css={styles.navContainer}>
      <chakra.button onClick={() => swiper.slidePrev()} __css={styles.navButton}><ChevronLeftIcon /></chakra.button>
      <chakra.span __css={styles.navText}>
        {activeIndex + 1}
        /
        {noticeCount}
      </chakra.span>
      <chakra.button onClick={() => swiper.slideNext()} __css={styles.navButton}><ChevronRightIcon /></chakra.button>
    </ConfigurableChakraFlex>
  )
}
