import { Data, Option } from 'effect'
import ProductFeatureState from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureState'
import ProductId from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductId'
import ProductName from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductName'
import SubscriptionPlan from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'

type SubscriptionProductActive = {
  readonly id: ProductId
  readonly name: ProductName
  readonly plans: SubscriptionPlan
  readonly description: string
  readonly features: ProductFeatureState[]
  readonly currentPeriod: {
    readonly end: Option.Option<Date>
  }
}

const SubscriptionProductActive = {
  make: Data.case<SubscriptionProductActive>(),
}

export default SubscriptionProductActive
