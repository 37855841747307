import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { selectDidExceedMaxResults } from 'features/CMA/infra/react/CMAState.selectors'
import { TriangleExclamationIcon } from 'presentation/components/Icons'
import { NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'

export const useCompsExceededMaxResultsNotice = () => {
  const { selectedListType: activeTab } = useActiveEntriesTab()

  const didExceedMaxResults = useCMAStore(selectDidExceedMaxResults(activeTab))

  const updateNotice = useNoticeStore(store => store.updateNotice)

  const isOnCompsRoute = useMatch('/search/:leadId/comps')

  useEffect(() => {
    updateNotice(
      'COMPS_EXCEEDED_MAX_RESULTS',
      didExceedMaxResults && isOnCompsRoute
        ? (
          <ResponsiveNotice
            colorScheme='error'
            icon={TriangleExclamationIcon}
            textContent={(
              <>
                <NoticeEmphasis>Results exceed 100 properties.</NoticeEmphasis>
                {' '}
                We have limited the properties displayed to 100 results;
                {' '}
                modify your filters to reduce the number of matching properties.
              </>
            )}
            actionsContent={null}
          />
        )
        : null)

    return () => {
      updateNotice('COMPS_EXCEEDED_MAX_RESULTS', null)
    }
  }, [didExceedMaxResults, isOnCompsRoute])
}
