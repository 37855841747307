import { ResponsiveFullscreenModal } from 'presentation/components/ResponsiveFullscreenModal'
import { UserInfoCard } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoCard'
import { UserInfoViewModel } from 'presentation/screens/MembersScreen/components/UserInfoModal/UserInfoModal.viewModel'
import { FC } from 'react'

export type UserInfoModalProps = UserInfoViewModel & {
  isOpen: boolean
  onClose: () => void
}

export const UserInfoModal: FC<UserInfoModalProps> = ({ isOpen, onClose, ...props }) => (
  <ResponsiveFullscreenModal
    title="Edit Team Member's Info"
    isOpen={isOpen}
    onClose={onClose}
  >
    <UserInfoCard {...props} />
  </ResponsiveFullscreenModal>
)
