import { createIcon } from '@chakra-ui/react'

export const CalenderIcon = createIcon({
  displayName: 'CalenderIcon',
  viewBox: '0 0 32 33',
  path: (
    <g fill='none'>
      <path d='M25.3333 5.8335H6.66667C5.19391 5.8335 4 7.0274 4 8.50016V27.1668C4 28.6396 5.19391 29.8335 6.66667 29.8335H25.3333C26.8061 29.8335 28 28.6396 28 27.1668V8.50016C28 7.0274 26.8061 5.8335 25.3333 5.8335Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M21.332 3.1665V8.49984' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.668 3.1665V8.49984' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M4 13.8335H28' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
