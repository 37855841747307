import { createIcon } from '@chakra-ui/react'

export const WebsiteIcon = createIcon({
  displayName: 'WebsiteIcon',
  viewBox: '0 0 32 32',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M15.9993 29.3332C23.3631 29.3332 29.3327 23.3636 29.3327 15.9998C29.3327 8.63604 23.3631 2.6665 15.9993 2.6665C8.63555 2.6665 2.66602 8.63604 2.66602 15.9998C2.66602 23.3636 8.63555 29.3332 15.9993 29.3332Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.66602 16H29.3327' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15.9993 2.6665C19.3344 6.31764 21.2297 11.0559 21.3327 15.9998C21.2297 20.9438 19.3344 25.682 15.9993 29.3332C12.6643 25.682 10.769 20.9438 10.666 15.9998C10.769 11.0559 12.6643 6.31764 15.9993 2.6665V2.6665Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
