import { Match, pipe } from 'effect'
import { MLSStatus } from 'features/valueObjects/MLSStatus'

export type CompCardColors = {
  cardTextColor: string
  cardBgColor: string
  cardLowerBgColor: string
  cardBorderColor: string
  cardHeaderBgColor: string
  cardHeaderTextColor: string
  statTableLabelColor: string
  statTableValueColor: string
  statTableBgColor: string
}

export const getCompCardColors = (
  status: MLSStatus,
) => pipe(
  Match.value(status),
  Match.when('FOR_SALE', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'positive.50',
    statTableLabelColor: 'positive.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'positive.500',
    cardHeaderBgColor: 'accent.xxdarkgreen',
    statTableBgColor: 'positive.100',
  } satisfies CompCardColors)),
  Match.when('FOR_LEASE', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'positive.50',
    statTableLabelColor: 'positive.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'positive.500',
    cardHeaderBgColor: 'accent.xxdarkgreen',
    statTableBgColor: 'positive.100',
  } satisfies CompCardColors)),
  Match.when('SALE_OR_LEASE', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'positive.50',
    statTableLabelColor: 'positive.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'marker.mintgreen',
    cardBorderColor: 'positive.500',
    cardHeaderBgColor: 'accent.xxdarkgreen',
    statTableBgColor: 'positive.100',
  } satisfies CompCardColors)),
  Match.when('PENDING', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'neutral.100',
    statTableLabelColor: 'neutral.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'marker.babyblue',
    cardBorderColor: 'neutral.400',
    cardHeaderBgColor: 'accent.xdarkblue',
    statTableBgColor: 'neutral.100',
  } satisfies CompCardColors)),
  Match.when('SOLD', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'marker.pink',
    statTableLabelColor: 'negative.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'card.bg.lightred',
    cardBorderColor: 'negative.500',
    cardHeaderBgColor: 'accent.xdarkred',
    statTableBgColor: 'marker.pink',
  } satisfies CompCardColors)),
  Match.when('LEASED', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'ondark.1',
    cardLowerBgColor: 'marker.pink',
    statTableLabelColor: 'negative.800',
    statTableValueColor: 'graystrong.400',
    cardBgColor: 'card.bg.lightred',
    cardBorderColor: 'negative.500',
    cardHeaderBgColor: 'accent.xdarkred',
    statTableBgColor: 'marker.pink',
  } satisfies CompCardColors)),
  Match.when('EXPIRED', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'graystrong.600',
    cardLowerBgColor: 'grayweak.600',
    statTableLabelColor: 'marker.dirty-white',
    statTableValueColor: 'marker.dirty-white',
    cardBgColor: 'grayweak.600',
    cardBorderColor: 'graystrong.100',
    cardHeaderBgColor: 'grayweak.200',
    statTableBgColor: 'marker.gray',
  } satisfies CompCardColors)),
  Match.when('CANCELED', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'graystrong.600',
    cardLowerBgColor: 'grayweak.600',
    statTableLabelColor: 'marker.dirty-white',
    statTableValueColor: 'marker.dirty-white',
    cardBgColor: 'grayweak.600',
    cardBorderColor: 'graystrong.100',
    cardHeaderBgColor: 'grayweak.200',
    statTableBgColor: 'marker.gray',
  } satisfies CompCardColors)),
  Match.when('WITHDRAWN', () => ({
    cardTextColor: 'graystrong.400',
    cardHeaderTextColor: 'graystrong.600',
    cardLowerBgColor: 'grayweak.600',
    statTableLabelColor: 'marker.dirty-white',
    statTableValueColor: 'marker.dirty-white',
    cardBgColor: 'marker.gray',
    cardBorderColor: 'graystrong.100',
    cardHeaderBgColor: 'grayweak.200',
    statTableBgColor: 'marker.gray',
  } satisfies CompCardColors)),
  Match.when('OFF_MARKET', () => ({
    cardTextColor: 'marker.dirtywhite',
    cardHeaderTextColor: 'marker.dirtywhite',
    cardLowerBgColor: 'marker.darkgray',
    statTableLabelColor: 'marker.dirty-white',
    statTableValueColor: 'marker.dirty-white',
    cardBgColor: 'marker.darkgray',
    cardBorderColor: 'graystrong.100',
    cardHeaderBgColor: 'marker.darkgray',
    statTableBgColor: 'marker.darkgray',
  } satisfies CompCardColors)),
  Match.exhaustive,
)
