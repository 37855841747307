import { Avatar, Flex, Text } from '@chakra-ui/react'
import { PersonName } from 'features/valueObjects/PersonName'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Table, TableProps, Tbody, Td, Th, Thead, Tr } from 'presentation/components/molecules/Table'
import { MemberTableViewModel } from 'presentation/screens/MembersScreen/components/MemberActivationModal/MemberTable.viewModel'
import { membersScreenUtils } from 'presentation/screens/MembersScreen/utils/MembersScreen.utils'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export type MemberTableProps = TableProps & MemberTableViewModel

export const MemberTable: FC<MemberTableProps> = props => {
  const { viewModel, rest } = separateViewModel(props)
  const name = PersonName.combine(viewModel.member.firstName, viewModel.member.lastName)
  return (
    <Table shouldHideExpandButton {...rest}>
      <Thead isBigScreenOnly textStyle='bodyMFat'>
        <Tr>
          <Th w={mbp({ tabSm: '33.33%' })} textStyle='inherit'>Team Member</Th>
          <Th w={mbp({ tabSm: '33.33%' })} isBigScreenOnly textStyle='inherit'>Position</Th>
          <Th w={mbp({ tabSm: '33.33%' })} isBigScreenOnly textStyle='inherit'>Invited On</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr
          smallScreenExpandedContent={<RowExpandedContent {...viewModel} />}
          isExpanded
        >
          <Td>
            <Flex gap={1} alignItems='center'>
              <Avatar
                size='sm'
                name={name || undefined}
                src={viewModel.member.avatarUrl ?? undefined}
              />
              <Text
                textStyle={mbp({
                  mobSm: 'bodyM',
                  mob: 'bodyL',
                })}
                color='graystrong.400'
              >
                {name}
              </Text>
            </Flex>
          </Td>
          <Td isBigScreenOnly>
            <Text textStyle='bodyM' color='graystrong.400'>
              {viewModel.isOwner ? 'Owner' : 'Team Member'}
            </Text>
          </Td>
          <Td isBigScreenOnly>
            <Text textStyle='bodyM' color='graystrong.400'>
              {viewModel.member.memberSince ? membersScreenUtils.formatDate(viewModel.member.memberSince) : ''}
            </Text>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
}

const RowExpandedContent: FC<MemberTableViewModel> = viewModel => (
  <Flex gap={1}>
    <Pair flexBasis='50%' size={mbp({ mobSm: 'sm', mob: 'md' })}>
      <PairKey>Position</PairKey>
      <PairValue>{viewModel.isOwner ? 'Owner' : 'Team Member'}</PairValue>
    </Pair>
    <Pair flexBasis='50%' size={mbp({ mobSm: 'sm', mob: 'md' })}>
      <PairKey>Invited On</PairKey>
      <PairValue>
        {viewModel.member.memberSince ? membersScreenUtils.formatDate(viewModel.member.memberSince) : ''}
      </PairValue>
    </Pair>
  </Flex>
)

const separateViewModel = ({ member, isOwner, ...rest }: MemberTableProps) => ({
  viewModel: {
    member,
    isOwner,
  } satisfies MemberTableViewModel,
  rest,
})
