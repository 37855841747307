import { createIcon } from '@chakra-ui/react'

export const CloseIcon = createIcon({
  displayName: 'CloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path id='Vector' d='M18 6L6 18' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path id='Vector_2' d='M6 6L18 18' stroke='currentColor' fill='none' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
