import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { BASE_TABS_CLASS_NAMES } from 'presentation/components/BaseTabs/BaseTabs.const'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { mergeDeep, values } from 'remeda'

const PARTS = {
  container: 'container',
  tabs: 'tabs',
  tab: 'tab',
  tabCurve: 'tabCurve',
  tabText: 'tabText',
  content: 'content',
  contentTopLeftCurveNegative: 'contentTopLeftCurveNegative',
}

const PART_KEYS = values(PARTS) as (keyof typeof PARTS)[]

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(
  PART_KEYS,
)

const baseStyle = definePartsStyle(props => mergeDeep(
  props.colorScheme ? colorScheme[props.colorScheme as colorScheme] : {},
  {
    [PARTS.tabs]: {
      flexGap: 0,
    },
    [PARTS.tab]: {
      'pos': 'relative',
      'borderTopRadius': 3,
      'boxShadow': 'tabs.notfirst',
      '&:first-of-type': {
        boxShadow: 'tabs.first',
      },

      // reverse zIndex, assume up to 10 tabs
      '&:nth-of-type(1)': { zIndex: 10 },
      '&:nth-of-type(2)': { zIndex: 9 },
      '&:nth-of-type(3)': { zIndex: 8 },
      '&:nth-of-type(4)': { zIndex: 7 },
      '&:nth-of-type(5)': { zIndex: 6 },
      '&:nth-of-type(6)': { zIndex: 5 },
      '&:nth-of-type(7)': { zIndex: 4 },
      '&:nth-of-type(8)': { zIndex: 3 },
      '&:nth-of-type(9)': { zIndex: 2 },
      '&:nth-of-type(10)': { zIndex: 1 },

      // Double && to win specificity
      '&&[data-isactive="true"]': { zIndex: 10 },
      '&[data-ishidden="true"]': { display: 'none' },
    },
    [PARTS.tabCurve]: {
      pos: 'absolute',
      bottom: '-1px',
      // @HACK to close the gab below the curve
      // transform: 'scaleY(1.05)',

      // We want to remove the first NON-HIDDEN left curve by:
      // 1. Removing all left curve
      // 2. Undo except the first non-hidden curve
      // @see https://stackoverflow.com/a/8539107
      [`.${BASE_TABS_CLASS_NAMES.tab}[data-ishidden="false"] &[data-curveside="left"]`]: {
        display: 'none',
      },
      [`.${BASE_TABS_CLASS_NAMES.tab}[data-ishidden="false"] ~ .${BASE_TABS_CLASS_NAMES.tab}[data-ishidden="false"] > &[data-curveside="left"]`]: {
        display: 'block',
      },
    },
    [PARTS.tabText]: {
      pos: 'relative',
      zIndex: 1, // Put the text above the curves
    },
    [PARTS.content]: {
      pos: 'relative',
      zIndex: 11, // Put the content above the tabs, to hide shadow
      boxShadow: 'primary.w',
      overflow: 'hidden',
    },
    [PARTS.contentTopLeftCurveNegative]: {
      pos: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
    },
  },
))

export const colorScheme = {
  strong: {
    [PARTS.tabs]: {
    },
    [PARTS.tab]: {
      'bg': 'highlight.500',
      'color': 'onlight.3',

      ':hover': {
        bg: 'accent.gold.100',
      },

      '&[data-isactive="true"]': {
        bg: 'neutral.500',
        color: 'ondark.1',
      },

      '&[data-isdisabled="true"], &[data-ismuted="true"]:not([data-isactive="true"])': {
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
    [PARTS.tabCurve]: {
      color: 'highlight.500',

      [`.${BASE_TABS_CLASS_NAMES.tab}:hover &`]: {
        color: 'accent.gold.100',
      },

      [`
        .${BASE_TABS_CLASS_NAMES.tab}[data-isdisabled="true"] &,
        .${BASE_TABS_CLASS_NAMES.tab}[data-ismuted="true"] &
      `]: {
        color: 'grayweak.600',
      },

      [`.${BASE_TABS_CLASS_NAMES.tab}[data-isactive="true"] &`]: {
        color: 'neutral.500',
      },
    },
    [PARTS.content]: {
      bg: 'neutral.500',
    },
    [PARTS.contentTopLeftCurveNegative]: {
      '&[data-firsttabstatus="null"]': {
        display: 'none',
      },
      '&[data-firsttabstatus="inactive"]': {
        color: 'highlight.500',
      },
      '&[data-firsttabstatus="active"]': {
        color: 'neutral.500',
      },
      '&[data-firsttabstatus="muted"]': {
        color: 'grayweak.600',
      },
    },
  },
  weak: {
    [PARTS.tabs]: {
    },
    [PARTS.tab]: {
      'bg': 'highlight.200',
      'color': 'graystrong.300',

      ':hover': {
        bg: 'card.bg.yellow',
      },

      '&[data-isactive="true"]': {
        bg: 'neutral.200',
        color: 'graystrong.600',
      },

      '&[data-isdisabled="true"], &[data-ismuted="true"]:not([data-isactive="true"])': {
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
    [PARTS.tabCurve]: {
      color: 'highlight.200',

      [`.${BASE_TABS_CLASS_NAMES.tab}:hover &`]: {
        color: 'card.bg.yellow',
      },

      [`.${BASE_TABS_CLASS_NAMES.tab}[data-isactive="true"] &`]: {
        color: 'neutral.200',
      },

      [`
        .${BASE_TABS_CLASS_NAMES.tab}[data-isdisabled="true"] &,
        .${BASE_TABS_CLASS_NAMES.tab}[data-ismuted="true"] &
      `]: {
        color: 'grayweak.600',
      },
    },
    [PARTS.content]: {
      bg: 'neutral.200',
    },
    [PARTS.contentTopLeftCurveNegative]: {
      '&[data-firsttabstatus="null"]': {
        display: 'none',
      },
      '&[data-firsttabstatus="inactive"]': {
        color: 'highlight.200',
      },
      '&[data-firsttabstatus="active"]': {
        color: 'neutral.200',
      },
      '&[data-firsttabstatus="muted"]': {
        color: 'grayweak.600',
      },
    },
  },
  xweak: {
    [PARTS.tabs]: {
    },
    [PARTS.tab]: {
      'bg': 'highlight.100',
      'color': 'graystrong.300',

      ':hover': {
        bg: 'accent.gold.100',
      },

      '&[data-isactive="true"]': {
        bg: 'accent.hover',
        color: 'graystrong.600',
      },

      '&[data-isdisabled="true"], &[data-ismuted="true"]:not([data-isactive="true"])': {
        bg: 'grayweak.600',
        color: 'ondark.6',
      },
    },
    [PARTS.tabCurve]: {
      color: 'highlight.100',

      [`.${BASE_TABS_CLASS_NAMES.tab}:hover &`]: {
        color: 'accent.gold.100',
      },

      [`.${BASE_TABS_CLASS_NAMES.tab}[data-isactive="true"] &`]: {
        color: 'accent.hover',
      },

      [`
        .${BASE_TABS_CLASS_NAMES.tab}[data-isdisabled="true"] &,
        .${BASE_TABS_CLASS_NAMES.tab}[data-ismuted="true"] &
      `]: {
        color: 'grayweak.600',
      },
    },
    [PARTS.content]: {
      bg: 'accent.hover',
    },
    [PARTS.contentTopLeftCurveNegative]: {
      '&[data-firsttabstatus="null"]': {
        display: 'none',
      },
      '&[data-firsttabstatus="inactive"]': {
        color: 'highlight.100',
      },
      '&[data-firsttabstatus="active"]': {
        color: 'accent.hover',
      },
      '&[data-firsttabstatus="muted"]': {
        color: 'grayweak.600',
      },
    },
  },
} as const
export type colorScheme = keyof typeof colorScheme

const sizes = {
  lg: {
    [PARTS.tabs]: {
      display: 'flex',
    },
    [PARTS.tab]: {
      display: 'block',
      h: 4.5,
      ...textStyles.bodyXLFat,
    },
    [PARTS.tabText]: {
      // We put pading in tabText, not tab, to make position absolute elements
      // inside tab component easier
      px: 3,
    },
    [PARTS.tabCurve]: {
      'h': 4.5,
      'w': 4.5,

      '&[data-curveside="left"]': {
        left: -1.5,
      },
      '&[data-curveside="right"]': {
        right: -1.5,
      },
    },
    [PARTS.content]: {
      borderRadius: 2,
      borderTopLeftRadius: 0,
    },
  },
  md: {
    [PARTS.tabs]: {
      display: 'flex',
    },
    [PARTS.tab]: {
      display: 'block',
      h: 4,
      ...textStyles.bodyLFat,
    },
    [PARTS.tabText]: {
      // We put pading in tabText, not tab, to make position absolute elements
      // inside tab component easier
      px: 3,
    },
    [PARTS.tabCurve]: {
      'h': 4,
      'w': 4.5,

      '&[data-curveside="left"]': {
        left: -1.5,
      },
      '&[data-curveside="right"]': {
        right: -1.5,
      },
    },
    [PARTS.content]: {
      borderRadius: 2,
      borderTopLeftRadius: 0,
    },
  },
  sm: {
    [PARTS.tabs]: {
      display: 'flex',
    },
    [PARTS.tab]: {
      display: 'block',
      h: 3.5,
      ...textStyles.bodyMFat,
    },
    [PARTS.tabText]: {
      // We put pading in tabText, not tab, to make position absolute elements
      // inside tab component easier
      px: 2,
    },
    [PARTS.tabCurve]: {
      'h': 3.5,
      'w': 4.5,

      '&[data-curveside="left"]': {
        left: -1.5,
      },
      '&[data-curveside="right"]': {
        right: -1.5,
      },
    },
    [PARTS.content]: {
      borderRadius: 2,
      borderTopLeftRadius: 0,
    },
  },
} as const
export type size = keyof typeof sizes

export const baseTabsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'strong',
  },
})
