import { gql } from '__generated__'

const GET_PRODUCTS = gql(/* GraphQL */`
  query GetProducts {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
          plan {
            id
          }
        }
      }
    }

    billingSubscriptionProducts { 
      id
      name
      description

      plans {
        id
        name
        price
        interval {
          unit
        }
      }

      features {
        ...ProductFeatures 
      }      
    }
  }
`)

export default GET_PRODUCTS
