import { BoxProps, Button, Center, Flex, FlexProps, MenuButton, MenuItemOption, Spacer, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match, Option } from 'effect'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import NearbyBuyersItem from 'features/NearbyBuyers/views/NearbyBuyersItem/NearbyBuyersItem'
import { NearbyBuyersPagination } from 'features/NearbyBuyers/views/NearbyBuyersPagination/NearbyBuyersPagination'
import NearbyBuyersNoData from 'features/NearbyBuyers/views/NearbyBuyersPanel/components/NearbyBuyersNoData/NearbyBuyersNoData'
import { Breakpoint } from 'presentation/components/Breakpoint'
import DraggablePanelContext from 'presentation/components/DraggablePanel/DraggablePanelContext'
import FloatingActionButton from 'presentation/components/FloatingActionButton'
import { DownloadBoxIcon } from 'presentation/components/Icons'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useCallback, useEffect } from 'react'
import InvestorTypeFilterCheckboxGroup from '../InvestorTypeFilter/InvestorTypeFilterCheckboxGroup'
import NearbyBuyersNoInvestorSelected from './components/NearbyBuyersNoInvestorSelected/NearbyBuyersNoInvestorSelected'

const NearbyBuyersPanel = (props: BoxProps) => {
  const panelState = NearbyBuyersHooks.usePanelState()

  return (
    <Flex
      flexDir='column'
      bgColor='accent.hover'
      maxH='full'
      minH={0}
      h='full'
      borderBottomLeftRadius={3}
      {...props}
    >
      {panelState._tag !== 'Empty' && (
        <Breakpoint
          mobSm={<ListHeaderSmall />}
          tabSm={<ListHeaderLarge />}
        />
      )}

      {Match.value(panelState).pipe(
        Match.tag('Unready', () => (
          <Center flex='1'>
            <Spinner color='graystrong.500' />
          </Center>
        )),
        Match.tag('NoBuyerTypesSelected', () => (
          <VStack p={2} flex='1 1 0' align='stretch' justify='stretch'>
            <NearbyBuyersNoInvestorSelected>
              <Breakpoint
                mobSm={(
                  <InvestorTypeFilterCheckboxGroup mt={3} />
                )}
                tabSm={<></>}
              />
            </NearbyBuyersNoInvestorSelected>
          </VStack>
        )),
        Match.tag('Empty', () => (
          <VStack p={2} flex='1 1 0' align='stretch' justify='stretch'>
            <NearbyBuyersNoData>
              <InvestorTypeFilterCheckboxGroup mt={3} />
            </NearbyBuyersNoData>
          </VStack>
        )),
        Match.tag('Populated', ({ data }) => (
          <>
            {/** NOTE: Show when at least one item is selected */}
            {/* <SelectionToolbar /> */}

            <ListContainer
              flex='1 0 0'
              flexDirection='column'
              gap={2}
              px={mbp({
                mobSm: 1,
                mob: 2,
                tabSm: 3,
              })}
              pb={3}
              overflow='auto'
              maxH='full'
              minH={0}
            >
              {data.nearbyBuyerActorRefs.map(actor => (
                <NearbyBuyersItem
                  key={actor.id}
                  actorRef={actor}
                />
              ))}
            </ListContainer>

            {/** Footer */}
            {/**
            <Flex
              gap={2}
              boxShadow='0px -4px 10px 0px rgba(0, 0, 0, 0.25)'
              px={3}
              py={2}
              alignItems='center'
              zIndex={1}

              borderBottomLeftRadius={3}
            >
              <Text
                textStyle='bodyM'
                color='graystrong.400'
              >
                260 results
              </Text>

              <ListDownloadButton />
              <Button
                variant='solid'
                colorScheme='positive'
                leftIcon={
                  <SkiptraceSuccessIcon boxSize={3} />
                }
              >
                Skiptrace
              </Button>
            </Flex>
          */}
            <PanelFooter />
          </>
        )),
        Match.exhaustive,
      )}
    </Flex>
  )
}

export default NearbyBuyersPanel

const PanelFooter = () => {
  const { sbp } = useSwitchBreakpointFn()

  const isMobile = sbp({
    mobSm: true,
    tabSm: false,
  }) ?? false

  const state = DraggablePanelContext.useActorState()
  const showPagination = state === 'Open' && isMobile

  return showPagination
    ? (
      <NearbyBuyersPagination
        gap={2}
        boxShadow='0px -4px 10px 0px rgba(0, 0, 0, 0.25)'
        px={mbp({ mobSm: 1, mob: 2 })}
        py={1}
        alignItems='center'
        zIndex={1}
      />
    )
    : null
}

const ListContainer = (props: FlexProps) => {
  const listMountEvents = NearbyBuyersHooks.useListMountEvents()

  useEffect(() => listMountEvents.onUnmount, [])

  const handleRef = useCallback((ref: HTMLDivElement) => {
    if (!ref) return
    FloatingActionButton.setTargetScrollableElement(ref)
    listMountEvents.onMount(ref)
  }, [])

  return (
    <Flex
      ref={handleRef}
      {...props}
    />
  )
}

// const SelectionToolbar = () => (
//   <Flex
//     justifyContent='space-between'
//     px={3}
//     pt={3}
//     pb={2}
//   >
//     <Checkbox>
//       <Text
//         textStyle='bodyM'
//         color='graystrong.200'
//       >
//         SELECT ALL
//       </Text>
//     </Checkbox>

//     <Text
//       textStyle='bodyM'
//       color='grayweak.900'
//     >
//       1 item selected
//     </Text>
//   </Flex>
// )

export const ListDownloadButton = () => (
  <Menu>
    <MenuButton
      as={Button}
      ml='auto'
      variant='ghost'
      colorScheme='neutral'
      size='lg'
      rightIcon={
        <DownloadBoxIcon boxSize={2} />
      }
    >
      Download
    </MenuButton>

    <ResponsiveMenuList>
      <MenuItemOption>
        Download list data
      </MenuItemOption>
      <MenuItemOption>
        Download skiptrace data
      </MenuItemOption>
    </ResponsiveMenuList>
  </Menu>
)

const ListHeaderLarge = () => (
  <Flex
    gap={2}
    alignItems='center'
    px={3}
    pt={3}
    pb={2}
  >
    <ListCount />

    <Spacer />

    <NearbyBuyersPagination />
  </Flex>
)

const ListHeaderSmall = () => (
  <Flex
    justifyContent='space-between'
    px={2}
    py={2}
  >
    <Text
      textStyle='bodyXLFat'
      color='accent.blue-text'
    >
      Buyers
    </Text>
    <ListCount />
  </Flex>
)

const ListCount = () => {
  const pageRangeText = NearbyBuyersHooks.usePageRangeText()

  if (Option.isNone(pageRangeText)) return null

  return (
    <Text
      textStyle='bodyM'
      color='graystrong.400'
    >
      {pageRangeText.value}
    </Text>
  )
}
