import { FormControl, FormErrorMessage, FormLabel, Input, ModalOverlay } from '@chakra-ui/react'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { UpdatingListNameState, useListStoreActions } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useUpdateListNameState } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/useUpdateListNameState'
import LadyGift from 'presentation/assets/img/3d/lady-gift.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { usePrevious } from 'react-use'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type EditListNameForm = {
  listName: string
}

export const EditListNameModal = () => {
  const modalApi = useEditListNameModal()
  const modalState = modalApi.state
  const formApi = useForm<EditListNameForm>({})
  const actionsApi = useListStoreActions()
  const updateState = useUpdateListNameState()

  useEffect(() => {
    if (modalState.status === 'closed') return
    formApi.reset({ listName: modalState.payload.listName })
  }, [modalState])

  const previousUpdateState = usePrevious(updateState)
  useEffect(() => {
    if (
      modalState.status === 'open'
      && UpdatingListNameState.$is('Loading')(previousUpdateState)
      && UpdatingListNameState.$is('Success')(updateState)
    )
      modalApi.close()
  }, [updateState])

  useEffect(() => {
    if (
      UpdatingListNameState.$is('Loading')(previousUpdateState)
      && UpdatingListNameState.$is('Failed')(updateState)
    ) {
      toast.error({
        title: 'Failed to Edit List Name',
        message: TOAST_PRESET.GENERIC_ERROR.message,
      })
    }
  }, [updateState])

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalApi.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            as='form'
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.neutral'
            onSubmit={formApi.handleSubmit(formValues => {
              actionsApi.updateListName(modalState.payload.listId, formValues.listName)
            })}
          >
            <CardImage src={LadyGift} />
            <ModalCloseButton onClose={modalApi.close} />
            <CardPrimaryTitle>
              Edit List Name
            </CardPrimaryTitle>
            <CardBody>
              <FormControl isInvalid={!!formApi.formState.errors.listName}>
                <FormLabel>List Name</FormLabel>
                <Input
                  type='text'
                  {...formApi.register('listName', {
                    required: 'List name is required',
                  })}
                />
                {formApi.formState.errors.listName && (
                  <FormErrorMessage>
                    {formApi.formState.errors.listName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </CardBody>
            <CardFooter>
              <CardButton
                variant='outline'
                colorScheme='neutral'
                size='md'
                onClick={modalApi.close}
                autoFocus
              >
                Cancel
              </CardButton>
              <CardButton
                variant='solid'
                colorScheme='positive'
                size='md'
                autoFocus
                isLoading={UpdatingListNameState.$is('Loading')(updateState)}
                loadingText='Updating...'
                type='submit'
              >
                Update
              </CardButton>
            </CardFooter>

          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}

type OpenPayload = {
  listId: MarketingListId
  listName: string
}

type EditListNameModalState = {
  state: {
    status: 'open'
    payload: {
      listId: MarketingListId
      listName: string
    }
  }
  | { status: 'closed' }
  close: () => void
  open: (payload: OpenPayload) => void
}

export const useEditListNameModal = create<EditListNameModalState>()(immer(set => ({
  state: { status: 'closed' },
  close: () => set(draft => {
    draft.state = { status: 'closed' }
  }),
  open: ({ listId, listName }) => set(draft => {
    draft.state = { status: 'open', payload: { listId, listName } }
  }),
})))
