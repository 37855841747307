import { Address } from 'features/valueObjects/Address'
import { Location } from 'features/valueObjects/Location'
import { ReportableException } from 'libs/errors'
import { loadGoogleMaps } from 'presentation/libs/loadGoogleMaps'
import { isNumber } from 'remeda'

export type GooglePlaceId = string

const geocodeByPlaceId = async (placeId: GooglePlaceId): Promise<Address & Location> => {
  const maps = await loadGoogleMaps()
  const geocoder = new maps.Geocoder()
  const response = await geocoder.geocode({ placeId })

  const componentsMap: Record<string, string> = {}

  response.results[0]?.address_components.forEach(component => {
    component.types.forEach(type => {
      componentsMap[type] = component.short_name
    })
  })

  const line1 = [
    componentsMap.street_number,
    componentsMap.route,
  ].filter(Boolean).join(' ')

  const city = componentsMap.locality
    || componentsMap.neighborhood
    || componentsMap.sublocality_level_1
    || componentsMap.administrative_area_level_3

  const state = componentsMap.administrative_area_level_1

  const postalCode = componentsMap.postal_code

  const address = Address.toNullIfEmpty({
    line1,
    city,
    state,
    postalCode,
  })

  if (!address) {
    throw new ReportableException('Failed to geocode place id', {
      extraInfo: {
        placeId,
        geocodingResponse: response,
      },
    })
  }

  const latitude = response.results[0]?.geometry?.location?.lat()
  const longitude = response.results[0]?.geometry?.location?.lng()

  if (!isNumber(latitude) || !isNumber(longitude)) {
    throw new ReportableException('Failed to geocode place id', {
      extraInfo: {
        placeId,
        geocodingResponse: response,
      },
    })
  }

  return {
    ...address,
    latitude,
    longitude,
  }
}

const geocodeByAddressString = async (addressString: string): Promise<Address & Location> => {
  const maps = await loadGoogleMaps()

  const autocompleteService = new maps.places.AutocompleteService()

  return await new Promise<Address & Location>((resolve, reject) => {
    autocompleteService.getQueryPredictions({
      input: addressString,
    }, async results => {
      const placeId = results?.[0]?.place_id

      if (placeId)
        resolve(geocodeByPlaceId(placeId))
      else
        reject(new Error('Failed to get place id'))
    })
  })
}

export const GoogleGeocoder = {
  geocodeByPlaceId,
  geocodeByAddressString,
}
