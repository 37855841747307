import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { Match, Option, pipe } from 'effect'
import HomeType from 'features/ListBuilderV2/domain/entities/ListCriteria/PropertyListCriteria/members/HomeType'
import { CheckCircleIcon } from 'presentation/components/Icons'
import { SubjectPropertyMarker } from 'presentation/components/PropertyMarker/SubjectPropertyMarker'
import { HomeTypeFilterOptionProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/HomeTypeFilterOption.props'
import { HomeTypeIcon } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon'

export const HomeTypeFilterOption = (props: HomeTypeFilterOptionProps) => {
  const checkIconColor = props.isSelected && props.isSubjectProperty
    ? 'specialsat.500'
    : 'positivesat.500'

  const shouldShowCheckIcon = props.isSelected

  const shouldShowSubjectProperty = !props.isSelected && props.isSubjectProperty

  const iconSize = pipe(
    Match.value(props.size),
    Match.when('lg', () => 2),
    Match.when('sm', () => 1.5),
    Match.exhaustive,
  )

  const labelTextStle = pipe(
    Match.value(props.size),
    Match.when('lg', () => 'bodyMFat'),
    Match.when('sm', () => 'bodySFat'),
    Match.exhaustive,
  )

  const handleOnSelectionChange = () => {
    props.onSelectionChange(!props.isSelected)
  }

  return (
    <Flex
      direction='column'
      alignItems='center'
      gap={0.5}
      cursor='pointer'
      onClick={handleOnSelectionChange}
    >
      <Grid
        gridTemplateAreas='"layer"'
        w='max-content'
      >
        <Box gridArea='layer'>
          <HomeTypeIcon {...props} />
        </Box>

        { /**
       * ========================================
       * Subscripts
       * ========================================
       */}
        <Box
          gridArea='layer'
          justifySelf='end'
          alignSelf='end'
        >
          {shouldShowCheckIcon && (
            <CheckCircleIcon
              boxSize={iconSize}
              color={checkIconColor}
            />
          )}

          {shouldShowSubjectProperty && (
            <Box>
              <SubjectPropertyMarker
                size='xxs'
                markerType='pin'
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Text
        textStyle={labelTextStle}
        color='graystrong.400'
        textAlign='center'
        w='max-content'
      >
        {pipe(
          props.variation,
          Option.fromNullable,
          Option.flatMap(HomeType.fromString),
          HomeType.getOrAnyType,
        )}
      </Text>
    </Flex>
  )
}
