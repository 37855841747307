import { LegacyPlanModal__CurrentSubscriptionFragment } from '__generated__/graphql'
import { ErrorModalProps } from 'presentation/main/globalModals/ErrorModal/ErrorModal.types'
import { closeModal, goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals/modals'

// @IMPORTANT Please stop adding functions to this file, it's better to define
// modal open function near the actual modal code

export const openAddPaymentModal = (context?: 'registration' | 'other'): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'ADD_PAYMENT_MODAL',
      props: {
        onClose: goBackModal,
        context,
      },
    },
  })
}

export const openLegacyPlanModal = ({
  subscription,
}: {
  subscription: LegacyPlanModal__CurrentSubscriptionFragment
}): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'LEGACY_PLAN_MODAL',
      props: {
        onClose: goBackModal,
        subscription,
      },
    },
  })
}

export const openErrorModal = (
  props: Omit<ErrorModalProps, 'onClose'> & { onClose?: () => void },
): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'ERROR_MODAL',
      props: {
        onClose: closeModal,
        ...props,
      },
    },
  })
}

export const openWalletBalanceEntryModal = (): void => {
  modals.emitter.emit({ type: 'OPEN', payload: { key: 'WALLET_BALANCE_ENTRY_MODAL', props: { onClose: goBackModal } } })
}

export const openWalletPaymentDetailsModal = (amount: number): void => {
  modals.emitter.emit({ type: 'OPEN', payload: { key: 'WALLET_PAYMENT_DETAILS_MODAL', props: { amount, onClose: goBackModal } } })
}
