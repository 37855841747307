import { Box, BoxProps, Button, Flex, Grid, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ExclamationCircleIcon } from 'presentation/components/Icons'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { EquityOnlyPropertyMarker } from 'presentation/components/PropertyMarker/components/EquityOnlyPropertyMarker/EquityOnlyPropertyMarker'
import { SupOnlyPropertyMarker } from 'presentation/components/PropertyMarker/components/SupOnlyPropertyMarker/SupOnlyPropertyMarker'
import { usePropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal/PropertyMarkerTrainingModal.api'
import { PropsWithChildren, ReactNode } from 'react'

export const PropertyMarkerLegendItems = (props: BoxProps) => {
  const api = usePropertyMarkerTrainingModal()
  return (
    <Box {...props}>
      <Grid
        gridTemplateColumns='repeat(2, auto)'
        rowGap={1}
        columnGap={2}
      >
        <OwnerOccupied />
        <FreeAndClear />
        <InStateAbsentee />
        <HighEquity />
        <OutOfState />
        <LowEquity />
        <BankOwned />
        <UpsideDown />
        <Corporate />
        <Preforeclosure />
        <Trust />
        <Vacant />
        <Military />
        <Multiple />
        <Unknown />
      </Grid>
      <Button
        mt={3}
        variant='outline'
        colorScheme='neutral'
        leftIcon={<ExclamationCircleIcon boxSize={3} />}
        w='full'
        onClick={() => api.actions.open()}
      >
        Learn More
      </Button>
    </Box>

  )
}

/**
 * ================
 * Classification Legend Items
 * ================
 */
const OwnerOccupied = () => (
  <LegendItem label='Owner Occupied'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='OWNER_OCCUPIED'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const InStateAbsentee = () => (
  <LegendItem label='In State Absentee'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='IN_STATE'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const OutOfState = () => (
  <LegendItem label='Out Of State/Country'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='OUT_OF_STATE'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const BankOwned = () => (
  <LegendItem label='Bank Owned'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='BANK'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const Corporate = () => (
  <LegendItem label='Corporate'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='CORPORATE'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const Trust = () => (
  <LegendItem label='Trust'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='TRUST'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const Military = () => (
  <LegendItem label='Military'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification='MILITARY'
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

const Unknown = () => (
  <LegendItem label='Unknown'>
    <PropertyMarker
      markerType='tag'
      size='md'
      classification={null}
      equityType={null}
      isForeclosure={false}
      isSenior={false}
      isVacant={false}
    />
  </LegendItem>
)

/**
 * ================
 * Equity Legend Items
 * ================
 */

const FreeAndClear = () => (
  <LegendItem label='Free and Clear'>
    <EquityOnlyPropertyMarker
      markerType='tag'
      size='sm'
      equityType='FREE_AND_CLEAR'
    />
  </LegendItem>
)

const HighEquity = () => (
  <LegendItem label='High Equity'>
    <EquityOnlyPropertyMarker
      markerType='tag'
      size='sm'
      equityType='HIGH'
    />
  </LegendItem>
)

const LowEquity = () => (
  <LegendItem label='Low Equity'>
    <EquityOnlyPropertyMarker
      markerType='tag'
      size='sm'
      equityType='LOW'
    />
  </LegendItem>
)

const UpsideDown = () => (
  <LegendItem label='Updside Down'>
    <EquityOnlyPropertyMarker
      markerType='tag'
      size='sm'
      equityType='UPSIDE_DOWN'
    />
  </LegendItem>
)

/**
 * ================
 * Superscript Legend Items
 * ================
 */
const Preforeclosure = () => (
  <LegendItem
    label={(
      <Breakpoint
        mobSm='Prefore- closure'
        mob='Preforeclosure'
      />
    )}
  >
    <SupOnlyPropertyMarker
      markerType='tag'
      size='sm'
      superscript='preforeclosure'
    />
  </LegendItem>
)

const Vacant = () => (
  <LegendItem label='Vacant'>
    <SupOnlyPropertyMarker
      markerType='tag'
      size='sm'
      superscript='vacant'
    />
  </LegendItem>
)

const Multiple = () => (
  <LegendItem label='Multiple'>
    <SupOnlyPropertyMarker
      markerType='tag'
      size='sm'
      superscript='multi'
    />
  </LegendItem>
)
/**
 * ================
 * Common
 * ================
 */
const LegendItem = (props: PropsWithChildren<{
  label: ReactNode
}>) => (
  <Flex gap={1} alignItems='center'>
    {props.children}
    <Text
      textStyle='bodyMFat'
      color='graystrong.400'
    >
      {props.label}
    </Text>
  </Flex>
)
