import { createIcon } from '@chakra-ui/react'

export const PROP_TYPE_ANY_ICON_W = 42

export const PropTypeAnyIcon = createIcon({
  displayName: 'PropTypeAnyIcon',
  viewBox: `0 0 ${PROP_TYPE_ANY_ICON_W} 34`,
  path: (
    <>
      <path fillRule='evenodd' clipRule='evenodd' d='m22.93 18.327 5.002 3.747v-1.279c0-.26.204-.471.455-.471s.455.21.455.471v1.96l2.54 1.902c.203.153.25.448.102.659a.444.444 0 0 1-.635.106l-7.92-5.931-7.919 5.93a.444.444 0 0 1-.635-.105.483.483 0 0 1 .103-.659l8.452-6.33Zm0 1.417 6.821 5.053V34H16.108v-9.203l6.822-5.053Zm-5.912 5.538v7.775h11.824v-7.775l-5.912-4.379-5.912 4.38Z' fill='currentColor' />
      <path d='M15.527 30.5H3.976V10.25l7.5-6.75 7.5 6.75v10.056' stroke='currentColor' fill='none' strokeWidth='1.25' />
      <path stroke='currentColor' fill='none' strokeWidth='1.25' d='M7.414 12.758h2.5v2.617h-2.5zM7.414 18.558h2.5v2.617h-2.5zM13.039 12.758h2.5v2.617h-2.5zM13.039 18.558h2.5v2.617h-2.5zM10.227 24.978h2.5v5.517h-2.5z' />
      <path d='m1.977 10 9.5-8.5 6.705 6m2.795 2.5-2.795-2.5m0 0V5' fill='none' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' />
      <path d='M33.36 28.545V14.727' stroke='currentColor' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M35.792 11.54c2.173 0 3.731 1.385 3.731 2.869h1c0-2.237-2.23-3.87-4.73-3.87v1Zm3.731 2.869c0 1.484-1.558 2.869-3.73 2.869v1c2.5 0 4.73-1.632 4.73-3.87h-1Zm-3.799 3.276c-.196 1.043-.575 1.66-1.079 2.03-.513.377-1.236.557-2.237.557v1c1.101 0 2.071-.194 2.83-.752.77-.566 1.242-1.446 1.469-2.65l-.983-.185Zm-3.316 2.587c-.823 0-1.641-.545-2.281-1.473-.634-.92-1.027-2.132-1.027-3.267h-1c0 1.345.459 2.754 1.203 3.834.739 1.072 1.824 1.906 3.105 1.906v-1Zm-7.116-8.483c0-1.633 1.42-3.06 3.358-3.228l-.086-.996c-2.346.203-4.272 1.97-4.272 4.224h1ZM28.65 8.56c.122-.01.247-.016.373-.016v-1c-.155 0-.308.007-.46.02l.087.996Zm.373 6.47c-2.12 0-3.731-1.507-3.731-3.242h-1c0 2.399 2.177 4.243 4.73 4.243v-1ZM29.1 8.42c0-.115.002-.228.006-.338l-1-.038a9.844 9.844 0 0 0-.006.376h1Zm.006-.338c.056-1.45.47-2.458 1.097-3.102.622-.64 1.513-.98 2.648-.98V3c-1.34 0-2.513.408-3.365 1.283-.848.871-1.317 2.144-1.38 3.761l1 .038ZM32.851 4c1.065 0 2.395.81 3.22 2.125.806 1.286 1.076 2.975.13 4.672l.874.486c1.15-2.064.812-4.145-.157-5.69C35.968 4.08 34.358 3 32.851 3v1Z' fill='currentColor' />
    </>
  ),
})
