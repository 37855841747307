import { useActorRef } from '@xstate/react'
import { Effect, Exit, Layer, Request, Scope, pipe } from 'effect'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import { makeLiveLayers } from 'features/ListBuilder/infra/makeLiveLayers'
import ParcelListsManagerMachine from 'features/ListBuilder/views/machines/ParcelListsManagerMachine'
import { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react'
import { ActorLogicFrom } from 'xstate'

const ParcelListManagerContext = createContext<ParcelListsManagerMachine | null>(null)

export const useParcelListManager = () => {
  const context = useContext(ParcelListManagerContext)
  if (!context)
    throw new Error('No ParcelListManager Provider')
  return context
}

export type ParcelListManagerProviderProps = PropsWithChildren<{
  parcelId: ParcelId
}>

export const ParcelListManagerProvider = (props: ParcelListManagerProviderProps) => {
  // @TODO: Move runtime creation to a very top level provider that is reusable for all effect programs
  const layers = makeLiveLayers()

  const StoreScope = Effect.runSync(Scope.make())

  const StoreCache = Request.makeCache({
    capacity: 100,
    timeToLive: '60 minutes',
  }).pipe(Effect.runSync)

  const StoreRuntime = pipe(
    Layer.merge(
      layers,
      Layer.setRequestCache(StoreCache),
    ),
    Layer.toRuntime,
    Scope.extend(StoreScope),
    Effect.withRequestCaching(false),
    Effect.runSync,
  )

  const ref = useRef<ActorLogicFrom<typeof ParcelListsManagerMachine.make>>()

  if (!ref.current)
    ref.current = ParcelListsManagerMachine.make(StoreRuntime)

  const actorRef = useActorRef(ref.current, {
    input: {
      parcelId: props.parcelId,
    },
  })

  useEffect(() =>
    () => {
      Effect.runSync(Scope.close(StoreScope, Exit.void))
    }
  , [])

  return (
    <ParcelListManagerContext.Provider value={actorRef}>
      {props.children}
    </ParcelListManagerContext.Provider>
  )
}
