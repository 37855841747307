import { StyleFunctionProps } from '@chakra-ui/theme-tools'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { px } from 'presentation/utils/px'
import { getRecKey } from 'utils/getRecKey'

const BORDER_WIDTH = 2

const importantSizeStyles = {
  // Can't fix this without repeating it in every size
  minW: 0,
}

export const buttonTheme = {
  baseStyle: () => ({
    d: 'flex',
    borderRadius: 'full',
    borderColor: 'transparent',
    ...textStyles['body2-xb'],
    textTransform: 'uppercase',

    _hover: {
      _disabled: {
        cursor: 'not-allowed',
        opacity: 1,
      },
    },
    _disabled: {
      cursor: 'not-allowed',
      opacity: 1,
    },
  }),

  sizes: {
    'xl': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
          borderWidth: '0.125',
          boxSize: 4.5,
          fontSize: 2.5,
        }
      }

      return {
        ...importantSizeStyles,
        h: 6.5,
        px: variant === 'ghost' ? px(32) : px(32 - BORDER_WIDTH),
        ...textStyles.bodyLFat,
        borderWidth: variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        _active: variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(32),
          }
          : {},
      }
    },
    'lg': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
          borderWidth: '0.125',
          boxSize: 4.5,
          p: 0,
          fontSize: 2.5,
        }
      }

      if (variant === 'icon-ghost') {
        return {
          ...importantSizeStyles,
          boxSize: 5,
          borderRadius: '14px',
          p: 0,
          fontSize: 3,
        }
      }

      return {
        ...importantSizeStyles,
        h: 6,
        px: variant === 'ghost' ? px(32) : px(32 - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        borderWidth: variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        _active: variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(32),
          }
          : {},
      }
    },
    'lgv2': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
          borderWidth: '0.125',
          boxSize: 3.5,
          p: 0,
          fontSize: 2,
        }
      }

      if (variant === 'icon-ghost') {
        return {
          ...importantSizeStyles,
          boxSize: 4,
          borderRadius: '14px',
          p: 0,
          fontSize: 3,
        }
      }

      return {
        ...importantSizeStyles,
        h: 5,
        px: variant === 'ghost' ? px(32) : px(32 - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        borderWidth: variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        _active: variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(32),
          }
          : {},
      }
    },
    'md': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
          borderWidth: '0.125',
          boxSize: 3.5,
          p: 0,
          fontSize: 2,
        }
      }

      if (variant === 'icon-ghost') {
        return {
          ...importantSizeStyles,
          boxSize: 4,
          borderRadius: '14px',
          p: 0,
          fontSize: 3,
        }
      }

      return {
        ...importantSizeStyles,
        h: 5,
        px: variant === 'ghost' ? px(24) : px(24 - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        borderWidth: variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        _active: variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(24),
          }
          : {},
      }
    },
    'sm': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
          borderWidth: '0.125',
          boxSize: 3,
          p: 0,
          fontSize: 1.5,
        }
      }

      const paddingX = variant === 'ghost' ? 16 : 24
      return {
        ...importantSizeStyles,
        '& .chakra-button__icon': { fontSize: 3 },
        'h': 4,
        'px': variant === 'ghost' ? px(paddingX) : px(paddingX - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        'borderWidth': variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        '_active': variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(paddingX),
          }
          : {},
      }
    },
    'xs': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
        }
      }

      const paddingX = variant === 'ghost' || variant === 'muted' ? 8 : 24
      return {
        ...importantSizeStyles,
        '& .chakra-button__icon': { fontSize: 2 },
        'h': 3,
        'px': variant === 'ghost' ? px(paddingX) : px(paddingX - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        'borderWidth': variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        '_active': variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(paddingX),
          }
          : {},
      }
    },
    'xs-narrow': ({ variant }: StyleFunctionProps) => {
      if (variant === 'icon-solid') {
        return {
          ...importantSizeStyles,
        }
      }

      return {
        ...importantSizeStyles,
        '& .chakra-button__icon': { fontSize: 2 },
        'h': 3,
        'px': variant === 'ghost' ? px(16) : px(16 - BORDER_WIDTH),
        ...textStyles.bodyMFat,
        'borderWidth': variant === 'ghost' ? 0 : px(BORDER_WIDTH),
        '_active': variant === 'solid'
          ? {
            borderWidth: 0,
            px: px(16),
          }
          : {},
      }
    },
    'unsized': {
      borderWidth: 0,
    },
  },

  variants: {
    'solid': ({ colorScheme }: StyleFunctionProps) => {
      if (colorScheme === 'neutral') {
        return {
          bg: 'neutral.500',
          color: 'ondark.1',

          _hover: {
            bg: 'accent.darkblue',
            boxShadow: 'button-hovered',

            _disabled: {
              bg: 'grayweak.600',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'accent.xdarkblue',
            boxShadow: 'button-selected',
          },

          _disabled: {
            bg: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'negative') {
        return {
          bg: 'negative.500',
          color: 'ondark.1',

          _hover: {
            bg: 'accent.darkred',
            boxShadow: 'button-hovered',

            _disabled: {
              bg: 'grayweak.600',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'accent.xdarkred',
            boxShadow: 'button-selected',
          },

          _disabled: {
            bg: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'positive') {
        return {
          bg: 'positive.500',
          color: 'ondark.1',

          _hover: {
            bg: 'accent.xdarkgreen',
            boxShadow: 'button-hovered',

            _disabled: {
              bg: 'grayweak.600',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'accent.xxdarkgreen',
            boxShadow: 'button-selected',
          },

          _disabled: {
            bg: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'special') {
        return {
          bg: 'special.500',
          color: 'ondark.1',

          _hover: {
            bg: 'accent.xdarkpurple',
            boxShadow: 'button-hovered',

            _disabled: {
              bg: 'grayweak.600',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'accent.xxdarkpurple',
            boxShadow: 'button-selected',
          },

          _disabled: {
            bg: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'highlight') {
        return {
          bg: 'highlight.500',
          color: 'onlight.2',

          _hover: {
            bg: 'accent.gold.100',
            boxShadow: 'button-hovered',

            _disabled: {
              bg: 'grayweak.600',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'accent.gold.200',
            boxShadow: 'button-selected',
          },

          _disabled: {
            bg: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }
    },

    'outline': ({ colorScheme }: StyleFunctionProps) => {
      if (colorScheme === 'neutral') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'accent.blue-to-gray',
          borderColor: 'neutral.500',

          _hover: {
            bg: 'accent.hover',
            boxShadow: 'button-hovered',

            _disabled: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'card.bg.xlightblue',
            boxShadow: 'button-selected',
          },

          _disabled: {
            borderColor: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'negative') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'accent.red-to-gray',
          borderColor: 'negative.500',

          _hover: {
            bg: 'card.bg.lightred',
            boxShadow: 'button-hovered',

            _disabled: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'negative.200',
            boxShadow: 'button-selected',
          },

          _disabled: {
            borderColor: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'positive') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'accent.green-to-gray',
          borderColor: 'positive.500',

          _hover: {
            bg: 'card.bg.xlightgreen',
            boxShadow: 'button-hovered',

            _disabled: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'card.bg.darkgreen',
            boxShadow: 'button-selected',
          },

          _disabled: {
            borderColor: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'special') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'special.500',
          borderColor: 'special.500',

          _hover: {
            color: 'special.600',
            bg: 'special.50',
            boxShadow: 'button-hovered',

            _disabled: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          },

          _active: {
            bg: 'special.200',
            boxShadow: 'button-selected',
          },

          _disabled: {
            borderColor: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'highlight') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'accent.yellow-to-gray',
          borderColor: 'highlight.500',

          _hover: {
            bg: 'highlight.100',
            boxShadow: 'button-hovered',
            color: 'highlight.600',

            _disabled: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              color: 'ondark.6',
            },
          },

          _active: {
            bg: 'highlight.200',
            boxShadow: 'button-selected',
            color: 'accent.gold.200',
          },

          _disabled: {
            borderColor: 'grayweak.600',
            color: 'ondark.6',
          },
        }
      }
    },

    'ghost': ({ colorScheme }: StyleFunctionProps) => {
      if (colorScheme === 'neutral') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'neutral.500',

          _hover: {
            boxShadow: 'button-hovered',
            bg: 'accent.hover',
          },

          _active: {
            boxShadow: 'button-selected',
            bg: 'card.bg.xlightblue',
          },

          _disabled: {
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'ondark') {
        return {
          border: 0,
          bg: 'transparent',
          color: 'ondark.2',

          _hover: {
            boxShadow: 'button-hovered',
            bg: 'grayweak.900',
          },

          _active: {
            boxShadow: 'button-selected',
            bg: 'onlight.3',
          },

          _disabled: {
            color: 'grayweak.600',
          },
        }
      }
      if (colorScheme === 'negative') {
        return {
          border: 0,
          bg: 'transparent',
          color: 'accent.red-to-gray',

          _hover: {
            boxShadow: 'button-hovered',
            bg: 'card.bg.lightred',
          },

          _active: {
            boxShadow: 'button-selected',
            bg: 'negative.200',
          },

          _disabled: {
            color: 'grayweak.600',
          },
        }
      }
    },

    'muted': ({ colorScheme }: StyleFunctionProps) => {
      if (colorScheme === 'neutral') {
        return {
          border: 0,
          bg: 'transparent',
          color: 'grayweak.700',

          _hover: {
            boxShadow: 'button-hovered',
            bg: 'accent.hover',
          },

          _active: {
            boxShadow: 'button-selected',
            bg: 'card.bg.xlightblue',
          },

          _disabled: {
            color: 'ondark.6',
          },
        }
      }
    },

    'icon-ghost': ({ colorScheme }: StyleFunctionProps) => {
      if (colorScheme === 'ondark') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'ondark.1',

          _hover: {
            bg: 'highlight.500',
            color: 'onlight.1',

            _disabled: {
              color: 'ondark.6',
            },
          },

          _active: {},

          _disabled: {
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'onlight') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'onlight.1',

          _hover: {
            bg: 'card.bg.yellow',
            color: 'onlight.1',

            _disabled: {
              color: 'onlight.6',
            },
          },

          _active: {},

          _disabled: {
            color: 'onlight.6',
          },
        }
      }

      if (colorScheme === 'negative') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'negative.500',

          _hover: {
            bg: 'negative.100',
            color: 'negative.500',

            _disabled: {
              color: 'ondark.6',
            },
          },

          _active: {},

          _disabled: {
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'neutral') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'neutral.500',

          _hover: {
            bg: 'neutral.200',
            color: 'neutral.500',

            _disabled: {
              color: 'ondark.6',
            },
          },

          _active: {},

          _disabled: {
            color: 'ondark.6',
          },
        }
      }

      if (colorScheme === 'graystrong') {
        return {
          border: '', // override default border
          bg: 'transparent',
          color: 'graystrong.500',

          _hover: {
            bg: 'card.bg.yellow',
            color: 'onlight.1',

            _disabled: {
              color: 'ondark.6',
            },
          },

          _active: {},

          _disabled: {
            color: 'ondark.6',
          },
        }
      }
    },

    'icon-solid': ({ colorScheme }: StyleFunctionProps) => ({
      ...getRecKey(colorScheme)({
        ondark: {
          bg: 'ondark.1',
          color: 'onlight.1',
          borderColor: 'grayweak.500',

          _hover: {
            boxShadow: 'button-hovered',
            _disabled: {
              color: 'grayweak.600',
              bg: 'grayweak.400',
              boxShadow: 'none',
            },
          },
          _active: {
            boxShadow: 'button-hovered',
            bg: 'grayweak.500',
          },
          _disabled: {
            color: 'grayweak.600',
            bg: 'grayweak.400',
          },
        },
        positive: {
          bg: 'positive.500',
          color: 'ondark.1',
          borderColor: 'none',

          _hover: {
            boxShadow: 'button-hovered',
            _disabled: {
              color: 'grayweak.600',
              bg: 'grayweak.400',
              boxShadow: 'none',
            },
          },
          _active: {
            boxShadow: 'button-hovered',
            bg: 'grayweak.500',
          },
          _disabled: {
            color: 'grayweak.600',
            bg: 'grayweak.400',
          },
        },
        negative: {
          bg: 'negative.500',
          color: 'ondark.1',
          borderColor: 'grayweak.500',

          _hover: {
            boxShadow: 'button-hovered',
            _disabled: {
              color: 'grayweak.600',
              bg: 'grayweak.400',
              boxShadow: 'none',
            },
          },
          _active: {
            boxShadow: 'button-hovered',
            bg: 'grayweak.500',
          },
          _disabled: {
            color: 'grayweak.600',
            bg: 'grayweak.400',
          },
        },
      }),
    }),
  },

  defaultProps: {
    variant: 'ghost',
    size: 'md',
  },
}
