import { Button, Checkbox, Flex, MenuButton, MenuItemOption, MenuItemOptionProps, Text } from '@chakra-ui/react'
import { AscDescIcon, ChevronDownIcon } from 'presentation/components/Icons'
import { Menu, ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { CMAEntriesSortMenuValue as SortValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/components/CMAEntriesSortMenu/CMAEntriesSortMenuValue'
import { closeSortMenu, setSortValue, toggleSortMenu, toggleSortMenuGroupByStatus, useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useRef } from 'react'
import { useHoverDirty } from 'react-use'

export const CMAEntriesSortMenu = () => {
  const { isSortOpen, sortValue, isSortGroupedByStatus } = useEntriesToolbarMenu()
  const handleOnChange = () => {
    toggleSortMenuGroupByStatus()
  }
  return (
    <Menu
      closeOnSelect={false}
      closeOnBlur={true}
      isOpen={isSortOpen}
      onClose={() => closeSortMenu()}
    >
      <MenuButton
        as={Button}
        colorScheme='neutral'
        variant='solid'
        rightIcon={<ChevronDownIcon />}
        size={mbp({ mobSm: 'xs-narrow', mob: 'xs' })}
        onClick={() => toggleSortMenu()}
      >
        Sort
      </MenuButton>
      <ResponsiveMenuList menuListProps={{ width: '320px' }}>
        <Flex gap={1} alignItems='center' justifyContent='end' h={5}>
          <Text
            textStyle='bodySFat'
            color='graystrong.400'
          >
            Group by status
          </Text>
          <Checkbox isChecked={isSortGroupedByStatus} onChange={handleOnChange} />
        </Flex>
        {SortValue.toDefaultsArray().map(value => (
          <SortMenuItem
            key={value}
            defaultValue={value}
            selectedValue={sortValue}
            onChange={setSortValue}
          />
        ))}

      </ResponsiveMenuList>
    </Menu>
  )
}

type SortMenuItemProps = {
  defaultValue: SortValue
  selectedValue: SortValue
  onChange?: (value: SortValue) => void
}

const SortMenuItem = (props: SortMenuItemProps) => {
  const isSelected = SortValue.isSelected(
    props.defaultValue,
    props.selectedValue,
  )

  const value = isSelected
    ? props.selectedValue
    : props.defaultValue

  const Icon = SortValue.getIcon(value, isSelected)

  const label = SortValue.getLabel(value, isSelected)

  const handleOnClick = () => {
    const newValue = SortValue.getInverseValue(value)
    /**
     * If clicked and currently not the selected value,
     * use the default value. Otherwise, toggle the value
     */
    props.onChange?.(isSelected ? newValue : props.defaultValue)
  }

  return (
    <SortMenuItemBase
      isChecked={isSelected}
      icon={Icon ? <Icon boxSize={3} /> : undefined}
      onClick={handleOnClick}
    >
      {label}
    </SortMenuItemBase>
  )
}

const SortMenuItemBase = (props: MenuItemOptionProps) => {
  const ref = useRef(null)
  const isHovered = useHoverDirty(ref)
  const { icon, isChecked, children, ...rest } = props
  const defaultIcon = <AscDescIcon boxSize={3} />
  return (
    <MenuItemOption
      {...rest}
      ref={ref}
      isChecked={isChecked}
    >
      <Flex gap={1}>
        {isHovered && !isChecked ? defaultIcon : icon}
        <Text color='inherit' textStyle='bodyMFat'>
          {children}
        </Text>
      </Flex>
    </MenuItemOption>
  )
}
