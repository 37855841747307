import { Box, Button, Flex, HStack, Spacer } from '@chakra-ui/react'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon, ChevronDownIcon } from 'presentation/components/Icons'
import { PeekingDrawer, PeekingDrawerButton, PeekingDrawerContent } from 'presentation/components/PeekingDrawer/PeekingDrawer'
import { useCompsListPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CMADataUpdateButton } from 'presentation/screens/CompsScreen/components/CMADataUpdateMeta'
import { CMAEntriesViewMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar'
import { CMAPrintButton } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/CMAPrintButton'
import { CompsCount } from 'presentation/screens/CompsScreen/components/CompsCount/CompsCount'
import { DefaultFiltersDropdown } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/DefaultFiltersDropdown/DefaultFiltersDropdown'
import { openCMARentalsTab, openCMASalesTab, openCMASummaryTab, selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'
import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type CMAPanelBottomDrawerProps = PropsWithChildren

export const CMAPanelBottomDrawer = ({ children }: CMAPanelBottomDrawerProps) => {
  const { isPanelOpen, setPanelOpen } = useCompsListPanelStore()
  const handleClosePanel = () => {
    setPanelOpen(false)
  }
  return createPortal(
    <Box
      position='absolute'
      top='0'
      left='0'
      right='0'
      bottom='0'
      pointerEvents='none'
      zIndex='overlay'
    >
      <PeekingDrawer
        placement='bottom'
        isOpen={isPanelOpen}
        h='100%'
        onChange={setPanelOpen}
      >
        {!isPanelOpen
          ? (
            <DrawerPanelMetaButton />
          )
          : (
            <Flex
              bgColor='neutral.500'
              gap={1}
              py={1}
              px={1}
              pointerEvents='auto'
              justifyContent='space-between'
            >
              <Button
                leftIcon={<ArrowLeftIcon />}
                variant='solid'
                colorScheme='neutral'
                size='sm'
                alignSelf='center'
                onClick={handleClosePanel}
                textTransform='none'
                px={1}
                _active={{
                  px: 1,
                }}
              >
                Map
              </Button>
              <Spacer />
              <CMAEntriesViewMenu
                size='sm'
                variant='solid'
                colorScheme='highlight'
                px={2}
                rightIcon={(
                  <Breakpoint
                    mobSm={<></>}
                    mob={<ChevronDownIcon />}
                  />
                )}
              />
              <DefaultFiltersDropdown
                borderWidth={0}
                px={2}
                rightIcon={(
                  <Breakpoint
                    mobSm={<></>}
                    mob={<ChevronDownIcon />}
                  />
                )}
              />
              <Breakpoint
                mob={(
                  <CMAPrintButton
                    colorScheme='ondark'
                  />
                )}
              />
            </Flex>
          )}

        <PeekingDrawerContent>
          {children}
        </PeekingDrawerContent>
      </PeekingDrawer>
    </Box>,
    document.body,
  )
}

export const DrawerPanelMetaButton = () => {
  const activeTab = useActiveEntriesTab(selectActiveTab)
  const { openPanel } = useCompsListPanelStore()

  return (
    <PeekingDrawerButton>
      <BaseTabsContainer minH={0} flex='1 1 0' colorScheme='xweak' size='sm'>
        <BaseTabs alignItems='flex-end'>
          <BaseTab
            isActive={activeTab === 'SALES'}
            onClick={() => {
              openCMASalesTab()
              openPanel()
            }}
          >
            SALES
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'RENTALS'}
            onClick={() => {
              openCMARentalsTab()
              openPanel()
            }}
          >
            RENTALS
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'REPORT'}
            onClick={openCMASummaryTab}
          >
            REPORT
          </BaseTab>
        </BaseTabs>
        <BaseTabContent
          sx={{
            borderTopRightRadius: 0,
          }}
          p={2}
        >
          <HStack>
            <CompsCount color='ondark.6' />
            <Spacer />
            <CMADataUpdateButton />
          </HStack>
        </BaseTabContent>
      </BaseTabsContainer>
    </PeekingDrawerButton>
  )
}
