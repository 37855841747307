import { Match } from 'effect'
import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Lead } from 'features/PropertyDetails/infra/remote/getProperty/Lead.type'
import { getLead } from 'features/PropertyDetails/infra/remote/getProperty/getLead'
import { getLeadWithPropertyId } from 'features/PropertyDetails/infra/remote/getProperty/getLeadWithPropertyId'
import { getLeadWithAddress } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getLeadWithAddress'
import { getPropertyDetails } from 'features/PropertyDetails/infra/remote/getPropertyDetails/getPropertyDetails'
import { GoogleGeocoder } from 'libs/GoogleGeocoder'
import { ServerCache } from 'libs/ServerCache'
import { object } from 'libs/object'
import { pipe } from 'remeda'

export type GetProperty = (params: Domain.GetPropertyPayload) => Promise<Domain.GetPropertyResult>

const isResponseInsufficientConsumables = (response: Domain.InsufficientConsumables | Lead): response is Domain.InsufficientConsumables =>
  'status' in response && response.status === 'insufficient-consumables'

export const getProperty: GetProperty = async input => {
  const serializedInput = serializeGetPropertyPayload(input)

  const fromCache = serializedInput && ServerCache.get('getProperty')(serializedInput)

  if (fromCache) return fromCache as Domain.Property

  const lead = await pipe(
    Match.value(input),
    Match.when({ leadId: Match.string }, async ({ leadId }) =>
      await getLead(leadId)),
    Match.when({ parcelId: Match.string }, async ({ parcelId }) =>
      await getLeadWithPropertyId(parcelId)),
    Match.when({ addressString: Match.string }, async ({ addressString }) => {
      const address = await GoogleGeocoder.geocodeByAddressString(addressString)
      return await getLeadWithAddress(address)
    }),
    Match.exhaustive,
  )

  if (isResponseInsufficientConsumables(lead))
    return lead

  const property = object.has<Lead, 'parcelId'>('parcelId')(lead)
    ? await getPropertyDetails(lead)
    : lead.property

  // cache via leadId
  const serializedLeadIdParams = serializeGetPropertyPayload({ leadId: property.leadId })

  if (serializedLeadIdParams)
    ServerCache.set('getProperty')(serializedLeadIdParams)(property)

  // cache via parcelId
  const parcelId = property.status === 'with-details' ? property.parcelId : null
  const serializedParcelIdParams = parcelId && serializeGetPropertyPayload({ parcelId })

  if (serializedParcelIdParams)
    ServerCache.set('getProperty')(serializedParcelIdParams)(property)

  return property
}

export const serializeGetPropertyPayload = (payload: Domain.GetPropertyPayload): string | null =>
  pipe(
    Match.value(payload),
    Match.when({ leadId: Match.string }, ({ leadId }) => `leadId:${leadId}`),
    Match.when({ parcelId: Match.string }, ({ parcelId }) => `parcelId:${parcelId}`),

    // We will not cache by addressString because there's no easy way to
    // invalidate the cache when for example we edit the property address
    // (otherwise we'd be calling google service just to recache the same
    // address string and even that might not be reliable)
    Match.when({ addressString: Match.string }, () => null),

    Match.exhaustive,
  )
