import { ArrayFormatter, Schema as S } from '@effect/schema'
import { Effect, Either, pipe } from 'effect'
import { ToSkiptraceCount } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { IS_DEV_ENV } from 'presentation/const/env.const'

export namespace POSTGetToBatchSkiptraceCountSchema {
  // #region Schemas
  export const Self = S.Number

  export type Self = S.Schema.Type<typeof Self>

  // #endregion

  // #region decode
  export const decode = (data: any) => pipe(
    data,
    S.decodeUnknownEither(Self),
    Either.getOrThrowWith(error => {
      if (IS_DEV_ENV) {
        pipe(
          error,
          ArrayFormatter.formatError,
          // eslint-disable-next-line no-console
          Effect.tap(console.log),
          Effect.runSync,
        )
      }

      throw error as Error
    }),
  )
  // #endregion

  // #region toDomain
  export const toDomain = (resp: Self): ToSkiptraceCount =>
    ({
      count: resp,
    })
  // #endregion
}

export type POSTGetToBatchSkiptraceCountSchema = POSTGetToBatchSkiptraceCountSchema.Self
