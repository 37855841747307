import { Box, Center, DrawerContent, Flex, HStack, Icon, IconButton, IconProps, Text } from '@chakra-ui/react'
import { ArrowLeftIcon, ListIcon, MenuIcon, SearchIcon, SkiptraceSuccessIcon, UpwardTrendIcon, UserIcon } from 'presentation/components/Icons'
import { usePropertyDrawerContentState } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/usePropertyDrawerContent'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ComponentType, FC } from 'react'
import { NavLink } from 'react-router-dom'

export const PropertyDrawerContent = (props: {
  onBackToMainMenu: () => void
  onSelect: () => void
}) => {
  const leadId = useLeadId()
  const scenario = usePropertyDrawerContentState(state => state.scenario)

  return (
    <DrawerContent maxW='232px' bg='graycool.200'>
      <Box>
        {scenario.type !== 'from-other-modules' && (
          <HStack pt={mbp({ mobSm: 3, tabSm: 5 })} px='2' alignItems='center'>
            <IconButton
              aria-label='Back to Main Menu'
              icon={<ArrowLeftIcon />}
              variant='icon-ghost'
              colorScheme='graystrong'
              size='md'
              onClick={props.onBackToMainMenu}
            />
            <Text textStyle='bodyLFat' color='graystrong.500'>Back to Main Menu</Text>
          </HStack>
        )}

        <Flex pr='2' py='2.5' flexDir='column'>
          {scenario.type === 'from-other-modules' && (
            <>
              <Box
                as='button'
                onClick={() => {
                  props.onSelect()
                  scenario.goBackToOriginButton.onClick()
                }}
              >
                <SidebarItemBase
                  isActive={false}
                  icon={ArrowLeftIcon}
                  title={scenario.goBackToOriginButton.label}
                />
              </Box>
              <Box
                as='button'
                onClick={props.onBackToMainMenu}
              >
                <SidebarItemBase
                  isActive={false}
                  icon={MenuIcon}
                  title='Open Main Menu'
                />
              </Box>
            </>
          )}
          <SidebarItem onClick={props.onSelect} to='/search' icon={SearchIcon} title='Search History' end />
          <SidebarItem onClick={props.onSelect} to={`/search/${leadId || ''}`} icon={ListIcon} title='Property Details' end />
          <SidebarItem onClick={props.onSelect} to={`/search/${leadId || ''}/comps`} icon={UpwardTrendIcon} title='Comparables Sales' end />
          <SidebarItem onClick={props.onSelect} to={`/search/${leadId || ''}/skiptrace`} icon={SkiptraceSuccessIcon} title='Skiptrace' end />
          <SidebarItem onClick={props.onSelect} to={`/search/${leadId || ''}/cash-buyers`} icon={UserIcon} title='Cash Buyers' end />
        </Flex>
      </Box>
    </DrawerContent>
  )
}

const SidebarItem: FC<{
  icon: ComponentType<IconProps>
  title: string
  to: string
  onClick?: () => void
  end?: boolean
}> = props => (
  <NavLink to={props.to} onClick={props.onClick} end={props.end}>
    {({ isActive }) => (
      <SidebarItemBase
        isActive={isActive}
        icon={props.icon}
        title={props.title}
      />
    )}
  </NavLink>
)

const SidebarItemBase = ({
  isActive,
  icon,
  title,
}: {
  isActive: boolean
  icon: ComponentType<IconProps>
  title: string
}) => (
  <Flex
    alignItems='center'
    pl='2.5'
    my='0.5'
    py='1'
    borderRightRadius='full'
    bg={isActive ? 'accent.lightpurple-to-gray' : 'transparent'}
    _hover={{ bg: 'accent.lightpurple-to-gray' }}
    transitionProperty='background'
    transitionDuration='0.2s'
    role='group'
    title={title}
  >
    <Center boxSize='3'>
      <Icon
        as={icon}
        fontSize={3}
        color={isActive ? 'accent.purple-to-gray' : 'graystrong.200'}
        _groupHover={{ color: 'accent.purple-to-gray' }}
      />
    </Center>
    <Text
      textStyle='panel-title'
      color={isActive ? 'accent.purple-to-gray' : 'graystrong.200'}
      _groupHover={{ color: 'accent.purple-to-gray' }}
      ml='1'
      noOfLines={1}
    >
      {title}
    </Text>
  </Flex>
)
