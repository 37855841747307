import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { NumberLib } from 'libs/Number'
import { ServerCache } from 'libs/ServerCache'
import { StringLib } from 'libs/String'
import { restClient } from 'presentation/libs/client'
import QueryString from 'qs'

const cacheId = 'PropertyDetails:getPropertySuggestions'

export const getPropertySuggestions = async ({
  addressString,
  type,
}: {
  addressString: string
  type: Domain.PropertySuggestionType
}): Promise<Domain.PropertySuggestion[]> => {
  const paramsSerialized = [type, addressString].join('|')
  const fromCache = ServerCache.get(cacheId)(paramsSerialized)

  if (fromCache) return (fromCache as any).response as Domain.PropertySuggestion[]

  const response = await restClient.get(`parcels/v1/parcels/suggest/${type}`, {
    searchParams: QueryString.stringify({
      query: addressString,
    }),
  })
    .json()
    .then((data: any) =>
      (data?.items || [])
        .map((item: any) => ({
          parcelId: StringLib.ensureOr('')(item.id),
          addressString: StringLib.ensureOr('')(item.address),
          score: NumberLib.ensureOr(0)(item.score),
        })))

  ServerCache.set(cacheId)(paramsSerialized)({ response })

  return response
}
