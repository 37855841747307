import { Box, CardProps, useToken } from '@chakra-ui/react'
import { Card } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, ReactNode, useRef, useState } from 'react'
import { useHoverDirty } from 'react-use'

export type ContainerProps = Omit<CardProps, 'children'> & {
  isMuted?: boolean
  children?: ReactNode | ((props: {
    isHovered?: boolean
    isMenuOpen: boolean
    onMenuOpen: () => void
    onMenuClose: () => void
  }) => ReactNode)
}

export const Container: FC<ContainerProps> = ({
  children,
  isMuted,
  ...cardProps
}) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const isHovered = useHoverDirty(cardRef)
  const shadowColor = useToken('colors', 'specialsat.500')
  const shouldMuteCard = isMuted && !isHovered
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onMenuOpen = () => setIsMenuOpen(true)
  const onMenuClose = () => setIsMenuOpen(false)

  return (
    <Card
      ref={cardRef}
      positon='relative'
      size={mbp({ mobSm: 'xs-locked', mob: 'sm-locked' })}
      variant='bordered'
      shadow='primary.w'
      overflow={isMuted ? 'hidden' : 'initial'} // for the overlay

      /**
       * We need to set the zIndex for menu to not get clipped. Seems like
       * animation CSS is doing something to cause the menu to be clipped
       */
      zIndex={isMenuOpen ? 'overlay' : 'initial'}
      {...isHovered && { borderColor: shadowColor }}
      {...cardProps}
    >
      {typeof children === 'function'
        ? children({
          isHovered,
          isMenuOpen,
          onMenuOpen,
          onMenuClose,
        })
        : children}
      {shouldMuteCard && <MutedEffect />}
    </Card>
  )
}

export const MutedEffect: FC = () => (
  <Box
    position='absolute'
    top='0'
    right='0'
    left='0'
    bottom='0'
    bg='grayweak.100'
    opacity='0.7'
  />
)
