import { useEffect } from 'react'
import { useMeasure } from 'react-use'
import { createStore, useStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export const createWidthSync = <TKey extends string>() => {
  type WidthSyncState = {
    widthByKey: Record<string, number | null>
  }

  const INITIAL_STATE: WidthSyncState = {
    widthByKey: {},
  }

  const api = {
    store: createStore<WidthSyncState>()(immer(() => INITIAL_STATE)),

    setWidth: (key: TKey, width: number | null) => {
      api.store.setState(state => {
        state.widthByKey[key] = width
      })
    },

    useWidthTracker: (
      key: TKey,
      options?: {
        isDisabled?: boolean
      }) => {
      const [ref, { width }] = useMeasure()
      useEffect(() => {
        if (options?.isDisabled) return

        api.setWidth(key, width)
        return () => api.setWidth(key, null)
      }, [key, width])
      return ref
    },

    useWidth: (key: TKey) =>
      useStore(api.store, state => state.widthByKey[key] ?? null),
  }

  return api
}
