import { useLazyQuery } from '@apollo/client'
import { CQ } from 'libs/commandQuery/commandQuery'
import { CQReact } from 'libs/commandQuery/commandQuery.react'
import { ErrorLib, ProgramException } from 'libs/errors'
import { InviteMembersCommand, InviteMembersError, InviteMembersPayload } from 'presentation/screens/MembersScreen/MembersScreen.domain'
import { PartialInviteMembersError } from 'presentation/screens/MembersScreen/MembersScreen.errors'
import * as single from 'presentation/screens/MembersScreen/useCases/command/InviteMembersCommand/private/InviteMemberComand'
import { MembersScreenQuery } from 'presentation/screens/MembersScreen/useCases/query/MembersInfoQuery/MembersInfoQuery'
import { useCallback } from 'react'

export const useInviteMembersCommand = (): InviteMembersCommand => {
  const { state, transition } = CQReact.useStateManager<InviteMembersError, InviteMembersPayload>(
    CQ.createIdleState,
  )

  const { regenerateId } = CQReact.useRegeneratableId()

  const inviteCommand = single.useInviteMemberCommand()
  const [refetchQuery] = useLazyQuery(MembersScreenQuery, {
    fetchPolicy: 'network-only', // Force refetch because invite makes data stale
  })

  const execute = useCallback<InviteMembersCommand['execute']>(async payload => {
    let remainingEmails: string[] = []
    const commandId = regenerateId()

    try {
      transition.loading({ id: commandId, payload })

      const { emails } = payload

      const promises = emails.map(async email => await inviteCommand.execute({ email }))
      const results = await Promise.all(promises)
      const errorResults = results.filter(CQ.isError)
      remainingEmails = results.reduce<string[]>((acc, result, i) => {
        if (result.status === 'success') return acc
        return [...acc, emails[i]]
      }, [])

      if (errorResults.length) {
        const errors = errorResults.map(r => r.error)
        throw new PartialInviteMembersError('', { emails: errors }, remainingEmails)
      }

      await refetchQuery()
      transition.success({ id: commandId, payload })
    } catch (rawErr) {
      const isExpectedError = rawErr instanceof PartialInviteMembersError

      if (!isExpectedError)
        void ErrorLib.report(rawErr)

      const error = isExpectedError
        ? rawErr
        : new ProgramException('An error occurred while inviting this email')

      transition.error({ id: commandId, error, payload })
    }
  }, [])

  return {
    execute,
    state,
  }
}
