import { createIcon } from '@chakra-ui/react'

export const ModalCloseIcon = createIcon({
  displayName: 'ModalCloseIcon',
  viewBox: '0 0 14 14',
  path: (
    <g fill='currentColor'>
      <path d='M3.7555925,0.6443925 C2.8964925,-0.2147975 1.5034925,-0.2147975 0.6443925,0.6443925 C-0.2147975,1.5034925 -0.2147975,2.8964925 0.6443925,3.7555925 L3.8886925,7 L0.6443925,10.2443925 C-0.2147975,11.1034925 -0.2147975,12.4964925 0.6443925,13.3555925 C1.5034925,14.2147925 2.8964925,14.2147925 3.7555925,13.3555925 L7,10.1112925 L10.2443925,13.3555925 C11.1034925,14.2147925 12.4964925,14.2147925 13.3555925,13.3555925 C14.2147925,12.4964925 14.2147925,11.1034925 13.3555925,10.2443925 L10.1112925,7 L13.3555925,3.7555925 C14.2147925,2.8964925 14.2147925,1.5034925 13.3555925,0.6443925 C12.4964925,-0.2147975 11.1034925,-0.2147975 10.2443925,0.6443925 L7,3.8886925 L3.7555925,0.6443925 Z' />
    </g>
  ),
})
