import { Button, CardProps, Flex, FormControl, FormErrorMessage, FormLabel, Input, Link, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { PaperPlaneIcon } from 'presentation/components/Icons'
import { PreserveQueryLink } from 'presentation/components/PreserveQueryLink/PreserveQueryLink'
import { toast } from 'presentation/components/Toast'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { useOneToastAtATime } from 'presentation/libs/useOneToastAtATime'
import { FormCard } from 'presentation/screens/AuthScreens/components/FormCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { pipe } from 'remeda'

type FormValues = {
  email: string
}

export const SendResetPasswordEmailCard = (props: CardProps) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectUrl = queryParams.get('redirect')

  const domain = useAccessStore(store => {
    const sendResetPasswordEmail = store.actions.sendResetPasswordEmail
    const clearErrors = store.actions.clearErrors

    return {
      sendResetPasswordEmail: sendResetPasswordEmail.execute,
      clearErrors: clearErrors.execute,
      isSubmitting: sendResetPasswordEmail.state.status === 'loading',
      didSucceed: sendResetPasswordEmail.state.status === 'success',
      errorMsg: pipe(
        Match.value(sendResetPasswordEmail.state),
        Match.when({ error: Match.instanceOf(Error) }, ({ error }) => error.message),
        Match.orElse(() => null),
      ),
    }
  })

  const form = useForm<FormValues>()

  useOneToastAtATime(() => {
    if (!domain.errorMsg) return null

    domain.clearErrors()

    return {
      status: 'error',
      title: 'Send Reset Password Email failed',
      message: domain.errorMsg,
    }
  }, [domain.errorMsg])

  useEffect(() => {
    if (domain.didSucceed) {
      toast.success({
        title: 'Password Reset Link Sent!',
        message: 'Check your email for a link to reset your password.',
      })
    }
  }, [domain.didSucceed])

  return (
    <FormCard
      as='form'
      onSubmit={form.handleSubmit(formValues => {
        void domain.sendResetPasswordEmail({
          email: formValues.email,
          redirectUrl: redirectUrl || null,
        })
      })}
      {...props}
    >
      <CardPrimaryTitle>
        Forgot Password?
      </CardPrimaryTitle>
      <Flex mt={3} flexDirection='column' gap={3}>
        <CardPrimaryText shouldResetSpacing>
          Enter your email address and we&rsquo;ll send you a link to reset your password.
        </CardPrimaryText>
        <FormControl isInvalid={!!form.formState.errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            type='email'
            placeholder='Enter Email'
            {...form.register('email', {
              required: 'This field is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Please provide valid email address',
              },
            })}
          />
          {form.formState.errors.email && (
            <FormErrorMessage>
              {form.formState.errors.email.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>

      <Button
        mt={mbp({ mobSm: 'auto', mob: '5' })}
        variant='solid'
        colorScheme='positive'
        leftIcon={<PaperPlaneIcon boxSize={3} />}
        isLoading={domain.isSubmitting}
        type='submit'
      >
        Send
      </Button>

      <Text
        mt={3}
        color='graystrong.400'
        textAlign='center'
      >
        Know your password?
        &nbsp;
        <Link as={PreserveQueryLink} color='link' to='/login' textDecoration='underline'>Sign In</Link>
      </Text>
    </FormCard>
  )
}
