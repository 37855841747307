import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { COMPS_BOUNDARY_APPLY_ID } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.const'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const CompsBoundaryBar = () => {
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)
  const drawnLines = CompsBoundary.useStore(state => state.lines)

  return (
    <Stack
      direction={mbp({ mobSm: 'column', tabSm: 'row' })}
      bg='card.bg.yellow'
      px={mbp({ mobSm: 2, tabSm: 3 })}
      py={mbp({ mobSm: 2, tabSm: 1 })}
      spacing={2}
      align={mbp({ mobSm: 'stretch', tabSm: 'center' })}
    >
      <Text textStyle={mbp({ mobSm: 'bodyMFat', tabSm: 'bodyLFat' })} flex={1}>
        Draw one or more boundaries on the map to filter within that area.
      </Text>
      <HStack spacing={2}>
        <Button
          colorScheme='negative'
          size={mbp({ mobSm: 'sm', tabSm: 'lgv2' })}
          variant='outline'
          onClick={() => {
            CompsBoundary.setStatus('idle')
          }}
          flex={mbp({ mobSm: 1, tabSm: undefined })}
        >
          Cancel
        </Button>
        <Button
          id={COMPS_BOUNDARY_APPLY_ID}
          colorScheme='positive'
          size={mbp({ mobSm: 'sm', tabSm: 'lgv2' })}
          variant='solid'
          onClick={() => {
            const mappedLines = drawnLines.map(line =>
              line.coordinates.map(point =>
                ({ latitude: point[1], longitude: point[0] })))
            void filterComps({ boundary: { lines: mappedLines } })
            CompsBoundary.setStatus('idle')
          }}
          flex={mbp({ mobSm: 1, tabSm: undefined })}
        >
          Apply
        </Button>
      </HStack>
    </Stack>
  )
}
