import { Array, Effect, Match, Number, Option, RequestResolver, String, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { FallbackPropertyListDataId, PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMember } from 'features/ListBuilder/domain/MarketingListMember'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import { PropertyListMember } from 'features/ListBuilder/domain/MarketingListMember/PropertyListMember'
import MarketingListMemberRepo from 'features/ListBuilder/domain/repository/MarketingListMemberRepo'
import { GETMembersByPageSchema } from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/GetMembersByPageResolver/GETMembersByPageSchema'
import MarketingListMemberRequest from 'features/ListBuilder/infra/repo/MarketingListMemberRepoLive/MarketingListMemberRequest'
import MarketingListMemberSchema from 'features/ListBuilder/infra/repo/schema/MarketingListMemberSchema'
import { GETDebouncedListMembers } from 'features/common/infra/GETDebouncedListMembers'
import { Location } from 'features/valueObjects/Location'
import ParcelId from 'features/valueObjects/ParcelId'
import { HTTPError } from 'ky'
import { Acres } from 'libs/Acres'
import { EffectLib } from 'libs/Effect'
import { NumberLib } from 'libs/Number'

const GetMembersByPageResolver = RequestResolver.fromEffect(
  (request: MarketingListMemberRequest.GetByPage) => {
    const LIMIT = 50
    const currentPage = Math.max(request.page, 1)
    const offset = (currentPage - 1) * LIMIT
    return pipe(
      Effect.tryPromise({
        try: async () =>
          await GETDebouncedListMembers.request(request.listId, offset, LIMIT),
        catch: error => {
          if (error instanceof HTTPError && error.response.status === 404)
            return new ListNotFoundError({ marketingListId: request.listId })

          return new UnknownException(error)
        },
      }),
      Effect.map(raw => decode(raw)),
      Effect.map(decoded => toDomain({ countPerPage: LIMIT, currentPage }, decoded)),
      Effect.catchTag('UnknownException', v => Effect.die(v)),
    )
  },
)

export default GetMembersByPageResolver

const decode = EffectLib.makeLoggedDecoder({
  schema: GETMembersByPageSchema,
  name: 'GETFetchMembersByPageResponse',
})

type PageMeta = {
  countPerPage: number
  currentPage: number
}

export const toDomain = (
  pageMeta: PageMeta,
  schema: GETMembersByPageSchema,
): MarketingListMemberRepo.GetByPageResult => ({
  totalCount: schema.count ?? 0,
  countPerPage: pageMeta.countPerPage,
  currentPage: pageMeta.currentPage,
  members: schema.items.map(item => mapToPropertyListMember(item)),
})

const mapToPropertyListMember = (schema: MarketingListMemberSchema): PropertyListMember => {
  const member = MarketingListMember.PropertyList({
    id: MarketingListMemberId.make(Number.isNumber(schema.id) ? schema.id.toString() : schema.id),
    listId: MarketingListId.make(schema.listId),
    propertyData: PropertyListData.make({
      // #region App Info
      id: schema.meta.property.pipe(
        Option.flatMap(property => property.id),
        Option.map(id => Number.isNumber(id) ? id.toString() : id),
        Option.map(PropertyListDataId.from),
        Option.getOrElse(() => FallbackPropertyListDataId.make()),
      ),
      parcelId: schema.meta.property.pipe(
        Option.flatMap(property => property.id),
        Option.map(id => id.toString()),
        Option.map(ParcelId.fromString),
        Option.getOrElse(() => null),
      ),
      // #endregion

      // #region Address Info
      address: schema.meta.property.pipe(
        Option.map(property => ({
          line1: property.address.pipe(
            Option.flatMap(address => address.line1),
            Option.getOrNull,
          ),
          city: property.address.pipe(
            Option.flatMap(address => address.city),
            Option.getOrNull,
          ),
          state: property.address.pipe(
            Option.flatMap(address => address.state),
            Option.getOrNull,
          ),
          postalCode: property.address.pipe(
            Option.flatMap(address => address.zip),
            Option.getOrNull,
          ),
          subdivision: null,
        })),
        Option.getOrElse(() => ({
          line1: null,
          city: null,
          state: null,
          postalCode: null,
          subdivision: null,
        })),
      ),
      location: schema.meta.property.pipe(
        Option.flatMap(property => property.address),
        Option.flatMap(address => Option.zipWith(
          address.lat,
          address.lon,
          (lat, lon) => Location.makeSafe(
            String.isString(lat)
              ? NumberLib.fromStringSafe(lat)
              : lat,
            String.isString(lon)
              ? NumberLib.fromStringSafe(lon)
              : lon,
          ),
        )),
        Option.getOrNull,
      ),
      // #endregion

      // #region Value Info
      lastSoldDate: schema.meta.lastSaleDate.pipe(Option.getOrNull),
      estimatedValue: schema.meta.value.pipe(Option.getOrNull),
      estimatedEquity: schema.meta.equity.pipe(Option.getOrNull),
      listPrice: null,
      salePrice: schema.meta.lastSalePrice.pipe(Option.getOrNull),
      // #endregion

      // #region Property Info
      useType: schema.meta.propertyUseType.pipe(Option.getOrNull),
      // #endregion

      // #region Stats
      bedroomsCount: schema.meta.property.pipe(
        Option.flatMap(property => property.beds),
        Option.getOrNull,
      ),
      bathroomsCountInfo: {
        full: schema.meta.property.pipe(
          Option.flatMap(property => property.baths_full),
          Option.getOrNull,
        ),
        half: schema.meta.property.pipe(
          Option.flatMap(property => property.baths_half),
          Option.getOrNull,
        ),
        total: schema.meta.property.pipe(
          Option.flatMap(property => pipe(
            Match.value([
              property.baths_full,
              property.baths_half,
            ]),
            Match.when([Option.isSome, Option.isNone], ([full, _]) => Option.some(`${full}`)),
            Match.when([Option.isNone, Option.isSome], ([_, half]) => Option.some(`.${half}`)),
            Match.when([Option.isSome, Option.isSome], ([full, half]) => Option.some(`${full}.${half}`)),
            Match.orElse(() => Option.none()),
          )),
          Option.getOrNull,
        ),
      },
      garageSpacesCount: schema.meta.property.pipe(
        Option.flatMap(property => property.garageSpaces),
        Option.getOrNull,
      ),
      buildingAreaSqft: null,
      livingAreaSqft: schema.meta.property.pipe(
        Option.flatMap(property => property.sqft),
        Option.map(sqft => String.isString(sqft)
          ? NumberLib.fromStringSafe(sqft)
          : sqft),
        Option.getOrNull,
      ),
      lotAreaSqft: schema.meta.lotSqft.pipe(Option.getOrNull),
      lotAreaAcres: schema.meta.lotSqft.pipe(
        Option.map(sqft => Acres.fromSqft(sqft)),
        Option.getOrNull,
      ),
      yearBuilt: schema.meta.property.pipe(
        Option.flatMap(property => property.year_built),
        Option.getOrNull,
      ),
      // #endregion

      // #region Owner/Value Info
      ownerName: schema.contact.pipe(
        Option.map(contact => pipe(
          [contact.firstName, contact.lastName],
          Array.getSomes,
          Array.join(' '),
        )),
        Option.getOrNull,
      ),
      ownerAddress: schema.contact.pipe(
        Option.flatMap(contact => contact.address),
        Option.map(address => ({
          line1: address.line1.pipe(Option.getOrNull),
          city: address.city.pipe(Option.getOrNull),
          state: address.state.pipe(Option.getOrNull),
          postalCode: address.zip.pipe(Option.getOrNull),
          subdivision: null,
        })),
        Option.getOrElse(() => ({
          line1: null,
          city: null,
          state: null,
          postalCode: null,
          subdivision: null,
        })),
      ),
      photos: [], // @NOTE: Not part of design
      // #endregion

      // #region Owner/Value Info
      classification: null, // @NOTE: not part of design
      equityType: null, // @NOTE: not part of design
      isVacant: schema.meta.isVacant.pipe(Option.getOrNull),
      isForeclosure: schema.meta.isForeclosure.pipe(Option.getOrNull),
      isSenior: schema.meta.isSenior.pipe(Option.getOrNull),
      isHomestead: schema.meta.isHomestead.pipe(Option.getOrNull),
      isTaxDelinquent: schema.meta.isTaxDelinquent.pipe(Option.getOrNull),
      // #endregion

      // #region MLS Info
      mlsStatus: null,
      // #endregion
    }),
  })

  return member
}
