import { IS_DEV_ENV } from './env.const'

export const GOOGLE_KEY = IS_DEV_ENV ? 'AIzaSyB0oLbG833bk1haXpHPpbYRt-jMGVxByxI' : 'AIzaSyCi3feSdTerouRXNDTvioVli8bpZ40M6vY'
export const GOOGLE_SIGNATURE = 'zNIFJxOAJfwWe23dVwBAYxqQq6k='
export const MAPBOX_KEY = 'pk.eyJ1IjoicHJvcGVsaW8td2ViIiwiYSI6ImNrbnFlNmVtdTAwNTkybm41Nng5MHJsNW0ifQ.jVxqdwqSS-qhJa74zMwnYA'

/** @TODO improve constant naming after understanding the purpose */
export const MAPBOX_STREET_STYLE_A = 'mapbox://styles/propelio-web/ckpwyhe8a081d17l9kcr1zdd6?optimize=true'
export const MAPBOX_SATTELITE_STYLE = 'mapbox://styles/mapbox/satellite-v9'
export const MAPBOX_STYLE_B = 'mapbox://styles/propelio-web/ckpx293np4kqe17nxdwqzoz8y?optimize=true'
