import { Data, Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import Buyer from 'features/NearbyBuyers/domain/Buyer'
import AppCache from 'services/AppCache'

type GetBuyerDeals = (params: GetBuyerDeals.Params) => GetBuyerDeals.ResultEffect

namespace GetBuyerDeals {
  export type Params = {
    id: string
  }

  export type Result = Data.TaggedEnum<{
    Success: Pick<Buyer.BuyerHistory, 'deals' | 'dealsGeographicInfo'>
  }>

  export type Error = UnknownException

  export type ResultEffect = Effect.Effect<Result, Error, AppCache>

  export const Result = Data.taggedEnum<Result>()
}

export default GetBuyerDeals
