import { Effect } from 'effect'
import { UnknownException } from 'effect/Cause'
import GetOwnerDeals from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals'
import GET_OWNERS_DEALS from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals.graphql'
import GetOwnerDealsSchema from 'features/OwnersProperties/domain/repo/GetOwnerDeals/GetOwnerDeals.schema'
import { apolloClient } from 'presentation/libs/client'

const GetOwnerDealsLive: GetOwnerDeals = ({
  parcelContactId,
}) =>
  Effect.tryPromise({
    try: () =>
      apolloClient.query({
        errorPolicy: 'all',
        query: GET_OWNERS_DEALS,
        variables: {
          parcelContactId,
        },
      })
        .then(response => GetOwnerDealsSchema.resultFromGraphQL(response.data)),
    catch: error => new UnknownException(error),
  })

export default GetOwnerDealsLive
