import { createIcon } from '@chakra-ui/react'

export const TableIcon = createIcon({
  displayName: 'TableIcon',
  viewBox: '0 0 20 20',
  path: (
    <g fill='none'>
      <path d='M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 7.5H17.5' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 17.5V7.5' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5 12.5L2.5 12.5' stroke='currentColor' strokeWidth='1.75' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
