import { Box, ButtonProps, Center, Flex, Grid, GridItem, HStack, Image, Spinner, Text, VStack } from '@chakra-ui/react'
import { Photo } from 'features/valueObjects/Photo'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { CircleInfoIcon2, FullscreenIcon } from 'presentation/components/Icons'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { CompsMLSInfoCardProps } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/components/CompsMLSInfoCard/CompsMLSInfoCard.props'
import { getCompCardColors } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/utils/getCompCardColors'
import { CollapsibleContent } from 'presentation/screens/PropertyDetailsScreen/components/ConditionSection/CollapsibleContent'
import { NoPhoto } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/MLSPhotos'
import { SliderMaximizedModal } from 'presentation/screens/PropertyDetailsScreen/components/VisualsSection/components/MLSPhotos/SliderMaximizedModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const CompsMLSInfoCard = ({
  entry,
  ...boxProps
}: CompsMLSInfoCardProps) => {
  const { sbp } = useSwitchBreakpointFn()

  const maxLineLimit = sbp({
    mobSm: 5,
    mob: 6,
  })

  const cardColors = getCompCardColors(entry.status)

  const isOffMarket = entry.status === 'OFF_MARKET'

  return (
    <Box
      borderColor={cardColors.cardBorderColor}
      bgColor={cardColors.cardLowerBgColor}
      borderRadius={3}
      borderWidth={0.25}
      h='min-content'
      p={2}
      {...boxProps}
    >
      {/** Content */}

      <Flex h={5} gap={2} justifyContent='space-between'>
        <Text
          textStyle='bodyLFat'
          color={mbp({
            mobSm: 'graystrong.400',
            mob: isOffMarket ? cardColors.cardTextColor : 'accent.blue-to-gray',
          })}
        >
          Agent&rsquo;s Description
        </Text>

      </Flex>

      <Box
        color={mbp({
          mobSm: 'graystrong.200',
          mob: cardColors.cardTextColor,
        })}
        textStyle={mbp({ mobSm: 'bodyM', mob: 'bodyL' })}
      >
        {entry.description
          ? (
            <CollapsibleContent
              maxLineLimit={maxLineLimit}
            >
              {entry.description}
            </CollapsibleContent>
          )
          : '--'}
      </Box>

      <Gallery photos={entry.photos} />

      <Breakpoint
        mobSm={(
          <VStack spacing={0.5} align='stretch'>
            <HStack
              mt={2}
              align='center'
              spacing={1}
            >
              <Text
                textStyle='bodyLFat'
                color={isOffMarket ? cardColors.cardTextColor : 'graystrong.500'}
                flex='1'
              >
                Listing Office
                <Tooltip label="Seller's Agent" placement='bottom' hasArrow>
                  <Box as='span' display='inline-block' ml={0.5}>
                    <CircleInfoIcon2 boxSize={2.5} color='accent.pink' />
                  </Box>
                </Tooltip>
              </Text>
              <Image
                h='4'
                src={entry.organization.logo}
                alt={entry.organization.name}
              />
            </HStack>

            <HStack>
              <Text
                textStyle='bodyMFat'
                color='neutral.500'
              >
                Source:
              </Text>
              <Text
                textStyle='bodyM'
                textTransform='uppercase'
                color='graystrong.200'
                flex='1'
              >
                {entry.organization.name ? entry.organization.name : '--'}
              </Text>
            </HStack>
          </VStack>
        )}
        tabSm={(
          <HStack
            mt={2}
            align='center'
            spacing={1}
          >
            <Text
              textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat' })}
              color={isOffMarket ? cardColors.cardTextColor : 'graystrong.500'}
            >
              Listing Office
              <Tooltip label="Seller's Agent" placement='bottom' hasArrow>
                <Box as='span' display='inline-block' ml={0.5}>
                  <CircleInfoIcon2 boxSize={2.5} color='accent.pink' />
                </Box>
              </Tooltip>
            </Text>
            <Text
              ml={1}
              textStyle='bodyMFat'
              color='neutral.500'
            >
              Source:
            </Text>
            <Text
              textStyle='bodyM'
              textTransform='uppercase'
              color='graystrong.200'
              flex='1'
            >
              {entry.organization.name ? entry.organization.name : '--'}
            </Text>

            <Image
              h='4'
              src={entry.organization.logo}
              alt={entry.organization.name}
            />
          </HStack>
        )}
      />

      <Grid
        mt={mbp({
          mobSm: 2,
          tabSm: 1,
        })}
        gridTemplateColumns='repeat(2, 1fr)'
        columnGap={1}
        rowGap={2}
      >
        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>Agent Name</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.agent.name ?? '--'}
          </PairValue>
        </Pair>

        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>Broker Name</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.broker.name ?? '--'}
          </PairValue>
        </Pair>

        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>Agent Phone No.</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.agent.phone ?? '--'}
          </PairValue>
        </Pair>

        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>Broker Phone No.</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.broker.phone ?? '--'}
          </PairValue>
        </Pair>

        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>Agent Email</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.agent.email ?? '--'}
          </PairValue>
        </Pair>

        <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
          <PairKey>MLS #</PairKey>
          <PairValue
            wordBreak='break-all'
          >
            {entry.listingId}
          </PairValue>
        </Pair>
      </Grid>

      {/* Buyer Agent/Office Information - Only show for SOLD or LEASED listings */}
      {(entry.status === 'SOLD' || entry.status === 'LEASED') && entry.buyerAgent && (
        <>
          {/* Divider between seller and buyer agent info */}
          <Box
            borderTopWidth='1px'
            borderTopColor='grayweak.500'
            my={4}
          />

          <Breakpoint
            mobSm={(
              <VStack spacing={0.5} align='stretch' mt={4}>
                <HStack align='center' spacing={1}>
                  <Text
                    textStyle='bodyLFat'
                    color={isOffMarket ? cardColors.cardTextColor : 'graystrong.500'}
                    flex='1'
                  >
                    Buyer&apos;s Agent
                  </Text>
                </HStack>
              </VStack>
            )}
            tabSm={(
              <HStack mt={4} align='center' spacing={1}>
                <Text
                  textStyle={mbp({ mobSm: 'bodyMFat', mob: 'bodyLFat' })}
                  color={isOffMarket ? cardColors.cardTextColor : 'graystrong.500'}
                >
                  Buyer&apos;s Agent
                </Text>
              </HStack>
            )}
          />

          <Grid
            mt={mbp({
              mobSm: 2,
              tabSm: 1,
            })}
            gridTemplateColumns='repeat(2, 1fr)'
            columnGap={1}
            rowGap={2}
          >
            <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
              <PairKey>Agent Name</PairKey>
              <PairValue
                wordBreak='break-all'
              >
                {entry.buyerAgent.name ?? '--'}
              </PairValue>
            </Pair>

            <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
              <PairKey>Broker Name</PairKey>
              <PairValue
                wordBreak='break-all'
              >
                {entry.buyerBroker?.name ?? '--'}
              </PairValue>
            </Pair>

            <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
              <PairKey>Agent Phone No.</PairKey>
              <PairValue
                wordBreak='break-all'
              >
                {entry.buyerAgent.phone ?? '--'}
              </PairValue>
            </Pair>

            <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
              <PairKey>Broker Phone No.</PairKey>
              <PairValue
                wordBreak='break-all'
              >
                {entry.buyerBroker?.phone ?? '--'}
              </PairValue>
            </Pair>

            <Pair size='md' colorScheme={isOffMarket ? 'white' : 'default'}>
              <PairKey>Agent Email</PairKey>
              <PairValue
                wordBreak='break-all'
              >
                {entry.buyerAgent.email ?? '--'}
              </PairValue>
            </Pair>
          </Grid>
        </>
      )}
    </Box>
  )
}

const Gallery = (props: { photos: Photo[] }) => {
  const [initialSlide, setInitialSlide] = useState(0)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const handleFullscreen = () => {
    setIsFullscreen(true)
  }

  const handleClose = () => {
    setIsFullscreen(false)
  }

  const handlePhotoClick = (index: number) => {
    setInitialSlide(index)
    setIsFullscreen(true)
  }

  const PHOTO_LIMIT = 9

  const plusCount = props.photos.length === PHOTO_LIMIT
    ? 0
    : (
      props.photos.length - PHOTO_LIMIT
    ) + 1 // let's count the last photo since it's covered with overlay

  if (props.photos.length === 0) {
    return (
      <NoPhoto
        display={mbp({ mobSm: 'none', tabSm: 'grid' })}
        borderRadius={3}
        bgColor='card.bg.1'
        h='420px'
        mt={3}
      />
    )
  }
  return (
    <Grid
      display={mbp({ mobSm: 'none', tabSm: 'grid' })}
      mt={3}
      borderRadius={3}
      overflow='hidden'
      gridTemplateColumns='repeat(2, 1fr)'
      rowGap={0.5}
      columnGap={0.5}
      bgColor='grayweak.200'
      shadow='primary.m'
      position='relative'
    >
      <SliderMaximizedModal
        photos={props.photos}
        isOpen={isFullscreen}
        initialSlide={initialSlide}
        onClose={handleClose}
      />
      <FullscreenButton onClick={handleFullscreen} />

      {props.photos.slice(0, PHOTO_LIMIT).map((photo, index) => {
        const isLast = index === PHOTO_LIMIT - 1
        const shouldShowPlusNumber = isLast && plusCount > 0

        return (
          <GridItem
            key={index}
            gridColumn={index === 0 ? '1 / 3' : 'auto / auto'}
            h={index === 0 ? '266px' : '175px'}
            onClick={() => handlePhotoClick(index)}
            pos='relative'
            cursor='pointer'
          >
            <Image
              as={LazyLoadImage}
              src={photo.url}
              objectFit='cover'
              w='100%'
              h='100%'
              fallback={(
                <Center bg='card.bg.2' h='full'>
                  <Spinner size='sm' color='grayweak.900' />
                </Center>
              )}
            />

            {shouldShowPlusNumber && (
              <Center
                pos='absolute'
                top={0}
                left={0}
                w='100%'
                h='100%'
                bg='overlay.dark-am'
                onClick={() => handlePhotoClick(index)}
                textStyle='display2'
                color='ondark.4'
                cursor='pointer'
              >
                +
                {plusCount}
              </Center>
            )}
          </GridItem>
        )
      })}
    </Grid>
  )
}

export const FullscreenButton = (props: ButtonProps) => (
  <Box
    position='absolute'
    top='10px'
    right='9px'
    borderRadius='50%'
    bgColor='grayweak.200'
    borderColor='grayweak.500'
    borderWidth={0.125}
    cursor='pointer'
    p={1}
    {...props}
  >
    <FullscreenIcon
      display='block'
      boxSize={2.5}
      color='graystrong.500'
    />
  </Box>
)
