import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals/modals'

export type OpenPlanDowngradeAlertParams = {
  skipAmount: string
  billingInterval: string
  planName: string
  onProceed: () => void
}

export const openPlanDowngradeAlert = ({
  skipAmount,
  billingInterval,
  planName,
  onProceed,
}: OpenPlanDowngradeAlertParams) => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'PLAN_DOWNGRADE_ALERT_MODAL',
      props: {
        skipAmount,
        billingInterval,
        planName,
        onClose: goBackModal,
        onProceed,
      },
    },
  })
}
