import { Match, pipe } from 'effect'
import { BoundaryCriteria, LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { PropertyListCriteria } from 'features/ListBuilder/domain/ListCriteria/PropertyListCriteria'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { useStore } from 'features/ListBuilder/infra/react/ListBuilderStore/useStore'

export const useLocationCriteria = () =>
  useStore(store => pipe(
    Match.value(store.criteria),
    Match.tag('PropertyListCriteria', oldCriteria => ({
      value: oldCriteria.location,
      apply: (locations: LocationCriteria[]) => {
        const newCriteria = PropertyListCriteria.with(oldCriteria, {
          location: locations,
        })
        store.buildList.execute(newCriteria)
      },
      add: (location: LocationCriteria) => {
        const isAlreadyAdded = oldCriteria.location
          .some(existing =>
            existing._tag === location._tag
            && existing.value === location.value,
          )

        if (isAlreadyAdded) return

        const newCriteria = PropertyListCriteria.with(oldCriteria, {
          location: [...oldCriteria.location, location],
        })

        store.buildList.execute(newCriteria)
      },
      remove: (locationsParams: LocationCriteria[]): void => {
        const isAlreadyRemoved = locationsParams.every(location => {
          const isExisting = !!oldCriteria.location.find(existing =>
            existing._tag === location._tag
            && existing.value === location.value,
          )
          return !isExisting
        })

        if (isAlreadyRemoved) return

        const newCriteria = PropertyListCriteria.with(oldCriteria, {
          location: oldCriteria.location.filter(existing => {
            const isInParams = !!locationsParams
              .some(location =>
                existing._tag === location._tag
                && existing.value === location.value,
              )
            return !isInParams
          }),
        })

        store.buildList.execute(newCriteria)
      },
    })),
    Match.when(Match.null, () => ({
      value: [],
      apply: () => {},
      add: () => {},
      remove: () => {},
    })),
    Match.exhaustive,
  ))

export const useBoundaryCriteria = () =>
  useStore(store => pipe(
    Match.value(store.criteria),
    Match.tag('PropertyListCriteria', (oldCriteria): UseCriteriaHookApi<BoundaryCriteria> => ({
      value: oldCriteria.boundary,
      apply: value => {
        const newCriteria = PropertyListCriteria.with(oldCriteria, {
          boundary: value,
        })
        store.buildList.execute(newCriteria)
      },
    })),
    Match.when(Match.null, () => ({
      value: null,
      apply: () => {},
    })),
    Match.exhaustive,
  ))
