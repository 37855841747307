import { useMutation } from '@apollo/client'
import { gql } from '__generated__'
import { ChoosePlanFlow_ChangeQuoteMutation } from '__generated__/graphql'
import { ChoosePlanBillingSummary, NextChargeInfo, Quote } from 'presentation/screens/Billing/components/ChoosePlanFlow/ChoosePlanModal.types'
import { useCallback, useState } from 'react'

export const CHOOSE_PLAN_FLOW__CHANGE_QUOTE = gql(/* GraphQL */ `
mutation ChoosePlanFlow_ChangeQuote($planId: ID!) {
  billingSubscriptionChangeQuote(planId: $planId) {
    ... on BillingSubscriptionChangeQuote {
      id
      proposedInvoice {
        items {
          amount
          description
        }
        totalAmount
      }
      followingInvoice {
        paymentDate
        totalAmount
      }
    }
    ... on BillingInvoicePastDueError {
      message
      field
    }
    ... on BillingSubscriptionCanceledError {
      message
      field
    }
    ... on BillingSubscriptionPlanNotFoundError {
      message
      field
    }
  }
}
`)

export type ChangeQuote = (planId: string) => Promise<ChoosePlanBillingSummary>

export type UseChangeQuoteMutationResult = {
  changeQuote: ChangeQuote
  loading: boolean
  wasCalled: boolean
}

export const useChangeQuoteMutation = (): UseChangeQuoteMutationResult => {
  const [changeQuoteMutation, { loading }] = useMutation(CHOOSE_PLAN_FLOW__CHANGE_QUOTE)
  const [wasCalled, setWasCalled] = useState(false)

  const changeQuote: ChangeQuote = useCallback(async (planId: string) => {
    setWasCalled(true)
    return await changeQuoteMutation({ variables: { planId } })
      .then(({ errors, data }) => {
        const errMsg = data ? getErrorFromData(data) : errors?.[0].message
        if (errMsg) throw new Error(errMsg)

        if (!data) throw new Error('Missing data in changeQuote')

        return getBillingSummary(data)
      })
  }, [changeQuoteMutation])

  return { changeQuote, loading, wasCalled }
}

export const getBillingSummary = (data: ChoosePlanFlow_ChangeQuoteMutation): ChoosePlanBillingSummary => {
  const { billingSubscriptionChangeQuote } = data

  if (billingSubscriptionChangeQuote.__typename === 'BillingSubscriptionChangeQuote') {
    const { proposedInvoice, followingInvoice } = billingSubscriptionChangeQuote

    const quote: Quote = {
      id: billingSubscriptionChangeQuote.id,
      items: proposedInvoice.items.map(item => ({
        ...item,
        description: item.description ?? null,
      })),
      totalAmount: proposedInvoice.totalAmount,
    }

    const nextChargeInfo: NextChargeInfo = {
      date: followingInvoice.paymentDate ? new Date(followingInvoice.paymentDate) : null,
      amount: followingInvoice.totalAmount,
    }

    return {
      quote,
      nextChargeInfo,
    }
  }

  throw new Error('Unexpected billingSubscriptionChangeQuote type')
}

const getErrorFromData = (data: ChoosePlanFlow_ChangeQuoteMutation): string | null => {
  if ('message' in data.billingSubscriptionChangeQuote)
    return data?.billingSubscriptionChangeQuote?.message ?? null

  return null
}
