import { Box, Flex, Grid, GridItem, Text, chakra } from '@chakra-ui/react'
import classNames from 'classnames'
import { ID } from 'libs/id'
import { TableIcon } from 'presentation/components/Icons'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag'
import { Pager } from 'presentation/components/Pager'
import { Tooltip } from 'presentation/components/Tooltip'
import { useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { PhotosSlider } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAEntryTileCard/components/PhotosSlider'
import { CompsMarkerPopupProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsMarkerPopup'
import { CompsSinglePointMarkerPopupProps } from 'presentation/screens/CompsScreen/components/CompsMap/components/CompsSinglePointMarkerPopup/CompsSinglePointMarkerPopup.props'
import { getMarkerPopupColors } from 'presentation/screens/CompsScreen/components/CompsMap/components/utils/getMarkerPopupColors'
import { CompsThumbButtons } from 'presentation/screens/CompsScreen/components/CompsThumbButtons/CompsThumbButtons'
import { toBedBathsLivingSqft } from 'presentation/screens/CompsScreen/formatters/toBedBathsBldngSqft'
import { animationClasses } from 'presentation/utils/animationClasses'
import { useState } from 'react'

type SinglePointMarkerSlide = Record<ID, {
  slideNumber: number
  comp: CompsMarkerPopupProps
}>

export const CompsSinglePointMarkerPopup = (props: CompsSinglePointMarkerPopupProps) => {
  const slides = props.entries.reduce<SinglePointMarkerSlide>((acc, comp, index) => ({
    ...acc,
    [comp.id]: {
      slideNumber: index,
      comp,
    },
  }), {})

  const [currentEntryId, setCurrentEntryId] = useState<ID>(
    props.highlightedEntry?.id ?? props.entries[0].id,
  )

  const viewValue = useResponsiveViewValue()
  const isTableView = viewValue === 'TABLE_VIEW'

  const currentEntry = slides[currentEntryId] ?? props.entries[0]

  const markerPopupColors = getMarkerPopupColors(currentEntry.comp.status)

  const bedsBathsSizeFormat = toBedBathsLivingSqft(currentEntry.comp)

  const handleOnPrev = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    const currentIndex = currentEntry.slideNumber
    const prevIndex = currentIndex === 0
      ? props.entries.length - 1
      : currentIndex - 1
    const entry = props.entries[prevIndex]
    setCurrentEntryId(entry.id)
    props.onPageChange(entry.id)
  }

  const handleOnNext = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    const currentIndex = currentEntry.slideNumber
    const nextIndex = currentIndex === props.entries.length - 1
      ? 0
      : currentIndex + 1
    const entry = props.entries[nextIndex]
    setCurrentEntryId(entry.id)
    props.onPageChange(entry.id)
  }

  const handleOnFullscreen = () => {
    props.onOpenComparativePropertyModal?.(currentEntry.comp.id)
  }

  const shouldShowPager = props.entries.length > 1

  return (
    <Box
      className={classNames(animationClasses.fadeIn, animationClasses.faster)}
      borderColor='marker.brown'
      borderWidth={0.125}
      borderRadius={3}
      bgColor='marker.cream'
      color='marker.brown'
      p={2}
      w='336px'
      position='relative'
      shadow='float'
      onClick={handleOnFullscreen}
      cursor='pointer'
    >
      <Box
        position='absolute'
        top='-14px'
        right='-14px'
        zIndex='4'
        borderRadius='100px'
        bgColor='ondark.1'
        borderColor='accent.gold.300'
        borderWidth={0.125}
        p={1}
      >
        {isTableView
          ? <TableIcon boxSize={2.5} display='block' />
          : <FullscreenIcon />}
      </Box>

      {/** Header */}
      <Flex
        borderTopRadius={2}
        py={1}
        px={2}
        bgColor={markerPopupColors.headerBgColor}
        gap={1}
        alignItems='center'
      >

        {/* @GENESIS Current comps service does not support this */}
        {/*
        <PropertyMarker
          markerType='pin'
          classification={currentEntry.comp.classification}
          equityType={currentEntry.comp.equityType}
          isVacant={currentEntry.comp.isVacant}
          isForeclosure={currentEntry.comp.isForeclosure}
          isSenior={currentEntry.comp.isSenior}
          size='xs'
        /> */}
        <Flex
          minW={0}
          flex='1'
          flexDirection='column'
          color={markerPopupColors.textColor}
        >
          <Tooltip label={currentEntry.comp.address.line1}>
            <Text
              textStyle='bodyMFat'
              isTruncated
              color='inherit'
              mr={1}
            >
              {currentEntry.comp.address.line1}
            </Text>
          </Tooltip>
          <Text
            textStyle='bodyM'
            color='inherit'
          >
            {bedsBathsSizeFormat}
          </Text>
        </Flex>
        <CompsThumbButtons
          comp={currentEntry.comp}
          onCompsRatingChange={currentEntry.comp.onCompsRatingChange}
          ml='auto'
        />
      </Flex>

      <Grid
        gridTemplateAreas={'"layer"'}
        borderBottomRadius={2}
        overflow='hidden'
      >

        <PhotosSlider
          key={currentEntry.comp.id}
          gridArea='layer'
          minW={0}
          zIndex='1'
          photos={currentEntry.comp.photos}
          shouldOpenFullscreenOnSlideClick={false}
        />

        <GridItem
          gridArea='layer'
          alignSelf='end'
          justifySelf='end'
          zIndex='2'
          mr={1}
          mb={1}
        >
          <MLSStatusTag
            status={currentEntry.comp.status}
          />
        </GridItem>
      </Grid>

      {shouldShowPager && (
        <Pager
          mt={1}
          currentPage={currentEntry.slideNumber + 1}
          totalPages={props.entries.length}
          onPrev={handleOnPrev}
          onNext={handleOnNext}
        />
      )}
    </Box>
  )
}

const FullscreenIcon = () => (
  <chakra.svg
    width='20px'
    height='20px'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    display='block'
  >
    <g id='ICON OUTLINE'>
      <chakra.path
        id='Vector'
        d='M6.66667 2.5H4.16667C3.72464 2.5 3.30072 2.67559 2.98816 2.98816C2.67559 3.30072 2.5 3.72464 2.5 4.16667V6.66667M17.5 6.66667V4.16667C17.5 3.72464 17.3244 3.30072 17.0118 2.98816C16.6993 2.67559 16.2754 2.5 15.8333 2.5H13.3333M13.3333 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V13.3333M2.5 13.3333V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H6.66667'
        stroke='accent.gold.300'
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </chakra.svg>
)
