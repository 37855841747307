import { Box } from '@chakra-ui/react'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { Pagination } from 'presentation/components/Pagination/Pagination'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { SearchHistoryPaginationProps } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/components/SearchHistoryPagination/SearchHistoryPagination.props'
import { shallow } from 'zustand/shallow'

export const SearchHistoryPagination = (props: SearchHistoryPaginationProps) => {
  const {
    currentPage,
    onPageChange,
    totalPages,
    isDisabled,
  } = useSearchHistoryStore(api => ({
    currentPage: api.local.currentPage,
    onPageChange: api.actions.getPage,
    totalPages: api.remote.history.status === 'success'
      ? api.remote.history.data.totalPages
      : api.remote.history.status === 'updating' && api.remote.history.previousData
        ? api.remote.history.previousData.totalPages
        : 0,
    isDisabled: api.remote.history.status === 'updating',
  }), shallow)

  const { sbp } = useSwitchBreakpointFn()

  if (totalPages === 0) return null

  return (
    <Box
      p={1}
      {...props}
    >
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        maxPageButtons={sbp({
          mobSm: 5,
          mob: 8,
          tabSm: 6,
        }) || 5}
        onPageChange={onPageChange}
        isDisabled={isDisabled}
        size='lg'
        colorScheme='highlight'
      />
    </Box>
  )
}
