import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { useRegisterScrollSpy } from 'presentation/components/ScrollSpy'
import { LAYOUT_CONTENT_MAX_W } from 'presentation/layouts/Layout'
import { BILLING_SECTION_IDS } from 'presentation/screens/Billing/Billing.const'
import { SettingsLink, useBillingDefaultNotice } from 'presentation/screens/Billing/BillingDefaultNotice'
import CurrentFeaturesSection from 'presentation/screens/Billing/CurrentFeaturesSection/CurrentFeaturesSection'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { FC } from 'react'
import { AccountBalance } from './AccountBalance'
import './Billing.modals.register'
import { CurrentPlanSection } from './CurrentPlanSection'
import { BillingHistorySection } from './components/BillingHistorySection'
import { PaymentSection } from './components/PaymentSection'

const BILLING_SIDEBAR_W = 224
const BILLING_SECTION_IDS_LIST = [
  BILLING_SECTION_IDS.plans,
  BILLING_SECTION_IDS.funds,
  BILLING_SECTION_IDS.payment,
  BILLING_SECTION_IDS.history,
]

export const Billing: FC = () => {
  useBillingDefaultNotice({
    breadcrumbs: [
      SettingsLink,
      { label: 'Plans & Billing' },
    ],
  })

  useRegisterScrollSpy(BILLING_SECTION_IDS_LIST)

  return (
    <Box
      px={mbp({ mobSm: 0, mob: 1.5, tabSm: 3.5, dtSm: 3.5, dtLg: 5 })}
      py={mbp({ mobSm: 1.5, mob: 1.5, tabSm: 2.5, dtSm: 2.5, dtLg: 5 })}
      maxW={px(LAYOUT_CONTENT_MAX_W - BILLING_SIDEBAR_W)}

      /**
       * I swear, if you remove this, buy an iPhone, open Safari, go to the
       * billing page, scroll down, and then toggle one of the rows in the
       * billing history...
       *
       * The other sections will vanish!
       *
       * I swear! I saw it! (Well I couldn't, that was the issue)
       *
       * https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela
       */
      sx={{ '-webkit-transform': 'translate3d(0, 0, 0)' }}
    >
      <Flex flexWrap='wrap' id={BILLING_SECTION_IDS.plans}>
        <SectionContainer
          flexGrow={1}
          flexBasis={mbp({ mobSm: 'full', tabSm: '50%' })}
        >
          <CurrentPlanSection />
        </SectionContainer>

        <SectionContainer
          flexGrow={1}
          flexBasis={mbp({ mobSm: 'full', tabSm: '50%' })}
        >
          <CurrentFeaturesSection />
        </SectionContainer>
      </Flex>

      <SectionContainer maxW='100%' id={BILLING_SECTION_IDS.funds}>
        <AccountBalance />
      </SectionContainer>

      <SectionContainer pt={mbp({ mobSm: '3' })} id={BILLING_SECTION_IDS.payment}>
        <PaymentSection />
      </SectionContainer>

      <SectionContainer id={BILLING_SECTION_IDS.history}>
        <BillingHistorySection />
      </SectionContainer>
    </Box>
  )
}

const SectionContainer: FC<BoxProps> = ({ children, ...props }) => (
  <Flex
    px={mbp({ mobSm: 1, mob: 1.5, tabSm: 1.5, dtSm: 1.5, dtLg: 3 })}
    py={mbp({ mobSm: 1.5, mob: 1.5, tabSm: 2.5, dtSm: 2.5, dtLg: 3 })}
    flexBasis='full'
    alignItems='stretch'
    justifyContent='stretch'
    {...props}
  >
    {children}
  </Flex>
)
