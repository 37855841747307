import { chakra } from '@chakra-ui/react'
import { MarkerIconContainer, MarkerIconProps } from 'presentation/components/PropertyMarker/components/containers/MarkerIconContainer'

export const MilitaryIcon = (props: MarkerIconProps) => (
  <MarkerIconContainer {...props}>
    <chakra.path
      d='M12.4952,1.83787375 C13.4472,1.38737375 14.5528,1.38737375 15.5048,1.83788375 L22.5048,5.15050375 C23.7236,5.72730375 24.5,6.94929375 24.5,8.29089375 L24.5,14.5562338 C24.5,17.6474338 22.7653,20.2206338 20.8827,22.1152338 C18.9851,24.0249338 16.7918,25.3940338 15.5513,26.0916338 C14.5831,26.6361338 13.4169,26.6361338 12.4487,26.0916338 C11.2082,25.3940338 9.01488,24.0249338 7.1173,22.1152338 C5.23469,20.2206338 3.5,17.6474338 3.5,14.5562338 L3.5,8.29089375 C3.5,6.94929375 4.27639,5.72730375 5.49523,5.15050375 L12.4952,1.83787375 Z M14.5016,3.93146375 C14.1843,3.78130375 13.8157,3.78130375 13.4984,3.93146375 L6.49841,7.24409375 C6.09213,7.43636375 5.83333,7.84369375 5.83333,8.29089375 L5.83333,14.5562338 C5.83333,16.7608338 7.08024,18.7777338 8.77757,20.4859338 C10.4599,22.1790338 12.4449,23.4252338 13.5978,24.0735338 C13.8531,24.2170338 14.1469,24.2170338 14.4022,24.0735338 C15.5551,23.4252338 17.5401,22.1790338 19.2224,20.4859338 C20.9198,18.7777338 22.1667,16.7608338 22.1667,14.5562338 L22.1667,8.29089375 C22.1667,7.84369375 21.9079,7.43636375 21.5016,7.24409375 L14.5016,3.93146375 Z'
      fill='marker.olivegreen'
    />
  </MarkerIconContainer>
)
