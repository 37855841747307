import { UnionToIntersection, UnpackArray } from 'libs/utils.types'

export type ToErrorFields<T> =
  T extends any[] ? (Error | undefined)[] :
  T extends File ? Error :
  T extends object ? { [K in keyof T]?: ToErrorFields<T[K]> } :
  Error

/**
 * Error that contains errors by fields.
 */
const fieldsErrorTag = Symbol('fieldsErrorTag')
export class FieldsError<Fields extends object | any[]> extends Error {
  [fieldsErrorTag] = true
  name = 'FieldsError'

  constructor(
    public message: string,
    public fields: ToErrorFields<Fields>,
  ) {
    super(message)
  }
}

/** FieldsError but param is unconstrained */
type FieldsErrorConditionally<T> =
  T extends object | any[]
    ? FieldsError<T>
    : never

type ExtractFields<T> =
  T extends FieldsError<infer Fields> ? Fields : never

/**
 * @NOTE Distributed means it will be applied for each member of union,
 * as opposed to applying to the whole
 */
type ExtractFieldsDistributed<T extends FieldsError<any>> =
  T extends FieldsError<any>
    ? ExtractFields<T>
    : never

/**
 * @example
 * type FooError = FieldsError<{ foo: string }>
 * type BarError = FieldsError<{ bar: number }>
 * type FooBarErrorArray = (FooError | BarError)[] <--- Array!
 * type FooBarFieldsMerged = MergedFields<FooBarErrorUnion>
 * // equivalent to { foo: string, bar: number } <--- Merged fields!
 */
type MergedFieldsArray<Arr extends FieldsError<any>[]> = UnionToIntersection<ExtractFieldsDistributed<UnpackArray<Arr>>>

/**
 * @example
 * type FooError = FieldsError<{ foo: string }>
 * type BarError = FieldsError<{ bar: number }>
 * type FooBarErrorArray = (FooError | BarError)[] <--- Array!
 * type FooBarErrorMerged = MergedFieldsErrorsArray<FooBarErrorUnion>
 * // equivalent to FieldsError<{ foo: string, bar: number }> <--- Merged ERROR fields, NOT FIELDS!
 */
export type MergedFieldsErrorsArray<Arr extends FieldsError<any>[]> =
  FieldsErrorConditionally<MergedFieldsArray<Arr>>

/**
 * @example
 * type FooError = FieldsError<{ foo: string }>
 * type BarError = FieldsError<{ bar: number }>
 * type FooBarError = (FooError | BarError) <--- Union, NOT ARRAY!
 * type FooBarFieldsMerged = MergedFields<FooBarErrorUnion>
 * // equivalent to { foo: string, bar: number } <--- Merged fields!
 */
export type MergedFieldsErrorsFields<FE extends FieldsError<any>> = UnionToIntersection<ExtractFieldsDistributed<FE>>

/**
 * @example
 * type FooError = FieldsError<{ foo: string }>
 * type BarError = FieldsError<{ bar: number }>
 * type FooBarError = (FooError | BarError) <--- Union, NOT ARRAY!
 * type FooBarErrorMerged = MergedFieldsErrors<FooBarErrorUnion>
 * // equivalent to FieldsError<{ foo: string, bar: number }> <--- Merged ERROR fields, NOT FIELDS!
 */
export type MergedFieldsErrors<FE extends FieldsError<any>> =
  FieldsErrorConditionally<MergedFieldsErrorsFields<FE>>

// Merges field errors
export function mergeFieldsErrors<Arr extends FieldsError<any>[]>(errors: Arr): MergedFieldsErrorsArray<Arr> {
  let mergedFields: any = {}

  for (const error of errors)
    mergedFields = { ...mergedFields, ...error.fields }

  // Assumes that all error messages are identical. If not, you'll need a strategy for merging them
  return new FieldsError(errors[0]?.message || '', mergedFields) as any
}
