import { Box, Flex, Grid, GridItem, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ColoredStarIcon } from 'presentation/components/Icons/ColoredStarIcon'
import { MapStoreProvider } from 'presentation/components/Map'
import { usePropertyModalContext } from 'presentation/components/PropertyModal/PropertyModalContext'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { LayoutBodyHeight } from 'presentation/layouts/Layout/components/LayoutBody'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { useLayoutWidth } from 'presentation/libs/useLayoutWidth/useLayoutWidth'
import { useCompsListPanelStore } from 'presentation/screens/CompsScreen/CompsScreen.panels'
import { CMAFullscreenPanel } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel'
import { CMAPanelBottomDrawer } from 'presentation/screens/CompsScreen/components/CMAPanelBottomDrawer'
import { CMASidePanel } from 'presentation/screens/CompsScreen/components/CMASidePanel'
import { CMAReportPrint } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/CMAReportPrint'
import { WithScrollableCompsMapBehavior } from 'presentation/screens/CompsScreen/components/CMASidePanel/components/CMAReport/components/WithScrollableCompsMapBehavior'
import { CMASidePanelDrawer } from 'presentation/screens/CompsScreen/components/CMASidePanelDrawer'
import { GRID_AREA_ID, LOCAL_ZINDEX } from 'presentation/screens/CompsScreen/components/CompScreenBase/CompsScreenBase.const'
import { CompsBoundaryBar } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundaryBar'
import { CompsCoverage } from 'presentation/screens/CompsScreen/components/CompsCoverage/CompsCoverage'
import { CompsError } from 'presentation/screens/CompsScreen/components/CompsError/CompsError'
import { CompsFilterPanel } from 'presentation/screens/CompsScreen/components/CompsFilterPanel/CompsFilterPanel'
import { CompsHeader, CompsHeaderAddress } from 'presentation/screens/CompsScreen/components/CompsHeader'
import { CompsMap } from 'presentation/screens/CompsScreen/components/CompsMap'
import { CompsUnavailable } from 'presentation/screens/CompsScreen/components/CompsUnavailable/CompsUnavailable'
import { CompsViewSettingsModal } from 'presentation/screens/CompsScreen/components/CompsViewSettingsModal/CompsViewSettingsModal'
import { ComparativePropertyModal } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/ComparativePropertyModal'
import { CompsViewMode, useCompsScreenViewMode } from 'presentation/screens/CompsScreen/hooks/useCompsScreenViewMode'
import { px } from 'presentation/utils/px'
import { PropsWithChildren, useEffect, useMemo } from 'react'
import * as portals from 'react-reverse-portal'
import { CompsInitializeStep } from '../CompsInitializeStep/CompsInitializeStep'

export const CompsScreenBase = () => {
  // ========================================
  // Status Handling
  // ========================================
  const leadId = useLeadId() ?? ''
  const status = useCMAStore(state => state.local.report.status)
  const settingsStatus = useSettingsStore(state => state.settingsState.status)
  const isLeadIdMismatch = useCMAStore(state =>
    'leadId' in state.local.report && state.local.report.leadId !== leadId,
  )

  useCustomMobileSmallHeader()

  if (!leadId || isLeadIdMismatch || status === 'initial' || status === 'initiating')
    return <Loading />

  if (status === 'initiated')
    return <CompsInitializeStep />
  if (status === 'no-coverage')
    return <CompsCoverage />

  if (status === 'no-subj-location')
    return <CompsUnavailable />

  if (status === 'loading' || settingsStatus === 'loading' || settingsStatus !== 'loaded')
    return <Loading />

  if (status === 'error') {
    return (
      <CompsError />
    )
  }

  return (
    <CompsScreenWrapper>
      <CompsHeader zIndex={LOCAL_ZINDEX.HEADER} />

      <CompsScreenBody />

      <CompsFilterPanel />
      <ComparativePropertyModal />

      <Breakpoint
        tabSm={<CompsViewSettingsModal />}
      />
      <CMAReportPrint />
    </CompsScreenWrapper>
  )
}

const CompsScreenBody = () => {
  const mode = useCompsScreenViewMode(store => store.mode)

  const tilePanel = <CMASidePanel />

  const { closePanel } = useCompsListPanelStore()

  const isFullscreen = mode === 'with-table'

  useEffect(() => {
    closePanel()
  }, [isFullscreen])

  const portalNode = useMemo(() => portals.createHtmlPortalNode({
    attributes: {
      style: 'height: 100%;',
    },
  }), [])

  return (
    <CompsScreenBodyGrid mode={mode}>
      <MapStoreProvider>
        <CompsMapGridItem>
          {/*
            * We render CompsMap via reverse portal lib
            * to avoid re-rendering when layout/parent changes,
            * which is important for draw boundary feature to work
            * (we want to retain map zoom/position when layout shifts
            * upon entering drawing mode.)
            */}
          <portals.InPortal node={portalNode}>
            <CompsMap />
          </portals.InPortal>
          {pipe(
            Match.value(mode),
            Match.when('with-table', () => (
              <WithScrollableCompsMapBehavior>
                <portals.OutPortal node={portalNode} />
              </WithScrollableCompsMapBehavior>
            )),
            Match.when('with-side-panel', () => (
              <WithMapSidepanelDrawerOverlay>
                <portals.OutPortal node={portalNode} />
              </WithMapSidepanelDrawerOverlay>
            )),
            Match.orElse(() => (
              <portals.OutPortal node={portalNode} />
            )),
          )}
        </CompsMapGridItem>
      </MapStoreProvider>

      {pipe(
        Match.value(mode),
        Match.when('with-draw-canvas', () => (
          <GridItem zIndex={LOCAL_ZINDEX.MAP_CONTROLS}>
            <CompsBoundaryBar />
          </GridItem>
        )),
        Match.when('with-side-panel', () => (
          <CMASidePanelDrawer>
            {tilePanel}
          </CMASidePanelDrawer>
        )),
        Match.when('with-panel-overlay', () => (
          <GridItem gridArea={GRID_AREA_ID} zIndex={LOCAL_ZINDEX.PANEL}>
            {tilePanel}
          </GridItem>
        )),
        Match.when('with-table', () => (
          <CMAFullscreenPanel mt='-36px' zIndex={LOCAL_ZINDEX.PANEL} />
        )),
        Match.when('with-bottom-drawer', () => (
          <CMAPanelBottomDrawer>
            {tilePanel}
          </CMAPanelBottomDrawer>
        )),
        Match.orElse(() => null),
      )}
    </CompsScreenBodyGrid>
  )
}

const CompsScreenBodyGrid = (props: PropsWithChildren<{ mode: CompsViewMode | null }>) => {
  const gridProps = pipe(
    Match.value(props.mode),
    Match.when('with-side-panel', () => ({
      gridTemplateColumns: '1fr auto',
    })),
    Match.when('with-table', () => ({
      gridTemplateColumns: 'minmax(0, 1fr)',
    })),
    Match.when('with-draw-canvas', () => ({
      gridTemplateColumns: 'minmax(0, 1fr)',
      gridTemplateRows: 'auto min-content',
    })),
    Match.when('with-panel-overlay', () => ({
      gridTemplateColumns: 'minmax(0, 1fr)',
      gridTemplateAreas: `"${GRID_AREA_ID}"`,
      gridTemplateRows: 'auto',
    })),
    Match.orElse(() => ({})),
  )

  return (
    <Grid
      flex='1 1 0'
      {...gridProps}
    >
      {props.children}
    </Grid>
  )
}

const CompsMapGridItem = ({ children }: PropsWithChildren) => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  const mode = useCompsScreenViewMode(store => store.mode)
  const mapHeight = pipe(
    Match.value(mode),
    Match.when('with-table', () => px(height * 0.65)),
    Match.when('with-draw-canvas', () => 'auto'),
    Match.when(
      mode =>
        ([
          'with-bottom-drawer',
          'with-panel-overlay',
        ] as (typeof mode)[])
          .includes(mode),
      () => 'full',
    ),
    Match.orElse(() => 'auto'),
  )

  return (
    <GridItem
      position='relative'
      h={mapHeight}
      zIndex={LOCAL_ZINDEX.MAP}
      {...mode === 'with-panel-overlay' && { gridArea: GRID_AREA_ID }}
    >
      {children}
    </GridItem>
  )
}

const CompsScreenWrapper = ({ children }: PropsWithChildren) => {
  const height = useLayoutStore(store => store.totalBodyHeight)
  const layoutWidth = useLayoutWidth()
  const mode = useCompsScreenViewMode(store => store.mode)
  const isPropertyModal = usePropertyModalContext()

  return (
    <Flex
      flexDirection='column'
      {...mode === 'with-table'
        ? { minH: px(height) } // free height, for sticky stuff to work
        : { h: px(height), w: layoutWidth, overflow: 'hidden' }} // fixed height, for tile view fixed height
      {...(isPropertyModal
        ? {
          flex: '1 1 auto',
          h: 'full',
          minH: 'full',
          w: 'full',
          maxW: 'full',
        }
        : {
          w: 'full',
          minW: 0,
        })}
    >
      {children}
    </Flex>
  )
}

const WithMapSidepanelDrawerOverlay = ({ children }: PropsWithChildren) => {
  const { isPanelOpen, closePanel } = useCompsListPanelStore()
  const shouldShowOverlay = useSwitchBreakpoint({
    tabSm: true,
    dtSm: false,
  }) ?? false
  const shouldShowAddress = useSwitchBreakpoint({
    tabSm: true,
    dtLg: false,
  }) ?? false
  return (
    <>
      {children}
      {isPanelOpen && shouldShowOverlay && (
        <Box
          position='absolute'
          top='0'
          left='0'
          right='0'
          bottom='0'
          backgroundColor='modal.blur'
          onClick={() => closePanel()}
          zIndex={LOCAL_ZINDEX.MAP_OVERLAY}
        />
      )}
      {shouldShowAddress && <CompsHeaderAddress zIndex={LOCAL_ZINDEX.MAP_OVERLAY} />}
    </>
  )
}

const Loading = () => (
  <LayoutBodyHeight
    display='flex'
    alignItems='center'
    justifyContent='center'
  >
    <Spinner />
  </LayoutBodyHeight>
)

const useCustomMobileSmallHeader = () => {
  const { title, setTitle } = useLayoutStore(layout => ({
    title: layout.title,
    setTitle: layout.setTitle,
  }))

  const originalTitle = useMemo(() => title, [])

  const isMobileSmall = useSwitchBreakpoint({
    mobSm: true,
    mob: false,
  }) ?? false

  const address = useCMAStore(api =>
    checkStateHasReportData(api.local.report)
      ? api.local.report.data.salesListInfo.subject.address
      : null)

  const shouldCustomizeHeader = isMobileSmall && address

  useEffect(() => {
    if (!shouldCustomizeHeader) {
      setTitle(originalTitle)
      return
    }

    setTitle(
      <VStack spacing={0}>
        <Text textStyle='bodyXlFat' color='ondark.1'>Property Data</Text>
        <Box>
          <HStack spacing={1} mt={-0.5}>
            <ColoredStarIcon />
            <Text textStyle='bodyLFat' color='highlight.500'>
              {address.line1}
            </Text>
          </HStack>
        </Box>
      </VStack>,
    )
  }, [shouldCustomizeHeader])
}
