import { Td, Text, Tr } from '@chakra-ui/react'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { OutlineHomeIcon } from 'presentation/components/Icons'
import { SkiptraceSubAccordionItem } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceSubAccordionItem'
import { DateValue, NewValue, SkiptraceTable } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceTable'

export type SkiptraceAddressAccordionProps = {
  items: Skiptrace.AddressHistoryEntryInfo[]
}

export const SkiptraceAddressAccordion = (props: SkiptraceAddressAccordionProps) => {
  const { items } = props
  const count = items.length
  const hasNew = items.some(v => v.isNew)
  return (
    <SkiptraceSubAccordionItem
      hasNew={hasNew}
      headerIcon={OutlineHomeIcon}
      headerLabel={`Address History (${count})`}
      isEmpty={count === 0}
    >
      <SkiptraceTable>
        {items.map((item, i) => (
          <Tr key={i}>
            <Td w='80px' textAlign='center'>
              {item.isNew
                ? <NewValue />
                : item.updatedAt
                  ? <DateValue date={item.updatedAt} />
                  : null}
            </Td>

            <Breakpoint
              mobSm={(
                <Td>
                  {/* <Text textStyle='bodyM' color='graystrong.200'>
                    Address {i + 1}
                  </Text> */}
                  <Text textStyle='bodyL' color='link.500'>
                    {item.address.line1}
                  </Text>
                  <Text textStyle='bodyL' color='link.500'>
                    {item.address.city}
                    {' '}
                    {item.address.state}
                    {' '}
                    {item.address.postalCode}
                  </Text>
                </Td>
              )}
              tabSm={(
                <>
                  {/* <Td w='112px'>
                    <Text textStyle='bodyL' color='graystrong.200'>
                      Address {i + 1}
                    </Text>
                  </Td> */}
                  <Td>
                    <Text textStyle='bodyL' color='link.500'>
                      {item.address.line1}
                    </Text>
                    <Text textStyle='bodyL' color='link.500'>
                      {item.address.city}
                      {' '}
                      {item.address.state}
                      {' '}
                      {item.address.postalCode}
                    </Text>
                  </Td>
                </>

              )}
            />
          </Tr>
        ))}
      </SkiptraceTable>
    </SkiptraceSubAccordionItem>
  )
}
