import { useQuery } from '@apollo/client'
import { Link } from '@chakra-ui/react'
import { gql } from '__generated__'
import dayjs from 'dayjs'
import { Notice, NoticeSecondaryText, NoticeText } from 'presentation/components/Notice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { get } from 'presentation/utils/graphql'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, Fragment, useEffect } from 'react'
import { Link as RRLink } from 'react-router-dom'

const BILLING_DEFAULT_NOTICE__GET_SUB_INFO = gql(/* Graphql */ `
  query BillingDefaultNotice_GetSubInfo {
    myEnterprise {
      id
      subscription {
        ... on BillingSubscriptionActive {
          id
          cancelAtPeriodEnd
          currentPeriod {
            end
          }
        }
      }
    }
  }
`)

type PageBreadcrumbs = {
  label: string
  link?: string
}

const BillingDefaultNotice: FC<{
  breadcrumbs: PageBreadcrumbs[]
  renewDate: string | null
}> = ({ breadcrumbs, renewDate }) => (
  <Notice colorScheme='default' display={mbp({ mobSm: 'none', tabSm: 'flex' })}>
    <NoticeText textTransform='uppercase'>
      {breadcrumbs.map(({ label, link }) => link
        ? (
          <Fragment key={label}>
            <Link as={RRLink} to={link}>{label}</Link>
&nbsp;/&nbsp;
          </Fragment>
        )
        : <Fragment key={label}>{label}</Fragment>,
      )}
    </NoticeText>

    {renewDate && (
      <NoticeSecondaryText>
        Your subscription will renew on
        {' '}
        {dayjs(renewDate).format('MMMM D, YYYY')}
      </NoticeSecondaryText>
    )}
  </Notice>
)

export const useBillingDefaultNotice = ({ breadcrumbs }: { breadcrumbs: PageBreadcrumbs[] }) => {
  const { data } = useQuery(BILLING_DEFAULT_NOTICE__GET_SUB_INFO)

  const activeSub = get('BillingSubscriptionActive')(data?.myEnterprise?.subscription)
  const isCanceled = activeSub?.cancelAtPeriodEnd
  const renewDate = isCanceled === false && activeSub?.currentPeriod?.end

  const { updateDefaultNotice } = useNoticeStore()

  useEffect(() => {
    updateDefaultNotice(
      <BillingDefaultNotice
        breadcrumbs={breadcrumbs}
        renewDate={renewDate || null}
      />,
    )

    return () => updateDefaultNotice(null)
  }, [renewDate])
}

export const SettingsLink: PageBreadcrumbs = {
  label: 'Settings',
  link: 'user/profile',
}

export const PlansBillingLink: PageBreadcrumbs = {
  label: 'Plans & Billing',
  link: 'user/billing',
}
