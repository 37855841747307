import { Effect, Exit, Layer, Option, Request, Runtime, Scope } from 'effect'
import AppRuntimeLayer from 'main/AppRuntimeLayer'
import { PropsWithChildren, createContext, useContext, useEffect } from 'react'

namespace AppRuntime {

  export type AppRuntime = Runtime.Runtime<AppRuntimeLayer.Requirements>

  export type Self = AppRuntime

  export const make = (layer: AppRuntimeLayer.Self) =>
    Effect.gen(function* (_) {
      const scope = yield * _(Scope.make())

      const StoreCache = yield * _(Request.makeCache({
        capacity: 500,
        timeToLive: '60 minutes',
      }))

      const layerWithCache = Layer.merge(
        layer,
        Layer.setRequestCache(StoreCache),
      )

      const env = yield * _(Layer.buildWithScope(scope)(layerWithCache))

      const runtime = yield * _(
        Effect.runtime<AppRuntimeLayer.Requirements>(),
        Effect.provide(env),
      )

      return {
        runtime,
        clean: Scope.close(scope, Exit.void),
      }
    })

  const Context = createContext<Option.Option<AppRuntime>>(Option.none())

  export type ProviderProps = PropsWithChildren<{
    layers: AppRuntimeLayer.Self
  }>

  export const Provider = ({ children, layers }: ProviderProps) => {
    const appRuntime = make(layers).pipe(Effect.runSync)

    useEffect(() => () => {
      Effect.runSync(appRuntime.clean)
    }, [])

    return (
      <Context.Provider value={Option.some(appRuntime.runtime)}>
        {children}
      </Context.Provider>
    )
  }

  export const useRuntime = () => {
    const context = useContext(Context)
    return Option.getOrElse(context, () => {
      throw new Error('No AppRuntime Provider')
    })
  }
}

export default AppRuntime
