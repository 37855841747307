import { Box, FormControl, FormLabel, InputGroup, InputRightElement, VStack } from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { LocationCounty, LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { BuildListState, useLocationCriteria } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { useSearchCities, useSearchCounties, UseSearchLocation, useSearchZip } from 'features/ListBuilder/infra/services/LocationServiceLive/LocationServiceLive'
import { CloseIcon } from 'presentation/components/Icons'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { useToastFailedState } from 'presentation/libs/hooks/useToastFailedState'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { PropertiesFilterChip } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/PropertiesFilterChips'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import React from 'react'

export const LocationCard = () => (
  <Card
    variant='default'
    colorScheme='card.bg.1'
    size={mbp({
      mobSm: 'xxs-locked',
      mob: 'sm-locked',
    })}
  >
    <CardHeader>
      <CardSecondaryTitle justifySelf='flex-start'>
        Apply Location
      </CardSecondaryTitle>
      <VStack
        spacing={3}
        mt={3}
        justify='stretch'
      >
        <CountyField />
        <CityField />
        <ZipField />
      </VStack>
    </CardHeader>
  </Card>
)

const makeLocationField = ({
  type,
  typeTag,
  label,
  displayNamePrefix,
  useSearchLocation,
}: {
  type: keyof typeof LocationCriteria
  typeTag: LocationCriteria['_tag']
  label: string
  displayNamePrefix: string
  useSearchLocation: UseSearchLocation
}) => {
  const LocationField = () => {
    const suggestLocationApi = useSearchLocation()
    const [isOpen, setIsOpen] = React.useState(false)
    const applyLocationApi = useLocationCriteria()
    const locationsFromState = useLocationCriteria()
      .value
      .filter(loc => loc._tag === typeTag)

    const state = suggestLocationApi.error
      ? BuildListState.Failed({ error: suggestLocationApi.error })
      : BuildListState.Initial()

    useToastFailedState(state, {
      title: `Failed to Load ${type} Suggestions`,
      message: TOAST_PRESET.GENERIC_ERROR.message,
    })

    const shouldRenderChipsOutside = useSwitchBreakpoint({
      mobSm: true,
      tabSm: false,
    })

    return (
      <FormControl
        /**
         * The menu is getting clipped by subsequent inputs for some reason.
         * Curse the gods, wasted some time on this.
         */
        zIndex={isOpen ? 1 : 'auto'}
      >
        <FormLabel>
          {label}
        </FormLabel>

        {shouldRenderChipsOutside && !!locationsFromState.length && (
          <Box mx={-0.5} mb={1} lineHeight={4.5}>
            {locationsFromState.map((loc, lid) => (
              <PropertiesFilterChip
                key={lid}
                type='geographic'
                iconComponent={CloseIcon}
                onIconClick={() => {
                  applyLocationApi.remove([loc])
                }}
                mx={0.5}
              >
                {type === 'County'
                  ? LocationCounty.formatCounty(loc.value)
                  : loc.value}
              </PropertiesFilterChip>
            ))}
          </Box>
        )}

        <InputGroup
          sx={{
            '.chakra-wrap': {
              'p': 0,
              'h': 5,

              'px': px(16 - 1), // padding - border
              'py': '1px !important', // offset border difference on hover

              '&:focus-within': {
                px: px(16 - 2), // padding - border
                py: '0 !important',
              },
              'element': {
                right: '6px',
              },
              '_invalid': {
                px: px(16 - 2), // padding - border
                py: px(8 - 2), // padding - border
              },

              '_hover': {
                px: px(16 - 2), // padding - border
                py: px(8 - 2), // padding - border
              },
            },
            '.chakra-wrap__list': {
              display: 'flex',
              alignItems: 'center',
              m: 0,
              gap: 0,
            },
            '.chakra-input__group': {
              width: 'auto',
            },
            '.properties-filter-chip': {
              'h': 3.5,
              'mx': 0.5,
              'my': 0,

              '&:first-of-type': {
                ml: -1,
              },
            },
            '& .chakra-input__group': {
              mx: 0,
              my: 0,
            },
            '.chakra-input': {
              py: '6px',
              px: '0 !important',
            },
          }}
        >
          <AutoComplete
            emphasize
            closeOnBlur
            closeOnSelect
            disableFilter
            multiple
            rollNavigation={false}
            prefocusFirstItem={false}
            suggestWhenEmpty={false}
            emptyState={(
              <Box
                px='2'
                color='graystrong.100'
              >
                {suggestLocationApi.status === 'loading'
                  ? 'Loading...'
                  : suggestLocationApi.results.length === 0
                    ? 'No results found'
                    : ''}
              </Box>
            )}
            onSelectOption={ev => {
              applyLocationApi.add(ev.item.originalValue)
              suggestLocationApi.onKeywordChange('')
            }}
            onTagRemoved={tag => {
              applyLocationApi.remove([tag])
            }}
            values={applyLocationApi.value
              .filter(loc => loc._tag === typeTag)
              .map(loc => loc)}
          >
            {renderProps => {
              if (renderProps.isOpen !== isOpen)
                setIsOpen(renderProps.isOpen)

              return (
                <InputGroup>
                  <AutoCompleteInput
                    value={suggestLocationApi.keyword}
                    onChange={ev => {
                      suggestLocationApi.onKeywordChange(ev.target.value)
                    }}
                  >
                    {({ tags }) =>
                      shouldRenderChipsOutside
                        ? null
                        : tags.map((tag, tid) => (
                          <PropertiesFilterChip
                            className='properties-filter-chip'
                            key={tid}
                            type='geographic'
                            iconComponent={CloseIcon}
                            onIconClick={tag.onRemove}
                          >
                            {type === 'County'
                              ? LocationCounty.formatCounty(tag.label.value)
                              : tag.label.value}
                          </PropertiesFilterChip>
                        ))}
                  </AutoCompleteInput>

                  {!shouldRenderChipsOutside && locationsFromState.length && (
                    <InputRightElement
                      role='button'
                      px={1}
                      onClick={() => {
                        applyLocationApi.remove(locationsFromState)
                      }}
                    >
                      <CloseIcon />
                    </InputRightElement>
                  )}

                  <AutoCompleteList
                    sx={{
                      bg: 'accent.hover !important',
                      borderColor: 'grayweak.500',
                      borderRadius: '2',
                      py: '16px !important',
                      pr: '16px !important',
                      pl: '0 !important',
                      overflow: 'auto',
                      maxH: '320px',
                      display: 'flex',
                      flexDirection: 'column !important',
                      alignItems: 'stretch',
                      gap: '0.5',
                    }}
                  >
                    {suggestLocationApi.results.map(data => (
                      <AutoCompleteItem
                        key={data.value}
                        value={data}
                        sx={{
                          ...textStyles.bodyMFat,

                          display: 'flex',
                          alignItems: 'center',
                          gap: '1',
                          flexShrink: '0',

                          // bg: 'none',
                          color: 'graystrong.400',
                          borderRightRadius: 'full',
                          h: '5',
                          p: 0,
                          pl: '2',
                          m: 0,
                        }}
                        _focus={{
                          bg: 'card.bg.xlightblue',
                          color: 'accent.blue-to-gray',
                        }}
                      >
                        {data.value}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>

                </InputGroup>
              )
            }}
          </AutoComplete>
        </InputGroup>
      </FormControl>
    )
  }

  LocationField.displayName = `${displayNamePrefix}Field`

  return LocationField
}

const CountyField = makeLocationField({
  type: 'County',
  typeTag: 'LocationCounty',
  label: 'County',
  displayNamePrefix: 'County',
  useSearchLocation: useSearchCounties,
})

const CityField = makeLocationField({
  type: 'City',
  typeTag: 'LocationCity',
  label: 'City',
  displayNamePrefix: 'City',
  useSearchLocation: useSearchCities,
})

const ZipField = makeLocationField({
  type: 'Zip',
  typeTag: 'LocationZip',
  label: 'Zip Code',
  displayNamePrefix: 'Zip',
  useSearchLocation: useSearchZip,
})
