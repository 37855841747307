import { Button, ButtonProps, Center, Checkbox, Flex, Spinner, Text } from '@chakra-ui/react'
import { Match, Option } from 'effect'
import pluralize from 'pluralize'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ArrowLeftIcon } from 'presentation/components/Icons'
import { useSetMobileHeaderContents } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import OwnersPropertiesHooks from '../../viewModel/OwnersPropertiesHooks'
import OwnedPropertiesMap from '../OwnedPropertiesMap'
import OwnedPropertiesTable from '../OwnedPropertiesTable'

const OwnedPropertiesMobileScreen = () => {
  const viewModel = OwnersPropertiesHooks.useOwnerDealsMobSm()

  const [activeTab, setActiveTab] = useState(0)

  if (Option.isNone(viewModel)) return null

  return (
    <MobileRedirect>
      {Match.value(viewModel.value).pipe(
        Match.tag('Success', viewModel => (
          <Flex
            flexDir='column'
            bgColor='special.500'
            minH='layout-height'
          >
            <MobileHeader
              ownerId={viewModel.ownerId}
              onBack={viewModel.onBackToOwnerProperties}
            />
            <BaseTabsContainer
              colorScheme='xweak'
              size='sm'
              display='flex'
              flex='1'
              minH={0}
              flexDirection='column'
            >
              <BaseTabs>
                <BaseTab
                  isActive={activeTab === 0}
                  onClick={() => setActiveTab(0)}
                >
                  List
                </BaseTab>
                <BaseTab
                  isActive={activeTab === 1}
                  onClick={() => setActiveTab(1)}
                >
                  Map
                </BaseTab>
              </BaseTabs>
              <BaseTabContent
                pt='2'
                pb={activeTab === 0 ? 0 : 2}
                px={{ base: 1, mob: 2 }}
                flex='1'
                minH='0'
                borderBottomRadius='0 !important'
                {...activeTab === 0
                  ? {
                    borderTopRightRadius: '0 !important',
                  }
                  : {}}
                sx={{
                  '& > div': {
                    borderRadius: 0,
                  },
                }}
                display='flex'
                flexDirection='column'
              >
                {Match.value(activeTab).pipe(
                  Match.when(0, () => (
                    <Flex
                      flexDir='column'
                      minH={0}
                      h='full'
                      gap='2'
                      alignItems='stretch'
                      justifyContent='stretch'
                    >
                      <Text
                        display='flex'
                        justifyContent='left'
                        alignContent='center'
                        textStyle='bodyXLFat'
                        py='6px'
                      >
                        {pluralize('Property', viewModel.displayedPropertiesCount, false)}
                        {' '}
                        (
                        {viewModel.displayedPropertiesCount}
                        )
                      </Text>
                      <OwnedPropertiesTable
                        ownerId={viewModel.ownerId}
                        h='auto'
                        w='auto'
                      />
                    </Flex>
                  )),
                  Match.when(1, () => (
                    <Flex
                      flexDir='column'
                      minH={0}
                      h='full'
                      gap='2'
                      flex='1'
                    >
                      <Text
                        textStyle='bodyXLFat'
                      >
                        Potential
                        {' '}
                        {pluralize('Property', viewModel.displayedPropertiesCount, false)}
                        {' '}
                        Owned (
                        {viewModel.displayedPropertiesCount}
                        )
                      </Text>
                      <OwnedPropertiesMap
                        flex='1'
                        borderRadius={3}
                        overflow='auto'
                        ownerId={viewModel.ownerId}
                      />
                    </Flex>
                  )),
                  Match.orElse(() => null),
                )}
              </BaseTabContent>
            </BaseTabsContainer>
          </Flex>
        )),
        Match.orElse(() => (
          <Center flex='1'>
            <Spinner />
          </Center>
        )),
      )}
    </MobileRedirect>
  )
}

export default OwnedPropertiesMobileScreen

const BackButton = (props: ButtonProps) => (
  <Button
    key='back'
    variant='ghost'
    leftIcon={(
      <ArrowLeftIcon boxSize='3' />
    )}
    colorScheme=''
    size='md'
    color='card.bg.1'
    ml='1'
    px={1}
    {...props}
  >
    Back
  </Button>
)

const MobileHeader = ({
  ownerId,
  onBack,
}: {
  ownerId: string
  onBack: () => void
}) => {
  const setHeaderContents = useSetMobileHeaderContents()
  const toggleViewModel = OwnersPropertiesHooks.useToggleLimitToCurrentlyOwned(ownerId)

  const isChecked = toggleViewModel.pipe(
    Option.map(vm => vm.shouldLimitToCurrentlyOwned),
    Option.getOrElse(() => false),
  )

  useEffect(() => {
    setHeaderContents([
      <Flex
        key='back-button'
        p={1}
        pl={0}
        minH={6.5}
      >
        <BackButton
          onClick={onBack}
        />

        {/* <Flex
            key='include-checkbox'
            gap={1}
            ml='auto'
            h={5}
            alignItems='center'
            p={1}
          >
            <Text
              textStyle='bodySFat'
              color='ondark.2'
            >
              <Breakpoint
                mobSm={(
                  <>
                    Show properties
                    <br />
                    previously owned
                  </>
                )}
                mob='Show properties previously owned'
              />
            </Text>
            <Checkbox />
          </Flex> */}
      </Flex>,
      null,
      toggleViewModel.pipe(
        Option.map(viewModel => (
          <Flex
            key='include-checkbox'
            gap={1}
            ml='auto'
            h={5}
            alignItems='center'
            p={1}
          >
            <Text
              textStyle='bodySFat'
              color='ondark.2'
            >
              <Breakpoint
                mobSm={(
                  <>
                    Show properties
                    <br />
                    previously owned
                  </>
                )}
                mob='Show properties previously owned'
              />
            </Text>
            <Checkbox
              isChecked={isChecked}
              onChange={viewModel.onToggle}
            />
          </Flex>
        )),
        Option.getOrElse(() => null),
      ),
    ])

    return () => {
      setHeaderContents(null)
    }
  }, [isChecked])

  return null
}

/**
 * @TODO Fix brief blank screen when navigating to owner properties
 *   mobile screen on desktop
 */
const MobileRedirect = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const viewModel = OwnersPropertiesHooks.useRedirect()

  return (
    <Breakpoint
      mobSm={children}
      tabSm={(
        Option.match(viewModel.ownersPropertiesPath, {
          onSome: path => <Navigate to={path} replace />,
          onNone: () => null,
        })
      )}
    />
  )
}
