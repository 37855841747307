import { useTheme } from '@chakra-ui/react'

type SelectThemeSpecificValue = <T>(forLight: T, forDark: T) => T
type Result = {
  selectThemeSpecificValue: SelectThemeSpecificValue
  tsv: SelectThemeSpecificValue
}

/**
 * @TODO Probably { light, dark } is better than (forLight, forDark)?
 */
export const useThemeSpecificValueFn = (): Result => {
  const theme = useTheme()
  const selectThemeSpecificValue = <T>(forLight: T, forDark: T): T =>
    theme.id === 'light' ? forLight : forDark
  return { selectThemeSpecificValue, tsv: selectThemeSpecificValue }
}

export const useThemeSpecificValue = <T>({
  light: forLight,
  dark: forDark,
}: {
  light: T
  dark: T
}) => {
  const { selectThemeSpecificValue } = useThemeSpecificValueFn()
  return selectThemeSpecificValue(forLight, forDark)
}
