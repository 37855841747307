import { Grid, GridItem, VStack } from '@chakra-ui/react'

import { useIsBankFlag, useIsCorporateOwnedFlag, useIsInStateFlag, useIsMilitaryFlag, useIsOutOfCountryFlag, useIsOutOfStateFlag, useIsPersonalResidenceFlag, useIsSeniorFlag, useIsTrustFlag, useYearsOfOwnership } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { makeOptionalRangeCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/OptionalRangeCard'
import { makeWiredSwitchCard } from 'presentation/screens/ListBuilderScreen/components/ListBuilderScreenBase/components/SwitchCard'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const OwnershipFiltersContent = () => (
  <VStack p={2} spacing={2} align='stretch'>
    {/** @POSTTNT */}
    {/* <Card
      variant='default'
      colorScheme='card.bg.1'
      size='sm-locked'
    >
      <CardHeader>
        <CardSecondaryTitle justifySelf='flex-start'>
          Length of Ownership
        </CardSecondaryTitle>

        <Grid
          mt={2}
          templateColumns='repeat(3, 1fr)'
          gap={2}
        >
          <GridItem>
            <FormControl>
              <FormLabel>Year</FormLabel>
              <Input placeholder='Any Time' />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Months</FormLabel>
              <Input placeholder='Any Time' />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Days</FormLabel>
              <Input placeholder='Any Time' />
            </FormControl>
          </GridItem>
        </Grid>
      </CardHeader>
    </Card> */}

    <Grid
      gridTemplate={mbp({
        mobSm: 'auto / repeat(1, auto)',
        tabSm: 'auto / repeat(2, 1fr)',
      })}
      gap={2}
    >
      <GridItem>
        <LengthOfOwnershipCard />
      </GridItem>
      <GridItem>
        <OwnerOccupiedCard h='full' />
      </GridItem>
      <GridItem>
        <InStateLandlordsCard h='full' />
      </GridItem>
      <GridItem>
        <OutOfStateLandlordsCard h='full' />
      </GridItem>
      <GridItem>
        <OutOfCountryLandlordsCard h='full' />
      </GridItem>
      <GridItem>
        <MilitaryCard h='full' />
      </GridItem>
      <GridItem>
        <SeniorCard h='full' />
      </GridItem>
      <GridItem>
        <CorporateOwnedCard h='full' />
      </GridItem>
      <GridItem>
        <TrustCard h='full' />
      </GridItem>
      <GridItem>
        <REOCard h='full' />
      </GridItem>
      {/* <GridItem>
        <UnknownCard h='full' />
      </GridItem>
      <GridItem>
        <NoOfPropertiesOwnedCard h='full' />
      </GridItem> */}
    </Grid>
  </VStack>
)

const LengthOfOwnershipCard = makeOptionalRangeCard({
  title: 'Length of Ownership',
  displayNamePrefix: 'LengthOfOwnership',
  useApi: useYearsOfOwnership,
  minLabel: 'Minimum Years',
  maxLabel: 'Maximum Years',
})

const OwnerOccupiedCard = makeWiredSwitchCard({
  title: (
    <>
      Personal Residence
      <br />
      /Owner Occupied
    </>
  ),
  displayNamePrefix: 'OwnerOccupied',
  useApi: useIsPersonalResidenceFlag,
})

const InStateLandlordsCard = makeWiredSwitchCard({
  title: (
    <>
      In State Landlords
      <br />
      /Absentee Owners
    </>
  ),
  displayNamePrefix: 'InStateLandlords',
  useApi: useIsInStateFlag,
})

const OutOfStateLandlordsCard = makeWiredSwitchCard({
  title: (
    <>
      Out of State Landlords
      <br />
      /Absentee Owners
    </>
  ),
  displayNamePrefix: 'OutOfStateLandlords',
  useApi: useIsOutOfStateFlag,
})

const OutOfCountryLandlordsCard = makeWiredSwitchCard({
  title: (
    <>
      Out of Country Landlords
      <br />
      /Absentee Owners
    </>
  ),
  displayNamePrefix: 'OutOfCountryLandlords',
  useApi: useIsOutOfCountryFlag,
})

const MilitaryCard = makeWiredSwitchCard({
  title: 'Military',
  displayNamePrefix: 'Military',
  useApi: useIsMilitaryFlag,
})

const SeniorCard = makeWiredSwitchCard({
  title: 'Senior',
  displayNamePrefix: 'Senior',
  useApi: useIsSeniorFlag,
})

const CorporateOwnedCard = makeWiredSwitchCard({
  title: 'Corporate Owned',
  displayNamePrefix: 'CorporateOwned',
  useApi: useIsCorporateOwnedFlag,
})

const TrustCard = makeWiredSwitchCard({
  title: 'Trust',
  displayNamePrefix: 'Trust',
  useApi: useIsTrustFlag,
})

const REOCard = makeWiredSwitchCard({
  title: 'REO/Bank',
  displayNamePrefix: 'REO',
  useApi: useIsBankFlag,
})

// const UnknownCard = makeWiredSwitchCard({
//   title: 'Unknown',
//   displayNamePrefix: 'Unknown',
//   useApi: useIsUnknownOwnershipFlag,
//   isInDev: true,
// })

// const NoOfPropertiesOwnedCard = makeWiredSwitchCard({
//   title: 'No. of Properties Owned',
//   displayNamePrefix: 'NoOfPropertiesOwned',
//   useApi: () => ({
//     apply: () => {},
//     value: null,
//   }),
//   isInDev: true,
// })
