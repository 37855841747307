import { Effect } from 'effect'
import MarketingList from 'features/ListBuilderV2/domain/entities/MarketingList/MarketingList'
import Connection from 'libs/Connection'
import { ActorRefFrom, assign, setup } from 'xstate'

namespace NearbyBuyerListEdgeMachine {
  export type Event = {
    type: 'toggle'
    isChecked?: boolean
  }

  export type Context = {
    isChecked: boolean
    edge: Connection.Edge<MarketingList>
  }

  export type Input = {
    edge: Connection.Edge<MarketingList>
  }

  export const make = () => Effect.succeed(setup({
    types: {} as {
      context: Context
      events: Event
      input: Input
    },
  }).createMachine({
    context: ({ input }) => ({
      isChecked: false,
      edge: input.edge,
    }),
    initial: 'Default',
    states: {
      Default: {
        on: {
          toggle: {
            actions: assign({
              isChecked: ({ event, context }) => event.isChecked ?? !context.isChecked,
            }),
          },
        },
      },
    },
  }))

  export type ActorRef = ActorRefFrom<
    Effect.Effect.Success<ReturnType<typeof make>>
  >
}

export default NearbyBuyerListEdgeMachine
