import { MlsResoAreaUnits, MlsResoStandardStatus, ParcelCondition, ParcelContactClassification, ParcelContactFieldsFragment, ParcelLienFieldsFragment, ParcelOwnerStatus, ParcelRawContactFieldsFragment, ParcelTransferFieldsFragment, PropertyDetailsQuery } from '__generated__/graphql'
import { isFuture } from 'date-fns'
import { Array, Option } from 'effect'
import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Lead } from 'features/PropertyDetails/infra/remote/getProperty/Lead.type'
import { patchDates } from 'features/PropertyDetails/infra/remote/getPropertyDetails/patchDates'
import { patchLastSale } from 'features/PropertyDetails/infra/remote/getPropertyDetails/patchLastSale'
import { patchValueFields } from 'features/PropertyDetails/infra/remote/getPropertyDetails/patchValueFields'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { EquityType } from 'features/valueObjects/EquityType'
import { Location } from 'features/valueObjects/Location'
import { MLSListing } from 'features/valueObjects/MLSListing'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import { PersonName } from 'features/valueObjects/PersonName'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { GqlPath } from 'presentation/libs/graphql'
import { createPipe, isBoolean, isDefined, isNonNull, isNumber, isString, pipe } from 'remeda'
import { isNullable } from 'utils/isNonNullable'

export const mapPropertyDetails = (
  details: PropertyDetailsQuery,
  lead: Lead,
  leadId: string,
): Domain.PropertyWithDetails => pipe(
  mapPropertyDetailsBase(details, lead, leadId),
  patchDates,
  patchLastSale,
  patchValueFields,
)

/**
 * @TODO Decide how to deal with nullable properties like address, location
 *   - We can go through fields with backend team focusing on fields that
 *     probably shouldn't be optional
 *   - We can also add a more elaborate schema parser and report everything
 *     unexpected, although nothing is really unexpected as what we have is
 *     an accepted contract on GraphQL level
 *   - We're punting on client side solution as it seems like this is more
 *     appropriate for backend to handle
 */
const mapPropertyDetailsBase = (
  details: PropertyDetailsQuery,
  lead: Lead,
  leadId: string,
): Domain.PropertyWithDetails => {
  const address = details.parcel?.address
  const location = details.parcel?.address?.location
  const ownerDetails = details.parcel?.ownerDetails
  const buildingDetails = details.parcel?.buildingDetails
  const landDetails = details.parcel?.landDetails
  const rawLastSale = details.parcel?.lastSale
  const valuationDetails = details.parcel?.valuationDetails
  const preforeclosures = details.parcel?.preforeclosures
  const taxDetail = details.parcel?.taxHistory?.edges[0]?.node

  const rawMls = details.parcel?.mlsPropertyListings?.edges[0]?.node?.resoPropertyListing
  const mlsListings = (details.parcel?.mlsPropertyListings?.edges || [])
    .map(mapMlsListing(location ?? null))
    .filter(isNonNull)

  const ownerAddress = ownerDetails?.owners.find(o => o.address)?.address || null

  const processEstimateNumber = createPipe(
    NumberLib.orNull,
    NumberLib.ifValid(NumberLib.preferNullOverZero),
  )

  const originalBuilding = {
    bathroomsCount: (() => {
      const half = pickBestValue(
        buildingDetails?.bathroomsCount?.half,
        rawMls?.bathroomsHalf,
      ) ?? null

      const full = pickBestValue(
        buildingDetails?.bathroomsCount?.full,
        rawMls?.bathroomsFull,
      ) ?? null

      return {
        total: buildingDetails?.bathroomsCount?.total
        || BathroomsCountInfo.getTotal({ full, half }),
        full,
        half,
      }
    })(),

    bedroomsCount: pickBestValue(
      buildingDetails?.bedroomsCount,
      rawMls?.bedroomsTotal,
    ) ?? null,

    garageSpacesCount: pickBestValue(
      buildingDetails?.garageSpacesCount,
      rawMls?.garageSpaces,
    ) ?? null,

    roomsCount: buildingDetails?.roomsCount || null,
    fireplacesCount: buildingDetails?.fireplacesCount ?? null,
    storiesCount: pickBestValue(
      buildingDetails?.storiesCount,
      rawMls?.stories,
    ) || null,

    hasPool: buildingDetails && isNumber(buildingDetails.poolAreaSqft) && buildingDetails.poolAreaSqft > 0
      ? true
      : isBoolean(mlsListings[0]?.hasPool)
        ? mlsListings[0].hasPool
        : false,

    buildingAreaSqft: buildingDetails?.buildingAreaSqft || null,

    livingAreaSqft: pickBestValue(
      buildingDetails?.livingAreaSqft,
      calcLivingAreaSqft(
        rawMls?.livingArea,
        rawMls?.livingAreaUnits,
      ),
    ) || null,

    basementAreaSqft: buildingDetails?.basementAreaSqft || null,
    garageAreaSqft: buildingDetails?.garageAreaSqft || null,

    yearBuilt: pickBestValue(
      buildingDetails?.yearBuilt,
      rawMls?.yearBuilt?.toString(),
    ) || null,

    basementType: !buildingDetails?.basementType
    || buildingDetails?.basementType === 'No Basement'
    || buildingDetails?.basementType === 'None'
      ? 'None'
      : buildingDetails?.basementType,
    heatingType: buildingDetails?.heatingType || null,
    heatingFuelType: buildingDetails?.heatingFuelType || null,
  }

  const mappedLastSale = rawLastSale
    ? mapTransfer({ rawTransfer: rawLastSale, isFirstAndHasLastSale: true })
    : null

  return {
    id: leadId,
    parcelId: details.parcel?.id || '',
    leadId,
    status: 'with-details',

    address: {
      line1: address?.line1 || '',
      city: address?.city || '',
      state: address?.state || '',
      postalCode: address?.postalCode || '',
    },

    location: Location.ensureOrNull(location),

    ownership: {
      owners: ownerDetails?.owners?.map(owner => ({
        name: {
          first: owner.name?.first || '',
          last: owner.name?.last || '',
          full: PersonName.combine(owner.name?.first, owner.name?.last) || '',
        },
      })) || [],
      address: Address.toNullIfEmpty({
        line1: ownerAddress?.line1 || '',
        city: ownerAddress?.city || '',
        state: ownerAddress?.state || '',
        postalCode: ownerAddress?.postalCode || '',
      }),
      classification: ownerClassificationFromGql(
        ownerDetails?.ownerClassification || null,
        ownerDetails?.ownerStatus || null,
      ),
      months: ownerDetails?.ownershipMonths || null,
      isVacant: ownerDetails?.isVacant || false,
      isForeclosure: ownerDetails?.isForeclosure || false,
      isSenior: ownerDetails?.isSenior || false,

      potentialPropertiesCount: pipe(
        ownerDetails?.owners ?? [],
        Array.map(owner =>
          Option.fromNullable(owner.buyerDetails?.historicalDeals.pageInfo.totalCount),
        ),
        Option.all,
        Option.map(counts => Array.reduce(counts, counts[0], Math.max)),
      ),
    },

    building: {
      ...originalBuilding,
      livingAreaSqft: lead.property.userInputData?.building?.livingAreaSqft
      ?? originalBuilding.livingAreaSqft
      ?? null,
    },

    land: {
      legalDescription: landDetails?.legalDescription || null,
      assessorParcelNumber: landDetails?.assessorParcelNumber || null,
      standardizedUseClass: landDetails?.standardizedUseClass || null,
      standardizedUseType: landDetails?.standardizedUseType || null,
      censusTract: landDetails?.censusTract || null,
      lotAreaSqft: landDetails?.lotAreaSqft || null,
      lotAreaAcres: landDetails?.lotAreaAcres || null,
      lot: landDetails?.lot || null,
      block: landDetails?.block || null,
      subdivision: landDetails?.subdivision || null,
      county: landDetails?.county?.__typename === 'UsCounty'
        ? landDetails.county.name
        : null,
    },

    condition: mapCondition({
      yearBuilt: buildingDetails?.yearBuilt || null,
      rawCondition: valuationDetails?.condition || null,
    }),

    lastSale: mappedLastSale,

    valuation: {
      equity: valuationDetails?.equity || null,
      equityType: valuationDetails?.loanToValue
        ? EquityType.fromLtv(valuationDetails.loanToValue)
        : null,
      loanToValue: NumberLib.ensureOrNull(valuationDetails?.loanToValue),
      estimateRange: {
        price: {
          min: processEstimateNumber(valuationDetails?.low),
          avg: processEstimateNumber(valuationDetails?.value),
          max: processEstimateNumber(valuationDetails?.high),
        },
        pricePerSqft: {
          min: calcPricePerSqft(
            processEstimateNumber(valuationDetails?.low),
            NumberLib.orNull(buildingDetails?.livingAreaSqft),
          ),
          avg: calcPricePerSqft(
            processEstimateNumber(valuationDetails?.value),
            NumberLib.orNull(buildingDetails?.livingAreaSqft),
          ),
          max: calcPricePerSqft(
            processEstimateNumber(valuationDetails?.high),
            NumberLib.orNull(buildingDetails?.livingAreaSqft),
          ),
        },
      },
    },

    mlsListings,

    preforeclosures: preforeclosures?.edges.map((edge): Domain.Preforeclosure => ({
      auction: {
        date: edge?.node?.auction?.date || null,
        location: edge?.node?.auction?.location || null,
        openingBid: edge?.node?.auction?.openingBid || null,
        time: edge?.node?.auction?.time || null,
      },
      document: {
        type: edge?.node?.document?.type || null,
        defaultAmount: edge?.node?.document?.defaultAmount || null,
        recordingDate: edge?.node?.document?.recordingDate || null,
        caseNumber: edge?.node?.document?.caseNumber || null,
        documentNumber: edge?.node?.document?.documentNumber || null,

        trusteeInfo: {
          names: edge?.node?.document?.trustee?.contacts
            ?.map(contact => contact?.name?.full || '') || [],
          phone: edge?.node?.document?.trustee?.phone || null,
          address: Address.toNullIfEmpty({
            line1: edge?.node?.document?.trustee?.address?.line1 || '',
            city: edge?.node?.document?.trustee?.address?.city || '',
            state: edge?.node?.document?.trustee?.address?.state || '',
            postalCode: edge?.node?.document?.trustee?.address?.postalCode || '',
          }),
        },
      },
      lien: {
        documentNumber: edge?.node?.lien?.documentNumber || null,
        originalAmount: edge?.node?.lien?.originalAmount || null,
        recordingDate: edge?.node?.lien?.recordingDate || null,
        holder: {
          name: edge?.node?.lien?.lienHolder?.name || null,
        },
        servicer: {
          name: edge?.node?.lien?.servicer?.name || null,
          address: Address.toNullIfEmpty({
            line1: edge?.node?.document?.trustee?.address?.line1 || '',
            city: edge?.node?.document?.trustee?.address?.city || '',
            state: edge?.node?.document?.trustee?.address?.state || '',
            postalCode: edge?.node?.document?.trustee?.address?.postalCode || '',
          }),
        },
      },
    })) || [],

    taxation: {
      assessment: {
        improvements: taxDetail?.assessment?.improvements || null,
        land: taxDetail?.assessment?.land || null,
        total: taxDetail?.assessment?.total || null,
      },
      billed: taxDetail?.billed || null,
      year: taxDetail?.year?.toString() || null,
      exemptions: [
        ownerDetails?.isSenior
          ? 'senior' as const
          : null,
        ownerDetails?.ownerStatus === ParcelOwnerStatus.Military
          ? 'military' as const
          : null,
      ].filter(isNonNull),
    },

    transfers: details.parcel?.transferHistory.edges
      ?.map((edge, i) => edge?.node
        ? mapTransfer({
          rawTransfer: edge?.node,
          isFirstAndHasLastSale: !!rawLastSale && i === 0,
        })
        : null)
      ?.filter(isNonNull)
      || [],

    currentLiens: details.parcel?.currentLiens?.map(rawLoan =>
      mapLien({ rawLoan, isFirstAndHasLastSale: !!rawLastSale })) || [],

    userInputData: lead.property.userInputData,
    originalData: {
      building: originalBuilding,
    },
  }
}

export const ownerClassificationFromGql = (
  inputClassification: ParcelContactClassification | null,
  inputStatus: ParcelOwnerStatus | null,
): OwnerClassification | null => {
  if (inputClassification === ParcelContactClassification.Trust)
    return 'TRUST'
  if (inputClassification === ParcelContactClassification.Bank)
    return 'BANK'
  if (inputClassification === ParcelContactClassification.Corporate)
    return 'CORPORATE'
  if (inputStatus === ParcelOwnerStatus.OwnerOccupied)
    return 'OWNER_OCCUPIED'
  if (inputStatus === ParcelOwnerStatus.InState)
    return 'IN_STATE'
  if (inputStatus === ParcelOwnerStatus.OutOfState)
    return 'OUT_OF_STATE'
  if (inputStatus === ParcelOwnerStatus.OutOfCountry)
    return 'OUT_OF_COUNTRY'
  if (inputStatus === ParcelOwnerStatus.Military)
    return 'MILITARY'
  return null
}

export const mlsStatusFromGql = (
  gql: MlsResoStandardStatus | null | undefined,
): MLSStatus | null => {
  switch (gql) {
  case 'Active':
  case 'ActiveUnderContract':
  case 'ComingSoon':
    return 'FOR_SALE'
  case 'Closed':
    return 'SOLD'
  case 'Canceled':
    return 'CANCELED'
  case 'Expired':
    return 'EXPIRED'
  case 'Pending':
    return 'PENDING'
  case 'Withdrawn':
    return 'WITHDRAWN'
  default:
    return null
  }
}

const mapTransfer = ({ rawTransfer, isFirstAndHasLastSale }: {
  rawTransfer: ParcelTransferFieldsFragment
  isFirstAndHasLastSale: boolean
}): Domain.Transfer => ({
  documentNumber: rawTransfer.documentNumber || null,
  documentType: rawTransfer.documentType || null,
  recordingDate: rawTransfer.recordingDate || null,
  saleDate: rawTransfer.saleDate || null,
  salePrice: rawTransfer.saleAmount || null,
  purchaseMethod: rawTransfer.purchaseMethod || null,
  transactionType: rawTransfer.transactionType || null,
  buyerInfo: rawTransfer.buyers
    ? {
      buyers: rawTransfer.buyers.map(buyer => {
        const name = constructNameFromContactUnion(buyer)
        return name ? { name } : null
      }).filter(isNonNull),
      address: Address.toNullIfEmpty({
        line1: rawTransfer.buyers[0]?.address?.line1 || '',
        city: rawTransfer.buyers[0]?.address?.city || '',
        state: rawTransfer.buyers[0]?.address?.state || '',
        postalCode: rawTransfer.buyers[0]?.address?.postalCode || '',
      }),
    }
    : null,
  sellerInfo: rawTransfer.sellers
    ? {
      sellers: rawTransfer.sellers.map(seller => {
        const name = constructNameFromContactUnion(seller)
        return name ? { name } : null
      }).filter(isNonNull),
      address: Address.toNullIfEmpty({
        line1: rawTransfer.sellers[0]?.address?.line1 || '',
        city: rawTransfer.sellers[0]?.address?.city || '',
        state: rawTransfer.sellers[0]?.address?.state || '',
        postalCode: rawTransfer.sellers[0]?.address?.postalCode || '',
      }),
    }
    : null,
  liens: rawTransfer.liens?.map(rawLoan =>
    mapLien({
      rawLoan,
      isFirstAndHasLastSale,
    })) || [],
})

const constructNameFromContactUnion = (
  input: ParcelContactFieldsFragment | ParcelRawContactFieldsFragment,
): string | null => {
  const nameSource = 'name' in input ? input.name : input.nameRaw
  const nameParts = [
    nameSource?.first,
    nameSource?.middle,
    nameSource?.last,
  ].filter(isDefined)
  let nameConstructed = nameParts.length > 0
    ? nameParts.join(' ')
    : nameSource?.full ?? null

  if (nameConstructed !== null && nameSource?.suffix)
    nameConstructed += `, ${nameSource.suffix}`

  return nameConstructed
}

const mapLien = ({ rawLoan, isFirstAndHasLastSale }: {
  rawLoan: ParcelLienFieldsFragment
  isFirstAndHasLastSale: boolean
}): Domain.Lien => ({
  isActive: !rawLoan.maturityDate
    ? false
    : isFirstAndHasLastSale && isFuture(DateLib.fixDateString(rawLoan.maturityDate)),
  recordingDate: rawLoan.recordingDate || null,
  mortgageType: [
    rawLoan.type || null,
  ].filter(Boolean).join(', '),
  documentNumber: rawLoan.documentNumber || null,
  amount: isNumber(rawLoan.originalAmount) ? rawLoan.originalAmount : null,
  estimatedBalance: rawLoan.estimatedBalance ?? null,
  estimatedPayment: rawLoan.estimatedPayment ?? null,
  holder: {
    name: rawLoan.lienHolder && 'name' in rawLoan.lienHolder && isString(rawLoan.lienHolder.name)
      ? rawLoan.lienHolder.name
      : rawLoan.lienHolder && 'nameRaw' in rawLoan.lienHolder && isString(rawLoan.lienHolder.nameRaw)
        ? rawLoan.lienHolder.nameRaw
        : null,
    address: Address.toNullIfEmpty({
      line1: rawLoan.lienHolder?.address?.line1 || '',
      city: rawLoan.lienHolder?.address?.city || '',
      state: rawLoan.lienHolder?.address?.state || '',
      postalCode: rawLoan.lienHolder?.address?.postalCode || '',
    }),
  },
  interestRatePercent: rawLoan.interestRatePercent ?? null,
  termMonths: rawLoan.termMonths ?? null,
  maturityDate: rawLoan.maturityDate ?? null,
})

const calcPricePerSqft = (
  price: number | null,
  livingAreaSqft: number | null,
): number | null => {
  if (price === null || livingAreaSqft === null)
    return null
  const NO_OF_PLACES = 2
  return NumberLib.roundNPlaces(NO_OF_PLACES)(price / livingAreaSqft)
}

const mapCondition = ({
  rawCondition,
  yearBuilt,
}: {
  rawCondition: ParcelCondition | null
  yearBuilt: string | null
}): Domain.HomeCondition | null => {
  switch (rawCondition) {
  case ParcelCondition.Excellent:
    return yearBuilt && yearBuilt === new Date().getFullYear().toString()
      ? 'C1'
      : 'C2'
  case ParcelCondition.Good:
    return 'C3'
  case ParcelCondition.Fair:
  case ParcelCondition.Average:
    return 'C4'
  case ParcelCondition.Poor:
    return 'C5'
  case ParcelCondition.Unsound:
  case ParcelCondition.Damaged:
  case ParcelCondition.UnderConstruction:
    return 'C6'
  default:
    return null
  }
}

const pickBestValue = <T>(a: T, b: T): T => {
  // pick b if non-empty and a is empty
  // do this check first so zero and false wins over null and undefined
  if (!isNullable(b) && isNullable(a)) return b

  // pick b if truthy and a is falsy
  if (!!b && !a) return b

  // otherwise prefer a
  return a
}

type MlsEdge = GqlPath<
  PropertyDetailsQuery,
  [
    ['parcel', 'Parcel'],
    ['mlsPropertyListings', 'MlsPropertyListingConnection'],
    ['edges', 'MlsPropertyListingEdge'],
  ]
>

/**
 * @IMPORTANT NEVER FALLBACK WITH ANYTHING BUT NULL, when dealing with MLS data
 *   We are legally required to not change the data in any way.
 */
const mapMlsListing = (
  fallbackLocation: Location | null,
) => (
  edge: MlsEdge | null,
): (MLSListing & { hasPool: boolean | null }) | null => {
  const node = edge?.node
  const reso = node?.resoPropertyListing

  if (!node || !reso) return null

  const isRental = (reso.propertyType || '').toLowerCase().includes('lease')
  const location = Location.makeSafe(reso.latitude, reso.longitude)
    || fallbackLocation
  const status = mlsStatusFromGql(reso.standardStatus)

  if (!location || !status || !reso.listingId) return null

  return {
    isRental,
    address: {
      city: reso.city || '',
      line1: [
        reso.streetNumber,
        reso.streetDirPrefix,
        reso.streetName,
        reso.streetDirSuffix,
        reso.streetSuffix,
        reso.unitNumber && `Unit ${reso.unitNumber}`,
      ]
        .filter(i => !!i)
        .join(' '),
      postalCode: reso.postalCode || '',
      state: reso.stateOrProvince || '',
      subdivision: reso.subdivisionName || '',
    },
    bathroomsCountInfo: BathroomsCountInfo.fromMissableFullHalf(
      reso.bathroomsFull,
      reso.bathroomsHalf,
    ),
    bedroomsCount: NumberLib.orNull(reso.bedroomsTotal),
    buildingAreaSqft: NumberLib.orNull(reso.buildingAreaTotal),
    livingAreaSqft: calcLivingAreaSqft(
      reso.livingArea,
      reso.livingAreaUnits,
    ),

    /**
     * @NOTE onMarketTimestamp sounds more appropriate vs originalEntryTimestamp,
     *   but originalEntryTimestamp is more populated. Here's slack convo for
     *   reference:
     *   https://propelio.slack.com/archives/C02FTP88RLZ/p1700174266963049?thread_ts=1700130360.143289&cid=C02FTP88RLZ
     */
    listDate: DateLib.fixDateString(reso.originalEntryTimestamp || null),
    daysOnMarket: reso.daysOnMarket || null,
    description: reso.publicRemarks || null,
    garageSpacesCount: NumberLib.orNull(reso.garageSpaces),
    hasPool: reso.poolPrivateYN ?? null,
    listPrice: (() => {
      if (isNumber(reso.closePrice))
        return reso.closePrice
      if (isNumber(reso.listPrice))
        return reso.listPrice
      return null
    })(),
    listPricePerSqft: isNumber(reso.listPrice) && isNumber(reso.buildingAreaTotal)
      ? reso.listPrice / reso.buildingAreaTotal
      : null,
    location,
    lotAreaAcres: NumberLib.orNull(reso.lotSizeAcres),
    lotAreaSqft: NumberLib.orNull(reso.lotSizeSquareFeet),
    agent: {
      email: reso.listAgentEmail || null,
      name: reso.listAgentFullName || null,
      phone: reso.listAgentPreferredPhone || reso.listAgentDirectPhone || null,
    },
    broker: {
      name: reso.listOfficeName || null,
      phone: reso.listOfficePhone || null,
      email: reso.listOfficeEmail || null,
    },
    id: node.id || '',
    listingId: reso.listingId || '',
    organization: {
      id: node.organization.id,
      logo: node.organization.logoUrl || '',
      name: node.organization.name,
    },
    photos: node.media.edges
      .map(edge => edge?.node?.mediaURL
        ? {
          caption: edge?.node?.shortDescription || null,
          url: edge?.node?.mediaURL,
        }
        : null)
      .filter(isNonNull),
    propertyType: reso.propertyType || null,
    saleDate: reso.closeDate || null,
    salePrice: reso.closePrice || null,
    salePricePerSqft: isNumber(reso.closePrice) && isNumber(reso.buildingAreaTotal)
      ? reso.closePrice / reso.buildingAreaTotal
      : null,
    status,
    yearBuilt: reso.yearBuilt || null,

    style: null,
    parking: null,
    laundry: null,
    interiorFeature: null,
    heatingOrCooling: null,
    hasBasement: null,
    flooring: null,
    fireplacesCount: null,
    fireplace: null,
    exteriorFeature: null,
    exterior: null,
    classification: null,
    driveway: null,
    equityType: null,
    expireDate: null,
    isForeclosure: false,
    isSenior: false,
    isVacant: false,
    buyerAgent: status === 'SOLD' || status === 'LEASED'
      ? {
        name: reso.buyerAgentFullName || null,
        phone: reso.buyerAgentPreferredPhone || reso.buyerAgentDirectPhone || null,
        email: reso.buyerAgentEmail || null,
      }
      : null,
    buyerBroker: status === 'SOLD' || status === 'LEASED'
      ? {
        name: reso.buyerOfficeName || null,
        phone: reso.buyerOfficePhone || null,
        email: reso.buyerOfficeEmail || null,
      }
      : null,
  }
}

const calcLivingAreaSqft = (
  value: number | null | undefined,
  unit: MlsResoAreaUnits | null | undefined,
) => {
  if (!isNumber(value) || !unit) return null
  if (unit === MlsResoAreaUnits.SquareFeet) return value
  if (unit === MlsResoAreaUnits.SquareMeters) return value * 10.7639
  return null
}
