import { useEffect } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type State = {
  stickyElement: HTMLDivElement | null
  tableElement: HTMLDivElement | null
  isOverreaching: boolean
}

const store = create<State>()(immer(_set => ({
  stickyElement: null,
  tableElement: null,
  isOverreaching: false,
})))

const api = {
  useStore: store,

  setStickyElement: (element: HTMLDivElement | null) =>
    store.setState(state => {
      state.stickyElement = (element as any)
    }),

  setTableElement: (element: HTMLDivElement | null) =>
    store.setState(state => {
      state.tableElement = (element as any)
    }),

  useSetupScrollListener: () => {
    useEffect(() => {
      const handleScroll = () => {
        const { stickyElement, tableElement } = store.getState()

        if (!stickyElement || !tableElement) return

        const tableRect = tableElement.getBoundingClientRect()
        const stickyRect = stickyElement.getBoundingClientRect()

        // did bottom of sticky element go beyond bottom of table element
        const isOverreaching = stickyRect.bottom > tableRect.bottom

        store.setState(state => {
          state.isOverreaching = isOverreaching
        })
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])
  },
}

export const CompsTableStickyOverreachFix = api
