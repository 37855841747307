import { Box, BoxProps, Button, Center, Checkbox, Divider, Flex, Grid, HStack, IconButton, IconButtonProps, Menu, MenuButton, Spinner, Table, Tag, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import { Array, Match, Option, Record, pipe } from 'effect'
import Buyer from 'features/NearbyBuyers/domain/Buyer'
import NearbyBuyerHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerHooks'
import NearbyBuyerMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerMachine'
import NearbyBuyersHooks from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyersHooks'
import { serializeDealInfo } from 'features/NearbyBuyers/repository/GetNearbyBuyers.schema'
import { Address } from 'features/valueObjects/Address'
import { DateLib } from 'libs/Date'
import { Dollar } from 'libs/dollar'
import pluralize from 'pluralize'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon } from 'presentation/components/Icons'
import { Map, MapStoreProvider } from 'presentation/components/Map'
import { MapHooks } from 'presentation/components/Map/MapHooks'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { ResponsiveMenuList } from 'presentation/components/ResponsiveMenu'
import { Card } from 'presentation/components/molecules/Card'
import { TableScroller } from 'presentation/components/molecules/Table'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { Fragment, PropsWithChildren, useState } from 'react'
import { Marker } from 'react-map-gl'

export const NEARBY_BUYERS_TEST_ID = {
  CARD_HOVER: 'nearby-buyers-hover-test-id',
  EXPAND_BUTTON: 'nearby-buyers-expand-button-test-id',
  PURCHASE_POPOVER: 'nearby-buyers-purchase-popover-test-id',
}

const NearbyBuyersItem = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const setup = NearbyBuyerHooks.useSetup(actorRef)
  const isHighlighted = NearbyBuyerHooks.useIsHighlighted(actorRef)
  const isExpanded = NearbyBuyerHooks.useIsExpanded(actorRef)
  const setToggleExpand = NearbyBuyerHooks.useToggleExpand(actorRef)
  const buyer = NearbyBuyerHooks.useBuyer(actorRef)
  const isExpandLoading = NearbyBuyerHooks.useIsBuyerHistoryLoading(actorRef)

  return (
    <Card
      data-testid={NEARBY_BUYERS_TEST_ID.CARD_HOVER}
      ref={setup as any}
      size='xxs-locked'
      w='full'
      minW={0}
      borderWidth={0.25}
      {...isHighlighted ? { borderColor: 'neutral.500' } : {}}
      {...isExpanded ? { bgColor: 'highlight.100' } : {}}
      pr={2}
    >
      <Box w='full' minW={0} flex='1 1 0'>
        <CollapsedContent
          buyer={buyer}
          onExpandToggle={setToggleExpand}
        />

        {isExpanded && (
          isExpandLoading
            ? (
              <Center p={3}>
                <Spinner />
              </Center>
            )
            : (
              <ExpandedContent actorRef={actorRef} />
            )
        )}
      </Box>
    </Card>
  )
}

export default NearbyBuyersItem

type CollapsedContentProps = {
  buyer: Buyer
  onExpandToggle: () => void
}
const CollapsedContent = ({
  buyer: buyer,
  onExpandToggle,
}: CollapsedContentProps) => {
  const openPropertyModal = NearbyBuyersHooks.useOpenPropertyModal()

  const formattedOwnerName = buyer.name.pipe(
    Option.getOrElse(() => 'Unknown Owner'),
  )

  return (
    <Flex
      w='full'
      minW={0}
      gap={mbp({
        mobSm: 2,
        tabSm: 0,
      })}
      flexDir={mbp({
        mobSm: 'column',
        tabSm: 'row',
      })}
    >
      <Flex
        flexDir='column'
        minW={0}
        flex='1 1 0'
      >
        <Flex w='full' minW={0} gap={0.5} alignItems='center'>

          {/* {isHovered && <Checkbox />} */}

          {/**
            @NOTE: Bonus feature
            <SkiptraceSuccessIcon boxSize={3} />
            <SkiptraceFailedIcon boxSize={3} color='negative.500' />
          */}

          <Text
            textStyle='bodyXLFat'
            color='graystrong.400'
            minW={0}
            isTruncated
            title={formattedOwnerName}
          >
            {formattedOwnerName}
          </Text>

          {buyer.buyerHistory.propertiesCount.pipe(
            Option.flatMap(Option.liftPredicate(count => count > 1)),
            Option.map(count => (
              <PropCountPill
                key='prop-count'
                ml={mbp({
                  mobSm: '0',
                  tabSm: 'auto',
                })}
                flexShrink={0}
              >
                {count}
              </PropCountPill>
            )),
            Option.getOrElse(() => null),
          )}

          <Breakpoint
            mobSm={(
              <Box ml='auto' pl={0.5} flex='0 0 0'>
                <ExpandButton onClick={onExpandToggle} />
              </Box>
            )}
            tabSm={<></>}
          />
        </Flex>

        <Box
          key='buyer-address'
          textStyle='bodyM'
          color='link.500'
          mt={0.5}
        >
          {buyer.address.pipe(
            Option.map(address => {
              const line1 = address.line1
              const cityStateZip = Address.formatCityStateZip(address)

              return (
                <>
                  <Text color='inherit' title={line1} isTruncated>
                    <span
                      role='button'
                      onClick={() => openPropertyModal({ address })}
                    >
                      {line1}
                    </span>
                  </Text>
                  <Text color='inherit' title={cityStateZip} isTruncated>
                    <span
                      role='button'
                      onClick={() => openPropertyModal({ address })}
                    >
                      {cityStateZip}
                    </span>
                  </Text>
                </>
              )
            }),
            Option.getOrElse(() => (
              <>
                <Text color='inherit'>--</Text>
                <Text color='inherit'>--</Text>
              </>
            )),
          )}
        </Box>

        <Flex
          gap={0.5}
          mt={mbp({
            mobSm: '0.5',
            tabSm: 'auto',
          })}
        >
          {buyer.buyerType.pipe(
            Option.map(types => (
              <Fragment key='pills'>
                {/**
                  * @NOTE: Bonus feature
                  * {types.includes('neighbor') && (
                  *   <NeighborPill />
                  * )}
                  */}
                {types.includes('landlord') && (
                  <LandlordPill />
                )}
                {types.includes('flipper') && (
                  <FlipperPill />
                )}
              </Fragment>
            )),
            Option.getOrNull,
          )}
        </Flex>
      </Flex>

      <Grid
        pl={mbp({
          mobSm: 0,
          tabSm: 2,
        })}
        flex='0 0 55%'
        gridTemplateColumns='auto auto'
        rowGap={1}
        columnGap={1}
      >
        <Pair>
          <PairKey w='max-content'>Average Deal</PairKey>
          <PairValue>
            {buyer.buyerHistory.averageDealAmount.pipe(
              Option.map(Dollar.formatNumberWithCommas),
              Option.getOrElse(() => '--'),
            )}
          </PairValue>
        </Pair>

        <Pair>
          <PairKey
            w='max-content'
            as={Flex}
            gap={0.25}
            alignItems='center'
          >
            Total Deal
          </PairKey>
          <PairValue>
            {buyer.buyerHistory.totalDealsCount.pipe(
              Option.flatMap(Option.liftPredicate(count => count > 0)),
              Option.map(totalDealsCount => (
                <>
                  {buyer.buyerHistory.totalDealAmount.pipe(
                    Option.map(Dollar.formatNumberWithK),
                    Option.getOrElse(() => '--'),
                  )}
                  {' '}
                  in
                  {' '}
                  {pluralize('deal', totalDealsCount, true)}
                </>
              )),
              Option.getOrElse(() => '--'),
            )}
          </PairValue>
        </Pair>

        <Pair>
          <PairKey>Last Deal</PairKey>
          <PairValue w='max-content'>
            {pipe(
              buyer.buyerHistory.lastDeal,
              Option.map(deal => {
                if (
                  Option.isNone(deal.purchaseDate)
                  && Option.isNone(deal.purchaseAmount)
                ) return '--'

                const purchaseDateFormatted = deal.purchaseDate.pipe(
                  Option.map(DateLib.formatMMDDYYDots),
                  Option.getOrElse(() => '--'),
                )

                const purchaseAmountFormatted = deal.purchaseAmount.pipe(
                  Option.map(Dollar.formatNumberWithCommas),
                  Option.getOrElse(() => '--'),
                )

                return (
                  <>
                    {purchaseDateFormatted}
                    :
                    <br />
                    {purchaseAmountFormatted}
                  </>
                )
              }),
              Option.getOrElse(() => '--'),
            )}
          </PairValue>
        </Pair>

        <Pair>
          <PairKey>Price Range</PairKey>
          <PairValue w='max-content'>
            {(() => {
              const minDealFormatted = buyer.buyerHistory.dealAmountRange.pipe(
                Option.map(range => range[0]),
                Option.map(Dollar.formatNumberWithCommas),
                Option.getOrElse(() => '--'),
              )

              const maxDealFormatted = buyer.buyerHistory.dealAmountRange.pipe(
                Option.map(range => range[1]),
                Option.map(Dollar.formatNumberWithCommas),
                Option.getOrElse(() => '--'),
              )

              return (
                <>
                  {minDealFormatted}
                  {' '}
                  -
                  <br />
                  {maxDealFormatted}
                </>
              )
            })()}
          </PairValue>
        </Pair>
      </Grid>
      <Breakpoint
        tabSm={(
          <Box pl={0.5} flex='0 0 0'>
            <ExpandButton onClick={onExpandToggle} />
          </Box>
        )}
      />
    </Flex>
  )
}

const ExpandedContent = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const openMobileBuyerHistory = NearbyBuyerHooks.useOpenMobileBuyerHistoryModal(actorRef)

  return (
    <Box>
      <Breakpoint
        mobSm={(
          <Button
            colorScheme='neutral'
            variant='outline'
            w='full'
            mt={2}
            onClick={openMobileBuyerHistory}
          >
            View Buyers History
          </Button>
        )}
        mob={(
          <Flex justifyContent='right' mt={2}>
            <Button
              colorScheme='neutral'
              variant='outline'
              onClick={openMobileBuyerHistory}
            >
              View Buyers History
            </Button>
          </Flex>
        )}
        tabSm={(
          <>
            <Box py={3}>
              <Divider
                variant='solid'
                orientation='horizontal'
                borderColor='grayweak.500'
              />
            </Box>
            <BuyerHistorySection actorRef={actorRef} />
          </>
        )}
      />
    </Box>
  )
}

const BuyerHistorySection = ({
  actorRef,
  ...props
}: BoxProps & {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const shouldDisplayPropertiesOutsideLocation = NearbyBuyerHooks.useShouldDisplayPropertiesOutsideLocation(actorRef)
  const toggleDisplayPropertiesOutsideLocation = NearbyBuyerHooks.useToggleDisplayPropertiesOutsideLocation(actorRef)

  return (
    <Box {...props}>
      <Text
        textStyle='bodyXLFat'
        color='graystrong.500'
      >
        Buyer History
      </Text>

      <Flex
        alignItems='center'
        justifyContent='space-between'
        h={4}
      >
        <Flex gap={1} alignItems='baseline'>
          <Text textStyle='tagL' color='graystrong.200'>
            PURCHASES:
          </Text>
          <PurchasesPopover actorRef={actorRef} />
        </Flex>
        <Checkbox
          isChecked={shouldDisplayPropertiesOutsideLocation}
          onChange={toggleDisplayPropertiesOutsideLocation}
        >
          <Text
            textStyle='bodySFat'
            color='graystrong.400'
          >
            Include props outside location
          </Text>
        </Checkbox>
      </Flex>

      <BaseTabsContainer mt={1}>
        <BaseTabs>
          <BaseTab
            isActive={activeTab === 0}
            onClick={() => setActiveTab(0)}
          >
            Table
          </BaseTab>
          <BaseTab
            isActive={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            Map
          </BaseTab>
        </BaseTabs>
        <BaseTabContent p={2} h='450px'>
          {Match.value(activeTab).pipe(
            Match.when(0, () => <BuyerHistoryTable actorRef={actorRef} />),
            Match.when(1, () => <BuyerHistoryMap actorRef={actorRef} />),
            Match.orElse(() => null),
          )}
        </BaseTabContent>
      </BaseTabsContainer>
    </Box>
  )
}

export const BuyerHistoryMap = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => (
  <MapStoreProvider>
    <BuyerHistoryMapContent
      actorRef={actorRef}
    />
  </MapStoreProvider>
)

const BuyerHistoryMapContent = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const mapMountEvents = NearbyBuyerHooks.useMapMountEvents(actorRef)
  const properties = NearbyBuyerHooks.useDisplayedProperties(actorRef)

  MapHooks.useOnMapReady(ref => {
    mapMountEvents.onMount(ref)

    return mapMountEvents.onUnmount
  })

  return (
    <Map
      style={{
        height: '100%',
        borderRadius: '24px',
      }}
    >
      {properties.map(property => (
        <BuyerMapMarker key={property.id} property={property} />
      ))}
    </Map>
  )
}

const BuyerMapMarker = ({
  property,
}: {
  property: Buyer.Property
}) => (
  <Marker
    latitude={property.location.latitude}
    longitude={property.location.longitude}
    anchor='bottom'
  >
    <PropertyMarker
      classification={property.ownershipInfo.classification.pipe(Option.getOrNull)}
      equityType={property.valueInfo.equityType.pipe(Option.getOrNull)}
      isForeclosure={property.valueInfo.isForeclosure}
      isSenior={property.ownershipInfo.isSenior}
      isVacant={property.ownershipInfo.isVacant}
      markerType='pin'
      size='md'
    />
  </Marker>
)

export const BuyerHistoryTable = ({
  actorRef,
  ...props
}: BoxProps & {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const deals = NearbyBuyerHooks.useDisplayedBuyerDeals(actorRef)

  return (
    <TableScroller
      h='full'
      borderRadius={3}
      {...props}
    >
      <Table>
        <Thead
          position='sticky'
          top='0'
          sx={{
            th: {
              bgColor: 'neutral.400',
            },
          }}
        >
          <Tr>
            <Th w='max-content'>Property Address</Th>
            <Th textAlign='right'>Purchase Amt/Date</Th>
            <Th textAlign='right'>Sold Amt/Date</Th>
            <Th textAlign='right'>Rental Amt/Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deals.map(deal => (
            <NearbyBuyerDealRow
              key={serializeDealInfo(deal)}
              deal={deal}
            />
          ))}
        </Tbody>
      </Table>
    </TableScroller>
  )
}

export const NearbyBuyerDealRow = ({
  deal,
}: {
  deal: Buyer.Deal
}) => {
  const openPropertyModal = NearbyBuyersHooks.useOpenPropertyModal()
  const address = deal.property.address
  const propertyModalParams = deal.property.parcelId.pipe(
    Option.flatMap(Option.liftPredicate(() => Address.isValid(address))),
    Option.map(parcelId => ({ parcelId })),
    Option.orElse(() =>
      Address.isValid(address)
        ? Option.some({ address })
        : Option.none(),
    ),
  )
  const canOpenPropertyModal = Option.isSome(propertyModalParams)

  const handleAddressClick = () => {
    if (Option.isNone(propertyModalParams)) return

    openPropertyModal(propertyModalParams.value)
  }

  return (
    <Tr>
      <Td>
        <Box textStyle='bodyM' color={canOpenPropertyModal ? 'link.500' : 'inherit'}>
          <Text
            color='inherit'
            as={canOpenPropertyModal ? 'button' : 'span'}
            textAlign='left'
            onClick={handleAddressClick}
          >
            {address.line1}
          </Text>

          <Text
            color='inherit'
            as={canOpenPropertyModal ? 'button' : 'span'}
            textAlign='left'
            onClick={handleAddressClick}
          >
            {Address.formatCityStateZip(address)}
          </Text>
        </Box>

        {/* <Flex mt={0.5} gap={0.5}>
        <FlipperPill />
      </Flex> */}

      </Td>
      <Td textAlign='right'>
        {deal.purchaseAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        {' '}
        /
        {' '}
        {deal.purchaseDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
      <Td textAlign='right'>
        {deal.soldAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        {' '}
        /
        {' '}
        {deal.soldDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
      <Td textAlign='right'>
        {deal.rentAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        {' '}
        /
        {' '}
        {deal.rentDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
    </Tr>
  )
}

export const PurchasesPopover = ({
  actorRef,
}: {
  actorRef: NearbyBuyerMachine.ActorRef
}) => {
  const {
    counties,
    states,
    countiesByState,
  } = NearbyBuyerHooks.useDealsGeographicInfo(actorRef)

  return (
    <Menu>
      <MenuButton>
        <Text textStyle='bodyM' color='link.500'>
          {/* 4 States and 4 Counties */}
          {pluralize('State', states.length, true)}
          {' '}
          and
          {' '}
          {pluralize('County', counties.length, true)}
        </Text>
      </MenuButton>
      <ResponsiveMenuList>
        <Flex
          flexDirection='column'
          gap={1}
          p={2}
        >
          {pipe(
            countiesByState,
            Record.toEntries,
            Array.map(([state, counties]) => (
              <VStack spacing='1' align='left' key={state}>
                <VStack align='left' justify='center' h={5}>
                  <Text
                    textStyle='bodyLFat'
                    color='grayweak.900'
                  >
                    {state || 'Unknown State'}
                    :
                    {' '}
                  </Text>
                </VStack>
                <HStack>
                  {counties.map(county => (
                    <LocationPill key={county}>
                      {county}
                    </LocationPill>
                  ))}
                </HStack>
              </VStack>
            )),
          )}
        </Flex>
      </ResponsiveMenuList>
    </Menu>
  )
}

const ExpandButton = (props: Omit<IconButtonProps, 'aria-label'>) => (
  <IconButton
    colorScheme='onlight'
    variant='icon-ghost'
    alignSelf='flex-start'
    icon={<ChevronDownIcon fontSize={3} />}
    aria-label='expand button'
    {...props}
  />
)

/**
const NeighborPill = () => (
  <Box
    w='min-content'
    textStyle='bodyM'
    color='graystrong.600'
    px='1'
    py='0.5'
    bgColor='positive.50'
    borderColor='positive.700'
    borderWidth={0.125}
    borderRadius={2.5}
  >
    Neighbor
  </Box>
)
*/

// #region: Pills
export const FlipperPill = () => (
  <Box
    w='min-content'
    textStyle='bodyM'
    color='graystrong.600'
    px='1'
    py='0.5'
    bgColor='warm.100'
    borderColor='warm.700'
    borderWidth={0.125}
    borderRadius={2.5}
  >
    Flipper
  </Box>
)

export const LandlordPill = () => (
  <Box
    w='min-content'
    textStyle='bodyM'
    color='graystrong.600'
    px='1'
    py='0.5'
    bgColor='neutral.100'
    borderColor='neutral.700'
    borderWidth={0.125}
    borderRadius={2.5}
  >
    Landlord
  </Box>
)

export const PropCountPill = ({ children, ...props }: BoxProps) => (
  <Box
    textStyle='bodyMFat'
    color='ondark.1'
    bgColor='positive.500'
    borderRadius='100px'
    h='2.5'
    px={1}
    w='max-content'
    {...props}
  >
    {children}
    {' '}
    Props
  </Box>
)

const LocationPill = ({ children }: PropsWithChildren) => (
  <Tag colorScheme='highlight'>
    <Text textStyle='bodyM' color='graystrong.600'>
      {children}
    </Text>
  </Tag>
)
