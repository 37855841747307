import { Text, TextProps } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { useCMAStore } from 'features/CMA/infra/react/CMAState'
import { selectCompsBy } from 'features/CMA/infra/react/CMAState.selectors'
import pluralize from 'pluralize'
import { useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'

export const CompsCount = (props: TextProps) => {
  const { selectedListType: activeTab } = useActiveEntriesTab()
  const { comps } = useCMAStore(selectCompsBy(activeTab))
  const status = useCMAStore(state => state.local.report.status)

  return (
    <Text
      textStyle='bodyM'
      color='ondark.1'
      {...props}
    >
      {
        pipe(
          Match.value(status),
          Match.when('filtering', () => 'Filtering...'),
          Match.when('regenerating', () => 'Updating...'),
          Match.orElse(() => pluralize('result', comps.length, true)),
        )
      }
    </Text>
  )
}
