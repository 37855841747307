import { Box, BoxProps, useToken } from '@chakra-ui/react'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { Map, useMapStore } from 'presentation/components/Map'
import { ComponentProps, PropsWithChildren, useEffect } from 'react'
import MapFromMapbox from 'react-map-gl'
import { shallow } from 'zustand/shallow'

export type TabbedMapProps = PropsWithChildren<{
  containerProps?: BoxProps
} & Partial<ComponentProps<typeof MapFromMapbox>>>

export const TabbedMap = ({
  children,
  containerProps,
  ...mapProps
}: TabbedMapProps) => {
  const { mapRef, defaultZoom, setMode, mode } = useMapStore(store => ({
    mapRef: store.computed.getMapRef(),
    defaultZoom: store.defaultZoom,
    setMode: store.setMode,
    mode: store.mode,
  }), shallow)

  useEffect(() => {
    mapRef?.resize()
  }, [mapRef])

  const borderRadius = useToken('radii', 2)

  const handlActiveTabKeyChange = (tabKey: string) => {
    if (tabKey === 'map')
      setMode('map')
    else if (tabKey === 'satellite')
      setMode('satellite')
  }

  return (
    <BaseTabsContainer
      colorScheme='strong'
      w='full'
      h='full'
      pos='relative'
      display='flex'
      flexDirection='column'
    >
      <BaseTabs>
        <BaseTab
          isActive={mode === 'map'}
          onClick={() => handlActiveTabKeyChange('map')}
        >
          Map
        </BaseTab>
        <BaseTab
          isActive={mode === 'satellite'}
          onClick={() => handlActiveTabKeyChange('satellite')}
        >
          Satellite
        </BaseTab>
      </BaseTabs>
      <BaseTabContent
        flex='1 1 0'
        p={1}
        overflow='hidden'
        boxShadow='float'
        bg='red'
        {...containerProps}
      >
        <Box h='full'>
          <Map
            style={{
              borderRadius,
            }}
            initialViewState={{
              zoom: defaultZoom,
            }}
            {...mapProps}
          >
            {children}
          </Map>
        </Box>
      </BaseTabContent>
    </BaseTabsContainer>
  )
}
