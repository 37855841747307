import { Grid, VStack } from '@chakra-ui/react'
import { Array, Option } from 'effect'
import { EmphasisText, FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'
import { Fragment } from 'react'
import LeadsFeatureRowViewModel from './LeadsFeatureRowViewModel'

const LeadsFeatureRow = () => {
  const vm = LeadsFeatureRowViewModel.usePresenter()
  return (
    <Grid
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Marketing Leads
        </PrimaryText>
        <SecondaryText>
          Equity Filters: Preforeclosure: Absentee Owner:
          Cash Buyers: Private Lender: and more.
        </SecondaryText>
      </VStack>
      {Array.map(vm, (column, i) => (
        <VStack justify='center' spacing='1' key={i}>
          {column.pipe(
            Option.match({
              onNone: () => (
                <FeatureAvailability isAvailable={false} />
              ),
              onSome: lead => (
                <Fragment key={i}>
                  <PrimaryText>
                    {lead.primaryText}
                  </PrimaryText>
                  {lead.secondaryText.pipe(
                    Option.map(text => (
                      <SecondaryText key='secondary'>
                        {text}
                      </SecondaryText>
                    )),
                    Option.getOrNull,
                  )}
                  {lead.emphasisText.pipe(
                    Option.map(text => (
                      <EmphasisText key='emphasis'>
                        {text}
                      </EmphasisText>
                    )),
                    Option.getOrNull,
                  )}
                </Fragment>
              ),
            }),
          )}
        </VStack>
      ))}
    </Grid>
  )
}

export default LeadsFeatureRow
