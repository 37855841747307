import { Schema as S } from '@effect/schema'
import { Match, Option, String, pipe } from 'effect'

const schema = S.Literal(
  'town-house',
  'multi-family',
  'lot-or-land',
  'commercial',
  'single-family',
  'condo',
  'mobile',
  'farm',
  'duplex-to-fourplex',
)

type HomeType = typeof schema.Type

const toString = (homeType: HomeType): string =>
  pipe(
    Match.value(homeType),
    Match.when('town-house', () => 'Town House'),
    Match.when('multi-family', () => 'Multi Family'),
    Match.when('lot-or-land', () => 'Lot/Land'),
    Match.when('single-family', () => 'Single Family'),
    Match.when('commercial', () => 'Commercial'),
    Match.when('condo', () => 'Condo'),
    Match.when('mobile', () => 'Mobile Home'),
    Match.when('farm', () => 'Farm'),
    Match.when('duplex-to-fourplex', () => 'Duplex - Fourplex'),
    Match.exhaustive,
  )

const fromString = (value: string): Option.Option<HomeType> =>
  pipe(
    Match.value(value),
    Match.when('town-house', v => v as HomeType),
    Match.when('multi-family', v => v as HomeType),
    Match.when('lot-or-land', v => v as HomeType),
    Match.when('single-family', v => v as HomeType),
    Match.when('commercial', v => v as HomeType),
    Match.when('condo', v => v as HomeType),
    Match.when('mobile', v => v as HomeType),
    Match.when('farm', v => v as HomeType),
    Match.when('duplex-to-fourplex', v => v as HomeType),
    Match.option,
  )

const HomeType = {
  Schema: schema,
  toArray: () => schema.literals,
  getOrAnyType: (homeType: Option.Option<HomeType>) => homeType.pipe(
    Option.map(toString),
    Option.getOrElse(() => 'Any Type'),
  ),
  toString,
  fromString,
  toStringUnstandardized: (value: string) => pipe(
    fromString(value),
    Option.map(toString),
    Option.flatMap(v => String.isEmpty(v)
      ? Option.none()
      : Option.some(v)),
  ),
}

export default HomeType
