import { Grid, VStack } from '@chakra-ui/react'
import { Option, Array } from 'effect'
import TeamFeatureRowViewModel from './TeamFeatureRowViewModel'
import { FeatureAvailability, PrimaryText, SecondaryText } from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/common'

const TeamFeatureRow = () => {
  const vm = TeamFeatureRowViewModel.usePresenter()
  return (
    <Grid
      id='teamFeatureRow'
      gridTemplateColumns='subgrid'
      gridColumn='1 / -1'
    >
      <VStack
        py='3'
        px={{
          base: '3',
          dtSm: '4',
        }}
        spacing='1'
        align='flex-start'
      >
        <PrimaryText>
          Team Members
        </PrimaryText>
        <SecondaryText>
          Additional team members are only
          {' '}
          {vm.descriptionPriceText}
          {' '}
          with configurable user roles to control their access
        </SecondaryText>
      </VStack>
      {Array.map(vm.primaryText, (column, i) => (
        <VStack justify='center' spacing='1' key={i}>
          {column.pipe(
            Option.match({
              onSome: content => (
                <PrimaryText>
                  {content}
                </PrimaryText>
              ),
              onNone: () => (
                <FeatureAvailability isAvailable={false} />
              ),
            }),
          )}
        </VStack>
      ))}
    </Grid>

  )
}

export default TeamFeatureRow
