import * as S from '@effect/schema/Schema'
import { Effect } from 'effect'
import { LocationCity, LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { GETSearchCitiesResponse } from 'features/ListBuilder/infra/services/LocationServiceLive/GETSearchCities/GETSearchCitiesResponse'
import { restClient } from 'presentation/libs/client'

const request = (keyword: string) =>
  Effect.promise(async () =>
    await restClient.get(`parcels/v1/cities?name=${keyword}`).json())

const decode = (u: unknown): GETSearchCitiesResponse =>
  S.decodeUnknownSync(GETSearchCitiesResponse)(u)

const toDomain = (response: GETSearchCitiesResponse): LocationCity[] =>
  response.map(item => LocationCriteria.City(`${item.city}, ${item.state}`))

export const GETSearchCities = {
  request,
  decode,
  toDomain,
}
