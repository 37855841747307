import { TracersQuery } from '__generated__/graphql'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { isString } from 'remeda'

export const mapGetTracersResponse = (input: TracersQuery): { tracers: Skiptrace.Tracer[] } =>
  ({
    tracers: input.myEnterprise?.users?.edges.map(edge => ({
      id: edge.node.id,
      name: [edge.node.firstname, edge.node.lastname]
        .filter(isString)
        .map(name => name.trim())
        .join(' '),
    })) || [],
  })
