import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { EquityType } from 'features/valueObjects/EquityType'
import { isNumber } from 'remeda'

export const patchValueFields = (
  property: PropertyDetails.PropertyWithDetails,
): PropertyDetails.PropertyWithDetails => {
  const equityOrig = property.valuation?.equity
  const equityTypeOrig = property.valuation?.equityType
  const lastsalePriceOrig = property.lastSale?.salePrice
  const valuationOrig = property.valuation?.estimateRange?.price.avg
  const loanToValueOrig = property.valuation?.loanToValue

  let didPatch = false

  // patch equity if it's missing
  if (
    !isNumber(equityOrig)
    && isNumber(lastsalePriceOrig)
    && isNumber(valuationOrig)
  ) {
    didPatch = true

    property.valuation = {
      ...EMPTY_VALUATION,
      ...property.valuation,
      equity: valuationOrig - lastsalePriceOrig,
    }
  }

  // patch loanToValue if it's missing
  if (
    !isNumber(loanToValueOrig)
    && isNumber(valuationOrig)
    && isNumber(lastsalePriceOrig)
  ) {
    didPatch = true

    property.valuation = {
      ...EMPTY_VALUATION,
      ...property.valuation,
      loanToValue: (lastsalePriceOrig / valuationOrig) * 100,
    }
  }

  // patch equityType if it's missing
  if (
    !equityTypeOrig
    && isNumber(loanToValueOrig)
  ) {
    didPatch = true

    property.valuation = {
      ...EMPTY_VALUATION,
      ...property.valuation,
      equityType: EquityType.fromLtv(
        loanToValueOrig,
      ),
    }
  }

  // Keep patching until nothing changes. When any of fields get patched, its
  // possible that it enabled another field to be patched in next iteration.
  if (didPatch)
    return patchValueFields(property)
  else
    return property
}

const EMPTY_VALUATION = {
  equity: null,
  equityType: null,
  estimateRange: null,
  loanToValue: null,
} satisfies PropertyDetails.Valuation
