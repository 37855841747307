import { Box, BoxProps } from '@chakra-ui/react'
import { useLayoutStore } from 'presentation/layouts/Layout/hooks/useLayoutStore'
import { FC, useEffect } from 'react'

/**
 * Fills up remaining height of the layout minus header/notices
 *
 * @GOTCHA using height: 100% on a child of this component will not work,
 *   because height: 100% doesn't work inside parent with height: auto.
 */
export const LayoutBody: FC<BoxProps> = props => {
  const height = useLayoutBodyHeightCSSValue()
  return (
    <Box
      minH={height}
      {...props}
    />
  )
}

/**
 * Alias for LayoutBody because <LayoutBody><LayoutMinHeight /><LayoutBody>
 * looks more natural than <LayoutBody><LayoutBody /><LayoutBody>, in case
 * you need to nest it
 */
export const LayoutBodyHeight: FC<BoxProps & {
  heightMode?: 'min' | 'fixed'
}> = ({ heightMode, ...props }) => {
  const height = useLayoutBodyHeightCSSValue()
  return (
    <Box
      {...heightMode === 'min' ? { minH: height } : { h: height }}
      {...props}
    />
  )
}

export const useLayoutBodyHeightCSSValue = () => {
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))
  const height = `calc(var(--app-height) - ${headerHeight}px)`
  return height
}

export const useLayoutBodyHeightCSSVar = () => {
  const { headerHeight } = useLayoutStore(store => ({ headerHeight: store.totalHeaderHeight }))

  const updateCssVar = () => {
    const doc = document.documentElement
    const height = `calc(var(--app-height) - ${headerHeight}px)`
    doc.style.setProperty('--layout-height', height)
  }

  useEffect(() => {
    updateCssVar()
    window.addEventListener('resize', updateCssVar)

    return () => {
      window.removeEventListener('resize', updateCssVar)
    }
  }, [headerHeight])
}
