import { modals } from 'presentation/main/modals/modals'
import { ChoosePlanModal } from './components/ChoosePlanFlow/ChoosePlanModal'
import { LegacyPlanModal } from './components/LegacyPlanModal'
import { WalletBalanceEntryModal } from './components/WalletAddBalanceFlow/WalletBalanceEntryModal'
import { WalletPaymentDetailsModal } from './components/WalletAddBalanceFlow/WalletPaymentDetailsModal'

modals.register('WALLET_BALANCE_ENTRY_MODAL', WalletBalanceEntryModal)
modals.register('WALLET_PAYMENT_DETAILS_MODAL', WalletPaymentDetailsModal)
modals.register('LEGACY_PLAN_MODAL', LegacyPlanModal)
modals.register('CHOOSE_PLAN_MODAL', ChoosePlanModal)
