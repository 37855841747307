import { Box } from '@chakra-ui/react'
import { Match, pipe } from 'effect'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer } from 'presentation/components/BaseTabs/BaseTabs'
import { CMAFullscreenPanelProps } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/CMAFullscreenPanel.props'
import { CMAEntriesTable } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/CMAEntriesTable'
import { EntriesTableProvider } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAEntriesTable/hooks/useEntriesTableStore'
import { CMAFullscreenReport } from 'presentation/screens/CompsScreen/components/CMAFullscreenPanel/components/CMAFullscreenReport'
import { openCMARentalsTab, openCMASalesTab, openCMASummaryTab, selectActiveTab, useActiveEntriesTab } from 'presentation/screens/CompsScreen/hooks/useActiveEntriesTab'

export const CMA_FULLSCREEN_PANEL__VIEW_CMA_BUTTON_ID = 'cma-fullscreen-panel__view-cma-buton'
export const CMA_FULLSCREEN_PANEL__VIEW_MAP_BUTTON_ID = 'cma-fullscreen-panel__view-map-buton'

export const CMAFullscreenPanel = (props: CMAFullscreenPanelProps) => {
  const activeTab = useActiveEntriesTab(selectActiveTab)

  return (
    <Box {...props}>
      <BaseTabsContainer
        pointerEvents='auto'
        flex='0 1 0'
        zIndex={1}
        overflowY='initial'
        size='md'
        colorScheme='xweak'
      >
        <BaseTabs>
          <BaseTab
            isActive={activeTab === 'SALES'}
            onClick={openCMASalesTab}
          >
            SALES
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'RENTALS'}
            onClick={openCMARentalsTab}
          >
            RENTALS
          </BaseTab>
          <BaseTab
            isActive={activeTab === 'REPORT'}
            onClick={openCMASummaryTab}
          >
            REPORT
          </BaseTab>
        </BaseTabs>
        <BaseTabContent
          overflow='initial'
          sx={{
            borderTopRightRadius: 0,
          }}
        >
          {pipe(
            Match.value(activeTab),
            Match.when('SALES', () => (
              <EntriesTableProvider type='SALES'>
                <CMAEntriesTable />
              </EntriesTableProvider>
            )),
            Match.when('RENTALS', () => (
              <EntriesTableProvider type='RENTALS'>
                <CMAEntriesTable />
              </EntriesTableProvider>
            )),
            Match.when('REPORT', () => (
              <CMAFullscreenReport />
            )),
            Match.exhaustive,
          )}
        </BaseTabContent>
      </BaseTabsContainer>
    </Box>
  )
}
