import { Settings } from 'features/Settings/domain/Settings.domain'
import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { DeepPartial } from 'libs/utils.types'
import { useEntriesToolbarMenu } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { useEffect, useState } from 'react'
import { identity, isBoolean, merge, pipe } from 'remeda'

/**
 * Syncs the view UI settings of CompsScreen with the useEntriesToolbarMenu hook
 */
export const useSyncUISettings = () => {
  const local = useEntriesToolbarMenu(state => ({
    /**
     * Yes, you want the raw value here for syncing purposes.
     *
     * You only want to use the responsive value when you're actually
     * rendering the view.
     */
    viewValue: state.__DANGER__viewValue,
    sortValue: state.sortValue,
    shouldGroupByStatus: state.isSortGroupedByStatus,
    shouldShowThumbsDownValue: state.showValues.includes('THUMBS_DOWN'),
  }))

  const remoteState = useSettingsStore(state => state.settingsState)
  const updateSettings = useSettingsStore(state => state.actions.updateSettings.execute)
  const remote = remoteState.status === 'loaded'
    ? {
      viewValue: remoteState.settings.features.cma.view,
      sortValue: remoteState.settings.features.cma.sort.sortBy,
      shouldGroupByStatus: remoteState.settings.features.cma.sort.shouldGroupByStatus,
      shouldShowThumbsDownValue: remoteState.settings.features.cma.show.shouldShowThumbsDown,
    }
    : null

  const hasLoaded = remoteState.status === 'loaded'
  const [isInitialSyncDone, setIsInitialSyncDone] = useState(false)

  /** Update local value when remote loads for the first time */
  useEffect(() => {
    if (!hasLoaded || !remote || isInitialSyncDone) return

    if (remote.viewValue && remote.viewValue !== local.viewValue)
      useEntriesToolbarMenu.setState({ __DANGER__viewValue: remote.viewValue })

    if (remote.sortValue && remote.sortValue !== local.sortValue)
      useEntriesToolbarMenu.setState({ sortValue: remote.sortValue })

    if (
      isBoolean(remote.shouldGroupByStatus)
      && remote.shouldGroupByStatus !== local.shouldGroupByStatus)
      useEntriesToolbarMenu.setState({ isSortGroupedByStatus: remote.shouldGroupByStatus })

    if (
      isBoolean(remote.shouldShowThumbsDownValue)
      && remote.shouldShowThumbsDownValue !== local.shouldShowThumbsDownValue
    ) {
      useEntriesToolbarMenu.setState(state => ({
        showValues: remote.shouldShowThumbsDownValue
          ? [...state.showValues.filter(value => value !== 'THUMBS_DOWN'), 'THUMBS_DOWN']
          : state.showValues.filter(value => value !== 'THUMBS_DOWN'),
      }))
    }

    setIsInitialSyncDone(true)
  }, [hasLoaded])

  /** Update remote value when local updates */
  useEffect(() => {
    if (!remote || !isInitialSyncDone) return

    /**
     * If remote value is not set, we must not set the first value from here.
     * It should be handled by CompsViewSettingsModal elsewhere
     */
    const shouldUpdateViewValue = remote?.viewValue && local.viewValue !== remote?.viewValue
    const shouldUpdateSortValue = local.sortValue !== remote?.sortValue
    const shouldUpdateGroupByStatus = local.shouldGroupByStatus !== remote?.shouldGroupByStatus
    const shouldUpdateShowThumbsDownValue = local.shouldShowThumbsDownValue !== remote?.shouldShowThumbsDownValue

    if (
      !shouldUpdateViewValue
      && !shouldUpdateSortValue
      && !shouldUpdateGroupByStatus
      && !shouldUpdateShowThumbsDownValue
    ) return

    const payload: Settings.UpdateSettingsPayload = {
      settings: {
        features: {
          cma: pipe(
            {},
            shouldUpdateViewValue
              ? merge({
                view: local.viewValue,
              } satisfies DeepPartial<Settings.CMA>)
              : identity,
            shouldUpdateSortValue
              ? merge({
                sort: {
                  sortBy: local.sortValue,
                },
              } satisfies DeepPartial<Settings.CMA>)
              : identity,
            shouldUpdateGroupByStatus
              ? merge({
                sort: {
                  shouldGroupByStatus: local.shouldGroupByStatus,
                },
              } satisfies DeepPartial<Settings.CMA>)
              : identity,
            shouldUpdateShowThumbsDownValue
              ? merge({
                show: {
                  shouldShowThumbsDown: local.shouldShowThumbsDownValue,
                },
              } satisfies DeepPartial<Settings.CMA>)
              : identity,
          ),
        },
      },
    }

    void updateSettings(payload)
  }, [
    local.viewValue,
    local.sortValue,
    local.shouldGroupByStatus,
    local.shouldShowThumbsDownValue,
    remote?.viewValue,
    remote?.sortValue,
    remote?.shouldGroupByStatus,
    remote?.shouldShowThumbsDownValue,
  ])
}
