import { addColorAlphaVariants } from '../addColorAlphaVariants'

export const v3 = {
  'accent.blue-text': '#034b7e',
  'accent.blue-to-gray': '#0770ba',
  'accent.darkblue': '#055d9c',
  'accent.darkgreen': '#34943d',
  'accent.darkpurple': '#5a3972',
  'accent.darkred': '#a91f1c',
  'accent.gold.100': '#ddb05d',
  'accent.gold.200': '#916c27',
  'accent.gold.300': '#785920',
  'accent.green-to-gray': '#4fb959',
  'accent.hover': '#e0f2ff',
  'accent.lightpurple-to-gray': '#dacae8',
  'accent.peach-to-gray': '#ffe0df',
  'accent.purple-to-gray': '#33124f',
  'accent.red-to-gray': '#f04745',
  'accent.xdarkblue': '#023960',
  'accent.xdarkgreen': '#34943d',
  'accent.xdarkpurple': '#33124f',
  'accent.xdarkred': '#620604',
  'accent.xxdarkgreen': '#025406',
  'accent.xxdarkpurple': '#1f0338',
  'accent.yellow-to-gray': '#ffcf77',
  'card.bg.1': '#ffffff',
  'card.bg.2': '#eff1f7',
  'card.bg.3': '#ffffff',
  'card.bg.4': '#ffffff',
  'card.bg.blue': '#0770ba',
  'card.bg.darkgreen': '#b7e8bb',
  'card.bg.green': '#4fb959',
  'card.bg.lightblue': '#e0f2ff',
  'card.bg.lightgreen': '#defbe1',
  'card.bg.lightred': '#ffe0df',
  'card.bg.xlightblue': '#b8dcf5',
  'card.bg.xlightgreen': '#defbe1',
  'card.bg.yellow': '#ffcf77',
  'error.100': '#ffd8d8',
  'error.200': '#f19595',
  'error.300': '#ed7473',
  'error.400': '#dd2f2e',
  'error.500': '#d70300',
  'error.600': '#b40200',
  'error.700': '#940200',
  'error.800': '#740200',
  'error.900': '#550100',
  'graycool.100': '#eff1f7',
  'graycool.200': '#e5e9f6',
  'graycool.300': 'RED', // @TODO
  'graystrong.100': '#787878',
  'graystrong.200': '#696969',
  'graystrong.300': '#5a5a5a',
  'graystrong.400': '#4b4b4b',
  'graystrong.500': '#3c3c3c',
  'graystrong.600': '#2d2d2d',
  'graystrong.700': '#1e1e1e',
  'graystrong.800': '#0f0f0f',
  'graystrong.900': '#000000',
  'grayweak.100': '#ffffff',
  'grayweak.200': '#f7f7f7',
  'grayweak.300': '#e3e3e3',
  'grayweak.400': '#dbdbdb',
  'grayweak.500': '#cdcdcd',
  'grayweak.600': '#bababa',
  'grayweak.700': '#ababab',
  'grayweak.800': '#9a9a9a',
  'grayweak.900': '#888888',
  'gs.900.am': 'rgba(0,0,0,0.5)',
  'gs.900.as': 'rgba(0,0,0,0.3)',
  'gs.900.aw': 'rgba(0,0,0,0.7)',
  'gs.900.axs': 'rgba(0,0,0,0.1)',
  'gs.900.axw': 'rgba(0,0,0,0.9)',
  'gw.100.az': 'rgba(255,255,255,0)',
  'gw.100.am': 'rgba(255,255,255,0.5)',
  'gw.100.as': 'rgba(255,255,255,0.3)',
  'gw.100.aw': 'rgba(255,255,255,0.7)',
  'gw.100.axs': 'rgba(255,255,255,0.1)',
  'gw.100.axw': 'rgba(255,255,255,0.9)',
  'highlight.100': '#fff2d9',
  'highlight.200': '#ffe2ad',
  'highlight.300': '#ffdc9b',
  'highlight.400': '#ffd689',
  'highlight.500': '#ffcf77',
  'highlight.600': '#ddb05d',
  'highlight.700': '#bb9245',
  'highlight.800': '#916c27',
  'highlight.900': '#785920',
  'link.500': '#2f80ed',
  'modal.bg': '#ffcf77',
  'modal.blur': 'rgba(0,0,0,0.7)',
  'modal.text': '#785920',
  'negative.100': '#ffbfbe',
  'negative.200': '#fba09e',
  'negative.300': '#f88280',
  'negative.400': '#f46562',
  'negative.50': '#ffe0df',
  'negative.500': '#eb4c4a',
  'negative.600': '#cd322e',
  'negative.700': '#a91f1c',
  'negative.800': '#86100d',
  'negative.900': '#620604',
  'neutral.100': '#e0f2ff',
  'neutral.200': '#b8dcf5',
  'neutral.300': '#70afdb',
  'neutral.400': '#378ecb',
  'neutral.500': '#0770ba',
  'neutral.600': '#055d9c',
  'neutral.700': '#034b7e',
  'neutral.800': '#023960',
  'neutral.900': '#012742',
  'ondark.1': '#f7f7f7',
  'ondark.2': '#f7f7f7',
  'ondark.3': '#e4e4e4',
  'ondark.4': '#cdcdcd',
  'ondark.5': '#bebebe',
  'ondark.6': '#9a9a9a',
  'onlight.1': '#4b4b4b',
  'onlight.2': '#3c3c3c',
  'onlight.3': '#696969',
  'overlay.dark-am': 'rgba(0, 0, 0, 0.5)',
  'plans.advance': '#755394',
  'plans.intermediate': '#51b35a',
  'positive.100': '#c7f0ca',
  'positive.200': '#96d99d',
  'positive.300': '#6fce78',
  'positive.400': '#5bc865',
  'positive.50': '#defbe1',
  'positive.500': '#4fb959',
  'positive.600': '#34943d',
  'positive.700': '#1c6a23',
  'positive.800': '#0e4b14',
  'positive.900': '#042407',
  'positivesat.100': '#a3f9a7',
  'positivesat.200': '#79ea7c',
  'positivesat.300': '#49d34d',
  'positivesat.400': '#2bb730',
  'positivesat.500': '#0fa115',
  'positivesat.600': '#09870e',
  'positivesat.700': '#056e0a',
  'positivesat.800': '#025406',
  'positivesat.900': '#003a03',
  'special.100': '#dacae8',
  'special.200': '#c2a9d9',
  'special.300': '#9675b3',
  'special.400': '#755394',
  'special.50': '#f4e7ff',
  'special.500': '#4a2966',
  'special.600': '#3e1d5a',
  'special.700': '#33124f',
  'special.800': '#290a43',
  'special.900': '#1f0338',
  'specialsat.100': '#dab1ff',
  'specialsat.200': '#ba86e8',
  'specialsat.300': '#9c60d1',
  'specialsat.400': '#813fba',
  'specialsat.50': '#efdeff',
  'specialsat.500': '#6d2fa3',
  'specialsat.600': '#551f85',
  'specialsat.700': '#401267',
  'specialsat.800': '#2b094a',
  'specialsat.900': '#19032c',
  'teal.100': '#c1e2e6',
  'teal.200': '#c1e2e6',
  'teal.300': '#71aeb4',
  'teal.400': '#6ca9af',
  'teal.50': '#eefdff',
  'teal.500': '#559499',
  'teal.600': '#3b7980',
  'teal.700': '#256268',
  'teal.800': '#083439',
  'teal.900': '#022528',
  'trueorange.100': '#ffefcf',
  'trueorange.200': '#ffe5b2',
  'trueorange.300': '#ffd88d',
  'trueorange.400': '#ffc047',
  'trueorange.500': '#ffa500',
  'warm.100': '#ffd3ba',
  'warm.200': '#fbb68f',
  'warm.300': '#f4a578',
  'warm.400': '#ef8f58',
  'warm.500': '#e97a3a',
  'warm.600': '#c86125',
  'warm.700': '#a74a15',
  'warm.800': '#873708',
  'warm.900': '#662500',
  'mixkey.blue': '#0770BA',
  /**
   * ===========================
   * Marker Colors
   * ===========================
   */
  'marker.teal': '#3b7980',
  'marker.sky': '#ccf3f6',
  'marker.olivegreen': '#5c6333',
  'marker.yellowgreen': '#d6e775',
  'marker.carolinablue': '#3e769e',
  'marker.lightblue': '#c5e0ef',
  'marker.darkgreen': '#0E4B14',
  'marker.lightgreen': '#b9ee9b',
  'marker.darkpink': '#ebb5b4',
  'marker.scarlet': '#8d2622',
  'marker-mlstag.scarlet': '#8D2622',
  'marker.gold': '#785e2c',
  'marker.yellow': '#fce093',
  'marker.cobaltblue': '#2F80ed',
  'marker.strawberrypink': '#eb4c4a',
  'marker.white': '#f7f7f7',
  'marker.mintgreen': '#c7f0ca',
  'marker.babyblue': '#E0F2FF',
  'marker.oxfordblue': '#023960',
  'marker.pink': '#FFE0DF',
  'marker.gray': '#9A9A9A',
  'marker.darkgray': '#4B4B4B',
  'marker.dirtywhite': '#F0F0F0',
  'marker.lightpurple': '#F4E7FF',
  'marker.darkpurple': '#4A2966',
  'marker.cream': '#FFDC9B',
  'marker.brown': '#785920',
  'marker.maroon': '#8D2622',
  'marker.white-to-blue': '#F7F7F7',
  'marker.white-to-red': '#F7F7F7',
  'marker.dirty-white': '#F0F0F0',
}

const v2 = {
  'link': {
    500: '#2f80ed',
  },

  'grayWeak': {
    100: '#FFFFFF',
    200: '#F7F7F7',
    300: '#E7E7E7',
    400: '#DBDBDB',
    500: '#CDCDCD',
    600: '#BABABA', // close to legacy color `font-5` and `fg-30`
    700: '#ABABAB',
    800: '#9A9A9A',
    900: '#888888',
  },

  'grayStrong': {
    100: '#787878',
    200: '#696969', // close to legacy color `font-3`
    300: '#5A5A5A',
    400: '#4B4B4B',
    500: '#3C3C3C', // close to legacy color `font-2`
    600: '#2D2D2D',
    700: '#1E1E1E',
    800: '#0F0F0F',
    900: '#000000',
  },

  /** @deprecated Use grayWeak or grayStrong instead */
  'gray': {
    100: '#FFFFFF',
    200: '#F7F7F7',
    300: '#CDCDCD',
    400: '#B4B4B4',
    500: '#888888',
    600: '#5A5A5A',
    700: '#3A3A3A',
    800: '#1A1A1A',
    900: '#000000',
  },

  'neutral': {
    100: '#E0F2FF',
    200: '#B0D3EC',
    300: '#70AFDB',
    400: '#378ECB',
    500: '#0770BA',
    600: '#055D9C',
    700: '#034B7E',
    800: '#023960',
    900: '#012742',
  },

  'positive': {
    50: '#DEFBE1',
    100: '#C7F0CA',
    200: '#96D99D',
    300: '#6FCE78',
    400: '#5BC865',
    500: '#4FB959',
    600: '#34943D',
    700: '#1C6A23',
    800: '#0E4B14',
    900: '#042407',
  },

  'negative': {
    50: '#FFE0DF',
    100: '#FFBFBE',
    200: '#FBA09E',
    300: '#F88280',
    400: '#F46562',
    500: '#F04745',
    600: '#CD322E',
    700: '#A91F1C',
    800: '#86100D',
    900: '#620604',
  },

  'highlight': {
    100: '#FFF2D9',
    200: '#FFE2AD',
    300: '#FFDC9B',
    400: '#FFD689',
    500: '#FFCF77',
    600: '#DDB05D',
    700: '#BB9245',
    800: '#997531',
    900: '#785920',
  },

  'special': {
    100: '#F4E7FF',
    200: '#C2A9D9',
    300: '#9675B3',
    400: '#755394',
    500: '#4A2966',
    600: '#3E1D5A',
    700: '#33124F',
    800: '#290A43',
    900: '#1F0338',
  },

  'special-sat': {
    50: '#EFDEFF',
    100: '#DAB1FF',
    200: '#BA86E8',
    300: '#9C60D1',
    400: '#813FBA',
    500: '#6D2FA3',
    600: '#551F85',
    700: '#401267',
    800: '#2B094A',
    900: '#19032C',
  },

  'error': {
    100: '#FFD8D8',
    200: '#F19595',
    300: '#ED7473',
    400: '#DD2F2E',
    500: '#D70300',
    600: '#B40200',
    700: '#940200',
    800: '#740200',
    900: '#550100',
  },

  'positivesat': {
    100: '#A3F9A7',
    200: '#79EA7C',
    300: '#49D34D',
    400: '#2BB730',
    500: '#0FA115',
    600: '#09870E',
    700: '#056E0A',
    800: '#025406',
    900: '#003A03',
  },

  'warm': {
    100: '#FFD3BA',
    200: '#FBB68F',
    300: '#F4A578',
    400: '#EF8F58',
    500: '#E97A3A',
    600: '#C86125',
    700: '#A74A15',
    800: '#873708',
    900: '#662500',
  },

  'teal': {
    50: '#EEFDFF',
    100: '#C1E2E6',
    200: '#90CACF',
    300: '#71AEB4',
    400: '#559499',
    500: '#3B7980',
    600: '#256268',
    700: '#144B51',
    800: '#083439',
    900: '#022528',
  },
}

const lightColorsLegacy = {
  /**
   * Foundation colors
   */
  // Primary
  'pri': '#f04745',
  'pri-1': '#C24444',
  'pri-2': '#F36C6A',
  'pri-3': '#FFB7B7',
  'pri-4': '#FFDFDF',

  // Secondary
  'sec': '#0770ba',
  'sec-1': '#2E5571',
  'sec-2': '#398DC8',
  'sec-3': '#A2D3F5',
  'sec-4': '#E5F1F9',

  // Tertiary
  'ter': 'rgb(255, 207, 119, 1)',
  'ter-1': '#BA9244',
  'ter-70': 'rgb(255, 207, 119, 0.70)',
  'ter-50': 'rgb(255, 207, 119, 0.50)',
  'ter-40': 'rgb(255, 207, 119, 0.40)',
  'ter-30': 'rgb(255, 207, 119, 0.30)',
  'ter-20': 'rgb(255, 207, 119, 0.20)',
  'ter-10': 'rgb(255, 207, 119, 0.10)',
  'ter-0': 'rgb(255, 207, 119, 0)',

  // Accent
  'acc': '#4a2966',
  'acc-1': '#6D2FA3',
  'acc-2': '#2E5571',
  'acc-3': '#EDDFF9',
  'acc-4': '#DADADA',
  'acc-5': '#CCE1F0',

  // Quaternary
  'quat': 'rgba(15, 161, 21, 1)',
  'quat-70': 'rgba(15, 161, 21, 0.70)',
  'quat-50': 'rgba(15, 161, 21, 0.50)',
  'quat-40': 'rgba(15, 161, 21, 0.40)',
  'quat-30': 'rgba(15, 161, 21, 0.30)',
  'quat-20': 'rgba(15, 161, 21, 0.20)',
  'quat-10': 'rgba(15, 161, 21, 0.10)',
  'quat-0': 'rgba(15, 161, 21, 0)',

  /**
   * Status colors
   */
  'error': '#d70300',
  'error-4': '#FFDFDF',

  'success': 'rgba(79, 185, 89, 1)',
  'success-1': '#1D9C29',
  'success-2': '#72C77A',
  'success-3': '#B0DBB4',
  'success-4': '#DEFBE1',
  'success-5': 'RED', /** @deprecated */
  'success-6': 'RED', /** @deprecated */
  'success-7': 'RED', /** @deprecated */

  'info': 'rgba(7, 112, 186, 1)',
  'info-70': 'rgba(7, 112, 186, 0.70)',
  'info-50': 'rgba(7, 112, 186, 0.50)',
  'info-40': 'rgba(7, 112, 186, 0.40)',
  'info-30': 'rgba(7, 112, 186, 0.30)',
  'info-20': 'rgba(7, 112, 186, 0.20)',
  'info-10': 'rgba(7, 112, 186, 0.10)',

  'link': '#2f80ed',

  /**
   * Misc
   */
  // Font
  'font-1': '#000000',
  'font-2': '#3B3A3C',
  'font-3': '#646365',
  'font-4': 'rgba(0, 0, 0, 0.30)',
  'font-5': '#B2BCC4',
  'font-6': 'rgba(255, 255, 255, 0.90)',
  'font-7': 'rgba(255, 255, 255, 0.70)',

  // Gray Shades
  'fg': 'rgba(0, 0, 0, 1)',
  'fg-900': 'rgba(0, 0, 0, 0.70)',
  'fg-800': 'rgba(0, 0, 0, 0.50)',
  'fg-700': 'rgba(0, 0, 0, 0.40)',
  'fg-600': 'rgba(0, 0, 0, 0.30)',
  'fg-500': 'rgba(0, 0, 0, 0.20)',
  'fg-400': 'rgba(0, 0, 0, 0.10)',
  'fg-300': 'rgba(0, 0, 0, 0.05)',

  // `-s` means solid
  'fg-900-s': '#3B3B3B',
  'fg-800-s': '#626262',
  'fg-600-s': '#898989',
  'fg-500-s': '#CACACA',
  'fg-400-s': '#E3E3E3',
  'fg-300-s': '#EFEFEF',
  'fg-200-s': '#F5F6F4',

  'bg': 'rgba(255, 255, 255, 1)',
  'bg-900': 'rgba(255, 255, 255, 0.90)',
  'bg-800': 'rgba(255, 255, 255, 0.70)',
  'bg-700': 'rgba(255, 255, 255, 0.70)',
  'bg-600': 'rgba(255, 255, 255, 0.50)',
  'bg-500': 'rgba(255, 255, 255, 0.40)',
  'bg-400': 'rgba(255, 255, 255, 0.30)',
  'bg-300': 'rgba(255, 255, 255, 0.20)',
  'bg-200': 'rgba(255, 255, 255, 0.10)',

  'main-bg': 'linear-gradient(118.63deg, #FFFFFF -1.58%, #FAFAF9 101.57%)',

  // Extra background colors
  'bg-screen': '#EFF1F7',
  'bg-bubble': '#F7F7F7',
  'bg-modal-highlight': '#FFCF77',
  'bg-card/divider': '#FFFFFF',
  'bg-sbmenu': '#4A2966',
  'bg-icon-outline-light': '#f7f7f7',
  'bg-landingpage-special': '#33124F',
  'bg-accent': '#FFCF77',

  // Extra text colors
  'text-primary-xstrong': '#3B3A3C',
  'text-primary-strong': 'rgba(60, 60, 60, 0.8)',
  'text-onbutton/banner-xstrong': '#F7F7F7',
  'text-primary-neutral': '#787878',
  'text-highlight-neutral': '#0770BA',
  'onhighlight-text-xstrong': 'rgba(15, 15, 15, 0.8)',

  // Extra colors
  'l.gw100-graymid': '#ffffff',
  'darkblue-brightblue': '#013281',
  'xlightblue-d.gw300': '#eff1f7',
  'xlightblue-grayxdark': '#eff1f7',
  'lightblue-newpurple': '#e5e9f6',
  'l.n500-brightblue': '#0770BA',
  'l.gs200-d.gs300': '#696969',
  'l.gs100-d.gs300': '#787878',
  'l.s300-d.s500': '#9675B3',
  'l.gw200-d.gs300': '#F7F7F7',
  'gs900-alphamid': 'rgba(0, 0, 0, 0.5)',
  'bg-button-go-active': '#4FB959',
  'l.h500-d.gw300': '#FFCF77',
  'l.gw200-l.gw200': '#F7F7F7',
  'l.gs300-graymid': '#e3e3e3',
  'l.gw600-graydark': '#BABABA',
  'l.gw300-l.gw300': '#E3E3E3',
  'l.gs200-d.gw500': '#696969',
  'l.neg500-d.neg800': '#FF4341',
  'l.gs500-d.gs700': '#3C3C3C',
  'l.p50-lightgreen': '#DEFBE1',
  'l.gs200-d.gs700': '#696969',
  'l.gw300-graymid': '#E3E3E3',
  'text-header-neutral-xstrong': '#013281',
  'l.gs400-d.gs700': '#4B4B4B',
  'l.s500-lightpurple': '#4A2966',
  'lightpurple-lightpurple': '#D9CAE8',
  'l.gs500-l.gw100Axw': '#3C3C3C',
  'l.n500-d.gs500': '#0770BA',
  'l.gw100-graydark': '#FFFFFF',
  'l.gs500-d.gs300': '#3C3C3C',
  'l.gs400-d.gs300': '#4B4B4B',
  'l.gw200-l.gw100': '#F7F7F7',
  'l.gs200-d.gw900': '#696969',
  'l.n500-brightpurple': '#0770BA',
  'l.s700-l.s700': '#33124F',
  'l.n200-d.s500': '#B8DCF5',
  'l.p600-d.p500': '#34943D',
  'l.gw500-d.gw800': '#CDCDCD',
  'l.n500-d.h500': '#0770BA',
  'l.h500-mudyellow': '#FFCF77',
  'l.gw100-l.gw100': '#FFFFFF',

  // modals
  'modal-backdrop': 'rgba(74, 41, 102, 0.95)',
  'modal-success-bg': '#FFFFFF',
  'modal-success-border': '#0770BA',
  'l.gw100-graylight': '#FFFFFF',
  'l.gw500-d.gs500': '#CDCDCD',
  'l.s500-brightpurple': '#4A2966',
  'l.gs200-d.gs500': '#696969',
  'l.h500-graymid': '#FFCF77',
  'l.h800-l.h800': '#997531',
  'l.gs500-d.p300': '#3C3C3C',
  'l.gs400-d.gw600': '#4B4B4B',

}

export const lightColors = {
  v2: addColorAlphaVariants(v2),
  ...lightColorsLegacy,
  ...v3,
}
