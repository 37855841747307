import { Array, Match, Option, pipe } from 'effect'
import { Dollar } from 'libs/dollar'
import { formatCommas, formatK1OptionalDecimal } from 'libs/Number/formatNumber'
import { Color } from 'presentation/libs/chakra/chakra.types'
import SubscriptionPlan from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionPlan'
import SubscriptionProduct from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/SubscriptionProduct'
import React, { createContext, PropsWithChildren } from 'react'
import { formatPenniesOptionalDecUsd } from 'utils/dataAdapter'

export type DetailsDisclosureViewModel = {
  type: 'skiptrace' | 'comps'
  value: string
}

type FeatureLineItem = {
  iconType:
    | 'car'
    | 'two-persons'
    | 'book'
    | 'list'
    | 'skip'
    | 'mail'
    | 'binocular'
  featureNameText: string
  featureValueText: string
  featureValueTextColor: Color
  disclosure: Option.Option<DetailsDisclosureViewModel>
}

type PlanCompactViewModel = {
  productNameText: string
  planPriceText: string
  planPriceTextColor: Color
  planPriceSideText: string
  productDescText: string
  productDescTextColor: Color
  productIconType: 'feather' | 'paper-plane' | 'plane' | 'rocket'
  featureValuesTextColor: Color
  isCurrentPlan: boolean
  isDisabled: boolean
  addToCart: () => void
  seePlanDetailsLink: string
  featureLineItems: FeatureLineItem[]
}

type Params = {
  isYearly: boolean
  product: SubscriptionProduct
  addToCart: (product: SubscriptionProduct) => void
  disableFreePlan?: boolean
}

const makePresenter = ({ isYearly, product, addToCart, disableFreePlan = false }: Params) => {
  const textColor: Color = pipe(
    Match.value(product.name),
    Match.when('free', () => 'graystrong.200'),
    Match.when('beginner', () => 'accent.gold.200'),
    Match.when('intermediate', () => 'neutral.500'),
    Match.when('advanced', () => 'positivesat.500'),
    Match.orElse(() => { throw Error('Product not found') }),
  )

  const linebreak = '\n'

  const currentPlan: Option.Option<SubscriptionPlan> = pipe(
    product.plans,
    plans => isYearly
      ? SubscriptionPlan.getYearly(plans)
      : SubscriptionPlan.getMonthly(plans),
  )

  // Helper function to determine feature value text color
  const getFeatureValueTextColor = (valueText: string): Color => valueText === 'Zero' ? 'negative.500' : textColor

  const findTeamMembersFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'TeamMembers'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureLimitedSpec', f => Option.some(formatCommas(f.limit))),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'two-persons',
      featureNameText: 'Team Members',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: Option.none(),
    }
  }

  const findPropertyFindersFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'PropertyFinders'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureLimitedSpec', f => Option.some(formatCommas(f.limit))),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'binocular',
      featureNameText: 'Prospector',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: Option.none(),
    }
  }

  const findDrivingForDollarsFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'DrivingForDollars'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureAvailableSpec', () => Option.some('Yes')),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'No'),
    )

    return {
      iconType: 'car',
      featureNameText: 'Driving For Dollars',
      featureValueText: valueText,
      featureValueTextColor: valueText === 'No' ? 'negative.500' : textColor,
      disclosure: Option.none(),
    }
  }

  const findLeadListExportsFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'LeadListExports'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureLimitedSpec', f => Option.some(formatK1OptionalDecimal(f.limit))),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'book',
      featureNameText: 'Lead List Exports',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: Option.none(),
    }
  }

  const findCompsFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'Comps'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureQuotaSpec', f => Option.some(formatK1OptionalDecimal(f.quotaLimit))),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'list',
      featureNameText: 'Comps Reports',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: pipe(
        product.features,
        Array.findFirst(feature => feature.featureName === 'Comps'),
        Option.flatMap(feature => pipe(
          Match.value(feature),
          Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
          Match.tag('FeatureQuotaSpec', f => Option.some(formatCommas(f.quotaLimit))),
          Match.orElse(() => Option.none()),
        )),
        Option.map(v => ({
          type: 'comps',
          value: v,
        } as DetailsDisclosureViewModel)),
      ),
    }
  }

  const findSkiptraceFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'Skiptrace'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureQuotaSpec', f => Option.some(
          `${formatK1OptionalDecimal(f.quotaLimit)} Free`,
        )),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'skip',
      featureNameText: 'Skiptrace',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: pipe(
        product.features,
        Array.findFirst(feature => feature.featureName === 'Skiptrace'),
        Option.flatMap(feature => pipe(
          Match.value(feature),
          Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
          Match.tag('FeatureQuotaSpec', f => Option.some(
            formatCommas(f.quotaLimit)),
          ),
          Match.orElse(() => Option.none()),
        )),
        Option.map(value => ({
          type: 'skiptrace',
          value,
        } as DetailsDisclosureViewModel)),
      ),
    }
  }

  const findDirectMailFeature = (): FeatureLineItem => {
    const valueText = pipe(
      product.features,
      Array.findFirst(feature => feature.featureName === 'DirectMailFeature'),
      Option.flatMap(feature => pipe(
        Match.value(feature),
        Match.tag('FeatureUnlimitedSpec', () => Option.some('Unli')),
        Match.tag('FeatureUsagePricingSpec', f => Option.some(
          Dollar.formatPennies(f.price)),
        ),
        Match.orElse(() => Option.none()),
      )),
      Option.getOrElse(() => 'Zero'),
    )

    return {
      iconType: 'mail',
      featureNameText: 'Direct Mail',
      featureValueText: valueText,
      featureValueTextColor: getFeatureValueTextColor(valueText),
      disclosure: Option.none(),
    }
  }

  const vm: PlanCompactViewModel = {
    productNameText: product.name,
    planPriceText: pipe(
      currentPlan,
      Option.flatMap<SubscriptionPlan, number>(plan => pipe(
        Match.value(plan),
        Match.tag('YearlyPlan', p => Option.some(p.pricePerMonth)),
        Match.tag('MonthlyPlan', p => Option.some(p.price)),
        Match.orElse(() => Option.none()),
      )),
      Option.map(price => formatPenniesOptionalDecUsd(price)),
      Option.getOrElse(() => '--'),
    ),
    planPriceTextColor: textColor,
    planPriceSideText: product.name === 'free'
      ? `Free${linebreak}Forever`
      : isYearly ? `per month /${linebreak}billed annually` : `per${linebreak}month`,
    productDescText: product.description,
    productDescTextColor: textColor,
    productIconType: pipe(
      Match.value(product.name),
      Match.withReturnType<PlanCompactViewModel['productIconType']>(),
      Match.when('free', () => 'feather'),
      Match.when('beginner', () => 'paper-plane'),
      Match.when('intermediate', () => 'plane'),
      Match.when('advanced', () => 'rocket'),
      Match.exhaustive,
    ),
    featureValuesTextColor: textColor,
    isCurrentPlan: pipe(
      product.plans,
      Array.findFirst(plan =>
        plan._tag === (isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      Option.map(plan => plan.isCurrentPlan),
      Option.getOrElse(() => false),
    ),
    isDisabled: product.name === 'free' && disableFreePlan,
    addToCart: () => addToCart(product),
    seePlanDetailsLink: '#plan-comparison-detailed',
    featureLineItems: [
      findTeamMembersFeature(),
      findPropertyFindersFeature(),
      findDrivingForDollarsFeature(),
      findLeadListExportsFeature(),
      findCompsFeature(),
      findSkiptraceFeature(),
      findDirectMailFeature(),
    ],
  }
  return vm
}

const Context = createContext<Option.Option<PlanCompactViewModel>>(Option.none())

type ProviderProps = PropsWithChildren<{
  viewModel: PlanCompactViewModel
}>

const Provider = ({ children, viewModel }: ProviderProps) => (
  <Context.Provider value={Option.some(viewModel)}>
    {children}
  </Context.Provider>
)

const usePresenter = () => React.useContext(Context).pipe(
  Option.getOrThrowWith(() => new Error('No PlanEssentialViewModel provider')),
)

const PlanCompactViewModel = {
  makePresenter,
  usePresenter,
  Provider,
}

export default PlanCompactViewModel
