const META_VITE_API_URL = import.meta.env.VITE_API_URL
const META_STORYBOOK_API_URL = import.meta.env.STORYBOOK_API_URL
export const API_URL
= (META_VITE_API_URL
|| META_STORYBOOK_API_URL
|| '') as string

const META_VITE_ENVIRONMENT_NAME = import.meta.env.VITE_ENVIRONMENT_NAME
const META_STORYBOOK_ENVIRONMENT_NAME = import.meta.env.STORYBOOK_ENVIRONMENT_NAME
export const ENVIRONMENT_NAME
= (META_VITE_ENVIRONMENT_NAME
|| META_STORYBOOK_ENVIRONMENT_NAME
|| '') as string

const META_VITE_RELEASE_HASH = import.meta.env.VITE_RELEASE_HASH
const META_STORYBOOK_RELEASE_HASH = import.meta.env.STORYBOOK_RELEASE_HASH
export const RELEASE_HASH
= (META_VITE_RELEASE_HASH
|| META_STORYBOOK_RELEASE_HASH
|| '') as string

const META_VITE_STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY
const META_STORYBOOK_STRIPE_KEY = import.meta.env.STORYBOOK_STRIPE_KEY
export const STRIPE_KEY
= (META_VITE_STRIPE_KEY
|| META_STORYBOOK_STRIPE_KEY
|| '') as string

const META_VITE_MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN
const META_STORYBOOK_MAPBOX_TOKEN = import.meta.env.STORYBOOK_MAPBOX_TOKEN
export const MAPBOX_TOKEN
= (META_VITE_MAPBOX_TOKEN
|| META_STORYBOOK_MAPBOX_TOKEN
|| '') as string

const META_VITE_OLD_PROPELIO_URL = import.meta.env.VITE_OLD_PROPELIO_URL
const META_STORYBOOK_OLD_PROPELIO_URL = import.meta.env.STORYBOOK_OLD_PROPELIO_URL
export const OLD_PROPELIO_URL
= (META_VITE_OLD_PROPELIO_URL
|| META_STORYBOOK_OLD_PROPELIO_URL
|| '') as string

export const INTERCOM_APP_ID = 'wo4wt04k'

const META_IS_DEV = import.meta.env.DEV
export const IS_DEV_ENV = !!META_IS_DEV

const META_STORYBOOK = import.meta.env.STORYBOOK
export const IS_STORYBOOK = META_STORYBOOK === 'true'

export const SENTRY_DSN = 'https://bfffd49a97e0457ca465b2a42a83747d@o361455.ingest.sentry.io/4504680897576960'

const META_TEST_USER_EMAIL = import.meta.env.TEST_USER_EMAIL
export const TEST_USER_EMAIL = (META_TEST_USER_EMAIL || '') as string

const META_TEST_USER_PASSWORD = import.meta.env.TEST_USER_PASSWORD
export const TEST_USER_PASSWORD = (META_TEST_USER_PASSWORD || '') as string

export const LEGAL_PRIVACY_URL = 'https://app.propelio.com/privacy'
