import { Skiptrace as Domain } from 'features/Skiptrace/domain/Skiptrace.domain'
import { restClient } from 'presentation/libs/client'

export const performSkiptrace = async (
  params: Domain.PerformSkiptracePayload,
): Promise<Domain.PerformSkiptraceResult> =>
  await restClient.post('marketing/v2/skiptrace/v3', {
    json: {
      provider: 'skipGenie',
      request: {
        line1: params.address.line1,
        city: params.address.city,
        state: params.address.state,
        zip: params.address.postalCode,
      },
      useIncludedConsumables: true,
      confirmationKey: params.confirmationKey,
    },
  })
    .json<any>()
    .then(response => ({
      isSuccessful: !!response?.result?.isSuccessful,
    }))
