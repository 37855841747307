import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { EMPTY_PROPERTY_BUILDING } from 'features/PropertyDetails/domain/PropertyDetails.utils'
import { serializeGetPropertyPayload } from 'features/PropertyDetails/infra/remote/getProperty/getProperty'
import { produce } from 'immer'
import { ServerCache } from 'libs/ServerCache'
import { restClient } from 'presentation/libs/client'

export type EditProperty = (params: Domain.EditPropertyPayload) => Promise<Domain.EditPropertyResult>

export const editProperty: EditProperty = async payload => {
  // update cache optimistically
  const leadIdSerial = serializeGetPropertyPayload(payload)
  let parcelIdSerial = null as string | null

  if (leadIdSerial) {
    const fromCache = ServerCache.get('getProperty')(leadIdSerial)

    if (fromCache) {
      const livingAreaSqft = payload.building?.livingAreaSqft

      if (livingAreaSqft !== undefined) {
        const typedProperty = fromCache as Domain.Property

        const updatedProperty = produce(typedProperty, draft => {
          draft.userInputData.building = {
            ...EMPTY_PROPERTY_BUILDING,
            ...draft.userInputData.building,
            livingAreaSqft,
          }

          if (draft.status === 'with-details') {
            parcelIdSerial = serializeGetPropertyPayload({ parcelId: draft.parcelId })

            draft.building = {
              ...EMPTY_PROPERTY_BUILDING,
              ...draft.building,
              livingAreaSqft: livingAreaSqft
              ?? draft.originalData.building?.livingAreaSqft
              ?? null,
            }
          }
        })

        ServerCache.set('getProperty')(leadIdSerial)(updatedProperty)
        if (parcelIdSerial) ServerCache.set('getProperty')(parcelIdSerial)(updatedProperty)
      }
    }
  }

  await restClient.put(`legacy/leads/${payload.leadId}`, {
    json: {
      sqft: payload.building?.livingAreaSqft,
    },
  })
    .catch(error => {
      // invalidate cache
      if (leadIdSerial) ServerCache.delete('getProperty')(leadIdSerial)
      if (parcelIdSerial) ServerCache.delete('getProperty')(parcelIdSerial)

      throw error
    })
}
