import * as S from '@effect/schema/Schema'
import { Effect } from 'effect'
import { LocationCounty, LocationCriteria } from 'features/ListBuilder/domain/ListCriteria/GeographyCriteria'
import { GETSearchCountiesResponse } from 'features/ListBuilder/infra/services/LocationServiceLive/GETSearchCounties/GETSearchCountiesResponse'
import { restClient } from 'presentation/libs/client'

const request = (keyword: string) =>
  Effect.promise(async () =>
    await restClient.get(`national-leads/v1/counties?name=${keyword}`).json())

const decode = (u: unknown): GETSearchCountiesResponse =>
  S.decodeUnknownSync(GETSearchCountiesResponse)(u)

const toDomain = (response: GETSearchCountiesResponse): LocationCounty[] =>
  response.map(item => LocationCriteria.County(`${item.name}, ${item.state}`, item.fips))

export const GETSearchCounties = {
  request,
  decode,
  toDomain,
}
