import { Settings } from 'features/Settings/domain/Settings.domain'
import { Location } from 'features/valueObjects/Location'
import mapboxgl from 'mapbox-gl'
import { DEFAULT_FIT_BOUNDS_PADDING } from 'presentation/components/Map/Map.const'
import { LngLatBoundsLike, LngLatLike, MapRef } from 'react-map-gl'
import { createStore } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

/** Street level zoom */
const DEFAULT_ZOOM = 16

const MAP_MODE: Record<MapMode, Record<Settings.Theme, string>> = {
  map: {
    light: 'mapbox://styles/chrisregnerpropelio/clroxgcbj005e01p18p6d4jnf?access_token=pk.eyJ1IjoiY2hyaXNyZWduZXJwcm9wZWxpbyIsImEiOiJjbHJvcnU2aWsxa2hjMmpwOW8xZ3I1cmtvIn0.SiQVgHbuW6qyd7So5EzQoA',
    dark: 'mapbox://styles/chrisregnerpropelio/clroxgcq7005d01qq8ylj7u9t?access_token=pk.eyJ1IjoiY2hyaXNyZWduZXJwcm9wZWxpbyIsImEiOiJjbHJvcnU2aWsxa2hjMmpwOW8xZ3I1cmtvIn0.SiQVgHbuW6qyd7So5EzQoA',
  },
  satellite: {
    light: 'mapbox://styles/mapbox/satellite-v9',
    dark: 'mapbox://styles/mapbox/satellite-v9',
  },
}

export type MapStyle = string

export type MapMode = 'map' | 'satellite'

export type MapStore = {
  /** @PRIVATE we set the map ref here internally */
  _mapRef: MapRef | null
  computed: {
    getMapRef: () => MapRef | null
  }
  mode: MapMode
  isMapLoaded: boolean
  isFullscreen: boolean
  defaultZoom: number
  setMapRef: (mapRef: MapRef) => void
  toggleMode: () => void
  setMode: (mode: MapMode) => void
  onMapLoad: () => void
  getMapStyle: () => string
  recenter: (params: {
    isSingleMarker: boolean
    targetBounds: LngLatLike
  }) => void
  fitMarkers: (params: {
    markers: Location[]
    isInitialLoad: boolean
    padding?: number | mapboxgl.PaddingOptions
  }) => void
}

/**
 * @TODO Refactor. This seems to be getting a bit too large and handling a bit
 * too many responsibilities. I think the core store should only handle the
 * tracking/exposing map behaviors, and other behaviors such us fullscreen,
 * centering, mode, etc should be separate modules that depends on exposed map
 * API that can be composed together.
 */
export const createMapStore = (themeType: Settings.Theme) => createStore(
  subscribeWithSelector<MapStore>((set, get) => ({
    _mapRef: null,
    computed: {
      getMapRef: () => get().isMapLoaded && get()._mapRef
        ? get()._mapRef
        : null,
    },

    defaultZoom: DEFAULT_ZOOM,
    isFullscreen: false,
    mode: 'map',
    isMapLoaded: false,
    setMapRef: mapRef => set({ _mapRef: mapRef }),
    toggleMode: () => set(state => ({ ...state, mode: state.mode === 'map' ? 'satellite' : 'map' })),
    setMode: mode => set({ mode }),
    getMapStyle: () => {
      const mode = get().mode
      return getMapStyle(mode, themeType)
    },
    onMapLoad: () => set({ isMapLoaded: true }),
    recenter: params => {
      const mapRef = get().computed.getMapRef()
      if (!mapRef) return
      recenter(mapRef, params)
    },
    fitMarkers: params => {
      const mapRef = get().computed.getMapRef()
      if (!mapRef || !get().isMapLoaded) return
      fitMarkers(mapRef, params)
    },
  })),
)

const fitMarkers = (
  mapRef: MapRef,
  params: {
    markers: Location[]
    isInitialLoad?: boolean
    padding?: number | mapboxgl.PaddingOptions
  },
) => {
  const { markers, isInitialLoad } = params
  /**
     * Animating on first load from min zoom level all the way to streetview isn't very pleasant.
     * So for first load just immediately show the intended zoom/centering.
     */
  const duration = isInitialLoad ? 0 : 1000
  const padding = params.padding ?? DEFAULT_FIT_BOUNDS_PADDING

  if (markers.length === 0) {
    mapRef.fitBounds(MapLib.GLOBE_WITH_US_AT_CENTER_BOUNDS, { duration })

    return
  }

  const longitudeList = markers.map(v => v.longitude)
  const latitudeList = markers.map(v => v.latitude)

  const westLongitude = longitudeList.reduce((acc, current) =>
    Math.min(acc, current), longitudeList[0])
  const southLatitude = latitudeList.reduce((acc, current) =>
    Math.min(acc, current), latitudeList[0])
  const eastLongitude = longitudeList.reduce((acc, current) =>
    Math.max(acc, current), longitudeList[0])
  const northLatitude = latitudeList.reduce((acc, current) =>
    Math.max(acc, current), latitudeList[0])

  const southwestCorner: [number, number] = [westLongitude, southLatitude]
  const northeastCorner: [number, number] = [eastLongitude, northLatitude]

  if (southwestCorner.some(isNaN) || northeastCorner.some(isNaN)) return

  // const bounds: LngLatBoundsLike = [southwestCorner, northeastCorner]
  const bounds = new mapboxgl.LngLatBounds([southwestCorner, northeastCorner])
  /**
     * If there is only one marker, zoom in to it with
     * detailed street layouts within a neighborhood.
     */
  if (markers.length === 1) {
    mapRef.flyTo({
      center: [westLongitude, northLatitude],
      zoom: DEFAULT_ZOOM,
      duration,
      padding,
    })

    return
  }

  try {
    mapRef.fitBounds(bounds, { padding, duration })
  } catch (error) {
    // Remove padding when it fails to fit bounds. Probably because the
    // map viewport is too small to fit the markers with padding.
    mapRef.fitBounds(bounds, { padding: 0, duration })
  }
}

const recenter = (
  mapRef: MapRef,
  params: {
    isSingleMarker: boolean
    targetBounds: LngLatLike
  },
) => {
  const {
    targetBounds,
    isSingleMarker,
  } = params

  const mapBounds = mapRef?.getMap().getBounds()

  /**
   * If the marker is already in view do not recenter
   */
  const isMarkerInView = mapBounds?.contains(targetBounds)
  if (isMarkerInView) return

  /**
   * If there is only one marker, zoom in to it with
   * detailed street layouts within a neighborhood.
   */
  if (isSingleMarker) {
    mapRef?.flyTo({
      center: targetBounds,
      zoom: DEFAULT_ZOOM,
    })
    return
  }

  mapRef?.flyTo({ center: targetBounds })
}

export const getMapStyle = (mode: MapMode, theme: Settings.Theme): MapStyle =>
  MAP_MODE[mode][theme]

// ========================================
// Calculate "GLOBE WITH US AT CENTER BOUNDS"
// ========================================

/**
 * @example
 * loop(10)(2) = 2
 * loop(10)(12) = 2
 * loop(10)(22) = 2
 * loop(90)(135) = 45
 * loop(180)(-98.585522 - 50) = -148.585522
 */
const loop = (max: number) => (value: number): number =>
  (value > max)
    ? loop(max)(value - max)
    : value

const loopLng = loop(180)
const loopLat = loop(90)

const DEGREES_FROM_CENTER = 50

/** [longitude, latitude] */
const US_MAINLAND_CENTER = [-98.585522, 39.833333]

/**
 * This bounds, is like a rectangle inside a circle,
 * if map was viewed as globe and US is at the center.
 *
 * The effect is it will show the globe, with US at the center.
 */
const GLOBE_WITH_US_AT_CENTER_BOUNDS = [
  // Go 50 degrees South, 50 degrees West from US Mainland Center
  [
    loopLng(US_MAINLAND_CENTER[0] - DEGREES_FROM_CENTER),
    loopLat(US_MAINLAND_CENTER[1] - DEGREES_FROM_CENTER),
  ],

  // Go 50 degrees North, 50 degrees East from US Mainland Center
  [
    loopLng(US_MAINLAND_CENTER[0] + DEGREES_FROM_CENTER),
    loopLat(US_MAINLAND_CENTER[1] + DEGREES_FROM_CENTER),
  ],
] satisfies LngLatBoundsLike

export const MapLib = {
  DEFAULT_ZOOM,
  GLOBE_WITH_US_AT_CENTER_BOUNDS,
  DEFAULT_CENTER: US_MAINLAND_CENTER,
  getMapStyle,
  fitMarkers,
  recenter,
}
