import { useSelector } from '@xstate/react'
import { Array, Option, pipe } from 'effect'
import OwnerRelationMachine from 'features/OwnersProperties/view/machine/OwnerRelationMachine'
import MachineContext from 'features/OwnersProperties/view/machine/OwnersPropertiesMachineContext'
import OwnersPropertiesRoutes from 'features/OwnersProperties/view/routes/OwnersPropertiesRoutes'
import Hooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { ReactNode, useEffect, useMemo } from 'react'
import { MapRef } from 'react-map-gl'
import { useParams } from 'react-router-dom'

namespace OwnerPropertiesHooksLive {
  const useAsyncInput: Hooks.UseAsyncInput = () => {
    const actor = MachineContext.useActorRef()

    return {
      onEmpty: () => actor.send({ type: 'get-async-input-empty' }),
      onError: () => actor.send({ type: 'get-async-input-failed' }),
      onSuccess: requirements => actor.send({ type: 'get-async-input-success', payload: requirements }),
    }
  }

  const useScreen: Hooks.UseScreen = () =>
    MachineContext.useSelector(snapshot => {
      switch (true) {
      case snapshot.matches({ GetAsyncInputsSuccess: 'GetPreviewLoading' }):
        return Hooks.ScreenViewModel.Loading()

      case snapshot.matches({ GetAsyncInputsSuccess: 'GetPreviewSucceeded' }):
        return snapshot.context.data.pipe(
          Option.map(data =>
            Hooks.ScreenViewModel.Success({
              potentialPropertiesCount: data.ownershipInfo.potentialPropertiesCount,
            })),
          Option.getOrElse(() => Hooks.ScreenViewModel.Error()),
        )

      default:
        return Hooks.ScreenViewModel.Error()
      }
    })

  const useOwnersList: Hooks.UseOwnersList = () => {
    const ownerIds = MachineContext.useSelector(snapshot =>
      snapshot.context.data.pipe(
        Option.map(data =>
          data.owners.map(actor =>
            actor.getSnapshot().context.owner.id),
        ),
        Option.getOrElse(() => []),
      ))

    return { ownerIds }
  }

  const useOwnerActorRef = (ownerId: Option.Option<string>) =>
    MachineContext.useSelector(snapshot =>
      Option.all([
        ownerId,
        snapshot.context.data,
      ]).pipe(
        Option.flatMapNullable(([ownerId, data]) =>
          data.owners.find(actor =>
            actor.getSnapshot().context.owner.id === ownerId),
        ),
      ))

  // eslint-disable-next-line @stylistic/comma-dangle
  const useOwnerActorSelector = <T,>(
    ownerId: Option.Option<string>,
    selector: (snapshot: Option.Option<OwnerRelationMachine.Snapshot>) => T,
  ): T => {
    const actorRef = useOwnerActorRef(ownerId).pipe(
      Option.getOrUndefined,
    )

    const selected = useSelector(actorRef, snapshot =>
      selector(Option.fromNullable(snapshot)),
    )

    return selected
  }

  const useOwner: Hooks.UseOwner = ownerId => {
    const actorRef = MachineContext.useActorRef()

    const viewModel = useOwnerActorSelector(
      Option.some(ownerId),
      Option.map(snapshot => ({
        owner: snapshot.context.owner,
        onAddressClick: () => {
          const address = snapshot.context.owner.address
          if (Option.isNone(address)) return
          actorRef.send({
            type: 'open-address',
            payload: { address: address.value },
          })
        },
      })))

    return viewModel
  }

  const useOwnerTabSm: Hooks.UseOwnerTabSm = ownerId => {
    const owners = MachineContext.useSelector(snapshot =>
      snapshot.context.data.pipe(
        Option.map(data => data.owners),
      ),
    )

    const actorRef = useOwnerActorRef(Option.some(ownerId))

    const viewModel = useOwnerActorSelector(
      Option.some(ownerId),
      Option.flatMap(snapshot =>
        Option.all([actorRef, owners]).pipe(
          Option.map(([actorRef, owners]) => {
            const lastOwnerIndex = owners.length - 1
            const lastOwner = owners[lastOwnerIndex].getSnapshot().context.owner
            const isLastRow = snapshot.context.owner === lastOwner
            const isExpanded = snapshot.matches({ OwnerRow: 'Expanded' })

            return {
              isLastRow,
              isExpanded: snapshot.matches({ OwnerRow: 'Expanded' }),
              onToggleExpand: () => actorRef.send({
                type: isExpanded ? 'collapse-owner-row' : 'expand-owner-row',
              }),
            }
          }),
        )),
    )

    return viewModel
  }

  const useOwnerMobSm: Hooks.UseOwnerMobSm = ownerId => {
    const actorRef = MachineContext.useActorRef()

    return {
      onOwnerDealsOpenMobile: () => actorRef.send({
        type: 'open-owner-deals-mobile',
        payload: { ownerId },
      }),
    }
  }

  const useOwnerDeals: Hooks.UseOwnerDeals = (ownerId: string) =>
    useOwnerActorSelector(
      Option.some(ownerId),
      Option.flatMap((
        snapshot,
      ): Option.Option<Hooks.OwnerDealsViewModel> => {
        switch (true) {
        case snapshot.matches({ GetDeals: 'Idle' }):
          return Option.some(Hooks.OwnerDealsViewModel.Loading())

        case snapshot.matches({ GetDeals: 'Loading' }):
          return Option.some(Hooks.OwnerDealsViewModel.Loading())

        case snapshot.matches({ GetDeals: 'Success' }):
          return Option.all([
            snapshot.context.dealRelations, snapshot.context.displayed.dealRelations]).pipe(
            Option.map(([allRelations, displayedRelations]) => Hooks.OwnerDealsViewModel.Success({
              dealIds: displayedRelations.map(relation => relation.deal.id),
              displayedPropertiesCount: pipe(
                allRelations,
                Array.filter(relation =>
                  !snapshot.context.displayed.filters.shouldLimitToCurrentlyOwned
                  || relation.isCurrentlyOwned),
                Array.length,
              ),
            })),
          )

        default:
          return Option.some(Hooks.OwnerDealsViewModel.Error())
        }
      }),
    )

  const useOwnerDealsMobSm: Hooks.UseOwnerDealsMobSm = () => {
    const { ownerId: ownerIdRaw } = useParams()
    const ownerId = useMemo(() => Option.fromNullable(ownerIdRaw), [ownerIdRaw])

    const actorRef = useOwnerActorRef(ownerId)

    const viewModel = useOwnerActorSelector(
      ownerId,
      snapshot => Option.all([actorRef, snapshot]).pipe(
        Option.flatMap((
          [actorRef, snapshot],
        ): Option.Option<Hooks.OwnerDealsMobSmViewModel> => {
          switch (true) {
          case snapshot.matches({ GetDeals: 'Idle' }):
            return Option.some(Hooks.OwnerDealsMobSmViewModel.Loading())

          case snapshot.matches({ GetDeals: 'Loading' }):
            return Option.some(Hooks.OwnerDealsMobSmViewModel.Loading())

          case snapshot.matches({ GetDeals: 'Success' }):
            return Option.all([snapshot.context.dealRelations, snapshot.context.displayed.dealRelations]).pipe(
              Option.map(([allRelations, displayedRelations]) => Hooks.OwnerDealsMobSmViewModel.Success({
                ownerId: snapshot.context.owner.id,
                dealIds: displayedRelations.map(relation => relation.deal.id),
                displayedPropertiesCount: pipe(
                  allRelations,
                  Array.filter(relation =>
                    !snapshot.context.displayed.filters.shouldLimitToCurrentlyOwned
                    || relation.isCurrentlyOwned),
                  Array.length,
                ),
                onBackToOwnerProperties: () => actorRef.send({ type: 'go-back-to-owners-properties' }),
              })),
            )

          default:
            return Option.some(Hooks.OwnerDealsMobSmViewModel.Error())
          }
        }),
      ),
    )

    useEffect(() => {
      if (Option.isNone(actorRef)) return
      actorRef.value.send({ type: 'get-deals' })
    }, [actorRef])

    return viewModel
  }

  const useOwnerDeal: Hooks.UseOwnerDeal = (ownerId: string, dealId: string) => {
    const actorRef = MachineContext.useActorRef()

    const dealRelations = useOwnerActorSelector(
      Option.some(ownerId),
      Option.flatMap(snapshot =>
        snapshot.context.dealRelations,
      ),
    )

    const dealRelation = dealRelations.pipe(
      Option.flatMapNullable(relations =>
        relations.find(relation =>
          relation.deal.id === dealId),
      ),
    )

    return dealRelation.pipe(
      Option.map(relation => ({
        relation,
        onAddressClick: () => {
          const address = relation.property.address
          const parcelId = relation.property.parcelId
          actorRef.send({
            type: 'open-address',
            payload: parcelId.pipe(
              Option.map(parcelId => ({ parcelId })),
              Option.getOrElse(() => ({ address })),
            ),
          })
        },
      })),
    )
  }

  const useMap: Hooks.UseMap = ownerId => {
    const actorRef = useOwnerActorRef(Option.some(ownerId))

    return actorRef.pipe(
      Option.map(actorRef => ({
        onMount: (mapRef: MapRef) => actorRef.send({ type: 'map-mounted', mapRef }),
        onUnmount: () => actorRef.send({ type: 'map-unmounted' }),
      })),
    )
  }

  const useBackButton: Hooks.UseBackButton = () => {
    const actor = MachineContext.useActorRef()
    return {
      onBack: () => actor.send({ type: 'go-back' }),
    }
  }

  const useSkiptraceButton: Hooks.UseSkiptraceButton = () => {
    const actor = MachineContext.useActorRef()
    return {
      onSkiptrace: () => actor.send({ type: 'skiptrace' }),
    }
  }

  const useOwnersInfo: Hooks.UseOwnersInfo = () => {
    const actorRef = MachineContext.useActorRef()

    return MachineContext.useSelector(snapshot =>
      snapshot.context.data.pipe(
        Option.map(data => ({
          ownership: data.ownershipInfo,
          owners: data.owners.map(actor => actor.getSnapshot().context.owner),
          onOpenAddress: () => {
            const address = Option.fromNullable(data.ownershipInfo.address)

            if (Option.isNone(address)) return

            actorRef.send({
              type: 'open-address',
              payload: { address: address.value },
            })
          },
        })),
      ))
  }

  const useAddressButton: Hooks.UseAddressButton = () => {
    const actor = MachineContext.useActorRef()

    return {
      onClick: payload => actor.send({ type: 'open-address', payload }),
    }
  }

  const useRedirect: Hooks.UseRedirect = () => {
    const leadId = MachineContext.useSelector(snapshot => snapshot.context.leadId)

    return {
      ownersPropertiesPath: leadId.pipe(
        Option.map(leadId => OwnersPropertiesRoutes.makeOwnersPropertiesPath(leadId)),
      ),
    }
  }

  const useToggleLimitToCurrentlyOwned: Hooks.UseToggleLimitToCurrentlyOwned = ownerId => {
    const actorOption = useOwnerActorRef(Option.some(ownerId))

    return actorOption.pipe(
      Option.map(actor => ({
        shouldLimitToCurrentlyOwned: actor.getSnapshot().context.displayed.filters.shouldLimitToCurrentlyOwned,
        onToggle: () => actor.send({ type: 'toggle-limit-to-currently-owned' }),
      })),
    )
  }

  export const Provider = ({ children }: { children: ReactNode }) => {
    const value = useMemo((): Hooks => ({
      useAsyncInput,
      useScreen,
      useOwnersList,
      useOwner,
      useOwnerTabSm,
      useOwnerMobSm,
      useOwnerDeals,
      useOwnerDealsMobSm,
      useOwnerDeal,
      useMap,
      useBackButton,
      useSkiptraceButton,
      useOwnersInfo,
      useAddressButton,
      useRedirect,
      useToggleLimitToCurrentlyOwned,
    }), [])

    return (
      <Hooks.Provider value={value}>
        {children}
      </Hooks.Provider>
    )
  }
}

export default OwnerPropertiesHooksLive
