import { createMultiStyleConfigHelpers, StyleFunctionProps } from '@chakra-ui/react'
import { pipe } from 'effect'
import { mapValues, merge } from 'lodash/fp'
import { MODAL_MX } from 'presentation/components/Modal/Modal.theme'
import { RESET_SPACING_CLASS_NAME as RESET_SPACING } from 'presentation/components/molecules/Card/Card.const'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { sizeToPx } from 'presentation/utils/chakraSizeToNumber'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { px } from 'presentation/utils/px'
import { subtractPixelFromSize } from 'presentation/utils/subtractPixelFromSize'
import { getRecKey } from 'utils/getRecKey'

/**
 * CARD BORDER WIDTH in px
 */
const CARD_BORDER_WIDTH = 2

const DEFAULT_CARD_SIZE = 'md-locked'

// =============================================================================
// Parts
// =============================================================================

export const CARD_PARTS = {
  container: 'container',

  primaryTitle: 'primaryTitle',
  primaryText: 'primaryText',
  primarySupportingText: 'primarySupportingText',

  secondaryTitle: 'secondaryTitle',
  secondaryText: 'secondaryText',
  secondarySupportingText: 'secondarySupportingText',

  image: 'image',

  header: 'header',
  body: 'body',
  footer: 'footer',

  button: 'button',
  tertiaryAction: 'tertiaryAction',
  footnote: 'footnote',

  fullWidthSection: 'fullWidthSection',
  sideScrollSpacer: 'sideScrollSpacer',
}

// =============================================================================
// Helpers
// =============================================================================

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  CARD_PARTS.container,

  CARD_PARTS.primaryTitle,
  CARD_PARTS.primaryText,
  CARD_PARTS.primarySupportingText,

  CARD_PARTS.secondaryTitle,
  CARD_PARTS.secondaryText,
  CARD_PARTS.secondarySupportingText,

  CARD_PARTS.image,

  CARD_PARTS.header,
  CARD_PARTS.body,
  CARD_PARTS.footer,

  CARD_PARTS.button,
  CARD_PARTS.tertiaryAction,
  CARD_PARTS.footnote,

  CARD_PARTS.fullWidthSection,
  CARD_PARTS.sideScrollSpacer,
])

// =============================================================================
// Base Style
// =============================================================================

const baseStyle = definePartsStyle({
  primaryTitle: {
    '& + .chakra-button': {
      alignSelf: 'center',
    },
  },
  secondaryTitle: {
    'display': 'flex',
    'alignItems': 'center',
    'alignSelf': 'center',

    '& + .chakra-button': {
      alignSelf: 'center',
    },
  },
  header: {
    m: 0,
    p: 0,
  },
  body: {
    m: 0,
    p: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  sideScrollSpacer: {
    display: 'flex',
  },
  button: {
    /**
     * @HACK Only styles should go here but since we control Card.Button, we opted to this hack,
     * so that the size is in theme file
     */
    size: mbp({
      mobSm: 'sm',
      tabSm: 'md',
    }) as any,
  },
  tertiaryAction: {
    cursor: 'pointer',
  },
  image: {
    boxSize: mbp({ mobSm: '140px', tabSm: '180px' }),
    mx: 'auto',
    pos: mbp({ mobSm: 'static', tabSm: 'absolute' }),
    top: '-106px',
    right: '-16px',
  },
})

// =============================================================================
// Sizes
// =============================================================================

export const cardSizes = {
  // =============================================================================
  // Locked Sizes
  // =============================================================================

  'xxs-locked': (props: StyleFunctionProps) => definePartsStyle({
    container: {
      p: maybeAdjustForBorder(2, props),
      borderRadius: 3,
    },
    primaryTitle: {
      ...textStyles.bodyXLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyXLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: '2',
      },

    },
    primaryText: {
      ...textStyles.bodyLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    primarySupportingText: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },

    body: {
      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
      },
    },

    secondaryTitle: {
      ...textStyles.bodyLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },
    },
    secondaryText: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    secondarySupportingText: {
      ...textStyles.bodySFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    footnote: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    footer: {
      p: 0,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
        gap: 2,
      },

    },
    button: {},
    tertiaryAction: textStyles.bodyL,
    sideScrollSpacer: {
      [`&:not(.${RESET_SPACING})`]: {
        minW: 2,
      },
    },
    fullWidthSection: {
      [`&:not(.${RESET_SPACING})`]: {
        mx: -2,
      },
    },
  }),

  'xs-locked': (props: StyleFunctionProps) => definePartsStyle({
    container: {
      px: maybeAdjustForBorder(2, props),
      py: maybeAdjustForBorder(3, props),
      borderRadius: 3,
    },
    primaryTitle: {
      ...textStyles.bodyXLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyXLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: '2',
      },
    },
    primaryText: {
      ...textStyles.bodyLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    primarySupportingText: {
      ...textStyles.bodyMFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },

    body: {
      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
      },
    },

    secondaryTitle: {
      ...textStyles.bodyLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyLFat.lineHeight}) / 2)`,
      },

      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    secondaryText: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    secondarySupportingText: {
      ...textStyles.bodySFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    footnote: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    footer: {
      p: 0,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
        gap: 2,
      },
    },
    button: {},
    tertiaryAction: textStyles.bodyL,
    sideScrollSpacer: {
      [`&:not(.${RESET_SPACING})`]: {
        minW: 2,
      },
    },
    fullWidthSection: {
      [`&:not(.${RESET_SPACING})`]: {
        mx: -2,
      },
    },
  }),

  'sm-locked': (props: StyleFunctionProps) => definePartsStyle({
    container: {
      p: maybeAdjustForBorder(3, props),
      borderRadius: 3,
    },
    primaryTitle: {
      ...textStyles.h4,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.h4.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },
    },
    primaryText: {
      ...textStyles.bodyXLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    primarySupportingText: {
      ...textStyles.bodyLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },

    body: {
      [`&:not(.${RESET_SPACING})`]: {
        mt: 4,
      },
    },

    secondaryTitle: {
      ...textStyles.bodyXLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyXLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    secondaryText: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    secondarySupportingText: {
      ...textStyles.bodyM,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 1,
      },
    },
    footnote: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
      },
    },
    footer: {
      p: 0,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 4,
        gap: 2,
      },
    },
    button: {},
    tertiaryAction: textStyles.bodyL,
    sideScrollSpacer: {
      [`&:not(.${RESET_SPACING})`]: {
        minW: 3,
      },
    },
    fullWidthSection: {
      [`&:not(.${RESET_SPACING})`]: {
        mx: -3,
      },
    },
  }),

  'md-locked': (props: StyleFunctionProps) => definePartsStyle({
    container: {
      p: maybeAdjustForBorder(4, props),
      borderRadius: 4,
    },
    primaryTitle: {
      ...textStyles.h3,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.h3.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    primaryText: {
      ...textStyles.bodyXLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    primarySupportingText: {
      ...textStyles.bodyLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },

    body: {
      [`&:not(.${RESET_SPACING})`]: {
        mt: 5,
      },
    },

    secondaryTitle: {
      ...textStyles.bodyXLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyXLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    secondaryText: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    secondarySupportingText: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    footnote: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
      },
    },
    footer: {
      p: 0,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 4,
        gap: 3,
      },
    },
    button: {},
    tertiaryAction: textStyles.bodyXL,
    sideScrollSpacer: {
      [`&:not(.${RESET_SPACING})`]: {
        minW: 4,
      },
    },
    fullWidthSection: {
      [`&:not(.${RESET_SPACING})`]: {
        mx: -4,
      },
    },
  }),

  'lg-locked': (props: StyleFunctionProps) => definePartsStyle({
    container: {
      p: maybeAdjustForBorder(6, props),
      borderRadius: 5,
    },
    primaryTitle: {
      ...textStyles.h3,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.h3.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    primaryText: {
      ...textStyles.bodyXLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    primarySupportingText: {
      ...textStyles.bodyLFat,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },

    body: {
      [`&:not(.${RESET_SPACING})`]: {
        mt: 5,
      },
    },

    secondaryTitle: {
      ...textStyles.bodyXLFat,
      [`&:not(.${RESET_SPACING})`]: {
        my: `calc((40px - ${textStyles.bodyXLFat.lineHeight}) / 2)`,
      },
      [`&:not(.${RESET_SPACING}) + .chakra-button:not(.${RESET_SPACING})`]: {
        ml: 2,
      },

    },
    secondaryText: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    secondarySupportingText: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 2,
      },
    },
    footnote: {
      ...textStyles.bodyL,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 3,
      },
    },
    footer: {
      p: 0,

      [`&:not(.${RESET_SPACING})`]: {
        mt: 4,
        gap: 3,
      },
    },
    button: {},
    tertiaryAction: textStyles.bodyXL,
    sideScrollSpacer: {
      [`&:not(.${RESET_SPACING})`]: {
        minW: 5,
      },
    },
    fullWidthSection: {
      [`&:not(.${RESET_SPACING})`]: {
        mx: -6,
      },
    },
  }),

  // =============================================================================
  // Modal Sizes
  // =============================================================================
  'modal.default': (props: StyleFunctionProps) => mbpg({
    mobSm: cardSizes['xxs-locked'](props),
    mob: cardSizes['sm-locked'](props),
    tabSm: cardSizes['lg-locked'](props),
  }),

  'modal.default.n': (props: StyleFunctionProps) => merge(
    cardSizes['modal.default'](props),
    {
      container: {
        w: '736px',
        maxW: modalMaxWidth,
      },
    },
  ),

  'modal.default.w': (props: StyleFunctionProps) => merge(
    cardSizes['modal.default'](props),
    {
      container: {
        w: '1008px',
        maxW: modalMaxWidth,
      },
    },
  ),

  'modal.default.xw': (props: StyleFunctionProps) => merge(
    cardSizes['modal.default'](props),
    {
      container: {
        w: '1168px',
        maxW: modalMaxWidth,
      },
    },
  ),

  'modal.alert.xn': () => merge(
    {
      container: {
        maxW: modalMaxWidth,
      },
    },
    mbpg({
      mobSm: {
        container: {
          px: 2,
          pt: 3,
          pb: 3,
          borderRadius: 3,

          w: '304px',
        },
        primaryTitle: {
          ...textStyles.bodyXLHeavy,
          [`&:not(.${RESET_SPACING})`]: {
            mt: 0.5,
          },
        },
        primaryText: {
          ...textStyles.bodyL,
          [`&:not(.${RESET_SPACING})`]: {
            mt: 1,
            textAlign: 'center',
          },
        },
        footer: {
          [`&:not(.${RESET_SPACING})`]: {
            mt: 3,
            gap: 2,
          },
        },
        button: {},
        tertiaryAction: textStyles.bodyL,
      },

      tabSm: {
        container: {
          px: 6,
          pt: 7,
          pb: 6,
          borderRadius: 4,

          w: '496px',
        },
        primaryTitle: {
          ...textStyles.h3Heavy,
          [`&:not(.${RESET_SPACING})`]: {
            mt: 0,
          },
        },
        primaryText: {
          ...textStyles.bodyXLFat,
          [`&:not(.${RESET_SPACING})`]: {
            mt: 2,
            textAlign: 'center',
          },
        },
        footer: {
          [`&:not(.${RESET_SPACING})`]: {
            mt: 5,
            gap: 3,
          },
        },
        button: {},
        tertiaryAction: textStyles.bodyXL,
      },
    })),

  // =============================================================================
  // Responsive Sizes
  // =============================================================================
  /** @TODO One day the responsive hero will fill this place */
  'xxs': () => ({}),
  'xs': () => ({}),
  'sm': () => ({}),
  'md': () => ({}),
  'lg': () => ({}),
}

// =============================================================================
// Color Scheme
// =============================================================================

export const cardColorSchemes = {
  'card.bg.1': ({ size }: StyleFunctionProps) => definePartsStyle({
    container: {
      bg: 'card.bg.1',
      borderColor: 'card.bg.1',
      boxShadow: size !== 'full' ? 'primary.s' : 'none',
    },
    primaryTitle: {
      color: 'accent.blue-text',
    },
    primaryText: {
      color: 'graystrong.200',
    },
    primarySupportingText: {
      color: 'graystrong.400',
    },
    secondaryTitle: {
      color: 'graystrong.500',
    },
    secondaryText: {
      color: 'graystrong.400',
    },
    secondarySupportingText: {
      color: 'graystrong.400',
    },
    footnote: {
      color: 'graystrong.200',
    },
    tertiaryAction: {
      color: 'link.500',
    },
  }),
  'card.bg.2': (props: StyleFunctionProps) =>
    merge(
      cardColorSchemes['card.bg.1'](props),
      {
        container: {
          bg: 'card.bg.2',
        },
      },
    ),
  'modal.alert.neutral': (props: StyleFunctionProps) =>
    merge(
      cardColorSchemes['card.bg.1'](props),
      {
        primaryTitle: {
          color: 'accent.blue-text',
        },
        primaryText: {
          color: 'graystrong.200',
        },
      },
    ),
  'modal.alert.negative': (props: StyleFunctionProps) =>
    merge(
      cardColorSchemes['card.bg.1'](props),
      {
        primaryTitle: {
          color: 'negative.500',
        },
        primaryText: {
          color: 'graystrong.200',
        },
      },
    ),
  'modal.highlight': ({ size }: StyleFunctionProps) => definePartsStyle({
    container: {
      bg: 'modal.bg',
      boxShadow: size !== 'full' ? 'primary.s' : 'none',
    },
    primaryTitle: {
      color: 'graystrong.500',
    },
    primaryText: {
      color: 'modal.text',
    },
    primarySupportingText: {
      color: 'modal.text',
    },
    secondaryTitle: {
      color: 'graystrong.500',
    },
    secondaryText: {
      color: 'modal.text',
    },
    secondarySupportingText: {
      color: 'modal.text',
    },
    footnote: {
      color: 'graystrong.200',
    },
    tertiaryAction: {
      color: 'link.500',
    },
  }),
}

// =============================================================================
// Variants
// =============================================================================

export const cardVariants = {
  'default': (props: StyleFunctionProps) =>
    getRecKey(props.colorScheme)(cardColorSchemes)?.(props) || {},
  'bordered': (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(cardColorSchemes)?.(props) || {},
      {
        container: {
          borderWidth: px(CARD_BORDER_WIDTH),
        },
      },
    ),
  'modal.alert': (props: StyleFunctionProps) =>
    merge(
      getRecKey(props.colorScheme)(cardColorSchemes)?.(props) || {},
      {
        primaryTitle: {
          textAlign: 'center',
        },
        primaryText: {
          textAlign: 'center',
        },
      },
    ),
}

// =============================================================================
// Card Theme
// =============================================================================

export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: cardSizes,
  variants: cardVariants,
  defaultProps: {
    variant: 'default',
    colorScheme: 'card.bg.1',
    size: DEFAULT_CARD_SIZE,
  },
})

const maybeAdjustForBorder = (size: number, props: StyleFunctionProps) =>
  props.variant?.endsWith('bordered')
    ? subtractPixelFromSize({ size, pixel: CARD_BORDER_WIDTH })
    : size

/**
 * @HACK
 * Context:
 * Inside ModalContent, we have close button and the card.
 *
 * Requirement #1:
 * Card width should try to achieve maxW unless it exceeds 100vh minus margins.
 *
 * Requiruement #2:
 * ModalContent should fit the card width, so that the close button can position
 * relative to it.
 *
 * Problem:
 * If the ModalContent has w='auto', Card inside will only take the minimum space,
 *   breaking requirement #1
 * If the ModalContent has w='full', it will break requirement #2 and the close
 *   button will be positioned relative to the screen, not the card.
 *
 * Solution:
 * Replace maxW={desiredMaxW} and w='full' (which causes the behavior described)
 * with maxW='calc(100vh-modalMargin)' and w={desiredMaxW}
 */
const modalMaxWidth = pipe(
  MODAL_MX,
  mapValues((size: number) => {
    const margin = sizeToPx(size)
    if (margin === null) return '' // should be unreachable
    return `calc(100vw - ${margin * 2}px)`
  }),
  mbp,
)
