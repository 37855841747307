import { Button, Center, Checkbox, Flex, ModalOverlay, Spinner, Text } from '@chakra-ui/react'
import { shallowEqual, useSelector } from '@xstate/react'
import { Array } from 'effect'
import { useParcelListManager } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import SelectableListMachine from 'features/ListBuilder/views/machines/SelectableListMachine'
import { NumberLib } from 'libs/Number'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import ladyGiftPng from 'presentation/assets/img/3d/lady-gift.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { ScrollWithFade } from 'presentation/components/ScrollWithFadeV2'
import { Card, CardBody, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import CreateListAction from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/CreateListAction/CreateListAction'
import { usePropertySaveToListModal, usePropertySaveToListModalActions } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/usePropertySaveToListModal'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { useEffect } from 'react'

export const PropertySaveToListActionModal = () => {
  const modalActions = usePropertySaveToListModalActions()
  const modalState = usePropertySaveToListModal()

  const actor = useParcelListManager()

  const {
    lists,
    isLoading,
    isLoaded,
    hasList,
  } = useSelector(actor, snapshot => ({
    lists: snapshot.context.selectableLists,
    isLoading: snapshot.hasTag('loading'),
    isLoaded: snapshot.matches('Loaded'),
    hasList: snapshot.context.parcelLists.length > 0,
  }), shallowEqual)

  const handleSave = () => {
    actor.send({ type: 'lists.save' })
  }

  useEffect(() => {
    if (!isLoaded && modalState.status === 'open')
      modalActions.close()
  }, [isLoaded, modalState.status])

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.neutral'
          >
            <CardImage
              src={hasList ? happyDogPng : ladyGiftPng}
              display={mbp({ mobSm: 'none', tabSm: 'block' })}
            />
            <ModalCloseButton onClose={modalActions.close} />

            <CardPrimaryTitle color={hasList ? 'neutral.500' : 'positive.500'}>
              {hasList ? 'Change Property List' : 'Save Property to List'}
            </CardPrimaryTitle>
            <CardPrimaryText>
              Select the desired list from the options below or create a new list.
            </CardPrimaryText>

            <CardBody mt={mbp({ mobSm: 2, tabSm: 3 })}>
              {isLoading
                ? (
                  <Center py={5}>
                    <Spinner />
                  </Center>
                )
                : (
                  <ScrollWithFade
                    containerProps={{
                      minH: '280px',
                      maxH: '460px',
                    }}
                    pb={3}
                    px={2}
                    mx={-2}
                    fadeColor='rgb(255, 255, 255, 1.5)'
                    fadeTopSize={-0.5}
                    fadeBottomSize={4}
                  >
                    <Flex
                      flexDirection='column'
                      gap={1}
                    >
                      {Array.map(lists, list => (
                        <ListSelectionItem selectableList={list} key={list.getSnapshot().context.list.id} />
                      ))}
                    </Flex>
                  </ScrollWithFade>
                )}
            </CardBody>
            <CardFooter
              shouldResetSpacing
              gap={mbp({ mobSm: 2, tabSm: 3 })}
              mt={6}
            >
              <CreateListAction />
              <Button
                variant='solid'
                colorScheme='positive'
                onClick={handleSave}
                isLoading={isLoading}
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}

const ListSelectionItem = (props: { selectableList: SelectableListMachine }) => {
  const { list, isSelected } = useSelector(props.selectableList, snapshot => ({
    list: snapshot.context.list,
    isSelected: snapshot.matches('Checked'),
  }), shallowEqual)

  const toggle = () => {
    props.selectableList.send({ type: 'list.toggle' })
  }
  return (
    <Checkbox
      isChecked={isSelected}
      onChange={toggle}
      py={1}
      sx={{
        '& .chakra-checkbox__label': {
          gap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          w: 'full',
          minW: 0,
        },
      }}
    >
      <Text
        textStyle='bodyMFat'
        color='graystrong.400'
        isTruncated
      >
        {list.name}
      </Text>
      <Text
        textStyle='bodySFat'
        color='grayweak.900'
        ml='auto'
        whiteSpace='nowrap'
      >
        {NumberLib.formatComma(list.memberCount)}
        {' '}
        properties
      </Text>
    </Checkbox>
  )
}
