/**
 * @NOTE Prepend selectors with `body` to increase specificity
 *   (and override react-toastify's default styles)
 */
export const globalStyles = {
  'body .Toastify__toast-container': {
    w: '100%',
    py: ['1', '0'],
    px: '1',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    pointerEvents: 'none',
  },
  'body .Toastify__toast': {
    p: '0',
    pointerEvents: 'auto',
    borderRadius: '2',
    bg: 'none',
  },
  'body .Toastify__toast-body': {
    p: '0',
  },
  'body .Toastify__progress-bar--info': {
    bg: 'neutral.700',
    h: '5px',
  },
  'body .Toastify__progress-bar--success': {
    bg: 'positive.700',
    h: '5px',
  },
  'body .Toastify__progress-bar--error': {
    bg: 'negative.700',
    h: '5px',
  },
  'body .Toastify__close-button': {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'graystrong.800',
  },
}
