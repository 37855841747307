import { BoxProps, chakra, ChakraProps, Flex, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { getMLSStatusTagColor, MLSStatusColorProps } from 'presentation/components/MLSStatusTag/MLSStatusTag.utils'
import { MLSStatusTagProps } from './MLSStatusTag.props'

export const MLSStatusTag = ({
  status,
  date,
  labelLayout = 'horizontal',
  children,
  ...boxProps
}: MLSStatusTagProps): JSX.Element => {
  const { color, bgColor, borderColor } = getMLSStatusTagColor(status)
  const label = MLSStatus.toString(status)
  return (
    <Tag
      flexShrink={0}
      {...boxProps}
      color={color}
      bgColor={bgColor}
      borderColor={borderColor}
      date={date}
      labelLayout={labelLayout}
    >
      {children || label}
    </Tag>
  )
}

export const MLSStatusMultipleTag = (props: BoxProps): JSX.Element => (
  <Tag
    {...props}
    color='marker.brown'
    bgColor='marker.cream'
    borderColor='marker.brown'
  >
    Multiple
  </Tag>
)

type TagProps = MLSStatusColorProps & Pick<MLSStatusTagProps, 'date' | 'labelLayout'>

export const Tag = (props: TagProps) => {
  const { color, bgColor, date, labelLayout, children, ...rest } = props
  const isVertical = labelLayout === 'vertical' && date
  return (
    <Flex
      filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
      w='fit-content'
      {...rest}
    >
      <TagHead
        bgColor={bgColor}
        {...isVertical
          ? {
            w: '30px',
          }
          : {
            w: '18px',
          }}
      />
      <Flex
        {...isVertical
          ? {
            ml: '-3px',
          }
          : {}}
        pr={1}
        backgroundColor={bgColor}
        borderEndRadius='1'
        alignItems='center'
        flexShrink={0}
        flexGrow={1}
      >
        <Flex
          w='full'
          flexDirection={isVertical ? 'column' : 'row'}
          gap={isVertical ? 0 : 0.5}
          alignItems={isVertical ? 'flex-start' : 'center'}
          justifyContent={isVertical ? 'flex-start' : 'center'}
        >
          <Text
            textStyle='bodySHeavy'
            color={color}
            textAlign={isVertical ? 'left' : 'center'}
          >
            {children}
          </Text>
          {date && (
            <Text
              textStyle='bodySFat'
              color={color}
              textAlign='left'
            >
              {!isVertical && '- '}
              {dayjs(date).format('MM.DD.YY')}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

type TagHeadProps = Pick<MLSStatusColorProps, 'bgColor'> & ChakraProps

const TagHead = (props: TagHeadProps) => {
  const { bgColor, ...rest } = props
  return (
    <chakra.svg
      viewBox='0 0 18 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      stroke='none'
      strokeWidth='1'
      fillRule='evenodd'
      fill={bgColor}
      {...rest}
    >
      <path
        d='M2.644125,7.36140939 C-0.881375,9.51440939 -0.881375,14.4856094 2.644125,16.6386094 L13.296125,23.1435094 C17.074225,25.4507094 22.000025,22.8256094 22.000025,18.5049094 L22.000025,5.49510939 C22.000025,1.17440939 17.074225,-1.45069061 13.296125,0.856509393 L2.644125,7.36140939 Z M6.000025,14.0000094 C7.104625,14.0000094 8.000025,13.1046094 8.000025,12.0000094 C8.000025,10.8954094 7.104625,10.0000094 6.000025,10.0000094 C4.895425,10.0000094 4.000025,10.8954094 4.000025,12.0000094 C4.000025,13.1046094 4.895425,14.0000094 6.000025,14.0000094 Z'
      />
      <rect
        fillRule='nonzero'
        x='9.000025'
        y='9.3926123e-06'
        rx='8'
      />
    </chakra.svg>
  )
}
