import { Button, CardProps, Flex, Text, TextProps } from '@chakra-ui/react'
import { useSkiptraceStore } from 'features/Skiptrace/infra/react/Skiptrace.state'
import { Dollar } from 'libs/dollar'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { Card, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { openWalletBalanceEntryModal } from 'presentation/screens/Billing/Billing.modals'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { pipe } from 'remeda'

export const SkiptraceAccountBalanceCard = (props: CardProps) => {
  const state = useAccountBalanceCardState()

  if (state.status === 'unready') return null

  return (
    <Card
      size='sm-locked'
      {...props}
    >
      <Flex justifyContent='space-between'>
        <CardPrimaryTitle>
          Account Balance
        </CardPrimaryTitle>
        <AddBalanceButton />
      </Flex>
      <PriceText>
        {pipe(
          state.funds.available,
          Dollar.fromCents,
          Dollar.formatNumberWithCommasDecimals,
        )}
      </PriceText>
      <Breakpoint
        mobSm={<AccountBalanceInfo mt={4} />}
        mob={<></>}
        tabSm={<AccountBalanceInfo mt={4} />}
      />
    </Card>
  )
}

const PriceText = (props: TextProps) => (
  <Text
    {...mbpg({
      mobSm: {
        fontFamily: 'Inter',
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '36px',
        mt: 1,
      },
      tabSm: {
        fontSize: '42px',
        lineHeight: '46px',
        mt: 3,
      },
      dtSm: {
        fontSize: '50px',
        lineHeight: '55px',
      },
    })}
    color='graystrong.400'
    {...props}
  />
)

const AddBalanceButton = () => (
  <Button
    variant='solid'
    colorScheme='special'
    size={mbp({ mobSm: 'sm', tabSm: 'md' })}
    onClick={openWalletBalanceEntryModal}
  >
    <Breakpoint
      mobSm='Add'
      tabSm='Add Balance'
    />
  </Button>
)

const AccountBalanceInfo = (props: TextProps) => (
  <Text
    textStyle='bodyL'
    color='graystrong.400'
    {...props}
  >
    Your account balance is used for mailers, skiptraces, and other services.
  </Text>
)

const useAccountBalanceCardState = () =>
  useSkiptraceStore(skiptraceApi => {
    const skiptraceFunds = skiptraceApi.entities.skiptraceFunds

    if (skiptraceFunds.status !== 'success') return { status: 'unready' as const }

    return {
      status: 'ready' as const,
      funds: skiptraceFunds.attempt.funds,
      planName: skiptraceFunds.attempt.planName,
    }
  })
