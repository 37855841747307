import { Effect, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { DownloadNotAvailableError } from 'features/ListBuilder/domain/Errors'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import { downloadTextAsFile } from 'features/Skiptrace/infra/remote/downloadResult/downloadResult'
import { HTTPError } from 'ky'
import { restClient } from 'presentation/libs/client'

const DownloadListResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.DownloadList) => pipe(
    Effect.tryPromise({
      try: async () => await restClient
        .get(`lists/v3/${request.listId}/downloads/${request.downloadId}`)
        .text()
        .then(text => downloadTextAsFile({ text, fileName: `${request.fileName}.csv` })),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404) {
          return new DownloadNotAvailableError({
            marketingListId: request.listId,
            downloadId: request.downloadId,
          })
        }

        return new UnknownException(error)
      },
    }),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default DownloadListResolver
