import { Image, ImageProps } from '@chakra-ui/react'
import PropelioLogoSvg from 'presentation/assets/img/red-logo-black-text.svg'
import { mbp } from 'presentation/utils/mapBreakpoint'

export const PropelioLogo = (props: ImageProps) => (
  <Image
    src={PropelioLogoSvg}
    h={mbp({
      mob: '4',
      tabSm: '6',
      dtSm: '38px',
      dtLg: '5',
    })}
    {...props}
  />
)
