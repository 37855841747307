import { ModalOverlay } from '@chakra-ui/react'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { FC } from 'react'
import { SuccessModalProps } from './SuccessModal.types'
import LadyGift from './img/lady-gift.png'

export const SuccessModal: FC<SuccessModalProps> = ({
  isOpen = true,
  onClose,
  message,
  actionButtons,
  ...props
}) => (
  <Modal
    {...props}
    isOpen={isOpen}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent>
      <Card
        variant='modal.alert'
        size='modal.alert.xn'
        colorScheme='modal.alert.neutral'
      >
        <CardImage src={LadyGift} />
        <ModalCloseButton onClose={onClose} />
        <CardPrimaryTitle>
          That was a success!
        </CardPrimaryTitle>
        <CardBody>
          <CardPrimaryText>
            {message ?? 'Congratulations!'}
          </CardPrimaryText>
        </CardBody>
        <CardFooter>
          {actionButtons
            ? actionButtons()
            : (
              <CardButton variant='solid' colorScheme='neutral' onClick={closeModal} autoFocus>
                Great!
              </CardButton>
            )}
        </CardFooter>

      </Card>
    </ModalContent>
  </Modal>
)
