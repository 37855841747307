import { Link, LinkProps, useLocation } from 'react-router-dom'
import { isString } from 'remeda'

export const PreserveQueryLink = (props: LinkProps) => {
  const location = useLocation()

  const newPath = isString(props.to)
    ? `${props.to}${location.search}`
    : props.to

  return <Link {...props} to={newPath} />
}
