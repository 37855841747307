import { Flex, Image, Text } from '@chakra-ui/react'
import Img from 'presentation/assets/img/3d/2-peeps-1-magnifying-glass.png'
import { Card } from 'presentation/components/molecules/Card'
import { PropsWithChildren } from 'react'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

const NearbyBuyersNoData = ({ children }: PropsWithChildren) => (
  <Card
    boxShadow='button-hovered'
    h='full'
  >
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      h='full'
    >
      <Image
        src={Img}
        {...mbpg({
          mobSm: {
            w: '120px',
            h: '120px',
          },
          tabSm: {
            w: '180px',
            h: '180px',
          },
        })}
      />

      <Text
        mt={2}
        textStyle={mbp({
          mobSm: 'bodyXLFat',
          tabSm: 'h2',
        })}
        color='graystrong.500'
      >
        Oops! No Buyers Match your Filters
      </Text>
      <Text
        mt={mbp({
          mobSm: 1,
          tabSm: 2,
        })}
        textStyle={mbp({
          mobSm: 'bodyMFat',
          tabSm: 'bodyXLFat',
        })}
        textAlign='center'
        color='graystrong.200'
      >
        It seems like there are no matching results. Please adjust your filters and try again.
      </Text>
      {children}
    </Flex>
  </Card>
)

export default NearbyBuyersNoData
