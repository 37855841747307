import { useSelector } from '@xstate/react'
import { Array, Data, Equal, Match, Option, pipe } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { ProductFeatureSpec } from 'presentation/screens/Plans/PlanComparisonV2/domain/subscription/ProductFeatureSpec'

type TeamFeatureRowViewModel = {
  readonly descriptionPriceText: string
  readonly primaryText: readonly Option.Option<string>[]
}

const isTeamMembersFeature = (feature: ProductFeatureSpec): boolean =>
  feature.featureName === 'TeamMembers'

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const vm: TeamFeatureRowViewModel = {
    descriptionPriceText: '$9/month per user', // @NOTE: Where does this come from?
    primaryText: pipe(
      snapshot.context.products,
      /**
       * Make sure we only show the product if the plan is available
       */
      Array.filter(product => pipe(
        product.plans,
        Array.some(plan =>
          plan._tag === (snapshot.context.isYearly ? 'YearlyPlan' : 'MonthlyPlan')),
      )),
      Array.map(product => pipe(
        product.features,
        Array.findFirst(isTeamMembersFeature),
        Option.flatMap(feature => pipe(
          Match.value(feature),
          Match.tag('FeatureUnlimitedSpec', () => Option.some('Unlimited')),
          Match.tag('FeatureLimitedSpec', f => Option.some(`${f.limit} Team Members`)),
          Match.orElse(() => Option.none()),
        )),
      )),
      Data.array,
    ),
  }
  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const TeamFeatureRowViewModel = {
  usePresenter,
}

export default TeamFeatureRowViewModel
