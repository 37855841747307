import { Box, Center, CircularProgress, Grid, GridItem, ModalOverlay, Spinner, Text } from '@chakra-ui/react'
import { useDownloadListState, useDownloadStats, useListStoreActions } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { NumberLib } from 'libs/Number'
import happyDogPng from 'presentation/assets/img/3d/happy-dog.png'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { Pair, PairKey, PairValue } from 'presentation/components/Pair/Pair'
import { toast } from 'presentation/components/Toast'
import { Card, CardBody, CardButton, CardFooter, CardImage, CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { TOAST_PRESET } from 'presentation/const/toast.const'
import { useToastFailedStateTagged } from 'presentation/libs/hooks/useToastFailedState'
import { useListDataDownloadModal, useListDataDownloadModalActions } from 'presentation/screens/ListScreen/components/ListFooter/components/useListDataDownloadModal'
import { useEffect } from 'react'

export const ListDataDownloadModal = () => {
  const modalState = useListDataDownloadModal()
  const modalActions = useListDataDownloadModalActions()
  const downloadStatsState = useDownloadStats()
  const listActions = useListStoreActions()
  const downloadListState = useDownloadListState()

  useEffect(() => {
    if (modalState.status !== 'open') return

    listActions.getDownloadStats(modalState.payload.listId)
  }, [modalState.status])

  useToastFailedStateTagged(downloadStatsState, {
    title: 'Failed to fetch download stats',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  useToastFailedStateTagged(downloadListState, {
    title: 'Failed to download list',
    message: TOAST_PRESET.GENERIC_ERROR.message,
  })

  const handleDownload = () => {
    if (modalState.status !== 'open') return

    listActions.downloadList(modalState.payload.listId)
    modalActions.close()
    toast.info({
      title: 'Download Starting...',
      message: 'Your list will download shortly',
    })
  }

  useEffect(() => {
    if (downloadStatsState._tag !== 'Success') return

    const limitInfo = downloadStatsState.downloadStats.limitInfoByInterval.monthly

    if (limitInfo.limit !== Infinity) return

    handleDownload()
  }, [downloadStatsState])

  return (
    <Modal
      isOpen={modalState.status === 'open'}
      onClose={modalActions.close}
    >
      <ModalOverlay />
      <ModalContent>
        {modalState.status === 'open' && (
          <Card
            variant='modal.alert'
            size='modal.alert.xn'
            colorScheme='modal.alert.neutral'
          >
            <CardImage src={happyDogPng} />
            <ModalCloseButton onClose={modalActions.close} />

            <CardPrimaryTitle>
              Download List Data
            </CardPrimaryTitle>

            {downloadStatsState._tag === 'Success'
              ? (() => {
                const limitInfo = downloadStatsState.downloadStats.limitInfoByInterval.monthly

                return (
                  <>
                    <CardBody>
                      <CardPrimaryText>
                        Continue with the download?
                      </CardPrimaryText>

                      <Card
                        mt={5}
                        p={3}
                        borderRadius={4}
                        bg='highlight.100'
                        boxShadow='primary.m'
                      >
                        <Grid
                          templateColumns='min-content 1fr'
                          columnGap={2}
                          rowGap={3}
                        >
                          <GridItem>
                            <Box pos='relative'>
                              <CircularProgress
                                capIsRound
                                transform='scaleX(-1)'
                                value={limitInfo.remaining / limitInfo.limit * 100}
                                color='positivesat.500'
                                trackColor='grayweak.500'
                                size='160px'
                                thickness={4}
                              />

                              <Center
                                flexDir='column'
                                pos='absolute'
                                top={0}
                                left={0}
                                right={0}
                                bottom={0}
                                display='flex'
                              >
                                <Text color='positivesat.500' fontSize='26px' fontWeight='700'>
                                  {NumberLib.formatComma(limitInfo.remaining)}
                                </Text>
                                <Text textAlign='center' textStyle='bodyMFat' color='graystrong.600'>
                                  Lead List
                                  <br />
                                  Exports Left
                                </Text>
                              </Center>
                            </Box>
                          </GridItem>
                          <GridItem
                            display='flex'
                            flexDir='column'
                            justifyContent='center'
                            alignItems='stretch'
                          >
                            <Pair>
                              <PairKey>Monthly Export Limit:</PairKey>
                              <PairValue>{NumberLib.formatComma(limitInfo.limit)}</PairValue>
                            </Pair>

                            <Pair>
                              <PairKey>Exported This Month:</PairKey>
                              <PairValue>{NumberLib.formatComma(limitInfo.consumed)}</PairValue>
                            </Pair>
                          </GridItem>
                        </Grid>
                        {/* <GridItem
                        pt={2}
                        colSpan={2}
                      >
                        <Text textStyle='bodyL' color='graystrong.400' textAlign='center'>
                          Your lead list export limit will replenish on 04.02.2024.
                        </Text>
                      </GridItem> */}
                      </Card>
                    </CardBody>

                    <CardFooter>
                      <CardButton
                        variant='outline'
                        colorScheme='neutral'
                        size='md'
                        onClick={modalActions.close}
                      >
                        Cancel
                      </CardButton>
                      <CardButton
                        variant='solid'
                        colorScheme='neutral'
                        size='md'
                        onClick={handleDownload}
                      >
                        Download
                      </CardButton>
                    </CardFooter>
                  </>
                )
              })()
              : (
                <Center py={5}>
                  <Spinner />
                </Center>
              )}
          </Card>
        )}
      </ModalContent>
    </Modal>
  )
}
