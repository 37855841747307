import { Array, Brand, HashSet, Match, Option, pipe } from 'effect'
import { HomeType } from 'features/CMA/valueObjects/HomeType'

const fromNumber = (n: number) => n as HomeTypeCode

const fromIterable = (n: readonly number[]) => pipe(
  n,
  Array.fromIterable,
  Array.map(fromNumber),
)

const getCodes = (type: HomeType) => pipe(
  Match.value(type),
  Match.when('town-house', () => fromIterable([43, 44, 48])),
  Match.when('multi-family', () => fromIterable([
    63, 67, 68, 69, 70, 73, 75, 77, 78, 79,
    115, 116, 122, 133,
    /**
     * @NOTE technically, the following omitted numbers belong to multi-family.
     * But because of duplication, selecting a home type might that has a number,
     * from a different set may cause confusion. Meaning that the next time the codes
     * are converted to a HomeType, it will select a previously non selected type.
     *
     * 44 already in townhouse
     * 124 already in commercial
     */
  ])),
  Match.when('lot-or-land', () => fromIterable([
    250, 261, 262, 265, 275, 276, 277, 278, 280,
    281, 283, 284, 285, 286, 290, 291, 292,
  ])),
  Match.when('single-family', () => fromIterable([
    41, 42, 54, 55, 58, 59, 61, 333, 334, 347,
  ])),
  Match.when('commercial', () => fromIterable([
    71, 72, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
    112, 113, 114, 117, 118, 119, 120, 121, 123, 124,
    125, 126, 127, 128, 129, 130, 131, 132, 134, 135,
    136, 137, 150,
  ])),
  Match.when('condo', () => fromIterable([
    45, 46, 60,
  ])),
  Match.when('mobile', () => fromIterable([
    47, 57, 62,
  ])),
  Match.when('farm', () => fromIterable([
    49, 251, 252, 253, 254, 255, 256, 257, 258, 259,
    264, 266,
  ])),
  Match.when('duplex-to-fourplex', () => fromIterable([
    64, 65, 66,
  ])),
  Match.orElse(() => Array.empty<HomeTypeCode>()),
)

export const getTypes = (inputs: readonly HomeTypeCode[]) => pipe(
  HomeType.toArray(),
  Array.map(code => pipe(
    HomeTypeCode.getCodes(code),
    HashSet.fromIterable,
    HashSet.intersection(HashSet.fromIterable(inputs)),
    HashSet.size,
    size => size > 0 ? Option.some(code) : Option.none(),
  ),
  ),
  Array.getSomes,
)

type HomeTypeCode = number & Brand.Brand<'HomeTypeCode'>

const HomeTypeCode = {
  fromNumber,
  fromIterable,
  getCodes,
  getTypes,
}

export default HomeTypeCode
