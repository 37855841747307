import { ApolloQueryResult } from '@apollo/client'
import { LoadCmaQuery } from '__generated__/graphql'
import assert from 'assert'
import { Array, Match, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { PropertyDetails } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { Location } from 'features/valueObjects/Location'
import { MLSOrganization } from 'features/valueObjects/MLSOrganization'
import { MLSStatus } from 'features/valueObjects/MLSStatus'
import { Photo } from 'features/valueObjects/Photo'
import haversine from 'haversine'
import { DateLib } from 'libs/Date'
import { NumberLib } from 'libs/Number'
import { Sqft } from 'libs/Sqft'
import { StringLib } from 'libs/String'
import { geoJSONToCompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/geoJSONtoCompsBoundary'
import { isNonNull, isNumber } from 'remeda'
import { isNonNullable } from 'utils/isNonNullable'

const ensureNumberOrNull = NumberLib.ensureNumberOrNull

export const mapCMA = ({ rest, gql, property }: {
  rest: any
  gql: ApolloQueryResult<LoadCmaQuery> | null
  property: PropertyDetails.Property
}): CMA.Report | null => {
  const reportRaw = Array.isArray(rest)
    ? rest?.[0] // for load CMA, an array of reports is returned (usually only 1 element)
    : rest // for filter CMA, only the specific report is returned

  if (!reportRaw) return null

  const updatedAt = DateLib.ensureOrCastStringOrNull(reportRaw?.updated_at)
    || DateLib.ensureOrCastStringOrNull(reportRaw?.created_at)

  if (!updatedAt) return null

  const subjListingEdges = gql?.data.parcel?.mlsPropertyListings?.edges || []
  const subjListingIdMap: Record<string, boolean> = !gql
    ? {}
    : subjListingEdges.reduce((acc: Record<string, boolean>, edge) => {
      const listingId = edge?.node.resoPropertyListing?.listingId

      if (!listingId) return acc

      return {
        ...acc,
        [listingId]: true,
      }
    }, {})

  const subjLocation = Location.ensureOrNull(property.location)

  const initOrgsMap: Record<string, MLSOrganization> = {}
  const fallbackOrg = {
    id: '',
    name: '',
    logo: '',
  }
  const organizationsMap = ((reportRaw?.organizations || []) as any[])
    .reduce((acc, org: any) => {
      if (!org?.id) return acc

      acc[org.id] = {
        id: org.id,
        name: org.name || '',
        logo: org.logo || '',
      }

      return acc
    }, initOrgsMap)

  const mapComps = (type: 'sales' | 'rentals', rawComps: any[]): {
    subjectComp: CMA.SubjectComp | null
    list: CMA.SingleComp[]
  } => {
    let subjectComp: CMA.SubjectComp | null = null
    const list = rawComps.map((compRaw: any): CMA.SingleComp | null => {
      if (!compRaw) return null

      const location = Location.ensureOrNull({
        latitude: NumberLib.ifValid(NumberLib.roundNPlaces(5))(ensureNumberOrNull(compRaw.address_lat)),
        longitude: NumberLib.ifValid(NumberLib.roundNPlaces(5))(ensureNumberOrNull(compRaw.address_lon)),
      })

      if (location === null) return null

      if (location.latitude === 0 && location.longitude === 0) return null

      const mlsStatus = ((): MLSStatus | null => {
        switch (compRaw.status) {
        case 'active':
          return type === 'sales'
            ? 'FOR_SALE'
            : 'FOR_LEASE'
        case 'pending':
          return 'PENDING'
        case 'sold':
          return type === 'sales'
            ? 'SOLD'
            : 'LEASED'
        case 'leased':
          return 'LEASED'
        case 'expired':
          return 'EXPIRED'
        case 'cancelled':
          return 'CANCELED'
        case 'withdrawn':
          return 'WITHDRAWN'
        default:
          return null
        }
      })()

      if (mlsStatus === null || !subjLocation) return null

      const bathsFull = NumberLib.ensureOrNull(compRaw.baths_full)
      const bathsHalf = NumberLib.ensureOrNull(compRaw.baths_half)
      const listPrice = NumberLib.ensureOrNull(compRaw.list_price)
      const sqft = NumberLib.ensureOrNull(compRaw.sqft)
      const salePrice = NumberLib.ensureOrNull(compRaw.close_price)
      const rawRating = reportRaw?.states?.[compRaw.key]

      const comp: CMA.SingleComp = {
        id: StringLib.ensureOr('')(compRaw.key),
        listingId: StringLib.ensureOr('')(compRaw.mls),
        isRental: type === 'rentals',
        location,
        address: {
          line1: [
            compRaw.address_line1,
            compRaw.address_unit,
          ].filter(isNonNull).join(' ') || '',
          city: compRaw.address_city || '',
          state: compRaw.address_state || '',
          postalCode: compRaw.address_zip || '',
          subdivision: compRaw.address_subdivision || '',
        },
        bathroomsCountInfo: {
          full: bathsFull,
          half: bathsHalf,
          total: isNumber(bathsFull) || isNumber(bathsHalf)
            ? `${bathsFull || '0'}.${bathsHalf || '0'}`
            : null,
        },
        bedroomsCount: NumberLib.ensureOrNull(compRaw.beds),
        buildingAreaSqft: null,
        livingAreaSqft: NumberLib.ensureOrNull(compRaw.sqft),
        daysOnMarket: NumberLib.ensureOrNull(compRaw.calculated_dom),
        description: StringLib.ensureOrNull(compRaw.remarks),
        distance: haversine(subjLocation, location, { unit: 'mile' }),
        garageSpacesCount: NumberLib.ensureOrNull(compRaw.garage),
        listPrice,
        listPricePerSqft: isNumber(listPrice) && isNumber(sqft)
          ? listPrice / sqft
          : null,
        lotAreaAcres: NumberLib.ensureOrNull(compRaw.lot_size_acres),
        lotAreaSqft: NumberLib.ensureOrNull(compRaw.lot_size_sqft),
        organization: organizationsMap?.[compRaw.organization_id] || fallbackOrg,
        agent: {
          email: StringLib.ensureOrNull(compRaw.listing_agent_email),
          name: StringLib.ensureOrNull(compRaw.listing_agent_name),
          phone: StringLib.ensureOrNull(compRaw.listing_agent_phone),
        },
        broker: {
          name: StringLib.ensureOrNull(compRaw.listing_office_name),
          phone: StringLib.ensureOrNull(compRaw.listing_office_phone),
          email: StringLib.ensureOrNull(compRaw.listing_office_email),
        },
        buyerAgent: (compRaw.status === 'sold' || compRaw.status === 'leased') && compRaw.buyer_agent_name
          ? {
            name: StringLib.ensureOrNull(compRaw.buyer_agent_name),
            phone: StringLib.ensureOrNull(compRaw.buyer_agent_phone),
            email: StringLib.ensureOrNull(compRaw.buyer_agent_email),
          }
          : null,
        buyerBroker: (compRaw.status === 'sold' || compRaw.status === 'leased') && compRaw.buyer_agent_name
          ? {
            name: StringLib.ensureOrNull(compRaw.buyer_office_name),
            phone: StringLib.ensureOrNull(compRaw.buyer_office_phone),
            email: StringLib.ensureOrNull(compRaw.buyer_office_email),
          }
          : null,
        photos: (compRaw.photos || [])
          .map((raw: any): Photo | null => {
            const url = StringLib.ensureOrNull(raw.url)

            if (!url) return null

            return {
              url,
              caption: StringLib.ensureOrNull(raw.description),
            }
          })
          .filter(isNonNull),
        /** @TODO Standardize/validate */
        propertyType: StringLib.ensureOrNull(compRaw.property_type) as any,
        salePrice,
        salePricePerSqft: isNumber(salePrice) && isNumber(sqft)
          ? salePrice / sqft
          : null,
        saleDate: DateLib.ensureOrCastStringOrNull(compRaw.close_date),
        yearBuilt: NumberLib.ensureOrNull(compRaw.year_built),
        status: mlsStatus,
        type: 'single-comp',
        userRating: rawRating === 'included'
          ? 'included'
          : rawRating === 'excluded'
            ? 'excluded'
            : 'undecided',
        hasPool: !!compRaw.pool,

        /** @TODO not in REST yet */
        classification: null,
        listDate: null,
        driveway: null,
        equityType: null,
        expireDate: null,
        exterior: null,
        exteriorFeature: null,
        fireplace: null,
        fireplacesCount: null,
        flooring: null,
        hasBasement: false,
        heatingOrCooling: null,
        interiorFeature: null,
        isForeclosure: false,
        isSenior: false,
        isVacant: false,
        laundry: null,
        parking: null,
        style: null,
      }

      if (!subjectComp && subjListingIdMap[compRaw.mls]) {
        subjectComp = {
          ...comp,
          type: 'subject-comp',
        }

        return null
      }

      return comp
    })
      .filter(isNonNull)

    return {
      list,
      subjectComp,
    }
  }

  const saleCompsInfo = mapComps('sales', reportRaw?.data?.sales || [])
  const rentalCompsInfo = mapComps('rentals', reportRaw?.data?.leases || [])

  let saleSubjProperty: CMA.SubjectComp | null = saleCompsInfo.subjectComp
  let rentalSubjProperty: CMA.SubjectComp | null = rentalCompsInfo.subjectComp

  if (!saleSubjProperty || !rentalSubjProperty) {
    const details = property.status === 'with-details' ? property : null
    const offMarketProperty: CMA.SubjectComp = {
      id: 'subject-off-market',
      listingId: '',
      type: 'subject-comp',
      status: 'OFF_MARKET',
      location: property.location,
      address: {
        ...property.address,
        subdivision: details?.land?.subdivision || null,
      },
      isRental: null,
      bathroomsCountInfo: details?.building?.bathroomsCount || null,
      bedroomsCount: NumberLib.ensureOrNull(details?.building?.bedroomsCount),
      buildingAreaSqft: null,
      livingAreaSqft: NumberLib.ensureOrNull(details?.building?.livingAreaSqft),
      daysOnMarket: null,
      description: null,
      garageSpacesCount: NumberLib.ensureOrNull(details?.building?.garageSpacesCount),
      listPrice: null,
      listPricePerSqft: null,
      lotAreaAcres: NumberLib.ensureOrNull(details?.land?.lotAreaAcres),
      lotAreaSqft: NumberLib.ensureOrNull(details?.land?.lotAreaSqft),
      agent: {
        email: null,
        name: null,
        phone: null,
      },
      broker: {
        name: null,
        phone: null,
        email: null,
      },
      buyerAgent: null,
      buyerBroker: null,
      organization: fallbackOrg,
      photos: [],
      classification: details?.ownership?.classification || null,
      listDate: null,
      driveway: null,
      equityType: details?.valuation?.equityType || null,
      expireDate: null,
      exterior: null,
      exteriorFeature: null,
      fireplace: null,
      fireplacesCount: details?.building?.fireplacesCount || null,
      flooring: null,
      hasBasement: details?.building
      && isNumber(details?.building.basementAreaSqft)
        ? details?.building.basementAreaSqft > 0
        : null,
      hasPool: details?.building?.hasPool || false,
      heatingOrCooling: null,
      interiorFeature: null,
      isForeclosure: details?.ownership?.isForeclosure || false,
      isSenior: details?.ownership?.isSenior || false,
      isVacant: details?.ownership?.isVacant || false,
      laundry: null,
      parking: null,
      propertyType: null,
      salePrice: NumberLib.ensureOrNull(details?.lastSale?.salePrice),
      salePricePerSqft: details?.lastSale
      && details?.building
      && isNumber(details?.lastSale.salePrice)
      && isNumber(details?.building.livingAreaSqft)
        ? details?.lastSale.salePrice / details?.building.livingAreaSqft
        : null,
      saleDate: details?.lastSale?.saleDate || null,
      style: null,
      userRating: null,
      yearBuilt: details?.building?.yearBuilt
        ? NumberLib.fromStringSafe(details?.building.yearBuilt)
        : null,
    } satisfies CMA.SubjectComp

    const mlsFromPropDetails = property.status === 'with-details' && property.mlsListings[0]
      ? {
        ...property.mlsListings[0],
        type: 'subject-comp',
        buyerAgent: null,
        buyerBroker: null,
      }
      : null

    saleSubjProperty = saleSubjProperty
    || (!mlsFromPropDetails?.isRental && mlsFromPropDetails && {
      ...mlsFromPropDetails,
      type: 'subject-comp',
      userRating: null,
    })
    || offMarketProperty
    rentalSubjProperty = rentalSubjProperty
    || (mlsFromPropDetails?.isRental && mlsFromPropDetails && {
      ...mlsFromPropDetails,
      type: 'subject-comp',
      userRating: null,
    })
    || offMarketProperty
  }

  assert(saleSubjProperty !== null)
  assert(rentalSubjProperty !== null)

  const estimateMethod = reportRaw?.arvType === 'avg_per_foot'
    ? 'from-avg-per-sqft'
    : reportRaw?.arvType === 'avg_price'
      ? 'from-avg'
      : reportRaw?.arvType === 'custom'
        ? 'override'
        : 'from-avg-per-sqft'

  const castedOverrideValue = typeof reportRaw?.arv === 'string'
    ? NumberLib.fromStringSafe(reportRaw?.arv)
    : reportRaw?.arv

  const homeTypes: HomeType[] = pipe(
    reportRaw?.params?.propertyTypePresets ?? [],
    Array.map(type => Match.value(type).pipe(
      Match.when('TOWN_HOUSE', () => 'town-house' as HomeType),
      Match.when('MULTI_FAMILY', () => 'multi-family' as HomeType),
      Match.when('LOT_LAND', () => 'lot-or-land' as HomeType),
      Match.when('COMMERCIAL', () => 'commercial' as HomeType),
      Match.when('SINGLE_FAMILY', () => 'single-family' as HomeType),
      Match.when('CONDO', () => 'condo' as HomeType),
      Match.when('MOBILE_HOME', () => 'mobile' as HomeType),
      Match.when('FARM', () => 'farm' as HomeType),
      Match.when('MULTI_PLEX', () => 'duplex-to-fourplex' as HomeType),
      Match.orElse(() => null),
    )),
    Array.filter(isNonNullable),
  )

  return {
    /** @NOTE Legacy concept, lead is equivalent to search entries */
    leadId: reportRaw?.lead_id || '',
    /** @NOTE Legacy concept, in legacy report has ID */
    reportId: reportRaw?.id || '',
    updatedAt,

    organizations: Object.values(organizationsMap),

    salesListInfo: {
      subject: saleSubjProperty,
      comps: saleCompsInfo.list,
      didExceedMaxResults: reportRaw?.sales_count > reportRaw?.data?.sales?.length,
      estimateInfo: estimateMethod === 'override' && isNumber(castedOverrideValue)
        ? {
          method: estimateMethod,
          overrideValue: castedOverrideValue,
        }
        : estimateMethod !== 'override'
          ? { method: estimateMethod }
          : { method: 'from-avg-per-sqft' as const },
    },

    rentalsListInfo: {
      subject: rentalSubjProperty,
      comps: rentalCompsInfo.list,
      didExceedMaxResults: reportRaw?.leases_count > reportRaw?.data?.leases?.length,
      estimateInfo: { method: 'from-avg-per-sqft' },
    },

    filters: {
      bathroomsCount: {
        range: [
          NumberLib.ensureOrNull(reportRaw?.params?.baths_full?.min),
          NumberLib.ensureOrNull(reportRaw?.params?.baths_full?.max),
        ],

        /* @TODO not yet supported by backend */
        shouldMatchSubjectProperty: false,
      },
      bedroomsCount: {
        range: [
          NumberLib.ensureOrNull(reportRaw?.params?.beds?.min),
          NumberLib.ensureOrNull(reportRaw?.params?.beds?.max),
        ],

        /* @TODO not yet supported by backend */
        shouldMatchSubjectProperty: false,
      },
      livingAreaSqft: {
        range: [
          NumberLib.ensureOrNull(reportRaw?.params?.sqft?.min),
          NumberLib.ensureOrNull(reportRaw?.params?.sqft?.max),
        ],
      },
      distanceMiles: {
        max: NumberLib.ensureOr(CMA.DEFAULT_DISTANCE_MILES_MAX)(reportRaw?.params?.range),
        subdivision: StringLib.ensureOrNull(reportRaw?.params?.address?.subdivision),
      },
      garageSpacesCount: {
        range: [
          NumberLib.ensureOrNull(reportRaw?.params?.garage?.min),
          NumberLib.ensureOrNull(reportRaw?.params?.garage?.max),
        ],

        /* @TODO not yet supported by backend */
        shouldExcludeAll: false,
        shouldMatchSubjectProperty: false,
        type: null,
      },
      lotAreaSize: {
        unit: 'acres',
        sqftRange: [
          Sqft.fromAcresOrNull(reportRaw?.params?.lot_size_acres?.min),
          Sqft.fromAcresOrNull(reportRaw?.params?.lot_size_acres?.max),
        ],
      },
      poolsCount: {
        type: null,
        availablility: reportRaw?.params?.pool === 1 ? 'yes' : 'any',

        /* @TODO not yet supported by backend */
        shouldExcludeAll: false,
        shouldMatchSubjectProperty: false,
      },
      soldWithinMonths: {
        range: [
          0,
          /** @TODO Report when months are empty */
          NumberLib.ensureOrNull(reportRaw?.params?.months) || CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX,
        ],
        max: NumberLib.ensureOr(CMA.DEFAULT_SOLD_WITHIN_MONTHS_MAX)(reportRaw?.params?.months),
      },
      yearBuilt: {
        range: [
          NumberLib.ensureOrNull(reportRaw?.params?.year_built?.min),
          NumberLib.ensureOrNull(reportRaw?.params?.year_built?.max),
        ],
      },

      boundary: reportRaw?.params?.geojson
        ? geoJSONToCompsBoundary(reportRaw?.params?.geojson)
        : null,

      /* @TODO not yet supported by backend */
      storiesCount: null,
      basementType: null,
      construction: null,
      fireplacesCount: null,
      foundation: null,
      homeType: {
        shouldMatchSubjectProperty: false,
        types: homeTypes,
      },
    },

    propelioEstimate: property.status === 'with-details' && property.valuation?.estimateRange
      ? {
        stats: property.valuation.estimateRange,
      }
      : null,
  }
}
