import { createIcon } from '@chakra-ui/react'

export const MegaphoneIcon = createIcon({
  displayName: 'MegaphoneIcon',
  viewBox: '0 0 131 125',
  path: (
    <g stroke='currentColor' fill='none'>
      <path d='M80.9486 69.9305C80.6005 57.1651 74.9473 38.9505 59.2372 38.5291C50.3791 38.8676 47.167 51.3819 46.6422 57.6812C45.5506 70.7858 53.4598 88.5625 69.1528 88.1114C78.4613 87.8441 81.1254 76.4143 80.9486 69.9305Z' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M53.2388 80.2906C51.2041 77.871 49.0944 73.425 48.666 72.0181C47.8657 69.3895 47.203 67.5047 46.7552 63.959C52.1162 61.7668 58.3177 59.7054 63.6268 57.3077C64.2738 60.9019 65.616 67.6729 67.0239 70.9725C61.4365 74.4187 57.9494 77.3666 53.2388 80.2906Z' fill='#F7F7F7' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M63.5983 57.2917C60.2329 59.1685 61.8834 64.4735 63.1801 67.6346C63.7474 69.0174 65.8776 71.5751 67.2853 70.8191C71.6877 68.4546 67.4429 55.6536 63.5983 57.2917Z' fill='#F7F7F7' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M59.304 93.9903C79.4802 94.1607 82.3232 86.0562 84.8258 73.7363C87.9902 58.1573 78.0248 32.9541 59.3526 33.8718C47.4789 34.4551 44.0518 47.3713 36.7598 55.2185C26.3655 66.4041 18.9935 71.8304 6.67693 80.7098C-2.03305 86.9889 2.65223 101.473 11.7171 104.294C16.6428 105.827 22.7576 102.451 27.7317 100.902C38.1716 97.6509 48.7099 93.901 59.304 93.9903V93.9903Z' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M32.1202 99.5221C25.2836 96.9591 20.5526 88.0977 18.658 81.713C17.7956 78.8064 17.3473 75.5108 18.1434 72.289' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.01074 90.7163C8.40134 92.9047 9.25145 94.9999 10.7765 96.5571' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.127 87.3354C13.6729 89.655 14.7713 91.6742 16.5175 93.2475' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M35.0025 98.6201C32.0314 99.5628 25.8639 101.403 21.6098 103.123C21.6687 103.329 21.8064 103.643 21.8764 103.807C24.2595 109.411 27.4761 115.694 33.4166 118.403C36.7911 119.943 40.5361 119.84 44.2623 117.739C45.9817 116.77 47.8632 114.902 47.6959 113.018C47.3792 109.449 42.1514 106.515 39.8809 104.142C38.2909 102.48 36.2366 100.564 35.0025 98.6201Z' fill='#F7F7F7' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path fillRule='evenodd' clipRule='evenodd' d='M37.2777 97.92C35.1506 98.5113 32.5971 99.3517 30.1817 100.118C31.1343 101.829 32.4521 103.93 33.9208 105.258C34.5507 105.827 35.2193 106.419 36.1635 106.532C36.5388 106.577 36.9523 106.527 37.3408 106.444C38.494 106.197 41.3909 105.484 41.5335 103.986C41.626 103.014 40.8455 102.146 40.2843 101.5C39.271 100.331 38.3183 99.1115 37.3828 97.8821' fill='#F7F7F7' />
      <path d='M37.2777 97.92C35.1506 98.5113 32.5971 99.3517 30.1817 100.118C31.1343 101.829 32.4521 103.93 33.9208 105.258C34.5507 105.827 35.2193 106.419 36.1635 106.532C36.5388 106.577 36.9523 106.527 37.3408 106.444C38.494 106.197 41.3909 105.484 41.5335 103.986C41.626 103.014 40.8455 102.146 40.2843 101.5C39.271 100.331 38.3183 99.1115 37.3828 97.8821' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M87.267 87.0336C92.8785 87.7449 98.5414 88.31 104.095 89.2169C102.441 86.5089 101.764 82.6765 100.768 79.6543C107.605 78.0236 114.589 76.4368 121.365 74.4269C117.21 71.3389 113.489 66.9727 110.138 63.1786C116.674 60.6021 123.11 57.616 129.515 54.6565C123.126 51.3656 116.761 47.847 110.071 45.039C114.654 38.7181 118.937 32.2055 123.758 26.0265C115.903 27.024 108.055 27.6394 100.2 28.6867C102.539 22.2837 105.515 15.94 108.073 9.58246C99.293 13.3166 90.8227 17.8531 81.9512 21.3537C81.9097 16.4352 82.0107 11.4966 81.6735 6.6311C77.1485 11.3216 72.6798 16.0301 67.8986 20.4938C66.7469 17.9198 65.571 15.3709 64.2889 12.8668C62.5227 17.2983 61.1913 21.809 59.8865 26.3124' stroke='#F7F7F7' strokeWidth='2.93433' strokeMiterlimit='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
  ),
})
