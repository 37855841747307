import { createIcon } from '@chakra-ui/react'

export const BinocularIcon = createIcon({
  displayName: 'BinocularIcon',
  viewBox: '0 0 32 32',
  path: (
    <g fill='currentColor'>
      <path d='M11 5C9.032 5 7.46 6.44 7.125 8.313C5.235 8.789 3.595 10.018 2.875 11.969H2.845L0.718 17.936C0.248279 18.8893 0.00268983 19.9373 0 21C0 24.854 3.146 28 7 28C10.472 28 13.365 25.448 13.906 22.125C14.449 22.66 15.186 23 16 23C16.814 23 17.55 22.66 18.094 22.125C18.634 25.448 21.528 28 25 28C28.854 28 32 24.854 32 21C32 20.026 31.8 19.094 31.437 18.25L29.157 11.875L29.125 11.845V11.812C28.395 10.042 26.777 8.8 24.875 8.312C24.54 6.442 22.968 5 21 5C19.143 5 17.638 6.285 17.187 8H14.812C14.362 6.285 12.857 5 11 5ZM11 7C12.19 7 13 7.81 13 9V10H19V9C19 7.81 19.81 7 21 7C22.19 7 23 7.81 23 9V9.906L23.906 10C25.392 10.156 26.672 11.192 27.25 12.53C27.26 12.552 27.272 12.572 27.28 12.594L28.03 14.719C27.0878 14.2521 26.0516 14.0059 25 13.999C22.076 13.999 19.575 15.816 18.53 18.374C18.2566 17.9519 17.8818 17.605 17.4399 17.365C16.9979 17.125 16.5029 16.9995 16 17C15.497 16.9995 15.0019 17.1251 14.5599 17.3653C14.118 17.6055 13.7432 17.9527 13.47 18.375C12.424 15.817 9.923 14 7 14C5.928 14 4.91 14.253 4 14.688L4.75 12.656V12.626C5.33 11.076 6.558 10.162 8.094 10L9 9.906V9C9 7.81 9.81 7 11 7ZM7 16C9.773 16 12 18.227 12 21C12 23.773 9.773 26 7 26C4.227 26 2 23.773 2 21C2 20.292 2.175 19.64 2.438 19.03C2.454 18.993 2.451 18.944 2.468 18.906C2.8657 18.0384 3.50424 17.3033 4.30767 16.7881C5.1111 16.273 6.04559 15.9994 7 16ZM25 16C27.773 16 30 18.227 30 21C30 23.773 27.773 26 25 26C22.227 26 20 23.773 20 21C20 18.227 22.227 16 25 16ZM16 19C16.564 19 17 19.436 17 20C17 20.564 16.564 21 16 21C15.436 21 15 20.564 15 20C15 19.436 15.436 19 16 19Z' fill='currentColor' />
    </g>
  ),
})
