import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { restClient } from 'presentation/libs/client'

const request = async (
  listId: MarketingListId,
) =>
  await restClient.get('marketing/v2/orders', {
    searchParams: {
      referenceId: listId,
    },
  })
    .json()

export const GETListOrdersByListId = {
  request,
}
