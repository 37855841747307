import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import { createStore } from '@xstate/store'
import { useEffect, useState } from 'react'
import { Option } from 'effect'
import { useSelector } from '@xstate/react'
import { noop } from 'utils/noop'

type ScrollingState = 'up' | 'down' | 'idle'

const Button = ({
  children,
  leftIcon,
  ...props
}: ButtonProps) => {
  const targetScrollableElement = useSelector(Store,
    snapshot => snapshot.context.targetScrollableElementRef,
  )
  const [scrollingState, setScrollingState] = useState<ScrollingState>('down')

  useEffect(() => targetScrollableElement.pipe(
    Option.map(element => {
      if (!element) return

      let scrollTimeout: NodeJS.Timeout
      let lastScrollTop = 0

      const handleScroll = () => {
        const scrollTop = element.scrollTop

        if (scrollTop > lastScrollTop)
          setScrollingState('down')
        else
          setScrollingState('up')

        lastScrollTop = scrollTop

        clearTimeout(scrollTimeout)

        scrollTimeout = setTimeout(() => {
          setScrollingState('idle')
        }, 500)
      }

      element.addEventListener('scroll', handleScroll)

      return () => {
        clearTimeout(scrollTimeout)
        element.removeEventListener('scroll', handleScroll)
      }
    }),
    Option.getOrElse(() => noop),
  ), [targetScrollableElement])

  return (
    <ChakraButton
      colorScheme='positive'
      variant='solid'
      size='lg'
      textStyle='bodyLFat'
      position='fixed'
      right={1}
      bottom={1.5}
      zIndex='modal'
      shadow='float'
      borderRadius='100px'
      {...scrollingState !== 'up'
        ? {
          leftIcon,
        }
        : {
          width: 6.5,
          height: 6.5,
          p: 0,
          sx: {
            '&:active': {
              p: 0,
            },
          },
        }}
      {...props}
    >
      {scrollingState === 'up' ? leftIcon : children}
    </ChakraButton>
  )
}

const Store = createStore({
  context: {
    targetScrollableElementRef: Option.none<HTMLDivElement>(),
  },
  on: {
    setTargetScrollableElement: (context, event: {
      ref: Option.Option<HTMLDivElement>
    }) => ({
      ...context,
      targetScrollableElementRef: event.ref,
    }),
  },
})

const setTargetScrollableElement = (ref?: HTMLDivElement | null) =>
  Store.send({
    type: 'setTargetScrollableElement',
    ref: Option.fromNullable(ref),
  })

const FloatingActionButton = {
  Button,
  Store,
  setTargetScrollableElement,
}

export default FloatingActionButton
