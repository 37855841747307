import { Flex, Text } from '@chakra-ui/react'
import { ThumbsDownIcon, ThumbsUpIcon } from 'presentation/components/ThumbButtons'
import { Card } from 'presentation/components/molecules/Card'

export const CMANoPropertiesSelected = () => (
  <Card
    display='flex'
    justifyContent='center'
    alignContent='center'
    flexDirection='column'
    h='full'
    gap={2}
    boxShadow='button-hovered'
  >
    <Flex gap={2} mt={4} justifyContent='center'>
      <ThumbsUpIcon boxSize={7} value='up' />
      <ThumbsDownIcon boxSize={7} value='down' />
    </Flex>
    <Text
      textStyle='h2'
      color='graystrong.500'
      textAlign='center'
    >
      Oops! No Properties Selected
    </Text>
    <Text
      mt={1}
      textStyle='bodyXLFat'
      color='graystrong.200'
      textAlign='center'
    >
      Use the thumbs up or thumbs down when viewing properties to include or
      exclude them from your suggested value.
    </Text>
  </Card>
)
