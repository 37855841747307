import { create } from 'zustand'

type SupportModalState = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useSupportModalStore = create<SupportModalState>(set => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
}))

export const suppotModalStore = useSupportModalStore

export const useSupportModal = () => {
  const { isOpen, setIsOpen } = useSupportModalStore(store => store)

  return {
    isOpen,
    setIsOpen,
  }
}
