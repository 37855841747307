import { Match, pipe } from 'effect'
import { useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { useResponsiveViewValue } from 'presentation/screens/CompsScreen/components/CMAEntriesToolbar/useEntriesToolbarMenu'
import { CompsBoundary } from 'presentation/screens/CompsScreen/components/CompsBoundary/CompsBoundary.store'
import { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react'
import { identity } from 'remeda'
import { StoreApi, createStore, useStore } from 'zustand'

export type CompsViewMode =
  | 'with-table'
  | 'with-side-panel'
  | 'with-panel-overlay'
  | 'with-bottom-drawer'
  | 'with-draw-canvas'

export type CompsScreenViewModeStore = {
  mode: CompsViewMode | null
  setMode: (mode: CompsViewMode | null) => void
}

const Context = createContext<StoreApi<CompsScreenViewModeStore> | null>(null)

export const CompsScreenViewModeProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<StoreApi<CompsScreenViewModeStore>>()
  const { sbp } = useSwitchBreakpointFn()
  const viewValue = useResponsiveViewValue()
  const isDrawing = CompsBoundary.useStore(state => state.status === 'drawing')

  if (!storeRef.current) {
    storeRef.current = createStore<CompsScreenViewModeStore>()(set => ({
      mode: null,
      setMode: mode => set({ mode }),
    }))
  }

  const mode: CompsViewMode | null = isDrawing
    ? 'with-draw-canvas'
    : sbp<CompsViewMode | null>({
      mobSm: 'with-bottom-drawer',
      tabSm: pipe(
        Match.value(viewValue),
        Match.when('TABLE_VIEW', () => 'with-table' as const),
        Match.when('TILE_VIEW', () => 'with-side-panel' as const),
        Match.orElse(() => null),
      ),
    }) ?? null

  useEffect(() => {
    storeRef.current?.getState().setMode(mode)
  }, [mode])

  return (
    <Context.Provider value={storeRef.current}>
      {children}
    </Context.Provider>
  )
}

// eslint-disable-next-line @stylistic/comma-dangle
export const useCompsScreenViewMode = <T,>(
  selector: (store: CompsScreenViewModeStore) => T = (identity as (state: CompsScreenViewModeStore) => T),
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const context = useContext(Context)
  if (!context) throw new Error('useCompsScreenViewMode must be within CompsScreenViewModeProvider')
  return equalityFn
    ? useStore(context, selector, equalityFn)
    : useStore(context, selector)
}
