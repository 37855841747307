import { useQuery } from '@apollo/client'
import { gql } from '__generated__'
import dayjs from 'dayjs'
import { CreditCardIcon, TriangleExclamationIcon } from 'presentation/components/Icons'
import { NoticeButton, NoticeEmphasis } from 'presentation/components/Notice'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { openUpdatePaymentModal } from 'presentation/main/globalModals/UpdatePaymentModal/openUpdatePaymentModal'
import { useEffect } from 'react'

export const EXPIRING_CARD_NOTICE__GET_PAYMENT_METHODS = gql(/* GraphQL */ `
  query ExpiringCardNotice_GetPaymentMethods {
    myEnterprise {
      id
      paymentMethods {
        edges {
          node {
            __typename

            ...on BillingCardsPaymentMethod {
              id
              isDefault
              expiration
            }
          }
        }
      }
    }
  }
`)

export const useExpiringCardNotice = () => {
  const { data, loading, error } = useQuery(EXPIRING_CARD_NOTICE__GET_PAYMENT_METHODS)

  const primaryPaymentMethod = data?.myEnterprise?.paymentMethods?.edges
    .map(e => {
      if (e?.node?.__typename !== 'BillingCardsPaymentMethod') return null
      if (!e.node.isDefault) return null
      return e.node
    })[0]

  const expirationRaw = primaryPaymentMethod?.expiration
  const expiration = dayjs(expirationRaw, 'M/YYYY')

  const nextMonth = dayjs().add(1, 'month')
  const isExpiringNextMonth = expiration.isSame(nextMonth, 'month')

  const thisMonth = dayjs()
  const isExpiringThisMonth = expiration.isSame(thisMonth, 'month')

  const isExpired = expiration.isBefore(thisMonth, 'month')

  const expirationMsg = isExpiringNextMonth
    ? EXP_NEXT_MO_MSG
    : isExpiringThisMonth
      ? EXP_THIS_MO_MSG
      : isExpired
        ? EXPIRED_MSG
        : null

  const updateNotice = useNoticeStore(store => store.updateNotice)

  useEffect(() => {
    if (loading || error) return

    if (expirationMsg) {
      updateNotice(NOTICE_KEYS.EXPIRING_CARD, (
        <ResponsiveNotice
          colorScheme='warning'
          icon={TriangleExclamationIcon}
          textContent={expirationMsg}
          actionsContent={(
            <>
              <NoticeButton
                variant='solid'
                colorScheme='negative'
                onClick={() => openUpdatePaymentModal()}
                leftIcon={<CreditCardIcon fontSize='2' />}
              >
                Update Card
              </NoticeButton>
            </>
          )}
        />
      ))
    }

    return () => updateNotice(NOTICE_KEYS.EXPIRING_CARD, null)
  }, [expirationMsg])
}

const EXP_NEXT_MO_MSG = (
  <>
    <NoticeEmphasis>
      The credit/debit card on file expires next month
    </NoticeEmphasis>
    .
    {' '}
    If not updated before expiration you will lose access to your account.
  </>
)
const EXP_THIS_MO_MSG = (
  <>
    <NoticeEmphasis>
      The credit/debit card on file expires this month
    </NoticeEmphasis>
    .
    {' '}
    If not updated before expiration you will lose access to your account.
  </>
)
const EXPIRED_MSG = (
  <>
    <NoticeEmphasis>
      The credit/debit card on file has expired
    </NoticeEmphasis>
    .
    {' '}
    If not updated before expiration you will lose access to your account.
  </>
)
