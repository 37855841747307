import { Box, BoxProps, Button, ButtonProps, HStack, Text, VStack, chakra } from '@chakra-ui/react'
import { ChevronsLeftIcon } from 'presentation/components/Icons'
import { PropertiesListPreviewPanel } from 'presentation/screens/ListBuilderScreen/components/PropertiesListPreviewPanel/PropertiesListPreviewPanel'
import { ListPreviewMap } from 'presentation/screens/ListPreviewScreen/components/ListPreviewMap'
import { px } from 'presentation/utils/px'
import { useState } from 'react'

const PEEKING_PANEL_WIDTH = 44
const PEEKING_BUTTON_HEIGHT = 40

export const ListPreviewTablet = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <HStack align='stretch' h='layout-height' spacing={0} overflow='hidden'>
      <ListPreviewMap
        shouldHideStatusCard={isDrawerOpen}
      />
      <HStack
        align='stretch'
        flexShrink={0}
        boxShadow='-4px 0px 10px 0px rgba(0, 0, 0, 0.25)'
        position='relative'
      >
        <VStack
          align='stretch'
          flexShrink={0}
          pos='relative'
          width={isDrawerOpen ? 'auto' : px(PEEKING_PANEL_WIDTH)}
          zIndex='docked-2'
          overflow={isDrawerOpen ? 'visible' : 'hidden'}
        >
          <PropertiesListPreviewPanel />
        </VStack>

        <Box
          position='absolute'
          top='50%'
          left={0}
          zIndex='docked-1'
        >
          <PeekingButton
            isDrawerOpen={isDrawerOpen}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            transform='rotate(-90deg) translate(-50%, -50%)'
            transformOrigin='left'
            zIndex='docked-1'
          />
        </Box>
      </HStack>
    </HStack>
  )
}

const PeekingButton = ({
  isDrawerOpen,
  ...boxProps
}: ButtonProps & {
  isDrawerOpen: boolean
}) => (
  <Button
    boxShadow='-4px 0px 10px 0px rgba(0, 0, 0, 0.25)'
    bg='accent.hover'
    textTransform='uppercase'
    h={px(PEEKING_BUTTON_HEIGHT)}
    pos='relative'
    px={0}
    borderRadius={0}
    borderTopLeftRadius={3}
    {...boxProps}
  >
    <ButtonCurve pos='absolute' left='-36px' transform='scaleX(-100%)' />
    <ChevronsLeftIcon
      fontSize={3}
      transform={isDrawerOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}
      mr={1}
    />

    <Text textStyle='bodyLFat' color='graystrong.400' zIndex={1}>
      {isDrawerOpen ? 'Hide' : 'View Preview List'}
    </Text>
    <ButtonCurve pos='absolute' right='-48px' />
  </Button>
)

const ButtonCurve = (props: BoxProps) => (
  <chakra.svg
    width='56px'
    height='40px'
    viewBox='0 0 56 40'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g
      transform='translate(28, 20) rotate(90) translate(-28, -20)translate(8, -8)'
    >
      <chakra.path
        d='M7.51203007,33.1105017 C5.30424007,35.5207017 -0.34076893,41.4080017 0.0161785704,55.9287017 L40,56 L40,7.83325058e-16 C39.4257001,28.0358017 19.2929001,20.2493017 7.51203007,33.1105017 Z'
        id='Path'
        fill='accent.hover'
      >
      </chakra.path>
    </g>
  </chakra.svg>

)
