import { pipe } from 'effect'
import numeral from 'numeral'

const SQFT_PER_ACRE = 43560
const fromSqft = (sqft: number) => sqft / SQFT_PER_ACRE

const fromSqftOrNull = (sqft: number | null | undefined) =>
  sqft == null ? null : fromSqft(sqft)

const formatWithVariableDecimalsOnce = (acres: number | string) => {
  if (Number(acres) < 1) return numeral(acres).format('0.00')
  if (Number(acres) < 10) return numeral(acres).format('0.0')
  return numeral(Number(acres)).format('0')
}

const formatWithVariableDecimals = (acres: number) => pipe(
  acres,
  formatWithVariableDecimalsOnce,
  // Calling it second time fixes for example 9.99 that
  // would be formatted as 10.0 but should have been 10
  formatWithVariableDecimalsOnce,
)

export const Acres = {
  fromSqft,
  fromSqftOrNull,
  formatWithVariableDecimals,
}
