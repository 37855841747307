import { Button, Center, Flex, Image, Spinner } from '@chakra-ui/react'
import { HashLink } from 'presentation/components/HashLink'
import { RepeatIcon } from 'presentation/components/Icons'
import { ResponsiveFullscreenModalV1 } from 'presentation/components/ResponsiveFullscreenModal'
import { CardBody, CardPrimaryTitle, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo } from 'presentation/components/molecules/Card/CardCombo'
import { useToastError } from 'presentation/hooks/useToastError'
import { BillingSummaryItem, BillingSummarySegment } from 'presentation/main/globalModals/PaymentDetailsModal/BillingSummarySegment'
import { DefaultPaymentMethodSegment } from 'presentation/main/globalModals/PaymentDetailsModal/DefaultPaymentMethodSegment'
import { useDefaultPaymentMethodQuery } from 'presentation/main/globalModals/PaymentDetailsModal/useDefaultPaymentMethodQuery'
import { closeModal } from 'presentation/main/globalModals/globalModals.utils'
import { TermsAndPolicy } from 'presentation/screens/Billing/components/TermsAndPolicy'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'
import JugglerPNG from './img/juggler.png'
import RocketManPNG from './img/rocketman.png'

export type PaymentDetailsModalProps = {
  title?: string
  modalFooter: (params?: { hasPaymentMethod?: boolean }) => React.ReactNode
  modalHeader: React.ReactNode
  termsAndPolicyAdditionalInfo?: React.ReactNode
  billingSummaryItems: BillingSummaryItem[]
  billingSummaryTotal?: number
  isBillingSummaryLoading?: boolean
  isOpen?: boolean
  isCardImageAlt?: boolean
  shouldShowChangePlanButton?: boolean
  onClose: () => void
}

/**
 * This is currently disabled, because we are disabling changing the plan
 * with overdue invoices.
 */
const DISABLE_CHANGE_PLAN_BUTTON = true

export const PaymentDetailsModal: FC<PaymentDetailsModalProps> = ({
  title = 'Payment Details',
  isOpen = true,
  onClose,
  modalHeader,
  billingSummaryItems,
  billingSummaryTotal,
  isBillingSummaryLoading,
  termsAndPolicyAdditionalInfo,
  isCardImageAlt,
  shouldShowChangePlanButton = true,
  modalFooter,
}) => {
  const { data: defaultPaymentMethod, error, loading } = useDefaultPaymentMethodQuery()

  useToastError({
    error,
    title: 'We couldn’t load your transaction.',
  })

  const handlePlanChange = () => {
    closeModal()
  }

  return (
    <ResponsiveFullscreenModalV1
      isOpen={isOpen}
      onClose={onClose}
      variant='default'
      colorScheme='modal.highlight'
      size='modal.default.n'
      isCentered
      title={title}
      footer={(loading || isBillingSummaryLoading) ? null : modalFooter({ hasPaymentMethod: !!defaultPaymentMethod })}
    >
      {(loading || isBillingSummaryLoading)
        ? (<Center py='5' h='full'><Spinner /></Center>)
        : (
          <>
            <Image
              src={isCardImageAlt ? JugglerPNG : RocketManPNG}
              position='absolute'
              w={mbp({ tabSm: '200px', tab: '254px' })}
              h={mbp({ tabSm: '200px', tab: '254px' })}
              top={mbp({ tabSm: '-53px', tab: '-80px' })}
              right={mbp({ tabSm: '-20px', tab: '0' })}
              pointerEvents='none'
              display={mbp({ mobSm: 'none', tabSm: 'block' })}
              zIndex={isCardImageAlt ? 'modal' : 'initial'}
            />

            {modalHeader}

            <CardBody>
              <Flex
                flexDirection={mbp({ mobSm: 'row' })}
                justifyContent='space-between'
                alignItems='center'
              >
                {modalHeader
                  ? (<CardSecondaryTitle>Billing Summary</CardSecondaryTitle>)
                  : (<CardPrimaryTitle>Billing Summary</CardPrimaryTitle>)}
                {!DISABLE_CHANGE_PLAN_BUTTON && shouldShowChangePlanButton && (
                  <Button
                    size='xs'
                    variant='ghost'
                    colorScheme='neutral'
                    leftIcon={<RepeatIcon boxSize='2' />}
                    onClick={handlePlanChange}
                    as={HashLink}
                    to='/pricing#first-choose-plan'
                  >
                    Change Plan
                  </Button>
                ) }
              </Flex>

              <CardCombo
                size='md'
                boxShadow='primary.m'
                mt={mbp({ mobSm: 1, tabSm: 2 })}
              >
                <DefaultPaymentMethodSegment paymentMethod={defaultPaymentMethod || undefined} />
                <BillingSummarySegment
                  items={billingSummaryItems}
                  totalAmount={billingSummaryTotal}
                />
              </CardCombo>

              <TermsAndPolicy mt='3'>
                {termsAndPolicyAdditionalInfo}
              </TermsAndPolicy>
            </CardBody>
          </>
        )}
    </ResponsiveFullscreenModalV1>
  )
}
