import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type OpenPayload = {
  listId: MarketingListId
}

type ListDataDownloadModalState = {
  state: {
    status: 'open'
    payload: OpenPayload
  } | {
    status: 'closed'
  }
  open: (payload: OpenPayload) => void
  close: () => void
}

const useListDataDownloadModalStore = create<ListDataDownloadModalState>()(immer(set => ({
  state: { status: 'closed' },
  open: payload => set(draft => {
    draft.state = { status: 'open', payload }
  }),
  close: () => set(draft => {
    draft.state = { status: 'closed' }
  }),
})))

export const useListDataDownloadModal = () =>
  useListDataDownloadModalStore(store => store.state)

export const useListDataDownloadModalActions = () =>
  useListDataDownloadModalStore(store => ({
    open: store.open,
    close: store.close,
  }))
