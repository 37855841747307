import { Data } from 'effect'
import { AttemptBatchSkiptracePayload, BatchSkiptrace, BatchSkiptraceStats, ConfirmBatchSkiptracePayload, DownloadBatchSkiptraceFormat, GetSelectionToSkiptraceCountPayload, ToSkiptraceCount } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'

// #region BatchSkiptraceStore
export type BatchSkiptraceStore = {
  /** Queries */
  batchSkiptrace: BatchSkiptraceBranch
  stats: StatsBranch
  selectionUnskiptracedCount: SelectionUnskiptracedCountBranch
  selectionToSkiptraceCount: SelectionToSkiptraceCountBranch
  progress: BatchSkiptraceProgressBranch

  /** Actions */
  getByListId: GetBatchSkiptraceByListIdBranch
  getStatsByListId: GetStatsByListIdBranch

  getSelectionUnskiptracedCount: GetSelectionUnskiptracedCountBranch
  getSelectionToSkiptraceCount: GetSelectionToSkiptraceCountBranch
  attemptBatchSkiptrace: AttemptBatchSkiptraceBranch
  confirmBatchSkiptrace: ConfirmBatchSkiptraceBranch
  download: DownloadBatchSkiptraceBranch
  watchProgress: WatchBatchSkiptraceProgressBranch
}
// #endregion

// #region BatchSkiptraceBranch
export type BatchSkiptraceBranch = {
  state: BatchSkiptraceState
}

export type BatchSkiptraceState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { batchSkiptrace: BatchSkiptrace }
  Failed: {}
}>

export const BatchSkiptraceState = Data.taggedEnum<BatchSkiptraceState>()
// #endregion

// #region BatchStatsBranch

export type StatsBranch = {
  state: StatsState
}

export type StatsState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { stats: BatchSkiptraceStats }
  Failed: { }
}>

export const StatsState = Data.taggedEnum<StatsState>()

// #endregion

// #region SelectionToSkiptraceCountBranch

export type SelectionToSkiptraceCountBranch = {
  state: SelectionToSkiptraceCountState
}

export type SelectionToSkiptraceCountState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: ToSkiptraceCount
  Failed: {}
}>

export const SelectionToSkiptraceCountState = Data.taggedEnum<SelectionToSkiptraceCountState>()

// #endregion

// #region SelectionUnskiptracedCountBranch

export type SelectionUnskiptracedCountBranch = {
  state: SelectionToSkiptraceCountState
}

export type SelectionUnskiptracedCountState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: ToSkiptraceCount
  Failed: {}
}>

export const SelectionUnskiptracedCountState = Data.taggedEnum<SelectionUnskiptracedCountState>()

// #endregion

// #region BatchSkiptraceProgressBranch

export type BatchSkiptraceProgressBranch = {
  state: BatchSkiptraceProgressState
}

export type BatchSkiptraceProgressState = Data.TaggedEnum<{
  Initial: {}
  Checking: {}
  InProgress: {
    percentage: number
  }
  Failed: {}
}>

export const BatchSkiptraceProgressState = Data.taggedEnum<BatchSkiptraceProgressState>()

// #endregion

// #region FindBatchResultByListIdBranch

export type GetBatchSkiptraceByListIdBranch = {
  state: GetBatchSkiptraceByListIdState
  execute: GetBatchSkiptraceByListExecute
}

export type GetBatchSkiptraceByListIdState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const GetBatchSkiptraceByListIdState = Data.taggedEnum<GetBatchSkiptraceByListIdState>()

type GetBatchSkiptraceByListExecute = (listId: MarketingListId, page: number) => void

// #endregion

// #region GetStatsByListIdBranch

export type GetStatsByListIdBranch = {
  state: GetStatsByListIdState
  execute: GetStatsByListIdExecute
}

export type GetStatsByListIdState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const GetStatsByListIdState = Data.taggedEnum<GetStatsByListIdState>()

type GetStatsByListIdExecute = (listId: MarketingListId) => void

// #endregion

// #region GetSelectionToSkiptraceCountBranch

export type GetSelectionToSkiptraceCountBranch = {
  state: GetSelectionToSkiptraceCountState
  execute: GetSelectionToSkiptraceCountExecute
  reset: () => void
}

export type GetSelectionToSkiptraceCountState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const GetSelectionToSkiptraceCountState = Data.taggedEnum<GetSelectionToSkiptraceCountState>()

type GetSelectionToSkiptraceCountExecute = (payload: GetSelectionToSkiptraceCountPayload) => void

// #endregion

// #region GetSelectionUnskiptracedCountBranch

export type GetSelectionUnskiptracedCountBranch = {
  state: GetSelectionUnskiptracedCountState
  execute: GetSelectionUnskiptracedCountExecute
  reset: () => void
}

export type GetSelectionUnskiptracedCountState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const GetSelectionUnskiptracedCountState = Data.taggedEnum<GetSelectionUnskiptracedCountState>()

type GetSelectionUnskiptracedCountExecute = (payload: Omit<GetSelectionToSkiptraceCountPayload, 'reskipConfig'>) => void

// #endregion

// #region DoBatchSkiptraceBranch
export type AttemptBatchSkiptraceBranch = {
  state: AttemptBatchSkiptraceState
  execute: AttemptBatchSkiptraceExecute
}

export type AttemptBatchSkiptraceState = Data.TaggedEnum<{
  Initial: {}
  Attempting: {}
  Attempted: { attempt: ConsumableAttempt.ConsumableAttempt }
  Failed: { error: Error }
}>

export const AttemptBatchSkiptraceState = Data.taggedEnum<AttemptBatchSkiptraceState>()

export type AttemptBatchSkiptraceExecute = (payload: AttemptBatchSkiptracePayload) => void
// #endregion

// #region ConfirmBatchSkiptraceBranch
export type ConfirmBatchSkiptraceBranch = {
  state: ConfirmBatchSkiptraceState
  execute: ConfirmBatchSkiptraceExecute
}

export type ConfirmBatchSkiptraceState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const ConfirmBatchSkiptraceState = Data.taggedEnum<ConfirmBatchSkiptraceState>()

export type ConfirmBatchSkiptraceExecute = (payload: ConfirmBatchSkiptracePayload) => void
// #endregion

// #region DownloadBatchSkiptraceBranch

export type DownloadBatchSkiptraceBranch = {
  state: DownloadBatchSkiptraceState
  execute: DownloadBatchSkiptraceExecute
}

export type DownloadBatchSkiptraceState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: Error }
}>

export const DownloadBatchSkiptraceState = Data.taggedEnum<DownloadBatchSkiptraceState>()

export type DownloadBatchSkiptraceExecute = (
  list: MarketingList,
  format: DownloadBatchSkiptraceFormat
) => void

// #endregion

// #region WatchBatchSkiptraceProgressBranch

export type WatchBatchSkiptraceProgressBranch = {
  state: WatchBatchSkiptraceProgressState
  execute: WatchBatchSkiptraceProgressExecute
}

export type WatchBatchSkiptraceProgressState = Data.TaggedEnum<{
  Initial: {}
  Checking: {}
  InProgress: {}
  Failed: { error: Error }
}>

export const WatchBatchSkiptraceProgressState = Data.taggedEnum<WatchBatchSkiptraceProgressState>()

export type WatchBatchSkiptraceProgressExecute = (listId: MarketingListId) => void
