import { Center } from '@chakra-ui/react'
import { castToFiniteNumber } from 'libs/string/castToFiniteNumber'
import { isNumber, random } from 'lodash/fp'
import { FC, PropsWithChildren, useMemo } from 'react'
import { useSearchParam, useTimeout } from 'react-use'

export const DevHelpers: FC<PropsWithChildren> = ({ children }) => {
  const forceRandomDelayMaxSecsRaw = useSearchParam('forceRandomDelayMaxSecs')
  const max = forceRandomDelayMaxSecsRaw && castToFiniteNumber(forceRandomDelayMaxSecsRaw)
  const duration = useMemo(() => max ? random(0, max) : 0, [])
  const [checkIsTimeoutDone] = useTimeout(duration * 1000)
  const shouldStagger = isNumber(max)

  // Stager is useful with responsive viewer extension,
  // to prevent hitting throttle limit on dev
  if (shouldStagger && !checkIsTimeoutDone()) {
    return (
      <Center h='app-height'>
        WAITING FOR ARTIFICIAL DELAY (forceRandomDelayMaxSecs) for
        {' '}
        {duration}
        {' '}
        seconds
      </Center>
    )
  }

  return <>{children}</>
}

// Useful when you want to test light/dark mode at the same time
// and prevent HMR from correcting the real theme from settings
export const getForcedTheme = () => {
  const forcedTheme = useSearchParam('forceTheme')

  if (forcedTheme === 'light') return 'light'
  if (forcedTheme === 'dark') return 'dark'

  return null
}
