import { ComponentWithAs, IconProps } from '@chakra-ui/react'
import { useSearchHistoryStore } from 'features/SearchHistory/infra/react/SearchHistory.state'
import { SearchHistoryMeta } from 'presentation/screens/SearchHistoryScreen/components/SearchHistoryPanel/common/SearchHistoryMeta'
import { shallow } from 'zustand/shallow'

export type SearchHistoryMetaProps = {
  icon: ComponentWithAs<'svg', IconProps>
  bgColor: string
  color: string
  metaInfo: string
  isDisabled?: boolean
}

export const useSearchHistoryMetaProps = (): SearchHistoryMetaProps => {
  const {
    history,
    textFilter,
    seekersFilter,
    currentPage,
    pageLimit,
  } = useSearchHistoryStore(api => ({
    history: api.remote.history,
    textFilter: api.local.textFilter,
    seekersFilter: api.local.seekersFilter,
    currentPage: api.local.currentPage,
    pageLimit: api.local.pageLimit,
  }), shallow)

  const totalResult = history.status === 'success'
    ? history.data.total
    : history.status === 'updating' && history.previousData
      ? history.previousData.total
      : 0

  const hasSeekerFilter = seekersFilter.length > 0

  const isDisabled = history.status === 'updating'

  const metaInfo = SearchHistoryMeta.formatText({
    totalResult,
    hasSeekerFilter,
    textFilter,
    currentPage,
    pageLimit,
  })

  const icon = SearchHistoryMeta.getIcon({ textFilter, hasSeekerFilter })

  const color = isDisabled
    ? 'ondark.6'
    : SearchHistoryMeta.getColor({ textFilter, hasSeekerFilter })

  const bgColor = isDisabled
    ? 'grayweak.600'
    : SearchHistoryMeta.getBgColor({ textFilter, hasSeekerFilter })

  return {
    metaInfo,
    icon,
    color,
    bgColor,
    isDisabled,
  }
}
