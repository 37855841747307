import { Button, Flex, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { ResponsiveNotice } from 'presentation/components/ResponsiveNotice/ResponsiveNotice'
import { usePropertyMarkerTrainingModal } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingModal/PropertyMarkerTrainingModal.api'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { MouseEventHandler } from 'react'

export type PropertyMarkerTrainingNoticeProps = {
  onClose: () => void
}

export const PropertyMarkerTrainingNotice = (props: PropertyMarkerTrainingNoticeProps) => {
  const api = usePropertyMarkerTrainingModal()
  const handleOpenModal: MouseEventHandler = () => {
    api.actions.open()
    props.onClose()
  }
  return (
    <ResponsiveNotice
      colorScheme='info'
      textContent={(
        <>
          <Flex gap={1} alignItems='center'>
            <Breakpoint
              tabSm={(
                <PropertyMarker
                  markerType='pin'
                  classification='OWNER_OCCUPIED'
                  equityType='LOW'
                  isVacant={false}
                  isForeclosure={true}
                  isSenior={false}
                  size='xs'
                />
              )}
            />
            <Text as='span' color='inherit'>
              <Breakpoint
                mobSm=' We use pins and tags to categorize properties. Click &lsquo;Learn More&rsquo; for details.'
                mob='We use pins and tags to categorize properties, making it easy to grasp information at a glance. Click &lsquo;Learn More&rsquo; for details.'
              />
            </Text>
          </Flex>
        </>
      )}
      actionsContent={(
        <Button
          variant='solid'
          colorScheme='highlight'
          size={mbp({ mobSm: 'sm', mob: 'xs' })}
          onClick={handleOpenModal}
        >
          Learn More
        </Button>
      )}
    />
  )
}
