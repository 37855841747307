import { AccessProvider } from 'features/Access/infra/react/AccessState'
import { changePassword } from 'features/Access/infra/remote/changePassword'
import { getAccess } from 'features/Access/infra/remote/getAccess'
import { login } from 'features/Access/infra/remote/login'
import { logout } from 'features/Access/infra/remote/logout'
import { register } from 'features/Access/infra/remote/register'
import { resendConfirmationEmail } from 'features/Access/infra/remote/resendConfirmationEmail'
import { sendResetPasswordEmail } from 'features/Access/infra/remote/sendResetPasswordEmail'
import { ErrorLib } from 'libs/errors'
import { ReactNode } from 'react'

const errorReportedLogin = ErrorLib.wrapReportOnReject(login)
const errorReportedLogout = ErrorLib.wrapReportOnReject(logout)
const errorReportedGetAccess = ErrorLib.wrapReportOnReject(getAccess)
const errorReportedRegister = ErrorLib.wrapReportOnReject(register)
const errorReportedResendConfirmationEmail = ErrorLib.wrapReportOnReject(resendConfirmationEmail)
const errorReportedSendResetPasswordEmail = ErrorLib.wrapReportOnReject(sendResetPasswordEmail)
const errorReportedChangePassword = ErrorLib.wrapReportOnReject(changePassword)

export const BoundAccessProvider = ({ children }: {
  children?: ReactNode
}) => (
  <AccessProvider
    login={errorReportedLogin}
    logout={errorReportedLogout}
    getAccess={errorReportedGetAccess}
    register={errorReportedRegister}
    resendConfirmationEmail={errorReportedResendConfirmationEmail}
    sendResetPasswordEmail={errorReportedSendResetPasswordEmail}
    changePassword={errorReportedChangePassword}
  >
    {children}
  </AccessProvider>
)
