import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { NumberLib } from 'libs/Number'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { getMarkerInfoFromProperty } from 'presentation/screens/PropertyDetailsScreen/PropertyDetailsScreen.helpers'
import { shallow } from 'zustand/shallow'

export const EstimateSubsection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()

  const rangeData = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return null

    const estimate = property.status === 'with-details'
      ? property.valuation?.estimateRange
      : null

    return property.status === 'with-details'
      ? {
        avgEstimatePrice: NumberLib.orNull(estimate?.price?.avg),
        avgEstimatePricePerSqft: NumberLib.orNull(estimate?.pricePerSqft?.avg),
        maxEstimatePrice: NumberLib.orNull(estimate?.price?.max),
        maxEstimatePricePerSqft: NumberLib.orNull(estimate?.pricePerSqft?.max),
        minEstimatePrice: NumberLib.orNull(estimate?.price?.min),
        minEstimatePricePerSqft: NumberLib.orNull(estimate?.pricePerSqft?.min),
      }
      : {
        avgEstimatePrice: null,
        avgEstimatePricePerSqft: null,
        maxEstimatePrice: null,
        maxEstimatePricePerSqft: null,
        minEstimatePrice: null,
        minEstimatePricePerSqft: null,
      }
  }, shallow)

  const markerData = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return null

    return getMarkerInfoFromProperty(property)
  }, shallow)

  if (!rangeData || !markerData) return null

  const hasNoData = [
    rangeData.minEstimatePrice,
    rangeData.avgEstimatePrice,
    rangeData.maxEstimatePrice,
    rangeData.minEstimatePricePerSqft,
    rangeData.avgEstimatePricePerSqft,
    rangeData.maxEstimatePricePerSqft,
  ].every(x => x === null)

  return (
    <VStack spacing={0} align='stretch'>
      <HStack justify='space-between' pt='2' align='center'>
        <Text textStyle='bodySHeavy' color='grayweak.900'>
          Low
        </Text>
        <Box>
          <PropertyMarker
            markerType='pin'
            size='xs'
            {...markerData}
            classification={markerData?.classification ?? null}
            equityType={markerData?.equityType ?? null}
            isVacant={markerData?.isVacant ?? false}
            isSenior={markerData?.isSenior ?? false}
            isForeclosure={markerData?.isForeclosure ?? false}
          />
        </Box>
        <Text textStyle='bodySHeavy' color='grayweak.900'>
          High
        </Text>
      </HStack>

      <Box h='1' />
      <Box
        borderRadius='1'
        h='1'
        bg={hasNoData ? 'grayweak.900' : 'specialsat.500'}
      />

      <HStack justify='space-between' pt='1' align='center'>
        <Text textStyle='bodySFat' color='graystrong.400'>
          {formatDollar(rangeData.minEstimatePrice)}
        </Text>
        <Text textStyle='bodySHeavy' color='graystrong.400'>
          {formatDollar(rangeData.avgEstimatePrice)}
        </Text>
        <Text textStyle='bodySFat' color='graystrong.400'>
          {formatDollar(rangeData.maxEstimatePrice)}
        </Text>
      </HStack>
      <HStack justify='space-between' align='center'>
        <Text textStyle='bodySFat' color='graystrong.200'>
          {formatDollarPerSqft(rangeData.minEstimatePricePerSqft)}
        </Text>
        <Text textStyle='bodySFat' color='graystrong.200'>
          {formatDollarPerSqft(rangeData.avgEstimatePricePerSqft)}
        </Text>
        <Text textStyle='bodySFat' color='graystrong.200'>
          {formatDollarPerSqft(rangeData.maxEstimatePricePerSqft)}
        </Text>
      </HStack>
    </VStack>
  )
}

const formatDollar = (x: number | null) =>
  x === null ? '--' : `$${NumberLib.formatComma(x)}`
const formatDollarPerSqft = (x: number | null) =>
  x === null ? '--' : `$${NumberLib.roundNPlaces(0)(x)}/sqft`
