import { Box, Center, Flex, FlexProps, HStack, Spinner, Text } from '@chakra-ui/react'
import { useSelector } from '@xstate/react'
import cn from 'classnames'
import { Array } from 'effect'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ScrollAnimation } from 'presentation/components/animation/ScrollAnimation'
import PlanCompact from 'presentation/screens/Plans/PlanComparisonV2/PlanCompact/PlanCompact'
import PlanIntervalSwitcher from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonDetailed/components/PlanIntervalSwitcher'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { PlanComparisonCarousel } from 'presentation/screens/Plans/PlanComparisonV2/components/PlanComparisonCarousel'
import { HeadingText, SubheadingText } from 'presentation/screens/Plans/PlanComparisonV2/components/common'
import { fadeIn, fadeInDown, fast, faster, zoomIn } from 'presentation/utils/animationClasses'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { PropsWithChildren } from 'react'
import ChoosePlanSectionViewModel from './ChoosePlanSectionViewModel'

const ChoosePlanSection = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  const isLoading = useSelector(actor, snap => snap.hasTag('products-unready'))

  const vm = ChoosePlanSectionViewModel.usePresenter()
  const plans = Array.map(vm.planCards, (planCompactViewModel, i) => (
    <PlanCompact
      key={i}
      viewModel={planCompactViewModel}
    />
  ))

  return isLoading
    ? (
      <SectionContainer minH='1380px' h='1px'>
        <Center py='4' h='full'>
          <Spinner color='grayweak.100' />
        </Center>
      </SectionContainer>
    )
    : (
      <SectionContainer>
        <ScrollAnimation
          className={cn(fadeInDown, fast)}
        >
          <HeadingText color='ondark.1'>
            <Breakpoint
              mobSm='Choose the plan for your team'
              mob='Choose the plan that’s right for your team'
            />
          </HeadingText>
          <ScrollAnimation
            className={cn(fadeIn, faster)}
          >
            <SubheadingText
              {...mbpg({
                mobSm: {
                  w: '240px',
                  mt: 0,
                },
                mob: {
                  w: 'full',
                  mt: '1',
                },
              })}
              color='ondark.1'
              mx='auto'
            >
              Pay by the month or the year, and cancel at any time
            </SubheadingText>
          </ScrollAnimation>
        </ScrollAnimation>
        <Flex
          gap='3'
          flexDirection='column'
        >
          <PackageSavings />

          <ScrollAnimation
            className={cn(fadeIn, faster)}
            staggerFactor='longer'
          >
            <PlanIntervalSwitcher
              monthlyTextColor='ondark.1'
            />
          </ScrollAnimation>
        </Flex>
        <Breakpoint
          mobSm={(
            <PlanComparisonCarousel>
              {plans}
            </PlanComparisonCarousel>
          )}
          dtLg={(
            <StaticStack>
              {plans}
            </StaticStack>
          )}
        />
      </SectionContainer>
    )
}

export default ChoosePlanSection

const StaticStack = ({ children }: PropsWithChildren) => (
  <HStack
    spacing='2'
    justifyContent='center'
    overflow='visible'
  >
    {children}
  </HStack>
)

const SectionContainer = ({ children, ...props }: FlexProps) => (
  <Flex
    id='first-choose-plan'
    {...mbpg({
      mobSm: {
        pt: '3',
        pb: '4',
        gap: '3',
      },
      mob: {
        pt: '4',
        pb: '4',
        gap: '4',
      },
      dtSm: {
        pt: '6',
        pb: '6',
        gap: '7',
      },
      dtLg: {
        pt: '80px',
        pb: '80px',
        gap: '80px',
      },
    })}
    bg='bg-landingpage-special'
    flexDirection='column'
    overflow='hidden'
    {...props}
  >
    {children}
  </Flex>
)

const PackageSavings = () => {
  const vm = ChoosePlanSectionViewModel.usePresenter()
  return (
    <Box
      color='highlight.500'
      textAlign='center'
    >
      <ScrollAnimation
        className={cn(zoomIn, fast)}
        staggerFactor='longer'
      >
        <Text
          color='inherit'
          textStyle={{
            base: 'kalam-18-700',
            mob: 'kalam-28-700',
            dtSm: 'kalam-36-700',
            dtLg: 'kalam-44-700',
          }}
        >
          Save up to
          {' '}
          {vm.saveUpToText}
          !
        </Text>
      </ScrollAnimation>
      <ScrollAnimation
        className={cn(fadeIn, faster)}
      >
        <Text
          color='inherit'
          mt='-4px'
          textStyle={{
            base: 'body2-b',
            mob: 'lato-16-700',
            dtSm: 'h3-b',
            dtLg: 'h2-b',
          }}
        >
          when you pay yearly
        </Text>
      </ScrollAnimation>
    </Box>
  )
}
