import { createIcon } from '@chakra-ui/react'

export const DarkModeIcon = createIcon({
  displayName: 'DarkModeIcon',
  viewBox: '0 0 48 25',
  path: (
    <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_i_1926_161576)'>
        <rect y='0.5' width='48' height='24' rx='12' fill='#0770BA' />
      </g>
      <rect x='0.5' y='1' width='47' height='23' rx='11.5' stroke='#0A68AB' />
      <path fillRule='evenodd' clipRule='evenodd' d='M29.9999 17.3005C33.9764 17.3005 37.1999 14.077 37.1999 10.1005C37.1999 7.24655 35.5394 4.78041 33.1318 3.61554C32.7971 3.45362 32.8483 2.90137 33.2201 2.90137C38.6434 2.90137 43.0398 7.29782 43.0398 12.7211C43.0398 18.1444 38.6434 22.5409 33.2201 22.5409C29.6707 22.5409 26.5612 20.6578 24.836 17.8361C24.2635 16.8996 25.8906 16.1147 26.8795 16.591C27.8235 17.0457 28.882 17.3005 29.9999 17.3005Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M14.1313 3.44288C14.2416 3.22086 14.5583 3.22086 14.6686 3.44288L15.3925 4.89976C15.4362 4.98768 15.5201 5.04866 15.6172 5.06304L17.2265 5.30126C17.4717 5.33757 17.5696 5.63878 17.3925 5.8123L16.2306 6.95092C16.1605 7.01964 16.1285 7.11831 16.1448 7.21512L16.4155 8.81923C16.4568 9.06368 16.2006 9.24984 15.9808 9.13506L14.5389 8.38189C14.4519 8.33644 14.3481 8.33644 14.2611 8.38189L12.8191 9.13506C12.5994 9.24984 12.3432 9.06368 12.3844 8.81923L12.6552 7.21512C12.6715 7.11831 12.6394 7.01964 12.5693 6.95092L11.4074 5.8123C11.2304 5.63878 11.3282 5.33757 11.5735 5.30126L13.1827 5.06304C13.2798 5.04866 13.3638 4.98768 13.4075 4.89976L14.1313 3.44288ZM8.39981 14.3003L8.73273 15.0421L9.54108 15.1295L8.93849 15.6753L9.10516 16.4711L8.39981 16.0667L7.69447 16.4711L7.86113 15.6753L7.25854 15.1295L8.06689 15.0421L8.39981 14.3003ZM16.7545 17.7067C16.6369 17.5189 16.3634 17.5189 16.2458 17.7067L15.8583 18.326C15.8171 18.3918 15.752 18.439 15.6767 18.4579L14.968 18.6351C14.7531 18.6888 14.6685 18.949 14.8108 19.1188L15.28 19.6788C15.3299 19.7383 15.3547 19.8148 15.3494 19.8922L15.2989 20.621C15.2836 20.842 15.5049 21.0028 15.7104 20.92L16.388 20.6467C16.4599 20.6177 16.5404 20.6177 16.6123 20.6467L17.2899 20.92C17.4954 21.0028 17.7167 20.842 17.7014 20.621L17.6509 19.8922C17.6456 19.8148 17.6704 19.7383 17.7203 19.6788L18.1895 19.1188C18.3318 18.949 18.2472 18.6888 18.0323 18.6351L17.3236 18.4579C17.2483 18.439 17.1832 18.3918 17.142 18.326L16.7545 17.7067ZM23.7002 10.701L23.9499 11.2573L24.5561 11.3229L24.1042 11.7322L24.2292 12.3291L23.7002 12.0258L23.1712 12.3291L23.2962 11.7322L22.8442 11.3229L23.4505 11.2573L23.7002 10.701ZM27.9331 4.24241L27.6001 3.50064L27.2672 4.24241L26.4589 4.32982L27.0615 4.87567L26.8948 5.67146L27.6001 5.26704L28.3055 5.67146L28.1388 4.87567L28.7414 4.32982L27.9331 4.24241Z' fill='white' />
      <defs>
        <filter id='filter0_i_1926_161576' x='0' y='0.5' width='51' height='27' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx='3' dy='3' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_1926_161576' />
        </filter>
      </defs>
    </svg>

  ),
})
