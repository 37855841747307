import { createIcon } from '@chakra-ui/react'

export const CheckCircleGreenIcon = createIcon({
  displayName: 'CheckCircleGreenIcon',
  viewBox: '0 0 25 24',
  path: (
    <>
      <ellipse cx='12.3564' cy='12' rx='12.3564' ry='12' fill='#0FA115' />
      <path d='M6.17871 13L8.81354 17L19.05 7' stroke='white' fill='none' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    </>
  ),
})
