import { createIcon } from '@chakra-ui/react'

const SolidPaperPlaneIcon = createIcon({
  displayName: 'SolidPaperPlaneIcon',
  viewBox: '0 0 56 57',
  path: (
    <path
      d='M50.0107 3.93075L4.38 30.2477C2.59809 31.2712 2.82452 33.7513 4.59659 34.4993L15.0616 38.8887L43.3457 13.9694C43.8872 13.4871 44.6551 14.2252 44.1924 14.7862L20.4763 43.6718V51.5945C20.4763 53.9171 23.282 54.8324 24.6603 53.1495L30.9118 45.5418L43.1784 50.6792C44.5763 51.2697 46.1712 50.3938 46.4272 48.888L53.5154 6.37151C53.8502 4.38348 51.7138 2.94658 50.0107 3.93075Z'
      fill='currentColor'
    />
  ),
})

export default SolidPaperPlaneIcon
