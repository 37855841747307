import { ModalOverlay } from '@chakra-ui/react'
import { Modal, ModalContent } from 'presentation/components/Modal'
import { ModalCloseButton } from 'presentation/components/Modal/ModalCloseButton'
import { useState } from 'react'
import PlanDowngradeAlertCard from './PlanDowngradeAlertCard'

export type PlanDowngradeAlertProps = {
  isOpen: boolean
  skipAmount: string
  billingInterval: string
  planName: string
  onClose: () => void
  onProceed: () => void
}

const PlanDowngradeAlert = ({
  isOpen,
  skipAmount,
  billingInterval,
  planName,
  onClose,
  onProceed,
}: PlanDowngradeAlertProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleOnClose = () => {
    if (!isLoading)
      onClose()
  }

  const handleOnProceed = () => {
    setIsLoading(true)
    onProceed()
    // Note: The modal should be closed by the parent component after the operation completes
  }

  const handleOnCancel = () => {
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClose={handleOnClose} />
        <PlanDowngradeAlertCard
          isLoading={isLoading}
          skipAmount={skipAmount}
          billingInterval={billingInterval}
          planName={planName}
          onProceed={handleOnProceed}
          onCancel={handleOnCancel}
        />
      </ModalContent>
    </Modal>
  )
}

export default PlanDowngradeAlert
