import { Box, useToken } from '@chakra-ui/react'
import dompurify from 'dompurify'
import { CMA } from 'features/CMA/CMA.domain'
import ky from 'ky'
import { useEffect, useMemo, useRef } from 'react'
import counties from './counties.json'

type Props = {
  data: CMA.CoverageInfo
}

export const CompsCoverageSvgBox = ({
  data,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  const incomingColor = useToken('colors', 'highlight.500')
  const coveredColor = useToken('colors', 'neutral.500')
  const outlineColor = useToken('colors', 'ondark.1')

  const sx = useMemo(() => {
    const coveredFipsMap = data.coveredFipsMap
    const sx: Record<string, Record<string, string>> = {
      '& #separatorAK,HI': {
        strokeWidth: '0 !important',
      },
      '& #borders': {
        stroke: `${outlineColor} !important`,
      },
      '& > svg': {
        w: 'full',
        h: 'full',
      },
    }

    counties.forEach(({ STATE, COUNTY }) => {
      const fips = `${STATE}${COUNTY}`

      if (coveredFipsMap[fips]) {
        sx[`& #c${fips}`] = {
          fill: incomingColor,
          stroke: `${outlineColor} !important`,
        }
      } else {
        sx[`& #c${fips}`] = {
          fill: coveredColor,
          stroke: `${outlineColor} !important`,
        }
      }
    })

    return sx
  }, [])

  useEffect(() => {
    let didUnmount = false
    void ky.get('/assets/counties.svg', {
      headers: {
        // accept text
        Accept: 'text/plain',
      },
    })
      .text()
      .then(svgText => {
        if (didUnmount) return

        const sanitized = dompurify.sanitize(svgText)

        if (ref.current)
          ref.current.innerHTML = sanitized
      })

    return () => {
      didUnmount = true
    }
  }, [])

  return (
    <Box w='full' h='full' sx={sx} ref={ref} />
  )
}
