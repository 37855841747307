import { useSelector } from '@xstate/react'
import { Equal } from 'effect'
import PlanComparisonMachine from 'presentation/screens/Plans/PlanComparisonV2/PlanComparisonMachine'
import { formatPenniesOptionalDecUsdOr } from 'utils/dataAdapter'

type SectionBannerViewModel = {
  priceText: string
}

const presenter = (snapshot: PlanComparisonMachine.Snapshot) => {
  const saveUpToPrice = snapshot.context.yearlySavings
  const vm: SectionBannerViewModel = {
    priceText: formatPenniesOptionalDecUsdOr('UNKNOWN')(saveUpToPrice),
  }
  return vm
}

const usePresenter = () => {
  const actor = PlanComparisonMachine.useActorRefFromContext()
  return useSelector(
    actor,
    presenter,
    Equal.equals,
  )
}

const SectionBannerViewModel = {
  usePresenter,
}

export default SectionBannerViewModel
