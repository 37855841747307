import { useSelector } from '@xstate/react'
import { Option } from 'effect'
import NearbyBuyerPropertyMachine from 'features/NearbyBuyers/machine/NearbyBuyersMachine/NearbyBuyerPropertyMachine'
import { useCallback } from 'react'

namespace NearbyBuyerPropertyHooks {
  export const useSetup = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useCallback((element: HTMLElement) => {
      actorRef.send({ type: 'setup', getElement: () => element })
    }, [actorRef])

  export const useIsHighlighted = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.matches({
      Initialized: 'Highlighted',
    }))

  export const useProperty = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.property)

  export const useDeals = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.nearbyDeals)

  export const useLatestDealPrice = (actorRef: NearbyBuyerPropertyMachine.ActorRef) => {
    const deals = useDeals(actorRef)
    return deals[0]?.soldAmount
      ?? deals[0]?.rentAmount
      ?? Option.none()
  }

  export const useIsSubject = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useSelector(actorRef, snap => snap.context.isSubject)

  export const useOnClick = (actorRef: NearbyBuyerPropertyMachine.ActorRef) =>
    useCallback(() => {
      actorRef.send({ type: 'click' })
    }, [actorRef])
}

export default NearbyBuyerPropertyHooks
