import { Avatar, Box, Button, Center, Drawer, DrawerContent, DrawerOverlay, Flex, IconButton, IconButtonProps, Image, Spacer, Text, useDisclosure, useTheme } from '@chakra-ui/react'
import { useThemeSettings } from 'features/Settings/infra/react/Settings.state.reactUtils'
import { ReactComponent as DarkIcon } from 'presentation/assets/icons/dark-mode.svg'
import { ReactComponent as LightIcon } from 'presentation/assets/icons/light-mode.svg'
import logo from 'presentation/assets/img/red-logo-white-text.svg'
import { ArrowLeftIcon, CarIcon, HelpCircleIcon, ListBuilderIcon, ListIcon, MenuIcon, MoonIcon, PowerIcon, SearchIcon, SunIcon } from 'presentation/components/Icons'
import { UserSidebarNav } from 'presentation/components/UserSidebarNav'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { LayoutViewModel } from 'presentation/layouts/Layout/Layout.viewModel'
import { PropertyDrawerContent } from 'presentation/layouts/Layout/components/HeaderMobile/HeaderMobileDrawer/PropertyDrawerContent'
import { SupportWidget } from 'presentation/libs/SupportWidget'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { HeaderMobileDrawerItem } from './HeaderMobileDrawerItem'

type MenuType = 'top-level' | 'profile-settings' | 'property-details'

export const HeaderMobileDrawer = memo((props: Partial<IconButtonProps>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement | null>(null)

  const isPropertyRoute = useMatch('/search/:leadId/*')
  const isUserRoute = useMatch('/user/*')
  const isTabSmAndBelow = useSwitchBreakpoint({
    dtSm: false,
    mobSm: true,
  }) || true
  const initialMenu = !isTabSmAndBelow
    ? 'top-level'
    : isPropertyRoute
      ? 'property-details'
      : isUserRoute
        ? 'profile-settings'
        : 'top-level'

  const [shownMenu, setShownMenu] = useState<MenuType>(initialMenu)

  const viewModel = LayoutViewModel.useReadySelector(state => ({
    isSubscribed: !!state.subscription,
    logout: state.accessor.logout,
    name: state.accessor.name,
    avatarUrl: state.accessor.avatarUrl,
  }))
  const themeApi = useThemeSettings()

  const theme = useTheme()

  // Reset shown menu route/viewport change
  useEffect(() => {
    setShownMenu(initialMenu)
  }, [isPropertyRoute, isUserRoute, isTabSmAndBelow, isOpen])

  // Update menu for
  useEffect(() => {

  }, [isUserRoute, isTabSmAndBelow])

  const handleSupportClicked = () => {
    void SupportWidget.openWidget({ topic: 'support' })
    onClose()
  }

  if (!viewModel) return null

  return (
    <>
      <IconButton
        ref={btnRef}
        onClick={onOpen}
        aria-label='Open Navigation Menu'
        variant='icon-ghost'
        colorScheme='ondark'
        size='md'
        {...props}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        placement='left'
      >
        <DrawerOverlay css={{ backdropFilter: 'blur(4px)' }} bg='fg-400' />

        {(shownMenu === 'property-details') && (
          <PropertyDrawerContent
            onBackToMainMenu={() => { setShownMenu('top-level') }}
            onSelect={onClose}
          />
        )}

        {(shownMenu === 'profile-settings') && (
          <ProfileSettingsDrawerContent
            onBack={() => { setShownMenu('top-level') }}
            onSelect={onClose}
          />
        )}

        {shownMenu === 'top-level' && (
          <DrawerContent maxW='248px' bg='bg-sbmenu'>
            <Flex as='aside' h='app-height' direction='column' py='4'>
              {/* TOP SECTION */}
              <Flex align='center' gap='1' px='1.5'>
                <Center flex='1'>
                  <Image
                    src={logo}
                    alt='Propelio'
                    w='auto'
                    h='34px'
                  />
                </Center>

                <IconButton
                  aria-label='close menu'
                  icon={<ArrowLeftIcon />}
                  variant='icon-ghost'
                  colorScheme='ondark'
                  size='md'
                  onClick={onClose}
                />
              </Flex>

              <Spacer
                flexBasis={mbp({ mobSm: '3', mob: '6', tabSm: '7' })}
                flexGrow='0'
                flexShrink='1'
              />

              {/* MAIN/MID SECTION */}
              {viewModel.isSubscribed && (
                <Flex flex={1} direction='column' gap={mbp({ mobSm: '1', mob: '3' })} py='3'>
                  <HeaderMobileDrawerItem
                    to='/search'
                    icon={<SearchIcon />}
                    title='Property Data'
                    onClick={onClose}
                  />
                  <HeaderMobileDrawerItem
                    to='/lists/builder'
                    icon={<ListIcon />}
                    title='List Builder'
                    onClick={onClose}
                  />
                  <HeaderMobileDrawerItem
                    to='/lists'
                    icon={<ListBuilderIcon />}
                    title='List Manager'
                    onClick={onClose}
                  />
                  <HeaderMobileDrawerItem
                    to='/driving-for-dollars'
                    icon={<CarIcon />}
                    title='Driving for Dollars'
                    onClick={onClose}
                  />
                </Flex>
              )}

              <Spacer />

              {/* BOTTOM SECTION */}
              <Flex direction='column' gap={mbp({ mobSm: '1', mob: '3' })} align='flex-start'>
                <HeaderMobileDrawerItem
                  onClick={themeApi.toggleTheme}
                  icon={theme.id === 'light' ? <MoonIcon /> : <SunIcon />}
                  title={theme.id === 'light' ? 'Dark Mode' : 'Light Mode'}
                  rightJsx={(
                    <Box pos='absolute' right='-1' top='50%' transform='translateY(-50%)'>
                      {theme.id === 'light' ? <LightIcon /> : <DarkIcon />}
                    </Box>
                  )}
                />

                {viewModel.isSubscribed && (
                  <HeaderMobileDrawerItem
                    onClick={() => setShownMenu('profile-settings')}
                    iconReplacement={(
                      <Box boxSize='3'>
                        <Avatar
                          name={viewModel.name}
                          src={viewModel.avatarUrl}
                          size='sm'
                          ml='-0.5'
                          mt='-0.5'
                        />
                      </Box>
                    )}
                    title='Settings'
                  />
                )}

                <HeaderMobileDrawerItem
                  onClick={viewModel.logout}
                  icon={<PowerIcon />}
                  title='Logout'
                  iconColor='negative.500'
                />
              </Flex>

              <Button
                mx='2'
                mt={mbp({ mobSm: '4', tabSm: '6.5' })}
                variant='solid'
                colorScheme='highlight'
                leftIcon={<HelpCircleIcon fontSize='3' />}
                onClick={handleSupportClicked}
              >
                Ask Live Support
              </Button>
            </Flex>
          </DrawerContent>
        )}
      </Drawer>
    </>
  )
})

HeaderMobileDrawer.displayName = 'HeaderMobileDrawer'

const ProfileSettingsDrawerContent: FC<{
  onBack: () => void
  onSelect: () => void
}> = props => (
  <DrawerContent maxW='232px' bg='graycool.200'>
    <Box>
      <Flex pt={mbp({ mobSm: 3, tabSm: 5 })} px='2'pl='4' alignItems='center'>
        <Text textStyle='body-b' color='graystrong.500'>Settings</Text>
        <Box flex='1' />
        <IconButton
          aria-label='close menu'
          icon={<ArrowLeftIcon />}
          boxSize='4'
          fontSize='3'
          color='graystrong.500'
          onClick={props.onBack}
        />
      </Flex>

      <UserSidebarNav onSelect={props.onSelect} />
    </Box>
  </DrawerContent>
)
