import { Effect, Match, RequestResolver, pipe } from 'effect'
import { UnknownException } from 'effect/Cause'
import { ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import MarketingListRequest from 'features/ListBuilder/infra/repo/MarketingListRepoLive/MarketingListRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import { ListTypeSchema } from 'features/ListBuilder/infra/repo/schema/ListTypeSchema/ListTypeSchema'
import MarketingListSchema from 'features/ListBuilder/infra/repo/schema/MarketingListSchema'
import { GETDebouncedListInfoById } from 'features/common/infra/GETDebouncedListInfo'
import { HTTPError } from 'ky'
import { EffectLib } from 'libs/Effect'

const FindByListIdResolver = RequestResolver.fromEffect(
  (request: MarketingListRequest.FindById) => pipe(
    Effect.tryPromise({
      try: async () => await GETDebouncedListInfoById.request(request.listId),
      catch: error => {
        if (error instanceof HTTPError && error.response.status === 404)
          return new ListNotFoundError({ marketingListId: request.listId })

        return new UnknownException(error)
      },
    }),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => toDomain(decoded)),
    Effect.catchTag('UnknownException', v => Effect.die(v)),
  ),
)

export default FindByListIdResolver

const decode = EffectLib.makeLoggedDecoder({
  schema: MarketingListSchema,
  name: 'GETFindByListIdResponse',
})

const toDomain = (response: MarketingListSchema): MarketingList => MarketingList.make({
  id: MarketingListId.make(response.id),
  type: ListTypeSchema.toDomain(response.type),
  name: response.name,
  createdAt: response.createdAt,
  updatedAt: response.updatedAt ?? response.createdAt,
  memberCount: response.memberStats?.total ?? 0,
  skiptracedCount: response.memberStats?.skiptraced ?? 0,
  criteria: CriteriaSchema.toDomain(
    Match.value(response.type).pipe(
      Match.when('national-leads-sellers', (): ListType => 'property-lists'),
      Match.when('national-leads-cashbuyers', (): ListType => 'cash-buyers'),
      Match.when('national-leads-privatelenders', (): ListType => 'private-lenders'),
      Match.when('drive', (): ListType => 'drive'),
      Match.exhaustive,
    ),
    response.source?.payload || null,
  ),
})
