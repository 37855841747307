import { useSettingsStore } from 'features/Settings/infra/react/Settings.state.react'
import { useNoticeStore } from 'presentation/main/NoticeCarousel'
import { NOTICE_KEYS } from 'presentation/main/NoticeCarousel/Notice.const'
import { PropertyMarkerTrainingNotice } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingNotice/PropertyMarkerTrainingNotice'
import { useEffect } from 'react'

export const usePropertyMarkerTrainingNotice = () => {
  const shouldShow = useSettingsStore(state => {
    const settingsState = state.settingsState
    if (settingsState.status !== 'loaded') return false
    const isDone = settingsState.settings.features.searchHistory.isPinTrainingDone
    return !isDone
  })

  const updateNotice = useNoticeStore(store => store.updateNotice)
  const handleOnClose = () => {
    updateNotice(NOTICE_KEYS.PROPERTY_MARKER_TRAINING, null)
  }

  useEffect(() => {
    if (!shouldShow) return

    updateNotice(NOTICE_KEYS.PROPERTY_MARKER_TRAINING,
      <PropertyMarkerTrainingNotice onClose={handleOnClose} />,
    )

    return () => {
      updateNotice(NOTICE_KEYS.PROPERTY_MARKER_TRAINING, null)
    }
  }, [shouldShow])
}
