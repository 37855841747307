import AppRouterContext from 'presentation/main/Router/AppRouterContext'
import { appRouter } from 'presentation/main/Router/appRouter'
import { FC } from 'react'
import {
  RouterProvider,
} from 'react-router-dom'

export const Router: FC = () => (
  <AppRouterContext.Provider value={appRouter}>
    <RouterProvider router={appRouter} />
  </AppRouterContext.Provider>
)
