import { Match } from 'effect'
import { PropertyDetails as Domain } from 'features/PropertyDetails/domain/PropertyDetails.domain'
import { EMPTY_PROPERTY_BUILDING } from 'features/PropertyDetails/domain/PropertyDetails.utils'
import { PropertyDetailsState } from 'features/PropertyDetails/infra/react/usePropertyDetailsState.types'
import { AppDomainEvents } from 'features/events/AppDomainEvents'
import { PanicError } from 'libs/errors/PanicError'
import { object } from 'libs/object'
import { nanoid } from 'nanoid'
import { useLeadId } from 'presentation/libs/LeadIdContext'
import { modals } from 'presentation/main/modals/modals'
import { createContext, useContext, useEffect, useRef } from 'react'
import { pipe, setPath, sortBy } from 'remeda'
import { create, useStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'

// Type guard for InsufficientConsumables
const isInsufficientConsumables = (data: any): data is Domain.InsufficientConsumables =>
  data && 'status' in data && data.status === 'insufficient-consumables'

export type PropertyDetailsStateDeps = {
  getProperty: (payload: Domain.GetPropertyPayload & {
    origin?: 'search' | 'other'
  }) =>
  Promise<Domain.GetPropertyResult>

  getPropertySuggestions: (
    payload: Domain.GetPropertySuggestionsPayload & {
      type: Domain.PropertySuggestionType
    }
  ) =>
  Promise<Domain.GetPropertySuggestionsResult>

  editProperty: (payload: Domain.EditPropertyPayload) =>
  Promise<Domain.EditPropertyResult>
}

export const createPropertyDetailsStore = (deps: PropertyDetailsStateDeps) =>
  create<PropertyDetailsState>()(immer((set, get) => {
    const stateApi: PropertyDetailsState = {
      actions: {
        getProperty: {
          state: { status: 'idle' },
          execute: async (payload): Promise<void> => {
            const currentState = get().actions.getProperty.state
            const isAlreadyRequested = pipe(
              Match.value([currentState, payload]),
              Match.when(
                [{ status: 'success' }, { leadId: Match.string }],
                ([state, params]) => state.data.leadId === params.leadId,
              ),
              Match.when(
                [{ status: 'success' }, { parcelId: Match.string }],
                ([state, params]) => state.data.status === 'with-details'
                && state.data.parcelId === params.parcelId,
              ),
              Match.when(
                [{ data: { status: 'with-details' } }, { parcelId: Match.string }],
                ([state, params]) => state.data.parcelId === params.parcelId,
              ),
              Match.when(
                [{ params: { addressString: Match.string } }, { addressString: Match.string }],
                ([state, params]) => state.params.addressString === params.addressString,
              ),
              Match.orElse(() => false),
            )

            if (isAlreadyRequested && !payload.allowRefresh) return

            const isAlreadyRequesting = currentState.status === 'loading' && object.shallowEqual(currentState.params, payload)

            if (isAlreadyRequesting) return

            const requestId = nanoid()

            set(state =>
              setPath(
                state,
                ['actions', 'getProperty', 'state'],
                { status: 'loading', requestId, params: payload },
              ),
            )

            await deps.getProperty(payload)
              .then(data => {
                const latestState = get().actions.getProperty.state
                const latestRequestId = 'requestId' in latestState && latestState.requestId

                if (latestState && latestRequestId !== requestId) return

                // Check for InsufficientConsumables error
                if (isInsufficientConsumables(data)) {
                  // Create a modal that shows the plans - we use the existing CHOOSE_PLAN_MODAL key
                  modals.emitter.emit({
                    type: 'OPEN',
                    payload: {
                      key: 'PROPERTY_DETAIL_REPORT_MODAL',
                      props: {
                        attempt: data.attempt,
                        onClose: () => modals.emitter.emit({ type: 'BACK', payload: null }),
                      },
                    },
                  })
                  return
                }

                set(state =>
                  setPath(
                    state,
                    ['actions', 'getProperty', 'state'],
                    { status: 'success', data, requestId, params: payload },
                  ))

                AppDomainEvents.emit('PropertyDetails/getProperty/success', {
                  state: get(),
                  payload,
                })
              })
              .catch(error => {
                const latestState = get().actions.getProperty.state
                const latestRequestId = 'requestId' in latestState && latestState.requestId

                if (latestState && latestRequestId !== requestId) return

                set(state =>
                  setPath(
                    state,
                    ['actions', 'getProperty', 'state'],
                    { status: 'error', error, requestId, params: payload },
                  ))
              })
          },
        },

        editProperty: {
          error: null,
          execute: async payload => {
            const property = getMatchingPropertyFromState(payload.leadId)(get())

            if (!property) return

            set(draft => {
              const property = getMatchingPropertyFromState(payload.leadId)(draft)

              if (!property) return

              // clear error
              draft.actions.editProperty.error = null

              // apply changes optimistically
              // for now we only implement livingAreaSqft
              const livingAreaSqftFromPayload = payload.building?.livingAreaSqft

              if (livingAreaSqftFromPayload !== undefined) {
                property.userInputData.building = {
                  ...property.userInputData.building,
                  livingAreaSqft: livingAreaSqftFromPayload,
                }

                if (property.status === 'with-details') {
                  property.building = {
                    ...EMPTY_PROPERTY_BUILDING,
                    ...property.building,
                    livingAreaSqft: livingAreaSqftFromPayload,
                  }
                }
              }
            })

            return await deps.editProperty(payload)
              .catch(async error => {
                const latestProperty = getMatchingPropertyFromState(payload.leadId)(get())

                if (!latestProperty) return

                const didLeadIdChange = latestProperty?.leadId !== payload.leadId

                if (didLeadIdChange) return

                set(draft => {
                  draft.actions.editProperty.error = error
                })

                return await get().actions.getProperty.execute({
                  leadId: payload.leadId,
                  allowRefresh: true,
                  origin: 'other',
                })
              })
          },
        },

        clearProperty: {
          execute: () => {
            set(state =>
              setPath(
                state,
                ['actions', 'getProperty', 'state'],
                { status: 'idle' },
              ),
            )

            // clear edit error
            set(state => {
              state.actions.editProperty.error = null
            })
          },
        },

        getPropertySuggestions: {
          state: { status: 'idle' },
          execute: async (params: Domain.GetPropertySuggestionsPayload): Promise<void> => {
            const currentState = get().actions.getPropertySuggestions.state
            const isDoneOrOngoingWithoutError = 'params' in currentState
              && currentState.params.addressString === params.addressString
              && !('error' in currentState)

            if (isDoneOrOngoingWithoutError) return

            const requestId = nanoid()

            set(state =>
              setPath(
                state,
                ['actions', 'getPropertySuggestions', 'state'],
                { status: 'loading', requestId, params },
              ),
            )

            await Promise.all([
              'exact' as const,
              'close' as const,
              'fuzzy' as const,
            ].map(async type =>
              await deps.getPropertySuggestions({ ...params, type })
                .catch(error => ({ error }))
                .then(data => {
                  const latestState = get().actions.getPropertySuggestions.state
                  const latestRequestId = 'requestId' in latestState && latestState.requestId

                  if (latestState && latestRequestId !== requestId) return

                  // error
                  const newError = 'error' in data ? data.error : undefined
                  const existingError = latestState.status === 'partial'
                    ? latestState.error
                    : undefined
                  const mergedError = existingError || newError

                  // suggestions
                  const newSuggestions = Array.isArray(data) ? data : []
                  const existingSuggestions = latestState.status === 'partial'
                    ? latestState.data.suggestions
                    : []

                  let mergedSuggestions = [...existingSuggestions]

                  newSuggestions.forEach(newSuggestion => {
                    const dupeIndex = mergedSuggestions
                      .findIndex(s => s.addressString === newSuggestion.addressString)

                    if (dupeIndex === -1)
                      return mergedSuggestions.push(newSuggestion)

                    const dupeSuggestion = mergedSuggestions[dupeIndex]
                    mergedSuggestions.splice(dupeIndex, 1)

                    const mergedSuggestion = {
                      ...newSuggestion,
                      score: Math.max(newSuggestion.score, dupeSuggestion.score),
                    }

                    mergedSuggestions.push(mergedSuggestion)
                  })

                  // sort by score
                  mergedSuggestions = sortBy(mergedSuggestions, s => -s.score)

                  // completedTypes
                  const existingCompletedTypes = latestState.status === 'partial'
                    ? latestState.data.completedTypes
                    : []
                  const mergedTypes = [...existingCompletedTypes, type]

                  // status
                  const exactSuggestions = mergedSuggestions.filter(s => s.score === Domain.EXACT_SUGGESTION_SCORE)
                  const closeSuggestions = mergedSuggestions.filter(s => s.score === Domain.CLOSE_SUGGESTION_SCORE)

                  const areAllTypesDone = mergedTypes.length === 3
                  const TARGET_SUGGESTION_COUNT = 10
                  const isTargetMetByExactAndClose
                    = exactSuggestions.length + closeSuggestions.length >= TARGET_SUGGESTION_COUNT
                  const isExactTypeDone = mergedTypes.includes('exact')

                  const isDone = areAllTypesDone
                    || (isExactTypeDone && isTargetMetByExactAndClose)
                  const status = isDone ? 'done' : 'partial'

                  // update state
                  set(state =>
                    setPath(
                      state,
                      ['actions', 'getPropertySuggestions', 'state'],
                      {
                        status,
                        data: {
                          completedTypes: mergedTypes,
                          suggestions: mergedSuggestions,
                        },
                        error: mergedError,
                        requestId,
                        params,
                      },
                    ))
                }),
            ))
          },
        },

        clearPropertySuggestions: {
          execute: () => {
            set(state =>
              setPath(
                state,
                ['actions', 'getPropertySuggestions', 'state'],
                { status: 'idle' },
              ),
            )
          },
        },
      },
    }

    return stateApi
  }))

type PropertyDetailsStore = ReturnType<typeof createPropertyDetailsStore>

const PropertyDetailsContext = createContext<PropertyDetailsStore | null>(null)

export type PropertyDetailsProviderProps = PropertyDetailsStateDeps & {
  children: React.ReactNode
}

export const PropertyDetailsProvider = ({
  children,
  ...rest
}: PropertyDetailsProviderProps) => {
  const store = useRef(createPropertyDetailsStore(rest)).current
  return (
    <PropertyDetailsContext.Provider value={store}>
      {children}
    </PropertyDetailsContext.Provider>
  )
}

// eslint-disable-next-line @stylistic/comma-dangle
export const usePropertyDetailsStore = <T,>(
  selector: (state: PropertyDetailsState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(PropertyDetailsContext)
  if (!store) throw new PanicError('usePropertyDetailsStore must be used within PropertyDetailsProvider')
  return useStore(store, selector, equalityFn)
}

/** @TOREFACTOR Rename to useGetPropertyByContextLeadId */
export const useGetPropertyByRouteLeadId = () => {
  const leadId = useLeadId()

  const getProperty = usePropertyDetailsStore(state => state.actions.getProperty.execute)

  useEffect(() => {
    if (!leadId) return
    void getProperty({ leadId, origin: 'other' })
  }, [leadId])
}

/** @TOREFACTOR Rename to GetPropertyByContextLeadIdCaller */
export const GetPropertyByRouteLeadIdCaller = () => {
  useGetPropertyByRouteLeadId()
  return null
}

const getMatchingPropertyFromState = (leadId: string) => (state: PropertyDetailsState) => {
  const property = getPropertyFromState(state)
  if (!property) return null
  if (property.leadId !== leadId) return null
  return property
}

export const getPropertyFromState = (state: PropertyDetailsState) => {
  const property = state.actions.getProperty.state
  if (property.status !== 'success') return null
  return property.data
}
