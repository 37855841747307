import { Data } from 'effect'
import { DownloadNotAvailableError, ListMemberNotFoundError, ListNotFoundError } from 'features/ListBuilder/domain/Errors'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { ListPreview } from 'features/ListBuilder/domain/ListSource/ListPreview'
import { MarketingList, MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'
import { MarketingListMember } from 'features/ListBuilder/domain/MarketingListMember'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import MarketingListMemberRepo from 'features/ListBuilder/domain/repository/MarketingListMemberRepo'
import MarketingListRepo from 'features/ListBuilder/domain/repository/MarketingListRepo'

export type ListBuilderStore = {
  buildList: BuildList
  buildTotal: BuildTotalState
  saveList: SaveList
  deleteLists: DeleteListsBranch
  criteria: ListCriteria | null
  switchCriteria: SwitchCriteria
  selectList: SelectListBranch
  listMembers: ListMembersState
  deleteListMembers: DeleteListMembersBranch
  listMembersPagination: ListMembersPaginationBranch
  loadLists: LoadLists
  listsPagination: ListsPaginationBranch
  updateListName: UpdateListNameBranch
  getDownloadStats: GetDownloadStatsBranch
  downloadList: DownloadListBranch
  closeStore: () => void
}

export type Self = ListBuilderStore

export type BuildList = {
  state: BuildListState
  execute: (criteria: ListCriteria) => void
}

export type BuildListState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { preview: ListPreview }
  Failed: { error: Error }
}>

export const BuildListState = Data.taggedEnum<BuildListState>()

export type BuildTotalState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { total: number }
  Failed: { error: Error }
}>

export const BuildTotalState = Data.taggedEnum<BuildTotalState>()

export type SaveList = {
  state: SaveListState
  execute: (listName: string) => void
}

export type SaveListState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { list: MarketingList }
  Failed: { error: Error }
}>

export const SaveListState = Data.taggedEnum<SaveListState>()

export type SwitchCriteria = (type: ListType) => void

export type SelectListBranch = {
  state: SelectListState
  execute: (listId: MarketingListId) => void
}

export type SelectListState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { list: MarketingList }
  Failed: { error: ListNotFoundError | Error }
}>

export const SelectListState = Data.taggedEnum<SelectListState>()

export type ListMembersState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Importing: { members: MarketingListMember[], progress: MarketingListRepo.ImportProgress }
  Success: { listId: MarketingListId, members: MarketingListMember[] }
  Failed: { error: Error }
}>

export const ListMembersState = Data.taggedEnum<ListMembersState>()

export type LoadLists = {
  state: ListsState
  execute: () => void
}

export type ListsState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { lists: MarketingList[] }
  Failed: { error: Error }
}>

export const ListsState = Data.taggedEnum<ListsState>()

export type ListMembersPaginationBranch = {
  state: ListMembersPaginationState
  execute: (page: number) => void
}

export type MembersPaginationMeta = {
  listId: MarketingListId
  currentPage: number
  countPerPage: number
  totalCount: number
}

export type ListMembersPaginationState = Data.TaggedEnum<{
  Initial: {}
  Loading: MembersPaginationMeta
  Success: MembersPaginationMeta
  Failed: { error: ListNotFoundError | Error }
}>

export const ListMembersPaginationState = Data.taggedEnum<ListMembersPaginationState>()

export type ListsPaginationMeta = {
  currentPage: number
  countPerPage: number
  totalCount: number
}

export type ListsPaginationBranch = {
  state: ListsPaginationState
  execute: (page: number) => void
}

export type ListsPaginationState = Data.TaggedEnum<{
  Initial: {}
  Loading: ListsPaginationMeta
  Success: ListsPaginationMeta
  Failed: { error: Error }
}>

export const ListsPaginationState = Data.taggedEnum<ListsPaginationState>()

export type DeleteListsBranch = {
  state: DeleteListState
  execute: (listIds: MarketingListId[]) => void
}

export type DeleteListState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: MarketingListRepo.DeleteListsResult
  Failed: { error: ListNotFoundError | Error }
}>

export const DeleteListState = Data.taggedEnum<DeleteListState>()

export type DeleteListMembersBranch = {
  state: DeleteListMembersState
  execute: (listId: MarketingListId, memberIds: MarketingListMemberId[]) => void
}

export type DeleteListMembersState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: MarketingListMemberRepo.DeleteByIdsResult
  Failed: { error: ListMemberNotFoundError | Error }
}>

export const DeleteListMembersState = Data.taggedEnum<DeleteListMembersState>()

export type UpdateListNameBranch = {
  state: UpdatingListNameState
  execute: (listId: MarketingListId, name: string) => void
}

export type UpdatingListNameState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { list: MarketingList }
  Failed: { error: ListNotFoundError | Error }
}>

export const UpdatingListNameState = Data.taggedEnum<UpdatingListNameState>()

/**
 * Check download limits
 */
export type GetDownloadStatsBranch = {
  state: GetDownloadStatsState
  execute: GetDownloadStatsExecute
}

export type GetDownloadStatsState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: { downloadStats: MarketingListRepo.DownloadStats }
  Failed: { error: ListNotFoundError | Error }
}>

export const GetDownloadStatsState = Data.taggedEnum<GetDownloadStatsState>()

export type GetDownloadStatsExecute = (listId: MarketingListId) => void

export type DownloadListBranch = {
  state: DownloadListState
  execute: (listId: MarketingListId) => void
}

export type DownloadListState = Data.TaggedEnum<{
  Initial: {}
  Loading: {}
  Success: {}
  Failed: { error: ListNotFoundError | DownloadNotAvailableError | Error }
}>

export const DownloadListState = Data.taggedEnum<DownloadListState>()
