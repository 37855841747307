import { Button, CardProps, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement, Link, Text } from '@chakra-ui/react'
import { Match } from 'effect'
import { useAccessStore } from 'features/Access/infra/react/AccessState'
import { EyeIcon, EyeOffIcon } from 'presentation/components/Icons'
import { PreserveQueryLink } from 'presentation/components/PreserveQueryLink/PreserveQueryLink'
import { toast } from 'presentation/components/Toast'
import { CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { MARKETING_PAGE_PRICING_URL } from 'presentation/const/const'
import { useOneToastAtATime } from 'presentation/libs/useOneToastAtATime'
import { FormCard } from 'presentation/screens/AuthScreens/components/FormCard'
import { mbp } from 'presentation/utils/mapBreakpoint'
import QueryString from 'qs'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { pipe } from 'remeda'

type FormValues = {
  email: string
  password: string
}

export const SignInCard = (props: CardProps) => {
  const domain = useAccessStore(store => {
    const login = store.actions.login
    const clearErrors = store.actions.clearErrors

    return {
      login: login.execute,
      clearErrors: clearErrors.execute,
      isSubmitting: login.state.status === 'loading',
      errorMsg: pipe(
        Match.value(login.state),
        Match.when({ error: { status: 'credentials-error' } }, () =>
          'Please check your email and password and try again.'),
        Match.when({ error: Match.instanceOf(Error) }, ({ error }) => error.message),
        Match.orElse(() => null),
      ),
    }
  })

  useOneToastAtATime(() => {
    if (!domain.errorMsg) return null

    domain.clearErrors()

    return {
      status: 'error',
      title: 'Sign in failed',
      message: domain.errorMsg,
    }
  }, [domain.errorMsg])

  const form = useForm<FormValues>({ mode: 'onChange' })

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()
    setIsPasswordVisible(!isPasswordVisible)
  }

  const reason = QueryString.parse(location.search, { ignoreQueryPrefix: true }).reason as string | undefined

  useEffect(() => {
    if (reason === 'no-beta-access') {
      toast.error({
        title: 'Beta Access Required',
        message: 'Sorry, this is a closed beta. Please contact support to ask for access.',
      })
    }
  }, [reason])

  return (
    <FormCard
      as='form'
      onSubmit={form.handleSubmit(formValues => {
        void domain.login(formValues)
      })}
      {...props}
    >
      <CardPrimaryTitle>
        Sign in to your account
      </CardPrimaryTitle>
      <Flex mt={3} flexDirection='column' gap={3}>
        <FormControl isInvalid={!!form.formState.errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            type='email'
            placeholder='Enter Email'
            {...form.register('email', {
              required: 'Email is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Please provide valid email address.',
              },
            })}
          />
          {form.formState.errors.email && (
            <FormErrorMessage>
              {form.formState.errors.email.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.password}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder='Enter Password'
              {...form.register('password', {
                required: 'Password is required',
              })}
            />
            <InputRightElement
              onClick={togglePasswordVisibility}
              as='button'
              type='button'
            >
              {isPasswordVisible
                ? <EyeOffIcon color='graystrong.200' />
                : <EyeIcon color='graystrong.200' />}
            </InputRightElement>
          </InputGroup>
          {form.formState.errors.password && (
            <FormErrorMessage>
              {form.formState.errors.password.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>

      <Link
        as={PreserveQueryLink}
        mt={1}
        color='link'
        to='/send-reset-password-email'
      >
        Forgot Password?
      </Link>

      <Button
        mt={mbp({ mobSm: 'auto', mob: '5' })}
        variant='solid'
        colorScheme='positive'
        type='submit'
        isLoading={domain.isSubmitting}
        isDisabled={form.formState.isSubmitted && !form.formState.isValid}
      >
        Sign In
      </Button>

      <Text
        mt={3}
        color='graystrong.400'
        textAlign='center'
      >
        Don&rsquo;t have an account yet?
        &nbsp;
        <Link href={MARKETING_PAGE_PRICING_URL} textDecoration='underline'>Sign up</Link>
      </Text>
    </FormCard>
  )
}
