import { BoxProps, HStack, Link, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { Option } from 'effect'
import OwnersPropertiesHooks from 'features/OwnersProperties/view/viewModel/OwnersPropertiesHooks'
import { DateLib } from 'libs/Date'
import { Dollar } from 'libs/dollar'

/**
 * Owner's currently owned properties
 */
const OwnedPropertiesTable = ({ ownerId, ...props }: { ownerId: string } & BoxProps) => {
  const viewModel = OwnersPropertiesHooks.useOwnerDeals(ownerId)

  if (
    Option.isNone(viewModel)
    || !OwnersPropertiesHooks.OwnerDealsViewModel.$is('Success')(viewModel.value)
  ) return null

  return (
    <HStack
      w='full'
      h='0'
      minH='0'
      overflowX='auto'
      pb={{ base: 3, tabSm: 0 }}
      spacing={0}
      mx={{ base: -1, mob: -2, tabSm: 0 }}
      flex='1 1 auto'
      align='flex-start'
      {...props}
    >
      <Spacer
        flexGrow='0'
        flexShrink='0'
        flexBasis={{ base: 1, mob: 2, tabSm: 0 }}
        w={{ base: 1, mob: 2, tabSm: 0 }}
      />

      <Table minW='560px'>
        <Thead
          position='sticky'
          top='0'
          sx={{
            th: {
              bgColor: 'neutral.400',
            },
          }}
        >
          <Tr>
            <Th>Property Address</Th>
            {/* <Th>Deal Type</Th> */}
            <Th textAlign='right'>Purchase Amt/Date</Th>
            <Th textAlign='right'>Sold Amt/Date</Th>
            <Th textAlign='right'>Rental Amt/Date</Th>
            {/* <Th textAlign='right'>Estimated Discount</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {viewModel.value.dealIds.map(dealId => (
            <DealRow key={dealId} ownerId={ownerId} dealId={dealId} />
          ))}
        </Tbody>
      </Table>

      <Spacer
        flexGrow='0'
        flexShrink='0'
        flexBasis={{ base: 1, mob: 2, tabSm: 0 }}
        w={{ base: 1, mob: 2, tabSm: 0 }}
      />
    </HStack>
  )
}

export default OwnedPropertiesTable

const DealRow = ({ ownerId, dealId }: { ownerId: string, dealId: string }) => {
  const viewModel = OwnersPropertiesHooks.useOwnerDeal(ownerId, dealId)

  if (Option.isNone(viewModel)) return null

  const { relation, onAddressClick } = viewModel.value
  const { deal, property } = relation
  const { address } = property

  return (
    <Tr
      key={dealId}
      sx={{
        '&:hover > td': {
          bgColor: 'positive.50 !important',
        },
      }}
    >
      <Td maxW='200px'>
        <Link>
          <Text
            color='inherit'
            maxW='full'
            isTruncated
            title={`${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`}
            as='button'
            minW='0'
            onClick={onAddressClick}
            textAlign='left'
          >
            {address.line1}
            ,
            {' '}
            {address.city}
          </Text>
          <br />
          <Text
            maxW='full'
            color='inherit'
            isTruncated
            title={`${address.state} ${address.postalCode}`}
            as='button'
            minW='0'
            onClick={onAddressClick}
            textAlign='left'
          >
            {address.state}
            {' '}
            {address.postalCode}
          </Text>
        </Link>
      </Td>
      {/* <Td>
        <FlippedPill />
      </Td> */}
      <Td textAlign='right'>
        {deal.purchaseAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        /
        <br />
        {deal.purchaseDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
      <Td textAlign='right'>
        {deal.soldAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        /
        <br />
        {deal.soldDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
      <Td textAlign='right'>
        {deal.rentAmount.pipe(
          Option.map(Dollar.formatNumberWithCommas),
          Option.getOrElse(() => '--'),
        )}
        /
        <br />
        {deal.rentDate.pipe(
          Option.map(DateLib.formatMMDDYYDots),
          Option.getOrElse(() => '--'),
        )}
      </Td>
      {/* <Td textAlign='right'>
        20 %
      </Td> */}
    </Tr>
  )
}
