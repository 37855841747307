import { CardProps, Center, Spinner } from '@chakra-ui/react'
import { useBatchSkiptraceActions } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceActions'
import { useBatchSkiptraceAttemptState } from 'features/BatchSkiptrace/infra/views/BatchSkiptraceStore/hooks/useBatchSkiptraceAttemptState'
import { ConsumableAttemptBillingSummaryCard } from 'features/common/ConsumableAttempt/views/ConsumableAttemptBillingSummaryCard'
import { DateLib } from 'libs/Date'
import { LegacyBillingEvents } from 'presentation/screens/Billing/Billing.events'
import { BatchSkiptraceModalState, useBatchSkiptraceModalActions } from 'presentation/screens/ListScreen/components/BatchSkiptraceModal/useBatchSkiptraceModalStore'
import { useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

export type SkiptraceBreakdownStepCardProps = {
  payload: Extract<BatchSkiptraceModalState, { _tag: 'SkiptraceBreakdownStep' }>
} & CardProps

export const SkiptraceBreakdownStepCard = ({ payload, ...cardProps }: SkiptraceBreakdownStepCardProps) => {
  const modalActions = useBatchSkiptraceModalActions()

  // Extract data from the attempt
  const [attempt, setAttempt] = useState(payload.attempt)
  const availableFunds = attempt.funds.available
  const fundsStatus = attempt.status
  const pricePenniesPerUnit = attempt.price
  const refreshingConsumables = attempt.refreshingConsumables
  const remainingConsumables = attempt.remainingConsumables
  const nextRenewalDateRaw = attempt.consumablesRefreshDate
  const nextRenewalDate = DateLib.formatMMDDYYDots(nextRenewalDateRaw)
  const currentPlan = attempt.planName

  // Calculate payment price based on the number of records to skiptrace
  const paymentPrice = pricePenniesPerUnit * payload.toSkiptraceCount

  // Handle proceeding to format step
  const handleNext = () => {
    // Just navigate to format step, passing the payload with attempt data
    // The confirmation will happen in the FormatStepCard
    modalActions.toFormatStep({
      ...payload,
      useFreeSkipsOnly: false,
      remainingConsumables,
      attempt,
    })
  }

  const handleUseFreeSkips = () => {
    modalActions.toFormatStep({
      ...payload,
      useFreeSkipsOnly: true,
      remainingConsumables,
      attempt,
    })
  }

  // Calculate the freeItemExceedCount - how many records exceed the free limit
  const calculateFreeItemExceedCount = () => {
    // If we have sufficient free consumables or the count is less than the free consumables, return 0
    if (fundsStatus === 'sufficient-consumables' && payload.toSkiptraceCount <= remainingConsumables)
      return 0

    // If we have some free consumables but not enough for all records, calculate the excess
    if (remainingConsumables > 0 && payload.toSkiptraceCount > remainingConsumables)
      return payload.toSkiptraceCount - remainingConsumables

    // If we have no free consumables, all records need to be paid for
    return payload.toSkiptraceCount
  }

  // Generate the appropriate subtitle based on whether we're using free skips or paying
  const getSubtitle = () => {
    const freeItemExceedCount = calculateFreeItemExceedCount()

    if (fundsStatus === 'sufficient-consumables') {
      if (freeItemExceedCount > 0)
        return `You're about to skiptrace ${payload.toSkiptraceCount} properties. ${remainingConsumables} will be covered by free skips. Do you want to pay for the remaining ${freeItemExceedCount}?`
      else
        return `You're about to skiptrace ${payload.toSkiptraceCount} properties. Do you want to use your free skips?`
    } else {
      return `You've run out of skips for this month. Do you want to pay for an additional ${payload.toSkiptraceCount} skips?`
    }
  }

  const actions = useBatchSkiptraceActions()
  const attemptState = useBatchSkiptraceAttemptState()

  // Update attempt state when funds are added
  useEffect(() => {
    const handleAddFundsDone = () => {
      actions.attemptBatchSkiptrace({
        ...payload,
        format: 'narrow', // Placeholder format
        useFreeSkipsOnly: false, // Assume false
        remainingConsumables: 0, // Because useFreeSkipsOnly is false, 0 is fine
      })
    }

    // Subscribe to the event and store the emitter
    const emitter = LegacyBillingEvents.addFundsDone.subscribe(handleAddFundsDone)

    // Clean up the subscription when component unmounts
    return () => {
      emitter.off('addFundsDone', handleAddFundsDone)
    }
  }, [])

  const prevAttemptState = usePrevious(attemptState)

  // Update the attempt state when re-attempting
  useEffect(() => {
    const didJustAttempt = prevAttemptState?._tag === 'Attempting'
      && attemptState._tag === 'Attempted'

    if (!didJustAttempt) return

    setAttempt(attemptState.attempt)
  }, [attemptState, prevAttemptState])

  if (attemptState._tag === 'Attempting')
    return <Center p={6}><Spinner /></Center>

  return (
    <ConsumableAttemptBillingSummaryCard
      {...cardProps}
      maxW='736px'
      title='Run Skiptrace'
      subtitle={getSubtitle()}
      consumableType='skiptrace'
      paymentPrice={paymentPrice}
      freeItemExceedCount={calculateFreeItemExceedCount()}
      pricePenniesPerUnit={pricePenniesPerUnit}
      refreshingConsumables={refreshingConsumables}
      remainingConsumables={remainingConsumables}
      consumablesRefreshDate={nextRenewalDate}
      currentPlan={currentPlan}
      fundsStatus={fundsStatus}
      availableFunds={availableFunds}
      onAttempt={handleNext}
      onCancel={modalActions.close}
      onUseFreeSkips={calculateFreeItemExceedCount() > 0 && remainingConsumables > 0
        ? handleUseFreeSkips
        : undefined}
      quantity={calculateFreeItemExceedCount()}
    />
  )
}
