import { createIcon } from '@chakra-ui/react'

export const CardDinersLightIcon = createIcon({
  displayName: 'CardDinersLightIcon',
  viewBox: '0 0 47 32',
  path: (
    <>
      <rect width='46.6721' height='32' rx='4' fill='#FFCF77' />
      <path d='M25.3964 25.9999C30.5937 26.025 35.3375 21.7228 35.3375 16.4893C35.3375 10.7666 30.5937 6.81046 25.3964 6.81259H20.9233C15.6641 6.81046 11.3347 10.7676 11.3347 16.4893C11.3347 21.7239 15.6641 26.025 20.9233 25.9999H25.3964Z' fill='#916C27' />
      <path d='M20.9447 7.60562C16.1384 7.60722 12.2443 11.5377 12.2427 16.3889C12.2443 21.2395 16.1384 25.17 20.9447 25.1716C25.7515 25.17 29.6472 21.2395 29.6478 16.3889C29.6472 11.5377 25.7521 7.60722 20.9447 7.60562ZM15.4291 16.3889C15.4281 15.2672 15.7655 14.1714 16.3972 13.2446C17.029 12.3177 17.9257 11.603 18.9701 11.1938V21.5834C17.9256 21.1745 17.0288 20.4599 16.397 19.5331C15.7652 18.6063 15.4279 17.5105 15.4291 16.3889V16.3889ZM22.9187 21.5855V11.1933C23.9639 11.6019 24.8614 12.3165 25.4936 13.2437C26.1258 14.1708 26.4633 15.2673 26.4619 16.3894C26.4631 17.5115 26.1255 18.6078 25.4933 19.5349C24.8612 20.462 23.9638 21.1768 22.9187 21.5855V21.5855Z' fill='#916C27' />
      <path fillRule='evenodd' clipRule='evenodd' d='M12.2424 16.3888C12.244 11.5376 16.1381 7.60716 20.9444 7.60556C25.7518 7.60716 29.647 11.5376 29.6475 16.3888C29.647 21.2394 25.7513 25.1699 20.9444 25.1715C16.1381 25.1699 12.244 21.2394 12.2424 16.3888ZM16.397 13.2445C15.7653 14.1713 15.4278 15.2672 15.4288 16.3888C15.4276 17.5104 15.765 18.6063 16.3968 19.5331C17.0286 20.4599 17.9254 21.1744 18.9699 21.5834V11.1937C17.9254 11.6029 17.0288 12.3176 16.397 13.2445ZM22.9185 11.1932V21.5855C23.9636 21.1767 24.8609 20.462 25.4931 19.5349C26.1253 18.6078 26.4629 17.5114 26.4616 16.3893C26.4631 15.2672 26.1256 14.1708 25.4934 13.2436C24.8612 12.3165 23.9637 11.6018 22.9185 11.1932Z' fill='#FFCF77' />
    </>
  ),
})
