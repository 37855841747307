import { Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react'
import ParcelId from 'features/ListBuilder/domain/ParcelId'
import { ParcelListManagerProvider } from 'features/ListBuilder/views/machines/ParcelListManagerContext'
import { useSelectPropertyByRouteLeadId } from 'features/PropertyDetails/infra/react/usePropertyDetailsSelectors'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { MLSStatusTag } from 'presentation/components/MLSStatusTag/MLSStatusTag'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { MarkerSize } from 'presentation/components/PropertyMarker/PropertyMarker.type'
import { Tooltip } from 'presentation/components/Tooltip'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import MobileActionSheet from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/MobileActionSheet/MobileActionSheet'
import { PropertyDeleteAction } from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertyDeleteAction/PropertyDeleteAction'
import PropertySaveToListAction from 'presentation/screens/PropertyDetailsScreen/components/HeaderActionsSection/PropertySaveToListAction/PropertySaveToListAction'
import { MarkerInfoSection } from 'presentation/screens/PropertyDetailsScreen/components/MarkerInfoSection/MarkerInfoSection'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { isNonNullable } from 'utils/isNonNullable'
import { shallow } from 'zustand/shallow'

export const HeaderInfoSection = () => {
  const selectProperty = useSelectPropertyByRouteLeadId()
  const data = usePropertyDetailsStore(state => {
    const property = selectProperty(state)

    if (!property) return null

    if (property.status === 'without-details') {
      return {
        parcelId: null,
        line1: property.address.line1,
        city: property.address.city,
        postalCode: property.address.postalCode,
        state: property.address.state,
        status: null,
        classification: null,
        equityType: null,
        isForeclosure: false,
        isSenior: false,
        isVacant: false,
      }
    }

    return {
      parcelId: ParcelId.make(property.parcelId),
      line1: property.address.line1,
      city: property.address.city,
      postalCode: property.address.postalCode,
      state: property.address.state,
      status: property.mlsListings?.[0]?.status || null,
      classification: property.ownership?.classification || null,
      equityType: property.valuation?.equityType || null,
      isForeclosure: property.ownership?.isForeclosure || false,
      isSenior: property.ownership?.isSenior || false,
      isVacant: property.ownership?.isVacant || false,
    }
  }, shallow)

  const markerSize = useSwitchBreakpoint<MarkerSize>({
    mobSm: 'xs',
    tabSm: 'md',
  }) ?? 'xs'

  if (!data) return null

  const {
    line1,
    city,
    state,
    postalCode,
    classification,
    equityType,
    isVacant,
    isForeclosure,
    isSenior,
    status,
  } = data

  const addressString = `${city}, ${state} ${postalCode}`

  return (
    <Stack
      spacing={2}
      direction='row'
      backgroundColor='neutral.200'
      align='center'
    >
      <Box
        flexShrink={0}
        alignSelf='center'
      >
        <PropertyMarker
          classification={classification}
          equityType={equityType}
          isVacant={isVacant}
          isForeclosure={isForeclosure}
          isSenior={isSenior}
          size={markerSize}
          markerType='pin'
        />
      </Box>

      <Stack
        spacing={mbp({ mobSm: 0, mob: 0.5 })}
        flexGrow={1}
        alignItems='flex-start'
      >
        <Stack
          direction='row'
          spacing={1}
          align='center'
        >
          <Tooltip label={line1}>
            <Text
              color='accent.blue-to-gray'
              textStyle={mbp({ mobSm: 'bodyLFat', mob: 'h4', dtSm: 'h3' })}
              noOfLines={1}
            >
              {line1}
            </Text>
          </Tooltip>
          {isNonNullable(status) && <MLSStatusTag status={status} />}
        </Stack>
        <Tooltip label={addressString}>
          <Text
            color='graystrong.200'
            textStyle='bodyMFat'
            noOfLines={1}
          >
            {addressString}
          </Text>
        </Tooltip>
      </Stack>

      <Spacer />

      <Box
        display={mbp({ mobSm: 'none', tabSm: 'block' })}
      >
        <MarkerInfoSection />
      </Box>

      <Breakpoint
        mobSm={<MobileActionSheet parcelId={data.parcelId} />}
        tabSm={(
          <>
            {isNonNullable(data.parcelId)
              ? (
                <ParcelListManagerProvider parcelId={data.parcelId}>
                  <Flex alignItems='center' gap={2}>
                    <PropertySaveToListAction />
                    <PropertyDeleteAction shouldShowFromAllListAction />
                  </Flex>
                </ParcelListManagerProvider>
              )
              : (
                <PropertyDeleteAction shouldShowFromAllListAction={false} />
              )}
          </>
        )}
      />
    </Stack>
  )
}
