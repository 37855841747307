import { Badge, BadgeProps, Box, BoxProps, FlexProps, useBreakpointValue } from '@chakra-ui/react'
import classNames from 'classnames'
import { BaseTab, BaseTabContent, BaseTabs, BaseTabsContainer, BaseTabsProps } from 'presentation/components/BaseTabs/BaseTabs'
import { SwitchTabsAPI, SwitchTabsProvider, useSwitchTabButton, useSwitchTabContent } from 'presentation/components/molecules/SwitchTabs/SwitchTabs.logic'
import { FC, PropsWithChildren, forwardRef } from 'react'
import { isBoolean } from 'remeda'

export type SwitchTabsWrapperProps = BoxProps
  & Pick<BaseTabsProps, 'colorScheme' | 'size'>
  & {
    initialActiveTabKey?: string
    onActiveTabKeyChange?: (tabKey: string) => void
  }

export const SwitchTabsWrapper = forwardRef<SwitchTabsAPI, SwitchTabsWrapperProps>(({
  children,
  initialActiveTabKey,
  onActiveTabKeyChange,
  colorScheme,
  size,
  ...props
}, ref) => (
  <SwitchTabsProvider
    ref={ref}
    initialActiveTabKey={initialActiveTabKey}
    onActiveTabKeyChange={onActiveTabKeyChange}
  >
    <BaseTabsContainer
      className={TAB_SWITCH_WRAPPER_CLASS}
      size={size}
      colorScheme={colorScheme}
      {...props}
    >
      {children}
    </BaseTabsContainer>
  </SwitchTabsProvider>
))

SwitchTabsWrapper.displayName = 'SwitchTabsWrapper'

export const TAB_SWITCH_WRAPPER_CLASS = 'SwitchTabsWrapper'

type SwitchTabButtonsProps = FlexProps & BaseTabsProps
export const SwitchTabButtons = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SwitchTabButtonsProps>
>(
  ({ children, ...props }, ref) => (
    <BaseTabs
      pos='relative'
      zIndex='sticky'
      role='tablist'
      {...props}
      ref={ref}
    >
      {children}
    </BaseTabs>
  ))

SwitchTabButtons.displayName = 'SwitchTabButtons'

export type SwitchTabButtonProps = BoxProps & {
  tabKey?: string
  onClick?: () => void

  /**
   * true by default (because tabs typically corresponds to a content
   * counterpart.) Set this to false if tab does not correspond to any
   * content. fFr example for "more" tab that opens menu on mobile.
   */
  hasContent?: boolean

  isMuted?: boolean

  /**
   * Used to hide certain tabs on certain breakpoints
   */
  isHidden?: boolean[] | boolean
}

/**
 * @TODO Tabs on mobile should have carousel-like navigation when it exceeds container width
  */
export const SwitchTabButton = forwardRef<HTMLDivElement, SwitchTabButtonProps>(({
  children,
  tabKey,
  hasContent = true,
  isMuted,
  isHidden: isHiddenFromProps,
  onClick: onClickFromProps,
  className,
  ...props
}, ref) => {
  const tab = useSwitchTabButton({ tabKey, hasContent, onClick: onClickFromProps })

  const isHidden = useBreakpointValue(
    isBoolean(isHiddenFromProps)
      ? [isHiddenFromProps]
      : isHiddenFromProps === undefined
        ? [false]
        : isHiddenFromProps)

  return (
    <BaseTab
      ref={ref}
      id={tab.tabKey}
      aria-selected={tab.isActive ? 'true' : 'false'}
      aria-controls={tab.contentKey ? `#${tab.contentKey}` : undefined}
      onClick={tab.onClick}
      role='tab'
      isActive={tab.isActive}
      isMuted={isMuted}
      isHidden={isHidden}
      className={classNames(
        SWITCH_TAB_BUTTON_CLASS_NAME,
        className,
      )}
      {...props}
    >
      {children}
    </BaseTab>
  )
})

SwitchTabButton.displayName = 'SwitchTabButton'
export const SWITCH_TAB_BUTTON_CLASS_NAME = 'TabButton'

export const SwitchTabContents: FC<BoxProps> = ({ children, ...props }) => (
  <Box
    pos='relative'
    zIndex='sticky-2'
    id='test'
    {...props}
  >
    {children}
  </Box>
)

export const SwitchTabContent: FC <BoxProps> = ({ children, ...props }) => {
  const content = useSwitchTabContent()

  if (!content.isActive) return null

  return (
    <BaseTabContent
      role='tabpanel'
      id={content.contentKey}
      aria-labelledby={`#${content.tabKey}`}
      {...props}
    >
      {children}
    </BaseTabContent>
  )
}

export type SwitchTabBadgeProps = Omit<BadgeProps, 'colorScheme'> & {
  colorScheme?: 'positivesat' | 'negative'
}

export const SwitchTabBadge = ({
  children,
  colorScheme,
  ...props
}: SwitchTabBadgeProps) => (
  <Badge
    colorScheme={colorScheme}
    pos='absolute'
    top='-1.5'
    right='0'
    {...props}
  >
    {children}
  </Badge>
)
