import { BoxProps, Center, HStack, Switch, Text } from '@chakra-ui/react'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import ChakraLib from 'presentation/libs/Chakra'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ReactNode } from 'react'

export const SwitchCard = ({
  title,
  value,
  onIsCheckedChange,
  isInDev,
  ...props
}: Omit<BoxProps, 'title'> & {
  title: string | ReactNode
  value?: boolean
  onIsCheckedChange?: (value: boolean) => void
  isInDev?: boolean
}) => (
  <Card
    variant='default'
    colorScheme='card.bg.1'
    size={mbp({
      mobSm: 'xxs-locked',
      tabSm: 'sm-locked',
    })}
    align='stretch'
    justify='center'
    bg={value ? 'neutral.200' : undefined}
    pos='relative'
    onClick={ev => {
      if (isInDev) return
      const isChakraSwitchClick = ChakraLib.isTargetChakraSwitch(ev.target)
      if (isChakraSwitchClick) return
      onIsCheckedChange?.(!value)
    }}
    {...props}
  >
    <CardHeader>
      <HStack>
        <CardSecondaryTitle
          justifySelf='flex-start'
          flex='1'
        >
          {title}
        </CardSecondaryTitle>
        <Switch
          colorScheme='default'
          isChecked={value}
          isDisabled={isInDev}
          onChange={() => {
            if (isInDev) return
            onIsCheckedChange?.(!value)
          }}
        />
      </HStack>
    </CardHeader>

    {isInDev && (
      <Center
        pos='absolute'
        top='0'
        left='0'
        right='0'
        bottom='0'
        bg='modal.blur'
        borderRadius={3}
        cursor='not-allowed'
      >
        <Text
          color='ondark.1'
          textStyle='bodyXLFat'
        >
          Currently in development!
        </Text>
      </Center>
    )}
  </Card>
)

export const makeWiredSwitchCard = ({
  title,
  displayNamePrefix,
  useApi,
  isInDev,
}: {
  title: string | ReactNode
  displayNamePrefix: string
  useApi: () => UseCriteriaHookApi<boolean | null>
  isInDev?: boolean
}) => {
  const WiredSwitchCard = (props: BoxProps) => {
    const { value, apply } = useApi()
    return (
      <SwitchCard
        title={title}
        value={!!value}
        onIsCheckedChange={() => apply(value ? null : true)}
        isInDev={isInDev}
        {...props}
      />
    )
  }

  WiredSwitchCard.displayName = `${displayNamePrefix}SwitchCard`

  return WiredSwitchCard
}
