import * as S from '@effect/schema/Schema'
import { Effect, Match, RequestResolver, pipe } from 'effect'
import { ListCriteria } from 'features/ListBuilder/domain/ListCriteria'
import { PropertyList } from 'features/ListBuilder/domain/ListSource'
import { ListPreview } from 'features/ListBuilder/domain/ListSource/ListPreview'
import { PropertyListData, PropertyListDataId } from 'features/ListBuilder/domain/ListSource/PropertyList'
import { POSTBuildPropertyListSchema } from 'features/ListBuilder/infra/repo/ListSourceRepoLive/BuildPropertyListResolver/POSTBuildPropertyListSchema'
import ListSourceRequest from 'features/ListBuilder/infra/repo/ListSourceRepoLive/ListSourceRequest'
import CriteriaSchema from 'features/ListBuilder/infra/repo/schema/CriteriaSchema'
import ParcelId from 'features/valueObjects/ParcelId'
import { restClient } from 'presentation/libs/client'
import UnitConverter from 'utils/UnitConverter'
import { isNonNullable } from 'utils/isNonNullable'

const BuildPropertyListResolver = RequestResolver.fromEffect(
  (request: ListSourceRequest.BuildList<PropertyList>) => pipe(
    Effect.sync(() => CriteriaSchema.encode(request.criteria)),
    Effect.flatMap(payload =>
      Effect.promise(async () => await restClient
        .post('parcels/v1/listbuilder/seller', { json: payload })
        .json()),
    ),
    Effect.map(raw => decode(raw)),
    Effect.map(decoded => toDomain(request.criteria, decoded)),
  ),
)

export default BuildPropertyListResolver

const decode = (response: unknown) =>
  S.decodeUnknownSync(POSTBuildPropertyListSchema)(response)

export const toDomain = (
  criteria: ListCriteria,
  schema: POSTBuildPropertyListSchema,
): ListPreview<PropertyList> => ({
  criteria,
  items: schema.items.map((item): PropertyListData => {
    const data: PropertyListData = {
      // #region App Info
      id: PropertyListDataId.from(item.id),
      parcelId: ParcelId.fromString(item.id),
      // #endregion

      // #region Address Info
      address: {
        line1: item.propertyAddressLine1 ?? null,
        city: item.propertyAddressCity ?? null,
        state: item.propertyAddressState ?? null,
        subdivision: '', // @TODO: where to get
        postalCode: item.propertyAddressZip ?? null,
      },
      location: isNonNullable(item.propertyAddressLat)
      && isNonNullable(item.propertyAddressLon)
        ? {
          latitude: item.propertyAddressLat,
          longitude: item.propertyAddressLon,
        }
        : null,
      // #endregion

      // #region Value Info
      lastSoldDate: item.lastSaleDate ?? null,
      estimatedValue: item.value ?? null,
      estimatedEquity: item.equity ?? null,
      listPrice: null,
      salePrice: item.lastSalePrice ?? null,
      // #endregion

      // #region Property Info
      useType: item.propertyUseType ?? null,
      // #endregion

      // #region Stats
      bedroomsCount: item.beds ?? null,
      bathroomsCountInfo: {
        full: item.bathsFull ?? null,
        half: item.bathsHalf ?? null,
        total: pipe(
          Match.value([item.bathsFull, item.bathsHalf]),
          Match.when([Match.number, undefined], ([full, _]) => `${full}`),
          Match.when([undefined, Match.number], ([_, half]) => `.${half}`),
          Match.when([Match.number, Match.number], ([full, half]) => `${full}.${half}`),
          Match.orElse(() => null),
        ),
      },
      garageSpacesCount: item.garageSpaces ?? null,
      buildingAreaSqft: null,
      livingAreaSqft: item.sqft ?? null,
      lotAreaSqft: item.lotSqft ?? null,
      lotAreaAcres: item.lotSqft
        ? UnitConverter.sqftToAcre(item.lotSqft)
        : null,
      yearBuilt: item.yearBuilt ?? null,
      // #endregion

      // #region Owner/Value Info
      ownerName: item.name ?? null,
      ownerAddress: {
        line1: item.addressLine1 ?? null,
        city: item.addressCity ?? null,
        state: item.addressState ?? null,
        postalCode: item.addressZip ?? null,
      },
      photos: [], // @TODO: get photos
      // #endregion

      // #region Owner/Value Info
      classification: null, // @TODO
      equityType: null,
      isVacant: item.isVacant ?? null,
      isForeclosure: item.isForeclosure ?? null,
      isSenior: item.isSenior ?? null,
      isHomestead: item.isHomestead ?? null,
      isTaxDelinquent: item.isTaxDelinquent ?? null,
      // #endregion

      // #region MLS Info
      mlsStatus: 'OFF_MARKET', /** @TODO */
      // #endregion
    }

    return data
  }),
})
