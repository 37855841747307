import * as S from '@effect/schema/Schema'
import { Match } from 'effect'
import { ListType } from 'features/ListBuilder/domain/MarketingList/ListType'

export type POSTSavePropertyListType = S.Schema.Type<typeof schema>

export const schema = S.Literal('seller', 'cashBuyer', 'privateLender')

export const toDomain = (encoded: POSTSavePropertyListType): ListType =>
  Match.value(encoded).pipe(
    Match.when('seller', (): ListType => 'property-lists'),
    Match.when('cashBuyer', (): ListType => 'cash-buyers'),
    Match.when('privateLender', (): ListType => 'private-lenders'),
    Match.exhaustive,
  )

export const POSTSavePropertyListType = {
  schema,
  toDomain,
}
