import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { PropertyMarker } from 'presentation/components/PropertyMarker/PropertyMarker'
import { EquityOnlyPropertyMarker } from 'presentation/components/PropertyMarker/components/EquityOnlyPropertyMarker/EquityOnlyPropertyMarker'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { CardCombo } from 'presentation/components/molecules/Card/CardCombo'
import { CardComboBottomSegment, CardComboTopSegment, Heading, MarkerLabelDescriptionPairs, MarkerLabelDescriptionPairsProps, MultipleIcon, PreforeclosureIcon, SavedIcon, VacantIcon } from 'presentation/screens/PropertyDetailsScreen/components/PropertyMarkerTraining/PropertyMarkerTrainingCard/components/common'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const IntroStep = () => {
  const equityMarkers: MarkerLabelDescriptionPairsProps[] = [{
    marker: (
      <EquityOnlyPropertyMarker
        equityType='FREE_AND_CLEAR'
        markerType='tag'
        size='lg'
      />
    ),
    label: <>FREE AND CLEAR</>,
    description: <>The property has no known active lients</>,
  }, {
    marker: (
      <EquityOnlyPropertyMarker
        equityType='HIGH'
        markerType='tag'
        size='lg'
      />
    ),
    label: <>HIGH EQUITY</>,
    description: <>Estimated more than 30% equity in the property</>,
  }, {
    marker: (
      <EquityOnlyPropertyMarker
        equityType='LOW'
        markerType='tag'
        size='lg'
      />
    ),
    label: <>LOW EQUITY</>,
    description: <>Estimated less than 30% equity in the property</>,
  }, {
    marker: (
      <EquityOnlyPropertyMarker
        equityType='UPSIDE_DOWN'
        markerType='tag'
        size='lg'
      />
    ),
    label: <>UPSIDE DOWN</>,
    description: <>There is likely no equity or negative equity</>,
  }]

  const scripts: MarkerLabelDescriptionPairsProps[] = [{
    marker: <PreforeclosureIcon />,
    label: <>PREFORECLOSURE</>,
    description: <>A document was filed indicating potential pending foreclosure</>,
  }, {
    marker: <VacantIcon />,
    label: <>VACANT</>,
    description: <>The USPS has flagged the property as vacant and not deliverable</>,
  }, {
    marker: <MultipleIcon />,
    label: <>MULTIPLE</>,
    description: <>The property has more than one stress indicator associated</>,
  }, {
    marker: <SavedIcon />,
    label: <>SAVED</>,
    description: <>The property has been added to a list</>,
  }]

  return (
    <>
      <Breakpoint
        mob={(
          <CardPrimaryTitle>Property Status</CardPrimaryTitle>
        )}
      />
      <CardPrimaryText>
        To enhance your property research, we&rsquo;ve assigned icons and symbols
        to indicate equity and ownership of your property. These markers and
        pin combinations will help you understand the property&rsquo;s state at a glance.
      </CardPrimaryText>

      <Heading mt={mbp({ mobSm: 2, mob: 3 })}>
        Equity Marker
      </Heading>

      <CardCombo
        size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
        mt={mbp({ mobSm: 2, mob: 3 })}
      >
        <CardComboTopSegment alignItems='center'>
          <Flex
            flexDirection={mbp({ mobSm: 'column', mob: 'row' })}
            gap={mbp({ mobSm: 1, mob: 2, tabSm: 3 })}
          >
            <PropertyMarker
              markerType='pin'
              size='md'
              classification='OWNER_OCCUPIED'
              equityType='FREE_AND_CLEAR'
              isVacant={false}
              isForeclosure={true}
              isSenior={true}
            />
            <PropertyMarker
              markerType='tag'
              size='lg'
              classification='OUT_OF_STATE'
              equityType='UPSIDE_DOWN'
              isVacant={false}
              isForeclosure={false}
              isSenior={false}
            />
          </Flex>
          <Text
            textStyle='bodyMFat'
            color='graystrong.600'
          >
            You can typically see the equity marker above the pins.
            It serves as a meter-like indicator describing the property&rsquo;s equity level.
          </Text>
        </CardComboTopSegment>
        <CardComboBottomSegment>
          <Grid
            gridTemplateColumns={mbp({
              mobSm: '1fr',
              mob: 'repeat(2, 1fr)',
            })}
            gap={3}
          >
            {equityMarkers.map((value, index) => (
              <MarkerLabelDescriptionPairs
                key={index}
                marker={value.marker}
                label={value.label}
                description={value.description}
              />
            ))}
          </Grid>
        </CardComboBottomSegment>
      </CardCombo>

      <Heading mt={mbp({ mobSm: 2, mob: 3, dtSm: 5 })}>
        Distressed Indication
      </Heading>

      <CardCombo
        size={mbp({ mobSm: 'xxs-locked', mob: 'xs-locked' })}
        mt={mbp({ mobSm: 2, mob: 3 })}
      >
        <CardComboTopSegment
          {...mbpg({
            mobSm: {
              flexDirection: 'column',
            },
            mob: {
              gap: 2,
            },
            tabSm: {
              flexDirection: 'row',
              alignItems: 'center',
              gap: 3,
            },
            dtSm: {
              gap: 4,
            },
          })

          }
        >
          <Flex gap={2} justifyContent='center'>
            <PropertyMarker
              markerType='pin'
              size='md'
              classification='CORPORATE'
              equityType='LOW'
              isVacant={false}
              isForeclosure={true}
              isSenior={false}
            />

            <PropertyMarker
              markerType='pin'
              size='md'
              classification='BANK'
              equityType='UPSIDE_DOWN'
              isVacant={true}
              isForeclosure={false}
              isSenior={false}
            />
            <PropertyMarker
              markerType='pin'
              size='md'
              classification='OUT_OF_COUNTRY'
              equityType='HIGH'
              isVacant={true}
              isForeclosure={true}
              isSenior={false}
            />

            <Box position='relative'>
              <PropertyMarker
                markerType='pin'
                size='md'
                classification='TRUST'
                equityType='FREE_AND_CLEAR'
                isVacant={false}
                isForeclosure={true}
                isSenior={false}
              />
              {/** @TODO: temporarily add a new PropertyMarker part when it is clear
             * if it really belongs to the PropertyMarker component.
             */}
              <SavedIcon
                boxSize={2.5}
                position='absolute'
                bottom='-4px'
                right='-4px'
              />
            </Box>
          </Flex>
          <Text
            textStyle='bodyLFat'
            color='graystrong.600'
            textAlign={mbp({ mobSm: 'left', mob: 'center', tabSm: 'left' })}
          >
            You can typically see the equity marker above the pins.
            It serves as a meter-like indicator describing the property&rsquo;s equity level.
          </Text>
        </CardComboTopSegment>
        <CardComboBottomSegment>
          <Grid
            gridTemplateColumns={mbp({
              mobSm: '1fr',
              mob: 'repeat(2, 1fr)',
              dtSm: 'repeat(4, 1fr)',
            })}
            gap={3}
          >
            {scripts.map((value, index) => (
              <MarkerLabelDescriptionPairs
                key={index}
                marker={value.marker}
                label={value.label}
                description={value.description}
              />
            ))}
          </Grid>

        </CardComboBottomSegment>
      </CardCombo>

    </>
  )
}
