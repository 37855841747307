import { Array, Match, String, pipe } from 'effect'
import { CMA } from 'features/CMA/CMA.domain'
import { HomeType } from 'features/CMA/valueObjects/HomeType'
import { NumberLib } from 'libs/Number'
import { compsBoundaryToGeoJSON } from 'presentation/screens/CompsScreen/components/CompsBoundary/compsBoundaryToGeoJSON'

export type LegacyFiltersRange = {
  min?: number
  max?: number
}

export type LegacyFilters = {
  months: number
  range: string
  beds?: LegacyFiltersRange
  baths_full?: LegacyFiltersRange
  sqft?: LegacyFiltersRange
  year_built?: LegacyFiltersRange
  garage?: LegacyFiltersRange
  lot_size_acres?: LegacyFiltersRange
  pool?: number
  address?: {
    subdivision?: string
  }
  propertyTypePresets?: string[]
}

const SQFT_PER_ACRE = 43560

/** @TODO subdivision */
export const mapFiltersToLegacy = (filters: CMA.Filters): LegacyFilters => {
  const geojson = (
    filters.boundary
    && compsBoundaryToGeoJSON(filters.boundary)
  ) ?? undefined

  const legacyFilters = {
    propertyTypePresets: filters.homeType
      ? pipe(
        filters.homeType.types,
        Array.map(pipe(
          Match.type<HomeType>(),
          Match.when('commercial', () => 'COMMERCIAL'),
          Match.when('condo', () => 'CONDO'),
          Match.when('duplex-to-fourplex', () => 'MULTI_PLEX'),
          Match.when('farm', () => 'FARM'),
          Match.when('lot-or-land', () => 'LOT_LAND'),
          Match.when('mobile', () => 'MOBILE_HOME'),
          Match.when('multi-family', () => 'MULTI_FAMILY'),
          Match.when('single-family', () => 'SINGLE_FAMILY'),
          Match.when('town-house', () => 'TOWN_HOUSE'),
          Match.orElse(() => ''),
        )),
        Array.filter(String.isNonEmpty),
      )
      : [],
    months: filters.soldWithinMonths.max,
    range: filters.distanceMiles.max.toString(),
    beds: {
      min: NumberLib.orUndefined(filters.bedroomsCount?.range?.[0]),
      max: NumberLib.orUndefined(filters.bedroomsCount?.range?.[1]),
    },
    baths_full: {
      min: NumberLib.orUndefined(filters.bathroomsCount?.range?.[0]),
      max: NumberLib.orUndefined(filters.bathroomsCount?.range?.[1]),
    },
    sqft: {
      min: NumberLib.orUndefined(filters.livingAreaSqft?.range?.[0]),
      max: NumberLib.orUndefined(filters.livingAreaSqft?.range?.[1]),
    },
    year_built: {
      min: NumberLib.orUndefined(filters.yearBuilt?.range?.[0]),
      max: NumberLib.orUndefined(filters.yearBuilt?.range?.[1]),
    },
    garage: {
      min: NumberLib.orUndefined(filters.garageSpacesCount?.range?.[0]),
      max: NumberLib.orUndefined(filters.garageSpacesCount?.range?.[1]),
    },
    lot_size_acres: {
      min: pipe(
        filters.lotAreaSize?.sqftRange?.[0],
        NumberLib.ifValid(sqft => sqft / SQFT_PER_ACRE),
        NumberLib.orUndefined,
      ),
      max: pipe(
        filters.lotAreaSize?.sqftRange?.[1],
        NumberLib.ifValid(sqft => sqft / SQFT_PER_ACRE),
        NumberLib.orUndefined,
      ),
    },
    pool: filters.poolsCount?.availablility === 'yes' ? 1 : undefined,
    address: filters.distanceMiles.subdivision
      ? {
        subdivision: filters.distanceMiles.subdivision,
      }
      : undefined,
    geojson,
  }

  Object.keys(legacyFilters).forEach(keyRaw => {
    const key = keyRaw as keyof LegacyFilters
    const value = legacyFilters[key]

    if (value === undefined)
      delete legacyFilters[key]

    const isPropertyEmptyObject = typeof value === 'object'
      && Object.entries(value).every(([_, v]) => v === undefined)

    if (isPropertyEmptyObject)
      delete legacyFilters[key]
  })

  return legacyFilters
}
