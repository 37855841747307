import { Option } from 'effect'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { Address } from 'features/valueObjects/Address'
import { BathroomsCountInfo } from 'features/valueObjects/BathroomsCountInfo'
import { EquityType } from 'features/valueObjects/EquityType'
import { Location } from 'features/valueObjects/Location'
import { MLSListing } from 'features/valueObjects/MLSListing'
import { MLSOrganization as ImportedMLSOrganization } from 'features/valueObjects/MLSOrganization'
import { OwnerClassification } from 'features/valueObjects/OwnerClassification'
import { PersonName } from 'features/valueObjects/PersonName'
import { Photo as CommonPhoto } from 'features/valueObjects/Photo'
import { PropelioEstimateStats } from 'features/valueObjects/PropelioEstimateStats'
import { PurchaseMethod } from 'features/valueObjects/PurchaseMethod'
import { ProgramException } from 'libs/errors'
import { ID } from 'libs/id'
import { DeepPartial } from 'react-hook-form'
import { HomeCondition as ImportedHomeCondition } from './HomeCondition'

export namespace PropertyDetails {
  // =============================================================================
  // Entities & Value Objects
  // =============================================================================

  // =============================================================================
  // Value Object - Ownership
  // =============================================================================
  /** @TODO GROUP ENTITY AND INFO */
  export type Ownership = {
    owners: Owner[]
    /**
     * @TODO How to determine if the owners are landlord? Or do we just use the
     *   owner classification?
     */
    classification: OwnerClassification | null
    months: number | null
    address: Address | null
    isVacant: boolean | null
    isSenior: boolean | null
    isForeclosure: boolean | null
    potentialPropertiesCount: Option.Option<number>
  }

  export type Owner = {
    name: PersonName
  }

  // =============================================================================
  // Value Object - Building
  // =============================================================================
  export type Building = {
    bathroomsCount: BathroomsCountInfo | null
    bedroomsCount: number | null
    garageSpacesCount: number | null
    roomsCount: number | null
    fireplacesCount: number | null
    storiesCount: number | null

    /** @TODOIMPORTANT change to boolean */
    hasPool: boolean | null

    buildingAreaSqft: number | null
    livingAreaSqft: number | null
    basementAreaSqft: number | null
    garageAreaSqft: number | null

    yearBuilt: string | null
    basementType: string | null
    heatingType: string | null
    heatingFuelType: string | null
  }

  // =============================================================================
  // Value Object - Land
  // =============================================================================
  export type Land = {
    legalDescription: string | null
    assessorParcelNumber: string | null
    standardizedUseClass: string | null
    standardizedUseType: string | null
    censusTract: string | null
    lotAreaSqft: number | null
    lotAreaAcres: number | null
    lot: string | null
    block: string | null
    subdivision: string | null
    county: string | null
  }

  // =============================================================================
  // Value Object - Valuation
  // =============================================================================
  export type Valuation = {
    equity: number | null
    equityType: EquityType | null
    loanToValue: number | null
    estimateRange: PropelioEstimateStats | null
  }

  // =============================================================================
  // Value Object - Transfer
  // =============================================================================
  export type Transfer = {
    recordingDate: Date | null
    saleDate: Date | null
    salePrice: number | null

    documentNumber: string | null
    documentType: string | null
    purchaseMethod: PurchaseMethod | null
    transactionType: string | null

    buyerInfo: Transfer.BuyerInfo | null
    sellerInfo: Transfer.SellerInfo | null

    liens: Lien[]
  }

  export namespace Transfer {
    export type BuyerInfo = {
      buyers: Buyer[]
      address: Address | null
    }

    export type Buyer = {
      name: string
    }

    export type SellerInfo = {
      sellers: Seller[]
      address: Address | null
    }

    export type Seller = {
      name: string
    }
  }

  // =============================================================================
  // Value Object - Preforeclosure
  // =============================================================================

  /**
   * Preforeclosure is a stage in the real estate process when a homeowner has
   * failed to pay their mortgage, giving them a final chance to either settle
   * their debts, renegotiate loan terms, or sell their property before the
   * lender takes ownership to recover their money.
   */
  export type Preforeclosure = {
    document: Preforeclosure.Document
    auction: Preforeclosure.Auction | null
    lien: Preforeclosure.Lien | null
  }

  export namespace Preforeclosure {
    export type Document = {
      recordingDate: Date | null
      type: string | null
      defaultAmount: number | null
      documentNumber: string | null
      caseNumber: string | null
      trusteeInfo: TrusteeInfo
    }

    export type Auction = {
      date: Date | null
      time: string | null
      openingBid: number | null
      location: string | null
    }

    /**
     * Lien is a legal claim or a "right of holding" on an asset, used as
     * collateral to secure a loan, that entitles a lender to take possession of
     * the asset if the borrower fails to pay the loan.
     */
    export type Lien = {
      recordingDate: Date | null
      documentNumber: string | null
      originalAmount: number | null
      servicer: Lien.Servicer | null
      holder: Lien.Holder | null
    }

    export namespace Lien {
      export type Servicer = {
        name: string | null
        address: Address | null
      }

      export type Holder = {
        name: string | null
      }
    }

    export type TrusteeInfo = {
      names: string[]
      address: Address | null
      phone: string | null
    }
  }

  // =============================================================================
  // Value Object - Lien
  // =============================================================================
  export type Lien = {
    isActive: boolean | null
    documentNumber: string | null
    recordingDate: Date | null
    mortgageType: string | null
    holder: LienHolder | null
    amount: number | null
    estimatedBalance: number | null
    estimatedPayment: number | null
    termMonths: number | null
    interestRatePercent: number | null
    maturityDate: Date | null
  }

  export type LienHolder = {
    name: string | null
    address: Address | null
  }

  // =============================================================================
  // Value Object - Taxation
  // =============================================================================
  export type Taxation = {
    year: string | null
    billed: number | null
    assessment: Taxation.Assessment | null
    exemptions: Taxation.Exemption[]
  }

  export namespace Taxation {
    export type Exemption = 'senior' | 'military'

    export namespace Exemption {
      export const format = (exemption: Exemption): string =>
        exemption === 'senior' ? 'Senior' : 'Military'
    }

    export type Assessment = {
      total: number | null
      improvements: number | null
      land: number | null
    }
  }

  // =============================================================================
  // Value Object - MLS
  // =============================================================================
  /**
   * @TODO Comps and Prop Details should just share one Comp value object
   */
  // export type MLSListing = {
  //   address: {
  //     line1: string | null
  //     city: string | null
  //     state: string | null
  //     postalCode: string | null
  //     subdivision: string | null
  //   }
  //   location: Location | null

  //   // listing info
  //   organization: MLSOrganization
  //   number: string | null
  //   listDate: Date | null
  //   isRental: boolean
  //   status: MLSStatus | null
  //   daysOnMarket: number | null
  //   photos: Photo[]
  //   description: string | null

  //   // price info
  //   salePrice: number | null
  //   saleDate: Date | null
  //   listPrice: number | null
  //   priceChange: number | null

  //   // list agent info
  //   mlsInfo: MLSInfo
  //   /** @deprecated Please use mlsInfo property instead */
  //   listAgentFullName: string | null
  //   /** @deprecated Please use mlsInfo property instead */
  //   listAgentEmail: string | null
  //   /** @deprecated Please use mlsInfo property instead */
  //   listAgentPreferredPhone: string | null
  //   /** @deprecated Please use mlsInfo property instead */
  //   listAgentDirectPhone: string | null

  //   // list office info
  //   /** @deprecated Please use mlsInfo property instead */
  //   listOfficeName: string | null
  //   /** @deprecated Please use mlsInfo property instead */
  //   listOfficeEmail: string | null
  //   /** @deprecated Please use mlsInfo property instead */
  //   listOfficePhone: string | null

  //   // physical info
  //   bathroomsCountInfo: BathroomsCountInfo | null
  //   bedroomsCount: number | null
  //   buildingAreaSqft: number | null
  //   garageSpacesCount: number | null
  //   lotAreaAcres: number | null
  //   lotAreaSqft: number | null

  //   // property info
  //   propertyType: string | null
  //   yearBuilt: number | null
  // }

  export type MLSOrganization = ImportedMLSOrganization

  export type Photo = CommonPhoto

  export type HomeCondition = ImportedHomeCondition

  // =============================================================================
  // Entity - Property
  // =============================================================================

  export type CommonPropertyFields = {
    /**
     * At the moment this is the same as leadId
     */
    id: ID

    /**
     * Lead is a legacy entity that corresponds to what is search history item right now.
     * It's identifier is also used to access the property information
     * @legacy
     */
    leadId: ID

    address: Address
    location: Location | null

    userInputData: UserInputData
    originalData: UserInputData
  }

  export type PropertyWithDetails = CommonPropertyFields & {
    /**
     * Under the hood this corresponds to parcel id, a Parcel is a more
     * up to date GraphQL concept
     */
    parcelId: string

    status: 'with-details'
    ownership: Ownership | null
    building: Building | null
    land: Land | null
    valuation: Valuation | null
    lastSale: Transfer | null
    mlsListings: MLSListing[]
    condition: HomeCondition | null
    preforeclosures: Preforeclosure[]
    transfers: Transfer[]
    currentLiens: Lien[]
    taxation: Taxation
  }

  export type PropertyWithoutDetails = CommonPropertyFields & {
    status: 'without-details'
  }

  export type Property = PropertyWithDetails | PropertyWithoutDetails

  // =============================================================================
  // Value Object - EditableData
  // =============================================================================
  export type EditableData = {
    building: Pick<Building, 'livingAreaSqft'> | null
  }

  export type UserInputData = EditableData
  export type OriginalData = EditableData

  // =============================================================================
  // Value Object - Property Suggestion
  // =============================================================================

  // @TODO Do all thise score/exactl/close/fuzzy concept belong in domain?
  //   or are these implementation details that does not belong here?

  export type PropertySuggestionType = 'exact' | 'close' | 'fuzzy'

  export const EXACT_SUGGESTION_SCORE = 1.2
  export const CLOSE_SUGGESTION_SCORE = 1.1
  export type PropertySuggestion = {
    addressString: string
    parcelId: string
    /**
     * 1.2 are exact results
     * 1.1 are close results
     * 1 and less are fuzzy results
     */
    score: number
  }

  // =============================================================================
  // Command and Queries
  // =============================================================================

  /**
   * Search History entity is a Lead entity within the legacy app. Route ID
   * (e.g. search/123) and search history item links is based on Lead ID
   */
  export type GetPropertyLeadIdPayload = { leadId: ID }
  /**
   * AKA Parcel ID, Parcel is the entity which contains the property details.
   * Loading from suggestions relies on Parcel ID.
   */
  export type GetPropertyParcelIdPayload = { parcelId: string }
  /**
   * When hitting enter from search input without selecting a suggestion,
   * we attempt to search (or create new lead under the hood) by only using
   * the address query (which is processed into address by Google API)
   */
  export type GetPropertyAddressStringPayload = { addressString: string }
  export type GetPropertyPayload =
    | GetPropertyLeadIdPayload
    | GetPropertyParcelIdPayload
    | GetPropertyAddressStringPayload
  export type InsufficientConsumables = {
    status: 'insufficient-consumables'
    attempt: ConsumableAttempt.ConsumableAttemptWithoutPrice
  }
  export type GetPropertyResult = Property | InsufficientConsumables
  export type GetPropertyError = ProgramException

  export type EditPropertyPayload = DeepPartial<EditableData> & { leadId: ID }
  export type EditPropertyResult = void
  export type EditPropertyError = ProgramException

  export type GetPropertySuggestionsPayload = { addressString: string }
  export type GetPropertySuggestionsResult = PropertySuggestion[]
  export type GetPropertySuggestionsError = ProgramException

  // =============================================================================
  // Constants
  // =============================================================================
  export const EMPTY_OWNERSHIP: Ownership = {
    owners: [],
    classification: null,
    months: null,
    address: null,
    isVacant: null,
    isSenior: null,
    isForeclosure: null,
    potentialPropertiesCount: Option.none(),
  }

  export const EMPTY_VALUATION: Valuation = {
    equity: null,
    equityType: null,
    loanToValue: null,
    estimateRange: null,
  }
}
