import { NetworkStatus, useQuery } from '@apollo/client'
import { gql } from '__generated__'
import { last, noop } from 'lodash/fp'
import { useToastError } from 'presentation/hooks/useToastError'
import { is } from 'presentation/utils/graphql'

export const useAccountBalanceTable = () => {
  const { data, error, networkStatus, ...query } = useQuery(ACCOUNT_BALANCE_TABLE__GET_WALLET_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
  })

  useToastError({
    error,
    title: 'We couldn’t load your wallet transactions.',
  })

  const transactionsConnection = data?.myEnterprise?.wallet?.transactions
  const transactionEdges = transactionsConnection?.edges.filter(isTransactionEdge)

  const dataHasNextPage = transactionsConnection?.pageInfo.hasNextPage
  const lastCursor = (transactionEdges && last(transactionEdges)?.cursor) || null
  const canFetchMore = !!dataHasNextPage && !!lastCursor

  const fetchMore = (): void => {
    query.fetchMore({
      variables: {
        cursor: lastCursor,
      },
    })
      .catch(noop) // error is handled from hook
  }

  return {
    transactions: transactionEdges,
    isReady: !!data,
    canFetchMore,
    fetchMore,
    isFetchingMore: networkStatus === NetworkStatus.fetchMore,
  }
}

export const ACCOUNT_BALANCE_TABLE__GET_WALLET_TRANSACTIONS = gql(/* GraphQL */ `
  query FundsTable_GetWalletTransactions($cursor: String) {
    myEnterprise {
      id
      wallet {
        transactions(first: 10, after: $cursor) {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              ...FundHistoryRow__TransactionFragment
            }
          }
        }
      }
    }
  }
  `,
)

const isTransactionEdge = is('WalletTransactionEdge' as const)
