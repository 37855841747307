import { Grid } from '@chakra-ui/react'
import { useBathroomsCount, useBedroomsCount, useLivingAreaSqft, useLotAreaSize, useYearBuilt } from 'features/ListBuilder/infra/react/ListBuilderStore'
import { UseCriteriaHookApi } from 'features/ListBuilder/infra/react/ListBuilderStore/hooks/common'
import { PartialRange } from 'features/valueObjects/Range'
import { NumberLib } from 'libs/Number'
import { Card, CardHeader, CardSecondaryTitle } from 'presentation/components/molecules/Card'
import { FormValidators } from 'presentation/screens/Billing/components/WalletAddBalanceFlow/formValidators'
import { ListBuilderRangeField, ListBuilderRangeFieldProps } from 'presentation/screens/ListBuilderScreen/components/ListBuilderRangeField/ListBuilderRangeField'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { isNumber } from 'remeda'

export const PropertyFeaturesCard = () => (
  <Card
    variant='default'
    colorScheme='card.bg.1'
    size={mbp({
      mobSm: 'xxs-locked',
      tabSm: 'sm-locked',
    })}
  >
    <CardHeader>
      <CardSecondaryTitle justifySelf='flex-start'>
        Property Feature
      </CardSecondaryTitle>

      <Grid
        mt={2}
        gridTemplate={mbp({
          mobSm: 'auto / repeat(1, 1fr)',
          tabSm: 'auto / repeat(2, 1fr)',
          dtLg: 'repeat(2, auto) / repeat(3, 1fr)',
        })}
        rowGap={4}
        gridAutoFlow={mbp({
          mobSm: 'unset',
          dtLg: 'column',
        })}
        gap={4}
      >
        <BedsField />
        <BathsField />
        <YearBuiltField />
        <HouseSizeField />
        <LotSizeField />
      </Grid>
    </CardHeader>
  </Card>
)

const makeRangeField = ({
  label,
  displayNamePrefix,
  useApi,
  validate,
  precision,
  isInDev,
  formatInputDisplay = v => {
    const parsed = isNumber(v) ? v : NumberLib.fromStringSafe(v)

    if (parsed === null) return ''

    return NumberLib.formatComma(parsed)
  },
}: {
  label: string
  displayNamePrefix: string
  useApi: () => UseCriteriaHookApi<PartialRange>
  validate?: ListBuilderRangeFieldProps['validate']
  precision?: ListBuilderRangeFieldProps['precision']
  isInDev?: boolean
  formatInputDisplay?: ListBuilderRangeFieldProps['formatInputDisplay']
}) => {
  const RangeField = () => {
    const api = useApi()
    return (
      <ListBuilderRangeField
        isInDev={isInDev}
        label={label}
        initialValue={api.value}
        onValidBlur={api.apply}
        validate={validate}
        precision={precision}
        formatInputDisplay={formatInputDisplay}
      />
    )
  }

  RangeField.displayName = `${displayNamePrefix}Field`

  return RangeField
}

const BedsField = makeRangeField({
  label: 'Beds',
  displayNamePrefix: 'Beds',
  useApi: useBedroomsCount,
})

const BathsField = makeRangeField({
  label: 'Baths',
  displayNamePrefix: 'Baths',
  useApi: useBathroomsCount,
})

// const GarageField = makeRangeField({
//   label: 'Garage',
//   displayNamePrefix: 'Garage',
//   useApi: useGarageSpacesCount,
//   isInDev: true,
// })

const YearBuiltField = makeRangeField({
  label: 'Year Built',
  displayNamePrefix: 'YearBuilt',
  useApi: useYearBuilt,
  validate: FormValidators.validateYear(),
  formatInputDisplay: v => {
    const parsed = isNumber(v) ? v : NumberLib.fromStringSafe(v)

    if (parsed === null) return ''

    return parsed
  },
})

const HouseSizeField = makeRangeField({
  label: 'House Size Sqft',
  displayNamePrefix: 'HouseSize',
  useApi: useLivingAreaSqft,
})

const LotSizeField = () => {
  const api = useLotAreaSize()
  return (
    <ListBuilderRangeField
      label='Lot Size Sqft'
      initialValue={api.value.range}
      onValidBlur={range => {
        api.apply({ range, unit: api.value.unit })
      }}
      // upperRightElement={(
      //   <LotSizeSwitch
      //     value={api.value.unit}
      //     onToggle={() => {
      //       api.apply({
      //         range: api.value.range,
      //         unit: api.value.unit === 'sqft' ? 'acres' : 'sqft',
      //       })
      //     }}
      //   />
      // )}
      // precision={api.value.unit === 'sqft' ? 0 : 3}
      precision={0}
    />
  )
}
