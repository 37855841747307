import { SystemStyleObject, useToken } from '@chakra-ui/react'
import { CMA } from 'features/CMA/CMA.domain'

export const useRowSx = ({
  comp,
  isOpen,
}: {
  comp: CMA.SingleComp | CMA.SubjectComp
  isOpen: boolean
}): SystemStyleObject | undefined => {
  const subjectBgHex = useToken('colors', 'special.50')
  const nonSubjectBgHex = useToken('colors', 'highlight.100')
  const bgHex = comp.type === 'subject-comp'
    ? subjectBgHex
    : isOpen
      ? nonSubjectBgHex
      : null

  const color = useToken('colors', 'graystrong.600')

  return {
    td: {
      ...bgHex && { bg: `${bgHex} !important` },
      color: `${color} !important`,
    },
  }
}
