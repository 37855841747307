import { Box, Fade, Flex, Text } from '@chakra-ui/react'
import { useMouseEnterLeaveHover } from 'presentation/hooks/useMouseEnterLeaveHover'
import { Color } from 'presentation/libs/chakra/chakra.types'
import { ComponentType, FC, ReactElement, useRef } from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

type Props = {
  Icon?: ComponentType
  to?: string
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  color?: Color
  name: string
  iconReplacement?: ReactElement
}

export const SidebarDtItem: FC<Props> = ({
  Icon,
  to = '',
  color = 'ondark.1',
  name,
  iconReplacement,
  onClick,
}) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const isCurrentRoute = to && match !== null
  const hoverRef = useRef<HTMLDivElement | null>(null)
  const { isHovered, onMouseEnter, onMouseLeave } = useMouseEnterLeaveHover()

  const handleClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Prevents duplicate call of onClick from both main element and hover element
    ev.stopPropagation()

    onClick?.(ev)
  }

  return (
    <Flex

      ref={hoverRef as any}
      h={5}
      align='center'
      justify='flex-start'
      pos='relative'
      zIndex='popover'
      pl={2.5}
      cursor='pointer'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...(onClick
        ? { as: 'a', onClick: handleClick }

        : { as: Link, to } as any)}
    >
      {iconReplacement || (
        <Box as={Icon} boxSize={3} color={isCurrentRoute ? 'highlight.500' : color} />
      )}

      <Fade in={isHovered} unmountOnExit>
        <Flex
          align='center'
          justify='flex-start'
          zIndex='popover-1'
          pl={2.5}
          bg='specialsat.500'
          borderEndRadius='full'
          cursor='pointer'
          transition='background 0.1s ease-in-out'
          pos='absolute'
          top='0'
          bottom='0'
          left='0'
          right='-120px'
          gap='2'
        >
          {iconReplacement || (
            <Box as={Icon} boxSize={3} color='highlight.500' />
          )}

          <Text as='span' color='highlight.500' textStyle='panel-title'>
            {' '}
            {name}
          </Text>
        </Flex>
      </Fade>
    </Flex>
  )
}
