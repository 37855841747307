import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'

export const FiltersDisabledAlert = () => (
  <Alert
    colorScheme='neutral'
    variant='ghost'
  >
    <AlertIcon>
      <RegularCircleExclamationIcon />
    </AlertIcon>
    <AlertDescription>
      Editing filters is disabled when viewing the report
    </AlertDescription>
  </Alert>
)
