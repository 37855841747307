import { Box, BoxProps, Button, ButtonProps, Center, Collapse, Flex, FlexProps, Image, Spinner, TableHeadProps, Text } from '@chakra-ui/react'
import cn from 'classnames'
import { isBoolean } from 'lodash/fp'
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import runningDogPng from 'presentation/assets/img/3d/running-dog.png'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { ChevronDownIcon, ChevronUpIcon } from 'presentation/components/Icons'
import { Card } from 'presentation/components/molecules/Card'
import { TAB_SWITCH_WRAPPER_CLASS } from 'presentation/components/molecules/SwitchTabs/SwitchTabs'
import { useMobFirstBreakpointValue } from 'presentation/hooks/useMobFirstBreakpointValue'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ComponentProps, FC, MouseEventHandler, PropsWithChildren, ReactNode, createContext, forwardRef, useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from 'react'
import { isFunction } from 'remeda'

const TH_HEIGHT = '5'
const HAS_TABLE_ACTIONS_CLASS = 'has-table-actions'
const TABLE_GROUP_CLASS = 'table-group'
const IS_TABLE_HEAD_HIDDEN_CLASS = 'is-table-head-hidden'
const IS_EXPAND_BUTTON_HIDDEN_CLASS = 'is-expand-button-hidden'

// =============================================================================
// Table Group
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableGroup: FC<BoxProps> = ({ children, className, ...props }) => (
  <Flex
    // Dimensions
    h={mbp({ mobSm: 'auto', tabSm: '488px' })}

    // Flows and alignments
    flexDir='column'
    overflow='clip' // do not use hidden as it breaks position sticky elements inside

    // Visual styles
    borderRadius='2'
    bg={mbp({ mobSm: 'card.bg.blue', tabSm: 'grayweak.100' })}
    boxShadow='primary.w'

    className={cn(TABLE_GROUP_CLASS, className)}

    sx={{
      // No curve on topLeft if it's inside a Tabs
      [`.${TAB_SWITCH_WRAPPER_CLASS} &`]: {
        borderTopLeftRadius: 0,
      },

      // When TableGroup is present, we want to remove the duplicate shadow of the table
      '& table': {
        boxShadow: 'none',
      },
    }}
    {...props}
  >
    {children}
  </Flex>
)

// =============================================================================
// Table Scroller
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableScroller = forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => {
  const commonProps = {
    // Dimensions
    minH: TH_HEIGHT,

    // Flows and alignments
    flex: mbp({
      mobSm: '1 0 auto',
      tabSm: '1 0 0',
    }),
    overflow: 'clip',

    sx: {
      // No curve on topLeft if it's inside a Tabs
      [`.${TAB_SWITCH_WRAPPER_CLASS} &`]: {
        borderTopLeftRadius: 0,
      },
    },

    ...props,
  }

  return (
    <Breakpoint
      mobSm={(
        <Box {...commonProps}>
          {children}
        </Box>
      )}
      tabSm={(
        <Box
          as={OverlayScrollbarsComponent}
          ref={osInstance => {
            if (!ref || osInstance === null) return
            const scrollingEl = (osInstance as any as OverlayScrollbarsComponentRef)
              .getElement()
              ?.querySelector('.os-viewport, [data-overlayscrollbars-contents]') as HTMLDivElement

            if (!scrollingEl) return
            if (isFunction(ref)) ref(scrollingEl)
            if ('current' in ref) ref.current = scrollingEl
          }}
          zIndex='sticky-1'
          {...commonProps}
          overflow='auto'
        >
          {children}
        </Box>
      )}
    />
  )
})

TableScroller.displayName = 'TableScroller'

// =============================================================================
// Table Actions
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableActions: FC<FlexProps> = ({ children, ...props }) => {
  /** When there's TableActions, we should remove the rounded lower corners of table above it */
  const ref = useRef<HTMLDivElement>(null)

  /** Add HAS_TABLE_ACTIONS_CLASS to table group for styling */
  useEffect(() => {
    const parentTableGroup = ref.current
      && findAncestor(ref.current, `.${TABLE_GROUP_CLASS}`)

    if (!parentTableGroup) return

    parentTableGroup.classList?.add(HAS_TABLE_ACTIONS_CLASS)

    return () => {
      parentTableGroup.classList?.remove(HAS_TABLE_ACTIONS_CLASS)
    }
  }, [])

  return (
    <Flex
      ref={ref}

      // Dimensions
      pb={mbp({ mobSm: 2, tabSm: 0 })}
      mt={mbp({
        mobSm: -1, // Offsets spacer from last row on mobile
        tabSm: 0,
      })}

      // Flows and alignments
      justifyContent='center'

      // Position
      position='relative'
      zIndex='sticky-2' // Just to go above tbody to display boxShadow

      // Visual styles
      borderBottomRadius='2'
      boxShadow={mbp({ mobSm: 'none', tabSm: 'vscroll-fade-bottom' })}

      {...props}
    >
      {children}
    </Flex>
  )
}

// =============================================================================
// Table Button
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableActionButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button
    variant='ghost'
    colorScheme='neutral'
    h='5'
    sx={{ color: mbp({ mobSm: 'ondark.1', tabSm: 'neutral.500' }) }}
    {...props}
  >
    {children}
  </Button>
)

// =============================================================================
// Table
// =============================================================================

type TableContextValue = {
  isReady: boolean
  columnsRef: { current: {
    id: string
    isBigScreenOnly?: boolean
  }[] }
  shouldHideExpandButton: boolean
  registerColumn: (opts: { id: string, isBigScreenOnly?: boolean }) => void
  countColumns: (opts: { isBigScreenOnly?: boolean }) => number
}

const TableContext = createContext<TableContextValue>({
  isReady: false,
  columnsRef: { current: [] },
  shouldHideExpandButton: false,
  registerColumn: () => {},
  countColumns: () => 0,
})

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type TableProps = BoxProps & ComponentProps<'table'> & {
  shouldHideExpandButton?: boolean
}

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Table: FC<
  PropsWithChildren<
    BoxProps
    & ComponentProps<'table'>
    & { shouldHideExpandButton?: boolean }
  >
> = ({ children, className, shouldHideExpandButton = false, ...props }) => {
  const [isReady, setIsReady] = useState(false)
  const columnsRef = useRef<{ id: string, isBigScreenOnly?: boolean }[]>([])

  /**
   * Registers column so that we can count columns later for colSpan stuff
   */
  const registerColumn = useCallback(({ id: columnId, isBigScreenOnly }: {
    id: string
    isBigScreenOnly?: boolean
  }) => {
    columnsRef.current = columnsRef.current.find(({ id }) => id === columnId)
      ? columnsRef.current
      : [...columnsRef.current, { id: columnId, isBigScreenOnly }]
  }, [])

  /**
   * Count the number of columns in the table.
   * If isBigScreenOnly is true, count only columns that are big screen only
   * otherwise, count all columns.
   */
  const countColumns = useCallback(({ isBigScreenOnly }: {
    isBigScreenOnly?: boolean
  }) =>
    columnsRef.current
      .filter(col => isBigScreenOnly ? col.isBigScreenOnly : true)
      .length,
  [])

  const contextValue = useMemo(() => ({
    isReady,
    shouldHideExpandButton,
    columnsRef,
    registerColumn,
    countColumns,
  }), [
    isReady,
    shouldHideExpandButton,
    columnsRef,
    registerColumn,
    countColumns,
  ])

  /**
   * After mounting the component, all columns would have been registered.
   */
  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <TableContext.Provider value={contextValue}>
      <Box
        as='table'
        className={cn(shouldHideExpandButton && IS_EXPAND_BUTTON_HIDDEN_CLASS, className)}
        boxShadow='primary.w'
        borderRadius='2'
        w='full'
        {...props}
      >
        {children}
      </Box>
    </TableContext.Provider>
  )
}

// =============================================================================
// Table Section Context
// =============================================================================
type TableSectionContextValue = {
  section: 'thead' | 'tbody'
}

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableSectionContext = createContext<TableSectionContextValue>({
  section: 'tbody',
})

// =============================================================================
// Table Head
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type TheadProps = PropsWithChildren<TableHeadProps & {
  isBigScreenOnly?: boolean
}>

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Thead: FC<TheadProps> = ({ children, isBigScreenOnly, className, ...props }) => {
  const isBigScreen = useSwitchBreakpoint({ mobSm: false, tabSm: true })
  const shouldHideThead = !isBigScreen && isBigScreenOnly

  return (
    <TableSectionContext.Provider value={{ section: 'thead' }}>
      <Box
        as='thead'
        pos='sticky'
        top='0'
        zIndex='sticky-1'
        className={cn(shouldHideThead && IS_TABLE_HEAD_HIDDEN_CLASS, className)}

        {...shouldHideThead && {
          // We're trying to hide the thead, but we still want to render it
          // We still want to render it so that we don't break column widths
          sx: {
            'h': 0,
            'minH': 0,
            'maxH': 0,
            'oveflow': 'hidden',
            '& th, & tr': {
              h: 0,
              minH: 0,
              maxH: 0,
              p: 0,
              overflow: 'hidden',
              fontSize: 0,
              lineHeight: 0,
            },
          },
        }}
        {...props}
      >
        {children}
      </Box>
    </TableSectionContext.Provider>
  )
}

// =============================================================================
// Table Body
// =============================================================================

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type TbodyProps = PropsWithChildren<BoxProps & ComponentProps<'tbody'>>

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Tbody: FC<TbodyProps> = ({ children, ...props }) => (
  <TableSectionContext.Provider value={{ section: 'tbody' }}>
    <Box as='tbody' {...props}>{children}</Box>
  </TableSectionContext.Provider>
)

// =============================================================================
// Table Row
// =============================================================================
/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type TrProps = PropsWithChildren<Omit<BoxProps, 'onClick'> & ComponentProps<'tr'> & {
  smallScreenExpandedContent?: ReactNode
  isExpanded?: boolean
}>

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Tr = forwardRef<HTMLTableRowElement, TrProps>(({
  children,
  smallScreenExpandedContent,
  isExpanded: isExpandedProp,
  onClick,
  ...props
}, ref) => {
  const { section } = useContext(TableSectionContext)
  const { isReady, countColumns, shouldHideExpandButton } = useContext(TableContext)

  const { mfv } = useMobFirstBreakpointValue()
  const isBigScreen = mfv({ mobSm: false, tabSm: true })

  const mobileColumnsCount = countColumns({ isBigScreenOnly: isBigScreen })

  // One for the left edge of the mobile row card, one for the right edge
  const EXTRA_MOBILE_COLUMNS_COUNT = 2

  const [isExpandedInternal, setIsExpandedInternal] = useState(false)
  const isExpanded = !smallScreenExpandedContent
    ? false
    : isBoolean(isExpandedProp)
      ? isExpandedProp
      : isExpandedInternal

  const handleClick = useCallback<MouseEventHandler<HTMLTableRowElement>>(ev => {
    onClick?.(ev)
    if (isBigScreen) return
    setIsExpandedInternal(value => !value)
  }, [isBigScreen, onClick])

  /**
   * On thead, we allow render to allow the registerColumn to be called.
   * On tbody, we don't render until the registerColumn is called, by waiting on isReady.
   * We need columns to be registered before we can count them, which is needed for tbody rows
   */
  if (!isReady && section === 'tbody') return <>{null}</>

  const { className, sx, ...rest } = props

  return (
    <>
      {/* Mobile space row */}
      {section === 'tbody' && !isBigScreen && (
        <MobileTopSpacerRow colSpan={mobileColumnsCount + EXTRA_MOBILE_COLUMNS_COUNT} />
      )}

      {/* Main row */}
      <Box
        as='tr'

        /**
         * Fixes children td/div height behaviors
         * @see https://stackoverflow.com/a/34781198
         */
        h='1px'

        data-group
        cursor={mbp({ mobSm: 'pointer', tabSm: 'default' })}
        className={cn(isExpanded && !isBigScreen && 'expanded', className)}
        onClick={handleClick}
        sx={{
          '& > td, & > td > div': {
            transitionProperty: mbp({ tabSm: 'background-color' }),
            transitionDuration: mbp({ tabSm: '0.3s' }),
          },
          ...sx,
        }}
        ref={ref}
        {...rest}
      >
        {!isBigScreen && (
          section === 'thead'
            ? (
              /* Left filler for left edge of mobile header row card */
              <MobileLeftSpacerHeadCell shouldHideExpandButton={shouldHideExpandButton} />
            )
            : (
              /* Left edge of main mobile row card */
              <MobileLeftMostCell isExpanded={isExpanded} shouldHideExpandButton={shouldHideExpandButton} />
            )
        )}

        {children}

        {!isBigScreen && (
          section === 'thead'
            ? (
              /* Right filler for right edge of mobile header row card */
              <MobileRightSpacerHeadCell />
            )
            : (
              /* Right edge of main mobile row card */
              <MobileRightMostCell isExpanded={isExpanded} />
            )
        )}
      </Box>

      {/* Mobile expandable row  */}
      {section === 'tbody' && !isBigScreen && (
        <Box as='tr'>
          <Box
            as='td'
            colSpan={mobileColumnsCount + 2}
            h='0'
            bg='card.bg.blue'
            p='0'
            w='auto'
          >
            <Box
              borderBottomRadius='2'
              mx={mbp({ mobSm: '1', mob: '2' })}
              bg='neutral.100'
            >
              <Collapse
                in={isExpanded}
                animateOpacity
                transition={{
                  enter: { duration: 0.1, delay: 0.05, ease: 'linear' },
                  exit: { duration: 0.1, ease: 'linear' },
                }}
              >
                <Box p='2' color='graystrong.400' textStyle='bodyL'>
                  {smallScreenExpandedContent}
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>
      )}

      {/* Mobile space row */}
      {section === 'tbody' && !isBigScreen && (
        <MobileBotSpacerRow colSpan={mobileColumnsCount + EXTRA_MOBILE_COLUMNS_COUNT} />
      )}
    </>
  )
})

Tr.displayName = 'Tr'

const MobileTopSpacerRow: FC<{ colSpan: number }> = ({ colSpan }) => (
  <Box as='tr'>
    <Box
      as='td'
      colSpan={colSpan}
      h='1'
      bg='card.bg.blue'
      p='0'
      sx={{
        // Very first MobileTopSpacerRow has rounded top border if thead is hidden
        [`thead.${IS_TABLE_HEAD_HIDDEN_CLASS} + tbody tr:first-of-type &`]: {
          borderTopRadius: 2,
          h: 3,
        },
      }}
    />
  </Box>
)

const MobileBotSpacerRow: FC<{ colSpan: number }> = ({ colSpan }) => (
  <Box as='tr'>
    <Box
      as='td'
      colSpan={colSpan}
      bg='card.bg.blue'
      p='0'
      h='1'
      sx={{
        // Very last MobileBotSpacerRow is a bit taller
        'tr:last-of-type &': {
          h: '3',
          borderBottomRadius: '2',
        },
      }}
    />
  </Box>
)

const MobileLeftSpacerHeadCell: FC<{ shouldHideExpandButton: boolean }> = ({
  shouldHideExpandButton,
}) => (
  <Th w={shouldHideExpandButton ? mbp({ mobSm: 1, mob: 2 }) : '44px'} />
)

const MobileRightSpacerHeadCell: FC = () => (
  <Th w='3' />
)

const MobileRightMostCell: FC<{ isExpanded: boolean }> = ({ isExpanded }) => (
  <Box
    as='td'

    // Dimensions
    h='6.5'
    p='0'
    pr={mbp({ mobSm: '1', mob: '2' })}

    // Visuals
    bg='card.bg.blue'
  >
    <Box
      // Dimensions
      w='2'
      h='full'

      // Backgrounds
      bg='card.bg.3'
      sx={{
        'tr:hover &': { bg: 'accent.hover' },
        'tr.expanded &': { bg: mbp({ mobSm: 'neutral.200', tabSm: 'initial' }) },
      }}

      // Animation related
      borderRightRadius='2'
      borderBottomRightRadius={isExpanded ? '0' : '2'}
      transitionDuration='0.1s'
      transitionProperty='border-bottom-right-radius'
      transitionDelay={isExpanded ? '0s' : '0.1s'}
      transitionTimingFunction='linear'
    />
  </Box>
)

const MobileLeftMostCell: FC<{
  isExpanded: boolean
  shouldHideExpandButton: boolean
}> = ({ isExpanded, shouldHideExpandButton }) =>
  shouldHideExpandButton
    ? (
      <Box
        as='td'
        w={mbp({ mobSm: 1, mob: 2 })}
        bg='card.bg.blue'
      />
    )
    : (
      <Box
        as='td'

        // Dimensions
        h='6.5'
        p='0'
        pl={mbp({ mobSm: '1', mob: '2' })}

        // Visuals
        bg='card.bg.blue'
      >
        <Center
          // Dimensions
          w='4.5'
          h='full'
          pl='1.5'
          pr='1'

          // Backgrounds
          bg='card.bg.3'
          sx={{
            'tr:hover &': { bg: 'accent.hover' },
            'tr.expanded &': { bg: mbp({ mobSm: 'neutral.200', tabSm: 'initial' }) },
            '& > svg': {
              display: 'inline-block',
            },
          }}

          // Animate border radius from rounded to pointed when expanding
          borderLeftRadius='2'
          borderBottomLeftRadius={isExpanded ? '0' : '2'}
          transitionDuration='0.1s'
          transitionProperty='border-bottom-left-radius'
          transitionDelay={isExpanded ? '0s' : '0.05s'}
          transitionTimingFunction='linear'
        >
          {isExpanded
            ? <ChevronUpIcon color='graystrong.200' fontSize={mbp({ mobSm: '2', mob: '3' })} />
            : <ChevronDownIcon color='graystrong.200' fontSize={mbp({ mobSm: '2', mob: '3' })} />}
        </Center>
      </Box>
    )

// =============================================================================
// Table Head Cell
// =============================================================================

const DEFAULT_TABSM_PX = '2'
const DEFAULT_MOBSM_PX = '0.75'
const DEFAULT_MOB_PX = '1'

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type ThProps = PropsWithChildren<BoxProps & ComponentProps<'th'> & {
  columnType?: 'default' | 'numeric' | 'icon-button'
  isBigScreenOnly?: boolean
}>

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Th: FC<ThProps> = ({
  children,
  columnType = 'default',
  _first,
  _last,
  isBigScreenOnly,
  ...props
}) => {
  const { mfv } = useMobFirstBreakpointValue()
  const isBigScreen = mfv({ mobSm: false, tabSm: true })

  const id = useId()
  const { registerColumn } = useContext(TableContext)

  registerColumn({ id, isBigScreenOnly })

  if (isBigScreenOnly && !isBigScreen) return <></>

  return (
    <Box
      as='th'
      h={TH_HEIGHT}
      bg='card.bg.blue'
      color='ondark.1'
      textAlign={columnType === 'numeric' ? 'right' : 'left'}
      px={mbp({ mobSm: DEFAULT_MOBSM_PX, mob: DEFAULT_MOB_PX, tabSm: DEFAULT_TABSM_PX })}
      textStyle='caption-h-b'

      _first={{ borderTopLeftRadius: 2, ..._first }}
      _last={{ borderTopRightRadius: 2, ..._last }}

      sx={{
        // No curve on first th if it's inside a Tabs
        [`.${TAB_SWITCH_WRAPPER_CLASS} &`]: {
          borderTopLeftRadius: 0,
        },

        // Affects big screens only
        '&:first-of-type': { pl: mbp({ mobSm: DEFAULT_MOBSM_PX, mob: DEFAULT_MOB_PX, tabSm: '3' }) },
        '&:last-of-type': { pr: mbp({ mobSm: DEFAULT_MOBSM_PX, mob: DEFAULT_MOB_PX, tabSm: '3' }) },

        [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} &:first-of-type`]: {
          pl: mbp({ mobSm: '0.5', mob: '1', tabSm: '3' }),
          pr: mbp({ mobSm: '0.5', mob: '1', tabSm: '3' }),
        },

        // Affects small screens only
        '&:nth-of-type(2)': { pl: mbp({ mobSm: '0', tabSm: DEFAULT_TABSM_PX }) },
        '&:nth-last-of-type(2)': { pr: mbp({ mobSm: '0.5', tabSm: DEFAULT_TABSM_PX }) },
      }}
      {...props}
    >
      {columnType === 'icon-button'
        ? (
          <IconButtonColumnAdjuster>{children}</IconButtonColumnAdjuster>
        )
        : children}
    </Box>
  )
}

// =============================================================================
// Table Cell
// =============================================================================

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export type TdProps =
  & BoxProps
  & ComponentProps<'td'>
  & {
    columnType?: 'default' | 'numeric' | 'icon-button'
    isBigScreenOnly?: boolean
  }

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const Td: FC<TdProps
> = ({
  children,
  columnType = 'default',
  _first,
  _last,
  isBigScreenOnly,
  colSpan,
  ...props
}) => {
  const { mfv } = useMobFirstBreakpointValue()
  const isBigScreen = mfv({ mobSm: false, tabSm: true })

  if (isBigScreenOnly && !isBigScreen) return <></>

  return (
    <Box
      as='td'
      p='0'
      colSpan={colSpan}
      className={`${columnType}-column`}
      sx={{
        ...!isBigScreen && {
          // Act as left most rounded card BACKGROUND when expand button is hidden
          [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} &:nth-of-type(2)`]: {
            bg: 'card.bg.blue',
          },
        },

        'h': '1px',
        '@-moz-document url-prefix()': {
          h: 'full',
        },
      }}
    >
      {/**
       * Extra container because firefox. Apply BG and border styles here.
       * Padding in inner container.
       * @see https://stackoverflow.com/a/36576083/7823311
       */}
      <Box
        display='table'
        h='full'
        w='full'

        sx={{
          // Apply striped background
          'tr:nth-of-type(odd) &': {
            bg: mbp({ mobSm: 'card.bg.3', tabSm: 'grayweak.200' }),
          },
          'tr:nth-of-type(even) &': {
            bg: mbp({ mobSm: 'card.bg.3', tabSm: 'grayweak.100' }),
          },

          // Make last rows' corner cells rounded...
          'tbody tr:last-of-type td:first-of-type &': {
            borderBottomLeftRadius: 2,
          },

          'tbody tr:last-of-type td:last-of-type &': {
            borderBottomRightRadius: 2,
          },

          // ...unless there is table action section
          [`.${TABLE_GROUP_CLASS}.${HAS_TABLE_ACTIONS_CLASS} tbody tr:last-of-type td:first-of-type &`]: {
            borderBottomLeftRadius: 0,
          },

          [`.${TABLE_GROUP_CLASS}.${HAS_TABLE_ACTIONS_CLASS} tbody tr:last-of-type td:last-of-type &`]: {
            borderBottomRightRadius: 0,
          },

          'tr:hover &': { bg: 'accent.hover' },
          'tr.expanded &': { bg: mbp({ mobSm: 'neutral.200', tabSm: 'initial' }) },

          ...!isBigScreen && {
            // Act as left most rounded CARD when expand button is hidden
            [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} td:nth-of-type(2) &`]: {
              pl: 2,
              borderTopLeftRadius: 2,
            },
            [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} tr td:nth-of-type(2) &`]: {
              transitionDuration: '0.1s',
              transitionProperty: 'border-bottom-left-radius',
              transitionTimingFunction: 'linear',
            },

            [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} tr:not(.expanded) td:nth-of-type(2) &`]: {
              borderBottomLeftRadius: 2,
              transitionDelay: '0.1s',
            },
            [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} tr:.expanded td:nth-of-type(2) &`]: {
              borderBottomLeftRadius: 0,
              transitionDelay: '0',
            },
          },
        }}
      >
        <Flex
          // Dimensions
          w='full'
          h='full'
          minH={mbp({ mobSm: '6.5', tabSm: '6' })}
          pl={mbp({ mobSm: DEFAULT_MOBSM_PX, mob: DEFAULT_MOB_PX, tabSm: DEFAULT_TABSM_PX })}
          pr={mbp({ mobSm: DEFAULT_MOBSM_PX, mob: DEFAULT_MOB_PX, tabSm: DEFAULT_TABSM_PX })}
          py='0.5'

          // Alignment
          justifyContent={columnType === 'numeric' ? 'flex-end' : 'flex-start'}
          alignItems='center'

          // Text
          color='graystrong.400'
          textStyle='bodyL'
          whiteSpace={mbp({ mobSm: 'normal', tabSm: 'initial' })}

          sx={{

            ...isBigScreen && {
              // Adjust big screen first/last padding
              'td:first-of-type &': {
                pl: '3',
              },
              'td:last-of-type &': {
                pr: '3',
              },
            },

            ...!isBigScreen && {
              // Adjust small screen first/last padding
              'td:nth-of-type(2) &': {
                pl: '0',
              },
              'td:nth-last-of-type(2) &': {
                pr: '0.5',
              },

              // Act as left most rounded CARD when expand button is hidden
              [`.${IS_EXPAND_BUTTON_HIDDEN_CLASS} td:nth-of-type(2) &`]: {
                pl: 2,
              },
            },
          }}
          {...props}
        >
          {columnType === 'icon-button'
            ? (
              <IconButtonColumnAdjuster>{children}</IconButtonColumnAdjuster>
            )
            : children}
        </Flex>
      </Box>
    </Box>
  )
}

// =============================================================================
// TableLoadingIndicator
// =============================================================================

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableLoadingIndicator: FC = () => (
  <Center flex='9999' py='5'>
    <Spinner
      // this actually works, Chakra UI's type is probably just outdated
      // so allow here `any` for now
      color={mbp({
        mobSm: 'ondark.1',
        tabSm: 'graystrong.900',
      }) as any}
    />
  </Center>
)

// =============================================================================
// TableEmptyIndicator
// =============================================================================

/** @deprecated An abomination; Use chakra table instead, it's custom themed by us. */
export const TableEmptyIndicator: FC<{ message: string }> = ({ message }) => {
  const content = (
    <>
      <Image src={runningDogPng} w='180px' h='162px' />
      <Text mt='1' textStyle={mbp({ mobSm: 'h4-b', tabSm: 'h3-b' })} color='graystrong.500'>
        There’s nothing to show here
      </Text>
      <Text mt='1' textStyle={mbp({ mobSm: 'bodyL', mob: 'bodyXL' })} color='graystrong.200' textAlign='center'>
        {message}
      </Text>
    </>
  )

  return (
    <Breakpoint
      mobSm={(
        <Card
          borderRadius='2'
          colorScheme='card.bg.1'
          px='0'
          p='2'
          pb='5'
          alignItems='center'
          m='2'
          mb='3'
        >
          {content}
        </Card>
      )}
      tabSm={(
        <Flex
          flex='9999'
          py='5'
          flexDir='column'
          alignItems='center'
          mt='3'
        >
          {content}
        </Flex>
      )}
    />
  )
}

const findAncestor = (el: HTMLElement, selector: string): HTMLElement | null =>
  el.matches(selector)
    ? el
    : el.parentElement
      ? findAncestor(el.parentElement, selector)
      : null

const IconButtonColumnAdjuster: FC<PropsWithChildren> = ({ children }) => (
  <Box ml={mbp({ mobSm: -1, tabSm: -2 })} mr={mbp({ mobSm: -2, mob: -1 })}>{children}</Box>
)
