import { Match, pipe } from 'effect'
import { isNumber } from 'effect/Number'
import { AttemptBatchSkiptracePayload, AttemptBatchSkiptraceResult } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { Pennies } from 'features/valueObjects/Pennies'
import { ErrorLib } from 'libs/errors'
import { apolloClient, restClient } from 'presentation/libs/client'
import { GET_ATTEMPT_BATCH_SKIPTRACE_MISSING_DATA_GQL } from './POSTAttemptBatchSkiptrace.graphql'

const request = async (payload: AttemptBatchSkiptracePayload): Promise<AttemptBatchSkiptraceResult> =>
  // Make the attempt request with useIncludedConsumables: true
  // This is expected to return a 402 with consumable attempt data
  restClient.post(`lists/v3/${payload.listId}/skiptrace`, {
    json: {
      members: payload.memberIds,
      provider: 'skipGenie',
      reskip: pipe(
        Match.value(payload.reskipConfig),
        /** We'd get null if there's nothing to reskip, so we can put true without consequence */
        Match.when(Match.null, () => true),
        Match.when({ _tag: 'Reskip' }, () => true),
        Match.when({ _tag: 'DoNotReskip' }, () => false),
        Match.when({ _tag: 'ReskipIfOlderThanXDays' }, ({ daysCount }) =>
          ({ olderThanXDays: daysCount })),
        Match.exhaustive,
      ),
      providerOptions: {
        skipGenie: {
          searchtype: payload.format,
        },
      },
      useIncludedConsumables: true,
      successfulItemLimit: payload.useFreeSkipsOnly
        ? payload.remainingConsumables
        : undefined,
    },
  })
    .json()
    .then(() => {
      // This should not happen - we expect the request to throw a 402 error
      throw new Error('Unexpected response from batch skiptrace attempt')
    })
    .catch(async error => {
      // Check if it's an error with a response
      if (error?.response) {
        try {
          const errorResponse = await error.response.json()

          // Check if it's the payment confirmation required error (402)
          if (errorResponse?.error?.type === 'payment_confirmation_required') {
            // Extract data from the error response first
            const confirmationKey = errorResponse.error.confirmationKey

            // Check if we have consumable data in the error response
            const restRemainingConsumables = errorResponse.error?.details?.included?.available
            const restRefreshingConsumables = errorResponse.error?.details?.included?.limit
            const restRefreshDate = errorResponse.error?.details?.included?.rolloverDate
            const restWalletBalance = errorResponse.error?.details?.balance?.available

            // Get missing subscription and funds info from GraphQL - only for planName and price
            const { data } = await apolloClient.query({
              query: GET_ATTEMPT_BATCH_SKIPTRACE_MISSING_DATA_GQL,
            }).catch(gqlError => {
              ErrorLib.report(gqlError, {
                extraInfo: { context: 'Error fetching batch skiptrace subscription data' },
              })
              throw gqlError
            })

            const enterprise = data.myEnterprise

            if (!enterprise)
              throw new Error('undefined `myEnterprise` in GraphQL response')

            const subscription = enterprise.subscription

            if (subscription?.__typename !== 'BillingSubscriptionActive')
              throw new Error('undefined or inactive subscription')

            // Find the Skiptrace feature in subscription - only for price
            const skiptraceFeature = subscription.plan.product.features
              .find((feat): feat is Extract<typeof feat, { __typename: 'SubscriptionFeatureSkiptrace' }> =>
                feat.__typename === 'SubscriptionFeatureSkiptrace')

            const price = skiptraceFeature?.prices[0]?.price

            if (!isNumber(price))
              throw new Error('missing Skiptrace price data')

            // Use REST data for everything except planName and price when not available in REST
            const remainingConsumables = restRemainingConsumables ?? 0
            const refreshingConsumables = restRefreshingConsumables ?? 0
            const consumablesRefreshDate = restRefreshDate ? new Date(restRefreshDate) : new Date()
            const walletBalance = restWalletBalance ?? 0

            // Create a ConsumableAttempt object from the response structure
            const consumableAttempt: ConsumableAttempt.ConsumableAttempt = {
              planName: subscription.plan.name, // Only using GraphQL for planName
              price, // Using REST price with GraphQL as fallback
              refreshingConsumables,
              remainingConsumables,
              consumablesRefreshDate,
              funds: {
                available: walletBalance as Pennies,
              },
              confirmationKey,
              status: determineStatus({
                remainingConsumables,
                availableFunds: walletBalance,
                toSkiptraceCount: payload.toSkiptraceCount,
                pricePerUnit: price,
              }),
            }

            // Return the attempt data
            return {
              attempt: consumableAttempt,
            }
          }
        } catch (parseError) {
          // Error parsing the response JSON
          ErrorLib.report(parseError, {
            extraInfo: { originalError: error, context: 'Error parsing batch skiptrace attempt response' },
          })

          throw parseError
        }
      }

      // If we reached here, it's not the expected error format or failed to parse
      const extraInfo = await ErrorLib.safeParseError(error)
      ErrorLib.report(error, {
        extraInfo: { ...extraInfo, context: 'Batch skiptrace attempt failed' },
      })

      throw error
    })

/**
 * Determine the status based on remaining consumables, available funds, and how many records need to be skiptrace'd
 */
const determineStatus = ({
  remainingConsumables,
  availableFunds,
  toSkiptraceCount,
  pricePerUnit,
}: {
  remainingConsumables: number
  availableFunds: number
  toSkiptraceCount: number
  pricePerUnit: number
}): ConsumableAttempt.Status => {
  if (remainingConsumables >= toSkiptraceCount)
    return 'sufficient-consumables'
  else if (availableFunds >= pricePerUnit * (toSkiptraceCount - remainingConsumables))
    return 'sufficient-funds'
  else
    return 'insufficient-funds'
}

export const POSTAttemptBatchSkiptrace = {
  request,
}
