import { Text } from '@chakra-ui/react'
import { String } from 'effect'
import { CardPrimaryText, CardPrimaryTitle } from 'presentation/components/molecules/Card'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { FC } from 'react'

export type PlanSwitcherProps = {
  planName: string
  transition: 'upgrading' | 'downgrading' | 'subscribing'
}

export const PlanSwitcherDescription: FC<PlanSwitcherProps> = ({
  transition,
  planName,
}) => (
  <>
    <CardPrimaryTitle>
      {String.capitalize(transition)}
      {' '}
      <Text as='span' color='inherit' textTransform='lowercase'>to</Text>
      {' '}
      {String.capitalize(planName)}
      {' '}
      Plan
    </CardPrimaryTitle>
    <CardPrimaryText
      mt={mbp({ mobSm: '1', tabSm: '2' })}
    >
      Pay by the month or the year, and cancel at any time.
    </CardPrimaryText>
  </>
)
