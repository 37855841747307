import { MapStore, createMapStore, getMapStyle } from 'presentation/components/Map/createMapStore'
import { useThemeType } from 'presentation/main/themes/hooks/useThemeType'
import { PropsWithChildren, createContext, useEffect, useRef } from 'react'
import { StoreApi } from 'zustand'
import { shallow } from 'zustand/shallow'

export const MapStoreContext = createContext<StoreApi<MapStore> | null>(null)

export const MapStoreProvider = ({ children }: PropsWithChildren) => {
  const themeType = useThemeType()

  const storeRef = useRef<StoreApi<MapStore>>()
  if (!storeRef.current) {
    const store = createMapStore(themeType)

    storeRef.current = store

    store.subscribe(
      state => ({ mode: state.mode, mapRef: state.computed.getMapRef() }),
      ({ mode, mapRef }) => {
        if (mapRef)
          mapRef.getMap().setStyle(getMapStyle(mode, themeType))
      },
      { equalityFn: shallow },
    )

    /**
     * Detect when map is fullscreen
     */
    store.subscribe(
      state => state.computed.getMapRef(),
      mapRef => {
        if (!mapRef) return
        mapRef.on('resize', () => {
          const isFullscreen = document.fullscreenElement?.className === 'mapboxgl-map'
          store.setState({ isFullscreen })
        })
      },
    )
  }

  useEffect(() => {
    if (!storeRef.current) return
    const state = storeRef.current.getState()
    state.computed.getMapRef()?.getMap().setStyle(getMapStyle(state.mode, themeType))
  }, [themeType, storeRef.current])

  return (
    <MapStoreContext.Provider value={storeRef.current}>
      {children}
    </MapStoreContext.Provider>
  )
}
