import { createPanelProvider, usePanelStore } from 'presentation/screens/hooks/usePanelStore'

const COMPS_FILTERS_KEY = 'comps-filters'
const COMPS_LIST_KEY = 'comps-list'

export const CompsScreenPanelsProvider = createPanelProvider({
  configs: [
    {
      panelKey: COMPS_FILTERS_KEY,
      localMinBreakpoint: 'tabSm',
      localInitialIsOpen: false,
    },
    {
      panelKey: COMPS_LIST_KEY,
      localMinBreakpoint: 'tabSm',
      localInitialIsOpen: false,
    },
  ],
})

export const useCompsFiltersPanelStore = usePanelStore(COMPS_FILTERS_KEY)
export const useCompsListPanelStore = usePanelStore(COMPS_LIST_KEY)
