import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionItemProps, AccordionPanel, AccordionPanelProps, Box, ComponentWithAs, Flex, Grid, GridItem, GridProps, IconProps, Text } from '@chakra-ui/react'
import { Skiptrace } from 'features/Skiptrace/domain/Skiptrace.domain'
import { Breakpoint } from 'presentation/components/Breakpoint'
import { BankIcon, EmailIcon, ExclamationCircleIcon, GavelIcon, OutlineHomeIcon, PhoneIcon, TwoPersonsIcon } from 'presentation/components/Icons'
import { SkiptraceAddressAccordion } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceAddressAccordion'
import { SkiptraceAssociatesAccordion } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceAssociatesAccordion'
import { SkiptraceEmailsAccordion } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceEmailsAccordion/SkiptraceEmailsAccordion'
import { SkiptracePhonesAccordion } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptracePhonesAccordion'
import { SkiptraceStatus } from 'presentation/screens/SkiptraceScreen/components/SkiptraceResultsCard/components/SkiptraceStatus/SkiptraceStatus'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { PropsWithChildren } from 'react'

type SkiptraceStat = {
  icon: ComponentWithAs<'svg', IconProps>
  label: string
  count: number
}

type SkiptraceAccordionItemProps = {
  owner: Skiptrace.Owner
}

export const SkiptraceAccordionItem = (props: SkiptraceAccordionItemProps) => {
  const { owner } = props

  const accordionHeaderLabel = owner.name && owner.age
    ? `${owner.name} - ${owner.age} y/o`
    : owner.name
      ? owner.name
      : '--'

  const stats: SkiptraceStat[] = [{
    icon: PhoneIcon,
    label: 'Phones',
    count: owner.possiblePhoneNumbers.length,
  }, {
    icon: EmailIcon,
    label: 'Emails',
    count: owner.possibleEmails.length,
  }, {
    icon: OutlineHomeIcon,
    label: 'Addresses',
    count: owner.addressHistory.length,
  }, {
    icon: TwoPersonsIcon,
    label: 'Relatives',
    count: owner.possibleAssociates.length,
  }, {
    icon: BankIcon,
    label: 'Bankruptcy',
    count: owner.bankruptciesCount ?? 0, // @NOTE Should this be just default to 0?
  }, {
    icon: GavelIcon,
    label: 'Judgements',
    count: owner.judgementsCount ?? 0,
  }, {
    icon: ExclamationCircleIcon,
    label: 'Lien',
    count: owner.liensCount ?? 0,
  }]

  const innerStatGridItems = stats.slice(4)

  return (
    <CustomAccordionItem>
      {({ isExpanded }) => (
        <>
          <CustomAccordionButton>
            {accordionHeaderLabel}
          </CustomAccordionButton>
          <CustomAccordionPanel>
            <SkiptracePhonesAccordion items={owner.possiblePhoneNumbers} />
            <SkiptraceEmailsAccordion items={owner.possibleEmails} />
            <SkiptraceAddressAccordion items={owner.addressHistory} />
            <SkiptraceAssociatesAccordion items={owner.possibleAssociates} />

            <Breakpoint
              tabSm={<Text textStyle='bodyL' color='graystrong.400'> Additional Information </Text>}
            />

            <SkiptraceInnerStatGrid stats={innerStatGridItems} />
          </CustomAccordionPanel>
          {!isExpanded && <SkiptraceStatGrid stats={stats} />}
        </>
      )}
    </CustomAccordionItem>
  )
}

const CustomAccordionItem = (props: AccordionItemProps) => (
  <AccordionItem
    sx={{
      '&:last-of-type': {
        borderBottomWidth: 0,
        borderTopWidth: 0,
      },
    }}
    shadow='primary.w'
    borderRadius={2}
    {...props}
  />
)

type CustomAccordionItemProps = PropsWithChildren

const CustomAccordionButton = (props: CustomAccordionItemProps) => (
  <AccordionButton
    bgColor='highlight.200'
    borderTopRadius={2}
    p={0}
    _hover={{
      bgColor: 'highlight.100',
    }}
  >
    <Flex alignItems='center' p={1} gap={1}>
      <Box p={0.5}>
        <AccordionIcon boxSize={3} />
      </Box>
      <SkiptraceStatus
        isSkipped={true}
        boxSize={2.5}
        successColor='positivesat.500'
      />
      <Text textStyle='bodyMFat' color='graystrong.600'>
        {props.children}
      </Text>
    </Flex>
  </AccordionButton>
)

const CustomAccordionPanel = (props: AccordionPanelProps) => (
  <AccordionPanel
    p={mbp({ mobSm: 1, mob: 2 })}
    bgColor='highlight.100'
    borderBottomRadius={2}
    {...props}
  >
    <Accordion allowToggle>
      <Flex
        flexDirection='column'
        gap={2}
      >
        {props.children}
      </Flex>
    </Accordion>
  </AccordionPanel>
)

type SkiptraceStatGridProps = {
  stats: SkiptraceStat[]
} & GridProps

const SkiptraceStatGrid = ({ stats, ...props }: SkiptraceStatGridProps) => (
  <Grid
    bgColor='grayweak.100'
    p={1}
    rowGap={3}
    gridTemplateColumns={mbp({ mobSm: 'repeat(4, 1fr)', dtSm: 'repeat(7, 1fr)' })}
    borderBottomRadius={2}
    {...props}
  >
    {stats.map(({ icon: Icon, label, count }, i) => (
      <GridItem
        key={i}
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={0.5}
      >
        <Box>
          <Icon boxSize={3} color='mixkey.blue' />
        </Box>
        <Text
          textStyle='bodyL'
          color='graystrong.400'
        >
          {count}
          {' '}
          <Box as='span' display={mbp({ mobSm: 'none', tabSm: 'inline' })}>{label}</Box>
        </Text>
      </GridItem>
    ))}
  </Grid>
)

const SkiptraceInnerStatGrid = ({ stats, ...props }: SkiptraceStatGridProps) => (
  <Grid
    bgColor='grayweak.100'
    p={1}
    rowGap={3}
    gridTemplateColumns={mbp({ mobSm: '1fr 60px 1fr', tabSm: 'repeat(3, 1fr)' })}
    borderRadius={2}
    {...props}
  >
    {stats.map(({ icon: Icon, label, count }, i) => (
      <GridItem
        key={i}
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={0.5}
        {...mbpg({
          mobSm: {
            ...label === 'Lien' && { order: 2 },
            ...label === 'Judgements' && { order: 3 },
          },
          tabSm: {
            ...label === 'Judgements' && { order: 2 },
            ...label === 'Lien' && { order: 3 },
          },
        })}
      >
        <Box>
          <Icon boxSize={3} color='mixkey.blue' />
        </Box>
        <Text
          textStyle={{ base: 'bodyM', tabSm: 'bodyL' }}
          color='graystrong.400'
        >
          {count}
          {' '}
          {label}
        </Text>
      </GridItem>
    ))}
  </Grid>
)
