export type Question = {
  question: string
  answer: string
}

const QUESTIONS: Question[] = [
  {
    question: 'Do you offer discounts?',
    answer: 'Yes. When you pay for a year up front, you get 2 months of Propelio free. We are always running specials so feel free to ask us what is available.',
  },
  {
    question: 'Will we be charged when our trial is up?',
    answer: 'No. We don\'t ask for your credit card up front, so you\'ll only be charged when you decide you\'re ready. If you want to continue after your trial, we\'ll ask for payment details. If not - cancel with a click, no questions asked.',
  },
  {
    question: 'What if we need a longer trial?',
    answer: 'We\'re happy to give you some more time. Just let us know and we\'ll extend your trial.',
  },
  {
    question: 'Can we cancel at any time?',
    answer: 'There\'s no minimum contract for Propelio - cancel at any time without penalty or hassle. If you prepaid for a year, we\'ll refund any unused months.',
  },
  {
    question: 'What if I have more questions?',
    answer: 'We\'d be happy to answer them. Just click the chat bubble in the bottom right corner of the screen.',
  },
]

export const useCommonQuestions = (): Question[] => QUESTIONS
