import { inputLegacyTheme } from 'presentation/components/Input/InputLegacy.theme'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { px } from 'presentation/utils/px'

import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig }
  = createMultiStyleConfigHelpers(inputAnatomy.keys)

export const baseStyle = definePartsStyle({
  field: {
    color: 'graystrong.700',
  },
})

export const variants = {
  ...inputLegacyTheme.variants,
  default: definePartsStyle({
    field: {
      ...textStyles.body, // let's put this here so we can access it from Stripe inputs
      boxShadow: 'none',
      bg: 'card.bg.1',
      borderWidth: '1px',
      borderColor: 'grayweak.500',
      color: 'graystrong.700',
      borderRadius: 1,

      _placeholder: {
        color: 'graystrong.200',
      },

      _focus: {
        boxShadow: 'depth',
        borderColor: 'neutral.500',
        borderWidth: '2px',
      },

      _invalid: {
        borderColor: 'error.500',
        borderWidth: '2px',
      },

      _disabled: {
        'opacity': 1,
        'boxShadow': 'none',
        'borderColor': 'grayweak.600',
        'color': 'grayweak.800',
        'bgColor': 'grayweak.400',
        '::placeholder': {
          color: 'positive.100',
        },
      },
      _hover: {
        boxShadow: 'depth',
      },
    },
    addon: {
      color: 'graystrong.200',
    },
    element: {
      color: 'graystrong.200',
    },
  }),
  rounded: definePartsStyle({
    field: {
      ...textStyles.body, // let's put this here so we can access it from Stripe inputs
      bg: 'card.bg.1',
      borderWidth: '1px',
      borderColor: 'grayweak.500',
      color: 'graystrong.700',
      borderRadius: '100px',

      _placeholder: {
        color: 'graystrong.200',
      },

      _focus: {
        boxShadow: 'none',
        borderColor: 'neutral.500',
        borderWidth: '2px',
      },

      _invalid: {
        borderColor: 'error.500',
        borderWidth: '2px',
      },

      _hover: {
        boxShadow: 'depth',
      },
    },
    addon: {
      color: 'graystrong.200',
    },
    element: {
      color: 'graystrong.200',
    },
  }),
}

export const sizes = {
  md: definePartsStyle({
    field: {
      ...textStyles.bodyL,
      px: px(16 - 1), // padding - border
      py: px(8 - 1), // padding - border

      _focus: {
        px: px(16 - 2), // padding - border
        py: px(8 - 2), // padding - border
      },
      element: {
        right: '6px',
      },
      _invalid: {
        px: px(16 - 2), // padding - border
        py: px(8 - 2), // padding - border
      },

      _hover: {
        px: px(16 - 1), // padding - border
        py: px(8 - 2), // padding - border
      },
    },
    element: {
      px: px(16 - 1), // padding - border
      fontSize: 3,
      w: 'auto',
    },
  }),
}

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
})
