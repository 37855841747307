import { FailedInvoiceModalProps } from 'presentation/main/globalModals/FailedInvoiceModal/FailedInvoiceModal.types'
import { goBackModal } from 'presentation/main/globalModals/globalModals.utils'
import { modals } from 'presentation/main/modals'

type Params = Omit<FailedInvoiceModalProps, 'isOpen' | 'onClose'>

export const openRestoreFailedInvoiceModal = ({
  onSuccess,
  onError,
  failedInvoiceSummary,
}: Params): void => {
  modals.emitter.emit({
    type: 'OPEN',
    payload: {
      key: 'RESTORE_FAILED_INVOICE_MODAL',
      props: {
        onClose: goBackModal,
        onSuccess,
        onError,
        failedInvoiceSummary,
      },
    },
  })
}
