import { CardProps, Flex, Image, Link, LinkProps, Text } from '@chakra-ui/react'
import RocketManPng from 'presentation/assets/img/3d/rocketman-2x.png'
import { CheckIcon, TriangleExclamationOutlineIcon } from 'presentation/components/Icons'
import { RegularCircleExclamationIcon } from 'presentation/components/Icons/regular'
import { Card } from 'presentation/components/molecules/Card'
import { PropelioLogo } from 'presentation/screens/AuthScreens/components/EverythingYouNeedGrid/components/PropelioLogo'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { PropsWithChildren } from 'react'

export const FormCard = ({ children, ...props }: CardProps) => (
  <Card
    size={mbp({ mobSm: 'xxs-locked', mob: 'sm-locked', tabSm: 'lg-locked' })}
    colorScheme='modal.highlight'
    position='relative'
    borderRadius={mbp({ mobSm: '0 !important', mob: '4 !important' })}
    {...props}
  >
    <PropelioLogo
      display={mbp({ mobSm: 'block', mob: 'none' })}
      mt={3}
      mb={3}
      h={3}
      alignSelf='start'
    />
    <Image
      position='absolute'
      src={RocketManPng}
      {...mbpg({
        mobSm: {
          display: 'none',
        },
        dtSm: {
          display: 'block',
          top: '-93px',
          right: '-20px',
          w: '180px',
          h: '180px',
        },
        dtLg: {
          top: '-93px',
          right: 0,
          w: '230px',
          h: '230px',
        },
      })}
    />
    {children}
  </Card>
)

export const UnderlinedLink = (props: LinkProps) => (
  <Link color='link' textDecoration='underline' {...props} />
)

export const ValidationFeedback = (props: PropsWithChildren<{
  status: 'valid' | 'invalid' | 'pristine'
}>) => (
  <Flex alignItems='center' mt={1} gap={1}>
    {props.status === 'valid'
      ? <CheckIcon color='positive.600' boxSize={3} />
      : props.status === 'invalid'
        ? <TriangleExclamationOutlineIcon color='negative.500' boxSize={3} />
        : <RegularCircleExclamationIcon color='neutral.600' boxSize={3} />}
    <Text
      textStyle='bodyL'
      color='graystrong.200'
    >
      {props.children}
    </Text>
  </Flex>
)

export const RequiredAsterisk = () => (
  <Text color='negative.500'>&nbsp;*</Text>
)
