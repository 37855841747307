import { createContext, useContext } from 'react'

export type PropertyDetailsActions = {
  goToSkiptrace: () => void
  goToComps: () => void
}

const context = createContext<PropertyDetailsActions>({
  goToSkiptrace: () => {},
  goToComps: () => {},
})

export const PropertyDetailsActionsProvider = context.Provider

export const usePropertyDetailsActions = () =>
  useContext(context)
