import { openAddPaymentModal } from 'presentation/screens/Billing/Billing.modals'
import { useCallback, useEffect, useRef, useState } from 'react'

type PaymentRegistrationOptions = {
  isPaidPlan: boolean
  onPaymentComplete?: (values: any, paymentMethodId: string) => void
}

/**
 * Custom hook to handle payment processing during registration
 *
 * @param options Configuration options
 * @returns Payment handling utilities for registration
 */
export const usePaymentRegistration = (options: PaymentRegistrationOptions) => {
  const { isPaidPlan, onPaymentComplete } = options

  // State to track payment information
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null)
  const formValuesRef = useRef<any>(null)

  // Listen for payment method added events
  useEffect(() => {
    const handlePaymentAdded = (event: any) => {
      if (event.detail?.type === 'PAYMENT_METHOD_ADDED' && event.detail?.paymentMethodId) {
        const paymentMethodId = event.detail.paymentMethodId
        setPaymentMethodId(paymentMethodId)

        // If we have a callback and stored values, call it
        if (onPaymentComplete && formValuesRef.current)
          onPaymentComplete(formValuesRef.current, paymentMethodId)
      }
    }

    window.addEventListener('paymentMethodAdded', handlePaymentAdded)
    return () => {
      window.removeEventListener('paymentMethodAdded', handlePaymentAdded)
    }
  }, [onPaymentComplete])

  // Remember form values from latest submission
  const storeFormValues = useCallback((values: any) => {
    formValuesRef.current = values
  }, [])

  // Handle payment flow initiation
  const initiatePaymentFlow = useCallback(() => {
    if (isPaidPlan && !paymentMethodId) {
      openAddPaymentModal('registration')
      return true
    }
    return false
  }, [isPaidPlan, paymentMethodId])

  // Get card info for registration if required
  const getCardInfo = useCallback(() => {
    if (isPaidPlan && paymentMethodId)
      return { card: paymentMethodId }

    return {}
  }, [isPaidPlan, paymentMethodId])

  return {
    paymentMethodId,
    storeFormValues,
    initiatePaymentFlow,
    getCardInfo,
    hasStoredValues: !!formValuesRef.current,
    getStoredValues: () => formValuesRef.current,
  }
}
