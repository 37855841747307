import { TaggedEnum, taggedEnum } from 'effect/Data'
import { BatchSkiptraceStats, ReskipConfig } from 'features/BatchSkiptrace/domain/BatchSkiptraceRepo'
import { MarketingListId } from 'features/ListBuilder/domain/MarketingList'
import { MarketingListMemberId } from 'features/ListBuilder/domain/MarketingListMember/MarketingListMemberId'
import ConsumableAttempt from 'features/common/ConsumableAttempt/domain/ConsumableAttempt'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type BatchSkiptraceModalState = TaggedEnum<{
  Closed: object
  ReskipConfigStep: ReskipConfigStepPayload
  SkiptraceBreakdownStep: SkiptraceBreakdownStepPayload
  FormatStep: FormatStepPayload
}>

export const BatchSkiptraceModalState = taggedEnum<BatchSkiptraceModalState>()

export type ReskipConfigStepPayload = {
  stats: BatchSkiptraceStats
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
}

export type SkiptraceBreakdownStepPayload = {
  toSkiptraceCount: number
  reskipConfig: ReskipConfig | null
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
  attempt: ConsumableAttempt.ConsumableAttempt
}

export type FormatStepPayload = SkiptraceBreakdownStepPayload & {
  useFreeSkipsOnly: boolean
  remainingConsumables: number
}

type OpenPayload = {
  stats: BatchSkiptraceStats
  listId: MarketingListId
  memberIds: MarketingListMemberId[]
}

type BatchSkiptraceModalShape = {
  state: BatchSkiptraceModalState
  open: (payload: OpenPayload) => void
  close: () => void
  toSkiptraceBreakdownStep: (payload: SkiptraceBreakdownStepPayload) => void
  toFormatStep: (payload: FormatStepPayload) => void
}

const useBatchSkiptraceModalStore = create<BatchSkiptraceModalShape>()(immer(set => ({
  state: BatchSkiptraceModalState.Closed(),
  open: payload => set(state => {
    state.state = BatchSkiptraceModalState.ReskipConfigStep(payload)
  }),
  close: () => set(() => ({ state: BatchSkiptraceModalState.Closed() })),
  toSkiptraceBreakdownStep: (payload: SkiptraceBreakdownStepPayload) => set(() => ({
    state: BatchSkiptraceModalState.SkiptraceBreakdownStep(payload),
  })),
  toFormatStep: (payload: FormatStepPayload) => set(() => ({
    state: BatchSkiptraceModalState.FormatStep(payload),
  })),
})))

export const useBatchSkiptraceModalActions = () => useBatchSkiptraceModalStore(state => ({
  open: state.open,
  close: state.close,
  toSkiptraceBreakdownStep: state.toSkiptraceBreakdownStep,
  toFormatStep: state.toFormatStep,
}))

export const useBatchSkiptraceModal = () => useBatchSkiptraceModalStore(state => state.state)
