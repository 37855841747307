import { CompEntry } from 'presentation/screens/CompsScreen/components/modals/ComparativePropertyModal/hooks/useComparativePropertyCardLogic'
import { useLayoutEffect, useState } from 'react'
import Swiper from 'swiper'

export type UseCardSelectionCarouselParams = {
  selectedIndex: number | null
  entries: CompEntry[]
  onSelectedIndexChange: (index: number) => void
}

export const useCardSelectionCarousel = (props: UseCardSelectionCarouselParams) => {
  const [swiper, setSwiper] = useState<Swiper | null>(null)

  const isOutOfBounds = (index: number) => index < 0 || index >= props.entries.length

  useLayoutEffect(() => {
    if (props.selectedIndex === null
      || isOutOfBounds(props.selectedIndex)) return
    if (swiper)
      swiper.slideTo(props.selectedIndex)
  }, [swiper, props.selectedIndex])

  if (props.selectedIndex === null) {
    return {
      status: 'aborting' as const,
    }
  }

  const handleOnNext = () => {
    if (props.selectedIndex === null) return
    const nextIndex = props.selectedIndex + 1
    if (nextIndex >= props.entries.length) return
    swiper?.slideTo(nextIndex)
    props.onSelectedIndexChange(nextIndex)
  }

  const handleOnPrev = () => {
    if (props.selectedIndex === null) return
    const prevIndex = props.selectedIndex - 1
    if (prevIndex < 0) return
    swiper?.slideTo(prevIndex)
    props.onSelectedIndexChange(prevIndex)
  }

  const isFirstSlide = props.selectedIndex === 0
  const isLastSlide = props.selectedIndex === props.entries.length - 1

  return {
    status: 'ok' as const,
    isFirstSlide,
    isLastSlide,
    slidesOffsetBefore: 24,
    slidesOffsetAfter: 24,
    setSwiper,
    next: handleOnNext,
    prev: handleOnPrev,
  }
}
