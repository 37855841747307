import { Box, BoxProps, Center, Flex, IconButton, Spinner } from '@chakra-ui/react'
import { gql } from '__generated__'
import { PaymentMethodCardList_PaymentMethodFragmentFragment } from '__generated__/graphql'
import { isFunction, isNumber } from 'lodash/fp'
import { ChevronLeftIcon } from 'presentation/components/Icons'
import { HIDE_SIDEBAR_SX } from 'presentation/const/styles.const'
import { usePeekaboo } from 'presentation/hooks/usePeekaboo'
import { useSwitchBreakpoint, useSwitchBreakpointFn } from 'presentation/hooks/useSwitchBreakpoint'
import { fadeIn } from 'presentation/utils/animationClasses'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { px } from 'presentation/utils/px'
import { FC, forwardRef, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react'
import { AddPaymentCard } from '../AddPaymentCard'
import { PaymentCard } from '../PaymentCard'

export const PAYMENT_METHOD_CARD_LIST__PAYMENT_METHOD_FRAGMENT = gql(/* GraphQL */ `
  fragment PaymentMethodCardList_PaymentMethodFragment on BillingCardsPaymentMethod {
    id,
    isDefault
    ...PaymentMethodCard_PaymentMethodFragment
  }
`)

export const PaymentCardList: FC<{
  paymentMethods?: (PaymentMethodCardList_PaymentMethodFragmentFragment & { id: string })[]
  isLoading?: boolean
  /** Number represeting pixels only */
  slidesOffsetBefore: number
  /** Number represeting pixels only */
  slidesOffsetAfter: number
}> = ({ paymentMethods, isLoading, slidesOffsetBefore, slidesOffsetAfter }) => {
  const { sbp } = useSwitchBreakpointFn()

  return (
    <Flex overflowX='auto' sx={HIDE_SIDEBAR_SX} w='full'>
      {isLoading
        ? (
          <Center w='full' py='5'><Spinner /></Center>
        )
        : !paymentMethods?.length
          ? (
            <>
              <Box flex={`0 0 ${slidesOffsetBefore}px`} />
              <CardContainer
                width={sbp(CARD_W_MAP) || CARD_W_MAP.mobSm}
                className={fadeIn}
                mx='0'
                flex='1 0 0'
                maxW='unset'
              >
                <AddPaymentCard />
              </CardContainer>
              <Box flex={`0 0 ${slidesOffsetBefore}px`} />
            </>
          )
          : (
            <PaymentCardCarousel
              key={paymentMethods.length}
              paymentMethods={paymentMethods}
              slidesOffsetBefore={slidesOffsetBefore}
              slidesOffsetAfter={slidesOffsetAfter}
            />
          )}
    </Flex>
  )
}

const CARD_W_MAP = { mobSm: 240, mob: 284, dtLg: 300 }

const PaymentCardCarousel: FC<{
  paymentMethods: (PaymentMethodCardList_PaymentMethodFragmentFragment & { id: string })[]
  /** Number represeting pixels only */
  slidesOffsetBefore: number
  /** Number represeting pixels only */
  slidesOffsetAfter: number
}> = ({
  paymentMethods,
  slidesOffsetBefore,
  slidesOffsetAfter,
}) => {
  const { sbp } = useSwitchBreakpointFn()
  const spaceBetween = 24
  const { itemSize: cardWidth, containerRef } = usePeekaboo<HTMLDivElement>({
    idealSize: sbp(CARD_W_MAP) || CARD_W_MAP.mobSm,
    spaceAround: slidesOffsetBefore,
    spaceBetween,
  })

  /**
   * Re-render when payment methods count or card width change as a
   * workaround to nav buttons not responding correctly, and other swiper issues
   *
   * Note that at the time of coding, adding key to SwiperComponent did not
   * work, so we added it here instead
   */
  const key = [paymentMethods.length, cardWidth].join('|')

  return (
    <Box
      w='full'
      sx={{
        '& .slide': {
          maxW: px(cardWidth),
        },
      }}
      pos='relative'
      className={fadeIn}
      ref={containerRef}
    >
      {!!cardWidth && (
        <PaymentCardCarouselInner
          key={key}
          cardWidth={cardWidth}
          paymentMethods={paymentMethods}
          slidesOffsetBefore={slidesOffsetBefore}
          slidesOffsetAfter={slidesOffsetAfter}
        />
      )}
    </Box>
  )
}

const PaymentCardCarouselInner: FC<{
  paymentMethods: (PaymentMethodCardList_PaymentMethodFragmentFragment & { id: string })[]
  /** Number represeting pixels only */
  slidesOffsetBefore: number
  /** Number represeting pixels only */
  slidesOffsetAfter: number
  cardWidth: number
}> = ({
  paymentMethods: fragment,
  slidesOffsetBefore,
  slidesOffsetAfter,
  cardWidth,
}) => {
  const paymentMethods = fragment
  const [swiperInst, setSwiperInst] = useState<Swiper | null>(null)

  const [isLocked, setIsLocked] = useState(false)
  const [slidesCount, setSlidesCount] = useState(0)
  const prevSlidesCount = usePrevious(slidesCount)

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)

  const spaceBetween = 24

  useEffect(() => {
    if (!slidesCount || !swiperInst || !isSwiperInstReady(swiperInst)) return

    const didIncrease = isNumber(prevSlidesCount) && slidesCount > prevSlidesCount

    if (didIncrease && swiperInst.realIndex !== 0)
      swiperInst.slideTo(0)

    // Whene locked, it's possible that the user has deleted a card,
    // and the slide is left at an awkward position without the user being able
    // to slide it.
    else if (isLocked)
      swiperInst.slideTo(0)
  }, [slidesCount, swiperInst, prevSlidesCount, slidesCount, isLocked])

  const primaryId = paymentMethods?.find(paymentMethod => paymentMethod.isDefault)?.id
  useEffect(() => {
    if (!primaryId || !swiperInst || !isSwiperInstReady(swiperInst)) return

    swiperInst.slideTo(0)
  }, [primaryId, swiperInst])

  const trackSwiperState = (swiper: Swiper) => {
    setIsLocked(swiper.isLocked)
    setSlidesCount(swiper.slides.length)
    setSwiperInst(swiper)
  }

  const shouldCenterSlides = useSwitchBreakpoint({ mobSm: true, tabSm: false })

  /**
   * Swiper is "locked" when it cannot be swiped, usually because the
   * slides width are less than the container width.
   *
   * At that point, we the slides would be centered, and we need to adjust
   * the slides offset
   */
  const shouldAdjustForCenteredSlides = isLocked && shouldCenterSlides

  return (
    <SwiperComponent
      key={cardWidth}
      grabCursor={!isLocked}
      slidesPerView='auto'
      spaceBetween={spaceBetween}
      centerInsufficientSlides={shouldCenterSlides}
      slidesOffsetBefore={shouldAdjustForCenteredSlides ? 0 : slidesOffsetBefore}
      slidesOffsetAfter={shouldAdjustForCenteredSlides ? 0 : slidesOffsetAfter}
      onSwiper={trackSwiperState}
      onUpdate={trackSwiperState}
      onSlideChange={trackSwiperState}
      modules={[Navigation]}
      navigation={{ prevEl, nextEl }}
    >
      {paymentMethods?.map(paymentMethod => paymentMethod && (
        <SwiperSlide className='slide' key={paymentMethod.id}>
          <CardContainer width={cardWidth}>
            <PaymentCard paymentMethod={paymentMethod} />
          </CardContainer>
        </SwiperSlide>
      ))}

      <SwiperSlide className='slide' key='addPaymentCard'>
        <CardContainer width={cardWidth}>
          <AddPaymentCard />
        </CardContainer>
      </SwiperSlide>

      <NavButton ref={setNextEl} direction='next' />
      <NavButton ref={setPrevEl} direction='prev' />
    </SwiperComponent>
  )
}

const CardContainer: FC<BoxProps & { width: number }> = ({ children, width, ...props }) => (
  <Box
    maxW={px(width)}
    flexBasis={px(width)}
    flexShrink={0}
    w='full'
    pt={mbp({ mobSm: 2 })}
    pb='3'
    {...props}
  >
    {children}
  </Box>
)

/**
 * @HACK This is a hack to avoid a crash when slideTo is called before the
 *   swiper instance is ready.
 */
const isSwiperInstReady = (swiperInst: Swiper | null) => !!swiperInst?.slides

const SWIPER_DISABLE_NAV_BUTTONS_CLASS = 'swiper-button-disabled'

type NavButtonProps = { direction: 'next' | 'prev' }
const NavButton = forwardRef<HTMLButtonElement, NavButtonProps>(({ direction }, ref) => {
  const { setEl } = useUpdateTabIndexOnDisable()

  return (
    <IconButton
      ref={el => {
        setEl(el)
        if (ref && 'current' in ref) ref.current = el
        else if (isFunction(ref)) ref(el)
      }}
      aria-label={direction === 'next' ? 'Next' : 'Prev'}
      fontSize='4'
      boxSize='4'
      bg='graystrong.200'
      color='grayweak.200'
      pos='absolute'
      top='50%'
      left={direction === 'next' ? 'auto' : '2'}
      right={direction === 'next' ? '2' : 'auto'}
      transform='translateY(-50%)'
      zIndex='1'
      pl={direction === 'next' ? '0.5' : '0'}
      pr={direction === 'next' ? '0' : '0.5'}
      _hover={{ bg: 'graystrong.200' }}
      sx={{
        [`&.${SWIPER_DISABLE_NAV_BUTTONS_CLASS}`]: {
          pointerEvents: 'none',
          opacity: 0,
          transition: 'opacity 0.3s',
        },
      }}
    >
      <ChevronLeftIcon sx={{ transform: direction === 'next' ? 'rotate(180deg)' : 'none' }} />
    </IconButton>
  )
})

NavButton.displayName = 'NavButton'

const useUpdateTabIndexOnDisable = () => {
  const [el, setEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!el) return

    const observer = new MutationObserver(() => {
      const isDisabled = el.classList.contains(SWIPER_DISABLE_NAV_BUTTONS_CLASS)
      el.tabIndex = isDisabled ? -1 : 0
    })

    observer.observe(el, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
    })
  }, [el])

  return { setEl }
}
