import { Box, Link, LinkProps, Text } from '@chakra-ui/react'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { ReactNode } from 'react'

export type LegalDocumentAttr = { title: string, children: ReactNode }
export type LegalSectionAttr = { title: string, children: ReactNode }
export type Children = { children: ReactNode }

export const LegalDocument = ({ children, title }: LegalDocumentAttr) => (
  <Box>
    <Text
      mt={4}
      textStyle={mbp({ dtSm: 'bodyXLFat', dtLg: 'h1' })}
      textAlign='center'
      color='graystrong.200'
    >
      {title}
    </Text>
    {children}
  </Box>

)

export const LegalSection = ({ children, title }: LegalSectionAttr) => (
  <Box margin='5'>
    <Text
      mt={4}
      textStyle={mbp({ dtSm: 'bodyLFat', dtLg: 'h3' })}
      color='graystrong.200'
      decoration='underline'
    >
      {title}
    </Text>
    {children}
  </Box>
)

export const LegalParagraph = ({ children }: Children) => (
  <Text
    mt={4}
    textStyle={mbp({ dtSm: 'bodyLFat', dtLg: 'h3' })}
    color='graystrong.200'
  >
    {children}
  </Text>
)

export const UnderlinedLink = (props: LinkProps) => (
  <Link color='link' textDecoration='underline' {...props} />
)
