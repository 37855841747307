import { createIcon } from '@chakra-ui/react'

export const OutlineBathIcon = createIcon({
  displayName: 'OutlineBatchIcon',
  viewBox: '0 0 13 13',
  path: (
    <path
      fill='none'
      stroke='currentColor'
      d='M0 7.47333H12.8M5.54667 2.78V1.5H3.84C3.16105 1.5 2.5099 1.76971 2.02981 2.24981C1.54971 2.7299 1.28 3.38105 1.28 4.06V9.18C1.28 9.85896 1.54971 10.5101 2.02981 10.9902C2.5099 11.4703 3.16105 11.74 3.84 11.74H8.96C9.63895 11.74 10.2901 11.4703 10.7702 10.9902C11.2503 10.5101 11.52 9.85896 11.52 9.18V7.47333M4.26667 4.06H6.82667'
    />
  ),
})
