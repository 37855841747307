// eslint-disable-next-line no-restricted-imports
import { Box, BoxProps, ButtonProps, Card as ChakraCard, ComponentWithAs, FlexProps, HTMLChakraProps, SystemStyleObject, TextProps, chakra, forwardRef as chakraForwardRef, useButtonGroup, useCardStyles, useStyleConfig } from '@chakra-ui/react'
import cn from 'classnames'
import { merge } from 'lodash/fp'
import { ConfigurableChakraButton } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraButton'
import { ConfigurableChakraFlex } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraFlex'
import { ConfigurableChakraText } from 'presentation/components/ConfigurableChakraComponents/ConfigurableChakraText'
import { RESET_SPACING_CLASS_NAME as RESET_SPACING } from 'presentation/components/molecules/Card/Card.const'
import { CARD_PARTS } from 'presentation/components/molecules/Card/Card.theme'
import { createChakraClass, mergeChakraClass } from 'presentation/components/molecules/Card/Card.utils'
import { Breakpoint } from 'presentation/main/themes/common/breakpoints.theme'
import { mbp } from 'presentation/utils/mapBreakpoint'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'
import { FC, forwardRef } from 'react'

export const Card = ChakraCard

// =============================================================================
// Section Elements
// =============================================================================

export const CardHeader: FC<HTMLChakraProps<'div'> & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <chakra.div
      __css={styles.header}
      className={mergeChakraClass(
        CARD_PARTS.header,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

export const CardBody: FC<HTMLChakraProps<'div'> & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <chakra.div
      __css={styles.body}
      className={mergeChakraClass(
        CARD_PARTS.body,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

type CardFooterProps = HTMLChakraProps<'div'> & {
  shouldResetSpacing?: boolean
  horizontallyStackAt?: Breakpoint | null
}

/**
 * @TODO Instead `horizontallyStackAt` we should just use something like `flexDirection` so you can
 *   do something like mbp({ mobSm: 'column', tab: 'row' })
 */
export const CardFooter = chakraForwardRef<CardFooterProps, 'div'>(
  ({
    horizontallyStackAt = 'mob',
    ...props
  }, ref) => {
    const { className, shouldResetSpacing, ...rest } = props
    const styles = useCardStyles()
    return (
      <chakra.div
        ref={ref}
        __css={styles.footer}
        flexDir={mbp({
          mobSm: 'column',
          ...horizontallyStackAt && { [horizontallyStackAt]: 'row' },
        })}
        className={cn(
          'chakra-card__footer',
          className,
          shouldResetSpacing && RESET_SPACING,
        )}
        sx={mbpg(merge(
          {
            mobSm: {
              '& > .chakra-button:first-of-type': {
                order: 2,
              },
              '& > .chakra-button + .chakra-button': {
                order: 1,
              },
              [`& > .${createChakraClass(`${CARD_PARTS.tertiaryAction}_container`)}`]: {
                order: 3,
              },
            },
          },
          horizontallyStackAt
            ? {
              [horizontallyStackAt]: {
                '& > .chakra-button:first-of-type': {
                  order: 1,
                },
                '& > .chakra-button + .chakra-button': {
                  order: 1,
                },
              },
            }
            : {},
        ))}
        {...rest}
      />
    )
  },
)

CardFooter.displayName = 'CardFooter'

// =============================================================================
// Primary Texts
// =============================================================================

export const CardPrimaryTitle: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      __css={styles.primaryTitle}
      className={mergeChakraClass(
        CARD_PARTS.primaryTitle,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

export const CardPrimaryText: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      __css={styles.primaryText}
      className={mergeChakraClass(
        CARD_PARTS.primaryText,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

export const CardPrimarySupportingText: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      __css={styles.primarySupportingText}
      className={mergeChakraClass(
        CARD_PARTS.primarySupportingText,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

// =============================================================================
// Secondary Texts
// =============================================================================

export const CardSecondaryTitle: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = forwardRef(({ className, shouldResetSpacing, ...props }, ref) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      ref={ref}
      __css={styles.secondaryTitle}
      className={mergeChakraClass(
        CARD_PARTS.secondaryTitle,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
})

CardSecondaryTitle.displayName = 'CardSecondaryTitle'

export const CardSecondaryText: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = forwardRef(({ className, shouldResetSpacing, ...props }, ref) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      ref={ref}
      __css={styles.secondaryText}
      className={mergeChakraClass(
        CARD_PARTS.secondaryText,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
})

CardSecondaryText.displayName = 'CardSecondaryText'

export const CardSecondarySupportingText: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <ConfigurableChakraText
      __css={styles.secondarySupportingText}
      className={mergeChakraClass(
        CARD_PARTS.secondarySupportingText,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

// =============================================================================
// Special elements
// =============================================================================
export const CardImage: FC<HTMLChakraProps<'img'> & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()
  return (
    <chakra.img
      __css={styles.image}
      className={mergeChakraClass(
        CARD_PARTS.image,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

// =============================================================================
// Lower elements
// =============================================================================

export const CardFootnote: FC<TextProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()

  return (
    <ConfigurableChakraText
      __css={styles.footnote}
      className={mergeChakraClass(
        CARD_PARTS.footnote,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}

type CardButtonProps = ButtonProps & {
  shouldResetSpacing?: boolean
}

export const CardButton: ComponentWithAs<FC<CardButtonProps>> = props => {
  const {
    className,
    shouldResetSpacing,
    size: sizeFromProps,
    ...rest
  } = props

  const styles = useCardStyles()
  const group = useButtonGroup()
  const buttonStyles = useStyleConfig('Button', { ...group, ...props })

  const { size: sizeFromTheme, ...cardButtonStyles } = styles.button as SystemStyleObject & {
    size?: string
  }

  return (
    <ConfigurableChakraButton
      __css={merge(buttonStyles, cardButtonStyles)}
      size={sizeFromProps || sizeFromTheme}
      className={mergeChakraClass(
        CARD_PARTS.button,
        'chakra-button',
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...rest}
    />
  )
}

export const CardTertiaryAction: FC<ButtonProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()

  return (
    <Box
      w='full'
      textAlign='center'
      className={createChakraClass(`${CARD_PARTS.tertiaryAction}_container`)}
    >
      <chakra.a
        __css={styles.tertiaryAction}
        className={mergeChakraClass(
          CARD_PARTS.tertiaryAction,
          className,
          shouldResetSpacing && RESET_SPACING,
        )}
        {...props}
      />
    </Box>
  )
}

// =============================================================================
// Other helpers
// =============================================================================

export const CardFullWidthSection = forwardRef<HTMLDivElement, BoxProps & {
  shouldResetSpacing?: boolean
}>(({ className, shouldResetSpacing, ...props }, ref) => {
  const styles = useCardStyles()

  return (
    <Box
      ref={ref}
      __css={styles.fullWidthSection}
      className={mergeChakraClass(
        CARD_PARTS.fullWidthSection,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
})

CardFullWidthSection.displayName = 'CardFullWidthSection'

export const CardSideScrollSpacer: FC<FlexProps & {
  shouldResetSpacing?: boolean
}> = ({ className, shouldResetSpacing, ...props }) => {
  const styles = useCardStyles()

  return (
    <ConfigurableChakraFlex
      __css={styles.sideScrollSpacer}
      className={mergeChakraClass(
        CARD_PARTS.sideScrollSpacer,
        className,
        shouldResetSpacing && RESET_SPACING,
      )}
      {...props}
    />
  )
}
