import { chakra } from '@chakra-ui/react'
import { HomeTypeIconProps } from 'presentation/screens/CompsScreen/components/HomeTypeFilterOption/components/HomeTypeIcon.props'

export const HomeTypeMultiFamIcon = (props: HomeTypeIconProps) => (
  <chakra.g>
    <chakra.rect
      x='19.625'
      y='37.625'
      width='6.75px'
      height='6.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='19.625'
      y='31.625'
      width='6.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M34.625 44.875V23.7589L42 16.3839L49.375 23.7589V44.875H34.625Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M11.625 44.875V31.8024L23 22.7971L34.375 31.8024V44.875H11.625Z'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='37.625'
      y='27.125'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='37.625'
      y='33.125'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='43.625'
      y='27.125'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='43.625'
      y='33.125'
      width='2.75px'
      height='2.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.rect
      x='40.625'
      y='39.125'
      width='2.75px'
      height='5.75px'
      stroke={props.color}
      strokeWidth='1.25'
    />
    <chakra.path
      d='M9 31.5L14 27.5M51.5 23.5L42 14L33 23V28.5L23 20.5L14 27.5M14 27.5V23'
      stroke={props.color}
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </chakra.g>
)
