import { Box, Center, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteRefMethods,
  Item,
} from '@choc-ui/chakra-autocomplete'
import { usePropertyDetailsStore } from 'features/PropertyDetails/infra/react/usePropertyDetailsState'
import { CloseIcon, SearchIcon } from 'presentation/components/Icons'
import { toast } from 'presentation/components/Toast'
import { useSwitchBreakpoint } from 'presentation/hooks/useSwitchBreakpoint'
import { textStyles } from 'presentation/main/themes/common/textStyles.common.theme'
import { ChangeEvent, useRef, useState } from 'react'
import { useDebounce } from 'react-use'

export const SearchPropertyInput = ({
  shouldShowCloseButton = false,
  inputPlaceholder,
  onClose,
}: {
  shouldShowCloseButton?: boolean
  inputPlaceholder?: string
  onClose?: () => void
}) => {
  // ========================================
  // Domain Selector
  // ========================================

  const {
    isLoadingSuggestions,
    isLoadingProperty,
    suggestions,
    suggestionsError,

    getSuggestions,
    clearSuggestions,
    getProperty,
  } = usePropertyDetailsStore(state => {
    const getSuggestions = state.actions.getPropertySuggestions
    const getProperty = state.actions.getProperty
    const clearSuggestions = state.actions.clearPropertySuggestions

    return {
      isLoadingSuggestions: getSuggestions.state.status === 'loading',
      isLoadingProperty: getProperty.state.status === 'loading'
      && getProperty.state.params.origin === 'search',
      suggestions: 'data' in getSuggestions.state
        ? getSuggestions.state.data.suggestions
        : [],
      searchState: getProperty.state,
      suggestionsError: 'error' in getSuggestions.state
        ? getSuggestions.state.error
        : undefined,

      getSuggestions: getSuggestions.execute,
      clearSuggestions: clearSuggestions.execute,
      getProperty: getProperty.execute,
    }
  })

  // ========================================
  // Managed State
  // ========================================
  const [inputValue, setInputValue] = useState('')

  const autocompleteRef = useRef<AutoCompleteRefMethods | null>(null)

  // ========================================
  // Effects
  // ========================================

  // Fetch on debounced value change
  const DEBOUNCE_RATE = 500
  const [getIsDebounceEffectReady] = useDebounce(
    () => {
      if (inputValue.length >= 3)
        void getSuggestions({ addressString: inputValue })
      else
        void clearSuggestions()
    },
    DEBOUNCE_RATE,
    [inputValue],
  )

  // ========================================
  // Event Handlers
  // ========================================

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value
    setInputValue(value)
  }

  const hasFocusedSuggestionRef = useRef(false)
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      const value = (ev.target as HTMLInputElement).value

      if (hasFocusedSuggestionRef.current) return

      if (value.length < 3) {
        toast.error({ title: 'Please enter at least 3 characters' })
      } else {
        void getProperty({
          origin: 'search',
          addressString: value,
        });

        (ev.target as HTMLInputElement).blur()

        setInputValue('')
      }
    }
  }

  const handleSelectSuggestion = ({ item }: { item: Item }) => {
    const suggestion = item.originalValue as string
    clearSuggestions()

    void getProperty({
      origin: 'search',
      parcelId: suggestion,
    })

    setInputValue('')
  }

  // ========================================
  // Other values
  // ========================================

  const placeholder = useSwitchBreakpoint({
    mobSm: 'Search for a specific property ',
    dtLg: 'Search for a specific property by typing the address here',
  })

  // ========================================
  // Render
  // ========================================

  return (
    <InputGroup>
      <AutoComplete
        ref={autocompleteRef}
        rollNavigation={false}
        closeOnSelect
        prefocusFirstItem={false}
        onSelectOption={handleSelectSuggestion}
        emphasize
        onOptionFocus={params => {
          hasFocusedSuggestionRef.current = !!params.item
        }}
        suggestWhenEmpty={false}
        closeOnBlur
        emptyState={(
          <Box
            px='2'
            color='graystrong.100'
          >
            {inputValue.length < 3
              ? (
                <> Please enter at least 3 characters</>
              )
              : isLoadingSuggestions || !getIsDebounceEffectReady()
                ? (
                  <>Loading...</>
                )
                : suggestionsError
                  ? (
                    <>If no results are shown, please type the complete address and press enter when done.</>
                  )
                  : (
                    <>If no results are shown, please type the complete address and press enter when done.</>
                  )}
          </Box>
        )}
        disableFilter
      >
        <AutoCompleteInput
          size='md'
          variant='rounded'
          placeholder={inputPlaceholder ?? placeholder}
          value={inputValue}
          onChange={handleInputChange}

          // search property on enter
          onKeyDown={handleKeyDown}
        />

        {/* @TODO Apply overlayscrollbar */}
        <AutoCompleteList
          sx={{
            bg: 'accent.hover !important',
            borderColor: 'grayweak.500',
            borderRadius: '2',
            py: '16px !important',
            pr: '16px !important',
            pl: '0 !important',
            overflow: 'auto',
            maxH: '320px',
            display: 'flex',
            flexDirection: 'column !important',
            alignItems: 'stretch',
            gap: '0.5',
          }}
        >
          {suggestions.map(data => (
            <AutoCompleteItem
              key={data.parcelId}
              value={data.parcelId}
              sx={{
                ...textStyles.bodyMFat,

                display: 'flex',
                alignItems: 'center',
                gap: '1',
                flexShrink: '0',

                // bg: 'none',
                color: 'graystrong.400',
                borderRightRadius: 'full',
                h: '5',
                p: 0,
                pl: '2',
                m: 0,
              }}
              _focus={{
                bg: 'card.bg.xlightblue',
                color: 'accent.blue-to-gray',
              }}
            >
              {data.addressString}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>

      <InputRightElement>
        {isLoadingSuggestions || isLoadingProperty
          ? (
            <Spinner boxSize={3} />
          )
          : shouldShowCloseButton
            ? (
              <Center
              /** Let's try to increase touchable area */
                m={-2}
                p={1}
                pr={2}
                onClick={onClose}
              >
                <CloseIcon />
              </Center>
            )
            : (
              <SearchIcon />
            )}
      </InputRightElement>
    </InputGroup>
  )
}
