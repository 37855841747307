import { checkStateHasReportData, useCMAStore } from 'features/CMA/infra/react/CMAState'
import { SoldWithinFilterDropdownDumb } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SoldWithinFilterDropdown/SoldWithinFilterDropdown.dumb'
import { SoldWithinFilterDropdownDumbProps } from 'presentation/screens/CompsScreen/components/CompsFilterBar/components/SoldWithinFilterDropdown/SoldWithinFilterDropdown.dumb.props'

export const SoldWithinFilterDropdown = () => {
  const reportState = useCMAStore(state => state.local.report)
  const filterComps = useCMAStore(state => state.actions.filterComps.execute)

  const handleOnValueChange: SoldWithinFilterDropdownDumbProps['onChange'] = value => {
    void filterComps({
      soldWithinMonths: {
        max: value[1],
        range: value,
      },
    })
  }

  if (!checkStateHasReportData(reportState)) return null

  const value = reportState.data.filters.soldWithinMonths.range

  return (
    <SoldWithinFilterDropdownDumb
      value={value}
      onChange={handleOnValueChange}
    />
  )
}
