import { Grid, GridProps } from '@chakra-ui/react'
import { mbpg } from 'presentation/utils/mapBreakpointByGroup'

export const HomeTypeFilterOptionGrid = (props: GridProps) => (
  <Grid
    mt={2}
    gridTemplateColumns='repeat(3, 1fr)'
    justifyItems='center'
    {...mbpg({
      mobSm: {
        columnGap: 0,
        rowGap: 2,
      },
      tabSm: {
        columnGap: 3,
        rowGap: 4,
      },
    })}
    {...props}
  />
)
